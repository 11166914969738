import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Loading, Paper } from '@swyftx/aviary/icons/outlined';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import { Links } from '@shared/constants';
import { UserStore } from '@shared/store';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useFetchApiKeys } from 'src/lib/user-profile/hooks/useFetchApiKeys';

import { ApiKeysContext } from './ApiKeys.context';
import { CreateApiKey } from './CreateApiKey';
import { MoreOptions } from './MoreOptions';
import { RevokeAllKeysModal } from './modals/RevokeAllKeysModal';
import { getKeyType } from './utils';

interface ApiKeysTable {
  name: string;
  date: string;
  scope: string;
  actions: React.ReactNode;
}

const ProfileApiKeys: React.FC = observer(() => {
  const { isRestricted, isUserVerified } = UserStore.useUserStore;
  const { showCreateNewApiKey, setShowCreateNewApiKey } = useContext(ApiKeysContext);
  const { t } = useTranslation('profile.apiKey');
  const { data, isFetching } = useFetchApiKeys();

  const isUserRestricted = isRestricted();

  const CreateApiKeyButton: React.FC = () => (
    <Tooltip
      title={
        isUserRestricted
          ? t('sections.activeKeys.actionItems.restrictedAction')
          : isUserVerified()
          ? t('sections.activeKeys.actionItems.createNewKeyDisabled')
          : ''
      }
    >
      <Button onClick={() => setShowCreateNewApiKey(true)} variant='outlined' disabled={isUserRestricted}>
        Create new key
      </Button>
    </Tooltip>
  );

  const tableData: EnhancedTableData<ApiKeysTable>[] = useMemo(
    () =>
      data?.map((apiKey) => ({
        name: {
          value: 'Name',
          element: <Body size='small'>{apiKey.label}</Body>,
        },
        date: {
          value: 'Date',
          element: <Body size='small'>{DateTime.fromMillis(apiKey.created).toFormat('EEE dd/MM/yyyy')}</Body>,
        },
        scope: {
          value: 'Scope',
          element: <Body size='small'>{getKeyType(apiKey)}</Body>,
        },
        actions: {
          value: 'Actions',
          element: <MoreOptions apiKey={apiKey} />,
        },
      })) || [],
    [data],
  );

  return (
    <>
      <SettingsCluster
        title='Your active keys'
        items={[
          <FlexLayout direction='column' className='w-[100%] py-8' key='keys-active-keys'>
            <FlexLayout direction='row' className='w-[100%] justify-end p-8' spacing={8}>
              <RevokeAllKeysModal />
              <CreateApiKeyButton />
            </FlexLayout>
            <EnhancedTable<ApiKeysTable>
              headers={{
                name: {
                  title: 'Name',
                  alignment: 'start',
                  sortable: false,
                  enabled: true,
                },
                date: {
                  title: 'Date',
                  alignment: 'start',
                  sortable: false,
                  enabled: true,
                },
                scope: {
                  title: 'Scope',
                  alignment: 'start',
                  sortable: false,
                  enabled: true,
                },
                actions: {
                  title: 'Actions',
                  alignment: 'end',
                  sortable: false,
                  enabled: true,
                },
              }}
              data={tableData}
              onSort={() => tableData}
              containerClassName='max-h-[35rem] overflow-y-auto'
              rowClassName='hover:bg-color-background-surface-primary cursor-default'
              headerClassName='rounded-[8px]'
            >
              {isFetching ? (
                <FlexLayout className='h-full w-full p-16' alignItems='center' justifyContent='center'>
                  <Loading className='animate-spin text-color-text-primary' />
                </FlexLayout>
              ) : (
                !data && (
                  <FlexLayout direction='column' className='items-center justify-center p-32 text-center' spacing={8}>
                    <Heading size='h5'>No active keys to display</Heading>
                    <Body>All active keys will display here on creation</Body>
                    <CreateApiKeyButton />
                  </FlexLayout>
                )
              )}
            </EnhancedTable>
          </FlexLayout>,
        ]}
      />
      <SettingsCluster
        title='Learn more about connecting to our API'
        items={[
          <SettingsItem
            key='keys-more-info'
            icon={<Paper />}
            title={t('sections.moreInformation.title')}
            description={t('sections.moreInformation.subtitle')}
            action={
              <Button onClick={() => window.open(Links.meta.apiDocumentation)?.focus()}>
                {t('sections.moreInformation.buttonLabel')}
              </Button>
            }
          />,
        ]}
      />

      {showCreateNewApiKey && <CreateApiKey />}
    </>
  );
});

export { ProfileApiKeys };

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Notification, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { SwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import AuthenticationService from '@services/AuthenticationService';

type Props = {
  onClose: () => void;
  onAction: () => void;
};

const INITIAL_CODE_TIMEOUT = 30;

export const DisableTwoFactorVerifyStep: React.FC<Props> = ({ onClose, onAction }) => {
  const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);
  const [mfaToken, setMfaToken] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const { t } = useTranslation('profile');
  const { t: securityT } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });

  const { getMaskedPhoneNumber } = UserStore.useUserStore;
  const { addMessageBox } = UIStore.useUIStore;

  const sendCode = async () => {
    try {
      setResendTime(resendTime + INITIAL_CODE_TIMEOUT);
      const mfa = await AuthenticationService.RemoveMFA();
      setMfaToken(mfa);
    } catch (e) {
      const { errorMessage } = e as SwyftxError;
      setError(errorMessage);
    }
  };

  const disableMfa = useCallback(async () => {
    if (!mfaToken) return;

    try {
      setSubmitting(true);
      const headers = {
        AuthAction: `${mfaToken} ${code}`,
      };

      const res = await AuthenticationService.RemoveMFA(headers);
      if (res.error) {
        return;
      }

      if (res.success === true) {
        onAction();
      }
    } catch (e) {
      const { errorMessage } = e as SwyftxError;
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  }, [code, mfaToken]);

  useEffect(() => {
    if (code.length === 6) disableMfa();
  }, [code, disableMfa]);

  const handlePasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setCode(text);
    addMessageBox({ content: <InformationMessageBox title={t('smsVerification.pastedFromClipboard')} /> });
  };

  useEffect(() => {
    sendCode();
  }, []);

  return (
    <Wizard.Step onClose={onClose} title={securityT('disable.title')} hideActions>
      <Stack spacing={2}>
        <Notification severity='info' title={securityT('disable.labels.notificationTitle')}>
          {securityT('disable.labels.notificationDescription')}
        </Notification>
        <Typography fontSize={pxToRem(14)} color='text.secondary'>
          {t('smsVerification.smsRequired')} {t('smsVerification.codeSent', { phone: '' })}
          <Typography fontSize={pxToRem(14)} color='text.secondary' display='inline' fontWeight={600}>
            {getMaskedPhoneNumber()}.
          </Typography>
        </Typography>
        <CodeInput
          onChange={(newCode: string[]) => setCode(newCode.join(''))}
          id='disable-two-factor-modal-code'
          hidePasteButton
          loading={submitting}
          error={error}
          length={6}
        />
        <Stack direction='row' spacing={2} alignItems='center'>
          {hasClipboardPermission === 'granted' && (
            <Button variant='text' onClick={() => handlePasteFromClipboard()} sx={{ marginTop: '0rem' }}>
              <Stack direction='row' alignItems='center' height='100%' spacing={1}>
                <Paste className='h-20 w-20 text-color-text-primary' />
                <Typography color='primary'>{t('smsVerification.pasteFromClipboard')}</Typography>
              </Stack>
            </Button>
          )}
          <CountdownDelay
            id='disable-two-factor-modal-resend-countdown'
            delay={resendTime}
            delayText={t('smsVerification.resendCodeIn')}
            restartText={t('smsVerification.resendCode')}
            onTimerRestart={sendCode}
          />
        </Stack>
      </Stack>
    </Wizard.Step>
  );
};

import React, { useCallback } from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

export interface RadioType {
  text: string;
  value: string;
}

interface RadioGroupProps {
  checkboxes: RadioType[];
  selected?: RadioType['value'];
  onChange: (value: RadioType['value']) => void;
  disabled?: boolean;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { checkboxes, selected, onChange, disabled } = props;

  const handleCheckedChange = useCallback(
    (value: RadioType['value']) => {
      if (value !== selected) {
        onChange(value);
      }
    },
    [onChange, selected],
  );

  return (
    <FlexLayout direction='column' spacing={12}>
      {checkboxes.map((checkbox, index) => (
        <FlexLayout
          direction='row'
          spacing={16}
          key={`${checkbox.value}-${index}`}
          onMouseDown={() => handleCheckedChange(checkbox.value)}
          className='cursor-pointer'
        >
          <Checkbox className='rounded-[100%]' checked={selected === checkbox.value} disabled={disabled} />
          <Body color={disabled ? 'disabled' : 'primary'}>{checkbox.text}</Body>
        </FlexLayout>
      ))}
    </FlexLayout>
  );
};

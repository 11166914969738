import React, { useCallback, useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset, Network } from '@shared/api/@types/markets';

type Props = {
  asset: Asset;
  selectedNetworkId?: number;
  onSelectNetwork: (network: Network) => void;
};

const CryptoNetworkSelector: React.FC<Props> = ({ asset, selectedNetworkId, onSelectNetwork }) => {
  const getNetwork = useCallback(
    (networkId: number) => asset.networks.find((n) => n.id === networkId),
    [asset.networks],
  );

  const filteredNetworks = useMemo(() => asset.networks.filter((n) => !n.depositDisableForce), [asset]);

  const onNetworkSelect = useCallback(
    (networkId: number) => {
      const network = getNetwork(networkId);
      if (network) onSelectNetwork(network);
    },
    [getNetwork, onSelectNetwork],
  );

  return (
    <FlexLayout direction='column' spacing={4}>
      <FlexLayout direction='row' spacing={8}>
        <Body weight='bold' size='small'>
          Select a network
        </Body>

        <Body color='error' size='small' weight='bold'>
          *
        </Body>
      </FlexLayout>
      <Select
        title='network'
        showInputTitle={false}
        required
        className='h-56'
        value={selectedNetworkId?.toString() || ''}
        onValueChange={(value) => onNetworkSelect(Number(value))}
        placeholder='Select a Network'
        items={filteredNetworks.map((network) => {
          const disabled = network.depositDisabled;

          return {
            label: network.networkNameFull,
            value: network.id.toString(),
            disabled,
            trailingIcon: disabled ? <Chip variant='subtle'>Temporarily suspended</Chip> : undefined,
          };
        })}
      ></Select>
    </FlexLayout>
  );
};

export { CryptoNetworkSelector };

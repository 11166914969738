import React from 'react';

import SkeletonMUI, { SkeletonProps } from '@mui/material/Skeleton';

const Skeleton: React.FC<SkeletonProps> = ({ id, children, ...props }) => (
  <SkeletonMUI id={id} {...props}>
    {children}
  </SkeletonMUI>
);

export { Skeleton };

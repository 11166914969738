import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import { CloseUniversalTrade } from '@global-components/CloseUniversalTrade/CloseUniversalTrade';
import { LimitedFunctionalityNotificationAccordion } from '@global-components/LimitedFunctionalityNotification/LimitedFunctionalityNotificationAccordion';
import { HighSlippageReviewModal } from '@global-components/Modals/HighSlippageReviewModal/HighSlippageReviewModal';

import { Asset } from '@shared/api';
import { ViewportLayout } from '@shared/events';
import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import {
  TradeAssetAction,
  TradeData,
  TradeSide,
  TradeState,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useAvo } from '@hooks/Avo/useAvo';
import { useUniversalTradeUtilityStore } from '@hooks/Trade';
import { getAssetBreakdowns } from '@utils/analytics';

import { TradeAssetInput } from '@Trade/components/UniversalTradePanel/components/TradeAssetInput';

import { observer } from 'mobx-react-lite';

import {
  TradeMultiDetailsLimitInput,
  TradeMultiDetailsPercent,
  TradeMultiDetailsReceive,
  TradeMultiDetailsSpend,
} from './components';

type Props = {
  dismissible?: boolean;
};

const TradeMultiDetails: React.FC<Props> = observer(({ dismissible }) => {
  const {
    setTradeAssets,
    setTradeUIData,
    setTradeState,
    setTradeData,
    setMaxTradeValue,
    multiTradeError,
    maxTradeValue,
    tradeType,
    tradeKeys,
    tradeFrom,
    tradeData,
    tradeTo,
  } = UniversalTradeStore;
  const { tradeUIDataErrors, tradeUIDataLoading, highSlippageTrades } = useUniversalTradeUtilityStore();
  const avo = useAvo();
  const { t } = useTranslation('trade', { keyPrefix: 'tradeMultiDetails' });
  const [reviewHighSlippage, setReviewHighSlippage] = useState<boolean>(false);

  const onRemoveAsset = (data?: TradeData) => {
    if (!data) return;

    const { to, from } = data || {};

    if (tradeFrom.length === 1) {
      setTradeAssets([to], TradeSide.To, TradeAssetAction.Remove);
    } else if (tradeTo.length === 1) {
      setTradeAssets([from], TradeSide.From, TradeAssetAction.Remove);
    }
  };

  const assets = useMemo(() => {
    const tradeFromAssets = tradeFrom.map(assetService.getAsset).filter((t) => t !== undefined) as Asset[];
    const tradeToAssets = tradeTo.map(assetService.getAsset).filter((t) => t !== undefined) as Asset[];

    return [...tradeFromAssets, ...tradeToAssets];
    // For some reason this will not run unless we actually check the length of the array as well
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeFrom.length, tradeTo.length, tradeFrom, tradeTo]);

  useEffect(() => {
    if (tradeKeys.length === 1) {
      const { from, to } = tradeData[tradeKeys[0]];
      setMaxTradeValue('');
      setTradeData(from, to, { balance: '' });
      setTradeUIData(from, to, { percentage: 0 });
      setTradeState(TradeState.PlaceOrder);
    }
  }, [maxTradeValue, setMaxTradeValue, setTradeData, setTradeState, setTradeUIData, tradeData, tradeKeys]);

  const { pathname } = useLocation();

  const theme = useTheme();
  const layout: ViewportLayout = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop';

  const reviewDisabledTitle = useMemo(() => {
    if (tradeFrom.length === 1 && !maxTradeValue?.length) return t('labels.fetchingDetails');
    if (tradeUIDataErrors.length > 0) return t('labels.fixTradeErrors');
    if (tradeUIDataLoading.length > 0) return t('labels.fetchingRates');
    if (multiTradeError !== undefined && multiTradeError.length > 0) return t('labels.fixTradeErrors');

    return '';
  }, [
    tradeFrom.length,
    maxTradeValue?.length,
    t,
    tradeUIDataErrors.length,
    tradeUIDataLoading.length,
    multiTradeError,
  ]);

  const handleOrderDetailsReviewed = () => {
    // Fire event for trade analytics: order details reviewed (multi asset btn)
    avo.orderDetailsReviewed({
      ...getAssetBreakdowns(tradeData),
      tradeType,
      layout,
      screen: pathname,
      sliderInputUsed: undefined,
      sliderUsed: undefined,
      quantityFieldUsed: undefined,
      limitAssetCode: undefined,
      fromAssetPercentage: undefined,
    });

    setTradeState(TradeState.OrderSummary);
  };

  const onReview = () => {
    if (highSlippageTrades.length > 0) {
      setReviewHighSlippage(true);
      return;
    }

    handleOrderDetailsReviewed();
  };

  return (
    <>
      <FlexLayout direction='column' spacing={8} className='h-full justify-between'>
        <FlexLayout direction='row' className='w-full items-center justify-between p-24 pb-8'>
          <Heading size='h5'>{t('labels.tradeAmounts')}</Heading>
          {dismissible && <CloseUniversalTrade />}
        </FlexLayout>
        <FlexLayout direction='column' className='h-full justify-between'>
          <div className='h-[10rem] min-h-[5rem] flex-grow overflow-auto'>
            <FlexLayout direction='column' className='p-16 !pt-0 lg:p-24' spacing={16}>
              {tradeFrom.length === 1 && <TradeMultiDetailsLimitInput />}
              {tradeTo.length === 1 && <TradeMultiDetailsPercent />}

              {tradeKeys.map((key: string) => (
                <FlexLayout key={key} className='w-full'>
                  <TradeAssetInput
                    variant='multiTrade'
                    key={key}
                    tradeDataKey={key}
                    title={false}
                    assetToDisplay={tradeTo.length === 1 ? tradeData[key].from : tradeData[key].to}
                    showBalances={tradeTo.length === 1}
                    flippable={tradeTo.length === 1}
                    onRemove={onRemoveAsset}
                    disabled={tradeFrom.length === 1 && !maxTradeValue}
                    lockable={tradeFrom.length === 1}
                    minPercentage={tradeFrom.length === 1 ? 5 : 0}
                  />
                </FlexLayout>
              ))}
            </FlexLayout>
          </div>
          <FlexLayout direction='column' className='bottom-0 border-t p-24 pt-8' spacing={8}>
            {assets && <LimitedFunctionalityNotificationAccordion assets={assets} assetsToIgnore={tradeFrom} />}
            <TradeMultiDetailsSpend />
            <TradeMultiDetailsReceive />
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' spacing={8}>
              <Button
                variant='outlined'
                size='lg'
                onClick={() => setTradeState(TradeState.PlaceOrder)}
                className='w-full'
              >
                {t('buttonLabels.back')}
              </Button>
              <Tooltip title={reviewDisabledTitle}>
                <Button
                  loading={tradeUIDataLoading.length > 0}
                  size='lg'
                  disabled={reviewDisabledTitle.length > 0}
                  onClick={onReview}
                  className='w-full'
                >
                  {t('buttonLabels.review')}
                </Button>
              </Tooltip>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      {reviewHighSlippage && (
        <HighSlippageReviewModal onClose={() => setReviewHighSlippage(false)} onContinue={handleOrderDetailsReviewed} />
      )}
    </>
  );
});

TradeMultiDetails.displayName = 'TradeMultiDetails';

export { TradeMultiDetails };

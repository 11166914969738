import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  title: string;
  icon: React.ReactNode;
  checked: boolean;
  color: 'warning' | 'primary';
  onClick: () => void;
};

const SideBarMobileHeaderSwitchItem: React.FC<Props> = ({ onClick, title, icon, checked, color }) => (
  <FlexLayout
    direction='row'
    spacing={24}
    alignItems='center'
    className='cursor-pointer select-none rounded-12 p-10 hover:bg-color-background-surface-hover'
    onClick={onClick}
  >
    <FlexLayout className='flex items-center justify-center text-color-text-primary'>{icon}</FlexLayout>
    <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
      <FlexLayout direction='column'>
        <Body weight='emphasis' color='primary'>
          {title}
        </Body>
      </FlexLayout>

      <Switch checked={checked} color={color} data-transition-force />
    </FlexLayout>
  </FlexLayout>
);

export { SideBarMobileHeaderSwitchItem };

import { Validator, ValidatorResponse } from '.';

const WEIGHTINGS = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
const ABN_LENGTH = 11;
const DIVIDER = 89;

// ABN validator logic was sourced from here https://abr.business.gov.au/Help/AbnFormat
class AbnValidator extends Validator<string> {
  validate = (input: string): ValidatorResponse => {
    const sanitizedInput = input.replace(/\s/g, '');
    let valid = true;

    if (sanitizedInput.length !== ABN_LENGTH) {
      valid = false;
    } else {
      const digits = sanitizedInput.split('');
      let sum = 0;
      digits.forEach((digit, index) => {
        const weight = WEIGHTINGS[index];

        if (index === 0) {
          sum += (Number(digit) - 1) * weight;
        } else {
          sum += Number(digit) * weight;
        }
      });

      valid = sum % DIVIDER === 0;
    }

    return {
      message: valid ? undefined : this.message,
      valid,
    };
  };
}

export default AbnValidator;

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DriversLicenseIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <rect
        x='3.667'
        y='5.333'
        width='24.667'
        height='21.333'
        rx='3'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='.4'
        d='M21 12h4M21 16h4M21 20h4'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        clipRule='evenodd'
        d='M12.167 15.333a2.667 2.667 0 1 1 2.666-2.666 2.657 2.657 0 0 1-2.647 2.666h-.019ZM12.167 18c2.516 0 4.666.4 4.666 1.994 0 1.595-2.136 2.006-4.666 2.006C9.65 22 7.5 21.604 7.5 20.009 7.5 18.413 9.636 18 12.167 18Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

import * as React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const AssetMigratorFeesModal: React.FC<Props> = (props) => {
  const { open, handleClose } = props;

  return (
    <Modal
      open={open}
      onOpenChange={(e) => (e !== true ? handleClose() : undefined)}
      aria-labelledby='confirm-asset-transfer-from-exchange'
      title='Portfolio Transfer Fees'
    >
      <FlexLayout direction='column' className='max-w-[40rem] p-24 pt-0' spacing={16}>
        <FlexLayout direction='column'>
          <ul>
            <li>
              <Body size='small' color='secondary'>
                - First transfer per exchange with one asset is fee-free.
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                - First transfer per exchange, per day are also fee-free.
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                - All additional transfers made in the same day will incur transfer fees.
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                - Swyftx will match each asset with our available networks and select the cheapest available network, to
                reduce any fees.
              </Body>
            </li>
          </ul>
        </FlexLayout>
        <FlexLayout direction='row' className='w-full justify-end'>
          <Button variant='outlined' onClick={handleClose}>
            Close
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

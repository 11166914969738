import { useCallback, useContext, useEffect } from 'react';

import { ModalNameValueType } from 'src/context/Avo/generated-avo';

import { NavigationContext } from '../context';

type Props = {
  title?: string;
  content?: string;
};

const useNavigateExitIntent = ({ title, content }: Props = {}) => {
  const { setExitIntentData } = useContext(NavigationContext);

  const initExitIntent = useCallback(
    (initTitle: string, initContent: string, stepId?: string, featureName?: string, modalName?: ModalNameValueType) => {
      setExitIntentData({ title: initTitle, content: initContent, stepId, featureName, modalName });
    },
    [setExitIntentData],
  );

  const removeExitIntent = useCallback(() => {
    setExitIntentData(undefined);
  }, [setExitIntentData]);

  useEffect(() => {
    if (title && content) {
      initExitIntent(title, content);
    }

    return () => {
      removeExitIntent();
    };
  }, [content, initExitIntent, removeExitIntent, setExitIntentData, title]);

  return {
    initExitIntent,
    removeExitIntent,
  };
};

export { useNavigateExitIntent };

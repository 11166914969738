import React from 'react';
import { useTranslation } from 'react-i18next';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';

type Props = {
  onClose: () => void;
  onAction: () => void;
};

export const EnableTwoFactorConfirmStep: React.FC<Props> = ({ onClose, onAction }) => {
  const { t } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });

  return (
    <Wizard.Step
      onClose={onClose}
      title={t('enable.title')}
      onAction={onAction}
      actionName={t('enable.buttonLabels.action')}
    >
      <Stack spacing={2}>
        <Typography fontSize={pxToRem(14)} color='text.secondary'>
          {t('enable.labels.confirmDescription')}
        </Typography>
      </Stack>
    </Wizard.Step>
  );
};

import React from 'react';

import Error from '@mui/icons-material/Error';
import { useMediaQuery, useTheme } from '@mui/material';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

// Need to get the filled icon from designs

type Props = {
  title: string;
  content?: string | React.ReactNode;
  footer?: JSX.Element;
};

export const ErrorMessageBox: React.FC<Props> = ({ title, content, footer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FlexLayout direction='row' spacing={8} alignItems='start' justifyContent='start'>
      <Error color='error' fontSize={isMobile ? 'small' : 'large'} />
      <FlexLayout direction='column' spacing={4} className='max-w-[85%]'>
        <Body weight='bold'>{title}</Body>

        {content && (
          <>
            {typeof content === 'string' ? (
              <Body size='small' color='secondary' weight='emphasis'>
                {content}
              </Body>
            ) : (
              content
            )}
          </>
        )}

        {footer}
      </FlexLayout>
    </FlexLayout>
  );
};

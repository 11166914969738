import React, { useCallback, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';

import { NavigationContext } from '../../context';
import { useNavigateRoute } from '../../hooks';
import { NavigationURLs } from '../../types';

const NavigationExitIntent: React.FC = () => {
  const { exitIntentData, hideExitIntent } = useContext(NavigationContext);
  const { navigate } = useNavigateRoute();
  const { pathname: screen } = useLocation();
  const isXs = useTailwindBreakpoint('xs');
  const avo = useAvo();

  const navigateToRoute = useCallback(
    (url?: NavigationURLs) => {
      hideExitIntent(true);
      if (url) navigate(url, undefined, true);

      if (exitIntentData) {
        const { stepId, featureName, modalName } = exitIntentData;
        if (stepId) avo.uxFlowExited({ stepId, modalName, featureName, screen });
      }
    },
    [avo, exitIntentData, hideExitIntent, navigate, screen],
  );

  if (!exitIntentData) return null;

  return (
    <Modal
      title={exitIntentData.title}
      open={exitIntentData.show}
      position={isXs ? 'bottom' : 'center'}
      className='sm:!min-w-[500px] sm:!max-w-[500px]'
      onClose={() => hideExitIntent(false)}
    >
      <FlexLayout direction='column' className='px-16 pb-16' spacing={24}>
        <Body>{exitIntentData.content}</Body>
        <FlexLayout spacing={16} alignItems='center' justifyContent='end' direction={isXs ? 'column' : 'row'}>
          <Button variant='outlined' size='lg' onClick={() => hideExitIntent(false)} className={isXs ? 'w-full' : ''}>
            Cancel
          </Button>
          {exitIntentData.path && (
            <Button
              size='lg'
              variant='filled'
              color='destructive'
              onClick={() => navigateToRoute(exitIntentData.path)}
              className={isXs ? 'w-full' : ''}
            >
              Yes, discard
            </Button>
          )}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { NavigationExitIntent };

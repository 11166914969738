import { useCallback } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { usePriceScale } from '../General/usePriceScale';

const useHighSlippage = (primary?: Asset, secondary?: Asset) => {
  const { getRate } = RatesStore.useRatesStore;
  const { calculatePriceScale } = usePriceScale();

  const calculateSlippage = useCallback(
    (triggerPrice: Big) => {
      if (!primary || !secondary) return 0;

      const secondaryScale = calculatePriceScale(secondary);

      const currentPrice = Big(getRate(secondary).midPrice).toFixed(secondaryScale);

      const quotePrice = triggerPrice.toFixed(secondaryScale);

      const quoteToPriceDifference = Big(quotePrice).minus(currentPrice);

      return Big(quoteToPriceDifference).div(currentPrice).times(100).abs().toNumber();
    },
    [calculatePriceScale, getRate, primary, secondary],
  );

  return {
    calculateSlippage,
  };
};

export { useHighSlippage };

import { useCallback, useEffect, useRef } from 'react';

import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';
import {
  getFromGlobalState,
  updateGlobalState,
} from 'src/lib/trade/components/TradingViewWidget/types/tradingViewGlobalState';

import { useTradingViewAnalytics } from '../hooks';
import { TradingViewSide } from '../types/TradingViewWidget.types';

type Props = {
  tvWidget?: IChartingLibraryWidget;
  primary: string;
  secondary: string;
  useNewStyle?: boolean;
  onPriceSideChange?: (side: TradingViewSide) => void;
};

const useTradingViewHeaderAdapter = ({ tvWidget, primary, secondary, useNewStyle, onPriceSideChange }: Props) => {
  const tvWidgetId = useRef<string>();
  const priceSideButton = useRef<HTMLElement>();
  const tradeMarksButton = useRef<HTMLElement>();
  const { trackPriceSideSelected } = useTradingViewAnalytics({ primary, secondary });

  const getSides = () => {
    const currentSide = getFromGlobalState('side');
    const oppositeSide: TradingViewSide = currentSide === 'bid' ? 'ask' : 'bid';

    return { currentSide, oppositeSide };
  };

  const onTogglePriceSide = useCallback(() => {
    if (!tvWidget) return;

    const { oppositeSide, currentSide } = getSides();

    if (onPriceSideChange) onPriceSideChange(oppositeSide);
    trackPriceSideSelected(oppositeSide);
    if (getFromGlobalState('onResetCacheNeededCallback')) getFromGlobalState('onResetCacheNeededCallback')?.();
    tvWidget.activeChart().resetData();

    if (priceSideButton.current) {
      priceSideButton.current.innerHTML = `SHOW ${currentSide.toUpperCase()}`;
    }

    updateGlobalState('side', oppositeSide);
  }, [onPriceSideChange, trackPriceSideSelected, tvWidget, priceSideButton]);

  const createPriceSideButton = useCallback(() => {
    if (!tvWidget) return;
    const { oppositeSide } = getSides();

    const button = priceSideButton.current ?? tvWidget.createButton();
    button.setAttribute('title', 'Click to toggle between BID and ASK prices');
    button.onclick = onTogglePriceSide;
    button.classList.add('custom-button');

    button.innerHTML = `SHOW ${oppositeSide.toUpperCase()}`;
    priceSideButton.current = button;
  }, [tvWidget, onTogglePriceSide]);

  const onToggleMarks = useCallback(() => {
    if (!tvWidget) return;

    const showMarks = getFromGlobalState('showMarks');

    tvWidget.chart().executeActionById('hideAllMarks');

    if (tradeMarksButton.current) {
      tradeMarksButton.current.innerHTML = `${!showMarks ? 'HIDE' : 'SHOW'} TRADES`;
    }

    updateGlobalState('showMarks', !showMarks);
    if (getFromGlobalState('onResetCacheNeededCallback')) getFromGlobalState('onResetCacheNeededCallback')?.();
  }, [tvWidget, tradeMarksButton]);

  const createTradesButton = useCallback(() => {
    if (!tvWidget) return;

    const showMarks = getFromGlobalState('showMarks');
    const button = tradeMarksButton.current ?? tvWidget.createButton();
    button.setAttribute('title', 'Click to toggle showing trades on the chart');
    button.classList.add('custom-button');
    button.onclick = onToggleMarks;

    button.innerHTML = `${showMarks ? 'HIDE' : 'SHOW'} TRADES`;
    tradeMarksButton.current = button;

    if (!showMarks && !tvWidget.activeChart().getCheckableActionState('hideAllMarks')) {
      tvWidget.chart().executeActionById('hideAllMarks');
    }
  }, [tvWidget, onToggleMarks]);

  useEffect(() => {
    if (tvWidget && !useNewStyle) {
      tvWidget?.onChartReady(() => {
        const widgetId = (tvWidget as any)?._id;

        if (tvWidgetId.current !== widgetId) {
          tradeMarksButton.current = undefined;
          priceSideButton.current = undefined;

          tvWidget?.headerReady().then(() => {
            createPriceSideButton();
            createTradesButton();
            tvWidgetId.current = widgetId;
          });
        }
      });
    }
  }, [createPriceSideButton, createTradesButton, onPriceSideChange, tvWidget, useNewStyle, tvWidgetId]);
};

export { useTradingViewHeaderAdapter };

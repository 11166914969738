import { VariantProps, cva } from 'cva';

import { BodyProps } from '../Typography/Body/Body.styles';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  color?: 'primary' | 'subtle' | 'accent' | 'success' | 'destructive' | 'inverse';
  textAlign?: 'start' | 'center' | 'end';
  textJustify?: 'start' | 'center' | 'end' | 'space-between';
  bodyProps?: BodyProps;
  disabled?: boolean;
  leadingIcon?: React.ReactElement;
  trailingIcon?: React.ReactElement;
  tooltip?: string;
  loading?: boolean;
}

export const buttonIconVariants = cva('', {
  variants: {
    color: {
      primary: 'text-color-icon-inverse',
      subtle: 'text-color-icon-primary',
      accent: 'text-color-icon-inverse',
      success: 'text-color-icon-inverse',
      destructive: 'text-color-icon-inverse',
      inverse: 'text-color-icon-inverse',
    },
    variant: {
      filled: undefined,
      outlined: 'text-color-icon-primary',
      ghost: 'text-color-icon-primary',
    },
    size: {
      sm: 'w-12 h-12',
      md: 'w-16 h-16',
      lg: 'w-20 h-20',
    },
    selected: {
      true: 'text-color-icon-selected',
      false: '',
    },
    disabled: {
      true: 'text-color-icon-disabled',
    },
  },
});

export const getFontSize = (size: string | null) => {
  switch (size) {
    case 'sm':
      return 'xsmall';
    case 'md':
      return 'small';
    case 'lg':
      return 'medium';
    default:
      return 'small';
  }
};

export const getCustomBodyClasses = (size: string | null) => {
  switch (size) {
    case 'sm':
      return '!line-height-12';
    case 'md':
      return '!line-height-16';
    case 'lg':
      return '!line-height-20';
    default:
      return '!line-height-16';
  }
};

export const buttonVariants = cva('flex text-center font-medium whitespace-nowrap focus:focus-area-info', {
  variants: {
    color: {
      primary:
        'bg-color-background-primary text-color-text-inverse hover:bg-color-background-primary-hover active:bg-color-background-info-active',
      subtle:
        ' text-black bg-color-background-neutral text-color-text-primary hover:bg-color-background-neutral-hover active:bg-color-background-neutral-active',
      accent:
        'bg-color-background-primary text-color-text-inverse hover:bg-color-background-primary-hover active:bg-color-background-primary-active',
      success:
        'bg-color-background-success text-color-text-inverse hover:bg-color-background-success-hover active:bg-color-background-success-active',
      destructive:
        'bg-color-background-error text-color-text-inverse hover:bg-color-background-error-hover active:bg-color-background-error-active',
      inverse:
        'bg-color-text-primary text-color-text-inverse hover:bg-color-background-surface-inverse-hover active:bg-color-background-surface-inverse-active',
    },
    variant: {
      filled: undefined,
      outlined:
        'bg-color-background-none hover:bg-color-background-none text-color-text-primary outline outline-1 outline-color-border-main hover:outline-color-border-hover active:outline-color-border-active active:bg-color-background-none',
      ghost:
        'bg-color-background-none hover:bg-color-background-neutral-hover active:bg-color-background-neutral-active text-color-text-primary',
    },
    layout: {
      default: '',
      icon: '',
    },
    focusStyle: {
      true: 'focus-area-info',
      false: '',
    },
    size: {
      sm: 'font-size-12 rounded-14',
      md: 'font-size-14 rounded-20',
      lg: 'font-size-16 rounded-24',
    },
    selected: {
      true: 'bg-color-background-surface-selected hover:bg-color-background-surface-selected active:bg-color-background-surface-selected text-color-text-selected',
      false: '',
    },
    disabled: {
      true: 'bg-color-background-surface-disabled hover:bg-color-background-surface-disabled active:bg-color-background-surface-disabled text-color-text-neutral',
    },
  },
  compoundVariants: [
    {
      variant: 'ghost',
      color: 'accent',
      class:
        'text-color-text-accent hover:bg-color-background-primary-subtle active:bg-color-background-primary-subtle',
    },
    {
      variant: 'ghost',
      color: 'inverse',
      class:
        'text-color-text-inverse hover:bg-color-background-surface-inverse-hover active:bg-color-background-surface-inverse-active',
    },
    {
      variant: 'outlined',
      // TODO fix this to use boolean when CVA updates
      selected: 'false' as any,
      class: 'border border-color-border-main ',
    },
    {
      size: 'sm',
      layout: 'icon',
      className: 'p-4 rounded-full',
    },
    {
      variant: 'outlined',
      className: 'bg-color-background-transparent hover:bg-color-background-transparent',
    },
    {
      size: 'sm',
      layout: 'default',
      className: 'px-12 py-4',
    },
    {
      size: 'md',
      layout: 'icon',
      className: 'p-8 rounded-full',
    },
    {
      size: 'md',
      layout: 'default',
      className: 'px-16 py-8',
    },
    {
      size: 'lg',
      layout: 'icon',
      class: 'p-12 rounded-full',
    },
    {
      size: 'lg',
      layout: 'default',
      class: 'px-24 py-12',
    },
  ],
  defaultVariants: {
    variant: 'filled',
    color: 'primary',
    layout: 'default',
    size: 'md',
    disabled: false,
    focusStyle: false,
  },
});

export const buttonLoadingVariants = cva('animate-spin', {
  variants: {
    size: {
      sm: 'w-15 h-15',
      md: 'w-14 h-14',
      lg: 'w-16 h-16',
    },
    variant: {
      filled: 'text-color-text-inverse',
      outlined: 'text-color-text-primary',
      ghost: 'text-color-text-primary',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

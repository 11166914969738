const REM_TO_PX_MULTIPLIER = 16;
const ITEM_MARGIN = 12;
const FIAT_ITEM_REM_WIDTH = 11.5;
const BTC_ITEM_REM_WIDTH = 14.5;

/**
 * Calculates the px width of a static market ticker item
 */
export const getStaticItemWidth = (isBaseBTC: boolean) =>
  (isBaseBTC ? BTC_ITEM_REM_WIDTH : FIAT_ITEM_REM_WIDTH) * REM_TO_PX_MULTIPLIER + ITEM_MARGIN;

export enum MarketTickerMode {
  STATIC = 'static',
  MOVING = 'moving',
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CoinFilled } from '@swyftx/aviary/icons/filled';
import { Button, Card, Stack, Typography } from '@swyftx/react-web-design-system';

import { NavigationURLs } from 'src/lib/navigation/types';

const AppHeaderBaseCurrencySelectorPurchaseCrypto: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'headerWallet' });
  const navigate = useNavigate();

  const viewAssets = () => {
    navigate(NavigationURLs.AssetsAll);
  };

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      spacing={2}
      paddingX={2}
      paddingY={2}
      color='text.secondary'
    >
      <Card elevation={0} variant='outlined'>
        <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
          <CoinFilled className='my-8' />
          <Typography fontWeight={600} fontSize={16} color='text.primary' textAlign='center'>
            {t('purchase.title')}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={14}
            color='text.secondary'
            textAlign='center'
            marginBottom='.5rem !important'
          >
            {t('purchase.subTitle')}
          </Typography>
          <Button variant='contained' color='primary' onClick={viewAssets} fullWidth size='large'>
            {t('purchase.viewAssets')}
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export { AppHeaderBaseCurrencySelectorPurchaseCrypto };

import React, { useContext, useMemo, useState } from 'react';

import { UserStore } from '@shared/store';

import { ApiKeysContext } from '@routes/Profile/subroutes/ApiKeys/ApiKeys.context';
import { ApiKeyConfirmPasswordStep } from '@routes/Profile/subroutes/ApiKeys/CreateApiKey/components';

import { observer } from 'mobx-react-lite';

import { IntegrateKoinlyApiKeyStep, IntegrateKoinlySsoStep, IntegrateKoinlySsoTypeStep } from './components';
import { IntegrateKoinlyStep } from './types/IntegrateKoinly.types';

type Props = {
  onClickCancel: () => void;
};

const IntegrateKoinly: React.FC<Props> = observer(({ onClickCancel }) => {
  const { isEntity } = UserStore.useUserStore;
  const entityAccount = isEntity();
  const { resetNewApiKeyPayload, setCreatedApiKeyResponse, createNewApiKey } = useContext(ApiKeysContext);
  const [step, setStep] = useState<IntegrateKoinlyStep>(IntegrateKoinlyStep.SsoType);

  const steps: IntegrateKoinlyStep[] = useMemo(
    () =>
      entityAccount
        ? [IntegrateKoinlyStep.SsoType, IntegrateKoinlyStep.ApiKey, IntegrateKoinlyStep.Sso]
        : [
            IntegrateKoinlyStep.SsoType,
            IntegrateKoinlyStep.ApiKey,
            IntegrateKoinlyStep.Authentication,
            IntegrateKoinlyStep.Sso,
          ],
    [entityAccount],
  );

  const handleSubmitApiKey = () => {
    nextStep();
    if (entityAccount) createNewApiKey();
  };

  const handleOnClose = () => {
    setStep(IntegrateKoinlyStep.SsoType);
    resetNewApiKeyPayload();
    setCreatedApiKeyResponse(undefined);
    onClickCancel();
  };

  /** sets step which changes modal displayed */
  const nextStep = () => {
    if (!step) return;

    const nextStepIdx = steps.indexOf(step) + 1;

    if (nextStepIdx >= steps.length) {
      setStep(IntegrateKoinlyStep.SsoType);
    } else {
      setStep(steps[nextStepIdx]);
    }
  };

  return (
    <>
      {step === IntegrateKoinlyStep.SsoType && (
        <IntegrateKoinlySsoTypeStep onContinue={nextStep} onClose={handleOnClose} />
      )}
      {step === IntegrateKoinlyStep.ApiKey && (
        <IntegrateKoinlyApiKeyStep onSubmit={handleSubmitApiKey} onClose={handleOnClose} />
      )}
      {step === IntegrateKoinlyStep.Authentication && (
        <ApiKeyConfirmPasswordStep onSubmit={nextStep} onClose={handleOnClose} thirdParty />
      )}
      {step === IntegrateKoinlyStep.Sso && <IntegrateKoinlySsoStep onClose={handleOnClose} />}
    </>
  );
});

IntegrateKoinly.displayName = 'IntegrateKoinly';

export { IntegrateKoinly };

import React from 'react';

import BoxMUI, { BoxProps } from '@mui/material/Box';
import InputAdornmentMUI from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';

import { StyledTextFieldMUI } from './Input.styled';
import { DangerIcon, TickIcon } from './assets';
import { getPIIClassName } from '../global-utils';
import { PIITypes } from '../types/PII.types';
import Typography from '../typography';

export type InputProps = {
  success?: boolean;
  ContainerProps?: BoxProps;
} & TextFieldProps &
  PIITypes;

const Input: React.FC<InputProps> = ({ id, error, label, sx, success = false, PII, ContainerProps, ...props }) => {
  const getEndAdornment = () => {
    const { endAdornment } = props.inputProps || {};

    if (error) {
      return (
        <InputAdornmentMUI position='end'>
          <DangerIcon color='error' />
        </InputAdornmentMUI>
      );
    }

    if (success) {
      return (
        <InputAdornmentMUI position='end'>
          <TickIcon color='success' />
        </InputAdornmentMUI>
      );
    }

    return endAdornment && <InputAdornmentMUI position='end'>{endAdornment}</InputAdornmentMUI>;
  };

  return (
    <BoxMUI
      className={getPIIClassName(PII)}
      {...ContainerProps}
      sx={{ ...ContainerProps?.sx, width: '100%' }}
      id={`${id}.container`}
    >
      {label && (
        <Typography fontWeight={600} color='text.primary' fontSize={14} marginBottom='.5rem' fontFamily='Public Sans'>
          {label}
          {props?.required && <span style={{ color: '#EF2D4E' }}>*</span>}
        </Typography>
      )}
      <StyledTextFieldMUI
        id={id}
        margin='none'
        success={success}
        error={error}
        {...props}
        InputProps={{
          ...props.InputProps,
          startAdornment: props.inputProps?.startAdornment || props.InputProps?.startAdornment,
          endAdornment: props.inputProps?.endAdornment || props.InputProps?.endAdornment || getEndAdornment(),
        }}
        sx={{ ...sx, width: '100%' }}
      />
    </BoxMUI>
  );
};

export default Input;

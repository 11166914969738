import { DefaultMidnightThemePalette } from '../midnight/default.palette';
import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

// TODO: replace these with colors from refresh
const RefreshMidnightThemePalette: any = {
  ...DefaultMidnightThemePalette,
  text: {
    primary: '#F5F5F6',
    secondary: '#C3C6C8',
    disabled: '#22262C',
  },
  divider: '#22262C',
  background: {
    paper: '#101214',
    default: '#0B0D0F',
  },
  getContrastText: () => Colors.White,
  augmentColor: () => ({
    main: '#ABFFFD',
    light: '#ABFFFD',
    dark: '#ABFFFD',
    contrastText: Colors.White,
  }),
  action: {
    hover: '#28313c',
  },
  grey: {
    50: '#F5F5F6',
    100: '#F5F5F6',
    200: '#C3C6C8',
    300: '#888C92',
    400: '#565D64',
    500: '#384049',
    600: '#22262C',
    700: '#191C20',
    800: '#101214',
    900: '#0B0D0F',
    A100: '#F5F5F6',
    A200: '#C3C6C8',
    A400: '#565D64',
    A700: '#191C20',
  },
  primary: {
    main: '#ABFFFD',
  },
  secondary: {
    main: '#ABFFFD',
  },
  error: {
    main: '#FC5151',
  },
  warning: {
    main: '#ECB24C',
  },
  info: {
    main: '#ABFFFD',
  },
  success: {
    main: '#4CC672',
  },
  common: {
    black: Colors.Black,
    white: Colors.White,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: '#101214',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
};

const RefreshMidnightTheme = createTheme(RefreshMidnightThemePalette, '"Public Sans"');

export { RefreshMidnightTheme };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  asset: Asset;
};

export const AssetWalletHeader: React.FC<Props> = observer(({ asset }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleAsset.assetWallet' });
  const { navigate } = useNavigateRoute();

  const handleViewWallet = () => {
    navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset.code } });
  };

  return (
    <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
      <FlexLayout direction='row' spacing={12} alignItems='center'>
        <AssetIcon asset={asset} size={16} wallet />
        <Body id='asset-wallet-name' weight='bold'>
          {asset?.code} {t('labels.wallet')}
        </Body>
      </FlexLayout>
      <FlexLayout direction='row' spacing={2}>
        <Button variant='ghost' color='accent' onClick={handleViewWallet}>
          {t('buttonLabels.viewWallet')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
});

import { createTheme as createThemeMUI, Palette, Theme } from '@mui/material';

import { Colors } from '../types/Colors';

/**
 * Creates a theme object merging: common themes, theme pallet, theme metadata
 *
 * @param palette color pallet for provided them
 * @param name name of the theme for displaying purposes
 * @param id id of the theme for data storage purposes
 * @returns
 */
const createTheme = (palette: Palette, fontFamily = '"Public Sans"'): Theme =>
  createThemeMUI({
    palette,
    typography: {
      fontFamily,
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red',
          },
        },
      },
      MuiCard: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: '.5rem',
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            zIndex: 110000,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            '.MuiCardHeader-title': {
              fontSize: 14,
              fontWeight: 600,
            },
            borderBottom: '1px solid',
            borderColor: palette.mode === 'dark' ? Colors.Grey[7] : Colors.Grey[3],
          },
        },
      },
    },
    shape: {
      borderRadius: 8,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    },
  });

export default createTheme;

import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CountriesEnum } from '@shared/enums';
import { SwyftxError, isReCaptchaError } from '@shared/error-handler';
import storage, { StorageKey } from '@shared/storage';

import { useMarketingTags } from '@hooks/useMarketingTags/useMarketingTags';
import { useRecaptcha } from '@hooks/useRecaptcha/useRecaptcha';
import AuthenticationService from '@services/AuthenticationService';

import { registerStepsConfig as registerStepsConfigDefault } from '@Register/Register.data';
import { RegisterTranslationKey } from '@Register/translations';
import { AffiliatesEnum, initialValues, RegisterStoreSchema, SignUpFormValues } from '@Register/types/Register.types';

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { useLocalObservable } from 'mobx-react-lite';

export const RegisterContext = createContext<RegisterStoreSchema>(initialValues);

type Props = {
  children?: React.ReactNode;
};

export const RegisterProviderMain: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation(RegisterTranslationKey);
  const { getMarketingTags } = useMarketingTags();
  const {
    captchaToken,
    setCaptchaToken: updateCaptchaToken,
    setCaptchaRefresh: updateCaptchaRefresh,
    captchaRefresh,
  } = useRecaptcha();

  const registerStepsConfig = registerStepsConfigDefault;

  const store = useLocalObservable(
    (): RegisterStoreSchema => ({
      ...initialValues,
      registerStepsConfig,

      // useRecaptcha variables
      captchaRefresh,
      captchaToken,
      setCaptchaToken: (token: string) => {
        store.captchaToken = token;
        updateCaptchaToken(token);
      },
      setCaptchaRefresh: (refresh: boolean) => {
        store.captchaRefresh = refresh;
        updateCaptchaRefresh(refresh);
      },
      setReferralCode: (referralCode: string) => {
        store.referralCode = referralCode;
      },
      setPromoRef: (promoRef: string) => {
        store.promoRef = promoRef;
      },
      setAffiliate: (affiliate?: AffiliatesEnum) => {
        store.affiliate = affiliate;
      },
      setCountry: (country: CountriesEnum) => {
        store.country = country;
      },
      setEmail: (email: string) => {
        if (email !== store.email) {
          store.emailVerified = false;
        }

        store.email = email;
      },
      setEmailVerified: (emailVerified: boolean) => {
        store.emailVerified = emailVerified;
      },
      setFirstName: (firstName: string) => {
        store.firstName = firstName;
      },
      setLastName: (lastName: string) => {
        store.lastName = lastName;
      },
      setPhoneNumber: (phoneNumber: string) => {
        if (phoneNumber !== store.phoneNumber) {
          store.phoneNumberVerified = false;
        }

        store.phoneNumber = phoneNumber;
      },
      setPhoneNumberVerified: (phoneNumberVerified: boolean) => {
        store.phoneNumberVerified = phoneNumberVerified;
      },
      setPassword: (password: string) => {
        store.password = password;
      },
      setPasswordConfirmation: (passwordConfirmation: string) => {
        store.passwordConfirmation = passwordConfirmation;
      },
      setTermsAccepted: (termsAccepted: boolean) => {
        store.termsAccepted = termsAccepted;
      },
      resetRegisterStep: () => {
        store.registerStep = 0;
      },
      setRegisterStep: (registerStep: number) => {
        store.registerStep = registerStep;
      },
      nextRegisterStep: () => {
        store.registerStep += 1;
      },
      prevRegisterStep: () => {
        store.registerStep -= 1;
      },
      isFirstStep: () => store.registerStep === 0,
      isFinalStep: () => store.registerStep + 1 === store.registerStepsConfig.length,
      isComplete: () => store.registerStep >= store.registerStepsConfig.length,
      setEmailCode: (newCode: string[]) => {
        store.emailCode = newCode.join('');
      },
      setEmailCodeSent: (emailCodeSent: boolean) => {
        store.emailCodeSent = emailCodeSent;
      },
      setPhoneCode: (newCode: string[]) => {
        store.phoneCode = newCode.join('');
      },
      setPhoneCodeSent: (phoneCodeSent: boolean) => {
        store.phoneCodeSent = phoneCodeSent;
      },
      setSubmitting: (submitting: boolean) => {
        store.submitting = submitting;
      },
      setError: (error: string) => {
        store.error = error;
      },
      setHasDuplicateInfo: (hasDuplicateInfo: boolean) => {
        store.hasDuplicateInfo = hasDuplicateInfo;
      },
      getPhoneNumber: (phoneNumber: string, country?: CountriesEnum) =>
        parsePhoneNumber(phoneNumber, country as CountryCode)
          ?.formatInternational()
          .replace(/\s/g, ''),
      createAccount: async (formData: SignUpFormValues) => {
        try {
          const parsedPhoneNumber = store.getPhoneNumber(formData.mobileNumber, formData.country || CountriesEnum.AU);
          const marketingTags = getMarketingTags();
          const userProfile = await AuthenticationService.CreateAccount(
            formData.email,
            parsedPhoneNumber,
            formData.password,
            formData.firstName,
            formData.lastName,
            formData.country || CountriesEnum.AU,
            store.emailCode,
            store.phoneCode,
            store.referralCode,
            store.promoRef,
            formData.captchaToken,
            marketingTags,
            formData.applyForEntityAccount,
          );

          if (userProfile) {
            storage.setItem(StorageKey.USER_DATA, JSON.stringify(userProfile));
            store.nextRegisterStep();
          }
          store.setSubmitting(false);

          return true;
        } catch (e: any) {
          const { errorMessage } = e as SwyftxError;

          if (isReCaptchaError(e)) {
            store.setError(t('errors.recaptcha'));
            store.setCaptchaToken('');
            store.setHasDuplicateInfo(false);
          } else {
            if (errorMessage) {
              store.setError(errorMessage);
            } else {
              store.setError(e?.message ?? t('errors.somethingWrong'));
            }
            store.setSubmitting(false);
          }
        }

        return false;
      },
      handleBack: () => {
        if (store.registerStep > 0) {
          store.setRegisterStep(store.registerStep - 1);
        }
      },
      handleSendCode: async (formData: SignUpFormValues, isPhone: boolean) => {
        try {
          await AuthenticationService.SetupOTP(
            store.captchaToken,
            isPhone ? store.getPhoneNumber(formData.mobileNumber, formData.country) : undefined,
            isPhone ? undefined : formData.email,
          );

          if (isPhone) {
            store.setPhoneCodeSent(true);
          } else {
            store.setEmailCodeSent(true);
          }

          // When the code is successfully sent
          store.setHasDuplicateInfo(false);
          store.setError('');

          return true;
        } catch (e) {
          const { errorMessage } = e as SwyftxError;

          // Check if there is a reCAPTCHA error
          if (isReCaptchaError(e)) {
            store.setError(t('errors.recaptcha'));
            store.setCaptchaToken('');
            store.setHasDuplicateInfo(false);
          } else {
            // Parse error messages with custom messages or default to the backend error
            switch (errorMessage.toLowerCase()) {
              case 'missing args for request':
                store.setError(t('errors.somethingWrong'));
                store.setHasDuplicateInfo(false);
                break;
              case 'an account exists with the credentials you have provided':
                store.setHasDuplicateInfo(true);
                store.setError(errorMessage);
                return false;
              default:
                store.setError(t('errors.failedToSend'));
                store.setHasDuplicateInfo(false);
                break;
            }
          }

          return false;
        }
      },
      handleVerifyCode: async (formData: SignUpFormValues, isPhone: boolean) => {
        store.setSubmitting(true);
        store.setError('');

        try {
          const res: any = await AuthenticationService.SetupOTP(
            store.captchaToken,
            isPhone ? store.getPhoneNumber(formData.mobileNumber, formData.country) : undefined,
            isPhone ? undefined : formData.email,
            isPhone ? store.phoneCode : store.emailCode,
          );
          if (res.success) {
            if (isPhone) {
              store.setPhoneNumberVerified(true);
              store.nextRegisterStep();
            } else {
              store.setEmailVerified(true);
              store.nextRegisterStep();
            }
          }
        } catch (e) {
          const { errorMessage } = e as SwyftxError;

          if (isReCaptchaError(e)) {
            store.setError(t('errors.recaptcha'));
            store.setCaptchaToken('');
            store.setHasDuplicateInfo(false);
          } else {
            // Parse error messages with custom messages or default to the backend error
            switch (errorMessage.toLowerCase()) {
              case 'invalid otp code':
                store.setError(t('errors.invalidOtp'));
                break;
              case 'missing args for request':
                store.setError(t('errors.somethingWrong'));
                break;
              default:
                store.setError(errorMessage);
                break;
            }
          }
        } finally {
          store.setSubmitting(false);
        }
      },
      cleanup: () => {
        store.referralCode = initialValues.referralCode;
        store.promoRef = initialValues.promoRef;
        store.affiliate = initialValues.affiliate;
        store.country = initialValues.country;
        store.email = initialValues.email;
        store.emailVerified = initialValues.emailVerified;
        store.firstName = initialValues.firstName;
        store.lastName = initialValues.lastName;
        store.phoneNumber = initialValues.phoneNumber;
        store.phoneNumberVerified = initialValues.phoneNumberVerified;
        store.password = initialValues.password;
        store.passwordConfirmation = initialValues.passwordConfirmation;
        store.error = initialValues.error;
        store.hasDuplicateInfo = initialValues.hasDuplicateInfo;
        store.termsAccepted = initialValues.termsAccepted;
        store.registerStep = initialValues.registerStep;
        store.submitting = initialValues.submitting;
        store.emailCode = initialValues.emailCode;
        store.phoneCode = initialValues.phoneCode;
      },
    }),
  );

  return <RegisterContext.Provider value={store}>{children}</RegisterContext.Provider>;
};

/**
 * Wrapping register context in a experiment context for AB testing
 */
const RegisterProvider: React.FC<Props> = ({ children }) => <RegisterProviderMain>{children}</RegisterProviderMain>;

RegisterProvider.displayName = 'RegisterProvider';

export { RegisterProvider };

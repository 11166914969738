import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const WithdrawFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.32422 5.10938C7.91001 5.10938 7.57422 5.44516 7.57422 5.85938C7.57422 6.27359 7.91001 6.60938 8.32422 6.60938H16.3289L5.37592 17.5623C5.08303 17.8552 5.08303 18.3301 5.37592 18.623C5.66881 18.9159 6.14369 18.9159 6.43658 18.623L17.3411 7.71844V15.6263C17.3411 16.0405 17.6769 16.3763 18.0911 16.3763C18.5053 16.3763 18.8411 16.0405 18.8411 15.6263V5.96796C18.8447 5.92514 18.8446 5.88208 18.8409 5.83927C18.8302 5.43435 18.4986 5.10938 18.0911 5.10938H8.32422Z'
      fill='currentColor'
    />
  </svg>
);

export { WithdrawFilled };

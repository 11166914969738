import React, { useCallback, useMemo, useState } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { CountriesEnum } from '@shared/enums';

import { Countries } from '@utils/countries';

import { InputProps } from 'react-day-picker';

type Props = {
  onChange?: (value: string) => void;
  value?: string;
  defaultCountry?: CountriesEnum;
  onChangeCountry?: (value: CountriesEnum) => void;
  disabled?: boolean;
  error?: boolean;
} & InputProps;

{
  /* TODO: flesh this out with mobile validation */
}
export const MobileInput: React.FC<Props> = (props) => {
  const { defaultCountry, onChange, onChangeCountry, value, disabled, error } = props;

  const [phoneCountry, setPhoneCountry] = useState<CountriesEnum | undefined>(defaultCountry || undefined);

  const items: SelectItemData[] = useMemo(() => {
    const sortedCountries = Object.values(CountriesEnum).sort((a, b) => {
      if (a === CountriesEnum.AU || a === CountriesEnum.NZ) return -1;
      if (b === CountriesEnum.AU || b === CountriesEnum.NZ) return 1;
      return 0;
    });

    return sortedCountries.map((country) => ({
      leadingIcon: <img src={`/assets/images/flags/${Countries[country].code}.svg`} alt='flag' />,
      label: `(${Countries[country].dialCode}) ${Countries[country].name}`,
      value: country,
    }));
  }, []);

  const handlePhoneNumberCountryChange = useCallback(
    (e: CountriesEnum) => {
      setPhoneCountry(e);
      if (onChangeCountry) onChangeCountry(e);
    },
    [onChangeCountry],
  );

  const handlePhoneNumberInputChange = useCallback(
    (newNumber: string) => {
      if (onChange) onChange(newNumber);
    },
    [onChange],
  );

  return (
    <FlexLayout direction='row' spacing={8} className='justify-between'>
      <Select
        items={items}
        triggerClassName='rounded-r-0 pr-8 h-[2.5rem]'
        layoutClassName='w-80 !h-full'
        portalClassName='min-w-[20rem]'
        value={phoneCountry}
        noSelectedLabel
        onValueChange={(country: CountriesEnum) => handlePhoneNumberCountryChange(country)}
        disabled={disabled}
        error={error}
      />
      <Input
        containerClassName='w-full rounded-l-0 h-[2.5rem]'
        leading={phoneCountry ? <Body color='secondary'>{`(${Countries[phoneCountry].dialCode})`}</Body> : undefined}
        placeholder='0000-000-000'
        value={value}
        onChange={(e) => handlePhoneNumberInputChange(e.target.value)}
        disabled={disabled}
        error={error}
      />
    </FlexLayout>
  );
};

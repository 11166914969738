import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppStore, UniversalTradeStore, UserStore } from '@shared/store';
import { TradeSide, TradeState } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import {
  TradeAssetSelector,
  TradeMultiDetails,
  TradeOrderDetails,
  TradeOrderDetailsReview,
  TradeUnverifiedAccount,
} from '@Trade/components/UniversalTradePanel/components';
import { useUpdateTradePanelRates } from '@Trade/components/UniversalTradePanel/hooks';

import { observer } from 'mobx-react-lite';

type Props = {
  dismissible?: boolean;
  tradePanel?: boolean;
};

const UniversalTradePanel: React.FC<Props> = observer(({ dismissible, tradePanel }) => {
  const { t } = useTranslation('trade');
  const { setTradeState, tradeFrom, tradeTo, tradeState } = UniversalTradeStore;
  useUpdateTradePanelRates(UniversalTradeStore);
  const { isUserVerified } = UserStore.useUserStore;
  const { isDemo } = AppStore.useAppStore;

  useEffect(() => {
    if (
      (tradeState === TradeState.Recurring && tradeTo.length > 0) ||
      tradeState === TradeState.OrderSummary ||
      tradeState === TradeState.MultiTrade ||
      tradeState === TradeState.SelectFromAssets ||
      tradeState === TradeState.SelectToAsset
    )
      return;
    setTradeState(TradeState.PlaceOrder);
  }, [tradeFrom, tradeTo, tradeState, setTradeState]);

  return isUserVerified() || isDemo ? (
    <>
      {tradeState === TradeState.PlaceOrder && <TradeOrderDetails dismissible={dismissible} tradePanel={tradePanel} />}
      {tradeState === TradeState.SelectFromAssets && (
        <TradeAssetSelector
          title={t('tradeAssetSelector.labels.tradeFrom')}
          side={TradeSide.From}
          dismissible={dismissible}
        />
      )}
      {tradeState === TradeState.SelectToAsset && (
        <TradeAssetSelector
          title={t('tradeAssetSelector.labels.tradeTo')}
          side={TradeSide.To}
          dismissible={dismissible}
        />
      )}
      {tradeState === TradeState.OrderSummary && <TradeOrderDetailsReview dismissible={dismissible} />}
      {tradeState === TradeState.MultiTrade && <TradeMultiDetails dismissible={dismissible} />}
    </>
  ) : (
    <TradeUnverifiedAccount dismissible={dismissible} />
  );
});

UniversalTradePanel.displayName = 'UniversalTradePanel';

export { UniversalTradePanel };

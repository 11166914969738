import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { defaultPortfolioPerformanceTabs } from '@swyftx/aviary/organisms/Portfolio/PortfolioPerformanceCard/PortfolioPerformanceCard.types';

import { PortfolioBreakdownWidget, PortfolioPerformanceWidget } from 'src/lib/portfolio/widgets';

import { DashboardWidgetRowProps } from '../../types/Dashboard.types';

const PortfolioHeroRow: React.FC<DashboardWidgetRowProps> = ({ atLeast, layoutKey }) => (
  <FlexLayout
    alignItems='start'
    justifyContent='space-between'
    className='w-full flex-wrap @sm:flex-nowrap'
    spacing={atLeast?.md ? 24 : 16}
  >
    <PortfolioBreakdownWidget
      key='porfolio-breakdown'
      className={`z-10 h-[520px] w-full p-24 ${layoutKey === 'markets' ? '@md:w-1/2' : '@md:w-1/3'}`}
      showLegendPercentages={false}
      atLeast={atLeast}
    />
    <PortfolioPerformanceWidget
      key='portfolio-performance'
      className={`h-[430px] w-full @sm:h-[520px] ${layoutKey === 'markets' ? '@md:w-1/2' : '@md:w-2/3'}`}
      tabs={defaultPortfolioPerformanceTabs}
    />
  </FlexLayout>
);

export { PortfolioHeroRow };

import { useCallback } from 'react';

import { api, Asset, Network } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

type Props = {
  asset?: Asset;
  network?: Network;
};

const useFetchAssetDepositAddresses = ({ asset, network }: Props) => {
  const { userId } = UserStore.useUserStore;

  const fetchAssetDepositAddresses = useCallback(async () => {
    if (!asset || !network) return { addresses: [] };

    const results = await api.endpoints.getDepositAddressesV2({
      params: { assId: asset.id, networkId: network.id },
    });

    return results.data;
  }, [asset, network]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['asset-deposit-addresses', asset?.id, network?.id, userId],
    queryFn: fetchAssetDepositAddresses,
    keepPreviousData: false,
    enabled: asset !== undefined && network !== undefined,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: TimesEnum.MINUTE * 60,
  });

  return { status, data: data, error, isFetching, isPreviousData, isStale };
};

export { useFetchAssetDepositAddresses };

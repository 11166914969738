import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ClickAwayListener, Popper } from '@mui/material';

import { CategoryFilled, ListFilled } from '@swyftx/aviary/icons/filled';
import { Category, List, Refresh } from '@swyftx/aviary/icons/outlined';
import { Button, Grid, pxToRem, Typography } from '@swyftx/react-web-design-system';

import { SearchInput } from '@global-components/Input';

import { NEWS_TILE_PREFERRED_VIEW, NewsTileTab, NewsTileView } from '@shared/api';
import { Asset } from '@shared/api/@types/markets';
import { UniversalTradeStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { useDebounce } from 'react-use';

import NewsFeedSearchResults from '../NewsFeedSearchResults/NewsFeedSearchResults';

type Props = {
  ownedAndFavouriteAssets: Asset[];
  allAssets: Asset[];
  setSearchFilter: Dispatch<SetStateAction<string[]>>;
  setNewsFeedSearchValue: Dispatch<SetStateAction<string>>;
  newsFeedSearchValue: string;
  newsTileTab: NewsTileTab;
  tileSize?: string;
  lastUpdated: string;
  handleRefresh: () => void;
  isThumbnailView: boolean;
  setIsThumbnailView: Dispatch<SetStateAction<boolean>>;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
};

const NewsFeedSearchFilter = ({
  ownedAndFavouriteAssets,
  allAssets,
  setSearchFilter,
  setNewsFeedSearchValue,
  newsFeedSearchValue,
  newsTileTab,
  tileSize,
  lastUpdated,
  handleRefresh,
  isThumbnailView,
  setIsThumbnailView,
  setIsSearching,
}: Props) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'newsFeedTile' });
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const avo = useAvo();
  const { showGlobalTrade } = UniversalTradeStore;
  const { bx, isMobile, isLargeContent } = useContentBreakpoint();
  const hideForMobile = isMobile || tileSize === 'compact';
  const isSmall =
    !isLargeContent || tileSize === 'compact' || tileSize === 'half' || (tileSize === 'wide' && showGlobalTrade);

  // Default asset list is the list we will default back to when there is no search input by the user
  const [defaultAssetList, setDefaultAssetList] = React.useState<Asset[]>(
    newsTileTab === NewsTileTab.MyNews ? ownedAndFavouriteAssets : allAssets,
  );

  // Filtered assets are the assets that the news articles are filtered on
  // and the list of assets shown in the filter dropdown
  const [filteredAssets, setFilteredAssets] = React.useState<Asset[]>(defaultAssetList);

  const open = Boolean(anchorEl);
  const id = open ? 'news-search-popper' : undefined;
  const searchValue = newsFeedSearchValue.toLocaleLowerCase();

  const resetSearchFilter = () => {
    const defaultSearchFilter =
      newsTileTab === NewsTileTab.MyNews ? ownedAndFavouriteAssets.map((ownedAsset) => ownedAsset.code) : [];

    setSearchFilter(defaultSearchFilter);
    setNewsFeedSearchValue('');
    setIsSearching(false);
  };

  const handleClose = () => {
    setFocused(false);
  };

  const handlePreferredView = () => {
    localStorage.setItem(NEWS_TILE_PREFERRED_VIEW, isThumbnailView ? NewsTileView.List : NewsTileView.Thumbnail);
    setIsThumbnailView(!isThumbnailView);
    avo.switchedLatestNewsView({
      screen: pathname,
      switchedView: isThumbnailView ? NewsTileView.Thumbnail : NewsTileView.List,
    });
  };

  useDebounce(
    () => {
      if (newsFeedSearchValue.length > 0) {
        const assets = defaultAssetList.filter(
          (asset) =>
            asset.name.toLocaleLowerCase().includes(searchValue) ||
            asset.code.toLocaleLowerCase().includes(searchValue),
        );
        setFilteredAssets(assets);

        if (assets.length === 0) {
          avo.noNewsFilterAvailable({
            screen: pathname,
            newsFeedPreference: newsTileTab,
            filterSearched: newsFeedSearchValue,
          });
        }
      } else {
        setFilteredAssets(defaultAssetList);
      }
    },
    200,
    [newsFeedSearchValue],
  );

  useEffect(() => {
    if (newsTileTab === NewsTileTab.MyNews) {
      setDefaultAssetList(ownedAndFavouriteAssets);
      setFilteredAssets(ownedAndFavouriteAssets);
    } else {
      setDefaultAssetList(allAssets);
      setFilteredAssets(allAssets);
    }

    resetSearchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsTileTab]);

  useEffect(() => {
    if (newsTileTab === NewsTileTab.MyNews) {
      setSearchFilter(ownedAndFavouriteAssets.map((ownedAsset) => ownedAsset.code));
      setDefaultAssetList(ownedAndFavouriteAssets);
      setFilteredAssets(ownedAndFavouriteAssets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownedAndFavouriteAssets]);

  useEffect(() => {
    setAnchorEl(inputRef.current);
  }, []);

  return (
    <>
      {!isSmall && lastUpdated && (
        <Typography fontSize={12} color='text.secondary' sx={{ marginTop: '12px' }}>
          {t('labels.lastUpdated', { time: lastUpdated })}
        </Typography>
      )}
      <Button size='small' icon onClick={handleRefresh} sx={{ color: 'text.secondary' }}>
        <Refresh />
      </Button>
      {!hideForMobile && (
        <>
          <Button size='small' icon onClick={handlePreferredView} disabled={!isThumbnailView}>
            {isThumbnailView ? <List className='text-color-text-secondary' /> : <ListFilled />}
          </Button>

          <Button size='small' icon onClick={handlePreferredView} disabled={isThumbnailView}>
            {isThumbnailView ? <CategoryFilled /> : <Category className='text-color-text-secondary' />}
          </Button>
        </>
      )}

      {!hideForMobile && (
        <Grid item sx={{ width: bx({ sm: '100%', lg: '300px' }) }}>
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              <div ref={inputRef}>
                <SearchInput
                  disabled={newsTileTab === NewsTileTab.MyNews && defaultAssetList.length < 1}
                  id='news-filter-search'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewsFeedSearchValue(e.target.value);
                    if (e.target.value.length < 1) resetSearchFilter();
                  }}
                  placeholder={t('labels.search')}
                  autoComplete='off'
                  onFocus={() => setFocused(true)}
                  onDelete={() => {
                    resetSearchFilter();
                  }}
                  inputProps={{
                    sx: {
                      height: 8,
                      fontSize: pxToRem(14),
                    },
                  }}
                  value={newsFeedSearchValue}
                />
              </div>
              <Popper
                id={id}
                open={focused}
                anchorEl={anchorEl}
                placement='bottom-end'
                modifiers={[
                  {
                    name: 'flip',
                    enabled: false,
                    options: {
                      altBoundary: true,
                      rootBoundary: 'document',
                      padding: 8,
                      offset: '1, 0',
                    },
                  },
                ]}
              >
                <NewsFeedSearchResults
                  filteredAssets={filteredAssets}
                  setFilteredSearch={setSearchFilter}
                  setFocused={setFocused}
                  isSmall={isSmall}
                  newsFeedSearchValue={newsFeedSearchValue}
                  setNewsFeedSearchValue={setNewsFeedSearchValue}
                  newsTileTab={newsTileTab}
                  setIsSearching={setIsSearching}
                />
              </Popper>
            </div>
          </ClickAwayListener>
        </Grid>
      )}
    </>
  );
};

export { NewsFeedSearchFilter };

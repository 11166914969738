import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';

import { AssetIconsGroup } from '../AssetIconsGroup';

type Props = {
  assets: Asset[];
  size?: number;
  maxAssetsToShow?: number;
  onClick?: () => void;
};

const AssetThumbnailStack: React.FC<Props> = ({ assets, size = 20, maxAssetsToShow, onClick }) => (
  <Button
    variant='outlined'
    className='bg-color-background-surface-primary'
    layout='icon'
    onClick={onClick}
    trailingIcon={<ArrowChevronDown />}
  >
    <FlexLayout alignItems='center'>
      <AssetIconsGroup assets={assets} size={size} maxAssets={maxAssetsToShow} alignment='left' />
      {assets.length} assets
    </FlexLayout>
  </Button>
);

export { AssetThumbnailStack };

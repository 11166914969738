import { useCallback, useState } from 'react';

import { api, Asset, AssetHistoryItemStatus, SortDirection, SortKey, TransactionType } from '@shared/api';

import { useQuery } from '@tanstack/react-query';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

type Props = {
  asset?: Asset;
  pageLimit?: number;
  sortKey: SortKey;
  sortDirection: SortDirection;
  statuses?: AssetHistoryItemStatus[];
  types?: TransactionType[];
  startDate?: number;
  userId?: string;
};

const useTransactions = ({
  asset,
  pageLimit = 20,
  sortKey,
  sortDirection,
  statuses,
  types,
  userId,
  startDate,
}: Props) => {
  const [curPage, setCurPage] = useState<number>(1);
  const { isSwyftxPro } = useSwyftxPro();

  const fetchTransactions = useCallback(
    async (page = 0) => {
      if (asset) {
        const { data } = await api.endpoints.getAssetTransactionHistory({
          query: {
            page,
            limit: pageLimit,
            sortKey,
            sortDirection,
            startDate,
            type: types?.join(','),
            status: statuses?.join(','),
            showLinks: isSwyftxPro ? true : undefined,
          },
          params: { assetId: asset.id },
        });
        return data.items;
      }
      const { data } = await api.endpoints.getAllTransactionHistory({
        query: {
          page,
          limit: pageLimit,
          sortKey,
          sortDirection,
          type: types?.join(','),
          status: statuses?.join(','),
          showLinks: isSwyftxPro ? true : undefined,
        },
      });
      return data.items;
    },
    [asset, isSwyftxPro, pageLimit, sortDirection, sortKey, startDate, statuses, types],
  );

  const { status, data, error, isFetching, isPreviousData } = useQuery({
    queryKey: [
      'transactions',
      userId,
      asset?.code || 'all',
      statuses?.join(','),
      types?.join(','),
      sortKey,
      sortDirection,
      curPage,
    ],
    queryFn: () => fetchTransactions(curPage),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 10000,
  });

  const nextPage = useCallback(() => {
    setCurPage(curPage + 1);
  }, [curPage]);

  const prevPage = useCallback(() => {
    setCurPage(Math.max(1, curPage - 1));
  }, [curPage]);

  return {
    status,
    transactions: data,
    error,
    isFetching,
    isPreviousData,
    nextPage,
    prevPage,
  };
};

export { useTransactions };

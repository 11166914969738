/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { NavigationErrorBoundary } from '@global-components/ErrorBoundaries';

import env from '@shared/config';
import { AppStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useTrackPageView } from './useTrackPageView';

type Props = {
  element: React.ReactElement;
  pageName?: string;
};

const MaintenanceRoute: React.FC<Props> = observer(({ element, pageName = '' }) => {
  const { isMaintenanceMode } = AppStore.useAppStore;
  const { userProfile } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  useTrackPageView({ pageName });

  if (isMaintenanceMode || env.MAINTENANCE_MODE === true || isFeatureEnabled(AppFeature.MaintenanceMode)) {
    return <NavigationErrorBoundary>{element}</NavigationErrorBoundary>;
  }

  return userProfile ? <Navigate to={NavigationURLs.Dashboard} /> : <Navigate to={NavigationURLs.Login} />;
});

MaintenanceRoute.displayName = 'MaintenanceRoute';

export default MaintenanceRoute;

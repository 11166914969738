import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { Information } from '@swyftx/aviary/icons/outlined';

type Props = {
  title: string;
  tooltip?: string;
  value: string;
  valueWeight?: 'none' | 'emphasis' | 'bold';
  color?: 'primary' | 'success' | 'error';
};

const SwyftxProTradeBreakdownItem: React.FC<Props> = ({
  title,
  tooltip,
  value,
  valueWeight = 'none',
  color = 'primary',
}) => (
  <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
    <FlexLayout alignItems='center' spacing={4}>
      <Body className='uppercase' size='small' color='primary'>
        {title}
      </Body>
      {tooltip && <Button size='sm' layout='icon' variant='ghost' leadingIcon={<Information />} />}
    </FlexLayout>
    <Numeric size='small' color={color} weight={valueWeight}>
      {value}
    </Numeric>
  </FlexLayout>
);

export { SwyftxProTradeBreakdownItem };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';

import { TransactionTypeFilter } from '@services/WalletService';

import { useAssetTransactionHistoryTable } from './AssetTransactionHistoryTable.hooks';

type Props = {
  asset: Asset;
  type: TransactionTypeFilter.DEPOSIT | TransactionTypeFilter.WITHDRAWAL;
  onClose: () => void;
};

// TODO infinite scroll with skeleton
export const AssetTransactionHistoryModal: React.FC<Props> = ({ asset, type, onClose }) => {
  const { t } = useTranslation('modals');
  const { table } = useAssetTransactionHistoryTable(asset, type);
  const isDeposit = type === TransactionTypeFilter.DEPOSIT;

  const getTitle = () =>
    t('assetTransactionHistory.table.headers.assetHistory', {
      code: asset.code,
      type: isDeposit
        ? t('assetTransactionHistory.table.headers.deposit')
        : t('assetTransactionHistory.table.headers.withdraw'),
    });

  return (
    <Modal
      id='asset-transaction-history-modal'
      open
      HeaderProps={{
        title: getTitle(),
        dismissible: true,
      }}
      onClose={onClose}
      sx={{ width: '80%' }}
    >
      {table}
    </Modal>
  );
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

type Props = {
  className?: string;
  icon: React.ReactElement;
  title: string;
  date: string;
};

const TransactionsOrderTypeDate: React.FC<Props> = ({ className, icon, title, date }) => (
  <FlexLayout justifyContent='start' alignItems='center' spacing={8} className={className}>
    {icon}
    <FlexLayout direction='column'>
      <Body size='small' overflow='truncate' weight='emphasis'>
        {title}
      </Body>
      <Numeric size='small' className='truncate'>
        {date}
      </Numeric>
    </FlexLayout>
  </FlexLayout>
);

export { TransactionsOrderTypeDate };

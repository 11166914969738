import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { ArrowInCircleLeft } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography, IconButton } from '@swyftx/react-web-design-system';

import { useAvo } from '@hooks/Avo/useAvo';
import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { onboardingMachine } from '@routes/Onboarding/Onboarding.machine';
import { OnboardingStepId } from '@routes/Onboarding/types/Onboarding.types';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';

import { NavigationURLs } from 'src/lib/navigation/types';

const OnboardingForm = styled('form')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type Props = {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  submitDisabled?: boolean;
  hideTitle?: boolean;
  customTitle?: string;
  customFooterLeftElement?: React.ReactElement;
};

const OnboardingStepContainer: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, onSubmit, hideTitle, customFooterLeftElement, submitDisabled, customTitle } = props;
  const { t } = useTranslation('onboarding');
  const avo = useAvo();

  const onboardingService = useOnboardingService();

  const stepId = useOnboardingSelector((state) => {
    const [rootStateId, activeStateId] = state.toStrings();

    if (activeStateId != null) {
      const activeStateNode = onboardingMachine.getStateNodeByPath(activeStateId);
      if (activeStateNode.meta?.step && !activeStateNode.meta?.hidden) {
        return activeStateId as OnboardingStepId;
      }
    }

    return rootStateId as OnboardingStepId;
  });

  const isCompleted = useOnboardingSelector((state) => state.context.completedSteps[stepId]);

  useEffect(() => {
    if (!isCompleted) {
      avo.onboardingWizardStepStarted({
        screen: NavigationURLs.Onboarding,
        stepId,
      });
    }
  }, [avo, stepId, isCompleted]);

  const canGoBack = useOnboardingSelector((state) => state.can('BACK'));
  const canContinue = useOnboardingSelector((state) => state.can('NEXT'));
  const hideContinueButton = useOnboardingSelector((state) => state?.meta?.[stepId]?.hideContinueButton) ?? false;
  const titleBackButton = useOnboardingSelector((state) => state?.meta?.[stepId]?.titleBackButton) ?? false;

  const { isLargeScreen } = useScreenBreakpoints();

  const tipContent = useMemo(
    () =>
      t(`steps.${stepId}.tip.content` as any, {
        defaultValue: '',
      }),
    [t, stepId],
  );

  const [, setSearchParams] = useSearchParams();

  // Search params used purely for analytics & tracking purposes
  useEffect(() => {
    setSearchParams({ step: stepId });
  }, [stepId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = () => {
    onboardingService.send('BACK');
    avo.onboardingWizardBackClicked({
      screen: NavigationURLs.Onboarding,
      stepId,
    });
  };

  const onSubmitDefault = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onboardingService.send('NEXT');
  };

  const shouldRender =
    customFooterLeftElement !== undefined || (canGoBack && !titleBackButton) || (canContinue && !hideContinueButton);

  return (
    <OnboardingForm onSubmit={onSubmit ?? onSubmitDefault}>
      <Stack flex={1}>
        {!hideTitle && (
          <Box marginBottom={3}>
            <Stack direction='row' alignItems='center'>
              {canGoBack && titleBackButton && (
                <IconButton sx={{ color: 'inherit' }} onClick={handleBack}>
                  <ArrowInCircleLeft />
                </IconButton>
              )}

              <Typography variant='h1' fontWeight={700} fontSize={{ xs: 20, sm: 24 }} marginTop={{ xs: 1, md: 0 }}>
                {customTitle ?? t(`steps.${stepId}.title` as any)}
              </Typography>
            </Stack>

            {!isLargeScreen && tipContent ? (
              <Typography fontSize={14} color='text.secondary' marginTop={2}>
                {tipContent}
              </Typography>
            ) : null}
          </Box>
        )}

        {children}
      </Stack>
      {shouldRender && (
        <Stack direction='row' justifyContent='flex-end' spacing={1} marginTop={{ xs: 3, sm: 6 }}>
          {customFooterLeftElement}
          {canGoBack && !titleBackButton && (
            <Button color='inherit' size='large' onClick={handleBack} sx={{ height: '3rem', paddingX: '1rem' }}>
              {t('actions.back')}
            </Button>
          )}
          {canContinue && !hideContinueButton && (
            <Button
              variant='contained'
              type='submit'
              fullWidth={!canGoBack}
              disabled={submitDisabled}
              sx={{ height: '3rem', paddingX: '1rem' }}
            >
              {t('actions.continue')}
            </Button>
          )}
        </Stack>
      )}
    </OnboardingForm>
  );
};

export { OnboardingStepContainer };

import { useCallback } from 'react';

import { api, DashboardSettings } from '@shared/api';
import { UserStore } from '@shared/store';

import * as Sentry from '@sentry/react';

import { DashboardLayoutItem } from '../types/Dashboard.types';
import { DashboardWidgets } from '../types/Dashboard.widgets';

const useUpdateDashboard = () => {
  const { userProfile, setUserSettings } = UserStore.useUserStore;

  const updateDashboardLayout = useCallback(
    async (newLayout: DashboardLayoutItem) => {
      try {
        const newDashboardSettings: DashboardSettings = {
          ...userProfile?.userSettings.dashboardSettings,
          layout: {
            layoutKey: newLayout.key,
            metadata: userProfile?.userSettings.dashboardSettings?.layout?.metadata || {},
          },
        };

        await api.endpoints.updateUserSettings({
          data: {
            data: {
              dashboardSettings: newDashboardSettings,
            },
          },
        });

        if (userProfile) {
          setUserSettings({
            ...userProfile?.userSettings,
            dashboardSettings: newDashboardSettings,
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [setUserSettings, userProfile],
  );

  const updateDashboardLayoutWidget = useCallback(
    async (widgetId: DashboardWidgets, metadata: any) => {
      try {
        let existingMetadata = userProfile?.userSettings.dashboardSettings?.layout?.metadata[widgetId] || {};
        existingMetadata = { ...existingMetadata, ...metadata };

        const newDashboardSettings: DashboardSettings = {
          ...userProfile?.userSettings.dashboardSettings,
          layout: {
            layoutKey: userProfile?.userSettings.dashboardSettings?.layout?.layoutKey || undefined,
            metadata: {
              ...userProfile?.userSettings.dashboardSettings?.layout?.metadata,
              [widgetId]: existingMetadata,
            },
          },
        };

        await api.endpoints.updateUserSettings({
          data: {
            data: {
              dashboardSettings: newDashboardSettings,
            },
          },
        });

        if (userProfile) {
          setUserSettings({
            ...userProfile?.userSettings,
            dashboardSettings: newDashboardSettings,
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [setUserSettings, userProfile],
  );

  return {
    updateDashboardLayout,
    updateDashboardLayoutWidget,
  };
};

export { useUpdateDashboard };

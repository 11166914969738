/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import SvgIconMUI, {SvgIconProps} from '@mui/material/SvgIcon';
import {styled} from '@mui/material/styles';

export const StyledIcon = styled(SvgIconMUI)`
  &.MuiSvgIcon-root {
    fill: none !important;
    width: ${({width}) => width || '1em'};
    height: ${({height}) => height || '1em'};
  }
`;

const Icon: React.FC<SvgIconProps> = ({children, ...props}) => <StyledIcon {...props}>{children}</StyledIcon>;

export {Icon};

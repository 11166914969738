import { DarkModeEnum } from '@shared/enums';
import { runWithTransitionsDisabled } from '@shared/utils/lib/stylesheet';

import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type ThemeState = {
  theme: 'default';
  darkMode: DarkModeEnum;
  setDarkMode: (mode?: DarkModeEnum) => void;
};

const useThemeStore = create<ThemeState>()(
  persist(
    (set) => ({
      theme: 'default',
      darkMode: DarkModeEnum.LIGHT,
      systemTheme: DarkModeEnum.LIGHT,

      setDarkMode: (mode: DarkModeEnum = DarkModeEnum.LIGHT) => {
        runWithTransitionsDisabled(() => {
          set({ darkMode: mode !== DarkModeEnum.AUTO ? mode : DarkModeEnum.LIGHT });
          const htmlElement = document.documentElement;
          htmlElement.classList.remove('dark', 'light', 'midnight');
          htmlElement.classList.add(mode);
        });
      },
    }),
    {
      name: 'theme-store',
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        if (state?.darkMode) {
          const htmlElement = document.documentElement;
          htmlElement.classList.remove('dark', 'light', 'midnight');
          htmlElement.classList.add(state.darkMode);
        }
      },
    },
  ),
);

export { useThemeStore };

import React, { useMemo } from 'react';

import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart';
import { DonutChartItem } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart.types';
import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { usePortfolioValue } from 'src/lib/portfolio/hooks';

type Props = {
  asset: Asset;
  countryAsset: Asset;
  value: Big;
};

const PortfolioTableAllocation: React.FC<Props> = ({ asset, countryAsset, value }) => {
  const { externalMutations, setExternalMutations } = useDonutChartMutations();
  const { portfolioValue, getAllocationPercentage } = usePortfolioValue({
    valueAsset: countryAsset,
    side: 'midPrice',
  });

  const chartData = useMemo(
    (): DonutChartItem[] => [
      {
        y: value.toNumber(),
        color: asset.color,
        title: '',
      },
      {
        y: portfolioValue.minus(value).toNumber(),
        color: '',
        title: '',
      },
    ],
    [asset.color, portfolioValue, value],
  );

  const allocation = useMemo(() => getAllocationPercentage(value), [getAllocationPercentage, value]);

  const allocationText = useMemo(
    () => (allocation.eq(0) ? '0%' : `${allocation.gt(1) ? allocation.toFixed(0) : '<1'}%`),
    [allocation],
  );

  return (
    <div className='h-34 w-34'>
      <DonutChart
        id={asset.code}
        innerRadius={164}
        showTooltip={false}
        data={chartData}
        cornerRadius={0}
        gap={0}
        loading={allocation.eq(0)}
        externalMutations={externalMutations}
        setExternalMutations={setExternalMutations}
      >
        <Numeric size='xxsmall' weight='emphasis' className='select-none text-center'>
          {allocationText}
        </Numeric>
      </DonutChart>
    </div>
  );
};

export { PortfolioTableAllocation };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Asset } from '@shared/api';
import { DEFAULT_EMPTY_VALUE } from '@shared/constants/general';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

type Props = {
  triggerType: TriggerType;
  feePercentage?: string;
  triggerPrice: string;
  estimatedFee: string;
  estimatedProfitPercentage: string;
  estimatedProfitDollars: string;
  estimatedTotal?: string;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  showDivider?: boolean;
  addPadding?: boolean;
  validOrder?: boolean;
};

const ProfitStopLossSummary: React.FC<Props> = ({
  triggerType,
  triggerPrice,
  feePercentage,
  estimatedFee,
  estimatedProfitPercentage,
  estimatedProfitDollars,
  primaryAsset,
  estimatedTotal,
  secondaryAsset,
  showDivider,
  addPadding,
  validOrder = true,
}) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  const isTakeProfit = useMemo(() => triggerType === TriggerType.TakeProfit, [triggerType]);

  return (
    <>
      {showDivider && <Divider variant='fullWidth' orientation='horizontal' />}
      <Stack paddingX={addPadding ? 2 : 0} spacing={1}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}>
          <Typography fontSize={14} color='text.secondary'>
            {t('labels.triggerPrice')}
          </Typography>
          <Typography fontSize={14} number color='text.primary'>
            {triggerPrice && validOrder
              ? `${formatCurrency(triggerPrice, undefined, { hideCode: true, appendCode: false })} ${
                  primaryAsset?.code
                }/${secondaryAsset?.code}`
              : DEFAULT_EMPTY_VALUE}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}>
          <Typography fontSize={14} color='text.secondary'>
            {t('labels.estimatedFees')} ({Big(feePercentage).times(100).toString()}%)
          </Typography>
          <Typography fontSize={14} number color='text.primary'>
            {estimatedFee && validOrder
              ? `~${formatCurrency(estimatedFee, primaryAsset)} ${primaryAsset?.code}`
              : DEFAULT_EMPTY_VALUE}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}>
          <Typography fontSize={14} color='text.secondary'>
            {t(isTakeProfit ? 'labels.estimatedProfit' : 'labels.estimatedLoss', { symbol: '%' })}
          </Typography>
          <Typography fontSize={16} fontWeight={500} number color={isTakeProfit ? 'success.main' : 'error.main'}>
            {estimatedProfitPercentage && validOrder ? `~${estimatedProfitPercentage}` : DEFAULT_EMPTY_VALUE}%
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}>
          <Typography fontSize={14} color='text.secondary'>
            {t(isTakeProfit ? 'labels.estimatedProfit' : 'labels.estimatedLoss', { symbol: '$' })}
          </Typography>
          <Typography fontSize={16} fontWeight={500} number color={isTakeProfit ? 'success.main' : 'error.main'}>
            {estimatedProfitDollars && validOrder
              ? `~${formatCurrency(estimatedProfitDollars, primaryAsset)} ${primaryAsset?.code}`
              : DEFAULT_EMPTY_VALUE}
          </Typography>
        </Stack>

        {estimatedTotal && (
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}>
            <Typography fontSize={16} fontWeight={500} color='text.primary'>
              {t('labels.estimatedTotal')}
            </Typography>
            <Typography fontSize={18} fontWeight={500} number color='text.primary'>
              ~{formatCurrency(estimatedTotal, primaryAsset)}
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export { ProfitStopLossSummary };

import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

const stopLossEntryTour: TourGuideData = {
  name: 'Take Profit / Stop Loss Entry Tour',
  description: "Guides you through the 'Take Profit / Stop Loss' process from a market buy trade",
  steps: [
    {
      title: 'Did you know you can set automated trades?',
      description:
        'Discover new ways to trade to ensure you are minimising risk and realising profits. Swyftx offers trigger type orders that automatically execute instant orders based on specific conditions.',
      spotlightElementId: 'setTakeProfitStopLoss.button',
      alignment: 'left',
    },
  ],
};

export { stopLossEntryTour };

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, useTheme } from '@mui/material';
import { darken } from '@mui/system';

import { Chart } from '@swyftx/aviary/icons/outlined';
import { Button, Stack } from '@swyftx/react-web-design-system';

import { NoHistoryChart } from '@global-components/ComingSoonChart/variants/NoHistoryChart';
import { NoChartError } from '@global-components/Errors/NoChartError';
import {
  PerformanceMenuControlEnum,
  performanceMenuControls,
} from '@global-components/PerformanceMenu/PerformanceMenu.const';
import { PerformanceMenuControl } from '@global-components/PerformanceMenu/PerformanceMenu.types';
import { LineChart, LineChartToolTip } from '@global-components/charts/LineChart';
import {
  LineChartOverlay,
  LineChartTooltipData,
  LineChartTooltipPosition,
} from '@global-components/charts/LineChart/LineChart.types';
import {
  TimeOverlayMap,
  usePortfolioAssetLineOverlayData,
  usePortfolioLineChartData,
} from '@global-components/charts/LineChart/hooks/portfolioLineDataHooks';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { AppStore } from '@shared/store';
import { formatDate } from '@shared/utils';

import { useOnEntityChanged } from '@hooks/Entity/useOnEntityChange';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useCostBasis } from '@hooks/Portfolio';
import { AssetColors } from '@utils/assets';
import { generateKey } from '@utils/id';

import { observer } from 'mobx-react-lite';
import { useSize, useToggle } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { PortfolioPerformanceChartMenu } from './PortfolioPerformanceChartMenu';
import { PortfolioPerformanceDropdown } from './PortfolioPerformanceDropdown';

type Props = {
  selectedItem?: number | null;
};

const MIN_CHART_HEIGHT = 175;

export const isOverlayDefined = (overlay: LineChartOverlay | undefined): overlay is LineChartOverlay =>
  (overlay as LineChartOverlay) !== undefined;

const endDate = new Date();

const PortfolioPerformanceLineChart: React.FC<Props> = observer(({ selectedItem }) => {
  const [tooltipInfo, setTooltipInfo] = useState<{
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  }>({ position: null, data: null });

  const [selectedControl, setSelectedControl] = useState<PerformanceMenuControl>(
    performanceMenuControls[PerformanceMenuControlEnum.OneYear],
  );

  const [costBasis, toggleCostBasis] = useToggle(false);

  const [selectedAssets, setSelectedAssets] = useState<Map<string, Asset>>(new Map());
  const [reloadTrigger, setReloadTrigger] = useState(generateKey()); // causes line refresh

  const [containerSized, { width: containerWidth }] = useSize(
    <Box width='100%' height='100%' sx={{ position: 'absolute !important', pointerEvents: 'none' }} />,
  );

  const [chartSized, { height: chartHeight }] = useSize(
    <Box width='100%' height='100%' sx={{ position: 'absolute !important', pointerEvents: 'none' }} />,
  );

  const costBasisWidth = useCallback(() => containerWidth > 600, [containerWidth]);

  const {
    down: { md: showCompact },
  } = useContentBreakpoint();
  const { isDemo } = AppStore.useAppStore;
  const theme = useTheme();
  const { getAsset } = assetService;
  const { t } = useTranslation('dashboard');
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const costBasisEnabled = isFeatureEnabled(AppFeature.CostBasis);

  const bgColor = theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[600];

  useOnEntityChanged(() => setReloadTrigger(generateKey()));

  const startDate = useMemo(
    () => (selectedControl.startTime ? new Date(selectedControl.startTime) : null),
    [selectedControl],
  );

  const { noDataYet, formattedData, loading, error } = usePortfolioLineChartData(
    startDate,
    endDate,
    selectedItem || undefined,
    reloadTrigger,
  );

  const { data: useCostBasisData } = useCostBasis(formattedData);

  const costBasisData = costBasis ? useCostBasisData : undefined;

  const selectedAssetArray: Array<Asset> = useMemo(() => Array.from(selectedAssets.values()), [selectedAssets]);

  const { filteredOverlayDataMap } = usePortfolioAssetLineOverlayData(
    selectedAssetArray,
    startDate,
    endDate,
    reloadTrigger,
  );

  const overlayData: Array<LineChartOverlay> = useMemo(
    () =>
      Object.entries<TimeOverlayMap>(filteredOverlayDataMap)
        .map(([assetId, data]) => {
          const asset = getAsset(parseInt(assetId, 10));
          if (asset && formattedData) {
            const arrayData = Object.values(data);
            const misMatchedData = formattedData.length > arrayData.length;

            const formatted: LineChartOverlay = {
              color: AssetColors[asset.code] || theme.palette.grey[300],
              data: misMatchedData
                ? formattedData.map(({ time: overviewTime }) => {
                    const overviewTimeKey = formatDate(overviewTime);
                    const overlayDatum = data?.[overviewTimeKey];
                    return (
                      overlayDatum || {
                        time: overviewTime,
                        value: 0,
                      }
                    );
                  })
                : Object.values(data),
              variant: 'dashed',
              id: asset.id,
            };
            return formatted;
          }
          return undefined;
        })
        .filter(isOverlayDefined),
    [formattedData, getAsset, filteredOverlayDataMap, theme],
  );

  const tooltipCallback = useCallback(
    (tooltipPosition: LineChartTooltipPosition | null, tooltipData: LineChartTooltipData | null) => {
      setTooltipInfo({ data: tooltipData, position: tooltipPosition });
    },
    [],
  );

  const asset = getAsset(selectedItem || -1);

  const chartColor =
    selectedItem === null || !asset || !AssetColors?.[asset.code]
      ? theme.palette.primary.main
      : AssetColors[asset.code];

  const lineChart = useMemo(() => {
    const chartBgColor = theme.palette.background.paper;
    const isLoading = containerWidth === Infinity || loading;

    if (noDataYet) {
      return <NoHistoryChart height={chartHeight} width={containerWidth} bgColor={chartBgColor} />;
    }

    if (formattedData?.length || isLoading) {
      return (
        <LineChart
          idKey='dashboard-portfolio-title-performance-chart'
          tooltipCallback={tooltipCallback}
          chartData={formattedData || []}
          backgroundColor={chartBgColor}
          overlays={overlayData}
          costBasis={costBasisData}
          areaColor={chartColor}
          width={containerWidth}
          height={chartHeight}
          loading={isLoading}
          removeYAxisPadding
          showAxes
        />
      );
    }

    if (error) {
      return (
        <NoChartError
          containerProps={{ width: containerWidth, height: chartHeight }}
          onTryAgain={() => setReloadTrigger(generateKey())}
        />
      );
    }

    return null;
  }, [
    theme.palette.background.paper,
    containerWidth,
    loading,
    noDataYet,
    formattedData,
    error,
    chartHeight,
    tooltipCallback,
    overlayData,
    costBasisData,
    chartColor,
  ]);

  const getControls = () => (
    <PortfolioPerformanceChartMenu
      setSelectedControl={setSelectedControl}
      disabled={Boolean(noDataYet || error)}
      selectedControl={selectedControl}
      noPadding
    />
  );

  if (isDemo) {
    return <NoChartError containerProps={{ minHeight: chartHeight }} reason='isPortfolioAndDemoMode' />;
  }

  return (
    <Box position='relative' width='100%' height='100%'>
      {containerSized}
      <LineChartToolTip tooltipInfo={tooltipInfo} width={containerWidth} selectedAssets={selectedAssets} />
      <Stack spacing={2} height='100%' width='100%'>
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <Stack direction='row' spacing={1}>
            <PortfolioPerformanceDropdown
              setSelectedAssets={setSelectedAssets}
              selectedAssets={selectedAssets}
              fullWidth={showCompact}
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1}>
            {costBasisEnabled && (
              <Stack>
                {costBasisWidth() ? (
                  <Button
                    variant='contained'
                    size='small'
                    onClick={toggleCostBasis}
                    color='inherit'
                    sx={{
                      backgroundColor: bgColor,
                      borderRadius: 1,
                      transition: '0.1s',
                      height: '100%',
                      '&:hover': {
                        backgroundColor: darken(theme.palette.action.hover, 0.1),
                      },
                    }}
                  >
                    {costBasis ? t('portfolioTile.costBasis.disable') : t('portfolioTile.costBasis.enable')}
                  </Button>
                ) : (
                  <IconButton onClick={toggleCostBasis} color='inherit'>
                    <Chart />
                  </IconButton>
                )}
              </Stack>
            )}
            {!showCompact && getControls()}
          </Stack>
        </Stack>
        <Box position='relative' display='flex' width='100%' flex={1} minHeight={`${MIN_CHART_HEIGHT}px`}>
          {chartSized}
          {lineChart}
        </Box>
        {showCompact && getControls()}
      </Stack>
    </Box>
  );
});

PortfolioPerformanceLineChart.displayName = 'PortfolioPerformanceLineChart';

export { PortfolioPerformanceLineChart };

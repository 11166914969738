import React from 'react';

import { CrossInCircleFilled, MinusInCircleFilled, TickInCircleFilled } from '@swyftx/aviary/icons/filled';

import { MessageTile } from 'src/shared/api';

type MessageCards = {
  [key: string]: MessageTile;
};

// NOTE: the number strings are message IDs (logics transferred across from angular)
export const defaultMessages: MessageCards = {
  '1': {
    title: 'Payment Successful',
    content: `Congratulations! Your POLi deposit has been successful and will be processed shortly. Please
    close this window now and wait up-to 30 seconds. Note: Your withdrawal limit has been capped until we can verify receipt of your funds (usually
    same business day). All other account functions are enabled.
    POLi deposits execute a transfer to a generic account number which differs from your individual Swyftx deposit account
    number. Please remove these details from your internet banking portal, as if used again for any further transfers it
    will not credit the deposit to your account automatically.`,
    icon: <TickInCircleFilled className='h-[40px] w-[40px] text-color-text-success' />,
  },
  '2': {
    title: 'Something Went Wrong.',
    content: `Uh oh. We're not sure what happened there, sorry! Before trying again, please login to your
    bank's internet banking to check that no funds were transferred. Note: If you've already tried again with POLi and been unable to
    complete a transfer, please use a different instant deposit method such as PayID.`,
    icon: <CrossInCircleFilled className='h-[40px] w-[40px] text-color-text-error' />,
  },
  '3': {
    title: 'Payment Cancelled',
    content: `Uh oh. Looks like your POLi deposit was cancelled. Please try again, or use a different deposit
    method.`,
    icon: <CrossInCircleFilled className='h-[40px] w-[40px] text-color-text-error' />,
  },
  '11': {
    title: 'Cancelled',
    content: `You cancelled your order! You can close this window to return to the platform.`,
    icon: <TickInCircleFilled className='h-[40px] w-[40px] text-color-text-success' />,
  },
  '12': {
    title: 'Failed',
    content: `Your order has failed! Please close this window and try again shortly or contact our support.`,
    icon: <CrossInCircleFilled className='h-[40px] w-[40px] text-color-text-error' />,
  },
  '13': {
    title: 'Payment successful',
    content: `Your payment is being processed, you can close this window now and return to the platform.`,
    icon: <TickInCircleFilled className='h-[40px] w-[40px] text-color-text-success' />,
  },
  '14': {
    title: 'Verification Documents Submitted',
    content: `Your verification is being processed, you can close this window now and return to the platform.`,
    icon: <TickInCircleFilled className='h-[40px] w-[40px] text-color-text-success' />,
  },
  '15': {
    title: 'Verification Document Submission Failure',
    content: `Something went wrong with your verification document submission. You can close this window and go
    back to the platform to try again.`,
    icon: <CrossInCircleFilled className='h-[40px] w-[40px] text-color-text-error' />,
  },
  '16': {
    title: 'Banxa Loading',
    content: `Banxa is currently loading, please wait a few moments.`,
    icon: <MinusInCircleFilled className='h-[40px] w-[40px] text-color-text-accent' />,
  },
};

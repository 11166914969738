import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';

import { Links } from '@shared/constants';
import { SwyftxError } from '@shared/error-handler';
import { UIStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { useIntercom } from 'react-use-intercom';
import { ModalName } from 'src/context/Avo/generated-avo';

export type WithdrawStaus = 'scam' | 'blacklist' | 'ok';

const useCheckWithdrawalAddress = () => {
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('wallet');
  const isXs = useTailwindBreakpoint('xs');
  const { showNewMessages } = useIntercom();
  const { pathname } = useLocation();
  const avo = useAvo();

  const showAddressBreachMessage = useCallback(
    (address: string, error: SwyftxError): WithdrawStaus => {
      const message = error.errorMessage;

      if (message.includes('Blacklisted')) {
        avo.modalOpened({ modalName: ModalName.WITHDRAWAL_ADDRESS_BLOCKED_POPUP, screen: pathname });
        addMessageBox({
          anchorOrigin: {
            vertical: isXs ? 'top' : 'bottom',
            horizontal: 'center',
          },
          content: (
            <ErrorMessageBox
              key={address}
              title={t('cryptoWithdrawalAcknowledgement.violatedAddressTitle')}
              content={
                <FlexLayout direction='column' spacing={16} className='max-w-[450px]'>
                  <Body size='small' color='secondary'>
                    {t('cryptoWithdrawalAcknowledgement.violatedAddress')}{' '}
                    <BodyLink
                      color='accent'
                      size='small'
                      className='cursor-pointer hover:underline'
                      href={Links.legal.tos}
                    >
                      {t('cryptoWithdrawalAcknowledgement.termsLink')}
                    </BodyLink>{' '}
                    {t('cryptoWithdrawalAcknowledgement.violatedAddressAttempts')}
                  </Body>
                </FlexLayout>
              }
            />
          ),
        });

        return 'blacklist';
      } else if (message.includes('scam')) {
        avo.modalOpened({ modalName: ModalName.WITHDRAWAL_ADDRESS_BLOCKED_POPUP, screen: pathname });
        addMessageBox({
          anchorOrigin: {
            vertical: isXs ? 'top' : 'bottom',
            horizontal: 'center',
          },
          content: (
            <ErrorMessageBox
              key={address}
              title={t('cryptoWithdrawalAcknowledgement.scamAddressTitle')}
              content={
                <FlexLayout direction='column' spacing={16} className='max-w-[450px]'>
                  <Body size='small' color='secondary'>
                    {t('cryptoWithdrawalAcknowledgement.scamAddress')}
                  </Body>
                  <FlexLayout className='cursor-pointer hover:underline' alignItems='center' spacing={4}>
                    <BodyLink color='accent' weight='emphasis' size='small' onClick={() => showNewMessages()}>
                      {t('cryptoWithdrawalAcknowledgement.contactSupport')}
                    </BodyLink>
                    <ArrowLineOnlyRight className='h-16 w-16 text-color-text-accent' />
                  </FlexLayout>
                </FlexLayout>
              }
            />
          ),
        });

        return 'scam';
      }

      return 'ok';
    },
    [addMessageBox, isXs, showNewMessages, t],
  );

  return {
    showAddressBreachMessage,
  };
};

export { useCheckWithdrawalAddress };

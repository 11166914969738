import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

interface Props {
  title: string;
  subtitle?: string;
}

export const TitleText: React.FC<Props> = (props) => {
  const { title, subtitle } = props;

  return (
    <FlexLayout
      direction='column'
      className='mb-32 flex items-center justify-center self-center text-center'
      spacing={8}
    >
      <Heading size='h3'>{title}</Heading>
      {subtitle && <Body color='secondary'>{subtitle}</Body>}
    </FlexLayout>
  );
};

import { useCallback } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

export const useGetDailyMovement = () => {
  const { getRate } = RatesStore.useRatesStore;
  const getDailyMovement = useCallback(
    (asset: Asset) => {
      const rate = getRate(asset.id);
      return Big(rate?.dailyPriceChange).toNumber();
    },
    [getRate],
  );

  return { getDailyMovement };
};

import { useCallback } from 'react';

import { SignUpMetaParams, UTM_TAGS } from '@shared/api';

const useMarketingTags = () => {
  const extractFromParams = useCallback((searchParams: URLSearchParams) => {
    UTM_TAGS.forEach((tag) => {
      const value = searchParams.get(tag);
      if (value !== null) {
        localStorage.setItem(tag, value);
      }
    });
  }, []);

  const getMarketingTags = () => {
    const tags = UTM_TAGS.reduce<SignUpMetaParams | undefined>((acc, tag) => {
      const value = localStorage.getItem(tag);

      if (!value) return acc;

      if (acc !== undefined) {
        return { ...acc, [tag]: value };
      }

      return { [tag]: value };
    }, undefined);

    return tags;
  };

  return {
    extractFromParams,
    getMarketingTags,
  };
};

export { useMarketingTags };

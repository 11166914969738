import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { List } from '@swyftx/aviary/atoms/List/List';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Add, Refresh, Wallet } from '@swyftx/aviary/icons/outlined';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset, AssetType } from '@shared/api';
import { WithdrawalAddress } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useFetchAssetAddresses } from 'src/lib/assets/hooks/useFetchAssetAddresses';
import { useFetchAssetAddressesCache } from 'src/lib/assets/hooks/useFetchAssetAddressesCache';

import { TransferModalStep } from '../TransferModal.types';

type Props = {
  asset?: Asset;
  onClose: () => void;
  onOpenAddress: (address: WithdrawalAddress) => void;
};

const ManageAccountsStep: React.FC<Props> = ({ asset, onClose, onOpenAddress }) => {
  const isFiat = useMemo(() => asset?.assetType === AssetType.Fiat, [asset]);
  const { isFetching, data } = useFetchAssetAddresses({ asset });
  const { invalidateCache } = useFetchAssetAddressesCache();
  const { t } = useTranslation('wallet');
  const { onNextStep } = useContext(StepModalContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const getSecondaryLabel = (address: WithdrawalAddress) => {
    const details: string[] = [address.address_details.address];

    if (address.metadata?.name && address.metadata?.data) {
      details.push(`${address.metadata.name}: ${address.metadata.data}`);
    }

    if (address.address_details.network) {
      details.push(`${address.address_details.network} Network`);
    }

    return details.join(' • ');
  };

  if (!asset) return null;

  return (
    <StepModal.Step onClose={onClose} hideActions title={`Manage ${asset.code} addresses`}>
      <FlexLayout direction='column' spacing={8} className='px-16 pb-16 sm:p-24 sm:pb-24'>
        <Body className='pr-16' color='secondary'>
          Select an address below to review details or to remove it from your profile. Note: You will not be able to
          withdraw to an address until it has been verified.
        </Body>

        <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
          <Body size='large' weight='bold'>
            {isFiat ? 'Accounts' : 'Addresses'}
          </Body>

          {asset && (
            <Button
              variant='outlined'
              leadingIcon={<Refresh />}
              size='sm'
              loading={isFetching}
              color='subtle'
              onClick={invalidateCache}
            >
              {t('manageAccounts.refreshAddresses')}
            </Button>
          )}
        </FlexLayout>
        <Separator className='w-full' />

        <List className='max-h-[250px] py-8' spacing={8}>
          {data === undefined && (
            <>
              <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
              <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
              <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
              <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
              <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
              <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
            </>
          )}
          {data?.map((address: WithdrawalAddress) => (
            <ListItem
              key={address.id}
              onClick={() => {
                onOpenAddress(address);
              }}
              className='w-full'
            >
              <FlexLayout
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                spacing={16}
                className='w-full'
              >
                <FlexLayout
                  direction='row'
                  spacing={16}
                  justifyContent='start'
                  alignItems='center'
                  className={cn(address.verified ? 'w-full' : 'w-[calc(100%-100px)]')}
                >
                  <FlexLayout
                    className='h-24 w-24 rounded-8 bg-color-background-info-subtle'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Wallet className={cn('h-18 w-18')} />
                  </FlexLayout>

                  <FlexLayout direction='column' alignItems='start' justifyContent='center' className='break-all'>
                    <Body weight='emphasis' color='primary'>
                      {address.label}
                    </Body>
                    <Body color='secondary' size='small' className='w-full break-all text-left'>
                      {getSecondaryLabel(address)}
                    </Body>
                  </FlexLayout>
                </FlexLayout>

                {!address.verified && (
                  <div className='w-[100px]'>
                    <Chip color='warning' size='sm'>
                      Confirm via Email
                    </Chip>
                  </div>
                )}
              </FlexLayout>
            </ListItem>
          ))}
        </List>
        <FlexLayout alignItems='center' justifyContent='end'>
          <Button
            leadingIcon={<Add />}
            onClick={() => {
              if (asset.assetType === AssetType.Fiat || !isFeatureEnabled(AppFeature.CryptoWithdrawalAcknowledgement)) {
                onNextStep(TransferModalStep.AddNewAccount);
              } else {
                onNextStep(TransferModalStep.WithdrawalAcknowledgement, false);
              }
            }}
          >
            Add new address
          </Button>
        </FlexLayout>
      </FlexLayout>
    </StepModal.Step>
  );
};

export { ManageAccountsStep };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@swyftx/react-web-design-system';

import { NoChartError } from '@global-components/Errors/NoChartError';

import { useTheme } from '@hooks/useTheme';

import { LineChart } from '../LineChart';
import { placeholderLineData } from '../mocks/placeholderData';

type Props = {
  chartHeight: number;
  chartWidth: number;
  subTitle?: string;
  bgColor?: string;
  title?: string;
  idKey?: string;
};

export const EmptyLineChart: React.FC<Props> = ({ chartHeight, chartWidth, subTitle, title, bgColor, idKey }) => {
  const { t } = useTranslation('common', { keyPrefix: 'emptyCharts.performance' });
  const defaultTitle = t('title');
  const defaultSubTitle = t('subTitle');
  const { theme, isLightTheme } = useTheme();
  const chartColor = isLightTheme ? theme.palette.grey[200] : theme.palette.grey[700];

  return (
    <Stack>
      <LineChart
        backgroundColor={bgColor || theme.palette.background.paper}
        chartData={placeholderLineData}
        idKey={idKey || 'empty-line-chart'}
        disableLoadingAnimation
        height={chartHeight}
        areaColor={chartColor}
        width={chartWidth}
        disableAreaFill
        strokeWidth={4}
        smoothing
      />
      <NoChartError
        subTitle={subTitle || defaultSubTitle}
        title={title || defaultTitle}
        containerProps={{
          sx: { alignItems: 'flex-start', height: '100px', padding: 3, paddingBottom: 2 },
          spacing: 1,
        }}
        subTitleProps={{ align: 'left' }}
        titleProps={{ align: 'left' }}
      />
    </Stack>
  );
};

import React from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

interface Props {
  tooltipText: string;
  content?: React.ReactNode;
  align?: 'left' | 'right';
}

export const HelpTooltip: React.FC<Props> = (props) => {
  const { tooltipText, content, align } = props;

  return (
    <Tooltip title={tooltipText}>
      <FlexLayout direction='row' spacing={8} className='items-center'>
        <Avatar size='sm' className={cn('h-16 w-16', align === 'right' ? 'order-last' : 'order-first')}>
          <Body className='text-color-text-inverse' size='small'>
            ?
          </Body>
        </Avatar>
        {content}
      </FlexLayout>
    </Tooltip>
  );
};

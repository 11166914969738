import React, { PropsWithChildren } from 'react';

import { types } from '@swyftx/marketing-utils';
import { Card, Stack } from '@swyftx/react-web-design-system';

import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { RewardType } from '@routes/Rewards/types';

import { observer } from 'mobx-react-lite';

import { RewardTabs } from '../RewardTabs';
import { TotalRewardsBreakdown } from '../TotalRewardsBreakdown';
import { WhatIsSwyftxRewards } from '../WhatIsSwyftxRewards';

type Props = {
  rewardType: RewardType;
  setRewardType: React.Dispatch<React.SetStateAction<RewardType>>;
  rewards: { [key in RewardType]: types.UserAssetReward[] };
  showLearnMore?: boolean;
};

const RewardsWrapper: React.FC<PropsWithChildren<Props>> = observer(
  ({ rewardType, setRewardType, rewards, showLearnMore, children }) => {
    const { bx } = useContentBreakpoint();

    return (
      <LayoutGridItem
        sx={{ padding: { xs: 2, sm: 3 }, paddingRight: { xs: 2, sm: 0 } }}
        container
        spacing={2}
        direction={bx({ xs: 'column', xl: 'row' })}
        width='100%'
        marginLeft={{ xs: '-8px', sm: undefined }}
      >
        <LayoutGridItem item xs={12} md={9} order={bx({ xs: 2, xl: 1 })} width='100%'>
          <Card contentSx={{ padding: 0 }} variant='outlined'>
            <RewardTabs rewardType={rewardType} setRewardType={setRewardType} rewards={rewards} />
            <Stack
              direction='column'
              flexWrap='wrap'
              alignItems='flex-start'
              gap={2}
              justifyContent='flex-start'
              padding={2}
            >
              {children}
            </Stack>
          </Card>
        </LayoutGridItem>
        <LayoutGridItem item gap={2} md={3} order={bx({ xs: 1, xl: 2 })}>
          <Stack direction={bx({ xs: 'column', md: 'row', xl: 'column' })} spacing={2}>
            <WhatIsSwyftxRewards showLearnMore={showLearnMore} />
            <TotalRewardsBreakdown />
          </Stack>
        </LayoutGridItem>
      </LayoutGridItem>
    );
  },
);

export { RewardsWrapper };

import { CallListenerType, EmitOptions } from './EmitOptions';
import { Listener } from './Listener';

export default class EventListener {
  listeners: Listener[] = [];

  lastEvent: any | undefined;

  addListener(fn: (...args: any[]) => void) {
    // create new listener
    const listener = new Listener(this, fn);
    // add function to listener array
    this.listeners.push(listener);
    return listener;
  }

  removeListener(fn: (...args: any[]) => void) {
    // remove function from listeners array
    for (let i = 0; i < this.listeners.length; i++) {
      if (this.listeners[i].fn === fn) {
        this.listeners.splice(i--, 1);
      }
    }
  }

  emit(args: any, opts?: EmitOptions) {
    // save last event to replay if required
    this.lastEvent = args;

    if (opts?.callListeners === CallListenerType.First) {
      // only call first listener
      this.listeners[0].fn(args);
    } else if (opts?.callListeners === CallListenerType.Last) {
      // only call last listener
      this.listeners[this.listeners.length - 1].fn(args);
    } else {
      // call all listeners
      for (let i = 0; i < this.listeners.length; i++) {
        this.listeners[i].fn(args);
      }
    }
  }
}

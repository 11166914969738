import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

type Props = {
  showLearnMore?: boolean;
};

type StepPreviewProps = {
  icon: any;
  title: string;
  isComplete: boolean;
  showVerified?: boolean;
  description?: string;
};

const StepPreview = ({ icon: Icon, title, description, isComplete, showVerified }: StepPreviewProps) => {
  const showDescription = description != null && !isComplete;

  return (
    <FlexLayout direction='row' alignItems={showDescription ? 'start' : 'center'} spacing={32}>
      {Icon}
      <FlexLayout direction='row' spacing={4}>
        <FlexLayout direction='column' spacing={4}>
          <Body weight='emphasis'>{title}</Body>
          {showDescription && (
            <Body size='small' color='secondary'>
              {description}
            </Body>
          )}
        </FlexLayout>
        {isComplete && showVerified && (
          <Chip color='success' size='sm' variant='subtle'>
            Verified
          </Chip>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

const WhatIsSwyftxPortfolioTransfer: React.FC<Props> = observer(() => (
  <Card className='h-full p-16'>
    <FlexLayout direction='column' spacing={24}>
      <Heading size='h4'>How it works</Heading>
      <StepPreview
        icon={<Image image='exchange' usePalette className='h-64 w-64' />}
        title='You select the exchange'
        description='Select which exchange you would like to transfer your assets from.'
        isComplete={false}
      />
      <StepPreview
        icon={<Image image='api_keys' usePalette className='h-64 w-64' />}
        title='You add your API key & select assets'
        description='You generate an API Key on the selected exchange, then select which assets to transfer.'
        isComplete={false}
      />
      <StepPreview
        icon={<Image image='transfers' usePalette className='h-64 w-64' />}
        title="We'll initiate the transfer"
        description='We use your API Key to initiate the transfer of all selected assets. We will never store API Key information.'
        isComplete={false}
      />
      <StepPreview
        icon={<Image image='transfer_fees' usePalette={false} className='h-64 w-64' />}
        title="We'll cover the transfer fees"
        description="We'll cover transfer fees for every asset on your first transfer per exchange, per day."
        isComplete={false}
      />
      <StepPreview
        icon={<Image image='email_notifications' usePalette className='h-64 w-64' />}
        title="We'll notify you once assets are transferred"
        description="Keep an eye on your inbox. We'll send you an email once your portfolio transfer is complete."
        isComplete={false}
      />
    </FlexLayout>
  </Card>
));

export { WhatIsSwyftxPortfolioTransfer };

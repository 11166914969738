import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Utility } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

import { HIDE_CHART_WIDTH, HIDE_MONTH_WIDTH, HIDE_WEEK_WIDTH } from './AssetRow.consts';
import { AssetRowHeaderItem } from './AssetRowHeaderItem';

export type AssetRowSortBy = 'rank' | 'dailyChange' | 'weeklyChange' | 'monthlyChange' | 'id';
export type AssetRowSortDirection = 'asc' | 'desc';

export type AssetRowSort = {
  sort: AssetRowSortBy;
  direction: AssetRowSortDirection;
};

type Props = {
  width: number;
  isFavourite?: boolean;
  isAppHeader?: boolean;
  sortingKeys?: AssetRowSortBy[];
  sort?: AssetRowSort;
  onSort?: (sort: AssetRowSort) => void;
};

const AssetRowHeader: React.FC<Props> = observer(({ width, sortingKeys, isAppHeader, sort, onSort }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'moversTile' });

  const hideRank = width < HIDE_MONTH_WIDTH;
  const hideMonth = width < HIDE_MONTH_WIDTH;
  const hideWeek = width < HIDE_WEEK_WIDTH;
  const hideChart = width < HIDE_CHART_WIDTH;

  const onSortRow = useCallback(
    (newSortKey: AssetRowSortBy, startingDirection: AssetRowSortDirection = 'desc') => {
      if (!onSort) return;

      const oppositeDirection = sort?.direction === 'asc' ? 'desc' : 'asc';

      const direction = sort?.sort === newSortKey ? oppositeDirection : startingDirection;

      if (onSort) onSort({ sort: newSortKey, direction });
    },
    [onSort, sort?.direction, sort?.sort],
  );

  return (
    <FlexLayout
      alignItems='center'
      justifyContent='space-between'
      className='w-full border-b border-color-border-main px-16 py-8 @container'
    >
      <FlexLayout spacing={0} className='@xs:w-[70%] @sm:w-[calc(50%+10px)]' justifyContent='start'>
        {!hideRank && (
          <AssetRowHeaderItem
            sortable={sortingKeys?.includes('rank')}
            startingDirection='asc'
            className='min-w-[60px]'
            justifyContent='start'
            onClick={() => onSortRow('rank', 'asc')}
            sorting={sort?.sort === 'rank' ? sort.direction : undefined}
          >
            <Utility color='secondary' className='text-left'>
              {t('labels.rank')}
            </Utility>
          </AssetRowHeaderItem>
        )}

        <FlexLayout className={`min-w-[150px] ${isAppHeader ? 'w-full' : 'w-[150px]'} `} direction='row' spacing={8}>
          <Utility color='secondary' className='text-left'>
            {t('labels.asset')}
          </Utility>
        </FlexLayout>
        <Utility color='secondary' className='text-left'>
          {t('labels.price')}
        </Utility>
      </FlexLayout>

      <AssetRowHeaderItem
        sortable={sortingKeys?.includes('dailyChange')}
        className='min-w-[80px] pr-8'
        justifyContent='end'
        onClick={() => onSortRow('dailyChange')}
        sorting={sort?.sort === 'dailyChange' ? sort.direction : undefined}
      >
        <Utility color='secondary' className='text-right'>
          {t('labels.24h')}
        </Utility>
      </AssetRowHeaderItem>

      {!hideWeek && (
        <AssetRowHeaderItem
          sortable={sortingKeys?.includes('weeklyChange')}
          className='min-w-[80px] pr-8'
          justifyContent='end'
          onClick={() => onSortRow('weeklyChange')}
          sorting={sort?.sort === 'weeklyChange' ? sort.direction : undefined}
        >
          <Utility color='secondary' className='text-right'>
            {t('labels.1W')}
          </Utility>
        </AssetRowHeaderItem>
      )}

      {!hideMonth && (
        <AssetRowHeaderItem
          sortable={sortingKeys?.includes('monthlyChange')}
          className='min-w-[80px] pr-8'
          justifyContent='end'
          onClick={() => onSortRow('monthlyChange')}
          sorting={sort?.sort === 'monthlyChange' ? sort.direction : undefined}
        >
          <Utility color='secondary' className='text-right'>
            {t('labels.1M')}
          </Utility>
        </AssetRowHeaderItem>
      )}
      {!hideChart && (
        <FlexLayout className='min-w-[80px]' justifyContent='end'>
          <Utility color='secondary' className='text-right'>
            {t('labels.chart')}
          </Utility>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export { AssetRowHeader };

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Heart } from '@swyftx/aviary/icons/outlined';

import { AppHeaderHelp } from '@global-components/AppHeader/AppHeaderHelp';
import { AppHeaderProfile } from '@global-components/AppHeader/AppHeaderProfile';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import env from '@shared/config';
import { UserStore } from '@shared/store';
import { formatCurrency, getValuePriceScale } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';

import { observer } from 'mobx-react-lite';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';
import { SwyftxProLogo } from 'src/lib/trade-pro/components';

import { SwyftxProAppHeaderSettings } from './SwyftxProAppHeaderSettings';
import { SwyftxProAppNavigation } from './SwyftxProAppNavigation';

const SwyftxProAppHeader: React.FC = observer(() => {
  const { openModal } = useModal();
  const { portfolio } = usePortfolio({ balanceKey: 'all' });
  const baseAsset = useBaseAsset();
  const { getBalance } = usePortfolioBalance();
  const usersnapApi = useUsersnapApi();
  const { userProfile } = UserStore.useUserStore;

  const portfolioValue = formatCurrency(portfolio.accountValue, baseAsset, {
    priceScale: getValuePriceScale(baseAsset),
    appendCode: true,
    hideCode: false,
  });

  const openUserSnap = useCallback(() => {
    usersnapApi?.on('open', (event) => event.api.setValue('visitor', userProfile?.email));
    usersnapApi?.show(env.USERSNAP_PROJECT_ID_PRO).then((widgetApi) => widgetApi.open({}));
  }, [userProfile?.email, usersnapApi]);

  return (
    <FlexLayout
      className='h-56 w-full bg-color-background-surface-primary px-16'
      alignItems='center'
      justifyContent='space-between'
    >
      <FlexLayout
        alignItems='center'
        spacing={16}
        className='w-full items-center justify-between md:w-auto md:justify-start'
      >
        <FlexLayout alignItems='center' spacing={16}>
          <SwyftxProAppNavigation />
          <SwyftxProLogo />
        </FlexLayout>
        <Button leadingIcon={<Heart />} variant='outlined' size='sm' onClick={openUserSnap}>
          Have feedback?
        </Button>
      </FlexLayout>
      <FlexLayout alignItems='center' spacing={12} className='hidden md:flex'>
        <FlexLayout direction='column' alignItems='end' justifyContent='center'>
          <Numeric weight='emphasis'>{portfolioValue}</Numeric>
          {baseAsset && (
            <Numeric size='small' color='secondary'>
              Available:{' '}
              {formatCurrency(getBalance(baseAsset.id)?.availableBalance, baseAsset, {
                appendCode: true,
                hideCode: false,
              })}
            </Numeric>
          )}
        </FlexLayout>
        <Button variant='outlined' onClick={() => openModal(Modals.DepositReceive)}>
          Deposit
        </Button>
        <AppHeaderHelp />
        <SwyftxProAppHeaderSettings />
        <AppHeaderProfile />
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxProAppHeader };

import LengthValidator, { LengthRule } from '../Base/LengthValidator';
import StringValidator, { StringError, StringRule } from '../Base/StringValidator';
import { Validator } from '../Validator';

export type PasswordError = {
  minCharacters: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  isEmpty: boolean;
};

class PasswordValidator extends Validator<string, PasswordError> {
  lengthValidator: LengthValidator;

  stringValidator: StringValidator;

  constructor() {
    super();
    // Password should be at least 8 characters long
    this.lengthValidator = new LengthValidator(8, LengthRule.GreaterThanOrEqual);

    // Password should have an Uppercase lowercase number and special character
    this.stringValidator = new StringValidator(
      StringRule.HasUppercase,
      StringRule.hasLowerCase,
      StringRule.HasSpecialCharacter,
      StringRule.HasNumber,
      StringRule.IsEmpty,
    );
  }

  isValid = (input: string): PasswordError => {
    const stringErrors: StringError = this.stringValidator.isValid(input);

    return {
      minCharacters: this.lengthValidator.isValid(input),
      hasUppercase: stringErrors.hasUppercase || false,
      hasLowercase: stringErrors.hasLowercase || false,
      hasNumber: stringErrors.hasNumber || false,
      hasSpecialCharacter: stringErrors.hasSpecialCharacter || false,
      isEmpty: stringErrors.isEmpty || false,
    };
  };
}

export default PasswordValidator;

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List } from '@swyftx/aviary/atoms/List';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { Information, Search, Star } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { MarketFilterSort, useMarketsFilterSort } from 'src/lib/markets/hooks/useMarketsFilterSort';
import { MarketAssetFilterType } from 'src/lib/markets/types/Markets.types';

import { SelectAssetListHeader } from './SelectAssetListHeader';
import { SelectAssetListItem } from './SelectAssetListItem';
import { SelectAssetListItemSkeleton } from './SelectAssetListItemSkeleton';

type Props = {
  title?: string;
  defaultFilterType?: MarketAssetFilterType;
  showTabs?: boolean;
  sortForTransferList?: boolean;
  excludedAssetCodes?: string[];
  includedAssetCodes?: string[];
  containerClassName?: string;
  className?: string;
  max?: number;
  customMaxMessage?: string;
  onBack?: () => void;
  onClose?: () => void;
} & (
  | {
      type: 'multi';
      onSelectAsset: (asset: Asset[]) => void;
      selectedAssets?: Asset[];
    }
  | {
      type: 'buy' | 'sell' | 'priceAlert' | 'deposit' | 'withdraw';
      onSelectAsset: (asset: Asset) => void;
      selectedAssets?: Asset;
    }
);

const SelectAssetList: React.FC<Props> = observer(
  ({
    title,
    selectedAssets,
    showTabs = true,
    defaultFilterType = 'all',
    sortForTransferList = false,
    type,
    max,
    customMaxMessage,
    excludedAssetCodes,
    includedAssetCodes,
    className,
    containerClassName,
    onBack,
    onClose,
    onSelectAsset,
  }) => {
    const [search, setSearch] = useState<string>('');
    const [selectedFilterType, setSelectedFilterType] = useState<MarketAssetFilterType>(defaultFilterType);
    const isXs = useTailwindBreakpoint('xs');
    const countryAsset = useCountryAsset();

    const sort: MarketFilterSort = useMemo((): MarketFilterSort => {
      switch (selectedFilterType) {
        case 'all':
        case 'favourites':
          return { sortKey: 'rank', sortDirection: 'DESC' };
        case 'owned':
          return { sortKey: 'balanceValue', sortDirection: 'DESC' };
        case 'new':
          return { sortKey: 'id', sortDirection: 'ASC' };
      }
    }, [selectedFilterType]);

    const { filteredAndSortedAssets, loading } = useMarketsFilterSort({
      search,
      sort,
      filterType: selectedFilterType,
      sortForTransferList,
      excludedAssetCodes,
      includedAssetCodes,
    });

    useEffect(() => {
      if (defaultFilterType) setSelectedFilterType(defaultFilterType);
    }, [defaultFilterType]);

    const tabs: EnhancedTab<MarketAssetFilterType>[] = useMemo(() => {
      const favouritesIcon =
        selectedFilterType === 'favourites' ? <StarFilled className='h-20 w-20' /> : <Star className='h-20 w-20' />;

      return [
        { title: 'All assets', value: 'all' },
        { title: 'Owned', value: 'owned' },
        {
          title: 'Favourites',
          icon: isXs ? favouritesIcon : undefined,
          value: 'favourites',
        },
        { title: 'New', value: 'new' },
      ];
    }, [isXs, selectedFilterType]);

    const onToggleAsset = useCallback(
      (asset: Asset) => {
        if (type !== 'multi') {
          onSelectAsset(asset);
          return;
        }

        const newAssets = [...(selectedAssets || [])];
        const selectedAssetIndex = newAssets.findIndex((a) => a.code === asset.code) ?? -1;

        if (selectedAssetIndex === -1) {
          newAssets.push(asset);
        } else {
          newAssets.splice(selectedAssetIndex, 1);
        }

        onSelectAsset(newAssets);
      },
      [onSelectAsset, selectedAssets, type],
    );

    const atMaxAssets = useMemo(() => {
      if (!max || type !== 'multi' || !selectedAssets) return false;

      return selectedAssets.length >= max;
    }, [max, selectedAssets, type]);

    const noResultsTerm = useMemo(() => {
      switch (selectedFilterType) {
        case 'all':
          return 'assets ';
        case 'favourites':
          return 'favourite assets';
        case 'new':
          return 'new assets';
        case 'owned':
          return 'owned assets';
        default:
          selectedFilterType satisfies never;
      }
    }, [selectedFilterType]);

    return (
      <FlexLayout
        direction='column'
        spacing={0}
        alignItems='center'
        justifyContent='start'
        className={cn('h-full w-full overflow-hidden pb-8 sm:pb-24', containerClassName)}
      >
        {title && <SelectAssetListHeader title={title} onBack={onBack} onClose={onClose} />}
        <div className={cn('sticky top-0 mb-12 w-full px-8 sm:px-24', title ? '' : 'mt-8', className)}>
          <Input
            placeholder='Search for asset'
            autoFocus
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant='default'
            className='w-full'
            leading={<Search />}
          />
        </div>
        {showTabs && (
          <div className='mb-12 w-full px-8 sm:px-24'>
            <EnhancedTabs<MarketAssetFilterType>
              tabs={tabs}
              value={selectedFilterType}
              onChange={setSelectedFilterType}
            />
          </div>
        )}

        <List className={cn('h-full w-full px-8 pr-8 pt-4 sm:px-24 sm:pr-24', className)} spacing={16}>
          {loading && (
            <>
              <SelectAssetListItemSkeleton />
              <SelectAssetListItemSkeleton />
              <SelectAssetListItemSkeleton />
              <SelectAssetListItemSkeleton />
              <SelectAssetListItemSkeleton />
              <SelectAssetListItemSkeleton />
              <SelectAssetListItemSkeleton />
            </>
          )}
          {!loading &&
            filteredAndSortedAssets?.map((asset: Asset) => {
              let selected = false;
              if (type === 'multi') {
                if (selectedAssets) {
                  selected = selectedAssets.findIndex((a) => a.code === asset.code) > -1;
                }
              } else {
                selected = selectedAssets?.code === asset.code;
              }

              return (
                <SelectAssetListItem
                  key={asset.code}
                  type={type}
                  asset={asset}
                  selected={selected}
                  disabled={atMaxAssets && !selected}
                  onClick={onToggleAsset}
                />
              );
            })}
          {!loading && filteredAndSortedAssets?.length === 0 && search.length > 0 && (
            <FlexLayout
              direction='column'
              className='h-full w-full'
              alignItems='center'
              justifyContent='center'
              spacing={16}
            >
              <Search className='h-48 w-48' />
              <FlexLayout direction='column' spacing={0} alignItems='center' justifyContent='center'>
                <Heading size='h5'>No {noResultsTerm} found</Heading>
                <Body>No search results found for &apos;{search}&apos;</Body>
              </FlexLayout>
            </FlexLayout>
          )}
          {!loading && countryAsset && filteredAndSortedAssets?.length === 0 && type === 'sell' && !search.length && (
            <FlexLayout
              direction='column'
              className='mt-32  w-full'
              alignItems='center'
              justifyContent='start'
              spacing={24}
            >
              <Search className='h-48 w-48' />
              <FlexLayout direction='column' spacing={0} alignItems='center' justifyContent='center'>
                <Heading size='h5'>No results</Heading>
                <Body>Deposit {countryAsset.code} to buy, sell and swap on Swyftx</Body>
              </FlexLayout>
            </FlexLayout>
          )}
        </List>

        {max && (
          <FlexLayout className='mt-8' alignItems='center' spacing={4}>
            <Information className='h-14 w-14 text-color-text-accent' />

            <Body color='accent' size='small'>
              {customMaxMessage ?? <>You can select up to {max} assets</>}
            </Body>
          </FlexLayout>
        )}
      </FlexLayout>
    );
  },
);

export { SelectAssetList };

/* eslint-disable new-cap */
import React, { useEffect, useRef, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';

import { cn } from '@shared/utils/lib/ui';

import { DateTime } from 'luxon';
import { useFullscreen, useMeasure } from 'react-use';

import { TradingViewWidgetContent } from './TradingViewWidgetContent';
import { TradingViewHeader } from './components';
import { TradingViewContextProvider } from './context/TradingView.context';
import { TradingViewChartType, TradingViewSide } from './types';
import { clearGlobalState } from './types/tradingViewGlobalState';

type Props = {
  primary: string;
  secondary: string;
  border?: boolean;
  onPriceSideChange?: (side: TradingViewSide) => void;
  onDateClick?: (date: DateTime) => void;
  useNewStyle?: boolean;
  className?: string;
  style?: React.CSSProperties;
  chartStyle: TradingViewChartType;
  side?: 'bid' | 'ask';
  changeSideEnabled?: boolean;
  priceLines?: string[];
  onUpdatePriceLines?: (triggerPrices: string[]) => void;
};

const TradingViewWidget: React.FC<Props> = ({
  primary,
  secondary,
  border = true,
  useNewStyle = true,
  className,
  style,
  onPriceSideChange,
  onDateClick,
  chartStyle = 'simple',
  changeSideEnabled = true,
  side,
  priceLines,
  onUpdatePriceLines,
}) => {
  const widgetRef = useRef<HTMLDivElement>(null);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const isFullscreen = useFullscreen(widgetRef, fullscreen);

  useEffect(() => {
    setFullscreen(isFullscreen);
  }, [isFullscreen]);

  useEffect(() => {
    clearGlobalState();
  }, []);

  return (
    <TradingViewContextProvider primary={primary} secondary={secondary} chartStyle={chartStyle} chartSide={side}>
      <Card
        ref={widgetRef}
        className={cn('h-full w-full overflow-hidden @container', className)}
        data-container='tradingview-widget'
        border={border}
        style={style}
      >
        {useNewStyle && (
          <TradingViewHeader
            ref={ref}
            setFullscreen={setFullscreen}
            fullscreen={fullscreen}
            onPriceSideChange={onPriceSideChange}
            changeSideEnabled={changeSideEnabled}
          />
        )}
        <TradingViewWidgetContent
          useNewStyle={useNewStyle}
          onDateClick={onDateClick}
          headerHeight={height}
          onPriceSideChange={onPriceSideChange}
          priceLines={priceLines}
          onUpdatePriceLines={onUpdatePriceLines}
        />
      </Card>
    </TradingViewContextProvider>
  );
};

export { TradingViewWidget };

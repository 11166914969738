import type {
  ChangePasswordResponse,
  LoginParams,
  MfaLostAuthParams,
  MfaParams,
  OTPParams,
  OTPRequired,
  OTPSMSFallbackParams,
  PasswordlessOTPParams,
  RefreshAccessTokenParams,
  ResetPasswordParams,
  SignUpParams,
} from '../..';
import {
  ChangePasswordParams,
  CompleteMFAEnrolmentParams,
  CompleteResetPasswordParams,
  MFAEnrolment,
  MFARequired,
  ReCaptchaHeaders,
  RefreshAccessTokenResponse,
  SetupMFAEnrolmentParams,
  SetupMFAEnrolmentResponse,
  CancelMFAEnrolmentResponse,
  UserProfile,
} from '../../@types/auth';
import { Methods, request } from '../rest';

const authEndpoints = {
  login: ({
    data,
    headers,
  }: {
    data: LoginParams;
    headers: ReCaptchaHeaders;
  }): UserProfile | MFARequired | OTPRequired | MFAEnrolment =>
    request({ path: '/auth/login/', method: Methods.POST, data, headers, hideErrorToast: true }),

  register: ({ data, headers }: { data: SignUpParams; headers: ReCaptchaHeaders }): UserProfile =>
    request({ path: '/auth/signup/', method: Methods.POST, data, headers, hideErrorToast: true }),

  mfa: ({ data }: { data: MfaParams }): UserProfile =>
    request({ path: '/auth/mfa/', method: Methods.POST, data, hideErrorToast: true }),

  otpSmsFallback: ({ data }: { data: OTPSMSFallbackParams }): { success: boolean; mobile: string } =>
    request({ path: '/auth/otp/sms-fallback/', method: Methods.POST, data, hideErrorToast: true }),

  otp: ({ data }: { data: OTPParams }): UserProfile =>
    request({ path: '/auth/otp/', method: Methods.POST, data, hideErrorToast: true }),

  resetPassword: ({ data, headers }: { data: ResetPasswordParams; headers: ReCaptchaHeaders }) =>
    request({ path: '/auth/resetPassword/', method: Methods.POST, data, headers }),

  mfaRemove: ({ headers }: { headers?: Record<string, string> } = {}): string =>
    request({ path: '/auth/mfa/remove/', method: Methods.POST, data: {}, headers, auth: true, hideErrorToast: true }),

  mfaLostAuth: ({ data, headers }: { data: MfaLostAuthParams; headers?: Record<string, string> }): string =>
    request({ path: '/auth/mfa/lostAuth/', method: Methods.POST, data, headers, hideErrorToast: true }),

  refreshAccessToken: ({ data }: { data: RefreshAccessTokenParams }): RefreshAccessTokenResponse =>
    request({ path: '/auth/refresh/', method: Methods.POST, data }),

  logout: () => request({ path: '/auth/logout/', method: Methods.POST, auth: true, hideErrorToast: true }),

  changePassword: ({ data }: { data: ChangePasswordParams }): ChangePasswordResponse =>
    request({ path: '/auth/changePassword/', method: Methods.PATCH, auth: true, data, hideErrorToast: true }),

  enrollMFA: () => request({ path: '/auth/mfa/enroll/', method: Methods.GET, auth: true }),

  cancelMFA: (): CancelMFAEnrolmentResponse => request({ path: '/auth/mfa/cancel/', method: Methods.GET, auth: true }),

  completePasswordReset: ({ data }: { data: CompleteResetPasswordParams }) =>
    request({ path: '/auth/completeResetPassword/', method: Methods.POST, data }),

  setupMFAEnrolment: ({ query }: { query: SetupMFAEnrolmentParams }): SetupMFAEnrolmentResponse =>
    request({ path: '/auth/mfa/setupEnrollment/', method: Methods.GET, auth: true, query }),

  completeMFAEnrolment: ({ data }: { data: CompleteMFAEnrolmentParams }) =>
    request({ path: '/auth/mfa/completeEnrollment/', method: Methods.POST, auth: true, data, hideErrorToast: true }),

  signUpOTP: ({ data, headers }: { data: PasswordlessOTPParams; headers: ReCaptchaHeaders }) =>
    request({ path: '/auth/signup/otp/', method: Methods.POST, data, headers }),
};

export default authEndpoints;

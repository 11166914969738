import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';

import { useSwyftxProTradeAmount } from '../../hooks/useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from '../../hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from '../../hooks/useSwyftxProTradeDirection';

const SwyftxProOrderConfirmationModalHeader: React.FC = observer(() => {
  const { selectedAsset, sellAsset, buyAsset, limitAsset } = useSwyftxProTradeAssets();
  const { total, amount } = useSwyftxProTradeAmount();
  const { isBuy } = useSwyftxProTradeDirection();

  const value = useMemo(() => (isBuy ? total : amount), [amount, isBuy, total]);
  const secondaryValue = useMemo(() => (isBuy ? amount : total), [amount, isBuy, total]);

  if (!selectedAsset || !buyAsset || !sellAsset || (!value && !secondaryValue)) return null;

  return (
    <FlexLayout direction='column' spacing={8} alignItems='center'>
      <AssetIcon asset={selectedAsset} size={48} />
      <FlexLayout direction='column' spacing={0} alignItems='center'>
        {value && (
          <Numeric size='xxlarge' weight='bold'>
            {formatCurrency(value, sellAsset, {
              appendCode: true,
              hideCode: false,
              isApproximate: limitAsset?.code === buyAsset.code,
            })}
          </Numeric>
        )}
        {secondaryValue && (
          <Numeric color='secondary'>
            {formatCurrency(secondaryValue, buyAsset, {
              appendCode: true,
              hideCode: false,
              isApproximate: limitAsset?.code === sellAsset.code,
            })}
          </Numeric>
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxProOrderConfirmationModalHeader };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Download } from '@swyftx/aviary/icons/outlined';
import { Button } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';
import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api } from '@shared/api';
import { assetService } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { AffiliateHistoryEntry } from '@Profile/types';

import { observer } from 'mobx-react-lite';

type Props = {
  item: AffiliateHistoryEntry;
};

const AffiliateDashboardPastPayoutsTableItem: React.FC<Props> = observer(({ item }) => {
  const { userProfile } = UserStore.useUserStore;
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false);
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('profile.affiliates', { keyPrefix: 'affiliateDashboard.pastPayouts' });
  const { bx } = useContentBreakpoint();
  const gstRegistered = userProfile?.userSettings.gstRegistered === 1;

  const countryAsset = useCountryAsset();
  const payoutAsset = assetService.getAsset(item.asset);

  const downloadAffiliateInvoice = async () => {
    const { reference } = item;

    try {
      setDownloadingInvoice(true);

      const res = await api.endpoints.downloadAffiliateInvoice({
        data: { reference },
      });

      const uInt8 = new Uint8Array(res.data);
      const blob = new Blob([uInt8], { type: res.headers['content-type'] });
      const name = `/AffiliatePayout-${reference}.pdf`;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 0);

      addMessageBox({
        content: <SuccessMessageBox title={t('labels.downloadSuccess')} />,
      });
    } catch {
      addMessageBox({
        content: <ErrorMessageBox title={t('labels.downloadError')} />,
      });
    } finally {
      setDownloadingInvoice(false);
    }
  };

  return (
    <FlexLayout
      direction='row'
      alignItems='center'
      justifyContent='start'
      className='h-[60px] w-full border-b border-color-border-main px-16'
    >
      <Body className='flex-[3] text-left'>
        {new Date(item.updated).toLocaleString('default', { month: 'long', year: 'numeric' })}
      </Body>
      <FormattedText
        typographyProps={{ className: 'text-right flex-[2] hidden @md:block' }}
        value={item.accrued}
        formatOpts={{ appendCode: payoutAsset !== undefined, hideCode: false }}
        currency={payoutAsset || countryAsset}
      />
      <FormattedText
        typographyProps={{ className: 'text-right flex-[2] hidden @md:block' }}
        value={item.gst}
        formatOpts={{ appendCode: payoutAsset !== undefined, hideCode: false }}
        currency={payoutAsset || countryAsset}
      />
      <FormattedText
        typographyProps={{ className: 'text-right flex-[2] hidden @md:block' }}
        value={item.total}
        formatOpts={{ appendCode: payoutAsset !== undefined, hideCode: false }}
        currency={payoutAsset || countryAsset}
      />

      <Box display={bx({ xs: 'block', md: 'none' })} flex={3}>
        <FormattedText
          typographyProps={{ className: 'text-right' }}
          value={item.total}
          formatOpts={{ appendCode: payoutAsset !== undefined, hideCode: false }}
          currency={payoutAsset || countryAsset}
        />

        <FormattedText
          typographyProps={{ className: 'text-right' }}
          value={item.gst}
          formatOpts={{ appendCode: payoutAsset !== undefined, hideCode: false }}
          currency={payoutAsset || countryAsset}
          secondaryText={{
            value: t('headers.gst'),
            typographyProps: { className: 'text-12' },
          }}
        />
      </Box>
      {gstRegistered && (
        <Box flex={1} display='flex' alignItems='center' justifyContent={bx({ xs: 'flex-end', md: 'center' })}>
          <Button icon color='inherit' loading={downloadingInvoice} onClick={downloadAffiliateInvoice}>
            <Download className='h-28 w-28' />
          </Button>
        </Box>
      )}
    </FlexLayout>
  );
});

export { AffiliateDashboardPastPayoutsTableItem };

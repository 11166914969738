import React, { useMemo } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { uuid } from '@shared/utils';

export const IdentityDarkIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  const maskId = useMemo(() => uuid(), []);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none'>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d='M10.9489 59.6948L10.949 59.6947L10.9534 59.6974L13.919 61.5461C14.4825 61.872 15.1952 62.0541 15.8928 62.0826C16.5927 62.1111 17.2603 61.9844 17.7368 61.7107L17.7369 61.7106L49.5302 43.466L49.5306 43.4658L51.9817 42.049C51.9818 42.049 51.9818 42.0489 51.9819 42.0489C52.4106 41.7999 52.5948 41.4868 52.5948 41.1826C52.5948 40.8085 52.3184 40.3745 51.6971 40.0137L51.697 40.0138L51.6931 40.0114L48.7247 38.1628C48.1625 37.8397 47.4497 37.6576 46.7517 37.6288C46.0514 37.5999 45.3833 37.726 44.907 38.0009L44.9067 38.0011L10.6648 57.6627C10.6647 57.6628 10.6647 57.6628 10.6646 57.6628C10.2355 57.9121 10.0516 58.2223 10.0516 58.5262C10.0516 58.9034 10.3282 59.3372 10.9489 59.6948Z'
            fill='#13151B'
          />
          <path
            d='M10.9489 59.6948L10.949 59.6947L10.9534 59.6974L13.919 61.5461C14.4825 61.872 15.1952 62.0541 15.8928 62.0826C16.5927 62.1111 17.2603 61.9844 17.7368 61.7107L17.7369 61.7106L49.5302 43.466L49.5306 43.4658L51.9817 42.049C51.9818 42.049 51.9818 42.0489 51.9819 42.0489C52.4106 41.7999 52.5948 41.4868 52.5948 41.1826C52.5948 40.8085 52.3184 40.3745 51.6971 40.0137L51.697 40.0138L51.6931 40.0114L48.7247 38.1628C48.1625 37.8397 47.4497 37.6576 46.7517 37.6288C46.0514 37.5999 45.3833 37.726 44.907 38.0009L44.9067 38.0011L10.6648 57.6627C10.6647 57.6628 10.6647 57.6628 10.6646 57.6628C10.2355 57.9121 10.0516 58.2223 10.0516 58.5262C10.0516 58.9034 10.3282 59.3372 10.9489 59.6948Z'
            stroke='#13151B'
            strokeWidth='0.3'
          />
        </g>
        <path
          d='M14.7636 29.7535V55.0099C14.7636 56.0713 15.1045 56.8362 15.653 57.2157L15.8576 57.3372C16.3942 57.5981 17.0879 57.5418 17.8439 57.106L48.8549 39.202C50.5566 38.2178 51.9352 35.6889 51.9352 33.5483V8.29191C51.9352 7.19497 51.5705 6.41525 50.9895 6.04762L50.8679 5.97645C50.4084 5.74224 49.8302 5.74521 49.1928 6.0239C49.0831 6.07133 48.9705 6.13063 48.8549 6.19586L47.0731 7.2246L17.8439 24.0998C17.7372 24.162 17.6334 24.2273 17.5296 24.3014C15.9791 25.3954 14.7636 27.7464 14.7636 29.7535Z'
          fill='#4E5869'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.7636 29.7535V33.2252L47.0731 14.5712L51.9352 11.7636V8.29191C51.9352 7.19497 51.5705 6.41524 50.9895 6.04762L50.8679 5.97645C50.4084 5.74224 49.8302 5.74521 49.1928 6.0239C49.0831 6.07133 48.9705 6.13063 48.8549 6.19586L47.0731 7.2246L17.8439 24.0998C17.7372 24.162 17.6334 24.2273 17.5296 24.3014C15.9791 25.3954 14.7636 27.7464 14.7636 29.7535Z'
          fill='#80C0FF'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.90145 26.946V30.4176L14.7636 33.2252V29.7535C14.7636 27.7464 15.9791 25.3954 17.5297 24.3014C17.6334 24.2273 17.7372 24.1621 17.844 24.0998L47.0732 7.22466L48.855 6.19591C48.9706 6.13069 49.0832 6.07139 49.1929 6.02395C49.8303 5.74527 50.4085 5.7423 50.868 5.97651L46.166 3.26084L46.0059 3.16893C45.4663 2.89321 44.7636 2.94658 43.9928 3.39128L12.9818 21.2952C11.2831 22.2765 9.90145 24.8054 9.90145 26.946Z'
          fill='#3D93FA'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.90185 30.4176V52.2024C9.90185 53.2667 10.2428 54.0316 10.7942 54.4111L10.9929 54.5267L15.6534 57.2157C15.105 56.8362 14.764 56.0713 14.764 55.0099V33.2252L9.90185 30.4176Z'
          fill='#303642'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M23.1616 32.6182L16.6612 36.3737'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.915 29.3044L25.4981 31.2791'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.5306 35.2123L17.1159 40.6502'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.0607 30.2877L29.1967 33.6745'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.5306 43.6135L17.1159 49.0515'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.0607 38.6891L29.1967 42.0759'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.3745 43.6644L17.7658 44.5931'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.4228 37.0449L24.425 40.5081'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.6525 37.3658L47.7192 32.0907C48.1189 31.8278 48.452 31.4746 48.6915 31.0596C48.9311 30.6447 49.0705 30.1797 49.0983 29.7021L49.1345 19.2104C49.1364 18.3341 48.5249 17.981 47.7669 18.4208L38.7022 23.6971C38.3019 23.96 37.9684 24.3134 37.7285 24.7288C37.4887 25.1442 37.3494 25.6098 37.3219 26.0878L37.2849 36.5762C37.2818 37.4546 37.8945 37.8056 38.6525 37.3658Z'
          fill='#303642'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <mask id={maskId} style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='37' y='18' width='13' height='20'>
          <path
            d='M38.6525 37.3658L47.7192 32.0907C48.1189 31.8278 48.452 31.4746 48.6915 31.0596C48.9311 30.6447 49.0705 30.1797 49.0983 29.7021L49.1345 19.2104C49.1364 18.3341 48.5249 17.981 47.7669 18.4208L38.7022 23.6971C38.3019 23.96 37.9684 24.3134 37.7285 24.7288C37.4887 25.1442 37.3494 25.6098 37.3219 26.0878L37.2849 36.5762C37.2818 37.4546 37.8945 37.8056 38.6525 37.3658Z'
            fill='#E0E8F7'
            stroke='#223893'
            strokeWidth='0.289775'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <path
            d='M47.0145 37.5389C44.9431 41.1267 41.4326 42.9778 39.1737 41.6736C36.9147 40.3694 36.7626 36.4037 38.834 32.8159C40.9055 29.2281 44.4159 27.3769 46.6749 28.6811C48.9339 29.9854 49.0859 33.9511 47.0145 37.5389Z'
            fill='#3D93FA'
            stroke='#13151B'
            strokeWidth='0.289775'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M38.6525 37.3658L47.7192 32.0907C48.1189 31.8278 48.452 31.4746 48.6915 31.0596C48.9311 30.6447 49.0705 30.1797 49.0983 29.7021L49.1345 19.2104C49.1364 18.3341 48.5249 17.981 47.7669 18.4208L38.7022 23.6971C38.3019 23.96 37.9684 24.3134 37.7285 24.7288C37.4887 25.1442 37.3494 25.6098 37.3219 26.0878L37.2849 36.5762C37.2818 37.4546 37.8945 37.8056 38.6525 37.3658Z'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M44.7227 25.95C43.8788 27.4117 42.4406 28.1613 41.5105 27.6243C40.5803 27.0872 40.5104 25.4669 41.3543 24.0053C42.1982 22.5436 43.6364 21.794 44.5665 22.331C45.4967 22.8681 45.5666 24.4883 44.7227 25.95Z'
          fill='#3D93FA'
          stroke='#13151B'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

import { api, UserProfile } from '@shared/api';
import { Profile } from '@shared/api/@types/auth';
import { EntityTypeEnum } from '@shared/enums';
import { emitEvent, SwyftxEvent } from '@shared/events';
import logger from '@shared/logger';
import storage, { StorageKey } from '@shared/storage';
import { UIStore, UserStore } from '@shared/store';
import { EntityAccount, EntityColor, EntityMember } from '@shared/store/userStore/@types/userTypes';
import { getInitials } from '@shared/utils/lib/entity';

import AuthenticationService from '@services/AuthenticationService';

export const currentUserUUID = 'USER';

const numberOfEntityColors = 4;

const LOG_TAG = '[ENTITY_SERVICE]';

/**
 * Update list of entities associated with the user
 */
const updateEntities = async () => {
  const { userProfile: currentUserProfile, previousUserProfile, setEntityAccounts, isEntity } = UserStore.useUserStore;

  let userProfile = currentUserProfile;

  // if an entity, load the previous user profile
  if (isEntity()) {
    if (previousUserProfile) {
      userProfile = previousUserProfile;
    } else {
      logger.error(LOG_TAG, 'Unable to load personal user profile for entity account list');
    }
  }

  // create a list with yourself (personal user) already added
  const entityAccountsList: EntityAccount[] = [
    {
      uuid: currentUserUUID,
      name: `${userProfile?.name.first} ${userProfile?.name.last}`,
      initials: getInitials(`${userProfile?.name.first} ${userProfile?.name.last}`),
      checkIfVerified: false,
      isEntity: false,
    },
  ];

  // create an color reference index (4 colors, so rotate through 1-4)
  let currentEntityColorIndex = 0;

  try {
    // load entities
    const entities = await api.endpoints.getEntities(getAuthorizationHeader());

    // if any entities were found, add them
    if (entities.data && entities.data.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const entity of entities.data) {
        // add entity account
        entityAccountsList.push({
          uuid: entity.uuid,
          name: entity.entityName,
          initials: getInitials(entity.entityName),
          isEntity: true,
          nonTradingAccount: entity.nonTradingAccount,
          entityColorIndex: currentEntityColorIndex,
          checkIfVerified: true,
        });

        if (currentEntityColorIndex < numberOfEntityColors - 1) {
          // increment the current entity color index by 1 if current index less then the number of entity colors
          currentEntityColorIndex += 1;
        } else {
          // otherwise reset to 0 index (I.e. rotate back to 0)
          currentEntityColorIndex = 0;
        }
      }
    }
  } catch (err) {
    logger.error(LOG_TAG, 'Unable to update entities', err);
  }

  setEntityAccounts(entityAccountsList);
};

const updateMembers = async () => {
  const { entityUuid, setEntityMembers, isEntity } = UserStore.useUserStore;

  if (!isEntity()) {
    setEntityMembers([]);
    return;
  }

  if (entityUuid) {
    try {
      await Promise.all([
        api.endpoints.getMembers({ params: { entityUuid, deepSearch: true } }),
        api.endpoints.getInvites({ params: { entityUuid } }),
      ]).then(([members, invites]) => {
        const filteredMembers = members.data.filter(
          (member) => member.entityType === EntityTypeEnum.PERSONAL || member.entityType === EntityTypeEnum.INVITE,
        ); // TODO: potentially support sub entities

        const combinedMembers: EntityMember[] = filteredMembers.map((member) => ({
          ...member,
          parentUuid: entityUuid,
        }));

        // if there are any invited members, add them
        if (invites.data && invites.data.length > 0) {
          for (const invite of invites.data) {
            combinedMembers.unshift({
              entityType: EntityTypeEnum.INVITE,
              name: `${invite.firstName} ${invite.lastName}`,
              parentUuid: invite.entityUuid,
              scope: invite.scope,
              email: invite.email,
              expiry: invite.expiry,
              isExpired: invite.expiry <= new Date().valueOf(),
            });
          }
        }

        setEntityMembers(combinedMembers);
      });
    } catch (error) {
      // TODO should we handle this error?
      logger.error(LOG_TAG, 'Unable to update members', error);
      setEntityMembers([]);
    }
  } else {
    // TODO should we handle this error?
    logger.error(LOG_TAG, 'Unable to update members due to entityUuid missing');
    setEntityMembers([]);
  }
};

const applyEntityColorToDOM = (entityColorIndex?: EntityColor) => {
  for (let i = 0; i < 4; i++) {
    document.body.classList.remove(`entity${i + 1}`);
  }

  if (entityColorIndex === undefined) {
    return '';
  }

  document.body.classList.add(`entity${entityColorIndex + 1}`);
};

/**
 * Switch accounts (I.e. switch to an entity account or back to personal account)
 */
const switchAccounts = async (
  uuid: string,
  entityColorIndex?: EntityColor,
  onSwitch?: () => void,
  initThirdPartyServices?: (userProfile: UserProfile) => void,
  onSwitchError?: () => void,
) => {
  try {
    const {
      userAuth: currentUserAuth,
      userProfile: currentUserProfile,
      previousUserProfile,
      previousUserAuth,
      setUserProfile,
      setAuth,
      setPreviousAuth,
      setPreviousUserProfile,
      resetPreviousUserProfile,
      isEntity,
      setEntityUuid,
      setEntityColorIndex,
    } = UserStore.useUserStore;
    api.cache.clear();

    await storage.setItem(StorageKey.CURRENT_ACCOUNT, uuid);

    if (uuid === currentUserUUID) {
      // get previous user profile
      const userProfile = {
        auth: previousUserAuth,
        profile: previousUserProfile,
      } as UserProfile;

      if (!userProfile) {
        logger.error(LOG_TAG, 'Unable to switch to user account as previous personal user profile is missing');
        throw new Error('Missing user data');
      }

      // clear the previous user profile
      resetPreviousUserProfile();

      applyEntityColorToDOM();

      // update the user profile
      if (initThirdPartyServices) initThirdPartyServices(userProfile);
      await AuthenticationService.handleSuccessfulLogin(userProfile, { initServices: true });
      if (previousUserProfile) setUserProfile(previousUserProfile);
      if (previousUserAuth) setAuth(previousUserAuth, false);
    } else {
      // get user entity token for entity
      const response = await api.endpoints.refreshEntityToken({
        params: { entityUuid: uuid },
        ...getAuthorizationHeader(),
      });

      await storage.setItem(StorageKey.CURRENT_ACCOUNT_COLOR, entityColorIndex);

      // update current user profile to be the retrieved user profile
      const entityToken = response.data as UserProfile;

      // save the current user profile for future use if a personal account
      if (!isEntity() && currentUserProfile) {
        setPreviousUserProfile(currentUserProfile);
      }

      // save the current auth for future use if a personal account
      if (!isEntity() && currentUserAuth) {
        setPreviousAuth(currentUserAuth);
      }

      // set auth for the entity
      setAuth(entityToken.auth, true);

      // get the full user profile for entity (refreshEntityToken does not return the full profile)
      const profileResponse = await api.endpoints.getProfile();

      // if the profile exists
      if (profileResponse.data?.user?.profile) {
        // get the profile
        const profile = profileResponse.data.user.profile as Profile;

        // update the entity color index if provided
        if (entityColorIndex !== undefined) {
          setEntityColorIndex(entityColorIndex);
        }

        // update entity UUID
        setEntityUuid(uuid);

        // complete the login by recording the profile and setting up services
        if (initThirdPartyServices) initThirdPartyServices({ profile });
        await AuthenticationService.handleSuccessfulLogin(entityToken, {
          isEntity: true,
          initServices: true,
        });
        setUserProfile(profile);

        applyEntityColorToDOM(entityColorIndex);
      } else {
        logger.error(LOG_TAG, 'Unable to switch to entity as unable to get profile');
        throw new Error('Missing profile');
      }
    }

    // clear cache on transaction history
    await api.endpoints.getAllTransactionHistory.resetCache();

    // fire event to update
    if (onSwitch) onSwitch();

    emitEvent(SwyftxEvent.ChangeAccount);
  } catch (error) {
    const { addToastMessage } = UIStore.useUIStore;

    logger.error(LOG_TAG, 'Unable to switch to account', error);
    addToastMessage({ severity: 'error', message: 'There was an error switching accounts. Please try again.' });
    if (onSwitchError) onSwitchError();
    throw error;
  }
};

const getAuthorizationHeader = () => {
  const { userAuth: currentUserAuth, previousUserAuth, isEntity } = UserStore.useUserStore;

  return {
    headers: {
      Authorization: `Bearer ${
        isEntity() && previousUserAuth && previousUserAuth.access_token
          ? previousUserAuth.access_token // if an entity account, use the access token from the previous user profile (I.e. personal account)
          : currentUserAuth?.access_token // if an personal account, use the access token from the current user profile
      }`,
    },
  };
};

const isEntityVerified = () => {
  const { userProfile } = UserStore.useUserStore;
  return !!userProfile?.verification?.id;
};

const cleanup = () => {
  const {
    setPreviousAuth,
    setEntityUuid,
    resetPreviousUserProfile,
    resetEntityAccounts,
    resetEntityMembers,
    resetEntityColorIndex,
  } = UserStore.useUserStore;

  setEntityUuid(undefined);
  resetPreviousUserProfile();
  setPreviousAuth(undefined);
  resetEntityAccounts();
  resetEntityMembers();
  resetEntityColorIndex();
};

export default {
  updateEntities,
  updateMembers,
  switchAccounts,
  cleanup,
  getAuthorizationHeader,
  isEntityVerified,
  applyEntityColorToDOM,
};

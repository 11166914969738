import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { AssetSummaryItemsMarqueeContainer } from './@styles/AssetSummaryItemsMarquee.styled';

type Props = {
  children: Array<JSX.Element>;
};

const SECONDS_PER_ITEM = 5;

export const AssetSummaryItemsMarquee: React.FC<Props> = ({ children }) => {
  const duration = children?.length * SECONDS_PER_ITEM;

  return (
    <AssetSummaryItemsMarqueeContainer duration={`${duration}s`}>
      <FlexLayout spacing={8} direction='row'>
        {children}
      </FlexLayout>
    </AssetSummaryItemsMarqueeContainer>
  );
};

import * as RadixSwitch from '@radix-ui/react-switch';
import { VariantProps, cva } from 'cva';

// https://www.figma.com/file/bTl1BBi1Bh0ECdgyWX8GAS/Components?node-id=484%3A29209&mode=dev

export interface SwitchProps extends RadixSwitch.SwitchProps, VariantProps<typeof switchVariants> {
  color?: 'primary' | 'warning';
  size?: 'sm' | 'md';
  text?: string;
  disabled?: boolean;
  checked?: boolean;
}

// TODO: flesh out variants with Figma link above
export const switchVariants = cva('', {
  variants: {
    color: {
      primary:
        'data-[state=checked]:bg-color-background-primary data-[state=unchecked]:bg-color-background-neutral-hover',
      warning:
        'data-[state=checked]:bg-color-background-warning data-[state=unchecked]:bg-color-background-neutral-hover',
    },
    disabled: {
      true: 'data-[state=checked]:bg-color-background-neutral bg-color-background-neutral',
      false: '',
    },
    size: {
      sm: 'w-[30px] h-[16px]',
      md: 'w-[44px] h-[24px]',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'primary',
    disabled: false,
  },
});

export const thumbVariants = cva('', {
  variants: {
    size: {
      sm: 'ml-[2px] w-[12px] h-[12px] data-[state=checked]:translate-x-[13px]',
      md: 'ml-[4px] w-[16px] h-[16px] data-[state=checked]:translate-x-[19px]',
    },
  },
});

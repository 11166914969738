import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';

import { NavigationURLs } from 'src/lib/navigation/types';

const LOG_TAG = 'APP_HEADER_BASE_CURRENCY_SELECTOR';

const AppHeaderBaseCurrencySelectorMenuOptions: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const { t } = useTranslation('common', { keyPrefix: 'headerWallet' });
  const countryAsset = useCountryAsset();
  const avo = useAvo();

  const onWalletOverview = () => {
    navigate(NavigationURLs.WalletsRoot);
  };

  const onDepositReceive = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
  };

  return (
    <FlexLayout direction='row' className='p-8 ' spacing={16}>
      <Button variant='outlined' onClick={onWalletOverview} className='w-full'>
        {t('walletOverview')}
      </Button>
      <Button color='primary' onClick={onDepositReceive} className='w-full'>
        {t('depositReceive')}
      </Button>
    </FlexLayout>
  );
};

export { AppHeaderBaseCurrencySelectorMenuOptions };

import { PerformanceMenuControl, PerformanceMenuControlLabel } from './PerformanceMenu.types';

export enum PerformanceMenuControlEnum {
  OneWeek = 0,
  OneMonth = 1,
  ThreeMonths = 2,
  SixMonths = 3,
  OneYear = 4,
  AllTime = 5,
}

export const performanceMenuControls: Array<PerformanceMenuControl> = [
  {
    label: PerformanceMenuControlLabel.OneWeek,
    startTime: new Date().setDate(new Date().getDate() - 7),
  },
  {
    label: PerformanceMenuControlLabel.OneMonth,
    startTime: new Date().setDate(new Date().getDate() - 30),
  },
  {
    label: PerformanceMenuControlLabel.ThreeMonths,
    startTime: new Date().setDate(new Date().getDate() - 90),
  },
  {
    label: PerformanceMenuControlLabel.SixMonths,
    startTime: new Date().setDate(new Date().getDate() - 180),
  },
  {
    label: PerformanceMenuControlLabel.OneYear,
    startTime: new Date().setDate(new Date().getDate() - 365),
  },
  // Will need backend changes if we want this as a future feature
  // {
  //   label: PerformanceMenuControlLabel.AllTime,
  //   startTime: null,
  // },
];

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { UserVerificationCard } from '@swyftx/aviary/organisms/Verification';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { verificationSteps } from '../data/Verification.data';
import { VerificationCardProps } from '../types';

const VerifyAccountWidget: React.FC<VerificationCardProps> = ({
  completed,
  variant = 'full',
  direction = 'row',
  isCurrentStep = false,
}) => {
  const { navigate } = useNavigateRoute();

  const onVerifyNow = useCallback(() => {
    navigate(NavigationURLs.Onboarding);
  }, [navigate]);

  return (
    <UserVerificationCard
      completed={completed}
      imageType='verification'
      usePalette
      variant={variant}
      direction={direction}
      isCurrentStep={isCurrentStep}
      title='Verify your account to deposit and trade'
      content='In order to deposit and trade on Swyftx, please take a few minutes to complete our account verification process.'
      actions={
        <FlexLayout spacing={8} alignItems='center'>
          <Button variant='filled' disabled={!isCurrentStep} onClick={onVerifyNow}>
            Verify now
          </Button>
        </FlexLayout>
      }
      steps={verificationSteps}
      currentStep='verify'
    />
  );
};

export { VerifyAccountWidget };

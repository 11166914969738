import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';

import { useAssetTransactionHistory } from '@global-components/Modals/AssetTransactionHistoryModal/AssetTransactionHistoryModals.hooks';

import { Asset } from '@shared/api/@types/markets';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

import { SingleWalletTransactionHistoryItem } from './SingleWalletTransactionHistoryItem';
import { TransactionHistoryLoadingState } from '../components/SingleWalletTransactionListLoading';

type Props = {
  asset: Asset;
};

const TRANSACTIONS_PER_PAGE = 10;

const SingleWalletTransactionHistoryList: React.FC<Props> = observer(({ asset }) => {
  const { loading, transactions, hasAnotherPage, incrementPage } = useAssetTransactionHistory(asset, [], {
    transactionsPerPage: TRANSACTIONS_PER_PAGE,
  });

  if (!asset) return null;

  return (
    <div>
      <FlexLayout className='overflow-auto p-16' direction='row' alignItems='center' justifyContent='space-between'>
        <Utility className={cn('w-1/2 text-left @md:w-1/4')} color='secondary'>
          Transaction type
        </Utility>
        <Utility color='secondary' className='block w-1/4 text-right @md:hidden'>
          Amount
        </Utility>
        <Utility color='secondary' className='hidden w-1/4 text-right @md:block'>
          Credit
        </Utility>
        <Utility color='secondary' className='hidden w-1/4 text-right @md:block'>
          Debit
        </Utility>
        <Utility color='secondary' className={cn('w-1/2 text-right @md:w-1/4')}>
          Balance
        </Utility>
      </FlexLayout>
      <Separator className='w-full' />
      <FlexLayout direction='column'>
        {loading && !transactions.length && <div>{TransactionHistoryLoadingState}</div>}

        {!transactions.length && !loading && <Body className='p-16'>No transaction history</Body>}

        {transactions.length > 0 && (
          <div>
            {transactions.map((transaction) => (
              <SingleWalletTransactionHistoryItem transaction={transaction} key={transaction.uuid} asset={asset} />
            ))}
          </div>
        )}
      </FlexLayout>
      {hasAnotherPage && (
        <FlexLayout className='p-16' direction='row' alignItems='center' justifyContent='center'>
          <Button variant='ghost' onClick={incrementPage} loading={loading}>
            Show more transactions
          </Button>
        </FlexLayout>
      )}
    </div>
  );
});

export { SingleWalletTransactionHistoryList };

import React, { useState } from 'react';

import { AppHeaderMenu } from '@swyftx/aviary/molecules/AppHeaderMenu';

import { UserStore } from '@shared/store';

import { useEntitySwitch } from '@hooks/Entity/useEntitySwitch';
import { CreateEntityModal } from '@routes/Entities/components/CreateEntity/CreateEntityModal';

import { observer } from 'mobx-react-lite';

import { MenuContent } from './AppHeaderContent';

const AppHeaderProfile: React.FC = observer(() => {
  const [showCreateEntityModal, setShowCreateEntityModal] = useState<boolean>(false);

  const { selectedAccount, switchingAccount, switchingAccountError, selectAccount } = useEntitySwitch();

  const { userProfile, entityAccounts, isUserVerified, isEntity, isKyc1Complete, userHasCompletedOnboarding } =
    UserStore.useUserStore;

  return (
    <>
      <AppHeaderMenu
        user={userProfile}
        isUserVerified={isUserVerified()}
        menuItems={MenuContent}
        entityAccounts={entityAccounts}
        openCreateEntity={() => setShowCreateEntityModal(true)}
        selectedAccount={selectedAccount}
        switchingAccount={switchingAccount}
        switchingAccountError={switchingAccountError}
        selectAccount={selectAccount}
        isKyc1Complete={isKyc1Complete()}
        isEntity={isEntity()}
        userHasCompletedOnboarding={userHasCompletedOnboarding()}
      />
      {showCreateEntityModal && <CreateEntityModal onClose={() => setShowCreateEntityModal(false)} />}
    </>
  );
});

AppHeaderProfile.displayName = 'AppHeaderProfile';

export default AppHeaderProfile;

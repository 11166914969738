import { NavigationURLs } from './types/navigationURL.types';

export const NavigationURLNames: { [key in NavigationURLs]: string } = {
  /* Assets */
  [NavigationURLs.Assets]: 'Assets',
  [NavigationURLs.AssetFilter]: 'Assets',
  [NavigationURLs.AssetsAll]: 'All',
  [NavigationURLs.AssetsFavourites]: 'Favourites',
  [NavigationURLs.AssetsGainers]: 'Gainers',
  [NavigationURLs.AssetsLosers]: 'Losers',
  [NavigationURLs.AssetsNew]: 'New',
  [NavigationURLs.AssetsOwned]: 'Owned',
  [NavigationURLs.AssetsEarnEligible]: 'Earn Eligible',
  [NavigationURLs.AssetsCategory]: 'Asset Category',
  [NavigationURLs.AssetsCategories]: 'Asset Categories',
  [NavigationURLs.SingleAsset]: 'Single Asset Page',
  [NavigationURLs.SingleCategory]: 'Single Category Page',

  /* Aviary Asset List */
  [NavigationURLs.AviaryAssetsAll]: 'All',
  [NavigationURLs.AviaryAssetsOwned]: 'Owned',
  [NavigationURLs.AviaryAssetsFavourites]: 'Favourites',
  [NavigationURLs.AviaryAssetsGainers]: 'Gainers',
  [NavigationURLs.AviaryAssetsLosers]: 'Losers',
  [NavigationURLs.AviaryAssetsNew]: 'New',

  /* Bundles */
  [NavigationURLs.Bundles]: 'Bundles',

  /* Dashboard */
  [NavigationURLs.Dashboard]: 'Dashboard',

  /* Login */
  [NavigationURLs.Login]: 'Login',
  [NavigationURLs.Auth]: 'Auth',

  /* Onboarding */
  [NavigationURLs.Onboarding]: 'Account Setup',

  /* Source of Wealth */
  [NavigationURLs.SourceOfWealth]: 'Source of wealth check',

  /* Maintenance */
  [NavigationURLs.Maintenance]: 'Maintenance',

  // Message
  [NavigationURLs.Message]: 'Message',

  /* Orders */
  [NavigationURLs.Transactions]: 'Transactions',
  [NavigationURLs.TransactionsRoot]: 'Transactions',
  [NavigationURLs.TransactionsOrders]: 'All Orders',
  [NavigationURLs.TransactionsDeposits]: 'All Deposits',
  [NavigationURLs.TransactionsWithdrawals]: 'All Withdrawals',

  /* Profile} */
  [NavigationURLs.ProfileInformation]: 'Information',
  [NavigationURLs.ProfileSettings]: 'Settings',
  [NavigationURLs.ProfileSecurity]: 'Security & passwords',
  [NavigationURLs.ProfileVerification]: 'Verification',
  [NavigationURLs.ProfileReferAFriend]: 'Refer a friend',
  [NavigationURLs.ProfileAffiliateProgram]: 'Affiliate program',
  [NavigationURLs.ProfileApiKeys]: 'API keys',
  [NavigationURLs.ProfileTaxReports]: 'Transaction reports',
  [NavigationURLs.ProfileTradeFees]: 'Trade fees',
  [NavigationURLs.ProfileAbout]: 'About',
  [NavigationURLs.Profile]: 'Profile',
  [NavigationURLs.ProfileEntityVerification]: 'Entity verification',
  [NavigationURLs.ProfileEntityLinkedMembers]: 'Entity linked members',
  [NavigationURLs.ProfileEntitySecurity]: 'Entity security',

  /** Auto Invest */
  [NavigationURLs.AutoInvest]: 'Auto Invest',
  [NavigationURLs.AutoInvestCreate]: 'Create',

  /* Register */
  [NavigationURLs.Register]: 'Create Account',

  /* Shop */
  [NavigationURLs.Shop]: 'Shop',
  [NavigationURLs.LedgerNanoX]: 'Ledger Nano X',
  [NavigationURLs.LedgerNanoSPlus]: 'Ledger Nano S Plus',

  /* Trade */
  [NavigationURLs.UniversalTrade]: 'Trade',
  [NavigationURLs.UniversalTradeAsset]: 'Trade',

  [NavigationURLs.Tutorials]: 'Tutorials',

  /* Quick Buy */
  [NavigationURLs.QuickBuy]: 'Quick Buy',

  /* Price Alerts */
  [NavigationURLs.PriceAlerts]: 'Price Alerts',

  /* Wallets */
  [NavigationURLs.SingleWallet]: 'Single Wallet',
  [NavigationURLs.Wallets]: 'Wallets',
  [NavigationURLs.WalletsRoot]: 'Wallets',
  [NavigationURLs.WalletsEarn]: 'Wallets Earn',

  /* Rewards */
  [NavigationURLs.RewardsRoot]: 'Swyftx Rewards',

  /* Portfolio Transfer */
  [NavigationURLs.PortfolioTransfer]: 'Portfolio Transfer',

  /* Force Password Reset */
  [NavigationURLs.PasswordReset]: 'Reset password',

  [NavigationURLs.Root]: '',
  [NavigationURLs.DepositReceive]: 'Deposit',
  [NavigationURLs.WithdrawSend]: 'Withdraw',

  [NavigationURLs.SwyftxPro]: 'Trade',

  /* Investing Entities Onboarding Uplift */
  [NavigationURLs.EntityOnboarding]: 'Entity Application',

  /* Loans */
  [NavigationURLs.Loans]: 'Loans',
};

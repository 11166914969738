import { useCallback } from 'react';

import { calculatePortfolioDifference } from '@global-components/PortfolioAssetTicker/PortfolioAssetTicker';

import { FiatIdEnum } from '@shared/enums';
import { useLiveRates } from '@shared/hooks';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { WalletSort } from '@routes/Wallet/types';
import { BalanceKey, getBalanceValue } from '@utils/balance';

export enum PercentTimeFrame {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum AssetSort {
  Rank = 'rank',
  Balance = 'balance',
  PercentChange = 'percent change',
  Volume = 'volume',
  BuyPrice = 'buy price',
  Name = 'name',
  MostRecent = 'most recent',
  New = 'new',
  Gainer = 'gainer',
  Loser = 'loser',
  MarketCap = 'market cap',
  Type = 'type',
}

const useSort = () => {
  const { balances, tradePriceHistory, userBaseCurrency } = UserStore.useUserStore;
  const { convertRateOnce, rates } = RatesStore.useRatesStore;
  const { getRateOnce } = useLiveRates();

  const sortWallets = useCallback(
    (assetIds: number[], ascending: boolean, sort: WalletSort, balanceKey: BalanceKey) =>
      assetIds.sort((assetIdA, assetIdB) => {
        if (assetService.isAssetFiat(Number(assetIdA)) || assetService.isAssetFiat(Number(assetIdB))) {
          if (assetService.isAssetFiat(Number(assetIdA)) && !assetService.isAssetFiat(Number(assetIdB))) {
            return -1;
          }
          if (assetService.isAssetFiat(Number(assetIdB)) && !assetService.isAssetFiat(Number(assetIdA))) {
            return 1;
          }
        }

        let assetA = { ...assetService.getAsset(Number(assetIdA))!, ...getRateOnce(Number(assetIdA)) };
        let assetB = { ...assetService.getAsset(Number(assetIdB))!, ...getRateOnce(Number(assetIdB)) };

        const balanceA = balances[Number(assetIdA)];
        const balanceB = balances[Number(assetIdB)];
        let assetABalance = getBalanceValue(balanceA, balanceKey) ?? 0;
        let assetBBalance = getBalanceValue(balanceB, balanceKey) ?? 0;

        if (ascending) {
          [assetA, assetB] = [assetB, assetA];
          [assetABalance, assetBBalance] = [assetBBalance, assetABalance];
        }

        const userCurrencyBalanceA = convertRateOnce(assetA, userBaseCurrency, assetABalance);
        const userCurrencyBalanceB = convertRateOnce(assetB, userBaseCurrency, assetBBalance);

        if (userBaseCurrency) {
          switch (sort) {
            case WalletSort.Amount:
              return userCurrencyBalanceB.minus(userCurrencyBalanceA).toNumber();
            case WalletSort.ChangePercentage: {
              const portfolioChangeA = calculatePortfolioDifference(
                assetA.id,
                tradePriceHistory,
                Big(assetA.askPrice),
                Big(assetABalance),
              );

              const portfolioChangeB = calculatePortfolioDifference(
                assetB.id,
                tradePriceHistory,
                Big(assetB.askPrice),
                Big(assetBBalance),
              );

              return Number(portfolioChangeB.percent.minus(portfolioChangeA.percent));
            }
            case WalletSort.ChangeAmount: {
              const portfolioChangeA = calculatePortfolioDifference(
                assetA.id,
                tradePriceHistory,
                Big(assetA.askPrice),
                Big(assetABalance),
              );

              const portfolioChangeB = calculatePortfolioDifference(
                assetB.id,
                tradePriceHistory,
                Big(assetB.askPrice),
                Big(assetBBalance),
              );

              return Number(portfolioChangeB.value.minus(portfolioChangeA.value));
            }
            case WalletSort.Name:
            default:
              // eslint-disable-next-line no-nested-ternary
              return assetA.name < assetB.name ? -1 : assetA.name > assetB.name ? 1 : 0;
          }
        }

        return 0;
      }),
    [balances, convertRateOnce, getRateOnce, tradePriceHistory, userBaseCurrency],
  );

  const sortAssets = useCallback(
    (assetIds: number[], ascending: boolean, sort: AssetSort, percentDisplay?: PercentTimeFrame) => {
      let filteredAssetIds = [];

      if (sort === AssetSort.Gainer || sort === AssetSort.Loser) {
        const isGainer = sort === AssetSort.Gainer;
        filteredAssetIds = assetIds.filter((id) => {
          const asset = { ...assetService.getAsset(id)!, ...getRateOnce(id) };
          switch (percentDisplay) {
            case PercentTimeFrame.Day: {
              const dailyPriceChange = parseFloat(asset.dailyPriceChange);
              return isGainer ? dailyPriceChange > 0 : dailyPriceChange < 0;
            }

            case PercentTimeFrame.Week: {
              return isGainer ? asset.priceChange.week > 0 : asset.priceChange.week < 0;
            }
            case PercentTimeFrame.Month:
            default: {
              return isGainer ? asset.priceChange.month > 0 : asset.priceChange.month < 0;
            }
          }
        });
      } else {
        filteredAssetIds = assetIds;
      }

      return filteredAssetIds.sort((assetAId, assetBId) => {
        let assetA = { ...assetService.getAsset(assetAId)!, ...getRateOnce(assetAId) };
        let assetB = { ...assetService.getAsset(assetBId)!, ...getRateOnce(assetBId) };

        // flips the list if the filter is ascending or descending
        if (!ascending) [assetA, assetB] = [assetB, assetA];

        switch (sort) {
          case AssetSort.Gainer:
          case AssetSort.Loser:
          case AssetSort.PercentChange: {
            switch (percentDisplay) {
              case PercentTimeFrame.Day: {
                const changeDayA = parseFloat(assetA.dailyPriceChange ?? 0.0);
                const changeDayB = parseFloat(assetB.dailyPriceChange ?? 0.0);
                return changeDayA - changeDayB;
              }

              case PercentTimeFrame.Week: {
                const changeWeekA = assetA.priceChange.week;
                const changeWeekB = assetB.priceChange.week;
                return changeWeekA - changeWeekB;
              }

              case PercentTimeFrame.Month: {
                const changeMonthA = assetA.priceChange.month;
                const changeMonthB = assetB.priceChange.month;
                return changeMonthA - changeMonthB;
              }

              default: {
                const assetAChange = parseFloat(assetA.dailyPriceChange ?? 0.0);
                const assetBChange = parseFloat(assetB.dailyPriceChange ?? 0.0);
                return assetAChange - assetBChange;
              }
            }
          }
          case AssetSort.Volume: {
            const assetAVolume = Number(assetA.volume[FiatIdEnum.AUD]?.day);
            const assetBVolume = Number(assetB.volume[FiatIdEnum.AUD]?.day);
            return assetAVolume - assetBVolume;
          }
          case AssetSort.BuyPrice:
            return parseFloat(assetA?.askPrice) - parseFloat(assetB?.askPrice);
          case AssetSort.Rank:
            return assetA.rank - assetB.rank;
          case AssetSort.Name:
            // eslint-disable-next-line no-nested-ternary
            return assetA.name < assetB.name ? -1 : assetA.name > assetB.name ? 1 : 0;
          case AssetSort.MostRecent:
            return assetB.id - assetA.id;
          case AssetSort.New:
            // eslint-disable-next-line no-nested-ternary
            return assetA.id < assetB.id ? -1 : assetA.id > assetB.id ? 1 : 0;
          case AssetSort.MarketCap: {
            const rateA = rates?.[assetA.id];
            const rateB = rates?.[assetB.id];
            const priceA = rateA?.midPrice;
            const priceB = rateB?.midPrice;

            return (
              Number(Big(assetA.circulatingSupply).times(Big(priceA))) -
              Number(Big(assetB.circulatingSupply).times(Big(priceB)))
            );
          }
          case AssetSort.Type: {
            return assetA.assetType > assetB.assetType ? 1 : -1;
          }
          case AssetSort.Balance: {
            const aBalance = Number(balances[assetA.id]?.availableBalance ?? '0');
            const bBalance = Number(balances[assetB.id]?.availableBalance ?? '0');

            return aBalance > bBalance ? 1 : -1;
          }
          default:
            return 0;
        }
      });
    },
    [balances, getRateOnce, rates],
  );

  return {
    sortAssets,
    sortWallets,
  };
};

export { useSort };

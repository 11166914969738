import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Loading, SwyftxFull } from '@swyftx/aviary/icons/outlined';

import { api } from '@shared/api';
import { MaintenanceState } from '@shared/api/@types/maintenance';
import logger from '@shared/logger';
import { AppStore } from '@shared/store';
import { formatDate, formatTime } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { IncidentType, MaintenanceStatus } from './Maintenance.types';

const LOG_TAG = '[MAINTENANCE]';

const Maintenance: React.FC = observer(() => {
  const { t } = useTranslation('common', { keyPrefix: 'maintenanceMode' });
  const { isMaintenanceMode, setIsMaintenanceMode } = AppStore.useAppStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [maintenanceTitle, setMaintenanceTitle] = useState<string>(t('unplanned.header'));
  const [maintenanceMessage, setMaintenanceMessage] = useState<string>('unplanned.detail');
  const [, setMaintenanceButton] = useState<string>(t('unplanned.button'));
  const [scheduledUntil, setScheduledUntil] = useState<string | undefined>();
  const [maintenanceStatus, setMaintenanceStatus] = useState<MaintenanceStatus | undefined>();

  const getMaintenanceStatus = async () => {
    try {
      let activeIncident: MaintenanceStatus | undefined;
      let activeMaintenance: MaintenanceStatus | undefined;

      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: { incidents, scheduled_maintenances },
      } = await api.endpoints.maintenanceSummary();

      incidents.forEach((incident: any) => {
        const incidentUpdate: MaintenanceStatus = incident.incident_updates?.reduce(
          (latest: Record<string, string | number>, update: Record<string, string | object>) => {
            // Get the latest status of an incident
            if (
              (!latest || new Date(`${latest.updatedAt}`).getTime() < new Date(`${update.updated_at}`).getTime()) &&
              update.status !== 'completed'
            ) {
              return {
                body: update.body as string,
                updatedAt: update.updated_at as string,
                status: update.status as string,
                type: IncidentType.UNPLANNED,
              };
            }

            return latest;
          },
          null,
        );

        // Store the latest incident
        if (
          !activeIncident ||
          new Date(`${activeIncident.updatedAt}`).getTime() < new Date(`${incidentUpdate.updatedAt}`).getTime()
        ) {
          activeIncident = incidentUpdate;
        }
      });

      scheduled_maintenances.forEach((maintenance: any) => {
        const scheduledForUtc = maintenance.scheduled_for;
        const scheduledUntilUtc = maintenance.scheduled_until;
        const scheduledId = maintenance.id;

        const maintenanceUpdate: MaintenanceStatus = maintenance.incident_updates?.reduce(
          (latest: Record<string, string | number>, update: Record<string, string | object>) => {
            if (latest && latest.status === 'in_progress' && update.status !== 'in_progress') {
              // Return the maintenance info. if is in progress and skip the scheduled maintenances
              return latest;
            }

            // Get the latest status of a maintenance
            if (
              (!latest || new Date(`${latest.updatedAt}`).getTime() < new Date(`${update.updated_at}`).getTime()) &&
              update.status !== 'completed'
            ) {
              const scheduledUntilDate = new Date(scheduledUntilUtc);
              const currentScheduledUntil = `${formatTime(scheduledUntilDate)} ${formatDate(scheduledUntilDate)}`;

              return {
                body: update.body as string,
                updatedAt: update.updated_at as string,
                status: update.status as string,
                type: IncidentType.PLANNED,
                scheduledId,
                scheduledForUtc,
                scheduledUntilUtc,
                scheduledUntil: currentScheduledUntil,
              };
            }

            return latest;
          },
          null,
        );

        // 'in_progress' maintenance should be the first choice to display
        if (
          !activeMaintenance ||
          (new Date(`${activeMaintenance.scheduledForUtc}`).getTime() >
            new Date(`${maintenanceUpdate.scheduledForUtc}`).getTime() &&
            !(activeMaintenance.status === 'in_progress' && maintenanceUpdate.status !== 'in_progress'))
        ) {
          // Store the latest maintenance
          activeMaintenance = maintenanceUpdate;
        }
      });

      setMaintenanceStatus(activeMaintenance || activeIncident);
    } catch (ex) {
      logger.log(LOG_TAG, 'Failed to retrieve maintenance status', { ts: Date.now() });
    }
  };

  const checkMaintenanceMode = async () => {
    if (!isMaintenanceMode) {
      // if not in maintenance mode stop timer
      return;
    }

    let inMaintenance = true;

    try {
      const res = await api.endpoints.maintenanceInfo();
      inMaintenance = res.data.state !== MaintenanceState.COMPLETE || res.data.maintenanceMode;
    } catch (ex) {
      logger.warn(LOG_TAG, 'Unable to check for maintenance info', ex);
    }

    if (inMaintenance) {
      // update maintenance info
      getMaintenanceStatus();
    } else {
      logger.log(LOG_TAG, 'Out of maintenance mode', { ts: Date.now() });
      setIsMaintenanceMode(false);
    }
  };

  useEffect(() => {
    checkMaintenanceMode();
  }, []);

  useEffect(() => {
    if (maintenanceStatus) {
      const { type } = maintenanceStatus;

      setMaintenanceTitle(IncidentType.PLANNED === type ? `${t('planned.header')}` : `${t('unplanned.header')}`);

      setMaintenanceButton(IncidentType.PLANNED === type ? `${t('planned.button')}` : `${t('unplanned.button')}`);

      if (type === IncidentType.PLANNED) {
        if (maintenanceStatus.scheduledUntil) {
          setScheduledUntil(maintenanceStatus.scheduledUntil);
          setMaintenanceMessage('planned.detailWithDateTime');
        } else {
          setScheduledUntil(undefined);
          setMaintenanceMessage('planned.detail');
        }
      } else {
        setScheduledUntil(undefined);
        setMaintenanceMessage('unplanned.detail');
      }
    }
  }, [maintenanceStatus, t]);

  return (
    <FlexLayout
      direction='column'
      alignItems='center'
      justifyContent='center'
      className='h-full w-full p-0'
      spacing={12}
    >
      <div className='left-0'>
        <SwyftxFull className='h-[225px] w-[225px] text-color-text-primary' alt='logo' />
      </div>
      <FlexLayout direction='column' alignItems='center' justifyContent='start' spacing={16} className='h-full w-full'>
        <Image image='maintenance' className='h-[125px] w-[125px]' usePalette alt='maintenance' />
        <Heading size='h2' className='text-center'>
          {isFeatureEnabled(AppFeature.MaintenanceMode)
            ? 'Our site is currently undergoing maintenance'
            : maintenanceTitle}
        </Heading>
        <Body className='w-full max-w-[600px] text-center' color='secondary'>
          {isFeatureEnabled(AppFeature.MaintenanceMode) && (
            <>
              We are currently performing maintenance across the entire platform. Your funds are safe, and we will be
              back up and running shortly.
            </>
          )}
          {!isFeatureEnabled(AppFeature.MaintenanceMode) && (
            <Trans
              t={t}
              i18nKey={maintenanceMessage as any}
              components={{
                time: (
                  <Body color='primary' weight='emphasis'>
                    {scheduledUntil}
                  </Body>
                ),
              }}
            />
          )}
        </Body>
        {!isFeatureEnabled(AppFeature.MaintenanceMode) && (
          <FlexLayout direction='row' spacing={8} alignItems='center'>
            <Loading className='h-18 w-18 animate-spin text-color-text-primary' />
            <Body color='primary'>{t('retrying')}</Body>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

export { Maintenance };

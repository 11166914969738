import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

export const useBaseAsset = () => {
  const { userBaseCurrency, userProfile, baseAsset: userBaseAsset } = UserStore.useUserStore;

  const baseAsset = assetService.getAsset(userBaseCurrency || userProfile?.currency.id) || userBaseAsset;

  return baseAsset;
};

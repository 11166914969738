import React from 'react';

import { observer } from 'mobx-react-lite';

import { VerificationCardWidget } from '../VerificationCardWidget';
import { VerificationCompactWidget } from '../VerificationCompactWidget';

const VerificationWidget: React.FC = observer(() => (
  <>
    <VerificationCardWidget className='hidden @sm:block' />
    <VerificationCompactWidget className='block @sm:hidden' />
  </>
));

export { VerificationWidget };

import { CountryCode } from 'libphonenumber-js';
import parseMobile from 'libphonenumber-js/mobile';

import { Validator, ValidatorResponse } from '.';

class PhoneNumberValidator extends Validator<string> {
  country: string;

  constructor(country: string, message: string) {
    super(message);
    this.country = country;
  }

  validate = (input: string): ValidatorResponse => {
    const pn = parseMobile(input, this.country as CountryCode);

    const valid = Boolean(pn && pn.isValid() && pn.country === this.country);

    return {
      message: valid ? undefined : this.message,
      valid,
    };
  };
}

export default PhoneNumberValidator;

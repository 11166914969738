import React from 'react';

import MenuItemMUI, { MenuItemProps } from '@mui/material/MenuItem';

const MenuItem: React.FC<MenuItemProps> = ({ id, children, ...props }) => (
  <MenuItemMUI id={id} {...props}>
    {children}
  </MenuItemMUI>
);

export { MenuItem };

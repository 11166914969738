import React, { useMemo } from 'react';

import { Notification } from '@swyftx/aviary/atoms/Notification';

import LimitedSendNotification from '@global-components/LimitedSendNotification/LimitedSendNotification';
import LimitedTradeNotification from '@global-components/LimitedTradeNotification/LimitedTradeNotification';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

type Props = {
  asset: Asset;
  renderContent?: (content: JSX.Element) => JSX.Element; // Allows us to use the conditional rendering from inside here with a custom container in higher context
};

const LimitedFunctionalityNotification: React.FC<Props> = ({ asset, renderContent }) => {
  const isRestricted = useMemo(() => !assetService.canWithdrawAndDepositAsset(asset), [asset]);

  const isTradeRestricted = useMemo(() => asset.buyDisabled || !asset.sellEnabled, [asset]);

  if (!isRestricted && !isTradeRestricted) return null;

  const Content = (
    <Notification severity='warning' title='Limited functionality' className='p-12'>
      {isRestricted && <LimitedSendNotification asset={asset} />}
      {isTradeRestricted && <LimitedTradeNotification asset={asset} />}
    </Notification>
  );

  if (renderContent) {
    return renderContent(Content);
  }

  return Content;
};

export { LimitedFunctionalityNotification };

import { useCallback, useEffect, useMemo } from 'react';

import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types';

type Props<T> = {
  widgetId?: DashboardWidgets;
  items: T[];
  itemsPerPage: number;
};

const useItemPagination = <T>({ widgetId, items, itemsPerPage }: Props<T>) => {
  const [page, setPage] = useDashboardSyncState<number>({ widgetId, stateKey: 'page', defaultValue: 0 });

  useEffect(() => {
    if (items.length <= itemsPerPage && page > 0) {
      setPage(0);
    }
  }, [items.length, itemsPerPage, page, setPage]);

  const hasAnotherPage = useMemo(() => items.length > itemsPerPage + page, [items.length, itemsPerPage, page]);

  const nextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const prevPage = useCallback(() => {
    if (page === 0) return;

    setPage(page - 1);
  }, [page, setPage]);

  return {
    page,
    nextPage,
    prevPage,
    hasAnotherPage,
  };
};

export { useItemPagination };

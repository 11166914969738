import { useCallback } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { formatDate, formatTime, formatValueToCurrencyShorthand } from '@swyftx/aviary/utils';
import { CurrencyType } from '@swyftx/aviary/utils/currency/currency.types';

import * as d3 from 'd3';
import { DateTime } from 'luxon';

import { LINE_CHART_SETTINGS } from '../LineChart.const';
import { LineChartData } from '../LineChart.types';

type Props = {
  idKey: string;
  container: d3.Selection<SVGSVGElement | null, unknown, null, undefined>;
  showAxes?: boolean;
  y: d3.ScaleLinear<number, number, never>;
  x: d3.ScaleTime<number, number, never>;
  width: number;
  height: number;
  margins: any; //TODO type this
  xTicks: number;
  yTicks: number;
  chartData: LineChartData;
  currencyCode: string;
  currencyType: CurrencyType;
  loading?: boolean;
};

const { axisFontSize } = LINE_CHART_SETTINGS;
const MAX_AXIS = 5;
const MAX_AXIS_XS = 3;

const useDrawAxis = ({
  idKey,
  container,
  showAxes = false,
  y,
  x,
  width,
  height,
  margins,
  yTicks,
  chartData,
  currencyCode,
  currencyType,
}: Props) => {
  const isXs = useTailwindBreakpoint('xs');

  const drawAxisElements = useCallback(() => {
    if (!showAxes) return;

    const startDate = chartData.length ? DateTime.fromJSDate(chartData[0].time) : null;
    const endDate = chartData.length ? DateTime.fromJSDate(chartData[chartData.length - 1].time) : null;
    const dayDifference = startDate && endDate ? startDate.diff(endDate, ['days']).toObject().days : null;
    const showAsTime = dayDifference !== null && dayDifference !== undefined && Math.abs(dayDifference) < 2;

    // Draw the axes
    const yAxisTicks = d3
      .axisRight(y)
      .ticks(yTicks)
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickPadding(0)
      .tickFormat((value) =>
        formatValueToCurrencyShorthand(value.toString(), currencyType, currencyCode, {
          includeThousands: true,
          fixedTo: 1,
        }),
      );
    const yAxis = container.select<SVGSVGElement>(`#y-axis-${idKey}`);
    yAxis
      .call(yAxisTicks)
      .call((g) => g.select('.domain').remove())
      .attr('color', 'var(--color-text-secondary)')
      .attr('stroke-opacity', 0)
      .selectAll('text')
      .attr('class', 'font-mono')
      .attr('transform', `translate(0, -${axisFontSize / 2})`)
      .attr('font-size', axisFontSize);

    // Ignore the first datapoint when we are calculating the axis as it is our realtime point.
    // We can just scope the first axis to our latest snapshot date.
    const data = chartData.slice(1);

    // The new logic is to create an X axis point for the first and last points
    // We then need to use an arbitary number for the amount of axis points between the start and end
    // 5 on a large screen and 3 on a smaller screen appears to be the sweet spot
    const maxAxis = isXs ? MAX_AXIS_XS : MAX_AXIS;

    // Calculate the "ticks" (each x axis element)
    const tickValues = data
      .filter((d, index) => {
        // Get the amount of data points remaining after processing the first and last data points
        const remainingAfterStartAndEnd = data.length - 2;

        // if we have less than our buffer, just return all
        if (remainingAfterStartAndEnd <= maxAxis) return true;

        // Automatically accept the first and last data point as these should always be visible
        if (index === 0 || index === data.length - 1) return true;

        // Work out how many gaps there should be between each axis calculation
        // for example 20 remaining data points with a max of 5 data points, it should be every ~4 steps
        const stepIndex = Math.ceil(data.length / maxAxis);

        // take the current index, divide by our steps and when the numbers are divisible, render an axis point
        return index % stepIndex === 0;
      })
      .map((d) => d.time);

    const xAxisTicks = d3
      .axisBottom(x)
      .tickValues(tickValues)
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickFormat((date) => (showAsTime ? formatTime(date as Date) : formatDate(date as Date)));
    const xAxis = container.select<SVGSVGElement>(`#x-axis-${idKey}`);

    xAxis
      .attr('color', 'var(--color-text-secondary)')
      .call(xAxisTicks)
      .attr('transform', `translate(0, ${height - margins.marginBottom + axisFontSize / 2})`)
      .attr('class', 'font-mono')
      .attr('font-size', axisFontSize)
      .attr('stroke-opacity', 0);

    // Grid-lines
    const gridLines = d3.axisLeft(y).ticks(yTicks).tickSize(-width);
    const gridLinesContainer = container.select<SVGSVGElement>(`#y-axis-grid-lines-${idKey}`);
    gridLinesContainer
      .call(gridLines)
      .attr('opacity', 0.05)
      .call((g) => g.select('.domain').remove());
  }, [
    chartData,
    container,
    currencyCode,
    currencyType,
    height,
    idKey,
    margins.marginBottom,
    showAxes,
    width,
    x,
    y,
    yTicks,
    isXs,
  ]);

  return { drawAxisElements };
};

export { useDrawAxis };

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { HistoricalOrder, OrderType } from '@shared/api';
import { OrderStatusEnum } from '@shared/enums';
import { TransactionHistoryItem } from '@shared/services';
import { UserStore } from '@shared/store';

import { EntityPermissions, usePermissions } from '@hooks/Permissions';

import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

interface Props {
  order: HistoricalOrder;
  transaction?: TransactionHistoryItem;
  setDeleteOpen: () => void;
  downloadUserOrder: () => Promise<void>;
  viewAsset: () => void;
  onEditOrder: (order?: HistoricalOrder) => void;
  onReOrder: () => void;
}

export const OrderDetailsFooter: React.FC<Props> = (props) => {
  const { order, setDeleteOpen, onEditOrder, downloadUserOrder, viewAsset, onReOrder } = props;
  const { isEntity } = UserStore.useUserStore;
  const { isSwyftxPro } = useSwyftxPro();

  const { enabled: tradeEnabled, validKYC } = usePermissions({
    permissions: [EntityPermissions.ORDERS_CREATE],
  });

  const isOtcOrder = [OrderType.OTCBuy, OrderType.OTCSell].includes(order.order_type);
  const isDustOrder = OrderType.DustSell === order.order_type;
  const showReorderButton = useMemo(
    () => !isSwyftxPro && !isOtcOrder && !isDustOrder && (!isEntity() || (validKYC && tradeEnabled)),
    [isSwyftxPro, isOtcOrder, isDustOrder, isEntity, validKYC, tradeEnabled],
  );

  const footer = useMemo(() => {
    switch (order?.status) {
      case OrderStatusEnum.OPEN:
      case OrderStatusEnum.PARTIAL_FILL:
        return (
          <>
            <Button size='lg' variant='outlined' onClick={setDeleteOpen}>
              Cancel order
            </Button>
            <Button size='lg' onClick={() => onEditOrder(order)}>
              Edit order
            </Button>
          </>
        );
      case OrderStatusEnum.SYSTEM_CANCELLED:
      case OrderStatusEnum.USER_CANCELLED:
      case OrderStatusEnum.REFUNDED:
        return (
          <Button size='lg' onClick={viewAsset}>
            View asset
          </Button>
        );
      case OrderStatusEnum.INSUFFICIENT_BALANCE:
      case OrderStatusEnum.FAILED:
      case OrderStatusEnum.FAILED_MAX_AMOUNT:
      case OrderStatusEnum.FAILED_MIN_AMOUNT:
        return (
          <>
            <Button size='lg' variant='outlined' onClick={onReOrder}>
              Try again
            </Button>
            <Button size='lg' onClick={viewAsset}>
              View asset
            </Button>
          </>
        );
      default:
        return (
          <>
            {showReorderButton && (
              <Button size='lg' variant='outlined' onClick={onReOrder}>
                Order again
              </Button>
            )}
            <Button size='lg' onClick={downloadUserOrder}>
              Download invoice
            </Button>
          </>
        );
    }
  }, [downloadUserOrder, onEditOrder, onReOrder, order?.status, setDeleteOpen, showReorderButton, viewAsset]);

  return (
    <FlexLayout className='w-full flex-col-reverse p-12 pb-8 sm:flex-row' justifyContent='end' spacing={8}>
      {footer}
    </FlexLayout>
  );
};

import React from 'react';

import { pxToRem, Typography, TypographyProps } from '@swyftx/react-web-design-system';

const defaultTypographyProps: TypographyProps = {
  fontSize: pxToRem(14),
  fontWeight: 500,
};

type Props = {
  labelTypographyProps?: TypographyProps;
};

export const RequiredAsterisk: React.FC<Props> = ({ labelTypographyProps = defaultTypographyProps }) => (
  <Typography color='error.main' {...labelTypographyProps} display='inline'>
    *
  </Typography>
);

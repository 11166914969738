import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Danger } from '@swyftx/aviary/icons/outlined';

import { observer } from 'mobx-react-lite';

import { useAutoInvestFailure } from '../hooks';

const AutoInvestWidgetFailure: React.FC = observer(() => {
  const { onCancel } = useAutoInvestFailure();

  return (
    <FlexLayout direction='column' spacing={24} alignItems='center' justifyContent='start' className='h-full w-full'>
      <FlexLayout direction='column' className='h-full w-full p-16 sm:p-24' spacing={32}>
        <FlexLayout direction='column' alignItems='center' spacing={8}>
          <Icon color='destructive' className='h-48 w-48 p-8' icon={<Danger className='h-32 w-32' />} />
          <Heading color='primary' size='h3'>
            Order creation failed
          </Heading>
        </FlexLayout>
        <Body color='secondary' className='text-center'>
          Unfortunately we ran into an issue while trying to create your order. Please try again.
        </Body>

        <FlexLayout className='h-full w-full' alignItems='end' justifyContent='end' direction='column' spacing={12}>
          <Button size='lg' variant='outlined' className='w-full' onClick={onCancel}>
            Cancel and discard order
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AutoInvestWidgetFailure };

import { Config } from './@types/Config';

if (import.meta.hot || import.meta.env.MODE === 'test') {
  const reactPrefixKeys = Object.keys(import.meta.env)
    .filter((key) => key.startsWith('REACT_APP_'))
    .reduce((obj, key) => {
      obj[key.replace('REACT_APP_', '')] = import.meta.env[key];
      return obj;
    }, {} as Record<string, any>);

  // @ts-ignore
  window.ENV_CONFIG = { ...window.ENV_CONFIG, ...reactPrefixKeys };
}

// Acquire the current environment variables from a window variable.
// This is set in a script loaded by the HTML, env.js.
// @ts-ignore
export default window.ENV_CONFIG as Config;

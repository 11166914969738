import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { CameraFilled, ScanFilled } from '@swyftx/aviary/icons/filled';

type Props = {
  onClose: () => void;
  onAction: () => void;
};

export const JumioWizardLandingStep: React.FC<Props> = ({ onClose, onAction }) => {
  const { t } = useTranslation('profile.verification');

  return (
    <FlexLayout direction='column' spacing={16}>
      <Body color='secondary'>{t('jumio.landing.description')}</Body>
      <Body weight='emphasis'>{t('jumio.landing.whatsInvolved')}</Body>
      <FlexLayout direction='column' spacing={12}>
        <Card className='p-16'>
          <FlexLayout direction='row' alignItems='start' spacing={16}>
            <ScanFilled className='!h-24 !w-24' />
            <FlexLayout direction='column' spacing={8}>
              <Body weight='emphasis'>{t('jumio.landing.scanCard.title')}</Body>
              <Body color='secondary' size='small'>
                {t('jumio.landing.scanCard.description')}
              </Body>
              <Body color='secondary' size='small'>
                {t('jumio.landing.scanCard.listItems.acceptedForms')}
              </Body>
              <FlexLayout direction='column' spacing={4}>
                <ul>
                  <li>
                    <Body color='secondary' size='small'>
                      - {t('jumio.landing.scanCard.listItems.driversLicense')}
                    </Body>
                  </li>
                  <li>
                    <Body color='secondary' size='small'>
                      - {t('jumio.landing.scanCard.listItems.identityCard')}
                    </Body>
                  </li>
                </ul>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </Card>
        <Card className='p-16'>
          <FlexLayout direction='row' alignItems='start' spacing={16}>
            <CameraFilled className='!h-24 !w-24' />
            <FlexLayout direction='column' spacing={8}>
              <Body weight='emphasis'> {t('jumio.landing.photoCard.title')}</Body>
              <Body color='secondary' size='small'>
                {t('jumio.landing.photoCard.description')}
              </Body>
            </FlexLayout>
          </FlexLayout>
        </Card>
        <Notification title={t('jumio.landing.notification.title')} severity='info' />
      </FlexLayout>
      <FlexLayout direction='row' className='w-full items-center justify-end pb-2' spacing={8}>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>
        <Button onClick={onAction}>Continue</Button>
      </FlexLayout>
    </FlexLayout>
  );
};

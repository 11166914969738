import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { Rewards } from './Rewards';

const RewardsRoutes: RouteObject[] = [
  {
    path: NavigationURLs.RewardsRoot,
    element: <PrivateRoute element={<Rewards />} />,
  },
];

export { RewardsRoutes };

import React, { useCallback, useMemo } from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { Funnel } from '@swyftx/aviary/icons/outlined';
import { ClearTableFilters } from '@swyftx/aviary/molecules/TableFilters';
import { TableSelectFilter } from '@swyftx/aviary/molecules/TableFilters/TableSelectFilter';

import { Asset } from '@shared/api';

import {
  transactionHistorySides,
  TransactionHistorySides,
  transactionHistoryStatuses,
  TransactionHistoryStatuses,
  TransactionHistoryTab,
} from '@routes/Transactions/types';

import { DateTime } from 'luxon';

import { TransactionsFilterAssets } from './TransactionsFilterAssets';
import { TransactionsFilterDate } from './TransactionsFilterDate';

type Props = {
  filterAsset?: Asset;
  type: TransactionHistoryTab;
  asset?: Asset;
  statuses: TransactionHistoryStatuses[];
  sides: TransactionHistorySides[];
  startDate?: DateTime;
  endDate?: DateTime;
  setAsset: React.Dispatch<React.SetStateAction<Asset | undefined>>;
  setStatuses: React.Dispatch<React.SetStateAction<TransactionHistoryStatuses[]>>;
  setSides: React.Dispatch<React.SetStateAction<TransactionHistorySides[]>>;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
  setEndDate: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
};

const TransactionsFilters: React.FC<Props> = ({
  type,
  filterAsset,
  asset,
  statuses,
  sides,
  startDate,
  endDate,
  setAsset,
  setStatuses,
  setSides,
  setStartDate,
  setEndDate,
}) => {
  const { isSmallScreen } = useTailwindContainerBreakpoint({ containerName: 'transactions-table-card' });

  const resetFilters = useCallback(() => {
    if (!filterAsset) setAsset(undefined);
    setStatuses([]);
    setSides([]);
    setStartDate(undefined);
    setEndDate(undefined);
  }, [filterAsset, setAsset, setEndDate, setSides, setStartDate, setStatuses]);

  const canReset = useMemo(() => {
    if (startDate || endDate) return true;
    if (!filterAsset && asset) return true;
    if (sides.length > 0 || statuses.length > 0) return true;

    return false;
  }, [asset, endDate, filterAsset, sides.length, startDate, statuses.length]);

  const totalFiltersActive = useMemo(() => {
    let totalFilters = 0;

    if (!filterAsset && asset) totalFilters++;
    if (statuses.length > 0) totalFilters++;
    if (sides.length > 0) totalFilters++;
    if (startDate || endDate) totalFilters++;

    return totalFilters;
  }, [asset, endDate, filterAsset, sides.length, startDate, statuses.length]);

  if (isSmallScreen) {
    return (
      <Accordion type='single' className='w-full' collapsible>
        <AccordionItem value='filters' className='rounded-0 border-none'>
          <AccordionTrigger className='rounded-[0%] border-color-border-main data-[state=open]:border-b'>
            <FlexLayout direction='row' alignItems='center' className='w-full' spacing={8}>
              <Funnel className='h-20 w-20 text-color-text-primary' alt='filter' />
              <Body weight='bold'>Filters {totalFiltersActive > 0 ? `(${totalFiltersActive})` : ''}</Body>
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent>
            <FlexLayout
              direction='column'
              className='w-full px-4 pt-8'
              alignItems='center'
              justifyContent='center'
              spacing={16}
            >
              <TransactionsFilterDate
                className='w-full'
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                isSmallScreen={isSmallScreen}
              />
              {!filterAsset && (
                <TransactionsFilterAssets
                  asset={asset}
                  className='w-full'
                  setAsset={setAsset}
                  isSmallScreen={isSmallScreen}
                />
              )}
              {type === 'orders' && (
                <TableSelectFilter<TransactionHistorySides>
                  title='Side'
                  items={transactionHistorySides}
                  value={sides}
                  className='w-full'
                  onChange={(values) => setSides(values)}
                  isSmallScreen={isSmallScreen}
                />
              )}
              <TableSelectFilter<TransactionHistoryStatuses>
                title='Status'
                items={transactionHistoryStatuses(type)}
                value={statuses}
                className='w-full'
                onChange={(values) => setStatuses(values)}
                isSmallScreen={isSmallScreen}
              />
              <Button
                variant='ghost'
                color='subtle'
                className='w-full'
                size='md'
                onClick={resetFilters}
                disabled={!canReset}
              >
                Reset filters
              </Button>
            </FlexLayout>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <FlexLayout alignItems='center' spacing={8} className='px-16 py-8'>
      <TransactionsFilterDate
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        isSmallScreen={false}
      />
      {!filterAsset && <TransactionsFilterAssets asset={asset} setAsset={setAsset} isSmallScreen={false} />}
      {type === 'orders' && (
        <TableSelectFilter<TransactionHistorySides>
          title='Side'
          items={transactionHistorySides}
          value={sides}
          className='w-full'
          onChange={(values) => setSides(values)}
          isSmallScreen={false}
        />
      )}

      <TableSelectFilter<TransactionHistoryStatuses>
        title='Status'
        items={transactionHistoryStatuses(type)}
        value={statuses}
        className='w-full'
        onChange={(values) => setStatuses(values)}
        isSmallScreen={false}
      />

      <ClearTableFilters onClick={resetFilters} disabled={!canReset} />
    </FlexLayout>
  );
};

export { TransactionsFilters };

import React, { useMemo } from 'react';

import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';
import { SingleDatePicker } from '@swyftx/aviary/molecules/SingleDatePicker/SingleDatePicker';

import { RecurringOrderFrequency } from '@shared/api/@types/recurringOrder';

import { DateTime } from 'luxon';

import { useAutoInvestBalanceCheck } from '../../widgets/CreateAutoInvestWidget/hooks/useAutoInvestBalanceCheck';

type Props = {
  startDate?: DateTime;
  fromDate?: DateTime;
  onStartDateChange: (value?: DateTime) => void;
  frequency?: RecurringOrderFrequency;
  onFrequencyChange: (value: RecurringOrderFrequency) => void;
};

const AutoInvestDateFrequency: React.FC<Props> = ({
  startDate,
  fromDate = DateTime.now().startOf('day'),
  onStartDateChange,
  frequency,
  onFrequencyChange,
}) => {
  const { diffDayText } = useAutoInvestBalanceCheck({ startDate });

  const frequencyOptions: SelectItemData[] = useMemo(
    () => [
      {
        label: 'Daily',
        secondaryLabel: `Everyday, starting ${diffDayText}`,
        value: 'DAILY',
      },
      {
        label: 'Weekly',
        secondaryLabel: `Every 7 days, starting ${diffDayText}`,
        value: 'WEEKLY',
      },
      {
        label: 'Fortnightly',
        secondaryLabel: `Every 14 days, starting ${diffDayText}`,
        value: 'FORTNIGHTLY',
      },
      {
        label: 'Monthly',
        secondaryLabel: `Every 30 days, starting ${diffDayText}`,
        value: 'MONTHLY',
      },
    ],
    [diffDayText],
  );

  return (
    <FlexLayout direction='row' className='w-full' alignItems='start' justifyContent='start' spacing={12}>
      <div className='w-1/2'>
        <FlexLayout direction='column' spacing={8}>
          <Label>Start date</Label>
          <SingleDatePicker
            id='auto-invest-start-date'
            title='Start date'
            fromDate={fromDate.toJSDate()}
            toDate={DateTime.now().startOf('day').plus({ months: 2 }).toJSDate()}
            value={startDate}
            onChange={onStartDateChange}
          />
        </FlexLayout>
      </div>
      <div className='w-1/2'>
        <Select
          className='h-46'
          title='Frequency'
          items={frequencyOptions}
          value={frequency}
          onValueChange={(value) => onFrequencyChange(value as RecurringOrderFrequency)}
        />
      </div>
    </FlexLayout>
  );
};

export { AutoInvestDateFrequency };

import React, { useCallback, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardContent, CardHeader } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AssetGroupCard } from '@swyftx/aviary/complex/AssetGroupCard';
import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useMarketActivity } from '../../hooks/useMarketActivity';
import { useMarkets } from '../../hooks/useMarkets';
import { MarketActivityType, defaultMarketActivityTabs } from '../../types/Markets.types';
import { getEmptyContent, getEmptyIcon } from '../../utils';

const widgetId: DashboardWidgets = 'market-activity';

const MarketActivityCompactWidget: React.FC = observer(() => {
  const [currentTab, setCurrentTab] = useDashboardSyncState<MarketActivityType>({
    widgetId,
    stateKey: 'tabs',
    defaultValue: 'favourites',
  });
  const baseAsset = useBaseAsset();
  const { assets } = useMarkets();
  const favouriteAssets = useFavouriteAssets();
  const { gainers, losers, newListings } = useMarketActivity({ assets });
  const { navigateToAsset } = useAssetNavigation();

  const { navigate } = useNavigateRoute();

  const cardAssets = useMemo(() => {
    switch (currentTab) {
      case 'favourites':
        return favouriteAssets.slice(0, 10);
      case 'gainers':
        return gainers;
      case 'losers':
        return losers;
      case 'new':
        return newListings;
      default:
        return [];
    }
  }, [currentTab, favouriteAssets, gainers, losers, newListings]);

  const viewAll = useCallback(() => {
    switch (currentTab) {
      case 'favourites':
        if (cardAssets.length < 10) {
          navigate(NavigationURLs.AssetsAll);
        } else {
          navigate(NavigationURLs.AssetsFavourites);
        }
        break;
      case 'gainers':
        navigate(NavigationURLs.AssetsGainers);
        break;
      case 'losers':
        navigate(NavigationURLs.AssetsLosers);
        break;
      case 'new':
        navigate(NavigationURLs.AssetsNew);
        break;
      default:
        break;
    }
  }, [cardAssets.length, currentTab, navigate]);

  if (!baseAsset) return null;

  return (
    <ElementContainer title='Market activity' className='w-full'>
      <Card className='min-h-[300px] w-full overflow-hidden'>
        <CardHeader className='p-0'>
          <FlexLayout className='overflow-y-auto'>
            <div className='p-16 @sm:p-24'>
              <EnhancedTabs value={currentTab} onChange={setCurrentTab} tabs={defaultMarketActivityTabs} />
            </div>
          </FlexLayout>
        </CardHeader>
        <CardContent className='h-full p-0 pb-8'>
          <FlexLayout direction='column' justifyContent='space-between' className='h-full'>
            <AssetGroupCard
              border={false}
              forceChart
              assets={cardAssets}
              baseAsset={baseAsset}
              className=' h-full min-h-[404px] w-full'
              numAssets={10}
              loaded
              emptyContent={
                <FlexLayout
                  className='h-full min-h-[400px] w-full p-24'
                  alignItems='center'
                  justifyContent='center'
                  direction='column'
                  spacing={8}
                >
                  {getEmptyIcon(currentTab)}
                  <Body className='text-center' color='secondary' size='small' weight='emphasis'>
                    {getEmptyContent(currentTab)}
                  </Body>
                </FlexLayout>
              }
              onSelectAsset={navigateToAsset}
            />
            {cardAssets.length >= 10 && (
              <div className='p-16 @sm:p-24'>
                <Button className='w-full' color='subtle' trailingIcon={<ArrowLineOnlyRight />} onClick={viewAll}>
                  View all
                </Button>
              </div>
            )}
            {cardAssets.length < 10 && currentTab === 'favourites' && (
              <div className='p-16 @sm:p-24'>
                <Button className='w-full' color='subtle' trailingIcon={<ArrowLineOnlyRight />} onClick={viewAll}>
                  Explore asssets
                </Button>
              </div>
            )}
          </FlexLayout>
        </CardContent>
      </Card>
    </ElementContainer>
  );
});

export { MarketActivityCompactWidget };

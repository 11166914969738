import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Callout } from '@swyftx/aviary/atoms/Typography/Heading/Callout';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { Delete } from '@swyftx/aviary/icons/outlined';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { CreatePriceAlertModal } from 'src/lib/dynamic-price-alerts/components/CreatePriceAlertModal';
import { DeleteAllPriceAlertModal } from 'src/lib/dynamic-price-alerts/components/DeleteAllPriceAlertModal';
import { useDynamicPriceAlertFilters } from 'src/lib/dynamic-price-alerts/components/DynamicPriceAlertFilters/useDynamicPriceAlertFilters';
import { PriceAlertType } from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import { useFetchDynamicPriceAlertsCache } from 'src/lib/dynamic-price-alerts/hooks';

import { DynamicPriceAlertsActiveTable } from './components/DynamicPriceAlertsActiveTable/DynamicPriceAlertsActiveTable';
import { DynamicPriceAlertsFilterBar } from './components/DynamicPriceAlertsFilterBar/DynamicPriceAlertsFilterBar';
import { DynamicPriceAlertsTriggeredTable } from './components/DynamicPriceAlertsTriggeredTable/DynamicPriceAlertsTriggeredTable';

type Props = {
  className?: string;
};

type LocationState = {
  triggers?: number[];
};

const DynamicPriceAlertsPage: React.FC<Props> = observer(({ className }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { t } = useTranslation('priceAlerts');
  const { state } = useLocation();
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();
  const [currentTab, setCurrentTab] = useState<PriceAlertType>(state?.triggers?.length > 0 ? 'triggered' : 'active');
  const { groupBy, setGroupBy, filterTypes, setFilterTypes, filterAssets, setFilterAssets, onRemoveAllFilters } =
    useDynamicPriceAlertFilters();

  const { atLeast } = useTailwindContainerBreakpoint({
    containerName: 'dynamic-alerts-page',
  });

  useEffect(
    () => () => {
      invalidateCache();
    },
    [invalidateCache],
  );

  return (
    <>
      <Page
        data-container='dynamic-alerts-page'
        showCompactHeader={false}
        data-spotlightelementid='dynamic-alerts-page'
        alignment='center'
        className={cn('pr-0 @container sm:!mt-0 sm:!pt-0', className)}
        offset={false}
        customHeader={
          <FlexLayout justifyContent='space-between' className='\ flex-col gap-16 px-1 pt-48 @sm:flex-row'>
            <FlexLayout direction='column' className='order-2 gap-8 @sm:order-1 @sm:w-[50%] @sm:gap-8'>
              <FlexLayout className='inline flex-wrap'>
                <Heading className='inline leading-normal' weight='light' size={atLeast.lg ? 'h2' : 'h3'}>
                  {t('page.title')}{' '}
                </Heading>
                <Heading
                  className='inline leading-normal'
                  weight='light'
                  size={atLeast.lg ? 'h2' : 'h3'}
                  color='accent'
                >
                  <Callout>{t('page.titleCallout')}</Callout>
                </Heading>
              </FlexLayout>
              <Body color='secondary'>{t('page.subtitle')}</Body>
              <div className='mt-16 hidden sm:block'>
                <CreatePriceAlertModal
                  type={isFeatureEnabled(AppFeature.DynamicPriceAlertsRelease2) ? 'multi' : 'single'}
                >
                  <Button size='lg'>{t('buttons.createNew')}</Button>
                </CreatePriceAlertModal>
              </div>
            </FlexLayout>
            <div className='order-1 @sm:order-2'>
              <Image image='price_alerts' usePalette={false} className='h-[128px] @md:h-[152px] @xl:h-[192px]' />
            </div>
          </FlexLayout>
        }
      >
        <PageContent offset={false} className='pb-84 @container @sm:p-32 @md:p-24 @xl:px-48 @xl:py-24'>
          <FlexLayout
            direction='column'
            justifyContent='center'
            alignItems='start'
            spacing={24}
            className='h-full w-full'
          >
            <DynamicPriceAlertsFilterBar
              groupBy={groupBy}
              filterTypes={filterTypes}
              filterAssets={filterAssets}
              setGroupBy={setGroupBy}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setFilterTypes={setFilterTypes}
              setFilterAssets={setFilterAssets}
            />
            {currentTab === 'active' ? (
              <DynamicPriceAlertsActiveTable
                filterTypes={filterTypes}
                filterAssets={filterAssets}
                currentTab={currentTab}
                groupBy={groupBy}
                onRemoveAllFilters={onRemoveAllFilters}
              />
            ) : (
              <DynamicPriceAlertsTriggeredTable
                filterTypes={filterTypes}
                filterAssets={filterAssets}
                currentTab={currentTab}
                triggers={(state as LocationState)?.triggers}
                onRemoveAllFilters={onRemoveAllFilters}
              />
            )}
          </FlexLayout>
        </PageContent>
      </Page>

      <div className='fixed bottom-0 left-0 z-20 h-[80px]  w-[calc(100%-8px)] bg-color-background-pageBG sm:hidden'>
        <FlexLayout alignItems='center' className='h-full w-full px-16' justifyContent='center' spacing={16}>
          <DeleteAllPriceAlertModal alertType={currentTab}>
            <Button layout='icon' size='lg' variant='outlined' leadingIcon={<Delete />} />
          </DeleteAllPriceAlertModal>

          <CreatePriceAlertModal type={isFeatureEnabled(AppFeature.DynamicPriceAlertsRelease2) ? 'multi' : 'single'}>
            <Button size='lg' className='w-full'>
              {t('buttons.createNew')}
            </Button>
          </CreatePriceAlertModal>
        </FlexLayout>
      </div>
    </>
  );
});

export { DynamicPriceAlertsPage };

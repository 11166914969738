import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { RegisterContext } from '@routes/Register/Register.context';

import { observer } from 'mobx-react-lite';

type Props = {
  hideBackButton?: boolean;
  disableButton?: boolean;
  buttonLoading?: boolean;
  onNext?: () => void;
  nextButtonText?: string;
  form?: boolean;
};

const RegisterStepFooter: React.FC<Props> = observer(
  ({ hideBackButton, disableButton = false, buttonLoading = false, onNext, nextButtonText, form = false }) => {
    const { handleBack } = useContext(RegisterContext);
    const { t } = useTranslation('register');

    if (hideBackButton && disableButton) return null;

    return (
      <FlexLayout alignItems='center' spacing={8} className='mb-8s w-full px-4 pb-4'>
        <FlexLayout className='w-full sm:w-1/2'>
          {!hideBackButton && (
            <Button variant='outlined' className='w-full' color='primary' onClick={handleBack} size='lg'>
              {t('registerStep.buttonLabels.back')}
            </Button>
          )}
        </FlexLayout>
        {!disableButton && (
          <FlexLayout className='w-full sm:w-1/2'>
            <Button
              type={form ? 'submit' : 'button'}
              variant='filled'
              size='lg'
              className='w-full'
              onClick={onNext}
              loading={buttonLoading}
            >
              {nextButtonText != null ? nextButtonText : t('registerStep.buttonLabels.skip')}
            </Button>
          </FlexLayout>
        )}
      </FlexLayout>
    );
  },
);

RegisterStepFooter.displayName = 'RegisterStepFooter';

export { RegisterStepFooter };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Coin: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8929 2.75C13.8929 2.33579 14.2287 2 14.6429 2C16.7725 2 18.6297 3.20973 19.9252 5.02333C21.2216 6.83837 22.0001 9.30739 22.0001 12C22.0001 14.6926 21.2216 17.1616 19.9252 18.9767C18.6297 20.7903 16.7725 22 14.6429 22C14.2287 22 13.8929 21.6642 13.8929 21.25C13.8929 20.8358 14.2287 20.5 14.6429 20.5C16.1624 20.5 17.6087 19.6391 18.7046 18.1048C19.7994 16.572 20.5001 14.416 20.5001 12C20.5001 9.58398 19.7994 7.42799 18.7046 5.89519C17.6087 4.36095 16.1624 3.5 14.6429 3.5C14.2287 3.5 13.8929 3.16421 13.8929 2.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.35714 2C7.22755 2 5.37032 3.20973 4.07489 5.02333C2.77843 6.83837 2 9.30739 2 12C2 14.6926 2.77843 17.1616 4.07489 18.9767C5.37032 20.7903 7.22755 22 9.35714 22H14.6429C15.0571 22 15.3931 21.6642 15.3931 21.25C15.3931 20.8358 15.0571 20.5 14.6429 20.5H13.2474C13.5391 20.2537 13.8156 19.9817 14.0752 19.6875H19C19.4142 19.6875 19.75 19.3517 19.75 18.9375C19.75 18.5233 19.4142 18.1875 19 18.1875H15.1467C15.4963 17.5823 15.7946 16.922 16.0341 16.2188H20.5C20.9142 16.2188 21.25 15.883 21.25 15.4688C21.25 15.0545 20.9142 14.7188 20.5 14.7188H16.441C16.5716 14.0845 16.6574 13.4258 16.694 12.75H21C21.4142 12.75 21.75 12.4142 21.75 12C21.75 11.5858 21.4142 11.25 21 11.25H16.694C16.6574 10.5742 16.5716 9.91552 16.441 9.28125H20.5C20.9142 9.28125 21.25 8.94546 21.25 8.53125C21.25 8.11704 20.9142 7.78125 20.5 7.78125H16.0341C15.7946 7.07799 15.4963 6.41769 15.1467 5.8125H19C19.4142 5.8125 19.75 5.47671 19.75 5.0625C19.75 4.64829 19.4142 4.3125 19 4.3125H14.0752C13.8156 4.01829 13.5391 3.74628 13.2474 3.5H14.6429C15.0571 3.5 15.3931 3.16421 15.3931 2.75C15.3931 2.33579 15.0571 2 14.6429 2H9.35714ZM3.5 12C3.5 9.58398 4.20063 7.42799 5.29549 5.89519C6.39137 4.36095 7.83771 3.5 9.35714 3.5C10.8766 3.5 12.3229 4.36095 13.4188 5.89519C14.5137 7.42799 15.2143 9.58398 15.2143 12C15.2143 14.416 14.5137 16.572 13.4188 18.1048C12.3229 19.6391 10.8766 20.5 9.35714 20.5C7.83771 20.5 6.39137 19.6391 5.29549 18.1048C4.20063 16.572 3.5 14.416 3.5 12Z'
      fill='currentColor'
    />
  </svg>
);

export { Coin };

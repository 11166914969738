import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { HistoricalOrder } from '@shared/api';
import { assetService } from '@shared/services';

import { useOrderAmount } from '../../hooks/useOrderAmount';

interface Props {
  order: HistoricalOrder;
}

export const OrderInfoAmount: React.FC<Props> = (props) => {
  const { order } = props;
  const asset = assetService.getAsset(order?.secondary_asset);
  const { amount, secondaryAmount } = useOrderAmount(order);

  return (
    <FlexLayout direction='column' className='items-end' spacing={4}>
      <FlexLayout direction='row' spacing={8}>
        <AssetIcon asset={asset} size={20} />
        <Body color='secondary'>{amount}</Body>
      </FlexLayout>
      <Body color='secondary' size='small'>
        {secondaryAmount}
      </Body>
    </FlexLayout>
  );
};

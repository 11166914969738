import React, { createContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { api, DashboardTile, Profile } from '@shared/api';
import { UIStore, UserStore } from '@shared/store';
import { mergeDeep } from '@shared/utils';

import { defaultDashboardTiles } from '@Dashboard/Dashboard.data';
import { DashboardStoreSchema, initialValues } from '@Dashboard/types';

import { toJS } from 'mobx';
import { useLocalObservable } from 'mobx-react-lite';

export const DashboardContext = createContext<DashboardStoreSchema>(initialValues);

export const DashboardProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { addToastMessage } = UIStore.useUIStore;
  const { setUserSettings } = UserStore.useUserStore;
  const { t } = useTranslation('dashboard');

  const store = useLocalObservable(
    (): DashboardStoreSchema => ({
      ...initialValues,
      setEditMode: (editMode: boolean) => {
        store.editMode = editMode;
      },
      initTiles: (userTiles?: DashboardTile[]) => {
        const defaultTiles = Object.values(defaultDashboardTiles);
        const userSpecificTiles = userTiles || defaultTiles;

        const tilesRecord = defaultTiles.reduce<DashboardTile[]>((mergedTiles, defaultTile) => {
          const foundTile = userSpecificTiles.find((userTile) => defaultTile.name === userTile.name);
          const foundTileIndex = userSpecificTiles.findIndex((userTile) => defaultTile.name === userTile.name);

          if (foundTile) {
            // Merge the tiles if we have both versions
            if (foundTile?.metadata?.webIndex === undefined) {
              mergedTiles.splice(foundTileIndex, 0, { ...defaultTile, display: foundTile.display });
            } else {
              mergedTiles.splice(foundTileIndex, 0, mergeDeep({ ...defaultTile }, { ...foundTile }));
            }
          } else {
            // If our user tiles don't have a record for a tile, add it
            mergedTiles.splice(foundTileIndex, 0, defaultTile);
          }

          return mergedTiles;
        }, []);

        store.tiles = toJS(tilesRecord);
        store.editTiles = toJS(tilesRecord);
      },
      setEditTiles: (tiles: DashboardTile[]) => {
        store.editTiles = tiles;
      },
      updateEditTile: (name: string, data: Partial<DashboardTile>) => {
        const cachedTile = store.editTiles.find((editTile) => editTile.name === name);
        const cachedTileIndex = store.editTiles.findIndex((editTile) => editTile.name === name);

        if (cachedTile) {
          store.editTiles[cachedTileIndex] = mergeDeep({ ...cachedTile }, { ...data });
        }
      },
      cancelEditMode: () => {
        store.editTiles = toJS(store.tiles);
        store.editMode = false;
      },
      saveTiles: async (userProfile?: Profile) => {
        try {
          await api.endpoints.updateUserSettings({
            data: {
              data: {
                dashboardSettings: {
                  ...userProfile?.userSettings.dashboardSettings,
                  tiles: toJS(store.editTiles),
                },
              },
            },
          });

          if (userProfile) {
            setUserSettings({
              ...userProfile?.userSettings,
              dashboardSettings: {
                ...userProfile?.userSettings.dashboardSettings,
                tiles: toJS(store.editTiles),
              },
            });
          }

          store.tiles = toJS(store.editTiles);
          store.editMode = false;

          addToastMessage({
            severity: 'success',
            message: t('customizeDashboardBanner.labels.updatedDashboard'),
          });
        } catch (error) {
          addToastMessage({
            severity: 'error',
            message: t('customizeDashboardBanner.labels.errorUpdatingDashboard'),
          });
        }
      },
    }),
  );

  return <DashboardContext.Provider value={store}>{children}</DashboardContext.Provider>;
};

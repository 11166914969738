import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { BuySellButtons } from '@global-components/BuySellButtons';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';

interface SingleWalletTradingInfoLinksProps {
  asset: Asset;
}

const SingleWalletTradingInfoLinks: React.FC<SingleWalletTradingInfoLinksProps> = observer(({ asset }) => (
  <FlexLayout direction='row' className='w-full items-center justify-center' spacing={16}>
    <BuySellButtons asset={asset} openTradePanel showSwaps />
  </FlexLayout>
));

export { SingleWalletTradingInfoLinks };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { SendReceiveCryptoWarning } from '@global-components/Transactions';

import { Asset, CryptoAddress, Network } from '@shared/api';

interface Props {
  network: Network;
  asset: Asset;
  address?: CryptoAddress;
  error?: boolean;
}

export const ReceiveCryptoNotifications: React.FC<Props> = (props) => {
  const { network, asset, address, error } = props;

  return (
    <FlexLayout direction='column' spacing={16}>
      <SendReceiveCryptoWarning network={network} asset={asset} type='receive' />

      {asset.code === 'KDA' && (
        <Notification severity='warning' title='Kadena (KDA) has limited functionality'>
          <Body size='small'>
            Swyftx accept deposits of KDA via Chain 1-19, but can only complete withdrawals on Chain 2.
          </Body>
        </Notification>
      )}

      {!network?.depositDisabled && address && address.address_details.metadata && (
        <Notification severity='warning' title='Ensure you include address and memo'>
          <Body size='small'>
            You must include both the address and the memo for incoming deposits. Failure to do so may result in the
            permanent loss of funds. Please contact us before sending if you are unsure.
          </Body>
        </Notification>
      )}

      {network?.depositDisabled && (
        <Notification severity='destructive' title='Deposits disabled'>
          <Body size='small'>Deposits are currently disabled for the {network?.networkName} network.</Body>
        </Notification>
      )}
      {error && (
        <Notification severity='destructive' title='Error generating deposit address'>
          <Body size='small'>
            There was an error trying to generate an address for the {network?.networkName} network. Please try again
            later.
          </Body>
        </Notification>
      )}
    </FlexLayout>
  );
};

import { useEffect, useMemo, useState } from 'react';

import { UIStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { SwipeDirection } from '@hooks/useSwipe';

import { useEvent } from 'react-use';

import { ASSET_SUMMARY_BREAKPOINT_SETTINGS } from '../../AssetSummary.const';

type Output = {
  canShiftLeft: boolean;
  canShiftRight: boolean;
};

export const useCanShiftSummarySlider = (
  activeIndex: number,
  direction: SwipeDirection | null,
  lastElement: HTMLDivElement | null,
): Output => {
  const { itemWidth, itemSpacing, rightMenuWidth } = ASSET_SUMMARY_BREAKPOINT_SETTINGS;
  const { contentBreakpoint } = useContentBreakpoint();
  const { mainContentWidth } = UIStore.useUIStore;
  const itemS = itemSpacing[contentBreakpoint];
  const itemW = itemWidth[contentBreakpoint];

  const [canShiftLeft, setCanShiftLeft] = useState(true);
  const canShiftRight = useMemo(() => activeIndex > 0, [activeIndex]);
  const [lastElementX, setLastElementX] = useState(lastElement?.getBoundingClientRect()?.x);

  // find the position of the last element before the transition is complete so we can hide the chevron without delay
  useEffect(() => {
    const currX = lastElement?.getBoundingClientRect()?.x;
    if (currX) {
      if (direction === SwipeDirection.LEFT) {
        setLastElementX(currX - (itemW + itemS));
      } else if (direction === SwipeDirection.RIGHT) {
        setLastElementX(currX + (itemW + itemS));
      } else if (direction === null) {
        setLastElementX(currX);
      }
    }
  }, [activeIndex, lastElement]);

  const updateChevronVisibility = () => {
    if (lastElementX) {
      const invisibleLastElement = mainContentWidth - itemW - rightMenuWidth[contentBreakpoint] < lastElementX;
      setCanShiftLeft(invisibleLastElement);
    }
  };

  useEffect(() => {
    updateChevronVisibility();
  }, [activeIndex, lastElementX]);

  useEvent('resize', updateChevronVisibility);

  return {
    canShiftLeft,
    canShiftRight,
  };
};

import { createContext, PropsWithChildren, useState, useCallback, useMemo } from 'react';

import { Asset, AssetType, OrderType } from '@shared/api';
import { FiatIdEnum, LowLiquidityMax } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';
import { assetService, tradeService, TransactionHistoryItem } from '@shared/services';
import { RatesStore, UIStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useCurrentPrice } from '@hooks/Trade/useCurrentPrice';
import { useLowLiquidity } from '@hooks/Trade/useLowLiquidity';
import { useMinimumTradeAmount } from '@hooks/Trade/useMinimumTradeAmount';
import { useTradeFeesData } from '@routes/Profile/subroutes/TradeFees/useTradeFeesData';
import { getOrderType } from '@utils/orders';

import { useToggle } from 'react-use';
import { getOrderSide } from 'src/lib/UniversalTrade/components/OrdersTile/utils';
import { usePriceScale } from 'src/lib/trade/hooks/General/usePriceScale';
import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

import { formatTriggerValuesToOrderParams } from '../EditTriggerOrders.util';

interface EditTriggerOrderContextType {
  orderType: string;
  amountAvailable: string;
  fromAsset: Asset;
  toAsset: Asset;
  primaryAsset: Asset;
  secondaryAsset: Asset;
  secondaryAmount: string;
  approxSecondaryAmountString: string;
  estimatedFee: string;
  feePercentage?: number;
  triggerPrice?: string;
  setTriggerPrice: React.Dispatch<React.SetStateAction<string | undefined>>;
  initialOrderAmount: string;
  amount?: string;
  setAmount: React.Dispatch<React.SetStateAction<string | undefined>>;
  isBaseAssetToggled: boolean;
  onToggleAsset: () => void;
  orderSide: 'Buy' | 'Sell' | 'Unknown';
  sliderValue: number;
  onSliderValueChange: (newValue: number[]) => void;
  isFiat: (asset: Asset) => boolean;
  handleSubmit: () => Promise<void>;
  onAmountValueChange: (value: string) => void;
  error: string | null;
}

export const EditTriggerOrderContext = createContext<EditTriggerOrderContextType>({
  orderType: 'Unknown',
  amountAvailable: '0',
  fromAsset: {} as Asset,
  toAsset: {} as Asset,
  primaryAsset: {} as Asset,
  secondaryAsset: {} as Asset,
  secondaryAmount: '0',
  approxSecondaryAmountString: '0',
  estimatedFee: '0',
  triggerPrice: '0',
  setTriggerPrice: () => {},
  initialOrderAmount: '0',
  amount: '0',
  setAmount: () => {},
  isBaseAssetToggled: false,
  onToggleAsset: () => {},
  orderSide: 'Unknown',
  sliderValue: 0,
  onSliderValueChange: () => {},
  isFiat: () => false,
  handleSubmit: async () => {},
  onAmountValueChange: () => {},
  error: null,
});

interface Props {
  order: TransactionHistoryItem;
  onClose: () => void;
  onSuccess?: () => void;
}

export const EditTriggerOrderProvider: React.FC<PropsWithChildren & Props> = (props) => {
  const { children, order, onSuccess, onClose } = props;
  const { balances } = UserStore.useUserStore;
  const { invalidateCache } = useTransactionsCache();
  const { addToastMessage } = UIStore.useUIStore;
  const { convertRate } = RatesStore.useRatesStore;
  const primaryAsset = assetService.getAsset(order.primaryAsset)!;
  const secondaryAsset = assetService.getAsset(order.secondaryAsset)!;
  const { userFeeStatus } = useTradeFeesData(order.pro ? 'pro' : 'standard');
  const isFiat = useCallback((asset: Asset) => asset.assetType === AssetType.Fiat, []);
  const [isBaseAssetToggled, toggleBaseAsset] = useToggle(order.limitAsset === order.primaryAsset);
  const currentPrice = useCurrentPrice(primaryAsset, secondaryAsset, 'midPrice');
  const userFeeStatusPercentage = useMemo(() => userFeeStatus?.feePercentage, [userFeeStatus]);

  const { priceScale: secondaryPriceScale } = usePriceScale(secondaryAsset);
  const { priceScale: primaryPriceScale } = usePriceScale(primaryAsset);

  const orderAmount = Big(isBaseAssetToggled ? order.primaryAmount : order.secondaryAmount)
    .abs()
    .toFixed(isBaseAssetToggled ? primaryPriceScale : secondaryPriceScale);

  const orderSide = useMemo(() => getOrderSide(order.orderType), [order.orderType]);

  const orderType = useMemo(() => getOrderType(order), [order]);

  /**
   * Asset to convert from when trigger order executes
   */
  const fromAsset = useMemo(() => {
    if (getOrderSide(order.orderType) === 'Buy') {
      return primaryAsset;
    }
    return secondaryAsset;
  }, [order.orderType, primaryAsset, secondaryAsset]);

  /**
   * Asset to convert into when trigger order executes
   */
  const toAsset = useMemo(() => {
    if (getOrderSide(order.orderType) === 'Buy') {
      return secondaryAsset;
    }
    return primaryAsset;
  }, [order.orderType, primaryAsset, secondaryAsset]);

  const { calculatePriceScale } = usePriceScale();

  /**
   * The user's trigger price. If they are buying, it is the same as the order's trigger price.
   * If they are selling, it is the inverse of the order's trigger price.
   */
  const userTriggerPrice = useMemo(() => {
    if (orderSide === 'Buy') {
      return Big(order.trigger).toFixed(calculatePriceScale(secondaryAsset));
    }
    return Big(1).div(Big(order.trigger)).toFixed(calculatePriceScale(secondaryAsset));
  }, [calculatePriceScale, order.trigger, orderSide, secondaryAsset]);

  const [triggerPrice, setTriggerPrice] = useState<string | undefined>(userTriggerPrice);
  const [amount, setAmount] = useState<string | undefined>(orderAmount);
  const minAmount = useMinimumTradeAmount(
    primaryAsset,
    isBaseAssetToggled ? primaryAsset : secondaryAsset,
    amount,
    triggerPrice,
  );

  const { isLowLiquidity, maximumAmount } = useLowLiquidity(fromAsset?.id, toAsset?.id);

  /**
   * The amount of asset available
   */
  const amountAvailable = useMemo(() => {
    const balance = balances[fromAsset.id];
    if (!balance) return 'Unknown';
    return balance.availableBalance;
  }, [balances, fromAsset]);

  /**
   * The amount in the currency opposite of the one they have chosen to input the amount as
   */
  const secondaryAmount = useMemo(() => {
    if (!isBaseAssetToggled) {
      return Big(amount)
        .times(triggerPrice || 0)
        .abs()
        .toString();
    }

    return Big(amount)
      .div(triggerPrice || 0)
      .abs()
      .toString();
  }, [amount, isBaseAssetToggled, triggerPrice]);

  /**
   * secondaryAmount in a formatted string
   */
  const approxSecondaryAmountString = useMemo(() => {
    if (!isBaseAssetToggled) {
      return `${formatCurrency(secondaryAmount, primaryAsset, { isApproximate: true })} ${primaryAsset.code}`;
    }
    return `${formatCurrency(secondaryAmount, secondaryAsset, { isApproximate: true })} ${secondaryAsset.code}`;
  }, [isBaseAssetToggled, primaryAsset, secondaryAmount, secondaryAsset]);

  /**
   * The estimated fee, in the same asset as the one they have chosen to input the amount as
   */
  const estimatedFee = useMemo(() => {
    const feePercentage = Big(userFeeStatusPercentage).div(100);
    if (!isBaseAssetToggled) {
      return `${formatCurrency(
        Big(triggerPrice)
          .times(amount || 1)
          .times(feePercentage)
          .abs()
          .toString(),
        primaryAsset,
        { isApproximate: true },
      )} ${primaryAsset.code}`;
    }
    return `${formatCurrency(Big(amount).times(feePercentage).abs().toString(), primaryAsset, {
      isApproximate: true,
    })} ${primaryAsset.code}`;
  }, [userFeeStatusPercentage, isBaseAssetToggled, amount, primaryAsset, triggerPrice]);

  /**
   * Returns the percentage of that the amount is of the amount av
   */
  const defaultSliderValue = useMemo(() => {
    const sliderPercentage = Big(amount).div(amountAvailable).mul(100);
    if (sliderPercentage.gt(100)) return 100;
    return sliderPercentage.toNumber();
  }, [amount, amountAvailable]);

  const [sliderValue, setSliderValue] = useState<number>(defaultSliderValue);

  /**
   * Handles the slider value changing
   */
  const onSliderValueChange = useCallback(
    (newValue: number[]) => {
      if (!newValue.length) return;
      const value = newValue[0];
      if (value === 0) {
        setAmount(Big(0).toString());
      } else {
        const percentage = Big(value).div(100);
        const newAmount = Big(amountAvailable)
          .mul(percentage)
          .toFixed(isBaseAssetToggled ? primaryPriceScale : secondaryPriceScale)
          .toString();
        setAmount(newAmount);
      }
      setSliderValue(newValue[0]);
    },
    [amountAvailable, isBaseAssetToggled, primaryPriceScale, secondaryPriceScale],
  );

  const onAmountValueChange = useCallback(
    (value: string) => {
      if (value !== '.' && Number.isNaN(Number(value))) return;

      setAmount(value);

      if (!primaryAsset || !value || Number.isNaN(Number(value))) {
        setSliderValue(0);
      } else {
        const asset = isBaseAssetToggled ? primaryAsset : secondaryAsset;
        const balance = Big(balances[asset.id].availableBalance);
        setSliderValue(Math.min(Number(Big(value).div(balance).times(100).toFixed(0)), 100));
      }
    },
    [balances, setAmount, setSliderValue, primaryAsset],
  );

  /**
   * When the user toggles the base asset, we need to update the amount and slider value
   */
  const onToggleAsset = useCallback(() => {
    if (isBaseAssetToggled) {
      const newAmount = Big(amount)
        .div(triggerPrice || 0)
        .abs()
        .toString();
      setAmount(newAmount);
      setSliderValue(Big(newAmount).div(amountAvailable).mul(100).toNumber());
    } else {
      const newAmount = Big(amount)
        .times(triggerPrice || 0)
        .abs()
        .toString();
      setAmount(newAmount);
      setSliderValue(Big(newAmount).div(amountAvailable).mul(100).toNumber());
    }

    toggleBaseAsset();
  }, [amount, amountAvailable, isBaseAssetToggled, toggleBaseAsset, triggerPrice]);

  const quantityAsset = useMemo(
    () => (isBaseAssetToggled ? primaryAsset : secondaryAsset),
    [isBaseAssetToggled, primaryAsset, secondaryAsset],
  );

  const error = useMemo(() => {
    const validAmount = minAmount && amount && Big(amount).gte(minAmount);
    const minAmountError = !validAmount;
    const asset = isBaseAssetToggled ? primaryAsset : secondaryAsset;

    if (minAmountError)
      return `Amount is less than minimum: ${formatCurrency(minAmount.toString(), asset)} ${asset.code}`;

    let quantityValue = amount;
    let maximumCustomAmount = maximumAmount;

    if (primaryAsset && quantityAsset && quantityAsset.id !== FiatIdEnum.AUD) {
      const convertedTriggerPrice = convertRate(primaryAsset, FiatIdEnum.AUD, triggerPrice || 0);
      maximumCustomAmount = Big(LowLiquidityMax.AUD).div(convertedTriggerPrice);
    }

    if (isLowLiquidity && Big(quantityValue).gt(maximumCustomAmount)) {
      return `Low liquidity. Orders are limited to ${formatCurrency(maximumCustomAmount.toString(), quantityAsset)} ${
        quantityAsset.code
      }`;
    }

    if (currentPrice) {
      const bigTrigger = Big(triggerPrice);

      if (order.orderType === OrderType.TriggerBuy && bigTrigger.gte(currentPrice)) {
        return `Trigger price must be less than current price: ${formatCurrency(currentPrice, primaryAsset)}`;
      } else if (order.orderType === OrderType.StopBuy && bigTrigger.lte(currentPrice)) {
        return `Trigger price must be more than current price: ${formatCurrency(currentPrice, primaryAsset)}`;
      } else if (order.orderType === OrderType.TriggerSell && bigTrigger.lte(currentPrice)) {
        return `Trigger price must be more than current price: ${formatCurrency(currentPrice, primaryAsset)}`;
      } else if (order.orderType === OrderType.StopSell && bigTrigger.gte(currentPrice)) {
        return `Trigger price must be less than current price: ${formatCurrency(currentPrice, primaryAsset)}`;
      }
    }

    return null;
  }, [
    minAmount,
    amount,
    fromAsset,
    maximumAmount,
    primaryAsset,
    quantityAsset,
    isLowLiquidity,
    convertRate,
    triggerPrice,
    currentPrice,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!amount || !triggerPrice) {
      addToastMessage({ severity: 'error', message: 'Amount and/or trigger cannot be 0' });
      return;
    }
    const payload = formatTriggerValuesToOrderParams(order, amount, triggerPrice, quantityAsset, orderSide === 'Buy');
    if (payload) {
      try {
        await tradeService.updateTriggerOrder(payload);
        if (onSuccess) onSuccess();
        invalidateCache();
        onClose();
        addToastMessage({ severity: 'success', message: 'Order successfully updated' });
      } catch (err: any) {
        addToastMessage({ severity: 'error', message: (err as SwyftxError)?.errorMessage });
      }
    } else {
      addToastMessage({ severity: 'error', message: 'There was an error editing the order, please try again' });
    }
    invalidateCache();
  }, [addToastMessage, amount, invalidateCache, onClose, onSuccess, order, orderSide, quantityAsset, triggerPrice]);

  return (
    <EditTriggerOrderContext.Provider
      value={{
        orderType,
        amountAvailable,
        fromAsset,
        toAsset,
        primaryAsset,
        secondaryAsset,
        approxSecondaryAmountString,
        secondaryAmount,
        estimatedFee,
        feePercentage: userFeeStatusPercentage,
        triggerPrice,
        setTriggerPrice,
        initialOrderAmount: orderAmount,
        amount,
        setAmount,
        isBaseAssetToggled,
        onToggleAsset,
        orderSide,
        sliderValue,
        onSliderValueChange,
        isFiat,
        handleSubmit,
        onAmountValueChange,
        error,
      }}
    >
      {children}
    </EditTriggerOrderContext.Provider>
  );
};

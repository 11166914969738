import { Asset, AssetType } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';

const thousand = 1000;
const million = thousand * thousand;
const billion = million * thousand;
const trillion = billion * thousand;

export type FormatCurrencyOptions = {
  hideSuffix?: boolean;
  useAssetType?: boolean;
  forceSuffix?: boolean;
  forceMinimumPriceScale?: number;
};

const formatNumber = (val: string | number, decimals?: number) =>
  Number(val).toLocaleString('en', { minimumFractionDigits: decimals });

/**
 * @deprecated use src/shared/utils/lib/formatCurrency.ts
 */
const formatCurrency = (
  value: string | number,
  baseAssetType?: AssetType,
  baseAssetCode?: string,
  asset?: Asset,
  options?: FormatCurrencyOptions,
): string => {
  const bigAmount = Big(value);
  const priceScale = (() => {
    const std = asset ? (asset.assetType === AssetType.Fiat ? 2 : asset.price_scale) : 2;
    if (options?.forceMinimumPriceScale) {
      if (options.forceMinimumPriceScale > (std || 0)) {
        return options.forceMinimumPriceScale;
      }
    }
    return std;
  })();

  const formattedAmount = bigAmount.toFixed(priceScale, 0);
  const isFiat = options?.useAssetType ? asset?.assetType === AssetType.Fiat : baseAssetType === AssetType.Fiat;
  const prefix = isFiat ? '$' : '';
  const suffix = isFiat && !options?.forceSuffix ? '' : baseAssetCode || '';

  const formattedNumber = formatNumber(formattedAmount, priceScale);

  return `${prefix}${formattedNumber}${!options?.hideSuffix && suffix ? ` ${suffix}` : ''}`;
};

type ShorthandOptions = {
  includeThousands?: boolean;
  fixedTo?: number;
};

/**
 * @deprecated use aviary/utils/currency/currency.format.ts
 */
const formatValueToShorthand = (value: string | number, options?: ShorthandOptions) => {
  const fixedTo = options?.fixedTo === undefined ? 2 : options?.fixedTo;
  const trillionDivided = Big(value).div(trillion);
  const billionDivided = Big(value).div(billion);
  const millionDivided = Big(value).div(million);
  const thousandDivided = Big(value).div(thousand);

  if (trillionDivided.gt(1)) return `${trillionDivided.toFixed(fixedTo)}T`;
  if (billionDivided.gt(1)) return `${billionDivided.toFixed(fixedTo)}B`;
  if (millionDivided.gt(1)) return `${millionDivided.toFixed(fixedTo)}M`;
  if (options?.includeThousands && thousandDivided.gt(1)) return `${thousandDivided.toFixed(fixedTo)}k`;

  return new Intl.NumberFormat('en', { minimumFractionDigits: 0 }).format(Number(value));
};

/**
 * @deprecated use aviary/utils/currency/currency.format.ts
 */
const formatValueToCurrencyShorthand = (
  value: string,
  baseAssetType?: AssetType,
  baseAssetCode?: string,
  shorthandOptions?: ShorthandOptions,
) => {
  const isNumber = !Number.isNaN(Number(value));
  const prefix = baseAssetType === AssetType.Crypto ? '' : '$';
  const val = isNumber ? formatValueToShorthand(value, shorthandOptions) : '0';
  const suffix = baseAssetType === AssetType.Crypto ? baseAssetCode : '';

  return `${prefix}${val}${suffix ? ` ${suffix}` : ''}`;
};

export { formatCurrency, formatValueToCurrencyShorthand, formatValueToShorthand };

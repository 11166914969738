import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useImageLoader } from '@swyftx/aviary/hooks/useImageLoader';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { PlatformFeature } from '../../types/FeatureDiscovery.types';

type Props = {
  feature?: PlatformFeature;
  onClose: () => void;
};

const FeatureCardItem: React.FC<Props> = ({ feature, onClose }) => {
  const { imgRef, onLoadImage, loaded } = useImageLoader(300);
  const { theme } = useTailwindTheme();
  const isXs = useTailwindBreakpoint('xs');

  const imageTheme = useMemo(() => {
    if (theme === TailWindTheme.Light) return 'light';

    return 'dark';
  }, [theme]);

  return (
    <FlexLayout direction='column' spacing={12}>
      <div
        className={cn(
          'max-h-[30rem] w-full overflow-hidden bg-color-background-surface-active xs:h-[30rem] xs:min-h-[30rem] sm:h-[22rem] sm:h-auto sm:min-h-[22rem]',
          !loaded ? 'animate-pulse' : '',
        )}
      >
        <img
          ref={imgRef}
          src={`/assets/images/features/${feature?.imageKey}/${imageTheme}/${feature?.imageKey}_${
            isXs ? 'mobile' : 'hero'
          }.png`}
          alt={feature?.title}
          className={!loaded ? 'hidden' : ''}
          onLoad={onLoadImage}
        />
      </div>
      <FlexLayout alignItems='center' justifyContent='end' className='absolute right-20 top-20'>
        <Button
          id='featureDiscoveryCloseButton'
          variant='filled'
          color='inverse'
          size='md'
          layout='icon'
          leadingIcon={<Cross />}
          onClick={onClose}
        />
      </FlexLayout>

      <FlexLayout direction='column' spacing={8} className='px-32 py-12'>
        <Chip size='sm' color='info' variant='subtle'>
          {feature?.typeLabel}
        </Chip>
        <FlexLayout direction='column' spacing={12}>
          <Heading size='h4'>{feature?.title}</Heading>
          <Body size='small' color='secondary'>
            {feature?.content}
          </Body>
        </FlexLayout>
        {feature?.footerContent}
      </FlexLayout>
    </FlexLayout>
  );
};

export { FeatureCardItem };

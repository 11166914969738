import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { DangerFilled } from '@swyftx/aviary/icons/filled';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import apiKeyHelpers, { CreateAPIKeyType, GroupedScope } from '@shared/api/lib/helpers/apiKeys.helper';
import { Links } from '@shared/constants';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

const API_KEY_LABEL = 'Koinly SSO';

export type Props = {
  onSubmit: () => void;
  onClose: () => void;
};

const IntegrateKoinlyApiKeyStep: React.FC<Props> = observer(({ onSubmit, onClose }) => {
  const { t } = useTranslation('profile.taxreports');
  const { setNewApiKeyPayload } = useContext(ApiKeysContext);

  const handleSubmit = () => {
    // We are reverse-engineering a set of `GroupedScope`s here
    // based on permissions from the `ReadOnly` preset.
    // TODO: refactor this logic when scopes are refactored in future
    const scopes = apiKeyHelpers.presets
      .find((p) => p.name === CreateAPIKeyType.ReadOnly)!
      .scopes.reduce((group, scope) => {
        const [parent] = scope.split('.');
        if (!group[parent]) {
          group[parent] = {
            parent: {
              expanded: false,
              locked: false,
              selected: false,
              display: '',
              desc: '',
              key: parent,
              state: 0,
            },
            children: [],
          };
        }
        group[parent].children.push({
          expanded: false,
          locked: false,
          selected: true,
          display: '',
          desc: '',
          key: `app.${scope}`,
          state: 0,
        });
        return group;
      }, {} as GroupedScope);

    setNewApiKeyPayload({
      keyType: CreateAPIKeyType.ReadOnly,
      label: API_KEY_LABEL,
      scopes,
    });
    onSubmit();
  };

  const openLearn = () => {
    window.open(Links.help.apiKeys, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      id='integrate-koinly-api-key-step'
      open
      onClose={onClose}
      sx={{ width: '400px' }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' justifyContent='center' spacing={2}>
            <Button onClick={onClose} variant='text' sx={{ width: '50%' }}>
              <Typography color='text.secondary' fontSize={16} fontWeight='500'>
                {t('integrateKoinly.sections.apiKey.cancel')}
              </Typography>
            </Button>
            <Button onClick={handleSubmit} variant='contained' sx={{ width: '50%', fontSize: 16, fontWeight: '500' }}>
              {t('integrateKoinly.sections.apiKey.understand')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={2} alignItems='center' textAlign='center'>
        <Box width='70px' height='70px'>
          <DangerFilled id='integrate-koinly-api-key-step-icon' className='h-full w-full text-color-text-warning' />
        </Box>

        <Typography fontSize={20} fontWeight='700'>
          {t('integrateKoinly.sections.apiKey.title')}
        </Typography>

        <Typography color='text.secondary' fontSize={16} fontWeight='400'>
          {t('integrateKoinly.sections.apiKey.subtitle')}
        </Typography>

        <Button id='integrate-koinly-api-key-step-learn-button' onClick={openLearn} variant='text' size='small'>
          {t('integrateKoinly.sections.apiKey.learn')}
        </Button>
      </Stack>
    </Modal>
  );
});

export { IntegrateKoinlyApiKeyStep };

import React, { useState, useEffect } from 'react';

import { StripeCardDepositWrapper } from '@global-components/Modals/StripeCardDepositModal/StripeCardDepositWrapper';

import { Big } from '@shared/safe-big';

import { loadStripe, Stripe } from '@stripe/stripe-js';

interface StripeElementsProps {
  inputAmount: string;
  setIsSubmitDisabled: (value: boolean) => void;
  setOnSubmit: (onSubmit: () => void) => void;
  onSuccess: () => void;
  onFailure?: () => void;
  stripeData: {
    feePercentage: Big;
    feeAmount: Big;
    clientSecret: string;
    publicKey: string;
  };
}

const StripeElements: React.FC<StripeElementsProps> = ({
  inputAmount,
  setIsSubmitDisabled,
  setOnSubmit,
  onSuccess,
  onFailure,
  stripeData,
}) => {
  const amount = Big(inputAmount);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  useEffect(() => {
    if (stripeData.publicKey) {
      setStripePromise(loadStripe(stripeData.publicKey));
    }
  }, [stripeData.publicKey]);

  return (
    <StripeCardDepositWrapper
      clientSecret={stripeData.clientSecret}
      stripePromise={stripePromise}
      onSuccess={onSuccess}
      onFailure={onFailure}
      setOnSubmit={setOnSubmit}
      setIsSubmitDisabled={setIsSubmitDisabled}
      amount={amount}
      feeAmount={stripeData.feeAmount}
      feePercentage={stripeData.feePercentage}
    />
  );
};

export { StripeElements };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { cn } from '@shared/utils/lib/ui';

interface StatItemProps {
  title: string;
  data: string;
  percentage?: boolean;
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const StatItem: React.FC<StatItemProps> = (itemProps) => {
  const { title, data, percentage, loading, className, children } = itemProps;
  const isXs = useTailwindBreakpoint('xs');
  return (
    <FlexLayout direction='column' spacing={loading ? 0 : 4} className={cn('justify-center', className)}>
      <NumericDataItem
        title={title}
        data={data}
        renderIcons={false}
        percentage={percentage}
        size='medium'
        weight={!isXs ? 'emphasis' : 'none'}
        color={percentage ? undefined : 'primary'}
        className='!line-height-0 gap-4'
        loading={loading}
      >
        {children}
      </NumericDataItem>
    </FlexLayout>
  );
};

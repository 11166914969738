import { emitEvent, SwyftxEvent } from '@shared/events';
import { balanceService } from '@shared/services';
import storage, { StorageKey } from '@shared/storage';

import { action, autorun, observable, runInAction } from 'mobx';

import { AppStateType, AppStoreSchema } from './@types/appTypes';

const initialValues: AppStateType = {
  servicesInit: false,
  isDemo: false,
  sessionTimeout: 0,
  locale: Intl.Collator().resolvedOptions().locale,
  isMaintenanceMode: false,
  maintenanceRetryAfter: false,
  isNetworkAvailable: true,
  depositLandingRead: false,
  receiveLandingRead: false,
  withdrawalLandingRead: false,
  sendLandingRead: false,
  sendScamLandingRead: false,
  priceAlertNoticeRead: false,
  showStripHeader: false,
  assetsInitialized: false,
  multiTradeLimitationRead: false,
};

const store: AppStoreSchema = observable({
  /* observables */
  ...initialValues,
  setServicesInit: action((servicesInit: boolean) => {
    store.servicesInit = servicesInit;
  }),
  setIsDemo: action((isDemo: boolean) => {
    store.isDemo = isDemo || initialValues.isDemo;
    storage.setItem(StorageKey.DEMO, store.isDemo);

    emitEvent(SwyftxEvent.DemoMode, { enabled: store.isDemo });
    balanceService.forceUpdate();
  }),

  setAssetsInitialized: action((assetsInitialized: boolean) => {
    store.assetsInitialized = assetsInitialized;
  }),

  setLanguage: action((language: string) => {
    store.locale = language || initialValues.locale;
    storage.setItem(StorageKey.LANGUAGE, store.locale);
  }),

  setSessionTimeout: action((sessionTimeout: number) => {
    store.sessionTimeout = sessionTimeout || initialValues.sessionTimeout;
  }),

  setIsMaintenanceMode: action((isMaintenanceMode: boolean) => {
    store.isMaintenanceMode = isMaintenanceMode;
  }),

  setMaintenanceRetryAfter: action((maintenanceRetryAfter: number | boolean) => {
    store.maintenanceRetryAfter = maintenanceRetryAfter;
  }),

  setIsNetworkAvailable: action((isNetworkAvailable: boolean) => {
    store.isNetworkAvailable = isNetworkAvailable;
  }),

  setDepositLandingRead: action((depositLandingRead: boolean) => {
    store.depositLandingRead = depositLandingRead;
    storage.setItem(StorageKey.DEPOSIT_LANDING_READ, store.receiveLandingRead);
  }),

  setReceiveLandingRead: action((receiveLandingRead: boolean) => {
    store.receiveLandingRead = receiveLandingRead;
    storage.setItem(StorageKey.RECEIVE_LANDING_READ, store.receiveLandingRead);
  }),

  setMultiTradeLimitationRead: action((multiTradeLimitationRead: boolean) => {
    store.multiTradeLimitationRead = multiTradeLimitationRead;
    storage.setItem(StorageKey.MULTI_TRADE_LIMITATION_READ, store.multiTradeLimitationRead);
  }),

  setWithdrawalLandingRead: action((withdrawalLandingRead: boolean) => {
    store.withdrawalLandingRead = withdrawalLandingRead;
    storage.setItem(StorageKey.WITHDRAWAL_LANDING_READ, store.withdrawalLandingRead);
  }),

  setSendLandingRead: action((sendLandingRead: boolean) => {
    store.sendLandingRead = sendLandingRead;
    storage.setItem(StorageKey.SEND_LANDING_READ, store.sendLandingRead);
  }),

  setSendScamLandingRead: action((sendScamLandingRead: boolean) => {
    store.sendScamLandingRead = sendScamLandingRead;
    storage.setItem(StorageKey.SEND_SCAM_LANDING_READ, store.sendScamLandingRead);
  }),

  setPriceAlertNoticeRead: action((priceAlertNoticeRead: boolean) => {
    store.priceAlertNoticeRead = priceAlertNoticeRead;
    storage.setItem(StorageKey.PRICE_ALERT_NOTICE_READ, store.priceAlertNoticeRead);
  }),
});

/** Side effect for save scopes into an array */
autorun(() => {
  const showStripHeader = !store.isNetworkAvailable || store.isDemo;
  runInAction(() => {
    store.showStripHeader = showStripHeader;
  });
});

export { store as useAppStore };

import { Profile } from '@shared/api';
import { ReturnedTransaction, VerificationStatus } from '@shared/api/@types/verification';
import { GreenIdStatusEnum, KycStatusEnum } from '@shared/enums';

const isLocked = (
  userProfile: Profile | undefined,
  jumioTransaction: ReturnedTransaction | undefined,
  isKyc1Complete: () => boolean,
  isKyc2Required: () => boolean,
): boolean => {
  if (!isKyc2Required()) return true;
  if (!userProfile) return true;

  const { verification } = userProfile;

  const greenIdVerified = verification?.greenid.status === GreenIdStatusEnum.VERIFIED;
  const jumioVerified = isKyc1Complete() || jumioTransaction?.result?.status === VerificationStatus.PASSED;

  return !jumioVerified || !greenIdVerified;
};

const inProgress = (
  userProfile: Profile | undefined,
  jumioTransaction: ReturnedTransaction | undefined,
  isKyc1Complete: () => boolean,
  isKyc2Required: () => boolean,
): boolean => {
  if (!userProfile || isLocked(userProfile, jumioTransaction, isKyc1Complete, isKyc2Required)) return false;

  const { kyc } = userProfile;

  return kyc?.KYC2 === KycStatusEnum.STARTED;
};

const isVerified = (
  userProfile: Profile | undefined,
  jumioTransaction: ReturnedTransaction | undefined,
  isKyc1Complete: () => boolean,
  isKyc2Complete: () => boolean,
  isKyc2Required: () => boolean,
): boolean => {
  if (
    !userProfile ||
    isLocked(userProfile, jumioTransaction, isKyc1Complete, isKyc2Required) ||
    inProgress(userProfile, jumioTransaction, isKyc1Complete, isKyc2Required)
  )
    return false;

  return isKyc2Complete();
};

export { isLocked, inProgress, isVerified };

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';

import { RegisterContext } from '@routes/Register/Register.context';
import { RegisterTranslationKey } from '@routes/Register/translations';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const RegisterAlreadyHaveAccount: React.FC = observer(() => {
  const { isFirstStep, resetRegisterStep } = useContext(RegisterContext);
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation(RegisterTranslationKey);

  const goToLogin = () => {
    navigate(NavigationURLs.Login, { state: { fromRegister: true } });
    resetRegisterStep();
  };

  if (!isFirstStep()) return null;

  return (
    <FlexLayout
      id='registerAlreadyHaveAccount.container'
      className='mt-16 w-full '
      alignItems='start'
      justifyContent='center'
      direction='row'
      onClick={goToLogin}
    >
      <Body id='registerAlreadyHaveAccount.labels.AlreadyHaveAccount' color='secondary' size='small'>
        {t('registerAlreadyHaveAccount.labels.AlreadyHaveAccount')}{' '}
        <BodyLink
          id='registerAlreadyHaveAccount.labels.signIn'
          color='accent'
          weight='emphasis'
          size='small'
          className='cursor-pointer hover:underline'
        >
          {t('registerAlreadyHaveAccount.labels.signIn')}
        </BodyLink>
      </Body>
    </FlexLayout>
  );
});

RegisterAlreadyHaveAccount.displayName = 'RegisterAlreadyHaveAccount';

export { RegisterAlreadyHaveAccount };

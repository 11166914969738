import { useCallback, useMemo, useState } from 'react';

import { CompanyForm, Individual, Shareholder } from '@shared/api';

import { useToggle } from 'react-use';
import { v4 as uuid } from 'uuid';

import { useEntityOnboardingSelector, useEntityOnboardingService } from '../EntityOnboarding.context';
import { IndividualWithUuid } from '../types/EntityApplicationForm.types';

export const useDirectorsStep = () => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as CompanyForm;
  const prevDirectors = applicationData.directors
    ? applicationData.directors.map((director) => ({ individual: director, uuid: uuid() }))
    : undefined;
  const [directors, setDirectors] = useState<IndividualWithUuid[]>(prevDirectors ?? []);
  const prevDirectorsAlsoShareholders = applicationData.shareholders
    ? (applicationData.shareholders.filter((shareholder) => shareholder.type === 'INDIVIDUAL') as Individual[]).map(
        (shareholder) => ({
          individual: shareholder,
          uuid:
            prevDirectors?.find(
              (director) =>
                director.individual.email === shareholder.email &&
                director.individual.firstName === shareholder.firstName,
            )?.uuid ?? uuid(),
        }),
      )
    : undefined;
  const [shareholders, setShareholders] = useState<IndividualWithUuid[]>(prevDirectorsAlsoShareholders ?? []);
  const [addMemberOpen, toggleAddMemberOpen] = useToggle(false);

  const onAddMember = useCallback(
    (individual: Individual, isShareholder: boolean) => {
      const newUuid = uuid();
      if (isShareholder) {
        setShareholders((prev) => [...prev, { individual, uuid: newUuid }]);
      }
      setDirectors((prev) => [...prev, { individual, uuid: newUuid }]);
      toggleAddMemberOpen(false);
    },
    [setDirectors, toggleAddMemberOpen],
  );

  const onCancel = useCallback(() => {
    toggleAddMemberOpen(false);
  }, [toggleAddMemberOpen]);

  const companyName = applicationData.name;

  const continueDisabled = useMemo(() => !directors.length || addMemberOpen, [addMemberOpen, directors.length]);

  const rawDirectors = useMemo(() => directors.map((director) => director.individual), [directors]);
  const rawShareholders = useMemo(() => shareholders.map((shareholder) => shareholder.individual), [shareholders]);

  const combineOldAndNewShareholders = useCallback(
    (prevShareholders: Shareholder[], newIndividualShareholders: Individual[]) => {
      const newIndividualShareholdersEmails = newIndividualShareholders.map((shareholder) => shareholder.email);
      const filteredOldShareholders = prevShareholders.filter(
        (shareholder) =>
          shareholder.type === 'CORPORATE' || !newIndividualShareholdersEmails.includes(shareholder.email),
      );

      return [...filteredOldShareholders, ...newIndividualShareholders];
    },
    [],
  );
  const shareholdersToSubmit = useMemo(
    () => combineOldAndNewShareholders(applicationData.shareholders ?? [], rawShareholders),
    [applicationData.shareholders, combineOldAndNewShareholders, rawShareholders],
  );

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: {
          directors: rawDirectors,
          shareholders: shareholdersToSubmit,
        } as CompanyForm,
      }),
    [entityOnboardingService, rawDirectors, shareholdersToSubmit],
  );

  const handleBack = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'BACK',
        data: {
          directors: rawDirectors,
          shareholders: shareholdersToSubmit,
        } as CompanyForm,
      }),
    [entityOnboardingService, rawDirectors, shareholdersToSubmit],
  );

  const handleRemove = useCallback((memberUuid: string) => {
    setDirectors((prev) => prev.filter((director) => director.uuid !== memberUuid));
    setShareholders((prev) => prev.filter((shareholder) => shareholder.uuid !== memberUuid));
  }, []);

  const onEditMember = useCallback((member: IndividualWithUuid, isShareholder?: boolean) => {
    // Updates the director list with the updated member
    setDirectors((prev) => [...prev.filter((director) => director.uuid !== member.uuid), member]);

    // Also updates the shareholdersList if the member is a shareholder
    if (isShareholder) {
      setShareholders((prev) => [...prev.filter((shareholder) => shareholder.uuid !== member.uuid), member]);
    } else {
      setShareholders((prev) => prev.filter((shareholder) => shareholder.uuid !== member.uuid));
    }
  }, []);

  return {
    directors,
    addMemberOpen,
    onAddMember,
    onCancel,
    continueDisabled,
    handleRemove,
    toggleAddMemberOpen,
    companyName,
    handleSubmit,
    handleBack,
    onEditMember,
    rawShareholders,
  };
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { HistoricalOrder } from '@shared/api';

import { useOrderFee } from '../../hooks/useOrderFee';

interface Props {
  order: HistoricalOrder;
}

export const OrderInfoFee: React.FC<Props> = (props) => {
  const { order } = props;

  const { feeAmount, secondaryFeeAmount } = useOrderFee(order);
  return (
    <FlexLayout direction='column' spacing={8} alignItems='end'>
      <Body color='secondary'>{feeAmount}</Body>
      <Body size='small' color='secondary'>
        {secondaryFeeAmount}
      </Body>
    </FlexLayout>
  );
};

import React from 'react';

import { Box } from '@mui/material';

import { Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  leading?: React.ReactNode;
  title: string;
  subTitle?: string;
  content?: React.ReactNode;
};

const VerifyTile: React.FC<Props> = ({ leading, title, subTitle, content }) => (
  <Stack
    direction='row'
    borderRadius={1}
    spacing={1}
    padding={2}
    sx={{
      width: '100%',
      backgroundColor: 'action.hover',
    }}
  >
    {leading}
    <Box>
      <Typography fontWeight={600} fontSize={12} marginBottom={1}>
        {title}
      </Typography>
      <Typography fontSize={12}>{subTitle}</Typography>
      {content}
    </Box>
  </Stack>
);

export { VerifyTile };

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { LoginContext } from '@routes/Login/Login.context';
import { LoginStep } from '@routes/Login/types';
import AuthenticationService from '@services/AuthenticationService';

import { observer } from 'mobx-react-lite';

import { LoginStepFooter } from '../LoginStepFooter';
import { LoginTitle } from '../LoginTitle';

const TwoFactorRecovery: React.FC = observer(() => {
  const { mfaLogin, recoveryUserProfile, setLoginStep, error, submitting, postLoginActions } = useContext(LoginContext);
  const [code, setCode] = useState('');
  const [copiedRecoveryCode, setCopiedRecoveryCode] = useState(false);
  const [newRecoveryCode, setNewRecoveryCode] = useState('');

  const { t } = useTranslation('login');

  const TWO_FACTOR_RECOVERY_STEP = 2;

  const postRecoveryLogin = async () => {
    if (!recoveryUserProfile) return;

    await AuthenticationService.handleSuccessfulLogin(recoveryUserProfile, {
      initServices: true,
      setUserProfile: true,
    });

    if (recoveryUserProfile) postLoginActions(recoveryUserProfile);
  };

  const loginWithRecoveryCode = async () => {
    const res = await mfaLogin(code, 'rec', '', false);
    if (res.auth?.recovery_code) setNewRecoveryCode(res.auth?.recovery_code);
  };

  const renderRecoveryCodeCard = () => (
    <FlexLayout
      direction='column'
      className='h-full w-full'
      alignItems='center'
      justifyContent='space-between'
      spacing={32}
    >
      <FlexLayout direction='column' className='h-full w-full' alignItems='center' spacing={32}>
        <LoginTitle
          id='twoFactorRecovery'
          title={t('twoFactorRecovery.title')}
          subTitle={t('twoFactorRecovery.subTitle')}
          subTitleDescription={t('twoFactorRecovery.subTitleDescription')}
        />
        <Input
          id='twoFactorRecovery.form.recoveryCode'
          value={code}
          placeholder='XXXX - XXXX - XXXX - XXXX - XXXX'
          containerClassName='w-full'
          className='w-fu'
          readOnly={newRecoveryCode.length > 0}
          onChange={(e) => setCode(e.target.value)}
        />

        {error && (
          <Body id='twoFactorRecovery.form.recoveryCode.error' color='error' size='small' className='mt-8 text-center'>
            {error}
          </Body>
        )}
      </FlexLayout>

      <LoginStepFooter
        step={TWO_FACTOR_RECOVERY_STEP}
        buttonText={t('twoFactorRecovery.buttonLabels.recover')}
        onNext={loginWithRecoveryCode}
        onBack={() => setLoginStep(LoginStep.SignIn)}
        buttonLoading={submitting}
        disableButton={!code}
      />
    </FlexLayout>
  );

  const renderNewRecoveryCodeCard = () => (
    <>
      <FlexLayout direction='column' className='w-full' alignItems='center' justifyContent='center' spacing={32}>
        <LoginTitle
          id='twoFactorRecovery.saveNewCode'
          title={t('twoFactorRecovery.saveNewCodeTitle')}
          subTitle={t('twoFactorRecovery.saveNewCodeSubTitle')}
          subTitleDescription={t('twoFactorRecovery.saveNewCodeDescription')}
        />

        <Input id='twoFactorRecovery.form.newRecoveryCode' readOnly value={newRecoveryCode} />
      </FlexLayout>

      <FlexLayout direction='column' className='mt-8 w-full' alignItems='start' justifyContent='start'>
        <FlexLayout
          id='twoFactorRecovery.form.storedCodeSafely.container'
          alignItems='start'
          justifyContent='start'
          className='mb-8 mt-16 w-full lg:mt-0'
        >
          <FlexLayout direction='row' spacing={16}>
            <Checkbox
              id='twoFactorRecovery.form.storedCodeSafely'
              checked={copiedRecoveryCode}
              onCheckedChange={(checked: boolean) => setCopiedRecoveryCode(checked)}
            />
            <Body size='small'>{t('twoFactorRecovery.labels.storedCodeSafely')}</Body>
          </FlexLayout>
        </FlexLayout>
        <Button
          id='twoFactorRecovery.buttons.signIn'
          variant='filled'
          className='mt-16 w-full'
          disabled={!copiedRecoveryCode || !recoveryUserProfile}
          size='lg'
          loading={submitting}
          onClick={postRecoveryLogin}
        >
          {t('twoFactorRecovery.buttonLabels.continue')}
        </Button>
      </FlexLayout>
    </>
  );

  return newRecoveryCode ? renderNewRecoveryCodeCard() : renderRecoveryCodeCard();
});

export { TwoFactorRecovery };

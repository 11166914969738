import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ArrowChevronDown, Coin } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useOwnedAssets } from '@hooks/Assets/useOwnedAssets';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedAssets: Map<string, Asset>;
  setSelectedAssets: (selectedAssets: Map<string, Asset>) => void;
  fullWidth?: boolean;
};

const PortfolioPerformanceDropdown: React.FC<Props> = observer(({ selectedAssets, setSelectedAssets, fullWidth }) => {
  const ownedAssets = useOwnedAssets();
  const baseAsset = useBaseAsset();

  const handleAssetClicked = (assetId: string, asset: Asset, selected: boolean) => {
    // Remove if selected, add if unselected
    if (!selected) {
      setSelectedAssets(new Map(selectedAssets.set(assetId, asset)));
    } else {
      const selectedAssetsBuff = new Map(selectedAssets);
      selectedAssetsBuff.delete(assetId);
      setSelectedAssets(selectedAssetsBuff);
    }
  };

  const label = (() => {
    const values = Array.from(selectedAssets.values());
    if (values.length > 0) {
      if (values.length === 1) {
        return values[0].name;
      }

      return `${values.length} assets`;
    }

    return (
      <FlexLayout direction='row' alignItems='center'>
        <Coin className='mr-[12px] h-16 w-16' id='coin-icon' />
        All assets
      </FlexLayout>
    );
  })();

  return (
    <Menu
      contentClassName='max-h-[250px] overflow-auto'
      align='start'
      triggerContent={
        <Button variant='outlined' trailingIcon={<ArrowChevronDown />} className={cn(fullWidth ? 'w-full' : 'w-auto')}>
          {label}
        </Button>
      }
    >
      <FlexLayout className='w-full' direction='column'>
        {ownedAssets.map((asset) => {
          const selected = Boolean(selectedAssets.get(asset.id.toString()));
          const assetId = asset.id.toString();
          const disabled = asset === baseAsset || (selectedAssets.size >= 3 && !selected);

          return (
            <MenuItem
              action={() => handleAssetClicked(assetId, asset, selected)}
              key={asset.code}
              selected={selected}
              disabled={disabled}
              icon={<AssetIcon asset={asset} size={18} />}
              text={asset.code}
            />
          );
        })}
      </FlexLayout>
    </Menu>
  );
});

PortfolioPerformanceDropdown.displayName = 'PortfolioPerformanceDropdown';

export { PortfolioPerformanceDropdown };

import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { useSwyftxProTradeAmount } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeBalance } from 'src/lib/trade-pro/hooks/useSwyftxProTradeBalance';

const SwyftxProTradeAvailableBalance: React.FC = () => {
  const { sellAsset, limitAsset } = useSwyftxProTradeAssets();

  const { onChange } = useSwyftxProTradeAmount();
  const { balance } = useSwyftxProTradeBalance({ asset: sellAsset });

  const limitingOnSellAsset = useMemo(() => limitAsset?.code === sellAsset?.code, [limitAsset?.code, sellAsset?.code]);

  const handleSetBalance = useCallback(() => {
    if (!balance || !limitingOnSellAsset) return;

    onChange(formatCurrency(balance, sellAsset, { hideCode: true, hideSeparator: true, hideSymbol: true }));
  }, [balance, limitingOnSellAsset, onChange, sellAsset]);

  if (!sellAsset) return null;

  return (
    <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
      <Numeric size='small' className='uppercase' color='secondary'>
        Available
      </Numeric>
      {balance ? (
        <Numeric
          size='small'
          color='accent'
          className={cn(limitingOnSellAsset ? 'cursor-pointer hover:underline' : 'cursor-default')}
          onClick={handleSetBalance}
        >
          {formatCurrency(balance, sellAsset, { appendCode: false, hideCode: true })} {sellAsset.code}
        </Numeric>
      ) : (
        <Numeric size='small' color='secondary'>
          -
        </Numeric>
      )}
    </FlexLayout>
  );
};

export { SwyftxProTradeAvailableBalance };

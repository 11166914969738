/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

const useSingleCategoryAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const trackSingleCategoryPageOpened = useCallback(
    (category: string) => {
      avo.singleCategoryPageOpened({
        screen: pathname,
        category,
      });
    },
    [avo, pathname],
  );

  const trackSingleCategoryPageBuyBundleClicked = useCallback(
    (category: string) => {
      avo.singleCategoryPageBuyBundleClicked({
        screen: pathname,
        category,
      });
    },
    [avo, pathname],
  );

  const trackSingleCategoryTradeButtonClicked = useCallback(
    (category: string, asset_code: string, trade_side: 'buy' | 'sell') => {
      avo.singleCategoryPageTradeButtonClicked({
        screen: pathname,
        category,
        assetCode: asset_code,
        tradeSide: trade_side,
      });
    },
    [avo, pathname],
  );

  const trackSingleCategoryAssetFromMoversOpened = useCallback(
    (category: string, asset_code: string, source: 'gainers' | 'losers') => {
      avo.singleCategoryAssetFromMoversCardOpened({
        screen: pathname,
        category,
        assetCode: asset_code,
        source,
      });
    },
    [avo, pathname],
  );

  const trackSingleCategoryAssetFromTableOpened = useCallback(
    (category: string, asset_code: string) => {
      avo.singleCategoryAssetFromAssetsTableOpened({
        screen: pathname,
        category,
        assetCode: asset_code,
        source: 'table',
      });
    },
    [avo, pathname],
  );

  const trackSingleCategoryNewsArticleOpened = useCallback(
    (category: string, article: string) => {
      avo.singleCategoryNewsArticleOpened({
        screen: pathname,
        category,
        article,
      });
    },
    [avo, pathname],
  );

  return {
    trackSingleCategoryPageOpened,
    trackSingleCategoryPageBuyBundleClicked,
    trackSingleCategoryTradeButtonClicked,
    trackSingleCategoryAssetFromMoversOpened,
    trackSingleCategoryAssetFromTableOpened,
    trackSingleCategoryNewsArticleOpened,
  };
};

export { useSingleCategoryAnalytics };

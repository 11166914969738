import React, { useMemo } from 'react';

import { Loading } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { ButtonProps, buttonIconVariants, buttonVariants, getCustomBodyClasses, getFontSize } from './Button.styles';
import { FlexLayout } from '../Layout/Flex';
import { Tooltip } from '../Tooltip/Tooltip';
import { Body } from '../Typography/Body';

type LinkButtonProps = ButtonProps & {
  url?: string;
  openInNewTab?: boolean;
};

const LinkButton = React.forwardRef<HTMLAnchorElement, Omit<LinkButtonProps, 'style'>>(
  (
    {
      className,
      leadingIcon,
      trailingIcon,
      size = 'md',
      variant = 'filled',
      disabled,
      bodyProps,
      layout = 'default',
      color = 'primary',
      textAlign = 'center',
      tooltip = '',
      focusStyle,
      children,
      loading = false,
      url,
      openInNewTab = false,
    },
    ref,
  ) => {
    const fontSize = useMemo(() => getFontSize(size), [size]);
    const customBodyClasses = useMemo(() => getCustomBodyClasses(size), [size]);

    const content = useMemo(() => {
      if (!children) return null;
      if (layout === 'icon' && typeof children === 'string') return null;

      return (
        <Body size={fontSize} {...bodyProps} weight='emphasis' className={customBodyClasses}>
          {children}
        </Body>
      );
    }, [children, layout, fontSize, bodyProps, customBodyClasses]);

    return (
      <a
        className={cn(buttonVariants({ variant, size, disabled, layout, color, className, focusStyle }))}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        href={url}
        ref={ref}
      >
        <Tooltip title={tooltip} sideOffset={16} side='bottom'>
          <FlexLayout alignItems={textAlign} justifyContent={textAlign} spacing={8} className='w-full'>
            {loading ? (
              <Loading className='animate-spin' />
            ) : (
              <>
                {leadingIcon &&
                  React.cloneElement(leadingIcon, {
                    className: cn(buttonIconVariants({ variant, size, color, disabled }), leadingIcon.props.className),
                  })}
                {content}
                {trailingIcon &&
                  React.cloneElement(trailingIcon, {
                    className: cn(buttonIconVariants({ variant, size, color, disabled })),
                  })}
              </>
            )}
          </FlexLayout>
        </Tooltip>
      </a>
    );
  },
);

LinkButton.displayName = 'Button';

export { LinkButton };

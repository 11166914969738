export const LINE_CHART_SETTINGS = {
  defaults: {
    strokeWidth: 2,
    width: 800,
    height: 450,
    xTicks: 6,
    yTicks: 6,
  },
  tooltipColor: {
    dark: '#C9CCD2',
    light: '#4E5869',
  },
  tooltipThrottle: 200,
  overlapPadding: 10,
  tooltipWidth: '1px',
  yAxisPaddingFactor: 1.01,
  axisFontSize: 12,
};

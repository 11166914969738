export type ValidatorResponse<U = unknown> = {
  message?: string;
  metadata?: U;
  valid: boolean;
};

export abstract class Validator<T = unknown, U = unknown, V = unknown> {
  public message: string;

  public additionalParams?: V;

  constructor(_message: string, _additionalParams?: V) {
    this.message = _message;
    this.additionalParams = _additionalParams;
  }

  abstract validate(input: T): ValidatorResponse<U> | Promise<ValidatorResponse<U>>;
}

import { RouteObject } from 'react-router-dom';

import { NavigationURLs } from 'src/lib/navigation/types';

import { SwyftxProPage } from './SwyftxProPage';

export const SwyftxProRoutes: RouteObject[] = [
  {
    path: NavigationURLs.SwyftxPro,
    element: <SwyftxProPage />,
  },
];

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Recurring: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M4.65703 9.97756C4.2786 9.83445 4.08784 9.41166 4.23096 9.03323C5.42897 5.86536 8.49074 3.61035 12.0804 3.61035C15.7413 3.61035 18.8545 5.95569 20.0001 9.22584L20.6321 8.12564C20.8336 7.77483 21.2814 7.65381 21.6322 7.85535C21.983 8.05689 22.1041 8.50466 21.9025 8.85548L20.3493 11.5591C20.1478 11.9099 19.7 12.0309 19.3492 11.8294C19.2805 11.7899 19.2206 11.741 19.1703 11.6852C19.0586 11.5716 18.983 11.421 18.9646 11.2503C18.5913 7.77934 15.6506 5.07549 12.0804 5.07549C9.11991 5.07549 6.59101 6.93461 5.60137 9.55149C5.45826 9.92992 5.03546 10.1207 4.65703 9.97756Z'
      fill='currentColor'
    />
    <path
      d='M2.09748 14.6839C1.89594 15.0347 2.01696 15.4825 2.36777 15.684C2.71859 15.8856 3.16636 15.7645 3.3679 15.4137L4.00963 14.2967C5.00882 17.8137 8.24387 20.389 12.0805 20.389C15.7148 20.389 18.8081 18.0781 19.9739 14.8474C20.1112 14.4669 19.914 14.047 19.5335 13.9097C19.1529 13.7724 18.7331 13.9696 18.5957 14.3501C17.6329 17.0185 15.0779 18.9238 12.0805 18.9238C8.51014 18.9238 5.56944 16.2206 5.19626 12.7491C5.17921 12.5904 5.11273 12.4491 5.01378 12.3388C5.00864 12.0904 4.87726 11.8508 4.64616 11.718C4.29535 11.5165 3.84757 11.6375 3.64604 11.9883L2.09748 14.6839Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6972 12.1562C16.6972 14.7457 14.5978 16.8446 12.0087 16.8446C9.41972 16.8446 7.32031 14.7457 7.32031 12.1562C7.32031 9.56718 9.41972 7.46777 12.0087 7.46777C14.5978 7.46777 16.6972 9.56718 16.6972 12.1562ZM10.2281 11.9304C10.1136 12.0448 10.1136 12.2303 10.2281 12.3448L11.8028 13.9195C11.9172 14.0339 12.1028 14.0339 12.2172 13.9195L13.792 12.3448C13.9064 12.2303 13.9064 12.0448 13.792 11.9304L12.2172 10.3556C12.1028 10.2412 11.9172 10.2412 11.8028 10.3556L10.2281 11.9304Z'
      fill='currentColor'
    />
  </svg>
);

export { Recurring };

import React from 'react';

import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Skeleton, Stack } from '@swyftx/react-web-design-system';

export const tableStyles = {
  maxHeight: '30rem',
  boxShadow: 'none !important',
  '& .MuiTableCell-root': {
    bgcolor: 'background.paper !important',
    padding: 1.5,
  },
  '& th': {
    borderBottom: '1px solid',
    borderTop: '1px solid',
    borderColor: 'divider',
  },

  '& td': {
    borderBottom: '1px solid',
    borderColor: 'divider',
    paddingY: 2,
  },
};

export const headerTypographyProps: NumericProps = {
  weight: 'bold',
  color: 'secondary',
  size: 'small',
};

export const rowTypographyProps: NumericProps = { color: 'primary', size: 'small' };

export const LoadingStateRow = (
  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3} width='100%'>
    <Skeleton width='100%' height='36px' />
    <Skeleton width='100%' height='36px' />
    <Skeleton width='100%' height='36px' />
    <Skeleton width='100%' height='36px' />
    <Stack direction='row' spacing={1} alignItems='center' width='100%'>
      <Skeleton width='100%' height='36px' />
      <Skeleton variant='circular' width={16} height={16} />
    </Stack>
  </Stack>
);

export const LoadingState = (
  <Stack direction='column' spacing={2} width='100%'>
    {LoadingStateRow}
    {LoadingStateRow}
    {LoadingStateRow}
  </Stack>
);

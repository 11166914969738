import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';

import { RegisterContext } from '@routes/Register/Register.context';
import { RegisterTranslationKey } from '@routes/Register/translations';

type Props = {
  type: 'referralCode' | 'promoCode';
};

const RegisterAffiliateChip: React.FC<Props> = ({ type }) => {
  const { referralCode, promoRef } = useContext(RegisterContext);
  const { t } = useTranslation(RegisterTranslationKey);

  const label = useMemo(
    () =>
      type === 'referralCode' ? t('referralCode.refCode', { referralCode }) : t('referralCode.promoCode', { promoRef }),
    [promoRef, referralCode, t, type],
  );

  return (
    <Chip className='mt-8' color='secondary'>
      {label}
    </Chip>
  );
};

export { RegisterAffiliateChip };

import React, { PropsWithChildren, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FlexLayoutProps } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';
import { ArrowLineOnlyDown, ArrowLineOnlyUp } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
  sortable?: boolean;
  sorting?: 'asc' | 'desc';
  startingDirection?: 'asc' | 'desc';
  onClick?: () => void;
} & Pick<FlexLayoutProps, 'justifyContent'>;

const AssetRowHeaderItem: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  sortable,
  sorting,
  startingDirection = 'desc',
  justifyContent,
  onClick,
}) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <FlexLayout
      alignItems='center'
      className={cn(sortable ? 'cursor-pointer' : '', className)}
      spacing={8}
      justifyContent={justifyContent}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={sortable ? onClick : undefined}
    >
      <div className={justifyContent === 'start' ? 'order-1' : 'order-2'}>{children}</div>
      {sortable && (
        <div className={justifyContent === 'start' ? 'order-2' : 'order-1'}>
          {!sorting && (
            <>
              {startingDirection === 'desc' && (
                <ArrowLineOnlyDown
                  className={` h-12 w-12 text-color-text-tertiary ${hover ? 'visible' : 'invisible'}`}
                />
              )}
              {startingDirection === 'asc' && (
                <ArrowLineOnlyUp className={` h-12 w-12 text-color-text-tertiary ${hover ? 'visible' : 'invisible'}`} />
              )}
            </>
          )}
          {sorting === 'asc' && <ArrowLineOnlyUp className='h-12 w-12 text-color-text-accent' />}
          {sorting === 'desc' && <ArrowLineOnlyDown className='h-12 w-12 text-color-text-accent' />}
        </div>
      )}
    </FlexLayout>
  );
};

export { AssetRowHeaderItem };

import React from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { RatesStore } from '@shared/store';

import { useAssetPriceMetrics } from '@hooks/Assets/useAssetPriceMetrics';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { TradingViewSide } from 'src/lib/trade/components/TradingViewWidget/types';

import { AssetPrice } from './AssetPrice';
import { StatItem } from './StatItem';
import { DailyChange, DailyVolume } from './StatItems';
import { XsAssetStats } from './XsAssetStats';

interface Props {
  selectedAsset: Asset;
  hidePrice?: boolean;
  priceSide: TradingViewSide;
}

export const AssetStats: React.FC<Props> = observer((props) => {
  const { selectedAsset, hidePrice, priceSide } = props;
  const baseAsset = useBaseAsset()!;
  const { data, loading } = useAssetPriceMetrics(selectedAsset, undefined, priceSide);
  const { getRate } = RatesStore.useRatesStore;
  const rate = getRate(selectedAsset);
  const isXs = useTailwindBreakpoint('xs');

  const dailyHigh = data?.day.high;
  const dailyLow = data?.day.low;
  const { dailyPriceChange } = rate;

  if (isXs) {
    return (
      <XsAssetStats
        priceSide={priceSide}
        baseAsset={baseAsset}
        selectedAsset={selectedAsset}
        loading={loading}
        dailyHigh={dailyHigh}
        dailyLow={dailyLow}
        dailyPriceChange={dailyPriceChange}
      />
    );
  }

  return (
    <>
      {!hidePrice && (
        <AssetPrice
          side={priceSide}
          selectedAsset={selectedAsset}
          baseAsset={baseAsset}
          dailyPriceChange={dailyPriceChange}
        />
      )}

      <StatItem title='24H change' data={`${dailyPriceChange || '0.0'}%`} percentage loading={loading} />
      <DailyChange change={dailyHigh} baseAsset={baseAsset} loading={loading} direction='high' />
      <DailyChange change={dailyLow} baseAsset={baseAsset} loading={loading} direction='low' />
      <DailyVolume
        volume={selectedAsset.volume[baseAsset?.id || FiatIdEnum.AUD].day}
        loading={loading}
        asset={baseAsset}
      />
    </>
  );
});

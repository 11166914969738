import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { RecaptchaSkeleton } from '@global-components/Recaptcha/Recaptcha.skeleton';

import env from '@shared/config';
import { DarkModeEnum } from '@shared/enums';

import { useTheme } from '@hooks/useTheme';
import { getLanguage } from '@utils/i18n/i18n';

import Reaptcha from 'reaptcha';

interface Props {
  id: string;
  submitCaptcha: (token: string) => void;
  captchaRefresh: boolean;
}

export const Recaptcha: React.FC<Props> = (props) => {
  const recaptchaRef = React.useRef<Reaptcha>(null);
  const [recaptchaTheme, setRecaptchaTheme] = useState<'light' | 'dark'>('light');
  const language = getLanguage();
  const { theme } = useTheme();

  // Updating theme
  useEffect(() => {
    switch (theme.palette.mode) {
      case DarkModeEnum.DARK:
        setRecaptchaTheme('dark');
        break;
      case DarkModeEnum.LIGHT:
      default:
        setRecaptchaTheme('light');
        break;
    }
  }, [theme.palette.mode]);

  const onChange = (token: string) => {
    props.submitCaptcha(token);
  };

  // manually refresh token on failed login .current is required as a dependency, if not present it will refresh every time
  useEffect(() => {
    if (recaptchaRef.current?.state.rendered) {
      recaptchaRef.current?.reset();
    }
  }, [props.captchaRefresh]);

  return (
    <FlexLayout direction='column' id={props.id} className='mb-16 h-[100px] !w-full !bg-color-background-error'>
      <Box
        display='flex'
        justifyContent='center'
        position='relative'
        sx={{
          borderColor: 'error.main',
          iframe: {
            position: 'relative',
            zIndex: 21,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '300px',
            height: '75px',
          }}
        >
          <RecaptchaSkeleton />
        </Box>
        <Reaptcha
          ref={recaptchaRef}
          sitekey={env.CAPTCHA_API_KEY}
          hl={language}
          onVerify={onChange}
          theme={recaptchaTheme}
        />
      </Box>
    </FlexLayout>
  );
};

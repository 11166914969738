import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

import { DashboardLayoutKeys } from '../types';

const useDashboardAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const trackDashboardLayoutChanged = useCallback(
    (prevLayout: DashboardLayoutKeys, newLayout: DashboardLayoutKeys) => {
      avo.dashboardLayoutChanged({
        screen: pathname,
        prevLayout,
        newLayout,
      });
    },
    [avo, pathname],
  );

  const trackDashboardLayoutModalOpened = useCallback(() => {
    avo.dashboardLayoutSelectorModalOpened({
      screen: pathname,
    });
  }, [avo, pathname]);

  return {
    trackDashboardLayoutChanged,
    trackDashboardLayoutModalOpened,
  };
};

export { useDashboardAnalytics };

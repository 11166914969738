import { useMemo } from 'react';

import { Asset, AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';

export type UseFavouriteAssetsOptions = {
  excludeFiat?: boolean;
};

export const useFavouriteAssets = ({ excludeFiat = false }: UseFavouriteAssetsOptions = {}) => {
  const { userProfile } = UserStore.useUserStore;
  const { assetsInitialized } = AppStore.useAppStore;
  const { favouriteAssets } = userProfile?.userSettings || {};

  const favouriteAssetList = useMemo(() => {
    if (!favouriteAssets || !assetsInitialized) return [];

    return Object.keys(favouriteAssets).reduce((reducedFavourites, assetKey) => {
      if (favouriteAssets[assetKey]) {
        const currentFavouriteAsset = assetService.getAsset(parseFloat(assetKey));
        const assetIsEnabled =
          currentFavouriteAsset && (!currentFavouriteAsset.delisting || currentFavouriteAsset.tradable); // People can still have delisted and non tradable assets in their favourites list

        if (assetIsEnabled && (!excludeFiat || currentFavouriteAsset.assetType !== AssetType.Fiat)) {
          reducedFavourites.push(currentFavouriteAsset);
        }
      }

      return reducedFavourites;
    }, [] as Asset[]);
  }, [excludeFiat, favouriteAssets, assetsInitialized]);

  return favouriteAssetList;
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { More, Paper } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useDemoMode } from 'src/lib/utils/hooks';

import { usePortfolioReport } from '../../hooks/usePortfolioReport';

type Props = {
  showSmallBalances: boolean;
  setShowSmallBalances: (newValue: boolean) => void;
};

const PortfolioAssetAllocationWidgetMenu: React.FC<Props> = observer(({ showSmallBalances, setShowSmallBalances }) => {
  const { downloadReport } = usePortfolioReport();
  const { isDemo } = useDemoMode();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  return (
    <Menu
      triggerContent={<Button layout='icon' leadingIcon={<More />} variant='outlined' size='md' />}
      align='end'
      hideArrow
    >
      <MenuItem
        icon={<></>}
        text='Show small balances'
        endAdornment={<Switch checked={showSmallBalances} />}
        action={() => setShowSmallBalances(!showSmallBalances)}
        preventCloseOnSelect
      />
      {!isDemo && isFeatureEnabled(AppFeature.PortfolioBalanceReport) && (
        <MenuItem icon={<Paper />} text='Download balances report' action={downloadReport} preventCloseOnSelect />
      )}
    </Menu>
  );
});

export { PortfolioAssetAllocationWidgetMenu };

import { useCallback, useMemo } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { useAvo } from '@hooks/Avo/useAvo';

import { ResolutionString } from 'public/assets/charting_library/charting_library';
import { useLocation } from 'react-router';
import { ChartTypeValueType } from 'src/context/Avo/generated-avo';

import { TradingViewSide } from '../types';

type Props = {
  primary: string;
  secondary: string;
};

const useTradingViewAnalytics = ({ secondary }: Props) => {
  const { pathname } = useLocation();
  const isXs = useTailwindBreakpoint('xs');
  const secondaryAsset = useMemo(() => assetService.getAssetByCode(secondary), [secondary]);
  const layout = useMemo(() => (isXs ? 'mobile' : 'desktop'), [isXs]);
  const avo = useAvo();

  const getTrackingDetails = useCallback(
    (asset: Asset) => ({
      screen: pathname,
      assetId: asset.id,
      assetCode: asset.code,
      layout,
    }),
    [layout, pathname],
  );

  const trackChartViewed = useCallback(() => {
    if (!secondaryAsset) return;

    avo.chartOpened(getTrackingDetails(secondaryAsset));
  }, [secondaryAsset, getTrackingDetails, avo]);

  const trackChartClosed = useCallback(() => {
    if (!secondaryAsset) return;

    avo.chartClosed(getTrackingDetails(secondaryAsset));
  }, [secondaryAsset, getTrackingDetails, avo]);

  const trackChartAreaClicked = useCallback(() => {
    if (!secondaryAsset) return;

    avo.chartAreaClicked(getTrackingDetails(secondaryAsset));
  }, [secondaryAsset, avo, getTrackingDetails]);

  const trackPriceSideSelected = useCallback(
    (priceSide: TradingViewSide) => {
      if (!secondaryAsset) return;

      avo.priceSideSelected({ ...getTrackingDetails(secondaryAsset), priceSide });
    },
    [secondaryAsset, avo, getTrackingDetails],
  );

  const trackIndicatorSelected = useCallback(
    (indicator: string) => {
      if (!secondaryAsset) return;

      avo.indicatorSelected({ ...getTrackingDetails(secondaryAsset), indicator });
    },
    [secondaryAsset, avo, getTrackingDetails],
  );

  const trackIntervalSelected = useCallback(
    (interval: ResolutionString) => {
      if (!secondaryAsset) return;

      avo.intervalSelected({ ...getTrackingDetails(secondaryAsset), interval });
    },
    [secondaryAsset, avo, getTrackingDetails],
  );

  const trackChartTypeSelected = useCallback(
    (chartType: ChartTypeValueType) => {
      if (!secondaryAsset) return;

      avo.changedChartType({ screen: pathname, chartType });
    },
    [secondaryAsset, avo, pathname],
  );

  const trackFullScreenOpened = useCallback(() => {
    if (!secondaryAsset) return;

    avo.fullscreenChartOpened(getTrackingDetails(secondaryAsset));
  }, [secondaryAsset, avo, getTrackingDetails]);

  const changedCandleType = useCallback(
    (chartType: ChartTypeValueType, candleType: string) => {
      if (!secondaryAsset) return;

      avo.changedCandleType({ screen: pathname, chartType, candleType });
    },
    [avo, pathname, secondaryAsset],
  );

  const changedChartTimeframe = useCallback(
    (chartType: ChartTypeValueType, chartTimeframe: string) => {
      if (!secondaryAsset) return;

      avo.changedChartTimeframe({ screen: pathname, chartType, chartTimeframe });
    },
    [avo, pathname, secondaryAsset],
  );

  return {
    trackChartViewed,
    trackChartClosed,
    trackChartAreaClicked,
    trackPriceSideSelected,
    trackIndicatorSelected,
    trackIntervalSelected,
    trackChartTypeSelected,
    trackFullScreenOpened,
    changedCandleType,
    changedChartTimeframe,
  };
};

export { useTradingViewAnalytics };

import { useCallback, useContext } from 'react';

import { Candle } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { AreaData, IChartApi, ISeriesApi, Time, UTCTimestamp } from 'lightweight-charts';
import { DateTime } from 'luxon';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';
import { useColor } from 'src/lib/utils/hooks';

import { TradingViewContext } from '../../../context/TradingView.context';
import { isSimpleChartState } from '../../../utils/Tradingview.utils';

type Props = {
  chartState?: IChartApi | IChartingLibraryWidget;
  data?: Candle[];
};

const useCreateAreaSeries = ({ chartState, data }: Props) => {
  const { secondary } = useContext(TradingViewContext);
  const { getAssetColor } = useColor();
  const createAreaSeries = useCallback(() => {
    if (!isSimpleChartState(chartState) || !data) return undefined;

    const areaSeries = chartState.addAreaSeries({
      lineColor: getAssetColor(secondary),
      lineWidth: 2,
      topColor: getAssetColor(secondary),
      bottomColor: `${getComputedStyle(document.documentElement).getPropertyValue(
        '--color-background-surface-primary',
      )}`,
    });

    const seriesData: AreaData<Time>[] = data
      .map((d) => ({
        time: (Date.parse(DateTime.fromMillis(d.time).toISO()) / 1000) as UTCTimestamp,
        value: d.close || 0,
      }))
      .sort((a, b) => Big(a.time).cmp(b.time));

    try {
      areaSeries.setData(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }

    return areaSeries;
  }, [chartState, data, secondary]);

  const updateAreaSeries = useCallback((series: ISeriesApi<'Area'>, bar: Candle) => {
    if (series.seriesType() !== 'Area') return;

    const seriesData: AreaData<Time> = {
      time: (Date.parse(DateTime.fromMillis(bar.time).toISO()) / 1000) as UTCTimestamp,
      value: bar.close || 0,
    };

    try {
      series.update(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, []);

  return { createAreaSeries, updateAreaSeries };
};

export { useCreateAreaSeries };

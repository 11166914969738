import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Chat: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M15.9397 11.4131C15.3874 11.4131 14.9397 11.8608 14.9397 12.4131C14.9397 12.9654 15.3874 13.4131 15.9397 13.4131H15.9487C16.501 13.4131 16.9487 12.9654 16.9487 12.4131C16.9487 11.8608 16.501 11.4131 15.9487 11.4131H15.9397Z'
      fill='currentColor'
    />
    <path
      d='M10.93 12.4131C10.93 11.8608 11.3777 11.4131 11.93 11.4131H11.939C12.4912 11.4131 12.939 11.8608 12.939 12.4131C12.939 12.9654 12.4912 13.4131 11.939 13.4131H11.93C11.3777 13.4131 10.93 12.9654 10.93 12.4131Z'
      fill='currentColor'
    />
    <path
      d='M7.92215 11.4131C7.36986 11.4131 6.92215 11.8608 6.92215 12.4131C6.92215 12.9654 7.36986 13.4131 7.92215 13.4131H7.93115C8.48343 13.4131 8.93115 12.9654 8.93115 12.4131C8.93115 11.8608 8.48343 11.4131 7.93115 11.4131H7.92215Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.6019 4.3959C15.4076 0.201188 8.59421 0.201188 4.39997 4.39697C1.11869 7.67767 0.407055 12.5347 2.23755 16.5095C2.4665 17.0801 2.5674 17.3756 2.5674 17.6266C2.5674 17.8144 2.52067 18.047 2.43133 18.3435C2.38767 18.4884 2.33768 18.6367 2.28357 18.7955L2.26893 18.8384C2.22032 18.9809 2.16842 19.1331 2.1216 19.2831C2.02158 19.6034 1.91956 19.9884 1.91956 20.3734C1.91956 20.7849 2.03728 21.2314 2.40314 21.5973C2.76904 21.9627 3.21573 22.0807 3.62721 22.0804C4.01222 22.0802 4.39688 21.9778 4.71707 21.8773C4.8694 21.8294 5.02373 21.7764 5.1681 21.7267L5.20419 21.7143C5.36284 21.6598 5.5108 21.6094 5.6555 21.5652C5.9504 21.4752 6.18145 21.4275 6.36766 21.426C6.61597 21.4266 6.915 21.5319 7.48931 21.7629C11.4646 23.5926 16.3203 22.882 19.6019 19.6002C23.7957 15.4059 23.8034 8.59794 19.6019 4.3959ZM5.46082 5.45744C9.06926 1.84768 14.9327 1.84768 18.5411 5.4565C22.1563 9.07208 22.15 14.9303 18.5411 18.5396C15.7152 21.3658 11.5296 21.9787 8.10137 20.3933C8.08993 20.388 8.07836 20.383 8.06667 20.3783L8.01214 20.3563C7.51804 20.1571 6.94509 19.926 6.36551 19.926L6.36106 19.926C5.95423 19.9284 5.55622 20.0272 5.21753 20.1306C5.04483 20.1833 4.87412 20.2416 4.71675 20.2957L4.68425 20.3069L4.68325 20.3072C4.535 20.3582 4.3995 20.4048 4.26763 20.4462C3.97415 20.5383 3.77134 20.5803 3.62632 20.5804C3.50852 20.5805 3.48186 20.5547 3.46386 20.5367C3.44581 20.5186 3.41956 20.4923 3.41956 20.3734C3.41956 20.2279 3.46151 20.0246 3.55345 19.7301C3.59411 19.5998 3.63967 19.4662 3.68949 19.3201L3.7034 19.2793C3.75726 19.1212 3.81525 18.9498 3.86755 18.7763C3.97017 18.4357 4.0674 18.0357 4.0674 17.6266C4.0674 17.0398 3.83821 16.4696 3.63491 15.9639L3.62239 15.9328C3.6176 15.9209 3.61251 15.9091 3.60712 15.8974C2.02104 12.47 2.63487 8.28278 5.46082 5.45744Z'
      fill='currentColor'
    />
  </svg>
);

export { Chat };

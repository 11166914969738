import { Big } from '@shared/safe-big';

import { ProfitLossValueMeasurement, TriggerType } from '../../types';

type Props = {
  amount: Big;
  purchasePrice?: Big;
  feeAmount: Big;
  feePercentage: string;
  triggerType: TriggerType;
  profitLossValue: string;
  profitLossValueMeasurement: ProfitLossValueMeasurement;
};

const getCalculatedTriggerPrice = (
  { amount, purchasePrice, feeAmount, triggerType, profitLossValueMeasurement, profitLossValue, feePercentage }: Props,
  percentage: string,
) => {
  const amountToSell = Big(amount);
  const relativeCost = Big(purchasePrice).times(Big(amountToSell).plus(feeAmount));

  const isTakeProfit = triggerType === TriggerType.TakeProfit;

  let profitDollars = Big(0);
  let profitPercentage = Big(0);

  if (profitLossValueMeasurement === ProfitLossValueMeasurement.Dollar) {
    profitDollars = Big(profitLossValue).times(isTakeProfit ? 1 : -1);
    profitPercentage = relativeCost.plus(profitDollars).div(relativeCost).minus(1).times(100);
  } else {
    profitPercentage = Big(percentage);
    profitDollars = relativeCost.times(profitPercentage.div(100)).times(isTakeProfit ? 1 : -1);
  }

  const totalTradeValue = relativeCost.plus(profitDollars);
  const calculatedFee = totalTradeValue.times(feePercentage);
  const calculatedTriggerPrice = totalTradeValue.plus(calculatedFee).div(amount);

  return calculatedTriggerPrice;
};

export { getCalculatedTriggerPrice };

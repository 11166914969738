import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Card, Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { AssetPriceText } from '@global-components/Text';

import { AssetType } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { RewardsContext } from '@routes/Rewards/Rewards.context';
import { RewardType } from '@routes/Rewards/types';

import { observer } from 'mobx-react-lite';

import { EmptyRewardsBreakdown } from './EmptyRewardsBreakdown';
import { TotalRewardsBreakdownItem } from './TotalRewardsBreakdownItem';
import { useRewardsBreakdownData } from './useRewardsBreakdownData';

const BREAKDOWN_ITEM_SIZE = 54;

const TotalRewardsBreakdown: React.FC = observer(() => {
  const { t } = useTranslation('rewards', { keyPrefix: 'totalRewardsPieChart' });
  const baseAsset = useBaseAsset();
  const { rewards } = useContext(RewardsContext);
  const { breakdownItems, totalValue } = useRewardsBreakdownData(rewards[RewardType.History], baseAsset);

  return (
    <Card contentSx={{ padding: 0 }} sx={{ padding: 0, width: '100%' }} variant='outlined'>
      <Typography fontWeight={600} fontSize={14} padding={2}>
        {t('title')}
      </Typography>
      <Divider />
      {breakdownItems.length > 0 && (
        <Stack direction='row' padding={2} alignItems='center' justifyContent='space-between'>
          <AssetIcon asset={baseAsset} size={36} />

          <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={0.5}>
            {baseAsset && (
              <AssetPriceText
                prefix='~'
                asset={baseAsset}
                balance={totalValue}
                liveChanges={false}
                typographyProps={{ size: 'large', weight: 'bold', color: 'primary' }}
              />
            )}
            {baseAsset?.assetType === AssetType.Fiat && (
              <Numeric size='large' color='secondary'>
                {baseAsset?.code}
              </Numeric>
            )}
          </Stack>
        </Stack>
      )}
      <Stack maxHeight={`${BREAKDOWN_ITEM_SIZE * 6}px`} sx={{ overflowY: 'auto' }}>
        {breakdownItems.map((breakdown) => (
          <TotalRewardsBreakdownItem
            key={breakdown.asset.code}
            amount={breakdown.amount}
            totalValue={totalValue}
            asset={breakdown.asset}
          />
        ))}
      </Stack>
      {breakdownItems.length === 0 && <EmptyRewardsBreakdown />}
    </Card>
  );
});

export { TotalRewardsBreakdown };

import { useMemo } from 'react';

import {
  assetCategoryFilterRegex,
  singleCategoryPageRegex,
  useCustomCategoryRouteNames,
} from './useCustomCategoryRouteNames';

type Props = {
  route?: string;
};

// Some route names are more complex. The logic for their names are here
const useCustomRouteNames = ({ route }: Props) => {
  const { getCategoryName } = useCustomCategoryRouteNames();
  const friendlyName = useMemo(() => {
    if (!route) return '';

    // Single Category Page
    if (route.match(singleCategoryPageRegex)) return getCategoryName(route, singleCategoryPageRegex);

    if (route.match(assetCategoryFilterRegex)) return getCategoryName(route, assetCategoryFilterRegex);

    return '';
  }, [getCategoryName, route]);

  return {
    friendlyName,
  };
};

export { useCustomRouteNames };

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@swyftx/aviary/atoms/Input';
import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Tick, Error } from '@swyftx/aviary/icons/outlined';

import NameValidator from '@hooks/validator/types/name.validator';
import { useValidation } from '@hooks/validator/useValidation';

type NameInputFormProps = {
  setValid?: (valid: boolean) => void;
  success?: boolean;
  type: 'FirstName' | 'MiddleName' | 'LastName' | 'ApiKey' | 'AccountName' | 'AccountHolderName';
  showLabel?: boolean;
  label?: string;
  liveValidate?: boolean;
  allowNumbers?: boolean;
} & InputProps;

const NameInput: React.FC<NameInputFormProps> = ({
  id,
  type,
  setValid,
  onChange,
  value,
  label,
  required,
  showLabel = true,
  liveValidate = false,
  allowNumbers = true,
  ...props
}) => {
  const { t } = useTranslation('common');
  const inputTypeTranslationKey = `labels.${type}`;

  const validatorProps = useValidation(
    [new NameValidator(t('errors.input.invalid', { label: t(inputTypeTranslationKey as any) }))],
    liveValidate,
    onChange,
    value,
  );

  const onInputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const containsNumber = (inputValue: string): boolean => /[0-9]/.test(inputValue);

    if (containsNumber(event.target.value) && !allowNumbers) {
      event.preventDefault();
    } else if (onChange) {
      validatorProps.onChange(event);
    }
  };

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  const trailing = useMemo(() => {
    if (validatorProps.success) return <Tick className='h-16 w-16 text-color-text-success' />;
    if (validatorProps.error) return <Error className='h-16 w-16 text-color-text-error' />;

    return null;
  }, [validatorProps.error, validatorProps.success]);

  return (
    <FlexLayout direction='column' spacing={4}>
      {showLabel && (
        <Body size='small' weight='emphasis'>
          {label}
          {required && <span className='inline text-color-text-error'>*</span>}
        </Body>
      )}
      <Input
        className='fs-mask'
        containerClassName={validatorProps.success ? 'border-color-border-success border' : ''}
        id={id}
        type='text'
        {...props}
        {...validatorProps}
        trailing={trailing}
        required={required}
        onChange={onInputValueChange}
      />
      <Body color={validatorProps.error ? 'error' : 'success'} size='small' weight='emphasis'>
        {validatorProps.helperText}
      </Body>
    </FlexLayout>
  );
};

export { NameInput };

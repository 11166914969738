import React, { useCallback } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Category } from '@shared/api/@types/categories';

import { observer } from 'mobx-react-lite';
import { CategoryIcons, fallbackIcon } from 'src/context/Categories/Categories.icons';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

interface Props {
  category: Category;
  disableNavigation?: boolean;
}

export const AssetCategoriesChip: React.FC<Props> = observer((props) => {
  const { category, disableNavigation } = props;
  const { navigate } = useNavigateRoute();

  const openCategory = useCallback(
    (e?: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
      if (disableNavigation) return;
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: category?.id } });
    },
    [category?.id, disableNavigation, navigate],
  );

  return (
    <Tooltip title={!disableNavigation ? `View ${category.name}` : ''}>
      <Chip
        variant='subtle'
        color='primary'
        size='sm'
        onClick={openCategory}
        className='cursor-pointer'
        key={category.id}
        leadingIcon={
          <div className='flex w-16 items-center text-color-text-accent'>
            {CategoryIcons(16, true, true)[category.name] || fallbackIcon(96, true, true)}
          </div>
        }
      >
        <Body color='accent' size='xsmall' weight='emphasis'>
          {category.name}
        </Body>
      </Chip>
    </Tooltip>
  );
});

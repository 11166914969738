/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react';

import { Box, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';
import { Input } from '@swyftx/react-web-design-system';

import { CountriesEnum } from '@shared/enums';

import { CountryMenu } from './CountryMenu';
import Countries from '../../../utils/countries/countries';

type Props = {
  id: string;
  compact?: boolean;
  onChange: (country: CountriesEnum) => void;
  value?: CountriesEnum;
  label?: string;
  placeholder?: string;
  disabledOptions?: CountriesEnum[];
  required?: boolean;
  error?: boolean;
  sx?: SxProps;
  onlySuggested?: boolean;
};

const SUGGESTED_GROUP = ' Suggested';
const SUGGESTED_COUNTRY_CODES = ['AU', 'NZ'];

const CountryDropdown: React.FC<Props> = ({
  id,
  onChange,
  value,
  compact,
  label,
  placeholder,
  disabledOptions,
  required = false,
  error,
  sx,
  onlySuggested = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const countries = useMemo(
    () =>
      Object.values(Countries)
        .map((country) => ({
          label: country.name,
          code: country.code,
          dialCode: country.dialCode,
          group: SUGGESTED_COUNTRY_CODES.includes(country.code)
            ? SUGGESTED_GROUP
            : country.name.charAt(0).toUpperCase(),
        }))
        .filter((country) => !onlySuggested || SUGGESTED_COUNTRY_CODES.includes(country.code))
        .sort((a, b) => a.group.toUpperCase().localeCompare(b.group.toUpperCase())),
    [onlySuggested],
  );

  const onSelectCountry = useCallback(
    (countryEnum: CountriesEnum) => {
      onChange(countryEnum);
      setAnchorEl(null);
    },
    [onChange],
  );

  const onClose = useCallback(() => setAnchorEl(null), []);

  const autoCompleteValue = useMemo(() => countries.find((c) => c.code === value), [countries, value]);

  const getCompactContent = () => (
    <>
      <Button
        size='sm'
        id={id}
        variant={compact ? 'ghost' : 'outlined'}
        color='success'
        layout='icon'
        leadingIcon={
          value ? (
            <FlexLayout direction='row' spacing={8} className='mr-36' alignItems='center'>
              <img src={`/assets/images/flags/${Countries[value].code}.svg`} alt='flag' />
              <Body color='primary'>{Countries[value].dialCode}</Body>
            </FlexLayout>
          ) : undefined
        }
        trailingIcon={anchorEl ? <ArrowChevronUp className='h-16 w-16' /> : <ArrowChevronDown className='h-16 w-16' />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        tabIndex={compact ? -1 : undefined}
      >
        {value && !compact && (
          <Body className='ml-8' weight='emphasis'>
            {Countries[value].name}
          </Body>
        )}
      </Button>
      <CountryMenu
        countries={countries}
        anchorEl={anchorEl}
        onClose={onClose}
        onCountrySelected={onSelectCountry}
        selectedCountry={value}
      />
    </>
  );

  if (compact) {
    return getCompactContent();
  }

  return (
    <Autocomplete
      sx={{
        width: '100%',
        marginBottom: 3,
        ...sx,
        '.MuiTypography-root': {
          fontSize: 12,
        },
      }}
      options={countries}
      autoHighlight
      value={autoCompleteValue}
      inputValue={autoCompleteValue?.label}
      defaultValue={autoCompleteValue}
      onChange={(e, option) => option && onSelectCountry(option?.code)}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={(option) => disabledOptions?.includes(option.code) || false}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...(props as any)}>
          <img loading='lazy' width='20' src={`/assets/images/flags/${option.code}.svg`} alt='' />
          {option.label}
        </Box>
      )}
      groupBy={onlySuggested ? undefined : (option) => option.group}
      renderInput={(params) => (
        <Input
          {...params}
          sx={{
            height: '50px',
            '.MuiInputBase-root': {
              backgroundColor: 'var(--color-background-surface-secondary)',
              fontSize: 12,
              fontWeight: 500,
              padding: 1,
            },
          }}
          label={label}
          placeholder={placeholder}
          autoComplete='off'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            startAdornment: (
              <Box
                sx={{
                  borderRadius: '100%',
                  overflow: 'hidden',
                  position: 'relative',
                  width: '1.8rem',
                  height: '1.8rem',
                  marginLeft: '5px',
                  marginRight: '0.5rem',
                }}
              >
                {value && (
                  <img
                    loading='lazy'
                    src={`/assets/images/flags/${value}.svg`}
                    alt=''
                    width='70rem'
                    style={{ position: 'absolute', transform: 'scale(1.4)', top: '4px' }}
                  />
                )}
                {!value && <Box sx={{ height: '1.8rem', width: '1.8rem', bgcolor: 'text.disabled' }} />}
              </Box>
            ),
          }}
          required={required}
          error={error}
        />
      )}
    />
  );
};

export default CountryDropdown;

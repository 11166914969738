import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { QuickBuyType } from 'src/lib/trade/types/Trade.types';

type Props = {
  title: string;
  value: QuickBuyType;
  onClick: (value: QuickBuyType) => void;
  selected: boolean;
  className?: string;
  disabled?: boolean;
};

const QuickBuyWidgetTab: React.FC<Props> = ({ value, title, onClick, selected, className, disabled }) => {
  const titleElement = useMemo(() => {
    if (selected) {
      return (
        <Heading size='h6' color='primary'>
          {title}
        </Heading>
      );
    }

    return (
      <Heading size='h6' color='secondary'>
        {title}
      </Heading>
    );
  }, [selected, title]);

  return (
    <FlexLayout
      className={cn(
        className,
        'h-full w-1/2 p-16 sm:p-24 ',
        disabled ? 'cursor-not-allowed opacity-40' : 'cursor-pointer',
      )}
      alignItems='center'
      justifyContent='center'
      onClick={() => !disabled && onClick(value)}
    >
      {titleElement}
    </FlexLayout>
  );
};

export { QuickBuyWidgetTab };

import { useEffect, useMemo, useState } from 'react';

type Size = {
  width: number;
  height: number;
};

type Props = {
  elementId: string;
};

const useResize = ({ elementId }: Props) => {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((event) => {
        window.requestAnimationFrame(() => {
          if (event.length === 1) {
            const { clientWidth, clientHeight } = event[0].target;
            setSize({ width: clientWidth, height: clientHeight });
          }
        });
      }),
    [],
  );

  useEffect(() => {
    const element = document.getElementById(elementId);

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeObserver, elementId]);

  return {
    width: size.width,
    height: size.height,
  };
};

export { useResize };

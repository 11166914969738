import React from 'react';
import { RouteObject } from 'react-router-dom';

import MaintenanceRoute from '@routes/MaintenanceRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { Maintenance } from './Maintenance';

export const MaintenanceRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Maintenance,
    element: <MaintenanceRoute element={<Maintenance />} />,
  },
];

/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export type Props = {
    id: string;
    width?: string | number;
    height?: string | number;
    alt: string;
};

const KoinlyLogo: React.FC<Props> = ({id, width, height, alt}) => (
    <img id={id} src='/assets/images/logos/koinly.png' alt={alt} width={width} height={height}/>
);

export {KoinlyLogo};

import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';

import { Menu } from '@swyftx/react-web-design-system';

import { SearchInput } from '@global-components/Input';

import { Asset } from '@shared/api';
import useDebounce from '@shared/hooks/lib/useDebounce';
import { assetService } from '@shared/services';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { AssetRow, AssetRowHeader } from '@Dashboard/components/Tiles/components';

import { observer } from 'mobx-react-lite';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

type Props = Omit<MenuProps, 'open'> & {
  anchorEl: null | HTMLElement;
  type?: 'all' | 'trade' | 'earn';
  currentAssetId?: number;
  currentAssetCode?: string;
  forceRenderAssets?: (assets: Array<Asset>) => React.ReactNode;
  onClose: () => void;
};

const INPUT_DEBOUNCE = 500;
const DESKTOP_HEIGHT = 500;
const DESKTOP_WIDTH = 460;
const MOBILE_WIDTH = 300;

export const AssetPickerMenu: React.FC<Props> = observer(
  ({ anchorEl, currentAssetCode, currentAssetId, onClose, forceRenderAssets, ...props }) => {
    const [assetSearch, setAssetSearch] = useState<string>('');
    const { bx } = useContentBreakpoint();
    const baseAsset = useBaseAsset();
    const { navigateToAsset } = useAssetNavigation();

    const currentAsset = useMemo(() => {
      if (currentAssetId) {
        return assetService.getAsset(currentAssetId);
      }
      if (currentAssetCode) {
        return assetService.getAssetByCode(currentAssetCode);
      }
      return undefined;
    }, [currentAssetId, currentAssetCode]);

    const debouncedAssetSearch = useDebounce(assetSearch, INPUT_DEBOUNCE);

    const assets = useMemo(() => {
      const assetsList = assetService.getActiveAssetList();

      const assetKeys = Object.values(assetsList)
        .filter((a) => !Number.isNaN(a.id))
        .map((a) => Number(a.id));
      const assetInformation = assetKeys.map(assetService.getAsset);
      const validAssets: Asset[] = assetInformation.filter((asset) => asset !== undefined) as Asset[];

      const searchAssets = validAssets.filter(
        (asset) =>
          asset.code.toLowerCase().includes(debouncedAssetSearch.toLowerCase()) ||
          asset.name.toLowerCase().includes(debouncedAssetSearch.toLowerCase()),
      );

      return searchAssets.sort((a, b) => a.rank - b.rank);
    }, [debouncedAssetSearch]);

    const isCurrentAsset = (assetId: number): boolean => Boolean(currentAsset && assetId === currentAsset.id);

    const renderAssets = () =>
      assets &&
      assets.map((asset) => (
        <Box
          key={asset.code}
          sx={{
            marginX: 0,
            paddingX: 0,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: 'action.hover',
              borderRadius: 0.5,
              cursor: 'pointer',
            },
          }}
        >
          <Box sx={{ width: '100%' }}>
            <AssetRow
              selected={isCurrentAsset(asset.id)}
              height={40}
              width={80}
              asset={asset}
              key={asset.id}
              baseAsset={baseAsset}
              idKey='appHeader.AssetPicker'
              isAppHeader
              onSelectAsset={(selectedAsset) => navigateToAsset(selectedAsset, true)}
            />
          </Box>
        </Box>
      ));

    return (
      <Menu
        {...props}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => onClose()}
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: { width: '100%', maxWidth: bx({ xs: MOBILE_WIDTH, md: DESKTOP_WIDTH }), maxHeight: DESKTOP_HEIGHT },
        }}
      >
        <Box
          key='asset-picker-menu-search'
          sx={{ width: '100%', paddingX: 1.5, paddingTop: bx({ xs: 1, md: 2 }), paddingBottom: bx({ xs: 1, md: 0 }) }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <SearchInput
            id='asset-picker-menu-search-input'
            placeholder='Search for an asset'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAssetSearch(e.target.value);
            }}
            onDelete={() => {
              setAssetSearch('');
            }}
            InputProps={{
              sx: { height: '46px', fontSize: '14px' },
            }}
            value={assetSearch}
          />
        </Box>
        {!forceRenderAssets ? (
          <Box sx={{ marginBottom: 1, display: bx({ xs: 'none', md: 'block' }) }}>
            <AssetRowHeader width={80} isAppHeader />
          </Box>
        ) : null}
        {forceRenderAssets ? forceRenderAssets(assets) : renderAssets()}
      </Menu>
    );
  },
);

import React, { useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';

import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

import { VerifyAccountWidget, FundAccountWidget, FirstTradeWidget } from '../../components';

type Props = {
  className?: string;
};

const VerificationCompactWidget: React.FC<Props> = observer(({ className }) => {
  const { isVerified, hasDeposited, hasTraded } = useProfileStatus();

  const element = useMemo(() => {
    if (!isVerified) return <VerifyAccountWidget variant='compact' direction='column' isCurrentStep />;
    if (!hasDeposited) return <FundAccountWidget variant='compact' direction='column' isCurrentStep />;
    if (!hasTraded) return <FirstTradeWidget variant='compact' direction='column' isCurrentStep />;
    return null;
  }, [hasDeposited, hasTraded, isVerified]);

  return <Card className={cn(className, 'p-24')}>{element}</Card>;
});

export { VerificationCompactWidget };

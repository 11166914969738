export enum SourceOfWealthStatus {
  UNREQUESTED = 'UNREQUESTED',
  REQUIRED = 'REQUIRED',
  REQUESTED = 'REQUESTED', // deprecated
  STARTED = 'STARTED',
  SUBMITTED = 'SUBMITTED',
  MORE_INFO_REQUESTED = 'MORE_INFO_REQUESTED',
  MORE_INFO_SUBMITTED = 'MORE_INFO_SUBMITTED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}

export type GetOccupationResponse = { code: string; occupation: string }[];
export type GetEmploymentTypesResponse = { code: string; name: string }[];
export type GetIndustryResponse = { code: string; industry: string }[];

export type GetDocumentUploadUrlResponse = {
  id: string;
  url: string;
  expiresAt: number;
};

export type GetSowDocumentsResponse = UserDocumentDto[];

/**
 * The file name.
 *
 * @pattern ^[^\\\/:*"?<>|\u0000-\u001F]{1,252}\.(pdf|jpeg|jpg|png)$
 * @minLength 1
 * @maxLength 255
 * @see https://github.com/sindresorhus/filename-reserved-regex
 */
export type PermittedFileName = string;

/**
 * The file MIME type.
 */
export type PermittedFileType = 'application/pdf' | 'image/jpeg' | 'image/png';

/**
 * File size in bytes.
 *
 * @minimum 1
 * @maximum 5242880
 */
export type PermittedFileSize = number;

export type GeneratePresignedUrlPayload = {
  // eslint-disable-next-line prettier/prettier
  fileType: PermittedFileType;
  // eslint-disable-next-line prettier/prettier
  filename: PermittedFileName;
  // eslint-disable-next-line prettier/prettier
  size: PermittedFileSize;
};

export interface UserDocumentDto {
  id: string;
  filename: PermittedFileName;
  status: 'pending' | 'completed';
}

export enum CashDepositPercentageOption {
  LESS_THAN_20 = 'LT_20',
  GREATER_THAN_20 = 'GT_20',
}

export enum SourceOfWealthOption {
  OCCUPATIONAL_INCOME = 'OCCUPATIONAL_INCOME',
  INVESTMENT_INCOME = 'INVESTMENT_INCOME',
  RENTAL_INCOME = 'RENTAL_INCOME',
  BUSINESS_OWNERSHIP = 'BUSINESS_OWNERSHIP',
  INTELLECTUAL_PROPERTY = 'INTELLECTUAL_PROPERTY',
  SAVINGS = 'SAVINGS',
  INHERITANCE = 'INHERITANCE',
  GAMBLING_OR_LOTTERY = 'GAMBLING_OR_LOTTERY',
  SELF_MANAGED_SUPER_FUND = 'SELF_MANAGED_SUPER_FUND',
}

export enum ExpectedAnnualSpendOption {
  LESS_THAN_50K = 'LESS_THAN_50K',
  BETWEEN_50K_AND_100K = 'BETWEEN_50K_AND_100K',
  BETWEEN_100K_AND_250K = 'BETWEEN_100K_AND_250K',
  BETWEEN_250K_AND_500K = 'BETWEEN_250K_AND_500K',
  BETWEEN_500K_AND_1M = 'BETWEEN_500K_AND_1M',
}

export type ExpectedInvestments =
  | { expectedAnnualSpend: ExpectedAnnualSpendOption | null }
  | { expectedAnnualSpendOther?: string | null };

export interface SourceOfWealthVerificationDto {
  id: string;
  status: SourceOfWealthStatus;
  requestedAt: number;
  submittedAt: number | null;
  moreInfoRequestedAt: number | null;
  moreInfoSubmittedAt: number | null;
  completedAt: number | null;
  expiresAt: number;
}

export type SourceOfWealthFormPartial = {
  employmentType?: string | null;
  industry?: string | null;
  occupation?: string | null;
  cashDepositsPercentage?: CashDepositPercentageOption | null;
  cashDepositsOrigin?: string | null;
  sourcesOfWealth?: SourceOfWealthOption[] | null;
  sourcesOfWealthOther?: string | null;
} & ExpectedInvestments;

export type SourceOfWealthResponse = SourceOfWealthVerificationDto & SourceOfWealthFormPartial;

export type SourceOfWealthPayload = SourceOfWealthFormPartial;

import React from 'react';

import { Stack } from '@swyftx/react-web-design-system';

// NOTE: isSm and isMd variables here are using new breakpoint (useContentBreakpoint is.sm and is.md)
const getColumns = (components: React.ReactNode[], isSm: boolean, isMd: boolean): React.ReactNode[] => {
  if (isSm) {
    return [
      <Stack key='advanced-config-col-1' direction='column' width='100%' spacing={2}>
        {components[0]}
        {components[5]}
        {components[1]}
      </Stack>,
      <Stack key='advanced-config-col-2' direction='column' width='100%' spacing={2}>
        {components[2]}
        {components[3]}
        {components[4]}
      </Stack>,
    ];
  }
  if (isMd) {
    return [
      <Stack key='advanced-config-col-1' direction='column' width='100%' spacing={2}>
        {components[0]}
        {components[5]}
      </Stack>,
      <Stack key='advanced-config-col-2' direction='column' width='100%' spacing={2}>
        {components[2]}
        {components[3]}
      </Stack>,
      <Stack key='advanced-config-col-3' direction='column' width='100%' spacing={2}>
        {components[1]}
        {components[4]}
      </Stack>,
    ];
  }
  return [
    <Stack key='advanced-config-col-1' direction='column' width='100%'>
      {components[0]}
    </Stack>,
    <Stack key='advanced-config-col-2' direction='column' width='100%' spacing={2}>
      {components[2]}
      {components[1]}
    </Stack>,
    <Stack key='advanced-config-col-3' direction='column' width='100%' spacing={2}>
      {components[4]}
      {components[5]}
    </Stack>,
    <Stack key='advanced-config-col-4' direction='column' width='100%'>
      {components[3]}
    </Stack>,
  ];
};

export { getColumns };

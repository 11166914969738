import React, { useCallback } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';

import { useEntityOnboardingAnalytics } from '../events/useEntityOnboardingAnalytics';

interface CompleteProfileStepProps {
  actionUnlocked: boolean;
}

export const SubmitApplicationStep: React.FC<CompleteProfileStepProps> = (props) => {
  const { actionUnlocked } = props;

  const { startedApplication, entityCallToActionClicked } = useEntityOnboardingAnalytics();
  const { inProgressSubmissions, startNewEntityApplication, goToExistingApplication } =
    useEntityOnboardingApplication();

  const onNavigateToEntityOnboarding = useCallback(() => {
    startedApplication();
    entityCallToActionClicked({ callToActionName: 'Create entity account' });
    if (!inProgressSubmissions.length) {
      startNewEntityApplication();
    } else {
      goToExistingApplication();
    }
  }, [
    entityCallToActionClicked,
    goToExistingApplication,
    inProgressSubmissions.length,
    startNewEntityApplication,
    startedApplication,
  ]);

  return (
    <FlexLayout direction='row' className='mx-8 w-full @md:w-1/3' spacing={24}>
      <Image image='entity_application' className='w-60' />
      <FlexLayout direction='column' spacing={8}>
        <Avatar
          className={cn(!actionUnlocked ? 'border border-color-text-primary bg-color-background-surface-primary' : '')}
        >
          <Body color={!actionUnlocked ? 'primary' : 'inverse'} size='small'>
            3
          </Body>
        </Avatar>
        <Heading size='h6' color='secondary'>
          Submit entity application
        </Heading>
        <Body color='secondary' size='xsmall'>
          Provide some information about your entity, its members and legal documentation. This is a legal requirement
          for all entity applicants.
        </Body>
        {actionUnlocked && (
          <FlexLayout direction='row' className='mt-4'>
            <Button onClick={onNavigateToEntityOnboarding}>Start Application</Button>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

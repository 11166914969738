import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

import { AffiliateDashboardPastPayoutsTable } from './PastPayoutsTable';

const AffiliateDashboardPastPayouts: React.FC = observer(() => {
  const { t } = useTranslation('profile.affiliates');

  return (
    <FlexLayout direction='column' className='m-16'>
      <Body weight='emphasis'>{t('affiliateDashboard.pastPayouts.title')}</Body>
      <AffiliateDashboardPastPayoutsTable />
    </FlexLayout>
  );
});

export { AffiliateDashboardPastPayouts };

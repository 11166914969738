import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Chip, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { AssetPriceText, FormattedText } from '@global-components/Text';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  value: Big;
  totalValue: Big;
  asset: Asset;
  isSmall?: boolean;
};

const PortfolioBreakdownBalanceItem: React.FC<Props> = observer(({ value, totalValue, asset, isSmall = false }) => {
  const { isLightTheme, getContrastAssetColor } = useTheme();
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);
  const { navigate } = useNavigateRoute();
  const { all } = useAssetBalance(asset);

  const percentage = useMemo(() => {
    if (!baseAsset) return 0;

    const valueInBase = Big(getRate(asset).midPrice).times(value);
    const totalValueInBase = Big(getRate(baseAsset).midPrice).times(totalValue);

    return valueInBase.div(totalValueInBase).times(100).toNumber();
  }, [totalValue, value, getRate, asset, baseAsset]);

  const openWallet = () => {
    navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset.code } });
  };

  const getColor = (val: string, emptyValue: NumericProps['color']) => {
    const bigValue = Big(val);

    // Typography works with color set to error, but not with success (need to be specified as success.main)
    if (bigValue.gt(0)) return 'success';
    if (bigValue.lt(0)) return 'error';

    return emptyValue;
  };

  const getChip = () => {
    const bigValue = Big(all.changePercent || '0');

    if (bigValue.gt(0)) {
      return (
        <Chip
          variant='filled'
          color='success'
          size='small'
          label={`${Big(all.changePercent || '0')
            .toNumber()
            .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`}
          sx={{ width: 'fit-content', fontSize: 12, weight: 'emphasis' }}
        />
      );
    }

    if (bigValue.lt(0)) {
      return (
        <Chip
          variant='filled'
          size='small'
          color='error'
          label={`${Big(all.changePercent || '0')
            .toNumber()
            .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`}
          sx={{ width: 'fit-content', fontSize: 12, weight: 'emphasis' }}
        />
      );
    }

    return (
      <Chip
        variant='filled'
        color='default'
        size='small'
        label={`${Big(all.changePercent || '0')
          .toNumber()
          .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`}
        sx={{ width: 'fit-content', fontSize: 12, weight: 'emphasis' }}
      />
    );
  };

  return (
    <Stack
      direction='column'
      spacing={1}
      paddingY={1}
      paddingX={2}
      onClick={openWallet}
      sx={{
        '&:hover': {
          backgroundColor: isLightTheme ? 'grey.50' : 'grey.900',
          cursor: 'pointer',
        },
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <AssetIcon asset={asset} size={20} />
        <Stack direction='column' width='100%' spacing={0.5}>
          <Stack direction='row' width='100%' justifyContent='space-between'>
            <Typography id='asset-code' fontSize={12} fontWeight={600} color='text.secondary'>
              {asset?.code}
            </Typography>
            {baseAsset && asset && (
              <AssetPriceText
                asset={asset}
                balance={value}
                liveChanges
                formatOpts={{ priceScale: isBaseAssetFiat ? 2 : baseAsset.price_scale }}
                typographyProps={{ weight: 'emphasis', color: 'primary' }}
                isPortfolioTile
              />
            )}
          </Stack>
          <Stack direction='row' width='100%' justifyContent='space-between'>
            <FormattedText formatBalance value={value} currency={asset} />
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
              <FormattedText
                typographyProps={{
                  color: getColor(all.change || '0', 'secondary'),
                }}
                value={all.change || ''}
                formatBalance={false}
                currency={baseAsset}
                formatOpts={{
                  priceScale: isBaseAssetFiat ? 2 : baseAsset?.price_scale,
                }}
              />
              {!isSmall ? getChip() : null}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box
        sx={{
          borderRadius: 1,
          width: '100%',
          height: '6px',
          position: 'relative',
          backgroundColor: isLightTheme ? 'grey.200' : 'grey.700',
          overflow: 'hidden',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: asset ? getContrastAssetColor(asset.code) : 'primary.main',
            width: `${percentage}%`,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '6px',
            zIndex: 2,
          }}
        />
      </Box>
    </Stack>
  );
});

export { PortfolioBreakdownBalanceItem };

import { useContext, useEffect, useState } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { Candle } from '@shared/api/@types/charts';

import * as Sentry from '@sentry/react';
import { IChartApi, ISeriesApi } from 'lightweight-charts';

import { useCreateAreaSeries, useCreateBarSeries, useCreateCandleSeries, useCreateLineSeries } from './series';
import { useSimpleChartVolume } from './useSimpleChartVolume';
import { TradingViewContext } from '../../context/TradingView.context';
import { ChartStyle } from '../../types';

type Props = {
  chartState?: IChartApi;
  data?: Candle[];
  latestData?: Candle;
  isFetching?: boolean;
  secondary: string;
};

const useSimpleChartSeries = ({ chartState, data, latestData, isFetching, secondary }: Props) => {
  const { isDarkMode } = useTailwindTheme();

  const [series, setSeries] = useState<ISeriesApi<'Area' | 'Bar' | 'Candlestick' | 'Line'>>();
  const { selectedChartType, selectedChartStyle } = useContext(TradingViewContext);

  const { createBarSeries, updateBarSeries } = useCreateBarSeries({ chartState, data });
  const { createAreaSeries, updateAreaSeries } = useCreateAreaSeries({ chartState, data });
  const { createLineSeries, updateLineSeries } = useCreateLineSeries({ chartState, data });
  const { createCandleSeries, updateCandleSeries } = useCreateCandleSeries({ chartState, data });
  const { volumeSeries, createChartVolume } = useSimpleChartVolume({ chartState, data, secondary });

  useEffect(() => {
    if (!chartState || !data || isFetching) return;

    if (series !== undefined) {
      try {
        chartState.removeSeries(series);
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    switch (selectedChartType) {
      case ChartStyle.Area:
        setSeries(createAreaSeries());
        break;
      case ChartStyle.Line:
        setSeries(createLineSeries());
        break;
      case ChartStyle.Bar:
        setSeries(createBarSeries());
        break;
      default:
      case ChartStyle.Candle:
        setSeries(createCandleSeries());
        break;
    }

    chartState.timeScale().fitContent();

    chartState.timeScale().applyOptions({
      rightOffset: 5,
      fixLeftEdge: true,
    });

    createChartVolume();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    chartState,
    createAreaSeries,
    createBarSeries,
    createCandleSeries,
    createLineSeries,
    createChartVolume,
    selectedChartStyle,
    selectedChartType,
    isDarkMode,
    isFetching,
  ]);

  useEffect(() => {
    if (!latestData || !series || !data || isFetching) return;

    switch (selectedChartType) {
      case ChartStyle.Area:
        updateAreaSeries(series as ISeriesApi<'Area'>, latestData);
        break;
      case ChartStyle.Line:
        updateLineSeries(series as ISeriesApi<'Line'>, latestData);
        break;
      case ChartStyle.Bar:
        updateBarSeries(series as ISeriesApi<'Bar'>, latestData);
        break;
      default:
      case ChartStyle.Candle:
        updateCandleSeries(series as ISeriesApi<'Candlestick'>, latestData);
        break;
    }
  }, [
    data,
    isDarkMode,
    isFetching,
    latestData,
    selectedChartType,
    series,
    updateAreaSeries,
    updateBarSeries,
    updateCandleSeries,
    updateLineSeries,
  ]);

  return {
    series,
    volumeSeries,
  };
};

export { useSimpleChartSeries };

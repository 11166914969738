import { useCallback } from 'react';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UIStore } from '@shared/store';

const useGlobalSearch = () => {
  const { openModal } = useModal();
  const { setGlobalModalData } = UIStore.useUIStore;

  const openGlobalSearch = useCallback(() => {
    openModal(Modals.GlobalSearch, { defaultOpen: true });
  }, [openModal]);

  const closeGlobalSearch = useCallback(() => {
    setGlobalModalData(undefined);
  }, [setGlobalModalData]);

  return {
    openGlobalSearch,
    closeGlobalSearch,
  };
};

export { useGlobalSearch };

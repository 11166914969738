import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Add } from '@swyftx/aviary/icons/outlined';
import { RadioGroup } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

import { CorporateTrustForm } from '@shared/api';

import { useShareholdersStep } from '@routes/EntityOnboarding/hooks/useShareholdersStep';
import { yesNoRadioGroup } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';
import { getRadioSelectedValue } from '@routes/EntityOnboarding/utils/getRadioSelectedValue';

import { observer } from 'mobx-react-lite';

import { AddCorporateShareholder } from './components/AddCorporateShareholder';
import { AddIndividual } from './components/AddIndividual';
import { CorporateCard } from './components/CorporateCard';
import { IndividualCard } from './components/IndividualCard';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { ConfirmRemoveCorporateMembersModal } from '../../modals/ConfirmRemoveCorporateMembersModal';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const TrustAddShareholdersStep: React.FC = observer(() => {
  const {
    addIndividualMemberOpen,
    addCorporateMemberOpen,
    continueDisabled,
    corporateShareholders,
    handleRemove,
    hasCorporateTrustShareholders,
    setHasCorporateTrustShareholders,
    individualShareholders,
    onCancel,
    onAddCorporateShareholder,
    onAddMember: onAddTrustMember,
    toggleAddCorporateMemberOpen,
    toggleAddIndividualMemberOpen,
    entityOnboardingService,
    shareholders,
    onEditIndividual,
    onEditCorporate,
    removeAllCorporateShareholders,
    hasNotAddedCorporateTrustShareholder,
    directors,
    beneficiaries,
  } = useShareholdersStep();
  const [confirmRemoveCorporateShareholdersOpen, setConfirmRemoveCorporateShareholdersOpen] = useState<boolean>(false);

  const rawShareholders = useMemo(() => shareholders.map((shareholder) => shareholder.shareholder), [shareholders]);
  const rawDirectors = useMemo(() => directors.map((director) => director.individual), [directors]);
  const rawBeneficiaries = useMemo(() => beneficiaries.map((beneficiary) => beneficiary.individual), [beneficiaries]);

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: {
          shareholders: rawShareholders,
          directors: rawDirectors,
          beneficiaries: rawBeneficiaries,
        } as CorporateTrustForm,
      }),
    [entityOnboardingService, rawBeneficiaries, rawDirectors, rawShareholders],
  );

  const handleBack = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'BACK',
        data: {
          shareholders: rawShareholders,
          directors: rawDirectors,
          beneficiaries: rawBeneficiaries,
        } as CorporateTrustForm,
      }),
    [entityOnboardingService, rawBeneficiaries, rawDirectors, rawShareholders],
  );

  const handleRadioOnChange = useCallback(
    (newValue: boolean) => {
      if (hasCorporateTrustShareholders === true && corporateShareholders.length && newValue === false) {
        return setConfirmRemoveCorporateShareholdersOpen(true);
      }
      setHasCorporateTrustShareholders(newValue);
      toggleAddCorporateMemberOpen(false);
      toggleAddIndividualMemberOpen(false);
    },
    [
      hasCorporateTrustShareholders,
      corporateShareholders.length,
      setHasCorporateTrustShareholders,
      toggleAddCorporateMemberOpen,
      toggleAddIndividualMemberOpen,
    ],
  );

  const submitDisabled = useMemo(
    () =>
      !shareholders.length || addCorporateMemberOpen || addIndividualMemberOpen || hasNotAddedCorporateTrustShareholder,
    [addCorporateMemberOpen, addIndividualMemberOpen, hasNotAddedCorporateTrustShareholder, shareholders.length],
  );

  return (
    <>
      <EntityOnboardingStepContainer
        customTitle='Add shareholders of corporate trustee'
        submitDisabled={continueDisabled || submitDisabled}
        onSubmit={handleSubmit}
        onBack={handleBack}
      >
        <EntityOnboardingPageLayout
          subtitle="List all shareholders that own 25% or more shares, equity or voting rights of your trust's corporate
            trustee. We are required to collect this for regulatory purposes."
        >
          <FlexLayout direction='column' spacing={12}>
            <Body weight='emphasis'>Are any of your shares held by a company or business?</Body>
            <RadioGroup
              checkboxes={yesNoRadioGroup}
              selected={
                hasCorporateTrustShareholders !== undefined
                  ? getRadioSelectedValue(hasCorporateTrustShareholders)
                  : undefined
              }
              onChange={(value) => handleRadioOnChange(value === 'yes')}
            />
          </FlexLayout>
          {hasCorporateTrustShareholders !== undefined && (
            <FlexLayout direction='column' spacing={16}>
              <Body weight='emphasis'>Please add all shareholders:</Body>
              {individualShareholders.map((shareholder, index) => (
                <IndividualCard
                  key={`${shareholder.individual.firstName}-${index}`}
                  member={shareholder}
                  onRemove={() => handleRemove(shareholder.uuid)}
                  memberType='shareholder'
                  onEditMember={onEditIndividual}
                />
              ))}
              {corporateShareholders.map((shareholder, index) => (
                <CorporateCard
                  key={`${shareholder.corporate.name}-${index}`}
                  shareholder={shareholder}
                  onRemove={() => handleRemove(shareholder.uuid)}
                  onEditCorporateMember={onEditCorporate}
                />
              ))}
              {addIndividualMemberOpen && (
                <Card className='p-12'>
                  <AddIndividual onCompanySubmit={onAddTrustMember} onCancel={onCancel} memberType='shareholder' />
                </Card>
              )}
              {addCorporateMemberOpen && (
                <Card className='p-12'>
                  <AddCorporateShareholder
                    onCancel={toggleAddCorporateMemberOpen}
                    onSubmit={onAddCorporateShareholder}
                  />
                </Card>
              )}
              <FlexLayout direction='row' className='w-full flex-wrap' spacing={8}>
                <Button
                  leadingIcon={<Add />}
                  onClick={() => toggleAddIndividualMemberOpen(true)}
                  disabled={addIndividualMemberOpen || addCorporateMemberOpen}
                >
                  Add individual shareholder
                </Button>
                {hasCorporateTrustShareholders && (
                  <Button
                    leadingIcon={<Add />}
                    onClick={toggleAddCorporateMemberOpen}
                    disabled={addIndividualMemberOpen || addCorporateMemberOpen}
                  >
                    Add corporate shareholder
                  </Button>
                )}
              </FlexLayout>
              <FlexLayout direction='column' spacing={4}>
                {hasNotAddedCorporateTrustShareholder && (
                  <Body size='small' color='error'>
                    Please add at least one corporate shareholder
                  </Body>
                )}
              </FlexLayout>
            </FlexLayout>
          )}
        </EntityOnboardingPageLayout>
      </EntityOnboardingStepContainer>
      {confirmRemoveCorporateShareholdersOpen && (
        <ConfirmRemoveCorporateMembersModal
          open={confirmRemoveCorporateShareholdersOpen}
          setOpen={setConfirmRemoveCorporateShareholdersOpen}
          onConfirm={removeAllCorporateShareholders}
        />
      )}
    </>
  );
});

import React, { useEffect } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { observer } from 'mobx-react-lite';
import { AutoInvestAssetAllocation } from 'src/lib/auto-invest/components';
import { useNavigateExitIntent } from 'src/lib/navigation/hooks/useNavigateExitIntent';
import { MAX_AUTO_INVEST_ASSETS } from 'src/lib/trade/trade.consts';

import { AutoInvestCardHeader } from '../components';
import { AutoInvestDonutChart } from '../components/AutoInvestDonutChart';
import { useAutoInvestValues } from '../hooks';

const title = 'Step 1: Set asset allocations';
const description = `Select up to ${MAX_AUTO_INVEST_ASSETS} assets and set a percentage investment amount.`;

const AutoInvestWidgetValues: React.FC = observer(() => {
  const {
    validAssets,
    selectedAutoInvestAssets,
    setCurrentStep,
    onCancel,
    onLock,
    onRemove,
    handleAddNewAsset,
    onUpdateAsset,
    onUpdatePercentage,
    onBulkUpdateAssets,
  } = useAutoInvestValues();
  const { initExitIntent, removeExitIntent } = useNavigateExitIntent();

  useEffect(() => {
    if (selectedAutoInvestAssets.length > 0 && selectedAutoInvestAssets[0].assetCode) {
      initExitIntent(
        'Discard changes?',
        'Are you sure you want to discard the changes you’ve made to this order?',
        'Select Assets',
        'Auto Invest',
      );
    } else {
      removeExitIntent();
    }
  }, [initExitIntent, removeExitIntent, selectedAutoInvestAssets]);

  return (
    <FlexLayout
      direction='column'
      spacing={8}
      alignItems='center'
      justifyContent='space-between'
      className='h-full w-full'
    >
      <FlexLayout
        direction='column'
        className='h-full w-full overflow-auto p-16 @sm:p-24'
        spacing={8}
        alignItems='center'
        justifyContent='space-between'
      >
        <FlexLayout direction='column' className='h-full w-full' spacing={24} alignItems='center'>
          <AutoInvestCardHeader title={title} description={description} />
          <AutoInvestDonutChart autoInvestAssets={selectedAutoInvestAssets} />
          <AutoInvestAssetAllocation
            assets={selectedAutoInvestAssets}
            onLock={onLock}
            onRemove={onRemove}
            onUpdatePercentage={onUpdatePercentage}
            onUpdateAsset={onUpdateAsset}
            onAddNewAsset={handleAddNewAsset}
            onBulkUpdateAssets={onBulkUpdateAssets}
          />
        </FlexLayout>
        <FlexLayout direction='column' className='mt-24 w-full sm:mt-0' spacing={12}>
          <Button
            size='lg'
            className='w-full'
            onClick={() => setCurrentStep('orderPreferences')}
            disabled={!validAssets.valid}
            tooltip={validAssets.tooltip}
          >
            Continue
          </Button>
          <Button variant='outlined' size='lg' className='w-full' onClick={onCancel}>
            Cancel
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AutoInvestWidgetValues };

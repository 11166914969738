import { useCallback, useMemo } from 'react';

import { StateNode } from 'xstate';

import { useEntityOnboardingSelector } from '../EntityOnboarding.context';
import { entityOnboardingMachine } from '../EntityOnboarding.machine';
import { Step, transformEntityOnboardingStates } from '../components/EntityOnboardingStepper/stepperUtils';

export const useStepperSteps = () => {
  const transformedSteps: Step[] = useMemo(
    () =>
      transformEntityOnboardingStates(entityOnboardingMachine.states as Record<string, StateNode>).filter(
        (step) => !step.hidden,
      ),
    [],
  );
  const completedSteps = useEntityOnboardingSelector((state) => state.context.completedSteps);

  const [activeRootStepId] = useEntityOnboardingSelector((state) => state.toStrings());

  const isStepComplete = useCallback(
    (step: Step) =>
      completedSteps[step.id] || (step.children.length && step.children.every((s) => completedSteps[s.id])),
    [completedSteps],
  );
  const entityType = useEntityOnboardingSelector((state) => state.context.applicationData.type);

  const activeRootStep = transformedSteps.find((s) => s.id === activeRootStepId);

  const fetchStepNumber = useCallback((step: Step) => {
    switch (step.id) {
      case 'introduction':
        return 0;
      case 'entityType':
        return 1;
      case 'companyInformation':
      case 'trustInformation':
        return 2;
      case 'companyAddMembers':
      case 'individualTrustAddMembers':
      case 'corporateTrustAddMembers':
      case 'smsfAddMembers':
        return 3;
      case 'uploadDocuments':
        return 4;
    }
  }, []);

  const strippedTransformedSteps = transformedSteps.filter((step) => {
    if (entityType === 'SMSF_INDIVIDUAL' || entityType === 'SMSF_CORPORATE') {
      return ![
        'trustInformation',
        'individualTrustAddMembers',
        'corporateTrustAddMembers',
        'companyAddMembers',
      ].includes(step.id);
    }
    if (entityType === 'TRUST_INDIVIDUAL') {
      return !['companyInformation', 'companyAddMembers', 'smsfAddMembers', 'corporateTrustAddMembers'].includes(
        step.id,
      );
    }
    if (entityType === 'TRUST_CORPORATE') {
      return !['companyInformation', 'companyAddMembers', 'smsfAddMembers', 'individualTrustAddMembers'].includes(
        step.id,
      );
    }
    return !['trustInformation', 'individualTrustAddMembers', 'corporateTrustAddMembers', 'smsfAddMembers'].includes(
      step.id,
    );
  });

  const isActive = useCallback(
    (step: Step) => {
      if (fetchStepNumber(step) === 2 && ['trustInformation', 'companyInformation'].includes(activeRootStepId)) {
        return true;
      }
      if (
        fetchStepNumber(step) === 3 &&
        ['companyAddMembers', 'trustAddMembers', 'smsfAddMembers'].includes(activeRootStepId)
      ) {
        return true;
      }
      return !!activeRootStep && activeRootStep.id === step.id;
    },
    [activeRootStep, activeRootStepId, fetchStepNumber],
  );

  return { isActive, isStepComplete, steps: strippedTransformedSteps, fetchStepNumber, activeRootStep };
};

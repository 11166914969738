import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ThreeUser: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8872 3.49976C9.36447 3.49976 7.31898 5.54477 7.3182 8.06733L7.3182 8.06876H8.0682L7.31821 8.0659L7.3182 8.06733C7.3094 10.5828 9.34062 12.6294 11.8567 12.6378L11.8592 12.6378H11.8872C14.4103 12.6378 16.4562 10.5931 16.4562 8.06876C16.4562 5.54554 14.4104 3.49976 11.8872 3.49976ZM8.8182 8.06876C8.8182 6.37397 10.1924 4.99976 11.8872 4.99976C13.582 4.99976 14.9562 6.37397 14.9562 8.06876C14.9562 9.76441 13.5821 11.1378 11.8872 11.1378H11.8605C10.1736 11.1315 8.81178 9.75907 8.8182 8.07161L8.8182 8.06876Z'
      fill='currentColor'
    />
    <path
      d='M18.076 4.4795C17.6673 4.41242 17.2816 4.6894 17.2145 5.09814C17.1474 5.50689 17.4244 5.89262 17.8331 5.9597C18.8415 6.12518 19.6102 7.00069 19.6106 8.0548C19.608 9.12474 18.8147 10.0091 17.7842 10.1539C17.374 10.2115 17.0882 10.5908 17.1459 11.0009C17.2035 11.4111 17.5827 11.6969 17.9929 11.6393C19.7522 11.3921 21.1068 9.88487 21.1106 8.05715L21.1106 8.0556C21.1106 6.25406 19.7974 4.76197 18.076 4.4795Z'
      fill='currentColor'
    />
    <path
      d='M19.8393 13.5086C19.4297 13.4473 19.0479 13.7298 18.9867 14.1394C18.9254 14.5491 19.2079 14.9308 19.6175 14.9921C20.2527 15.0871 20.7001 15.2351 20.9707 15.4126C21.2037 15.5654 21.2724 15.7097 21.2724 15.9003C21.2724 16.0527 21.2284 16.1529 21.1487 16.243C21.0552 16.3489 20.8841 16.4696 20.593 16.5806C20.206 16.7283 20.012 17.1618 20.1597 17.5487C20.3074 17.9357 20.7408 18.1297 21.1278 17.9821C21.5548 17.8191 21.9647 17.5848 22.2726 17.2364C22.5944 16.8723 22.7724 16.419 22.7724 15.9003C22.7724 15.116 22.3697 14.5363 21.7934 14.1583C21.2548 13.8051 20.5552 13.6156 19.8393 13.5086Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.46333 14.4394C8.67209 14.034 10.2635 13.9138 11.8866 13.9138C13.5191 13.9138 15.1112 14.0381 16.3185 14.4485C16.9261 14.655 17.4911 14.9518 17.9106 15.3914C18.3474 15.8492 18.5946 16.4299 18.5946 17.1128C18.5946 17.7973 18.3451 18.3779 17.9055 18.8338C17.4837 19.2711 16.9167 19.5645 16.3087 19.7679C15.1002 20.172 13.5091 20.2908 11.8866 20.2908C10.2541 20.2908 8.66197 20.1671 7.45456 19.7576C6.84694 19.5515 6.28187 19.2553 5.86215 18.8162C5.42505 18.359 5.17755 17.7785 5.17755 17.0958C5.17755 16.4114 5.42719 15.8309 5.86662 15.3749C6.28824 14.9373 6.85517 14.6433 7.46333 14.4394ZM6.94674 16.4157C6.77104 16.598 6.67755 16.8077 6.67755 17.0958C6.67755 17.3851 6.77106 17.5963 6.94642 17.7797C7.13917 17.9813 7.45804 18.1749 7.9363 18.337C8.90063 18.6641 10.288 18.7908 11.8866 18.7908C13.478 18.7908 14.8659 18.6687 15.8329 18.3453C16.3127 18.1849 16.6325 17.9929 16.8257 17.7925C17.001 17.6107 17.0946 17.4013 17.0946 17.1128C17.0946 16.8227 17.0009 16.6109 16.8254 16.427C16.6326 16.225 16.3138 16.0311 15.8358 15.8687C14.8719 15.541 13.485 15.4138 11.8866 15.4138C10.2956 15.4138 8.90751 15.5371 7.94027 15.8615C7.46043 16.0224 7.14024 16.2149 6.94674 16.4157Z'
      fill='currentColor'
    />
    <path
      d='M6.55803 5.09814C6.6251 5.50689 6.34812 5.89262 5.93938 5.9597C4.93096 6.12518 4.1623 7.0007 4.16193 8.05482C4.16452 9.12498 4.95702 10.0091 5.9882 10.1539C6.39839 10.2115 6.68423 10.5907 6.62664 11.0009C6.56905 11.4111 6.18984 11.6969 5.77965 11.6393C4.01908 11.3921 2.66571 9.88465 2.66193 8.05715L2.66193 8.0556C2.66193 6.25406 3.97514 4.76197 5.69647 4.4795C6.10522 4.41242 6.49095 4.6894 6.55803 5.09814Z'
      fill='currentColor'
    />
    <path
      d='M4.15472 14.9921C4.56438 14.9308 4.84682 14.5491 4.78557 14.1394C4.72432 13.7298 4.34257 13.4473 3.93291 13.5086C3.21705 13.6156 2.51745 13.8051 1.97887 14.1583C1.40257 14.5363 0.999817 15.116 0.999817 15.9003C0.999817 16.419 1.1778 16.8723 1.49961 17.2364C1.80754 17.5848 2.21749 17.8191 2.64441 17.9821C3.03141 18.1297 3.46485 17.9357 3.61253 17.5487C3.76021 17.1618 3.56621 16.7283 3.17922 16.5806C2.88814 16.4696 2.71709 16.3489 2.62352 16.243C2.54383 16.1529 2.49982 16.0527 2.49982 15.9003C2.49982 15.7097 2.56856 15.5654 2.80151 15.4126C3.07218 15.2351 3.51958 15.0871 4.15472 14.9921Z'
      fill='currentColor'
    />
  </svg>
);

export { ThreeUser };

import { useCallback, useMemo } from 'react';

import * as d3 from 'd3';

import { LineChartData, LineChartDatum, LineChartMargins } from '../LineChart.types';

type Props = {
  overlayData?: LineChartData[];
  costBasis?: LineChartData;
  smoothing?: boolean;
  chartData: LineChartData;
  width: number;
  height: number;
  margins: LineChartMargins;
  removeYAxisPadding?: boolean;
  yAxisPaddingFactor: number;
  loading?: boolean;
  areaColor?: string;
};

const useCalculationUtils = ({
  overlayData = [],
  costBasis = [],
  smoothing = false,
  removeYAxisPadding = false,
  chartData,
  width,
  height,
  margins,
  yAxisPaddingFactor,
  loading,
  areaColor,
}: Props) => {
  // quick data mapper
  const getX = useCallback((d: LineChartDatum) => d.time, []);
  const getY = useCallback((d: LineChartDatum) => d.value, []);

  const { minY, maxY }: { minY: number; maxY: number } =
    overlayData?.length || costBasis?.length
      ? [...overlayData, chartData, costBasis].reduce<{ minY: number; maxY: number }>(
          (acc, data) => {
            const dataMinY = d3.min(data, getY);
            const dataMaxY = d3.max(data, getY);
            if (dataMinY !== undefined && (dataMinY < acc.minY || acc.minY === undefined)) {
              acc.minY = dataMinY;
            }
            if (dataMaxY !== undefined && (dataMaxY > acc.maxY || acc.maxY === undefined)) {
              acc.maxY = dataMaxY;
            }
            return acc;
          },
          {
            minY: d3.min([...costBasis, ...chartData], getY) as number,
            maxY: d3.max([...costBasis, ...chartData], getY) as number,
          },
        )
      : {
          minY: d3.min([...chartData], getY) as number,
          maxY: d3.max([...chartData], getY) as number,
        };

  // Adds a bit of room below the minimum Y value on the chart
  const add10PercentToBottomOfMinY = (calculatedMinY: number) => {
    const minYPlus10Percent = calculatedMinY - calculatedMinY / 20;
    if (minYPlus10Percent > 0) {
      return minYPlus10Percent;
    }
    return 0;
  };

  // set the x range to 0 - width and the domain from the min to the max
  const x = useMemo(
    () =>
      d3
        .scaleTime()
        .range([margins.marginLeft, width - margins.marginRight])
        .domain([d3.min(chartData, getX) as Date, d3.max(chartData, getX) as Date]),
    [chartData, margins.marginLeft, margins.marginRight, width],
  );

  // set the y range to height - 0 and the domain from the min to the max
  const y = useMemo(
    () =>
      d3
        .scaleLinear()
        .range([height - margins.marginBottom, margins.marginTop])
        // * small increase to add a little padding and remove a cut-off bug
        .domain([
          removeYAxisPadding
            ? add10PercentToBottomOfMinY(minY)
            : add10PercentToBottomOfMinY(minY) * (1 - (yAxisPaddingFactor - 1)),
          removeYAxisPadding ? maxY : maxY * yAxisPaddingFactor,
        ])
        .nice(),
    [height, margins.marginBottom, margins.marginTop, maxY, minY, removeYAxisPadding, yAxisPaddingFactor],
  );

  // d3 doing the thing to make the area calculations from our data
  const valueArea = useMemo(
    () =>
      d3
        .area<LineChartDatum>()
        .x((d) => x(getX(d)))
        .y0(height)
        .y1((d) => y(getY(d))),
    [getX, getY, height, x, y],
  );

  // separate line and area calculations because we want to add a stroke to the line data but not the whole area
  const valueLine = useMemo(
    () =>
      d3
        .line<LineChartDatum>()
        .x((d) => x(getX(d)))
        .y((d) => y(getY(d))),
    [getX, getY, x, y],
  );

  if (smoothing) {
    valueLine.curve(d3.curveBasis);
    valueArea.curve(d3.curveBasis);
  }

  const color = useMemo(() => {
    if (loading || areaColor === 'neutral') return 'var(--color-border-main)';

    switch (areaColor) {
      case 'destructive':
        return 'var(--color-background-error)';
      case 'success':
        return 'var(--color-background-success)';
      case 'primary':
        return 'var(--color-background-info)';
      default:
        return areaColor || 'var(--color-background-info-subtle)';
    }
  }, [loading, areaColor]);

  return {
    x,
    y,
    getX,
    getY,
    valueArea,
    valueLine,
    color,
  };
};

export { useCalculationUtils };

import { useMemo } from 'react';

import { Dashboard, TrackEverything, TrackMarket, TrackPortfolio } from '@swyftx/aviary/icons/outlined';

import { UserStore } from '@shared/store';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AnnouncementsWidget } from 'src/lib/announcements/widgets';
import { MarketActivityWidget } from 'src/lib/markets/widgets';
import { NewsCardWidget } from 'src/lib/news/widgets';
import { defaultTransactionsTabs } from 'src/lib/transactions/types/Transactions.types';
import { TransactionsWidget } from 'src/lib/transactions/widgets/TransactionsWidget/TransactionsWidget';
import { GettingStartedWidget } from 'src/lib/verification/widgets';

import { PortfolioAssetAllocationWidget } from '../../portfolio/widgets';
import { MarketsActivityRow } from '../layouts/Markets';
import { PortfolioHeroRow } from '../layouts/Portfolio/PortfolioHeroRow';
import { DashboardLayoutItem } from '../types/Dashboard.types';

const useBuildDashboardLayouts = () => {
  const { userProfile, isEntity } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const hideClassic = useMemo(() => {
    if (isFeatureEnabled(AppFeature.ForceClassicLayoutVisible)) return false;

    return userProfile?.userSettings.dashboardSettings?.tiles === undefined;
  }, [isFeatureEnabled, userProfile?.userSettings.dashboardSettings?.tiles]);

  const layouts = useMemo(
    (): DashboardLayoutItem[] => [
      {
        key: 'classic',
        icon: <Dashboard className='h-24 w-24 text-color-text-info' />,
        image: 'layout_thumb_classic',
        imageSm: 'layout_thumb_classic_sm',
        hidden: hideClassic,
        title: 'Classic layout',
        description:
          'Our classic dashboard, enjoy the flexibility of full customisation. Hide, show, and resize content to best represent your ideal dashboard layout.',
        widgetRows: [],
      },
      {
        key: 'everything',
        icon: <TrackEverything className='h-24 w-24 text-color-text-info' />,
        image: 'layout_thumb_everything',
        imageSm: 'layout_thumb_everything_sm',

        hidden: false,
        title: 'Track everything',
        description:
          'Track and monitor everything from your Dashboard. Includes a detailed portfolio breakdown, open orders, market overview and all the latest news.',
        widgetRows: [
          {
            widgets: [<PortfolioHeroRow key='portfolio-hero' />],
          },
          {
            widgets: isEntity() ? [] : [<GettingStartedWidget key='GettingStartedWidget' />],
          },
          {
            widgets: [<AnnouncementsWidget key='announcements' />],
          },
          {
            widgets: [<PortfolioAssetAllocationWidget key='PortfolioAssetAllocationWidget' />],
          },
          {
            widgets: [
              <TransactionsWidget key='TransactionsWidget' title='Your transactions' tabs={defaultTransactionsTabs} />,
            ],
          },
          {
            widgets: [<MarketsActivityRow key='MarketsActivityRow' />],
          },
          {
            widgets: [<NewsCardWidget key='news' widgetId='latest-news' />],
          },
        ],
      },
      {
        key: 'portfolio',
        icon: <TrackPortfolio className='h-24 w-24 text-color-text-info' />,
        image: 'layout_thumb_portfolio',
        imageSm: 'layout_thumb_portfolio_sm',
        hidden: false,
        title: 'Track your portfolio',
        description:
          'A detailed breakdown of your portfolio and individual wallet performance with a basic overview of the market.',
        widgetRows: [
          {
            widgets: [<PortfolioHeroRow key='portfolio-hero' />],
          },
          {
            widgets: isEntity() ? [] : [<GettingStartedWidget key='GettingStartedWidget' />],
          },
          {
            widgets: [<AnnouncementsWidget key='announcements' />],
          },
          {
            widgets: [<PortfolioAssetAllocationWidget key='portfolio-allocation' />],
          },
          {
            widgets: [
              <TransactionsWidget key='transactions' title='Your transactions' tabs={defaultTransactionsTabs} />,
            ],
          },
          {
            widgets: [<MarketActivityWidget key='market-activity' />],
          },
          {
            widgets: [<NewsCardWidget key='news' widgetId='latest-news' />],
          },
        ],
      },
      {
        key: 'markets',
        icon: <TrackMarket className='h-24 w-24 text-color-text-info' />,
        image: 'layout_thumb_markets',
        imageSm: 'layout_thumb_markets_sm',
        hidden: false,
        title: 'Track the market',
        description:
          'An in-depth overview of what is happening across the market. Get detailed asset performance and news.',
        widgetRows: [
          {
            widgets: [<PortfolioHeroRow key='portfolio-row' layoutKey='markets' />],
          },
          {
            widgets: isEntity() ? [] : [<GettingStartedWidget key='GettingStartedWidget' />],
          },
          {
            widgets: [<AnnouncementsWidget key='announcements' />],
          },
          {
            widgets: [<MarketsActivityRow key='MarketsActivityRow' />],
          },
          {
            widgets: [<NewsCardWidget key='news' widgetId='latest-news' />],
          },
        ],
      },
    ],
    [hideClassic, isEntity],
  );

  return { layouts };
};

export { useBuildDashboardLayouts };

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Stack } from '@swyftx/react-web-design-system';

import { UniversalTradeStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

import { HighSlippageMultiReviewContent } from './HighSlippageMultiReviewContent';
import { HighSlippageReviewContent } from './HighSlippageReviewContent';

interface Props {
  onClose: () => void;
  onContinue: () => void;
  showActions?: boolean;
}

const HighSlippageReviewModal: React.FC<Props> = observer(({ onClose, onContinue, showActions = true }) => {
  const { highSlippageTrades } = useUniversalTradeUtilityStore();
  const { tradeKeys } = UniversalTradeStore;
  const { t } = useTranslation('trade', { keyPrefix: 'highSlippageModal' });
  const { isMediumScreen } = useScreenBreakpoints();

  const hasMultipleTrades = tradeKeys.length > 1;

  const handleReviewConfirmation = useCallback(() => {
    onContinue();
  }, [onContinue]);

  if (highSlippageTrades.length === 0) return null;

  return (
    <Modal
      id='high-slippage-review-modal'
      open
      variant='BottomSheet'
      sx={{
        width: hasMultipleTrades ? '900px' : '400px',
        padding: 0,
        '.MuiBox-root': {
          padding: 0,
        },
        '.MuiCardContent-root': { height: '100%' },
        '.MuiCardContent-root > .MuiBox-root': { height: '100%' },
        '.MuiCardContent-root > .MuiBox-root ~ .MuiBox-root': { height: 'auto' },
      }}
      onClose={onClose}
      HeaderProps={{ title: t('title'), dismissible: true, divider: true }}
      FooterProps={{
        content: showActions ? (
          <Stack
            width='100%'
            direction={hasMultipleTrades ? 'row' : 'column'}
            alignItems='center'
            color='text.secondary'
            justifyContent={hasMultipleTrades ? 'flex-end' : 'space-between'}
            padding={2}
            spacing={hasMultipleTrades ? 2 : 0}
          >
            <Button
              variant='text'
              color='inherit'
              size='large'
              fullWidth={!hasMultipleTrades}
              onClick={onClose}
              disableElevation
              sx={{
                order: hasMultipleTrades ? undefined : 2,
                marginTop: hasMultipleTrades ? 0 : 2,
                height: '48px',
              }}
            >
              {t(isMediumScreen ? 'editOrderAmount' : 'editAmounts')}
            </Button>
            <Button
              sx={{
                order: hasMultipleTrades ? undefined : 1,
                marginTop: 0,
                height: '48px',
              }}
              fullWidth={!hasMultipleTrades}
              variant='contained'
              color='primary'
              size='large'
              onClick={handleReviewConfirmation}
              disableElevation
            >
              {t(isMediumScreen ? 'continueAndReview' : 'continue')}
            </Button>
          </Stack>
        ) : undefined,
        divider: true,
      }}
    >
      {hasMultipleTrades ? <HighSlippageMultiReviewContent /> : <HighSlippageReviewContent />}
    </Modal>
  );
});

export { HighSlippageReviewModal };

import React from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';
import { getGlobalContext } from './context/global.context';
// TODO: readd service worker; redirect to native if possible
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

(async () => {
  const GlobalContext = await getGlobalContext();

  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <GlobalContext>
        <App />
      </GlobalContext>
    </React.StrictMode>,
  );
})();

serviceWorkerRegistration.unregister();

import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { UserStore } from '@shared/store';

import { Command } from 'cmdk';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';

import { GlobalSearchEmpty, GlobalSearchInput } from './components';
import { useGlobalSearchAnalytics } from './hooks/useGlobalSearchAnalytics';
import { useParseSearchMetadata } from './hooks/useParseSearchMetadata';
import {
  SuggestionsModuleSearchCategory,
  ActionsModuleSearchCategory,
  SettingsModuleSearchCategory,
  AssetsModuleSearchCategory,
  PagesModuleSearchCategory,
} from './modules';

type Props = {
  defaultOpen?: boolean;
  onClose?: () => void;
};

const GlobalSearch: React.FC<Props> = observer(({ defaultOpen = false, onClose }) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const { assets } = useParseSearchMetadata({ searchValue: debouncedSearch });
  const isXs = useTailwindBreakpoint('xs');
  const { isLoggedIn } = UserStore.useUserStore;
  const { trackGlobalSearchBarInputFieldCompleted } = useGlobalSearchAnalytics();

  useDebounce(() => setDebouncedSearch(searchValue), 300, [searchValue]);

  // Only track the search bar input if no value has been entered for 1s
  useDebounce(
    () => {
      if (searchValue) trackGlobalSearchBarInputFieldCompleted(searchValue);
    },
    1000,
    [searchValue],
  );

  const handleOnOpenChange = useCallback(
    (val?: boolean) => {
      setOpen(val || false);
      if (!val && onClose) onClose();
    },
    [onClose],
  );

  useEffect(() => {
    setSearchValue('');
  }, [open]);

  if (!isLoggedIn) return null;

  return (
    <Modal
      open={open}
      onClose={() => handleOnOpenChange(false)}
      onOpenChange={handleOnOpenChange}
      position={isXs ? 'bottom' : 'top'}
      className='max-h-[92vh] min-h-[92vh] sm:!max-h-[80vh] sm:min-h-0 sm:pb-8'
    >
      <Command loop shouldFilter={false}>
        <div className='p-16'>
          <GlobalSearchInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleOnOpenChange={handleOnOpenChange}
          />
        </div>
        <Command.List className='mt-8 max-h-[82vh] sm:max-h-[60vh]'>
          <GlobalSearchEmpty />
          <SuggestionsModuleSearchCategory searchValue={debouncedSearch} />
          <SettingsModuleSearchCategory searchValue={debouncedSearch} />
          <ActionsModuleSearchCategory searchValue={debouncedSearch} assets={assets} />
          <PagesModuleSearchCategory searchValue={debouncedSearch} assets={assets} />
          <AssetsModuleSearchCategory searchValue={debouncedSearch} />
        </Command.List>
      </Command>
    </Modal>
  );
});

export { GlobalSearch };

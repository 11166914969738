import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { DEFAULT_PRICE_SCALE } from '@shared/constants';
import { FiatIdEnum, LowLiquidityMax } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';

import { ErrorLinkTypeValueType } from 'src/context/Avo/generated-avo';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

import { useQuickBuyAmounts } from './useQuickBuyAmounts';
import { useQuickBuyAssets } from './useQuickBuyAssets';
import { QuickBuyContext } from '../../context';

const useQuickBuyError = () => {
  const { buyType, quoteError, errorMessage } = useContext(QuickBuyContext);
  const { getMinimumOrderAmount, getRate, convertRate } = RatesStore.useRatesStore;
  const { selectedAsset, countryAsset, limitAsset } = useQuickBuyAssets();
  const { amount, setAmount, nonLimitAmount } = useQuickBuyAmounts();
  const { getBalance } = usePortfolioBalance();
  const { openModal } = useModal();
  const avo = useAvo();
  const location = useLocation();

  const openDepositModal = useCallback(
    (errorLinkType: ErrorLinkTypeValueType) => {
      avo.quickBuyErrorTextTapped({ errorLinkType, screen: location.pathname });

      if (buyType === 'buy') {
        openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
      } else {
        openModal(Modals.DepositReceive, { selectedAsset });
      }
    },
    [avo, buyType, countryAsset, location.pathname, openModal, selectedAsset],
  );

  const onSetAmountFromError = useCallback(
    (newAmount: string, errorLinkType: ErrorLinkTypeValueType, forceSet?: boolean) => {
      avo.quickBuyErrorTextTapped({ errorLinkType, screen: location.pathname });
      setAmount(newAmount, forceSet);
    },
    [avo, location.pathname, setAmount],
  );

  const balanceAsset = useMemo(
    () => (buyType === 'buy' ? countryAsset : selectedAsset),
    [buyType, countryAsset, selectedAsset],
  );

  const relativeAmount = useMemo(() => {
    if (!limitAsset || !countryAsset || !selectedAsset || !amount) return '0';

    if (buyType === 'buy') {
      if (limitAsset.id === countryAsset.id) {
        return amount;
      } else {
        return nonLimitAmount;
      }
    } else {
      if (limitAsset.id === selectedAsset.id) {
        return amount;
      } else {
        return nonLimitAmount;
      }
    }
  }, [amount, buyType, countryAsset, limitAsset, nonLimitAmount, selectedAsset]);

  const processMaximumAmountError = useCallback(() => {
    if (!selectedAsset || !countryAsset || !quoteError || !amount) return '';

    if (quoteError.maximumPercent && !Big(quoteError.maximumPercent).eq(0)) {
      const maxAmount = Big(amount).times(Big(1).minus(quoteError.maximumPercent));
      const formattedMax = formatCurrency(
        maxAmount,
        limitAsset,
        {
          appendCode: false,
          hideCode: true,
          hideSymbol: true,
          hideSeparator: true,
        },
        undefined,
        true,
      );

      return (
        <>
          Amount exceeds maximum{' '}
          <span
            className='cursor-pointer underline'
            onClick={() => onSetAmountFromError(formattedMax, 'moreThanMax', limitAsset?.id === countryAsset.id)}
          >
            {formatCurrency(maxAmount, limitAsset, undefined, undefined, true)}
          </span>{' '}
          order limit.
        </>
      );
    }

    return '';
  }, [amount, countryAsset, limitAsset, onSetAmountFromError, quoteError, selectedAsset]);

  const processGenericErrorMessage = useCallback(() => {
    if (!errorMessage) return '';

    return errorMessage;
  }, [errorMessage]);

  const processLowLiquidity = useCallback(() => {
    if (!selectedAsset || !countryAsset) return '';

    const { buyLiquidityFlag, sellLiquidityFlag } = getRate(selectedAsset);
    const maxLowLiqudityBuy = convertRate(FiatIdEnum.AUD, countryAsset.id, LowLiquidityMax.AUD, 'askPrice');

    if (buyType === 'buy' && buyLiquidityFlag) {
      if (Big(relativeAmount).gt(maxLowLiqudityBuy)) {
        const maxLiquidity = formatCurrency(
          maxLowLiqudityBuy,
          countryAsset,
          {
            hideCode: true,
            appendCode: false,
            hideSeparator: true,
            hideSymbol: true,
          },
          undefined,
          true,
        );
        return (
          <>
            Asset impacted by low liquidity. Enter amount less than{' '}
            <span
              className='cursor-pointer underline'
              onClick={() => onSetAmountFromError(maxLiquidity, 'moreThanMax')}
            >
              {formatCurrency(maxLowLiqudityBuy, countryAsset, undefined, undefined, true)}
            </span>
          </>
        );
      }
    } else if (buyType === 'sell' && sellLiquidityFlag) {
      const maxLowLiquditySell = convertRate(FiatIdEnum.AUD, selectedAsset.id, LowLiquidityMax.AUD, 'bidPrice');
      const maxLiquidity = formatCurrency(
        maxLowLiquditySell,
        selectedAsset,
        {
          hideCode: true,
          priceScale: selectedAsset.price_scale ?? DEFAULT_PRICE_SCALE,
          appendCode: false,
          hideSeparator: true,
          hideSymbol: true,
        },
        undefined,
        true,
      );

      if (Big(relativeAmount).gt(maxLowLiquditySell)) {
        return (
          <>
            Asset impacted by low liquidity. Enter amount less than{' '}
            <span
              className='cursor-pointer underline'
              onClick={() => onSetAmountFromError(maxLiquidity, 'moreThanMax')}
            >
              {formatCurrency(
                maxLowLiquditySell,
                selectedAsset,
                {
                  priceScale: selectedAsset.price_scale ?? DEFAULT_PRICE_SCALE,
                },
                undefined,
                true,
              )}
            </span>
          </>
        );
      }
    }
  }, [selectedAsset, countryAsset, getRate, convertRate, buyType, relativeAmount, onSetAmountFromError]);

  const processBelowMinimum = useCallback(() => {
    if (!limitAsset) return '';

    const minimumAmount = getMinimumOrderAmount(limitAsset, buyType === 'buy' ? 'askPrice' : 'bidPrice').round(
      limitAsset.price_scale ?? DEFAULT_PRICE_SCALE,
    );

    if (minimumAmount.gt(amount)) {
      const minimum = formatCurrency(
        minimumAmount,
        limitAsset,
        {
          hideCode: true,
          appendCode: false,
          hideSeparator: true,
          hideSymbol: true,
        },
        undefined,
        true,
      );

      return (
        <>
          Amount is below minimum{' '}
          <span className='cursor-pointer underline' onClick={() => onSetAmountFromError(minimum, 'lessThanMin', true)}>
            {formatCurrency(minimumAmount, limitAsset, undefined, undefined, true)}
          </span>{' '}
          order limit.
        </>
      );
    }

    return '';
  }, [limitAsset, getMinimumOrderAmount, buyType, amount, onSetAmountFromError]);

  const processInsufficientFunds = useCallback(() => {
    if (!balanceAsset) return '';

    const balance = getBalance(balanceAsset.id)?.availableBalance || '0';

    if (Big(relativeAmount).gt(balance)) {
      if (buyType === 'buy') {
        return (
          <>
            Insufficient funds. Reduce order amount or{' '}
            <span className='cursor-pointer underline' onClick={() => openDepositModal('insufficientFunds')}>
              deposit {balanceAsset.code}.
            </span>
          </>
        );
      } else if (buyType === 'sell') {
        const maxBalance = formatCurrency(
          balance,
          balanceAsset,
          {
            hideCode: true,
            appendCode: false,
            hideSeparator: true,
            hideSymbol: true,
          },
          undefined,
          true,
        );

        return (
          <>
            Insufficient funds. Reduce order amount to{' '}
            <span
              className='cursor-pointer underline'
              onClick={() => onSetAmountFromError(maxBalance, 'insufficientFunds')}
            >
              {formatCurrency(balance, balanceAsset, undefined, undefined, true)}
            </span>
          </>
        );
      }
    }

    return '';
  }, [balanceAsset, buyType, getBalance, onSetAmountFromError, openDepositModal, relativeAmount]);

  const getTradeError = useCallback(() => {
    if (!amount || Big(amount).eq(0)) return '';

    return (
      processBelowMinimum() ||
      processLowLiquidity() ||
      processMaximumAmountError() ||
      processGenericErrorMessage() ||
      processInsufficientFunds() ||
      ''
    );
  }, [
    amount,
    processBelowMinimum,
    processGenericErrorMessage,
    processInsufficientFunds,
    processLowLiquidity,
    processMaximumAmountError,
  ]);

  const isValidTrade = useMemo(() => {
    if (!limitAsset || !selectedAsset || !countryAsset) return false;
    if (buyType === 'buy' && selectedAsset.buyDisabled) return false;
    if (buyType === 'sell' && !selectedAsset.sellEnabled) return false;
    if (getTradeError()) return false;

    return true;
  }, [buyType, countryAsset, getTradeError, limitAsset, selectedAsset]);

  return {
    getTradeError,
    isValidTrade,
  };
};

export { useQuickBuyError };

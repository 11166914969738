import { AccountPurpose, SourceOfWealth } from '@shared/enums';

import { AddressBrokenUp } from './user';

export type GreenIdData = AddressBrokenUp & {
  accountId: string;
  apiCode: string;
  givenNames: string;
  middleNames: string;
  surname: string;
  ruleId: string;
  email: string;
  citizenship?: string;
  purposeOfAccount: AccountPurpose;
  sourceOfWealth: SourceOfWealth;
  citizenshipDual?: string;
  dob: {
    day: number;
    month: number;
    year: number;
  };
};

export enum IdentityVerificationStatus {
  MATCH = 'MATCH',
  NO_MATCH = 'NO_MATCH',
  NOT_POSSIBLE = 'NOT_POSSIBLE',
}

export enum IdentityVerificationError {
  SELFIE_CROPPED_FROM_ID = 'SELFIE_CROPPED_FROM_ID',
  ENTIRE_ID_USED_AS_SELFIE = 'ENTIRE_ID_USED_AS_SELFIE',
  MULTIPLE_PEOPLE = 'MULTIPLE_PEOPLE',
  SELFIE_IS_SCREEN_PAPER_VIDEO = 'SELFIE_IS_SCREEN_PAPER_VIDEO',
  SELFIE_MANIPULATED = 'SELFIE_MANIPULATED',
  AGE_DIFFERENCE_TOO_BIG = 'AGE_DIFFERENCE_TOO_BIG',
  NO_FACE_PRESENT = 'NO_FACE_PRESENT',
  FACE_NOT_FULLY_VISIBLE = 'FACE_NOT_FULLY_VISIBLE',
  BAD_QUALITY = 'BAD_QUALITY',
  BLACK_AND_WHITE = 'BLACK_AND_WHITE',
  LIVENESS_FAILED = 'LIVENESS_FAILED',
}

export type Identity = {
  verification: {
    status?: IdentityVerificationStatus;
    rejection: {
      reason?: IdentityVerificationError;
    };
  };
};

export interface JumioTransactionDetails {
  transactionReference: string;
  document: JumioDocument;
  identity: Identity;
}

export type JumioInformation = {
  firstName: string;
  lastName: string;
};

export enum DocumentVerificationStatus {
  APPROVED_VERIFIED = 'APPROVED_VERIFIED',
  DENIED_FRAUD = 'DENIED_FRAUD',
  DENIED_UNSUPPORTED_ID_TYPE = 'DENIED_UNSUPPORTED_ID_TYPE',
  DENIED_UNSUPPORTED_ID_COUNTRY = 'DENIED_UNSUPPORTED_ID_COUNTRY',
  ERROR_NOT_READABLE_ID = 'ERROR_NOT_READABLE_ID',
  NO_ID_UPLOADED = 'NO_ID_UPLOADED',
}

export type JumioDocument = {
  information?: JumioInformation;
  detailsMatchUser?: number | null;
  verification: {
    status?: DocumentVerificationStatus;
    // These details should not be needed on the react frontend and hence will not include the types
    // rejection: {
    //   reason?: DocumentRejectionReason;
    //   details?: DocumentRejectionReasonDetails;
    // };
  };
};

export enum VerificationStatus {
  PASSED = 'PASSED',
  DENIED_SUSPICIOUS = 'DENIED_SUSPICIOUS',
  NO_SIMILARITY = 'NO_SIMILARITY',
  DETAILS_DID_NOT_MATCH = 'DETAILS_DID_NOT_MATCH',
  DENIED_RETRY_MAXIMUM_REACHED = 'DENIED_RETRY_MAXIMUM_REACHED',
  DENIED_RETRY_ALLOWED = 'DENIED_RETRY_ALLOWED',
}

export interface TransactionResult {
  status: VerificationStatus;
  message: {
    header: string;
    subtext: string;
  };
  attempts?: JumioTransactionDetails[];
}

export enum TransactionStatus {
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED', // This is only for the frontend that has been added to manually pass.
}

export type ReturnedTransaction = ReturnedTransactionStatus & {
  redirectUrl: string;
  status: TransactionStatus;
  transactionReference: string;
};

export interface ReturnedTransactionStatus {
  status: TransactionStatus;
  result?: TransactionResult; // Only included if status === TransactionStatus.DONE
}

export interface EmailStatus {
  success: boolean;
  emailVerified: boolean;
  msg: string;
}

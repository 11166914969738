import React, { useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Radio } from '@swyftx/aviary/atoms/Radio';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Dollar, Clock, Information } from '@swyftx/aviary/icons/outlined';

import { AssetTransactionHistoryModal } from '@global-components/Modals/AssetTransactionHistoryModal';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';
import { StorageKey } from '@shared/storage';

import { CardDepositProvider, useCardDepositProvider } from '@hooks/Deposit/useCardDepositProvider';
import { getDepositMethods } from '@routes/Wallet/subroutes/SingleWallet/getDepositMethods';
import { TransactionTypeFilter } from '@services/WalletService';

import { observer } from 'mobx-react-lite';

import { PromptLineItem } from './PromptLineItem';
import { DepositMethodItem } from '../../types';

type Props = {
  asset: Asset;
};

const SingleWalletFiatDepositSelector: React.FC<Props> = observer(({ asset }) => {
  const [hasReadDeposit, setHasReadDeposit] = useState<boolean>(
    Boolean(JSON.parse(localStorage.getItem(StorageKey.DEPOSIT_LANDING_READ) || 'false')),
  );
  const [doNotShowPromptAgain, setDoNotShowPromptAgain] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<DepositMethodEnum | undefined>(undefined);
  const [showDepositHistory, setShowDepositHistory] = useState(false);
  const { t } = useTranslation('wallet', { keyPrefix: 'singleWalletFiatDeposit.selector' });
  const isNzd = asset.code === 'NZD';
  const cardDepositProvider = useCardDepositProvider();
  const { openModal } = useModal();

  const navigateToSelctor = () => {
    if (doNotShowPromptAgain) localStorage.setItem(StorageKey.DEPOSIT_LANDING_READ, 'true');
    setHasReadDeposit(true);
  };

  const depositMethods = useCallback(() => getDepositMethods(asset.code), [asset.code]);

  const renderPrompt = () => (
    <FlexLayout direction='column' spacing={16}>
      <Body weight='bold' size='large'>
        {t('depositing')}
      </Body>
      <Body color='secondary'>{t('description')}</Body>
      <PromptLineItem
        title={t('accountLimits.title')}
        subtitle={
          isNzd ? (
            <Trans
              t={t}
              i18nKey='accountLimits.subtitleNZD'
              components={{
                bold: <Body className='inline' size='small' color='secondary' weight='bold' />,
              }}
            />
          ) : (
            t('accountLimits.subtitleAUD')
          )
        }
        icon={<Dollar />}
      />
      <PromptLineItem
        title={t('dailyLimits.title')}
        subtitle={isNzd ? t('dailyLimits.subTitle.nzd') : t('dailyLimits.subTitle.aud')}
        icon={<Clock />}
        subtitleInline={t('dailyLimits.contactUs')}
      />
      <PromptLineItem title={t('depositOrigin.title')} subtitle={t('depositOrigin.subTitle')} icon={<Information />} />
      <FlexLayout className='mb-8' direction='row' spacing={8}>
        <Checkbox checked={doNotShowPromptAgain} onCheckedChange={(checked) => setDoNotShowPromptAgain(!!checked)} />
        <Body>{t('buttonLabels.dontShow')}</Body>
      </FlexLayout>

      <Button size='lg' onClick={navigateToSelctor} className='w-full'>
        {t('buttonLabels.depositFunds')}
      </Button>
    </FlexLayout>
  );

  const getChipLabel = (method: DepositMethodItem) => {
    if (method.disabled) {
      return t('disabled');
    }

    if (cardDepositProvider === CardDepositProvider.Stripe && method.timeframeStripe) {
      return method.timeframeStripe;
    }

    return method.timeframe;
  };

  if (hasReadDeposit === false) {
    return renderPrompt();
  }

  return (
    <>
      <FlexLayout direction='column' spacing={16}>
        <Body weight='bold' size='large'>
          {t('selectMethod')}
        </Body>
        {depositMethods().map((method) => (
          <Card
            key={`deposit-method-${method.method}`}
            onClick={() => !method.disabled && setSelectedMethod(method.method)}
            className='flex cursor-pointer border border-color-border-main p-16 hover:bg-color-background-surface-hover'
          >
            <FlexLayout
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={8}
              className='w-full'
            >
              <FlexLayout direction='column' spacing={8} className='w-full'>
                <FlexLayout className='flex-col items-start @lg:flex-row @lg:items-center' spacing={8}>
                  <Body weight='bold'>{method.title}</Body>

                  <Chip size='sm' variant='subtle'>
                    {getChipLabel(method)}
                  </Chip>
                </FlexLayout>
                <Body size='small' weight='emphasis'>
                  {method.depositLimit}
                </Body>
                <Body size='small'>
                  {cardDepositProvider === CardDepositProvider.Stripe
                    ? method.depositSummaryStripe
                    : method.depositSummary}
                </Body>
              </FlexLayout>
              <div>
                <Radio checked={selectedMethod === method.method} name='deposit-options' />
              </div>
            </FlexLayout>
          </Card>
        ))}
        <Button
          size='lg'
          disabled={selectedMethod === undefined}
          onClick={() => openModal(Modals.DepositReceive, { selectedAsset: asset, initialDepositType: selectedMethod })}
        >
          {t('buttonLabels.depositFunds')}
        </Button>
        <Button variant='ghost' color='primary' onClick={() => setShowDepositHistory(true)}>
          {t('buttonLabels.depositHistory')}
        </Button>
      </FlexLayout>

      {showDepositHistory && (
        <AssetTransactionHistoryModal
          asset={asset}
          type={TransactionTypeFilter.DEPOSIT}
          onClose={() => setShowDepositHistory(false)}
        />
      )}
    </>
  );
});

export { SingleWalletFiatDepositSelector };

import React from 'react';

import ChipMUI, { ChipProps } from '@mui/material/Chip';

const Chip: React.FC<ChipProps> = ({ id, children, ...props }) => (
  <ChipMUI id={id} {...props}>
    {children}
  </ChipMUI>
);

export { Chip };

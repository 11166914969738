import React, { useMemo } from 'react';

import Tooltip from '@mui/material/Tooltip';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Utility } from '@swyftx/aviary/atoms/Typography';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { Information } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { WalletMetricCardProps } from '@Wallet/components/WalletMetrics/types';

import { observer } from 'mobx-react-lite';

export const WalletMetricCard: React.FC<WalletMetricCardProps> = observer(
  ({ variant = 'standard', backgroundIcon, titleIcon, content, tooltip, title, size = 'standard' }) => {
    const { isLightMode } = useTailwindTheme();
    const isPrimary = variant === 'primary';
    const isLarge = size === 'large';

    const primaryTextColor = useMemo(() => {
      if (isPrimary) {
        if (!isLightMode) {
          return 'inverse';
        } else {
          return 'white';
        }
      }

      return 'primary';
    }, [isLightMode, isPrimary]);

    return (
      <Card className={cn(isPrimary ? 'bg-color-background-primary' : '', 'relative h-full w-full')}>
        <div
          className={cn(
            'absolute -translate-x-1/2 -translate-y-1/2 opacity-70',
            isLarge ? 'left-[15%] top-[65%]' : 'left-[90%] top-1/2',
          )}
        >
          {backgroundIcon}
        </div>
        <FlexLayout
          direction='column'
          justifyContent='space-around'
          className={cn(primaryTextColor, 'h-[90px] px-16 py-12')}
        >
          <FlexLayout direction='row' justifyContent='space-between' alignItems='center' spacing={8}>
            <FlexLayout direction='row' spacing={8} alignItems='center'>
              <Utility color={primaryTextColor}>{title}</Utility>
              {tooltip ? (
                <Tooltip title={tooltip}>
                  <Information color='primary' className='h-12 w-12 cursor-pointer' />
                </Tooltip>
              ) : null}
            </FlexLayout>
            {titleIcon}
          </FlexLayout>
          {content}
        </FlexLayout>
      </Card>
    );
  },
);

import { useCallback, useState } from 'react';

import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { PriceAlert } from '@shared/api/@types/alerts';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { OrderDate } from 'src/lib/UniversalTrade/components/OrdersTile/tableItems/OrderDate';

import { DeleteAlert } from './DeleteConfirmation';
import { PriceAlertPrice } from './PriceAlertPrice';
import { PriceAlertStatus } from '../../PriceAlerts.types';

export type PriceAlertsTableData = {
  price: string;
  createdAt: string;
  actions: string;
};

type PriceAlertsHeaderData = { [key in keyof PriceAlertsTableData]: EnhancedTableHeaderData };

export const usePriceAlertsTable = (alerts: PriceAlert[], tab: PriceAlertStatus, onDelete: (uuid: string) => void) => {
  const { getRate } = RatesStore.useRatesStore;
  const [sort, setSort] = useState<EnhancedTableSort<PriceAlertsTableData>>({
    sortKey: 'createdAt',
    sortDirection: 'DESC',
  });
  const isTriggeredTab = tab === PriceAlertStatus.TRIGGERED;

  const headers: PriceAlertsHeaderData = {
    price: {
      title: 'Price',
      alignment: 'start',
      sortable: false,
    },
    createdAt: {
      title: 'Created At',
      alignment: isTriggeredTab ? 'end' : 'start',
      sortable: false,
    },
    actions: {
      title: 'Actions',
      alignment: 'end',
      sortable: false,
      className: isTriggeredTab ? 'hidden' : '',
    },
  };

  const data: EnhancedTableData<PriceAlertsTableData>[] = alerts.map((alert) => {
    const asset = assetService.getAsset(alert.secondary)!;
    const rate = Big(getRate(asset.id).midPrice);
    return {
      price: {
        value: alert.price,
        element: <PriceAlertPrice isTriggeredTab={isTriggeredTab} alert={alert} rate={rate} />,
      },
      createdAt: {
        value: alert.dateUpdated,
        element: <OrderDate orderDate={alert.dateUpdated} size='small' alignment={isTriggeredTab ? 'end' : 'start'} />,
      },
      actions: {
        value: alert.priceAlertUuid,
        element: <DeleteAlert onDelete={() => onDelete(alert.priceAlertUuid)} />,
      },
    };
  });

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<PriceAlertsTableData>) => {
      if (!newSort) return;

      setSort(newSort);
    },
    [setSort],
  );

  return { headers, data, sort, onSort };
};

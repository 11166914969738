import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselUnstyledNext,
  CarouselUnstyledPrevious,
  CarouselApi,
} from '@swyftx/aviary/atoms/ShadcnCarousel';
import { Heading, Utility } from '@swyftx/aviary/atoms/Typography';
import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { cn } from '@shared/utils/lib/ui';

import * as braze from '@braze/web-sdk';
import { observer } from 'mobx-react-lite';
import { DashboardWidgetRowProps } from 'src/lib/dashboard/types';

import { useBuildAnnouncementCards, BrazeContentCardItem } from '../hooks/useBuildAnnouncementCards';

const AnnouncementsWidget: React.FC<DashboardWidgetRowProps> = observer(({ className }) => {
  const [api, setApi] = React.useState<CarouselApi>();
  const { items } = useBuildAnnouncementCards();
  const navigate = useNavigate();
  const { theme } = useTailwindTheme();

  const handleClick = (card: BrazeContentCardItem) => {
    if (!card.url) return;

    braze.logContentCardClick(card.card);

    if (card.url.startsWith('http')) {
      window.open(card.url, '_blank', 'noopener,noreferrer');
      return;
    }

    navigate(card.url);
  };

  const getCardImage = (item: BrazeContentCardItem) => {
    switch (theme) {
      case TailWindTheme.Midnight:
        return item.card.extras?.['midnight-image'] || item.card.extras?.['dark-image'] || item.imgSrc;
      case TailWindTheme.Light:
        return item.imgSrc;
      default:
        return item.card.extras?.['dark-image'] || item.imgSrc;
    }
  };

  const getCardImageBackground = (item: braze.CaptionedImage) => {
    if (theme === TailWindTheme.Light) {
      if (item.description.toLocaleLowerCase() === 'a new look for swyftx') {
        return 'bg-color-background-primary';
      }
    }

    return 'bg-color-background-primary-subtle';
  };

  const previousSlidesRef = React.useRef<number[]>([]);

  React.useEffect(() => {
    if (!api) return;

    const getVisibleSlides = () => {
      const slidesInView = api.slidesInView();
      const slidesNotInView = api.slidesNotInView();
      const allSeenSlides = [...new Set([...slidesInView, ...slidesNotInView])];
      const containerWidth = api.containerNode().offsetWidth;

      return allSeenSlides.filter((index) => {
        const slideElement = api.slideNodes()[index];
        const rect = slideElement.getBoundingClientRect();
        return rect.left < containerWidth && rect.right > 0;
      });
    };

    const initiallyVisibleSlides = getVisibleSlides();
    previousSlidesRef.current = initiallyVisibleSlides;
    braze.logContentCardImpressions(initiallyVisibleSlides.map((slide) => items[slide].card));

    const handleSlideChange = () => {
      setTimeout(() => {
        const currentSlides = getVisibleSlides();
        braze.logContentCardImpressions(currentSlides.slice(-1).map((slide) => items[slide].card));

        previousSlidesRef.current = currentSlides;
      }, 100);
    };

    api.on('select', handleSlideChange);
    return () => {
      api.off('select', handleSlideChange);
    };
  }, [api, items]);

  if (items.length <= 0) {
    return null;
  }

  return (
    <Carousel
      opts={{
        align: 'end',
      }}
      className='h-[9rem] w-full gap-12'
      setApi={setApi}
    >
      <FlexLayout alignItems='center' spacing={12} justifyContent='space-between' direction='row' className='pb-12'>
        <Heading size='h5' className='whitespace-nowrap'>
          Announcements
        </Heading>
        <FlexLayout alignItems='center' className='w-full' justifyContent='end' spacing={4}>
          <CarouselUnstyledPrevious />
          <CarouselUnstyledNext />
        </FlexLayout>
      </FlexLayout>

      <CarouselContent className='-ml-16'>
        {items.map((item) => {
          if (item.card instanceof braze.Banner) {
            return (
              <CarouselItem key={item.id} className='pl-16 @sm:basis-1/2 @md:basis-1/3 @lg:basis-1/4'>
                <img
                  src={getCardImage(item)}
                  className={cn('h-[7rem] w-full rounded-16 object-cover', { 'cursor-pointer': item.url })}
                  onClick={() => handleClick(item)}
                />
              </CarouselItem>
            );
          }

          return (
            <CarouselItem key={item.id} className='pl-16 @sm:basis-1/2 @md:basis-1/3 @lg:basis-1/4'>
              <Card
                className={cn(
                  `h-[7rem] justify-center p-8 hover:bg-color-background-surface-hover`,
                  { 'cursor-pointer': item.url },
                  className,
                )}
                onClick={() => handleClick(item)}
              >
                <FlexLayout spacing={16} alignItems='start'>
                  <Image
                    src={getCardImage(item)}
                    category='/announcements'
                    className={cn('h-96 w-96 rounded-[.75rem]', getCardImageBackground(item.card))}
                  />
                  <FlexLayout direction='column' className='self-center' spacing={4}>
                    <Utility variant='overline' color='accent'>
                      {item.card.title}
                    </Utility>
                    <FlexLayout spacing={4} alignItems='center'>
                      <Heading size='h6'>{item.card.description}</Heading>
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </Card>
            </CarouselItem>
          );
        })}
      </CarouselContent>
    </Carousel>
  );
});

export { AnnouncementsWidget };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { UIStore } from '@shared/store';

import TradeService from '@services/TradeService';

import { observer } from 'mobx-react-lite';

export const DEMO_MODE_RESET_MODAL_KEY = 'demo_mode_reset_modal_key';

const DemoModeResetModal: React.FC = observer(() => {
  const { t } = useTranslation('profile.settings', { keyPrefix: 'platformData.demoMode' });
  const { t: commonT } = useTranslation('common');
  const [resetting, setResetting] = useState<boolean>(false);
  const { removeMessageBox, addToastMessage } = UIStore.useUIStore;

  const resetDemoMode = async () => {
    try {
      setResetting(true);
      await TradeService.resetDemoMode();
      addToastMessage({ severity: 'success', message: commonT('demoMode.resetMessage') as string });
    } catch (e) {
      removeMessageBox(DEMO_MODE_RESET_MODAL_KEY);
      addToastMessage({ severity: 'error', message: commonT('demoMode.resetMessageError') as string });
      setResetting(false);
    }
  };

  return (
    <Stack spacing={2} direction='row'>
      <Stack spacing={1}>
        <Typography fontSize={pxToRem(16)} fontWeight={600}>
          {t('labels.modalTitle')}
        </Typography>

        <Typography fontSize={pxToRem(14)} color='text.secondary'>
          {t('labels.modalSubTitle')}
        </Typography>

        <Stack direction='row' spacing={1}>
          <Button
            id='profile-settings-reset-demo-mode'
            onClick={resetDemoMode}
            color='error'
            loading={resetting}
            variant='contained'
            sx={{ width: 'fit-content' }}
          >
            {t('buttonLabels.yesReset')}
          </Button>

          <Button
            id='profile-settings-reset-demo-mode'
            onClick={() => removeMessageBox(DEMO_MODE_RESET_MODAL_KEY)}
            color='inherit'
            variant='text'
            sx={{ width: 'fit-content' }}
          >
            {t('buttonLabels.cancel')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
});

DemoModeResetModal.displayName = 'DemoModeResetModal';

export { DemoModeResetModal };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const AMM: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M7.89574 4.21856C6.0513 4.70004 4.69 6.37748 4.69 8.37271C4.69 8.77036 4.36765 9.09271 3.97 9.09271C3.57235 9.09271 3.25 8.77036 3.25 8.37271C3.25 5.20653 5.81688 2.63965 8.98306 2.63965C9.21883 2.63965 9.43966 2.75508 9.57424 2.94867C9.70882 3.14226 9.74009 3.38947 9.65796 3.61047L9.06297 5.21143C8.92445 5.58417 8.50999 5.77404 8.13725 5.63551C7.76451 5.49698 7.57465 5.08253 7.71317 4.70979L7.89574 4.21856Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8134 2.89159C16.3952 2.55541 17.1122 2.55598 17.694 2.89143L20.4765 4.498C21.0592 4.83415 21.4179 5.4561 21.4179 6.12771V9.34086C21.4179 10.0125 21.0596 10.6342 20.4769 10.9703L17.6943 12.5769C17.4396 12.7238 17.1589 12.8065 16.8749 12.8249C16.8359 12.8314 16.7959 12.8348 16.7552 12.8348C16.7152 12.8348 16.6759 12.8315 16.6377 12.8252C16.3521 12.8077 16.0696 12.725 15.8134 12.577L13.03 10.9704C12.4483 10.6342 12.0898 10.0123 12.0898 9.34086V6.12771C12.0898 5.45629 12.4479 4.8346 13.0296 4.49838L15.8134 2.89159ZM17.4752 11.0414V8.13317L19.9793 6.68456V9.3408C19.9793 9.49849 19.8946 9.6446 19.7583 9.72322L17.4752 11.0414ZM16.0352 11.0414V8.13552L13.5312 6.68737V9.3408C13.5312 9.49872 13.6157 9.64474 13.7514 9.72327L16.0352 11.0414ZM16.7531 6.88727L14.2654 5.44848L16.5351 4.13843C16.6704 4.06017 16.8386 4.05967 16.9759 4.13877L19.2423 5.4473L16.7531 6.88727Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.85846 21.1023C8.60105 21.2507 8.3171 21.3336 8.03009 21.3508C7.99271 21.3568 7.95437 21.3599 7.91531 21.3599C7.87534 21.3599 7.83613 21.3567 7.79792 21.3504C7.51347 21.3323 7.23223 21.2496 6.97707 21.1021L4.19403 19.4958C3.61253 19.1596 3.25391 18.5377 3.25391 17.8662V14.6531C3.25391 13.9816 3.61208 13.36 4.19358 13.0238L6.97751 11.417C7.55935 11.0808 8.27635 11.0814 8.85812 11.4168L11.6407 13.0234C12.2237 13.3595 12.5819 13.9815 12.5819 14.6531V17.8662C12.5819 18.5377 12.2238 19.1595 11.641 19.4957L8.85846 21.1023ZM7.69779 12.6639C7.83323 12.5856 8.00149 12.5851 8.13879 12.6643L10.4055 13.973L7.91723 15.4125L5.42902 13.9734L7.69779 12.6639ZM4.69391 15.2117V17.8662C4.69391 18.0241 4.77839 18.1702 4.91415 18.2488L7.19531 19.5654V16.6584L4.69391 15.2117ZM8.63531 16.6606V19.5684L10.921 18.2487C11.0573 18.1701 11.1419 18.024 11.1419 17.8662V15.2105L8.63531 16.6606Z'
      fill='currentColor'
    />
    <path
      d='M21.4179 15.6255C21.4179 15.2279 21.0955 14.9055 20.6979 14.9055C20.3002 14.9055 19.9779 15.2279 19.9779 15.6255C19.9779 17.6207 18.6166 19.2981 16.7722 19.7796L16.9548 19.2884C17.0933 18.9156 16.9034 18.5012 16.5307 18.3627C16.1579 18.2241 15.7435 18.414 15.605 18.7867L15.01 20.3877C14.9278 20.6087 14.9591 20.8559 15.0937 21.0495C15.2282 21.2431 15.4491 21.3585 15.6848 21.3585C18.851 21.3585 21.4179 18.7916 21.4179 15.6255Z'
      fill='currentColor'
    />
  </svg>
);

export { AMM };

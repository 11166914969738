import React, { useRef, useEffect } from 'react';

import { StyledCarouselItemContainer } from './CarouselItem.styled';
import { ComponentProps } from '../../global-utils';

type Props = {
  indexOffset?: number; // internal prop, will always be provided by parent but ? here to stop ts yelling
  activeIndex?: number; // internal prop, will always be provided by parent but ? here to stop ts yelling
  onVisibilityChange?: (isVisible: boolean) => void;
} & ComponentProps;

const CarouselItem = ({ id, indexOffset, children, onVisibilityChange, activeIndex, sx }: Props) => {
  const itemRef = useRef(null);
  const wasVisibleRef = useRef(false);

  useEffect(() => {
    if (!onVisibilityChange) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const isVisible = entries[0].isIntersecting;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const itemIndex = activeIndex! + indexOffset!;

        if (isVisible && itemIndex === activeIndex) {
          onVisibilityChange(isVisible);
          wasVisibleRef.current = true;
        }
      },
      { threshold: 0 },
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => observer.disconnect();
  }, [onVisibilityChange, activeIndex, indexOffset, id]);

  return (
    <StyledCarouselItemContainer id={id} indexOffset={indexOffset} ref={itemRef} sx={sx}>
      {children}
    </StyledCarouselItemContainer>
  );
};

export default CarouselItem;

import { VariantProps, cva } from 'cva';

import { AppSideBarItemType, AppSideBarState } from './AppSideBar.types';

export interface AppSideBarProps extends VariantProps<typeof appSideBarVariants> {
  state: AppSideBarState;
  items: AppSideBarItemType[];
  className?: string;
  itemsClassName?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
}

export const appSideBarContainerVariants = cva('sm:sidebar-width-desktop-closed relative z-30 h-full', {
  variants: {
    state: {
      closed: 'translate-x-[-100%] sm:translate-x-0',
      overlay: '',
      docked: 'sm:sidebar-width-desktop-opened',
    },
  },
  defaultVariants: {
    state: 'closed',
  },
});

export const appSideBarVariants = cva(
  'absolute left-0 top-0 h-full border-r border-color-border-main bg-color-background-surface-primary transition-transform md:transition-width duration-300 py-8',
  {
    variants: {
      state: {
        closed: 'sidebar-width-mobile-opened sm:sidebar-width-desktop-closed translate-x-[-100%] sm:translate-x-0',
        overlay: 'sidebar-width-desktop-opened translate-x-0',
        docked: 'sidebar-width-mobile-opened md:sidebar-width-desktop-opened translate-x-0',
      },
      openOnHover: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        state: 'closed',
        openOnHover: true,
        class: 'hover:sidebar-width-desktop-opened',
      },
    ],
    defaultVariants: {
      state: 'closed',
    },
  },
);

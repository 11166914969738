import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyLeft, Cross, Delete } from '@swyftx/aviary/icons/outlined';

import { observer } from 'mobx-react-lite';

import { useEditPriceAlertModal } from './useEditPriceAlertModal';
import { PriceAlert } from '../../dynamicPriceAlerts.types';
import { DeletePriceAlertModal } from '../DeletePriceAlertModal';
import { PriceAlertInput } from '../PriceAlertInput';

type Props = {
  alert: PriceAlert;
  onClose?: () => void;
};

const EditPriceAlertModal: React.FC<PropsWithChildren<Props>> = observer(({ alert, onClose, children }) => {
  const {
    primaryAsset,
    secondaryAsset,
    updateDisabled,
    value,
    updating,
    alertWindow,
    open,
    setValue,
    setAlertWindow,
    onUpdateDynamicPriceAlert,
    handleOpenChange,
  } = useEditPriceAlertModal({
    alert,
    children,
    onClose,
  });
  const [deleteAlert, setDeleteAlert] = useState<PriceAlert | undefined>(undefined);
  const isXs = useTailwindBreakpoint('xs');
  const { t } = useTranslation('priceAlerts');

  if (!secondaryAsset || !primaryAsset) return null;

  return (
    <>
      <Modal
        triggerElement={children}
        position={isXs ? 'bottom' : 'center'}
        className='min-w-[100vw] sm:!min-w-[500px] sm:!max-w-[500px]'
        overlayClassName='z-modal-popover-backdrop'
        onOpenChange={handleOpenChange}
        open={open && !deleteAlert}
      >
        <FlexLayout
          direction='column'
          alignItems='start'
          justifyContent='space-between'
          className='w-full overflow-hidden p-24'
          spacing={24}
        >
          <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
            <FlexLayout alignItems='center' spacing={16}>
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={<ArrowLineOnlyLeft />}
                onClick={() => handleOpenChange(false)}
              />
              <Heading size='h4'>{t('labels.editAlert', { code: secondaryAsset.code })}</Heading>
            </FlexLayout>
            <FlexLayout alignItems='center' spacing={8}>
              <Button variant='outlined' layout='icon' leadingIcon={<Delete />} onClick={() => setDeleteAlert(alert)} />
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={<Cross />}
                onClick={() => handleOpenChange(false)}
              />
            </FlexLayout>
          </FlexLayout>
          <PriceAlertInput
            value={value}
            primaryAsset={primaryAsset}
            secondaryAsset={secondaryAsset}
            setValue={setValue}
            alertType={alert.type}
            loading={updating}
            disabled={updateDisabled}
            alert={alert}
            alertWindow={alertWindow}
            setAlertWindow={setAlertWindow}
            onActionClick={onUpdateDynamicPriceAlert}
          />
        </FlexLayout>
      </Modal>
      {deleteAlert && (
        <DeletePriceAlertModal alert={deleteAlert} onClose={() => setDeleteAlert(undefined)} onDelete={onClose} />
      )}
    </>
  );
});

export { EditPriceAlertModal };

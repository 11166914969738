import React from 'react';

import { BankTransferDepositModal, CreditCardDepositModal, PayIDDepositModal } from '@global-components/Modals';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

interface DepositMethodSelectorProps {
  asset: Asset;
  setDepositMethod: (depositMethod?: DepositMethodEnum) => void;
  method: DepositMethodEnum | undefined;
}

export const DepositMethodSelector: React.FC<DepositMethodSelectorProps> = ({ asset, setDepositMethod, method }) => {
  switch (method) {
    case DepositMethodEnum.NZD_BANK_TRANSFER:
    case DepositMethodEnum.MONOOVA_DIRECT_CREDIT:
      return <BankTransferDepositModal asset={asset} onClose={() => setDepositMethod(undefined)} />;
    case DepositMethodEnum.MONOOVA_PAYID:
      return <PayIDDepositModal asset={asset} onClose={() => setDepositMethod(undefined)} />;
    case DepositMethodEnum.BANXA:
      return <CreditCardDepositModal asset={asset} onClose={() => setDepositMethod(undefined)} />;
    default:
      return <> </>;
  }
};

import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import Shop from '@Shop/Shop';
import LedgerNano from '@Shop/subroutes/LedgerNano';

import { NavigationURLs } from 'src/lib/navigation/types';

export const ShopRoutes: RouteObject[] = [
  {
    path: NavigationURLs.LedgerNanoX,
    element: <PrivateRoute element={<LedgerNano deviceKey='ledger-nano-x' />} />,
  },
  {
    path: NavigationURLs.LedgerNanoSPlus,
    element: <PrivateRoute element={<LedgerNano deviceKey='ledger-nano-s' />} />,
  },
  {
    path: NavigationURLs.Shop,
    element: <PrivateRoute element={<Shop />} />,
  },
];

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';

import { useAssetEarnTransactionHistory } from '@global-components/Modals/AssetTransactionHistoryModal/AssetTransactionHistoryModals.hooks';
import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode/UnavailableDemoMode';

import { Asset } from '@shared/api/@types/markets';
import { AppStore } from '@shared/store';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

import { SingleWalletEarnTransactionHistoryItem } from './SingleWalletEarnTransactionHistoryItem';
import { TransactionHistoryLoadingState } from '../components/SingleWalletTransactionListLoading';

type Props = {
  asset: Asset;
};

const TRANSACTIONS_PER_PAGE = 5;

const SingleWalletEarnTransactionHistoryList: React.FC<Props> = observer(({ asset }) => {
  const { t } = useTranslation('wallet');
  const { isDemo } = AppStore.useAppStore;
  const { staking } = useAssetBalance(asset);

  const { loading, transactions, hasAnotherPage, page, setPage } = useAssetEarnTransactionHistory(asset, [], {
    transactionsPerPage: TRANSACTIONS_PER_PAGE,
  });

  const incrementPage = () => {
    setPage(page + 1);
  };

  if (!asset || !staking.isStakeable) return null;

  return (
    <>
      {isDemo && <UnavailableDemoMode subTitle={t('singleWalletTransactionHistory.demoMode') as string} showImage />}
      {!isDemo && (
        <>
          <FlexLayout className='p-16' direction='row' alignItems='center' justifyContent='space-between'>
            <Utility className='w-1/3 text-left' color='secondary'>
              Transaction type
            </Utility>

            <Utility color='secondary' className='w-1/3 text-right'>
              Credit
            </Utility>

            <Utility color='secondary' className='w-1/3 text-right'>
              Debit
            </Utility>
          </FlexLayout>
          <Separator className='w-full' />
          <FlexLayout direction='column'>
            {loading && !transactions.length && <div>{TransactionHistoryLoadingState}</div>}

            {!transactions.length && !loading && <Body className='p-16'>No transaction history</Body>}

            {transactions.length > 0 && (
              <div>
                {transactions.map((transaction) => (
                  <SingleWalletEarnTransactionHistoryItem
                    transaction={transaction}
                    // eslint-disable-next-line no-underscore-dangle
                    key={transaction._id}
                    asset={asset}
                  />
                ))}
              </div>
            )}

            {hasAnotherPage && (
              <FlexLayout className='p-16' direction='row' alignItems='center' justifyContent='center'>
                <Button variant='ghost' onClick={incrementPage} loading={loading}>
                  Show more transactions
                </Button>
              </FlexLayout>
            )}
          </FlexLayout>
        </>
      )}
    </>
  );
});

export { SingleWalletEarnTransactionHistoryList };

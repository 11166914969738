import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { types } from '@swyftx/marketing-utils';

type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
type ButtonVariant = 'contained' | 'outlined' | 'text';

const useReward = (reward?: types.UserAssetReward) => {
  const { t } = useTranslation('rewards', { keyPrefix: 'rewardCard' });

  const buttonColor: ButtonColor | undefined = useMemo(() => {
    if (!reward || !reward.redemptions) return undefined;

    if (!reward.redemptions.length) return 'primary';

    const redemption = reward.redemptions[0];

    switch (redemption.status) {
      case types.AssetRedemptionStatus.InProgress:
        return 'inherit';
      default:
        return 'primary';
    }
  }, [reward?.redemptions]);

  const buttonLabel = useMemo(() => {
    if (!reward || !reward.redemptions) return undefined;

    if (!reward.redemptions.length) return t('buttonLabels.optIn');

    const redemption = reward.redemptions[0];
    switch (redemption.status) {
      case types.AssetRedemptionStatus.InProgress:
        return t(reward.hasPayout ? 'buttonLabels.redeem' : 'buttonLabels.submitEntry');
      case types.AssetRedemptionStatus.Qualified:
        return t(reward.hasPayout ? 'buttonLabels.redeem' : 'buttonLabels.submitEntry');
      default:
        return t('buttonLabels.optIn');
    }
  }, [reward, t]);

  const buttonVariant: ButtonVariant | undefined = useMemo(() => {
    if (!reward) return undefined;

    return 'contained';
  }, [reward]);

  const buttonDisabled = useMemo(() => {
    if (!reward || !reward.redemptions) return true;

    if (!reward.redemptions.length) return false;

    const redemption = reward.redemptions[0];

    switch (redemption.status) {
      case types.AssetRedemptionStatus.InProgress:
        return true;
      default:
        return false;
    }
  }, [reward]);

  return {
    buttonColor,
    buttonLabel,
    buttonVariant,
    buttonDisabled,
  };
};

export { useReward };

import { EmitOptions } from './EmitOptions';
import EventListener from './EventListener';
import { SwyftxEvent } from './eventTypes';

type EventManager = {
  [key in SwyftxEvent]?: EventListener;
};

const eventHashmap: EventManager = {};

export const subscribeToEvent = (event: SwyftxEvent, fn: (...args: any[]) => void, replayLast = false) => {
  const eventListener = getEventListener(event);
  const listener = eventListener.addListener(fn);
  // replay last event if asked for
  if (replayLast && eventListener.lastEvent) listener.fn(eventListener.lastEvent);

  return listener;
};

export const unsubscribeFromEvent = (event: SwyftxEvent, fn: (...args: any[]) => void) => {
  const eventListener = getEventListener(event);
  eventListener.removeListener(fn);
};

export const emitEvent = <T>(event: SwyftxEvent, args?: T, opts?: EmitOptions) => {
  const eventListener = getEventListener(event);
  eventListener.emit(args, opts);
};

export const hasListeners = (event: SwyftxEvent) => {
  const eventListener = getEventListener(event);
  return eventListener.listeners.length > 0;
};

const getEventListener = (event: SwyftxEvent) => {
  // make sure the event exists
  if (!eventHashmap[event]) eventHashmap[event] = new EventListener();

  return eventHashmap[event] as EventListener;
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Perpetuals: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.27148 5.78205C9.27148 4.27524 10.493 3.05371 11.9998 3.05371C13.5066 3.05371 14.7282 4.2752 14.7282 5.78205C14.7282 7.03986 13.877 8.09884 12.7193 8.41452V10.8862H16.6239C18.1307 10.8862 19.3522 12.1077 19.3522 13.6146V14.8542C20.5089 15.1706 21.3591 16.229 21.3591 17.4861C21.3591 18.993 20.1374 20.2145 18.6307 20.2145C17.1238 20.2145 15.9023 18.993 15.9023 17.4861C15.9023 16.228 16.7539 15.1687 17.9122 14.8534V13.6146C17.9122 12.903 17.3354 12.3262 16.6239 12.3262H12.7193V14.8537C13.877 15.1693 14.7282 16.2283 14.7282 17.4861C14.7282 18.993 13.5066 20.2145 11.9998 20.2145C10.493 20.2145 9.27148 18.993 9.27148 17.4861C9.27148 16.2287 10.1221 15.17 11.2793 14.854V12.3262H7.37678C6.66524 12.3262 6.08844 12.903 6.08844 13.6146V14.8537C7.24616 15.1693 8.0973 16.2283 8.0973 17.4861C8.0973 18.993 6.87577 20.2145 5.36896 20.2145C3.86216 20.2145 2.64062 18.993 2.64062 17.4861C2.64062 16.2287 3.49125 15.17 4.64844 14.854V13.6146C4.64844 12.1077 5.86997 10.8862 7.37678 10.8862H11.2793V8.41424C10.1221 8.09822 9.27148 7.03946 9.27148 5.78205ZM11.9998 4.49371C11.2883 4.49371 10.7115 5.07051 10.7115 5.78205C10.7115 6.49359 11.2883 7.07039 11.9998 7.07039C12.7114 7.07039 13.2882 6.49355 13.2882 5.78205C13.2882 5.07055 12.7114 4.49371 11.9998 4.49371ZM4.08062 17.4861C4.08062 16.7746 4.65742 16.1978 5.36896 16.1978C6.08051 16.1978 6.6573 16.7746 6.6573 17.4861C6.6573 18.1977 6.0805 18.7745 5.36896 18.7745C4.65742 18.7745 4.08062 18.1977 4.08062 17.4861ZM10.7115 17.4861C10.7115 16.7746 11.2883 16.1978 11.9998 16.1978C12.7114 16.1978 13.2882 16.7746 13.2882 17.4861C13.2882 18.1976 12.7114 18.7745 11.9998 18.7745C11.2883 18.7745 10.7115 18.1977 10.7115 17.4861ZM17.3423 17.4861C17.3423 16.7746 17.9191 16.1978 18.6307 16.1978C19.3422 16.1978 19.9191 16.7746 19.9191 17.4861C19.9191 18.1976 19.3422 18.7745 18.6307 18.7745C17.9191 18.7745 17.3423 18.1977 17.3423 17.4861Z'
      fill='currentColor'
    />
  </svg>
);

export { Perpetuals };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Delete } from '@swyftx/aviary/icons/outlined';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { EntityMember } from '@shared/store/userStore/@types/userTypes';

import { useIntercom } from 'react-use-intercom';

type Props = {
  open: boolean;
  onClose: () => void;
  entityMember: EntityMember;
};

const EntityRemoveModal: React.FC<Props> = ({ open, onClose, entityMember }) => {
  const { showNewMessages } = useIntercom();
  const { t } = useTranslation('profile');

  return (
    <Modal
      sx={{ width: '400px' }}
      id='remove-entity-modal'
      onClose={onClose}
      open={open}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' width='100%' spacing={1}>
            <Button variant='text' color='inherit' onClick={onClose} sx={{ width: '50%' }}>
              {t('entities.entityMembers.remove.cancel')}
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                showNewMessages(`Remove entity account uuid: ${entityMember.uuid}`);
                onClose();
              }}
              sx={{ width: '50%' }}
            >
              {t('entities.entityMembers.remove.contactSupport')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={1} alignItems='center' textAlign='center'>
        <Delete className='h-[60px] w-[60px] text-color-text-error' />

        <Typography fontSize={20} fontWeight='700'>
          {t('entities.entityMembers.remove.modalTitle')}
        </Typography>

        <Typography fontSize={14} fontWeight='400' color='text.secondary'>
          {t('entities.entityMembers.remove.modalContent')}
        </Typography>
      </Stack>
    </Modal>
  );
};

export { EntityRemoveModal };

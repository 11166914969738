import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { Links } from '@shared/constants';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useUniversalTradeUtilityStore } from '@hooks/Trade';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';

import { observer } from 'mobx-react-lite';

const HighSlippageReviewContent: React.FC = observer(() => {
  const { highSlippageTrades } = useUniversalTradeUtilityStore();
  const { getMinimumOrderAmount, convertRate } = RatesStore.useRatesStore;
  const { t } = useTranslation('trade', { keyPrefix: 'highSlippageModal' });

  const highSlippageTrade = useMemo(() => highSlippageTrades[0], [highSlippageTrades]);
  const fromAsset = assetService.getAsset(highSlippageTrade.from);
  const toAsset = assetService.getAsset(highSlippageTrade.to);

  const currentPrice = useMemo(() => {
    if (!fromAsset || !toAsset) return '';

    const minimumAmount = getMinimumOrderAmount(fromAsset);
    const rate = convertRate(fromAsset, toAsset, minimumAmount, 'midPrice').toString();
    return minimumAmount.div(rate).toString();
  }, [convertRate, fromAsset, getMinimumOrderAmount, toAsset]);

  if (!highSlippageTrade || !fromAsset || !toAsset) return null;

  return (
    <Stack direction='column' alignItems='flex-start' justifyContent='center' width='100%' spacing={1} padding={3}>
      <Typography marginBottom={2} color='text.secondary'>
        <Trans
          t={t}
          i18nKey='single.subTitle'
          values={{ slippageThreshold: HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD }}
          components={[
            <Typography
              key='slippageThreshold'
              display='inline-block'
              marginLeft={0.25}
              fontWeight={500}
              color='primary'
              sx={{ display: 'inline-block', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
              onClick={() => window.open(Links.learn.highSlippage)}
            />,
          ]}
        />
      </Typography>

      <Typography
        color={
          (highSlippageTrade.slippagePercentage || 0) > HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD ? 'error' : 'warning.main'
        }
        number
        width='100%'
        fontSize={28}
        fontWeight={500}
        paddingBottom={2}
        textAlign='left'
      >
        ~{highSlippageTrade.slippagePercentage?.toFixed(2)}%
      </Typography>

      <Typography fontWeight={600} fontSize={16}>
        {t('single.impact')}
      </Typography>

      <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingTop={0.5}>
        <Typography fontWeight={500} fontSize={14}>
          {t('currentMarketPrice')}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Typography number>
            {formatCurrency(currentPrice, fromAsset, { appendCode: false, hideCode: true })}
          </Typography>
          <Typography number color='text.secondary'>
            {fromAsset.code}/{toAsset.code}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingTop={0.5}>
        <Typography fontWeight={500} fontSize={14}>
          {t('quotePrice')}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Typography number>
            {formatCurrency(highSlippageTrade.price, fromAsset, { appendCode: false, hideCode: true })}
          </Typography>

          <Typography number color='text.secondary'>
            {fromAsset.code}/{toAsset.code}
          </Typography>
        </Stack>
      </Stack>

      <Typography marginBottom={2} paddingTop={2} color='text.secondary'>
        {t('single.reduceAmount')}
      </Typography>
    </Stack>
  );
});

export { HighSlippageReviewContent };

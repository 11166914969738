import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { api } from '@shared/api';
import { ExchangesEnum } from '@shared/enums';
import { UIStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { lookupTranslationKey, Data } from '../../apiHandler';

export function ConfirmModal({
  exchange = ExchangesEnum.Binance,
  handleCancel,
  open,
  apiKey,
  apiSecret,
  feeFree,
  selectedAssets,
  onSuccess,
}: {
  exchange: ExchangesEnum;
  handleCancel: (errored: boolean) => void;
  open: boolean;
  apiKey: string;
  apiSecret: string;
  feeFree: boolean;
  selectedAssets: Data[];
  onSuccess: () => void;
}) {
  const { pathname } = useLocation();
  const { t } = useTranslation('onboarding');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { addToastMessage } = UIStore.useUIStore;
  const avo = useAvo();

  const executeMigrate = async () => {
    setIsSubmitting(true);
    try {
      avo.migrateInitiated({
        screen: pathname,
        exchange,
        feeFree,
      });

      const swyftxIds = selectedAssets.map((item) => item.asset.swyftxId) as number[];
      await api.endpoints.migrate({
        data: { exchange, apiKey: apiKey.trim(), apiSecret: apiSecret.trim(), assets: swyftxIds },
      });
      setIsSubmitting(false);

      avo.migrateConfirmed({
        screen: pathname,
        exchange,
        feeFree,
      });

      onSuccess();
    } catch (error: any) {
      const lookupKey =
        lookupTranslationKey(exchange, error) ?? 'steps.assetMigrator.confirmationModal.error.binance.invalidKeys';
      addToastMessage({
        message: t(lookupKey as unknown as TemplateStringsArray),
        severity: 'error',
      });

      setIsSubmitting(false);
      handleCancel(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel(false)}
      aria-labelledby='confirm-asset-transfer-from-exchange'
      aria-describedby='are you sure you want to transfer'
      maxWidth='xs'
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            By continuing, all assets listed on Swyftx will be transferred from your {exchange} account to your Swyftx
            account. This action cannot be reversed.
          </Typography>
          <br />
          <Typography fontWeight='bold'>Assets included:</Typography>
          <ul className='list-disc pl-20'>
            <li>We will only transfer assets you select.</li>
            <li>We will only transfer assets listed on Swyftx.</li>
            <li>The entire balance of each asset will be transferred.</li>
            <li>Any assets locked in staking, loans or pending orders may not transfer.</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel(false)} sx={{ textTransform: 'none' }}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ textTransform: 'none' }}
          onClick={executeMigrate}
          loading={isSubmitting}
          autoFocus
          variant='contained'
        >
          Yes, transfer now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Typography } from '@swyftx/react-web-design-system';

import env from '@shared/config';
import { DarkModeEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';
import ReCAPTCHA from 'react-google-recaptcha';
import { getLanguage } from 'src/utils';

import { RecaptchaSkeleton } from './Recaptcha.skeleton';

type Props = {
  id: string;
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
};

const Recaptcha: React.FC<Props> = observer(({ id, attachForm }) => {
  const { setRecaptchaToken, recaptchaToken, refreshRecaptcha, setRefreshRecaptcha } = UserStore.useUserStore;
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);
  const [recaptchaTheme, setRecaptchaTheme] = useState<any>('light');
  const [error, setError] = useState<boolean>(false);

  const { t } = useTranslation('common');
  const language = getLanguage();
  const { theme } = useTheme();

  const validate = useCallback(async () => {
    if (env.DISABLE_CAPTCHA) {
      setError(false);
      return true;
    }
    setError(recaptchaToken.length === 0);
    return recaptchaToken.length > 0;
  }, [recaptchaToken]);

  // Form validation (related to Form component)
  useEffect(() => {
    if (attachForm) attachForm(id, validate);
  }, [attachForm, id, validate, recaptchaToken]);

  useEffect(() => {
    if (refreshRecaptcha) {
      recaptchaRef.current?.reset();
      window.setTimeout(() => {
        setRefreshRecaptcha(false);
      }, 100);
    }
  }, [refreshRecaptcha, setRefreshRecaptcha]);

  // Updating theme
  useEffect(() => {
    let newTheme = '';
    switch (theme.palette.mode) {
      case DarkModeEnum.DARK:
        setRecaptchaTheme('dark');
        newTheme = DarkModeEnum.DARK;
        break;
      case DarkModeEnum.LIGHT:
      default:
        setRecaptchaTheme('light');
        newTheme = DarkModeEnum.LIGHT;
        break;
    }

    if (newTheme !== recaptchaTheme) {
      setRecaptchaToken('');
    }
  }, [theme.palette.mode, recaptchaTheme, setRecaptchaToken]);

  // Once this component is loaded, do not hide
  useEffect(() => {
    if (!recaptchaToken && !initiallyLoaded) setInitiallyLoaded(true);
  }, [recaptchaToken, initiallyLoaded, setInitiallyLoaded]);

  const onChange = (token: string | null) => {
    setRecaptchaToken(token || '');
    if (token) {
      setError(token.length === 0);
    }
  };

  if (!initiallyLoaded && recaptchaToken) {
    return <Box id={id} height='78px' sx={{ marginBottom: 2 }} />;
  }

  return (
    <Box height='100px' id={id} sx={{ marginBottom: 2 }}>
      <Box
        display='flex'
        justifyContent='center'
        position='relative'
        sx={{
          border: error ? '1px solid' : '',
          borderColor: 'error.main',
          iframe: {
            position: 'relative',
            zIndex: 21,
          },
        }}
      >
        <Box sx={{ position: 'absolute', width: '300px', height: '75px' }}>
          <RecaptchaSkeleton />
        </Box>

        <ReCAPTCHA
          ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
          hl={language}
          sitekey={env.CAPTCHA_API_KEY as string}
          onChange={onChange}
          onErrored={() => onChange(null)}
          theme={recaptchaTheme}
        />
      </Box>

      {error && (
        <Typography width='100%' marginTop={0.5} fontSize={12} textAlign='left' id={`${id}_error`} color='error'>
          {t('errors.recaptcha.invalid')}
        </Typography>
      )}
    </Box>
  );
});

Recaptcha.displayName = 'Recaptcha';

export default Recaptcha;

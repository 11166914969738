import { lighten } from '@mui/system';

import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';

import { EntityColor } from '@shared/store/userStore/@types/userTypes';

export const getChangeColor = (price: string): NumericProps['color'] => {
  const firstChar = price.charAt(0);

  switch (firstChar) {
    case '+': {
      return 'success';
    }
    case '-': {
      return 'error';
    }
    default: {
      return 'primary';
    }
  }
};
export const EntityThemeColors: { [key in EntityColor]: string } = {
  [EntityColor.Purple]: '#6B00E5',
  [EntityColor.Magenta]: '#E50097',
  [EntityColor.Teal]: '#00CCC0',
  [EntityColor.Orange]: '#E56E00',
};

export const TRADING_COLOR = '#0072ED';
export const STAKING_COLOR = '#00C2FF';

const getLuma = (color: string) => {
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue
  return 0.2126 * r + 0.7152 * g + 0.0722 * b; // https://en.wikipedia.org/wiki/Rec._709
};

const COLOR_LIGHTEN_WEIGHT = 0.3;
const LUMINOSITY_LIGHTEN_THRESHOLD = 165;
export const getVisibleColor = (color: string) => {
  if (getLuma(color) >= LUMINOSITY_LIGHTEN_THRESHOLD) {
    return color;
  }

  return lighten(color, COLOR_LIGHTEN_WEIGHT);
};

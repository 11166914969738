/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Tick, Error } from '@swyftx/aviary/icons/outlined';

import AccountNumberValidator from '@hooks/validator/types/accountNumber.validator';
import { useValidation } from '@hooks/validator/useValidation';

type AccountNumberInputFormProps = {
  characters: {
    min: number;
    max: number;
  };
  setValid?: (valid: boolean) => void;
  success?: boolean;
} & InputProps;

const AccountNumberInput: React.FC<AccountNumberInputFormProps> = ({
  id,
  characters,
  setValid,
  onChange,
  value,
  required,
  ...props
}) => {
  const invalidChars = ['-', '+', 'e', '.'];

  const validatorProps = useValidation(
    [new AccountNumberValidator('Account number is not valid', { characters })],
    true,
    onChange,
    value,
  );

  const isValidKey = (key: string) => {
    if (invalidChars.includes(key)) return false;
    return true;
  };

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  const trailing = useMemo(() => {
    if (validatorProps.success) return <Tick className='h-16 w-16 text-color-text-success' />;
    if (validatorProps.error) return <Error className='h-16 w-16 text-color-text-error' />;

    return null;
  }, [validatorProps.error, validatorProps.success]);

  return (
    <FlexLayout direction='column' spacing={4}>
      <Body size='small' weight='emphasis'>
        Account number:
        {required && <span className='inline text-color-text-error'>*</span>}
      </Body>
      <Input
        className='fs-mask'
        containerClassName={validatorProps.success ? 'border-color-border-success border' : ''}
        id={id}
        type='number'
        required={required}
        placeholder='Enter account number'
        {...props}
        {...validatorProps}
        trailing={trailing}
        onKeyDown={(evt) => !isValidKey(evt.key) && evt.preventDefault()}
      />
      <Body color={validatorProps.error ? 'error' : 'success'} size='small' weight='emphasis'>
        {validatorProps.helperText}
      </Body>
    </FlexLayout>
  );
};

export { AccountNumberInput };

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import {
  PerformanceMenuControl,
  PerformanceMenuControlLabel,
} from '@global-components/PerformanceMenu/PerformanceMenu.types';
import { FormattedText } from '@global-components/Text';
import { LineChartTooltipData, LineChartTooltipPosition } from '@global-components/charts/LineChart/LineChart.types';

import { Asset } from '@shared/api';
import { formatDate, formatTime } from '@shared/utils';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedControl: PerformanceMenuControl;
  baseAsset: Asset;
  width: number;
  asset: Asset;
  tooltipInfo: {
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  };
};

export const SingleWalletPerformanceTooltip: React.FC<Props> = observer(
  ({ selectedControl, tooltipInfo, baseAsset, asset, width }) => {
    const transform = (() => {
      const shouldTranslateLeft = (tooltipInfo?.position?.x || 0) > width / 2;
      const translateX = shouldTranslateLeft ? `calc(-100% - 1rem)` : '2rem';
      return `translate(${translateX}, -30%)`;
    })();

    const getTimeLabel = (time: Date) =>
      selectedControl.label === PerformanceMenuControlLabel.OneDay ? formatTime(time) : formatDate(time);

    return tooltipInfo.data && tooltipInfo.position ? (
      <div
        className='pointer-events-none absolute	z-tooltip whitespace-nowrap duration-100'
        style={{ top: tooltipInfo.position.y, left: tooltipInfo.position.x, transform }}
      >
        <Card className=''>
          <FlexLayout direction='column' spacing={8}>
            <Body className='px-16 pt-8' color='secondary'>
              {getTimeLabel(tooltipInfo.data.main.time)}
            </Body>
            <Separator className='w-full' />
            <FlexLayout className='px-16' spacing={8} direction='row' alignItems='start'>
              <AssetIcon asset={asset} size={24} />
              <FlexLayout spacing={8} direction='column'>
                <FormattedText
                  typographyProps={{ size: 'large' }}
                  value={tooltipInfo.data.main.value}
                  currency={baseAsset}
                />
                <FlexLayout spacing={8} direction='row' alignItems='center' key='cost-basis'>
                  {tooltipInfo.data.costBasis && (
                    <>
                      <Body>Cost Basis: </Body>
                      <FormattedText
                        value={tooltipInfo.data.costBasis.value}
                        currency={baseAsset}
                        formatOpts={{ appendCode: true }}
                      />
                    </>
                  )}
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </Card>
      </div>
    ) : null;
  },
);

import { Asset } from '@shared/api';
import { Candle } from '@shared/api/@types/charts';

import { FetchAssetLineChartDataOutput, LineChartData } from './LineChart.types';

export const formatCandleData = (asset: Asset, baseAsset: Asset, candles: Candle[]): FetchAssetLineChartDataOutput => {
  const currentPrice = candles.length ? candles[candles.length - 1].close : 0;
  const currentDateTime = candles[candles.length - 1]?.time ? candles[candles.length - 1].time : undefined;
  const openPrice = candles.length ? candles[0].open : 0;
  const closePrice = candles.length ? candles[candles.length - 1].close : 0;
  const openDateTime = candles[0]?.time ? candles[0].time : undefined;
  const closeDateTime = candles.length ? candles[candles.length - 1].time : undefined;
  const volume = asset.volume[baseAsset.id].day;

  const { formattedData, high, low } = candles.reduce<{ formattedData: LineChartData; high: number; low: number }>(
    (acc, datum) => {
      if (datum.close > acc.high) {
        acc.high = datum.high;
      }
      if (datum.close < acc.low) {
        acc.low = datum.low;
      }

      // More efficient to format the data while we're here to calculate the min/max
      acc.formattedData.push({ value: datum.close, time: new Date(datum.time) });
      return acc;
    },
    { formattedData: [], high: 0, low: candles.length ? candles[candles.length - 1].low : 0 },
  );

  return {
    currentDateTime,
    formattedData,
    closeDateTime,
    currentPrice,
    openDateTime,
    closePrice,
    openPrice,
    volume,
    data: candles,
    high,
    low,
  };
};

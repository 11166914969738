import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@swyftx/react-web-design-system';

import { NoChartError } from '@global-components/Errors/NoChartError';

import { useTheme } from '@hooks/useTheme';

import { PieChart } from '../PieChart';
import { getPlaceholderPieData } from '../mocks/placeholderData';

type Props = {
  chartSize: number;
  subTitle?: string;
  title?: string;
  showErrorMessage?: boolean;
};

const ERROR_HEIGHT = 100;

export const EmptyPieChart: React.FC<Props> = ({ chartSize, subTitle, title, showErrorMessage = true }) => {
  const { t } = useTranslation('common', { keyPrefix: 'emptyCharts.allocation' });
  const defaultTitle = t('title');
  const defaultSubTitle = t('subTitle');
  const { theme } = useTheme();

  return (
    <Stack width='100%' height='100%' justifyContent='space-between' paddingTop={2}>
      {/* Pie chart */}
      <Stack width='100%' height='100%' justifyContent='center' alignContent='center'>
        <PieChart
          data={getPlaceholderPieData(theme)}
          height={chartSize - ERROR_HEIGHT}
          width={chartSize - ERROR_HEIGHT}
          selectedId={0}
        />
      </Stack>
      {showErrorMessage && (
        <NoChartError
          subTitle={subTitle || defaultSubTitle}
          subTitleProps={{ align: 'left' }}
          titleProps={{ align: 'left' }}
          title={title || defaultTitle}
          containerProps={{
            sx: {
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              paddingBottom: 0,
              height: `${ERROR_HEIGHT}px`,
              paddingLeft: 1,
            },
            spacing: 1,
          }}
        />
      )}
    </Stack>
  );
};

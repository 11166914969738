import React, { useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';
import { Dollar } from '@swyftx/aviary/icons/outlined';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { InverseAssetIcon } from '@global-components/InverseAssetIcon';
import { FormattedText } from '@global-components/Text';
import { LineChartTooltipData, LineChartTooltipPosition } from '@global-components/charts/LineChart/LineChart.types';

import { Asset } from '@shared/api';
import { formatDate, formatDateTime, formatTime } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

enum ToolTipPeriod {
  OneDay = '1D',
  OneWeek = '1W',
  OneMonth = '1M',
  ThreeMonths = '3M',
  OneYear = '1Y',
  AllTime = 'All',
}

type Props = {
  tooltipPeriod?: ToolTipPeriod;
  selectedAssets?: Map<string, Asset>;
  forceBaseAsset?: Asset;
  width: number;
  tooltipInfo: {
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  };
};

const LineChartToolTip: React.FC<Props> = observer(
  ({ tooltipInfo, selectedAssets, width, tooltipPeriod, forceBaseAsset }) => {
    const { theme } = useTheme();
    const baseAsset = useBaseAsset();
    const usedAsset = forceBaseAsset || baseAsset;

    const transform = useMemo(() => {
      const shouldTranslateLeft = (tooltipInfo?.position?.x || 0) > width / 2;
      const translateX = shouldTranslateLeft ? `calc(-100% - 1rem)` : '2rem';
      return `translate(${translateX}, -30%)`;
    }, [tooltipInfo?.position?.x, width]);

    const getTimeLabel = (time: Date) => {
      if (!tooltipPeriod) {
        return formatDateTime(time);
      }
      return tooltipPeriod === ToolTipPeriod.OneDay ? formatTime(time) : formatDate(time);
    };

    if (!usedAsset) return null;

    return tooltipInfo.data && tooltipInfo.position ? (
      <div
        className='pointer-events-none absolute z-tooltip whitespace-nowrap'
        style={{ left: tooltipInfo.position.x, top: tooltipInfo.position.y, transform }}
      >
        <Card className='p-0'>
          <FlexLayout direction='column' spacing={8}>
            <Body color='secondary' size='small' className='px-8 pt-8 uppercase'>
              {getTimeLabel(tooltipInfo.data.main.time)}
            </Body>
            <Separator className='w-full' />
            <FlexLayout spacing={8} direction='row' alignItems='center' className='px-8 pb-8'>
              <InverseAssetIcon color={theme.palette.primary.main} className='h-20 w-20'>
                <Dollar className='text-color-text-inverse' />
              </InverseAssetIcon>
              <FormattedText
                value={tooltipInfo?.data?.main.value}
                currency={usedAsset}
                formatOpts={{ appendCode: true }}
                typographyProps={{ size: 'small' }}
              />
            </FlexLayout>
            {selectedAssets &&
              tooltipInfo.data.overlays.map((tooltipOverlayData) => {
                const selectedAsset = Array.from(selectedAssets.entries())?.find(
                  ([id]) => id === tooltipOverlayData.id.toString(),
                );
                const asset = selectedAsset?.[1];
                if (!asset) {
                  return null;
                }

                return (
                  <FlexLayout
                    spacing={8}
                    direction='row'
                    alignItems='center'
                    key={tooltipOverlayData.id}
                    className='px-8 pb-8'
                  >
                    <AssetIcon asset={asset} size={20} />
                    <FormattedText
                      value={tooltipOverlayData.value}
                      currency={usedAsset}
                      formatOpts={{ appendCode: true }}
                      typographyProps={{ size: 'small' }}
                    />
                  </FlexLayout>
                );
              })}
            {tooltipInfo.data.costBasis && (
              <FlexLayout spacing={8} direction='column' alignItems='start' key='cost-basis' className='px-8 pb-8'>
                <Utility>Cost Basis: </Utility>
                <FormattedText
                  value={tooltipInfo.data.costBasis.value}
                  currency={usedAsset}
                  formatOpts={{ appendCode: true }}
                  typographyProps={{ size: 'small' }}
                />
              </FlexLayout>
            )}
          </FlexLayout>
        </Card>
      </div>
    ) : null;
  },
);

LineChartToolTip.displayName = 'LineChartToolTip';

export { LineChartToolTip, ToolTipPeriod };

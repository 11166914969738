import { useCallback } from 'react';

import { api } from '@shared/api';
import { ChartBarsParams, ChartBarsQuery } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';
import { chartService } from '@shared/services';

import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { ResolutionString } from 'public/assets/charting_library/charting_library';

import mockData from './getBars.mock.json';

type Props = {
  side: 'bid' | 'ask';
  resolution: ResolutionString;
  secondary: string;
  primary: string;
  timeStart: DateTime;
  timeEnd: DateTime;
  mock?: boolean;
  pro?: boolean;
};

const useFetchChartBars = ({
  side,
  secondary,
  primary,
  timeStart,
  timeEnd,
  resolution,
  mock = false,
  pro = false,
}: Props) => {
  const fetchChartBars = useCallback(async () => {
    const params: ChartBarsParams = {
      side,
      secondary,
      primary,
    };

    const query: ChartBarsQuery = {
      resolution: chartService.tradingToSwyftx?.[resolution],
      timeStart: timeStart.toMillis(),
      timeEnd: timeEnd.toMillis(),
      isPro: pro,
    };

    if (mock) return mockData.candles.sort((a, b) => Big(a.time).cmp(Big(b.time)));

    const { data } = await api.endpoints.getChartBars({
      params,
      query,
    });

    return data.candles.sort((a, b) => Big(a.time).cmp(Big(b.time)));
  }, [mock, primary, pro, resolution, secondary, side, timeEnd, timeStart]);

  const { status, data, error, isFetching, isPreviousData } = useQuery({
    queryKey: ['chart_bars', primary, secondary, resolution, side, timeStart.toMillis()],
    queryFn: () => fetchChartBars(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return {
    status,
    chartBars: data,
    error,
    isFetching,
    isPreviousData,
  };
};

export { useFetchChartBars };

import React from 'react';

import AttachMoney from '@mui/icons-material/AttachMoney';
import { Box } from '@mui/material';

import { Card, Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { InverseAssetIcon } from '@global-components/InverseAssetIcon';
import { FormattedText } from '@global-components/Text';
import { LineChartTooltipData, LineChartTooltipPosition } from '@global-components/charts/LineChart/LineChart.types';

import { Asset } from '@shared/api';
import { formatDate, formatDateTime, formatTime } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

enum ToolTipPeriod {
  OneDay = '1D',
  OneWeek = '1W',
  OneMonth = '1M',
  ThreeMonths = '3M',
  OneYear = '1Y',
  AllTime = 'All',
}

type Props = {
  tooltipPeriod?: ToolTipPeriod;
  selectedAssets?: Map<string, Asset>;
  forceBaseAsset?: Asset;
  width: number;
  tooltipInfo: {
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  };
};

// TODO - REMOVE MUI
const LineChartToolTip: React.FC<Props> = observer(
  ({ tooltipInfo, selectedAssets, width, tooltipPeriod, forceBaseAsset }) => {
    const { theme } = useTheme();
    const baseAsset = useBaseAsset();
    const usedAsset = forceBaseAsset || baseAsset;

    const transform = (() => {
      const shouldTranslateLeft = (tooltipInfo?.position?.x || 0) > width / 2;
      const translateX = shouldTranslateLeft ? `calc(-100% - 1rem)` : '2rem';
      return `translate(${translateX}, -30%)`;
    })();

    const getTimeLabel = (time: Date) => {
      if (!tooltipPeriod) {
        return formatDateTime(time);
      }
      return tooltipPeriod === ToolTipPeriod.OneDay ? formatTime(time) : formatDate(time);
    };

    if (!usedAsset) return null;

    return tooltipInfo.data && tooltipInfo.position ? (
      <Box
        position='absolute'
        zIndex={theme.zIndex.appBar}
        left={tooltipInfo.position.x}
        top={tooltipInfo.position.y}
        whiteSpace='nowrap'
        sx={{ transform, pointerEvents: 'none', transition: '0.1s' }}
      >
        <Card contentSx={{ padding: `${theme.spacing(2)} !important` }} variant='outlined' elevation={0}>
          <Stack spacing={1}>
            <Typography color='text.secondary'>{getTimeLabel(tooltipInfo.data.main.time)}</Typography>
            <Divider variant='fullWidth' />
            <Stack spacing={1} direction='row' alignItems='center'>
              <InverseAssetIcon color={theme.palette.primary.main} className='h-24 w-24'>
                <AttachMoney />
              </InverseAssetIcon>
              <FormattedText
                value={tooltipInfo?.data?.main.value}
                currency={usedAsset}
                formatOpts={{ appendCode: true }}
              />
            </Stack>
            {selectedAssets &&
              tooltipInfo.data.overlays.map((tooltipOverlayData) => {
                const selectedAsset = Array.from(selectedAssets.entries())?.find(
                  ([id]) => id === tooltipOverlayData.id.toString(),
                );
                const asset = selectedAsset?.[1];
                if (!asset) {
                  return null;
                }

                return (
                  <Stack spacing={1} direction='row' alignItems='center' key={tooltipOverlayData.id}>
                    <AssetIcon asset={asset} size={24} />
                    <FormattedText
                      value={tooltipOverlayData.value}
                      currency={usedAsset}
                      formatOpts={{ appendCode: true }}
                    />
                  </Stack>
                );
              })}
            {tooltipInfo.data.costBasis && (
              <Stack spacing={1} direction='row' alignItems='center' key='cost-basis'>
                <Typography fontSize={14}>Cost Basis: </Typography>
                <FormattedText
                  value={tooltipInfo.data.costBasis.value}
                  currency={usedAsset}
                  formatOpts={{ appendCode: true }}
                />
              </Stack>
            )}
          </Stack>
        </Card>
      </Box>
    ) : null;
  },
);

LineChartToolTip.displayName = 'LineChartToolTip';

export { LineChartToolTip, ToolTipPeriod };

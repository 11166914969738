import React from 'react';

import ListItemTextMUI, { ListItemTextProps } from '@mui/material/ListItemText';

const ListItemText: React.FC<ListItemTextProps> = ({ id, children, ...props }) => (
  <ListItemTextMUI id={id} {...props}>
    {children}
  </ListItemTextMUI>
);

export { ListItemText };

import React from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { OutlinedAvatarIcon } from '@global-components/OutlinedAvatarIcon';
import { PriceIncrease } from '@global-components/PriceIncrease';
import { FormattedText } from '@global-components/Text';

import { assetService } from '@shared/services';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';
import { WalletType } from '@routes/Wallet/types';

import {
  getBalanceKeyFromSelectedPortfolioItem,
  getPortfolioItemColor,
  getPortfolioItemIcon,
} from '@Wallet/components/WalletPortfolioAllocation/WalletPortfolioAllocation.utils';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedPortfolioItem: number | null;
  walletType: WalletType;
};

// TODO write a hook that calculates total staking wallet change (if we have the data)
const WalletPortfolioAllocationOverviewDetails: React.FC<Props> = observer(({ selectedPortfolioItem, walletType }) => {
  const baseAsset = useBaseAsset();
  const {
    portfolio: { accountValue, profit, percentChange, staking },
  } = usePortfolio({ balanceKey: getBalanceKeyFromSelectedPortfolioItem(selectedPortfolioItem, walletType) });
  const change = percentChange.toNumber();

  const baseAssetPriceScale = assetService.getDisplayPriceScale(baseAsset);
  const icon = getPortfolioItemIcon(selectedPortfolioItem, walletType);
  const color = getPortfolioItemColor(selectedPortfolioItem);
  const chipColor = (() => {
    if (change === 0) {
      return 'secondary';
    }

    return change >= 0 ? 'success' : 'destructive';
  })();

  return (
    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
      <FlexLayout direction='column' spacing={8} alignItems='center' justifyContent='center'>
        <FormattedText
          typographyProps={{ weight: 'emphasis', className: 'text-16 sm:text-18' }}
          value={accountValue}
          currency={baseAsset}
          formatOpts={{
            priceScale: baseAssetPriceScale,
            appendCode: true,
            hideCode: false,
          }}
        />
        <OutlinedAvatarIcon color={color} bgClassName={!color ? 'bg-color-background-primary' : undefined}>
          {icon}
        </OutlinedAvatarIcon>
        {walletType === WalletType.EARNING_WALLETS && (
          <>
            <Body color='secondary' className='text-center' weight='emphasis'>
              All time rewards
            </Body>
            <PriceIncrease
              typographyProps={{ size: 'large', weight: 'emphasis' }}
              formatCurrencyOptions={{ appendCode: true, hideCode: false }}
              amount={staking.stakingRewardsTotal.toString()}
              currencyAsset={baseAsset}
            />
          </>
        )}
        {walletType !== WalletType.EARNING_WALLETS && (
          <>
            <PriceIncrease
              typographyProps={{ size: 'large', weight: 'emphasis' }}
              formatCurrencyOptions={{ appendCode: true, hideCode: false }}
              amount={profit.toString()}
              currencyAsset={baseAsset}
            />

            <Chip size='md' color={chipColor}>
              <Body color='inverse' className='text-center' weight='emphasis'>
                {`${change >= 0 ? '+' : ''}${change.toLocaleString([], {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}%`}
              </Body>
            </Chip>
          </>
        )}
      </FlexLayout>
    </div>
  );
});

WalletPortfolioAllocationOverviewDetails.displayName = 'WalletPortfolioAllocationOverviewDetails';

export { WalletPortfolioAllocationOverviewDetails };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Loading: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading

  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.06999 4.90718C6.36288 4.61428 6.83775 4.61427 7.13065 4.90716L8.07964 5.85612C8.37253 6.14901 8.37254 6.62388 8.07965 6.91678C7.78676 7.20968 7.31189 7.20969 7.01899 6.9168L6.07 5.96784C5.77711 5.67495 5.7771 5.20008 6.06999 4.90718ZM8.07949 15.1406C8.37247 15.4334 8.37261 15.9082 8.0798 16.2012L6.35861 17.9234C6.0658 18.2164 5.59093 18.2165 5.29795 17.9237C5.00497 17.6309 5.00483 17.1561 5.29764 16.8631L7.01883 15.1409C7.31164 14.8479 7.78651 14.8478 8.07949 15.1406ZM16.3047 15.1406C16.5977 14.8478 17.0726 14.8479 17.3654 15.1409L19.0865 16.8631C19.3793 17.1561 19.3792 17.631 19.0862 17.9238C18.7932 18.2166 18.3183 18.2164 18.0255 17.9234L16.3044 16.2012C16.0116 15.9082 16.0118 15.4334 16.3047 15.1406Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.1913 3C12.6055 3 12.9413 3.33579 12.9413 3.75V4.46778C12.9413 4.88199 12.6055 5.21778 12.1913 5.21778C11.7771 5.21778 11.4413 4.88199 11.4413 4.46778V3.75C11.4413 3.33579 11.7771 3 12.1913 3ZM3 11.0334C3 10.6192 3.33579 10.2834 3.75 10.2834H5.6257C6.03991 10.2834 6.3757 10.6192 6.3757 11.0334C6.3757 11.4476 6.03991 11.7834 5.6257 11.7834H3.75C3.33579 11.7834 3 11.4476 3 11.0334ZM18.007 11.0334C18.007 10.6192 18.3428 10.2834 18.757 10.2834H21.1913C21.6055 10.2834 21.9413 10.6192 21.9413 11.0334C21.9413 11.4476 21.6055 11.7834 21.1913 11.7834H18.757C18.3428 11.7834 18.007 11.4476 18.007 11.0334ZM12.1913 16.849C12.6055 16.849 12.9413 17.1848 12.9413 17.599V20.0334C12.9413 20.4476 12.6055 20.7834 12.1913 20.7834C11.7771 20.7834 11.4413 20.4476 11.4413 20.0334V17.599C11.4413 17.1848 11.7771 16.849 12.1913 16.849Z'
      fill='currentColor'
    />
  </svg>
);

export { Loading };

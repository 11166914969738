import React, { useCallback, useContext, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { CreateOrderResponse } from '@shared/api';
import { OrderSourceEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';

import { LimitedFunctionalityNotification } from 'src/lib/trade/components';
import { QuickBuyContext } from 'src/lib/trade/context';
import { useExecuteTrade } from 'src/lib/trade/hooks/General/useExecuteTrade';
import { useQuickBuyAnalytics } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAnalytics';
import { useQuickBuyAssets } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAssets';
import { useQuickBuyReview } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyReview';

import { QuickBuyWidgetReviewItem } from './QuickBuyWidgetReviewItem';
import { QuickBuyCardHeader } from '../../components';

const QuickBuyWidgetReview: React.FC = () => {
  const { setCurrentStep, setCompletedOrder, setTradeError } = useContext(QuickBuyContext);
  const { addToast } = useContext(ToastManagerContext);
  const { limitAsset, selectedAsset, countryAsset } = useQuickBuyAssets();
  const { trackTradeExecuted } = useQuickBuyAnalytics();

  const {
    fee,
    feePercentage,
    total,
    currentPrice,
    type,
    paymentMethod,
    amount: quoteAmount,
    order,
    isBuy,
  } = useQuickBuyReview();

  const onExecuteSuccess = useCallback(
    (response: CreateOrderResponse) => {
      if (response.processed) {
        setCompletedOrder({ ...response, fee, feePercentage });
        setCurrentStep('complete');
      } else {
        addToast({
          title: 'Failed to process order',
          type: 'destructive',
          description: 'The order failed to process. Please try again.',
          isDismissable: true,
        });
      }
    },
    [addToast, fee, feePercentage, setCompletedOrder, setCurrentStep],
  );

  const onExecuteFailure = useCallback(
    (errorMessage: string) => {
      setCompletedOrder(undefined);
      setTradeError(errorMessage);
      setCurrentStep('failure');
    },
    [setCompletedOrder, setCurrentStep, setTradeError],
  );

  const { executeTrade, isExecuting } = useExecuteTrade({ onExecuteSuccess, onExecuteFailure });

  const initiateTrade = useCallback(() => {
    executeTrade(order, OrderSourceEnum.QUICK_BUY);
    if (!paymentMethod) return;
    trackTradeExecuted({
      order: {
        type: isBuy ? 'buy' : 'sell',
        asset_code: order.secondary,
        paymentOption: paymentMethod.type,
        amount: quoteAmount,
      },
      asset: selectedAsset!,
      limitAsset: limitAsset!,
      userCountryValue: Number(quoteAmount),
    });
  }, [executeTrade, isBuy, limitAsset, order, paymentMethod, quoteAmount, selectedAsset, trackTradeExecuted]);

  const isRestricted = useMemo(() => !assetService.canWithdrawAndDepositAsset(selectedAsset), [selectedAsset]);

  const isTradeRestricted = useMemo(() => selectedAsset?.buyDisabled || !selectedAsset?.sellEnabled, [selectedAsset]);

  if (!limitAsset || !selectedAsset || !countryAsset) return null;

  return (
    <FlexLayout direction='column' alignItems='center' justifyContent='start' className='h-full w-full'>
      <QuickBuyCardHeader title='Review order' onBack={() => setCurrentStep('values')} />
      <FlexLayout direction='column' className='h-full w-full p-24 pt-0' spacing={16}>
        <FlexLayout direction='column' alignItems='center' spacing={4}>
          <AssetIcon asset={selectedAsset} size={40} />
          <FlexLayout direction='column' alignItems='center' spacing={0}>
            <Numeric size='xxlarge' weight='bold' className='text-center'>
              {total}
            </Numeric>
            <Numeric size='medium' color='secondary'>
              {quoteAmount}
            </Numeric>
          </FlexLayout>
        </FlexLayout>
        {(isRestricted || isTradeRestricted) && (
          <FlexLayout direction='column' className='h-full'>
            {isBuy && <LimitedFunctionalityNotification asset={selectedAsset} />}
          </FlexLayout>
        )}

        <FlexLayout direction='column' alignItems='center' spacing={12}>
          <QuickBuyWidgetReviewItem title='Order type' value={type} />
          <QuickBuyWidgetReviewItem title='Current price' value={currentPrice} />
          {paymentMethod && isBuy && <QuickBuyWidgetReviewItem title='Pay with' value={paymentMethod.title} />}
          {!isBuy && <QuickBuyWidgetReviewItem title='Sell to' value={`${countryAsset.code} Wallet`} />}

          <QuickBuyWidgetReviewItem title={`Fee (${Big(feePercentage).times(100).toString()}%)`} value={fee} />
          <QuickBuyWidgetReviewItem title='Total' value={total} />
          <QuickBuyWidgetReviewItem title='Receive' value={quoteAmount} />
        </FlexLayout>

        <FlexLayout className='h-full w-full' alignItems='end'>
          <Button
            size='lg'
            variant='filled'
            className='w-full'
            loading={isExecuting}
            disabled={isExecuting}
            onClick={initiateTrade}
          >
            {type} now
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export { QuickBuyWidgetReview };

import { styled, Theme } from '@mui/material/styles';

import { ThemeProps } from 'styled-components';

type StyledProps = {
  indexOffset?: number;
} & Partial<ThemeProps<Theme>>;

export const StyledCarouselItemContainer = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'indexOffset',
})<StyledProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  transform: translateX(${({ indexOffset }) => (indexOffset ? indexOffset * 100 : 0)}%);
  transition: transform 0.5s;
`;

import React, { useCallback, useEffect, useState } from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { PriceAlert } from '../../dynamicPriceAlerts.types';
import { useToggleDynamicPriceAlertStatus } from '../../hooks/useToggleDynamicPriceAlertStatus';
import { isLimitAlert } from '../../utils/dynamic-price-alerts.utils';

type Props = {
  alert: PriceAlert;
};

const DynamicPriceAlertsVolatilitySwitch: React.FC<Props> = ({ alert }) => {
  const [checked, setChecked] = useState<boolean>(alert.status !== 'PAUSED');
  const { togglePriceAlertStatus } = useToggleDynamicPriceAlertStatus();

  useEffect(() => {
    setChecked(alert.status !== 'PAUSED');
  }, [alert.status]);

  const handleOnClick = useCallback(async () => {
    const newChecked = !checked;

    setChecked(newChecked);
    await togglePriceAlertStatus({ ...alert, status: newChecked ? 'PAUSED' : 'WAITING' });
  }, [alert, checked, togglePriceAlertStatus]);

  if (isLimitAlert(alert)) return null;

  return <Switch checked={checked} onClick={handleOnClick} />;
};

export { DynamicPriceAlertsVolatilitySwitch };

import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { CountdownDelay } from '@global-components/CountdownDelay';

import { Asset } from '@shared/api';
import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useFetchAssetAddressesCache } from 'src/lib/assets/hooks/useFetchAssetAddressesCache';

import { AddAddressFormData, TransferModalStep } from '../TransferModal.types';
import { useAddWithdrawalAddress } from '../hooks/useAddWithdrawalAddress';

type Props = {
  asset: Asset;
  addAddressToken: string;
  addAddressFormData: AddAddressFormData;
  setAddAddressToken: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
};

const INITIAL_CODE_TIMEOUT = 60;

const PhoneVerificationStep: React.FC<Props> = observer(
  ({ onClose, setAddAddressToken, asset, addAddressToken, addAddressFormData }) => {
    const { onNextStep } = useContext(StepModalContext);
    const [addingAddress, setAddingAddress] = useState<boolean>(false);
    const [code, setCode] = useState<string>();
    const { addWithdrawalAddress } = useAddWithdrawalAddress({ asset });
    const { t: commonT } = useTranslation('common');
    const { addToastMessage } = UIStore.useUIStore;
    const { invalidateCache } = useFetchAssetAddressesCache();
    const { getMaskedPhoneNumber } = UserStore.useUserStore;

    const onContinue = useCallback(
      async (requestNewToken = false) => {
        if (!code?.length && !requestNewToken) return;

        setAddingAddress(true);
        try {
          const { mfaType, token } = await addWithdrawalAddress(
            addAddressFormData,
            requestNewToken ? undefined : addAddressToken,
            requestNewToken ? undefined : code,
          );

          switch (mfaType) {
            case 'sms': {
              if (requestNewToken) setAddAddressToken(token);
              onNextStep(TransferModalStep.PhoneVerification);
              break;
            }
            case 'email': {
              if (requestNewToken) setAddAddressToken(token);
              invalidateCache();
              onNextStep(TransferModalStep.EmailVerification);
              break;
            }
            default: {
              setAddAddressToken('');
              onClose();
              addToastMessage({ severity: 'success', message: commonT('addAddress.success') });
              break;
            }
          }
        } catch (error: any) {
          setAddAddressToken('');
          addToastMessage({ severity: 'error', message: error?.message || commonT('addAddress.genericError') });
        } finally {
          setAddingAddress(false);
        }
      },
      [
        addAddressFormData,
        addAddressToken,
        addToastMessage,
        addWithdrawalAddress,
        code,
        commonT,
        invalidateCache,
        onClose,
        onNextStep,
        setAddAddressToken,
      ],
    );

    return (
      <StepModal.Step
        onClose={onClose}
        hideActions
        hideBack
        title={commonT('phoneVerification.title')}
        key={TransferModalStep.PhoneVerification}
      >
        <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
          <Body color='secondary'>{commonT('phoneVerification.codeSentTo', { phone: getMaskedPhoneNumber() })}</Body>
          <Body color='secondary'>{commonT('phoneVerification.shareCode')}</Body>
          <FlexLayout spacing={8} direction='column'>
            <Body size='small' weight='emphasis'>
              {commonT('phoneVerification.smsCode')}:<span className='text-color-text-error'>*</span>
            </Body>
            <Input
              className='fs-mask'
              onChange={(e) => setCode(e.target.value)}
              value={code}
              placeholder={commonT('phoneVerification.enterCode')}
            />

            <span>
              <CountdownDelay
                id='mobile-verification-resend-countdown'
                delay={INITIAL_CODE_TIMEOUT}
                delayText={commonT('verification.misc.resendCodeIn')}
                restartText={commonT('verification.misc.resendCode')}
                onTimerRestart={() => onContinue(true)}
              />
            </span>
          </FlexLayout>

          <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
            <Button size='lg' onClick={() => onContinue()} loading={addingAddress} disabled={!code?.length}>
              {commonT('labels.continue')}
            </Button>
          </FlexLayout>
        </FlexLayout>
      </StepModal.Step>
    );
  },
);

export { PhoneVerificationStep };

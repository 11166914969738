/* eslint-disable max-len */
import { Release, ReleaseVersion } from '../whats-new.type';

const swyftxRewardsRelease: Release = {
  imgSrc: '/assets/images/releases/swyftxRewards/overview.png',
  version: ReleaseVersion.SwyftxRewards,
  features: [
    {
      imgSrc: '/assets/images/releases/swyftxRewards/feature-1.png',
      imgHeight: '262px',
      title: 'Introducing Swyftx Rewards',
      descriptions: [
        'Swyftx Rewards lets users receive crypto rewards straight to their wallet by opting in and completing tasks! Keep an eye out for available rewards.',
      ],
    },
    {
      imgSrc: '/assets/images/releases/swyftxRewards/feature-2.png',
      imgHeight: '262px',
      title: 'Get rewarded in 3 easy steps',
      orderedListDescription: true,
      descriptions: [
        'Opt-in to an available reward.',
        'Complete tasks within the allocated time.',
        'Redeem completed reward.',
      ],
    },
    {
      imgSrc: '/assets/images/releases/swyftxRewards/feature-3.png',
      imgHeight: '262px',
      title: 'Clock is ticking!',
      descriptions: ['Remember to complete tasks and redeem rewards before they expire! Best of luck!'],
    },
  ],
};

export { swyftxRewardsRelease };

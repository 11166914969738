import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { NEWS_TILE_TAB_SELECTION, NewsTileTab } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';
import { DashboardContext } from '@routes/Dashboard';

type Props = {
  selectedTab: NewsTileTab;
  setSelectedTab: (option: NewsTileTab) => void;
};

const NewsFeedTileHeader: React.FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const { editMode } = useContext(DashboardContext);
  const { t } = useTranslation('dashboard');
  const { pathname } = useLocation();
  const avo = useAvo();

  const switchTabs = (tab: any) => {
    localStorage.setItem(NEWS_TILE_TAB_SELECTION, tab);
    setSelectedTab(tab);

    avo.switchedNewsTab({
      screen: pathname,
      switchedTo: tab,
    });
  };

  return (
    <Stack direction='row' spacing={2}>
      {Object.values(NewsTileTab).map((key: any) => (
        <Typography
          key={key}
          fontSize='14px'
          fontWeight={600}
          color={selectedTab === key ? 'text.primary' : 'text.disabled'}
          sx={{ cursor: 'pointer', userSelect: 'none' }}
          onClick={() => {
            if (!editMode) switchTabs(key);
          }}
        >
          {t(key)}
        </Typography>
      ))}
    </Stack>
  );
};

export { NewsFeedTileHeader };

// Maps the XState step ID to a title
export const StepTitles = {
  introduction: 'Introduction',
  entityType: 'Select entity type',
  companyInformation: 'Supply information',
  trustInformation: 'Supply information',
  smsfInformation: 'Supply information',
  companyAddMembers: 'Add members',
  individualTrustAddMembers: 'Add members',
  corporateTrustAddMembers: 'Add members',
  smsfAddMembers: 'Add members',
  'smsfAddMembers.addIndividuals': 'Add individuals',
  'smsfAddMembers.addCorporateMembers': 'Add corporate trustees',
  'companyAddMembers.addDirectors': 'Add directors',
  'companyAddMembers.addShareholders': 'Add shareholders',
  'corporateTrustAddMembers.addDirectors': 'Add corporate trustee directors',
  'corporateTrustAddMembers.addBeneficiaries': 'Add all beneficiaries',
  'corporateTrustAddMembers.addShareholders': 'Add corporate trustee shareholders',
  'individualTrustAddMembers.addTrustees': 'Add trustees',
  'individualTrustAddMembers.addBeneficiaries': 'Add beneficiaries',
  uploadDocuments: 'Upload documents',
} as const;

export const getStepSubtitle = (stepId: keyof typeof StepTitles) => {
  switch (stepId) {
    case 'introduction':
      return 'What you need to know';
    default:
      return undefined;
  }
};

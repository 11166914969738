import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { ContentBanner } from '@global-components/ContentBanner';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { useGlobalResolveRestrictions } from '@hooks/useGlobalResolveRestrictions';

import { observer } from 'mobx-react-lite';

export const RestrictedBanner: React.FC = observer(() => {
  const { t } = useTranslation('common', { keyPrefix: 'restrictedBanner' });
  const resolveRestrictions = useGlobalResolveRestrictions();
  const { openModal } = useModal();
  const { isKyc0Required, isKyc1Required } = UserStore.useUserStore;

  const kyc0Required = useMemo(() => isKyc0Required(), [isKyc0Required]);
  const kyc1Required = useMemo(() => isKyc1Required(), [isKyc1Required]);

  const openRestrictedModal = useCallback(() => {
    openModal(Modals.RestrictedModal);
  }, [openModal]);

  const bannerText = useMemo(() => {
    if (kyc1Required) {
      return 'Please complete photo ID verification to enable crypto and fiat withdrawals';
    }
    if (kyc0Required) {
      return 'Crypto withdrawals are restricted until a verification call has been complete. Please contact support';
    }
    return t('subTitle');
  }, [kyc0Required, kyc1Required, t]);

  return (
    <FlexLayout direction='row' className='w-full'>
      <ContentBanner
        subActionLabel={t('subActionLabel')}
        onSubAction={!kyc0Required ? openRestrictedModal : undefined}
        onAction={resolveRestrictions}
        actionLabel={t('actionLabel')}
        description={bannerText}
        title={t('title')}
        icon={
          <Image image='account_restricted' usePalette={false} className='h-64 w-64' alt='account restricted icon' />
        }
      />
    </FlexLayout>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Checkbox, Divider, Input, Stack, Typography } from '@swyftx/react-web-design-system';

import { useCardDepositProvider } from '@hooks/Deposit/useCardDepositProvider';
import { DefaultBanxaLimit, useDepositDescription } from '@hooks/Deposit/useDepositDescription';
import { isValidDepositInput } from '@services/DepositService/DepositService.utils';

type Props = {
  assetCode: string;
  value: string;
  acceptKycSharing: boolean;
  setValue: (val: string) => void;
  setAcceptKycSharing: (acceptKycSharing: boolean) => void;
  error?: string;
};

const CreditCardDepositModalBanxaDetails: React.FC<Props> = ({
  assetCode,
  value,
  acceptKycSharing,
  error,
  setValue,
  setAcceptKycSharing,
}) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation.banxa' });
  const { depositCreditCardText } = useDepositDescription(assetCode);
  const minValue = depositCreditCardText.extraBankAddressDetails?.minimumOrder || DefaultBanxaLimit[assetCode].min;
  const maxValue = depositCreditCardText.extraBankAddressDetails?.maximumOrder || DefaultBanxaLimit[assetCode].max;

  const cardDepositProvider = useCardDepositProvider();

  return (
    <Stack>
      <Typography color='text.secondary' fontSize={14} marginBottom={2}>
        {t('title', { cardDepositProvider })}
      </Typography>
      <Input
        error={!!error}
        label={t('amountToDeposit', { code: assetCode })}
        onChange={(e) => {
          const val = e.target.value;
          if (!isValidDepositInput(val)) {
            return;
          }
          setValue(val);
        }}
        placeholder={t('minMaxInputPlaceholder', {
          minValue,
          maxValue: maxValue.toLocaleString(),
          code: assetCode,
        })}
        required
        type='number'
        value={value}
        InputProps={{
          startAdornment: value && (
            <Typography color='text.primary' fontWeight={400} fontSize={18}>
              $
            </Typography>
          ),
          sx: { fontSize: 18 },
        }}
      />
      {error && (
        <Typography color='error' fontWeight={400} fontSize={12} marginTop={0.5}>
          {error}
        </Typography>
      )}
      <Divider orientation='horizontal' variant='fullWidth' sx={{ marginTop: 3 }} />

      {depositCreditCardText.notes && (
        <>
          <Typography color='text.primary' fontWeight={600} fontSize={16} marginTop={2}>
            {t('notesTitle')}:
          </Typography>

          <ul style={{ paddingLeft: '20px' }}>
            {depositCreditCardText.notes.map((note) => (
              <li key={note}>
                <Typography color='text.secondary' fontWeight={400} fontSize={14}>
                  {note}
                </Typography>
              </li>
            ))}
          </ul>
        </>
      )}

      {depositCreditCardText.label && (
        <Box width='100%' marginTop={3} sx={{ color: 'text.primary', '.MuiTypography-root': { fontSize: 14 } }}>
          <Checkbox
            label={depositCreditCardText.label}
            checked={acceptKycSharing}
            onChange={(_, checked) => setAcceptKycSharing(checked)}
          />
        </Box>
      )}
    </Stack>
  );
};

export { CreditCardDepositModalBanxaDetails };

import { MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from 'react';

export type ReactEvent = ReactTouchEvent | ReactMouseEvent;
export type NativeEvent = TouchEvent | MouseEvent;

export const isNativeEvent = (e: ReactEvent | NativeEvent): e is NativeEvent =>
  (e as NativeEvent).cancelBubble !== undefined;

export const isReactTouchEvent = (e: ReactTouchEvent | ReactMouseEvent): e is ReactTouchEvent =>
  (e as ReactTouchEvent).targetTouches !== undefined;

export const isTouchEvent = (e: TouchEvent | MouseEvent): e is TouchEvent =>
  (e as TouchEvent).targetTouches !== undefined;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DepositFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M7.18244 17.8776H15.0892C15.5034 17.8776 15.8392 18.2133 15.8392 18.6276C15.8392 19.0418 15.5034 19.3776 15.0892 19.3776H5.32227C4.90805 19.3776 4.57227 19.0418 4.57227 18.6276V8.86064C4.57227 8.44643 4.90805 8.11064 5.32227 8.11064C5.73648 8.11064 6.07227 8.44643 6.07227 8.86064V16.8664L17.5628 5.37592C17.8556 5.08303 18.3305 5.08303 18.6234 5.37592C18.9163 5.66881 18.9163 6.14369 18.6234 6.43658L7.18244 17.8776Z'
      fill='currentColor'
    />
  </svg>
);

export { DepositFilled };

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CalendarPicker, CalendarPickerTriggerButton } from '@swyftx/aviary/atoms/CalendarPicker';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import * as Popover from '@radix-ui/react-popover';
import { DateTime } from 'luxon';
import { DayPickerProps } from 'react-day-picker';

type Props = {
  title?: string;
  placeholder?: string;
  value?: DateTime;
  className?: string;
  customTriggerIcon?: React.ReactElement;
  onChange: (newDate?: DateTime) => void;
} & Omit<DayPickerProps, 'selected' | 'setSelected' | 'mode'>;

const SingleDatePicker: React.FC<Props> = ({
  title,
  placeholder = 'DD/MM/YYYY',
  value,
  className,
  customTriggerIcon,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value?.toFormat('yyyy-MM-dd') || '');

  const isXs = useTailwindBreakpoint('xs');

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const date = DateTime.fromISO(e.target.value);
      if (date) {
        onChange(date);
        setInputValue(e.target.value);
      }
    },
    [onChange],
  );

  const onSelectDate = useCallback(
    (e?: Date) => {
      if (e === undefined) {
        setOpen(false);
        return;
      }

      onChange(e ? DateTime.fromJSDate(e) : undefined);
      setOpen(false);
    },
    [onChange],
  );

  useEffect(() => {
    setInputValue(value?.toFormat('yyyy-MM-dd') || '');
  }, [value]);

  const error = useMemo(() => {
    if (!value) return false;
    if (value.isValid == false) return true;

    if (props.fromDate && value < DateTime.fromJSDate(props.fromDate)) return true;
    if (props.toDate && value > DateTime.fromJSDate(props.toDate)) return true;

    return false;
  }, [value, props.fromDate, props.toDate]);

  return (
    <Popover.Root open={open} onOpenChange={(o) => !o && setOpen(o)}>
      <Popover.Trigger className='w-full'>
        <Input
          title={title}
          type='date'
          placeholder={placeholder}
          readOnly={isXs}
          error={error}
          trailing={<CalendarPickerTriggerButton leadingIcon={customTriggerIcon} onClick={() => setOpen(true)} />}
          value={inputValue}
          onChange={onChangeInput}
          onClick={() => isXs && setOpen(true)}
          className='relative cursor-text font-mono'
          containerClassName={className}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <>
          {!isXs && (
            <Popover.Content sideOffset={8} alignOffset={0} align='start' className='z-modal-popover'>
              <Card className='p-8'>
                <CalendarPicker {...props} selected={value?.toJSDate()} onSelect={onSelectDate} mode='single' />
              </Card>
            </Popover.Content>
          )}
          {isXs && (
            <Popover.Content className='z-modal-popover'>
              <Modal
                title={title ? `Select ${title?.toLocaleLowerCase()}` : undefined}
                position='bottom'
                open
                triggerElement={null}
                onClose={() => setOpen(false)}
                className='z-modal-popover px-0 py-16'
                onOpenChange={() => setOpen(false)}
                overlayClassName='z-modal-popover-backdrop'
              >
                <CalendarPicker
                  className='!mt-0'
                  {...props}
                  selected={value?.toJSDate()}
                  onSelect={onSelectDate}
                  mode='single'
                />
              </Modal>
            </Popover.Content>
          )}
        </>
      </Popover.Portal>
    </Popover.Root>
  );
};

export { SingleDatePicker };

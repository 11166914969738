/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Paste, Tick } from '@swyftx/aviary/icons/outlined';
import { Button, Grid, Input, Loading, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import { observer } from 'mobx-react-lite';

import CodeInputProps from './CodeInput.types';

const CodeInput: React.FC<CodeInputProps> = observer(
  ({
    id,
    length,
    error,
    onChange,
    forceDisabled,
    label,
    value,
    hidePasteButton = false,
    hideErrorMessage = false,
    loading = false,
    resendCode,
    codeResent = false,
    resendTime = 0,
  }) => {
    const { t } = useTranslation('login');
    const [code, setCode] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [focused, setFocused] = useState<boolean>(false);
    const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

    const focusInput = useCallback(
      (index: number) => {
        const input: HTMLElement | null = document.getElementById(`${id}-input-${index}`);
        input?.focus();
      },
      [id],
    );

    const clearCode = useCallback(() => {
      const emptyCode = Array(length).fill('');
      setCode(emptyCode);
      onChange(emptyCode);
      focusInput(0);
    }, [length, onChange, focusInput]);

    const getClipboardData = async (e: any) => {
      const copiedText = (e.clipboardData || (window as any).clipboardData).getData('text');
      return copiedText;
    };

    const onPasteCode = async (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const copiedText = await getClipboardData(e);

      if (copiedText.length === length) {
        setCode(copiedText.split(''));
        if (onChange) onChange(copiedText.split(''));
      } else {
        setErrorMessage(t('errors.2FA.invalidLength'));
      }
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
      const newCode = [...code];

      if (e.key === 'Backspace') {
        e.preventDefault();
        const indexAdjustment = 1;

        if (newCode[index] === '' && index > 0) {
          newCode[index - 1] = '';
        } else {
          newCode[index] = '';
        }

        setCode(newCode);
        if (index > 0 || index < length - 1) focusInput(Math.max(0, index - indexAdjustment));
        if (onChange) onChange(newCode);
      } else if (e.key === 'ArrowLeft') {
        // When left arrow key is pressed
        focusInput(Math.max(0, index - 1));
      } else if (e.key === 'ArrowRight') {
        // When right arrow key is pressed
        focusInput(Math.min(length - 1, index + 1));
      }
    };

    const updateCode = (e: any, index: number) => {
      if (loading) return;

      const newCode = [...code];
      const curCode = newCode[index];

      if (!Number.isNaN(Number(e.target.value))) {
        const newValue = e.target.value[e.target.value.length - 1];

        newCode[index] = newValue;

        // Clear next input's code
        if (index < length - 1) {
          newCode[index + 1] = '';
        }

        (() => {
          if (curCode.length && !newValue.length) {
            focusInput(Math.max(0, index - 1));

            return;
          }

          if (index === length - 1) {
            focusInput(index);

            return;
          }

          focusInput(index + 1);
        })();
      } else {
        newCode[index] = '';
      }

      setCode(newCode);

      if (onChange) onChange(newCode);
    };

    const onClickPasteCode = async (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      if (hasClipboardPermission !== 'granted') {
        setErrorMessage(t('errors.2FA.clipboardBlocked'));
      } else {
        const copiedText = await navigator.clipboard.readText();
        if (copiedText.length === length) {
          setCode(copiedText.split(''));
          if (onChange) onChange(copiedText.split(''));
        } else {
          setErrorMessage(t('errors.2FA.invalidLength'));
        }
      }
    };

    // Set initial code
    useEffect(() => {
      const emptyCode = Array(length).fill('');
      setCode(emptyCode);

      window.setTimeout(() => focusInput(0));
    }, [length, focusInput]);

    // When there is an existing value
    useEffect(() => {
      if (value && value.length === length) setCode(value.split(''));
    }, [value, length]);

    // Handle error
    useEffect(() => {
      if (error) {
        setErrorMessage(error);
        clearCode();
      } else {
        setErrorMessage('');
      }
    }, [error]);

    return (
      <>
        {label && (
          <Typography
            id={`${id}-label`}
            marginBottom={3}
            fontWeight={500}
            fontSize={16}
            width='100%'
            textAlign='center'
            color='text.primary'
          >
            {label}
          </Typography>
        )}

        <Box position='relative'>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: { xs: '50px', sm: '60px' },
                zIndex: 1,
              }}
            >
              <Loading id='code-input-loader' size={32} sx={{ color: 'primary.main' }} variant='indeterminate' />
            </Box>
          )}

          <Stack
            PII
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-around'
            spacing={1}
            id={id}
            sx={{ opacity: loading ? 0.1 : 1 }}
          >
            {code.map((c, index) => {
              const i = index;
              return (
                <Box key={`${id}-input-${i}-key`}>
                  <Input
                    id={`${id}-input-${index}`}
                    disabled={forceDisabled}
                    onChange={(e) => updateCode(e, index)}
                    onKeyUpCapture={(e) => onKeyUp(e, index)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onPasteCapture={onPasteCode}
                    placeholder={focused ? '' : '0'}
                    value={code[index]}
                    sx={{
                      fontSize: '22px',
                      fieldset: {
                        border: '1px solid',
                        borderColor: errorMessage ? 'error.main' : 'divider',
                      },
                    }}
                    inputProps={{
                      max: 1,
                      'data-lpignore': 'true',
                      sx: {
                        fontSize: '18px',
                        fontWeight: 500,
                        width: { xs: '50px', sm: '56px' },
                        height: { xs: '50px', sm: '60px' },
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        padding: '0',
                      },
                      autoComplete: 'off', // disable autocomplete and autofill
                      'aria-autocomplete': 'none',
                    }}
                    InputProps={{
                      sx: {
                        width: { xs: '50px', sm: '56px' },
                        height: { xs: '50px', sm: '60px' },
                        textAlign: 'center',
                      },
                    }}
                    onPaste={onPasteCode}
                  />
                </Box>
              );
            })}
          </Stack>
        </Box>

        {errorMessage && !hideErrorMessage && (
          <Typography color='error.main' fontSize={12} textAlign='center' sx={{ marginTop: 1 }}>
            {errorMessage}
          </Typography>
        )}

        {!hidePasteButton && (
          <Grid container alignItems='center' justifyContent='space-between' marginTop={errorMessage ? 2 : 4}>
            <Grid
              item
              xs={12}
              sm={resendCode ? 6 : 12}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              {hasClipboardPermission === 'granted' && (
                <Button color='primary' variant='text' onClick={onClickPasteCode} sx={{ fontSize: 14 }}>
                  <Paste className='mr-4 h-12 w-12 text-color-text-primary' />
                  {t('codeInput.buttonLabels.pasteCode')}
                </Button>
              )}
            </Grid>

            {resendCode && (
              <Grid item xs={12} sm={6} display='flex' alignItems='center' justifyContent='end'>
                {codeResent ? (
                  <Typography color='success.main' fontSize={14} fontWeight={500} display='flex' margin={1}>
                    <Tick className='mr-4 h-10 w-10' />
                    {t('codeInput.buttonLabels.codeSent')}
                  </Typography>
                ) : (
                  <CountdownDelay
                    id={`${id}-countdown`}
                    delay={resendTime}
                    containerProps={{ justifyContent: 'center' }}
                    onTimerRestart={resendCode}
                    delayText={t('codeInput.buttonLabels.resendCodeIn')}
                    restartText={t('codeInput.buttonLabels.resendCode')}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  },
);

export { CodeInput };

import * as React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { AvatarProps, avatarVariants } from './Avatar.styles';
import { Body, Heading } from '../Typography';

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, size = 'md', text, color = 'primary', children, ...props }, ref) => {
    const renderText = () => {
      switch (size) {
        case 'sm':
          return (
            <Body size='small' weight='bold' color='inverse' className='font-medium'>
              {text}
            </Body>
          );
        case 'lg':
          return (
            <Heading size='h4' color='inverse'>
              {text}
            </Heading>
          );
        case 'xl':
          return (
            <Heading size='h3' color='inverse'>
              {text}
            </Heading>
          );
        default:
          return (
            <Body size='medium' weight='bold' color='inverse'>
              {text}
            </Body>
          );
      }
    };
    return (
      <AvatarPrimitive.Root
        ref={ref}
        className={cn(
          'relative flex shrink-0 select-none items-center justify-center overflow-hidden rounded-[100%]',
          avatarVariants({ size, color }),
          className,
        )}
        {...props}
      >
        {children}
        {!children && renderText()}
      </AvatarPrimitive.Root>
    );
  },
);

Avatar.displayName = AvatarPrimitive.Root.displayName;

export { Avatar };

import { EditInSquare, Locked, Show, Trade } from '@swyftx/aviary/icons/outlined';
import { pxToRem } from '@swyftx/react-web-design-system';

import { APIKeyScope, APIKeyScopeItem } from '@shared/api';
import { GroupedScope } from '@shared/api/lib/helpers/apiKeys.helper';
import { EntityMemberNotificationStatusEnum, EntityTypeEnum, GreenIdStatusEnum } from '@shared/enums';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';
import entityHelpers, { MemberPermissionType } from '@shared/utils/lib/entityHelpers';

export const mockEntityMember: EntityMember = {
  country: 'Australia',
  email: 'test@test.com',
  entityType: EntityTypeEnum.PERSONAL,
  expiry: new Date('2024').getTime(),
  isExpired: false,
  name: 'John Doe',
  notificationEnabled: EntityMemberNotificationStatusEnum.Enabled,
  uuid: 'test',
  parentUuid: 'testing',
  stakeholder: 0,
  scope: 'Admin',
  verified: GreenIdStatusEnum.VERIFIED,
};

export const getBooleanNotificationFromStatusEnum = (notificationEnabled?: EntityMemberNotificationStatusEnum) => {
  switch (notificationEnabled) {
    case EntityMemberNotificationStatusEnum.Disabled:
      return false;
    case EntityMemberNotificationStatusEnum.Enabled:
      return true;
    default:
      return false;
  }
};

export const formatPermissionKeys = (apiScopes: APIKeyScope): GroupedScope => {
  const localScopes: GroupedScope = {};

  const createSelectable = (item: APIKeyScopeItem) => ({ ...item, selected: false, expanded: false, locked: false });

  for (const [key, value] of Object.entries(apiScopes)) {
    const group = key.split('.')[1];
    if (group && group !== 'special') {
      if (!localScopes[group]) {
        localScopes[group] = {
          parent: createSelectable(value),
          children: [],
        };
      } else {
        localScopes[group].children.push(createSelectable(value));
      }
    }
  }

  return localScopes;
};

export const processPermissionScopes = (
  localScopes: GroupedScope,
  newScopes: string[],
  setScopes: (scopes: GroupedScope) => void,
) => {
  // set all scopes
  for (const scope of newScopes) {
    const [parent, child] = scope.split('.');

    const group = localScopes[parent];

    if (!child && group?.parent) {
      // if no child set all as active
      group.parent.selected = true;
      for (const currentChild of group.children) {
        currentChild.selected = true;
      }
    } else if (group) {
      const childScope = group.children.find((currentChild) => currentChild.key === `app.${scope}`);
      if (childScope) childScope.selected = true;
    }
  }

  // set all required read scopes
  entityHelpers.checkReadScopes(localScopes);

  // check dependants
  entityHelpers.checkDependants(localScopes);

  // update state
  setScopes(localScopes);
};

export const setEntityPermissionPreset = (
  localScopes: GroupedScope,
  type: MemberPermissionType,
  setScopes: (scopes: GroupedScope) => void,
) => {
  // set all to unselected
  entityHelpers.toggleAll(localScopes, false, setScopes);

  const preset = entityHelpers.presets.find((currentPreset) => currentPreset.name === type);

  if (preset) {
    processPermissionScopes(localScopes, preset.scopes, setScopes);
  }
};

const iconProps = {
  width: pxToRem(32),
  height: pxToRem(32),
};

export const entityPermissionTypeDetails = [
  {
    type: MemberPermissionType.TradeOnly,
    icon: <Trade {...iconProps} />,
    title: 'entities.configureMemberModal.permissions.selectionBox.tradeOnly.title',
    description: 'entities.configureMemberModal.permissions.selectionBox.tradeOnly.description',
  },
  {
    type: MemberPermissionType.ReadOnly,
    icon: <Show {...iconProps} />,
    title: 'entities.configureMemberModal.permissions.selectionBox.readOnly.title',
    description: 'entities.configureMemberModal.permissions.selectionBox.readOnly.description',
  },
  {
    type: MemberPermissionType.Admin,
    icon: <Locked {...iconProps} />,
    title: 'entities.configureMemberModal.permissions.selectionBox.admin.title',
    description: 'entities.configureMemberModal.permissions.selectionBox.admin.description',
  },
  {
    type: MemberPermissionType.Custom,
    icon: <EditInSquare {...iconProps} />,
    title: 'entities.configureMemberModal.permissions.selectionBox.custom.title',
    description: 'entities.configureMemberModal.permissions.selectionBox.custom.description',
  },
] as const;

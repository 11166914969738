import React, { PropsWithChildren, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useTradeFeesData } from '@routes/Profile/subroutes/TradeFees/useTradeFeesData';

import { SwyftxProFeeTiersItem } from './SwyftxProFeeTiersItem';
import { SwyftxProFeeTiersTable } from './SwyftxProFeeTiersTable';
import { useSwyftxPro } from '../../hooks/useSwyftxPro';

const SwyftxProFeeTiersModal: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [open, setOpen] = useState<boolean>();
  const { thirtyDayVolume, userFeeStatus: feeStatus, updatesIn, feeTiers, userTierStatus } = useTradeFeesData();
  const { isSwyftxPro } = useSwyftxPro();
  const countryAsset = useCountryAsset();
  const isXs = useTailwindBreakpoint('xs');

  if (!isSwyftxPro) return <>{children}</>;

  return (
    <Modal
      open={open}
      triggerElement={children}
      title='Swyftx Pro fee tiers'
      showCloseButton
      className='!max-w-[42.5rem]'
      position={isXs ? 'bottom' : 'center'}
      onOpenChange={setOpen}
    >
      <FlexLayout spacing={24} direction='column' className='p-16 pt-0'>
        <Body>Fee tiers are determined by your 30-Day Trading Volume and are recalculated every 60 minutes.</Body>
        <FlexLayout
          alignItems='center'
          justifyContent='space-between'
          spacing={16}
          className='flex-wrap sm:flex-nowrap'
        >
          {userTierStatus?.currentTier?.label && (
            <SwyftxProFeeTiersItem icon={<></>} title='Current Tier' value={userTierStatus?.currentTier?.label} />
          )}
          <SwyftxProFeeTiersItem
            icon={<></>}
            title='30-Day Volume'
            value={formatCurrency(thirtyDayVolume?.tradeVolume, countryAsset, { appendCode: true, hideCode: true })}
          />
          <SwyftxProFeeTiersItem icon={<></>} title='Current Trade Fee' value={`${feeStatus?.feePercentage}%`} />
        </FlexLayout>
        <SwyftxProFeeTiersTable feeTiers={feeTiers} currentTier={userTierStatus?.currentTier?.tier} />

        <Body color='secondary'>Updates in {updatesIn}</Body>
      </FlexLayout>
    </Modal>
  );
};

export { SwyftxProFeeTiersModal };

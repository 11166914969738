import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { formatCurrency } from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

interface Props {
  side: 'bid' | 'ask';
  selectedAsset: Asset;
  baseAsset?: Asset;
  dailyPriceChange?: string;
  className?: string;
}

export const AssetPrice: React.FC<Props> = observer((props) => {
  const { side, baseAsset, selectedAsset, dailyPriceChange, className } = props;
  const { convertRate, getRate } = RatesStore.useRatesStore;
  const isXs = useTailwindBreakpoint('xs');

  const rate = getRate(selectedAsset);

  const price = useMemo(() => (side === 'bid' ? rate.bidPrice : rate.askPrice), [rate.askPrice, rate.bidPrice, side]);

  const fetchAssetToConvertTo = useMemo(() => {
    if (baseAsset?.code === 'AUD') {
      const usdAsset = assetService.getAssetByCode('USD')!;
      return usdAsset;
    }
    const audAsset = assetService.getAssetByCode('AUD')!;
    return audAsset;
  }, [baseAsset?.code]);

  const getPriceChangeColor = useMemo(() => {
    const biggedDPC = Big(dailyPriceChange);
    if (biggedDPC.gt(0)) {
      return 'success';
    }
    if (biggedDPC.lt(0)) {
      return 'error';
    }
    return 'secondary';
  }, [dailyPriceChange]);

  const convertedRate = useMemo(
    () => convertRate(selectedAsset, fetchAssetToConvertTo, 1, side === 'bid' ? 'bidPrice' : 'askPrice'),
    [convertRate, selectedAsset, fetchAssetToConvertTo, side],
  );

  if (isXs)
    return (
      <FlexLayout direction='column' className={cn('w-1/2', className)} spacing={4}>
        <Numeric size='xlarge' weight='bold'>
          {formatCurrency(price)}
        </Numeric>
        <Numeric size='large' color='secondary'>
          {`${baseAsset?.code}/${selectedAsset.code}`}
        </Numeric>
        <Numeric size='large' color={getPriceChangeColor}>{`${dailyPriceChange || '-'}% (24hr)`}</Numeric>
      </FlexLayout>
    );

  return (
    <FlexLayout direction='column' spacing={0} className={cn('justify-center', className)}>
      <FlexLayout direction='row' spacing={4}>
        <Numeric size='large' weight='emphasis' className='!line-height-20'>
          {formatCurrency(price, { isFiat: baseAsset?.assetType === AssetType.Fiat })}
        </Numeric>
        <Numeric size='large' color='secondary' className='!line-height-20'>
          {baseAsset?.code}
        </Numeric>
      </FlexLayout>
      <FlexLayout direction='row' spacing={4}>
        <Numeric size='small' color='secondary'>
          {`~${formatCurrency(convertedRate.toFixed(8), {
            isFiat: fetchAssetToConvertTo.assetType === AssetType.Fiat,
          })}`}{' '}
          {fetchAssetToConvertTo.code}
        </Numeric>
      </FlexLayout>
    </FlexLayout>
  );
});

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as Sentry from '@sentry/react';

import { HeaderAlignment } from './PageHeader.types';

type Props = {
  showCompact?: boolean;
  offset?: boolean;
  position?: 'sticky' | 'relative';
  alignment?: HeaderAlignment;
  className?: string;
};

const PageHeaderBase: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  position,
  offset,
  children,
  showCompact = false,
}) => (
  <div
    data-container='page-header'
    className={cn(
      `transition-opacity duration-200 ${
        showCompact ? 'opacity-0' : 'opacity-1'
      } bg-page-header @container ${position} p-32 pt-16 @sm:pt-24  @md:pt-48 ${offset ? '!pb-[196px]' : ''} top-0`,
      className,
    )}
  >
    {children}
  </div>
);

PageHeaderBase.displayName = 'PageHeaderBase';

export const ProfiledPageHeaderBase = Sentry.withProfiler(PageHeaderBase);

export { ProfiledPageHeaderBase as PageHeaderBase };

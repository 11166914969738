import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const SingleWalletFiatDepositVerify: React.FC = () => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('wallet', { keyPrefix: 'singleWalletFiatDeposit' });

  const navigateToVerify = () => {
    navigate(NavigationURLs.Onboarding);
  };

  return (
    <FlexLayout direction='column' spacing={16}>
      <Notification severity='warning' title={t('labels.verify')}>
        <Body size='small'>{t('labels.verifyDescription')}</Body>
      </Notification>
      <Button variant='filled' color='primary' onClick={navigateToVerify}>
        {t('buttonLabels.verifyNow')}
      </Button>
    </FlexLayout>
  );
};

export { SingleWalletFiatDepositVerify };

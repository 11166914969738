import { useCallback, useContext } from 'react';
import { NavigateOptions, useLocation, useNavigate, useParams } from 'react-router-dom';

import { NavigationURLs } from 'src/lib/navigation/types';

import { NavigationContext } from '../context';

enum CrumbAction {
  Push,
  Remove,
  Clear,
}

type NavigateOptionsExtended = {
  breadcrumbsAction?: CrumbAction;
  pathParams?: object;
} & NavigateOptions;

const useNavigateRoute = () => {
  const { hasExitIntent, showExitIntent } = useContext(NavigationContext);
  const location = useLocation();
  const domNavigate = useNavigate();
  const params = useParams();

  const parsePath = (path: NavigationURLs, options?: NavigateOptionsExtended) => {
    const pathParamsObject = ((options?.pathParams || {}) as {}) || {};
    let pathParsed = path as string;

    if (pathParamsObject !== undefined)
      Object.keys(pathParamsObject).forEach((p) => {
        pathParsed = pathParsed.replace(`:${p}`, (pathParamsObject as any)[p]);
      });

    return pathParsed;
  };

  // TODO - Add path params type validation based on the path passed
  const navigate = useCallback(
    (path: NavigationURLs, options?: NavigateOptionsExtended, ignoreExitIntentCheck?: boolean) => {
      if (hasExitIntent && !ignoreExitIntentCheck) {
        showExitIntent(path);
        return;
      }

      const pathParsed = parsePath(path, options);
      domNavigate(pathParsed, options);
    },
    [domNavigate, hasExitIntent, showExitIntent],
  );

  const navigateByIndex = useCallback(
    (index: number) => {
      domNavigate(index);
    },
    [domNavigate],
  );

  const isRouteActive = useCallback(
    (path: NavigationURLs, options?: NavigateOptionsExtended) => location.pathname.includes(parsePath(path, options)),
    [location.pathname],
  );

  return {
    location,
    parsePath,
    navigate,
    navigateByIndex,
    isRouteActive,
    params,
  };
};

export { useNavigateRoute };

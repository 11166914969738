import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { cn } from '@shared/utils/lib/ui';

import { RegisterContext } from '@routes/Register/Register.context';

import { observer } from 'mobx-react-lite';

const RegisterProgressBar: React.FC = observer(() => {
  const { registerStepsConfig, registerStep } = useContext(RegisterContext);

  return (
    <FlexLayout
      direction='column'
      alignItems='center'
      justifyContent='center'
      className='mt-16 w-full'
      id='registerProgressBar.container'
    >
      <FlexLayout direction='row' className='w-full' justifyContent='space-between' spacing={4}>
        {Array.from({ length: registerStepsConfig.length }).map((x, index) => (
          <div
            key={`progress-step-${index + 1}`}
            className={cn(
              registerStep >= index ? 'bg-color-background-primary' : 'bg-color-background-surface-disabled',
              'h-8 w-full rounded-8',
            )}
          />
        ))}
      </FlexLayout>
    </FlexLayout>
  );
});

RegisterProgressBar.displayName = 'RegisterProgressBar';

export { RegisterProgressBar };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Box, { BoxProps } from '@mui/material/Box';

import { Image } from '@swyftx/aviary/atoms/Image';

import { ContentBanner } from '@global-components/ContentBanner';
import { ContentBannerProps } from '@global-components/ContentBanner/ContentBanner';

import { UserStore } from '@shared/store';

import {
  isLocked,
  isValidCountry,
} from '@Profile/subroutes/Verification/components/VerificationMethod/VerifyIdentity/VerifyIdentity.util';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  containerSx?: BoxProps['sx'];
  bannerCardSx?: ContentBannerProps['cardSx'];
  onActionOverride?: () => void;
};

const OnboardingBanner: React.FC<Props> = observer(({ containerSx, bannerCardSx }) => {
  const { isNZ, userProfile, userHasCompletedOnboarding } = UserStore.useUserStore;
  const userCountry = userProfile?.address?.country;
  const { t } = useTranslation('common', { keyPrefix: isNZ() ? 'nzUnverifiedBanner' : 'unverifiedBanner' });
  const navigate = useNavigate();
  const { show } = useIntercom();
  const locked: boolean = isLocked(userProfile);
  const completedOnboarding = userHasCompletedOnboarding();

  const handleVerifyNow = () => {
    navigate(NavigationURLs.Onboarding);
  };

  // User has completed onboarding, however their email or phone number is unverified
  if (locked && completedOnboarding) {
    return null;
  }

  const bannerIcon = <Image image='verification_banner' className='h-64 w-64' usePalette />;

  return (
    <Box
      width='100%'
      sx={{
        ...containerSx,
      }}
    >
      <ContentBanner
        onAction={handleVerifyNow}
        onSubAction={() => show()}
        actionLabel={isValidCountry(userCountry) ? t('actionLabel') : undefined}
        subActionLabel={t('contactSupport')}
        description={t('subTitle')}
        cardSx={bannerCardSx}
        title={t('title')}
        icon={bannerIcon}
      />
    </Box>
  );
});

OnboardingBanner.displayName = 'OnboardingBanner';

export { OnboardingBanner };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TrendLineUp: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M3.40198 21.1747C3.19735 21.5348 2.73952 21.6609 2.37938 21.4563C2.01924 21.2517 1.89317 20.7938 2.09779 20.4337L7.47248 10.9742C7.58036 10.7844 7.76565 10.6509 7.97993 10.6088C8.19422 10.5667 8.41622 10.6201 8.58794 10.755L13.8577 14.8955L18.8147 4.02823L17.5692 4.48383C17.1801 4.62613 16.7494 4.42613 16.6071 4.03713C16.4648 3.64812 16.6648 3.21742 17.0538 3.07512L19.8686 2.04548C20.2576 1.90319 20.6883 2.10318 20.8306 2.49219L21.9539 5.56284C22.0962 5.95185 21.8962 6.38255 21.5072 6.52485C21.1182 6.66715 20.6875 6.46715 20.5452 6.07815L20.0926 4.84099L14.8266 16.3857C14.7311 16.595 14.5449 16.7489 14.3214 16.8033C14.0978 16.8576 13.8617 16.8063 13.6809 16.6642L8.34627 12.4728L3.40198 21.1747Z'
      fill='currentColor'
    />
  </svg>
);

export { TrendLineUp };

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { useOwnedAssets } from '@hooks/Assets/useOwnedAssets';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedAssets: Map<string, Asset>;
  setSelectedAssets: (selectedAssets: Map<string, Asset>) => void;
};

const WalletPerformanceDropdown: React.FC<Props> = observer(({ selectedAssets, setSelectedAssets }) => {
  const ownedAssets = useOwnedAssets();

  const handleAssetClicked = (assetId: string, asset: Asset, selected: boolean) => {
    // Remove if selected, add if unselected
    if (!selected) {
      setSelectedAssets(new Map(selectedAssets.set(assetId, asset)));
    } else {
      const selectedAssetsBuff = new Map(selectedAssets);
      selectedAssetsBuff.delete(assetId);
      setSelectedAssets(selectedAssetsBuff);
    }
  };

  const label = (() => {
    const values = Array.from(selectedAssets.values());
    if (values.length > 0) {
      if (values.length === 1) {
        return values[0].name;
      }

      return `${values.length} assets`;
    }
    return 'Select an asset to overlay';
  })();

  return (
    <Menu
      portalClassName='max-h-[20rem]'
      triggerContent={
        <Card className='min-w-[16rem] cursor-pointer p-12 hover:bg-color-background-surface-hover'>
          <FlexLayout
            justifyContent='space-between'
            className='min-w-[7rem]'
            direction='row'
            alignItems='center'
            spacing={16}
          >
            <Body color='primary'>{label}</Body>
            <ArrowChevronDown className='h-20 w-20' />
          </FlexLayout>
        </Card>
      }
    >
      {ownedAssets.map((asset) => {
        const selected = Boolean(selectedAssets.get(asset.id.toString()));
        const assetId = asset.id.toString();
        const disabled = selectedAssets.size >= 3 && !selected;

        return (
          <MenuItem
            preventCloseOnSelect
            key={asset.code}
            text={`\u00A0${asset.code}`}
            icon={<AssetIcon asset={asset} size={18} />}
            disabled={disabled}
            action={() => handleAssetClicked(assetId, asset, selected)}
            endAdornment={<Checkbox checked={selected} disabled={disabled} />}
          />
        );
      })}
    </Menu>
  );
});

WalletPerformanceDropdown.displayName = 'WalletPerformanceDropdown';

export { WalletPerformanceDropdown };

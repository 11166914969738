import { useEffect, useState } from 'react';

import { api, Asset } from '@shared/api';
import { Candle, ChartsMultiBarsQuery, MultiBarParams, Resolution, Side } from '@shared/api/@types/charts';
import { TimesEnum } from '@shared/enums';

import { DateTime } from 'luxon';

export const useMultiAssetData = ({
  assets,
  baseAsset,
  side,
  resolution,
}: {
  assets: Asset[];
  baseAsset: string;
  side: Side;
  resolution: Resolution;
}) => {
  const [assetData, setAssetData] = useState<Record<string, Candle[]>>({});

  const fetchData = async () => {
    const formatQuery = (secondaryAssets: string[]): [MultiBarParams, ChartsMultiBarsQuery] => {
      const params: MultiBarParams = {
        primary: baseAsset,
        side,
      };
      const query: ChartsMultiBarsQuery = {
        timeStart: new Date().getTime() - TimesEnum.DAY,
        timeEnd: new Date().getTime(),
        resolution,
        secondary: secondaryAssets,
      };
      return [params, query];
    };

    try {
      const assetsToFetch = assets.filter((a) => !assetData[a.id] && a.code !== baseAsset).map((a) => a.code);
      if (assetsToFetch.length > 0) {
        const [params, query] = formatQuery(assetsToFetch);
        const response = await api.endpoints.getChartMultiBars({ params, query });

        setAssetData((prevData) => ({ ...prevData, ...response.data.candles }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const clearAssetOnHour = () => {
      const now = DateTime.now();
      const nextHour = now.startOf('hour').plus({ hours: 1, seconds: 15 }); // Giving a 15s buffer to make sure candles are written to DB

      const timeUntilNextHour = nextHour.diff(now).as('milliseconds');
      timer = setTimeout(() => {
        setAssetData({});
        fetchData();
        clearAssetOnHour();
      }, timeUntilNextHour);
    };
    clearAssetOnHour();
    return () => clearInterval(timer);
    // TODO: there is some missing logic here, improvement can be made to call fetchData with correct assets if scroll position is in the middle of favourites list
  }, [assets]);

  useEffect(() => {
    fetchData();
  }, [assets]);

  return assetData;
};

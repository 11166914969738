import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { PriceAlert } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useDynamicPriceAlertsAnalytics } from '../../hooks';
import { useFetchTriggerNotifications } from '../../hooks/useFetchTriggerNotifications/useFetchTriggerNotifications';
import { PriceAlertsModal } from '../PriceAlertsModal/PriceAlertsModal';

type Props = {
  secondary?: number;
  variant?: 'compact' | 'full';
};

type WrapperProps = {
  triggers?: number[];
} & Props;

const PriceAlertsButtonWrapper: React.FC<PropsWithChildren<WrapperProps>> = observer(
  ({ variant, secondary, children, triggers }) => {
    const baseAsset = useBaseAsset();

    if (variant === 'full') {
      return (
        <Link to={NavigationURLs.PriceAlerts} state={{ triggers }}>
          {children}
        </Link>
      );
    }

    if (!baseAsset || !secondary) return null;

    return (
      <PriceAlertsModal primary={baseAsset.id} secondary={secondary} triggers={triggers}>
        {children}
      </PriceAlertsModal>
    );
  },
);

const PriceAlertButton: React.FC<Props> = observer(({ secondary, variant = 'full' }) => {
  const { t } = useTranslation('priceAlerts');
  const { data } = useFetchTriggerNotifications(secondary);
  const isCompact = useMemo(() => variant === 'compact', [variant]);
  const { alertsUIClicked } = useDynamicPriceAlertsAnalytics();

  return (
    <PriceAlertsButtonWrapper variant={variant} secondary={secondary} triggers={data}>
      <Button
        disabled={isCompact && !secondary}
        variant='outlined'
        layout={isCompact ? 'icon' : 'default'}
        size={isCompact ? 'md' : 'lg'}
        onClick={alertsUIClicked}
        leadingIcon={
          <FlexLayout className='relative h-full w-full' alignItems='center' justifyContent='center'>
            {data && data.length > 0 && (
              <Chip
                size='sm'
                color={isCompact ? 'destructive' : 'accent'}
                className={cn(
                  'absolute origin-right px-6 py-0',
                  isCompact ? 'right-0 top-0 -translate-y-full translate-x-1/2' : '-top-1/4 left-0 -translate-x-1/2',
                )}
              >
                <Numeric size='xxsmall' color='inverse' weight='emphasis' className='text-right'>
                  {data?.length}
                </Numeric>
              </Chip>
            )}
            <PriceAlert className='h-16 w-16' />
          </FlexLayout>
        }
      >
        {t('buttons.priceAlerts')}
      </Button>
    </PriceAlertsButtonWrapper>
  );
});

export { PriceAlertButton };

import { TabItem } from '@swyftx/aviary/atoms/Tabs';

export type TransactionsTableData = {
  orderType: string;
  date: string;
  amount: string;
  triggerPrice: string;
  total: string;
  status: string;
  actions: string;

  //sm
  orderTypeDate: string;
  valueAmount: string;

  // xs
  orderTypeTitles: string;
  orderTypeInfo: string;
};

export type TransactionsType = 'open' | 'recent' | 'all';

export type TransactionsTab = TabItem<TransactionsType>;

export const defaultTransactionsTabs: TransactionsTab[] = [
  {
    title: 'Recent transactions',
    value: 'recent',
  },
  {
    title: 'Open orders',
    value: 'open',
  },
];

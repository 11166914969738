import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeCandlesHollow: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.0266 5.25C16.4408 5.25 16.7766 5.58579 16.7766 6V9H17.9328C18.4851 9 18.9328 9.44772 18.9328 10V14C18.9328 14.5523 18.4851 15 17.9328 15H16.7766V18C16.7766 18.4142 16.4408 18.75 16.0266 18.75C15.6124 18.75 15.2766 18.4142 15.2766 18V15H14.1203C13.568 15 13.1203 14.5523 13.1203 14V10C13.1203 9.44772 13.568 9 14.1203 9H15.2766V6C15.2766 5.58579 15.6124 5.25 16.0266 5.25ZM14.6203 13.5V10.5H17.4328V13.5H14.6203Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.97266 2.28125C8.38687 2.28125 8.72266 2.61704 8.72266 3.03125L8.72266 6.03125H9.87891C10.4312 6.03125 10.8789 6.47897 10.8789 7.03125V16.9688C10.8789 17.521 10.4312 17.9688 9.87891 17.9688H8.72266L8.72266 20.9688C8.72266 21.383 8.38687 21.7188 7.97266 21.7188C7.55844 21.7188 7.22266 21.383 7.22266 20.9688L7.22266 17.9688H6.06641C5.51412 17.9688 5.06641 17.521 5.06641 16.9688V7.03125C5.06641 6.47897 5.51412 6.03125 6.06641 6.03125H7.22266L7.22266 3.03125C7.22266 2.61704 7.55844 2.28125 7.97266 2.28125ZM6.56641 14.364V12.477L9.37891 9.66447V11.5535C9.36711 11.564 9.35556 11.5748 9.34429 11.5861L6.56641 14.364ZM6.58293 16.4688H9.37891V13.6728L6.58293 16.4688ZM9.37891 7.53125V7.54315L6.56641 10.3557V7.53125H9.37891Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeCandlesHollow };

import { useCallback } from 'react';

import { api, Asset, AssetHistoryItemStatus, SortDirection, SortKey, TransactionType } from '@shared/api';
import { UserStore } from '@shared/store';

import { useInfiniteQuery } from '@tanstack/react-query';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

type Props = {
  asset?: Asset;
  pageLimit?: number;
  sortKey: SortKey;
  sortDirection: SortDirection;
  statuses?: AssetHistoryItemStatus[];
  types?: TransactionType[];
  startDate?: number;
  endDate?: number;
};

const useInfiniteTransactions = ({
  asset,
  pageLimit = 20,
  sortKey,
  sortDirection,
  statuses,
  types,
  startDate,
  endDate,
}: Props) => {
  const { isSwyftxPro } = useSwyftxPro();
  const { userId } = UserStore.useUserStore;

  const fetchTransactions = useCallback(
    async (page = 0) => {
      if (asset) {
        const { data } = await api.endpoints.getAssetTransactionHistory({
          query: {
            page,
            limit: pageLimit,
            sortKey,
            sortDirection,
            startDate,
            endDate,
            type: types?.join(','),
            status: statuses?.join(','),
            showLinks: isSwyftxPro ? true : undefined,
          },
          params: { assetId: asset.id },
        });
        return { transactions: data.items, page: data.items.length > 0 ? page : undefined };
      }
      const { data } = await api.endpoints.getAllTransactionHistory({
        query: {
          page,
          limit: pageLimit,
          sortKey,
          sortDirection,
          type: types?.join(','),
          status: statuses?.join(','),
          startDate,
          endDate,
          showLinks: isSwyftxPro ? true : undefined,
        },
      });

      return { transactions: data.items, page: data.items.length > 0 ? page : undefined };
    },
    [asset, endDate, isSwyftxPro, pageLimit, sortDirection, sortKey, startDate, statuses, types],
  );

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useInfiniteQuery({
    queryKey: [
      'transactions',
      userId,
      asset?.code || 'all',
      statuses?.join(','),
      types?.join(','),
      sortKey,
      sortDirection,
      startDate,
      endDate,
    ],
    queryFn: ({ pageParam = 1 }) => fetchTransactions(pageParam),
    staleTime: Infinity,
    getNextPageParam: (lastPage) => (lastPage.page ? lastPage.page + 1 : undefined),
    getPreviousPageParam: (lastPage) => (lastPage.page ? lastPage.page - 1 : undefined),
  });

  return {
    status,
    transactions: data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  };
};

export { useInfiniteTransactions };

import { useCallback } from 'react';

import * as d3 from 'd3';

import { LineChartData, LineChartDatum } from '../LineChart.types';

type Props = {
  container: d3.Selection<SVGSVGElement | null, unknown, null, undefined>;
  costBasis?: LineChartData;
  valueLine: d3.Line<LineChartDatum>;
  strokeWidth: number;
};

const useBuildCostBasis = ({ container, costBasis = [], valueLine, strokeWidth }: Props) => {
  const buildCostBasis = useCallback(() => {
    container
      .select('#cost-basis')
      .datum(costBasis)
      .attr('fill', 'none')
      .attr('stroke', 'var(--color-background-neutral)')
      .attr('stroke-width', strokeWidth)
      .attr('stroke-dasharray', 4)
      .attr('d', valueLine(costBasis));
  }, [container, costBasis, strokeWidth, valueLine]);

  return {
    buildCostBasis,
  };
};

export { useBuildCostBasis };

import React from 'react';

import { StackProps } from '@mui/material';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

export type TabItemProps = {
  onClick: () => void;
  selected: boolean;
  label: string;
  direction?: StackProps['direction'];
  spacing?: StackProps['spacing'];
  icon?: JSX.Element;
  showIcon?: boolean;
};

export const TransferTabItem: React.FC<TabItemProps> = ({
  selected,
  onClick,
  label,
  direction = 'row',
  spacing = 1,
  icon,
  showIcon,
}) => {
  const containerProps: StackProps = selected
    ? {
        className: 'bg-color-background-surface-primary',
        borderColor: 'divider',
        sx: {
          pointerEvents: 'none',
          transition: '0.15s',
          userSelect: 'none',
        },
      }
    : {
        className: 'bg-color-background-surface-secondary cursor-pointer',
        borderBottom: '1px solid',
        borderColor: 'divider',
        sx: {
          transition: '0.15s',
        },
      };

  return (
    <Stack
      justifyContent='center'
      direction={direction}
      alignItems='center'
      onClick={onClick}
      spacing={spacing}
      padding={2}
      flex={1}
      {...containerProps}
    >
      {showIcon ? (
        icon
      ) : (
        <Typography color={selected ? 'primary' : 'text.secondary'} fontSize={pxToRem(14)} fontWeight={500}>
          {label}
        </Typography>
      )}
    </Stack>
  );
};

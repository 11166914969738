import { useMemo } from 'react';

import { SortDirection } from '@shared/api';

import { DateTime } from 'luxon';

import { useFetchDynamicPriceAlerts } from './useFetchDynamicPriceAlerts';
import { useFetchDynamicTriggeredPriceAlerts } from './useFetchDynamicTriggeredPriceAlerts';
import { PriceAlert, PriceAlertTriggerType, TriggeredPriceAlert } from '../dynamicPriceAlerts.types';
import { getGroupByTitle } from '../utils/dynamic-price-alerts.utils';

type Props = {
  secondary?: number | number[];
  filterTypes?: PriceAlertTriggerType[];
  dateSort?: SortDirection;
};

const TRIGGER_LIMIT = 50;

const useDynamicPriceAlerts = ({ secondary, dateSort, filterTypes }: Props = {}) => {
  const { data: alerts, isFetching: isFetchingAlerts } = useFetchDynamicPriceAlerts({ secondary });

  const {
    data: alertTriggers,
    isFetching: isFetchingTriggeredAlerts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useFetchDynamicTriggeredPriceAlerts({
    secondary,
    type: filterTypes?.length === 1 ? filterTypes[0] : undefined,
    limit: TRIGGER_LIMIT,
  });

  const activeAlerts = useMemo(() => {
    if (!alerts) return [];

    return alerts
      .filter((d: PriceAlert) => {
        if (d.status === 'TRIGGERED') return false;

        if (filterTypes && filterTypes?.length) {
          if (filterTypes.findIndex((t) => t === d.type) === -1) return false;
        }

        return true;
      })
      .sort((a, b) => {
        if (dateSort === SortDirection.ASCENDING) return a.dateUpdated - b.dateUpdated;
        return b.dateUpdated - a.dateUpdated;
      });
  }, [alerts, dateSort, filterTypes]);

  const triggeredAlerts = useMemo(() => {
    if (!alertTriggers) return [];

    // Sort data by last updated
    return alertTriggers.sort((a, b) => {
      if (dateSort === SortDirection.ASCENDING) return a.dateTriggered - b.dateTriggered;
      return b.dateTriggered - a.dateTriggered;
    });
  }, [alertTriggers, dateSort]);

  const groupedTriggerAlerts = useMemo(() => {
    const groupedAlerts: { [date: string]: TriggeredPriceAlert[] } = {};

    triggeredAlerts
      .sort((a, b) => {
        if (dateSort === SortDirection.ASCENDING) return a.dateTriggered - b.dateTriggered;
        return b.dateTriggered - a.dateTriggered;
      })
      ?.forEach((d) => {
        const key = getGroupByTitle(DateTime.fromMillis(d.dateTriggered));
        if (!(key in groupedAlerts)) groupedAlerts[key] = [];
        groupedAlerts[key].push(d);
      });

    return groupedAlerts;
  }, [dateSort, triggeredAlerts]);

  const hasNoAlerts = useMemo(() => alerts?.length === 0 && alertTriggers?.length === 0, [alerts, alertTriggers]);

  return {
    alerts,
    activeAlerts,
    triggeredAlerts,
    groupedTriggerAlerts,
    hasNoAlerts,
    isFetchingAlerts,
    isFetchingTriggeredAlerts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  };
};

export { useDynamicPriceAlerts };

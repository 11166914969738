import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@swyftx/aviary/atoms/Input';
import { Label } from '@swyftx/aviary/atoms/Label';
import { Typography, Button } from '@swyftx/react-web-design-system';

import { api } from '@shared/api';
import { Big } from '@shared/safe-big';

import { z } from 'zod';

import { StripeElements } from './DepositFundsStripeElements';

const amountAfterFee = (amount: Big, fee: Big): string =>
  amount.minus(amount.times(fee.div(100) ?? 1).round(18, 0)).toFixed(2);

const schema = z.coerce.number().min(30).max(15000);

const Stripe: React.FC<{ onSuccess: () => void; onFailure?: () => void }> = ({ onSuccess, onFailure }) => {
  const { t } = useTranslation('onboarding');
  const [step, setStep] = useState(0);
  const [amount, setAmount] = useState('');
  const [amountToSubmit, setAmountToSubmit] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [onSubmit, setOnSubmit] = useState<(() => void) | null>(null);
  const [isValid, setIsValid] = useState(false);

  const [stripeData, setStripeData] = useState({
    clientSecret: '',
    publicKey: '',
    feeAmount: Big(1.99),
    feePercentage: Big(0),
  });

  const fetchData = useCallback(async () => {
    const { data } = await api.endpoints.generateStripePayment({
      data: { data: { amount: amountToSubmit, assetId: 1 } },
    });

    setStripeData((prevState) => ({
      ...prevState,
      clientSecret: data.data.clientSecret,
      publicKey: data.data.publishableKey,
      feeAmount: Big(data.data.fee?.amount ?? 1.99),
    }));
  }, [amountToSubmit]);

  const fetchStripeMetadata = useCallback(async () => {
    const { data: metadata } = await api.endpoints.getStripeMetadata({
      params: { assetId: 1 },
    });

    setStripeData((prevState) => ({
      ...prevState,
      feePercentage: Big(metadata.feePercentage ?? 0),
    }));
  }, []);

  useEffect(() => {
    fetchData();
    fetchStripeMetadata();
  }, [fetchData, fetchStripeMetadata]);

  const handleNextStripePage = () => {
    setStep(1);
    setAmountToSubmit(amount);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  if (step === 0) {
    return (
      <div className='py-12'>
        <Typography color='text.secondary' marginBottom={3}>
          {t('steps.depositFunds.option.stripe.description')}
        </Typography>
        <div>
          <Label htmlFor='stripeAmount' className='text-sm mb-4 block font-medium dark:text-white'>
            {t('steps.depositFunds.option.stripe.amountToDeposit')}
          </Label>
          <div className='relative'>
            <Input
              type='text'
              schema={schema}
              value={amount}
              inputMode='decimal'
              id='stripeAmount'
              name='stripeAmount'
              className='text-sm block w-full rounded-md border-none px-16 py-12 pl-36 pr-64 focus:z-10'
              placeholder='0.00'
              onChange={handleChange}
              onValidityChange={setIsValid}
            />
            <div className='pointer-events-none absolute inset-y-0 left-0 z-20 flex items-center pl-16'>
              <span className='text-gray-500'>$</span>
            </div>
            <div className='pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center pr-16'>
              <span className='text-gray-500'>AUD</span>
            </div>
          </div>
          <div className='pr-16'>
            <span className='text-gray-500'>{t('steps.depositFunds.option.stripe.amountToRecieve')}: </span>
            <span className='text-color-text-accent'>
              ~${amount ? amountAfterFee(Big(amount), Big(stripeData?.feePercentage)) : '0.00'}
            </span>
          </div>
        </div>
        <br />
        <hr style={{ borderTop: '2px dashed lightGray' }} />
        <ul className='py-16 text-gray-500'>
          <li className='flex items-center justify-between py-6'>
            <div className='flex-grow'>
              <span className='font-normal'>{t('steps.depositFunds.labels.processingTime')}</span>
            </div>
            <div className='text-right'>
              <span>{t('steps.depositFunds.labels.nearInstant')}</span>
            </div>
          </li>
          <li className='flex items-center justify-between py-6'>
            <div className='flex-grow'>
              <span className='font-normal'>{t('steps.depositFunds.labels.depositFee')}</span>
            </div>
            <div className='text-right'>
              <span>{t('steps.depositFunds.option.stripe.fee')}</span>
            </div>
          </li>
        </ul>

        <Button fullWidth variant='contained' disabled={!isValid} onClick={handleNextStripePage}>
          {t('steps.depositFunds.option.stripe.deposited')}
        </Button>
      </div>
    );
  }

  return (
    <div className='py-12'>
      <StripeElements
        inputAmount={amountToSubmit}
        setIsSubmitDisabled={setIsSubmitDisabled}
        onSuccess={onSuccess}
        onFailure={onFailure}
        setOnSubmit={setOnSubmit}
        stripeData={stripeData}
      />
      <br />
      <Button
        fullWidth
        variant='contained'
        disabled={isSubmitDisabled}
        onClick={(event) => {
          event.preventDefault();
          onSubmit?.();
        }}
      >
        {t('steps.depositFunds.option.stripe.confirm')}
      </Button>
    </div>
  );
};

export { Stripe };

import React from 'react';

import { pxToRem, Stack, Typography, TypographyProps } from '@swyftx/react-web-design-system';

import { RequiredAsterisk } from '@global-components/RequiredAsterisk';

const defaultTypographyProps: TypographyProps = {
  fontSize: pxToRem(14),
  fontWeight: 500,
};

type FormInputItemProps = {
  label: string;
  children: JSX.Element;
  labelTypographyProps?: TypographyProps;
  required?: boolean;
  width?: string;
};

export const FormInputItem: React.FC<FormInputItemProps> = ({
  children,
  label,
  labelTypographyProps = defaultTypographyProps,
  width = '50%',
  required,
}) => (
  <Stack spacing={1} width={width}>
    <Typography {...labelTypographyProps}>
      {label}
      {required && <RequiredAsterisk labelTypographyProps={labelTypographyProps} />}
    </Typography>
    {children}
  </Stack>
);

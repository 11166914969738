import { useCallback, useMemo, useState } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Information } from '@swyftx/aviary/icons/outlined';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { BuyButton } from '@global-components/BuySellButtons';

import { Category } from '@shared/api/@types/categories';
import { Big } from '@shared/safe-big';

import { CategoriesListGainersLosers } from '@routes/AssetsList/subroutes/CategoriesList/CategoriesListGainersLosers';

import { RawCategoryIcons } from 'src/context/Categories/Categories.icons';
import { useCategories, useCategoryPerformance, useCategoryRank } from 'src/lib/categories/hooks';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { AssetCategoriesTableData } from '../AssetCategories.types';

type AssetCategoriesHeaderData = { [key in keyof AssetCategoriesTableData]: EnhancedTableHeaderData };

export const useAssetCategoriesTable = (search: string) => {
  const { categories } = useCategories();
  const { getCategoryRank } = useCategoryRank();
  const { getMetrics } = useCategoryPerformance();
  const { getAssetsByCategoryId } = useMarkets();
  const isXs = useTailwindBreakpoint('xs');

  const [sort, setSort] = useState<EnhancedTableSort<AssetCategoriesTableData>>({
    sortKey: 'rank',
    sortDirection: 'ASC',
  });

  const headers: AssetCategoriesHeaderData = {
    rank: {
      title: 'Rank',
      alignment: 'start',
      sortable: true,
      className: 'min-w-[3rem] max-w-[3rem] hidden @sm:table-cell',
    },
    category: {
      title: 'Category',
      alignment: 'start',
      sortable: true,
      className: 'min-w-[9rem] max-w-[9rem] sm:min-w-[15rem] sm:max-w-[15rem]',
    },
    numberOfAssets: {
      title: '# Assets',
      alignment: 'start',
      sortable: true,
      className: 'min-w-[4rem] max-w-[4rem] hidden @sm:table-cell',
    },
    gainersVsLosers: {
      title: 'Gainers vs Losers',
      alignment: 'start',
      sortable: false,
      className: 'win-w-[10rem] max-w-[10rem] hidden @sm:table-cell',
    },
    performanceDaily: {
      title: 'Avg 24h',
      alignment: 'end',
      sortable: true,
      tooltip: 'Average performance of all assets within a category over a rolling 24hr timeframe',
      className: 'min-w-[4rem] max-w-[4rem] hidden @sm:table-cell',
    },
    performanceWeekly: {
      title: 'Avg 7d',
      alignment: 'end',
      sortable: true,
      tooltip: 'Average performance of all assets within a category over a rolling 7 day timeframe',
      className: 'min-w-[4rem] max-w-[4rem] hidden @md:table-cell',
    },
    performanceMonthly: {
      title: 'Avg 30d',
      alignment: 'end',
      sortable: true,
      tooltip: 'Average performance of all assets within a category over a rolling 30 day timeframe',
      className: 'min-w-[4rem] max-w-[4rem] hidden @lg:table-cell',
    },
    marketCap: {
      title: 'Market Cap',
      alignment: 'end',
      sortable: true,
      className: 'hidden @lg:table-cell',
    },
    dailyVolume: {
      title: 'Volume',
      alignment: 'end',
      sortable: true,
      className: 'hidden @sm:table-cell',
    },
    actions: {
      title: 'Actions',
      alignment: 'end',
      sortable: false,
      className: 'hidden @sm:table-cell',
    },
    // Mobile only
    marketCap24h: {
      title: 'Market Cap / 24h %',
      alignment: 'end',
      sortable: false,
      className: 'table-cell @sm:hidden',
    },
  };

  const categoriesSearch = useCallback(
    (category: Category) => {
      const assets = getAssetsByCategoryId(category.id);
      const searchLower = search.toLowerCase();
      const nameLower = category.name.toLowerCase();
      const searchAndAssetsMatch = assets.some(
        (asset) => asset.name.toLowerCase().includes(searchLower) || asset.code.toLowerCase().includes(searchLower),
      );
      return nameLower.includes(searchLower) || searchAndAssetsMatch;
    },
    [getAssetsByCategoryId, search],
  );

  const data: EnhancedTableData<AssetCategoriesTableData>[] = useMemo(
    () =>
      categories
        .filter((c) => getAssetsByCategoryId(c.id).length > 0)
        .filter(categoriesSearch)
        .map((category) => {
          const rank = getCategoryRank(category);
          const { dailyChange, dailyVolume, weeklyChange, monthlyChange, marketCap } = getMetrics(category);
          const assets = getAssetsByCategoryId(category.id);

          return {
            rank: {
              value: rank,
              element: <Numeric>#{category.id}</Numeric>,
            },
            category: {
              value: category.name,
              element: (
                <FlexLayout direction='row' spacing={8} className='items-center'>
                  <FlexLayout direction='row' className='items-center' spacing={12}>
                    <div
                      className='icon-color-text-inverse flex h-22 w-22 items-center rounded-6 p-4'
                      // eslint-disable-next-line no-restricted-syntax
                      style={{ background: category?.colour }}
                    >
                      <div className='flex'>
                        {RawCategoryIcons('!text-color-text-inverse !w-full !h-full')[category.name]}
                      </div>
                    </div>
                    <FlexLayout direction='column' spacing={4}>
                      <FlexLayout direction='row' spacing={8} className='items-center'>
                        <Body size='small'>{category.name}</Body>
                        {!isXs && (
                          <Tooltip title={category.description}>
                            <Information className='h-12 w-12 text-color-text-secondary' />
                          </Tooltip>
                        )}
                      </FlexLayout>
                      <Chip variant='subtle' size='sm' color='secondary' className='flex @sm:hidden'>
                        {assets.length} assets
                      </Chip>
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            numberOfAssets: {
              value: assets.length,
              element: <Numeric>{assets.length}</Numeric>,
            },
            gainersVsLosers: {
              value: category.id,
              element: <CategoriesListGainersLosers assets={assets} />,
            },
            performanceDaily: {
              value: dailyChange,
              element: (
                <FlexLayout alignItems='center' justifyContent='end'>
                  <NumericDataItem data={`${dailyChange}%` || ''} renderIcons={false} size='small' percentage />
                </FlexLayout>
              ),
            },
            performanceWeekly: {
              value: weeklyChange,
              element: (
                <FlexLayout alignItems='center' justifyContent='end'>
                  <NumericDataItem data={`${weeklyChange}%` || ''} renderIcons={false} size='small' percentage />
                </FlexLayout>
              ),
            },
            performanceMonthly: {
              value: monthlyChange,
              element: (
                <FlexLayout alignItems='center' justifyContent='end'>
                  <NumericDataItem data={`${monthlyChange}%` || ''} renderIcons={false} size='small' percentage />
                </FlexLayout>
              ),
            },
            marketCap: {
              value: marketCap,
              element: (
                <FlexLayout alignItems='center' justifyContent='end'>
                  <Numeric className='text-end' size='small'>
                    {marketCap}
                  </Numeric>
                </FlexLayout>
              ),
            },
            dailyVolume: {
              value: dailyVolume,
              element: (
                <FlexLayout alignItems='center' justifyContent='end'>
                  <Numeric className='text-end' size='small'>
                    {marketCap}
                  </Numeric>
                </FlexLayout>
              ),
            },
            actions: {
              value: category,
              element: (
                <BuyButton assets={assets} variant='aviary-subtle' size='sm' buttonVariant='filled' color='primary' />
              ),
            },
            // Mobile only
            marketCap24h: {
              value: marketCap,
              element: (
                <FlexLayout direction='column' spacing={4} className='items-end justify-center'>
                  <Numeric size='small'>{marketCap}</Numeric>
                  <NumericDataItem data={`${dailyChange}%` || ''} renderIcons={false} size='small' percentage />
                </FlexLayout>
              ),
            },
          };
        }),
    [categories, categoriesSearch, getAssetsByCategoryId, getCategoryRank, getMetrics, isXs],
  );

  const sortItems = (
    a: EnhancedTableData<AssetCategoriesTableData>,
    b: EnhancedTableData<AssetCategoriesTableData>,
    sortingBy: EnhancedTableSort<AssetCategoriesTableData>,
  ) => {
    switch (sortingBy.sortKey) {
      case 'category':
        const aVal = a[sortingBy.sortKey].value as string;
        const bVal = b[sortingBy.sortKey].value as string;
        if (sortingBy.sortDirection === 'ASC') return bVal.localeCompare(aVal);
        return aVal.localeCompare(bVal);
      case 'numberOfAssets':
      case 'performanceDaily':
      case 'performanceWeekly':
      case 'performanceMonthly':
      case 'marketCap':
      case 'rank':
      case 'dailyVolume':
        const aValBig = Big(a[sortingBy.sortKey].value as string);
        const bValBig = Big(b[sortingBy.sortKey].value as string);
        if (sortingBy.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
        return bValBig.lt(aValBig) ? -1 : 1;
      case 'actions':
      case 'gainersVsLosers':
      case 'marketCap24h':
        return 1;
      default:
        sortingBy.sortKey satisfies never;
    }
    return 1;
  };

  const filteredData = useMemo(() => data.sort((a, b) => sortItems(a, b, sort)), [data, sort]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<AssetCategoriesTableData>) => {
      const tableData: EnhancedTableData<AssetCategoriesTableData>[] = Object.assign([], filteredData);
      if (!newSort) return tableData;
      setSort(newSort);
      return tableData.sort((a, b) => sortItems(a, b, newSort));
    },
    [filteredData],
  );

  return { headers, sort, onSort, data };
};

import React, { useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { MultiAssetPicker } from '@swyftx/aviary/complex/MultiAssetPickerModal/MultiAssetPicker';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  preSelectedAssetCodes: string[];
  onConfirm: (assets: string[]) => void;
}

export const AutoInvestMobileAssetPickerModal: React.FC<Props> = (props) => {
  const { open, setOpen, preSelectedAssetCodes, onConfirm } = props;

  const handleConfirm = useCallback(
    (assetCodes: string[]) => {
      onConfirm(assetCodes);
      setOpen(false);
    },
    [onConfirm, setOpen],
  );

  return (
    <Modal
      id='auto-invest-multi-asset-picker-modal'
      open={open}
      onClose={() => setOpen(false)}
      title='Select assets'
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      className='sm:!min-w-[500px] sm:!max-w-[500px]'
      position='bottom'
    >
      <FlexLayout direction='column' className='pb-24'>
        <MultiAssetPicker preSelectedAssetCodes={preSelectedAssetCodes} onConfirm={handleConfirm} />
      </FlexLayout>
    </Modal>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowInCircleRight: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M10.4708 14.9392C10.1773 15.2315 10.1763 15.7064 10.4685 15.9999C10.7608 16.2934 11.2357 16.2944 11.5292 16.0022L15.0152 12.5312C15.1565 12.3904 15.236 12.1992 15.236 11.9997C15.236 11.8002 15.1565 11.609 15.0152 11.4682L11.5292 7.99723C11.2357 7.70497 10.7608 7.70599 10.4685 7.99952C10.1763 8.29304 10.1773 8.76791 10.4708 9.06018L13.423 11.9997L10.4708 14.9392Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12C2 17.5233 6.47784 22 12 22C17.5222 22 22 17.5233 22 12C22 6.47779 17.5222 2 12 2C6.47779 2 2 6.47779 2 12ZM12 20.5C7.30616 20.5 3.5 16.6947 3.5 12C3.5 7.30621 7.30621 3.5 12 3.5C16.6938 3.5 20.5 7.30621 20.5 12C20.5 16.6947 16.6938 20.5 12 20.5Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowInCircleRight };

import { useEffect, useRef, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';

import * as Sentry from '@sentry/react';

export const useCountdown = (initialValue: number, interval = 1000, initialDisable = true) => {
  const [countdown, setCountdown] = useState(initialValue);
  const [resendDisabled, setResendDisabled] = useState(initialDisable);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  const startCountdown = () => {
    setResendDisabled(true);
    if (timerIdRef.current) clearInterval(timerIdRef.current);
    timerIdRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          if (timerIdRef.current) clearInterval(timerIdRef.current);
          setResendDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, interval);
  };

  useEffect(
    () => () => {
      if (timerIdRef.current) clearInterval(timerIdRef.current);
    },
    [interval],
  );

  return { countdown, setCountdown, startCountdown, resendDisabled };
};

export const ResendButtonComponent = ({
  countdown,
  sendOtp,
  resendDisabled,
  startCountdown,
}: {
  countdown: number;
  sendOtp: () => void;
  resendDisabled: boolean;
  startCountdown: () => void;
}) => {
  const handleSendOtp = () => {
    sendOtp();
    startCountdown();
  };

  return (
    <Button size='md' variant='ghost' color='primary' disabled={resendDisabled} onClick={handleSendOtp}>
      {countdown > 0 ? `Resend code in ${countdown}s` : 'Resend code'}
    </Button>
  );
};

export const usePasteFromClipboard =
  (...sideEffects: Array<(value: string) => void>) =>
  async () => {
    try {
      const text = await navigator.clipboard.readText();
      const otp = text.trim().slice(0, 6);

      if (otp.length === 6 && /^[0-9]{6}$/.test(otp)) {
        sideEffects.forEach((effect) => effect(otp));
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

import { useCallback, useMemo } from 'react';

import { Category } from '@shared/api/@types/categories';
import { Big } from '@shared/safe-big';

import { useCreateCategoryRowItem } from '@routes/AssetsList/subroutes/CategoriesList/CategoriesList.data';

import { useCategories } from 'src/lib/categories/hooks';

const useCategoryRank = (category?: Category) => {
  const { categories } = useCategories();
  const { createCategoryRowItem } = useCreateCategoryRowItem();

  const getCategoryRank = useCallback(
    (category: Category) => {
      const categoryData = categories.map((c) => createCategoryRowItem(c));
      const allMarketCaps = categoryData.map((cd) => cd.mcap);
      const categoryMarketCap = categoryData.find((cd) => cd.categoryName === category.name)?.mcap;
      if (!categoryMarketCap) return undefined;

      return allMarketCaps.sort((a, b) => (Big(a).lt(b) ? 1 : -1)).indexOf(categoryMarketCap) + 1;
    },
    [categories, createCategoryRowItem],
  );

  const rank = useMemo(() => {
    if (!category) return undefined;
    if (category.id === -1) return 'N/A';

    return getCategoryRank(category);
  }, [getCategoryRank, category]);

  return { rank, getCategoryRank };
};

export { useCategoryRank };

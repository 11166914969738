import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Dashboard: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.75 2C2.7835 2 2 2.7835 2 3.75V12.25C2 13.2165 2.7835 14 3.75 14H9.5C10.4665 14 11.25 13.2165 11.25 12.25V3.75C11.25 2.7835 10.4665 2 9.5 2H3.75ZM3.5 3.75C3.5 3.61193 3.61193 3.5 3.75 3.5H9.5C9.63807 3.5 9.75 3.61193 9.75 3.75V12.25C9.75 12.3881 9.63807 12.5 9.5 12.5H3.75C3.61193 12.5 3.5 12.3881 3.5 12.25V3.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.75 15.5C2.7835 15.5 2 16.2835 2 17.25V20.25C2 21.2165 2.7835 22 3.75 22H9.5C10.4665 22 11.25 21.2165 11.25 20.25V17.25C11.25 16.2835 10.4665 15.5 9.5 15.5H3.75ZM3.5 17.25C3.5 17.1119 3.61193 17 3.75 17H9.5C9.63807 17 9.75 17.1119 9.75 17.25V20.25C9.75 20.3881 9.63807 20.5 9.5 20.5H3.75C3.61193 20.5 3.5 20.3881 3.5 20.25V17.25Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.75 10.75C12.75 9.7835 13.5335 9 14.5 9H20.25C21.2165 9 22 9.7835 22 10.75V20.25C22 21.2165 21.2165 22 20.25 22H14.5C13.5335 22 12.75 21.2165 12.75 20.25V10.75ZM14.5 10.5C14.3619 10.5 14.25 10.6119 14.25 10.75V20.25C14.25 20.3881 14.3619 20.5 14.5 20.5H20.25C20.3881 20.5 20.5 20.3881 20.5 20.25V10.75C20.5 10.6119 20.3881 10.5 20.25 10.5H14.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5 2C13.5335 2 12.75 2.7835 12.75 3.75V5.75C12.75 6.7165 13.5335 7.5 14.5 7.5H20.25C21.2165 7.5 22 6.7165 22 5.75V3.75C22 2.7835 21.2165 2 20.25 2H14.5ZM14.25 3.75C14.25 3.61193 14.3619 3.5 14.5 3.5H20.25C20.3881 3.5 20.5 3.61193 20.5 3.75V5.75C20.5 5.88807 20.3881 6 20.25 6H14.5C14.3619 6 14.25 5.88807 14.25 5.75V3.75Z'
      fill='currentColor'
    />
  </svg>
);

export { Dashboard };

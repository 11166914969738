import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowInCircleLeft: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M13.5292 9.06077C13.8227 8.76851 13.8237 8.29364 13.5315 8.00011C13.2392 7.70659 12.7643 7.70556 12.4708 7.99782L8.98481 11.4688C8.84346 11.6096 8.764 11.8008 8.764 12.0003C8.764 12.1998 8.84346 12.391 8.98481 12.5318L12.4708 16.0028C12.7643 16.295 13.2392 16.294 13.5315 16.0005C13.8237 15.707 13.8227 15.2321 13.5292 14.9398L10.577 12.0003L13.5292 9.06077Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 12C22 6.47673 17.5222 2 12 2C6.47784 2 2 6.47673 2 12C2 17.5222 6.47779 22 12 22C17.5222 22 22 17.5222 22 12ZM12 3.5C16.6938 3.5 20.5 7.30527 20.5 12C20.5 16.6938 16.6938 20.5 12 20.5C7.30621 20.5 3.5 16.6938 3.5 12C3.5 7.30527 7.30616 3.5 12 3.5Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowInCircleLeft };

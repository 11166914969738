import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const StopLossAmount: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.77946 3.375C4.77946 3.09886 4.55559 2.875 4.27946 2.875C4.00326 2.875 3.77946 3.09886 3.77946 3.375V4.43099H3.38942C2.25429 4.43099 1.3335 5.35078 1.3335 6.48636C1.3335 7.62194 2.25429 8.54174 3.38942 8.54174H3.77946V10.653H2.2782C2.00206 10.653 1.7782 10.8769 1.7782 11.153C1.7782 11.4292 2.00206 11.653 2.2782 11.653H3.77946V12.7083C3.77946 12.9845 4.00326 13.2083 4.27946 13.2083C4.55559 13.2083 4.77946 12.9845 4.77946 12.7083V11.653H5.16716C6.30249 11.653 7.22249 10.733 7.22249 9.59767C7.22249 8.46254 6.30276 7.54174 5.16716 7.54174H4.77946V5.43099H6.27836C6.55449 5.43099 6.77836 5.20714 6.77836 4.93099C6.77836 4.65485 6.55449 4.43099 6.27836 4.43099H4.77946V3.375ZM3.77946 5.43099H3.38942C2.80609 5.43099 2.3335 5.90352 2.3335 6.48636C2.3335 7.0692 2.80609 7.54174 3.38942 7.54174H3.77946V5.43099ZM4.77946 8.54174V10.653H5.16716C5.75022 10.653 6.22249 10.1807 6.22249 9.59767C6.22249 9.01434 5.74996 8.54174 5.16716 8.54174H4.77946Z'
      fill='currentColor'
    />
    <path
      d='M12.5165 5.3334C12.5165 5.05726 12.2926 4.8334 12.0165 4.8334C11.7404 4.8334 11.5165 5.05726 11.5165 5.3334V10.1225L10.3792 8.98058C10.1844 8.78491 9.86781 8.78425 9.67214 8.97911C9.47648 9.17397 9.47582 9.49055 9.67068 9.68622L11.6529 11.6767C11.7357 11.7643 11.8498 11.822 11.9773 11.8319C11.9904 11.833 12.0036 11.8335 12.0168 11.8335C12.1498 11.8335 12.2773 11.7805 12.3711 11.6863L14.3626 9.68619C14.5575 9.49051 14.5568 9.17392 14.3611 8.97908C14.1654 8.78424 13.8488 8.78492 13.654 8.98061L12.5165 10.123L12.5165 5.3334Z'
      fill='currentColor'
    />
  </svg>
);

export { StopLossAmount };

import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
  frozen?: boolean;
  showFrozenDivider?: boolean;
  freezeColumns?: boolean;
  insetLeft?: boolean;
  colSpan?: number;
  insetRight?: boolean;
  offsetLeft?: number;
} & Pick<HTMLAttributes<HTMLTableCellElement>, 'onMouseEnter' | 'onMouseLeave' | 'onClick'>;

const TableCell: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  frozen,
  showFrozenDivider = true,
  insetLeft,
  insetRight,
  freezeColumns,
  colSpan,
  offsetLeft,
  ...props
}) => (
  <td
    colSpan={colSpan}
    className={cn(
      `${insetLeft ? '!pl-16 @md:!pl-24' : 'pl-12'}
        ${insetRight ? '!pr-16 @md:!pr-24' : 'pr-12'}
        py-12
        ${freezeColumns ? 'z-10 bg-color-background-surface-primary' : ''}
      ${frozen ? 'sticky' : ''}`,
      className,
    )}
    style={{ left: offsetLeft !== undefined && frozen ? `${offsetLeft}px` : undefined }}
    {...props}
  >
    {children}
    {frozen && showFrozenDivider && freezeColumns && (
      <div className='absolute right-0 top-0 h-full w-[1px] bg-color-border-main' />
    )}
  </td>
);

TableCell.displayName = 'TableCell';

export { TableCell };

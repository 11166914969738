import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Edit: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1867 4.20669L17.5767 5.29669C18.8967 6.33669 19.1767 7.96669 18.2367 9.16669L9.93673 19.7367C9.51673 20.2667 8.88673 20.5867 8.20673 20.5867L5.00673 20.6267C4.65673 20.6267 4.35673 20.3867 4.27673 20.0467L3.55673 16.9367C3.40673 16.2767 3.55673 15.5967 3.97673 15.0567L12.2667 4.48669C13.2067 3.28669 14.8567 3.16669 16.1867 4.20669ZM8.19673 19.0967C8.42673 19.0967 8.62673 18.9967 8.76673 18.8167L15.0367 10.8067L11.4267 7.97669L5.15673 15.9767C5.01673 16.1567 4.96673 16.3767 5.01673 16.5967L5.60673 19.1267L8.19673 19.0967ZM15.9667 9.63669L17.0567 8.24669H17.0667C17.5867 7.58669 17.1967 6.89669 16.6567 6.47669L15.2667 5.38669C14.7467 4.98669 13.9667 4.75669 13.4467 5.41669L12.3567 6.80669L15.9667 9.63669Z'
      fill='currentColor'
    />
    <path
      d='M13.6953 19.1367H20.0753C20.4853 19.1367 20.8253 19.4767 20.8253 19.8867C20.8253 20.2967 20.4853 20.6367 20.0753 20.6367H13.6953C13.2853 20.6367 12.9453 20.2967 12.9453 19.8867C12.9453 19.4767 13.2853 19.1367 13.6953 19.1367Z'
      fill='currentColor'
    />
  </svg>
);

export { Edit };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { cn } from '@shared/utils/lib/ui';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  error: string | null;
}

export const EditOrderFooter: React.FC<Props> = (props) => {
  const { onClose, onConfirm, error } = props;
  const isXs = useTailwindBreakpoint('xs');

  return (
    <FlexLayout
      direction={isXs ? 'column' : 'row'}
      className={cn(isXs ? 'w-full flex-col-reverse' : 'items-center justify-end')}
      spacing={8}
    >
      <Button size='lg' variant='outlined' onClick={onClose}>
        Discard
      </Button>
      <Button size='lg' onClick={onConfirm} disabled={!!error?.length}>
        Update order
      </Button>
    </FlexLayout>
  );
};

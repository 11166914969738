export enum FeeType {
  DISCRETIONARY = 'discretionary fee',
  FEE_TIER_UPGRADE = 'fee tier upgrade',
  PRO_FEE_TIER_UPGRADE = 'pro fee tier upgrade',
}

export type FeeTier = {
  label: string;
  tier: number;
  lowerBound: number;
  upperBound: number | null;
  fee: number;
};

export type FeeTiersResponse = FeeTier[];

export type CurrentUserFeesResponse = {
  timestamp: number;
  fees: {
    label: string;
    feePercentage: number;
    feeType: FeeType;
  }[];
};

export type TradeVolumeResponse = {
  tradeVolume: number;
  timestamp: number;
};

import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import SourceOfWealth from '@SourceOfWealth/SourceOfWealth';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { SwyftxAppShellCustomHeader } from 'src/lib/layout';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SourceOfWealthProvider } from './SourceOfWealth.context';
import { HeaderExitButton } from './components';

const SourceOfWealthRoute: React.FC = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  if (!isFeatureEnabled(AppFeature.SourceOfWealth)) {
    return <Navigate to={NavigationURLs.Dashboard} />;
  }

  return (
    <SwyftxAppShellCustomHeader headerItemOverride={<HeaderExitButton />}>
      <PrivateRoute
        element={
          <SourceOfWealthProvider>
            <SourceOfWealth />
          </SourceOfWealthProvider>
        }
      />
    </SwyftxAppShellCustomHeader>
  );
};

export const SourceOfWealthRoutes: RouteObject[] = [
  {
    path: NavigationURLs.SourceOfWealth,
    element: <SourceOfWealthRoute />,
  },
];

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const CreditCard: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M13.6636 15.4085C13.964 15.1081 13.964 14.6211 13.6636 14.3207C13.3632 14.0203 12.8761 14.0203 12.5757 14.3207L10.7107 16.1857C10.4103 16.4861 10.4103 16.9731 10.7107 17.2735C11.0111 17.5739 11.4982 17.5739 11.7986 17.2735L13.6636 15.4085Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.4202 3.44131C15.5411 2.56213 14.4712 2.04238 13.3506 2.00247C12.2216 1.96227 11.1271 2.41288 10.2254 3.31457L3.89907 9.64105C2.99941 10.5407 2.54964 11.6352 2.58934 12.764C2.62875 13.8847 3.14697 14.9561 4.0268 15.836L4.69988 16.5092C4.71117 16.5221 4.72297 16.5348 4.73527 16.5471C4.74753 16.5593 4.7601 16.5711 4.77295 16.5823L8.0884 19.8986H2.76922C2.34439 19.8986 2 20.243 2 20.6679C2 21.0927 2.34439 21.4371 2.76922 21.4371H20.5988C21.0236 21.4371 21.368 21.0927 21.368 20.6679C21.368 20.243 21.0236 19.8986 20.5988 19.8986H14.5619L20.6851 13.7754C21.5872 12.8732 22.0379 11.7784 21.9975 10.6492C21.9574 9.52844 21.4374 8.45854 20.5583 7.57945L16.4202 3.44131ZM9.25169 18.8863L6.36817 16.002L16.5866 5.7834L19.4705 8.66731C20.1251 9.32192 20.4362 10.0382 20.4601 10.7041C20.4836 11.3616 20.2307 12.054 19.5972 12.6875L13.2721 19.0128C12.6385 19.646 11.946 19.8989 11.2884 19.8754C10.6224 19.8517 9.90623 19.5408 9.25169 18.8863ZM5.11471 14.7482L5.28047 14.914L15.4987 4.69554L15.3324 4.52917C14.6779 3.87464 13.9617 3.56367 13.2958 3.53996C12.6385 3.51655 11.9463 3.76942 11.3132 4.40243L4.98691 10.7289C4.35687 11.359 4.10365 12.0511 4.12682 12.71C4.15028 13.377 4.46083 14.0943 5.11471 14.7482Z'
      fill='currentColor'
    />
  </svg>
);

export { CreditCard };

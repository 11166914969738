import { useCallback, useMemo } from 'react';

import { AssetType } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { assetService, OrderType } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import { TradeData, TradeType, TradeUIData } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

export const useUniversalTradeAsset = (tradeDataKey: string) => {
  const { tradeData, tradeType, tradeUIData, setTradeData, setTradeUIData } = UniversalTradeStore;

  const data = tradeData[tradeDataKey];
  const uiData = tradeUIData[tradeDataKey];

  const {
    from,
    to,
    limit,
    balance,
    amount,
    executedAmount,
    total,
    executedTotal,
    trigger,
    customTrigger,
    fee,
    price,
    slippagePercentage,
    isHighSlippage,
  } = data || {};
  const {
    percentage,
    locked,
    error,
    clientSideError,
    orderStatus,
    loading,
    isOverLowLiquidityThreshold,
    interactedWith,
  } = uiData || {};

  const nonLimit = from === limit ? to : from;
  const fromAsset = assetService.getAsset(from);
  const toAsset = assetService.getAsset(to);
  const limitAsset = assetService.getAsset(limit);
  const nonLimitAsset = assetService.getAsset(nonLimit);
  const baseAssets = assetService.getBaseAssets();

  const triggerFromAsset = useMemo(() => {
    if (!fromAsset || !toAsset || tradeType !== TradeType.OnTrigger) return fromAsset;

    // If both assets we are trading are base assets, use fiat
    if (baseAssets.includes(fromAsset?.id) && baseAssets.includes(toAsset?.id)) {
      if (fromAsset.assetType !== AssetType.Fiat && toAsset.assetType === AssetType.Fiat) return toAsset;
    }

    if (!baseAssets.includes(fromAsset?.id) && baseAssets.includes(toAsset?.id)) {
      return toAsset;
    }

    return fromAsset;
  }, [fromAsset, toAsset, baseAssets, tradeType]);

  const triggerToAsset = useMemo(() => {
    if (!fromAsset || !toAsset || !triggerFromAsset || tradeType !== TradeType.OnTrigger) return toAsset;

    if (fromAsset.id === triggerFromAsset.id) return toAsset;

    return fromAsset;
  }, [fromAsset, triggerFromAsset, toAsset, tradeType]);

  const getNonLimitValue = () => {
    if (!limitAsset || !nonLimitAsset || !balance) return '';

    if (tradeType === TradeType.OnTrigger) {
      if (limit === triggerFromAsset?.id) {
        if (triggerFromAsset?.id === from) {
          return getReceiveAmount();
        }

        return getSpendAmount();
      }

      if (triggerFromAsset?.id === from && limit !== from) {
        return getSpendAmount();
      }

      return getReceiveAmount();
    }

    if (error?.length) {
      if (limit === from) return getReceiveAmount();
      return getSpendAmount();
    }

    if (limit === from) return getReceiveAmount();

    return getSpendAmount();
  };

  const getReceiveAmount = (): string => {
    const priceTrigger = Big(getTrigger());

    if (customTrigger && tradeType === TradeType.OnTrigger) {
      // Early exit if we are still typing a decimal
      if (Number.isNaN(Number(customTrigger))) return '';

      if (limit === triggerFromAsset?.id) {
        if (triggerFromAsset?.id === from) {
          return Big(getSpendAmount()).times(Big(1).div(customTrigger)).toString();
        }

        return balance || '';
      }

      if (triggerFromAsset?.id === from && limit !== from) {
        return Big(getSpendAmount()).times(Big(1).div(customTrigger)).toString();
      }

      return Big(getSpendAmount()).div(Big(1).div(customTrigger)).toString();
    }

    if (error?.length) {
      if (limit === to) return balance || '';
      return Big(getSpendAmount()).div(Big(price)).toString();
    }

    // If we are limiting on the asset we are trading to we want to multiply the quantity by the trigger price
    if (limit === to) {
      const val = executedTotal || amount || '';
      return Big(val).toString();
    }

    if (priceTrigger.eq(0)) return '';

    return Big(executedTotal || amount).toString();
  };

  const getSpendAmount = (removeFee = false): string => {
    if (tradeType === TradeType.OnTrigger) {
      if (limit === triggerFromAsset?.id) {
        if (triggerFromAsset?.id === from) {
          return balance || '';
        }

        return Big(balance)
          .times(Big(1).div(Big(customTrigger)).toFixed())
          .toString();
      }

      if (triggerFromAsset?.id === from && limit !== from) {
        return Big(balance).times(Big(customTrigger)).toString();
      }

      return balance || '';
    }

    if (error?.length) {
      if (limit === from) return balance || '';
      return Big(balance).times(Big(price)).toString();
    }

    // If we are limiting on the asset we are trading to we want to multiply the quantity by the trigger price
    if (removeFee === false) {
      return Big(executedAmount || total).toString() || '';
    }

    const val = Big(amount).times(Big(price));

    return Big(val).round(toAsset?.price_scale, 0).toString();
  };

  const getOrderType = (): OrderType => {
    const baseAssets = assetService.getBaseAssets();
    const isBaseAssetSell = baseAssets.includes(from);
    const isBaseAssetBuy = baseAssets.includes(to);

    // If we have a custom trigger we are performing a Limit or Stop order
    if (customTrigger && tradeType !== TradeType.Instantly) {
      const bigTrigger = Big(trigger);
      const bigCustomTrigger = Big(customTrigger);

      // work out if the custom price is higher than the current exchange rate
      const isHigherCustom = bigCustomTrigger.gt(bigTrigger);

      if (!isBaseAssetSell) {
        return isHigherCustom ? OrderType.TriggerSell : OrderType.StopSell;
      }

      return isHigherCustom ? OrderType.StopBuy : OrderType.TriggerBuy;
    }

    return isBaseAssetBuy && toAsset?.assetType === AssetType.Fiat ? OrderType.MarketSell : OrderType.MarketBuy;
  };

  const getTrigger = () => {
    const orderType: OrderType = getOrderType();

    if (!customTrigger || tradeType === TradeType.Instantly) return trigger;

    const bigCustomTrigger = Big(customTrigger);

    switch (orderType) {
      case OrderType.TriggerSell:
      case OrderType.StopSell:
        return customTrigger.toString();
      case OrderType.TriggerBuy:
      case OrderType.StopBuy:
        return bigCustomTrigger.toString();
      case OrderType.MarketBuy:
      case OrderType.MarketSell:
      default:
        return trigger;
    }
  };

  const othersLocked = useCallback(() => {
    const uiDataValues = Object.values(tradeUIData);

    if (uiDataValues.length === 1) return false;

    return uiDataValues.length - 1 === uiDataValues.filter((d) => d.locked).length;
  }, [tradeUIData]);

  return {
    customTrigger,
    amount,
    price,
    total,
    slippagePercentage,
    isHighSlippage,
    orderStatus,
    interactedWith,
    trigger,
    balance,
    percentage,
    loading,
    locked,
    error,
    clientSideError,
    isOverLowLiquidityThreshold,
    data: tradeData[tradeDataKey] || {},
    setTradeData: (newTradeData: Partial<TradeData>) => setTradeData(from, to, newTradeData),
    setTradeUIData: (newTradeUIData: Partial<TradeUIData>, updateBalances = true) =>
      setTradeUIData(from, to, newTradeUIData, updateBalances),
    getNonLimitValue,
    getReceiveAmount,
    getSpendAmount,
    getTrigger,
    othersLocked,
    nonLimit,
    fromAsset,
    toAsset,
    limitAsset,
    nonLimitAsset,
    fee,
    triggerFromAsset,
    triggerToAsset,
  };
};

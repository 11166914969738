import React, { useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Indicators } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as Popover from '@radix-ui/react-popover';
import { useFullScreenElement } from 'src/lib/utils/hooks/useFullScreenElement';

import { useTradingViewIndicators } from './useTradingViewIndicators';
import { TradingViewHeaderVariant } from '../../../types';

type Props = {
  variant?: TradingViewHeaderVariant;
};

const TradingViewWidgetHeaderIndicators: React.FC<Props> = ({ variant = 'full' }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { items, search, setSearch } = useTradingViewIndicators();
  const isXs = useTailwindBreakpoint('xs');
  const { element } = useFullScreenElement();

  useEffect(() => {
    if (!open) setSearch('');
  }, [open, setSearch]);

  return (
    <>
      {open && isXs && (
        <Modal
          position='bottom'
          open={open}
          onOpenChange={(o) => !o && setOpen(o || false)}
          title='Chart timeframe'
          container={element}
        >
          <FlexLayout direction='column' spacing={0}>
            <div className='p-16 pb-0'>
              <Input
                placeholder='Search for an indicator...'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <FlexLayout direction='column' className='max-h-[25rem] min-w-[22rem] overflow-auto p-16'>
              {items.map((item) =>
                item.hidden ? null : (
                  <ListItem onClick={item.onClick} key={item.text}>
                    <Body>{item.text}</Body>
                  </ListItem>
                ),
              )}
            </FlexLayout>
          </FlexLayout>
        </Modal>
      )}

      <Popover.Root open={open} onOpenChange={setOpen} modal>
        <Popover.Trigger>
          <Button
            leadingIcon={<Indicators width={16} height={16} />}
            variant='ghost'
            size='sm'
            className={cn(open ? 'bg-color-background-surface-selected' : '')}
          >
            {variant === 'full' ? 'Indicators' : undefined}
          </Button>
        </Popover.Trigger>
        {!isXs && (
          <Popover.Portal container={element}>
            <Popover.Content className='z-modal-popover'>
              <Card>
                <FlexLayout direction='column' spacing={0}>
                  <div className='p-16 pb-0'>
                    <Input
                      placeholder='Search for an indicator...'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <FlexLayout direction='column' className='max-h-[25rem] min-w-[22rem] overflow-auto p-16'>
                    {items.map((item) =>
                      item.hidden ? null : (
                        <ListItem onClick={item.onClick} key={item.text}>
                          <Body>{item.text}</Body>
                        </ListItem>
                      ),
                    )}
                  </FlexLayout>
                </FlexLayout>
              </Card>
            </Popover.Content>
          </Popover.Portal>
        )}
      </Popover.Root>
    </>
  );
};

export { TradingViewWidgetHeaderIndicators };

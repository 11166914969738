import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { ShopItem } from '@swyftx/aviary/molecules/ShopItem';

import { ShopInformationComponent } from '@Shop/components';

import { shopItems } from './Shop.data';

const Shop: React.FC = () => (
  <FlexLayout
    direction='row'
    spacing={16}
    justifyContent='space-between'
    className='flex-wrap p-16 @container sm:p-24 sm:pr-0'
  >
    <FlexLayout className='@xs:w-full @sm:w-[calc(66%-8px)]'>
      <Card className='p-24'>
        <FlexLayout direction='column' spacing={32}>
          <FlexLayout direction='column' spacing={16}>
            <Heading size='h4'>Shop with Swyftx</Heading>
            <Separator />
            <Body size='small' color='primary'>
              The Swyftx shop is a safe, secure, convenient and fast way to get your hands on some popular crypto
              currency products. We’ve vetted leading crypto currency brands, with the goal in mind of bringing
              everything you need related to crypto currency into the one simple place, Swyftx! You can expect fast
              shipping, great support and competitive prices on all the items listed.
            </Body>
          </FlexLayout>

          <FlexLayout direction='column' spacing={16}>
            <Heading size='h5'>Hardware wallet</Heading>

            <FlexLayout direction='row' spacing={16}>
              {Object.values(shopItems).map((data) => (
                <ShopItem key={data.title} {...data} />
              ))}
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </FlexLayout>

    <ShopInformationComponent />
  </FlexLayout>
);

Shop.displayName = 'Shop';

export default Shop;

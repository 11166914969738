import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Skeleton } from '@swyftx/react-web-design-system';

import { WithdrawalReasonEnum, withdrawalService } from '@shared/services';

type Props = {
  value?: WithdrawalReasonEnum;
  onChange: (val: WithdrawalReasonEnum) => void;
  title: string;
  loading?: boolean;
};

const WithdrawReasonDropdown: React.FC<Props> = ({ value, onChange, title, loading = false }) => {
  const { t } = useTranslation('wallet');
  const reasons = useMemo(() => withdrawalService.getSortedWithdrawalReasons(), []);
  const selectedReason = useMemo(() => reasons.filter((reason) => reason.key === value)[0], [value]);

  if (loading) {
    return (
      <FlexLayout direction='column' spacing={8}>
        <Skeleton variant='text' width='120px' height='21px' />
        <Skeleton variant='rounded' width='100%' height='56px' />
      </FlexLayout>
    );
  }

  return (
    <FlexLayout direction='column' spacing={8}>
      <Select
        className='h-56'
        title={title}
        required
        value={selectedReason?.key.toString()}
        placeholder={t('singleWalletFiatWithdrawal.selectReason')}
        onValueChange={(e) => onChange(Number(e) as WithdrawalReasonEnum)}
        items={reasons.map((reason) => ({
          value: reason.key.toString(),
          label: t(reason.label as any),
        }))}
      />
    </FlexLayout>
  );
};

export { WithdrawReasonDropdown };

import { useMediaQuery as MUIUseMediaQuery, useTheme } from '@mui/material';

const XXL_BREAKPOINT = 1728;

function useMediaQuery(input: string) {
  // resolves issue where MUIUseMediaQuery by default always returns false the first time on page load,
  // which can cause unnecessary re-renders and trigger useEffects with a false positive change
  return MUIUseMediaQuery(input, { noSsr: true });
}

const useScreenBreakpoints = () => {
  const theme = useTheme();
  const isXxl = useMediaQuery(theme.breakpoints.up(XXL_BREAKPOINT));
  const isXl = useMediaQuery(theme.breakpoints.up('lg'));
  const isLg = useMediaQuery(theme.breakpoints.down('xl'));
  const isMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isXxl,
    isXl,
    isLg,
    isMd,
    isXs,
    isSm,
    isMediumScreen,
    isLargeScreen,
    isDesktop,
  };
};

export { useScreenBreakpoints };

import { Big } from '@shared/safe-big';

import { CurrencyType } from './currency.types';

const thousand = 1000;
const million = thousand * thousand;
const billion = million * thousand;
const trillion = billion * thousand;

export type FormatCurrencyOptions = {
  hideSuffix?: boolean;
  useAssetType?: boolean;
  forceSuffix?: boolean;
  forceMinimumPriceScale?: number;
};

type ShorthandOptions = {
  includeThousands?: boolean;
  fixedTo?: number;
  includeCodePrefix?: boolean;
};

/**
 * Formats a given value to a shorthand representation, such as "1.23T" for 1,230,000,000,000.
 * @param options.includeThousands - Whether to include thousands in the shorthand when below 1 million.
 *
 * Example usage:
 * @example
 * formatValueToShorthand(1_234_567_890); // "1.23B"
 * formatValueToShorthand(1_234_567_890_000, { fixedTo: 3 }); // "1.235T"
 * formatValueToShorthand(25_234, { includeThousands: true }); // "25.23k"
 * formatValueToShorthand(2_512.3456, { fixedTo: 3, includeThousands: true }); // "2.512k"
 */
const formatValueToShorthand = (value: string | number, options?: ShorthandOptions) => {
  const fixedTo = options?.fixedTo === undefined ? 2 : options?.fixedTo;
  const bigValue = Big(value);

  const sign = bigValue.s;
  const stringSign = sign === -1 ? '-' : '';

  const trillionDivided = bigValue.div(trillion * sign);
  const billionDivided = bigValue.div(billion * sign);
  const millionDivided = bigValue.div(million * sign);
  const thousandDivided = bigValue.div(thousand * sign);

  if (trillionDivided.gt(1)) return `${stringSign}${trillionDivided.toFixed(fixedTo)}T`;
  if (billionDivided.gt(1)) return `${stringSign}${billionDivided.toFixed(fixedTo)}B`;
  if (millionDivided.gt(1)) return `${stringSign}${millionDivided.toFixed(fixedTo)}M`;
  if (options?.includeThousands && thousandDivided.gt(1)) return `${stringSign}${thousandDivided.toFixed(fixedTo)}k`;

  return new Intl.NumberFormat('en', { minimumFractionDigits: 0 }).format(Number(value));
};

/**
 * Formats a given value to a shorthand representation with a currency symbol and code, such as "1.23T BTC" for 1,230,000,000,000 BTC.
 * Only includes the currency code if the currency type is crypto.
 * Only includes the currency symbol if the currency type is fiat.
 *
 * Example usage:
 * @example
 * formatValueToCurrencyShorthand("1234567890"); // "$1.23B"
 * formatValueToCurrencyShorthand("1234567890000", CurrencyType.CryptoCurrency, "BTC"); // "1.23T BTC"
 * formatValueToCurrencyShorthand("1234567890000", CurrencyType.Fiat, undefined, { fixedTo: 3 }); // "$1.235T"
 */
const formatValueToCurrencyShorthand = (
  value: string,
  currencyType?: CurrencyType,
  currencyCode?: string,
  shorthandOptions?: ShorthandOptions,
) => {
  const isNumber = !Big(value).eq(0);
  const prefix = currencyType === CurrencyType.CryptoCurrency ? '' : '$';
  const val = isNumber ? formatValueToShorthand(value, shorthandOptions) : '0';
  const suffix =
    currencyType === CurrencyType.CryptoCurrency || shorthandOptions?.includeCodePrefix ? currencyCode : '';

  // If the value is negative, we want to keep the negative sign at the front of the string
  const formattedVal = val.charAt(0) === '-' ? `-${prefix}${val.slice(1)}` : `${prefix}${val}`;

  return `${formattedVal}${suffix ? ` ${suffix}` : ''}`;
};

export { formatValueToCurrencyShorthand, formatValueToShorthand };

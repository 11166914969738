import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import { GlobalActions } from '@global-components/GlobalActions/GlobalActions';
import { GlobalTradeButton } from '@global-components/GlobalTradeButton';

import useDebounce from '@shared/hooks/lib/useDebounce';
import { UIStore, UniversalTradeStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useShouldSkipGlobalActions } from '@hooks/Layout/useShouldSkip';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';
import { useMeasure } from 'react-use';

type Props = {
  children?: React.ReactNode;
};

const DynamicGrid: React.FC<Props> = observer(({ children }) => {
  const { setMainContentWidth, setMainContentHeight } = UIStore.useUIStore;
  const { showGlobalTrade } = UniversalTradeStore;
  const { theme } = useTheme();
  const { isMediumScreen } = useScreenBreakpoints();
  const [ref, { width: mainRefWidth, height: mainRefHeight }] = useMeasure();
  const debouncedWidth = useDebounce(mainRefWidth, 100);
  const debouncedHeight = useDebounce(mainRefHeight, 100);

  // TODO refactor wallet page so we don't need this pageRef
  const { setPageRef } = UIStore.useUIStore;

  const noGlobalActionsRoutes = useShouldSkipGlobalActions();

  useEffect(() => {
    setMainContentWidth(debouncedWidth);
  }, [debouncedWidth]);

  useEffect(() => {
    setMainContentHeight(debouncedHeight);
  }, [debouncedHeight]);

  return (
    <Grid
      container
      direction='row'
      flexWrap='nowrap'
      columns={12}
      height='100%'
      width='100%'
      className='overflow-y-auto overflow-x-hidden'
      ref={(pageRef) => setPageRef(pageRef)}
    >
      <Grid
        item
        height='100%'
        width='100%'
        position='relative'
        display='flex'
        justifyContent='center'
        sx={{
          overflow: 'visible',
        }}
      >
        <Box
          ref={ref}
          position='absolute'
          width='100%'
          maxWidth={noGlobalActionsRoutes ? undefined : 1808}
          height='100%'
          justifyContent='space-between'
        >
          {children}
        </Box>
      </Grid>

      {!noGlobalActionsRoutes && isMediumScreen && (
        <Grid
          item
          xs='auto'
          height='100%'
          width='100%'
          zIndex={showGlobalTrade ? theme.zIndex.drawer : undefined}
          position='sticky'
          top={0}
        >
          <Box width='100%' height='100%' overflow='hidden' padding={showGlobalTrade ? 3 : 0}>
            {isMediumScreen && <GlobalActions />}
            <GlobalTradeButton />
          </Box>
        </Grid>
      )}
    </Grid>
  );
});

export { DynamicGrid };

import React, { useContext } from 'react';

import { observer } from 'mobx-react-lite';

import { FeatureCard } from './components';
import { FeatureDiscoveryContext } from './context/FeatureDiscovery.context';
import { useFeatureDiscovery } from './hooks/useFeatureDiscovery';

const FeatureDiscovery: React.FC = observer(() => {
  const { activeFeatures, onClose, activeFeature, setActiveFeature, showFeatureDiscovery } = useFeatureDiscovery();
  const { setMinimized, minimized } = useContext(FeatureDiscoveryContext);

  if (!activeFeatures.length || !showFeatureDiscovery) return null;

  return (
    <FeatureCard
      activeFeature={activeFeature}
      setActiveFeature={setActiveFeature}
      features={activeFeatures}
      onClose={onClose}
      minimized={minimized}
      setMinimized={setMinimized}
    />
  );
});

export { FeatureDiscovery };

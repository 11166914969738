import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import Recaptcha from '@components/molecules/Recaptcha';

import { Form } from '@global-components/Form';
import { CodeInput, EmailInput } from '@global-components/Input';

import { EmailVerificationLightIcon } from '@routes/Onboarding/assets/icons';
import { RegisterContext } from '@routes/Register/Register.context';
import { CODE_LENGTH, INITIAL_CODE_TIMEOUT, FormStepProps } from '@routes/Register/types';

import { RegisterTranslationKey } from '@Register/translations';

import { observer } from 'mobx-react-lite';

import { RegisterStepFooter } from '../../RegisterStepFooter';

const VerifyEmailAddress: React.FC<FormStepProps> = observer(
  ({ captchaToken, captchaRefresh, setCaptchaToken, formData, setFormData }) => {
    const {
      emailCode,
      setEmailCode,
      emailCodeSent,
      emailVerified,
      submitting,
      setSubmitting,
      error,
      setError,
      handleVerifyCode,
      handleSendCode,
      nextRegisterStep,
    } = useContext(RegisterContext);

    const [changeEmail, setChangeEmail] = useState<boolean>(false);
    const [newEmail, setNewEmail] = useState<string>('');
    const [emailCodeResent, setEmailCodeResent] = useState(false);

    const { t } = useTranslation(RegisterTranslationKey);

    const changeEmailAddress = () => setChangeEmail(true);
    const email = useMemo(() => formData.email, [formData.email]);

    const onChangeEmail = () => {
      // Only change the email, if we actually change the email
      if (newEmail.length && newEmail !== formData.email) {
        setFormData((prevState) => ({ ...prevState, email: newEmail }));
        handleSendCode({ ...formData, email: newEmail }, false);
      }
      setEmailCodeResent(false);
      setChangeEmail(false);
      setSubmitting(false);
      setError('');
    };

    const resendCode = useCallback(() => {
      if (!emailCodeResent) {
        handleSendCode(formData, false);
        setEmailCodeResent(true);
      }
    }, [emailCodeResent, formData, handleSendCode]);

    useEffect(() => {
      setError('');

      if (!emailVerified && !emailCodeSent) {
        handleSendCode(formData, false);
      }
    }, []);

    useEffect(() => {
      if (!emailVerified && emailCode.length === CODE_LENGTH) {
        handleVerifyCode(formData, false);
      }
    }, [emailCode, emailVerified, formData, handleVerifyCode]);

    const renderChangeEmail = () => (
      <>
        <FlexLayout direction='column' className='mb-24 w-full'>
          <Heading id='verifyEmailAddress.title' size='h4' className='text-center'>
            {t('verifyEmailAddress.update.title')}
          </Heading>
          <Body id='verifyEmailAddress.subTitle' className='my-8 text-center' color='secondary'>
            {t('verifyEmailAddress.update.subTitle')}
          </Body>
        </FlexLayout>
        <Form
          id='verifyEmailAddress.form'
          buttonLabel={t('verifyEmailAddress.buttonLabels.updateAndResendCode')}
          buttonDisabled={!captchaToken}
          onSubmit={onChangeEmail}
          setSubmitting={setSubmitting}
          submitting={submitting}
          sx={{
            '.MuiButton-root': {
              marginTop: 1,
              fontSize: 14,
            },
          }}
        >
          <EmailInput id='verifyEmailAddress.form.email' value={email} onChange={(e) => setNewEmail(e.target.value)} />
        </Form>

        <Button
          id='verifyEmailAddress.buttons.cancelUpdate'
          variant='ghost'
          type='button'
          size='lg'
          className='my-8 w-full'
          onClick={() => setChangeEmail(false)}
        >
          {t('verifyEmailAddress.buttonLabels.cancelUpdate')}
        </Button>
      </>
    );

    const renderVerifyEmail = () => (
      <>
        <Heading id='verifyEmailAddress.title' size='h4' className='text-center'>
          {t('verifyEmailAddress.title')}
        </Heading>

        <Body
          id='verifyEmailAddress.labels.sentVerificationEmailCodeTo'
          className='mt-8 w-full text-center'
          size='large'
          color='secondary'
        >
          {t('verifyEmailAddress.labels.sentVerificationEmailCodeTo')}
        </Body>

        <Body
          id='verifyEmailAddress.labels.email'
          className='pii-mask mb-8 w-full text-center'
          size='large'
          weight='bold'
          color='primary'
        >
          {email}
        </Body>

        <FlexLayout className='mb-16 w-full' alignItems='center' justifyContent='center'>
          <Button
            id='verifyEmailAddress.buttons.changeEmailAddress'
            variant='ghost'
            onClick={changeEmailAddress}
            size='lg'
            color='accent'
          >
            {t('verifyEmailAddress.buttonLabels.changeEmailAddress')}
          </Button>
        </FlexLayout>

        <CodeInput
          id='verifyEmailAddress.code'
          length={CODE_LENGTH}
          error={error}
          onChange={setEmailCode}
          loading={submitting}
          resendCode={resendCode}
          codeResent={emailCodeResent}
          resendTime={INITIAL_CODE_TIMEOUT}
        />
      </>
    );

    const renderVerified = () => (
      <FlexLayout direction='column' alignItems='center' spacing={4}>
        <EmailVerificationLightIcon sx={{ fontSize: '64px', marginBottom: '7px' }} />
        <Heading size='h4'>{t('verifyEmailAddress.labels.alreadyVerified')}</Heading>
        <Body className='pii-mask' color='secondary' weight='bold'>
          {email}
        </Body>
      </FlexLayout>
    );

    return (
      <div className='h-full w-full'>
        <FlexLayout direction='column' className='h-full w-full' justifyContent='space-between'>
          <div className='mt-16 w-full sm:mt-[2.5rem]'>
            {emailVerified ? renderVerified() : changeEmail ? renderChangeEmail() : renderVerifyEmail()}
          </div>

          <div className='w-full pt-8'>
            {!captchaToken && (
              <Recaptcha
                id='verifyEmailAddress.recaptcha'
                captchaRefresh={captchaRefresh}
                submitCaptcha={setCaptchaToken}
              />
            )}

            <RegisterStepFooter
              onNext={nextRegisterStep}
              nextButtonText={emailVerified ? t('registerStep.buttonLabels.continue') : undefined}
            />
          </div>
        </FlexLayout>
      </div>
    );
  },
);

VerifyEmailAddress.displayName = 'VerifyEmailAddress';

export { VerifyEmailAddress };

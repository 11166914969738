import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { Profile, UpdateUserProfileArgs } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import UserService from '@services/UserService';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  email: string;
};

const INITIAL_CODE_TIMEOUT = 30;

export const UpdateEmailAddressVerifyStep: React.FC<Props> = ({ onClose, email, onSuccess }) => {
  const { userProfile, setUserProfile, getMaskedPhoneNumber } = UserStore.useUserStore;
  const { addMessageBox } = UIStore.useUIStore;

  const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);
  const [mfaCode, setMfaCode] = useState<string>('');
  const { t } = useTranslation('login');
  const [error, setError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const { verification } = userProfile as Profile;
  const { email: isVerified } = verification || {};

  const getUserProfileArgs = useCallback(
    (): UpdateUserProfileArgs => ({
      profile: {
        email,
      },
    }),
    [email],
  );

  const handleError = useCallback((e: any) => {
    const { errorMessage } = e as SwyftxError;
    setError(errorMessage);
  }, []);

  const handleNewAddress = useCallback(
    async (email: string) => {
      if (userProfile) {
        const userProfileUpdated = { ...userProfile };
        userProfileUpdated.email = email;
        if (userProfileUpdated.verification) userProfileUpdated.verification.email = 0;
        setUserProfile(userProfileUpdated);
      }

      addMessageBox({
        content: (
          <InformationMessageBox
            PII
            title="We've sent you a message"
            content={`The action you are trying to complete requires SMS verification. A code will be sent to ${getMaskedPhoneNumber()}.`}
          />
        ),
      });

      if (Boolean(isVerified) === false) {
        // If we are not verified, we need to send an email verification to the customer
        await UserService.RequestEmailVerification();
      }
    },
    [isVerified],
  );

  const updateEmail = useCallback(async () => {
    try {
      const data: UpdateUserProfileArgs = getUserProfileArgs();
      const response: any = await UserService.UpdateUserProfile(data);

      if (response.new) handleNewAddress(response.new.email);

      if (typeof response === 'string' && response.includes('mfa_')) {
        setMfaCode(response);
      }
    } catch (e) {
      handleError(e);
    }
  }, [handleNewAddress, getUserProfileArgs, handleError]);

  useEffect(() => {
    // update the email on load
    updateEmail();
  }, []);

  const verifyNewEmail = useCallback(async () => {
    try {
      const data: UpdateUserProfileArgs = getUserProfileArgs();

      const headers = { AuthAction: `${mfaCode} ${code}` };

      await UserService.UpdateUserProfile(data, headers);
      onSuccess();
    } catch (e) {
      handleError(e);
    }
  }, [code, getUserProfileArgs, handleError, mfaCode, onClose]);

  const handleResendCode = () => {
    setResendTime(resendTime + INITIAL_CODE_TIMEOUT);
    updateEmail();
  };

  useEffect(() => {
    if (code.length === 6) {
      verifyNewEmail();
    }
  }, [code, verifyNewEmail]);

  const handlePasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setCode(text);
  };

  return (
    <Wizard.Step onClose={onClose} title='Verification via phone required' hideActions>
      <Stack spacing={2}>
        <Typography PII color='text.secondary' fontSize={16}>
          The action you are trying to complete requires SMS verification. A code will be sent to{' '}
          <strong>{getMaskedPhoneNumber()}</strong>.
        </Typography>
        <Typography color='text.secondary' fontSize={16}>
          Do not share this code, Swyftx will never ask you for verification details.
        </Typography>
        <Stack direction='column' spacing={2} alignItems='center' justifyContent='center'>
          <CodeInput
            id='update-email-address-verify-step-code'
            length={6}
            onChange={(newCode: string[]) => setCode(newCode.join(''))}
            error={error}
            hidePasteButton
          />
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          {hasClipboardPermission === 'granted' && (
            <Button variant='text' onClick={() => handlePasteFromClipboard()} sx={{ marginTop: '0rem' }}>
              <Stack direction='row' alignItems='center' height='100%' spacing={1}>
                <Paste className='h-20 w-20 text-color-text-primary' />
                <Typography color='primary'>Paste from clipboard</Typography>
              </Stack>
            </Button>
          )}
          <CountdownDelay
            id='update-email-address-verify-step-resend-countdown'
            delay={resendTime}
            delayText={t('codeInput.buttonLabels.resendCodeIn')}
            restartText={t('codeInput.buttonLabels.resendCode')}
            onTimerRestart={handleResendCode}
          />
        </Stack>
      </Stack>
    </Wizard.Step>
  );
};

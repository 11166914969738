import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyRight, Cross } from '@swyftx/aviary/icons/outlined';
import { Grid } from '@swyftx/react-web-design-system';

import { cn } from '@shared/utils/lib/ui';

import { useQuery } from '@hooks/useQuery';
import { RegisterContext } from '@routes/Register/Register.context';
import { Affiliates } from '@routes/Register/Register.data';
import { RegisterTranslationKey } from '@routes/Register/translations';

import { observer } from 'mobx-react-lite';

const RegisterReferralInput: React.FC = observer(() => {
  const { referralCode, setReferralCode, affiliate, promoRef } = useContext(RegisterContext);

  const { t } = useTranslation(RegisterTranslationKey);
  const query = useQuery();

  const [showReferralCode, setShowReferralCode] = useState<boolean>(false);
  const [referralCodeLocked, setReferralCodeLocked] = useState<boolean>(false);
  const [editedReferralCode, setEditedReferralCode] = useState<string>('');
  const [isRAFCode, setIsRAFCode] = useState<boolean>(false);

  useEffect(() => {
    const urlReferralCode = query.get('ref') || query.get('promoRef');
    if (urlReferralCode?.startsWith('rf_')) {
      setIsRAFCode(true);
    }
    if (urlReferralCode && referralCode) {
      setShowReferralCode(true);
      setReferralCodeLocked(true);
    }
  }, [query, referralCode]);

  const handleDelete = () => {
    setReferralCode('');
    setReferralCodeLocked(false);
  };

  const submitReferralCode = () => {
    setReferralCode(editedReferralCode);
    setReferralCodeLocked(true);
  };

  if (promoRef) return null;

  return (
    <>
      {!showReferralCode && !isRAFCode && (
        <BodyLink
          onClick={() => setShowReferralCode(true)}
          color='accent'
          className='my-8 cursor-pointer hover:underline'
          size='medium'
          weight='emphasis'
        >
          {t('referralCode.title')}
        </BodyLink>
      )}

      {showReferralCode &&
        (affiliate ? (
          Affiliates[affiliate].component
        ) : (
          <div
            className={cn(
              referralCodeLocked
                ? 'border-color-border-accent bg-color-background-surface-secondary'
                : 'border-color-border-main',
              'mb-8 w-full rounded-8 border border-dashed p-16',
            )}
          >
            <Grid container justifyContent='space-between' alignItems='center' height='100%' maxHeight={{ md: '72px' }}>
              <img
                src='/assets/images/referrals_register.svg'
                height={64}
                width={64}
                style={{ display: 'flex' }}
                alt='Referral code sign up'
              />

              {!referralCodeLocked && (
                <Grid item xs={12} sm={9}>
                  <FlexLayout direction='row' className='w-full' alignItems='end' spacing={8}>
                    <FlexLayout direction='column' spacing={4} className='w-full'>
                      <FlexLayout spacing={0} alignItems='start' justifyContent='start'>
                        <Body size='small' weight='bold' color='primary'>
                          {t('referralCode.title')}
                        </Body>
                      </FlexLayout>

                      <Input
                        id='createAccount.form.referralCode'
                        placeholder='Enter code'
                        value={editedReferralCode || referralCode}
                        onChange={(e) => setEditedReferralCode(e.target.value)}
                      />
                    </FlexLayout>

                    <Button
                      variant='filled'
                      disabled={!editedReferralCode}
                      type='button'
                      layout='icon'
                      onClick={submitReferralCode}
                      leadingIcon={<ArrowLineOnlyRight className='h-20 w-20' />}
                    />
                  </FlexLayout>
                </Grid>
              )}

              {referralCodeLocked && (
                <Grid item xs={12} sm={9}>
                  <Body color='secondary' size='small' className='mb-4 w-full'>
                    <strong>{t('referralCode.codeApplied')}</strong>
                    {t('referralCode.subTitle')}
                  </Body>
                  <Chip onClick={() => setReferralCodeLocked(false)} color='primary'>
                    <FlexLayout alignItems='center' spacing={4}>
                      <Body size='small' weight='emphasis' color='white'>
                        {referralCode}
                      </Body>
                      <Button
                        layout='icon'
                        leadingIcon={<Cross className='text-color-text-white' />}
                        onClick={handleDelete}
                        variant='ghost'
                        size='sm'
                        color='inverse'
                      />
                    </FlexLayout>
                  </Chip>
                </Grid>
              )}
            </Grid>
          </div>
        ))}
    </>
  );
});

export { RegisterReferralInput };

import { useCallback, useEffect, useRef } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import * as d3 from 'd3';

import { LINE_CHART_SETTINGS } from '../LineChart.const';
import {
  LineChartData,
  LineChartDatum,
  LineChartOverlays,
  LineChartTooltipCallback,
  LineChartTooltipData,
} from '../LineChart.types';

type Props = {
  idKey: string;
  chartData: LineChartData;
  overlays?: LineChartOverlays;
  overlayData?: LineChartData[];
  costBasis?: LineChartData;
  areaColor: string;
  width: number;
  height: number;
  tooltipCallback: LineChartTooltipCallback | undefined;
  x: d3.ScaleTime<number, number, never>;
  y: d3.ScaleLinear<number, number, never>;
  loading?: boolean;
  getX: (d: LineChartDatum) => Date;
  getY: (d: LineChartDatum) => number;
};

const { tooltipWidth, tooltipThrottle } = LINE_CHART_SETTINGS;

const useBuildTooltip = ({
  idKey,
  tooltipCallback,
  chartData,
  width,
  areaColor,
  height,
  x,
  y,
  loading = false,
  getX,
  getY,
  costBasis = [],
  overlayData = [],
  overlays,
}: Props) => {
  const throttlingMouse = useRef(false);
  const tooltipLine = useRef<any>();
  const tooltipPoint = useRef<any>();
  const tooltipPointX = useRef<number>(-1);
  const { isDarkMode } = useTailwindTheme();

  useEffect(() => {
    if (!tooltipCallback) return;

    tooltipLine.current = d3
      .select(`#tooltip-line-${idKey}`)
      .attr('stroke-width', tooltipWidth)
      .attr('stroke', 'var(--color-background-surface-active)')
      .attr('fill', 'none')
      .attr('opacity', 0)
      .attr('y1', 0)
      .attr('y2', height);

    tooltipPoint.current = d3
      .select(`#tooltip-point-${idKey}`)
      .attr('opacity', 0)
      .attr('r', 5)
      .attr('stroke', isDarkMode ? 'var(--color-background-info)' : areaColor)
      .attr('fill', 'var(--color-background-pageBG)');
  }, [tooltipCallback, height, idKey, isDarkMode, areaColor]);

  const buildTooltip = useCallback(() => {
    if (!tooltipCallback) return;

    // Tooltip
    const onMouseMove = (e: MouseEvent) => {
      if (!chartData.length) return;

      if (tooltipCallback && !loading && !throttlingMouse.current) {
        const mousePosition = d3.pointer(e);
        const hoveredDate = x.invert(mousePosition[0]);
        const getTimeDifferenceFromHoveredDate = (d: LineChartDatum) =>
          Math.abs(getX(d).getTime() - hoveredDate.getTime());
        const closestIndex = d3.leastIndex(
          chartData,
          (a, b) => getTimeDifferenceFromHoveredDate(a) - getTimeDifferenceFromHoveredDate(b),
        );
        if (closestIndex !== undefined) {
          // Find the closest data points of our main data and our overlays
          const closestDataPoints = [chartData, ...overlayData, costBasis].reduce<LineChartTooltipData>(
            (acc, data, index) => {
              if (index === 0) {
                const closestDataPoint = data[closestIndex];
                acc.main = closestDataPoint;
                acc.costBasis = closestDataPoint;
              } else {
                const overlay = overlays?.[index - 1];
                const closestDataPoint = data[closestIndex];
                const oneDataPointAway = data[closestIndex + 1];
                if (overlay) {
                  acc.overlays.push({ ...closestDataPoint, id: overlay.id });
                }
                acc.costBasis = oneDataPointAway;
              }

              return acc;
            },
            { main: chartData[0], overlays: [] },
          );

          const xPos = x(getX(closestDataPoints.main));
          const yPos = y(getY(closestDataPoints.main));
          const xPosOffset = xPos > width - 210 ? 250 : 0;

          tooltipLine.current?.attr('x1', xPos).attr('x2', xPos).attr('opacity', '1');
          tooltipPoint.current?.attr('cx', xPos).attr('cy', yPos).attr('opacity', '1');
          tooltipPointX.current = xPos;

          tooltipCallback({ x: xPos - xPosOffset, y: yPos }, closestDataPoints);
        }
        throttlingMouse.current = true;
      } else {
        setTimeout(() => {
          throttlingMouse.current = false;
        }, tooltipThrottle);
      }
    };

    const onMouseLeave = () => {
      if (!chartData.length) return;

      tooltipLine.current?.attr('opacity', '0');
      tooltipPoint.current?.attr('opacity', '0').attr('cx', '-1');
      tooltipPointX.current = -1;

      if (tooltipCallback) {
        tooltipCallback(null, null);
      }
    };

    d3.select(`.listening-rect-${idKey}`)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'transparent')
      .on('mousemove', onMouseMove)
      .on('mouseleave', onMouseLeave);
  }, [
    chartData,
    costBasis,
    getX,
    getY,
    height,
    idKey,
    loading,
    overlayData,
    overlays,
    tooltipCallback,
    tooltipLine,
    tooltipPoint,
    width,
    x,
    y,
  ]);

  return { buildTooltip, tooltipPointX };
};

export { useBuildTooltip };

import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const SMSRecovery: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;

  const { openModal } = useModal();

  const handleSwitch = (checked: boolean) => {
    if (checked) {
      openModal(Modals.SMSRecovery, { toEnable: true });
    } else {
      openModal(Modals.SMSRecovery, { toEnable: false });
    }
  };

  return (
    <Switch
      id='profile-security-sms-verification'
      checked={!userProfile?.userSettings?.disableSMSRecovery}
      size='md'
      onCheckedChange={(checked: boolean) => handleSwitch(checked)}
    />
  );
});

SMSRecovery.displayName = 'SMSRecovery';

export { SMSRecovery };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Contract: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M16.8868 10.0518L15.0085 10.0518L18.6842 6.37603C18.9771 6.08314 18.9771 5.60827 18.6842 5.31537C18.3913 5.02248 17.9164 5.02248 17.6235 5.31537L13.9473 8.99166L13.9473 7.11121C13.9473 6.697 13.6115 6.36121 13.1973 6.36121C12.7831 6.36121 12.4473 6.697 12.4473 7.11121V10.8018C12.4473 11.0007 12.5263 11.1915 12.6669 11.3321C12.8076 11.4728 12.9984 11.5518 13.1973 11.5518L16.8868 11.5518C17.301 11.5518 17.6368 11.216 17.6368 10.8018C17.6368 10.3876 17.301 10.0518 16.8868 10.0518Z'
      fill='currentColor'
    />
    <path
      d='M11.3326 12.6679C11.4733 12.8086 11.5523 12.9993 11.5523 13.1982L11.5523 16.8888C11.5523 17.303 11.2165 17.6388 10.8023 17.6388C10.3881 17.6388 10.0523 17.303 10.0523 16.8888L10.0523 15.0084L6.37603 18.6847C6.08314 18.9776 5.60827 18.9776 5.31537 18.6847C5.02248 18.3918 5.02248 17.9169 5.31537 17.624L8.99113 13.9482L7.11282 13.9482C6.69861 13.9482 6.36282 13.6125 6.36282 13.1982C6.36282 12.784 6.69861 12.4482 7.11282 12.4482L10.8023 12.4482C11.0012 12.4482 11.192 12.5273 11.3326 12.6679Z'
      fill='currentColor'
    />
  </svg>
);

export { Contract };

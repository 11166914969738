import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Fade } from '@mui/material';

import { Checkbox, Input, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { RequiredAsterisk } from '@global-components/RequiredAsterisk';

import { api, Asset } from '@shared/api';
import { GetStripeMetadataResponse } from '@shared/api/@types/stripeService';
import { DepositMethodEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { UIStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';
import { CardDepositProvider, useCardDepositProvider } from '@hooks/Deposit/useCardDepositProvider';
import { DefaultBanxaLimit, useDepositDescription } from '@hooks/Deposit/useDepositDescription';
import { generateBankAddress } from '@services/DepositService';
import { isValidDepositInput } from '@services/DepositService/DepositService.utils';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { DepositReceiveProps } from '../Deposit.types';

type Props = {
  asset: Asset;
} & DepositReceiveProps;

const amountAfterFee = (amount: Big, fee: Big): string =>
  amount.minus(amount.times(fee.div(100) ?? 1).round(18, 0)).toFixed(2);

const DepositCreditCardTransfer: React.FC<Props> = ({ asset, setDisabled, setOnAction, onClose }) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });
  const [stripeMetadata, setStripeMetadata] = useState<GetStripeMetadataResponse>();
  const { addToastMessage } = UIStore.useUIStore;
  const { depositCreditCardText } = useDepositDescription(asset.code);
  const minValue = depositCreditCardText.extraBankAddressDetails?.minimumOrder || DefaultBanxaLimit[asset.code].min;
  const maxValue = depositCreditCardText.extraBankAddressDetails?.maximumOrder || DefaultBanxaLimit[asset.code].max;
  const { pathname } = useLocation();
  const { getAssetById } = useMarkets();

  const { userCountryCurrencyCode, userCountryCurrency } = UserStore.useUserStore;
  const [value, setValue] = useState<string>('');
  const [valueAsBig, setValueAsBig] = useState<Big>();
  const [error, setError] = useState<string>('');
  const [acceptSharing, setAcceptSharing] = useState(false);

  const cardDepositProvider = useCardDepositProvider();
  const { openModal } = useModal();
  const avo = useAvo();

  useEffect(() => {
    (async () => {
      if (cardDepositProvider === CardDepositProvider.Stripe) {
        const { data } = await api.endpoints.getStripeMetadata({
          params: { assetId: userCountryCurrency },
        });
        setStripeMetadata(data);
      }
    })();
  }, [cardDepositProvider, userCountryCurrency]);

  useEffect(() => {
    let shouldDisableButton = error.length > 0 || value.length <= 0;
    if (cardDepositProvider === CardDepositProvider.Banxa) {
      shouldDisableButton = shouldDisableButton || !acceptSharing;
    }
    setDisabled(shouldDisableButton);
  }, [acceptSharing, cardDepositProvider, error, setDisabled, value.length]);

  useEffect(() => {
    avo.clickedCardDeposit({
      screen: pathname,
      depositOption: cardDepositProvider,
    });
  }, [avo, cardDepositProvider, pathname]);

  const handleProceedBanxa = async (val: string) => {
    try {
      const { redirectUrl } = await generateBankAddress(asset.code, DepositMethodEnum.BANXA, Number(val));
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
      avo.proceededWithCardDeposit({
        screen: pathname,
        depositOption: cardDepositProvider,
        amount: +val,
        currency: userCountryCurrencyCode,
      });
      onClose();
    } catch (e) {
      avo.cardDepositFailed({
        screen: pathname,
        depositOption: cardDepositProvider,
        amount: +val,
        currency: userCountryCurrencyCode,
      });
      addToastMessage({
        severity: 'error',
        message: t('banxa.errorGeneratingDetails'),
      });
    }
  };

  const handleProceedStripe = async (val: string) => {
    avo.proceededWithCardDeposit({
      screen: pathname,
      depositOption: CardDepositProvider.Stripe,
      amount: +val,
      currency: userCountryCurrencyCode,
    });
    openModal(Modals.StripeCardDeposit, { amount: val });
  };

  const handleProceed = async (val: string) => {
    if (cardDepositProvider === CardDepositProvider.Banxa) {
      handleProceedBanxa(val);
    } else {
      handleProceedStripe(val);
    }
  };

  const updateValue = (val: string) => {
    if (!isValidDepositInput(val)) {
      return;
    }

    setValue(val);

    if (!val) {
      setError('');
      setOnAction(() => {});
      setValueAsBig(Big(0));
      return;
    }

    const valueParsed = Big(val);
    setValueAsBig(valueParsed);

    if (valueParsed.toNumber() < minValue) {
      setError(t('banxa.minError', { minValue, code: asset.code }));
      setOnAction(() => {});
    } else if (valueParsed.toNumber() > maxValue) {
      setError(t('banxa.maxError', { maxValue, code: asset.code }));
      setOnAction(() => {});
    } else {
      setError('');
      setOnAction(() => () => handleProceed(val));
    }
  };

  const showCheckbox = cardDepositProvider === CardDepositProvider.Banxa;

  return (
    <Fade in timeout={500}>
      <div>
        <Stack padding={2} spacing={2}>
          <Typography color='text.secondary' fontSize={14} fontWeight='400'>
            {cardDepositProvider === CardDepositProvider.Stripe
              ? t('stripe.cardForm.providerTitle', { feePercentage: stripeMetadata?.feePercentage })
              : t('creditCard.steps.providerTitle', {
                  value: formatCurrency(
                    depositCreditCardText.extraBankAddressDetails?.maximumOrder,
                    getAssetById(userCountryCurrency),
                  ),
                })}
          </Typography>

          <Stack>
            <Input
              InputProps={{
                startAdornment: value && (
                  <Typography color='text.primary' fontWeight={400} fontSize={18}>
                    $
                  </Typography>
                ),
                sx: { fontSize: 16 },
              }}
              inputProps={{
                endAdornment: <Typography color='text.secondary'>{asset.code}</Typography>,
                step: '0.01',
              }}
              label={
                <>
                  {t('banxa.amountToDeposit', { code: asset.code })}
                  <RequiredAsterisk labelTypographyProps={{ marginLeft: '1px' }} />
                </>
              }
              placeholder={t('creditCard.steps.enterAnAmount')}
              error={!!error}
              value={value}
              onChange={(e) => updateValue(e.target.value)}
            />

            {error && (
              <Typography color='error' fontWeight={400} height={10} fontSize={12} marginTop={1}>
                {error}
              </Typography>
            )}

            {cardDepositProvider === CardDepositProvider.Stripe && (
              <Typography fontWeight={400} height={10} fontSize={12} marginTop={1}>
                {t('stripe.depositInfo.amountToReceive') + ' '}
                <Typography color='primary' display='inline' fontWeight={600} height={10} fontSize={12}>
                  ~${valueAsBig ? amountAfterFee(valueAsBig, Big(stripeMetadata?.feePercentage)) : '0.00'}
                </Typography>
              </Typography>
            )}
          </Stack>

          {showCheckbox && (
            <Stack
              direction='row'
              alignItems='flex-start'
              spacing={1}
              sx={{
                '.MuiCheckbox-root': { paddingTop: '0', paddingRight: '0' },
                '.MuiFormControlLabel-root': {
                  marginRight: '0',
                },
              }}
            >
              <Checkbox
                checked={acceptSharing}
                onChange={(_, checked) => setAcceptSharing(checked)}
                size='small'
                sx={{ color: 'grey.400' }}
                disableRipple
              />
              <Typography fontSize={14} fontWeight={400}>
                {depositCreditCardText.label}
              </Typography>
            </Stack>
          )}
        </Stack>
      </div>
    </Fade>
  );
};

export { DepositCreditCardTransfer };

export type ColorTypes = {
  Blue: ColorVariants;
  Green: ColorVariants;
  White: string;
  Red: ColorVariants;
  Yellow: ColorVariants;
  Grey: ColorVariants;
  Orange: ColorVariants;
  Purple: ColorVariants;
  Magenta: ColorVariants;
  Teal: ColorVariants;
  Black: string;
};

type ColorVariants = {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6?: string;
  7?: string;
  8?: string;
  9?: string;
  10?: string;
};

export const Colors: ColorTypes = {
  Blue: {
    1: '#E2F0FF',
    2: '#6DAFF6',
    3: '#0072ED',
    4: '#004590',
    5: '#001933',
  },
  Green: {
    1: '#E2FFE9',
    2: '#5FD58B',
    3: '#00AB55',
    4: '#006F29',
    5: '#00330D',
  },
  Red: {
    1: '#FFE2E7',
    2: '#F78598',
    3: '#EF2D4E',
    4: '#910018',
    5: '#330009',
  },
  Yellow: {
    1: '#fff7e2',
    2: '#FFE18C',
    3: '#FFCB36',
    4: '#997100',
    5: '#332500',
  },
  Orange: {
    1: '#FFF2E2',
    2: '#FFCA8C',
    3: '#FFA236',
    4: '#995301',
    5: '#331B00',
  },
  Grey: {
    1: '#F7F9FC',
    2: '#EAF0F9',
    3: '#D5DEED',
    4: '#B9C4D6',
    5: '#96A2B6',
    6: '#707C8F',
    7: '#4E5869',
    8: '#313640',
    9: '#1A1C21',
    10: '#0A0B0C',
  },
  Purple: {
    1: '#E1CCFA',
    2: '#A666EF',
    3: '#6B00E5',
    4: '#400089',
    5: '#15002E',
  },
  Magenta: {
    1: '#FACCEA',
    2: '#EF66C1',
    3: '#E50097',
    4: '#89005B',
    5: '#2E001E',
  },
  Teal: {
    1: '#CCF5F2',
    2: '#66E0D9',
    3: '#00CCC0',
    4: '#007A73',
    5: '#002926',
  },
  White: '#FFFFFF', // This color is used for the dropdown arrow theming and hex codes do not work
  Black: '#00000', // This color is used for the dropdown arrow theming and hex codes do not work
};

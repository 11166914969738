import { StakingInfo } from '../../@types/staking';
import { Methods, request } from '../rest';

const earnEndpoints = {
  earnOptIn: () =>
    request({
      path: '/earn/earnOptIn/',
      method: Methods.POST,
      auth: true,
    }),

  earnOptOut: () =>
    request({
      path: '/earn/earnOptOut/',
      method: Methods.POST,
      auth: true,
    }),

  getAssetEarnRewards: ({ query }: { query: { assetCode: string } }) =>
    request({
      path: '/earn/rewards/{:assetCode}',
      method: Methods.GET,
      query,
      auth: true,
    }),

  getEarnOfferings: () =>
    request({
      path: '/earn/offerings/',
      method: Methods.GET,
      auth: true,
    }),

  getEarnExpectedRewards: () =>
    request({
      path: '/earn/expectedRewards/',
      method: Methods.GET,
      auth: true,
    }),

  earnAmount: ({ data }: { data: { offerId: string; amount: string } }) =>
    request({
      path: '/earn/deposit/',
      method: Methods.POST,
      auth: true,
      data,
    }),

  deearnAmount: ({ data }: { data: { offerId: string; amount: string } }) =>
    request({
      path: '/earn/withdraw/',
      method: Methods.POST,
      auth: true,
      data,
    }),

  getEarnHistory: ({ query }: { query: { types?: string; assets: string } }) =>
    request({
      path: '/earn/history/',
      method: Methods.GET,
      auth: true,
      query,
    }),

  getEarnInfo: (): StakingInfo =>
    request({
      path: '/earn/info/',
      method: Methods.GET,
      auth: true,
    }),

  optInEarn: (): void =>
    request({
      path: '/earn/optIn',
      method: Methods.POST,
      auth: true,
    }),
};

export default earnEndpoints;

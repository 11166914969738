import { useMemo } from 'react';

import { Asset, RateSide } from '@shared/api';
import { RatesStore } from '@shared/store';

const useCurrentPrice = (primaryAsset?: Asset, secondaryAsset?: Asset, side?: RateSide) => {
  const { convertRate, getMinimumOrderAmount } = RatesStore.useRatesStore;

  const minimumAmount = primaryAsset ? getMinimumOrderAmount(primaryAsset) : undefined;
  // Get the bid price as we will be setting a sell
  const rate =
    primaryAsset && secondaryAsset && minimumAmount
      ? convertRate(primaryAsset, secondaryAsset, minimumAmount, side)
      : undefined;

  const currentPrice = useMemo(() => {
    if (!rate || !minimumAmount) return undefined;
    return minimumAmount.div(rate).toString();
  }, [minimumAmount, rate]);

  return currentPrice;
};

export { useCurrentPrice };

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset } from '@shared/api';

import { clamp } from '@utils/number';

import { AutoInvestAsset } from '../../autoInvest.types';
import { useAutoInvestPercentages } from '../../hooks';

type Props = {
  assets: AutoInvestAsset[];
  assetCode: string;
  percentage: number;
  onUpdatePercentage: (assetCode: string, percentage: number) => void;
  onUpdateAsset: (oldAssetCode: string, newAssetCode: string) => void;
};

const useAutoInvestAssetValueItem = ({ assets, assetCode, percentage, onUpdatePercentage, onUpdateAsset }: Props) => {
  const { getMax, getMin } = useAutoInvestPercentages();

  const [dirty, setDirty] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number | undefined>(percentage);

  const maxValue = useMemo(() => getMax(assets), [getMax, assets]);
  const minValue = useMemo(() => getMin(assets), [getMin, assets]);

  useEffect(() => {
    setInputValue(percentage);
  }, [percentage]);

  const handleUpdatePercentages = useCallback(
    (value: number, min: number) => {
      // On Input clamp the upper range but allow a min of 1 for input purposes
      const clampedValue = clamp(value, min, maxValue);
      setInputValue(clampedValue);
      onUpdatePercentage(assetCode, clampedValue);
    },
    [assetCode, maxValue, onUpdatePercentage],
  );
  const handleOnBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.includes('.') || !dirty) return;

      handleUpdatePercentages(Number(e.target.value), minValue);
      setDirty(false);
    },
    [dirty, handleUpdatePercentages, minValue],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.includes('.')) return;

      setDirty(true);

      if (!e.target.value) {
        setInputValue(undefined);
        return;
      }

      // On Input clamp the upper range but allow a min of 1 for input purposes
      handleUpdatePercentages(Number(e.target.value), 1);
    },
    [handleUpdatePercentages],
  );

  const handleAssetChange = useCallback(
    (newAsset: Asset) => {
      onUpdateAsset(assetCode, newAsset.code);
    },
    [assetCode, onUpdateAsset],
  );

  return {
    minValue,
    maxValue,
    inputValue: assetCode ? inputValue : undefined,
    setInputValue,
    handleAssetChange,
    handleOnChange,
    handleOnBlur,
  };
};

export { useAutoInvestAssetValueItem };

import { types } from '@swyftx/marketing-utils';

export enum RewardType {
  Available = 'available',
  History = 'history',
}

export const mockRewards: { [key in RewardType]: types.UserAssetReward[] } = {
  [RewardType.Available]: [
    {
      id: 1,
      campaignName: 'Double Defence',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      description: 'Add an extra layer of security to your account by enabling 2FA',
      valueAssetId: 1,
      rewardAssetId: 3,
      valueAmount: 2,
      hasPayout: true,
      endDate: new Date(2023, 3, 31),
      status: types.AssetRewardStatus.Live,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [
        {
          trigger: types.AssetRewardQualificationTrigger.GreenIdComplete,
          thresholdAssetId: 1,
          thresholdAmount: 1,
        },
      ],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: new Date(2023, 3, 1),
          redeemedAt: null,
          paidAt: null,
          rewardAmount: null,
          expiresAt: new Date(2023, 3, 31),
          status: types.AssetRedemptionStatus.InProgress, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          updatedAt: new Date(),
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.GreenIdComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 1,
              progress: 0,
            },
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 250,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      campaignName: 'Scam Shield',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      description: 'Protect yourself from scammers by creating an Anti-Phishing Code',
      valueAssetId: null,
      rewardAssetId: null,
      valueAmount: null,
      hasPayout: false,
      endDate: null,
      status: types.AssetRewardStatus.Live,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: null,
          redeemedAt: null,
          expiresAt: new Date(2023, 11, 1),
          status: types.AssetRedemptionStatus.Qualified, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          updatedAt: new Date(),
          paidAt: null,
          rewardAmount: null,
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 500,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      campaignName: 'Volume Venture',
      description: 'Buy/sell AUD 500 of crypto in a day',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      valueAssetId: 1,
      rewardAssetId: 1,
      hasPayout: true,
      valueAmount: 12,
      endDate: new Date(2023, 3, 31),
      status: types.AssetRewardStatus.Live,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: null,
          redeemedAt: null,
          expiresAt: new Date(2023, 11, 1),
          status: types.AssetRedemptionStatus.Qualified, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          updatedAt: new Date(),
          paidAt: null,
          rewardAmount: null,
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 50,
            },
          ],
        },
      ],
    },
    {
      id: 4,
      campaignName: 'Ether Hunter',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      description: 'Buy/sell AUD 100 of Ethereum in a week',
      valueAssetId: 1,
      rewardAssetId: 5,
      hasPayout: true,
      valueAmount: 2,
      endDate: null,
      status: types.AssetRewardStatus.Live,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [],
    },
    {
      id: 5,
      campaignName: 'Market Tracker',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      description: 'Get notified of important market changes by setting a custom Percentage Movement Alert',
      valueAssetId: 1,
      hasPayout: true,
      rewardAssetId: 3,
      valueAmount: 2,
      endDate: new Date(2023, 11, 11),
      status: types.AssetRewardStatus.Live,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [
        {
          trigger: types.AssetRewardQualificationTrigger.TradesComplete,
          thresholdAssetId: 1, // Reference to a fiat asset ID
          thresholdAmount: 500,
        },
      ],
      redemptions: [],
    },
  ],
  [RewardType.History]: [
    {
      id: 6,
      campaignName: 'Token Chaser',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      description: 'Stay updated about your most-loved token by adding it to your favourites.',
      valueAssetId: 1,
      rewardAssetId: 3,
      valueAmount: 2,
      hasPayout: true,
      endDate: null,
      status: types.AssetRewardStatus.Expired,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: null,
          redeemedAt: null,
          status: types.AssetRedemptionStatus.Expired, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          updatedAt: new Date(),
          expiresAt: new Date(2023, 10, 1),
          paidAt: null,
          rewardAmount: null,
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 100,
            },
          ],
        },
      ],
    },
    {
      id: 7,
      campaignName: 'Geeked Out 5',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      description: 'Complete all of Swyftx Learn quizzes.',
      valueAssetId: 1,
      rewardAssetId: 5,
      valueAmount: 15,
      hasPayout: true,
      endDate: null,
      status: types.AssetRewardStatus.Expired,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: new Date(),
          rewardAmount: '1',
          redeemedAt: new Date(),
          status: types.AssetRedemptionStatus.Expired,
          createdAt: new Date(),
          expiresAt: new Date(),
          paidAt: null,
          updatedAt: new Date(),
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 200,
              progress: 200,
            },
          ],
        },
      ],
    },
    {
      id: 8,
      campaignName: 'Geeked Out 6',
      description: 'Complete all of Swyftx Learn quizzes.',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      valueAssetId: 1,
      rewardAssetId: 5,
      valueAmount: 15,
      hasPayout: true,
      endDate: new Date(2023, 10, 1),
      status: types.AssetRewardStatus.Expired,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [],
    },
    {
      id: 9,
      campaignName: 'Geeked Out',
      description: 'Complete all of Swyftx Learn quizzes.',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      valueAssetId: 1,
      rewardAssetId: 3,
      valueAmount: 2,
      hasPayout: true,
      endDate: new Date(),
      status: types.AssetRewardStatus.Expired,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: null,
          rewardAmount: '0.0000612',
          redeemedAt: new Date(),
          expiresAt: new Date(),
          status: types.AssetRedemptionStatus.Redeemed, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          updatedAt: new Date(),
          paidAt: null,
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 500,
            },
          ],
        },
      ],
    },
    {
      id: 10,
      campaignName: 'Geeked Out 2',
      description: 'Complete all of Swyftx Learn quizzes.',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      valueAssetId: 1,
      rewardAssetId: 3,
      valueAmount: 200,
      hasPayout: true,
      endDate: null,
      status: types.AssetRewardStatus.Expired,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: null,
          rewardAmount: '0.00612',
          paidAt: null,
          expiresAt: new Date(),
          redeemedAt: new Date(),
          status: types.AssetRedemptionStatus.Redeemed, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          updatedAt: new Date(),
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 500,
            },
          ],
        },
      ],
    },
    {
      id: 11,
      campaignName: 'Geeked Out 3',
      description: 'Complete all of Swyftx Learn quizzes.',
      promotionType: types.AssetRewardPromotionType.SignUpBonus,
      valueAssetId: 1,
      rewardAssetId: 5,
      valueAmount: 15,
      hasPayout: true,
      endDate: null,
      status: types.AssetRewardStatus.Expired,
      qualificationPeriod: 0,
      redeemablePeriod: 0,
      qualifications: [],
      redemptions: [
        {
          id: 1,
          userId: '1',
          qualifiedAt: null,
          rewardAmount: '1',
          redeemedAt: new Date(),
          status: types.AssetRedemptionStatus.Redeemed, // Computed based on qualifiedAt, redeemedAt & expiresAt
          createdAt: new Date(),
          expiresAt: new Date(),
          paidAt: null,
          updatedAt: new Date(),
          requirements: [
            {
              trigger: types.AssetRewardQualificationTrigger.TradesComplete,
              thresholdAssetId: 1, // Reference to a fiat asset ID
              thresholdAmount: 500,
              progress: 500,
            },
          ],
        },
      ],
    },
  ],
};

export const mockActionable: types.UserAssetRewardActionableIds = {
  canOptIn: [4, 5],
  canRedeem: [2, 3],
  inProgress: [1],
};

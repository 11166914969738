import React, { useMemo } from 'react';

import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset, AssetType } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

type Props = {
  value?: string;
  asset: Asset;
  formatValue?: boolean;
  leading?: string;
  trailing?: string;
  error?: boolean;
} & InputProps;

const FONT_SCALE_FACTOR = 1.5;

const HeroInput: React.FC<Props> = ({
  value,
  leading,
  trailing,
  placeholder,
  asset,
  onChange,
  onBlur,
  className,
  error,
  ...props
}) => {
  const isXs = useTailwindBreakpoint('xs');

  const inputWidth = useMemo(() => {
    const totalLength = value?.length;
    if (!totalLength) {
      if (placeholder?.length) return placeholder.length;
      if (asset.assetType === AssetType.Crypto) {
        if (asset.price_scale === 0) return 1;

        return asset.price_scale + 2;
      }
      return 4;
    }

    // It seems like decimals and commas take up an additional ch value
    let numberCharactersLength = value.replace(/[,]/g, '').length;
    return numberCharactersLength + (totalLength - numberCharactersLength);
  }, [value, placeholder, asset.assetType, asset.price_scale]);

  const fontSize = useMemo(() => {
    const length = value?.length || 0;
    const maxLengthSize = isXs ? 4 : 12;

    if (length > maxLengthSize) {
      return 48 - (length - maxLengthSize) * FONT_SCALE_FACTOR;
    }

    return 48;
  }, [value, isXs]);

  return (
    <FlexLayout className='w-full' alignItems='center' justifyContent='center' spacing={4}>
      <Numeric weight='bold' size='xxlarge' color={error ? 'error' : 'accent'}>
        {leading}
      </Numeric>
      <input
        type='text'
        pattern='\d*'
        inputMode='decimal'
        className={cn(
          'h-[4rem] max-w-[380px] bg-transparent text-left !font-mono font-600',
          error ? 'text-color-text-error' : 'text-color-text-accent',
          className,
        )}
        style={{ width: inputWidth + 'ch', fontSize }}
        width='auto'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <Numeric weight='bold' size='xxlarge' color={error ? 'error' : 'accent'}>
        {trailing}
      </Numeric>
    </FlexLayout>
  );
};

export { HeroInput };

import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

import { PortfolioTransfer } from './PortfolioTransfer';

const PortfolioTransferRoute: React.FC = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  if (!isFeatureEnabled(AppFeature.PortfolioTransfer)) {
    return <Navigate to={NavigationURLs.Dashboard} />;
  }

  return <PrivateRoute key='r-portfoliotransfer' element={<PortfolioTransfer />} />;
};

export const PortfolioTransferRoutes: RouteObject[] = [
  {
    path: NavigationURLs.PortfolioTransfer,
    element: <PortfolioTransferRoute />,
  },
];

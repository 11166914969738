import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import {
  CitizenshipIcon,
  DriversLicenseIcon,
  ImmiCardIcon,
  MedicareIcon,
  NzBirthCertIcon,
  PassportIcon,
  VisaIcon,
} from '@routes/Onboarding/assets/icons';

const IDENTIFICATION_FORMS = [
  { key: 'drivers_licence', Icon: DriversLicenseIcon },
  { key: 'passport', Icon: PassportIcon },
  { key: 'citizenship', Icon: CitizenshipIcon },
  { key: 'visa', Icon: VisaIcon },
  { key: 'immi_card', Icon: ImmiCardIcon },
  { key: 'medicare', Icon: MedicareIcon },
  { key: 'nz_birth_cert', Icon: NzBirthCertIcon },
];

const IdVerificationListOfForms: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const { isXs } = useScreenBreakpoints();

  return (
    <>
      <Typography fontSize={{ sm: 14, md: 16 }} color='text.secondary'>
        {t('steps.idVerification.subtitle')}
      </Typography>
      <Typography fontWeight={600} fontSize={{ xs: 14, sm: 16 }} marginTop={4} marginBottom={1}>
        {t('steps.idVerification.acceptedForms')}
      </Typography>
      <Stack spacing={1}>
        {IDENTIFICATION_FORMS.map(({ key, Icon }) => (
          <Stack
            key={key}
            direction='row'
            alignItems='center'
            sx={{
              backgroundColor: isDarkMode ? 'action.hover' : 'grey.50',
              borderRadius: '8px',
              padding: '12px 16px',
            }}
          >
            <Icon sx={{ color: 'text.secondary', fontSize: isXs ? 24 : 32 }} />
            <Typography fontWeight={500} fontSize={14} marginLeft={2}>
              {t(`steps.idVerification.formItems.${key}` as any)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export { IdVerificationListOfForms };

import { AvatarColor } from '@swyftx/aviary/atoms/Avatar/Avatar.styles';

import { cva, VariantProps } from 'cva';

export interface MenuItemProps extends VariantProps<typeof menuItemVariants> {
  icon?: React.ReactNode | string;
  text: string;
  avatarColor?: AvatarColor;
  description?: string | React.ReactElement;
  action?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color?: 'primary' | 'error';
  selected?: boolean;
  error?: boolean;
  endAdornment?: React.ReactNode;
  preventCloseOnSelect?: boolean;
  disabled?: boolean;
  tooltip?: string;
  className?: string;
}

export const menuItemVariants = cva('', {
  variants: {
    color: {
      primary: 'hover:bg-color-background-surface-hover',
      error: 'hover:bg-color-background-error-subtle',
    },
    disabled: {
      true: 'hover:bg-color-background-pageBG cursor-not-allowed !text-color-text-disabled icon-color-icon-disabled',
      false: '',
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

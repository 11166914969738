import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyLeft, ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useGetDailyMovement } from '@hooks/Trade/useGetDailyMovement';

import { observer } from 'mobx-react-lite';
import { useWindowSize } from 'react-use';

import { MarketTickerItem } from './MarketTickerItem';
import { getStaticItemWidth } from './marketTicker.consts';
import { MarketTickerSortingOptions } from '../consts';

interface Props {
  assets: Asset[];
  isBaseBTC: boolean;
  onClick: (asset: Asset) => void;
  sort: MarketTickerSortingOptions;
  sortingText?: string;
}

export const StaticMarketTicker: React.FC<Props> = observer((props) => {
  const { isBaseBTC, onClick, assets, sort, sortingText } = props;
  const [startIndex, setStartIndex] = useState<number>(0);
  const { width: windowWidth } = useWindowSize();
  const { getDailyMovement } = useGetDailyMovement();
  const staticItemWidth = getStaticItemWidth(isBaseBTC);
  const nonMarketTickerItemSpace = 74;
  const totalItemWidth = windowWidth - nonMarketTickerItemSpace;
  const amountOfAssetsShown = useMemo(
    () => Math.floor(totalItemWidth / staticItemWidth),
    [staticItemWidth, totalItemWidth],
  );

  useEffect(() => {
    setStartIndex(0);
  }, [sort]);

  const assetsToShow = useMemo(
    () => assets.slice(startIndex, startIndex + amountOfAssetsShown),
    [amountOfAssetsShown, startIndex, assets, sort],
  );

  const updateRow = useCallback(
    (next: boolean) => {
      if (next) {
        setStartIndex(startIndex + amountOfAssetsShown);
      } else {
        setStartIndex(startIndex - amountOfAssetsShown);
      }
    },
    [amountOfAssetsShown, startIndex],
  );

  const handleOnWheel = useCallback(
    (e: { deltaY: number }) => {
      setTimeout(() => {
        if (startIndex !== 0 && e.deltaY < 0) {
          updateRow(false);
        }
        if (startIndex + amountOfAssetsShown < assets.length && e.deltaY > 0) {
          updateRow(true);
        }
      }, 100);
    },
    [amountOfAssetsShown, startIndex, assets.length, updateRow],
  );

  return (
    <FlexLayout className='w-full pl-4' spacing={8} onWheel={handleOnWheel}>
      <div className='flex w-16 items-center'>
        <Button
          variant='outlined'
          layout='icon'
          size='sm'
          leadingIcon={<ArrowLineOnlyLeft />}
          onClick={() => updateRow(false)}
          disabled={startIndex < 1}
          tooltip={`View previous ${amountOfAssetsShown} assets`}
        />
      </div>
      <FlexLayout direction='row' className='w-full justify-between'>
        {assetsToShow.map((asset, index) => {
          const movement = Big(getDailyMovement(asset));
          const isLastItem = startIndex + index === assets.length - 1;
          return (
            <FlexLayout key={index} className='my-6 w-full items-center '>
              <MarketTickerItem asset={asset} movement={movement} isBaseBTC={isBaseBTC} onClick={onClick} />
              {index !== amountOfAssetsShown - 1 && !isLastItem && (
                <Separator orientation='vertical' className='bg-color-border-main' />
              )}
            </FlexLayout>
          );
        })}
        {!assetsToShow.length && (
          <FlexLayout direction='row' className='w-full items-center justify-center text-center'>
            <Body color='secondary' size='small' className='w-full'>
              No {sortingText || 'assets'} found
            </Body>
          </FlexLayout>
        )}
      </FlexLayout>
      <div className='flex w-16 items-center !justify-end'>
        <Button
          variant='outlined'
          layout='icon'
          size='sm'
          leadingIcon={<ArrowLineOnlyRight />}
          disabled={assets.length <= startIndex + amountOfAssetsShown}
          onClick={() => updateRow(true)}
          tooltip={`View next ${amountOfAssetsShown} assets`}
        />
      </div>
    </FlexLayout>
  );
});

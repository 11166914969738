import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Indicators: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.0711 5.23956C11.4176 3.29731 9.81563 2.32255 8.26967 2.33015C6.69634 2.33789 5.48059 3.35611 4.59894 4.67288C2.84595 7.29103 2 11.6662 2 15.0197C2 15.4339 2.33579 15.7697 2.75 15.7697C3.16421 15.7697 3.5 15.4339 3.5 15.0197C3.5 11.8128 4.32525 7.77774 5.84536 5.50742C6.60026 4.37993 7.42153 3.83434 8.27705 3.83013C9.15992 3.82579 10.3786 4.39095 11.9289 6.21193C13.5771 8.14785 15.0611 9.15174 16.4202 9.42953C17.8318 9.71805 18.9605 9.19641 19.7846 8.44108C20.5868 7.70587 21.1305 6.7291 21.468 5.97773C21.6398 5.59537 21.7649 5.25592 21.8476 5.01069C21.889 4.8878 21.92 4.78782 21.941 4.71704C21.9515 4.68163 21.9596 4.65347 21.9653 4.63334L21.9719 4.60924L21.9739 4.60187L21.975 4.59773C22.081 4.19731 21.8424 3.78676 21.442 3.68074C21.0417 3.57476 20.6312 3.81369 20.525 4.21378L20.5246 4.21504L20.5209 4.22847C20.5173 4.24136 20.5114 4.26195 20.5032 4.2895C20.4868 4.34463 20.4613 4.42736 20.4261 4.53166C20.3556 4.74082 20.2477 5.0337 20.0997 5.36309C19.7978 6.03523 19.3555 6.79967 18.7711 7.33527C18.2087 7.85074 17.5503 8.1295 16.7206 7.95991C15.8384 7.77959 14.6267 7.06687 13.0711 5.23956ZM21.2502 4.40582C21.975 4.59773 21.975 4.59773 21.975 4.59773L21.2502 4.40582Z'
      fill='currentColor'
    />
    <path
      d='M21.448 17.2222C21.847 17.1111 22.0804 16.6976 21.9693 16.2986C21.8582 15.8995 21.4447 15.6661 21.0456 15.7772L19.5251 16.2006C19.1261 16.3117 18.8926 16.7252 19.0037 17.1243C19.1148 17.5233 19.5284 17.7567 19.9274 17.6456L21.448 17.2222Z'
      fill='currentColor'
    />
    <path
      d='M16.8863 18.4923C17.2854 18.3812 17.5188 17.9677 17.4077 17.5686C17.2966 17.1696 16.883 16.9362 16.484 17.0473L13.4429 17.894C13.0438 18.0051 12.8104 18.4187 12.9215 18.8177C13.0326 19.2167 13.4462 19.4502 13.8452 19.3391L16.8863 18.4923Z'
      fill='currentColor'
    />
    <path
      d='M10.8041 20.1858C11.2031 20.0747 11.4366 19.6611 11.3255 19.2621C11.2144 18.8631 10.8008 18.6296 10.4018 18.7407L7.36068 19.5875C6.96164 19.6986 6.72822 20.1121 6.83933 20.5111C6.95043 20.9102 7.36398 21.1436 7.76301 21.0325L10.8041 20.1858Z'
      fill='currentColor'
    />
    <path
      d='M4.72191 21.8792C5.12094 21.7681 5.35436 21.3546 5.24326 20.9555C5.13216 20.5565 4.71861 20.3231 4.31957 20.4342L2.79902 20.8575C2.39999 20.9686 2.16657 21.3822 2.27767 21.7812C2.38877 22.1803 2.80232 22.4137 3.20136 22.3026L4.72191 21.8792Z'
      fill='currentColor'
    />
  </svg>
);

export { Indicators };

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardContent, CardHeader } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading, Numeric, Utility } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';

import { RecurringOrder } from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { capitalize } from '@utils/formatting';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';

import { AutoInvestOrderItemActions } from './AutoInvestOrderItemActions';
import { AutoInvestOrderItemDonutChart } from './AutoInvestOrderItemDonutChart';
import { AutoInvestOrderItemMobileTemplate } from './AutoInvestOrderItemMobileTemplate';
import { AutoInvestOrderItemTable } from './AutoInvestOrderItemTable';
import { useAutoInvestOrderItem } from './useAutoInvestOrderItem';

type Props = {
  autoInvestment: RecurringOrder;
};

const MINIMUM_BANK_DEPOSIT_AMOUNT = 30;

const AutoInvestOrderItem: React.FC<Props> = observer(({ autoInvestment }) => {
  const [showBreakdown, setShowBreakdown] = useLocalStorage<boolean>(`auto-invest_${autoInvestment.templateKey}`, true);

  const countryAsset = useCountryAsset();
  const isXs = useTailwindBreakpoint('xs');

  const { fundingMethod, totalInvested, statusChip, nextScheduleTime, isPaused, isProcessing } = useAutoInvestOrderItem(
    { autoInvestment },
  );

  const sourceIsBankTransfer = useMemo(
    () => !autoInvestment.source || autoInvestment.source === RecurringOrderSource.DEPOSIT,
    [autoInvestment.source],
  );

  return (
    <Card className='overflow-hidden'>
      <CardHeader className='border-b border-color-border-main px-16 py-16 md:px-24'>
        <FlexLayout alignItems='center' justifyContent='space-between'>
          <Heading size='h6'>{autoInvestment.label}</Heading>
          <AutoInvestOrderItemActions
            autoInvestment={autoInvestment}
            showBreakdown={showBreakdown || false}
            isPaused={isPaused}
            isProcessing={isProcessing}
            setShowBreakdown={setShowBreakdown}
          />
        </FlexLayout>
      </CardHeader>

      <CardContent className='p-0'>
        <FlexLayout className='flex-col gap-16 p-16 @sm:flex-row @sm:items-center @sm:gap-96 md:p-24'>
          {autoInvestment.template && (
            <div className='w-[140px] @sm:w-[200px]'>
              <AutoInvestOrderItemDonutChart template={autoInvestment.template} size={150} />
            </div>
          )}
          <FlexLayout className='h-full w-full flex-wrap' spacing={16}>
            <FlexLayout
              direction='column'
              spacing={8}
              className='order-1 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-1'
            >
              <Utility>Total Invested</Utility>
              <Numeric>{formatCurrency(totalInvested, countryAsset)}</Numeric>
            </FlexLayout>
            <FlexLayout
              direction='column'
              spacing={8}
              className='order-6 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-2'
            >
              <Utility>Funding Method</Utility>
              <Numeric>{fundingMethod}</Numeric>
            </FlexLayout>

            <FlexLayout
              direction='column'
              spacing={8}
              className='order-2 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-3'
            >
              <Utility>Status</Utility>
              {statusChip}
            </FlexLayout>

            {!sourceIsBankTransfer && (
              <FlexLayout
                direction='column'
                spacing={8}
                className='order-3 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-4'
              >
                <Utility>Investment per order</Utility>
                <Numeric>{formatCurrency(autoInvestment.orderTotal || 0, countryAsset)}</Numeric>
              </FlexLayout>
            )}

            {sourceIsBankTransfer && (
              <FlexLayout
                direction='column'
                spacing={8}
                className='order-3 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-4'
              >
                <Utility>Minimum Order</Utility>
                <Numeric>
                  {formatCurrency(countryAsset?.min_deposit || MINIMUM_BANK_DEPOSIT_AMOUNT, countryAsset)}
                </Numeric>
              </FlexLayout>
            )}

            {!sourceIsBankTransfer && (
              <FlexLayout
                direction='column'
                spacing={8}
                className='order-4 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-5'
              >
                <Utility>Frequency</Utility>
                <Numeric>{capitalize(autoInvestment.frequency || '')}</Numeric>
              </FlexLayout>
            )}

            {sourceIsBankTransfer && (
              <FlexLayout
                direction='column'
                spacing={8}
                className='order-4 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-5'
              >
                <Utility>Reference</Utility>
                <Numeric>{autoInvestment.templateKey}</Numeric>
              </FlexLayout>
            )}

            {sourceIsBankTransfer && (
              <FlexLayout
                direction='column'
                spacing={8}
                className='order-5 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-6'
              >
                <Utility>Last Processed</Utility>
                <Numeric>
                  {autoInvestment.lastTime
                    ? DateTime.fromMillis(autoInvestment.lastTime).toFormat('dd/MM/yyyy hh:mm a')
                    : '-'}
                </Numeric>
              </FlexLayout>
            )}

            {!sourceIsBankTransfer && (
              <FlexLayout
                direction='column'
                spacing={8}
                className='order-5 w-[calc(50%-16px)] @sm:w-[calc(33%-16px)] sm:order-6'
              >
                <Utility>Next scheduled order</Utility>
                <Numeric>{nextScheduleTime}</Numeric>
              </FlexLayout>
            )}
          </FlexLayout>
        </FlexLayout>
        <div className='block w-full p-24 @sm:hidden'>
          <Button
            color='subtle'
            size='lg'
            className='w-full'
            trailingIcon={showBreakdown ? <ArrowChevronUp /> : <ArrowChevronDown />}
            onClick={() => setShowBreakdown(!showBreakdown)}
          >
            {showBreakdown ? 'Hide' : 'Show'} assets details
          </Button>
        </div>
        {showBreakdown && (
          <>
            {!isXs && <AutoInvestOrderItemTable template={autoInvestment.template} />}
            {isXs && <AutoInvestOrderItemMobileTemplate template={autoInvestment.template} />}
          </>
        )}
      </CardContent>
    </Card>
  );
});

export { AutoInvestOrderItem };

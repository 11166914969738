import { CountryCode } from 'libphonenumber-js';
import parseMobile from 'libphonenumber-js/max';
import { z } from 'zod';

export const individualSchema = (mobileCountryCode: string) =>
  z
    .object({
      firstName: z.string().min(1, 'Please enter a valid first name'),
      lastName: z.string().min(1, 'Please enter a valid last name'),
      email: z.string().min(1).email('Please enter a valid email'),
      phone: z.string(),
    })
    .superRefine(({ phone }, ctx) => {
      if (mobileCountryCode && phone) {
        const pn = parseMobile(phone, mobileCountryCode as CountryCode);
        const validMobile = Boolean(pn && pn.isValid() && pn.country === mobileCountryCode);

        if (!validMobile) {
          ctx.addIssue({
            code: 'custom',
            path: ['phone'],
            message: 'Mobile phone number is invalid',
          });
          return false;
        }

        return true;
      }
    });

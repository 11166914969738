import React from 'react';

import { AccountStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { VerificationLevelCard } from './VerificationLevelCard';
import { useVerificationLevels } from '../VerificationLevel.data';

const DiamondCard: React.FC = observer(() => {
  const { userProfile, getAccountStatus, isKyc0Required, isKyc1Required, isKyc2Required } = UserStore.useUserStore;
  const verificationLevels = useVerificationLevels();
  const level = verificationLevels.find((verificationLevel) => verificationLevel.status === AccountStatusEnum.DIAMOND);
  const isRestricted = getAccountStatus() === AccountStatusEnum.DIAMOND_RESTRICTED;

  if (!level || !userProfile) return null;

  return (
    <VerificationLevelCard
      level={level}
      isRestricted={isRestricted}
      isRestrictedDisabled={!isKyc0Required() && !isKyc1Required() && isKyc2Required()}
      backgroundColor='bg-color-background-diamond'
    />
  );
});

export { DiamondCard };

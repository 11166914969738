import { ExchangesEnum } from '@shared/enums';

export enum BalanceFailureReasons {
  UnsupportedAsset = 'UNSUPPORTED_ASSET',
  WithdrawalFailed = 'WITHDRAWAL_FAILED', // This enum is also used internally, you shouldn't get this specifically from the balances endpoint
  BelowMin = 'BELOW_MINIMUM_WITHDRAWAL',
}

export interface MigratorStatus {
  Coinspot: number;
  Binance: number;
}

export interface MigratorBody {
  apiKey: string;
  apiSecret: string;
  exchange: ExchangesEnum;
}

export interface Network {
  networkId: number;
  code: string;
  withdrawalFee: string; // Big
  minWithdrawal: string; // Big
}

export interface BalanceFailure {
  balance: string;
  asset: { exchangeCode: string; swyftxId?: number };
  canMigrate: false;
  reason: BalanceFailureReasons;
  network?: Network;
}

export interface BalanceSuccess {
  balance: string;
  asset: { exchangeCode: string; swyftxId: number };
  canMigrate: true;
  network: Network;
}

export type BalanceResult = BalanceFailure | BalanceSuccess;

export type MigrateResponse = { success: true };

export type StatusV1Response = { [key in keyof ExchangesEnum]?: number };

export type StatusV2Response = { [key in keyof ExchangesEnum]?: { nextFeeFreeTransfer: number; hasTested: boolean } };

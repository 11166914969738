import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const StopLossPercentage: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.78588 4.5C1.98375 4.5 1.3335 5.15025 1.3335 5.95238C1.3335 6.75451 1.98375 7.40476 2.78588 7.40476C3.588 7.40476 4.23826 6.75451 4.23826 5.95238C4.23826 5.15025 3.588 4.5 2.78588 4.5ZM2.3335 5.95238C2.3335 5.70254 2.53603 5.5 2.78588 5.5C3.03572 5.5 3.23826 5.70254 3.23826 5.95238C3.23826 6.20222 3.03572 6.40476 2.78588 6.40476C2.53603 6.40476 2.3335 6.20222 2.3335 5.95238Z'
      fill='currentColor'
    />
    <path
      d='M8.84272 5.36071C9.03799 5.16545 9.03799 4.84887 8.84272 4.65361C8.64746 4.45835 8.33088 4.45835 8.13562 4.65361L1.49622 11.293C1.30096 11.4883 1.30096 11.8049 1.49622 12.0001C1.69148 12.1954 2.00806 12.1954 2.20333 12.0001L8.84272 5.36071Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.54792 9.26188C6.74579 9.26188 6.09554 9.91213 6.09554 10.7143C6.09554 11.5164 6.74579 12.1666 7.54792 12.1666C8.35005 12.1666 9.0003 11.5164 9.0003 10.7143C9.0003 9.91213 8.35005 9.26188 7.54792 9.26188ZM7.09554 10.7143C7.09554 10.4644 7.29808 10.2619 7.54792 10.2619C7.79776 10.2619 8.0003 10.4644 8.0003 10.7143C8.0003 10.9641 7.79776 11.1666 7.54792 11.1666C7.29808 11.1666 7.09554 10.9641 7.09554 10.7143Z'
      fill='currentColor'
    />
    <path
      d='M12.5165 5.3334C12.5165 5.05726 12.2926 4.8334 12.0165 4.8334C11.7404 4.8334 11.5165 5.05725 11.5165 5.3334L11.5165 10.1225L10.3792 8.98058C10.1844 8.78491 9.86781 8.78425 9.67214 8.97911C9.47648 9.17397 9.47582 9.49055 9.67068 9.68622L11.6529 11.6767C11.7357 11.7643 11.8498 11.822 11.9773 11.8319C11.9904 11.833 12.0036 11.8335 12.0168 11.8335C12.1498 11.8335 12.2773 11.7805 12.3711 11.6863L14.3626 9.68619C14.5575 9.49051 14.5568 9.17392 14.3611 8.97908C14.1654 8.78424 13.8488 8.78492 13.654 8.98061L12.5165 10.123L12.5165 5.3334Z'
      fill='currentColor'
    />
  </svg>
);

export { StopLossPercentage };

import React, { useContext, useMemo } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import { AssetType } from '@shared/api';
import { formatNumberWithCommasAndAllowTrailingDot } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { SwyftxProTradeContext } from 'src/lib/trade-pro/context';
import { useSwyftxProTradePrice } from 'src/lib/trade-pro/hooks/useSwyftxProTradePrice';
import { useSwyftxProTradeType } from 'src/lib/trade-pro/hooks/useSwyftxProTradeType';

const SwyftxProTradePriceInput: React.FC = observer(() => {
  const { baseAsset } = useContext(SwyftxProTradeContext);
  const { isMarket, isLimit } = useSwyftxProTradeType();
  const { triggerPrice, onChangeValue } = useSwyftxProTradePrice();

  const formattedPrice = useMemo(() => {
    if (!triggerPrice) return '';

    const currencyPrefix = baseAsset?.assetType === AssetType.Fiat ? '$' : '';
    const approximate = !isLimit ? '~' : '';
    const prefix = `${approximate} ${currencyPrefix}`;

    return formatNumberWithCommasAndAllowTrailingDot(triggerPrice, prefix);
  }, [baseAsset?.assetType, isLimit, triggerPrice]);

  return (
    <Input
      readOnly={isMarket}
      placeholder='0'
      value={formattedPrice}
      onChange={(e) => onChangeValue(e.target.value)}
      className={cn(
        'text-right font-mono',
        isMarket
          ? 'cursor-not-allowed text-color-text-secondary'
          : 'text-color-text-primary hover:outline-color-border-hover',
      )}
      containerClassName={cn(
        'bg-color-background-surface-primary hover:bg-color-background-surface-primary focus-within:!bg-color-background-surface-primary  outline outline-color-border-main',
        isMarket ? 'outline-dashed' : 'hover:outline-color-border-hover focus-within:!outline-color-border-accent',
      )}
      leading={
        <Body weight='emphasis' className='uppercase' size='medium' color='primary'>
          Price
        </Body>
      }
      trailing={
        <Numeric size='medium' color={isMarket ? 'secondary' : 'primary'} className='whitespace-nowrap'>
          {baseAsset?.code}
        </Numeric>
      }
    />
  );
});

export { SwyftxProTradePriceInput };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Tick } from '@swyftx/aviary/icons/outlined';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';
import { WarningMessageBox } from '@global-components/message-boxes/WarningMessageBox';

import { api } from '@shared/api';
import env from '@shared/config';
import { UIStore, UserStore } from '@shared/store';

import { useIntercomEvent } from '@hooks/useIntercomEvent/useIntercomEvent';

import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';

import { GreenIDResponse } from './GreenIDVerificationModal.data';

// To test Green ID values, use the following document to find the stub values
// https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/8880678/Data+Testing

type Props = {
  greenIdResponse?: GreenIDResponse;
  setGreenIdResponse: (greenIdResponse: GreenIDResponse) => void;
  onClose: (showConfirmation: boolean) => void;
};

const GreenIDFrame: React.FC<Props> = observer(({ greenIdResponse, onClose, setGreenIdResponse }) => {
  const [token, setToken] = useState<string>('');
  const { addMessageBox } = UIStore.useUIStore;
  const { greenIdError } = useIntercomEvent();
  const { userProfile, setUserProfile } = UserStore.useUserStore;

  const greenIdref = userProfile?.verification?.greenid.id;
  const { t } = useTranslation('profile.verification');

  useEffect(() => {
    (async () => {
      if (token) return;
      const result = await api.endpoints.getNewGreenIDToken({ query: { id: greenIdref } });
      setToken(result.data.token);
    })();
  }, [token, greenIdref]);

  useEffect(() => {
    if (!token) return;

    const anyWindow = window as any;

    anyWindow.greenidUI.updateSubmitted(false);

    anyWindow.greenidJQuery('document').ready(() => {
      anyWindow.greenidUI.setup({
        environment: env.GREEN_ID_ENV,
        frameId: 'greenid-container',
        errorCallback: async (newToken: string, error: string) => {
          if (newToken) {
            setToken(newToken);
            return;
          }
          switch (error) {
            case 'TokenTimeoutFault':
              addMessageBox({
                content: (
                  <WarningMessageBox
                    title={t('greenIdVerificationModal.labels.timeoutBoxTitle')}
                    content={t('greenIdVerificationModal.labels.timeoutBoxSubTitle')}
                  />
                ),
              });
              setToken('');
              return;
            default:
              // TODO: Handle other greenID errors
              greenIdError(error);
              onClose(false);
          }
        },
        sessionCompleteCallback: async (token: string) => {
          setToken(token);
          try {
            await api.endpoints.setGreenIDInfo({
              data: {
                verification: {
                  id: greenIdref,
                },
              },
            });

            api.endpoints.getProfile.resetCache();
            const profile = await api.endpoints.getProfile();
            setUserProfile(profile?.data?.user?.profile);

            onClose(false);

            addMessageBox({
              content: (
                <SuccessMessageBox
                  title={t('greenIdVerificationModal.labels.successBoxTitle')}
                  content={t('greenIdVerificationModal.labels.successBoxSubTitle')}
                />
              ),
            });
          } catch (e) {
            setGreenIdResponse(GreenIDResponse.EmailPhoneVerificationRequired);
            Sentry.captureException(e);
          }
        },
        sourceAttemptCallback: (token: string) => {
          setToken(token);

          //TODO - work out what we should do here
        },
        sessionCancelledCallback: () => {
          onClose(true);
        },
      });

      anyWindow.greenidUI.show(env.GREEN_ID_ACCOUNT_ID, env.GREEN_ID_API_KEY, token);
    });
  }, [token]);

  const renderErrorStates = () => {
    if (greenIdResponse === undefined) return null;

    switch (greenIdResponse) {
      case GreenIDResponse.EmailPhoneVerificationRequired:
        return t('greenIdVerificationModal.labels.emailPhoneVerificationRequired');
      case GreenIDResponse.Error:
        return t('greenIdVerificationModal.labels.error');
      case GreenIDResponse.Success:
      default:
        return (
          <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={2}>
            <Tick className='h-[7.5rem] w-[7.5rem] text-color-text-success' />
            <Typography fontWeight={600} fontSize={18}>
              {t('greenIdVerificationModal.labels.successTitle')}
            </Typography>
            <Typography fontSize={14} color='text.secondary'>
              {t('greenIdVerificationModal.labels.successSubTitle')}
            </Typography>
          </Stack>
        );
    }
  };

  return (
    <>
      <Box id='greenid-container' />
      {renderErrorStates()}
    </>
  );
});

export { GreenIDFrame };

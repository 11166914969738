import React from 'react';

import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Information } from '@swyftx/aviary/icons/outlined';

type Props = {
  tooltip?: string;
  align?: 'center' | 'start' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
};

const InfoTooltip: React.FC<Props> = ({ tooltip, align = 'center', side = 'top' }) => (
  <Tooltip title={tooltip} align={align} side={side}>
    <span>
      <Information className='text-color-primary h-14 w-14 hover:text-color-text-accent' />
    </span>
  </Tooltip>
);

export { InfoTooltip };

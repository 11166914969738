import v from 'validator';

import { Validator, ValidatorResponse } from '.';

class EmailValidator extends Validator<string> {
  validate = (input: string): ValidatorResponse => {
    const valid = v.isEmail(input || '');

    return {
      message: valid ? undefined : this.message,
      valid,
    };
  };
}

export default EmailValidator;

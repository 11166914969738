import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowInCircleDown } from '@swyftx/aviary/icons/outlined';

import { AppHeaderWalletPickerMenu } from './AppHeaderWalletPickerMenu';

type Props = {
  assetCode: string;
  type?: 'all' | 'trade' | 'earn';
};

const AppHeaderWalletPicker: React.FC<Props> = ({ assetCode, type }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('wallet');

  return (
    <>
      <Tooltip title={t('overview.appHeader.title')}>
        <FlexLayout
          onClick={(e) => setAnchorEl(e.currentTarget)}
          alignItems='center'
          justifyContent='center'
          spacing={2}
          className='text-color-text-primary hover:text-color-text-accent'
        >
          <Body weight='emphasis'>{assetCode.toUpperCase()}</Body>
          <ArrowInCircleDown className='ml-8 h-18 w-18' />
        </FlexLayout>
      </Tooltip>
      <AppHeaderWalletPickerMenu
        type={type}
        currentAsset={assetCode}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ maxHeight: '500px' }}
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: { maxWidth: '220px', minWidth: '220px' },
        }}
      />
    </>
  );
};

export { AppHeaderWalletPicker };

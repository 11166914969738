/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren, useContext, useMemo } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { SxProps } from '@mui/system';

import { Card } from '@swyftx/aviary/atoms/Card';

import { DragItemProps } from '@global-components/DragAndDrop/DragAndDrop.data';

import { DashboardTileEnum } from '@shared/enums';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { DashboardContext } from '@Dashboard/Dashboard.context';
import { MAXIMUM_COLUMN_SPACING } from '@Dashboard/Dashboard.data';
import { useDashboardRows } from '@Dashboard/hooks';

import { observer } from 'mobx-react-lite';
import { Draggable } from 'react-beautiful-dnd';

import { DashboardBaseTileEditActions } from './components/DashboardBaseTileEditActions';

type Props = PropsWithChildren<
  {
    id?: string;
    cardSx?: SxProps<{}>;
    contentSx?: SxProps<{}>;
    customHeaderBar?: React.ReactNode;
    divider?: boolean;
    headerBarActionItem?: React.ReactNode;
    headerSx?: SxProps<{}>;
    noBorder?: boolean;
    noPadding?: boolean;
    noHeader?: boolean;
    tileName: DashboardTileEnum;
    title?: string;
    allowCompact?: boolean;
    allowWide?: boolean;
    resizable?: boolean;
  } & DragItemProps
>;

const DashboardBaseTile: React.FC<Props> = observer(
  ({
    id,
    children,
    dragProps,
    headerBarActionItem,
    noHeader = false,
    allowCompact = true,
    allowWide = true,
    resizable = true,
    tileName,
    title,
    customHeaderBar,
  }) => {
    const { editMode, editTiles } = useContext(DashboardContext);
    const { getItemSpacing } = useDashboardRows();
    const { isLargeContent } = useContentBreakpoint();

    const tile = editTiles.find((editTile) => editTile.name === tileName);
    if (!tile) return null;

    const { display, metadata } = tile;
    const { size } = useMemo(() => metadata || {}, [metadata]);

    const renderDashboardTile = () => (
      <Card
        id={id}
        border
        shadow={false}
        className={`h-full max-h-[390px] overflow-hidden !rounded-[8px] ${
          editMode && !display ? 'opacity-50' : 'opacity-100'
        }`}
      >
        {(!noHeader || editMode) && (
          <CardHeader
            sx={{
              '.MuiCardHeader-content': {
                order: title ? 1 : 2,
              },
              '.MuiCardHeader-action': {
                order: title ? 2 : 1,
              },
            }}
            title={customHeaderBar ?? title}
            action={
              editMode ? (
                <DashboardBaseTileEditActions
                  tile={tile}
                  allowCompact={allowCompact}
                  allowWide={allowWide}
                  resizable={resizable}
                />
              ) : (
                headerBarActionItem
              )
            }
          />
        )}
        <CardContent sx={{ padding: 0, overflowY: 'auto', height: '100%', paddingBottom: '0 !important' }}>
          {' '}
          {(isLargeContent || !editMode) && children}
        </CardContent>
      </Card>
    );

    const wrapDraggable = () => (
      <Draggable
        key={dragProps?.id || ''}
        draggableId={dragProps?.id || ''}
        index={dragProps?.index || 0}
        isDragDisabled={!editMode || !dragProps}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              width: `${(getItemSpacing(size || 'compact') / MAXIMUM_COLUMN_SPACING) * 100}%`,
              paddingLeft: '8px',
              paddingRight: '8px',
              ...provided.draggableProps.style,
            }}
          >
            {renderDashboardTile()}
          </div>
        )}
      </Draggable>
    );

    if (!editMode && !display) return null;

    return dragProps ? wrapDraggable() : renderDashboardTile();
  },
);

DashboardBaseTile.displayName = 'DashboardBaseTile';

export { DashboardBaseTile };

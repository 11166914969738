import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import Big from 'big.js';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';
import { useHighSlippage } from 'src/lib/trade/hooks/Utils/useHighSlippage';
import { HIGH_SLIPPAGE_THRESHOLD } from 'src/lib/trade/trade.consts';

import { SwyftxProTradeHighSlippageWarning } from './SwyftxProTradeHighSlippageWarning';
import { SwyftxProTradeLimitedFunctionalityWarning } from './SwyftxProTradeLimitedFunctionalityWarning';
import { SwyftxProTradeLowLiquidityWarning } from './SwyftxProTradeLowLiquidityWarning';
import { SwyftxProTradeMultipleWarnings } from './SwyftxProTradeMultipleWarnings';
import { SwyftxProTradeContext } from '../../context';
import { useSwyftxProTradePrice } from '../../hooks/useSwyftxProTradePrice';

type Props = {
  selectedAsset?: Asset;
  baseAsset?: Asset;
};

const SwyftxProTradeWarnings: React.FC<Props> = observer(({ selectedAsset, baseAsset }) => {
  const { tradeType } = useContext(SwyftxProTradeContext);
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const { getRate } = RatesStore.useRatesStore;
  const { calculateSlippage } = useHighSlippage(baseAsset, selectedAsset);
  const { triggerPrice } = useSwyftxProTradePrice();
  const [isHighSlippage, setIsHighSlippage] = useState<boolean>();

  const isRestricted = useMemo(
    () => selectedAsset && !assetService.canWithdrawAndDepositAsset(selectedAsset),
    [selectedAsset],
  );

  const isLowLiquidity = useMemo(() => {
    if (!selectedAsset) return false;

    const rate = getRate(selectedAsset);
    return rate.buyLiquidityFlag || rate.sellLiquidityFlag;
  }, [getRate, selectedAsset]);

  const slippage = useMemo(() => {
    if (!triggerPrice) return undefined;

    return calculateSlippage(Big(triggerPrice));
  }, [calculateSlippage, triggerPrice]);

  useDebounce(
    () => {
      if (!slippage || tradeType !== 'market') {
        setIsHighSlippage(false);
        return;
      }

      setIsHighSlippage(Big(slippage || 0).gte(HIGH_SLIPPAGE_THRESHOLD));
    },
    50,
    [slippage, tradeType],
  );

  const hasMultipleWarnings = useMemo(() => {
    const warningCount = [isRestricted, isLowLiquidity, isHighSlippage].filter(Boolean).length;
    return warningCount > 1;
  }, [isHighSlippage, isLowLiquidity, isRestricted]);

  useEffect(() => {
    if (!hasMultipleWarnings && !showDetails) setShowDetails(true);
  }, [hasMultipleWarnings, showDetails]);

  if (!selectedAsset || !baseAsset || (!isRestricted && !isLowLiquidity && !isHighSlippage)) return null;

  return (
    <div className='bg-color-background-warning p-12'>
      <FlexLayout spacing={4} alignItems={showDetails ? 'start' : 'center'} justifyContent='space-between'>
        {!showDetails && <SwyftxProTradeMultipleWarnings />}
        {showDetails && (
          <FlexLayout direction='column' spacing={8}>
            {isRestricted && <SwyftxProTradeLimitedFunctionalityWarning selectedAsset={selectedAsset} />}
            {isLowLiquidity && (
              <SwyftxProTradeLowLiquidityWarning selectedAsset={selectedAsset} baseAsset={baseAsset} />
            )}
            {isHighSlippage && (
              <SwyftxProTradeHighSlippageWarning
                selectedAsset={selectedAsset}
                marketPrice={formatCurrency(getRate(selectedAsset).midPrice, baseAsset)}
                quotePrice={formatCurrency(triggerPrice, baseAsset)}
                slippagePercentage={slippage}
              />
            )}
          </FlexLayout>
        )}
        {hasMultipleWarnings && (
          <Button
            variant='ghost'
            layout='icon'
            tooltip={showDetails ? 'Hide details' : 'Show details'}
            leadingIcon={
              showDetails ? <ArrowChevronUp className='text-black' /> : <ArrowChevronDown className='text-black' />
            }
            color='inverse'
            size='sm'
            onClick={() => setShowDetails((prev) => !prev)}
          />
        )}
      </FlexLayout>
    </div>
  );
});

export { SwyftxProTradeWarnings };

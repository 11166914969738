import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const LiquidStaking: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M18.5058 9.22949C16.1205 5.80176 12.5507 3.46777 12.3998 3.37012C12.1518 3.20996 11.8324 3.20996 11.5844 3.37012C11.4335 3.46778 7.86369 5.80176 5.47892 9.2295C5.47355 9.23829 5.46818 9.2461 5.46281 9.25391C4.07511 11.4258 4.04435 14.4102 5.38517 16.8574C6.73966 19.3311 9.14787 20.75 11.9921 20.75C14.8363 20.75 17.245 19.3311 18.5995 16.8574C19.9403 14.4102 19.9096 11.4258 18.5058 9.22949ZM17.2841 16.1367C16.2006 18.1152 14.2719 19.25 11.9921 19.25C9.7128 19.25 7.78409 18.1152 6.7006 16.1377C5.62199 14.168 5.62931 11.79 6.71915 10.0742C8.4794 7.54883 11.0126 5.60938 11.9921 4.91113C12.9731 5.61035 15.5102 7.55078 17.2582 10.0625C18.3549 11.7783 18.3651 14.1631 17.2841 16.1367ZM12.1024 16.2812C12.0585 16.6631 11.7343 16.9453 11.3583 16.9453C11.3295 16.9453 11.3007 16.9434 11.2719 16.9404C9.79581 16.7705 8.57999 15.8281 8.01895 14.418C7.86563 14.0332 8.05362 13.5967 8.43838 13.4434C8.82168 13.291 9.25967 13.4785 9.4125 13.8633C9.58242 14.29 10.12 15.2978 11.4428 15.4502C11.8544 15.4971 12.1498 15.8692 12.1024 16.2812Z'
      fill='currentColor'
    />
  </svg>
);

export { LiquidStaking };

/* eslint-disable no-unused-vars */
import React, { ImgHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ArrowInCircleRight,
  ArrowLineOnlyRight,
  Bundles,
  Coin,
  Deposit,
  Game,
  Recurring,
  Trade,
} from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { OnboardingStepId } from '@routes/Onboarding/types/Onboarding.types';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useDemoMode } from 'src/lib/utils/hooks';

import { Confetti } from './components/Confetti';

type NextStepProps = {
  icon: React.FC<ImgHTMLAttributes<SVGElement>>;
  title: string;
  description: string;
  onClick: () => void;
};

const LOG_TAG = 'ONBOARDING_STEP_CONGRATULATIONS';

const NextStep = ({ icon: Icon, title, description, onClick }: NextStepProps) => (
  <Stack
    direction='row'
    sx={{
      border: '1px solid',
      borderColor: 'action.hover',
      borderRadius: '5px',
      paddingX: '1rem',
      paddingY: '1.5rem',
      cursor: 'pointer',
      backgroundColor: 'background.paper',
      zIndex: 1,
      '&:hover': {
        outline: 'solid #0072ED',
      },
    }}
    gap='16px'
    alignItems='center'
    onClick={onClick}
  >
    <Stack width='48px' height='48px' flexShrink={0} alignItems='center' justifyContent='center'>
      <Icon color='primary' width={32} height={32} />
    </Stack>
    <Stack direction='column' gap='4px'>
      <Typography fontWeight={500} fontSize={14}>
        {title}
      </Typography>
      <Typography fontWeight={400} fontSize={14} color='text.secondary'>
        {description}
      </Typography>
    </Stack>
    <Stack width='48px' height='48px' flexShrink={0} alignItems='center' justifyContent='center'>
      <ArrowLineOnlyRight className='h-32 w-32 text-color-text-primary' />
    </Stack>
  </Stack>
);

const CongratulationsStep: React.FC = () => {
  const onboardingService = useOnboardingService();
  const { exchange } = useOnboardingSelector((state) => state.context.assetMigratorData);
  const { setDemoMode } = useDemoMode();
  const avo = useAvo();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type CongratulationsSteps<T extends string> = T extends `congratulations${infer _Rest}` ? T : never;
  const stepId = onboardingService
    .getSnapshot()
    .toStrings()
    ?.slice(0, 2)
    .slice(-1)[0] as CongratulationsSteps<OnboardingStepId>;

  const isDeposited = stepId === 'congratulations.deposited';
  const isSkippedDeposit = stepId === 'congratulations.skippedDeposit';
  const isIDIssue = stepId === 'congratulations.idIssue';
  const isAssetsMigrating = stepId === 'congratulations.assetsMigrating';

  const step = (stepId.split('.')[1] ?? 'deposited') as 'deposited' | 'skippedDeposit' | 'idIssue' | 'assetsMigrating';

  const { t } = useTranslation('onboarding');

  const { userProfile } = UserStore.useUserStore;
  const { isLargeScreen } = useScreenBreakpoints();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const countryAsset = useCountryAsset();
  const { pathname } = useLocation();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const autoInvestEnabled = isFeatureEnabled(AppFeature.AutoInvest);

  const onDepositClick = () => {
    navigate(NavigationURLs.Dashboard);

    // navigating causes modals to close so we need to open it using a timeout
    setTimeout(() => {
      avo.depositFundsTapped({
        screen: pathname,
        component: LOG_TAG,
      });
      openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
    }, 0);
  };

  const onBundlesClick = () => {
    navigate(NavigationURLs.Bundles);
  };

  const onBuyClick = () => {
    navigate(NavigationURLs.UniversalTrade);
  };

  const onViewAssetsClick = () => {
    navigate(NavigationURLs.AssetsAll);
  };

  const onAutoInvestClick = () => {
    navigate(NavigationURLs.AutoInvest);
  };

  const onDemoClick = () => {
    setDemoMode(true);
    navigate(NavigationURLs.Dashboard);
  };

  const onDashboardClick = () => {
    navigate(NavigationURLs.Dashboard);
  };

  const confettiAnchor = isLargeScreen ? 50 : 120;
  const confettiHeightAdjust = confettiAnchor + 96;

  const renderIsDeposited = () => (
    <>
      <NextStep
        icon={Trade}
        title={t('steps.congratulations.actions.buy.title')}
        description={t('steps.congratulations.actions.buy.description')}
        onClick={onBuyClick}
      />
      <NextStep
        icon={Bundles}
        title={t('steps.congratulations.actions.bundles.title')}
        description={t('steps.congratulations.actions.bundles.description')}
        onClick={onBundlesClick}
      />
      {autoInvestEnabled && (
        <NextStep
          icon={Recurring}
          title='Auto Invest'
          description={t('steps.congratulations.actions.recurringOrders.description')}
          onClick={onAutoInvestClick}
        />
      )}
    </>
  );

  const renderIsSkippedDeposit = () => (
    <>
      {isFeatureEnabled(AppFeature.OnboardingDepositPage) && (
        <NextStep
          icon={Deposit}
          title={t('steps.congratulations.actions.deposit.title')}
          description={t('steps.congratulations.actions.deposit.description')}
          onClick={onDepositClick}
        />
      )}
      {isFeatureEnabled(AppFeature.AssetList) && (
        <NextStep
          icon={Coin}
          title={t('steps.congratulations.actions.viewAssets.title')}
          description={t('steps.congratulations.actions.viewAssets.description')}
          onClick={onViewAssetsClick}
        />
      )}
      {autoInvestEnabled && (
        <NextStep
          icon={Recurring}
          title='Auto Invest'
          description={t('steps.congratulations.actions.recurringOrders.description')}
          onClick={onAutoInvestClick}
        />
      )}
    </>
  );

  const renderIsIDIssue = () => (
    <>
      {isFeatureEnabled(AppFeature.DemoMode) && (
        <NextStep
          icon={Game}
          title={t('steps.congratulations.actions.demo.title')}
          description={t('steps.congratulations.actions.demo.description')}
          onClick={onDemoClick}
        />
      )}
      {isFeatureEnabled(AppFeature.AssetList) && (
        <NextStep
          icon={Coin}
          title={t('steps.congratulations.actions.viewAssets.title')}
          description={t('steps.congratulations.actions.viewAssets.description')}
          onClick={onViewAssetsClick}
        />
      )}
    </>
  );

  return (
    <Stack alignItems='center' spacing={2} position='relative'>
      <Confetti
        count={200}
        rate={12}
        position='absolute'
        height={`calc(100% + ${confettiHeightAdjust}px)`}
        top={-confettiAnchor}
        left={-50}
        right={-50}
        sx={{
          pointerEvents: 'none',
        }}
      />
      <div style={{ height: confettiAnchor }} />
      <Typography fontWeight={700} fontSize={{ xs: 20, sm: 24 }} align='center'>
        {t(`steps.congratulations.state.${step}.title`, {
          name: userProfile?.name?.first ?? '',
        })}
      </Typography>
      <Typography
        fontWeight={400}
        fontSize={{ xs: 14, sm: 16 }}
        color='text.secondary'
        align='center'
        paddingBottom={2}
      >
        {t(`steps.congratulations.state.${step}.subheading`, { exchange: exchange ?? '' })}
      </Typography>
      <Typography fontWeight={600} fontSize={{ xs: 14, sm: 18 }} align='center'>
        {t(`steps.congratulations.state.${step}.cta`)}
      </Typography>
      <Stack gap='1rem'>
        {(isDeposited || isAssetsMigrating) && renderIsDeposited()}
        {isSkippedDeposit && renderIsSkippedDeposit()}
        {isIDIssue && renderIsIDIssue()}
        <Button icon onClick={onDashboardClick} endIcon={<ArrowInCircleRight />}>
          {t(`labels.goToDashboard`)}
        </Button>
      </Stack>
    </Stack>
  );
};

export { CongratulationsStep };

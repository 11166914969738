import { types } from '@swyftx/marketing-utils';

import { RewardType } from './Rewards.data';

export type RewardsStateType = {
  rewards: { [key in RewardType]: types.UserAssetReward[] };
  actionable: types.UserAssetRewardActionableIds;
  fetchingRewards: boolean;
  fetchingActionable: boolean;
  isMock?: boolean;
  hasAvailableReward: boolean;
  hasRewardToRedeem: boolean;
  unseenRewards: number;
};

export type RewardsActionType = {
  fetchRewards: (userId: string, rewardType: RewardType) => void;
  fetchActionable: (userId: string) => void;
  redeemReward: (assetReward: types.UserAssetReward, userId: string) => void;
  optIn: (assetRewardId: number, userId: string) => void;
  viewRewards: () => void;
};

export interface RewardsStoreSchema extends RewardsStateType, RewardsActionType {}

export const RewardsInitialValues: RewardsStoreSchema = {
  rewards: { [RewardType.Available]: [], [RewardType.History]: [] },
  actionable: { canOptIn: [], canRedeem: [], inProgress: [] },
  fetchingRewards: true,
  fetchingActionable: true,
  unseenRewards: 0,
  hasAvailableReward: false,
  hasRewardToRedeem: false,
  fetchRewards: () => {},
  fetchActionable: () => {},
  redeemReward: () => {},
  optIn: () => {},
  viewRewards: () => {},
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useIntercom } from 'react-use-intercom';

import { Deposit } from './Deposit/Deposit';
import { DepositReceiveProps } from './Deposit/Deposit.types';
import { Receive } from './Receive/Receive';

export const TransferDepositReceive: React.FC<DepositReceiveProps> = ({
  selectedAsset,
  depositType,
  setDepositType,
  setDisabled,
  setOnAction,
  onClose,
  setHideContinue,
}) => {
  const [minimumDepositWarning, setMinimumDepositWarning] = useState<string>();
  const { t } = useTranslation('modals', { keyPrefix: 'transferModal.deposit' });
  const { userProfile } = UserStore.useUserStore;
  const userDepositsDisabled = useCallback(() => userProfile?.accountStatus?.deposits === 0, [userProfile]);
  const { show } = useIntercom();

  const isFiat = useMemo(() => assetService.isAssetFiat(selectedAsset?.id), [selectedAsset]);

  useEffect(() => {
    if (selectedAsset?.customWarningText) {
      setMinimumDepositWarning(selectedAsset.customWarningText.replace('<b>', '').replace('</b>', ''));
    } else {
      setMinimumDepositWarning('');
    }
  }, [selectedAsset]);

  if (userDepositsDisabled()) {
    return (
      <FlexLayout direction='column' spacing={24}>
        <Notification severity='destructive' title={t('userDepositsDisabled.title')}>
          <FlexLayout direction='column' spacing={16}>
            <Body color='secondary'>{t('userDepositsDisabled.message')}</Body>
            <div>
              <Button
                color='destructive'
                variant='filled'
                className='intercom-trigger-bot-deposits-disabled'
                onClick={show}
              >
                Contact support
              </Button>
            </div>
          </FlexLayout>
        </Notification>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout spacing={24} className='w-full' direction='column'>
      {minimumDepositWarning && (
        <Notification severity='warning' title='Minimum deposit'>
          {minimumDepositWarning}
        </Notification>
      )}

      {selectedAsset && !selectedAsset.deposit_enabled && (
        <Notification title={t('depositDisabledTitle')} severity='destructive'>
          {t('depositDisabled')}
        </Notification>
      )}

      {selectedAsset && !!selectedAsset.deposit_enabled && (
        <>
          {isFiat ? (
            <Deposit
              asset={selectedAsset}
              depositType={depositType}
              setDepositType={setDepositType}
              setDisabled={setDisabled}
              setOnAction={setOnAction}
              onClose={onClose}
              setHideContinue={setHideContinue}
            />
          ) : (
            <Receive asset={selectedAsset} />
          )}
        </>
      )}
    </FlexLayout>
  );
};

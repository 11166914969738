import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button, Link, Modal, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import { Links } from '@shared/constants';

import { useTheme } from '@hooks/useTheme';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { whatsInvolvedItems } from './FirstTimeVerificationModal.data';

const FirstTimeVerificationModal: React.FC = observer(() => {
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const { isLightTheme } = useTheme();
  const { t } = useTranslation('profile.verification');

  const onClose = () => {
    setVerificationStep(VerificationStep.None);
  };

  const onLinkClick = () => {
    window.open(Links.help.dueDiligence, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      HeaderProps={{
        title: t('firstTimeVerificationModal.title'),
        dismissible: true,
        divider: true,
      }}
      FooterProps={{
        content: (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing={3}
            sx={{ color: 'text.secondary' }}
          >
            <Button variant='text' color='inherit' onClick={onClose}>
              {t('firstTimeVerificationModal.buttonLabels.cancel')}
            </Button>
            <Button
              disableElevation
              variant='contained'
              onClick={() => setVerificationStep(VerificationStep.PersonalDetails)}
            >
              {t('firstTimeVerificationModal.buttonLabels.startVerification')}
            </Button>
          </Stack>
        ),
      }}
      sx={{ width: '600px', maxHeight: 'calc(100% - 2rem)', '.MuiCardContent-root': { height: '100%' } }}
      onClose={onClose}
      open
      variant='BottomSheet'
    >
      <Stack direction='column' spacing={2}>
        <Typography fontSize={14}>
          <Trans
            components={[
              <Link sx={{ cursor: 'pointer' }} onClick={onLinkClick} key='firstTimeVerificationModal.subTitle' />,
            ]}
            i18nKey='firstTimeVerificationModal.subTitle'
            t={t}
          />
        </Typography>
        <Typography fontWeight={600}>{t('firstTimeVerificationModal.labels.whatsInvolved')}</Typography>
        <Typography fontSize={14}>{t('firstTimeVerificationModal.labels.whatsInvolvedDescription')}</Typography>

        {whatsInvolvedItems.map((item) => (
          <Box padding={1} bgcolor={isLightTheme ? 'grey.50' : 'grey.700'} borderRadius={1} key={item.text}>
            <Stack direction='row' alignItems='center' spacing={2}>
              {item.icon}
              <Typography fontSize={12} fontWeight={600}>
                {t(item.text)}
              </Typography>
            </Stack>
          </Box>
        ))}

        <Notification
          title={t('firstTimeVerificationModal.labels.processTime')}
          alertTitleProps={{ sx: { fontSize: 14, marginBottom: 0, color: 'primary.light', fontWeight: 600 } }}
          sx={{ fontSize: 14, marginTop: 4, alignItems: 'flex-end' }}
          severity='info'
        />
      </Stack>
    </Modal>
  );
});

export { FirstTimeVerificationModal };

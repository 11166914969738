import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const SendCrypto: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 14C19.3133 14 22 11.3139 22 8C22 4.6867 19.3133 2 16 2C12.6867 2 10 4.6867 10 8C10 11.3139 12.6867 14 16 14ZM13.8094 8.32953C13.6142 8.13427 13.6142 7.81769 13.8094 7.62242L15.6479 5.78395C15.8432 5.58868 16.1597 5.58868 16.355 5.78395L18.1935 7.62242C18.3887 7.81769 18.3887 8.13427 18.1935 8.32953L16.355 10.168C16.1597 10.3633 15.8432 10.3633 15.6479 10.168L13.8094 8.32953Z'
      fill='currentColor'
    />
    <path
      d='M7.70711 11.7071C8.09763 11.3166 8.09763 10.6834 7.70711 10.2929C7.31658 9.90237 6.68342 9.90237 6.29289 10.2929L2.29289 14.2929C1.90237 14.6834 1.90237 15.3166 2.29289 15.7071C2.68342 16.0976 3.31658 16.0976 3.70711 15.7071L7.70711 11.7071Z'
      fill='currentColor'
    />
    <path
      d='M9.70711 15.7071C10.0976 15.3166 10.0976 14.6834 9.70711 14.2929C9.31658 13.9024 8.68342 13.9024 8.29289 14.2929L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L9.70711 15.7071Z'
      fill='currentColor'
    />
    <path
      d='M13.7071 16.2929C14.0976 16.6834 14.0976 17.3166 13.7071 17.7071L11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071C9.90237 19.3166 9.90237 18.6834 10.2929 18.2929L12.2929 16.2929C12.6834 15.9024 13.3166 15.9024 13.7071 16.2929Z'
      fill='currentColor'
    />
  </svg>
);

export { SendCrypto };

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddWithdrawAddressPayload, api, Asset } from '@shared/api';

import { AddAddressFormData, isCryptoAddressFormData } from '../TransferModal.types';

type Props = {
  asset: Asset;
};

const useAddWithdrawalAddress = ({ asset }: Props) => {
  const { t } = useTranslation('common');

  const formatNZAddress = (address: string) => {
    const match = address.match(/^(\d{2})(\d{4})(\d{7})(0?\d{2})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
    }
    throw new Error(`Failed to format NZ address. Invalid address provided`);
  };

  const buildAddressPayload = useCallback(
    (payload: AddAddressFormData): AddWithdrawAddressPayload['address'] => {
      if (isCryptoAddressFormData(payload)) {
        const { label, address, selectedCryptoNetworkId, metaDataPayload } = payload;

        return {
          label,
          address_details: {
            address,
            networkId: selectedCryptoNetworkId,
            ...metaDataPayload,
          },
        };
      } else {
        const { bsb, accountName, accountNumber, accountHolderName } = payload;
        const formattedAddress = asset.code.toUpperCase() === 'NZD' ? formatNZAddress(accountNumber) : accountNumber;

        return {
          label: accountName,
          address_details: {
            bsb,
            address: formattedAddress,
            nameOnAccount: accountHolderName,
          },
        };
      }
    },
    [asset.code],
  );

  const addWithdrawalAddress = useCallback(
    async (payload: AddAddressFormData, token?: string, code?: string) => {
      if (payload) {
        const auth = token && code ? `${token} ${code}` : undefined;

        const data = buildAddressPayload(payload);

        const response = await api.endpoints.addWithdrawAddress({
          params: { code: asset.code },
          data: {
            address: data,
            auth,
          },
        });
        return response.data;
      }

      throw new Error(t('addAddress.genericError'));
    },
    [asset.code, buildAddressPayload, t],
  );

  return {
    addWithdrawalAddress,
  };
};

export { useAddWithdrawalAddress };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeHLCArea: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.1655 5.96864C20.8703 5.67807 20.3954 5.68182 20.1049 5.97702L13.789 12.3935L10.3967 8.93838C10.2556 8.79462 10.0625 8.71369 9.86107 8.71382C9.65961 8.71395 9.46667 8.79512 9.32571 8.93906L2.82354 15.5784C2.53372 15.8744 2.53868 16.3492 2.83461 16.639C3.13054 16.9288 3.60539 16.9239 3.89521 16.6279L9.86223 10.535L13.2532 13.9887C13.3941 14.1322 13.5867 14.2132 13.7879 14.2133C13.989 14.2134 14.1817 14.1327 14.3228 13.9894L21.1739 7.02927C21.4644 6.73407 21.4607 6.25921 21.1655 5.96864Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.2297 5.38863C17.9857 5.14455 17.5899 5.14455 17.3459 5.38862L16.146 6.58846C15.9019 6.83254 15.9019 7.22827 16.146 7.47235C16.3901 7.71643 16.7858 7.71643 17.0299 7.47235L18.2297 6.27251C18.4738 6.02843 18.4738 5.63271 18.2297 5.38863ZM9.86436 5.2056C9.69761 5.20484 9.53747 5.27074 9.41956 5.38865L7.96783 6.84038C7.72375 7.08446 7.72375 7.48019 7.96783 7.72427C8.21191 7.96834 8.60763 7.96834 8.85171 7.72427L9.85743 6.71855L10.5936 7.4683C10.8354 7.7146 11.2311 7.71823 11.4774 7.4764C11.7237 7.23457 11.7273 6.83886 11.4855 6.59255L10.3075 5.39272C10.1906 5.27373 10.0311 5.20636 9.86436 5.2056ZM15.4301 8.18826C15.186 7.94418 14.7903 7.94417 14.5462 8.18825L13.7924 8.94208L13.0562 8.19233C12.8144 7.94603 12.4187 7.9424 12.1724 8.18423C11.9261 8.42606 11.9224 8.82177 12.1643 9.06808L13.3423 10.2679C13.4591 10.3869 13.6187 10.4543 13.7854 10.455C13.9522 10.4558 14.1123 10.3899 14.2302 10.272L15.4301 9.07214C15.6742 8.82806 15.6742 8.43233 15.4301 8.18826ZM6.91607 8.77602C6.67199 8.53194 6.27627 8.53194 6.03219 8.77602L4.58046 10.2278C4.33638 10.4718 4.33638 10.8676 4.58046 11.1116C4.82454 11.3557 5.22027 11.3557 5.46434 11.1116L6.91607 9.65991C7.16015 9.41583 7.16015 9.0201 6.91607 8.77602Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.2067 12.8886C17.9626 12.6445 17.5669 12.6445 17.3228 12.8886L16.123 14.0885C15.8789 14.3325 15.8789 14.7283 16.123 14.9723C16.3671 15.2164 16.7628 15.2164 17.0069 14.9724L18.2067 13.7725C18.4508 13.5284 18.4508 13.1327 18.2067 12.8886ZM9.84134 12.7056C9.67459 12.7048 9.51444 12.7707 9.39653 12.8887L7.9448 14.3404C7.70072 14.5845 7.70072 14.9802 7.9448 15.2243C8.18888 15.4683 8.58461 15.4683 8.82869 15.2243L9.8344 14.2185L10.5705 14.9683C10.8124 15.2146 11.2081 15.2182 11.4544 14.9764C11.7007 14.7346 11.7043 14.3389 11.4625 14.0926L10.2844 12.8927C10.1676 12.7737 10.0081 12.7064 9.84134 12.7056ZM15.4071 15.6883C15.163 15.4442 14.7673 15.4442 14.5232 15.6883L13.7693 16.4421L13.0332 15.6923C12.7914 15.446 12.3957 15.4424 12.1494 15.6842C11.903 15.9261 11.8994 16.3218 12.1413 16.5681L13.3193 17.7679C13.4361 17.8869 13.5956 17.9543 13.7624 17.955C13.9291 17.9558 14.0893 17.8899 14.2072 17.772L15.4071 16.5721C15.6511 16.3281 15.6511 15.9323 15.4071 15.6883ZM6.89305 16.276C6.64897 16.0319 6.25324 16.0319 6.00916 16.276L4.55743 17.7278C4.31336 17.9718 4.31336 18.3676 4.55743 18.6116C4.80151 18.8557 5.19724 18.8557 5.44132 18.6116L6.89305 17.1599C7.13712 16.9158 7.13712 16.5201 6.89305 16.276Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeHLCArea };

import React, { useContext, useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { PortfolioTransferContext } from '@routes/PortfolioTransfer/PortfolioTransfer.context';

interface Props {
  onNext: () => void;
}

const SuccessStep: React.FC<Props> = () => {
  const { exchange } = useContext(PortfolioTransferContext);

  const handleContinue = useCallback(
    () => () => {
      // already on the route, so just reload the page
      window.location.reload();
    },
    [],
  );

  return (
    <FlexLayout direction='column' spacing={32} className='items-center justify-center p-16 text-center'>
      <Image image='portfolio_transfer' className='h-[124px] w-[124px]' alt='portfolio transfer' usePalette={false} />
      <FlexLayout direction='column' spacing={8}>
        <Heading size='h3'>Your portfolio transfer has been initiated</Heading>
        <Body color='secondary'>
          We&apos;ve initiated your portfolio transfer from {exchange}. We&apos;ll send you an email once your transfer
          is complete. For peace of mind, we recommend disabling withdrawals on your API Key.
        </Body>
      </FlexLayout>

      <Button size='lg' onClick={handleContinue}>
        Transfer another portfolio
      </Button>
    </FlexLayout>
  );
};

export { SuccessStep };

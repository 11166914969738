import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

const AssetAutoCompleteItemSkeleton: React.FC = () => (
  <div className={cn('mt-8 min-h-[2.5rem] w-full px-12 py-8')}>
    <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
      <FlexLayout alignItems='center' spacing={12}>
        <div className='h-20 w-20 rounded-10 bg-color-background-surface-active' />
        <FlexLayout direction='column' alignItems='start' spacing={0}>
          <Body color='secondary' size='xsmall' loading>
            Bitcoin
          </Body>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='column' spacing={0} alignItems='end'>
        <Body color='primary' weight='emphasis' loading>
          BTC
        </Body>
      </FlexLayout>
    </FlexLayout>
  </div>
);

export { AssetAutoCompleteItemSkeleton };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { Bundle, useBundles } from '@hooks/Bundles/useBundles.hooks';

import { TradeAssetSelectorBundleItem } from './TradeAssetSelectorBundleItem';

const TradeAssetSelectorBundles: React.FC = () => {
  const { t } = useTranslation('trade');
  const { bundles } = useBundles();
  const { setTradeCache } = UniversalTradeStore;
  const [selectedBundle, setSelectedBundle] = useState<Bundle>();

  useEffect(() => {
    if (!selectedBundle) return;

    const bundleAssets: number[] = [];

    for (const asset of selectedBundle.assets) {
      bundleAssets.push(asset.id);
    }

    setTradeCache(bundleAssets, TradeSide.To, TradeAssetAction.Replace);
  }, [selectedBundle, setTradeCache]);

  const getTitle = () => (
    <Stack direction='row' alignItems='center' padding={2}>
      <Typography fontWeight={600} fontSize={14}>
        {t('bundles.title')}
      </Typography>
    </Stack>
  );

  return (
    <Stack direction='column' className='h-full overflow-y-auto'>
      {getTitle()}
      <Box>
        {bundles.map((bundle) => (
          <TradeAssetSelectorBundleItem
            key={bundle.title}
            bundle={bundle}
            setSelected={setSelectedBundle}
            selected={bundle.id === selectedBundle?.id}
          />
        ))}
      </Box>
    </Stack>
  );
};

export { TradeAssetSelectorBundles };

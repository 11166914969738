import React from 'react';

import BoxMUI from '@mui/material/Box';

import { ComponentProps, EventProps } from '../global-utils';

type Props = {
  icon: any;
} & ComponentProps &
  EventProps;

const Icon: React.FC<Props> = ({ id, icon, sx, onClick }) => (
  <BoxMUI id={id} component='div' sx={sx} display='flex' alignItems='center' justifyContent='center' onClick={onClick}>
    {icon}
  </BoxMUI>
);

export default Icon;

export enum NewsFeedTabId {
  All = 'news-feed-tab-all',
  Assets = 'news-feed-tab-assets',
  Categories = 'news-feed-tab-categories',
}

export type NewsFeedSearchFilterTabs = {
  id: NewsFeedTabId;
  label: string;
};

export const TabToPathMap: Record<NewsFeedTabId, string> = {
  [NewsFeedTabId.All]: 'All',
  [NewsFeedTabId.Assets]: 'Assets',
  [NewsFeedTabId.Categories]: 'Categories',
};

export const Tabs: NewsFeedSearchFilterTabs[] = [
  // commented out for now
  // {
  //   id: NewsFeedTabId.All,
  //   label: 'All',
  // },
  {
    id: NewsFeedTabId.Assets,
    label: 'Assets',
  },
  // {
  //   id: NewsFeedTabId.Categories,
  //   label: 'Categories',
  // },
];

import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import Recaptcha from '@components/molecules/Recaptcha';

import { StyledForm } from '@global-components/Form/Form.styled';

import env from '@shared/config';

import AuthenticationService from '@services/AuthenticationService';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ResetLinkSent } from '../';

type ForgotPasswordFormProps = {
  email: string;
};

type ForgotPasswordValues = {
  email: string;
  captchaToken: string;
};

const ForgotPasswordSchema = z.object({
  email: z.string().min(1).email(),
  captchaToken: env.DISABLE_CAPTCHA ? z.string().optional() : z.string().min(12),
});

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ email }) => {
  const [captchaRefresh, setCaptchaRefresh] = useState<boolean>(false);
  const [resetLinkSent, setResetLinkSent] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    watch,
  } = useForm<ForgotPasswordValues>({
    defaultValues: {
      email: email ?? '',
    },
    resolver: zodResolver(ForgotPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data: ForgotPasswordValues) => {
    await AuthenticationService.ResetPassword(data.email, data.captchaToken).catch(() => {
      setError('email', {
        type: 'API',
        message: 'Something went wrong, please try again or contact support',
      });
      setCaptchaRefresh(!captchaRefresh);
    });
    setResetLinkSent(true);
  };

  if (resetLinkSent) {
    return <ResetLinkSent email={watch('email')} />;
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FlexLayout className='h-full w-full' alignItems='center' direction='column'>
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <FlexLayout direction='column' spacing={4} className='w-full'>
              <Body size='small' weight='bold' color='primary'>
                Email address
              </Body>
              <Input
                placeholder='Email address'
                type='email'
                onChange={field.onChange}
                value={field.value}
                id='signIn.form.email'
                error={!!errors.email}
              />
            </FlexLayout>
          )}
        />

        <Controller
          name='captchaToken'
          control={control}
          render={({ field }) => (
            <div className='mt-36'>
              <Recaptcha
                id='forgotPassword.form.recaptcha'
                submitCaptcha={field.onChange}
                captchaRefresh={captchaRefresh}
              />
            </div>
          )}
        />

        <Button
          id='signIn.submit.button'
          size='lg'
          type='submit'
          variant='filled'
          disabled={!isValid}
          loading={isSubmitting}
          className='w-full'
        >
          Send reset link
        </Button>
      </FlexLayout>
    </StyledForm>
  );
};

import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

const useFetchDynamicPriceAlertAssetCountsCache = () => {
  const queryCache = useQueryClient();

  const invalidateCache = useCallback(
    () => queryCache.invalidateQueries(['dynamic-price-alerts-counts']),
    [queryCache],
  );

  return {
    invalidateCache,
  };
};

export { useFetchDynamicPriceAlertAssetCountsCache };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { TickInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';

import { useUserStore } from '@shared/store/userStore';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import { useOnboardingSelector, useOnboardingService } from '@routes/Onboarding/Onboarding.context';
import { OnboardingTranslationKey } from '@routes/Onboarding/translations';
import { EmailVerificationSteps } from '@routes/Onboarding/types/Onboarding.types';

import { CODE_LENGTH } from '@Register/types';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { OnboardingStepContainer } from './OnboardingStepContainer';

const INITIAL_CODE_TIMEOUT = 60;

const EmailVerificationStep: React.FC = () => {
  const currentSubState = useOnboardingSelector((state) => {
    const stateStrings = state.toStrings();

    const statePath = stateStrings[stateStrings.length - 1].split('.');

    return statePath[statePath.length - 1] as EmailVerificationSteps;
  });

  const onboardingService = useOnboardingService();
  const { error } = useOnboardingSelector((state) => state.context.emailCodeVerificationData);
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation(OnboardingTranslationKey);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const [code, setCode] = useState('');

  const { getMaskedEmail: emailAddress } = useUserStore;

  const handleUpdateNumberClicked = () => {
    navigate(NavigationURLs.ProfileInformation);
  };

  const handleResendCode = () => {
    onboardingService.send({ type: 'EMAIL_CODE_RESENT' });
  };

  const handlePasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setCode(text);
  };

  useEffect(() => {
    if (code.length === 6) {
      onboardingService.send({ type: 'EMAIL_CODE_INPUT', code });
    }
  }, [code, onboardingService]);

  return (
    <OnboardingStepContainer
      onSubmit={(e) => {
        e.preventDefault();
        onboardingService.send({ type: 'NEXT' });
      }}
    >
      <Stack flexDirection='column' alignItems='center' width='100%' marginBottom={3}>
        <Typography
          PII
          id='email-verification-step-description'
          marginBottom={5}
          fontSize={16}
          fontWeight={400}
          width='100%'
          color='text.secondary'
        >
          {t(`steps.setupProfile.emailVerification.description.sentCodeFragment`)}{' '}
          <Typography display='inline' fontWeight={600} color='text.secondary'>
            {emailAddress()}
          </Typography>
          . {t('steps.setupProfile.emailVerification.description.callToAction')}
        </Typography>

        <Button variant='text' sx={{ width: 'fit-content' }} onClick={handleUpdateNumberClicked}>
          {t('steps.setupProfile.emailVerification.buttonLabels.updateEmail')}
        </Button>

        <Stack direction='row' alignItems='center' marginTop={3} position='relative'>
          <Box>
            <CodeInput
              id='email-verification-step-code'
              length={CODE_LENGTH}
              error={error}
              value={code}
              onChange={(newCode: string[]) => setCode(newCode.join(''))}
              loading={currentSubState === 'verifyEmailCode'}
              forceDisabled={currentSubState === 'verifiedEmail'}
              hidePasteButton
            />
          </Box>
          {currentSubState === 'verifiedEmail' && (
            <TickInCircleFilled className='absolute right-[-40px] text-color-text-success' />
          )}
        </Stack>

        {currentSubState !== 'verifiedEmail' && (
          <Stack direction='row' spacing={2} alignItems='center' marginTop={4}>
            {hasClipboardPermission === 'granted' && (
              <Button variant='text' onClick={handlePasteFromClipboard} sx={{ marginTop: 0 }}>
                <Stack direction='row' alignItems='center' height='100%' spacing={1}>
                  <Paste className='h-20 w-20 text-color-text-primary' />
                  <Typography color='primary' fontSize='14px' fontWeight={500}>
                    {t('steps.emailVerification.buttonLabels.pasteClipboard')}
                  </Typography>
                </Stack>
              </Button>
            )}
            <CountdownDelay
              id='email-verification-step-resend-countdown'
              delay={INITIAL_CODE_TIMEOUT}
              delayText={t('steps.emailVerification.buttonLabels.resendCodeIn')}
              restartText={t('steps.emailVerification.buttonLabels.resendCode')}
              onTimerRestart={handleResendCode}
            />
          </Stack>
        )}
      </Stack>
    </OnboardingStepContainer>
  );
};

export { EmailVerificationStep };

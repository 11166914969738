import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { formatValueToShorthand } from '@swyftx/aviary/utils';
import { formatCurrency } from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api';

import { StatItem } from './StatItem';

interface DailyChangeProps {
  change?: string | null;
  baseAsset?: Asset;
  loading: boolean;
  direction: 'high' | 'low';
  className?: string;
}

export const DailyChange: React.FC<DailyChangeProps> = (props) => {
  const { change, baseAsset, loading, direction, className } = props;
  const isXs = useTailwindBreakpoint('xs');
  const isFiat = baseAsset?.assetType === AssetType.Fiat;

  return (
    <StatItem
      title={`24H ${direction}`}
      data={formatCurrency(change ?? '0', { isFiat })}
      loading={loading}
      className={className}
    >
      <FlexLayout direction='row' spacing={4}>
        <Numeric weight='emphasis'>{formatCurrency(change ?? '0', { isFiat })}</Numeric>
        {!isXs && <Numeric color='secondary'>{baseAsset?.code}</Numeric>}
      </FlexLayout>
    </StatItem>
  );
};

interface VolumeProps {
  volume?: string | null;
  asset: Asset;
  loading: boolean;
  className?: string;
}

export const DailyVolume: React.FC<VolumeProps> = (props) => {
  const { volume, asset, loading, className } = props;
  const isXs = useTailwindBreakpoint('xs');

  return (
    <StatItem
      title={isXs ? `24H volume (${asset.code})` : '24H volume'}
      data={formatValueToShorthand(volume ?? '0')}
      loading={loading}
      className={className}
    >
      <FlexLayout direction='row' spacing={4}>
        <Numeric weight='emphasis'>{formatValueToShorthand(volume ?? '0')}</Numeric>
        {!isXs && <Numeric color='secondary'>{asset.code}</Numeric>}
      </FlexLayout>
    </StatItem>
  );
};

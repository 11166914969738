import React from 'react';

import TypographyMUI, { TypographyProps } from '@mui/material/Typography';

import { getPIIClassName } from '../global-utils';
import { PIITypes } from '../types/PII.types';

type Props = TypographyProps & {
  number?: boolean;
} & PIITypes;

const Typography: React.FC<Props> = ({ id, children, color, number, PII, ...props }) => (
  <TypographyMUI
    id={id}
    className={`${getPIIClassName(PII)} ${number ? 'font-mono' : ''}`}
    color={color || 'text.primary'}
    {...props}
  >
    {children}
  </TypographyMUI>
);

export default Typography;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useTradeValue } from 'src/lib/trade/hooks/General';
import { usePriceScale } from 'src/lib/trade/hooks/General/usePriceScale';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { SwyftxProTradeContext } from '../../context';
import { MarketOcoTab } from '../../types/TradePro.types';
import { useSwyftxProTradeAssets } from '../useSwyftxProTradeAssets';

type Props = {
  tabType: MarketOcoTab;
};

const useSwyftxProTradeTakeProfit = ({ tabType }: Props = { tabType: 'price' }) => {
  const { takeProfitPrice, setTakeProfitPrice, triggerPrice, total, tradeType } = useContext(SwyftxProTradeContext);
  const [value, setValue] = useState<string>();
  const [dirty, setDirty] = useState<boolean>();

  const { selectedAsset, baseAsset } = useSwyftxProTradeAssets();
  const { lowestFee } = useCurrentUserFees();
  const { calculatePriceScale } = usePriceScale();

  const { formattedAmount, onChangeValue } = useTradeValue({
    amount: tradeType === 'oco' ? takeProfitPrice : value,
    limitAsset: selectedAsset,
    onChange: (val?: string) => {
      if (tradeType === 'oco') {
        setTakeProfitPrice(val || '');
      } else {
        setValue(val || '');
      }
    },
  });

  useEffect(() => {
    setDirty(false);
  }, [tabType, selectedAsset]);

  useEffect(() => {
    if (!triggerPrice || (takeProfitPrice && tradeType === 'oco')) return;

    if (selectedAsset && !dirty && tabType === 'price') {
      const triggerMultiple = Big(105).div(100);
      const newTakeProfitPrice = Big(triggerPrice).times(triggerMultiple.plus(lowestFee)).toString();
      onChangeValue(
        formatCurrency(Big(newTakeProfitPrice), selectedAsset, {
          hideSeparator: true,
          hideSymbol: true,
          hideCode: true,
        }),
      );
    }
  }, [
    calculatePriceScale,
    dirty,
    lowestFee,
    onChangeValue,
    selectedAsset,
    tabType,
    takeProfitPrice,
    tradeType,
    triggerPrice,
  ]);

  useEffect(() => {
    onChangeValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset]);

  const leadingText = useMemo(() => {
    switch (tabType) {
      case 'price':
        return 'TP Price';
      case 'percent':
        return 'Profit Target';
      case 'total':
        return 'Profit Amount';
      default:
        tabType satisfies never;
    }
  }, [tabType]);

  const leadingTooltip = useMemo(() => {
    switch (tabType) {
      case 'price':
        return 'Take profit price';
      case 'percent':
      case 'total':
        return '';
      default:
        tabType satisfies never;
    }
  }, [tabType]);

  const trailingText = useMemo(() => {
    switch (tabType) {
      case 'price':
      case 'total':
        return baseAsset?.code;
      case 'percent':
        return '%';
      default:
        tabType satisfies never;
    }
  }, [baseAsset?.code, tabType]);

  const resetValues = useCallback(() => {
    onChangeValue('');
    setTakeProfitPrice('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculatePercentageToPrice = useCallback(
    (percent: string) => {
      const triggerMultiple = Big(100).plus(percent).div(100);
      const newTakeProfitPrice = Big(triggerPrice).times(triggerMultiple.plus(lowestFee)).toString();
      setTakeProfitPrice(newTakeProfitPrice);
    },
    [lowestFee, setTakeProfitPrice, triggerPrice],
  );

  const calculateTotalToPrice = useCallback(
    (profitTotal: string) => {
      const totalPercentage = Big(profitTotal).div(total).times(100);
      const triggerMultiple = Big(100).plus(totalPercentage).div(100);
      const newTakeProfitPrice = Big(triggerPrice).times(triggerMultiple.plus(lowestFee)).toString();
      setTakeProfitPrice(newTakeProfitPrice);
    },
    [lowestFee, setTakeProfitPrice, total, triggerPrice],
  );

  useEffect(() => {
    if (tradeType === 'oco') return;

    if (!tabType) {
      return;
    }

    // Calculate the take profit price as we type with the various take profit types
    switch (tabType) {
      case 'price':
        setTakeProfitPrice(value || '');
        break;
      case 'percent':
        calculatePercentageToPrice(value || '');
        break;
      case 'total':
        calculateTotalToPrice(value || '');
        break;
      default:
        tabType satisfies never;
    }
  }, [value, tabType, setTakeProfitPrice, calculatePercentageToPrice, calculateTotalToPrice, tradeType]);

  return {
    value: formattedAmount,
    dirty,
    setDirty,
    leadingText,
    leadingTooltip,
    trailingText,
    takeProfitPrice,
    onChangeValue,
    resetValues,
  };
};

export { useSwyftxProTradeTakeProfit };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';

import { EntityAddMemberWizard } from '@global-components/Modals/EntityAddMemberModal/EntityAddMemberWizard';

import entityService from '@shared/services/entityService';
import { UserStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';

import { EntityMemberListItem } from './components/EntityMemberListItem';

type Props = {
  isAdmin: boolean;
};

const EntityMembersList: React.FC<Props> = observer(({ isAdmin }) => {
  const { entityMembers } = UserStore.useUserStore;
  const { t } = useTranslation('profile');
  const { isLargeContent } = useContentBreakpoint();
  const [addMember, setAddMember] = useState(false);
  const isEntityVerified = entityService.isEntityVerified();

  useEffect(() => {
    entityService.updateMembers();
  }, []);

  return (
    <>
      <FlexLayout direction='column' spacing={0}>
        {entityMembers.map((member, index) => (
          <>
            <EntityMemberListItem key={`entity-member-${member.uuid}`} isAdmin={isAdmin} member={member} />
            {index !== entityMembers.length - 1 && <Separator />}
          </>
        ))}

        {!isLargeContent && !isEntityVerified && (
          <Button onClick={() => setAddMember(true)}>{t('entities.entityMembers.button.addMember')}</Button>
        )}
      </FlexLayout>

      {addMember && <EntityAddMemberWizard open onClose={() => setAddMember(false)} />}
    </>
  );
});

export { EntityMembersList };

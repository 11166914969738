import React, { useCallback, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Settings } from '@swyftx/aviary/icons/outlined';

import { TradingViewContext } from '../../../context/TradingView.context';

const TradingViewWidgetHeaderSettings: React.FC = () => {
  const { advancedChartState } = useContext(TradingViewContext);

  const handleOpenSettings = useCallback(() => {
    if (!advancedChartState) return;

    advancedChartState.chart().executeActionById('chartProperties');
  }, [advancedChartState]);

  if (!advancedChartState) return null;

  return (
    <Button
      size='md'
      variant='ghost'
      color='subtle'
      layout='icon'
      leadingIcon={<Settings />}
      tooltip='Open chart settings'
      onClick={handleOpenSettings}
    />
  );
};

export { TradingViewWidgetHeaderSettings };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const VerifiedFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.65414 4.2089C5.30496 4.2089 4.20984 5.30402 4.20984 6.65427V7.76452C4.20984 8.411 3.95363 9.02937 3.4985 9.48774L2.71149 10.2758C1.75907 11.2326 1.76339 12.7807 2.72122 13.7331L3.4985 14.5115C3.95363 14.9677 4.20984 15.5882 4.20984 16.2325V17.3449C4.20984 18.6952 5.30496 19.7892 6.65414 19.7892H7.76655C8.41087 19.7892 9.03032 20.0454 9.48869 20.5006L10.2757 21.2876C11.2335 22.24 12.7816 22.2357 13.733 21.2779L14.5113 20.5006C14.9686 20.0454 15.5881 19.7892 16.2335 19.7892H17.3448C18.6951 19.7892 19.7902 18.6952 19.7902 17.3449V16.2325C19.7902 15.5882 20.0453 14.9677 20.5004 14.5115L21.2885 13.7234C22.241 12.7655 22.2355 11.2185 21.2788 10.2661L20.5004 9.48774C20.0453 9.02937 19.7902 8.411 19.7902 7.76452V6.65427C19.7902 5.30402 18.6951 4.2089 17.3448 4.2089H16.2335C15.5881 4.2089 14.9686 3.95377 14.5113 3.49864L13.733 2.72027L13.7232 2.71162C12.7665 1.7592 11.2184 1.76352 10.266 2.72027L9.48869 3.49864C9.03032 3.95377 8.41087 4.2089 7.76655 4.2089H6.65414ZM16.5031 10.0897C16.865 9.7278 16.865 9.14109 16.5031 8.77922C16.1412 8.41735 15.5545 8.41735 15.1926 8.77922L10.717 13.2549L8.80576 11.3444C8.44382 10.9827 7.85711 10.9828 7.49531 11.3447C7.13352 11.7067 7.13364 12.2934 7.49559 12.6552L10.062 15.2205C10.4239 15.5823 11.0105 15.5822 11.3724 15.2204L16.5031 10.0897Z'
      fill='currentColor'
    />
  </svg>
);

export { VerifiedFilled };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

interface Props {
  children: React.ReactNode;
  subtitle?: string;
}

export const EntityOnboardingPageLayout: React.FC<Props> = (props) => {
  const { children, subtitle } = props;

  return (
    <FlexLayout direction='column' spacing={32}>
      <FlexLayout direction='column' spacing={8}>
        <Body color='secondary'>{subtitle}</Body>
      </FlexLayout>
      {children}
    </FlexLayout>
  );
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { cn } from '@shared/utils/lib/ui';

const SelectAssetListItemSkeleton: React.FC = () => (
  <div className={cn('mt-8 min-h-[3.5rem] w-full px-12 py-8')}>
    <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
      <FlexLayout alignItems='center' spacing={12}>
        <div className='h-20 w-20 rounded-10 bg-color-background-surface-active' />
        <FlexLayout direction='column' alignItems='start' spacing={4}>
          <Body color='primary' weight='emphasis' size='small' loading>
            Bitcoin
          </Body>
          <Numeric color='secondary' size='xsmall' loading>
            Holdings: $1,523.90
          </Numeric>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='column' spacing={0} alignItems='end'>
        <Numeric color='primary' weight='emphasis' loading>
          $30,000
        </Numeric>
        <NumericDataItem size='medium' data='1.00%' loading>
          1.00%
        </NumericDataItem>
      </FlexLayout>
    </FlexLayout>
  </div>
);

export { SelectAssetListItemSkeleton };

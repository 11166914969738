import { createCronJob, CronJob, deleteCronJob, runCronJob } from './cronManager';

export abstract class CronJobService {
  updateInterval: number;

  job: CronJob | null = null;

  constructor(updateInterval: number) {
    this.updateInterval = updateInterval;
  }

  abstract update(): Promise<void>;

  public async init() {
    return this._init();
  }

  public cleanup() {
    return this._cleanup();
  }

  public async forceUpdate() {
    if (this.job) await runCronJob(this.job, true);
  }

  protected async _init() {
    if (this.job) deleteCronJob(this.job);
    // create new cron job
    this.job = createCronJob(this.updateInterval, () => this.update());
    // run immediately
    return this.forceUpdate();
  }

  protected _cleanup() {
    if (this.job) deleteCronJob(this.job);
    this.job = null;
  }
}

import React, { useCallback } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Stop } from '@swyftx/aviary/icons/outlined';

import { AccountStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useGlobalResolveRestrictions } from '@hooks/useGlobalResolveRestrictions';

import { observer } from 'mobx-react-lite';

type Props = {
  onClose: () => void;
};

type RestrictedStatus =
  | AccountStatusEnum.BRONZE_RESTRICTED
  | AccountStatusEnum.SILVER_RESTRICTED
  | AccountStatusEnum.GOLD_RESTRICTED
  | AccountStatusEnum.GOLD_NZ_RESTRICTED
  | AccountStatusEnum.DIAMOND_RESTRICTED;

const AccountRestrictedModal: React.FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'restrictedModal' });
  const resolveRestrictions = useGlobalResolveRestrictions();
  const { isRestricted, getAccountStatus } = UserStore.useUserStore;
  const accountStatus = getAccountStatus();

  const fetchAllRestrictions = useCallback((status: RestrictedStatus) => {
    const restrictionsToShow: Array<TFuncKey<'modals', 'restrictedModal'>> = [
      'restrictions.bronzeRestricted.item1',
      'restrictions.bronzeRestricted.item2',
    ];
    if ([AccountStatusEnum.BRONZE_RESTRICTED, AccountStatusEnum.SILVER_RESTRICTED].includes(status)) {
      return restrictionsToShow;
    }
    restrictionsToShow.push('restrictions.goldRestricted.item1');
    if ([AccountStatusEnum.GOLD_NZ_RESTRICTED, AccountStatusEnum.GOLD_RESTRICTED].includes(status)) {
      return restrictionsToShow;
    }
    restrictionsToShow.push('restrictions.diamondRestricted.item1');
    return restrictionsToShow;
  }, []);

  if (!isRestricted()) {
    onClose();
    return null;
  }

  const restrictedFunctionality = fetchAllRestrictions(accountStatus as RestrictedStatus);

  return (
    <Modal open id='restricted-modal' triggerElement={<></>} showCloseButton onClose={onClose}>
      <FlexLayout direction='column' className='p-24 text-center' spacing={8}>
        <Image image='account_restricted' usePalette={false} className='h-[100px] w-[100px]' />
        <Heading size='h4' className='pt-8'>
          {t('title')}
        </Heading>
        <Body color='secondary' size='small'>
          {t('description')}
        </Body>
        <Separator className='my-8' />
        <FlexLayout direction='column' spacing={8} className='pb-24 text-start'>
          <Body weight='bold'>{t('restrictions.title')}</Body>
          <FlexLayout direction='column' className='max-h-[10rem] overflow-y-auto' spacing={4}>
            {restrictedFunctionality.map((labelKey: TFuncKey<'modals', 'restrictedModal'>) => (
              <FlexLayout direction='row' key={labelKey} className='items-center' spacing={16}>
                <FlexLayout direction='row' className='!w-[1rem] items-center'>
                  <Stop className=' text-color-text-error' />
                </FlexLayout>
                <Body size='small'>{t(labelKey as unknown as TemplateStringsArray)}</Body>
              </FlexLayout>
            ))}
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='row' spacing={8} className='w-full'>
          <Button variant='outlined' onClick={onClose} className='w-full' size='lg'>
            {t('dismiss')}
          </Button>
          <Button onClick={resolveRestrictions} className='w-full' size='lg'>
            {t('resolve')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { AccountRestrictedModal };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Instagram: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8517 9.68529C10.3944 9.68529 9.20875 10.869 9.20875 12.3253C9.20875 13.7816 10.3944 14.9653 11.8517 14.9653C13.309 14.9653 14.4936 13.7816 14.4936 12.3253C14.4936 10.869 13.309 9.68529 11.8517 9.68529Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6703 8.56131C16.1231 8.56131 15.6767 8.11779 15.6767 7.57347C15.6767 7.02723 16.1231 6.58371 16.6703 6.58371C17.2175 6.58371 17.6629 7.02723 17.6629 7.57347C17.6629 8.11779 17.2175 8.56131 16.6703 8.56131ZM11.8511 16.4055C9.60086 16.4055 7.76918 14.5757 7.76918 12.3255C7.76918 10.0752 9.60086 8.24547 11.8511 8.24547C14.1023 8.24547 15.933 10.0752 15.933 12.3255C15.933 14.5757 14.1023 16.4055 11.8511 16.4055ZM16.0492 2.88867H7.95158C4.91894 2.88867 2.88086 5.02179 2.88086 8.19747V15.8199C2.88086 18.9946 4.91894 21.1287 7.95158 21.1287H16.0482C19.0818 21.1287 21.1209 18.9946 21.1209 15.8199V8.19747C21.1209 5.02179 19.0828 2.88867 16.0492 2.88867Z'
      fill='currentColor'
    />
  </svg>
);

export { Instagram };

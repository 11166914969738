import React, { PropsWithChildren, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, BodyLink, Numeric } from '@swyftx/aviary/atoms/Typography';

import { Links } from '@shared/constants';

type Props = {
  marketPrice?: string;
  quotePrice?: string;
  slippagePercentage?: number;
};

const SwyftxProHighSlippageModal: React.FC<PropsWithChildren<Props>> = ({
  marketPrice,
  quotePrice,
  slippagePercentage,
  children,
}) => {
  const [open, setOpen] = useState<boolean>();

  const slippageColor = useMemo(() => {
    if (!slippagePercentage) return 'error';

    if (slippagePercentage >= 5) {
      return 'error';
    } else if (slippagePercentage < 2) {
      return 'success';
    } else {
      return 'warning';
    }
  }, [slippagePercentage]);

  return (
    <Modal
      open={open}
      triggerElement={children}
      title='Price slippage warning'
      showCloseButton
      className='!min-w-[25rem] !max-w-[25rem]'
      onOpenChange={setOpen}
    >
      <FlexLayout spacing={16} direction='column' className='p-24 pt-0'>
        <div>
          <Body color='secondary' className='inline'>
            Based on your order amount, it is estimated to be impacted by a{' '}
          </Body>
          <BodyLink
            color='secondary'
            href={Links.learn.highSlippage}
            className='inline cursor-pointer underline decoration-dashed decoration-1 underline-offset-4'
          >
            price slippage
          </BodyLink>
        </div>

        {slippagePercentage && (
          <Numeric size='xxlarge' color={slippageColor}>
            ≈ {slippagePercentage.toFixed(2)}%
          </Numeric>
        )}
        <Body color='secondary'>Note: to reduce the amount of slippage, please enter a lower order amount.</Body>
        <FlexLayout spacing={12} direction='column'>
          <Body size='large' weight='emphasis'>
            How does this impact your order?
          </Body>
          {marketPrice && (
            <FlexLayout spacing={12} alignItems='center' justifyContent='space-between' className='w-full'>
              <Body weight='emphasis'>Current market price</Body>
              <Numeric color='secondary'>{marketPrice}</Numeric>
            </FlexLayout>
          )}
          {quotePrice && (
            <FlexLayout spacing={12} alignItems='center' justifyContent='space-between' className='w-full'>
              <Body weight='emphasis'>Quote price</Body>
              <Numeric color='secondary'>{quotePrice}</Numeric>
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { SwyftxProHighSlippageModal };

import { useCallback, useState } from 'react';

import { api, CreateOrderParams, CreateOrderResponse } from '@shared/api';
import { OrderSourceEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { balanceService, portfolioService, userStatisticsService } from '@shared/services';

import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

type Props = {
  onExecuteSuccess?: (response: CreateOrderResponse) => void;
  onExecuteFailure?: (error: string) => void;
};

const useExecuteTrade = ({ onExecuteFailure, onExecuteSuccess }: Props = {}) => {
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const { invalidateCache } = useTransactionsCache();

  const executeTrade = useCallback(
    async (data: CreateOrderParams, orderSource?: OrderSourceEnum) => {
      setIsExecuting(true);
      try {
        const response = await api.endpoints.createOrder({ data: { ...data, orderSource } });

        if (!response.data.orderUuid) {
          if (onExecuteFailure) onExecuteFailure('It looks like there was an error placing your order');
          return;
        }

        const updateValuesPromise = [
          balanceService.forceUpdate(),
          portfolioService.forceUpdate(),
          userStatisticsService.forceUpdate(),
        ];

        await Promise.all(updateValuesPromise);
        invalidateCache();

        if (onExecuteSuccess) onExecuteSuccess(response.data);
      } catch (e) {
        const { errorMessage } = e as SwyftxError;
        if (onExecuteFailure) onExecuteFailure(errorMessage);
      } finally {
        setIsExecuting(false);
      }
    },
    [invalidateCache, onExecuteFailure, onExecuteSuccess],
  );

  return { executeTrade, isExecuting };
};

export { useExecuteTrade };

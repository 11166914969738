interface TradeCandle {
  time: number;
  close: number;
}

function generateFakeTradeCandleData(weeks: number, roughValue: number): TradeCandle[] {
  const now = Date.now();
  const tradeCandles: TradeCandle[] = [];

  for (let i = 0; i < weeks; i++) {
    const time = now - i * 1000 * 60 * 60 * 24;
    const prevCandle = tradeCandles[i - 1];
    const close = prevCandle
      ? prevCandle.close + (Math.random() - 0.5) * roughValue
      : roughValue * 1.5 + Math.random() * 1000;

    tradeCandles.push({ time, close });
  }

  return tradeCandles;
}

export const mockLineChartData = (() =>
  generateFakeTradeCandleData(50, 30000).map((candle) => ({ value: candle.close, time: new Date(candle.time) })))();

export const smallMockLineChartData = (() =>
  generateFakeTradeCandleData(50, 10).map((candle) => ({ value: candle.close, time: new Date(candle.time) })))();

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset } from '@shared/api';
import { CreateAlertParams, CreateAlertResponse } from '@shared/api/@types/alerts';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';
import { UIStore } from '@shared/store';

import {
  CreateLimitAlertData,
  CreatePriceAlertType,
  CreateVolatilityAlertData,
  PriceAlertTriggerType,
  PriceAlertWindow,
} from '../../dynamicPriceAlerts.types';
import { useDynamicPriceAlertsAnalytics } from '../../hooks';
import { useCreatePriceAlert } from '../../hooks/useCreatePriceAlert';

type Props = {
  assets: Asset[];
  baseAsset: Asset;
  onClose: () => void;
  onChangeAssets?: () => void;
};

const useCreatePriceAlertModalContent = ({ baseAsset, assets, onClose }: Props) => {
  const [value, setValue] = useState<string>('');
  const [alertType, setAlertType] = useState<PriceAlertTriggerType>(assets.length > 1 ? 'VOLATILITY' : 'LIMIT');
  const [alertWindow, setAlertWindow] = useState<PriceAlertWindow | undefined>('DAY');
  const { priceAlertMultiAssetCreated } = useDynamicPriceAlertsAnalytics();

  const { addToastMessage } = UIStore.useUIStore;

  const type = useMemo((): CreatePriceAlertType => (assets.length > 1 ? 'multi' : 'single'), [assets.length]);

  useEffect(() => {
    setValue('');
  }, [alertType]);

  useEffect(() => {
    setAlertType(assets.length > 1 ? 'VOLATILITY' : 'LIMIT');
  }, [assets]);

  const { createDynamicPriceAlert, creatingAlert } = useCreatePriceAlert();

  const onCreatePriceAlert = useCallback(async () => {
    if (!baseAsset || assets.length === 0) return;

    try {
      if (alertType === 'LIMIT') {
        const [asset] = assets;
        const params: CreateAlertParams = { primary: baseAsset.id, secondary: asset.id };

        const data: CreateLimitAlertData = {
          price: value,
        };

        await createDynamicPriceAlert(params, data);
        addToastMessage({ severity: 'success', message: `${asset.code} price alert created` });
      } else {
        if (!alertWindow) return;

        const promises: Promise<CreateAlertResponse>[] = [];

        assets.forEach((asset) => {
          const params: CreateAlertParams = { primary: baseAsset.id, secondary: asset.id };
          const data: CreateVolatilityAlertData = {
            window: alertWindow,
            change: Big(value).div(100).toString(),
          };

          promises.push(createDynamicPriceAlert(params, data));
        });

        await Promise.all(promises);

        const assetIds = assets.map((a) => a.id);
        priceAlertMultiAssetCreated(assetIds);

        const message =
          assets.length === 1 ? `${assets[0].code} price alert created` : 'Multi-asset price alert created';

        addToastMessage({ severity: 'success', message });
      }

      onClose();
    } catch (e) {
      const swyftxError = e as SwyftxError;

      addToastMessage({ severity: 'error', message: swyftxError.errorMessage });
    }
  }, [
    addToastMessage,
    alertType,
    alertWindow,
    assets,
    baseAsset,
    createDynamicPriceAlert,
    onClose,
    priceAlertMultiAssetCreated,
    value,
  ]);

  return {
    open,
    type,
    creatingAlert,
    value,
    alertType,
    alertWindow,
    onCreatePriceAlert,
    setAlertType,
    setAlertWindow,
    setValue,
  };
};

export { useCreatePriceAlertModalContent };

import env from '@shared/config';

import { Methods, request } from '../rest';

const statusPageBaseUrl = env.STATUS_PAGE_URL;

const maintenanceEndpoints = {
  maintenanceSummary: () => request({ path: '/summary.json', method: Methods.GET, host: statusPageBaseUrl }),

  maintenanceInfo: () => request({ path: '/info/', method: Methods.GET }),
};

export default maintenanceEndpoints;

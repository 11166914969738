import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  selected: boolean;
  onClick: (assetCode: string) => void;
};

const AssetAutoCompleteItem: React.FC<Props> = observer(({ asset, selected, onClick }) => (
  <ListItem
    className={cn(
      'mt-2 min-h-[2.5rem] w-full',
      selected ? 'bg-color-background-surface-selected hover:bg-color-background-surface-selected' : '',
    )}
    onClick={() => !selected && onClick(asset.code)}
  >
    <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
      <FlexLayout alignItems='center' spacing={12} className={cn('w-full')} justifyContent='start'>
        <AssetIcon asset={asset} size={20} />
        <Body color='primary' weight='emphasis' className='w-3/4 truncate text-left  sm:whitespace-nowrap'>
          {asset.name}
        </Body>
      </FlexLayout>
      <Body color='secondary' size='small'>
        {asset.code}
      </Body>
    </FlexLayout>
  </ListItem>
));

export { AssetAutoCompleteItem };

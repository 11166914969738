import React, { useMemo } from 'react';

import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Chip, Stack } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import { AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

type Props = {
  isSmall?: boolean;
};

const PortfolioBreakdownBaseCurrency: React.FC<Props> = observer(({ isSmall = false }) => {
  const { portfolio } = usePortfolio({ balanceKey: 'all' });
  const { isLightTheme } = useTheme();
  const baseAsset = useBaseAsset();
  const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);

  const getColor = (val: Big, emptyValue: NumericProps['color']) => {
    if (val.gt(0)) return 'success';
    if (val.lt(0)) return 'error';

    return emptyValue;
  };

  const getChip = () => {
    const bigValue = Big(portfolio.percentChange || '0');

    if (bigValue.gt(0)) {
      return (
        <Chip
          color='success'
          sx={{ fontSize: 12, fontWeight: 500 }}
          label={`${Big(portfolio.percentChange || '0')
            .toNumber()
            .toLocaleString('en-US', { maximumFractionDigits: 2 })}%`}
          size='small'
        />
      );
    }

    if (bigValue.lt(0)) {
      return (
        <Chip
          color='error'
          sx={{ fontSize: 12, fontWeight: 500 }}
          label={`${Big(portfolio.percentChange || '0')
            .toNumber()
            .toLocaleString('en-US', { maximumFractionDigits: 2 })}%`}
          size='small'
        />
      );
    }

    return (
      <Chip
        sx={{
          fontSize: 12,
          fontWeight: 500,
          color: 'text.disabled',
          backgroundColor: isLightTheme ? 'grey.100' : 'grey.900',
        }}
        label={`${Big(portfolio.percentChange || '0')
          .toNumber()
          .toLocaleString('en-US', { maximumFractionDigits: 2 })}%`}
        size='small'
      />
    );
  };

  return (
    <Stack alignItems='center' justifyContent='space-between' direction='row' paddingX={2} paddingY={1}>
      {!isSmall ? <AssetIcon asset={baseAsset} size={36} /> : null}

      <Stack direction='column'>
        <FormattedText
          typographyProps={{ size: 'xxlarge', weight: 'emphasis' }}
          value={portfolio.accountValue}
          currency={baseAsset}
          formatOpts={{
            hideCode: true,
            displayPriceScale: true,
            showTrailingZero: true,
            appendCode: false,
            priceScale: isBaseAssetFiat ? 2 : baseAsset?.price_scale,
          }}
          secondaryText={{
            typographyProps: { size: 'xlarge', color: 'secondary', className: 'ml-8' },
            value: baseAsset?.code,
          }}
        />
        <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
          <FormattedText
            typographyProps={{
              color: getColor(portfolio.profit, 'secondary'),
            }}
            value={portfolio.profit}
            currency={baseAsset}
            formatOpts={{ displayPriceScale: true, showTrailingZero: true }}
          />
          {getChip()}
        </Stack>
      </Stack>
    </Stack>
  );
});

export { PortfolioBreakdownBaseCurrency };

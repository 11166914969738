import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Modals, modalToModalNameValueTypeMap } from '@global-components/Modals/Modals.enum';

import { UIStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

export const useModal = () => {
  const { setGlobalModalData } = UIStore.useUIStore;
  const { pathname } = useLocation();
  const avo = useAvo();

  const openModal = useCallback(
    (modal: Modals, props?: any) => {
      const modalName = modalToModalNameValueTypeMap[modal];
      avo.modalOpened({ modalName, screen: pathname });
      setGlobalModalData({ modal, props });
    },
    [setGlobalModalData],
  );

  const closeModal = useCallback(() => setGlobalModalData(), [setGlobalModalData]);

  return {
    openModal,
    closeModal,
  };
};

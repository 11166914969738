import { SourceOfWealthOption } from '@shared/api/@types/compliance';

export enum DocumentUploadFlow {
  SourceOfWealth = 'sourceOfWealth',
  EntityOnboarding = 'entityOnboarding',
}

export const uploadFileToS3 = (
  presignedUrl: string,
  file: File,
  onProgress: (percentage: number) => void,
  mock = false,
) =>
  new Promise<string>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', presignedUrl);

    xhr.onload = () => {
      res(xhr.responseURL);
    };
    xhr.onerror = (evt) => rej(evt);

    if (mock) {
      let percentage = 0;
      const interval = setInterval(() => {
        if (percentage >= 100) {
          clearInterval(interval);
          res('Mock upload completed');
          return;
        }
        percentage += 10;
        onProgress(percentage);
      }, 500);
      return;
    }

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 95;
        onProgress(Math.round(percentage));
      }
    };

    xhr.send(file);
  });

const suggestedDocuments: Record<SourceOfWealthOption, string[]> = {
  [SourceOfWealthOption.OCCUPATIONAL_INCOME]: [
    'Payslips, dated within the last 3 months',
    'Employment agreement from your current employer',
    'ATO statement from the most recent financial year',
  ],
  [SourceOfWealthOption.INVESTMENT_INCOME]: ['Portfolio statement, dated within the last 3 months'],
  [SourceOfWealthOption.RENTAL_INCOME]: [
    'Rental agreement, dated and signed)',
    'Bill / sale of property, dated and signed',
    'Property settlement statement (PEXA settlement statement)',
  ],
  [SourceOfWealthOption.BUSINESS_OWNERSHIP]: [
    'ASIC extract dated within the last year',
    'Company constitution, signed and executed',
  ],
  [SourceOfWealthOption.INTELLECTUAL_PROPERTY]: ['Trademark grant notification from IP Australia', 'Proof of patent'],
  [SourceOfWealthOption.SAVINGS]: ['Bank statement, no older than 3 months'],
  [SourceOfWealthOption.INHERITANCE]: ['Will, Probate documentation', 'Bank statement showing incoming inheritance'],
  [SourceOfWealthOption.GAMBLING_OR_LOTTERY]: ['Bank statement showing funds received from lottery / gambling service'],
  [SourceOfWealthOption.SELF_MANAGED_SUPER_FUND]: ['SMSF Rollover statement'],
};

export const generateDocumentSuggestions = (sourcesOfWealth: SourceOfWealthOption[], sourcesOfWealthOther?: string) => {
  const documents: string[] = [];
  sourcesOfWealth.forEach((source) => {
    documents.push(...suggestedDocuments[source]);
  });

  if (sourcesOfWealthOther && sourcesOfWealthOther !== '') {
    documents.push(`Any documentation to support your 'other' source of wealth: ${sourcesOfWealthOther}`);
  }
  return documents;
};

// Granular file sizes per flow as this will likely change in the future
export const maxFileSizePerFlow: Record<DocumentUploadFlow, number> = {
  [DocumentUploadFlow.SourceOfWealth]: 20000000, // 20mb
  [DocumentUploadFlow.EntityOnboarding]: 20000000, // 20mb
};

export const checkDocumentsAreValid = (documents: File[], flow: DocumentUploadFlow) => {
  const errorsToShow: string[] = [];
  documents.forEach((file) => {
    if (['application/pdf', 'image/jpeg', 'image/png'].includes(file.type) === false) {
      if (!errorsToShow.includes(`${file.type} is not supported`)) {
        errorsToShow.push(`${file.type} is not supported`);
      }
    }
    const maxFileSize = maxFileSizePerFlow[flow];
    if (file.size > maxFileSize) {
      const alreadyHasError = errorsToShow.some((error) => error.startsWith('Individual file size cannot exceed'));
      if (!alreadyHasError) {
        errorsToShow.push(`Individual file size cannot exceed ${maxFileSize / 1000000}mb`);
      }
    }
    if (file.name.length > 255) {
      if (!errorsToShow.includes(`File name cannot exceed 255 characters`)) {
        errorsToShow.push(`File names cannot exceed 255 characters`);
      }
    }
  });

  if (errorsToShow.length) {
    return errorsToShow.join('. ');
  }
  return undefined;
};

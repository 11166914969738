import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Information } from '@swyftx/aviary/icons/outlined';

import { AssetType } from '@shared/api';

import { SwyftxProTradeContext } from 'src/lib/trade-pro/context';
import { useSwyftxProTradeAssets } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from 'src/lib/trade-pro/hooks/useSwyftxProTradeDirection';
import { useSwyftxProTradeType } from 'src/lib/trade-pro/hooks/useSwyftxProTradeType';

import { SwyftxProMarketTakeProfit, SwyftxProMarketStopLoss } from './SwyftxProMarketOCO';
import { SwyftxProTradePriceInput } from './SwyftxProTradePriceInput';
import { SwyftxProTradeStopLossInput } from './SwyftxProTradeStopLossInput';
import { SwyftxProTradeTakeProfitInput } from './SwyftxProTradeTakeProfitInput';
import { SwyftxProTradeTotalInput } from './SwyftxProTradeTotalInput';
import { SwyftxProOrderTypesModal } from '../../../SwyftxProOrderTypesModal';

type Props = {
  error: React.ReactElement | null;
};

const SwyftxProTradeDetails: React.FC<Props> = ({ error }) => {
  const { showMarketStopLoss, showMarketTakeProfit } = useContext(SwyftxProTradeContext);
  const { isOco, isMarket } = useSwyftxProTradeType();
  const { isBuy } = useSwyftxProTradeDirection();
  const { baseAsset } = useSwyftxProTradeAssets();

  return (
    <FlexLayout className='w-full' direction='column' spacing={12}>
      {isOco ? (
        <>
          <SwyftxProTradeTakeProfitInput />
          <SwyftxProTradeStopLossInput />
        </>
      ) : (
        <SwyftxProTradePriceInput />
      )}
      <SwyftxProTradeTotalInput error={error} />
      {isMarket && isBuy && baseAsset?.assetType === AssetType.Fiat && (
        <>
          <Separator className='h-[0.5px] bg-color-border-main' />
          <SwyftxProMarketTakeProfit />
          <SwyftxProMarketStopLoss />
          {showMarketStopLoss && showMarketTakeProfit && (
            <FlexLayout alignItems='center' spacing={8}>
              <Information className='h-12 w-12 text-color-icon-accent' />
              <FlexLayout alignItems='center' spacing={4}>
                <Body size='xsmall' color='accent'>
                  These orders will be linked as an
                </Body>
                <SwyftxProOrderTypesModal>
                  <Body
                    size='xsmall'
                    color='accent'
                    className='cursor-pointer underline decoration-dashed underline-offset-4'
                  >
                    OCO order.
                  </Body>
                </SwyftxProOrderTypesModal>
              </FlexLayout>
            </FlexLayout>
          )}
        </>
      )}
    </FlexLayout>
  );
};

export { SwyftxProTradeDetails };

import { useCallback, useContext, useEffect, useMemo } from 'react';

import { AssetHistoryItem } from '@shared/api';

import { IChartApi, ISeriesApi, MouseEventParams, Time } from 'lightweight-charts';
import { DateTime } from 'luxon';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { useSimpleChartPriceTooltip } from './useSimpleChartPriceTooltip';
import { useSimpleChartVolumeTooltip } from './useSimpleChartVolumeTooltip';
import { TradingViewContext } from '../../context/TradingView.context';
import { isSimpleChartState } from '../../utils/Tradingview.utils';

type Props = {
  chartState?: IChartApi | IChartingLibraryWidget;
  chartContainerRef: React.MutableRefObject<HTMLInputElement>;
  chartPriceTooltipRef: React.MutableRefObject<HTMLDivElement>;
  chartVolumeRef: React.MutableRefObject<HTMLDivElement>;
  series?: ISeriesApi<'Area' | 'Bar' | 'Candlestick' | 'Line'>;
  volumeSeries?: ISeriesApi<'Histogram'>;
  transactions?: AssetHistoryItem[];
  onClickDate?: (date: DateTime) => void;
};

const useSimpleChartEvents = ({
  chartState,
  chartContainerRef,
  chartPriceTooltipRef,
  chartVolumeRef,
  series,
  volumeSeries,
  transactions,
  onClickDate,
}: Props) => {
  const { primary, secondary, showMarks } = useContext(TradingViewContext);
  const { getAssetByCode } = useMarkets();
  const primaryAsset = useMemo(() => getAssetByCode(primary), [getAssetByCode, primary]);
  const secondaryAsset = useMemo(() => getAssetByCode(secondary), [getAssetByCode, secondary]);
  const { buildPriceTooltip } = useSimpleChartPriceTooltip({
    chartContainerRef,
    chartPriceTooltipRef,
    primaryAsset,
    secondaryAsset,
    series,
    transactions,
  });

  const { buildVolumeTooltip } = useSimpleChartVolumeTooltip({
    chartContainerRef,
    chartVolumeRef,
    series: volumeSeries,
  });

  const onCrosshairMove = useCallback(
    (param: MouseEventParams<Time>) => {
      buildPriceTooltip(param);
      buildVolumeTooltip(param);
    },
    [buildPriceTooltip, buildVolumeTooltip],
  );

  const onClick = useCallback(
    (param: MouseEventParams<Time>) => {
      if (param.time && onClickDate && showMarks) {
        const date = DateTime.fromISO(param.time.toLocaleString());

        onClickDate(date);
      }
    },
    [onClickDate, showMarks],
  );

  useEffect(() => {
    if (!isSimpleChartState(chartState)) return;

    chartState.subscribeCrosshairMove(onCrosshairMove);

    chartState.subscribeClick(onClick);

    return () => {
      chartState?.unsubscribeCrosshairMove(onCrosshairMove);
      chartState?.unsubscribeClick(onClick);
    };
  }, [chartContainerRef, chartState, onClick, onCrosshairMove]);
};

export { useSimpleChartEvents };

import React, { useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { DangerFilled } from '@swyftx/aviary/icons/filled';

import { APIKey } from '@shared/api';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

type Props = {
  apiKey: APIKey;
  triggerContent?: React.ReactNode;
  onClose?: () => void;
};

const RevokeApiKeyModal: React.FC<Props> = observer(({ apiKey, triggerContent, onClose }) => {
  const { revokeKey } = useContext(ApiKeysContext);
  const [open, setOpen] = useState<boolean>(false);

  const onRevoke = () => {
    revokeKey(apiKey);
    if (onClose) onClose();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      triggerElement={triggerContent ?? <Button color='destructive'>Revoke key</Button>}
      title='Are you sure you want to revoke this key?'
    >
      <FlexLayout direction='column' spacing={16} className='p-24'>
        <FlexLayout className='justify-center'>
          <DangerFilled className='h-[7rem] w-[7rem] text-color-text-error' id='create-api-key-warning-step-icon' />
        </FlexLayout>
        <FlexLayout direction='column' spacing={8} className='text-center'>
          <Body>Performing this action will revoke the API key</Body>
          <Body color='secondary'>This action cannot be undone</Body>
        </FlexLayout>

        <FlexLayout direction='row' className='justify-end' spacing={8}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color='destructive' onClick={onRevoke}>
            Confirm
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { RevokeApiKeyModal };

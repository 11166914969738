import React from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowLineOnlyDown, ArrowLineOnlyUp } from '@swyftx/aviary/icons/outlined';
import { Typography } from '@swyftx/react-web-design-system';

import { AffiliateHistoryEntry } from '@Profile/types';

import { Column } from './PastPayouts.data';

type Props = {
  column: Column;
  sortKey: Extract<keyof AffiliateHistoryEntry, string>;
  setSortKey: (sortKey: Extract<keyof AffiliateHistoryEntry, string>) => void;
  sortDirection: 'asc' | 'desc' | 'default';
  setSortDirection: (sortDirection: 'asc' | 'desc' | 'default') => void;
};

const AffiliateDashboardPastPayoutsTableHeader: React.FC<Props> = ({
  column,
  sortKey,
  setSortKey,
  sortDirection,
  setSortDirection,
}) => {
  const { t } = useTranslation('profile.affiliates', { keyPrefix: 'affiliateDashboard.pastPayouts' });
  const { hidden, flex, align, label, accessor } = column;

  const updateSortDirection = () => {
    if (accessor === undefined) return;

    switch (sortDirection) {
      case 'default':
        setSortDirection('desc');
        break;
      case 'desc':
        setSortDirection('asc');
        break;
      default:
        setSortDirection('default');
        break;
    }

    if (sortKey !== accessor) setSortKey(accessor);
  };

  return (
    <Typography
      onClick={updateSortDirection}
      sx={{
        display: 'flex',
        justifyContent: align,
        userSelect: 'none',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          color: 'text.primary',
        },
      }}
      display={hidden ? 'none' : 'block'}
      fontSize={14}
      fontWeight={600}
      flex={flex}
      color='text.secondary'
    >
      {sortKey === accessor && (
        <>
          {sortDirection !== 'default' ? (
            <>
              {sortDirection === 'desc' ? (
                <ArrowLineOnlyDown className='mr-8 h-16 w-16 cursor-pointer' />
              ) : (
                <ArrowLineOnlyUp className='mr-8 h-16 w-16 cursor-pointer' />
              )}
            </>
          ) : (
            <ArrowLineOnlyDown className='mr-8 h-16 w-16 cursor-pointer' />
          )}
        </>
      )}
      {label.length > 0 && t(label as any)}
    </Typography>
  );
};

export { AffiliateDashboardPastPayoutsTableHeader };

import React from 'react';

import { DiscountFilled } from '@swyftx/aviary/icons/filled';
import { Swap, SwyftxCompact } from '@swyftx/aviary/icons/outlined';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { OverviewDataID } from '@global-components/charts/PieChart/PieChart.hooks';

import { assetService } from '@shared/services';

import { AssetColors } from '@utils/assets';
import { BalanceKey } from '@utils/balance';

import { WalletType } from '@Wallet/types';

import { STAKING_COLOR, TRADING_COLOR } from 'src/utils/color';

export const getBalanceKeyFromWalletType = (walletType: WalletType): BalanceKey => {
  if (walletType === WalletType.TRADING_WALLETS) {
    return 'availableBalance';
  }

  if (walletType === WalletType.EARNING_WALLETS) {
    return 'stakingBalance';
  }

  return 'all';
};

export const getBalanceKeyFromSelectedPortfolioItem = (
  selectedPortfolioItem: number | null,
  walletType: WalletType,
): BalanceKey => {
  if (selectedPortfolioItem === OverviewDataID.Trading || walletType === WalletType.TRADING_WALLETS) {
    return 'availableBalance';
  }

  if (selectedPortfolioItem === OverviewDataID.Staking || walletType === WalletType.EARNING_WALLETS) {
    return 'stakingBalance';
  }

  return 'all';
};

export const getPortfolioItemIcon = (
  selectedPortfolioItem: number | null,
  walletType: WalletType,
  assetCode?: string,
) => {
  // Shortcut if we already have the asset object
  if (assetCode) {
    return <AssetIcon asset={assetCode} size={36} />;
  }

  // Check our overview data IDs
  if (selectedPortfolioItem === OverviewDataID.Trading || walletType === WalletType.TRADING_WALLETS) {
    return <Swap className='h-[2rem] w-[2rem] text-white' />;
  }
  if (selectedPortfolioItem === OverviewDataID.Staking || walletType === WalletType.EARNING_WALLETS) {
    return <DiscountFilled className='h-[2rem] w-[2rem] text-white' />;
  }

  if (selectedPortfolioItem === null) {
    return <SwyftxCompact className='h-36 w-36 text-color-text-inverse' />;
  }

  // Last attempt
  const asset = assetService.getAsset(selectedPortfolioItem);
  return <AssetIcon asset={asset} size={24} />;
};

export const getPortfolioItemColor = (selectedPortfolioItem: number | null, assetCode?: string) => {
  if (selectedPortfolioItem === null) {
    return undefined;
  }

  // Shortcut if we already have the asset object
  if (assetCode) {
    return AssetColors[assetCode];
  }

  // Check our overview data IDs
  if (selectedPortfolioItem === OverviewDataID.Trading) {
    return TRADING_COLOR;
  }
  if (selectedPortfolioItem === OverviewDataID.Staking) {
    return STAKING_COLOR;
  }

  // Last attempt
  const asset = assetService.getAsset(selectedPortfolioItem);
  return asset?.code ? AssetColors[asset.code] : undefined;
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import PortfolioAssetTicker, {
  PortfolioTickerDisplay,
} from '@global-components/PortfolioAssetTicker/PortfolioAssetTicker';
import { FormattedText } from '@global-components/Text';

import { Asset, AssetType } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UserStore } from '@shared/store';

import { AssetBalanceData } from '@hooks/Assets/useAssetBalance';
import { usePortfolio } from '@hooks/usePortfolio';
import { BalanceKey } from '@utils/balance';

import { observer } from 'mobx-react-lite';

import { WalletListMobileTradingRow } from './WalletListMobileTradingRow';
import { WalletListPieChart } from '../../../WalletListPieChart';
import { WalletListRowAssetDetails } from '../WalletListRowAssetDetails';
import { WalletListRowLineChart } from '../WalletListRowLineChart';

type Props = {
  asset: Asset;
  assetBalanceData: AssetBalanceData;
  baseAsset: Asset;
  balanceKey: BalanceKey;
  assetBalanceKey: 'trading' | 'staking' | 'all';
};

const WalletListTradingRow: React.FC<Props> = observer(
  ({ asset, assetBalanceData, baseAsset, balanceKey, assetBalanceKey }) => {
    const { isDemo } = AppStore.useAppStore;
    const { t } = useTranslation('wallet', { keyPrefix: 'walletList' });
    const { userProfile } = UserStore.useUserStore;
    const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);

    const { portfolio } = usePortfolio({ balanceKey });

    const userAsset = assetService.getAsset(userProfile?.currency.id ?? FiatIdEnum.USD);
    const isFiat = asset && assetService.isAssetFiat(asset.id);
    const { accountValue } = portfolio;
    const { getRate } = RatesStore.useRatesStore;

    return (
      <>
        <WalletListMobileTradingRow
          asset={asset}
          assetBalanceData={assetBalanceData}
          assetBalanceKey={assetBalanceKey}
          userAsset={userAsset}
          className='flex @md:hidden'
        />

        <FlexLayout className='hidden w-full @container @md:flex' direction='row' alignItems='center'>
          {/* asset wallet and name */}
          <FlexLayout direction='row' className='w-3/12 @lg:w-2/12'>
            <WalletListRowAssetDetails asset={asset} balance={assetBalanceData[assetBalanceKey].balance} />
          </FlexLayout>

          {/* line chart */}
          <FlexLayout direction='row' justifyContent='center' alignItems='center' className='w-2/12'>
            {!isFiat && !isDemo && <WalletListRowLineChart asset={asset} baseAsset={baseAsset} />}
          </FlexLayout>

          {/* current price */}
          <FlexLayout alignItems='center' direction='row' className='hidden w-2/12 @lg:flex' justifyContent='end'>
            {!isFiat && !isDemo && (
              <FlexLayout direction='column' spacing={8} className='text-right'>
                <Body size='small' color='secondary' className='uppercase'>
                  {t('labels.currentPrice')}
                </Body>
                <FormattedText
                  typographyProps={{ color: 'primary', weight: 'none' }}
                  value={getRate(asset).midPrice ?? '0'}
                  currency={userAsset}
                  formatOpts={{ appendCode: true }}
                />
              </FlexLayout>
            )}
          </FlexLayout>

          {/* avg buy price */}
          <FlexLayout alignItems='center' direction='row' className='hidden w-2/12 @lg:flex' justifyContent='end'>
            {!isFiat && !isDemo && (
              <FlexLayout direction='column' spacing={8} className='text-right'>
                <Body size='small' color='secondary' className='uppercase'>
                  {t('labels.avgBuyPrice')}
                </Body>
                <FormattedText
                  typographyProps={{ color: 'primary', weight: 'none' }}
                  value={assetBalanceData.trading.averageBuyPrice ?? '0'}
                  currency={userAsset}
                  formatOpts={{ appendCode: true }}
                />
              </FlexLayout>
            )}
          </FlexLayout>

          {/* wallet value */}
          <FlexLayout alignItems='center' direction='row' className='w-1/3' justifyContent='end'>
            <FlexLayout direction='column' spacing={8} className='text-right' alignItems='end'>
              <FormattedText
                typographyProps={{ color: 'primary', weight: 'emphasis' }}
                value={assetBalanceData[assetBalanceKey].value}
                currency={baseAsset}
                dynamicCurrency={asset}
                formatOpts={{ appendCode: true, priceScale: isBaseAssetFiat ? 2 : baseAsset.price_scale }}
              />
              <FormattedText
                typographyProps={{ color: 'secondary' }}
                value={assetBalanceData[assetBalanceKey].balance ?? '0'}
                currency={asset}
                formatOpts={{ appendCode: true }}
                formatBalance
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout
            className='w-4/12 @lg:w-3/12'
            direction='row'
            justifyContent='end'
            alignItems='center'
            spacing={24}
          >
            {/* price tickers */}
            {!isDemo && (
              <FlexLayout className='w-auto text-end' direction='column' alignItems='center'>
                <FlexLayout direction='column' spacing={4} className='text-right' alignItems='end'>
                  <PortfolioAssetTicker
                    assetId={asset.id}
                    display={PortfolioTickerDisplay.Amount}
                    displayProps={{ priceScale: isBaseAssetFiat ? 2 : baseAsset.price_scale }}
                    typographyProps={{ weight: 'emphasis' }}
                  />
                  <PortfolioAssetTicker
                    assetId={asset.id}
                    chipProps={{ size: 'sm' }}
                    display={PortfolioTickerDisplay.Percentage}
                    isChip
                    typographyProps={{
                      weight: 'emphasis',
                      color: 'white',
                      className: 'mt-[3px]',
                    }}
                  />
                </FlexLayout>
              </FlexLayout>
            )}

            {/* pie chart */}
            <FlexLayout direction='row' alignItems='center' className='w-auto'>
              <WalletListPieChart
                asset={asset}
                walletValue={accountValue.toString()}
                assetValue={assetBalanceData[assetBalanceKey].value}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </>
    );
  },
);

export { WalletListTradingRow };

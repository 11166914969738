import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Loading, Percent, PriceAlert } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { PriceAlertEmpty } from 'src/lib/dynamic-price-alerts/components/PriceAlertEmpty/PriceAlertEmpty';
import {
  PriceAlertGroupBy,
  PriceAlertTriggerType,
  PriceAlertType,
} from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import { useFetchDynamicPriceAlerts } from 'src/lib/dynamic-price-alerts/hooks';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { DynamicPriceAlertTable } from './DynamicPriceAlertTable';
import { alertTypeMap, useDynamicPriceActiveAlertsTables } from './useDynamicPriceActiveAlertsTables';

type Props = {
  currentTab: PriceAlertType;
  filterTypes: PriceAlertTriggerType[];
  filterAssets: Asset[];
  groupBy: PriceAlertGroupBy;
  onRemoveAllFilters: () => void;
};

const DynamicPriceAlertsActiveTable: React.FC<Props> = observer(
  ({ filterAssets, filterTypes, groupBy, onRemoveAllFilters }) => {
    const { t } = useTranslation('priceAlerts');
    const { getAssetById } = useMarkets();

    const { data: alerts, isFetching } = useFetchDynamicPriceAlerts({
      secondary: filterAssets.map((a) => a.id),
    });

    const { groupedAlerts } = useDynamicPriceActiveAlertsTables({
      alerts,
      groupBy,
      filterAssets,
      filterTypes,
    });

    const getTableTitle = useCallback(
      (key: number) => {
        if (key === 0) return t('table.priceAlerts');
        return t('table.volatilityAlerts');
      },
      [t],
    );

    const getAlertTables = useCallback(() => {
      const alertKeys = groupedAlerts?.keys();

      if (!alertKeys || !groupedAlerts) return null;

      if (groupBy === 'asset') {
        return Array.from(alertKeys).map((key) => {
          const asset = getAssetById(key as number);
          const alerts = groupedAlerts.get(key);

          if (!asset || !alerts) return null;

          const [alert] = alerts;

          return (
            <DynamicPriceAlertTable
              key={`${key}_${alert.dateUpdated}`}
              groupBy={groupBy}
              title={asset.name}
              icon={<AssetIcon asset={asset} size={24} />}
              alerts={alerts}
            />
          );
        });
      }

      return Array.from(alertKeys)
        .sort()
        .map((key) => {
          const alerts = groupedAlerts.get(key);

          if (!alerts) return null;

          const [alert] = alerts;

          return (
            <DynamicPriceAlertTable
              key={`${key}_${alert.dateUpdated}`}
              title={getTableTitle(key as number)}
              groupBy={groupBy}
              icon={
                <Icon
                  icon={key === alertTypeMap.LIMIT ? <PriceAlert /> : <Percent />}
                  className='h-24 w-24'
                  color='info'
                />
              }
              alerts={alerts}
            />
          );
        });
    }, [getAssetById, getTableTitle, groupBy, groupedAlerts]);

    const hasFiltersActive = useMemo(() => {
      if (!alerts?.length) return false;
      return filterAssets.length > 0 || filterTypes.length > 0;
    }, [alerts?.length, filterAssets.length, filterTypes.length]);

    return (
      <>
        {!groupedAlerts ? (
          <Card className='h-full w-full p-24'>
            {isFetching ? (
              <FlexLayout alignItems='center' justifyContent='center' className='w-full'>
                <Loading className='animate-spin' />
              </FlexLayout>
            ) : (
              <PriceAlertEmpty
                alertType='active'
                filtersActive={hasFiltersActive}
                onRemoveAllFilters={onRemoveAllFilters}
              />
            )}
          </Card>
        ) : (
          getAlertTables()
        )}
      </>
    );
  },
);

export { DynamicPriceAlertsActiveTable };

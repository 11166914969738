import React from 'react';

import { Box } from '@mui/material';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { Droppable } from 'react-beautiful-dnd';
import { DragAndDropRowType } from 'src/global-components/DragAndDrop/DragAndDrop.data';

type Props = {
  row?: DragAndDropRowType;
  index: string;
  editMode?: boolean;
  placeholderProps?: any;
};

const DragAndDropRow: React.FC<Props> = ({ row, index, editMode, placeholderProps }) => {
  const isDroppable = editMode;

  const sourceIsDestinationId = placeholderProps?.destination.droppableId === placeholderProps?.source.droppableId;
  const sourceIsDestinationIndex = placeholderProps?.destination.index === placeholderProps?.source.index;

  const sourceIsDestination = sourceIsDestinationId && sourceIsDestinationIndex;

  const { isMediumContent } = useContentBreakpoint();

  // When all items in the row are not visible
  if (!editMode && row?.items.every((item) => !item.visible)) return null;

  let tileGap: string;
  if (editMode && !row) {
    tileGap = '0';
  } else {
    tileGap = '8px';
  }

  return (
    <Droppable droppableId={index} direction='horizontal' isDropDisabled={!isDroppable}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            display: 'flex',
            position: 'relative',
            paddingTop: tileGap,
            paddingBottom: tileGap,
            minHeight: '1px',
            width: '100%',
            flexWrap: !isMediumContent ? 'wrap' : 'unset',
            gap: '16px 0',
            margin: 0,
            overflowX: 'hidden',
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.droppableProps}
        >
          {row &&
            row.items.map((item, itemIndex) => {
              const props = {
                ...(item.component?.props as {}),
                key: `${itemIndex}_item`,
                dragProps: {
                  index: Number(itemIndex),
                  id: item.id,
                  rowItemCount: row.items.length,
                },
              };

              return React.cloneElement<any>({ ...item.component }, props);
            })}

          {provided.placeholder}

          {placeholderProps && snapshot.isDraggingOver && !sourceIsDestination && (
            <Box
              sx={{
                position: 'absolute',
                border: '2px dashed',
                borderColor: 'primary.light',
                borderRadius: 1,
                top: placeholderProps.clientTop,
                left: placeholderProps.clientLeft,
                height: placeholderProps.clientHeight,
                width: placeholderProps.clientWidth,
              }}
            />
          )}
        </div>
      )}
    </Droppable>
  );
};

export { DragAndDropRow };

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { clsx } from 'clsx';

import { UtilityProps, utilityVariants } from './Utility.styles';

const Utility: React.FC<UtilityProps> = ({ className, variant, color, ...props }) => (
  <p className={clsx(utilityVariants({ variant, color, className }))} {...props} />
);

export { Utility };

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import Tile from '@global-components/Tile';

import { UniversalTradeStore } from '@shared/store';
import {
  TradeAssetAction,
  TradeSide,
  TradeState,
  TradeType,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatDate } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { Bundle } from '@hooks/Bundles/useBundles.hooks';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { EntityPermissions, usePermissions } from '@hooks/Permissions';
import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AutoInvestAsset } from 'src/lib/auto-invest/autoInvest.types';
import { useAutoInvestAnalytics } from 'src/lib/auto-invest/hooks';
import { AssetIconsGroup } from 'src/lib/markets/components';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  bundle: Bundle;
};

const BundleListItem: React.FC<Props> = observer(({ bundle }) => {
  const { tradeFrom, setTradeAssets, setTradeType, setTradeState, setShowGlobalTrade } = UniversalTradeStore;
  const { canTrade } = useUniversalTradeUtilityStore();
  const { id, title, description, assets, helperLink, lastUpdatedAt } = bundle;
  const { t } = useTranslation('bundles', { keyPrefix: 'bundlesList' });
  const { t: bundlesT } = useTranslation('bundles');
  const baseAsset = useBaseAsset();
  const { isMobile } = useContentBreakpoint();
  const { pathname } = useLocation();
  const avo = useAvo();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isXs = useTailwindBreakpoint('xs');
  const { createAutoInvestClicked } = useAutoInvestAnalytics();

  const isAutoInvestFlagEnabled = isFeatureEnabled(AppFeature.AutoInvest);

  const getAssetIds = useCallback(() => assets.map((asset) => asset.id), [assets]);

  const getAssetBreakdowns = useCallback(
    () =>
      assets.map((a) => ({
        assetId: a.id,
        assetCode: a.code,
      })),
    [assets],
  );

  const buyBundle = () => {
    if (!assets || assets.length === 0) return;

    setTradeType(TradeType.Instantly);
    setTradeAssets(getAssetIds(), TradeSide.To, TradeAssetAction.Replace);
    setTradeState(TradeState.PlaceOrder);

    const fromAssets = getAssetBreakdowns();
    avo.bundleOpened({
      screen: pathname,
      layout: isMobile ? 'mobile' : 'desktop',
      tradeType: TradeType.Instantly,
      fromAssets,
    });

    const replaceFrom = baseAsset && tradeFrom.length !== 1;
    if (replaceFrom) {
      setTradeAssets([baseAsset.id], TradeSide.From, TradeAssetAction.Replace);
    }

    setShowGlobalTrade(true);
  };

  const setupRecurring = () => {
    if (!assets || assets.length === 0) return;

    setTradeType(TradeType.Recurring);
    setTradeAssets(getAssetIds(), TradeSide.To, TradeAssetAction.Replace);
    setTradeState(TradeState.PlaceOrder);

    avo.bundleOpened({
      screen: pathname,
      layout: isMobile ? 'mobile' : 'desktop',
      tradeType: TradeType.Recurring,
      fromAssets: getAssetBreakdowns(),
    });

    setShowGlobalTrade(true);
  };

  const setUpAutoInvest = () => {
    if (!assets || assets.length === 0) return;

    const selectedAssets: AutoInvestAsset[] = getAssetBreakdowns().map((asset) => ({
      assetCode: asset.assetCode,
      percentage: 0,
      locked: false,
    }));

    createAutoInvestClicked('bundles');

    navigate(NavigationURLs.AutoInvestCreate, { state: { selectedAssets } });
  };

  const getSubtitle = () => {
    const descriptionWithDate = `${bundlesT(description as any)} ${t('labels.lastUpdatedAt', {
      dateString: formatDate(lastUpdatedAt),
    })}`;

    if (!helperLink)
      return (
        <Body size='medium' color='secondary'>
          {descriptionWithDate}
        </Body>
      );

    return (
      <Body size='medium' color='secondary'>
        {descriptionWithDate}{' '}
        <Body
          color='accent'
          size='medium'
          weight='emphasis'
          className='inline cursor-pointer hover:underline'
          onClick={() => window.open(helperLink.link, '_blank', 'noopener,noreferrer')}
        >
          {helperLink.label}
        </Body>
      </Body>
    );
  };

  const { validKYC, element: SetupRecurringOrdersButton } = usePermissions({
    enabledElement: isAutoInvestFlagEnabled ? (
      <Button
        variant='outlined'
        onClick={setUpAutoInvest}
        disabled={!canTrade(false)}
        size={isXs ? 'lg' : 'md'}
        className={isXs ? 'w-full' : ''}
      >
        Create Auto Invest Order
      </Button>
    ) : (
      <Button
        variant='outlined'
        onClick={setupRecurring}
        disabled={!canTrade(false)}
        size={isXs ? 'lg' : 'md'}
        className={isXs ? 'w-full' : ''}
      >
        {t('buttons.setupRecurring')}
      </Button>
    ),
    disabledOptions: {
      disabled: true,
      tooltip: true,
      invalidScopeLabel: t('labels.noTrade'),
      invalidKYCLabel: t('labels.setupRecurringDisabled'),
    },
    permissions: [],
    // TODO permissions: [EntityPermissions.ORDERS_CREATE],
  });

  const { element: BuyBundleButton } = usePermissions({
    enabledElement: (
      <Button
        variant='filled'
        onClick={buyBundle}
        disabled={!validKYC || !canTrade(true)}
        size={isXs ? 'lg' : 'md'}
        className={isXs ? 'w-full' : ''}
      >
        {t('buttons.buy')}
      </Button>
    ),
    disabledOptions: {
      disabled: true,
      tooltip: true,
      invalidScopeLabel: t('labels.noTrade'),
      invalidKYCLabel: t('labels.buyDisabled'),
    },
    permissions: [EntityPermissions.ORDERS_CREATE],
  });

  return (
    <Card className='w-full p-16 sm:p-24'>
      <Tile
        id={id}
        translationKey='bundles'
        separator={false}
        title={<Heading size='h5'>{bundlesT(title as unknown as TemplateStringsArray)}</Heading>}
        subTitle={getSubtitle()}
        trailing={
          <FlexLayout direction='row' spacing={8} className='w-full @md:w-auto'>
            {SetupRecurringOrdersButton}
            {BuyBundleButton}
          </FlexLayout>
        }
      >
        <AssetIconsGroup assets={assets} size={24} alignment='left' />
      </Tile>
    </Card>
  );
});

export { BundleListItem };

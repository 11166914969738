import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UniversalTradeStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const PercentOptions = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '25%',
    value: 25,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
  {
    label: '100%',
    value: 100,
  },
];

const TradeMultiDetailsPercent: React.FC = observer(() => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeMultiDetails' });
  const { balances } = UserStore.useUserStore;
  const { convertRate } = RatesStore.useRatesStore;
  const { setTradeUIData, setTradeData, tradeUIData, tradeData } = UniversalTradeStore;
  const theme = useTheme();

  const onChange = (value: number) => {
    Object.keys(tradeUIData).forEach((key: string) => {
      const { limit, from, to } = tradeData[key];

      setTradeUIData(from, to, { percentage: value, loading: true });

      const fromAsset = assetService.getAsset(from);
      const toAsset = assetService.getAsset(to);

      if (!fromAsset || !toAsset) return;

      if (limit === from) {
        const newBalance = Big(balances[fromAsset.id].availableBalance)
          .times(Big(value.toString()).div(100))
          .round(fromAsset.price_scale, 0)
          .toString();
        setTradeData(from, to, { balance: newBalance });
      } else if (limit === to) {
        const newBalance = Big(balances[from].availableBalance)
          .times(Big(value.toString()).div(100))
          .round(fromAsset.price_scale, 0)
          .toString();

        const toValue = convertRate(fromAsset, toAsset, newBalance, 'askPrice')
          .round(toAsset.price_scale, 0)
          .toString();

        setTradeData(from, to, { balance: toValue });
      }
    });
  };

  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      spacing={1}
      padding={1}
      marginBottom={2}
      width='100%'
      sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 2 }}
    >
      <Typography color='text.primary' fontSize={14} fontWeight={600}>
        {t('labels.quickSet')}
      </Typography>
      <Typography color='primary' fontSize={14} fontWeight={400}>
        {t('labels.selectToggle')}
      </Typography>

      <Stack direction='row' width='100%' alignItems='center' justifyContent='flex-start' flexWrap='wrap' gap={0.5}>
        {PercentOptions.map((option) => (
          <Button
            key={option.value}
            variant='contained'
            disableElevation
            onClick={() => onChange(option.value)}
            sx={{
              '&.MuiLoadingButton-root': {
                backgroundColor: theme.palette.mode === 'light' ? 'grey.100' : 'grey.800',
                color: 'text.primary',
                border: '1px solid',
                borderColor: 'action.hover',
                '&:hover': {
                  border: '1px solid',
                  borderColor: 'primary.main',
                },
              },
            }}
          >
            {option.label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
});

TradeMultiDetailsPercent.displayName = 'TradeMultiDetailsPercent';

export { TradeMultiDetailsPercent };

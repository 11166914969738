import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { TabItem } from '@swyftx/aviary/atoms/Tabs';
import { Utility } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { Asset } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { WalletListHideZeroBalancesFilter } from '@routes/Wallet/components/WalletList/components/WalletListFilterBar/components';

import { observer } from 'mobx-react-lite';
import { DustSmallBalancesButton } from 'src/lib/markets/components';

interface Props {
  onSelectCurrency: (asset: Asset) => void;
}

const AppHeaderBaseCurrencySelectorBases: React.FC<Props> = observer(({ onSelectCurrency }) => {
  const baseAsset = useBaseAsset();

  const baseAssets = useMemo(() => {
    const bases = assetService.getBaseAssets();
    const assets: Asset[] = [];

    bases.forEach((assetId) => {
      const asset = assetService.getAsset(assetId);

      if (asset) assets.push(asset);
    });

    return assets;
  }, []);

  const baseCurrencyTabs: TabItem<string>[] = baseAssets.map((asset) => ({
    title: asset.code,
    value: asset.code,
    tooltip: asset.name,
  }));

  const onSelect = useCallback(
    (assetCode: string) => {
      const asset = assetService.getAssetByCode(assetCode);
      if (asset) {
        onSelectCurrency(asset);
      }
    },
    [onSelectCurrency],
  );

  return (
    <FlexLayout direction='column' spacing={4} className='w-full'>
      <Utility>Change base asset</Utility>
      <FlexLayout direction='row' className='w-full items-center justify-between p-6' spacing={16}>
        <EnhancedTabs<string>
          tabs={baseCurrencyTabs}
          value={baseAsset?.code}
          variant='child'
          onChange={onSelect}
          fullWidth
        />
        <FlexLayout direction='row' spacing={8} className='items-center'>
          <DustSmallBalancesButton layout='icon' iconClassName='w-20 h-20' />
          <WalletListHideZeroBalancesFilter />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AppHeaderBaseCurrencySelectorBases };

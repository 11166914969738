import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { DateRange } from '@swyftx/react-web-design-system';

import { CalendarPicker } from '@global-components/CalendarPicker';

import { CountriesEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { UIStore } from '@shared/store';
import { getTaxPresetOptions, PresetTaxOption } from '@shared/utils';

import { useTransactionReports } from '@hooks/TransactionReports/useTransactionReport';

import ct from 'countries-and-timezones';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { TaxPresetCard } from './TaxPresetCard';

type ReportType = 'pdf' | 'csv';

export const DownloadTaxReportCard: React.FC = observer(() => {
  const { t } = useTranslation('profile.taxreports', { keyPrefix: 'downloadTaxReport' });
  const [selectedTaxPreset, setSelectedTaxPreset] = useState<PresetTaxOption | null>(null);
  const [showCustomPicker, setShowCustomPicker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange<DateTime>>([DateTime.now().minus({ year: 1 }), DateTime.now()]);
  const [submitting, setSubmitting] = useState<ReportType>();

  const { addToastMessage } = UIStore.useUIStore;

  const { generateTransactionReport } = useTransactionReports();

  const country = useMemo(
    () =>
      ct.getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0]?.id as
        | CountriesEnum
        | CountriesEnum.AU,
    [],
  );

  const taxPresets = getTaxPresetOptions(country);

  const handlePresetClick = useCallback(
    (taxPreset: PresetTaxOption) => {
      if (selectedTaxPreset?.value === taxPreset.value) {
        setSelectedTaxPreset(null);
        setShowCustomPicker(false);
      } else {
        if (taxPreset.value === 'custom') {
          setShowCustomPicker(true);
        } else {
          setShowCustomPicker(false);
          setDateRange([taxPreset.startDate, taxPreset.endDate]);
        }
        setSelectedTaxPreset(taxPreset);
      }
    },
    [selectedTaxPreset?.value],
  );

  const downloadTaxReport = useCallback(
    async (reportType: ReportType) => {
      try {
        setSubmitting(reportType);

        if (!dateRange[0] || !dateRange[1]) {
          throw new SwyftxError('Tax Report', 'Invalid date range');
        }

        await generateTransactionReport(dateRange[0], dateRange[1], reportType);
      } catch (e) {
        // TODO still need to work out how to handle any errors here
        const swyftxError: SwyftxError = e as SwyftxError;
        addToastMessage({ message: swyftxError.errorMessage, severity: 'error' });
      }

      setSubmitting(undefined);
    },
    [addToastMessage, dateRange, generateTransactionReport],
  );

  return (
    <SettingsCluster
      title={t('title')}
      items={[
        <FlexLayout direction='column' spacing={16} className='p-16' key='download-tax-report-card'>
          <FlexLayout direction='row' className='flex flex-wrap'>
            {taxPresets.map((taxPreset) => (
              <FlexLayout className='w-full flex-col @sm:w-1/2 @sm:flex-row @md:w-1/3' key={taxPreset.value}>
                <TaxPresetCard
                  taxPreset={taxPreset}
                  selected={selectedTaxPreset?.value === taxPreset.value}
                  onClick={handlePresetClick}
                />
              </FlexLayout>
            ))}
          </FlexLayout>
          {showCustomPicker && (
            <FlexLayout direction='column' className='w-[380px]' spacing={4}>
              <Body size='small' weight='emphasis'>
                {t('labels.selectRange')}
              </Body>
              <CalendarPicker dateRange={dateRange} setDateRange={setDateRange} id='custom-tax-period-picker' />
            </FlexLayout>
          )}

          <FlexLayout direction='row' spacing={8}>
            <Button
              onClick={() => downloadTaxReport('csv')}
              loading={submitting === 'csv'}
              disabled={!selectedTaxPreset}
              color='primary'
            >
              {t('buttonLabels.downloadCsv')}
            </Button>
            <Button
              onClick={() => downloadTaxReport('pdf')}
              loading={submitting === 'pdf'}
              disabled={!selectedTaxPreset}
              variant='outlined'
            >
              {t('buttonLabels.downloadPdf')}
            </Button>
          </FlexLayout>
        </FlexLayout>,
      ]}
    />
  );
});

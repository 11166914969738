import React, { Dispatch, SetStateAction } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';

import { cn } from '@shared/utils/lib/ui';

import { FeatureCardActions } from './FeatureCardActions';
import { FeatureCardItem } from './FeatureCardItem';
import { FeatureCardMinimized } from './FeatureCardMinimized';
import { PlatformFeature } from '../../types/FeatureDiscovery.types';

type Props = {
  activeFeature?: PlatformFeature;
  setActiveFeature: React.Dispatch<React.SetStateAction<PlatformFeature | undefined>>;
  features: PlatformFeature[];
  minimized?: boolean;
  setMinimized: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const animateIn =
  'animate-[fade-in_forwards,feature-card-in_forwards] opacity-0 duration-300 !ease-[cubic-bezier(0, 0, 0.58, 1)]';
const animateOut = 'animate-[fade-out_forwards,feature-card-out_forwards] opacity-0 duration-500';

const FeatureCard: React.FC<Props> = ({
  activeFeature,
  setActiveFeature,
  features,
  minimized,
  onClose,
  setMinimized,
}) => (
  <>
    <Modal
      open={!minimized}
      onOpenChange={onClose}
      className='max-h-[100vh]'
      contentClassName={cn(
        'h-[100dvh] w-[100vw] overflow-hidden transition-all duration-500 sm:max-h-[42rem] sm:max-w-[40rem]',
        minimized ? animateOut : animateIn,
        activeFeature?.modalClassName,
      )}
    >
      <FlexLayout className='h-full min-h-[600px] w-full overflow-auto'>
        <FlexLayout
          direction='column'
          justifyContent='space-between'
          className='h-auto min-h-[600px] w-full bg-color-background-surface-primary'
          spacing={12}
        >
          <FeatureCardItem feature={activeFeature} onClose={onClose} />
          <FeatureCardActions
            features={features}
            activeFeature={activeFeature}
            onNextFeature={setActiveFeature}
            setMinimized={setMinimized}
            onClose={onClose}
          />
        </FlexLayout>
      </FlexLayout>
    </Modal>
    <FeatureCardMinimized
      activeFeature={activeFeature}
      minimize={minimized}
      onClose={onClose}
      setMinimized={setMinimized}
    />
  </>
);

export { FeatureCard };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronRight } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { ViewportLayout } from '@shared/events';
import { assetService } from '@shared/services/assetService';
import { AppStore, UniversalTradeStore, UserStore } from '@shared/store';
import { TradeAssetAction, TradeSide, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { EntityPermissions, usePermissions } from '@hooks/Permissions';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

interface Props {
  tradePanel?: boolean;
  dismissible?: boolean;
}

const TradeOrderDetailsType: React.FC<Props> = observer((props) => {
  const { tradePanel, dismissible } = props;
  const { t } = useTranslation('trade');
  const { tradeType, setTradeType, setTradeAssets, tradeFrom, tradeTo, setShowGlobalTrade } = UniversalTradeStore;
  const { pathname } = useLocation();
  const avo = useAvo();

  const baseAssets = assetService.getBaseAssets();
  const countryAsset = useCountryAsset();

  const { isUserVerified } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isDemo } = AppStore.useAppStore;
  const { enabled: canCreateRecurringOrder } = usePermissions({
    permissions: [EntityPermissions.ORDERS_CREATE],
  });

  const theme = useTheme();
  const layout: ViewportLayout = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop';

  const isVerified = isUserVerified();

  const changeTradeType = (newTradeType: TradeType) => {
    // Fire event for trade analytics: order type selected
    avo.tradeTypeSelected({
      screen: pathname,
      swyftxPro: 'false',
      layout,
      tradeType: newTradeType,
    });

    switch (newTradeType) {
      case TradeType.OnTrigger:
        if (tradeFrom.length > 1) {
          setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
        }

        if (tradeTo.length > 1) {
          setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
        }

        if (
          baseAssets.findIndex((a) => tradeFrom.includes(a) || tradeTo.includes(a)) === -1 &&
          tradeFrom.length > 0 &&
          tradeTo.length > 0
        ) {
          setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
          setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
        }
        break;
      case TradeType.Recurring:
        setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
        if (countryAsset) setTradeAssets([countryAsset.id], TradeSide.To, TradeAssetAction.Remove);
        break;
      default:
        break;
    }

    setTradeType(newTradeType);
  };

  const tradeTypeOptions = Object.values(TradeType)
    .filter((tradeTypeOption) => tradeTypeOption !== TradeType.Recurring)
    .map((tradeTypeOption) => ({
      title: tradeTypeOption,
      value: tradeTypeOption,
    }));

  if (tradePanel) {
    return (
      <FlexLayout direction='column' className='w-full overflow-x-hidden' spacing={8}>
        <FlexLayout direction='row' className='w-full items-center justify-between p-2'>
          <Body weight='emphasis' size='large' className='text-color-text-primary'>
            Order Type
          </Body>
          {dismissible && (
            <Button
              variant='outlined'
              layout='icon'
              leadingIcon={<ArrowChevronRight />}
              tooltip='Close trade panel'
              onClick={() => setShowGlobalTrade(false)}
            />
          )}
        </FlexLayout>
        <EnhancedTabs<typeof tradeType>
          variant='default'
          tabs={tradeTypeOptions}
          onChange={(value) => changeTradeType(value)}
          value={tradeType}
        />
      </FlexLayout>
    );
  }

  const nonTradePageTradeTypeOptions = Object.values(TradeType)
    .filter((nonTradePageTradeType) => {
      if (nonTradePageTradeType === TradeType.OnTrigger && !isFeatureEnabled(AppFeature.TriggerTrade)) {
        return false;
      }

      if (nonTradePageTradeType === TradeType.Recurring && isFeatureEnabled(AppFeature.AutoInvest)) {
        return false;
      }

      return true;
    })
    .map((nonTradePageTradeType) => {
      let isDisabled = false;
      let tooltip;

      if (nonTradePageTradeType === TradeType.Recurring) {
        if (isDemo) {
          isDisabled = true;
          tooltip = t('tradePanel.tabs.labels.demo');
        } else if (!canCreateRecurringOrder) {
          isDisabled = true;
          tooltip = t('tradePanel.tabs.labels.noPermissions');
        } else if (!isVerified) {
          isDisabled = true;
          tooltip = t('tradePanel.tabs.labels.verificationNeeded');
        }
      }

      return {
        title: nonTradePageTradeType,
        value: nonTradePageTradeType,
        disabled: isDisabled,
        tooltip,
      };
    });

  return (
    <Stack direction='column' spacing={1}>
      <Typography fontSize={14} fontWeight={500}>
        {t('tradePanel.labels.whenToPlace')}
      </Typography>
      <EnhancedTabs
        variant='default'
        tabs={nonTradePageTradeTypeOptions}
        onChange={(value) => changeTradeType(value)}
        value={tradeType}
      />
    </Stack>
  );
});

export { TradeOrderDetailsType };

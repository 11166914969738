import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Wizard from '@global-components/Wizard';

import { CancelVerificationModal } from '@routes/Profile/subroutes/Verification/components/VerificationMethod/CancelVerificationModal';

import { CreateEntityCreateStepsStep } from './steps/CreateEntityCreateStepsStep';
import { CreateEntityFormStep } from './steps/CreateEntityFormStep';
import { CreateEntityStartStep } from './steps/CreateEntityStartStep';

enum Steps {
  WhatIsAnEntity = 0,
  CreateSteps = 1,
  CreateEntity = 2,
}

type Props = {
  onClose: () => void;
};

const CreateEntityModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('entities');
  const [stepIndex, setStepIndex] = useState(0);
  const [showVerifyCancelModal, setVerifyCancelModal] = useState<boolean>(false);

  const goToWhatIsAnEntity = () => {
    setStepIndex(Steps.WhatIsAnEntity);
  };

  const goToSetupSteps = () => {
    setStepIndex(Steps.CreateSteps);
  };

  const goToTypeForm = () => {
    setStepIndex(Steps.CreateEntity);
  };

  const handleVerifyCancelClose = (closeParent: boolean) => {
    setVerifyCancelModal(false);
    if (closeParent) goToSetupSteps();
  };

  return (
    <>
      <Wizard.Container open onClose={onClose} stepIndex={stepIndex} width='600px'>
        <CreateEntityStartStep
          onClose={onClose}
          onAction={goToSetupSteps}
          title={t('createEntities.title')}
          actionName={t('buttonLabels.continue')}
          useModalHeader
          useModalFooter
        />
        <CreateEntityCreateStepsStep
          onClose={goToWhatIsAnEntity}
          onAction={goToTypeForm}
          title={t('createEntities.title')}
          useModalHeader
          useModalFooter
        />
        <CreateEntityFormStep
          onClose={() => setVerifyCancelModal(true)}
          title={t('createEntities.title')}
          useModalHeader
          useModalFooter
          hideActions
          style={{ height: '80vh' }}
        />
      </Wizard.Container>
      {showVerifyCancelModal && (
        <CancelVerificationModal
          open={showVerifyCancelModal}
          onClose={handleVerifyCancelClose}
          title={t('createEntities.setUp.cancelModalTitle')}
        />
      )}
    </>
  );
};

export { CreateEntityModal };

import { ChartTypeArea } from '@swyftx/aviary/icons/charts';
import { Coin, Star } from '@swyftx/aviary/icons/outlined';

import { AssetPickerTabTypes } from './AssetPicker.types';

{
  /* Placeholders for now, take with a grain of salt */
}
export const getEmptyIcon = (selectedTab: AssetPickerTabTypes) => {
  switch (selectedTab) {
    case 'favourites':
      return <Star className='h-50 w-50 text-color-text-accent' />;
    case 'gainers':
    case 'losers':
      return <ChartTypeArea className='h-50 w-50 text-color-text-accent' />;
    case 'all':
    case 'new':
    case 'owned':
    default:
      return <Coin className='h-50 w-50 text-color-text-accent' />;
  }
};

export const getEmptyContent = (selectedTab: AssetPickerTabTypes) => {
  switch (selectedTab) {
    case 'favourites':
      return 'You have no favourite assets';
    case 'gainers':
      return 'There are no gainers';
    case 'losers':
      return "The market must be hot, we can't find any losers";
    case 'owned':
      return "You don't own any assets";
    case 'all':
    case 'new':
    default:
      return 'No assets found';
  }
};

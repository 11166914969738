import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

export const LoadingMovers = [
  <ListItem key='loading_1'>
    <FlexLayout alignItems='center' className='w-full' spacing={12}>
      <div className='h-20 w-20 animate-pulse rounded-[100%] bg-color-background-surface-secondary' />
      <Body loading>Bitcoin</Body>
    </FlexLayout>
    <NumericDataItem data='0.0%' percentage loading size='medium' />
  </ListItem>,
  <ListItem key='loading_2'>
    <FlexLayout alignItems='center' className='w-full' spacing={12}>
      <div className='h-20 w-20 animate-pulse rounded-[100%] bg-color-background-surface-secondary' />
      <Body loading>Ethereum</Body>
    </FlexLayout>
    <NumericDataItem data='0.0%' percentage loading size='medium' />
  </ListItem>,
  <ListItem key='loading_3'>
    <FlexLayout alignItems='center' className='w-full' spacing={12}>
      <div className='h-20 w-20 animate-pulse rounded-[100%] bg-color-background-surface-secondary' />
      <Body loading>Ripple</Body>
    </FlexLayout>
    <NumericDataItem data='0.0%' percentage loading size='medium' />
  </ListItem>,
  <ListItem key='loading_4'>
    <FlexLayout alignItems='center' className='w-full' spacing={12}>
      <div className='h-20 w-20 animate-pulse rounded-[100%] bg-color-background-surface-secondary' />
      <Body loading>Cardano</Body>
    </FlexLayout>
    <NumericDataItem data='0.0%' percentage loading size='medium' />
  </ListItem>,
  <ListItem key='loading_5'>
    <FlexLayout alignItems='center' className='w-full' spacing={12}>
      <div className='h-20 w-20 animate-pulse rounded-[100%] bg-color-background-surface-secondary' />
      <Body loading>Ethereum Classic</Body>
    </FlexLayout>
    <NumericDataItem data='0.0%' percentage loading size='medium' />
  </ListItem>,
];

import React, { useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UIStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { useSort } from '@hooks/useSort/useSort';
import { useUserTradeDetails } from '@hooks/useUserTradeDetails';
import { getBalanceValue } from '@utils/balance';

import {
  WalletListFilterBar,
  WalletListNoSearchResultsFound,
  WalletListNoWallets,
  WalletListRow,
  WalletListTile,
} from '@Wallet/components/WalletList/components';
import { WalletLayoutType, WalletListSort, WalletSort } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';
import { useHideSmallWalletBalances } from 'src/lib/wallets/hooks/useHideSmallWalletBalances';

const WalletList: React.FC = observer(() => {
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;
  const { pageRef } = UIStore.useUIStore;
  const { balances } = usePortfolioBalance();

  const { isMobile } = useContentBreakpoint();

  const { sortWallets } = useSort();
  const { depositedFiat, tradedCrypto } = useUserTradeDetails();
  const { hideZeroBalances } = useHideSmallWalletBalances();
  const [walletSort, setWalletSort] = useState<WalletListSort>({
    key: WalletSort.Amount,
    ascending: false,
  });

  const [walletLayout, setWalletLayout] = useState<WalletLayoutType>(WalletLayoutType.LIST);
  const [walletSearch, setWalletSearch] = useState<string>('');

  const [searchResultFound, setSearchResultFound] = useState(false);

  // TODO: refactor to wallet store
  const wallets = useMemo(() => {
    const balanceKey = 'availableBalance';
    const walletInformation = balances;
    const walletKeys = Object.keys(walletInformation).map(Number);

    let filteredWalletKeys = walletKeys.filter(assetService.getAsset);

    if (hideZeroBalances) {
      filteredWalletKeys = walletKeys.filter((wallet) => {
        const assetBalance = getBalanceValue(balances[Number(wallet)], balanceKey);
        return Big(assetBalance).gte(getMinimumOrderAmount(wallet));
      });
    }

    return sortWallets(
      filteredWalletKeys,
      walletSort.ascending || false,
      walletSort.key || WalletSort.Amount,
      balanceKey,
    );
  }, [balances, hideZeroBalances, sortWallets, walletSort, getMinimumOrderAmount]);

  /** filter wallets based on user search value */
  const filteredWallets = useMemo(() => {
    if (walletSearch.length < 1) {
      setSearchResultFound(true);
      return wallets;
    }

    const searchedAssets = wallets.filter((wallet) => {
      const asset = assetService.getAsset(wallet);
      if (!asset) return false;
      const includesName = asset.name.toLowerCase().includes(walletSearch.toLowerCase());
      const includesCode = asset.code.toLowerCase().includes(walletSearch.toLowerCase());

      return includesName || includesCode;
    });

    setSearchResultFound(searchedAssets.length > 0);
    return searchedAssets;
  }, [walletSearch, wallets]);

  /** for performance only show 6 elements at a time */
  const shownWallets = useInfiniteScroll(pageRef, filteredWallets, {
    initialItemsLength: 20,
    addLengthAmount: 10,
    fromBottomTrigger: 150,
  });

  /**
   * If no wallets show tiles to deposit and trade crypto.
   * If never traded crypto show trade call to action
   * Even if no wallets staking still has wallet data
   */
  if (wallets.length < 1 || !tradedCrypto) {
    return <WalletListNoWallets depositedFiat={depositedFiat} wallets={wallets} />;
  }

  return (
    <FlexLayout direction='column' spacing={8} className='w-full'>
      <WalletListFilterBar
        search={walletSearch}
        setSearch={setWalletSearch}
        sort={walletSort}
        setSort={setWalletSort}
        layout={walletLayout}
        setLayout={setWalletLayout}
      />

      {!searchResultFound && <WalletListNoSearchResultsFound />}

      {searchResultFound && (
        <FlexLayout spacing={8} className='h-full w-full flex-wrap' direction='row'>
          {shownWallets.map((wallet) => (
            <React.Fragment key={`wallet-item-${wallet}`}>
              {walletLayout === WalletLayoutType.TILES && !isMobile ? (
                <WalletListTile id={`wallet-item-${wallet}`} assetId={wallet} />
              ) : (
                <WalletListRow id={`wallet-item-${wallet}`} assetId={wallet} />
              )}
            </React.Fragment>
          ))}
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

WalletList.displayName = 'WalletList';

export { WalletList };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  icon: React.ReactNode;
  title: string;
  value: string;
};

const SwyftxProFeeTiersItem: React.FC<Props> = ({ icon, title, value }) => (
  <FlexLayout className='w-full rounded-8 border border-color-border-main p-16'>
    {icon}
    <FlexLayout direction='column' className=''>
      <Body color='primary'>{title}</Body>
      <Body color='accent'>{value}</Body>
    </FlexLayout>
  </FlexLayout>
);

export { SwyftxProFeeTiersItem };

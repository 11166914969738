import React, { useCallback } from 'react';

import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { RecurringOrderTemplate } from '@shared/api/@types/recurringOrder';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AutoInvestmentOrderTableData, useAutoInvestOrderTable } from '../../hooks';

type Props = {
  template: RecurringOrderTemplate[];
};

const AutoInvestOrderItemTable: React.FC<Props> = ({ template }) => {
  const { initialSort, headers, data, onSort } = useAutoInvestOrderTable({ template });
  const { navigate } = useNavigateRoute();

  const handleOnClickItem = useCallback(
    (item: EnhancedTableData<AutoInvestmentOrderTableData, RecurringOrderTemplate>) => {
      if (!item.asset.value) return;

      navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: item.asset.value } });
    },
    [navigate],
  );

  return (
    <EnhancedTable<AutoInvestmentOrderTableData, RecurringOrderTemplate>
      sort={initialSort}
      headers={headers}
      data={data}
      onSort={onSort}
      className='border-t border-color-border-main'
      onClickItem={handleOnClickItem}
    />
  );
};

export { AutoInvestOrderItemTable };

import React, { useContext } from 'react';

import { PasswordResetContext } from '../Context/PasswordResetContext';
import { ContentData } from '../types';

export const PasswordResetCardContent: React.FC = () => {
  const context = useContext(PasswordResetContext);
  if (!context) {
    return null;
  }
  const { getStep } = context;

  return <>{ContentData[getStep()].content}</>;
};

import { GridSize } from '@mui/material';

import { useContentBreakpoint } from './useContentBreakpoint';

export type BreakpointInput = {
  xs: GridSize;
  sm: GridSize;
  md: GridSize;
  lg: GridSize;
  xl: GridSize;
};

// left-right temp params
export const useGridContentBreakpoints = (breakpoints: BreakpointInput) => {
  const { contentBreakpoint } = useContentBreakpoint();
  const currentContentBreakpointValue = breakpoints[contentBreakpoint as keyof BreakpointInput];
  return currentContentBreakpointValue;
};

import { Theme } from '@mui/material';

import { PieChartData } from '../PieChart.types';

export const getPlaceholderPieData = (theme: Theme): PieChartData => {
  const isDarkMode = theme.palette.mode === 'dark';
  const color = isDarkMode ? theme.palette.grey[700] : theme.palette.grey[200];

  return [
    {
      color,
      id: 0,
      name: '0',
      value: 40,
    },
    {
      color,
      id: 1,
      name: '1',
      value: 20,
    },
    {
      color,
      id: 2,
      name: '2',
      value: 20,
    },
    {
      color,
      id: 3,
      name: '3',
      value: 20,
    },
  ];
};

import React, { useCallback, useMemo } from 'react';

import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { AtLeastBreakpoints } from '@swyftx/aviary/hooks/breakpoints';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';
import { PortfolioBreakdownCard } from '@swyftx/aviary/organisms/Portfolio/PortfolioBreakdownCard';

import { AppStore } from '@shared/store';
import { formatCurrency, getValuePriceScale } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { usePortfolioTable } from '../../hooks';
import { usePortfolioBreakdown } from '../../hooks/usePortfolioBreakdown';
import { PortfolioTableData } from '../../types';

export type PortfolioBreakdownWidgetProps = {
  className?: string;
  showDefaultLegend?: boolean;
  showLegendPercentages?: boolean;
  size?: number;
  atLeast?: AtLeastBreakpoints;
};

const PortfolioBreakdownWidget: React.FC<PortfolioBreakdownWidgetProps> = observer(
  ({ className, showLegendPercentages, showDefaultLegend = true, size, atLeast }) => {
    const { externalMutations, setExternalMutations } = useDonutChartMutations();
    const { breakdownData } = usePortfolioBreakdown();
    const { headers, data, initialSort, onSort } = usePortfolioTable({
      variant: 'compact',
    });
    const { navigate } = useNavigateRoute();
    const { servicesInit } = AppStore.useAppStore;
    const baseAsset = useBaseAsset();
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const universalTradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);

    const {
      portfolio: { accountValue },
    } = usePortfolio({ balanceKey: 'all' });

    const onSelectItem = useCallback(
      (item: EnhancedTableData<PortfolioTableData>) => {
        if (universalTradePageEnabled) {
          return navigate(NavigationURLs.UniversalTrade, { pathParams: { asset: item.asset.value } });
        }
        navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: item.asset.value } });
      },
      [navigate, universalTradePageEnabled],
    );

    const chartSize = useMemo(() => {
      if (size) return size;
      if (atLeast?.md) return 280;
      return 280;
    }, [atLeast?.md, size]);

    return (
      <PortfolioBreakdownCard
        className={className}
        loading={!servicesInit}
        portfolioValue={formatCurrency(accountValue, baseAsset, { priceScale: getValuePriceScale(baseAsset) })}
        data={breakdownData}
        showLegend={showDefaultLegend}
        externalMutations={externalMutations}
        setExternalMutations={setExternalMutations}
        showLegendPercentages={showLegendPercentages}
        size={chartSize}
      >
        {!showDefaultLegend && (
          <EnhancedTable<PortfolioTableData>
            containerClassName='h-full'
            data-container='portfolio-allocation-table'
            headers={headers}
            data={data}
            sort={initialSort}
            onSort={onSort}
            onClickItem={onSelectItem}
          />
        )}
      </PortfolioBreakdownCard>
    );
  },
);

export { PortfolioBreakdownWidget };

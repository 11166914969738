import { api } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';

import type { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

type ReportType = 'pdf' | 'csv';

const LOG_TAG = 'TransactionReportService';

const DownloadTransactionReport = async (useNew: boolean, from: DateTime, to: DateTime, type: ReportType) => {
  const fromTimezoneOffset = from.offset * 60 * 1000; // In Milliseconds

  try {
    const data = {
      query: {
        from: from.toMillis().toString(),
        to: to.toMillis().toString(),
        offset: fromTimezoneOffset,
        type,
      },
    };

    let res: AxiosResponse<ArrayBuffer>;
    if (useNew) {
      res = await api.endpoints.downloadTransactionReport(data);
    } else {
      res = await api.endpoints.downloadTaxReport(data);
    }
    const uInt8 = new Uint8Array(res.data);
    const blob = new Blob([uInt8], { type: res.headers['content-type'] });

    const name = `Swyftx-Transaction-Report_${from}_${to}.${type}`;

    // on browser download file use a "clicked" <a/> object
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    }, 0);

    return true;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const TaxReportService = {
  DownloadTransactionReport,
};

export default TaxReportService;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { CodeInput } from '@global-components/Input';

import { LoginContext } from '@routes/Login/Login.context';
import { LoginStep } from '@routes/Login/types';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';

import { LoginStepFooter } from '../LoginStepFooter';
import { LoginTitle } from '../LoginTitle';

const TWO_FACTOR_LOST_STEP = 2;
const INITIAL_RESEND_TIME = 30;

const TwoFactorLost: React.FC = observer(() => {
  const { mfaData, setLoginStep, error, mfaResetToken, removeMfaError, lostAuthenticator, submitting } =
    useContext(LoginContext);
  const [code, setCode] = useState('');
  const [codeResent, setCodeResent] = useState(false);
  const { t } = useTranslation('login');

  const { show: showIntercom } = useIntercom();

  const remove = async () => {
    if (!mfaData) return;

    const headers: Record<string, string> = { AuthAction: `${mfaResetToken} ${code}` };

    const res = await lostAuthenticator(headers);

    if (res === undefined) {
      return;
    }

    if (res.success === true) {
      setLoginStep(LoginStep.SignIn);
    }
  };

  const resendCode = () => {
    lostAuthenticator();
    setCodeResent(true);
  };

  // Send out the code
  useEffect(() => {
    lostAuthenticator();
  }, [mfaData, lostAuthenticator]);

  return (
    <FlexLayout
      direction='column'
      alignItems='center'
      justifyContent='space-between'
      className='h-full w-full'
      spacing={32}
    >
      <FlexLayout direction='column' spacing={24}>
        <LoginTitle
          id='twoFactorLost.labels.title'
          title={t('twoFactorLost.title')}
          subTitle={t('twoFactorLost.subTitle')}
        />
        <FlexLayout direction='column' alignItems='start'>
          <Body id='twoFactorLost.labels.title.subTitleDescription' className='mb-24 w-full ' color='secondary'>
            An SMS has been sent to the mobile number registered to your account. Please enter the pin below to remove
            2FA and sign in.
          </Body>
          <Button variant='ghost' onClick={showIntercom}>
            Need help?
          </Button>
        </FlexLayout>
      </FlexLayout>
      <CodeInput
        id='twoFactorLost.form.codeInput'
        error={error || removeMfaError}
        length={6}
        onChange={(newCode) => setCode(newCode.join(''))}
        loading={submitting && !mfaResetToken}
        resendCode={resendCode}
        codeResent={codeResent}
        resendTime={INITIAL_RESEND_TIME}
      />

      <LoginStepFooter
        step={TWO_FACTOR_LOST_STEP}
        buttonColor='destructive'
        buttonText={t('twoFactorLost.buttonLabels.remove')}
        onNext={() => remove()}
        onBack={() => setLoginStep(LoginStep.SignIn)}
        disableButton={!code}
        buttonLoading={submitting && !!mfaResetToken}
      />
    </FlexLayout>
  );
});

TwoFactorLost.displayName = 'TwoFactorLost';

export { TwoFactorLost };

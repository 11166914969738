import { useCallback, useContext, useEffect, useState } from 'react';

import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';
import { WithdrawalAddress, WithdrawalReasonEnum } from '@shared/services';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { AddAddressFormData, TransferEnum, TransferModalStep } from '../TransferModal.types';

type Props = {
  selectedAsset?: Asset | string;
  initialTransferType: TransferEnum;
  initialDepositType?: DepositMethodEnum;
};

const useTransferModal = ({
  selectedAsset,
  initialTransferType,
  initialDepositType = DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
}: Props) => {
  const { currentStep, onClear, onBack, onNextStep } = useContext(StepModalContext);
  const [transferType, setTransferType] = useState<TransferEnum>(initialTransferType);
  const [depositType, setDepositType] = useState<DepositMethodEnum>(initialDepositType);
  const [preSelectedAsset, setPreSelectedAsset] = useState<Asset>();
  const [selectedAddress, setSelectedAddress] = useState<WithdrawalAddress>();
  const [withdrawAddress, setWithdrawAddress] = useState<WithdrawalAddress>();
  const [withdrawReason, setWithdrawReason] = useState<WithdrawalReasonEnum>();
  const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  const [addAddressFormData, setAddAddressFormData] = useState<AddAddressFormData>({
    label: '',
    address: '',
    accountHolderName: '',
    accountName: '',
    bsb: '',
    accountNumber: '',
    selectedCryptoNetworkId: -1,
  });
  const [addAddressToken, setAddAddressToken] = useState<string>('');
  const [withdrawToken, setWithdrawToken] = useState<string>('');

  const { getAssetByCode } = useMarkets();

  useEffect(() => {
    if (typeof selectedAsset === 'string') {
      return setPreSelectedAsset(getAssetByCode(selectedAsset));
    } else {
      return setPreSelectedAsset(selectedAsset);
    }
  }, [getAssetByCode, selectedAsset]);

  const onSelectAsset = useCallback(
    (asset: Asset) => {
      setPreSelectedAsset(asset);
      setWithdrawAddress(undefined);
      setWithdrawReason(undefined);
      setWithdrawAmount('');
      onClear();
    },
    [onClear],
  );

  const onUpdateFormData = <T extends AddAddressFormData>(key: keyof T, value: T[typeof key]) => {
    setAddAddressFormData((prev) => ({ ...prev, [key]: value }));
  };

  const onClearFormData = () => {
    setAddAddressFormData({
      label: '',
      address: '',
      accountHolderName: '',
      accountName: '',
      bsb: '',
      accountNumber: '',
      selectedCryptoNetworkId: -1,
    });
  };

  const onOpenAddress = useCallback(
    (address: WithdrawalAddress) => {
      setSelectedAddress(address);
      onNextStep(TransferModalStep.ViewAddressDetails);
    },
    [onNextStep],
  );

  return {
    preSelectedAsset,
    currentStep,
    addAddressFormData,
    selectedAddress,
    addAddressToken,
    transferType,
    depositType,
    withdrawAddress,
    withdrawReason,
    withdrawAmount,
    withdrawToken,
    setWithdrawToken,
    setWithdrawAddress,
    setWithdrawReason,
    setWithdrawAmount,
    setDepositType,
    setTransferType,
    onOpenAddress,
    setSelectedAddress,
    onSelectAsset,
    onBack,
    onNextStep,
    onUpdateFormData,
    onClearFormData,
    setAddAddressToken,
  };
};

export { useTransferModal };

import React from 'react';

import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

type Props = {
  onClose: () => void;
};

const FiatWithdrawalEmailVerifyModal: React.FC<Props> = observer(({ onClose }) => {
  const { userProfile } = UserStore.useUserStore;
  const { addToastMessage } = UIStore.useUIStore;

  const handleOnClose = () => {
    addToastMessage({
      severity: 'success',
      message: `New withdrawal account added (Pending verification)`,
    });

    onClose();
  };

  return (
    <Modal
      id='deposit-funds-info-modal'
      open
      sx={{ width: '600px', top: { xs: '2rem', md: 'calc(50% - 251px)' } }}
      HeaderProps={{ title: 'Verification required', dismissible: true, divider: true }}
      onClose={onClose}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={1}>
            <Button variant='contained' disableElevation onClick={handleOnClose}>
              Ok, I understand
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack spacing={3} alignItems='flex-start'>
        <Typography fontSize={16} fontWeight='400' color='text.secondary'>
          The action you are trying to complete requires email verification. An email will be sent to{' '}
          <Typography PII fontSize={16} fontWeight='500' color='text.secondary' display='inline'>
            {userProfile?.email}
          </Typography>
        </Typography>

        <Typography fontSize={16} fontWeight='400' color='text.secondary'>
          Please check your email inbox and follow the steps to verify and enable withdrawals to this new address
        </Typography>
      </Stack>
    </Modal>
  );
});

FiatWithdrawalEmailVerifyModal.displayName = 'FiatWithdrawalEmailVerifyModal';

export { FiatWithdrawalEmailVerifyModal };

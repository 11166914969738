import { useCallback, useEffect, useMemo, useState } from 'react';

import { AtLeastBreakpoints, breakpoints } from './breakpoints';

type Props = {
  containerName: string;
  deps?: any[];
};

const useTailwindContainerBreakpoint = ({ containerName, deps }: Props) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((event) => {
        window.requestAnimationFrame(() => {
          if (event.length === 1) {
            setContainerWidth(event[0].contentRect.width);
          }
        });
      }),
    [],
  );

  const isBreakpoint = useCallback(
    (min: keyof typeof breakpoints, max?: keyof typeof breakpoints) => {
      if (!containerWidth) return true;

      return containerWidth >= breakpoints[min] && (!max || containerWidth < breakpoints[max]);
    },
    [containerWidth],
  );

  useEffect(() => {
    const element = document.querySelector(`[data-container="${containerName}"]`);

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerName, resizeObserver, deps]);

  useEffect(() => setLoaded(containerWidth > 0), [containerWidth]);

  const atLeast: AtLeastBreakpoints = useMemo(
    () => ({
      xs: isBreakpoint('xs'),
      sm: isBreakpoint('sm'),
      md: isBreakpoint('md'),
      lg: isBreakpoint('lg'),
      xl: isBreakpoint('xl'),
      xxl: isBreakpoint('xxl'),
      xxxl: isBreakpoint('xxxl'),
    }),
    [isBreakpoint],
  );

  return {
    isXs: isBreakpoint('xs', 'sm'),
    isSm: isBreakpoint('sm', 'md'),
    isMd: isBreakpoint('md', 'lg'),
    isLg: isBreakpoint('lg', 'xl'),
    isXl: isBreakpoint('xl', 'xxl'),
    isXxl: isBreakpoint('xxl', 'xxxl'),
    isXxxl: isBreakpoint('xxxl'),
    isSmallScreen: isBreakpoint('xs', 'md'),
    atLeast,
    loaded,
  };
};

export { useTailwindContainerBreakpoint };

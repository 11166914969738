import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Copy: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M10.4864 10.0303C10.0721 10.0303 9.73636 10.3661 9.73636 10.7803C9.73636 11.1945 10.0721 11.5303 10.4864 11.5303H12.707C13.1212 11.5303 13.457 11.1945 13.457 10.7803C13.457 10.3661 13.1212 10.0303 12.707 10.0303H10.4864Z'
      fill='currentColor'
    />
    <path
      d='M9.73636 14.1465C9.73636 13.7323 10.0721 13.3965 10.4864 13.3965H16.3059C16.7201 13.3965 17.0559 13.7323 17.0559 14.1465C17.0559 14.5607 16.7201 14.8965 16.3059 14.8965H10.4864C10.0721 14.8965 9.73636 14.5607 9.73636 14.1465Z'
      fill='currentColor'
    />
    <path
      d='M9.73636 17.521C9.73636 17.1068 10.0721 16.771 10.4864 16.771H16.3059C16.7201 16.771 17.0559 17.1068 17.0559 17.521C17.0559 17.9352 16.7201 18.271 16.3059 18.271H10.4864C10.0721 18.271 9.73636 17.9352 9.73636 17.521Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.9442 3.44058C15.1555 2.65757 14.0613 2.25 12.7997 2.25L6.60218 2.25321L6.59756 2.25324C5.31692 2.26113 4.21114 2.69082 3.42649 3.50609C2.64461 4.31846 2.25003 5.44228 2.25003 6.71338V14.1256C2.25003 15.4027 2.64747 16.5317 3.4358 17.3456C4.08599 18.0169 4.95463 18.4246 5.95978 18.5486C6.08866 19.5114 6.46848 20.3589 7.09595 21.0067C7.88712 21.8236 9.00209 22.2501 10.2925 22.2501L10.2936 22.2501L16.4897 22.2477L16.4943 22.2477C17.7751 22.2398 18.881 21.8099 19.6658 20.9945C20.4478 20.1819 20.8427 19.058 20.8427 17.7868V10.3745C20.8427 9.09734 20.445 7.96829 19.6564 7.15444C19.0063 6.4834 18.1378 6.07576 17.133 5.95166C17.0004 4.96174 16.6022 4.09392 15.9442 3.44058ZM15.6065 5.91157C15.4867 5.31613 15.2314 4.84666 14.8873 4.50503C14.4242 4.04518 13.7329 3.75007 12.8001 3.75L6.60478 3.75322C5.6618 3.75947 4.96717 4.0684 4.50724 4.54627C4.0442 5.02737 3.75003 5.75178 3.75003 6.71338V14.1256C3.75003 15.0926 4.04658 15.8202 4.51325 16.302C4.85102 16.6507 5.31479 16.9089 5.91019 17.0235V10.3745C5.91019 9.10341 6.30477 7.97959 7.08664 7.16722C7.8713 6.35195 8.97708 5.92226 10.2577 5.91437L10.2623 5.91434L15.6065 5.91157ZM16.4601 7.41113L10.2649 7.41436C9.32196 7.4206 8.62732 7.72953 8.1674 8.2074C7.70436 8.6885 7.41019 9.41291 7.41019 10.3745V17.7868C7.41019 18.7537 7.70674 19.4813 8.1734 19.9631C8.63722 20.442 9.3386 20.7501 10.2921 20.7501L16.4851 20.7477L16.4872 20.7477C17.43 20.7415 18.1249 20.4324 18.5851 19.9543C19.0484 19.4729 19.3427 18.7482 19.3427 17.7868V10.3745C19.3427 9.40769 19.046 8.68006 18.5791 8.19821C18.1152 7.71933 17.4135 7.4112 16.4601 7.41113Z'
      fill='currentColor'
    />
  </svg>
);

export { Copy };

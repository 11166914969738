import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const InformationFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12C2 6.481 6.48 2 12 2C17.53 2 22 6.481 22 12C22 17.521 17.53 22 12 22C6.48 22 2 17.521 2 12ZM11.99 7.32996C12.48 7.32996 12.87 7.72996 12.87 8.20996C12.87 8.68996 12.48 9.07996 12 9.07996C11.51 9.07996 11.12 8.68996 11.12 8.20996C11.12 7.72996 11.51 7.32996 11.99 7.32996ZM12.88 15.801C12.88 16.28 12.48 16.681 12 16.681C11.52 16.681 11.13 16.28 11.13 15.801V11.381C11.13 10.9 11.52 10.511 12 10.511C12.48 10.511 12.88 10.9 12.88 11.381V15.801Z'
      fill='currentColor'
    />
  </svg>
);

export { InformationFilled };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

type Props = {
  step?: number;
  buttonText: string;
  onNext?: () => void;
  onBack?: () => void;
  buttonColor?: 'primary' | 'success' | 'accent' | 'inverse' | 'subtle' | 'destructive' | undefined;
  buttonLoading?: boolean;
  disableButton?: boolean;
};

const LoginStepFooter: React.FC<Props> = observer(
  ({ step, buttonText, onNext, onBack, buttonColor = 'primary', buttonLoading = false, disableButton = false }) => {
    const { t } = useTranslation('login');

    return (
      <FlexLayout
        id='loginStep.container'
        direction='column'
        justifyContent='end'
        className='h-full w-full pb-16 sm:pb-0'
      >
        <FlexLayout alignItems='center' className='mt-32'>
          <FlexLayout className='w-full sm:w-1/2'>
            {step && (
              <Body color='secondary' className='hidden sm:block'>
                {t('loginStep.label', { loginStep: step })}
              </Body>
            )}
          </FlexLayout>

          <FlexLayout className='w-full sm:w-1/2' alignItems='center' justifyContent='end'>
            <Button variant='outlined' color='primary' size='lg' className='mr-8 w-full sm:w-[100px]' onClick={onBack}>
              {t('twoFactorLogin.buttonLabels.back')}
            </Button>

            <Button
              id='twoFactorLogin.buttonLabels.verify'
              color={buttonColor}
              className='w-full sm:w-[100px]'
              size='lg'
              variant='filled'
              onClick={onNext}
              disabled={disableButton}
              loading={buttonLoading}
            >
              {buttonText}
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    );
  },
);

LoginStepFooter.displayName = 'LoginStepFooter';

export { LoginStepFooter };

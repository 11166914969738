import React, { PropsWithChildren } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import AppHeader from '@global-components/AppHeader';
import { DynamicGrid } from '@global-components/DynamicGrid/DynamicGrid';

type Props = {
  headerItemOverride: React.ReactElement;
};

const SwyftxAppShellCustomHeader: React.FC<PropsWithChildren<Props>> = ({ headerItemOverride, children }) => (
  <FlexLayout
    direction='column'
    className='relative h-full w-full overflow-hidden bg-color-background-pageBG'
    alignItems='start'
    justifyContent='start'
  >
    <AppHeader headerItemsOverride={headerItemOverride} />
    <FlexLayout className='h-full w-full'>
      <FlexLayout className='w-full overflow-auto'>
        <div className='w-full'>
          <DynamicGrid>{children}</DynamicGrid>
        </div>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);

export { SwyftxAppShellCustomHeader };

import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

import { TabsTrigger } from '@swyftx/aviary/atoms/Tabs';

import { cn } from '@shared/utils/lib/ui';

import { tabsTriggerVariants } from './EnhancedTabs.styles';
import { EnhancedTab } from './EnhancedTabs.types';

type Props<T> = {
  tab: EnhancedTab<T>;
  variant?: 'parent' | 'child' | 'default' | 'header';
  size?: 'sm' | 'md';
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (newValue: T) => void;
  value?: T;
  spotlightElementId?: string;
};

const EnhancedTabsTrigger = <T extends string>({
  tab,
  value,
  fullWidth,
  variant,
  size,
  disabled,
  onChange,
  spotlightElementId,
  children,
}: PropsWithChildren<Props<T>>) => {
  const ref = useRef<number>();
  const [animate, setAnimate] = useState<string>('');

  const colorAnimateClass = useMemo(() => {
    switch (tab.animateColor) {
      case 'error':
        return '!outline-color-border-error text-color-text-error duration-0';
      case 'success':
        return '!outline-color-border-success text-color-text-success duration-0';
      default:
        return '';
    }
  }, [tab.animateColor]);

  const handleOnClick = useCallback(() => {
    if (value === tab.value) return;

    if (colorAnimateClass) {
      setAnimate(colorAnimateClass);

      window.clearTimeout(ref.current);

      ref.current = window.setTimeout(() => {
        setAnimate('duration-500');
      }, 1000);
    }

    if (onChange) onChange(tab.value);
  }, [colorAnimateClass, onChange, tab.value, value]);

  return (
    <TabsTrigger
      disabled={tab.disabled || disabled || !onChange}
      data-spotlightelementid={spotlightElementId}
      className={cn(
        tabsTriggerVariants({
          variant,
          size,
          disabled: tab.disabled,
          fullWidth,
          color: tab.color,
          selected: tab.value === value,
        }),
        animate,
      )}
      value={tab.value}
      onClick={handleOnClick}
    >
      {children}
    </TabsTrigger>
  );
};

export { EnhancedTabsTrigger };

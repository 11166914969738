import React, { useContext, useEffect } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { DollarInCircle, Email, Phone, Profile } from '@swyftx/aviary/icons/outlined';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import { emitEvent, SwyftxEvent } from '@shared/events';

import { AnalyticsType } from '@services/Analytics';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationLevel } from '@Profile/subroutes/Verification/components/VerificationLevel';
import { VerificationModals, VerificationStepEventName } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { SourceOfWealthHistoryTable } from './SourceOfWealthHistory';
import { VerifyEmail, VerifyFund, VerifyIdentity, VerifyPhone } from './components';
import { SourceOfWealthVerificationAction } from './components/VerificationMethod/SourceOfWealthVerificationAction/SourceOfWealthVerificationAction';

const ProfileVerification: React.FC = observer(() => {
  const { verificationStep } = useContext(ProfileVerificationContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const sourceOfWealthEnabled = isFeatureEnabled(AppFeature.SourceOfWealth);

  // Send page view event
  useEffect(() => {
    const screen = VerificationStepEventName[verificationStep];
    if (screen === null) return;

    emitEvent(SwyftxEvent.Analytics, {
      type: AnalyticsType.Navigation,
      data: { screen },
    });
  }, [verificationStep]);

  const verificationItems = [
    <SettingsItem key='email' icon={<Email />} title='Verify email' action={<VerifyEmail />} />,
    <SettingsItem key='phone' icon={<Phone />} title='Verify phone' action={<VerifyPhone />} />,
    <SettingsItem
      key='identity'
      icon={<Profile />}
      title='Verify identity'
      description='As a registered trading platform, we are required by law to authenticate your identity.'
      action={<VerifyIdentity />}
    />,
  ];

  if (sourceOfWealthEnabled) {
    verificationItems.push(
      <SettingsItem
        icon={<DollarInCircle />}
        title='Complete source of wealth check'
        description='Verify the source of your funds to comply with AML/CTF laws and help us protect your account'
        action={<SourceOfWealthVerificationAction />}
      />,
    );
  } else {
    verificationItems.push(
      <SettingsItem
        icon={<DollarInCircle />}
        title='Verify funds'
        description='Verify the source of your funds to comply with AML/CTF laws and help us protect your account'
        action={<VerifyFund />}
      />,
    );
  }

  return (
    <>
      <FlexLayout direction='column' spacing={24}>
        <VerificationLevel />
        <SettingsCluster title='Verification Methods' items={verificationItems} />
        {sourceOfWealthEnabled && <SourceOfWealthHistoryTable />}
      </FlexLayout>
      {VerificationModals[verificationStep]}
    </>
  );
});

export { ProfileVerification };

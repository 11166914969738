import React, { useRef, ElementRef } from 'react';

import Wizard from '@global-components/Wizard';
import { StepProps } from '@global-components/Wizard/Wizard';

import { Links } from '@shared/constants';
import { UserStore } from '@shared/store';

const CreateEntityFormStep: React.FC<StepProps> = (props) => {
  const { userProfile } = UserStore.useUserStore;
  const iframeRef = useRef<ElementRef<'iframe'>>(null);

  return (
    <Wizard.Step {...props}>
      <iframe
        ref={iframeRef}
        style={{ height: '100%', width: '100%', border: 'none' }}
        src={`${Links.forms.entityApplicationForm}?UUID=${userProfile?.intercom?.uuid}`}
      />
    </Wizard.Step>
  );
};

export { CreateEntityFormStep };

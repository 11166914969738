import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DEX: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.00573 6.63828C7.33586 4.17424 9.44642 2.27393 12.0008 2.27393C14.5554 2.27393 16.6661 4.1745 16.996 6.63879C19.4595 6.96938 21.3593 9.0797 21.3593 11.6337C21.3593 14.1878 19.4595 16.2981 16.996 16.6287C16.6662 19.0931 14.5555 20.9938 12.0008 20.9938C9.44634 20.9938 7.33572 19.0934 7.0057 16.6292C4.54036 16.3002 2.63867 14.1891 2.63867 11.6337C2.63867 9.0784 4.54038 6.96722 7.00573 6.63828ZM8.46116 6.65413C8.77106 4.98102 10.238 3.71393 12.0008 3.71393C13.7635 3.71393 15.2303 4.98073 15.5404 6.65357C14.0371 6.88679 12.7534 7.78439 11.999 9.03682C11.2451 7.78539 9.96292 6.88822 8.46116 6.65413ZM7.02084 15.1738C5.34675 14.8646 4.07867 13.3973 4.07867 11.6337C4.07867 9.87021 5.34673 8.40285 7.0208 8.09372C7.2542 9.5964 8.15144 10.8795 9.4033 11.6339C8.1515 12.3882 7.25429 13.6712 7.02084 15.1738ZM8.46112 16.6134C8.77092 18.2866 10.2379 19.5538 12.0008 19.5538C13.7636 19.5538 15.2304 18.2869 15.5404 16.6139C14.0371 16.3807 12.7534 15.4831 11.999 14.2307C11.2451 15.4821 9.9629 16.3793 8.46112 16.6134ZM8.49292 15.1413C9.82785 14.8326 10.8785 13.7815 11.1866 12.4463C9.85164 12.755 8.80095 13.8061 8.49292 15.1413ZM8.49286 8.12621C8.80081 9.46153 9.85158 10.5128 11.1866 10.8214C10.8787 9.48613 9.82791 8.43487 8.49286 8.12621ZM15.509 8.12531C14.1718 8.43289 13.1191 9.48529 12.8111 10.8224C14.1483 10.5148 15.201 9.46236 15.509 8.12531ZM12.8112 12.4454C13.1192 13.7823 14.1718 14.8346 15.5089 15.1422C15.2009 13.8052 14.1483 12.753 12.8112 12.4454ZM14.5984 11.6339C15.85 12.3881 16.7471 13.6708 16.9807 15.1731C18.653 14.8625 19.9193 13.396 19.9193 11.6337C19.9193 9.87148 18.653 8.40496 16.9808 8.09439C16.7472 9.59678 15.85 10.8796 14.5984 11.6339Z'
      fill='currentColor'
    />
  </svg>
);

export { DEX };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import { PriceTicker } from '@global-components/PriceTicker';

import { Asset } from '@shared/api';
import { AppStore } from '@shared/store';

import { AssetBalanceData, useAssetBalance } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

interface SingleWalletTradingInfoBuyPriceProps {
  baseAsset: Asset;
  assetBalanceData: AssetBalanceData;
}

const SingleWalletTradingInfoBuyPrice: React.FC<SingleWalletTradingInfoBuyPriceProps> = ({
  assetBalanceData,
  baseAsset,
}) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'tradingInfo' });
  const price = assetBalanceData.trading.averageBuyPrice;

  return price ? (
    <FlexLayout direction='row' spacing={8} alignItems='center'>
      <Body size='small' weight='emphasis'>
        {t('labels.avgBuyPrice')}
      </Body>

      <FlexLayout direction='row' spacing={8} className='text-center' alignItems='center'>
        <PriceTicker
          formatOptions={{ hideCode: false }}
          typographyProps={{ size: 'large' }}
          currency={baseAsset}
          duration={200}
          price={price}
          ticks={3}
        />
        <Numeric color='secondary' weight='emphasis' className='text-center'>
          {baseAsset.code}
        </Numeric>
      </FlexLayout>
    </FlexLayout>
  ) : null;
};

interface SingleWalletTradingInfoPriceProps {
  asset: Asset;
  baseAsset: Asset;
}

const SingleWalletTradingInfoPrice: React.FC<SingleWalletTradingInfoPriceProps> = observer(({ asset, baseAsset }) => {
  const assetBalanceData = useAssetBalance(asset, { forceBaseAsset: baseAsset });
  const { isDemo } = AppStore.useAppStore;

  if (isDemo) return null;

  return (
    <FlexLayout direction='row' justifyContent='space-between' alignItems='center' className='w-full'>
      <SingleWalletTradingInfoBuyPrice assetBalanceData={assetBalanceData} baseAsset={baseAsset} />
    </FlexLayout>
  );
});

export { SingleWalletTradingInfoPrice };

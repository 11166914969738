import React, { ReactNode } from 'react';

import { Theme } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import { InputBaseProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

export type AutocompleteOption<T, S = any> = {
  label: string;
  value: T;
  description?: string;
  extraState?: S;
};

type Props = {
  id: string;
  options: Array<AutocompleteOption<any>>;
  onChange: (option: AutocompleteOption<any>) => void;
  InputProps?: InputBaseProps['inputProps'];
  value?: AutocompleteOption<any> | null;
  onDeselect?: () => void;
  placeholder?: string;
  disabled?: boolean;
  height?: string;
  width?: string;
  groupBy?: (option: AutocompleteOption<any>) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
  customRenderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption<any>,
    state: AutocompleteRenderOptionState,
  ) => ReactNode;
  customRenderInput?: (param: AutocompleteRenderInputParams) => ReactNode;
};

const getDefaultTextFieldStyles = (theme: Theme) => ({
  '& input': {
    fontSize: pxToRem(14),
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[700],
  },
  fieldset: {
    borderColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
  },
});

const DEFAULT_WIDTH = '100%';
const DEFAULT_HEIGHT = '48px';

export const AutocompleteDropdown: React.FC<Props> = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  customRenderOption,
  customRenderInput,
  renderGroup,
  placeholder,
  InputProps,
  onDeselect,
  disabled,
  onChange,
  options,
  groupBy,
  value,
  id,
}) => {
  const theme = useTheme();

  return (
    <Autocomplete
      sx={{ width, height, '& .MuiOutlinedInput-root': { height } }}
      renderGroup={renderGroup}
      disabled={disabled}
      options={options}
      groupBy={groupBy}
      value={value}
      autoHighlight
      id={id}
      onChange={(e, option: AutocompleteOption<any> | null) => {
        if (onDeselect && option === null) {
          onDeselect();
        }

        if (option) {
          onChange(option);
        }
      }}
      getOptionLabel={(option) => option.label}
      renderOption={
        customRenderOption ||
        ((props, option) =>
          option.description ? (
            <li {...props}>
              <Stack spacing={1} padding={1.5}>
                <Typography fontSize={pxToRem(16)} fontWeight={500}>
                  {option.label}
                </Typography>
                <Typography fontSize={pxToRem(14)} color='text.secondary'>
                  {option.description}
                </Typography>
              </Stack>
            </li>
          ) : (
            <li {...props}> {option.label} </li>
          ))
      }
      renderInput={
        customRenderInput ||
        ((params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{ ...params.inputProps, ...InputProps }}
            sx={getDefaultTextFieldStyles(theme)}
          />
        ))
      }
    />
  );
};

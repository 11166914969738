import React from 'react';

import { GlobalMessageBoxes, GlobalToasts } from '@global-components/GlobalMessages';
import { Modal } from '@global-components/Modals/Modal';
import { IdleSessionTimeout } from '@global-components/Session/Timeout/IdleSessionTimeout';
import { DynamicTheme } from '@global-components/Theme';

import { TradeUtilityContext } from '@hooks/Trade/useUniversalTradeUtilityStore';
import { useQueryMetadata } from '@hooks/UseQueryMetadata/useQueryMetadata';
import { useInitApp } from '@hooks/useInitApp';
import { createTradeUtilityStore } from '@routes/Trade/components/Trade.store';

import { observer, useLocalObservable } from 'mobx-react-lite';

import './aviary/styles/index.scss';
import './global.css';
import 'overlayscrollbars/overlayscrollbars.css';

import { ToastManager } from './aviary/molecules/ToastManager/ToastManager';
import { TourGuide } from './aviary/molecules/TourGuide';
import { FeatureDiscovery } from './lib/feature-discovery/FeatureDiscovery';
import { NavigationExitIntent } from './lib/navigation/components/NavigationExitIntent';
import { Router } from './lib/router';
import { useProcessSearchParams } from './lib/utils/hooks/useProcessSearchParams';

/**
 * The Main App Entry Point
 * @returns {React.FC}
 */
const App: React.FC = observer(() => {
  const { loadedUserData, loadedFromStorage } = useInitApp();

  useQueryMetadata();

  useProcessSearchParams();

  const tradeStore = useLocalObservable<ReturnType<typeof createTradeUtilityStore>>(() => createTradeUtilityStore());

  // TODO: think about this logic (routes load before userData is set)
  if (!loadedUserData || !loadedFromStorage) return null;

  return (
    <DynamicTheme>
      <IdleSessionTimeout />
      <TourGuide />
      <FeatureDiscovery />
      <NavigationExitIntent />
      <ToastManager />
      <GlobalToasts />
      <GlobalMessageBoxes />
      <TradeUtilityContext.Provider value={tradeStore}>
        <Modal />
        <Router />
      </TradeUtilityContext.Provider>
    </DynamicTheme>
  );
});

App.displayName = 'App';

export default App;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Defi: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24'>
    <path
      d='M8.06158 2.46967C7.76869 2.17678 7.29381 2.17678 7.00092 2.46967C6.70803 2.76256 6.70803 3.23744 7.00092 3.53033L7.10145 3.63086H6.75092C4.43164 3.63086 2.55078 5.51172 2.55078 7.83101C2.55078 8.24522 2.88657 8.58101 3.30078 8.58101C3.71499 8.58101 4.05078 8.24522 4.05078 7.83101C4.05078 6.34014 5.26007 5.13086 6.75092 5.13086H7.10011L7.00111 5.22979C6.70811 5.52258 6.70794 5.99746 7.00073 6.29045C7.29352 6.58345 7.7684 6.58362 8.06139 6.29083L9.44203 4.91116C9.58277 4.77052 9.66186 4.57973 9.66189 4.38077C9.66193 4.18181 9.5829 3.99099 9.44222 3.85031L8.06158 2.46967Z'
      fill='currentColor'
    />
    <path
      d='M17.0008 17.7099C17.2937 18.0028 17.2937 18.4776 17.0008 18.7705L16.9012 18.8701H17.2472C18.738 18.8701 19.9474 17.6608 19.9474 16.1699C19.9474 15.7557 20.2831 15.4199 20.6974 15.4199C21.1116 15.4199 21.4474 15.7557 21.4474 16.1699C21.4474 18.4892 19.5665 20.3701 17.2472 20.3701H16.9006L17.0006 20.47C17.2936 20.7628 17.2938 21.2377 17.001 21.5307C16.7082 21.8237 16.2333 21.8238 15.9403 21.5311L14.5597 20.1515C14.419 20.0108 14.3399 19.82 14.3398 19.6211C14.3398 19.4221 14.4188 19.2313 14.5595 19.0906L15.9401 17.7099C16.233 17.417 16.7079 17.417 17.0008 17.7099Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.73057 8.72571C6.17209 9.22843 4.24219 11.4834 4.24219 14.1892C4.24219 17.2645 6.73504 19.7574 9.81033 19.7574C12.5145 19.7574 14.7683 17.8299 15.2729 15.2739C17.8314 14.7712 19.7612 12.5162 19.7612 9.81033C19.7612 6.73506 17.2685 4.24219 14.1932 4.24219C11.489 4.24219 9.23519 6.16965 8.73057 8.72571ZM8.6454 10.2903C6.96627 10.7913 5.74219 12.3473 5.74219 14.1892C5.74219 16.4361 7.56348 18.2574 9.81033 18.2574C11.651 18.2574 13.2062 17.035 13.7082 15.3577C11.0182 15.1256 8.87512 12.981 8.6454 10.2903ZM10.1377 10.1341C10.2942 12.1216 11.8788 13.7073 13.8658 13.8655C13.7094 11.8781 12.1247 10.2923 10.1377 10.1341ZM15.3581 13.7093C15.1284 11.0186 12.9853 8.87398 10.2953 8.64192C10.7973 6.96458 12.3524 5.74219 14.1932 5.74219C16.44 5.74219 18.2613 7.56346 18.2613 9.81033C18.2613 11.6523 17.0372 13.2083 15.3581 13.7093Z'
      fill='currentColor'
    />
  </svg>
);

export { Defi };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { TickInCircle } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { StepTitles } from '@routes/SourceOfWealth/types/StepTitles';

import { useSourceOfWealthSelector } from '@SourceOfWealth/SourceOfWealth.context';

import { Step } from './stepperUtils';

type Props = {
  step: Step;
  activeOrder: number;
  root?: boolean;
};

const SourceOfWealthStepperItem: React.FC<Props> = (props) => {
  const { step, root, activeOrder } = props;

  const currentState = useSourceOfWealthSelector((state) => state.toStrings());
  const completedSteps = useSourceOfWealthSelector((state) => state.context.completedSteps);

  const isActive = useMemo(() => currentState.includes(step.id), [currentState, step.id]);
  const isComplete = useMemo(
    () => completedSteps[step.id] || (step.children.length && step.children.every((s) => completedSteps[s.id])),
    [completedSteps, step.id, step.children],
  );

  const labelColor = useMemo(() => {
    if (isActive) return 'primary';
    if (isComplete) return 'disabled';
    return 'secondary';
  }, [isActive, isComplete]);

  return (
    <>
      <FlexLayout
        key={step.id}
        direction='row'
        alignItems='center'
        className={cn(root ? 'h-[52px]' : 'h-[36px]')}
        spacing={16}
      >
        <div
          className={cn(
            isActive || activeOrder > step.order
              ? 'bg-color-background-primary'
              : 'bg-color-background-surface-secondary',
            'h-full w-3',
          )}
        />
        <FlexLayout alignItems='center'>
          {root &&
            (isComplete ? (
              <TickInCircle className='text-color-text-accent' />
            ) : (
              <div
                className={cn(
                  'h-20 w-20 rounded-[100%] border-[1.5px]',
                  isActive ? 'border-color-border-accent' : 'border-color-border-main',
                )}
              />
            ))}
        </FlexLayout>
        <Body color={labelColor} weight={isActive ? 'bold' : 'emphasis'} className={isComplete ? 'line-through' : ''}>
          {StepTitles[step.id as keyof typeof StepTitles]}
        </Body>
      </FlexLayout>
      {isActive &&
        step.children
          .filter((s) => !s.hidden)
          .map((s) => <SourceOfWealthStepperItem key={s.id} step={s} activeOrder={activeOrder} />)}
    </>
  );
};

export { SourceOfWealthStepperItem };

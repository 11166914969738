import { MouseEvent, TouchEvent, useCallback, useRef, useState } from 'react';

import { isReactTouchEvent } from 'src/utils/guards';

type Options = {
  onSwipe?: (direction: SwipeDirection) => void;
  swipePixelThreshold?: number;
  giveOffset?: boolean;
};

const DEFAULT_PIXEL_THRESH = 100;

export enum SwipeDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const useSwipe = (options?: Options) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [offset, setOffset] = useState(0);

  const initialX = useRef<number | null>(null);
  const isMouseDown = useRef<boolean>(false);

  const swipePixelThreshold = options?.swipePixelThreshold || DEFAULT_PIXEL_THRESH;

  const onStart = useCallback((e: TouchEvent | MouseEvent) => {
    e.stopPropagation();
    isMouseDown.current = true;

    const clientX = isReactTouchEvent(e) ? e.targetTouches[0].clientX : e.clientX;
    setTouchStart(clientX);

    if (options?.giveOffset) {
      initialX.current = clientX;
    }
  }, []);

  const onMove = useCallback((e: TouchEvent | MouseEvent) => {
    e.stopPropagation();
    const isTouch = isReactTouchEvent(e);
    const clientX = isTouch ? e.targetTouches[0].clientX : e.clientX;

    // Save renders from mouseMove if the mouse isn't down
    if (isTouch || (isMouseDown.current && !isTouch)) {
      if (options?.giveOffset && initialX.current) {
        setOffset(clientX - (initialX.current || 0));
      }
    }
  }, []);

  const onEnd = useCallback(
    (e: TouchEvent | MouseEvent) => {
      e.stopPropagation();
      const isTouch = isReactTouchEvent(e);
      const clientX = isTouch ? e.targetTouches[0].clientX : e.clientX;
      const clientTouchEnd = clientX;

      isMouseDown.current = false;
      setTouchStart(null);

      if (touchStart !== null && clientTouchEnd !== null) {
        if (touchStart - clientTouchEnd > swipePixelThreshold && options?.onSwipe) {
          options.onSwipe(SwipeDirection.LEFT);
        }

        if (touchStart - clientTouchEnd < -swipePixelThreshold && options?.onSwipe) {
          options.onSwipe(SwipeDirection.RIGHT);
        }

        if (options?.giveOffset) {
          initialX.current = null;
          setOffset(0);
        }
      }
    },
    [touchStart],
  );

  return { onStart, onMove, onEnd, offset, isMouseDown: isMouseDown.current };
};

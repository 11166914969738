import React, { useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { DangerFilled } from '@swyftx/aviary/icons/filled';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

export const RevokeAllKeysModal: React.FC = observer(() => {
  const { revokeAllKeys } = useContext(ApiKeysContext);
  const [open, setOpen] = useState<boolean>(false);

  const onRevoke = () => {
    revokeAllKeys();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      triggerElement={<Button color='destructive'>Revoke all keys</Button>}
      title='Are you sure you want to revoke all keys?'
    >
      <FlexLayout direction='column' spacing={16} className='p-24'>
        <FlexLayout direction='column' className='py-16' spacing={16}>
          <FlexLayout className='justify-center'>
            <DangerFilled className='h-[7rem] w-[7rem] text-color-text-error' id='create-api-key-warning-step-icon' />
          </FlexLayout>

          <FlexLayout direction='column' spacing={8} className='text-center'>
            <Body>Performing this action will revoke all of your API keys</Body>
            <Body color='secondary'>This action cannot be undone</Body>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout direction='row' className='justify-end' spacing={8}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color='destructive' onClick={onRevoke}>
            Confirm
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

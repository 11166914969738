import React, { useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';
import { Copy } from '@swyftx/aviary/icons/outlined';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

type Props = {
  title: string;
  value: string;
};

const COPIED_TIMEOUT = 5000;

const DepositInformationItem: React.FC<Props> = ({ title, value }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  const copyValue = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
    } catch (e) {
      setCopied(false);
    }
  };

  return (
    <FlexLayout direction='row' alignItems='center' className='w-full py-8' justifyContent='space-between'>
      <Utility>{title}</Utility>

      <FlexLayout direction='row' spacing={24} alignItems='center'>
        <Body className='fs-mask' size='small' weight='emphasis' color={copied ? 'accent' : 'primary'}>
          {copied ? 'Copied to clipboard!' : value}
        </Body>

        {hasClipboardPermission === 'granted' && (
          <Button leadingIcon={<Copy />} size='sm' variant='outlined' onClick={copyValue}>
            Copy
          </Button>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export { DepositInformationItem };

import React, { useCallback, useMemo, useState } from 'react';

import { CorporateTrustForm } from '@shared/api';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useIndividualTrusteeMembersStep } from '@routes/EntityOnboarding/hooks/useIndividualTrusteeMembersStep';
import { ShareholderWithUuid } from '@routes/EntityOnboarding/hooks/useShareholdersStep';
import { IndividualWithUuid } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';

import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';

import { IndividualPageLayout } from './components/IndividualPageLayout';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const CorporateTrustAddBeneficiariesStep: React.FC = observer(() => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData as CorporateTrustForm);

  const {
    members: beneficiaries,
    addMemberOpen,
    onAddMember,
    onCancel,
    continueDisabled,
    handleRemove,
    toggleAddMemberOpen,
    onEditMember,
    prevMembers,
  } = useIndividualTrusteeMembersStep('beneficiary');

  const prevDirectors = applicationData.directors
    ? applicationData.directors.map((director) => ({
        individual: director,
        uuid:
          prevMembers?.find(
            (beneficiary) =>
              beneficiary.individual.type === 'INDIVIDUAL' && beneficiary.individual.email === director.email,
          )?.uuid ?? uuid(),
      }))
    : undefined;

  const prevShareholders: ShareholderWithUuid[] | undefined = applicationData.shareholders
    ? applicationData.shareholders.map((shareholder) => ({
        shareholder,
        uuid:
          shareholder.type === 'INDIVIDUAL'
            ? prevMembers?.find(
                (beneficiary) =>
                  beneficiary.individual.type === 'INDIVIDUAL' && beneficiary.individual.email === shareholder.email,
              )?.uuid ?? uuid()
            : uuid(),
      }))
    : undefined;

  const [shareholders, setShareholders] = useState<ShareholderWithUuid[]>(() => prevShareholders ?? []);
  const [directors, setDirectors] = useState<IndividualWithUuid[]>(prevDirectors ?? []);

  const rawBeneficiaries = useMemo(() => beneficiaries.map((member) => member.individual), [beneficiaries]);
  const rawDirectors = useMemo(() => directors.map((director) => director.individual), [directors]);
  const rawShareholders = useMemo(() => shareholders.map((shareholder) => shareholder.shareholder), [shareholders]);

  const handleEdit = useCallback(
    (editedBeneficiary: IndividualWithUuid) => {
      onEditMember(editedBeneficiary);
      const isAlsoDirector = directors.some((director) => director.uuid === editedBeneficiary.uuid);
      if (isAlsoDirector) {
        setDirectors((prev) => [
          ...prev.filter((director) => director.uuid !== editedBeneficiary.uuid),
          { uuid: editedBeneficiary.uuid, individual: editedBeneficiary.individual },
        ]);
      }
      const isAlsoShareholder = shareholders.some((shareholder) => shareholder.uuid === editedBeneficiary.uuid);
      if (isAlsoShareholder) {
        setShareholders((prev) => [
          ...prev.filter(
            (shareholder) =>
              shareholder.shareholder.type === 'INDIVIDUAL' && shareholder.uuid !== editedBeneficiary.uuid,
          ),
          { uuid: editedBeneficiary.uuid, shareholder: editedBeneficiary.individual },
        ]);
      }
    },
    [directors, onEditMember, shareholders],
  );

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: {
          beneficiaries: rawBeneficiaries,
          directors: rawDirectors,
          shareholders: rawShareholders,
        } as CorporateTrustForm,
      }),
    [entityOnboardingService, rawBeneficiaries, rawDirectors, rawShareholders],
  );

  const handleBack = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'BACK',
        data: {
          beneficiaries: rawBeneficiaries,
          directors: rawDirectors,
          shareholders: rawShareholders,
        } as CorporateTrustForm,
      }),
    [entityOnboardingService, rawBeneficiaries, rawDirectors, rawShareholders],
  );

  return (
    <EntityOnboardingStepContainer
      customTitle='Add all named beneficiaries'
      submitDisabled={continueDisabled}
      onSubmit={handleSubmit}
      onBack={handleBack}
    >
      <EntityOnboardingPageLayout subtitle='List all beneficiaries of your trust, including any beneficiaries from corporate trustees (if applicable). We are required to collect this for regulatory purposes.'>
        <IndividualPageLayout
          title='Please add all beneficiaries'
          members={beneficiaries}
          onAddMember={onAddMember}
          handleRemove={handleRemove}
          onCancel={onCancel}
          addMemberOpen={addMemberOpen}
          toggleAddMemberOpen={toggleAddMemberOpen}
          memberType='beneficiary'
          onEditMember={handleEdit}
        />
      </EntityOnboardingPageLayout>
    </EntityOnboardingStepContainer>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Notification } from '@swyftx/react-web-design-system';

import { useOnboardingService } from '@Onboarding/Onboarding.context';

const IdVerificationInReview: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const onboardingService = useOnboardingService();

  const handleClick = () => {
    onboardingService.send('SAVE_AND_EXIT');
  };

  return (
    <>
      <Notification severity='warning' title={t('steps.idVerification.messages.pendingReviewTitle')}>
        {t('steps.idVerification.messages.pendingReviewDocuments')}
      </Notification>
      <Button variant='contained' onClick={handleClick} sx={{ marginTop: 4 }}>
        {t('labels.continue')}
      </Button>
    </>
  );
};

export { IdVerificationInReview };

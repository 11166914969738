import React, { useState, useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { DownloadTaxReportCard, TaxLearnCard, TaxPartnerCard, IntegrateKoinly } from './components';

const ProfileTaxReports: React.FC = () => {
  const [showIntegrateKoinly, setShowIntegrateKoinly] = useState<boolean>(false);

  const handleClickKoinlyIntegrationCta = useCallback(() => {
    setShowIntegrateKoinly(true);
  }, []);

  const handleCancelKoinlyIntegration = useCallback(() => {
    setShowIntegrateKoinly(false);
  }, []);

  return (
    <>
      <FlexLayout direction='column' spacing={24}>
        <DownloadTaxReportCard />
        <TaxPartnerCard onClickKoinlyCta={handleClickKoinlyIntegrationCta} />
        <TaxLearnCard />
      </FlexLayout>

      {showIntegrateKoinly && <IntegrateKoinly onClickCancel={handleCancelKoinlyIntegration} />}
    </>
  );
};

export { ProfileTaxReports };

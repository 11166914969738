import { useCallback, useContext, useMemo } from 'react';

import { Big } from '@shared/safe-big';

import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { SwyftxProTradeContext } from '../context';

const useSwyftxProTradeAmount = () => {
  const { limitAsset, baseAsset, amount, total, setAmount, setTotal } = useContext(SwyftxProTradeContext);
  const { lowestFee } = useCurrentUserFees();

  const onChange = useCallback(
    (val = '') => {
      if (val === '') {
        setTotal('');
        setAmount('');
        return;
      }

      if (limitAsset?.code === baseAsset?.code) {
        setTotal(val);
      } else {
        setAmount(val);
      }
    },
    [baseAsset?.code, limitAsset?.code, setAmount, setTotal],
  );

  const value = useMemo(() => {
    if (limitAsset?.code === baseAsset?.code) return total;

    return amount;
  }, [amount, baseAsset?.code, limitAsset?.code, total]);

  const totalFee = useMemo(() => Big(total).times(lowestFee), [lowestFee, total]);

  const amountFee = useMemo(() => Big(amount).times(lowestFee), [lowestFee, amount]);

  return {
    amount,
    total,
    value,
    totalFee,
    amountFee,
    setAmount,
    setTotal,
    onChange,
  };
};

export { useSwyftxProTradeAmount };

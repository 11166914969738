import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

const ActivityTileEmptyView: React.FC = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Stack direction='column' spacing={1} paddingX={1} paddingY={4} alignItems='center' textAlign='center'>
      <Typography fontSize={16} fontWeight='600'>
        {t('activityTile.labels.empty.title')}
      </Typography>
      <Typography fontSize={14} fontWeight='400' color='text.secondary'>
        {t('activityTile.labels.empty.subTitle')}
      </Typography>
    </Stack>
  );
};

export { ActivityTileEmptyView };

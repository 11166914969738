import { useContext, useMemo } from 'react';

import { Candle } from '@shared/api/@types/charts';

import { DateTime } from 'luxon';
import { useFetchLatestChartBar, useFetchChartBars } from 'src/lib/trade/hooks/Charts';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { useTradingViewSimpleResolution } from '../../components/TradingViewWidgetHeader/TradingViewWidgetHeaderResolution/useTradingViewSimpleResolution';
import { TradingViewContext } from '../../context/TradingView.context';

const USE_MOCK = false;

const useSimpleChartData = () => {
  const { primary, secondary, side, simpleResolution: resolution } = useContext(TradingViewContext);
  const { selectedItem } = useTradingViewSimpleResolution();
  const { isSwyftxPro } = useSwyftxPro();

  const timeEnd = DateTime.now();

  const { chartBars, isFetching: isFetchingBars } = useFetchChartBars({
    side,
    resolution,
    secondary,
    primary,
    timeEnd,
    timeStart: selectedItem.date,
    mock: USE_MOCK,
    pro: isSwyftxPro,
  });

  const { latestChartBar } = useFetchLatestChartBar({
    side,
    resolution,
    secondary,
    primary,
    timeEnd,
    timeStart: selectedItem.date,
    mock: USE_MOCK,
    refetchInterval: 2500,
    pro: isSwyftxPro,
  });

  const data = useMemo((): Candle[] => {
    if (!chartBars) return [];

    return chartBars.map((bar) => ({
      ...bar,
      close: Number(bar.close.toString()),
      high: Number(bar.high.toString()),
      low: Number(bar.low.toString()),
      open: Number(bar.open.toString()),
      time: DateTime.fromMillis(bar.time).plus({ minutes: DateTime.now().offset }).toMillis(),
    }));
  }, [chartBars]);

  const latestData = useMemo(
    (): Candle | undefined =>
      latestChartBar
        ? ({
            ...latestChartBar,
            close: Number(latestChartBar.close.toString()),
            high: Number(latestChartBar.high.toString()),
            low: Number(latestChartBar.low.toString()),
            open: Number(latestChartBar.open.toString()),
            time: DateTime.fromMillis(latestChartBar.time).plus({ minutes: DateTime.now().offset }).toMillis(),
          } as Candle)
        : undefined,
    [latestChartBar],
  );

  return {
    data,
    latestData,
    isFetching: isFetchingBars,
    timeStart: selectedItem.date,
    timeEnd,
  };
};

export { useSimpleChartData };

import { VariantProps, cva } from 'cva';
// https://www.figma.com/file/bTl1BBi1Bh0ECdgyWX8GAS/Components?type=design&node-id=281-4636&mode=design&t=RufRmJAttZWOqOCd-0

export type AvatarColor = 'primary' | 'personal' | 'purple' | 'teal' | 'magenta' | 'orange' | 'secondary' | 'success';
export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof avatarVariants> {
  color?: AvatarColor;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  text?: string;
}

export const avatarVariants = cva('', {
  variants: {
    color: {
      primary: 'bg-color-background-primary',
      secondary: 'bg-color-background-surface-secondary',
      personal: 'bg-color-background-info',
      purple: 'bg-color-entity-purple',
      magenta: 'bg-color-entity-magenta',
      teal: 'bg-color-entity-teal',
      orange: 'bg-color-entity-orange',
      success: 'bg-color-background-success',
    },
    size: {
      sm: 'w-[1.25rem] h-[1.25rem]',
      md: 'w-[1.5rem] h-[1.5rem]',
      lg: 'w-[2rem] h-[2rem]',
      xl: 'w-[3rem] h-[3rem]',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'primary',
  },
});

import React, { useCallback, useEffect } from 'react';

import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { Modals } from './Modals.enum';
import { useModalMap } from './useModalMap.hooks';

const Modal: React.FC = observer(() => {
  const { setGlobalModalData, globalModalData } = UIStore.useUIStore;
  const { isLoggedIn } = UserStore.useUserStore;
  const modalMapHook = useModalMap(globalModalData?.props);

  const onClose = useCallback(() => {
    setGlobalModalData(undefined);
  }, [setGlobalModalData]);

  const renderModal = (modalId: Modals, props: any) => {
    const modal = modalMapHook[modalId];

    if (!modal || !isLoggedIn) return null;
    return React.cloneElement<any>(modal, {
      ...(modal?.props as {}),
      onClose: () => {
        // If there is a custom onClose on the props, call it as well
        if (props?.onClose) props.onClose();
        onClose();
      },
    });
  };

  useEffect(() => {
    if (!isLoggedIn) onClose();
  }, [isLoggedIn, onClose]);

  if (!globalModalData) return null;

  return renderModal(globalModalData.modal, globalModalData.props);
});

export { Modal };

import { useMemo } from 'react';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { MarketFilterSortType, useMarketsFilterSort } from 'src/lib/markets/hooks/useMarketsFilterSort';
import { MarketAssetFilterType } from 'src/lib/markets/types/Markets.types';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { AssetsSearchRates } from './AssetsSearchRates';
import { GlobalSearchItemType } from '../../search.types';

type Props = {
  filterType?: MarketAssetFilterType;
  sortKey?: MarketFilterSortType;
  excludedAssetCodes?: string[];
  limit?: number;
};

const useAssetsSearchModule = ({
  filterType,
  sortKey = 'rank',
  excludedAssetCodes,
  limit,
}: Props = {}): GlobalSearchItemType[] => {
  const { filteredAndSortedAssets } = useMarketsFilterSort({
    sort: { sortKey, sortDirection: 'DESC' },
    filterType,
    excludedAssetCodes,
    limit,
  });
  const { navigateToAsset } = useAssetNavigation();

  const items = useMemo(
    (): GlobalSearchItemType[] =>
      filteredAndSortedAssets.map((asset) => ({
        leading: <AssetIcon asset={asset} size={24} />,
        title: asset.name,
        subTitle: asset.code,
        trailing: <AssetsSearchRates asset={asset} />,
        onClick: () => navigateToAsset(asset),
      })),
    [filteredAndSortedAssets, navigateToAsset],
  );

  return items;
};

export { useAssetsSearchModule };

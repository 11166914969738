import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { Profile } from './subroutes/Profile';
import { ProfileRoute } from './subroutes/profile.types';

const CommonRoutes: RouteObject[] = [
  {
    path: NavigationURLs.ProfileSettings,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileSettings} />} />,
  },
  {
    path: NavigationURLs.ProfileTradeFees,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileTradeFees} />} />,
  },
  {
    path: NavigationURLs.ProfileApiKeys,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileApiKeys} />} />,
  },
  {
    path: NavigationURLs.ProfileTaxReports,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileTaxReports} />} />,
  },
  {
    path: NavigationURLs.ProfileAffiliateProgram,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileAffiliateProgram} />} />,
  },
];

const ProfileRoutes: RouteObject[] = [
  ...CommonRoutes,
  {
    path: NavigationURLs.ProfileInformation,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileInformation} />} />,
  },
  {
    path: NavigationURLs.ProfileVerification,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileVerification} />} />,
  },
  {
    path: NavigationURLs.ProfileSecurity,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileSecurity} />} />,
  },
  {
    path: NavigationURLs.ProfileReferAFriend,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileReferAFriend} />} />,
  },
  {
    path: NavigationURLs.Profile,
    element: <PrivateRoute element={<Navigate to={NavigationURLs.ProfileInformation} />} />,
  },
  {
    path: NavigationURLs.ProfileAbout, // Angular Redirect
    element: <PrivateRoute element={<Navigate to={NavigationURLs.ProfileInformation} />} />,
  },
  {
    path: NavigationURLs.ProfileEntityVerification,
    element: <PrivateRoute element={<Navigate to={NavigationURLs.ProfileVerification} />} />,
  },
];

const ProfileEntityRoutes: RouteObject[] = [
  ...CommonRoutes,
  {
    path: NavigationURLs.ProfileEntityVerification,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileEntityVerification} />} />,
  },
  {
    path: NavigationURLs.ProfileEntitySecurity,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileEntitySecurity} />} />,
  },
  {
    path: NavigationURLs.ProfileEntityLinkedMembers,
    element: <PrivateRoute element={<Profile route={ProfileRoute.ProfileEntityLinkedMembers} />} />,
  },
  {
    path: NavigationURLs.Profile,
    element: <PrivateRoute element={<Navigate to={NavigationURLs.ProfileEntityVerification} />} />,
  },
  {
    path: NavigationURLs.ProfileInformation,
    element: <PrivateRoute element={<Navigate to={NavigationURLs.ProfileEntityVerification} />} />,
  },
  {
    path: NavigationURLs.ProfileVerification,
    element: <PrivateRoute element={<Navigate to={NavigationURLs.ProfileEntityVerification} />} />,
  },
];

export { ProfileRoutes, ProfileEntityRoutes };

import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

const DefaultDarkThemePalette: any = {
  mode: 'dark',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#f5f7f8',
    secondary: '#b5bdc7',
    disabled: '#394756',
  },
  divider: '#394756',
  background: {
    paper: '#1b2129',
    default: '#0b0d0f',
  },
  getContrastText: () => Colors.White,
  augmentColor: () => ({
    main: '#4c9cf2',
    light: '#4c9cf2',
    dark: '#4c9cf2',
    contrastText: Colors.White,
  }),
  action: {
    hover: '#28313c',
  },
  grey: {
    50: '#f5f7f8',
    100: '#f5f7f8',
    200: '#dce0e4',
    300: '#b5bdc7',
    400: '#8795a4',
    500: '#596c81',
    600: '#394756',
    700: '#28313c',
    800: '#1b2129',
    900: '#11151a',
    A100: '#f5f7f8',
    A200: '#dce0e4',
    A400: '#8795a4',
    A700: '#28313c',
  },
  primary: {
    main: '#4c9cf2',
  },
  secondary: {
    main: '#4c9cf2',
  },
  error: {
    main: '#e05e54',
  },
  warning: {
    main: '#f1814c',
  },
  info: {
    main: '#4c9cf2',
  },
  success: {
    main: '#4ea75a',
  },
  common: {
    black: Colors.Black,
    white: Colors.White,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: '#151e29',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
};

const DefaultDarkTheme = createTheme(DefaultDarkThemePalette);

export { DefaultDarkTheme, DefaultDarkThemePalette };

import v from 'validator';

import { Validator, ValidatorResponse } from '.';

interface Params {
  characters: {
    min: number;
    max: number;
  };
}

class AccountNumberValidator extends Validator<string, any, Params> {
  public additionalParams: Params;

  constructor(_message: string, _additionalParams: Params) {
    super(_message, _additionalParams);
    this.message = _message;
    this.additionalParams = _additionalParams;
  }

  validate = (input: string): ValidatorResponse => {
    /** Must be a number with 6-10 digits */
    const { min, max } = this.additionalParams.characters;

    const regex = new RegExp(`^\\d{${min},${max}}$`);
    const valid = v.matches(input, regex);

    return {
      message: valid ? undefined : this.message,
      valid,
    };
  };
}

export default AccountNumberValidator;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Bundles: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4561 5.91544C14.8954 5.4761 15.6077 5.4761 16.0471 5.91544L18.0623 7.9307C18.5017 8.37003 18.5017 9.08235 18.0623 9.52169L16.0471 11.5369C15.6077 11.9763 14.8954 11.9763 14.4561 11.5369L12.4408 9.52169C12.0015 9.08235 12.0015 8.37004 12.4408 7.9307L14.4561 5.91544ZM15.2516 7.24127L13.7667 8.72619L15.2516 10.2111L16.7365 8.72619L15.2516 7.24127Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.5 8.75C8.5 5.02249 11.5225 2 15.25 2C18.9775 2 22 5.02249 22 8.75C22 12.4782 18.9775 15.5 15.25 15.5C11.5225 15.5 8.5 12.4782 8.5 8.75ZM15.25 3.5C12.3509 3.5 10 5.85092 10 8.75C10 11.6497 12.3509 14 15.25 14C18.1491 14 20.5 11.6497 20.5 8.75C20.5 5.85092 18.1491 3.5 15.25 3.5Z'
      fill='currentColor'
    />
    <path
      d='M6.16236 9.0141C6.58073 8.84027 7.01397 9.1705 7.07307 9.61968C7.12008 9.97695 6.91351 10.3172 6.58531 10.466C4.76587 11.2907 3.5 13.1229 3.5 15.2502C3.5 18.1499 5.85086 20.5002 8.75 20.5002C10.8775 20.5002 12.7097 19.2345 13.5343 17.4149C13.6831 17.0867 14.0234 16.8801 14.3807 16.9271C14.8298 16.9862 15.16 17.4194 14.9862 17.8378C13.971 20.2817 11.5609 22.0002 8.75 22.0002C5.02254 22.0002 2 18.9784 2 15.2502C2 12.4394 3.71869 10.0294 6.16236 9.0141Z'
      fill='currentColor'
    />
  </svg>
);

export { Bundles };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Seigniorage: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M13.6631 7.30371C12.3737 7.30371 11.2408 8.16103 10.8907 9.40307L9.41499 14.6358L8.56066 12.2643C8.241 11.3781 7.48764 10.7189 6.56601 10.5208C6.16105 10.4337 5.76218 10.6914 5.67512 11.0964C5.58805 11.5013 5.84575 11.9002 6.25071 11.9872C6.6657 12.0765 7.00532 12.3734 7.14964 12.7733L8.26455 15.868C8.67854 17.015 10.3189 16.955 10.65 15.7828L12.3345 9.81006C12.5023 9.21452 13.0453 8.80371 13.6631 8.80371H16.518C16.9322 8.80371 17.268 8.46792 17.268 8.05371C17.268 7.6395 16.9322 7.30371 16.518 7.30371H13.6631Z'
      fill='currentColor'
    />
    <path
      d='M13.5139 11.8745C13.8472 11.6286 14.3168 11.6996 14.5626 12.0329L15.2379 12.9485L15.9131 12.0329C16.159 11.6996 16.6285 11.6286 16.9619 11.8745C17.2952 12.1203 17.3662 12.5899 17.1203 12.9232L16.1698 14.2121L17.1203 15.501C17.3662 15.8344 17.2952 16.3039 16.9619 16.5498C16.6285 16.7956 16.159 16.7247 15.9131 16.3913L15.2379 15.4757L14.5626 16.3913C14.3168 16.7247 13.8472 16.7956 13.5139 16.5498C13.1805 16.3039 13.1096 15.8344 13.3554 15.501L14.306 14.2121L13.3554 12.9232C13.1096 12.5899 13.1805 12.1203 13.5139 11.8745Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.78216 2.25C6.12669 2.25 4.72468 2.84117 3.7402 3.89737C2.76117 4.94771 2.25 6.39806 2.25 8.02638V15.9736C2.25 17.601 2.75842 19.0515 3.73657 20.1023C4.72038 21.1591 6.1228 21.75 7.78216 21.75H16.2159C17.8757 21.75 19.2785 21.1592 20.2627 20.1024C21.2413 19.0516 21.75 17.6011 21.75 15.9736V8.02638C21.75 6.39889 21.2413 4.94842 20.2629 3.89765C19.2789 2.84085 17.8762 2.25 16.2169 2.25H7.78216ZM3.75 8.02638C3.75 6.70951 4.16024 5.64667 4.83745 4.92012C5.50921 4.19943 6.49828 3.75 7.78216 3.75H16.2169C17.5057 3.75 18.4946 4.19975 19.1651 4.91984C19.8412 5.64596 20.25 6.70868 20.25 8.02638V15.9736C20.25 17.2913 19.8412 18.354 19.165 19.0801C18.4944 19.8002 17.5052 20.25 16.2159 20.25H7.78216C6.49341 20.25 5.50475 19.8003 4.83451 19.0802C4.15861 18.3541 3.75 17.2914 3.75 15.9736V8.02638Z'
      fill='currentColor'
    />
  </svg>
);

export { Seigniorage };

import { useEffect, useState } from 'react';

const toggleEventListener = <T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  add: boolean,
  ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
) => {
  if (obj) {
    if (add && obj.addEventListener) {
      obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
    } else if (!add && obj.removeEventListener) {
      obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
    }
  }
};

type IState = PermissionState | '';

type DescriptorNamePolyfill =
  | 'accelerometer'
  | 'accessibility-events'
  | 'ambient-light-sensor'
  | 'background-sync'
  | 'camera'
  | 'clipboard-read'
  | 'clipboard-write'
  | 'gyroscope'
  | 'magnetometer'
  | 'microphone'
  | 'notifications'
  | 'payment-handler'
  | 'persistent-storage'
  | 'push'
  | 'speaker';

type GeneralPermissionDescriptor = PermissionDescriptor | { name: DescriptorNamePolyfill };

const useCheckBrowserPermission = (
  permissionDesc: GeneralPermissionDescriptor | GeneralPermissionDescriptor['name'],
): IState => {
  const [state, setState] = useState<IState>('');

  useEffect(() => {
    const desc =
      typeof permissionDesc === 'string'
        ? ({ name: permissionDesc } as PermissionDescriptor)
        : (permissionDesc as PermissionDescriptor);
    let mounted = true;
    let permissionStatus: PermissionStatus | null = null;

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(() => permissionStatus?.state ?? '');
    };

    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query(desc)
        .then((status) => {
          permissionStatus = status;
          toggleEventListener(permissionStatus, true, 'change', onChange);
          onChange();
        })
        .catch(() => {});
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      permissionStatus && toggleEventListener(permissionStatus, false, 'change', onChange);
      mounted = false;
      permissionStatus = null;
    };
  }, [permissionDesc]);

  return state;
};

export { useCheckBrowserPermission };

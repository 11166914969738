import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';

import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

import { FirstTradeWidget, FundAccountWidget, VerifyAccountWidget } from '../../components';

type Props = {
  className?: string;
};

const VerificationCardWidget: React.FC<Props> = observer(({ className }) => {
  const { isVerified, hasDeposited, hasTraded } = useProfileStatus();
  const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'dashboard-page' });

  return (
    <Card className={cn(className, 'p-24')}>
      <FlexLayout alignItems='stretch' justifyContent='space-between' spacing={24}>
        <VerifyAccountWidget
          completed={isVerified}
          isCurrentStep={!isVerified}
          variant='full'
          direction={atLeast.lg ? 'row' : 'column'}
        />
        <FundAccountWidget
          completed={hasDeposited}
          isCurrentStep={isVerified}
          variant='full'
          direction={atLeast.lg ? 'row' : 'column'}
        />
        <FirstTradeWidget
          completed={hasTraded}
          isCurrentStep={hasDeposited}
          variant='full'
          direction={atLeast.lg ? 'row' : 'column'}
        />
      </FlexLayout>
    </Card>
  );
});

export { VerificationCardWidget };

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Chip, Modal, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import {
  Asset,
  AssetHistoryItemStatus,
  depositStatus,
  depositStatusColors,
  orderStatus,
  orderStatusColors,
  TransactionType,
  WithdrawalHistoryResponse,
  withdrawStatus,
  withdrawStatusColors,
} from '@shared/api';
import { AssetType } from '@shared/api/@types/markets';
import { FiatCodeEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService, TransactionHistoryItem } from '@shared/services';
import { UIStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { TransactionTypeFilter } from '@services/WalletService';
import { isDepositHistoryArray, isWithdrawalHistoryArray } from '@utils/guards/transactions';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useAssetWalletHistory } from '../AssetTransactionHistoryModal/AssetTransactionHistoryModals.hooks';

type Props = {
  transaction: TransactionHistoryItem;
  asset: Asset;
  onClose: () => void;
  onSuccess: () => void;
};

const HISTORY_FETCH_LIMIT = 100; //Surely we won't have more than 100 deposits in a 1 hour block

const TransactionDetailsModal: React.FC<Props> = observer(({ transaction, asset, onClose }) => {
  const { t } = useTranslation('common');
  const { t: ordersT } = useTranslation('orders');

  const { t: activityTileT } = useTranslation('dashboard', { keyPrefix: 'activityTile' });
  const { isXs } = useScreenBreakpoints();
  const secondaryAsset = assetService.getAsset(transaction.secondaryAsset);
  const countryAsset = useCountryAsset();
  const { getAssetById } = useMarkets();
  const { navigate } = useNavigateRoute();
  const { addToastMessage } = UIStore.useUIStore;
  const { walletHistory } = useAssetWalletHistory(
    asset,
    transaction.type === TransactionType.Deposit ? TransactionTypeFilter.DEPOSIT : TransactionTypeFilter.WITHDRAWAL,
    DateTime.fromMillis(transaction.date).minus({ minutes: 30 }).toMillis(),
    DateTime.fromMillis(transaction.date).plus({ minutes: 30 }).toMillis(),
    HISTORY_FETCH_LIMIT,
    false,
  );

  const getHistoryItem = useCallback(
    (transactionId: string) => {
      if (walletHistory === null) {
        return null;
      }

      if (isDepositHistoryArray(walletHistory)) {
        return walletHistory.find((item) => item.depositId === transactionId);
      }

      if (isWithdrawalHistoryArray(walletHistory)) {
        return walletHistory.find((item) => item.withdrawalId === transactionId);
      }

      return null;
    },
    [walletHistory],
  );

  const historyItem = useMemo(() => getHistoryItem(transaction.uuid), [getHistoryItem, transaction.uuid]);

  // This is a hack to show the mining fee for NZD which can be used as the withdraw fee
  const withdrawFee = useMemo(() => {
    if (!historyItem) return undefined;

    const historyAsset = getAssetById(historyItem.assetId);
    if (historyAsset?.code === FiatCodeEnum.NZD) {
      return historyAsset?.miningFee;
    }

    return undefined;
  }, [getAssetById, historyItem]);

  const viewWallet = () => {
    if (secondaryAsset) {
      navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: secondaryAsset.code } });
    } else {
      addToastMessage({ message: t('orderDetailsModal.labels.somethingWentWrong'), severity: 'error' });
    }
  };

  const addressLabel = useMemo(
    () =>
      (transaction.label?.includes('Default') ? transaction.label?.split('Default ')[1] : transaction.label) ||
      t('orderDetailsModal.labels.externalWallet'),
    [transaction, t],
  );

  const modalTitle =
    transaction.type === TransactionType.Withdrawal
      ? t('openOrdersSummary.labels.withdraw')
      : t('openOrdersSummary.labels.deposit');

  const getChipLabel = () => {
    let color: 'primary' | 'error' | 'success';
    let text: any;

    if (transaction.type === TransactionType.Deposit) {
      text = ordersT(depositStatus[transaction.statusRaw] as any);
      color = depositStatusColors[transaction.statusRaw];
    } else if (transaction.type === TransactionType.Withdrawal) {
      text = ordersT(withdrawStatus[transaction.statusRaw] as any);
      color = withdrawStatusColors[transaction.statusRaw];
    } else {
      text = ordersT(orderStatus[transaction.statusRaw] as any);
      color = orderStatusColors[transaction.statusRaw];
    }

    return (
      <Stack direction='row' alignItems='center' spacing={1}>
        <Badge variant='dot' color={color} />
        <Typography fontSize={pxToRem(14)} fontWeight={500}>
          {text}
        </Typography>
      </Stack>
    );
  };

  const getDateLabel = (): string => {
    if (transaction.status === AssetHistoryItemStatus.Completed) {
      if (transaction.type === TransactionType.Deposit) {
        return t('orderDetailsModal.labels.dateDeposited');
      }
      if (TransactionType.Withdrawal) {
        return t('orderDetailsModal.labels.dateWithdrawn');
      }
    }

    return t('orderDetailsModal.labels.datePlaced');
  };

  const getAddressLabel = (): string => {
    if (transaction.type === TransactionType.Deposit) {
      if (secondaryAsset?.assetType === AssetType.Fiat) return t('orderDetailsModal.labels.address');
    }
    if (TransactionType.Withdrawal) {
      if (secondaryAsset?.assetType === AssetType.Fiat) return t('orderDetailsModal.labels.withdrawnTo');
      return t('orderDetailsModal.labels.address');
    }

    return t('orderDetailsModal.labels.datePlaced');
  };

  const getAddressMetadata = () => {
    const { bsb, memo, reference } = transaction.addressMetadata || {};

    if (bsb) {
      return ` (BSB ${bsb})`;
    }
    if (memo) {
      return ` (Memo ${memo})`;
    }
    if (reference) {
      return ` (Reference ${reference})`;
    }

    return '';
  };

  return (
    <Modal
      id='transaction-modal'
      open
      onClose={onClose}
      HeaderProps={{
        dismissible: true,
        title: `${modalTitle} ${secondaryAsset?.code}`,
        divider: true,
      }}
      sx={{
        width: '600px',
        '.MuiCardContent-root': { height: '100%' },
        '.MuiCardContent-root > .MuiBox-root': { height: '100%' },
        '.MuiCardContent-root > .MuiBox-root ~ .MuiBox-root': { height: 'auto' },
      }}
      FooterProps={{
        content: (
          <Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center' width='100%'>
            <Button id='btn-close' variant='text' size='medium' color='inherit' onClick={onClose}>
              {t('orderDetailsModal.buttonLabels.close')}
            </Button>
            <Button id='btn-view-asset' variant='outlined' size='medium' color='primary' onClick={viewWallet}>
              {t('orderDetailsModal.buttonLabels.viewWallet')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={2}>
        {/* Status */}
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Typography fontSize={pxToRem(14)} fontWeight={500}>
            {t('orderDetailsModal.labels.status')}
          </Typography>
          <Chip
            size='small'
            label={getChipLabel()}
            sx={{ borderRadius: '4px', marginLeft: 1, backgroundColor: 'secondary' }}
          />
        </Stack>

        {/* Date */}
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Typography fontSize={pxToRem(14)} fontWeight={500}>
            {getDateLabel()}
          </Typography>
          <Typography fontSize={pxToRem(14)} fontWeight={500} sx={{ textTransform: 'uppercase' }}>
            {new Date(transaction.date).toLocaleDateString()}{' '}
            {new Date(transaction.date).toLocaleTimeString([], { hour12: true })}
          </Typography>
        </Stack>

        {/* Amount */}
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Typography color='text.primary' fontSize={pxToRem(14)} fontWeight={500}>
            {activityTileT('labels.amount')}
          </Typography>

          <Stack direction='column' alignItems='flex-end'>
            <Stack direction='row' spacing={0.5}>
              <AssetIcon asset={secondaryAsset} size={18} />
              <FormattedText
                prefix={transaction.type === TransactionType.Withdrawal ? '' : '+'}
                value={transaction.secondaryAmount}
                currency={secondaryAsset}
                typographyProps={{
                  weight: 'emphasis',
                  className: 'truncate',
                }}
                formatOpts={{
                  hideCode: false,
                  appendCode: true,
                }}
              />
            </Stack>
            {secondaryAsset?.assetType === AssetType.Crypto && (
              <FormattedText
                prefix='~'
                value={transaction.userCountryValue}
                currency={countryAsset}
                typographyProps={{
                  color: 'secondary',
                }}
                formatOpts={{
                  hideCode: false,
                  appendCode: true,
                }}
              />
            )}
          </Stack>
        </Stack>

        {/* Transaction ID */}
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Typography color='text.primary' fontSize={pxToRem(14)} fontWeight={500}>
            {activityTileT('labels.transactionId')}
          </Typography>

          <Stack direction='row'>
            <Typography
              color='text.primary'
              fontSize={pxToRem(14)}
              fontWeight={500}
              width='300px'
              sx={{ overflowWrap: 'break-word', textAlign: 'right' }}
            >
              {(historyItem as WithdrawalHistoryResponse)?.transactionId || transaction.uuid}
            </Typography>
          </Stack>
        </Stack>

        {/* Address Label */}
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Typography color='text.primary' fontSize={pxToRem(14)} fontWeight={500}>
            {getAddressLabel()}
          </Typography>

          <Stack direction='row'>
            <Stack direction='column' alignItems='flex-end'>
              <Typography color='text.primary' fontSize={pxToRem(14)} fontWeight={500}>
                {addressLabel}
              </Typography>
              <Typography
                color='text.secondary'
                fontSize={pxToRem(14)}
                fontWeight={400}
                sx={
                  isXs
                    ? {
                        maxWidth: '250px',
                        whiteSpace: 'pre-line',
                        overflowWrap: 'break-word',
                        textAlign: 'right',
                      }
                    : undefined
                }
              >
                {transaction.address}
              </Typography>
              <Typography color='text.secondary' fontSize={pxToRem(14)} fontWeight={400}>
                {getAddressMetadata()}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* Fee */}
        {(transaction.type === TransactionType.Deposit ||
          historyItem?.feeAmount ||
          historyItem?.txFeeAmount ||
          withdrawFee ||
          secondaryAsset?.assetType === AssetType.Fiat) && (
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Typography color='text.primary' fontSize={pxToRem(14)} fontWeight={500}>
              {activityTileT(secondaryAsset?.assetType === AssetType.Fiat ? 'labels.fee' : 'labels.networkFee')}
            </Typography>

            <Stack direction='column' alignItems='flex-end'>
              <FormattedText
                value={Big(historyItem?.feeAmount || withdrawFee || '').plus(historyItem?.txFeeAmount || '')}
                currency={secondaryAsset}
                typographyProps={{
                  size: 'large',
                  weight: 'emphasis',
                }}
                formatOpts={{
                  hideCode: true,
                  appendCode: false,
                }}
                secondaryText={{
                  typographyProps: { size: 'large', color: 'secondary' },
                  value: secondaryAsset?.code,
                }}
              />
              {/* The feeUserCountryValue doesn't appear to be correct commenting out until we can fix this */}
              {/* {secondaryAsset?.assetType === AssetType.Crypto && (
                <FormattedText
                  prefix='~'
                  value={transaction.feeUserCountryValue}
                  currency={countryAsset}
                  typographyProps={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  formatOpts={{
                    hideCode: false,
                    appendCode: true,
                  }}
                />
              )} */}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
});

export { TransactionDetailsModal };

import React, { useContext, useEffect } from 'react';

import { Paper } from '@mui/material';

import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { SwyftxFull } from '@swyftx/aviary/icons/outlined';
import { Stack } from '@swyftx/react-web-design-system';

import { DefaultTheme } from '@global-components/Theme';

import { DarkModeEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { AuthContext, AuthProvider } from '@Auth/Auth.context';
import { authSteps } from '@Auth/Auth.data';

import { observer } from 'mobx-react-lite';

const InnerAuth: React.FC = observer(() => {
  // todo: redirectUri that only accepts *.superhero.com (or localhost?)

  const { setRecaptchaToken, setRefreshRecaptcha } = UserStore.useUserStore;
  const { authStep } = useContext(AuthContext);
  const { forceSetTheme } = useTailwindTheme();

  useEffect(() => {
    setRecaptchaToken('');
    setRefreshRecaptcha(true);
  }, [setRecaptchaToken, setRefreshRecaptcha]);

  useEffect(() => {
    forceSetTheme(TailWindTheme.Light);

    return () => {
      forceSetTheme();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultTheme mode={DarkModeEnum.LIGHT}>
      <Stack
        direction='column'
        width='100%'
        height='100%'
        padding='48px'
        justifyContent='center'
        alignItems='center'
        gap='42px'
        className='bg-color-background-primary'
      >
        <SwyftxFull id='loginCarousel.logo.light.image' alt='Swyftx' className='h-[3rem] text-color-text-inverse' />

        <Paper sx={{ width: '480px', padding: '48px', flex: '0 1' }}>{authSteps[authStep]}</Paper>
      </Stack>
    </DefaultTheme>
  );
});

const Auth: React.FC = () => (
  <AuthProvider>
    <InnerAuth />
  </AuthProvider>
);

Auth.displayName = 'Auth';

export default Auth;

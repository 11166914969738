import React from 'react';

import SvgIconMUI, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

export const StyledIcon = styled(SvgIconMUI)`
  &.MuiSvgIcon-root {
    fill: none !important;
  }
`;

const TickCircleIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <StyledIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89182 17.108 2.74982 12 2.74982C6.892 2.74982 2.75 6.89182 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity='0.4'
      d='M8.43994 12.0002L10.8139 14.3732L15.5599 9.6272'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledIcon>
);

export { TickCircleIcon };

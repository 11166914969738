import React, { HTMLAttributes } from 'react';

import { FlexLayoutSpacing } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';

import { cn } from '@shared/utils/lib/ui';

import { PageHeader } from '../PageHeader';
import { HeaderAlignment, HeaderIcon, HeaderMetrics } from '../PageHeader/PageHeader.types';
import { PageHeaderBase } from '../PageHeader/PageHeaderBase';

type Props = {
  title?: string;
  titleSpacing?: FlexLayoutSpacing;
  icon?: HeaderIcon;
  actions?: React.ReactNode[];
  compactActions?: React.ReactNode[];
  showCompactHeader?: boolean;
  content?: string;
  contentClassName?: string;
  alignment?: HeaderAlignment;
  metrics?: HeaderMetrics[];
  className?: string;
  customHeader?: React.ReactNode;
  offset?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'style'>;

const Page = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      title,
      titleSpacing,
      icon,
      alignment = 'start',
      metrics,
      actions,
      compactActions,
      content,
      contentClassName,
      className,
      children,
      showCompactHeader = true,
      customHeader,
      offset = true,
      ...props
    },
    ref,
  ) => (
    <div ref={ref} className={cn('bg-color-background-pageBG', className)} {...props}>
      {!customHeader ? (
        <PageHeader
          title={title}
          titleSpacing={titleSpacing}
          className='!xs-heading-padding @!sm:sm-heading-padding @md:!md-heading-padding @xl:!xl-heading-padding  -ml-[100vw] -mr-[100vw]'
          position='relative'
          alignment={alignment}
          offset={offset}
          icon={icon}
          actions={actions}
          compactActions={compactActions}
          showCompactHeader={showCompactHeader}
          content={content}
          contentClassName={contentClassName}
          metrics={metrics}
        />
      ) : (
        <PageHeaderBase
          offset={offset}
          className={cn(
            className,
            '!xs-heading-padding @!sm:sm-heading-padding @md:!md-heading-padding @xl:!xl-heading-padding  -ml-[100vw] -mr-[100vw]',
          )}
        >
          {customHeader}
        </PageHeaderBase>
      )}

      {children}
    </div>
  ),
);

Page.displayName = 'Page';

export { Page };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { useTransactionOrderType } from 'src/lib/transactions/hooks/useTransactionOrderType';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';
import { InfoTooltip } from 'src/lib/utils/components';

import { useBuildSwyftxProOrder } from '../../hooks/useBuildSwyftxProOrder';
import { useSwyftxProTradeAmount } from '../../hooks/useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from '../../hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from '../../hooks/useSwyftxProTradeDirection';
import { useSwyftxProTradePrice } from '../../hooks/useSwyftxProTradePrice';
import { useSwyftxProTradeType } from '../../hooks/useSwyftxProTradeType';

const SwyftxProOrderConfirmationModalOrderDetails: React.FC = observer(() => {
  const { selectedAsset, baseAsset, sellAsset, buyAsset } = useSwyftxProTradeAssets();
  const { total, totalFee, amountFee } = useSwyftxProTradeAmount();
  const { isBuy } = useSwyftxProTradeDirection();
  const { isLimit, isMarket } = useSwyftxProTradeType();
  const { triggerPrice, currentPrice } = useSwyftxProTradePrice();
  const { order } = useBuildSwyftxProOrder();
  const { parseOrderTypeName } = useTransactionOrderType();
  const { lowestFee } = useCurrentUserFees();

  if (!selectedAsset || !buyAsset || !sellAsset) return null;

  return (
    <FlexLayout direction='column' spacing={16}>
      <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
        <Body weight='emphasis'>Order type</Body>
        <Body color='secondary'>{parseOrderTypeName(order?.orderType)}</Body>
      </FlexLayout>
      {currentPrice && (
        <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
          <Body weight='emphasis'>Current price</Body>
          <Body color='secondary'>
            {formatCurrency(currentPrice, baseAsset, { appendCode: true, hideCode: false, isApproximate: true })}
          </Body>
        </FlexLayout>
      )}
      {isLimit && triggerPrice && (
        <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
          <Body weight='emphasis'>Trigger price</Body>
          <Body color='secondary'>
            {formatCurrency(triggerPrice, isBuy ? sellAsset : buyAsset, { appendCode: true, hideCode: false })}
          </Body>
        </FlexLayout>
      )}
      {totalFee && (
        <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
          <FlexLayout alignItems='center' spacing={4}>
            <Body weight='emphasis'>
              {isMarket ? '' : 'Estimated '}Fees {`(${lowestFee.times(100).toString()}%)`}
            </Body>
            {amountFee && (
              <InfoTooltip
                tooltip={formatCurrency(amountFee, isBuy ? buyAsset : sellAsset, { appendCode: true, hideCode: false })}
              />
            )}
          </FlexLayout>
          <Body color='secondary'>
            {formatCurrency(totalFee, isBuy ? sellAsset : buyAsset, { appendCode: true, hideCode: false })}
          </Body>
        </FlexLayout>
      )}
      {total && (
        <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
          <Body weight='emphasis'>Total</Body>
          <Body color='secondary'>
            {formatCurrency(total, isBuy ? sellAsset : buyAsset, { appendCode: true, hideCode: false })}
          </Body>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export { SwyftxProOrderConfirmationModalOrderDetails };

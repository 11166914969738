import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox, Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { Links } from '@shared/constants';

type Props = {
  open: boolean;
  doNotShowToggle?: boolean;
  onDoNotShowToggle: (checked: boolean) => void;
  onClose: () => void;
};

const TriggerOrdersNoteModal: React.FC<Props> = ({ open, onClose, doNotShowToggle, onDoNotShowToggle }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'triggerOrderNotes' });

  return (
    <Modal
      id='note-about-trigger-orders-modal'
      open={open}
      sx={{ width: '400px' }}
      HeaderProps={{ title: 'A note about trigger orders', dismissible: true, divider: true }}
      FooterProps={{
        divider: true,
        content: (
          <Stack width='100%'>
            <Button variant='contained' onClick={onClose}>
              {t('buttonLabels.understand')}
            </Button>
          </Stack>
        ),
      }}
      onClose={onClose}
    >
      <Stack spacing={2}>
        <Typography fontSize={16} fontWeight={400} color='text.secondary'>
          {t('labels.learnMoreText')}
        </Typography>
        <Typography marginTop={2} color='text.secondary'>
          <Trans
            t={t}
            i18nKey='labels.historyPage'
            components={[
              <Typography
                key='hyperlink'
                display='inline'
                fontSize={16}
                fontWeight={400}
                color='primary'
                onClick={() => window.open(Links.help.orderTypes, '_blank', 'noopener,noreferrer')}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              />,
            ]}
          />
        </Typography>
        <Checkbox
          label={t('buttonLabels.doNotShow')}
          checked={doNotShowToggle}
          onChange={(_, checked) => onDoNotShowToggle(checked)}
        />
      </Stack>
    </Modal>
  );
};

export { TriggerOrdersNoteModal };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Star: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M7.19973 21.25C6.78973 21.25 6.37973 21.12 6.02973 20.86C5.41973 20.42 5.12973 19.68 5.24973 18.95L5.94973 14.94C5.97973 14.79 5.92973 14.64 5.81973 14.53L2.84973 11.69C2.30973 11.17 2.11973 10.4 2.33973 9.69C2.56973 8.97 3.18973 8.45 3.93973 8.34L8.02973 7.75C8.18973 7.73 8.31973 7.63 8.38973 7.49L10.2197 3.84C10.5597 3.17 11.2397 2.75 11.9997 2.75C12.7597 2.75 13.4397 3.17 13.7797 3.84L15.6097 7.49C15.6797 7.63 15.8097 7.73 15.9697 7.75L20.0597 8.34C20.8197 8.45 21.4297 8.97 21.6597 9.69C21.8897 10.41 21.6897 11.17 21.1497 11.7L18.1897 14.54C18.0797 14.65 18.0297 14.8 18.0597 14.94L18.7597 18.95C18.8897 19.69 18.5897 20.42 17.9797 20.87C17.3597 21.32 16.5597 21.38 15.8897 21.03L12.2397 19.14C12.0997 19.07 11.9297 19.07 11.7797 19.14L8.12973 21.03C7.83973 21.18 7.51973 21.26 7.20973 21.26L7.19973 21.25ZM11.9997 4.25C11.9097 4.25 11.6897 4.28 11.5697 4.51L9.73973 8.16C9.44973 8.74 8.88973 9.14 8.24973 9.23L4.15973 9.82C3.88973 9.86 3.79973 10.06 3.76973 10.14C3.73973 10.22 3.69973 10.43 3.88973 10.61L6.84973 13.45C7.31973 13.9 7.53973 14.56 7.41973 15.2L6.71973 19.21C6.67973 19.46 6.82973 19.61 6.89973 19.66C6.97973 19.71 7.16973 19.83 7.41973 19.7L11.0697 17.8C11.6497 17.5 12.3297 17.5 12.9097 17.8L16.5597 19.69C16.8097 19.82 16.9997 19.71 17.0797 19.65C17.1497 19.6 17.3097 19.45 17.2597 19.2L16.5597 15.19C16.4497 14.55 16.6597 13.9 17.1297 13.45L20.0897 10.61C20.2797 10.43 20.2397 10.23 20.2097 10.14C20.1797 10.06 20.0897 9.86 19.8197 9.82L15.7297 9.23C15.0897 9.14 14.5297 8.74 14.2397 8.16L12.4097 4.51C12.2897 4.27 12.0697 4.25 11.9797 4.25H11.9997Z'
      fill='currentColor'
    />
  </svg>
);

export { Star };

import React, { useContext, useMemo } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Slider } from '@swyftx/aviary/atoms/Slider/Slider';
import { Body, Heading, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';
import { formatCurrency } from '@swyftx/currency-util';

import { AssetType } from '@shared/api';

import { observer } from 'mobx-react-lite';

import { EditTriggerOrderContext } from './EditTriggerOrder.context';

export const EditOrderAmount: React.FC = observer(() => {
  const {
    primaryAsset,
    secondaryAsset,
    fromAsset,
    toAsset,
    approxSecondaryAmountString,
    amountAvailable,
    amount,
    isBaseAssetToggled,
    onToggleAsset,
    orderSide,
    sliderValue,
    onSliderValueChange,
    onAmountValueChange,
    isFiat,
    error,
  } = useContext(EditTriggerOrderContext);

  const trailingCode = useMemo(
    () => (isBaseAssetToggled ? primaryAsset.code : secondaryAsset.code),
    [isBaseAssetToggled, primaryAsset.code, secondaryAsset.code],
  );

  const showShowSlider = useMemo(() => {
    if (orderSide === 'Buy' && toAsset.assetType !== AssetType.Fiat && isBaseAssetToggled) {
      return true;
    }
    if (orderSide === 'Sell' && fromAsset.assetType === AssetType.Crypto && !isBaseAssetToggled) {
      return true;
    }
    return false;
  }, [fromAsset.assetType, isBaseAssetToggled, orderSide, toAsset.assetType]);

  type AssetTab = EnhancedTab<string>;

  const assetTabs: AssetTab[] = [
    { title: primaryAsset.code, value: primaryAsset.code },
    { title: secondaryAsset.code, value: secondaryAsset.code },
  ];

  return (
    <FlexLayout direction='column' spacing={4}>
      <FlexLayout direction='column' spacing={0}>
        <Heading size='h6'>Amount</Heading>
        <FlexLayout direction='row' className='w-full items-center justify-between'>
          <Body color='secondary' size='small'>
            Available: {`${formatCurrency(amountAvailable, { isFiat: isFiat(fromAsset) })} ${fromAsset?.code}`}
          </Body>
          <EnhancedTabs<string>
            variant='default'
            tabs={assetTabs}
            onChange={onToggleAsset}
            size='sm'
            value={isBaseAssetToggled ? primaryAsset.code : secondaryAsset.code}
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='column' spacing={8}>
        <Input
          trailing={<Body color='secondary'>{trailingCode}</Body>}
          onChange={(e) => onAmountValueChange(e.target.value)}
          className='text-16'
          fontSize='large'
          variant='monospace'
          value={amount}
        />
        <Numeric color='secondary'>{approxSecondaryAmountString}</Numeric>
      </FlexLayout>
      {showShowSlider && (
        <FlexLayout direction='row' className='justify-between py-8' spacing={8}>
          <div className='w-[90%]'>
            <Slider value={sliderValue} onValueChange={onSliderValueChange} />
          </div>
          <Numeric className='justify-end'>{sliderValue.toFixed(2)}%</Numeric>
        </FlexLayout>
      )}
      {error && <Body color='error'>{error}</Body>}
    </FlexLayout>
  );
});

import { useCallback } from 'react';

import { api } from '@shared/api';
import { GetEmploymentTypesResponse, GetIndustryResponse, GetOccupationResponse } from '@shared/api/@types/compliance';

import { useQuery } from '@tanstack/react-query';

export const useOccupationAndIndustry = () => {
  const fetchIndustries = useCallback(async () => {
    const response = await api.endpoints.getIndustries();
    return response.data;
  }, []);

  const fetchOccupations = useCallback(async () => {
    const response = await api.endpoints.getOccupations();
    return response.data;
  }, []);

  const fetchEmploymentTypes = useCallback(async () => {
    const response = await api.endpoints.getEmploymentTypes();
    return response.data;
  }, []);

  const { data: industries } = useQuery<GetIndustryResponse>({
    queryKey: ['sow-industries'],
    queryFn: () => fetchIndustries(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data: occupations } = useQuery<GetOccupationResponse>({
    queryKey: ['sow-occupations'],
    queryFn: () => fetchOccupations(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data: employmentTypes } = useQuery<GetEmploymentTypesResponse>({
    queryKey: ['sow-employment-types'],
    queryFn: () => fetchEmploymentTypes(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { industries: industries ?? [], occupations: occupations ?? [], employmentTypes: employmentTypes ?? [] };
};

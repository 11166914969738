import React from 'react';

import { StyledDot } from './CarouselDot.styled';
import { ComponentProps, ComponentStateProps, EventProps } from '../../global-utils';

type Props = ComponentProps & EventProps & ComponentStateProps;

const CarouselDot: React.FC<Props> = ({ id, active, onClick }) => (
  <StyledDot id={id} active={active} onClick={onClick} />
);

export default CarouselDot;

import { AddressBrokenUp } from '@shared/api';

export const addressToString = (address: Partial<AddressBrokenUp>) => {
  const { flatNumber, streetNumber, streetName, streetType, suburb, state, postcode } = address || {};

  if (!streetNumber || !streetName || !streetType || !suburb || !state || !postcode) return '';

  const unit = `${flatNumber ? `U ${flatNumber} ` : ''}`;
  const street = `${streetNumber} ${streetName} ${streetType}`;
  const stateSuburb = `${suburb} ${state} ${postcode}`;

  return `${unit}${street}, ${stateSuburb}`;
};

export const LINE_CHART_SETTINGS = {
  defaults: {
    strokeWidth: 1,
    width: 800,
    height: 450,
    xTicks: 6,
    yTicks: 6,
  },
  tooltipThrottle: 200,
  overlapPadding: 10,
  tooltipWidth: '1px',
  yAxisPaddingFactor: 1.01,
  axisFontSize: 12,
};

import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { SwyftxError } from '@shared/error-handler';
import { UIStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';
import UserService from '@services/UserService';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

interface PasswordResetContextType {
  getStep: () => 1 | 2;
  signOut: () => void;
  submit: (values: PasswordResetValues) => void;
  submitOTP: (values: PasswordResetValues) => void;
  resendOTP: () => void;
  form: UseFormReturn<PasswordResetValues, any>;
  mfaType?: 'sms' | 'email';
}

type PasswordResetValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  newOTP?: string;
};

export const PasswordResetContext = createContext<PasswordResetContextType | undefined>(undefined);

const ResetPasswordSchema = z
  .object({
    currentPassword: z.string().min(1),
    newPassword: z
      .string()
      .regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/, 'Please enter a valid password'),
    confirmPassword: z.string(),
    newOTP: z.string().optional(),
  })
  .superRefine(({ newPassword, confirmPassword }, ctx) => {
    if (confirmPassword !== newPassword) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'Passwords do not match',
      });
      return false;
    }

    return true;
  });

export const PasswordResetContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [step, setStep] = useState<1 | 2>(1);
  const [otpToken, setOtpToken] = useState('');
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [mfaType, setMfaType] = useState<'sms' | 'email'>();
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('modals');
  const logout = useLogout();

  const getStep: () => 1 | 2 = useCallback(() => step, [step]);

  const updateStep: (step: 1 | 2) => void = useCallback(
    (newStep) => {
      setStep(newStep);
    },
    [setStep],
  );

  const form = useForm<PasswordResetValues>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      newOTP: '',
    },
    resolver: zodResolver(ResetPasswordSchema, undefined, { rawValues: true }),
    mode: 'onChange',
  });

  const completeFlow = useCallback(async () => {
    addMessageBox({
      content: (
        <SuccessMessageBox title={t('changePassword.success.title')} content={t('changePassword.success.subTitle')} />
      ),
    });
    await logout();
  }, [addMessageBox, logout, t]);

  const submit = useCallback(
    async (values: PasswordResetValues) => {
      try {
        const res = await UserService.ChangePassword(values.currentPassword, values.newPassword);
        if (!res.token) {
          return await completeFlow();
        }
        setOtpToken(res.token);
        if (res.mfaType) setMfaType(res.mfaType);
        setCurrentPassword(values.currentPassword);
        setNewPassword(values.newPassword);
        updateStep(2);
      } catch (e) {
        const err = e as SwyftxError;
        addMessageBox({
          content: (
            <ErrorMessageBox
              title={t('changePassword.error.title')}
              content={err?.errorMessage || t('changePassword.error.subTitle')}
            />
          ),
        });
      }
    },
    [addMessageBox, completeFlow, t, updateStep],
  );

  const submitOTP = useCallback(
    async (values: PasswordResetValues) => {
      try {
        const auth = `${otpToken} ${values.newOTP}`;
        const res = await UserService.ChangePassword(values.currentPassword, values.newPassword, auth);
        if (!res.token) {
          await completeFlow();
        } else {
          addMessageBox({
            content: <ErrorMessageBox title='OTP error' content='OTP code was incorrect, please try again' />,
          });
        }
      } catch (e) {
        // Take the user back to the first step if there is an error
        updateStep(1);
        form.setValue('newOTP', '');
        const err = e as SwyftxError;
        addMessageBox({
          content: (
            <ErrorMessageBox
              title={t('changePassword.error.title')}
              content={err?.errorMessage || t('changePassword.error.subTitle')}
            />
          ),
        });
      }
    },
    [addMessageBox, completeFlow, form, otpToken, t, updateStep],
  );

  const resendOTP = useCallback(async () => {
    try {
      if (!currentPassword || !newPassword) throw new Error('Missing current password or new password');
      const res = await UserService.ChangePassword(currentPassword, newPassword);
      if (res.token) setOtpToken(res.token);
    } catch (e) {
      const err = e as SwyftxError;
      addMessageBox({
        content: (
          <ErrorMessageBox
            title={t('changePassword.error.title')}
            content={err?.errorMessage || t('changePassword.error.subTitle')}
          />
        ),
      });
    }
  }, [addMessageBox, currentPassword, newPassword, t]);

  return (
    <PasswordResetContext.Provider
      value={{
        getStep,
        submit,
        signOut: logout,
        form,
        submitOTP,
        mfaType,
        resendOTP,
      }}
    >
      {children}
    </PasswordResetContext.Provider>
  );
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Progress } from '@swyftx/aviary/atoms/Progress';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { FormattedText } from '@global-components/Text';

import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useWithdrawalLimit } from '@Wallet/components/WithdrawalLimit/WithdrawalLimit.hooks';

import { useIntercom } from 'react-use-intercom';

type Props = {
  variant?: 'compact' | 'default';
};

export const WithdrawalLimit: React.FC<Props> = ({ variant = 'default' }) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'withdrawalLimit' });
  const { showNewMessages } = useIntercom();
  const { data: limits, isFetching: loading, error } = useWithdrawalLimit();
  const { userCountryCurrency } = UserStore.useUserStore;

  const countryAsset = assetService.getAsset(userCountryCurrency);

  const used = useMemo(() => Big(limits?.used || 0), [limits?.used]);
  const remaining = useMemo(() => Big(limits?.remaining || 0), [limits?.remaining]);

  const total = useMemo(() => used.add(remaining), [remaining, used]);
  const progress = useMemo(() => used.div(total).times(100), [total, used]);

  // TODO
  if (error) return null;

  return (
    <FlexLayout
      direction='column'
      alignItems='center'
      className={cn(
        'w-full rounded-16 bg-color-background-surface-secondary text-center',
        variant === 'default' ? 'px-16 py-32' : 'px-8 py-16',
      )}
      spacing={variant === 'default' ? 16 : 4}
    >
      <Body weight='bold' color='primary'>
        {t('title')}
      </Body>
      <FlexLayout direction='row' alignItems='center' spacing={4}>
        <FormattedText
          typographyProps={{ size: variant === 'default' ? 'large' : 'medium', weight: 'bold', color: 'primary' }}
          value={used}
          loading={loading}
          currency={countryAsset}
        />
        <Body color='secondary'>/</Body>
        <FormattedText
          typographyProps={{ size: variant === 'default' ? 'large' : 'medium', color: 'secondary' }}
          value={total}
          formatOpts={{ appendCode: true }}
          loading={loading}
          currency={countryAsset}
        />
      </FlexLayout>
      <div className='w-3/4'>
        <Progress value={progress.toNumber()} />
      </div>
      <div>
        <Body size='small' color='secondary' weight='emphasis' className='inline'>
          Note: limit includes both fiat and crypto transactions. Increase your daily limit{' '}
        </Body>
        <Body
          className='inline cursor-pointer hover:underline'
          size='small'
          color='accent'
          weight='emphasis'
          onClick={() => showNewMessages()}
        >
          here.
        </Body>
      </div>
    </FlexLayout>
  );
};

import { APIKey, APIKeyScopeItem } from '../../@types/user';

export interface SelectableAPIKeyScopeItem extends APIKeyScopeItem {
  expanded: boolean;
  locked: boolean;
  selected: boolean;
}

export enum CreateAPIKeyType {
  ReadOnly = 'Reading',
  TradeOnly = 'Trading',
  Admin = 'Admin',
  Custom = 'Custom',
}

export interface ScopeGroup {
  parent: SelectableAPIKeyScopeItem;
  children: SelectableAPIKeyScopeItem[];
}

export interface GroupedScope {
  [key: string]: ScopeGroup;
}

const scopeDependants = {
  'orders.create': {
    deps: [
      { group: 'account', scopes: ['balance', 'read'] },
      { group: 'orders', scopes: ['read'] },
    ],
    message: 'Required to create orders', // TODO
  },
  'funds.withdraw': {
    deps: [
      { group: 'account', scopes: ['balance', 'read'] },
      { group: 'address', scopes: ['read'] },
      { group: 'funds', scopes: ['read', 'withdrawal-limit'] },
    ],
    message: 'Required to withdraw funds', // TODO
  },
  'recurring-orders.create': {
    deps: [
      { group: 'orders', scopes: ['create', 'read'] },
      { group: 'account', scopes: ['balance', 'read'] },
    ],
  },
};

const presets = [
  {
    name: CreateAPIKeyType.ReadOnly,
    scopes: [
      'account.tax-report',
      'account.balance',
      'account.read',
      'address.read',
      'funds.read',
      'orders.read',
      'api.read',
      'recurring-orders.read',
    ],
  },
  {
    name: CreateAPIKeyType.TradeOnly,
    scopes: [
      'account.tax-report',
      'orders',
      'account.balance',
      'account.read',
      'address.read',
      'funds.read',
      'recurring-orders.read',
    ],
  },
  {
    name: CreateAPIKeyType.Admin,
    scopes: ['account', 'recurring-orders', 'address', 'funds', 'orders', 'api'],
  },
];

const formatDisplayName = (name: string) => {
  const parts = name.split(' ');
  parts.forEach((part, i) => {
    parts[i] = part[0].toUpperCase() + part.slice(1);
  });
  return parts.join(' ');
};

const getSelectedPermission = (localScopes: GroupedScope): string[] => {
  // return string array of selected permissions
  const permissions: string[] = [];
  for (const group of Object.values(localScopes)) {
    if (group.parent.selected) permissions.push(group.parent.key);
    for (const child of group.children) {
      if (child.selected) permissions.push(child.key);
    }
  }
  return permissions;
};

const toggleAll = (localScopes: GroupedScope, select: boolean) => {
  // set all scopes
  for (const scope of Object.values(localScopes)) {
    scope.parent.selected = select;
    for (const child of scope.children) {
      child.selected = select;
    }
  }

  // reset all scopes lock status
  resetAllScopes(localScopes);

  if (select) {
    // set all required read scopes
    checkReadScopes(localScopes);
    // check dependants
    checkDependants(localScopes);
  }
};

const resetAllScopes = (localScopes: GroupedScope) => {
  for (const group of Object.values(localScopes)) {
    group.parent.locked = false;
    for (const child of group.children) {
      child.locked = false;
    }
  }
};

const checkReadScopes = (localScopes: GroupedScope) => {
  for (const group of Object.values(localScopes)) {
    const hasSelection = group.children.find((child) => child.selected);
    if (hasSelection && hasSelection.key !== `${group.parent.key}.read`) {
      const readScope = group.children.find((child) => child.key === `${group.parent.key}.read`);
      if (readScope) {
        readScope.selected = true;
        readScope.locked = true;
      }
    }
  }
};

const checkDependants = (localScopes: GroupedScope) => {
  for (const [scope, value] of Object.entries(scopeDependants)) {
    const group = scope.split('.')[0];
    const selected = localScopes[group].children.find((child) => child.key === `app.${scope}` && child.selected);

    if (selected) {
      for (const dep of value.deps) {
        for (const scope of dep.scopes) {
          const dependantScope = localScopes[dep.group].children.find(
            (child) => child.key === `app.${dep.group}.${scope}`,
          );
          if (dependantScope) {
            dependantScope.selected = true;
            dependantScope.locked = true;
          }
        }
      }
    }
  }
};

const getKeyType = (localScopes: GroupedScope) => {
  const permissions = getSelectedPermission(localScopes);

  for (const preset of presets) {
    let scopeCount = 0;
    let selectedCount = 0;
    for (const scope of preset.scopes) {
      // get number of permissions covered by the scope
      let count = 1;
      const isParentScope = scope.split('.').length === 1;
      if (isParentScope) {
        count += localScopes[scope].children.length;
      }
      scopeCount += count;
      const hasScope = !!permissions.find((perm) => perm === `app.${scope}`);
      if (hasScope) {
        selectedCount += count;
      } else {
        selectedCount = 0;
        break;
      }
    }
    // is using a preset when we have the same number of selected permissions as the scope requires
    // and they are the total number of permissions we have selected
    if (scopeCount === selectedCount && scopeCount === permissions.length) {
      return preset.name;
    }
  }
  return CreateAPIKeyType.Custom;
};

const getKeyTypeFromKey = (apiKey: APIKey): CreateAPIKeyType => {
  for (const preset of presets) {
    const keyScopes = apiKey.scope.split(' ');
    let hasMissing = false;

    for (const presetScope of preset.scopes) {
      const search = `app.${presetScope}`;

      let hasMatch = false;
      for (let i = 0; i < keyScopes.length; i++) {
        if (keyScopes[i].includes(search)) {
          hasMatch = true;
          keyScopes.splice(i--, 1);
        }
      }

      if (!hasMatch) {
        hasMissing = true;
        break;
      }
    }

    if (!hasMissing) {
      // remove app and offline_access parent scopes as they are automatically added on the server
      for (let i = 0; i < keyScopes.length; i++) {
        if (keyScopes[i] === 'app' || keyScopes[i] === 'offline_access') {
          keyScopes.splice(i--, 1);
        }
      }
      if (!keyScopes.length) return preset.name;
    }
  }

  return CreateAPIKeyType.Custom;
};

const checkAnySelected = (localScopes: GroupedScope) => {
  for (const scope of Object.values(localScopes)) {
    for (const child of scope.children) {
      if (child.selected) {
        return true;
      }
    }
  }

  return false;
};

const apiKeyHelpers = {
  scopeDependants,
  presets,
  getKeyType,
  checkDependants,
  checkReadScopes,
  resetAllScopes,
  toggleAll,
  getSelectedPermission,
  formatDisplayName,
  getKeyTypeFromKey,
  checkAnySelected,
};

export default apiKeyHelpers;

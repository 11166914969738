import React, { useState, useCallback } from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { SourceOfWealthOption } from '@shared/api/@types/compliance';

import { SourcesOfIncomeOptionsText } from '@routes/SourceOfWealth/types/QuestionOptions';

import { useSourceOfWealthSelector, useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';
import { Controller, useForm } from 'react-hook-form';

import { SourceOfWealthStepContainer } from './SourceOfWealthStepContainer';

type FormInputs = { sourcesOfWealth: SourceOfWealthOption[]; sourcesOfWealthOther?: string };

const PrimarySourceOfIncomeStep: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();
  const verificationData = useSourceOfWealthSelector((state) => state.context.verificationData);

  const { control, handleSubmit, watch } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      sourcesOfWealth: verificationData.sourcesOfWealth ?? [],
      sourcesOfWealthOther: verificationData.sourcesOfWealthOther,
    },
  });

  const [checkedOtherSource, setCheckedOtherSource] = useState<boolean>(
    verificationData.sourcesOfWealthOther !== undefined,
  );

  const sourcesOfWealth = watch('sourcesOfWealth');
  const sourcesOfWealthOther = watch('sourcesOfWealthOther');

  const canContinue = useCallback(() => {
    if (checkedOtherSource) {
      return sourcesOfWealthOther && sourcesOfWealthOther.length > 2;
    }
    return sourcesOfWealth.length > 0;
  }, [checkedOtherSource, sourcesOfWealth, sourcesOfWealthOther]);

  const stripSourceOfWealthOtherIfNotNeeded = useCallback(
    (data: FormInputs) => {
      if (!checkedOtherSource) {
        return { sourcesOfWealth: data.sourcesOfWealth };
      }
      return data;
    },
    [checkedOtherSource],
  );

  return (
    <SourceOfWealthStepContainer
      customTitle='What are your primary sources of wealth?'
      onSubmit={handleSubmit((data) =>
        sourceOfWealthService.send({ type: 'NEXT', data: stripSourceOfWealthOtherIfNotNeeded(data) }),
      )}
      submitDisabled={!canContinue()}
    >
      <FlexLayout direction='column' spacing={8}>
        <Body size='small' weight='emphasis'>
          Please select all that apply:
        </Body>
        <Controller
          name='sourcesOfWealth'
          control={control}
          render={({ field }) => (
            <FlexLayout direction='column' spacing={16}>
              {Object.entries(SourceOfWealthOption).map(([key, value]) => (
                <FlexLayout key={key} direction='row' className='flex items-center' spacing={16}>
                  <Checkbox
                    className='h-20 w-20 border-2 border-color-border-main'
                    value={value}
                    checked={field.value.includes(value)}
                    onCheckedChange={() => {
                      const checkedValue = value;
                      const updatedValue = field.value.includes(checkedValue)
                        ? field.value.filter((item) => item !== checkedValue)
                        : [...field.value, checkedValue];
                      field.onChange(updatedValue);
                    }}
                  />
                  <Body size='small'>{SourcesOfIncomeOptionsText[value]}</Body>
                </FlexLayout>
              ))}
              <FlexLayout direction='row' className='flex items-center' spacing={16}>
                <Checkbox
                  className='h-20 w-20 border-2 border-color-border-main'
                  checked={checkedOtherSource}
                  onCheckedChange={() => {
                    setCheckedOtherSource(!checkedOtherSource);
                  }}
                />
                <Body size='small'>Other</Body>
              </FlexLayout>
            </FlexLayout>
          )}
        />

        {checkedOtherSource && (
          <FlexLayout direction='column' spacing={8} className='mt-16'>
            <Body size='small' weight='emphasis'>
              Please specify other source of deposits:
            </Body>
            <Controller
              name='sourcesOfWealthOther'
              control={control}
              render={({ field }) => <Input placeholder='e.g Loans' value={field.value} onChange={field.onChange} />}
            />
          </FlexLayout>
        )}
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

export { PrimarySourceOfIncomeStep };

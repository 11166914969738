import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { UniversalTradeStore } from '@shared/store';
import { TradeState, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { observer } from 'mobx-react-lite';
import { useTradeInputSwapErrors } from 'src/lib/trade/hooks/General/useTradeInputSwapErrors';

type Props = {
  state: TradeState;
  disabled?: boolean;
};

const TradeOrderDetailsContinue: React.FC<Props> = observer(({ state, disabled }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });
  const { tradeType, setTradeState, tradeFrom, tradeTo } = UniversalTradeStore;

  const { errorAssets: from } = useTradeInputSwapErrors([...tradeFrom], TradeSide.From, tradeType);
  const { errorAssets: to } = useTradeInputSwapErrors([...tradeTo], TradeSide.To, tradeType);

  const assetsWithErrorsExist = Boolean(from.length) || Boolean(to.length);

  return (
    <FlexLayout direction='row' className='full-width p-24' spacing={8}>
      <Button
        size='lg'
        className='w-[100%]'
        color='primary'
        onClick={() => setTradeState(state)}
        disabled={disabled || assetsWithErrorsExist}
      >
        {t('buttonLabels.multiContinue')}
      </Button>
    </FlexLayout>
  );
});

TradeOrderDetailsContinue.displayName = 'TradeOrderDetailsContinue';

export { TradeOrderDetailsContinue };

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

import TileProps from './Tile.types';

/** NOTE: Keep this component as generic as possible implement a custom tile if you need */
const Tile: React.FC<TileProps> = observer(
  ({
    id,
    children,
    ChipProps,
    leading,

    separator = true,
    subTitle,
    translationKey,
    title,
    trailChip,
    trailing,
    PII,
    hidden,
  }) => {
    const { t } = useTranslation(translationKey);

    if (hidden) {
      return null;
    }

    const getTitle = () => {
      if (typeof title === 'string') {
        return (
          <Body id={`${id}-title`} weight='emphasis'>
            {t(title as any)}
          </Body>
        );
      }

      return title;
    };

    const getSubTitle = () => {
      if (typeof subTitle === 'string') {
        return (
          <Body id={`${id}-subtitle`} color='secondary'>
            {t(subTitle as any)}
          </Body>
        );
      }

      return subTitle;
    };

    return (
      <FlexLayout className={cn('w-full', PII ? 'fs-mask' : '')} direction='column' spacing={16}>
        <FlexLayout
          className='w-full flex-col md:flex-row'
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={16}
        >
          <FlexLayout direction='row' spacing={16} className='w-full'>
            {/* leading */}
            {leading}

            {/* body */}
            <FlexLayout direction='column' spacing={16} className='w-full'>
              <FlexLayout direction='column' spacing={4} className='w-full'>
                {/* title */}
                <FlexLayout direction='row' spacing={8} alignItems='center' justifyContent='start'>
                  {getTitle()}
                  {ChipProps && !trailChip && <Chip {...ChipProps} />}
                </FlexLayout>

                {/* subtitle */}
                {subTitle && getSubTitle()}
              </FlexLayout>

              {/* content */}
              {children}
            </FlexLayout>
          </FlexLayout>

          {trailing && (
            <FlexLayout
              direction='row'
              spacing={8}
              alignItems='center'
              justifyContent='end'
              className='w-full justify-start md:justify-end'
            >
              {ChipProps && trailChip && <Chip {...ChipProps} />}
              {trailing}
            </FlexLayout>
          )}
        </FlexLayout>

        {separator && <Separator className='w-full' />}
      </FlexLayout>
    );
  },
);

export default Tile;

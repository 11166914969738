import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Menu } from '@swyftx/aviary/molecules/Menu';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api/@types/markets';
import { balanceService, portfolioService, ratesService, userStatisticsService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';
import { formatCurrency, getValuePriceScale } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';
import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

import { AppHeaderBaseCurrencySelectorMenu } from './AppHeaderBaseCurrencySelectorMenu';

const AppHeaderBaseCurrencySelector: React.FC = observer(() => {
  const [loadingStatus, setLoadingStatus] = useState<string>('');
  const { portfolio } = usePortfolio({ balanceKey: 'all' });
  const baseAsset = useBaseAsset();
  const { setUserCurrency } = UserStore.useUserStore;
  const { setLoading, loading } = RatesStore.useRatesStore;
  const portfolioValue = formatCurrency(portfolio.accountValue, baseAsset, {
    priceScale: getValuePriceScale(baseAsset),
  });

  const onSelectCurrency = async (asset: Asset) => {
    const currentCurrency = baseAsset;
    setLoading(true);
    setLoadingStatus('Changing currency...');
    try {
      setUserCurrency(asset.id, asset.code);
      await UserService.UpdateUserCurrency(asset.id);
    } catch (e) {
      if (currentCurrency) setUserCurrency(currentCurrency.id, currentCurrency.code);
    }

    // get latest rates before updating other services
    setLoadingStatus('Getting Rates...');
    await ratesService.forceUpdate();

    setLoadingStatus('Loading balances...');
    await balanceService.forceUpdate();

    setLoadingStatus('Retrieving portfolio......');
    await portfolioService.forceUpdate();
    await userStatisticsService.forceUpdate();
    setLoadingStatus('');
    setLoading(false);
  };

  return (
    <>
      <Menu
        className='w-full max-w-[30rem]'
        align='start'
        triggerContent={
          <Button id='header-base-selector' variant='outlined' size='md' loading={loading} className='px-8 py-6'>
            <FlexLayout direction='row' alignItems='center' spacing={8} className='pr-4'>
              {!loading && <AssetIcon asset={baseAsset?.code} size={20} />}
              {baseAsset !== undefined && <Numeric>{portfolioValue}</Numeric>}
            </FlexLayout>
          </Button>
        }
      >
        <AppHeaderBaseCurrencySelectorMenu
          loadingStatus={loadingStatus}
          onSelectCurrency={onSelectCurrency}
          loading={loading}
        />
      </Menu>
    </>
  );
});

export { AppHeaderBaseCurrencySelector };

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';

import { InverseAssetIcon } from '@global-components/InverseAssetIcon';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  children: JSX.Element;
  color?: string;
  className?: string;
  bgClassName?: string;
};

export const OutlinedAvatarIcon: React.FC<Props> = ({ children, className, bgClassName, color }) => (
  <Avatar
    className={cn(bgClassName, 'flex h-64 w-64 items-center justify-center text-white')}
    style={{
      backgroundColor: bgClassName !== undefined ? '' : color,
    }}
  >
    <InverseAssetIcon color={color} className={cn(className, 'h-56 w-56')} bgClassName={bgClassName}>
      {children}
    </InverseAssetIcon>
  </Avatar>
);

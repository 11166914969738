import React, { useMemo } from 'react';

import { MiniPieChart } from '@global-components/charts/MiniPieChart/MiniPieChart';
import { PieChartData } from '@global-components/charts/PieChart/PieChart.types';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useTheme } from '@hooks/useTheme';
import { AssetColors } from '@utils/assets';

type Props = {
  walletValue: string;
  assetValue: string;
  asset: Asset;
};

const WalletListPieChart: React.FC<Props> = ({ asset, walletValue, assetValue }) => {
  const { theme } = useTheme();

  const percent = useMemo(() => {
    const percentage = Big(assetValue).div(walletValue).times(100);

    if (percentage.lte(0)) {
      return `0%`;
    }

    if (percentage.lte(0.1)) {
      return `<0.1%`;
    }

    return `~${percentage.toFixed(0)}%`;
  }, [assetValue, walletValue]);

  const pieData: PieChartData = useMemo(
    (): PieChartData => [
      {
        color: AssetColors[asset.code] || theme.palette.primary.main,
        name: 'Wallet balances',
        value: Big(assetValue).toNumber(),
        id: asset.id,
      },
      {
        color: window.getComputedStyle(document.body).getPropertyValue('--color-background-surface-active'),
        name: 'Account balance',
        value: Big(walletValue).minus(assetValue).toNumber(),
        id: -1,
      },
    ],
    [theme, asset, assetValue, walletValue],
  );

  return <MiniPieChart pieData={pieData} percentOverlay={percent} />;
};

export { WalletListPieChart };

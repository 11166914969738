import React, { useMemo } from 'react';

import ArrowForward from '@mui/icons-material/ArrowForward';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import { CrossInCircle } from '@swyftx/aviary/icons/outlined';
import { Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { OrderStatus } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

import { useUniversalTradeAsset } from '@hooks/Trade/useUniversalTradeAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  tradeDataKey: string;
  parentOrderStatus: OrderStatus;
};

const TradeOrderDetailsReviewItem: React.FC<Props> = observer(({ tradeDataKey, parentOrderStatus }) => {
  const {
    fromAsset,
    nonLimitAsset,
    toAsset,
    trigger,
    error,
    orderStatus,
    getSpendAmount,
    getReceiveAmount,
    limitAsset,
  } = useUniversalTradeAsset(tradeDataKey);

  const getNonLimitValueColor = (code: string) => {
    if (nonLimitAsset?.code === code) {
      if (orderStatus === OrderStatus.Success && parentOrderStatus === OrderStatus.Success) {
        return 'success.main';
      }
    }

    return 'text.primary';
  };

  const itemStatus: OrderStatus = useMemo(() => {
    if ([undefined, null, OrderStatus.Idle].includes(orderStatus)) {
      return OrderStatus.Idle;
    }

    if (orderStatus === OrderStatus.Pending || parentOrderStatus === OrderStatus.Pending) {
      return OrderStatus.Pending;
    }

    if (orderStatus === OrderStatus.Success && parentOrderStatus === OrderStatus.Success) {
      return OrderStatus.Success;
    }

    if (orderStatus === OrderStatus.Error) {
      return OrderStatus.Error;
    }

    return OrderStatus.Idle;
  }, [orderStatus, parentOrderStatus]);

  const renderTradeItem = (asset: Asset, value: string, direction: 'row' | 'row-reverse') => (
    <Stack direction={direction} paddingLeft={1} paddingRight={1} alignItems='center' spacing={1}>
      <AssetIcon asset={asset} size={20} />
      <Stack direction='column'>
        <Typography
          fontSize={14}
          fontWeight={600}
          marginLeft={0.5}
          marginRight={0.5}
          color={error?.length ? 'error.main' : 'text.primary'}
        >
          {asset.code}
        </Typography>
        <Typography
          color={error?.length ? 'error.main' : getNonLimitValueColor(asset.code)}
          fontWeight={500}
          number
          fontSize={14}
        >
          {nonLimitAsset?.code === asset.code ? '~' : ''}
          {value}
        </Typography>
      </Stack>
    </Stack>
  );

  if (!trigger || !toAsset || !fromAsset || !limitAsset || Big(getSpendAmount()).lte(0)) return null;

  return (
    <>
      <Grid container direction='row' alignItems='center' paddingY={1}>
        <Grid item xs={5} textAlign='start'>
          {renderTradeItem(
            fromAsset,
            formatCurrency(getSpendAmount(), fromAsset, { hideCode: true, appendCode: false }),
            'row',
          )}
        </Grid>

        <Grid item xs={2} textAlign='center' alignItems='start' justifyContent='center' display='flex'>
          {itemStatus === OrderStatus.Idle && (
            <Box position='relative' display='flex' alignItems='center' justifyContent='center'>
              <ArrowForward sx={{ position: 'absolute', zIndex: 10, width: '18px', height: '18px' }} />
              <CircularProgress
                size='small'
                value={0}
                variant='determinate'
                color='inherit'
                sx={{
                  backgroundColor: 'action.hover',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                }}
              />
            </Box>
          )}

          {itemStatus === OrderStatus.Pending && (
            <CircularProgress
              size='small'
              variant='indeterminate'
              color='inherit'
              sx={{ backgroundColor: 'action.hover', width: '24px', height: '24px', borderRadius: '50%' }}
            />
          )}

          {itemStatus === OrderStatus.Success && (
            <CheckCircle sx={{ color: 'success.main', width: '24px', height: '24px' }} />
          )}

          {itemStatus === OrderStatus.Error && <CrossInCircle className='h-24 w-24 text-color-text-error' />}
        </Grid>

        <Grid item xs={5} textAlign='end'>
          {renderTradeItem(
            toAsset,
            formatCurrency(getReceiveAmount(), toAsset, {
              hideCode: true,
              appendCode: false,
            }),
            'row-reverse',
          )}
        </Grid>
        {error && (
          <Grid item xs={12} textAlign='start'>
            <Typography fontSize={14} color='error' marginTop={1}>
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider variant='fullWidth' orientation='horizontal' />
    </>
  );
});

TradeOrderDetailsReviewItem.displayName = 'TradeOrderDetailsReviewItem';

export { TradeOrderDetailsReviewItem };

import React, { useEffect, useRef } from 'react';

import { Box, useTheme } from '@mui/material';
import { darken, lighten } from '@mui/system';

import * as d3 from 'd3';

import { PieChartData, PieChartDatum } from './PieChart.types';

export type PieChartProps = {
  data: PieChartData;
  onSliceClick?: (id: number | null) => void;
  formatTotal?: (total: number) => string;
  selectedInnerRadiusRatio?: number;
  selectedId?: number | null;
  innerRadiusRatio?: number;
  noSort?: boolean;
  height?: number;
  width?: number;
  containerWidth?: number | string;
  disableLoadAnimation?: boolean;
};

const UNSELECTED_COLOR_VARIANCE = 0.65;
const TRANSITION_DURATION = 100; // ms

const DEFAULTS = {
  selectedInnerRadiusRatio: 0.825,
  innerRadiusRatio: 0.875,
  height: 300,
  width: 300,
  containerWidth: '100%',
};

export const PieChart: React.FC<PieChartProps> = ({
  selectedInnerRadiusRatio = DEFAULTS.selectedInnerRadiusRatio,
  innerRadiusRatio = DEFAULTS.innerRadiusRatio,
  containerWidth = DEFAULTS.containerWidth,
  height = DEFAULTS.height,
  width = DEFAULTS.width,
  disableLoadAnimation,
  selectedId,
  noSort,
  data,
  onSliceClick,
  formatTotal,
}: PieChartProps) => {
  const cache = useRef<d3.PieArcDatum<PieChartDatum>[]>();
  const theme = useTheme();
  const outerRadius = height / 2;
  const innerRadius = (height / 2) * innerRadiusRatio;
  const selectedInnerRadius = (height / 2) * selectedInnerRadiusRatio;
  const ref = useRef<SVGSVGElement | null>(null);
  const backgroundRef = useRef<SVGSVGElement | null>(null);
  const total = d3.sum(data, (d) => d.value);
  const isSelecting = selectedId !== undefined;
  const isLightMode = theme.palette.mode === 'light';

  const createArc = d3
    .arc<d3.PieArcDatum<PieChartDatum>>()
    .outerRadius(outerRadius)
    .innerRadius((d) => d.data?.innerRadius || innerRadius); // Allow override of inner radius

  const createPie = d3
    .pie<PieChartDatum>()
    .sort(noSort ? () => 1 : (a, b) => (a.value < b.value ? 1 : -1))
    .value((d) => d.value);

  const getColor = (datum: PieChartDatum) => {
    if ((isSelecting && datum.id === selectedId) || selectedId === null) {
      return datum.color;
    }
    if (isLightMode) {
      return lighten(datum.color, UNSELECTED_COLOR_VARIANCE);
    }
    return darken(datum.color, UNSELECTED_COLOR_VARIANCE);
  };

  // Handles adding in additional fields to tween selected states
  const createRawData = (rawData: PieChartData) =>
    !isSelecting
      ? rawData
      : rawData.map((datum) => ({
          ...datum,
          innerRadius: selectedId === datum.id ? selectedInnerRadius : innerRadius,
          color: getColor(datum),
        }));

  // Create the background
  useEffect(() => {
    const backgroundContainer = d3
      .select(backgroundRef.current)
      .attr('transform', `translate(${width / 2} ${height / 2})`);

    backgroundContainer
      .select('path.background')
      .attr(
        'd',
        createArc({
          startAngle: 0,
          endAngle: Math.PI * 2,
        } as any),
      )
      .attr('fill', 'var(--color-background-surface-active)');
  }, [isLightMode, width, height, createArc]);

  // Setup
  useEffect(() => {
    const currentData = createPie(createRawData(data));
    if (!cache.current) {
      cache.current = currentData;
    }

    const prevData = cache.current;
    const pieContainer = d3.select(ref.current).attr('transform', `translate(${width / 2} ${height / 2})`);
    const pieContainerData = pieContainer.selectAll('g.arc').data(currentData);

    // clean
    pieContainerData.exit().remove();
    const pieContainerUpdate = pieContainerData.enter().append('g').attr('class', 'arc');

    // Create path and merge with existing path
    const path = pieContainerUpdate.append('path').merge(pieContainerData.select('path.arc'));

    // interpolates the data values and returns the arc function
    const arcTween = (d: d3.PieArcDatum<PieChartDatum>, i: number) => {
      const interpolateData = d3.interpolate(prevData[i], d);
      return (t: any) => createArc(interpolateData(t)) || '';
    };

    // interpolates the data values and returns the color
    const colorTween = (d: d3.PieArcDatum<PieChartDatum>, i: number) => {
      const interpolateData = d3.interpolate(prevData[i], d);
      return (t: any) => interpolateData(t).data.color;
    };

    // Stop event propagation and use callback on slice click
    const onClick = (e: any, id: number | null) => {
      if (onSliceClick) {
        e?.stopPropagation();
        onSliceClick(id);
      }
    };

    // Pie chart arcs
    path
      .attr('class', 'arc')
      // Add onclick event/styles
      .on('click', (e, d) => onClick(e, d.data.id))
      .attr('style', onSliceClick ? 'cursor: pointer' : '')
      // Handle tween transition
      .transition()
      .duration(TRANSITION_DURATION)
      .attrTween('d', arcTween)
      .attrTween('fill', colorTween)
      .attr('strokeLinecap', 'rounded');

    cache.current = currentData;
  }, [width, height, data, theme, selectedId]);

  return (
    <Box
      onClick={() => onSliceClick && onSliceClick(null)}
      justifyContent='center'
      alignItems='center'
      display='flex'
      height='100%'
      width={containerWidth}
      sx={!disableLoadAnimation ? { animation: 'rotate 1s ease-in-out' } : {}}
    >
      <svg style={{ width, height }}>
        <g ref={backgroundRef}>
          <path className='background' />
        </g>
        <g ref={ref} />
        {/* TEMP */}
        {formatTotal ? (
          <text x={width / 2} y={width / 2} textAnchor='middle'>
            {formatTotal(total)}
          </text>
        ) : null}
      </svg>
    </Box>
  );
};

import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { useCategories } from 'src/lib/categories/hooks';
import { useMarketActivity } from 'src/lib/markets/hooks/useMarketActivity';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { AssetListTabTypes } from '../AssetList.types';

export const useAssetListAssets = (selectedTab: AssetListTabTypes, categoryId?: string) => {
  const { assets } = useMarkets();
  const favouriteAssets = useFavouriteAssets();
  const { gainers, losers, newListings, topAssets, ownedAssets } = useMarketActivity({
    assets,
  });
  const { categories } = useCategories();

  const getAssetsFromCategory: (id: string) => Asset[] = useCallback(
    (id: string) => {
      const categoryAssetIds = categories.find((category) => category.id.toString() === id)?.assetIds ?? [];

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return categoryAssetIds.map((assetId) => assetService.getAsset(assetId)!);
    },
    [categories],
  );

  const tableAssets = useMemo(() => {
    if (categoryId) {
      return getAssetsFromCategory(categoryId);
    }
    switch (selectedTab) {
      case 'all':
        return topAssets;
      case 'favourites':
        return favouriteAssets;
      case 'gainers':
        return gainers;
      case 'losers':
        return losers;
      case 'new':
        return newListings;
      case 'owned':
        return ownedAssets;
      default:
        return [];
    }
  }, [
    categoryId,
    favouriteAssets,
    gainers,
    getAssetsFromCategory,
    losers,
    newListings,
    ownedAssets,
    selectedTab,
    topAssets,
  ]);

  return { tableAssets };
};

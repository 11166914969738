import { useMemo } from 'react';

import { Big } from '@shared/safe-big';
import { TradeSide, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';
import { useRates } from 'src/lib/rates/hooks';

export const useTradeInputSwapErrors = (assetIds: number[], tradeSide: TradeSide, tradeType: TradeType) => {
  const { getBalance } = usePortfolioBalance();
  const { getAssetsById } = useMarkets();
  const { getMinimumOrderAmount } = useRates();

  const errorAssets = useMemo(() => {
    const assets = getAssetsById(assetIds);

    if (tradeType === TradeType.OnTrigger) {
      return assets?.filter((asset) => asset.buyDisabled) || [];
    }

    return (
      assets?.filter((asset) => {
        const balance = getBalance(asset.id);

        if (tradeSide === TradeSide.From) {
          return Big(balance?.availableBalance).lt(getMinimumOrderAmount(asset)) || !asset.sellEnabled;
        }

        return asset.buyDisabled;
      }) || []
    );
  }, [assetIds, getAssetsById, getBalance, getMinimumOrderAmount, tradeSide, tradeType]);

  return {
    errorAssets,
  };
};

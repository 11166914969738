import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AddCircle from '@mui/icons-material/AddCircle';
import LockIcon from '@mui/icons-material/Lock';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box } from '@mui/material';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Button, Chip, Stack, Typography } from '@swyftx/react-web-design-system';

import { UniversalTradeStore } from '@shared/store';
import {
  TradeAssetAction,
  TradeSide,
  TradeState,
  TradeType,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { cn } from '@shared/utils/lib/ui';

import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';
import { useTradeInputSwapErrors } from 'src/lib/trade/hooks/General/useTradeInputSwapErrors';

import { TradeOrderDetailsAssetContainerItem } from './TradeOrderDetailsAssetContainerItem';

type TradeDetailsAssetsContainerProps = {
  title: string;
  side: TradeSide;
};

const TradeOrderDetailsAssetsContainer: React.FC<TradeDetailsAssetsContainerProps> = observer(({ title, side }) => {
  const { getMaxAssets } = useUniversalTradeUtilityStore();
  const { tradeType, setTradeCache, setTradeState, setShowGlobalTrade } = UniversalTradeStore;
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });

  const assets = useMemo(() => UniversalTradeStore[side], [UniversalTradeStore[side]]);
  const bankAccountTransferOnly = useMemo(
    () => tradeType === TradeType.Recurring && side === TradeSide.From,
    [tradeType],
  );

  const { errorAssets } = useTradeInputSwapErrors([...assets], side, tradeType);

  const maxAssets = getMaxAssets(side);

  const onAddAsset = () => {
    // Global trade may not be opened due to Universal Trade Page
    // This ensures if they start a trade in the trade panel and
    // navigate away, the panel will stay open
    setShowGlobalTrade(true);
    const newState = side === TradeSide.To ? TradeState.SelectToAsset : TradeState.SelectFromAssets;
    setTradeCache([...UniversalTradeStore[side]], side, TradeAssetAction.Replace);
    setTradeState(newState);
  };

  return (
    <Stack direction='column' width='100%' spacing={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography fontSize={14} fontWeight={500}>
          {title}
        </Typography>
        {!bankAccountTransferOnly && (
          <Typography fontSize={14} fontWeight={400} number color='text.secondary'>
            {t('labels.tradeLimit', { selected: assets.length, max: maxAssets })}
          </Typography>
        )}
      </Stack>
      <FlexLayout
        direction='row'
        spacing={8}
        className={cn(
          '!m-0 flex-wrap rounded-[0.5rem] border p-8',
          errorAssets.length > 0 ? 'border-color-border-error' : '',
        )}
      >
        {bankAccountTransferOnly ? (
          <Box key='bank-transfer-only'>
            <Chip
              label={t('labels.transferBankAccount')}
              onDelete={() => {}}
              deleteIcon={<LockIcon />}
              avatar={<MonetizationOnIcon />}
              sx={{
                '.MuiChip-avatar': {
                  color: 'primary.main',
                },

                pointerEvents: 'none',
                fontWeight: 600,
                borderRadius: 1,
                height: '40.5px', // match height of other tiles
              }}
            />
          </Box>
        ) : (
          <>
            {assets.map((asset) => (
              <TradeOrderDetailsAssetContainerItem
                key={asset}
                assetId={asset}
                side={side}
                assetInErrorState={errorAssets.findIndex((e) => e.id === asset) > -1}
              />
            ))}

            {assets.length < maxAssets && (
              <Button
                onClick={onAddAsset}
                startIcon={<AddCircle sx={{ marginRight: assets.length > 0 ? 0 : 1 }} />}
                variant='text'
                icon={assets.length > 0}
                sx={{ backgroundColor: 'action.hover', padding: 1 }}
                color='primary'
              >
                {assets.length === 0 ? t('buttonLabels.addAsset') : ''}
              </Button>
            )}
          </>
        )}
      </FlexLayout>
      {errorAssets.length > 0 && (
        <Body size='small' color='error'>
          {t('invalidAssets.invalidAssetsSelected')}{' '}
          {(() => {
            const names = errorAssets.map((asset) => asset.name);
            if (names.length > 1) {
              return (
                <>
                  <span className='font-bold'>{names.slice(0, -1).join(', ')}</span> {t('invalidAssets.and')}{' '}
                  <span className='font-bold'>{names[names.length - 1]}</span>
                </>
              );
            }
            return <span className='font-bold'>{names.join('')}</span>;
          })()}{' '}
          {t('invalidAssets.toContinue')}
        </Body>
      )}
    </Stack>
  );
});

TradeOrderDetailsAssetsContainer.displayName = 'TradeOrderDetailsAssetsContainer';

export { TradeOrderDetailsAssetsContainer };

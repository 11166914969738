import logger from '@shared/logger';

import { compile } from 'path-to-regexp';

const compilePath = (path: string) => {
  const generator = compile(path);
  return generator;
};

/**
 * Transform parameters into path
 * @param {string} path Relative endpoint path
 * @param {object} params key-value pair parameters to transform
 * @returns Formatted path
 * @example
 * // return /users/:uuid
 * generatePath('/users/:uuid/', {uuid: '123e4567-e89b-12d3-a456-426614174000'})
 */

const generatePath = (path = '/', params: Record<string, any> = {}) => {
  try {
    // compile only accepts string as values, refer to https://github.com/pillarjs/path-to-regexp/releases/tag/v7.0.0
    const stringParams: Partial<Record<string, string | string[]>> = Object.keys(params).reduce((acc, key) => {
      const value = params[key];

      if (value === undefined || value === null) return acc;

      acc[key] = String(value);

      return acc;
    }, {} as Partial<Record<string, string | string[]>>);

    return path === '/' ? path : compilePath(path)(stringParams);
  } catch (error) {
    logger.error('[REST]', 'Error in generatePath', error);
    return path;
  }
};

export default generatePath;

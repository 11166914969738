export default class GenericError extends Error {
  data: any;

  isGenericError = true;

  constructor(data: any) {
    super();
    this.data = data;
  }

  static isGenericError(error?: any) {
    return (error as GenericError)?.isGenericError;
  }
}

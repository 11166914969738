import { Box, styled } from '@mui/material';

import { Stack } from '@swyftx/react-web-design-system';

type AssetSummarySliderItemsContainerProps = {
  slideTransition: number;
  activeIndex: number;
  itemWidth: number;
};

export const AssetSummaryItemsSliderContainer = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'slideTransition' && propName !== 'backgroundColor',
})<AssetSummarySliderItemsContainerProps>(({ activeIndex, itemWidth, slideTransition }) => ({
  transform: `translateX(-${activeIndex * itemWidth}px)`,
  transition: `${slideTransition}ms`,
  whiteSpace: 'nowrap',
  alignItems: 'center',
  userSelect: 'none',
  display: 'flex',
  height: '100%',
}));

type AssetSummaryIconsContainerProps = {
  slideTransition: number;
  backgroundColor?: string;
};

export const AssetSummaryIconsContainer = styled(Stack, {
  shouldForwardProp: (propName) => propName !== 'slideTransition',
})<AssetSummaryIconsContainerProps>(({ theme, slideTransition, backgroundColor }) => ({
  backgroundColor,
  transition: `${slideTransition}ms`,
  zIndex: theme.zIndex.drawer,
  alignItems: 'center',
  position: 'absolute',
  height: '100%',
  opacity: 0.9,
}));

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Calendar: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M6.80859 13.3096C6.80859 12.8954 7.14438 12.5596 7.55859 12.5596H7.56786C7.98207 12.5596 8.31786 12.8954 8.31786 13.3096C8.31786 13.7238 7.98207 14.0596 7.56786 14.0596H7.55859C7.14438 14.0596 6.80859 13.7238 6.80859 13.3096Z'
      fill='currentColor'
    />
    <path
      d='M12.0053 12.5596C11.5911 12.5596 11.2553 12.8954 11.2553 13.3096C11.2553 13.7238 11.5911 14.0596 12.0053 14.0596H12.0146C12.4288 14.0596 12.7646 13.7238 12.7646 13.3096C12.7646 12.8954 12.4288 12.5596 12.0146 12.5596H12.0053Z'
      fill='currentColor'
    />
    <path
      d='M16.4428 12.5596C16.0286 12.5596 15.6928 12.8954 15.6928 13.3096C15.6928 13.7238 16.0286 14.0596 16.4428 14.0596H16.452C16.8663 14.0596 17.202 13.7238 17.202 13.3096C17.202 12.8954 16.8663 12.5596 16.452 12.5596H16.4428Z'
      fill='currentColor'
    />
    <path
      d='M16.4428 16.446C16.0286 16.446 15.6928 16.7818 15.6928 17.196C15.6928 17.6103 16.0286 17.946 16.4428 17.946H16.452C16.8663 17.946 17.202 17.6103 17.202 17.196C17.202 16.7818 16.8663 16.446 16.452 16.446H16.4428Z'
      fill='currentColor'
    />
    <path
      d='M11.2553 17.196C11.2553 16.7818 11.5911 16.446 12.0053 16.446H12.0146C12.4288 16.446 12.7646 16.7818 12.7646 17.196C12.7646 17.6103 12.4288 17.946 12.0146 17.946H12.0053C11.5911 17.946 11.2553 17.6103 11.2553 17.196Z'
      fill='currentColor'
    />
    <path
      d='M7.55859 16.446C7.14438 16.446 6.80859 16.7818 6.80859 17.196C6.80859 17.6103 7.14438 17.946 7.55859 17.946H7.56786C7.98207 17.946 8.31786 17.6103 8.31786 17.196C8.31786 16.7818 7.98207 16.446 7.56786 16.446H7.55859Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.793 2C16.793 1.58579 16.4572 1.25 16.043 1.25C15.6288 1.25 15.293 1.58579 15.293 2V2.8291H8.71484V2C8.71484 1.58579 8.37906 1.25 7.96484 1.25C7.55063 1.25 7.21484 1.58579 7.21484 2V2.84773C5.84454 2.9413 4.65151 3.38902 3.76749 4.22937C2.76004 5.18705 2.25 6.5633 2.25 8.22213V17.2718C2.25 18.9507 2.75774 20.3462 3.76225 21.3198C4.76302 22.2898 6.16301 22.7499 7.77096 22.7499H16.229C17.8377 22.7499 19.2366 22.2991 20.2371 21.3438C21.243 20.3834 21.75 19.0051 21.75 17.3474V8.22317C21.7548 6.56549 21.2504 5.18867 20.2448 4.23009C19.3617 3.38825 18.1672 2.94066 16.793 2.84754V2ZM15.293 4.3291V5.29078C15.293 5.70499 15.6288 6.04078 16.043 6.04078C16.4572 6.04078 16.793 5.70499 16.793 5.29078V4.35162C17.853 4.44046 18.6559 4.78781 19.2098 5.31582C19.8507 5.92673 20.2541 6.87097 20.25 8.21982L20.25 8.22213V8.6543H3.75V8.22213C3.75 6.87387 4.1571 5.9286 4.80096 5.31654C5.35677 4.78818 6.15959 4.44113 7.21484 4.35189V5.29078C7.21484 5.70499 7.55063 6.04078 7.96484 6.04078C8.37906 6.04078 8.71484 5.70499 8.71484 5.29078V4.3291H15.293ZM3.75 10.1543V17.2718C3.75 18.6472 4.1594 19.6158 4.8062 20.2427C5.45673 20.8732 6.44222 21.2499 7.77096 21.2499H16.229C17.5663 21.2499 18.5528 20.878 19.2013 20.2589C19.8444 19.6448 20.25 18.6968 20.25 17.3474V10.1543H3.75Z'
      fill='currentColor'
    />
  </svg>
);

export { Calendar };

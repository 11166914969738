import { useCallback, useMemo } from 'react';

import * as d3 from 'd3';

import { LineChartData, LineChartDatum } from '../LineChart.types';

type Props = {
  idKey: string;
  container: d3.Selection<SVGSVGElement | null, unknown, null, undefined>;
  chartData: LineChartData;
  width: number;
  height: number;
  valueLine: d3.Line<LineChartDatum>;
  strokeWidth: number;
  areaColor?: 'primary' | 'success' | 'destructive' | 'neutral' | string;
  color: string;
  loading?: boolean;
  xPos: number;
};

const useBuildGradientAndLine = ({
  container,
  chartData,
  width,
  height,
  idKey,
  valueLine,
  strokeWidth,
  loading,
  areaColor,
  color,
  xPos,
}: Props) => {
  const gradientColor = useMemo(() => {
    if (loading || areaColor === 'neutral') return 'var(--color-border-main)';

    switch (areaColor) {
      case 'destructive':
        return 'var(--color-background-error)';
      case 'success':
        return 'var(--color-background-success)';
      case 'primary':
        return 'var(--color-background-info)';
      default:
        return areaColor || 'var(--color-background-info)';
    }
  }, [loading, areaColor]);

  const buildGradientAndLine = useCallback(() => {
    const graphArea = container.select('.line-graph-area');

    const lineGradient = d3.select(`#line-gradient-${idKey}`);

    let x = width;

    if (xPos > -1) {
      x = xPos;
    } else {
      x = width;
    }

    lineGradient
      .select('.top-stop')
      .attr('stop-color', 'var(--color-border-main)')
      .attr('offset', 50)
      .attr('stop-opacity', '100%');

    lineGradient
      .select('.bottom-stop')
      .attr('stop-color', gradientColor)
      .attr('offset', '100%')
      .attr('stop-opacity', '100%');

    // Gradient stops
    const bgGradient = d3.select(`#bg-gradient-${idKey}`).attr('gradientTransform', 'rotate(90)');

    bgGradient.select('.top-stop').attr('stop-color', gradientColor).attr('offset', '0%').attr('stop-opacity', '12%');
    bgGradient
      .select('.bottom-stop')
      .attr('stop-color', gradientColor)
      .attr('offset', '100%')
      .attr('stop-opacity', '0%');

    // Add the line from our line data
    container
      .select('.line-path')
      .datum(chartData)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', strokeWidth)
      .attr('d', valueLine(chartData));

    // Clip the area to our data so the gradient shows only under
    const gradientClipPath = graphArea.select('.gradient-clip').attr('clip-path', `url(#clip-line-path-${idKey})`);

    gradientClipPath
      .select('.area-rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', x || width)
      .attr('height', height)
      .style('fill', `url(#bg-gradient-${idKey})`);
  }, [chartData, color, container, gradientColor, height, idKey, strokeWidth, valueLine, width, xPos]);

  return {
    buildGradientAndLine,
    color,
  };
};

export { useBuildGradientAndLine };

import React from 'react';

import { VariantProps, cva } from 'cva';
import { z } from 'zod';

export type TextAreaProps = {
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  schema?: z.ZodType<any, any>;
  onValidityChange?: (isValid: boolean) => void;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  VariantProps<typeof textAreaVariants> &
  VariantProps<typeof textAreaContainerVariants>;

export const textAreaContainerVariants = cva(
  'rounded-[8px] p-12  bg-color-background-surface-secondary focus-within:!bg-color-background-surface-secondary hover:bg-color-background-neutral-hover',
  {
    variants: {
      disabled: {
        true: 'cursor-not-allowed opacity-50 hover:!bg-color-background-surface-secondary',
        false: '',
      },
      error: {
        true: 'focus-area-error border border-color-border-error text-color-text-error',
        false: 'focus-within:focus-area-info focus-within:border-color-border-info',
      },
    },
    defaultVariants: {
      disabled: false,
      error: false,
    },
  },
);

export const textAreaVariants = cva(
  'w-full text-sm file:text-10 file:bg-color-background-surface-primary file:cursor-pointer file:text-color-text-primary file:border-solid file:border-color-border-main file:rounded-[8px] bg-transparent focus-visible:outline-none text-color-text-primary border-none',
  {
    variants: {
      variant: {
        default: 'font-sans',
        monospace: 'font-mono',
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: '',
      },
      error: {
        true: 'caret-[var(--color-text-error)]',
        false: 'caret-[var(--color-text-info)]',
      },
    },
    defaultVariants: {
      variant: 'default',
      error: false,
    },
  },
);

import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { UniversalTradePage } from 'src/lib/UniversalTrade/UniversalTradePage';
import { NavigationURLs } from 'src/lib/navigation/types';

const DEFAULT_TRADE_ASSET_CODE = 'BTC';

export const UniversalTradePageRoutes: RouteObject[] = [
  {
    path: NavigationURLs.UniversalTradeAsset,
    element: <PrivateRoute element={<UniversalTradePage />} />,
  },
  {
    path: NavigationURLs.UniversalTrade,
    element: <Navigate to={`${NavigationURLs.UniversalTrade}/${DEFAULT_TRADE_ASSET_CODE}`} />,
  },
];

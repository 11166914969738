import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Delete } from '@swyftx/aviary/icons/outlined';

import { APIKey } from '@shared/api';

import { ApiKeyDetailsModal } from './modals/ApiKeyDetailsModal';
import { RevokeApiKeyModal } from './modals/RevokeApiKeyModal';

type Props = {
  apiKey: APIKey;
};

const MoreOptions: React.FC<Props> = ({ apiKey }) => {
  const { t } = useTranslation('profile.apiKey');

  return (
    <>
      <FlexLayout direction='row' className='w-[100%] justify-end' spacing={8}>
        <ApiKeyDetailsModal apiKey={apiKey} />
        <RevokeApiKeyModal
          apiKey={apiKey}
          triggerContent={
            <Button variant='outlined' leadingIcon={<Delete />} size='sm'>
              {t('sections.activeKeys.table.moreOptions.remove')}
            </Button>
          }
        />
      </FlexLayout>
    </>
  );
};

export { MoreOptions };

import { useCallback, useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset, SortKey, SortDirection, TransactionType, AssetHistoryItemStatus, AssetHistoryItem } from '@shared/api';

import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';

import { useTransactionDetails } from './useTransactionDetails';
import { useTransactions } from './useTransactions';
import { TransactionsDate, TransactionsAmount, TransactionsOrderTypeDate, TransactionsActions } from '../components';
import { TransactionsTableData, TransactionsType } from '../types/Transactions.types';

type Props = {
  asset?: Asset;
  deps?: any[];
  transactionsType: TransactionsType;
  types?: TransactionType[];
  statuses?: AssetHistoryItemStatus[] | undefined;
  widgetId?: DashboardWidgets;
  userId?: string;
};

type TransactionsHeaderData = { [key in keyof TransactionsTableData]: EnhancedTableHeaderData };

const useTransactionsTable = ({ asset, types, statuses, widgetId, transactionsType, userId }: Props) => {
  const [sort, setSort] = useDashboardSyncState<EnhancedTableSort<TransactionsTableData>>({
    widgetId,
    stateKey: 'sort',
    defaultValue: { sortKey: 'date', sortDirection: 'DESC' },
  });

  const { transactions } = useTransactions({
    asset,
    sortKey: sort.sortKey as SortKey,
    sortDirection: sort.sortDirection as SortDirection,
    statuses,
    types,
    userId,
  });

  const { getTransactionDetails } = useTransactionDetails();

  const headers: TransactionsHeaderData = useMemo(
    () => ({
      orderType: {
        title: 'Order Type',
        alignment: 'start',
        sortable: false,
        frozen: true,
        className: 'hidden @md:table-cell',
        insetLeft: true,
        showFrozenDivider: true,
      },
      date: {
        title: 'Date',
        alignment: 'start',
        sortable: true,
        className: 'hidden @md:table-cell',
      },

      orderTypeDate: {
        title: 'Order Type',
        alignment: 'start',
        sortable: true,
        insetLeft: true,
        className: 'hidden @sm:table-cell @md:hidden',
      },

      amount: {
        title: 'Amount',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell',
      },

      triggerPrice: {
        title: 'Price',
        alignment: 'end',
        sortable: false,
        className: 'hidden @sm:table-cell',
      },

      total: {
        title: 'Total',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell',
      },

      status: {
        title: 'Status',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell',
      },

      actions: {
        title: 'Actions',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell',
        insetRight: true,
      },

      valueAmount: {
        title: 'Value',
        alignment: 'end',
        sortable: true,
        insetRight: true,
        className: 'hidden @sm:table-cell @md:hidden',
      },

      orderTypeTitles: {
        title: 'Order Type',
        alignment: 'start',
        sortable: false,
        insetLeft: true,
        className: 'table-cell @sm:hidden',
      },
      orderTypeInfo: {
        title: 'Details',
        alignment: 'end',
        sortable: false,
        insetLeft: true,
        className: 'table-cell @sm:hidden',
      },
    }),
    [],
  );

  const data: EnhancedTableData<TransactionsTableData, AssetHistoryItem>[] = useMemo(() => {
    if (!transactions) return [];
    return transactions?.map((transaction: AssetHistoryItem) => {
      const details = getTransactionDetails(transaction);

      return {
        value: transaction,
        orderType: {
          value: transaction.orderType,
          element: (
            <FlexLayout justifyContent='start' alignItems='center' spacing={8} className='w-[200px]'>
              {details.orderType.icon}
              <Body size='small' overflow='truncate'>
                {details.orderType.title}
              </Body>
            </FlexLayout>
          ),
        },
        date: {
          value: transaction.date,
          element: <TransactionsDate className='w-[200px]' date={details.date} />,
        },

        orderTypeDate: {
          value: transaction.date,
          element: (
            <TransactionsOrderTypeDate
              icon={details.orderType.icon}
              title={details.orderType.title}
              date={details.date}
            />
          ),
        },

        amount: {
          value: details.amount,
          element: (
            <TransactionsAmount className='min-w-[200px]' amount={details.amount} assetCode={details.amountAssetCode} />
          ),
        },

        triggerPrice: {
          value: details.triggerPrice,
          element: (
            <FlexLayout justifyContent='end' className='min-w-[200px]'>
              <Numeric size='small' className='truncate'>
                {details.triggerPrice}
              </Numeric>
            </FlexLayout>
          ),
        },

        total: {
          value: transaction.orderType,
          element: (
            <FlexLayout justifyContent='end' className='min-w-[200px]'>
              <Numeric size='small'>{details.total}</Numeric>
            </FlexLayout>
          ),
        },

        status: {
          value: transaction.statusRaw,
          element: (
            <FlexLayout justifyContent='end'>
              <Chip size='sm' variant='subtle' color={details.status.color}>
                {details.status.status}
              </Chip>
            </FlexLayout>
          ),
        },

        actions: {
          element: <TransactionsActions transaction={transaction} />,
        },

        valueAmount: {
          element: (
            <FlexLayout justifyContent='end' spacing={8} alignItems='center'>
              <FlexLayout direction='column' alignItems='end'>
                <Numeric size='small'>{details.total}</Numeric>
                <Numeric size='small' className='truncate'>
                  {details.amount}
                </Numeric>
              </FlexLayout>
              <AssetIcon asset={details.amountAssetCode} size={16} />
            </FlexLayout>
          ),
        },

        orderTypeTitles: {
          element: (
            <FlexLayout spacing={8} justifyContent='start'>
              <div>{details.orderType.icon}</div>
              <FlexLayout direction='column' justifyContent='start' alignItems='start'>
                <Body size='small' overflow='truncate'>
                  {details.orderType.title}
                </Body>
                <Body size='small' overflow='truncate'>
                  Amount
                </Body>
                <Body size='small' overflow='truncate'>
                  {transactionsType === 'open' ? 'Trigger price' : 'Price'}
                </Body>
                <Body size='small' overflow='truncate'>
                  Total
                </Body>
              </FlexLayout>
            </FlexLayout>
          ),
        },
        orderTypeInfo: {
          element: (
            <FlexLayout spacing={8} justifyContent='end'>
              <FlexLayout direction='column' justifyContent='start' alignItems='end'>
                <Numeric size='small' color='secondary'>
                  {details.date}
                </Numeric>
                <FlexLayout justifyContent='end' spacing={8} alignItems='center'>
                  <AssetIcon asset={details.amountAssetCode} size={16} />
                  <Numeric size='small'>{details.amount}</Numeric>
                </FlexLayout>
                <Numeric size='small'>{details.triggerPrice}</Numeric>
                <Numeric size='small'>{details.total}</Numeric>
              </FlexLayout>
            </FlexLayout>
          ),
        },
      };
    });
  }, [transactions, getTransactionDetails, transactionsType]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<TransactionsTableData>) => {
      if (!newSort) return;

      setSort(newSort);
    },
    [setSort],
  );

  return {
    initialSort: sort,
    headers,
    data,
    onSort,
  };
};

export { useTransactionsTable };

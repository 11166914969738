import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { useBundles } from '@hooks/Bundles/useBundles.hooks';

import { BundleListItem } from '@Bundles/components/BundlesList/components';

const BundlesList: React.FC = () => {
  const { bundles } = useBundles();

  return (
    <FlexLayout direction='column' spacing={16}>
      {bundles.map((bundle) => (
        <BundleListItem key={bundle.id} bundle={bundle} />
      ))}
    </FlexLayout>
  );
};

export { BundlesList };

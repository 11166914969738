import { HTMLAttributes } from 'react';

import { VariantProps, cva } from 'cva';

export type HeadingProps = Omit<
  HTMLAttributes<HTMLParagraphElement> & VariantProps<typeof headingVariants>,
  'style' | 'size'
> & { size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' };

export const headingVariants = cva('', {
  variants: {
    color: {
      primary: 'text-color-text-primary',
      secondary: 'text-color-text-secondary',
      white: 'text-white',
      inverse: 'text-color-text-inverse',
      accent: 'text-color-text-accent',
    },
    weight: {
      normal: 'font-700',
      light: 'font-400',
    },
    size: {
      h1: 'font-sans text-48 line-height-64 letter-spacing-base',
      h2: 'font-sans text-32 line-height-40 letter-spacing-base',
      h3: 'font-sans text-24 line-height-32 letter-spacing-base',
      h4: 'font-sans text-18 line-height-24 letter-spacing-base',
      h5: 'font-sans text-16 line-height-20 letter-spacing-base',
      h6: 'font-sans text-14 line-height-20 letter-spacing-base',
    },
  },
  defaultVariants: {
    size: 'h3',
    color: 'primary',
    weight: 'normal',
  },
});

import React from 'react';
import { RouteObject } from 'react-router-dom';

import PublicRoute from '@routes/PublicRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { Messages } from './Messages';

export const MessagesRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Message,
    element: <PublicRoute ignoreAuth element={<Messages />} />,
  },
];

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Dust: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.3956 3.06832C19.7188 3.32747 19.7706 3.7995 19.5115 4.12263L16.4005 8.00186L17.0113 8.49177C17.7653 9.09644 17.8864 10.1978 17.2817 10.9518L16.7598 11.6026C15.2796 13.4483 14.4835 15.7493 14.5065 18.1151L14.5237 19.8857C14.5325 20.7863 13.6149 21.4003 12.7858 21.0487L11.6566 20.5699C8.47038 19.2188 5.72026 17.0122 3.7108 14.1945L3.00739 13.2081C2.48328 12.4732 2.88709 11.4421 3.77089 11.2585L5.49761 10.8998C7.8292 10.4155 9.91942 9.13451 11.4093 7.27677L11.9142 6.64722C12.5188 5.89324 13.6202 5.7722 14.3742 6.37687L15.2303 7.06341L18.3413 3.18418C18.6005 2.86105 19.0725 2.80917 19.3956 3.06832ZM13.4358 7.54705C13.3281 7.46067 13.1707 7.47796 13.0843 7.58567L12.5794 8.21522C12.4449 8.38298 12.3061 8.54664 12.1632 8.70607L15.2157 11.1563C15.3358 10.9893 15.4604 10.8252 15.5896 10.6641L16.1115 10.0134C16.1979 9.90567 16.1806 9.74832 16.0729 9.66194L13.4358 7.54705ZM14.4147 12.4368L11.0848 9.76389C9.58257 11.0588 7.76737 11.9604 5.80267 12.3685L4.45115 12.6492L4.93205 13.3235C5.08882 13.5434 5.25047 13.7591 5.41686 13.9707L5.87764 13.931C7.01302 13.8331 8.10206 13.4355 9.03339 12.7788L9.56775 12.402C9.82276 12.2221 10.1753 12.2831 10.3551 12.5381C10.5349 12.7931 10.474 13.1456 10.2189 13.3255L9.68459 13.7023C8.68235 14.409 7.52475 14.8605 6.31222 15.0201C6.61897 15.3518 6.93831 15.6709 7.26943 15.9769C8.08162 15.7759 8.84515 15.4088 9.51101 14.8969L9.54968 14.8672C9.79706 14.677 10.1518 14.7234 10.342 14.9708C10.5322 15.2181 10.4858 15.5729 10.2384 15.763L10.1997 15.7928C9.61717 16.2406 8.97158 16.5951 8.28628 16.8459C9.48882 17.7955 10.8182 18.5851 12.2422 19.1889L13.0201 19.5188L13.0066 18.1297C12.9871 16.1321 13.4771 14.1748 14.4147 12.4368Z'
      fill='currentColor'
    />
    <path
      d='M17.7227 15.06C17.7227 14.991 17.6667 14.935 17.5977 14.935C17.5286 14.935 17.4727 14.991 17.4727 15.06C17.4727 16.3717 16.4093 17.435 15.0977 17.435C15.0286 17.435 14.9727 17.491 14.9727 17.56C14.9727 17.6291 15.0286 17.685 15.0977 17.685C16.4093 17.685 17.4727 18.7484 17.4727 20.06C17.4727 20.1291 17.5286 20.185 17.5977 20.185C17.6667 20.185 17.7227 20.1291 17.7227 20.06C17.7227 18.7484 18.786 17.685 20.0977 17.685C20.1667 17.685 20.2227 17.6291 20.2227 17.56C20.2227 17.491 20.1667 17.435 20.0977 17.435C18.786 17.435 17.7227 16.3717 17.7227 15.06Z'
      fill='currentColor'
    />
    <path
      d='M19.623 12C19.623 11.9309 19.5671 11.875 19.498 11.875C19.429 11.875 19.373 11.9309 19.373 12C19.373 12.7594 18.7574 13.375 17.998 13.375C17.929 13.375 17.873 13.4309 17.873 13.5C17.873 13.569 17.929 13.625 17.998 13.625C18.7574 13.625 19.373 14.2406 19.373 15C19.373 15.069 19.429 15.125 19.498 15.125C19.5671 15.125 19.623 15.069 19.623 15C19.623 14.2406 20.2387 13.625 20.998 13.625C21.0671 13.625 21.123 13.569 21.123 13.5C21.123 13.4309 21.0671 13.375 20.998 13.375C20.2387 13.375 19.623 12.7594 19.623 12Z'
      fill='currentColor'
    />
  </svg>
);

export { Dust };

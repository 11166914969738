import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { DangerFilled } from '@swyftx/aviary/icons/filled';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

export type Props = {
  onClick: () => void;
  onClose: () => void;
};

const CreateApiKeyWarningStep: React.FC<Props> = ({ onClick, onClose }) => {
  const { t } = useTranslation('profile.apiKey');

  return (
    <Modal
      id='create-api-key-warning-step'
      open
      onClose={onClose}
      sx={{ width: '400px' }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' justifyContent='center' spacing={2}>
            <Button onClick={onClose} variant='text' sx={{ width: '50%' }}>
              <Typography color='text.secondary' fontSize={16} fontWeight='500'>
                {t('sections.createApiKey.warning.cancel')}
              </Typography>
            </Button>
            <Button onClick={onClick} variant='contained' sx={{ width: '50%', fontSize: 16, fontWeight: '500' }}>
              {t('sections.createApiKey.warning.understand')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={2} alignItems='center' textAlign='center'>
        <Box width='70px' height='70px'>
          <DangerFilled id='create-api-key-warning-step-icon' className='h-full w-full text-color-text-warning' />
        </Box>

        <Typography fontSize={20} fontWeight='700'>
          {t('sections.createApiKey.warning.title')}
        </Typography>

        <Typography color='text.secondary' fontSize={16} fontWeight='400'>
          {t('sections.createApiKey.warning.subtitle')}
        </Typography>
      </Stack>
    </Modal>
  );
};

export { CreateApiKeyWarningStep };

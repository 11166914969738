import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { ProfitLossValueMethod } from '../../types/profitStopLoss.types';

const stopLossModalTour = (
  profitLossValueMethod?: ProfitLossValueMethod,
  setProfitLossValueMethod?: React.Dispatch<React.SetStateAction<ProfitLossValueMethod>>,
): TourGuideData => ({
  name: 'Take Profit / Stop Loss Modal Tour',
  description: "Guides you through the 'Take Profit / Stop Loss' process from a market buy trade",
  steps: [
    {
      title: 'Set a take profit order',
      description:
        'Select this order type to help ensure your target profit is realised. An instant order will be automatically filled if the price rises to your set conditions.',
      spotlightElementId: 'btn-take-profit',
      alignment: 'left',
      customSpotlightStyles: 'border-radius: 12px',
    },
    {
      title: 'Set a stop loss order',
      description:
        'Select this order type to help minimise your potential losses. An instant order will be automatically filled if the price falls to your set conditions.',
      spotlightElementId: 'btn-stop-loss',
      alignment: 'left',
      customSpotlightStyles: 'border-radius: 12px',
      onNext: () => setProfitLossValueMethod && setProfitLossValueMethod(ProfitLossValueMethod.AmountTarget),
    },
    {
      title: 'Define your desired profit & loss targets',
      description: 'Specify either a dollar amount ($) or percentage (%) to set when your order will be triggered.',
      spotlightElementId: 'profit-loss-container',
      onNext: () => setProfitLossValueMethod && setProfitLossValueMethod(ProfitLossValueMethod.TriggerPrice),
      alignment: 'left',
      customSpotlightStyles: 'border-radius: 12px',
    },
    {
      title: 'Set a specific price to trigger your order',
      description: 'If you’d prefer, you can also set a price target to trigger your order.',
      spotlightElementId: 'profit-loss-container',
      onNext: () =>
        setProfitLossValueMethod &&
        setProfitLossValueMethod(profitLossValueMethod || ProfitLossValueMethod.AmountTarget),
      alignment: 'left',
      customSpotlightStyles: 'border-radius: 12px',
    },
    {
      title: 'Review your estimated profit / loss before final confirmation',
      description: 'Visualise your estimated profit and loss amounts.',
      spotlightElementId: 'profit-stop-loss-summary',
      alignment: 'left',
      customSpotlightStyles: 'border-radius: 12px',
    },
  ],
});

export { stopLossModalTour };

import { ShopProductColorEnum, ShopProductEnum } from '@shared/enums';

const referrerCode = '3838a8425f19';

const productColorValues: { [color in ShopProductColorEnum]: string } = {
  [ShopProductColorEnum.MatteBlack]: '#000000',
  [ShopProductColorEnum.SaffronYellow]: '#F6A94F',
  [ShopProductColorEnum.FlamingoPink]: '#D8A0A6',
  [ShopProductColorEnum.JadeGreen]: '#B9CEAC',
  [ShopProductColorEnum.LagoonBlue]: '#7EBAB5',
  [ShopProductColorEnum.DeepseaBlue]: '#7EBAB5',
  [ShopProductColorEnum.MysticWhite]: '#FFFFFF',
  [ShopProductColorEnum.Transparent]: 'transparent',
};

const productColors: { [product in ShopProductEnum]: ShopProductColorEnum[] } = {
  [ShopProductEnum.LedgerNanoSPlus]: [
    ShopProductColorEnum.MatteBlack,
    ShopProductColorEnum.SaffronYellow,
    ShopProductColorEnum.FlamingoPink,
    ShopProductColorEnum.JadeGreen,
    ShopProductColorEnum.LagoonBlue,
    ShopProductColorEnum.DeepseaBlue,
    ShopProductColorEnum.MysticWhite,
    ShopProductColorEnum.Transparent,
  ],
  [ShopProductEnum.LedgerNanoX]: [ShopProductColorEnum.MatteBlack],
};

const productURL: { [product in ShopProductEnum]: string } = {
  [ShopProductEnum.LedgerNanoSPlus]: 'https://shop.ledger.com/products/ledger-nano-s-plus',
  [ShopProductEnum.LedgerNanoX]: 'https://shop.ledger.com/products/ledger-nano-x',
};

export const getShopProductColors = (product: ShopProductEnum) => productColors[product];

export const getShopProductColorValue = (color: ShopProductColorEnum) => productColorValues[color];

export const getShopProductURL = (product: ShopProductEnum, color: ShopProductColorEnum) => {
  let url = productURL[product];

  // if color isn't black, append color
  if (color !== ShopProductColorEnum.MatteBlack) {
    url += `/${color}`;
  }

  // add referrer code
  url += `?r=${referrerCode}`;

  return url;
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, TextFieldProps } from '@swyftx/react-web-design-system';

import EmailValidator from '@hooks/validator/types/email.validator';
import { useValidation } from '@hooks/validator/useValidation';

type EmailInputFormProps = {
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  success?: boolean;
  setValid?: (valid: boolean) => void;
  placeholder?: string;
  hideLabel?: boolean;
} & TextFieldProps;

const EmailInput: React.FC<EmailInputFormProps> = ({
  placeholder,
  attachForm,
  hideLabel,
  onChange,
  setValid,
  value,
  id,
  ...props
}) => {
  const { t } = useTranslation('common');

  const onChangeTrimmed = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.target.value = e.target.value.trim();
    if (onChange) onChange(e);
  };

  const validatorProps = useValidation(
    [new EmailValidator(t('errors.email.invalid'))],
    false,
    onChangeTrimmed,
    (value as string)?.trim(),
  );

  useEffect(() => {
    if (attachForm) attachForm(id!, validatorProps.validate);
  }, [attachForm]);

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  return (
    <Input
      id={id}
      PII
      label={!hideLabel ? t('labels.email') : undefined}
      placeholder={placeholder || t('labels.email')}
      InputProps={{ sx: { height: '48px', fontSize: '14px' } }}
      ContainerProps={{
        sx: { marginBottom: validatorProps.error ? { xs: 1, sm: 0 } : { xs: 3, sm: 2 } },
      }}
      sx={{
        width: '100%',
        '.MuiFormHelperText-root': {
          marginTop: '4px',
          lineHeight: '1',
        },
        '.MuiInputBase-root': {
          backgroundColor: 'var(--color-background-surface-secondary)',
          fontSize: 12,
          fontWeight: 500,
          padding: 1,
        },
      }}
      {...props}
      {...validatorProps}
    />
  );
};

export { EmailInput };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

type Props = {
  asset: Asset;
};

const AssetsSearchBalances: React.FC<Props> = observer(({ asset }) => {
  const { getBalance, getBalanceValue } = usePortfolioBalance();
  const baseAsset = useBaseAsset();

  return (
    <FlexLayout direction='column' alignItems='end' spacing={4}>
      <Numeric>
        {formatCurrency(getBalance(asset.id)?.availableBalance, asset, { appendCode: true, hideCode: false })}
      </Numeric>
      <Numeric color='secondary' size='small'>
        {formatCurrency(getBalanceValue(asset.id, 'midPrice'), baseAsset, { appendCode: true, hideCode: false })}
      </Numeric>
    </FlexLayout>
  );
});

export { AssetsSearchBalances };

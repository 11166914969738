import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { ExternalLink } from '@swyftx/aviary/icons/outlined';

type Props = { url: string; label: string; hideArrow?: boolean };

export const SecurityLink: React.FC<Props> = ({ url, label, hideArrow }) => (
  <Button
    variant='outlined'
    color='primary'
    onClick={() => window.open(url)?.focus()}
    trailingIcon={!hideArrow ? <ExternalLink /> : <></>}
  >
    {label}
  </Button>
);

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Redo: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.9651 3.96967C13.258 3.67678 13.7329 3.67678 14.0257 3.96967L18.0257 7.96967C18.1664 8.11032 18.2454 8.30109 18.2454 8.5C18.2454 8.69891 18.1664 8.88968 18.0257 9.03033L14.0257 13.0303C13.7329 13.3232 13.258 13.3232 12.9651 13.0303C12.6722 12.7374 12.6722 12.2626 12.9651 11.9697L15.6848 9.25H12.5173C7.89874 9.25 5.53068 14.7841 8.71971 18.125C8.71971 18.125 8.71972 18.125 8.71973 18.125L9.5379 18.9821C9.82391 19.2817 9.81288 19.7565 9.51327 20.0425C9.21365 20.3285 8.73891 20.3175 8.4529 20.0179L7.63471 19.1608L7.63469 19.1608C3.5345 14.8653 6.5791 7.75 12.5173 7.75H15.6848L12.9651 5.03033C12.6722 4.73744 12.6722 4.26256 12.9651 3.96967Z'
      fill='currentColor'
    />
  </svg>
);

export { Redo };

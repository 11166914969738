import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import { TextAreaProps, textAreaContainerVariants, textAreaVariants } from './TextArea.styles';
import { FlexLayout } from '../Layout/Flex';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { className, schema, onValidityChange, onChange, leading, trailing, variant, error, disabled, ...otherProps },
    ref,
  ) => {
    const validateValue = (value: string) => {
      if (!schema) {
        return true;
      }

      try {
        schema.parse(value);
        return true;
      } catch (err) {
        return false;
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      const isValid = validateValue(newValue);

      if (onChange) {
        onChange(event);
      }

      if (onValidityChange) {
        onValidityChange(isValid);
      }
    };

    return (
      <FlexLayout
        className={cn(textAreaContainerVariants({ error, disabled }))}
        alignItems='center'
        spacing={8}
        justifyContent='space-between'
      >
        <FlexLayout alignItems='center' justifyContent='start' spacing={8} className='w-full'>
          {leading}
          <textarea
            ref={ref}
            className={cn(textAreaVariants({ variant, error, disabled }), className)}
            onChange={handleChange}
            disabled={disabled}
            {...otherProps}
          />
        </FlexLayout>
        {trailing}
      </FlexLayout>
    );
  },
);
TextArea.displayName = 'TextArea';

export { TextArea };

import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';

import { PriceDirection } from './usePriceAlertInput';
import { PriceAlertTriggerType, PriceAlertWindow } from '../../dynamicPriceAlerts.types';

type Props = {
  type: PriceAlertTriggerType;
  secondaryCode: string;
  priceDirection?: PriceDirection;
  alertWindow?: PriceAlertWindow;
  value: string;
  assets?: Asset[];
  priceDiffPercentage?: string;
  error?: React.ReactElement;
};

const PriceAlertInputFooterContent: React.FC<Props> = ({
  type,
  priceDirection,
  value,
  assets = [],
  alertWindow,
  secondaryCode,
  priceDiffPercentage,
  error,
}) => {
  const { t } = useTranslation('priceAlerts');
  const window = useMemo(() => (alertWindow === 'DAY' ? '24 hours' : '1 hour'), [alertWindow]);

  if (error) return error;

  if (type === 'LIMIT') {
    return (
      <Trans
        t={t}
        i18nKey='labels.currentPercentage'
        values={{
          percentage: `${priceDiffPercentage || '0'}% ${priceDirection}`,
          code: secondaryCode,
        }}
        components={[
          <Body
            key='percentage'
            size='small'
            color={priceDirection === 'decrease' ? 'error' : 'success'}
            weight='bold'
          />,
          <Body key='price' size='small' />,
        ]}
      />
    );
  }

  return (
    <Trans
      t={t}
      i18nKey='labels.currentPercentageChange'
      values={{
        percentage: `${value}%`,
        code: assets.length > 1 ? `${assets?.length} asset prices` : secondaryCode,
        plural: assets.length > 1 ? '' : 's',
        window,
      }}
      components={[<Body key='code' size='small' />, <Body key='price' weight='bold' color='accent' size='small' />]}
    />
  );
};

export { PriceAlertInputFooterContent };

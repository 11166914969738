import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import { Links } from '@shared/constants';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useUniversalTradeUtilityStore } from '@hooks/Trade';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';

import { observer } from 'mobx-react-lite';

import { HighSlippageReviewItem } from './HighSlippageReviewItem';

const HighSlippageMultiReviewContent: React.FC = observer(() => {
  const { highSlippageTrades } = useUniversalTradeUtilityStore();
  const { t } = useTranslation('trade', { keyPrefix: 'highSlippageModal' });
  const { isMediumScreen } = useScreenBreakpoints();

  return (
    <Stack direction='column' alignItems='flex-start' justifyContent='center' width='100%' marginBottom={1}>
      <Typography padding={2} marginBottom={2} color='text.secondary'>
        <Trans
          t={t}
          i18nKey='multi.subTitle'
          values={{ slippageThreshold: HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD }}
          components={[
            <Typography
              key='slippageThreshold'
              display='inline-block'
              marginLeft={0.25}
              fontWeight={500}
              color='primary'
              sx={{ display: 'inline-block', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
              onClick={() => window.open(Links.learn.highSlippage)}
            />,
          ]}
        />
      </Typography>

      <Typography paddingX={2} marginBottom={2} fontWeight={600} fontSize={16}>
        {t('multi.impact')}
      </Typography>
      {isMediumScreen && (
        <>
          <Stack
            direction='row'
            width='100%'
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
            padding={1}
            paddingX={2}
          >
            <Typography fontSize={10} fontWeight={500} width='25%' textTransform='uppercase' color='text.secondary'>
              {t('order')}
            </Typography>
            <Typography
              fontSize={10}
              fontWeight={500}
              width='25%'
              textAlign='right'
              textTransform='uppercase'
              color='text.secondary'
            >
              {t('currentMarketPrice')}
            </Typography>
            <Typography
              fontSize={10}
              fontWeight={500}
              width='25%'
              textAlign='right'
              textTransform='uppercase'
              color='text.secondary'
            >
              {t('slippage')}
            </Typography>
            <Typography
              fontSize={10}
              fontWeight={500}
              width='25%'
              textAlign='right'
              textTransform='uppercase'
              color='text.secondary'
            >
              {t('quotePrice')}
            </Typography>
          </Stack>
          <Divider sx={{ width: '100%' }} orientation='horizontal' />
        </>
      )}
      {highSlippageTrades.length > 0 &&
        highSlippageTrades.map((data) => <HighSlippageReviewItem tradeData={data} key={`${data.from} ${data.to}`} />)}
    </Stack>
  );
});

export { HighSlippageMultiReviewContent };

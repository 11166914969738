import React from 'react';

import { Asset } from '@shared/api';

import { useActionsSearchModule } from './useActionsSearchModule';
import { GlobalSearchCategory } from '../../components/GlobalSearchCategory';
import { useFilterSearchModuleItems } from '../../hooks';

type Props = {
  searchValue: string;
  assets: Asset[];
};

const ActionsModuleSearchCategory: React.FC<Props> = ({ searchValue, assets }) => {
  const actionItems = useActionsSearchModule({ assets });
  const filteredItems = useFilterSearchModuleItems({ searchValue, items: actionItems });

  if (!filteredItems.length || !searchValue) return null;

  return <GlobalSearchCategory title='Actions' items={filteredItems} />;
};

export { ActionsModuleSearchCategory };

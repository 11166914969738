import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Close from '@mui/icons-material/Close';
import { Slide } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';

import { Button, ButtonProps } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FlexLayoutProps } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyLeft } from '@swyftx/aviary/icons/outlined';
import { Divider, PIITypes } from '@swyftx/react-web-design-system';

import { cn } from '@shared/utils/lib/ui';

import { StepModalContext } from './StepModal.context';

type ContainerProps = {
  children: Array<JSX.Element>;
  stepIndex: number;
  onClose: () => void;
  open: boolean;
  width?: string;
  maxWidth?: string;
  scrollClassName?: string;
  contentClassName?: string;
  className?: string;
  height?: string;
  maintainStateOnIndexes?: Array<number>;
} & PIITypes;

const Container: React.FC<ContainerProps> = ({
  maintainStateOnIndexes,
  scrollClassName,
  contentClassName,
  className,
  stepIndex,
  children,
  onClose,
  open,
  PII,
}) => {
  const childProps = useMemo(() => children[stepIndex].props as StepProps, [children, stepIndex]);
  const isXs = useTailwindBreakpoint('xs');

  const content = (() => {
    if (maintainStateOnIndexes) {
      return (
        <>
          {children.map((child, index) => {
            const selected = index === stepIndex;
            const maintainingState = maintainStateOnIndexes.find((_, stateIndex) => stateIndex === index) !== undefined;

            if (maintainingState || selected) {
              return (
                <div className={cn('h-full w-full', selected ? 'block' : 'hidden')} key={child.key}>
                  {child}
                </div>
              );
            }

            return <></>;
          })}
        </>
      );
    }

    return children[stepIndex];
  })();

  return (
    <Modal
      className={cn(PII ? 'fs-mask' : '', className)}
      position={isXs ? 'bottom' : 'center'}
      title={childProps.useModalHeader ? childProps.title : undefined}
      scrollClassName={scrollClassName}
      contentClassName={contentClassName}
      onClose={onClose}
      onOpenChange={(o) => {
        if (!o) onClose();
      }}
      open={open}
    >
      {content}
    </Modal>
  );
};

export type StepProps = {
  onClose?: () => void;
  actionColor?: ButtonProps['color'];
  footerProps?: FlexLayoutProps;
  useModalHeader?: boolean;
  useModalFooter?: boolean;
  actionLoading?: boolean;
  children?: JSX.Element;
  stickyFooter?: boolean;
  hideContinue?: boolean;
  stepLoading?: boolean;
  hideActions?: boolean;
  hideBack?: boolean;
  hideCancel?: boolean;
  actionName?: string;
  cancelName?: string;
  animate?: boolean;
  locked?: boolean;
  title?: string;
  onAction?: () => void;
  onStepBack?: () => void;
  style?: React.CSSProperties;
} & PIITypes;

const Step: React.FC<StepProps> = ({
  onClose,
  actionColor = 'primary',
  useModalFooter = false,
  useModalHeader = false,
  actionLoading,
  hideContinue,
  stickyFooter,
  stepLoading,
  footerProps,
  hideActions,
  actionName,
  cancelName,
  hideCancel,
  hideBack,
  children,
  animate,
  locked,
  title,
  PII,
  onStepBack,
  onAction,
  style,
}) => {
  const { stepCount, onBack } = useContext(StepModalContext);

  const { t } = useTranslation('profile', { keyPrefix: 'misc' });
  const theme = useTheme();
  return (
    <FlexLayout
      direction='column'
      className={cn(PII ? 'fs-mask' : '', 'relative w-full')}
      spacing={16}
      style={{ height: style?.height || '100%' }}
      justifyContent='start'
    >
      {/* title */}
      {title && !useModalHeader ? (
        <FlexLayout spacing={16} direction='column' className='px-16 pt-16 sm:px-24 sm:pt-24'>
          <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
            <FlexLayout spacing={16} alignItems='center'>
              {stepCount > 1 && !hideBack ? (
                <Button
                  layout='icon'
                  leadingIcon={<ArrowLineOnlyLeft />}
                  variant='outlined'
                  onClick={() => {
                    onBack();
                    if (onStepBack) onStepBack();
                  }}
                />
              ) : null}
              <Heading size='h4'>{title}</Heading>
            </FlexLayout>
            {onClose && (
              <Button
                layout='icon'
                id='wizard-close'
                onClick={() => onClose()}
                className='cursor-pointer'
                leadingIcon={<Close />}
                variant='outlined'
              />
            )}
          </FlexLayout>

          {stepLoading ? (
            <LinearProgress
              sx={{
                position: 'absolute',
                marginLeft: -2,
                width: `calc(100% + ${theme.spacing(4)})`,
                height: '2px',
              }}
              variant='query'
            />
          ) : null}
        </FlexLayout>
      ) : null}
      {/* content */}
      {animate ? (
        <Slide in direction='left'>
          <div>{children}</div>
        </Slide>
      ) : (
        <>{children}</>
      )}

      {/* footer */}
      {!hideActions && !useModalFooter && (
        <FlexLayout {...footerProps} direction='column' className='px-16 pb-16'>
          {stickyFooter ? <Divider sx={{ marginX: '-1rem !important' }} /> : null}
          <FlexLayout justifyContent='space-between' direction='row'>
            <FlexLayout
              justifyContent='end'
              alignItems='center'
              direction='row'
              className={cn('h-full w-full', stickyFooter ? 'sticky pt-16' : '')}
              spacing={16}
            >
              {!hideCancel && (
                <Button variant='outlined' onClick={onClose} size='lg'>
                  {cancelName || t('buttonLabels.cancel')}
                </Button>
              )}

              {!hideContinue && (
                <Button
                  loading={actionLoading !== undefined ? actionLoading : false}
                  variant='filled'
                  color={actionColor}
                  onClick={onAction}
                  disabled={locked}
                  size='lg'
                >
                  {actionName || t('buttonLabels.continue')}
                </Button>
              )}
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default {
  Container,
  Step,
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { inputContainerVariants, inputVariants } from '@swyftx/aviary/atoms/Input/Input.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Cross, Search } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { Command } from 'cmdk';

type Props = {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleOnOpenChange: (open?: boolean) => void;
};

const GlobalSearchInput: React.FC<Props> = ({ searchValue, setSearchValue, handleOnOpenChange }) => (
  <FlexLayout spacing={16} alignItems='center'>
    <FlexLayout className={cn(inputContainerVariants({}), '!rounded-32')} spacing={8} alignItems='center'>
      <Search />
      <Command.Input
        className={cn(inputVariants({}))}
        value={searchValue}
        onValueChange={setSearchValue}
        placeholder='Search Swyftx...'
        autoFocus={false}
      />
      <Button
        className={cn('pointer-events-auto', searchValue.length === 0 ? 'invisible' : '')}
        variant='ghost'
        onClick={() => setSearchValue('')}
      >
        Clear
      </Button>
    </FlexLayout>
    <Button
      onClick={() => handleOnOpenChange(false)}
      layout='icon'
      variant='outlined'
      size='lg'
      leadingIcon={<Cross />}
    />
  </FlexLayout>
);

export { GlobalSearchInput };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Reddit: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M19.7698 9.78927C19.1375 9.78927 18.5811 10.0506 18.1722 10.4595C16.6673 9.41832 14.6397 8.74807 12.3929 8.67641L13.5605 3.4156L17.2785 4.25025C17.2785 5.16077 18.0204 5.90268 18.9309 5.90268C19.8583 5.90268 20.6044 5.1397 20.6044 4.22917C20.6044 3.31865 19.8625 2.55566 18.9309 2.55566C18.2818 2.55566 17.7211 2.9477 17.4429 3.48305L13.3371 2.57253C13.1306 2.51773 12.9282 2.66526 12.8734 2.87182L11.592 8.67219C9.36203 8.76493 7.35551 9.43518 5.84641 10.4764C5.43752 10.0506 4.86001 9.78927 4.22771 9.78927C1.88396 9.78927 1.11676 12.934 3.26239 14.0089C3.18651 14.3419 3.15279 14.696 3.15279 15.0501C3.15279 18.5826 7.1321 21.4448 12.0177 21.4448C16.9244 21.4448 20.9037 18.5826 20.9037 15.0501C20.9037 14.696 20.8658 14.325 20.773 13.992C22.8765 12.9129 22.1009 9.78927 19.7698 9.78927ZM6.66419 14.2323C6.66419 13.3049 7.4061 12.5588 8.33769 12.5588C9.24822 12.5588 9.99012 13.3007 9.99012 14.2323C9.99012 15.1428 9.24822 15.8847 8.33769 15.8847C7.41031 15.8889 6.66419 15.1428 6.66419 14.2323ZM15.6977 18.1737C14.1633 19.7081 9.83415 19.7081 8.29976 18.1737C8.13114 18.0261 8.13114 17.7648 8.29976 17.5962C8.44729 17.4486 8.70865 17.4486 8.85618 17.5962C10.0281 18.7976 13.9146 18.8186 15.1371 17.5962C15.2846 17.4486 15.546 17.4486 15.6935 17.5962C15.8664 17.7648 15.8664 18.0261 15.6977 18.1737ZM15.664 15.8889C14.7535 15.8889 14.0116 15.147 14.0116 14.2365C14.0116 13.3091 14.7535 12.563 15.664 12.563C16.5914 12.563 17.3375 13.3049 17.3375 14.2365C17.3333 15.1428 16.5914 15.8889 15.664 15.8889Z'
      fill='currentColor'
    />
  </svg>
);

export { Reddit };

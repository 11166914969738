import { Typography } from '@mui/material';

import Links from '@shared/constants/links';

import { Release, ReleaseVersion } from '../whats-new.type';

const EarnClosureLink: JSX.Element = (
  <Typography key='earn-link' component='li' color='text.secondary'>
    You can read more in our blog post here:{' '}
    <Typography
      variant='body1'
      component='a'
      color='primary.main'
      href={Links.news.earnClosure}
      target='_blank'
      rel='noopener noreferrer'
    >
      {Links.news.earnClosure}
    </Typography>
  </Typography>
);

const releaseSix: Release = {
  version: ReleaseVersion.Six,
  title: 'Important message about Earn',
  features: [
    {
      imgSrc: '/assets/images/releases/6/feature.png',
      descriptions: [
        'Due to the constantly changing regulatory landscape, we have made the decision to close the Earn program on 10 January 2023.',
        'While we believe in the value and potential of cryptocurrency, what we currently need is greater clarity on the regulation of crypto offerings such as Earn.',
        'This is not a financially driven decision and we are not removing the program due to any losses or issues with customer assets.',
        'Any earn balances have been returned to your trade wallet and you can continue to trade as normal.',
        EarnClosureLink,
        "If you have any questions or concerns, please don't hesitate to reach out to us via the live chat.",
      ],
    },
  ],
};

export { releaseSix };

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Loading, SwyftxFull } from '@swyftx/aviary/icons/outlined';
import { Typography } from '@swyftx/react-web-design-system';

import { CodeInput } from '@global-components/Input';

import { LoginContext } from '@routes/Login/Login.context';
import { LoginStep } from '@routes/Login/types';
import AuthenticationService from '@services/AuthenticationService';

import { observer } from 'mobx-react-lite';

import { LoginStepFooter } from '../LoginStepFooter';
import { LoginTitle } from '../LoginTitle';

const NewLoginDetected: React.FC = observer(() => {
  const { otpLogin, otpData, setOtpData, error, setLoginStep, setShowCreateAccount, submitting, email, password } =
    useContext(LoginContext);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation('login');

  useEffect(() => {
    setShowCreateAccount(false);
    if (code.length === 6) otpLogin(code);
  }, [code, otpLogin, setShowCreateAccount]);

  const onLogin = useCallback(() => otpLogin(code), [otpLogin, code]);

  const onSMSFallback = useCallback(async () => {
    if (!email || !password || !otpData) return;

    setLoading(true);

    const response = await AuthenticationService.OTPSMSFallback(email, password, otpData);

    if (response) {
      setOtpData(response);
    }

    setLoading(false);
  }, [email, otpData, password, setOtpData]);

  return (
    <>
      {loading && (
        <FlexLayout alignItems='center' justifyContent='center' className='h-full w-full'>
          <Loading className='animate-spin' />
        </FlexLayout>
      )}
      {!loading && (
        <FlexLayout
          direction='column'
          className='h-full w-full'
          alignItems='center'
          justifyContent='space-between'
          spacing={32}
        >
          <FlexLayout direction='column' spacing={32}>
            <SwyftxFull className='hidden h-[38px] sm:block' />
            <LoginTitle
              id='newLoginDetected.title'
              title={t('newLoginDetected.title')}
              subTitle={
                otpData?.otp.challengeType === 'email'
                  ? t('newLoginDetected.subTitle')
                  : t('newLoginDetected.subTitleSMS')
              }
              subTitleDescription={
                otpData?.otp.challengeType === 'email'
                  ? t('newLoginDetected.subTitleDescription')
                  : t('newLoginDetected.subTitleDescriptionSMS')
              }
            />
            <FlexLayout
              className='w-full'
              spacing={16}
              alignItems='center'
              justifyContent='center'
              direction='column'
              id='twoFactorLogin.form.codeInput.container'
            >
              {otpData?.otp.challengeType === 'email' && (
                <Typography color='text.secondary'>
                  <Trans
                    t={t}
                    i18nKey='newLoginDetected.subTitleSentEmail'
                    values={{ emailAddress: email }}
                    components={[
                      <Typography
                        key='newLoginDetected.subTitleSentEmail'
                        PII
                        display='inline-block'
                        marginLeft={0.25}
                        fontWeight={500}
                        color='text.primary'
                        sx={{ display: 'inline-block' }}
                      />,
                    ]}
                  />
                </Typography>
              )}

              {otpData?.otp.challengeType === 'sms' && (
                <Typography color='text.secondary' textAlign='center'>
                  {t('newLoginDetected.subTitleSentSMS')}
                </Typography>
              )}

              <CodeInput
                id='newLoginDetected.form.codeInput'
                error={error}
                length={6}
                onChange={(newCode) => setCode(newCode.join(''))}
              />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout className='w-full' direction='column'>
            {otpData?.otp.challengeType === 'email' && (
              <Typography fontSize={12} color='text.secondary' textAlign='center'>
                If you haven’t received an email after a few minutes, please check your spam folder. If you still can’t
                find it,{' '}
                <Typography
                  color='primary'
                  display='inline-block'
                  fontSize={12}
                  onClick={onSMSFallback}
                  sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                >
                  click here to try another method.
                </Typography>
              </Typography>
            )}

            <LoginStepFooter
              buttonText={t('newLoginDetected.buttonLabels.submit')}
              onNext={onLogin}
              onBack={() => setLoginStep(LoginStep.SignIn)}
              buttonLoading={submitting}
              disableButton={!code}
            />
          </FlexLayout>
        </FlexLayout>
      )}
    </>
  );
});

export { NewLoginDetected };

import React, { useCallback, useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List, ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { QuickBuyContext } from 'src/lib/trade/context';
import { useQuickBuyPaymentMethods } from 'src/lib/trade/hooks';
import { useQuickBuyAnalytics } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAnalytics';
import { QuickBuyPaymentMethodData } from 'src/lib/trade/types/Trade.types';

import { QuickBuyCardHeader } from '../../components';

const QuickBuyWidgetPaymentMethod: React.FC = () => {
  const { setCurrentStep } = useContext(QuickBuyContext);
  const { paymentOptions, selectedPaymentMethod, setSelectedPaymentMethod } = useQuickBuyPaymentMethods();
  const { trackUpdatedPaymentOption } = useQuickBuyAnalytics();

  const selectOption = useCallback(
    (option: QuickBuyPaymentMethodData) => {
      if (option.disabled) return;

      trackUpdatedPaymentOption(option.type);
      setSelectedPaymentMethod(option.type);
      setCurrentStep('values');
    },
    [setCurrentStep, setSelectedPaymentMethod, trackUpdatedPaymentOption],
  );

  return (
    <FlexLayout
      direction='column'
      spacing={24}
      alignItems='center'
      justifyContent='space-between'
      className='h-full w-full pb-24'
    >
      <QuickBuyCardHeader title='Select payment method' onBack={() => setCurrentStep('values')} />
      <FlexLayout
        direction='column'
        className='h-full w-full px-24'
        spacing={24}
        alignItems='center'
        justifyContent='end'
      >
        <List className='w-full pt-4' spacing={16}>
          {paymentOptions.map((option: QuickBuyPaymentMethodData) => (
            <ListItem
              key={option.type}
              className={cn(
                'mb-8 w-full',
                selectedPaymentMethod === option.type ? 'bg-color-background-surface-selected ' : '',
              )}
              disabled={option.disabled}
              onClick={() => selectOption(option)}
            >
              <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                <FlexLayout alignItems='center' spacing={12}>
                  {option.icon}
                  <FlexLayout direction='column' alignItems='start' spacing={0}>
                    <Body color='primary'>{option.title}</Body>
                    <Body color='secondary' size='xsmall' className='text-left'>
                      {option.description}
                    </Body>
                  </FlexLayout>
                </FlexLayout>
                {option.disabled && (
                  <Body color='secondary' className='whitespace-nowrap'>
                    Coming Soon!
                  </Body>
                )}
              </FlexLayout>
            </ListItem>
          ))}
        </List>
      </FlexLayout>
    </FlexLayout>
  );
};

export { QuickBuyWidgetPaymentMethod };

import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GreenIdStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';

import { useIntercom } from 'react-use-intercom';

import { ProfileVerificationContext } from '../../ProfileVerification.context';
import { VerificationStep } from '../../types';

export const useGreenIdStatus = (entityMember?: EntityMember, isEntityUser?: boolean) => {
  const { userProfile } = UserStore.useUserStore;
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const status = entityMember?.verified || userProfile?.verification?.greenid?.status;
  const { t } = useTranslation('profile.verification');
  const { showNewMessages } = useIntercom();

  const chipColor: 'success' | 'primary' | 'destructive' | 'secondary' = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.VERIFIED:
      case GreenIdStatusEnum.VERIFIED_UNDERAGE:
        return 'success';
      case GreenIdStatusEnum.PENDING_REVIEW:
      case GreenIdStatusEnum.IN_PROGRESS:
        return 'primary';
      case GreenIdStatusEnum.FAILED:
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return 'destructive';
      default:
        return 'secondary';
    }
  }, [status]);

  const chatWithSupport = useCallback(
    (supportStatus?: GreenIdStatusEnum) => {
      if (!supportStatus) return;

      if (supportStatus === GreenIdStatusEnum.FAILED_DUPLICATE) {
        showNewMessages(t('greenIdVerificationModal.errors.duplicateID.intercom'));
      } else if (supportStatus === GreenIdStatusEnum.FAILED) {
        showNewMessages(t('greenIdVerificationModal.errors.failed.intercom'));
      }
    },
    [showNewMessages, t],
  );

  const shouldShowNotification = useMemo(
    () =>
      status &&
      [
        GreenIdStatusEnum.IN_PROGRESS,
        GreenIdStatusEnum.PENDING_REVIEW,
        GreenIdStatusEnum.FAILED,
        GreenIdStatusEnum.FAILED_DUPLICATE,
      ].includes(status),
    [status],
  );

  const notificationColor: 'info' | 'warning' | 'destructive' | undefined = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.IN_PROGRESS:
        return 'info';
      case GreenIdStatusEnum.PENDING_REVIEW:
        return 'warning';
      case GreenIdStatusEnum.FAILED:
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return 'destructive';
      default:
        return undefined;
    }
  }, [status]);

  const notificationDescription = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.IN_PROGRESS:
      case GreenIdStatusEnum.PENDING_REVIEW:
        return t('identityVerificationModal.labels.greenIdAdditionalInfo');
      case GreenIdStatusEnum.FAILED:
        return t('identityVerificationModal.labels.failedGeneric');
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return t('identityVerificationModal.labels.failedDuplicateId');
      default:
        return '';
    }
  }, [status, t]);

  const notificationTitle = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.IN_PROGRESS:
      case GreenIdStatusEnum.PENDING_REVIEW:
        return t('identityVerificationModal.labels.additionalInfoTitle');
      case GreenIdStatusEnum.FAILED:
        return t('identityVerificationModal.labels.failedGenericTitle');
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return t('identityVerificationModal.labels.failedDuplicateIdTitle');
      default:
        return '';
    }
  }, [status, t]);

  const startVerification = useCallback(() => {
    if (status !== GreenIdStatusEnum.NOT_STARTED) {
      setVerificationStep(VerificationStep.GreenID);
    } else {
      setVerificationStep(VerificationStep.PersonalDetails);
    }
  }, []);

  const shouldShowButton = useMemo(() => {
    if (entityMember !== undefined && !isEntityUser) {
      return null;
    }

    return (
      status &&
      [GreenIdStatusEnum.IN_PROGRESS, GreenIdStatusEnum.FAILED, GreenIdStatusEnum.FAILED_DUPLICATE].includes(status)
    );
  }, [entityMember, isEntityUser, status]);

  const buttonColor: 'primary' | 'destructive' | undefined = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.IN_PROGRESS:
        return 'primary';
      case GreenIdStatusEnum.FAILED:
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return 'destructive';
      default:
        return undefined;
    }
  }, [status]);

  const buttonText = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.IN_PROGRESS:
        return t('identityVerificationModal.buttonLabels.resumeVerification');
      case GreenIdStatusEnum.FAILED:
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return t('identityVerificationModal.buttonLabels.chatWithSupport');
      default:
        return '';
    }
  }, [status, t]);

  const buttonAction = useMemo(() => {
    switch (status) {
      case GreenIdStatusEnum.IN_PROGRESS:
        return startVerification;
      case GreenIdStatusEnum.FAILED:
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return () => chatWithSupport(status);
      default:
        return undefined;
    }
  }, [chatWithSupport, startVerification, status]);

  return {
    chipColor,
    shouldShowNotification,
    notificationColor,
    notificationTitle,
    notificationDescription,
    shouldShowButton,
    buttonColor,
    buttonText,
    buttonAction,
  };
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { formatCurrency as currencyFormat } from '@swyftx/currency-util';

import { Asset, AssetType, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  balance: UserBalance;
  color?: 'primary' | 'secondary';
  variant?: 'dashboard' | 'trade';
  value?: Big;
  baseAsset?: Asset;
};

const PortfolioTableBalance: React.FC<Props> = observer(
  ({ asset, color = 'primary', balance, variant = 'dashboard', value, baseAsset }) => {
    if (variant === 'trade') {
      return (
        <FlexLayout direction='column' className='flex-col truncate text-end sm:text-start'>
          <FlexLayout
            direction='row'
            spacing={4}
            alignItems='center'
            className='flex-wrap justify-end truncate sm:flex-nowrap sm:justify-start'
          >
            <Numeric size='small' color={color}>
              {currencyFormat(balance.availableBalance, { isFiat: asset.assetType === AssetType.Fiat })} {asset.code}
            </Numeric>
          </FlexLayout>
          {value && baseAsset && (
            <Numeric size='small' color='secondary' className='block  @sm:hidden'>
              {currencyFormat(value.toString(), { isFiat: baseAsset.assetType === AssetType.Fiat })} {baseAsset.code}
            </Numeric>
          )}
        </FlexLayout>
      );
    }
    return (
      <Numeric size='small' className='whitespace-nowrap text-right' color={color}>
        {formatCurrency(balance.availableBalance, asset)}
      </Numeric>
    );
  },
);

export { PortfolioTableBalance };

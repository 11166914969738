import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const PredictionMarkets: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M9.10345 5.73238C9.38462 5.4512 9.8405 5.45118 10.1217 5.73236L10.1317 5.74236C10.4129 6.02353 10.4129 6.4794 10.1317 6.76059C9.85054 7.04177 9.39466 7.04178 9.11347 6.76061L9.10347 6.75061C8.82229 6.46944 8.82228 6.01356 9.10345 5.73238Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8939 9.12753C10.8016 8.82437 10.5219 8.61718 10.205 8.61719C9.88815 8.61719 9.60852 8.82439 9.51625 9.12755C9.21945 10.1028 8.45624 10.866 7.48104 11.1628C7.17789 11.2551 6.9707 11.5348 6.9707 11.8516C6.9707 12.1685 7.17789 12.4482 7.48104 12.5404C8.45621 12.8373 9.21944 13.6006 9.51625 14.5758C9.60852 14.879 9.88816 15.0862 10.205 15.0862C10.5219 15.0862 10.8016 14.879 10.8939 14.5758C11.1907 13.6006 11.954 12.8373 12.9291 12.5404C13.2323 12.4482 13.4395 12.1685 13.4395 11.8516C13.4395 11.5348 13.2323 11.2551 12.9291 11.1628C11.9539 10.866 11.1907 10.1028 10.8939 9.12753ZM9.26814 11.8517C9.62491 11.5873 9.94074 11.2715 10.2051 10.9147C10.4694 11.2715 10.7853 11.5873 11.142 11.8517C10.7853 12.116 10.4694 12.4319 10.2051 12.7887C9.94074 12.4319 9.62491 12.116 9.26814 11.8517Z'
      fill='currentColor'
    />
    <path
      d='M16.0963 12.8823C15.8152 12.6011 15.3593 12.6011 15.0781 12.8822C14.7969 13.1634 14.7969 13.6193 15.078 13.9005L15.088 13.9105C15.3692 14.1917 15.8251 14.1917 16.1063 13.9105C16.3875 13.6294 16.3875 13.1735 16.1063 12.8923L16.0963 12.8823Z'
      fill='currentColor'
    />
    <path
      d='M15.0165 6.83913C14.9202 6.54234 14.6437 6.34134 14.3317 6.34131C14.0197 6.34128 13.7431 6.54223 13.6468 6.83901C13.5604 7.10529 13.3518 7.31385 13.0856 7.40022C12.7889 7.49654 12.5879 7.77305 12.5879 8.08507C12.5879 8.39709 12.7889 8.67359 13.0856 8.7699C13.3518 8.85627 13.5604 9.06482 13.6468 9.33111C13.7431 9.62788 14.0197 9.82883 14.3317 9.82881C14.6437 9.82878 14.9202 9.62778 15.0165 9.33098C15.1028 9.06483 15.3113 8.8563 15.5776 8.76992C15.8744 8.67362 16.0754 8.3971 16.0754 8.08507C16.0754 7.77304 15.8744 7.49651 15.5776 7.40021C15.3113 7.31382 15.1028 7.10528 15.0165 6.83913Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9991 2.51074C7.44506 2.51074 3.75391 6.20278 3.75391 10.7559C3.75391 13.6588 5.25402 16.2102 7.51888 17.6788L7.1342 18.2792C6.90647 18.6345 6.70966 18.9417 6.57559 19.1993C6.44101 19.4578 6.30845 19.7758 6.33055 20.1339C6.36133 20.6325 6.61257 21.0916 7.01565 21.3865C7.30524 21.5983 7.64442 21.6582 7.93475 21.6843C8.22388 21.7104 8.58851 21.7104 9.0104 21.7104H14.9931C15.415 21.7104 15.7797 21.7104 16.0688 21.6843C16.3591 21.6582 16.6984 21.5982 16.988 21.3864C17.391 21.0915 17.6422 20.6326 17.673 20.1338C17.6951 19.7758 17.5626 19.4579 17.428 19.1993C17.2939 18.9416 17.097 18.6343 16.8692 18.2788L16.4831 17.6763C18.7458 16.2073 20.2442 13.6572 20.2442 10.7559C20.2442 6.20278 16.553 2.51074 11.9991 2.51074ZM5.19391 10.7559C5.19391 6.99798 8.24044 3.95074 11.9991 3.95074C15.7576 3.95074 18.8042 6.99798 18.8042 10.7559C18.8042 13.3094 17.3983 15.5353 15.3152 16.7001H8.6829C6.59979 15.5353 5.19391 13.3094 5.19391 10.7559ZM8.36403 19.029L8.93358 18.1401H15.07L15.6396 19.029C15.8898 19.4194 16.0503 19.6713 16.1506 19.864C16.2135 19.985 16.2303 20.0425 16.2345 20.0589C16.2272 20.1201 16.1965 20.1763 16.1486 20.2157C16.1326 20.2211 16.0751 20.2379 15.9395 20.2502C15.723 20.2697 15.4243 20.2704 14.9605 20.2704H9.04299C8.57922 20.2704 8.28057 20.2697 8.0641 20.2502C7.92843 20.2379 7.87086 20.221 7.85483 20.2157C7.80707 20.1764 7.77637 20.1202 7.76905 20.0588C7.77322 20.0425 7.79004 19.9849 7.85294 19.8641C7.95328 19.6713 8.11382 19.4194 8.36403 19.029Z'
      fill='currentColor'
    />
  </svg>
);

export { PredictionMarkets };

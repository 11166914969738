import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { WalletList, WalletMetrics, WalletPortfolioAllocation, WalletPortfolioPerformance } from '@Wallet/components';

import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';

import { WalletType } from './types';

type WalletParams = {
  type: 'all' | 'trade' | 'earn';
};

const Wallet: React.FC = observer(() => {
  const { type } = useParams<WalletParams>();

  const navigate = useNavigate();

  const isValidType = (type: WalletParams | string | undefined): type is WalletParams =>
    type === undefined || type === 'all' || type === 'trade';

  useEffect(() => {
    if (!isValidType(type)) {
      navigate(NavigationURLs.WalletsRoot);
    }
  }, [type, navigate]);

  return (
    <>
      <FlexLayout direction='column' className='p-16 @container sm:p-24 sm:pr-0' spacing={16}>
        <FlexLayout direction='column' className='hidden w-full @md:flex'>
          <WalletMetrics walletType={WalletType.ALL_WALLETS} />
        </FlexLayout>
        <FlexLayout direction='row' spacing={16}>
          <FlexLayout direction='column' className='w-full @md:w-5/12'>
            <Card className='h-full w-full'>
              <WalletPortfolioAllocation walletType={WalletType.ALL_WALLETS} />
            </Card>
          </FlexLayout>
          <FlexLayout direction='column' className='hidden w-full @md:flex md:w-7/12'>
            <Card className='h-full w-full'>
              <WalletPortfolioPerformance walletType={WalletType.ALL_WALLETS} />
            </Card>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout className='min-h-[80px] w-full'>
          <WalletList />
        </FlexLayout>
      </FlexLayout>
    </>
  );
});

export default Wallet;

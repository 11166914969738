import { useContext, useMemo } from 'react';

import { Big } from '@shared/safe-big';

import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { SwyftxProTradeContext } from '../../context';

const useSwyftxProTradeTakeProfitDetails = () => {
  const { takeProfitPrice, triggerPrice, total, amount, showMarketTakeProfit, tradeType } =
    useContext(SwyftxProTradeContext);
  const { lowestFee } = useCurrentUserFees();

  const takeProfitPercentage = useMemo(() => {
    if (!triggerPrice || !takeProfitPrice) return Big('0');

    const priceDiff = Big(takeProfitPrice).minus(triggerPrice);
    return priceDiff.div(triggerPrice).minus(lowestFee).times(100);
  }, [lowestFee, takeProfitPrice, triggerPrice]);

  const takeProfitTotal = useMemo(
    () => (takeProfitPercentage.gt(0) ? Big(total).times(Big(1).plus(takeProfitPercentage.div(100))) : Big(0)),
    [takeProfitPercentage, total],
  );

  const simpleTakeProfitTotal = useMemo(
    () => (takeProfitPrice ? Big(amount).times(Big(takeProfitPrice)) : Big(0)),
    [amount, takeProfitPrice],
  );

  const takeProfitTotalProfit = useMemo(
    () => (takeProfitTotal ? Big(takeProfitTotal).minus(total) : undefined),
    [takeProfitTotal, total],
  );

  const takeProfitError = useMemo(() => {
    if ((tradeType === 'market' && !showMarketTakeProfit) || takeProfitPercentage.eq(0)) return false;

    return Big(triggerPrice).gt(0) && Big(takeProfitPrice).gt(0) && Big(triggerPrice).gte(takeProfitPrice || '0');
  }, [tradeType, showMarketTakeProfit, takeProfitPercentage, triggerPrice, takeProfitPrice]);

  return {
    takeProfitTotalProfit,
    takeProfitPercentage,
    takeProfitTotal,
    takeProfitPrice,
    takeProfitError,
    simpleTakeProfitTotal,
  };
};

export { useSwyftxProTradeTakeProfitDetails };

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { Star, Notification } from '@swyftx/aviary/icons/outlined';
import { Badge } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { api } from '@shared/api';
import { PriceAlert } from '@shared/api/@types/alerts';
import { FiatIdEnum } from '@shared/enums';
import { assetService } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AssetInfoPopover } from 'src/lib/UniversalTrade/components/AssetInfoPopover/AssetInfoPopover';
import { PriceAlertButton } from 'src/lib/dynamic-price-alerts/components';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

type Props = {
  assetCode: string;
  alerts?: PriceAlert[];
  onFavouriteCb?: (assetId: number, isFavourite: boolean) => void;
  tradePanel?: boolean;
  assetInfo?: boolean;
  className?: string;
};

const AppHeaderAssetActions: React.FC<Props> = observer(
  ({ assetCode, onFavouriteCb, tradePanel, assetInfo, className }) => {
    const { t } = useTranslation('common', { keyPrefix: 'appHeaderAssetActions' });
    const { userProfile, setFavourites } = UserStore.useUserStore;
    const { addToastMessage } = UIStore.useUIStore;
    const { getAssetByCode } = assetService;
    const { isFavourite } = useMarkets();
    const [activeAlerts, setActiveAlerts] = useState<PriceAlert[]>([]);
    const { openModal } = useModal();
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const { isSwyftxPro } = useSwyftxPro();

    const baseAsset = useBaseAsset();
    const asset = useMemo(() => getAssetByCode(assetCode), [assetCode, getAssetByCode]);

    const isFavouriteAsset = useMemo(() => (asset ? isFavourite(asset.id) : false), [asset, isFavourite]);

    const setAsFavourite = useCallback(() => {
      if (!asset) return;
      const newIsFavourite = !isFavouriteAsset;
      UserService.UpdateUserSettings({
        data: { favouriteAsset: { assetId: asset.id, favStatus: newIsFavourite } },
      });
      setFavourites(asset.id, newIsFavourite);
      if (onFavouriteCb) {
        onFavouriteCb(asset.id, newIsFavourite);
      }

      addToastMessage({
        severity: 'info',
        message: t(!isFavouriteAsset ? 'addFavourite' : 'removeFavourite', { name: asset.name }),
      });
    }, [addToastMessage, asset, isFavouriteAsset, onFavouriteCb, setFavourites, t]);

    useEffect(() => {
      const getAlerts = async () => {
        if (asset) {
          const response = await api.endpoints.getAlerts({
            query: {
              primary: userProfile?.currency.id || FiatIdEnum.USD,
              secondary: asset.id,
            },
          });

          setActiveAlerts(response.data);
        }
      };

      getAlerts();
    }, [asset, userProfile?.currency.id]);

    if (!asset) return null;

    return (
      <FlexLayout direction='row' spacing={8} className={cn(tradePanel ? 'px-0' : '', 'items-center', className)}>
        <Button
          variant={isSwyftxPro ? 'ghost' : 'outlined'}
          className='h-32 w-32 items-center rounded-19'
          size='sm'
          onClick={setAsFavourite}
          layout='icon'
          leadingIcon={
            isFavouriteAsset ? (
              <StarFilled
                className={cn('h-18 w-18', isSwyftxPro ? '!text-color-text-accent' : 'text-color-text-warning')}
              />
            ) : (
              <Star className='h-18 w-18 text-color-text-primary' />
            )
          }
          tooltip={isFavouriteAsset ? 'Remove from favourites' : 'Add to favourites'}
        />

        {baseAsset?.code !== asset.code && (
          <>
            {isFeatureEnabled(AppFeature.DynamicPriceAlerts) ? (
              <PriceAlertButton secondary={asset.id} variant='compact' />
            ) : (
              <Button
                variant={isSwyftxPro ? 'ghost' : 'outlined'}
                className='h-32 w-32 items-center rounded-19'
                size='sm'
                onClick={() => openModal(Modals.PriceAlerts, { asset })}
                tooltip='View price alerts'
              >
                <FlexLayout alignItems='center' justifyContent='center' className='relative'>
                  <Notification className='absolute h-18 w-18 text-color-text-primary' />
                  {activeAlerts.length > 0 && (
                    <Badge variant='dot' color='info' className='absolute right-[-0.8rem] top-[-0.8rem]' />
                  )}
                </FlexLayout>
              </Button>
            )}
          </>
        )}

        {assetInfo && <AssetInfoPopover asset={asset} className='flex h-32 w-32 items-center rounded-19 @xl:hidden' />}
      </FlexLayout>
    );
  },
);

export { AppHeaderAssetActions };

import { DateTime } from 'luxon';

const shortTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const formatDate = (date: number | Date) => {
  const formatted = DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy');
  return formatted;
};

export const formatTime = (time: number | Date) => {
  const options: Intl.DateTimeFormatOptions = { ...shortTime };
  const formatted = new Date(time).toLocaleTimeString([], options);
  return formatted;
};

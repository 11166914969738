import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const LayerTwo: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24'>
    <path
      d='M14.3763 3.15746L15.7071 3.93762C16.0502 4.13873 16.1652 4.57984 15.9641 4.92289C15.763 5.26593 15.3219 5.38099 14.9789 5.17989L13.6481 4.39971C12.9202 3.97298 12.019 3.97294 11.2911 4.3997L9.96026 5.17989C9.61722 5.38099 9.1761 5.26593 8.97499 4.92289C8.77388 4.57985 8.88895 4.13873 9.23199 3.93762L10.5628 3.15744C11.7404 2.46705 13.1988 2.46705 14.3763 3.15746Z'
      fill='currentColor'
    />
    <path
      d='M7.55563 5.75498C7.75673 6.09803 7.64167 6.53914 7.29863 6.74025L5.96786 7.52041C5.43074 7.83538 5.4307 8.61222 5.96781 8.92719L7.29863 9.70737C7.64167 9.90848 7.75673 10.3496 7.55563 10.6926C7.35452 11.0357 6.9134 11.1507 6.57036 10.9496L5.23954 10.1695C3.75351 9.29815 3.75347 7.1495 5.2395 6.27819L6.57036 5.49799C6.9134 5.29688 7.35452 5.41194 7.55563 5.75498Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.85034 11.2151C9.88778 11.2293 9.92455 11.2468 9.96026 11.2677L11.2911 12.0479C12.019 12.4747 12.9202 12.4747 13.648 12.0479L14.9789 11.2677C15.0076 11.2509 15.037 11.2363 15.0669 11.2238C15.3028 11.0405 15.6383 11.0173 15.9025 11.1899L19.922 13.8162C21.3206 14.7337 21.2707 16.8015 19.8258 17.6482L14.3786 20.8416C13.2016 21.5323 11.7423 21.5322 10.5647 20.8418L5.09895 17.6372C3.66165 16.7954 3.60331 14.7386 4.98871 13.8153L8.98054 11.1604C9.25606 10.9771 9.61178 11.0086 9.85034 11.2151ZM9.40184 12.6096L5.78727 15.0136C5.28612 15.3477 5.30826 16.0911 5.82648 16.3945L11.293 19.5995C12.0211 20.0264 12.923 20.0262 13.6497 19.5997L19.0975 16.406C19.6194 16.1002 19.6388 15.3531 19.1326 15.0206L15.4874 12.6388L14.3764 13.2901C13.1988 13.9806 11.7404 13.9806 10.5628 13.2902L9.40184 12.6096Z'
      fill='currentColor'
    />
    <path
      d='M17.3702 5.75564C17.169 6.09868 17.2841 6.5398 17.6272 6.74091L18.9579 7.52106C19.495 7.83603 19.4951 8.61288 18.958 8.92785L17.6272 9.70803C17.2841 9.90913 17.169 10.3503 17.3702 10.6933C17.5713 11.0363 18.0124 11.1514 18.3554 10.9503L19.6862 10.1701C21.1723 9.2988 21.1723 7.15016 19.6863 6.27885L18.3554 5.49864C18.0124 5.29753 17.5713 5.4126 17.3702 5.75564Z'
      fill='currentColor'
    />
  </svg>
);

export { LayerTwo };

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const VisaIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <rect
        x='5.333'
        y='3.333'
        width='21.333'
        height='25.333'
        rx='3.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='.4'
        d='M10.668 12.668h10.667M10.668 18.001h10.667M10.668 23.335h5.333'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5 6.5A3.5 3.5 0 0 1 8.5 3h15A3.5 3.5 0 0 1 27 6.5V8H5V6.5Z' fill='currentColor' />
    </svg>
  </SvgIcon>
);

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { AssetThumbnailStack } from 'src/lib/markets/components';

import { useCreatePriceAlertModalContent } from './useCreatePriceAlertModalContent';
import { PriceAlertInput } from '../PriceAlertInput';

type Props = {
  assets: Asset[];
  baseAsset: Asset;
  onClose: () => void;
  onChangeAssets?: () => void;
};

const CreatePriceAlertModalContent: React.FC<Props> = observer(({ assets, baseAsset, onClose, onChangeAssets }) => {
  const {
    type,
    creatingAlert,
    value,
    alertWindow,
    alertType,
    onCreatePriceAlert,
    setAlertType,
    setAlertWindow,
    setValue,
  } = useCreatePriceAlertModalContent({
    assets,
    baseAsset,
    onClose,
  });
  if (!baseAsset) return null;

  return (
    <FlexLayout direction='column' className='h-full w-full px-24 pt-24' justifyContent='space-between' spacing={24}>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <FlexLayout alignItems='center' spacing={16}>
          {type === 'single' && <AssetIcon asset={assets[0]} size={24} />}
          <Heading size='h4'>Create price alert {type === 'multi' ? 'for' : ''}</Heading>
          {type === 'multi' && <AssetThumbnailStack assets={assets} onClick={onChangeAssets} maxAssetsToShow={5} />}
        </FlexLayout>
        {onClose !== undefined && <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={onClose} />}
      </FlexLayout>

      <PriceAlertInput
        type={type}
        value={value}
        primaryAsset={baseAsset}
        alertType={alertType}
        setAlertType={setAlertType}
        secondaryAsset={assets[0]}
        assets={assets}
        setValue={setValue}
        loading={creatingAlert}
        onActionClick={onCreatePriceAlert}
        disabled={!value}
        alertWindow={alertWindow}
        setAlertWindow={setAlertWindow}
      />
    </FlexLayout>
  );
});

export { CreatePriceAlertModalContent };

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EmailVerificationDarkIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none'>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.8803 59.2333C11.2979 58.8978 11 58.4684 11 58.0577C11 57.7195 11.2067 57.4002 11.6227 57.1586L42.6222 39.3587C43.5428 38.8273 45.1531 38.8944 46.216 39.5063L48.9053 41.1811C49.4877 41.5193 49.7856 41.9487 49.7856 42.3567C49.7856 42.6949 49.579 43.0169 49.163 43.2585L46.9434 44.5414L43.8686 46.3058C44.8758 46.028 45.9371 45.8263 47.0012 45.7251C51.2138 45.3246 53.8735 46.6516 52.9419 48.6889C52.0103 50.7262 47.8401 52.7025 43.6275 53.103C39.4149 53.5035 36.7552 52.1766 37.6868 50.1392C37.7517 49.9975 37.8322 49.856 37.9273 49.7153L18.1608 61.0584C17.2402 61.5871 15.6325 61.5254 14.567 60.9081L11.8803 59.2333Z'
          fill='#13151B'
        />
      </g>
      <path
        d='M47.0816 8.80862L42.941 6.42165C42.6212 6.17841 42.2376 6.03468 41.8375 6.00822C41.2901 5.96308 40.6655 6.10291 39.9999 6.44862C39.9347 6.48221 39.8701 6.51799 39.8034 6.55872L12.1531 22.6108C12.0869 22.6493 12.0217 22.6889 11.9566 22.7308C11.8915 22.7726 11.8318 22.8128 11.7705 22.8541C11.7092 22.8954 11.6473 22.9411 11.5871 22.9856C11.0406 23.4014 10.548 23.8843 10.1206 24.423C9.57335 25.1032 9.11577 25.8516 8.75924 26.6498C8.34815 27.5653 8.08321 28.5215 8.01478 29.4248C8.0115 29.4711 8.00985 29.5146 8.00821 29.5603C8.00164 29.6566 8 29.7502 8 29.8449V50.6582C8 50.7336 8 50.8096 8.00493 50.8833C8.00493 50.923 8.00821 50.9626 8.0115 51.0011C8.01478 51.0397 8.01861 51.0804 8.0219 51.1189C8.02682 51.174 8.03175 51.2252 8.03832 51.2775C8.14013 52.0597 8.42642 52.6664 8.85066 53.0655C9.02461 53.2306 9.22673 53.3628 9.44732 53.4558L13.8358 56.0107L12.8456 52.6681L39.8012 37.0184C39.8679 36.9794 39.9325 36.9397 39.9977 36.8984L40.1044 36.8285L40.209 36.7564C40.755 36.3678 41.249 35.91 41.6787 35.3945C42.248 34.7212 42.7275 33.9761 43.1053 33.1782C43.53 32.2863 43.8169 31.3466 43.9182 30.4466C43.9247 30.3871 43.9296 30.3282 43.9346 30.2688C43.9379 30.2264 43.9411 30.184 43.9444 30.1394C43.9477 30.0948 43.9494 30.0552 43.951 30.0139C43.9543 29.9363 43.9559 29.8581 43.9559 29.7827V10.1238L47.0816 8.80862Z'
        fill='#4E5869'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M46.8139 37.0278C46.0875 38.1288 45.164 39.0691 44.1585 39.6526L16.6549 55.6221C15.6466 56.2073 14.721 56.3377 13.9946 56.0785C29.001 32.4493 27.0561 35.5112 28.4733 33.2828C29.584 31.5361 31.224 30.5887 32.3303 31.0434C33.7858 31.6423 31.8146 30.8281 46.8139 37.0278Z'
        fill='#4E5869'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.4009 34.9691L13.9946 56.0785L13.9771 56.0725C13.0279 55.7262 12.423 54.7138 12.423 53.1873V32.5116C12.423 31.4657 12.7082 30.3278 13.1932 29.2505C13.2939 29.0259 13.6908 28.2854 13.7696 28.155L13.8676 27.9843L27.4009 34.9691Z'
        fill='#303642'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M48.3904 11.6278V32.3041C48.3904 33.7486 47.8392 35.4667 46.8314 37.002C46.8254 37.0103 46.8194 37.0196 46.8139 37.0279L33.4026 31.4855C34.5735 29.5279 35.8112 27.4437 46.9387 8.7746C47.0597 8.83623 47.1774 8.90422 47.2912 8.97828C47.5872 9.18732 47.8283 9.46561 47.9935 9.78917C48.1103 10.0161 48.1993 10.2564 48.2585 10.5048C48.3498 10.872 48.3942 11.2494 48.3904 11.6278Z'
        fill='#303642'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M47.0887 8.79274C44.7026 12.9435 40.255 20.6835 31.998 35.062C31.1194 36.5902 29.6934 37.4182 28.816 36.9095C28.661 36.8187 18.5566 30.9592 13.7263 28.1615C13.849 27.9534 13.9787 27.7516 14.1156 27.556C14.8173 26.5436 15.6866 25.681 16.6292 25.1338L44.1842 9.1346C45.1334 8.5841 46.0103 8.43822 46.7143 8.64355C46.8437 8.6808 46.9691 8.73077 47.0887 8.79274Z'
        fill='#303642'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M52.9041 40.682C56.8231 34.4549 57.0402 27.523 53.3891 25.1991C49.738 22.8752 43.6013 26.0394 39.6823 32.2665C35.7634 38.4935 35.5462 45.4254 39.1973 47.7493C42.8484 50.0732 48.9851 46.9091 52.9041 40.682Z'
        fill='#0072ED'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M55.8382 26.6296L53.4438 25.2388C53.4438 25.2388 54.5296 26.9175 54.5605 26.8928C54.5913 26.8681 55.8382 26.6296 55.8382 26.6296Z'
        fill='#333131'
      />
      <path
        d='M39.0605 47.6636C39.0993 47.6883 42.0829 49.4405 42.0829 49.4405L41.3828 48.3239L39.0605 47.6636Z'
        fill='#22262E'
      />
      <path
        d='M58.6029 32.6218C58.6029 37.1417 56.0885 42.407 52.5318 45.9752C51.4106 47.0999 50.1889 48.0544 48.9048 48.7665C48.886 48.7774 48.8665 48.7877 48.8477 48.7981C47.7699 49.3894 46.7333 49.7624 45.7687 49.9308C43.4326 50.3423 41.4989 49.5682 40.3497 47.8228C39.6205 46.7154 39.2068 45.2184 39.2068 43.384C39.2068 38.4417 42.2092 32.6103 46.3007 29.0743C47.104 28.3731 47.9768 27.7569 48.9054 27.2353C50.5626 26.3158 52.1198 25.9135 53.4827 25.9681C54.3736 26.0038 55.1816 26.2354 55.8782 26.6491C56.7754 27.1801 57.488 28.0117 57.9594 29.1105C58.3743 30.0829 58.6029 31.2593 58.6029 32.6218Z'
        fill='#80C0FF'
        stroke='#13151B'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.2937 31.3604C54.1879 30.9886 53.9382 30.7679 53.5891 30.7398C53.2399 30.7116 52.8347 30.8777 52.4462 31.211C52.0452 31.5616 51.7328 32.003 51.5347 32.4989L48.5546 39.9261L46.3791 38.3009C46.1185 38.1061 45.7539 38.1021 45.3505 38.2888C44.947 38.4756 44.5602 38.8313 44.2544 39.2911C43.9487 39.7508 43.7624 40.2577 43.7304 40.7203C43.6984 41.1829 43.8247 41.5444 44.0853 41.7398L47.6494 44.4018C47.9351 44.6155 48.3534 44.5977 48.7923 44.3529C48.838 44.3276 48.8837 44.3 48.9323 44.2684C49.4192 43.9472 49.8558 43.3938 50.0986 42.788L54.1416 32.7098C54.3468 32.2121 54.3988 31.7322 54.2937 31.3604Z'
        fill='#0072ED'
      />
      <path
        d='M55.4806 19.528C55.48 18.5785 55.1213 17.6641 54.4761 16.9675C53.8309 16.2708 52.9467 15.8431 52 15.7698C52.9462 15.696 53.8297 15.2681 54.4742 14.5714C55.1187 13.8748 55.4767 12.9607 55.4767 12.0116V12H55.5253V12.0116C55.5253 12.9604 55.883 13.8742 56.5271 14.5708C57.1712 15.2674 58.0542 15.6956 59 15.7698C58.0544 15.8445 57.1716 16.2728 56.5276 16.9693C55.8836 17.6658 55.5257 18.5794 55.5253 19.528V19.5416H55.4767L55.4806 19.528Z'
        fill='#80C0FF'
      />
      <path
        d='M34.8461 57.9928C34.8458 57.4892 34.6555 57.0042 34.3133 56.6347C33.9711 56.2652 33.5021 56.0384 33 55.9995C33.5018 55.9603 33.9704 55.7334 34.3123 55.3639C34.6541 54.9944 34.844 54.5095 34.844 54.0062V54H34.8698V54.0062C34.8698 54.5094 35.0595 54.9941 35.4011 55.3635C35.7427 55.733 36.2111 55.9601 36.7127 55.9995C36.2112 56.0391 35.743 56.2663 35.4014 56.6357C35.0598 57.0051 34.87 57.4897 34.8698 57.9928V58H34.844L34.8461 57.9928Z'
        fill='#80C0FF'
      />
    </svg>
  </SvgIcon>
);

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Locked } from '@swyftx/aviary/icons/outlined';

import { UserStore } from '@shared/store';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { inProgress, isLocked, isVerified } from './VerifyFund.util';

export const VerifyFund: React.FC = observer(() => {
  const { setVerificationStep, jumioTransaction } = useContext(ProfileVerificationContext);
  const { userProfile, isKyc1Complete, isKyc2Complete, isKyc2Required } = UserStore.useUserStore;
  const { t } = useTranslation('profile.verification');

  const locked = isLocked(userProfile, jumioTransaction, isKyc1Complete, isKyc2Required);
  const isInProgress = inProgress(userProfile, jumioTransaction, isKyc1Complete, isKyc2Required);
  const verified = isVerified(userProfile, jumioTransaction, isKyc1Complete, isKyc2Complete, isKyc2Required);

  const BuildChip: React.FC = () => {
    if (locked) return null;

    if (isInProgress) {
      return (
        <Chip id='verificationMethod.verifyFund.buttonLabels.pending' color='info' size='sm' variant='subtle'>
          {t('verificationMethod.verifyFund.buttonLabels.pending')}
        </Chip>
      );
    }

    if (verified) {
      return (
        <Chip id='verificationMethod.verifyFund.buttonLabels.verified' size='sm' color='success' variant='subtle'>
          {t('verificationMethod.verifyFund.buttonLabels.verified')}
        </Chip>
      );
    }

    return null;
  };

  const BuildButton: React.FC = () => {
    if (verified || isInProgress) return null;

    if (locked) {
      return (
        <Button id='verificationMethod.verifyFund.buttons.locked' disabled leadingIcon={<Locked />} color='primary'>
          {t('verificationMethod.verifyFund.buttonLabels.locked')}
        </Button>
      );
    }

    return (
      <Button
        id='verificationMethod.verifyFund.buttons.start'
        onClick={() => setVerificationStep(VerificationStep.FundsSummary)}
        color='primary'
      >
        {t('verificationMethod.verifyFund.buttonLabels.startVerification')}
      </Button>
    );
  };

  return (
    <FlexLayout>
      <BuildChip />
      <BuildButton />
    </FlexLayout>
  );
});

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

type Props = { url: string; imageUrl: string };

export const SecurityImage: React.FC<Props> = ({ url, imageUrl }) => (
  <a href={url} target='_blank' rel='noreferrer'>
    <FlexLayout className='min-w-[200px] max-w-full' alignItems='center' justifyContent='center'>
      <img src={imageUrl} alt={url} className='h-[135px] w-[200px] max-w-full rounded-[4px]' />
    </FlexLayout>
  </a>
);

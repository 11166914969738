import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { api } from '@shared/api';
import {
  RecurringOrder,
  RecurringOrderFrequency,
  RecurringOrderStatus,
  UpdateRecurringOrderData,
} from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';
import { fromLocalToUTCMidnightOrMinInFuture } from '@shared/utils/lib/date';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { DateTime } from 'luxon';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { useAutoInvestCache } from './useAutoInvestCache';
import { useAutoInvestMinimumOrder } from './useAutoInvestMinimumOrder';
import { useAutoInvestTemplate } from './useAutoInvestTemplate';
import { useAutoInvestAllocationCheck } from '../widgets/CreateAutoInvestWidget/hooks/useAutoInvestAllocationCheck';
import { useAutoInvestBalanceCheck } from '../widgets/CreateAutoInvestWidget/hooks/useAutoInvestBalanceCheck';

type Props = {
  autoInvestment: RecurringOrder;
  setOpen?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

const statusToActionMap: { [key in RecurringOrderStatus]: string } = {
  ACTIVE: 'resume',
  PAUSED: 'pause',
  EXPIRED: 'expire',
  PROCESSING: '',
  QUEUED: '',
};

const statusToActionPastMap: { [key in RecurringOrderStatus]: string } = {
  ACTIVE: 'resumed',
  PAUSED: 'paused',
  EXPIRED: 'expired',
  PROCESSING: '',
  QUEUED: '',
};

const useEditAutoInvestment = ({ autoInvestment, setOpen }: Props) => {
  const { invalidateCache } = useAutoInvestCache();
  const [orderName, setOrderName] = useState<string>(autoInvestment.label);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<RecurringOrderSource>(
    autoInvestment.source || RecurringOrderSource.DEPOSIT,
  ); // Default to Bank Deposit if its undefined

  const [startDate, setStartDate] = useState<DateTime | undefined>(
    DateTime.fromMillis(autoInvestment.nextTime || DateTime.now().startOf('day').toMillis()),
  );
  const [frequency, setFrequency] = useState<RecurringOrderFrequency | undefined>(autoInvestment.frequency || 'WEEKLY');
  const [amount, setAmount] = useState<string | undefined>(autoInvestment.orderTotal?.toString());

  const { addToast } = useContext(ToastManagerContext);
  const [updating, setUpdating] = useState<boolean>(false);
  const countryAsset = useCountryAsset();
  const { lowestFee } = useCurrentUserFees();
  const { showBalanceWarning, diffDays, diffDayText } = useAutoInvestBalanceCheck({
    startDate,
    amount,
    selectedPaymentMethod,
    frequency,
  });

  const feeAmount = useMemo(() => {
    if (!amount) return Big(0);
    return Big(amount).times(lowestFee || 0);
  }, [amount, lowestFee]);

  const {
    autoInvestAssets,
    parseTemplate,
    onLock,
    onRemove,
    onAddAsset,
    onUpdatePercentage,
    onUpdateAsset,
    convertToTemplate,
    onBulkUpdateAssets,
  } = useAutoInvestTemplate({
    autoInvestment,
  });

  const { minimumOrderAmount } = useAutoInvestMinimumOrder(autoInvestAssets);

  const { validAssets } = useAutoInvestAllocationCheck({ autoInvestAssets });

  const resetValues = useCallback(() => {
    setOrderName(autoInvestment.label);
    setSelectedPaymentMethod(autoInvestment.source || RecurringOrderSource.DEPOSIT);
    setStartDate(DateTime.fromMillis(autoInvestment.nextTime || DateTime.now().toMillis()));
    setFrequency(autoInvestment.frequency || undefined);
    setAmount(autoInvestment.orderTotal?.toString());
  }, [autoInvestment]);

  const buildUpdateData = useCallback(
    (primaryAssetId: number, status: RecurringOrderStatus = RecurringOrderStatus.Active) => {
      let data: UpdateRecurringOrderData = {
        templateKey: autoInvestment.templateKey,
        label: orderName,
        primaryAssetId,
        template: convertToTemplate(),
        source: selectedPaymentMethod,
        status,
      };

      if (selectedPaymentMethod === RecurringOrderSource.ACCOUNT) {
        data.frequency = frequency;

        if (startDate && startDate.toMillis() !== autoInvestment.nextTime) {
          data.nextTime = fromLocalToUTCMidnightOrMinInFuture(startDate);
          data.nextTimeOffset = DateTime.now().offset;
        }
        data.orderTotal = Number(amount);
      }

      return data;
    },
    [
      amount,
      autoInvestment.nextTime,
      autoInvestment.templateKey,
      convertToTemplate,
      frequency,
      orderName,
      selectedPaymentMethod,
      startDate,
    ],
  );

  const updateRecurringOrder = useCallback(async () => {
    if (!countryAsset) return;

    setUpdating(true);
    try {
      let data: UpdateRecurringOrderData = buildUpdateData(countryAsset.id, autoInvestment.status);

      await api.endpoints.updateRecurringOrderTemplate({ data: data });

      invalidateCache();

      addToast({ title: 'Order successfully updated', type: 'success' });
    } catch (e) {
      addToast({ title: 'Order failed to update. Please try again', type: 'destructive' });
    } finally {
      setUpdating(false);
      if (setOpen) setOpen(false);
    }
  }, [countryAsset, buildUpdateData, autoInvestment.status, invalidateCache, addToast, setOpen]);

  const updateRecurringOrderStatus = useCallback(
    async (status: RecurringOrderStatus) => {
      if (!countryAsset) return;

      setUpdating(true);
      try {
        let data: UpdateRecurringOrderData = buildUpdateData(countryAsset.id, status);

        await api.endpoints.updateRecurringOrderTemplate({ data: data });

        invalidateCache();

        addToast({ title: `Your order has been ${statusToActionPastMap[status]}`, type: 'success' });
      } catch (e) {
        addToast({
          title: `Failed to ${statusToActionMap[status]}`,
          description: `There was an issue while trying to ${statusToActionMap[status]} your order. Please try again.`,
          type: 'destructive',
        });
      } finally {
        setUpdating(false);
        if (setOpen) setOpen(false);
      }
    },
    [countryAsset, buildUpdateData, invalidateCache, addToast, setOpen],
  );

  const validEditOrder = useMemo(() => {
    const fromDate = DateTime.now().startOf('day');
    const toDate = DateTime.now().startOf('day').plus({ months: 2 });

    if (!validAssets.valid) return validAssets;

    if (selectedPaymentMethod === RecurringOrderSource.ACCOUNT) {
      if (!frequency || frequency === 'NEVER') {
        return {
          valid: false,
          tooltip: 'Please select a frequency',
        };
      }

      if (!amount || Big(amount).lt(minimumOrderAmount)) {
        return {
          valid: false,
          tooltip: `Please make sure the investment amount is greater than ${formatCurrency(
            minimumOrderAmount,
            countryAsset,
          )}`,
        };
      }

      if (!startDate) {
        return {
          valid: false,
          tooltip: 'Please select a start date for the order',
        };
      }

      if (startDate < DateTime.now().startOf('day'))
        return {
          valid: false,
          tooltip: `Please select a start date on or after ${fromDate.toFormat('dd/MM/yyyy')}`,
        };

      if (startDate > DateTime.now().startOf('day').plus({ months: 2 }))
        return {
          valid: false,
          tooltip: `Please select a start date on or before ${toDate.toFormat('dd/MM/yyyy')}`,
        };
    }

    return {
      valid: true,
      tooltip: '',
    };
  }, [amount, countryAsset, frequency, minimumOrderAmount, selectedPaymentMethod, startDate, validAssets]);

  const dirty = useMemo(() => {
    let diffAssets = false;

    autoInvestAssets.forEach((a) => {
      const asset = assetService.getAssetByCode(a.assetCode);
      if (!asset) return;

      if (autoInvestment.template.find((t) => t.secondaryAssetId === asset.id)?.percentage !== a.percentage)
        diffAssets = true;
    });

    if (diffAssets) return true;
    if (autoInvestment.label !== orderName) return true;
    if (autoInvestment.source !== selectedPaymentMethod) return true;

    if (selectedPaymentMethod === RecurringOrderSource.ACCOUNT) {
      if (autoInvestment.label !== orderName) return true;
      if (autoInvestment.frequency !== frequency) return true;
      if (!Big(autoInvestment.orderTotal || 0).eq(amount || '0')) return true;
      if (autoInvestment.source !== selectedPaymentMethod) return true;
      if (
        autoInvestment.nextTime &&
        startDate &&
        !DateTime.fromMillis(autoInvestment.nextTime).hasSame(startDate, 'day')
      ) {
        return true;
      }
    }

    return false;
  }, [amount, autoInvestment, frequency, orderName, selectedPaymentMethod, startDate, autoInvestAssets]);

  return {
    orderName,
    feeAmount,
    lowestFeeAmount: `${lowestFee.times(100)}%`,
    setOrderName,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    startDate,
    setStartDate,
    frequency,
    setFrequency,
    amount,
    setAmount,
    minimumOrderAmount,
    updateRecurringOrder,
    updateRecurringOrderStatus,
    updating,
    autoInvestAssets,
    parseTemplate,
    onLock,
    onRemove,
    onAddAsset,
    onUpdatePercentage,
    onUpdateAsset,
    convertToTemplate,
    resetValues,
    validEditOrder,
    showBalanceWarning,
    diffDayText,
    diffDays,
    dirty,
    onBulkUpdateAssets,
  };
};

export { useEditAutoInvestment };

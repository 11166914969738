import { useState, useRef, useEffect, useCallback } from 'react';
import type { InputHTMLAttributes, KeyboardEvent, ChangeEvent, FocusEvent, ClipboardEvent } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { cn } from '@shared/utils/lib/ui';

interface OTPInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'ref' | 'value' | 'onFocus' | 'onBlur' | 'onKeyDown' | 'onPaste' | 'autoComplete' | 'maxLength'
  > {
  value?: string;
  onOtpChange?: (otp: string) => void;
  numInputs?: number;
  error?: boolean; // comes from endpoint
}

export const OTPInput = ({
  value = '',
  numInputs = 6,
  onOtpChange,
  type = 'text',
  placeholder = '',
  pattern = '[0-9]',
  autoFocus = true,
  className,
  id,
  name,
  error = false,
  ...rest
}: OTPInputProps) => {
  const [otpValue, setOTPValue] = useState(value);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const getOTPValue = () => (otpValue ? otpValue.toString().split('') : []);

  const isInputNum = type === 'number' || type === 'tel';

  useEffect(() => {
    if (autoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [autoFocus]);

  const isInputValueValid = (inputValue: string) => {
    const isTypeValid = isInputNum ? !isNaN(Number(inputValue)) : typeof inputValue === 'string';
    return isTypeValid && inputValue.trim().length === 1;
  };

  const focusInput = useCallback(
    (index: number) => {
      const activeInputIndex = Math.max(Math.min(numInputs - 1, index), 0);

      if (inputRefs.current[activeInputIndex]) {
        inputRefs.current[activeInputIndex]?.focus();
        inputRefs.current[activeInputIndex]?.select();
        setActiveInput(activeInputIndex);
      }
    },
    [numInputs],
  );

  useEffect(() => {
    setOTPValue(value);
    if (!value.length) focusInput(0);
  }, [focusInput, value]);

  const handleOTPChange = (otp: string[]) => {
    const otpJoined = otp.join('');
    setOTPValue(otpJoined);
    onOtpChange?.(otpJoined);
  };

  const changeCodeAtFocus = (valueAtFocus: string) => {
    const otp = [...getOTPValue()];
    // eslint-disable-next-line prefer-destructuring
    otp[activeInput] = valueAtFocus[0];
    handleOTPChange(otp);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target;

    // handle password manager autofill
    if (inputValue.length > 1 && inputValue.length <= numInputs) {
      const inputValues = inputValue.split('');
      if (isInputNum && inputValues.some((val: string) => isNaN(Number(val)))) {
        return;
      }

      // Distribute the values across the inputs
      handleOTPChange(inputValues);
      focusInput(inputValues.length - 1);
    } else if (isInputValueValid(inputValue)) {
      changeCodeAtFocus(inputValue);
      focusInput(activeInput + 1);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => (index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const otp = getOTPValue();
    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    } else if (isInputNum && !isInputValueValid(event.key)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const pastedData = event.clipboardData.getData('text/plain').slice(0, numInputs);
    const pastedDataArray = pastedData.split('');

    if (isInputNum && pastedDataArray.some((data: string) => isNaN(Number(data)))) {
      return;
    }

    if (pastedDataArray.length === numInputs) {
      handleOTPChange(pastedDataArray);
      focusInput(numInputs - 1);
    } else {
      const updatedOTP = [...getOTPValue()];
      for (let i = 0; i < pastedDataArray.length; i++) {
        if (activeInput + i < numInputs) {
          updatedOTP[activeInput + i] = pastedDataArray[i];
        }
      }
      handleOTPChange(updatedOTP);
      focusInput(activeInput + pastedDataArray.length);
    }
  };

  return (
    <FlexLayout spacing={8} alignItems='center' justifyContent='space-between' className='w-full'>
      {Array.from({ length: numInputs }, (_, index) => index).map((i) => (
        <Input
          key={i}
          id={`${id}-${i}`}
          name={`otp-digit-${i}`}
          value={getOTPValue()[i] ?? ''}
          placeholder={placeholder}
          ref={(element) => (inputRefs.current[i] = element)}
          onChange={handleChange}
          onFocus={(event) => handleFocus(event)(i)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          autoComplete='one-time-code'
          maxLength={1}
          size={1}
          error={error === true}
          className={cn('h-48 w-48 text-center font-bold', className)}
          containerClassName='h-56 w-56 p-4 text-center'
          pattern={pattern}
          {...rest}
        />
      ))}
      <input type='hidden' id={id} name={name} value={otpValue} />
    </FlexLayout>
  );
};

import React, { useCallback, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { HelpTooltip } from '@swyftx/aviary/molecules/HelpTooltip/HelpTooltip';

import { EntityApplicationForm, EntityType } from '@shared/api';
import { ENTITY_ASSISTANCE_PHONE_CONSULT_URL } from '@shared/constants/urls';
import { cn } from '@shared/utils/lib/ui';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';
import { useStepId } from '@routes/EntityOnboarding/hooks/useStepId';
import { EntityTypeStepContent } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';

import { observer } from 'mobx-react-lite';

import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { ChangeEntityTypeModal } from '../../modals/ChangeEntityTypeModal';

const EntityApplicationTypeStep: React.FC = observer(() => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);
  const [changeTypeModalOpen, setChangeTypeModalOpen] = useState<boolean>(false);
  const [selectedEntityType, setSelectedEntityType] = useState<EntityType | undefined>(applicationData.type);
  const { entityTypeChanged, phoneConsultLinkCtaClicked } = useEntityOnboardingAnalytics();
  const { stepId } = useStepId();

  const handleOpenPhoneConsultLink = useCallback(() => {
    phoneConsultLinkCtaClicked({
      entityType: selectedEntityType,
      stepId,
      callToActionLocation: 'select_entity_type_page',
    });
    window.open(ENTITY_ASSISTANCE_PHONE_CONSULT_URL, '_blank', 'noopener,noreferrer');
  }, [phoneConsultLinkCtaClicked, selectedEntityType, stepId]);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      if (applicationData.type === undefined || applicationData.type === selectedEntityType) {
        return entityOnboardingService.send({
          type: 'NEXT',
          data: { type: selectedEntityType } as Partial<EntityApplicationForm>,
        });
      }
      if (changeTypeModalOpen) {
        setChangeTypeModalOpen(false);
        if (selectedEntityType) {
          entityTypeChanged(selectedEntityType);
        }

        return entityOnboardingService.send({
          type: 'CHANGE_TYPE',
          data: { type: selectedEntityType } as Partial<EntityApplicationForm>,
        });
      }
      setChangeTypeModalOpen(true);
      e.preventDefault();
    },
    [applicationData.type, changeTypeModalOpen, entityOnboardingService, entityTypeChanged, selectedEntityType],
  );

  return (
    <>
      <EntityOnboardingStepContainer
        customTitle="Let's start with the basics"
        onSubmit={(e) => onSubmit(e)}
        submitDisabled={!selectedEntityType}
      >
        <FlexLayout direction='column' spacing={32}>
          <Body color='secondary'>
            Select an entity type below to get started. We are required to collect this information for regulatory
            purposes. Don&apos;t have an existing entity?{' '}
            <BodyLink
              className='decoration cursor-pointer text-color-text-accent underline'
              onClick={handleOpenPhoneConsultLink}
            >
              Book a phone consult.
            </BodyLink>
          </Body>
          <FlexLayout direction='column' spacing={8}>
            <Body size='small' weight='emphasis'>
              Select type of entity:
            </Body>
            <FlexLayout direction='column' spacing={16}>
              {Object.entries(EntityTypeStepContent).map(([key, value]) => {
                const selected = value.types.includes(selectedEntityType ?? '');
                return (
                  <Card
                    className={cn(
                      'cursor-pointer',
                      selected
                        ? 'border-color-text-accent bg-color-background-surface-selected'
                        : 'hover:bg-color-background-surface-hover',
                    )}
                    key={key}
                    onMouseDown={() => setSelectedEntityType(value.types[0] as EntityType)}
                  >
                    <FlexLayout direction='row' className='items-center p-16' spacing={16}>
                      <Checkbox checked={selected} />
                      <FlexLayout direction='column' className='text-start'>
                        <Body weight='emphasis' className='select-none'>
                          {value.title}
                        </Body>
                        <Body color='secondary' className='select-none'>
                          {value.subtitle}
                        </Body>
                      </FlexLayout>
                    </FlexLayout>
                  </Card>
                );
              })}
            </FlexLayout>
            <FlexLayout direction='row' className='mt-8'>
              <HelpTooltip
                tooltipText='We do not support Unit trusts, Not-for-profits or Partnerships.'
                content={
                  <Body
                    size='small'
                    color='secondary'
                    className='inline underline decoration-dashed decoration-1 underline-offset-4'
                  >
                    Don&apos;t see your entity type?
                  </Body>
                }
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </EntityOnboardingStepContainer>
      <ChangeEntityTypeModal
        open={changeTypeModalOpen}
        setOpen={setChangeTypeModalOpen}
        newType={selectedEntityType}
        prevType={applicationData.type}
        onConfirm={(e) => onSubmit(e)}
      />
    </>
  );
});

export { EntityApplicationTypeStep };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Category: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.50001 2.25C5.61184 2.25 4.88649 2.30474 4.30609 2.45021C3.71378 2.59866 3.21858 2.85536 2.86424 3.29473C2.51831 3.72367 2.37926 4.23844 2.31454 4.7518C2.25055 5.25931 2.25001 5.8576 2.25001 6.5L2.25 6.55647C2.24995 7.17492 2.2499 7.75268 2.30842 8.24323C2.36941 8.75449 2.50319 9.27099 2.84699 9.70208C3.19978 10.1445 3.69603 10.4016 4.2902 10.55C4.87239 10.6953 5.60261 10.75 6.50001 10.75C7.3974 10.75 8.12762 10.6953 8.70981 10.55C9.30398 10.4016 9.80023 10.1445 10.153 9.70208C10.4968 9.27099 10.6306 8.75449 10.6916 8.24323C10.7501 7.75268 10.7501 7.17492 10.75 6.55647L10.75 6.5C10.75 5.8576 10.7495 5.25931 10.6855 4.7518C10.6208 4.23844 10.4817 3.72367 10.1358 3.29473C9.78144 2.85536 9.28624 2.59866 8.69392 2.45021C8.11352 2.30474 7.38817 2.25 6.50001 2.25ZM3.75001 6.5C3.75001 5.82979 3.75297 5.33428 3.80276 4.93943C3.8518 4.55042 3.93677 4.35427 4.03185 4.23636C4.11853 4.12889 4.28134 4.00281 4.67077 3.9052C5.07211 3.80461 5.65222 3.75 6.50001 3.75C7.34779 3.75 7.92791 3.80461 8.32925 3.9052C8.71867 4.00281 8.88148 4.12889 8.96816 4.23636C9.06324 4.35427 9.14821 4.55042 9.19726 4.93943C9.24704 5.33428 9.25001 5.82979 9.25001 6.5C9.25001 7.17286 9.24951 7.66863 9.20216 8.06555C9.1555 8.45665 9.07261 8.65107 8.98029 8.76683C8.89697 8.87131 8.73766 8.99694 8.34642 9.09464C7.9432 9.19532 7.35815 9.25 6.50001 9.25C5.64186 9.25 5.05681 9.19532 4.6536 9.09464C4.26235 8.99694 4.10304 8.87131 4.01972 8.76683C3.9274 8.65107 3.84451 8.45665 3.79786 8.06555C3.75051 7.66863 3.75001 7.17286 3.75001 6.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 2.25C16.6118 2.25 15.8865 2.30474 15.3061 2.45021C14.7138 2.59866 14.2186 2.85536 13.8642 3.29473C13.5183 3.72367 13.3793 4.23844 13.3145 4.7518C13.2506 5.25931 13.25 5.8576 13.25 6.5L13.25 6.55647C13.2499 7.17492 13.2499 7.75269 13.3084 8.24323C13.3694 8.75449 13.5032 9.27099 13.847 9.70208C14.1998 10.1445 14.696 10.4016 15.2902 10.55C15.8724 10.6953 16.6026 10.75 17.5 10.75C18.3974 10.75 19.1276 10.6953 19.7098 10.55C20.304 10.4016 20.8002 10.1445 21.153 9.70208C21.4968 9.27099 21.6306 8.75449 21.6916 8.24323C21.7501 7.75268 21.7501 7.17492 21.75 6.55647L21.75 6.5C21.75 5.8576 21.7495 5.25931 21.6855 4.7518C21.6208 4.23844 21.4817 3.72367 21.1358 3.29473C20.7814 2.85536 20.2862 2.59866 19.6939 2.45021C19.1135 2.30474 18.3882 2.25 17.5 2.25ZM14.75 6.5C14.75 5.82979 14.753 5.33428 14.8028 4.93943C14.8518 4.55042 14.9368 4.35427 15.0319 4.23636C15.1185 4.12889 15.2813 4.00281 15.6708 3.9052C16.0721 3.80461 16.6522 3.75 17.5 3.75C18.3478 3.75 18.9279 3.80461 19.3292 3.9052C19.7187 4.00281 19.8815 4.12889 19.9682 4.23636C20.0632 4.35427 20.1482 4.55042 20.1973 4.93943C20.247 5.33428 20.25 5.82979 20.25 6.5C20.25 7.17286 20.2495 7.66863 20.2022 8.06555C20.1555 8.45665 20.0726 8.65107 19.9803 8.76683C19.897 8.87131 19.7377 8.99694 19.3464 9.09464C18.9432 9.19532 18.3582 9.25 17.5 9.25C16.6419 9.25 16.0568 9.19532 15.6536 9.09464C15.2624 8.99694 15.103 8.87131 15.0197 8.76683C14.9274 8.65107 14.8445 8.45665 14.7979 8.06555C14.7505 7.66863 14.75 7.17286 14.75 6.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.30609 13.4502C4.88649 13.3047 5.61184 13.25 6.50001 13.25C7.38817 13.25 8.11352 13.3047 8.69392 13.4502C9.28624 13.5987 9.78144 13.8554 10.1358 14.2947C10.4817 14.7237 10.6208 15.2384 10.6855 15.7518C10.7495 16.2593 10.75 16.8576 10.75 17.5L10.75 17.5565C10.7501 18.1749 10.7501 18.7527 10.6916 19.2432C10.6306 19.7545 10.4968 20.271 10.153 20.7021C9.80023 21.1445 9.30398 21.4016 8.70981 21.55C8.12762 21.6953 7.3974 21.75 6.50001 21.75C5.60261 21.75 4.87239 21.6953 4.2902 21.55C3.69603 21.4016 3.19978 21.1445 2.84699 20.7021C2.50319 20.271 2.36941 19.7545 2.30842 19.2432C2.2499 18.7527 2.24995 18.1749 2.25 17.5565L2.25001 17.5C2.25001 16.8576 2.25055 16.2593 2.31454 15.7518C2.37926 15.2384 2.51831 14.7237 2.86424 14.2947C3.21858 13.8554 3.71378 13.5987 4.30609 13.4502ZM3.80276 15.9394C3.75297 16.3343 3.75001 16.8298 3.75001 17.5C3.75001 18.1729 3.75051 18.6686 3.79786 19.0655C3.84451 19.4567 3.9274 19.6511 4.01972 19.7668C4.10304 19.8713 4.26235 19.9969 4.6536 20.0946C5.05681 20.1953 5.64186 20.25 6.50001 20.25C7.35815 20.25 7.9432 20.1953 8.34642 20.0946C8.73766 19.9969 8.89697 19.8713 8.98029 19.7668C9.07261 19.6511 9.1555 19.4567 9.20216 19.0655C9.24951 18.6686 9.25001 18.1729 9.25001 17.5C9.25001 16.8298 9.24704 16.3343 9.19726 15.9394C9.14821 15.5504 9.06324 15.3543 8.96816 15.2364C8.88148 15.1289 8.71867 15.0028 8.32925 14.9052C7.92791 14.8046 7.34779 14.75 6.50001 14.75C5.65222 14.75 5.07211 14.8046 4.67077 14.9052C4.28134 15.0028 4.11853 15.1289 4.03185 15.2364C3.93677 15.3543 3.8518 15.5504 3.80276 15.9394Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 13.25C16.6118 13.25 15.8865 13.3047 15.3061 13.4502C14.7138 13.5987 14.2186 13.8554 13.8642 14.2947C13.5183 14.7237 13.3793 15.2384 13.3145 15.7518C13.2506 16.2593 13.25 16.8576 13.25 17.5L13.25 17.5565C13.2499 18.1749 13.2499 18.7527 13.3084 19.2432C13.3694 19.7545 13.5032 20.271 13.847 20.7021C14.1998 21.1445 14.696 21.4016 15.2902 21.55C15.8724 21.6953 16.6026 21.75 17.5 21.75C18.3974 21.75 19.1276 21.6953 19.7098 21.55C20.304 21.4016 20.8002 21.1445 21.153 20.7021C21.4968 20.271 21.6306 19.7545 21.6916 19.2432C21.7501 18.7527 21.7501 18.1749 21.75 17.5565L21.75 17.5C21.75 16.8576 21.7495 16.2593 21.6855 15.7518C21.6208 15.2384 21.4817 14.7237 21.1358 14.2947C20.7814 13.8554 20.2862 13.5987 19.6939 13.4502C19.1135 13.3047 18.3882 13.25 17.5 13.25ZM14.75 17.5C14.75 16.8298 14.753 16.3343 14.8028 15.9394C14.8518 15.5504 14.9368 15.3543 15.0319 15.2364C15.1185 15.1289 15.2813 15.0028 15.6708 14.9052C16.0721 14.8046 16.6522 14.75 17.5 14.75C18.3478 14.75 18.9279 14.8046 19.3292 14.9052C19.7187 15.0028 19.8815 15.1289 19.9682 15.2364C20.0632 15.3543 20.1482 15.5504 20.1973 15.9394C20.247 16.3343 20.25 16.8298 20.25 17.5C20.25 18.1729 20.2495 18.6686 20.2022 19.0655C20.1555 19.4567 20.0726 19.6511 19.9803 19.7668C19.897 19.8713 19.7377 19.9969 19.3464 20.0946C18.9432 20.1953 18.3582 20.25 17.5 20.25C16.6419 20.25 16.0568 20.1953 15.6536 20.0946C15.2624 19.9969 15.103 19.8713 15.0197 19.7668C14.9274 19.6511 14.8445 19.4567 14.7979 19.0655C14.7505 18.6686 14.75 18.1729 14.75 17.5Z'
      fill='currentColor'
    />
  </svg>
);

export { Category };

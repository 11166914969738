import { useEffect } from 'react';

import { ratesService } from '@shared/services';
import { RatesStore } from '@shared/store';

/** Run live rates update interval and return ratesStore */
export const useLiveRates = () => {
  // TODO filter on assetId
  useEffect(() => {
    ratesService.init();
    return () => {
      ratesService.cleanup();
    };
  }, []);

  return RatesStore.useRatesStore;
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/react-web-design-system';

import { EntityAddMemberWizard } from '@global-components/Modals/EntityAddMemberModal/EntityAddMemberWizard';

import entityService from '@shared/services/entityService';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

type Props = {
  isAdmin: boolean;
};

const EntityMembersActionItem: React.FC<Props> = ({ isAdmin }) => {
  const { t } = useTranslation('profile');
  const { isLargeContent } = useContentBreakpoint();
  const [addMember, setAddMember] = useState(false);
  const isEntityVerified = entityService.isEntityVerified();

  return (
    <>
      {isLargeContent && !isEntityVerified && isAdmin && (
        <Button variant='text' onClick={() => setAddMember(true)}>
          {t('entities.entityMembers.button.addMember')}
        </Button>
      )}

      {addMember && <EntityAddMemberWizard open onClose={() => setAddMember(false)} />}
    </>
  );
};

export { EntityMembersActionItem };

import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ScrollView } from '@swyftx/aviary/atoms/ScrollView';
import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { UserBalance } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

import { DustableAssetItem } from './DustableAssetItem';
import { useDustingCache } from '../../hooks/useDustingCache';
import { useMarkets } from '../../hooks/useMarkets';

type Props = {
  onClose?: () => void;
};

const DustableAssets: React.FC<Props> = observer(({ onClose }) => {
  const { balances } = UserStore.useUserStore;
  const { getAssetById } = useMarkets();
  const { getMinimumOrderAmount, convertRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const { addToast } = useContext(ToastManagerContext);
  const { invalidateCache } = useDustingCache();

  const dustableAssets = useMemo(
    (): UserBalance[] =>
      Object.values(balances).filter((balance: UserBalance) => {
        const asset = assetService.getAsset(balance.assetId);

        if (!asset || !asset.sellEnabled || asset.delisting) return false;

        return (
          balance.assetId !== baseAsset?.id &&
          Big(balance.availableBalance).gt(0) &&
          Big(balance.availableBalance).lt(getMinimumOrderAmount(balance.assetId))
        );
      }),
    [balances, baseAsset?.id, getMinimumOrderAmount],
  );

  const [assetsToDust, setAssetsToDust] = useState<number[]>(dustableAssets.map((d) => d.assetId));

  const toggleAssetToDust = useCallback(
    (id: number) => {
      const newAssetsToDust = [...assetsToDust];
      if (assetsToDust.includes(id)) {
        newAssetsToDust.splice(newAssetsToDust.indexOf(id), 1);
      } else {
        newAssetsToDust.push(id);
      }

      setAssetsToDust(newAssetsToDust);
    },
    [assetsToDust],
  );

  const dustableAssetsValue = useMemo(() => {
    if (!baseAsset) return Big(0);

    let value = Big(0);

    assetsToDust.forEach((a) => {
      value = value.plus(Big(convertRate(a, baseAsset, balances[a].availableBalance, 'bidPrice')));
    });

    return value;
  }, [baseAsset, assetsToDust, convertRate, balances]);

  const dustAssets = useCallback(async () => {
    if (!baseAsset) return;

    try {
      await UserService.DustBalance(assetsToDust, baseAsset.id);
      invalidateCache();
      addToast({
        title: `${assetsToDust.length} Asset${assetsToDust.length !== 1 ? 's' : ''} successfully dusted`,
        type: 'success',
      });
    } catch (e) {
      const { errorMessage } = e as SwyftxError;
      addToast({ title: errorMessage, type: 'destructive' });
    } finally {
      if (onClose) onClose();
    }
  }, [addToast, assetsToDust, baseAsset, invalidateCache, onClose]);

  return (
    <>
      <FlexLayout
        direction='column'
        className='max-h-[80vh] min-h-[80vh] px-24 pb-8 sm:max-h-[400px] sm:min-h-[400px] sm:pb-16'
        justifyContent='space-between'
        spacing={24}
      >
        <ScrollView className='pr-8'>
          {dustableAssets.map((dust) => (
            <DustableAssetItem
              key={dust.assetId}
              selected={assetsToDust.includes(dust.assetId)}
              balance={dust.availableBalance}
              asset={getAssetById(dust.assetId)}
              onSelect={() => toggleAssetToDust(dust.assetId)}
            />
          ))}
        </ScrollView>
        <Button size='lg' disabled={assetsToDust.length === 0} onClick={dustAssets}>
          Dust sell assets{' '}
          {assetsToDust.length > 0 ? `for ${formatCurrency(dustableAssetsValue?.toString(), baseAsset)}` : ''}
        </Button>
      </FlexLayout>
    </>
  );
});

export { DustableAssets };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const HighContrast: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9999 21.9996C6.47778 21.9996 1.99994 17.5229 1.99994 11.9996C1.99994 6.47742 6.47773 1.99963 11.9999 1.99963C17.5222 1.99963 21.9999 6.47742 21.9999 11.9996C21.9999 17.5229 17.5221 21.9996 11.9999 21.9996ZM12.7499 20.467C17.0926 20.0873 20.4999 16.4416 20.4999 11.9996C20.4999 7.55857 17.0926 3.91208 12.7499 3.53228V20.467Z'
      fill='currentColor'
    />
  </svg>
);

export { HighContrast };

import { useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';

import { RecurringOrder, RecurringOrderStatus } from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { DateTime } from 'luxon';

type Props = {
  autoInvestment: RecurringOrder;
};

const useAutoInvestOrderItem = ({ autoInvestment }: Props) => {
  const countryAsset = useCountryAsset();

  const fundingMethod = useMemo(() => {
    switch (autoInvestment.source) {
      case RecurringOrderSource.ACCOUNT:
        return `${countryAsset?.code} Wallet`;
      case RecurringOrderSource.DEPOSIT:
      default:
        return 'Bank transfer';
    }
  }, [autoInvestment.source, countryAsset]);

  const totalInvested = useMemo(
    () =>
      autoInvestment.template !== undefined
        ? autoInvestment.template.reduce<Big>((prev, curr) => Big(prev).plus(curr.total), Big(0))
        : Big(0),
    [autoInvestment.template],
  );

  const isPaused = useMemo(() => autoInvestment.status === RecurringOrderStatus.Paused, [autoInvestment.status]);

  const isProcessing = useMemo(() => {
    if (!autoInvestment.nextTime || isPaused) return false;
    if ([RecurringOrderStatus.Processing, RecurringOrderStatus.Queued].includes(autoInvestment.status)) return true;

    return false;
  }, [autoInvestment.nextTime, autoInvestment.status, isPaused]);

  const nextScheduleTime = useMemo(() => {
    if (!autoInvestment.nextTime || isPaused) return '-';

    const nextDateTime = DateTime.fromMillis(autoInvestment.nextTime);

    return `${nextDateTime.toFormat('dd/MM/yyyy h:mm a')} to ${nextDateTime.plus({ hour: 1 }).toFormat('h:mm a')}`;
  }, [autoInvestment.nextTime, isPaused]);

  const statusChip = useMemo(() => {
    if (isProcessing) {
      return (
        <Chip size='sm' color='primary' variant='subtle'>
          Processing
        </Chip>
      );
    }

    // Backwards compatible with the API before Investing from funds was available
    if (!autoInvestment.status) {
      return (
        <Chip size='sm' color='success' variant='subtle'>
          Active
        </Chip>
      );
    }

    switch (autoInvestment.status) {
      case RecurringOrderStatus.Active:
        return (
          <Chip size='sm' color='success' variant='subtle'>
            Active
          </Chip>
        );
      case RecurringOrderStatus.Paused:
        return (
          <Chip size='sm' color='warning' variant='subtle'>
            Paused
          </Chip>
        );
      case RecurringOrderStatus.Processing:
      case RecurringOrderStatus.Queued:
        return (
          <Chip size='sm' color='primary' variant='subtle'>
            Processing
          </Chip>
        );
      case RecurringOrderStatus.Expired:
        return (
          <Chip size='sm' color='destructive' variant='subtle'>
            Expired
          </Chip>
        );
      default:
        return (
          <Chip size='sm' color='secondary' variant='subtle'>
            Unknown
          </Chip>
        );
    }
  }, [autoInvestment.status, isProcessing]);

  return {
    fundingMethod,
    totalInvested,
    nextScheduleTime,
    statusChip,
    isProcessing,
    isPaused,
  };
};

export { useAutoInvestOrderItem };

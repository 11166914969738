import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const SendCryptoFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path d='M9 15L3 21' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    <path d='M7 11L3 15' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    <path d='M13 17L11 19' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 8C22 11.3139 19.3133 14 16 14C12.6867 14 10 11.3139 10 8C10 4.6867 12.6867 2 16 2C19.3133 2 22 4.6867 22 8ZM13.8094 7.62242C13.6142 7.81769 13.6142 8.13427 13.8094 8.32953L15.6479 10.168C15.8432 10.3633 16.1597 10.3633 16.355 10.168L18.1935 8.32953C18.3887 8.13427 18.3887 7.81769 18.1935 7.62242L16.355 5.78395C16.1597 5.58868 15.8432 5.58868 15.6479 5.78395L13.8094 7.62242Z'
      fill='currentColor'
    />
  </svg>
);

export { SendCryptoFilled };

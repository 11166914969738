import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { Image } from '@swyftx/aviary/atoms/Image';
import { Card, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';
import { swyftxRewardsRelease } from 'src/config/releases';

type Props = {
  showLearnMore?: boolean;
};

const WhatIsSwyftxRewards: React.FC<Props> = observer(({ showLearnMore }) => {
  const { bx } = useContentBreakpoint();
  const { openModal } = useModal();

  const showLearnMoreModal = useCallback(() => {
    openModal(Modals.WhatsNewModal, { customRelease: swyftxRewardsRelease });
  }, [openModal]);

  useEffect(() => {
    if (showLearnMore) showLearnMoreModal();
  }, [showLearnMore, showLearnMoreModal]);

  const { t } = useTranslation('rewards', { keyPrefix: 'whatIsSwyftxRewards' });
  return (
    <Box width='100%' height='unset'>
      <Card contentSx={{ height: '100%' }} sx={{ height: '100%' }} variant='outlined'>
        <Stack
          height='100%'
          alignItems='center'
          justifyContent={bx({ xs: 'center', sm: 'space-between', xl: 'center' })}
          spacing={2}
          direction={bx({ xs: 'column', sm: 'row', xl: 'column' })}
        >
          <Stack order={bx({ xs: 1, sm: 2, xl: 1 })}>
            <Image image='rewards' usePalette={false} className='w-128 h-128' alt='rewards' />
          </Stack>
          <Stack
            order={bx({ xs: 2, sm: 1, xl: 2 })}
            fontSize={18}
            alignItems={bx({ xs: 'center', sm: 'flex-start', xl: 'center' })}
            spacing={1}
          >
            <Typography fontWeight={600}>{t('title')}</Typography>
            <Typography color='text.secondary' fontSize={14} textAlign={bx({ xs: 'left', xl: 'center' })}>
              {t('content')}
            </Typography>
            <Typography color='primary' fontSize={14} sx={{ cursor: 'pointer' }} onClick={showLearnMoreModal}>
              {t('learnMore')}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
});

export { WhatIsSwyftxRewards };

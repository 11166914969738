import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { formatCurrency } from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useCurrentPrice } from '@hooks/Trade/useCurrentPrice';

import { observer } from 'mobx-react-lite';

interface Props {
  assetId: number;
  side?: 'Buy' | 'Sell' | 'Unknown';
  baseAsset: Asset | undefined;
}

export const CurrentPrice: React.FC<Props> = observer((props) => {
  const { assetId, side, baseAsset } = props;
  const asset = assetService.getAsset(assetId);
  const { loading } = RatesStore.useRatesStore;

  const priceSide = useMemo(() => {
    if (!side) return 'midPrice';
    return side === 'Buy' ? 'askPrice' : 'bidPrice';
  }, [side]);

  const currentPrice = useCurrentPrice(baseAsset, asset, priceSide);

  if (!asset || side === 'Unknown' || !baseAsset || !currentPrice) return null;

  return (
    <FlexLayout direction='row' spacing={4}>
      <Numeric size='small' loading={loading}>
        {formatCurrency(currentPrice, { isFiat: baseAsset.assetType === AssetType.Fiat })}
      </Numeric>
      <Numeric size='small' color='secondary' className='block @xl:hidden'>
        {baseAsset.code}
      </Numeric>
      <Numeric size='small' color='secondary' className='hidden truncate @xl:block'>
        {`${baseAsset.code}/${asset.code}`}
      </Numeric>
    </FlexLayout>
  );
});

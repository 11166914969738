import React from 'react';

import MenuMUI, { MenuProps } from '@mui/material/Menu';

const Menu: React.FC<MenuProps> = ({ id, children, ...props }) => (
  <MenuMUI id={id} {...props}>
    {children}
  </MenuMUI>
);

export { Menu };

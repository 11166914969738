import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { LineChartTooltipData, LineChartTooltipPosition } from '@swyftx/aviary/atoms/Charts/LineChart/LineChart.types';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import { assetService } from '@shared/services';
import { formatCurrency, formatDateTime } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  tooltipInfo: {
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  };
};

const PortfolioPerformanceCardLineChartTooltip: React.FC<Props> = observer(({ tooltipInfo: { position, data } }) => {
  const baseAsset = useBaseAsset();

  if (!data || !position || !baseAsset) return null;

  if (data.overlays.length > 0) {
    return (
      <Card
        className='pointer-events-none z-tooltip rounded-[8px]'
        style={{ position: 'absolute', left: position.x + 16, top: position.y - 32 }}
      >
        <FlexLayout direction='column' className='h-full w-full' spacing={0} alignItems='start'>
          <FlexLayout direction='column' className='h-full w-full p-8' spacing={8} alignItems='start'>
            <Numeric size='xsmall' color='secondary' className='truncate'>
              {formatDateTime(data.main.time)}
            </Numeric>
            <Numeric size='medium' weight='emphasis' className='truncate'>
              {formatCurrency(data.main.value, baseAsset, { hideCode: true, appendCode: false })} {baseAsset.code}
            </Numeric>
          </FlexLayout>
          <div className='h-1 w-full bg-color-border-main' />

          <FlexLayout direction='column' className='w-full p-12' spacing={8}>
            {data.overlays.map((overlay) => {
              const asset = assetService.getAsset(Number(overlay.id));

              if (!asset) return null;

              return (
                <FlexLayout spacing={8} alignItems='center' justifyContent='space-between' key={asset.code}>
                  <FlexLayout spacing={8} className='mr-12' alignItems='center'>
                    <div className='h-[20px] min-w-[4px] rounded-[2px]' style={{ backgroundColor: asset.color }} />
                    <Body size='xsmall'>{asset.code}</Body>
                  </FlexLayout>

                  <Numeric size='xsmall'>
                    {formatCurrency(overlay.value, baseAsset, { hideCode: true, appendCode: false })} {baseAsset.code}
                  </Numeric>
                </FlexLayout>
              );
            })}
          </FlexLayout>
        </FlexLayout>
      </Card>
    );
  }

  return (
    <Card
      className='pointer-events-none z-tooltip rounded-[8px] p-8'
      style={{ position: 'absolute', left: position.x + 16, top: position.y - 32 }}
    >
      <FlexLayout className='h-full w-full' spacing={4} alignItems='start' direction='column'>
        <Numeric size='xsmall' color='secondary' className='truncate'>
          {formatDateTime(data.main.time)}
        </Numeric>
        <FlexLayout className='w-full' alignItems='center' spacing={8}>
          <div className='h-[24px] min-w-[4px] rounded-[2px]' style={{ backgroundColor: baseAsset.color || 'blue' }} />
          <Numeric size='medium' weight='emphasis' className='truncate'>
            {formatCurrency(data.main.value, baseAsset, { hideCode: true, appendCode: false })} {baseAsset.code}
          </Numeric>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
});

export { PortfolioPerformanceCardLineChartTooltip };

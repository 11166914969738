import React from 'react';

import { GreenIdStatusEnum } from '@shared/enums';

import { useOnboardingService } from '@Onboarding/Onboarding.context';

import { GreenIDFrame } from '../components/GreenIDFrame';

const IdVerificationInProgress: React.FC = () => {
  const onboardingService = useOnboardingService();

  const handleSuccess = (greenIdStatus?: GreenIdStatusEnum) => {
    if (greenIdStatus === GreenIdStatusEnum.PENDING_REVIEW) {
      onboardingService.send('ID_VERIFICATION_IN_REVIEW');
      return;
    }
    onboardingService.send('ID_VERIFICATION_COMPLETE');
  };

  const handleCancel = () => {
    onboardingService.send('SAVE_AND_EXIT');
  };

  const handleError = () => {
    onboardingService.send('VERIFICATION_ERROR');
  };

  return <GreenIDFrame onSuccess={handleSuccess} onCancel={handleCancel} onError={handleError} />;
};

export { IdVerificationInProgress };

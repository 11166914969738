import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { AppHeaderTransferButton } from '@global-components/AppHeader/AppHeaderTransferButton';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';
import { cn } from '@shared/utils/lib/ui';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { TableName } from 'src/context/Avo/generated-avo';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { OpenOrders } from './OpenOrders';
import { OrderHistory } from './OrderHistory';
import { OrdersTileActions } from './OrdersTileActions';
import { PortfolioBreakdown } from './PortfolioBreakdown';
import { ordersTabs, OrdersTileTabs } from './ordersTile.types';

interface Props {
  className?: string;
  selectedAsset: Asset;
  setSelectedAsset: (asset: Asset) => void;
  tabVariant?: 'parent' | 'child' | 'default' | 'header';
  minimized?: boolean;
  setMinimized?: (minimized?: boolean) => void;
}

const LOG_TAG = 'UNIVERSAL_TRADE_PAGE_WALLETS_TABLE';

export const OrdersTile: React.FC<Props> = observer((props) => {
  const { className, setSelectedAsset, selectedAsset, minimized, setMinimized, tabVariant = 'child' } = props;
  const [selectedTab, setSelectedTab] = useState<OrdersTileTabs>(OrdersTileTabs.Wallets);
  const [filterCurrentAsset, setFilterCurrentAsset] = useLocalStorage<boolean>(
    StorageKey.SHOW_CURRENT_ASSET_ONLY,
    false,
  );
  const [hideCancelled, setHideCancelled] = useLocalStorage<boolean>(StorageKey.HIDE_CANCELLED_ORDERS, false);
  const { openModal } = useModal();
  const avo = useAvo();
  const { pathname } = useLocation();
  const { changedFilter } = useTradePageAnalytics();
  const { isSwyftxPro } = useSwyftxPro();

  const depositCrypto = useCallback(() => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive, { selectedAsset });
  }, [avo, openModal, pathname, selectedAsset]);

  const withdrawCrypto = useCallback(() => {
    avo.withdrawFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.WithdrawSend, { selectedAsset });
  }, [avo, openModal, pathname, selectedAsset]);

  const content = useMemo(() => {
    switch (selectedTab) {
      case OrdersTileTabs.Wallets:
        return <PortfolioBreakdown setSelectedAsset={setSelectedAsset} />;
      case OrdersTileTabs.OpenOrders:
        return (
          <OpenOrders
            selectedAsset={selectedAsset}
            filterCurrentAsset={filterCurrentAsset}
            hideCancelled={hideCancelled}
          />
        );
      case OrdersTileTabs.OrderHistory:
        return (
          <OrderHistory
            selectedAsset={selectedAsset}
            filterCurrentAsset={filterCurrentAsset}
            hideCancelled={hideCancelled}
          />
        );
      default:
        selectedTab satisfies never;
    }
  }, [filterCurrentAsset, hideCancelled, selectedAsset, selectedTab, setSelectedAsset]);

  const getTableName = useCallback(
    (table?: OrdersTileTabs) => {
      switch (table || selectedTab) {
        case OrdersTileTabs.Wallets:
          return TableName.WALLETS;
        case OrdersTileTabs.OpenOrders:
          return TableName.OPEN_ORDERS;
        case OrdersTileTabs.OrderHistory:
        default:
          return TableName.ORDER_HISTORY;
      }
    },
    [selectedTab],
  );

  const handleToggleFilterCurrentAsset = useCallback(() => {
    const newValue = !filterCurrentAsset;
    setFilterCurrentAsset(newValue);
    changedFilter({ filterName: 'currentAsset', filterEnabled: newValue, tableName: getTableName() });
  }, [changedFilter, getTableName, filterCurrentAsset, setFilterCurrentAsset]);

  const handleToggleHideCancelled = useCallback(() => {
    const newValue = !hideCancelled;
    setHideCancelled(newValue);
    changedFilter({ filterName: 'hideCancelled', filterEnabled: newValue, tableName: getTableName() });
  }, [hideCancelled, setHideCancelled, changedFilter, getTableName]);

  const handleSwitchTab = useCallback(
    (newTab: OrdersTileTabs) => {
      setSelectedTab(newTab);
      changedFilter({ filterName: 'ordersTile', filterEnabled: true, tableName: getTableName(newTab) });
    },
    [changedFilter, getTableName],
  );

  const DepositWithdrawButtons: React.FC = () => (
    <>
      <Button onClick={depositCrypto} variant={isSwyftxPro ? 'ghost' : 'filled'} className='hidden @md:flex'>
        Deposit
      </Button>
      <Button variant={isSwyftxPro ? 'ghost' : 'outlined'} onClick={withdrawCrypto} className='hidden @md:flex'>
        Withdraw
      </Button>
      <div className={cn('flex', isSwyftxPro ? '@sm:hidden' : '@md:hidden')}>
        <AppHeaderTransferButton />
      </div>
    </>
  );

  return (
    <FlexLayout
      className={cn('h-full w-full overflow-hidden', className)}
      direction='column'
      spacing={0}
      data-spotlightelementId='orders-tile'
    >
      <FlexLayout direction='column' className='h-full'>
        <FlexLayout direction='row' className='w-full items-center justify-between p-12'>
          <EnhancedTabs<OrdersTileTabs>
            variant={tabVariant}
            tabs={ordersTabs}
            onChange={handleSwitchTab}
            value={selectedTab}
          />
          <FlexLayout direction='row' spacing={8} className='items-center'>
            {selectedTab === OrdersTileTabs.Wallets && <DepositWithdrawButtons />}
            {(isSwyftxPro || [OrdersTileTabs.OpenOrders, OrdersTileTabs.OrderHistory].includes(selectedTab)) && (
              <OrdersTileActions
                hideCancelled={hideCancelled || false}
                toggleHideCancelled={handleToggleHideCancelled}
                filterCurrentAsset={filterCurrentAsset || false}
                selectedTab={selectedTab}
                toggleFilterCurrentAsset={handleToggleFilterCurrentAsset}
              />
            )}
            <Button
              variant={isSwyftxPro ? 'ghost' : 'outlined'}
              layout='icon'
              leadingIcon={minimized ? <ArrowChevronUp /> : <ArrowChevronDown />}
              onClick={() => setMinimized && setMinimized(!minimized)}
            />
          </FlexLayout>
        </FlexLayout>
        <Separator />
        <FlexLayout className='min-h-2/5 max-h-2/5 block h-full w-full overflow-hidden @container'>
          {content}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { api } from '@shared/api';
import { GreenIdData } from '@shared/api/@types/verification';
import env from '@shared/config';
import { GreenIdStatusEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import UserService from '@services/UserService';
import countries from '@utils/countries/countries';

import { useInterpret, useSelector } from '@xstate/react';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { assign, InterpreterFrom, Subscribable } from 'xstate';

import { onboardingMachine } from './Onboarding.machine';
import { OnboardingStepId, RequiredVerificationDataKeys } from './types/Onboarding.types';
import {
  transformOnboardingPayloadToVerificationData,
  transformVerificationDataToOnboardingPayload,
} from './utils/transforms';

export const OnboardingContext = createContext<{
  onboardingService: InterpreterFrom<typeof onboardingMachine>;
}>({
  // @ts-ignore placeholder
  onboardingService: {},
});

const hasCompletedVerificationFunc = (greenIdStatus: GreenIdStatusEnum | undefined) =>
  greenIdStatus === GreenIdStatusEnum.VERIFIED || greenIdStatus === GreenIdStatusEnum.VERIFIED_UNDERAGE;

type Props = {
  children: React.ReactNode;
};

const OnboardingProvider: React.FC<Props> = ({ children }) => {
  const { navigate } = useNavigateRoute();
  const avo = useAvo();

  // User profile should always be available as this is authenticated route
  const userProfile = useMemo(() => UserStore.useUserStore.userProfile!, []);
  const hasGreenId = () => Boolean(userProfile.verification?.greenid?.id);
  const { isNZ } = UserStore.useUserStore;

  const onboardingService = useInterpret(onboardingMachine, {
    devTools: env.ENABLE_XSTATE_INSPECT,
    // Initial context state based on existing user profile data
    context: {
      verificationData: {
        givenNames: userProfile.name.first,
        surname: userProfile.name.last,
        country: userProfile.address?.country,
      },
      completedSteps: {
        createAccount: true,
        emailVerification: Boolean(userProfile.verification?.email),
        mobileVerification: Boolean(userProfile.verification?.phone),
        idVerification: hasCompletedVerificationFunc(userProfile.verification?.greenid.status),
      },
    },
    actions: {
      prefillProfileData: assign((context, event) => {
        const prefilledVerificationData = transformOnboardingPayloadToVerificationData(event.data);
        return {
          verificationData: {
            ...context.verificationData,
            ...prefilledVerificationData,
          },
          completedSteps: {
            ...context.completedSteps,
            'setupProfile.nameAndDob': Boolean(
              (prefilledVerificationData.givenNames &&
                prefilledVerificationData.surname &&
                prefilledVerificationData.dob) ||
                hasGreenId(),
            ),
            'setupProfile.address': Boolean(
              (prefilledVerificationData.country &&
                prefilledVerificationData.citizenshipCountry &&
                prefilledVerificationData.address) ||
                hasGreenId(),
            ),
            'setupProfile.sourceOfWealth': Boolean(prefilledVerificationData.sourceOfWealth || hasGreenId()),
            'setupProfile.purpose': Boolean(prefilledVerificationData.accountPurpose || hasGreenId()),
          },
        };
      }),
      completeEmailVerification: assign((context) => ({
        completedSteps: {
          ...context.completedSteps,
          emailVerification: true,
        },
      })),
      processSendMobileCodeResponse: assign((context, event) => ({
        completedSteps: {
          ...context.completedSteps,
          mobileVerification: event.data.phoneVerified,
        },
      })),
      completeMobileVerification: assign((context) => ({
        completedSteps: {
          ...context.completedSteps,
          mobileVerification: true,
        },
      })),
      completeStep: assign((context, event) => ({
        completedSteps: {
          ...context.completedSteps,
          [event.stepId]: true,
        },
      })),
      completeVerification: assign((context) => ({
        completedSteps: {
          ...context.completedSteps,
          idVerification: true,
        },
      })),
      assignVerificationData: assign((context, event) => ({
        verificationData: {
          ...context.verificationData,
          ...event.data,
        },
      })),
      setMobileInputCode: assign((context, event) => ({
        mobileCodeVerificationData: {
          ...context.mobileCodeVerificationData,
          userInputCode: event.code,
        },
      })),
      setMobileInputCodeError: assign((context, event) => ({
        mobileCodeVerificationData: {
          ...context.mobileCodeVerificationData,
          error: (event.data as SwyftxError).errorMessage,
        },
      })),
      clearMobileInputCodeError: assign((context) => ({
        mobileCodeVerificationData: {
          ...context.mobileCodeVerificationData,
          error: '',
        },
      })),
      setEmailInputCode: assign((context, event) => ({
        emailCodeVerificationData: {
          ...context.emailCodeVerificationData,
          userInputCode: event.code,
        },
      })),
      setEmailInputCodeError: assign((context, event) => ({
        emailCodeVerificationData: {
          ...context.emailCodeVerificationData,
          error: (event.data as SwyftxError).errorMessage,
        },
      })),
      clearEmailInputCodeError: assign((context) => ({
        emailCodeVerificationData: {
          ...context.emailCodeVerificationData,
          error: '',
        },
      })),
      syncVerificationData: (_, event) => {
        if (!event.data) return;

        api.endpoints.syncOnboardingData({ data: transformVerificationDataToOnboardingPayload(event.data) });
      },
      redirectToDashboard: () => {
        navigate(NavigationURLs.Dashboard);
      },
      completeQuickIntro: assign((context) => ({
        completedSteps: {
          ...context.completedSteps,
          quickIntro: true,
        },
      })),
    },
    services: {
      fetchOnboardingData: async () => {
        const response = await api.endpoints.getOnboardingData();
        return response.data;
      },

      setupGreenID: async (context) => {
        const {
          givenNames,
          middleNames,
          surname,
          country,
          citizenshipCountry,
          dualCitizenshipCountry,
          address,
          accountPurpose,
          sourceOfWealth,
          dob,
        } = context.verificationData;
        const { setGreenIdInfo } = UserStore.useUserStore;

        const greenIdData: GreenIdData = {
          accountId: env.GREEN_ID_ACCOUNT_ID!,
          apiCode: env.GREEN_ID_API_KEY!,
          givenNames: givenNames ?? '',
          middleNames: middleNames ?? '',
          surname: surname ?? '',
          ruleId: (country ? countries[country].ruleSet : '') ?? '',
          email: userProfile?.email ?? '',
          citizenship: citizenshipCountry,
          citizenshipDual: dualCitizenshipCountry,
          purposeOfAccount: accountPurpose!,
          sourceOfWealth: sourceOfWealth!,
          dob: {
            day: dob?.day ?? 0,
            month: dob?.month ?? 0,
            year: dob?.year ?? 0,
          },
          ...address!,
        };
        const result = await api.endpoints.setupNewGreenID({ data: { ...greenIdData } });
        setGreenIdInfo(result.data.greenIdRef, GreenIdStatusEnum.IN_PROGRESS);

        return result?.data?.greenIdRef;
      },
      sendMobileCode: async () => UserService.RequestPhoneVerification(),
      verifyMobileCode: async (context) => {
        await UserService.CheckPhoneVerification(context.mobileCodeVerificationData.userInputCode);
        await UserService.GetUserProfile();
      },
      sendEmailCode: async () => {
        await UserService.VerifyEmailOTP(userProfile?.email);
      },
      verifyEmailCode: async (context) => {
        await UserService.VerifyEmailOTP(userProfile?.email, context.emailCodeVerificationData.userInputCode);
        await UserService.GetUserProfile();
      },
    },
    guards: {
      hasNotCompletedEmailStep: (context) => !context.completedSteps.emailVerification,
      hasNotCompletedMobileStep: (context) => !context.completedSteps.mobileVerification,
      shouldShowQuickIntro: (context) => !context.completedSteps['setupProfile.nameAndDob'] && !hasGreenId(),
      noGreenIDRef: () => !hasGreenId(),
      hasNotCompletedSetupProfileNameAndDob: (context) =>
        !context.completedSteps['setupProfile.nameAndDob'] && !hasGreenId(),
      hasNotCompletedSetupProfileAddress: (context) => !context.completedSteps['setupProfile.address'] && !hasGreenId(),
      hasNotCompletedSetupProfileSourceOfWealth: (context) =>
        !context.completedSteps['setupProfile.sourceOfWealth'] && !hasGreenId(),
      hasNotCompletedSetupProfilePurpose: (context) => !context.completedSteps['setupProfile.purpose'] && !hasGreenId(),
      isVerificationInReview: () => {
        const greenIdStatus = userProfile.verification?.greenid.status;
        return greenIdStatus === GreenIdStatusEnum.PENDING_REVIEW;
      },
      isVerificationErrored: () => {
        const greenIdStatus = userProfile.verification?.greenid.status;
        return greenIdStatus === GreenIdStatusEnum.FAILED || greenIdStatus === GreenIdStatusEnum.FAILED_DUPLICATE;
      },
      hasCompletedVerification: (context) => Boolean(context.completedSteps.idVerification),
      isNzResidentOrEntitySignUp: () => isNZ() || userProfile.userSettings?.applyForEntityAccount === true,
      isNotNzResident: () => !isNZ(),
    },
  });

  useEffect(() => {
    onboardingService.onTransition((state, event) => {
      if (event.type === 'NEXT') {
        const stepId = state.history?.toStrings()?.slice(0, 2).slice(-1)[0] as OnboardingStepId;
        if (stepId && !state.context.completedSteps[stepId]) {
          onboardingService.send({ type: 'COMPLETE_STEP', stepId });
          avo.onboardingWizardStepComplete({
            screen: NavigationURLs.Onboarding,
            stepId,
          });
        }
      }
    });

    onboardingService.onStop(() => {
      const stepId = onboardingService.state.toStrings()?.slice(0, 2).slice(-1)[0];
      avo.uxFlowExited({
        screen: NavigationURLs.Onboarding,
        stepId,
        featureName: 'onboarding',
        modalName: null,
      });
    });
  }, [avo, onboardingService]);

  const value = useMemo(
    () => ({
      onboardingService,
    }),
    [onboardingService],
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export { OnboardingProvider };

export const useOnboardingService = () => {
  const { onboardingService } = useContext(OnboardingContext);
  return onboardingService;
};

export const useOnboardingSelector = <
  T,
  TEmitted = InterpreterFrom<typeof onboardingMachine> extends Subscribable<infer Emitted> ? Emitted : never,
>(
  selector: (emitted: TEmitted) => T,
) => {
  const onboardingService = useOnboardingService();
  const value = useSelector(onboardingService, selector);

  return value;
};

export const useOnboardingAccountProgress = () => {
  const verificationData = useOnboardingSelector((state) => state.context.verificationData);
  const completedSteps = useOnboardingSelector((state) => state.context.completedSteps);

  let completed = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of RequiredVerificationDataKeys) {
    const data = verificationData[key];

    if (data != null && data !== '') {
      completed += 1;
    }
  }

  const mobileCompleted = completedSteps.mobileVerification ? 0.1 : 0;
  const emailCompleted = completedSteps.emailVerification ? 0.1 : 0;
  const profileCompleted = (completed / RequiredVerificationDataKeys.length) * 0.6;
  const verificationCompleted = completedSteps.idVerification ? 0.2 : 0;

  const rawTotal = mobileCompleted + emailCompleted + profileCompleted + verificationCompleted;
  return Math.ceil(rawTotal * 100);
};

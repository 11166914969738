/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { SetupMFAEnrolmentResponse } from '@shared/api/@types/auth';
import env from '@shared/config';
import { SwyftxError } from '@shared/error-handler';
import { UIStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';
import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import AuthenticationService from '@services/AuthenticationService';

type Props = {
  onClose: () => void;
  onAction: () => void;
  setRecoveryCode: (recoveryCode: string) => void;
};

export const EnableTwoFactorVerifyStep: React.FC<Props> = ({ onClose, onAction, setRecoveryCode }) => {
  const { t } = useTranslation('profile');
  const logout = useLogout();
  const { t: securityT } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const [error, setError] = useState<string>('');
  const [mfa, setMfa] = useState<SetupMFAEnrolmentResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [enabling, setEnabling] = useState<boolean>(false);
  const { addToastMessage, addMessageBox } = UIStore.useUIStore;
  const [code, setCode] = useState<string>('');

  const getMfaDetails = async () => {
    try {
      setLoading(true);
      const mfaToken = await AuthenticationService.SetupMFA();
      setMfa(mfaToken);
      setRecoveryCode(mfaToken?.recoveryCode || '');
      setLoading(false);

      // FIXME: forces the Auth0 flow by signing the user out
      // on next login, the user will be prompted to setup MFA via Auth0
      logout();
    } catch (e) {
      const { errorMessage } = e as SwyftxError;
      addToastMessage({
        severity: 'error',
        message: errorMessage,
      });
    }
  };

  useEffect(() => {
    getMfaDetails();
  }, []);

  const enableMfa = useCallback(async () => {
    if (!mfa) return;

    try {
      setEnabling(true);
      const res = await AuthenticationService.CompleteMFASetup(code, mfa.mfaToken);
      setEnabling(false);

      if (res.error) {
        return;
      }

      if (res.success === true) {
        addMessageBox({
          content: <SuccessMessageBox title={securityT('enable.labels.success')} />,
        });
      }
    } catch (e) {
      const { errorMessage } = e as SwyftxError;
      setError(errorMessage);
    } finally {
      setEnabling(false);
      onAction();
    }
  }, [code, mfa, addMessageBox, securityT, onAction]);

  useEffect(() => {
    if (code.length === 6) enableMfa();
  }, [code, enableMfa]);

  const copySecret = () => {
    navigator.clipboard.writeText(loading || !mfa ? '' : mfa.secret);
    addMessageBox({ content: <InformationMessageBox title={t('smsVerification.copiedToClipboard')} /> });
  };

  const handlePasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setCode(text);
    addMessageBox({ content: <InformationMessageBox title={t('smsVerification.pastedFromClipboard')} /> });
  };

  return (
    <Wizard.Step
      PII
      actionName={securityT('enable.buttonLabels.action')}
      title={securityT('enable.title')}
      actionLoading={enabling}
      locked={!code || !mfa}
      onAction={onAction}
      onClose={onClose}
      hideCancel
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Button variant='text' onClick={logout} loading={loading} disabled={loading}>
            You should be redirected soon. Click here to continue if you are not.
          </Button>
        </Stack>
      </Stack>
    </Wizard.Step>
  );
};

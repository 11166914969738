import {
  GeneratePresignedUrlPayload,
  GetDocumentUploadUrlResponse,
  GetEmploymentTypesResponse,
  GetIndustryResponse,
  GetOccupationResponse,
  GetSowDocumentsResponse,
  SourceOfWealthPayload,
  SourceOfWealthResponse,
  SourceOfWealthStatus,
  SourceOfWealthVerificationDto,
} from '@shared/api/@types/compliance';
import env from '@shared/config';

import { SourceOfWealthData } from '@routes/SourceOfWealth/types/SourceOfWealth.types';

import { Methods, request } from '../rest';

type Headers = Record<string, string>;

const host = env.GEN2_HOST;

const complianceEndpoints = {
  saveSourceOfWealthFormData: ({
    headers,
    data,
  }: {
    headers?: Headers;
    data: SourceOfWealthPayload;
  }): SourceOfWealthResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/form',
      method: Methods.PUT,
      headers,
      data,
      host,
      auth: true,
    }),

  getSourceOfWealthStatus: ({ headers }: { headers?: Headers } = {}): SourceOfWealthStatus =>
    request({
      path: '/compliance/sourceOfWealth/v1/status',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  getSourceOfWealthHistory: ({ headers }: { headers?: Headers } = {}): SourceOfWealthVerificationDto[] =>
    request({
      path: '/compliance/sourceOfWealth/v1/history',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  getSourceOfWealthFormData: ({ headers }: { headers?: Headers } = {}): SourceOfWealthResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/form',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  submitSourceOfWealthForm: ({ headers, data }: { headers?: Headers; data: Partial<SourceOfWealthData> }) =>
    request({
      path: '/compliance/sourceOfWealth/v1/form',
      method: Methods.POST,
      headers,
      host,
      data,
      auth: true,
    }),

  getOccupations: ({ headers }: { headers?: Headers } = {}): GetOccupationResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/occupations',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  getEmploymentTypes: ({ headers }: { headers?: Headers } = {}): GetEmploymentTypesResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/employmentTypes',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  getIndustries: ({ headers }: { headers?: Headers } = {}): GetIndustryResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/industries',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  getSowDocuments: ({ headers }: { headers?: Headers } = {}): GetSowDocumentsResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/documents',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  generateDocumentUploadUrl: ({
    headers,
    data,
  }: {
    headers?: Headers;
    data: GeneratePresignedUrlPayload;
  }): GetDocumentUploadUrlResponse =>
    request({
      path: '/compliance/sourceOfWealth/v1/documents/upload',
      method: Methods.POST,
      headers,
      host,
      data,
      auth: true,
    }),

  processUploadedDocument: ({ headers, params }: { headers?: Headers; params: { id: string } }) =>
    request({
      path: '/compliance/sourceOfWealth/v1/documents/:id',
      method: Methods.POST,
      headers,
      params,
      host,
      auth: true,
    }),

  deleteUploadedDocument: ({ headers, params }: { headers?: Headers; params: { id: string } }) =>
    request({
      path: '/compliance/sourceOfWealth/v1/documents/:id',
      method: Methods.DELETE,
      headers,
      params,
      host,
      auth: true,
    }),
};

export default complianceEndpoints;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const SwyftxFull: React.FC<Props> = ({ className }) => (
  <svg viewBox='0 0 86 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <g clipPath='url(#clip0_4856_21591)'>
      <path
        d='M7.10857 16.0286C6.53714 17.8743 4.04571 21.9258 2.98857 23.24L2.58857 23.7829C2.58857 23.7829 2.20571 24.3543 3.52571 23.6286C4.49714 23.1086 9.74286 19.5772 11.32 14.44C11.32 14.44 12.9543 14.1429 13.72 12.7886C13.72 12.7886 13.2457 11.9886 11.6971 11.7658C11.6971 11.7658 11.8971 9.57147 11.1657 8.17147C10.88 7.6229 6.93714 2.01719 3 0.00576046C2.92571 -0.0285253 2.85143 0.0514747 2.89143 0.120046C3.41143 1.02862 5.30857 4.37147 6.58857 7.13719C6.58857 7.13719 8.05714 9.96576 7.70857 11.4058C7.08 14.0115 0 13.7715 0 13.7715L3.05714 14.6286C3.05714 14.6286 0.451429 16.3086 0.468571 16.3086L4.61714 15.1029C4.61714 15.1029 8.72572 14.9372 11.1657 14.4743L7.10857 16.0286Z'
        fill='currentColor'
      />
      <path
        d='M39.96 19.0058L37.1428 10.5944L34.3257 19.0058H31.6171L27.6343 7.11438H29.8686L32.9771 16.5087L36.0743 7.11438H38.2114L41.32 16.5087L44.5028 7.36581L45.5714 10.343L42.6686 19.0058H39.96Z'
        fill='currentColor'
      />
      <path
        d='M19.7086 19.0058V17.08H22.6057C23.52 17.08 24.64 17.04 25.2114 16.7029C25.5257 16.5143 25.7371 16.1829 25.7829 15.7943C25.8343 15.3829 25.6914 14.9886 25.4057 14.7086C25.2114 14.52 24.76 14.3429 24.0686 14.1829L22.2114 13.7486C21.1543 13.4972 20.3657 13.0972 19.8629 12.56C19.3543 12.0172 19.0914 11.3258 19.0914 10.5029C19.0914 9.83433 19.2857 9.22861 19.6629 8.71433C20.7771 7.20576 22.6514 7.16576 24.3086 7.13147H26.1257L26.7714 9.05147C26.16 9.05147 24.1257 9.04576 23.9314 9.04576C23.0686 9.04576 22.0286 9.08004 21.6343 9.34861C21.2286 9.6229 21.04 9.94861 21.04 10.3715C21.04 11.2686 21.8743 11.5658 22.9714 11.8572L24.6286 12.2629C25.6571 12.5143 26.44 12.9029 26.9543 13.4229C27.6571 14.1372 27.7314 15.04 27.7314 15.6572C27.72 16.3029 27.5257 16.8858 27.16 17.3829C26.7486 17.9372 26.2171 18.3658 25.6171 18.6172C24.72 18.9943 23.7257 19.0286 22.8629 19.0286H19.7143V19.0058H19.7086Z'
        fill='currentColor'
      />
      <path
        d='M45.9771 23.9944V21.9944H48.3886C48.9943 21.9944 49.5486 21.8401 50.2743 19.943C50.3143 19.8458 50.3486 19.743 50.3886 19.6401L50.4 19.6058L45.9371 7.11438H48.0571L51.44 16.583L54.5486 7.11438H56.64L53.3028 17.3258C53.2343 17.5315 52.6571 19.3087 52.1371 20.6572C51.0743 23.4287 49.8 23.9944 48.2057 23.9944H45.9714H45.9771Z'
        fill='currentColor'
      />
      <path
        d='M59.9314 19.0172V8.94292H57.56L58.1543 7.12007H59.9257V6.39435C59.9257 4.94864 60.2971 3.82864 61.0343 3.06864C61.7714 2.30864 62.84 1.92578 64.2114 1.92578C64.5143 1.92578 65.4686 2.0115 65.7714 2.04007V3.81721C65.4629 3.8115 64.56 3.79435 64.4343 3.79435C63.64 3.8115 62.68 4.18864 62.36 4.88578C62.1943 5.24007 62.1029 5.7715 62.0857 6.46292V7.12007H64.9543V8.94292H62.0857V19.0172H59.9257H59.9314Z'
        fill='currentColor'
      />
      <path
        d='M72.3943 19.0058C71.0971 19.0058 70.0686 18.6172 69.3371 17.84C68.3029 16.7486 68.3029 15.3372 68.3086 15.0629V8.93148H66.3943V7.10862H68.3086V3.89148H70.4229V7.10862H73.7314L74.9714 8.93148H70.4229V15.1201C70.4229 15.1201 70.4114 15.9086 70.88 16.3943C71.2 16.7201 71.7086 16.8858 72.3943 16.8858H74.8286L73.3829 19.0001H72.3943V19.0058Z'
        fill='currentColor'
      />
      <path
        d='M83.6229 19.0058L80.5086 14.4515L77.3943 19.0058H75.1429L79.3715 12.8172L75.4857 7.11438H77.76L85.8857 19.0058H83.6229Z'
        fill='currentColor'
      />
      <path d='M82.5371 11.5658L85.6629 7.11438H83.4114L81.4229 9.92581L82.5371 11.5658Z' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='clip0_4856_21591'>
        <rect width='85.8857' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export { SwyftxFull };

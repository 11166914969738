import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { types } from '@swyftx/marketing-utils';
import { Skeleton, Stack, Typography } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { useTheme } from '@hooks/useTheme';
import { RewardsContext } from '@routes/Rewards/Rewards.context';
import { RewardType } from '@routes/Rewards/types';

import { DateTime, Duration } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useInterval } from 'react-use';

type Props = {
  reward: types.UserAssetReward;
};

const FONT_SIZE = 16;

const RewardCardCountdown: React.FC<Props> = observer(({ reward }) => {
  const [diff, setDiff] = useState<Duration | undefined>(undefined);
  const [dateToUse, setDateToUse] = useState<DateTime>();
  const { t } = useTranslation('rewards', { keyPrefix: 'countdown' });
  const { fetchRewards } = useContext(RewardsContext);
  const { theme } = useTheme();
  const { userProfile } = UserStore.useUserStore;

  useEffect(() => {
    const { endDate } = reward;
    let newDateToUse: DateTime | undefined;

    if (!reward.redemptions.length) {
      if (reward.status !== types.AssetRewardStatus.Expired) {
        newDateToUse = endDate ? DateTime.fromJSDate(endDate) : undefined;
      }
    } else {
      const { expiresAt, status } = reward.redemptions[0];

      if (status !== types.AssetRedemptionStatus.Expired) {
        newDateToUse = expiresAt ? DateTime.fromJSDate(expiresAt) : undefined;
      }
    }

    setDateToUse(newDateToUse);
    if (newDateToUse) {
      setDiff(newDateToUse.diffNow(['days', 'hours', 'minutes', 'seconds']));
    }
  }, [reward]);

  const onLoop = useCallback(() => {
    if (dateToUse) setDiff(dateToUse.diffNow(['days', 'hours', 'minutes', 'seconds']));
  }, [dateToUse]);

  useInterval(onLoop, 1000);

  const title = useMemo(() => {
    if (!reward.redemptions.length) return t('timeToOptIn');

    const { status } = reward.redemptions[0];

    if (status === types.AssetRedemptionStatus.InProgress) {
      return t('completeIn');
    }

    return t(reward.hasPayout ? 'timeToRedeem' : 'timeToSubmit');
  }, [reward, t]);

  useEffect(() => {
    if (!diff) return;

    if (diff.seconds <= 0) {
      if (userProfile && userProfile.intercom?.uuid) {
        fetchRewards(userProfile.intercom.uuid, RewardType.Available);
        fetchRewards(userProfile.intercom.uuid, RewardType.History);
      }
    }
  }, [diff, userProfile, fetchRewards]);

  if (!dateToUse) return null;

  return (
    <Stack
      direction='row'
      alignItems='center'
      flexWrap='wrap'
      spacing={0}
      bgcolor={theme.palette.mode === 'light' ? 'grey.50' : 'grey.00'}
      width='100%'
      padding={1}
    >
      <Typography color='text.secondary' fontSize={FONT_SIZE} number whiteSpace='nowrap'>
        {title}
      </Typography>
      <Box paddingY={0} paddingX={0.5} display='flex' justifyContent='flex-end'>
        {diff && (
          <Typography color='primary' number fontSize={FONT_SIZE}>
            {Math.floor(diff.days)}
          </Typography>
        )}
        {!diff && <Skeleton width='20px' variant='text' />}
      </Box>
      <Typography color='text.secondary' number fontSize={FONT_SIZE}>
        {t('days')}
      </Typography>
      <Box paddingY={0} paddingX={0.5} display='flex' justifyContent='flex-end'>
        {diff && (
          <Typography color='primary' number fontSize={FONT_SIZE}>
            {Math.floor(diff.hours)}
          </Typography>
        )}
        {!diff && <Skeleton width='20px' variant='text' />}
      </Box>
      <Typography color='text.secondary' number fontSize={FONT_SIZE}>
        {t('hours')}
      </Typography>
      <Box paddingY={0} paddingX={0.5} display='flex' justifyContent='flex-end'>
        {diff && (
          <Typography color='primary' number fontSize={FONT_SIZE}>
            {Math.floor(diff.minutes)}
          </Typography>
        )}
        {!diff && <Skeleton width='20px' variant='text' />}
      </Box>
      <Typography color='text.secondary' number fontSize={FONT_SIZE}>
        {t('minutes')}
      </Typography>
      <Box paddingY={0} paddingX={0.5} display='flex' justifyContent='flex-end'>
        {diff && (
          <Typography color='primary' number fontSize={FONT_SIZE}>
            {Math.floor(diff.seconds)}
          </Typography>
        )}
        {!diff && <Skeleton width='20px' variant='text' />}
      </Box>
      <Typography color='text.secondary' number fontSize={FONT_SIZE}>
        {t('seconds')}
      </Typography>
    </Stack>
  );
});

export { RewardCardCountdown };

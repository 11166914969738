import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Email: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M6.80778 8.41486C6.48406 8.15644 6.01215 8.20938 5.75373 8.5331C5.49531 8.85681 5.54825 9.32873 5.87196 9.58715L10.174 13.0214L10.1792 13.0255C11.2581 13.8714 12.7718 13.8715 13.8507 13.0255L18.1237 9.58519C18.4464 9.32542 18.4973 8.85329 18.2376 8.53066C17.9778 8.20802 17.5057 8.15705 17.183 8.41682L12.9217 11.8477C12.3873 12.2643 11.6412 12.264 11.107 11.8469L6.80778 8.41486Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.27264 2.68994C3.86163 2.68994 1.63477 5.48249 1.63477 8.73406V15.2619C1.63477 18.5135 3.86163 21.3061 7.27264 21.3061L16.7006 21.3061L16.709 21.306C18.275 21.2884 19.7645 20.6264 20.8309 19.4756C21.892 18.3306 22.4423 16.8012 22.3567 15.2407V8.7553C22.4423 7.19478 21.892 5.66536 20.8309 4.52035C19.7645 3.36961 18.275 2.70751 16.709 2.68994H7.27264ZM3.13477 8.73406C3.13477 6.14829 4.84383 4.18994 7.27264 4.18994H16.6962C17.8475 4.20396 18.9441 4.69112 19.7307 5.53994C20.5184 6.38991 20.9263 7.5283 20.858 8.69007C20.8572 8.70472 20.8567 8.71939 20.8567 8.73406V15.2619C20.8567 15.2766 20.8572 15.2913 20.858 15.3059C20.9263 16.4677 20.5184 17.6061 19.7307 18.4561C18.9441 19.3049 17.8475 19.792 16.6962 19.8061H7.27264C4.84383 19.8061 3.13477 17.8477 3.13477 15.2619V8.73406Z'
      fill='currentColor'
    />
  </svg>
);

export { Email };

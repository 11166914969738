import env from '@shared/config';

import {
  Candle,
  Candles,
  ChartBarsParams,
  ChartBarsQuery,
  ChartsMultiBarsQuery,
  LatestBarQuery,
  MultiBarParams,
  ResolveChartSymbolData,
  ResolveChartSymbolParams,
} from '../../@types/charts';
import { Methods, request } from '../rest';

const host = env.CHART_HOST;
const urlPrefix = '/charts/v3';

const chartsEndpoints = {
  getChartSettings: () => request({ path: '/settings/', method: Methods.GET, auth: true, host }),

  resolveChartSymbol: ({ params }: { params: ResolveChartSymbolParams }): ResolveChartSymbolData =>
    request({
      path: `${urlPrefix}/resolveSymbol/:primary/:secondary/`,
      method: Methods.GET,
      params,
      auth: true,
      host,
      hideErrorToast: true,
    }),

  getChartBars: ({ query, params }: { params: ChartBarsParams; query: ChartBarsQuery }): Candles =>
    request({
      path: `${urlPrefix}/getBars/:primary/:secondary/:side/`,
      method: Methods.GET,
      params,
      query,
      auth: true,
      host,
      hideErrorToast: true,
    }),

  getChartLatestBar: ({ query, params }: { params: ChartBarsParams; query: LatestBarQuery }): Candle =>
    request({
      path: `${urlPrefix}/getLatestBar/:primary/:secondary/:side/`,
      method: Methods.GET,
      params,
      host,
      query,
      auth: true,
      ignoreErrors: true,
      hideErrorToast: true,
    }),
  getChartMultiBars: ({
    query,
    params,
  }: {
    params: MultiBarParams;
    query: ChartsMultiBarsQuery;
  }): { candles: Record<string, Candle[]> } =>
    request({
      path: `${urlPrefix}/getMultiBars/:primary/:side/`,
      method: Methods.GET,
      params,
      query,
      auth: true,
      host,
      hideErrorToast: true,
    }),
};

export default chartsEndpoints;

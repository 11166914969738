import React from 'react';

import { TabItem } from '@swyftx/aviary/atoms/Tabs';

import { CountriesEnum } from '@shared/enums';

import ct from 'countries-and-timezones';

export const CODE_LENGTH = 6;
export const INITIAL_CODE_TIMEOUT = 30;

export type FormStepProps = {
  captchaToken: string;
  setCaptchaToken: (token: string) => void;
  captchaRefresh: boolean;
  setCaptchaRefresh: (refresh: boolean) => void;
  formData: SignUpFormValues;
  setFormData: React.Dispatch<React.SetStateAction<SignUpFormValues>>;
};

export type CommonFormValues = {
  captchaToken: string;
};

export type CreateAccountFormValues = {
  country?: CountriesEnum;
  firstName: string;
  lastName: string;
  countryCode: CountriesEnum;
  mobileNumber: string;
  mobileNumberCountry: CountriesEnum;
  email: string;
  applyForEntityAccount?: boolean;
} & CommonFormValues;

export type SetPasswordFormValues = {
  password: string;
  confirmPassword: string;
  referralCode: string;
} & CommonFormValues;

export type SignUpFormValues = {
  verifiedPhone?: boolean;
  verifiedEmail?: boolean;
  applyForEntityAccount?: boolean;
} & CreateAccountFormValues &
  SetPasswordFormValues;

export enum RegisterStepType {
  CreateAccountForm = 'CreateAccountForm',
  VerifyPhoneNumber = 'VerifyPhoneNumber',
  VerifyEmailAddress = 'VerifyEmailAddress',
  SetPasswordForm = 'SetPasswordForm',
}

export type RegisterStepsConfig = Array<{ type: RegisterStepType }>;

export enum AffiliatesEnum {
  Finder = 'finder',
}

export type AffiliateType = {
  name: string;
  miniLogo: JSX.Element;
  logo: JSX.Element;
  bigLogo: JSX.Element;
  registerLogo: JSX.Element;
  component: JSX.Element;
};

export enum RegisterFieldEnum {
  Country = 'country',
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
  Password = 'password',
  Referral = 'referral',
}

type RegisterStateType = {
  referralCode: string;
  promoRef: string;
  affiliate?: AffiliatesEnum;
  country: CountriesEnum;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  password: string;
  passwordConfirmation: string;
  termsAccepted: boolean;
  registerStepsConfig: RegisterStepsConfig;
  registerStep: number;
  error: string;
  hasDuplicateInfo: boolean;
  emailCode: string;
  emailCodeSent: boolean;
  phoneCode: string;
  phoneCodeSent: boolean;
  submitting: boolean;

  // useRecaptcha variables
  captchaToken: string;
  captchaRefresh: boolean;
};

type RegisterActionType = {
  setReferralCode: (referralCode: string) => void;
  setPromoRef: (promoRef: string) => void;
  setAffiliate: (affiliate?: AffiliatesEnum) => void;
  setCountry: (country: CountriesEnum) => void;
  setEmail: (email: string) => void;
  setEmailVerified: (emailVerified: boolean) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setPhoneNumberVerified: (phoneNumberVerified: boolean) => void;
  setPassword: (password: string) => void;
  setPasswordConfirmation: (passwordConfirmation: string) => void;
  setTermsAccepted: (termsAccepted: boolean) => void;
  resetRegisterStep: () => void;
  setRegisterStep: (registerStep: number) => void;
  nextRegisterStep: () => void;
  prevRegisterStep: () => void;
  isFirstStep: () => boolean;
  isFinalStep: () => boolean;
  isComplete: () => boolean;
  setError: (error: string) => void;
  setHasDuplicateInfo: (hasDuplicateInfo: boolean) => void;
  setEmailCode: (newCode: string[]) => void;
  setEmailCodeSent: (emailCodeSent: boolean) => void;
  setPhoneCode: (newCode: string[]) => void;
  setPhoneCodeSent: (phoneCodeSent: boolean) => void;
  setSubmitting: (submitting: boolean) => void;
  getPhoneNumber: (phoneNumber: string, country?: CountriesEnum) => string;
  createAccount: (formData: SignUpFormValues) => Promise<boolean>;
  handleBack: () => void;
  handleSendCode: (formData: SignUpFormValues, isPhone: boolean) => Promise<boolean>;
  handleVerifyCode: (formData: SignUpFormValues, isPhone: boolean) => void;
  cleanup: () => void;
  setCaptchaToken: (token: string) => void;
  setCaptchaRefresh: (refresh: boolean) => void;
};

interface RegisterStoreSchema extends RegisterStateType, RegisterActionType {}

const initialValues: RegisterStoreSchema = {
  referralCode: '',
  promoRef: '',
  affiliate: undefined,
  country:
    (ct.getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0]?.id as CountriesEnum) ||
    CountriesEnum.AU,
  email: new URLSearchParams(window.location.search).get('email') ?? '',
  emailVerified: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneNumberVerified: false,
  password: '',
  passwordConfirmation: '',
  error: '',
  hasDuplicateInfo: false,
  termsAccepted: false,
  registerStepsConfig: [],
  registerStep: 0,
  submitting: false,
  emailCode: '',
  emailCodeSent: false,
  phoneCode: '',
  phoneCodeSent: false,
  captchaToken: '',
  captchaRefresh: false,
  setReferralCode: () => {},
  setPromoRef: () => {},
  setAffiliate: () => {},
  setCountry: () => {},
  setEmail: () => {},
  setEmailVerified: () => {},
  setFirstName: () => {},
  setLastName: () => {},
  setPhoneNumber: () => {},
  setPhoneNumberVerified: () => {},
  setPassword: () => {},
  setPasswordConfirmation: () => {},
  setTermsAccepted: () => {},
  resetRegisterStep: () => {},
  setRegisterStep: () => {},
  nextRegisterStep: () => {},
  prevRegisterStep: () => {},
  isFirstStep: () => true,
  isFinalStep: () => false,
  isComplete: () => false,
  setError: () => {},
  setHasDuplicateInfo: () => {},
  setEmailCode: () => {},
  setEmailCodeSent: () => {},
  setPhoneCode: () => {},
  setPhoneCodeSent: () => {},
  setSubmitting: () => {},
  getPhoneNumber: () => '',
  createAccount: async () => false,
  handleBack: () => {},
  handleSendCode: async () => true,
  handleVerifyCode: () => {},
  cleanup: () => {},
  setCaptchaRefresh: () => {},
  setCaptchaToken: () => {},
};

export type AccountType = 'personal' | 'entity';

export type AccountTypeTab = TabItem<AccountType>;

export const accountTypeTabs: AccountTypeTab[] = [
  {
    title: 'Personal',
    value: 'personal',
    tooltip: 'For individuals.',
  },
  {
    title: 'Entity',
    value: 'entity',
    tooltip: 'For companies, SMSFs and trusts',
  },
];

export { initialValues };
export type { RegisterStoreSchema };

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Image } from '@swyftx/aviary/atoms/Image';
import { Copy } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { useAvo } from '@hooks/Avo/useAvo';
import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import { ProfileContext } from '@routes/Profile/Profile.context';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

const COPIED_TIMEOUT = 5000;

const ReferAFriendTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const { inView } = useInView();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [copied, setCopied] = useState<boolean>(false);
  const { referralCode, fetchReferralCode } = useContext(ProfileContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  const { t } = useTranslation('dashboard');
  const { pathname } = useLocation();
  const avo = useAvo();

  useEffect(() => {
    if (inView && isInitialLoad) {
      avo.viewedReferAFriendDashboardTile({
        screen: pathname,
      });
      setIsInitialLoad(false);
    }
  }, [avo, inView, isInitialLoad, pathname]);

  const copyReferralLink = async () => {
    if (!referralCode || copied) return;

    try {
      await navigator.clipboard.writeText(referralCode.link);
      setCopied(true);
      avo.copiedReferAFriendLink({
        screen: pathname,
      });
    } catch (e) {
      setCopied(false);
    }
  };

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  useEffect(() => {
    fetchReferralCode();
  }, []);

  if (!isFeatureEnabled(AppFeature.ReferAFriend)) return null;

  return (
    <DashboardBaseTile
      tileName={tile.name}
      title={t('referTile.title')}
      headerSx={{ paddingX: 2, paddingTop: 2 }}
      contentSx={{ padding: 0, height: '100%' }}
      dragProps={dragProps}
    >
      <Stack
        direction='column'
        alignItems='flex-start'
        justifyContent='space-between'
        height='100%'
        spacing={1}
        padding={2}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
          <Typography fontWeight='400' fontSize={14} color='text.secondary'>
            {t('referTile.subTitle')}
          </Typography>
          <Image image='refer_a_friend' className='h-[80px] w-[80px]' usePalette alt='refer a friend' />
        </Stack>
        <Button
          disabled={!referralCode || hasClipboardPermission !== 'granted'}
          startIcon={<Copy className='mr-[5px] h-20 w-20 text-color-text-primary' />}
          variant='text'
          icon
          onClick={copyReferralLink}
        >
          {copied ? 'Copied to clipboard!' : 'Copy your unique referral link'}
        </Button>
      </Stack>
    </DashboardBaseTile>
  );
});

ReferAFriendTile.displayName = 'ReferAFriendTile';

export { ReferAFriendTile };

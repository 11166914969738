/* eslint-disable react/jsx-props-no-spreading */
import React, {useEffect, useState} from 'react';

import {SvgIconProps} from '@mui/material/SvgIcon';

import {observer} from 'mobx-react-lite';

import {Icon} from './Icon';

const FRAMES = [
    <>
        <circle cx='10' cy='10' r='2.5' fill='currentColor'/>
        <path
            opacity='0.4'
            d='M5.93837 7.0835C5.34782 7.90443 5 8.91168 5 10.0002C5 11.0886 5.34782 12.0959 5.93837 12.9168M14.0616 7.0835C14.6522 7.90443 15 8.91168 15 10.0002C15 11.0886 14.6522 12.0959 14.0616 12.9168'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
        <path
            opacity='0.4'
            d='M16.4862 5.8335C17.2596 7.03492 17.7083 8.46515 17.7083 10.0002C17.7083 11.5352 17.2596 12.9654 16.4862 14.1668M3.51369 14.1668C2.74031 12.9654 2.29163 11.5352 2.29163 10.0002C2.29163 8.46515 2.74031 7.03492 3.51369 5.8335'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
    </>,
    <>
        <circle cx='10' cy='10' r='2.5' fill='currentColor'/>
        <path
            opacity='0.4'
            d='M5.93837 7.0835C5.34782 7.90443 5 8.91168 5 10.0002C5 11.0886 5.34782 12.0959 5.93837 12.9168M14.0616 7.0835C14.6522 7.90443 15 8.91168 15 10.0002C15 11.0886 14.6522 12.0959 14.0616 12.9168'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
        <path
            opacity='0.4'
            d='M16.4862 5.8335C17.2596 7.03492 17.7083 8.46515 17.7083 10.0002C17.7083 11.5352 17.2596 12.9654 16.4862 14.1668M3.51369 14.1668C2.74031 12.9654 2.29163 11.5352 2.29163 10.0002C2.29163 8.46515 2.74031 7.03492 3.51369 5.8335'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
    </>,
    <>
        <circle cx='10' cy='10' r='2.5' fill='currentColor'/>
        <path
            d='M5.93837 7.0835C5.34782 7.90443 5 8.91168 5 10.0002C5 11.0886 5.34782 12.0959 5.93837 12.9168M14.0616 7.0835C14.6522 7.90443 15 8.91168 15 10.0002C15 11.0886 14.6522 12.0959 14.0616 12.9168'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
        <path
            opacity='0.4'
            d='M16.4862 5.8335C17.2596 7.03492 17.7083 8.46515 17.7083 10.0002C17.7083 11.5352 17.2596 12.9654 16.4862 14.1668M3.51369 14.1668C2.74031 12.9654 2.29163 11.5352 2.29163 10.0002C2.29163 8.46515 2.74031 7.03492 3.51369 5.8335'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
    </>,
    <>
        <circle cx='10' cy='10' r='2.5' fill='currentColor'/>
        <path
            d='M5.93837 7.0835C5.34782 7.90443 5 8.91168 5 10.0002C5 11.0886 5.34782 12.0959 5.93837 12.9168M14.0616 7.0835C14.6522 7.90443 15 8.91168 15 10.0002C15 11.0886 14.6522 12.0959 14.0616 12.9168'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
        <path
            d='M16.4862 5.8335C17.2596 7.03492 17.7083 8.46515 17.7083 10.0002C17.7083 11.5352 17.2596 12.9654 16.4862 14.1668M3.51369 14.1668C2.74031 12.9654 2.29163 11.5352 2.29163 10.0002C2.29163 8.46515 2.74031 7.03492 3.51369 5.8335'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
        />
    </>,
];

type LiveIndicatorIconProps = {
    pulse: any; // every time the pulse changes the animation plays once
    frameMs?: number; // time spent per frame
    error?: boolean;
} & SvgIconProps;

const LiveIndicatorIcon: React.FC<LiveIndicatorIconProps> = observer(
    ({pulse, frameMs = 200, error, sx, ...props}) => {
        // -1 = not animating
        const [frame, setFrame] = useState(-1);

        useEffect(() => {
            setFrame(0);
        }, [pulse]);

        useEffect(() => {
            // frame is in the rest state, end
            if (frame < 0) {
                return undefined;
            }

            const timeout = setTimeout(() => {
                const nextFrame = (frame + 1) % FRAMES.length;
                // if the animation was reset, but it into the rest state
                if (nextFrame === 0) {
                    setFrame(-1);
                } else {
                    setFrame(nextFrame);
                }
            }, frameMs);

            return () => {
                clearTimeout(timeout);
            };
        }, [frame]);

        let renderFrame = frame < 0 ? 0 : frame;
        let color = renderFrame === 0 ? 'action.disabled' : undefined;
        if (error) {
            color = 'error.main';
            renderFrame = FRAMES.length - 1;
        }

        if (color != null) {
            // eslint-disable-next-line no-param-reassign
            sx = {...sx, color};
        }

        return (
            <Icon {...props} sx={sx}>
                {FRAMES[renderFrame]}
            </Icon>
        );
    },
);

export {LiveIndicatorIcon};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';

import { useModal } from '@global-components/Modals/useModal.hooks';

import { useIntercom } from 'react-use-intercom';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  title: string;
  subTitle: string;
  errorType?: 'verification' | 'restricted' | 'limit';
  showButton?: boolean;
  onClose: () => void;
};

export const TransferModalErrorContentStandard: React.FC<Props> = ({ subTitle, title, onClose }) => (
  <FlexLayout justifyContent='center' spacing={16} direction='column'>
    <Notification severity='warning' title={title}>
      <FlexLayout direction='column'>
        <Body color='secondary'>{subTitle}</Body>
      </FlexLayout>
    </Notification>
    <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
      <Button variant='outlined' color='primary' onClick={onClose}>
        Cancel
      </Button>
    </FlexLayout>
  </FlexLayout>
);

export const TransferModalErrorContentVerification: React.FC<Props> = ({
  subTitle,
  title,
  errorType,
  showButton = true,
  onClose,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'transferModal.buttonLabels' });
  const { navigate } = useNavigateRoute();
  const { closeModal } = useModal();
  const { showNewMessages } = useIntercom();

  const handleClick = () => {
    closeModal();
    navigate(NavigationURLs.Onboarding);
  };

  return (
    <FlexLayout justifyContent='center' spacing={16} direction='column'>
      <Notification severity={errorType === 'limit' ? 'destructive' : 'warning'} title={title}>
        <FlexLayout direction='column' spacing={8}>
          <Body color='secondary'>{subTitle}</Body>
          {errorType === 'limit' && (
            <span>
              <Button
                variant='ghost'
                color='accent'
                trailingIcon={<ArrowLineOnlyRight className='text-color-icon-accent' />}
                onClick={() => showNewMessages()}
              >
                {t('contactSupport')}
              </Button>
            </span>
          )}
        </FlexLayout>
      </Notification>
      <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
        {showButton && (
          <Button variant='filled' color='primary' onClick={handleClick}>
            {errorType === 'verification' ? t('verifyNow') : t('resolveRestriction')}
          </Button>
        )}
        <Button variant='outlined' color='primary' onClick={onClose}>
          Cancel
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

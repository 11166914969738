import { LineChartData } from '../LineChart.types';

const TODAY = new Date().getTime();

// Gross but I want it to look a certain way
export const placeholderLineData: LineChartData = [
  {
    time: new Date(TODAY - 12000000),
    value: 0,
  },
  {
    time: new Date(TODAY - 11000000),
    value: 4,
  },
  {
    time: new Date(TODAY - 10000000),
    value: 1,
  },
  {
    time: new Date(TODAY - 9000000),
    value: 5,
  },
  {
    time: new Date(TODAY - 8000000),
    value: 4,
  },
  {
    time: new Date(TODAY - 7000000),
    value: 7,
  },
  {
    time: new Date(TODAY - 6000000),
    value: 6,
  },
  {
    time: new Date(TODAY - 5000000),
    value: 5,
  },
  {
    time: new Date(TODAY - 4000000),
    value: 8,
  },
  {
    time: new Date(TODAY - 3000000),
    value: 10,
  },
  {
    time: new Date(TODAY - 2000000),
    value: 7,
  },
];

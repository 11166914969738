import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { assetService } from '@shared/services';

interface Props {
  assetId: number;
  title: string;
}

export const TradeAssetName: React.FC<Props> = (props) => {
  const { assetId, title } = props;
  const asset = assetService.getAsset(assetId)!;
  return (
    <FlexLayout direction='column' spacing={12}>
      <Heading size='h6'>{title}</Heading>

      <FlexLayout direction='row' spacing={8} className='items-center'>
        <AssetIcon asset={asset} size={24} />
        <Body>{asset.name}</Body>
        <Body color='secondary'>{asset.code}</Body>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { QuickBuyType } from 'src/lib/trade/types/Trade.types';

import { QuickBuyWidgetTab } from './QuickBuyWidgetTab';

type Props = {
  value: QuickBuyType;
  onChange: (value: QuickBuyType) => void;
};

const QuickBuyWidgetTabs: React.FC<Props> = ({ value, onChange }) => {
  const buildClassNames = useCallback(
    (alignment: 'left' | 'right' | 'center', tab: QuickBuyType) => {
      if (tab === value) return '';

      switch (alignment) {
        case 'left':
          return 'border-b border-r border-color-border-main bg-color-background-surface-secondary';
        case 'right':
          return 'border-b border-l border-color-border-main bg-color-background-surface-secondary';
        case 'center':
          return 'border-b border-r border-l border-color-border-main bg-color-background-surface-secondary';
      }
    },
    [value],
  );

  return (
    <FlexLayout className='h-[3.5rem] w-full'>
      <QuickBuyWidgetTab
        title='Buy'
        value='buy'
        onClick={onChange}
        className={buildClassNames('left', 'buy')}
        selected={value === 'buy'}
      />
      <QuickBuyWidgetTab
        title='Sell'
        value='sell'
        onClick={onChange}
        className={buildClassNames('right', 'sell')}
        selected={value === 'sell'}
      />
    </FlexLayout>
  );
};

export { QuickBuyWidgetTabs };

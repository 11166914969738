import React, { useMemo } from 'react';

import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';
import { TableSelectFilter } from '@swyftx/aviary/molecules/TableFilters/TableSelectFilter';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

type Props = {
  asset?: Asset;
  className?: string;
  isSmallScreen: boolean;
  setAsset: React.Dispatch<React.SetStateAction<Asset | undefined>>;
};

const TransactionsFilterAssets: React.FC<Props> = observer(({ asset, isSmallScreen, setAsset }) => {
  const { getBalance } = usePortfolioBalance();
  const { getAssetByCode, allAssets } = useMarkets();

  const items = useMemo(
    (): SelectItemData<Asset['code']>[] =>
      allAssets
        .filter((a) => getBalance(a.id) !== undefined)
        .map((assetWithBalance) => ({
          label: assetWithBalance.name,
          value: assetWithBalance.code,
          leadingIcon: <AssetIcon asset={assetWithBalance} size={20} />,
        })),
    [allAssets, getBalance],
  );

  return (
    <TableSelectFilter<Asset['code']>
      title='Asset'
      align='start'
      items={items}
      value={asset ? [asset.code] : []}
      className='w-full'
      multi={false}
      showSearch
      onChange={(values) => setAsset(values.length === 1 ? getAssetByCode(values[0]) : undefined)}
      isSmallScreen={isSmallScreen}
    />
  );
});

export { TransactionsFilterAssets };

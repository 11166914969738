import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { UIStore } from '@shared/store';

import UserService from '@services/UserService';

import { ProfileInformationUpdateProps } from '@Profile/subroutes/Information/types';

import { useEvent } from 'react-use';

const ProfileInformationUpdateEmail: React.FC<ProfileInformationUpdateProps> = ({ userProfile }) => {
  const { t } = useTranslation('profile.information', { keyPrefix: 'email' });
  const { email, verification } = userProfile;
  const { email: emailVerified } = verification || {};
  const { addMessageBox, addToastMessage } = UIStore.useUIStore;
  const { openModal } = useModal();

  const onCheckVerifyEmail = useCallback(async () => {
    const resp = await UserService.CheckEmailVerification();
    if (!resp.emailVerified) return;

    await UserService.GetUserProfile();
    addToastMessage({ message: t('labels.verifiedEmail'), severity: 'info' });
  }, [addToastMessage, t]);

  const onVerifyEmail = useCallback(async () => {
    try {
      const resp = await UserService.RequestEmailVerification();
      if (resp.emailVerified) {
        await UserService.GetUserProfile();
        addToastMessage({ message: t('labels.verifiedEmail'), severity: 'info' });
      } else {
        addMessageBox({
          content: (
            <InformationMessageBox
              PII
              title={t('labels.title')}
              content={
                <FlexLayout>
                  <Body size='small' color='secondary'>
                    {t('labels.verifyEmail')}
                  </Body>
                  <Body size='small' color='secondary'>
                    {t('labels.linkSentTo')}
                  </Body>
                  <Body size='small' color='secondary'>
                    {email}
                  </Body>
                </FlexLayout>
              }
              footer={
                <FlexLayout>
                  <Button variant='ghost' color='primary' onClick={() => openModal(Modals.UpdateEmailAddress)}>
                    {t('buttonLabels.notYourEmail')}
                  </Button>
                </FlexLayout>
              }
            />
          ),
        });
      }
    } catch (e) {
      // TODO error
    }
  }, [addMessageBox, addToastMessage, email, openModal, t]);

  useEvent('visibilitychange', async () => {
    if (!document.hidden && !emailVerified) {
      onCheckVerifyEmail();
    }
  });

  useEffect(() => {
    if (!emailVerified) {
      onCheckVerifyEmail();
    }
  }, [emailVerified, onCheckVerifyEmail]);

  if (!emailVerified) {
    return (
      <Button id='profile-information-verify-email' onClick={onVerifyEmail}>
        {t('buttonLabels.verify')}
      </Button>
    );
  }

  return (
    <Button id='profile-information-update-email' onClick={() => openModal(Modals.UpdateEmailAddress)} size='md'>
      {t('buttonLabels.changeAddress')}
    </Button>
  );
};

export { ProfileInformationUpdateEmail };

import React, { useEffect, useMemo } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Error, Tick } from '@swyftx/aviary/icons/outlined';

import BsbValidator from '@hooks/validator/types/bsb.validator';
import { useValidation } from '@hooks/validator/useValidation';

type BsbInputFormProps = {
  setValid?: (valid: boolean) => void;
  success?: boolean;
} & InputProps;

const BsbInput: React.FC<BsbInputFormProps> = ({ id, onChange, value, required, setValid, ...props }) => {
  const invalidChars = ['-', '+', 'e', '.'];

  const validatorProps = useValidation([new BsbValidator('BSB address is not valid')], true, onChange, value);

  const isValidKey = (key: string) => {
    if (invalidChars.includes(key)) return false;
    return true;
  };

  const handleOnChange = (e: any) => {
    const val = e.target.value;

    // max bsb length is 6
    if (String(val).length > 6 || !isValidKey(val)) return;

    if (validatorProps.onChange) validatorProps.onChange(e);
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('Text');
    const parsedData = pastedData.replace(/[-\s]/g, '');

    handleOnChange({ target: { value: parsedData } });
  };

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  const trailing = useMemo(() => {
    if (validatorProps.success) return <Tick className='h-16 w-16 text-color-text-success' />;
    if (validatorProps.error) return <Error className='h-16 w-16 text-color-text-error' />;

    return null;
  }, [validatorProps.error, validatorProps.success]);

  return (
    <FlexLayout direction='column' spacing={4}>
      <Body size='small' weight='emphasis'>
        BSB:
        {required && <span className='inline text-color-text-error'>*</span>}
      </Body>
      <Input
        className='fs-mask'
        containerClassName={validatorProps.success ? 'border-color-border-success border' : ''}
        id={id}
        type='number'
        placeholder='Enter BSB'
        {...props}
        {...validatorProps}
        trailing={trailing}
        required={required}
        onKeyDown={(evt) => !isValidKey(evt.key) && evt.preventDefault()}
        onChange={handleOnChange}
        onPaste={handleOnPaste}
      />
      <Body color={validatorProps.error ? 'error' : 'success'} size='small' weight='emphasis'>
        {validatorProps.helperText}
      </Body>
    </FlexLayout>
  );
};

export { BsbInput };

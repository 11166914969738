/* eslint-disable @typescript-eslint/no-unused-vars */
import { api } from '@shared/api';
import { UserBalances } from '@shared/api/@types/user';
import { CronJobService } from '@shared/cron';
import { TimesEnum } from '@shared/enums';
import handleError, { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { assetService } from './assetService';

const logTag = 'BALANCE_SERVICE';

class BalanceService extends CronJobService {
  async update() {
    try {
      const { isLoggedIn, setBalances } = UserStore.useUserStore;
      if (isLoggedIn) {
        const response = await api.endpoints.getUserBalance();
        // update balances in store
        const balances: UserBalances = {};
        for (const assetBalance of response.data) {
          balances[assetBalance.assetId] = assetBalance;
        }

        assetService.getAssetList().forEach((asset) => {
          if (
            asset.delisting &&
            (!balances[asset.id] ||
              (Big(balances[asset.id].availableBalance).lte(0) && Big(balances[asset.id].stakingBalance).lte(0)))
          ) {
            assetService.setAssetActive(asset.id, false);
          } else {
            assetService.setAssetActive(asset.id, true);
          }
        });

        setBalances(balances);
      }
    } catch (ex) {
      // ignore if known
      if (!(ex as SwyftxError).isSwyftxError) {
        handleError(logTag, 'Failed to update', ex);
      }
    }
  }
}

export const balanceService = new BalanceService(TimesEnum.MINUTE);

// [dev] When hot reloading remove the cron
// eslint-disable-next-line no-unused-vars
declare const module: any;
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    logger.debug(logTag, 'Hot reload cleanup');
    balanceService.cleanup();
  });
}

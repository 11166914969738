import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';
import { SingleDatePicker } from '@swyftx/aviary/molecules/SingleDatePicker/SingleDatePicker';

import { RecurringOrder, RecurringOrderStatus } from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { capitalize } from '@utils/formatting';

import { DateTime } from 'luxon';
import { AssetIconsGroup } from 'src/lib/markets/components/AssetIconsGroup';

import { useEditAutoInvestment, useFundOtherWithOptions } from '../../hooks';
import { AutoInvestReviewItem } from '../AutoInvestReviewItem';

type Props = {
  autoInvestment: RecurringOrder;
  position?: 'center' | 'bottom';
  onOpen?: () => void;
};

const ResumeAutoInvestModal: React.FC<PropsWithChildren<Props>> = ({
  autoInvestment,
  position = 'center',
  onOpen,
  children,
}) => {
  const [open, setOpen] = useState<boolean>();

  const {
    updateRecurringOrderStatus,
    setStartDate,
    startDate,
    autoInvestAssets,
    selectedPaymentMethod,
    diffDayText,
    validEditOrder,
    feeAmount,
  } = useEditAutoInvestment({
    autoInvestment,
    setOpen,
  });
  const { getFundOrderWithOption } = useFundOtherWithOptions();
  const countryAsset = useCountryAsset();

  useEffect(() => {
    setStartDate(DateTime.now().startOf('day'));
  }, [setStartDate]);

  useEffect(() => {
    if (open && onOpen) onOpen();
  }, [onOpen, open]);

  const handleOnResume = useCallback(() => {
    updateRecurringOrderStatus(RecurringOrderStatus.Active);
  }, [updateRecurringOrderStatus]);

  return (
    <Modal
      triggerElement={children}
      open={open}
      onOpenChange={setOpen}
      position={position}
      className={cn(position === 'center' ? '!min-w-[500px] !max-w-[500px]' : '')}
    >
      <FlexLayout direction='column'>
        <FlexLayout alignItems='center' justifyContent='space-between' className='p-24'>
          <Heading size='h4'>Resume order</Heading>
          <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={() => setOpen(false)} />
        </FlexLayout>
        <FlexLayout direction='column' spacing={24} className='px-24'>
          {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
            <>
              <Body color='secondary'>
                Please review your order details and ensure that you have sufficient funds prior to the next scheduled
                order date.
              </Body>
              <div className='w-1/2'>
                <SingleDatePicker
                  id='auto-invest-start-date'
                  title='Date to resume order'
                  onChange={setStartDate}
                  value={startDate}
                  fromDate={DateTime.now().startOf('day').toJSDate()}
                  toDate={DateTime.now().startOf('day').plus({ month: 2 }).toJSDate()}
                />
              </div>
              <div className='h-1 w-full bg-color-border-main' />
            </>
          )}

          <FlexLayout direction='column' spacing={12}>
            <Heading size='h6'>Order details</Heading>
            <FlexLayout direction='column' spacing={16}>
              <AutoInvestReviewItem title='Order name' value={autoInvestment.label} />
              <AutoInvestReviewItem
                title='Assets'
                value={
                  <AssetIconsGroup
                    assets={assetService.getAssetsByCode(autoInvestAssets.map((a) => a.assetCode))}
                    size={20}
                  />
                }
              />
              {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
                <AutoInvestReviewItem title='Frequency' value={capitalize(autoInvestment.frequency || '')} />
              )}
              {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
                <AutoInvestReviewItem
                  title={`${capitalize(autoInvestment.frequency || '')} investment`}
                  value={`${formatCurrency(autoInvestment.orderTotal || 0, countryAsset)} ${countryAsset?.code}`}
                />
              )}
              {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
                <AutoInvestReviewItem
                  title='Fee per order'
                  value={`${formatCurrency(feeAmount, countryAsset)} ${countryAsset?.code}`}
                />
              )}
              <AutoInvestReviewItem
                title='Funding method'
                value={getFundOrderWithOption(autoInvestment.source)?.label}
              />
              {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && startDate && (
                <AutoInvestReviewItem
                  title='Next order date'
                  value={
                    <FlexLayout direction='column' alignItems='end' spacing={4}>
                      <Body color='secondary' weight='emphasis' size='small'>
                        {capitalize(diffDayText)}
                      </Body>
                      <Body size='small' color='secondary'>
                        ({startDate.toFormat('dd/MM/yyyy')})
                      </Body>
                    </FlexLayout>
                  }
                />
              )}
            </FlexLayout>
          </FlexLayout>
          <FlexLayout alignItems='center' justifyContent='end' className='pb-24' spacing={12}>
            <Button variant='outlined' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='filled'
              color='accent'
              onClick={handleOnResume}
              disabled={!validEditOrder.valid}
              tooltip={validEditOrder.tooltip}
            >
              Resume order
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { ResumeAutoInvestModal };

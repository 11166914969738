import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { api, UpdatePhoneData, UpdatePhoneResponse } from '@shared/api';
import { GreenIdStatusEnum } from '@shared/enums';
import { UIStore } from '@shared/store';

import { ProfileInformationUpdateProps } from '@Profile/subroutes/Information/types';

import { DateTime } from 'luxon';
import { useForm, FormProvider } from 'react-hook-form';

import { OTPStep } from './OTPStep';
import { PhoneNumberInputStep } from './PhoneNumberInputStep';

type Step = 0 | 1 | 2;
const NUMBER_OF_DIGITS = 6;

export const updatePhone = async (data: UpdatePhoneData): Promise<UpdatePhoneResponse> => {
  const response = await api.endpoints.updatePhone({ data });
  return response.data;
};

const ProfileInformationUpdatePhone: React.FC<ProfileInformationUpdateProps> = ({
  userProfile,
  isDoubleOTPEnabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const { addToastMessage } = UIStore.useUIStore;
  const { openModal } = useModal();
  const isXs = useTailwindBreakpoint('xs');

  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, reset, getValues } = methods;

  const phoneVerified = userProfile.verification?.phone;
  const greenIdVerified = userProfile.verification?.greenid.status === GreenIdStatusEnum.VERIFIED;
  const skipAuthorisation = !phoneVerified && !greenIdVerified;

  const [step, setStep] = useState<Step>(skipAuthorisation ? 1 : 0);
  const [formValues, setFormValues] = useState({} as UpdatePhoneData);

  if (!phoneVerified) {
    return (
      <Button id='profile-information-verify-email' onClick={() => openModal(Modals.VerifyPhone)} size='md'>
        Verify phone
      </Button>
    );
  }

  if (!isDoubleOTPEnabled) {
    return (
      <Button id='profile-information-update-email' onClick={() => openModal(Modals.UpdatePhone)} size='md'>
        Change number
      </Button>
    );
  }

  const handleError = (message: string, shouldExit?: boolean) => {
    addToastMessage({ severity: 'error', message });
    if (shouldExit) {
      setOpen(false);
      reset();
      setStep(skipAuthorisation ? 1 : 0);
    }
  };

  const handleOpenChange = async (openValue?: boolean) => {
    if (!openValue) {
      setOpen(false);
      reset();
      setStep(skipAuthorisation ? 1 : 0);
      return;
    }

    if (skipAuthorisation) {
      // straight to step 1
      setOpen(true);
      return;
    }

    try {
      setOpen(true);
      await updatePhone({});
    } catch (error: any) {
      if (error?.isRateLimitError) {
        handleError('You have reached the maximum number of attempts. Please try again later.', true);
      } else {
        handleError('Something went wrong sending a code to your current phone number.', true);
      }
    }
  };

  const handleOTPError = (
    otp:
      | {
          success: boolean;
          expiration?: number | undefined;
          sent?: boolean | undefined;
          attemptsRemaining?: number | undefined;
        }
      | undefined,
    defaultErrorMessage: string,
  ) => {
    if (otp?.attemptsRemaining === 0) {
      handleError('You have reached the maximum number of attempts. Please try again later.', true);
    } else if (otp?.expiration && DateTime.fromMillis(otp.expiration) >= DateTime.now()) {
      handleError('That code has expired. Please try again.', true);
    } else if (!otp?.success) {
      handleError(defaultErrorMessage, false);
    }
  };

  const handleErrorInStep = (errorStep: Step, { error, oldOTP, newOTP, success }: UpdatePhoneResponse) => {
    if (success) return;

    switch (errorStep) {
      case 0:
        if (oldOTP?.success) {
          return;
        }
        handleOTPError(oldOTP, error?.message || 'That code is incorrect.');
        break;
      case 1:
        if (!newOTP?.sent) {
          handleError(error?.message || 'Something went wrong sending a code to your new phone number.', false);
        }
        break;
      case 2:
        handleOTPError(newOTP, error?.message || 'That code is incorrect.');
        break;
      default:
        errorStep satisfies never;
    }
  };

  const onSubmit = async (data: UpdatePhoneData): Promise<UpdatePhoneResponse> => {
    try {
      const sanitizedPhone = data.phone?.replace(/-|\s/g, '');
      const newFormValues = { ...formValues, ...data, phone: sanitizedPhone };
      const payload = {
        oldOTP: skipAuthorisation ? undefined : newFormValues.oldOTP,
        phone: step > 0 ? newFormValues.phone : undefined,
        newOTP: step === 2 ? newFormValues.newOTP : undefined,
      };
      const filteredData = Object.fromEntries(
        Object.entries(payload).filter(([, value]) => value !== undefined),
      ) as UpdatePhoneData;

      const response = await updatePhone(filteredData);

      handleErrorInStep(step, response);

      setFormValues(newFormValues);
      step < 2 ? setStep((prevStep) => (prevStep + 1) as Step) : handleOpenChange(false);
      if (step === 2) {
        addToastMessage({ severity: 'success', message: 'Phone number updated successfully' });
      }

      return response;
    } catch (error: any) {
      handleError('An error occurred while updating your phone number. Please try again.');

      return {
        success: false,
        error: { error: error?.error ?? '', message: error?.errorMessage ?? 'An error occurred' },
      } satisfies UpdatePhoneResponse;
    }
  };

  const stepsConfig = [
    {
      title: 'Authentication required',
      component: (
        <OTPStep
          methods={methods}
          fieldName='oldOTP'
          nextButtonText='Continue'
          numberOfDigits={NUMBER_OF_DIGITS}
          bodyText={
            <>
              <Body>SMS Verification is required to change to a new phone number.</Body>
              <Body>
                Enter the {NUMBER_OF_DIGITS} digit code we sent to {userProfile?.phone ?? 'your phone number'}
              </Body>
            </>
          }
        />
      ),
    },
    {
      title: 'Change phone number',
      component: <PhoneNumberInputStep userProfile={userProfile} methods={methods} handleClose={handleOpenChange} />,
    },
    {
      title: 'Verify new phone number',
      component: (
        <OTPStep
          methods={methods}
          fieldName='newOTP'
          nextButtonText='Verify'
          showIntercom={false}
          bodyText={
            <Body>
              To verify your new phone number, enter the {NUMBER_OF_DIGITS} digit code we sent to {getValues('phone')}
            </Body>
          }
        />
      ),
    },
  ];

  const { title, component } = stepsConfig[step];

  return (
    <Modal
      open={open}
      position={isXs ? 'bottom' : 'center'}
      onOpenChange={(openValue) => handleOpenChange(openValue)}
      showCloseButton
      title={title}
      triggerElement={
        <Button id='profile-information-update-phone' size='md'>
          Change number
        </Button>
      }
    >
      <FlexLayout direction='column' spacing={16} className='p-24 pt-0'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='w-full'>{component}</div>
          </form>
        </FormProvider>
      </FlexLayout>
    </Modal>
  );
};

export { ProfileInformationUpdatePhone };

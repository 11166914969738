import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

import { ApiKeyResultInput } from './components';

type Props = {
  onClose: () => void;
};

const ApiKeyResultStep: React.FC<Props> = observer(({ onClose }) => {
  const { createdApiKeyResponse, setCreatedApiKeyResponse } = useContext(ApiKeysContext);
  const { t } = useTranslation('profile.apiKey');
  const { bx } = useContentBreakpoint();

  const handleOnClose = () => {
    setCreatedApiKeyResponse(undefined);
    onClose();
  };

  return (
    <Modal
      id='api-key-modal-warning-step'
      open
      sx={{ width: '600px' }}
      onClose={handleOnClose}
      HeaderProps={{ title: t('sections.createApiKey.result.title'), dismissible: true, divider: true }}
      FooterProps={{
        divider: true,
        content: (
          <Stack width='100%' alignItems='flex-end'>
            <Button
              id='api-keys-modal-back'
              onClick={handleOnClose}
              variant='contained'
              sx={{ width: bx({ xs: '100%', sm: 'fit-content' }) }}
            >
              {t('sections.createApiKey.result.understand')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={3}>
        <Notification
          id='notification-api-key-result'
          title={t('sections.createApiKey.result.notification.title')}
          severity='info'
          alertTitleProps={{ sx: { color: 'info.main' } }}
        >
          <Typography id='notification-text-api-key' fontSize={14} fontWeight='400'>
            {t('sections.createApiKey.result.notification.subtitle')}
          </Typography>
        </Notification>

        <Typography id='create-api-key-authentication-subtitle' color='text.secondary' fontSize={16} fontWeight='400'>
          {t('sections.createApiKey.result.subtitle')}
        </Typography>

        <ApiKeyResultInput
          id='api-key-token'
          label={t('sections.createApiKey.result.apiKey')}
          value={createdApiKeyResponse?.apiKey}
        />

        <ApiKeyResultInput
          id='api-key-access-token'
          label={t('sections.createApiKey.result.accessToken')}
          value={createdApiKeyResponse?.accessToken}
        />
      </Stack>
    </Modal>
  );
});

export { ApiKeyResultStep };

import React, { useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Cross, Hamburger, SwyftxCompact, SwyftxFull } from '@swyftx/aviary/icons/outlined';
import { Badge } from '@swyftx/react-web-design-system';

import { AppHeaderHelp } from '@global-components/AppHeader/AppHeaderHelp';
import { AppHeaderProfile } from '@global-components/AppHeader/AppHeaderProfile';
import { AppHeaderSettings } from '@global-components/AppHeader/AppHeaderSettings';

import { UIStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useShouldHideMobileAppHeaderMenu } from '@hooks/Layout/useShouldSkip';
import { RewardsContext } from '@routes/Rewards/Rewards.context';

import { observer } from 'mobx-react-lite';
import { useKeyPressEvent } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useGlobalSearch } from 'src/lib/search/hooks';
import { useGlobalSearchAnalytics } from 'src/lib/search/hooks/useGlobalSearchAnalytics';

import { AppHeaderBaseCurrencySelector } from './AppHeaderBaseCurrencySelector';
import { AppHeaderBreadCrumbs } from './AppHeaderBreadCrumbs';
import { AppHeaderEntityCta } from './AppHeaderEntityCta/AppHeaderEntityCta';
import { AppHeaderReferrals } from './AppHeaderReferrals';
import { AppHeaderSearch } from './AppHeaderSearch';
import { AppHeaderTransferButton } from './AppHeaderTransferButton';

type Props = {
  headerItemsOverride?: React.ReactElement;
};

const AppHeader: React.FC<Props> = observer(({ headerItemsOverride }) => {
  const { setNavDrawerExpanded, navDrawerExpanded, hideSideMenu, globalModalData } = UIStore.useUIStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { navigate } = useNavigateRoute();
  const { isMediumScreen, isLargeScreen } = useScreenBreakpoints();
  const { openGlobalSearch } = useGlobalSearch();
  const { trackGlobalSearchBarOpened } = useGlobalSearchAnalytics();
  const shouldHideMobileMenu = useShouldHideMobileAppHeaderMenu();

  const { unseenRewards } = useContext(RewardsContext);

  const navigateToDashboard = () => {
    navigate(NavigationURLs.Dashboard);
  };

  useKeyPressEvent('/', () => {
    const { tagName } = document.activeElement || {};
    const inputTypes = ['input', 'textarea'];

    if ((tagName && inputTypes.indexOf(tagName.toLowerCase()) !== -1) || globalModalData) {
      return;
    }

    if (
      isFeatureEnabled(AppFeature.AssetList) &&
      window.document.activeElement?.nodeName !== 'INPUT' &&
      !document.querySelector('[role="dialog"]')
    ) {
      openGlobalSearch();
      trackGlobalSearchBarOpened(["' / ' shortcut"]);
    }
  });

  return (
    <FlexLayout
      id='header'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='z-20 flex h-64 w-full items-center justify-between border-b border-color-border-main bg-color-background-surface-primary sm:z-10'
    >
      <FlexLayout className='cursor-pointer items-center px-20' direction='row' spacing={16}>
        {isMediumScreen && (
          <FlexLayout
            onClick={navigateToDashboard}
            direction='row'
            className='items-center justify-center border-r border-color-border-main pb-4 pr-16'
          >
            <SwyftxFull className='h-[2rem] text-color-text-primary' />
          </FlexLayout>
        )}
        {!isMediumScreen && (
          <FlexLayout direction='row' className='items-center justify-start' spacing={8}>
            {!hideSideMenu && !shouldHideMobileMenu && (
              <Button
                id='header-menu'
                onClick={() => setNavDrawerExpanded(!navDrawerExpanded)}
                variant='ghost'
                layout='icon'
                leadingIcon={
                  <>
                    {!navDrawerExpanded && (
                      <Badge badgeContent={unseenRewards} color='error' overlap='circular'>
                        <Hamburger className='text-color-text-secondary' />
                      </Badge>
                    )}
                    {navDrawerExpanded && <Cross className='text-color-text-secondary' />}
                  </>
                }
              ></Button>
            )}

            {!isMediumScreen && (
              <FlexLayout direction='row' className='pb-4 text-color-text-primary'>
                <SwyftxCompact className='h-[2rem]' />
              </FlexLayout>
            )}
          </FlexLayout>
        )}
        <FlexLayout direction='row' className='items-center' spacing={12}>
          <AppHeaderBreadCrumbs />
        </FlexLayout>
      </FlexLayout>

      {headerItemsOverride ? (
        <FlexLayout spacing={8} className='px-12'>
          {headerItemsOverride}
        </FlexLayout>
      ) : (
        <FlexLayout direction='row' spacing={8} className='flex items-center px-12 text-color-text-primary'>
          {isLargeScreen && <AppHeaderEntityCta />}
          {isMediumScreen && (
            <>
              <AppHeaderBaseCurrencySelector />
              <AppHeaderTransferButton />
            </>
          )}
          {isFeatureEnabled(AppFeature.AssetList) && <AppHeaderSearch />}
          {isMediumScreen && (
            <>
              <AppHeaderHelp />
              <AppHeaderSettings />
              <AppHeaderReferrals />
              <AppHeaderProfile />
            </>
          )}
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export default AppHeader;

import React from 'react';
import { useParams } from 'react-router-dom';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';

import { AppHeaderAssetActions } from '@global-components/AppHeader/AppHeaderAssetActions';
import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { BuySellButtons } from '@global-components/BuySellButtons';
import { PriceTicker } from '@global-components/PriceTicker/PriceTicker';

import { Asset, RateSide } from '@shared/api';
import { RatesStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { useAssetSummaryData } from '../AssetSummary.hooks';

type Props = {
  asset: Asset;
  priceSide?: RateSide;
  forceBaseAsset?: Asset;
};

type AssetSummaryTitleParams = {
  asset: string;
};

const AssetSummaryTitle: React.FC<Props> = observer(({ asset, priceSide, forceBaseAsset }) => {
  const { getRate } = RatesStore.useRatesStore;
  const livePrice = asset && getRate(asset)[priceSide || 'midPrice'];
  const { currency, price, changingCurrency } = useAssetSummaryData(livePrice, forceBaseAsset);

  const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'asset-summary' });

  const getIcon = () => <AssetIcon asset={asset} size={28} />;
  const { asset: assetParam } = useParams<AssetSummaryTitleParams>();

  return (
    <FlexLayout
      className='w-full flex-col items-start py-8 @sm:flex-row @sm:justify-between'
      justifyContent='space-between'
      spacing={16}
      alignItems='center'
      data-container='asset-summary'
    >
      <FlexLayout alignItems='center' direction='row' className='w-full' spacing={8}>
        <FlexLayout className='w-full flex-col @sm:flex-row' spacing={8} alignItems='center'>
          {getIcon()}
          <FlexLayout alignItems='center' className='flex-col gap-0 @sm:flex-row @md:gap-8'>
            <Body size='large' weight='bold' color='primary'>
              {asset.name}
            </Body>

            <FlexLayout direction='row' alignItems='center' spacing={16}>
              <PriceTicker
                typographyProps={{
                  size: 'large',
                  weight: 'emphasis',
                }}
                disableNextAnimation={changingCurrency}
                formatOptions={{ hideCode: true, hideSymbol: true, appendCode: false }}
                currency={currency}
                price={price || '0'}
              />
              <Numeric color='secondary'>
                {currency?.code || 'N/A'}/{asset.code}
              </Numeric>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout
        direction='row'
        className={`w-full items-center ${atLeast.sm ? 'justify-end' : 'justify-center'}`}
        spacing={8}
      >
        <AppHeaderAssetActions assetCode={assetParam ? assetParam.toLowerCase() : ''} />
        <BuySellButtons asset={asset} openTradePanel showSwaps className={atLeast.sm ? 'w-fit' : ''} />
      </FlexLayout>
    </FlexLayout>
  );
});

AssetSummaryTitle.displayName = 'AssetSummaryTitle';

export { AssetSummaryTitle };

import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';

import { Category } from '@shared/api/@types/categories';

import * as Popover from '@radix-ui/react-popover';
import { observer } from 'mobx-react-lite';
import { RawCategoryIcons } from 'src/context/Categories/Categories.icons';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { MoreCategoriesContent } from './MoreCategoriesContent';

interface Props {
  otherCategories: Category[];
}

export const MoreCategoriesPopover: React.FC<Props> = observer((props) => {
  const { otherCategories } = props;
  const [categoriesSearch, setCategoriesSearch] = useState<string>('');
  const { navigate, params } = useNavigateRoute();
  const { categoryId } = params as { categoryId?: string };
  const isXs = useTailwindBreakpoint('xs');
  const [open, setOpen] = useState<boolean>(false);

  const categoriesToShow = useMemo(
    () => otherCategories.filter((category) => category.name.toLowerCase().includes(categoriesSearch.toLowerCase())),
    [categoriesSearch, otherCategories],
  );

  const selectedCategory = useMemo(
    () => (categoryId ? otherCategories.find((category) => category.id.toString() === categoryId) : undefined),
    [categoryId, otherCategories],
  );

  const onSelectCategory = useCallback(
    (category: Category) => {
      setOpen(false);
      navigate(NavigationURLs.AssetsCategory, { pathParams: { categoryId: category.id } });
    },
    [navigate],
  );

  return (
    <Popover.Root open={open} onOpenChange={(o) => setOpen(o.valueOf() ?? false)}>
      <Popover.Trigger>
        {!selectedCategory && (
          <Button
            variant='outlined'
            layout='icon'
            size='sm'
            tooltip='More Categories'
            leadingIcon={<ArrowChevronDown className='h-14 w-14' />}
            onClick={() => setOpen(true)}
          />
        )}
        {selectedCategory && (
          <Button
            variant='outlined'
            trailingIcon={<ArrowChevronDown className='h-20 w-20' />}
            leadingIcon={RawCategoryIcons('!w-14 !h-14')[selectedCategory.name]}
            size='sm'
            className='outline outline-color-icon-active'
            onClick={() => setOpen(true)}
          >
            {selectedCategory?.name}
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <>
          {!isXs && (
            <Popover.Content asChild sideOffset={12} align='end'>
              <Card className='min-w-[25rem] max-w-[25rem] shadow-lg'>
                <MoreCategoriesContent
                  search={categoriesSearch}
                  setSearch={setCategoriesSearch}
                  categoriesToShow={categoriesToShow}
                  onSelectCategory={onSelectCategory}
                />
              </Card>
            </Popover.Content>
          )}
          {isXs && (
            <Popover.Content className='!z-modal-popover' asChild>
              <Modal
                title='Select Asset'
                position='bottom'
                open={open}
                onClose={() => setOpen(false)}
                onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
                className='overflow-x-hidden'
              >
                <MoreCategoriesContent
                  search={categoriesSearch}
                  setSearch={setCategoriesSearch}
                  categoriesToShow={categoriesToShow}
                  onSelectCategory={onSelectCategory}
                />
              </Modal>
            </Popover.Content>
          )}
        </>
      </Popover.Portal>
    </Popover.Root>
  );
});

import { useEffect, useState } from 'react';

import { api } from '@shared/api';
import { Candle, ChartBarsParams, ChartBarsQuery, Resolution, Side } from '@shared/api/@types/charts';
import { Asset } from '@shared/api/@types/markets';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { DateTime } from 'luxon';

type useCandleDataProps = {
  asset?: Asset;
  resolution?: Resolution;
  timeStart?: number;
  timeEnd?: number;
  side?: Side;
};

const useCandleData = (props: useCandleDataProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [candles, setCandles] = useState<Candle[]>([]);
  const baseAsset = useBaseAsset();
  const today = DateTime.now();
  const monthAgo = DateTime.now().minus({ month: 1 });

  const { asset, resolution = '1d', timeStart = monthAgo.toMillis(), timeEnd = today.toMillis(), side = 'ask' } = props;

  useEffect(() => {
    const getData = async () => {
      if (!asset || !baseAsset || asset.code === baseAsset.code || loading) {
        return;
      }

      setLoading(true);

      const params: ChartBarsParams = {
        primary: baseAsset.code,
        secondary: asset.code,
        side,
      };

      const query: ChartBarsQuery = {
        timeStart,
        timeEnd,
        resolution,
      };

      try {
        if (asset) {
          const res = (await api.endpoints.getChartBars({ query, params })).data;
          setCandles(res.candles);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [baseAsset, asset]);

  return {
    candles,
    error,
  };
};

export { useCandleData };

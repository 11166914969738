import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NoSearchResultsFound } from '@global-components/EmptyStates';

import { NewsTileTab } from '@shared/api';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

type Props = {
  filteredSearch: string[];
  newsTileTab?: NewsTileTab;
  isSearching?: boolean;
};

const NoNewsResults = ({ filteredSearch, newsTileTab, isSearching = false }: Props) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'newsFeedTile' });
  const { isMobile } = useContentBreakpoint();

  const displayInfo = useMemo(() => {
    const isAssetPage = !newsTileTab && filteredSearch.length > 0;
    if (isAssetPage) {
      return {
        title: t('labels.assetPageEmpty.title', { asset: filteredSearch }),
        subTitle: t('labels.assetEmpty.subTitle', { asset: filteredSearch }),
      };
    }

    // When searching articles, the empty results screen is the same between both 'All' and 'My News' tabs.
    if (isSearching) {
      return {
        title: t('labels.assetEmpty.title', { asset: filteredSearch }),
        subTitle: t('labels.assetEmpty.subTitle', { asset: filteredSearch }),
      };
    }

    // On My News tab, they have favourited/owned assets, but no news articles for those assets.
    if (newsTileTab === NewsTileTab.MyNews && filteredSearch.length > 0) {
      return {
        title: t('labels.noArticlesForMyNews.title'),
        subTitle: t('labels.noArticlesForMyNews.subTitle'),
      };
    }

    // On My News tab, with no owned or favourited assets
    if (newsTileTab === NewsTileTab.MyNews && filteredSearch.length === 0) {
      return { title: t('labels.emptyMyNews.title'), subTitle: t('labels.emptyMyNews.subTitle') };
    }

    // Error state. Only way they'd get here is if something probably went wrong
    // Eg. No articles returned even without search active.
    return { title: t('labels.empty.title'), subTitle: t('labels.empty.subTitle') };
  }, [filteredSearch, isSearching, newsTileTab]);

  return <NoSearchResultsFound title={displayInfo.title} subTitle={displayInfo.subTitle} isSmall={isMobile} />;
};

export { NoNewsResults };

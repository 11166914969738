import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Callout } from '@swyftx/aviary/atoms/Typography/Heading/Callout';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { CrossInCircle, Search } from '@swyftx/aviary/icons/outlined';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { cn } from '@shared/utils/lib/ui';

import { AccountSecurityTutorials, CustomisationTutorials, MarketsTutorials } from './components';

const TutorialsPage: React.FC = () => {
  const [search, setSearch] = useState<string>('');

  const { atLeast } = useTailwindContainerBreakpoint({
    containerName: 'tutorials-page',
  });

  return (
    <Page
      data-container='tutorials-page'
      showCompactHeader={false}
      data-spotlightelementid='dynamic-alerts-page'
      alignment='center'
      className={cn('pr-0 @container sm:!mt-0 sm:!pt-0')}
      offset={false}
      customHeader={
        <FlexLayout justifyContent='space-between' alignItems='end' className='flex-col gap-16 px-1 pt-48 @sm:flex-row'>
          <FlexLayout direction='column' className='gap-8 @sm:w-[50%] @sm:gap-8'>
            <FlexLayout className='inline flex-wrap'>
              <Heading className='inline leading-normal' weight='light' size={atLeast.lg ? 'h2' : 'h3'}>
                Swyftx{' '}
              </Heading>
              <Heading className='inline leading-normal' weight='light' size={atLeast.lg ? 'h2' : 'h3'} color='accent'>
                <Callout>Tutorials</Callout>
              </Heading>
            </FlexLayout>
            <Body color='secondary'>Text about Swyftx Tutorials Goes Here.</Body>
          </FlexLayout>
          <div>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Search tutorials'
              className='w-[250px] text-color-text-primary'
              leading={<Search className='h-18 w-18 text-color-text-primary' />}
              trailing={
                <Button
                  layout='icon'
                  variant='ghost'
                  leadingIcon={<CrossInCircle className='text-color-text-primary' />}
                  onClick={() => setSearch('')}
                />
              }
            />
          </div>
        </FlexLayout>
      }
    >
      <PageContent offset={false} className='pb-84 @container @sm:p-32 @md:p-24 @xl:px-48 @xl:py-24'>
        <FlexLayout
          direction='column'
          justifyContent='center'
          alignItems='start'
          spacing={24}
          className='h-full w-full'
        >
          <AccountSecurityTutorials search={search} />
          <CustomisationTutorials search={search} />
          <MarketsTutorials search={search} />
        </FlexLayout>
      </PageContent>
    </Page>
  );
};

export { TutorialsPage };

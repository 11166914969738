import React from 'react';

import {Box} from '@mui/material';


import {Big} from '@shared/safe-big';

import {AssetColors} from '@utils/assets';

import {AUDCurrency} from './AUDCurrency';
import {NZDCurrency} from './NZDCurrency';
import {USDCurrency} from './USDCurrency';
import {Asset} from '../../shared/api/@types/markets';

export const CurrencyIconsRounded: { [key: string]: React.ReactNode | null } = {
    AUD: (
        <AUDCurrency
            sx={{
                transform: 'scale(1.8) translateY(-1px)',
                transformOrigin: 'top left',
                '&.MuiSvgIcon-root': {width: '100% !important', height: '100% !important'},
            }}
        />
    ),
    NZD: (
        <NZDCurrency
            sx={{
                transform: 'scale(1.8) translateY(-1px)',
                transformOrigin: 'top left',
                '&.MuiSvgIcon-root': {width: '100% !important', height: '100% !important'},
            }}
        />
    ),
    USD: (
        <USDCurrency
            sx={{
                transform: 'scale(1.8) translateY(-1px)',
                transformOrigin: 'top left',
                '&.MuiSvgIcon-root': {width: '100% !important', height: '100% !important'},
            }}
        />
    ),
};

export const CurrencyIcons: { [key: string]: React.ReactNode | null } = {
    AUD: (
        <AUDCurrency
            sx={{height: '2.5rem !important', width: '2.5rem !important', position: 'absolute', top: 0, left: 0}}
        />
    ),
    NZD: (
        <NZDCurrency
            sx={{height: '2.5rem !important', width: '2.5rem !important', position: 'absolute', top: 0, left: 0}}
        />
    ),
    USD: (
        <USDCurrency
            sx={{height: '2.5rem !important', width: '2.5rem !important', position: 'absolute', top: 0, left: 0}}
        />
    ),
};

export const Currencies: { [key: string]: React.ReactNode | null } = {
    AUD: (
        <Box
            sx={{
                overflow: 'hidden',
                borderRadius: '100%',
                display: 'flex',
                width: '1.4rem !important',
                height: '1.4rem !important',
            }}
        >
            {CurrencyIconsRounded.AUD}
        </Box>
    ),
    BTC: (
        <Box
            sx={{
                backgroundColor: AssetColors.BTC,
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '1.6rem',
                padding: 0.2,
                color: ' white',
                height: '1.6rem',
            }}
        >
            <Box
                component='span'
                className='coin-icon-stack alt-icon md-avatar'
                width={24}
                height={24}
                minWidth={24}
                minHeight={24}
            >
                <Box component='i' fontSize={24} color={AssetColors.BTC}
                     className='icon icon-circle coin-icon-circle'></Box>
                <Box
                    component='i'
                    fontSize={Big(24).times(0.6).toNumber()}
                    color={AssetColors.BTC}
                    className='icon icon-BTC coin-icon-logo'
                    title='BTC'
                ></Box>
            </Box>
        </Box>
    ),
    NZD: (
        <Box
            sx={{
                overflow: 'hidden',
                borderRadius: '100%',
                display: 'flex',
                width: '1.4rem !important',
                height: '1.4rem !important',
            }}
        >
            {CurrencyIconsRounded.NZD}
        </Box>
    ),
    USD: (
        <Box
            sx={{
                overflow: 'hidden',
                borderRadius: '100%',
                display: 'flex',
                width: '1.4rem !important',
                height: '1.4rem !important',
            }}
        >
            {CurrencyIconsRounded.USD}
        </Box>
    ),
};

export const GetDynamicCurrency = (asset: Asset) => {
    const {code} = asset;
    return (
        <Box
            sx={{
                backgroundColor: AssetColors[code],
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '1.6rem',
                padding: 0.2,
                color: ' white',
                height: '1.6rem',
            }}
        >
            <Box
                component='span'
                className='coin-icon-stack alt-icon md-avatar'
                width={24}
                height={24}
                minWidth={24}
                minHeight={24}
            >
                <Box
                    component='i'
                    fontSize={24}
                    color={AssetColors[code]}
                    className='icon icon-circle coin-icon-circle'
                ></Box>
                <Box
                    component='i'
                    fontSize={Big(24).times(0.6).toNumber()}
                    color={AssetColors[code]}
                    className={`icon icon-${asset.code} coin-icon-logo`}
                    title={code}
                ></Box>
            </Box>
        </Box>
    );
};

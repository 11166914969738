import React from 'react';

import { Divider, IconButton, Stack, Typography, Tooltip } from '@mui/material';

import { Information, Cross } from '@swyftx/aviary/icons/outlined';

type ModalHeaderProps = {
  title: string;
  dismissible?: boolean;
  divider?: boolean;
  onInfo?: () => void;
  infoTooltip?: string;
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
};

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, dismissible, divider, onInfo, infoTooltip, onClose }) => (
  <>
    <Stack direction='row' display='flex' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' alignItems='center'>
        <Typography fontSize={20} fontWeight={700} padding={2} paddingRight={0}>
          {title}
        </Typography>
        {onInfo && (
          <Tooltip title={infoTooltip}>
            <span>
              <IconButton onClick={onInfo} sx={{ cursor: 'pointer' }}>
                <Information color='inherit' width={20} height={20} />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>

      {dismissible && onClose && (
        <IconButton size='large' onClick={(e) => onClose(e, 'backdropClick')} sx={{ cursor: 'pointer' }}>
          <Cross width={18} height={18} />
        </IconButton>
      )}
    </Stack>

    {divider && <Divider />}
  </>
);

export { ModalHeader };
export type { ModalHeaderProps };

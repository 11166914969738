import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { AvatarColor } from '@swyftx/aviary/atoms/Avatar/Avatar.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';
import { AddInCircle, Clock, Loading, Locked, Tick } from '@swyftx/aviary/icons/outlined';

import { UIStore, UserStore } from '@shared/store';
import { EntityAccount, EntityColor } from '@shared/store/userStore/@types/userTypes';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';
import { useEntitySwitch } from '@hooks/Entity/useEntitySwitch';
import { CreateEntityModal } from '@routes/Entities/components/CreateEntity/CreateEntityModal';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  defaultOpen?: boolean;
};

const SwitchAccountModal: React.FC<PropsWithChildren<Props>> = observer(({ defaultOpen, children }) => {
  const [open, setOpen] = useState<boolean | undefined>(defaultOpen);
  const [showCreateEntityModal, setShowCreateEntityModal] = useState<boolean>(false);

  const { entityAccounts, isKyc1Complete, isUserVerified } = UserStore.useUserStore;
  const { setNavDrawerExpanded } = UIStore.useUIStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const entityOnboardingEnabled = isFeatureEnabled(AppFeature.EntityOnboarding);

  const { selectedAccount, switchingAccount, switchingAccountError, selectAccount } = useEntitySwitch();
  const { startedApplication, entityCallToActionClicked } = useEntityOnboardingAnalytics();
  const { navigate } = useNavigateRoute();

  const {
    entityHistoryFetching,
    inProgressSubmissions,
    startNewEntityApplication,
    goToExistingApplication,
    hasASubmittedSubmission,
  } = useEntityOnboardingApplication();

  const onCreateEntity = useCallback(() => {
    if (entityOnboardingEnabled) {
      entityCallToActionClicked({ callToActionName: 'Create entity account' });
      if (!inProgressSubmissions.length) {
        startNewEntityApplication();
        startedApplication();
      } else {
        goToExistingApplication();
      }
    } else {
      setShowCreateEntityModal(true);
    }
    setOpen(false);
    setNavDrawerExpanded(false);
  }, [
    entityCallToActionClicked,
    entityOnboardingEnabled,
    goToExistingApplication,
    inProgressSubmissions.length,
    setNavDrawerExpanded,
    startNewEntityApplication,
    startedApplication,
  ]);

  const navigateToProfileVerification = useCallback(() => {
    navigate(NavigationURLs.ProfileVerification);
    setOpen(false);
    setNavDrawerExpanded(false);
  }, [navigate, setNavDrawerExpanded]);

  const onSelectAccount = useCallback(
    (entity: EntityAccount) => {
      selectAccount(entity);
      setNavDrawerExpanded(false);
    },
    [selectAccount, setNavDrawerExpanded],
  );

  const avatarColorCycle: AvatarColor[] = useMemo(() => ['purple', 'teal', 'magenta', 'orange'], []);

  const handleNavigateToApplication = useCallback(() => {
    if (!inProgressSubmissions.length) {
      startNewEntityApplication();
    } else {
      goToExistingApplication();
    }
  }, [goToExistingApplication, inProgressSubmissions.length, startNewEntityApplication]);

  const submissionAvatarColor = useMemo(() => {
    const existingAccountsIndex = entityAccounts.length;
    return avatarColorCycle[existingAccountsIndex % avatarColorCycle.length];
  }, [avatarColorCycle, entityAccounts.length]);

  const inProgressAvatarColor = useCallback(
    (index: number) => {
      const existingAccountsIndex = entityAccounts.length + (hasASubmittedSubmission ? 1 : 0);
      return avatarColorCycle[(existingAccountsIndex + index) % avatarColorCycle.length];
    },
    [avatarColorCycle, entityAccounts.length, hasASubmittedSubmission],
  );

  const showCreateEntityButton = useMemo(() => {
    if (entityOnboardingEnabled) {
      return isKyc1Complete() && !inProgressSubmissions.length;
    }
    return isKyc1Complete();
  }, [entityOnboardingEnabled, inProgressSubmissions.length, isKyc1Complete]);

  const hasNotCompletedKyc1 = useMemo(() => !isKyc1Complete() && isUserVerified(), [isKyc1Complete, isUserVerified]);

  return (
    <>
      <Modal title='Switch account' position='bottom' triggerElement={children} open={open} onOpenChange={setOpen}>
        <FlexLayout direction='column' spacing={8}>
          {entityAccounts.map((entity) => {
            let avatarColor: AvatarColor = 'personal';
            if (entity.entityColorIndex !== undefined) {
              const color = Object.entries(EntityColor).find(([, value]) => value === entity.entityColorIndex);
              avatarColor = color ? (color[0].toLocaleLowerCase() as AvatarColor) : 'personal';
            }
            return (
              <ListItem onClick={() => selectedAccount !== entity.uuid && onSelectAccount(entity)} key={entity.uuid}>
                <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                  <FlexLayout alignItems='center' spacing={16}>
                    <Avatar color={avatarColor} text={entity.initials?.slice(0, 1)} />
                    <Body weight='emphasis'>{entity.name}</Body>
                  </FlexLayout>
                  {switchingAccountError !== entity.uuid && selectedAccount === entity.uuid && (
                    <Tick className='text-color-text-accent' />
                  )}
                  {switchingAccountError !== entity.uuid && switchingAccount === entity.uuid && (
                    <Loading className='animate-spin text-color-text-primary' />
                  )}
                  {switchingAccountError === entity.uuid && <AlertFilled className='text-color-text-error' />}
                </FlexLayout>
              </ListItem>
            );
          })}
          {entityHistoryFetching && (
            <FlexLayout direction='column' className='w-full items-center'>
              <Loading className='animate-spin' />
            </FlexLayout>
          )}
          {!entityHistoryFetching && (
            <>
              {!!hasASubmittedSubmission && (
                <ListItem>
                  <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                    <FlexLayout alignItems='center' spacing={16}>
                      <Avatar size='sm' color={submissionAvatarColor}>
                        <Clock className='h-12 w-12 text-color-text-inverse' />
                      </Avatar>
                      <FlexLayout direction='column' alignItems='start'>
                        <Body weight='emphasis'>Entity account pending</Body>
                        <Body size='small' color='secondary'>
                          Our team is currently reviewing your application
                        </Body>
                      </FlexLayout>
                    </FlexLayout>
                  </FlexLayout>
                </ListItem>
              )}
              {inProgressSubmissions.map((submissions, index) => (
                <ListItem onClick={handleNavigateToApplication} key={submissions.id}>
                  <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                    <FlexLayout alignItems='center' spacing={16}>
                      <Avatar size='sm' color={inProgressAvatarColor(index)}>
                        <Body className='text-color-text-inverse'>{index + 1}</Body>
                      </Avatar>
                      <Body weight='emphasis'>Continue Entity Application</Body>
                    </FlexLayout>
                  </FlexLayout>
                </ListItem>
              ))}
            </>
          )}
          {showCreateEntityButton && (
            <ListItem onClick={onCreateEntity}>
              <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                <FlexLayout alignItems='center' spacing={16}>
                  <AddInCircle />
                  <Body weight='emphasis'>Create entity account</Body>
                </FlexLayout>
              </FlexLayout>
            </ListItem>
          )}
          {hasNotCompletedKyc1 && (
            <ListItem onClick={navigateToProfileVerification}>
              <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                <FlexLayout alignItems='center' spacing={16}>
                  <Avatar size='sm' color='secondary'>
                    <Locked />
                  </Avatar>
                  <FlexLayout direction='column' alignItems='start'>
                    <Body weight='emphasis'>Create entity account</Body>
                    <Body size='small' color='secondary'>
                      Please complete photo verification to create an entity account
                    </Body>
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </ListItem>
          )}
        </FlexLayout>
      </Modal>
      {showCreateEntityModal && <CreateEntityModal onClose={() => setShowCreateEntityModal(false)} />}
    </>
  );
});

export { SwitchAccountModal };

import React, { useCallback, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Search } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { observer } from 'mobx-react-lite';
import { useMeasure } from 'react-use';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { assetListTabs, AssetListTabType, AssetListTabTypes } from './AssetList.types';
import { AssetListTable } from './AssetListTable';
import { AssetCategoriesTabs } from './components/AssetCategoriesTabs';
import { AssetListTabs } from './components/AssetListTabs';

interface Props {
  path: 'Assets' | 'Category';
}

const HEADER_HEIGHT = 5 * 16;

export const AssetList: React.FC<Props> = observer((props) => {
  const { path } = props;
  const { params, navigate } = useNavigateRoute();
  const [selectedTab, setSelectedTab] = useState<AssetListTabType>('assets');
  const { filter: assetTabPathPram } = params as { filter?: AssetListTabTypes };
  const [selectedAssetTab, setSelectedAssetTab] = useState<AssetListTabTypes>(
    assetTabPathPram ?? AssetListTabTypes.ALL,
  );
  const [search, setSearch] = useState<string>('');
  const { categoryId } = params as { categoryId?: string };
  const isXs = useTailwindBreakpoint('xs');

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const onSelectAssetTab = useCallback(
    (filter: AssetListTabTypes) => {
      navigate(NavigationURLs.AssetFilter, { pathParams: { filter } });
      setSelectedAssetTab(filter);
    },
    [navigate],
  );

  const onSelectPageTab = useCallback(
    (tab: AssetListTabType) => {
      // TODO: remove when we convert categories to Aviary
      if (tab === 'categories') {
        return navigate(NavigationURLs.AssetsCategories);
      }
      setSelectedTab(tab);
    },
    [navigate],
  );

  return (
    <>
      <FlexLayout direction='column' className='h-full p-16 sm:pr-0 sm:pt-24'>
        <Card className='h-full w-full overflow-hidden py-16 @container' ref={ref}>
          <FlexLayout direction='column' spacing={8}>
            <FlexLayout direction='row' className='w-full items-center justify-between px-16'>
              <EnhancedTabs<AssetListTabType> tabs={assetListTabs} onChange={onSelectPageTab} value={selectedTab} />
              <FlexLayout direction='row'>
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={isXs ? 'Search...' : 'Search assets...'}
                  leading={<Search />}
                  containerClassName='w-[8rem] @sm:w-[13rem] @md:w-[16rem]'
                  onClear={() => setSearch('')}
                />
              </FlexLayout>
            </FlexLayout>
            <FlexLayout direction='row' className='w-full items-center overflow-x-auto px-16 py-4 pb-8' spacing={8}>
              <AssetListTabs
                onSelectTab={onSelectAssetTab}
                selectedTab={selectedAssetTab}
                activePath={path === 'Assets'}
              />
              <AssetCategoriesTabs selectedCategoryId={categoryId} activePath={path === 'Category'} />
            </FlexLayout>
            <div className='flex flex-col overflow-auto' style={{ height: height - HEADER_HEIGHT }}>
              <AssetListTable search={search} selectedTab={selectedAssetTab} />
            </div>
          </FlexLayout>
        </Card>
      </FlexLayout>
    </>
  );
});

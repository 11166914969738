import React from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Tick } from '@swyftx/aviary/icons/outlined';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

interface CompleteProfileStepProps {
  completed: boolean;
}

export const CompleteProfileStep: React.FC<CompleteProfileStepProps> = (props) => {
  const { completed } = props;
  const { navigate } = useNavigateRoute();

  return (
    <FlexLayout direction='row' className='mx-8 w-full @md:w-1/3' spacing={24}>
      <Image image='update_profile' fileType='svg' className='w-60' />
      <FlexLayout direction='column' spacing={8}>
        <Avatar>
          {completed ? (
            <Tick className='h-14 w-14 text-color-text-inverse' />
          ) : (
            <Body color='inverse' size='small'>
              1
            </Body>
          )}
        </Avatar>
        <Heading size='h6' color={completed ? 'secondary' : 'primary'}>
          Complete your profile
        </Heading>
        <Body color='secondary' size='xsmall'>
          All Swyftx users are required to complete profile verification. This covers personal details, ID verification
          and a few security questions.
        </Body>
        {!completed && (
          <FlexLayout direction='row' className='mt-4'>
            <Button onClick={() => navigate(NavigationURLs.Onboarding)}>Complete Profile</Button>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { AutoInvestContextProvider } from 'src/lib/auto-invest/context';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AutoInvestCreatePage } from './AutoInvestCreatePage';
import AutoInvestLandingPage from './AutoInvestLandingPage';

export const AutoInvestRoutes: RouteObject[] = [
  {
    path: NavigationURLs.AutoInvest,
    element: <PrivateRoute element={<AutoInvestLandingPage />} />,
  },
  {
    path: NavigationURLs.AutoInvestCreate,
    element: (
      <PrivateRoute
        element={
          <AutoInvestContextProvider>
            <AutoInvestCreatePage />
          </AutoInvestContextProvider>
        }
      />
    ),
  },
];

export class OnReady {
  private _waiters: Array<(value: boolean) => void> = [];

  private _ready = false;

  setReady(ready: boolean) {
    this._ready = ready;
    if (this._ready) {
      while (this._waiters.length) {
        const resolve = this._waiters.pop();
        if (resolve) resolve(true);
      }
    }
  }

  isReady(): Promise<boolean> {
    if (this._ready) return Promise.resolve(true);
    return new Promise((resolve) => {
      this._waiters.push(resolve);
    });
  }
}

import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { formatNumberWithCommasAndAllowTrailingDot, isValidDecimalString } from '@shared/utils';

import { usePriceScale } from './usePriceScale';

type Props = {
  limitAsset?: Asset;
  amount?: string;
  ignoreAssetScale?: boolean;
  onChange?: (value?: string) => void;
};

const useTradeValue = ({ limitAsset, amount = '', ignoreAssetScale = true, onChange }: Props = {}) => {
  const { priceScale } = usePriceScale(limitAsset, ignoreAssetScale);

  const parseTradeValue = useCallback(
    (value: string, truncate = false, side: 'buy' | 'sell' | 'none' = 'buy') => {
      const isValid: boolean = isValidDecimalString(value, priceScale);

      if (!isValid && truncate) {
        // If side is 'none' don't apply any rounding logic
        if (side === 'none') {
          return Big(value).toFixed(priceScale, 0);
        }

        // round half-up when its a buy, down when its a sell
        const roundingMode = side === 'buy' ? 1 : 0;
        return Big(value).round(priceScale, roundingMode).toFixed(priceScale);
      }

      if (!limitAsset || !isValid) {
        return '';
      }

      if (value === '.') return '0.';

      return value;
    },
    [limitAsset, priceScale],
  );

  const formattedAmount = useMemo(() => {
    if (!limitAsset || !amount) return amount;

    // Don't format the number if its less than 0
    if (Big(amount).lte(1)) return amount;

    // format numbers with thousand separators
    return formatNumberWithCommasAndAllowTrailingDot(amount);
  }, [limitAsset, amount]);

  const onChangeValue = useCallback(
    (value: string) => {
      // Prevent users from entering '00000' etc
      if (value.length > 1 && value.split('').every((v) => v === '0')) return;

      const unformattedValue = value.replace(/[,$≈\s]/g, '');

      const isValid: boolean = isValidDecimalString(unformattedValue, priceScale);

      if (!isValid) return;

      const newValue = parseTradeValue(unformattedValue);
      if (onChange && newValue !== undefined) onChange(newValue);
    },
    [onChange, parseTradeValue, priceScale],
  );

  return { parseTradeValue, formattedAmount, onChangeValue, priceScale };
};

export { useTradeValue };

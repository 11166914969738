import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Stack, Notification } from '@swyftx/react-web-design-system';

import { WithdrawAddressDropdown, WithdrawReasonDropdown } from '@global-components/Dropdown';
import { AssetInput } from '@global-components/Input';
import { Balance } from '@global-components/Input/AssetInput/AssetInput';
import { SendReceiveCryptoWarning } from '@global-components/Transactions';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { WithdrawalAddress, WithdrawalReasonEnum, withdrawalService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useAccountFeatures } from '@hooks/Account';

import { observer } from 'mobx-react-lite';

import { AccountHolderNameInput } from './AccountHolderNameInput';
import { WithdrawFaqAccordion } from './WithdrawFaqAccordion';
import { useWithdrawFunds } from './WithdrawSendDetails.utils';
import { useWithdrawDescription } from '../../hooks/Withdraw/useWithdrawDescription';

type Props = {
  withdrawDisabled?: boolean;
  loading?: boolean;
  setWithdrawAddress: (withdrawalAddress: WithdrawalAddress) => void;
  setWithdrawAmount: (withdrawalAmount: string) => void;
  setWithdrawReason: (WithdrawalReasonEnum: WithdrawalReasonEnum) => void;
  withdrawAddress?: WithdrawalAddress;
  setError: (error: string) => void;
  withdrawReason?: WithdrawalReasonEnum;
  onClose: () => void;
  onReceive?: () => void;
  withdrawAmount: string;
  countryAsset: Asset;
  baseAsset: Asset;
  asset: Asset;
  error: string;
  updatedNameOnAccount?: string;
  setUpdatedNameOnAccount: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const WithdrawSendDetails: React.FC<Props> = observer(
  ({
    withdrawDisabled,
    setWithdrawAddress,
    setWithdrawAmount,
    setWithdrawReason,
    withdrawAddress,
    withdrawAmount,
    withdrawReason,
    onClose,
    onReceive,
    countryAsset,
    baseAsset,
    setError,
    asset,
    error,
    updatedNameOnAccount,
    setUpdatedNameOnAccount,
    loading = false,
  }) => {
    const { t } = useTranslation('common', { keyPrefix: 'withdrawalDetails' });
    const isCrypto = useMemo(() => asset.assetType === AssetType.Crypto, [asset]);
    const [withdrawValue, setWithdrawValue] = useState<string>(withdrawAmount);
    const [isAssetFlipped, setIsAssetFlipped] = useState<boolean>(false);
    const { minWithdrawal, maxWithdrawal, countryWithdrawalLimit } = useWithdrawFunds(
      asset,
      withdrawAmount,
      withdrawReason,
      withdrawAddress,
    );
    const { withdrawDescription } = useWithdrawDescription(countryAsset.code);
    const { convertRate } = RatesStore.useRatesStore;
    const { canWithdraw } = useAccountFeatures();

    const addressTitle = 'address';
    const inputTitle = t(`labels.howMuch${isCrypto ? 'Crypto' : 'Fiat'}`, { code: asset.code });
    const reasonTitle = t(`labels.reasonTitle${isCrypto ? 'Crypto' : 'Fiat'}`);
    const decimals = useMemo(() => {
      if (!withdrawAddress || !asset) return undefined;

      const minWithdrawalIncrementE = withdrawalService.getMinWithdrawalIncrementE(withdrawAddress, asset);
      return minWithdrawalIncrementE * -1;
    }, [asset, withdrawAddress]);

    const requiresUpdatedNameOnAccount = useMemo(
      () => asset.code === 'AUD' && withdrawAddress && !withdrawAddress?.address_details.nameOnAccount,
      [asset.code, withdrawAddress],
    );

    useEffect(() => {
      const isAssetBase = asset.code === baseAsset.code;
      if (!withdrawAmount.length) {
        setError('');
      } else if (withdrawAddress && (!Number(withdrawAmount) || Number(withdrawAmount) < Number(minWithdrawal))) {
        const minBase = convertRate(asset, baseAsset, minWithdrawal, 'midPrice')?.toFixed(baseAsset.price_scale);

        if (isAssetBase) {
          setError(t('minErrorBase', { amount: formatCurrency(minWithdrawal, asset) }));
        } else {
          setError(
            t('minError', {
              amount: formatCurrency(minWithdrawal, asset, { appendCode: true }),
              minBase: formatCurrency(minBase, baseAsset, { appendCode: true }),
            }),
          );
        }
      } else if (Number(withdrawAmount) > Number(maxWithdrawal)) {
        const maxBase = convertRate(asset, baseAsset, maxWithdrawal || '', 'midPrice')?.toFixed(baseAsset.price_scale);

        if (Number(withdrawAmount) < (countryWithdrawalLimit?.remaining.toNumber() || 0)) {
          setError(
            t('maxBalance', {
              amount: formatCurrency(Number(maxWithdrawal), asset),
            }),
          );
          return;
        }

        if (isAssetBase) {
          setError(
            t('maxErrorBase', {
              amount: formatCurrency(Number(maxWithdrawal), asset),
            }),
          );
        } else {
          setError(
            t('maxError', {
              amount: formatCurrency(Number(maxWithdrawal), asset, { appendCode: true }),
              maxBase: formatCurrency(maxBase, baseAsset, { appendCode: true }),
            }),
          );
        }
      } else if (withdrawAddress) {
        const network = withdrawalService.getAddressAssetNetwork(withdrawAddress, asset);
        const decimalString = withdrawAmount.split('.')?.[1];

        if (decimals && decimalString && decimalString.length > decimals) {
          if (decimals === 0) {
            setError(t('maxDecimalsZero', { networkName: network?.networkName }));
          } else {
            setError(
              t('maxDecimals', { networkName: network?.networkName, decimals, plural: decimals !== 1 ? 's' : '' }),
            );
          }
        } else {
          setError('');
        }
      } else {
        setError('');
      }
    }, [
      minWithdrawal,
      maxWithdrawal,
      withdrawAmount,
      withdrawAddress,
      asset,
      baseAsset,
      convertRate,
      setError,
      t,
      decimals,
      updatedNameOnAccount,
      requiresUpdatedNameOnAccount,
      countryWithdrawalLimit?.remaining,
    ]);

    if (!asset || !countryAsset || !baseAsset) return null;

    return (
      <Stack spacing={2}>
        {canWithdraw(asset.assetType) && (
          <WithdrawAddressDropdown
            value={withdrawAddress?.id.toString() || ''}
            onChange={setWithdrawAddress}
            title={addressTitle}
            asset={asset}
          />
        )}
        {isCrypto && !withdrawDisabled && !loading && withdrawAddress && <SendReceiveCryptoWarning type='send' />}
        {withdrawAddress && asset.code === 'KDA' && (
          <Notification
            severity='warning'
            title='Kadena (KDA) has limited functionality'
            alertTitleProps={{ color: 'warning.main' }}
            sx={{ backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'grey.50' : 'grey.900') }}
          >
            You must use Chain 2 when withdrawing KDA from Swyftx.
          </Notification>
        )}
        {requiresUpdatedNameOnAccount && (
          <AccountHolderNameInput value={updatedNameOnAccount} onChange={setUpdatedNameOnAccount} />
        )}
        {!withdrawDisabled && withdrawAddress && (
          <>
            <AssetInput
              loading={loading}
              onBuyAsset={onClose}
              onReceive={onReceive}
              onChange={(val) => {
                setWithdrawAmount(val[asset.id]);
                setWithdrawValue(val.value);
              }}
              onAssetFlippedTo={(flipAsset) => setIsAssetFlipped(flipAsset.id === baseAsset.id)}
              balanceToUse={Balance.Trading}
              baseAsset={baseAsset}
              value={withdrawValue}
              title={inputTitle}
              flippable
              initialFlip={isAssetFlipped}
              error={error}
              asset={asset}
              slider
              maxDecimals={decimals}
            />
            <WithdrawReasonDropdown
              loading={loading}
              onChange={setWithdrawReason}
              value={withdrawReason}
              title={reasonTitle}
            />
            {asset.id === countryAsset.id && (
              <Stack direction='column' spacing={2}>
                <Divider variant='fullWidth' orientation='horizontal' />
                <WithdrawFaqAccordion withdrawDescription={withdrawDescription} loading={loading} />
              </Stack>
            )}
          </>
        )}
      </Stack>
    );
  },
);

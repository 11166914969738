import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';

import { getEmptyIcon, getEmptyContent } from './EmptyContent';
import { useOrderHistoryTable } from './hooks/useOrderHistoryTable';
import { OrderHistoryTableData, OrdersTileTabs } from './ordersTile.types';

interface Props {
  selectedAsset: Asset;
  filterCurrentAsset?: boolean;
  hideCancelled?: boolean;
}

export const OrderHistory: React.FC<Props> = observer((props) => {
  const { filterCurrentAsset, selectedAsset, hideCancelled } = props;
  const filterAsset = filterCurrentAsset ? selectedAsset : undefined;
  const { openModal } = useModal();
  const { headers, sort, onSort, data } = useOrderHistoryTable(filterAsset, hideCancelled);
  const isXs = useTailwindBreakpoint('xs');

  const onSelectItem = useCallback(
    (item: EnhancedTableData<OrderHistoryTableData>) => {
      openModal(Modals.OrderDetails, { orderId: item.id.value, transaction: item.value, deleteModal: false });
    },
    [openModal],
  );

  const tableElement = useMemo(
    () => (
      <EnhancedTable<OrderHistoryTableData>
        data-containter='orders-history-table'
        headers={headers}
        data={data}
        sort={sort}
        onSort={onSort}
        onClickItem={onSelectItem}
        rowClassName='h-16'
        itemsPerPage={20}
        lazyLoad={!isXs}
      >
        <FlexLayout direction='column' className='h-full items-center justify-center' spacing={8}>
          {getEmptyIcon(OrdersTileTabs.OrderHistory)}
          <Body color='primary'>{getEmptyContent(OrdersTileTabs.OrderHistory)}</Body>
        </FlexLayout>
      </EnhancedTable>
    ),
    [data, headers, isXs, onSelectItem, onSort, sort],
  );

  return <>{tableElement}</>;
});

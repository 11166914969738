import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { DashboardTileEnum } from '@shared/enums';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { DashboardContext } from '@routes/Dashboard';
import UserService from '@services/UserService';

import { AssetRow } from '@Dashboard/components/Tiles/components';

import { observer } from 'mobx-react-lite';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

const ASSET_ROW_HEIGHT = 20;
const ASSET_ROW_SPACING = 16;
const emptyAssetExamples: string[] = ['BTC', 'ETH'];

type Props = {
  width: number;
};

const FavouritesTileEmptyState: React.FC<Props> = observer(({ width }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'favouritesTile' });
  const baseAsset = useBaseAsset();
  const { userProfile, setFavourites } = UserStore.useUserStore;
  const { setEditTiles, saveTiles, editMode, tiles } = useContext(DashboardContext);
  const { bx } = useContentBreakpoint();
  const { navigateToAsset } = useAssetNavigation();

  const notInterested = () => {
    const newTiles = [...tiles];
    const tileIndex = newTiles.findIndex((tile) => tile.name === DashboardTileEnum.FAVOURITES);
    newTiles[tileIndex].display = false;

    setEditTiles(newTiles);
    saveTiles(userProfile);
  };

  const addToMyList = () => {
    emptyAssetExamples.forEach((code) => {
      const asset = assetService.getAssetByCode(code);

      if (!asset) return;

      UserService.UpdateUserSettings({
        data: { favouriteAsset: { assetId: asset.id, favStatus: true } },
      });

      setFavourites(asset.id, true);
    });
  };

  return (
    <Stack
      direction='column'
      width='100%'
      alignItems='flex-start'
      justifyContent='space-between'
      height='100%'
      spacing={3}
    >
      <Stack spacing={`${ASSET_ROW_SPACING}px`} flex={1} width='100%' sx={{ opacity: 0.5 }}>
        {emptyAssetExamples.map((code) => {
          const asset = assetService.getAssetByCode(code);

          if (!asset) return null;

          return (
            <AssetRow
              height={ASSET_ROW_HEIGHT}
              width={width}
              asset={asset}
              isFavourite
              canUnFavourite={false}
              baseAsset={baseAsset}
              key={asset.id}
              idKey={asset.code}
              onSelectAsset={(selectedAsset) => navigateToAsset(selectedAsset, false)}
            />
          );
        })}
      </Stack>
      <Stack direction='column' alignItems='center' justifyContent='flex-start' width='100%' height='100%'>
        <Typography fontSize={bx({ xs: 16, md: 18 })} fontWeight={600}>
          {t('labels.keepTrack')}
        </Typography>
        <Typography color='text.secondary' fontSize={bx({ xs: 14, md: 16 })}>
          {t('labels.getStarted')}
        </Typography>
        <Stack
          direction='row'
          spacing={1}
          marginTop={bx({ xs: '.5rem !important', md: '1rem !important' })}
          color='text.secondary'
        >
          <Button
            id='buttonLabels.notInterested'
            variant='outlined'
            color='inherit'
            onClick={notInterested}
            disabled={editMode}
          >
            {t('buttonLabels.notInterested')}
          </Button>
          <Button
            id='buttonLabels.addToList'
            variant='contained'
            color='primary'
            onClick={addToMyList}
            disabled={editMode}
          >
            {t('buttonLabels.addToList')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
});

export { FavouritesTileEmptyState };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Document } from '@swyftx/aviary/icons/outlined';

import { SearchInputAviary } from '@global-components/Input/SearchInput/SearchInputAviary';

import { WalletLayoutType, WalletListSort } from '@routes/Wallet/types';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { DustSmallBalancesButton } from 'src/lib/markets/components';
import { usePortfolioReport } from 'src/lib/portfolio/hooks/usePortfolioReport';
import { useDemoMode } from 'src/lib/utils/hooks';

import { WalletListHideZeroBalancesFilter, WalletListLayoutFilter, WalletListSortFilter } from './components';

type Props = {
  search: string;
  layout: WalletLayoutType;
  sort: WalletListSort;
  setSearch: (newSearch: string) => void;
  setLayout: (newLayout: WalletLayoutType) => void;
  setSort: React.Dispatch<React.SetStateAction<WalletListSort>>;
};

const WalletListFilterBar: React.FC<Props> = observer(({ search, layout, sort, setSearch, setLayout, setSort }) => {
  const { downloadReport } = usePortfolioReport();
  const { isDemo } = useDemoMode();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  return (
    <FlexLayout
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={8}
      className='w-full @container'
    >
      <FlexLayout direction='row' spacing={8} alignItems='center' className='w-full @sm:w-auto'>
        <Body weight='bold' color='primary' size='large'>
          All wallets
        </Body>
      </FlexLayout>

      <FlexLayout direction='row' justifyContent='center' alignItems='center' spacing={8} className='h-full'>
        {isFeatureEnabled(AppFeature.PortfolioBalanceReport) && (
          <Button
            layout='icon'
            disabled={isDemo}
            variant='outlined'
            leadingIcon={<Document className='h-20 w-20' />}
            tooltip='Download balances report'
            onClick={downloadReport}
          />
        )}
        <DustSmallBalancesButton layout='icon' />
        <WalletListHideZeroBalancesFilter />
        <SearchInputAviary
          id='walletList-search-filter'
          placeholder='Search for wallet'
          onChange={(e) => setSearch(e.target.value)}
          onDelete={() => {
            setSearch('');
          }}
          value={search}
          containerClassName='bg-color-background-surface-primary border border-color-border-main h-[36px] rounded-[16px] max-w-[190px] hidden @md:flex'
        />
        <WalletListLayoutFilter className='hidden @md:block' layout={layout} setLayout={setLayout} />
        <WalletListSortFilter sort={sort} setSort={setSort} />
      </FlexLayout>
    </FlexLayout>
  );
});

export { WalletListFilterBar };

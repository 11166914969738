import { useCallback, useEffect, useState } from 'react';

type Options = {
  initialItemsLength?: number;
  fromBottomTrigger?: number;
  addLengthAmount?: number;
};

const DEFAULTS = {
  initialItemsLength: 15,
  fromBottomTrigger: 50,
  addLengthAmount: 10,
};

export const useInfiniteScroll = (el: HTMLElement | null, items: Array<any>, options?: Options) => {
  const [isBottom, setIsBottom] = useState(false);
  const [shownItems, setShownItems] = useState(
    items.slice(0, (options?.initialItemsLength || DEFAULTS.initialItemsLength) - 1),
  );

  // check if we're at the bottom
  const onScroll = useCallback(() => {
    if (el && items.length !== shownItems.length) {
      const { scrollTop } = el;
      const { scrollHeight } = el;

      if (scrollTop && scrollHeight) {
        if (
          scrollTop + el?.getBoundingClientRect().height + (options?.fromBottomTrigger || DEFAULTS.fromBottomTrigger) >=
          scrollHeight
        ) {
          setIsBottom(true);
        } else {
          setIsBottom(false);
        }
      }
    }
  }, [el]);

  // update the shown items
  useEffect(() => {
    if (isBottom) {
      setShownItems((current) => {
        const addLengthAmount = options?.addLengthAmount || DEFAULTS.addLengthAmount;
        const currentIndex = shownItems.length;
        const newSliceIndex = currentIndex + addLengthAmount;

        if (newSliceIndex > items.length) {
          return items;
        }

        return current.concat(items.slice(currentIndex, newSliceIndex));
      });

      setIsBottom(false);
    }
  }, [isBottom]);

  // listen to the scroll
  useEffect(() => {
    el?.addEventListener('scroll', onScroll);
    return () => el?.removeEventListener('scroll', onScroll);
  }, [el]);

  // reset on items change
  useEffect(() => {
    setShownItems(items.slice(0, options?.initialItemsLength || DEFAULTS.initialItemsLength));
  }, [items]);

  return shownItems;
};

import React from 'react';

import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { AssetType } from '@shared/api';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useWithdrawalFundsImportantInformation } from '@services/WithdrawService';

import { observer } from 'mobx-react-lite';

const cryptoItems = [
  'New addresses must be confirmed via Email and SMS',
  'Always double check the address before transferring',
  'Crypto withdrawn to an incorrect address is unrecoverable.',
  "If you're unsure, send a small test withdrawal first",
  'Transaction monitoring programs are used by Swyftx and in some circumstances may we require enhanced verification on your account.',
];

interface Props {
  assetType: AssetType;
  assetCode: string;
}

export const SingleWalletSendInfo: React.FC<Props> = observer((props) => {
  const { assetType } = props;
  const countryAsset = useCountryAsset();

  const fiatItem = useWithdrawalFundsImportantInformation(countryAsset?.code).informationList;
  const items = assetType === AssetType.Fiat ? fiatItem : cryptoItems;

  return (
    <Notification severity='info' title='Important information'>
      <ul style={{ listStyleType: 'disc' }}>
        {items.map((item) => (
          <li key={item}>
            <Body size='small'>{item}</Body>
          </li>
        ))}
      </ul>
    </Notification>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@swyftx/react-web-design-system';

import { AppStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { observer } from 'mobx-react-lite';

import { PortfolioPerformanceDetails } from './components/PortfolioPerformanceDetails';
import { PortfolioPerformanceLineChart } from './components/PortfolioPerformanceLineChart';

const PortfolioPerformanceTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const { t } = useTranslation('dashboard');
  const { isDemo } = AppStore.useAppStore;

  const {
    portfolio: { accountValue },
    deposited,
    withdrawn,
    orders,
    traded,
  } = usePortfolio({ balanceKey: 'all' });

  const baseAsset = useBaseAsset();

  const showDetails = deposited.gt(0) || withdrawn.gt(0) || orders || traded.gt(0) || accountValue.gt(0);

  return (
    <DashboardBaseTile
      dragProps={dragProps}
      tileName={tile.name}
      title={t('portfolioPerformanceTile.title')}
      contentSx={{ height: '100%' }}
    >
      <Stack width='100%' height='100%' justifyContent='space-between' spacing={0.5} padding={2}>
        <PortfolioPerformanceLineChart />

        {showDetails && !isDemo && (
          <PortfolioPerformanceDetails
            deposited={deposited}
            withdrawn={withdrawn}
            baseAsset={baseAsset}
            orders={orders}
            traded={traded}
          />
        )}
      </Stack>
    </DashboardBaseTile>
  );
});

PortfolioPerformanceTile.displayName = 'PortfolioPerformanceTile';

export { PortfolioPerformanceTile };

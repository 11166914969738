import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Grid } from '@swyftx/react-web-design-system';

import Recaptcha from '@components/molecules/Recaptcha';

import { StyledForm } from '@global-components/Form/Form.styled';
import PasswordRequirements from '@global-components/Input/PasswordInput/PasswordRequirements';

import { useRecaptcha } from '@hooks/useRecaptcha/useRecaptcha';
import { RegisterContext } from '@routes/Register/Register.context';
import { SetPasswordFormValues, FormStepProps } from '@routes/Register/types';

import { RegisterTranslationKey } from '@Register/translations';

import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { SetPasswordSchema } from './SetPassword.schema';
import { AcceptTermsAndConditions, RegisterReferralInput } from './components';

const SetPasswordForm: React.FC<FormStepProps> = observer(
  ({ captchaToken, setCaptchaToken, formData, setFormData }) => {
    const { captchaRefresh, setCaptchaRefresh } = useRecaptcha();
    const { prevRegisterStep, termsAccepted, createAccount, error, hasDuplicateInfo } = useContext(RegisterContext);
    const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
    const { t } = useTranslation(RegisterTranslationKey);
    const {
      control,
      handleSubmit,
      getValues,
      formState: { errors, isSubmitting, isValid },
      watch,
      trigger,
    } = useForm<SetPasswordFormValues>({
      defaultValues: {
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        referralCode: formData.referralCode,
        captchaToken: formData.captchaToken,
      },
      resolver: zodResolver(SetPasswordSchema, undefined, { rawValues: true }),
      mode: 'onChange',
    });

    const onSubmit = async (submitted: SetPasswordFormValues) => {
      setCreatingAccount(true);
      setFormData((prevState) => ({
        ...prevState,
        ...submitted,
      }));

      const success = await createAccount({ ...formData, ...submitted });
      if (success) {
        setCreatingAccount(false);
        setCaptchaRefresh(true);
      } else {
        setCreatingAccount(false);
        setCaptchaRefresh(!captchaRefresh);
        setCaptchaToken('');
        setFormData((prevState) => ({ ...prevState, captchaToken: '' }));
      }
    };

    const errorMessage = useMemo(() => {
      if (hasDuplicateInfo) return t('errors.duplicatePhone');
      if (error) return error;
      return undefined;
    }, [hasDuplicateInfo, t, error]);

    const password = watch('password');

    React.useEffect(() => {
      if (password !== undefined) {
        trigger('confirmPassword');
      }
    }, [password, trigger]);

    return (
      <div className='h-full w-full p-4'>
        <FlexLayout direction='column' className='h-full w-full py-24' justifyContent='space-between'>
          <StyledForm onSubmit={handleSubmit(onSubmit)} sx={{ height: '100%' }}>
            <FlexLayout justifyContent='space-between' className='h-full' direction='column'>
              <FlexLayout direction='column'>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <FlexLayout direction='column' spacing={4} className='w-full'>
                        <FlexLayout spacing={0} alignItems='start' justifyContent='start'>
                          <Body size='small' weight='bold' color='primary'>
                            Create password
                          </Body>
                        </FlexLayout>

                        <Input
                          placeholder='Create password'
                          type='password'
                          onChange={field.onChange}
                          value={field.value}
                          id='setPassword.form.password'
                          className='pii-mask'
                          error={!!errors.password?.message?.length}
                        />
                        {errors.password?.message && (
                          <Body size='small' color='error'>
                            {errors.password.message}
                          </Body>
                        )}
                      </FlexLayout>
                      <PasswordRequirements
                        error={false}
                        type='create'
                        password={getValues().password}
                        confirmPassword={getValues().confirmPassword}
                      />
                    </>
                  )}
                />

                <Controller
                  name='confirmPassword'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FlexLayout direction='column' spacing={4} className='mt-24 w-full sm:mt-16'>
                      <FlexLayout spacing={0} alignItems='start' justifyContent='start'>
                        <Body size='small' weight='bold' color='primary'>
                          Re-enter password
                        </Body>
                      </FlexLayout>

                      <Input
                        placeholder='Re-enter password'
                        type='password'
                        onChange={field.onChange}
                        value={field.value}
                        id='setPassword.form.password'
                        className='pii-mask'
                        error={!!errors.confirmPassword?.message?.length}
                      />
                      {errors.confirmPassword?.message && (
                        <Body size='small' color='error'>
                          {errors.confirmPassword.message}
                        </Body>
                      )}
                    </FlexLayout>
                  )}
                />

                <FlexLayout spacing={16} direction='column'>
                  <AcceptTermsAndConditions />
                  <RegisterReferralInput />

                  {!captchaToken && (
                    <Controller
                      name='captchaToken'
                      control={control}
                      render={({ field }) => (
                        <Recaptcha
                          captchaRefresh={captchaRefresh}
                          id='signIn.form.recaptcha'
                          submitCaptcha={(token: string) => {
                            field.onChange(token);
                            setCaptchaToken(token);
                          }}
                        />
                      )}
                    />
                  )}
                </FlexLayout>

                {errorMessage && (
                  <Body color='error' size='small' className='mb-16 w-full text-center'>
                    {errorMessage}
                  </Body>
                )}
              </FlexLayout>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    id='setPassword.back.button'
                    onClick={prevRegisterStep}
                    size='lg'
                    color='primary'
                    variant='outlined'
                    className='w-full'
                  >
                    {t('setPassword.buttonLabels.back')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    id='setPassword.submit.button'
                    size='lg'
                    type='submit'
                    disabled={!isValid || !termsAccepted || creatingAccount || hasDuplicateInfo}
                    variant='filled'
                    loading={isSubmitting || creatingAccount}
                    className='w-full'
                  >
                    {t('setPassword.buttonLabels.create')}
                  </Button>
                </Grid>
              </Grid>
            </FlexLayout>
          </StyledForm>
        </FlexLayout>
      </div>
    );
  },
);

SetPasswordForm.displayName = 'SetPasswordForm';

export { SetPasswordForm };

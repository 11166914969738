import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { AtLeastBreakpoints } from '@swyftx/aviary/hooks/breakpoints';
import { ArrowChevronLeft, ArrowChevronRight } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';

import { useItemPagination } from '@hooks/useItemPagination/useItemPagination';

import { AutoInvestLearnCard } from './AutoInvestLearnCard';
import { AutoInvestLearnItem } from './AutoInvestLearnCards.types';
import { useBuildAutoInvestLearnCards } from './useBuildAutoInvestLearnCards';

type Props = {
  atLeast?: AtLeastBreakpoints;
};

const AutoInvestLearnCards: React.FC<Props> = ({ atLeast }) => {
  const { items } = useBuildAutoInvestLearnCards();

  const itemsPerPage = useMemo(() => {
    if (!atLeast?.sm) return 1;
    if (atLeast?.md) return 3;
    return 2;
  }, [atLeast]);

  const { page, hasAnotherPage, nextPage, prevPage } = useItemPagination<AutoInvestLearnItem>({
    items,
    itemsPerPage,
  });

  return (
    <ElementContainer
      className='w-full'
      title={atLeast?.sm ? 'Learn more about auto investment strategies' : 'Learn about auto invest strategies'}
      actions={
        <FlexLayout alignItems='center' className='w-full' justifyContent='end' spacing={8}>
          {itemsPerPage < items.length && (
            <>
              <Button
                size='sm'
                variant='outlined'
                layout='icon'
                leadingIcon={<ArrowChevronLeft />}
                disabled={page === 0}
                onClick={prevPage}
              />
              <Button
                size='sm'
                variant='outlined'
                layout='icon'
                onClick={nextPage}
                leadingIcon={<ArrowChevronRight />}
                disabled={!hasAnotherPage}
              />
            </>
          )}
        </FlexLayout>
      }
    >
      <FlexLayout spacing={24} className='flex-nowrap px-1 py-1 sm:overflow-hidden'>
        {items.map((item) => (
          <AutoInvestLearnCard
            key={item.title}
            style={{ transform: `translateX(calc(-${page * 100}% - ${page * 24}px)`, transition: 'transform 300ms' }}
            image={item.image}
            type={item.type}
            title={item.title}
            content={item.content}
            onClick={item.onClick}
            footer={item.footer}
          />
        ))}
      </FlexLayout>
    </ElementContainer>
  );
};

export { AutoInvestLearnCards };

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Links } from '@shared/constants';

import { countryTextFactory } from '@services/DepositService';
import { DepositFundsInfo, DepositOverview } from '@services/DepositService/DepositService.types';

import { CardDepositProvider, useCardDepositProvider } from './useCardDepositProvider';

export interface BanxaLimit {
  [key: string]: {
    min: number;
    max: number;
  };
}

export const DefaultBanxaLimit: BanxaLimit = {
  NZD: {
    min: 95,
    max: 24300,
  },
  AUD: {
    min: 91,
    max: 15000,
  },
};

export const useDepositDescription = (country: string) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });

  const cardPaymentProvider = useCardDepositProvider();

  const depositCreditCardText = useMemo((): DepositOverview => {
    const isStripeEnabled = cardPaymentProvider === CardDepositProvider.Stripe;
    const stripeOverView: DepositOverview = {
      limits: t('stripe.depositInfo.limits') as any,
      fees: t('stripe.depositInfo.fees') as any,
      processingTime: {
        description: t('stripe.depositInfo.processingTime') as any,
      },
      extraBankAddressDetails: {
        minimumOrder: 30,
        maximumOrder: 15000,
      },
      supportedCards: t('stripe.depositInfo.supportedCards'),
      notes: [
        t('stripe.depositInfo.notes.note1') as any,
        t('stripe.depositInfo.notes.note2') as any,
        t('stripe.depositInfo.notes.note3') as any,
      ],
    };

    const nzdBanxa: DepositOverview = {
      successfulDeposits: t('creditCard.nzd.successfulDeposits') as any,
      limits: t('creditCard.nzd.limits') as any,
      fees: t('creditCard.nzd.fees') as any,
      processingTime: { description: t('creditCard.nzd.processingTime') as any },
      verification: t('depositFundsInfo.aud.content.verification.description') as any,
      label: t('banxa.nzd.label') as any,
      extraBankAddressDetails: {
        minimumOrder: 95,
        maximumOrder: 24300,
      },
    };

    const audBanxa: DepositOverview = {
      successfulDeposits: t('creditCard.aud.successfulDeposits') as any,
      limits: t('creditCard.aud.limits') as any,
      fees: t('creditCard.aud.fees') as any,
      processingTime: { description: t('creditCard.aud.processingTime') as any },
      verification: t('depositFundsInfo.aud.content.verification.description') as any,
      notes: [
        t('banxa.aud.notes.note1') as any,
        t('banxa.aud.notes.note2') as any,
        t('banxa.aud.notes.note3') as any,
        t('banxa.aud.notes.note4') as any,
      ],
      label: t('banxa.aud.label') as any,
      extraBankAddressDetails: {
        minimumOrder: 91,
        maximumOrder: 15000,
      },
    };

    return countryTextFactory<DepositOverview>({
      nzd: isStripeEnabled ? stripeOverView : nzdBanxa,
      aud: isStripeEnabled ? stripeOverView : audBanxa,
      currentCountry: country,
    });
  }, [t, country]);

  const depositPayIdTransfer = useMemo(
    () =>
      countryTextFactory<DepositOverview>({
        nzd: {
          warning: t('payId.nzd.warning') as any,
          processingTime: {
            description: t('payId.nzd.processingTime') as any,
            link: Links.help.payID,
            linkDescription: t('payId.nzd.processingTimeLinkDescription') as any,
          },
          limits: t('payId.nzd.limits') as any,
          fees: t('payId.nzd.fees') as any,
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
        },
        aud: {
          warning: t('payId.aud.warning') as any,
          limits: t('payId.aud.limits') as any,
          fees: t('payId.aud.fees') as any,
          processingTime: {
            description: t('payId.aud.processingTime') as any,
            link: Links.help.payID,
            linkDescription: t('payId.aud.processingTimeLinkDescription') as any,
          },
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
        },
        currentCountry: country,
      }),
    [t, country],
  );

  const depositBankTransfer = useMemo(
    () =>
      countryTextFactory<DepositOverview>({
        nzd: {
          warning: t('bankTransfer.nzd.warning') as any,
          limits: t('bankTransfer.nzd.limits') as any,
          fees: t('bankTransfer.nzd.fees') as any,
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          processingTime: { description: t('bankTransfer.nzd.processingTime') as any },
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
          addressDetails: [
            {
              title: t('bankTransfer.nzd.addressDetails.accountNumber') as any,
              key: 'address',
            },
            {
              title: t('bankTransfer.nzd.addressDetails.particulars') as any,
              key: 'particulars',
            },
            {
              title: t('bankTransfer.nzd.addressDetails.code') as any,
              key: 'crownReference',
            },
          ],
        },
        aud: {
          warning: t('bankTransfer.aud.warning') as any,
          limits: t('bankTransfer.aud.limits') as any,
          fees: t('bankTransfer.aud.fees') as any,
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          processingTime: { description: t('bankTransfer.aud.processingTime') as any },
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
          addressDetails: [
            {
              title: t('bankTransfer.aud.addressDetails.bsb') as any,
              key: 'bsb',
            },
            {
              title: t('bankTransfer.nzd.addressDetails.accountNumber') as any,
              key: 'address',
            },
          ],
        },
        currentCountry: country,
      }),
    [t, country],
  );

  const depositFundsInfo = useMemo(
    () =>
      countryTextFactory<DepositFundsInfo>({
        aud: {
          title: t('depositFundsInfo.aud.title') as any,
          content: [
            {
              title: t('depositFundsInfo.aud.content.limits.title') as any,
              description: t('depositFundsInfo.aud.content.limits.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.fees.title') as any,
              description: t('depositFundsInfo.aud.content.fees.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.sameNameDeposit.title') as any,
              description: t('depositFundsInfo.aud.content.sameNameDeposit.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.verification.title') as any,
              description: t('depositFundsInfo.aud.content.verification.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.branchDeposit.title') as any,
              description: t('depositFundsInfo.aud.content.branchDeposit.description') as any,
            },
          ],
        },
        nzd: {
          title: t('depositFundsInfo.nzd.title') as any,
          content: [
            {
              title: t('depositFundsInfo.nzd.content.limits.title') as any,
              description: t('depositFundsInfo.nzd.content.limits.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.fees.title') as any,
              description: t('depositFundsInfo.nzd.content.fees.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.sameNameDeposit.title') as any,
              description: t('depositFundsInfo.nzd.content.sameNameDeposit.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.verification.title') as any,
              description: t('depositFundsInfo.nzd.content.verification.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.branchDeposit.title') as any,
              description: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
            },
          ],
        },
        currentCountry: country,
      }),
    [t, country],
  );

  return {
    depositCreditCardText,
    depositPayIdTransfer,
    depositBankTransfer,
    depositFundsInfo,
  };
};

import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

import { PriceAlertStatus } from '../../dynamicPriceAlerts.types';

type Props = {
  primary?: number;
  secondary?: number;
  status?: PriceAlertStatus[];
};

const useFetchDynamicPriceAlertAssetCounts = ({ primary, secondary, status }: Props = {}) => {
  const { userId } = UserStore.useUserStore;

  const fetchDynamicPriceAlertsAssetCounts = useCallback(async () => {
    const response = await api.endpoints.getAlertCounts({ query: { primary, secondary, status } });

    return response.data;
  }, [primary, secondary, status]);

  const { data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['dynamic-price-alerts-counts', primary, secondary, status, userId],
    queryFn: fetchDynamicPriceAlertsAssetCounts,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { data, error, isFetching, isPreviousData, isStale };
};

export { useFetchDynamicPriceAlertAssetCounts };

import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { DynamicPriceAlertsPage } from './DynamicPriceAlertsPage';

export const DynamicPriceAlertsRoutes: RouteObject[] = [
  {
    path: NavigationURLs.PriceAlerts,
    element: <PrivateRoute element={<DynamicPriceAlertsPage />} />,
  },
];

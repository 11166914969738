const ShopIDs = {
  index: 'shop',
  main: {
    index: 'shop.main',
    container: 'shop.main.container',
    title: 'shop.main.title',
    description: 'shop.main.description',
  },
  shopItem: {
    index: 'shop.shopItem',
    title: 'shop.shopItem.title',
    container: 'shop.shopItem.container',
    ledgerNanoX: 'shop.shopItem.ledgerNanoX',
    ledgerNanoSPlus: 'shop.shopItem.ledgerNanoSPlus',
  },
  shopInfo: {
    index: 'shop.shopInfo',
    container: 'shop.shopInfo.container',
    what: 'shop.shopInfo.what',
    need: 'shop.shopInfo.need',
  },
  colorPicker: {
    index: 'shop.colorPicker',
    container: 'shop.colorPicker.container',
  },
};

export { ShopIDs };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const QuickBuy: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg {...props} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.6585 2.66406C19.2214 2.66406 16.4362 5.44923 16.4362 8.88641C16.4362 12.3236 19.2213 15.1088 22.6585 15.1088C26.0942 15.1088 28.8809 12.3237 28.8809 8.88641C28.8809 5.44904 26.0942 2.66406 22.6585 2.66406ZM18.4877 8.88641C18.4877 6.58219 20.3544 4.7155 22.6585 4.7155C24.9616 4.7155 26.8295 6.58238 26.8295 8.88641C26.8295 11.1904 24.9616 13.0573 22.6585 13.0573C20.3544 13.0573 18.4877 11.1906 18.4877 8.88641Z'
      fill='currentColor'
    />
    <path
      d='M10.1938 4.76892C8.19435 4.76892 6.57438 6.38888 6.57438 8.38835V8.82411L6.68303 8.71545C7.0836 8.31488 7.73305 8.31488 8.13362 8.71545C8.53418 9.11601 8.53419 9.76546 8.13362 10.166L6.27557 12.0241C6.08321 12.2165 5.82231 12.3245 5.55027 12.3245C5.27824 12.3245 5.01734 12.2165 4.82498 12.0241L2.96693 10.166C2.56636 9.76546 2.56636 9.11601 2.96693 8.71545C3.3675 8.31488 4.01695 8.31488 4.41752 8.71545L4.52295 8.82088V8.38835C4.52295 5.2559 7.06137 2.71749 10.1938 2.71749C10.7603 2.71749 11.2195 3.17671 11.2195 3.7432C11.2195 4.30969 10.7603 4.76892 10.1938 4.76892Z'
      fill='currentColor'
    />
    <path
      d='M27.1747 19.0394C26.7741 18.6388 26.1247 18.6388 25.7241 19.0394L23.8659 20.8974C23.4654 21.298 23.4653 21.9474 23.8659 22.348C24.2664 22.7486 24.9159 22.7486 25.3165 22.3481L25.4248 22.2398V22.6761C25.4248 24.6756 23.8048 26.2955 21.8052 26.2955C21.2387 26.2955 20.7795 26.7548 20.7795 27.3213C20.7795 27.8877 21.2387 28.347 21.8052 28.347C24.9378 28.347 27.4762 25.8086 27.4762 22.6761V22.2421L27.5822 22.348C27.9827 22.7486 28.6322 22.7486 29.0327 22.348C29.4333 21.9475 29.4333 21.298 29.0327 20.8975L27.1747 19.0394Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.42672 16.0091C5.3327 16.0091 4.36678 16.4032 3.67932 17.1404C2.99925 17.8696 2.6665 18.8529 2.6665 19.9082V24.4509C2.6665 25.5042 2.9972 26.4874 3.67692 27.2171C4.36429 27.9549 5.33074 28.3486 6.42672 28.3486H13.3709C14.4669 28.3486 15.4332 27.9548 16.1202 27.2168C16.7996 26.487 17.1297 25.5038 17.1297 24.4509V19.9082C17.1297 18.8547 16.7997 17.8712 16.1203 17.1412C15.4333 16.4028 14.4669 16.0091 13.3709 16.0091H6.42672ZM4.71794 24.4509V21.712H15.0782V24.4509C15.0782 25.0806 14.8845 25.5334 14.6187 25.819C14.3606 26.0963 13.9603 26.2972 13.3709 26.2972H6.42672C5.83723 26.2972 5.43643 26.0962 5.17792 25.8187C4.91176 25.533 4.71794 25.0802 4.71794 24.4509ZM4.72849 19.6605H15.0678C15.0241 19.1573 14.8482 18.7855 14.6186 18.5387C14.3605 18.2614 13.9603 18.0605 13.3709 18.0605H6.42672C5.84066 18.0605 5.43933 18.261 5.17956 18.5395C4.94886 18.7869 4.77236 19.1587 4.72849 19.6605Z'
      fill='currentColor'
    />
  </svg>
);

export { QuickBuy };

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useDebounce } from 'react-use';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

import { useMarkets } from './useMarkets';
import { MarketAssetFilterType } from '../types/Markets.types';

export type MarketFilterSortType = 'rank' | 'id' | 'name' | 'code' | 'balanceValue' | 'marketCap';

export type MarketFilterSort = {
  sortKey: MarketFilterSortType;
  sortDirection: 'ASC' | 'DESC';
};

type Props = {
  search?: string;
  sort: MarketFilterSort;
  filterType?: MarketAssetFilterType;
  sortForTransferList?: boolean;
  includedAssetCodes?: string[];
  excludedAssetCodes?: string[];
  delay?: number;
  limit?: number;
};

const MAX_NEW_ITEMS = 15;

const useMarketsFilterSort = ({
  search,
  sort,
  filterType = 'all',
  sortForTransferList = false,
  excludedAssetCodes = [],
  includedAssetCodes = [],
  delay = 200,
  limit,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [debouncedSearch, setDebouncedSearch] = useState<string>();
  const { assets, isFavourite, isNew, getAssetMarketCap } = useMarkets();
  const { getBalance, getBalanceValue } = usePortfolioBalance();

  useDebounce(() => setDebouncedSearch(search), delay, [search]);

  const onSortAssets = useCallback(
    (a: Asset, b: Asset, sortKey: MarketFilterSortType = sort.sortKey) => {
      switch (sortKey) {
        case 'rank':
          const aValNum = a[sortKey] as number;
          const bValNum = b[sortKey] as number;
          if (sort.sortDirection === 'ASC') return bValNum - aValNum;
          return aValNum - bValNum;
        case 'code':
        case 'name':
          return a[sortKey].toString().localeCompare(b[sortKey].toString());
        case 'id':
          const aVal = Big(a[sortKey]);
          const bVal = Big(b[sortKey]);
          if (sort.sortDirection === 'ASC') return bVal.cmp(aVal);

          return aVal.cmp(bVal);
        case 'balanceValue':
          const aBalanceValue = getBalanceValue(a.id);
          const bBalanceValue = getBalanceValue(b.id);
          if (sort.sortDirection === 'ASC') return aBalanceValue.cmp(bBalanceValue);

          return bBalanceValue.cmp(aBalanceValue);
        case 'marketCap':
          const aMarketCap = getAssetMarketCap(a);
          const bMarketCap = getAssetMarketCap(b);
          return Big(bMarketCap).cmp(aMarketCap);
      }
    },
    [getAssetMarketCap, getBalanceValue, sort.sortDirection, sort.sortKey],
  );

  const sortedAssets = useMemo(() => {
    const sortAssets = Array.from(assets);

    if (sortForTransferList) {
      const fiat = sortAssets.filter((s) => s.assetType === AssetType.Fiat);
      const ownedCrypto = sortAssets.filter(
        (s) => s.assetType === AssetType.Crypto && getBalanceValue(s.id, 'midPrice').gt(0),
      );
      const unOwnedCrypto = sortAssets.filter(
        (s) => s.assetType === AssetType.Crypto && getBalanceValue(s.id, 'midPrice').lte(0),
      );
      const sortedFiat = fiat.sort((a, b) => onSortAssets(a, b, 'name'));
      const sortedOwnedCrypto = ownedCrypto.sort((a, b) => onSortAssets(a, b, 'balanceValue'));
      const sortedUnOwnedCrypto = unOwnedCrypto.sort((a, b) => onSortAssets(a, b, 'rank'));

      return [...sortedFiat, ...sortedOwnedCrypto, ...sortedUnOwnedCrypto];
    } else {
      return sortAssets.sort(onSortAssets);
    }
  }, [assets, getBalanceValue, onSortAssets, sortForTransferList]);

  const searchCheck = useCallback(
    (asset: Asset) => {
      if (!debouncedSearch) return true;

      const loweredSearch = debouncedSearch?.toLowerCase();
      const assetCode = asset.code.toLowerCase();
      const assetName = asset.name.toLowerCase();

      return assetCode.includes(loweredSearch) || assetName.includes(loweredSearch);
    },
    [debouncedSearch],
  );

  const isAssetExcluded = useCallback((asset: Asset) => excludedAssetCodes.includes(asset.code), [excludedAssetCodes]);
  const isAssetIncluded = useCallback(
    (asset: Asset) => {
      if (!includedAssetCodes.length) return true;

      return includedAssetCodes.includes(asset.code);
    },
    [includedAssetCodes],
  );

  const filterTypeCheck = useCallback(
    (asset: Asset) => {
      switch (filterType) {
        case 'all':
          return true;
        case 'owned':
          return Big(getBalance(asset.id)?.availableBalance).gt(0);
        case 'new':
          return isNew(asset, MAX_NEW_ITEMS);
        case 'favourites':
          return isFavourite(asset.id);
        default:
          return true;
      }
    },
    [filterType, getBalance, isFavourite, isNew],
  );

  const filteredAndSortedAssets = useMemo(
    () =>
      Array.from(sortedAssets).filter((asset, index) => {
        if (!isAssetIncluded(asset)) return false;
        if (!searchCheck(asset)) return false;
        if (isAssetExcluded(asset)) return false;
        if (!filterTypeCheck(asset)) return false;
        if (limit && index > limit - 1) return false;

        return true;
      }),
    [filterTypeCheck, isAssetExcluded, isAssetIncluded, limit, searchCheck, sortedAssets],
  );

  useEffect(() => {
    if (filteredAndSortedAssets !== undefined) {
      setLoading(false);
    }
  }, [filteredAndSortedAssets]);

  return { filteredAndSortedAssets, loading };
};

export { useMarketsFilterSort };

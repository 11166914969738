import React from 'react';

import ListItemButtonMUI, { ListItemButtonProps } from '@mui/material/ListItemButton';

const ListItemButton: React.FC<ListItemButtonProps> = ({ id, children, ...props }) => (
  <ListItemButtonMUI id={id} {...props}>
    {children}
  </ListItemButtonMUI>
);

export { ListItemButton };

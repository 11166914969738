import env from '@shared/config';

import { AffiliateHistoryResponse, PortfolioHistoryResponse } from '../../@types/history';
import {
  DepositHistoryResponse,
  GetDepositHistoryParams,
  GetDepositHistoryPayload,
  GetWithdrawalHistoryParams,
  GetWithdrawalHistoryPayload,
  WithdrawalHistoryResponse,
} from '../../@types/trade';
import { Methods, request } from '../rest';

const historyEndpoints = {
  getAllHistory: ({ query, headers }: { query: object; headers: Record<string, string> }) =>
    request({ path: '/history/all/', method: Methods.GET, headers, query, auth: true }),

  getPortfolioHistory: (): PortfolioHistoryResponse =>
    request({ host: env.GEN2_HOST, path: '/portfolio/api/v1/portfolio/', method: Methods.GET, auth: true }),

  getAssetPortfolioHistory: ({ params }: { params: { asset?: number } }): PortfolioHistoryResponse =>
    request({
      host: env.GEN2_HOST,
      path: '/portfolio/api/v1/portfolio/:asset',
      method: Methods.GET,
      params,
      auth: true,
    }),

  getAffiliatePayoutHistory: ({ params }: { params: { limit: number; page?: number } }): AffiliateHistoryResponse[] =>
    request({ path: '/history/affiliate/:limit/{:page/}', method: Methods.GET, params, auth: true }),

  getDepositHistory: ({
    params,
    query,
  }: {
    params: GetDepositHistoryParams;
    query: GetDepositHistoryPayload;
  }): [DepositHistoryResponse] =>
    request({ path: '/history/deposit/:code/', method: Methods.GET, query, params, auth: true }),

  getWithdrawalHistory: ({
    params,
    query,
  }: {
    params: GetWithdrawalHistoryParams;
    query: GetWithdrawalHistoryPayload;
  }): [WithdrawalHistoryResponse] =>
    request({ path: '/history/withdraw/:code/', method: Methods.GET, query, params, auth: true }),
};

export default historyEndpoints;

import React, { useMemo } from 'react';

import { AtLeastBreakpoints } from '@swyftx/aviary/hooks/breakpoints';

import { cn } from '@shared/utils/lib/ui';

import { HeaderIcon, IconSize } from './PageHeader.types';

const sizeClasses: { [key in IconSize]: string } = {
  32: 'w-[32px] h-[32px] rounded-[8px] p-4',
  48: 'w-[48px] h-[48px] rounded-[12px] p-4',
  64: 'w-[64px] h-[64px] rounded-[12px] p-4',
  96: 'w-[96px] h-[96px] rounded-[30px] p-16',
};

type Props = {
  icon: HeaderIcon;
  isDarkMode: boolean;
  atLeast: AtLeastBreakpoints;
  size?: IconSize;
};

const PageHeaderIcon: React.FC<Props> = ({ icon, isDarkMode, atLeast, size }) => {
  const iconSize: IconSize = useMemo(() => {
    if (icon.size) return icon.size;
    if (size) return size;
    if (atLeast.md) return 96;
    if (atLeast.sm) return 64;
    return 48;
  }, [atLeast.md, atLeast.sm, icon.size, size]);

  if (!size && !icon.color) return icon.element;

  return (
    <div
      className={cn(
        `${sizeClasses[iconSize]} text-white midnight:!bg-color-background-pageBG dark:!bg-color-background-pageBG`,
      )}
      style={{ background: icon?.color, color: isDarkMode ? icon?.color : '' }}
    >
      {icon?.element}
    </div>
  );
};

export { PageHeaderIcon };

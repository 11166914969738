import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { DiscountFilled } from '@swyftx/aviary/icons/filled';
import { SwyftxCompact, Trade } from '@swyftx/aviary/icons/outlined';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { PercentageIncrease } from '@global-components/PercentageIncrease';
import { PriceIncrease } from '@global-components/PriceIncrease';
import { PriceTicker } from '@global-components/PriceTicker';
import { FormattedText } from '@global-components/Text';

import { assetService } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';
import { BalanceKey } from '@utils/balance';

import { WalletMetricData } from '@Wallet/components/WalletMetrics/types';
import { WalletType } from '@Wallet/types';

// TODO remove once portfolio allocation is merged
const getBalanceKeyFromWalletType = (walletType: WalletType): BalanceKey => {
  if (walletType === WalletType.TRADING_WALLETS) {
    return 'availableBalance';
  }

  if (walletType === WalletType.EARNING_WALLETS) {
    return 'stakingBalance';
  }

  return 'all';
};

const getWalletMetricsIcon = (id: string, assetCode?: string) => <AssetIcon wallet asset={assetCode} size={16} />;

const LIVE_PRICE_TOOLTIP = 'Calculated using live mid rate prices';

export const useWalletMetricContent = (walletType: WalletType): WalletMetricData => {
  const { t } = useTranslation('wallet');
  const balanceKey = getBalanceKeyFromWalletType(walletType);
  const baseAsset = useBaseAsset();
  const { isLightMode } = useTailwindTheme();

  const isAll = walletType === WalletType.ALL_WALLETS;

  const {
    portfolio: { accountValue, profit, percentChange, highestPerformer, lowestPerformer, staking },
  } = usePortfolio({ balanceKey });

  const highestPerformingAsset =
    highestPerformer.assetId !== null ? assetService.getAsset(highestPerformer.assetId) : undefined;
  const lowestPerformingAsset =
    lowestPerformer.assetId !== null ? assetService.getAsset(lowestPerformer.assetId) : undefined;

  const highestEarnAsset =
    staking.highestStakingRewardsAssetId !== null
      ? assetService.getAsset(staking.highestStakingRewardsAssetId)
      : undefined;
  const lowestEarnAsset =
    staking.lowestStakingRewardsAssetId !== null
      ? assetService.getAsset(staking.lowestStakingRewardsAssetId)
      : undefined;

  if (walletType === WalletType.EARNING_WALLETS) {
    return [
      {
        variant: 'primary',
        content: (
          <PriceTicker
            typographyProps={{ size: 'large', weight: 'emphasis' }}
            price={staking.stakingValueTotal.toString()}
            currency={baseAsset}
          />
        ),
        title: t('overview.metrics.earn.earnWalletValue'),
        backgroundIcon: <DiscountFilled className='text-white' />,
        tooltip: LIVE_PRICE_TOOLTIP,
      },
      {
        variant: 'standard',
        content: (
          <FormattedText
            typographyProps={{
              size: 'large',
              weight: 'emphasis',

              color: staking.stakingRewardsTotal.gte(0) ? 'success' : 'error',
            }}
            prefix='~'
            value={staking.stakingRewardsTotal}
            currency={baseAsset}
          />
        ),
        title: t('overview.metrics.earn.totalRewards'),
        tooltip: LIVE_PRICE_TOOLTIP,
      },
      {
        variant: 'standard',
        content: (
          <FormattedText
            typographyProps={{
              size: 'large',
              weight: 'emphasis',
              color: staking.stakingRewardsTotal.gte(0) ? 'success' : 'error',
            }}
            prefix='~'
            value={staking.nextPayoutTotal}
            currency={baseAsset}
          />
        ),
        title: t('overview.metrics.earn.nextExpectedPayoutAmount'),
        tooltip: LIVE_PRICE_TOOLTIP,
      },
      {
        variant: 'standard',
        titleIcon: getWalletMetricsIcon('highest-reward-icon', highestEarnAsset?.code),
        content: (
          <FormattedText
            typographyProps={{
              size: 'large',
              weight: 'emphasis',

              color: staking.stakingRewardsTotal.gte(0) ? 'success' : 'error',
            }}
            value={staking.highestStakingRewards || ''}
            currency={baseAsset}
          />
        ),
        title: t('overview.metrics.earn.highestRewards'),
        tooltip: LIVE_PRICE_TOOLTIP,
      },
      {
        variant: 'standard',
        titleIcon: getWalletMetricsIcon('lowest-reward-icon', lowestEarnAsset?.code),
        content: (
          <FormattedText
            typographyProps={{
              size: 'large',
              weight: 'emphasis',

              color: staking.stakingRewardsTotal.gte(0) ? 'success' : 'error',
            }}
            value={staking.lowestStakingRewards || ''}
            currency={baseAsset}
          />
        ),
        title: t('overview.metrics.earn.lowestRewards'),
        tooltip: LIVE_PRICE_TOOLTIP,
      },
    ];
  }

  return [
    {
      variant: 'primary',
      content: (
        <FormattedText
          typographyProps={{
            size: 'large',
            weight: 'emphasis',
            color: 'inverse',
          }}
          value={accountValue}
          currency={baseAsset}
          formatOpts={{ displayPriceScale: true }}
        />
      ),
      title: t(`overview.metrics.${isAll ? 'all.totalWalletValue' : 'trading.tradingWalletValue'}`),
      backgroundIcon: isAll ? (
        <SwyftxCompact className={cn(!isLightMode ? 'text-color-text-inverse' : 'text-white', 'h-48 w-48')} />
      ) : (
        <Trade className='text-white' />
      ),
    },
    {
      variant: 'standard',
      content: (
        <PercentageIncrease typographyProps={{ size: 'large', weight: 'emphasis' }} amount={percentChange.toString()} />
      ),
      title: t(
        `overview.metrics.${isAll ? 'all.totalWalletPerformancePercent' : 'trading.tradingWalletPerformancePercent'}`,
      ),
    },
    {
      variant: 'standard',
      content: (
        <PriceIncrease
          typographyProps={{ size: 'large', weight: 'emphasis' }}
          amount={profit.toString()}
          currencyAsset={baseAsset}
        />
      ),
      title: t(
        `overview.metrics.${isAll ? 'all.totalWalletPerformanceMoney' : 'trading.tradingWalletPerformanceMoney'}`,
      ),
    },
    {
      variant: 'standard',
      titleIcon: getWalletMetricsIcon('highest-performer-icon', highestPerformingAsset?.code),
      content: (
        <PriceIncrease
          typographyProps={{ size: 'large', weight: 'emphasis' }}
          amount={highestPerformer?.profit?.toString() || '0'}
          currencyAsset={baseAsset}
        />
      ),
      title: t('overview.metrics.shared.highestPerformer'),
    },
    {
      variant: 'standard',
      titleIcon: getWalletMetricsIcon('lowest-performer-icon', lowestPerformingAsset?.code),
      content: (
        <PriceIncrease
          typographyProps={{ size: 'large', weight: 'emphasis' }}
          amount={lowestPerformer?.profit?.toString() || '0'}
          currencyAsset={baseAsset}
        />
      ),
      title: t('overview.metrics.shared.lowestPerformer'),
    },
  ];
};

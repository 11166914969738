import React from 'react';

import { Box, Divider } from '@mui/material';

type ModalFooterProps = {
  divider?: boolean;
  content: React.ReactNode;
};

const ModalFooter: React.FC<ModalFooterProps> = ({ divider, content }) => (
  <>
    {divider && <Divider />}
    <Box padding={2}>{content}</Box>
  </>
);

export { ModalFooter };
export type { ModalFooterProps };

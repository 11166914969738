import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const SynthIssuer: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5576 3.23389C14.2862 3.23389 13.2161 4.09575 12.8997 5.26709H10.9842C8.90246 5.26709 7.21484 6.95471 7.21484 9.0365V9.33301H5.39357C3.87317 9.33301 2.64062 10.5655 2.64062 12.0859C2.64062 13.6064 3.87315 14.8389 5.39357 14.8389H7.21484V15.1355C7.21484 17.2173 8.90248 18.9048 10.9842 18.9048H12.8998C13.2164 20.0759 14.2864 20.9375 15.5576 20.9375H18.607C20.1274 20.9375 21.3599 19.705 21.3599 18.1846C21.3599 16.6642 20.1274 15.4316 18.607 15.4316H15.5576C14.2862 15.4316 13.2162 16.2935 12.8997 17.4648H10.9842C9.69774 17.4648 8.65484 16.4219 8.65484 15.1355V14.8309C10.0763 14.7227 11.196 13.5351 11.196 12.0859C11.196 10.6368 10.0762 9.44922 8.65484 9.34104V9.0365C8.65484 7.75 9.69776 6.70709 10.9842 6.70709H12.8999C13.2165 7.87816 14.2864 8.73977 15.5576 8.73977H18.607C20.1274 8.73977 21.3599 7.50722 21.3599 5.98683C21.3599 4.46644 20.1274 3.23389 18.607 3.23389H15.5576ZM15.5576 4.67389C14.8415 4.67389 14.2592 5.24731 14.245 5.96009C14.2453 5.96905 14.2455 5.97805 14.2455 5.98709C14.2455 5.99607 14.2453 6.00501 14.245 6.01391C14.2594 6.72653 14.8416 7.29977 15.5576 7.29977H18.607C19.3321 7.29977 19.9199 6.71196 19.9199 5.98683C19.9199 5.2617 19.3321 4.67389 18.607 4.67389H15.5576ZM14.245 18.2117C14.2453 18.2028 14.2455 18.1938 14.2455 18.1848C14.2455 18.1758 14.2453 18.1668 14.245 18.1578C14.2592 17.445 14.8415 16.8716 15.5576 16.8716H18.607C19.3321 16.8716 19.9199 17.4594 19.9199 18.1846C19.9199 18.9097 19.3321 19.4975 18.607 19.4975H15.5576C14.8415 19.4975 14.2594 18.9243 14.245 18.2117ZM4.08062 12.0859C4.08062 11.3608 4.66844 10.773 5.39357 10.773H8.44298C9.16812 10.773 9.75595 11.3609 9.75595 12.0859C9.75595 12.8111 9.1681 13.3989 8.44298 13.3989H5.39357C4.66846 13.3989 4.08062 12.8111 4.08062 12.0859Z'
      fill='currentColor'
    />
  </svg>
);

export { SynthIssuer };

import React from 'react';

import { useDirectorsStep } from '@routes/EntityOnboarding/hooks/useDirectorsStep';

import { observer } from 'mobx-react-lite';

import { IndividualPageLayout } from './components/IndividualPageLayout';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const CompanyAddDirectorsStep: React.FC = observer(() => {
  const {
    directors,
    addMemberOpen,
    onAddMember,
    onCancel,
    continueDisabled,
    handleRemove,
    toggleAddMemberOpen,
    companyName,
    handleSubmit,
    onEditMember,
    rawShareholders,
    handleBack,
  } = useDirectorsStep();

  return (
    <EntityOnboardingStepContainer
      customTitle='Business directors'
      submitDisabled={continueDisabled}
      onSubmit={handleSubmit}
      onBack={handleBack}
    >
      <EntityOnboardingPageLayout
        subtitle={`List all individuals who are directors or members of the governing board of ${companyName}. 
                  We are required to collect this for regulatory purposes.`}
      >
        <IndividualPageLayout
          title='Please add all directors of the company:'
          members={directors}
          onAddMember={onAddMember}
          handleRemove={handleRemove}
          onCancel={onCancel}
          addMemberOpen={addMemberOpen}
          toggleAddMemberOpen={toggleAddMemberOpen}
          memberType='director'
          shareholders={rawShareholders}
          onEditMember={onEditMember}
        />
      </EntityOnboardingPageLayout>
    </EntityOnboardingStepContainer>
  );
});

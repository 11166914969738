import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { useIntercom } from 'react-use-intercom';

interface Props {
  title: string;
  subtitle: string | React.ReactNode;
  icon: React.ReactNode;
  subtitleInline?: string;
}

export const PromptLineItem = ({ title, subtitle, icon, subtitleInline }: Props) => {
  const { show: showIntercom } = useIntercom();

  return (
    <div className='rounded-8 bg-color-background-surface-secondary p-16'>
      <FlexLayout spacing={8}>
        <div>{icon}</div>
        <FlexLayout direction='column' className='h-full' alignItems='start' justifyContent='space-evenly'>
          <Body weight='bold'>{title}</Body>
          <Body size='small' color='secondary'>
            {subtitle}{' '}
            {subtitleInline && (
              <Body className='inline cursor-pointer underline' size='small' color='accent' onClick={showIntercom}>
                {subtitleInline}
              </Body>
            )}
          </Body>
        </FlexLayout>
      </FlexLayout>
    </div>
  );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, Divider, Fade } from '@mui/material';

import { Button, Stack } from '@swyftx/react-web-design-system';

import {
  Asset,
  AssetType,
  NEWS_TILE_PREFERRED_VIEW,
  NEWS_TILE_TAB_SELECTION,
  NewsItem,
  NewsTileTab,
  NewsTileView,
} from '@shared/api';
import { assetService } from '@shared/services';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';
import { useOwnedAssets } from '@hooks/Assets/useOwnedAssets';
import { useAvo } from '@hooks/Avo/useAvo';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import fetchNewsData from '@hooks/NewsItems/useFetchNewsData';

import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';

import { NewsFeedTileHeader, NewsFeedTileItem, NewsFeedTileTableHeader } from './components';
import { NewsFeedSearchFilter } from './components/NewsFeedSearchFilter';
import { NewsFeedTileLoadingState } from './components/NewsFeedTileItem/NewsFeedTileLoadingState';
import { NoNewsResults } from './components/NoNewsResults';
import { DashboardBaseTile } from '../../DashboardBaseTile';
import { DashboardProps } from '../../DashboardBaseTile/DashboardBaseTile.data';

const ownedAssetOptions = {
  sortByValue: true,
  ignoreBalanceUpdates: true,
  ignoreDustableAssets: true,
  assetTypeFilter: AssetType.Crypto,
};

const favouriteAssetOptions = {
  excludeFiat: true,
};

const dedupeAndSort = (assetsToSort: Asset[]) => {
  const deduped = Array.from(new Set(assetsToSort));
  return deduped.sort((a, b) => a.rank - b.rank);
};

const NewsFeedTile = observer(({ dragProps, tile }: DashboardProps) => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { t } = useTranslation('dashboard', { keyPrefix: 'newsFeedTile' });
  const { isLargeContent, isMobile } = useContentBreakpoint();
  const isSmall = !isLargeContent || tile.metadata?.size === 'compact' || tile.metadata?.size === 'half';

  const [searchFilter, setSearchFilter] = useState<string[]>([]);
  const [newsFeedSearchValue, setNewsFeedSearchValue] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextPageCursor, setNextPageCursor] = useState<string>('');
  const [isLoadingShowMore, setIsLoadingShowMore] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const ownedAssetList = useOwnedAssets(ownedAssetOptions);
  const favouriteAssetList = useFavouriteAssets(favouriteAssetOptions);

  // Merge them together
  const ownedAndFavouriteAssets = useMemo(() => {
    // Only use crypto assets for news as fiat articles don't exist
    const assets = dedupeAndSort([...favouriteAssetList, ...ownedAssetList]).filter(
      (a) => a.assetType === AssetType.Crypto,
    );
    return assets;
  }, [favouriteAssetList, ownedAssetList]);

  const allAssets = useMemo(() => dedupeAndSort(assetService.getActiveCryptoAssets()), []);

  const [newsTileTab, setNewsTileTab] = useState<NewsTileTab>(
    (() => {
      const previousTabSelection = localStorage.getItem(NEWS_TILE_TAB_SELECTION) as NewsTileTab | null;
      if (previousTabSelection != null) {
        if (previousTabSelection === NewsTileTab.MyNews && ownedAndFavouriteAssets.length === 0) {
          return NewsTileTab.All;
        }

        return previousTabSelection;
      }

      return ownedAndFavouriteAssets.length > 0 ? NewsTileTab.MyNews : NewsTileTab.All;
    })(),
  );

  const previousThumbnailView = localStorage.getItem(NEWS_TILE_PREFERRED_VIEW) || NewsTileView.Thumbnail;
  const [isThumbnailView, setIsThumbnailView] = useState(previousThumbnailView === NewsTileView.Thumbnail);

  const newsListRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();
  const avo = useAvo();
  const { ref: newsTileRef, inView } = useInView();

  const fetchNewsItems = useCallback(
    async (shouldGetNextPage: boolean) => {
      if (!shouldGetNextPage) {
        setIsLoading(true);
      }

      try {
        // NewsFeedSearchFilter would set the search filter if it detects we're on the "My News" tab
        // We want to show an empty result if no search filter is present in this case, instead of all articles
        if (newsTileTab === NewsTileTab.MyNews && searchFilter.length === 0) {
          setNewsItems([]);
        } else {
          const { data } = await fetchNewsData({
            searchFilter: searchFilter.join(','), // expects string
            shouldGetNextPage,
            nextPageCursor,
            pathname,
            setLastUpdated,
            newsListRef,
            setNextPageCursor,
          });
          const newsItemsToShow = shouldGetNextPage ? [...newsItems, ...(data ?? [])] : data;
          setNewsItems(newsItemsToShow);

          if (data.length === 0) {
            avo.noNewsReturned({
              screen: pathname,
              filterType: 'Assets',
              filterSelection: searchFilter,
              newsFeedPreference: newsTileTab,
            });
          }
        }
        // eslint-disable-next-line no-empty
      } catch {
      } finally {
        setIsLoading(false);
        setIsLoadingShowMore(false);
      }
    },
    [avo, newsItems, newsTileTab, nextPageCursor, pathname, searchFilter],
  );

  const handleShowMore = () => {
    setIsLoadingShowMore(true);

    fetchNewsItems(true);
    avo.clickedShowMoreLatestNews({
      screen: pathname,
      newsFeedPreference: newsTileTab,
    });
  };

  const handleRefresh = useCallback(() => {
    fetchNewsItems(false);
    avo.refreshedLatestNews({
      screen: pathname,
      newsFeedPreference: newsTileTab,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avo, newsTileTab, pathname]);

  useEffect(() => {
    fetchNewsItems(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    if (inView && isInitialLoad) {
      avo.viewedLatestNews({
        screen: pathname,
        newsFeedPreference: newsTileTab,
      });
      setIsInitialLoad(false);
    }
  }, [avo, inView, pathname, isInitialLoad, newsTileTab]);

  return (
    <DashboardBaseTile
      dragProps={dragProps}
      tileName={tile.name}
      title={t('title')}
      noPadding
      headerSx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        paddingRight: isMobile ? '8px' : '16px',
      }}
      customHeaderBar={<NewsFeedTileHeader selectedTab={newsTileTab} setSelectedTab={setNewsTileTab} />}
      headerBarActionItem={
        <div ref={newsTileRef}>
          <Stack direction='row' spacing={1} flex='flex-end'>
            {/* Handle search and refresh */}
            <NewsFeedSearchFilter
              ownedAndFavouriteAssets={ownedAndFavouriteAssets}
              allAssets={allAssets}
              setSearchFilter={setSearchFilter}
              setNewsFeedSearchValue={setNewsFeedSearchValue}
              newsFeedSearchValue={newsFeedSearchValue}
              newsTileTab={newsTileTab}
              tileSize={tile?.metadata?.size}
              lastUpdated={lastUpdated}
              handleRefresh={handleRefresh}
              isThumbnailView={isThumbnailView}
              setIsThumbnailView={setIsThumbnailView}
              setIsSearching={setIsSearching}
            />
          </Stack>
        </div>
      }
    >
      {!isSmall && !isThumbnailView && <NewsFeedTileTableHeader />}
      {isLoading && <NewsFeedTileLoadingState isThumbnailView={isThumbnailView} />}
      {!isLoading && !newsItems.length && (
        <Stack direction='column' minHeight='280px' spacing={1} padding={1} alignItems='center' textAlign='center'>
          <NoNewsResults filteredSearch={searchFilter} newsTileTab={newsTileTab} isSearching={isSearching} />
        </Stack>
      )}
      {!isLoading && newsItems.length > 0 && (
        <Fade in timeout={500}>
          <Box overflow='auto' minHeight='280px' ref={newsListRef}>
            <Stack spacing={1} flex={1} padding={1}>
              {newsItems.map((newsItem, index) => (
                <NewsFeedTileItem
                  newsItem={newsItem}
                  key={newsItem.id}
                  showDivider={index < newsItems.length - 1}
                  selectedTab={newsTileTab}
                  isThumbnailView={isThumbnailView}
                  tileSize={tile.metadata?.size}
                />
              ))}
            </Stack>
            {nextPageCursor && (
              <>
                <Divider />
                <Box textAlign='center'>
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ fontSize: '12px', marginTop: '6px', marginBottom: '10px' }}
                    onClick={handleShowMore}
                    loading={isLoadingShowMore}
                  >
                    Show More
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      )}
    </DashboardBaseTile>
  );
});
export { NewsFeedTile };

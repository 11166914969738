import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Download: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.1229 2.6452C12.5371 2.6452 12.8729 2.98099 12.8729 3.3952V13.6206L14.5075 11.9792C14.7998 11.6858 15.2747 11.6848 15.5682 11.9771C15.8617 12.2694 15.8626 12.7442 15.5703 13.0377L12.6543 15.9657C12.5136 16.107 12.3224 16.1865 12.1229 16.1865C11.9235 16.1865 11.7322 16.107 11.5915 15.9657L8.67551 13.0377C8.38322 12.7442 8.38419 12.2694 8.67769 11.9771C8.97118 11.6848 9.44605 11.6858 9.73834 11.9792L11.3729 13.6205V3.3952C11.3729 2.98099 11.7087 2.6452 12.1229 2.6452Z'
      fill='currentColor'
    />
    <path
      d='M17.6889 8.87811C19.3096 8.87811 20.6229 10.1912 20.6229 11.8131V16.6971C20.6229 18.3129 19.3137 19.6221 17.6979 19.6221H6.55791C4.93712 19.6221 3.62291 18.3079 3.62291 16.6871V11.8021C3.62291 10.1876 4.93286 8.87811 6.54791 8.87811H7.48991C7.90412 8.87811 8.23991 8.54233 8.23991 8.12811C8.23991 7.7139 7.90412 7.37811 7.48991 7.37811H6.54791C4.10496 7.37811 2.12291 9.35863 2.12291 11.8021V16.6871C2.12291 19.1363 4.1087 21.1221 6.55791 21.1221H17.6979C20.1421 21.1221 22.1229 19.1413 22.1229 16.6971V11.8131C22.1229 9.36303 20.1383 7.37811 17.6889 7.37811H16.7559C16.3417 7.37811 16.0059 7.7139 16.0059 8.12811C16.0059 8.54233 16.3417 8.87811 16.7559 8.87811H17.6889Z'
      fill='currentColor'
    />
  </svg>
);

export { Download };

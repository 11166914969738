import { MigrateResponse, BalanceResult, StatusV2Response } from '@shared/api/@types/migrator';

import { Methods, request } from '../rest';

const migratorEndpoints = {
  status: () => request({ path: '/migrator/status/', method: Methods.GET, auth: true }),

  exchanges: (): StatusV2Response => request({ path: '/migrator/exchanges/', method: Methods.GET, auth: true }),

  migrate: ({
    data,
  }: {
    data: {
      apiKey: string;
      apiSecret: string;
      exchange: string;
      assets?: number[];
    };
  }): MigrateResponse => request({ path: '/migrator/migrate/', method: Methods.POST, data, auth: true }),

  balances: ({
    data,
  }: {
    data: {
      apiKey: string;
      apiSecret: string;
      exchange: string;
    };
  }): BalanceResult[] => request({ path: '/migrator/balances/', method: Methods.POST, data, auth: true }),
};

export default migratorEndpoints;

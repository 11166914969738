import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import PageBanner from '@global-components/PageBanner';
import { TabNavigationBarProps } from '@global-components/TabNavigationBar/TabNavigationBar.types';

import { observer } from 'mobx-react-lite';

import { ProfileTabView } from './components';

const ProfileWrapper: React.FC<Omit<TabNavigationBarProps, 'tabs'>> = React.memo(
  observer((props) => (
    <FlexLayout className='flex-col flex-wrap p-16 @container sm:flex-row sm:p-24 sm:pr-0'>
      <FlexLayout direction='column' className='w-full @sm:w-1/3 @md:w-1/4'>
        <ProfileTabView selectedTabId={props.selectedTabId} />
      </FlexLayout>
      <FlexLayout direction='row' className='w-full @sm:w-2/3 @md:w-3/4'>
        <FlexLayout direction='column' className='w-full' spacing={24}>
          <PageBanner />
          {props.children}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  )),
);

ProfileWrapper.displayName = 'ProfileWrapper';

export { ProfileWrapper };

import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import {
  Button,
  Card,
  Chip,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { NoSearchResultsFound } from '@global-components/EmptyStates';

import { Asset, NewsTileTab } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';

import { NewsFeedTabId, Tabs } from '../NewsFeedSearchFilter/NewsFeedSearchFilter.types';

type Props = {
  filteredAssets: Asset[];
  setFilteredSearch: Dispatch<SetStateAction<string[]>>;
  newsFeedSearchValue: string;
  setNewsFeedSearchValue: Dispatch<SetStateAction<string>>;
  setFocused: Dispatch<SetStateAction<boolean>>;
  isSmall: boolean;
  newsTileTab: NewsTileTab;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
};

const NewsFeedSearchResults = ({
  filteredAssets,
  setFilteredSearch,
  newsFeedSearchValue,
  setNewsFeedSearchValue,
  setFocused,
  newsTileTab,
  setIsSearching,
}: Props) => {
  const [tab, setTab] = useState<NewsFeedTabId>(NewsFeedTabId.Assets);
  const scrollRef = useRef(null);
  const { pathname } = useLocation();
  const avo = useAvo();
  const { t } = useTranslation('dashboard', { keyPrefix: 'newsFeedTile' });

  const handleClick = (asset: Asset) => {
    avo.searchedLatestNews({
      screen: pathname,
      newsFeedPreference: newsTileTab,
      filterType: 'Assets', // Will need to change this once we have more filter types like "Categories"
      filterSearched: newsFeedSearchValue,
      filterSelection: [asset.name],
    });

    setFilteredSearch([asset.code]);
    setNewsFeedSearchValue(asset.name);
    setFocused(false);
    setIsSearching(true);
  };

  const infiniteScrollFilteredAssets = useInfiniteScroll(scrollRef.current, filteredAssets, {
    initialItemsLength: 20,
    addLengthAmount: 10,
    fromBottomTrigger: 100,
  });

  return (
    <Card>
      <Grid item xs={12}>
        <Stack direction='row' spacing={2} sx={{ marginTop: 1, marginBottom: 1, overflowX: 'auto' }}>
          {Tabs.map((filterTab) => (
            <Button
              id={filterTab.id}
              key={filterTab.label}
              variant='text'
              sx={{
                '&.MuiLoadingButton-root': {
                  color: filterTab.id === tab ? 'text.primary' : 'text.secondary',
                  backgroundColor: filterTab.id === tab ? 'action.hover' : '',
                  fontWeight: filterTab.id === tab ? '500' : '400',
                },
                '.MuiSvgIcon-root': {
                  color: filterTab.id === tab ? 'primary.main' : 'text.secondary',
                  marginRight: 1,
                },
              }}
              onClick={() => setTab(filterTab.id)}
            >
              {filterTab.label}
            </Button>
          ))}
        </Stack>
      </Grid>
      <Box position='relative' marginTop={0} height={260} minWidth={400} sx={{ overflowY: 'auto' }} ref={scrollRef}>
        {infiniteScrollFilteredAssets.length < 1 ? (
          <NoSearchResultsFound
            title={
              newsTileTab === NewsTileTab.MyNews ? t('labels.emptyMyNewsFilters.title') : t('labels.emptySearch.title')
            }
            subTitle={
              newsTileTab === NewsTileTab.MyNews
                ? t('labels.emptyMyNewsFilters.subTitle')
                : t('labels.emptySearch.subTitle')
            }
          />
        ) : (
          infiniteScrollFilteredAssets.map((asset) => (
            <ListItemButton
              key={asset.code}
              sx={{
                paddingX: 0,
              }}
              onClick={() => handleClick(asset)}
            >
              <ListItemIcon>
                <AssetIcon asset={asset.code} size={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    {asset.name}
                    <Chip size='small' label={`#${asset.displayRank}`} sx={{ marginLeft: 1 }} />
                  </Typography>
                }
              />
            </ListItemButton>
          ))
        )}
      </Box>
    </Card>
  );
};

export default NewsFeedSearchResults;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { lighten } from '@mui/system/colorManipulator';

import { ArrowInCircleDown } from '@swyftx/aviary/icons/outlined';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { StorageKey } from '@shared/storage';
import { formatCurrency } from '@shared/utils';

import { AssetColors } from '@utils/assets';

import { useLocalStorage } from 'react-use';

import { ProfitStopLossSummary } from './ProfitStopLossSummary';
import { TriggerOrdersNoteModal } from '../../TriggerOrdersNoteModal';

type Props = {
  triggerType: TriggerType;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  amount: Big;
  feePercentage?: string;
  estimatedTotal: string;
  triggerPrice: string;
  estimatedFee: string;
  estimatedProfitPercentage: string;
  estimatedProfitDollars: string;
};

const ProfitLossPlaceOrderSummary: React.FC<Props> = ({
  triggerType,
  primaryAsset,
  secondaryAsset,
  amount,
  estimatedTotal,
  feePercentage,
  triggerPrice,
  estimatedFee,
  estimatedProfitPercentage,
  estimatedProfitDollars,
}) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  const [doNotShowStopLossTriggerNotes, setDoNotShowStopLossTriggerNotes] = useLocalStorage(
    StorageKey.DO_NOT_SHOW_STOP_LOSS_TRIGGER_NOTES,
    false,
  );

  const [showTriggerNotes, setShowTriggerNotes] = useState<boolean>(false);

  useEffect(() => {
    if (!doNotShowStopLossTriggerNotes) {
      setShowTriggerNotes(true);
    }
  }, []);

  return (
    <>
      <TriggerOrdersNoteModal
        open={showTriggerNotes}
        onClose={() => setShowTriggerNotes(false)}
        doNotShowToggle={doNotShowStopLossTriggerNotes}
        onDoNotShowToggle={(checked) => setDoNotShowStopLossTriggerNotes(checked)}
      />

      <Stack spacing={2}>
        <Stack spacing={0} padding={2}>
          <Typography fontSize={12} color='text.secondary'>
            {t('labels.orderType')}
          </Typography>
          <Typography fontSize={18} fontWeight={600} color='text.primary'>
            {t(triggerType === TriggerType.TakeProfit ? 'labels.triggerSell' : 'labels.stopSell')}
          </Typography>
        </Stack>

        <Stack spacing={0} padding={2}>
          {secondaryAsset && (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Stack
                alignItems='center'
                justifyContent='center'
                width={48}
                height={48}
                borderRadius={1}
                bgcolor={lighten(AssetColors[secondaryAsset.code], 0.8)}
              >
                <AssetIcon asset={secondaryAsset.code} size={24} />
              </Stack>
              <Stack>
                <Typography fontSize={12} color='text.secondary'>
                  {t('labels.spend')}
                </Typography>
                <Typography number fontSize={18} color='text.primary' fontWeight={500}>
                  {formatCurrency(amount, secondaryAsset)}
                </Typography>
              </Stack>
            </Stack>
          )}
          <Stack alignItems='center' justifyContent='center' width={48} height={48}>
            <ArrowInCircleDown />
          </Stack>
          {primaryAsset && (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Stack
                alignItems='center'
                justifyContent='center'
                width={48}
                height={48}
                borderRadius={1}
                bgcolor={lighten(AssetColors[primaryAsset.code], 0.8)}
              >
                <AssetIcon asset={primaryAsset.code} size={24} />
              </Stack>
              <Stack>
                <Typography fontSize={12} color='text.secondary'>
                  {t('labels.deposit')}
                </Typography>
                <Typography number fontSize={18} color='text.primary' fontWeight={500}>
                  {formatCurrency(estimatedTotal, primaryAsset)}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>

        <ProfitStopLossSummary
          triggerType={triggerType}
          triggerPrice={triggerPrice}
          estimatedFee={estimatedFee}
          feePercentage={feePercentage}
          estimatedProfitPercentage={estimatedProfitPercentage}
          estimatedProfitDollars={estimatedProfitDollars}
          estimatedTotal={estimatedTotal}
          primaryAsset={primaryAsset}
          secondaryAsset={secondaryAsset}
          showDivider
          addPadding
        />
      </Stack>
    </>
  );
};

export { ProfitLossPlaceOrderSummary };

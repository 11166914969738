import { useCallback, useMemo } from 'react';

import { EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { AssetPickerTableData } from '../AssetPicker.types';

type Props = {
  assets?: Asset[];
  numAssets?: number;
  search: string;
  setSort: (newSort: EnhancedTableSort<AssetPickerTableData>) => void;
  sort: EnhancedTableSort<AssetPickerTableData>;
};

export const useAssetPickerList = (props: Props) => {
  const { assets = [], numAssets, search, setSort, sort } = props;
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const assetsToShow = useMemo(() => {
    if (search === '') return assets.slice(0, numAssets ?? undefined);
    return assets
      .filter(
        (asset) =>
          asset.name.toLowerCase().includes(search.toLowerCase()) ||
          asset.code.toLowerCase().includes(search.toLowerCase()),
      )
      .slice(0, numAssets ?? undefined);
  }, [assets, numAssets, search]);

  const onSort = useCallback(
    (nextSort?: EnhancedTableSort<AssetPickerTableData>): Asset[] => {
      const tableData: Asset[] = assetsToShow;

      const newSort = nextSort ?? sort;

      if (newSort) setSort(newSort);

      const bigSort = (a: Big, b: Big) => {
        if (newSort.sortDirection === 'ASC') return b.lt(a) ? 1 : -1;
        return b.lt(a) ? -1 : 1;
      };

      return tableData.sort((a, b) => {
        switch (newSort.sortKey) {
          case 'asset':
            const aVal = a.name.toString();
            const bVal = b.name.toString();
            if (newSort.sortDirection === 'ASC') return bVal.localeCompare(aVal);
            return aVal.localeCompare(bVal);
          case 'buyPrice':
            const aRate = Big(getRate(a).askPrice);
            const bRate = Big(getRate(b).askPrice);
            return bigSort(aRate, bRate);
          case 'dailyVolume':
            const aVolume = Big(a.volume[baseAsset?.id || FiatIdEnum.AUD].day);
            const bVolume = Big(b.volume[baseAsset?.id || FiatIdEnum.AUD].day);
            return bigSort(aVolume, bVolume);
          case 'dailyChange':
            const aRateChange = Big(getRate(a).dailyPriceChange ?? '0');
            const bRateChange = Big(getRate(b).dailyPriceChange ?? '0');
            return bigSort(aRateChange, bRateChange);
          case 'weeklyChange':
            const aWeeklyChange = Big(a.priceChange.week);
            const bWeeklyChange = Big(b.priceChange.week);
            return bigSort(aWeeklyChange, bWeeklyChange);
          case 'monthlyChange':
            const aMonthlyChange = Big(a.priceChange.month);
            const bMonthlyChange = Big(b.priceChange.month);
            return bigSort(aMonthlyChange, bMonthlyChange);
          default:
            return 1;
        }
      });
    },
    [assetsToShow, baseAsset?.id, getRate, setSort, sort],
  );

  return {
    assets,
    onSort,
    assetsToShow,
  };
};

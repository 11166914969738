import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

const AdvancedConfigurationHeader: React.FC = observer(() => {
  const { showAdvancedConfig, setShowAdvancedConfig, toggleAllApiScopes } = useContext(ApiKeysContext);
  const { is } = useContentBreakpoint();
  const { t } = useTranslation('profile.apiKey');

  const getButtonState = useMemo(
    () =>
      is.md
        ? showAdvancedConfig
          ? t('sections.createApiKey.config.configHeader.mobile.hide')
          : t('sections.createApiKey.config.configHeader.mobile.show')
        : showAdvancedConfig
        ? t('sections.createApiKey.config.configHeader.desktop.hide')
        : t('sections.createApiKey.config.configHeader.desktop.show'),
    [showAdvancedConfig, is, t],
  );

  return (
    <Stack direction='column'>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography fontSize={14} fontWeight='600'>
          {t('sections.createApiKey.config.configHeader.title')}
        </Typography>

        <Stack direction='row'>
          {showAdvancedConfig && !is.md && (
            <>
              <Button variant='text' onClick={() => toggleAllApiScopes(true)}>
                {t('sections.createApiKey.config.configHeader.selectAll')}
              </Button>
              <Button variant='text' onClick={() => toggleAllApiScopes(false)}>
                {t('sections.createApiKey.config.configHeader.removeAll')}
              </Button>
            </>
          )}

          <Button variant='text' onClick={() => setShowAdvancedConfig(!showAdvancedConfig)}>
            {getButtonState}
          </Button>
        </Stack>
      </Stack>

      {showAdvancedConfig && is.md && (
        <Stack direction='row' justifyContent='space-between' spacing={1} sx={{ marginY: '10px !important' }}>
          <Button variant='outlined' color='inherit' onClick={() => toggleAllApiScopes(true)} sx={{ width: '50%' }}>
            {t('sections.createApiKey.config.configHeader.selectAll')}
          </Button>
          <Button variant='outlined' color='inherit' onClick={() => toggleAllApiScopes(false)} sx={{ width: '50%' }}>
            {t('sections.createApiKey.config.configHeader.removeAll')}
          </Button>
        </Stack>
      )}
    </Stack>
  );
});

export { AdvancedConfigurationHeader };

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import { CarouselItemType } from './Carousel.types';
import { FlexLayout } from '../Layout/Flex';

type Props = {
  item: CarouselItemType;
  className?: string;
};

const CarouselItem: React.FC<Props> = ({ item, className }) => (
  <FlexLayout direction='column' spacing={48} alignItems='center' justifyContent='center' className='h-full w-full'>
    <FlexLayout direction='column' spacing={24} alignItems='center' className={cn(className)}>
      {item.image}
      <FlexLayout direction='column' spacing={12} alignItems='center' className='mb-24 w-full text-center'>
        {item.title}
        {item.subTitle}
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);

export { CarouselItem };

import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Add } from '@swyftx/aviary/icons/outlined';
import { RadioGroup } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

import { useSmsfTrusteeStep } from '@routes/EntityOnboarding/hooks/useSmsfTrusteeStep';
import { yesNoRadioGroup } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';
import { getRadioSelectedValue } from '@routes/EntityOnboarding/utils/getRadioSelectedValue';

import { observer } from 'mobx-react-lite';

import { AddCorporateTrustee } from './components/AddCorporateTrustee';
import { CorporateCard } from './components/CorporateCard';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { ConfirmRemoveCorporateMembersModal } from '../../modals/ConfirmRemoveCorporateMembersModal';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const SmsfAddCorporateTrusteesStep: React.FC = observer(() => {
  const {
    addMemberOpen,
    addCorporateTrusteeOpen,
    corporateTrustees,
    handleRemove,
    hasCorporateTrustees,
    setHasCorporateTrustees,
    onAddCorporateTrustee,
    toggleAddCorporateTrusteeOpen,
    toggleAddMemberOpen,
    handleSubmit,
    handleBack,
    onEditCorporateTrustee,
    removeAllCorporateTrustees,
    hasNotAddedCorporateTrustee,
  } = useSmsfTrusteeStep();
  const [confirmRemoveCorporateTrusteesOpen, setConfirmRemoveCorporateTrusteesOpen] = useState<boolean>(false);

  const handleRadioOnChange = useCallback(
    (newValue: boolean) => {
      if (hasCorporateTrustees === true && corporateTrustees.length && newValue === false) {
        return setConfirmRemoveCorporateTrusteesOpen(true);
      }
      setHasCorporateTrustees(newValue);
      toggleAddCorporateTrusteeOpen(false);
      toggleAddMemberOpen(false);
    },
    [
      corporateTrustees.length,
      hasCorporateTrustees,
      setHasCorporateTrustees,
      toggleAddCorporateTrusteeOpen,
      toggleAddMemberOpen,
    ],
  );

  const continueDisabled = useMemo(
    () =>
      hasCorporateTrustees === undefined ||
      (hasCorporateTrustees && (!corporateTrustees.length || addCorporateTrusteeOpen || hasNotAddedCorporateTrustee)),
    [addCorporateTrusteeOpen, corporateTrustees.length, hasCorporateTrustees, hasNotAddedCorporateTrustee],
  );

  return (
    <>
      <EntityOnboardingStepContainer
        customTitle='SMSF trustees & members'
        submitDisabled={continueDisabled}
        onSubmit={handleSubmit}
        onBack={handleBack}
      >
        <EntityOnboardingPageLayout
          subtitle='If applicable, add the corporate trustee of your Self Managed Super Fund (SMSF). We are required to
          collect this for regulatory purposes.'
        >
          <FlexLayout direction='column' spacing={12}>
            <Body weight='emphasis'>
              1. Was a company (Pty Ltd) set up to act as a corporate trustee, of which you are the director?
            </Body>
            <RadioGroup
              checkboxes={yesNoRadioGroup}
              selected={hasCorporateTrustees !== undefined ? getRadioSelectedValue(hasCorporateTrustees) : undefined}
              onChange={(value) => handleRadioOnChange(value === 'yes')}
            />
          </FlexLayout>
          {hasCorporateTrustees && (
            <FlexLayout direction='column' spacing={16}>
              <Body weight='emphasis'>2. Please add the corporate trustee:</Body>
              {corporateTrustees.map((trustee, index) => (
                <CorporateCard
                  key={`${trustee.corporate.name}-${index}`}
                  shareholder={trustee}
                  onRemove={() => handleRemove(trustee.uuid)}
                  onEditCorporateTrustee={onEditCorporateTrustee}
                />
              ))}
              {addCorporateTrusteeOpen && (
                <Card className='p-12'>
                  <AddCorporateTrustee onCancel={toggleAddCorporateTrusteeOpen} onSubmit={onAddCorporateTrustee} />
                </Card>
              )}
              {!corporateTrustees.length && !addCorporateTrusteeOpen && (
                <FlexLayout direction='row' className='w-full flex-wrap' spacing={8}>
                  <Button
                    leadingIcon={<Add />}
                    onClick={toggleAddCorporateTrusteeOpen}
                    disabled={addMemberOpen || addCorporateTrusteeOpen}
                  >
                    Add corporate trustee
                  </Button>
                </FlexLayout>
              )}
              {hasNotAddedCorporateTrustee && (
                <Body size='small' color='error'>
                  Please add the corporate trustee
                </Body>
              )}
            </FlexLayout>
          )}
        </EntityOnboardingPageLayout>
      </EntityOnboardingStepContainer>
      {confirmRemoveCorporateTrusteesOpen && (
        <ConfirmRemoveCorporateMembersModal
          open={confirmRemoveCorporateTrusteesOpen}
          setOpen={setConfirmRemoveCorporateTrusteesOpen}
          onConfirm={removeAllCorporateTrustees}
        />
      )}
    </>
  );
});

import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { uuid } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { Body } from '../Body';
import { BodyProps } from '../Body/Body.styles';

const Expandable = React.forwardRef<HTMLDivElement, PropsWithChildren<BodyProps>>(
  ({ className, children, ...props }) => {
    const [expandable, setExpandable] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);

    const id = useMemo(() => uuid(), []);

    const resizeObserver = useMemo(
      () =>
        new ResizeObserver((event) => {
          window.requestAnimationFrame(() => {
            if (event.length === 1) {
              const { scrollHeight, clientHeight } = event[0].target;
              if (!expandable) setExpandable(scrollHeight > clientHeight);
            }
          });
        }),
      [expandable],
    );

    useEffect(() => {
      const element = document.querySelector(`[data-container="${id}"]`);

      if (element) {
        resizeObserver.observe(element);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, [resizeObserver, id]);

    return (
      <>
        <Body data-container={id} {...props} className={cn(` ${expanded ? '' : 'line-clamp-3'}`, className)}>
          {children}
        </Body>

        {expandable && (
          <Body
            color='accent'
            weight='emphasis'
            size='small'
            className={`mt-8 cursor-pointer ${expandable ? 'visible' : 'invisible'}`}
            onClick={() => setExpanded(!expanded)}
          >
            Show {expanded ? 'less' : 'more'}
          </Body>
        )}
      </>
    );
  },
);
Expandable.displayName = 'Expandable';

export { Expandable };

import { useCallback, useMemo } from 'react';

import { UserBalance } from '@shared/api';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { mkConfig, generateCsv, download } from 'export-to-csv';
import { DateTime } from 'luxon';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { usePortfolioBalance } from './usePortfolioBalance';
import { usePortfolioValue } from './usePortfolioValue';
import { PortfolioReportData } from '../types';

const usePortfolioReport = () => {
  const countryAsset = useCountryAsset();
  const { getBalances } = usePortfolioBalance();

  const { getPortfolioValue, getAveragePricePaid, getProfitLossPercentage, getProfitLossValue } = usePortfolioValue({
    valueAsset: countryAsset,
    side: 'midPrice',
  });
  const { getAssetById } = useMarkets();
  const reportData = useMemo((): PortfolioReportData[] => {
    const balances = getBalances();
    const data: PortfolioReportData[] = [];

    Object.values(balances).forEach((balance: UserBalance) => {
      const asset = getAssetById(balance.assetId);

      if (!asset) return;

      data.push({
        assetCode: asset.code,
        assetName: asset.name,
        holdings: balance.availableBalance,
        currentValue: getPortfolioValue(asset).toString(),
        avgBuyPrice: getAveragePricePaid(asset).toString(),
        profitLossValue: getProfitLossValue(asset).toString(),
        profitLossPercentage: getProfitLossPercentage(asset).toString(),
      });
    });

    return data;
  }, [getAssetById, getAveragePricePaid, getBalances, getPortfolioValue, getProfitLossPercentage, getProfitLossValue]);

  const downloadReport = useCallback(() => {
    const config = mkConfig({ filename: `swyftx_balances_${DateTime.now().toISODate()}`, useKeysAsHeaders: true });

    const csv = generateCsv(config)(reportData);
    download(config)(csv);
  }, [reportData]);

  return {
    downloadReport,
  };
};

export { usePortfolioReport };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

const SingleWalletReceiveInfo: React.FC = () => (
  <Notification severity='info' title='Important information'>
    <FlexLayout direction='column' spacing={8} className='mt-8'>
      <Body size='small'>
        Crypto deposits that are sent to the wrong address are <strong>NOT recoverable.</strong>
      </Body>

      <Body size='small'>
        Crypto deposits for tokens that Swyftx does not list or support are <strong>NOT recoverable.</strong>
      </Body>

      <Body size='small'>
        Crypto deposits sent on the wrong network <strong>MAY NOT be recoverable.</strong>
      </Body>

      <Body size='small'>
        IF we are able to recover assets misplaced as a result of user error, recovery may take up to 180 days and may
        incur a recovery fee.
      </Body>

      <Body size='small'>
        <strong>ALWAYS send a small test deposit</strong> when making a first-time deposit of a new asset to Swyftx.
      </Body>
    </FlexLayout>
  </Notification>
);

export { SingleWalletReceiveInfo };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { TradeData } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';

import { observer } from 'mobx-react-lite';

type Props = {
  tradeData: TradeData;
};

const HighSlippageReviewItem: React.FC<Props> = observer(({ tradeData }) => {
  const { getMinimumOrderAmount, convertRate } = RatesStore.useRatesStore;
  const fromAsset = assetService.getAsset(tradeData.from);
  const toAsset = assetService.getAsset(tradeData.to);
  const { isMediumScreen } = useScreenBreakpoints();
  const { t } = useTranslation('trade', { keyPrefix: 'highSlippageModal' });

  const currentPrice = useMemo(() => {
    if (!fromAsset || !toAsset) return '';

    const minimumAmount = getMinimumOrderAmount(fromAsset);
    const rate = convertRate(fromAsset, toAsset, minimumAmount, 'midPrice').toString();
    return minimumAmount.div(rate).toString();
  }, [convertRate, fromAsset, getMinimumOrderAmount, toAsset]);

  const itemSize = useMemo(() => (isMediumScreen ? '25%' : '100%'), [isMediumScreen]);

  if (!fromAsset || !toAsset) return null;

  return (
    <Stack
      direction={isMediumScreen ? 'row' : 'column'}
      width='100%'
      spacing={1}
      alignItems='center'
      justifyContent='space-between'
      padding={1}
      paddingX={2}
    >
      <Stack
        order={1}
        direction='row'
        width={itemSize}
        spacing={1}
        justifyContent={isMediumScreen ? 'flex-start' : 'space-between'}
      >
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
          <AssetIcon asset={fromAsset} size={20} />
          <Typography fontWeight={500} fontSize={14}>
            {fromAsset.code}
          </Typography>
        </Stack>
        <Stack
          alignItems='center'
          justifyContent='center'
          color='text.primary'
          bgcolor='divider'
          borderRadius={3}
          width='24px'
          height='24px'
        >
          <ArrowLineOnlyRight className='h-18 w-18' />
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
          <Typography fontWeight={500} fontSize={14}>
            {toAsset.code}
          </Typography>
          <AssetIcon asset={toAsset} size={20} />
        </Stack>
      </Stack>

      <Stack
        order={2}
        direction='row'
        spacing={0.5}
        alignItems='flex-end'
        justifyContent={isMediumScreen ? 'flex-end' : 'space-between'}
        width={itemSize}
      >
        {!isMediumScreen && (
          <Typography fontSize={12} fontWeight={500} whiteSpace='nowrap'>
            {t('currentMarketPrice')}
          </Typography>
        )}
        <Box>
          <Typography display='inline-block' fontSize={14} number textAlign='right' fontWeight={400}>
            {formatCurrency(currentPrice, fromAsset, { appendCode: false, hideCode: true })}
          </Typography>
          <Typography
            display='inline-block'
            marginLeft={0.5}
            fontSize={14}
            number
            textAlign='right'
            color='text.secondary'
          >
            {fromAsset.code}/{toAsset.code}
          </Typography>
        </Box>
      </Stack>
      <Stack
        order={isMediumScreen ? 3 : 4}
        direction='row'
        alignItems='flex-end'
        justifyContent={isMediumScreen ? 'flex-end' : 'space-between'}
        width={itemSize}
      >
        {!isMediumScreen && (
          <Typography fontSize={12} fontWeight={500} whiteSpace='nowrap'>
            {t('slippage')}
          </Typography>
        )}
        <Typography
          color={(tradeData.slippagePercentage || 0) > HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD ? 'error' : 'warning.main'}
          number
          width='100%'
          fontSize={14}
          textAlign='right'
        >
          ~{tradeData.slippagePercentage?.toFixed(2)}%
        </Typography>
      </Stack>

      <Stack
        direction='row'
        order={isMediumScreen ? 4 : 3}
        spacing={0.5}
        alignItems='flex-end'
        justifyContent={isMediumScreen ? 'flex-end' : 'space-between'}
        width={itemSize}
      >
        {!isMediumScreen && (
          <Typography fontSize={12} fontWeight={500} whiteSpace='nowrap'>
            {t('quotePrice')}
          </Typography>
        )}
        <Box>
          <Typography display='inline-block' fontSize={14} number textAlign='right'>
            {formatCurrency(tradeData.price, fromAsset, { appendCode: false, hideCode: true })}
          </Typography>
          <Typography
            display='inline-block'
            marginLeft={0.5}
            fontSize={14}
            number
            textAlign='right'
            color='text.secondary'
          >
            {fromAsset.code}/{toAsset.code}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
});

export { HighSlippageReviewItem };

import React from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';

interface Props {
  status: string;
  color?: 'info' | 'success' | 'destructive';
}

export const OrderStatusChip: React.FC<Props> = (props) => {
  const { status, color } = props;

  return (
    <Chip color={color} variant='subtle' size='sm'>
      {status}
    </Chip>
  );
};

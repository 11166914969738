import React, { createContext, PropsWithChildren, useCallback } from 'react';

import { ratesService } from '@shared/services';
import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SwyftxPlatform } from '../utils.types';

type UIContextType = {
  currentPlatform?: SwyftxPlatform;
  openOnHover?: boolean;
  setCurrentPlatform: (newCurrentPlatform?: SwyftxPlatform) => void;
  setOpenOnHover: (openOnHover: boolean) => void;
};

const UIContext = createContext<UIContextType>({
  currentPlatform: 'swyftx',
  openOnHover: true,
  setCurrentPlatform: () => undefined,
  setOpenOnHover: () => undefined,
});

const UIContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentPlatform, setPlatform] = useLocalStorage<SwyftxPlatform>(StorageKey.CURRENT_PLATFORM, 'swyftx');
  const [openOnHover, setOpenOnHover] = useLocalStorage(StorageKey.NAVIGATION_HOVER_OPEN, true);

  const { navigate } = useNavigateRoute();

  const setCurrentPlatform = useCallback(
    (newCurrentPlatform?: SwyftxPlatform) => {
      setPlatform(newCurrentPlatform);

      if (newCurrentPlatform === 'swyftx') {
        navigate(NavigationURLs.Dashboard);
      } else {
        navigate(NavigationURLs.SwyftxPro, { pathParams: { asset: 'BTC' } });
      }

      window.setTimeout(() => ratesService.forceUpdate(), 100);
    },
    [navigate, setPlatform],
  );

  return (
    <UIContext.Provider value={{ currentPlatform, openOnHover, setOpenOnHover, setCurrentPlatform }}>
      {children}
    </UIContext.Provider>
  );
};

export { UIContextProvider, UIContext };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartSplit: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M11.248 2.75C11.248 2.33579 11.5838 2 11.998 2C17.5203 2 21.998 6.47779 21.998 12C21.998 12.4142 21.6623 12.75 21.248 12.75C20.8338 12.75 20.498 12.4142 20.498 12C20.498 7.30621 16.6918 3.5 11.998 3.5C11.5838 3.5 11.248 3.16421 11.248 2.75Z'
      fill='currentColor'
    />
    <path
      d='M7.99941 3.6228C8.2078 3.98077 8.08654 4.4399 7.72857 4.64829C5.19691 6.12207 3.49805 8.8633 3.49805 11.9999C3.49805 12.4142 3.16226 12.7499 2.74805 12.7499C2.33383 12.7499 1.99805 12.4142 1.99805 11.9999C1.99805 8.3077 3.99978 5.08331 6.97392 3.35195C7.33189 3.14356 7.79102 3.26482 7.99941 3.6228Z'
      fill='currentColor'
    />
    <path
      d='M4.33489 15.6758C4.15578 15.3023 3.70781 15.1447 3.33432 15.3238C2.96084 15.5029 2.80326 15.9509 2.98237 16.3244C4.07289 18.5984 6.02101 20.4659 8.57866 21.3968C13.7678 23.2855 19.5067 20.6103 21.3958 15.4201C21.5375 15.0309 21.3368 14.6005 20.9475 14.4588C20.5583 14.3171 20.1279 14.5178 19.9862 14.9071C18.3806 19.3187 13.5025 21.5927 9.09169 19.9873C6.91727 19.1959 5.2627 17.6105 4.33489 15.6758Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartSplit };

import React from 'react';

import { Icon } from '@swyftx/aviary/atoms/Icon/Icon';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Layer, Search } from '@swyftx/aviary/icons/outlined';

import { Category } from '@shared/api/@types/categories';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { CategoryIcons } from 'src/context/Categories/Categories.icons';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

interface MoreCategoriesProps {
  search: string;
  setSearch: (search: string) => void;
  categoriesToShow: Category[];
  onSelectCategory: (category: Category) => void;
}

export const MoreCategoriesContent: React.FC<MoreCategoriesProps> = observer((props) => {
  const { search, setSearch, categoriesToShow, onSelectCategory } = props;
  const { getAssetsByCategoryId } = useMarkets();
  const isXs = useTailwindBreakpoint('xs');

  return (
    <FlexLayout direction='column' className='p-12'>
      <FlexLayout direction='row' className='pb-12'>
        <Input
          placeholder='Search categories...'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onClear={() => setSearch('')}
          leading={<Search className='h-20 w-20 text-color-text-secondary' />}
        />
      </FlexLayout>
      <FlexLayout
        direction='column'
        className={cn(isXs ? 'max-h-[50vh] min-h-[50vh]' : 'max-h-[14rem] min-h-[14rem]', ' overflow-y-auto')}
      >
        {categoriesToShow.map((category) => (
          <FlexLayout
            direction='row'
            className='w-full cursor-pointer items-center justify-between rounded-8 p-8 hover:bg-color-background-surface-hover'
            key={category.id}
            onClick={() => onSelectCategory(category)}
          >
            <FlexLayout direction='row' spacing={4} className='items-center'>
              <Icon icon={<>{CategoryIcons(16)[category.name]}</>} />
              <Body>{category.name}</Body>
            </FlexLayout>
            <Body color='secondary' size='small'>
              {getAssetsByCategoryId(category.id).length} assets
            </Body>
          </FlexLayout>
        ))}
        {!categoriesToShow.length && (
          <FlexLayout direction='column' className='h-full w-full items-center justify-center p-48' spacing={8}>
            <Layer className='h-68 w-68 text-color-text-accent' />
            <Body color='secondary'>No categories found</Body>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import { TradeData } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

const useTradePanelTradeErrors = () => {
  const { setTradeUIData } = UniversalTradeStore;
  const processMaximumError = (tradeData: TradeData, error: any) => {
    const { limit, balance, from, to } = tradeData;

    // If the error is a maximum order error we need to do some processing on the message
    if (error.message.indexOf('maximum order of') > -1) {
      const number = Number(error.message.match(/\d+.\d+/)?.toString());

      // If we find a number in the message, we need to pass it into formatCurrency and append the code
      if (number) {
        const limitAsset = assetService.getAsset(limit);

        const maximumAmount = Big(balance).times(Big(1).minus(error.maximumPercent));

        const formattedNumber = formatCurrency(maximumAmount, limitAsset, {
          appendCode: false,
          hideCode: true,
        });

        // // Set the error based on the new parsed message
        setTradeUIData(from, to, {
          error: `Maximum order size of ${formattedNumber} ${limitAsset?.code}`,
          loading: false,
        });
      }
    } else {
      // If the error message doesn't contain the maximum order text, just set the message
      setTradeUIData(from, to, { error: error?.message, loading: false });
    }
  };

  return {
    processMaximumError,
  };
};

export { useTradePanelTradeErrors };

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useCurrentPrice } from '@hooks/Trade/useCurrentPrice';

import { useFormatInputText } from 'src/lib/utils/hooks/useFormatInputText';

import { PriceAlert, PriceAlertTriggerType, PriceAlertWindow } from '../../dynamicPriceAlerts.types';
import { useCheckDuplicatePriceAlert } from '../../hooks/useCheckDuplicatePriceAlert';

export type PriceDirection = 'decrease' | 'increase';

const MAXIMUM_PERCENTAGE = 10000;
const MINIMUM_PERCENTAGE = 1;

type Props = {
  value?: string;
  secondaryAsset: Asset;
  primaryAsset: Asset;
  alertType: PriceAlertTriggerType;
  alert?: PriceAlert;
  alertWindow?: PriceAlertWindow;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const usePriceAlertInput = ({
  value = '',
  alertType,
  primaryAsset,
  secondaryAsset,
  alert,
  setValue,
  alertWindow,
}: Props) => {
  const { t } = useTranslation('priceAlerts');
  const { checkForDuplicate } = useCheckDuplicatePriceAlert({ primary: primaryAsset.id, secondary: secondaryAsset.id });
  const currentPrice = useCurrentPrice(primaryAsset, secondaryAsset, 'midPrice');

  const { formattedAmount, onChangeValue, parseTradeValue, priceScale } = useFormatInputText({
    asset: secondaryAsset,
    value,
    onChange: (val) => {
      if (alertType === 'VOLATILITY') {
        const [, decimals] = val.split('.');
        if (!decimals || decimals.length <= 2) setValue(val);
      } else {
        setValue(val);
      }
    },
  });

  const priceDirection = useMemo((): PriceDirection | undefined => {
    if (!currentPrice) return undefined;

    if (Big(value).lt(currentPrice)) {
      return t('labels.decrease') as PriceDirection;
    }

    return t('labels.increase') as PriceDirection;
  }, [currentPrice, t, value]);

  const priceTitle = useMemo(() => {
    if (alertType === 'VOLATILITY') return t('labels.enterPercentage');

    if (!value || !priceDirection) return t('labels.enterPrice');

    return t('labels.priceMoves', { direction: priceDirection });
  }, [alertType, priceDirection, t, value]);

  const priceDiffPercentage = useMemo(() => {
    if (!currentPrice || !value) return undefined;

    const priceDifference = Big(value).minus(currentPrice);

    return priceDifference.div(currentPrice).abs().times(100).toFixed(2, 1);
  }, [value, currentPrice]);

  const onSelectPercentageTab = useCallback(
    (percentage: string) => {
      if (!currentPrice) return;

      const valuePercentage = Big(100).plus(percentage).div(100);

      if (!value) {
        const parsedValue = parseTradeValue(Big(currentPrice).times(valuePercentage).toFixed(priceScale), true);
        if (parsedValue) setValue(parsedValue);
      } else {
        const parsedValue = parseTradeValue(Big(value).times(valuePercentage).toFixed(priceScale), true);
        if (parsedValue) setValue(parsedValue);
      }
    },
    [currentPrice, parseTradeValue, priceScale, setValue, value],
  );

  const placeholder = useMemo(
    () => (alertType === 'LIMIT' ? Big(currentPrice).toFixed(priceScale) : '5'),
    [alertType, currentPrice, priceScale],
  );

  const error = useMemo(() => {
    if (!value.length || !currentPrice) return undefined;

    const bigValue = Big(value);

    if (alertType === 'VOLATILITY') {
      if (checkForDuplicate(value, primaryAsset, secondaryAsset, alert, alertWindow)) {
        return (
          <Body color='error' size='small'>
            Price alert already exists. Enter in a different percentage.
          </Body>
        );
      } else if (bigValue.gt(MAXIMUM_PERCENTAGE)) {
        return (
          <FlexLayout alignItems='center' spacing={4}>
            <Body color='error' size='small'>
              Percentage exceeds maximum
            </Body>
            <Body
              color='error'
              className='cursor-pointer underline'
              size='small'
              onClick={() => setValue(MAXIMUM_PERCENTAGE.toString())}
            >
              {MAXIMUM_PERCENTAGE.toFixed(2)}%
            </Body>
            <Body color='error' size='small'>
              alert limit.
            </Body>
          </FlexLayout>
        );
      } else if (bigValue.lt(MINIMUM_PERCENTAGE)) {
        return (
          <FlexLayout alignItems='center' spacing={4}>
            <Body color='error' size='small'>
              Percentage is below minimum
            </Body>
            <Body
              color='error'
              className='cursor-pointer underline'
              size='small'
              onClick={() => setValue(MINIMUM_PERCENTAGE.toString())}
            >
              {MINIMUM_PERCENTAGE.toFixed(2)}%
            </Body>
            <Body color='error' size='small'>
              alert limit.
            </Body>
          </FlexLayout>
        );
      }
    } else {
      const pricePercentage = Big(priceDiffPercentage);
      const maximumPriceValue = Big(currentPrice)
        .times(Big(MAXIMUM_PERCENTAGE).div(100))
        .plus(currentPrice)
        .toFixed(primaryAsset.price_scale);
      const minimumPriceValue = Big(currentPrice)
        .times(Big(MINIMUM_PERCENTAGE).div(100))
        .toFixed(primaryAsset.price_scale);

      if (checkForDuplicate(value, primaryAsset, secondaryAsset, alert, alertWindow)) {
        return (
          <Body color='error' size='small'>
            Price alert already exists. Enter in a different price.
          </Body>
        );
      } else if (pricePercentage.gt(MAXIMUM_PERCENTAGE)) {
        return (
          <FlexLayout alignItems='center' spacing={4}>
            <Body color='error' size='small'>
              Price alert exceeds maximum
            </Body>
            <Body
              color='error'
              className='cursor-pointer underline'
              size='small'
              onClick={() => setValue(maximumPriceValue.toString())}
            >
              {formatCurrency(maximumPriceValue, primaryAsset)}
            </Body>
            <Body color='error' size='small'>
              alert limit.
            </Body>
          </FlexLayout>
        );
      } else if (bigValue.lt(minimumPriceValue)) {
        return (
          <FlexLayout alignItems='center' spacing={4}>
            <Body color='error' size='small'>
              Price alert is below minimum
            </Body>
            <Body
              color='error'
              className='cursor-pointer underline'
              size='small'
              onClick={() => setValue(minimumPriceValue.toString())}
            >
              {formatCurrency(minimumPriceValue, primaryAsset)}
            </Body>
            <Body color='error' size='small'>
              alert limit.
            </Body>
          </FlexLayout>
        );
      }
    }

    return undefined;
  }, [
    alert,
    alertType,
    alertWindow,
    checkForDuplicate,
    currentPrice,
    priceDiffPercentage,
    primaryAsset,
    secondaryAsset,
    setValue,
    value,
  ]);

  return {
    currentPrice,
    placeholder,
    formattedAmount,
    priceDirection,
    priceTitle,
    priceDiffPercentage,
    error,
    onSelectPercentageTab,
    onChangeValue,
  };
};

export { usePriceAlertInput };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Scan: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M8.37847 4.25C8.79268 4.24974 9.12826 3.91375 9.128 3.49953C9.12774 3.08532 8.79175 2.74974 8.37753 2.75L6.69676 2.75105C4.45079 2.75362 2.63086 4.5746 2.63086 6.82095V8.7779C2.63086 9.19212 2.96665 9.5279 3.38086 9.5279C3.79507 9.5279 4.13086 9.19212 4.13086 8.7779V6.82095C4.13086 5.40287 5.28006 4.25288 6.69848 4.25105L8.37847 4.25Z'
      fill='currentColor'
    />
    <path
      d='M15.0312 3.5C15.0312 3.08579 15.367 2.75 15.7812 2.75H17.425C19.6683 2.75 21.5001 4.58179 21.5001 6.82514V8.7779C21.5001 9.19212 21.1643 9.5279 20.7501 9.5279C20.3359 9.5279 20.0001 9.19212 20.0001 8.7779V6.82514C20.0001 5.41021 18.8399 4.25 17.425 4.25H15.7812C15.367 4.25 15.0312 3.91421 15.0312 3.5Z'
      fill='currentColor'
    />
    <path
      d='M0.75 13.0146C0.75 12.6004 1.08579 12.2646 1.5 12.2646H22.6315C23.0457 12.2646 23.3815 12.6004 23.3815 13.0146C23.3815 13.4289 23.0457 13.7646 22.6315 13.7646H21.5001V17.5456C21.5001 19.7878 19.6685 21.6207 17.425 21.6207H15.7812C15.367 21.6207 15.0312 21.285 15.0312 20.8707C15.0312 20.4565 15.367 20.1207 15.7812 20.1207H17.425C18.8397 20.1207 20.0001 18.9596 20.0001 17.5456V13.7646H4.13086V17.5498C4.13086 18.9679 5.27967 20.1179 6.69809 20.1197L8.37847 20.1207C8.79268 20.121 9.12826 20.457 9.128 20.8712C9.12774 21.2854 8.79175 21.621 8.37753 21.6207L6.69715 21.6197C4.45118 21.6171 2.63086 19.7961 2.63086 17.5498V13.7646H1.5C1.08579 13.7646 0.75 13.4289 0.75 13.0146Z'
      fill='currentColor'
    />
  </svg>
);

export { Scan };

import { useCallback, useMemo } from 'react';

import { DarkModeEnum } from '@shared/enums';

import { useThemeStore } from 'src/lib/theme/hooks/useThemeStore';

const useToggleTheme = () => {
  const [darkMode, setDarkMode] = useThemeStore((state) => [state.darkMode, state.setDarkMode]);

  const isDarkMode = useMemo(() => darkMode === DarkModeEnum.DARK || darkMode === DarkModeEnum.MIDNIGHT, [darkMode]);

  const isMidnightMode = useMemo(() => darkMode === DarkModeEnum.MIDNIGHT, [darkMode]);
  const isLightMode = useMemo(() => darkMode === DarkModeEnum.LIGHT, [darkMode]);

  const toggleDarkMode = useCallback(
    (checked?: boolean) => {
      setDarkMode(checked ? DarkModeEnum.DARK : DarkModeEnum.LIGHT);
    },
    [setDarkMode],
  );

  return {
    toggleDarkMode,
    setDarkMode,
    darkMode,
    isLightMode,
    isDarkMode,
    isMidnightMode,
  };
};

export { useToggleTheme };

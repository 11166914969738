import { useEffect, useMemo } from 'react';

import { useLocation, useSessionStorage } from 'react-use';

type Platform = 'web' | 'mobile';
type AccountType = 'personal' | 'entity';

/**
 * Parses and stores URL parameters in session storage.
 * We need to use the useEffects as a listener for search parameters.
 * As the component may not have updated we need to use the useMemo consts to return the actual value
 */
export const useQueryMetadata = () => {
  const { search } = useLocation();
  const [platformParam, setPlatformParam] = useSessionStorage<Platform>('platform', 'web');
  const [accountTypeParam, setAccountTypeParam] = useSessionStorage<AccountType>('accountType', 'personal');

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const newPlatform = searchParams.get('platform');
    if (newPlatform && ['web', 'mobile'].includes(newPlatform) && platformParam !== newPlatform) {
      setPlatformParam(newPlatform as Platform);
    }
  }, [platformParam, search, searchParams, setPlatformParam]);

  useEffect(() => {
    const newAccountType = searchParams.get('accountType');
    if (newAccountType && ['personal', 'entity'].includes(newAccountType) && accountTypeParam !== newAccountType) {
      setAccountTypeParam(newAccountType as AccountType);
    }
  }, [accountTypeParam, searchParams, setAccountTypeParam]);

  const platform = useMemo(() => {
    const platformSearchParams = searchParams.get('platform');
    if (platformSearchParams) return platformSearchParams as Platform;
    return platformParam;
  }, [platformParam, searchParams]);

  const accountType = useMemo(() => {
    const accountTypeSearchParams = searchParams.get('accountType');
    if (accountTypeSearchParams) return accountTypeSearchParams as AccountType;
    return accountTypeParam;
  }, [accountTypeParam, searchParams]);

  return { platform, accountType };
};

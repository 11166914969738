export enum EntityTypeEnum {
  PERSONAL = 'PERSONAL',
  INVITE = 'INVITE',
  COMPANY = 'COMPANY',
  SMSF = 'SMSF',
  JOINT = 'JOINT',
  SOLETRADER = 'SOLETRADER',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
}

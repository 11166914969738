import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  showContinueButton?: boolean;
  onContinue?: () => void;
};

const WhatIsDustingContent: React.FC<Props> = ({ showContinueButton = true, onContinue }) => (
  <FlexLayout
    direction='column'
    className='max-h-[80vh] min-h-[80vh] px-24 pb-8 sm:max-h-[400px] sm:min-h-[400px]'
    justifyContent='space-between'
    spacing={24}
  >
    <Body color='secondary' size='small'>
      Dusting is a simple way to sell out of any crypto asset with balance smaller than our minimum sell amount. You can
      dust mulitple assets at once, which will be credited to your account.
    </Body>
    {showContinueButton && (
      <Button size='lg' onClick={onContinue}>
        Continue
      </Button>
    )}
  </FlexLayout>
);

export { WhatIsDustingContent };

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { clsx } from 'clsx';

import { HeadingProps, headingVariants } from './Heading.styles';

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, size, color, weight, ...props }, ref) =>
    React.createElement(size, { className: clsx(headingVariants({ size, color, weight, className })), ...props, ref }),
);

Heading.displayName = 'Heading';

export { Heading };

import { useCallback, useContext, useMemo } from 'react';

import { Asset, OrderbookResponseItem } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';
import { useOrderbooks } from 'src/lib/trade/hooks/Orderbooks/useOrderbooks';

import { SwyftxProTradeContext } from '../context';
import { OrderBookValueType } from '../types/TradePro.types';

type Props = {
  selectedAsset: Asset;
  baseAsset: Asset;
};

const useSwyftxProOrderbooks = ({ selectedAsset, baseAsset }: Props) => {
  const { setTotal, setAmount, setLimitAsset, setTradeType, setTriggerPrice, tradeType, limitAsset } =
    useContext(SwyftxProTradeContext);
  const { data: orderbook, isFetching } = useOrderbooks({ baseAsset, secondaryAsset: selectedAsset });
  const avo = useAvo();
  const location = useLocation();

  const data = useMemo(() => orderbook?.[selectedAsset.id], [orderbook, selectedAsset.id]);
  const isLoading = useMemo(() => isFetching && !data, [data, isFetching]);

  const onClickValue = useCallback(
    (value: string, valueType: OrderBookValueType) => {
      switch (valueType) {
        case 'price':
          if (tradeType !== 'limit') setTradeType('limit');
          setTriggerPrice(value);
          avo.orderBookClicked({ screen: location.pathname, swyftxPro: 'true' });
          return;
        case 'qty':
          if (limitAsset?.code === baseAsset.code) setLimitAsset(selectedAsset);
          setAmount(value);
          return;
        case 'total':
          if (limitAsset?.code === selectedAsset.code) setLimitAsset(baseAsset);
          setTotal(value);
          return;
        default:
          return;
      }
    },
    [
      avo,
      baseAsset,
      limitAsset?.code,
      location.pathname,
      selectedAsset,
      setAmount,
      setLimitAsset,
      setTotal,
      setTradeType,
      setTriggerPrice,
      tradeType,
    ],
  );

  const asks = useMemo(() => {
    if (!data || !(data as OrderbookResponseItem)?.asks) return undefined;

    return [...(data as OrderbookResponseItem).asks];
  }, [data]);

  const bids = useMemo(() => {
    if (!data || !(data as OrderbookResponseItem)?.bids) return undefined;

    return [...(data as OrderbookResponseItem).bids].reverse();
  }, [data]);

  const midPrice = useMemo(() => {
    if (!data || !(data as OrderbookResponseItem)?.mid) return '';

    return (data as OrderbookResponseItem).mid;
  }, [data]);

  return {
    asks,
    bids,
    midPrice,
    isLoading,
    onClickValue,
  };
};

export { useSwyftxProOrderbooks };

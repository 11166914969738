import React, { useEffect, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@swyftx/aviary/atoms/Resizable/Resizable';
import { ScrollView } from '@swyftx/aviary/atoms/ScrollView';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';

import { StorageKey } from '@shared/storage';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';
import {
  SwyftxProBalanceOrders,
  SwyftxProOrderBook,
  SwyftxProAssetDetails,
  SwyftxProChart,
  SwyftxProTradePanel,
} from 'src/lib/trade-pro/components';
import { useSwyftxProTradeAssets, useSwyftxProLayout } from 'src/lib/trade-pro/hooks';
import { ProPanels } from 'src/lib/trade-pro/types/TradePro.types';
import { useMinimizedResize } from 'src/lib/utils/hooks/useMinimizedResize';

const SwyftxProPage: React.FC = observer(() => {
  const [selectedPanel, setSelectedPanel] = useState<ProPanels>('chart');
  const { update } = useIntercom();
  const { selectedAsset, baseAsset } = useSwyftxProTradeAssets();
  const { showOrderBook, showOrdersAndBalances } = useSwyftxProLayout();
  const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'pro-page' });
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { containerRef, panelRef, minimizedTileHeight, minimizedTile, setMinimizedTile, toggleMinimize } =
    useMinimizedResize({
      key: StorageKey.ORDERS_TILE_MINIMIZED,
      minimizedHeight: 56,
    });

  useEffect(() => {
    if (!isFeatureEnabled(AppFeature.MidnightTheme)) {
      document.documentElement.classList.add('pro');
    } else {
      document.documentElement.classList.remove('pro');
    }
    update({
      hideDefaultLauncher: true,
    });

    return () => {
      document.documentElement.classList.remove('pro');
      update({
        hideDefaultLauncher: false,
      });
    };
  }, [isFeatureEnabled, update]);

  if (!selectedAsset || !baseAsset) return null;

  return (
    <FlexLayout
      direction='column'
      spacing={2}
      className='h-full w-full overflow-hidden p-2 @container'
      data-container='pro-page'
    >
      <FlexLayout direction='row' className='w-full lg:min-h-[4rem]'>
        <SwyftxProAssetDetails className='h-full w-full' baseNavigationUrl={NavigationURLs.SwyftxPro} />
      </FlexLayout>
      <FlexLayout direction='row' className='h-full w-full overflow-hidden' spacing={2}>
        <FlexLayout direction='column' className='h-full w-full' spacing={2} ref={containerRef}>
          <ResizablePanelGroup direction='vertical' autoSaveId='pro-trade-page-orders-and-chart'>
            <ResizablePanel minSize={40} order={1} id='trading-view-tile'>
              <FlexLayout className='h-full w-full' spacing={2}>
                <SwyftxProChart
                  className='h-full w-full'
                  selectedPanel={selectedPanel}
                  setSelectedPanel={setSelectedPanel}
                />
                {atLeast.lg && showOrderBook && (
                  <SwyftxProOrderBook
                    className='hidden h-full min-w-[22.25rem] lg:block'
                    selectedAsset={selectedAsset}
                    baseAsset={baseAsset}
                  />
                )}
              </FlexLayout>
            </ResizablePanel>
            {atLeast.sm && showOrdersAndBalances && (
              <>
                <ResizableHandle
                  withHandle
                  border
                  dragClassName='bg-color-background-primary'
                  className='my-0 w-full rounded-0 py-1 hover:bg-color-background-primary'
                />
                <ResizablePanel
                  id='orders-tile'
                  order={2}
                  defaultSize={30}
                  collapsible
                  ref={panelRef}
                  collapsedSize={minimizedTileHeight}
                  minSize={minimizedTileHeight}
                  onCollapse={() => setMinimizedTile(true)}
                  onResize={() => setMinimizedTile(false)}
                >
                  <SwyftxProBalanceOrders
                    className='hidden h-full w-full overflow-auto sm:block'
                    minimized={minimizedTile}
                    setMinimized={toggleMinimize}
                  />
                </ResizablePanel>
              </>
            )}
          </ResizablePanelGroup>
        </FlexLayout>
        <ScrollView className='!hidden w-[19rem] min-w-[19rem] max-w-[19rem] md:!flex'>
          <SwyftxProTradePanel />
        </ScrollView>
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxProPage };

import { useCallback, useEffect } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import * as Sentry from '@sentry/react';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

type Props = {
  tvWidget?: IChartingLibraryWidget;
};

const useTradingViewThemeAdapter = ({ tvWidget }: Props) => {
  const { theme } = useTailwindTheme();

  const updateTheme = useCallback(async () => {
    if (!tvWidget || !tvWidget.changeTheme) return;
    try {
      await tvWidget?.changeTheme(document.documentElement.classList.contains('light') ? 'Light' : 'Dark', {
        disableUndo: true,
      });

      tvWidget?.chart()?.refreshMarks();

      tvWidget.applyOverrides({
        'paneProperties.background': getComputedStyle(document.body).getPropertyValue(
          '--color-background-surface-primary',
        ),
        'paneProperties.backgroundType': 'solid',
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [tvWidget]);

  useEffect(() => {
    if (tvWidget) {
      tvWidget.onChartReady(async () => {
        updateTheme();
      });
    }
  }, [theme, tvWidget, updateTheme]);

  return { updateTheme };
};

export { useTradingViewThemeAdapter };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useSourceOfWealthAnalytics } from '../useSourceOfWealthAnalytics';

const HeaderExitButton: React.FC = () => {
  const { isXs } = useScreenBreakpoints();
  const { navigate } = useNavigateRoute();
  const { exited } = useSourceOfWealthAnalytics();

  const handleClick = () => {
    exited();
    navigate(NavigationURLs.Dashboard);
  };

  if (isXs) {
    return <Button variant='ghost' onClick={handleClick} leadingIcon={<Cross />} layout='icon' />;
  }

  return (
    <Button variant='ghost' onClick={handleClick}>
      Save and exit
    </Button>
  );
};

export { HeaderExitButton };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TakeProfitPercentage: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.78575 4.5C1.98363 4.5 1.33337 5.15025 1.33337 5.95238C1.33337 6.75451 1.98363 7.40476 2.78575 7.40476C3.58788 7.40476 4.23814 6.75451 4.23814 5.95238C4.23814 5.15025 3.58788 4.5 2.78575 4.5ZM2.33337 5.95238C2.33337 5.70254 2.53591 5.5 2.78575 5.5C3.0356 5.5 3.23814 5.70254 3.23814 5.95238C3.23814 6.20222 3.0356 6.40476 2.78575 6.40476C2.53591 6.40476 2.33337 6.20222 2.33337 5.95238Z'
      fill='currentColor'
    />
    <path
      d='M8.8426 5.36071C9.03786 5.16545 9.03786 4.84887 8.8426 4.65361C8.64734 4.45835 8.33076 4.45835 8.1355 4.65361L1.4961 11.293C1.30083 11.4883 1.30083 11.8049 1.4961 12.0001C1.69136 12.1954 2.00794 12.1954 2.2032 12.0001L8.8426 5.36071Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.09542 10.7143C6.09542 9.91213 6.74567 9.26188 7.5478 9.26188C8.34993 9.26188 9.00018 9.91213 9.00018 10.7143C9.00018 11.5164 8.34993 12.1666 7.5478 12.1666C6.74567 12.1666 6.09542 11.5164 6.09542 10.7143ZM7.5478 10.2619C7.29796 10.2619 7.09542 10.4644 7.09542 10.7143C7.09542 10.9641 7.29796 11.1666 7.5478 11.1666C7.79764 11.1666 8.00018 10.9641 8.00018 10.7143C8.00018 10.4644 7.79764 10.2619 7.5478 10.2619Z'
      fill='currentColor'
    />
    <path
      d='M12.0166 11.8335C11.7405 11.8335 11.5166 11.6096 11.5166 11.3335L11.5166 6.54389L10.3792 7.68629C10.1843 7.88197 9.86773 7.88265 9.67205 7.68781C9.47637 7.49297 9.47569 7.17639 9.67053 6.9807L11.662 4.98061C11.7559 4.88638 11.8834 4.8334 12.0163 4.8334C12.0296 4.8334 12.0428 4.83392 12.0559 4.83496C12.1834 4.84486 12.2975 4.90259 12.3802 4.99023L14.3625 6.98067C14.5573 7.17634 14.5567 7.49292 14.361 7.68778C14.1653 7.88264 13.8488 7.88198 13.6539 7.68632L12.5166 6.54435V11.3335C12.5166 11.6096 12.2928 11.8335 12.0166 11.8335Z'
      fill='currentColor'
    />
  </svg>
);

export { TakeProfitPercentage };

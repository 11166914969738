import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { NavigationErrorBoundary } from '@global-components/ErrorBoundaries';

import env from '@shared/config';
import { AppStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useTrackPageView } from './useTrackPageView';

type Props = {
  pageName?: string;
  element: React.ReactElement;
};

const PrivateRoute: React.FC<Props> = observer(({ element, pageName = '' }) => {
  const { isMaintenanceMode } = AppStore.useAppStore;
  const { userProfile } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { pathname, search } = useLocation();

  useTrackPageView({ pageName });

  if (isMaintenanceMode || env.MAINTENANCE_MODE === true || isFeatureEnabled(AppFeature.MaintenanceMode)) {
    return <Navigate to={NavigationURLs.Maintenance} />;
  }

  if (!userProfile) {
    return <Navigate to={NavigationURLs.Login} state={{ redirectTo: `${pathname}${search}` }} />;
  }

  if (pathname !== NavigationURLs.PasswordReset && userProfile && userProfile.metadata?.passwordResetRequired) {
    return <Navigate to={NavigationURLs.PasswordReset} />;
  }

  return <NavigationErrorBoundary>{element}</NavigationErrorBoundary>;
});

PrivateRoute.displayName = 'PrivateRoute';

export default PrivateRoute;

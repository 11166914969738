import React, { PropsWithChildren, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FlexLayoutProps } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Numeric, Utility } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Deposit, Information, Withdraw } from '@swyftx/aviary/icons/outlined';

import { Big } from '@shared/safe-big';

type Props = {
  title?: string;
  data: string;
  percentage?: boolean;
  renderIcons?: boolean;
  color?: 'primary' | 'error' | 'success' | 'secondary';
  className?: string;
  textClassName?: string;
  loading?: boolean;
  tooltip?: string;
} & Pick<NumericProps, 'size'> &
  Pick<NumericProps, 'weight'> &
  Pick<FlexLayoutProps, 'alignItems'>;

const NumericDataItem = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      className,
      textClassName,
      renderIcons = true,
      title,
      data,
      size = 'xxlarge',
      color,
      loading = false,
      percentage = false,
      alignItems = 'start',
      tooltip = '',
      children,
      weight,
    },
    ref,
  ) => {
    const dataValue = useMemo(() => {
      const parsedData = data.replace(/[$%]/g, '');
      return Big(parsedData);
    }, [data]);

    const textColor = useMemo(() => {
      if (!percentage && color) return color;
      if (dataValue.lt(0)) return 'error';

      return 'success';
    }, [percentage, color, dataValue]);

    const value = useMemo(() => {
      if (!percentage) return children || data;

      if (children) return children;

      return renderIcons ? data.replace('-', '') : data;
    }, [children, data, percentage, renderIcons]);

    // TODO potentially refactor the below icons into an icon component that can use the same size as the text
    const icon = useMemo(() => {
      switch (size) {
        case 'small':
          return {
            iconClasses: 'w-16 h-16',
            positiveClasses: 'mt-4',
            negativeClasses: 'mb-6',
          };
        case 'medium':
          return {
            iconClasses: 'w-16 h-16',
            positiveClasses: 'mt-4',
            negativeClasses: 'mb-4',
          };
        case 'xlarge':
          return {
            iconClasses: 'w-26 h-26',
            positiveClasses: 'mt-4',
            negativeClasses: 'mb-6',
          };
        default:
          return {
            iconClasses: 'w-33 h-33',
            positiveClasses: 'mt-8',
            negativeClasses: 'mb-10',
          };
      }
    }, [size]);

    return (
      <FlexLayout spacing={8} direction='column' ref={ref} className={className} alignItems={alignItems}>
        {title && (
          <FlexLayout alignItems='center' spacing={2}>
            <Utility variant='overline'>{title}</Utility>
            {tooltip && (
              <Tooltip title={tooltip}>
                <Information className='h-[12px] w-[12px] cursor-pointer text-color-text-secondary' />
              </Tooltip>
            )}
          </FlexLayout>
        )}
        <FlexLayout alignItems={dataValue.gt(0) ? 'start' : 'end'} spacing={4} className='items-center'>
          <Numeric size={size} color={textColor} loading={!value || loading} weight={weight} className={textClassName}>
            {value}
          </Numeric>
          {percentage && renderIcons && !loading && (
            <>
              {dataValue.gte(0) && (
                <div className={`${icon.positiveClasses} text-color-text-success`}>
                  <Withdraw className={icon.iconClasses} />
                </div>
              )}
              {dataValue.lt(0) && (
                <div className={`${icon.negativeClasses} text-color-text-error`}>
                  <Deposit className={icon.iconClasses} />
                </div>
              )}
            </>
          )}
        </FlexLayout>
      </FlexLayout>
    );
  },
);

NumericDataItem.displayName = 'NumericDataItem';

export { NumericDataItem };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle: string | React.ReactElement;
}

export const EntityApplicationIntroductionStepItem: React.FC<Props> = (props) => {
  const { icon, title, subtitle } = props;

  return (
    <FlexLayout direction='row' spacing={24} alignItems='center'>
      {icon}
      <FlexLayout direction='column' spacing={4}>
        <Body weight='emphasis'>{title}</Body>
        <Body color='secondary'>{subtitle}</Body>
      </FlexLayout>
    </FlexLayout>
  );
};

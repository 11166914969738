import { NavigationURLs } from 'src/lib/navigation/types';

/* eslint-disable max-len */
export enum NanoColor {
  OnyxBlack = '#000000',
  MatteBlack = '#000000',
  PastelGreen = '#d2ede5',
  AmethystPurple = '#4a3ec1',
  RetroGaming = '#6d4978',
  BTCOrange = '#ffa841',
}

export type ShopItemType = {
  id: string;
  title: string;
  description: string;
  defaultColor: NanoColor;
  colors: { [key: string]: string };
  price: string;
  shippingTime: string;
  features: string[];
  url: string;
  internalUrl: NavigationURLs;
  buttonText: string;
  chipText?: string;
};

export type ShopInfo = {
  id: string;
  title: any;
  content: any;
};

import React from 'react';

import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const AssetDailyChange: React.FC<Props> = observer(({ asset }) => {
  const { getRate } = RatesStore.useRatesStore;
  const rate = getRate(asset);

  return (
    <NumericDataItem
      className='truncate'
      data={`${rate.dailyPriceChange || '0.0'}%`}
      renderIcons={false}
      percentage
      size='small'
      alignItems='end'
    />
  );
});

export { AssetDailyChange };

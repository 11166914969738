import { TabItem } from '@swyftx/aviary/atoms/Tabs';

export enum TradePageVariant {
  CLASSIC = 'Classic',
  ADVANCED = 'Advanced',
}

export enum TradePageType {
  CHART = 'chart',
  WALLETS = 'wallets',
  OPEN_ORDERS = 'open-orders',
  ORDER_HISTORY = 'order-history',
}

export type TradePageTab = TabItem<TradePageType>;

export const tradePageTabs: TradePageTab[] = [
  {
    title: 'Chart',
    value: TradePageType.CHART,
  },
  {
    title: 'Wallets',
    value: TradePageType.WALLETS,
  },
  {
    title: 'Open orders',
    value: TradePageType.OPEN_ORDERS,
  },
  {
    title: 'Order history',
    value: TradePageType.ORDER_HISTORY,
  },
];

export const isAdvanced = (variant: TradePageVariant) => variant === TradePageVariant.ADVANCED;

export const variantClass = (advanced: boolean) =>
  advanced
    ? 'bg-color-background-pageBG'
    : 'border-color-border-main rounded-16 border  bg-color-background-surface-primary';

import React, { useCallback, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { observer } from 'mobx-react-lite';
import { EditPriceAlertModal } from 'src/lib/dynamic-price-alerts/components/EditPriceAlertModal';
import { PriceAlert, PriceAlertGroupBy } from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';

import { DynamicPriceAlertTableData, useDynamicPriceActiveAlertsTable } from './useDynamicPriceActiveAlertsTable';

type Props = {
  alerts: PriceAlert[];
  groupBy: PriceAlertGroupBy;
  icon: React.ReactElement;
  title: string;
};

const DynamicPriceAlertTable: React.FC<Props> = observer(({ alerts, groupBy, icon, title }) => {
  const { headers, data, onSort, initialSort } = useDynamicPriceActiveAlertsTable({ alerts, groupBy });

  const [editAlert, setEditAlert] = useState<PriceAlert>();

  const onClickItem = useCallback(
    (item: EnhancedTableData<DynamicPriceAlertTableData, unknown>) => {
      setEditAlert(item.value as PriceAlert);
    },
    [setEditAlert],
  );

  return (
    <>
      <Card className='w-full overflow-hidden'>
        <FlexLayout alignItems='center' spacing={8} className='border-b border-color-border-main px-24 py-16'>
          {icon}
          <Heading size='h6' weight='normal'>
            {title}
          </Heading>
        </FlexLayout>
        <EnhancedTable<DynamicPriceAlertTableData>
          data-container='assets-table'
          onClickItem={onClickItem}
          headers={headers}
          sort={initialSort}
          data={data}
          onSort={onSort}
        />
      </Card>
      {editAlert && <EditPriceAlertModal alert={editAlert} onClose={() => setEditAlert(undefined)} />}
    </>
  );
});

export { DynamicPriceAlertTable };

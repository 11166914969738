import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { types } from '@swyftx/marketing-utils';

const useRewardQualificationProgress = (
  redemptionRequirement: types.UserAssetRedemptionRequirement | types.UserAssetRewardQualification,
) => {
  const { t } = useTranslation('rewards', { keyPrefix: 'progress' });

  const label = useMemo(() => {
    const { trigger, thresholdAmount } = redemptionRequirement;
    const progress: number = (redemptionRequirement as types.UserAssetRedemptionRequirement).progress || 0;
    const calcProgress = Math.min(progress || 0, thresholdAmount || 0);

    switch (trigger) {
      case types.AssetRewardQualificationTrigger.GreenIdComplete:
        return t('greenId');
      case types.AssetRewardQualificationTrigger.TradesComplete:
        return t('trade', {
          value: `$${calcProgress}/$${thresholdAmount}`,
        });
      default:
        return '';
    }
  }, [redemptionRequirement, t]);

  return {
    label,
  };
};

export { useRewardQualificationProgress };

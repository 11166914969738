import { useMemo } from 'react';

import {
  Clock,
  Dashboard,
  ExternalLink,
  Gift,
  HighContrast,
  Key,
  Paper,
  Password,
  PriceAlert,
  Profile,
  QuickBuy,
  ShieldPass,
  Shop,
  ThreeUser,
  Vip,
} from '@swyftx/aviary/icons/outlined';
import { Bundles, Coin, Wallet, Recurring, PortfolioTransfer } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { Links } from '@shared/constants';
import { Big } from '@shared/safe-big';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

import { GlobalSearchItemType } from '../../search.types';

type Props = {
  assets?: Asset[];
};

const usePagesSearchModule = ({ assets }: Props): GlobalSearchItemType[] => {
  const { navigate } = useNavigateRoute();
  const countryAsset = useCountryAsset();
  const { getBalance } = usePortfolioBalance();

  const asset = useMemo(() => {
    if (!assets?.length) return undefined;

    return assets[0];
  }, [assets]);

  const items = useMemo(
    (): GlobalSearchItemType[] => [
      {
        title: 'Dashboard',
        subTitle: 'View an overview of your account',
        leading: <Dashboard width={24} height={24} />,
        onClick: () => navigate(NavigationURLs.Dashboard),
      },
      {
        title: 'Quick buy',
        subTitle: `Simplified buying and selling crypto with ${countryAsset?.name}s`,
        leading: <QuickBuy width={24} height={24} />,
        additionalKeywords: ['trade'],
        onClick: () => navigate(NavigationURLs.QuickBuy),
      },
      {
        title: 'Price alerts',
        subTitle: `Never miss a market movement with custom price alerts`,
        leading: <PriceAlert width={24} height={24} />,
        onClick: () => navigate(NavigationURLs.PriceAlerts),
      },
      {
        title: 'Shop',
        subTitle: 'Purchase an offline wallet to store your crypto',
        leading: <Shop />,
        onClick: () => navigate(NavigationURLs.Shop),
      },
      {
        title: 'Transfer portfolio',
        subTitle: 'Move your portfolio from another exchange to Swyftx',
        leading: <PortfolioTransfer />,
        onClick: () => navigate(NavigationURLs.PortfolioTransfer),
      },
      {
        title: 'Asset list',
        subTitle: 'Discover all the assets we list on Swyftx',
        leading: <Coin />,
        onClick: () => navigate(NavigationURLs.Assets),
      },
      {
        title: 'Wallets',
        subTitle: 'View all the Cryptocurrencies that you currently own',
        leading: <Wallet />,
        onClick: () => navigate(NavigationURLs.WalletsRoot),
      },
      {
        title: 'Bundles',
        subTitle: 'View all the Cryptocurrency bundles that you can buy',
        leading: <Bundles />,
        onClick: () => navigate(NavigationURLs.Bundles),
      },
      {
        title: 'Auto Invest',
        subTitle: 'Setup an auto investment to purchase Cryptocurrencies periodically',
        leading: <Recurring />,
        onClick: () => navigate(NavigationURLs.AutoInvest),
      },
      {
        title: 'Transactions',
        subTitle: 'View completed and pending orders, withdrawals and deposits',
        leading: <Clock />,
        onClick: () => navigate(NavigationURLs.TransactionsRoot),
      },
      {
        title: 'Account information',
        subTitle: 'View account information',
        leading: <Profile />,
        onClick: () => navigate(NavigationURLs.ProfileInformation),
      },
      {
        title: 'Account verification',
        subTitle: 'View account verification status',
        leading: <ShieldPass />,
        onClick: () => navigate(NavigationURLs.ProfileVerification),
      },
      {
        title: 'Account personalisation',
        subTitle: 'View account personalisation settings',
        leading: <HighContrast />,
        additionalKeywords: ['personalization', 'ui', 'theme'],
        onClick: () => navigate(NavigationURLs.ProfileSettings),
      },
      {
        title: 'Account security',
        subTitle: 'View account security',
        leading: <Key />,
        onClick: () => navigate(NavigationURLs.ProfileSecurity),
      },
      {
        title: 'Transaction reports',
        subTitle: 'View and generate transaction reports',
        leading: <Paper />,
        additionalKeywords: ['transactions'],
        onClick: () => navigate(NavigationURLs.ProfileTaxReports),
      },
      {
        title: 'Account trade fees',
        subTitle: 'View your current account trade fees',
        leading: <Vip />,
        additionalKeywords: ['trade'],
        onClick: () => navigate(NavigationURLs.ProfileTradeFees),
      },
      {
        title: 'Affiliate program',
        subTitle: 'View your current affiliate earnings',
        leading: <ThreeUser />,
        onClick: () => navigate(NavigationURLs.ProfileAffiliateProgram),
      },
      {
        title: 'API keys',
        subTitle: 'View and manage your api keys',
        leading: <Password />,
        onClick: () => navigate(NavigationURLs.ProfileApiKeys),
      },
      {
        title: 'Refer a Friend',
        subTitle: 'View your referral earnings',
        leading: <ThreeUser />,
        onClick: () => navigate(NavigationURLs.ProfileReferAFriend),
      },
      {
        title: 'Swyftx Rewards',
        subTitle: 'View your earnings from Swyftx Rewards',
        leading: <Gift />,
        onClick: () => navigate(NavigationURLs.RewardsRoot),
      },
      {
        title: 'Swyftx Learn',
        subTitle: 'Educational resources to build your crypto and blockchain knowledge',
        leading: <ExternalLink />,
        additionalKeywords: ['help'],
        onClick: () => window.open(Links.learn.root, '_blank', 'noopener,noreferrer'),
      },
      {
        title: 'Swyftx Support centre',
        subTitle: 'Step-by-step guides, helpful resources, and more',
        leading: <ExternalLink />,
        additionalKeywords: ['help'],
        onClick: () => window.open(Links.help.home, '_blank', 'noopener,noreferrer'),
      },
      {
        title: `${asset?.name} asset page`,
        subTitle: `View information about ${asset?.name}`,
        leading: <AssetIcon asset={asset} size={24} />,
        hidden: !asset,
        forceShow: asset !== undefined,
        onClick: () => navigate(NavigationURLs.SingleAsset, { pathParams: { asset: asset?.code } }),
      },
      {
        title: `${asset?.name} wallet`,
        subTitle: `View your ${asset?.name} wallet`,
        leading: <AssetIcon asset={asset} size={24} />,
        hidden: !asset || Big(getBalance(asset.id)?.availableBalance).lte(0),
        forceShow: asset !== undefined,
        onClick: () => navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset?.code } }),
      },
    ],
    [asset, countryAsset?.code, getBalance, navigate],
  );

  return items;
};

export { usePagesSearchModule };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { RewardType } from '@routes/Rewards/types';

type Props = {
  rewardType: RewardType;
};

const EmptyRewards: React.FC<Props> = ({ rewardType }) => {
  const { t } = useTranslation('rewards', { keyPrefix: 'empty' });

  return (
    <FlexLayout className='p-32' spacing={8} alignItems='center' direction='column'>
      <Image image='empty_order' usePalette className='w-96' alt='empty total rewards' />
      <Body weight='bold' size='large' className='text-center'>
        {t('title')}
      </Body>
      <Body color='secondary' className='text-center'>
        {t(rewardType)}
      </Body>
    </FlexLayout>
  );
};

export { EmptyRewards };

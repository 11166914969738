import React, { useCallback } from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { TextArea } from '@swyftx/aviary/atoms/TextArea';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { CashDepositPercentageOption } from '@shared/api/@types/compliance';

import { useSourceOfWealthSelector, useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';
import { Controller, useForm } from 'react-hook-form';

import { SourceOfWealthStepContainer } from './SourceOfWealthStepContainer';

type FormInputs = { cashDepositsPercentage: CashDepositPercentageOption; cashDepositsOrigin?: string };

const CashIncomeStep: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();
  const verificationData = useSourceOfWealthSelector((state) => state.context.verificationData);

  const { control, handleSubmit, watch } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      cashDepositsPercentage: verificationData.cashDepositsPercentage,
      cashDepositsOrigin: verificationData.cashDepositsOrigin,
    },
  });

  const cashDepositsPercentage = watch('cashDepositsPercentage');
  const cashDepositsOrigin = watch('cashDepositsOrigin');

  const canContinue = useCallback(() => {
    if (cashDepositsPercentage === CashDepositPercentageOption.GREATER_THAN_20) {
      return cashDepositsOrigin && cashDepositsOrigin.length > 0;
    }
    return cashDepositsPercentage !== undefined;
  }, [cashDepositsOrigin, cashDepositsPercentage]);

  const stripOtherIfLessThan20 = useCallback((data: FormInputs) => {
    const { cashDepositsPercentage: percentage } = data;
    if (percentage === CashDepositPercentageOption.LESS_THAN_20) {
      return { cashDepositsPercentage: percentage };
    }
    return data;
  }, []);

  return (
    <SourceOfWealthStepContainer
      customTitle='Does more than 20% of your income come from cash deposits?'
      onSubmit={handleSubmit((data) =>
        sourceOfWealthService.send({ type: 'NEXT', data: stripOtherIfLessThan20(data) }),
      )}
      submitDisabled={!canContinue()}
    >
      <FlexLayout direction='column' spacing={8}>
        <Body size='small' color='secondary' className='mb-16'>
          For example, do you operate as a sole trader or own a business business that receives most payments in cash?
        </Body>
        <Body size='small' weight='emphasis'>
          Please select one:
        </Body>

        <FlexLayout direction='column' spacing={16}>
          <Controller
            name='cashDepositsPercentage'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                {Object.entries(CashDepositPercentageOption).map(([key, value]) => (
                  <FlexLayout key={key} direction='row' className='flex items-center' spacing={16}>
                    <Checkbox
                      className='h-20 w-20 rounded-[100%] border-2 border-color-border-main'
                      checked={field.value === value}
                      onCheckedChange={() => field.onChange(value)}
                    />
                    <Body size='small'>{value === CashDepositPercentageOption.GREATER_THAN_20 ? 'Yes' : 'No'}</Body>
                  </FlexLayout>
                ))}
              </>
            )}
          />

          {cashDepositsPercentage === CashDepositPercentageOption.GREATER_THAN_20 && (
            <FlexLayout direction='column' spacing={8} className='mt-16'>
              <Body size='small' weight='emphasis'>
                Please describe these cash deposits:
              </Body>
              <Controller
                name='cashDepositsOrigin'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextArea
                    placeholder='e.g. I am a painter and primarily receive payment in cash. Approx $500 of my income per week is cash.'
                    value={field.value}
                    rows={3}
                    onChange={field.onChange}
                  />
                )}
              />
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

export { CashIncomeStep };

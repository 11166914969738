import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Asset } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';

import { QuickBuyPresetAmountValueType } from 'src/context/Avo/generated-avo';

import { QuickBuyType } from '../../types/Trade.types';

const useQuickBuyAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const trackSwitchedTradeDirection = useCallback(
    (attributes: { direction: QuickBuyType; asset: Asset; limitedAsset: Asset }) => {
      const { direction, asset, limitedAsset } = attributes;
      avo.switchedTradeDirection({
        screen: pathname,
        direction,
        assetCode: asset.code,
        assetId: asset.id,
        limitedAssetCode: limitedAsset.code,
        limitedAssetId: limitedAsset.id,
      });
    },
    [avo, pathname],
  );

  const trackSelectedTradeAsset = useCallback(
    (attributes: { asset: Asset; direction: QuickBuyType }) => {
      const { asset, direction } = attributes;
      avo.selectedTradeAsset({
        screen: pathname,
        assetCode: asset.code,
        assetId: asset.id,
        direction,
      });
    },
    [avo, pathname],
  );

  const trackUpdateTradeAmount = useCallback(
    (amount: string) => {
      avo.updatedTradeAmount({
        screen: pathname,
        amount: parseFloat(amount.replace(/,/g, '')),
      });
    },
    [avo, pathname],
  );

  const trackSwitchedLimitAsset = useCallback(
    (attributes: { asset: Asset; direction: QuickBuyType }) => {
      const { asset, direction } = attributes;
      avo.switchedLimitAsset({
        screen: pathname,
        assetCode: asset.code,
        assetId: asset.id,
        direction,
      });
    },
    [avo, pathname],
  );

  const trackPreselectedAmountClicked = useCallback(
    (attributes: {
      amount: QuickBuyPresetAmountValueType;
      asset: Asset;
      limitAsset: Asset;
      direction: QuickBuyType;
    }) => {
      const { amount, asset, limitAsset, direction } = attributes;
      avo.clickedPreSelectedAmount({
        screen: pathname,
        quickBuyPresetAmount: amount,
        assetCode: asset.code,
        assetId: asset.id,
        limitedAssetCode: limitAsset.code,
        limitedAssetId: limitAsset.id,
        direction,
      });
    },
    [avo, pathname],
  );

  const trackUpdatedPaymentOption = useCallback(
    (paymentOption: string) => {
      avo.updatedPaymentOption({
        screen: pathname,
        paymentOption,
      });
    },
    [avo, pathname],
  );

  const trackReviewTradeClicked = useCallback(
    (attributes: { asset: Asset; limitAsset: Asset; amount: string; direction: QuickBuyType }) => {
      const { asset, limitAsset, amount, direction } = attributes;
      avo.clickedReviewTrade({
        screen: pathname,
        assetCode: asset.code,
        assetId: asset.id,
        amount: parseFloat(amount),
        limitedAssetCode: limitAsset.code,
        limitedAssetId: limitAsset.id,
        direction,
      });
    },
    [avo, pathname],
  );

  const trackTradeCompleted = useCallback(() => {
    avo.quickBuyTradeCompleted({
      screen: pathname,
    });
  }, [avo, pathname]);

  const trackTradeExecuted = useCallback(
    (attributes: {
      order: { type: 'buy' | 'sell'; amount: string; asset_code: string; paymentOption: 'card' | 'wallet' };
      asset: Asset;
      limitAsset: Asset;
      userCountryValue: number | undefined;
    }) => {
      const { order, asset, limitAsset, userCountryValue } = attributes;
      avo.quickBuyTradeExecuted({
        screen: pathname,
        direction: order.type,
        amount: parseFloat(order.amount.replace(/,/g, '')),
        assetCode: order.asset_code,
        paymentOption: order.paymentOption,
        assetId: asset.id,
        limitedAssetId: limitAsset.id,
        limitedAssetCode: limitAsset.code,
        userCountryValue,
      });
    },
    [avo, pathname],
  );

  return {
    trackSwitchedTradeDirection,
    trackSelectedTradeAsset,
    trackUpdateTradeAmount,
    trackSwitchedLimitAsset,
    trackPreselectedAmountClicked,
    trackUpdatedPaymentOption,
    trackReviewTradeClicked,
    trackTradeCompleted,
    trackTradeExecuted,
  };
};

export { useQuickBuyAnalytics };

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { Stack } from '@swyftx/react-web-design-system';

import { CodeInput } from '@global-components/Input';

import { AuthContext } from '@Auth/Auth.context';
import { AuthStep } from '@Auth/types';
import { LoginStepFooter, LoginTitle } from '@Login/components';

import { observer } from 'mobx-react-lite';

const TWO_FACTOR_LOGIN_STEP = 2;

const TwoFactorLogin: React.FC = observer(() => {
  const { mfaLogin, error, setAuthStep, submitting, getFederatedAuthType } = useContext(AuthContext);
  const [code, setCode] = useState('');
  const { t } = useTranslation('login');

  const [searchParams] = useSearchParams();
  const federatedAuthType = getFederatedAuthType(searchParams);

  useEffect(() => {
    if (code.length === 6) mfaLogin(federatedAuthType, code);
  }, [code, mfaLogin]);

  const onLogin = useCallback(() => mfaLogin(federatedAuthType, code), [mfaLogin, code]);

  return (
    <Stack flexDirection='column' width='100%' alignItems='center' height='100%' justifyContent='space-between'>
      <LoginTitle
        id='twoFactorLogin.title'
        title={t('twoFactorLogin.title')}
        subTitle={t('twoFactorLogin.subTitle')}
        subTitleDescription={t('twoFactorLogin.subTitleDescription')}
      />
      <Box width='100%' alignItems='center' justifyContent='center' id='twoFactorLogin.form.codeInput.container'>
        <CodeInput
          id='twoFactorLogin.form.codeInput'
          error={error}
          length={6}
          onChange={(newCode) => setCode(newCode.join(''))}
        />
      </Box>

      <LoginStepFooter
        step={TWO_FACTOR_LOGIN_STEP}
        buttonText={t('twoFactorLogin.buttonLabels.verify')}
        onNext={onLogin}
        onBack={() => setAuthStep(AuthStep.SignIn)}
        buttonLoading={submitting}
        disableButton={!code}
      />
    </Stack>
  );
});

TwoFactorLogin.displayName = 'TwoFactorLogin';

export { TwoFactorLogin };

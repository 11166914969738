import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const More: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 13.9983C3.89649 13.9983 3 13.1018 3 12C3 10.8982 3.89649 10 5 10C6.10351 10 7 10.8982 7 12C7 13.1018 6.10351 13.9983 5 13.9983ZM12 13.9983C10.8965 13.9983 10 13.1018 10 12C10 10.8982 10.8965 10 12 10C13.1035 10 14 10.8982 14 12C14 13.1018 13.1035 13.9983 12 13.9983ZM17 12C17 13.1018 17.8965 13.9983 19 13.9983C20.1035 13.9983 21 13.1018 21 12C21 10.8982 20.1035 10 19 10C17.8965 10 17 10.8982 17 12Z'
      fill='currentColor'
    />
  </svg>
);

export { More };

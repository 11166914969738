import React from 'react';
import { useTranslation } from 'react-i18next';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';

type Props = {
  onContinue: () => void;
  onCancel: () => void;
};

export const EnableTwoFactorCancelStep: React.FC<Props> = ({ onContinue, onCancel }) => {
  const { t } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });

  return (
    <Wizard.Step
      onClose={onContinue}
      title={t('enable.cancel.title')}
      onAction={onCancel}
      actionName={t('enable.cancel.buttonLabels.action')}
      cancelName={t('enable.cancel.buttonLabels.cancel')}
      actionColor='error'
    >
      <Stack spacing={2}>
        <Typography fontSize={pxToRem(14)} color='text.secondary'>
          {t('enable.cancel.labels.description')}
        </Typography>
      </Stack>
    </Wizard.Step>
  );
};

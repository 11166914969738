import React, { Dispatch, SetStateAction } from 'react';

import { api } from '@shared/api';
import { ParamKeysEnum } from '@shared/api/lib/endpoints/newsServiceEndpoint';
import { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';

import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  searchFilter: string;
  pathname: string;
  shouldGetNextPage: boolean;
  nextPageCursor: string;
  setLastUpdated: Dispatch<SetStateAction<string>>;
  newsListRef: React.RefObject<HTMLElement>;
  setNextPageCursor: Dispatch<SetStateAction<string>>;
};

const fetchNewsData = async ({
  searchFilter,
  pathname,
  shouldGetNextPage,
  nextPageCursor,
  setLastUpdated,
  newsListRef,
  setNextPageCursor,
}: Props) => {
  const LOG_TAG = 'NEWS_TILE';
  const DEFAULT_SIZE = pathname === NavigationURLs.Dashboard ? 10 : 5;

  const scrollToTopOfNewsList = () => {
    const newsList = newsListRef?.current;

    if (newsList) {
      newsList.scrollTop = 0;
    }
  };

  try {
    const { data } = await api.endpoints.getNews({
      query: {
        [ParamKeysEnum.FILTER_BY_CATEGORY]: searchFilter,
        [ParamKeysEnum.PAGE_SIZE]: DEFAULT_SIZE,
        [ParamKeysEnum.CURSOR]: shouldGetNextPage ? nextPageCursor : '',
      },
    });

    const cursorParam = new URLSearchParams(data.links.next).get(ParamKeysEnum.CURSOR);
    setNextPageCursor(cursorParam ?? '');
    const timeFetched = new Date().toLocaleString([], { hour: 'numeric', minute: 'numeric', hourCycle: 'h12' });
    setLastUpdated(timeFetched);

    if (!shouldGetNextPage) {
      // In the case of refreshes or filter changes, we want to scroll to the top of the article list
      scrollToTopOfNewsList();
    }
    return data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

export default fetchNewsData;

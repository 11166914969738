import { Breakpoint } from '@mui/material';

type AssetSummaryBreakpointSettings = {
  itemWidth: Record<Breakpoint, number>;
  itemSpacing: Record<Breakpoint, number>;
  rightMenuWidth: Record<Breakpoint, number>;
  leftMenuWidth: Record<Breakpoint, number>;
  slideTransition: number;
};

export const ASSET_SUMMARY_BREAKPOINT_SETTINGS: AssetSummaryBreakpointSettings = {
  itemWidth: {
    xl: 175,
    lg: 175,
    md: 175,
    sm: 155,
    xs: 180,
  },
  itemSpacing: {
    xl: 5,
    lg: 5,
    md: 5,
    sm: 5,
    xs: 30,
  },
  rightMenuWidth: {
    xl: 60,
    lg: 184,
    md: 55,
    sm: 55,
    xs: 0,
  },
  leftMenuWidth: {
    xl: 40,
    lg: 40,
    md: 40,
    sm: 40,
    xs: 0,
  },
  slideTransition: 200,
};

export const assetSummaryTimeKeys = ['day', 'week', 'month', 'allTime'] as const;
export const assetSummaryDetailKeys = ['low', 'high', 'volume', 'change'] as const;

export type AssetInfoData = Array<{
  timeKey: (typeof assetSummaryTimeKeys)[number];
  detailKey: (typeof assetSummaryDetailKeys)[number];
  name: any;
}>;

export const assetInfo: AssetInfoData = [
  {
    timeKey: 'day',
    detailKey: 'volume',
    name: 'singleAsset.summary.24volume',
  },
  {
    timeKey: 'day',
    detailKey: 'high',
    name: 'singleAsset.summary.24high',
  },
  {
    timeKey: 'day',
    detailKey: 'low',
    name: 'singleAsset.summary.24low',
  },
  {
    timeKey: 'day',
    detailKey: 'change',
    name: 'singleAsset.summary.24change',
  },
  {
    timeKey: 'week',
    detailKey: 'high',
    name: 'singleAsset.summary.7dayhigh',
  },
  {
    timeKey: 'week',
    detailKey: 'low',
    name: 'singleAsset.summary.7daylow',
  },
  {
    timeKey: 'week',
    detailKey: 'change',
    name: 'singleAsset.summary.7daychange',
  },
  {
    timeKey: 'month',
    detailKey: 'high',
    name: 'singleAsset.summary.30dayhigh',
  },
  {
    timeKey: 'month',
    detailKey: 'low',
    name: 'singleAsset.summary.30daylow',
  },
  {
    timeKey: 'month',
    detailKey: 'change',
    name: 'singleAsset.summary.30daychange',
  },
  // {
  //   timeKey: 'allTime',
  //   detailKey: 'high',
  //   name: 'All-time high',
  // },
  // {
  //   timeKey: 'allTime',
  //   detailKey: 'low',
  //   name: 'All-time low',
  // },
];

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '@mui/material/Collapse';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Stack } from '@swyftx/react-web-design-system';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import entityService from '@shared/services/entityService';
import { UIStore } from '@shared/store';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';
import entityHelpers, {
  GroupedScope,
  MemberPermissionType,
  ScopeGroup,
  SelectableMemberScopeItem,
} from '@shared/utils/lib/entityHelpers';

import { entityPermissionTypeDetails, setEntityPermissionPreset } from '../EntityAddMemberWizard.const';
import { useEntityPermissionScopes } from '../EntityConfigureMemberWizard.hooks';
import { EntityAdvancedConfigurationHeader } from '../components/EntityAdvancedConfigurationHeader';
import { GroupedScopeContainer } from '../components/GroupedScopeContainer';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  entityMember: EntityMember;
};

const ROWS = 2;

export const EntityConfigureMemberPermissionStep: React.FC<Props> = ({ onClose, onSuccess, entityMember }) => {
  const { t } = useTranslation('profile');
  const [showAdvancedConfiguration, setShowAdvancedConfiguration] = useState(false);
  const [groupedScopes, setGroupedScopes] = useState<GroupedScope>({});
  const [saving, setSaving] = useState(false);
  const [selectedPermissionPreset, setSelectedPermissionPreset] = useState<MemberPermissionType | null>(
    entityHelpers.getKeyTypeFromKey(entityMember.scope),
  );

  const { error: scopesError, loading: scopesLoading } = useEntityPermissionScopes(entityMember, setGroupedScopes);

  const { addMessageBox } = UIStore.useUIStore;

  // Chunk the scopes so we can display them in flexbox view with no whitespace
  const chunkedScopes = useMemo(() => {
    if (groupedScopes) {
      const scopeArray = Object.values(groupedScopes);
      const res = [];
      for (let i = 0; i < scopeArray.length; i += ROWS) {
        const chunk = scopeArray.slice(i, i + ROWS);
        res.push(chunk);
      }
      return res;
    }
    return [];
  }, [groupedScopes]);

  const onSelect = (item: SelectableMemberScopeItem, group: ScopeGroup, isParent: boolean) => {
    if (item.locked) return;

    const localScopes = { ...groupedScopes };

    // set items selection
    item.selected = !item.selected;

    if (isParent) {
      // if parent set all children
      group.children.forEach((child) => {
        child.selected = item.selected;
      });
    }

    // reset all scopes lock status
    entityHelpers.resetAllScopes(localScopes);
    // set all required read scopes
    entityHelpers.checkReadScopes(localScopes);
    // check dependants
    entityHelpers.checkDependants(localScopes);
    // check if is a preset
    setSelectedPermissionPreset(entityHelpers.getKeyType(localScopes));
    // update state
    setGroupedScopes(localScopes);
  };

  const handlePermissionLevelChange = (newPermission: MemberPermissionType) => {
    if (groupedScopes) {
      setSelectedPermissionPreset(newPermission);
      setEntityPermissionPreset(groupedScopes, newPermission, setGroupedScopes);
    }
  };

  const handleSavePermissions = useCallback(async () => {
    setSaving(true);

    try {
      if (!entityMember.parentUuid) {
        throw new SwyftxError('Entities', 'Parent UUID is missing');
      }

      if (!entityMember.uuid) {
        throw new SwyftxError('Entities', 'Member UUID is missing');
      }

      // generate scopes
      const permissions = entityHelpers.getSelectedPermission(groupedScopes);

      await api.endpoints.updateEntityMember({
        params: {
          entityUuid: entityMember.parentUuid,
          memberUuid: entityMember.uuid,
        },
        data: {
          scope: permissions.join(' '),
        },
      });

      await entityService.updateMembers();

      addMessageBox({
        content: (
          <SuccessMessageBox
            title={t('entities.configureMemberModal.permissions.permissionSuccess.title')}
            content={t('entities.configureMemberModal.permissions.permissionSuccess.content', {
              name: entityMember.name,
            })}
          />
        ),
      });
      onSuccess();
    } catch (err) {
      addMessageBox({
        content: (
          <ErrorMessageBox
            title={t('entities.configureMemberModal.permissions.permissionError.title')}
            content={
              // (err as SwyftxError)?.errorMessage ||
              t('entities.configureMemberModal.permissions.permissionError.defaultContent', { name: entityMember.name })
            }
          />
        ),
      });
    }

    setSaving(false);
  }, [addMessageBox, entityMember.name, entityMember.parentUuid, entityMember.uuid, groupedScopes, onSuccess, t]);

  return (
    <FlexLayout direction='column' spacing={16}>
      <Body size='small' color='secondary'>
        {t('entities.configureMemberModal.permissions.description', { name: entityMember.name })}
      </Body>
      <FlexLayout direction='row' spacing={8} className='w-full flex-wrap'>
        {!scopesLoading &&
          entityPermissionTypeDetails.map((permissionDetails) => (
            <FlexLayout
              direction='row'
              className='!h-full w-[calc(50%-8px)] items-center'
              key={permissionDetails.title}
            >
              <Card
                className='hover:bg-color-background-accent-subtle h-full w-full cursor-pointer p-16'
                onClick={() => handlePermissionLevelChange(permissionDetails.type)}
              >
                <FlexLayout direction='row' spacing={16} className='h-full w-full items-center justify-between'>
                  {permissionDetails.icon}
                  <FlexLayout direction='column'>
                    <Body>{t(permissionDetails.title)}</Body>
                    <Body color='secondary' size='small'>
                      {t(permissionDetails.description)}
                    </Body>
                  </FlexLayout>
                  <Checkbox checked={selectedPermissionPreset === permissionDetails.type} />
                </FlexLayout>
              </Card>
            </FlexLayout>
          ))}
      </FlexLayout>
      <FlexLayout direction='column' spacing={16}>
        <EntityAdvancedConfigurationHeader
          disableButtons={scopesLoading}
          setSelectedPermissionPreset={setSelectedPermissionPreset}
          setShowAdvancedConfiguration={setShowAdvancedConfiguration}
          showAdvancedConfiguration={showAdvancedConfiguration}
          setGroupedScopes={setGroupedScopes}
          groupedScopes={groupedScopes}
        />

        {scopesError && (
          <Notification severity='destructive' title='Permissions error'>
            <Body>{scopesError}</Body>
          </Notification>
        )}
        <Collapse in={showAdvancedConfiguration}>
          <Stack direction='row' spacing={2}>
            {chunkedScopes.map((chunkedScope) => (
              <Stack spacing={2} key={chunkedScope[0].parent.key} width='100%'>
                {chunkedScope.map((groupedScope) => (
                  <GroupedScopeContainer
                    groupedScope={groupedScope}
                    key={groupedScope.parent.key}
                    onSelect={onSelect}
                  />
                ))}
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </FlexLayout>
      <FlexLayout direction='row' className='w-full justify-end' spacing={8}>
        <Button onClick={handleSavePermissions} loading={saving}>
          Update permissions
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

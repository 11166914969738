import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Oracle: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M14.0742 10.6992C14.0742 10.285 13.7384 9.94922 13.3242 9.94922C12.91 9.94922 12.5742 10.285 12.5742 10.6992V16.5973C12.5742 16.901 12.7574 17.1748 13.0381 17.2906C13.3189 17.4065 13.6418 17.3416 13.856 17.1262L15.6216 15.351C15.9137 15.0573 15.9124 14.5825 15.6187 14.2904C15.325 13.9983 14.8502 13.9995 14.5581 14.2932L14.0742 14.7797V10.6992Z'
      fill='currentColor'
    />
    <path
      d='M11.1601 10.703C11.1601 10.3993 10.9769 10.1256 10.6961 10.0098C10.4154 9.89401 10.0925 9.95894 9.87832 10.1743L8.11275 11.9496C7.82066 12.2433 7.82196 12.7181 8.11566 13.0102C8.40936 13.3023 8.88423 13.301 9.17632 13.0073L9.66038 12.5206L9.661 16.6005C9.66106 17.0147 9.9969 17.3504 10.4111 17.3503C10.8253 17.3503 11.1611 17.0144 11.161 16.6002L11.1601 10.703Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.13533 2.25004C5.68722 2.24042 3.68258 4.1923 3.6242 6.63877L3.62398 6.65667V17.1554C3.57384 19.6388 5.5434 21.694 8.02771 21.7488L8.04415 21.749L15.9696 21.75C15.9798 21.75 15.99 21.7498 16.0002 21.7494C18.4571 21.6492 20.3924 19.6205 20.3764 17.1608V8.99936C20.3764 8.27678 20.0975 7.58129 19.5957 7.05957L15.8054 3.11064C15.2769 2.55953 14.5468 2.25004 13.7857 2.25004H8.13533ZM5.12398 6.6661V17.1633L5.1238 17.1798C5.08725 18.8362 6.39764 20.2083 8.05297 20.249L15.9536 20.25C17.5964 20.1754 18.8881 18.816 18.8764 17.1686L18.8764 17.1633L18.8764 9.26583L16.8219 9.26583C14.9959 9.26205 13.5186 7.7798 13.5195 5.95511V3.75004H8.13057C6.50225 3.74304 5.16731 5.03904 5.12398 6.6661ZM15.0195 4.45802V5.95511C15.0189 6.9525 15.8264 7.76342 16.8243 7.76583H18.1944L15.0195 4.45802Z'
      fill='currentColor'
    />
  </svg>
);

export { Oracle };

import { APIKey, CreateAPIKeyResponse } from '@shared/api';
import { GroupedScope } from '@shared/api/lib/helpers/apiKeys.helper';

import { CreateApiKeyPayload, defaultApiKeyPayload } from '@routes/Profile/subroutes/ApiKeys/CreateApiKey/types';

export type ApiKeysStateType = {
  apiKeys: APIKey[];
  showCreateNewApiKey: boolean;
  newApiKeyPayload: CreateApiKeyPayload;
  showAdvancedConfig: boolean;
  createdApiKeyResponse: CreateAPIKeyResponse | undefined;
};

export type ApiKeysActionType = {
  createNewApiKey: () => void;
  revokeAllKeys: () => void;
  revokeKey: (apiKey: APIKey) => void;
  setApiKeys: (keys: APIKey[]) => void;
  setShowCreateNewApiKey: (create: boolean) => void;
  setNewApiKeyPayload: (data: Partial<CreateApiKeyPayload>) => void;
  resetNewApiKeyPayload: () => void;
  fetchApiScopes: () => void;
  setNewApiKeyPayloadScopes: (scopes?: GroupedScope) => void;
  setShowAdvancedConfig: (show: boolean) => void;
  toggleAllApiScopes: (toggle: boolean) => void;
  setCreatedApiKeyResponse: (response?: CreateAPIKeyResponse) => void;
};

export const ApiKeysInitialValues: ApiKeysStoreSchema = {
  apiKeys: [],
  newApiKeyPayload: { ...defaultApiKeyPayload },
  showCreateNewApiKey: false,
  showAdvancedConfig: false,
  createdApiKeyResponse: undefined,
  createNewApiKey: () => {},
  revokeAllKeys: () => {},
  revokeKey: () => {},
  setApiKeys: () => {},
  setShowCreateNewApiKey: () => {},
  setNewApiKeyPayload: () => {},
  resetNewApiKeyPayload: () => {},
  fetchApiScopes: () => {},
  setNewApiKeyPayloadScopes: () => {},
  setShowAdvancedConfig: () => {},
  toggleAllApiScopes: () => {},
  setCreatedApiKeyResponse: () => {},
};

export interface ApiKeysStoreSchema extends ApiKeysStateType, ApiKeysActionType {}

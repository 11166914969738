import { SwyftxError } from './SwyftxError';

export * from './SwyftxError';
export * from './RateLimitError';
export * from './lib/ReCaptchaError';

/** Handle a JavaScript error */
export default function handleError(
  errorTag: string,
  message: string,
  error: any,
  options?: { logToConsole: boolean },
) {
  const swyftxError = new SwyftxError(errorTag, message, error);

  // detect flags
  const logToConsole = options && options.logToConsole !== false; // defaults to true

  return swyftxError.log(logToConsole);
}

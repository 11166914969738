import { Box, CircularProgress } from '@mui/material';

import { Big } from '@shared/safe-big';

import { useTheme } from '@hooks/useTheme';

import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';

import { StripeCardForm } from './StripeCardForm';

interface Props {
  clientSecret: string;
  stripePromise?: Promise<Stripe | null>;
  onSuccess: () => void;
  onFailure?: () => void;
  setOnSubmit: (onSubmit: () => void) => void;
  setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
  amount: Big;
  feeAmount: Big;
  feePercentage: Big;
}

export const StripeCardDepositWrapper = ({
  clientSecret,
  stripePromise,
  onSuccess,
  onFailure,
  setOnSubmit,
  setIsSubmitDisabled,
  amount,
  feeAmount,
  feePercentage,
}: Props) => {
  const { theme } = useTheme();

  const options: StripeElementsOptions = {
    fonts: [
      {
        cssSrc:
          // eslint-disable-next-line max-len
          'https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap',
      },
    ],
    clientSecret,
    appearance: {
      // Disabling Stripe's native animations since we're no longer relying on Stripe's loader.
      disableAnimations: true,
      theme: theme.palette.mode === 'light' ? 'none' : 'night',
      variables: {
        fontFamily: 'Public Sans',
        borderRadius: '4px',
        spacingUnit: '5',
        spacingGridColumn: '32px',
        colorBackground: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[700],
      },
      rules: {
        '.Label': {
          color: theme.palette.text.primary,
          fontWeight: '600',
          fontSize: '14px',
          marginBottom: '8px',
        },
        '.Input': {
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]}`,
        },
        '.Input:hover': {
          outline: `1px solid ${theme.palette.text.primary}`,
        },
        '.Input:focus': {
          border: `1px solid ${theme.palette.primary.main}`,
          outline: `1px solid ${theme.palette.primary.main}`,
        },
        '.Input::placeholder': {
          // rgba color - the 80 converts to 0.5 opacity since Stripe doesn't let us set the opacity CSS property
          color: `${theme.palette.text.primary}80`,
        },
        '.Input--invalid': {
          outline: `1px solid ${theme.palette.error.main}`,
        },
        '.Error': {
          fontSize: '12px',
        },
      },
    },
  };

  return clientSecret && stripePromise ? (
    <Elements options={options} stripe={stripePromise}>
      <StripeCardForm
        setOnSubmit={setOnSubmit}
        setIsSubmitDisabled={setIsSubmitDisabled}
        onSuccess={onSuccess}
        onFailure={onFailure}
        amount={Big(amount)}
        feeAmount={feeAmount}
        feePercentage={feePercentage}
      />
    </Elements>
  ) : (
    <Box width='100%' height='380px' display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Box>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

type Props = {
  onActionOverride?: () => void;
};

const VerificationPendingBanner: React.FC<Props> = observer(() => {
  const { t } = useTranslation('common');

  return (
    <FlexLayout className='w-full'>
      <Card className='w-full p-24'>
        <FlexLayout alignItems='center' justifyContent='space-between' spacing={16}>
          <Image image='id_reviewing' usePalette className='h-64 w-64' alt='pending verification' />
          <FlexLayout direction='column' className='w-full' spacing={4}>
            <Heading size='h6'>{t('pendingVerificationBanner.title')}</Heading>
            <Body color='secondary' size='small'>
              {t('pendingVerificationBanner.subTitle')}
            </Body>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
});

VerificationPendingBanner.displayName = 'VerificationPendingBanner';

export { VerificationPendingBanner };

import { DateTime } from 'luxon';

export const getCurrentDateTimeString = () => {
  const currentTime = DateTime.now();
  return currentTime.toFormat('yyyy-MM-dd HH:mm:ss.SSS');
};

export const TODAY = () => {
  const localDate = DateTime.local();
  return localDate.toFormat('yyyy-MM-dd');
};

export const fromLocalToUTCMidnight = (localDate: DateTime): number => {
  const utcStartOfDay = localDate?.toUTC().startOf('day');
  return utcStartOfDay.set({ day: localDate.day, month: localDate.month, year: localDate.year }).toMillis();
};

export const fromLocalToUTCMidnightOrMinInFuture = (localDate: DateTime, minute = 1): number => {
  const utcStartOfDay = fromLocalToUTCMidnight(localDate);

  if (localDate.hasSame(DateTime.now(), 'day')) {
    return DateTime.now().plus({ minute }).toUTC().toMillis();
  }

  return utcStartOfDay;
};

import React from 'react';

import { Stack } from '@mui/material';

import { Loading } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { AssetType } from '@shared/api';

import { useUniversalTradeAsset } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

type Props = {
  tradeDataKey: string;
};
const TradeInputNonLimitRate: React.FC<Props> = observer(({ tradeDataKey }) => {
  const { getNonLimitValue, nonLimitAsset, loading } = useUniversalTradeAsset(tradeDataKey);

  if (nonLimitAsset === undefined) return null;

  return (
    <>
      {loading && (
        <Stack height={24} justifyContent='center' color='text.secondary'>
          <Loading size={16} color='inherit' />
        </Stack>
      )}
      {!loading && (
        <FormattedText
          typographyProps={{
            className: 'font-mono',
            color: 'secondary',
            weight: 'emphasis',
          }}
          secondaryText={{
            typographyProps: {
              className: 'font-mono',
              color: 'secondary',
              weight: 'emphasis',
            },
            value: nonLimitAsset.assetType === AssetType.Crypto ? nonLimitAsset.code : '',
          }}
          prefix='~'
          value={getNonLimitValue() || '0'}
          currency={nonLimitAsset}
        />
      )}
    </>
  );
});

TradeInputNonLimitRate.displayName = 'TradeInputNonLimitRate';

export { TradeInputNonLimitRate };

import React, { useContext, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset, AssetType } from '@shared/api';
import { WithdrawalAddress } from '@shared/services';
import { formatDateTime } from '@shared/utils';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

interface Props {
  address: WithdrawalAddress;
  onClose: () => void;
}

const accountDetails = (address: WithdrawalAddress, asset?: Asset) => {
  if (asset?.assetType === AssetType.Fiat) {
    const code = asset?.code || '';

    if (code === 'AUD') {
      return [
        { label: 'Account Name', value: address.address_details.nameOnAccount },
        { label: 'Account Number', value: address.address_details.address },
        { label: 'BSB', value: address.address_details.bsb },
      ];
    }
    if (code === 'NZD') {
      return [
        { label: 'Account Name', value: address.address_details.nameOnAccount },
        { label: 'Account Number', value: address.address_details.address },
      ];
    }
  }
  return [
    {
      label: 'Label',
      value: address.label,
    },
    {
      label: 'Address',
      value: address.address_details.address,
    },
    {
      label: address.metadata?.name,
      value: address.metadata?.data,
    },
    {
      label: 'Network',
      value: address.address_details.network,
    },
    {
      label: 'Date added',
      value: formatDateTime(new Date(address.created)),
    },
    {
      label: 'Status',
      value: address.verified ? 'Verified' : 'Unverified',
    },
  ];
};

export const ViewAddressDetailsStep: React.FC<Props> = ({ address, onClose }) => {
  const { getAssetById } = useMarkets();
  const { onNextStep } = useContext(StepModalContext);

  const asset = useMemo(() => getAssetById(address.code), [address.code, getAssetById]);

  return (
    <StepModal.Step
      key={TransferModalStep.ViewAddressDetails}
      hideActions
      onClose={onClose}
      title={`Address details for ${address?.label}`}
    >
      <FlexLayout direction='column' spacing={24} className='p-24'>
        {accountDetails(address, asset).map(
          ({ label, value }) =>
            label && (
              <FlexLayout
                className='fs-mask'
                direction='row'
                justifyContent='space-between'
                spacing={16}
                key={`address-detail-line-${label}-${value}`}
              >
                <Body weight='emphasis'>{label}</Body>
                <Body color='secondary' className='break-all text-right'>
                  {value}
                </Body>
              </FlexLayout>
            ),
        )}
        <FlexLayout direction='row' alignItems='center' justifyContent='end'>
          <Button
            variant='filled'
            color='destructive'
            onClick={() => {
              onNextStep(TransferModalStep.RemoveAddress);
            }}
          >
            {asset?.assetType === AssetType.Fiat ? 'Remove Account' : 'Remove Address'}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </StepModal.Step>
  );
};

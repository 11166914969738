import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

const TransactionHistoryLoadingStateRow = (
  <FlexLayout direction='row' spacing={16} className='w-full'>
    <div className='h-[40px] w-full animate-pulse bg-color-background-surface-secondary' />
  </FlexLayout>
);

export const TransactionHistoryLoadingState = (
  <FlexLayout direction='column' spacing={8} className='w-full p-16'>
    {TransactionHistoryLoadingStateRow}
    {TransactionHistoryLoadingStateRow}
    {TransactionHistoryLoadingStateRow}
    {TransactionHistoryLoadingStateRow}
    {TransactionHistoryLoadingStateRow}
    {TransactionHistoryLoadingStateRow}
  </FlexLayout>
);

import React, { useCallback, useState, useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';
import { EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import * as Popover from '@radix-ui/react-popover';
import { useDebounce } from 'react-use';
import { useMarketActivity } from 'src/lib/markets/hooks/useMarketActivity';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { AssetPickerTableData, AssetPickerTabTypes } from './AssetPicker.types';
import { AssetPickerContent } from './AssetPickerContent';
import { useAssetPickerList } from './hooks/useAssetPickerList';

interface Props {
  asset: Asset;
  className?: string;
  cardClassName?: string;
  baseNavigationUrl?: NavigationURLs;
}

export const AssetPicker: React.FC<Props> = (props) => {
  const { asset: selectedAsset, className, cardClassName, baseNavigationUrl } = props;
  const [open, setOpen] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { openedAssetListDropdown, changedAssetViewed } = useTradePageAnalytics();
  const [sort, setSort] = useState<EnhancedTableSort<AssetPickerTableData>>({
    sortKey: 'asset',
    sortDirection: 'DESC',
  });
  const [selectedTab, setSelectedTab] = useState<AssetPickerTabTypes>('all');
  const { searchedInAssetDropdown } = useTradePageAnalytics();

  const { assets } = useMarkets();
  const favouriteAssets = useFavouriteAssets();
  const { gainers, losers, newListings, topAssets, ownedAssets } = useMarketActivity({
    assets,
  });
  const { isSwyftxPro } = useSwyftxPro();

  const handleOpenChange = useCallback(
    (o: boolean) => {
      setOpen(o);
      if (o) {
        openedAssetListDropdown(selectedAsset);
      }
    },
    [openedAssetListDropdown, selectedAsset],
  );

  const handleSelectAsset = useCallback(
    (newSelectedAsset: Asset) => {
      setOpen(false);
      changedAssetViewed({ asset: newSelectedAsset });
    },
    [changedAssetViewed],
  );

  const [search, setSearch] = useState<string>('');

  useDebounce(
    () => {
      if (search === '') return;
      return searchedInAssetDropdown();
    },
    300,
    [search],
  );

  const tableAssets = useMemo(() => {
    switch (selectedTab) {
      case 'all':
        return topAssets;
      case 'favourites':
        return favouriteAssets;
      case 'gainers':
        return gainers;
      case 'losers':
        return losers;
      case 'new':
        return newListings;
      case 'owned':
        return ownedAssets;
      default:
        selectedTab satisfies never;
        return [];
    }
  }, [favouriteAssets, gainers, losers, newListings, ownedAssets, selectedTab, topAssets]);

  const { onSort, assetsToShow } = useAssetPickerList({
    assets: tableAssets,
    search,
    setSort,
    sort,
  });

  return (
    <Popover.Root open={open} onOpenChange={(o) => !o && handleOpenChange(o)} modal>
      <Popover.Trigger asChild>
        <FlexLayout direction='row' className={cn('w-full', className)}>
          <Card
            className={cn(
              open && isSwyftxPro ? 'bg-color-background-surface-selected' : '',
              'w-full cursor-pointer hover:bg-color-background-surface-hover',
              isSwyftxPro ? 'rounded-[1rem]' : 'rounded-[20rem]',
              cardClassName,
            )}
            onClick={() => handleOpenChange(true)}
            data-spotlightelementId='asset-picker'
            border={!isSwyftxPro}
          >
            <FlexLayout
              direction='row'
              className={cn('items-center justify-between', isSwyftxPro ? 'px-8 py-4' : 'px-12 py-8')}
            >
              <FlexLayout direction='row' spacing={12} className=' items-center'>
                <AssetIcon asset={selectedAsset?.code} size={24} />
                <FlexLayout direction='column' spacing={0} className=' py-4 text-start'>
                  <Body size='large' weight='emphasis' className='!line-height-20'>
                    {selectedAsset?.code}
                  </Body>
                  {!isXs && (
                    <Body color='secondary' className='!line-height-16' size='small'>
                      {selectedAsset?.name}
                    </Body>
                  )}
                </FlexLayout>
              </FlexLayout>
              <div className='px-8'>
                <ArrowChevronDown className='h-20 w-20 sm:h-24 sm:w-24' />
              </div>
            </FlexLayout>
          </Card>
        </FlexLayout>
      </Popover.Trigger>
      <Popover.Portal>
        <>
          {!isXs && (
            <Popover.Content align='start' className='z-modal'>
              <Card className='mt-4 w-[47rem] p-12 shadow-lg'>
                <AssetPickerContent
                  onSelectAsset={handleSelectAsset}
                  selectedAsset={selectedAsset}
                  baseNavigationUrl={baseNavigationUrl}
                  onClose={() => setOpen(false)}
                  sort={sort}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  search={search}
                  setSearch={setSearch}
                  assetsToShow={assetsToShow}
                  onSort={onSort}
                />
              </Card>
            </Popover.Content>
          )}

          {isXs && (
            <Popover.Content className='!z-modal-popover' asChild>
              <Modal
                title='Select Asset'
                position='bottom'
                open={open}
                onClose={() => handleOpenChange(false)}
                onOpenChange={(e) => handleOpenChange(e?.valueOf() ?? false)}
                className='overflow-x-hidden'
              >
                <FlexLayout className='h-full w-full'>
                  <AssetPickerContent
                    onSelectAsset={handleSelectAsset}
                    baseNavigationUrl={baseNavigationUrl}
                    onClose={() => setOpen(false)}
                    sort={sort}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    search={search}
                    setSearch={setSearch}
                    assetsToShow={assetsToShow}
                    onSort={onSort}
                  />
                </FlexLayout>
              </Modal>
            </Popover.Content>
          )}
        </>
      </Popover.Portal>
    </Popover.Root>
  );
};

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown, ArrowChevronUp, Link } from '@swyftx/aviary/icons/outlined';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { formatCurrency } from '@shared/utils';

import { useToggle } from '@react-hookz/web';

import { useLinkedOrderDetails } from './useLinkedOrderDetails';

type Props = {
  orderUuid: string;
};

const OrderDetailsLinkedOrdersItem: React.FC<Props> = ({ orderUuid }) => {
  const [opened, setOpened] = useToggle(false);
  const { isValidLinkedOrder, typeName, triggerPrice, amount, total, primaryAsset, secondaryAsset } =
    useLinkedOrderDetails({ orderUuid });
  const { openModal } = useModal();

  const openLinkedOrder = useCallback(() => {
    openModal(Modals.OrderDetails, { orderId: orderUuid });
  }, [openModal, orderUuid]);

  if (isValidLinkedOrder) return null;

  return (
    <FlexLayout
      direction='column'
      alignItems='center'
      justifyContent='space-between'
      className='rounded-8 border border-color-border-main p-12'
      spacing={12}
    >
      <FlexLayout alignItems='center' className='w-full' justifyContent='space-between'>
        <FlexLayout spacing={12} alignItems='center'>
          <div className='rounded-6 bg-color-background-primary-subtle'>
            <div className='rounded-6 bg-color-icon-accent p-2'>
              <Link className='h-16 w-16 text-black' />
            </div>
          </div>
          <Body>{typeName}</Body>
        </FlexLayout>
        <FlexLayout spacing={12} alignItems='center'>
          <Button variant='outlined' size='sm' onClick={openLinkedOrder}>
            View
          </Button>
          <Button
            variant='ghost'
            layout='icon'
            leadingIcon={opened ? <ArrowChevronUp /> : <ArrowChevronDown />}
            size='sm'
            onClick={setOpened}
          />
        </FlexLayout>
      </FlexLayout>
      {opened && (
        <FlexLayout direction='column' className='w-full' spacing={8}>
          <Separator />
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Body color='primary' size='small'>
              Trigger price
            </Body>
            <Body color='secondary' size='small'>
              {formatCurrency(triggerPrice, primaryAsset, { hideCode: false, appendCode: true })}
            </Body>
          </FlexLayout>
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Body color='primary' size='small'>
              Amount
            </Body>
            <Body color='secondary' size='small'>
              {formatCurrency(amount, secondaryAsset, { hideCode: false, appendCode: true })}
            </Body>
          </FlexLayout>
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Body color='primary' size='small'>
              Total
            </Body>
            <Body color='secondary' size='small'>
              {formatCurrency(total, primaryAsset, { hideCode: false, appendCode: true })}
            </Body>
          </FlexLayout>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export { OrderDetailsLinkedOrdersItem };

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';

import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Chip, Skeleton } from '@swyftx/react-web-design-system';

import { PercentageIncreaseProps } from '@global-components/PercentageIncrease/PercentageIncrease.types';

import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

export const PercentageIncrease: React.FC<PercentageIncreaseProps> = observer(
  ({ typographyProps, defaultColor, chipProps, amount, suffix, chip }) => {
    const { loading } = RatesStore.useRatesStore;
    const displayAmount = `${Big(amount).toNumber().toLocaleString([], { maximumFractionDigits: 2 })}%`;

    const formattedAmount = (() => {
      if (Big(amount).gt(0)) {
        return `+${displayAmount}`;
      }
      return displayAmount;
    })();

    const color = ((): NumericProps['color'] => {
      if (Big(amount).gt(0)) {
        return 'success';
      }
      if (Big(amount).lt(0)) {
        return 'error';
      }

      return defaultColor || 'primary';
    })();

    if (loading) {
      return <Skeleton variant='text' width={90} height={20} />;
    }

    const chipColor =
      chip &&
      (() => {
        if (Big(amount).gt(0)) {
          return 'success';
        }
        if (Big(amount).lt(0)) {
          return 'error';
        }

        return 'default';
      })();

    if (chip && chipColor) {
      return <Chip color={chipColor} label={`${formattedAmount}${suffix ? ` ${suffix}` : ''}`} {...chipProps} />;
    }

    return (
      <Numeric color={color} {...typographyProps}>
        {formattedAmount}
        {suffix}
      </Numeric>
    );
  },
);

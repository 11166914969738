import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';

import { Currency } from '@shared/api';
import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { formatCurrency, FormatCurrencyOptions } from '@shared/utils';

import { useInterval } from 'react-use';

import { isCurrencyAsset } from './PriceTicker.types';

type Props = {
  price: string;
  formatOptions?: FormatCurrencyOptions;
  typographyProps?: NumericProps;
  disableNextAnimation?: boolean;
  currency?: Asset | Currency;
  duration?: number;
  prefix?: string;
  suffix?: string;
  ticks?: number;
  format?: boolean;
  isApplicable?: boolean;
  formatBalance?: boolean;
};

const DEFAULT_INCREMENT = 0.1; // mainly for type safety, probably won't be used
const DEFAULT_DURATION = 400; // milliseconds
const DEFAULT_TICKS = 10;

const getColor = (
  theme: Theme,
  isChanging: boolean,
  increment: number,
  options?: { forceColor?: NumericProps['color']; disabled?: boolean },
): NumericProps['color'] => {
  if (isChanging && !options?.disabled) {
    if (increment > 0) {
      return 'success';
    }
    return 'error';
  }
  return options?.forceColor || 'primary';
};

export const PriceTicker: React.FC<Props> = ({
  duration = DEFAULT_DURATION,
  ticks = DEFAULT_TICKS,
  disableNextAnimation,
  isApplicable = true,
  typographyProps,
  formatOptions,
  currency,
  prefix,
  suffix,
  format = true,
  price,
  formatBalance = false,
}) => {
  const [newPrice, setNewPrice] = useState(price);
  const [lastPrice, setLastPrice] = useState(price);
  const tickInterval = useMemo(() => duration / ticks, [duration, ticks]);
  const [currentPrice, setCurrentPrice] = useState(price);
  const [isChanging, setIsChanging] = useState(false);
  const [increment, setIncrement] = useState(DEFAULT_INCREMENT);

  const disabled = useRef(disableNextAnimation);
  const theme = useTheme();

  useEffect(() => {
    if (disabled.current) {
      setCurrentPrice(price);
      disabled.current = false;
    } else {
      // Set the destination and find the tick increment to reach it in the given duration
      setNewPrice(price);
      const priceDifference = Big(price).minus(lastPrice);
      if (priceDifference.gt(0.01) || priceDifference.lt(-0.01)) {
        setCurrentPrice(lastPrice);
        const newIncrement = priceDifference.div(ticks);
        setIncrement(newIncrement.toNumber());
        setIsChanging(true);
        setLastPrice(price);
      }
    }
  }, [lastPrice, price, ticks]);

  // If the currency changes, set the current price to the price
  useEffect(() => {
    setCurrentPrice(price);
  }, [currency, price]);

  // Using a ref to not interrupt the lifecycle determined by price updates.
  // Basically so we can just cancel the next animation without another render
  useEffect(() => {
    if (disableNextAnimation) {
      disabled.current = true;
    }
  }, [disableNextAnimation]);

  // Increment the current price
  useInterval(
    () => {
      if (!disabled.current && isChanging) {
        const nextPrice = Big(currentPrice).plus(increment);
        const isIncreasing = increment > 0;
        if (isIncreasing ? nextPrice.gt(newPrice) : nextPrice.lt(newPrice)) {
          setIsChanging(false);
          setCurrentPrice(newPrice);
        } else {
          setCurrentPrice(nextPrice.toString());
        }
      }
    },
    isChanging && increment ? tickInterval : null,
  );

  const textColor = getColor(theme, isChanging, increment, {
    forceColor: typographyProps?.color,
    disabled: disabled.current,
  });
  const isAsset = isCurrencyAsset(currency);
  const formattedCurrency = format
    ? formatCurrency(currentPrice, isAsset ? currency : undefined, formatOptions, currency?.id, formatBalance)
    : currentPrice;

  return (
    <Numeric {...typographyProps} color={textColor} style={{ transitionDuration: `${duration / 3}ms` }}>
      {isApplicable ? (
        <>
          {prefix} {formattedCurrency} {suffix}
        </>
      ) : (
        'N/A'
      )}
    </Numeric>
  );
};

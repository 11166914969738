import { useContext, useMemo } from 'react';

import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { SwyftxTradeDirection } from 'src/lib/trade/types/Trade.types';

import { SwyftxProTradeContext } from '../context';

const useSwyftxProTradeDirection = () => {
  const { tradeDirection, tradeType, setTradeDirection, chartLoading } = useContext(SwyftxProTradeContext);

  const tabs = useMemo(
    (): EnhancedTab<SwyftxTradeDirection>[] => [
      {
        title: 'Buy',
        value: 'buy',
        color: 'success',
        disabled: tradeType === 'oco' || chartLoading,
        tooltip:
          tradeType === 'oco' ? 'Setting an OCO order in the buy direction is currently not supported ' : undefined,
      },
      {
        title: 'Sell',
        value: 'sell',
        color: 'error',
        disabled: chartLoading,
      },
    ],
    [chartLoading, tradeType],
  );

  const isBuy = useMemo(() => tradeDirection === 'buy', [tradeDirection]);
  const isSell = useMemo(() => tradeDirection === 'sell', [tradeDirection]);

  return {
    tabs,
    tradeDirection,
    isBuy,
    isSell,
    setTradeDirection,
  };
};

export { useSwyftxProTradeDirection };

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Clock, Heart } from '@swyftx/aviary/icons/outlined';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import env from '@shared/config';
import { UIStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { TransactionsTableCard } from './components/TransactionsTableCard/TransactionsTableCard';
import { TransactionHistoryTab } from './types/Transactions.types';

type OrderParams = {
  type: TransactionHistoryTab;
};

const Transactions: React.FC = observer(() => {
  const { navigate } = useNavigateRoute();
  const { setCustomHeaderOptions, setCustomBreadcrumb } = UIStore.useUIStore;
  const usersnapApi = useUsersnapApi();
  const feedbackID = env.USERSNAP_PROJECT_ID_TRANSACTIONS;

  // Users should be redirected to the orders page anyway, but default it here for type safety
  const type = useParams<OrderParams>()?.type ?? 'orders';

  const onChangeRoute = (newRoute: TransactionHistoryTab) => {
    navigate(`${NavigationURLs.TransactionsRoot}${newRoute}` as NavigationURLs);
  };

  useEffect(() => {
    setCustomBreadcrumb('transactions', <Body color='primary'>Transactions</Body>);
    setCustomBreadcrumb('orders', <></>);
    setCustomBreadcrumb('deposits', <></>);
    setCustomBreadcrumb('withdrawals', <></>);

    setCustomHeaderOptions(
      <Button
        variant='outlined'
        size='sm'
        leadingIcon={<Heart />}
        onClick={() => usersnapApi?.show(feedbackID).then((widgetApi) => widgetApi.open({}))}
      >
        Provide feedback
      </Button>,
    );

    return () => {
      setCustomHeaderOptions(undefined);
      setCustomBreadcrumb('transactions', undefined);
      setCustomBreadcrumb('orders', undefined);
      setCustomBreadcrumb('deposits', undefined);
      setCustomBreadcrumb('withdrawals', undefined);
    };
  }, [feedbackID, setCustomBreadcrumb, setCustomHeaderOptions, usersnapApi]);

  return (
    <Page
      offset
      className='@container'
      showCompactHeader={false}
      customHeader={
        <FlexLayout
          direction='row'
          justifyContent='space-between'
          className='items-center @xs:px-8 @xs:pb-16 @sm:items-start @sm:p-0'
          spacing={16}
        >
          <FlexLayout spacing={16} alignItems='center'>
            <FlexLayout
              alignItems='center'
              justifyContent='center'
              className='bg-color-background-accent-subtle hidden h-48 w-48 rounded-8 p-4 sm:flex'
            >
              <Icon icon={<Clock className='h-36 w-36 text-color-text-accent' />} color='accent' />
            </FlexLayout>
            <FlexLayout direction='column'>
              <Heading size='h3'>Transaction history</Heading>
              <Body className='hidden @sm:block' color='secondary'>
                View all your deposits, withdrawals and trades in one spot.
              </Body>
            </FlexLayout>
          </FlexLayout>
          <Button size='lg' className='hidden @sm:block' onClick={() => navigate(NavigationURLs.ProfileTaxReports)}>
            View transaction reports
          </Button>
          <Button size='md' className='block @sm:hidden' onClick={() => navigate(NavigationURLs.ProfileTaxReports)}>
            View reports
          </Button>
        </FlexLayout>
      }
    >
      <PageContent offset className='p-0 sm:p-24 sm:pt-24'>
        <TransactionsTableCard
          type={type}
          onChangeType={onChangeRoute}
          className='h-[calc(100vh-8rem)] sm:h-[calc(100vh-15.5rem)]'
        />
      </PageContent>
    </Page>
  );
});

Transactions.displayName = 'Transactions';

export default Transactions;

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { StorageKey } from '@shared/storage';

import { IChartApi } from 'lightweight-charts';
import { IChartingLibraryWidget, ResolutionString } from 'public/assets/charting_library/charting_library';
import { useLocalStorage } from 'react-use';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { Widgets } from 'src/lib/dashboard/types';

import { useTradingViewAnalytics } from '../hooks';
import { ChartStyle, TradingViewChartType, TradingViewSide } from '../types';
import { DEFAULT_TRADINGVIEW_PRICE_SIDE, getFromGlobalState, updateGlobalState } from '../types/tradingViewGlobalState';

type TradingViewContextType = {
  simpleChartState?: IChartApi;
  advancedChartState?: IChartingLibraryWidget;
  chartState?: IChartApi | IChartingLibraryWidget;
  primary: string;
  secondary: string;
  side: TradingViewSide;
  selectedResolution: ResolutionString;
  simpleResolution: ResolutionString;
  selectedSimpleResolution: string;
  simpleResolutionMinutes: number;
  selectedChartType: ChartStyle;
  selectedChartStyle: TradingViewChartType;
  showMarks?: boolean;
  showSimpleVolume?: boolean;
  showOpenOrders?: boolean;
  showPreviewOrders?: boolean;
  loadingSide: boolean;
  setSelectedResolution: React.Dispatch<React.SetStateAction<ResolutionString>>;
  setSelectedSimpleResolution: React.Dispatch<React.SetStateAction<string>>;
  setSelectedChartType: React.Dispatch<React.SetStateAction<ChartStyle>>;
  setSelectedChartStyle: (newValue: TradingViewChartType) => void;
  setSide: React.Dispatch<React.SetStateAction<TradingViewSide>>;
  setShowMarks: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowSimpleVolume: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowOpenOrders: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowPreviewOrders: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSimpleChartState: React.Dispatch<React.SetStateAction<IChartApi | undefined>>;
  setAdvancedChartState: React.Dispatch<React.SetStateAction<IChartingLibraryWidget | undefined>>;
  setLoadingSide: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateSide: (newSide: TradingViewSide, onPriceSideChange?: (side: TradingViewSide) => void) => void;
};

const TradingViewContext = createContext<TradingViewContextType>({
  primary: '',
  secondary: '',
  side: 'bid',
  selectedResolution: '1D' as ResolutionString,
  selectedSimpleResolution: '1D',
  simpleResolution: '1D' as ResolutionString,
  simpleResolutionMinutes: 5,
  selectedChartType: ChartStyle.Area,
  selectedChartStyle: 'simple',
  showMarks: false,
  showSimpleVolume: false,
  showOpenOrders: false,
  showPreviewOrders: false,
  loadingSide: false,
  setSelectedResolution: () => {},
  setSelectedSimpleResolution: () => {},
  setSelectedChartType: () => {},
  setShowMarks: () => {},
  setShowSimpleVolume: () => {},
  setShowOpenOrders: () => {},
  setShowPreviewOrders: () => {},
  setSide: () => {},
  setSimpleChartState: () => {},
  setAdvancedChartState: () => {},
  setSelectedChartStyle: () => {},
  setLoadingSide: () => {},
  onUpdateSide: () => {},
});

const TRADING_VIEW_WIDGET_ID: Widgets = 'trading_view';

type Props = {
  primary: string;
  secondary: string;
  chartStyle: TradingViewChartType;
  chartSide?: 'bid' | 'ask';
};

const TradingViewContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  primary,
  secondary,
  chartStyle,
  chartSide,
}) => {
  const [selectedResolution, setSelectedResolution] = useState<ResolutionString>('1D' as ResolutionString);
  const [selectedSimpleResolution, setSelectedSimpleResolution] = useState<string>('1D');
  const [selectedChartType, setSelectedChartType] = useState<ChartStyle>(ChartStyle.Area);
  const [selectedChartStyle, setSelectedChartStyle] = useDashboardSyncState<TradingViewChartType>({
    widgetId: TRADING_VIEW_WIDGET_ID,
    defaultValue: chartStyle,
    stateKey: 'selectedChartStyle',
  });
  const [side, setSide] = useState<TradingViewSide>(chartSide || DEFAULT_TRADINGVIEW_PRICE_SIDE);

  const [loadingSide, setLoadingSide] = useState<boolean>(false);
  const [showMarks, setShowMarks] = useLocalStorage<boolean>(StorageKey.SHOW_HISTORICAL_ORDERS, false);
  const [showSimpleVolume, setShowSimpleVolume] = useLocalStorage<boolean>(StorageKey.SHOW_SIMPLE_VOLUME, false);
  const [showOpenOrders, setShowOpenOrders] = useLocalStorage<boolean>(StorageKey.SHOW_OPEN_ORDERS, false);
  const [showPreviewOrders, setShowPreviewOrders] = useLocalStorage<boolean>(StorageKey.SHOW_PREVIEW_ORDERS, true);
  const [simpleChartState, setSimpleChartState] = useState<IChartApi>();
  const [advancedChartState, setAdvancedChartState] = useState<IChartingLibraryWidget>();
  const { trackPriceSideSelected } = useTradingViewAnalytics({ primary, secondary });

  const onUpdateSide = useCallback(
    (newSide: TradingViewSide, onPriceSideChange?: (side: TradingViewSide) => void) => {
      setLoadingSide(true);
      updateGlobalState('side', newSide);
      trackPriceSideSelected(newSide);
      if (onPriceSideChange) onPriceSideChange(newSide);
      if (advancedChartState) {
        if (getFromGlobalState('onResetCacheNeededCallback')) getFromGlobalState('onResetCacheNeededCallback')?.();

        advancedChartState.onChartReady(() => {
          advancedChartState.activeChart().resetData();
        });
      }
      window.setTimeout(() => setLoadingSide(false), 750);
    },
    [advancedChartState, trackPriceSideSelected],
  );

  useEffect(() => {
    if (chartSide && chartSide !== side) {
      setSide(chartSide);
      onUpdateSide(chartSide);
    }
  }, [chartSide, onUpdateSide, side]);

  const chartState = useMemo(
    () => (selectedChartStyle === 'advanced' ? advancedChartState : simpleChartState),
    [advancedChartState, selectedChartStyle, simpleChartState],
  );

  const simpleResolution = useMemo((): ResolutionString => {
    switch (selectedSimpleResolution) {
      case '1D':
        return '5' as ResolutionString;
      case '1W':
        return '5' as ResolutionString;
      case '1M':
        return '60' as ResolutionString;
      case '3M':
        return '60' as ResolutionString;
      case '6M':
        return '240' as ResolutionString;
      case '1Y':
      case 'All':
      default:
        return '1D' as ResolutionString;
    }
  }, [selectedSimpleResolution]);

  const simpleResolutionMinutes = useMemo((): number => {
    switch (selectedSimpleResolution) {
      case '1D':
        return 5;
      case '1W':
        return 5;
      case '1M':
        return 60;
      case '3M':
        return 60;
      case '6M':
        return 240;
      case '1Y':
      case 'All':
      default:
        return 1440;
    }
  }, [selectedSimpleResolution]);

  return (
    <TradingViewContext.Provider
      value={{
        selectedChartStyle,
        simpleChartState,
        advancedChartState,
        chartState,
        primary,
        secondary,
        side,
        selectedResolution,
        selectedSimpleResolution,
        simpleResolution,
        simpleResolutionMinutes,
        setSelectedResolution,
        setSelectedSimpleResolution,
        selectedChartType,
        setSelectedChartType,
        showMarks,
        showSimpleVolume,
        showOpenOrders,
        showPreviewOrders,
        loadingSide,
        setLoadingSide,
        setShowSimpleVolume,
        setShowMarks,
        setShowOpenOrders,
        setShowPreviewOrders,
        setSide,
        setSimpleChartState,
        setAdvancedChartState,
        setSelectedChartStyle,
        onUpdateSide,
      }}
    >
      {children}
    </TradingViewContext.Provider>
  );
};

export { TradingViewContextProvider, TradingViewContext };

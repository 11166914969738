import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { GameFilled } from '@swyftx/aviary/icons/filled';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { AppStore, UIStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import {
  DEMO_MODE_RESET_MODAL_KEY,
  DemoModeResetModal,
} from '@routes/Profile/subroutes/Settings/components/DemoMode/DemoModeResetModal';

import { observer } from 'mobx-react-lite';

const DemoModeBanner: React.FC = observer(() => {
  const { t } = useTranslation('common');
  const { isDemo } = AppStore.useAppStore;
  const { addMessageBox } = UIStore.useUIStore;
  const { openModal } = useModal();
  const { isDarkMode } = useTailwindTheme();

  const confirmReset = useCallback(() => {
    addMessageBox({
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      content: <DemoModeResetModal />,
      hideDismiss: true,
      forceKey: DEMO_MODE_RESET_MODAL_KEY,
    });
  }, [addMessageBox]);

  return (
    <FlexLayout
      className={cn(
        isDemo ? 'h-52' : 'h-0',
        'transition-height flex w-full flex-row items-center justify-between overflow-hidden bg-color-background-demo-mode px-12 duration-200 ease-in-out',
      )}
    >
      <FlexLayout direction='row' spacing={8} alignItems='center' className='w-full'>
        <GameFilled className={cn('h-20 w-20', isDarkMode ? '!text-color-text-inverse' : '!text-color-text-primary')} />
        <Body weight='emphasis' size='small'>
          {t('demoMode.title')}
        </Body>
        <Body size='small'>{t('demoMode.subTitle')}</Body>
      </FlexLayout>
      <FlexLayout direction='row' spacing={8} className='hidden sm:flex'>
        <Button
          id='demoMode.resetFunds'
          variant='outlined'
          onClick={confirmReset}
          className={isDarkMode ? 'text-color-text-inverse outline-color-text-inverse' : 'outline-color-text-primary'}
        >
          {t('demoMode.resetFunds')}
        </Button>
        <Button
          id='demoMode.learnMore'
          variant='filled'
          color='inverse'
          onClick={() => openModal(Modals.DemoModeLearnMore)}
          className={
            isDarkMode
              ? 'bg-color-text-inverse text-color-text-primary hover:bg-color-text-inverse'
              : 'bg-color-text-primary text-color-text-inverse hover:bg-color-text-primary'
          }
        >
          {t('demoMode.learnMore')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
});

DemoModeBanner.displayName = 'DemoModeBanner';

export { DemoModeBanner };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { NanoColor } from '@swyftx/aviary/molecules/ShopItem';

import { cn } from '@shared/utils/lib/ui';

import { ColorNames } from '@Shop/Shop.data';
import { ShopIDs } from '@Shop/Shop.ids';

type Props = {
  colors: { [key: string]: string };
  selectedColor: NanoColor;
  setSelectedColor: (color: NanoColor) => void;
};

const ColorPicker: React.FC<Props> = ({ colors, selectedColor, setSelectedColor }) => {
  const keys = Object.keys(colors);

  return (
    <FlexLayout
      id={ShopIDs.colorPicker.container}
      direction='row'
      alignItems='center'
      justifyContent='center'
      className='pb-24'
      spacing={4}
    >
      {keys.map((key: string) => (
        <Tooltip key={key} title={ColorNames[key as NanoColor]}>
          <FlexLayout
            id={`${ShopIDs.colorPicker.index}.color.${key}`}
            alignItems='center'
            justifyContent='center'
            className={cn(
              'h-36 w-36 rounded-[100%] border-color-border-main p-4',
              selectedColor === key
                ? 'border border-color-border-accent'
                : 'hover:border hover:border-color-border-hover',
            )}
            onClick={() => setSelectedColor(key as NanoColor)}
          >
            <div className='h-24 w-24 cursor-pointer rounded-[100%]' style={{ background: key }} />
          </FlexLayout>
        </Tooltip>
      ))}
    </FlexLayout>
  );
};

export { ColorPicker };

/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { NoChartError } from '@global-components/Errors/NoChartError';
import { LineChart, LineChartToolTip } from '@global-components/charts/LineChart';
import { LineChartTooltipData, LineChartTooltipPosition } from '@global-components/charts/LineChart/LineChart.types';
import { formatCandleData } from '@global-components/charts/LineChart/util';

import { Asset } from '@shared/api';
import { formatDateTime } from '@shared/utils';

import { useCandleData } from '@hooks/Assets/useCandleData';

import { useSize } from 'react-use';

interface SingleWalletTradingInfoProps {
  asset: Asset;
  baseAsset: Asset;
}

const CHART_HEIGHT = 100;

const SingleWalletTradingInfoChart: React.FC<SingleWalletTradingInfoProps> = ({ asset, baseAsset }) => {
  const [widthSize, { width }] = useSize(<div className='h-0 w-full' />);
  const [tooltipInfo, setTooltipInfo] = useState<{
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  }>({ position: null, data: null });

  const tooltipCallback = useCallback(
    (tooltipPosition: LineChartTooltipPosition | null, tooltipData: LineChartTooltipData | null) => {
      setTooltipInfo({ data: tooltipData, position: tooltipPosition });
    },
    [],
  );

  const { candles: assetData } = useCandleData({ asset });

  const { formattedData, openPrice, closePrice, openDateTime, closeDateTime } = useMemo(
    () => formatCandleData(asset, baseAsset, assetData),
    [asset, baseAsset, assetData],
  );

  const lineChart = useMemo(() => {
    const graphColor = openPrice > closePrice ? 'var(--color-text-error)' : 'var(--color-text-success)';
    if (formattedData?.length) {
      return (
        <FlexLayout direction='column' className='w-full'>
          <LineChart
            idKey='single-wallet-trading-info-chart'
            chartData={formattedData || []}
            tooltipCallback={tooltipCallback}
            backgroundColor='var(--color-background-surface-primary)'
            areaColor={graphColor}
            height={CHART_HEIGHT}
            width={width}
            removeYAxisPadding
          />
          <FlexLayout direction='row' justifyContent='space-between'>
            {openDateTime ? (
              <Numeric size='small' color='secondary'>
                {formatDateTime(new Date(openDateTime))}
              </Numeric>
            ) : null}
            {closeDateTime ? (
              <Numeric size='small' color='secondary'>
                {formatDateTime(closeDateTime)}
              </Numeric>
            ) : null}
          </FlexLayout>
        </FlexLayout>
      );
    }

    return <NoChartError />;
  }, [openPrice, closePrice, formattedData, tooltipCallback, width, openDateTime, closeDateTime]);

  return (
    <FlexLayout direction='column'>
      <div className='relative'>
        {widthSize}
        {lineChart}
        <LineChartToolTip tooltipInfo={tooltipInfo} width={width} />
      </div>
    </FlexLayout>
  );
};

export { SingleWalletTradingInfoChart };

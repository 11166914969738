import React, { forwardRef, Ref, KeyboardEvent, InputHTMLAttributes } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

interface OTPInputProps extends InputHTMLAttributes<HTMLInputElement> {
  index: number;
  value: string;
  onChangeValue: (newValue: string[], index: number) => void;
  numberOfDigits: number;
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
}

const OTPInput = forwardRef<HTMLInputElement, OTPInputProps>(
  ({ index, value, onChangeValue, numberOfDigits, inputRefs, ...props }, ref: Ref<HTMLInputElement>) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const digits = e.target.value.match(/\d/g) || [];
      const truncatedDigits = digits.slice(0, numberOfDigits);
      const newValue = index === 0 && !truncatedDigits.length ? [''] : Array.from(value);

      truncatedDigits.forEach((d, i) => {
        if (index + i >= numberOfDigits) return;
        return (newValue[index + i] = d);
      });

      const lastDigit = truncatedDigits.slice(-1)[0];
      if (lastDigit) {
        const nextInput = inputRefs.current[Math.min(index + truncatedDigits.length, numberOfDigits - 1)];
        nextInput?.focus();
      }

      onChangeValue(newValue, index);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowLeft' && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      if (e.key === 'ArrowRight' && index < numberOfDigits - 1) {
        inputRefs.current[index + 1]?.focus();
      }
      if (e.key === 'Backspace' && index > 0) {
        if (index > 0) {
          e.preventDefault();
          const newValue = Array.from(value);
          newValue[index] = '';
          onChangeValue(newValue, index);
          inputRefs.current[index - 1]?.focus();
        } else {
          onChangeValue([], index);
          inputRefs.current[0]?.focus();
        }
      }
    };

    return (
      <Input
        type='tel'
        className='ml-6 text-center text-20 font-500'
        containerClassName='rounded-[12px] h-[56px] w-[48px] !p-12'
        ref={ref}
        id={`OTPinput-${index}`}
        value={value[index] || ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        aria-label={`OTP Digit ${index + 1}`}
        role='textbox'
        {...props}
      />
    );
  },
);

interface OTPInputGroupProps extends Omit<OTPInputProps, 'index' | 'inputRefs'> {
  numberOfDigits: number;
}

const OTPInputGroup: React.FC<OTPInputGroupProps> = ({ numberOfDigits, ...props }) => {
  const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);

  return (
    <FlexLayout direction='column' spacing={24}>
      <FlexLayout spacing={12}>
        {Array.from({ length: numberOfDigits }, (_, index) => (
          <OTPInput
            ref={(el) => (inputRefs.current[index] = el)}
            key={index}
            index={index}
            numberOfDigits={numberOfDigits}
            inputRefs={inputRefs}
            {...props}
          />
        ))}
      </FlexLayout>
    </FlexLayout>
  );
};

OTPInput.displayName = 'OTPInput';

export { OTPInput, OTPInputGroup };

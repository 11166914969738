import React, { useEffect, useState } from 'react';

import Wizard from '@global-components/Wizard';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { EntityTypeEnum } from '@shared/enums';
import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { UpdatePhoneInputStep } from './steps/UpdatePhoneInputStep';
import { UpdatePhoneVerifyStep } from './steps/UpdatePhoneVerifyStep';

type Props = {
  onClose: () => void;
};

export const UpdatePhoneWizard: React.FC<Props> = observer(({ onClose }) => {
  const { addMessageBox, addToastMessage } = UIStore.useUIStore;
  const { userProfile } = UserStore.useUserStore;

  const [stepIndex, setStepIndex] = useState(0);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  const goToVerify = () => {
    setStepIndex(1);
  };

  const onSuccess = () => {
    addMessageBox({
      content: <SuccessMessageBox title='Phone number successfully updated' />,
    });
    onClose();
  };

  useEffect(() => {
    if (userProfile?.entityDetails?.entityType !== EntityTypeEnum.PERSONAL) {
      onClose();
      addToastMessage({ message: 'Entity accounts cannot change their phone number', severity: 'error' });
    }
  }, [addToastMessage, onClose, userProfile]);

  return (
    <Wizard.Container open onClose={onClose} stepIndex={stepIndex}>
      <UpdatePhoneInputStep
        newPhoneNumber={newPhoneNumber}
        setNewPhoneNumber={setNewPhoneNumber}
        onClose={onClose}
        onAction={goToVerify}
      />
      <UpdatePhoneVerifyStep onClose={onClose} newPhoneNumber={newPhoneNumber} onSuccess={onSuccess} />
    </Wizard.Container>
  );
});

import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

// TODO we should not filter the timeout errors but we can't do anything about them right now
const errorTypesFilter = ['InvalidCredentials'];
const errorMessageFilter = ['Network Error', 'timeout of 20000ms exceeded'];

const checkTypesFilter = (responseBody?: any) => {
  const lookup = responseBody?.error?.error || responseBody?.error;
  return errorTypesFilter.includes(lookup);
};

export const checkIgnoreError = (error: AxiosError) => {
  try {
    // ignore errors if they are a certain type based on the above filters
    if (checkTypesFilter(error.response?.data)) return true;
    if (errorMessageFilter.includes(error.message)) return true;
  } catch (e) {
    Sentry.captureException(e);
  }

  // safely fallback to not ignoring the error
  return false;
};

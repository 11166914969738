import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { capitalize } from '@utils/formatting';

import { Menu, MenuProps, MenuItem } from '../Menu';

type Props<T extends string> = {
  align?: MenuProps['align'];
  title: string;
  isSmallScreen: boolean;
  className?: string;
  value?: T[];
  items: SelectItemData<T>[];
  multi?: boolean;
  showSearch?: boolean;
  onChange: (value: T[]) => void;
};

const TableSelectFilter = <T extends string>({
  align = 'end',
  title,
  isSmallScreen,
  className,
  items,
  value = [],
  multi = true,
  showSearch = false,
  onChange,
}: Props<T>) => {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<T[]>(value);

  const getItemLabel = useCallback((val: string) => items.find((i) => i.value === val), [items]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value, open]);

  const tooltip = useMemo(() => {
    if (value.length <= 1 || value.length === items.length) return '';

    return value.map(capitalize).join(', ');
  }, [items.length, value]);

  const titleValue = useMemo(() => {
    if (value.length === 0) return '';
    const filteredItems = items.filter((i) => !i.disabled);

    if (value.length === filteredItems.length) return 'All';
    if (value.length === 1) return getItemLabel(value[0])?.label;

    return `${getItemLabel(value[0])?.label} + ${value.length - 1} other`;
  }, [getItemLabel, items, value]);

  const onToggleItem = useCallback(
    (itemValue: T) => {
      if (!multi) {
        if (isSmallScreen) {
          const newValue = selectedValues.includes(itemValue) ? [] : [itemValue];
          setSelectedValues(newValue);
        } else {
          const newValue = value.includes(itemValue) ? [] : [itemValue];

          onChange(newValue);
        }
        return;
      }

      const currentValues: T[] = [...selectedValues];
      const index = currentValues.indexOf(itemValue);

      if (index > -1) {
        currentValues.splice(index, 1);
      } else {
        currentValues.push(itemValue);
      }

      if (isSmallScreen) {
        setSelectedValues(currentValues);
      } else {
        onChange(currentValues);
      }
    },
    [isSmallScreen, multi, onChange, selectedValues, value],
  );

  const filteredItems = useMemo(() => {
    if (!showSearch || !search.length) return items;

    return items.filter((a) => {
      const loweredSearch = search.toLowerCase();

      if (a.label.toLowerCase().includes(loweredSearch) || a.value.toLowerCase().includes(loweredSearch)) return true;

      return false;
    });
  }, [items, search, showSearch]);

  const onApply = useCallback(() => {
    onChange(selectedValues);
    setOpen(false);
  }, [onChange, selectedValues]);

  const onReset = useCallback(() => {
    onChange([]);
    setOpen(false);
  }, [onChange]);

  return (
    <Menu
      variant={isSmallScreen ? 'mobile' : 'standard'}
      open={open}
      onOpenChange={setOpen}
      align={align}
      title={`Select ${title.toLocaleLowerCase()}`}
      triggerContent={
        <span className={className}>
          <Tooltip title={tooltip}>
            <FlexLayout
              alignItems='center'
              justifyContent='space-between'
              className={cn(
                'focus:focus-area-info cursor-pointer rounded-8 border bg-color-background-surface-primary p-8 ',
                open
                  ? 'focus-area-info border-color-border-info'
                  : 'border-color-border-main hover:border-color-border-hover',
                className,
              )}
              spacing={16}
            >
              <FlexLayout alignItems='center' spacing={4} justifyContent='start'>
                <Body weight='emphasis'>
                  {title}
                  {titleValue ? ':' : ''}
                </Body>
                <Body className='whitespace-nowrap'>{titleValue}</Body>
              </FlexLayout>
              {open ? <ArrowChevronUp className='h-20 w-20' /> : <ArrowChevronDown className='h-20 w-20' />}
            </FlexLayout>
          </Tooltip>
        </span>
      }
    >
      <FlexLayout direction='column' spacing={8}>
        {showSearch && <Input placeholder='Search for...' value={search} onChange={(e) => setSearch(e.target.value)} />}
        <FlexLayout direction='column' className='max-h-[300px] overflow-y-auto'>
          {filteredItems.map((item) =>
            item.disabled ? null : (
              <MenuItem
                key={item.value}
                icon={item.leadingIcon}
                text={item.label}
                endAdornment={multi ? <Checkbox checked={selectedValues.includes(item.value)} /> : null}
                selected={!multi ? selectedValues.includes(item.value) : false}
                action={() => onToggleItem(item.value)}
              />
            ),
          )}
        </FlexLayout>
      </FlexLayout>
      <FlexLayout className='mt-8' spacing={16}>
        <Button
          size={isSmallScreen ? 'lg' : 'sm'}
          className={isSmallScreen ? 'w-full' : ''}
          variant='outlined'
          disabled={!value.length}
          onClick={onReset}
        >
          Reset
        </Button>
        {isSmallScreen && (
          <Button
            size={isSmallScreen ? 'lg' : 'sm'}
            className={isSmallScreen ? 'w-full' : ''}
            variant='filled'
            disabled={!selectedValues.length}
            onClick={onApply}
          >
            Apply
          </Button>
        )}
      </FlexLayout>
    </Menu>
  );
};

export { TableSelectFilter };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Percent: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M19.5072 4.48414C19.8001 4.77703 19.8001 5.2519 19.5072 5.5448L5.56451 19.4875C5.27162 19.7804 4.79674 19.7804 4.50385 19.4875C4.21096 19.1946 4.21096 18.7198 4.50385 18.4269L18.4466 4.48414C18.7395 4.19124 19.2144 4.19124 19.5072 4.48414Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.969 19.7496C17.7127 19.7496 18.4182 19.452 18.937 18.9331C19.197 18.6732 19.4103 18.3508 19.5532 18.0293C19.563 18.0072 19.5717 17.9847 19.5793 17.9618C19.677 17.6689 19.7535 17.3445 19.7535 16.9651C19.7535 16.5972 19.6806 16.245 19.5793 15.9413C19.5717 15.9184 19.563 15.8959 19.5532 15.8739C19.399 15.5269 19.1757 15.2358 18.937 14.9971C17.8846 13.9447 16.0534 13.9447 15.001 14.9971C14.7318 15.2664 14.5487 15.5663 14.412 15.8739C14.2581 16.2202 14.1846 16.596 14.1846 16.9651C14.1846 17.3485 14.2627 17.6934 14.412 18.0293C14.5352 18.3066 14.7157 18.6479 15.001 18.9331C15.5198 19.452 16.2253 19.7496 16.969 19.7496ZM17.8764 16.0578C17.4097 15.5912 16.5283 15.5912 16.0617 16.0578C15.9512 16.1683 15.863 16.3024 15.7827 16.4831C15.7196 16.6251 15.6846 16.7918 15.6846 16.9651C15.6846 17.1297 15.715 17.2677 15.7827 17.4201C15.8765 17.631 15.9672 17.778 16.0617 17.8725C16.3024 18.1132 16.6277 18.2496 16.969 18.2496C17.3104 18.2496 17.6356 18.1132 17.8764 17.8725C17.9887 17.7602 18.0943 17.6073 18.1681 17.4513C18.2253 17.2724 18.2535 17.1232 18.2535 16.9651C18.2535 16.8021 18.2224 16.6253 18.1682 16.4523C18.1046 16.321 18.0072 16.1886 17.8764 16.0578Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.96153 9.57816C6.29298 9.72547 6.64047 9.80827 7.02566 9.80827C7.39993 9.80827 7.77514 9.73006 8.11691 9.57816C8.41991 9.44349 8.72165 9.26384 8.99366 8.99183C9.00543 8.98006 9.0168 8.9679 9.02776 8.95537C9.23694 8.71632 9.45644 8.43308 9.60983 8.08795L9.61107 8.08515C9.77819 7.70496 9.8101 7.347 9.8101 7.02382C9.8101 6.28234 9.51402 5.5732 8.99317 5.05532C7.94038 4.0004 6.15509 4.00886 5.08152 5.03267C4.77029 5.30724 4.58457 5.67164 4.46861 5.93256C4.31491 6.27838 4.24121 6.65143 4.24121 7.02382C4.24121 7.40545 4.31974 7.74929 4.46737 8.08515L4.46861 8.08795C4.60695 8.39922 4.7829 8.68034 5.02355 8.95537C5.03451 8.9679 5.04588 8.98006 5.05765 8.99183C5.34517 9.27935 5.68929 9.45716 5.96153 9.57816ZM6.10989 6.12477C6.61089 5.63931 7.47067 5.6525 7.93206 6.11553L7.93491 6.11837C8.1727 6.35447 8.3101 6.68094 8.3101 7.02382C8.3101 7.24228 8.28795 7.36704 8.23847 7.48019C8.17706 7.61779 8.07644 7.76256 7.9169 7.94693C7.8121 8.04739 7.68386 8.12915 7.50771 8.20744C7.36121 8.27255 7.1939 8.30827 7.02566 8.30827C6.86833 8.30827 6.72754 8.27713 6.57073 8.20744C6.36669 8.11676 6.22677 8.0332 6.13415 7.94648C6.00524 7.79544 5.91501 7.64871 5.83996 7.48017C5.77101 7.32291 5.74121 7.18424 5.74121 7.02382C5.74121 6.84827 5.77601 6.68423 5.83933 6.54177C5.9381 6.31952 6.01864 6.20482 6.07608 6.15559C6.08766 6.14566 6.09893 6.13538 6.10989 6.12477Z'
      fill='currentColor'
    />
  </svg>
);

export { Percent };

import { APIKeyScope, APIKeyScopeItem } from '@shared/api';
import apiKeyHelpers, { CreateAPIKeyType, GroupedScope } from '@shared/api/lib/helpers/apiKeys.helper';

/** formats the keys to usable data since it comes from the api */
export const formatKeys = (apiScopes: APIKeyScope): GroupedScope => {
  const localScopes: GroupedScope = {};

  const createSelectable = (item: APIKeyScopeItem) => ({ ...item, selected: false, expanded: false, locked: false });

  for (const [key, value] of Object.entries(apiScopes)) {
    const group = key.split('.')[1];

    // eslint-disable-next-line no-continue
    if (!group || group === 'special') continue;

    if (!localScopes[group]) {
      localScopes[group] = {
        parent: createSelectable(value),
        children: [],
      };
    } else {
      localScopes[group].children.push(createSelectable(value));
    }
  }

  return localScopes;
};

export type ApiKeyPresetData = {
  keyType: CreateAPIKeyType;
  scopes: GroupedScope;
};

/** sets scope data with the specified presets */
export const fetchApiKeyPreset = (scopes: GroupedScope, type?: CreateAPIKeyType): ApiKeyPresetData | undefined => {
  if (!Object.keys(scopes).length || type === undefined) return;

  const preset = apiKeyHelpers.presets.find((presetData) => presetData.name === type);

  if (!preset) {
    return {
      keyType: type,
      scopes,
    };
  }

  const localScopes = { ...scopes };

  // set all to unselected
  apiKeyHelpers.toggleAll(localScopes, false);

  if (preset) {
    // set all scopes
    for (const scope of preset.scopes) {
      const [parent, child] = scope.split('.');

      const group = localScopes[parent];

      if (!child) {
        // if no child set all as active
        group.parent.selected = true;
        for (const childData of group.children) {
          childData.selected = true;
        }
      } else {
        const childScope = group.children.find((childData) => childData.key === `app.${scope}`);
        if (childScope) childScope.selected = true;
      }
    }

    // set all required read scopes
    apiKeyHelpers.checkReadScopes(localScopes);

    // check dependants
    apiKeyHelpers.checkDependants(localScopes);
  }

  return {
    keyType: preset?.name || type,
    scopes: localScopes,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@swyftx/react-web-design-system';

import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';

import { DashboardTile } from '@shared/api';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

interface Props {
  tile: DashboardTile;
}

const ActivityTileTableHeader: React.FC<Props> = ({ tile }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'activityTile' });

  const { isLargeContent } = useContentBreakpoint();
  const isSmall = !isLargeContent || tile.metadata?.size === 'compact' || tile.metadata?.size === 'half';

  return (
    <>
      <Grid container direction='row' alignItems='center' paddingX={2} paddingY={1.5}>
        {/* transaction type */}
        <LayoutGridItem xs={isSmall ? 4 : 3}>
          <Typography color='text.secondary' fontSize={14} fontWeight={600}>
            {t('labels.type')}
          </Typography>
        </LayoutGridItem>

        {!isSmall && <LayoutGridItem xs={3} />}

        <LayoutGridItem xs={isSmall ? 8 : 3}>
          <Typography color='text.secondary' fontSize={14} fontWeight={600} textAlign={isSmall ? 'right' : 'left'}>
            {t('labels.amount')}
          </Typography>
        </LayoutGridItem>

        {!isSmall && (
          <LayoutGridItem xs={isSmall ? 4 : 3}>
            <Typography color='text.secondary' fontSize={14} fontWeight={600} textAlign='right'>
              {t('labels.date')}
            </Typography>
          </LayoutGridItem>
        )}
      </Grid>
      <Divider variant='fullWidth' orientation='horizontal' />
    </>
  );
};

export { ActivityTileTableHeader };

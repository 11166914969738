import { RefObject, useEffect, useRef, useState } from 'react';

import { useScroll } from 'react-use';

type UsePaginatedScroll<T> = {
  scrollRef: RefObject<HTMLDivElement>;
  newItems: T[];
};

const usePaginatedScroll = <T>(items: T[], itemsPerPage = 10): UsePaginatedScroll<T> => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);
  const [newItems, setNewItems] = useState<T[]>(() => items.slice(0, itemsPerPage));
  const { y } = useScroll(scrollRef);

  useEffect(() => {
    const maxPages = Math.ceil(items.length / itemsPerPage);

    if (!scrollRef.current || scrollRef.current.clientHeight === 0) return;

    const listHeight = scrollRef.current.scrollHeight;
    const viewportHeight = scrollRef.current.clientHeight;
    const scrollHeight = listHeight - viewportHeight;
    const scrollPercentage = Math.min((y / scrollHeight) * 100, 100); // due to rounding in the percentage it's possible to get a value greater than 100
    const pageNumber = Math.max(1, Math.ceil((maxPages * scrollPercentage) / 100)); // We don't want to go below page 1

    setPage(pageNumber);
  }, [y, items.length, itemsPerPage, page]);

  useEffect(() => {
    setNewItems(items.slice(itemsPerPage * (page - 1), itemsPerPage * page));
  }, [items, itemsPerPage, page]);

  return {
    scrollRef,
    newItems,
  };
};

export { usePaginatedScroll };

import React from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';

import { useTradeValue } from 'src/lib/trade/hooks/General';

type Props = {
  baseAsset: Asset;
  asset: Asset;
  setTriggerPrice: (price: string) => void;
  triggerPrice: string;
  error: string;
};

export const EnterAlertPrice: React.FC<Props> = (props) => {
  const { baseAsset, error, triggerPrice, setTriggerPrice, asset } = props;

  const { formattedAmount, onChangeValue } = useTradeValue({
    amount: triggerPrice,
    limitAsset: asset,
    onChange: (e) => setTriggerPrice(e ?? ''),
  });

  return (
    <FlexLayout direction='column' spacing={8}>
      <Body weight='emphasis'>Edit Trigger Price</Body>
      <Input
        leading={<Body color='secondary'>$</Body>}
        onChange={(e) => onChangeValue(e.target.value)}
        error={error.length > 0}
        type='tel'
        trailing={<Numeric>{baseAsset.code}</Numeric>}
        placeholder='0.00'
        value={formattedAmount}
      />
      {error && (
        <Body color='error' size='small'>
          {error}
        </Body>
      )}
    </FlexLayout>
  );
};

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { RegisterContext } from '@routes/Register/Register.context';

import { observer } from 'mobx-react-lite';

import { RegisterAffiliateBar, RegisterAffiliateChip, RegisterProgressBar, RegisterTitle } from './components';
import { useRegister } from './hooks/useRegister';

const Register: React.FC = observer(() => {
  const { registerStep, referralCode, promoRef, registerStepsConfig } = useContext(RegisterContext);

  const { t } = useTranslation('register');
  const { registerStepElement } = useRegister();
  const { forceSetTheme } = useTailwindTheme();

  useEffect(() => {
    forceSetTheme(TailWindTheme.Light);

    return () => {
      forceSetTheme();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexLayout
      direction='column'
      className='no-scrollbar relative h-full w-full overflow-y-auto overflow-x-hidden'
      alignItems='center'
      justifyContent='space-between'
    >
      {registerStep < registerStepsConfig.length && (
        <FlexLayout direction='column' alignItems='center' className='w-full'>
          <FlexLayout direction='column' spacing={8} alignItems='start' className='w-full'>
            <RegisterAffiliateBar />
            <RegisterTitle
              id='createAccount.labels.title'
              title={t(
                registerStep + 1 === registerStepsConfig.length
                  ? 'createAccount.finalStepTitle'
                  : 'createAccount.title',
              )}
            />
          </FlexLayout>

          <RegisterProgressBar />

          <FlexLayout
            id='register.referralCode.container.tablet'
            direction='column'
            justifyContent='center'
            alignItems='center'
            className='md:none hidden w-full sm:flex'
          >
            {promoRef && <RegisterAffiliateChip type='promoCode' />}
            {referralCode && <RegisterAffiliateChip type='referralCode' />}
          </FlexLayout>
        </FlexLayout>
      )}

      <FlexLayout direction='column' alignItems='center' className='h-full w-full'>
        {registerStepElement}
      </FlexLayout>
    </FlexLayout>
  );
});

export default Register;

import React, { PropsWithChildren, useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { Loading } from '@swyftx/aviary/icons/outlined';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetHistoryItem } from '@shared/api';
import { Big } from '@shared/safe-big';

import {
  TransactionHistorySides,
  TransactionHistoryStatuses,
  TransactionHistoryTab,
  TransactionsPageTableData,
} from '@routes/Transactions/types';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { useTransactionsPageTable } from './useTransactionsPageTable';

type Props = {
  type: TransactionHistoryTab;
  asset?: Asset;
  sides: TransactionHistorySides[];
  statuses: TransactionHistoryStatuses[];
  startDate?: DateTime;
  endDate?: DateTime;
};

const TransactionsTable: React.FC<PropsWithChildren<Props>> = observer(
  ({ type, asset, sides, statuses, startDate, endDate, children }) => {
    const { getAssetById } = useMarkets();
    const { headers, sort, onSort, data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useTransactionsPageTable({
        type,
        asset,
        sides,
        statuses,
        startDate,
        endDate,
      });
    const { openModal } = useModal();
    const { isSmallScreen } = useTailwindContainerBreakpoint({ containerName: 'transactions-table-card' });

    const onSelectItem = useCallback(
      (item: EnhancedTableData<TransactionsPageTableData, AssetHistoryItem>) => {
        if (item.value?.orderType) {
          openModal(Modals.OrderDetails, {
            orderId: item.value?.uuid,
            transaction: item.value,
            deleteModal: false,
          });
        } else {
          const asset = getAssetById(item.value?.secondaryAsset);
          if (asset) openModal(Modals.TransactionDetails, { transaction: item.value, asset });
        }
      },
      [getAssetById, openModal],
    );

    const onScrollTable = useCallback(
      (e: Event) => {
        if (isFetching || isFetchingNextPage || !hasNextPage) return;

        const element = e.target as HTMLElement;
        const scrollHeight = element.scrollHeight - element.clientHeight;
        const scrollPos = element.scrollTop;
        if (Big(scrollHeight).minus(scrollPos).lte(120)) {
          fetchNextPage();
        }
      },
      [fetchNextPage, isFetching, isFetchingNextPage, hasNextPage],
    );

    return (
      <EnhancedTable<TransactionsPageTableData, AssetHistoryItem>
        data-container='orders-history-table'
        headers={headers}
        data={data}
        sort={sort}
        headerClassName='bg-color-background-surface-tertiary'
        onSort={onSort}
        inset
        groupBy={isSmallScreen ? 'date' : undefined}
        onClickItem={onSelectItem}
        onScroll={(e) => onScrollTable(e as Event)}
        rowClassName='h-16'
        itemsPerPage={20}
        lazyLoad
      >
        {isFetching && (
          <FlexLayout
            className='h-full w-full'
            alignItems='center'
            justifyContent='center'
            direction='column'
            spacing={8}
          >
            <Loading className='h-24 w-24 animate-spin' />
          </FlexLayout>
        )}

        {!isFetching && !data.length && (
          <FlexLayout
            className='h-full w-full'
            alignItems='center'
            justifyContent='center'
            direction='column'
            spacing={8}
          >
            <Heading size='h4'>No transaction history exists</Heading>
            <Body color='secondary'>All future transactions will be displayed here</Body>
            {children}
          </FlexLayout>
        )}
      </EnhancedTable>
    );
  },
);

export { TransactionsTable };

import React, { createContext, PropsWithChildren } from 'react';

import { api, AffiliateInfoResponse, ReferralCodeResponse, UpdateAffiliateArgs } from '@shared/api';
import { BecomeAffiliateArgs } from '@shared/api/@types/history';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { AffiliateHistoryEntry, initialValues, ProfileStoreSchema } from '@Profile/types/Profile.types';

import * as Sentry from '@sentry/react';
import { useLocalObservable } from 'mobx-react-lite';

export const ProfileContext = createContext<ProfileStoreSchema>(initialValues);

export const ProfileProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { userProfile } = UserStore.useUserStore;

  const store = useLocalObservable(
    (): ProfileStoreSchema => ({
      ...initialValues,
      setFetchedAffiliateInfo: (fetchedAffiliateInfo: boolean) => {
        store.fetchedAffiliateInfo = fetchedAffiliateInfo;
      },
      setFetchingAffiliateInfo: (fetchingAffiliateInfo: boolean) => {
        store.fetchingAffiliateInfo = fetchingAffiliateInfo;
      },
      setAffiliateInfo: (affiliateInfo?: AffiliateInfoResponse) => {
        store.affiliateInfo = affiliateInfo;
      },
      setFetchingReferralcode: (fetchingReferralCode: boolean) => {
        store.fetchingAffiliateInfo = fetchingReferralCode;
      },
      setReferralCode: (referralCode?: ReferralCodeResponse) => {
        store.referralCode = referralCode;
      },
      setPayoutHistory: (payoutHistory: AffiliateHistoryEntry[]) => {
        store.payoutHistory = payoutHistory;
      },
      setAbn: (abn: string) => {
        store.abn = abn;
      },
      setPassword: (password: string) => {
        store.password = password;
      },
      setConfirmPassword: (confirmPassword: string) => {
        store.confirmPassword = confirmPassword;
      },
      setGstRegistered: (gstRegistered: number) => {
        store.gstRegistered = gstRegistered;
      },
      setHasAbn: (hasAbn: number) => {
        // Clear the abn value if we're switching to no abn
        if (!hasAbn) {
          store.abn = undefined;
        }
        store.hasAbn = hasAbn;
      },
      setNoAbnGstReason: (noAbnGstReason: number) => {
        store.noAbnGstReason = noAbnGstReason;
      },
      fetchAffiliateInfo: async () => {
        try {
          store.setFetchingAffiliateInfo(true);
          const resp = await api.endpoints.getAffiliationInfo();
          store.setAffiliateInfo(resp.data);
          await store.fetchPayoutHistory();
        } catch {
          store.setAffiliateInfo(undefined);
        } finally {
          store.setFetchedAffiliateInfo(true);
          store.setFetchingAffiliateInfo(false);
        }
      },
      fetchReferralCode: async () => {
        try {
          store.setFetchingReferralcode(true);
          const resp = await api.endpoints.getReferralCode();
          store.setReferralCode(resp.data);
        } catch {
          store.setReferralCode(undefined);
        } finally {
          store.setFetchingReferralcode(false);
        }
      },
      fetchPayoutHistory: async () => {
        try {
          const gstRegistered = userProfile?.userSettings.gstRegistered === 1;
          const response = await api.endpoints.getAffiliatePayoutHistory({ params: { limit: 120, page: 1 } });

          if (response.data && response.data.length > 0) {
            const history = (response.data as AffiliateHistoryEntry[]).map((historyEntry) => {
              const updatedHistoryEntry = historyEntry;

              updatedHistoryEntry.loading = false;

              if (updatedHistoryEntry.amount) {
                const amount = Big(updatedHistoryEntry.amount);

                updatedHistoryEntry.gst = amount.div(11).toFixed(18);
                updatedHistoryEntry.accrued = amount.toFixed(18);
                updatedHistoryEntry.total =
                  gstRegistered && !updatedHistoryEntry.gstWithheld // They pay GST
                    ? amount.toFixed(18)
                    : amount.minus(updatedHistoryEntry.gst).toFixed(18);
              }

              return updatedHistoryEntry;
            });

            store.setPayoutHistory(history);
          } else {
            store.setPayoutHistory([]);
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      },
      becomeAffiliate: async (isUpdate: boolean, onSuccess?: () => void, onError?: () => void) => {
        try {
          const { abn } = store;
          const gstRegistered = (store.hasAbn === 0 || store.gstRegistered === 0 ? store.noAbnGstReason : 1) || 1;

          if (isUpdate) {
            const data: UpdateAffiliateArgs = {
              abn,
              gstRegistered,
            };
            await api.endpoints.updateAffiliate({ data, headers: {} });
          } else {
            const data: BecomeAffiliateArgs = {
              data: {
                abn,
                gstRegistered,
              },
            };
            await api.endpoints.becomeAffiliate({ data });
          }
          if (onSuccess) onSuccess();
        } catch (e) {
          // sometimes creating fails but it doesn't, lets fetch info and if it exists allow it
          const { errorMessage } = e as SwyftxError;
          store.setError(errorMessage);
          onError?.();
        }
      },
      setError: (error: string) => {
        store.error = error;
      },
      cleanup: () => {
        store.setAffiliateInfo(undefined);
      },
    }),
  );

  return <ProfileContext.Provider value={store}>{children}</ProfileContext.Provider>;
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DollarInCircle: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.0017 5.25C12.4159 5.25 12.7517 5.58579 12.7517 6V7.03369H14.7576C15.1718 7.03369 15.5076 7.36948 15.5076 7.78369C15.5076 8.19791 15.1718 8.53369 14.7576 8.53369H12.7517V10.9487H13.1476C14.8092 10.9487 16.1563 12.2958 16.1563 13.9574C16.1563 15.6191 14.8092 16.9661 13.1476 16.9661H12.7517V18C12.7517 18.4142 12.4159 18.75 12.0017 18.75C11.5875 18.75 11.2517 18.4142 11.2517 18V16.9661H9.24409C8.82987 16.9661 8.49409 16.6303 8.49409 16.2161C8.49409 15.8019 8.82987 15.4661 9.24409 15.4661H11.2517V12.4487H10.553C9.05764 12.4487 7.84544 11.2365 7.84544 9.74122C7.84544 8.24589 9.05764 7.03369 10.553 7.03369H11.2517V6C11.2517 5.58579 11.5875 5.25 12.0017 5.25ZM11.2517 8.53369H10.553C9.88607 8.53369 9.34544 9.07432 9.34544 9.74122C9.34544 10.4081 9.88607 10.9487 10.553 10.9487H11.2517V8.53369ZM12.7517 12.4487V15.4661H13.1476C13.9808 15.4661 14.6563 14.7907 14.6563 13.9574C14.6563 13.1242 13.9808 12.4487 13.1476 12.4487H12.7517Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.0017 22C6.47953 22 2.00169 17.5233 2.00169 12C2.00169 6.47779 6.47948 2 12.0017 2C17.5239 2 22.0017 6.47779 22.0017 12C22.0017 17.5233 17.5239 22 12.0017 22ZM3.50169 12C3.50169 16.6947 7.30785 20.5 12.0017 20.5C16.6955 20.5 20.5017 16.6947 20.5017 12C20.5017 7.30621 16.6955 3.5 12.0017 3.5C7.3079 3.5 3.50169 7.30621 3.50169 12Z'
      fill='currentColor'
    />
  </svg>
);

export { DollarInCircle };

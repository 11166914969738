import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const AddUser: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.87562 2C6.93975 2 4.55935 4.38017 4.55862 7.31666C4.54887 10.2443 6.91319 12.6255 9.84194 12.636L9.84462 12.636H9.87562C12.8128 12.636 15.1936 10.2552 15.1936 7.318C15.1936 4.38079 12.8128 2 9.87562 2ZM6.05862 7.318C6.05862 5.20911 7.76794 3.5 9.87562 3.5C11.9844 3.5 13.6936 5.20921 13.6936 7.318C13.6936 9.42679 11.9844 11.136 9.87562 11.136H9.84603C7.74581 11.1278 6.0511 9.42003 6.05862 7.32068L6.05862 7.318Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.877 14.4561C7.93913 14.4561 6.05564 14.5997 4.6336 15.0765C3.91878 15.3161 3.26484 15.6578 2.78289 16.1581C2.28315 16.6768 2 17.3351 2 18.1151C2 18.8935 2.28071 19.5521 2.77764 20.0728C3.25728 20.5754 3.90895 20.9204 4.62311 21.1631C6.04357 21.646 7.92787 21.7951 9.877 21.7951C11.8154 21.7951 13.6989 21.6511 15.1209 21.1743C15.8356 20.9345 16.4894 20.5928 16.9713 20.0926C17.4709 19.5739 17.754 18.9158 17.754 18.1361C17.754 17.3579 17.4733 16.6994 16.9765 16.1788C16.497 15.6762 15.8454 15.3312 15.1313 15.0884C13.711 14.6053 11.8267 14.4561 9.877 14.4561ZM3.5 18.1151C3.5 17.731 3.62723 17.4437 3.86317 17.1987C4.11691 16.9353 4.52397 16.6953 5.1104 16.4987C6.29086 16.1029 7.97087 15.9561 9.877 15.9561C11.7923 15.9561 13.4715 16.1083 14.6484 16.5085C15.2329 16.7073 15.6384 16.9493 15.8913 17.2143C16.1269 17.4612 16.254 17.7507 16.254 18.1361C16.254 18.5198 16.1268 18.8071 15.8909 19.052C15.6373 19.3153 15.2303 19.5554 14.6439 19.7521C13.4636 20.148 11.7836 20.2951 9.877 20.2951C7.96213 20.2951 6.28293 20.1431 5.10589 19.743C4.5213 19.5442 4.11572 19.3022 3.8628 19.0372C3.62717 18.7903 3.5 18.5006 3.5 18.1151Z'
      fill='currentColor'
    />
    <path
      d='M19.2031 7.91895C19.6173 7.91895 19.9531 8.25473 19.9531 8.66895V9.92383H21.2502C21.6644 9.92383 22.0002 10.2596 22.0002 10.6738C22.0002 11.088 21.6644 11.4238 21.2502 11.4238H19.9531V12.6789C19.9531 13.0932 19.6173 13.4289 19.2031 13.4289C18.7889 13.4289 18.4531 13.0932 18.4531 12.6789V11.4238H17.1602C16.7459 11.4238 16.4102 11.088 16.4102 10.6738C16.4102 10.2596 16.7459 9.92383 17.1602 9.92383H18.4531V8.66895C18.4531 8.25473 18.7889 7.91895 19.2031 7.91895Z'
      fill='currentColor'
    />
  </svg>
);

export { AddUser };

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Loading, Stack, Typography } from '@swyftx/react-web-design-system';

import { CodeInput } from '@global-components/Input';

import { AuthContext } from '@routes/Auth/Auth.context';
import { AuthStep } from '@routes/Auth/types';
import { LoginStepFooter, LoginTitle } from '@routes/Login/components';
import AuthenticationService from '@services/AuthenticationService';

import { observer } from 'mobx-react-lite';

const NewLoginDetected: React.FC = observer(() => {
  const { otpLogin, otpData, setOtpData, error, getFederatedAuthType, setAuthStep, submitting, email, password } =
    useContext(AuthContext);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const federatedAuthType = getFederatedAuthType(searchParams);

  const { t } = useTranslation('login');

  useEffect(() => {
    if (code.length === 6) otpLogin('Learn', code);
  }, [code, otpLogin]);

  const onLogin = useCallback(() => otpLogin(federatedAuthType, code), [otpLogin, federatedAuthType, code]);

  const onSMSFallback = useCallback(async () => {
    if (!email || !password || !otpData) return;

    setLoading(true);

    const response = await AuthenticationService.OTPSMSFallback(email, password, otpData);

    if (response) {
      setOtpData(response);
    }

    setLoading(false);
  }, [email, otpData, password, setOtpData]);

  return (
    <>
      {loading && (
        <Stack alignItems='center' justifyContent='center' width='100%' height='100%'>
          <Loading />
        </Stack>
      )}
      {!loading && (
        <Stack flexDirection='column' width='100%' alignItems='center' height='100%' justifyContent='space-between'>
          <Stack>
            <LoginTitle
              id='newLoginDetected.title'
              title={t('newLoginDetected.title')}
              subTitle={
                otpData?.otp.challengeType === 'email'
                  ? t('newLoginDetected.subTitle')
                  : t('newLoginDetected.subTitleSMS')
              }
              subTitleDescription={
                otpData?.otp.challengeType === 'email'
                  ? t('newLoginDetected.subTitleDescription')
                  : t('newLoginDetected.subTitleDescriptionSMS')
              }
            />
            <Stack
              width='100%'
              alignItems='center'
              justifyContent='center'
              id='twoFactorLogin.form.codeInput.container'
              spacing={2}
            >
              {otpData?.otp.challengeType === 'email' && (
                <Typography color='text.secondary'>
                  <Trans
                    t={t}
                    i18nKey='newLoginDetected.subTitleSentEmail'
                    values={{ emailAddress: email }}
                    components={[
                      <Typography
                        key='newLoginDetected.subTitleSentEmail'
                        PII
                        display='inline-block'
                        marginLeft={0.25}
                        fontWeight={500}
                        color='text.primary'
                        sx={{ display: 'inline-block' }}
                      />,
                    ]}
                  />
                </Typography>
              )}

              {otpData?.otp.challengeType === 'sms' && (
                <Typography color='text.secondary' textAlign='center'>
                  {t('newLoginDetected.subTitleSentSMS')}
                </Typography>
              )}

              <CodeInput
                id='newLoginDetected.form.codeInput'
                error={error}
                length={6}
                onChange={(newCode) => setCode(newCode.join(''))}
              />
            </Stack>
          </Stack>
          <Stack width='100%'>
            {otpData?.otp.challengeType === 'email' && (
              <Typography fontSize={12} color='text.secondary' textAlign='center' marginTop={2}>
                If you haven’t received an email after a few minutes, please check your spam folder. If you still can’t
                find it,{' '}
                <Typography
                  color='primary'
                  display='inline-block'
                  fontSize={12}
                  onClick={onSMSFallback}
                  sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                >
                  click here to try another method.
                </Typography>
              </Typography>
            )}

            <LoginStepFooter
              buttonText={t('newLoginDetected.buttonLabels.submit')}
              onNext={onLogin}
              onBack={() => setAuthStep(AuthStep.SignIn)}
              buttonLoading={submitting}
              disableButton={!code}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
});

export { NewLoginDetected };

import * as React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as ProgressPrimitive from '@radix-ui/react-progress';

// TODO: wait for component figma designs to update this component
const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn('relative h-[5px] w-full overflow-hidden rounded-[2rem] bg-primary/20', className)}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className='h-full w-full flex-1 bg-color-icon-accent transition-all '
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };

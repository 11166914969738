import { useCallback, useEffect, useState } from 'react';

import { RecurringOrder } from '@shared/api/@types/recurringOrder';
import { assetService } from '@shared/services';

import { useAutoInvestPercentages } from './useAutoInvestPercentages';
import { AutoInvestAsset } from '../autoInvest.types';

type Props = {
  autoInvestment: RecurringOrder;
};

const useAutoInvestTemplate = ({ autoInvestment }: Props) => {
  const [autoInvestAssets, setAutoInvestAssets] = useState<AutoInvestAsset[]>([]);
  const { calculatePercentages } = useAutoInvestPercentages();

  const parseTemplate = useCallback(() => {
    const assets = autoInvestment.template
      ?.filter((t) => t.active)
      .map((t) => {
        const asset = assetService.getAsset(t.secondaryAssetId);
        return {
          assetCode: asset?.code || '',
          percentage: t.percentage,
          locked: false,
        };
      });

    setAutoInvestAssets(assets);
  }, [autoInvestment]);

  const convertToTemplate = useCallback(() => {
    const template: Record<number, number> = {};

    autoInvestAssets.forEach((a) => {
      const asset = assetService.getAssetByCode(a.assetCode);
      if (asset) {
        template[asset.id] = a.percentage;
      }
    });

    return template;
  }, [autoInvestAssets]);

  useEffect(() => {
    parseTemplate();
  }, [parseTemplate]);

  const onLock = (assetCode: string) => {
    setAutoInvestAssets((prev) => {
      const cache = Array.from(prev);
      const item = cache.find((c) => c.assetCode === assetCode);
      if (item) item.locked = !item.locked;

      return cache;
    });
  };

  const onRemove = (assetCode: string) => {
    setAutoInvestAssets((prev) => {
      const cache = Array.from(prev);
      const itemIndex = cache.findIndex((c) => c.assetCode === assetCode);
      if (itemIndex > -1) cache.splice(itemIndex, 1);

      return calculatePercentages(cache);
    });
  };

  const onAddAsset = useCallback(() => {
    setAutoInvestAssets((prev) => {
      const copy = Array.from(prev);
      copy.push({ assetCode: '', percentage: 0, locked: false });
      return calculatePercentages(copy);
    });
  }, [calculatePercentages]);

  const onBulkUpdateAssets = useCallback(
    (assetCodes: string[]) => {
      const reducedAssets = assetCodes.map((assetCode) => ({ assetCode, percentage: 0, locked: false }));
      const assetsWithPercentages = calculatePercentages(reducedAssets);
      setAutoInvestAssets(assetsWithPercentages);
    },
    [calculatePercentages, setAutoInvestAssets],
  );

  const onUpdatePercentage = useCallback(
    (assetCode: string, percentage: number) => {
      setAutoInvestAssets((prev) => {
        const cache = Array.from(prev);
        const item = cache.find((c) => c.assetCode === assetCode);
        if (item) item.percentage = percentage;

        return calculatePercentages(cache, item);
      });
    },
    [calculatePercentages],
  );

  const onUpdateAsset = (oldAssetCode: string, newAssetCode: string) => {
    setAutoInvestAssets((prev) => {
      const cache = Array.from(prev);
      const item = cache.find((c) => c.assetCode === oldAssetCode);
      if (item) item.assetCode = newAssetCode;

      return cache;
    });
  };

  return {
    autoInvestAssets,
    parseTemplate,
    onLock,
    onRemove,
    onUpdatePercentage,
    onAddAsset,
    onUpdateAsset,
    convertToTemplate,
    onBulkUpdateAssets,
  };
};

export { useAutoInvestTemplate };

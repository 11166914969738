import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';

import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

const TradeMultiDetailsReceive: React.FC = observer(() => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeMultiDetails' });
  const { getReceiveValue } = useUniversalTradeUtilityStore();
  const { tradeTo } = UniversalTradeStore;

  const toAsset = assetService.getAsset(tradeTo[0]);

  if (!toAsset || tradeTo.length > 1) return null;

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' paddingLeft={2} paddingRight={2}>
      <Typography fontSize={14} fontWeight={500} marginBottom={1}>
        {t('labels.receive')}
      </Typography>
      <FormattedText
        typographyProps={{ color: 'primary', size: 'large', weight: 'emphasis', className: 'mb-8' }}
        value={getReceiveValue()}
        currency={toAsset}
        formatOpts={{ appendCode: true }}
      />
    </Stack>
  );
});

TradeMultiDetailsReceive.displayName = 'TradeMultiDetailsReceive';

export { TradeMultiDetailsReceive };

import { useCallback } from 'react';

import { Big } from '@shared/safe-big';

import { AutoInvestAsset } from '../autoInvest.types';

const MAXIMUM_PERCENTAGE = 100;

const MINIMUM_PERCENTAGE = 5;

export const useAutoInvestPercentages = () => {
  const getLockedPercentages = useCallback((assets: AutoInvestAsset[], currentAsset?: AutoInvestAsset) => {
    let lockedPercentage = assets.filter((a) => a.locked).reduce((acc, a) => acc.plus(a.percentage), Big(0));
    if (currentAsset) lockedPercentage = lockedPercentage.plus(currentAsset.percentage);

    return lockedPercentage;
  }, []);

  const calculatePercentages = useCallback(
    (assets: AutoInvestAsset[], currentAsset?: AutoInvestAsset) => {
      const selectedAssets = Array.from(assets);

      const totalAssets = selectedAssets.length;

      if (totalAssets === 0) return [];
      if (totalAssets === 1) {
        selectedAssets[0].percentage = MAXIMUM_PERCENTAGE;
        return selectedAssets;
      }

      const lockedPercentageTotal = getLockedPercentages(selectedAssets, currentAsset);

      if (lockedPercentageTotal.gt(MAXIMUM_PERCENTAGE)) {
        return selectedAssets;
      }

      const unlockedAssets = selectedAssets.filter((asset) => !asset.locked);
      if (currentAsset)
        unlockedAssets.splice(
          unlockedAssets.findIndex((a) => a.assetCode === currentAsset.assetCode),
          1,
        );
      const remainingPercentage = Big(MAXIMUM_PERCENTAGE).minus(lockedPercentageTotal);
      const basePercentagePerAsset = Math.floor(remainingPercentage.div(unlockedAssets.length).toNumber());

      if (basePercentagePerAsset < MINIMUM_PERCENTAGE) {
        return selectedAssets;
      }

      let remainingAfterBaseAllocation =
        remainingPercentage.toNumber() - basePercentagePerAsset * unlockedAssets.length;

      for (const asset of unlockedAssets) {
        asset.percentage = basePercentagePerAsset;

        if (remainingAfterBaseAllocation > 0) {
          asset.percentage++;
          remainingAfterBaseAllocation--;
        }
      }

      return selectedAssets;
    },
    [getLockedPercentages],
  );

  const getMin = useCallback(
    (assets: AutoInvestAsset[]) => {
      const lockedPercentage = getLockedPercentages(assets);
      const unlockedAssets = assets.filter((a) => !a.locked);

      if (unlockedAssets.length <= 1) return Big(MAXIMUM_PERCENTAGE).minus(lockedPercentage).toNumber();

      return 5;
    },
    [getLockedPercentages],
  );

  const getMax = useCallback(
    (assets: AutoInvestAsset[]) => {
      const lockedPercentage = getLockedPercentages(assets);
      const unlockedAssets = assets.filter((a) => !a.locked);

      return Big(100)
        .minus(lockedPercentage)
        .minus(Big(unlockedAssets.length - 1).times(getMin(assets)))
        .toNumber();
    },
    [getLockedPercentages, getMin],
  );

  const getRemainingPercentage = useCallback(
    (assets: AutoInvestAsset[]) => {
      const lockedPercentage = getLockedPercentages(assets);
      const unlockedAssetsLength = assets.filter((a) => !a.locked).length;
      const unlockedPercentageAllocation = unlockedAssetsLength * MINIMUM_PERCENTAGE;

      return Big(MAXIMUM_PERCENTAGE).minus(lockedPercentage).minus(unlockedPercentageAllocation);
    },
    [getLockedPercentages],
  );

  return {
    calculatePercentages,
    getMax,
    getMin,
    getRemainingPercentage,
    minimumPercentage: MINIMUM_PERCENTAGE,
  };
};

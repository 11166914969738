import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { DarkMode, DollarInCircle, Game } from '@swyftx/aviary/icons/outlined';
import { AppHeaderMenuProfile } from '@swyftx/aviary/molecules/AppHeaderMenu/AppHeaderMenuProfile';

import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { SwyftxThemeSelector } from 'src/lib/layout';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { SwitchAccountModal } from 'src/lib/user-profile/components/SwitchAccountModal/SwitchAccountModal';
import { SwitchPlatformMenuItem } from 'src/lib/user-profile/components/SwitchPlatformMenuItem/SwitchPlatformMenuItem';
import { useDemoMode } from 'src/lib/utils/hooks';

import { SideBarMobileHeaderCurrency } from './SideBarMobileHeaderCurrency';
import { SideBarMobileHeaderSwitchItem } from './SideBarMobileHeaderSwitchItem';

const SideBarMobileHeader: React.FC = observer(() => {
  const { isDemo, setDemoMode } = useDemoMode();
  const { swyftxProEnabled } = useSwyftxPro();
  const { isEntity, isUserVerified, userProfile } = UserStore.useUserStore;
  const { setNavDrawerExpanded } = UIStore.useUIStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const midnightTheme = isFeatureEnabled(AppFeature.MidnightTheme);

  return (
    <FlexLayout direction='column' className='w-full px-8 pr-16' spacing={4}>
      <FlexLayout className='py-8' alignItems='start' justifyContent='space-between' spacing={16}>
        <AppHeaderMenuProfile
          user={userProfile}
          isUserVerified={isUserVerified()}
          isEntity={isEntity()}
          onClick={() => setNavDrawerExpanded(false)}
        />
        <SwitchAccountModal>
          <Button className='mt-8' variant='outlined' size='sm'>
            Switch account
          </Button>
        </SwitchAccountModal>
      </FlexLayout>
      <SideBarMobileHeaderCurrency title='Currency' icon={<DollarInCircle width={24} height={24} />} />
      <SideBarMobileHeaderSwitchItem
        title='Demo mode'
        icon={<Game width={24} height={24} />}
        checked={isDemo}
        onClick={() => setDemoMode(!isDemo)}
        color='warning'
      />
      <FlexLayout
        direction='row'
        spacing={24}
        alignItems='center'
        className='cursor-pointer select-none rounded-12 p-10 hover:bg-color-background-surface-hover'
      >
        <FlexLayout className='flex items-center justify-center text-color-text-primary'>
          <DarkMode />
        </FlexLayout>
        <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
          <FlexLayout direction='column'>
            <Body weight='emphasis' color='primary'>
              {midnightTheme ? 'Theme' : 'Dark mode'}
            </Body>
          </FlexLayout>
          <SwyftxThemeSelector renderOnlyElement />
        </FlexLayout>
      </FlexLayout>

      {swyftxProEnabled && <SwitchPlatformMenuItem />}
    </FlexLayout>
  );
});

export { SideBarMobileHeader };

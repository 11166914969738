import React, { useCallback, useEffect, useRef } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';

import { cn } from '@shared/utils/lib/ui';

import { useScroll } from 'react-use';

import { useSwyftxProLayout } from '../../hooks';
import { panelName, ProPanels } from '../../types/TradePro.types';

type Props = {
  selectedPanel: ProPanels;
  setSelectedPanel: React.Dispatch<React.SetStateAction<ProPanels>>;
};

const SwyftxProChartPanelHeader: React.FC<Props> = ({ selectedPanel, setSelectedPanel }) => {
  const { showOrderBook, showOrdersAndBalances } = useSwyftxProLayout();
  const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'pro-page' });
  const isXs = useTailwindBreakpoint('xs');
  const ref = useRef<HTMLDivElement>(null);
  const { x } = useScroll(ref);

  const buildButton = useCallback(
    (panel: ProPanels, className = '') => {
      const isActive = selectedPanel === panel;
      return (
        <Heading
          size='h6'
          className={cn(
            'cursor-pointer whitespace-nowrap px-12 pt-8 uppercase',
            isActive ? '' : 'hover:text-color-text-secondary',
            className,
          )}
          color={isActive && !atLeast.lg ? 'accent' : 'primary'}
          onClick={() => setSelectedPanel(panel)}
        >
          {panelName[panel]}
        </Heading>
      );
    },
    [atLeast.lg, selectedPanel, setSelectedPanel],
  );

  useEffect(() => {
    if (atLeast.lg && selectedPanel === 'orderbook') setSelectedPanel('chart');
    if (atLeast.sm && ['wallets', 'openOrders', 'orderHistory'].includes(selectedPanel)) {
      setSelectedPanel('chart');
    }
  }, [atLeast, selectedPanel, setSelectedPanel]);

  return (
    <>
      <div
        className={cn(
          'pointer-events-none absolute left-[-1px] right-[-1px] z-20 h-full bg-gradient-to-r via-transparent opacity-80',
          x <= 10 ? 'from-transparent' : 'from-color-background-surface-primary',
          ref.current && ref.current.scrollWidth - ref.current.clientWidth - ref.current.scrollLeft <= 10
            ? 'to-transparent'
            : 'to-color-background-surface-primary',
          ['openOrders', 'orderHistory'].includes(selectedPanel) ? 'w-[calc(100%-48px)]' : 'w-[calc(100%+2px)]',
        )}
      />
      <FlexLayout className='no-scrollbar min-h-[3rem] w-full overflow-auto' alignItems='center' ref={ref}>
        {buildButton('chart')}
        {(showOrderBook || isXs) && buildButton('orderbook', 'block @lg:hidden')}
        {(showOrdersAndBalances || isXs) && buildButton('wallets', 'block @sm:hidden')}
        {(showOrdersAndBalances || isXs) && buildButton('openOrders', 'block @sm:hidden')}
        {(showOrdersAndBalances || isXs) && buildButton('orderHistory', 'block @sm:hidden')}
      </FlexLayout>
    </>
  );
};

export { SwyftxProChartPanelHeader };

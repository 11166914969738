import { Box } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

const getMarqueeAnimation = (initialOffset?: number) => keyframes`
  0% { transform: translate(-${initialOffset || 0}px, 0); }
  100% { transform: translate(-100%, 0); }
`;

type AssetSummarySliderItemsContainerProps = {
  duration: string;
  initialOffset?: number;
};

export const AssetSummaryItemsMarqueeContainer = styled(Box)<AssetSummarySliderItemsContainerProps>(
  ({ duration, initialOffset }) => ({
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    boxSizing: 'border-box',

    '& > div': {
      width: 'max-content',
      paddingLeft: '100%',
      animation: `${getMarqueeAnimation(initialOffset)} ${duration} linear infinite`,
    },

    '& > div:hover': {
      'animation-play-state': 'paused',
    },
  }),
);

import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import Transactions from './Transactions';

export const TransactionsRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Transactions,
    element: <PrivateRoute element={<Transactions />} />,
  },
  {
    path: NavigationURLs.TransactionsRoot,
    element: <PrivateRoute element={<Navigate to='/transactions/orders' />} />,
  },
];

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode';

const PortfolioChartDemoMode: React.FC = () => (
  <FlexLayout
    direction='column'
    spacing={24}
    alignItems='center'
    justifyContent='center'
    className='h-full w-full rounded-[8px] border border-dashed border-color-border-main p-48'
  >
    <UnavailableDemoMode subTitle='This content is not available in demo mode' showImage />
  </FlexLayout>
);
export { PortfolioChartDemoMode };

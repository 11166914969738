import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Loading } from '@swyftx/aviary/icons/outlined';

interface TradeFeesStatusesProps {
  content: {
    icon: React.ReactNode;
    title: string;
    description?: React.ReactNode;
    progress?: any;
  }[];
  updatesIn?: string;
  loading: boolean;
}

export const TradeFeesStatuses: React.FC<TradeFeesStatusesProps> = (props) => {
  const { content, updatesIn, loading } = props;
  const { t } = useTranslation('profile');

  return (
    <FlexLayout direction='column' spacing={8}>
      <FlexLayout direction='row' className='flex items-end justify-between'>
        <Heading size='h5'>{t('feeTiers.titles.statuses')}</Heading>
        {!loading && (
          <Body size='small' color='secondary'>
            {t('feeTiers.updatesIn')} {updatesIn}
          </Body>
        )}
      </FlexLayout>
      <Card id='trade-fees-statuses-card' className='w-full'>
        <FlexLayout direction='row' className='flex-wrap p-24 '>
          {content.map((item) => (
            <FlexLayout direction='row' className='@xs:w-full @xs:py-8 @sm:w-1/3' spacing={12} key={item.title}>
              <FlexLayout className='flex h-[32px] w-[32px] items-center rounded-12 bg-color-background-primary-subtle p-8 text-color-icon-accent'>
                {item.icon}
              </FlexLayout>
              <FlexLayout direction='column' spacing={4} className='@xs:w-full'>
                <FlexLayout className='items-center justify-between'>
                  <Body size='medium' weight='emphasis'>
                    {item.title}
                  </Body>
                  {item.progress && <Body weight='emphasis'>{item.progress.nextTier}</Body>}
                </FlexLayout>
                {item.description && !loading && <Body color='secondary'>{item.description}</Body>}
                {loading && <Loading className='h-16 w-16 animate-spin text-color-text-primary' />}
              </FlexLayout>
            </FlexLayout>
          ))}
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
};

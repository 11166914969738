import env from '@shared/config';
import { RateLimitError, ReCaptchaError, SwyftxError } from '@shared/error-handler';
import { AnalyticsEvent, AnalyticsEventType, emitEvent, RequestCompleteEvent, SwyftxEvent } from '@shared/events';
import logger from '@shared/logger';
import { AppStore, UIStore, UserStore } from '@shared/store';
import { hideSensitive, isUndefined } from '@shared/utils';

import AuthenticationService from '@services/AuthenticationService';

import axios, { AxiosError, AxiosResponse, ResponseType } from 'axios';

import apiCache from './apiCache';
import { checkIgnoreError } from './errorFilter';
import generatePath from './generatePath';
import HttpStatusCode from '../HttpStatusCode';

const logTag = '[REST_API]';

export enum Methods {
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
  GET = 'get',
  PUT = 'put',
}

export interface CustomAuth {
  token?: string;
}

export interface Request<T> {
  path: string;
  data?: T; // request body
  method: Methods;
  headers?: object;
  host?: string;
  query?: object; // parameters in url query
  params?: object; // dynamic values in the path, ie. /assets/:assetId/rates
  auth?: boolean; // indicate if token should be added to request
  customAuthToken?: string; // custom auth token to fetch against a given user auth token
  demoable?: boolean; // indicate the request is available on demo mode
  paperTrading?: boolean; // force to use demo api
  responseType?: ResponseType;
  cache?: number | Function; // set amount of time in ms the response should be cached. Omitting will disable.
  resetCache?: boolean; // True to reset cached response and NO api request performed
  hideErrorToast?: boolean; // Stops the generic error toast from showing
  ignoreErrors?: boolean; // Ignore errors and do not log to console or crashlytics
}

axios.defaults.timeout = 20000; // set default timeout to 20 seconds to allow for bad connections

axios.defaults.headers.get = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

const checkIsMaintenanceMode = (error: any) => {
  const isServerError = !isUndefined(error?.response?.data?.error?.error);

  if (isServerError && axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<any>;
    const serverInMaintenance =
      axiosError.response?.status === 503 || axiosError.response?.data?.error?.error === 'MaintenanceMode';

    const appStore = AppStore.useAppStore;

    if (serverInMaintenance && !appStore.isMaintenanceMode) {
      logger.log(logTag, 'Is in maintenance mode', { ts: Date.now() });
      appStore.setIsMaintenanceMode(serverInMaintenance);
      // @ts-ignore
      appStore.setMaintenanceRetryAfter(axiosError.response?.headers('retry-after') || false);
      return true;
    }
  }

  return false;
};

const handleAxiosError = (axiosError: AxiosError<any>, ignoreErrors: Request<any>['ignoreErrors']) => {
  const { addToastMessage, clearToastMessages } = UIStore.useUIStore;
  const genericErrorMessage = axiosError.response?.data?.error?.message ?? 'Something went wrong. Please try again.';

  let error;

  const doIgnore = ignoreErrors || checkIgnoreError(axiosError);

  if (!doIgnore) {
    // emit analytics event
    emitEvent(SwyftxEvent.ApiError, axiosError);
  }

  if (axiosError.response?.data?.error?.error === 'RecaptchaError') {
    throw new ReCaptchaError(logTag, genericErrorMessage, axiosError);
  } else {
    switch (axiosError.response?.status) {
      case HttpStatusCode.TOO_MANY_REQUESTS:
        const retryTimer = axiosError.response?.headers['retry-after'] ?? '60000';
        error = new RateLimitError(parseInt(retryTimer, 10));

        const fromSwyftxUrl = axiosError.request?.responseURL?.includes('api.swyftx');
        const isSwyftxRateLimit = axiosError.response?.data?.error?.error === 'RateLimit';

        // Only render the toast message if the response is from Swyftx
        if (fromSwyftxUrl && isSwyftxRateLimit) {
          // Clear any existing toast messages in case the user hits a number of rate limited responses
          clearToastMessages();
          addToastMessage({
            message: error.errorMessage || 'You have tried that too many times. Please try again later.',
            severity: 'error',
          });
        }
        break;
      case HttpStatusCode.UNAUTHORIZED:
        AuthenticationService.Logout();
        error = new SwyftxError(logTag, genericErrorMessage, axiosError);
        break;
      default:
        error = new SwyftxError(logTag, genericErrorMessage, axiosError);
    }

    if (!ignoreErrors) {
      error.log();
    }
  }

  throw error;
};

const diagnoseServerError = (error: any, ignoreErrors: Request<any>['ignoreErrors']) => {
  // if it maintenance mode defer to its screen
  if (!checkIsMaintenanceMode(error)) {
    // attempt to extract the server error
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error as AxiosError, ignoreErrors);
    }
  }

  throw error;
};

const assembleUrl = (path: string, demoable: boolean, paperTrading: boolean) => {
  const { isDemo } = AppStore.useAppStore;

  let host;
  if (paperTrading || (demoable && isDemo)) {
    host = env.SWYFTX_DEMO_API_URL; // TODO update demo api url in env file
  } else {
    host = env.SWYFTX_API_URL;
  }

  return host + path;
};

/**
 * For POST request, below is the example to pass on the data and configs to the request.
 * @example http://localhost:3001/auth/login/:codeParam/?queryKey=queryValue
 * request({
 *    path: '/auth/login/:samplePathParam',
 *    method: 'post',
 *    data: {
 *      username: 'username',
 *      password: 'password'
 *    },
 *    headers: {
 *      captcha: '123asd'
 *    },
 *    host: 'http://localhost:3001',
 *    query: {
 *      queryKey: 'queryValue'
 *    },
 *    params: {
 *      codeParam: '2783jdh'
 *    }
 * })
 *
 * To reset cached response for specific endpoint
 * invoke the resetCache() function that is generated as a property on the request function
 */

/**
 * This function is used as a generator hence the any type
 * It only exists for typings and is manipulated at runtime
 */
export const request = <T>(rq: Request<T>): any => rq;

export const resetApiCache = (rq: Request<any>): void => {
  apiCache.resetCache(rq);
};

export const performRequest = async <T>(rq: Request<T>): Promise<AxiosResponse> => {
  // const { addToastMessage } = UIStore.useUIStore;
  const {
    path,
    method,
    data,
    host,
    query,
    params,
    auth = false,
    demoable = false,
    paperTrading = false,
    responseType = 'json',
    headers,
    cache = 0,
    // hideErrorToast = false,
    ignoreErrors = false,
    customAuthToken = '',
  } = rq;
  const formattedPath = params ? generatePath(path, params) : path;
  const url = host ? host + formattedPath : assembleUrl(formattedPath, demoable, paperTrading);

  if ([Methods.PATCH].includes(method) && !data) {
    throw new SwyftxError(
      logTag,
      'Something went wrong. Please try again.',
      new Error('Patch request is being sent without data'),
    ).log();
  }

  // check if cached
  if (cache) {
    const cached = apiCache.read(rq);
    if (!isUndefined(cached)) {
      return cached as AxiosResponse;
    }
  }

  const updatedHeaders: any = headers || {};
  updatedHeaders['swyftx-platform'] = 'web';

  if (auth) {
    const { userAuth } = UserStore.useUserStore;
    updatedHeaders.Authorization = `Bearer ${customAuthToken.length > 0 ? customAuthToken : userAuth?.access_token}`;
  }

  const formattedUrl = new URL(url);

  if (query) {
    const querySearch: string[] = Object.keys(query).map((key: string) => {
      const value = (query as any)[key];
      return `${key}=${value}`;
    });
    formattedUrl.search = `?${querySearch.join('&')}`;
  }

  try {
    const response = await axios.request({
      responseType,
      url,
      method,
      headers: { 'Cache-Control': 'no-cache', ...updatedHeaders },
      params: query,
      data,
    });

    if (cache) {
      apiCache.write(rq, response as any);
    }

    // emit analytics event
    const event: AnalyticsEvent<RequestCompleteEvent> = {
      type: AnalyticsEventType.RequestComplete,
      data: {
        path,
        method,
        data,
        host,
        query,
        params,
        response: response.data,
      },
    };
    const sanitisedEvent = hideSensitive(event);
    emitEvent(SwyftxEvent.Analytics, sanitisedEvent);

    return response;
  } catch (ex: any) {
    // TODO - Removing for now as we are seeing too many errors, will need to reenable once its more stable
    // if (!hideErrorToast) {
    //   addToastMessage({ severity: 'error', message: ex?.message || 'Something went wrong' });
    // }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return diagnoseServerError(ex, ignoreErrors);
  }
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeStepLine: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 5.5C15 5.08579 15.3358 4.75 15.75 4.75H18.875C19.2892 4.75 19.625 5.08579 19.625 5.5C19.625 5.91421 19.2892 6.25 18.875 6.25H16.5V14.8125C16.5 15.2267 16.1642 15.5625 15.75 15.5625H11.9375C11.5233 15.5625 11.1875 15.2267 11.1875 14.8125V10.5H9.4375V18.5C9.4375 18.9142 9.10171 19.25 8.6875 19.25H5.125C4.71079 19.25 4.375 18.9142 4.375 18.5C4.375 18.0858 4.71079 17.75 5.125 17.75H7.9375V9.75C7.9375 9.33579 8.27329 9 8.6875 9H11.9375C12.3517 9 12.6875 9.33579 12.6875 9.75V14.0625H15V5.5Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeStepLine };

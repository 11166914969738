import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { TickInCircleFilled } from '@swyftx/aviary/icons/filled';

import { UserProfile } from '@shared/api';
import { initServices } from '@shared/services';
import storage, { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { RegisterContext } from '@routes/Register/Register.context';

import { RegisterTranslationKey } from '@Register/translations';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const logTag = '[WELCOME]';

const Welcome: React.FC = observer(() => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation(RegisterTranslationKey);
  const { setUserProfile, setAuth } = UserStore.useUserStore;
  const { cleanup } = useContext(RegisterContext);

  const setupUserProfileAuth = async () => {
    cleanup();

    // Updating the user profile will trigger navigation to the dashboard
    const userData = await storage.getItem(StorageKey.USER_DATA);
    if (userData) {
      const data = JSON.parse(userData) as UserProfile;
      setAuth(data.auth);
      initServices(logTag);
      setUserProfile(data.profile);
    }
  };

  const goToDashboard = async () => {
    await setupUserProfileAuth();
    navigate(NavigationURLs.Dashboard);
  };

  const goToVerification = async () => {
    await setupUserProfileAuth();
    navigate(NavigationURLs.Onboarding);
  };

  return (
    <FlexLayout direction='column' className='h-full w-full' alignItems='center' justifyContent='center'>
      <TickInCircleFilled className='mt-16 h-[75px] w-[75px] text-color-text-accent' />
      <Heading size='h2'>Welcome to Swyftx</Heading>
      <Body id='welcome.labels.subTitle' className='my-24 w-full text-center' color='primary'>
        {t('welcome.subTitle')}
      </Body>

      <Heading id='welcome.labels.verify' className='mb-24 text-center' size='h4'>
        {t('welcome.labels.verify')}
      </Heading>

      <FlexLayout
        direction='column'
        alignItems='center'
        justifyContent='center'
        id='welcome.verifyFeatures.container'
        spacing={8}
        className='mb-24'
      >
        <Body id='welcome.labels.depositWithdrawFunds' className='w-full text-center' color='primary'>
          <li>{t('welcome.labels.depositWithdrawFunds')}</li>
        </Body>

        <Body id='welcome.labels.buySellSwapAssets' className='w-full text-center' color='primary'>
          <li>{t('welcome.labels.buySellSwapAssets')}</li>
        </Body>
      </FlexLayout>
      <FlexLayout direction='column' className='w-full p-8' spacing={16}>
        <Button
          onClick={goToVerification}
          id='welcome.buttons.startVerification'
          variant='filled'
          color='accent'
          size='lg'
          className='w-full'
        >
          {t('welcome.buttonLabels.startVerification')}
        </Button>
        <Button
          id='welcome.buttons.toDashboard'
          color='primary'
          variant='outlined'
          size='lg'
          onClick={goToDashboard}
          className='w-full'
        >
          {t('welcome.buttonLabels.toDashboard')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
});

Welcome.displayName = 'Welcome';

export { Welcome };

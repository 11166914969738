import React, { useCallback, useEffect } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { formatCurrency } from '@shared/utils';

import { useSwyftxProTradeAssets } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradePrice } from 'src/lib/trade-pro/hooks/useSwyftxProTradePrice';
import {
  useSwyftxProTradeTakeProfit,
  useSwyftxProTradeTakeProfitDetails,
} from 'src/lib/trade-pro/hooks/useSwyftxProTradeTakeProfit';
import { MarketOcoTab } from 'src/lib/trade-pro/types/TradePro.types';

import { useSecondaryOcoTabs } from './SwyftxProMarketOCO/useSecondaryOcoTabs';

type Props = {
  disabled?: boolean;
  type?: MarketOcoTab;
  showPrefillOptions?: boolean;
};

const SwyftxProTradeTakeProfitInput: React.FC<Props> = ({ disabled, type = 'price', showPrefillOptions }) => {
  const { baseAsset, selectedAsset } = useSwyftxProTradeAssets();
  const { value, setDirty, leadingText, leadingTooltip, trailingText, onChangeValue, resetValues } =
    useSwyftxProTradeTakeProfit({
      tabType: type,
    });
  const { takeProfitError } = useSwyftxProTradeTakeProfitDetails();
  const { secondaryTabs, selectedSecondaryTabs, setSelectedSecondaryTabs } = useSecondaryOcoTabs({ type });

  const { triggerPrice } = useSwyftxProTradePrice();

  useEffect(() => {
    resetValues();
    setSelectedSecondaryTabs(undefined);
  }, [resetValues, setSelectedSecondaryTabs, type]);

  const onSelectPrefillOption = useCallback(
    (prefillOption: string) => {
      setSelectedSecondaryTabs(prefillOption);
      onChangeValue(prefillOption);
    },
    [onChangeValue, setSelectedSecondaryTabs],
  );

  const onValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(e.target.value);
      setSelectedSecondaryTabs(undefined);
    },
    [onChangeValue, setSelectedSecondaryTabs],
  );

  return (
    <FlexLayout direction='column' spacing={8}>
      <Input
        containerClassName='bg-color-background-surface-primary hover:bg-color-background-surface-primary focus-within:!bg-color-background-surface-primary hover:border-color-border-hover border-color-border-main border'
        className='text-right font-mono text-color-text-primary'
        error={takeProfitError}
        disabled={disabled}
        onFocus={() => setDirty(true)}
        leading={
          <Tooltip title={leadingTooltip}>
            <Body
              weight='emphasis'
              className='pointer-events-auto cursor-default whitespace-nowrap uppercase'
              size='medium'
              color='primary'
            >
              {leadingText}
            </Body>
          </Tooltip>
        }
        placeholder='0.00'
        value={value}
        onChange={onValueChange}
        trailing={
          <Numeric size='small' color='primary' className='whitespace-nowrap'>
            {trailingText}
          </Numeric>
        }
      />

      {triggerPrice && takeProfitError && (
        <div>
          <Body color='error' size='small' className='inline'>
            Take profit price must be higher than the current price:{' '}
          </Body>
          <Body
            color='error'
            size='small'
            className='inline cursor-pointer underline'
            onClick={() => onChangeValue(triggerPrice)}
          >
            {formatCurrency(triggerPrice, baseAsset, { hideCode: true, appendCode: false })} {baseAsset?.code}/
            {selectedAsset?.code}
          </Body>
        </div>
      )}
      {showPrefillOptions && secondaryTabs && (
        <EnhancedTabs
          variant='child'
          size='sm'
          tabs={secondaryTabs}
          onChange={onSelectPrefillOption}
          value={selectedSecondaryTabs}
        />
      )}
    </FlexLayout>
  );
};

export { SwyftxProTradeTakeProfitInput };

import React, { useState, useMemo, useEffect } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

interface Props {
  placeholder?: string;
  value?: string;
  onChange: (value?: string) => void;
  items: string[];
}

export const InputDropdown: React.FC<Props> = (props) => {
  const { placeholder, value, onChange, items } = props;
  const [search, setSearch] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const searchItems = useMemo(
    () => items.filter((item) => item.toLowerCase().includes(search.toLowerCase())).slice(0, 10),
    [items, search],
  );

  useEffect(() => {
    if (search.length > 0 && searchItems.length > 0) {
      setShowDropdown(true);
    }
    if (search.length === 0 || searchItems.length === 0) {
      setShowDropdown(false);
    }
  }, [search.length, searchItems.length]);

  return (
    <>
      <FlexLayout direction='column' className='full-width relative'>
        <FlexLayout direction='column' className='w-[100%]' spacing={8}>
          {!value && (
            <Input
              value={search.length > 0 ? search : value}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={placeholder}
              className='p-4'
            />
          )}
        </FlexLayout>
        {value && (
          <FlexLayout direction='row' className='w-[100%] rounded-[8px] bg-color-background-surface-secondary'>
            <FlexLayout direction='row' className='w-[100%] p-16'>
              <Body className='select-none'>{value}</Body>
            </FlexLayout>
            <FlexLayout className='m-12 w-[30px] cursor-pointer items-center justify-center rounded-[100%] px-16 hover:bg-color-background-neutral-hover'>
              <button type='button' onClick={() => onChange(undefined)}>
                <Cross />
              </button>
            </FlexLayout>
          </FlexLayout>
        )}
        {showDropdown && (
          <Card className='absolute z-20 mt-[52px] w-[100%] overflow-y-hidden shadow-md'>
            <FlexLayout direction='column' className='max-h-[300px] overflow-y-auto'>
              {searchItems.map((item, index) => (
                <>
                  {index > 0 && <Separator />}
                  <FlexLayout
                    key={item}
                    direction='row'
                    className='flex cursor-pointer items-center p-16 hover:bg-color-background-surface-hover'
                    spacing={16}
                    onClick={() => {
                      onChange(item);
                      setSearch('');
                      setShowDropdown(false);
                    }}
                  >
                    <Body size='small' className='select-none'>
                      {item}
                    </Body>
                  </FlexLayout>
                </>
              ))}
            </FlexLayout>
          </Card>
        )}
      </FlexLayout>
      {showDropdown && (
        <div className='absolute left-0 top-0 z-10 h-[100%] w-[100%]' onClick={() => setShowDropdown(false)} />
      )}
    </>
  );
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { SwyftxFull } from '@swyftx/aviary/icons/outlined';

type Props = {
  id: string;
  title: string;
  subTitle?: string;
  subTitleDescription?: string;
};

const LoginTitle: React.FC<Props> = ({ id, title, subTitle, subTitleDescription }) => (
  <FlexLayout
    direction='column'
    id={`${id}.container`}
    spacing={24}
    className='items-center sm:items-start'
    alignItems='start'
    justifyContent='center'
  >
    <SwyftxFull className='mt-8 block h-32 sm:hidden' />

    <Heading id={`${id}.title`} size='h2'>
      {title}
    </Heading>

    {subTitle ? (
      <Heading id={`${id}.subTitle`} className='w-full text-center' color='primary' size='h3'>
        {subTitle}
      </Heading>
    ) : null}

    {subTitleDescription ? (
      <Body id={`${id}.subTitleDescription`} className='mb-24 w-full text-center' color='secondary'>
        {subTitleDescription}
      </Body>
    ) : null}
  </FlexLayout>
);

LoginTitle.displayName = 'LoginTitle';

export { LoginTitle };

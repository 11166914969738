import * as Sentry from '@sentry/react';
import Big, { BigSource } from 'big.js';

import { SafeBig } from './@types';

Big.DP = 10;

/** Overrides Big.cmp to fallback to 0 on error */
function safeCmp(this: any, n: BigSource) {
  return this.unsafeCmp(safeBig(n));
}

Big.prototype.unsafeCmp = Big.prototype.cmp;
Big.prototype.cmp = safeCmp;

/** Overrides Big.div to divide by 1 if it would have caused an error */
function safeDiv(this: any, n: BigSource) {
  let divider = Big(1);
  try {
    const parsed = Big(n);
    if (!parsed.eq(0)) divider = parsed;
  } catch (e) {
    Sentry.captureException(e);
  }
  return this.unsafeDiv(divider);
}

Big.prototype.unsafeDiv = Big.prototype.div;
Big.prototype.div = safeDiv;

/** Overrides Big.eq to fallback to 0 on error */
function safeEq(this: any, n: BigSource) {
  return this.unsafeEq(safeBig(n));
}

Big.prototype.unsafeEq = Big.prototype.eq;
Big.prototype.eq = safeEq;

/** Overrides Big.gt to fallback to 0 on error */
function safeGt(this: any, n: BigSource) {
  return this.unsafeGt(safeBig(n));
}

Big.prototype.unsafeGt = Big.prototype.gt;
Big.prototype.gt = safeGt;

/** Overrides Big.gte to fallback to 0 on error */
function safeGte(this: any, n: BigSource) {
  return this.unsafeGte(safeBig(n));
}

Big.prototype.unsafeGte = Big.prototype.gte;
Big.prototype.gte = safeGte;

/** Overrides Big.gt to fallback to 0 on error */
function safeLt(this: any, n: BigSource) {
  return this.unsafeLt(safeBig(n));
}

Big.prototype.unsafeLt = Big.prototype.lt;
Big.prototype.lt = safeLt;

/** Overrides Big.lte to fallback to 0 on error */
function safeLte(this: any, n: BigSource) {
  return this.unsafeLte(safeBig(n));
}

Big.prototype.unsafeLte = Big.prototype.lte;
Big.prototype.lte = safeLte;

/** Overrides Big.minus to fallback to 0 on error */
function safeMinus(this: any, n: BigSource) {
  return this.unsafeMinus(safeBig(n));
}

Big.prototype.unsafeMinus = Big.prototype.minus;
Big.prototype.minus = safeMinus;
Big.prototype.sub = safeMinus;

/** Overrides Big.mod to fallback to 1 on error */
function safeMod(this: any, n: BigSource) {
  let rhs = tryParseBig(n) || Big(1);
  return this.unsafeMod(rhs);
}

Big.prototype.unsafeMod = Big.prototype.mod;
Big.prototype.mod = safeMod;

/** Overrides Big.plus to fallback to 0 on error */
function safePlus(this: any, n: BigSource) {
  return this.unsafePlus(safeBig(n));
}

Big.prototype.unsafePlus = Big.prototype.plus;
Big.prototype.plus = safePlus;
Big.prototype.add = safePlus;

/** Overrides Big.pow to fallback to 1 on error */
function safePow(this: any, n: BigSource) {
  let rhs = tryParseBig(n) || Big(1);
  return this.unsafePow(rhs.toNumber());
}

Big.prototype.unsafePow = Big.prototype.pow;
Big.prototype.pow = safePow;

/** Overrides Big.mod to fallback to 1 on error */
function safeTimes(this: any, n: BigSource) {
  let rhs = tryParseBig(n) || Big(1);
  return this.unsafeTimes(rhs);
}

Big.prototype.unsafeTimes = Big.prototype.times;
Big.prototype.times = safeTimes;
Big.prototype.mul = safeTimes;

/** Attempts to convert the input to a Big. Returns `undefined` on failure. */
export function tryParseBig(value?: BigSource): SafeBig | undefined {
  try {
    return Big(value ?? '');
  } catch (ex) {
    return undefined;
  }
}

/** Attempts to convert the input to a Big if fails defaults to Big(0). */
export const safeBig = (value?: BigSource): SafeBig => {
  let result: Big;
  try {
    result = Big(value ?? '0');
  } catch (ex) {
    result = Big('0');
  }
  return result;
};

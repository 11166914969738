import { ModalNameValueType, ModalName } from 'src/context/Avo/generated-avo';

/**
 * NOTE: that these names should match the modal names in Avo
 * @see https://www.avo.app/schemas/6POyHovCvydDkrmCrKkd/p/NO9CObS3y
 *
 * Ensure that the mapping below is updated
 */
export enum Modals {
  ChangePassword = 'change-password',
  DemoModeLearnMore = 'demo-learn-more',
  EditTriggerOrderAnnouncement = 'edit-trigger-order-announcement',
  DisableTwoFactor = 'disable-two-factor',
  EditTriggerOrders = 'edit-trigger-orders',
  EnableTwoFactor = 'enable-two-factor',
  SMSRecovery = 'sms-recovery',
  UpdateEmailAddress = 'update-email',
  UpdatePhone = 'update-phone',
  VerifyPhone = 'verify-phone',
  WhatsNewModal = 'whats-new-modal',
  OrderDetails = 'order-details',
  OTCTradesPermissionModal = 'otc-trades-permission-modal',
  TransactionDetails = 'transaction-details',
  DepositReceive = 'deposit-receive',
  WithdrawSend = 'withdraw-send',
  RestrictedModal = 'restricted',
  PriceAlerts = 'price-alerts',
  StripeCardDeposit = 'stripe-card-deposit',
  AffiliateSignUp = 'affiliate-sign-up',
  DustingModal = 'dusting',
  NextDustTimeModal = 'next-dust-time',
  HighSlippageReviewModal = 'high-slippage-review-modal',
  ProfitStopLossModal = 'profit-stop-loss-modal',
  ProfitStopLossSuccessModal = 'profit-loss-success-modal',
  AboutCategory = 'about-category',
  MultiTradeLimitation = 'multi-trade-limitation',
  DepositAddressChangesModal = 'deposit-address-changes',

  /* Rewards */
  RedeemSwyftxReward = 'redeem-swyftx-reward',
  RedeemSwyftxEntry = 'redeem-swyftx-entry',
  SwitchAccount = 'switch-account',

  DashboardLayoutSelector = 'dashboard-layout-selector',
  GlobalSearch = 'global-search',
  LoanPostApplicationPopup = 'loan-post-application-popup',
}

export const modalToModalNameValueTypeMap: Record<Modals, ModalNameValueType> = {
  [Modals.ChangePassword]: ModalName['CHANGE_PASSWORD'],
  [Modals.DemoModeLearnMore]: ModalName['DEMO_MODE_LEARN_MORE'],
  [Modals.EditTriggerOrderAnnouncement]: ModalName['EDIT_TRIGGER_ORDER_ANNOUNCEMENT'],
  [Modals.DisableTwoFactor]: ModalName['DISABLE_TWO_FACTOR_AUTHENTICATION'],
  [Modals.EditTriggerOrders]: ModalName['EDIT_TRIGGER_ORDERS'],
  [Modals.EnableTwoFactor]: ModalName['ENABLE_TWO_FACTOR_AUTHENTICATION'],
  [Modals.SMSRecovery]: ModalName['SMS_RECOVERY'],
  [Modals.UpdateEmailAddress]: ModalName['UPDATE_EMAIL_ADDRESS'],
  [Modals.UpdatePhone]: ModalName['UPDATE_PHONE_NUMBER'],
  [Modals.VerifyPhone]: ModalName['VERIFY_PHONE_NUMBER'],
  [Modals.WhatsNewModal]: ModalName['WHATS_NEW'],
  [Modals.OrderDetails]: ModalName['ORDER_DETAILS'],
  [Modals.OTCTradesPermissionModal]: ModalName['OTC_TRADES_PERMISSION'],
  [Modals.TransactionDetails]: ModalName['TRANSACTION_DETAILS'],
  [Modals.DepositReceive]: ModalName['DEPOSIT_MODAL'],
  [Modals.WithdrawSend]: ModalName['WITHDRAWAL_MODAL'],
  [Modals.RestrictedModal]: ModalName['RESTRICTED_ACCESS'],
  [Modals.PriceAlerts]: ModalName['PRICE_ALERTS'],
  [Modals.StripeCardDeposit]: ModalName['STRIPE_CARD_DEPOSIT'],
  [Modals.AffiliateSignUp]: ModalName['AFFILIATE_SIGN_UP'],
  [Modals.DustingModal]: ModalName['DUSTING'],
  [Modals.NextDustTimeModal]: ModalName['NEXT_DUST_TIME'],
  [Modals.HighSlippageReviewModal]: ModalName['HIGH_SLIPPAGE_REVIEW'],
  [Modals.ProfitStopLossModal]: ModalName['PROFIT_STOP_LOSS'],
  [Modals.ProfitStopLossSuccessModal]: ModalName['PROFIT_STOP_LOSS_SUCCESS'],
  [Modals.AboutCategory]: ModalName['ABOUT_CATEGORY'],
  [Modals.MultiTradeLimitation]: ModalName['MULTI_TRADE_LIMITATION'],
  [Modals.DepositAddressChangesModal]: ModalName['DEPOSIT_ADDRESS_CHANGES'],
  [Modals.RedeemSwyftxReward]: ModalName['REDEEM_SWYFTX_REWARD'],
  [Modals.RedeemSwyftxEntry]: ModalName['REDEEM_SWYFTX_ENTRY'],
  [Modals.SwitchAccount]: ModalName['SWITCH_ACCOUNT'],
  [Modals.DashboardLayoutSelector]: ModalName['DASHBOARD_LAYOUT_SELECTOR'],
  [Modals.GlobalSearch]: ModalName['GLOBAL_SEARCH'],
  [Modals.LoanPostApplicationPopup]: ModalName['LOAN_POST_APPLICATION_POPUP'],
};

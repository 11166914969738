import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Dashboard } from '@swyftx/aviary/icons/outlined';
import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { NavigationURLs } from 'src/lib/navigation/types';

const useDashboardLayoutTour = (): TourGuideData => {
  const { t } = useTranslation('featureDiscovery', { keyPrefix: 'tours' });
  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const isSmallScreen = useMemo(() => isXs || isSm, [isSm, isXs]);

  return useMemo(
    () => ({
      icon: <Dashboard className='h-28 w-28 text-color-text-primary' />,
      name: t('dashboardLayout.name'),
      description: t('dashboardLayout.description'),
      startRoute: NavigationURLs.Dashboard,
      steps: [
        {
          title: t('dashboardLayout.steps.1.title'),
          description: t('dashboardLayout.steps.1.content'),
          image: 'layout_thumbnail',
          spotlightElementId: `change-layout-button${isSmallScreen ? '-xs' : ''}`,
          alignment: isSmallScreen ? 'center' : 'right',
          customSpotlightStyles: 'border-radius: 12px',
        },
      ],
    }),
    [t, isSmallScreen],
  );
};

export { useDashboardLayoutTour };

import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { observer } from 'mobx-react-lite';
import { useNavigateExitIntent } from 'src/lib/navigation/hooks/useNavigateExitIntent';

import { AutoInvestCardHeader, AutoInvestReviewContent } from '../components';
import { useAutoInvestReview } from '../hooks/useAutoInvestReview';

const title = 'Step 3: Review order';

const AutoInvestWidgetReview: React.FC = observer(() => {
  const {
    selectedAutoInvestAssets,
    selectedPaymentMethod,
    frequency,
    amount,
    feeAmount,
    lowestFeeAmount,
    startDate,
    showBalanceWarning,
    diffDayText,
    setCurrentStep,
    createAutoInvestOrder,
    onCancel,
    orderName,
    diffDays,
  } = useAutoInvestReview();
  const [loading, setLoading] = useState<boolean>(false);
  const { initExitIntent } = useNavigateExitIntent();

  const handleOnBack = () => {
    setCurrentStep('orderPreferences');
  };

  const onSubmit = useCallback(() => {
    setLoading(true);
    createAutoInvestOrder();
  }, [createAutoInvestOrder]);

  useEffect(() => {
    initExitIntent(
      'Discard changes?',
      'Are you sure you want to discard the changes you’ve made to this order?',
      'Order review',
      'Auto Invest',
    );
  }, [initExitIntent]);

  return (
    <FlexLayout
      direction='column'
      spacing={8}
      alignItems='center'
      justifyContent='space-between'
      className='h-full w-full pt-16 sm:pt-0'
    >
      <FlexLayout
        direction='column'
        className='h-full w-full p-16 pt-0 sm:p-24'
        spacing={8}
        alignItems='center'
        justifyContent='space-between'
      >
        <AutoInvestCardHeader title={title} onBack={handleOnBack} />
        <AutoInvestReviewContent
          selectedAutoInvestAssets={selectedAutoInvestAssets}
          orderName={orderName}
          frequency={frequency}
          amount={amount}
          feeAmount={feeAmount}
          startDate={startDate}
          lowestFeeAmount={lowestFeeAmount}
          selectedPaymentMethod={selectedPaymentMethod}
          diffDayText={diffDayText}
          diffDays={diffDays}
          showBalanceWarning={showBalanceWarning}
        />
        <FlexLayout direction='column' className='mt-24 w-full sm:mt-0' spacing={12}>
          <Button size='lg' className='w-full' onClick={onSubmit} disabled={false} loading={loading}>
            Create order
          </Button>
          <Button variant='outlined' size='lg' className='w-full' onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AutoInvestWidgetReview };

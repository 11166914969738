import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteObject } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';

import PrivateRoute from '@routes/PrivateRoute';

import { SwyftxAppShellCustomHeader } from 'src/lib/layout';
import { NavigationURLs } from 'src/lib/navigation/types';

import { PasswordResetContext, PasswordResetContextProvider } from './Context/PasswordResetContext';
import { PasswordResetContainer } from './PasswordReset';

const InnerForcePasswordResetRoute: React.FC = () => {
  const { t } = useTranslation('login');
  const context = useContext(PasswordResetContext);
  if (!context) {
    return null;
  }
  const { signOut } = context;
  return (
    <SwyftxAppShellCustomHeader
      headerItemOverride={
        <Button color='subtle' variant='filled' onClick={signOut}>
          {t('forcePasswordReset.exit')}
        </Button>
      }
    >
      <PrivateRoute element={<PasswordResetContainer />} />
    </SwyftxAppShellCustomHeader>
  );
};

export const ForcePasswordResetRoute: React.FC = () => (
  <PasswordResetContextProvider>
    <InnerForcePasswordResetRoute />
  </PasswordResetContextProvider>
);

export const ForcePasswordResetRoutes: RouteObject[] = [
  {
    path: NavigationURLs.PasswordReset,
    element: <ForcePasswordResetRoute />,
  },
];

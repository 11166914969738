import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Filter: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.6727 6.13721H4.46973C4.07227 6.13721 3.75 5.81445 3.75 5.41699C3.75 5.01953 4.07227 4.69678 4.46973 4.69678H12.6747C12.8016 4.21924 13.0416 3.78266 13.3994 3.42969C13.9307 2.90527 14.6475 2.63867 15.3799 2.625C16.6609 2.63354 17.7274 3.5144 18.0463 4.69679H20.4648C20.8623 4.69679 21.1846 5.01954 21.1846 5.417C21.1846 5.81446 20.8623 6.13721 20.4648 6.13721H18.0479C17.7272 7.32819 16.6475 8.20996 15.3603 8.20996H15.3418C14.0595 8.20545 12.9888 7.32306 12.6727 6.13721ZM15.3613 4.06494C15.0029 4.06494 14.666 4.20313 14.4111 4.45459C14.1533 4.70849 14.0108 5.04737 14.0078 5.4087C14.0059 6.16163 14.6074 6.7671 15.3496 6.76954L15.3584 6.77003C16.1 6.77003 16.7036 6.1717 16.713 5.43171C16.7129 5.42917 16.7122 5.42683 16.7115 5.42449C16.7107 5.42207 16.71 5.41965 16.71 5.417L16.711 5.41202L16.712 5.40717C16.7048 4.67047 16.1084 4.06976 15.3701 4.06543C15.3672 4.06494 15.3643 4.06494 15.3613 4.06494Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.46973 12.7075H6.60028C6.72363 13.1732 6.95599 13.6042 7.30469 13.9556C7.8291 14.4834 8.52832 14.7754 9.26953 14.7783H9.28808C10.5745 14.7783 11.6535 13.8976 11.9749 12.7075H20.4658C20.8633 12.7075 21.1856 12.3848 21.1856 11.9873C21.1856 11.5898 20.8633 11.2671 20.4658 11.2671H11.9747C11.6567 10.0836 10.5896 9.2019 9.30762 9.19336H9.28711C8.54883 9.19336 7.85352 9.47852 7.32715 9.99756C6.96869 10.3511 6.72852 10.7885 6.60175 11.2671H4.46973C4.07227 11.2671 3.75 11.5898 3.75 11.9873C3.75 12.3848 4.07227 12.7075 4.46973 12.7075ZM8.33887 11.0229C8.59375 10.7715 8.92969 10.6333 9.28809 10.6333C9.29102 10.6333 9.29493 10.6333 9.29786 10.6338C10.043 10.6382 10.6455 11.249 10.6416 11.9951C10.6348 12.7378 10.0293 13.3384 9.28614 13.3384L9.27735 13.3379C8.53516 13.3354 7.9336 12.73 7.93555 11.9761C7.93848 11.6152 8.08106 11.2764 8.33887 11.0229Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.4648 17.8335H18.4351C18.1165 16.6508 17.0498 15.7698 15.7686 15.7612H15.75C14.4659 15.7612 13.3885 16.6384 13.0651 17.8335H4.46973C4.07227 17.8335 3.75 18.1562 3.75 18.5537C3.75 18.9512 4.07227 19.2739 4.46973 19.2739H13.0615C13.3779 20.4595 14.4484 21.3417 15.7305 21.3462H15.749C17.036 21.3462 18.1156 20.4647 18.4364 19.2739H20.4648C20.8623 19.2739 21.1846 18.9512 21.1846 18.5537C21.1846 18.1562 20.8623 17.8335 20.4648 17.8335ZM15.7471 19.9062L15.7383 19.9058C14.9961 19.9033 14.3945 19.2978 14.3965 18.5449C14.4014 17.8022 15.0078 17.2012 15.75 17.2012C15.7529 17.2012 15.7559 17.2012 15.7588 17.2017C16.5039 17.2061 17.1064 17.8174 17.1025 18.5635C17.0957 19.3057 16.4902 19.9062 15.7471 19.9062Z'
      fill='currentColor'
    />
  </svg>
);

export { Filter };

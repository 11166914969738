import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}

export const CancelEntityApplicationModal: React.FC<Props> = (props) => {
  const { open, setOpen, onConfirm } = props;
  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      title='Are you sure?'
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={24} className='p-24 pt-0'>
        <Body color='secondary'>Are you sure you want to cancel your entity application?</Body>
        <Body color='secondary'>All saved information will be deleted and is not recoverable.</Body>
        <FlexLayout direction='row' className='w-full justify-end' spacing={8}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Back
          </Button>
          <Button color='destructive' onClick={onConfirm} type='submit'>
            Cancel application
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

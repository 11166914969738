import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

type Props = {
  className?: string;
  amount: string;
  assetCode?: string;
};

const TransactionsAmount: React.FC<Props> = ({ className, amount, assetCode }) => (
  <FlexLayout justifyContent='end' spacing={8} alignItems='center' className={className}>
    <Numeric className='truncate font-600 @md:font-400'>{amount}</Numeric>
    <AssetIcon asset={assetCode} size={16} />
  </FlexLayout>
);

export { TransactionsAmount };

import env from '@shared/config';

import axios from 'axios';

// TODO: Add a CI test to check if all links are valid: https://www.npmjs.com/package/linkinator
const Links = {
  help: {
    home: 'https://support.swyftx.com/hc/en-au',
    earn: 'https://support.swyftx.com/hc/en-au/articles/15616234370457-Earn-program',
    payID: 'https://support.swyftx.com/hc/en-au/articles/12394268523033-PayID-deposits',
    bundles: 'https://support.swyftx.com/hc/en-au/articles/13043890154137-Cryptocurrency-bundles',
    mfa: 'https://support.swyftx.com/hc/en-au/articles/12807078449305-Set-up-two-factor-authentication',
    securityBestPracticesHelper:
      'https://support.swyftx.com/hc/en-au/articles/13306450184089-Swyftx-safety-and-security',
    networkFees: 'https://support.swyftx.com/hc/en-au/articles/13791305429401-Network-fees',
    understandingTaxReport: 'https://support.swyftx.com/hc/en-au/articles/13718823588505',
    dueDiligence:
      'https://support.swyftx.com/hc/en-au/articles/14377979566105-Swyftx-s-regulation-and-verification-requirements',
    lowLiquidityAndLimited:
      'https://support.swyftx.com/hc/en-au/articles/17446876861721-Low-liquidity-and-limited-functionality',
    orderTypes: 'https://support.swyftx.com/hc/en-au/articles/12848920881177-Swyftx-order-types',
    taxAndFinancial: 'https://support.swyftx.com/hc/en-au/sections/11857306857369-Tax-and-financial',
    apiKeys: 'https://support.swyftx.com/hc/en-au/articles/14254548716185-Create-an-API-Key',
    feeTiers: 'https://support.swyftx.com/hc/en-au/articles/21386258633753',
  },
  social: {
    telegram: 'https://t.me/swyftx',
    twitter: 'https://twitter.com/swyftxau',
    facebook: 'https://www.facebook.com/swyftx',
    instagram: 'https://www.instagram.com/swyftx/',
    reddit: 'https://www.reddit.com/r/Swyftx/',
  },
  legal: {
    tos: 'https://swyftx.com/au/terms-of-use/',
    legal: 'https://swyftx.com/au/legal/',
    privacyPolicy: 'https://swyftx.com/au/privacy-policy/',
    riskDisclosure: 'https://swyftx.com/wp-content/uploads/2022/04/Swyftx-Risk-Disclosure-Statement.pdf',
  },
  forms: {
    entityApplicationForm: 'https://swyftxforms.formstack.com/forms/entity_application_form',
    manualKYC1: 'https://swyftxforms.formstack.com/forms/swyftx_kyc_1',
    fundsVerificationForm: 'https://swyftxforms.formstack.com/forms/swyftx_kyc_2_individual',
    DCARegisterInterestForm: 'https://swyftxforms.formstack.com/forms/register_interest?user_id=',
    submitSuggestionsDCATool: 'https://swyftxforms.formstack.com/forms/submit_suggestions_dca_tool?user_id=',
  },
  apps: {
    appStoreLink: 'https://apps.apple.com/au/app/swyftx-buy-sell-crypto/id1516986805',
    playStoreLink: 'https://play.google.com/store/apps/details?id=au.com.swyftx&hl=en_US&gl=US',
  },
  meta: {
    learn: 'https://learn.swyftx.com/',
    support: 'https://support.swyftx.com/hc/en-au',
    status: 'https://swyftx.statuspage.io',
    home: 'https://swyftx.com',
    trade: env.SWYFTX_APP_URL,
    swyftxApiDoc: 'https://docs.swyftx.com.au/',
    apiDocumentation: 'https://swyftx.docs.apiary.io',
  },
  partners: {
    koinly: 'https://koinly.io/au/?utm_source=swyftx.com&via=SWYF20',
    cryptoTax: 'https://cryptotaxcalculator.io/?via=swyftx',
    koinlyNZ: 'https://koinly.io/?via=SWYF20',
    cryptoTaxNZ: 'https://cryptotaxcalculator.io/?via=swyftx',
    finder: 'https://www.finder.com.au/',
    firefoxMonitor: 'https://monitor.firefox.com/',
    coingecko: 'https://www.coingecko.com',
    austracRegulations:
      'https://www.austrac.gov.au/business/how-comply-and-report-guidance-and-resources/customer-identification-and-verification/customer-identification-and-due-diligence-overview',
  },
  blog: {
    taxGuide: 'https://swyftx.com/au/blog/crypto-tax-australia-guide/',
    christmasCampaign2023: 'https://swyftx.com/news/5-days-of-christmas-giveaways/',
    aBoldNewEra: 'https://swyftx.com/au/blog/a-bold-new-era-for-swyftx/',
  },
  news: {
    earnClosure: 'https://swyftx.com/news/earn-program-closure/',
  },
  learn: {
    root: 'https://learn.swyftx.com',
    security: 'https://learn.swyftx.com/security/',
    highSlippage: 'https://learn.swyftx.com/trading-and-analysis/what-is-slippage-crypto/',
    guideToStaking: 'https://learn.swyftx.com/defi/guide-to-crypto-staking/',
    decentralizedFinance: 'https://learn.swyftx.com/courses/decentralized-finance-defi/',
    whatAreAltcoins: 'https://learn.swyftx.com/altcoins/what-are-altcoins/',
    dollarCostAveragingGuide: 'https://learn.swyftx.com/trading-and-analysis/dollar-cost-averaging-guide/',
    guideToAustralianCryptoTax: 'https://learn.swyftx.com/courses/australian-crypto-tax/',
    learnAndEarn: 'https://learn.swyftx.com/learn-and-earn',
    guardingAgainstInvestmentScams: 'https://learn.swyftx.com/courses/guarding-against-investment-scams/',
    fundamentalAnalysisCourse: 'https://learn.swyftx.com/courses/fundamental-analysis-in-crypto-trading/',
    astarNetwork: 'https://learn.swyftx.com/courses/astar-network/',
    dollarCostAverageLearnEarn: 'https://learn.swyftx.com/cryptocurrency/dollar-cost-averaging-in-crypto/',
    timingTheMarketLearn:
      'https://learn.swyftx.com/trading-and-analysis/timing-the-market-vs-time-in-the-market-in-crypto/',
    cryptoAndBitcoinMarketCycles: 'https://learn.swyftx.com/courses/market-cycles/',
    cryptoXAi: 'https://learn.swyftx.com/analysis/archive/crypto-x-ai-why-we-could-still-be-early/',
  },
};

export const checkBrokenLinksObject = async (obj: Object) => {
  const brokenLinks: any[] = [];
  const promises = Object.entries(obj).map(async ([key, value]) => {
    try {
      await axios.get(value);
    } catch (error) {
      brokenLinks.push({ key, value });
    }
  });
  await Promise.all(promises);
  return brokenLinks;
};

export default Links;

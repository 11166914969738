import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FlexLayoutSpacing } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { Expandable } from '@swyftx/aviary/atoms/Typography/Expandable/Expandable';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { cn } from '@shared/utils/lib/ui';

import * as Sentry from '@sentry/react';

import { CompactPageHeader } from './CompactPageHeader';
import { HeaderAlignment, HeaderIcon, HeaderMetrics } from './PageHeader.types';
import { PageHeaderBase } from './PageHeaderBase';
import { PageHeaderIcon } from './PageHeaderIcon';

type Props = {
  title?: string;
  titleSpacing?: FlexLayoutSpacing;
  actions?: React.ReactNode[];
  compactActions?: React.ReactNode[];
  showCompactHeader?: boolean;
  icon?: HeaderIcon;
  content?: string;
  contentClassName?: string;
  metrics?: HeaderMetrics[];
  offset?: boolean;
  position?: 'sticky' | 'relative';
  alignment?: HeaderAlignment;
  className?: string;
};

const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  position,
  showCompactHeader = true,
  offset,
  title,
  titleSpacing,
  alignment = 'start',
  actions,
  compactActions,
  icon,
  content,
  contentClassName,
  metrics,
}) => {
  const [showCompact, setShowCompact] = useState<boolean>(false);
  const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'page-header' });
  const isScreenXs = useTailwindBreakpoint('xs');
  const { isLightMode } = useTailwindTheme();

  const contentRef = useRef<HTMLDivElement>(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (e) => {
          if (e.length > 0) {
            setShowCompact(!e[0].isIntersecting);
          }
        },
        {
          rootMargin: '-50px',
        },
      ),
    [],
  );

  useEffect(() => {
    if (!showCompactHeader) {
      if (observer) observer.disconnect();
      setShowCompact(false);
      return;
    }

    const element = document.querySelector(`[data-container="page-header-content"]`);

    if (element) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, [observer, showCompactHeader]);

  const titleAlignment = useMemo(() => (alignment === 'start' ? 'space-between' : 'center'), [alignment]);

  const contentAlignment = useMemo(() => {
    if (alignment === 'start') return 'start';
    return 'center';
  }, [alignment]);

  return (
    <>
      <CompactPageHeader
        title={title}
        icon={icon}
        className={className}
        show={showCompact}
        actions={compactActions}
        metrics={metrics}
        isDarkMode={!isLightMode}
        atLeast={atLeast}
      />
      <PageHeaderBase className={className} offset={offset} position={position} showCompact={showCompact}>
        <FlexLayout
          direction='column'
          className={`transition-transform duration-200 ${
            showCompact ? 'translate-y-[-100px] opacity-0' : 'opacity-1 translate-y-[0px]'
          }`}
        >
          <FlexLayout
            alignItems='start'
            direction={atLeast.md && alignment !== 'center' ? 'row' : 'column'}
            justifyContent='start'
            spacing={atLeast.md ? 24 : 16}
          >
            <FlexLayout
              className={`${!atLeast.md || alignment === 'center' ? 'w-full' : ''}`}
              alignItems='center'
              justifyContent={titleAlignment}
              spacing={24}
            >
              {icon && <PageHeaderIcon icon={icon} atLeast={atLeast} isDarkMode={!isLightMode} />}

              {!isScreenXs && !atLeast.md && (
                <FlexLayout direction='row' alignItems='center' justifyContent='space-between' spacing={12}>
                  {actions}
                </FlexLayout>
              )}
            </FlexLayout>
            <FlexLayout
              direction='column'
              alignItems={alignment}
              className='w-full @xs:gap-24 @xl:gap-32'
              spacing={(titleSpacing as any) ?? 12}
            >
              <FlexLayout
                className='w-full'
                alignItems='start'
                justifyContent={titleAlignment}
                data-container='page-header-title'
              >
                <FlexLayout direction='column' spacing={12}>
                  <Heading size='h3' color='primary'>
                    {title}
                  </Heading>
                  {content && (
                    <div
                      className={cn(
                        '@xs:w-full @md:w-[480px] @lg:w-[640px] @xl:w-[840px]',
                        alignment === 'center' ? 'text-center' : '',
                      )}
                      data-container='page-header-content'
                    >
                      <Expandable size='small' color='secondary' ref={contentRef} className={contentClassName}>
                        {content}
                      </Expandable>
                    </div>
                  )}
                </FlexLayout>
                {atLeast.md && (
                  <FlexLayout direction='row' alignItems='center' justifyContent='space-between' spacing={12}>
                    {actions}
                  </FlexLayout>
                )}
              </FlexLayout>
              <FlexLayout direction='column' alignItems={contentAlignment}>
                {metrics && (
                  <FlexLayout
                    data-container={!content ? 'page-header-content' : ''}
                    alignItems='center'
                    justifyContent='start'
                    className='flex-wrap @xs:gap-[16px] @md:gap-[24px]'
                  >
                    {metrics.map((metric) => (
                      <NumericDataItem
                        className='@xs:w-[calc(50%-8px)] @sm:w-[220px] @xl:w-[240px]'
                        key={metric.key}
                        title={metric.title}
                        size={atLeast.sm ? 'xxlarge' : 'xlarge'}
                        data={metric.data}
                        percentage={metric.percentage}
                        color={metric.color}
                        tooltip={metric.tooltip}
                      />
                    ))}
                  </FlexLayout>
                )}
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </PageHeaderBase>
    </>
  );
};

PageHeader.displayName = 'PageHeader';

export const ProfiledPageHeader = Sentry.withProfiler(PageHeader);

export { ProfiledPageHeader as PageHeader };

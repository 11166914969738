import { api } from '@shared/api';
import { AssetServiceAsset } from '@shared/api/@types/assetService';
import { Asset, AssetType, AssetVolume } from '@shared/api/@types/markets';
import { NA } from '@shared/constants';
import { CryptoEnum, FiatIdEnum } from '@shared/enums';
import { isNumber } from '@shared/utils';

import * as Sentry from '@sentry/react';

import { mapAssetServiceAssetToLegacyAsset } from './assetService.mapper';
import { AppStore, RatesStore } from '../store';

const assets: { [key: string]: Asset } = {};

let baseAssets: number[] = [];
let baseAssetCodes: string[] = [];
let activeAssets: number[] = [];

const userDefaultAssets = [CryptoEnum.BTC, CryptoEnum.USDT];

export const setUserCountryAsset = (assetId: number) => userDefaultAssets.push(assetId);

export const initAssets = async () => {
  try {
    const { assetsInitialized, setAssetsInitialized } = AppStore.useAppStore;

    if (assetsInitialized) return;

    setAssetsInitialized(false);

    const assetServiceInfo = (
      await api.endpoints.getAssetServiceAssets({
        query: { includeCategories: true, includeNetworks: true, includeMetadata: true },
      })
    ).data.data;

    baseAssets = [];
    baseAssetCodes = [];
    activeAssets = [];

    // create asset lookups
    for (const asset of assetServiceInfo) {
      if (asset.baseAsset) {
        baseAssets.push(asset.id);
        baseAssetCodes.push(asset.code);
      }

      if (!asset.delisting) activeAssets.push(asset.id);
    }

    // create assets. Do this separately so we know which the primary assets first
    for (const asset of assetServiceInfo) {
      assets[asset.id] = createAsset(asset);
    }

    setAssetsInitialized(true);
  } catch (e) {
    // If anything goes wrong trying to init v2 assets, fallback to v1 assets
    Sentry.captureException(e);
  }
};

const setAssetActive = (id: Asset['id'], active: boolean) => {
  assets[id].active = active;
};

/**
 * Get asset given the asset or asset Id
 * If given asset id return from map otherwise was given asset
 */
const getAsset = (asset: Asset | Asset['id']): Asset | undefined =>
  isNumber(asset) ? assets[asset as Asset['id']] : (asset as Asset);

/** Get asset given the asset code */
const getAssetByCode = (code: string): Asset | undefined =>
  Object.values(assets).find((asset) => asset.code === code.toUpperCase());

/** Get asset code given the asset id */
const getAssetCodeById = (id: Asset['id']): string | undefined => assets[id]?.code;
const canBuyAsset = (asset: Asset) => !asset.buyDisabled && asset.tradable;
const canDepositAsset = (asset: Asset) => asset.deposit_enabled;
const canSellAsset = (asset: Asset) => !asset.buyDisabled || asset.tradable;
const canWithdrawAsset = (asset: Asset) => asset.withdraw_enabled;
const canWithdrawAndDepositAsset = (asset?: Asset) => asset && asset?.withdraw_enabled && asset?.deposit_enabled;

const isAssetDelisted = (asset: Asset) => asset.delisting;

const refreshAssets = initAssets;
const getAllAssets = () => assets;
const getActiveAssets = () => activeAssets;
const getBaseAssets = () => baseAssets;
const getBaseAssetCodes = () => baseAssetCodes;
const isAssetFiat = (id: Asset['id'] | undefined = -1): boolean => getAsset(id)?.assetType === AssetType.Fiat;
const isAssetFiatByCode = (code: string): boolean => getAssetByCode(code)?.assetType === AssetType.Fiat;
const getDisplayPriceScale = (asset?: Asset) =>
  asset ? (asset.assetType === AssetType.Fiat ? 2 : asset.price_scale) : 2;

const createAsset = (assetServiceAsset: AssetServiceAsset): Asset => {
  const { convertRate, ratesOnReady } = RatesStore.useRatesStore;
  const assumedAssetCurrency = FiatIdEnum.USD;

  const volume: AssetVolume = {};
  for (const primaryAsset of baseAssets) {
    volume[primaryAsset] = {
      day: NA,
      week: NA,
      month: NA,
    };
    // convert rates when live rates have been loaded
    ratesOnReady().then(() => {
      const dailyVolume = assetServiceAsset?.metadata?.data?.volume?.['24H'];

      volume[primaryAsset] = {
        day: dailyVolume ? convertRate(assumedAssetCurrency, primaryAsset, dailyVolume, 'midPrice').toFixed(0) : NA,
        week: dailyVolume ? convertRate(assumedAssetCurrency, primaryAsset, dailyVolume, 'midPrice').toFixed(0) : NA,
        month: dailyVolume ? convertRate(assumedAssetCurrency, primaryAsset, dailyVolume, 'midPrice').toFixed(0) : NA,
      };
    });
  }

  const asset: Asset = mapAssetServiceAssetToLegacyAsset(assetServiceAsset, volume);

  asset.buyDisabled = Number(!canBuyAsset(asset));
  asset.delisting = isAssetDelisted(asset);
  asset.deposit_enabled = canDepositAsset(asset);
  asset.sellEnabled = Number(canSellAsset(asset));
  asset.withdraw_enabled = canWithdrawAsset(asset);

  // allow an assets price scale to represent at least 0.01 AUD
  // TODO this only runs the first time rates are loaded. Should probably run every time
  ratesOnReady().then(() => {
    const centPrice = convertRate(FiatIdEnum.AUD, asset.id, 0.01);

    const centPriceDecimals = centPrice.toFixed().split('.')[1];
    let priceScale = 0;

    // if conversion is greater than 1 no need to check the decimals
    if (centPrice.lt(1) && centPriceDecimals?.length) {
      for (let i = 0; i < centPriceDecimals.length; i += 1) {
        if (centPriceDecimals[i] !== '0') {
          priceScale = i + 1;
          break;
        }
      }
    }

    // cap max to 10 as we are limited by big.js
    let newPriceScale = Math.max(assetServiceAsset.priceScale, priceScale);
    newPriceScale = Math.min(newPriceScale, 10);
    asset.price_scale = newPriceScale;
  });

  return asset;
};

const getAssets = (assetIds: number[]): Asset[] =>
  assetIds.map((id) => getAsset(id)).filter((asset) => asset && !asset.delisting && asset.active) as Asset[];

const getAssetsByCode = (assetCodes: string[]): Asset[] =>
  assetCodes
    .map((code) => getAssetByCode(code))
    .filter((asset) => asset && !asset.delisting && asset.active) as Asset[];

export const assetService = {
  getAllAssets,
  getAssets,
  getAssetsByCode,
  getAssetByName: (name: string) => Object.values(assets).find((a) => a.name === name),
  getAllAssetList: () => Object.values(assets),
  getAssetList: () => Object.values(assets).filter((asset) => asset.active),
  getActiveAssetList: () => Object.values(assets).filter((asset) => !asset.delisting && asset.active),
  getCryptoAssets: () => Object.values(assets).filter((asset) => !isAssetFiat(asset.id) && asset.active),
  // eslint-disable-next-line max-len
  getActiveCryptoAssets: () =>
    Object.values(assets).filter((asset) => !asset.delisting && !isAssetFiat(asset.id) && asset.active),
  setAssetActive,
  refreshAssets,
  initAssets,
  setUserCountryAsset,
  getBaseAssets,
  getBaseAssetCodes,
  getDisplayPriceScale,
  getAsset,
  isAssetFiat,
  isAssetFiatByCode,
  getAssetByCode,
  getAssetCodeById,
  getActiveAssets,
  isAssetDelisted,
  canWithdrawAndDepositAsset,
  canDepositAsset,
  canWithdrawAsset,
};

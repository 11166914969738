import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';

import { api } from '@shared/api';
import { ChartBarsParams, ChartBarsQuery } from '@shared/api/@types/charts';
import { Asset } from '@shared/api/@types/markets';
import { AssetPriceHistoryData, chartService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { DateTime } from 'luxon';
import { TradingViewSide } from 'src/lib/trade/components/TradingViewWidget/types';

const useAssetPriceMetrics = (asset?: Asset, forceDeps?: DependencyList, priceSide?: TradingViewSide) => {
  const [data, setData] = useState<AssetPriceHistoryData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { getRate } = RatesStore.useRatesStore;
  const livePrice = useMemo(() => {
    if (!asset) return undefined;
    const rate = getRate(asset);
    if (priceSide) {
      return priceSide === 'ask' ? rate.askPrice : rate.bidPrice;
    }
    return rate.midPrice;
  }, [asset, getRate, priceSide]);

  const baseAsset = useBaseAsset();

  const forceFetch = () => getData();

  const getData = useCallback(async () => {
    if (!asset || !baseAsset || asset.code === baseAsset.code || loading) {
      return;
    }

    setLoading(true);

    const params: ChartBarsParams = {
      primary: baseAsset.code,
      secondary: asset.code,
      side: 'ask',
    };

    const today = DateTime.now();
    const monthAgo = DateTime.now().minus({ month: 1 });

    const query: ChartBarsQuery = {
      timeStart: monthAgo.toMillis(),
      timeEnd: today.toMillis(),
      resolution: '1d',
    };

    try {
      const { candles } = (await api.endpoints.getChartBars({ query, params })).data;

      const historyData = chartService.getAssetPriceHistory(candles, livePrice || '');
      setError(false);
      setData(historyData);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [asset, baseAsset, livePrice, loading]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, forceDeps || [baseAsset, asset]);

  return {
    data,
    loading,
    error,
    forceFetch,
  };
};

export { useAssetPriceMetrics };

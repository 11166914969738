import React from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  offset?: boolean;
  className?: string;
};

const PageContent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ className, offset, children }, ref) => (
    <div ref={ref} className={cn(`relative p-24 @sm:p-24 @md:p-32 @xl:p-48 ${offset ? '-mt-[196px]' : ''}`, className)}>
      {children}
    </div>
  ),
);

PageContent.displayName = 'PageContent';

export { PageContent };

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric, Utility } from '@swyftx/aviary/atoms/Typography';

import { Big } from '@shared/safe-big';

type Props = {
  title: string;
  assets: number;
  assetsPercentage: Big;
  color: 'success' | 'error';
  direction?: 'column' | 'row';
  loaded: boolean;
};

const directionSpacing: { [key: string]: 0 | 4 | 8 } = {
  column: 4,
  row: 8,
};

const MarketGainersLosersItem: React.FC<Props> = ({
  title,
  assets,
  assetsPercentage,
  color,
  direction = 'row',
  loaded,
}) => (
  <FlexLayout direction='column' alignItems='center' spacing={directionSpacing[direction]}>
    <Utility variant='overline'>{title}</Utility>
    <Numeric size='xlarge' color={assets === 0 ? 'secondary' : color} loading={!loaded}>
      {assets}
    </Numeric>
    <Numeric size='xsmall' color='secondary' loading={!loaded}>
      ({assetsPercentage.toFixed(0)}%)
    </Numeric>
  </FlexLayout>
);

export { MarketGainersLosersItem };

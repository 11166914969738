export enum DashboardTileEnum {
  ANNOUNCEMENT = 'announcement',
  FAVOURITES = 'favourites',
  MOVERS = 'movers',
  PORTFOLIO_BREAKDOWN = 'portfolioBreakdown',
  PORTFOLIO_PERFORMANCE = 'portfolioPerformance',
  QUICK_ACTIONS = 'quickActions',
  AFFILIATE = 'affiliateProgram',
  ACTIVITY = 'activity',
  VERIFICATION = 'verification',
  WELCOME = 'welcome',
  NEWS_FEED = 'newsFeed',
  REFER_A_FRIEND = 'referAFriend',
}

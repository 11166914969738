import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyDown, ArrowLineOnlyUp } from '@swyftx/aviary/icons/outlined';
import { MenuProps } from '@swyftx/aviary/molecules/Menu';

import { Asset, SortDirection } from '@shared/api';

import { observer } from 'mobx-react-lite';

import { DynamicPriceAlertAssetFilter } from './DynamicPriceAlertAssetFilter';
import { DynamicPriceAlertTypeFilter } from './DynamicPriceAlertTypeFilter';
import { PriceAlertGroupBy, PriceAlertTriggerType, PriceAlertType } from '../../dynamicPriceAlerts.types';
import { DeleteAllPriceAlertModal } from '../DeleteAllPriceAlertModal';

type Props = {
  align?: MenuProps['align'];
  showAssetFilter?: boolean;
  showDeleteAlerts?: boolean;
  showDeleteHistory?: boolean;
  groupBy?: PriceAlertGroupBy;
  filterTypes: PriceAlertTriggerType[];
  filterAssets?: Asset[];
  alertType: PriceAlertType;
  dateSort?: SortDirection;
  hasNoAlerts?: boolean;
  setDateSort?: (dateSort: SortDirection) => void;
  setGroupBy?: (groupBy: PriceAlertGroupBy) => void;
  setFilterTypes: (filterTypes: PriceAlertTriggerType[]) => void;
  setFilterAssets?: (filterAssets: Asset[]) => void;
};

const DynamicPriceAlertFilters: React.FC<Props> = observer(
  ({
    showAssetFilter = true,
    showDeleteAlerts = true,
    align,
    groupBy,
    alertType,
    filterTypes,
    filterAssets,
    dateSort,
    hasNoAlerts = false,
    setGroupBy,
    setFilterTypes,
    setFilterAssets,
    setDateSort,
  }) => {
    const { t } = useTranslation('priceAlerts');
    const showGroupByAsset = useMemo(
      () => alertType === 'active' && groupBy !== undefined && setGroupBy !== undefined,
      [alertType, groupBy, setGroupBy],
    );

    const showDateSort = useMemo(() => dateSort !== undefined && setDateSort !== undefined, [dateSort, setDateSort]);

    return (
      <FlexLayout alignItems='center' spacing={8}>
        {showDateSort && (
          <FlexLayout className='rounded-8 border border-color-border-main' spacing={8}>
            <FlexLayout
              onClick={() =>
                setDateSort &&
                setDateSort(dateSort === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING)
              }
              alignItems='center'
              justifyContent='center'
              className='cursor-pointer border-r border-color-border-main px-6 py-4 hover:bg-color-background-surface-hover'
            >
              {dateSort === SortDirection.ASCENDING ? (
                <ArrowLineOnlyUp className='h-20 w-20 text-color-text-accent' />
              ) : (
                <ArrowLineOnlyDown className='h-20 w-20 text-color-text-accent' />
              )}
            </FlexLayout>
            <Body color='primary' className='whitespace-nowrap py-4 pr-12'>
              {t('sort.title', { sort: t('sort.date') })}
            </Body>
          </FlexLayout>
        )}
        {showAssetFilter && (
          <DynamicPriceAlertAssetFilter
            alertType={alertType}
            filterAssets={filterAssets}
            setFilterAssets={setFilterAssets}
          />
        )}

        <DynamicPriceAlertTypeFilter align={align} filterTypes={filterTypes} setFilterTypes={setFilterTypes} />

        <>
          {(showGroupByAsset || showDeleteAlerts) && <Separator className='mx-4 h-36 w-1' />}
          {showGroupByAsset && (
            <Switch
              text={t('filters.groupByAsset')}
              checked={groupBy === 'asset'}
              disabled={hasNoAlerts}
              onCheckedChange={(checked) => setGroupBy !== undefined && setGroupBy(checked ? 'asset' : 'type')}
            />
          )}
          {showDeleteAlerts && (
            <DeleteAllPriceAlertModal alertType={alertType}>
              <Button variant='outlined'>
                {alertType === 'active' ? t('buttons.deleteAlerts') : t('buttons.deleteHistory')}
              </Button>
            </DeleteAllPriceAlertModal>
          )}
        </>
      </FlexLayout>
    );
  },
);

export { DynamicPriceAlertFilters };

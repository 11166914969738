import { Coin, Paper } from '@swyftx/aviary/icons/outlined';

import { OrdersTileTabs } from './ordersTile.types';

{
  /* Placeholders for now, take with a grain of salt */
}
export const getEmptyIcon = (selectedTab: OrdersTileTabs) => {
  switch (selectedTab) {
    case OrdersTileTabs.OrderHistory:
    case OrdersTileTabs.OpenOrders:
      return <Paper className='h-40 w-40 text-color-text-accent' />;
    case OrdersTileTabs.Wallets:
      return <Coin className='h-40 w-40 text-color-text-accent' />;
    default:
      selectedTab satisfies never;
  }
};

export const getEmptyContent = (selectedTab: OrdersTileTabs) => {
  switch (selectedTab) {
    case OrdersTileTabs.OrderHistory:
      return "You don't have any order history";
    case OrdersTileTabs.OpenOrders:
      return "You don't have any open orders";
    case OrdersTileTabs.Wallets:
      return "You don't own any assets";
    default:
      selectedTab satisfies never;
  }
};

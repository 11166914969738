import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowLineOnlyLeft: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M10.8281 6.78094C11.1216 6.48865 11.1226 6.01378 10.8303 5.72028C10.538 5.42678 10.0631 5.4258 9.76961 5.71808L3.71961 11.7431C3.57828 11.8838 3.49883 12.0751 3.49884 12.2745C3.49885 12.474 3.57831 12.6652 3.71965 12.806L9.76965 18.83C10.0632 19.1222 10.538 19.1212 10.8303 18.8277C11.1226 18.5342 11.1216 18.0593 10.828 17.767L6.06493 13.0244H19.25C19.6642 13.0244 20 12.6886 20 12.2744C20 11.8602 19.6642 11.5244 19.25 11.5244H6.06491L10.8281 6.78094Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowLineOnlyLeft };

import React, { useCallback, useMemo } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { AvatarColor } from '@swyftx/aviary/atoms/Avatar/Avatar.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';
import { Clock, AddInCircle, Loading } from '@swyftx/aviary/icons/outlined';

import { EntityHistoryPayload } from '@shared/api';
import { UserStore } from '@shared/store';
import { EntityAccount, EntityColor } from '@shared/store/userStore/@types/userTypes';

import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { MenuItem } from '../Menu';

interface Props {
  entityAccounts: EntityAccount[];
  selectAccount: (account: EntityAccount) => void;
  selectedAccount?: string;
  switchingAccount?: string;
  switchingAccountError?: string;
  entityHistoryFetching: boolean;
  inProgressSubmissions: EntityHistoryPayload[];
  goToExistingApplication: () => void;
  startNewEntityApplication: () => void;
  hasASubmittedSubmission: boolean;
}

export const EntityAccountSwitcherNew: React.FC<Props> = (props) => {
  const {
    entityAccounts,
    selectAccount,
    selectedAccount,
    switchingAccount,
    switchingAccountError,
    entityHistoryFetching,
    inProgressSubmissions,
    startNewEntityApplication,
    goToExistingApplication,
    hasASubmittedSubmission,
  } = props;
  const { isKyc1Complete, isUserVerified, isEntity } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();
  const { startedApplication, entityCallToActionClicked } = useEntityOnboardingAnalytics();

  const canCreateEntity = useMemo(() => isKyc1Complete() && isUserVerified(), [isKyc1Complete, isUserVerified]);

  const fetchAvatarColor = useCallback((entity: EntityAccount) => {
    if (entity.entityColorIndex !== undefined) {
      const color = Object.entries(EntityColor).find(([, value]) => value === entity.entityColorIndex);
      return color ? (color[0].toLocaleLowerCase() as AvatarColor) : 'personal';
    }
    return 'personal';
  }, []);

  const handleNavigateToApplication = useCallback(() => {
    if (!inProgressSubmissions.length) {
      startedApplication();
      entityCallToActionClicked({ callToActionName: 'Create entity account' });
      startNewEntityApplication();
    } else {
      goToExistingApplication();
      entityCallToActionClicked({ callToActionName: 'Resume entity setup' });
    }
  }, [
    entityCallToActionClicked,
    goToExistingApplication,
    inProgressSubmissions.length,
    startNewEntityApplication,
    startedApplication,
  ]);

  const avatarColorCycle: AvatarColor[] = useMemo(() => ['purple', 'teal', 'magenta', 'orange'], []);

  const submissionAvatarColor = useMemo(() => {
    const existingAccountsIndex = entityAccounts.length;
    return avatarColorCycle[existingAccountsIndex % avatarColorCycle.length];
  }, [avatarColorCycle, entityAccounts.length]);

  const inProgressAvatarColor = useCallback(
    (index: number) => {
      const existingAccountsIndex = entityAccounts.length + (hasASubmittedSubmission ? 1 : 0);
      return avatarColorCycle[(existingAccountsIndex + index) % avatarColorCycle.length];
    },
    [avatarColorCycle, entityAccounts.length, hasASubmittedSubmission],
  );

  return (
    <>
      <div className='PII'>
        {entityAccounts.map((entity) => (
          <MenuItem
            key={entity.uuid}
            icon={entity.name[0]}
            avatarColor={fetchAvatarColor(entity)}
            text={entity.name}
            selected={switchingAccountError !== entity.uuid && selectedAccount === entity.uuid}
            error={switchingAccountError === entity.uuid}
            endAdornment={
              switchingAccountError !== entity.uuid && switchingAccount === entity.uuid ? (
                <Loading className='h-18 w-18 animate-spin text-color-text-primary' />
              ) : undefined
            }
            action={() => selectedAccount !== entity.uuid && selectAccount(entity)}
          />
        ))}
      </div>
      {canCreateEntity && !isEntity() && (
        <FlexLayout direction='column'>
          {entityHistoryFetching && (
            <FlexLayout direction='column' className='w-full items-center py-8'>
              <Loading className='animate-spin' />
            </FlexLayout>
          )}
          {!entityHistoryFetching && (
            <>
              {!!hasASubmittedSubmission && (
                <MenuItem
                  key='submittedSubmission'
                  icon={
                    <Avatar size='sm' color={submissionAvatarColor}>
                      <Clock className='h-12 w-12 text-color-text-inverse' />
                    </Avatar>
                  }
                  text='Entity account pending'
                  description='Our team is currently reviewing your application'
                  className='cursor-auto hover:bg-color-background-surface-primary'
                />
              )}
              <FlexLayout direction='column' onClick={handleNavigateToApplication}>
                {inProgressSubmissions.map((submissions, index) => (
                  <MenuItem
                    avatarColor={inProgressAvatarColor(index)}
                    icon={(index + 1).toString()}
                    key={submissions.id}
                    text='Continue application'
                    description='Resume entity setup'
                  />
                ))}
                {!inProgressSubmissions.length && (
                  <MenuItem
                    icon={<AddInCircle width={20} height={20} />}
                    text='Create entity/SMSF account'
                    description='For companies, SMSF and trusts'
                  />
                )}
              </FlexLayout>
            </>
          )}
        </FlexLayout>
      )}
      {!canCreateEntity && (
        <FlexLayout
          direction='column'
          onClick={() => {
            entityCallToActionClicked({ callToActionName: 'Create entity account' });
            if (isUserVerified()) {
              return navigate(NavigationURLs.ProfileVerification);
            }
            return navigate(NavigationURLs.Onboarding);
          }}
        >
          <MenuItem
            icon={<AlertFilled className='text-color-text-warning' width={20} height={20} />}
            text='Create entity/SMSF account'
            description={
              <Body size='xsmall' color='secondary'>
                {isUserVerified() ? 'Upgrade to gold to create an entity' : 'Verify your account to create an entity'}
              </Body>
            }
          />
        </FlexLayout>
      )}
    </>
  );
};

import { NanoColor } from '@swyftx/aviary/molecules/ShopItem';

import { ShopIDs } from '@Shop/Shop.ids';

import { NavigationURLs } from 'src/lib/navigation/types';

export const ColorUrls: { [key in NanoColor]: string } = {
  [NanoColor.OnyxBlack]: 'onyx-black',
  [NanoColor.MatteBlack]: 'matte-black',
  [NanoColor.PastelGreen]: 'pastel-green',
  [NanoColor.AmethystPurple]: 'amethyst-purple',
  [NanoColor.RetroGaming]: 'retro-gaming',
  [NanoColor.BTCOrange]: 'btc-orange',
};

export const ColorNames: { [key in NanoColor]: string } = {
  [NanoColor.OnyxBlack]: 'Onyx Black',
  [NanoColor.MatteBlack]: 'Matte Black',
  [NanoColor.PastelGreen]: 'Pastel Green',
  [NanoColor.AmethystPurple]: 'Amethyst Purple',
  [NanoColor.RetroGaming]: 'Retro Gaming',
  [NanoColor.BTCOrange]: 'BTC Orange',
};

export const referral = 'r=3838a8425f19';

export const shopItems = {
  'ledger-nano-x': {
    id: ShopIDs.shopItem.ledgerNanoX,
    title: 'Ledger Nano X',
    description:
      'Unlock a world of crypto possibilities with the iconic Ledger Nano X. The most advanced Bluetooth-enabled hardware wallet to securely manage all your crypto assets on all platforms. Product color may vary slightly from pictures due to manufacturing process.',
    price: '$259.00 AUD',
    defaultColor: NanoColor.OnyxBlack,
    shippingTime: 'Free shipping (14-21 days)',
    colors: {
      [NanoColor.OnyxBlack]: '/assets/images/shop/ledgernanox_onyx_black.webp',
      [NanoColor.PastelGreen]: '/assets/images/shop/ledgernanox_pastel_green.webp',
      [NanoColor.AmethystPurple]: '/assets/images/shop/ledgernanox_amethyst_purple.webp',
      [NanoColor.RetroGaming]: '/assets/images/shop/ledgernanox_retro_gaming.webp',
      [NanoColor.BTCOrange]: '/assets/images/shop/ledgernanox_btc_orange.webp',
    },
    chip: true,
    features: [
      'Up-to 100 wallet apps at once ',
      'USB Type C + Bluetooth Connectivity ',
      '100mAh battery ',
      '128 x 64 pixel screen ',
    ],
    url: 'https://shop.ledger.com/products/ledger-nano-x',
    internalUrl: NavigationURLs.LedgerNanoX,
    buttonText: 'Learn More',
    chipText: 'Popular',
  },
  'ledger-nano-s': {
    id: ShopIDs.shopItem.ledgerNanoSPlus,
    title: 'Ledger Nano S Plus',
    description:
      'Secure, grow and manage your crypto and NFTs with Ledger’s most popular wallet, Ledger Nano S Plus. Review your transactions with ease and take control while on the move, all with uncompromising security. Product color may vary slightly from pictures due to manufacturing process.',
    price: '$139.00 AUD',
    defaultColor: NanoColor.MatteBlack,
    shippingTime: '~$25 (14-21 days)',
    colors: {
      [NanoColor.MatteBlack]: '/assets/images/shop/ledgernanosplus_matte_black.webp',
      [NanoColor.PastelGreen]: '/assets/images/shop/ledgernanosplus_pastel_green.webp',
      [NanoColor.AmethystPurple]: '/assets/images/shop/ledgernanosplus_amethyst_purple.webp',
      [NanoColor.RetroGaming]: '/assets/images/shop/ledgernanosplus_retro_gaming.webp',
      [NanoColor.BTCOrange]: '/assets/images/shop/ledgernanosplus_btc_orange.webp',
    },
    chip: false,
    features: ['Up-to 100 wallet apps at once ', 'USB Type C Connectivity ', 'No battery ', '128 x 64 pixel screen '],
    url: 'https://shop.ledger.com/products/ledger-nano-s-plus',
    internalUrl: NavigationURLs.LedgerNanoSPlus,
    buttonText: 'Learn More',
  },
};

export const shopInfoItems = [
  {
    id: ShopIDs.shopInfo.what,
    title: 'What is a hardware wallet?',
    content:
      'A hardware wallet is a secure storage device that is built and designed to assist you in protecting and managing your own crypto currencies. They do this by encrypting your wallet keys and providing a simple and intuitive user interface for managing your favourite crypto wallets. Swyftx recommends the Ledger Nano s or Ledger Nano X.',
  },
  {
    id: ShopIDs.shopInfo.need,
    title: 'Do I need a hardware wallet?',
    content:
      'It’s a common saying in the crypto world “Not your Keys, Not your Crypto” - essentially suggesting that if you’re long term investing in crypto currencies you should store them within your control, rather than with an exchange or hosted wallet. While Swyftx does everything possible to maintain the security of customers’ accounts, keeping your holdings in a hardware wallet is an excellent additional step to securing your crypto.',
  },
];

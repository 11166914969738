import { useCallback, useEffect, useMemo, useState } from 'react';

import { FeeTier, FeeTiersResponse, FeeType } from '@shared/api/@types/fees';
import { UserStore } from '@shared/store';

import { DateTime } from 'luxon';
import { useInterval } from 'react-use';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { useFetchCurrentUserFees } from 'src/lib/user-profile/hooks/useFetchCurrentUserFees';
import { useFetchFeeTiers } from 'src/lib/user-profile/hooks/useFetchFeeTiers';
import { useFetchMonthlyTradeVolume } from 'src/lib/user-profile/hooks/useFetchMonthlyTradeVolume';

const DEFAULT_TRADE_FEE = 0.6;
const DEFAULT_PRO_TRADE_FEE = 0.3;

interface CurrentTierAndUnlockedTier {
  currentTier?: FeeTier;
  unlockedTier?: FeeTier;
}

/**
 * Calculates the user's next tier to reach based on the feeTiers and user's 30-day volume
 */
const calculateCurrentTierAndUnlockedTier = (
  feeTiers: FeeTiersResponse,
  thirtyDayVolume: number,
  currentTierLabel: string,
) => {
  const currentTier = feeTiers.find((tier) => tier.label === currentTierLabel);

  if (!currentTier) {
    return { currentTier: feeTiers[0], unlockedTier: feeTiers[0] };
  }

  // If they are in the highest tier, return the highest tier as the unlocked tier
  if (currentTier.tier === feeTiers[feeTiers.length - 1].tier) return { currentTier, unlockedTier: currentTier };

  const unlockedTier = feeTiers.find((tier, index) => {
    // The last tier may have an upper bound of null
    if (thirtyDayVolume >= tier.lowerBound && tier.upperBound === null) return true;
    return (
      ((tier.upperBound !== null && thirtyDayVolume < tier.upperBound) ||
        (tier.upperBound !== null && thirtyDayVolume > tier.upperBound && index === feeTiers.length - 1)) &&
      thirtyDayVolume >= tier.lowerBound
    );
  });

  return { currentTier, unlockedTier };
};

type Platform = 'pro' | 'standard';

export const useTradeFeesData = (forcePlatform?: Platform) => {
  const [userFeeStatus, setUserFeeStatus] = useState<{ feePercentage: number; feeType: FeeType }>();
  const [userTierStatus, setUserTierStatus] = useState<CurrentTierAndUnlockedTier>();
  const [updatesIn, setUpdatesIn] = useState<string>();
  const { isSwyftxPro } = useSwyftxPro();
  const { userId } = UserStore.useUserStore;
  const { data: feeTiers } = useFetchFeeTiers({ userId });
  const { data: thirtyDayVolume } = useFetchMonthlyTradeVolume({ userId });
  const { data: currentFees } = useFetchCurrentUserFees({ userId });

  const [loading, setIsLoading] = useState<boolean>(true);

  const pro = useMemo(() => {
    if (!forcePlatform) return isSwyftxPro;

    return forcePlatform === 'pro';
  }, [forcePlatform, isSwyftxPro]);

  const calculateTimeRemaining = useCallback(() => {
    // get now in terms of Brisbane time instead of user's local time to ensure consistency with cron job
    const now = DateTime.now().setZone('Australia/Brisbane');
    // Fees update every hour in line with cron. Add 10 seconds for cron to run
    const upcomingHour = DateTime.now()
      .setZone('Australia/Brisbane')
      .plus({ hour: 1 })
      .startOf('hour')
      .plus({ seconds: 10 });
    const timeRemaining = upcomingHour.diff(now, ['minutes', 'seconds']).toObject();

    const updatesInString =
      timeRemaining.minutes !== undefined && timeRemaining.minutes > 0 ? `${timeRemaining?.minutes}m` : 'less than 1m';
    return updatesInString;
  }, []);

  useInterval(() => {
    setUpdatesIn(calculateTimeRemaining());
  }, 15000);

  useEffect(() => {
    const getUserFeeStatus = async () => {
      if (!currentFees || !feeTiers || !thirtyDayVolume) return;
      setIsLoading(true);

      const currentTierLabel =
        currentFees.fees.find((fee) => {
          if (pro) {
            return fee.feeType === FeeType.PRO_FEE_TIER_UPGRADE;
          } else {
            return fee.feeType === FeeType.FEE_TIER_UPGRADE;
          }
        })?.label ?? 'Regular';

      setUserTierStatus(calculateCurrentTierAndUnlockedTier(feeTiers, thirtyDayVolume.tradeVolume, currentTierLabel));

      setUpdatesIn(calculateTimeRemaining());
      setUserFeeStatus(
        currentFees.fees.reduce((bestFee, fee) => (fee.feePercentage < bestFee.feePercentage ? fee : bestFee), {
          label: '',
          feePercentage: pro ? DEFAULT_PRO_TRADE_FEE : DEFAULT_TRADE_FEE,
          feeType: FeeType.FEE_TIER_UPGRADE,
        }),
      );

      setIsLoading(false);
    };

    getUserFeeStatus();
  }, [calculateTimeRemaining, currentFees, feeTiers, pro, thirtyDayVolume]);

  return { thirtyDayVolume, userFeeStatus, updatesIn, loading, feeTiers, userTierStatus };
};

import { Methods, request } from '../rest';

const messagesEndpoints = {
  getLatestMessages: ({ params, headers }: { params: object; headers: Record<string, string> }) =>
    request({ path: '/messages/latest/', method: Methods.GET, headers, params, auth: true }),

  getLatestAnnouncements: ({ params, headers }: { params: object; headers: Record<string, string> }) =>
    request({ path: '/messages/announcements/', method: Methods.GET, headers, params, auth: true }),
};

export default messagesEndpoints;

export enum PerformanceMenuControlLabel {
  OneDay = '1D',
  OneWeek = '1W',
  OneMonth = '1M',
  ThreeMonths = '3M',
  SixMonths = '6M',
  OneYear = '1Y',
  AllTime = 'All',
}

export type PerformanceMenuControl = {
  label: PerformanceMenuControlLabel;
  startTime: number | null;
};

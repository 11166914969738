/**
 * Disables all CSS transitions before running the callback and re-enables once completed
 */
export const runWithTransitionsDisabled = (callback: () => void) => {
  const css = document.createElement('style');
  css.type = 'text/css';
  css.appendChild(
    document.createTextNode(
      // Ignore any elements with data-transition-force and children
      `*:not([data-transition-force]):not([data-transition-force] *) {
       -webkit-transition: none !important;
       -moz-transition: none !important;
       -o-transition: none !important;
       -ms-transition: none !important;
       transition: none !important;
    }
    `,
    ),
  );
  document.head.appendChild(css);

  callback();

  requestAnimationFrame(() => {
    document.head.removeChild(css);
  });
};

export enum SourceOfWealth {
  BUSINESS_OWNERSHIP_INTERESTS = 'business_ownership_interests',
  EMPLOYMENT = 'employment',
  INHERITANCE = 'inheritance',
  SALE_OF_PROPERTY = 'sale_of_property',
  SALE_OF_BUSINESS = 'sale_of_business',
  SALE_OF_ASSETS = 'sale_of_assets',
  GAMBLING_OR_LOTTERY = 'gambling_or_lottery',
  SELF_MANAGED_SUPER_FUND = 'self_managed_super_fund',
}

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Calendar } from '@swyftx/aviary/icons/outlined';

type Props = {
  leadingIcon?: React.ReactElement;
  onClick: () => void;
};

const CalendarPickerTriggerButton: React.FC<Props> = ({
  leadingIcon = <Calendar className='h-20 w-20' />,
  onClick,
}) => <Button layout='icon' leadingIcon={leadingIcon} className='p-0' size='sm' variant='ghost' onClick={onClick} />;

export { CalendarPickerTriggerButton };

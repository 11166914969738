import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';
import { usdStableCoins } from '@hooks/Charts/useCharts';

import { useMarketActivity } from 'src/lib/markets/hooks/useMarketActivity';

import { MarketTickerMode } from '../aviary/marketTicker.consts';
import { MarketTickerSortingOptions } from '../consts';

export const useMarketTickerAssets = (mode: MarketTickerMode, sort?: MarketTickerSortingOptions) => {
  const { balances } = UserStore.useUserStore;
  const { getRate } = RatesStore.useRatesStore;
  const { assetsInitialized } = AppStore.useAppStore;
  const baseAsset = useBaseAsset();

  /**
   * Used for the moving market ticker. Duplicates the array content until the length
   * matches the assets array so all sorting options move at the same speed.
   */
  const duplicateArrayContent = useCallback((array: Asset[], length: number) => {
    const assets = [];
    const duplicationFactor = Math.ceil(length / array.length);

    for (let i = 0; i < duplicationFactor; i++) {
      assets.push(...array);
    }

    return assets.slice(0, length);
  }, []);

  /**
   * Filters out stable coins and the base asset from the assets array.
   */
  const filterUnwantedAssets = useCallback(
    (assets: Asset[]) =>
      assets.filter((asset) => !usdStableCoins.includes(asset?.id as number) && baseAsset?.code !== asset.code),
    [baseAsset?.code],
  );

  const getAssetBalance = useCallback(
    (asset: Asset) => {
      const { availableBalance } = balances[asset.id];
      const assetBalance = Big(getRate(asset.id).midPrice).times(availableBalance);
      return assetBalance;
    },
    [balances, getRate],
  );

  const assets = useMemo(() => {
    if (!assetsInitialized) return [];

    const filteredAssets = filterUnwantedAssets(assetService.getActiveCryptoAssets());
    return filteredAssets;
  }, [assetsInitialized, filterUnwantedAssets]);

  const { topAssets, ownedAssets, newListings } = useMarketActivity({
    assets,
  });

  const favouriteAssets = useFavouriteAssets();

  const favourites = useMemo(() => {
    if (!favouriteAssets.length) return [];
    const filteredFavourites = filterUnwantedAssets(favouriteAssets);
    if (sort === MarketTickerSortingOptions.FAVOURITES && mode === MarketTickerMode.MOVING) {
      return duplicateArrayContent(filteredFavourites, assets.length);
    }
    return favouriteAssets;
  }, [assets.length, duplicateArrayContent, favouriteAssets, filterUnwantedAssets, mode, sort]);

  const owned = useMemo(() => {
    if (ownedAssets.length === 0) return [];
    const filteredOwned = filterUnwantedAssets(ownedAssets.filter((a) => getAssetBalance(a).gt(0)));
    if (!filteredOwned.length) return [];
    if (sort === MarketTickerSortingOptions.OWNED && mode === MarketTickerMode.MOVING) {
      return duplicateArrayContent(filteredOwned, assets.length);
    }
    return filteredOwned;
  }, [assets.length, duplicateArrayContent, filterUnwantedAssets, getAssetBalance, mode, ownedAssets, sort]);

  return { top: topAssets, owned, favourites, assets, new: newListings };
};

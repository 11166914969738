import React from 'react';

import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

export enum LabelSwitchSide {
  Left,
  Right,
}

type Props = {
  leftLabel: string;
  rightLabel: string;
  initialSide?: LabelSwitchSide;
  onChange: (side: LabelSwitchSide) => void;
};

export const LabelSwitch: React.FC<Props> = ({ leftLabel, onChange, rightLabel, initialSide }) => {
  const [selectedSide, setSelectedSide] = React.useState<LabelSwitchSide>(initialSide || LabelSwitchSide.Left);

  const handleChange = (newSide: LabelSwitchSide) => {
    if (newSide !== null) {
      setSelectedSide(newSide);
      onChange(newSide);
    }
  };

  return (
    <EnhancedTabs
      value={selectedSide.toString()}
      size='sm'
      variant='default'
      tabs={[
        {
          value: LabelSwitchSide.Left.toString(),
          title: leftLabel,
        },
        {
          value: LabelSwitchSide.Right.toString(),
          title: rightLabel,
        },
      ]}
      onChange={(val) => handleChange(Number(val) as LabelSwitchSide)}
    />
  );
};

import env from '@shared/config';

// eslint-disable-next-line @typescript-eslint/naming-convention
const __DEV__ = process.env.NODE_ENV === 'development';

/**
 * True when running in development environment
 */
export const isDev = !!__DEV__;

/**
 * True when only stable features should be available
 */
export const showStableFeaturesOnly = env.STABLE_FEATURES_ONLY;

import { useCallback, useContext } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { ApiKeysContext } from '@routes/Profile/subroutes/ApiKeys/ApiKeys.context';

import { useQuery } from '@tanstack/react-query';

const useFetchApiKeys = () => {
  const { userId } = UserStore.useUserStore;
  const { setApiKeys } = useContext(ApiKeysContext);

  const fetchApiKeys = useCallback(async () => {
    const response = await api.endpoints.getAPIKeys();
    setApiKeys(Array.from(response.data));
    return response.data;
  }, [setApiKeys]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['api-keys', userId],
    queryFn: fetchApiKeys,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { status, data, error, isFetching, isPreviousData, isStale };
};

export { useFetchApiKeys };

import React, { useMemo, useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { PhoneFilled } from '@swyftx/aviary/icons/filled';

import { ENTITY_ASSISTANCE_PHONE_CONSULT_URL } from '@shared/constants/urls';
import { cn } from '@shared/utils/lib/ui';

import { useEntityOnboardingSelector } from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';
import { useStepId } from '@routes/EntityOnboarding/hooks/useStepId';
import { useStepperSteps } from '@routes/EntityOnboarding/hooks/useStepperSteps';
import { EntityOnboardingStepId } from '@routes/EntityOnboarding/types/EntityOnboarding.types';

import { observer } from 'mobx-react-lite';

import { EntityOnboardingStepperItem } from './EntityOnboardingStepperItem';

const EntityOnboardingStepper: React.FC = observer(() => {
  const { isActive, isStepComplete, steps, fetchStepNumber, activeRootStep } = useStepperSteps();
  const { phoneConsultLinkCtaClicked } = useEntityOnboardingAnalytics();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);
  const { stepId } = useStepId();
  const isXs = useTailwindBreakpoint('xs');

  const showAssistancePhoneConsultNotification = useMemo(
    () => !['entityType', 'introduction'].includes(activeRootStep?.id ?? '') && !isXs,
    [activeRootStep?.id, isXs],
  );

  const handleOpenPhoneConsultLink = useCallback(() => {
    const entityType = applicationData.type;
    phoneConsultLinkCtaClicked({
      entityType,
      stepId,
      callToActionLocation: 'application_stepper',
    });
    window.open(ENTITY_ASSISTANCE_PHONE_CONSULT_URL, '_blank', 'noopener,noreferrer');
  }, [applicationData.type, phoneConsultLinkCtaClicked, stepId]);

  if (activeRootStep?.id === ('formSubmitted' as EntityOnboardingStepId)) return null;

  return (
    <FlexLayout direction='column' spacing={8} className='fixed'>
      {steps.map((step, index) => (
        <FlexLayout key={step.id} direction='column' spacing={8}>
          <EntityOnboardingStepperItem
            key={step.id}
            step={step}
            isActive={isActive(step)}
            stepNumber={fetchStepNumber(step)}
            root
          />
          {index !== steps.length - 1 && (
            <FlexLayout
              className={cn(
                'ml-14 h-16 w-10 border-l-2',
                isStepComplete(step) ? 'border-color-background-primary' : 'border-color-divider border-dashed',
              )}
            />
          )}
        </FlexLayout>
      ))}
      {showAssistancePhoneConsultNotification && (
        <FlexLayout direction='column' className='max-w-[300px] pt-12'>
          <Notification
            title='Need help? Book a phone consult'
            icon={<PhoneFilled className='min-w-[24px] text-color-text-accent' />}
          >
            <FlexLayout direction='column' spacing={12}>
              <Body>Our friendly team can discuss the entity application process with you.</Body>
              <BodyLink
                weight='emphasis'
                className='w-full cursor-pointer text-color-text-accent'
                onClick={handleOpenPhoneConsultLink}
              >
                Book a call
              </BodyLink>
            </FlexLayout>
          </Notification>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export { EntityOnboardingStepper };

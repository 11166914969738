import React, { PropsWithChildren } from 'react';

import { Modal } from '@swyftx/aviary/atoms/Modal';

import { useDustingModal } from './useDustingModal';

type Props = {
  defaultOpen?: boolean;
};

const DustingModal: React.FC<PropsWithChildren<Props>> = ({ children, defaultOpen }) => {
  const { content, title, position, open, setOpen } = useDustingModal({ defaultOpen });

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      triggerElement={children}
      title={title}
      showCloseButton
      className='min-w-[100vw] sm:min-h-[400px] sm:!min-w-[400px] sm:!max-w-[400px]'
      position={position}
    >
      {content}
    </Modal>
  );
};

export { DustingModal };

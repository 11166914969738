import { Asset } from '@shared/api';

const ITEM_SLIDER_ICON_SIZE = 25; // px
const ITEM_SLIDER_ALL_WIDTH = 40; // px
const ITEM_SLIDER_ICON_PADDING = 4; // px

export type SliderItem =
  | Asset
  | {
      icon: JSX.Element;
      id: number;
      color: string;
    };

export { ITEM_SLIDER_ICON_SIZE, ITEM_SLIDER_ALL_WIDTH, ITEM_SLIDER_ICON_PADDING };

import { useCallback } from 'react';

import { balanceService } from '@shared/services';
import { AppStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

const useDemoMode = () => {
  const avo = useAvo();
  const { isDemo, setIsDemo } = AppStore.useAppStore;

  const setDemoMode = useCallback(
    (checked: boolean) => {
      if (isDemo !== checked) {
        avo.demoModeToggled({
          screen: window.location.pathname,
          enabled: isDemo,
        });
      }

      setIsDemo(checked);
      balanceService.forceUpdate();
    },
    [avo, isDemo, setIsDemo],
  );

  const toggleDemoMode = useCallback(() => {
    setDemoMode(!isDemo);
  }, [isDemo, setDemoMode]);

  return {
    isDemo,
    setDemoMode,
    toggleDemoMode,
  };
};

export { useDemoMode };

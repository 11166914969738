/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, TextFieldProps } from '@swyftx/react-web-design-system';

import { CountryDropdown } from '@global-components/Dropdown';

import { CountriesEnum } from '@shared/enums';

import PhoneNumberValidator from '@hooks/validator/types/phone.validator';

import { CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

import { useValidation } from '../../../hooks/validator/useValidation';

type PhoneNumberInputFormProps = {
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  success?: boolean;
  country: CountriesEnum;
  onCountryChange?: (country: CountriesEnum) => void;
  setValid?: (valid: boolean) => void;
  hideLabel?: boolean;
} & TextFieldProps;

const PhoneNumberInput: React.FC<PhoneNumberInputFormProps> = ({
  onCountryChange,
  attachForm,
  hideLabel,
  onChange,
  setValid,
  country,
  value,
  id,
  ...props
}) => {
  const [phoneCountry, setPhoneCountry] = useState<CountriesEnum>(country);
  const { t } = useTranslation('common');

  const validatorProps = useValidation(
    [new PhoneNumberValidator(phoneCountry, t('errors.input.invalid', { label: 'Mobile phone number' }))],
    false,
    onChange,
    value,
    [phoneCountry],
  );

  const onInputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const containsDigitsOnly = (inputValue: string): boolean => /^[0-9]+$/.test(inputValue);

    if (!event.target.value || containsDigitsOnly(event.target.value)) {
      validatorProps.onChange(event);
    } else if (onChange) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (attachForm) attachForm(id!, validatorProps.validate);
  }, [attachForm]);

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  useEffect(() => {
    if (onCountryChange) {
      onCountryChange(phoneCountry);
    }
  }, [phoneCountry]);

  return (
    <Input
      id={id}
      PII
      type='tel'
      label={!hideLabel ? t('labels.phoneNumber') : undefined}
      placeholder={getExampleNumber(phoneCountry as CountryCode, examples)?.formatNational()}
      InputProps={{
        sx: { height: '48px', fontSize: '14px' },
        onChange: (event) => onInputValueChange(event),
      }}
      inputProps={{
        startAdornment: (
          <CountryDropdown id={`${id}-country-prefix`} value={phoneCountry} onChange={setPhoneCountry} compact />
        ),
      }}
      ContainerProps={{
        sx: { marginBottom: validatorProps.error ? { xs: 1, sm: 0 } : { xs: 3, sm: 2 } },
      }}
      sx={{
        width: '100%',
        '.MuiFormHelperText-root': {
          marginTop: '4px',
          lineHeight: '1',
        },
        '.MuiInputBase-root': {
          backgroundColor: 'var(--color-background-surface-secondary)',
          fontSize: 12,
          fontWeight: 500,
          padding: 1,
        },
      }}
      {...props}
      {...validatorProps}
    />
  );
};

export { PhoneNumberInput };

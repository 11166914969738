import { useMemo } from 'react';

import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';
import { Bank, Wallet } from '@swyftx/aviary/icons/outlined';

import { RecurringOrderSource } from '@shared/enums';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

const useFundOtherWithOptions = () => {
  const countryAsset = useCountryAsset();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const fundOrderWithOptions: SelectItemData[] = useMemo(
    (): SelectItemData[] => [
      {
        label: `${countryAsset?.code} Wallet`,
        leadingIcon: <Wallet className='h-20 w-20 text-color-text-primary' />,
        value: RecurringOrderSource.ACCOUNT,
        disabled: !isFeatureEnabled(AppFeature.AutoInvestFromBalances),
      },
      {
        label: 'Recurring bank transfer',
        leadingIcon: <Bank className='h-20 w-20 text-color-text-primary' />,
        value: RecurringOrderSource.DEPOSIT,
        disabled: countryAsset?.code !== 'AUD',
      },
    ],
    [countryAsset?.code, isFeatureEnabled],
  );

  const getFundOrderWithOption = (source: RecurringOrderSource) => fundOrderWithOptions.find((o) => o.value === source);

  return { fundOrderWithOptions, getFundOrderWithOption };
};

export { useFundOtherWithOptions };

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

const useDynamicPriceAlertsAnalytics = () => {
  const avo = useAvo();
  const location = useLocation();

  const alertsUIClicked = useCallback(() => {
    avo.alertsUiClicked({ screen: location.pathname });
  }, [avo, location.pathname]);

  const priceAlertMultiAssetCreated = useCallback(
    (assetIds: number[]) => {
      avo.priceAlertMultiAssetCreated({ screen: location.pathname, alertType: 'volatility', assetIds });
    },
    [avo, location.pathname],
  );

  return {
    alertsUIClicked,
    priceAlertMultiAssetCreated,
  };
};

export { useDynamicPriceAlertsAnalytics };

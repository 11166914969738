import React from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { Grid, Stack, Typography } from '@swyftx/react-web-design-system';

import { NewsItem } from '@shared/api';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { decode } from 'html-entities';
import { NavigationURLs } from 'src/lib/navigation/types';

interface Props {
  newsItem: NewsItem;
  buildAsset: React.ReactNode;
  relativeTime: string;
  isMobile?: boolean;
  tileSize?: string;
}

const NewsFeedTileThumbnailView: React.FC<Props> = ({
  newsItem,
  buildAsset,
  relativeTime,
  isMobile,
  tileSize,
}: Props) => {
  const { bx } = useContentBreakpoint();
  const location = useLocation();
  const isDashboard = location.pathname === NavigationURLs.Dashboard;

  const getMaxHeight = () => {
    if (isMobile) {
      if (isDashboard) {
        return '130px';
      }
      return '140px';
    }
    if (isDashboard) {
      return '174px';
    }
    return '178px';
  };

  const imageDimensions = {
    height: {
      xs: 86,
      sm: 120,
      md: tileSize === 'half' ? 120 : 158,
    },
    width: {
      xs: 86,
      sm: 160,
      md: tileSize === 'half' ? 120 : 210,
    },
  };

  return (
    <Grid container sx={{ height: getMaxHeight() }} spacing={2}>
      <Grid item xs={8} sm={9} xl={10} paddingTop={0.5}>
        <Stack
          sx={{
            maxHeight: isMobile ? '54px' : '104px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 2,
          }}
        >
          <Typography fontSize={isDashboard ? 12 : 14} fontWeight={600} sx={{ marginBottom: 2 }}>
            {decode(newsItem.title)}
          </Typography>
          {!isMobile && newsItem.excerpt?.length > 0 && (
            <Typography
              fontSize={isDashboard ? 12 : 14}
              textOverflow='ellipsis'
              overflow='hidden'
              sx={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
            >
              {decode(newsItem.excerpt)}...
            </Typography>
          )}
        </Stack>
        <Box marginY={1}>{buildAsset}</Box>
        <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={1}>
          <Typography color='text.secondary' fontSize={12} fontWeight={500} display='inline-block'>
            {newsItem.sourceName}
          </Typography>
          <Typography color='text.secondary' fontSize={14} fontWeight={500} number>
            &#183;
          </Typography>
          <Typography fontSize={12} color='text.secondary' display='inline-block'>
            {relativeTime}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4} sm={3} xl={2} paddingTop={1} display='flex' justifyContent='flex-end' position='relative'>
        <Box
          sx={{
            height: isMobile ? imageDimensions.height.xs : bx(imageDimensions.height),
            width: isMobile ? imageDimensions.width.xs : bx(imageDimensions.width),
            background: `url(${isMobile ? newsItem.imageMobileThumbnail : newsItem.imageThumbnail})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: 1,
          }}
        />
      </Grid>
    </Grid>
  );
};

export { NewsFeedTileThumbnailView };

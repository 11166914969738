import React from 'react';

import { InformationFilled } from '@swyftx/aviary/icons/filled';
import { PIITypes, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  title: string;
  content?: string | JSX.Element;
  footer?: JSX.Element;
} & PIITypes;

export const InformationMessageBox: React.FC<Props> = ({ title, content, footer }) => (
  <Stack PII spacing={2} direction='row'>
    <InformationFilled className='h-20 w-20' />
    <Stack spacing={1}>
      <Typography fontSize={pxToRem(16)} fontWeight={600}>
        {title}
      </Typography>
      {content &&
        (typeof content === 'string' ? (
          <Typography fontSize={pxToRem(14)} color='text.secondary'>
            {content}
          </Typography>
        ) : (
          content
        ))}
      {footer}
    </Stack>
  </Stack>
);

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';

import { Asset } from '@shared/api';
import { Links } from '@shared/constants';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedAsset: Asset;
};

const SwyftxProTradeLimitedFunctionalityWarning: React.FC<Props> = observer(({ selectedAsset }) => (
  <FlexLayout spacing={8} alignItems='start' justifyContent='start'>
    <AlertFilled className='!h-24 !w-24' />
    <div>
      <Body className='inline' weight='emphasis' size='small'>
        {selectedAsset.code}
      </Body>
      <Body className='inline' size='small'>
        {' '}
        has{' '}
      </Body>
      <BodyLink
        size='small'
        href={Links.help.lowLiquidityAndLimited}
        className='ml-2 inline cursor-pointer underline decoration-dashed decoration-1 underline-offset-4'
      >
        limited functionality.
      </BodyLink>
      <Body className='ml-2 inline' size='small'>
        Sending & receiving is currently not available.
      </Body>
    </div>
  </FlexLayout>
));

export { SwyftxProTradeLimitedFunctionalityWarning };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  title: string;
  icon?: React.ReactNode;
  value: string;
  subValue?: string;
  valueClassName?: string;
};

const QuickBuyWidgetReviewItem: React.FC<Props> = ({ title, icon, value, subValue, valueClassName }) => (
  <FlexLayout alignItems='start' justifyContent='space-between' className='w-full'>
    <Body size='small' weight='emphasis'>
      {title}
    </Body>
    <FlexLayout direction='column' alignItems='end'>
      <FlexLayout alignItems='center' spacing={4}>
        {icon}
        <Body size='small' weight='emphasis' color='secondary' className={valueClassName}>
          {value}
        </Body>
      </FlexLayout>
      {subValue && (
        <Body size='small' weight='emphasis' color='secondary' className={valueClassName}>
          ~{subValue}
        </Body>
      )}
    </FlexLayout>
  </FlexLayout>
);

export { QuickBuyWidgetReviewItem };

import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

const PRIMARY_COLOR = Colors.Magenta[3];

const EntityTwoMidnightThemePalette: any = {
  mode: 'dark',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#f5f7f8',
    secondary: '#b5bdc7',
    disabled: '#394756',
  },
  divider: '#22262c',
  background: {
    paper: '#101214',
    default: '#0B0D0F',
  },
  getContrastText: () => Colors.White,
  augmentColor: () => ({
    main: '#4c9cf2',
    light: '#4c9cf2',
    dark: '#4c9cf2',
    contrastText: Colors.White,
  }),
  action: {
    hover: '#28313c',
  },
  grey: {
    50: '#F5F5F6',
    100: '#F5F5F6',
    200: '#C3C6C8',
    300: '#888C92',
    400: '#565D64',
    500: '#384049',
    600: '#22262C',
    700: '#191C20',
    800: '#101214',
    900: '#0B0D0F',
    A100: '#F5F5F6',
    A200: '#C3C6C8',
    A400: '#565D64',
    A700: '#191C20',
  },
  primary: {
    main: PRIMARY_COLOR,
  },
  secondary: {
    main: '#4c9cf2',
  },
  error: {
    main: '#e05e54',
  },
  warning: {
    main: '#f1814c',
  },
  info: {
    main: '#4c9cf2',
  },
  success: {
    main: '#4ea75a',
  },
  common: {
    black: Colors.Black,
    white: Colors.White,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: '#101214',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
};

const EntityTwoMidnightTheme = createTheme(EntityTwoMidnightThemePalette);

export { EntityTwoMidnightTheme };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Clock, Dollar } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { AppStore } from '@shared/store';

import { countryTextFactory } from '@services/DepositService';

import { PromptLineItem } from '../../../SingleWalletFiatDeposit/components/SingleWalletFiatDepositSelector/PromptLineItem';

type Props = {
  setContinueToWithdrawals: React.Dispatch<React.SetStateAction<boolean>>;
  asset: Asset;
};

interface WithdrawalCardContent {
  title: string;
  description: string;
  icon: React.ReactNode;
}

interface WithdrawlLanding {
  cards: WithdrawalCardContent[];
}

const SingleWalletFiatWithdrawalLanding: React.FC<Props> = ({ setContinueToWithdrawals, asset }) => {
  const { setWithdrawalLandingRead } = AppStore.useAppStore;

  const [checked, setChecked] = useState(false);

  const { t } = useTranslation('wallet');

  const handleClick = () => {
    if (checked) setWithdrawalLandingRead(true);
    setContinueToWithdrawals(true);
  };

  const withdrawalLandingText = (country: string): WithdrawlLanding =>
    countryTextFactory<WithdrawlLanding>({
      nzd: {
        cards: [
          {
            title: 'Daily Withdrawal Limits',
            description:
              'The minimum withdrawal is $30 NZD per transaction,' +
              ' and the maximum withdrawal amount is $50,000 NZD per day.' +
              ' To request increased limits, please contact us.',
            icon: <Clock />,
          },
          {
            title: 'Withdrawal & Dishonour Fees',
            description: `All NZD withdrawals will incur a $10 NZD fee.
            Withdrawals made to incorrect accounts will result in a $40 NZD fee.`,
            icon: <Dollar />,
          },
          {
            title: 'Withdrawal Processing Times',
            description:
              'Withdrawals are processed within 1-3 business days and will arrive in your bank account after 8-10 business days.' +
              ' Please contact support if your withdrawal has not arrived after this time.',
            icon: <Dollar />,
          },
        ],
      },
      aud: {
        cards: [
          {
            title: 'Daily Withdrawal Limits',
            description:
              'The maximum withdrawal amount is $50,000 per day. To request increased limits, please contact us.',
            icon: <Clock />,
          },
          {
            title: 'Withdrawal & Dishonour Fees',
            description:
              'Withdrawals made to the correct account do not incur an fees.' +
              ' Any withdrawals made to incorrect accounts will result in a $40 dishonour fee per withdrawal.',
            icon: <Dollar />,
          },
        ],
      },
      currentCountry: country,
    });

  const text = withdrawalLandingText(asset.code);

  return (
    <FlexLayout direction='column' spacing={16} justifyContent='start'>
      <Body weight='bold' size='large'>
        {t('singleWalletFiatWithdrawal.landing.withdrawing')} {`${asset.code.toUpperCase()} `}
        {t('singleWalletFiatWithdrawal.landing.firstTime')}
      </Body>
      <Body>{t('singleWalletFiatWithdrawal.landing.description')}</Body>

      {text.cards.map((card) => (
        <PromptLineItem key={card.title} title={card.title} icon={card.icon} subtitle={card.description} />
      ))}

      <FlexLayout className='mb-8' spacing={8}>
        <Checkbox checked={checked} onCheckedChange={() => setChecked(!checked)} />
        <Body onClick={() => setChecked(!checked)} className='cursor-pointer'>
          {t('singleWalletFiatWithdrawal.landing.buttonLabels.dontShow')}
        </Body>
      </FlexLayout>

      <Button variant='filled' color='primary' size='lg' onClick={() => handleClick()}>
        {t('singleWalletFiatWithdrawal.landing.buttonLabels.continue')}
      </Button>
    </FlexLayout>
  );
};

export { SingleWalletFiatWithdrawalLanding };

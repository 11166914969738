import { useContext, useMemo } from 'react';

import { DateTime } from 'luxon';

import { TradingViewContext } from '../../../context/TradingView.context';
import { SimpleResolutionItem } from '../../../types';

const useTradingViewSimpleResolution = () => {
  const { selectedSimpleResolution, setSelectedSimpleResolution } = useContext(TradingViewContext);

  const items: SimpleResolutionItem[] = useMemo(
    () => [
      {
        text: '1D',
        value: '1D',
        date: DateTime.now().minus({ day: 1 }),
      },
      {
        text: '1W',
        value: '1W',
        date: DateTime.now().minus({ week: 1 }),
      },
      {
        text: '1M',
        value: '1M',
        date: DateTime.now().minus({ month: 1 }),
      },
      {
        text: '3M',
        value: '3M',
        date: DateTime.now().minus({ months: 3 }),
      },
      {
        text: '6M',
        value: '6M',
        date: DateTime.now().minus({ months: 6 }),
      },
      {
        text: '1Y',
        value: '1Y',
        date: DateTime.now().minus({ year: 1 }),
      },
      {
        text: 'All',
        value: 'All',
        date: DateTime.fromISO('2018-01-01'),
      },
    ],
    [],
  );

  const selectedItem = useMemo(
    () => items.find((i) => i.value === selectedSimpleResolution) || items[0],
    [items, selectedSimpleResolution],
  );

  return {
    selectedItem,
    items,
    setSelectedSimpleResolution,
  };
};

export { useTradingViewSimpleResolution };

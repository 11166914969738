import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Heart, QuickBuy } from '@swyftx/aviary/icons/outlined';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import env from '@shared/config';
import { assetService } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { QuickBuyPageState, QuickBuyType } from 'src/lib/trade/types/Trade.types';
import { QuickBuyWidget } from 'src/lib/trade/widgets';

import { DEFAULTS } from './QuickBuy.consts';

type Props = {
  className?: string;
};

const QuickBuyPage: React.FC<Props> = observer(({ className }) => {
  const location = useLocation();
  const usersnapApi = useUsersnapApi();
  const isXs = useTailwindBreakpoint('xs');

  const {
    assetCode,
    allowAssetChange = DEFAULTS.allowAssetChange,
    allowPaymentChange = DEFAULTS.allowPaymentChange,
  } = (location.state || DEFAULTS) as QuickBuyPageState;

  const tradeSide = useMemo((): QuickBuyType | undefined => {
    if (!assetCode) return undefined;
    const asset = assetService.getAssetByCode(assetCode);
    if (asset?.buyDisabled) return 'sell';

    return 'buy';
  }, [assetCode]);

  return (
    <Page
      showCompactHeader={false}
      alignment='center'
      className={cn('pr-0 @container', className, isXs ? 'mt-[-36px]' : '!mt-0 !pt-0')}
      customHeader={
        !isXs && (
          <FlexLayout
            className='w-full pt-24 sm:pb-32'
            direction='column'
            alignItems='center'
            justifyContent='center'
            spacing={8}
          >
            <Icon color='accent' className='hidden h-48 w-48 text-color-icon-accent @lg:block' icon={<QuickBuy />} />

            <FlexLayout direction='column' alignItems='center'>
              <Heading size='h3'>Quick Buy</Heading>
              <Body size='small' color='secondary'>
                The easiest way to buy and sell crypto.
              </Body>
            </FlexLayout>
          </FlexLayout>
        )
      }
    >
      <PageContent offset className='-mt-[196px] !p-8 @container @sm:p-32 @md:p-24'>
        <FlexLayout direction='column' justifyContent='center' alignItems='center' spacing={8}>
          <QuickBuyWidget
            assetCode={assetCode}
            tradeSide={tradeSide}
            allowAssetChange={allowAssetChange}
            allowPaymentChange={allowPaymentChange}
          />
          <Button
            variant='outlined'
            leadingIcon={<Heart className='!h-[20px] ' />}
            className='mt-12'
            size='md'
            onClick={() => {
              usersnapApi?.show(env.USERSNAP_PROJECT_ID_QUICK_BUY).then((widgetApi) => widgetApi.open({}));
            }}
          >
            Have feedback?
          </Button>
        </FlexLayout>
      </PageContent>
    </Page>
  );
});

export { QuickBuyPage };

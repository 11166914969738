import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

const useFetchTriggerNotifications = (secondary?: number) => {
  const { userId } = UserStore.useUserStore;

  const fetchDynamicPriceAlerts = useCallback(async () => {
    const response = await api.endpoints.getTriggerNotifications({ query: { secondary } });

    return response.data;
  }, [secondary]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['trigger-notifications', secondary, userId],
    queryFn: fetchDynamicPriceAlerts,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { status, data, error, isFetching, isPreviousData, isStale };
};

export { useFetchTriggerNotifications };

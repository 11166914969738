import React from 'react';

import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Category } from '@shared/api/@types/categories';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useCategoriesTable } from '../../hooks/useCategoriesTable';
import { CategoriesTableData } from '../../types/Markets.types';

const CategoriesTable: React.FC = () => {
  const { headers, data, initialSort, onSort } = useCategoriesTable();
  const { navigate } = useNavigateRoute();

  const onSelectItem = (item: EnhancedTableData<CategoriesTableData, Category>) => {
    if (!item.value) return;

    navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: item.value.id } });
  };

  return (
    <EnhancedTable<CategoriesTableData, Category>
      headers={headers}
      data={data}
      onSort={onSort}
      sort={initialSort}
      onClickItem={onSelectItem}
      cellClassName='py-14'
      inset
      itemsPerPage={10}
    />
  );
};

export { CategoriesTable };

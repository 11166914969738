import { LoginStep } from '@Login/types';

import { SignIn, TwoFactorEnrolment, TwoFactorLogin, TwoFactorLost, TwoFactorRecovery } from './components';
import { NewLoginDetected } from './components/NewLoginDetected/NewLoginDetected';

export const LoginSteps: { [key in LoginStep]: React.ReactElement } = {
  [LoginStep.SignIn]: <SignIn />,
  [LoginStep.NewLoginDetected]: <NewLoginDetected />,
  [LoginStep.TwoFactorLogin]: <TwoFactorLogin />,
  [LoginStep.TwoFactorRecovery]: <TwoFactorRecovery />,
  [LoginStep.TwoFactorLost]: <TwoFactorLost />,
  [LoginStep.TwoFactorEnrolment]: <TwoFactorEnrolment />,
};

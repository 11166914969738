import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Modal, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { SwyftxError } from '@shared/error-handler';
import { UIStore } from '@shared/store';

import OtcService from '@services/OtcService/OtcService';

import OTCTradesPermissionModalProps from './@types/OTCTradePermissionModal.types';

export const OTCTradesPermissionModal: React.FC<OTCTradesPermissionModalProps> = ({
  toEnable,
  entityUuid,
  onClose,
}) => {
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('modals');

  const disableOTCTrades = async () => {
    try {
      await OtcService.optInOtc(false, entityUuid);

      addMessageBox({
        content: <SuccessMessageBox title={t('otcTradePermissionsModal.disable.success')} />,
      });
    } catch (e) {
      const message = (e as SwyftxError)?.errorMessage;
      addMessageBox({
        content: <ErrorMessageBox title={message || t('otcTradePermissionsModal.disable.fail')} />,
      });
    }
    onClose();
  };

  const enableOTCTrades = async () => {
    try {
      await OtcService.optInOtc(true, entityUuid);
      addMessageBox({
        content: <SuccessMessageBox title={t('otcTradePermissionsModal.enable.success')} />,
      });
    } catch (e) {
      const message = (e as SwyftxError)?.errorMessage;
      addMessageBox({
        content: <ErrorMessageBox title={message || t('otcTradePermissionsModal.enable.fail')} />,
      });
    }
    onClose();
  };

  const getBody = () => {
    if (toEnable) {
      return (
        <Stack spacing={2}>
          <Typography color='text.secondary'>
            {t('otcTradePermissionsModal.enable.description1')}
            <br />
            <br />
            {t('otcTradePermissionsModal.enable.description2')}
            <br />
            <br />
            {t('otcTradePermissionsModal.enable.description3')}
          </Typography>
          <Divider />
          <Stack spacing={2} direction='row' alignSelf='flex-end'>
            <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
              {t('otcTradePermissionsModal.enable.buttonLabels.cancel')}
            </Button>
            <Button variant='contained' color='primary' onClick={() => enableOTCTrades()} disableElevation>
              {t('otcTradePermissionsModal.enable.buttonLabels.agree')}
            </Button>
          </Stack>
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Typography color='text.secondary'>{t('otcTradePermissionsModal.disable.description')}</Typography>
        <Divider />
        <Stack spacing={2} direction='row' alignSelf='flex-end'>
          <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
            {t('otcTradePermissionsModal.disable.buttonLabels.cancel')}
          </Button>
          <Button variant='contained' color='error' onClick={() => disableOTCTrades()} disableElevation>
            {t('otcTradePermissionsModal.disable.buttonLabels.agree')}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Modal
      id='otc-trades-permission-modal'
      open
      onClose={onClose}
      sx={{ maxWidth: pxToRem(640) }}
      HeaderProps={{
        title: toEnable ? t('otcTradePermissionsModal.enable.title') : t('otcTradePermissionsModal.disable.title'),
      }}
    >
      {getBody()}
    </Modal>
  );
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { Cross, Information, Percent } from '@swyftx/aviary/icons/outlined';

import { SwyftxProFeeTiersModal } from '../../SwyftxProFeeTiersModal/SwyftxProFeeTiersModal';
import { SwyftxProOrderTypesModal } from '../../SwyftxProOrderTypesModal';

type Props = {
  onClose?: () => void;
};

const SwyftxProTradePanelHeader: React.FC<Props> = ({ onClose }) => (
  <FlexLayout className='w-full' alignItems='center' justifyContent='space-between' spacing={12}>
    <FlexLayout className='min-h-[3rem]' alignItems='center'>
      <Heading size='h6' className='uppercase'>
        Place order
      </Heading>
    </FlexLayout>

    <FlexLayout alignItems='center' spacing={8}>
      <SwyftxProFeeTiersModal>
        <Button layout='icon' variant='ghost' leadingIcon={<Percent />} tooltip='View trading fees' />
      </SwyftxProFeeTiersModal>
      <SwyftxProOrderTypesModal>
        <Button layout='icon' variant='ghost' leadingIcon={<Information />} tooltip='View order type info' />
      </SwyftxProOrderTypesModal>
      {onClose && <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={onClose} />}
    </FlexLayout>
  </FlexLayout>
);

export { SwyftxProTradePanelHeader };

import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';

import { ToastType } from '@swyftx/aviary/atoms/Toast/Toast.types';

type ToastManagerContextType = {
  toasts: ToastType[];
  addToast: (toast: ToastType) => void;
  getNextToast: () => ToastType | undefined;
};

const ToastManagerContext = createContext<ToastManagerContextType>({
  toasts: [],
  addToast: () => undefined,
  getNextToast: () => undefined,
});

type Props = {
  mock?: boolean;
};

const ToastManagerContextProvider: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const addToast = useCallback((toast: ToastType) => {
    setToasts((prev) => [...prev, toast]);
  }, []);

  const getNextToast = useCallback(() => {
    const toastCache = Array.from(toasts);
    const nextToast = toastCache.shift();

    setToasts(toastCache);

    return nextToast;
  }, [toasts]);

  return (
    <ToastManagerContext.Provider value={{ toasts, addToast, getNextToast }}>{children}</ToastManagerContext.Provider>
  );
};

export { ToastManagerContextProvider, ToastManagerContext };

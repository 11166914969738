import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

type Props = {
  isAdmin: boolean;
};

const OTCTrades: React.FC<Props> = observer(({ isAdmin }) => {
  const { userProfile, entityUuid } = UserStore.useUserStore;
  const isTradeOnBehalfEnabled = userProfile?.accountStatus?.tradeOnBehalf;
  const isDisabled = isTradeOnBehalfEnabled == null || !isAdmin;
  const checked = Boolean(isTradeOnBehalfEnabled);

  const { openModal } = useModal();

  const handleSwitch = (newChecked: boolean) => {
    openModal(Modals.OTCTradesPermissionModal, { toEnable: newChecked, entityUuid });
  };

  return (
    <Switch
      id='entity-security-otc-enabled-switch'
      disabled={isDisabled}
      checked={checked}
      size='md'
      onCheckedChange={(newChecked: boolean) => handleSwitch(newChecked)}
    />
  );
});

OTCTrades.displayName = 'OTCTrades';

export { OTCTrades };

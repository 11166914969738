import React, { useEffect, useState, useContext } from 'react';

import env from '@shared/config';
import { UserStore } from '@shared/store';

import { InitOptions, loadSpace, SpaceApi } from '@usersnap/browser';

export const UsersnapContext = React.createContext<SpaceApi | null>(null);

export const UsersnapProvider = ({ initParams, children }: UsersnapProviderProps) => {
  const [usersnapApi, setUsersnapApi] = useState<SpaceApi | null>(null);
  const globalId = env.USERSNAP_GLOBAL_ID;
  const { userId, isEntity } = UserStore.useUserStore;
  const accountType = isEntity() ? 'Entity' : 'Personal';

  // use usersnap project id to trigger specific funnel
  // const projectId = env.USERSNAP_FEEDBACK_PROJECT_ID;

  useEffect(() => {
    loadSpace(globalId).then((api: SpaceApi) => {
      api.init({ user: { userUuid: userId, accountType }, ...initParams });
      setUsersnapApi(api);
    });
  }, [accountType, globalId, initParams, userId]);

  return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>;
};

interface UsersnapProviderProps {
  initParams?: InitOptions;
  children: React.ReactNode;
}

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}

import { OnboardingStepId } from '@Onboarding/types/Onboarding.types';

import { StateNode } from 'xstate';

export type Step = {
  id: OnboardingStepId;
  children: Step[];
  order: number;
  hidden?: boolean;
};

export const transformOnboardingStates = (stateNodeMap: Record<string, StateNode>, parentKey?: string): Step[] =>
  Object.values(stateNodeMap)
    .filter((stateNode) => stateNode?.meta?.step)
    .sort((s1, s2) => s1.order - s2.order)
    .reduce<Step[]>((acc, stateNode) => {
      acc.push({
        id: `${parentKey ? `${parentKey}.` : ''}${stateNode.key}` as OnboardingStepId,
        children: transformOnboardingStates(stateNode.states, stateNode.key),
        order: stateNode.order,
        hidden: stateNode.meta?.hidden,
      });
      return acc;
    }, []);

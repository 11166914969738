import React, { useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { DollarInCircle, Loading } from '@swyftx/aviary/icons/outlined';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { SourceOfWealthStatus, SourceOfWealthVerificationDto } from '@shared/api/@types/compliance';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useFetchSourceOfWealthHistory } from 'src/lib/user-profile/hooks/useFetchSourceOfWealthHistory';

interface SourceOfWealthHistory {
  history: React.ReactNode;
  lastUpdated: string;
  status: React.ReactNode;
}

/**
 * Returns the most recent added timestamp for each row.
 */
const calculateLastUpdated = (row: SourceOfWealthVerificationDto): number => {
  if (row.completedAt) return row.completedAt;
  if (row.moreInfoSubmittedAt) return row.moreInfoSubmittedAt;
  if (row.moreInfoRequestedAt) return row.moreInfoRequestedAt;
  if (row.submittedAt) return row.submittedAt;
  return row.requestedAt;
};

const calculateChipColor = (status: SourceOfWealthStatus) => {
  switch (status) {
    case SourceOfWealthStatus.PASSED:
      return 'success';
    case SourceOfWealthStatus.FAILED:
      return 'destructive';
    default:
      return 'secondary';
  }
};

const calculateChipText = (status: SourceOfWealthStatus) => {
  switch (status) {
    case SourceOfWealthStatus.PASSED:
      return 'Passed';
    case SourceOfWealthStatus.FAILED:
      return 'Failed';
    default:
      return 'Pending';
  }
};

export const SourceOfWealthHistoryTable: React.FC = observer(() => {
  const { isFetching, data: sourceOfWealthHistory } = useFetchSourceOfWealthHistory();

  const tableData: EnhancedTableData<SourceOfWealthHistory>[] = useMemo(
    () =>
      sourceOfWealthHistory
        ? sourceOfWealthHistory.map((row) => {
            const lastUpdated = DateTime.fromMillis(calculateLastUpdated(row));
            return {
              history: {
                value: 'Source of wealth check',
                element: (
                  <FlexLayout direction='row' className='items-center' spacing={16}>
                    <Icon icon={<DollarInCircle />} color='info' className='h-[24px] w-[24px]' />
                    <Body size='small'>Source of wealth check</Body>
                  </FlexLayout>
                ),
              },
              lastUpdated: {
                value: lastUpdated,
                element: <Body size='small'>{lastUpdated.toFormat('dd/MM/yyyy')}</Body>,
              },
              status: {
                value: row.status,
                element: (
                  <FlexLayout className='justify-end'>
                    <Chip variant='subtle' color={calculateChipColor(row.status)} size='sm'>
                      {calculateChipText(row.status)}
                    </Chip>
                  </FlexLayout>
                ),
              },
            };
          })
        : [],
    [sourceOfWealthHistory],
  );

  if (isFetching) {
    return (
      <FlexLayout className='w-full' alignItems='center' justifyContent='center'>
        <Loading className='animate-spin text-color-text-primary' />
      </FlexLayout>
    );
  }

  if (!sourceOfWealthHistory || sourceOfWealthHistory.length === 0) return null;

  return (
    <FlexLayout direction='column' spacing={12}>
      <Heading size='h5'>Source of wealth check history</Heading>
      <FlexLayout direction='row'>
        {!isFetching ? (
          <Card className='max-h-[20rem] w-full overflow-hidden py-4'>
            <EnhancedTable<SourceOfWealthHistory>
              headers={{
                history: {
                  title: 'History',
                  alignment: 'start',
                  sortable: false,
                  enabled: true,
                },
                lastUpdated: {
                  title: 'Last updated',
                  alignment: 'start',
                  sortable: true,
                  enabled: true,
                },
                status: {
                  title: 'Status',
                  alignment: 'end',
                  sortable: false,
                  enabled: true,
                },
              }}
              data={tableData}
              onSort={() => []}
              rowClassName='hover:bg-color-background-surface-primary cursor-default'
            />
          </Card>
        ) : (
          <Loading className='animate-spin text-color-text-primary' />
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

import { useEffect, useRef } from 'react'; // eslint-disable-line import/no-extraneous-dependencies

import { TimesEnum } from '@shared/enums';

const getRateInterval = TimesEnum.SECOND * 3;
const debounceTime = TimesEnum.SECOND * 0.5;
const instantDebounceTime = 0;

export const useGetRateInterval = (updateFn: () => void, pauseFn: () => boolean, deps: any[]) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const interval = useRef<number>(getRateInterval);

  useEffect(() => {
    startGetRateTimeout();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
    };
  }, deps);

  const startGetRateTimeout = (restart = false) => {
    if (!timer.current || restart) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }

      if (!pauseFn()) {
        timer.current = setTimeout(() => {
          timer.current = undefined;
          updateFn();
        }, interval.current);
      }
    }
  };

  const restartGetRate = () => {
    interval.current = getRateInterval;
    startGetRateTimeout(true);
  };

  const getRateAfterDebounce = () => {
    interval.current = debounceTime;
    startGetRateTimeout(true);
  };

  const getInstantRate = () => {
    interval.current = instantDebounceTime;
    startGetRateTimeout(true);
  };

  return { restartGetRate, getRateAfterDebounce, getInstantRate };
};

import React, { useCallback, useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Document } from '@swyftx/aviary/icons/outlined';
import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { api } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';

type Props = {
  orderId: string;
};

const TransactionsDownloadInvoice: React.FC<Props> = ({ orderId }) => {
  const { addToast } = useContext(ToastManagerContext);
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false);

  const downloadUserOrder = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        setDownloadingInvoice(true);
        e.preventDefault();
        e.stopPropagation();

        const res = await api.endpoints.downloadOrderInvoice({
          data: {
            orderIds: [orderId],
          },
        });

        const uInt8 = new Uint8Array(res.data);
        const blob = new Blob([uInt8], { type: res.headers['content-type'] });

        const name = `Swyftx Order Invoice - ${orderId}.pdf`;

        // on browser download file use a "clicked" <a/> object
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
        }, 0);
      } catch (e) {
        const swyftxError: SwyftxError = e as SwyftxError;
        addToast({
          type: 'destructive',
          title: swyftxError.errorMessage,
        });
      } finally {
        setDownloadingInvoice(false);
      }
    },
    [addToast, orderId],
  );

  return (
    <Button
      layout='icon'
      variant='outlined'
      leadingIcon={<Document />}
      size='sm'
      tooltip='Download transaction invoice'
      loading={downloadingInvoice}
      onClick={downloadUserOrder}
    />
  );
};

export { TransactionsDownloadInvoice };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyLeft } from '@swyftx/aviary/icons/outlined';

type Props = {
  title: string;
  onBack: () => void;
};

const QuickBuyCardHeader: React.FC<Props> = ({ title, onBack }) => (
  <FlexLayout className='h-[5.25rem] w-full p-8 sm:p-24' alignItems='center' spacing={16}>
    <Button layout='icon' leadingIcon={<ArrowLineOnlyLeft />} onClick={onBack} variant='outlined' />
    <Heading size='h5' color='primary'>
      {title}
    </Heading>
  </FlexLayout>
);

export { QuickBuyCardHeader };

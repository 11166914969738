import React from 'react';

import { PortfolioTransferContextProvider } from './PortfolioTransfer.context';
import { PortfolioTransferContent } from './PortfolioTransferContent';
import { PortfolioTransferWrapper } from './components/PortfolioTransferWrapper';

export const PortfolioTransfer: React.FC = () => (
  <PortfolioTransferContextProvider>
    <PortfolioTransferWrapper>
      <PortfolioTransferContent />
    </PortfolioTransferWrapper>
  </PortfolioTransferContextProvider>
);

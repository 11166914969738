import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ZeroKnowledge: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.86159 3.38963C4.52446 3.14897 4.05607 3.22718 3.81541 3.56431C3.57475 3.90144 3.65296 4.36983 3.99009 4.61049L6.10569 6.12068C5.98545 6.45447 5.91992 6.81436 5.91992 7.18952C5.91992 8.67216 6.94343 9.91626 8.32227 10.2536V14.5316C6.94343 14.869 5.91992 16.1132 5.91992 17.5958C5.91992 19.3377 7.33253 20.7501 9.07428 20.7501C10.816 20.7501 12.2287 19.3377 12.2287 17.5958C12.2287 16.1117 11.2032 14.8666 9.82227 14.5307V10.2546C10.2755 10.1444 10.6904 9.93621 11.0433 9.65381L14.2319 11.9331C14.091 12.2908 14.0137 12.6804 14.0137 13.088C14.0137 14.8297 15.4263 16.2423 17.1681 16.2423C18.9099 16.2423 20.3225 14.8297 20.3225 13.088C20.3225 11.3462 18.9099 9.93359 17.1681 9.93359C16.3759 9.93359 15.6518 10.2258 15.0978 10.7082L11.96 8.46522C12.1327 8.07514 12.2287 7.64351 12.2287 7.18952C12.2287 5.44772 10.8161 4.03516 9.07428 4.03516C8.24939 4.03516 7.49833 4.35198 6.93619 4.87056L4.86159 3.38963ZM7.41992 7.18952C7.41992 6.27616 8.16093 5.53516 9.07428 5.53516C9.98767 5.53516 10.7287 6.27617 10.7287 7.18952C10.7287 8.09935 9.99337 8.83817 9.08484 8.84385L9.07227 8.84375L9.06095 8.84383C8.15373 8.83666 7.41992 8.09844 7.41992 7.18952ZM7.41992 17.5958C7.41992 16.6824 8.16094 15.9414 9.07428 15.9414C9.98766 15.9414 10.7287 16.6824 10.7287 17.5958C10.7287 18.5092 9.9877 19.2501 9.07428 19.2501C8.16089 19.2501 7.41992 18.5092 7.41992 17.5958ZM15.7302 12.2694C15.5924 12.5108 15.5137 12.7902 15.5137 13.088C15.5137 14.0013 16.2547 14.7423 17.1681 14.7423C18.0815 14.7423 18.8225 14.0013 18.8225 13.088C18.8225 12.1746 18.0815 11.4336 17.1681 11.4336C16.6638 11.4336 16.2121 11.6594 15.9086 12.0154C15.8721 12.1011 15.8189 12.1813 15.749 12.2512L15.7403 12.2598L15.7302 12.2694Z'
      fill='currentColor'
    />
  </svg>
);

export { ZeroKnowledge };

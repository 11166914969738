import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { Star } from '@swyftx/aviary/icons/outlined';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { BuySellButtons } from '@global-components/BuySellButtons';

import { Category } from '@shared/api/@types/categories';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useSingleCategoryAnalytics } from '@hooks/Analytics/Categories/useSingleCategoryAnalytics';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';
import UserService from '@services/UserService';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { SingleCategoryAssetTableData } from '../SingleCategory.types';
import { SingleCategoryBuyPrice } from '../components/SingleCategoryTable/SingleCategoryBuyPrice';
import { SingleCategoryMarketCap } from '../components/SingleCategoryTable/SingleCategoryMarketCap';
import { SingleCategoryVolume } from '../components/SingleCategoryTable/SingleCategoryVolume';

type SingleCategoryHeaderData = { [key in keyof SingleCategoryAssetTableData]: EnhancedTableHeaderData };

type Props = {
  category?: Category;
};

const useSingleCategoryTable = ({ category }: Props) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const { getAssetsByCategoryId } = useMarkets();
  const favouriteAssetList = useFavouriteAssets();
  const { trackSingleCategoryTradeButtonClicked } = useSingleCategoryAnalytics();
  const [sort, setSort] = useState<EnhancedTableSort<SingleCategoryAssetTableData>>({
    sortKey: 'rank',
    sortDirection: 'DESC',
  });

  const favouriteAsset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, assetId: number, favourite: boolean) => {
    e.stopPropagation();
    e.preventDefault();

    UserService.UpdateUserSettings({
      data: { favouriteAsset: { assetId, favStatus: favourite } },
    });
  };

  const headers: SingleCategoryHeaderData = useMemo(
    () => ({
      rank: {
        title: t('table.labels.rank'),
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      asset: {
        title: t('table.labels.asset'),
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      buyPrice: {
        title: t('table.labels.buyPrice'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      dailyChange: {
        title: t('table.labels.dailyChange'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      weeklyChange: {
        title: t('table.labels.weeklyChange'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      monthlyChange: {
        title: t('table.labels.monthlyChange'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      dailyVolume: {
        title: t('table.labels.dailyVolume'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @lg:table-cell',
      },
      marketCap: {
        title: t('table.labels.marketCap'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      trade: {
        title: t('table.labels.trade'),
        alignment: 'end',
        sortable: false,
        className: 'hidden @sm:table-cell',
      },

      // Mobile headers
      assetRanked: {
        title: t('table.labels.assetRanked'),
        alignment: 'start',
        sortable: true,
        className: 'table-cell @sm:hidden',
      },
      buyPriceChange: {
        title: t('table.labels.buyPriceChange'),
        alignment: 'end',
        sortable: true,
        className: 'table-cell @sm:hidden',
      },
    }),
    [t],
  );

  const assets = useMemo(() => (category ? getAssetsByCategoryId(category.id) : []), [category, getAssetsByCategoryId]);

  const data = useMemo(
    () =>
      assets.map((asset) => {
        const rate = getRate(asset);
        const isFavourited = favouriteAssetList.findIndex((a) => asset.id === a.id) > -1;

        return {
          rank: {
            value: asset.rank,
            element: <Numeric size='small'>{asset.rank === Infinity ? '∞' : asset.rank}</Numeric>,
          },
          asset: {
            value: asset.name,
            element: (
              <FlexLayout spacing={8} alignItems='center' justifyContent='start'>
                <Button
                  layout='icon'
                  variant='ghost'
                  tooltip={isFavourited ? t('labels.unFavourite') : t('labels.favourite')}
                  leadingIcon={
                    isFavourited ? (
                      <StarFilled className='text-color-icon-accent' />
                    ) : (
                      <Star className='text-color-icon-neutral' />
                    )
                  }
                  onClick={(e) => favouriteAsset(e, asset.id, !isFavourited)}
                />
                <AssetIcon asset={asset} size={20} />
                <Body size='small' className='w-[160px]' overflow='truncate' title={asset.name}>
                  {asset.name}
                </Body>
              </FlexLayout>
            ),
          },
          buyPrice: {
            value: rate.askPrice,
            element: <SingleCategoryBuyPrice asset={asset} />,
          },
          dailyChange: {
            value: rate.dailyPriceChange || '0.00',
            element: (
              <NumericDataItem
                data={`${rate.dailyPriceChange || '0.00'}%`}
                renderIcons={false}
                percentage
                size='small'
                alignItems='end'
              />
            ),
          },
          weeklyChange: {
            value: asset.priceChange.week,
            element: (
              <NumericDataItem
                renderIcons={false}
                data={`${asset.priceChange.week.toFixed(2)}%`}
                percentage
                size='small'
                alignItems='end'
              />
            ),
          },
          monthlyChange: {
            value: asset.priceChange.month,
            element: (
              <NumericDataItem
                renderIcons={false}
                data={`${asset.priceChange.month.toFixed(2)}%`}
                percentage
                size='small'
                alignItems='end'
              />
            ),
          },
          dailyVolume: {
            value: asset.volume[baseAsset?.id || FiatIdEnum.AUD],
            element: <SingleCategoryVolume asset={asset} />,
          },
          marketCap: {
            value: Big(rate.midPrice).times(asset.circulatingSupply).toNumber(),
            element: <SingleCategoryMarketCap asset={asset} />,
          },
          trade: {
            value: '',
            element: (
              <FlexLayout justifyContent='end' spacing={4}>
                <BuySellButtons
                  asset={asset}
                  variant='aviary-subtle'
                  size='sm'
                  openTradePanel
                  postClick={(_, side) => trackSingleCategoryTradeButtonClicked(category?.name || '', asset.code, side)}
                />
              </FlexLayout>
            ),
          },

          // Mobile View Data
          assetRanked: {
            value: asset.rank,
            element: (
              <FlexLayout alignItems='center' spacing={8}>
                <AssetIcon asset={asset} size={20} />
                <FlexLayout direction='column'>
                  <Body size='small' className='w-[5rem]' overflow='truncate' title={asset.name}>
                    {asset.name}
                  </Body>
                  <Body size='small' className='w-[5rem]' overflow='truncate' color='secondary' title={asset.code}>
                    {asset.code}
                  </Body>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          buyPriceChange: {
            value: rate.askPrice,
            element: (
              <FlexLayout direction='column'>
                <Numeric size='small' className='text-right'>
                  {formatCurrency(rate.askPrice, baseAsset)}
                </Numeric>
                <NumericDataItem data={`${rate.dailyPriceChange || '0.0'}%`} percentage size='small' alignItems='end' />
              </FlexLayout>
            ),
          },
        };
      }) as unknown as EnhancedTableData<SingleCategoryAssetTableData>[],
    [assets, getRate, favouriteAssetList, t, baseAsset, trackSingleCategoryTradeButtonClicked, category?.name],
  );

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<SingleCategoryAssetTableData>) => {
      const tableData: EnhancedTableData<SingleCategoryAssetTableData>[] = Object.assign([], data);
      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => {
        switch (newSort.sortKey) {
          case 'rank':
          case 'assetRanked':
            const aValNum = a[newSort.sortKey].value as number;
            const bValNum = b[newSort.sortKey].value as number;
            if (newSort.sortDirection === 'ASC') return bValNum - aValNum;
            return aValNum - bValNum;
          case 'asset':
            const aVal = a[newSort.sortKey].value as string;
            const bVal = b[newSort.sortKey].value as string;
            if (newSort.sortDirection === 'ASC') return bVal.localeCompare(aVal);
            return aVal.localeCompare(bVal);
          case 'buyPrice':
          case 'marketCap':
          case 'dailyVolume':
          case 'monthlyChange':
          case 'weeklyChange':
          case 'dailyChange':
          case 'buyPriceChange':
            const aValBig = Big(a[newSort.sortKey].value as string);
            const bValBig = Big(b[newSort.sortKey].value as string);
            if (newSort.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
            return bValBig.lt(aValBig) ? -1 : 1;
          default:
            return 1;
        }
      });
    },
    [data],
  );

  return {
    initialSort: sort,
    headers,
    data,
    assets,
    onSort,
  };
};

export { useSingleCategoryTable };

import { LineChartData } from '@global-components/charts/LineChart/LineChart.types';

export const costBasisStartDate = (formattedData?: LineChartData) => {
  if (formattedData?.length) {
    const startDate = new Date(formattedData[formattedData.length - 1].time);
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  }
};

export const howManyDaysAgoWasStartDate = (formattedData?: LineChartData) => {
  const startDate = costBasisStartDate(formattedData);
  if (!startDate) return 365;
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - startDate.getTime();

  const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return daysAgo;
};

/**
 * Adds missing points to the data array where there were no events that would update the cost basis
 */
export const addMissingPoints = (
  existingArray: LineChartData,
  setHour: number,
  setMinute: number,
  formattedData?: LineChartData,
) => {
  const currentDate = new Date();
  currentDate.setHours(setHour, setMinute, 0, 0);
  const newArray = [];

  const daysAgo = howManyDaysAgoWasStartDate(formattedData);

  let previousValue = existingArray[0].value;
  let currentDateCopy = new Date(currentDate);

  for (let i = 0; i <= daysAgo; i++) {
    const existingEntry = existingArray.find((datum) => {
      const datumDate = new Date(datum.time);
      datumDate.setHours(setHour, setMinute, 0, 0);
      return datumDate.getTime() === currentDateCopy.getTime();
    });

    if (existingEntry) {
      newArray.push(existingEntry);
      previousValue = existingEntry.value;
    } else {
      const newEntry = {
        value: previousValue,
        time: new Date(currentDateCopy),
      };
      newArray.push(newEntry);
    }

    currentDateCopy.setDate(currentDateCopy.getDate() - 1);
  }

  return newArray;
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { ReceiveCryptoReceiveDetails } from '@global-components/Modals/ReceiveCrypto/ReceiveCryptoReceiveDetails';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { DepositAddressChangesContent } from 'src/lib/feature-discovery/components/DepositAddressChanges/DepositAddressChangesContent';

type Props = {
  asset: Asset;
};

export const Receive: React.FC<Props> = ({ asset }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const depositAddressAnnouncementEnabled = isFeatureEnabled(AppFeature.FireblocksDepositAddressAnnouncement);
  const [depositAddressChangesRead, setDepositAddressChangesRead] = useLocalStorage<boolean>(
    StorageKey.FIREBLOCKS_DEPOSIT_ADDRESS_CHANGES_READ,
    false,
  );

  if (!depositAddressChangesRead && depositAddressAnnouncementEnabled) {
    return (
      <FlexLayout direction='column' spacing={16} className='m-8 rounded-16 bg-color-background-surface-secondary p-24'>
        <Heading size='h5'>Changes to crypto deposit addresses</Heading>
        <Body color='secondary'>
          The way we generate crypto deposit addresses has changed. As a result, you will need to generate a new address
          for any crypto assets you deposit moving forward. Any crypto deposits to previous addresses may not be
          recoverable
        </Body>
        <DepositAddressChangesContent color='tertiary' />
        <FlexLayout direction='row'>
          <Button onClick={() => setDepositAddressChangesRead(true)}>I understand, continue</Button>
        </FlexLayout>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout spacing={24} className='w-full' direction='column'>
      <ReceiveCryptoReceiveDetails asset={asset} />
    </FlexLayout>
  );
};

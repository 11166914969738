import { useMemo } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';

import { Asset } from '@shared/api';
import Links from '@shared/constants/links';
import { assetService } from '@shared/services';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { excludedBundleAssets } from './Bundles.data';
import { useSort } from '../useSort';
import { AssetSort } from '../useSort/useSort';

export type BundleHelperLink = {
  label: string;
  link: string;
};

type BundlesTranslationKey = TFuncKey<'bundles', undefined>;

type BundleBootstrapData = {
  id: string;
  lastUpdatedAt: Date;
  assets: Array<string>;
  title: BundlesTranslationKey;
  description: BundlesTranslationKey;
  helperLink?: BundleHelperLink;
  feature?: AppFeature;
};

export type Bundle = {
  id: string;
  title: BundlesTranslationKey;
  description: BundlesTranslationKey;
  lastUpdatedAt: Date;
  helperLink?: BundleHelperLink;
  assets: Asset[];
};

export const useBundles = () => {
  const { t } = useTranslation('bundles', { keyPrefix: 'bundles' });
  const { sortAssets } = useSort();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const assets = assetService.getCryptoAssets().filter((assetId) => {
    const asset = assetService.getAsset(assetId);
    return !excludedBundleAssets.includes(asset?.code || '');
  });

  const sortedMarketCapAssetCodes = useMemo(() => {
    const sortedAssetCodes: string[] = [];

    sortAssets(
      assets.map((asset: Asset) => asset.id),
      false,
      AssetSort.MarketCap,
    )
      .slice(0, 10)
      .forEach((assetId: number) => {
        const asset = assetService.getAsset(assetId);
        if (asset) sortedAssetCodes.push(asset.code);
      });

    return sortedAssetCodes;
  }, [sortAssets, assets]);

  const bundles: Bundle[] = useMemo(() => {
    const bundleTemplates: Array<BundleBootstrapData> = [
      {
        id: 'big-two',
        assets: ['BTC', 'ETH'],
        title: 'bundles.big-two.title',
        description: 'bundles.big-two.description',
        lastUpdatedAt: new Date('2022-07-16'),
      },
      {
        id: 'top-5',
        assets: sortedMarketCapAssetCodes.slice(0, 5),
        title: 'bundles.top-5.title',
        description: 'bundles.top-5.description',
        lastUpdatedAt: new Date(),
      },
      {
        id: 'top-10',
        assets: sortedMarketCapAssetCodes,
        title: 'bundles.top-10.title',
        description: 'bundles.top-10.description',
        lastUpdatedAt: new Date(),
      },
      {
        id: 'staking',
        assets: ['ADA', 'SOL', 'DOT', 'FLOW', 'KAVA', 'ATOM', 'XTZ', 'ALGO', 'KSM', 'EOS'],
        title: 'bundles.staking.title',
        description: 'bundles.staking.description',
        lastUpdatedAt: new Date('2022-07-16'),
        helperLink: {
          label: t('staking.helperLink'),
          link: Links.learn.guideToStaking,
        },
      },
      {
        id: 'defi',
        assets: ['MKR', 'COMP', 'CRV', 'UNI', 'AAVE'],
        title: 'bundles.defi.title',
        description: 'bundles.defi.description',
        lastUpdatedAt: new Date('2022-07-16'),
        helperLink: {
          label: t('defi.helperLink'),
          link: Links.learn.decentralizedFinance,
        },
      },
      {
        id: 'alt',
        assets: ['SHIB', 'ETH', 'SOL', 'ADA', 'MANA'],
        title: 'bundles.alt.title',
        description: 'bundles.alt.description',
        lastUpdatedAt: new Date('2022-07-16'),
        helperLink: {
          label: t('alt.helperLink'),
          link: Links.learn.whatAreAltcoins,
        },
      },
      {
        id: 'nft',
        assets: ['ENJ', 'AXS', 'THETA', 'MANA', 'SAND', 'GALA', 'ILV', 'FLOW'],
        title: 'bundles.nft.title',
        description: 'bundles.nft.description',
        lastUpdatedAt: new Date('2022-07-16'),
      },
      {
        id: 'eco',
        assets: ['POWR', 'HBAR', 'ALGO', 'IOTA', 'XRP', 'XLM', 'PERL', 'XNO'],
        title: 'bundles.eco.title',
        description: 'bundles.eco.description',
        lastUpdatedAt: new Date('2022-07-16'),
      },
      {
        id: 'exchange',
        assets: ['BNB', 'UNI', 'CAKE', 'LRC', '1INCH', 'DYDX', 'SUSHI'],
        title: 'bundles.exchange.title',
        description: 'bundles.exchange.description',
        lastUpdatedAt: new Date('2022-07-16'),
      },
    ];

    // Replace the asset codes with the actual assets, as we need them in enough places in codebase
    // O(n^2), but this is memoized and runs only on the above hardcoded bundle assets
    return bundleTemplates
      .filter((a) => !a.feature || isFeatureEnabled(a.feature))
      .map((bundle) => ({
        ...bundle,
        assets: bundle.assets.reduce<Asset[]>((acc, code) => {
          const asset = assetService.getAssetByCode(code);

          if (asset) {
            return acc.concat(asset);
          }

          return acc;
        }, []),
      }));
  }, [t, sortedMarketCapAssetCodes]);

  return { bundles };
};

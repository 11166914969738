import React from 'react';

import CardMUI, { CardProps } from '@mui/material/Card';
import CardContentMUI from '@mui/material/CardContent';
import { SxProps } from '@mui/system';

type Props = {
  contentSx?: SxProps;
} & CardProps;

const Card: React.FC<Props> = ({ id, children, contentSx, ...props }) => (
  <CardMUI id={id} {...props}>
    <CardContentMUI id={`${id}-content`} sx={contentSx}>
      {children}
    </CardContentMUI>
  </CardMUI>
);

export default Card;

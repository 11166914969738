import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Dollar, Percent, Vip } from '@swyftx/aviary/icons/outlined';
import { TradeFeesStatuses } from '@swyftx/aviary/molecules/TradeFeesStatuses';
import { TradeFeesTable } from '@swyftx/aviary/molecules/TradeFeesTable';
import { formatCurrency } from '@swyftx/currency-util';

import { FeeType } from '@shared/api/@types/fees';
import { DEFAULT_EMPTY_VALUE } from '@shared/constants/general';

import { useTradeFeesData } from './useTradeFeesData';

const TradeFees: React.FC = () => {
  const { t } = useTranslation('profile');

  const {
    thirtyDayVolume,
    userFeeStatus: feeStatus,
    updatesIn,
    loading,
    feeTiers,
    userTierStatus,
  } = useTradeFeesData();

  const currentTierText = useMemo(
    () => (userTierStatus ? userTierStatus.currentTier?.label : DEFAULT_EMPTY_VALUE),
    [userTierStatus],
  );
  const currentFeeText = useMemo(
    () =>
      feeStatus ? (
        <FlexLayout spacing={12}>
          {`${feeStatus.feePercentage}%`}
          {feeStatus.feeType === FeeType.DISCRETIONARY && (
            <Chip size='sm' variant='subtle'>
              {t('feeTiers.discretionary')}
            </Chip>
          )}
        </FlexLayout>
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
    [feeStatus, t],
  );

  const thirtyDayVolumeText = useMemo(
    () =>
      thirtyDayVolume?.tradeVolume !== undefined
        ? formatCurrency(thirtyDayVolume.tradeVolume.toString())
        : DEFAULT_EMPTY_VALUE,
    [thirtyDayVolume?.tradeVolume],
  );

  const tradeFeesStatusContent = useMemo(
    () => [
      {
        icon: <Vip />,
        title: t('feeTiers.currentTier'),
        description: currentTierText,
      },
      {
        icon: <Percent />,
        title: t('feeTiers.currentFee'),
        description: currentFeeText,
      },
      {
        icon: <Dollar />,
        title: t('feeTiers.thirtyDayVolume'),
        description: thirtyDayVolumeText,
      },
    ],
    [currentFeeText, currentTierText, t, thirtyDayVolumeText],
  );

  return (
    <FlexLayout spacing={24} direction='column' className='@container'>
      <TradeFeesStatuses content={tradeFeesStatusContent} loading={loading} updatesIn={updatesIn} />
      <TradeFeesTable
        feeTiers={feeTiers}
        currentTier={userTierStatus?.currentTier}
        unlockedTier={userTierStatus?.unlockedTier}
        updatesIn={updatesIn}
      />
    </FlexLayout>
  );
};

export { TradeFees };

import React, { createContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { MFAEnrolment, MFARequired, OTPRequired, UserProfile } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import storage, { StorageKey } from '@shared/storage';
import { UniversalTradeStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useIntercomEvent } from '@hooks/useIntercomEvent/useIntercomEvent';
import AuthenticationService from '@services/AuthenticationService';

import { initialValues, LoginStep, LoginStoreSchema } from '@Login/types';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocalObservable } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';

export const LoginContext = createContext<LoginStoreSchema>(initialValues);

export const LoginProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation('login');
  const { loggedIn } = useIntercomEvent();
  const { update, shutdown, boot } = useIntercom();
  const { isLargeScreen } = useScreenBreakpoints();
  const isScreenXs = useTailwindBreakpoint('xs');
  const LDClient = useLDClient();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const universalTradeDefaultOpen = isFeatureEnabled(AppFeature.UniversalTradeDefaultOpen);
  const { setShowGlobalTrade } = UniversalTradeStore;
  const usersnapApi = useUsersnapApi();

  const store = useLocalObservable(
    (): LoginStoreSchema => ({
      ...initialValues,
      setEmail: (email?: string) => {
        store.email = email;
      },
      setPassword: (password?: string) => {
        store.password = password;
      },
      setOtpData: (otpData?: OTPRequired) => {
        store.otpData = otpData;
      },
      setMfaData: (mfaData?: MFARequired | MFAEnrolment) => {
        store.mfaData = mfaData;
      },
      setShowCreateAccount: (showCreateAccount: boolean) => {
        store.showCreateAccount = showCreateAccount;
      },
      setLoginStep: (loginStep: LoginStep) => {
        store.loginStep = loginStep;
        store.error = '';
      },
      setError: (error: string) => {
        store.error = error;
      },
      setSubmitting: (submitting: boolean) => {
        store.submitting = submitting;
      },
      setRecoveryUserProfile: (tempUserProfile?: UserProfile) => {
        store.recoveryUserProfile = tempUserProfile;
      },
      setMfaResetToken: (mfaResetToken: string) => {
        store.mfaResetToken = mfaResetToken;
      },
      setRemoveMfaError: (removeMfaError: string) => {
        store.removeMfaError = removeMfaError;
      },
      setTwoFactorEnableInProgress: (twoFactorEnable: boolean) => {
        store.twoFactorEnableInProgress = twoFactorEnable;
      },
      postLoginActions: async (
        data: UserProfile | MFARequired | MFAEnrolment | OTPRequired,
        email?: string,
        password?: string,
      ) => {
        if (email) store.setEmail(email);
        if (password) store.setPassword(password);

        if ((data as OTPRequired).otp?.required) {
          store.setOtpData(data as OTPRequired);
          store.setLoginStep(LoginStep.NewLoginDetected);
          return;
        }

        if ((data as MFARequired).mfa?.required) {
          store.setMfaData(data as MFARequired);
          store.setLoginStep(LoginStep.TwoFactorLogin);
          return;
        }

        if ((data as MFAEnrolment).mfa_enrollment) {
          store.setMfaData(data as MFAEnrolment);
          store.setLoginStep(LoginStep.TwoFactorEnrolment);
          return;
        }

        store.initThirdParty(data as UserProfile);

        if (universalTradeDefaultOpen && !isScreenXs) {
          setShowGlobalTrade(true);
        }

        store.cleanup();
      },
      initThirdParty: (userProfile: UserProfile) => {
        // intercom restart
        shutdown();
        boot();

        if (userProfile?.profile?.intercom) {
          LDClient?.identify({ key: userProfile?.profile?.intercom?.uuid });
          update({
            userHash: userProfile.profile.intercom.user_hash,
            userId: userProfile.profile.intercom.uuid,
            alignment: 'left',
            horizontalPadding: 20,
            hideDefaultLauncher: !isLargeScreen,
          });

          usersnapApi?.on('open', (event) => event.api.setValue('visitor', userProfile.profile.email));
        }
        loggedIn();
      },
      mfaLogin: async (code: string, challenge_type = '', oob_code = '', handleLogin = true) => {
        try {
          const mfaData = store.mfaData as MFARequired;
          store.setSubmitting(true);
          store.setError('');

          if (challenge_type !== 'rec' && Number.isNaN(Number(code))) {
            store.setError(t('errors.2FA.invalidCharacters'));
            store.setSubmitting(false);
            return;
          }

          const res = await AuthenticationService.MFALogin(
            code,
            mfaData.mfa.token,
            challenge_type || mfaData.mfa.challenge_type,
            oob_code,
            handleLogin,
          );

          if (res === undefined) {
            store.setSubmitting(false);
            return undefined;
          }

          storage.setItem(StorageKey.USER_DATA, JSON.stringify(res));

          if (handleLogin) {
            store.cleanup();
            store.postLoginActions(res);
          } else {
            store.setRecoveryUserProfile(res);
          }
          return res;
        } catch (e) {
          const { errorMessage } = e as SwyftxError;
          if (errorMessage === 'Something went wrong') {
            store.setError('Something went wrong. Please check your code and try again.');
          } else {
            store.setError(errorMessage);
          }

          return undefined;
        } finally {
          store.setSubmitting(false);
        }
      },

      lostAuthenticator: async (headers?: Record<string, string>) => {
        if (!store.mfaData) return;

        const mfaData = store.mfaData as MFARequired;

        if (!mfaData.mfa) return;

        try {
          store.setSubmitting(true);
          const res = await AuthenticationService.MFALostAuthenticator(mfaData.mfa.token, headers);
          if (!headers) store.setMfaResetToken(res);

          return res;
        } catch (e) {
          const swyftxError = e as SwyftxError;
          store.setRemoveMfaError(swyftxError.errorMessage);
        } finally {
          store.setSubmitting(false);
        }
      },
      otpLogin: async (code: string, handleLogin = true) => {
        if (!store.email || !store.otpData || !store.password) return;

        try {
          const otpData = store.otpData as OTPRequired;
          store.setSubmitting(true);
          store.setError('');

          const res = await AuthenticationService.OTPLogin(
            store.email,
            store.password,
            code,
            otpData.otp.challengeType,
            handleLogin,
          );

          if (res === undefined) {
            return undefined;
          }

          storage.setItem(StorageKey.USER_DATA, JSON.stringify(res));

          if (handleLogin) {
            store.cleanup();
            store.postLoginActions(res);
          } else {
            store.setRecoveryUserProfile(res);
          }
          return res;
        } catch (e) {
          const { errorMessage } = e as SwyftxError;
          store.setError(errorMessage);
          return undefined;
        } finally {
          store.setSubmitting(false);
        }
      },
      cleanup: () => {
        store.setError('');
        store.setOtpData(undefined);
        store.setEmail('');
        store.setPassword('');
        store.setMfaData(undefined);
        store.setSubmitting(false);
        store.setLoginStep(LoginStep.SignIn);
        store.setRemoveMfaError('');
        store.setMfaResetToken('');
        store.setRecoveryUserProfile(undefined);
        store.setTwoFactorEnableInProgress(false);
      },
    }),
  );

  return <LoginContext.Provider value={store}>{children}</LoginContext.Provider>;
};

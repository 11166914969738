import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { UniversalTradeStore } from '@shared/store';
import { TradeState } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { observer } from 'mobx-react-lite';

export const CloseUniversalTrade: React.FC = observer(() => {
  const { setShowGlobalTrade, setTradeState } = UniversalTradeStore;

  const closeUniversalTrade = () => {
    setShowGlobalTrade(false);
    setTradeState(TradeState.PlaceOrder);
  };

  return <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={closeUniversalTrade} />;
});

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Close from '@mui/icons-material/Close';
import { Box, Slide, StackProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Divider,
  Icon,
  Modal,
  ModalProps,
  PIITypes,
  pxToRem,
  Stack,
  Typography,
} from '@swyftx/react-web-design-system';

type ContainerProps = {
  children: Array<JSX.Element>;
  stepIndex: number;
  onClose: () => void;
  open: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  containerSx?: ModalProps['sx'];
  maintainStateOnIndexes?: Array<number>;
} & PIITypes;

const Container: React.FC<ContainerProps> = ({
  maintainStateOnIndexes,
  containerSx,
  stepIndex,
  children,
  maxWidth,
  onClose,
  height,
  width,
  open,
  PII,
}) => {
  const { t } = useTranslation('profile', { keyPrefix: 'misc' });
  const childProps = useMemo(() => children[stepIndex].props as StepProps, [children, stepIndex]);

  const content = (() => {
    if (maintainStateOnIndexes) {
      return (
        <>
          {children.map((child, index) => {
            const selected = index === stepIndex;
            const maintainingState = maintainStateOnIndexes.find((_, stateIndex) => stateIndex === index) !== undefined;

            if (maintainingState || selected) {
              return (
                <Box width='100%' height='100%' display={selected ? 'block' : 'none'} key={child.key}>
                  {child}
                </Box>
              );
            }

            return <></>;
          })}
        </>
      );
    }

    return children[stepIndex];
  })();

  return (
    <Modal
      PII={PII}
      variant='BottomSheet'
      sx={{
        maxWidth,
        width: width || '600px',
        height,
        maxHeight: 'calc(100% - 2rem)',
        '.MuiCardContent-root': {
          height: '100%',
          '> .MuiBox-root': { flexGrow: 1 },
          '> .MuiBox-root ~ .MuiBox-root': { flexGrow: 0 },
        },
        ...containerSx,
      }}
      id='withdraw-crypto-modal'
      onClose={onClose}
      open={open}
      HeaderProps={
        childProps.useModalHeader && childProps.title
          ? { title: childProps.title, dismissible: true, divider: true }
          : undefined
      }
      FooterProps={
        childProps.useModalFooter && !childProps.hideActions
          ? {
              divider: true,
              content: (
                <Stack>
                  <Stack justifyContent='space-between' direction='row'>
                    {childProps.onBack ? (
                      <Button
                        sx={{ color: 'text.secondary' }}
                        variant='outlined'
                        onClick={childProps.onBack}
                        color='inherit'
                      >
                        {t('buttonLabels.back')}
                      </Button>
                    ) : null}
                    <Stack
                      justifyContent='flex-end'
                      alignItems='center'
                      direction='row'
                      height='100%'
                      width='100%'
                      spacing={2}
                    >
                      {!childProps.hideCancel && (
                        <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
                          {childProps.cancelName || t('buttonLabels.cancel')}
                        </Button>
                      )}
                      {!childProps.hideContinue && (
                        <Button
                          loading={childProps.actionLoading !== undefined ? childProps.actionLoading : false}
                          variant='contained'
                          color={childProps.actionColor}
                          onClick={childProps.onAction}
                          disabled={childProps.locked}
                          disableElevation
                        >
                          {childProps.actionName || t('buttonLabels.continue')}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              ),
            }
          : undefined
      }
    >
      {content}
    </Modal>
  );
};

export type StepProps = {
  onClose: () => void;
  actionColor?: ButtonProps['color'];
  footerProps?: StackProps;
  useModalHeader?: boolean;
  useModalFooter?: boolean;
  actionLoading?: boolean;
  children?: JSX.Element;
  stickyFooter?: boolean;
  hideContinue?: boolean;
  stepLoading?: boolean;
  hideActions?: boolean;
  hideCancel?: boolean;
  actionName?: string;
  cancelName?: string;
  animate?: boolean;
  locked?: boolean;
  title?: string;
  onAction?: () => void;
  onBack?: () => void;
  style?: React.CSSProperties;
} & PIITypes;

const Step: React.FC<StepProps> = ({
  onClose,
  actionColor = 'primary',
  useModalFooter = false,
  useModalHeader = false,
  actionLoading,
  hideContinue,
  stickyFooter,
  stepLoading,
  footerProps,
  hideActions,
  actionName,
  cancelName,
  hideCancel,
  children,
  animate,
  locked,
  title,
  PII,
  onAction,
  onBack,
  style,
}) => {
  const { t } = useTranslation('profile', { keyPrefix: 'misc' });
  const theme = useTheme();
  return (
    <Stack PII={PII} spacing={2} position='relative' height={style?.height || '100%'} justifyContent='flex-start'>
      {/* title */}
      {title && !useModalHeader ? (
        <Stack spacing={2}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography fontSize={pxToRem(20)} fontWeight={700}>
              {title}
            </Typography>
            <Icon id='wizard-close' onClick={() => onClose()} sx={{ cursor: 'pointer' }} icon={<Close />} />{' '}
          </Stack>
          <Divider
            sx={{
              marginX: '-1rem !important',
            }}
          />
          {stepLoading ? (
            <LinearProgress
              sx={{
                position: 'absolute',
                marginLeft: -2,
                width: `calc(100% + ${theme.spacing(4)})`,
                height: '2px',
              }}
              variant='query'
            />
          ) : null}
        </Stack>
      ) : null}
      {/* content */}
      {animate ? (
        <Slide in direction='left'>
          <Box flex={1}>{children}</Box>
        </Slide>
      ) : (
        <Box flex={1}>{children}</Box>
      )}
      {/* footer */}
      {!hideActions && !useModalFooter && (
        <Stack {...footerProps}>
          {stickyFooter ? <Divider sx={{ marginX: '-1rem !important' }} /> : null}
          <Stack justifyContent='space-between' direction='row'>
            {onBack ? (
              <Button sx={{ color: 'text.secondary' }} variant='outlined' onClick={onBack} color='inherit'>
                {t('buttonLabels.back')}
              </Button>
            ) : null}
            <Stack
              justifyContent='flex-end'
              alignItems='center'
              direction='row'
              height='100%'
              width='100%'
              spacing={2}
              {...(stickyFooter && {
                position: 'sticky',
                paddingTop: 2,
                bottom: -0.75,
              })}
            >
              {!hideCancel && (
                <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
                  {cancelName || t('buttonLabels.cancel')}
                </Button>
              )}

              {!hideContinue && (
                <Button
                  loading={actionLoading !== undefined ? actionLoading : false}
                  variant='contained'
                  color={actionColor}
                  onClick={onAction}
                  disabled={locked}
                  disableElevation
                >
                  {actionName || t('buttonLabels.continue')}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default {
  Container,
  Step,
};

import React from 'react';

import { Box, Fade } from '@mui/material';

import { Button, List, ListItem, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset, DepositAddressDetails } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { useDeposit } from '@hooks/Deposit/useDeposit';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AppFeatureData } from 'src/config/featureFlags.data';

import { DepositInformationItem } from '../components/DepositInformationItem';
import { TransferTypeLoading } from '../components/TransferTypeLoading';

type Props = {
  asset: Asset;
};

const DepositBankTransfer: React.FC<Props> = ({ asset }) => {
  const { depositDescription, refetchAddressDetails, loading, bankAddressDetails } = useDeposit({
    asset,
    transferType: asset.code === 'NZD' ? DepositMethodEnum.NZD_BANK_TRANSFER : DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
  });

  const { isFeatureEnabled, getFeatureData } = useIsFeatureEnabled();
  const bankDepositDisabled = isFeatureEnabled(AppFeature.BankDepositDisabled);
  const disabledCopy = getFeatureData(AppFeatureData.BankDepositDisabledInformation);

  if (asset.code === 'NZD')
    return (
      <Notification
        severity='warning'
        title='New Zealand Bank Transfer deposits temporarily paused.'
        sx={{ margin: 2 }}
      >
        <Typography>
          Due to processing delays with our banking partner in New Zealand, bank transfer deposits will be temporarily
          paused from 6pm NZST on 16 June 2022. You can withdraw as usual from your Swyftx account to your bank account.
        </Typography>
      </Notification>
    );

  if (loading) return <TransferTypeLoading />;

  return (
    <Fade in timeout={500}>
      <div>
        <Stack>
          {bankDepositDisabled && asset.code === 'AUD' && (
            <Box margin={2}>
              <Notification severity='error' title={disabledCopy.title || 'Planned maintenance'}>
                <Typography fontSize={14}>{disabledCopy.detail || 'Please try another payment method.'}</Typography>
              </Notification>
            </Box>
          )}

          {!bankAddressDetails && (
            <Notification
              severity='warning'
              title='There was an issue generating deposit details'
              sx={{
                margin: 2,
                '.MuiAlertTitle-root': {
                  marginLeft: 1,
                  color: 'warning.dark',
                },
              }}
            >
              <Typography marginLeft={1} fontSize={14} fontWeight={400}>
                Please try again, and if you continue to experience issues, contact support.
              </Typography>

              <Button color='warning' sx={{ color: 'warning.dark' }} onClick={refetchAddressDetails}>
                Retry generating deposit details
              </Button>
            </Notification>
          )}

          {bankAddressDetails && (
            <>
              <Typography padding={2} color='text.secondary' fontSize={14} fontWeight='400'>
                Use the details below for a bank transfer deposit to Swyftx.
              </Typography>

              <Stack direction='column'>
                <List>
                  <ListItem divider>
                    <DepositInformationItem
                      key='deposit-information-account-name'
                      title='Account name'
                      value={depositDescription.extraBankAddressDetails?.accountName ?? ''}
                    />
                  </ListItem>

                  {depositDescription.addressDetails?.map(({ title, key }) => (
                    <ListItem divider key={key}>
                      <DepositInformationItem
                        key={`deposit-information-${title}-${key}`}
                        title={title}
                        value={bankAddressDetails ? bankAddressDetails[key as keyof DepositAddressDetails] : ''}
                      />
                    </ListItem>
                  ))}

                  <ListItem>
                    <DepositInformationItem
                      key='deposit-information-reference'
                      title='Reference'
                      value={depositDescription.extraBankAddressDetails?.reference ?? ''}
                    />
                  </ListItem>
                </List>
              </Stack>
            </>
          )}
        </Stack>
      </div>
    </Fade>
  );
};

export { DepositBankTransfer };

import { AssetHistoryItem } from '@shared/api/@types/portfolio';
import { Big } from '@shared/safe-big';

export type TransactionHistoryItem = AssetHistoryItem & { runningTotal: Big; tradedAssetId: number };

export enum TransactionSort {
  date = 'date',
  // TODO: if you want to sort by amount uncomment
  // amount = 'amount',
  userCountryCurrency = 'userCountryValue',
}

export enum TransactionTypeFilter {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum TransactionStatusFilter {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum TransactionOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EarnTypeFilter {
  Reward = 'Interest payout',
  Deposit = 'Add',
  Withdrawal = 'Remove',
}

export enum EarnItemType {
  Reward = 'REWARD',
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
}

export enum EarnFilterType {
  Add = 'Add',
  Remove = 'Remove',
  Reward = 'Reward',
}

export interface EarnHistoryItem {
  _id: number;
  amount: number;
  assetId: number;
  timestamp: number;
  type: EarnItemType;
}

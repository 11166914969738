import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Password: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.84007 9.39886C7.40333 9.39886 6.23907 10.5652 6.23907 12.0009C6.23907 13.4366 7.40333 14.6029 8.84007 14.6029C10.0168 14.6029 11.0105 13.8215 11.3324 12.7504H13.4324V13.8515C13.4324 14.2657 13.7682 14.6015 14.1824 14.6015C14.5966 14.6015 14.9324 14.2657 14.9324 13.8515V12.7504H16.2602V13.8524C16.2602 14.2666 16.596 14.6024 17.0102 14.6024C17.4244 14.6024 17.7602 14.2666 17.7602 13.8524V12.0004C17.7602 11.5862 17.4244 11.2504 17.0102 11.2504H14.221C14.2083 11.2498 14.1954 11.2495 14.1824 11.2495C14.1695 11.2495 14.1566 11.2498 14.1438 11.2504H11.3321C11.01 10.1798 10.0164 9.39886 8.84007 9.39886ZM7.73907 12.0009C7.73907 11.3926 8.23282 10.8989 8.84007 10.8989C9.4486 10.8989 9.94207 11.3928 9.94207 12.0009C9.94207 12.6089 9.4486 13.1029 8.84007 13.1029C8.23282 13.1029 7.73907 12.6092 7.73907 12.0009Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.66579 2.00043C5.9695 2.00043 4.53428 2.60605 3.52679 3.68692C2.52476 4.76195 2.00079 6.2472 2.00079 7.91643V16.0844C2.00079 17.7527 2.52195 19.2381 3.52308 20.3136C4.52986 21.3951 5.96549 22.0004 7.66579 22.0004H16.3338C18.0345 22.0004 19.4706 21.3952 20.4778 20.3137C21.4793 19.2382 22.0008 17.7528 22.0008 16.0844V7.91643C22.0008 6.24803 21.4793 4.76265 20.4779 3.68721C19.4709 2.60572 18.0351 2.00043 16.3348 2.00043H7.66579ZM3.50079 7.91643C3.50079 6.55865 3.92383 5.4609 4.62405 4.70968C5.31881 3.96431 6.34109 3.50043 7.66579 3.50043H16.3348C17.6645 3.50043 18.6867 3.96463 19.3802 4.7094C20.0793 5.46021 20.5008 6.55782 20.5008 7.91643V16.0844C20.5008 17.443 20.0793 18.5406 19.3801 19.2914C18.6865 20.0362 17.6641 20.5004 16.3338 20.5004H7.66579C6.3361 20.5004 5.31423 20.0362 4.62101 19.2915C3.92214 18.5408 3.50079 17.4431 3.50079 16.0844V7.91643Z'
      fill='currentColor'
    />
  </svg>
);

export { Password };

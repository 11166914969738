import React from 'react';

import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

interface Props {
  color?: 'accent' | 'tertiary';
}

export const DepositAddressChangesContent: React.FC<Props> = (props) => {
  const { color = 'accent' } = props;
  return (
    <FlexLayout direction='column' className='py-16' spacing={16}>
      <Body weight='bold' size='large'>
        We recommend you:
      </Body>
      <FlexLayout direction='row' spacing={16} className='items-center'>
        <Icon
          icon={
            <Body color='accent' weight='bold'>
              1
            </Body>
          }
          color={color}
          className='h-36 w-36'
        />
        <FlexLayout direction='column' spacing={4} className='justify-center'>
          <Body weight='bold'>Generate your new crypto deposit address</Body>
          <Body size='small' color='secondary'>
            Once you&apos;ve selected a crypto asset to deposit, follow the prompts to generate your new crypto deposit
            address.
          </Body>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='row' spacing={16} className='items-center'>
        <Icon
          icon={
            <Body color='accent' weight='bold'>
              2
            </Body>
          }
          color={color}
          className='h-36 w-36'
        />
        <FlexLayout direction='column' spacing={4} className='justify-center'>
          <Body weight='bold'>Update addresses on external wallets</Body>
          <Body size='small' color='secondary'>
            Delete any Swyftx existing crypto addresses or update them to reference your new deposit addresses.
          </Body>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

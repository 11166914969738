import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Rewards: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M18.5623 2C19.1003 2.28551 19.209 2.65243 18.9197 3.21628C18.2405 4.54056 17.9755 5.0586 17.7254 5.54747C17.4567 6.07275 17.2046 6.56558 16.4745 7.9886C16.4519 8.03306 16.4276 8.07639 16.4109 8.10632C16.4058 8.11548 16.4014 8.12347 16.3979 8.12976C16.3962 8.13277 16.3948 8.13541 16.3936 8.13764C16.2238 8.04641 16.0561 7.95366 15.8892 7.86139C15.489 7.64008 15.0936 7.42144 14.686 7.23262C14.41 7.10453 14.2144 6.9507 14.0677 6.66339C13.7351 6.01231 13.3813 5.37329 13.0276 4.73448L12.883 4.47325C12.8173 4.35474 12.8189 4.26615 12.8808 4.14764C13.3232 3.29406 13.3224 3.29747 13.3202 3.30629C13.3179 3.31521 13.3143 3.32966 13.7661 2.46927C13.8333 2.34104 13.9472 2.24113 14.0612 2.14114C14.1137 2.09504 14.1663 2.04892 14.2144 2H18.5623Z'
      fill='currentColor'
    />
    <path
      d='M9.7819 2.11914C10.0322 2.24364 10.1811 2.46571 10.3165 2.71531C10.9856 3.94952 11.1715 4.26418 11.3633 4.58912C11.5508 4.90666 11.7442 5.23405 12.3999 6.43895C12.4291 6.49232 12.4564 6.54689 12.4874 6.60902C12.5002 6.63467 12.5137 6.66167 12.5282 6.69035C10.6878 6.56286 9.03484 7.06265 7.52345 8.19334C7.51018 8.1694 7.49704 8.14617 7.48411 8.12335C7.458 8.07722 7.43263 8.03241 7.40916 7.98683C6.70292 6.61034 6.47242 6.13511 6.24112 5.65823C6.01066 5.18309 5.77942 4.70632 5.07601 3.33482C4.78722 2.77097 4.89538 2.40406 5.43337 2.11914H9.7819Z'
      fill='currentColor'
    />
    <path
      d='M14.2784 15.6021C14.1776 15.705 14.1313 15.8537 14.1542 15.9996L14.5003 18.0156C14.5295 18.1864 14.461 18.3594 14.3251 18.4581C14.192 18.5605 14.0149 18.5728 13.8697 18.4909L12.1457 17.5444C12.0858 17.5108 12.0192 17.4927 11.9511 17.4907H11.8456C11.8091 17.4964 11.7732 17.5087 11.7405 17.5276L10.0162 18.4786C9.93095 18.5237 9.83442 18.5396 9.73983 18.5237C9.5094 18.4778 9.35565 18.2467 9.39341 18.0029L9.73983 15.9869C9.7628 15.8398 9.71648 15.6902 9.61566 15.5857L8.21011 14.1516C8.09255 14.0315 8.05168 13.8512 8.1054 13.6885C8.15756 13.5263 8.29068 13.4079 8.45144 13.3812L10.386 13.0858C10.5331 13.0698 10.6623 12.9756 10.7285 12.8363L11.581 10.9964C11.6012 10.9555 11.6273 10.9178 11.6588 10.8858L11.6938 10.8571C11.7121 10.8358 11.7331 10.8182 11.7565 10.8039L11.7989 10.7875L11.8651 10.7588H12.029C12.1753 10.7748 12.3042 10.867 12.3715 11.0046L13.2352 12.8363C13.2975 12.9702 13.4186 13.0633 13.5583 13.0858L15.4929 13.3812C15.6563 13.4058 15.793 13.5246 15.8471 13.6885C15.8981 13.8529 15.8541 14.0332 15.7342 14.1516L14.2784 15.6021Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.0806 14.9201C19.0806 18.8305 15.9106 22.0004 12.0003 22.0004C8.08994 22.0004 4.91998 18.8305 4.91998 14.9201C4.91998 11.0098 8.08994 7.83984 12.0003 7.83984C15.9106 7.83984 19.0806 11.0098 19.0806 14.9201ZM17.5806 14.9201C17.5806 18.002 15.0822 20.5004 12.0003 20.5004C8.91836 20.5004 6.41998 18.002 6.41998 14.9201C6.41998 11.8382 8.91836 9.33984 12.0003 9.33984C15.0822 9.33984 17.5806 11.8382 17.5806 14.9201Z'
      fill='currentColor'
    />
  </svg>
);

export { Rewards };

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import { CarouselItemType } from './Carousel.types';
import { FlexLayout } from '../Layout/Flex';

type Props = {
  items: CarouselItemType[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  durationMs: number;
};

const CarouselProgress: React.FC<Props> = ({ items, durationMs, currentIndex, setCurrentIndex }) => (
  <FlexLayout direction='row' alignItems='center' justifyContent='center' spacing={8}>
    {items.map((item, index) => (
      <div key={item.key} className={cn('relative h-8 cursor-pointer')} onClick={() => setCurrentIndex(index)}>
        <div
          className={cn(
            ' h-8 bg-color-background-surface-secondary opacity-40 transition-width duration-500',
            currentIndex === index
              ? 'w-36 rounded-8 opacity-50 duration-500'
              : ' w-8 rounded-8 opacity-40 duration-500',
          )}
        />
        <div
          className={cn(
            currentIndex === index ? 'animate-width-left-right' : '',
            'absolute top-0 h-8 w-0 rounded-8 bg-color-background-surface-secondary opacity-50',
          )}
          style={{ animationDuration: `${durationMs}ms` }}
        />
      </div>
    ))}
  </FlexLayout>
);

export { CarouselProgress };

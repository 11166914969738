import React from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

type Props = {
  subTitle: string;
  showImage?: boolean;
};

const UnavailableDemoMode: React.FC<Props> = ({ subTitle, showImage }) => (
  <FlexLayout direction='column' alignItems='center' justifyContent='center' spacing={16} className='p-16'>
    {showImage && <Image image='demo_mode' className='h-96 w-96' usePalette alt='demo_mode' />}
    <Heading size='h5'>Not available in demo mode</Heading>
    <Body color='secondary'>{subTitle}</Body>
  </FlexLayout>
);

export { UnavailableDemoMode };

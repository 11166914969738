import React from 'react';

type Props = {
  completed?: boolean;
};

const StepperDivider: React.FC<Props> = ({ completed }) => (
  <div
    className={`h-2 w-full border ${
      completed ? 'rounded-[2px] border-color-border-accent' : 'border-dashed border-color-border-main'
    } `}
  />
);

export { StepperDivider };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';

import { observer } from 'mobx-react-lite';

import { ForgotPasswordForm } from './Form';

type ForgotPasswordProps = {
  backToSignIn: () => void;
  email: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = observer(({ backToSignIn, email }) => (
  <div>
    <ForgotPasswordForm email={email} />
    <div className='w-full text-center text-color-text-primary' id='forgotPassword.buttonLabels.backToLogin.container'>
      <Button
        id='forgotPassword.buttonLabels.backToLogin'
        variant='outlined'
        color='primary'
        size='lg'
        className='my-16 w-full'
        onClick={backToSignIn}
      >
        Return to sign in
      </Button>
    </div>
  </div>
));

ForgotPassword.displayName = 'ForgotPassword';

export { ForgotPassword };

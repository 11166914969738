import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { LoansPage } from './Loans';

export const LoansRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Loans,
    element: <PrivateRoute element={<LoansPage />} />,
  },
];

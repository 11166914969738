import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';

import { useGetDailyMovement } from '@hooks/Trade/useGetDailyMovement';

import { useLocalStorage } from 'react-use';

import { useMarketTickerAssets } from './useMarketTickerAssets';
import { MarketTickerMode } from '../aviary/marketTicker.consts';
import { MarketTickerSortingOptions } from '../consts';
import { useMarketTickerAnalytics } from '../useMarketTickerAnalytics';

export const useMarketTickerSort = (mode: MarketTickerMode) => {
  const [sort, setSort] = useLocalStorage<MarketTickerSortingOptions>(
    StorageKey.MARKET_TICKER_SORT,
    MarketTickerSortingOptions.GAINERS,
  );
  const { marketTickerSortChanged } = useMarketTickerAnalytics();

  const { getDailyMovement } = useGetDailyMovement();
  const { top, owned, favourites, assets, new: newAssets } = useMarketTickerAssets(mode, sort);

  const onSort = useCallback(
    (newSort: MarketTickerSortingOptions) => {
      marketTickerSortChanged({ sort: newSort, variant: mode });
      setSort(newSort);
    },
    [marketTickerSortChanged, mode, setSort],
  );

  const sortedAssets = useMemo(() => {
    const newSort = sort || MarketTickerSortingOptions.GAINERS;
    switch (newSort) {
      case MarketTickerSortingOptions.GAINERS:
        return assets
          .slice()
          .sort((a: Asset, b: Asset) => ((getDailyMovement(a) || 0) > (getDailyMovement(b) || 0) ? -1 : 1));
      case MarketTickerSortingOptions.LOSERS:
        return assets
          .slice()
          .sort((a: Asset, b: Asset) => ((getDailyMovement(a) || 0) < (getDailyMovement(b) || 0) ? -1 : 1));
      case MarketTickerSortingOptions.MARKET_CAP:
        return top;
      case MarketTickerSortingOptions.FAVOURITES:
        return favourites;
      case MarketTickerSortingOptions.OWNED:
        return owned;
      case MarketTickerSortingOptions.NEW:
        return newAssets;
      default:
        return [];
    }
  }, [assets, favourites, getDailyMovement, newAssets, owned, sort, top]);

  return {
    sortedAssets,
    sort: sort || MarketTickerSortingOptions.GAINERS,
    onSort,
  };
};

import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { SwyftxTradeDirection, SwyftxTradeType } from 'src/lib/trade/types/Trade.types';

import { usePriceScale } from '../usePriceScale';

type Props = {
  triggerPrice?: string;
  limitAsset?: Asset;
  tradeDirection: SwyftxTradeDirection;
  tradeType?: SwyftxTradeType;
  activeValue: string;
  updateActiveValue: (value: string) => void;
};

const useProcessMinimumTradeQuoteError = ({
  triggerPrice,
  limitAsset,
  tradeDirection,
  tradeType,
  activeValue,
  updateActiveValue,
}: Props) => {
  const baseAsset = useBaseAsset();
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;
  const { calculatePriceScale } = usePriceScale();

  const minimumAmount = useMemo(() => {
    if (!limitAsset || !tradeType) return undefined;

    if (tradeType === 'market') {
      return getMinimumOrderAmount(limitAsset, tradeDirection === 'buy' ? 'askPrice' : 'bidPrice').round(
        calculatePriceScale(limitAsset),
      );
    } else if (tradeType === 'limit' && triggerPrice && baseAsset) {
      const baseAssetMinimum = getMinimumOrderAmount(baseAsset, tradeDirection === 'buy' ? 'askPrice' : 'bidPrice');
      if (limitAsset.code === baseAsset.code) return baseAssetMinimum;
      return baseAssetMinimum.div(triggerPrice);
    }

    return undefined;
  }, [baseAsset, calculatePriceScale, getMinimumOrderAmount, limitAsset, tradeDirection, tradeType, triggerPrice]);

  const processBelowMinimum = useCallback(() => {
    if (!minimumAmount || !activeValue) return null;

    if (minimumAmount.gt(activeValue)) {
      const minimum = formatCurrency(
        minimumAmount,
        limitAsset,
        {
          hideCode: true,
          appendCode: false,
          hideSeparator: true,
          hideSymbol: true,
        },
        undefined,
        true,
      );

      return (
        <>
          Amount is below minimum{' '}
          <span className='cursor-pointer underline' onClick={() => updateActiveValue(minimum)}>
            {formatCurrency(minimumAmount, limitAsset, undefined, undefined, true)}
          </span>{' '}
          order limit.
        </>
      );
    }

    return null;
  }, [minimumAmount, activeValue, limitAsset, updateActiveValue]);

  return {
    processBelowMinimum,
  };
};

export { useProcessMinimumTradeQuoteError };

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Asset } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { useNavigateRoute } from './useNavigateRoute';
import { NavigationURLs } from '../types';

export const useAssetNavigation = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const universalTradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const { isSwyftxPro } = useSwyftxPro();
  const { navigate } = useNavigateRoute();
  const avo = useAvo();
  const { pathname } = useLocation();

  const navigateToAsset = useCallback(
    (asset: Asset, navigateToAssetPage = false) => {
      if (isSwyftxPro) {
        navigate(NavigationURLs.SwyftxPro, { pathParams: { asset: asset.code } });
      } else if (universalTradePageEnabled && !navigateToAssetPage) {
        navigate(NavigationURLs.UniversalTradeAsset, { pathParams: { asset: asset.code } });
      } else {
        navigate(NavigationURLs.SingleAsset, {
          pathParams: { asset: asset.code },
        });
        avo.viewedSingleAssetPage({
          screen: pathname,
          assetName: asset.name,
          assetId: asset.id,
          assetCode: asset.code,
        });
      }
    },
    [avo, navigate, pathname, universalTradePageEnabled],
  );

  return { navigateToAsset };
};

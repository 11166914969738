import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Withdraw: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M8.32397 5.1106C7.90976 5.1106 7.57397 5.44638 7.57397 5.8606C7.57397 6.27481 7.90976 6.6106 8.32397 6.6106H16.3281L5.37568 17.5631C5.08278 17.856 5.08278 18.3308 5.37568 18.6237C5.66857 18.9166 6.14344 18.9166 6.43634 18.6237L17.3409 7.71917V15.6275C17.3409 16.0417 17.6767 16.3775 18.0909 16.3775C18.5051 16.3775 18.8409 16.0417 18.8409 15.6275V5.9687C18.8445 5.92581 18.8444 5.88266 18.8406 5.83979C18.8296 5.43519 18.4981 5.1106 18.0909 5.1106H8.32397Z'
      fill='currentColor'
    />
  </svg>
);

export { Withdraw };

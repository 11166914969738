import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Chart: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.7891 6.91895C12.7891 6.50473 12.4533 6.16895 12.0391 6.16895C11.6248 6.16895 11.2891 6.50473 11.2891 6.91895V17.0617C11.2891 17.4759 11.6248 17.8117 12.0391 17.8117C12.4533 17.8117 12.7891 17.4759 12.7891 17.0617V6.91895Z'
      fill='currentColor'
    />
    <path
      d='M8.12109 10.2017C8.12109 9.78745 7.78531 9.45166 7.37109 9.45166C6.95688 9.45166 6.62109 9.78745 6.62109 10.2017V17.0618C6.62109 17.476 6.95688 17.8118 7.37109 17.8118C7.78531 17.8118 8.12109 17.476 8.12109 17.0618V10.2017Z'
      fill='currentColor'
    />
    <path
      d='M16.6289 13.0767C17.0431 13.0767 17.3789 13.4124 17.3789 13.8267V17.0617C17.3789 17.4759 17.0431 17.8117 16.6289 17.8117C16.2147 17.8117 15.8789 17.4759 15.8789 17.0617V13.8267C15.8789 13.4124 16.2147 13.0767 16.6289 13.0767Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.31429 1.25C5.49524 1.25 3.96018 1.89884 2.88354 3.05377C1.81235 4.20285 1.25 5.79292 1.25 7.58516V16.4148C1.25 18.2062 1.80943 19.7964 2.87964 20.9459C3.9555 22.1015 5.49096 22.75 7.31429 22.75H16.6857C18.509 22.75 20.0445 22.1015 21.1204 20.9459C22.1906 19.7964 22.75 18.2062 22.75 16.4148V7.58516C22.75 5.79384 22.1906 4.20365 21.1204 3.05412C20.0445 1.89853 18.509 1.25 16.6857 1.25H7.31429ZM2.75 7.58516C2.75 6.10432 3.21146 4.90181 3.98074 4.07659C4.74458 3.25721 5.86666 2.75 7.31429 2.75H16.6857C18.1386 2.75 19.2603 3.25751 20.0225 4.07623C20.7904 4.90101 21.25 6.1034 21.25 7.58516V16.4148C21.25 17.8966 20.7904 19.099 20.0225 19.9238C19.2603 20.7425 18.1386 21.25 16.6857 21.25H7.31429C5.86142 21.25 4.73974 20.7425 3.9775 19.9238C3.20962 19.099 2.75 17.8966 2.75 16.4148V7.58516Z'
      fill='currentColor'
    />
  </svg>
);

export { Chart };

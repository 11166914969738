import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { PercentageIncrease } from '@global-components/PercentageIncrease';
import { PriceIncrease } from '@global-components/PriceIncrease';
import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';
import { AppStore, UserStore } from '@shared/store';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

type Props = {
  asset: Asset;
  costBasis: boolean;
  toggleCostBasis: (toggle?: boolean) => void;
};

const Item = ({ title, children }: { title: string; children: JSX.Element }) => (
  <FlexLayout direction='column' spacing={8} className='w-full'>
    <FlexLayout spacing={4} direction='row' alignItems='center'>
      <Body color='secondary' size='small' weight='emphasis'>
        {title}
      </Body>
    </FlexLayout>
    {children}
  </FlexLayout>
);

const SingleWalletHoldingsDetails: React.FC<Props> = observer(({ asset, toggleCostBasis, costBasis }) => {
  const baseAsset = useBaseAsset();
  const { all } = useAssetBalance(asset);
  const { t } = useTranslation('wallet', { keyPrefix: 'performance' });
  const { isDemo } = AppStore.useAppStore;
  const { userStatistics } = UserStore.useUserStore;
  const countryAsset = useCountryAsset();
  const isXs = useTailwindBreakpoint('xs');

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isCostBasisEnabled = isFeatureEnabled(AppFeature.CostBasis);

  const assetIsCountry = countryAsset?.id === asset.id;

  return (
    <FlexLayout direction='column' spacing={16} className='p-16'>
      <FlexLayout className='w-full' direction={isXs ? 'column' : 'row'} spacing={isXs ? 8 : 0}>
        <Item title='Wallet holdings'>
          <FlexLayout direction='row' spacing={8} alignItems='center'>
            <AssetIcon asset={asset} size={isXs ? 20 : 24} wallet />
            <FormattedText
              typographyProps={{ className: 'text-[22px] sm:text-[24px]', weight: 'emphasis' }}
              value={all.balance}
              formatBalance
              currency={asset}
              formatOpts={{ appendCode: true }}
            />
          </FlexLayout>
        </Item>
        <Item title='Wallet value'>
          <FlexLayout direction='row' spacing={8} alignItems='start'>
            <FormattedText
              typographyProps={{ className: 'text-[22px] sm:text-[24px]', weight: 'emphasis' }}
              value={all.value}
              currency={baseAsset}
              dynamicCurrency={asset}
              formatOpts={{ appendCode: true }}
            />
          </FlexLayout>
        </Item>
      </FlexLayout>
      {!isDemo && !assetIsCountry && (
        <FlexLayout
          className='w-full'
          justifyContent='space-between'
          direction={isXs ? 'column' : 'row'}
          spacing={isXs ? 8 : 0}
        >
          <Item title='Performance ($)'>
            <PriceIncrease
              typographyProps={{ className: 'text-[22px] sm:text-[24px]', weight: 'emphasis' }}
              formatCurrencyOptions={{ appendCode: true }}
              amount={all.change ?? '0'}
              currencyAsset={baseAsset}
            />
          </Item>
          <Item title='Performance (%)'>
            <PercentageIncrease
              typographyProps={{ className: 'text-[22px] sm:text-[24px]', weight: 'emphasis' }}
              amount={all.changePercent ?? '0'}
            />
          </Item>
        </FlexLayout>
      )}
      {!isDemo && assetIsCountry && (
        <FlexLayout
          className='w-full'
          justifyContent='space-between'
          direction={isXs ? 'column' : 'row'}
          spacing={isXs ? 8 : 0}
        >
          <Item title='Deposited'>
            <FormattedText
              typographyProps={{ className: 'text-[22px] sm:text-[24px]', weight: 'emphasis' }}
              formatOpts={{ appendCode: true }}
              value={userStatistics?.depositedFiat?.toString() ?? '0'}
              currency={asset}
            />
          </Item>
          <Item title='Withdrawn'>
            <FormattedText
              typographyProps={{ className: 'text-[22px] sm:text-[24px]', weight: 'emphasis' }}
              formatOpts={{ appendCode: true }}
              value={userStatistics?.withdrawnFiat?.toString() ?? '0'}
              currency={asset}
            />
          </Item>
        </FlexLayout>
      )}
      {isCostBasisEnabled && (
        <FlexLayout
          className='w-full'
          justifyContent='space-between'
          direction={isXs ? 'column' : 'row'}
          spacing={isXs ? 8 : 0}
        >
          <Button variant='filled' onClick={() => toggleCostBasis()} color='subtle'>
            {costBasis ? t('costBasis.disable') : t('costBasis.enable')}
          </Button>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

SingleWalletHoldingsDetails.displayName = 'SingleWalletHoldingsDetails';

export { SingleWalletHoldingsDetails };

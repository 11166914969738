import React, { useContext, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading, Numeric } from '@swyftx/aviary/atoms/Typography';
import { formatCurrency } from '@swyftx/currency-util';

import { observer } from 'mobx-react-lite';

import { EditTriggerOrderContext } from './EditTriggerOrder.context';

export const EditOrderDetails: React.FC = observer(() => {
  const {
    triggerPrice,
    primaryAsset,
    secondaryAsset,
    estimatedFee,
    feePercentage,
    amount,
    approxSecondaryAmountString,
    isBaseAssetToggled,
    isFiat,
  } = useContext(EditTriggerOrderContext);
  const safeAmount = amount || '0';

  const total = useMemo(() => {
    if (Number.isNaN(Number(safeAmount))) return '0';

    if (!isBaseAssetToggled) {
      return `${formatCurrency(safeAmount, { isFiat: isFiat(secondaryAsset) })} ${secondaryAsset.code}`;
    }
    return `${formatCurrency(safeAmount, { isFiat: isFiat(primaryAsset) })} ${primaryAsset.code}`;
  }, [isBaseAssetToggled, isFiat, primaryAsset, safeAmount, secondaryAsset]);

  return (
    <FlexLayout direction='column' spacing={12}>
      <Heading size='h6'>Details</Heading>
      <FlexLayout direction='row' className='w-full justify-between'>
        <Body>Trigger price</Body>
        <Numeric weight='emphasis'>
          {formatCurrency(triggerPrice || '0', { isFiat: isFiat(primaryAsset) })} {primaryAsset.code}/
          {secondaryAsset.code}
        </Numeric>
      </FlexLayout>
      <FlexLayout direction='row' className='w-full justify-between'>
        <Body>Estimated fee ({feePercentage}%)</Body>
        <Numeric weight='emphasis'>{estimatedFee}</Numeric>
      </FlexLayout>
      <FlexLayout direction='row' className='w-full justify-between'>
        <Body>Total</Body>
        <FlexLayout direction='column' className='items-end'>
          <Numeric weight='emphasis'>{total}</Numeric>
          <Numeric color='secondary'>{approxSecondaryAmountString}</Numeric>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

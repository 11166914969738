import * as Sentry from '@sentry/react';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

export const CHART_KEY = 'tradingview_charts';
const INDICATORS_KEY = 'tradingview_indicators';

type Props = {
  tvWidget: IChartingLibraryWidget;
  secondary: string;
  primary: string;
};

const removeLegacyData = (secondary: string, primary: string) => {
  const legacyData = JSON.parse(localStorage.getItem('tradingViewChart') || '{}');
  delete legacyData[`${secondary}${primary}`];

  localStorage.setItem('tradingViewChart', JSON.stringify(legacyData));
};

const checkForLegacyData = (secondary: string, primary: string) => {
  const legacyData = JSON.parse(localStorage.getItem('tradingViewChart') || '{}');
  return legacyData[`${secondary}${primary}`];
};

const createNewChartEntry = (primary: string, secondary: string) => {
  const symbol = `${secondary}${primary}`;
  const legacyData = checkForLegacyData(secondary, primary);

  return {
    charts_symbols: `{"1":{"symbol":"SWYFTX:${symbol}"}}`,
    content: { content: legacyData ?? undefined },
    description: '',
    exchange: 'SWYFTX',
    is_realtime: '1',
    legs: `[{"symbol":"${symbol}","pro_symbol":"${symbol}"}]`,
    listed_exchange: 'SWYFTX',
    name: symbol,
    resolution: 'D',
    short_name: symbol,
    symbol: `SWYFTX:${symbol}`,
    symbol_type: 'crypto',
  };
};

const updateChartContent = (chart: any, state: any) => {
  const chartContent = typeof chart.content === 'string' ? JSON.parse(chart.content) : chart.content;
  chartContent.content = JSON.stringify(state);
  chart.content = JSON.stringify(chartContent);

  return chart;
};

const getIndicators = () => JSON.parse(localStorage.getItem(INDICATORS_KEY) || '[]') as string[];

const getCharts = () => JSON.parse(localStorage.getItem(CHART_KEY) || '[]');

const getChartIndex = (secondary: string, primary: string) =>
  getCharts().findIndex(
    (c: any) => c.symbol === `${secondary}${primary}` || c.symbol === `SWYFTX:${secondary}${primary}`,
  );

// There was a bug initially where it was possible for there to be 2 chart entries with the same symbol.
// for those uses affected, we should find any duplicate symbols and remove the entries with the lowest index
const sanitiseChartData = () => {
  const charts = getCharts();
  const uniqueCharts = new Map();

  charts.forEach((c: any) => {
    uniqueCharts.set(c.symbol, c);
  });

  localStorage.setItem(CHART_KEY, JSON.stringify(Array.from(uniqueCharts.values())));
};

const syncIndicators = (tvWidget: IChartingLibraryWidget) => {
  // Load the current selection of indicators from storage
  const indicators: string[] = getIndicators();

  if (!indicators || indicators.length === 0) return;

  const chartIndicators = tvWidget.activeChart().getAllStudies();

  // Remove any indicators from the chart that aren't in our list
  chartIndicators.forEach((e) => {
    if (!indicators.includes(e.name)) tvWidget.activeChart().removeEntity(e.id);
  });

  indicators.forEach((i) => {
    // Don't process Volume as we will do that below
    if (i !== 'Volume' && !chartIndicators.find((c) => c.name === i)) {
      tvWidget.activeChart().createStudy(i, false, false, undefined, undefined, {});
    }
  });
};

const TradingViewSaveLoadAdapter = ({ tvWidget, secondary, primary }: Props) => {
  try {
    // Remove any duplicate chart data
    sanitiseChartData();

    // Load all charts that are saved in local_storage
    const charts = getCharts();

    // Try and find an existing chart data in our list from localstorage
    let chartIndex = getChartIndex(secondary, primary);

    let chart: any;

    // If we found it, just simply load it
    if (chartIndex > -1) {
      chart = charts[chartIndex];
      const chartData = JSON.parse(JSON.parse(chart.content).content);

      tvWidget.load(chartData);
      syncIndicators(tvWidget);
    } else {
      const legacyData = checkForLegacyData(secondary, primary);

      // Otherwise create a new chart entry
      chart = createNewChartEntry(primary, secondary);
      // Remove legacy data if it exists
      removeLegacyData(secondary, primary);
      charts.push(chart);
      tvWidget.load(chart.content.content);
      syncIndicators(tvWidget);

      // We now need to check if the volume indicator exists, if not add it if we are creating a new chart
      if (!legacyData) {
        const volumeIndicatorIndex = tvWidget
          .chart()
          .getAllStudies()
          .findIndex((e) => e.name === 'Volume');

        if (volumeIndicatorIndex === -1) {
          tvWidget
            .activeChart()
            .createStudy('Volume', true, false, undefined, undefined, {})
            .then((entity) => {
              if (entity) tvWidget.chart().getStudyById(entity).mergeUp();
            });
        }
      }
    }

    // Hook into the autosave feature to automatically update our localstorage with the new chart data
    tvWidget.subscribe('onAutoSaveNeeded', () => {
      // If we need to save, store a snapshot of the indicators
      localStorage.setItem(
        INDICATORS_KEY,
        JSON.stringify(
          tvWidget
            .activeChart()
            .getAllStudies()
            .map((s) => s.name),
        ),
      );

      tvWidget.save((state) => {
        if (!chart) return;

        // Try and find an existing chart data in our list from localstorage
        let index = getChartIndex(secondary, primary);

        charts[index] = updateChartContent(chart, state);
        localStorage.setItem(CHART_KEY, JSON.stringify(charts));
      });
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export { TradingViewSaveLoadAdapter, createNewChartEntry, checkForLegacyData, removeLegacyData };

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Category } from '@swyftx/aviary/icons/outlined';
import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const useAssetCatgoriesTour = (): TourGuideData => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('featureDiscovery', { keyPrefix: 'tours' });

  return useMemo(
    () => ({
      icon: <Category className='h-28 w-28 text-color-text-primary' />,
      name: t('assetCategories.name'),
      description: t('assetCategories.description'),
      startRoute: NavigationURLs.AssetsAll,
      steps: [
        {
          title: t('assetCategories.steps.1.title'),
          description: t('assetCategories.steps.1.content'),
          spotlightElementId: 'asset-list-category-tab',
          alignment: 'right',
          customSpotlightStyles: 'border-radius: 12px',
          onNext: () => navigate(NavigationURLs.AssetsCategories),
        },
        {
          title: t('assetCategories.steps.2.title'),
          description: t('assetCategories.steps.2.content'),
          spotlightElementId: 'categories-list',
          alignment: 'right',
        },
        {
          title: t('assetCategories.steps.3.title'),
          description: t('assetCategories.steps.3.content'),
          spotlightElementId: 'categories-list-row-item-0',
          alignment: 'right',
          onNext: () => navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: 1 } }),
        },
        {
          title: t('assetCategories.steps.4.title'),
          description: t('assetCategories.steps.4.content'),
          spotlightElementId: 'single-category-page',
          alignment: 'right',
          delay: 500,
          customSpotlightStyles: 'overflow: hidden',
        },
      ],
    }),
    [navigate, t],
  );
};

export { useAssetCatgoriesTour };

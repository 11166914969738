import React, { useCallback } from 'react';

import { Asset } from '@shared/api';

import { useTradeValue } from 'src/lib/trade/hooks/General';

type Props = {
  asset?: Asset;
  value: string;
  blockAlphaNumericChars?: boolean;
  ignoreAssetScale?: boolean;
  onChange: (value: string) => void;
};

// Hook to format and unformat text for input boxes.
// i.e display $100,000 in an input but store as 100000
const useFormatInputText = ({ asset, value, blockAlphaNumericChars, ignoreAssetScale = true, onChange }: Props) => {
  const { parseTradeValue, formattedAmount, priceScale } = useTradeValue({
    limitAsset: asset,
    amount: value,
    ignoreAssetScale,
  });

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: newValue } = e.target;

      let strippedValue = newValue.replace(/^0+(?!\.|$)/, '').replace(/,/g, '');

      if (blockAlphaNumericChars && Number.isNaN(Number(strippedValue))) return;

      // Add a 0 to the front of the value if it starts with a '.'
      if (strippedValue.startsWith('.')) {
        strippedValue = '0' + strippedValue;
      }

      if (strippedValue.length > 1 && strippedValue.split('').every((v) => v === '0')) return;

      const unformattedValue = strippedValue.replace(/,/g, '');

      const newParsedValue = parseTradeValue(unformattedValue, true, 'none');
      if (newParsedValue !== undefined) onChange(newParsedValue);
    },
    [blockAlphaNumericChars, onChange, parseTradeValue],
  );

  return {
    formattedAmount,
    priceScale,
    parseTradeValue,
    onChangeValue,
  };
};

export { useFormatInputText };

import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Copy } from '@swyftx/aviary/icons/outlined';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

const COPIED_TIMEOUT = 5000;

export const AffiliateDashboardReferralLink: React.FC = observer(() => {
  const { t } = useTranslation('profile.affiliates');
  const [copied, setCopied] = useState<boolean>(false);
  const { affiliateInfo } = useContext(ProfileContext);

  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  const copyReferralLink = async () => {
    if (!affiliateInfo || copied) return;

    try {
      await navigator.clipboard.writeText(affiliateInfo.referralLink);
      setCopied(true);
    } catch (e) {
      setCopied(false);
    }
  };

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  if (!affiliateInfo || !affiliateInfo.referralLink) return null;

  return (
    <FlexLayout direction='column' spacing={4} className='p-16'>
      <Body size='small' weight='emphasis'>
        {t('affiliateDashboard.earn.labels.uniqueLink')}
      </Body>
      <FlexLayout
        direction='row'
        alignItems='center'
        spacing={8}
        className='flex rounded-12 bg-color-background-surface-secondary p-12'
      >
        <FlexLayout direction='row' className='align-center flex w-[100%] items-center justify-between'>
          <Body color='secondary' size='small'>
            {affiliateInfo.referralLink}
          </Body>

          <Button
            variant='outlined'
            size='sm'
            onClick={copyReferralLink}
            disabled={hasClipboardPermission !== 'granted'}
            leadingIcon={!copied ? <Copy /> : undefined}
          >
            {copied ? t('affiliateDashboard.earn.buttonLabels.copied') : t('affiliateDashboard.earn.buttonLabels.copy')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

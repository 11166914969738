import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const WhatsNew: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75081 1.80347C9.13544 1.80347 9.44724 2.11527 9.44724 2.4999C9.44724 3.91019 10.5905 5.05347 12.0008 5.05347C12.3854 5.05347 12.6972 5.36527 12.6972 5.7499C12.6972 6.13452 12.3854 6.44632 12.0008 6.44632C10.5905 6.44632 9.44724 7.5896 9.44724 8.9999C9.44724 9.38452 9.13544 9.69632 8.75081 9.69632C8.36618 9.69632 8.05438 9.38452 8.05438 8.9999C8.05438 7.5896 6.91111 6.44632 5.50081 6.44632C5.11618 6.44632 4.80438 6.13452 4.80438 5.7499C4.80438 5.36527 5.11618 5.05347 5.50081 5.05347C6.91111 5.05347 8.05438 3.91019 8.05438 2.4999C8.05438 2.11527 8.36618 1.80347 8.75081 1.80347ZM8.75081 4.73924C8.47809 5.13429 8.13521 5.47718 7.74016 5.7499C8.13521 6.02261 8.47809 6.3655 8.75081 6.76055C9.02353 6.3655 9.36642 6.02261 9.76147 5.7499C9.36642 5.47718 9.02353 5.13429 8.75081 4.73924Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.0016 6.24976C15.4159 6.24976 15.7516 6.58554 15.7516 6.99976C15.7516 9.89925 18.1022 12.2498 21.0016 12.2498C21.4159 12.2498 21.7516 12.5855 21.7516 12.9998C21.7516 13.414 21.4159 13.7498 21.0016 13.7498C18.1022 13.7498 15.7516 16.1003 15.7516 18.9998C15.7516 19.414 15.4159 19.7498 15.0016 19.7498C14.5874 19.7498 14.2516 19.414 14.2516 18.9998C14.2516 16.1003 11.9011 13.7498 9.00165 13.7498C8.58743 13.7498 8.25165 13.414 8.25165 12.9998C8.25165 12.5855 8.58743 12.2498 9.00165 12.2498C11.9011 12.2498 14.2516 9.89925 14.2516 6.99976C14.2516 6.58554 14.5874 6.24976 15.0016 6.24976ZM15.0016 10.0952C14.3588 11.3387 13.3406 12.3569 12.097 12.9998C13.3406 13.6426 14.3588 14.6608 15.0016 15.9044C15.6445 14.6608 16.6627 13.6426 17.9062 12.9998C16.6627 12.3569 15.6445 11.3387 15.0016 10.0952Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.44724 14.4999C6.44724 14.1153 6.13544 13.8035 5.75081 13.8035C5.36618 13.8035 5.05438 14.1153 5.05438 14.4999C5.05438 15.9102 3.91111 17.0535 2.50081 17.0535C2.11618 17.0535 1.80438 17.3653 1.80438 17.7499C1.80438 18.1345 2.11618 18.4463 2.50081 18.4463C3.91111 18.4463 5.05438 19.5896 5.05438 20.9999C5.05438 21.3845 5.36618 21.6963 5.75081 21.6963C6.13544 21.6963 6.44724 21.3845 6.44724 20.9999C6.44724 19.5896 7.59051 18.4463 9.00081 18.4463C9.38544 18.4463 9.69724 18.1345 9.69724 17.7499C9.69724 17.3653 9.38544 17.0535 9.00081 17.0535C7.59051 17.0535 6.44724 15.9102 6.44724 14.4999ZM4.74016 17.7499C5.13521 17.4772 5.47809 17.1343 5.75081 16.7392C6.02353 17.1343 6.36642 17.4772 6.76147 17.7499C6.36642 18.0226 6.02353 18.3655 5.75081 18.7606C5.47809 18.3655 5.13521 18.0226 4.74016 17.7499Z'
      fill='currentColor'
    />
  </svg>
);

export { WhatsNew };

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Coin, Information, Loading, Search } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { Asset } from '@shared/api';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { assetPickerTabs } from 'src/lib/UniversalTrade/components/AssetPicker/AssetPicker.types';
import { MarketAssetFilterType } from 'src/lib/markets/types/Markets.types';
import { MAX_AUTO_INVEST_ASSETS } from 'src/lib/trade/trade.consts';

import { MultiAssetPickerItem } from './MultiAssetPickerItem';
import { useMultiAssetPickerList } from './useMultiAssetPickerList';

interface Props {
  preSelectedAssetCodes: string[];
  onConfirm: (assets: string[]) => void;
}

export const MultiAssetPicker: React.FC<Props> = (props) => {
  const { preSelectedAssetCodes, onConfirm } = props;
  const [selectedAssets, setSelectedAssets] = useState<string[]>(preSelectedAssetCodes);
  const [search, setSearch] = useState<string>('');
  const isXs = useTailwindBreakpoint('xs');
  const [selectedTab, setSelectedTab] = useState<MarketAssetFilterType>('all');

  useEffect(() => setSelectedAssets(preSelectedAssetCodes), [preSelectedAssetCodes]);

  const handleConfirm = useCallback(() => {
    onConfirm(selectedAssets);
  }, [onConfirm, selectedAssets]);

  const handleSelect = useCallback(
    (asset: Asset) => {
      if (selectedAssets.includes(asset.code)) {
        setSelectedAssets((prev) => prev.filter((a) => a !== asset.code));
      } else {
        setSelectedAssets((prev) => [...prev, asset.code]);
      }
    },
    [selectedAssets],
  );

  const autoInvestTabs = assetPickerTabs.filter(
    (tab) => !['gainers', 'losers'].includes(tab.value),
  ) as EnhancedTab<MarketAssetFilterType>[];

  const { assetsToRender, intersectionObserverRef, hasMoreToLoad } = useMultiAssetPickerList({
    search,
    selectedTab,
  });

  const alertText = useMemo(() => {
    if (!selectedAssets.length) return `You can add up to ${MAX_AUTO_INVEST_ASSETS} assets to this order`;
    if (selectedAssets.length >= MAX_AUTO_INVEST_ASSETS) return "You've added the maximum number of assets";
    return `You can add ${MAX_AUTO_INVEST_ASSETS - selectedAssets.length} more assets to this order`;
  }, [selectedAssets.length]);

  return (
    <FlexLayout direction='column' className='w-full' spacing={8} justifyContent='space-between'>
      <FlexLayout direction='column' className='px-24 pt-2' spacing={16}>
        <Input
          placeholder='Search for an asset'
          leading={<Search className='h-20 w-20 text-color-text-secondary' />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <FlexLayout direction={isXs ? 'row' : 'column'} className='sm:overflow-x-none w-full overflow-x-scroll p-2'>
          <EnhancedTabs<MarketAssetFilterType>
            variant='child'
            tabs={autoInvestTabs}
            onChange={setSelectedTab}
            value={selectedTab}
            fullWidth
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout className='max-h-[50vh] min-h-[50vh] w-full sm:max-h-[30rem] sm:min-h-[30rem]' direction='column'>
        <OverlayScrollbarsComponent
          className='flex h-full flex-col p-0'
          options={{ scrollbars: { visibility: 'auto', autoHide: 'leave', autoHideDelay: 400 } }}
        >
          <FlexLayout direction='column' spacing={2} className='h-full overflow-auto'>
            {assetsToRender.map((asset) => (
              <MultiAssetPickerItem
                key={asset.id}
                asset={asset}
                onSelect={() => handleSelect(asset)}
                selected={selectedAssets.includes(asset.code)}
                disabled={selectedAssets.length >= MAX_AUTO_INVEST_ASSETS && !selectedAssets.includes(asset.code)}
              />
            ))}
            {!!assetsToRender.length && hasMoreToLoad && (
              <div ref={intersectionObserverRef} className='flex w-full items-center justify-center p-8 pb-16'>
                {hasMoreToLoad && <Loading className='animate-spin text-color-text-primary' />}
              </div>
            )}
            {!assetsToRender.length && (
              <FlexLayout direction='column' className='h-full' alignItems='center' justifyContent='center' spacing={8}>
                <Coin className='h-50 w-50 text-color-text-accent' />
                <Body color='primary'>No assets found</Body>
              </FlexLayout>
            )}
          </FlexLayout>
        </OverlayScrollbarsComponent>
      </FlexLayout>
      <FlexLayout direction='column' spacing={4} className='w-full items-center justify-center px-24'>
        <FlexLayout direction='row' className='w-full items-center justify-center' spacing={4}>
          <Information className='h-12 w-12 text-color-text-accent' />
          <Body size='xsmall' color='accent'>
            {alertText}
          </Body>
        </FlexLayout>
        <Button size='lg' disabled={!selectedAssets.length} onClick={handleConfirm} className='w-full'>
          Add assets
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Copy, Tick } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import QRCode from 'react-qr-code';

type Props = {
  title: string;
  subTitle: string;
  value: string;
  disabled?: boolean;
};

const COPIED_TIMEOUT = 5000;

const ReceiveCryptoInformationItem: React.FC<Props> = ({ title, subTitle, value, disabled }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  const copyValue = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
    } catch (e) {
      setCopied(false);
    }
  }, [value]);

  return (
    <FlexLayout direction='column' spacing={16}>
      <FlexLayout direction='column' spacing={4}>
        <Body weight='emphasis'>{title}</Body>
        <Body size='small' color='secondary'>
          Note: {subTitle}
        </Body>
      </FlexLayout>
      <FlexLayout
        direction='row'
        alignItems='center'
        justifyContent='start'
        className='PII rounded-12 bg-color-background-surface-secondary p-16'
      >
        <div className='PII w-[100px]'>
          <QRCode className='PII' value={value} size={100} />
        </div>
        <FlexLayout direction='column' className='w-[calc(100%-100px)] justify-center p-16' spacing={8}>
          <Body className={cn('PII text-balance break-words', disabled ? 'select-none text-color-text-secondary' : '')}>
            {value}
          </Body>
          {hasClipboardPermission === 'granted' && !disabled && (
            <FlexLayout direction='row'>
              <Button
                variant='outlined'
                leadingIcon={copied ? <Tick /> : <Copy />}
                onClick={copyValue}
                className='bg-color-background-surface-primary'
              >
                {copied ? 'Copied!' : 'Copy to clipboard'}
              </Button>
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export { ReceiveCryptoInformationItem };

import { api, Asset, PairExchangeRateParams, PairExchangeRateResponse } from '@shared/api';
import { ApiError } from '@shared/api/@types/api';
import { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { Big } from '@shared/safe-big';
import { assetService, balanceService, OrderType, portfolioService, userStatisticsService } from '@shared/services';
import { UserStore } from '@shared/store';

import * as Sentry from '@sentry/react';

const LOG_TAG = 'TradeService';

export class OrderRateResponseError extends Error {
  error: any;

  data?: PairExchangeRateParams[];

  constructor(error: any, data?: PairExchangeRateParams[]) {
    super();
    this.error = error;
    this.data = data;
  }
}

export interface OrderRateResponse {
  amount: string;
  feePerUnit: number;
  price: string;
  total: string;
}

export interface OrderAssetParam {
  from: Asset['id'];
  to: Asset['id'];
  limit: Asset['id'];
  amount: string;
}

export interface UpdatedOrderError {
  error: {
    type: 'generic' | 'depth' | 'min' | 'max';
    detail: string;
  };
}

export interface OrderState {
  fromAssetId: number;
  toAssetId: number;
}

const resetDemoMode = async () => {
  try {
    const res = await api.endpoints.resetPaperTrading();

    await Promise.all([
      balanceService.forceUpdate(),
      portfolioService.forceUpdate(),
      userStatisticsService.forceUpdate(),
    ]);

    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const processBulkOrderRate = (data: PairExchangeRateResponse | ApiError): PairExchangeRateResponse | ApiError => {
  const castData = data as PairExchangeRateResponse;
  if (castData.feePerUnit === undefined) return { ...data };

  const feePerUnit = castData.feePerUnit ?? '0';

  return {
    ...data,
    feePerUnit,
  };
};

const getOrderRate = async (
  data: PairExchangeRateParams[],
): Promise<Array<PairExchangeRateResponse | ApiError> | undefined> => {
  let response: Array<PairExchangeRateResponse | ApiError> = [];
  if (data.length === 1) {
    try {
      const rateData = data[0];
      const rateResponse = await api.endpoints.getPairExchangeRate({ data: rateData });
      response = [rateResponse.data];
    } catch (error) {
      const err = error as SwyftxError;
      throw new OrderRateResponseError(JSON.parse(err.errorMessage), data);
    }
  } else {
    try {
      const bulkResponse = await api.endpoints.getExchangeRates({ data });
      const bulkRates = bulkResponse.data;
      response = [...bulkRates];
    } catch (error) {
      const err = error as SwyftxError;
      throw new OrderRateResponseError(JSON.parse(err.errorMessage), data);
    }
  }

  return response.map((br) => processBulkOrderRate(br));
};

const isBuy = (orderType: OrderType) =>
  [OrderType.MarketBuy, OrderType.TriggerBuy, OrderType.StopBuy, OrderType.OTCBuy].includes(orderType);

const isSell = (orderType: OrderType) =>
  [OrderType.MarketSell, OrderType.TriggerSell, OrderType.StopSell, OrderType.OTCSell].includes(orderType);

const getPrimarySecondaryAssets = (state: OrderState | [Asset['id'], Asset['id']]) => {
  let assetIds: [Asset['id'], Asset['id']] = [0, 0];
  if (Array.isArray(state)) {
    assetIds = state;
  } else {
    assetIds = [state.fromAssetId, state.toAssetId];
  }

  const assetA = assetService.getAsset(assetIds[0]);
  const assetB = assetService.getAsset(assetIds[1]);

  let primaryAsset;
  let secondaryAsset;

  if (assetA?.isBaseAsset) {
    primaryAsset = assetA;
    secondaryAsset = assetB;
  } else if (assetB?.isBaseAsset) {
    primaryAsset = assetB;
    secondaryAsset = assetA;
  }

  // ensure user base is always primary
  const { userBaseCurrency } = UserStore.useUserStore;
  if (secondaryAsset?.id === userBaseCurrency) {
    const tmp = primaryAsset;
    primaryAsset = secondaryAsset;
    secondaryAsset = tmp;
  }

  return { primaryAsset, secondaryAsset };
};

const processError = (error: ApiError, order: OrderAssetParam) => {
  const { primaryAsset } = getPrimarySecondaryAssets({ fromAssetId: order.from, toAssetId: order.to });
  const limitAsset = assetService.getAsset(order.limit);
  const isPrimaryLimit = !!(limitAsset === primaryAsset);

  const errorDetail: UpdatedOrderError = {
    error: {
      type: 'generic',
      detail: error.error.message,
    },
  };

  if (limitAsset && order.amount) {
    try {
      const errorData = error.error;
      if (order.amount && errorData.error === 'MinimumAmountError') {
        const extra = JSON.parse(errorData.message);
        errorDetail.error.detail = Big(order.amount)
          .plus(Big(extra.minimumPercent).times(order.amount))
          .round(limitAsset.price_scale, 3)
          .toString();
        errorDetail.error.type = 'min';
      } else if (errorData.error === 'OrderbookDepthError') {
        const extra = JSON.parse(errorData.message);
        const amount = isPrimaryLimit ? Big(extra.depth.amount) : Big(extra.depth.total);
        errorDetail.error.detail = amount.round(limitAsset.price_scale, 3).toString();
        errorDetail.error.type = 'depth';
      } else if (order.amount && errorData.error === 'MaximumAmountError') {
        const extra = JSON.parse(errorData.message);
        errorDetail.error.detail = Big(order.amount)
          .minus(Big(extra.maximumPercent).times(order.amount))
          .round(limitAsset.price_scale, 0)
          .toString();
        errorDetail.error.type = 'max';
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return errorDetail;
};

const TradeService = {
  processError,
  resetDemoMode,
  getOrderRate,
  isBuy,
  isSell,
};

export default TradeService;

import React from 'react';

import { Grid, Stack, Typography } from '@swyftx/react-web-design-system';

import { NewsItem } from '@shared/api';

import { decode } from 'html-entities';

interface Props {
  newsItem: NewsItem;
  buildAsset: React.ReactNode;
  relativeTime: string;
  onNewsItemClicked: () => void;
}

const NewsFeedTileListView = ({ newsItem, buildAsset, relativeTime }: Props) => {
  const buildTitle = () => (
    <Stack direction='row' justifyContent='flex-start'>
      <Typography
        fontSize={12}
        display='inline-block'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: '1.2em',
          maxHeight: '1.5em',
          whiteSpace: 'nowrap',
          WebkitLineClamp: '2',
        }}
      >
        {decode(newsItem.title)}
      </Typography>
      <Typography
        fontSize={12}
        color='text.secondary'
        display='inline-block'
        sx={{
          marginLeft: 0.5,
          lineHeight: '1.2em',
          maxHeight: '1.5em',
          whiteSpace: 'nowrap',
          WebkitLineClamp: '2',
          width: 'minContent',
        }}
      >
        ({newsItem.sourceName})
      </Typography>
    </Stack>
  );

  return (
    <>
      <Grid item xs={2}>
        <Typography fontSize={12} display='inline'>
          {relativeTime}
        </Typography>
      </Grid>
      <Grid item xs={8} direction='row' justifyContent='flex-start'>
        {buildTitle()}
      </Grid>
      <Grid item xs={2} justifyContent='flex-end'>
        {buildAsset}
      </Grid>
    </>
  );
};

export { NewsFeedTileListView };

import { initReactI18next } from 'react-i18next';

import storage from '@shared/storage';

import i18n from 'i18next';

import { resources } from './i18n.resources';

export enum Languages {
  English = 'en',
  French = 'fr',
}

/**
 * Initializes i18n with either the current device language or what has been stored in session storage
 */
export const init = async () => {
  // const language = await getDeviceLanguage();

  i18n
    .use(initReactI18next)
    // .use(LanguageDetector)
    .init({
      lng: 'en',
      // lng: language,
      debug: false,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      ns: ['translation'],
      resources,
    });
};

/**
 * Updates the i18n language and saves it into storage
 * @param language The language that we want to change to
 */
export const changeLanguage = async (language: Languages) => {
  i18n.changeLanguage(language);
  await storage.setItem('language', language);
};

/**
 * Get the language currently stored in i18n
 * @returns {string}
 */
export const getLanguage = () => i18n.language as string;

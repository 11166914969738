import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@swyftx/react-web-design-system';

import { Links } from '@shared/constants';
import { useUserStore } from '@shared/store/userStore';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { CancelVerificationModal } from '../CancelVerificationModal';

const VerifyFundForm: React.FC = observer(() => {
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const { userProfile, isKyc2Required } = useUserStore;
  const [showVerifyCancelModal, setVerifyCancelModal] = useState<boolean>(false);
  const { t } = useTranslation('profile.verification');

  const onClose = () => setVerificationStep(VerificationStep.None);

  const handleVerifyCancelClose = (closeParent: boolean) => {
    setVerifyCancelModal(false);
    if (closeParent) onClose();
  };

  return (
    <>
      <Modal
        PII
        open
        id='verify-fund-form-modal'
        HeaderProps={{
          title: `${t('verificationMethod.verifyFund.modal.title')}`,
          dismissible: true,
          divider: true,
        }}
        sx={{
          width: '600px',
          height: '90%',
          '.MuiCardContent-root': { height: '100%', '.MuiBox-root': { height: '100%' } },
          visibility: showVerifyCancelModal ? 'hidden' : 'visible',
        }}
        onClose={() => setVerifyCancelModal(true)}
      >
        <iframe
          style={{ height: '100%', width: '100%', border: 'none' }}
          src={`${Links.forms.fundsVerificationForm}?ID=True&UUID=${userProfile?.intercom?.uuid}&Status=${
            isKyc2Required() ? 'required' : 'volunteered'
          }`}
        />
      </Modal>
      {showVerifyCancelModal && (
        <CancelVerificationModal
          open={showVerifyCancelModal}
          onClose={handleVerifyCancelClose}
          title={t('verificationMethod.cancelVerificationModal.title') as string}
        />
      )}
    </>
  );
});

export { VerifyFundForm };

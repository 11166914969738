import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PassportIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <rect
        x='5.333'
        y='3.333'
        width='21.333'
        height='25.333'
        rx='3.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='.4'
        d='M12 22.668h8'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        clipRule='evenodd'
        d='M16 8a5.333 5.333 0 1 1 0 10.667A5.333 5.333 0 0 1 16 8Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

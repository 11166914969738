import { useCallback, useContext, useEffect, useState } from 'react';

import { Candle } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { HistogramData, IChartApi, ISeriesApi, Time, UTCTimestamp } from 'lightweight-charts';
import { DateTime } from 'luxon';

import { TradingViewContext } from '../../context/TradingView.context';

type Props = {
  chartState?: IChartApi;
  data?: Candle[];
  secondary: string;
};

const useSimpleChartVolume = ({ chartState, data, secondary }: Props) => {
  const { showSimpleVolume } = useContext(TradingViewContext);
  const [series, setSeries] = useState<ISeriesApi<'Histogram'>>();
  const [asset, setAsset] = useState<string>(secondary);

  const createChartVolume = useCallback(() => {
    if (!chartState || !data) return;

    if (series !== undefined && secondary === asset) {
      try {
        chartState.removeSeries(series);
        setSeries(undefined);
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    if (!showSimpleVolume) return;

    const upColor = getComputedStyle(document.body).getPropertyValue('--color-background-success');
    const downColor = getComputedStyle(document.body).getPropertyValue('--color-background-error');

    const seriesData: HistogramData<Time>[] = data
      .map((d) => ({
        time: (Date.parse(DateTime.fromMillis(d.time).toISO()) / 1000) as UTCTimestamp,
        value: d.volume || 0,
        color: d.open < d.close ? `${upColor}80` : `${downColor}80`,
      }))
      .sort((a, b) => Big(a.time).cmp(b.time));

    const volumeSeries = chartState.addHistogramSeries({
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
    });

    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0,
      },
    });

    try {
      volumeSeries.setData(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }
    setSeries(volumeSeries);
    setAsset(secondary);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartState, data, asset, secondary, showSimpleVolume]);

  useEffect(
    () => () => {
      setSeries(undefined);
    },
    [],
  );

  return {
    volumeSeries: series,
    createChartVolume,
  };
};

export { useSimpleChartVolume };

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as RadixSelect from '@radix-ui/react-select';

import { SelectItemData } from './Select.types';
import { FlexLayout } from '../Layout/Flex';
import { Body } from '../Typography';

type Props = {
  item: SelectItemData;
  className?: string;
  selected?: boolean;
};

const SelectItem = React.forwardRef<HTMLDivElement, Props>(({ item, selected, ...props }, forwardedRef) => (
  <RadixSelect.Item
    value={item.value}
    disabled={item.disabled}
    {...props}
    ref={forwardedRef}
    className={cn(
      selected ? '!bg-color-background-surface-selected !text-color-text-selected' : '',
      'mt-8 rounded-8',
      item.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:bg-color-background-surface-hover',
      'p-8 focus:bg-color-background-surface-active focus:outline-0',
    )}
  >
    <FlexLayout alignItems='center' justifyContent='space-between' spacing={8}>
      <FlexLayout alignItems='center' justifyContent='start' spacing={12}>
        <div className={selected ? '[&>*]:!text-color-text-selected' : ''}>{item.leadingIcon}</div>
        <FlexLayout direction='column' alignItems='start'>
          <RadixSelect.ItemText>
            <Body weight='emphasis' color={selected ? 'selected' : 'primary'}>
              {item.label}
            </Body>
          </RadixSelect.ItemText>
          {item.secondaryLabel && (
            <Body color='secondary' size='small'>
              {item.secondaryLabel}
            </Body>
          )}
        </FlexLayout>
      </FlexLayout>
      <div className={selected ? '[&>*]:!text-color-text-selected' : ''}>{item.trailingIcon}</div>
    </FlexLayout>
  </RadixSelect.Item>
));

SelectItem.displayName = 'SelectItem';

export { SelectItem };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  leading?: React.ReactNode;
  step?: string;
  title?: string;
  subTitle: string;
};

const EntityModalTile: React.FC<Props> = ({ leading, step, title, subTitle }) => (
  <FlexLayout direction='row' spacing={8} className='w-full rounded-16 bg-color-background-surface-primary p-16'>
    <Body color='secondary'>{leading}</Body>
    <div className='ml-0 text-center'>
      <Body color='secondary' size='small' weight='bold'>
        {step}
      </Body>
    </div>

    <div>
      <Body weight='bold' size='small'>
        {title}
      </Body>
      <Body size='small'>{subTitle}</Body>
    </div>
  </FlexLayout>
);

export { EntityModalTile };

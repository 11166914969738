import React, { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@swyftx/aviary/atoms/Table';
import { Utility } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Loading } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { useIntersectionObserver } from '@hooks/IntersectionObserver/useIntersectionObserver';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export type EnhancedTableHeaderData = {
  title: string;
  sortable: boolean;
  alignment: 'start' | 'end';
  sortKey?: string;
  tooltip?: string;
  enabled?: boolean;
  frozen?: boolean;
  className?: string;
  showFrozenDivider?: boolean;
  insetLeft?: boolean;
  insetRight?: boolean;
  offsetLeft?: number;
};

export type EnhancedTableSort<T> = {
  sortKey: keyof T;
  sortDirection: 'ASC' | 'DESC';
};

type EnhancedTableDataItem = {
  element: React.ReactNode;
  blockClick?: boolean;
  value?: unknown;
  groupByTitle?: string;
  className?: string;
};

export type EnhancedTableData<T, U = unknown> = { [key in keyof T]: EnhancedTableDataItem } & {
  value?: U;
  className?: string;
};

type Props<T, U = unknown> = {
  sort?: EnhancedTableSort<T>;
  headers: { [key in keyof T]: EnhancedTableHeaderData };
  data?: EnhancedTableData<T, U>[];
  groupBy?: keyof T;
  itemsPerPage?: number;
  onSort: (sort?: EnhancedTableSort<T>) => EnhancedTableData<T, U>[] | void;
  onClickItem?: (item: EnhancedTableData<T, U>) => void;
  onMouseEnter?: (index: number) => void;
  onMouseLeave?: (index: number) => void;
  onScroll?: (e: Event) => void;
  className?: string;
  cellClassName?: string;
  rowClassName?: string;
  inset?: boolean;
  containerClassName?: string;
  spotlightElementId?: string;
  headerClassName?: string;
  lazyLoad?: boolean;
} & Omit<HTMLAttributes<HTMLTableElement>, 'style' | 'onScroll'>;

const FREEZE_POINT = 10;

const EnhancedTable = <T, U = unknown>({
  className,
  containerClassName,
  headerClassName,
  rowClassName,
  cellClassName,
  inset = false,
  sort,
  groupBy,
  headers,
  onSort,
  onClickItem,
  onMouseEnter,
  onMouseLeave,
  onScroll,
  data = [],
  children,
  spotlightElementId,
  itemsPerPage = 6,
  lazyLoad,
  ...props
}: PropsWithChildren<Props<T, U>>) => {
  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');
  const [page, setPage] = useState<number>(0);
  const [freezeColumns, setFreezeColumns] = useState<boolean>(false);
  const [tableData, setTableData] = useState<EnhancedTableData<T, U>[]>(data);
  const { targetRef: intersectionObserverRef } = useIntersectionObserver(() => setPage((prev) => prev + 1));
  const hasMoreToLoad = useMemo(() => page * itemsPerPage < tableData.length, [page, itemsPerPage, tableData.length]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const tableDataToRender = useMemo(() => {
    if (!isXs && !isSm && !lazyLoad) return tableData;

    return tableData.slice(0, (page + 1) * itemsPerPage);
  }, [isXs, isSm, lazyLoad, tableData, page, itemsPerPage]);

  const onUpdateSort = useCallback(
    (key: keyof T) => {
      setPage(0);

      const oppositeDirection = sort?.sortDirection === 'ASC' ? 'DESC' : 'ASC';

      const sortedData = onSort({
        sortKey: key,
        sortDirection: sort?.sortKey === key ? oppositeDirection : 'DESC',
      });

      if (sortedData) setTableData(sortedData);
    },
    [onSort, sort?.sortDirection, sort?.sortKey],
  );

  const getHeader = (key: keyof T) => headers[key];
  const isHeaderEnabled = (header?: EnhancedTableHeaderData) => header?.enabled ?? true;

  const renderGroupByRow = useCallback(
    (index: number) => {
      if (!groupBy || !isHeaderEnabled(headers[groupBy])) return null;

      const item = tableDataToRender[index];
      const prevItem = tableDataToRender[index - 1];

      if (!item) return null;

      const itemValue = item[groupBy].groupByTitle;
      const prevValue = prevItem?.[groupBy].groupByTitle;

      if (!itemValue && !prevValue) return null;

      if (prevValue !== itemValue || index === 0) {
        return (
          <TableRow className='sticky top-[40px] z-20 w-full bg-color-background-surface-tertiary'>
            <TableCell colSpan={Object.entries(item).length}>
              <Utility color='primary'>{itemValue}</Utility>
            </TableCell>
          </TableRow>
        );
      }

      return null;
    },
    [groupBy, headers, tableDataToRender],
  );

  return (
    <OverlayScrollbarsComponent
      className={cn('flex h-full w-full flex-col p-0', containerClassName)}
      options={{ scrollbars: { visibility: 'auto', autoHide: 'leave', autoHideDelay: 400 } }}
      events={{
        scroll: (_, e) => {
          const element = e.target as HTMLElement;
          setFreezeColumns(element?.scrollLeft > FREEZE_POINT);
          if (onScroll) onScroll(e);
        },
      }}
    >
      <Table className={className} {...props} data-spotlightelementid={spotlightElementId}>
        <thead>
          <TableRow className={cn(headerClassName, 'sticky top-0 z-20 h-40 px-12')} renderDivider isHeader>
            {Object.entries<EnhancedTableHeaderData>(headers).map(
              ([key, header]) =>
                isHeaderEnabled(header) && (
                  <TableHeader
                    key={key}
                    onClick={() => header.sortable && onUpdateSort((header.sortKey as keyof T) || (key as keyof T))}
                    justifyContent={header.alignment}
                    sortable={header.sortable}
                    frozen={header.frozen}
                    className={header.className}
                    showFrozenDivider={header.showFrozenDivider}
                    freezeColumns={header.frozen && freezeColumns}
                    offsetLeft={header.offsetLeft || 0}
                    sorting={
                      sort?.sortKey === key || sort?.sortKey === header.sortKey ? sort?.sortDirection : undefined
                    }
                    tooltip={header.tooltip}
                    insetLeft={inset && header.insetLeft}
                    insetRight={inset && header.insetRight}
                  >
                    <Utility variant='overline'>{header.title}</Utility>
                  </TableHeader>
                ),
            )}
          </TableRow>
        </thead>

        <TableBody className='h-full w-full'>
          {tableDataToRender.map((item, index) => (
            <>
              {renderGroupByRow(index)}
              <TableRow
                spotlightElementId={`${spotlightElementId}-row-item-${index}`}
                key={`table_row_${index}`}
                className={cn('cursor-pointer hover:bg-color-background-surface-hover', rowClassName)}
                renderDivider={index < tableDataToRender.length - 1}
              >
                {Object.entries<EnhancedTableDataItem>(item).map(([key, cell]) => {
                  const header = getHeader(key as keyof T);

                  if (!isHeaderEnabled(header)) return null;

                  return cell.element ? (
                    <TableCell
                      key={key}
                      frozen={header.frozen}
                      showFrozenDivider={header.showFrozenDivider}
                      freezeColumns={header.frozen && freezeColumns}
                      onMouseEnter={() => onMouseEnter && onMouseEnter(index)}
                      onMouseLeave={() => onMouseLeave && onMouseLeave(index)}
                      offsetLeft={header.offsetLeft || 0}
                      className={cn(cellClassName, header.className, cell.className)}
                      insetLeft={inset && header.insetLeft}
                      insetRight={inset && header.insetRight}
                      onClick={() => onClickItem && !cell.blockClick && onClickItem(item)}
                    >
                      {cell.element}
                    </TableCell>
                  ) : null;
                })}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
      {lazyLoad && hasMoreToLoad && (
        <div ref={intersectionObserverRef} className='flex w-full items-center justify-center p-8 pb-16'>
          {hasMoreToLoad && <Loading className='animate-spin text-color-text-primary' />}
        </div>
      )}
      {(isXs || isSm) && tableDataToRender.length < tableData.length && (
        <FlexLayout
          className='w-full bg-color-background-surface-primary p-16 py-16'
          alignItems='center'
          justifyContent='center'
        >
          <Button className='w-full' color='subtle' onClick={() => setPage((prev) => prev + 1)}>
            Show More
          </Button>
        </FlexLayout>
      )}

      {tableDataToRender.length === 0 && <div className='h-[calc(100%-40px)] w-full'>{children}</div>}
    </OverlayScrollbarsComponent>
  );
};

export { EnhancedTable };

// Maps the XState step ID to a title
export const StepTitles = {
  howItWorks: 'How it works',
  primarySourceOfIncome: 'Source of wealth',
  cashIncome: 'Cash deposits',
  occupation: 'Occupation',
  expectedInvestments: 'Expected investments',
  supportingDocuments: 'Supporting documents',
  reviewAndSubmit: 'Review & submit',
} as const;

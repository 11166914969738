/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, TextFieldProps } from '@swyftx/react-web-design-system';

import PasswordRequirements from './PasswordRequirements';
import PasswordValidator, { PasswordType } from '../../../hooks/validator/types/password.validator';
import { useValidation } from '../../../hooks/validator/useValidation';

type PasswordInputFormProps = {
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  success?: boolean;
  type: PasswordType;
  compareValue?: string;
  showRequirements?: boolean;
  liveValidation?: boolean;
  setValid?: (valid: boolean) => void;
  hideValidation?: boolean;
} & TextFieldProps;

export const PasswordInput: React.FC<PasswordInputFormProps> = ({
  id,
  attachForm,
  onChange,
  value,
  type,
  label,
  liveValidation = false,
  compareValue,
  showRequirements,
  setValid,
  hideValidation,
  ...props
}) => {
  const { t } = useTranslation('common');

  const validatorProps = useValidation(
    [
      new PasswordValidator(
        type,
        type === 'reenter' ? t('errors.password.passwordMatch') : t('errors.password.incorrect'),
        type === 'create' ? (value as string) : compareValue,
      ),
    ],
    liveValidation,
    onChange,
    value,
  );

  useEffect(() => {
    if (attachForm) attachForm(id!, validatorProps.validate);
  }, [attachForm]);

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  return (
    <>
      <Input
        id={id}
        PII
        label={label || t('labels.password')}
        placeholder={t('labels.password')}
        InputProps={{ sx: { height: '48px', fontSize: '14px' } }}
        ContainerProps={{ sx: { marginBottom: '.6rem' } }}
        sx={{ height: '48px', width: '100%' }}
        type='password'
        {...props}
        {...(!hideValidation
          ? validatorProps
          : {
              ...validatorProps,
              validate: () => Promise.resolve(false),
              success: false,
            })}
      />

      {showRequirements && (
        <PasswordRequirements
          error={validatorProps.error}
          type={type}
          password={value as string}
          confirmPassword={compareValue}
        />
      )}
    </>
  );
};

import { useCallback, useContext, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { DepositMethodEnum, RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useDeposit } from '@hooks/Deposit/useDeposit';

import { AutoInvestContext } from 'src/lib/auto-invest/context';
import { useAutoInvestAnalytics } from 'src/lib/auto-invest/hooks';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

import { useAutoInvestBalanceCheck } from './useAutoInvestBalanceCheck';

const useAutoInvestComplete = () => {
  const { onResetValues, onCancel, amount, startDate, selectedPaymentMethod, autoInvestOrder, frequency } =
    useContext(AutoInvestContext);
  const countryAsset = useCountryAsset();
  const { getBalance } = usePortfolioBalance();
  const { openModal } = useModal();
  const { diffDays, showBalanceWarning } = useAutoInvestBalanceCheck({
    startDate,
    amount,
    selectedPaymentMethod,
    frequency,
  });
  const { followUpActionPromptClicked } = useAutoInvestAnalytics();

  const { bankAddressDetails, loading } = useDeposit({
    asset: countryAsset!,
    transferType: DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
  });

  const hasEnoughBalance = useMemo(() => {
    // If no country asset, just assume there is enough so we dont show the error message
    if (!countryAsset || selectedPaymentMethod === RecurringOrderSource.DEPOSIT) return true;

    const countryBalance = getBalance(countryAsset?.id);

    return Big(amount).lt(countryBalance?.availableBalance || 0);
  }, [amount, countryAsset, getBalance, selectedPaymentMethod]);

  const handleCreateAnother = useCallback(() => {
    followUpActionPromptClicked('Create another order', undefined, 'Auto Invest');
    onResetValues();
  }, [followUpActionPromptClicked, onResetValues]);

  const handleOnCancel = useCallback(() => {
    followUpActionPromptClicked('Dismiss', undefined, 'Auto Invest');
    onCancel();
  }, [followUpActionPromptClicked, onCancel]);

  const handleDepositCountryAsset = useCallback(() => {
    followUpActionPromptClicked('Deposit funds', undefined, 'Auto Invest');
    openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
  }, [countryAsset, followUpActionPromptClicked, openModal]);

  const nextStartDate = useMemo(() => {
    switch (frequency) {
      case 'DAILY':
        return startDate?.plus({ day: 1 });
      case 'WEEKLY':
        return startDate?.plus({ day: 7 });
      case 'FORTNIGHTLY':
        return startDate?.plus({ day: 14 });
      case 'MONTHLY':
        return startDate?.plus({ month: 1 });
      case 'NEVER':
      default:
        return startDate;
    }
  }, [frequency, startDate]);

  const content = useMemo(() => {
    if (selectedPaymentMethod === RecurringOrderSource.DEPOSIT) {
      return (
        <FlexLayout direction='column' alignItems='center' spacing={8}>
          <Heading size='h4'>You’re almost there!</Heading>
          <Body color='secondary' className='text-center'>
            The final step is to log into your internet or mobile banking and set up a recurring transfer to your Swyftx
            account using the details below:.
          </Body>
        </FlexLayout>
      );
    } else if (selectedPaymentMethod === RecurringOrderSource.ACCOUNT && countryAsset) {
      return (
        <FlexLayout direction='column' spacing={24}>
          {diffDays.eq(0) && (
            <>
              <Body color='secondary' className='text-center'>
                Congratulations, your first order has commenced processing. Processing will typically take up to 15
                minutes.
              </Body>
              <Body color='secondary' className='text-center'>
                Your next scheduled order is due to process on{' '}
                <strong>{nextStartDate?.toFormat('EEEE dd MMMM, yyyy')}.</strong>
              </Body>
            </>
          )}
          {!diffDays.eq(0) && (
            <Body color='secondary' className='text-center'>
              Congratulations, your order is scheduled to process for the first time on{' '}
              <strong>{startDate?.toFormat('EEEE dd MMMM, yyyy')}</strong>. Please note, orders can only be modified up
              to 15 minutes prior to scheduled processing.
            </Body>
          )}
        </FlexLayout>
      );
    }

    return null;
  }, [countryAsset, diffDays, nextStartDate, selectedPaymentMethod, startDate]);

  return {
    handleOnCancel,
    handleCreateAnother,
    handleDepositCountryAsset,
    bankAddressDetails,
    autoInvestOrder,
    loading,
    content,
    hasEnoughBalance,
    countryAsset,
    diffDays,
    selectedPaymentMethod,
    showBalanceWarning,
  };
};

export { useAutoInvestComplete };

import { useMemo } from 'react';

import { RecurringOrderFrequency } from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { DateTime } from 'luxon';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

type Props = {
  startDate?: DateTime;
  amount?: string;
  selectedPaymentMethod?: RecurringOrderSource;
  frequency?: RecurringOrderFrequency;
};

const useAutoInvestBalanceCheck = ({ startDate, amount, selectedPaymentMethod, frequency }: Props) => {
  const { getBalance } = usePortfolioBalance();
  const countryAsset = useCountryAsset();

  const diffDays = useMemo(() => {
    if (!startDate) return Big(0);

    return Big(DateTime.now().startOf('day').diff(startDate.startOf('day'), 'days').days);
  }, [startDate]);

  const diffDayText = useMemo(() => {
    const days = diffDays.abs();

    if (days.eq(0)) {
      return 'immediately';
    } else if (days.eq(1)) {
      return 'tomorrow';
    }

    return `in ${days.toNumber()} days`;
  }, [diffDays]);

  const maxOrderAmount = useMemo((): Big | undefined => {
    if (!countryAsset) return undefined;

    return Big(getBalance(countryAsset.id)?.availableBalance || '0');
  }, [countryAsset, getBalance]);

  const showBalanceWarning = useMemo(() => {
    const overMaxAmount = maxOrderAmount && Big(amount).gt(maxOrderAmount);

    if (overMaxAmount && selectedPaymentMethod === RecurringOrderSource.ACCOUNT && frequency === 'DAILY') return true;
    if (selectedPaymentMethod === RecurringOrderSource.DEPOSIT || diffDays.abs().gt(2)) return false;

    return overMaxAmount;
  }, [amount, diffDays, frequency, maxOrderAmount, selectedPaymentMethod]);

  return {
    diffDays,
    diffDayText,
    maxOrderAmount,
    showBalanceWarning,
  };
};

export { useAutoInvestBalanceCheck };

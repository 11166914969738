import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { Search } from '@swyftx/aviary/icons/outlined';

import { useGlobalSearch } from 'src/lib/search/hooks/useGlobalSearch';
import { useGlobalSearchAnalytics } from 'src/lib/search/hooks/useGlobalSearchAnalytics';

const AppHeaderSearch: React.FC = () => {
  const { openGlobalSearch } = useGlobalSearch();
  const { trackGlobalSearchBarOpened } = useGlobalSearchAnalytics();

  const onClickSearch = useCallback(() => {
    openGlobalSearch();
    trackGlobalSearchBarOpened(['Manual click']);
  }, [openGlobalSearch, trackGlobalSearchBarOpened]);

  return (
    <Button
      id='header-search'
      variant='outlined'
      layout='icon'
      leadingIcon={<Search />}
      onClick={onClickSearch}
      tooltip='Press / to quickly bring up search'
    />
  );
};

AppHeaderSearch.displayName = 'AppHeaderSearch';

export { AppHeaderSearch };

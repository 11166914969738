import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Sports: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75ZM14.6604 8.27973C13.6253 7.03816 12.948 5.48779 12.7871 3.78706C14.4878 3.94802 16.0382 4.62528 17.2797 5.66041L14.6604 8.27973ZM11.2808 3.78091C11.4486 5.89755 12.2923 7.8239 13.596 9.34409L12.0004 10.9397L6.72071 5.66005C7.9787 4.61137 9.55367 3.93006 11.2808 3.78091ZM20.2191 12.7192C18.1028 12.5515 16.1768 11.7081 14.6568 10.4047L13.0611 12.0004L18.34 17.2793C19.3886 16.0213 20.0699 14.4463 20.2191 12.7192ZM15.7211 9.34032C16.9626 10.375 18.5126 11.052 20.2129 11.2129C20.052 9.51261 19.375 7.96256 18.3403 6.72114L15.7211 9.34032ZM12.7192 20.2191C14.4463 20.0699 16.0213 19.3886 17.2793 18.34L12.0004 13.0611L10.4047 14.6568C11.7081 16.1768 12.5515 18.1028 12.7192 20.2191ZM9.34032 15.7211C10.375 16.9626 11.052 18.5126 11.2129 20.2129C9.51261 20.052 7.96256 19.375 6.72114 18.3403L9.34032 15.7211ZM9.34409 13.596L10.9397 12.0004L5.66005 6.72071C4.61137 7.9787 3.93006 9.55367 3.78091 11.2808C5.89755 11.4486 7.8239 12.2923 9.34409 13.596ZM3.78706 12.7871C5.48779 12.948 7.03816 13.6253 8.27973 14.6604L5.66041 17.2797C4.62528 16.0382 3.94802 14.4878 3.78706 12.7871Z'
      fill='currentColor'
    />
  </svg>
);

export { Sports };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useTradeQuoteError } from 'src/lib/trade/hooks/General/useTradeQuoteError';

import { SwyftxProTradeAvailableBalance } from './components/SwyftxProTradeAvailableBalance';
import { SwyftxProTradeBreakdown } from './components/SwyftxProTradeBreakdown/SwyftxProTradeBreakdown';
import { SwyftxProTradeDetails } from './components/SwyftxProTradeDetails/SwyftxProTradeDetails';
import { SwyftxProTradeDirectionType } from './components/SwyftxProTradeDirectionType';
import { SwyftxProTradePanelHeader } from './components/SwyftxProTradePanelHeader';
import { SwyftxProTradeReviewButton } from './components/SwyftxProTradeReviewButton';
import { useSwyftxProTradeAmount } from '../../hooks/useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from '../../hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeBalance } from '../../hooks/useSwyftxProTradeBalance';
import { useSwyftxProTradeDirection } from '../../hooks/useSwyftxProTradeDirection';
import { useSwyftxProTradePrice } from '../../hooks/useSwyftxProTradePrice';
import { useSwyftxProTradeQuote } from '../../hooks/useSwyftxProTradeQuote';
import { useSwyftxProTradeType } from '../../hooks/useSwyftxProTradeType';
import { SwyftxProTradeWarnings } from '../SwyftxProTradeWarnings';

type Props = {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  onClose?: () => void;
};

const SwyftxProTradePanel: React.FC<Props> = observer(({ className, size = 'md', onClose }) => {
  const { buyAsset, sellAsset, limitAsset, selectedAsset } = useSwyftxProTradeAssets();
  const { balance } = useSwyftxProTradeBalance({ asset: sellAsset });
  const { triggerPrice } = useSwyftxProTradePrice();
  const { total, amount, setAmount, setTotal } = useSwyftxProTradeAmount();
  const { tradeDirection } = useSwyftxProTradeDirection();
  const { tradeType } = useSwyftxProTradeType();

  const { quoteError, errorMessage } = useSwyftxProTradeQuote();
  const { error, hasError } = useTradeQuoteError({
    quoteError,
    tradeDirection,
    tradeType,
    buyAsset,
    sellAsset,
    limitAsset,
    total,
    balance,
    amount,
    errorMessage,
    triggerPrice,
    setAmount,
    setTotal,
  });

  return (
    <FlexLayout
      direction='column'
      justifyContent='space-between'
      className={cn(className, 'min-h-full bg-color-background-surface-primary pb-12 lg:min-h-[32rem]')}
      spacing={12}
    >
      <FlexLayout direction='column'>
        <SwyftxProTradeWarnings selectedAsset={selectedAsset} baseAsset={sellAsset} />
        <FlexLayout className='px-12' alignItems='start' direction='column' spacing={0}>
          <SwyftxProTradePanelHeader onClose={onClose} />
          <FlexLayout alignItems='start' className='w-full' direction='column' spacing={12}>
            <SwyftxProTradeDirectionType />
            <SwyftxProTradeAvailableBalance />
            <SwyftxProTradeDetails error={error} />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='column' className='px-12' spacing={12}>
        <SwyftxProTradeBreakdown />
        <SwyftxProTradeReviewButton hasError={hasError} size={size} />
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxProTradePanel };

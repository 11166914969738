import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Dollar: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.9615 5.72C12.9615 5.32235 12.6391 5 12.2415 5C11.8438 5 11.5215 5.32235 11.5215 5.72V7.24072H10.9605C9.32594 7.24072 8 8.56522 8 10.2005C8 11.8358 9.32595 13.1602 10.9605 13.1602H11.5215V16.2004H9.36038C8.96273 16.2004 8.64038 16.5228 8.64038 16.9204C8.64038 17.3181 8.96273 17.6404 9.36038 17.6404H11.5215V19.16C11.5215 19.5576 11.8438 19.88 12.2415 19.88C12.6391 19.88 12.9615 19.5576 12.9615 19.16V17.6404H13.5205C15.1554 17.6404 16.4802 16.3156 16.4802 14.6807C16.4802 13.0462 15.1557 11.7202 13.5205 11.7202H12.9615V8.68072H15.1206C15.5182 8.68072 15.8406 8.35837 15.8406 7.96072C15.8406 7.56308 15.5182 7.24072 15.1206 7.24072H12.9615V5.72ZM11.5215 8.68072H10.9605C10.1206 8.68072 9.44 9.36117 9.44 10.2005C9.44 11.0398 10.1206 11.7202 10.9605 11.7202H11.5215V8.68072ZM12.9615 13.1602V16.2004H13.5205C14.3601 16.2004 15.0402 15.5203 15.0402 14.6807C15.0402 13.8408 14.3597 13.1602 13.5205 13.1602H12.9615Z'
      fill='currentColor'
    />
  </svg>
);

export { Dollar };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Progress } from '@swyftx/aviary/atoms/Progress';

import { useSourceOfWealthSelector } from '@SourceOfWealth/SourceOfWealth.context';
import { sourceOfWealthMachine } from '@SourceOfWealth/SourceOfWealth.machine';
import { StateNode } from 'xstate';

import { Step, transformSourceOfWealthStates } from './stepperUtils';

const MobileSourceOfWealthStepper: React.FC = () => {
  const transformedSteps = useMemo(
    () => transformSourceOfWealthStates(sourceOfWealthMachine.states as Record<string, StateNode>),
    [],
  );

  const currentState = useSourceOfWealthSelector((state) => state.toStrings());

  const [activeRootStep, activeRootStepIndex] = useMemo<[Step | undefined, number]>(() => {
    const [rootStepId] = currentState;
    const index = transformedSteps.findIndex((s) => s.id === rootStepId);
    return [transformedSteps[index], index];
  }, [transformedSteps, currentState]);

  const activeStep = useMemo<Step | undefined>(() => {
    const [, activeStepId] = currentState;
    if (!activeStepId || !sourceOfWealthMachine.getStateNodeByPath(activeStepId)?.meta?.step) {
      return activeRootStep;
    }

    const foundStep = activeRootStep?.children.find((s) => s.id === activeStepId);
    if (foundStep) return foundStep;
    return undefined;
  }, [activeRootStep, currentState]);

  // Dont render on the final step
  if (!activeStep?.order) {
    return null;
  }

  return (
    <FlexLayout direction='column'>
      <FlexLayout direction='row' spacing={8}>
        {transformedSteps.map((rootStep, index) => {
          const activeIndexInRoot = rootStep.children.findIndex((s) => s.id === activeStep?.id);

          let progress = ((activeIndexInRoot + 1) / rootStep.children.length) * 100 || 0; // 0 fallback to cover NaN

          // Previous steps should show completion 100%
          if (activeRootStepIndex > index) {
            progress = 100;
          }

          // Edge case where root has no children and is a step in itself (fallback to 1 so we don't have 0/0)
          if (activeRootStepIndex === index && rootStep.children.length === 0) {
            progress = 100;
          }

          return (
            <Progress
              key={rootStep.id}
              value={progress}
              className='mb-32 h-8 flex-grow rounded-4 bg-color-background-neutral'
            />
          );
        })}
      </FlexLayout>
    </FlexLayout>
  );
};

export { MobileSourceOfWealthStepper };

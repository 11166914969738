import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { ExternalLink } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { useCategoriesAnalytics } from 'src/lib/categories/hooks/useCategoriesAnalytics';

import { SingleCategoryLearnCardLogo } from './SingleCategoryLearnCardLogo';

type Props = {
  imageSrc: string;
  title: string;
  content: string;
  articleUrl: string;
};

const SingleCategoryLearnCard: React.FC<Props> = ({ imageSrc, title, content, articleUrl }) => {
  const { trackLearnArticleClicked } = useCategoriesAnalytics();

  return (
    <Card className='h-full w-full p-24'>
      <FlexLayout className={cn('flex-col', '@lg:flex-col', '@sm:flex-row', 'h-full w-full')} spacing={24}>
        <div
          className={cn(
            'order-1 min-h-[180px] w-full max-w-full',
            '@sm:order-2 @sm:max-h-[132px] @sm:min-h-[132px] @sm:min-w-[132px] @sm:max-w-[132px]',
            '@lg:order-1 @lg:min-h-[168px] @lg:w-full @lg:max-w-full',
            '@xl:order-1 @xl:min-h-[180px]',
            'rounded-16',
          )}
          style={{ backgroundImage: `url(${imageSrc})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        />
        <FlexLayout direction='column' spacing={24} className={cn('@lg:order-2', 'order-1', 'h-full')}>
          <FlexLayout direction='column' className='h-full' spacing={8}>
            <Heading size='h6'>{title}</Heading>
            <Body color='secondary' size='small' className={cn('line-clamp-3', '@lg:line-clamp-[7]')}>
              {content}
            </Body>
          </FlexLayout>
          {articleUrl && (
            <FlexLayout alignItems='center' justifyContent='space-between'>
              <a
                href={articleUrl}
                target='_blank'
                rel='noreferrer'
                onClick={() => trackLearnArticleClicked({ title, articleUrl })}
              >
                <Button size='lg' trailingIcon={<ExternalLink />} className='hidden @lg:block'>
                  Read article
                </Button>
                <Button size='md' trailingIcon={<ExternalLink />} className='block @lg:hidden'>
                  Read article
                </Button>
              </a>
              <SingleCategoryLearnCardLogo />
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export { SingleCategoryLearnCard };
export type { Props as LearnCardProps };

import { AxiosResponse } from 'axios';
import {
  GetAlertCountQuery,
  GetTriggerAlertsQuery,
  PriceAlertSecondaryCount,
  UpdateLimitAlertData,
  UpdateVolatilityAlertData,
  CreateLimitAlertData,
  CreateVolatilityAlertData,
  GetPriceAlertsQuery,
  PriceAlert,
  UpdateAlertData,
  DeleteAlertsData,
  TriggeredPriceAlert,
} from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';

import {
  CreateAlertData,
  CreateAlertParams,
  CreateAlertResponse,
  DeleteAlertData,
  GetAlertsQuery,
  GetAlertsResponse,
} from '../../@types/alerts';
import { Methods, request } from '../rest';

const alertsEndpoints = {
  // Legacy endpoints
  createAlert: ({ params, data }: { params: CreateAlertParams; data: CreateAlertData }): CreateAlertResponse =>
    request({
      path: '/alerts/:primary/:secondary/',
      method: Methods.POST,
      data,
      params,
      auth: true,
      hideErrorToast: true,
    }),

  getAlerts: ({ query }: { query: GetAlertsQuery }): GetAlertsResponse =>
    request({ path: '/alerts/', method: Methods.GET, query, auth: true, hideErrorToast: true }),

  // New endpoints
  getPriceAlerts: ({ query }: { query: GetPriceAlertsQuery }): PriceAlert[] =>
    request({ path: '/alerts/', method: Methods.GET, query, auth: true, hideErrorToast: true }),

  createPriceAlert: ({
    params,
    data,
  }: {
    params: CreateAlertParams;
    data: CreateLimitAlertData | CreateVolatilityAlertData;
  }): CreateAlertResponse =>
    request({
      path: '/alerts/:primary/:secondary/',
      method: Methods.POST,
      data,
      params,
      auth: true,
      hideErrorToast: true,
    }),

  updatePriceAlert: ({ data }: { data: UpdateLimitAlertData | UpdateVolatilityAlertData }): PriceAlert =>
    request({
      path: '/alerts',
      method: Methods.PATCH,
      data,
      auth: true,
      hideErrorToast: true,
    }),

  pausePriceAlert: ({ data }: { data: UpdateAlertData }): PriceAlert =>
    request({
      path: '/alerts/pause',
      method: Methods.POST,
      data,
      auth: true,
      hideErrorToast: true,
    }),

  resumePriceAlert: ({ data }: { data: UpdateAlertData }): PriceAlert =>
    request({
      path: '/alerts/resume',
      method: Methods.POST,
      data,
      auth: true,
      hideErrorToast: true,
    }),

  deleteAlert: ({ data }: { data: DeleteAlertData }): Promise<AxiosResponse> =>
    request({ path: '/alerts/', method: Methods.DELETE, data, auth: true, hideErrorToast: true }),

  deleteAlerts: ({ data }: { data: DeleteAlertsData }): Promise<AxiosResponse> =>
    request({ path: '/alerts/', method: Methods.DELETE, data, auth: true, hideErrorToast: true }),

  getAlertCounts: ({ query }: { query: GetAlertCountQuery }): PriceAlertSecondaryCount[] =>
    request({ path: '/alerts/assets', method: Methods.GET, query, auth: true, hideErrorToast: true }),

  getTriggerAlerts: ({ query }: { query: GetTriggerAlertsQuery }): TriggeredPriceAlert[] =>
    request({ path: '/alerts/triggers', method: Methods.GET, query, auth: true, hideErrorToast: true }),

  deleteTriggerAlerts: ({ data }: { data: DeleteAlertsData }): Promise<AxiosResponse> =>
    request({ path: '/alerts/triggers', method: Methods.DELETE, data, auth: true, hideErrorToast: true }),

  getTriggerAlertCounts: ({ query }: { query: GetAlertCountQuery }): PriceAlertSecondaryCount[] =>
    request({ path: '/alerts/triggers/assets', method: Methods.GET, query, auth: true, hideErrorToast: true }),

  /**
   * returns the IDs of all alert triggers that have not been dismissed
   */
  getTriggerNotifications: ({ query }: { query: { secondary?: number } }): number[] =>
    request({
      path: '/alerts/triggers/notifications',
      method: Methods.GET,
      query,
      auth: true,
      hideErrorToast: true,
    }),

  /**
   * dismisses all alert triggers with IDs specified in the triggers query parameter
   */
  dismissTriggerNotifications: ({ data }: { data: { triggers: number[] } }): Promise<AxiosResponse> =>
    request({
      path: '/alerts/triggers/notifications',
      method: Methods.DELETE,
      data,
      auth: true,
      hideErrorToast: true,
    }),
};

export default alertsEndpoints;

import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { observer } from 'mobx-react-lite';
import { useToggleTheme } from 'src/lib/utils/hooks';

const DarkModeSwitch: React.FC = observer(() => {
  const { toggleDarkMode, isDarkMode } = useToggleTheme();

  return (
    <Switch
      size='md'
      id='profile-settings-dark-mode-switch'
      onCheckedChange={() => toggleDarkMode(!isDarkMode)}
      checked={isDarkMode}
      data-transition-force
    />
  );
});

DarkModeSwitch.displayName = 'DarkModeSwitch';

export { DarkModeSwitch };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, TabsList, TabsTrigger, TabsContent } from '@swyftx/aviary/atoms/Tabs';
import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';
import { Button, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { CardDepositProvider, useCardDepositProvider } from '@hooks/Deposit/useCardDepositProvider';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';

import { useForm } from 'react-hook-form';

import { OnboardingStepContainer } from './OnboardingStepContainer';
import { BankTransfers } from './depositFunds/DepositFundsBankTransfers';
import { PayID } from './depositFunds/DepositFundsPayID';
import { Stripe } from './depositFunds/DepositFundsStripe';

export enum AcceptedDepositMethod {
  MONOOVA_DIRECT_CREDIT = DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
  MONOOVA_PAYID = DepositMethodEnum.MONOOVA_PAYID,
  STRIPE = DepositMethodEnum.STRIPE,
}

export function getDepositMethodKey(value: number): string {
  return (
    Object.keys(AcceptedDepositMethod).find(
      (key) => AcceptedDepositMethod[key as keyof typeof AcceptedDepositMethod] === value,
    ) || ''
  );
}

const DepositFundsStep: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const { userBaseCurrency, isNZ } = UserStore.useUserStore;
  const [asset, setAsset] = React.useState<Asset | undefined>(undefined);
  const onboardingService = useOnboardingService();
  const verificationData = useOnboardingSelector((state) => state.context.verificationData);
  const cardPaymentProvider = useCardDepositProvider();
  const isStripeEnabled = cardPaymentProvider === CardDepositProvider.Stripe;

  React.useEffect(() => {
    setAsset(assetService.getAsset(userBaseCurrency));
  }, [userBaseCurrency]);

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      accountPurpose: verificationData?.accountPurpose,
    },
  });

  const onSubmit = () => {
    onboardingService.send({ type: 'NEXT' });
  };

  const onSkipClick = () => {
    onboardingService.send({ type: 'SKIP' });
  };

  if (!asset) {
    return null;
  }

  return (
    <OnboardingStepContainer submitDisabled={!isValid}>
      <Typography color='text.secondary' marginBottom={4}>
        {t('steps.depositFunds.description')}
      </Typography>

      <Tabs defaultValue='bankTransfers'>
        <TabsList className='grid w-full grid-cols-3 '>
          <TabsTrigger value='bankTransfers'>{t('steps.depositFunds.option.bankTransfers.title')}</TabsTrigger>
          <TabsTrigger value='payID'>{t('steps.depositFunds.option.payID.title')}</TabsTrigger>
          {!isNZ() && isStripeEnabled && (
            <TabsTrigger value='stripe'>{t('steps.depositFunds.option.stripe.title')}</TabsTrigger>
          )}
        </TabsList>
        <TabsContent value='bankTransfers'>
          <BankTransfers asset={asset} />
          <Button fullWidth variant='contained' type='submit' onClick={handleSubmit(onSubmit)}>
            {t('steps.depositFunds.option.bankTransfers.deposited')}
          </Button>
        </TabsContent>
        <TabsContent value='payID'>
          <PayID asset={asset} />
          <Button fullWidth variant='contained' type='submit' onClick={handleSubmit(onSubmit)}>
            {t('steps.depositFunds.option.payID.deposited')}
          </Button>
        </TabsContent>
        {!isNZ() && isStripeEnabled && (
          <TabsContent value='stripe'>
            <Stripe onSuccess={onSubmit} />
          </TabsContent>
        )}
      </Tabs>

      <Button fullWidth variant='text' color='inherit' endIcon={<ArrowLineOnlyRight />} onClick={onSkipClick}>
        Skip
      </Button>
    </OnboardingStepContainer>
  );
};

export { DepositFundsStep };

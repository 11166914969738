import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';

import { DynamicPriceAlertFilters } from 'src/lib/dynamic-price-alerts/components';
import {
  PriceAlertGroupBy,
  PriceAlertTriggerType,
  PriceAlertType,
} from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';

import { DynamicPriceAlertsMobileFilters } from '../DynamicPriceAlertsMobileFilters';
import { DynamicPriceAlertsTabs } from '../DynamicPriceAlertsTabs';

type Props = {
  currentTab: PriceAlertType;
  setCurrentTab: React.Dispatch<React.SetStateAction<PriceAlertType>>;
  groupBy: PriceAlertGroupBy;
  setGroupBy: React.Dispatch<React.SetStateAction<PriceAlertGroupBy>>;
  filterTypes: PriceAlertTriggerType[];
  setFilterTypes: React.Dispatch<React.SetStateAction<PriceAlertTriggerType[]>>;
  filterAssets: Asset[];
  setFilterAssets: React.Dispatch<React.SetStateAction<Asset[]>>;
};

const DynamicPriceAlertsFilterBar: React.FC<Props> = ({
  currentTab,
  setCurrentTab,
  groupBy,
  setGroupBy,
  filterTypes,
  setFilterTypes,
  filterAssets,
  setFilterAssets,
}) => (
  <FlexLayout
    alignItems='center'
    justifyContent='space-between'
    spacing={16}
    className='w-full flex-col items-start @md:flex-row'
  >
    <DynamicPriceAlertsMobileFilters
      className='flex sm:hidden'
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      groupBy={groupBy}
      filterTypes={filterTypes}
      filterAssets={filterAssets}
      setGroupBy={setGroupBy}
      alertType={currentTab}
      setFilterTypes={setFilterTypes}
      setFilterAssets={setFilterAssets}
      showAssetFilter
    />

    <FlexLayout
      alignItems='center'
      justifyContent='space-between'
      spacing={16}
      className='hidden w-full flex-col items-start @md:flex-row sm:flex'
    >
      <DynamicPriceAlertsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <DynamicPriceAlertFilters
        align='end'
        groupBy={groupBy}
        filterTypes={filterTypes}
        filterAssets={filterAssets}
        setGroupBy={setGroupBy}
        alertType={currentTab}
        setFilterTypes={setFilterTypes}
        setFilterAssets={setFilterAssets}
      />
    </FlexLayout>
  </FlexLayout>
);

export { DynamicPriceAlertsFilterBar };

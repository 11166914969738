import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ReceiveCryptoFilled } from '@swyftx/aviary/icons/filled';

import { AssetReceiveButton } from '@global-components/Assets';
import { AssetTransactionHistoryModal } from '@global-components/Modals/AssetTransactionHistoryModal';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { CryptoTransferWarning } from '@global-components/message-boxes/CryptoTransferWarning';

import { Asset } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { EntityPermissions, usePermissions } from '@hooks/Permissions';
import { TransactionTypeFilter } from '@services/WalletService';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SingleWalletReceiveInfo } from './components';

type Props = {
  asset: Asset;
};

const LOG_TAG = 'SINGLE_WALLET';

const SingleWalletReceive: React.FC<Props> = observer(({ asset }) => {
  const { navigate } = useNavigateRoute();
  const { userProfile, canTransferCrypto } = UserStore.useUserStore;
  const { isDemo } = AppStore.useAppStore;
  const [showTransactionHistory, setShowTransactionHistory] = useState<boolean>(false);
  const { t } = useTranslation('wallet');
  const { openModal } = useModal();
  const isFiat = assetService.isAssetFiat(asset.id);
  const canTransfer = isFiat || canTransferCrypto();
  const { pathname } = useLocation();
  const avo = useAvo();

  const { enabled, element: PermissionsChip } = usePermissions({
    permissions: [EntityPermissions.ADDRESS_READ],
    disabledOptions: { chip: true },
  });

  const depositDisabled = useMemo(() => !(userProfile?.accountStatus?.deposits || 0), [userProfile]);
  const receiveDisabled = useMemo(
    () => asset.networks.every((network) => network.depositDisabled) || !asset.deposit_enabled || !canTransfer,
    [asset, canTransfer],
  );

  const openVerification = () => {
    navigate(NavigationURLs.Onboarding);
  };

  const getAccordionTrailing = () => {
    if (isDemo) {
      return (
        <Chip size='md' color='secondary'>
          {t('demoMode.notAvailable')}
        </Chip>
      );
    }

    if (!enabled) return PermissionsChip;

    return undefined;
  };

  return (
    <>
      <Accordion type='single' collapsible>
        <AccordionItem value={isDemo || !enabled ? '' : 'receive'}>
          <AccordionTrigger disabled={isDemo || !enabled}>
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
              <FlexLayout alignItems='center'>
                <ReceiveCryptoFilled className='h-20 w-20 text-color-text-accent' alt='deposit funds' />
                <Body weight='bold' className='ml-8'>
                  {t('singleWalletReceive.title')}
                </Body>
              </FlexLayout>
              {getAccordionTrailing()}
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent>
            <FlexLayout
              direction='column'
              className='w-full px-4'
              alignItems='center'
              justifyContent='center'
              spacing={16}
            >
              {depositDisabled && (
                <FlexLayout direction='column' spacing={2}>
                  <Notification severity='destructive' title={t('singleWalletReceive.labels.disabledTitle')}>
                    {t('singleWalletReceive.labels.disabledDescription')}
                  </Notification>
                  <Button variant='filled' size='lg' className='w-full' onClick={openVerification}>
                    {t('singleWalletReceive.buttonLabels.resolve')}
                  </Button>
                </FlexLayout>
              )}
              {!depositDisabled && !receiveDisabled && <SingleWalletReceiveInfo />}
              {!depositDisabled && receiveDisabled && canTransfer && (
                <Notification severity='destructive' title='Deposits disabled' className='my-16 w-full'>
                  <Body weight='emphasis'>{t('singleWalletReceive.labels.disabledDeposits')}</Body>
                </Notification>
              )}
              {!depositDisabled && receiveDisabled && !canTransfer && <CryptoTransferWarning direction='deposit' />}
              <AssetReceiveButton
                hideIfDepositDisabled
                onReceive={() => {
                  avo.depositFundsTapped({
                    screen: pathname,
                    component: LOG_TAG,
                  });
                  openModal(Modals.DepositReceive, { selectedAsset: asset });
                }}
                asset={asset}
              />
              <FlexLayout
                className='mt-16 w-full p-4'
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                spacing={16}
              >
                <Button disabled className='w-full'>
                  {t('singleWalletReceive.buttonLabels.checkNewDeposits')}
                </Button>
                <Button onClick={() => setShowTransactionHistory(true)} className='w-full' disabled={!canTransfer}>
                  {t('singleWalletFiatDeposit.selector.buttonLabels.depositHistory')}
                </Button>
              </FlexLayout>
            </FlexLayout>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      {showTransactionHistory && (
        <AssetTransactionHistoryModal
          onClose={() => setShowTransactionHistory(false)}
          type={TransactionTypeFilter.DEPOSIT}
          asset={asset}
        />
      )}
    </>
  );
});

SingleWalletReceive.displayName = 'SingleWalletReceive';

export { SingleWalletReceive };

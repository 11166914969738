import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';

import { cn } from '@shared/utils/lib/ui';

import { Root, Portal, Content, Trigger, Arrow } from '@radix-ui/react-popover';

export interface MenuProps {
  triggerContent?: React.ReactNode;
  children?: React.ReactNode;
  align?: 'center' | 'end' | 'start';
  variant?: 'standard' | 'mobile';
  sideOffset?: number;
  collisionPadding?: number;
  hideArrow?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  padding?: boolean;
  title?: string;
  className?: string;
  contentClassName?: string;
  portalClassName?: string;
  container?: HTMLElement | null;
  spotlightElementId?: string;
}

export const Menu: React.FC<MenuProps> = (props) => {
  const {
    triggerContent,
    children,
    align,
    hideArrow = false,
    variant = 'standard',
    className,
    contentClassName,
    portalClassName,
    padding = true,
    sideOffset = 10,
    collisionPadding = 15,
    title,
    open,
    container,
    spotlightElementId,
    onOpenChange,
  } = props;

  return (
    <>
      {variant === 'mobile' && (
        <Modal
          position='bottom'
          open={open}
          onOpenChange={(o) => onOpenChange && onOpenChange(o || false)}
          overlayClassName='z-modal-popover-backdrop'
          title={title}
        >
          <FlexLayout
            direction='column'
            className={cn('flex-wrap text-left', contentClassName, padding ? 'p-16' : 'p-0')}
            spacing={12}
          >
            {children}
          </FlexLayout>
        </Modal>
      )}

      <Root open={open} onOpenChange={onOpenChange}>
        <Trigger asChild>{triggerContent}</Trigger>
        {variant === 'standard' && (
          <Portal container={container}>
            <Content
              className={cn(
                `z-menu
              max-h-[80vh] overflow-hidden
              overflow-y-auto rounded-[12px] 
              shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] 
              data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade
              data-[side=top]:animate-slideDownAndFade
              `,
                portalClassName,
              )}
              sideOffset={sideOffset}
              align={align}
              collisionPadding={collisionPadding}
            >
              <Card className={cn('w-[300px] rounded-[12px]', className)} data-spotlightelementid={spotlightElementId}>
                <FlexLayout
                  direction='column'
                  className={cn('flex-wrap text-left', padding ? 'p-8' : 'p-0', contentClassName)}
                  spacing={2}
                >
                  {children}
                </FlexLayout>
              </Card>
              {!hideArrow && <Arrow className='fill-color-background-surface-primary' height={10} width={15} />}
            </Content>
          </Portal>
        )}
      </Root>
    </>
  );
};

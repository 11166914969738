import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import StepModal from '@swyftx/aviary/molecules/StepModal';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { TransferModalStep } from '../TransferModal.types';

type Props = {
  onClose: () => void;
};

const EmailVerificationStep: React.FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation('common', { keyPrefix: 'verification' });
  const { userProfile } = UserStore.useUserStore;
  const email = userProfile?.email;

  return (
    <StepModal.Step
      onClose={onClose}
      hideBack
      hideActions
      title='Verification via email required'
      key={TransferModalStep.EmailVerification}
    >
      <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
        <Body color='secondary'>
          {t('email.actionComplete')}
          <strong className='fs-mask'>{email}.</strong>
        </Body>
        <Body color='secondary'>{t('email.checkInbox')}</Body>
      </FlexLayout>
    </StepModal.Step>
  );
});

export { EmailVerificationStep };

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Paste } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { useCryptoAddressDetails } from '@global-components/Modals/SendCrypto/hooks/useCryptoAddressDetails';
import { useInvalidAddressMessage } from '@global-components/Modals/SendCrypto/hooks/useInvalidAddressMessage';

import { api, Asset, Network } from '@shared/api';
import { CryptoEnum, NetworkEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { walletAddressService } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useDebounce } from 'react-use';

import { AddAddressFormData, AddCryptoAddressFormData, TransferModalStep } from '../../TransferModal.types';
import { useAddWithdrawalAddress } from '../../hooks/useAddWithdrawalAddress';
import { useCheckWithdrawalAddress } from '../../hooks/useCheckWithdrawalAddress';

type Props = {
  asset: Asset;
  addAddressFormData: AddCryptoAddressFormData;
  onUpdateFormData: <T extends AddAddressFormData>(key: keyof T, value: T[keyof T]) => void;
  onClose: () => void;
  setAddAddressToken: React.Dispatch<React.SetStateAction<string>>;
};

const VALIDATION_DEBOUNCE = 300;

const AddNewAddressCryptoFields: React.FC<Props> = ({
  asset,
  addAddressFormData,
  onUpdateFormData,
  onClose,
  setAddAddressToken,
}) => {
  const { label, address, selectedCryptoNetworkId, metaDataPayload } = addAddressFormData;
  const { onNextStep, onBack, goBackToStep } = useContext(StepModalContext);
  const { showAddressBreachMessage } = useCheckWithdrawalAddress();
  const { addWithdrawalAddress } = useAddWithdrawalAddress({ asset });
  const [addingAdddress, setAddingAddress] = useState<boolean>(false);
  const [debouncedAddress, setDebouncedAddress] = useState<string>('');
  const [debouncedMemo, setDebouncedMemo] = useState('');
  const [validMemo, setValidMemo] = useState<boolean>();
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });
  const { addToastMessage } = UIStore.useUIStore;
  const { setUserProfile } = UserStore.useUserStore;
  const labelRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('wallet');
  const { t: commonT } = useTranslation('common');

  const { errorMessage, notification } = useInvalidAddressMessage(address, asset);
  const { validCryptoAddress, cryptoAddressMetadata, cryptoNetworks } = useCryptoAddressDetails(
    asset,
    debouncedAddress,
  );

  const filteredCryptoNetworks = useMemo(() => cryptoNetworks.filter((c) => !c.withdrawDisableForce), [cryptoNetworks]);

  const selectedNetwork = useMemo(
    () => filteredCryptoNetworks.find((n) => n.id === selectedCryptoNetworkId),
    [filteredCryptoNetworks, selectedCryptoNetworkId],
  );

  const singleNetworkFound = useMemo(() => filteredCryptoNetworks?.length === 1, [filteredCryptoNetworks?.length]);

  const multipleNetworksFound = filteredCryptoNetworks?.length > 1;
  const disabledSelectedNetwork =
    selectedNetwork && (selectedNetwork.withdrawDisabled || selectedNetwork.withdrawDisableForce);
  const showEthWarning =
    !selectedNetwork?.withdrawDisabled &&
    !selectedNetwork?.withdrawDisableForce &&
    asset.id === CryptoEnum.BTC &&
    selectedNetwork?.id === NetworkEnum.ETH;
  const isMemoRequired = cryptoAddressMetadata?.required;

  useEffect(() => {
    if (singleNetworkFound) {
      onUpdateFormData<AddCryptoAddressFormData>('selectedCryptoNetworkId', filteredCryptoNetworks[0].id);
    }
  }, [filteredCryptoNetworks, onUpdateFormData, singleNetworkFound]);

  useDebounce(
    () => {
      if (address.length) {
        setDebouncedAddress(address);
      }
    },
    VALIDATION_DEBOUNCE,
    [address],
  );

  useDebounce(
    () => {
      if (cryptoAddressMetadata && metaDataPayload?.[cryptoAddressMetadata.key].length) {
        setDebouncedMemo(metaDataPayload[cryptoAddressMetadata.key]);
      }
    },
    VALIDATION_DEBOUNCE,
    [metaDataPayload],
  );

  const updateAddressFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      onUpdateFormData<AddCryptoAddressFormData>('address', text.trim());
      addToastMessage({ message: t('withdrawCrypto.addAddress.updateDateClipboardToast.address'), severity: 'info' });
    });
  };

  const updateMemoFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      if (!cryptoAddressMetadata) return;

      onUpdateFormData<AddCryptoAddressFormData>('metaDataPayload', { [cryptoAddressMetadata.key]: text.trim() });

      addToastMessage({ message: t('withdrawCrypto.addAddress.updateDateClipboardToast.memo'), severity: 'info' });
    });
  };

  useEffect(() => {
    if (debouncedMemo?.length) {
      setValidMemo(walletAddressService.isValidCryptoMemo(debouncedMemo, selectedNetwork?.networkName));
    }
  }, [debouncedMemo, selectedNetwork]);

  const memoTitle = useMemo(() => {
    if (selectedNetwork?.destinationTag) return 'Destination Tag';

    return 'Memo';
  }, [selectedNetwork]);

  const getNetworkName = (network: Network) => {
    switch (network.id) {
      case NetworkEnum.BTC:
      case NetworkEnum.TRX:
      case NetworkEnum.ETH:
      case NetworkEnum.NEO:
      case NetworkEnum.BNB:
        return `${network.networkName} (${network.networkNameFull})`; // Show both network code and name
      case NetworkEnum.BSC:
        return 'BSC (BEP20)'; // BSC is a unique case where the Full Network Name doesnt work when formatted the same way as the other networks
      default:
        return network.networkName; // Show only network code
    }
  };

  const addAddressDisabled = useMemo(() => {
    if (!address || !label || selectedCryptoNetworkId === -1) return true;
    if (!filteredCryptoNetworks.find((n) => n.id === selectedCryptoNetworkId)) return true;
    if (errorMessage || !validCryptoAddress) return true;

    return false;
  }, [address, errorMessage, filteredCryptoNetworks, label, selectedCryptoNetworkId, validCryptoAddress]);

  const resetFormData = useCallback(() => {
    onUpdateFormData<AddCryptoAddressFormData>('address', '');
    onUpdateFormData<AddCryptoAddressFormData>('label', '');
    setDebouncedAddress('');
    setDebouncedMemo('');

    if (cryptoAddressMetadata) {
      onUpdateFormData<AddCryptoAddressFormData>('metaDataPayload', {
        [cryptoAddressMetadata.key]: '',
      });
      onUpdateFormData<AddCryptoAddressFormData>('selectedCryptoNetworkId', -1);
    }
  }, [cryptoAddressMetadata, onUpdateFormData]);

  const onAddAddress = useCallback(async () => {
    if (addAddressDisabled) return;

    setAddingAddress(true);
    try {
      const { mfaType, token } = await addWithdrawalAddress(addAddressFormData);

      switch (mfaType) {
        case 'sms': {
          setAddAddressToken(token);
          onNextStep(TransferModalStep.PhoneVerification);
          break;
        }
        case 'email': {
          setAddAddressToken(token);
          onNextStep(TransferModalStep.EmailVerification);
          break;
        }
        default: {
          setAddAddressToken('');
          onClose();
          addToastMessage({ severity: 'success', message: commonT('addAddress.success') });
          break;
        }
      }
    } catch (e) {
      setAddAddressToken('');
      const error = e as SwyftxError;
      const breachType = showAddressBreachMessage(addAddressFormData.address, error);

      if (breachType === 'blacklist') {
        // Reset fields
        resetFormData();

        labelRef?.current?.focus();
      } else if (breachType === 'scam') {
        api.endpoints.getProfile.resetCache();
        const response = await api.endpoints.getProfile();
        setUserProfile(response.data.user.profile);
        resetFormData();
        goBackToStep(TransferModalStep.Root);
      } else {
        addToastMessage({ severity: 'error', message: error?.message || commonT('addAddress.genericError') });
      }
    } finally {
      setAddingAddress(false);
    }
  }, [
    addAddressDisabled,
    addAddressFormData,
    addToastMessage,
    addWithdrawalAddress,
    commonT,
    goBackToStep,
    onClose,
    onNextStep,
    resetFormData,
    setAddAddressToken,
    setUserProfile,
    showAddressBreachMessage,
  ]);

  return (
    <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
      <Body color='secondary' size='small'>
        Once you enter an address you will be able to enter the memo or destination tag (if applicable) for the selected
        network.
      </Body>
      {notification}
      <FlexLayout spacing={8} direction='column'>
        <Body size='small' weight='emphasis'>
          {t('withdrawCrypto.addAddress.addressNickname')}:<span className='text-color-text-error'>*</span>
        </Body>
        <Input
          ref={labelRef}
          autoFocus
          className='fs-mask'
          onChange={(e) => onUpdateFormData<AddCryptoAddressFormData>('label', e.target.value)}
          placeholder={t('withdrawCrypto.addAddress.addressNicknamePlaceholder')}
          value={label}
        />
      </FlexLayout>
      <FlexLayout spacing={8} direction='column'>
        <Body size='small' weight='emphasis'>
          {validCryptoAddress === false
            ? t('withdrawCrypto.addAddress.cryptoAddressStatus.invalid')
            : t('withdrawCrypto.addAddress.cryptoAddressStatus.valid')}
          :<span className='text-color-text-error'>*</span>
        </Body>
        <Input
          className='fs-mask'
          onChange={(e) => {
            onUpdateFormData<AddCryptoAddressFormData>('address', e.target.value.trim());
          }}
          onBlur={(e) => onUpdateFormData<AddCryptoAddressFormData>('address', e.target.value.trim())}
          error={validCryptoAddress === false}
          placeholder='Enter address'
          value={address}
        />
        {!validCryptoAddress && errorMessage && (
          <Body size='small' weight='emphasis' color='error'>
            {errorMessage}
          </Body>
        )}
        {hasClipboardPermission === 'granted' && (
          <span>
            <Button
              variant='outlined'
              size='sm'
              leadingIcon={<Paste className='h-20 w-20 text-color-text-primary' />}
              onClick={updateAddressFromClipboard}
            >
              {t('withdrawCrypto.paste')}
            </Button>
          </span>
        )}
      </FlexLayout>
      {singleNetworkFound && (
        <Notification
          title={t('withdrawCrypto.addAddress.singleNetworkFound.title', {
            networkName: filteredCryptoNetworks[0].networkName,
            networkNameFull: filteredCryptoNetworks[0].networkNameFull,
          })}
          severity='info'
        >
          {t('withdrawCrypto.addAddress.singleNetworkFound.notification')}
        </Notification>
      )}
      {showEthWarning && (
        <Notification severity='warning' title={t('withdrawCrypto.addAddress.showEthWarning.title')}>
          {t('withdrawCrypto.addAddress.showEthWarning.notification')}
        </Notification>
      )}
      {filteredCryptoNetworks.length > 0 && asset.code === 'KDA' && (
        <Notification severity='warning' title='Kadena (KDA) has limited functionality'>
          You must use Chain 2 when withdrawing KDA from Swyftx.
        </Notification>
      )}
      {multipleNetworksFound && (
        <FlexLayout spacing={16} direction='column'>
          <Notification title={t('withdrawCrypto.addAddress.multipleNetworksFound.title')} severity='info'>
            {t('withdrawCrypto.addAddress.multipleNetworksFound.notification')}
          </Notification>
          <FlexLayout spacing={8} direction='column'>
            <Body size='small' weight='emphasis'>
              {t('withdrawCrypto.addAddress.multipleNetworksFound.selectNetworkTitle')}
              <span className='text-color-text-error'>*</span>
            </Body>
            <OverlayScrollbarsComponent
              options={{
                scrollbars: { visibility: 'visible', autoHide: 'leave', autoHideDelay: 400, dragScroll: true },
              }}
            >
              <FlexLayout direction='row' alignItems='center' spacing={8} className='p-4'>
                <EnhancedTabs
                  tabs={filteredCryptoNetworks.map((network) => ({
                    title: getNetworkName(network),
                    value: network.id.toString(),
                  }))}
                  variant='child'
                  value={selectedNetwork?.id.toString()}
                  onChange={(val) => onUpdateFormData<AddCryptoAddressFormData>('selectedCryptoNetworkId', Number(val))}
                />
              </FlexLayout>
            </OverlayScrollbarsComponent>
          </FlexLayout>
        </FlexLayout>
      )}
      {selectedNetwork && disabledSelectedNetwork && (
        <Notification
          title={t('withdrawCrypto.addAddress.disabledSelectedNetwork.title', {
            networkName: selectedNetwork.networkName,
            networkNameFull: selectedNetwork.networkNameFull,
            assetName: asset.code,
          })}
          severity='destructive'
        >
          {t('withdrawCrypto.addAddress.disabledSelectedNetwork.notification')}
        </Notification>
      )}
      {cryptoAddressMetadata && (
        <FlexLayout spacing={8} direction='column'>
          <Body size='small' weight='emphasis'>
            {validMemo === false
              ? t('withdrawCrypto.addAddress.cryptoAddressMetadata.memoValidation.invalid', { memoTitle })
              : t('withdrawCrypto.addAddress.cryptoAddressMetadata.memoValidation.valid', { memoTitle })}
            {isMemoRequired && <span className='text-color-text-error'>*</span>}
          </Body>
          <Input
            value={metaDataPayload?.[cryptoAddressMetadata.key]}
            onChange={(e) =>
              onUpdateFormData<AddCryptoAddressFormData>(
                'metaDataPayload',
                cryptoAddressMetadata ? { [cryptoAddressMetadata.key]: e.target.value.trim() } : undefined,
              )
            }
            onBlur={(e) =>
              onUpdateFormData<AddCryptoAddressFormData>(
                'metaDataPayload',
                cryptoAddressMetadata ? { [cryptoAddressMetadata.key]: e.target.value.trim() } : undefined,
              )
            }
            placeholder={`Enter ${memoTitle.toLowerCase()}`}
          />
          {hasClipboardPermission === 'granted' && (
            <span>
              <Button
                variant='outlined'
                size='sm'
                leadingIcon={<Paste className='h-20 w-20 text-color-text-primary' />}
                onClick={updateMemoFromClipboard}
              >
                {t('withdrawCrypto.paste')}
              </Button>
            </span>
          )}
        </FlexLayout>
      )}
      <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
        <Button variant='outlined' size='lg' onClick={() => onBack()}>
          Cancel
        </Button>
        <Button size='lg' onClick={onAddAddress} disabled={addAddressDisabled} loading={addingAdddress}>
          Add address
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

export { AddNewAddressCryptoFields };

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Wizard from '@global-components/Wizard';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { UIStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';
import { LoginContext } from '@routes/Login';
import AuthenticationService from '@services/AuthenticationService';

import { EnableTwoFactorCancelStep } from './steps/EnableTwoFactorCancelStep';
import { EnableTwoFactorConfirmStep } from './steps/EnableTwoFactorConfirmStep';
import { EnableTwoFactorRecoveryStep } from './steps/EnableTwoFactorRecoveryStep';
import { EnableTwoFactorVerifyStep } from './steps/EnableTwoFactorVerifyStep';

type Props = {
  onClose: () => void;
};

enum Steps {
  Confirm = 0,
  Verify = 1,
  Recovery = 2,
  Cancel = 3,
}

const EnableTwoFactorWizard: React.FC<Props> = ({ onClose }) => {
  const [recoveryCode, setRecoveryCode] = useState('');
  const { addMessageBox } = UIStore.useUIStore;
  const { setTwoFactorEnableInProgress } = useContext(LoginContext);
  const { t } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });
  const logout = useLogout();

  const [stepIndex, setStepIndex] = useState(0);

  const goToVerify = () => {
    setStepIndex(Steps.Verify);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const gotToRecovery = () => {
    setStepIndex(Steps.Recovery);
  };

  const goToCancel = () => {
    setStepIndex(Steps.Cancel);
  };

  const onCancel = async () => {
    await AuthenticationService.CancelMFA();
    onClose();
    addMessageBox({
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      content: <SuccessMessageBox title={t('enable.finish.cancel')} />,
    });
  };

  const onSuccess = () => {
    onClose();
    if (recoveryCode) {
      addMessageBox({
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        content: <SuccessMessageBox title={t('enable.finish.logout')} />,
      });
      setTwoFactorEnableInProgress(true);
      setTimeout(() => {
        logout();
      }, 5000);
    }
  };

  // Grab a different close handler depending on our wizard state
  const containerClose = (() => {
    if (stepIndex === Steps.Confirm) {
      return () => {
        onClose();
      };
    }

    return goToCancel;
  })();

  return (
    <Wizard.Container open onClose={containerClose} stepIndex={stepIndex}>
      <EnableTwoFactorConfirmStep onClose={onClose} onAction={goToVerify} />
      <EnableTwoFactorVerifyStep onClose={logout} onAction={logout} setRecoveryCode={setRecoveryCode} />
      <EnableTwoFactorRecoveryStep onClose={goToCancel} onAction={onSuccess} recoveryCode={recoveryCode} />
      <EnableTwoFactorCancelStep onContinue={goToVerify} onCancel={onCancel} />
    </Wizard.Container>
  );
};

export { EnableTwoFactorWizard };

import React, { useContext } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';
import { useFilterTourGuides } from '@swyftx/aviary/molecules/TourGuide/useFilterTourGuides';

import { useDashboardLayoutTour } from 'src/lib/feature-discovery/tours/dashboard/dashboardLayout.tour';
import { useDemoModeTour } from 'src/lib/feature-discovery/tours/profile/personalisation/demoMode.tour';
import { useThemeTour } from 'src/lib/feature-discovery/tours/profile/personalisation/theme.tour';

type Props = {
  search?: string;
};

const CustomisationTutorials: React.FC<Props> = ({ search }) => {
  const { startTour } = useContext(TourGuideContext);
  const dashboardLayout = useDashboardLayoutTour();
  const demoMode = useDemoModeTour();
  const theme = useThemeTour();

  const tours = useFilterTourGuides({ guides: [dashboardLayout, demoMode, theme], search });

  if (!tours.length) return null;

  return (
    <ElementContainer title='Customisation' className='w-full'>
      <Card className='w-full p-8'>
        <FlexLayout direction='column' spacing={8}>
          {tours.map((tour) => (
            <ListItem onClick={() => startTour(tour)} key={tour.name}>
              <FlexLayout alignItems='center' spacing={16}>
                {tour.icon}
                <FlexLayout direction='column' alignItems='start'>
                  <Body size='large' weight='bold'>
                    {tour.name}
                  </Body>
                  <Body color='secondary' className='text-start'>
                    {tour.description}
                  </Body>
                </FlexLayout>
              </FlexLayout>
            </ListItem>
          ))}
        </FlexLayout>
      </Card>
    </ElementContainer>
  );
};

export { CustomisationTutorials };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTable } from '@swyftx/aviary/molecules/EnhancedTable';

import { PriceAlert } from '@shared/api/@types/alerts';

import { observer } from 'mobx-react-lite';

import { NoAlerts } from './NoAlerts';
import { PriceAlertsTableData, usePriceAlertsTable } from './usePriceAlertsTable';
import { PriceAlertStatus } from '../../PriceAlerts.types';

type Props = {
  alerts: PriceAlert[];
  alertsStatus: PriceAlertStatus;
  onDelete: (uuid: string) => void;
};

export const PriceAlertList: React.FC<Props> = observer(({ alerts, onDelete, alertsStatus }) => {
  const { headers, data, sort, onSort } = usePriceAlertsTable(alerts, alertsStatus, onDelete);

  return (
    <FlexLayout className='max-h-[50vh] overflow-y-auto sm:max-h-[30rem]' direction='column'>
      <EnhancedTable<PriceAlertsTableData>
        data-container='price-alerts-table'
        headers={headers}
        data={data}
        sort={sort}
        onSort={onSort}
      >
        <NoAlerts tab={alertsStatus} />
      </EnhancedTable>
    </FlexLayout>
  );
});

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const AssetBacked: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3203 7.16C12.3203 4.64698 14.3579 2.60938 16.8709 2.60938C19.3831 2.60938 21.4205 4.6471 21.4205 7.16C21.4205 9.67312 19.3828 11.7097 16.8709 11.7097C14.3581 11.7097 12.3203 9.67325 12.3203 7.16ZM16.8709 4.10938C15.1863 4.10938 13.8203 5.47539 13.8203 7.16C13.8203 8.84436 15.1861 10.2097 16.8709 10.2097C18.5546 10.2097 19.9205 8.84449 19.9205 7.16C19.9205 5.47527 18.5544 4.10938 16.8709 4.10938Z'
      fill='currentColor'
    />
    <path
      d='M6.57981 4.22461C5.7678 4.22461 5.10938 4.88249 5.10938 5.69505V7.11207L5.18732 7.03412C5.48021 6.74123 5.95509 6.74123 6.24798 7.03412C6.54087 7.32702 6.54087 7.80189 6.24798 8.09478L4.88964 9.45312C4.59682 9.74594 4.12209 9.74603 3.82917 9.45331L2.46986 8.09497C2.17686 7.80218 2.17669 7.32731 2.46948 7.03431C2.76227 6.74131 3.23714 6.74115 3.53014 7.03393L3.60938 7.11311V5.69505C3.60938 4.05365 4.93979 2.72461 6.57981 2.72461H7.7562C8.17041 2.72461 8.5062 3.0604 8.5062 3.47461C8.5062 3.88882 8.17041 4.22461 7.7562 4.22461H6.57981Z'
      fill='currentColor'
    />
    <path
      d='M19.0917 14.6025C19.2286 14.4565 19.4232 14.3652 19.6391 14.3652C19.831 14.3652 20.0233 14.4384 20.1698 14.5847L21.5291 15.9431C21.8221 16.2359 21.8223 16.7108 21.5295 17.0038C21.2367 17.2968 20.7618 17.2969 20.4688 17.0041L20.3891 16.9245V18.3418C20.3891 19.9833 19.0585 21.3122 17.4186 21.3122H16.2422C15.828 21.3122 15.4922 20.9764 15.4922 20.5622C15.4922 20.148 15.828 19.8122 16.2422 19.8122H17.4186C18.2306 19.8122 18.8891 19.1544 18.8891 18.3418V16.9265L18.8116 17.004C18.5187 17.2969 18.0438 17.2969 17.7509 17.004C17.458 16.7111 17.458 16.2362 17.7509 15.9433L19.0917 14.6025Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.49609 12.4941C5.08188 12.4941 4.74609 12.8299 4.74609 13.2441C4.74609 13.588 4.97745 13.8777 5.29297 13.9663V18.9812C4.97545 19.0684 4.74219 19.3592 4.74219 19.7044C4.74219 20.1186 5.07797 20.4544 5.49219 20.4544H6.00555C6.01794 20.455 6.03042 20.4553 6.04297 20.4553C6.05552 20.4553 6.06799 20.455 6.08039 20.4544H6.15625V20.641C6.15625 21.0552 6.49204 21.391 6.90625 21.391C7.32046 21.391 7.65625 21.0552 7.65625 20.641V20.4544H8.63605V20.641C8.63605 21.0552 8.97184 21.391 9.38605 21.391C9.80026 21.391 10.136 21.0552 10.136 20.641V20.387C11.253 20.1236 12.0845 19.1202 12.0845 17.923C12.0845 17.1339 11.7239 16.4288 11.158 15.9643C11.4126 15.6051 11.5621 15.1662 11.5621 14.6928C11.5621 13.7509 10.9696 12.9465 10.136 12.6337V12.3086C10.136 11.8944 9.80026 11.5586 9.38605 11.5586C8.97184 11.5586 8.63605 11.8944 8.63605 12.3086V12.4941H7.65625V12.3086C7.65625 11.8944 7.32046 11.5586 6.90625 11.5586C6.49204 11.5586 6.15625 11.8944 6.15625 12.3086V12.4941H5.49609ZM6.88302 13.9941H6.79297V15.3925H9.19796C9.21581 15.3913 9.23382 15.3906 9.25199 15.3906H9.41747C9.778 15.3632 10.0621 15.0619 10.0621 14.6928C10.0621 14.3145 9.76162 14.0063 9.38587 13.9945C9.37816 13.9945 9.37048 13.9944 9.36282 13.9941H6.92948C6.92176 13.9944 6.91402 13.9945 6.90625 13.9945C6.89848 13.9945 6.89074 13.9944 6.88302 13.9941ZM6.79297 16.8925V18.9544H9.55316C10.1225 18.9544 10.5845 18.4924 10.5845 17.923C10.5845 17.3521 10.123 16.8906 9.55316 16.8906H9.45887C9.43641 16.8916 9.41385 16.8922 9.39121 16.8925L9.38194 16.8926L6.79297 16.8925Z'
      fill='currentColor'
    />
  </svg>
);

export { AssetBacked };

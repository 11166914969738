import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { useWithdrawFunds } from '@global-components/WithdrawSendDetails/WithdrawSendDetails.utils';

import { Asset } from '@shared/api';
import { WithdrawalAddress, WithdrawalReasonEnum } from '@shared/services';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { TransferModalStep } from '../TransferModal.types';

type Props = {
  selectedAsset?: Asset;
  withdrawToken: string;
  withdrawAmount: string;
  withdrawReason?: WithdrawalReasonEnum;
  withdrawAddress?: WithdrawalAddress;
  setWithdrawToken: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
};

const INITIAL_CODE_TIMEOUT = 60;
const MAX_ATTEMPTS = 3;

const PhoneVerificationWithdrawStep: React.FC<Props> = observer(
  ({ onClose, selectedAsset, withdrawAmount, withdrawReason, withdrawAddress, withdrawToken, setWithdrawToken }) => {
    const [code, setCode] = useState<string>();
    const [attempts, setAttempts] = useState(0);

    const { withdrawFunds } = useWithdrawFunds(selectedAsset!, withdrawAmount, withdrawReason, withdrawAddress);
    const { t: commonT } = useTranslation('common');
    const { onBack, onNextStep } = useContext(StepModalContext);
    const { getMaskedPhoneNumber } = UserStore.useUserStore;

    const doWithdrawal = useCallback(async () => {
      try {
        const auth = withdrawToken && code ? `${withdrawToken} ${code}` : undefined;
        await withdrawFunds(auth);
        onClose();
      } catch {
        setAttempts((prev) => prev + 1);
      }
    }, [code, onClose, withdrawFunds, withdrawToken]);

    const onResendCode = useCallback(async () => {
      setAttempts(0);
      setCode('');
      const response = await withdrawFunds();
      if (response.smsVerificationRequired && response.token) {
        setWithdrawToken(response.token);
        onNextStep(TransferModalStep.PhoneVerificationWithdraw, false);
      } else {
        onClose();
      }
    }, [onClose, onNextStep, setWithdrawToken, withdrawFunds]);

    const rateLimit = useMemo(() => attempts >= MAX_ATTEMPTS, [attempts]);

    return (
      <StepModal.Step
        onClose={onClose}
        hideActions
        hideBack
        title={commonT('phoneVerification.withdrawTitle')}
        key={TransferModalStep.PhoneVerification}
      >
        <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
          <Body color='secondary'>{commonT('phoneVerification.codeSentTo', { phone: getMaskedPhoneNumber() })}</Body>
          <Body color='secondary'>{commonT('phoneVerification.shareCode')}</Body>
          <FlexLayout spacing={8} direction='column'>
            <Body size='small' weight='emphasis'>
              {commonT('phoneVerification.smsCode')}:<span className='text-color-text-error'>*</span>
            </Body>
            <Input
              className='fs-mask'
              onChange={(e) => setCode(e.target.value)}
              value={code}
              error={rateLimit}
              disabled={rateLimit}
              placeholder={commonT('phoneVerification.enterCode')}
            />
            {rateLimit && (
              <Body color='error' size='small'>
                {commonT('verification.misc.maxAttemptsReached')}
              </Body>
            )}

            <span>
              <CountdownDelay
                id='mobile-verification-resend-countdown'
                delay={INITIAL_CODE_TIMEOUT}
                delayText={commonT('verification.misc.resendCodeIn')}
                restartText={commonT('verification.misc.resendCode')}
                onTimerRestart={onResendCode}
              />
            </span>
          </FlexLayout>

          <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
            <Button size='lg' variant='outlined' onClick={() => onBack()}>
              {commonT('labels.cancel')}
            </Button>
            <Button size='lg' onClick={doWithdrawal} disabled={!code?.length}>
              {commonT('labels.continue')}
            </Button>
          </FlexLayout>
        </FlexLayout>
      </StepModal.Step>
    );
  },
);

export { PhoneVerificationWithdrawStep };

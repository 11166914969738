import { useCallback, useContext, useMemo, useState } from 'react';

import { TakeProfitAmount, TakeProfitPercentage } from '@swyftx/aviary/icons/outlined';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { CheckedState } from '@radix-ui/react-checkbox';
import { SwyftxProTradeContext } from 'src/lib/trade-pro/context';
import { MarketOcoTab } from 'src/lib/trade-pro/types/TradePro.types';

const useSwyftxProMarketTakeProfit = () => {
  const [selectedTab, setSelectedTab] = useState<MarketOcoTab>('price');
  const { total, baseAsset, setTakeProfitPrice, showMarketTakeProfit, setShowMarketTakeProfit } =
    useContext(SwyftxProTradeContext);
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;

  const handleChecked = useCallback(
    (checkedState?: CheckedState) => {
      const val = checkedState !== 'indeterminate' ? checkedState : !showMarketTakeProfit;
      setShowMarketTakeProfit(val);
      if (!val) setTakeProfitPrice('');
    },
    [setShowMarketTakeProfit, setTakeProfitPrice, showMarketTakeProfit],
  );

  const tabs = useMemo(
    (): EnhancedTab<MarketOcoTab>[] => [
      { title: 'Price', value: 'price', tooltip: 'Set a price to trigger order' },
      {
        title: 'Percent',
        value: 'percent',
        icon: <TakeProfitPercentage className='h-12 w-12' />,
        tooltip: 'Set a target profit percentage',
      },
      {
        title: 'Total',
        value: 'total',
        icon: <TakeProfitAmount className='h-12 w-12' />,
        tooltip: 'Set a maximum loss percentage',
      },
    ],
    [],
  );

  const disabled = useMemo(
    () => (baseAsset ? Big(total).lt(getMinimumOrderAmount(baseAsset)) : false),
    [baseAsset, getMinimumOrderAmount, total],
  );

  return {
    tabs,
    selectedTab,
    setSelectedTab,
    showMarketTakeProfit,
    handleChecked,
    disabled,
  };
};

export { useSwyftxProMarketTakeProfit };

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsInfoItem } from '@swyftx/aviary/molecules/SettingsItem/SettingsInfoItem';

import { Links } from '@shared/constants';

export const TaxLearnCard: React.FC = () => {
  const { t } = useTranslation('profile.taxreports', { keyPrefix: 'taxLearnCards' });

  const openTaxGuide = useCallback(() => {
    window.open(Links.blog.taxGuide, '_blank', 'noopener,noreferrer');
  }, []);

  const openUnderstandingTax = useCallback(() => {
    window.open(Links.help.understandingTaxReport, '_blank', 'noopener,noreferrer');
  }, []);

  return (
    <SettingsCluster
      title={t('title')}
      items={[
        <SettingsInfoItem
          key='taxGuide'
          title={t('labels.taxGuideTitle')}
          description={t('labels.taxGuideContent')}
          infoAction={
            <Button onClick={openTaxGuide} variant='outlined' className='max-w-[15rem]'>
              {t('labels.taxGuideLink')}
            </Button>
          }
        />,
        <SettingsInfoItem
          key='understandingTax'
          title={t('labels.understandingTaxTitle')}
          description={t('labels.understandingTaxContent')}
          infoAction={
            <Button onClick={openUnderstandingTax} variant='outlined' className='max-w-[15rem]'>
              {t('labels.understandingTaxLink')}
            </Button>
          }
        />,
      ]}
    />
  );
};

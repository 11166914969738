import React from 'react';

import { useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { Divider } from '@swyftx/react-web-design-system';

type Props = {
  containerPadding?: number;
  loading?: boolean;
  dividerColor?: string;
  placement?: 'top' | 'bottom';
};

export const ContentDivider: React.FC<Props> = ({ containerPadding = 0, loading = false, dividerColor, placement }) => {
  const theme = useTheme();

  const placementStyles = (() => {
    if (placement) {
      if (placement === 'top') {
        return { top: 0 };
      }
      return { bottom: 0 };
    }
  })();

  return loading ? (
    <LinearProgress
      variant='indeterminate'
      sx={{
        backgroundColor:
          dividerColor || theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        width: `calc(100% + ${theme.spacing(containerPadding * 2)})`,
        marginLeft: -theme.spacing(containerPadding),
        position: 'absolute',
        height: '1px',
        ...placementStyles,
      }}
    />
  ) : (
    <Divider
      sx={{
        backgroundColor:
          dividerColor || theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        width: `calc(100% + ${theme.spacing(containerPadding * 2)})`,
        marginLeft: `-${theme.spacing(containerPadding)} !important`,
        position: 'absolute',
        ...placementStyles,
      }}
    />
  );
};

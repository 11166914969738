import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Notification } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';

const codesToIgnore = ['USDC'];

const useInvalidAddressMessage = (address: string, asset: Asset) => {
  const { t } = useTranslation('wallet');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [notification, setNotification] = useState<React.ReactNode>(null);

  useEffect(() => {
    const ronNetwork = asset.networks.find((network) => network.networkName === 'RON');

    if (ronNetwork && !ronNetwork.withdrawDisabled && !ronNetwork.withdrawDisableForce) {
      if (address.includes('ronin:')) {
        setErrorMessage(t('withdrawCrypto.addAddress.invalidAddressMessage.ronin.errorMessage'));
      }

      if (!codesToIgnore.includes(asset.code)) {
        setNotification(
          <Notification
            severity='warning'
            title={t('withdrawCrypto.addAddress.invalidAddressMessage.ronin.notificationTitle')}
          >
            {t('withdrawCrypto.addAddress.invalidAddressMessage.ronin.notificationContent')}
          </Notification>,
        );
      }
    } else {
      setErrorMessage('');
      setNotification(null);
    }
  }, [address, asset, t]);

  return {
    errorMessage,
    notification,
  };
};

export { useInvalidAddressMessage };

import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Callout } from '@swyftx/aviary/atoms/Typography/Heading/Callout';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { AppStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AutoInvestOrders, AutoInvestLearnCards } from 'src/lib/auto-invest/components';
import { useAutoInvestAnalytics } from 'src/lib/auto-invest/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTradeStatus } from 'src/lib/trade/hooks/General';

const AutoInvestLandingPage: React.FC = observer(() => {
  const { isDemo } = AppStore.useAppStore;
  const { isVerified, isRestricted, hasPermission } = useTradeStatus('auto-invest');
  const countryAsset = useCountryAsset();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { createAutoInvestClicked } = useAutoInvestAnalytics();

  const navigate = useNavigate();

  const { atLeast } = useTailwindContainerBreakpoint({
    containerName: 'autoinvest-page',
  });

  const onCreateOrderClick = useCallback(() => {
    createAutoInvestClicked('landing');
    navigate(NavigationURLs.AutoInvestCreate);
  }, [createAutoInvestClicked, navigate]);

  const disabledTooltip = useMemo(() => {
    if (isDemo) {
      return 'Creating Auto Invest orders not available in demo mode';
    } else {
      if (!isVerified) return 'Accounts must be verified to create orders';
      if (isRestricted) return 'Unable to create orders while in a restricted state';
      if (!hasPermission) return 'You do not have sufficient permissions to perform this action';
      if (countryAsset?.code === 'NZD' && !isFeatureEnabled(AppFeature.AutoInvestFromBalances))
        return 'NZ accounts are currently unable to create orders';
    }
    return '';
  }, [countryAsset?.code, hasPermission, isDemo, isFeatureEnabled, isRestricted, isVerified]);

  return (
    <>
      <Page
        data-container='autoinvest-page'
        showCompactHeader={false}
        data-spotlightelementid='autoInvest-page'
        titleSpacing={0}
        className={cn('@container')}
        offset={false}
        customHeader={
          <FlexLayout justifyContent='space-between' className='flex-col gap-16 @sm:flex-row'>
            <FlexLayout direction='column' className='order-2 gap-8 @sm:order-1 @sm:w-[50%] @sm:gap-16'>
              <FlexLayout className='inline flex-wrap'>
                <Heading className='inline leading-normal' size={atLeast.lg ? 'h2' : 'h3'}>
                  Optimise your investing with{' '}
                </Heading>
                <Heading className='inline leading-normal' size={atLeast.lg ? 'h2' : 'h3'} color='accent'>
                  <Callout>automated orders</Callout>
                </Heading>
                <Heading className='inline leading-normal' size={atLeast.lg ? 'h2' : 'h3'}>
                  .
                </Heading>
              </FlexLayout>

              <Body color='secondary'>
                Create orders that execute on a routine schedule and automate the purchase process for multiple
                cryptocurrencies at once. Automated orders may help to avoid trying to &apos;time the market&apos;,
                reduce emotional trading habits, and spread out your investments.
              </Body>
              <div className='hidden sm:block'>
                <Button
                  tooltip={disabledTooltip}
                  onClick={onCreateOrderClick}
                  disabled={!!disabledTooltip.length}
                  size='lg'
                >
                  Create an order
                </Button>
              </div>
            </FlexLayout>
            <div className='order-1 @sm:order-2'>
              <Image image='auto_invest_header' usePalette={false} className='h-[128px] @md:h-[152px] @xl:h-[192px]' />
            </div>
          </FlexLayout>
        }
      >
        <PageContent className='pb-[6rem]'>
          <FlexLayout direction='column' spacing={24}>
            <AutoInvestOrders isDemo={isDemo} disabledTooltip={disabledTooltip} />
            <AutoInvestLearnCards atLeast={atLeast} />
          </FlexLayout>
        </PageContent>
      </Page>
      <div className='fixed bottom-0 left-0 z-page-footer h-[5rem] w-[calc(100%-6px)] bg-color-background-pageBG sm:hidden'>
        <FlexLayout alignItems='center' className='h-full w-full px-16' justifyContent='center'>
          <Button
            tooltip={disabledTooltip}
            onClick={onCreateOrderClick}
            disabled={!!disabledTooltip.length}
            size='lg'
            className='w-full'
          >
            Create an order
          </Button>
        </FlexLayout>
      </div>
    </>
  );
});

export default AutoInvestLandingPage;

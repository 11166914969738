import { useCallback } from 'react';

import { api } from '@shared/api';
import { UIStore } from '@shared/store';

import { useFetchDynamicPriceAlertAssetCountsCache } from './useFetchDynamicPriceAlertAssetCounts/useFetchDynamicPriceAlertAssetCountsCache';
import { useFetchDynamicPriceAlertsCache } from './useFetchDynamicPriceAlerts';

const useDeleteAllDynamicPriceAlerts = () => {
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();
  const { invalidateCache: invalidateCountsCache } = useFetchDynamicPriceAlertAssetCountsCache();

  const { addToastMessage } = UIStore.useUIStore;

  const deleteAllPriceAlerts = useCallback(
    async (secondary?: number) => {
      try {
        const response = await api.endpoints.deleteAlerts({ data: { all: true, secondary } });

        invalidateCache();
        invalidateCountsCache();

        addToastMessage({ severity: 'success', message: 'All active price alerts deleted' });

        return response.data;
      } catch (e) {
        addToastMessage({ severity: 'error', message: 'Failed to delete all active price alerts' });
      }
    },
    [addToastMessage, invalidateCache, invalidateCountsCache],
  );

  const deleteAllTriggerPriceAlerts = useCallback(
    async (secondary?: number) => {
      try {
        const response = await api.endpoints.deleteTriggerAlerts({ data: { all: true, secondary } });

        invalidateCache();
        invalidateCountsCache();

        addToastMessage({ severity: 'success', message: 'All triggered price alerts deleted' });

        return response.data;
      } catch (e) {
        addToastMessage({ severity: 'error', message: 'Failed to delete all triggered price alerts' });
      }
    },
    [addToastMessage, invalidateCache, invalidateCountsCache],
  );

  return { deleteAllPriceAlerts, deleteAllTriggerPriceAlerts };
};

export { useDeleteAllDynamicPriceAlerts };

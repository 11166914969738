import React, { useState } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Tick } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { IdentityVerificationModal } from '@routes/Profile/subroutes/Verification/components';

interface CompleteProfileStepProps {
  completed: boolean;
  actionUnlocked: boolean;
}

export const VerifyIdStep: React.FC<CompleteProfileStepProps> = (props) => {
  const { completed, actionUnlocked } = props;
  const [identityVerificationModalOpen, setIdentityVerificationModalOpen] = useState<boolean>(false);
  const notYetUnlockedOrCompleted = !actionUnlocked && !completed;

  return (
    <>
      <FlexLayout direction='row' className='mx-8 w-full @md:w-1/3' spacing={24}>
        <Image image='identify' fileType='svg' className='w-60' />
        <FlexLayout direction='column' spacing={8}>
          <Avatar
            className={cn(
              notYetUnlockedOrCompleted ? 'border border-color-text-primary bg-color-background-surface-primary' : '',
            )}
          >
            {completed ? (
              <Tick className='h-14 w-14 text-color-text-inverse' />
            ) : (
              <Body color={notYetUnlockedOrCompleted ? 'primary' : 'inverse'} size='small'>
                2
              </Body>
            )}
          </Avatar>
          <Heading size='h6' color={completed ? 'secondary' : 'primary'}>
            Verify your ID
          </Heading>
          <Body color='secondary' size='xsmall'>
            Complete ID verification with a quick photo of your ID and a selfie. This is a legal requirement for all
            entity applicants.
          </Body>
          {actionUnlocked && !completed && (
            <FlexLayout direction='row' className='mt-4'>
              <Button onClick={() => setIdentityVerificationModalOpen(true)}>Verify</Button>
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
      {identityVerificationModalOpen && (
        <IdentityVerificationModal onClose={() => setIdentityVerificationModalOpen(false)} />
      )}
    </>
  );
};

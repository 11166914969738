import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Scan } from '@swyftx/aviary/icons/outlined';

import { TransactionStatus } from '@shared/api/@types/verification';
import { UserStore } from '@shared/store';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';

import { observer } from 'mobx-react-lite';

import { JumioStatus, useJumioStatus } from './useJumioStatus';

const JumioStatusCard: React.FC = observer(() => {
  const { jumioTransaction } = useContext(ProfileVerificationContext);
  const { isKyc1Complete } = UserStore.useUserStore;

  const { t } = useTranslation('profile.verification');

  const {
    status,
    setStatus,
    getColor,
    buttonText,
    buttonColor,
    buttonOnClick,
    shouldShowButton,
    notificationTitle,
    notificationDescription,
    shouldShowNotification,
    notificationColor,
  } = useJumioStatus();

  useEffect(() => {
    (async () => {
      // Only recalculate the jumio status if Kyc1Complete is false
      if (jumioTransaction && !isKyc1Complete()) {
        const newStatus = jumioTransaction
          ? jumioTransaction.result?.status ?? jumioTransaction.status
          : TransactionStatus.NOT_STARTED;

        setStatus(newStatus as JumioStatus);
      }
    })();
  }, [isKyc1Complete, jumioTransaction, setStatus]);

  return (
    <Card>
      <FlexLayout direction='column' spacing={16}>
        <FlexLayout direction='row' className='w-full items-center justify-between p-16 pb-0'>
          <FlexLayout direction='row' spacing={8}>
            <Scan className='mr-4 h-20 w-20 text-color-text-primary' />
            <Body weight='emphasis'>{t('identityVerificationModal.labels.authenticateId')}</Body>
          </FlexLayout>
          <Chip size='sm' color={getColor()} variant='subtle'>
            {t(`identityVerificationModal.jumioStatus.${status}`)}
          </Chip>
        </FlexLayout>
        <Separator />
        <FlexLayout direction='column' spacing={16} className='p-16 pt-0'>
          <Body weight='emphasis'>{t('identityVerificationModal.labels.authenticatingDocuments')}</Body>
          <ul>
            <li>
              <Body color='secondary'>- {t('identityVerificationModal.labels.increaseTrust')}</Body>
            </li>
            <li>
              <Body color='secondary'>- {t('identityVerificationModal.labels.fasterDepositWithdraw')}</Body>
            </li>
          </ul>
          {shouldShowNotification && (
            <Notification severity={notificationColor} title={notificationTitle}>
              {notificationDescription}
            </Notification>
          )}
          {shouldShowButton && (
            <FlexLayout direction='row'>
              <Button color={buttonColor} onClick={buttonOnClick}>
                {buttonText}
              </Button>
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
});

export { JumioStatusCard };

import React, { useMemo } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { uuid } from '@shared/utils';

export const MobileVerificationDarkIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  const maskId = useMemo(() => uuid(), []);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none'>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d='M3.80933 38.6394L3.80925 38.6393L2.78122 38.0465C2.33803 37.7895 2.15 37.4735 2.15 37.1879C2.15 36.9038 2.33635 36.5966 2.7821 36.3288L10.876 31.6537L10.876 31.6536L19.9017 26.4433C20.4103 26.186 20.9723 26.052 21.5422 26.052C22.1122 26.052 22.6742 26.186 23.1827 26.4433L32.2084 31.6537L61.9913 48.8476C61.9916 48.8477 61.9919 48.8479 61.9922 48.8481C62.4359 49.1124 62.624 49.4308 62.624 49.7141C62.624 49.9982 62.4376 50.3054 61.9919 50.5731C61.9916 50.5734 61.9912 50.5736 61.9908 50.5738L60.967 51.1655L60.967 51.1655L44.8699 60.4586C44.3613 60.7159 43.7994 60.85 43.2294 60.85C42.6594 60.85 42.0975 60.7159 41.5889 60.4586L3.80933 38.6394Z'
            fill='#13151B'
          />
          <path
            d='M3.80933 38.6394L3.80925 38.6393L2.78122 38.0465C2.33803 37.7895 2.15 37.4735 2.15 37.1879C2.15 36.9038 2.33635 36.5966 2.7821 36.3288L10.876 31.6537L10.876 31.6536L19.9017 26.4433C20.4103 26.186 20.9723 26.052 21.5422 26.052C22.1122 26.052 22.6742 26.186 23.1827 26.4433L32.2084 31.6537L61.9913 48.8476C61.9916 48.8477 61.9919 48.8479 61.9922 48.8481C62.4359 49.1124 62.624 49.4308 62.624 49.7141C62.624 49.9982 62.4376 50.3054 61.9919 50.5731C61.9916 50.5734 61.9912 50.5736 61.9908 50.5738L60.967 51.1655L60.967 51.1655L44.8699 60.4586C44.3613 60.7159 43.7994 60.85 43.2294 60.85C42.6594 60.85 42.0975 60.7159 41.5889 60.4586L3.80933 38.6394Z'
            stroke='#13151B'
            strokeWidth='0.3'
          />
        </g>
        <path
          d='M62.7738 39.8526V43.0153C62.7738 43.373 62.5385 43.7213 62.0679 44.006L44.9412 53.8895C44.4107 54.1587 43.8241 54.2989 43.2292 54.2989C42.6343 54.2989 42.0478 54.1587 41.5173 53.8895L2.70816 31.4823C2.23752 31.2093 2.0022 30.8516 2.0022 30.4939V27.3335C2.0022 26.9759 2.23752 26.6276 2.70816 26.3452L10.8032 21.6694H32.2856L62.0702 38.8643C62.5385 39.1372 62.7738 39.4949 62.7738 39.8526Z'
          fill='#303642'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M62.7739 38.805C62.7739 39.1627 62.5386 39.511 62.0679 39.7934L61.0419 40.3864L44.9413 49.6816C44.4107 49.9507 43.8242 50.091 43.2293 50.091C42.6344 50.091 42.0479 49.9507 41.5174 49.6816L3.73657 27.8673L2.70822 27.2743C2.23758 27.0013 2.00226 26.6436 2.00226 26.286C2.00226 25.9283 2.23758 25.58 2.70822 25.2976L10.8033 20.6218L19.8325 15.4094C20.3631 15.1403 20.9496 15 21.5445 15C22.1394 15 22.7259 15.1403 23.2564 15.4094L32.2857 20.6218L62.0703 37.8167C62.5386 38.0897 62.7739 38.4473 62.7739 38.805Z'
          fill='#4E5869'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M54.8701 35.7031L38.9828 44.877L10.3826 28.3653L26.2721 19.1913L54.8701 35.7031Z'
          fill='#80C0FF'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.0703 28.6348L26.5873 33.4949C26.1888 33.695 25.7485 33.7992 25.3019 33.7992C24.8553 33.7992 24.415 33.695 24.0166 33.4949L15.5312 28.6348C14.8229 28.228 14.8229 27.5699 15.5312 27.163L24.0143 22.3051C24.413 22.1045 24.8537 22 25.3008 22C25.7478 22 26.1886 22.1045 26.5873 22.3051L35.0703 27.163C35.7808 27.5699 35.7786 28.228 35.0703 28.6348Z'
          fill='#4E5869'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <mask id={maskId} style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='14' y='21' width='22' height='13'>
          <path
            d='M35.0703 28.6348L26.5873 33.4949C26.1888 33.695 25.7485 33.7992 25.3019 33.7992C24.8553 33.7992 24.415 33.695 24.0166 33.4949L15.5312 28.6348C14.8229 28.228 14.8229 27.5699 15.5312 27.163L24.0143 22.3051C24.413 22.1045 24.8537 22 25.3008 22C25.7478 22 26.1886 22.1045 26.5873 22.3051L35.0703 27.163C35.7808 27.5699 35.7786 28.228 35.0703 28.6348Z'
            fill='white'
            stroke='#22262E'
            strokeWidth='0.253127'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <path
            d='M31.3132 35.4649C35.1745 35.4649 38.3046 33.4941 38.3046 31.0629C38.3046 28.6318 35.1745 26.6609 31.3132 26.6609C27.452 26.6609 24.3218 28.6318 24.3218 31.0629C24.3218 33.4941 27.452 35.4649 31.3132 35.4649Z'
            fill='#3D93FA'
            stroke='#13151B'
            strokeWidth='0.253127'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M35.0703 28.6348L26.5873 33.4949C26.1888 33.695 25.7485 33.7992 25.3019 33.7992C24.8553 33.7992 24.415 33.695 24.0166 33.4949L15.5312 28.6348C14.8229 28.228 14.8229 27.5699 15.5312 27.163L24.0143 22.3051C24.413 22.1045 24.8537 22 25.3008 22C25.7478 22 26.1886 22.1045 26.5873 22.3051L35.0703 27.163C35.7808 27.5699 35.7786 28.228 35.0703 28.6348Z'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.0271 28.2146C24.6002 28.2146 25.8755 27.4031 25.8755 26.402C25.8755 25.4009 24.6002 24.5894 23.0271 24.5894C21.454 24.5894 20.1788 25.4009 20.1788 26.402C20.1788 27.4031 21.454 28.2146 23.0271 28.2146Z'
          fill='#3D93FA'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M33.9399 32.9321L34.8703 32.3934'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.8198 35.8877L32.7705 33.6078'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M33.6127 35.1944L40.617 31.1516'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.6179 36.9239L32.2672 35.972'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.7427 34.3071L40.7112 33.171'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.4141 37.962L37.4147 35.0749'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M39.5719 36.3125L40.5023 35.7738'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34.4518 39.2681L38.4025 36.9882'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M39.2448 38.5747L46.2492 34.5319'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M36.2499 40.3043L37.8991 39.3524'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M44.3747 37.6875L46.3432 36.5514'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.0461 41.3424L43.0467 38.4553'
          stroke='#13151B'
          strokeWidth='0.253127'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.4106 22.1653C13.963 22.1653 14.4107 21.9072 14.4107 21.5888C14.4107 21.2704 13.963 21.0123 13.4106 21.0123C12.8583 21.0123 12.4105 21.2704 12.4105 21.5888C12.4105 21.9072 12.8583 22.1653 13.4106 22.1653Z'
          fill='#80C0FF'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50.1635 43.5724C51.3007 43.5724 52.2225 43.0404 52.2225 42.3841C52.2225 41.7277 51.3007 41.1957 50.1635 41.1957C49.0263 41.1957 48.1044 41.7277 48.1044 42.3841C48.1044 43.0404 49.0263 43.5724 50.1635 43.5724Z'
          fill='#0072ED'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.1001 23.3915L18.5524 20.8218'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.0789 24.5586L12.9966 24.0292'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M51.9233 47.4319L50.6502 48.1661'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M6.88771 31.317L5.61462 30.5804'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M56.8156 44.6079L53.2552 46.6623'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M9.01084 32.3324C9.07232 32.4768 9.09166 32.6151 9.08001 32.7181C9.06778 32.8264 9.02801 32.8627 9.00694 32.8717C8.98588 32.8807 8.9321 32.8841 8.84564 32.8179C8.76331 32.7548 8.67712 32.645 8.61564 32.5005C8.55415 32.356 8.53481 32.2178 8.54645 32.1147C8.55867 32.0065 8.59843 31.9701 8.6195 31.9612C8.64057 31.9522 8.69436 31.9488 8.78083 32.015C8.86316 32.0781 8.94935 32.1879 9.01084 32.3324Z'
          fill='#004198'
          stroke='#13151B'
          strokeWidth='0.3'
        />
        <path
          d='M10.8162 33.3756C10.8776 33.5201 10.897 33.6583 10.8853 33.7614C10.8731 33.8696 10.8333 33.906 10.8123 33.915C10.7912 33.924 10.7374 33.9274 10.6509 33.8611C10.5686 33.7981 10.4824 33.6883 10.4209 33.5438C10.3595 33.3993 10.3401 33.2611 10.3518 33.158C10.364 33.0498 10.4038 33.0134 10.4248 33.0044C10.4459 32.9955 10.4997 32.992 10.5862 33.0583C10.6685 33.1213 10.7547 33.2311 10.8162 33.3756Z'
          fill='#004198'
          stroke='#13151B'
          strokeWidth='0.3'
        />
        <path
          d='M22.1506 15.5275L27.4606 18.5935C28.1774 18.377 28.9234 18.0494 29.6899 17.6076C32.1854 16.1653 34.4557 13.7604 36.1672 10.9752C37.3843 8.99751 39.2391 7.92674 40.4532 8.50016C41.3689 8.93022 42.4456 9.07065 43.6275 8.88634C44.6603 8.72543 45.772 8.32169 46.9364 7.6488C47.7029 7.20703 48.4489 6.67458 49.1628 6.06605L43.8528 3C43.139 3.60853 42.3929 4.14099 41.6264 4.58276C39.1308 6.02509 36.8547 6.24158 35.1433 5.43411C33.9291 4.86069 32.0742 5.93147 30.8572 7.90918C29.1457 10.6944 26.8725 13.0992 24.377 14.5416C23.6134 14.9833 22.8645 15.311 22.1506 15.5275Z'
          fill='#303642'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.0949 17.3503C22.0949 25.5479 26.9398 36.0187 38.3146 35.2463C35.4212 35.1088 32.9402 33.9649 31.0824 31.9286C28.7624 29.3951 27.405 25.4806 27.405 20.4163C27.405 19.8137 27.4254 19.2051 27.4605 18.5937L22.1505 15.5276C22.1154 16.1391 22.0949 16.7476 22.0949 17.3503Z'
          fill='#303642'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.4051 20.4161C27.4051 25.4803 28.7625 29.3948 31.0825 31.9283C32.9403 33.9646 35.4212 35.1085 38.3147 35.246L38.3498 35.2021C44.8241 27.3966 49.2184 16.9346 49.2184 7.82421C49.2184 7.22153 49.1979 6.63641 49.1628 6.06592C48.449 6.67445 47.703 7.2069 46.9365 7.64867C45.7721 8.32156 44.6603 8.7253 43.6276 8.88621C42.4456 9.07052 41.369 8.93009 40.4533 8.50003C39.2391 7.92661 37.3843 8.99738 36.1673 10.9751C34.4558 13.7603 32.1855 16.1651 29.6899 17.6075C28.9234 18.0492 28.1774 18.3769 27.4606 18.5934C27.4255 19.2049 27.4051 19.8134 27.4051 20.4161Z'
          fill='#4E5869'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.3117 31.8495C43.1623 25.4394 46.2518 17.6163 46.6409 10.7411C43.7123 12.3502 40.9184 12.675 38.7476 11.651C38.5925 11.5779 38.4228 11.6422 38.3087 11.7066C38.1946 11.771 38.0249 11.9055 37.8699 12.1571C35.6991 15.6884 32.908 18.5876 29.9794 20.3606C30.3685 26.7852 33.4581 31.042 38.3117 31.8495Z'
          fill='#80C0FF'
          stroke='#13151B'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M42.9552 16.4967C42.8656 16.2006 42.654 16.0249 42.3581 16.0024C42.0622 15.98 41.7189 16.1123 41.3896 16.3777C41.0499 16.6569 40.7852 17.0084 40.6173 17.4033L38.092 23.3177L36.2485 22.0235C36.0277 21.8684 35.7188 21.8652 35.3769 22.0139C35.0351 22.1627 34.7072 22.4459 34.4482 22.812C34.1891 23.1781 34.0312 23.5818 34.0041 23.9502C33.977 24.3186 34.084 24.6064 34.3048 24.762L37.325 26.8818C37.5671 27.052 37.9216 27.0378 38.2935 26.8429C38.3322 26.8227 38.3709 26.8008 38.4121 26.7756C38.8247 26.5198 39.1946 26.0791 39.4004 25.5967L42.8264 17.5712C43.0002 17.1749 43.0443 16.7928 42.9552 16.4967Z'
          fill='#0072ED'
        />
      </svg>
    </SvgIcon>
  );
};

import {
  formatCurrency as formatCurrencyText,
  FormatCurrencyOptions as SharedFormatCurrencyOptions,
} from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api/@types/markets';
import logger from '@shared/logger';
import { Big } from '@shared/safe-big';
import { assetService, ratesService } from '@shared/services';

import * as Sentry from '@sentry/react';

const LOG_TAG = 'FORMAT_CURRENCY';

export interface FormatCurrencyOptions {
  appendCode?: boolean;
  hideCode?: boolean;
  hideSeparator?: boolean;
  showTrailingZero?: boolean;
  separator?: string;
  priceScale?: number;
  minPriceScale?: number;
  noRoundingToFirstZeroDecimal?: boolean;
  noRounding?: boolean;
  hideSymbol?: boolean;
  displayPriceScale?: boolean;
  appendTrailingDecimals?: boolean;
  isApproximate?: boolean;
}

const SMALL_DECIMALS = 8;
const EXTRA_SMALL_DECIMALS = 12;
const MAX_TRADING_VIEW_DECIMALS = 12;

// To format the fractional side for tiny amount
const roundToFirstNonZeroDecimal = (fractionalSideNumber: string) => {
  try {
    const index = fractionalSideNumber.split('').findIndex((digit) => Number(digit) > 0);
    return fractionalSideNumber
      .split('')
      .slice(0, index + 1)
      .join('');
  } catch (ex) {
    return fractionalSideNumber;
  }
};

/** Remove tailing 0's on a number */
const removeTrailingZeros = (value: string) => {
  let newValue = value;
  const hasDecimal = value.includes('.');
  if (hasDecimal) {
    let endIndex = value.length - 1;
    while (value[endIndex] === '0') {
      endIndex -= 1;
    }
    // if last character isn't a number separator increase the index
    if (value[endIndex] !== '.') endIndex += 1;
    newValue = value.slice(0, endIndex);
  }
  return newValue;
};

// get a dynamic price scale based on the decimal the bid and ask price begin to differ
// I.e. for an ask price of 0.000000332 and bid price of 0.000000346, the dynamic price scale will be 8
export const getDynamicPriceScale = (
  assetID: number,
  priceScale: number,
  useRoundedCalculation = false,
  forTradingView = false,
) => {
  try {
    const rate = ratesService.getRate(assetID);

    if (!rate || !rate.bidPrice || !rate.askPrice) return priceScale;

    const bid = rate.bidPrice.split('.')[1].split('');
    const ask = rate.askPrice.split('.')[1].split('');

    // if we are a low price, calculate based on price
    if (useRoundedCalculation) {
      if (Big(rate.bidPrice).lte(0.0000001) && Big(rate.askPrice).lte(0.0000001)) {
        return forTradingView ? MAX_TRADING_VIEW_DECIMALS : EXTRA_SMALL_DECIMALS;
      }
      if (Big(rate.bidPrice).lte(0.0001) && Big(rate.askPrice).lte(0.0001)) {
        return SMALL_DECIMALS;
      }
    }

    for (let i = 0; i < bid.length; i += 1) {
      if (bid[i] !== ask[i]) {
        const dynamicPriceScale = i + 1;

        // make sure the dynamic price scale is greater than the current price scale
        if (priceScale >= dynamicPriceScale) {
          return priceScale;
        }

        return dynamicPriceScale;
      }
    }
  } catch (error) {
    logger.info(LOG_TAG, 'Unable to get dynamic price scale', error);
  }

  return priceScale;
};

/** Right now we are still formatting balances the same, once this has been refactored to the currency-util we can remove this as well */
const legacyFormatCurrency = (
  amount?: string | number | Big,
  asset?: Asset,
  opts?: FormatCurrencyOptions,
  dynamicPriceScaleAssetID?: number,
) => {
  const safeAmount = amount ?? '0';
  try {
    // The number of decimal places of the amount
    const actualPrecision = Big(safeAmount).c.length - Big(safeAmount).e - 1;

    // predict the small amount of asset based on the asset price scale
    const predictSmallestAmount = Big(`0.${new Array((asset?.price_scale || 2) - 1).fill(0).join('')}1`);

    // Apply the actual price scale if the position of first occurrence of non-zero digit exceed the price scale
    const smallAmountPriceScale = Big(safeAmount).abs().lt(predictSmallestAmount)
      ? actualPrecision
      : asset?.price_scale ?? 0;

    let priceScale = smallAmountPriceScale;

    if (opts?.displayPriceScale) {
      priceScale = assetService.getDisplayPriceScale(asset);
    } else if (opts?.priceScale) {
      priceScale = opts.priceScale;
    } else if (dynamicPriceScaleAssetID) {
      priceScale = getDynamicPriceScale(dynamicPriceScaleAssetID, opts?.minPriceScale ?? smallAmountPriceScale);
    }

    // if calculated price scale is less than the min price scale, use the min price scale
    if (opts?.minPriceScale && opts.minPriceScale > priceScale) {
      priceScale = opts.minPriceScale;
    }

    const formattedBig = opts?.noRounding ? Big(safeAmount) : Big(safeAmount).round(priceScale, 0);
    let formatted = '';

    if (opts?.displayPriceScale && asset?.assetType === AssetType.Fiat) {
      formatted = formattedBig.toFixed(assetService.getDisplayPriceScale(asset));
    } else {
      formatted = formattedBig.toFixed(priceScale);
    }

    // format number with commas
    if (!opts?.hideSeparator) {
      const [lhs, rhs] = formatted.split('.');
      const formattedLhs = lhs.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${opts?.separator ?? ','}`);
      const hasDecimal = !!rhs && Big(rhs).gt(0);
      if (!hasDecimal) {
        formatted = rhs && opts?.displayPriceScale ? `${formattedLhs}.${rhs}` : formattedLhs;
      } else {
        const formattedRhs =
          Big(amount).abs().lt(predictSmallestAmount) && !opts?.noRoundingToFirstZeroDecimal
            ? roundToFirstNonZeroDecimal(rhs)
            : rhs;
        formatted = `${formattedLhs}.${formattedRhs}`;
      }
    }

    // remove trailing 0's
    if (!opts?.showTrailingZero) {
      formatted = removeTrailingZeros(formatted);
    }

    // set display of asset code
    if (asset) {
      const showSymbol = asset.assetType === AssetType.Fiat && !opts?.hideSymbol;
      const showCode = opts?.appendCode || (!opts?.hideCode && asset.assetType === AssetType.Crypto);
      if (showSymbol) formatted = `$${formatted}`; // TODO: support other fiat symbols (I.e. €)
      if (showCode) formatted = `${formatted} ${asset.code}`;
    }

    // check negative
    const isNegative = Big(safeAmount).lt(0);
    if (isNegative) {
      formatted = `-${formatted.replace('-', '')}`;
    }

    return formatted;
  } catch {
    return safeAmount.toString();
  }
};

export const getValuePriceScale = (asset?: Asset) => {
  if (!asset) return undefined;

  if (asset.assetType === AssetType.Fiat) return 2;
  return asset.price_scale;
};

export const formatCurrency = (
  amount?: string | number | Big,
  asset?: Asset,
  opts?: FormatCurrencyOptions,
  dynamicPriceScaleAssetID?: number,
  formatBalance = false,
  minimumDigits?: number,
) => {
  const safeAmount = amount?.toString() ?? '0';
  try {
    // We want to format balances the old way still
    if (formatBalance) return legacyFormatCurrency(amount, asset, opts, dynamicPriceScaleAssetID);

    const isFiat = asset?.assetType === AssetType.Fiat;

    const options: SharedFormatCurrencyOptions = {
      isFiat,
      currency: isFiat ? 'AUD' : asset?.code,
      priceScaleOverride: opts?.priceScale,
      minimumFractionDigits: minimumDigits ?? isFiat ? 2 : opts?.priceScale || 0,
    };

    let text = formatCurrencyText(safeAmount, options);

    if (opts?.hideSeparator) text = text.replace(/,/g, '');
    if (opts?.hideSymbol) text = text.replace('$', '');

    if (opts?.appendTrailingDecimals && amount?.toString().charAt(amount.toString().length - 1) === '.') {
      text = `${text}.`;
    }

    if (opts?.isApproximate) {
      text = `~${text}`;
    }

    if (opts?.appendCode && asset) {
      text = `${text} ${asset.code}`;
    }

    return text;
  } catch {
    Sentry.captureException('Unable to format currency', {
      extra: { amount, asset, opts },
    });
    return safeAmount.toString();
  }
};

export const truncateNumber = (amount: string | number | Big, minWithdrawalIncrementE: number): string => {
  let truncNum = Big(amount);
  truncNum.e -= minWithdrawalIncrementE;
  truncNum = truncNum.round(0, 0);
  truncNum.e += minWithdrawalIncrementE;
  return truncNum.toString();
};

export const getCurrencySymbol = (currency: string) => {
  try {
    const { locale } = Intl.Collator().resolvedOptions();

    return Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' })
      .formatToParts(0)
      .map((val) => val.value)[0];
  } catch (error) {
    logger.warn(LOG_TAG, 'Unable to get currency symbol', error);
    return '';
  }
};

export const formatNumberWithCommasAndAllowTrailingDot = (value?: string, prefix = ''): string => {
  if (!value) return '';

  const endsWithDecimal = value.endsWith('.');
  const [integerPart, decimalPart] = value.split('.');

  let formattedIntegerPart = '';
  let i = integerPart.length - 1;
  let count = 0;

  while (i >= 0) {
    if (count === 3) {
      formattedIntegerPart = ',' + formattedIntegerPart;
      count = 0;
    }
    formattedIntegerPart = integerPart.charAt(i) + formattedIntegerPart;
    count++;
    i--;
  }

  let formattedValue = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

  // Add the decimal point back if it was there originally
  if (endsWithDecimal && !decimalPart) {
    formattedValue += '.';
  }

  return `${prefix}${formattedValue}`;
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowLineOnlyDown: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M13.0234 4.75C13.0234 4.33579 12.6877 4 12.2734 4C11.8592 4 11.5234 4.33579 11.5234 4.75V17.9327L6.78143 13.171C6.48914 12.8775 6.01427 12.8765 5.72077 13.1688C5.42727 13.4611 5.42629 13.9359 5.71857 14.2294L11.7157 20.2514C11.8384 20.3879 12.0105 20.479 12.2038 20.4968C12.2274 20.4991 12.2511 20.5002 12.275 20.5002C12.4745 20.5002 12.6657 20.4207 12.8065 20.2794L18.8305 14.2294C19.1227 13.9359 19.1217 13.461 18.8282 13.1687C18.5347 12.8765 18.0598 12.8775 17.7675 13.171L13.0234 17.9356V4.75Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowLineOnlyDown };

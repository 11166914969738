import React, { PropsWithChildren, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { CrossInCircleFilled, DangerFilled, InformationFilled } from '@swyftx/aviary/icons/filled';

import clsx from 'clsx';

type Props = {
  severity?: 'warning' | 'error' | 'info';
  className?: string;
};

const InlineNotification: React.FC<PropsWithChildren<Props>> = ({ className, severity = 'info', children }) => {
  const icon = useMemo(() => {
    switch (severity) {
      case 'warning':
        return <DangerFilled className='h-16 w-16 text-color-text-warning' />;
      case 'error':
        return <CrossInCircleFilled className='h-16 w-16 text-color-text-error' />;
      case 'info':
      default:
        return <InformationFilled className='h-16 w-16 text-color-text-info' />;
    }
  }, [severity]);

  return (
    <FlexLayout direction='row' alignItems='start' justifyContent='start' spacing={4} className={clsx(className)}>
      {icon}
      {children}
    </FlexLayout>
  );
};

export { InlineNotification };

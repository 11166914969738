import React, { useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { UploadFileFilled } from '@swyftx/aviary/icons/filled';
import { UploadFile } from '@swyftx/aviary/icons/outlined';

import { useDropzone } from 'react-dropzone';
import { SupportingDocumentsContext } from 'src/context/DocumentUpload/SupportingDocument.context';
import { DocumentUploadFlow, maxFileSizePerFlow } from 'src/context/DocumentUpload/supportingDocumentsUtils';

import { PreviouslyUploadedFiles, UploadedFiles, UploadInProgress } from './DocumentUploadCards';
import { SourceOfWealthStepContainer } from './SourceOfWealthSteps/SourceOfWealthStepContainer';
import { useSourceOfWealthSelector, useSourceOfWealthService } from '../SourceOfWealth.context';

export const SupportingDocumentsContent: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();
  const verificationData = useSourceOfWealthSelector((state) => state.context.verificationData);

  const DocumentsContext = useContext(SupportingDocumentsContext);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: DocumentsContext?.onDrop });

  if (!DocumentsContext) {
    return null;
  }

  const {
    selectFilesEnabled,
    documentUploadSuggestions,
    previousFiles,
    filesInProgress,
    deleteFile,
    uploadedFiles,
    loading,
    continueDisabled,
    backDisabled,
  } = DocumentsContext;

  const getDropzonePropsIfEnabled = () => {
    if (selectFilesEnabled) {
      return getRootProps();
    }
    return {};
  };

  const MAX_SIZE = maxFileSizePerFlow[DocumentUploadFlow.SourceOfWealth] / 1000000;

  return (
    <SourceOfWealthStepContainer
      customTitle='Upload documents of source of wealth'
      onSubmit={(e) => {
        e.preventDefault();
        sourceOfWealthService.send({ type: 'NEXT' });
      }}
      submitDisabled={continueDisabled}
      backDisabled={backDisabled}
    >
      <FlexLayout direction='column' spacing={32}>
        <Body size='small' color='secondary'>
          Please upload documentation that will help verify your source of wealth and depositing funds, such as bank
          statements or payslips.
        </Body>

        <FlexLayout direction='column' spacing={4}>
          <Body size='small' weight='emphasis'>
            Based on your previous answers, we suggest:
          </Body>
          <FlexLayout direction='column' className='px-8'>
            <ul>
              {documentUploadSuggestions(verificationData).map((file) => (
                <li key={file} className='ml-8 list-disc text-12 text-color-text-secondary'>
                  <Body key={file} size='small' color='secondary'>
                    {file}
                  </Body>
                </li>
              ))}
            </ul>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout direction='column' spacing={12}>
          <FlexLayout
            direction='column'
            className={`flex h-[12rem] w-full items-center  justify-center rounded-lg border-2 border-dashed px-12 text-center ${
              isDragActive ? 'border-color-background-info' : ''
            }`}
            spacing={12}
            {...getDropzonePropsIfEnabled()}
          >
            <UploadFileFilled
              id='document_upload_icon'
              className='h-32 w-32 text-color-text-accent'
              alt='Upload icon'
            />
            {!isDragActive && (
              <>
                <FlexLayout direction='column'>
                  <Body size='small' weight='emphasis' className='pointer-non text-color-text-primary'>
                    {!selectFilesEnabled ? 'Maximum documents selected' : 'Drag and drop or select files'}
                  </Body>
                  <Body className='pointer-none text-color-text-secondary'>
                    JPEG, PNG, PDF. Max size {MAX_SIZE}MB per file.
                  </Body>
                </FlexLayout>
                <Button
                  variant='outlined'
                  className='w-full'
                  leadingIcon={<UploadFile />}
                  disabled={!selectFilesEnabled}
                >
                  Select files
                </Button>
              </>
            )}
            {isDragActive && (
              <Body size='small' weight='emphasis' className='pointer-non text-color-text-primary'>
                Drop files here
              </Body>
            )}

            {selectFilesEnabled && <input type='file' accept='.jpeg, .png, .application/pdf' {...getInputProps()} />}
          </FlexLayout>
          <UploadInProgress filesInProgress={filesInProgress} />
          <UploadedFiles uploadedFiles={uploadedFiles} deleteFile={deleteFile} />
          {!loading && previousFiles.length > 0 && (
            <PreviouslyUploadedFiles documents={previousFiles} deleteFile={deleteFile} />
          )}
        </FlexLayout>
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

import { useCallback, useMemo, useState } from 'react';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';

import { api, Asset, OrderType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UIStore, UniversalTradeStore } from '@shared/store';

import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

import { useProfitStopLossAnalytics } from './useProfitStopLossAnalytics';
import { ProfitLossValueMeasurement, ProfitLossValueMethod, TriggerType } from '../../types/profitStopLoss.types';

type Props = {
  secondaryAsset?: Asset;
  primaryAsset?: Asset;
  triggerType: TriggerType;
  profitLossValueMeasurement: ProfitLossValueMeasurement;
  profitLossValueMethod: ProfitLossValueMethod;
  amount?: Big; // amount may be undefined if the order has failed
  triggerPrice: string;
  relatedOrderUuid: string;
  estimatedProfitPercentage: string;
  estimatedProfitDollars: string;
  estimatedFee: string;
  currentPrice?: string;
  profitLossValue: string;
};

const useProfitStopLossOrder = ({
  secondaryAsset,
  primaryAsset,
  triggerType,
  currentPrice,
  amount,
  triggerPrice,
  relatedOrderUuid,
  profitLossValueMethod,
  profitLossValueMeasurement,
  estimatedProfitPercentage,
  estimatedProfitDollars,
  estimatedFee,
  profitLossValue,
}: Props) => {
  const [executingOrder, setExecutingOrder] = useState<boolean>(false);
  const { invalidateCache } = useTransactionsCache();
  const { addMessageBox } = UIStore.useUIStore;
  const { setShowGlobalTrade } = UniversalTradeStore;
  const { trackOrderCreated } = useProfitStopLossAnalytics();
  const { openModal } = useModal();

  // amount may be undefined if the order has failed
  // set order amount to 0 so that UI doesn't break
  const safeAmount = amount ?? Big(0);

  const validOrder = useMemo(() => {
    if (triggerType === TriggerType.StopLoss) {
      if (Big(currentPrice).lte(triggerPrice)) return false;
      if (Big(estimatedProfitPercentage).gte(99)) return false;
    } else if (Big(currentPrice).gte(triggerPrice)) return false;

    return (
      primaryAsset &&
      secondaryAsset &&
      safeAmount.gt(0) &&
      triggerPrice.length > 0 &&
      estimatedFee.length > 0 &&
      estimatedProfitDollars.length > 0 &&
      estimatedProfitPercentage.length > 0 &&
      profitLossValue.length > 0
    );
  }, [
    triggerType,
    primaryAsset,
    secondaryAsset,
    safeAmount,
    triggerPrice,
    estimatedFee,
    estimatedProfitDollars,
    estimatedProfitPercentage,
    profitLossValue,
    currentPrice,
  ]);

  const executeOrder = useCallback(async () => {
    if (!validOrder || !secondaryAsset || !primaryAsset) return;

    try {
      setExecutingOrder(true);

      const response = await api.endpoints.createOrder({
        data: {
          assetQuantity: secondaryAsset.code,
          orderType: triggerType === TriggerType.StopLoss ? OrderType.StopSell : OrderType.TriggerSell,
          primary: primaryAsset.code,
          quantity: safeAmount.toString(),
          secondary: secondaryAsset.code,
          trigger: Big(1).div(triggerPrice).toString(),
        },
      });

      trackOrderCreated(triggerType, {
        relatedOrderUuid,
        placedOrderUuid: response.data.orderUuid,
        calculationType: profitLossValueMethod,
        calculationValueType: profitLossValueMeasurement,
        estimatedPercentage: estimatedProfitPercentage,
        estimatedProfit: estimatedProfitDollars,
      });

      openModal(Modals.ProfitStopLossSuccessModal, { triggerType, originalOrderUuid: relatedOrderUuid });

      invalidateCache();
      setShowGlobalTrade(false);
    } catch (e) {
      addMessageBox({
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        content: <ErrorMessageBox title='An error occured when trying to place the order' />,
      });
    } finally {
      setExecutingOrder(false);
    }
  }, [
    addMessageBox,
    safeAmount,
    profitLossValueMethod,
    profitLossValueMeasurement,
    estimatedProfitPercentage,
    estimatedProfitDollars,
    invalidateCache,
    openModal,
    primaryAsset,
    relatedOrderUuid,
    secondaryAsset,
    setShowGlobalTrade,
    trackOrderCreated,
    triggerPrice,
    triggerType,
    validOrder,
  ]);

  return {
    validOrder,
    executeOrder,
    executingOrder,
  };
};

export { useProfitStopLossOrder };

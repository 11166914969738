import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowLineOnlyUp: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M17.2186 10.829C17.5108 11.1225 17.9857 11.1235 18.2792 10.8312C18.5727 10.5389 18.5737 10.064 18.2814 9.77052L12.2843 3.74849C12.1616 3.61219 11.9898 3.52108 11.7966 3.50318C11.7729 3.50091 11.749 3.49975 11.725 3.49976C11.5255 3.49976 11.3343 3.57923 11.1935 3.72057L5.16952 9.77057C4.87726 10.0641 4.87828 10.539 5.1718 10.8312C5.46533 11.1235 5.9402 11.1225 6.23246 10.8289L10.9766 6.06437V19.25C10.9766 19.6642 11.3123 20 11.7266 20C12.1408 20 12.4766 19.6642 12.4766 19.25V6.0673L17.2186 10.829Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowLineOnlyUp };

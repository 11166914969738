import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';

import { AccountStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { SelectedLevelParam, urlToStatusMap, useVerificationLevels } from './VerificationLevel.data';
import { verificationLevelCards } from './VerificationLevelCard/VerificationLevelCard.data';
import { VerificationLevelTabs } from './VerificationLevelTabs';

const VerificationLevel: React.FC = observer(() => {
  const { getAccountStatus, isNZ } = UserStore.useUserStore;
  const [params] = useSearchParams();

  const [selectedLevel, setSelectedLevel] = useState<AccountStatusEnum>(getAccountStatus());
  const verificationLevels = useVerificationLevels();

  const selectedLevelData = useMemo(
    () => verificationLevels.find((level) => [level.status, level.restrictedStatus].includes(selectedLevel)),
    [selectedLevel, verificationLevels],
  );

  useEffect(() => {
    if (params.has('selected')) {
      const level = params.get('selected') as SelectedLevelParam;
      setSelectedLevel(urlToStatusMap[level]);
    } else {
      setSelectedLevel(getAccountStatus());
    }
  }, [getAccountStatus, params]);

  return (
    <SettingsCluster
      title='Verification Level'
      items={[
        <FlexLayout direction='column' className='p-24' spacing={24} key='verificationLevel'>
          <VerificationLevelTabs selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} isNZ={isNZ()} />
          {selectedLevelData && verificationLevelCards[selectedLevelData.status]}
        </FlexLayout>,
      ]}
    />
  );
});

VerificationLevel.displayName = 'VerificationLevel';

export { VerificationLevel };

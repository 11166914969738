import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { DepositFilled, ReceiveCryptoFilled } from '@swyftx/aviary/icons/filled';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { EntityPermissions, usePermissions, UserPermissions } from '@hooks/Permissions';

import { observer } from 'mobx-react-lite';

const LOG_TAG = 'WALLETS';

const WalletSendReceive: React.FC = observer(() => {
  const { t } = useTranslation('wallet');
  const { openModal } = useModal();
  const { canTransferCrypto } = UserStore.useUserStore;
  const { pathname } = useLocation();
  const avo = useAvo();

  const handleDepositClicked = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive);
  };

  const { element: DepositFundsButton } = usePermissions({
    permissions: [EntityPermissions.ADDRESS_READ],
    enabledElement: (
      <Button size='lg' onClick={handleDepositClicked} leadingIcon={<DepositFilled className='h-20 w-20' />}>
        {t('overview.deposit')}
      </Button>
    ),
    disabledOptions: {
      disabled: true,
      tooltip: true,
    },
  });

  const { element: SendReceivesButton } = usePermissions({
    permissions: [UserPermissions.WITHDRAW_FUNDS],
    enabledElement: (
      <Button
        size='lg'
        onClick={() => openModal(Modals.WithdrawSend)}
        leadingIcon={<ReceiveCryptoFilled className='h-20 w-20' />}
      >
        {t('overview.withdraw')}
      </Button>
    ),
    disabledOptions: {
      disabled: true,
      tooltip: true,
    },
  });

  return (
    <FlexLayout direction='row' spacing={8}>
      {DepositFundsButton}

      {canTransferCrypto() && SendReceivesButton}
    </FlexLayout>
  );
});

export { WalletSendReceive };

import { HTMLAttributes } from 'react';

import { VariantProps, cva } from 'cva';

export type FlexLayoutSpacing = 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32 | 48 | 64 | 96;

export type FlexLayoutProps = VariantProps<typeof flexLayoutVariants> & {
  className?: string;
  spacing?: FlexLayoutSpacing;
} & HTMLAttributes<HTMLDivElement>;

export const flexLayoutVariants = cva('flex', {
  variants: {
    direction: {
      row: 'flex-row',
      column: 'flex-col',
    },
    alignItems: {
      start: 'items-start',
      center: 'items-center',
      stretch: 'items-stretch',
      baseline: 'items-baseline',
      end: 'items-end',
    },
    justifyContent: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      'space-between': 'justify-between',
      'space-around': 'justify-around',
      'space-evenly': 'justify-evenly',
      stretch: 'justify-stretch',
    },
    spacing: {
      0: 'gap-0',
      2: 'gap-2',
      4: 'gap-4',
      8: 'gap-8',
      12: 'gap-12',
      16: 'gap-16',
      24: 'gap-24',
      32: 'gap-32',
      48: 'gap-48',
      64: 'gap-64',
      96: 'gap-96',
    },
  },
  compoundVariants: [
    {
      direction: 'row',
      spacing: 2,
    },
    {
      direction: 'row',
      spacing: 4,
    },
    {
      direction: 'row',
      spacing: 8,
    },
    {
      direction: 'row',
      spacing: 12,
    },
    {
      direction: 'row',
      spacing: 16,
    },
    {
      direction: 'row',
      spacing: 24,
    },
    {
      direction: 'row',
      spacing: 32,
    },
    {
      direction: 'row',
      spacing: 48,
    },
    {
      direction: 'row',
      spacing: 64,
    },
    {
      direction: 'row',
      spacing: 96,
    },

    {
      direction: 'column',
      spacing: 2,
    },
    {
      direction: 'column',
      spacing: 4,
    },
    {
      direction: 'column',
      spacing: 8,
    },
    {
      direction: 'column',
      spacing: 12,
    },
    {
      direction: 'column',
      spacing: 16,
    },
    {
      direction: 'column',
      spacing: 24,
    },
    {
      direction: 'column',
      spacing: 32,
    },
    {
      direction: 'column',
      spacing: 48,
    },
    {
      direction: 'column',
      spacing: 64,
    },
    {
      direction: 'column',
      spacing: 96,
    },
  ],
});

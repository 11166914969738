type Options = {
  overlapPadding?: number;
  containerBBox?: DOMRect; // Removes width overlap
};

const removeNodeOverlaps = (textNodes: Element[], options?: Options) => {
  const overlapPadding = options?.overlapPadding || 0;
  const containerBBox = options?.containerBBox;
  const areNodesOverlapping = (l: DOMRect, r: DOMRect) => {
    const leftNode = l;
    const rightNode = r;
    const isYParallel =
      leftNode.y >= rightNode.y - leftNode.height && leftNode.y <= rightNode.y + rightNode.height + leftNode.height;
    const isXOverlap = isYParallel && leftNode.x + leftNode.width + overlapPadding >= rightNode.x;
    const isPastContainer = containerBBox ? rightNode.x + rightNode.width >= containerBBox.right : false;

    // No use-case yet but it's doable if our labels get more complicated
    // const isYoverlap = leftNode.y + leftNode.height >= rightNode.y;

    return isXOverlap || isPastContainer;
  };

  textNodes.forEach((currentNode: Element, i: number) => {
    const nodeBBox = currentNode.getBoundingClientRect();

    textNodes
      .filter((k: Element, j: number) => j > i) // Ignore previous nodes
      .forEach((nextNode: Element) => {
        const nextNodeBBox = nextNode.getBoundingClientRect();
        // hide if we're overlapping with the next node
        if (areNodesOverlapping(nodeBBox, nextNodeBBox)) {
          nextNode.setAttribute('display', 'none');
        }
      });
  });
};

export { removeNodeOverlaps };

import { useCallback, useContext, useState } from 'react';

import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { api, CreateLinkedOrderPayload, OrderType } from '@shared/api';
import { OrderSourceEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';

import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

type BuildOrderProps = {
  primary: string;
  secondary: string;
  amount: string;
  orderSourceUuid?: string;
  stopLossPrice: string;
  takeProfitPrice: string;
};

const useBuildSwyftxProOcoOrder = () => {
  const [isExecutingOco, setIsExecutingOco] = useState<boolean>(false);
  const { invalidateCache } = useTransactionsCache();
  const { addToast } = useContext(ToastManagerContext);

  const buildOcoOrder = useCallback(
    ({
      primary,
      secondary,
      amount,
      orderSourceUuid,
      stopLossPrice,
      takeProfitPrice,
    }: BuildOrderProps): CreateLinkedOrderPayload => ({
      orderA: {
        orderType: OrderType.TriggerSell,
        trigger: Big(1).div(takeProfitPrice).toString(),
        primary: primary,
        secondary: secondary,
        assetQuantity: secondary,
        quantity: amount,
      },
      orderB: {
        orderType: OrderType.StopSell,
        trigger: Big(1).div(stopLossPrice).toString(),
        primary: primary,
        secondary: secondary,
        assetQuantity: secondary,
        quantity: amount,
      },
      linkType: 'OCO',
      orderSourceUuid,
      orderSource: orderSourceUuid ? OrderSourceEnum.PARENT_ORDER : undefined,
    }),
    [],
  );

  const executeOcoOrder = useCallback(
    async (data: CreateLinkedOrderPayload) => {
      try {
        setIsExecutingOco(true);
        await api.endpoints.createLinkedOrder({ data });
        invalidateCache();

        addToast({
          title: 'Linked order has successfully been placed',
          type: 'success',
          isDismissable: true,
        });
      } catch (e) {
        addToast({
          title: (e as SwyftxError).errorMessage,
          type: 'destructive',
          isDismissable: true,
        });
      } finally {
        setIsExecutingOco(false);
      }
    },
    [addToast, invalidateCache],
  );

  return {
    buildOcoOrder,
    executeOcoOrder,
    isExecutingOco,
  };
};

export { useBuildSwyftxProOcoOrder };

import React from 'react';

import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { formatValueToCurrencyShorthand } from '@utils/currency';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const SingleCategoryVolume: React.FC<Props> = observer(({ asset }) => {
  const baseAsset = useBaseAsset();

  if (!baseAsset) return null;

  return (
    <NumericDataItem
      color='primary'
      data={formatValueToCurrencyShorthand(asset.volume[baseAsset.id].day, baseAsset.assetType, baseAsset.code)}
      size='small'
      alignItems='end'
    />
  );
});

export { SingleCategoryVolume };

import { OrderType } from '@shared/api';
import { OrderStatusEnum } from '@shared/enums';

export const getStatusText = (orderStatus: OrderStatusEnum) => {
  switch (orderStatus) {
    case OrderStatusEnum.OPEN:
      return 'Open';
    case OrderStatusEnum.FILLED:
      return 'Complete';
    case OrderStatusEnum.SYSTEM_CANCELLED:
    case OrderStatusEnum.USER_CANCELLED:
      return 'Cancelled';
    case OrderStatusEnum.FAILED:
    case OrderStatusEnum.FAILED_MIN_AMOUNT:
    case OrderStatusEnum.FAILED_MAX_AMOUNT:
    case OrderStatusEnum.INSUFFICIENT_BALANCE:
      return 'Failed';
    case OrderStatusEnum.REFUNDED:
      return 'Refunded';
    case OrderStatusEnum.PARTIAL_FILL:
      return 'Partially filled';
    case OrderStatusEnum.PENDING:
      return 'Pending';
    default:
      orderStatus satisfies never;
  }
};

export const getStatusColor = (orderStatus: OrderStatusEnum) => {
  switch (orderStatus) {
    case OrderStatusEnum.OPEN:
    case OrderStatusEnum.PENDING:
    case OrderStatusEnum.PARTIAL_FILL:
    case OrderStatusEnum.REFUNDED:
      return 'primary';
    case OrderStatusEnum.FILLED:
      return 'success';
    case OrderStatusEnum.FAILED:
    case OrderStatusEnum.FAILED_MIN_AMOUNT:
    case OrderStatusEnum.FAILED_MAX_AMOUNT:
    case OrderStatusEnum.INSUFFICIENT_BALANCE:
    case OrderStatusEnum.SYSTEM_CANCELLED:
    case OrderStatusEnum.USER_CANCELLED:
      return 'destructive';
    default:
      orderStatus satisfies never;
  }
};

const TRIGGER_ORDERS = [OrderType.StopBuy, OrderType.StopSell, OrderType.TriggerBuy, OrderType.TriggerSell];

export const isTriggerOrder = (orderType: OrderType) => TRIGGER_ORDERS.includes(orderType);

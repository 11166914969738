import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Notification, Locked, Loading } from '@swyftx/aviary/icons/outlined';

import { GreenIdStatusEnum } from '@shared/enums';
import entityService from '@shared/services/entityService';
import { UIStore, UserStore } from '@shared/store';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';
import entityHelpers from '@shared/utils/lib/entityHelpers';

import { GreenIDStatusCard } from '@Profile/subroutes/Verification/components/IdentityVerificationModal/GreenIDStatusCard';

import { observer } from 'mobx-react-lite';

import { getBooleanNotificationFromStatusEnum } from '../EntityAddMemberWizard.const';
import { useToggleEntityMemberNotifications } from '../EntityConfigureMemberWizard.hooks';
import { EntityConfigureMemberInvitation } from '../components/EntityConfigureMemberInvitation';

type ItemProps = {
  action: JSX.Element;
  icon: JSX.Element;
  title: string;
  description?: string;
};

const Item: React.FC<ItemProps> = ({ description, action, title, icon }) => (
  <FlexLayout spacing={16} direction='row' alignItems='center'>
    {icon}
    <FlexLayout direction='row' justifyContent='space-between' alignItems='center' className='w-full'>
      <FlexLayout direction='column' spacing={4}>
        <Body weight='emphasis'>{title}</Body>
        {description && (
          <Body color='secondary' size='small'>
            {description}
          </Body>
        )}
      </FlexLayout>
      {action}
    </FlexLayout>
  </FlexLayout>
);

type Props = {
  isAdmin?: boolean;
  goToPermissions: () => void;
  entityMember: EntityMember;
  entityMemberUuid: string;
  entityUuid: string;
  onClose: () => void;
};

export const EntityConfigureMemberOverviewStep: React.FC<Props> = observer(
  ({ isAdmin, entityMember, entityUuid, entityMemberUuid, goToPermissions, onClose }) => {
    const [enable, setEnable] = useState(getBooleanNotificationFromStatusEnum(entityMember.notificationEnabled));
    const [newEnable, setNewEnable] = useState(getBooleanNotificationFromStatusEnum(entityMember.notificationEnabled));
    const { addToastMessage } = UIStore.useUIStore;
    const { previousUserProfile } = UserStore.useUserStore;
    const { t } = useTranslation('profile');

    const scopePreset = useMemo(() => entityHelpers.getPermissionPreset(entityMember.scope), [entityMember.scope]);
    const isEntityUser = useMemo(
      () => entityMember.uuid === previousUserProfile?.intercom?.uuid,
      [entityMember.uuid, previousUserProfile?.intercom?.uuid],
    );

    useEffect(() => {
      entityService.updateMembers();
    }, []);

    const { error, isLoading } = useToggleEntityMemberNotifications(
      entityUuid,
      entityMemberUuid,
      enable,
      newEnable,
      setEnable,
    );

    useEffect(() => {
      if (error) {
        setNewEnable(enable);
        addToastMessage({
          severity: 'error',
          message: error,
        });
      }
    }, [addToastMessage, enable, error]);

    const initials = useMemo(
      () =>
        entityMember.name
          .split(' ')
          .map((name) => name[0])
          .join(''),
      [entityMember.name],
    );

    return (
      <FlexLayout direction='column' spacing={24}>
        <Item
          description={entityMember.email}
          action={
            <Chip
              variant='subtle'
              color={entityMember?.verified === GreenIdStatusEnum.VERIFIED ? 'success' : 'secondary'}
              size='sm'
            >
              {t(
                `entities.configureMemberModal.overview.chipLabels.${
                  entityMember?.verified === GreenIdStatusEnum.VERIFIED ? 'verified' : 'unverified'
                }`,
              )}
            </Chip>
          }
          title={entityMember.name}
          icon={<Avatar color='purple' text={initials} />}
        />
        <Item
          action={
            <>
              {isLoading && <Loading className='animate-spin' />}
              <Switch
                checked={enable}
                disabled={!entityMember.uuid || !entityUuid}
                onCheckedChange={(checked) => setNewEnable(checked)}
              />
            </>
          }
          icon={<Notification className='h-24 w-24' />}
          description={t('entities.configureMemberModal.overview.labels.notifications')}
          title={t('entities.configureMemberModal.overview.headers.notifications')}
        />
        <Item
          action={
            <Button
              variant='outlined'
              onClick={goToPermissions}
              disabled={!isAdmin || entityMember.expiry !== undefined}
            >
              {t('entities.configureMemberModal.overview.labels.update')}
            </Button>
          }
          description={scopePreset}
          icon={<Locked className='h-24 w-24' />}
          title={t('entities.configureMemberModal.overview.headers.permissions')}
        />
        <FlexLayout direction='column' spacing={16}>
          {entityMember.expiry && <EntityConfigureMemberInvitation expiration={entityMember.expiry} />}
          <GreenIDStatusCard entityMember={entityMember} isEntityUser={isEntityUser} />
          <FlexLayout direction='row' className='w-full justify-end'>
            <Button variant='outlined' onClick={onClose}>
              Close
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    );
  },
);

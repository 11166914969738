import React, { useMemo } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { DiscountFilled, MinusInCircleFilled, AddInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Help } from '@swyftx/aviary/icons/outlined';

import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api/@types/markets';
import { formatDateTime } from '@shared/utils';

import { EarnHistoryItem, EarnItemType } from '@services/WalletService';

type Props = {
  asset: Asset;
  transaction: EarnHistoryItem;
};

const SingleWalletEarnTransactionHistoryItem: React.FC<Props> = ({ asset, transaction }) => {
  const title = useMemo(() => {
    switch (transaction.type) {
      case EarnItemType.Reward:
        return 'Interest earned';
      case EarnItemType.Deposit:
        return 'Added to earn';
      case EarnItemType.Withdrawal:
        return 'Removed from earn';
      default:
        return '';
    }
  }, [transaction]);

  const icon = useMemo(() => {
    switch (transaction.type) {
      case EarnItemType.Reward:
        return <DiscountFilled className='h-18 w-18 text-color-text-primary' />;
      case EarnItemType.Deposit:
        return <AddInCircleFilled className='h-18 w-18 text-color-text-primary' />;
      case EarnItemType.Withdrawal:
        return <MinusInCircleFilled className='h-18 w-18 text-color-text-primary' />;
      default:
        return <Help width={18} height={18} color='warning' />;
    }
  }, [transaction]);

  return (
    <>
      <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='h-[60px] p-16'>
        <FlexLayout direction='row' alignItems='center' spacing={8} justifyContent='start' className='w-1/3 text-left'>
          <Avatar color='secondary'>{icon}</Avatar>

          <FlexLayout direction='column'>
            <Body color='primary'>{title}</Body>

            <Body size='small' color='secondary'>
              {formatDateTime(new Date(transaction.timestamp)).toUpperCase()}
            </Body>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout alignItems='center' justifyContent='end' className='w-1/3 text-right'>
          {(transaction.type === EarnItemType.Deposit || transaction.type === EarnItemType.Reward) && (
            <FormattedText
              prefix='+'
              formatBalance
              value={transaction.amount}
              currency={asset}
              typographyProps={{ color: 'success', className: 'text-12 @md:text-14', size: 'small' }}
            />
          )}
        </FlexLayout>
        <FlexLayout alignItems='center' justifyContent='end' className='w-1/3 text-right'>
          {transaction.type === EarnItemType.Withdrawal && (
            <FormattedText
              prefix='-'
              formatBalance
              value={transaction.amount.toString().replace('-', '')}
              currency={asset}
              typographyProps={{ color: 'error', className: 'text-12 @md:text-14', size: 'small' }}
            />
          )}
        </FlexLayout>
      </FlexLayout>
      <Separator className='w-full' />
    </>
  );
};

export { SingleWalletEarnTransactionHistoryItem };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Analytics: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M4.07175 3.85532C5.01707 2.84143 6.3632 2.27393 7.95243 2.27393H10.2897C10.6874 2.27393 11.0097 2.59628 11.0097 2.99393C11.0097 3.39157 10.6874 3.71393 10.2897 3.71393H7.95243C6.71989 3.71393 5.77011 4.14539 5.12497 4.83732C4.47463 5.53484 4.08062 6.55516 4.08062 7.81925V15.4486C4.08062 16.7136 4.47306 17.7338 5.12215 18.4309C5.76583 19.1222 6.71522 19.554 7.95243 19.554H16.0488C17.2865 19.554 18.2359 19.1222 18.8794 18.431C19.5284 17.7339 19.9206 16.7137 19.9206 15.4486V13.5792C19.9206 13.1816 20.243 12.8592 20.6406 12.8592C21.0383 12.8592 21.3606 13.1816 21.3606 13.5792V15.4486C21.3606 17.0109 20.8725 18.4034 19.9333 19.4122C18.9887 20.4268 17.6422 20.994 16.0488 20.994H7.95243C6.35946 20.994 5.01295 20.4268 4.06827 19.4122C3.129 18.4035 2.64062 17.011 2.64062 15.4486V7.81925C2.64062 6.25596 3.13164 4.86362 4.07175 3.85532Z'
      fill='currentColor'
    />
    <path
      d='M16.9208 13.3719C17.1994 13.6557 17.1951 14.1116 16.9113 14.3901L14.4387 16.817C14.1961 17.0551 13.82 17.0911 13.5367 16.9034L10.3791 14.8115L8.27312 17.0447C8.00031 17.334 7.54463 17.3474 7.25533 17.0745C6.96603 16.8017 6.95266 16.346 7.22547 16.0567L9.74742 13.3824C9.98781 13.1275 10.3768 13.0826 10.6689 13.2761L13.846 15.381L15.9027 13.3624C16.1864 13.0839 16.6423 13.0881 16.9208 13.3719Z'
      fill='currentColor'
    />
    <path
      d='M7.60406 6.62137C7.60406 6.22372 7.28171 5.90137 6.88406 5.90137C6.48642 5.90137 6.16406 6.22372 6.16406 6.62137V10.409C6.16406 10.8066 6.48642 11.129 6.88406 11.129C7.28171 11.129 7.60406 10.8066 7.60406 10.409V6.62137Z'
      fill='currentColor'
    />
    <path
      d='M10.4856 8.02431C10.4856 7.62667 10.1633 7.30431 9.76563 7.30431C9.36798 7.30431 9.04563 7.62667 9.04563 8.02431V10.409C9.04563 10.8066 9.36798 11.129 9.76563 11.129C10.1633 11.129 10.4856 10.8066 10.4856 10.409V8.02431Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6635 2.27549C16.7037 2.27445 16.7439 2.27393 16.7842 2.27393C19.3112 2.27393 21.3595 4.32265 21.3595 6.84919C21.3595 6.89787 21.3588 6.94638 21.3572 6.99469C21.3571 7.00208 21.3569 7.00944 21.3565 7.01677C21.2683 9.46614 19.2551 11.4245 16.7842 11.4245C14.2572 11.4245 12.209 9.37619 12.209 6.84919C12.209 4.38759 14.1533 2.37953 16.5904 2.27796C16.6106 2.27626 16.631 2.27539 16.6516 2.27539C16.6556 2.27539 16.6596 2.27542 16.6635 2.27549ZM15.9316 3.83125C14.6144 4.20267 13.649 5.41333 13.649 6.84919C13.649 8.58092 15.0525 9.9845 16.7842 9.9845C18.2206 9.9845 19.4313 9.01878 19.8024 7.70111H17.08C16.4458 7.70111 15.9316 7.18698 15.9316 6.55275V3.83125ZM19.8644 6.26111H17.3716V3.76887C18.6319 4.00776 19.6253 5.00104 19.8644 6.26111Z'
      fill='currentColor'
    />
  </svg>
);

export { Analytics };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeLine: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.1667 6.66569C20.8716 6.37512 20.3967 6.37887 20.1061 6.67407L13.7903 13.0905L10.398 9.63543C10.2568 9.49167 10.0638 9.41075 9.86233 9.41087C9.66086 9.411 9.46793 9.49217 9.32696 9.63611L2.82479 16.2755C2.53497 16.5714 2.53993 17.0463 2.83586 17.3361C3.1318 17.6259 3.60664 17.6209 3.89646 17.325L9.86348 11.2321L13.2544 14.6858C13.3953 14.8293 13.588 14.9102 13.7891 14.9103C13.9902 14.9105 14.183 14.8298 14.3241 14.6865L21.1751 7.72632C21.4657 7.43112 21.4619 6.95626 21.1667 6.66569Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeLine };

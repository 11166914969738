import { HTMLAttributes } from 'react';

import { VariantProps, cva } from 'cva';

export type IconProps = VariantProps<typeof iconVariants> & {
  className?: string;
  icon: React.ReactElement;
} & Omit<HTMLAttributes<HTMLDivElement>, 'style'>;

export const iconContainerVariants = cva('rounded-[8px]  p-4 flex items-center justify-center', {
  variants: {
    color: {
      accent: 'bg-color-background-primary-subtle',
      tertiary: 'bg-color-background-pageBG',
      info: 'bg-color-background-info-subtle',
      destructive: 'bg-color-background-error-subtle',
      success: 'bg-color-background-success-subtle',
    },
  },
});

export const iconVariants = cva('flex', {
  variants: {
    color: {
      accent: 'text-color-text-accent',
      tertiary: 'bg-color-background-pageBG',
      info: 'text-color-text-info',
      destructive: 'text-color-text-error',
      success: 'text-color-text-success',
    },
  },
});

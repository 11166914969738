import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const MetaGovernance: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7209 8.51406H13.551C14.9029 8.51406 15.999 7.41807 15.999 6.06606V5.198C15.999 3.846 14.9029 2.75 13.551 2.75H10.5906C9.23854 2.75 8.14258 3.846 8.14258 5.198V6.06606C8.14258 7.41805 9.23854 8.51406 10.5906 8.51406H11.2809V10.4075L6.64415 14.7539H5.56714C4.21515 14.7539 3.11914 15.8499 3.11914 17.2019V18.0699C3.11914 19.422 4.21517 20.5179 5.56714 20.5179H8.52749C9.87947 20.5179 10.9755 19.422 10.9755 18.0699V17.2019C10.9755 15.9215 9.99252 14.8707 8.74003 14.763L12.0009 11.7063L15.2615 14.7628C14.0078 14.8693 13.0234 15.9206 13.0234 17.2019V18.0699C13.0234 19.422 14.1195 20.5179 15.4714 20.5179H18.4318C19.7837 20.5179 20.8798 19.422 20.8798 18.0699V17.2019C20.8798 15.8499 19.7838 14.7539 18.4318 14.7539H17.3577L12.7209 10.4075V8.51406ZM10.5906 4.19C10.0338 4.19 9.58258 4.64129 9.58258 5.198V6.06606C9.58258 6.62276 10.0338 7.07406 10.5906 7.07406H11.9788C11.9861 7.07384 11.9935 7.07373 12.0009 7.07373C12.0082 7.07373 12.0156 7.07384 12.0229 7.07406H13.551C14.1077 7.07406 14.559 6.62277 14.559 6.06606V5.198C14.559 4.64129 14.1077 4.19 13.551 4.19H10.5906ZM4.55914 17.2019C4.55914 16.6452 5.01044 16.1939 5.56714 16.1939H8.52749C9.0842 16.1939 9.53549 16.6452 9.53549 17.2019V18.0699C9.53549 18.6267 9.08422 19.0779 8.52749 19.0779H5.56714C5.01042 19.0779 4.55914 18.6267 4.55914 18.0699V17.2019ZM14.4634 17.2019C14.4634 16.6452 14.9147 16.1939 15.4714 16.1939H18.4318C18.9885 16.1939 19.4398 16.6452 19.4398 17.2019V18.0699C19.4398 18.6267 18.9885 19.0779 18.4318 19.0779H15.4714C14.9147 19.0779 14.4634 18.6267 14.4634 18.0699V17.2019Z'
      fill='currentColor'
    />
  </svg>
);

export { MetaGovernance };

import { AssetDetails, AssetInformation, LiveRates } from '../../@types/markets';
import { Methods, request } from '../rest';

const marketsEndpoints = {
  getAssets: ({ headers }: { headers?: Record<string, string> } = {}): AssetInformation[] =>
    request({ path: '/markets/assets/', method: Methods.GET, headers, auth: true }),

  getAssetsBasicInfo: ({ headers }: { headers: Record<string, string> }) =>
    request({ path: '/markets/info/basic/', method: Methods.GET, headers, auth: true }),

  getDetailedAssetInfo: ({
    headers,
    params,
  }: { params?: { asset: string }; headers?: Record<string, string> } = {}): AssetDetails[] =>
    request({ path: '/markets/info/detail/', method: Methods.GET, headers, params, auth: true }),

  getRates: ({
    headers,
    params,
    query,
  }: {
    params: { assetId: number };
    headers?: Record<string, string>;
    query?: object;
  }): LiveRates => request({ path: '/liverates/:assetId/', method: Methods.GET, headers, params, query, auth: true }),

  getProRates: ({
    headers,
    params,
    query,
  }: {
    params: { assetId: number };
    headers?: Record<string, string>;
    query?: object;
  }): LiveRates =>
    request({ path: '/live-rates/pro/:assetId/', method: Methods.GET, headers, params, query, auth: true }),

  getLiveOrderbooks: ({
    headers,
    params,
    query,
  }: {
    params: { baseId: number; secondaryId: number };
    headers?: Record<string, string>;
    query?: object;
  }) =>
    request({
      path: '/live-orderbooks/:baseId/:secondaryId',
      method: Methods.GET,
      headers,
      params,
      query,
      auth: true,
    }),
};

export default marketsEndpoints;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const MessagesSearch: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='49' height='48' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.0733 15.9549C31.9891 8.23448 20.7623 7.75132 14.0365 14.4999C9.12069 19.4323 8.05097 26.7422 10.8103 32.7284C10.8202 32.7498 10.8295 32.7716 10.8384 32.7934C11.0424 33.2974 11.9434 34.909 12.6239 36.0932C13.2858 37.2432 13.2186 38.6704 12.4507 39.7546C12.0259 40.3532 11.4916 41.1182 11.077 41.7394L9.8298 40.9068L11.0785 41.7372C11.0464 41.7854 11.0391 41.822 11.0382 41.8502C11.0372 41.8836 11.0453 41.923 11.0657 41.9614C11.0861 42 11.1129 42.0266 11.1376 42.042C11.1576 42.0546 11.1876 42.0678 11.2418 42.0678C11.2417 42.0678 11.242 42.0678 11.2418 42.0678L12.3167 42.0674C16.9536 42.0654 23.7361 42.0624 25.3549 42.0678C26.1833 42.0706 26.8527 42.7444 26.8499 43.5728C26.8471 44.4014 26.1733 45.0706 25.3449 45.0678C23.7311 45.0624 16.9431 45.0654 12.3025 45.0674L11.2428 45.0678C8.6625 45.0678 7.171 42.1954 8.58038 40.076L8.58182 40.0738C9.01954 39.418 9.57184 38.6278 10.0033 38.0198C10.0954 37.8896 10.1014 37.7246 10.0238 37.5898C9.41051 36.5224 8.3924 34.723 8.07258 33.9556C4.824 26.8772 6.08606 18.2273 11.9116 12.3821C19.8928 4.37386 33.2191 4.94888 40.4293 14.0977C42.3867 16.5804 43.6283 19.462 44.1661 22.4454C44.3131 23.2606 43.7713 24.0406 42.9559 24.1876C42.1407 24.3346 41.3605 23.7928 41.2137 22.9774C40.7589 20.4548 39.7119 18.0332 38.0733 15.9549Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.1133 21.8438C17.1133 21.0153 17.7848 20.3438 18.6133 20.3438H28.0023C28.8307 20.3438 29.5023 21.0153 29.5023 21.8438C29.5023 22.6722 28.8307 23.3438 28.0023 23.3438H18.6133C17.7848 23.3438 17.1133 22.6722 17.1133 21.8438ZM17.1133 28.8948C17.1133 28.0664 17.7848 27.3948 18.6133 27.3948H22.6665C23.4949 27.3948 24.1665 28.0664 24.1665 28.8948C24.1665 29.7232 23.4949 30.3948 22.6665 30.3948H18.6133C17.7848 30.3948 17.1133 29.7232 17.1133 28.8948Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.476 30.2617C34.0088 30.2617 32 32.2699 32 34.7577C32 37.2461 34.0094 39.2557 36.476 39.2557C37.6338 39.2557 38.691 38.8127 39.4868 38.0853C39.509 38.0633 39.5316 38.0423 39.5548 38.0221C40.4146 37.2029 40.9518 36.0443 40.9518 34.7577C40.9518 32.2699 38.943 30.2617 36.476 30.2617ZM42.588 39.0755C43.4474 37.8539 43.9518 36.3641 43.9518 34.7577C43.9518 30.6227 40.6094 27.2617 36.476 27.2617C32.3424 27.2617 29 30.6227 29 34.7577C29 38.8921 32.3418 42.2557 36.476 42.2557C37.9188 42.2557 39.2652 41.8459 40.4066 41.1367L42.7586 43.4923C43.3438 44.0785 44.2936 44.0793 44.8798 43.4941C45.466 42.9087 45.4668 41.9589 44.8816 41.3727L42.588 39.0755Z'
      fill='currentColor'
    />
  </svg>
);

export { MessagesSearch };

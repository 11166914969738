import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { useCustomRouteNames } from '@routes/types/useCustomRouteNames';
import { capitalize } from '@utils/formatting';

import { NavigationURLNames } from 'src/lib/navigation';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  title: string;
  tooltip?: string;
  fullPath?: string;
  historyIndex: number;
  anotherPath?: boolean;
  collapse?: boolean;
  isCompact?: boolean;
};

const AppHeaderBreadCrumb: React.FC<Props> = ({ title, tooltip, anotherPath, historyIndex, fullPath, isCompact }) => {
  const { navigateByIndex } = useNavigateRoute();
  const { friendlyName } = useCustomRouteNames({ route: fullPath });

  const friendlyTitle = useMemo(
    () => (NavigationURLNames[`/${fullPath}` as NavigationURLs] || friendlyName) ?? '',
    [friendlyName, fullPath],
  );

  if (!title) return null;

  return (
    <>
      <FlexLayout direction='row' spacing={8} onClick={() => historyIndex && navigateByIndex(historyIndex)}>
        <Tooltip title={tooltip}>
          <span>
            <Body
              color={!anotherPath ? 'primary' : 'secondary'}
              weight={!anotherPath ? 'emphasis' : 'none'}
              key={title}
              size={isCompact ? 'small' : 'medium'}
              className={cn('cursor-pointer', historyIndex < 0 ? 'hover:text-color-text-accent' : '')}
            >
              {decodeURIComponent(friendlyTitle || capitalize(title))}
            </Body>
          </span>
        </Tooltip>
      </FlexLayout>
      {anotherPath && (
        <Body color='secondary' size={isCompact ? 'small' : 'medium'}>
          {' / '}
        </Body>
      )}
    </>
  );
};

export { AppHeaderBreadCrumb };

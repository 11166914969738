import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

import { AssetWalletActions } from './components/AssetWalletActions';
import { AssetWalletHeader } from './components/AssetWalletHeader';
import { AssetWalletInformation } from './components/AssetWalletInformation';

type Props = {
  asset: Asset;
};

const AssetWallet: React.FC<Props> = observer(({ asset }) => {
  const assetBalanceData = useAssetBalance(asset);
  const hasBalance = Big(assetBalanceData.all.balance).gt(0) || asset.code === 'TAUD';

  return (
    <Card className='p-16'>
      <FlexLayout direction='column' className='w-full' spacing={24}>
        <AssetWalletHeader asset={asset} />
        <AssetWalletInformation asset={asset} all={assetBalanceData.all} />
        <AssetWalletActions asset={asset} hasBalance={hasBalance} />
      </FlexLayout>
    </Card>
  );
});

AssetWallet.displayName = 'AssetWallet';

export { AssetWallet };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Information } from '@swyftx/aviary/icons/outlined';

import { APIKey } from '@shared/api';

import { DateTime } from 'luxon';

import { RevokeApiKeyModal } from './RevokeApiKeyModal';
import { getKeyType } from '../utils';

type ApiKeyScopesProps = {
  apiKey: APIKey;
};

export const ApiKeyDetailsModal: React.FC<ApiKeyScopesProps> = ({ apiKey }) => {
  const { t } = useTranslation('profile.apiKey');
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Modal
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      title={`${t('sections.activeKeys.scopes.header')} ${apiKey.label}`}
      triggerElement={
        <Button variant='outlined' leadingIcon={<Information />} size='sm'>
          Info
        </Button>
      }
    >
      <FlexLayout direction='column' spacing={16} className='p-24'>
        <FlexLayout direction='row' className='w-[100%] justify-between'>
          <Body weight='emphasis'>{t('sections.activeKeys.scopes.dateCreated')}</Body>
          <Body>{DateTime.fromMillis(apiKey.created).toLocaleString()}</Body>
        </FlexLayout>

        <FlexLayout direction='row' className='w-[100%] justify-between'>
          <Body weight='emphasis'>{t('sections.activeKeys.scopes.keyScope')}</Body>
          <Body>{getKeyType(apiKey)}</Body>
        </FlexLayout>

        <FlexLayout direction='column' spacing={8}>
          <Body weight='emphasis'>Permissions:</Body>
          {apiKey.scope
            .split(' ')
            .filter((scope) => scope !== 'offline_access')
            .map((scope) => (
              <FlexLayout direction='row' key={`scope-${scope}-${apiKey.id}`} spacing={8}>
                <Body color='secondary'>
                  {'\u2022'} {t(`sections.activeKeys.scopes.scopeToLookup.${scope.replace(/\./g, '_')}` as any)}
                </Body>
              </FlexLayout>
            ))}
        </FlexLayout>

        <FlexLayout direction='row' className='justify-end' spacing={8}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Close
          </Button>
          <RevokeApiKeyModal apiKey={apiKey} onClose={() => setOpen(false)} />
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';
import { Copy, Information } from '@swyftx/aviary/icons/outlined';

import { useCopy } from '@hooks/useCopy';

type Props = {
  title: string;
  value?: string;
  copyable?: boolean;
  infoMessage?: string;
  loading?: boolean;
};

const FiatDepositDetailsCardItem: React.FC<Props> = ({ title, value, copyable, loading, infoMessage }) => {
  const { onCopy, copied } = useCopy(2000);

  return (
    <Card className='bg-color-background-surface-secondary p-12' border={false}>
      <FlexLayout className='w-full' direction='column' spacing={16}>
        <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
          <FlexLayout direction='column' spacing={8}>
            <Utility variant='overline'>{title}</Utility>
            <Body weight='emphasis' color={copied ? 'accent' : 'primary'} loading={loading}>
              {copied ? 'Copied!' : value}
            </Body>
          </FlexLayout>
          {!loading && value && copyable && (
            <div>
              <Button variant='outlined' layout='icon' leadingIcon={<Copy />} onClick={() => onCopy(value, title)} />
            </div>
          )}
        </FlexLayout>

        {infoMessage && (
          <FlexLayout alignItems='start' spacing={8}>
            <div>
              <Information className='mt-4 h-16 w-16 text-color-text-accent' />
            </div>
            <Body size='small' color='accent'>
              <Body className='inline' weight='emphasis' size='small'>
                Important:{' '}
              </Body>
              {infoMessage}
            </Body>
          </FlexLayout>
        )}
      </FlexLayout>
    </Card>
  );
};

export { FiatDepositDetailsCardItem };

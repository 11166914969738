import { useMemo } from 'react';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { BalanceKey, getBalanceValue } from '@utils/balance';

import { useBaseAsset } from './useBaseAsset';

export type UseOwnedAssetOptions = {
  sortByValue?: boolean;
  balanceKey?: BalanceKey;
  assetTypeFilter?: AssetType;
  ignoreBalanceUpdates?: boolean;
  ignoreDustableAssets?: boolean;
};

export const useOwnedAssets = (options?: UseOwnedAssetOptions) => {
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const { balances } = UserStore.useUserStore;
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;

  const isNonDustableAsset = (asset: Asset) => {
    const assetBalance = balances[asset.id]!;
    const minOrderAmount = getMinimumOrderAmount(asset.id);

    return (
      !asset.delisting &&
      (Big(assetBalance.availableBalance).gt(minOrderAmount) || Big(assetBalance.stakingBalance).gt(minOrderAmount))
    );
  };

  const ownedAssets = useMemo(
    () => {
      const assets = assetService.getAssetList();
      const ownedCryptoAssets = assets.filter((asset) => {
        const balanceVal = parseFloat(getBalanceValue(balances?.[asset.id], options?.balanceKey || 'all'));
        return (
          balanceVal > 0 &&
          (!options?.assetTypeFilter || asset.assetType === options.assetTypeFilter) &&
          (!options?.ignoreDustableAssets || isNonDustableAsset(asset))
        );
      });

      if (options?.sortByValue) {
        const priceMap = new Map<number, number>(); // Adds a bit of processing efficiency
        return ownedCryptoAssets.sort((a, b) => {
          // Check our price map for the values of our balance, otherwise add them
          if (priceMap.get(a.id) === undefined) {
            const bal = balances?.[a.id];
            if (bal) {
              const amount = parseFloat(getBalanceValue(bal, options?.balanceKey || 'all'));
              const price = parseFloat(getRate(a).midPrice) * amount;
              priceMap.set(a.id, price);
            } else {
              priceMap.set(a.id, 0);
            }
          }
          if (priceMap.get(b.id) === undefined) {
            const bal = balances?.[b.id];
            if (bal) {
              const amount = parseFloat(getBalanceValue(bal, options?.balanceKey || 'all'));
              const price = parseFloat(getRate(b).midPrice) * amount;
              priceMap.set(b.id, price);
            } else {
              priceMap.set(b.id, 0);
            }
          }

          // Grab the values from our price map and sort by largest
          const aPrice = priceMap.get(a.id);
          const bPrice = priceMap.get(b.id);

          return (aPrice || 0) < (bPrice || 0) ? 1 : -1;
        });
      }

      return ownedCryptoAssets;
    },
    options?.ignoreBalanceUpdates ? [baseAsset, options] : [balances, baseAsset, options],
  );

  return ownedAssets;
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowLineOnlyRight: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M13.1719 17.2191C12.8784 17.5114 12.8775 17.9863 13.1697 18.2798C13.462 18.5733 13.9369 18.5743 14.2304 18.282L20.2804 12.257C20.4217 12.1162 20.5012 11.925 20.5012 11.7255C20.5012 11.526 20.4217 11.3348 20.2804 11.1941L14.2304 5.17007C13.9368 4.87781 13.462 4.87883 13.1697 5.17235C12.8774 5.46587 12.8785 5.94075 13.172 6.23301L17.9351 10.9756H4.75C4.33579 10.9756 4 11.3114 4 11.7256C4 12.1398 4.33579 12.4756 4.75 12.4756H17.9351L13.1719 17.2191Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowLineOnlyRight };

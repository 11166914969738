import { useEffect, useState } from 'react';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { useHighSlippage, useUniversalTradeUtilityStore } from '@hooks/Trade';
import { useLowLiquidity } from '@hooks/Trade/useLowLiquidity';

import { AppFeatureData, useIsFeatureEnabled } from 'src/config';
import { AssetRedenominationInformation } from 'src/config/featureFlags.data';

enum LimitedFunctionalityType {
  SendingReceiving = 'depositWithdraw',
  Sending = 'withdraw',
  Receiving = 'deposit',
  Trade = 'trade',
  Buy = 'buy',
  Sell = 'sell',
  LowLiquidity = 'lowLiquidity',
  HighSlippage = 'highSlippage',
  RedenominationInProgress = 'redenominationInProgress',
  RedenominationCompleted = 'redenominationCompleted',
}

export type LimitedFunctionalityItem = {
  assets: Asset[];
  limitedFunctionalityType: LimitedFunctionalityType;
  featureFlagData?: any; // some JSON value from Launchdarkly
};

export const checkAssetRedenomination = (
  asset: Asset,
  assetRedenominations: AssetRedenominationInformation[],
): { assetRedenomination: AssetRedenominationInformation; type: LimitedFunctionalityType } | null => {
  const assetRedenomination = assetRedenominations.find((a) => a.legacyAssetCode === asset.code);

  if (assetRedenomination) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime >= assetRedenomination.dateTimeStart && currentTime <= assetRedenomination.dateTimeEnd) {
      return { assetRedenomination, type: LimitedFunctionalityType.RedenominationInProgress };
    } else if (currentTime > assetRedenomination.dateTimeEnd) {
      return { assetRedenomination, type: LimitedFunctionalityType.RedenominationCompleted };
    }
  }
  return null;
};

const useAssetLimitedFunctionality = (assets: Asset[], assetsToIgnore: number[] = []) => {
  const [limitedFunctionalityItems, setLimitedFunctionalityItems] = useState<LimitedFunctionalityItem[]>([]);
  const { highSlippageTrades } = useUniversalTradeUtilityStore();
  const { getIsLowLiquidity } = useLowLiquidity();
  const { getIsHighSlippage } = useHighSlippage();
  const { getFeatureData } = useIsFeatureEnabled();
  const featureFlagaAssetRedenominations = getFeatureData(AppFeatureData.AssetRedenominationInformation);

  useEffect(() => {
    if (!assets.length) {
      setLimitedFunctionalityItems([]);
      return;
    }

    let limitedItems: LimitedFunctionalityItem[] = [];

    assets.forEach((asset) => {
      const redenomAsset = checkAssetRedenomination(asset, featureFlagaAssetRedenominations);

      if (redenomAsset) {
        limitedItems.push({
          assets: [asset],
          limitedFunctionalityType: redenomAsset.type,
          featureFlagData: redenomAsset.assetRedenomination,
        });
      }

      if (getIsLowLiquidity(asset)) {
        const lowLiquidityEntry = limitedItems.find(
          (li) => li.limitedFunctionalityType === LimitedFunctionalityType.LowLiquidity,
        );

        if (!lowLiquidityEntry) {
          limitedItems.push({
            assets: [asset],
            limitedFunctionalityType: LimitedFunctionalityType.LowLiquidity,
          });
        } else {
          lowLiquidityEntry.assets.push(asset);
        }
      }

      if (getIsHighSlippage(asset)) {
        const highSlippageEntry = limitedItems.find(
          (li) => li.limitedFunctionalityType === LimitedFunctionalityType.HighSlippage,
        );

        if (!highSlippageEntry) {
          limitedItems.push({
            assets: [asset],
            limitedFunctionalityType: LimitedFunctionalityType.HighSlippage,
          });
        } else {
          highSlippageEntry.assets.push(asset);
        }
      }

      if (assetsToIgnore.includes(asset.id)) {
        return;
      }

      if (asset.buyDisabled && !asset.sellEnabled) {
        const tradeEntry = limitedItems.find((li) => li.limitedFunctionalityType === LimitedFunctionalityType.Trade);

        if (!tradeEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: LimitedFunctionalityType.Trade });
        } else {
          tradeEntry.assets.push(asset);
        }
      }

      if (asset.buyDisabled && asset.sellEnabled) {
        const buyEntry = limitedItems.find((li) => li.limitedFunctionalityType === LimitedFunctionalityType.Buy);

        if (!buyEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: LimitedFunctionalityType.Buy });
        } else {
          buyEntry.assets.push(asset);
        }
      }

      if (!asset.buyDisabled && !asset.sellEnabled) {
        const buyEntry = limitedItems.find((li) => li.limitedFunctionalityType === LimitedFunctionalityType.Sell);

        if (!buyEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: LimitedFunctionalityType.Sell });
        } else {
          buyEntry.assets.push(asset);
        }
      }

      if (!assetService.canWithdrawAndDepositAsset(asset)) {
        const canWithdraw = assetService.canWithdrawAsset(asset);
        const canDeposit = assetService.canDepositAsset(asset);
        let limitedType = LimitedFunctionalityType.SendingReceiving;

        if (canWithdraw && !canDeposit) limitedType = LimitedFunctionalityType.Receiving;
        if (!canWithdraw && canDeposit) limitedType = LimitedFunctionalityType.Sending;

        const sendReceiveEntry = limitedItems.find((li) => li.limitedFunctionalityType === limitedType);

        if (!sendReceiveEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: limitedType });
        } else {
          sendReceiveEntry.assets.push(asset);
        }
      }
    });

    setLimitedFunctionalityItems(limitedItems);
  }, [
    assets,
    assetsToIgnore,
    featureFlagaAssetRedenominations,
    getIsHighSlippage,
    getIsLowLiquidity,
    highSlippageTrades,
  ]);

  return {
    limitedFunctionalityItems,
  };
};

export { useAssetLimitedFunctionality, LimitedFunctionalityType };

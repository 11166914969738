import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const PayID: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M17.3781 2H6.49239C4.01229 2 2 4.02339 2 6.522V17.478C2 19.9766 4.01229 22 6.49239 22H17.3781C19.8582 22 21.8705 19.9766 21.8705 17.478V6.51828C21.8705 4.02339 19.8582 2 17.3781 2ZM10.4204 6.86356L10.9848 6.79302C11.033 6.78931 12.1691 6.65194 13.4872 6.93039C15.3101 7.3165 16.5984 8.30035 17.2184 9.77798L16.0341 10.2792C15.1913 8.26323 12.8003 8.03676 11.7051 8.04418V14.0401H10.4204V6.86356ZM6.36616 7.68405C6.36616 7.14943 6.79683 6.71877 7.32775 6.71877C7.85867 6.71877 8.28935 7.14943 8.28935 7.68405C8.28935 8.21868 7.85867 8.64934 7.32775 8.64934C6.79683 8.64934 6.36616 8.21868 6.36616 7.68405ZM17.534 13.1862C17.4635 13.6428 17.3595 14.0549 17.2259 14.3742C16.3571 16.431 14.3262 17.4743 11.1964 17.4743H6.63719V10.3386H7.92179V16.1823H11.1927C13.7582 16.1823 15.3918 15.4026 16.0378 13.8693C16.112 13.6911 16.1789 13.4535 16.2271 13.1862H15.1616L16.9957 10.8695L18.8335 13.1899L17.534 13.1862Z'
      fill='currentColor'
    />
  </svg>
);

export { PayID };

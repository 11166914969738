import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Paper } from '@swyftx/aviary/icons/outlined';

interface Props {
  onClose: () => void;
}

export const OrderNotFound: React.FC<Props> = (props) => {
  const { onClose } = props;

  return (
    <FlexLayout direction='column' className='justify-between' spacing={16}>
      <FlexLayout direction='column' className='h-[40vh] items-center justify-center' spacing={8}>
        <Paper className='h-64 w-64 text-color-text-accent' />
        <FlexLayout direction='column' spacing={0} className='items-center'>
          <Body weight='emphasis' size='large'>
            Order not found
          </Body>
          <Body color='secondary'>Please try again or contact support</Body>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='row' className='justify-end px-12'>
        <Button size='lg' variant='outlined' onClick={onClose} className='w-full sm:w-auto'>
          Back
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import { api } from '@shared/api';
import { Asset } from '@shared/api/@types/markets';
import { UpdateOrderParams } from '@shared/api/@types/trade';
import { OrderStatusEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { Big } from '@shared/safe-big';

const LOG_TAG = 'tradeService';

export interface AlterOrderParams {
  isBuy: boolean;
  triggerPrice?: string;
  primary: {
    asset: Asset;
    amount?: string;
  };
  secondary: {
    asset: Asset;
    amount?: string;
  };
  limit?: {
    asset: Asset;
    amount?: string;
  };
}

export interface UpdatedOrderAmount {
  exchangeRate: string | Big;
  priceRate: string;
  feePerUnit: number;
  primaryQuantity?: string;
  secondaryQuantity?: string;
}

export interface UpdatedOrderError {
  error: {
    type: 'generic' | 'depth' | 'min' | 'max';
    detail: string;
  };
}

// These should match the OrderTypeEnum on the backend!
export enum OrderType {
  MarketBuy = 1,
  MarketSell = 2,
  TriggerBuy = 3,
  TriggerSell = 4,
  StopBuy = 5,
  StopSell = 6,
  DustSell = 8,
  OTCBuy = 9,
  OTCSell = 10,
}

const isBuy = (orderType: OrderType) =>
  [OrderType.MarketBuy, OrderType.TriggerBuy, OrderType.StopBuy, OrderType.OTCBuy].includes(orderType);

const isSell = (orderType: OrderType) =>
  [OrderType.MarketSell, OrderType.TriggerSell, OrderType.StopSell, OrderType.OTCSell].includes(orderType);

const orderStatusMessage: { [key: number]: { title: string; description: string } } = {
  [OrderStatusEnum.INSUFFICIENT_BALANCE]: {
    title: 'wallet:transactions.status.order.insufficientBalance.title',
    description: 'wallet:transactions.status.order.insufficientBalance.description',
  },
  [OrderStatusEnum.USER_CANCELLED]: {
    title: 'wallet:transactions.status.order.userCancelled.title',
    description: 'wallet:transactions.status.order.userCancelled.description',
  },
  [OrderStatusEnum.FAILED]: {
    title: 'wallet:transactions.status.order.failed.title',
    description: 'wallet:transactions.status.order.failed.description',
  },
  [OrderStatusEnum.SYSTEM_CANCELLED]: {
    title: 'wallet:transactions.status.order.systemCancelled.title',
    description: 'wallet:transactions.status.order.systemCancelled.description',
  },
  [OrderStatusEnum.FAILED_MIN_AMOUNT]: {
    title: 'wallet:transactions.status.order.failedMinAmount.title',
    description: 'wallet:transactions.status.order.failedMinAmount.description',
  },
  [OrderStatusEnum.REFUNDED]: {
    title: 'wallet:transactions.status.order.refunded.title',
    description: 'wallet:transactions.status.order.refunded.description',
  },
};

const updateTriggerOrder = async (orderRequest: UpdateOrderParams) => {
  try {
    const res = await api.endpoints.updateOrder({ data: orderRequest });
    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

export const tradeService = {
  isBuy,
  isSell,
  orderStatusMessage,
  updateTriggerOrder,
};

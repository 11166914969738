import React, { useCallback } from 'react';

import { TransactionHistoryItem } from '@shared/services';
import { UIStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { AviaryEditTriggerOrdersModal } from './aviary/AviaryEditTriggerOrdersModal';
import { EditTriggerOrderProvider } from './aviary/EditTriggerOrder.context';

type Props = {
  order: TransactionHistoryItem;
  onClose: () => void;
  onSuccess?: () => void;
};

const EditTriggerOrdersModal: React.FC<Props> = observer(({ onClose, order, onSuccess }) => {
  const { refreshTransactions, setRefreshTransactions } = UIStore.useUIStore;

  const handleSuccess = useCallback(() => {
    setRefreshTransactions(!refreshTransactions);
    if (onSuccess) onSuccess();
  }, [onSuccess, refreshTransactions, setRefreshTransactions]);

  return (
    <EditTriggerOrderProvider order={order} onClose={onClose} onSuccess={handleSuccess}>
      <AviaryEditTriggerOrdersModal onClose={onClose} />
    </EditTriggerOrderProvider>
  );
});

export { EditTriggerOrdersModal };

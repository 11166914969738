import { useCallback } from 'react';

import { api } from '@shared/api';

import { useFetchTriggerNotificationsCache } from './useFetchTriggerNotifications/useFetchTriggerNotificationsCache';

const ITEMS_PER_REQUEST = 50;

const useDismissTriggerNotifications = () => {
  const { invalidateCache } = useFetchTriggerNotificationsCache();

  const dismissNotifications = useCallback(
    async (ids?: number[]) => {
      if (!ids?.length) return;

      const dismissPromises = [];

      const requestsCount = Math.ceil(ids.length / ITEMS_PER_REQUEST);

      for (let i = 0; i < requestsCount; i++) {
        const batch = ids.slice(i * ITEMS_PER_REQUEST, (i + 1) * ITEMS_PER_REQUEST);
        dismissPromises.push(
          api.endpoints.dismissTriggerNotifications({
            data: { triggers: batch },
          }),
        );
      }

      await Promise.all(dismissPromises);

      invalidateCache();
    },
    [invalidateCache],
  );

  return {
    dismissNotifications,
  };
};

export { useDismissTriggerNotifications };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const OlympusPro: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 2.25C4.15279 2.25 2.25 4.15279 2.25 6.5C2.25 8.84721 4.15279 10.75 6.5 10.75C8.84721 10.75 10.75 8.84721 10.75 6.5C10.75 4.15279 8.84721 2.25 6.5 2.25ZM3.75 6.5C3.75 4.98121 4.98121 3.75 6.5 3.75C8.01879 3.75 9.25 4.98121 9.25 6.5C9.25 8.01879 8.01879 9.25 6.5 9.25C4.98121 9.25 3.75 8.01879 3.75 6.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 13.25C4.15279 13.25 2.25 15.1528 2.25 17.5C2.25 19.8472 4.15279 21.75 6.5 21.75C8.84721 21.75 10.75 19.8472 10.75 17.5C10.75 15.1528 8.84721 13.25 6.5 13.25ZM3.75 17.5C3.75 15.9812 4.98121 14.75 6.5 14.75C8.01879 14.75 9.25 15.9812 9.25 17.5C9.25 19.0188 8.01879 20.25 6.5 20.25C4.98121 20.25 3.75 19.0188 3.75 17.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.25 6.5C13.25 4.15279 15.1528 2.25 17.5 2.25C19.8472 2.25 21.75 4.15279 21.75 6.5C21.75 8.84721 19.8472 10.75 17.5 10.75C15.1528 10.75 13.25 8.84721 13.25 6.5ZM17.5 3.75C15.9812 3.75 14.75 4.98121 14.75 6.5C14.75 8.01879 15.9812 9.25 17.5 9.25C19.0188 9.25 20.25 8.01879 20.25 6.5C20.25 4.98121 19.0188 3.75 17.5 3.75Z'
      fill='currentColor'
    />
    <path
      d='M18.251 14.2383C18.251 13.8241 17.9152 13.4883 17.501 13.4883C17.0868 13.4883 16.751 13.8241 16.751 14.2383V16.752H14.2324C13.8182 16.752 13.4824 17.0878 13.4824 17.502C13.4824 17.9162 13.8182 18.252 14.2324 18.252H16.751V20.7683C16.751 21.1825 17.0868 21.5183 17.501 21.5183C17.9152 21.5183 18.251 21.1825 18.251 20.7683V18.252H20.7684C21.1826 18.252 21.5184 17.9162 21.5184 17.502C21.5184 17.0878 21.1826 16.752 20.7684 16.752H18.251V14.2383Z'
      fill='currentColor'
    />
  </svg>
);

export { OlympusPro };

import React from 'react';

import { Button, Notification, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';
import { DepositAddressDetails } from '@shared/api/@types/trade';
import { DepositMethodEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useDeposit } from '@hooks/Deposit/useDeposit';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DepositFundsInformationItem } from './DepositFundsInformationItem';
import { DepositFundsModal } from './DepositFundsModal';

interface Props {
  asset: Asset;
  onClose: () => void;
}

const BankTransferDepositModal: React.FC<Props> = observer(({ asset, onClose }) => {
  const { isUserVerified } = UserStore.useUserStore;

  const { navigate } = useNavigateRoute();
  const { depositDescription, refetchAddressDetails, bankAddressDetails } = useDeposit({
    asset,
    transferType: asset.code === 'NZD' ? DepositMethodEnum.NZD_BANK_TRANSFER : DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
  });

  const openVerifyPage = () => {
    navigate(NavigationURLs.Onboarding);
  };

  return (
    <DepositFundsModal
      id='bank_transfer_deposit_modal'
      title='Deposit via bank transfer'
      limits={depositDescription.limits}
      fees={depositDescription.fees}
      onClose={onClose}
    >
      <>
        {!isUserVerified() && (
          <Notification
            severity='info'
            title='Verify account to deposit via bank transfer'
            sx={{
              marginTop: 2,
              marginBottom: 2,
              '.MuiAlertTitle-root': {
                marginLeft: 1,
                fontSize: 14,
                fontWeight: 600,
                color: 'primary.main',
              },
            }}
          >
            <Typography marginLeft={1} fontSize={14} fontWeight={400}>
              Your account is not verified. Please verify your account to continue with the deposit.
            </Typography>
            <Button color='primary' onClick={openVerifyPage}>
              Verify now
            </Button>
          </Notification>
        )}

        {isUserVerified() && (
          <>
            <Notification title='You may experience delays from your bank' severity='warning' sx={{ mb: 2 }}>
              Deposits may be subject to a temporary, 24hr hold for the purpose of conducting additional compliance
              checks by your banking institution.
            </Notification>

            <Typography color='text.primary' fontSize={16} fontWeight={600} marginBottom={1}>
              Deposit Information
            </Typography>

            <Typography color='text.secondary' fontSize={14} fontWeight={400}>
              Deposit funds directly from your bank account using a dedicated account number and BSB.
            </Typography>

            {!bankAddressDetails && (
              <Notification
                severity='warning'
                title='There was an issue generating deposit details'
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  '.MuiAlertTitle-root': {
                    marginLeft: 1,
                    color: 'warning.dark',
                  },
                }}
              >
                <Typography marginLeft={1} fontSize={14} fontWeight={400}>
                  Please try again, and if you continue to experience issues, contact support.
                </Typography>

                <Button color='warning' sx={{ color: 'warning.dark' }} onClick={refetchAddressDetails}>
                  Retry generating deposit details
                </Button>
              </Notification>
            )}

            {bankAddressDetails && (
              <>
                <DepositFundsInformationItem
                  title='Account name'
                  value={depositDescription.extraBankAddressDetails?.accountName || ''}
                />

                {depositDescription.addressDetails?.map(({ title, key }) => (
                  <DepositFundsInformationItem
                    key={`deposit-information-${title}-${key}`}
                    title={title}
                    value={bankAddressDetails ? bankAddressDetails[key as keyof DepositAddressDetails] : ''}
                  />
                ))}

                <DepositFundsInformationItem
                  title='Reference'
                  value={depositDescription.extraBankAddressDetails?.reference ?? ''}
                />
              </>
            )}
          </>
        )}
      </>
    </DepositFundsModal>
  );
});

BankTransferDepositModal.displayName = 'BankTransferDepositModal';

export { BankTransferDepositModal };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import AppHeader from '@global-components/AppHeader';
import { DemoModeBanner } from '@global-components/Banners';
import { DynamicGrid } from '@global-components/DynamicGrid/DynamicGrid';
import MarketTicker from '@global-components/MarketTicker';
import Navigation from '@global-components/Navigation';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router';

const SwyftxAppShell = observer(() => {
  const { userProfile } = UserStore.useUserStore;

  // If there is no user profile just render the outlet (PrivateRoute) which will handle redirecting if
  // we need to log in
  if (!userProfile) return <Outlet />;

  return (
    <FlexLayout
      direction='column'
      className='relative h-full w-full overflow-hidden bg-color-background-pageBG'
      alignItems='start'
      justifyContent='start'
    >
      <AppHeader />
      <FlexLayout className='h-[calc(100%-4rem)] w-full'>
        <FlexLayout direction='column' className='w-full overflow-hidden'>
          <MarketTicker />
          <FlexLayout className=' h-full w-full overflow-hidden'>
            <Navigation />
            <FlexLayout className='w-full' direction='column'>
              <DemoModeBanner />
              <DynamicGrid>
                <Outlet />
              </DynamicGrid>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxAppShell };

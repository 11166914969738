import { GetProfileResponse, UserProfile } from '@shared/api';
import { Methods } from '@shared/api/lib/rest';
import {
  AnalyticsEvent,
  AnalyticsEventType,
  RequestCompleteEvent,
  subscribeToEvent,
  SwyftxEvent,
  unsubscribeFromEvent,
} from '@shared/events';

// Add dataLayer to the window object, GTM uses it to pass information to tags
// for more information https://developers.google.com/tag-platform/tag-manager/web/datalayer
declare global {
  interface Window {
    dataLayer: any[];
  }
}

/** Logged when a user creates an account(POST: /auth/signup/) */
const createdAccount = (request: RequestCompleteEvent) => {
  const args = request.response as UserProfile;
  window.dataLayer.push({
    event: 'Signup',
    event_category: 'Signup',
    user_id: args.profile.intercom?.uuid,
  });
};

/** Logged when a user logs in(POST: /auth/signup/) */
const loggedIn = (request: RequestCompleteEvent) => {
  if ((request.response as UserProfile).profile) {
    const args = request.response as UserProfile;
    window.dataLayer.push({ user_id: args.profile.intercom?.uuid });
  }
};

/** Logged when a user refreshes their profile (GET: /user/) */
const refreshedProfile = (request: RequestCompleteEvent) => {
  const args = request.response as GetProfileResponse;
  window.dataLayer.push({ user_id: args.user.profile.intercom?.uuid });
};

/**
 * Logs an event to GTM
 *
 * @param event event formatted for GTM analytics event containing event type and data
 * @returns
 */
const onEvent = (event: AnalyticsEvent<any>) => {
  if (event.type === AnalyticsEventType.RequestComplete) {
    const data = event.data as RequestCompleteEvent;

    if (data.path === '/auth/signup/' && data.method === Methods.POST) {
      createdAccount(data);
    } else if (data.path === '/auth/login/' && data.method === Methods.POST) {
      loggedIn(data);
    } else if (data.path === '/auth/mfa/' && data.method === Methods.POST) {
      loggedIn(data);
    } else if (data.path === '/user/' && data.method === Methods.GET) {
      refreshedProfile(data);
    }
  }
};

/** Initializes GTM */
const init = () => {
  subscribeToEvent(SwyftxEvent.Analytics, onEvent);
};

const cleanup = () => {
  unsubscribeFromEvent(SwyftxEvent.Analytics, onEvent);
};

const GTMService = {
  init,
  cleanup,
};

export default GTMService;

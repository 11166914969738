import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { api } from '@shared/api';
import { GeneratePresignedUrlPayload, PermittedFileType, UserDocumentDto } from '@shared/api/@types/compliance';
import { UIStore } from '@shared/store';

import { SourceOfWealthData } from '@routes/SourceOfWealth/types/SourceOfWealth.types';

import { useAsync } from 'react-use';

import {
  checkDocumentsAreValid,
  DocumentUploadFlow,
  generateDocumentSuggestions,
  uploadFileToS3,
} from './supportingDocumentsUtils';

export type FileInProgress = { file: File; progress: number; processing?: boolean };
export type DocumentUuid = string;
export type UploadedFile = { file: File; expiresAt: number; uuid: DocumentUuid };
export type UserDocumentWithProcessing = UserDocumentDto & { processing?: boolean };

interface SupportingDocumentsContextType {
  filesInProgress: FileInProgress[];
  setFilesInProgress: React.Dispatch<React.SetStateAction<FileInProgress[]>>;
  uploadedFiles: UploadedFile[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  previousFiles: UserDocumentWithProcessing[];
  setPreviousFiles: React.Dispatch<React.SetStateAction<UserDocumentWithProcessing[]>>;
  loading: boolean;
  selectFilesEnabled: boolean;
  continueDisabled: boolean;
  deleteFile: (uploadedFile: DocumentUuid) => Promise<void>;
  onDrop: (newFiles: File[]) => Promise<void>;
  documentUploadSuggestions: (verificationData: Partial<SourceOfWealthData>) => string[];
  backDisabled: boolean;
}

export const SupportingDocumentsContext = createContext<SupportingDocumentsContextType | undefined>(undefined);

const MAX_FILES = 10;

export const SupportingDocumentsProvider: React.FC<PropsWithChildren & { flow: DocumentUploadFlow }> = ({
  children,
  flow,
}) => {
  const { addToastMessage } = UIStore.useUIStore;

  const [filesInProgress, setFilesInProgress] = useState<FileInProgress[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [previousFiles, setPreviousFiles] = useState<UserDocumentDto[]>([]);
  const [backDisabled, setBackDisabled] = useState<boolean>(false);

  const fetchPreviousUploads = async () => {
    if (flow === 'entityOnboarding') {
      const response = await api.endpoints.getEntityDocuments();
      return setPreviousFiles(response.data);
    }
    const response = await api.endpoints.getSowDocuments();
    setPreviousFiles(response.data);
  };

  const { loading } = useAsync(fetchPreviousUploads);

  const generatePresignedUrl = useCallback(
    async (data: GeneratePresignedUrlPayload) => {
      if (flow === DocumentUploadFlow.EntityOnboarding) {
        const response = await api.endpoints.generateEntityDocumentUploadUrl({ data });
        return response.data;
      }
      const response = await api.endpoints.generateDocumentUploadUrl({ data });
      return response.data;
    },
    [flow],
  );

  const selectFilesEnabled = useMemo(
    () => uploadedFiles.length + previousFiles.length < MAX_FILES,
    [previousFiles.length, uploadedFiles.length],
  );

  /**
   * Deletes a file from the server and removes it from the list of uploaded files
   */
  const deleteFile = useCallback(
    async (documentUuid: DocumentUuid) => {
      try {
        if (flow === DocumentUploadFlow.EntityOnboarding) {
          await api.endpoints.deleteUploadedEntityDocument({ params: { id: documentUuid } });
        }
        if (flow === DocumentUploadFlow.SourceOfWealth) {
          await api.endpoints.deleteUploadedDocument({ params: { id: documentUuid } });
        }

        setUploadedFiles(uploadedFiles.filter((files) => files.uuid !== documentUuid));
        setPreviousFiles(previousFiles.filter((files) => files.id !== documentUuid));
      } catch (error) {
        addToastMessage({
          severity: 'error',
          message: `File deletion error: ${error}`,
        });
      }
    },
    [addToastMessage, flow, previousFiles, uploadedFiles],
  );

  const setNewPercentageForFile = useCallback((fileInProgress: FileInProgress) => {
    const { file, progress: percentage } = fileInProgress;
    setFilesInProgress((prevProgresses) =>
      prevProgresses.map((fp) => (fp.file === file ? { file, progress: percentage } : fp)),
    );
  }, []);

  const continueDisabled = useMemo(() => {
    const hasPending = previousFiles.some((file) => file.status === 'pending');
    return uploadedFiles.length + previousFiles.length < 1 || !!filesInProgress.length || hasPending;
  }, [filesInProgress.length, previousFiles, uploadedFiles.length]);

  const onDrop = useCallback(
    async (newFiles: File[]) => {
      if (!newFiles?.length) return;

      if (newFiles.length + uploadedFiles.length + previousFiles.length > MAX_FILES) {
        addToastMessage({
          severity: 'error',
          message: `You cannot upload more than ${MAX_FILES} files.`,
        });
        return;
      }

      const errorMessage = checkDocumentsAreValid(newFiles, flow);
      if (errorMessage !== undefined) {
        addToastMessage({
          severity: 'error',
          message: errorMessage,
        });
        return;
      }
      setBackDisabled(true);
      setFilesInProgress((prevProgresses) => [...prevProgresses, ...newFiles.map((file) => ({ file, progress: 0 }))]);

      const tryGenerateUrl = async (file: File) => {
        try {
          // Generate presigned URL
          return await generatePresignedUrl({
            filename: file.name,
            size: file.size,
            fileType: file.type as PermittedFileType,
          });
        } catch (error) {
          setBackDisabled(false);
          return null;
        }
      };

      const uploadPromises = [];

      for (const file of newFiles) {
        setNewPercentageForFile({ file, progress: 15 });

        const generatedUrl = await tryGenerateUrl(file);
        if (!generatedUrl) {
          return;
        }

        const { url, id, expiresAt } = generatedUrl;

        const tryUploadFile = uploadFileToS3(url, file, (progress) => setNewPercentageForFile({ file, progress }))
          .then(async () => {
            setFilesInProgress((prev) =>
              prev.map((prevFile) => (prevFile.file === file ? { ...prevFile, processing: true } : prevFile)),
            );
            if (flow === 'sourceOfWealth') {
              await api.endpoints.processUploadedDocument({ params: { id } });
            }
            if (flow === 'entityOnboarding') {
              await api.endpoints.processEntityUploadedDocument({ params: { id } });
            }

            setNewPercentageForFile({ file, progress: 100 });
            setUploadedFiles((prevFilesAndIds) => [...prevFilesAndIds, { file, uuid: id, expiresAt }]);
            setFilesInProgress((prevProgresses) => prevProgresses.filter((fp) => fp.file !== file));
          })
          .catch(async (error) => {
            addToastMessage({
              severity: 'error',
              message: `File upload error: ${error}`,
            });
            const filesInS3 =
              flow === 'sourceOfWealth'
                ? await api.endpoints.getSowDocuments()
                : await api.endpoints.getEntityDocuments();
            const foundFile = filesInS3.data.find((s3File) => s3File.id === id);
            if (foundFile) {
              setFilesInProgress((prevProgresses) => prevProgresses.filter((fp) => fp.file !== file));
              setPreviousFiles((prev) => [foundFile, ...prev]);
            }
            setBackDisabled(false);
          });

        uploadPromises.push(tryUploadFile);
      }
      await Promise.all(uploadPromises);

      setBackDisabled(false);
    },
    [addToastMessage, flow, generatePresignedUrl, previousFiles.length, setNewPercentageForFile, uploadedFiles.length],
  );

  const documentUploadSuggestions = useCallback(
    (verificationData: Partial<SourceOfWealthData>) =>
      generateDocumentSuggestions(verificationData.sourcesOfWealth ?? [], verificationData.sourcesOfWealthOther),
    [],
  );

  return (
    <SupportingDocumentsContext.Provider
      value={{
        filesInProgress,
        setFilesInProgress,
        previousFiles,
        setPreviousFiles,
        uploadedFiles,
        setUploadedFiles,
        deleteFile,
        loading,
        selectFilesEnabled,
        onDrop,
        continueDisabled,
        documentUploadSuggestions,
        backDisabled,
      }}
    >
      {children}
    </SupportingDocumentsContext.Provider>
  );
};

import { useEffect, useRef, useState } from 'react';

import { api, Asset } from '@shared/api';
import { PortfolioHistoryResponse } from '@shared/api/@types/history';
import { RatesStore } from '@shared/store';
import { formatDate } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { LineChartData, LineChartDatum } from '../LineChart.types';

const fetchPortfolioHistory = async (assetId?: number): Promise<PortfolioHistoryResponse> => {
  const { data } = assetId
    ? await api.endpoints.getAssetPortfolioHistory({
        params: {
          asset: assetId,
        },
      })
    : await api.endpoints.getPortfolioHistory();
  return data;
};

/**
 * OVERALL PORTFOLIO LINE CHART DATA
 */
export const usePortfolioLineChartData = (
  startDate: Date | null,
  endDate: Date | null,
  assetId?: number,
  reloadTrigger?: any,
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [noDataYet, setNoDataYet] = useState<boolean>(false);
  const { convertRate } = RatesStore.useRatesStore;
  const countryAsset = useCountryAsset();
  const baseAsset = useBaseAsset();

  const [formattedData, setFormattedData] = useState<LineChartData>();
  const [filteredFormattedData, setFilteredFormattedData] = useState<LineChartData>();
  const [data, setData] = useState<PortfolioHistoryResponse['data']>();

  useEffect(() => {
    setLoading(true);
    setError(false);
    (async () => {
      api.endpoints.getPortfolioHistory.resetCache();
      api.endpoints.getAssetPortfolioHistory.resetCache();

      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });

      try {
        const { data: res } = await fetchPortfolioHistory(assetId);
        const formattedRes: LineChartData = res.map((datum) => ({
          time: new Date(datum.time),
          value:
            baseAsset && countryAsset && baseAsset.id !== countryAsset.id
              ? convertRate(countryAsset, baseAsset, datum.value).toNumber()
              : datum.value,
        }));

        setNoDataYet(false);
        setData(res);
        setFormattedData(formattedRes);
      } catch (e) {
        setFilteredFormattedData(undefined);
        setFormattedData(undefined);
        setError(true);
      }
      setLoading(false);
    })();
  }, [assetId, baseAsset, convertRate, countryAsset, reloadTrigger]);

  useEffect(() => {
    if (formattedData) {
      const filteredData = formattedData?.filter(
        (datum) =>
          (startDate ? datum.time.getTime() > startDate.getTime() : true) &&
          (endDate ? datum.time.getTime() < endDate.getTime() : true),
      );

      setFilteredFormattedData(filteredData);
    }
  }, [formattedData, startDate, endDate]);

  return { noDataYet, data, formattedData: filteredFormattedData, loading, error };
};

/**
 * BULK ASSET PORTFOLIO LINE CHART OVERLAY DATA
 */
export type TimeOverlayMap = Record<string, LineChartDatum>;

export const usePortfolioAssetLineOverlayData = (
  assets: Array<Asset>,
  startDate: Date | null,
  endDate: Date | null,
  reloadTrigger?: any,
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [dataMap, setDataMap] = useState<Record<number, LineChartData>>({}); // Trying to optimize fetches with a map
  // Handles date filtering from existing data in a separate effect as double map id to date to data
  // (For optimizing date fills of non-existent data that's present on overview)
  const [filteredDataMap, setFilteredDataMap] = useState<Record<number, TimeOverlayMap>>({});
  const { convertRate } = RatesStore.useRatesStore;
  const countryAsset = useCountryAsset();
  const baseAsset = useBaseAsset();

  // We don't want to cause a rerender from updating formatted data from assets changing
  const usedAssetsMap = useRef(
    assets.reduce<Record<number, boolean>>((acc, asset) => {
      acc[asset.id] = true;
      return acc;
    }, {}),
  );

  useEffect(() => {
    (async () => {
      try {
        usedAssetsMap.current = assets.reduce<Record<number, boolean>>((acc, asset) => {
          acc[asset.id] = true;
          return acc;
        }, {});

        const mapBuff: Record<number, LineChartData> = { ...dataMap };

        setLoading(true);
        setError(false);
        await Promise.all(
          assets.map(async (asset) => {
            if (!dataMap[asset.id]) {
              const { data: res } = await fetchPortfolioHistory(asset.id);
              const formattedData: LineChartData = res.map((datum) => ({
                time: new Date(datum.time),
                value:
                  baseAsset && countryAsset && baseAsset.id !== countryAsset.id
                    ? convertRate(countryAsset, baseAsset, datum.value).toNumber()
                    : datum.value,
              }));
              mapBuff[asset.id] = formattedData;
            }
          }),
        );
        setDataMap(mapBuff);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, baseAsset, convertRate, countryAsset, reloadTrigger]);

  useEffect(() => {
    if (dataMap) {
      const dateFilteredMap = Object.entries(dataMap).reduce<Record<number, TimeOverlayMap>>(
        (acc, [assetId, formattedData]) => {
          const parsedAssetId = parseInt(assetId, 10);
          const currentMap = { ...acc };

          if (usedAssetsMap.current[parsedAssetId]) {
            const safeFormattedData = formattedData ? [...formattedData] : undefined;
            if (safeFormattedData) {
              const filteredData = safeFormattedData?.filter(
                (datum) =>
                  (startDate ? datum.time.getTime() > startDate.getTime() : true) &&
                  (endDate ? datum.time.getTime() < endDate.getTime() : true),
              );
              const timeMap = filteredData.reduce<TimeOverlayMap>((timeMapAcc, datum) => {
                const timeMapCopy = { ...timeMapAcc };
                const timeKey = formatDate(datum.time);
                timeMapCopy[timeKey] = datum;
                return timeMapCopy;
              }, {});
              currentMap[parsedAssetId] = timeMap;
            }
          }
          return currentMap;
        },
        {},
      );

      setFilteredDataMap(dateFilteredMap);
    }
  }, [dataMap, startDate, endDate]);

  return { overlayDataMap: dataMap, filteredOverlayDataMap: filteredDataMap, loading, error };
};

import React, { PropsWithChildren } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

type Props = {
  className?: string;
  onScroll?: (e: Event) => void;
};

const ScrollView: React.FC<PropsWithChildren<Props>> = ({ className, children, onScroll }) => (
  <OverlayScrollbarsComponent
    className={className}
    events={{
      scroll: (_, e) => {
        if (onScroll) onScroll(e);
      },
    }}
    options={{ scrollbars: { visibility: 'auto', autoHide: 'leave', autoHideDelay: 400 } }}
  >
    {children}
  </OverlayScrollbarsComponent>
);

export { ScrollView };

import { useEffect, useMemo, useState } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useCountryAsset } from './useCountryAsset';

const useGainersAndLosers = (assets?: Asset[]) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { getRateOnce } = RatesStore.useRatesStore;
  const [allAssets, setAllAssets] = useState<Asset[]>(assets || assetService.getActiveAssetList());
  const [gainers, setGainers] = useState<Asset[]>([]);
  const [losers, setLosers] = useState<Asset[]>([]);
  const countryAsset = useCountryAsset();

  useEffect(() => setAllAssets(assets || assetService.getActiveAssetList()), [assets]);

  useEffect(() => {
    if (!allAssets?.length) return;

    const positiveMovers = allAssets.filter((a) => {
      const rate = getRateOnce(a);
      return Big(rate.dailyPriceChange).gte(0) && a.code !== countryAsset?.code;
    });

    positiveMovers.sort((a, b) => {
      const aRate = Big(getRateOnce(a).dailyPriceChange || '');
      const bRate = Big(getRateOnce(b).dailyPriceChange || '');
      return bRate.cmp(aRate);
    });

    setGainers(positiveMovers);

    setLosers(
      allAssets
        .filter((a) => {
          const rate = getRateOnce(a);
          return Big(rate.dailyPriceChange).lt(0) && a.code !== countryAsset?.code;
        })
        .sort((a, b) => {
          const aRate = Big(getRateOnce(a).dailyPriceChange || '');
          const bRate = Big(getRateOnce(b).dailyPriceChange || '');
          return aRate.cmp(bRate);
        }),
    );

    setLoaded(true);
  }, [allAssets, getRateOnce, countryAsset]);

  const losersPercentage = useMemo(() => {
    if (allAssets.length === 0) return Big(0);
    const allAssetsLength = allAssets.filter((a) => a.code !== countryAsset?.code).length;

    const percentage = Big(losers.length).div(allAssetsLength).times(100);

    if (percentage.mod(1).gte(0.5)) {
      return percentage.round(0, 3);
    }

    return percentage.round(0, 0);
  }, [allAssets, countryAsset?.code, losers.length]);

  const gainersPercentage = useMemo(() => {
    if (allAssets.length === 0) return Big(0);
    const allAssetsLength = allAssets.filter((a) => a.code !== countryAsset?.code).length;

    const percentage = Big(gainers.length).div(allAssetsLength).times(100);

    if (percentage.mod(1).gte(0.5)) {
      return percentage.round(0, 3);
    }

    return percentage.round(0, 0);
  }, [allAssets.length, assets, gainers.length]);

  const getPriceChangePercentage = (asset: Asset) => getRateOnce(asset).dailyPriceChange || '0.0';

  return {
    loaded,
    gainers,
    losers,
    losersPercentage,
    gainersPercentage,
    getPriceChangePercentage,
  };
};

export { useGainersAndLosers };

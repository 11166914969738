import { useEffect, useRef } from 'react';

export const useIntersectionObserver = (callback?: () => void, options?: IntersectionObserverInit) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && callback) {
        callback();
      }
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.disconnect();
      }
    };
  }, [targetRef, options, callback]);

  return { targetRef };
};

import React from 'react';

import { Divider, Stack } from '@swyftx/react-web-design-system';

type Props = {
  amount: React.ReactNode;
  status: React.ReactNode;
  currency: React.ReactNode;
  date: React.ReactNode;
  orderDescription: React.ReactNode;
  openOrderDetails: () => void;
};

const ActivityTileMobileTransactionItem: React.FC<Props> = ({
  amount,
  currency,
  date,
  status,
  orderDescription,
  openOrderDetails,
}) => (
  <Stack
    onClick={(e) => {
      e.stopPropagation();
      openOrderDetails();
    }}
    sx={{
      '&:hover': {
        backgroundColor: 'action.hover',
        cursor: 'pointer',
      },
    }}
  >
    <Stack direction='row' justifyContent='space-between' alignItems='center' paddingX={2} paddingY={0.75}>
      <Stack direction='column' spacing={0}>
        {orderDescription}
        {status}
        {date}
      </Stack>

      <Stack direction='column' justifyContent='flex-end' spacing={1}>
        {currency}
        {amount}
      </Stack>
    </Stack>
    <Divider variant='middle' />
  </Stack>
);

export { ActivityTileMobileTransactionItem };

/* eslint-disable camelcase */

import { RecurringOrderSource } from '@shared/enums';

export interface PostRecurringOrderData {
  label: string;
  primaryAssetId: number;
  template: Record<number, number>;
  source: RecurringOrderSource;
  frequency?: RecurringOrderFrequency;
  nextTime?: number;
  nextTimeOffset?: number;
  orderTotal?: number;
  status?: RecurringOrderStatus;
}

export interface UpdateRecurringOrderData extends PostRecurringOrderData {
  templateKey: string;
}

export interface PostRecurringOrderResponse {
  template: Record<number, number>;
  templateKey: RecurringOrderSource;
}

export interface RecurringOrderTemplate {
  secondaryAssetId: number;
  percentage: number;
  active: boolean;
  qty: number;
  total: number;
}

export type RecurringOrderFrequency = 'NEVER' | 'DAILY' | 'WEEKLY' | 'FORTNIGHTLY' | 'MONTHLY';

export enum RecurringOrderStatus {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Expired = 'EXPIRED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
}

export interface RecurringOrder {
  label: string;
  templateUuid: string;
  lastTime: number;
  createdTime: number;
  templateKey: string;
  primaryAssetId: number;
  template: RecurringOrderTemplate[];
  source: RecurringOrderSource;
  nextTime: number | null;
  frequency: RecurringOrderFrequency | null;
  totalCount: number;
  orderTotal: number | null;
  status: RecurringOrderStatus;
}

export interface RecurringOrderQuery {
  assetsFilter: 'all' | 'active' | 'inactive';
}

export interface RecurringOrderStatsQuery {
  templateUuid: string;
  assetsFilter: 'all' | 'active' | 'inactive';
}

export interface RecurringOrderStats {
  secondaryAssetId: number;
  percentage: number;
  qty: number;
  total: number;
  average: number;
  active: boolean;
}

export interface DeleteRecurringOrderTemplateParams {
  name: string;
  templateUuid: string;
}

// TODO: needs business review
export type RecurringOrderDisplayPeriod = 'day' | 'week' | 'month';

export interface DCASimulation {
  assetName: string; // secondary
  assetCode: string; // secondary
  amount: number; // in base currency. BE should derive this from session
  frequency: 'daily' | 'weekly' | 'monthly';
  startDate: number;
  displayPeriod: RecurringOrderDisplayPeriod;
  periods: number;
  totalInvested: number; // in base currency
  investments: number;
  profitOrLoss: number;
  yieldPercent: number;
  totalValue: number; // in base currency
  valueInCurrency: number; // in secondary
}

import { useMemo } from 'react';

import { Big } from '@shared/safe-big';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { AutoInvestAsset } from '../autoInvest.types';

export const useAutoInvestMinimumOrder = (assets: AutoInvestAsset[]) => {
  const countryAsset = useCountryAsset();

  const minimumOrderAmount = useMemo(() => {
    if (!countryAsset) return Big(1);

    const minimumAmount = Big(1);

    const smallestPercentage = assets.map((t) => t.percentage).reduce((a, v) => Math.min(a, v), 100);
    const percentageBig = Big(smallestPercentage).div(100);

    return Big(minimumAmount).div(percentageBig);
  }, [assets, countryAsset]);

  return {
    minimumOrderAmount,
  };
};

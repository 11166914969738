import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { AssetPickerTableData, AssetPickerTabTypes } from './AssetPicker.types';
import { AssetPickerHeader } from './AssetPickerHeader';
import { AssetPickerRow } from './AssetPickerRow';
import { getEmptyContent, getEmptyIcon } from './EmptyStates';

interface Props {
  assetsToShow: Asset[];
  onSelectItem: (asset: Asset) => void;
  selectedItem?: Asset;
  sort: EnhancedTableSort<AssetPickerTableData>;
  onSort: (newSort?: EnhancedTableSort<AssetPickerTableData> | undefined) => Asset[];
  selectedTab: AssetPickerTabTypes;
  itemsPerPage?: number;
}

export const AssetPickerList: React.FC<Props> = (props) => {
  const { assetsToShow, onSelectItem, selectedItem, sort, onSort, selectedTab, itemsPerPage = 30 } = props;
  const [tableData, setTableData] = useState<Asset[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const baseAsset = useBaseAsset();
  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const loadMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const setScrollToTop = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    setScrollToTop();
    setPage(0);
  }, [selectedTab, setScrollToTop]);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (isXs || isSm) return;
      const offset = 100;

      const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - offset <= e.currentTarget.clientHeight;
      if (bottom) {
        loadMore();
      }
    },
    [isSm, isXs, loadMore],
  );

  const handleSort = useCallback(
    (newSortKey: keyof AssetPickerTableData) => {
      const oppositeDirection = sort?.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      const newSortedData = onSort({ sortKey: newSortKey, sortDirection: oppositeDirection });
      setTableData(newSortedData);
      setScrollToTop();
      setPage(0);
    },
    [onSort, setScrollToTop, sort?.sortDirection],
  );

  const tableDataToRender = tableData.slice(0, (page + 1) * itemsPerPage);

  useEffect(() => {
    setTableData(assetsToShow);
  }, [assetsToShow]);

  const AssetPickerHeaders: React.FC = () => (
    <FlexLayout direction='row' spacing={16} className='w-full py-8 sm:p-8'>
      <AssetPickerHeader
        headerKey='asset'
        title='Asset'
        sortable
        alignment='start'
        className='w-1/2 pl-8 sm:w-3/12 sm:pl-0'
        handleSort={() => handleSort('asset')}
        sort={sort}
      />
      <AssetPickerHeader
        headerKey='buyPrice'
        title='Price'
        sortable
        alignment='end'
        className='w-1/4 sm:w-2/12'
        handleSort={() => handleSort('buyPrice')}
        sort={sort}
      />
      <AssetPickerHeader
        headerKey='dailyChange'
        title='24H change'
        sortable
        alignment='end'
        className='w-1/4 pr-12 sm:w-[12.5%] sm:pr-0'
        handleSort={() => handleSort('dailyChange')}
        sort={sort}
      />
      <AssetPickerHeader
        headerKey='weeklyChange'
        title='7D change'
        sortable
        alignment='end'
        className='hidden w-[12.5%] md:flex'
        handleSort={() => handleSort('weeklyChange')}
        sort={sort}
      />
      <AssetPickerHeader
        headerKey='monthlyChange'
        title='1M change'
        sortable
        alignment='end'
        className='hidden w-[12.5%] lg:flex'
        handleSort={() => handleSort('monthlyChange')}
        sort={sort}
      />
      <AssetPickerHeader
        headerKey='dailyVolume'
        title='24H volume'
        sortable
        alignment='end'
        className='hidden w-[12.5%] sm:flex'
        handleSort={() => handleSort('dailyVolume')}
        sort={sort}
      />
    </FlexLayout>
  );

  return (
    <FlexLayout direction='column' className='@w-[100vw] sm:w-full'>
      <AssetPickerHeaders />
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className='h-[50vh] overflow-y-auto !px-0 sm:h-[calc(75vh-15rem)]'
      >
        {tableDataToRender.map((item) => (
          <AssetPickerRow
            key={item.code}
            item={item}
            onSelectItem={() => onSelectItem(item)}
            baseAsset={baseAsset}
            selectedItem={selectedItem}
          />
        ))}
        {(isXs || isSm) && tableDataToRender.length < tableData.length && (
          <FlexLayout className='w-full p-16 py-16' alignItems='center' justifyContent='center'>
            <Button className='w-full' color='subtle' onClick={loadMore}>
              Show More
            </Button>
          </FlexLayout>
        )}
        {!tableData.length && (
          <FlexLayout className='h-full items-center justify-center' direction='column' spacing={8}>
            {getEmptyIcon(selectedTab)}
            <Body>{getEmptyContent(selectedTab)}</Body>
          </FlexLayout>
        )}
      </div>
    </FlexLayout>
  );
};

import React, { useMemo } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { uuid } from '@shared/utils';

export const IdentityLightIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  const maskId = useMemo(() => uuid(), []);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none'>
        <rect width='64' height='64' fill='white' />
        <path
          d='M9.90163 58.1525C9.90163 58.6061 10.2307 59.0805 10.8741 59.4511L13.8418 61.3011C15.0187 61.9829 16.7947 62.0511 17.8116 61.4671L49.6052 43.2222L52.057 41.8051C52.5166 41.5382 52.7448 41.1825 52.7448 40.8089C52.7448 40.3583 52.4157 39.8839 51.7724 39.5104L48.8017 37.6604C47.6277 36.9844 45.8489 36.9103 44.832 37.4973L10.5895 57.1594C10.1299 57.4262 9.90163 57.779 9.90163 58.1525Z'
          fill='black'
          fillOpacity='0.05'
        />
        <path
          d='M14.7636 29.3798V54.6363C14.7636 55.6976 15.1045 56.4625 15.653 56.842L15.8576 56.9636C16.3942 57.2245 17.0879 57.1681 17.8439 56.7323L48.8549 38.8284C50.5566 37.8441 51.9352 35.3152 51.9352 33.1747V7.91826C51.9352 6.82131 51.5705 6.04159 50.9895 5.67396L50.8679 5.60279C50.4084 5.36858 49.8302 5.37156 49.1928 5.65024C49.0831 5.69768 48.9705 5.75697 48.8549 5.8222L47.0731 6.85095L17.8439 23.7261C17.7372 23.7884 17.6334 23.8536 17.5296 23.9277C15.9791 25.0217 14.7636 27.3727 14.7636 29.3798Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.7636 29.3798V32.8515L47.0731 14.1975L51.9352 11.3899V7.91825C51.9352 6.82131 51.5705 6.04159 50.9895 5.67396L50.8679 5.60279C50.4084 5.36858 49.8302 5.37156 49.1928 5.65024C49.0831 5.69768 48.9705 5.75697 48.8549 5.8222L47.0731 6.85095L17.8439 23.7261C17.7372 23.7884 17.6334 23.8536 17.5296 23.9277C15.9791 25.0217 14.7636 27.3727 14.7636 29.3798Z'
          fill='#80C0FF'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.90145 26.5723V30.044L14.7636 32.8516V29.3799C14.7636 27.3728 15.9791 25.0218 17.5297 23.9278C17.6334 23.8537 17.7372 23.7884 17.844 23.7262L47.0732 6.851L48.855 5.82226C48.9706 5.75703 49.0832 5.69773 49.1929 5.6503C49.8303 5.37161 50.4085 5.36864 50.868 5.60285L46.166 2.88718L46.0059 2.79527C45.4663 2.51955 44.7636 2.57292 43.9928 3.01763L12.9818 20.9216C11.2831 21.9029 9.90145 24.4318 9.90145 26.5723Z'
          fill='#3D93FA'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.90185 30.0439V51.8287C9.90185 52.893 10.2428 53.6579 10.7942 54.0374L10.9929 54.153L15.6534 56.842C15.105 56.4625 14.764 55.6976 14.764 54.6363V32.8515L9.90185 30.0439Z'
          fill='#E0E8F7'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M23.1616 32.2446L16.6612 36.0001'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.915 28.9307L25.4981 30.9054'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.5306 34.8386L17.1159 40.2766'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.0607 29.9141L29.1967 33.3008'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.5306 43.2399L17.1159 48.6778'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.0607 38.3155L29.1967 41.7022'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.3745 43.2908L17.7658 44.2194'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.4228 36.6713L24.425 40.1345'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.6525 36.9922L47.7192 31.7171C48.1189 31.4542 48.452 31.101 48.6915 30.686C48.9311 30.2711 49.0705 29.8061 49.0983 29.3285L49.1345 18.8368C49.1364 17.9605 48.5249 17.6074 47.7669 18.0472L38.7022 23.3235C38.3019 23.5864 37.9684 23.9398 37.7285 24.3552C37.4887 24.7706 37.3494 25.2362 37.3219 25.7142L37.2849 36.2026C37.2818 37.081 37.8945 37.432 38.6525 36.9922Z'
          fill='#E0E8F7'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <mask id={maskId} style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='37' y='17' width='13' height='21'>
          <path
            d='M38.6525 36.9922L47.7192 31.7171C48.1189 31.4542 48.452 31.101 48.6915 30.686C48.9311 30.2711 49.0705 29.8061 49.0983 29.3285L49.1345 18.8368C49.1364 17.9605 48.5249 17.6074 47.7669 18.0472L38.7022 23.3235C38.3019 23.5864 37.9684 23.9398 37.7285 24.3552C37.4887 24.7706 37.3494 25.2362 37.3219 25.7142L37.2849 36.2026C37.2818 37.081 37.8945 37.432 38.6525 36.9922Z'
            fill='#E0E8F7'
            stroke='#223893'
            strokeWidth='0.289775'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <path
            d='M47.0145 37.1652C44.9431 40.753 41.4326 42.6042 39.1737 41.3C36.9147 39.9958 36.7626 36.03 38.834 32.4422C40.9055 28.8545 44.4159 27.0033 46.6749 28.3075C48.9339 29.6117 49.0859 33.5774 47.0145 37.1652Z'
            fill='#3D93FA'
            stroke='#22262E'
            strokeWidth='0.289775'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M38.6525 36.9922L47.7192 31.7171C48.1189 31.4542 48.452 31.101 48.6915 30.686C48.9311 30.2711 49.0705 29.8061 49.0983 29.3285L49.1345 18.8368C49.1364 17.9605 48.5249 17.6074 47.7669 18.0472L38.7022 23.3235C38.3019 23.5864 37.9684 23.9398 37.7285 24.3552C37.4887 24.7706 37.3494 25.2362 37.3219 25.7142L37.2849 36.2026C37.2818 37.081 37.8945 37.432 38.6525 36.9922Z'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M44.7227 25.5764C43.8788 27.0381 42.4406 27.7877 41.5105 27.2507C40.5803 26.7136 40.5104 25.0934 41.3543 23.6317C42.1982 22.17 43.6364 21.4204 44.5665 21.9574C45.4967 22.4945 45.5666 24.1147 44.7227 25.5764Z'
          fill='#3D93FA'
          stroke='#22262E'
          strokeWidth='0.289775'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

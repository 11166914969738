import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Fire: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      d='M25.1124 6C25.8948 12.0012 24.371 22.1628 18.6871 22.1628C18.9439 19.2731 18.1772 16.5547 16.422 13.9997C15.7487 14.4823 14.1336 15.9787 13.5498 16.5566C2.58652 27.0024 12.322 44.8172 26.9144 41.622C38.0568 39.7832 41.9584 27.1386 37.109 17.6989C34.5618 12.3962 30.2284 8.6737 25.1124 6Z'
      stroke='currentColor'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.7062 30.56C16.4528 34.946 18.2645 40.8208 23.4426 41.675C29.4262 42.938 35.0286 35.4812 29.4244 30.1786C28.4592 31.2352 26.3128 34.3934 25.7932 33.7766C23.2518 30.7584 24.2792 25.125 24.2792 25.125C24.2792 25.125 19.8893 28.0964 18.7062 30.56Z'
      stroke='currentColor'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { Fire };

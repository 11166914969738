import React, { PropsWithChildren } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = PropsWithChildren<{
  id: string;
  code?: string;
}>;

const WalletListRowContainer: React.FC<Props> = observer(({ id, code, children }) => {
  const { navigate } = useNavigateRoute();

  const openWallet = () => {
    if (!code) return;

    const type = 'all';
    navigate(NavigationURLs.SingleWallet, { pathParams: { type, asset: code } });
  };

  return (
    <Card id={id} className='w-full cursor-pointer p-16 hover:bg-color-background-surface-hover' onClick={openWallet}>
      {children}
    </Card>
  );
});

export { WalletListRowContainer };

import React from 'react';

import { Box, useTheme } from '@mui/material';

import { NoChartError } from '@global-components/Errors/NoChartError';
import { LineChart } from '@global-components/charts/LineChart';
import { mockLineChartData } from '@global-components/charts/LineChart/mocks/priceData';

type Props = {
  forceId?: string;
  bgColor?: string;
  height: number;
  width: number;
  customError?: JSX.Element;
};

export const ComingSoonChart: React.FC<Props> = ({ width, height, forceId, bgColor, customError }) => {
  const theme = useTheme();
  const chartColor = theme.palette.grey[400];
  return (
    <Box position='relative' width={width} height={height}>
      <LineChart
        backgroundColor={bgColor || theme.palette.background.paper}
        idKey={forceId || 'coming-soon-chart'}
        chartData={mockLineChartData}
        disableLoadingAnimation
        areaColor={chartColor}
        height={height}
        width={width}
      />
      <Box width='80%' position='absolute' left='50%' top='50%' sx={{ transform: 'translate(-50%, -50%)' }}>
        {customError || (
          <NoChartError
            reason='comingSoon'
            containerProps={{
              bgcolor: theme.palette.background.paper,
              borderRadius: theme.spacing(2),
              border: '1px solid',
              borderColor: 'divider',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { MessagesSearch } from '@swyftx/aviary/icons/outlined';

import { usePriceAlertEmpty } from './usePriceAlertEmpty';
import { CreatePriceAlertType, PriceAlertType } from '../../dynamicPriceAlerts.types';

type Props = {
  alertType: PriceAlertType;
  type?: CreatePriceAlertType;
  showCreateButton?: boolean;
  showRemoveFiltersButton?: boolean;
  filtersActive?: boolean;
  onRemoveAllFilters?: () => void;
};

const PriceAlertEmpty: React.FC<Props> = (props) => {
  const { title, subTitle, actionButton } = usePriceAlertEmpty(props);

  return (
    <FlexLayout direction='column' spacing={12} alignItems='center'>
      <MessagesSearch className='text-color-text-accent' />
      <Heading size='h3' weight='light'>
        {title}
      </Heading>
      <Body color='secondary' size='small'>
        {subTitle}
      </Body>
      {actionButton}
    </FlexLayout>
  );
};

export { PriceAlertEmpty };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps } from '@mui/material';

import { Image } from '@swyftx/aviary/atoms/Image';

import { ContentBanner } from '@global-components/ContentBanner';
import { ContentBannerProps } from '@global-components/ContentBanner/ContentBanner';

import { useGlobalResolveRestrictions } from '@hooks/useGlobalResolveRestrictions';

import { observer } from 'mobx-react-lite';

type Props = {
  containerSx?: BoxProps['sx'];
  bannerCardSx?: ContentBannerProps['cardSx'];
};

export const CryptoNotYetUnlockedBanner: React.FC<Props> = observer(({ containerSx, bannerCardSx }) => {
  const { t } = useTranslation('common', { keyPrefix: 'withdrawalsNotYetUnlockedBanner' });

  const resolveRestrictions = useGlobalResolveRestrictions();

  const bannerIcon = <Image image='verification_banner' className='h-64 w-64' usePalette />;

  return (
    <Box
      width='100%'
      sx={{
        ...containerSx,
      }}
    >
      <ContentBanner
        onAction={resolveRestrictions}
        actionLabel={t('actionLabel')}
        description={t('subTitle')}
        cardSx={bannerCardSx}
        title={t('title')}
        icon={bannerIcon}
      />
    </Box>
  );
});

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TickCircleFilledIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.4301 14.9899L16.1801 10.2399C16.5201 9.89988 16.5201 9.34988 16.1801 8.99988C15.8401 8.65988 15.2801 8.65988 14.9401 8.99988L10.8101 13.1299L9.06012 11.3799C8.72012 11.0399 8.16012 11.0399 7.82012 11.3799C7.48012 11.7199 7.48012 12.2699 7.82012 12.6199L10.2001 14.9899C10.3701 15.1599 10.5901 15.2399 10.8101 15.2399C11.0401 15.2399 11.2601 15.1599 11.4301 14.9899Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);

export default TickCircleFilledIcon;

import { HistoricalOrder } from '@shared/api';
import { formatCurrency } from '@shared/utils';

import { useFeeUtils } from './useFeeUtils';

export const useOrderFee = (order: HistoricalOrder) => {
  const { calculateExecutedOrderFee, calculateOpenOrderFee, quantityAsset, secondaryQuantityAsset, isOpenOrder } =
    useFeeUtils(order);

  if (isOpenOrder) {
    const { primaryAmount, secondaryAmount, primaryFeeAsset, secondaryFeeAsset } = calculateOpenOrderFee();
    return {
      feeAmount: formatCurrency(primaryAmount, primaryFeeAsset, { isApproximate: true, appendCode: true }),
      secondaryFeeAmount: formatCurrency(secondaryAmount, secondaryFeeAsset, { isApproximate: true, appendCode: true }),
    };
  }

  const { primaryAmount, secondaryAmount } = calculateExecutedOrderFee();

  return {
    feeAmount: formatCurrency(primaryAmount, secondaryQuantityAsset, { appendCode: true }),
    secondaryFeeAmount: formatCurrency(secondaryAmount, quantityAsset, { isApproximate: true, appendCode: true }),
  };
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { TriggeredPriceAlert } from '../../dynamicPriceAlerts.types';
import { useDynamicPriceAlert } from '../../hooks/useDynamicPriceAlert';
import { getGroupByTitle, isLimitTriggerAlert, isVolatilityTriggerAlert } from '../../utils/dynamic-price-alerts.utils';
import { DynamicPriceAlertIcon } from '../DynamicPriceAlertIcon';
import { DynamicPriceAlertTitle } from '../DynamicPriceAlertTitle';

type Props = {
  alert: TriggeredPriceAlert;
  onClick?: () => void;
};

const PriceAlertsModalTriggeredItem: React.FC<Props> = observer(({ alert, onClick }) => {
  const { primaryAsset, secondaryAsset } = useDynamicPriceAlert({ alert });

  return (
    <ListItem onClick={onClick}>
      <FlexLayout alignItems='center' justifyContent='space-between' className='w-full py-8'>
        <FlexLayout alignItems='center' spacing={12}>
          <DynamicPriceAlertIcon
            type={alert.type}
            showIconOnly
            direction={isLimitTriggerAlert(alert) ? alert.direction : undefined}
            asset={secondaryAsset}
          />
          <FlexLayout alignItems='start' direction='column'>
            <DynamicPriceAlertTitle
              primaryAsset={primaryAsset}
              secondaryAsset={secondaryAsset}
              triggered
              type={alert.type}
              direction={isLimitTriggerAlert(alert) ? alert.direction : undefined}
              price={isLimitTriggerAlert(alert) ? alert.alertPrice : undefined}
              change={isVolatilityTriggerAlert(alert) ? alert.alertChange : undefined}
              window={isVolatilityTriggerAlert(alert) ? alert.window : undefined}
            />
            <Body size='small' color='secondary'>
              {DateTime.fromMillis(alert.dateTriggered).toFormat('hh:mm a')} &bull;{' '}
              {getGroupByTitle(DateTime.fromMillis(alert.dateTriggered || 0))}
            </Body>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </ListItem>
  );
});

export { PriceAlertsModalTriggeredItem };

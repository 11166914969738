import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import { Body } from '@swyftx/aviary/atoms/Typography';
import { Stack } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useMultiAssetData } from '@hooks/Assets/useMultiAssetData';
import { useAvo } from '@hooks/Avo/useAvo';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { usePaginatedScroll } from '@hooks/usePaginatedScroll';

import { DashboardContext } from '@Dashboard/Dashboard.context';
import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';
import { AssetRow } from '@Dashboard/components/Tiles/components/AssetRow';

import { observer } from 'mobx-react-lite';
import { useMeasure, useSize } from 'react-use';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { AssetRowHeader } from '../components';
import { AssetRowSort, AssetRowSortBy } from '../components/AssetRowHeader';
import { useSortAssetRow } from '../components/useSortAssetRow';

enum MoversSelection {
  Gainers = 'moversTile.title.gainers',
  Losers = 'moversTile.title.losers',
  New = 'moversTile.title.new',
}

const IdMap: { [key in MoversSelection]: string } = {
  [MoversSelection.Gainers]: 'moversTile-title-gainers',
  [MoversSelection.Losers]: 'moversTile-title-losers',
  [MoversSelection.New]: 'moversTile-title-new',
};

export type MoversTileFilter = 'gainers' | 'losers' | 'new';

type HeaderProps = {
  selectedOption: MoversSelection;
  setSelectedOption: (option: MoversSelection) => void;
};

const Header: React.FC<HeaderProps> = ({ selectedOption, setSelectedOption }) => {
  const { editMode } = useContext(DashboardContext);
  const { t } = useTranslation('dashboard');
  const { pathname } = useLocation();
  const avo = useAvo();

  const handleSelection = (key: MoversSelection) => {
    if (!editMode) {
      avo.changedTabOnMoversDashboardTile({
        screen: pathname,
        previousTileFilter: selectedOption.split('.')[2] as MoversTileFilter,
        tileFilter: key.split('.')[2] as MoversTileFilter,
        tileView: 'list',
      });
      setSelectedOption(key);
    }
  };

  return (
    <Stack direction='row' spacing={2} width='100%' padding={0.5}>
      {Object.values(MoversSelection).map((key: any) => (
        <Body
          key={key}
          weight='emphasis'
          className={cn(
            'user-select-none cursor-pointer hover:text-color-text-secondary',
            selectedOption === key ? 'text-color-text-info' : 'text-color-text-primary',
          )}
          onClick={() => handleSelection(key)}
        >
          {t(key)}
        </Body>
      ))}
    </Stack>
  );
};

const ASSET_ROW_HEIGHT = 20;
const ASSET_ROW_SPACING = 8;
const ITEMS_PER_PAGE = 10;
const NUMBER_OF_ASSETS_TO_SHOW = 10;

const MoversTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const baseAsset = useBaseAsset();
  const { bx } = useContentBreakpoint();
  const { t } = useTranslation('dashboard');
  const { editMode } = useContext(DashboardContext);
  const [sort, setSort] = useState<AssetRowSort>({ sort: 'dailyChange', direction: 'desc' });
  const { navigateToAsset } = useAssetNavigation();

  const [selectedOption, setSelectedOption] = useState<MoversSelection>(MoversSelection.Gainers);

  const [cardRef, { width }] = useMeasure();
  const [heightSize] = useSize(<Box width={0} height={bx({ xs: '220px', md: '440px' })} />);

  const { userCountryCurrency, userCountryCurrencyCode } = UserStore.useUserStore;
  const { getRate } = RatesStore.useRatesStore;
  // check current currency rate
  const currentCurrencyRate = getRate(userCountryCurrency).midPrice;
  const assets = useMemo(() => assetService.getActiveAssetList(), [currentCurrencyRate]);

  const assetMovers = useMemo((): Array<Asset> => {
    const getDailyMovement = (asset: Asset) => {
      const rate = getRate(asset.id);
      return Number(rate?.dailyPriceChange);
    };

    if (selectedOption === MoversSelection.Gainers) {
      return assets.filter((asset) => {
        if (sort.sort === 'monthlyChange') return Big(asset.priceChange.month).gt(0);
        if (sort.sort === 'weeklyChange') return Big(asset.priceChange.week).gt(0);

        return getDailyMovement(asset) > 0;
      });
    }
    if (selectedOption === MoversSelection.Losers) {
      return assets.filter((asset) => {
        if (sort.sort === 'monthlyChange') return Big(asset.priceChange.month).lt(0);
        if (sort.sort === 'weeklyChange') return Big(asset.priceChange.week).lt(0);

        return getDailyMovement(asset) < 0;
      });
    }
    return assets.sort((a: Asset, b: Asset) => b.id - a.id).slice(0, NUMBER_OF_ASSETS_TO_SHOW);
  }, [selectedOption, assets, getRate, sort.sort]);

  const { sortedData } = useSortAssetRow({
    data: assetMovers,
    sort,
    reverseCheck: selectedOption === MoversSelection.Losers,
    maxAssets: NUMBER_OF_ASSETS_TO_SHOW,
  });

  useEffect(() => {
    switch (selectedOption) {
      case MoversSelection.Gainers:
      case MoversSelection.Losers:
        setSort({ sort: 'dailyChange', direction: 'desc' });
        break;
      case MoversSelection.New:
        setSort({ sort: 'id', direction: 'desc' });
        break;
      default:
        break;
    }
  }, [selectedOption]);

  const sortingKeys = useMemo((): AssetRowSortBy[] => {
    if (selectedOption === MoversSelection.New) return [];

    return ['dailyChange', 'weeklyChange', 'monthlyChange'];
  }, [selectedOption]);

  const assetData = useMultiAssetData({
    assets: sortedData,
    baseAsset: baseAsset?.code ?? userCountryCurrencyCode,
    side: 'ask',
    resolution: '1h',
  });

  const { scrollRef } = usePaginatedScroll(sortedData, ITEMS_PER_PAGE);
  return (
    <DashboardBaseTile
      dragProps={dragProps}
      headerBarActionItem={<Header selectedOption={selectedOption} setSelectedOption={setSelectedOption} />}
      contentSx={{ height: '100%' }}
      tileName={tile.name}
      title={editMode ? t('moversTile.title.name') : undefined}
      noPadding
    >
      <Box height='100%' position='relative' display='flex' ref={cardRef}>
        {heightSize}
        <Stack direction='column' width='100%'>
          <AssetRowHeader width={width || 0} onSort={setSort} sort={sort} sortingKeys={sortingKeys} />
          <Box sx={{ overflowY: 'auto' }} ref={scrollRef}>
            <Stack spacing={`${ASSET_ROW_SPACING}px`} marginTop={1} flex={1} padding={0}>
              {sortedData.map((asset: Asset) => (
                <AssetRow
                  idKey={IdMap[selectedOption]}
                  baseAsset={baseAsset}
                  key={asset.id}
                  asset={asset}
                  height={ASSET_ROW_HEIGHT}
                  width={width || 0}
                  assetData={assetData[asset.id]}
                  onSelectAsset={(selectedAsset) => navigateToAsset(selectedAsset, false)}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </DashboardBaseTile>
  );
});

MoversTile.displayName = 'MoversTile';

export { MoversTile };

import { DashboardTile, DashboardTileMetadata } from 'src/shared/api';
import { DashboardTileEnum } from 'src/shared/enums';

type DashboardTiles = DashboardTile[];

const defaultMetadata: DashboardTileMetadata = { size: 'compact' };

export const MAXIMUM_COLUMN_SPACING = 12;

export const defaultDashboardTiles: DashboardTiles = [
  {
    name: DashboardTileEnum.ANNOUNCEMENT,
    display: true,
    metadata: { ...defaultMetadata, webIndex: 0 },
  },
  {
    name: DashboardTileEnum.QUICK_ACTIONS,
    display: true,
    metadata: { ...defaultMetadata, size: 'wide', webIndex: 1 },
  },
  {
    name: DashboardTileEnum.PORTFOLIO_BREAKDOWN,
    display: true,
    metadata: { ...defaultMetadata, size: 'compact', webIndex: 2 },
  },
  {
    name: DashboardTileEnum.PORTFOLIO_PERFORMANCE,
    display: true,
    metadata: { ...defaultMetadata, size: 'wide', webIndex: 3 },
  },
  {
    name: DashboardTileEnum.FAVOURITES,
    display: true,
    metadata: { ...defaultMetadata, size: 'compact', webIndex: 4 },
  },
  {
    name: DashboardTileEnum.MOVERS,
    display: true,
    metadata: { ...defaultMetadata, size: 'compact', webIndex: 5 },
  },
  {
    name: DashboardTileEnum.ACTIVITY,
    display: true,
    metadata: { ...defaultMetadata, size: 'compact', webIndex: 6 },
  },
  {
    name: DashboardTileEnum.NEWS_FEED,
    display: true,
    metadata: { ...defaultMetadata, size: 'half', webIndex: 8 },
  },
  {
    name: DashboardTileEnum.REFER_A_FRIEND,
    display: true,
    metadata: { ...defaultMetadata, size: 'compact', webIndex: 9 },
  },
  {
    name: DashboardTileEnum.AFFILIATE,
    display: true,
    metadata: { ...defaultMetadata, size: 'compact', webIndex: 10 },
  },
  {
    name: DashboardTileEnum.VERIFICATION,
    display: true,
    metadata: { ...defaultMetadata, size: 'half', webIndex: 11 },
  },
];

import { useMemo } from 'react';

import Box from '@mui/material/Box';
import { alpha } from '@mui/system/colorManipulator';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import {
  AI,
  AMM,
  Analytics,
  AssetBacked,
  CEX,
  DEX,
  Defi,
  EthStaking,
  ExchangeBased,
  FanToken,
  FinanceBanking,
  Gamefi,
  Governance,
  GuildScholarship,
  InternetOfThings,
  Layer,
  LayerTwo,
  LendingBorrowing,
  LiquidStaking,
  MEVProtection,
  Masternodes,
  Meme,
  MetaGovernance,
  Metaverse,
  MoveToEarn,
  Music,
  NFT,
  NFTMarketplace,
  OlympusPro,
  Oracle,
  Perpetuals,
  PlayToEarn,
  PredictionMarkets,
  Seigniorage,
  SmartContract,
  Sports,
  Stablecoins,
  Storage,
  SynthIssuer,
  TokenisedGold,
  Tourism,
  Wallets,
  WrappedTokens,
  YieldAggregator,
  YieldFarming,
  ZeroKnowledge,
} from '@swyftx/aviary/icons/categories';
import { Star, Category } from '@swyftx/aviary/icons/outlined';

type IconSize = 12 | 16 | 24 | 48 | 96;

type Props = {
  bgcolor: string;
  icon: React.ReactNode;
  size: IconSize;
  solid?: boolean;
  justIcon?: boolean;
};

const borderSize: { [key in IconSize]: string } = {
  12: '8px',
  16: '8px',
  24: '8px',
  48: '10px',
  96: '30px',
};

const CategoryIconContainer: React.FC<Props> = ({ icon, bgcolor, size, solid = false, justIcon = false }) => {
  const { isLightMode } = useTailwindTheme();

  const color = useMemo(() => {
    if (isLightMode) return 'white';
    return bgcolor;
  }, [bgcolor, isLightMode]);

  const bgColor = useMemo(() => {
    if (isLightMode) return bgcolor;
    return 'var(--color-background-pageBG)';
  }, [bgcolor, isLightMode]);

  if (justIcon) return <>{icon}</>;

  return (
    <Box
      bgcolor={solid ? bgColor : alpha(bgcolor, 0.1)}
      color={solid ? color : bgcolor}
      width={size + 8}
      minWidth={size + 8}
      borderRadius={borderSize[size]}
      height={size + 8}
      minHeight={size + 8}
      padding={0.5}
      marginRight={1}
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        '.MuiSvgIcon-root': {
          color: solid ? color : bgcolor,
        },
      }}
    >
      {icon}
    </Box>
  );
};

export const RawCategoryIcons = (className?: string): { [key: string]: JSX.Element } => ({
  'Layer 1 (L1)': <Layer className={className} />,
  'Smart Contract Platform': <SmartContract className={className} />,
  Stablecoins: <Stablecoins className={className} />,
  'Exchange-based Tokens': <ExchangeBased className={className} />,
  'Centralized Exchange (CEX)': <CEX className={className} />,
  'Decentralized Finance (DeFi)': <Defi className={className} />,
  Meme: <Meme className={className} />,
  NFT: <NFT className={className} />,
  Governance: <Governance className={className} />,
  'Layer 2 (L2)': <LayerTwo className={className} />,
  'Zero Knowledge (ZK)': <ZeroKnowledge className={className} />,
  'Liquid Staking Tokens': <Category className={className} />,
  'Decentralized Exchange (DEX)': <DEX className={className} />,
  'Eth 2.0 Staking': <EthStaking className={className} />,
  'Yield Farming': <YieldFarming className={className} />,
  'Gaming (GameFi)': <Gamefi className={className} />,
  'Automated Market Maker (AMM)': <AMM className={className} />,
  Metaverse: <Metaverse className={className} />,
  'Play To Earn': <PlayToEarn className={className} />,
  Storage: <Storage className={className} />,
  Oracle: <Oracle className={className} />,
  'Liquid Staking Governance Tokens': <LiquidStaking className={className} />,
  'Wrapped-Tokens': <WrappedTokens className={className} />,
  'Finance / Banking': <FinanceBanking className={className} />,
  'Asset-backed Tokens': <AssetBacked className={className} />,
  'Internet of Things (IOT)': <InternetOfThings className={className} />,
  'Lending/Borrowing': <LendingBorrowing className={className} />,
  'Artificial Intelligence (AI)': <AI className={className} />,
  Sports: <Sports className={className} />,
  Seigniorage: <Seigniorage className={className} />,
  Perpetuals: <Perpetuals className={className} />,
  Masternodes: <Masternodes className={className} />,
  Analytics: <Analytics className={className} />,
  'Olympus Pro': <OlympusPro className={className} />,
  'Synthetic Issuer': <SynthIssuer className={className} />,
  'Yield Aggregator': <YieldAggregator className={className} />,
  'Tokenized Gold': <TokenisedGold className={className} />,
  Wallets: <Wallets className={className} />,
  Insurance: <Category className={className} />,
  Metagovernance: <MetaGovernance className={className} />,
  'MEV Protection': <MEVProtection className={className} />,
  Options: <Category className={className} />,
  'NFT Marketplace': <NFTMarketplace className={className} />,
  'Rebase Tokens': <Category className={className} />,
  'Prediction Markets': <PredictionMarkets className={className} />,
  Music: <Music className={className} />,
  'Move to Earn': <MoveToEarn className={className} />,
  'Fan Token': <FanToken className={className} />,
  'Guild and Scholarship': <GuildScholarship className={className} />,
  Environment: <Category className={className} />,
  Synths: <Category className={className} />,
  'Social Money': <Category className={className} />,
  Tourism: <Tourism className={className} />,
  'DeFi Index': <Defi className={className} />,
  'Favourite Assets': <Star className={className} />,
});

const categoryIconClassName = '!w-full !h-full';

// Just icon is a hack for now until get the icon from the backend
export const CategoryIcons = (
  size: IconSize = 16,
  solid = false,
  justIcon = false,
): { [key: string]: React.ReactNode } => ({
  'Layer 1 (L1)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#356be9'
      icon={RawCategoryIcons(categoryIconClassName)['Layer 1 (L1)']}
    />
  ),
  'Smart Contract Platform': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#3547e9'
      icon={RawCategoryIcons(categoryIconClassName)['Smart Contract Platform']}
    />
  ),
  Stablecoins: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1dd7c5'
      icon={RawCategoryIcons(categoryIconClassName)['Stablecoins']}
    />
  ),
  'Exchange-based Tokens': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e9b335'
      icon={RawCategoryIcons(categoryIconClassName)['Exchange-based Tokens']}
    />
  ),
  'Centralized Exchange (CEX)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1d43d7'
      icon={RawCategoryIcons(categoryIconClassName)['Centralized Exchange (CEX)']}
    />
  ),
  'Decentralized Finance (DeFi)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#8f35e9'
      icon={RawCategoryIcons(categoryIconClassName)['Decentralized Finance (DeFi)']}
    />
  ),
  Meme: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e98f35'
      icon={RawCategoryIcons(categoryIconClassName)['Meme']}
    />
  ),
  NFT: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#681dd7'
      icon={RawCategoryIcons(categoryIconClassName)['NFT']}
    />
  ),
  Governance: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#d71d30'
      icon={RawCategoryIcons(categoryIconClassName)['Governance']}
    />
  ),
  'Layer 2 (L2)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#358fe9'
      icon={RawCategoryIcons(categoryIconClassName)['Layer 2 (L2)']}
    />
  ),
  'Zero Knowledge (ZK)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#d7431d'
      icon={RawCategoryIcons(categoryIconClassName)['Zero Knowledge (ZK)']}
    />
  ),
  'Liquid Staking Tokens': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#35c5e9'
      icon={RawCategoryIcons(categoryIconClassName)['Liquid Staking Tokens']}
    />
  ),
  'Decentralized Exchange (DEX)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e935d7'
      icon={RawCategoryIcons(categoryIconClassName)['Decentralized Exchange (DEX)']}
    />
  ),
  'Eth 2.0 Staking': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1da0d7'
      icon={RawCategoryIcons(categoryIconClassName)['Eth 2.0 Staking']}
    />
  ),
  'Yield Farming': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#35e9e9'
      icon={RawCategoryIcons(categoryIconClassName)['Yield Farming']}
    />
  ),
  'Gaming (GameFi)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1dc5d7'
      icon={RawCategoryIcons(categoryIconClassName)['Gaming (GameFi)']}
    />
  ),
  'Automated Market Maker (AMM)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#d71d55'
      icon={RawCategoryIcons(categoryIconClassName)['Automated Market Maker (AMM)']}
    />
  ),
  Metaverse: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#b335e9'
      icon={RawCategoryIcons(categoryIconClassName)['Metaverse']}
    />
  ),
  'Play To Earn': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e935a1'
      icon={RawCategoryIcons(categoryIconClassName)['Play To Earn']}
    />
  ),
  Storage: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#c5d71d'
      icon={RawCategoryIcons(categoryIconClassName)['Storage']}
    />
  ),
  Oracle: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#d735e9'
      icon={RawCategoryIcons(categoryIconClassName)['Oracle']}
    />
  ),
  'Liquid Staking Governance Tokens': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Liquid Staking Governance Tokens']}
    />
  ),
  'Wrapped-Tokens': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e94735'
      icon={RawCategoryIcons(categoryIconClassName)['Wrapped-Tokens']}
    />
  ),
  'Finance / Banking': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#6b35e9'
      icon={RawCategoryIcons(categoryIconClassName)['Finance / Banking']}
    />
  ),
  'Asset-backed Tokens': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e96b35'
      icon={RawCategoryIcons(categoryIconClassName)['Asset-backed Tokens']}
    />
  ),
  'Internet of Things (IOT)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1dd768'
      icon={RawCategoryIcons(categoryIconClassName)['Internet of Things (IOT)']}
    />
  ),
  'Lending/Borrowing': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#35e96b'
      icon={RawCategoryIcons(categoryIconClassName)['Lending/Borrowing']}
    />
  ),
  'Artificial Intelligence (AI)': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1dd7a0'
      icon={RawCategoryIcons(categoryIconClassName)['Artificial Intelligence (AI)']}
    />
  ),
  Sports: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#431dd7'
      icon={RawCategoryIcons(categoryIconClassName)['Sports']}
    />
  ),
  Seigniorage: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#b2d71d'
      icon={RawCategoryIcons(categoryIconClassName)['Seigniorage']}
    />
  ),
  Perpetuals: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#8fe935'
      icon={RawCategoryIcons(categoryIconClassName)['Perpetuals']}
    />
  ),
  Masternodes: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e935b3'
      icon={RawCategoryIcons(categoryIconClassName)['Masternodes']}
    />
  ),
  Analytics: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#E9358F'
      icon={RawCategoryIcons(categoryIconClassName)['Analytics']}
    />
  ),
  'Olympus Pro': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#35e947'
      icon={RawCategoryIcons(categoryIconClassName)['Olympus Pro']}
    />
  ),
  'Synthetic Issuer': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#d71db2'
      icon={RawCategoryIcons(categoryIconClassName)['Synthetic Issuer']}
    />
  ),
  'Yield Aggregator': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1dd78d'
      icon={RawCategoryIcons(categoryIconClassName)['Yield Aggregator']}
    />
  ),
  'Tokenized Gold': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e9d735'
      icon={RawCategoryIcons(categoryIconClassName)['Tokenized Gold']}
    />
  ),
  Wallets: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#1d68d7'
      icon={RawCategoryIcons(categoryIconClassName)['Wallets']}
    />
  ),
  Insurance: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Insurance']}
    />
  ),
  Metagovernance: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#35e98f'
      icon={RawCategoryIcons(categoryIconClassName)['Metagovernance']}
    />
  ),
  'MEV Protection': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#30d71d'
      icon={RawCategoryIcons(categoryIconClassName)['MEV Protection']}
    />
  ),
  Options: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Options']}
    />
  ),
  'NFT Marketplace': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#4735e9'
      icon={RawCategoryIcons(categoryIconClassName)['NFT Marketplace']}
    />
  ),
  'Rebase Tokens': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Rebase Tokens']}
    />
  ),
  'Prediction Markets': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#b3e935'
      icon={RawCategoryIcons(categoryIconClassName)['Prediction Markets']}
    />
  ),
  Music: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#a135e9'
      icon={RawCategoryIcons(categoryIconClassName)['Music']}
    />
  ),
  'Move To Earn': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e93559'
      icon={RawCategoryIcons(categoryIconClassName)['Move to Earn']}
    />
  ),
  'Fan Token': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e93535'
      icon={RawCategoryIcons(categoryIconClassName)['Fan Token']}
    />
  ),
  'Guild and Scholarship': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#6be935'
      icon={RawCategoryIcons(categoryIconClassName)['Guild and Scholarship']}
    />
  ),
  Environment: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Environment']}
    />
  ),
  Synths: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Synths']}
    />
  ),
  'Social Money': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Social Money']}
    />
  ),
  Tourism: (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#e9357d'
      icon={RawCategoryIcons(categoryIconClassName)['Tourism']}
    />
  ),
  'DeFi Index': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['DeFi Index']}
    />
  ),
  'Favourite Assets': (
    <CategoryIconContainer
      justIcon={justIcon}
      size={size}
      solid={solid}
      bgcolor='#96A1B5'
      icon={RawCategoryIcons(categoryIconClassName)['Favourite Assets']}
    />
  ),
});

export const fallbackIcon = (size: IconSize = 16, solid = false, justIcon = false) => (
  <CategoryIconContainer
    justIcon={justIcon}
    size={size}
    solid={solid}
    bgcolor='#96A1B5'
    icon={<Category width='100%' height='100%' />}
  />
);

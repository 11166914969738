import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { AppHeaderAssetActions } from '@global-components/AppHeader/AppHeaderAssetActions';
import { AppHeaderAssetPicker } from '@global-components/AppHeader/AppHeaderAssetPicker';
import { BuySellButtons } from '@global-components/BuySellButtons';
import { ContentDivider } from '@global-components/ContentDivider';
import { CompositeErrorBoundary } from '@global-components/ErrorBoundaries';
import { NoChartError } from '@global-components/Errors/NoChartError';
import LimitedFunctionalityNotification from '@global-components/LimitedFunctionalityNotification/LimitedFunctionalityNotification';

import { Asset, RateSide } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { UIStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { useCharts } from '@hooks/Charts/useCharts';
import { TransactionsTableCard } from '@routes/Transactions/components/TransactionsTableCard/TransactionsTableCard';
import { TransactionHistoryTab } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';
import { useWindowSize } from 'react-use';
import { AssetInfoContent } from 'src/lib/UniversalTrade/components/AssetInfoPopover/AssetInfoContent';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { TradingViewWidget } from 'src/lib/trade/components';
import { TradingViewSide } from 'src/lib/trade/components/TradingViewWidget/types';

import { AssetNews, AssetSummary, AssetWallet } from './components';
import { AssetSummaryTitle } from './components/AssetSummary/AssetSummaryTitle';

type AssetParams = {
  asset: string;
};

const COMPACT_CHART_THRESHOLD = 768;

const AssetPage: React.FC = observer(() => {
  const [type, setType] = useState<TransactionHistoryTab>('orders');
  const [priceSide, setPriceSide] = useState<RateSide>('askPrice');
  const { navigate, params } = useNavigateRoute();
  const { asset: assetParam } = params as AssetParams;
  const baseAsset = useBaseAsset();
  const [asset, setAsset] = useState<Asset | undefined>(undefined);
  const { setCustomBreadcrumb, showMarketTicker } = UIStore.useUIStore;
  const { height } = useWindowSize();
  const { pathname } = useLocation();
  const avo = useAvo();

  const chartHeight = useMemo(() => {
    if (height < COMPACT_CHART_THRESHOLD) {
      return 'h-[50vh]';
    }
    return showMarketTicker ? 'h-[65vh]' : 'h-[71vh]';
  }, [height, showMarketTicker]);

  const { chartAvailable, notAvailableReason } = useCharts(asset);

  const navigateToAssetPage = useCallback(
    (newAsset: Asset) => {
      avo.viewedSingleAssetPage({
        screen: pathname,
        assetName: newAsset.name,
        assetId: newAsset.id,
        assetCode: newAsset.code,
      });
      navigate(NavigationURLs.SingleAsset, { pathParams: { asset: newAsset.code } });
    },
    [avo, navigate, pathname],
  );

  const handlePriceSide = (newPriceSide: TradingViewSide) => {
    if (newPriceSide === 'bid') {
      setPriceSide('bidPrice');
    }
    if (newPriceSide === 'ask') {
      setPriceSide('askPrice');
    }
  };

  useEffect(() => {
    if (assetParam && !pathname.includes('filter')) {
      setCustomBreadcrumb(
        assetParam.toLowerCase(),
        <AppHeaderAssetPicker assetCode={assetParam} key={assetParam.toLowerCase()} />,
      );
    }
  }, [assetParam, navigateToAssetPage, pathname, setCustomBreadcrumb]);

  useEffect(() => {
    if (!assetParam) {
      navigate(NavigationURLs.Assets);
      return;
    }

    const assetByCode = assetService.getAssetByCode(assetParam.toUpperCase());

    if (!assetByCode) {
      navigate(NavigationURLs.Assets);
      return;
    }
    setAsset(assetByCode);
  }, [assetParam, navigate]);

  const chartContent = useMemo(() => {
    if (!chartAvailable || !asset || !baseAsset) return <NoChartError reason={notAvailableReason} />;

    return (
      <div className={cn('w-full', chartHeight)}>
        <TradingViewWidget
          secondary={asset.code}
          primary={baseAsset.code}
          className='h-full w-full'
          onPriceSideChange={handlePriceSide}
          useNewStyle
          chartStyle='advanced'
        />
      </div>
    );
  }, [chartAvailable, asset, baseAsset, notAvailableReason, chartHeight]);

  if (!asset) return null;

  return (
    <>
      <FlexLayout className='relative @container' direction='column'>
        <FlexLayout className='relative z-20 mx-8 w-full justify-center bg-color-background-pageBG @sm:sticky @sm:top-0 @sm:justify-start'>
          <FlexLayout direction='column' className='w-full px-8 py-16'>
            <LimitedFunctionalityNotification asset={asset} />
            <AssetSummaryTitle asset={asset} priceSide={priceSide} />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout className='relative top-0 z-10 mx-8 w-full'>
          <div className='w-full px-8'>
            <FlexLayout direction='column' className='w-full pb-16'>
              <AssetSummary asset={asset} priceSide={priceSide} />
            </FlexLayout>
          </div>
        </FlexLayout>

        <FlexLayout className='relative top-0 z-10 w-full px-16'>
          <FlexLayout direction='column' spacing={16} className='w-full pb-16'>
            <CompositeErrorBoundary>{chartContent}</CompositeErrorBoundary>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout className='relative top-0 w-full px-16'>
          <FlexLayout direction='column' spacing={16} className='w-full'>
            <TransactionsTableCard type={type} onChangeType={setType} filterAsset={asset} className='h-[25rem]' />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout className='relative w-full p-16' spacing={16} direction='column'>
          <FlexLayout className='w-full flex-col @md:flex-row' justifyContent='space-between' spacing={16}>
            <FlexLayout className='relative w-full @md:w-1/2'>
              <FlexLayout direction='column' spacing={16} className='w-full'>
                <Card className='p-8'>
                  <AssetInfoContent asset={asset} setOpen={() => {}} variant='content' />
                </Card>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout className='relative w-full @md:w-1/2'>
              <FlexLayout direction='column' spacing={16} className='w-full'>
                <AssetWallet asset={asset} />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='column' className='w-full'>
            <AssetNews asset={asset} />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout className='fixed bottom-0 z-20 block h-[76px] w-full pt-16 sm:hidden'>
        <FlexLayout
          className='sticky bottom-0 h-full overflow-hidden bg-color-background-pageBG p-8'
          alignItems='center'
          spacing={8}
          direction='row'
        >
          <ContentDivider placement='top' containerPadding={1} />
          <BuySellButtons asset={asset} openTradePanel />
          <AppHeaderAssetActions assetCode={assetParam ? assetParam.toLowerCase() : ''} />
          <ContentDivider placement='bottom' containerPadding={1} />
        </FlexLayout>
      </FlexLayout>
    </>
  );
});

export default AssetPage;

import { NewsServiceResponse } from '@shared/api';
import env from '@shared/config';

import { Methods, request } from '../rest';

export enum ParamKeysEnum {
  FILTER_BY_CATEGORY = 'filter[category]',
  PAGE_SIZE = 'page[size]',
  CURSOR = 'page[cursor]',
}

type FilterParams = {
  [ParamKeysEnum.FILTER_BY_CATEGORY]: string;
  [ParamKeysEnum.PAGE_SIZE]: number;
  [ParamKeysEnum.CURSOR]: string | undefined;
};

const host = env.GEN2_HOST;

const newsServiceEndpoints = {
  getNews: ({ query }: { query: FilterParams }): NewsServiceResponse =>
    request({
      path: '/news/api/v1/news',
      method: Methods.GET,
      query,
      host,
      hideErrorToast: true,
      auth: false,
    }),
};

export default newsServiceEndpoints;

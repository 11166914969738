import React from 'react';

import { observer } from 'mobx-react-lite';

import { useSettingsSearchModule } from './useSettingsSearchModule';
import { GlobalSearchCategory } from '../../components/GlobalSearchCategory';
import { useFilterSearchModuleItems } from '../../hooks';

type Props = {
  searchValue: string;
};

const SettingsModuleSearchCategory: React.FC<Props> = observer(({ searchValue }) => {
  const settingsItems = useSettingsSearchModule();
  const filteredItems = useFilterSearchModuleItems({ searchValue, items: settingsItems });

  if (!filteredItems.length || !searchValue) return null;

  return <GlobalSearchCategory title='Settings' items={filteredItems} />;
});

export { SettingsModuleSearchCategory };

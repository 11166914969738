import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Coin, Trade } from '@swyftx/aviary/icons/outlined';

import { AssetHistoryItem, TransactionType } from '@shared/api';
import { DepositStatusEnum, WithdrawalStatusEnum } from '@shared/enums';

const useTransferOrderType = () => {
  const { t } = useTranslation('orders');

  const parseTransferOrderType = useCallback(
    (transaction: AssetHistoryItem) => {
      switch (transaction.type) {
        case TransactionType.Deposit:
          return {
            title: t('types.DEPOSIT'),
            icon: (
              <div className='rounded-[8px] bg-color-background-info-subtle p-4'>
                <Coin className='h-18 w-18 text-color-text-accent' />
              </div>
            ),
          };
        case TransactionType.Withdrawal:
          return {
            title: t('types.WITHDRAWAL'),
            icon: (
              <div className='rounded-[8px] bg-color-background-info-subtle p-4'>
                <Coin className='h-18 w-18 text-color-text-accent' />
              </div>
            ),
          };
        default:
          return {
            title: t('types.na'),
            icon: (
              <div className='rounded-[8px] bg-color-background-info-subtle p-4'>
                <Trade className='h-18 w-18 text-color-text-accent' />
              </div>
            ),
          };
      }
    },
    [t],
  );

  const parseTransferStatus = useCallback(
    (transaction: AssetHistoryItem): { status: string; color: 'info' | 'success' | 'destructive' | undefined } => {
      switch (transaction.statusRaw) {
        case DepositStatusEnum.PENDING:
        case DepositStatusEnum.PENDING_FIAT_CONVERTED:
        case DepositStatusEnum.PENDING_DAILY_LIMIT:
        case DepositStatusEnum.PENDING_NOT_CREDITED:
        case DepositStatusEnum.PENDING_EXTENDED_VERIFICATION:
        case DepositStatusEnum.PENDING_DISABLED:
        case DepositStatusEnum.PENDING_TERMINAL:
        case DepositStatusEnum.NOT_VERIFIED:
        case WithdrawalStatusEnum.PENDING:
        case WithdrawalStatusEnum.PENDING_FIAT_FX:
        case WithdrawalStatusEnum.PENDING_DAILY_LIMIT:
        case WithdrawalStatusEnum.PENDING_NOT_CREDITED:
        case WithdrawalStatusEnum.PENDING_EXTENDED_VERIFICATION:
        case WithdrawalStatusEnum.PENDING_DISABLED:
        case WithdrawalStatusEnum.NOT_VERIFIED:
        case WithdrawalStatusEnum.PENDING_LIQUIDITY:
        case WithdrawalStatusEnum.BOUNCED:
          return { status: t('status.pending'), color: 'info' };

        case DepositStatusEnum.UNSEEN:
        case WithdrawalStatusEnum.PROCESSING:
          return { status: t('status.processing'), color: 'info' };

        case DepositStatusEnum.FAILED_REJECTED:
        case DepositStatusEnum.FAILED_UNKNOWN:
        case DepositStatusEnum.FAILED_MIN_DEPOSIT:
        case DepositStatusEnum.POLI_CLAWED_BACK:
        case WithdrawalStatusEnum.FAILED_REJECTED:
        case WithdrawalStatusEnum.FAILED_UNKNOWN:
        case WithdrawalStatusEnum.FAILED_MIN_WITHDRAWAL:
          return { status: t('status.cancelled'), color: 'destructive' };

        case DepositStatusEnum.CREDITED:
        case WithdrawalStatusEnum.CREDITED:
          return { status: t('status.completed'), color: 'success' };

        case DepositStatusEnum.REFUNDED:
        case WithdrawalStatusEnum.REFUNDED:
          return { status: t('status.refunded'), color: 'destructive' };

        case DepositStatusEnum.CANCELLED:
          return { status: t('status.cancelled'), color: 'destructive' };

        default:
          return { status: 'N/A', color: 'info' };
      }
    },
    [t],
  );

  return {
    parseTransferOrderType,
    parseTransferStatus,
  };
};

export { useTransferOrderType };

import EventListener from './EventListener';

export class Listener {
  eventListener: EventListener;

  fn: (...args: any[]) => void;

  constructor(eventListener: EventListener, fn: (...args: any[]) => void) {
    this.eventListener = eventListener;
    this.fn = fn;
  }

  unsubscribe() {
    this.eventListener.removeListener(this.fn);
  }
}

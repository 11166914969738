import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EditInSquare, PaperUpload } from '@swyftx/aviary/icons/outlined';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { VerifyTile } from '../VerifyTile';

const VerifyFundModal: React.FC = observer(() => {
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const { t } = useTranslation('profile.verification');

  const onClose = () => setVerificationStep(VerificationStep.None);

  return (
    <>
      <Modal
        open
        id='verify-fund-modal'
        HeaderProps={{
          title: `Verify your funds`,
          dismissible: true,
          divider: true,
        }}
        sx={{
          width: '600px',
        }}
        FooterProps={{
          divider: true,
          content: (
            <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2}>
              <Button variant='text' color='inherit' onClick={onClose}>
                {t('verificationMethod.verifyFund.modal.buttonLabels.cancel')}
              </Button>
              <Button variant='contained' color='primary' onClick={() => setVerificationStep(VerificationStep.Funds)}>
                {t('verificationMethod.verifyFund.modal.buttonLabels.continue')}
              </Button>
            </Stack>
          ),
        }}
        onClose={onClose}
      >
        <Stack direction='column' alignItems='start' spacing={2}>
          <Typography fontSize={18} color='text.secondary' marginBottom={2}>
            {t('verificationMethod.verifyFund.modal.subTitle')}
          </Typography>
          <Typography fontWeight={600} fontSize={18}>
            {t('verificationMethod.verifyFund.modal.involved.title')}
          </Typography>

          <VerifyTile
            leading={<EditInSquare color='primary' height='20px' width='20px' />}
            title={t('verificationMethod.verifyFund.modal.involved.details.title')}
            subTitle={t('verificationMethod.verifyFund.modal.involved.details.subTitle')}
          />

          <VerifyTile
            leading={<PaperUpload color='primary' height='20px' width='20px' />}
            title={t('verificationMethod.verifyFund.modal.involved.documents.title')}
            subTitle={t('verificationMethod.verifyFund.modal.involved.documents.subTitle')}
          />
        </Stack>
      </Modal>
    </>
  );
});

export { VerifyFundModal };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';

type Props = {
  asset: Asset;
};

const LimitedTradeNotification: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleAsset' });

  const buyRestricted = useMemo(() => asset.buyDisabled, [asset]);
  const sellRestricted = useMemo(() => !asset.sellEnabled, [asset]);

  const content = useMemo(() => {
    if (buyRestricted && sellRestricted) {
      return t('limitedTrade.tradeDisabled', { asset: asset.name });
    }

    if (buyRestricted && !sellRestricted) return t('limitedTrade.buyDisabled', { asset: asset.name });

    if (!buyRestricted && sellRestricted) return t('limitedTrade.sellDisabled', { asset: asset.name });

    return null;
  }, [asset, buyRestricted, sellRestricted, t]);

  if (!buyRestricted && !sellRestricted) return null;

  return (
    <Body size='small' className='inline'>
      {content}
    </Body>
  );
};

export default LimitedTradeNotification;

import React from 'react';

import { Card, CardContent, CardHeader, CardTitle } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List, ListItem } from '@swyftx/aviary/atoms/List';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { FiatCodeEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { cn } from '@shared/utils/lib/ui';

import { useGainersAndLosers } from '@hooks/Assets/useGainersAndLosers';
import { useMultiAssetData } from '@hooks/Assets/useMultiAssetData';
import { formatCandleData } from '@utils/assets';

import * as Sentry from '@sentry/react';

import { LoadingMovers } from './AssetGroupCard.data';
import { AssetGroupCardLineChart } from './AssetGroupCardLineChart';

type Props = {
  title?: string;
  assets: Asset[];
  forceChart?: boolean;
  border?: boolean;
  baseAsset: Asset;
  numAssets: number;
  emptyContent: React.ReactNode;
  className?: string;
  loaded: boolean;
  actions?: React.ReactNode;
  variant?: 'full' | 'compact';
  style?: React.CSSProperties;
  onSelectAsset: (asset: Asset) => void;
};

const AssetGroupCard: React.FC<Props> = ({
  title,
  emptyContent,
  forceChart = false,
  border,
  assets,
  baseAsset,
  numAssets,
  loaded,
  className,
  variant = 'full',
  actions,
  style,
  onSelectAsset,
}) => {
  const { getPriceChangePercentage } = useGainersAndLosers();

  const assetData = useMultiAssetData({
    assets,
    baseAsset: baseAsset?.code ?? FiatCodeEnum.AUD,
    side: 'ask',
    resolution: '1h',
  });

  const openAsset = (asset: Asset) => {
    onSelectAsset(asset);
  };

  return (
    <Card shadow={false} border={border} style={style} className={cn(`min-w-[96%]`, className)}>
      {(title || actions) && (
        <CardHeader
          className={`@xs:px-16 @xs:py-12 @xs:pl-16 @xs:pr-12 @md:px-24 @md:pb-12 @md:pt-24  ${
            border ? 'mb-8 border-b border-color-border-main' : ''
          }`}
        >
          <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
            <FlexLayout alignItems='baseline' spacing={4}>
              <CardTitle>{title}</CardTitle>
              <Utility variant='caption'>(24HR)</Utility>
            </FlexLayout>
            {actions}
          </FlexLayout>
        </CardHeader>
      )}
      <CardContent className='flex h-full w-full overflow-hidden pr-0 pt-0 @xs:mr-0 @xs:pb-4 @xs:pl-0 @md:pb-12 @md:pl-8'>
        {loaded && assets.length === 0 && emptyContent}
        {assets.length === 0 && !loaded && (
          <FlexLayout className='w-full' direction='column'>
            {LoadingMovers}
          </FlexLayout>
        )}
        {assets.length > 0 && (
          <List className='w-full pt-4'>
            {assets.slice(0, numAssets).map((asset) => {
              const value = getPriceChangePercentage(asset);

              const { formattedData } = assetData[asset.id]
                ? formatCandleData(asset, baseAsset, assetData[asset.id])
                : { formattedData: [] };

              return (
                <ListItem key={asset.code} onClick={() => openAsset(asset)}>
                  <FlexLayout alignItems='center' justifyContent='space-between' spacing={12} className='w-full'>
                    <FlexLayout
                      alignItems='center'
                      justifyContent='space-between'
                      spacing={12}
                      className='@xs:max-w-[40%] @xl:max-w-[50%]'
                    >
                      <AssetIcon asset={asset} size={20} />
                      <Body className={cn('hidden w-full truncate', variant === 'full' ? '@lg:block' : '')}>
                        {asset.name}
                      </Body>
                      <Body className='block max-w-[80px] truncate @lg:hidden'>{asset.code}</Body>
                    </FlexLayout>
                    <FlexLayout alignItems='center' justifyContent='end' spacing={24}>
                      <AssetGroupCardLineChart
                        className={forceChart ? '' : 'hidden @md:block'}
                        id={`asset-row-${asset.id}`}
                        data={formattedData}
                        color={Big(value).gte(0) ? 'success' : 'destructive'}
                        asset={baseAsset}
                      />
                      <NumericDataItem
                        data={`${value}%`}
                        percentage
                        size='medium'
                        alignItems='end'
                        className='w-[70px]'
                      />
                    </FlexLayout>
                  </FlexLayout>
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export const ProfiledAssetGroupCard = Sentry.withProfiler(AssetGroupCard);

export { AssetGroupCard };

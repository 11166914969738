import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  ChartTypeArea,
  ChartTypeBars,
  ChartTypeCandles,
  ChartTypeHeikinAsh,
  ChartTypeCandlesHollow,
  ChartTypeHighLow,
  ChartTypeHLCArea,
  ChartTypeLine,
  ChartTypeLineWithMarker,
  ChartTypeStepLine,
  ChartTypeColumns,
} from '@swyftx/aviary/icons/charts';
import { MenuItemProps } from '@swyftx/aviary/molecules/Menu';

import { TradingViewContext } from '../../../context/TradingView.context';
import { useTradingViewAnalytics } from '../../../hooks';
import { ChartStyle, chartStyleName, TradingViewChartType } from '../../../types';
import { isAdvancedChartState } from '../../../utils/Tradingview.utils';

type Props = {
  chartType: TradingViewChartType;
};

type ChartTypeItem = {
  hidden?: boolean;
} & MenuItemProps;

const useTradingViewChartTypes = ({ chartType }: Props) => {
  const { selectedChartType, setSelectedChartType, chartState, primary, secondary } = useContext(TradingViewContext);
  const { changedCandleType } = useTradingViewAnalytics({ primary, secondary });
  const [advancedChartType, setAdvancedChartType] = useState<ChartStyle>();

  const updateSelectedItemName = useCallback(
    (newChartStyle: ChartStyle) => {
      setSelectedChartType(newChartStyle);
    },
    [setSelectedChartType],
  );

  useEffect(() => {
    if (!isAdvancedChartState(chartState)) return;

    chartState?.onChartReady(() => {
      if (!chartState?.chart()) return;

      setAdvancedChartType(chartState.chart().chartType() as number as ChartStyle);
    });
  }, [chartState, updateSelectedItemName]);

  const setChartType = useCallback(
    (newChartStyle: ChartStyle) => {
      changedCandleType(
        !isAdvancedChartState(chartState) ? 'TradingView' : 'Simple Charts',
        chartStyleName[newChartStyle],
      );

      if (!isAdvancedChartState(chartState)) {
        updateSelectedItemName(newChartStyle);
        return;
      }

      chartState?.onChartReady(() => {
        if (!chartState?.chart()) return;

        chartState.chart().setChartType(newChartStyle as number);
        setAdvancedChartType(newChartStyle);
      });
    },
    [changedCandleType, chartState, updateSelectedItemName],
  );

  const items: ChartTypeItem[] = useMemo(
    () => [
      {
        text: chartStyleName[ChartStyle.Bar],
        icon: <ChartTypeBars className='h-full w-full' />,
        action: () => setChartType(ChartStyle.Bar),
      },
      {
        text: chartStyleName[ChartStyle.Candle],
        icon: <ChartTypeCandles className='h-full w-full' />,
        action: () => setChartType(ChartStyle.Candle),
      },
      {
        text: chartStyleName[ChartStyle.HollowCandle],
        hidden: chartType === 'simple',
        icon: <ChartTypeCandlesHollow className='h-full w-full' />,
        action: () => setChartType(ChartStyle.HollowCandle),
      },
      {
        text: chartStyleName[ChartStyle.Column],
        icon: <ChartTypeColumns className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.Column),
      },
      {
        text: chartStyleName[ChartStyle.Line],
        icon: <ChartTypeLine className='h-full w-full' />,
        action: () => setChartType(ChartStyle.Line),
      },
      {
        text: chartStyleName[ChartStyle.LineWithMarkers],
        icon: <ChartTypeLineWithMarker className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.LineWithMarkers),
      },
      {
        text: chartStyleName[ChartStyle.Stepline],
        icon: <ChartTypeStepLine className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.Stepline),
      },
      {
        text: chartStyleName[ChartStyle.Area],
        icon: <ChartTypeArea className='h-full w-full' />,
        action: () => setChartType(ChartStyle.Area),
      },
      {
        text: chartStyleName[ChartStyle.HLCArea],
        icon: <ChartTypeHLCArea className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.HLCArea),
      },
      {
        text: chartStyleName[ChartStyle.Baseline],
        icon: <ChartTypeCandles className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.Baseline),
      },
      {
        text: chartStyleName[ChartStyle.HiLo],
        icon: <ChartTypeHighLow className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.HiLo),
      },
      {
        text: chartStyleName[ChartStyle.HeikinAshi],
        icon: <ChartTypeHeikinAsh className='h-full w-full' />,
        hidden: chartType === 'simple',
        action: () => setChartType(ChartStyle.HeikinAshi),
      },
    ],
    [chartType, setChartType],
  );

  const selectedItem = useMemo(
    () =>
      items.find((i) => {
        if (isAdvancedChartState(chartState)) {
          if (advancedChartType === undefined) return false;

          return i.text === chartStyleName[advancedChartType];
        }
        return i.text === chartStyleName[selectedChartType];
      }),
    [advancedChartType, chartState, items, selectedChartType],
  );

  useEffect(() => {
    // If the selected chart type is going to be hidden, default to the Candlestick chart
    if (selectedItem?.hidden) {
      setChartType(ChartStyle.Candle);
    }
  }, [selectedItem, setChartType]);

  return {
    selectedItem,
    items,
  };
};

export { useTradingViewChartTypes };

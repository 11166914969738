import { useCallback, useMemo } from 'react';

import { useTheme as MUIuseTheme } from '@mui/material';
import { darken, rgbToHex } from '@mui/system';

import { AssetColors } from '@utils/assets';
import { getVisibleColor } from '@utils/color';

const useTheme = () => {
  const theme = MUIuseTheme();

  const isLightTheme = useMemo(() => theme.palette.mode === 'light', [theme]);

  const getContrastAssetColor = useCallback(
    (code: string) => {
      const color = AssetColors[code];
      if (!color) return color;

      const hexColor = rgbToHex(darken(color, 0.2));

      return theme.palette.mode === 'dark' ? getVisibleColor(hexColor) : color;
    },
    [theme.palette.mode, getVisibleColor],
  );

  const getImageThemeVariant = (imageUrl: string, extension: 'png' | 'jpg' | 'jpeg' | 'svg') =>
    `${imageUrl}-${isLightTheme ? 'light' : 'dark'}.${extension}`;

  return {
    theme,
    isLightTheme,
    getContrastAssetColor,
    getImageThemeVariant,
  };
};

export { useTheme };

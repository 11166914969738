import { useCallback, useContext } from 'react';

import { AutoInvestContext } from 'src/lib/auto-invest/context';
import { useAutoInvestPercentages } from 'src/lib/auto-invest/hooks';

import { useAutoInvestAllocationCheck } from './useAutoInvestAllocationCheck';

const useAutoInvestValues = () => {
  const { selectedAutoInvestAssets, setSelectedAutoInvestAssets, setCurrentStep, onCancel, onLock } =
    useContext(AutoInvestContext);
  const { calculatePercentages } = useAutoInvestPercentages();
  const { validAssets } = useAutoInvestAllocationCheck({ autoInvestAssets: selectedAutoInvestAssets });

  const handleAddNewAsset = () => {
    setSelectedAutoInvestAssets((prev) => {
      const copy = Array.from(prev);
      copy.push({ assetCode: '', percentage: 0, locked: false });
      const copyWithPercentages = calculatePercentages(copy);
      return [...copyWithPercentages];
    });
  };

  const onBulkUpdateAssets = useCallback(
    (assetCodes: string[]) => {
      const reducedAssets = assetCodes.map((assetCode) => ({ assetCode, percentage: 0, locked: false }));
      const assetsWithPercentages = calculatePercentages(reducedAssets);
      setSelectedAutoInvestAssets(assetsWithPercentages);
    },
    [calculatePercentages, setSelectedAutoInvestAssets],
  );

  const onRemove = useCallback(
    (assetCode: string) => {
      const newSelectedAssetStates = selectedAutoInvestAssets.filter((asset) => asset.assetCode !== assetCode);
      const newSelectedAssetStatesWithPercentages = calculatePercentages(newSelectedAssetStates);
      setSelectedAutoInvestAssets(newSelectedAssetStatesWithPercentages);
    },
    [calculatePercentages, selectedAutoInvestAssets, setSelectedAutoInvestAssets],
  );

  const onUpdatePercentage = useCallback(
    (assetCode: string, percentage: number) => {
      setSelectedAutoInvestAssets((prev) => {
        const cache = Array.from(prev);
        const item = cache.find((c) => c.assetCode === assetCode);
        if (item) item.percentage = percentage;

        return calculatePercentages(cache, item);
      });
    },
    [calculatePercentages, setSelectedAutoInvestAssets],
  );

  const onUpdateAsset = useCallback(
    (oldAssetCode: string, newAssetCode: string) => {
      setSelectedAutoInvestAssets((prev) => {
        const cache = Array.from(prev);
        const item = cache.find((c) => c.assetCode === oldAssetCode);
        if (item) item.assetCode = newAssetCode;

        return cache;
      });
    },
    [setSelectedAutoInvestAssets],
  );

  return {
    selectedAutoInvestAssets,
    validAssets,
    setCurrentStep,
    onCancel,
    onLock,
    onRemove,
    handleAddNewAsset,
    onUpdateAsset,
    onUpdatePercentage,
    onBulkUpdateAssets,
  };
};

export { useAutoInvestValues };

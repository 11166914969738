import { TabItem } from '@swyftx/aviary/atoms/Tabs';

export type NewsType = 'all' | 'mine' | 'asset';

export type NewsTab = TabItem<NewsType>;

export const defaultNewsTabs: NewsTab[] = [
  {
    title: 'All news',
    value: 'all',
  },
  {
    title: 'My news',
    value: 'mine',
  },
];

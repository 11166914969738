import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ScrollView } from '@swyftx/aviary/atoms/ScrollView';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { More } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { SwyftxProOrderBookActionsMenu } from './SwyftxProOrderBookActionsMenu';
import { SwyftxProOrderBookBook } from './SwyftxProOrderBookBook';
import { SwyftxProOrderBookBookHeader } from './SwyftxProOrderBookBookHeader';
import { SwyftxProOrderbookMidPrice } from './SwyftxProOrderbookMidPrice';
import { useSwyftxProOrderbooks } from '../../hooks/useSwyftxProOrderbooks';
import { useSwyftxProTradeType } from '../../hooks/useSwyftxProTradeType';

type Props = {
  className?: string;
  selectedAsset: Asset;
  baseAsset: Asset;
  showHeader?: boolean;
};

const SwyftxProOrderBook: React.FC<Props> = ({ className, selectedAsset, baseAsset, showHeader = true }) => {
  const { isLimit } = useSwyftxProTradeType();
  const { asks, bids, midPrice, isLoading, onClickValue } = useSwyftxProOrderbooks({ selectedAsset, baseAsset });

  return (
    <ScrollView className='h-full w-full min-w-[22.25rem] @lg:w-auto'>
      <FlexLayout
        data-spotlightelementid='pro-orderbook'
        className={cn('bg-color-background-surface-primary px-12 pb-12', className)}
        alignItems='start'
        spacing={8}
        direction='column'
        justifyContent='start'
      >
        <FlexLayout className='sticky top-0 z-10 w-full bg-color-background-surface-primary' direction='column'>
          <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
            {showHeader && (
              <>
                <FlexLayout className='min-h-[3rem]' alignItems='center'>
                  <Heading size='h6' className='uppercase'>
                    Order book
                  </Heading>
                </FlexLayout>

                <SwyftxProOrderBookActionsMenu>
                  <Button layout='icon' variant='ghost' leadingIcon={<More />} />
                </SwyftxProOrderBookActionsMenu>
              </>
            )}
          </FlexLayout>
          <SwyftxProOrderBookBookHeader baseAsset={baseAsset} />
        </FlexLayout>

        {asks && bids && (
          <FlexLayout direction='column' className='w-full'>
            <SwyftxProOrderBookBook
              orderbook={asks}
              midPrice={midPrice}
              loading={isLoading}
              side='ask'
              selectedAsset={selectedAsset}
              baseAsset={baseAsset}
              onClick={isLimit ? onClickValue : undefined}
            />
            <SwyftxProOrderbookMidPrice midPrice={midPrice} loading={isLoading} baseAsset={baseAsset} />
            <SwyftxProOrderBookBook
              onClick={isLimit ? onClickValue : undefined}
              loading={isLoading}
              orderbook={bids}
              midPrice={midPrice}
              side='bid'
              selectedAsset={selectedAsset}
              baseAsset={baseAsset}
            />
          </FlexLayout>
        )}
      </FlexLayout>
    </ScrollView>
  );
};

export { SwyftxProOrderBook };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const List: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.50893 3.39372C3.48201 3.60726 3.47999 3.88018 3.47999 4.27067C3.47999 4.66338 3.48058 4.93651 3.50626 5.15177C3.53136 5.36219 3.5727 5.43321 3.59381 5.45968C3.60743 5.47675 3.65627 5.53245 3.85618 5.58237C4.06606 5.63478 4.38525 5.66634 4.87566 5.66634C5.36607 5.66634 5.68526 5.63478 5.89514 5.58237C6.09505 5.53245 6.14389 5.47675 6.15751 5.45968C6.17861 5.43321 6.21996 5.36219 6.24506 5.15177C6.27074 4.93651 6.27133 4.66338 6.27133 4.27067C6.27133 3.88018 6.26931 3.60726 6.24238 3.39372C6.21608 3.18505 6.17394 3.11258 6.15116 3.08432C6.13537 3.06476 6.08393 3.00868 5.88507 2.95884C5.67628 2.90651 5.35995 2.875 4.87566 2.875C4.39137 2.875 4.07504 2.90651 3.86625 2.95884C3.66739 3.00868 3.61594 3.06476 3.60016 3.08432C3.57737 3.11258 3.53524 3.18505 3.50893 3.39372ZM3.56236 1.74635C3.92036 1.65662 4.35772 1.625 4.87566 1.625C5.39359 1.625 5.83096 1.65662 6.18896 1.74635C6.55689 1.83856 6.88533 2.00348 7.12417 2.29963C7.35599 2.58708 7.44319 2.92506 7.48257 3.23736C7.52133 3.54478 7.52133 3.90335 7.52133 4.27067C7.52133 4.28364 7.52133 4.2966 7.52133 4.30955C7.52138 4.66117 7.52143 5.005 7.48626 5.29984C7.44921 5.61039 7.36546 5.94981 7.13478 6.23906C6.89661 6.5377 6.56698 6.70299 6.19797 6.79513C5.83894 6.88478 5.39878 6.91634 4.87566 6.91634C4.35254 6.91634 3.91238 6.88478 3.55335 6.79513C3.18434 6.70299 2.85471 6.5377 2.61654 6.23906C2.38586 5.94981 2.30211 5.61039 2.26506 5.29984C2.22989 5.005 2.22994 4.66118 2.22998 4.30956C2.22999 4.29661 2.22999 4.28364 2.22999 4.27067C2.22999 3.90335 2.22999 3.54478 2.26875 3.23736C2.30812 2.92506 2.39533 2.58708 2.62715 2.29963C2.86598 2.00348 3.19442 1.83856 3.56236 1.74635Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5213 4.27067C10.5213 3.92549 10.8012 3.64567 11.1463 3.64567H21.1463C21.4915 3.64567 21.7713 3.92549 21.7713 4.27067C21.7713 4.61585 21.4915 4.89567 21.1463 4.89567H11.1463C10.8012 4.89567 10.5213 4.61585 10.5213 4.27067Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.50893 10.873C3.48201 11.0866 3.47999 11.3595 3.47999 11.75C3.47999 12.1427 3.48058 12.4158 3.50626 12.6311C3.53136 12.8415 3.5727 12.9125 3.59381 12.939C3.60743 12.9561 3.65627 13.0118 3.85618 13.0617C4.06606 13.1141 4.38525 13.1457 4.87566 13.1457C5.36607 13.1457 5.68526 13.1141 5.89514 13.0617C6.09505 13.0118 6.14389 12.9561 6.15751 12.939C6.17861 12.9125 6.21996 12.8415 6.24506 12.6311C6.27074 12.4158 6.27133 12.1427 6.27133 11.75C6.27133 11.3595 6.26931 11.0866 6.24238 10.873C6.21608 10.6644 6.17394 10.5919 6.15116 10.5637C6.13537 10.5441 6.08393 10.488 5.88507 10.4382C5.67628 10.3858 5.35995 10.3543 4.87566 10.3543C4.39137 10.3543 4.07504 10.3858 3.86625 10.4382C3.66739 10.488 3.61594 10.5441 3.60016 10.5637C3.57737 10.5919 3.53524 10.6644 3.50893 10.873ZM3.56236 9.22568C3.92036 9.13595 4.35772 9.10433 4.87566 9.10433C5.39359 9.10433 5.83096 9.13595 6.18896 9.22568C6.55689 9.31789 6.88533 9.48281 7.12417 9.77896C7.35599 10.0664 7.44319 10.4044 7.48257 10.7167C7.52133 11.0241 7.52133 11.3827 7.52133 11.75C7.52133 11.763 7.52133 11.7759 7.52133 11.7889C7.52138 12.1405 7.52143 12.4843 7.48626 12.7792C7.44921 13.0897 7.36546 13.4291 7.13478 13.7184C6.89661 14.017 6.56698 14.1823 6.19797 14.2745C5.83894 14.3641 5.39878 14.3957 4.87566 14.3957C4.35254 14.3957 3.91238 14.3641 3.55335 14.2745C3.18434 14.1823 2.85471 14.017 2.61654 13.7184C2.38586 13.4291 2.30211 13.0897 2.26506 12.7792C2.22989 12.4843 2.22994 12.1405 2.22998 11.7889C2.22999 11.7759 2.22999 11.763 2.22999 11.75C2.22999 11.3827 2.22999 11.0241 2.26875 10.7167C2.30812 10.4044 2.39533 10.0664 2.62715 9.77896C2.86598 9.48281 3.19442 9.31789 3.56236 9.22568Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5213 11.75C10.5213 11.4048 10.8012 11.125 11.1463 11.125H21.1463C21.4915 11.125 21.7713 11.4048 21.7713 11.75C21.7713 12.0952 21.4915 12.375 21.1463 12.375H11.1463C10.8012 12.375 10.5213 12.0952 10.5213 11.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.50893 18.3524C3.48201 18.5659 3.47999 18.8388 3.47999 19.2293C3.47999 19.622 3.48058 19.8952 3.50626 20.1104C3.53136 20.3208 3.5727 20.3919 3.59381 20.4183C3.60743 20.4354 3.65627 20.4911 3.85618 20.541C4.06606 20.5934 4.38525 20.625 4.87566 20.625C5.36607 20.625 5.68526 20.5934 5.89514 20.541C6.09505 20.4911 6.14389 20.4354 6.15751 20.4183C6.17861 20.3919 6.21996 20.3208 6.24506 20.1104C6.27074 19.8952 6.27133 19.622 6.27133 19.2293C6.27133 18.8388 6.26931 18.5659 6.24238 18.3524C6.21608 18.1437 6.17394 18.0712 6.15116 18.043C6.13537 18.0234 6.08393 17.9673 5.88507 17.9175C5.67628 17.8652 5.35995 17.8337 4.87566 17.8337C4.39137 17.8337 4.07504 17.8652 3.86625 17.9175C3.66739 17.9673 3.61594 18.0234 3.60016 18.043C3.57737 18.0712 3.53524 18.1437 3.50893 18.3524ZM3.56236 16.705C3.92036 16.6153 4.35772 16.5837 4.87566 16.5837C5.39359 16.5837 5.83096 16.6153 6.18896 16.705C6.55689 16.7972 6.88533 16.9621 7.12417 17.2583C7.35599 17.5457 7.44319 17.8837 7.48257 18.196C7.52133 18.5034 7.52133 18.862 7.52133 19.2293C7.52133 19.2423 7.52133 19.2553 7.52133 19.2682C7.52138 19.6198 7.52143 19.9637 7.48626 20.2585C7.44921 20.569 7.36546 20.9085 7.13478 21.1977C6.89661 21.4964 6.56698 21.6616 6.19797 21.7538C5.83894 21.8434 5.39878 21.875 4.87566 21.875C4.35254 21.875 3.91238 21.8434 3.55335 21.7538C3.18434 21.6616 2.85471 21.4964 2.61654 21.1977C2.38586 20.9085 2.30211 20.569 2.26506 20.2585C2.22989 19.9637 2.22994 19.6198 2.22998 19.2682C2.22999 19.2553 2.22999 19.2423 2.22999 19.2293C2.22999 18.862 2.22999 18.5034 2.26875 18.196C2.30812 17.8837 2.39533 17.5457 2.62715 17.2583C2.86598 16.9621 3.19442 16.7972 3.56236 16.705Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5213 19.2293C10.5213 18.8841 10.8012 18.6043 11.1463 18.6043H21.1463C21.4915 18.6043 21.7713 18.8841 21.7713 19.2293C21.7713 19.5745 21.4915 19.8543 21.1463 19.8543H11.1463C10.8012 19.8543 10.5213 19.5745 10.5213 19.2293Z'
      fill='currentColor'
    />
  </svg>
);

export { List };

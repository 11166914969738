import React, { PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

import { FlexLayoutProps } from '../Layout/Flex/FlexLayout.styles';
import { ScrollView } from '../ScrollView';

type Props = {
  className?: string;
  onScroll?: (e: Event) => void;
} & Pick<FlexLayoutProps, 'spacing'>;

const List = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({ className, children, onScroll }) => (
  <ScrollView onScroll={onScroll} className={cn('h-full w-full pl-4 pr-12 @md:pr-12', className)}>
    {children}
  </ScrollView>
));

List.displayName = 'List';

export { List };

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';

import { Tick } from '@swyftx/aviary/icons/outlined';
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuItemIcon,
  pxToRem,
  Stack,
  Typography,
} from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { NoSearchResultsFound } from '@global-components/EmptyStates';
import { SearchInput } from '@global-components/Input';

import { Asset } from '@shared/api';
import useDebounce from '@shared/hooks/lib/useDebounce';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { Tabs, WalletPickerTabId } from './AppHeaderWalletPicker.types';

type Props = {
  anchorEl: null | HTMLElement;
  type?: 'all' | 'trade' | 'earn';
  currentAsset: string;
  onClose: () => void;
  onAssetClick?: (asset: Asset) => void;
} & Omit<MenuProps, 'open'>;

const INPUT_DEBOUNCE = 500;
const INPUT_HEIGHT = '36px';

const AppHeaderWalletPickerMenu: React.FC<Props> = observer(
  ({ anchorEl, currentAsset, onClose, onAssetClick, ...props }) => {
    const [assetSearch, setAssetSearch] = useState<string>('');
    const { balances } = UserStore.useUserStore;
    const [tab, setTab] = useState<WalletPickerTabId>(WalletPickerTabId.Owned);
    const { navigate } = useNavigateRoute();
    const { t } = useTranslation('wallet');

    const walletInformation = balances;
    const walletKeys = Object.keys(walletInformation).map(Number);
    const wallets = walletKeys.map(assetService.getAsset);
    const ownedAssets: Asset[] = wallets.filter((asset) => asset !== undefined) as Asset[];
    const favouriteAssets = useFavouriteAssets();
    const allAssets = assetService.getActiveAssetList();

    const onSelectAsset = (asset: Asset) => {
      if (onAssetClick) {
        onAssetClick(asset);
      } else {
        navigate(NavigationURLs.SingleWallet, {
          pathParams: { type: 'all', asset: asset.code },
        });
      }
    };

    const debouncedAssetSearch = useDebounce(assetSearch, INPUT_DEBOUNCE);
    const filterWalletPicker = (walletTab: WalletPickerTabId) => {
      switch (walletTab) {
        case WalletPickerTabId.Favourites:
          return favouriteAssets;
        case WalletPickerTabId.All:
          return allAssets;
        case WalletPickerTabId.Owned:
        default:
          return ownedAssets;
      }
    };

    const assets = useMemo(() => {
      const walletsToShow = filterWalletPicker(tab);
      const searchWallets = walletsToShow.filter(
        (asset) =>
          asset.code.toLowerCase().includes(debouncedAssetSearch.toLowerCase()) ||
          asset.name.toLowerCase().includes(debouncedAssetSearch.toLowerCase()),
      );

      return searchWallets.sort((a, b) => a.code.localeCompare(b.code));
    }, [balances, debouncedAssetSearch, tab]);

    return (
      <Menu
        {...props}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => onClose()}
        PaperProps={{ variant: 'outlined', elevation: 0 }}
      >
        <Box key='asset-search' sx={{ padding: 1.5 }} onKeyDown={(e) => e.stopPropagation()}>
          <SearchInput
            id='appHeader-wallet-picker-menu'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAssetSearch(e.target.value);
            }}
            onDelete={() => {
              setAssetSearch('');
            }}
            placeholder={t('walletPickerMenu.searchPlaceholder')}
            InputProps={{
              sx: {
                height: INPUT_HEIGHT,
                fontSize: pxToRem(12),
                paddingLeft: 1,
              },
            }}
            value={assetSearch}
          />
        </Box>
        <Grid item xs={12}>
          <Stack direction='row' paddingX={1.5} spacing={1} sx={{ overflowX: 'auto' }}>
            {Tabs.map((assetTab) => (
              <Button
                id={assetTab.id}
                key={assetTab.label}
                variant='text'
                sx={{
                  '&.MuiLoadingButton-root': {
                    color: assetTab.id === tab ? 'text.primary' : 'text.secondary',
                    backgroundColor: assetTab.id === tab ? 'action.hover' : '',
                    fontWeight: assetTab.id === tab ? '500' : '400',
                  },
                  '.MuiSvgIcon-root': {
                    color: assetTab.id === tab ? 'primary.main' : 'text.secondary',
                    marginRight: 1,
                  },
                }}
                onClick={() => setTab(assetTab.id)}
              >
                {t(assetTab.label as any)}
              </Button>
            ))}
          </Stack>
        </Grid>
        {/* When favourites is empty (if on single asset page, asset list would be loaded and you own at least one asset) */}
        {assets.length === 0 && WalletPickerTabId.Favourites && (
          <Box maxWidth='220px' padding={2} textAlign='center'>
            <NoSearchResultsFound
              title={t('walletPickerMenu.emptyFavourites.title')}
              subTitle={t('walletPickerMenu.emptyFavourites.subTitle')}
              isSmall
            />
          </Box>
        )}
        <Box paddingTop={1.5}>
          {assets &&
            assets.map(
              (asset) =>
                asset && (
                  <MenuItem
                    key={asset.id}
                    onClick={() => onSelectAsset(asset)}
                    sx={{ padding: 1.5 }}
                    disabled={currentAsset.toLowerCase() === asset.code.toLowerCase()}
                  >
                    <MenuItemIcon sx={{ marginRight: 1 }}>
                      <AssetIcon asset={asset} size={20} wallet />
                    </MenuItemIcon>
                    <Typography
                      fontWeight={currentAsset.toLowerCase() === asset.code.toLowerCase() ? 600 : 400}
                      color='text.primary'
                    >
                      {asset.code.toUpperCase()}
                    </Typography>
                    {currentAsset.toLowerCase() === asset.code.toLowerCase() && (
                      <MenuItemIcon sx={{ marginLeft: 1 }}>
                        <Tick className='h-20 w-20 text-color-text-accent' />
                      </MenuItemIcon>
                    )}
                  </MenuItem>
                ),
            )}
        </Box>
      </Menu>
    );
  },
);

export { AppHeaderWalletPickerMenu };

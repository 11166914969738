import React from 'react';

import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const AssetMonthlyChange: React.FC<Props> = observer(({ asset }) => (
  <NumericDataItem
    className='truncate'
    data={`${asset.priceChange.month.toFixed(2) || '0.0'}%`}
    renderIcons={false}
    percentage
    size='small'
    alignItems='end'
  />
));

export { AssetMonthlyChange };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { UniversalTradeStore } from '@shared/store';
import { TradeSide, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { AssetFilter } from '@hooks/Assets/useAssets';

type Props = {
  side: TradeSide;
  selectedFilter: AssetFilter;
  setSelectedFilter: React.Dispatch<React.SetStateAction<AssetFilter>>;
};

type AssetFilterTab = EnhancedTab<AssetFilter>;

const TradeAssetSelectorFilter: React.FC<Props> = ({ side, selectedFilter, setSelectedFilter }) => {
  const { tradeFrom, tradeType } = UniversalTradeStore;

  const assetFilterTabs: AssetFilterTab[] = useMemo(
    () => [
      {
        title: 'All',
        value: AssetFilter.All,
        disabled: side === TradeSide.From && tradeType === TradeType.Instantly,
      },
      {
        title: 'Owned',
        value: AssetFilter.Owned,
        disabled: side !== TradeSide.From && tradeType === TradeType.Instantly,
      },
      {
        title: 'Favourites',
        value: AssetFilter.Favourites,
        disabled: side === TradeSide.From && tradeType === TradeType.Instantly,
      },
      {
        title: 'Bundles',
        value: AssetFilter.Bundles,
        disabled: side === TradeSide.From || tradeFrom.length >= 2,
      },
    ],
    [side, tradeFrom.length, tradeType],
  );

  return (
    <FlexLayout direction='column' className='justify-between! w-full'>
      <EnhancedTabs<AssetFilter>
        variant='child'
        tabs={assetFilterTabs}
        onChange={setSelectedFilter}
        value={selectedFilter}
      />
    </FlexLayout>
  );
};

export { TradeAssetSelectorFilter };

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { Form } from '@global-components/Form';
import Recaptcha from '@global-components/Recaptcha';

import { UserStore } from '@shared/store';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

type Props = {
  thirdParty?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const ApiKeyConfirmPasswordStep: React.FC<Props> = observer(({ thirdParty, onClose, onSubmit }) => {
  const { newApiKeyPayload, setNewApiKeyPayload, createNewApiKey } = useContext(ApiKeysContext);
  const { confirmPassword } = UserStore.useUserStore;
  const { t } = useTranslation('profile.apiKey');

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleConfirmPassword = async () => {
    if (!newApiKeyPayload.password) {
      setError(true);
      return;
    }

    try {
      setSubmitting(true);
      const confirmed = await confirmPassword(newApiKeyPayload.password);

      if (!confirmed) {
        setError(true);
        setSubmitting(false);
        return;
      }

      await createNewApiKey();
      setSubmitting(false);
      onSubmit();
    } catch (e) {
      setError(true);
      setSubmitting(false);
    }
  };

  return (
    <Modal
      id='api-key-modal-warning-step'
      open
      onClose={onClose}
      sx={{ width: '600px' }}
      HeaderProps={{
        title: t(`sections.createApiKey.confirmPassword.${thirdParty ? 'thirdPartyTitle' : 'title'}`),
        dismissible: true,
        divider: true,
      }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' width='100%' spacing={2} justifyContent='flex-end'>
            <Button variant='text' color='inherit' disableElevation onClick={onClose} disabled={submitting}>
              {t('sections.createApiKey.confirmPassword.cancel')}
            </Button>

            <Button
              variant='contained'
              disabled={!newApiKeyPayload.password?.length}
              loading={submitting}
              type='submit'
              form='createApiKey.form'
            >
              {t('sections.createApiKey.confirmPassword.continue')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={3} marginY='10px'>
        <Typography id='create-api-key-authentication-subtitle' color='text.secondary' fontSize={16} fontWeight='400'>
          {t(`sections.createApiKey.confirmPassword.${thirdParty ? 'thirdPartySubtitle' : 'subtitle'}`)}
        </Typography>
        <Form
          id='createApiKey.form'
          onSubmit={handleConfirmPassword}
          setSubmitting={setSubmitting}
          submitting={submitting}
          hideButton
        >
          <Input
            id='api-key-confirm-password'
            ContainerProps={{ sx: { marginBottom: '.6rem' } }}
            error={error}
            helperText={error && t('sections.createApiKey.confirmPassword.error')}
            InputProps={{ sx: { height: '48px', fontSize: '14px' } }}
            onChange={(e) => setNewApiKeyPayload({ password: e.target.value })}
            onFocus={() => setError(false)}
            placeholder={t('sections.createApiKey.confirmPassword.placeholder')}
            sx={{ height: '48px', width: '100%', marginBottom: error ? 3 : 1 }}
            type='password'
            value={newApiKeyPayload.password}
          />
          {thirdParty && (
            <Stack direction='row' alignItems='flex-start' width='100%' marginBottom='25px'>
              <Typography
                id='create-api-key-authentication-notice'
                color='text.secondary'
                fontSize={14}
                fontWeight='400'
              >
                {t('sections.createApiKey.confirmPassword.thirdPartyNotice')}
              </Typography>
            </Stack>
          )}
          <Recaptcha id='createApiKey.recaptcha' />
        </Form>
      </Stack>
    </Modal>
  );
});

export { ApiKeyConfirmPasswordStep };

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useAvo } from '@hooks/Avo/useAvo';

import { NavigationURLs } from 'src/lib/navigation/types';

const useAppSideBarAvoEvents = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const isSm = useTailwindBreakpoint('sm');

  const trackRouteNavigation = useCallback(
    (route: NavigationURLs) => {
      if (!route) return;

      const layout = isSm ? 'mobile' : 'desktop';
      // Fire avo event based on route
      switch (route) {
        case NavigationURLs.UniversalTrade:
          avo.tradeNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.AssetsAll:
          avo.assetListNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.Bundles:
          avo.bundlesNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.AutoInvest:
          avo.recurringOrderOpened({
            screen: pathname,
            layout,
            source: 'sidebar',
          });
          break;
        case NavigationURLs.TransactionsRoot:
          avo.orderHistoryNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.WithdrawSend:
          avo.withdrawSendNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.DepositReceive:
          avo.depositReceiveNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.WalletsRoot:
          avo.walletsNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.Dashboard:
          avo.dashboardNavClicked({
            screen: pathname,
            layout,
          });
          break;
        case NavigationURLs.Shop:
          avo.shopNavClicked({
            screen: pathname,
            layout,
          });
          break;
        default:
          avo.untrackedItemClicked({
            screen: pathname,
            layout,
          });
          break;
      }
      if (route === NavigationURLs.DepositReceive) {
        avo.depositFundsTapped({
          screen: pathname,
          component: '',
        });
      }
    },
    [avo, isSm, pathname],
  );

  return { trackRouteNavigation };
};

export { useAppSideBarAvoEvents };

import { useMemo } from 'react';

import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

export const useUserTradeDetails = () => {
  const { userStatistics, balances } = UserStore.useUserStore;

  const depositedFiat = useMemo(() => (userStatistics ? userStatistics?.depositedFiat > 0 : false), [userStatistics]);
  const tradedCrypto = useMemo(
    () =>
      Object.keys(balances)
        .map(Number)
        .filter((wallet) => !assetService.isAssetFiat(wallet)).length > 0,
    [balances],
  );

  return {
    depositedFiat,
    tradedCrypto,
  };
};

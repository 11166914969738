import {
  api,
  Auth,
  DustUserBalanceData,
  OrderDirection,
  PasswordlessOTPParams,
  UpdateUserProfileArgs,
  UpdateUserSettingsArgs,
  UserProfile,
} from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { balanceService, initServices, ratesService, userStatisticsService } from '@shared/services';
import entityService from '@shared/services/entityService';
import storage, { StorageKey } from '@shared/storage';
import { AppStore, UserStore } from '@shared/store';

import AuthenticationService from '@services/AuthenticationService';

import jwtDecode from 'jwt-decode';

const LOG_TAG = 'UserService';

const DeleteAccount = async (): Promise<any> => {
  try {
    const res = await api.endpoints.deleteMyAccount();
    await AuthenticationService.Logout();
    return res;
  } catch (e) {
    const { error } = e as any;
    throw error?.response?.data?.error?.error;
  }
};

const InitUserProfile = async (initThirdPartyServices?: (userProfile: UserProfile) => void) => {
  try {
    const response = await GetUserProfile(initThirdPartyServices);

    if (response?.data) {
      await initServices(LOG_TAG);
    }

    // update demo mode from storage
    const { setIsDemo } = AppStore.useAppStore;

    try {
      setIsDemo((await storage.getItem(StorageKey.DEMO)) ?? false);
    } catch (error) {
      logger.error(LOG_TAG, 'Unable to get demo mode setting', error);
    }

    return response?.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    return undefined;
  }
};

const InitUserAuth = async () => {
  const { setAuth } = UserStore.useUserStore;

  try {
    // Also Init the User with the Personal Access Token
    let storageAccessToken = await storage.getItem(StorageKey.PERSONAL_ACCESS_TOKEN);

    // Try and grab the access token out of angular site if we have no access token
    if (!storageAccessToken) {
      storageAccessToken = sessionStorage.getItem(StorageKey.ANGULAR_ACCESS_TOKEN);
      storage.setItem(StorageKey.PERSONAL_ACCESS_TOKEN, JSON.stringify(storageAccessToken));
    }

    const decoded = jwtDecode(storageAccessToken) as any;

    /** TODO: expand on auth object with any needed values */
    const auth: Auth = {
      access_token: storageAccessToken,
      scope: decoded.scope,
      expires_in: decoded.exp,
    };

    setAuth(auth);
    return auth;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    return undefined;
  }
};

const GetUserProfile = async (initThirdPartyServices?: (userProfile: UserProfile) => void) => {
  const { setUserProfile, userAuth } = UserStore.useUserStore;

  try {
    if (!userAuth) {
      const auth = await InitUserAuth();
      if (!auth?.access_token) return undefined;
    }

    api.endpoints.getProfile.resetCache();
    const userProfile = await api.endpoints.getProfile();
    setUserProfile(userProfile?.data.user.profile);

    const currentAccount = await storage.getItem(StorageKey.CURRENT_ACCOUNT);
    const currentAccountColor = await storage.getItem(StorageKey.CURRENT_ACCOUNT_COLOR);

    if (
      currentAccount &&
      currentAccount !== 'USER' &&
      currentAccount !== userProfile.data.user.profile.intercom?.uuid
    ) {
      entityService.switchAccounts(
        currentAccount,
        currentAccountColor,
        () => {
          entityService.updateMembers();
          userStatisticsService.forceUpdate();
          balanceService.forceUpdate();
        },
        initThirdPartyServices,
      );
    }

    return userProfile;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    return undefined;
  }
};

/**
 * Updates user settings
 * NOTE: UpdateUserSettingsArgs (these settings differ from UserSettings a bit) ->
 * -> if you need to update other settings you may need to expand this type and check if api supports
 *
 * @param data UpdateUserSettingsArgs
 * @returns userData
 */
const UpdateUserSettings = async (data: UpdateUserSettingsArgs) => {
  const { setUserSettings } = UserStore.useUserStore;

  try {
    const response = await api.endpoints.updateUserSettings({ data });
    const userProfile = response.data as UserProfile;
    const {
      profile: { userSettings },
    } = userProfile;

    setUserSettings(userSettings);
    return userSettings;
  } catch (error: any) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw error?.response?.data?.error?.error;
  }
};

const UpdateUserProfile = async (data: UpdateUserProfileArgs, headers?: Record<string, string>) => {
  try {
    const res = await api.endpoints.updateUserProfile({ data, headers });
    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

/**
 * Changes the user's default currency
 *
 * @param assetId asset id of currency to change to
 * @returns api response
 */
const UpdateUserCurrency = async (assetId: number) => {
  try {
    const userCurrency = await api.endpoints.updateUserCurrency({
      data: {
        profile: {
          defaultAsset: assetId,
          previous: 0,
        },
      },
    });

    ratesService.forceUpdate();

    return userCurrency;
  } catch (error: any) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw error?.response?.data?.error?.error;
  }
};

const ChangePassword = async (oldPassword: string, newPassword: string, auth?: string) => {
  try {
    const res = await api.endpoints.changePassword({
      data: { changePassword: { oldPassword, newPassword }, auth },
    });
    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const RequestEmailVerification = async () => {
  try {
    const res = await api.endpoints.requestEmailVerification();
    api.endpoints.getProfile.resetCache();

    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const CheckEmailVerification = async () => {
  try {
    const res = await api.endpoints.checkEmailVerification();
    api.endpoints.getProfile.resetCache();

    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const VerifyEmailOTP = async (email: string, code?: string) => {
  const data: PasswordlessOTPParams = {
    email,
    code,
  };

  try {
    const res = await api.endpoints.verifyEmailOTP({ data });

    return res.data;
  } catch (e) {
    const error = e as SwyftxError;
    logger.error(LOG_TAG, error.errorMessage);

    throw error;
  }
};

const RequestPhoneVerification = async () => {
  try {
    const res = await api.endpoints.requestPhoneVerificationPin();
    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const CheckPhoneVerification = async (pin: string) => {
  try {
    const params = {
      pin,
    };
    const res = await api.endpoints.checkPhoneVerificationPin({ params, headers: {} });
    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const CheckDustLimit = async () => {
  try {
    const response = await api.endpoints.checkDustLimit();
    return response.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const DustBalance = async (assetsToDust: number[], baseAsset: number) => {
  try {
    const data: DustUserBalanceData = {
      selected: assetsToDust,
      primary: baseAsset,
    };

    const response = await api.endpoints.dustUserBalance({ data });
    await balanceService.forceUpdate();
    return response;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const IsEntity = (userProfile: UserProfile) =>
  userProfile && // if user profile exists (I.e. not logged out)
  !(
    (
      userProfile?.profile?.entityDetails &&
      userProfile?.profile?.entityDetails.entityType &&
      userProfile?.profile?.entityDetails.entityType === 'PERSONAL'
    ) // if account is of entity type of personal
  );

const GetFees = async (primaryAssetId: number, secondaryAssetId: number, direction: OrderDirection) => {
  try {
    const res = await api.endpoints.getFees({
      params: { primary: primaryAssetId, secondary: secondaryAssetId, direction },
    });
    return res.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const UserService = {
  DeleteAccount,
  GetUserProfile,
  InitUserProfile,
  UpdateUserSettings,
  UpdateUserProfile,
  UpdateUserCurrency,
  ChangePassword,
  RequestEmailVerification,
  CheckEmailVerification,
  VerifyEmailOTP,
  RequestPhoneVerification,
  CheckPhoneVerification,
  CheckDustLimit,
  DustBalance,
  IsEntity,
  GetFees,
};
export default UserService;

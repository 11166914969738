import logger from '@shared/logger';
import { hideSensitive, isDev, isFunction, normaliseTag } from '@shared/utils';

import axios, { AxiosError } from 'axios';

import GenericError from './GenericError';

/* eslint-disable
  @typescript-eslint/no-unused-expressions,
  import/first,
  import/order,
  import/no-extraneous-dependencies,
  import/newline-after-import
*/
/* eslint-enable */

const logTag = '[ERROR_HANDLER]';

export class SwyftxError extends Error {
  errorTag = '';

  error: Error = new Error();

  errorMessage = '';

  isSwyftxError = true;

  logged = false;

  constructor(errorTag: string, errorMessage: string, error?: any) {
    super();
    this.setTag(errorTag);
    this.setMessage(errorMessage);
    this.setError(error);
  }

  public get serverError(): AxiosError<any> | undefined {
    if (axios.isAxiosError(this.error)) {
      return this.error as AxiosError<any>;
    }
    return undefined;
  }

  public setMessage(content?: string) {
    if (content) this.errorMessage = content;
    return this;
  }

  public setTag(tag?: string) {
    if (tag) this.errorTag = normaliseTag(tag);
    return this;
  }

  public setError(error: any) {
    if (error) {
      this.error = this.isError(error) ? (this.error = error) : (this.error = new GenericError(error));

      // Maintains proper stack trace for where our error was thrown
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this.error);
      }
    }
  }

  public log(logToConsole = true) {
    if (this.logged) {
      logger.warn(logTag, 'Already logged error');
      return this;
    }
    // log error locally if applicable
    if (isDev && logToConsole) logger.log(this.errorTag, this.errorMessage, this.error);

    this.logged = true;

    return this;
  }

  private isError(error: any) {
    if (
      Object.prototype.toString.call(error) === '[object Error]' ||
      axios.isAxiosError(error) ||
      GenericError.isGenericError(error)
    ) {
      return true;
    }

    return error instanceof Error;
  }

  /**
   * Remove some unnecessary noise from errors
   */
  private cleanErrorObject(extracted: any) {
    let cleaned = extracted;
    const keys = Object.keys(cleaned);
    if (!keys) return;

    // remove axios noise
    delete cleaned.config?.adapter;
    delete cleaned.config?.headers;
    delete cleaned.config?.transformRequest;
    delete cleaned.config?.transformResponse;
    delete cleaned.config?.validateStatus;
    delete cleaned.request?.DONE;
    delete cleaned.request?.HEADERS_RECEIVED;
    delete cleaned.request?.LOADING;
    delete cleaned.request?.OPENED;
    delete cleaned.request?.UNSENT;
    delete cleaned.request?._performanceLogger;
    delete cleaned.request?._response;
    delete cleaned.request?._responseType;
    delete cleaned.request?._lowerCaseResponseHeaders;
    delete cleaned.response?.request;
    delete cleaned.response?.config;

    // axios data is stringified json
    if (cleaned.config?.data) {
      try {
        if (typeof cleaned.config.data === 'string' || cleaned.config.data instanceof String) {
          cleaned.config.data = JSON.parse(cleaned.config?.data);
        }
      } catch {
        // if it fails delete it so we aren't accidentally sending sensitive info.
        cleaned.config.data = {};
      }
    }

    // remove any functions
    cleaned = this.removeFunctions(cleaned);

    // hide sensitive info
    return hideSensitive(cleaned);
  }

  /**
   * Recursively remove functions from object
   */
  private removeFunctions(extracted: any) {
    const cleaned = extracted;
    const keys = Object.keys(cleaned);
    let i = keys.length;

    while (i--) {
      const key = keys[i];
      if (cleaned[key] !== null && typeof cleaned[key] === 'object' && !Array.isArray(cleaned)) {
        this.removeFunctions(cleaned[key]);
      } else if (isFunction(cleaned[key])) {
        delete cleaned[key];
      }
    }

    return cleaned;
  }

  /**
   * Process and upload error object to Crashlytics
   */
  private uploadToCrashlytics() {
    // add error details to breadcrumbs
    // crashlytics is not good at pulling unknown data out of js errors
    // so we'll do this manually
    const ignoredKeys = ['stack'];
    const extracted: any = {};

    Object.getOwnPropertyNames(this.error).forEach((key: string) => {
      if (
        !ignoredKeys.includes(key) &&
        (this.error as any)[key] !== undefined &&
        !isFunction((this.error as any)[key])
      ) {
        extracted[key] = (this.error as any)[key];
      }
    });

    const cleaned = this.cleanErrorObject(extracted);

    logger.debug(logTag, 'Error to upload', JSON.stringify(cleaned, null, 2));
  }
}

export const isSwyftxError = (error?: any): error is SwyftxError => (error as SwyftxError)?.isSwyftxError;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Game, Settings, TrendLineUp } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { UIStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { SwyftxThemeSelector } from 'src/lib/layout';
import { useDemoMode } from 'src/lib/utils/hooks/useDemoMode';

const AppHeaderSettings: React.FC = observer(() => {
  const { isDemo, setDemoMode } = useDemoMode();
  const { setShowMarketTicker, showMarketTicker } = UIStore.useUIStore;
  const { t } = useTranslation('common');

  return (
    <Menu
      triggerContent={
        <Button
          id='header-settings'
          data-spotlightelementid='header-settings'
          variant='outlined'
          layout='icon'
          leadingIcon={<Settings />}
        />
      }
    >
      <FlexLayout direction='column'>
        <MenuItem
          icon={<Game width={24} height={24} />}
          text={t('navigationMenu.demo')}
          endAdornment={<Switch checked={isDemo} color='warning' />}
          action={() => setDemoMode(!isDemo)}
          data-spotlightelementid='settings-menu-demo'
          preventCloseOnSelect
        />
        <SwyftxThemeSelector spotlightElementId='settings-menu-theme' />
        <MenuItem
          icon={<TrendLineUp width={24} height={24} />}
          text={t('navigationMenu.marketTicker')}
          endAdornment={<Switch checked={showMarketTicker} />}
          action={() => setShowMarketTicker(!showMarketTicker)}
          preventCloseOnSelect
        />
      </FlexLayout>
    </Menu>
  );
});

AppHeaderSettings.displayName = 'AppHeaderSettings';

export { AppHeaderSettings };

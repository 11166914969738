import React, { useMemo } from 'react';

import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart';
import { DonutChartItem } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart.types';
import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { AssetColors } from '@utils/assets';

type Props = {
  asset: Asset;
  percentage: Big;
};

const AutoInvestDonutChart: React.FC<Props> = ({ asset, percentage }) => {
  const { externalMutations, setExternalMutations } = useDonutChartMutations();

  const chartData = useMemo(
    (): DonutChartItem[] => [
      {
        y: percentage.toNumber(),
        color: asset.color || AssetColors[asset.code],
        title: '',
      },
      {
        y: Big(100).minus(percentage).toNumber(),
        color: '',
        title: '',
      },
    ],
    [asset.code, asset.color, percentage],
  );

  return (
    <Tooltip title={`${percentage.toString()}%`}>
      <div className='h-34 w-34'>
        <DonutChart
          id={asset.code}
          innerRadius={164}
          showTooltip={false}
          data={chartData}
          cornerRadius={0}
          gap={0}
          externalMutations={externalMutations}
          setExternalMutations={setExternalMutations}
        >
          <Numeric size='xxsmall' weight='emphasis' className='height-12 select-none text-center leading-normal'>
            {percentage.toFixed(0)}%
          </Numeric>
        </DonutChart>
      </div>
    </Tooltip>
  );
};

export { AutoInvestDonutChart };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { observer } from 'mobx-react-lite';
import { useCategories } from 'src/lib/categories/hooks';

import { AssetCategoriesTab } from './AssetCategoriesTab';
import { MoreCategoriesPopover } from './MoreCategoriesPopover';

const PROMOTED_CATEGORIES = [
  'Layer 1 (L1)',
  'Decentralized Finance (DeFi)',
  'NFT',
  'Meme',
  'Artificial Intelligence (AI)',
];

interface Props {
  selectedCategoryId?: string;
  activePath: boolean;
}

export const AssetCategoriesTabs: React.FC<Props> = observer((props) => {
  const { selectedCategoryId, activePath } = props;
  const { categories } = useCategories();

  const displayCategories = useMemo(() => {
    const promotedCategories = categories.filter((category) => PROMOTED_CATEGORIES.includes(category.name));
    return promotedCategories;
  }, [categories]);

  const otherCategories = useMemo(() => {
    const reducedDisplayCategories = displayCategories.map((category) => category.name);
    return categories.filter((category) => !reducedDisplayCategories.includes(category.name));
  }, [categories, displayCategories]);

  return (
    <FlexLayout direction='row' className='w-full' spacing={8}>
      {displayCategories.map((displayCategory) => (
        <AssetCategoriesTab
          key={displayCategory.id}
          category={displayCategory}
          selected={activePath && selectedCategoryId === displayCategory.id.toString()}
        />
      ))}
      <MoreCategoriesPopover otherCategories={otherCategories} />
    </FlexLayout>
  );
});

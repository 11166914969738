import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { BuySellButtons } from '@global-components/BuySellButtons';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';

type Props = {
  asset: Asset;
  countryAsset: Asset;
};

const PortfolioTableActions: React.FC<Props> = ({ asset, countryAsset }) => {
  const { openModal } = useModal();

  const depositFiat = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedAsset: Asset) => {
      e.preventDefault();
      e.stopPropagation();

      openModal(Modals.DepositReceive, { selectedAsset });
    },
    [openModal],
  );

  return (
    <FlexLayout justifyContent='end' spacing={4}>
      {asset.id === countryAsset.id && (
        <Button size='sm' variant='outlined' onClick={(e) => depositFiat(e, asset)}>
          Deposit
        </Button>
      )}
      {asset.id !== countryAsset.id && (
        <BuySellButtons asset={asset} variant='aviary-subtle' openTradePanel size='sm' />
      )}
    </FlexLayout>
  );
};

export { PortfolioTableActions };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { Button, Menu, Stack, Typography } from '@swyftx/react-web-design-system';

import { EntityRemoveModal } from '@global-components/Modals/EntityRemoveModal/EntityRemoveModal';

import { EntityMember } from '@shared/store/userStore/@types/userTypes';

type Props = {
  member: EntityMember;
};

const EntityMemberMoreOptions: React.FC<Props> = ({ member }) => {
  const { t } = useTranslation('profile');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [removeMember, setRemoveMember] = useState(false);

  const openMore = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction='row' justifyContent='flex-end'>
        <Button icon onClick={(e) => openMore(e)}>
          <MoreHorizIcon sx={{ color: 'text.secondary' }} />
        </Button>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '164px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '.MuiMenu-list': { padding: '0px !important' },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack direction='column' spacing={1} padding={1}>
          <Button
            color='error'
            onClick={() => setRemoveMember(true)}
            sx={{ borderRadius: '8px', width: '100%', justifyContent: 'flex-start' }}
          >
            <Typography fontWeight='500' fontSize={14} color='error.main'>
              {t('entities.entityMembers.button.remove')}
            </Typography>
          </Button>
        </Stack>
      </Menu>

      {removeMember && (
        <EntityRemoveModal
          open
          onClose={() => {
            setRemoveMember(false);
            setAnchorEl(null);
          }}
          entityMember={member}
        />
      )}
    </>
  );
};

export { EntityMemberMoreOptions };

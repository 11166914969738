import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationModals } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { EntityMembersActionItem } from './components/EntityMembersActionItem/EntityMembersActionItem';
import { EntityMembersList } from './components/EntityMembersTable/EntityMembersList';
import { useEntityMembers } from './useEntityMembers.hooks';

const ProfileEntityMembers: React.FC = observer(() => {
  const { isAdmin } = useEntityMembers();
  const { verificationStep } = useContext(ProfileVerificationContext);

  return (
    <>
      <FlexLayout direction='column' spacing={2}>
        <EntityMembersActionItem isAdmin={isAdmin} />
        <SettingsCluster
          title='Entity Members'
          items={[<EntityMembersList key='entityMembersList' isAdmin={isAdmin} />]}
        />
      </FlexLayout>
      {VerificationModals[verificationStep]}
    </>
  );
});

ProfileEntityMembers.displayName = 'EntityMembers';

export { ProfileEntityMembers };

import { HTMLAttributes } from 'react';

import { VariantProps, cva } from 'cva';

export type UtilityProps = Omit<HTMLAttributes<HTMLParagraphElement> & VariantProps<typeof utilityVariants>, 'style'>;

export const utilityVariants = cva('', {
  variants: {
    variant: {
      overline: 'font-sans text-10 font-500 line-height-12 letter-spacing-expanded text-case-uppercase',
      caption: 'font-sans text-10 font-400 line-height-12 letter-spacing-base',
    },
    color: {
      accent: 'text-color-text-accent',
      primary: 'text-color-text-primary',
      tertiary: 'text-color-text-tertiary',
      secondary: 'text-color-text-secondary',
      selected: 'text-color-text-selected',
      white: 'text-white',
      error: 'text-color-text-error',
      inverse: 'text-color-text-inverse',
    },
  },
  defaultVariants: {
    variant: 'overline',
    color: 'secondary',
  },
});

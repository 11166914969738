import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';
import { AppStore, RatesStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useGetDailyMovement } from '@hooks/Trade/useGetDailyMovement';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { MarketTickerSettings } from './MarketTickerSettings';
import { MovingMarketTicker } from './MovingMarketTicker';
import { StaticMarketTicker } from './StaticMarketTicker';
import { MarketTickerMode } from './marketTicker.consts';
import { MarketTickerSortingOptions } from '../consts';
import { useMarketTickerSort } from '../hooks/useMarketTickerSort';
import { useMarketTickerAnalytics } from '../useMarketTickerAnalytics';

interface Props {
  onClose: () => void;
}

export const AviaryMarketTicker: React.FC<Props> = observer((props) => {
  const { onClose } = props;
  const [localStorageTickerMode, setLocalMarketTickerMode] = useLocalStorage<MarketTickerMode>(
    StorageKey.MARKET_TICKER_MODE,
    MarketTickerMode.STATIC,
  );
  const tickerMode = localStorageTickerMode || MarketTickerMode.STATIC;
  const { getRate } = RatesStore.useRatesStore;
  const { getDailyMovement } = useGetDailyMovement();
  const baseAsset = useBaseAsset();
  const isBaseBTC = baseAsset?.code === 'BTC';

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const scrollingMarketTickerEnabled = isFeatureEnabled(AppFeature.ScrollingMarketTicker);
  const { marketTickerAssetClicked, marketTickerToggled, marketTickerVariantChanged } = useMarketTickerAnalytics();

  const isStatic = localStorageTickerMode == MarketTickerMode.STATIC;

  const { sortedAssets, sort, onSort } = useMarketTickerSort(tickerMode);
  const { assetsInitialized } = AppStore.useAppStore;
  const { navigateToAsset } = useAssetNavigation();

  const handleClose = useCallback(() => {
    marketTickerToggled({ toggleState: false });
    onClose();
  }, [marketTickerToggled, onClose]);

  const handleClick = useCallback(
    (asset: Asset) => {
      marketTickerAssetClicked({
        asset,
        sort,
        variant: tickerMode,
        percentageChange: getDailyMovement(asset).toString(),
        price: getRate(asset.id).midPrice,
      });

      navigateToAsset(asset);
    },
    [getDailyMovement, getRate, marketTickerAssetClicked, navigateToAsset, sort, tickerMode],
  );

  const toggleMode = useCallback(() => {
    const newMode =
      localStorageTickerMode === MarketTickerMode.MOVING ? MarketTickerMode.STATIC : MarketTickerMode.MOVING;
    marketTickerVariantChanged({ sort, variant: newMode });
    setLocalMarketTickerMode(newMode);
  }, [localStorageTickerMode, marketTickerVariantChanged, setLocalMarketTickerMode, sort]);

  const sortingText = useMemo(() => {
    switch (sort) {
      case MarketTickerSortingOptions.GAINERS:
      case MarketTickerSortingOptions.LOSERS:
      case MarketTickerSortingOptions.MARKET_CAP:
      case MarketTickerSortingOptions.NEW:
        return 'assets';
      case MarketTickerSortingOptions.OWNED:
        return 'owned assets';
      case MarketTickerSortingOptions.FAVOURITES:
        return 'favourites';
      default:
        sort satisfies never;
    }
  }, [sort]);

  return (
    <FlexLayout
      direction='row'
      className='flex h-32 w-full border-b border-color-border-main bg-color-background-surface-primary px-8'
    >
      <FlexLayout className={cn(isStatic ? 'flex w-[calc(100%-2rem)]' : '')}>
        {assetsInitialized && (
          <>
            {!isStatic && scrollingMarketTickerEnabled && (
              <MovingMarketTicker
                assets={sortedAssets}
                onClick={handleClick}
                isBaseBTC={isBaseBTC}
                sort={sort}
                sortingText={sortingText}
              />
            )}
            {(isStatic || !scrollingMarketTickerEnabled) && (
              <StaticMarketTicker
                assets={sortedAssets}
                sort={sort}
                onClick={handleClick}
                isBaseBTC={isBaseBTC}
                sortingText={sortingText}
              />
            )}
          </>
        )}
      </FlexLayout>

      <FlexLayout
        direction='row'
        className={cn(
          'px-6',
          isStatic
            ? 'flex w-32 items-center justify-end'
            : 'absolute right-0 flex-row items-end gap-4 bg-color-background-surface-primary py-5 pl-4',
        )}
      >
        <MarketTickerSettings
          isStatic={isStatic}
          toggleMode={toggleMode}
          onClose={handleClose}
          sort={sort}
          setSort={onSort}
        />
      </FlexLayout>
    </FlexLayout>
  );
});

import React, { createContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/react-web-design-system';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api, APIKey, CreateAPIKeyResponse } from '@shared/api';
import apiKeyHelpers, { CreateAPIKeyType, GroupedScope } from '@shared/api/lib/helpers/apiKeys.helper';
import { UIStore, UserStore } from '@shared/store';

import { fetchApiKeyPreset, formatKeys } from '@routes/Profile/subroutes/ApiKeys/CreateApiKey/CreateApiKey.utils';
import {
  CreateApiKeyPayload,
  defaultApiKeyPayload,
} from '@routes/Profile/subroutes/ApiKeys/CreateApiKey/types/CreateApiKey.types';

import { ApiKeysInitialValues, ApiKeysStoreSchema } from '@Profile/subroutes/ApiKeys/types';

import { action } from 'mobx';
import { useLocalObservable } from 'mobx-react-lite';
import { useApiKeysCache } from 'src/lib/user-profile/hooks/useApiKeysCache';

export const ApiKeysContext = createContext<ApiKeysStoreSchema>(ApiKeysInitialValues);

export const ApiKeysProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { addMessageBox } = UIStore.useUIStore;
  const { isEntity } = UserStore.useUserStore;
  const { t } = useTranslation('profile.apiKey');
  const { invalidateCache } = useApiKeysCache();

  const store = useLocalObservable(
    (): ApiKeysStoreSchema => ({
      /* observables */
      ...ApiKeysInitialValues,

      setShowCreateNewApiKey: action((createApiKey: boolean) => {
        store.showCreateNewApiKey = createApiKey;
      }),

      setApiKeys: action((keys: APIKey[]) => {
        store.apiKeys = [...keys];
      }),

      revokeKey: action(async (apiKey: APIKey) => {
        try {
          const data = {
            keyId: apiKey.id,
            keyRef: apiKey.keyRef,
          };

          await api.endpoints.revokeAPIKey({ data });

          addMessageBox({
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
            content: <SuccessMessageBox title={t('sections.messageBoxes.revoke.success.title')} />,
          });
        } catch (e) {
          // TODO: error

          addMessageBox({
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
            content: (
              <ErrorMessageBox
                title={t('sections.messageBoxes.revoke.error.title')}
                content={t('sections.messageBoxes.revoke.error.subtitle')}
                footer={
                  <Button variant='text' onClick={() => store.revokeKey(apiKey)} sx={{ width: 'fit-content' }}>
                    {t('sections.messageBoxes.retry')}
                  </Button>
                }
              />
            ),
          });

          throw e;
        } finally {
          invalidateCache();
        }
      }),

      revokeAllKeys: action(async () => {
        try {
          await api.endpoints.revokeAllAPIKeys();
          invalidateCache();

          addMessageBox({
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
            content: <SuccessMessageBox title={t('sections.messageBoxes.revokeAll.success.title')} />,
          });
        } catch (e) {
          // TODO: error

          addMessageBox({
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
            content: (
              <ErrorMessageBox
                title={t('sections.messageBoxes.revokeAll.error.title')}
                content={t('sections.messageBoxes.revokeAll.error.subtitle')}
                footer={
                  <Button variant='text' onClick={store.revokeAllKeys} sx={{ width: 'fit-content' }}>
                    {t('sections.messageBoxes.retry')}
                  </Button>
                }
              />
            ),
          });

          throw e;
        }
      }),

      createNewApiKey: action(async () => {
        if (!store.newApiKeyPayload) return;

        const { scopes, keyType, label, password } = store.newApiKeyPayload;
        const permissions = apiKeyHelpers.getSelectedPermission(scopes);

        if (!permissions.length) return; // TODO notify not valid?

        // when admin manually add app wide permission
        if (keyType === CreateAPIKeyType.Admin) permissions.push('app');

        const call = !isEntity() ? api.endpoints.createAPIKey : api.endpoints.createAPIKeyForEntity;

        try {
          const data = {
            label,
            password: password || '',
            scope: permissions,
          };

          const result = await call({ data });

          store.setCreatedApiKeyResponse(result.data);

          // refresh data
          api.endpoints.getAPIKeys.resetCache();
          store.resetNewApiKeyPayload();
          invalidateCache();

          addMessageBox({
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            content: <SuccessMessageBox title={t('sections.messageBoxes.created.success.title')} />,
          });

          return result;
        } catch (e) {
          // TODO:  error
          addMessageBox({
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            content: (
              <ErrorMessageBox
                title={t('sections.messageBoxes.created.error.title')}
                content={t('sections.messageBoxes.created.error.subtitle')}
              />
            ),
          });
        }
      }),

      setNewApiKeyPayload: action((data: Partial<CreateApiKeyPayload>) => {
        const previousKeyType = store.newApiKeyPayload.keyType;
        store.newApiKeyPayload = { ...defaultApiKeyPayload, ...store.newApiKeyPayload, ...data };

        if (data.keyType !== previousKeyType) store.setNewApiKeyPayloadScopes();
      }),

      resetNewApiKeyPayload: action(() => {
        store.newApiKeyPayload = { ...defaultApiKeyPayload };
        store.setShowAdvancedConfig(false);
      }),

      fetchApiScopes: action(async () => {
        try {
          const req = await api.endpoints.getAPIPermissions();
          const scopes = formatKeys(req.data);
          store.setNewApiKeyPayloadScopes(scopes);
        } catch (e) {
          // TODO: error
        }
      }),

      setNewApiKeyPayloadScopes: action((newScopes?: GroupedScope) => {
        const { keyType, scopes } = store.newApiKeyPayload;

        const presetData = fetchApiKeyPreset(newScopes || scopes, keyType);

        store.setNewApiKeyPayload({
          keyType: presetData?.keyType,
          scopes: presetData?.scopes,
        });
      }),

      setShowAdvancedConfig: action((show: boolean) => (store.showAdvancedConfig = show)),

      toggleAllApiScopes: action((toggle: boolean) => {
        const localScopes = { ...store.newApiKeyPayload.scopes };
        // set all selected
        apiKeyHelpers.toggleAll(localScopes, toggle);

        store.setNewApiKeyPayload({
          keyType: apiKeyHelpers.getKeyType(localScopes),
          scopes: localScopes,
        });
      }),

      setCreatedApiKeyResponse: action((response?: CreateAPIKeyResponse) => {
        store.createdApiKeyResponse = response;
      }),
    }),
  );

  return <ApiKeysContext.Provider value={store}>{children}</ApiKeysContext.Provider>;
};

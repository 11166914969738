import React, { useEffect, useRef, useMemo } from 'react';

import { useResize } from '@swyftx/aviary/hooks/useResize';

import { cn } from '@shared/utils/lib/ui';

import * as d3 from 'd3';

import { LINE_CHART_SETTINGS } from './LineChart.const';
import { LineChartMargins, LineChartProps } from './LineChart.types';
import { useBuildCostBasis } from './hooks/useBuildCostBasis';
import { useBuildGradientAndLine } from './hooks/useBuildGradientAndLine';
import { useBuildOverlays } from './hooks/useBuildOverlays';
import { useBuildTooltip } from './hooks/useBuildTooltip';
import { useCalculationUtils } from './hooks/useCalculationUtils';
import { useDrawAxis } from './hooks/useDrawAxis';
import { useLineDefinitions } from './hooks/useLineDefinitions';
import { mockLineChartData } from './mocks/priceData';

const { defaults, yAxisPaddingFactor } = LINE_CHART_SETTINGS;

const axisMargins: LineChartMargins = {
  marginTop: 20,
  marginLeft: 45,
  marginRight: 0,
  marginBottom: 20,
};

const emptyMargins: LineChartMargins = {
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
};

// TODO Refactor this to be an entirely aviary component (remove all references to MUI)
export const LineChart: React.FC<LineChartProps> = ({
  strokeWidth = defaults.strokeWidth,
  xTicks = defaults.xTicks,
  yTicks = defaults.yTicks,
  disableLoadingAnimation,
  removeYAxisPadding,
  idKey = 'default',
  disableAreaFill,
  costBasis: costBasisOrUndefined,
  areaColor,
  chartData,

  smoothing,
  overlays,
  showAxes,
  loading,
  currencyCode,
  currencyType,
  tooltipCallback,
  className,
}) => {
  const margins = showAxes ? axisMargins : emptyMargins;
  const { width, height } = useResize({ elementId: idKey });
  const ref = useRef<SVGSVGElement | null>(null);
  const chartRef = useRef<SVGSVGElement | null>(null);

  const usedChartData = loading ? mockLineChartData : chartData;
  const costBasis = useMemo(() => costBasisOrUndefined ?? [], [costBasisOrUndefined]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const container = useMemo(() => d3.select(ref.current), [ref.current]);

  const overlayData = useMemo(() => (!loading && overlays?.map((overlay) => overlay.data)) || [], [loading, overlays]);

  const { getX, getY, x, y, valueArea, valueLine, color } = useCalculationUtils({
    smoothing,
    chartData: usedChartData,
    width,
    height,
    margins,
    removeYAxisPadding,
    yAxisPaddingFactor,
    costBasis,
    overlayData,
    loading,
    areaColor,
  });

  const { buildLineDefinitions } = useLineDefinitions({ container, chartData: usedChartData, idKey, valueArea });
  const { drawAxisElements } = useDrawAxis({
    idKey,
    container,
    showAxes,
    margins,
    width,
    height,
    y,
    loading,
    x,
    xTicks,
    yTicks,
    chartData: usedChartData,
    currencyCode,
    currencyType,
  });

  const { buildTooltip, tooltipPointX } = useBuildTooltip({
    idKey,
    tooltipCallback,
    chartData,
    width,
    height,
    x,
    y,
    loading,
    areaColor: color,
    getX,
    getY,
    costBasis,
    overlayData,
    overlays,
  });

  const { buildGradientAndLine } = useBuildGradientAndLine({
    container,
    chartData: usedChartData,
    width,
    height,
    idKey,
    valueLine,
    strokeWidth,
    loading,
    areaColor,
    color,
    xPos: tooltipPointX.current,
  });

  const { buildCostBasis } = useBuildCostBasis({ container, valueLine, strokeWidth, costBasis });

  const { buildOverlays } = useBuildOverlays({ idKey, container, overlays, strokeWidth, valueLine });

  // Could use a decent refactor soon
  useEffect(() => {
    if (width !== Infinity && height !== Infinity) {
      buildTooltip();

      buildLineDefinitions();

      buildGradientAndLine();

      buildCostBasis();

      buildOverlays();
      drawAxisElements();
    }
  }, [
    buildCostBasis,
    buildGradientAndLine,
    buildLineDefinitions,
    buildOverlays,
    buildTooltip,
    drawAxisElements,
    height,
    width,
  ]);

  if (width === Infinity || height === Infinity) {
    return null;
  }

  // We pre-render the elements and use d3 selections rather than appends so we can leave the DOM renders to react
  // -- classnames are mostly for top-down readability
  return (
    <svg
      id={idKey}
      className={cn(
        `${loading && !disableLoadingAnimation ? 'animate-[clip-linear-loading_infinite] !duration-2000' : ''} ${
          !loading ? 'animate-[clip-linear_forwards]' : ''
        }`,
        'h-full w-full overflow-hidden',
        className,
      )}
      ref={chartRef}
      style={{
        willChange: 'clip-path',
        clipPath: 'inset(0px 0% 0px 0px)',
      }}
    >
      <g ref={ref} width={width} height={height}>
        {/* Definitions/helpers */}
        <defs className='line-definitions' />

        {!disableAreaFill ? (
          <linearGradient id={`bg-gradient-${idKey}`}>
            <stop className='top-stop' />
            <stop className='bottom-stop' />
          </linearGradient>
        ) : null}
        {/* Charts */}
        <g className='line-graph-area'>
          <g className='gradient-clip'>
            <g>
              <rect className='area-rect' />
            </g>
          </g>
        </g>
        {/* Overlays */}
        <g id={`overlays-${idKey}`}>
          {overlays?.map((overlay) => (
            <path id={`overlay-${overlay.id}`} key={overlay.id} />
          ))}
        </g>
        <path className='line-path' />

        <path id='cost-basis' />

        {/* Axis */}
        {!loading ? (
          <>
            <g id={`x-axis-${idKey}`} />
            <g id={`y-axis-${idKey}`} />
            <g id={`y-axis-grid-lines-${idKey}`} />
          </>
        ) : null}
      </g>
      {/* Tooltip */}
      {Boolean(tooltipCallback) && (
        <g>
          <line id={`tooltip-line-${idKey}`} />
          <circle id={`tooltip-point-${idKey}`} />
          <rect className={`listening-rect-${idKey}`} />
        </g>
      )}
    </svg>
  );
};

import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { cn } from '@shared/utils/lib/ui';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { UIContext } from 'src/lib/utils/context/UI.context';

import { appSideBarContainerVariants, AppSideBarProps, appSideBarVariants } from './AppSideBar.styles';
import { AppSideBarItem } from './AppSideBarItem';

const AppSideBar: React.FC<AppSideBarProps> = ({
  state,
  items,
  header,
  footer,
  className,
  itemsClassName,
  onClose,
}) => {
  const { openOnHover } = useContext(UIContext);

  return (
    <>
      <div className={cn(appSideBarContainerVariants({ state }))}>
        <div className={cn(appSideBarVariants({ state, openOnHover }), className)}>
          <OverlayScrollbarsComponent
            className='h-full'
            options={{
              scrollbars: { visibility: 'visible', autoHide: 'leave', autoHideDelay: 400, theme: 'os-theme-sidebar' },
            }}
          >
            <FlexLayout direction='column' className='h-full p-0' spacing={4}>
              {header}
              {header && <div className='mt-8 min-h-[1px] w-full bg-color-border-main md:mt-0' />}
              <FlexLayout direction='column' spacing={8} className={cn('my-8', itemsClassName)}>
                {items.map((item) => (
                  <AppSideBarItem key={item.title} item={item} />
                ))}
              </FlexLayout>

              {footer && <div className='min-h-[1px] w-full bg-color-border-main' />}
              {footer}
            </FlexLayout>
          </OverlayScrollbarsComponent>
        </div>
      </div>
      {state === 'docked' && (
        <div
          className='absolute left-0 top-0 z-10 h-full w-full cursor-pointer bg-black opacity-30 md:hidden'
          onClick={onClose}
        />
      )}
    </>
  );
};

export { AppSideBar };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Notification } from '@swyftx/aviary/icons/outlined';

import { PriceAlertStatus } from '../../PriceAlerts.types';

interface Props {
  tab: PriceAlertStatus;
}

export const NoAlerts: React.FC<Props> = (props) => {
  const { tab } = props;
  const isActive = tab === PriceAlertStatus.ACTIVE;
  return (
    <FlexLayout direction='column' spacing={8} className='items-center justify-center p-32'>
      <Notification className='h-[3rem] w-[3rem] text-color-text-accent' />
      <Body weight='emphasis'>No {isActive ? 'active' : 'triggered'} alerts</Body>
    </FlexLayout>
  );
};

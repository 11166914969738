import React from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Callout } from '@swyftx/aviary/atoms/Typography/Heading/Callout';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { Links } from '@shared/constants';
import { cn } from '@shared/utils/lib/ui';

import { useAvo } from '@hooks/Avo/useAvo';

import { BundlesList } from '@Bundles/components';

import { observer } from 'mobx-react-lite';

const Bundles: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();

  const handleLearnMoreClick = () => {
    // Fire event: Learn button clicked (Recurring Orders page)
    avo.learnArticleOpened({
      screen: pathname,
      layout: 'desktop',
      articleLink: Links.help.bundles,
      articleTitle: 'Bundles',
    });

    window.open(Links.help.bundles, '_blank', 'noopener,noreferrer');
  };

  return (
    <Page
      data-container='bundles-page'
      showCompactHeader={false}
      data-spotlightelementid='bundles-page'
      titleSpacing={0}
      className={cn('@container')}
      offset={false}
      customHeader={
        <FlexLayout justifyContent='space-between' className='flex-col gap-16 @sm:flex-row'>
          <FlexLayout direction='column' className='order-2 gap-8 @sm:order-1 @sm:w-[50%] @sm:gap-16'>
            <FlexLayout className='flex-wrap'>
              <Heading className='inline leading-normal' size='h2'>
                Diversify your portfolio in one{' '}
                <Callout className='text-color-text-accent'>simple transaction!</Callout>
              </Heading>
            </FlexLayout>

            <Body color='secondary'>
              Explore our curated bundles based on market trends and crypto categories. Customise your bundle as you
              build it by adding or removing assets, then trade all selected assets in one transaction.
            </Body>
            <Body color='secondary' size='small'>
              * Swyftx does not endorse any particular crypto asset.
            </Body>
            <div className='hidden sm:block'>
              <Button size='lg' onClick={handleLearnMoreClick}>
                Learn more
              </Button>
            </div>
          </FlexLayout>
          <div className='order-1 @sm:order-2'>
            <Image image='bundles_learn_more' usePalette={false} className='h-[128px]' />
          </div>
        </FlexLayout>
      }
    >
      <PageContent className='pb-[6rem]'>
        <FlexLayout direction='column' spacing={24}>
          <FlexLayout direction='column' spacing={24}>
            <BundlesList />
          </FlexLayout>
        </FlexLayout>
      </PageContent>
    </Page>
  );
});

export default Bundles;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ReceiveCryptoFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 12C15.7611 12 18 9.76162 18 7C18 4.23892 15.7611 2 13 2C10.2389 2 8 4.23892 8 7C8 9.76162 10.2389 12 13 12ZM11.2334 7.33353C11.0382 7.13827 11.0382 6.82169 11.2334 6.62643L12.6476 5.21221C12.8429 5.01695 13.1595 5.01695 13.3548 5.21221L14.769 6.62643C14.9642 6.82169 14.9642 7.13827 14.769 7.33353L13.3548 8.74775C13.1595 8.94301 12.8429 8.94301 12.6476 8.74775L11.2334 7.33353ZM21.9208 16.1702C21.9585 16.2828 21.9828 16.3994 21.9932 16.5176C22.0059 16.5239 21.9986 16.5335 21.9889 16.5464C21.98 16.558 21.9691 16.5724 21.9691 16.5895V16.6854C21.996 16.9677 21.9395 17.2516 21.8066 17.5026C21.6737 17.7537 21.4701 17.9609 21.2206 18.0991L13.0349 21.5855C11.8313 22.1028 10.4725 22.1372 9.24393 21.6814C7.44502 21.0104 4.59574 19.9441 2 18.9138V12.0008H5.86343C6.6025 11.9803 7.34948 12.3362 8.8301 13.0416C9.93745 13.5692 11.4552 14.2923 13.6869 15.1997C13.8604 15.3003 14.0028 15.4462 14.0986 15.6215C14.1944 15.7969 14.2399 15.9949 14.2302 16.1941C14.234 16.4183 14.1779 16.6395 14.0676 16.8352C13.9573 17.0309 13.7968 17.1942 13.6024 17.3084C13.4752 17.3367 13.3432 17.3367 13.216 17.3084L7.49331 15.3675C7.4267 15.3477 7.35671 15.3417 7.28769 15.35C7.21866 15.3582 7.15208 15.3804 7.09207 15.4152C7.03206 15.4501 6.97991 15.4968 6.93886 15.5524C6.8978 15.6081 6.86873 15.6716 6.85343 15.7389C6.81236 15.8773 6.82413 16.0259 6.88649 16.1563C6.94885 16.2867 7.05742 16.3898 7.19148 16.4457L12.9263 18.3747C13.1619 18.4444 13.4098 18.4633 13.6534 18.4303C13.897 18.3972 14.1307 18.3129 14.3388 18.183C14.5951 17.9949 14.8114 17.7583 14.9751 17.4869C15.1388 17.2154 15.2467 16.9145 15.2926 16.6015L19.7235 15.679L20.4116 15.5592C20.5509 15.4985 20.7009 15.4662 20.8529 15.4643C21.0049 15.4623 21.1558 15.4906 21.2966 15.5476C21.4373 15.6046 21.5651 15.6891 21.6724 15.796C21.7796 15.903 21.8641 16.0302 21.9208 16.1702Z'
      fill='currentColor'
    />
  </svg>
);

export { ReceiveCryptoFilled };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { LiveIndicatorIcon } from 'src/assets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import {
  SingleWalletTradingInfoChart,
  SingleWalletTradingInfoLinks,
  SingleWalletTradingInfoPair,
  SingleWalletTradingInfoPrice,
} from './components';

type SingleWalletTradingInfoProps = {
  asset: Asset;
  base?: Asset;
  showBuySellButtons?: boolean;
  showTradingInfo?: boolean;
  priceSide?: 'midPrice' | 'bidPrice' | 'askPrice';
  showAssetInfoLink?: boolean;
};

const SingleWalletTradingInfo: React.FC<SingleWalletTradingInfoProps> = observer(
  ({
    asset,
    base,
    showBuySellButtons = true,
    showTradingInfo = true,
    priceSide = 'midPrice',
    showAssetInfoLink = true,
  }) => {
    const { t } = useTranslation('wallet');
    const { getRate } = RatesStore.useRatesStore;
    const baseAsset = useBaseAsset();
    const { navigate } = useNavigateRoute();

    const { pathname } = useLocation();
    const avo = useAvo();

    const onAssetInfo = () => {
      avo.viewedSingleAssetPage({
        screen: pathname,
        assetName: asset.name,
        assetId: asset.id,
        assetCode: asset.code,
      });
      navigate(NavigationURLs.SingleAsset, { pathParams: { asset: asset.code } });
    };

    return baseAsset ? (
      <Accordion defaultValue='info' type='single' collapsible>
        <AccordionItem value='info'>
          <AccordionTrigger>
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
              <FlexLayout alignItems='center'>
                <LiveIndicatorIcon pulse={getRate(asset)[priceSide]} color='primary' />
                <Body weight='bold' className='ml-8'>
                  {t('tradingInfo.labels.title')}
                </Body>
              </FlexLayout>
              {showAssetInfoLink && (
                <Button variant='ghost' onClick={onAssetInfo}>
                  {t('tradingInfo.labels.assetInfo')}
                </Button>
              )}
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent>
            <FlexLayout spacing={8} direction='column' className='p-16 pt-0'>
              <FlexLayout direction='row' justifyContent='space-between' alignItems='start'>
                <SingleWalletTradingInfoPair asset={asset} baseAsset={base || baseAsset} priceSide={priceSide} />
              </FlexLayout>
              {showTradingInfo && <SingleWalletTradingInfoPrice asset={asset} baseAsset={base || baseAsset} />}
              <SingleWalletTradingInfoChart asset={asset} baseAsset={base || baseAsset} />
              {showBuySellButtons && <SingleWalletTradingInfoLinks asset={asset} />}
            </FlexLayout>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    ) : (
      <Notification severity='destructive' title={t('tradingInfo.labels.unableError')} />
    );
  },
);

export { SingleWalletTradingInfo };

import React from 'react';

import { FormattedText } from '@global-components/Text';

import { Big } from '@shared/safe-big';

import { PriceIncreaseProps } from './PriceIncrease.types';

export const PriceIncrease: React.FC<PriceIncreaseProps> = ({
  typographyProps: TypographyProps,
  formatCurrencyOptions,
  currencyAsset,
  defaultColor,
  forceDefault,
  amount,
}) => {
  const prefix = (() => {
    if (Big(amount).gt(0)) {
      return '+';
    }
    if (Big(amount).lt(0)) {
      return '-';
    }
    return '';
  })();

  const color = (() => {
    if (forceDefault && defaultColor) return defaultColor;

    if (Big(amount).gt(0)) {
      return 'success';
    }
    if (Big(amount).lt(0)) {
      return 'error';
    }

    return defaultColor || 'primary';
  })();

  return (
    <FormattedText
      prefix={prefix}
      value={Big(amount).abs()}
      currency={currencyAsset}
      typographyProps={{ ...TypographyProps, color }}
      formatOpts={{ ...formatCurrencyOptions, displayPriceScale: true }}
    />
  );
};

import React from 'react';

import SvgIconMUI, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

export const StyledIcon = styled(SvgIconMUI)`
  &.MuiSvgIcon-root {
    fill: none !important;
  }
`;

const TickIcon = ({ ...props }: SvgIconProps) => (
  <StyledIcon {...props}>
    <path
      d='M3 12.166l6.168 6.166L21.5 6'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledIcon>
);

export { TickIcon };

import React from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric, Utility } from '@swyftx/aviary/atoms/Typography';
import { Loading } from '@swyftx/aviary/icons/outlined';

import { FeeTier, FeeTiersResponse } from '@shared/api/@types/fees';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  feeTiers: FeeTiersResponse | undefined;
  currentTier?: number;
};

const SwyftxProFeeTiersTable: React.FC<Props> = observer(({ feeTiers, currentTier }) => {
  const countryAsset = useCountryAsset();
  const getTradingVolumeBound = (tier: FeeTier) => {
    if (!tier.lowerBound && tier.upperBound) return `< ${formatCurrency(tier.upperBound, countryAsset)}`;

    if (tier.lowerBound) return `≥ ${formatCurrency(tier.lowerBound, countryAsset)}`;
  };

  return (
    <FlexLayout direction='column'>
      <FlexLayout alignItems='center'>
        <Utility className='w-4/12 py-12 sm:w-5/12'>Tier</Utility>
        <Utility className='w-5/12 py-12 sm:w-5/12'>30 Day Trade Volume ({countryAsset?.code || 'AUD'})</Utility>
        <Utility className='w-3/12 py-12 text-right sm:w-2/12'>Trading Fee</Utility>
      </FlexLayout>
      <FlexLayout direction='column' spacing={0}>
        {!feeTiers?.length && (
          <FlexLayout className='w-full p-24' alignItems='center' justifyContent='center'>
            <Loading className='w-20 animate-spin' />
          </FlexLayout>
        )}
        {feeTiers?.map((tier) => (
          <FlexLayout key={tier.label} className='border-b border-color-border-main py-12' spacing={8}>
            <FlexLayout alignItems='center' className='w-4/12 sm:w-5/12' spacing={8}>
              <Body size='small'>{tier.label}</Body>
              {tier.tier === currentTier && (
                <Chip size='sm' className='uppercase'>
                  Current
                </Chip>
              )}
            </FlexLayout>
            <Numeric className='w-5/12 sm:w-5/12' size='small'>
              {getTradingVolumeBound(tier)}
            </Numeric>
            <Numeric className='w-3/12 text-right sm:w-2/12' size='small'>
              {tier.fee}%
            </Numeric>
          </FlexLayout>
        ))}
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxProFeeTiersTable };

import alertsEndpoints from './alertsEndpoints';
import assetServiceEndpoints from './assetServiceEndpoints';
import authEndpoints from './authEndpoints';
import brazeEndpoint from './brazeEndpoint';
import chartsEndpoints from './chartsEndpoints';
import complianceEndpoints from './complianceEndpoints';
import earnEndpoints from './earnEndpoints';
import entityApplicationEndpoints from './entityApplicationEndpoints';
import feesEndpoints from './feesEndpoints';
import historyEndpoints from './historyEndpoints';
import maintenanceEndpoints from './maintenanceEndpoints';
import marketsEndpoints from './marketsEndpoints';
import messagesEndpoints from './messagesEndpoints';
import migratorEndpoints from './migratorEndpoints';
import newsServiceEndpoints from './newsServiceEndpoint';
import otcEndpoints from './otcEndpoints';
import portfolioEndpoints from './portfolioEndpoints';
import reactEndpoints from './reactEndpoints';
import recurringOrderEndpoints from './recurringOrderEndpoints';
import tradeEndpoints from './tradeEndpoints';
import userEndpoints from './userEndpoints';
import verificationEndpoints from './verificationEndpoints';

export default {
  ...alertsEndpoints,
  ...assetServiceEndpoints,
  ...authEndpoints,
  ...userEndpoints,
  ...portfolioEndpoints,
  ...recurringOrderEndpoints,
  ...tradeEndpoints,
  ...maintenanceEndpoints,
  ...marketsEndpoints,
  ...historyEndpoints,
  ...messagesEndpoints,
  ...chartsEndpoints,
  ...reactEndpoints,
  ...brazeEndpoint,
  ...verificationEndpoints,
  ...earnEndpoints,
  ...newsServiceEndpoints,
  ...otcEndpoints,
  ...migratorEndpoints,
  ...feesEndpoints,
  ...complianceEndpoints,
  ...entityApplicationEndpoints,
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';

import { Links } from '@shared/constants';

import { RegisterContext } from '@routes/Register/Register.context';
import { RegisterTranslationKey } from '@routes/Register/translations';

import { observer } from 'mobx-react-lite';

const AcceptTermsAndConditions: React.FC = observer(() => {
  const { termsAccepted, setTermsAccepted } = useContext(RegisterContext);
  const { t } = useTranslation(RegisterTranslationKey);

  return (
    <FlexLayout id='setPassword.form.terms.container' className='mt-8 w-full py-8 text-12 sm:text-14'>
      <FlexLayout spacing={8} alignItems='center'>
        <Checkbox
          id='setPassword.form.terms'
          checked={termsAccepted}
          onCheckedChange={(checked: boolean) => setTermsAccepted(checked)}
        />
        <FlexLayout spacing={4}>
          <Body size='small'>{t('setPassword.labels.terms')}</Body>
          <BodyLink size='small' color='accent' href={Links.legal.tos} className='hover:underline'>
            {t('setPassword.labels.termsOfService')}
          </BodyLink>
          <Body size='small'>{t('setPassword.labels.and')}</Body>
          <BodyLink size='small' color='accent' href={Links.legal.privacyPolicy} className='hover:underline'>
            {t('setPassword.labels.privacyPolicy')}
          </BodyLink>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

AcceptTermsAndConditions.displayName = 'AcceptTermsAndConditions';

export { AcceptTermsAndConditions };

import React from 'react';

import { Stack } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';

import { TradeInputHeaderBalance } from './TradeInputHeaderBalance';
import { Balance } from '../TradeInput.data';

type Props = {
  from: Asset;
  balanceToUse: Balance;
  showBalances?: boolean;
  hasNoFunds?: boolean;
};

const TradeInputHeader: React.FC<Props> = ({ from, balanceToUse, showBalances = true, hasNoFunds }) => (
  <Stack direction='row' alignItems='center' justifyContent='space-between' height='100%' width='100%' spacing={1}>
    <Stack direction='row' alignItems='center' justifyContent='start' spacing={1}>
      {showBalances && <TradeInputHeaderBalance from={from} balanceToUse={balanceToUse} error={hasNoFunds} />}
    </Stack>
  </Stack>
);

export { TradeInputHeader };

import { UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';

import { WalletType } from '@Wallet/types';

export type BalanceKey = 'all' | 'availableBalance' | 'stakingBalance';

export const getBalanceValue = (balance: UserBalance, balanceKey: BalanceKey) => {
  if (balanceKey === 'all') {
    return Big(balance?.availableBalance).plus(Big(balance?.stakingBalance)).toString();
  }
  return balance?.[balanceKey];
};

export const getBalanceKey = (walletType: WalletType): BalanceKey => {
  switch (walletType) {
    case WalletType.EARNING_WALLETS:
      return 'stakingBalance';
    case WalletType.TRADING_WALLETS:
      return 'availableBalance';
    case WalletType.ALL_WALLETS:
    default:
      return 'all';
  }
};

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

import { TriggerType } from '../../types/profitStopLoss.types';

export type TrackOrderCreatedProps = {
  relatedOrderUuid: string;
  placedOrderUuid: string;
  calculationType: 'target' | 'triggerPrice';
  calculationValueType: 'dollar' | 'percentage';
  estimatedPercentage: string;
  estimatedProfit: string;
};

const useProfitStopLossAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const trackProfitStopLossStarted = useCallback(() => {
    avo.openedProfitAndStopLossModal({
      screen: pathname,
    });
  }, [avo, pathname]);

  const trackProfitStopLossClosed = useCallback(() => {
    avo.closedProfitAndStopLossModal({
      screen: pathname,
    });
  }, [avo, pathname]);

  const trackOrderCreated = useCallback(
    (
      triggerType: TriggerType,
      {
        relatedOrderUuid,
        placedOrderUuid,
        calculationType,
        calculationValueType,
        estimatedPercentage,
        estimatedProfit,
      }: TrackOrderCreatedProps,
    ) => {
      const calcValueType = calculationType === 'triggerPrice' ? 'percentage' : calculationValueType;

      if (triggerType === TriggerType.StopLoss) {
        avo.createdStopLoss({
          screen: pathname,
          relatedOrderUuid,
          placedOrderUuid,
          calculationType,
          calculationValueType: calcValueType,
          estimatedPercentage,
          estimatedLoss: estimatedProfit,
        });
      } else {
        avo.createdTakeProfit({
          screen: pathname,
          relatedOrderUuid,
          placedOrderUuid,
          calculationType,
          calculationValueType: calcValueType,
          estimatedPercentage,
          estimatedProfit,
        });
      }
    },
    [avo, pathname],
  );

  return {
    trackProfitStopLossStarted,
    trackProfitStopLossClosed,
    trackOrderCreated,
  };
};

export { useProfitStopLossAnalytics };

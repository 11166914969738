import React, { useCallback } from 'react';

import { Card, CardContent, CardHeader } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';

type Props = {
  title: string;
  titleAlignment?: 'start' | 'space-between';
  titleItemsAlignment?: 'start' | 'center';
  actions?: React.ReactNode;
  cardWrapper?: boolean;
  className?: string;
  titleDirection?: 'column' | 'row';
};

const ElementContainer = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      title,
      actions,
      cardWrapper = false,
      titleAlignment = 'space-between',
      titleItemsAlignment = 'center',
      children,
      className,
      titleDirection = 'row',
    },
    ref,
  ) => {
    const renderChildren = useCallback(() => {
      if (!cardWrapper) return children;

      return (
        <FlexLayout direction='column' alignItems='start' spacing={12} justifyContent={titleAlignment}>
          {title && (
            <Heading size='h5' className='whitespace-nowrap'>
              {title}
            </Heading>
          )}
          <Card className='w-full'>
            <CardHeader className='pb-8 pt-16'>
              <FlexLayout alignItems='center' spacing={12} justifyContent={titleAlignment}>
                {actions}
              </FlexLayout>
            </CardHeader>
            <CardContent className='!pt-0 @xs:p-8 sm:p-16'>{children}</CardContent>
          </Card>
        </FlexLayout>
      );
    }, [actions, cardWrapper, children, title, titleAlignment]);

    return (
      <FlexLayout ref={ref} direction='column' spacing={12} className={className}>
        {!cardWrapper && (title || actions) && (
          <FlexLayout
            alignItems={titleItemsAlignment}
            spacing={12}
            justifyContent={titleAlignment}
            direction={titleDirection}
          >
            <Heading size='h5' className='whitespace-nowrap'>
              {title}
            </Heading>
            {actions}
          </FlexLayout>
        )}
        {renderChildren()}
      </FlexLayout>
    );
  },
);
ElementContainer.displayName = 'ElementContainer';

export { ElementContainer };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import { Asset, SortDirection } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { DynamicPriceAlertAssetFilter } from 'src/lib/dynamic-price-alerts/components/DynamicPriceAlertFilters/DynamicPriceAlertAssetFilter';
import { DynamicPriceAlertTypeFilter } from 'src/lib/dynamic-price-alerts/components/DynamicPriceAlertFilters/DynamicPriceAlertTypeFilter';
import {
  PriceAlertGroupBy,
  PriceAlertTriggerType,
  PriceAlertType,
} from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';

import { DynamicPriceAlertsTabs } from '../DynamicPriceAlertsTabs';

type Props = {
  className?: string;
  currentTab: PriceAlertType;
  setCurrentTab: React.Dispatch<React.SetStateAction<PriceAlertType>>;
  showAssetFilter?: boolean;
  showDeleteAlerts?: boolean;
  showDeleteHistory?: boolean;
  groupBy?: PriceAlertGroupBy;
  filterTypes: PriceAlertTriggerType[];
  filterAssets?: Asset[];
  alertType: PriceAlertType;
  dateSort?: SortDirection;
  hasNoAlerts?: boolean;
  setDateSort?: (dateSort: SortDirection) => void;
  setGroupBy?: (groupBy: PriceAlertGroupBy) => void;
  setFilterTypes: (filterTypes: PriceAlertTriggerType[]) => void;
  setFilterAssets?: (filterAssets: Asset[]) => void;
};

const DynamicPriceAlertsMobileFilters: React.FC<Props> = ({
  className,
  groupBy,
  alertType,
  setGroupBy,
  showAssetFilter,
  filterAssets,
  filterTypes,
  currentTab,
  hasNoAlerts,
  setCurrentTab,
  setFilterTypes,
  setFilterAssets,
}) => {
  const { t } = useTranslation('priceAlerts');

  return (
    <FlexLayout alignItems='center' spacing={16} className={cn('w-full', className)} direction='column'>
      <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
        <Heading size='h6'>{t('filters.title')}</Heading>
        {alertType === 'active' && (
          <Switch
            text={t('filters.groupByAsset')}
            checked={groupBy === 'asset'}
            disabled={hasNoAlerts}
            onCheckedChange={(checked) => setGroupBy !== undefined && setGroupBy(checked ? 'asset' : 'type')}
          />
        )}
      </FlexLayout>
      <DynamicPriceAlertsTabs
        currentTab={currentTab}
        showTitle={false}
        setCurrentTab={setCurrentTab}
        fullWidth
        className='w-full'
      />

      {showAssetFilter && (
        <DynamicPriceAlertAssetFilter
          alertType={alertType}
          filterAssets={filterAssets}
          setFilterAssets={setFilterAssets}
        />
      )}

      <DynamicPriceAlertTypeFilter filterTypes={filterTypes} setFilterTypes={setFilterTypes} size='md' />
    </FlexLayout>
  );
};

export { DynamicPriceAlertsMobileFilters };

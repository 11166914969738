import { ReactElement } from 'react';

import { AccountStatusEnum } from '@shared/enums';

export enum EntityPermissions {
  ACCOUNT = 'app.account',
  ACCOUNT_BALANCE = 'app.account.balance',
  ACCOUNT_READ = 'app.account.read',
  ACCOUNT_TAX_REPORT = 'app.account.tax-report',
  ADDRESS = 'app.address',
  ADDRESS_READ = 'app.address.read',
  API = 'app.api',
  FUNDS = 'app.funds',
  FUNDS_READ = 'app.funds.read',
  ORDERS = 'app.orders',
  ORDERS_READ = 'app.orders.read',
  ORDERS_CREATE = 'app.orders.create',
  ORDERS_DELETE = 'app.orders.delete',
  RECURRING_ORDERS = 'app.recurring-orders',
  RECURRING_ORDERS_READ = 'app.recurring-orders.read',
  RECURRING_ORDERS_CREATE = 'app.recurring-orders.create',
  RECURRING_ORDERS_DELETE = 'app.recurring-orders.delete',
  ADDRESS_ADD = 'app.address.add',
  ADDRESS_REMOVE = 'app.address.remove',
}

export enum UserPermissions {
  WITHDRAW_FUNDS = 'app.funds.withdraw',
  DUST = 'app.orders.dust',
}

// add more scope types as they come
export type PermissionType = EntityPermissions | UserPermissions;

export type EnabledOption = {
  tooltip?: string;
};

export type DisabledOption = {
  chip?: boolean;
  customDisabledElement?: ReactElement;
  disabled?: boolean;
  hidden?: boolean;
  invalidKYCLabel?: string;
  invalidScopeLabel?: string;
  isRestrictedLabel?: string;
  tooltip?: boolean;
};

export type PermissionConfig = {
  // array of permissions needed to enable the element
  permissions?: PermissionType[];
  // kyc account status enum
  accountStatus?: AccountStatusEnum;
  // element to render if enabled
  enabledElement?: ReactElement;
  // requires unrestricted state
  needsUnrestricted?: boolean;
  /** NOTE: Added this prop below mainly for having a tooltip,
   *  Because if we nest enabledElement inside of the Tooltip component,
   *  all the props related to disabled doesn't get applied to the main element.
   *  So for now, we decided to have enabledOptions prop to keep the enabledElement not nested
   */
  // options when the element is enabled
  enabledOptions?: EnabledOption;
  // options when the element is disabled
  disabledOptions?: DisabledOption;
};

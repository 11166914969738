import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Link: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M20.0238 4.7066C17.8319 2.58991 14.3571 2.5901 12.1644 4.70647C11.8664 4.99413 11.858 5.46893 12.1457 5.76696C12.4333 6.06499 12.9081 6.0734 13.2062 5.78573C14.8177 4.23023 17.3712 4.23039 18.9817 5.78551C20.6312 7.3791 20.6786 10.0066 19.0883 11.6576L16.8426 13.9033C16.7034 14.0426 16.5524 14.1731 16.3919 14.2937C14.5541 15.6679 11.95 15.292 10.5753 13.4541C10.3272 13.1224 9.85707 13.0547 9.52547 13.3028C9.19373 13.5509 9.12597 14.0209 9.37407 14.3526C11.245 16.8539 14.7895 17.3654 17.291 15.4944L17.2922 15.4935C17.5071 15.332 17.712 15.1553 17.9033 14.964L20.1538 12.7136L20.1628 12.7043C22.3334 10.4574 22.2704 6.87688 20.0238 4.7066Z'
      fill='currentColor'
    />
    <path
      d='M14.6265 9.64572C12.7556 7.14449 9.21111 6.63291 6.70966 8.50395L6.70839 8.5049C6.49354 8.66632 6.28866 8.84307 6.09733 9.0344L3.84682 11.2847L3.83782 11.294C1.66722 13.5409 1.73023 17.1213 3.97673 19.2916C6.16861 21.4095 9.6436 21.4093 11.8363 19.2917C12.1343 19.004 12.1426 18.5292 11.8548 18.2313C11.5671 17.9333 11.0923 17.925 10.7943 18.2128C9.18276 19.7691 6.62936 19.7688 5.01897 18.2128C3.36938 16.6193 3.32203 13.9917 4.91233 12.3407L7.15799 10.095C7.29729 9.95572 7.44832 9.82522 7.60881 9.70463C9.44666 8.33051 12.0507 8.70638 13.4254 10.5442C13.6735 10.8759 14.1435 10.9437 14.4752 10.6956C14.8069 10.4475 14.8746 9.97742 14.6265 9.64572Z'
      fill='currentColor'
    />
  </svg>
);

export { Link };

import React, { useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Utility } from '@swyftx/aviary/atoms/Typography';

import { Command } from 'cmdk';
import { observer } from 'mobx-react-lite';

import { GlobalSearchItem } from './GlobalSearchItem';
import { useGlobalSearch } from '../hooks';
import { useGlobalSearchAnalytics } from '../hooks/useGlobalSearchAnalytics';
import { GlobalSearchItemType } from '../search.types';

type Props = {
  title: string;
  items: GlobalSearchItemType[];
};

const GlobalSearchCategory: React.FC<Props> = observer(({ title, items }) => {
  const { closeGlobalSearch } = useGlobalSearch();
  const { trackGlobalSearchBarResultClicked } = useGlobalSearchAnalytics();

  const onClick = useCallback(
    (item: GlobalSearchItemType) => {
      if (item.onClick) {
        if (!item.remainOpenOnClick) closeGlobalSearch();
        item.onClick();
        trackGlobalSearchBarResultClicked(title, item.title);
      }
    },
    [closeGlobalSearch, title, trackGlobalSearchBarResultClicked],
  );

  if (!items.length) return null;

  return (
    <Command.Group heading={<Utility variant='overline'>{title}</Utility>}>
      <FlexLayout direction='column' alignItems='start' className='w-full p-8' spacing={8}>
        {items.map((item) =>
          item.hidden ? null : (
            <Command.Item key={item.title} value={item.title} onSelect={() => onClick(item)} className='w-full'>
              <GlobalSearchItem key={item.title} item={item} />
            </Command.Item>
          ),
        )}
      </FlexLayout>
    </Command.Group>
  );
});

export { GlobalSearchCategory };

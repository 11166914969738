import React, { useCallback, useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';
import { TradeTypeValueType } from 'src/context/Avo/generated-avo';
import { SwyftxTradeDirection, SwyftxTradeType } from 'src/lib/trade/types/Trade.types';
import { SwyftxProTradeContext } from 'src/lib/trade-pro/context';
import { useSwyftxProTradeDirection } from 'src/lib/trade-pro/hooks/useSwyftxProTradeDirection';
import { useSwyftxProTradeType } from 'src/lib/trade-pro/hooks/useSwyftxProTradeType';

const SwyftxProTradeDirectionType: React.FC = () => {
  const { setChartLoading } = useContext(SwyftxProTradeContext);
  const { tradeDirection, setTradeDirection, tabs } = useSwyftxProTradeDirection();
  const { tradeType, setTradeType, items } = useSwyftxProTradeType();
  const isXs = useTailwindBreakpoint('xs');
  const avo = useAvo();
  const location = useLocation();

  const onChangeTradeDirection = useCallback(
    (newValue: SwyftxTradeDirection) => {
      setTradeDirection(newValue);
      setChartLoading(true);
    },
    [setChartLoading, setTradeDirection],
  );

  const onChangeTradeType = useCallback(
    (type: SwyftxTradeType) => {
      setTradeType(type);

      let avoTradeType: TradeTypeValueType = 'Market';

      switch (type) {
        case 'market':
          avoTradeType = 'Market';
          break;
        case 'limit':
          avoTradeType = 'Trigger';
          break;
        case 'oco':
          avoTradeType = 'OCO';
      }

      avo.tradeTypeSelected({
        tradeType: avoTradeType,
        swyftxPro: 'true',
        layout: isXs ? 'mobile' : 'desktop',
        screen: location.pathname,
      });
    },
    [avo, isXs, location.pathname, setTradeType],
  );

  return (
    <FlexLayout className='w-full' alignItems='center' justifyContent='space-between' spacing={8}>
      <EnhancedTabs
        variant='default'
        size='md'
        tabs={tabs}
        value={tradeDirection}
        onChange={onChangeTradeDirection}
        spotlightElementId='pro-trade-direction'
        fullWidth
      />
      <Select
        value={tradeType}
        onValueChange={(val) => onChangeTradeType(val as SwyftxTradeType)}
        items={items}
        className='rounded-[1.5rem]'
        titleWeight='emphasis'
        title='Order type'
        showInputTitle={false}
        spotlightElementId='pro-trade-type'
        size='small'
      />
    </FlexLayout>
  );
};

export { SwyftxProTradeDirectionType };

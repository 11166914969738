import React, { useEffect, useState } from 'react';

import ContentCopy from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

type Props = {
  title: string;
  value: string;
};

const COPIED_TIMEOUT = 5000;

const DepositFundsInformationItem: React.FC<Props> = ({ title, value }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const theme = useTheme();
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  const copyValue = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
    } catch (e) {
      setCopied(false);
    }
  };

  return (
    <Box
      sx={{ marginTop: 2, backgroundColor: theme.palette.mode === 'light' ? 'grey.50' : 'grey.800' }}
      borderRadius={1}
      padding={2}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          <Typography fontSize={12} fontWeight={600} color='text.primary'>
            {title}
          </Typography>
          <Typography PII color={copied ? 'primary' : 'text.secondary'} fontSize={12} fontWeight={400}>
            {copied ? 'Copied to clipboard!' : value}
          </Typography>
        </Stack>
        {hasClipboardPermission === 'granted' && (
          <Button variant='text' icon onClick={copyValue}>
            <ContentCopy sx={{ marginRight: 0.5 }} />
            Copy
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export { DepositFundsInformationItem };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  required?: boolean;
};

export const AddressInputLabel: React.FC<Props> = ({ required }) => {
  const { t } = useTranslation('common');

  return (
    <Stack direction='row'>
      <Typography fontWeight={600} fontSize={14}>
        {t('addressInput.label')}
      </Typography>
      {required && <Typography color='error'>*</Typography>}
    </Stack>
  );
};

import { useMemo, useState } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { Star, Coin, Search } from '@swyftx/aviary/icons/outlined';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { MarketFilterSort, useMarketsFilterSort } from '../../hooks/useMarketsFilterSort';
import { MarketAssetFilterType } from '../../types/Markets.types';

type Props = {
  excludedAssetCodes: string[];
  search: string;
};

export const PANEL_OFFSET = 42;

const useAssetAutoCompletePanel = ({ excludedAssetCodes, search }: Props) => {
  const [selectedFilterType, setSelectedFilterType] = useState<MarketAssetFilterType>('all');
  const isXs = useTailwindBreakpoint('xs');

  const sort: MarketFilterSort = useMemo((): MarketFilterSort => {
    switch (selectedFilterType) {
      case 'all':
      case 'favourites':
        return { sortKey: 'rank', sortDirection: 'DESC' };
      case 'owned':
        return { sortKey: 'balanceValue', sortDirection: 'DESC' };
      case 'new':
        return { sortKey: 'id', sortDirection: 'ASC' };
    }
  }, [selectedFilterType]);

  const emptyContentImage = useMemo(() => {
    if (search.length > 0) {
      return <Search className='h-48 w-48 text-color-text-accent' />;
    }
    switch (selectedFilterType) {
      case 'all':
        return (
          <div className='text-color-text-accent'>
            <Coin width={48} height={48} />
          </div>
        );
      case 'favourites':
        return <Star className='h-48 w-48 text-color-text-accent' />;
      case 'owned':
        return (
          <div className='text-color-text-accent'>
            <Coin width={48} height={48} />
          </div>
        );
      case 'new':
        return (
          <div className='text-color-text-accent'>
            <Coin width={48} height={48} />
          </div>
        );
      default:
        selectedFilterType satisfies never;
    }
  }, [selectedFilterType, search]);

  const emptyContent = useMemo(() => {
    if (search.length > 0) {
      return 'No matching results found';
    }
    switch (selectedFilterType) {
      case 'all':
        return 'No assets found';
      case 'favourites':
        return 'You haven’t added any favourite assets yet';
      case 'owned':
        return 'You don’t own any assets';
      case 'new':
        return 'No new assets found';
      default:
        selectedFilterType satisfies never;
    }
  }, [selectedFilterType, search]);

  const { filteredAndSortedAssets, loading } = useMarketsFilterSort({
    search,
    sort,
    filterType: selectedFilterType,
    excludedAssetCodes,
  });

  const tabs: EnhancedTab<MarketAssetFilterType>[] = useMemo(() => {
    const favouritesIcon =
      selectedFilterType === 'favourites' ? <StarFilled className='h-16 w-16' /> : <Star className='h-16 w-16' />;

    return [
      { title: 'All assets', value: 'all' },
      { title: 'Owned', value: 'owned' },
      {
        title: 'Favourites',
        icon: isXs ? favouritesIcon : undefined,
        value: 'favourites',
      },
      { title: 'New', value: 'new' },
    ];
  }, [isXs, selectedFilterType]);

  return {
    emptyContent,
    emptyContentImage,
    tabs,
    loading,
    filteredAndSortedAssets,
    selectedFilterType,
    setSelectedFilterType,
  };
};

export { useAssetAutoCompletePanel };

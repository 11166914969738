import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';

type Props = {
  id: string;
  icon?: JSX.Element;
  title: string;
};

const RegisterTitle: React.FC<Props> = ({ id, icon, title }) => (
  <FlexLayout id={`${id}.container`} className='w-full justify-center sm:justify-start' spacing={16} alignItems='start'>
    {icon}
    <Heading id={id} color='primary' size='h2'>
      {title}
    </Heading>
  </FlexLayout>
);

RegisterTitle.displayName = 'RegisterTitle';

export { RegisterTitle };

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Links } from '@shared/constants';

const NetworkFeesStep: React.FC = () => {
  const { onBack } = useContext(StepModalContext);
  const { t } = useTranslation('modals', { keyPrefix: 'networkFees' });

  const openFeesArticle = () => {
    window.open(Links.help.networkFees, '_blank', 'noopener,noreferrer');
  };

  return (
    <StepModal.Step title={t('title')} hideActions>
      <FlexLayout direction='column' alignItems='start' justifyContent='center' spacing={16} className='px-24 pb-24'>
        <Body weight='emphasis'>{t('contentTitle')}</Body>
        <Body color='secondary'>{t('contentDescription')}</Body>
        <BodyLink color='accent' className='cursor-pointer hover:underline' onClick={openFeesArticle}>
          {t('learnMore')}
        </BodyLink>

        <FlexLayout direction='row' alignItems='center' justifyContent='end' spacing={16} className='w-full'>
          <Button variant='filled' color='primary' size='lg' onClick={() => onBack()}>
            {t('dismiss')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </StepModal.Step>
  );
};

export { NetworkFeesStep };

import { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { ChartTypeLine } from '@swyftx/aviary/icons/charts';
import { DarkMode, Game, Password } from '@swyftx/aviary/icons/outlined';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { DemoModeSwitch, MarketTickerSwitch } from '@routes/Profile/subroutes/Settings/components';

import { SwyftxThemeSelector } from 'src/lib/layout';
import { useDemoMode, useToggleTheme, useMarketTicker } from 'src/lib/utils/hooks';

import { GlobalSearchItemType } from '../../search.types';

const useSettingsSearchModule = (): GlobalSearchItemType[] => {
  const { toggleDemoMode } = useDemoMode();
  const { isDarkMode, toggleDarkMode } = useToggleTheme();
  const { toggleMarketTicker } = useMarketTicker();
  const { openModal } = useModal();

  const items = useMemo(
    (): GlobalSearchItemType[] => [
      {
        title: 'Demo mode',
        subTitle: 'Trade in demo mode, using “simulated” paper trades.',
        leading: <Game />,
        trailing: <DemoModeSwitch />,
        additionalKeywords: ['trade', 'ui'],
        onClick: toggleDemoMode,
        remainOpenOnClick: true,
      },
      {
        title: 'Dark mode',
        subTitle: 'Choose which theme to use on your profile.',
        leading: <DarkMode />,
        trailing: <SwyftxThemeSelector renderOnlyElement />,
        additionalKeywords: ['personalization', 'ui', 'theme'],
        onClick: () => toggleDarkMode(!isDarkMode),
        remainOpenOnClick: true,
      },
      {
        title: 'Market ticker',
        subTitle: 'Choose which theme to use on your profile.',
        leading: <ChartTypeLine />,
        trailing: <MarketTickerSwitch />,
        additionalKeywords: ['personalization', 'ui', 'theme'],
        onClick: toggleMarketTicker,
        remainOpenOnClick: true,
      },
      {
        title: 'Change password',
        subTitle: 'Crypto withdrawals will be disabled for 48 hours.',
        leading: <Password />,
        trailing: <Button variant='outlined'>Change password</Button>,
        onClick: () => openModal(Modals.ChangePassword),
        additionalKeywords: ['security'],
      },
    ],
    [isDarkMode, openModal, toggleDarkMode, toggleDemoMode, toggleMarketTicker],
  );

  return items;
};

export { useSettingsSearchModule };

import {
  api,
  Asset,
  AssetHistoryItem,
  AssetHistoryItemStatus,
  SortDirection,
  SortKey,
  TransactionType,
} from '@shared/api';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';
import { TODAY } from '@shared/utils/lib/date';

export enum TransactionTypeFilter {
  DEPOSIT = 'wallet:transactions.filter.deposit',
  WITHDRAWAL = 'wallet:transactions.filter.withdrawal',
  BUY = 'wallet:transactions.filter.buy',
  SELL = 'wallet:transactions.filter.sell',
}

export enum TransactionStatusFilter {
  PENDING = 'wallet:transactions.filter.pending',
  COMPLETED = 'wallet:transactions.filter.completed',
  FAILED = 'wallet:transactions.filter.failedCancelled',
}

export enum TransactionOrder {
  ASC,
  DESC,
}

const convertStatusFilter = (statusFilter: TransactionStatusFilter[]): string | undefined => {
  const transactionStatuses = [];
  for (const filter of statusFilter) {
    switch (filter) {
      case TransactionStatusFilter.PENDING:
        transactionStatuses.push(AssetHistoryItemStatus.Pending);
        break;
      case TransactionStatusFilter.COMPLETED:
        transactionStatuses.push(AssetHistoryItemStatus.Completed);
        break;
      case TransactionStatusFilter.FAILED:
        transactionStatuses.push(AssetHistoryItemStatus.Failed);
        break;
      default:
        break;
    }
  }

  return transactionStatuses.join(',');
};

const convertTypeFilter = (typeFilter: TransactionTypeFilter[]): string | undefined => {
  const transactionTypes = [];
  for (const filter of typeFilter) {
    switch (filter) {
      case TransactionTypeFilter.DEPOSIT:
        transactionTypes.push(TransactionType.Deposit);
        break;
      case TransactionTypeFilter.WITHDRAWAL:
        transactionTypes.push(TransactionType.Withdrawal);
        break;
      case TransactionTypeFilter.BUY:
        transactionTypes.push(TransactionType.Buy);
        break;
      case TransactionTypeFilter.SELL:
        transactionTypes.push(TransactionType.Sell);
        break;
      default:
        break;
    }
  }

  return transactionTypes.join(',');
};

export type TransactionHistoryItem = AssetHistoryItem & { runningTotal: Big; tradedAssetId: number };

const getAllTransactionHistory = async (
  page: number,
  startDate: Date,
  endDate: Date,
  force: boolean,
  pageSize = 10,
  typeFilter: TransactionTypeFilter[] = [],
  statusFilter: TransactionStatusFilter[] = [],
  sort: SortKey = SortKey.Date,
  order: TransactionOrder = TransactionOrder.DESC,
  asset: Asset | undefined = undefined,
): Promise<{ tableItems: TransactionHistoryItem[]; recordCount: number }> => {
  const { userBaseCurrency } = UserStore.useUserStore;
  const type = typeFilter.length ? convertTypeFilter(typeFilter) : undefined;
  const status = statusFilter.length ? convertStatusFilter(statusFilter) : undefined;

  const sortDirection = order === TransactionOrder.ASC ? SortDirection.ASCENDING : SortDirection.DESCENDING;

  const query = {
    page,
    limit: pageSize,
    sortKey: sort,
    sortDirection,
    ...(type && { type }),
    ...(status && { status }),
    ...{ startDate: startDate.getTime() },
    ...{ endDate: endDate.getTime() },
  };

  if (force) api.endpoints.getAllTransactionHistory.resetCache();

  const response = asset
    ? await api.endpoints.getAssetTransactionHistory({ params: { assetId: asset.id }, query })
    : await api.endpoints.getAllTransactionHistory({ query });

  const { runningTotal, items, recordCount } = response.data;

  let runningTotalBig = Big(runningTotal ?? 0);

  const tableItems = [];

  for (const historyItem of items) {
    const movementAmount =
      historyItem.primaryAsset === userBaseCurrency ? historyItem.primaryAmount : historyItem.secondaryAmount;
    const itemPending = historyItem.status !== AssetHistoryItemStatus.Pending;
    if (order === TransactionOrder.ASC && itemPending) runningTotalBig = runningTotalBig.plus(movementAmount);
    tableItems.push({ ...historyItem, runningTotal: runningTotalBig, tradedAssetId: historyItem.primaryAsset });
    if (order === TransactionOrder.DESC && itemPending) runningTotalBig = runningTotalBig.minus(movementAmount);
  }

  return {
    tableItems,
    recordCount,
  };
};

/**
 * Format the timestamp of start date and end date
 * The period is set to past 12 months from today as default
 */
const dateFilters = (from?: Date, to?: Date): { from: Date; fromInMs: number; to: Date; toInMs: number } => {
  const today = new Date(TODAY());
  const fromDate = new Date(from ?? today);
  const toDate = new Date(to ?? today);

  fromDate.setMonth(toDate.getMonth() - 12);
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(23, 59, 59, 999);

  return {
    from: fromDate,
    fromInMs: fromDate.getTime(),
    to: toDate,
    toInMs: toDate.getTime(),
  };
};

export const defaultTransactionFilter = {
  order: TransactionOrder.DESC,
  sort: SortKey.Date,
  typeFilters: [],
  statusFilters: [],
  startDate: dateFilters().from,
  endDate: dateFilters().to,
};

export const walletService = {
  getAllTransactionHistory,
};

// DO NOT change the type id which should match the orderStatus enum on the backend
export enum OrderStatusEnum {
  OPEN = 1, // Pending
  INSUFFICIENT_BALANCE = 2, // Failed
  PARTIAL_FILL = 3, // Failed
  FILLED = 4, // Success
  PENDING = 5, // Pending
  USER_CANCELLED = 6, // Failed
  FAILED = 7, // Failed
  SYSTEM_CANCELLED = 8, // Failed
  FAILED_MIN_AMOUNT = 9, // Failed
  REFUNDED = 10, // Failed
  FAILED_MAX_AMOUNT = 11, // Failed Order size too large
}

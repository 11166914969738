import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { DEFAULT_EMPTY_VALUE } from '@shared/constants/general';
import { Big } from '@shared/safe-big';

import { observer } from 'mobx-react-lite';

type Props = {
  profitLossPercentage: Big;
  variant?: 'dashboard' | 'trade';
};

const PortfolioTableProfitLossPercentage: React.FC<Props> = observer(
  ({ profitLossPercentage, variant = 'dashboard' }) => {
    const percentageValue = useMemo(() => {
      if (variant === 'trade') {
        return profitLossPercentage.eq(0)
          ? DEFAULT_EMPTY_VALUE
          : `${profitLossPercentage.gt(0) ? '+' : ''}${profitLossPercentage.toFixed(2)}%`;
      }

      return !profitLossPercentage.eq(0) ? `${profitLossPercentage.toFixed(2)}%` : DEFAULT_EMPTY_VALUE;
    }, [profitLossPercentage, variant]);

    return (
      <FlexLayout justifyContent='end'>
        <NumericDataItem
          size='small'
          alignItems='start'
          renderIcons={false}
          percentage={!profitLossPercentage.eq(0)}
          data={profitLossPercentage.toFixed(2)}
        >
          {percentageValue}
        </NumericDataItem>
      </FlexLayout>
    );
  },
);

export { PortfolioTableProfitLossPercentage };

import React from 'react';

import CheckCircle from '@mui/icons-material/CheckCircle';
import { useMediaQuery, useTheme } from '@mui/material';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

// Need to get the filled tick icon from designs

type Props = {
  title: string;
  content?: string;
  footer?: JSX.Element;
};

export const SuccessMessageBox: React.FC<Props> = ({ title, content, footer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Stack direction='column' alignItems='flex-start'>
        <CheckCircle color='success' fontSize={isMobile ? 'small' : 'large'} />
      </Stack>

      <Stack direction='column' spacing={1} maxWidth='75%'>
        <Typography fontSize={pxToRem(14)} fontWeight={600}>
          {title}
        </Typography>

        {content && (
          <Typography fontSize={pxToRem(12)} color='text.secondary'>
            {content}
          </Typography>
        )}

        {footer}
      </Stack>
    </Stack>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeArea: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.5054 4.47899C21.7857 4.59507 21.9684 4.86855 21.9684 5.1719V18.9998C21.9684 19.414 21.6326 19.7498 21.2184 19.7498H2.78125C2.36704 19.7498 2.03125 19.414 2.03125 18.9998V11.3176C2.03125 11.1187 2.11027 10.9279 2.25092 10.7873L6.86021 6.178C7.15311 5.8851 7.62798 5.8851 7.92087 6.178L13.5363 11.7934L20.6881 4.64157C20.9026 4.42707 21.2252 4.3629 21.5054 4.47899ZM20.4684 6.98256L14.0666 13.3844C13.7737 13.6773 13.2988 13.6773 13.0059 13.3844L7.39054 7.76899L3.53125 11.6283V18.2498H20.4684V6.98256Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeArea };

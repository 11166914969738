import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { ContentBanner } from '@global-components/ContentBanner';

import { SourceOfWealthVerificationDto } from '@shared/api/@types/compliance';

import { useGetTimeRemaining } from 'src/lib/utils/useGetTimeRemaining';

interface Props {
  bannerIcon: JSX.Element;
  onAction: () => void;
  verification: SourceOfWealthVerificationDto;
}

export const SowRequestedContent: React.FC<Props> = (props) => {
  const { bannerIcon, onAction, verification } = props;

  const { timeRemaining } = useGetTimeRemaining(verification.expiresAt);

  const Description: React.FC = () => (
    <FlexLayout direction='row' spacing={4}>
      <Body size='small' color='secondary'>
        You have <b>{timeRemaining}</b> to complete the source of wealth check. Failure to complete within this
        timeframe will result in all withdrawals being disabled until completed.
      </Body>
    </FlexLayout>
  );

  return (
    <FlexLayout direction='column'>
      <ContentBanner
        onAction={onAction}
        actionLabel='Complete source of wealth'
        description={<Description />}
        title='Time to complete a source of wealth check.'
        icon={bannerIcon}
      />
    </FlexLayout>
  );
};

import React, { RefObject, useRef, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { ExternalLink } from '@swyftx/aviary/icons/outlined';
import { NanoColor } from '@swyftx/aviary/molecules/ShopItem';

import { useIntercomEvent } from '@hooks/useIntercomEvent/useIntercomEvent';

import { ColorUrls, referral, shopItems } from '@Shop/Shop.data';
import { ColorPicker, ShopInformationComponent } from '@Shop/components';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  deviceKey: 'ledger-nano-x' | 'ledger-nano-s';
};

const LedgerNano: React.FC<Props> = ({ deviceKey }) => {
  const { id, title, description, price, colors, features, shippingTime, defaultColor, url } = shopItems[deviceKey];

  const [selectedColor, setSelectedColor] = useState<NanoColor>(defaultColor);
  const currentColorRef: RefObject<HTMLImageElement> = useRef<HTMLImageElement>(null);
  const { buyLedger } = useIntercomEvent();
  const { navigate } = useNavigateRoute();

  const backToShop = () => {
    navigate(NavigationURLs.Shop);
  };

  const openShopItem = () => {
    buyLedger();

    window.open(
      `${url}/${ColorUrls[selectedColor]}?${referral}`,
      '_blank',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=1024,
      height=768,
      noopener,
      noreferrer`,
    );
  };

  const changeColor = (color: NanoColor) => {
    if (!currentColorRef) {
      setSelectedColor(color);
      return;
    }

    window.setTimeout(() => {
      setSelectedColor(color);
    }, 10);
  };

  return (
    <FlexLayout
      direction='row'
      spacing={16}
      justifyContent='space-between'
      className='flex-wrap p-16 @container sm:p-24 sm:pr-0'
    >
      <FlexLayout className='@xs:w-full @md:w-[calc(66%-8px)]'>
        <Card id={id} className='p-24'>
          <FlexLayout direction='column' spacing={32}>
            <FlexLayout direction='row' spacing={16} className='flex justify-between'>
              <FlexLayout direction='column'>
                <Heading size='h4'>{title}</Heading>
                <FlexLayout direction='row' spacing={4} className='flex items-center'>
                  <Body size='large' color='primary'>
                    {price}
                  </Body>
                  <Body size='small' color='secondary'>
                    inc GST
                  </Body>
                </FlexLayout>
              </FlexLayout>
              <FlexLayout direction='column'>
                <Button color='subtle' onClick={backToShop} className='w-full'>
                  Back
                </Button>
              </FlexLayout>
            </FlexLayout>
            <Separator />

            <FlexLayout direction='row' spacing={32} className='flex-wrap'>
              <FlexLayout
                direction='column'
                className='flex justify-between rounded-16 bg-color-background-surface-secondary p-16 @xs:w-full @sm:w-[calc(50%-16px)]'
              >
                <img
                  ref={currentColorRef}
                  id={`${id}.image`}
                  src={colors[selectedColor]}
                  alt={title}
                  className='max-h-[450px] object-contain'
                />
                <ColorPicker colors={colors} selectedColor={selectedColor} setSelectedColor={changeColor} />
              </FlexLayout>

              <FlexLayout
                direction='column'
                spacing={16}
                className='@xs:w-full @sm:w-[calc(50%-16px)]'
                justifyContent='space-between'
              >
                <FlexLayout direction='column' spacing={16}>
                  <FlexLayout direction='column' spacing={8}>
                    <Heading size='h6'>Product description</Heading>
                    <Body size='small'> {description}</Body>
                  </FlexLayout>

                  <FlexLayout direction='column' spacing={8}>
                    <Heading size='h6'>Features</Heading>
                    <FlexLayout direction='column' spacing={4}>
                      {Array.from(features).map((feature, index) => (
                        <Body key={feature} size='small' id={`${id}.feature.${index}`}>
                          {feature}
                        </Body>
                      ))}
                    </FlexLayout>
                  </FlexLayout>

                  <FlexLayout direction='column' spacing={8}>
                    <Heading size='h6'>Shipping</Heading>
                    <Body size='small'>{shippingTime}</Body>
                  </FlexLayout>
                </FlexLayout>
                <Button
                  id={`${id}.buttons.buy`}
                  color='primary'
                  onClick={openShopItem}
                  size='lg'
                  trailingIcon={<ExternalLink />}
                >
                  Buy via Ledger.com
                </Button>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </Card>
      </FlexLayout>

      <ShopInformationComponent />
    </FlexLayout>
  );
};

export default LedgerNano;

import { useCallback, useState } from 'react';

import { api } from '@shared/api';
import {
  PostRecurringOrderData,
  PostRecurringOrderResponse,
  RecurringOrderFrequency,
} from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums/lib/recurringOrderSourceEnum';
import { SwyftxError } from '@shared/error-handler';
import { assetService } from '@shared/services';

import { useAutoInvestCache } from './useAutoInvestCache';
import { AutoInvestAsset } from '../autoInvest.types';

const useCreateAutoInvest = () => {
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const { invalidateCache } = useAutoInvestCache();

  const convertSelectedAssetsToTemplate = (assets: AutoInvestAsset[]) => {
    const template: { [key: number]: number } = {};

    assets.forEach((a: AutoInvestAsset) => {
      const asset = assetService.getAssetByCode(a.assetCode);
      if (!asset) return;

      template[asset.id] = Number(a.percentage);
    });

    return template;
  };

  const createAutoInvestFromDeposit = useCallback(
    async (
      label: string,
      assets: AutoInvestAsset[],
      primaryAssetId: number,
      onExecuteSuccess: (response: PostRecurringOrderResponse) => void,
      onExecuteFailure: (error: string) => void,
    ) => {
      setIsExecuting(true);

      try {
        const data: PostRecurringOrderData = {
          label,
          source: RecurringOrderSource.DEPOSIT,
          primaryAssetId,
          template: convertSelectedAssetsToTemplate(assets),
        };

        const response = await api.endpoints.postRecurringOrderTemplate({ data });

        invalidateCache();

        onExecuteSuccess(response.data);
      } catch (e) {
        const { errorMessage } = e as SwyftxError;
        onExecuteFailure(errorMessage);
      } finally {
        setIsExecuting(false);
      }
    },
    [invalidateCache],
  );

  const createAutoInvestFromBalances = useCallback(
    async (
      label: string,
      assets: AutoInvestAsset[],
      primaryAssetId: number,
      frequency: RecurringOrderFrequency,
      nextTime: number,
      nextTimeOffset: number,
      orderTotal: number,
      onExecuteSuccess: (response: PostRecurringOrderResponse) => void,
      onExecuteFailure: (error: string) => void,
    ) => {
      setIsExecuting(true);

      try {
        const data: PostRecurringOrderData = {
          label,
          source: RecurringOrderSource.ACCOUNT,
          primaryAssetId,
          template: convertSelectedAssetsToTemplate(assets),
          frequency,
          nextTime,
          nextTimeOffset,
          orderTotal,
        };

        const response = await api.endpoints.postRecurringOrderTemplate({ data });

        invalidateCache();

        onExecuteSuccess(response.data);
      } catch (e) {
        const { errorMessage } = e as SwyftxError;
        onExecuteFailure(errorMessage);
      } finally {
        setIsExecuting(false);
      }
    },
    [invalidateCache],
  );

  return { createAutoInvestFromDeposit, createAutoInvestFromBalances, isExecuting };
};

export { useCreateAutoInvest };

import React, { useCallback } from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { SwyftxCompact } from '@swyftx/aviary/icons/outlined';
import { MenuItem } from '@swyftx/aviary/molecules/Menu';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { useCurrentPlatform } from 'src/lib/utils/hooks/useCurrentPlatform';
import { SwyftxPlatform } from 'src/lib/utils/utils.types';

const SwitchPlatformMenuItem: React.FC = () => {
  const { setCurrentPlatform } = useCurrentPlatform();
  const { isSwyftxPro } = useSwyftxPro();
  const avo = useAvo();
  const location = useLocation();

  const updateCurrentPlatform = useCallback(
    (platform: SwyftxPlatform) => {
      setCurrentPlatform(platform);
      avo.switchPlatformButtonClicked({ screen: location.pathname, buttonName: platform });
    },
    [avo, location.pathname, setCurrentPlatform],
  );

  return (
    <FlexLayout direction='column' spacing={8} className='flex-wrap'>
      <Body size='xsmall' color='secondary' className='my-4'>
        Switch Platform
      </Body>
      <MenuItem
        key='swyftx'
        className={!isSwyftxPro ? '!bg-color-background-surface-selected' : ''}
        icon={
          <FlexLayout
            alignItems='center'
            justifyContent='center'
            className='h-48 w-48 rounded-8 bg-color-background-info p-4'
          >
            <SwyftxCompact className='h-48 w-48 text-color-text-inverse' />
          </FlexLayout>
        }
        text='Swyftx'
        description='Trading made easy'
        action={() => updateCurrentPlatform('swyftx')}
      />
      <MenuItem
        key='swyftx-pro'
        className={isSwyftxPro ? '!bg-color-background-surface-selected' : ''}
        icon={
          <div className='flex h-48 w-48 items-center rounded-8 bg-color-icon-primary'>
            <Image image='swyftx_pro_logo' />
          </div>
        }
        text='Swyftx Pro'
        description='Professional trading'
        action={() => updateCurrentPlatform('swyftx-pro')}
      />
    </FlexLayout>
  );
};

export { SwitchPlatformMenuItem };

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const LoginCreateAccount: React.FC = observer(() => {
  const { t } = useTranslation('login');
  const { navigate } = useNavigateRoute();

  const onCreateAccount = useCallback(() => {
    navigate(NavigationURLs.Register, { state: { fromLogin: true } });
  }, [navigate]);

  return (
    <FlexLayout direction='row' alignItems='center' spacing={4} className='pb-16'>
      <Body size='small'>{t('loginCreateAccount.labels.dontHaveAccount')}</Body>
      <BodyLink size='small' color='accent' className='cursor-pointer hover:underline' onClick={onCreateAccount}>
        {t('loginCreateAccount.buttonLabels.createAccount')}
      </BodyLink>
    </FlexLayout>
  );
});

LoginCreateAccount.displayName = 'LoginCreateAccount';

export { LoginCreateAccount };

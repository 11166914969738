import axios from 'axios';

let ipAddress = 'UNKNOWN';

export const getIP = async () => {
  if (ipAddress === 'UNKNOWN') {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      ipAddress = response.data.ip;
    } catch {
      // if request fails try again next time
    }
  }
  return ipAddress;
};

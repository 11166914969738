import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { GlobalSearchItemType } from '../search.types';

type Props = {
  item: GlobalSearchItemType;
};

const GlobalSearchItem: React.FC<Props> = ({ item }) => {
  if (item.hidden) return null;

  return (
    <FlexLayout alignItems='center' justifyContent='space-between' className='h-full w-full'>
      <FlexLayout alignItems='center' justifyContent='start' spacing={16}>
        {item.leading}
        <FlexLayout direction='column' alignItems='start'>
          <Body weight='emphasis'>{item.title}</Body>
          {item.subTitle && (
            <Body color='secondary' size='small'>
              {item.subTitle}
            </Body>
          )}
        </FlexLayout>
      </FlexLayout>
      {item.trailing}
    </FlexLayout>
  );
};

export { GlobalSearchItem };

import { useCallback, useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { formatValueToCurrencyShorthand, formatValueToShorthand } from '@swyftx/aviary/utils';
import { formatCurrency } from '@swyftx/currency-util';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { CategoryIcons, fallbackIcon } from 'src/context/Categories/Categories.icons';
import { useCategory } from 'src/lib/categories/hooks';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { AssetLinks } from './AssetLinks';

export interface AssetInfoRow {
  title: string;
  value: string | React.ReactNode;
  tooltip?: string;
  hidden?: boolean;
}

export const useAssetInfo = (asset: Asset, showLearnMore: boolean) => {
  const { getAssetCategories } = useCategory();
  const { getRate } = RatesStore.useRatesStore;
  const { navigate } = useNavigateRoute();
  const baseAsset = useBaseAsset();
  const rate = getRate(asset);
  const categories = useMemo(() => getAssetCategories(asset), [asset, getAssetCategories]);
  const { isSwyftxPro } = useSwyftxPro();

  const openCategory = useCallback(
    (category?: Category, e?: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: category?.id } });
    },
    [navigate],
  );

  const marketCap = Big(asset.circulatingSupply).times(rate.midPrice).toString();
  const circulatingSupply = asset.circulatingSupply.toString();
  const maxSupply = asset.maxSupply.toString();
  const dailyVolume = Big(asset.volume[baseAsset?.id || FiatIdEnum.AUD].day).toString();
  const rankTitle = asset.rank.toString() === 'Infinity' ? 'Infinity' : `No. ${asset.rank.toString()}`;

  const maxSupplyIsInfinite = asset.maxSupply === '∞' || asset.maxSupply === 'N/A';

  const strippedDescription = asset.description?.replace(/<[^>]*>|[\n\r]/g, '');

  const info: AssetInfoRow[] = [
    { title: 'Global Rank', value: rankTitle },
    {
      title: 'Market cap',
      value: formatValueToCurrencyShorthand(marketCap),
      tooltip: formatCurrency(marketCap, { isFiat: true }),
    },
    {
      title: 'Circulating supply',
      value: Number.isNaN(Number(circulatingSupply)) ? circulatingSupply : formatValueToShorthand(circulatingSupply),
      tooltip: Number.isNaN(Number(circulatingSupply))
        ? circulatingSupply
        : formatCurrency(circulatingSupply, { isFiat: false }),
    },
    {
      title: 'Max supply',
      value: maxSupplyIsInfinite ? 'Infinity' : formatValueToShorthand(maxSupply),
      tooltip: !maxSupplyIsInfinite ? formatCurrency(maxSupply, { isFiat: false }) : undefined,
    },
    {
      title: '24hr volume',
      value: formatValueToCurrencyShorthand(dailyVolume),
      tooltip: formatCurrency(dailyVolume, { isFiat: true }),
    },
    {
      title: 'Website',
      value: (
        <>
          {asset.urls?.website ? (
            <a
              href={asset.urls?.website}
              target='_blank'
              className='font-500 text-color-text-accent'
              rel='noopener noreferrer'
            >
              <Body>
                {/* Strip https:// from the front if it exists and the / from the end if it exists */}
                {asset.urls?.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}
              </Body>
            </a>
          ) : (
            <Body color='secondary'>-</Body>
          )}
        </>
      ),
    },
    {
      title: 'Links',
      value: <AssetLinks asset={asset} />,
    },
    {
      title: 'Categories',
      value: (
        <FlexLayout spacing={8} className='flex-wrap'>
          {categories.map((category) => (
            <Chip
              variant='subtle'
              color='primary'
              size='sm'
              onClick={() => openCategory(category)}
              className={isSwyftxPro ? 'pointer-events-none' : 'cursor-pointer'}
              key={category.id}
              leadingIcon={
                <div className='flex w-16 items-center text-color-text-accent'>
                  {CategoryIcons(16, true, true)[category.name] || fallbackIcon(96, true, true)}
                </div>
              }
            >
              {category.name}
            </Chip>
          ))}
          {!categories.length && <Body color='secondary'>-</Body>}
        </FlexLayout>
      ),
    },
    {
      title: `What is ${asset.name} (${asset.code})?`,
      value: (
        <Body color='secondary' className='!line-height-12 w-full whitespace-normal'>
          {/* strips html tags from this CoinGecko description */}
          {isSwyftxPro || !showLearnMore ? strippedDescription : `${strippedDescription?.substring(0, 250)}...`}
        </Body>
      ),
      hidden: !strippedDescription,
    },
  ];

  return { info };
};

import * as React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as RadixSwitch from '@radix-ui/react-switch';

import { SwitchProps, switchVariants, thumbVariants } from './Switch.styles';
import { FlexLayout } from '../Layout/Flex';
import { Body } from '../Typography';

const Switch = React.forwardRef<HTMLButtonElement, Omit<SwitchProps, 'style'>>(
  ({ className, size = 'md', text, color = 'primary', disabled, ...props }, ref) => (
    <FlexLayout direction='row' spacing={8} className='flex items-center'>
      <RadixSwitch.Root
        ref={ref}
        id='switch-root'
        className={cn('relative rounded-[12px]', switchVariants({ size, color, disabled }), className)}
        disabled={disabled}
        {...props}
      >
        <RadixSwitch.Thumb
          className={cn(
            `translate-x-0.5 duration-120 block
              rounded-[100%] bg-color-background-surface-primary 
              transition-transform will-change-transform`,
            thumbVariants({ size }),
            className,
          )}
        />
      </RadixSwitch.Root>
      {text && <Body color={disabled ? 'disabled' : 'primary'}>{text}</Body>}
    </FlexLayout>
  ),
);

Switch.displayName = RadixSwitch.Root.displayName;

export { Switch };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { cn } from '@shared/utils/lib/ui';

import { performanceMenuControls } from './PerformanceMenu.const';
import { PerformanceMenuControl } from './PerformanceMenu.types';

type Props = {
  selectedControl: PerformanceMenuControl;
  setSelectedControl: (control: PerformanceMenuControl) => void;
  disabled?: boolean;
  noPadding?: boolean;
};

// TODO cancel existing network request upon new selection
export const PerformanceMenu: React.FC<Props> = ({ selectedControl, setSelectedControl, noPadding, disabled }) => {
  const handleClick = (label: string) => {
    const item = performanceMenuControls.find((c) => c.label === label);
    if (item) setSelectedControl(item);
  };

  return (
    <FlexLayout direction='row' className={cn(noPadding ? '' : 'p-16')}>
      <EnhancedTabs
        value={selectedControl.label}
        variant='child'
        size='md'
        fullWidth
        tabs={performanceMenuControls.map((c) => ({ title: c.label, value: c.label, disabled }))}
        onChange={handleClick}
      />
    </FlexLayout>
  );
};

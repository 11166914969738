import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { UpdateUserProfileArgs } from '@shared/api';
import { CountriesEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import UserService from '@services/UserService';

import ct from 'countries-and-timezones';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  newPhoneNumber: string;
};

const INITIAL_CODE_TIMEOUT = 30;

export const UpdatePhoneVerifyStep: React.FC<Props> = ({ onClose, newPhoneNumber, onSuccess }) => {
  const { userProfile, setUserProfile, getMaskedPhoneNumber } = UserStore.useUserStore;
  const currentPhone = getMaskedPhoneNumber();
  const { addMessageBox } = UIStore.useUIStore;

  const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);
  const [mfaCode, setMfaCode] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [checkPin, setCheckPin] = useState<boolean>(false);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const country = ct.getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0]?.id as
    | CountriesEnum
    | CountriesEnum.AU;
  const { t } = useTranslation('login');

  const getUserProfileArgs = useCallback(
    (): UpdateUserProfileArgs => ({
      profile: {
        phone: parsePhoneNumber(newPhoneNumber, country as CountryCode)?.formatInternational(),
      },
    }),
    [newPhoneNumber, country],
  );

  const handleError = useCallback((e: any) => {
    const { errorMessage } = e as SwyftxError;
    setError(errorMessage);
  }, []);

  const handleNewPhone = useCallback(
    async (phone: string) => {
      if (userProfile) {
        const userProfileUpdated = { ...userProfile };
        userProfileUpdated.phone = phone;
        if (userProfileUpdated.verification) userProfileUpdated.verification.phone = 0;
        setUserProfile(userProfileUpdated);
      }
    },
    [onClose],
  );

  const updatePhone = useCallback(async () => {
    try {
      const data: UpdateUserProfileArgs = getUserProfileArgs();

      const response = await UserService.UpdateUserProfile(data);

      if (response.new) {
        handleNewPhone(response.new.phone);
        await UserService.RequestPhoneVerification();
        addMessageBox({
          content: (
            <InformationMessageBox
              title="We've sent you a message"
              content={`The action you are trying to complete requires SMS verification. A code will be sent to ${newPhoneNumber}.`}
            />
          ),
        });
        setCheckPin(true);
      } else if (typeof response === 'string' && response.includes('mfa_')) {
        setMfaCode(response);
      }
    } catch (e) {
      handleError(e);
    }
  }, [handleError, getUserProfileArgs, handleNewPhone]);

  const verifyNewPhone = useCallback(async () => {
    try {
      const data: UpdateUserProfileArgs = getUserProfileArgs();
      const headers = { AuthAction: `${mfaCode} ${code}` };

      if (checkPin) {
        await UserService.CheckPhoneVerification(code);
      } else {
        await UserService.UpdateUserProfile(data, headers);
      }

      await UserService.GetUserProfile();
      onSuccess();
    } catch (e) {
      handleError(e);
    }
  }, [code, getUserProfileArgs, handleError, mfaCode, onClose]);

  const handleResendCode = () => {
    setResendTime(resendTime + INITIAL_CODE_TIMEOUT);
    updatePhone();
  };

  useEffect(() => {
    updatePhone();
  }, [updatePhone]);

  useEffect(() => {
    if (code.length === 6) {
      verifyNewPhone();
    }
  }, [code]);

  const handlePasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setCode(text);
  };

  return (
    <Wizard.Step onClose={onClose} title='Verification via phone required' hideActions>
      <Stack spacing={2}>
        {currentPhone ? (
          <>
            <Typography PII color='text.secondary' fontSize={16}>
              The action you are trying to complete requires SMS verification. A code will be sent to&nbsp;
              <strong>{currentPhone}</strong>.
            </Typography>
            <Typography color='text.secondary' fontSize={16}>
              Do not share this code, Swyftx will never ask you for verification details.
            </Typography>
          </>
        ) : null}
        <Stack direction='column' spacing={2} alignItems='center' justifyContent='center'>
          <CodeInput
            id='update-phone-address-modal-code'
            length={6}
            onChange={(newCode: string[]) => setCode(newCode.join(''))}
            value={code}
            error={error}
            hidePasteButton
          />
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          {hasClipboardPermission === 'granted' && (
            <Button variant='text' onClick={() => handlePasteFromClipboard()} sx={{ marginTop: '0rem' }}>
              <Stack direction='row' alignItems='center' height='100%' spacing={1}>
                <Paste className='h-20 w-20 text-color-text-primary' />
                <Typography color='primary'>Paste from clipboard</Typography>
              </Stack>
            </Button>
          )}
          <CountdownDelay
            id='update-phone-address-modal-resend-countdown'
            delay={resendTime}
            delayText={t('codeInput.buttonLabels.resendCodeIn')}
            restartText={t('codeInput.buttonLabels.resendCode')}
            onTimerRestart={handleResendCode}
          />
        </Stack>
      </Stack>
    </Wizard.Step>
  );
};

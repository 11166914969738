import React, { useCallback, useContext, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ChartTypeLine } from '@swyftx/aviary/icons/charts';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { TableName } from 'src/context/Avo/generated-avo';
import { OpenOrders } from 'src/lib/UniversalTrade/components/OrdersTile/OpenOrders';
import { OrderHistory } from 'src/lib/UniversalTrade/components/OrdersTile/OrderHistory';
import { OrdersTileActions } from 'src/lib/UniversalTrade/components/OrdersTile/OrdersTileActions';
import { PortfolioBreakdown } from 'src/lib/UniversalTrade/components/OrdersTile/PortfolioBreakdown';
import { OrdersTileTabs } from 'src/lib/UniversalTrade/components/OrdersTile/ordersTile.types';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { TradingViewWidget } from 'src/lib/trade/components';
import { TradingViewSide } from 'src/lib/trade/components/TradingViewWidget/types';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';
import { SwyftxTradeDirection } from 'src/lib/trade/types/Trade.types';

import { SwyftxProChartPanelHeader } from './SwyftxProChartPanelHeader';
import { SwyftxProTradeContext } from '../../context';
import { useSwyftxProTradeAssets } from '../../hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from '../../hooks/useSwyftxProTradeDirection';
import { ProPanels } from '../../types/TradePro.types';
import { SwyftxProOrderBook } from '../SwyftxProOrderBook';
import { SwyftxProTradePanel } from '../SwyftxProTradePanel';
import { SwyftxProTradePanelModal } from '../SwyftxProTradePanel/SwyftxProTradePanelModal';

type Props = {
  className?: string;
  selectedPanel: ProPanels;
  setSelectedPanel: React.Dispatch<React.SetStateAction<ProPanels>>;
};

const SwyftxProChart: React.FC<Props> = observer(({ className, selectedPanel, setSelectedPanel }) => {
  const {
    triggerPrice,
    setTriggerPrice,
    stopLossPrice,
    setStopLossPrice,
    takeProfitPrice,
    setTakeProfitPrice,
    tradeType,
    setChartLoading,
  } = useContext(SwyftxProTradeContext);
  const { baseAsset, selectedAsset } = useSwyftxProTradeAssets();
  const { tradeDirection, setTradeDirection } = useSwyftxProTradeDirection();
  const { navigate } = useNavigateRoute();
  const [filterCurrentAsset, setFilterCurrentAsset] = useLocalStorage<boolean>(
    StorageKey.SHOW_CURRENT_ASSET_ONLY,
    false,
  );
  const [hideCancelled, setHideCancelled] = useLocalStorage<boolean>(StorageKey.HIDE_CANCELLED_ORDERS, false);
  const { changedFilter } = useTradePageAnalytics();

  const side = useMemo(() => (tradeDirection === 'buy' ? 'ask' : 'bid'), [tradeDirection]);
  const selectedAssetEqualsBaseAsset = selectedAsset?.code === baseAsset?.code;

  const onPriceSideChange = useCallback(
    (newSide: TradingViewSide) => {
      setChartLoading(true);
      if (newSide === 'bid') setTradeDirection('sell');
      if (newSide === 'ask') setTradeDirection('buy');
    },
    [setChartLoading, setTradeDirection],
  );

  const priceLines = useMemo(() => {
    switch (tradeType) {
      case 'limit':
        return triggerPrice ? [triggerPrice] : undefined;
      case 'oco':
        return takeProfitPrice && stopLossPrice && triggerPrice
          ? [triggerPrice, takeProfitPrice, stopLossPrice]
          : undefined;
      case 'market':
      default:
        return undefined;
    }
  }, [stopLossPrice, takeProfitPrice, tradeType, triggerPrice]);

  const onUpdatePriceLines = useCallback(
    (newPriceLines?: string[]) => {
      if (!newPriceLines) return;

      switch (tradeType) {
        case 'limit':
          setTriggerPrice(
            formatCurrency(newPriceLines[0], baseAsset, {
              appendCode: false,
              hideCode: true,
              hideSeparator: true,
              hideSymbol: true,
            }),
          );
          return;
        case 'oco':
          setTakeProfitPrice(newPriceLines[0]);
          setStopLossPrice(newPriceLines[1]);
          return;
        case 'market':
        default:
          return;
      }
    },
    [baseAsset, setStopLossPrice, setTakeProfitPrice, setTriggerPrice, tradeType],
  );

  const getTableName = useCallback(
    (table?: ProPanels) => {
      switch (table || selectedPanel) {
        case 'wallets':
          return TableName.WALLETS;
        case 'openOrders':
          return TableName.OPEN_ORDERS;
        case 'orderHistory':
        default:
          return TableName.ORDER_HISTORY;
      }
    },
    [selectedPanel],
  );

  const handleToggleFilterCurrentAsset = useCallback(() => {
    const newValue = !filterCurrentAsset;
    setFilterCurrentAsset(newValue);
    changedFilter({ filterName: 'currentAsset', filterEnabled: newValue, tableName: getTableName() });
  }, [changedFilter, getTableName, filterCurrentAsset, setFilterCurrentAsset]);

  const handleToggleHideCancelled = useCallback(() => {
    const newValue = !hideCancelled;
    setHideCancelled(newValue);
    changedFilter({ filterName: 'hideCancelled', filterEnabled: newValue, tableName: getTableName() });
  }, [hideCancelled, setHideCancelled, changedFilter, getTableName]);

  const onSelectTradeDirection = (direction: SwyftxTradeDirection) => {
    setTradeDirection(direction);
  };

  if (!baseAsset || !selectedAsset) return null;

  return (
    <FlexLayout
      className={cn('bg-color-background-surface-primary', className)}
      alignItems='start'
      justifyContent='start'
      direction='column'
    >
      <FlexLayout alignItems='center' className='relative w-full' spacing={16}>
        <SwyftxProChartPanelHeader selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel} />

        {['openOrders', 'orderHistory'].includes(selectedPanel) && (
          <div className='mr-8'>
            <OrdersTileActions
              hideCancelled={hideCancelled || false}
              toggleHideCancelled={handleToggleHideCancelled}
              filterCurrentAsset={filterCurrentAsset || false}
              selectedTab={selectedPanel as OrdersTileTabs}
              toggleFilterCurrentAsset={handleToggleFilterCurrentAsset}
            />
          </div>
        )}
      </FlexLayout>

      {baseAsset && selectedAsset && (
        <>
          {selectedPanel === 'chart' && (
            <>
              {!selectedAssetEqualsBaseAsset ? (
                <TradingViewWidget
                  primary={baseAsset.code}
                  secondary={selectedAsset.code}
                  border={false}
                  chartStyle='advanced'
                  className='h-full w-full'
                  side={side}
                  onPriceSideChange={onPriceSideChange}
                  changeSideEnabled={tradeType !== 'oco'}
                  priceLines={priceLines}
                  onUpdatePriceLines={onUpdatePriceLines}
                />
              ) : (
                <FlexLayout
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  spacing={8}
                  className={cn('h-full w-full')}
                >
                  <ChartTypeLine className='h-96 w-96 text-color-text-accent' />
                  <Body color='primary' className='text-center'>
                    Cannot show chart when selected asset is the same as base asset
                  </Body>
                </FlexLayout>
              )}
            </>
          )}

          {selectedPanel === 'orderbook' && (
            <SwyftxProOrderBook
              className='h-full w-full'
              selectedAsset={selectedAsset}
              baseAsset={baseAsset}
              showHeader={false}
            />
          )}

          {['wallets', 'openOrders', 'orderHistory'].includes(selectedPanel) && (
            <div className={cn('w-full', selectedPanel === 'trade' ? 'h-[calc(100%-3rem)]' : 'h-[calc(100%-8rem)]')}>
              <FlexLayout direction='column' className='h-full'>
                <FlexLayout alignItems='center' justifyContent='end' spacing={4} className='h-[2.5rem] px-8'>
                  {selectedPanel === 'wallets' && (
                    <>
                      <Button variant='ghost' size='sm'>
                        Deposit
                      </Button>
                      <Button variant='ghost' size='sm'>
                        Withdraw
                      </Button>
                    </>
                  )}
                </FlexLayout>
                {selectedPanel === 'wallets' && (
                  <PortfolioBreakdown
                    setSelectedAsset={(asset: Asset) => {
                      navigate(NavigationURLs.SwyftxPro, { pathParams: { asset: asset.code } });
                    }}
                  />
                )}
                {selectedPanel === 'openOrders' && (
                  <OpenOrders
                    selectedAsset={selectedAsset}
                    hideCancelled={hideCancelled}
                    filterCurrentAsset={filterCurrentAsset}
                  />
                )}
                {selectedPanel === 'orderHistory' && (
                  <OrderHistory
                    selectedAsset={selectedAsset}
                    hideCancelled={hideCancelled}
                    filterCurrentAsset={filterCurrentAsset}
                  />
                )}
              </FlexLayout>
            </div>
          )}

          {selectedPanel === 'trade' && (
            <div className='h-full w-full'>
              <SwyftxProTradePanel />
            </div>
          )}
        </>
      )}

      <FlexLayout
        alignItems='center'
        spacing={16}
        className='flex h-[5rem] w-full border-t border-color-border-main p-16 md:hidden'
      >
        <SwyftxProTradePanelModal>
          <Button
            color='success'
            size='lg'
            className='w-1/2'
            onClick={() => onSelectTradeDirection('buy')}
            disabled={selectedAssetEqualsBaseAsset}
          >
            Buy
          </Button>
        </SwyftxProTradePanelModal>
        <SwyftxProTradePanelModal>
          <Button
            color='destructive'
            size='lg'
            className='w-1/2'
            onClick={() => onSelectTradeDirection('sell')}
            disabled={selectedAssetEqualsBaseAsset}
          >
            Sell
          </Button>
        </SwyftxProTradePanelModal>
      </FlexLayout>
    </FlexLayout>
  );
});

export { SwyftxProChart };

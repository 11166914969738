import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AssetGroupCard } from '@swyftx/aviary/complex/AssetGroupCard';
import { ArrowLineOnlyLeft, ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useItemPagination } from '@hooks/useItemPagination/useItemPagination';

import { observer } from 'mobx-react-lite';
import { DashboardWidgetRowProps } from 'src/lib/dashboard/types';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { MarketActivityCompactWidget } from './MarketActivityCompactWidget';
import { useBuildMarketActivityCards } from '../../hooks/useBuildMarketActivityCards';
import { MarketActivityCard } from '../../types/Markets.types';
import { getEmptyContent, getEmptyIcon } from '../../utils';

const widgetId: DashboardWidgets = 'market-activity';

type Props = {
  pageSize?: number;
} & DashboardWidgetRowProps;

const MarketActivityWidget: React.FC<Props> = observer(({ pageSize = 3 }) => {
  const baseAsset = useBaseAsset();

  const { navigateToAsset } = useAssetNavigation();

  const activityCards = useBuildMarketActivityCards();

  const { page, hasAnotherPage, nextPage, prevPage } = useItemPagination<MarketActivityCard>({
    items: activityCards,
    itemsPerPage: pageSize,
    widgetId,
  });

  if (!baseAsset) return null;

  return (
    <>
      <div className='block w-full @sm:hidden'>
        <MarketActivityCompactWidget />
      </div>
      <ElementContainer
        title='Market activity'
        className='hidden w-full @container @sm:flex'
        actions={
          <FlexLayout spacing={4} alignItems='center'>
            <FlexLayout spacing={4}>
              <Button variant='outlined' size='sm' layout='icon' onClick={prevPage} disabled={page === 0}>
                <ArrowLineOnlyLeft className='h-16 w-16' />
              </Button>
              <Button variant='outlined' size='sm' layout='icon' onClick={nextPage} disabled={!hasAnotherPage}>
                <ArrowLineOnlyRight className='h-16 w-16' />
              </Button>
            </FlexLayout>
          </FlexLayout>
        }
      >
        <FlexLayout
          alignItems='start'
          justifyContent='space-between'
          spacing={24}
          className='overflow-hidden px-1 py-1'
        >
          {activityCards.map((item: MarketActivityCard) => (
            <AssetGroupCard
              key={item.key}
              title={item.title}
              actions={item.actions}
              assets={item.assets}
              baseAsset={baseAsset}
              className='h-full min-h-[472px] @sm:min-w-[calc(33.33%-16px)]'
              numAssets={10}
              loaded
              style={{ transform: `translateX(calc(-${page * 100}% - ${page * 24}px)`, transition: 'transform 300ms' }}
              emptyContent={
                <FlexLayout
                  className='h-full w-full px-8'
                  alignItems='center'
                  justifyContent='center'
                  direction='column'
                  spacing={8}
                >
                  {getEmptyIcon(item.key)}
                  <Body className=' text-center' color='secondary' size='small' weight='emphasis'>
                    {getEmptyContent(item.key)}
                  </Body>
                </FlexLayout>
              }
              onSelectAsset={navigateToAsset}
            />
          ))}
        </FlexLayout>
      </ElementContainer>
    </>
  );
});

export { MarketActivityWidget };

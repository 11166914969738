import React, { useCallback, useEffect } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { useSwyftxProTradeStopLossDetails } from 'src/lib/trade-pro/hooks/useSwyftxProTradeStopLoss';
import { useSwyftxProTradeStopLoss } from 'src/lib/trade-pro/hooks/useSwyftxProTradeStopLoss/useSwyftxProTradeStopLoss';
import { MarketOcoTab } from 'src/lib/trade-pro/types/TradePro.types';

import { useSecondaryOcoTabs } from './SwyftxProMarketOCO/useSecondaryOcoTabs';

type Props = {
  disabled?: boolean;
  type?: MarketOcoTab;
  showPrefillOptions?: boolean;
};

const SwyftxProTradeStopLossInput: React.FC<Props> = ({ disabled, type = 'price', showPrefillOptions }) => {
  const { value, setDirty, leadingText, trailingText, leadingTooltip, onChangeValue, resetValues } =
    useSwyftxProTradeStopLoss({
      tabType: type,
    });
  const { stopLossError } = useSwyftxProTradeStopLossDetails();
  const { secondaryTabs, selectedSecondaryTabs, setSelectedSecondaryTabs } = useSecondaryOcoTabs({ type });

  useEffect(() => {
    resetValues();
    setSelectedSecondaryTabs(undefined);
  }, [resetValues, setSelectedSecondaryTabs, type]);

  const onSelectPrefillOption = useCallback(
    (prefillOption: string) => {
      setSelectedSecondaryTabs(prefillOption);
      onChangeValue(prefillOption);
    },
    [onChangeValue, setSelectedSecondaryTabs],
  );

  const onValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(e.target.value);
      setSelectedSecondaryTabs(undefined);
    },
    [onChangeValue, setSelectedSecondaryTabs],
  );

  return (
    <FlexLayout direction='column' spacing={8}>
      <Input
        containerClassName='bg-color-background-surface-primary hover:bg-color-background-surface-primary focus-within:!bg-color-background-surface-primary focus-within:!outline-color-border-accent hover:outline-color-border-hover outline-color-border-main outline'
        className='text-right font-mono text-color-text-primary'
        error={stopLossError !== null}
        disabled={disabled}
        leading={
          <Tooltip title={leadingTooltip}>
            <Body
              weight='emphasis'
              className='pointer-events-auto cursor-default whitespace-nowrap uppercase '
              size='medium'
              color='primary'
            >
              {leadingText}
            </Body>
          </Tooltip>
        }
        placeholder='0.00'
        value={value}
        onChange={onValueChange}
        onFocus={() => setDirty(true)}
        trailing={
          <Numeric size='small' color='primary' className='whitespace-nowrap'>
            {trailingText}
          </Numeric>
        }
      />

      {stopLossError}

      {showPrefillOptions && secondaryTabs && (
        <EnhancedTabs
          variant='child'
          size='sm'
          tabs={secondaryTabs}
          onChange={onSelectPrefillOption}
          value={selectedSecondaryTabs}
        />
      )}
    </FlexLayout>
  );
};

export { SwyftxProTradeStopLossInput };

/* eslint-disable max-len */
import { Release, ReleaseVersion } from '../whats-new.type';

const releaseTwo: Release = {
  version: ReleaseVersion.Two,
  features: [
    {
      imgSrc: '/assets/images/releases/1/feature-1.png',
      title: 'Customise the layout to suit your preferences',
      descriptions: [
        'More customisation options added to your Dashboard page',
        'Ability to keep the left side menu permanently open or closed',
        'Ability to keep the trading panel open or closed, on every page',
      ],
    },
  ],
};

export { releaseTwo };

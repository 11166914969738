import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';

import { Typography, Link } from '@swyftx/react-web-design-system';

import Links from '@shared/constants/links';
import { AccountPurpose } from '@shared/enums';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';
import { VerificationData } from '@Onboarding/types/Onboarding.types';

import { Controller, useForm } from 'react-hook-form';

import { OnboardingStepContainer } from './OnboardingStepContainer';
import { RadioSelection } from './components/RadioSelection';

type FormInputs = Pick<VerificationData, 'accountPurpose'>;

const PurposeOfUsingStep: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const onboardingService = useOnboardingService();
  const verificationData = useOnboardingSelector((state) => state.context.verificationData);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      accountPurpose: verificationData?.accountPurpose,
    },
  });

  const onSubmit = (data: FormInputs) => {
    onboardingService.send({ type: 'NEXT', data });
  };

  const accountPurposeList = useMemo(
    () =>
      Object.values(AccountPurpose).map((purpose) => ({
        label: t(`fields.accountPurpose.options.${purpose}`),
        value: purpose,
      })),
    [t],
  );

  return (
    <OnboardingStepContainer onSubmit={handleSubmit(onSubmit)} submitDisabled={!isValid}>
      <Typography color='text.secondary' marginBottom={4}>
        {t('fields.accountPurpose.description')}{' '}
        <Link target='_blank' rel='noopener noreferrer' href={Links.partners.austracRegulations}>
          {t('fields.accountPurpose.descriptionLink')}
        </Link>
        .
      </Typography>
      <Controller
        name='accountPurpose'
        control={control}
        rules={{
          required: t('fields.accountPurpose.required'),
        }}
        render={({ field }) => (
          <FormControl>
            <FormLabel
              required
              id='account-purpose-label'
              sx={{
                fontWeight: 500,
                fontSize: 14,
                color: 'text.primary',
                marginBottom: 1,
                '.MuiFormLabel-asterisk': {
                  color: 'error.main',
                },
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              {t('fields.accountPurpose.label')}
            </FormLabel>

            <RadioGroup
              aria-labelledby='account-purpose-label'
              aria-required='true'
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            >
              {accountPurposeList.map(({ label, value }) => (
                <RadioSelection key={value} label={label} value={value} />
              ))}
            </RadioGroup>

            {errors.accountPurpose?.message && <FormHelperText error>{errors.accountPurpose.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </OnboardingStepContainer>
  );
};

export { PurposeOfUsingStep };

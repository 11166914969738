import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { NameInput } from '@global-components/Input';
import { AccountNumberInput } from '@global-components/Input/AccountNumberInput/AccountNumberInput';
import { BsbInput } from '@global-components/Input/BsbInput/BsbInput';

import { Asset } from '@shared/api';
import { FiatCodeEnum } from '@shared/enums';
import { UIStore } from '@shared/store';

import { AddAddressFormData, AddFiatAddressFormData, TransferModalStep } from '../../TransferModal.types';
import { useAddWithdrawalAddress } from '../../hooks/useAddWithdrawalAddress';

type Props = {
  asset: Asset;
  addAddressFormData: AddFiatAddressFormData;
  onUpdateFormData: <T extends AddAddressFormData>(key: keyof T, value: T[keyof T]) => void;
  onClose: () => void;
  setAddAddressToken: React.Dispatch<React.SetStateAction<string>>;
};

const AddNewAddressFiatFields: React.FC<Props> = ({
  asset,
  addAddressFormData,
  onUpdateFormData,
  onClose,
  setAddAddressToken,
}) => {
  const { accountHolderName, accountName, accountNumber, bsb } = addAddressFormData;
  const [bsbValid, setBsbValid] = useState<boolean>(false);
  const [accountNumberValid, setAccountNumberValid] = useState<boolean>(false);
  const [accountNameValid, setAccountNameValid] = useState<boolean>(false);
  const [accountHolderNameValid, setAccountHolderNameValid] = useState<boolean>(false);

  const { onNextStep, onBack } = useContext(StepModalContext);
  const { addWithdrawalAddress } = useAddWithdrawalAddress({ asset });
  const [addingAdddress, setAddingAddress] = useState<boolean>(false);

  const { addToastMessage } = UIStore.useUIStore;

  const { t: commonT } = useTranslation('common');

  const accountNumberCharacters = useMemo(
    () => ({
      min: asset.code === FiatCodeEnum.AUD ? 6 : 16,
      max: asset.code === FiatCodeEnum.AUD ? 9 : 16,
    }),
    [asset.code],
  );

  const addAddressDisabled = useMemo(() => {
    if (!accountHolderNameValid || !accountNameValid || !accountNumberValid) return true;
    if (asset.code === FiatCodeEnum.AUD && !bsbValid) return true;

    return false;
  }, [accountHolderNameValid, accountNameValid, accountNumberValid, asset.code, bsbValid]);

  const onAddAddress = useCallback(async () => {
    if (addAddressDisabled) return;

    setAddingAddress(true);
    try {
      const { mfaType, token } = await addWithdrawalAddress(addAddressFormData);

      switch (mfaType) {
        case 'sms': {
          setAddAddressToken(token);
          onNextStep(TransferModalStep.PhoneVerification);
          break;
        }
        case 'email': {
          setAddAddressToken(token);
          onNextStep(TransferModalStep.EmailVerification);
          break;
        }
        default: {
          setAddAddressToken('');
          onClose();
          addToastMessage({ severity: 'success', message: commonT('addAddress.success') });
          break;
        }
      }
    } catch (error: any) {
      setAddAddressToken('');
      addToastMessage({ severity: 'error', message: error?.message || commonT('addAddress.genericError') });
    } finally {
      setAddingAddress(false);
    }
  }, [
    addAddressDisabled,
    addAddressFormData,
    addToastMessage,
    addWithdrawalAddress,
    commonT,
    onClose,
    onNextStep,
    setAddAddressToken,
  ]);

  return (
    <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
      <Body color='secondary' size='small'>
        Please ensure all account information is correct as withdrawals made to an incorrect account will incur a $40{' '}
        {asset.code.toUpperCase()} Dishonour Fee.
      </Body>

      <NameInput
        id='create-new--withdraw-account-form-account-name'
        label='Account name:'
        autoFocus
        setValid={setAccountNameValid}
        onChange={(e) => onUpdateFormData<AddFiatAddressFormData>('accountName', e.target.value)}
        placeholder='Enter name for this account'
        type='AccountName'
        value={accountName}
        required
      />

      <NameInput
        id='create-new--withdraw-account-form-account-holder-name'
        label='Account holder name:'
        setValid={setAccountHolderNameValid}
        onChange={(e) => onUpdateFormData<AddFiatAddressFormData>('accountHolderName', e.target.value)}
        placeholder='Enter name of account holder'
        type='AccountHolderName'
        value={accountHolderName}
        required
      />

      {asset.code === FiatCodeEnum.AUD && (
        <BsbInput
          id='create-new--withdraw-account-form-bsb'
          value={bsb}
          setValid={setBsbValid}
          onChange={(e) => onUpdateFormData<AddFiatAddressFormData>('bsb', e.target.value)}
          required
        />
      )}

      <AccountNumberInput
        characters={accountNumberCharacters}
        id='create-new--withdraw-account-form-account-number'
        value={accountNumber}
        setValid={setAccountNumberValid}
        onChange={(e) => onUpdateFormData<AddFiatAddressFormData>('accountNumber', e.target.value)}
        required
      />

      <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
        <Button variant='outlined' size='lg' onClick={() => onBack()}>
          Cancel
        </Button>
        <Button size='lg' onClick={onAddAddress} disabled={addAddressDisabled} loading={addingAdddress}>
          Add address
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

export { AddNewAddressFiatFields };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Link } from '@swyftx/aviary/icons/outlined';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { OrderType } from '@shared/api';

import { getOrderType } from '../utils';

interface Props {
  type: OrderType | null;
  isLinked?: boolean;
}

export const OrderTypeCell: React.FC<Props> = (props) => {
  const { type, isLinked } = props;

  const orderType = useMemo(() => getOrderType(type), [type]);

  return (
    <FlexLayout alignItems='center' spacing={8} justifyContent='start'>
      <NumericDataItem size='small' data={orderType} color='primary' className='w-64' />
      {isLinked && (
        <FlexLayout className='rounded-4 bg-color-background-primary-subtle px-4 py-2' spacing={4} alignItems='center'>
          <FlexLayout
            alignItems='center'
            justifyContent='center'
            className='h-12 w-12 rounded-4 bg-color-background-primary'
          >
            <Link className='h-8 w-8 text-color-text-inverse' />
          </FlexLayout>

          <Body className='hidden uppercase @sm:block' color='accent' size='xsmall'>
            Linked
          </Body>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

const DefaultLightThemePalette: any = {
  mode: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#1f2328',
    secondary: '#454f5a',
    disabled: '#b6c1ce',
  },
  divider: '#dce1e7',
  background: {
    paper: Colors.White,
    default: '#fdfdfd',
  },
  getContrastText: () => Colors.Black,
  augmentColor: () => ({
    main: '#0072ed',
    light: '#0072ed',
    dark: '#0072ed',
    contrastText: Colors.White,
  }),
  action: {
    hover: '#f6f7f9',
  },
  grey: {
    50: '#fdfdfe',
    100: '#fdfdfe',
    200: '#ededef',
    300: '#dcdde0',
    400: '#b6b9bd',
    500: '#8a8f97',
    600: '#585f6a',
    700: '#353941',
    800: '#24272d',
    900: '#0c0d0f',
    A100: '#fdfdfe',
    A200: '#ededef',
    A400: '#b6b9bd',
    A700: '#353941',
  },
  primary: {
    main: '#0072ed',
  },
  secondary: {
    main: '#0072ed',
  },
  error: {
    main: '#d41a0b',
  },
  warning: {
    main: '#ffc900',
  },
  info: {
    main: '#0072ed',
  },
  success: {
    main: '#038214',
  },
  common: {
    black: Colors.Black,
    white: Colors.White,
  },
};

const DefaultLightTheme = createTheme(DefaultLightThemePalette);

export { DefaultLightTheme, DefaultLightThemePalette };

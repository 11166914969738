import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import { Image } from '@swyftx/aviary/atoms/Image';
import { UnverifiedFilled, VerifiedFilled } from '@swyftx/aviary/icons/filled';
import { Button, IconButton, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { AccountStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';
import { useVerificationLevels } from '@Profile/subroutes/Verification/components/VerificationLevel/VerificationLevel.data';
import { VerificationFunctionalityItem } from '@Profile/subroutes/Verification/components/VerificationLevel/VerificationLevelCard/VerificationFunctionalityItem';
import { VerificationRequirementsItem } from '@Profile/subroutes/Verification/components/VerificationLevel/VerificationLevelCard/VerificationRequirementsItem';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { accountStatusEnumToSimpleStatusMap, getAccountStatusImage, nextLevelStatus } from './VerificationTile.const';

const VerificationTile: React.FC<DashboardProps> = observer(({ tile, dragProps }) => {
  const theme = useTheme();
  const { navigate } = useNavigateRoute();
  const [expanded, setExpanded] = useState(false);
  const { getAccountStatus, userProfile, isRestricted: isRestrictedFunc } = UserStore.useUserStore;
  const { t } = useTranslation('dashboard');
  const { t: profileT } = useTranslation('profile.verification');
  const verificationLevels = useVerificationLevels();
  const accountStatus = accountStatusEnumToSimpleStatusMap[getAccountStatus()];

  const icon = getAccountStatusImage(accountStatus);
  const verificationLevel = useMemo(
    () => verificationLevels.find((level) => level.status === getAccountStatus()),
    [getAccountStatus(), verificationLevels],
  );

  const nextLevel = useMemo(
    () => verificationLevels.find((level) => level.status === nextLevelStatus[getAccountStatus()]),
    [getAccountStatus(), verificationLevels],
  );

  const upgradeToNextStatus = () => {
    navigate(NavigationURLs.Onboarding);
  };

  if ((!verificationLevel && !nextLevel) || !userProfile) return null;

  const isRestricted = isRestrictedFunc();

  return (
    <DashboardBaseTile
      tileName={tile.name}
      dragProps={dragProps}
      title={t('verificationTile.title')}
      cardSx={{ height: '100%', maxHeight: expanded ? 'auto' : '100%' }}
      headerBarActionItem={
        getAccountStatus() !== AccountStatusEnum.DIAMOND && (
          <IconButton onClick={() => setExpanded((current) => !current)} sx={{ padding: 0 }}>
            <ExpandMore sx={{ transition: '0.2s', transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
          </IconButton>
        )
      }
    >
      <Stack spacing={1} position='relative' padding={2}>
        <Box position='absolute' top={theme.spacing(1)} right={theme.spacing(1)}>
          <Image image={icon} alt={accountStatus} className='h-[80px] w-[80px]' />
        </Box>
        <Typography fontSize={pxToRem(12)} color='text.secondary'>
          {t('verificationTile.currentTier')}
        </Typography>
        <Stack spacing={1} direction='row' alignItems='center'>
          {!isRestricted && <VerifiedFilled className='ml-4' style={{ color: verificationLevel?.color }} />}
          {isRestricted && <UnverifiedFilled className='ml-4 text-color-text-error' />}
          <Typography fontSize={pxToRem(18)} fontWeight={600}>
            {profileT(verificationLevel?.label as any)}
          </Typography>
        </Stack>

        <Stack direction='column'>
          <Typography fontSize={pxToRem(14)} color='text.secondary'>
            {t(`verificationTile.nextSteps.${getAccountStatus().toString()}` as any)}
          </Typography>
          {isRestricted && (
            <Button variant='text' color='primary' onClick={upgradeToNextStatus} sx={{ marginTop: 1 }}>
              {t('verificationTile.buttonLabels.resolve')}
            </Button>
          )}
        </Stack>

        <Collapse in={expanded}>
          <Stack spacing={2}>
            <>
              {!isRestricted && (
                <>
                  <Stack spacing={1}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Typography fontSize={pxToRem(14)} fontWeight={600}>
                        {t('verificationTile.nextLevel')}
                      </Typography>
                      <VerifiedFilled className='ml-4' style={{ color: nextLevel?.color }} />
                      <Typography fontSize={pxToRem(14)} fontWeight={600}>
                        {profileT(nextLevel?.label as any)}
                      </Typography>
                    </Stack>
                    {nextLevel?.functionalityUnlocked?.map((functionality) => (
                      <VerificationFunctionalityItem key={functionality} functionality={functionality} />
                    ))}
                  </Stack>
                  <Stack spacing={1}>
                    <Typography fontSize={pxToRem(14)} fontWeight={600}>
                      {t('verificationTile.requirementsToUnlock')} {profileT(nextLevel?.label as any)}
                    </Typography>
                    <Stack direction='column' alignItems='flex-start' spacing={1} color='text.secondary'>
                      {nextLevel?.requirementsToUnlock?.map((requirement) => (
                        <VerificationRequirementsItem key={requirement} requirement={requirement} />
                      ))}
                    </Stack>
                  </Stack>
                </>
              )}

              {isRestricted && (
                <Stack spacing={1}>
                  <Typography fontSize={pxToRem(14)} color='text.secondary'>
                    {t(`verificationTile.nextSteps.${getAccountStatus().toString()}` as any)}
                  </Typography>
                  <Typography fontSize={pxToRem(14)} fontWeight={600}>
                    {t('verificationTile.currentRestrictions')}
                  </Typography>
                  {verificationLevel?.functionalityUnlocked?.map((functionality) => (
                    <VerificationFunctionalityItem key={functionality} functionality={functionality} />
                  ))}
                </Stack>
              )}

              <Box>
                {!isRestricted && getAccountStatus() < AccountStatusEnum.DIAMOND && (
                  <Button variant='text' color='primary' onClick={upgradeToNextStatus}>
                    {t(`verificationTile.upgrade.${getAccountStatus().toString()}` as any)}
                  </Button>
                )}
                {isRestricted && (
                  <Button variant='contained' color='primary' size='large' fullWidth onClick={upgradeToNextStatus}>
                    {t('verificationTile.buttonLabels.resolveRestrictions')}
                  </Button>
                )}
              </Box>
            </>
          </Stack>
        </Collapse>
      </Stack>
    </DashboardBaseTile>
  );
});

export { VerificationTile };

import React from 'react';

import { AccountStatusEnum } from '@shared/enums';

import { BronzeCard } from './BronzeCard';
import { DiamondCard } from './DiamondCard';
import { GoldCard } from './GoldCard';
import { SilverCard } from './SilverCard';

const verificationLevelCards: { [key: string]: React.ReactElement } = {
  [AccountStatusEnum.BRONZE]: <BronzeCard />,
  [AccountStatusEnum.SILVER]: <SilverCard />,
  [AccountStatusEnum.GOLD]: <GoldCard />,
  [AccountStatusEnum.GOLD_NZ]: <GoldCard />,
  [AccountStatusEnum.DIAMOND]: <DiamondCard />,
};

export { verificationLevelCards };

import React, { PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { PriceAlert } from '../../dynamicPriceAlerts.types';
import { useDeleteDynamicPriceAlert } from '../../hooks/useDeleteDynamicPriceAlert';

type Props = {
  alert: PriceAlert;
  onClose?: () => void;
  onDelete?: () => void;
};

const DeletePriceAlertModal: React.FC<PropsWithChildren<Props>> = ({ alert, onClose, onDelete, children = null }) => {
  const [open, setOpen] = useState<boolean>(!children);
  const isXs = useTailwindBreakpoint('xs');
  const { deletePriceAlert } = useDeleteDynamicPriceAlert();
  const { t } = useTranslation('priceAlerts');

  const handleOpenChange = useCallback(
    (o?: boolean) => {
      setOpen(!!o);
      if (onClose && !o) onClose();
    },
    [onClose],
  );

  const handleDeleteAlert = useCallback(async () => {
    await deletePriceAlert(alert.priceAlertUuid, alert.secondary);
    setOpen(false);
    if (onDelete) onDelete();
  }, [alert, deletePriceAlert, onDelete]);

  return (
    <Modal
      triggerElement={children}
      position={isXs ? 'bottom' : 'center'}
      className='min-w-[100vw] sm:!min-w-[500px] sm:!max-w-[500px]'
      overlayClassName='z-modal-popover-backdrop'
      onOpenChange={handleOpenChange}
      open={open}
    >
      <FlexLayout
        direction='column'
        alignItems='start'
        justifyContent='space-between'
        className='w-full overflow-hidden p-24'
        spacing={24}
      >
        <Heading size='h4'>{t('deleteAlert.title')}</Heading>
        <Body color='secondary'>{t('deleteAlert.content')}</Body>
        <FlexLayout className='w-full' alignItems='center' justifyContent='end' spacing={16}>
          <Button variant='outlined' onClick={() => handleOpenChange(false)}>
            {t('buttons.cancel')}
          </Button>
          <Button color='destructive' onClick={handleDeleteAlert}>
            {t('buttons.yesDeleteAlert')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { DeletePriceAlertModal };

import { DefaultDarkThemePalette } from '../dark/default.palette';
import createTheme from '../utils/createTheme';

const RefreshDarkThemePalette: any = {
  ...DefaultDarkThemePalette,
  augmentColor: () => ({
    main: '#4c4cf8',
    light: '#4c4cf8',
    dark: '#4c4cf8',
    contrastText: '#ffffff',
  }),
  text: {
    primary: '#F5F5F6',
    secondary: '#C3C6C8',
    disabled: '#22262C',
  },
  background: {
    paper: '#151e29',
    default: '#11151a',
  },
  divider: '#394756',
  action: {
    hover: '#28313c',
  },
  grey: {
    900: '#11151a',
    800: '#11151a',
    700: '#151e29',
    600: '#28313c',
    500: '#394756',
    400: '#596c81',
    300: '#8795a4',
    200: '#b5bdc7',
    100: '#dce0e4',
    50: '#f5f7f8',
    A100: '#dce0e4',
    A200: '#b5bdc7',
    A400: '#596c81',
    A700: '#151e29',
  },
  primary: {
    main: '#ABFFFD',
  },
  secondary: {
    main: '#ABFFFD',
  },
  error: {
    main: '#FC5151',
  },
  warning: {
    main: '#ECB24C',
  },
  info: {
    main: '#ABFFFD',
  },
  success: {
    main: '#4CC672',
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: '#151e29',
        },
      },
    },
  },
};

const RefreshDarkTheme = createTheme(RefreshDarkThemePalette, '"Public Sans"');

export { RefreshDarkTheme };

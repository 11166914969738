import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@swyftx/aviary/atoms/Image';
import { Stack, Typography } from '@swyftx/react-web-design-system';

const EmptyRewardsBreakdown: React.FC = () => {
  const { t } = useTranslation('rewards', { keyPrefix: 'totalRewardsPieChart' });

  return (
    <Stack alignItems='center' spacing={2} padding={2}>
      <Image image='empty_order' usePalette className='w-[105px]' alt='empty total rewards' />
      <Typography fontWeight={600}>{t('empty.title')}</Typography>
      <Typography fontSize={14}>{t('empty.content')}</Typography>
    </Stack>
  );
};

export { EmptyRewardsBreakdown };

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { TradeActionEnum } from '@Trade/types';

/* examples ---
  ADA -> XRP, AUD = swap, sell
  XRP, AUD -> ADA = swap, buy
  AUD -> ADA, XRP = buy, buy
  XRP, ADA -> AUD = sell, sell
*/
export const getOrderAction = (fromAsset: Asset, toAsset: Asset): TradeActionEnum => {
  const { isAssetFiat, getBaseAssets } = assetService;
  const baseAssets = getBaseAssets();

  // if trading from a single asset -- buy if fromAsset is primary, sell if fromAsset isn't primary and toAsset is primary, swap if fromAsset and toAsset aren't primary,
  const isFromPrimary = baseAssets.find((baseAsset) => baseAsset === fromAsset.id);
  const isToPrimary = baseAssets.find((baseAsset) => baseAsset === toAsset.id);

  if (isFromPrimary && isToPrimary) {
    if (isAssetFiat(fromAsset.id)) {
      return TradeActionEnum.Buy;
    }
    if (isAssetFiat(toAsset.id)) {
      return TradeActionEnum.Sell;
    }

    return TradeActionEnum.Buy;
  }

  if (isFromPrimary) {
    return TradeActionEnum.Buy;
  }

  if (isToPrimary) {
    return TradeActionEnum.Sell;
  }

  return TradeActionEnum.Swap;
};

import React from 'react';

import { AvatarColor } from '@swyftx/aviary/atoms/Avatar/Avatar.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AddInCircle } from '@swyftx/aviary/icons/outlined';

import { EntityAccount, EntityColor } from '@shared/store/userStore/@types/userTypes';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { MenuItem } from '../Menu';

interface Props {
  entityAccounts: EntityAccount[];
  selectAccount: (account: EntityAccount) => void;
  selectedAccount?: string;
  userHasCompletedOnboarding: boolean;
  isKyc1Complete: boolean;
  openCreateEntity?: () => void;
}

export const EntityAccountSwitcherOld: React.FC<Props> = observer((props) => {
  const {
    entityAccounts,
    selectAccount,
    selectedAccount,
    userHasCompletedOnboarding,
    isKyc1Complete,
    openCreateEntity,
  } = props;

  const { navigate } = useNavigateRoute();

  return (
    <>
      <div className='PII'>
        {entityAccounts.map((entity) => {
          let avatarColor: AvatarColor = 'personal';
          if (entity.entityColorIndex !== undefined) {
            const color = Object.entries(EntityColor).find(([, value]) => value === entity.entityColorIndex);
            avatarColor = color ? (color[0].toLocaleLowerCase() as AvatarColor) : 'personal';
          }
          return (
            <MenuItem
              key={entity.uuid}
              icon={entity.name[0]}
              avatarColor={avatarColor}
              text={entity.name}
              selected={selectedAccount === entity.uuid}
              action={() => selectedAccount !== entity.uuid && selectAccount(entity)}
            />
          );
        })}
      </div>
      {userHasCompletedOnboarding && (
        <FlexLayout
          direction='column'
          onClick={isKyc1Complete ? openCreateEntity : () => navigate(NavigationURLs.ProfileVerification)}
        >
          <MenuItem
            icon={<AddInCircle width={20} height={20} />}
            text='Create Entity'
            description={
              !isKyc1Complete ? (
                <Body size='xsmall' color='secondary'>
                  Upgrade to gold to access
                </Body>
              ) : undefined
            }
          />
        </FlexLayout>
      )}
    </>
  );
});

import { useCallback, useContext } from 'react';

import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';

import { StorageKey } from '@shared/storage';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { useLocalStorage } from 'react-use';
import { stopLossEntryTour } from 'src/tours';

import { ProfitLossValueMethod } from '../../types';
import { stopLossModalTour } from '../tours/stopLossTour';

type Props = {
  profitLossValueMethod: ProfitLossValueMethod;
  setProfitLossValueMethod: React.Dispatch<React.SetStateAction<ProfitLossValueMethod>>;
};

const useProfitStopLossTours = ({ profitLossValueMethod, setProfitLossValueMethod }: Partial<Props>) => {
  const [stopLossTourViewed, setStopLossTourViewed] = useLocalStorage(StorageKey.STOP_LOSS_TOUR_VIEWED, false);
  const [stopLossEntryTourViewed, setStopLossEntryTourViewed] = useLocalStorage(
    StorageKey.STOP_LOSS_ENTRY_TOUR_VIEWED,
    false,
  );
  const { isLargeScreen } = useScreenBreakpoints();
  const { startTour } = useContext(TourGuideContext);

  const startEntryTour = useCallback(() => {
    if (!stopLossEntryTourViewed && isLargeScreen) {
      startTour(stopLossEntryTour, () => {
        setStopLossEntryTourViewed(true);
      });
    }
  }, [isLargeScreen, setStopLossEntryTourViewed, startTour, stopLossEntryTourViewed]);

  const startModalTour = useCallback(
    (force = false) => {
      if ((!stopLossTourViewed || force) && isLargeScreen) {
        const tour = stopLossModalTour(profitLossValueMethod, setProfitLossValueMethod);
        startTour(tour, () => {
          setStopLossTourViewed(true);
        });
      }
    },
    [
      isLargeScreen,
      profitLossValueMethod,
      setProfitLossValueMethod,
      setStopLossTourViewed,
      startTour,
      stopLossTourViewed,
    ],
  );

  return {
    startEntryTour,
    startModalTour,
  };
};

export { useProfitStopLossTours };

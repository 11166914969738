import React, { useCallback, useMemo, useState } from 'react';

import { NoChartError } from '@global-components/Errors/NoChartError';
import { PerformanceMenu } from '@global-components/PerformanceMenu';
import {
  PerformanceMenuControlEnum,
  performanceMenuControls,
} from '@global-components/PerformanceMenu/PerformanceMenu.const';
import { PerformanceMenuControl } from '@global-components/PerformanceMenu/PerformanceMenu.types';
import { LineChart, LineChartToolTip, ToolTipPeriod } from '@global-components/charts/LineChart';
import { EmptyLineChart } from '@global-components/charts/LineChart/EmptyLineChart/EmptyLineChart';
import {
  LineChartOverlay,
  LineChartTooltipData,
  LineChartTooltipPosition,
} from '@global-components/charts/LineChart/LineChart.types';
import {
  TimeOverlayMap,
  usePortfolioAssetLineOverlayData,
  usePortfolioLineChartData,
} from '@global-components/charts/LineChart/hooks/portfolioLineDataHooks';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { formatDate } from '@shared/utils';

import { useOnEntityChanged } from '@hooks/Entity/useOnEntityChange';
import { useCostBasis } from '@hooks/Portfolio';
import { useTheme } from '@hooks/useTheme';
import { AssetColors } from '@utils/assets';
import { generateKey } from '@utils/id';

import { observer } from 'mobx-react-lite';
import { useSize } from 'react-use';

const endDate = new Date();
const HEIGHT = 380;

type Props = {
  selectedAssets: Map<string, Asset>;
  costBasis: boolean;
};

export const isOverlayDefined = (overlay: LineChartOverlay | undefined): overlay is LineChartOverlay =>
  (overlay as LineChartOverlay) !== undefined;

const WalletPortfolioPerformanceChart: React.FC<Props> = observer(({ selectedAssets, costBasis }) => {
  const [selectedControl, setSelectedControl] = useState<PerformanceMenuControl>(
    performanceMenuControls[PerformanceMenuControlEnum.OneYear],
  );
  const { theme } = useTheme();
  const { getAsset } = assetService;

  const [widthSize, { width }] = useSize(<div className='h-0 w-full' />);
  const [reloadTrigger, setReloadTrigger] = useState(generateKey());
  const [tooltipInfo, setTooltipInfo] = useState<{
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  }>({ position: null, data: null });

  useOnEntityChanged(() => setReloadTrigger(generateKey()));

  const startDate = useMemo(
    () => (selectedControl.startTime ? new Date(selectedControl.startTime) : null),
    [selectedControl],
  );

  const { noDataYet, formattedData, loading } = usePortfolioLineChartData(startDate, endDate, undefined, reloadTrigger);

  const selectedAssetArray: Array<Asset> = useMemo(() => Array.from(selectedAssets.values()), [selectedAssets]);

  const { filteredOverlayDataMap } = usePortfolioAssetLineOverlayData(
    selectedAssetArray,
    startDate,
    endDate,
    reloadTrigger,
  );

  const { data: useCostBasisData } = useCostBasis(formattedData);

  const costBasisData = costBasis ? useCostBasisData : undefined;

  const overlayData: Array<LineChartOverlay> = useMemo(
    () =>
      Object.entries<TimeOverlayMap>(filteredOverlayDataMap)
        .map(([assetId, data]) => {
          const asset = getAsset(parseInt(assetId, 10));
          if (asset && formattedData) {
            const arrayData = Object.values(data);
            const misMatchedData = formattedData.length > arrayData.length;

            const formatted: LineChartOverlay = {
              color: AssetColors[asset.code] || theme.palette.grey[300],
              data: misMatchedData
                ? formattedData.map(({ time: overviewTime }) => {
                    const overviewTimeKey = formatDate(overviewTime);
                    const overlayDatum = data?.[overviewTimeKey];
                    return (
                      overlayDatum || {
                        time: overviewTime,
                        value: 0,
                      }
                    );
                  })
                : Object.values(data),
              variant: 'dashed',
              id: asset.id,
            };
            return formatted;
          }
          return undefined;
        })
        .filter(isOverlayDefined),
    [formattedData, getAsset, filteredOverlayDataMap, theme],
  );

  const tooltipCallback = useCallback(
    (tooltipPosition: LineChartTooltipPosition | null, tooltipData: LineChartTooltipData | null) => {
      setTooltipInfo({ data: tooltipData, position: tooltipPosition });
    },
    [],
  );

  const lineChart = useMemo(() => {
    const isLoading = loading || width === Infinity;

    if (noDataYet) {
      return <EmptyLineChart chartWidth={width} chartHeight={HEIGHT} />;
    }

    if (formattedData?.length || isLoading) {
      return (
        <LineChart
          tooltipCallback={tooltipCallback}
          idKey='wallet-performance-chart'
          chartData={formattedData || []}
          costBasis={costBasisData}
          overlays={overlayData}
          loading={isLoading}
          height={HEIGHT}
          width={width}
          showAxes
        />
      );
    }

    return (
      <NoChartError onTryAgain={() => setReloadTrigger(generateKey())} containerProps={{ sx: { height: HEIGHT } }} />
    );
  }, [loading, width, noDataYet, formattedData, tooltipCallback, costBasisData, overlayData]);

  return (
    <>
      <div className='relative h-full w-full p-16'>
        {widthSize}
        {lineChart}
        <LineChartToolTip
          tooltipPeriod={selectedControl.label as unknown as ToolTipPeriod}
          selectedAssets={selectedAssets}
          tooltipInfo={tooltipInfo}
          width={width}
        />
      </div>
      {!noDataYet && <PerformanceMenu selectedControl={selectedControl} setSelectedControl={setSelectedControl} />}
    </>
  );
});

WalletPortfolioPerformanceChart.displayName = 'WalletPortfolioPerformanceChart';

export { WalletPortfolioPerformanceChart };

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { formatCurrency, getPriceScale } from '@swyftx/currency-util';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api } from '@shared/api';
import { PriceAlert } from '@shared/api/@types/alerts';
import { Asset } from '@shared/api/@types/markets';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore, UIStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { MAX_PRICE_ALERT_PRECISION_VALUE } from 'src/lib/trade/trade.consts';

import { EnterAlertPrice } from './EnterAlertPrice';

type Props = {
  id: string;
  alerts?: PriceAlert[];
  asset: Asset;
  onClose: () => void;
  open: boolean;
  prevStep: () => void;
  refetchAlerts: () => void;
};

export const CreatePriceAlert: React.FC<Props> = observer(({ alerts, asset, open, prevStep, refetchAlerts }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'priceAlerts' });
  const { userProfile } = UserStore.useUserStore;
  const { addMessageBox } = UIStore.useUIStore;
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset()!;
  const assetRate = getRate(asset.id).midPrice;
  const isXs = useTailwindBreakpoint('xs');

  const [error, setError] = useState('');
  const [existingAlerts, setExistingAlerts] = useState<string[]>([]);
  const [triggerPrice, setTriggerPrice] = useState<string>('');
  const minDecimals = useMemo(() => getPriceScale(assetRate), [assetRate]);

  useEffect(() => {
    if (!alerts?.length) return;

    const alertPrices: string[] = [];
    alerts?.forEach((alert) => alert.status === 'WAITING' && alertPrices.push(alert.price.toString()));
    setExistingAlerts(alertPrices);
  }, [alerts]);

  useEffect(() => {
    const hasError = triggerPrice ? existingAlerts.includes(triggerPrice) : false;

    const emptyTriggerPrice = Big(triggerPrice).lte(0);

    if (triggerPrice.length && emptyTriggerPrice) {
      const minPrice = '0.'.padEnd(minDecimals + 1, '0') + '1';
      setError(`Price must be at least ${minPrice} ${baseAsset?.code}`);
      return;
    }

    if (triggerPrice.length && hasError) {
      setError('Price alert already exists');
      return;
    }

    if (triggerPrice.replace(',', '').length > MAX_PRICE_ALERT_PRECISION_VALUE) {
      setError('Trigger price must be less than 18 digits');
      return;
    }

    setError('');
  }, [triggerPrice, existingAlerts, baseAsset, minDecimals]);

  const createAlert = useCallback(async () => {
    if (!asset) return;

    try {
      const params = {
        primary: userProfile?.currency.id || FiatIdEnum.USD,
        secondary: asset.id,
      };

      await api.endpoints.createAlert({ params, data: { price: triggerPrice ?? '0' } });

      refetchAlerts();
      prevStep();
      addMessageBox({
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        content: <SuccessMessageBox title={t('successfulCreate')} />,
      });
    } catch (e) {
      addMessageBox({
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        content: <ErrorMessageBox title='Failed to create price alert. Please try again' />,
      });
    }
  }, [addMessageBox, asset, prevStep, refetchAlerts, t, triggerPrice, userProfile?.currency.id]);

  return (
    <Modal
      id='create-price-alert-modal'
      open={open}
      onClose={prevStep}
      title='Create new price alert'
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={24} className='p-24 pt-0'>
        <FlexLayout direction='row' className='w-full justify-between'>
          <Body weight='emphasis'>Current Price</Body>
          <FlexLayout direction='row' className='items-center' spacing={8}>
            <AssetIcon asset={asset} size={20} />
            <NumericDataItem data={formatCurrency(assetRate, { appendCurrency: true })} color='primary' size='medium' />
          </FlexLayout>
        </FlexLayout>

        <EnterAlertPrice
          setTriggerPrice={setTriggerPrice}
          baseAsset={baseAsset}
          asset={asset}
          error={error}
          triggerPrice={triggerPrice}
        />

        <FlexLayout direction='row' className='w-full justify-between' spacing={8}>
          <Button variant='outlined' onClick={prevStep} size='lg' className='w-full'>
            Cancel
          </Button>
          <Button onClick={createAlert} disabled={!!error || triggerPrice?.length < 1} size='lg' className='w-full'>
            Add price alert
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export default CreatePriceAlert;

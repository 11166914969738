import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const ClearTableFilters: React.FC<Props> = ({ disabled, onClick }) => (
  <Button variant='ghost' onClick={onClick} disabled={disabled}>
    Reset filters
  </Button>
);

export { ClearTableFilters };

import React from 'react';

import StackMUI, { StackProps } from '@mui/material/Stack';

import { getPIIClassName } from '../global-utils';
import { PIITypes } from '../types/PII.types';

const Stack: React.FC<StackProps & PIITypes> = ({ id, children, PII, ...props }) => (
  <StackMUI id={id} className={getPIIClassName(PII)} {...props}>
    {children}
  </StackMUI>
);

export { Stack };

import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Links } from '@shared/constants';
import { UserStore } from '@shared/store';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { GreenIDStatusCard } from './GreenIDStatusCard';
import { JumioStatusCard } from './JumioStatusCard';
import { isValidCountry } from '../VerificationMethod/VerifyIdentity/VerifyIdentity.util';

interface Props {
  onClose?: () => void;
}

const IdentityVerificationModal: React.FC<Props> = observer((props) => {
  const { onClose: handleClose } = props;
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const { t } = useTranslation('profile.verification');
  const { userProfile } = UserStore.useUserStore;
  const userCountry = userProfile?.address?.country;
  const isXs = useTailwindBreakpoint('xs');

  const onClose = useCallback(
    (open?: boolean) => {
      if (open) {
        return;
      }
      if (handleClose) {
        handleClose();
      }
      setVerificationStep(VerificationStep.None);
    },
    [handleClose, setVerificationStep],
  );

  if (!isValidCountry(userCountry)) return null;
  return (
    <Modal
      title={t('identityVerificationModal.title')}
      onClose={onClose}
      onOpenChange={onClose}
      open
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' className='w-full max-w-[40rem] p-24 pt-0' spacing={12}>
        <FlexLayout direction='column' spacing={12}>
          <FlexLayout direction='column' spacing={4}>
            <Body>As a registered trading platform, we are required by law to authenticate your identity.</Body>
            <BodyLink
              href={Links.help.dueDiligence}
              size='small'
              color='accent'
              className='decoration cursor-pointer underline'
            >
              Find out why we need to verify your ID.
            </BodyLink>
          </FlexLayout>
          <GreenIDStatusCard />
          <JumioStatusCard />
        </FlexLayout>
        <FlexLayout direction='row' className='w-full justify-end'>
          <Button variant='outlined' onClick={() => onClose(false)}>
            Close
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { IdentityVerificationModal };

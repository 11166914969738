import { useMemo } from 'react';

import { Asset, HistoricalOrder } from '@shared/api';
import { assetService } from '@shared/services';

const useProfitStopLossAssets = (order?: HistoricalOrder) => {
  const secondaryAsset: Asset | undefined = useMemo(
    () => (order ? assetService.getAsset(order.secondary_asset) : undefined),
    [order],
  );

  const primaryAsset: Asset | undefined = useMemo(
    () => (order ? assetService.getAsset(order.primary_asset) : undefined),
    [order],
  );

  return {
    primaryAsset,
    secondaryAsset,
  };
};

export { useProfitStopLossAssets };

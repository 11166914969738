import { TabItem } from '@swyftx/aviary/atoms/Tabs';

export type PortfolioPeriodType = '7D' | '30d' | '60d' | '90d' | '180d' | '1y';

export type PortfolioViewDensity = 'detailed' | 'simple';

export type PortfolioPerformanceTab = TabItem<PortfolioPeriodType>;

export const defaultPortfolioPerformanceTabs: PortfolioPerformanceTab[] = [
  {
    title: '7D',
    value: '7D',
  },
  {
    title: '30D',
    value: '30d',
  },
  {
    title: '90D',
    value: '90d',
  },
  {
    title: '180D',
    value: '180d',
  },
  {
    title: '1YR',
    value: '1y',
  },
];

export const periodToDays: { [key in PortfolioPeriodType]: number } = {
  '7D': 7,
  '30d': 30,
  '60d': 60,
  '90d': 90,
  '180d': 180,
  '1y': 365,
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Layer: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24'>
    <g clipPath='url(#clip0_1760_38254)'>
      <path
        d='M16.9228 11.8067L16.8907 11.7858L19.7151 10.13C21.2024 9.2588 21.2024 7.10976 19.7151 6.23848L14.3919 3.11788C13.2143 2.42751 11.756 2.42751 10.5785 3.11788L5.25516 6.2386C3.76914 7.10994 3.76908 9.25856 5.25516 10.1299L8.03549 11.7598L7.99838 11.7845C7.66726 12.0047 7.57736 12.4516 7.79757 12.7827C8.01778 13.1138 8.46468 13.2037 8.7958 12.9835L9.41744 12.57L10.5785 13.2506C11.756 13.9411 13.2143 13.9411 14.3919 13.2506L15.503 12.5993L16.135 13.0122C16.4679 13.2297 16.9141 13.1362 17.1316 12.8033C17.3491 12.4704 17.2556 12.0242 16.9228 11.8067ZM13.6636 12.0084C12.9358 12.4351 12.0346 12.4352 11.3067 12.0084L9.79416 11.1217C9.7919 11.1201 9.78946 11.119 9.7872 11.1176L5.98343 8.88765C5.44632 8.57271 5.44638 7.79585 5.98349 7.48085L11.3067 4.36019C12.0346 3.93343 12.9358 3.93343 13.6636 4.36013L18.9872 7.48104C19.5249 7.79604 19.5248 8.57265 18.9869 8.88766L13.6636 12.0084ZM7.46603 18.1417C7.26492 18.4847 6.82376 18.5997 6.48074 18.3986L5.11459 17.5977C3.67727 16.7558 3.61892 14.699 5.00436 13.7758L6.00271 13.1117C6.33383 12.8915 6.78073 12.9814 7.00094 13.3125C7.22115 13.6436 7.13125 14.0906 6.8002 14.3108L5.80289 14.9741C5.30173 15.3082 5.32389 16.0516 5.84214 16.355L7.20902 17.1564C7.5521 17.3575 7.66714 17.7986 7.46603 18.1417ZM16.0127 19.0186C16.214 19.3616 16.0988 19.8027 15.7557 20.0038L14.3943 20.802C13.2173 21.4928 11.7579 21.4926 10.5804 20.8022L9.21383 20.001C8.87075 19.7999 8.7557 19.3588 8.95687 19.0157C9.15798 18.6727 9.59908 18.5577 9.9421 18.7588L11.3086 19.56C12.0367 19.9868 12.9386 19.9866 13.6653 19.5601L15.0276 18.7616C15.3705 18.5605 15.8116 18.6755 16.0127 19.0186ZM19.8415 17.6087L18.4795 18.4071C18.1365 18.6082 17.6953 18.4931 17.4942 18.1501C17.2931 17.807 17.4082 17.3659 17.7513 17.1648L19.1131 16.3664C19.635 16.0606 19.6545 15.3136 19.1482 14.981L18.1443 14.325C17.8113 14.1075 17.7178 13.6613 17.9353 13.3284C18.1529 12.9955 18.599 12.902 18.9319 13.1195L19.9377 13.7766C21.3362 14.6941 21.2863 16.7619 19.8415 17.6087Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1760_38254'>
        <rect width='16.9566' height='18.7199' fill='currentColor' transform='translate(4 2.6001)' />
      </clipPath>
    </defs>
  </svg>
);

export { Layer };

import { useCallback, useContext, useEffect, useState } from 'react';

import useDebounce from '@shared/hooks/lib/useDebounce';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useTradeQuote } from 'src/lib/trade/hooks/General';
import { usePriceScale } from 'src/lib/trade/hooks/General/usePriceScale';

import { useSwyftxProTradeAmount } from './useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from './useSwyftxProTradeAssets';
import { SwyftxProTradeContext } from '../context';

const useSwyftxProTradeQuote = () => {
  const { setTriggerPrice, tradeDirection, tradeType, triggerPrice } = useContext(SwyftxProTradeContext);
  const { buyAsset, sellAsset, limitAsset, selectedAsset, baseAsset } = useSwyftxProTradeAssets();
  const { value, total, amount, setAmount, setTotal } = useSwyftxProTradeAmount();
  const { rates } = RatesStore.useRatesStore;
  const { calculatePriceScale } = usePriceScale();
  const [hasInitTriggerPrice, setHasInitTriggerPrice] = useState<boolean>(false);

  const debouncedAmount = useDebounce(value, 200);

  const { data, quoteError, errorMessage, isFetching } = useTradeQuote({
    buy: buyAsset?.code,
    sell: sellAsset?.code,
    amount: Big(debouncedAmount).toNumber(),
    limit: limitAsset?.code,
    pro: true,
    key: 'pro-trade',
  });

  useEffect(() => {
    setHasInitTriggerPrice(false);
  }, [selectedAsset]);

  const processTriggerPrice = useCallback(() => {
    if ((tradeType === 'limit' && hasInitTriggerPrice) || !selectedAsset || !rates[selectedAsset.id]) return;

    const rate = rates[selectedAsset.id];
    const priceScale = calculatePriceScale(selectedAsset);

    if (data) {
      const price = tradeDirection === 'buy' ? data.price : Big(1).div(data.price).toString();
      setTriggerPrice(Big(price).toFixed(priceScale));
    } else if (selectedAsset && rate) {
      const selectedAssetRate = tradeDirection === 'buy' ? rate.askPrice : rate.bidPrice;
      setTriggerPrice(Big(selectedAssetRate).toFixed(priceScale));
    } else {
      setTriggerPrice('');
    }

    setHasInitTriggerPrice(true);
  }, [
    tradeType,
    hasInitTriggerPrice,
    selectedAsset,
    rates,
    calculatePriceScale,
    data,
    tradeDirection,
    setTriggerPrice,
  ]);

  const processTotalAmount = useCallback(() => {
    if (!value || Big(value).lte(0)) {
      setAmount(value || '');
      setTotal(value || '');
      return;
    }

    if (tradeType === 'limit' && triggerPrice) {
      if (limitAsset?.code === baseAsset?.code) {
        setAmount(
          formatCurrency(Big(total).div(triggerPrice), selectedAsset, {
            hideCode: true,
            hideSeparator: true,
            hideSymbol: true,
          }),
        );
        return;
      } else {
        setTotal(
          formatCurrency(Big(amount).times(triggerPrice), selectedAsset, {
            hideCode: true,
            hideSeparator: true,
            hideSymbol: true,
          }),
        );
        return;
      }
    }

    if (!data || !buyAsset || !sellAsset) {
      return;
    }

    const buyScale = calculatePriceScale(buyAsset);
    const sellScale = calculatePriceScale(sellAsset);

    if (tradeDirection === 'buy') {
      if (limitAsset?.code !== buyAsset?.code) {
        setAmount(Big(data.amount).toFixed(buyScale));
      } else {
        setTotal(Big(data.total).toFixed(sellScale));
      }
    } else {
      if (limitAsset?.code !== sellAsset?.code) {
        setAmount(Big(data.total).toFixed(sellScale));
      } else {
        setTotal(Big(data.amount).toFixed(buyScale));
      }
    }
  }, [
    amount,
    baseAsset?.code,
    buyAsset,
    calculatePriceScale,
    data,
    limitAsset?.code,
    selectedAsset,
    sellAsset,
    setAmount,
    setTotal,
    total,
    tradeDirection,
    tradeType,
    triggerPrice,
    value,
  ]);

  useEffect(() => {
    processTriggerPrice();
    processTotalAmount();
  }, [processTotalAmount, processTriggerPrice]);

  return {
    data,
    quoteError,
    errorMessage,
    isFetching,
  };
};

export { useSwyftxProTradeQuote };

import React, { ReactNode } from 'react';

import PaperMUI from '@mui/material/Paper';
import TableMUI, { TableProps } from '@mui/material/Table';
import TableBodyMUI from '@mui/material/TableBody';
import TableCellMUI, { TableCellProps } from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHeadMUI from '@mui/material/TableHead';
import TableRowMUI from '@mui/material/TableRow';

export type TableCellProperties = TableCellProps & {
  id: string;
  children: ReactNode;
};

export type TableRow = {
  id: string;
  cells: TableCellProperties[];
};

type Props = {
  headers: TableCellProperties[];
  rows: TableRow[];
} & TableProps;

const Table: React.FC<Props> = ({ id, rows, headers, ...props }) => (
  <TableContainerMUI component={PaperMUI} {...props}>
    <TableMUI id={id} {...props}>
      <TableHeadMUI>
        <TableRowMUI>
          {headers.map((headerProps: TableCellProperties) => (
            <TableCellMUI key={headerProps.id} {...headerProps} />
          ))}
        </TableRowMUI>
      </TableHeadMUI>
      <TableBodyMUI>
        {rows.map((row: TableRow) => (
          <TableRowMUI key={row.id}>
            {row.cells.map((tableCellProps: TableCellProperties) => (
              <TableCellMUI key={tableCellProps.id} {...tableCellProps} />
            ))}
          </TableRowMUI>
        ))}
      </TableBodyMUI>
    </TableMUI>
  </TableContainerMUI>
);

export default Table;

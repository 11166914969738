import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Chip, Stack } from '@swyftx/react-web-design-system';

import { NavigationTab } from '@global-components/TabNavigationBar/TabNavigationBar.types';

import { UserStore } from '@shared/store';

import { useEntityVerification } from '@Profile/subroutes/Entities/Verification/useEntityVerification.hooks';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

export const useProfileWrapper = () => {
  const { isKyc1Complete, isKyc2Complete, isKyc2Required, userProfile, isEntity, entityMembers, isRestricted } =
    UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const showReferAFriend = isFeatureEnabled(AppFeature.ReferAFriend);
  const { steps } = useEntityVerification();
  const { t } = useTranslation('profile');

  const stepsRemaining = useMemo(() => steps.filter((step) => !step.completed).length, [steps]);

  const remainingVerificationItems = useMemo(() => {
    let verificationItems = 0;

    if (userProfile?.verification != null) {
      const emailVerified = Boolean(userProfile.verification?.email);
      const phoneVerified = Boolean(userProfile.verification?.phone);

      if (!emailVerified) verificationItems += 1;
      if (!phoneVerified) verificationItems += 1;
      if (!isKyc1Complete()) verificationItems += 1;
      if (!isKyc2Complete() && isKyc2Required()) verificationItems += 1;
    }

    return verificationItems;
  }, [isKyc1Complete, isKyc2Complete, isKyc2Required, userProfile?.verification]);

  const ProfileTabItems = useMemo((): NavigationTab[] => {
    const routes = [
      {
        id: 'profile-information',
        path: NavigationURLs.ProfileInformation,
        label: 'information',
      },
      {
        id: 'profile-verification',
        path: NavigationURLs.ProfileVerification,
        trailing: (
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            {remainingVerificationItems > 0 && (
              <Badge variant='standard' badgeContent={remainingVerificationItems} color='primary' />
            )}
            {isRestricted() && (
              <Chip color='error' size='small' sx={{ marginLeft: 2, fontSize: 12 }} label='Action required' />
            )}
          </Stack>
        ),
        label: 'verification',
      },
      {
        id: 'profile-settings',
        path: NavigationURLs.ProfileSettings,
        label: 'personalisation',
      },
      {
        id: 'profile-security',
        path: NavigationURLs.ProfileSecurity,
        label: 'security',
      },
      {
        id: 'profile-tax-reports',
        path: NavigationURLs.ProfileTaxReports,
        label: 'taxReports',
      },
      {
        id: 'profile-affiliate-program',
        path: NavigationURLs.ProfileAffiliateProgram,
        label: 'affiliateProgram',
      },
      {
        id: 'profile-api-keys',
        path: NavigationURLs.ProfileApiKeys,
        label: 'apiKeys',
      },
    ];

    routes.splice(5, 0, {
      id: 'profile-trade-fees',
      path: NavigationURLs.ProfileTradeFees,
      label: 'tradeFees',
    });

    if (showReferAFriend) {
      routes.push({
        id: 'profile-refer-a-friend',
        path: NavigationURLs.ProfileReferAFriend,
        label: 'referAFriend',
      });
    }

    return routes;
  }, [isRestricted, remainingVerificationItems, showReferAFriend]);

  const ProfileEntityTabItems: NavigationTab[] = useMemo(() => {
    const routes = [
      {
        id: 'profile-entity-verification',
        path: NavigationURLs.ProfileEntityVerification,
        label: 'entityVerification',
        trailing: stepsRemaining > 0 && (
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Badge variant='standard' badgeContent={stepsRemaining} color='primary' sx={{ marginLeft: 1 }} />
          </Stack>
        ),
      },
      {
        id: 'profile-entity-security',
        path: NavigationURLs.ProfileEntitySecurity,
        label: 'entitySecurity',
      },
      {
        id: 'profile-entity-linked-members',
        path: NavigationURLs.ProfileEntityLinkedMembers,
        label: 'entityLinkedMembers',
        trailing: entityMembers.length > 0 && (
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Badge variant='standard' badgeContent={entityMembers.length} color='primary' sx={{ marginLeft: 1 }} />
          </Stack>
        ),
      },
      {
        id: 'profile-settings',
        path: NavigationURLs.ProfileSettings,
        label: 'personalisation',
      },
      {
        id: 'profile-tax-reports',
        path: NavigationURLs.ProfileTaxReports,
        label: 'taxReports',
      },
      {
        id: 'profile-trade-fees',
        path: NavigationURLs.ProfileTradeFees,
        label: 'tradeFees',
      },
      {
        id: 'profile-affiliate-program',
        path: NavigationURLs.ProfileAffiliateProgram,
        label: 'affiliateProgram',
      },
      {
        id: 'profile-api-keys',
        path: NavigationURLs.ProfileApiKeys,
        label: 'apiKeys',
      },
    ];

    return routes;
  }, [stepsRemaining, entityMembers.length]);

  const tabs = useMemo(() => {
    const localTabs = isEntity() ? ProfileEntityTabItems : ProfileTabItems;

    return localTabs.map((tab) => ({
      ...tab,
      label: t(`profileWrapper.tabs.${[tab.label]}` as any),
    }));
  }, [isEntity, ProfileEntityTabItems, ProfileTabItems, t]);

  return { tabs };
};

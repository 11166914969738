import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Paper } from '@swyftx/aviary/icons/outlined';

import { UniversalTradeStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

interface Props {
  noPadding?: boolean;
}

const GlobalActions: React.FC<Props> = observer((props) => {
  const { noPadding } = props;
  const { showGlobalTrade, setShowGlobalTrade } = UniversalTradeStore;

  if (showGlobalTrade) return null;

  return (
    <FlexLayout
      className={cn('h-full', noPadding ? '' : 'p-24')}
      direction='column'
      alignItems='end'
      justifyContent='start'
    >
      <Button
        className='z-20 h-[54px] w-[54px] rounded-8 bg-color-background-surface-primary'
        variant='outlined'
        onClick={() => setShowGlobalTrade(true)}
      >
        <FlexLayout direction='column' alignItems='center' justifyContent='space-between' spacing={4}>
          <Paper height={20} width={20} className='text-color-text-secondary' />
          <Body size='xsmall' weight='emphasis' color='secondary'>
            Order
          </Body>
        </FlexLayout>
      </Button>
    </FlexLayout>
  );
});

export { GlobalActions };

import { Network } from '@shared/api/@types/markets';

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS = new Set([
  // BEP-2
  'BNB',
  // TERRA
  'LUNA',
  'LUNC',

  // ETH
  'AMB',
  'ARBITRUM',
  'AVAXC',
  'BSC',
  'CELO',
  'CHZ',
  'CTXC',
  'ETC',
  'ETH',
  'FTM',
  'GLMR',
  'KLAY',
  'MATIC',
  'MOVR',
  'OPTIMISM',
  'REI',
  'RON',
  'RSK',
  'THETA',
  'TOMO',
  'VET',
  'WAN',
  'WTC',
  'XDAI',

  // XLM
  'XLM',
]);

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_FULL_NAMES = [
  'Ambrosus (AMB)',
  'Arbitrum One (ARBITRUM)',
  'AVAX C-Chain (AVAXC)',
  'BNB Beacon Chain (BEP2) (BNB)',
  'BNB Smart Chain (BEP20) (BSC)',
  'CELO (CELO)',
  'Chiliz Chain (CHZ)',
  'Cortex (CTXC)',
  'Ethereum Classic (ETC)',
  'Ethereum (ERC20) (ETH)',
  'Fantom (FTM)',
  'Moonbeam (GLMR)',
  'Klaytn (KLAY)',
  'Terra (LUNA)',
  'Terra Classic (LUNC)',
  'Polygon (MATIC)',
  'Moonriver (MOVR)',
  'Optimism (OPTIMISM)',
  'REI Network (REI)',
  'Ronin (RON)',
  'RSK (RSK)',
  'Theta (THETA)',
  'TomoChain (TOMO)',
  'VeChain (VET)',
  'Wanchain (WAN)',
  'Walton Chain (WTC)',
  'Gnosis (XDAI)',
  'Stellar Network (XLM)',
];

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_TWO = new Set(['KAVA', 'HBAR', 'EOS', 'IOST', 'STX']);

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_TWO_FULL_NAMES = [
  'EOS',
  'Hedera Hashgraph',
  'IOST',
  'KAVA',
  'Stacks',
];

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_THREE = new Set(['BAND', 'SCRT', 'BTS', 'STEEM', 'HIVE']);

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_THREE_FULL_NAMES = [
  'BAND',
  'Secret',
  'BitShares',
  'Steem',
  'HIVE',
];

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FOUR = new Set(['XRP', 'TRX', 'SEGWITBTC']);

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FOUR_FULL_NAMES = ['Ripple', 'Tron (TRC20)', 'BTC(SegWit)'];

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FIVE = new Set(['DOT', 'ADA', 'SOL']);

export const DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FIVE_FULL_NAMES = ['Polkadot', 'Cardano', 'Solana'];

export const isAddressDeprecated = (network: Network) =>
  DEPRECATED_BINANCE_ADDRESS_NETWORKS.has(network.networkName) ||
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_TWO.has(network.networkName) ||
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_THREE.has(network.networkName) ||
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FOUR.has(network.networkName) ||
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FIVE.has(network.networkName);

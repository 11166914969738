import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import Wizard from '@global-components/Wizard';
import { StepProps } from '@global-components/Wizard/Wizard';

const CreateEntityStartStep: React.FC<StepProps> = (props) => {
  const { t } = useTranslation('entities');

  return (
    <Wizard.Step {...props}>
      <FlexLayout direction='column' alignItems='start' spacing={16}>
        <Body weight='bold' size='large'>
          {t('createEntities.info.title')}
        </Body>
        <Body color='secondary'>{t('createEntities.info.subTitle')}</Body>

        <Body color='secondary'>
          {t('createEntities.info.dotPointTitle')}
          <ul>
            <li> {t('createEntities.info.dotPoint1')}</li>
            <li> {t('createEntities.info.dotPoint2')}</li>
            <li> {t('createEntities.info.dotPoint3')}</li>
          </ul>
        </Body>

        <Body weight='bold' size='large'>
          {t('createEntities.info.moreInfo.title')}
        </Body>
        <Body color='secondary'>{t('createEntities.info.moreInfo.subTitle')}</Body>
      </FlexLayout>
    </Wizard.Step>
  );
};

export { CreateEntityStartStep };

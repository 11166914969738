import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Notification } from '@swyftx/aviary/atoms/Notification';

import { SourceOfWealthStatus } from '@shared/api/@types/compliance';

import { useSourceOfWealthAnalytics } from '@routes/SourceOfWealth/useSourceOfWealthAnalytics';

import { DateTime } from 'luxon';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useGetTimeRemaining } from 'src/lib/utils/useGetTimeRemaining';
import { useGetSourceOfWealthStatus } from 'src/lib/verification/hooks/useGetSourceOfWealth';

interface Props {
  action?: () => void;
}

export const SourceOfWealthRequiredNotification: React.FC<Props> = (props) => {
  const { action } = props;
  const navigate = useNavigate();
  const { startedVerification } = useSourceOfWealthAnalytics();
  const { loading, status, verification } = useGetSourceOfWealthStatus();
  const { timeRemaining } = useGetTimeRemaining(verification?.expiresAt || 0);

  if (loading || !status || !verification) return null;

  const navigateToSourceOfWealth = () => {
    if (status === SourceOfWealthStatus.REQUIRED || status === SourceOfWealthStatus.REQUESTED) {
      startedVerification();
    }
    if (action) action();
    navigate(NavigationURLs.SourceOfWealth);
  };

  const ActionButton: React.FC = () => (
    <Button onClick={navigateToSourceOfWealth} variant='ghost' size='sm'>
      Complete now
    </Button>
  );

  if ([SourceOfWealthStatus.MORE_INFO_SUBMITTED, SourceOfWealthStatus.SUBMITTED].includes(status)) {
    return null;
  }

  if (verification.expiresAt <= DateTime.now().toMillis()) {
    return (
      <Notification title='Source of wealth check required' severity='destructive' actions={<ActionButton />}>
        You failed to complete the source of wealth check in time. Complete it now to re-enable withdrawals on your
        account.
      </Notification>
    );
  }

  return (
    <Notification
      title='Source of wealth check required'
      severity='warning'
      actions={<ActionButton />}
    >{`You have ${timeRemaining} to complete the source of wealth check. Failure to complete within this timeframe will
    result in all withdrawals being disabled until completed.`}</Notification>
  );
};

import React from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

const AutoInvestOrderName: React.FC<Props> = ({ value, onChange }) => (
  <FlexLayout direction='column' className='w-full' spacing={4}>
    <Label htmlFor='auto-invest-order-name'>Order name</Label>
    <Input
      id='auto-invest-order-name'
      type='text'
      autoFocus
      autoComplete='off'
      maxLength={45}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder='Weekly Bitcoin DCA'
    />
  </FlexLayout>
);

export { AutoInvestOrderName };

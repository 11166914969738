import React from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { useSwyftxProMarketTakeProfit } from './useSwyftxProMarketTakeProfit';
import { SwyftxProTradeTakeProfitInput } from '../../SwyftxProTradeTakeProfitInput';

const SwyftxProMarketTakeProfit: React.FC = () => {
  const { showMarketTakeProfit, handleChecked, tabs, selectedTab, setSelectedTab } = useSwyftxProMarketTakeProfit();

  return (
    <FlexLayout direction='column' spacing={8}>
      <FlexLayout alignItems='center' spacing={8} justifyContent='space-between'>
        <FlexLayout spacing={8} alignItems='center'>
          <Checkbox
            checked={showMarketTakeProfit}
            onCheckedChange={handleChecked}
            className='h-16 w-16 cursor-pointer border-color-border-neutral'
          />
          <Tooltip title='A take profit order will be placed when the trigger price is reached and liquidity conditions are met'>
            <Body
              color='primary'
              size='small'
              className='cursor-pointer'
              onClick={() => handleChecked(!showMarketTakeProfit)}
            >
              Set{' '}
              <span className='underline decoration-color-text-disabled decoration-dashed underline-offset-4'>
                take profit
              </span>{' '}
              order
            </Body>
          </Tooltip>
        </FlexLayout>
        {showMarketTakeProfit && (
          <EnhancedTabs variant='default' size='sm' tabs={tabs} onChange={setSelectedTab} value={selectedTab} />
        )}
      </FlexLayout>

      {showMarketTakeProfit && <SwyftxProTradeTakeProfitInput type={selectedTab} showPrefillOptions />}
    </FlexLayout>
  );
};

export { SwyftxProMarketTakeProfit };

import React, { useMemo } from 'react';

import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { OrderType } from '@shared/api';

import { getOrderSide } from '../utils';

interface Props {
  orderType: OrderType | null;
}

export const OrderSide: React.FC<Props> = (props) => {
  const { orderType } = props;

  const side = useMemo(() => getOrderSide(orderType), [orderType]);

  return <NumericDataItem size='small' data={side} color={side === 'Buy' ? 'success' : 'error'} />;
};

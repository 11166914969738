import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TradeInput } from '@global-components/Input/TradeInput/TradeInput';
import { Balance, TradeInputVariant } from '@global-components/Input/TradeInput/TradeInput.data';

import { FiatIdEnum, LowLiquidityMax } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UniversalTradeStore, UserStore } from '@shared/store';
import { TradeData, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

import { useUniversalTradeAsset, useUniversalTradeUtilityStore } from '@hooks/Trade';
import { useLowLiquidity } from '@hooks/Trade/useLowLiquidity';

import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useRates } from 'src/lib/rates/hooks';

type Props = {
  variant?: TradeInputVariant;
  tradeDataKey: string;
  assetToDisplay?: number;
  title?: boolean;
  flippable?: boolean;
  slider?: boolean;
  showBalances?: boolean;
  onRemove?: (data?: TradeData) => void;
  disabled?: boolean;
  lockable?: boolean;
  minPercentage?: number;
  maxPercentage?: number;
};

const TradeAssetInput: React.FC<Props> = observer(
  ({
    variant = 'singleTrade',
    tradeDataKey,
    title = true,
    showBalances = true,
    flippable = true,
    slider = true,
    assetToDisplay,
    disabled = false,
    lockable,
    onRemove,
    minPercentage = 0,
    maxPercentage = 100,
  }) => {
    const [value, setValue] = useState<string>('');
    const { getMaxValue } = useUniversalTradeUtilityStore();
    const { getMinimumOrderAmount, convertRate } = useRates();
    const { t } = useTranslation('trade', { keyPrefix: 'tradeAssetInput' });
    const [hasNoFunds, setHasNoFunds] = useState(false);
    const { tradeType } = UniversalTradeStore;
    const { balances } = UserStore.useUserStore;

    const {
      fromAsset,
      toAsset,
      triggerFromAsset,
      customTrigger,
      limitAsset,
      balance, // NOTE: this is not the balance, it is the amount entered by the user. Ask me how I know
      data,
      error,
      clientSideError,
      isOverLowLiquidityThreshold,
      setTradeUIData,
    } = useUniversalTradeAsset(tradeDataKey);

    const { isLowLiquidity, maximumAmount } = useLowLiquidity(fromAsset?.id, toAsset?.id);

    const displayAsset = useMemo(
      () => (assetToDisplay ? assetService.getAsset(assetToDisplay) : undefined),
      [assetToDisplay],
    );

    const showSlider = useMemo(() => fromAsset?.id === limitAsset?.id, [fromAsset, limitAsset]);

    useEffect(() => {
      setValue(balance || '');
    }, []);

    useEffect(() => {
      if (!fromAsset || !limitAsset || !toAsset || disabled || !triggerFromAsset) return;

      const fromAssetBalance = balances[fromAsset.id]?.availableBalance;

      if (tradeType !== TradeType.OnTrigger) {
        // if there is no balance for the balance key pair OR
        // or the from balance is less than the minimum order amount for the limit asset, denominated in the from asset
        const fromAssetMinimum = getMinimumOrderAmount(fromAsset);
        let minOrderSize: Big;
        if (fromAsset.id === limitAsset.id) {
          minOrderSize = fromAssetMinimum;
        } else {
          const limitAssetMinimum = getMinimumOrderAmount(limitAsset);
          minOrderSize = convertRate(limitAsset, fromAsset, limitAssetMinimum);
        }
        const notEnoughFunds = fromAssetBalance === undefined || Big(fromAssetBalance).lt(minOrderSize);

        setHasNoFunds(notEnoughFunds);

        if (notEnoughFunds) {
          setTradeUIData({
            clientSideError: t('labels.noFundsAvailable', { assetCode: fromAsset.code }),
            isOverLowLiquidityThreshold: false,
          });
          return;
        }
      } else {
        setHasNoFunds(false);
      }

      // We need to work out a custom min Order amount based on the trigger price
      const assetToUse = customTrigger ? triggerFromAsset : limitAsset;

      const minTriggerOrderAmount = getMinimumOrderAmount(assetToUse);
      const limitingOnTriggerAsset = limitAsset.id === assetToUse.id;

      // if there is no amount entered (NOTE: this is called 'balance')
      if (!balance) {
        // clear the error state and wait for an input
        setTradeUIData({ clientSideError: '', isOverLowLiquidityThreshold: false });
        return;
      }

      // if an amount has been entered but it is not parseable to a Big, then return as an error
      if (!Big(balance)) {
        setTradeUIData({
          clientSideError: t('labels.invalidOrderAmount'),
          isOverLowLiquidityThreshold: false,
        });
        Sentry.captureException(`Unknown trade amount entered: ${balance}`);
        return;
      }

      const bigQuantity = Big(balance);

      if (tradeType === TradeType.OnTrigger) {
        if (!customTrigger) {
          setTradeUIData({ clientSideError: '', isOverLowLiquidityThreshold: false });
          return;
        }

        if (limitingOnTriggerAsset && bigQuantity.lt(minTriggerOrderAmount)) {
          setTradeUIData({
            clientSideError: t('labels.invalidMinOrder', {
              amount: formatCurrency(minTriggerOrderAmount, assetToUse),
              isOverLowLiquidityThreshold: false,
            }),
          });
          return;
        }

        const minLimitAmount = Big(minTriggerOrderAmount).div(Big(customTrigger));

        if (!limitingOnTriggerAsset && bigQuantity.lt(minLimitAmount)) {
          setTradeUIData({
            clientSideError: t('labels.invalidMinOrder', {
              amount: formatCurrency(minLimitAmount, limitAsset),
              isOverLowLiquidityThreshold: false,
            }),
          });
          return;
        }

        let quantityValue = bigQuantity;
        let maximumCustomAmount = maximumAmount;

        if (fromAsset.id !== FiatIdEnum.AUD) {
          const convertedTriggerPrice = convertRate(triggerFromAsset, FiatIdEnum.AUD, customTrigger);
          maximumCustomAmount = Big(LowLiquidityMax.AUD).div(convertedTriggerPrice);
        }

        if (isLowLiquidity && quantityValue.gt(maximumCustomAmount)) {
          setTradeUIData({
            clientSideError: t('labels.lowLiquidity', {
              amount: formatCurrency(maximumCustomAmount, limitAsset, { hideCode: false, appendCode: true }),
            }),
            isOverLowLiquidityThreshold: true,
          });
          return;
        }
      } else if (isLowLiquidity && bigQuantity.gt(maximumAmount)) {
        setTradeUIData({
          clientSideError: t('labels.lowLiquidity', {
            amount: formatCurrency(maximumAmount, limitAsset, { hideCode: false, appendCode: true }),
          }),
          isOverLowLiquidityThreshold: true,
        });
        return;
      } else if (bigQuantity.lt(minTriggerOrderAmount)) {
        setTradeUIData({
          clientSideError: t('labels.invalidMinOrder', { amount: formatCurrency(minTriggerOrderAmount, limitAsset) }),
          isOverLowLiquidityThreshold: false,
        });
        return;
      }

      setTradeUIData({ clientSideError: '', isOverLowLiquidityThreshold: false });
    }, [
      balance,
      customTrigger,
      triggerFromAsset,
      fromAsset,
      limitAsset,
      toAsset,
      disabled,
      isLowLiquidity,
      maximumAmount,
      getMinimumOrderAmount,
      setTradeUIData,
      tradeType,
      convertRate,
    ]);

    if (!fromAsset || !toAsset) return null;

    return (
      <TradeInput
        variant={variant}
        title={title ? t('labels.title') : ''}
        tradeData={data}
        asset={fromAsset}
        error={error}
        clientSideError={clientSideError}
        isOverLowLiquidityThreshold={isOverLowLiquidityThreshold}
        baseAsset={toAsset}
        assetToDisplay={displayAsset}
        showBalances={showBalances}
        tradeType={tradeType}
        onRemove={onRemove}
        balanceToUse={Balance.Trading}
        value={value}
        flippable={flippable}
        slider={slider && showSlider}
        showEnterAmountBanner={slider && !showSlider}
        maxValue={getMaxValue()}
        disabled={disabled}
        hasNoFunds={hasNoFunds}
        lockable={lockable}
        minPercentage={minPercentage}
        maxPercentage={maxPercentage}
      />
    );
  },
);

export { TradeAssetInput };

import { TourGuideVariant, TourStepAlignment } from './TourGuide.types';
import { TourPosition } from '../Tour/Tour';

const TOUR_SIZE = 256;
const SPOTLIGHT_TOUR_SIZE = 300;

const HORIZONTAL_BUFFER = 40;
const VERTICAL_BUFFER = 20;

type Position = 'top' | 'left' | 'right' | 'bottom';

const removeSpotlightElement = (index: number) => {
  document.getElementById(`spotlight_${index}`)?.remove();
};

const isOffPage = (boundingBox: DOMRect, position: Position, variant: TourGuideVariant) => {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const tourSize = variant === 'tour' ? TOUR_SIZE : SPOTLIGHT_TOUR_SIZE;

  switch (position) {
    case 'top':
      return boundingBox.top - tourSize - VERTICAL_BUFFER < 0;
    case 'bottom':
      return boundingBox.bottom + tourSize + VERTICAL_BUFFER > windowHeight;
    case 'left':
      return boundingBox.left - tourSize - HORIZONTAL_BUFFER < 0;
    case 'right':
      return boundingBox.right + tourSize + HORIZONTAL_BUFFER > windowWidth;
    default:
      return false;
  }
};

const getBestXPos = (alignment: TourStepAlignment, boundingBox: DOMRect, variant: TourGuideVariant) => {
  const windowWidth = window.innerWidth;
  const tourSize = variant === 'tour' ? TOUR_SIZE : SPOTLIGHT_TOUR_SIZE;

  let left: number | undefined = alignment === 'center' ? HORIZONTAL_BUFFER / 2 : 0;
  let right: number | undefined = alignment === 'center' ? HORIZONTAL_BUFFER / 2 : 0;
  let bestAlignment = alignment;
  const initialLeftPos = boundingBox.x - HORIZONTAL_BUFFER / 2 - tourSize - 10;
  const initialRightPos = boundingBox.x + boundingBox.width + HORIZONTAL_BUFFER / 2 + 10;

  if (bestAlignment === 'left' || bestAlignment === 'top') {
    if (boundingBox.x - tourSize - HORIZONTAL_BUFFER / 2 < 0) {
      left = boundingBox.x - HORIZONTAL_BUFFER / 2;
      right = undefined;
    } else {
      left = initialLeftPos;
      right = undefined;
    }
  } else if (bestAlignment === 'right') {
    if (boundingBox.x + boundingBox.width + tourSize + HORIZONTAL_BUFFER / 2 > windowWidth) {
      left = boundingBox.x + boundingBox.width + HORIZONTAL_BUFFER / 2 - tourSize;
      right = undefined;
    } else {
      left = initialRightPos;
      right = undefined;
    }
  }

  return { left, right };
};

const getBestYPos = (alignment: TourStepAlignment, boundingBox: DOMRect, variant: TourGuideVariant) => {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const initialPos = window.innerHeight - boundingBox.bottom - VERTICAL_BUFFER / 2;
  let bottom: number | undefined = alignment === 'center' ? VERTICAL_BUFFER : 0;
  let top: number | undefined = alignment === 'center' ? boundingBox.bottom + VERTICAL_BUFFER : 0;
  const tourSize = variant === 'tour' ? TOUR_SIZE : SPOTLIGHT_TOUR_SIZE;

  if (alignment === 'center') {
    return { top, bottom: undefined };
  }

  if (alignment === 'top') {
    return { top: boundingBox.top, bottom: undefined };
  }

  // If this is going to overlap the top of the page, align it to the top instead
  if (initialPos + tourSize > windowHeight) {
    if (boundingBox.x + boundingBox.width + tourSize + HORIZONTAL_BUFFER / 2 > windowWidth) {
      bottom = undefined;
      top = boundingBox.top + boundingBox.height + VERTICAL_BUFFER / 2;
    } else {
      bottom = undefined;
      top = boundingBox.top + VERTICAL_BUFFER / 2;
    }
    // If we are going to overlap the bottom
  } else if (boundingBox.bottom + tourSize > windowHeight) {
    bottom = undefined;
    top = Math.min(boundingBox.bottom, windowHeight) - tourSize / 2 - VERTICAL_BUFFER;
  } else if (boundingBox.x + boundingBox.width + tourSize + HORIZONTAL_BUFFER / 2 > windowWidth) {
    bottom = undefined;
    top = boundingBox.top + boundingBox.height + VERTICAL_BUFFER;
  } else {
    bottom = Math.max(VERTICAL_BUFFER, initialPos);
    top = undefined;
  }

  return { bottom, top };
};

const isOffPageInAllAlignemnts = (boundingBox: DOMRect, variant: TourGuideVariant) => {
  const offTop = isOffPage(boundingBox, 'top', variant);
  const offBottom = isOffPage(boundingBox, 'bottom', variant);
  const offLeft = isOffPage(boundingBox, 'left', variant);
  const offRight = isOffPage(boundingBox, 'right', variant);

  return offTop && offBottom && offLeft && offRight;
};

const getTourPosition = (
  alignment: TourStepAlignment,
  target: HTMLElement,
  variant: TourGuideVariant,
): TourPosition => {
  const boundingBox = target.getBoundingClientRect();
  const { left, right } = getBestXPos(alignment, boundingBox, variant);
  const { top, bottom } = getBestYPos(alignment, boundingBox, variant);

  if (isOffPageInAllAlignemnts(boundingBox, variant)) {
    return { top: undefined, bottom: 50, right: 50, left: undefined };
  }

  return { top, left, right, bottom };
};

export { removeSpotlightElement, getTourPosition };

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { FormInput } from '@swyftx/aviary/molecules/FormInput/FormInput';

import { PhoneNumberInput } from '@components/molecules/Inputs/User/PhoneNumberInput';
import Recaptcha from '@components/molecules/Recaptcha';

import { CountryDropdown } from '@global-components/Dropdown';
import { StyledForm } from '@global-components/Form/Form.styled';

import { CountriesEnum } from '@shared/enums';

import { useRecaptcha } from '@hooks/useRecaptcha/useRecaptcha';
import { RegisterContext } from '@routes/Register/Register.context';
import {
  AccountType,
  accountTypeTabs,
  CreateAccountFormValues,
  FormStepProps,
  SignUpFormValues,
} from '@routes/Register/types';

import { RegisterTranslationKey } from '@Register/translations';

import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { CreateAccountSchema } from './CreateAccount.schema';
import { RegisterAlreadyHaveAccount } from '../../RegisterAlreadyHaveAccount';

const CreateAccountForm: React.FC<FormStepProps> = observer(
  ({ captchaToken, setCaptchaToken, formData, setFormData }) => {
    const { captchaRefresh, setCaptchaRefresh } = useRecaptcha();
    const [loading, setLoading] = useState<boolean>(false);

    const {
      nextRegisterStep,
      setPhoneNumberVerified,
      setEmailVerified,
      setPhoneCodeSent,
      setEmailCodeSent,
      setError,
      error,
      handleSendCode,
      phoneNumberVerified,
      phoneCodeSent,
    } = useContext(RegisterContext);
    const { t } = useTranslation(RegisterTranslationKey);
    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { errors, isValid },
      trigger,
    } = useForm<CreateAccountFormValues>({
      defaultValues: {
        countryCode: formData.countryCode || CountriesEnum.AU,
        country: (formData.country as CountriesEnum) || CountriesEnum.AU,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        mobileNumberCountry: formData.mobileNumberCountry,
        mobileNumber: formData.mobileNumber,
        applyForEntityAccount: formData.applyForEntityAccount,
      },
      resolver: zodResolver(CreateAccountSchema, undefined, { rawValues: true }),
      mode: 'onChange',
    });
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const entityApplicationsEnabled = isFeatureEnabled(AppFeature.EntityOnboarding);

    const onSubmit = async (submitted: CreateAccountFormValues) => {
      if (formData.mobileNumber !== submitted.mobileNumber) {
        setPhoneCodeSent(false);
        setPhoneNumberVerified(false);
      }
      if (formData.email !== submitted.email) {
        setEmailCodeSent(false);
        setEmailVerified(false);
      }

      setFormData((prevState) => ({
        ...prevState,
        ...submitted,
      }));

      setCaptchaRefresh(true);

      setError('');

      let valid = true;

      if (!phoneNumberVerified && !phoneCodeSent) {
        setLoading(true);
        valid = await handleSendCode(submitted as SignUpFormValues, true);
        setLoading(false);
      }

      if (valid) nextRegisterStep();
    };

    useEffect(() => {
      setValue('captchaToken', '');
      setCaptchaToken('');
    }, []);

    const isXs = useTailwindBreakpoint('xs');

    return (
      <FlexLayout direction='column' className='h-full w-full justify-between px-4 pt-24' spacing={16}>
        {entityApplicationsEnabled && (
          <Controller
            name='applyForEntityAccount'
            control={control}
            render={({ field }) => (
              <EnhancedTabs<AccountType>
                tabs={accountTypeTabs}
                variant='default'
                fullWidth
                value={field.value === true ? 'entity' : 'personal'}
                onChange={(newValue) => field.onChange(newValue === 'entity' ? true : false)}
              />
            )}
          />
        )}

        <StyledForm onSubmit={handleSubmit(onSubmit)} sx={{ height: '100%', width: '100%' }}>
          <FlexLayout direction='column' className='h-full w-full' spacing={0}>
            <Controller
              name='country'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CountryDropdown
                  id='createAccount.form.country'
                  label={t('createAccount.labels.country')}
                  required
                  value={field.value}
                  onlySuggested
                  onChange={(countryCode) => {
                    setValue('mobileNumberCountry', countryCode);
                    field.onChange(countryCode);
                  }}
                />
              )}
            />
            <FlexLayout direction={isXs ? 'column' : 'row'} spacing={isXs ? 0 : 16} className='mb-16'>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput
                    label='First name'
                    required
                    placeholder='First name'
                    type='text'
                    onChange={field.onChange}
                    value={field.value}
                    id='createAccount.form.firstName'
                    autoComplete='off'
                    className='pii-mask'
                    error={errors.firstName}
                  />
                )}
              />
              <Controller
                name='lastName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput
                    label='Last name'
                    required
                    placeholder='Last name'
                    type='text'
                    onChange={field.onChange}
                    value={field.value}
                    id='createAccount.form.lastName'
                    className='pii-mask'
                    autoComplete='off'
                    error={errors.lastName}
                  />
                )}
              />
            </FlexLayout>
            <Controller
              name='mobileNumber'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneNumberInput
                  value={field.value}
                  required
                  onChange={field.onChange}
                  defaultCountry={watch('mobileNumberCountry')}
                  onCountrySelect={(countryCode: CountriesEnum) => {
                    setValue('mobileNumberCountry', countryCode);
                    trigger('mobileNumber');
                  }}
                  error={errors.mobileNumber}
                />
              )}
            />
            <Controller
              name='email'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className='my-16'>
                  <FormInput
                    label='Email address'
                    required
                    placeholder='Email address'
                    type='text'
                    onChange={field.onChange}
                    value={field.value}
                    id='createAccount.labels.emailAddress'
                    className='pii-mask'
                    error={errors.email}
                  />
                </div>
              )}
            />
            {!captchaToken && (
              <Controller
                name='captchaToken'
                control={control}
                render={({ field }) => (
                  <div className='mt-24'>
                    <Recaptcha
                      id='signIn.form.recaptcha'
                      captchaRefresh={captchaRefresh}
                      submitCaptcha={(token: string) => {
                        field.onChange(token);
                        setCaptchaToken(token);
                      }}
                    />
                  </div>
                )}
              />
            )}

            {error && (
              <Body color='error' size='small' className='mb-12 w-full items-center text-center'>
                {error}
              </Body>
            )}
            <Button
              id='signIn.submit.button'
              size='lg'
              type='submit'
              disabled={!isValid}
              loading={loading}
              className='w-full'
            >
              {t('createAccount.buttonLabels.next')}
            </Button>
            <RegisterAlreadyHaveAccount />
          </FlexLayout>
        </StyledForm>
      </FlexLayout>
    );
  },
);

CreateAccountForm.displayName = 'CreateAccountForm';

export { CreateAccountForm };

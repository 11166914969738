import React, { useCallback, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Information } from '@swyftx/aviary/icons/outlined';

import { Asset, AssetType } from '@shared/api';

import * as Popover from '@radix-ui/react-popover';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { AssetInfoContent } from './AssetInfoContent';

interface Props {
  asset: Asset;
  className?: string;
}

export const AssetInfoPopover: React.FC<Props> = (props) => {
  const { asset, className } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { isSwyftxPro } = useSwyftxPro();

  const isXs = useTailwindBreakpoint('xs');
  const isFiatAsset = asset.assetType === AssetType.Fiat;
  const { openedAssetInfoModal } = useTradePageAnalytics();

  const handleOpen = useCallback(
    (o: boolean) => {
      if (o) {
        openedAssetInfoModal({ asset });
      }
      setOpen(o);
    },
    [asset, openedAssetInfoModal],
  );

  return (
    <Popover.Root open={open} onOpenChange={(o) => !o && handleOpen(o)} modal>
      <Popover.Trigger asChild className={className}>
        <Button
          layout='icon'
          variant={isSwyftxPro ? 'ghost' : 'outlined'}
          leadingIcon={<Information />}
          onClick={() => handleOpen(true)}
          tooltip={isFiatAsset ? 'Not available for this asset' : 'View asset info'}
          disabled={isFiatAsset}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <>
          {!isXs && (
            <Popover.Content asChild sideOffset={12} align='start'>
              <Card className='max-h-[72vh] !w-[30rem] overflow-y-auto rounded-32 shadow-xl'>
                <AssetInfoContent asset={asset} setOpen={setOpen} showLearnMore={!isSwyftxPro} />
              </Card>
            </Popover.Content>
          )}
          {isXs && (
            <Popover.Content className='!z-modal-popover' asChild>
              <Modal
                title={`${asset.code} information`}
                position='bottom'
                open={open}
                onClose={() => handleOpen(false)}
                onOpenChange={(e) => handleOpen(e?.valueOf() ?? false)}
                className='overflow-x-hidden'
              >
                <FlexLayout className='!h-full w-full' direction='column'>
                  <AssetInfoContent asset={asset} setOpen={setOpen} showLearnMore={!isSwyftxPro} />
                </FlexLayout>
              </Modal>
            </Popover.Content>
          )}
        </>
      </Popover.Portal>
    </Popover.Root>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { AppStore } from '@shared/store';

import { useUniversalTradePanel } from '@hooks/Trade/useUniversalTradePanel';

import { observer } from 'mobx-react-lite';

type Props = {
  assets: Asset[];
  onClose: () => void;
};

const MultiTradeLimitationModal: React.FC<Props> = observer(({ assets, onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'multiTradeLimitationModal' });
  const { addAssetsToBuy } = useUniversalTradePanel();
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);
  const { multiTradeLimitationRead, setMultiTradeLimitationRead } = AppStore.useAppStore;

  useEffect(() => {
    if (multiTradeLimitationRead !== undefined) {
      setDoNotShowAgain(multiTradeLimitationRead);
    }
  }, [multiTradeLimitationRead]);

  const handleContinue = () => {
    addAssetsToBuy(assets, true);
    setMultiTradeLimitationRead(doNotShowAgain);
    onClose();
  };

  const handleDismiss = () => {
    onClose();
  };

  return (
    <Modal open id='restricted-modal' onClose={onClose} title={t('title')} className='w-[600px]'>
      <FlexLayout spacing={16} direction='column' className='px-24 pb-24'>
        <Body>{t('description')}</Body>
        <FlexLayout spacing={8}>
          <Checkbox
            title={t('buttons.doNotShowAgain')}
            checked={doNotShowAgain}
            onCheckedChange={(checked) => setDoNotShowAgain(checked === true)}
          />
          <Body weight='emphasis' className='cursor-pointer' onClick={() => setDoNotShowAgain((prev) => !prev)}>
            {t('buttons.doNotShowAgain')}
          </Body>
        </FlexLayout>

        <FlexLayout direction='row' alignItems='center' justifyContent='end' spacing={8}>
          <Button variant='ghost' onClick={handleDismiss}>
            {t('buttons.cancel')}
          </Button>
          <Button variant='filled' onClick={handleContinue}>
            {t('buttons.continue')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { MultiTradeLimitationModal };

import logger from '@shared/logger';
import { AppStore } from '@shared/store';

import { assetService } from './assetService';
import { balanceService } from './balanceService';
import { portfolioService } from './portfolioService';
import { ratesService } from './ratesService';
import { userStatisticsService } from './userStatisticsService';

export * from './assetService';
export * from './balanceService';
export * from './ratesService';
export * from './withdrawalService';
export * from './walletService';
export * from './tradeService';
export * from './chartService';
export * from './portfolioService';
export * from './userStatisticsService';
export * from './walletAddressService';

export const initServices = async (logTag: string) => {
  const { setServicesInit } = AppStore.useAppStore;
  setServicesInit(false);

  await Promise.all([
    assetService.initAssets().catch((error) => logger.error(logTag, 'Unable to init asset service', error)),
    ratesService.init().catch((error) => logger.error(logTag, 'Unable to init rates service', error)),
    portfolioService.init().catch((error) => logger.error(logTag, 'Unable to init portfolio service', error)),
    balanceService.init().catch((error) => logger.error(logTag, 'Unable to init balance service', error)),
    userStatisticsService
      .init()
      .catch((error) => logger.error(logTag, 'Unable to init user statistics service', error)),
  ]);

  setServicesInit(true);

  return Promise.resolve();
};

export const forceUpdateServices = async () =>
  Promise.all([
    ratesService.forceUpdate(),
    portfolioService.forceUpdate(),
    balanceService.forceUpdate(),
    userStatisticsService.forceUpdate(),
  ]);

export const cleanupServices = async () =>
  Promise.all([
    ratesService.cleanup(true),
    portfolioService.cleanup(),
    balanceService.cleanup(),
    userStatisticsService.cleanup(),
  ]);

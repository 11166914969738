import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const MenuCollapse: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M4.64258 15C4.64258 14.5858 4.97836 14.25 5.39258 14.25H7.39258C7.80679 14.25 8.14258 14.5858 8.14258 15C8.14258 15.4142 7.80679 15.75 7.39258 15.75H5.39258C4.97836 15.75 4.64258 15.4142 4.64258 15Z'
      fill='currentColor'
    />
    <path
      d='M5.39258 11.25C4.97836 11.25 4.64258 11.5858 4.64258 12C4.64258 12.4142 4.97836 12.75 5.39258 12.75H7.39258C7.80679 12.75 8.14258 12.4142 8.14258 12C8.14258 11.5858 7.80679 11.25 7.39258 11.25H5.39258Z'
      fill='currentColor'
    />
    <path
      d='M4.64258 9C4.64258 8.58579 4.97836 8.25 5.39258 8.25H7.39258C7.80679 8.25 8.14258 8.58579 8.14258 9C8.14258 9.41421 7.80679 9.75 7.39258 9.75H5.39258C4.97836 9.75 4.64258 9.41421 4.64258 9Z'
      fill='currentColor'
    />
    <path
      d='M17.0303 13.4697C17.3232 13.7626 17.3232 14.2374 17.0303 14.5303C16.7374 14.8232 16.2626 14.8232 15.9697 14.5303L14.6768 13.2374C13.9934 12.554 13.9934 11.446 14.6768 10.7626L15.9697 9.46967C16.2626 9.17678 16.7374 9.17678 17.0303 9.46967C17.3232 9.76256 17.3232 10.2374 17.0303 10.5303L15.7374 11.8232C15.6398 11.9209 15.6398 12.0791 15.7374 12.1768L17.0303 13.4697Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.665 3C5.99484 3 4.5693 3.52998 3.55611 4.49946C2.54095 5.47083 2 6.82651 2 8.35751V15.6425C2 17.1723 2.53768 18.5282 3.55196 19.5002C4.56438 20.4703 5.99037 21 7.665 21H16.333C18.0076 21 19.434 20.4703 20.4469 19.5003C21.4617 18.5285 22 17.1726 22 15.6425V8.35751C22 6.82742 21.4617 5.47157 20.447 4.49972C19.4343 3.5297 18.0082 3 16.334 3H7.665ZM3.5 8.35751C3.5 7.18876 3.90605 6.24069 4.59314 5.58324C5.2822 4.9239 6.31416 4.5 7.665 4.5H9.25L9.25 19.5H7.665C6.30863 19.5 5.27712 19.0758 4.58979 18.4171C3.90432 17.7603 3.5 16.8124 3.5 15.6425V8.35751ZM10.75 19.5H16.333C17.6894 19.5 18.7215 19.0758 19.4094 18.417C20.0953 17.76 20.5 16.8121 20.5 15.6425V8.35751C20.5 7.18785 20.0953 6.23994 19.4095 5.58297C18.7217 4.92418 17.6898 4.5 16.334 4.5H10.75L10.75 19.5Z'
      fill='currentColor'
    />
  </svg>
);

export { MenuCollapse };

import React, { useMemo } from 'react';

import Box from '@mui/material/Box';

import { Stack } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { AssetPriceText, FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  amount: Big;
  totalValue: Big;
  asset: Asset;
};

const TotalRewardsBreakdownItem: React.FC<Props> = observer(({ amount, totalValue, asset }) => {
  const { isLightTheme, getContrastAssetColor } = useTheme();
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const { navigate } = useNavigateRoute();

  const percentage = useMemo(() => {
    if (!baseAsset) return 0;

    const assetRate = getRate(asset).midPrice;
    const baseAssetRate = getRate(baseAsset).midPrice;

    if (!assetRate || !baseAssetRate) return 0;

    const valueInBase = Big(assetRate).times(amount);
    const totalValueInBase = Big(baseAssetRate).times(totalValue);

    return valueInBase.div(totalValueInBase).times(100).toNumber();
  }, [totalValue, getRate, asset, baseAsset, amount]);

  const openWallet = () => {
    navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset.code } });
  };

  return (
    <Stack
      direction='column'
      spacing={1}
      paddingY={1}
      paddingX={2}
      onClick={openWallet}
      sx={{
        '&:hover': {
          backgroundColor: isLightTheme ? 'grey.50' : 'grey.900',
          cursor: 'pointer',
        },
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <AssetIcon asset={asset} size={20} />
        <Stack direction='row' width='100%' alignItems='center' justifyContent='space-between'>
          <FormattedText typographyProps={{ size: 'large' }} value={amount} currency={asset} />
          <Stack direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
            <AssetPriceText
              asset={asset}
              balance={amount}
              liveChanges
              typographyProps={{ weight: 'emphasis', color: 'primary' }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Box
        sx={{
          borderRadius: 1,
          width: '100%',
          height: '6px',
          position: 'relative',
          backgroundColor: isLightTheme ? 'grey.200' : 'grey.700',
          overflow: 'hidden',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: asset ? getContrastAssetColor(asset.code) : 'primary.main',
            width: `${percentage}%`,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '6px',
            zIndex: 2,
          }}
        />
      </Box>
    </Stack>
  );
});

export { TotalRewardsBreakdownItem };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TradeFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M20.7221 7.54618C20.6021 7.71236 20.3266 7.98037 20.3266 7.98037C19.3514 9.02079 16.9261 10.5879 15.6956 11.0911C15.6956 11.1016 14.9639 11.4001 14.6159 11.4118H14.5696C14.0363 11.4118 13.5374 11.1133 13.2821 10.6335C13.1768 10.4357 13.0789 9.95456 13.0317 9.72244C13.016 9.64556 13.0059 9.59599 13.003 9.59308C12.8984 8.90727 12.8296 7.85514 12.8296 6.70003C12.8296 5.48757 12.8984 4.38981 13.0267 3.71453C13.0267 3.70282 13.1538 3.08606 13.2358 2.88009C13.3628 2.58282 13.5956 2.3312 13.8855 2.17087C14.1171 2.05618 14.3617 2 14.6159 2C14.8832 2.01053 15.382 2.18257 15.5792 2.26215C16.8797 2.76539 19.3633 4.41204 20.3147 5.41852C20.4208 5.52307 20.5319 5.64206 20.6093 5.72485C20.6506 5.76902 20.6822 5.8029 20.6983 5.81878C20.8955 6.0704 21 6.37936 21 6.71173C21 7.00783 20.9074 7.30509 20.7221 7.54618Z'
      fill='currentColor'
    />
    <path
      d='M13.8382 18.2286C13.3144 18.2286 12.8904 17.8108 12.8904 17.2947C12.8904 16.7786 13.3144 16.3608 13.8382 16.3608L18.417 16.1033C19.0846 16.1033 19.625 16.637 19.625 17.2947C19.625 17.9524 19.0846 18.4849 18.417 18.4849L13.8382 18.2286Z'
      fill='currentColor'
    />
    <path
      d='M10.7644 21.1199C10.6361 21.4172 10.4045 21.6688 10.1147 21.8291C9.88309 21.9438 9.63841 22 9.38304 22C9.11698 21.9895 8.61693 21.8174 8.41976 21.7367C7.12035 21.2346 4.63674 19.5868 3.68415 18.5815C3.58056 18.4787 3.4716 18.3619 3.39454 18.2793C3.35147 18.2331 3.3183 18.1976 3.30169 18.1812C3.10452 17.9284 3 17.6206 3 17.2894C3 16.991 3.09265 16.6937 3.27913 16.4538C3.3979 16.2876 3.67346 16.0196 3.67346 16.0196C4.64743 14.978 7.07403 13.4121 8.30455 12.9089C8.30455 12.8972 9.03502 12.5999 9.38304 12.5882H9.42936C9.96385 12.5882 10.4627 12.8855 10.7181 13.3665C10.8223 13.5639 10.9204 14.0435 10.9681 14.2762C10.984 14.3538 10.9942 14.404 10.9972 14.4069C11.1017 15.0927 11.1706 16.1449 11.1706 17.3C11.1706 18.5124 11.1017 19.6102 10.9735 20.2855C10.9735 20.296 10.8464 20.9139 10.7644 21.1199Z'
      fill='currentColor'
    />
    <path
      d='M11.1095 6.70553C11.1095 6.18942 10.6855 5.77161 10.1617 5.77161L5.58293 5.51414C4.91542 5.51414 4.375 6.04781 4.375 6.70553C4.375 7.36325 4.91542 7.89575 5.58293 7.89575L10.1617 7.63945C10.6855 7.63945 11.1095 7.22165 11.1095 6.70553Z'
      fill='currentColor'
    />
  </svg>
);

export { TradeFilled };

import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown, ArrowChevronUp, Delete, Edit } from '@swyftx/aviary/icons/outlined';

import { Individual } from '@shared/api';

import { useEntityOnboardingSelector } from '@routes/EntityOnboarding/EntityOnboarding.context';
import { RemoveMemberModal } from '@routes/EntityOnboarding/components/modals/RemoveMemberModal';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';
import { useStepId } from '@routes/EntityOnboarding/hooks/useStepId';
import { IndividualWithUuid, MemberType } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';

import { observer } from 'mobx-react-lite';

import { AddIndividual } from './AddIndividual';
import { ViewIndividualDetails } from './ViewIndividualDetails';

interface Props {
  member: IndividualWithUuid;
  onRemove: () => void;
  memberType: MemberType;
  isCorporateTrustVariant?: boolean;
  shareholders?: Individual[];
  beneficiaries?: Individual[];
  onEditMember: (member: IndividualWithUuid, isShareholder?: boolean, isBeneficiary?: boolean) => void;
}

export const IndividualCard: React.FC<Props> = observer((props) => {
  const {
    member: memberWithUuid,
    onRemove,
    memberType,
    shareholders,
    beneficiaries,
    isCorporateTrustVariant,
    onEditMember,
  } = props;
  const member = memberWithUuid.individual;
  const [open, setOpen] = useState<boolean>(false);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const { entityMemberRemoved } = useEntityOnboardingAnalytics();
  const { stepId } = useStepId();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);
  const { type } = applicationData;

  const editSubmitProps = useMemo(() => {
    if (onEditMember.length === 2) {
      return {
        onCompanySubmit: (editedMember: Individual, isShareholder: boolean) => {
          setEdit(false);
          return onEditMember({ uuid: memberWithUuid.uuid, individual: editedMember }, isShareholder);
        },
      };
    }
    if (onEditMember.length === 1) {
      return {
        onSmsfSubmit: (editedMember: Individual) => {
          setEdit(false);
          return onEditMember({ uuid: memberWithUuid.uuid, individual: editedMember }, false) as unknown as (
            member: IndividualWithUuid,
            isShareholder: boolean,
          ) => void;
        },
      };
    }
    if (onEditMember.length === 3) {
      return {
        onCorporateTrustSubmit: (editedMember: Individual, isShareholder: boolean, isBeneficiary: boolean) => {
          setEdit(false);
          return onEditMember(
            { uuid: memberWithUuid.uuid, individual: editedMember },
            isShareholder,
            isBeneficiary,
          ) as unknown as (member: IndividualWithUuid, isShareholder: boolean, isBeneficiary: boolean) => void;
        },
      };
    }
  }, [memberWithUuid.uuid, onEditMember]);

  const onConfirmRemove = useCallback(() => {
    if (type) {
      entityMemberRemoved({ stepId, memberType, entityType: type });
    }
    onRemove();
  }, [entityMemberRemoved, memberType, onRemove, stepId, type]);

  const handleOpenToggle = useCallback(() => {
    if (open) {
      return setOpen(false);
    }
    setOpen(true);
    setEdit(false);
  }, [open]);

  const handleOpenEdit = useCallback(() => {
    setEdit(true);
    setOpen(false);
  }, []);

  const isAlsoShareholder = useMemo(
    () => shareholders?.some((shareholder) => shareholder.email === member.email),
    [member.email, shareholders],
  );
  const isAlsoBeneficiary = useMemo(
    () => beneficiaries?.some((beneficiary) => beneficiary.email === member.email),
    [beneficiaries, member.email],
  );

  return (
    <>
      <Card className='p-16'>
        <FlexLayout direction='column' spacing={16}>
          <FlexLayout direction='row' className='w-full items-center justify-between'>
            <FlexLayout direction='column'>
              <Body weight='emphasis' className='truncate'>
                {member.firstName} {member.lastName}
              </Body>
              <Body color='secondary'>{member.email}</Body>
            </FlexLayout>
            <FlexLayout direction='row' spacing={8}>
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={<Edit />}
                onClick={handleOpenEdit}
                tooltip='Edit member details'
                disabled={edit}
              />
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={<Delete />}
                onClick={() => setConfirmRemoveOpen(true)}
                tooltip='Remove member'
              />
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={open ? <ArrowChevronUp /> : <ArrowChevronDown />}
                onClick={handleOpenToggle}
                tooltip='View member details'
                disabled={edit}
              />
            </FlexLayout>
          </FlexLayout>
          {open && (
            <ViewIndividualDetails
              member={member}
              memberType={memberType}
              isCorporateTrustVariant={isCorporateTrustVariant}
              isAlsoBeneficiary={isAlsoBeneficiary}
              isAlsoShareholder={isAlsoShareholder}
            />
          )}
          {edit && (
            <AddIndividual
              onCancel={() => setEdit(false)}
              {...editSubmitProps}
              memberType={memberType}
              member={member}
              isAlsoBeneficiary={isAlsoBeneficiary}
              isAlsoShareholder={isAlsoShareholder}
            />
          )}
        </FlexLayout>
      </Card>
      <RemoveMemberModal
        name={member.firstName}
        open={confirmRemoveOpen}
        setOpen={setConfirmRemoveOpen}
        onConfirm={onConfirmRemove}
      />
    </>
  );
});

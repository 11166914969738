import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Expandable, Heading } from '@swyftx/aviary/atoms/Typography';

type Props = {
  id: string;
  title: string;
  content: string;
};

const ShopInformationItem: React.FC<Props> = ({ id, title, content }) => (
  <Card className='w-full p-24' id={id}>
    <FlexLayout direction='column' spacing={16}>
      <Heading size='h5'>{title}</Heading>
      <Separator />
      <Expandable size='small'>{content}</Expandable>
    </FlexLayout>
  </Card>
);

export { ShopInformationItem };

import { useCallback, useEffect, useState } from 'react';

import { LineChartData, LineChartDatum } from '@global-components/charts/LineChart/LineChart.types';

import { api } from '@shared/api';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { addMissingPoints } from './costBasisHelpers';

export const useAssetCostBasis = (assetId: string, formattedData?: LineChartData) => {
  const [data, setData] = useState<LineChartData>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const calculateFilteredData = useCallback(() => {
    if (!data || !data.length) return;

    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset());

    const chartStartDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));

    chartStartDate.setUTCHours(9, 59);
    chartStartDate.setMinutes(chartStartDate.getMinutes() + chartStartDate.getTimezoneOffset());

    const startingPoint: LineChartDatum[] = [{ value: data[data.length - 1].value, time: chartStartDate }];
    const endingPoint: LineChartDatum[] = [{ value: data[0].value, time: currentDate }];
    const dataWithPointsAddedAtStartAndEnd = [...endingPoint, ...data, ...startingPoint];

    const dataWithPointsEveryDay = addMissingPoints(dataWithPointsAddedAtStartAndEnd, 9, 59, formattedData);

    return dataWithPointsEveryDay;
  }, [data, formattedData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (isFeatureEnabled(AppFeature.CostBasis)) {
        try {
          const { data: res } = await api.endpoints.getAssetCostBasis({ params: { assetId } });
          const costBasis = res.map((datum) => {
            const time = new Date(datum.time);
            time.setHours(9, 59);
            return {
              time,
              value: datum.value,
            };
          });
          setData(costBasis);
        } catch (e) {
          setError(true);
        }
      }
      setLoading(false);
    })();
  }, [assetId, isFeatureEnabled]);

  return {
    data: calculateFilteredData(),
    loading,
    error,
  };
};

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { AccountStatusEnum } from '@shared/enums';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedLevel: AccountStatusEnum;
  setSelectedLevel: (selectedLevel: AccountStatusEnum) => void;
  isNZ: boolean;
};

const VerificationLevelTabs: React.FC<Props> = observer(({ selectedLevel, setSelectedLevel, isNZ }) => {
  const levels = useMemo(
    () => [
      {
        title: 'Bronze',
        value: AccountStatusEnum.BRONZE.toString(),
      },
      {
        title: 'Silver',
        value: AccountStatusEnum.SILVER.toString(),
      },
      {
        title: 'Gold',
        value: isNZ ? AccountStatusEnum.GOLD_NZ.toString() : AccountStatusEnum.GOLD.toString(),
      },
      {
        title: 'Diamond',
        value: AccountStatusEnum.DIAMOND.toString(),
      },
    ],
    [isNZ],
  );

  return (
    <FlexLayout direction='row'>
      <EnhancedTabs
        tabs={levels}
        onChange={(val) => setSelectedLevel(Number(val) as AccountStatusEnum)}
        value={selectedLevel.toString()}
        variant='default'
        fullWidth
      />
    </FlexLayout>
  );
});

export { VerificationLevelTabs };

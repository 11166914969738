import { useEffect, useState } from 'react';

import { api, HistoricalOrder } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

const useFetchOrder = (orderUuid: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<HistoricalOrder>();
  const { isSwyftxPro } = useSwyftxPro();

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const response = await api.endpoints.getOrderById({
          params: { orderUuid },
        });
        const historicalOrder: HistoricalOrder = {
          ...response.data,
          amount: response.data.amount ? Big(response.data.amount) : null,
          audValue: response.data.audValue ? Big(response.data.audValue) : null,
          feeAmount: response.data.feeAmount ? Big(response.data.feeAmount) : null,
          feeAudValue: response.data.feeAudValue ? Big(response.data.feeAudValue) : null,
          feeUserCountryValue: response.data.feeUserCountryValue ? Big(response.data.feeUserCountryValue) : null,
          quantity: response.data.quantity ? Big(response.data.quantity) : response.data.quantity,
          rate: response.data.rate ? Big(response.data.rate) : null,
          total: response.data.total ? Big(response.data.total) : null,
          trigger: response.data.trigger ? Big(response.data.trigger) : null,
          userCountryValue: response.data.userCountryValue ? Big(response.data.userCountryValue) : null,
        };
        setOrder(historicalOrder);
      } catch (e) {
        setOrder(undefined);
      } finally {
        setLoading(false);
      }
    };

    if (orderUuid) fetchOrder();
  }, [isSwyftxPro, orderUuid]);

  return {
    order,
    loading,
  };
};

export { useFetchOrder };

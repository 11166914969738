import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { Copy } from '@swyftx/aviary/icons/outlined';
import { Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';

import { useDeposit } from '@hooks/Deposit/useDeposit';
import { useCopy } from '@hooks/useCopy';

const PayID: React.FC<{ asset: Asset }> = ({ asset }) => {
  const { t } = useTranslation('onboarding');
  const { onCopy } = useCopy();

  const { bankAddressDetails } = useDeposit({
    asset,
    transferType: DepositMethodEnum.MONOOVA_PAYID,
  });

  return (
    <div className='py-12'>
      <Typography color='text.secondary' marginBottom={3}>
        {t('steps.depositFunds.option.payID.description')}
      </Typography>
      <Card>
        <CardContent className='grid gap-4 py-4'>
          <ul className='divide-y divide-gray-200'>
            <li className='flex items-center justify-between py-16'>
              <div className='flex-grow'>
                <span className='font-normal'>Biller name</span>
              </div>
              <div className='mr-24 text-right text-blue-600'>
                <span>Swyftx</span>
              </div>
              <div>
                <Button
                  variant='filled'
                  color='subtle'
                  size='sm'
                  leadingIcon={<Copy />}
                  onCopy={() => onCopy('Swyftx', 'Biller name')}
                >
                  {t('labels.copy')}
                </Button>
              </div>
            </li>
            <li className='flex items-center justify-between py-16'>
              <div className='flex-grow'>
                <span className='font-normal'>PayID</span>
              </div>
              <div className='mr-24 text-right text-blue-600'>
                <span>{bankAddressDetails?.payid ?? ''}</span>
              </div>
              <div>
                <Button
                  variant='filled'
                  color='subtle'
                  size='sm'
                  leadingIcon={<Copy />}
                  onCopy={() => onCopy(bankAddressDetails?.payid ?? '', 'PayID')}
                >
                  {t('labels.copy')}
                </Button>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>
      <br />
      <hr style={{ borderTop: '2px dashed lightGray' }} />
      <ul className='py-16 text-gray-500'>
        <li className='flex items-center justify-between py-6'>
          <div className='flex-grow'>
            <span className='font-normal'>{t('steps.depositFunds.labels.processingTime')}</span>
          </div>
          <div className='text-right'>
            <span>{t('steps.depositFunds.labels.nearInstant')}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-6'>
          <div className='flex-grow'>
            <span className='font-normal'>{t('steps.depositFunds.labels.depositFee')}</span>
          </div>
          <div className='text-right'>
            <span>{t('steps.depositFunds.labels.free')}</span>
          </div>
        </li>
      </ul>
      <Alert severity='warning' sx={{ mb: 3 }}>
        <AlertTitle>You may experience delays from your bank</AlertTitle>
        Deposits may be subject to a temporary, 24hr hold for the purpose of conducting additional compliance checks by
        your banking institution.
      </Alert>
    </div>
  );
};

export { PayID };

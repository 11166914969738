import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';

import { AssetPrice } from './AssetPrice';
import { DailyChange, DailyVolume } from './StatItems';

interface Props {
  baseAsset: Asset;
  selectedAsset: Asset;
  loading: boolean;
  dailyHigh?: string | null;
  dailyLow?: string | null;
  dailyPriceChange: string;
  priceSide: 'bid' | 'ask';
}

export const XsAssetStats: React.FC<Props> = (props) => {
  const { baseAsset, selectedAsset, loading, dailyHigh, dailyLow, dailyPriceChange, priceSide } = props;

  return (
    <FlexLayout direction='row' className='w-full'>
      <FlexLayout direction='row' className='w-full'>
        <AssetPrice
          side={priceSide}
          selectedAsset={selectedAsset}
          baseAsset={baseAsset}
          dailyPriceChange={dailyPriceChange}
        />
      </FlexLayout>
      <FlexLayout direction='column' spacing={8} className='w-full'>
        <FlexLayout direction='row' spacing={8} className='w-full'>
          <DailyChange change={dailyHigh} baseAsset={baseAsset} loading={loading} direction='high' className='w-1/2' />
          <DailyChange change={dailyLow} baseAsset={baseAsset} loading={loading} direction='low' className='w-1/2' />
        </FlexLayout>
        <FlexLayout direction='row' spacing={8}>
          <DailyVolume
            volume={selectedAsset.volume[baseAsset?.id || FiatIdEnum.AUD].day}
            loading={loading}
            asset={baseAsset}
            className='w-1/2'
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Information } from '@swyftx/aviary/icons/outlined';

import { api } from '@shared/api';
import { ExchangesEnum } from '@shared/enums';
import { UIStore } from '@shared/store';

import { PortfolioTransferContext } from '@routes/PortfolioTransfer/PortfolioTransfer.context';
import { AssetMigratorFeesModal } from '@routes/PortfolioTransfer/components/AssetMigratorFeesModal';

import { lookupTranslationKey } from '../../apiHandler';

interface Props {
  onNext: () => void;
}

const feeFreeExchanges: ExchangesEnum[] = [];

const AddAPIKeyStep: React.FC<Props> = ({ onNext }) => {
  const { exchange, apiKey, apiSecret, setExchange, setApiKey, setApiSecret, setBalances } =
    useContext(PortfolioTransferContext);
  const { addToastMessage } = UIStore.useUIStore;
  const { t } = useTranslation('onboarding');

  const [feesDialogOpen, setFeesDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errored, setErrored] = useState(false);
  const [exchangeStatus, setExchangeStatus] = useState<Partial<{ [key in ExchangesEnum]: number }> | undefined>(
    undefined,
  );
  const [confirmTerms, setConfirmTerms] = useState(false);

  const continueDisabled = !apiKey || !apiSecret || !exchange || !confirmTerms || isSubmitting;

  const feesText = useMemo(() => {
    if (exchange === undefined) {
      return '-';
    }
    if (exchange && exchangeStatus?.[exchange]) {
      return 'Fees apply';
    }
    if (exchange && feeFreeExchanges.includes(exchange)) {
      return 'Fee free!';
    }
    return 'Reimbursed upon deposit';
  }, [exchange, exchangeStatus]);

  useEffect(() => {
    (async () => {
      const status = await api.endpoints.status();
      setExchangeStatus(status.data);
    })();
  }, []);

  const handleApiKeyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrored(false);
      setApiKey(event.target.value);
    },
    [setApiKey],
  );

  const handleApiSecretChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrored(false);
      setApiSecret(event.target.value);
    },
    [setApiSecret],
  );

  const handleContinue = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const balances = await api.endpoints.balances({
        data: {
          exchange: exchange as string,
          apiKey,
          apiSecret,
        },
      });
      setIsSubmitting(false);
      setBalances(balances.data);
      onNext();
    } catch (e: any) {
      setIsSubmitting(false);
      setErrored(true);
      const lookupKey =
        lookupTranslationKey(exchange!, e) ?? 'steps.assetMigrator.confirmationModal.error.binance.invalidKeys';
      addToastMessage({
        message: t(lookupKey as unknown as TemplateStringsArray),
        severity: 'error',
      });
    }
  }, [addToastMessage, apiKey, apiSecret, exchange, onNext, setBalances, t]);

  return (
    <>
      <FlexLayout direction='column' spacing={32}>
        <FlexLayout direction='column' spacing={8}>
          <Heading size='h3'>Add API Key</Heading>
          <Body size='small' color='secondary'>
            Select the exchange and add your API Key and Secret Key below.
          </Body>
        </FlexLayout>
        <FlexLayout direction='column' spacing={32}>
          <FormControl>
            <FlexLayout direction='column' spacing={0}>
              <FormLabel
                id='exchangeSelector'
                required
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: 'text.primary',
                  marginBottom: 1,
                  '.MuiFormLabel-asterisk': {
                    color: 'error.main',
                  },
                  '&.Mui-focused': {
                    color: 'text.primary',
                  },
                }}
              >
                Select Exchange
              </FormLabel>
              <RadioGroup
                aria-labelledby='exchange selector'
                name='exchangeSelector'
                value={exchange}
                onChange={(e) => setExchange(e.target.value as ExchangesEnum)}
              >
                <FlexLayout direction='column' spacing={8}>
                  <FormControlLabel
                    value='Binance'
                    control={<Radio />}
                    label={
                      <FlexLayout direction='column'>
                        <Body>Binance</Body>
                        <Body color='secondary' size='small'>
                          www.binance.com
                        </Body>
                      </FlexLayout>
                    }
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='Coinspot'
                    control={<Radio />}
                    label={
                      <FlexLayout direction='column'>
                        <Body>CoinSpot</Body>
                        <Body color='secondary' size='small'>
                          www.coinspot.com.au
                        </Body>
                      </FlexLayout>
                    }
                    labelPlacement='end'
                  />
                </FlexLayout>
              </RadioGroup>
            </FlexLayout>
          </FormControl>
          <FlexLayout direction='column' spacing={16}>
            <FlexLayout direction='column' spacing={2}>
              <FlexLayout direction='row' spacing={4}>
                <Body weight='emphasis'>Enter API Key:</Body>
                <Body color='error'>*</Body>
              </FlexLayout>
              <Input error={errored} onChange={handleApiKeyChange} value={apiKey} required />
            </FlexLayout>
            <FlexLayout direction='column' spacing={2}>
              <FlexLayout direction='row' spacing={4}>
                <Body weight='emphasis'>Enter Secret Key:</Body>
                <Body color='error'>*</Body>
              </FlexLayout>
              <Input error={errored} onChange={handleApiSecretChange} value={apiSecret} required />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='row' className='w-full justify-between'>
            <Button
              color='primary'
              onClick={() => setFeesDialogOpen(true)}
              leadingIcon={<Information className='text-color-text-primary' />}
              variant='outlined'
              size='sm'
            >
              Fees
            </Button>
            <Body color='secondary' size='small'>
              {feesText}
            </Body>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='row' className='flex space-x-12'>
          <Checkbox
            id='assetMigratorTerms'
            className='mt-4'
            onCheckedChange={(checked) => {
              if (checked === 'indeterminate') {
                setConfirmTerms(false);
              } else {
                setConfirmTerms(checked);
              }
            }}
            checked={confirmTerms}
          />
          <Label htmlFor='assetMigratorTerms' className='leading-16'>
            I understand Swyftx will have access to view and withdraw balances from this exchange, and grant consent to
            do so as part of this process. Swyftx will only withdraw balances at your request.
          </Label>
        </FlexLayout>
        <Button onClick={handleContinue} size='lg' disabled={continueDisabled}>
          Continue
        </Button>
      </FlexLayout>
      <AssetMigratorFeesModal open={feesDialogOpen} handleClose={() => setFeesDialogOpen(false)} />
    </>
  );
};

export { AddAPIKeyStep };

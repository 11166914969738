import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { Asset } from '@shared/api';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

type Props = {
  asset?: Asset;
  onClose?: () => void;
};

const useCreatePriceAlertModal = ({ asset, children, onClose }: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState<boolean>(!children);
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>(asset ? [asset] : []);
  const [tempSelectedAssets, setTempSelectedAssets] = useState<Asset[]>([]);

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const volatilityAlertsEnabled = useMemo(
    () => isFeatureEnabled(AppFeature.DynamicPriceAlertsRelease2),
    [isFeatureEnabled],
  );

  const type = useMemo(() => (volatilityAlertsEnabled ? 'multi' : 'priceAlert'), [volatilityAlertsEnabled]);

  useEffect(() => {
    if (asset) {
      setSelectedAssets([asset]);
    } else {
      setSelectedAssets([]);
    }
  }, [asset]);

  const handleOpenChange = useCallback(
    (o?: boolean) => {
      setOpen(!!o);
      if (!o) setSelectedAssets([]);

      if (onClose && !o) {
        onClose();
      }
    },
    [onClose],
  );

  const handleOnChangeAssets = useCallback(() => {
    setTempSelectedAssets(selectedAssets);
    setSelectedAssets([]);
  }, [selectedAssets]);

  const handleApplySelection = useCallback(() => {
    setSelectedAssets(tempSelectedAssets);
    setTempSelectedAssets([]);
  }, [tempSelectedAssets]);

  return {
    open,
    type,
    volatilityAlertsEnabled,
    selectedAssets,
    tempSelectedAssets,
    handleOnChangeAssets,
    handleOpenChange,
    setSelectedAssets,
    setTempSelectedAssets,
    handleApplySelection,
  };
};

export { useCreatePriceAlertModal };

import React from 'react';

import { Box, useTheme } from '@mui/material';
import { lighten } from '@mui/material/styles';

import { Icon } from '@swyftx/react-web-design-system';

type Props = {
  id: string;
  children?: JSX.Element;
  color?: string;
  height?: number;
  aspectRatio?: number;
};

const DEFAULTS = {
  height: 25,
  aspectRatio: 3 / 2,
};

export const WalletIcon: React.FC<Props> = ({
  id,
  color,
  children,
  height = DEFAULTS.height,
  aspectRatio = DEFAULTS.aspectRatio,
}) => {
  const width = height * aspectRatio;
  const theme = useTheme();

  const boxProps = {
    borderRadius: `${height / 4}px`,
    height,
    width,
  };

  const iconSize = height * 0.9;
  const fallbackBg = theme.palette.text.primary;
  const bgColor = color !== 'undefined' && color !== undefined ? color : fallbackBg;

  return (
    <Box position='relative' width={width} height={height} marginTop={`${height / 8}px`}>
      {/* Background wallet */}
      <Box
        {...boxProps}
        sx={{ transform: 'rotate(-10deg)' }}
        bgcolor={lighten(bgColor, 0.5)}
        width={width - width / 10}
        position='absolute'
        height={height / 2}
        top={-height / 8}
        zIndex={2}
        left={0}
      />
      {/* Cover */}
      <Box
        bgcolor={bgColor}
        overflow='hidden'
        justifyContent='center'
        alignItems='center'
        display='flex'
        {...boxProps}
        zIndex={4}
        position='absolute'
      >
        <Icon
          id={id}
          icon={children}
          sx={{
            height: iconSize,
            // width: iconSize,
            '& > svg': {
              height: iconSize,
              // width: iconSize,
            },
          }}
        />
      </Box>
    </Box>
  );
};

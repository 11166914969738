import { Settings, Verified } from '@swyftx/aviary/icons/outlined';

import { NavigationURLs } from 'src/lib/navigation/types';

export const MenuContent = [
  {
    icon: <Verified width={24} height={24} />,
    text: 'profileHeaderMenu.verification',
    navigatePath: NavigationURLs.ProfileVerification,
  },
  {
    icon: <Settings width={24} height={24} />,
    text: 'profileHeaderMenu.accountSettings',
    navigatePath: NavigationURLs.ProfileSettings,
  },
];

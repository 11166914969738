import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { AssetReceiveButton } from '@global-components/Assets';
import { BuySellButtons } from '@global-components/BuySellButtons';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetType } from '@shared/api';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  hasBalance: boolean;
};

const LOG_TAG = 'ASSET_WALLET_ACTIONS';

export const AssetWalletActions: React.FC<Props> = observer(({ asset, hasBalance }) => {
  const { canTransferCrypto } = UserStore.useUserStore;
  const { t } = useTranslation('assets', { keyPrefix: 'singleAsset.assetWallet.buttonLabels' });
  const isFiat = asset.assetType === AssetType.Fiat;
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const avo = useAvo();

  const handleReceive = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive, { selectedAsset: asset });
  };

  const handleSend = () => {
    openModal(Modals.WithdrawSend, { selectedAsset: asset });
  };

  if (isFiat) {
    return (
      <FlexLayout className='flex-col @md:flex-row' spacing={16}>
        {hasBalance && (
          <Button variant='outlined' onClick={handleSend} className='w-full'>
            {t('withdraw')}
          </Button>
        )}
        <Button variant='outlined' onClick={handleReceive} className='w-full'>
          {t('deposit')}
        </Button>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout className='flex-col @md:flex-row' spacing={16}>
      {canTransferCrypto() && (
        <>
          <AssetReceiveButton onReceive={handleReceive} asset={asset} hideIfDepositDisabled hideCode />
          {hasBalance && (
            <Button variant='outlined' onClick={handleSend} className='w-full'>
              {t('withdraw')}
            </Button>
          )}
        </>
      )}
      <BuySellButtons openTradePanel asset={asset} hideSell />
    </FlexLayout>
  );
});

import { useMemo } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { AssetRowSort } from './AssetRowHeader';

type Props = {
  data: Asset[];
  sort: AssetRowSort;
  reverseCheck?: boolean;
  maxAssets?: number;
};

const useSortAssetRow = ({ data, sort, reverseCheck, maxAssets }: Props) => {
  const { getRate } = RatesStore.useRatesStore;

  const sortedData = useMemo(() => {
    const dataToSort = Array.from(data);

    switch (sort.sort) {
      case 'rank':
        dataToSort.sort((a, b) => {
          if (sort.direction === 'asc') return Big(a.rank).cmp(b.rank);
          return Big(b.rank).cmp(a.rank);
        });
        break;
      case 'dailyChange':
        dataToSort.sort((a, b) => {
          const aRate = getRate(a.id);
          const bRate = getRate(b.id);

          const aAsset = !reverseCheck ? aRate : bRate;
          const bAsset = !reverseCheck ? bRate : aRate;

          if (sort.direction === 'asc') return Big(aAsset.dailyPriceChange).cmp(bAsset.dailyPriceChange);
          return Big(bAsset.dailyPriceChange).cmp(aAsset.dailyPriceChange);
        });
        break;
      case 'weeklyChange':
        dataToSort.sort((a, b) => {
          const aAsset = !reverseCheck ? a : b;
          const bAsset = !reverseCheck ? b : a;

          if (sort.direction === 'asc') return Big(aAsset.priceChange.week).cmp(bAsset.priceChange.week);
          return Big(bAsset.priceChange.week).cmp(aAsset.priceChange.week);
        });
        break;
      case 'monthlyChange':
        dataToSort.sort((a, b) => {
          const aAsset = !reverseCheck ? a : b;
          const bAsset = !reverseCheck ? b : a;

          if (sort.direction === 'asc') return Big(aAsset.priceChange.month).cmp(bAsset.priceChange.month);
          return Big(bAsset.priceChange.month).cmp(aAsset.priceChange.month);
        });
        break;
      case 'id':
        dataToSort.sort((a, b) => {
          const aAsset = !reverseCheck ? a : b;
          const bAsset = !reverseCheck ? b : a;

          if (sort.direction === 'asc') return Big(aAsset.id).cmp(bAsset.id);
          return Big(bAsset.id).cmp(aAsset.id);
        });
        break;
      default:
        break;
    }

    return dataToSort.slice(0, maxAssets);
  }, [data, getRate, maxAssets, reverseCheck, sort.direction, sort.sort]);

  return {
    sortedData,
  };
};

export { useSortAssetRow };

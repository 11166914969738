import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';

import { FormInput } from '@components/molecules/Inputs/FormInput';

import PasswordRequirements from '@global-components/Input/PasswordInput/PasswordRequirements';

import { Controller } from 'react-hook-form';

import { TitleText } from './TitleText';
import { PasswordResetContext } from '../Context/PasswordResetContext';

export const EnterOldAndNewPassword: React.FC = () => {
  const { t } = useTranslation('login');
  const context = useContext(PasswordResetContext);

  if (!context) {
    throw new Error('Missing Password Reset Context');
  }

  const {
    submit,
    form: {
      control,
      handleSubmit,
      formState: { isValid, isSubmitting },
    },
  } = context;

  return (
    <FlexLayout direction='column' className='flex justify-center' spacing={8}>
      <TitleText title={t('forcePasswordReset.title')} subtitle={t('forcePasswordReset.subtitle')} />
      <Notification severity='warning' className='mb-16' title={t('forcePasswordReset.notification.title')}>
        {t('forcePasswordReset.notification.subTitle')}
      </Notification>
      <Controller
        name='currentPassword'
        control={control}
        rules={{
          required: 'Current password is required',
        }}
        render={({ field }) => (
          <FormInput
            onChange={field.onChange}
            type='password'
            value={field.value}
            label={t('forcePasswordReset.labels.currentPassword')}
          />
        )}
      />

      <Controller
        name='newPassword'
        control={control}
        rules={{
          required: 'New password is required',
        }}
        render={({ field }) => (
          <FlexLayout direction='column' spacing={0} className='mb-16'>
            <FormInput
              onChange={field.onChange}
              type='password'
              value={field.value}
              label={t('forcePasswordReset.labels.newPassword')}
            />
            <PasswordRequirements error type='create' password={field.value} />
          </FlexLayout>
        )}
      />

      <Controller
        name='confirmPassword'
        control={control}
        rules={{
          required: 'Confirm new password is required',
        }}
        render={({ field, fieldState }) => (
          <FlexLayout direction='column'>
            <FormInput
              onChange={field.onChange}
              type='password'
              value={field.value}
              label={t('forcePasswordReset.labels.confirmPassword')}
              error={fieldState.isDirty ? fieldState.error : undefined}
            />
          </FlexLayout>
        )}
      />

      <FlexLayout direction='column' className='mb-16 mt-32'>
        <Button
          size='lg'
          onClick={handleSubmit(submit)}
          disabled={!isValid}
          loading={isSubmitting}
          className='w-full'
          type='submit'
        >
          Confirm
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

const MOBILE_IMAGE_SIZE = 150;
const IMAGE_SIZE = 196;
const LIGHT_SECONDARY_COLOR = '#E2F0FF';
const DARK_SECONDARY_COLOR = '#313640';

export type GetStartedFeatureStep = {
  title?: string;
  titleElement?: React.ReactElement;
  description: string;
  descriptionElement?: React.ReactElement;
};

const Step: React.FC<{ step: GetStartedFeatureStep; stepNo: number; color: string }> = ({ step, stepNo, color }) => (
  <Stack direction='row' spacing={2} alignItems='center'>
    <Box
      justifyContent='center'
      height={pxToRem(36)}
      alignItems='center'
      width={pxToRem(36)}
      borderRadius={2}
      bgcolor={color}
      display='flex'
      padding={1}
    >
      <Typography color='primary' number fontSize={pxToRem(20)} fontWeight={500} textAlign='center' mt={0.5}>
        {stepNo}
      </Typography>
    </Box>
    <Stack spacing={0.5}>
      <Typography fontSize={pxToRem(12)} fontWeight={600}>
        {step.titleElement || step.title}
      </Typography>
      <Typography fontSize={pxToRem(12)} fontWeight={400} color='text.secondary'>
        {step.descriptionElement || step.description}
      </Typography>
    </Stack>
  </Stack>
);

type Props = {
  title: string;
  onClose: () => void;
  imgSrc?: string;
  headerLabel?: string;
  description?: string;
  customStepHeaderLabel?: string;
  steps?: Array<GetStartedFeatureStep>;
  shouldRenderDescription?: boolean;
  children?: React.ReactNode;
  customConfirmButtons?: React.ReactNode;
};

export const GetStartedFeature: React.FC<Props> = observer(
  ({
    title,
    onClose,
    description,
    shouldRenderDescription,
    headerLabel,
    customStepHeaderLabel,
    imgSrc,
    steps,
    children,
    customConfirmButtons,
  }) => {
    const { isXs, isSm, isMd } = useScreenBreakpoints();
    const { isLightTheme } = useTheme();
    const isCompact = isXs || isSm || isMd;
    const isMobile = isXs;

    const secondaryColor = isLightTheme ? LIGHT_SECONDARY_COLOR : DARK_SECONDARY_COLOR;
    const imgSize = isMobile ? MOBILE_IMAGE_SIZE : IMAGE_SIZE;
    const { t } = useTranslation('common');

    return (
      <Stack direction={isCompact ? 'column' : 'row'} height='100%' maxWidth='100vw'>
        <Stack
          bgcolor={secondaryColor}
          justifyContent='center'
          alignItems='center'
          padding={3}
          sx={{ img: { maxWidth: imgSize } }}
        >
          <img id={`${title}-image`} src={imgSrc} alt={title} width={`${imgSize}px`} height={imgSize} />
        </Stack>
        <Stack height='100%' justifyContent='space-between'>
          <Stack spacing={3} padding={2} height='100%'>
            <Stack spacing={1}>
              {headerLabel && (
                <Typography color='primary' fontSize={pxToRem(12)}>
                  {headerLabel}
                </Typography>
              )}
              <Typography fontSize={pxToRem(24)} fontWeight={700}>
                {title}
              </Typography>

              {(!children || shouldRenderDescription) && description && (
                <Typography color='text.secondary' fontSize={pxToRem(16)}>
                  {description}
                </Typography>
              )}

              {children}
            </Stack>
            {!children && steps?.length ? (
              <Stack spacing={2}>
                <Typography fontSize={pxToRem(18)} fontWeight={600}>
                  {customStepHeaderLabel || t('labels.getStarted', { stepCount: steps.length })}
                </Typography>
                {steps.map((step, index) => (
                  <Step key={step.title} step={step} stepNo={index + 1} color={secondaryColor} />
                ))}
              </Stack>
            ) : null}
            {customConfirmButtons}
            {!customConfirmButtons && (
              <Box>
                <Button variant='contained' color='primary' onClick={onClose} fullWidth={isMobile}>
                  {t('labels.continue')}
                </Button>
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

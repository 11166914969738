/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { clsx } from 'clsx';

import { BodyProps, bodyVariants } from './Body.styles';

const Body = React.forwardRef<HTMLDivElement, BodyProps>(
  ({ className, size, weight, loading, color, overflow, ...props }, ref) => (
    <p ref={ref} className={clsx(bodyVariants({ size, weight, color, loading, overflow }), className)} {...props} />
  ),
);
Body.displayName = 'Body';

export { Body };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Copy } from '@swyftx/aviary/icons/outlined';
import { Button, Input, InputProps, Skeleton, Stack, Typography } from '@swyftx/react-web-design-system';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

const ApiKeyResultInput: React.FC<InputProps> = ({ ...props }) => {
  const { t } = useTranslation('profile.apiKey');
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  if (!props.value) {
    return <Skeleton width='100%' />;
  }

  return (
    <Input
      {...props}
      type='text'
      sx={{ width: '100%' }}
      inputProps={{
        endAdornment: hasClipboardPermission === 'granted' && (
          <Button onClick={() => navigator.clipboard.writeText((props?.value as string) ?? '')}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Copy className='text-color-text-primary' />
              <Typography fontSize={14} fontWeight='500' color='info.main'>
                {t('sections.createApiKey.result.copy')}
              </Typography>
            </Stack>
          </Button>
        ),
      }}
    />
  );
};

export { ApiKeyResultInput };

import { MFAEnrolment, MFARequired, OTPRequired, UserProfile } from '@shared/api';

export enum LoginStep {
  SignIn = 'SignIn',
  NewLoginDetected = 'NewLoginDetected',
  TwoFactorLogin = 'TwoFactorLogin',
  TwoFactorRecovery = 'TwoFactorRecovery',
  TwoFactorLost = 'TwoFactorLost',
  TwoFactorEnrolment = 'TwoFactorEnrolment',
}

type LoginStateType = {
  error: any; // todo potentially type it to login translations
  removeMfaError: string;
  submitting: boolean;
  otpData?: OTPRequired;
  mfaData?: MFARequired | MFAEnrolment;
  mfaResetToken: string;
  showCreateAccount: boolean;
  loginStep: LoginStep;
  recoveryUserProfile?: UserProfile;
  twoFactorEnableInProgress: boolean;
  email?: string;
  password?: string;
};

type LoginActionType = {
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setError: (error: string) => void;
  setSubmitting: (submitting: boolean) => void;
  setOtpData: (otpData?: OTPRequired) => void;
  setMfaData: (mfaData?: MFARequired | MFAEnrolment) => void;
  setShowCreateAccount: (showCreateAccount: boolean) => void;
  setLoginStep: (loginStep: LoginStep) => void;
  login: () => void;
  postLoginActions: (
    data: UserProfile | MFARequired | OTPRequired | MFAEnrolment,
    email?: string,
    password?: string,
  ) => void;
  mfaLogin: (code: string, challenge_type?: string, oob_code?: string, handleLogin?: boolean) => any;
  otpLogin: (code: string) => void;
  lostAuthenticator: (headers?: Record<string, string>) => any;
  getRememberMe: () => void;
  setRecoveryUserProfile: (tempUserProfile?: UserProfile) => void;
  setMfaResetToken: (mfaResetToken: string) => void;
  setRemoveMfaError: (removeMfaError: string) => void;
  setTwoFactorEnableInProgress: (twoFactorEnable: boolean) => void;
  cleanup: () => void;
  initThirdParty: (userProfile: UserProfile) => void;
};

interface LoginStoreSchema extends LoginStateType, LoginActionType {}

const initialValues: LoginStoreSchema = {
  password: '',
  error: '',
  removeMfaError: '',
  submitting: false,
  otpData: undefined,
  mfaData: undefined,
  mfaResetToken: '',
  recoveryUserProfile: undefined,
  showCreateAccount: true,
  twoFactorEnableInProgress: false,
  loginStep: LoginStep.SignIn,
  setEmail: () => {},
  setPassword: () => {},
  setOtpData: () => {},
  setMfaData: () => {},
  setShowCreateAccount: () => {},
  setLoginStep: () => {},
  setError: () => {},
  setTwoFactorEnableInProgress: () => {},
  setSubmitting: () => {},
  login: () => {},
  postLoginActions: () => {},
  mfaLogin: () => {},
  otpLogin: () => {},
  lostAuthenticator: () => {},
  getRememberMe: () => {},
  setRecoveryUserProfile: () => {},
  setMfaResetToken: () => {},
  setRemoveMfaError: () => {},
  initThirdParty: () => {},
  cleanup: () => {},
};

export { initialValues };
export type { LoginStoreSchema };

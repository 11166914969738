import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';

import DOMPurify from 'dompurify';
import { useDebounce, useMeasure } from 'react-use';

import { mockExcerpt } from './NewsCard.types';

const linesCss: { [key: number]: string } = {
  0: '',
  1: 'line-clamp-[1]',
  2: 'line-clamp-[2]',
  3: 'line-clamp-[3]',
  4: 'line-clamp-[4]',
  5: 'line-clamp-[5]',
  6: 'line-clamp-[6]',
  7: 'line-clamp-[7]',
};

type Props = {
  excerpt?: string;
  loading?: boolean;
};

const NewsCardExcerpt: React.FC<Props> = ({ excerpt = '', loading = false }) => {
  const [loadingExcerpt, setLoadingExcerpt] = useState<boolean>(loading);
  const [lines, setLines] = useState<number>(0);
  const { atLeast } = useTailwindContainerBreakpoint({
    containerName: 'single-category-page',
    deps: [excerpt],
  });

  const [ref, { height }] = useMeasure<HTMLParagraphElement>();

  const getLoadingExcerpt = useCallback(() => {
    if (!loadingExcerpt) return '';
    return mockExcerpt;
  }, [loadingExcerpt]);

  useEffect(() => setLines(0), [atLeast, excerpt]);
  useEffect(() => setLoadingExcerpt(loading), [loading]);
  useLayoutEffect(() => setLoadingExcerpt(lines === 0), [lines]);

  useDebounce(
    () => {
      if (lines === 0) {
        setLines(Math.floor(height / 16));
      }
    },
    100,
    [height, lines],
  );

  return (
    <>
      <Body
        ref={ref}
        size='small'
        color='secondary'
        className={`overflow-hidden ${linesCss[lines]}`}
        loading={loadingExcerpt}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(excerpt || getLoadingExcerpt() || '') }}
      />
    </>
  );
};

export { NewsCardExcerpt };

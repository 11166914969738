import { useCallback, useState } from 'react';

import { api, Asset } from '@shared/api';
import { ParamKeysEnum } from '@shared/api/lib/endpoints/newsServiceEndpoint';

import * as Sentry from '@sentry/react';

type Props = {
  assets?: Asset[];
  pageSize: number;
};

const useFetchNews = ({ assets, pageSize }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const fetchNews = useCallback(
    async (nextPageCursor: string) => {
      try {
        setLoading(true);

        const { data } = await api.endpoints.getNews({
          query: {
            [ParamKeysEnum.FILTER_BY_CATEGORY]: (assets || []).map((a) => a.code).join(','),
            [ParamKeysEnum.PAGE_SIZE]: pageSize,
            [ParamKeysEnum.CURSOR]: nextPageCursor ?? '',
          },
        });

        return data;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setLoading(false);
      }
    },
    [assets, pageSize],
  );

  return {
    fetchNews,
    loading,
  };
};

export { useFetchNews };

import React, { ReactElement, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { cn } from '@shared/utils/lib/ui';

import { useFullScreenElement } from 'src/lib/utils/hooks/useFullScreenElement';

import { useTradingViewChartTypes } from './useTradingViewChartTypes';
import { TradingViewChartType, TradingViewHeaderVariant } from '../../../types';

type Props = {
  chartType: TradingViewChartType;
  variant?: TradingViewHeaderVariant;
};

const TradingViewWidgetHeaderChartType: React.FC<Props> = ({ chartType, variant = 'full' }) => {
  const { items, selectedItem } = useTradingViewChartTypes({ chartType });
  const [open, setOpen] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { element } = useFullScreenElement();

  return (
    <Menu
      variant={isXs ? 'mobile' : 'standard'}
      className='max-h-[25rem] min-w-[20rem] overflow-auto'
      container={element}
      title='Chart type'
      open={open}
      onOpenChange={setOpen}
      triggerContent={
        <Button
          leadingIcon={<div className='h-16 w-16'>{selectedItem?.icon as ReactElement}</div>}
          className={cn(open ? 'bg-color-background-surface-selected' : '')}
          variant='ghost'
          onClick={() => setOpen(true)}
          size='sm'
        >
          {variant === 'full' && (selectedItem?.text ?? 'Chart Type')}
        </Button>
      }
      align='start'
    >
      <FlexLayout direction='column'>
        {items.map((item) => {
          if (item.hidden) return null;
          const selected = selectedItem?.text === item.text;

          return (
            <MenuItem
              {...item}
              key={item.text}
              selected={selected}
              className={selected && isXs ? 'bg-color-background-surface-selected text-color-text-selected' : ''}
              preventCloseOnSelect
            />
          );
        })}
      </FlexLayout>
    </Menu>
  );
};

export { TradingViewWidgetHeaderChartType };

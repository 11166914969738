import { CountriesEnum } from '@shared/enums';

import { Country } from './Country.type';

const Countries: { [key in CountriesEnum]: Country } = {
  [CountriesEnum.AD]: {
    code: CountriesEnum.AD,
    name: 'Andorra',
    dialCode: '+376',
  },
  [CountriesEnum.AE]: {
    code: CountriesEnum.AE,
    name: 'United Arab Emirates',
    dialCode: '+971',
  },
  [CountriesEnum.AF]: {
    code: CountriesEnum.AF,
    name: 'Afghanistan',
    dialCode: '+93',
  },
  [CountriesEnum.AG]: {
    code: CountriesEnum.AG,
    name: 'Antigua & Barbuda',
    dialCode: '+1268',
  },
  [CountriesEnum.AI]: {
    code: CountriesEnum.AI,
    name: 'Anguilla',
    dialCode: '+1264',
  },
  [CountriesEnum.AL]: {
    code: CountriesEnum.AL,
    name: 'Albania',
    dialCode: '+355',
  },
  [CountriesEnum.AM]: {
    code: CountriesEnum.AM,
    name: 'Armenia',
    dialCode: '+374',
  },
  [CountriesEnum.AO]: {
    code: CountriesEnum.AO,
    name: 'Angola',
    dialCode: '+244',
  },
  [CountriesEnum.AR]: {
    code: CountriesEnum.AR,
    name: 'Argentina',
    dialCode: '+54',
  },
  [CountriesEnum.AS]: {
    code: CountriesEnum.AS,
    name: 'American Samoa',
    dialCode: '+1684',
  },
  [CountriesEnum.AT]: {
    code: CountriesEnum.AT,
    name: 'Austria',
    dialCode: '+43',
  },
  [CountriesEnum.AU]: {
    code: CountriesEnum.AU,
    name: 'Australia',
    dialCode: '+61',
    ruleSet: 'ruleSetO_AU',
  },
  [CountriesEnum.AW]: {
    code: CountriesEnum.AW,
    name: 'Aruba',
    dialCode: '+297',
  },
  [CountriesEnum.AX]: {
    code: CountriesEnum.AX,
    name: 'Åland Islands',
    dialCode: '+358',
  },
  [CountriesEnum.AZ]: {
    code: CountriesEnum.AZ,
    name: 'Azerbaijan',
    dialCode: '+994',
  },
  [CountriesEnum.BA]: {
    code: CountriesEnum.BA,
    name: 'Bosnia & Herzegovina',
    dialCode: '+387',
  },
  [CountriesEnum.BB]: {
    code: CountriesEnum.BB,
    name: 'Barbados',
    dialCode: '+1246',
  },
  [CountriesEnum.BD]: {
    code: CountriesEnum.BD,
    name: 'Bangladesh',
    dialCode: '+880',
  },
  [CountriesEnum.BE]: {
    code: CountriesEnum.BE,
    name: 'Belgium',
    dialCode: '+32',
  },
  [CountriesEnum.BF]: {
    code: CountriesEnum.BF,
    name: 'Burkina Faso',
    dialCode: '+226',
  },
  [CountriesEnum.BG]: {
    code: CountriesEnum.BG,
    name: 'Bulgaria',
    dialCode: '+359',
  },
  [CountriesEnum.BH]: {
    code: CountriesEnum.BH,
    name: 'Bahrain',
    dialCode: '+973',
  },
  [CountriesEnum.BI]: {
    code: CountriesEnum.BI,
    name: 'Burundi',
    dialCode: '+257',
  },
  [CountriesEnum.BJ]: {
    code: CountriesEnum.BJ,
    name: 'Benin',
    dialCode: '+229',
  },
  [CountriesEnum.BL]: {
    code: CountriesEnum.BL,
    name: 'St. Barthélemy',
    dialCode: '+590',
  },
  [CountriesEnum.BM]: {
    code: CountriesEnum.BM,
    name: 'Bermuda',
    dialCode: '+1441',
  },
  [CountriesEnum.BN]: {
    code: CountriesEnum.BN,
    name: 'Brunei',
    dialCode: '+673',
  },
  [CountriesEnum.BO]: {
    code: CountriesEnum.BO,
    name: 'Bolivia',
    dialCode: '+591',
  },
  [CountriesEnum.BR]: {
    code: CountriesEnum.BR,
    name: 'Brazil',
    dialCode: '+55',
  },
  [CountriesEnum.BS]: {
    code: CountriesEnum.BS,
    name: 'Bahamas',
    dialCode: '+1242',
  },
  [CountriesEnum.BT]: {
    code: CountriesEnum.BT,
    name: 'Bhutan',
    dialCode: '+975',
  },
  [CountriesEnum.BW]: {
    code: CountriesEnum.BW,
    name: 'Botswana',
    dialCode: '+267',
  },
  [CountriesEnum.BY]: {
    code: CountriesEnum.BY,
    name: 'Belarus',
    dialCode: '+375',
  },
  [CountriesEnum.BZ]: {
    code: CountriesEnum.BZ,
    name: 'Belize',
    dialCode: '+501',
  },
  [CountriesEnum.CA]: {
    code: CountriesEnum.CA,
    name: 'Canada',
    dialCode: '+1',
  },
  [CountriesEnum.CC]: {
    code: CountriesEnum.CC,
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
  },
  [CountriesEnum.CD]: {
    code: CountriesEnum.CD,
    name: 'Congo - Kinshasa',
    dialCode: '+243',
  },
  [CountriesEnum.CF]: {
    code: CountriesEnum.CF,
    name: 'Central African Republic',
    dialCode: '+236',
  },
  [CountriesEnum.CG]: {
    code: CountriesEnum.CG,
    name: 'Congo - Brazzaville',
    dialCode: '+242',
  },
  [CountriesEnum.CH]: {
    code: CountriesEnum.CH,
    name: 'Switzerland',
    dialCode: '+41',
  },
  [CountriesEnum.CI]: {
    code: CountriesEnum.CI,
    name: 'Côte d’Ivoire',
    dialCode: '+225',
  },
  [CountriesEnum.CK]: {
    code: CountriesEnum.CK,
    name: 'Cook Islands',
    dialCode: '+682',
  },
  [CountriesEnum.CL]: {
    code: CountriesEnum.CL,
    name: 'Chile',
    dialCode: '+56',
  },
  [CountriesEnum.CM]: {
    code: CountriesEnum.CM,
    name: 'Cameroon',
    dialCode: '+237',
  },
  [CountriesEnum.CN]: {
    code: CountriesEnum.CN,
    name: 'China',
    dialCode: '+86',
  },
  [CountriesEnum.CO]: {
    code: CountriesEnum.CO,
    name: 'Colombia',
    dialCode: '+57',
  },
  [CountriesEnum.CR]: {
    code: CountriesEnum.CR,
    name: 'Costa Rica',
    dialCode: '+506',
  },
  [CountriesEnum.CU]: {
    code: CountriesEnum.CU,
    name: 'Cuba',
    dialCode: '+53',
  },
  [CountriesEnum.CV]: {
    code: CountriesEnum.CV,
    name: 'Cape Verde',
    dialCode: '+238',
  },
  [CountriesEnum.CX]: {
    code: CountriesEnum.CX,
    name: 'Christmas Island',
    dialCode: '+61',
  },
  [CountriesEnum.CY]: {
    code: CountriesEnum.CY,
    name: 'Cyprus',
    dialCode: '+357',
  },
  [CountriesEnum.CZ]: {
    code: CountriesEnum.CZ,
    name: 'Czech Republic',
    dialCode: '+420',
  },
  [CountriesEnum.DE]: {
    code: CountriesEnum.DE,
    name: 'Germany',
    dialCode: '+49',
  },
  [CountriesEnum.DJ]: {
    code: CountriesEnum.DJ,
    name: 'Djibouti',
    dialCode: '+253',
  },
  [CountriesEnum.DK]: {
    code: CountriesEnum.DK,
    name: 'Denmark',
    dialCode: '+45',
  },
  [CountriesEnum.DM]: {
    code: CountriesEnum.DM,
    name: 'Dominica',
    dialCode: '+1767',
  },
  [CountriesEnum.DO]: {
    code: CountriesEnum.DO,
    name: 'Dominican Republic',
    dialCode: '+1849',
  },
  [CountriesEnum.DZ]: {
    code: CountriesEnum.DZ,
    name: 'Algeria',
    dialCode: '+213',
  },
  [CountriesEnum.EC]: {
    code: CountriesEnum.EC,
    name: 'Ecuador',
    dialCode: '+593',
  },
  [CountriesEnum.EE]: {
    code: CountriesEnum.EE,
    name: 'Estonia',
    dialCode: '+372',
  },
  [CountriesEnum.EG]: {
    code: CountriesEnum.EG,
    name: 'Egypt',
    dialCode: '+20',
  },
  [CountriesEnum.ER]: {
    code: CountriesEnum.ER,
    name: 'Eritrea',
    dialCode: '+291',
  },
  [CountriesEnum.ES]: {
    code: CountriesEnum.ES,
    name: 'Spain',
    dialCode: '+34',
  },
  [CountriesEnum.ET]: {
    code: CountriesEnum.ET,
    name: 'Ethiopia',
    dialCode: '+251',
  },
  [CountriesEnum.FI]: {
    code: CountriesEnum.FI,
    name: 'Finland',
    dialCode: '+358',
  },
  [CountriesEnum.FJ]: {
    code: CountriesEnum.FJ,
    name: 'Fiji',
    dialCode: '+679',
  },
  [CountriesEnum.FK]: {
    code: CountriesEnum.FK,
    name: 'Falkland Islands',
    dialCode: '+500',
  },
  [CountriesEnum.FM]: {
    code: CountriesEnum.FM,
    name: 'Micronesia',
    dialCode: '+691',
  },
  [CountriesEnum.FO]: {
    code: CountriesEnum.FO,
    name: 'Faroe Islands',
    dialCode: '+298',
  },
  [CountriesEnum.FR]: {
    code: CountriesEnum.FR,
    name: 'France',
    dialCode: '+33',
  },
  [CountriesEnum.GA]: {
    code: CountriesEnum.GA,
    name: 'Gabon',
    dialCode: '+241',
  },
  [CountriesEnum.GB]: {
    code: CountriesEnum.GB,
    name: 'United Kingdom',
    altNames: ['uk', 'england', 'wales', 'northern ireland', 'scotland', 'nir', 'eng', 'sct', 'wls', 'zet'],
    dialCode: '+44',
  },
  [CountriesEnum.GD]: {
    code: CountriesEnum.GD,
    name: 'Grenada',
    dialCode: '+1473',
  },
  [CountriesEnum.GE]: {
    code: CountriesEnum.GE,
    name: 'Georgia',
    dialCode: '+995',
  },
  [CountriesEnum.GF]: {
    code: CountriesEnum.GF,
    name: 'French Guiana',
    dialCode: '+594',
  },
  [CountriesEnum.GG]: {
    code: CountriesEnum.GG,
    name: 'Guernsey',
    dialCode: '+44',
  },
  [CountriesEnum.GH]: {
    code: CountriesEnum.GH,
    name: 'Ghana',
    dialCode: '+233',
  },
  [CountriesEnum.GI]: {
    code: CountriesEnum.GI,
    name: 'Gibraltar',
    dialCode: '+350',
  },
  [CountriesEnum.GL]: {
    code: CountriesEnum.GL,
    name: 'Greenland',
    dialCode: '+299',
  },
  [CountriesEnum.GM]: {
    code: CountriesEnum.GM,
    name: 'Gambia',
    dialCode: '+220',
  },
  [CountriesEnum.GN]: {
    code: CountriesEnum.GN,
    name: 'Guinea',
    dialCode: '+224',
  },
  [CountriesEnum.GP]: {
    code: CountriesEnum.GP,
    name: 'Guadeloupe',
    dialCode: '+590',
  },
  [CountriesEnum.GQ]: {
    code: CountriesEnum.GQ,
    name: 'Equatorial Guinea',
    dialCode: '+240',
  },
  [CountriesEnum.GR]: {
    code: CountriesEnum.GR,
    name: 'Greece',
    dialCode: '+30',
  },
  [CountriesEnum.GS]: {
    code: CountriesEnum.GS,
    name: 'So. Georgia & So. Sandwich Isl.',
    dialCode: '+500',
  },
  [CountriesEnum.GT]: {
    code: CountriesEnum.GT,
    name: 'Guatemala',
    dialCode: '+502',
  },
  [CountriesEnum.GU]: {
    code: CountriesEnum.GU,
    name: 'Guam',
    dialCode: '+1671',
  },
  [CountriesEnum.GW]: {
    code: CountriesEnum.GW,
    name: 'Guinea-Bissau',
    dialCode: '+245',
  },
  [CountriesEnum.GY]: {
    code: CountriesEnum.GY,
    name: 'Guyana',
    dialCode: '+595',
  },
  [CountriesEnum.HK]: {
    code: CountriesEnum.HK,
    name: 'Hong Kong',
    dialCode: '+852',
  },
  [CountriesEnum.HN]: {
    code: CountriesEnum.HN,
    name: 'Honduras',
    dialCode: '+504',
  },
  [CountriesEnum.HR]: {
    code: CountriesEnum.HR,
    name: 'Croatia',
    dialCode: '+385',
  },
  [CountriesEnum.HT]: {
    code: CountriesEnum.HT,
    name: 'Haiti',
    dialCode: '+509',
  },
  [CountriesEnum.HU]: {
    code: CountriesEnum.HU,
    name: 'Hungary',
    dialCode: '+36',
  },
  [CountriesEnum.ID]: {
    code: CountriesEnum.ID,
    name: 'Indonesia',
    dialCode: '+62',
  },
  [CountriesEnum.IE]: {
    code: CountriesEnum.IE,
    name: 'Ireland',
    dialCode: '+353',
  },
  [CountriesEnum.IL]: {
    code: CountriesEnum.IL,
    name: 'Israel',
    dialCode: '+972',
  },
  [CountriesEnum.IM]: {
    code: CountriesEnum.IM,
    name: 'Isle of Man',
    dialCode: '+44',
  },
  [CountriesEnum.IN]: {
    code: CountriesEnum.IN,
    name: 'India',
    dialCode: '+91',
  },
  [CountriesEnum.IO]: {
    code: CountriesEnum.IO,
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
  },
  [CountriesEnum.IQ]: {
    code: CountriesEnum.IQ,
    name: 'Iraq',
    dialCode: '+964',
  },
  [CountriesEnum.IR]: {
    code: CountriesEnum.IR,
    name: 'Iran',
    dialCode: '+98',
  },
  [CountriesEnum.IS]: {
    code: CountriesEnum.IS,
    name: 'Iceland',
    dialCode: '+354',
  },
  [CountriesEnum.IT]: {
    code: CountriesEnum.IT,
    name: 'Italy',
    dialCode: '+39',
  },
  [CountriesEnum.JE]: {
    code: CountriesEnum.JE,
    name: 'Jersey',
    dialCode: '+44',
  },
  [CountriesEnum.JM]: {
    code: CountriesEnum.JM,
    name: 'Jamaica',
    dialCode: '+1876',
  },
  [CountriesEnum.JO]: {
    code: CountriesEnum.JO,
    name: 'Jordan',
    dialCode: '+962',
  },
  [CountriesEnum.JP]: {
    code: CountriesEnum.JP,
    name: 'Japan',
    dialCode: '+81',
  },
  [CountriesEnum.KE]: {
    code: CountriesEnum.KE,
    name: 'Kenya',
    dialCode: '+254',
  },
  [CountriesEnum.KG]: {
    code: CountriesEnum.KG,
    name: 'Kyrgyzstan',
    dialCode: '+996',
  },
  [CountriesEnum.KH]: {
    code: CountriesEnum.KH,
    name: 'Cambodia',
    dialCode: '+855',
  },
  [CountriesEnum.KI]: {
    code: CountriesEnum.KI,
    name: 'Kiribati',
    dialCode: '+686',
  },
  [CountriesEnum.KM]: {
    code: CountriesEnum.KM,
    name: 'Comoros',
    dialCode: '+269',
  },
  [CountriesEnum.KN]: {
    code: CountriesEnum.KN,
    name: 'St. Kitts & Nevis',
    dialCode: '+1869',
  },
  [CountriesEnum.KP]: {
    code: CountriesEnum.KP,
    name: 'North Korea',
    dialCode: '+850',
  },
  [CountriesEnum.KR]: {
    code: CountriesEnum.KR,
    name: 'South Korea',
    dialCode: '+82',
  },
  [CountriesEnum.KW]: {
    code: CountriesEnum.KW,
    name: 'Kuwait',
    dialCode: '+965',
  },
  [CountriesEnum.KY]: {
    code: CountriesEnum.KY,
    name: 'Cayman Islands',
    dialCode: '+ 345',
  },
  [CountriesEnum.KZ]: {
    code: CountriesEnum.KZ,
    name: 'Kazakhstan',
    dialCode: '+77',
  },
  [CountriesEnum.LA]: {
    code: CountriesEnum.LA,
    name: 'Laos',
    dialCode: '+856',
  },
  [CountriesEnum.LB]: {
    code: CountriesEnum.LB,
    name: 'Lebanon',
    dialCode: '+961',
  },
  [CountriesEnum.LC]: {
    code: CountriesEnum.LC,
    name: 'St. Lucia',
    dialCode: '+1758',
  },
  [CountriesEnum.LI]: {
    code: CountriesEnum.LI,
    name: 'Liechtenstein',
    dialCode: '+423',
  },
  [CountriesEnum.LK]: {
    code: CountriesEnum.LK,
    name: 'Sri Lanka',
    dialCode: '+94',
  },
  [CountriesEnum.LR]: {
    code: CountriesEnum.LR,
    name: 'Liberia',
    dialCode: '+231',
  },
  [CountriesEnum.LS]: {
    code: CountriesEnum.LS,
    name: 'Lesotho',
    dialCode: '+266',
  },
  [CountriesEnum.LT]: {
    code: CountriesEnum.LT,
    name: 'Lithuania',
    dialCode: '+370',
  },
  [CountriesEnum.LU]: {
    code: CountriesEnum.LU,
    name: 'Luxembourg',
    dialCode: '+352',
  },
  [CountriesEnum.LV]: {
    code: CountriesEnum.LV,
    name: 'Latvia',
    dialCode: '+371',
  },
  [CountriesEnum.LY]: {
    code: CountriesEnum.LY,
    name: 'Libya',
    dialCode: '+218',
  },
  [CountriesEnum.MA]: {
    code: CountriesEnum.MA,
    name: 'Morocco',
    dialCode: '+212',
  },
  [CountriesEnum.MC]: {
    code: CountriesEnum.MC,
    name: 'Monaco',
    dialCode: '+377',
  },
  [CountriesEnum.MD]: {
    code: CountriesEnum.MD,
    name: 'Moldova',
    dialCode: '+373',
  },
  [CountriesEnum.ME]: {
    code: CountriesEnum.ME,
    name: 'Montenegro',
    dialCode: '+382',
  },
  [CountriesEnum.MF]: {
    code: CountriesEnum.MF,
    name: 'St. Martin',
    dialCode: '+590',
  },
  [CountriesEnum.MG]: {
    code: CountriesEnum.MG,
    name: 'Madagascar',
    dialCode: '+261',
  },
  [CountriesEnum.MH]: {
    code: CountriesEnum.MH,
    name: 'Marshall Islands',
    dialCode: '+692',
  },
  [CountriesEnum.MK]: {
    code: CountriesEnum.MK,
    name: 'Macedonia',
    dialCode: '+389',
  },
  [CountriesEnum.ML]: {
    code: CountriesEnum.ML,
    name: 'Mali',
    dialCode: '+223',
  },
  [CountriesEnum.MM]: {
    code: CountriesEnum.MM,
    name: 'Myanmar',
    dialCode: '+95',
  },
  [CountriesEnum.MN]: {
    code: CountriesEnum.MN,
    name: 'Mongolia',
    dialCode: '+976',
  },
  [CountriesEnum.MO]: {
    code: CountriesEnum.MO,
    name: 'Macau',
    dialCode: '+853',
  },
  [CountriesEnum.MP]: {
    code: CountriesEnum.MP,
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
  },
  [CountriesEnum.MQ]: {
    code: CountriesEnum.MQ,
    name: 'Martinique',
    dialCode: '+596',
  },
  [CountriesEnum.MR]: {
    code: CountriesEnum.MR,
    name: 'Mauritania',
    dialCode: '+222',
  },
  [CountriesEnum.MS]: {
    code: CountriesEnum.MS,
    name: 'Montserrat',
    dialCode: '+1664',
  },
  [CountriesEnum.MT]: {
    code: CountriesEnum.MT,
    name: 'Malta',
    dialCode: '+356',
  },
  [CountriesEnum.MU]: {
    code: CountriesEnum.MU,
    name: 'Mauritius',
    dialCode: '+230',
  },
  [CountriesEnum.MV]: {
    code: CountriesEnum.MV,
    name: 'Maldives',
    dialCode: '+960',
  },
  [CountriesEnum.MW]: {
    code: CountriesEnum.MW,
    name: 'Malawi',
    dialCode: '+265',
  },
  [CountriesEnum.MX]: {
    code: CountriesEnum.MX,
    name: 'Mexico',
    dialCode: '+52',
  },
  [CountriesEnum.MY]: {
    code: CountriesEnum.MY,
    name: 'Malaysia',
    dialCode: '+60',
  },
  [CountriesEnum.MZ]: {
    code: CountriesEnum.MZ,
    name: 'Mozambique',
    dialCode: '+258',
  },
  [CountriesEnum.NA]: {
    code: CountriesEnum.NA,
    name: 'Namibia',
    dialCode: '+264',
  },
  [CountriesEnum.NC]: {
    code: CountriesEnum.NC,
    name: 'New Caledonia',
    dialCode: '+687',
  },
  [CountriesEnum.NE]: {
    code: CountriesEnum.NE,
    name: 'Niger',
    dialCode: '+227',
  },
  [CountriesEnum.NF]: {
    code: CountriesEnum.NF,
    name: 'Norfolk Island',
    dialCode: '+672',
  },
  [CountriesEnum.NG]: {
    code: CountriesEnum.NG,
    name: 'Nigeria',
    dialCode: '+234',
  },
  [CountriesEnum.NI]: {
    code: CountriesEnum.NI,
    name: 'Nicaragua',
    dialCode: '+505',
  },
  [CountriesEnum.NL]: {
    code: CountriesEnum.NL,
    name: 'Netherlands',
    dialCode: '+31',
  },
  [CountriesEnum.NO]: {
    code: CountriesEnum.NO,
    name: 'Norway',
    dialCode: '+47',
  },
  [CountriesEnum.NP]: {
    code: CountriesEnum.NP,
    name: 'Nepal',
    dialCode: '+977',
  },
  [CountriesEnum.NR]: {
    code: CountriesEnum.NR,
    name: 'Nauru',
    dialCode: '+674',
  },
  [CountriesEnum.NU]: {
    code: CountriesEnum.NU,
    name: 'Niue',
    dialCode: '+683',
  },
  [CountriesEnum.NZ]: {
    code: CountriesEnum.NZ,
    name: 'New Zealand',
    dialCode: '+64',
    ruleSet: 'ruleSetL_NZ',
  },
  [CountriesEnum.OM]: {
    code: CountriesEnum.OM,
    name: 'Oman',
    dialCode: '+968',
  },
  [CountriesEnum.PA]: {
    code: CountriesEnum.PA,
    name: 'Panama',
    dialCode: '+507',
  },
  [CountriesEnum.PE]: {
    code: CountriesEnum.PE,
    name: 'Peru',
    dialCode: '+51',
  },
  [CountriesEnum.PF]: {
    code: CountriesEnum.PF,
    name: 'French Polynesia',
    dialCode: '+689',
  },
  [CountriesEnum.PG]: {
    code: CountriesEnum.PG,
    name: 'Papua New Guinea',
    dialCode: '+675',
  },
  [CountriesEnum.PH]: {
    code: CountriesEnum.PH,
    name: 'Philippines',
    dialCode: '+63',
  },
  [CountriesEnum.PK]: {
    code: CountriesEnum.PK,
    name: 'Pakistan',
    dialCode: '+92',
  },
  [CountriesEnum.PL]: {
    code: CountriesEnum.PL,
    name: 'Poland',
    dialCode: '+48',
  },
  [CountriesEnum.PM]: {
    code: CountriesEnum.PM,
    name: 'St. Pierre & Miquelon',
    dialCode: '+508',
  },
  [CountriesEnum.PN]: {
    code: CountriesEnum.PN,
    name: 'Pitcairn Islands',
    dialCode: '+872',
  },
  [CountriesEnum.PR]: {
    code: CountriesEnum.PR,
    name: 'Puerto Rico',
    dialCode: '+1939',
  },
  [CountriesEnum.PS]: {
    code: CountriesEnum.PS,
    name: 'Palestinian Territories',
    dialCode: '+970',
  },
  [CountriesEnum.PT]: {
    code: CountriesEnum.PT,
    name: 'Portugal',
    dialCode: '+351',
  },
  [CountriesEnum.PW]: {
    code: CountriesEnum.PW,
    name: 'Palau',
    dialCode: '+680',
  },
  [CountriesEnum.PY]: {
    code: CountriesEnum.PY,
    name: 'Paraguay',
    dialCode: '+595',
  },
  [CountriesEnum.QA]: {
    code: CountriesEnum.QA,
    name: 'Qatar',
    dialCode: '+974',
  },
  [CountriesEnum.RE]: {
    code: CountriesEnum.RE,
    name: 'Réunion',
    dialCode: '+262',
  },
  [CountriesEnum.RO]: {
    code: CountriesEnum.RO,
    name: 'Romania',
    dialCode: '+40',
  },
  [CountriesEnum.RS]: {
    code: CountriesEnum.RS,
    name: 'Serbia',
    dialCode: '+381',
  },
  [CountriesEnum.RU]: {
    code: CountriesEnum.RU,
    name: 'Russia',
    dialCode: '+7',
  },
  [CountriesEnum.RW]: {
    code: CountriesEnum.RW,
    name: 'Rwanda',
    dialCode: '+250',
  },
  [CountriesEnum.SA]: {
    code: CountriesEnum.SA,
    name: 'Saudi Arabia',
    dialCode: '+966',
  },
  [CountriesEnum.SB]: {
    code: CountriesEnum.SB,
    name: 'Solomon Islands',
    dialCode: '+677',
  },
  [CountriesEnum.SC]: {
    code: CountriesEnum.SC,
    name: 'Seychelles',
    dialCode: '+248',
  },
  [CountriesEnum.SD]: {
    code: CountriesEnum.SD,
    name: 'Sudan',
    dialCode: '+249',
  },
  [CountriesEnum.SE]: {
    code: CountriesEnum.SE,
    name: 'Sweden',
    dialCode: '+46',
  },
  [CountriesEnum.SG]: {
    code: CountriesEnum.SG,
    name: 'Singapore',
    dialCode: '+65',
  },
  [CountriesEnum.SH]: {
    code: CountriesEnum.SH,
    name: 'St. Helena',
    dialCode: '+290',
  },
  [CountriesEnum.SI]: {
    code: CountriesEnum.SI,
    name: 'Slovenia',
    dialCode: '+386',
  },
  [CountriesEnum.SJ]: {
    code: CountriesEnum.SJ,
    name: 'Svalbard & Jan Mayen',
    dialCode: '+47',
  },
  [CountriesEnum.SK]: {
    code: CountriesEnum.SK,
    name: 'Slovakia',
    dialCode: '+421',
  },
  [CountriesEnum.SL]: {
    code: CountriesEnum.SL,
    name: 'Sierra Leone',
    dialCode: '+232',
  },
  [CountriesEnum.SM]: {
    code: CountriesEnum.SM,
    name: 'San Marino',
    dialCode: '+378',
  },
  [CountriesEnum.SN]: {
    code: CountriesEnum.SN,
    name: 'Senegal',
    dialCode: '+221',
  },
  [CountriesEnum.SO]: {
    code: CountriesEnum.SO,
    name: 'Somalia',
    dialCode: '+252',
  },
  [CountriesEnum.SR]: {
    code: CountriesEnum.SR,
    name: 'Suriname',
    dialCode: '+597',
  },
  [CountriesEnum.SS]: {
    code: CountriesEnum.SS,
    name: 'South Sudan',
    dialCode: '+211',
  },
  [CountriesEnum.ST]: {
    code: CountriesEnum.ST,
    name: 'São Tomé & Príncipe',
    dialCode: '+239',
  },
  [CountriesEnum.SV]: {
    code: CountriesEnum.SV,
    name: 'El Salvador',
    dialCode: '+503',
  },
  [CountriesEnum.SY]: {
    code: CountriesEnum.SY,
    name: 'Syria',
    dialCode: '+963',
  },
  [CountriesEnum.SZ]: {
    code: CountriesEnum.SZ,
    name: 'Swaziland',
    dialCode: '+268',
  },
  [CountriesEnum.TC]: {
    code: CountriesEnum.TC,
    name: 'Turks & Caicos Islands',
    dialCode: '+1649',
  },
  [CountriesEnum.TD]: {
    code: CountriesEnum.TD,
    name: 'Chad',
    dialCode: '+235',
  },
  [CountriesEnum.TG]: {
    code: CountriesEnum.TG,
    name: 'Togo',
    dialCode: '+228',
  },
  [CountriesEnum.TH]: {
    code: CountriesEnum.TH,
    name: 'Thailand',
    dialCode: '+66',
  },
  [CountriesEnum.TJ]: {
    code: CountriesEnum.TJ,
    name: 'Tajikistan',
    dialCode: '+992',
  },
  [CountriesEnum.TK]: {
    code: CountriesEnum.TK,
    name: 'Tokelau',
    dialCode: '+690',
  },
  [CountriesEnum.TL]: {
    code: CountriesEnum.TL,
    name: 'Timor-Leste',
    dialCode: '+670',
  },
  [CountriesEnum.TM]: {
    code: CountriesEnum.TM,
    name: 'Turkmenistan',
    dialCode: '+993',
  },
  [CountriesEnum.TN]: {
    code: CountriesEnum.TN,
    name: 'Tunisia',
    dialCode: '+216',
  },
  [CountriesEnum.TO]: {
    code: CountriesEnum.TO,
    name: 'Tonga',
    dialCode: '+676',
  },
  [CountriesEnum.TR]: {
    code: CountriesEnum.TR,
    name: 'Turkey',
    dialCode: '+90',
  },
  [CountriesEnum.TT]: {
    code: CountriesEnum.TT,
    name: 'Trinidad & Tobago',
    dialCode: '+1868',
  },
  [CountriesEnum.TV]: {
    code: CountriesEnum.TV,
    name: 'Tuvalu',
    dialCode: '+688',
  },
  [CountriesEnum.TW]: {
    code: CountriesEnum.TW,
    name: 'Taiwan',
    dialCode: '+886',
  },
  [CountriesEnum.TZ]: {
    code: CountriesEnum.TZ,
    name: 'Tanzania',
    dialCode: '+255',
  },
  [CountriesEnum.UA]: {
    code: CountriesEnum.UA,
    name: 'Ukraine',
    dialCode: '+380',
  },
  [CountriesEnum.UG]: {
    code: CountriesEnum.UG,
    name: 'Uganda',
    dialCode: '+256',
  },
  [CountriesEnum.US]: {
    code: CountriesEnum.US,
    name: 'United States',
    dialCode: '+1',
  },
  [CountriesEnum.UY]: {
    code: CountriesEnum.UY,
    name: 'Uruguay',
    dialCode: '+598',
  },
  [CountriesEnum.UZ]: {
    code: CountriesEnum.UZ,
    name: 'Uzbekistan',
    dialCode: '+998',
  },
  [CountriesEnum.VA]: {
    code: CountriesEnum.VA,
    name: 'Vatican City',
    dialCode: '+379',
  },
  [CountriesEnum.VC]: {
    code: CountriesEnum.VC,
    name: 'St. Vincent & Grenadines',
    dialCode: '+1784',
  },
  [CountriesEnum.VE]: {
    code: CountriesEnum.VE,
    name: 'Venezuela',
    dialCode: '+58',
  },
  [CountriesEnum.VG]: {
    code: CountriesEnum.VG,
    name: 'British Virgin Islands',
    dialCode: '+1284',
  },
  [CountriesEnum.VI]: {
    code: CountriesEnum.VI,
    name: 'U.S. Virgin Islands',
    dialCode: '+1340',
  },
  [CountriesEnum.VN]: {
    code: CountriesEnum.VN,
    name: 'Vietnam',
    dialCode: '+84',
  },
  [CountriesEnum.VU]: {
    code: CountriesEnum.VU,
    name: 'Vanuatu',
    dialCode: '+678',
  },
  [CountriesEnum.WF]: {
    code: CountriesEnum.WF,
    name: 'Wallis & Futuna',
    dialCode: '+681',
  },
  [CountriesEnum.WS]: {
    code: CountriesEnum.WS,
    name: 'Samoa',
    dialCode: '+685',
  },
  [CountriesEnum.YE]: {
    code: CountriesEnum.YE,
    name: 'Yemen',
    dialCode: '+967',
  },
  [CountriesEnum.YT]: {
    code: CountriesEnum.YT,
    name: 'Mayotte',
    dialCode: '+262',
  },
  [CountriesEnum.ZA]: {
    code: CountriesEnum.ZA,
    name: 'South Africa',
    dialCode: '+27',
  },
  [CountriesEnum.ZM]: {
    code: CountriesEnum.ZM,
    name: 'Zambia',
    dialCode: '+260',
  },
  [CountriesEnum.ZW]: {
    code: CountriesEnum.ZW,
    name: 'Zimbabwe',
    dialCode: '+263',
  },
};

export default Countries;

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { DEFAULT_EMPTY_VALUE } from '@shared/constants/general';
import { Big } from '@shared/safe-big';
import { formatCurrency, getValuePriceScale } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  profitLossValue: Big;
  variant?: 'dashboard' | 'trade';
};

const PortfolioTableProfitLossValue: React.FC<Props> = observer(({ profitLossValue, variant = 'dashboard' }) => {
  const baseAsset = useBaseAsset();

  const profitValue = useMemo(() => {
    if (variant === 'trade') {
      return profitLossValue.eq(0)
        ? DEFAULT_EMPTY_VALUE
        : `${profitLossValue.gt(0) ? '+' : '-'}${formatCurrency(profitLossValue.abs(), baseAsset, {
            priceScale: getValuePriceScale(baseAsset),
          })} `;
    }
    return !profitLossValue.eq(0)
      ? formatCurrency(profitLossValue.abs(), baseAsset, { priceScale: getValuePriceScale(baseAsset) })
      : DEFAULT_EMPTY_VALUE;
  }, [baseAsset, profitLossValue, variant]);

  return (
    <FlexLayout justifyContent={variant === 'dashboard' ? 'end' : 'start'}>
      <NumericDataItem size='small' alignItems='start' data={profitLossValue.toString()}>
        {profitValue}
      </NumericDataItem>
    </FlexLayout>
  );
});

export { PortfolioTableProfitLossValue };

import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

type Props = {
  label: string;
  value: string;
};

const BORDER_COLOR = '#ecf0f7';

const RadioSelection: React.FC<Props> = (props) => {
  const { label, value } = props;

  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label}
      labelPlacement='start'
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${BORDER_COLOR}`,
        borderRadius: '4px',
        height: '72px',
        paddingLeft: 2,
        paddingRight: 2,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 1,
        '.MuiFormControlLabel-label': {
          color: 'text.primary',
          fontSize: 14,
          fontWeight: 500,
        },
      }}
    />
  );
};

export { RadioSelection };

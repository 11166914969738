import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const WrappedTokens: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M12.6985 2.88494C12.7621 2.47564 13.1454 2.1954 13.5547 2.259C14.2913 2.37346 15.0136 2.57143 15.6884 2.856C16.0701 3.01695 16.249 3.45683 16.088 3.83849C15.9271 4.22016 15.4872 4.39907 15.1055 4.23812C14.5531 4.00515 13.9503 3.83847 13.3244 3.74121C12.9151 3.67761 12.6348 3.29424 12.6985 2.88494Z'
      fill='currentColor'
    />
    <path
      d='M10.6741 3.74162C11.0836 3.67949 11.3652 3.29713 11.3031 2.8876C11.241 2.47808 10.8586 2.19646 10.4491 2.25859C9.76344 2.36261 9.09264 2.53957 8.45973 2.79295C8.07519 2.9469 7.88826 3.38343 8.04221 3.76797C8.19616 4.15252 8.63269 4.33945 9.01723 4.1855C9.53508 3.97818 10.0934 3.82971 10.6741 3.74162Z'
      fill='currentColor'
    />
    <path
      d='M6.82182 4.45087C7.08573 4.77013 7.04087 5.24288 6.72161 5.50679C6.31164 5.8457 5.93453 6.21494 5.61489 6.60435C5.35208 6.92451 4.87949 6.97101 4.55932 6.7082C4.23916 6.44539 4.19266 5.9728 4.45547 5.65264C4.84548 5.1775 5.29369 4.74103 5.7659 4.35067C6.08515 4.08676 6.5579 4.13162 6.82182 4.45087Z'
      fill='currentColor'
    />
    <path
      d='M4.23975 8.88148C4.39855 8.49892 4.21715 8.06005 3.83459 7.90125C3.45203 7.74245 3.01316 7.92385 2.85436 8.30641C2.57137 8.98816 2.37354 9.70895 2.259 10.446C2.1954 10.8553 2.47564 11.2387 2.88494 11.3023C3.29424 11.3659 3.67761 11.0857 3.74121 10.6764C3.83839 10.051 4.0052 9.44653 4.23975 8.88148Z'
      fill='currentColor'
    />
    <path
      d='M2.88761 12.698C3.29714 12.6359 3.67949 12.9175 3.74162 13.3271C3.82861 13.9005 3.98578 14.4626 4.18954 14.9942C4.33779 15.381 4.14443 15.8147 3.75765 15.963C3.37088 16.1112 2.93716 15.9179 2.78891 15.5311C2.55156 14.9119 2.36372 14.2451 2.25859 13.552C2.19646 13.1425 2.47808 12.7602 2.88761 12.698Z'
      fill='currentColor'
    />
    <path
      d='M5.51093 17.2765C5.25047 16.9544 4.77823 16.9044 4.45615 17.1649C4.13408 17.4254 4.08413 17.8976 4.34459 18.2197C4.74216 18.7113 5.18201 19.15 5.65104 19.5359C5.97092 19.799 6.44356 19.753 6.70671 19.4332C6.96987 19.1133 6.92388 18.6406 6.604 18.3775C6.21046 18.0537 5.84264 17.6866 5.51093 17.2765Z'
      fill='currentColor'
    />
    <path
      d='M8.86334 19.7572C8.47889 19.603 8.04224 19.7897 7.88807 20.1742C7.7339 20.5586 7.92058 20.9953 8.30503 21.1494C8.97584 21.4184 9.69296 21.6262 10.4467 21.7417C10.8561 21.8044 11.2389 21.5234 11.3017 21.1139C11.3644 20.7045 11.0833 20.3217 10.6739 20.259C10.0455 20.1627 9.43935 19.9882 8.86334 19.7572Z'
      fill='currentColor'
    />
    <path
      d='M18.3308 4.45027C18.0078 4.19101 17.5357 4.2427 17.2764 4.56573C17.0172 4.88877 17.0689 5.36082 17.3919 5.62009C17.7869 5.93708 18.1519 6.31001 18.4768 6.71384C18.7364 7.0366 19.2085 7.08778 19.5312 6.82817C19.854 6.56855 19.9052 6.09644 19.6456 5.77369C19.2608 5.29534 18.8201 4.84295 18.3308 4.45027Z'
      fill='currentColor'
    />
    <path
      d='M20.2474 8.02595C20.635 7.87984 21.0676 8.0756 21.2137 8.46318C21.4497 9.0892 21.6374 9.75499 21.7425 10.4483C21.8046 10.8578 21.523 11.2401 21.1134 11.3022C20.7039 11.3643 20.3216 11.0827 20.2595 10.6731C20.1726 10.1 20.0153 9.53664 19.8101 8.9923C19.664 8.60471 19.8598 8.17206 20.2474 8.02595Z'
      fill='currentColor'
    />
    <path
      d='M21.7417 13.5532C21.8044 13.1437 21.5234 12.761 21.1139 12.6982C20.7045 12.6355 20.3217 12.9165 20.259 13.3259C20.163 13.9524 19.989 14.5488 19.7571 15.128C19.6031 15.5125 19.79 15.9491 20.1746 16.103C20.5591 16.257 20.9956 16.0701 21.1496 15.6855C21.4177 15.016 21.6259 14.3087 21.7417 13.5532Z'
      fill='currentColor'
    />
    <path
      d='M19.4268 17.2779C19.7495 17.5375 19.8007 18.0096 19.5411 18.3324C19.1522 18.8159 18.7094 19.259 18.2127 19.6509C17.8875 19.9075 17.4159 19.8519 17.1593 19.5267C16.9027 19.2016 16.9583 18.7299 17.2835 18.4734C17.6886 18.1537 18.0516 17.7909 18.3723 17.3923C18.6319 17.0695 19.104 17.0183 19.4268 17.2779Z'
      fill='currentColor'
    />
    <path
      d='M15.5414 21.1975C15.9259 21.0436 16.1129 20.607 15.959 20.2225C15.8051 19.8379 15.3685 19.651 14.984 19.8049C14.4659 20.0122 13.9044 20.1619 13.3159 20.2604C12.9073 20.3287 12.6315 20.7153 12.6998 21.1238C12.7682 21.5323 13.1547 21.8081 13.5633 21.7398C14.2411 21.6264 14.9087 21.4507 15.5414 21.1975Z'
      fill='currentColor'
    />
  </svg>
);

export { WrappedTokens };

import React, { PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
};

const TableBody = React.forwardRef<HTMLTableSectionElement, PropsWithChildren<Props>>(
  ({ className, children }, ref) => (
    <tbody ref={ref} className={cn('', className)}>
      {children}
    </tbody>
  ),
);

TableBody.displayName = 'TableBody';

export { TableBody };

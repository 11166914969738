import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

interface Props {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}

export const RemoveMemberModal: React.FC<Props> = (props) => {
  const { name, open, setOpen, onConfirm } = props;
  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      title={`Remove ${name} from application?`}
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={24} className='p-24 pt-0'>
        <Body color='secondary'>Are you sure you want to remove this member? All details will be lost</Body>
        <FlexLayout direction='row' className='w-full justify-end' spacing={8}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color='destructive' onClick={onConfirm} type='submit'>
            Remove member
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

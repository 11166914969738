import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { Category } from '@shared/api/@types/categories';

import DOMPurify from 'dompurify';
import { CategoryIcons } from 'src/context/Categories/Categories.icons';

type Props = {
  category: Category;
  onClose: () => void;
};

const MODAL_WIDTH = 750;

const AboutCategoryModal: React.FC<Props> = ({ category, onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'aboutCategoryModal' });

  return (
    <Modal
      open
      id='restricted-modal'
      sx={{ width: MODAL_WIDTH }}
      onClose={onClose}
      HeaderProps={{ title: t('title'), dismissible: true, divider: true }}
    >
      <Stack spacing={2}>
        <Stack direction='row' spacing={1} alignItems='center'>
          {CategoryIcons(24)[category.name]}
          <Typography fontSize={18} fontWeight={600}>
            {category.name}
          </Typography>
        </Stack>
        <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(category.description) }} />
      </Stack>
    </Modal>
  );
};

export { AboutCategoryModal };

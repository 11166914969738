import { useCallback, useContext, useMemo, useState } from 'react';

import { TradingViewContext } from '../../../context/TradingView.context';
import { chartIndicators } from '../../../types';
import { isAdvancedChartState } from '../../../utils/Tradingview.utils';

type IndicatorItem = {
  text: string;
  hidden?: boolean;
  onClick: () => void;
};

const useTradingViewIndicators = () => {
  const [search, setSearch] = useState<string>('');
  const { chartState } = useContext(TradingViewContext);

  const addIndicator = useCallback(
    (indicator: string) => {
      if (!isAdvancedChartState(chartState)) return;

      chartState?.onChartReady(() => {
        if (!chartState?.chart()) return;

        chartState.chart().createStudy(indicator, false, false);
      });
    },
    [chartState],
  );

  const isIndicatorVisible = (indicator: string, searchValue: string) => {
    if (!searchValue) return true;

    // If we find a matching string return true
    if (indicator.includes(searchValue)) return true;

    // Try splitting each letter typed, and compare with the start of each word in the indicator
    const indicatorSplit = indicator.split(' ');
    for (let i = 0; i < searchValue.length; i++) {
      if (indicatorSplit[i]?.charAt(0) !== searchValue.charAt(i)) return false;
    }
    return true;
  };

  const items: IndicatorItem[] = useMemo(
    (): IndicatorItem[] =>
      chartIndicators.map((indicator) => ({
        text: indicator,
        hidden: !isIndicatorVisible(indicator.toLocaleLowerCase(), search.toLocaleLowerCase()),
        onClick: () => addIndicator(indicator),
      })),
    [addIndicator, search],
  );

  return {
    items,
    search,
    setSearch,
  };
};

export { useTradingViewIndicators };

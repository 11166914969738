import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useTradeValue } from 'src/lib/trade/hooks/General';
import { usePriceScale } from 'src/lib/trade/hooks/General/usePriceScale';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { SwyftxProTradeContext } from '../../context';
import { MarketOcoTab } from '../../types/TradePro.types';
import { useSwyftxProTradeAssets } from '../useSwyftxProTradeAssets';

type Props = {
  tabType: MarketOcoTab;
};

const useSwyftxProTradeStopLoss = ({ tabType }: Props = { tabType: 'price' }) => {
  const { stopLossPrice, setStopLossPrice, triggerPrice, total, tradeType } = useContext(SwyftxProTradeContext);
  const [value, setValue] = useState<string>();
  const [dirty, setDirty] = useState<boolean>();

  const { baseAsset, selectedAsset } = useSwyftxProTradeAssets();
  const { lowestFee } = useCurrentUserFees();
  const { calculatePriceScale } = usePriceScale();

  const { formattedAmount, onChangeValue } = useTradeValue({
    amount: tradeType === 'oco' ? stopLossPrice : value,
    limitAsset: selectedAsset,
    onChange: (val?: string) => {
      if (tradeType === 'oco') {
        setStopLossPrice(val || '');
      } else {
        setValue(val || '');
      }
    },
  });

  useEffect(() => {
    setDirty(false);
  }, [tabType]);

  useEffect(() => {
    if (!triggerPrice || (stopLossPrice && tradeType === 'oco')) return;

    if (selectedAsset && !dirty && tabType === 'price') {
      const triggerMultiple = Big(95).div(100);
      const newStopLossPrice = Big(triggerPrice).times(triggerMultiple.plus(lowestFee)).toString();
      onChangeValue(
        formatCurrency(Big(newStopLossPrice), selectedAsset, {
          hideSeparator: true,
          hideSymbol: true,
          hideCode: true,
        }),
      );
    }
  }, [
    calculatePriceScale,
    dirty,
    lowestFee,
    onChangeValue,
    selectedAsset,
    stopLossPrice,
    tabType,
    tradeType,
    triggerPrice,
  ]);

  useEffect(() => {
    onChangeValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset]);

  const leadingText = useMemo(() => {
    switch (tabType) {
      case 'price':
        return 'SL Price';
      case 'percent':
        return 'Loss Target';
      case 'total':
        return 'Loss Amount';
      default:
        tabType satisfies never;
    }
  }, [tabType]);

  const leadingTooltip = useMemo(() => {
    switch (tabType) {
      case 'price':
        return 'Stop loss price';
      case 'percent':
      case 'total':
        return '';
      default:
        tabType satisfies never;
    }
  }, [tabType]);

  const trailingText = useMemo(() => {
    switch (tabType) {
      case 'price':
      case 'total':
        return baseAsset?.code;
      case 'percent':
        return '%';
      default:
        tabType satisfies never;
    }
  }, [baseAsset?.code, tabType]);

  const resetValues = useCallback(() => {
    onChangeValue('');
    setStopLossPrice('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculatePercentageToPrice = useCallback(
    (percent: string) => {
      if (!percent) {
        setStopLossPrice('');
        return;
      }
      const triggerMultiple = Big(100).minus(percent).div(100);
      const newStopLossPrice = Big(triggerPrice).times(triggerMultiple.plus(lowestFee)).toString();
      setStopLossPrice(newStopLossPrice);
    },
    [lowestFee, setStopLossPrice, triggerPrice],
  );

  const calculateTotalToPrice = useCallback(
    (profitTotal: string) => {
      if (!profitTotal) {
        setStopLossPrice('');
        return;
      }
      const totalPercentage = Big(profitTotal).div(total).times(100);
      const triggerMultiple = Big(100).minus(totalPercentage).div(100);
      const newStopLossPrice = Big(triggerPrice).times(triggerMultiple.plus(lowestFee)).toString();
      setStopLossPrice(newStopLossPrice);
    },
    [lowestFee, setStopLossPrice, total, triggerPrice],
  );

  useEffect(() => {
    if (tradeType === 'oco') return;

    if (!tabType) {
      setStopLossPrice('');
      return;
    }

    // Calculate the take profit price as we type with the various take profit types
    switch (tabType) {
      case 'price':
        setStopLossPrice(value || '');
        break;
      case 'percent':
        calculatePercentageToPrice(value || '');
        break;
      case 'total':
        calculateTotalToPrice(value || '');
        break;
      default:
        tabType satisfies never;
    }
  }, [value, tabType, setStopLossPrice, calculatePercentageToPrice, calculateTotalToPrice, tradeType]);

  return {
    value: formattedAmount,
    dirty,
    setDirty,
    leadingText,
    leadingTooltip,
    trailingText,
    stopLossPrice,
    onChangeValue,
    resetValues,
  };
};

export { useSwyftxProTradeStopLoss };

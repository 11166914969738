import { useCallback } from 'react';

import * as d3 from 'd3';

import { LineChartDatum, LineChartOverlays } from '../LineChart.types';

type Props = {
  idKey: string;
  container: d3.Selection<SVGSVGElement | null, unknown, null, undefined>;
  overlays?: LineChartOverlays;
  valueLine: d3.Line<LineChartDatum>;
  strokeWidth: number;
};

const useBuildOverlays = ({ idKey, container, overlays = [], valueLine }: Props) => {
  const buildOverlays = useCallback(() => {
    overlays?.forEach((overlay) => {
      container
        .select(`#overlays-${idKey}`)
        .select(`#overlay-${overlay.id}`)
        .datum(overlay.data)
        .attr('fill', 'none')
        .attr('stroke', overlay.color)
        .attr('stroke-opacity', 0.5)
        .attr('stroke-width', 1)
        .attr('d', valueLine(overlay.data));
    });
  }, [container, idKey, overlays, valueLine]);

  return {
    buildOverlays,
  };
};

export { useBuildOverlays };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import { useSourceOfWealthSelector } from '@SourceOfWealth/SourceOfWealth.context';
import { sourceOfWealthMachine } from '@SourceOfWealth/SourceOfWealth.machine';
import { StateNode } from 'xstate';

import { SourceOfWealthStepperItem } from './SourceOfWealthStepperItem';
import { transformSourceOfWealthStates } from './stepperUtils';

const SourceOfWealthStepper: React.FC = () => {
  const transformedSteps = useMemo(
    () => transformSourceOfWealthStates(sourceOfWealthMachine.states as Record<string, StateNode>),
    [],
  );

  const [activeRootStepId, activeStepId] = useSourceOfWealthSelector((state) => state.toStrings());

  const activeStepOrder = useMemo<number>(() => {
    const activeRootStep = transformedSteps.find((s) => s.id === activeRootStepId);

    if (!activeRootStep) return 0;

    if (!activeStepId) return activeRootStep.order;

    const activeStep = activeRootStep.children.find((s) => s.id === activeStepId);

    if (!activeStep) return activeRootStep.order;

    return activeStep.order;
  }, [transformedSteps, activeRootStepId, activeStepId]);

  return (
    <FlexLayout direction='column' spacing={16}>
      <Heading size='h5'>Source of wealth check</Heading>
      <div>
        {transformedSteps.map((step) => (
          <SourceOfWealthStepperItem key={step.id} step={step} activeOrder={activeStepOrder} root />
        ))}
      </div>
    </FlexLayout>
  );
};

export { SourceOfWealthStepper };

import { CashDepositPercentageOption } from '@shared/api/@types/compliance';

import { SourceOfWealthData } from '../types/SourceOfWealth.types';

/**
 * Used before submitting a source of wealth. Sometimes we can have pre-existing fields
 * from the context that do not match with the ones from the current event.
 */
export const mergeAndStripExclusiveFields = (
  contextData: Partial<SourceOfWealthData>,
  eventData?: Partial<SourceOfWealthData>,
) => {
  if (!eventData) return contextData;

  if (
    'cashDepositsPercentage' in eventData &&
    eventData.cashDepositsPercentage === CashDepositPercentageOption.LESS_THAN_20
  ) {
    delete eventData.cashDepositsOrigin;
    delete contextData.cashDepositsOrigin;
  }

  if ('expectedAnnualSpend' in eventData && 'expectedAnnualSpendOther' in contextData) {
    delete contextData.expectedAnnualSpendOther;
  }
  if ('expectedAnnualSpendOther' in eventData && 'expectedAnnualSpend' in contextData) {
    delete contextData.expectedAnnualSpend;
  }
  return { ...contextData, ...eventData };
};

export enum WithdrawalStatusEnum {
  PENDING = 1,
  CREDITED = 2,
  FAILED_REJECTED = 3, // Failed
  FAILED_UNKNOWN = 4, // Failed
  FAILED_MIN_WITHDRAWAL = 5, // Failed
  PENDING_FIAT_FX = 6,
  PENDING_DAILY_LIMIT = 7,
  PENDING_NOT_CREDITED = 8,
  PENDING_EXTENDED_VERIFICATION = 9,
  REFUNDED = 10, // Refuneded
  PENDING_DISABLED = 11,
  NOT_VERIFIED = 15,
  PROCESSING = 16,
  PENDING_LIQUIDITY = 17,
  BOUNCED = 18, // Refunded
}

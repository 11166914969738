import { createContext, useContext } from 'react';

import { createTradeUtilityStore } from '@routes/Trade/components/Trade.store';

// TODO proper types
export const TradeUtilityContext = createContext<ReturnType<typeof createTradeUtilityStore> | undefined>(undefined);

export const useUniversalTradeUtilityStore = () => {
  const context = useContext(TradeUtilityContext);
  if (context === undefined) {
    throw Error('useUniversalTrade must be used within a TradeContext provider');
  }
  return context;
};

import { api, TradePriceHistory } from '@shared/api';
import { CronJobService } from '@shared/cron';
import { TimesEnum } from '@shared/enums';
import handleError, { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { UserStore } from '@shared/store';

const logTag = 'PORTFOLIO_SERVICE';

class PortfolioService extends CronJobService {
  async update() {
    const { setTradePriceHistory } = UserStore.useUserStore;

    try {
      const { userBaseCurrency } = UserStore.useUserStore;

      api.endpoints.getTradePriceHistory.resetCache();
      const results = await api.endpoints.getTradePriceHistory({ params: { denotedAssetId: userBaseCurrency } });

      const tradePriceHistory = results.data as TradePriceHistory;

      setTradePriceHistory(tradePriceHistory);
    } catch (ex) {
      // ignore if known
      if (!(ex as SwyftxError).isSwyftxError) {
        handleError(logTag, 'Failed to update portfolio history', ex);
      }
    }
  }
}

export const portfolioService = new PortfolioService(TimesEnum.MINUTE);

// [dev] When hot reloading remove the cron
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const module: any;
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    logger.debug(logTag, 'Hot reload cleanup');
    portfolioService.cleanup();
  });
}

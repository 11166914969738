import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { NoChartError } from '@global-components/Errors/NoChartError';

import { observer } from 'mobx-react-lite';

import { ComingSoonChart } from '../ComingSoonChart';

type Props = {
  width: number;
  height: number;
  bgColor?: string;
};

export const NoHistoryChart: React.FC<Props> = observer(({ width, height, bgColor }) => {
  const { t } = useTranslation('common', { keyPrefix: 'chartError' });
  const theme = useTheme();

  return (
    <ComingSoonChart
      height={height}
      width={width}
      bgColor={bgColor}
      customError={
        <NoChartError
          subTitle={t('noHistoryLabel')}
          containerProps={{
            bgcolor: theme.palette.background.paper,
            borderRadius: theme.spacing(2),
            border: '1px solid',
            borderColor: 'divider',
          }}
        />
      }
    />
  );
});

import { useCallback, useState } from 'react';

import { api } from '@shared/api';
import { CreateAlertParams } from '@shared/api/@types/alerts';

import * as Sentry from '@sentry/react';

import { useFetchDynamicPriceAlertAssetCountsCache } from './useFetchDynamicPriceAlertAssetCounts/useFetchDynamicPriceAlertAssetCountsCache';
import { useFetchDynamicPriceAlertsCache } from './useFetchDynamicPriceAlerts';
import { CreateLimitAlertData, CreateVolatilityAlertData } from '../dynamicPriceAlerts.types';

const useCreatePriceAlert = () => {
  const [creatingAlert, setCreatingAlert] = useState<boolean>(false);
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();
  const { invalidateCache: invalidateCountsCache } = useFetchDynamicPriceAlertAssetCountsCache();

  const createDynamicPriceAlert = useCallback(
    async (params: CreateAlertParams, data: CreateLimitAlertData | CreateVolatilityAlertData) => {
      try {
        setCreatingAlert(true);
        const response = await api.endpoints.createPriceAlert({ params, data });
        invalidateCache();
        invalidateCountsCache();
        return response.data;
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      } finally {
        setCreatingAlert(false);
      }
    },
    [invalidateCache, invalidateCountsCache],
  );

  return { createDynamicPriceAlert, creatingAlert };
};

export { useCreatePriceAlert };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const PortfolioTransfer: React.FC<Props> = ({ className }) => (
  // This icon isn't center aligned properly, this gets us closer
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.96472 3.42188C5.5604 3.42188 4.36272 3.92395 3.51979 4.82825C2.68231 5.7267 2.25 6.96225 2.25 8.33895V14.9257C2.25 16.3015 2.68008 17.5372 3.51692 18.4361C4.35939 19.341 5.55745 19.8428 6.96472 19.8428H10.2912C10.7054 19.8428 11.0412 19.507 11.0412 19.0928C11.0412 18.6786 10.7054 18.3428 10.2912 18.3428H6.96472C5.92797 18.3428 5.14367 17.9821 4.61479 17.414C4.08027 16.8398 3.75 15.992 3.75 14.9257V10.2207H20.2493V11.6324C20.2493 12.0466 20.5851 12.3824 20.9993 12.3824C21.4135 12.3824 21.7493 12.0466 21.7493 11.6324V8.33895C21.7493 6.96313 21.3192 5.72747 20.4824 4.82859C19.6399 3.92366 18.4419 3.42188 17.0346 3.42188H6.96472ZM20.2493 8.7207V8.33895C20.2493 7.27271 19.919 6.42483 19.3845 5.85069C18.8556 5.2826 18.0713 4.92188 17.0346 4.92188H6.96472C5.93183 4.92188 5.14715 5.28231 4.61703 5.85103C4.08145 6.4256 3.75 7.27359 3.75 8.33895V8.7207H20.2493Z'
      fill='currentColor'
    />
    <path
      d='M20.0138 14.2617C20.0138 13.8475 19.678 13.5117 19.2638 13.5117C18.8496 13.5117 18.5138 13.8475 18.5138 14.2617V18.0595L18.0461 17.6085C17.7479 17.321 17.2731 17.3297 16.9856 17.6279C16.6981 17.9261 16.7068 18.4009 17.0049 18.6884L18.7069 20.3293C18.8441 20.4813 19.0428 20.5769 19.2638 20.5769C19.4887 20.5769 19.6904 20.478 19.8279 20.3212L21.5205 18.6882C21.8186 18.4006 21.8272 17.9258 21.5396 17.6277C21.252 17.3296 20.7772 17.3211 20.4791 17.6087L20.0138 18.0576V14.2617Z'
      fill='currentColor'
    />
    <path
      d='M14.6642 13.0156C14.4548 13.0156 14.2655 13.1014 14.1295 13.2397L12.4071 14.9013C12.109 15.1888 12.1005 15.6636 12.3881 15.9617C12.6757 16.2599 13.1505 16.2684 13.4486 15.9808L13.9142 15.5316V19.3308C13.9142 19.745 14.25 20.0808 14.6642 20.0808C15.0784 20.0808 15.4142 19.745 15.4142 19.3308V15.5303L15.8816 15.9809C16.1798 16.2684 16.6546 16.2598 16.9421 15.9616C17.2296 15.6634 17.2209 15.1886 16.9227 14.9011L15.1851 13.2257C15.0398 13.0856 14.8519 13.0156 14.6642 13.0156Z'
      fill='currentColor'
    />
  </svg>
);

export { PortfolioTransfer };

import { useCallback, useMemo } from 'react';

import { getPriceScale } from '@swyftx/currency-util';

import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { EntityId, IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  tvWidget?: IChartingLibraryWidget;
  secondary: string;
};

const LIMIT_PRICE_LINE_LENGTH = 1;
const OCO_PRICE_LINE_LENGTH = 3;
export const TRADE_PRICE_LINE_TITLE = 'order_price_line';
export const OPEN_ORDER_LINE_TITLE = 'open_order_price_line';
type Shape = 'horizontal_line' | 'long_position';

const useBuildPriceLine = ({ tvWidget, secondary }: Props) => {
  const { getAssetByCode } = useMarkets();
  const secondaryAsset = useMemo(() => getAssetByCode(secondary), [getAssetByCode, secondary]);

  const buildPriceLine = useCallback(
    (shape: Shape, price: number) => {
      try {
        if (!tvWidget) return null;

        return tvWidget.chart().createShape(
          { time: DateTime.now().toMillis(), price },
          {
            shape,
          },
        );
      } catch (e) {
        Sentry.captureException(e);
        return null;
      }
    },
    [tvWidget],
  );

  const buildPriceLines = useCallback(
    (priceLines?: string[]) => {
      if (!tvWidget || !priceLines || !secondaryAsset) return null;

      let priceLine = null;
      let color = undefined;

      if (priceLines.length === LIMIT_PRICE_LINE_LENGTH) {
        // Create standard horizontal line if there is only one price line
        color = '#0072ED';
        priceLine = tvWidget.chart().createShape(
          { time: DateTime.now().toMillis(), price: Number(priceLines[0]) },
          {
            shape: 'horizontal_line',
          },
        );
        if (priceLine) {
          const shape = tvWidget.activeChart().getShapeById(priceLine);
          shape.setProperties({
            text: 'Preview: Trigger price',
            title: TRADE_PRICE_LINE_TITLE,
            horzLabelsAlign: 'left',
            showLabel: true,
            linecolor: color,
            textcolor: color,
            bold: false,
            lineStyle: 1,
          });
        }
      } else if (priceLines.length === OCO_PRICE_LINE_LENGTH) {
        priceLine = buildPriceLine('long_position', Number(priceLines[0]));

        if (priceLine) {
          const priceScale = 10 ** getPriceScale(priceLines[0]);
          const shape = tvWidget.activeChart().getShapeById(priceLine);

          shape.setProperties({
            title: TRADE_PRICE_LINE_TITLE,
            profitLevel: Big(priceLines[1]).minus(priceLines[0]).times(priceScale).toNumber(),
            stopLevel: Big(priceLines[0]).minus(priceLines[2]).abs().times(priceScale).toNumber(),
          });
        }
      }

      return priceLine;
    },
    [buildPriceLine, secondaryAsset, tvWidget],
  );

  const clearPriceLines = useCallback(
    (title: string) => {
      if (!tvWidget) return;

      tvWidget.onChartReady(() => {
        tvWidget
          ?.activeChart()
          ?.getAllShapes()
          .forEach((e) => {
            const shape = tvWidget.activeChart().getShapeById(e.id);
            if (shape.getProperties().title === title) {
              tvWidget.activeChart().removeEntity(e.id);
            }
          });
      });
    },
    [tvWidget],
  );

  const removePriceLine = useCallback(
    (priceLineEntityId: EntityId) => {
      if (!tvWidget) return;

      tvWidget.activeChart().removeEntity(priceLineEntityId);
    },
    [tvWidget],
  );

  return {
    clearPriceLines,
    removePriceLine,
    buildPriceLine,
    buildPriceLines,
  };
};

export { useBuildPriceLine, LIMIT_PRICE_LINE_LENGTH, OCO_PRICE_LINE_LENGTH };

import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import Dashboard from '@Dashboard/Dashboard';

import { observer } from 'mobx-react-lite';
import { DashboardPage } from 'src/lib/dashboard/DashboardPage';
import { useDashboardLayout } from 'src/lib/dashboard/hooks/useDashboardLayout';
import { NavigationURLs } from 'src/lib/navigation/types';

const containerName = 'dashboard-page';

export const DashboardRoutePage: React.FC = observer(() => {
  const { layout } = useDashboardLayout();

  return (
    <>
      {layout && layout.key !== 'classic' ? (
        <DashboardPage layout={layout} containerName={containerName} />
      ) : (
        <Dashboard />
      )}
    </>
  );
});

export const DashboardRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Dashboard,
    element: <PrivateRoute element={<DashboardRoutePage />} />,
  },
];

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

interface Props {
  asset: Asset;
}

export const PriceAlertsModalHeader: React.FC<Props> = (props) => {
  const { asset } = props;

  return (
    <FlexLayout direction='row' className='items-center' spacing={12}>
      <AssetIcon asset={asset} size={32} />
      <Heading size='h4'>{`Your ${asset.code} price alerts`}</Heading>
    </FlexLayout>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const AddInCircle: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M7.24994 11.9996C7.24994 11.5854 7.58573 11.2496 7.99994 11.2496H11.2499V7.99963C11.2499 7.58542 11.5857 7.24963 11.9999 7.24963C12.4142 7.24963 12.7499 7.58542 12.7499 7.99963V11.2496H15.9999C16.4142 11.2496 16.7499 11.5854 16.7499 11.9996C16.7499 12.4138 16.4142 12.7496 15.9999 12.7496H12.7499V15.9996C12.7499 16.4138 12.4142 16.7496 11.9999 16.7496C11.5857 16.7496 11.2499 16.4138 11.2499 15.9996V12.7496H7.99994C7.58573 12.7496 7.24994 12.4138 7.24994 11.9996Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9999 21.9996C6.47778 21.9996 1.99994 17.5229 1.99994 11.9996C1.99994 6.47742 6.47773 1.99963 11.9999 1.99963C17.5222 1.99963 21.9999 6.47742 21.9999 11.9996C21.9999 17.5229 17.5221 21.9996 11.9999 21.9996ZM3.49994 11.9996C3.49994 16.6944 7.3061 20.4996 11.9999 20.4996C16.6938 20.4996 20.4999 16.6944 20.4999 11.9996C20.4999 7.30585 16.6937 3.49963 11.9999 3.49963C7.30615 3.49963 3.49994 7.30585 3.49994 11.9996Z'
      fill='currentColor'
    />
  </svg>
);

export { AddInCircle };

import React from 'react';

import { Asset } from '@shared/api';

import { usePagesSearchModule } from './usePagesSearchModule';
import { GlobalSearchCategory } from '../../components/GlobalSearchCategory';
import { useFilterSearchModuleItems } from '../../hooks';

type Props = {
  searchValue: string;
  assets: Asset[];
};

const PagesModuleSearchCategory: React.FC<Props> = ({ searchValue, assets }) => {
  const pageItems = usePagesSearchModule({ assets });
  const filteredItems = useFilterSearchModuleItems({ searchValue, items: pageItems });

  if (!filteredItems.length || !searchValue) return null;

  return <GlobalSearchCategory title='Pages' items={filteredItems} />;
};

export { PagesModuleSearchCategory };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { AppSideBarItemType } from './AppSideBar.types';

type Props = {
  item: AppSideBarItemType;
  className?: string;
};

const AppSideBarItem: React.FC<Props> = ({ item, className }) => {
  if (item.hidden) return null;

  return (
    <FlexLayout
      direction='column'
      spacing={16}
      className={cn('md: px-8 pr-16 md:pr-8', className)}
      data-spotlightelementid={`nav_item_${item.title.toLocaleLowerCase().replace(' ', '_')}`}
    >
      <FlexLayout
        className={cn(
          'w-[calc(var(--sidebar-width-desktop-opened)-16px] h-48 cursor-pointer rounded-8 px-8',
          item.active
            ? 'bg-color-background-primary-subtle text-color-text-accent'
            : 'text-color-text-primary hover:bg-color-background-surface-hover',
          item.disabled ? 'cursor-not-allowed bg-color-background-surface-disabled opacity-50' : '',
          item.className,
        )}
        alignItems='center'
        justifyContent='space-between'
        spacing={24}
        onClick={() => !item.disabled && item.onClick()}
      >
        <FlexLayout alignItems='center' spacing={24}>
          {item.icon}
          <Body weight={item.active ? 'emphasis' : 'none'} className='whitespace-nowrap'>
            {item.title}
          </Body>
        </FlexLayout>
        {item.trailing}
      </FlexLayout>
    </FlexLayout>
  );
};

export { AppSideBarItem };

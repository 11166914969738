import en from './register.en.json';
import fr from './register.fr.json';

const RegisterTranslations = {
  en,
  fr,
};

const RegisterTranslationKey = 'register';

export { RegisterTranslations, RegisterTranslationKey };

import React, { useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { OrderType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { useTransactionOrderType } from 'src/lib/transactions/hooks/useTransactionOrderType';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';
import { InfoTooltip } from 'src/lib/utils/components';

import { useSwyftxProTradeAmount } from '../../hooks/useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from '../../hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeStopLossDetails } from '../../hooks/useSwyftxProTradeStopLoss';
import { useSwyftxProTradeTakeProfitDetails } from '../../hooks/useSwyftxProTradeTakeProfit';
import { useSwyftxProTradeType } from '../../hooks/useSwyftxProTradeType';

const SwyftxProOrderConfirmationModalOcoDetails: React.FC = observer(() => {
  const { selectedAsset, baseAsset, sellAsset, buyAsset } = useSwyftxProTradeAssets();
  const { amount, total } = useSwyftxProTradeAmount();
  const { lowestFee } = useCurrentUserFees();
  const { stopLossTotalLoss, stopLossPercentage, stopLossPrice } = useSwyftxProTradeStopLossDetails();
  const { takeProfitTotalProfit, takeProfitPercentage, takeProfitPrice } = useSwyftxProTradeTakeProfitDetails();
  const { isMarket } = useSwyftxProTradeType();
  const { parseOrderTypeName } = useTransactionOrderType();

  const buildOCOOrderDetails = useCallback(
    (orderType: OrderType, priceTitle: string, price = '0', profit?: Big, percentage?: Big) => {
      const ocoTotal = Big(amount).times(price);
      const amountFee = Big(amount).times(lowestFee);
      const isProfit = percentage?.gte(0);

      return (
        <FlexLayout direction='column' spacing={16}>
          <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
            <Body weight='emphasis'>Order type</Body>
            <Body color='secondary'>{parseOrderTypeName(orderType)}</Body>
          </FlexLayout>
          {!isMarket && (
            <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
              <Body weight='emphasis'>Amount</Body>
              <Body color='secondary'>
                {formatCurrency(amount, selectedAsset, { appendCode: true, hideCode: false })}
              </Body>
            </FlexLayout>
          )}
          <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
            <Body weight='emphasis'>{priceTitle}</Body>
            <Body color='secondary'>{formatCurrency(price, baseAsset, { appendCode: true, hideCode: false })}</Body>
          </FlexLayout>
          {!isMarket && (
            <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
              <FlexLayout alignItems='center' spacing={8}>
                <Body weight='emphasis'>Estimated Fees</Body>

                {amountFee && (
                  <InfoTooltip
                    tooltip={formatCurrency(amountFee, sellAsset, {
                      appendCode: true,
                      hideCode: false,
                    })}
                  />
                )}
              </FlexLayout>
              <Body color='secondary'>
                {formatCurrency(ocoTotal.times(lowestFee), baseAsset, {
                  appendCode: true,
                  hideCode: false,
                  isApproximate: true,
                })}
              </Body>
            </FlexLayout>
          )}
          {!isMarket && (
            <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
              <Body weight='emphasis'>Total</Body>
              <Body color='secondary'>
                {formatCurrency(ocoTotal, baseAsset, { appendCode: true, hideCode: false, isApproximate: true })}
              </Body>
            </FlexLayout>
          )}
          {isMarket && profit && percentage && (
            <FlexLayout className='w-full' alignItems='center' justifyContent='space-between'>
              <Body weight='emphasis'>{isProfit ? 'Estimated profit' : 'Estimated loss'}</Body>
              <Body color={isProfit ? 'success' : 'error'}>
                {`≈(${percentage.toFixed(2)}%) ${formatCurrency(profit, baseAsset, {
                  appendCode: true,
                  hideCode: false,
                })}`}
              </Body>
            </FlexLayout>
          )}
        </FlexLayout>
      );
    },
    [amount, baseAsset, isMarket, lowestFee, parseOrderTypeName, selectedAsset, sellAsset, total],
  );

  if (!selectedAsset || !buyAsset || !sellAsset) return null;

  return (
    <FlexLayout direction='column' spacing={16}>
      {takeProfitPrice && (
        <>
          {buildOCOOrderDetails(
            OrderType.TriggerSell,
            'Take profit price',
            takeProfitPrice,
            takeProfitTotalProfit,
            takeProfitPercentage,
          )}
          <div className='h-1 bg-color-border-main' />
        </>
      )}
      {stopLossPrice &&
        buildOCOOrderDetails(
          OrderType.StopSell,
          'Stop loss price',
          stopLossPrice,
          stopLossTotalLoss,
          stopLossPercentage,
        )}
    </FlexLayout>
  );
});

export { SwyftxProOrderConfirmationModalOcoDetails };

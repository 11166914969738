/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import FormControlMUI, { FormControlProps } from '@mui/material/FormControl';
import FormHelperTextMUI from '@mui/material/FormHelperText';
import MenuItemMUI from '@mui/material/MenuItem';
import SelectMUI, { SelectProps } from '@mui/material/Select';
import TypographyMUI from '@mui/material/Typography';

import { ArrowDownIcon } from './assets';
import { getPIIClassName } from '../global-utils';
import { PIITypes } from '../types/PII.types';

export type SelectItem = {
  value: string;
  label: string;
};

type Props = {
  items: SelectItem[];
  error?: boolean;
  helperText?: string;
  success?: boolean;
  formControlProps?: FormControlProps; // Container props
} & SelectProps<string> &
  PIITypes;

const Icon: React.FC = (params) => <ArrowDownIcon {...params} />;

const Select: React.FC<Props> = ({ id, items, error, label, helperText, formControlProps, PII, ...props }) => (
  <FormControlMUI className={getPIIClassName(PII)} error={error} {...formControlProps}>
    {label && (
      <TypographyMUI fontWeight={600} fontSize={12} marginBottom='4px' fontFamily='Public Sans'>
        {label}
      </TypographyMUI>
    )}
    <SelectMUI
      id={id}
      {...props}
      variant='outlined'
      IconComponent={Icon}
      sx={{
        ...props.sx,
        '.MuiFormHelperText-root': {
          marginLeft: 0,
        },
        fieldset: {
          borderColor: (theme) => `${theme.palette.success.main} !important`,
        },
      }}
    >
      {items.map((item: SelectItem) => (
        <MenuItemMUI key={item.value} value={item.value}>
          {item.label}
        </MenuItemMUI>
      ))}
    </SelectMUI>
    {helperText && <FormHelperTextMUI sx={{ marginLeft: 0 }}>{helperText}</FormHelperTextMUI>}
  </FormControlMUI>
);

export default Select;

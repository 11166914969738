import React, { useCallback, useEffect, useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { Loading, PriceAlert } from '@swyftx/aviary/icons/outlined';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { PriceAlertEmpty } from 'src/lib/dynamic-price-alerts/components/PriceAlertEmpty/PriceAlertEmpty';
import { PriceAlertTriggerType, PriceAlertType } from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import { useFetchDynamicTriggeredPriceAlerts } from 'src/lib/dynamic-price-alerts/hooks';
import { useDismissTriggerNotifications } from 'src/lib/dynamic-price-alerts/hooks/useDismissTriggerNotifications';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DynamicPriceAlertTableData, useDynamicTriggeredPriceAlertsTable } from './useDynamicTriggeredPriceAlertsTable';

type Props = {
  currentTab: PriceAlertType;
  filterTypes: PriceAlertTriggerType[];
  filterAssets: Asset[];
  triggers?: number[];
  onRemoveAllFilters: () => void;
};

const TRIGGER_LIMIT = 50;

const DynamicPriceAlertsTriggeredTable: React.FC<Props> = observer(
  ({ filterAssets, filterTypes, triggers, onRemoveAllFilters }) => {
    const {
      data: alerts,
      isFetching,
      fetchNextPage,
      isFetchingNextPage,
    } = useFetchDynamicTriggeredPriceAlerts({
      secondary: filterAssets.map((a) => a.id),
      type: filterTypes?.length === 1 ? filterTypes[0] : undefined,
      limit: TRIGGER_LIMIT,
    });

    const { headers, data, onSort, initialSort } = useDynamicTriggeredPriceAlertsTable({ alerts });

    const { navigate } = useNavigateRoute();
    const { setTradeAssets } = UniversalTradeStore;
    const baseAsset = useBaseAsset();

    const { dismissNotifications } = useDismissTriggerNotifications();

    useEffect(() => {
      dismissNotifications(triggers);
    }, [dismissNotifications, triggers]);

    const onClickItem = useCallback(
      (item: EnhancedTableData<DynamicPriceAlertTableData, unknown>) => {
        if (!item.value) return;
        if (item.asset.value) setTradeAssets([Number(item.asset.value)], TradeSide.To, TradeAssetAction.Replace);
        if (baseAsset) setTradeAssets([baseAsset.id], TradeSide.From, TradeAssetAction.Replace);
        navigate(NavigationURLs.UniversalTradeAsset, { pathParams: { asset: item.value } });
      },
      [baseAsset, navigate, setTradeAssets],
    );

    const onScrollTable = useCallback(
      (e: Event) => {
        if (isFetching || isFetchingNextPage) return;

        const element = e.target as HTMLElement;
        const scrollHeight = element.scrollHeight - element.clientHeight;
        const scrollPos = element.scrollTop;

        if (Big(scrollHeight).minus(scrollPos).lte(120)) {
          fetchNextPage();
        }
      },
      [fetchNextPage, isFetchingNextPage, isFetching],
    );

    const hasFiltersActive = useMemo(() => {
      if (!alerts?.length) return false;
      return filterAssets.length > 0 || filterTypes.length > 0;
    }, [alerts?.length, filterAssets.length, filterTypes.length]);

    return (
      <Card className='max-h-[calc(100vh-21rem)] w-full overflow-hidden md:max-h-[calc(100vh-28rem)]'>
        <FlexLayout alignItems='center' spacing={8} className='border-b border-color-border-main px-24 py-16'>
          <Icon icon={<PriceAlert />} className='h-24 w-24' color='info' />
          <Heading size='h6' weight='normal'>
            History
          </Heading>
        </FlexLayout>
        <EnhancedTable<DynamicPriceAlertTableData>
          data-container='assets-table'
          headers={headers}
          groupBy='lastTriggered'
          sort={initialSort}
          data={data}
          onSort={onSort}
          onScroll={onScrollTable}
          onClickItem={onClickItem}
          lazyLoad
          itemsPerPage={20}
        >
          <FlexLayout className='w-full p-16' alignItems='center' justifyContent='center'>
            {isFetching ? (
              <Loading className='animate-spin text-color-text-primary' />
            ) : (
              <PriceAlertEmpty
                alertType='triggered'
                filtersActive={hasFiltersActive}
                onRemoveAllFilters={onRemoveAllFilters}
              />
            )}
          </FlexLayout>
        </EnhancedTable>
      </Card>
    );
  },
);

export { DynamicPriceAlertsTriggeredTable };

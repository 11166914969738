import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Information } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api/@types/markets';

type Props = {
  asset: Asset;
};

const SingleWalletUnsupported: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'singleWalletUnsupported' });
  return (
    <Card className='p-16'>
      <FlexLayout direction='row' spacing={8}>
        <Information className='text-color-text-accent' />
        <FlexLayout direction='column' spacing={4}>
          <Body color='primary' weight='bold'>
            {t('labels.title', { assetName: asset.name })}
          </Body>
          <Body color='secondary'>{t('labels.subTitle', { assetCode: asset.code })}</Body>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export { SingleWalletUnsupported };

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Abn } from '@swyftx/aviary/icons/outlined';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

const AffiliateUpdateAbn: React.FC = observer(() => {
  const { affiliateInfo } = useContext(ProfileContext);
  const { openModal } = useModal();
  const { t } = useTranslation('profile.affiliates');

  // TODO update card wording once we have the correct wording from finance
  let cardContent = t('updateAbnCard.content.abnProvided');
  let chipLabel = t('updateAbnCard.chipLabels.supplied');
  // TODO move to types instead of strings
  switch (affiliateInfo?.disqualifiedReason) {
    case 'ABN_DETAILS_REQUIRED':
      cardContent = t('updateAbnCard.content.noAbn');
      chipLabel = t('updateAbnCard.chipLabels.update');
      break;
    case 'INCORRECT_GST_STATUS':
      cardContent = t('updateAbnCard.content.incorrectGst');
      chipLabel = t('updateAbnCard.chipLabels.update');
      break;
    case 'LOW_INCOME_THRESHOLD':
      cardContent = t('updateAbnCard.content.lowIncome');
      chipLabel = t('updateAbnCard.chipLabels.update');
      break;
    default:
      cardContent = t('updateAbnCard.content.abnProvided');
      chipLabel = t('updateAbnCard.chipLabels.supplied');
  }

  return (
    <FlexLayout direction='column' className='PII p-8'>
      <SettingsItem
        key='updateAbn'
        title={t('updateAbnCard.subTitle')}
        description={cardContent}
        icon={<Abn className='h-[25px] w-[25px]' />}
        action={
          <>
            {!affiliateInfo?.disqualifiedReason && (
              <Chip
                id='verifyAbn'
                color={affiliateInfo?.disqualifiedReason ? 'destructive' : 'success'}
                variant='subtle'
                size='sm'
              >
                {chipLabel}
              </Chip>
            )}
            {affiliateInfo?.disqualifiedReason && (
              <Button onClick={() => openModal(Modals.AffiliateSignUp, { isUpdate: true })}>
                {t('updateAbnCard.buttonLabels.updateDetails')}
              </Button>
            )}
          </>
        }
      />
    </FlexLayout>
  );
});

export { AffiliateUpdateAbn };

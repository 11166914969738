import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Notification, Typography } from '@swyftx/react-web-design-system';

import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  direction: 'withdraw' | 'deposit';
};

const CryptoTransferWarning = ({ direction }: Props) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const onUpgrade = () => {
    navigate(NavigationURLs.ProfileVerification);
  };

  return (
    <Notification
      severity='warning'
      title={t(`cryptoTransferWarning.${direction}.title`)}
      sx={{ marginX: 1, marginTop: 1, marginBottom: 0.2, color: 'warning.dark' }}
    >
      <Typography fontSize={14} fontWeight={400}>
        {t(`cryptoTransferWarning.${direction}.message`)}
      </Typography>
      <Typography
        sx={{ cursor: 'pointer', marginTop: '16px' }}
        fontSize={14}
        fontWeight={500}
        color='warning.dark'
        onClick={onUpgrade}
      >
        {t('cryptoTransferWarning.button')}
      </Typography>
    </Notification>
  );
};

export { CryptoTransferWarning };

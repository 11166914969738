import React, { useMemo } from 'react';

import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';

type Props = {
  countryAsset: Asset;
  severity?: 'warning' | 'info';
  orderIsSameDay?: boolean;
  showActions?: boolean;
};

const AutoInvestBalanceWarning: React.FC<Props> = ({
  severity = 'warning',
  orderIsSameDay = true,
  countryAsset,
  showActions = true,
}) => {
  const { openModal } = useModal();

  const title = useMemo(() => {
    if (severity === 'info') {
      return 'Check your balances';
    }

    if (!orderIsSameDay) {
      return 'Insufficient balance';
    }

    return `Insufficent ${countryAsset.code} balance to process your first order`;
  }, [severity, orderIsSameDay, countryAsset.code]);

  const content = useMemo(() => {
    if (severity === 'info') {
      return `Please ensure you have sufficient funds in your ${countryAsset.code} Wallet prior to the first scheduled processing time.`;
    }

    if (!orderIsSameDay) {
      return `Your ${countryAsset.code} wallet has insufficient funds to execute your first order. Please deposit funds now to avoid order failure.`;
    }

    return 'To avoid your first order failing, please deposit funds now or select a later start date prior to progressing.';
  }, [severity, orderIsSameDay, countryAsset.code]);

  return (
    <Notification
      severity={severity}
      title={title}
      actions={
        showActions ? (
          <Body
            className='cursor-pointer hover:underline'
            weight='emphasis'
            size='small'
            color='accent'
            onClick={() => openModal(Modals.DepositReceive, { selectedAsset: countryAsset })}
          >
            Deposit funds
          </Body>
        ) : undefined
      }
    >
      {content}
    </Notification>
  );
};

export { AutoInvestBalanceWarning };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DiscountFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.3992 9.14635L21.1194 9.86635C21.6897 10.4264 21.9998 11.1864 21.9998 11.9864C22.0098 12.7864 21.6997 13.5474 21.1395 14.1164C21.1328 14.1237 21.1261 14.1301 21.1194 14.1366C21.1161 14.1398 21.1128 14.143 21.1094 14.1464L20.3992 14.8564C20.1191 15.1364 19.959 15.5164 19.959 15.9174V16.9464C19.959 18.6064 18.6085 19.9574 16.9479 19.9574H15.9176C15.5174 19.9574 15.1373 20.1164 14.8572 20.3964L14.1369 21.1164C13.5467 21.7074 12.7764 21.9964 12.0061 21.9964C11.2359 21.9964 10.4656 21.7074 9.87537 21.1274L9.14511 20.3964C8.865 20.1164 8.48487 19.9574 8.08472 19.9574H7.05435C5.39375 19.9574 4.04326 18.6064 4.04326 16.9464V15.9174C4.04326 15.5164 3.8832 15.1364 3.6031 14.8464L2.88284 14.1364C1.71241 12.9674 1.70241 11.0564 2.87283 9.87735L3.6031 9.14635C3.8832 8.86635 4.04326 8.48635 4.04326 8.07635V7.05635C4.04326 5.39635 5.39375 4.04735 7.05435 4.04735H8.08472C8.48487 4.04735 8.865 3.88635 9.14511 3.60635L9.86537 2.88635C11.0358 1.70735 12.9465 1.70735 14.1269 2.87735L14.8572 3.60635C15.1373 3.88635 15.5174 4.04735 15.9176 4.04735H16.9479C18.6085 4.04735 19.959 5.39635 19.959 7.05635V8.08735C19.959 8.48635 20.1191 8.86635 20.3992 9.14635ZM9.42521 15.4464C9.66529 15.4464 9.88537 15.3564 10.0454 15.1864L15.1873 10.0474C15.5274 9.70736 15.5274 9.14635 15.1873 8.80635C14.8472 8.46735 14.297 8.46735 13.9569 8.80635L8.81499 13.9464C8.47486 14.2864 8.47486 14.8464 8.81499 15.1864C8.97504 15.3564 9.19512 15.4464 9.42521 15.4464ZM13.6968 14.5664C13.6968 15.0564 14.0869 15.4464 14.5771 15.4464C15.0572 15.4464 15.4474 15.0564 15.4474 14.5664C15.4474 14.0874 15.0572 13.6964 14.5771 13.6964C14.0869 13.6964 13.6968 14.0874 13.6968 14.5664ZM9.43521 8.55635C9.91539 8.55635 10.3055 8.94635 10.3055 9.42635C10.3055 9.91736 9.91539 10.3064 9.43521 10.3064C8.95504 10.3064 8.55489 9.91736 8.55489 9.42635C8.55489 8.94635 8.95504 8.55635 9.43521 8.55635Z'
      fill='currentColor'
    />
  </svg>
);

export { DiscountFilled };

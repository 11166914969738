import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Copy } from '@swyftx/aviary/icons/outlined';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';

import { useAvo } from '@hooks/Avo/useAvo';
import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

import { AffiliateLink } from './components/AffiliateLink/AffiliateLink';

const COPIED_TIMEOUT = 5000;

const ProfileReferAFriend: React.FC = observer(() => {
  const { pathname } = useLocation();
  const avo = useAvo();
  const { t } = useTranslation('profile.referrals');
  const [copied, setCopied] = useState<boolean>(false);
  const { referralCode, fetchReferralCode } = useContext(ProfileContext);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  useEffect(() => {
    fetchReferralCode();
  }, [fetchReferralCode]);

  const copyReferralLink = useCallback(async () => {
    if (!referralCode || copied) return;

    try {
      await navigator.clipboard.writeText(referralCode.link);
      setCopied(true);
      avo.copiedReferAFriendLink({
        screen: pathname,
      });
    } catch (e) {
      setCopied(false);
    }
  }, [avo, copied, pathname, referralCode]);

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  useEffect(() => {
    avo.viewedReferAFriendPage({
      screen: pathname,
      customDestinationPageName_: 'Refer A Friend',
    });
  }, [avo, pathname]);

  return (
    <FlexLayout direction='column' spacing={24}>
      <Card className='py-32'>
        <FlexLayout direction='column' className='flex items-center justify-center' spacing={8}>
          <Image image='refer_a_friend' usePalette className='h-[100px] w-[100px]' alt='refer a friend sign up' />
          <Body weight='emphasis'>{t('title')}</Body>
          <Body size='small' color='secondary'>
            {t('subTitle')}
          </Body>
        </FlexLayout>
      </Card>

      <SettingsCluster
        title={t('referralLink.title')}
        items={[
          <FlexLayout direction='column' spacing={8} className='m-16' key='referralLink.title'>
            <Body size='small'>{t('referralLink.uniqueLink')}</Body>
            <FlexLayout
              direction='row'
              alignItems='center'
              spacing={8}
              className='flex rounded-16 bg-color-background-surface-secondary p-12'
            >
              <FlexLayout direction='row' className='align-center flex w-[100%] items-center justify-between'>
                <Body color='secondary' size='small'>
                  {referralCode ? referralCode?.link : 'Referral link unavailable at this time'}
                </Body>

                <Button
                  disabled={!referralCode || hasClipboardPermission !== 'granted'}
                  variant='outlined'
                  onClick={copyReferralLink}
                  className='justify-end'
                  size='sm'
                  leadingIcon={!copied ? <Copy /> : undefined}
                >
                  {copied ? t('referralLink.copied') : t('referralLink.copyButton')}
                </Button>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>,
        ]}
      />

      <SettingsCluster
        title={t('referralInstructions.title')}
        items={[
          <FlexLayout direction='column' className='m-16' key='referralInstructions.title'>
            <FlexLayout direction='row'>
              <Body weight='emphasis' size='small'>
                <Body color='accent' weight='emphasis' size='small'>
                  {t('referralInstructions.stepLabels.1')}
                </Body>{' '}
                {t('referralInstructions.1.title')}
              </Body>
            </FlexLayout>
            <Body color='secondary' size='small'>
              {t('referralInstructions.1.content')}
            </Body>

            <FlexLayout direction='row' className='pt-16'>
              <Body weight='emphasis' size='small'>
                <Body color='accent' weight='emphasis' size='small'>
                  {t('referralInstructions.stepLabels.2')}
                </Body>{' '}
                {t('referralInstructions.2.title')}
              </Body>
            </FlexLayout>
            <Body color='secondary' size='small'>
              {t('referralInstructions.2.content')}
            </Body>

            <Body color='secondary' size='xsmall' className='pt-12'>
              {t('referralInstructions.eligibilityCriteria')}
            </Body>
          </FlexLayout>,
        ]}
      />

      <AffiliateLink />
    </FlexLayout>
  );
});

export { ProfileReferAFriend };

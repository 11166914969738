import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Stop: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12C2 17.5233 6.47784 22 12 22C17.5222 22 22 17.5233 22 12C22 6.47779 17.5222 2 12 2C6.47779 2 2 6.47779 2 12ZM5.4831 17.4573C4.24534 15.9808 3.5 14.0775 3.5 12C3.5 7.30621 7.30621 3.5 12 3.5C14.0772 3.5 15.9806 4.24541 17.4571 5.48329L5.4831 17.4573ZM6.54387 18.5179C8.02031 19.7551 9.92327 20.5 12 20.5C16.6938 20.5 20.5 16.6947 20.5 12C20.5 9.92337 19.755 8.02047 18.5177 6.54405L6.54387 18.5179Z'
      fill='currentColor'
    />
  </svg>
);

export { Stop };

import { ChangeEventHandler, useEffect, useState } from 'react';

import { Validator, ValidatorResponse } from './types';

const useValidation = (
  validators: Validator[],
  liveValidate: boolean,
  _onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  _value?: any,
  _deps: any = [],
) => {
  const [value, setValue] = useState<any>(_value);
  const [error, setError] = useState<boolean>(false);
  const [errorMeta, setErrorMeta] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string | undefined>('');

  const validate = async (_force = false, _liveValidate = false, _val = ''): Promise<boolean> => {
    // run all validators
    if (_val || value || _force) {
      for (let i = 0; i < validators.length; i += 1) {
        const validator = validators[i];
        const response: ValidatorResponse = await validator.validate(_val || value);

        if (_liveValidate || _force) {
          setSuccess(response.valid);
          setError(!response.valid);
          setErrorMeta(response.metadata);
          setHelperText(response.message);
        }

        if (!response.valid) return false;
      }
    }

    return true;
  };

  const reset = () => {
    setError(false);
    setSuccess(false);
    setHelperText('');
    setErrorMeta(undefined);
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = e.target.value;
    setValue(val);
    if (_onChange) _onChange(e);
    // This is to validate when autofilling from LastPass
    if (!e.isTrusted) validate(false, true, val);
  };

  const onFocus = () => {
    reset();
  };

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  useEffect(() => {
    if (validators) validate(false, liveValidate);
  }, [value, liveValidate]);

  useEffect(() => {
    if (validators) validate(false, true);
  }, [..._deps]);

  const onBlur = () => {
    validate(false, true);
  };

  return {
    value,
    onChange,
    onFocus,
    onBlur,
    error,
    errorMeta,
    helperText,
    success,
    validate,
  };
};

export { useValidation };

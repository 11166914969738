import { AccountStatusEnum } from '@shared/enums';

type SimpleStatus = 'Bronze' | 'Silver' | 'Gold' | 'Diamond';

export const accountStatusEnumToSimpleStatusMap: Record<AccountStatusEnum, SimpleStatus> = {
  1: 'Bronze',
  2: 'Bronze',
  3: 'Silver',
  4: 'Silver',
  5: 'Gold',
  6: 'Gold',
  7: 'Diamond',
  8: 'Diamond',
  9: 'Gold',
  10: 'Gold',
};

export const accountStatusColorMap: Record<SimpleStatus, string> = {
  Bronze: '#995301',
  Silver: '#96A1B5',
  Gold: '#FFCB36',
  Diamond: '#0072ED',
};

export const nextLevelStatus: Partial<Record<AccountStatusEnum, AccountStatusEnum | null>> = {
  [AccountStatusEnum.BRONZE]: AccountStatusEnum.SILVER,
  [AccountStatusEnum.BRONZE_RESTRICTED]: AccountStatusEnum.SILVER,
  [AccountStatusEnum.SILVER]: AccountStatusEnum.GOLD,
  [AccountStatusEnum.SILVER_RESTRICTED]: AccountStatusEnum.GOLD,
  [AccountStatusEnum.GOLD]: AccountStatusEnum.DIAMOND,
  [AccountStatusEnum.GOLD_RESTRICTED]: AccountStatusEnum.DIAMOND,
  [AccountStatusEnum.GOLD_NZ]: AccountStatusEnum.DIAMOND,
  [AccountStatusEnum.GOLD_NZ_RESTRICTED]: AccountStatusEnum.DIAMOND,
  [AccountStatusEnum.DIAMOND]: null,
  [AccountStatusEnum.DIAMOND_RESTRICTED]: null,
};

export const getAccountStatusImage = (accountStatus: SimpleStatus) => {
  if (accountStatus === 'Bronze') {
    return 'verification_bronze';
  } else if (accountStatus === 'Silver') {
    return 'verification_silver';
  } else if (accountStatus === 'Gold') {
    return 'verification_gold';
  }

  return 'verification_diamond';
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Help: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.1133 16.2109C12.1133 16.6252 11.7775 16.9609 11.3633 16.9609H11.3575C10.9433 16.9609 10.6075 16.6252 10.6075 16.2109C10.6075 15.7967 10.9433 15.4609 11.3575 15.4609H11.3633C11.7775 15.4609 12.1133 15.7967 12.1133 16.2109Z'
      fill='currentColor'
    />
    <path
      d='M12.1881 6.75C11.3779 6.75 10.6482 6.97778 10.0802 7.50117C9.51038 8.02551 9.25 8.72441 9.25 9.51163C9.25 9.92585 9.58579 10.2616 10 10.2616C10.4142 10.2616 10.75 9.92585 10.75 9.51163C10.75 9.07551 10.8817 8.80201 11.096 8.60485C11.3278 8.3912 11.6652 8.25 12.1881 8.25C12.6911 8.25 13.036 8.39175 13.2906 8.62714C13.5331 8.85135 13.6732 9.15134 13.6732 9.60574C13.6732 10.4007 13.4466 10.7012 13.2297 10.8307C12.8484 11.0545 12.2957 11.1968 11.5175 11.1968C10.9962 11.1968 10.5753 11.6228 10.5817 12.1442L10.6029 13.8735C10.608 14.2877 10.9479 14.6193 11.3621 14.6142C11.7762 14.6091 12.1079 14.2693 12.1028 13.8551L12.0883 12.6745C12.8041 12.6165 13.4476 12.4428 13.9918 12.1227L13.9946 12.121C14.8596 11.607 15.1732 10.6601 15.1732 9.60574C15.1732 8.78964 14.8977 8.07009 14.3089 7.52573C13.7322 6.99254 13.0026 6.75 12.1881 6.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12C2 6.47779 6.47779 2 12 2C17.5233 2 22 6.47784 22 12C22 17.5222 17.5233 22 12 22C6.47779 22 2 17.5222 2 12ZM12 3.5C7.30621 3.5 3.5 7.30621 3.5 12C3.5 16.6938 7.30621 20.5 12 20.5C16.6947 20.5 20.5 16.6938 20.5 12C20.5 7.30616 16.6947 3.5 12 3.5Z'
      fill='currentColor'
    />
  </svg>
);

export { Help };

import { useMemo, useState } from 'react';

import { ChartUnavailableReason } from '@global-components/Errors/NoChartError';

import { Asset } from '@shared/api';
import { CryptoEnum, FiatIdEnum } from '@shared/enums';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

export const usdStableCoins = [
  CryptoEnum.USDT,
  FiatIdEnum.USD,
  CryptoEnum.USDC,
  CryptoEnum.TUSD,
  CryptoEnum.BUSD,
  CryptoEnum.USDP,
]; // Includes USD itself

export const useCharts = (asset?: Asset) => {
  const baseAsset = useBaseAsset();
  const [notAvailableReason, setNotAvailableReason] = useState<ChartUnavailableReason>();

  const chartAvailable = useMemo(() => {
    if (!baseAsset || !asset) {
      setNotAvailableReason('error');
      return false;
    }

    if (asset.delisting) {
      setNotAvailableReason('isDelisted');
      return false;
    }

    if (baseAsset?.id === asset?.id) {
      setNotAvailableReason('isMatchingBaseAsset');
      return false;
    }

    if (baseAsset?.id === FiatIdEnum.USD && usdStableCoins.includes(asset?.id as number)) {
      setNotAvailableReason('error');
      return false;
    }

    if (asset?.code === 'TAUD') {
      setNotAvailableReason('error');
      return false;
    }

    setNotAvailableReason(undefined);
    return true;
  }, [asset, baseAsset]);

  return { chartAvailable, notAvailableReason };
};

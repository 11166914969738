import { useMemo } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

const useMinimumTradeAmount = (primaryAsset?: Asset, limitAsset?: Asset, amount?: string, triggerPrice?: string) => {
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;

  const minimumAmount = useMemo(() => {
    if (!primaryAsset || !limitAsset || !triggerPrice) return 0;

    const minOrderAmount = getMinimumOrderAmount(primaryAsset);
    const limitingOnPrimary = limitAsset.id === primaryAsset.id;

    if (limitingOnPrimary) {
      return minOrderAmount;
    } else {
      const minLimitAmount = Big(minOrderAmount).div(Big(triggerPrice));
      return minLimitAmount;
    }
  }, [primaryAsset, amount, triggerPrice, getMinimumOrderAmount]);

  return minimumAmount;
};

export { useMinimumTradeAmount };

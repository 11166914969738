import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { Big } from '@shared/safe-big';

import {
  PriceAlert,
  PriceAlertWindow,
  UpdateLimitAlertData,
  UpdateVolatilityAlertData,
} from '../../dynamicPriceAlerts.types';
import { useFetchDynamicPriceAlertsCache } from '../../hooks';
import { useDynamicPriceAlert } from '../../hooks/useDynamicPriceAlert';
import { useUpdateDynamicPriceAlert } from '../../hooks/useUpdateDynamicPriceAlert';
import { isLimitAlert, isVolatilityAlert } from '../../utils/dynamic-price-alerts.utils';

type Props = {
  alert: PriceAlert;
  onClose?: () => void;
};

const useEditPriceAlertModal = ({ alert, onClose, children }: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState<boolean>(!children);
  const [value, setValue] = useState<string>(
    isLimitAlert(alert) ? alert.price : Big(alert.change).times(100).toString(),
  );
  const [alertWindow, setAlertWindow] = useState<PriceAlertWindow | undefined>(
    isVolatilityAlert(alert) ? alert.window : undefined,
  );
  const { secondaryAsset, primaryAsset } = useDynamicPriceAlert({ alert });
  const { updateDynamicPriceAlert, updating } = useUpdateDynamicPriceAlert();
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();

  useEffect(() => {
    if (!open) setValue(isLimitAlert(alert) ? alert.price : Big(alert.change).times(100).toString());
  }, [alert, open]);

  const updateDisabled = useMemo(() => {
    if (isLimitAlert(alert)) {
      return Big(value).eq(alert.price);
    } else {
      return Big(value).eq(Big(alert.change).times(100)) && alert.window === alertWindow;
    }
  }, [alert, value, alertWindow]);

  const handleOpenChange = useCallback(
    (o?: boolean) => {
      setOpen(!!o);
      if (onClose && !o) onClose();
    },
    [onClose],
  );

  const onUpdateDynamicPriceAlert = useCallback(async () => {
    let data: UpdateLimitAlertData | UpdateVolatilityAlertData;

    if (isLimitAlert(alert)) {
      data = {
        price: value,
        priceAlertUuid: alert.priceAlertUuid,
      };
    } else {
      data = {
        change: Big(value).div(100).toString(),
        window: alertWindow,
        priceAlertUuid: alert.priceAlertUuid,
      };
    }

    await updateDynamicPriceAlert(data);
    invalidateCache();
    handleOpenChange(false);
  }, [alert, updateDynamicPriceAlert, invalidateCache, handleOpenChange, value, alertWindow]);

  return {
    primaryAsset,
    secondaryAsset,
    updateDisabled,
    value,
    updating,
    open,
    handleOpenChange,
    setValue,
    setAlertWindow,
    alertWindow,
    onUpdateDynamicPriceAlert,
  };
};

export { useEditPriceAlertModal };

import React, { useCallback, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronRight, DollarInCircle } from '@swyftx/aviary/icons/outlined';
import { MenuItem } from '@swyftx/aviary/molecules/Menu';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { balanceService, portfolioService, ratesService, userStatisticsService } from '@shared/services';
import { UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import UserService from '@services/UserService';

import * as Popover from '@radix-ui/react-popover';
import { observer } from 'mobx-react-lite';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

const SwyftxProAppHeaderSettingsCurrency: React.FC = observer(() => {
  const [open, setOpen] = useState<boolean>(false);
  const { getAssetByCode } = useMarkets();
  const { setUserCurrency } = UserStore.useUserStore;

  const baseAsset = useBaseAsset();

  const onSelectAsset = useCallback(
    async (asset: Asset) => {
      setOpen(false);
      setUserCurrency(asset.id, asset.code);
      await UserService.UpdateUserCurrency(asset.id);

      await ratesService.forceUpdate();
      await balanceService.forceUpdate();
      await portfolioService.forceUpdate();
      await userStatisticsService.forceUpdate();
    },
    [setUserCurrency],
  );

  const renderAsset = useCallback(
    (assetCode: string) => {
      const asset = getAssetByCode(assetCode);

      if (!asset) return null;

      return (
        <MenuItem
          className={cn(
            baseAsset?.code === assetCode ? 'bg-color-background-surface-selected text-color-text-selected' : '',
          )}
          icon={<AssetIcon asset={asset} size={20} />}
          text={assetCode}
          action={() => onSelectAsset(asset)}
        />
      );
    },
    [baseAsset?.code, getAssetByCode, onSelectAsset],
  );

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal>
      <Popover.Trigger>
        <MenuItem
          data-spotlightelementid='pro-currency-selector'
          icon={<DollarInCircle width={24} height={24} />}
          text='Base currency'
          endAdornment={
            <FlexLayout spacing={8} alignItems='center'>
              <Body color='secondary' size='small'>
                {baseAsset?.code}
              </Body>
              <ArrowChevronRight />
            </FlexLayout>
          }
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align='start' side='right' sideOffset={24} asChild>
          <Card className='p-16'>
            <FlexLayout direction='column' spacing={8} alignItems='center'>
              {renderAsset('AUD')}
              {renderAsset('USD')}
              {renderAsset('NZD')}
              {renderAsset('BTC')}
            </FlexLayout>
          </Card>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
});

SwyftxProAppHeaderSettingsCurrency.displayName = 'SwyftxProAppHeaderSettingsCurrency';

export { SwyftxProAppHeaderSettingsCurrency };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@swyftx/react-web-design-system';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

const NewsFeedTileTableHeader: React.FC = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'newsFeedTile' });

  const { isSmallContent } = useContentBreakpoint();

  return (
    <>
      <Grid container direction='row' paddingX={2} paddingY={1.5}>
        <Grid item xs={isSmallContent ? 3 : 2}>
          <Typography color='text.secondary' fontSize={14} fontWeight={600} textAlign='left'>
            {t('labels.date')}
          </Typography>
        </Grid>

        <Grid item xs={isSmallContent ? 9 : 8}>
          <Typography color='text.secondary' fontSize={14} fontWeight={600} textAlign='left'>
            {t('labels.title')}
          </Typography>
        </Grid>
        {!isSmallContent && (
          <Grid item xs={2}>
            <Typography color='text.secondary' fontSize={14} fontWeight={600} textAlign='right'>
              {t('labels.assets')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider variant='fullWidth' orientation='horizontal' />
    </>
  );
};

export { NewsFeedTileTableHeader };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Resume: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.25 3.53846C7.57651 3.53846 3.78846 7.3274 3.78846 12C3.78846 16.6735 7.57645 20.4615 12.25 20.4615C16.9235 20.4615 20.7115 16.6735 20.7115 12C20.7115 7.3274 16.9235 3.53846 12.25 3.53846ZM2.25 12C2.25 6.47784 6.72673 2 12.25 2C17.7733 2 22.25 6.47784 22.25 12C22.25 17.5232 17.7732 22 12.25 22C6.72679 22 2.25 17.5232 2.25 12Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5988 11.1609C15.7227 11.321 15.9342 11.6256 15.9342 12.0007C15.9342 12.3728 15.725 12.6765 15.6032 12.8358C15.4523 13.0331 15.2556 13.2317 15.0462 13.4194C14.624 13.7978 14.0727 14.2012 13.518 14.5551C12.9646 14.9079 12.376 15.2318 11.8742 15.438C11.6277 15.5393 11.366 15.6275 11.1216 15.665C10.9993 15.6839 10.8484 15.6954 10.6898 15.6723C10.531 15.6492 10.3119 15.583 10.1266 15.4C9.9957 15.2707 9.91921 15.1221 9.87437 15.0195C9.82547 14.9075 9.78839 14.7886 9.75915 14.6752C9.70064 14.4481 9.65794 14.1804 9.62622 13.9011C9.56235 13.3387 9.53447 12.6508 9.5373 11.9769C9.54012 11.3027 9.57379 10.6191 9.6389 10.0643C9.67123 9.78888 9.7134 9.52689 9.76908 9.3066C9.79685 9.19674 9.83191 9.08139 9.8779 8.97292C9.91876 8.87656 9.99314 8.72453 10.1264 8.59277C10.3222 8.39907 10.555 8.34134 10.7064 8.32413C10.8614 8.3065 11.0082 8.32182 11.1253 8.34242C11.3609 8.38388 11.6165 8.47274 11.8597 8.57434C12.3554 8.78136 12.9426 9.10221 13.4982 9.45247C14.0545 9.80327 14.6098 10.203 15.0357 10.5791C15.2469 10.7655 15.446 10.9635 15.5988 11.1609ZM11.2044 9.96851C11.7204 10.1749 12.2085 10.4581 12.6776 10.7538C13.1883 11.0759 13.6716 11.4269 14.0173 11.7323C14.1346 11.8358 14.2272 11.9264 14.2965 12.0019C14.2276 12.0782 14.1356 12.1695 14.0193 12.2738C13.6766 12.581 13.1976 12.9347 12.6907 13.2579C12.1824 13.5821 11.6779 13.8554 11.2894 14.0151C11.2578 14.028 11.2278 14.0399 11.1995 14.0508C11.1837 13.9569 11.1686 13.8489 11.1549 13.7275C11.0998 13.2431 11.073 12.6189 11.0757 11.9834C11.0784 11.3482 11.1104 10.725 11.1669 10.2437C11.1788 10.1424 11.1914 10.0505 11.2044 9.96851Z'
      fill='currentColor'
    />
  </svg>
);

export { Resume };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // TODO: replace with our icon
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; // TODO: replace with our icon

import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { Bundle } from '@hooks/Bundles/useBundles.hooks';

import { useDeepCompareEffect } from 'react-use';

type TradeAssetPickerBundleItemProps = {
  bundle: Bundle;
  selected: boolean;
  setSelected: React.Dispatch<React.SetStateAction<Bundle | undefined>>;
};

const TradeAssetSelectorBundleItem: React.FC<TradeAssetPickerBundleItemProps> = ({ bundle, selected, setSelected }) => {
  const [assets, setAssets] = useState<Asset[]>();
  const { setTradeCache } = UniversalTradeStore;
  const { t: bundlesT } = useTranslation('bundles');

  useDeepCompareEffect(() => {
    setAssets(bundle.assets);
  }, [bundle]);

  if (!assets) return null;

  const clearBundleList = () => {
    for (const asset of bundle.assets) {
      setTradeCache([asset.id], TradeSide.To, TradeAssetAction.Remove);
    }

    setSelected(undefined);
  };

  return (
    <Button
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: 2,
        borderRadius: 1,
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer',
        },
      }}
      onClick={() => (selected ? clearBundleList() : setSelected(bundle))}
    >
      <Stack direction='row' marginBottom={1} width='100%' alignItems='center' justifyContent='space-between'>
        <Stack direction='column' width='80%' spacing={1}>
          <Typography fontWeight={500} fontSize={12} textAlign='left'>
            {bundlesT(bundle.title as any)}
          </Typography>
          <Stack direction='row' alignItems='center' flexWrap='wrap' sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}>
            {assets.map((asset: Asset) => asset && <AssetIcon key={asset.code} asset={asset} size={24} />)}
          </Stack>
        </Stack>

        {selected ? <CheckCircleIcon color='primary' /> : <RadioButtonUncheckedIcon sx={{ color: 'text.secondary' }} />}
      </Stack>
    </Button>
  );
};

export { TradeAssetSelectorBundleItem };

import React, { useMemo } from 'react';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

import { Balance } from '../TradeInput.data';

type Props = {
  from: Asset;
  balanceToUse: Balance;
  error?: boolean;
};

const TradeInputHeaderBalance: React.FC<Props> = observer(({ from, balanceToUse, error }) => {
  const { trading, staking } = useAssetBalance(from);
  const assetBalance = useMemo(
    () => (balanceToUse === Balance.Staking ? staking : trading),
    [balanceToUse, staking, trading],
  );

  return (
    <Stack direction='row' alignItems='center' height='100%' width='100%' justifyContent='flex-end' spacing={0.5}>
      <Typography fontSize={12} fontWeight={500} color='text.secondary' marginRight={0.5} textAlign='right'>
        {balanceToUse === Balance.Trading ? 'Available:' : 'Available Earn funds:'}
      </Typography>
      <FormattedText
        typographyProps={{ weight: 'emphasis', color: error ? 'error' : 'primary', className: 'mr-2' }}
        value={assetBalance.balance}
        currency={from}
        formatBalance
        formatOpts={{ hideCode: true, appendCode: true }}
      />
    </Stack>
  );
});

TradeInputHeaderBalance.displayName = 'TradeInputHeaderBalance';

export { TradeInputHeaderBalance };

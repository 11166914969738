import React from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

interface Props {
  asset: Asset;
  onSelect: () => void;
  selected: boolean;
  disabled: boolean;
}

export const MultiAssetPickerItem: React.FC<Props> = (props) => {
  const { asset, onSelect, selected, disabled } = props;

  return (
    <button key={asset.id} onClick={onSelect} disabled={disabled}>
      <FlexLayout direction='row' className='items-center rounded-8 p-24 py-12 hover:bg-color-background-surface-hover'>
        <FlexLayout direction='row' className='w-full items-center justify-between'>
          <FlexLayout direction='row' spacing={16} className='items-center'>
            <Checkbox className='h-20 w-20 border-2 border-color-border-main' checked={selected} disabled={disabled} />
            <FlexLayout direction='row' spacing={8}>
              <AssetIcon asset={asset} size={20} disabled={disabled} />
              <Body color='primary' className={cn(disabled ? '!text-color-text-disabled' : '')}>
                {asset.name}
              </Body>
            </FlexLayout>
          </FlexLayout>
          <Body color='secondary' className={cn(disabled ? '!text-color-text-disabled' : '')}>
            {asset.code}
          </Body>
        </FlexLayout>
      </FlexLayout>
    </button>
  );
};

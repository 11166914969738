import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

interface Props {
  generateDepositAddress: () => void;
  generateAddressLoading: boolean;
}

export const GenerateDepositAddress: React.FC<Props> = (props) => {
  const { generateAddressLoading, generateDepositAddress } = props;

  return (
    <FlexLayout
      direction='column'
      className='w-full justify-center rounded-12 bg-color-background-surface-secondary p-16'
      spacing={16}
    >
      <FlexLayout direction='column' spacing={4}>
        <Body weight='emphasis'>New deposit address required</Body>
        <Body size='small'>Generate a new address for this asset and network combination.</Body>
      </FlexLayout>
      <FlexLayout direction='row' className='w-full'>
        <Button onClick={generateDepositAddress} loading={generateAddressLoading}>
          Generate new address
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import type { AxiosResponse } from 'axios';

import cache from './apiCache';
import endpoints from './endpoints/index';
import { performRequest, request, Request, resetApiCache } from './rest';

/** Manipulate endpoints so `resetCache` becomes a new property of the request function so it can be called consistently */
type Endpoint<T extends (...args: any) => any> = ((...args: Parameters<T>) => Promise<AxiosResponse<ReturnType<T>>>) & {
  resetCache: () => void;
};
type Endpoints<T extends Record<string, (args: any) => any>> = {
  [key in keyof T]: Endpoint<T[key]>;
};

const buildEndpoints = (): Endpoints<typeof endpoints> => {
  const assembledEndpoints = {} as any;
  for (const endpointKey in endpoints) {
    const key = endpointKey as keyof typeof endpoints;
    // the return type of this object is a lie to get intellisense working correctly.
    const args = endpoints[key]({} as any) as unknown as Request<any>;
    const endpoint = endpoints[key];
    const newEndpoint = (userArgs: Request<any>) => performRequest({ ...args, ...userArgs } as any);
    newEndpoint.resetCache = () => resetApiCache(args);
    assembledEndpoints[key] = newEndpoint as Endpoint<typeof endpoint>;
  }

  return assembledEndpoints;
};

const api = {
  request,
  endpoints: buildEndpoints(),
  cache,
};

export { api };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const WhatsNewFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M9.44559 2.50112C9.44559 2.11649 9.13379 1.80469 8.74916 1.80469C8.36454 1.80469 8.05273 2.11649 8.05273 2.50112C8.05273 3.91141 6.90946 5.05469 5.49916 5.05469C5.11454 5.05469 4.80273 5.36649 4.80273 5.75112C4.80273 6.13574 5.11454 6.44754 5.49916 6.44754C6.90946 6.44754 8.05273 7.59082 8.05273 9.00112C8.05273 9.38574 8.36454 9.69754 8.74916 9.69754C9.13379 9.69754 9.44559 9.38574 9.44559 9.00112C9.44559 7.59082 10.5889 6.44754 11.9992 6.44754C12.3838 6.44754 12.6956 6.13574 12.6956 5.75112C12.6956 5.36649 12.3838 5.05469 11.9992 5.05469C10.5889 5.05469 9.44559 3.91141 9.44559 2.50112Z'
      fill='currentColor'
    />
    <path
      d='M15.75 7.00098C15.75 6.58676 15.4142 6.25098 15 6.25098C14.5858 6.25098 14.25 6.58676 14.25 7.00098C14.25 9.90047 11.8995 12.251 9 12.251C8.58579 12.251 8.25 12.5868 8.25 13.001C8.25 13.4152 8.58579 13.751 9 13.751C11.8995 13.751 14.25 16.1015 14.25 19.001C14.25 19.4152 14.5858 19.751 15 19.751C15.4142 19.751 15.75 19.4152 15.75 19.001C15.75 16.1015 18.1005 13.751 21 13.751C21.4142 13.751 21.75 13.4152 21.75 13.001C21.75 12.5868 21.4142 12.251 21 12.251C18.1005 12.251 15.75 9.90047 15.75 7.00098Z'
      fill='currentColor'
    />
    <path
      d='M6.44559 14.5011C6.44559 14.1165 6.13379 13.8047 5.74916 13.8047C5.36454 13.8047 5.05273 14.1165 5.05273 14.5011C5.05273 15.9114 3.90946 17.0547 2.49916 17.0547C2.11454 17.0547 1.80273 17.3665 1.80273 17.7511C1.80273 18.1357 2.11454 18.4475 2.49916 18.4475C3.90946 18.4475 5.05273 19.5908 5.05273 21.0011C5.05273 21.3857 5.36454 21.6975 5.74916 21.6975C6.13379 21.6975 6.44559 21.3857 6.44559 21.0011C6.44559 19.5908 7.58886 18.4475 8.99916 18.4475C9.38379 18.4475 9.69559 18.1357 9.69559 17.7511C9.69559 17.3665 9.38379 17.0547 8.99916 17.0547C7.58886 17.0547 6.44559 15.9114 6.44559 14.5011Z'
      fill='currentColor'
    />
  </svg>
);

export { WhatsNewFilled };

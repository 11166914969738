import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Utility } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyDown, ArrowLineOnlyUp } from '@swyftx/aviary/icons/outlined';
import { EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { cn } from '@shared/utils/lib/ui';

import { AssetPickerTableData } from './AssetPicker.types';

interface AssetPickerHeaderProps {
  headerKey: keyof AssetPickerTableData;
  title: string;
  sortable: boolean;
  alignment: 'start' | 'end';
  className: string;
  handleSort: () => void;
  sort: EnhancedTableSort<AssetPickerTableData>;
}

export const AssetPickerHeader: React.FC<AssetPickerHeaderProps> = (props) => {
  const { headerKey, title, sortable, alignment, className, handleSort, sort } = props;
  const { sortDirection, sortKey } = sort;
  return (
    <FlexLayout
      key={headerKey}
      className={cn(className, 'cursor-pointer whitespace-nowrap')}
      justifyContent={alignment}
      onClick={handleSort}
    >
      <FlexLayout alignItems={alignment} className='flex gap-2' justifyContent={alignment}>
        {sortable && sortKey === headerKey && (
          <div>
            {sortDirection === 'ASC' && <ArrowLineOnlyUp className='h-12 w-12 text-color-text-accent' />}
            {sortDirection === 'DESC' && <ArrowLineOnlyDown className='h-12 w-12 text-color-text-accent' />}
          </div>
        )}
        <Utility>{title}</Utility>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const CrossInCircle: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M9.07129 9.06202C9.36421 8.76916 9.83909 8.76921 10.1319 9.06213L11.9996 10.9301L13.8652 9.06452C14.1581 8.77163 14.633 8.77163 14.9258 9.06452C15.2187 9.35741 15.2187 9.83229 14.9258 10.1252L13.0601 11.9909L14.9279 13.8591C15.2208 14.1521 15.2208 14.6269 14.9278 14.9198C14.6349 15.2127 14.16 15.2126 13.8672 14.9197L11.9995 13.0516L10.1338 14.9172C9.84095 15.2101 9.36608 15.2101 9.07319 14.9172C8.78029 14.6243 8.78029 14.1494 9.07319 13.8565L10.9389 11.9908L9.07118 10.1227C8.77831 9.82976 8.77836 9.35488 9.07129 9.06202Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 11.9996C2 17.5229 6.47784 21.9996 12 21.9996C17.5222 21.9996 22 17.5229 22 11.9996C22 6.47742 17.5222 1.99963 12 1.99963C6.47779 1.99963 2 6.47742 2 11.9996ZM12 20.4996C7.30616 20.4996 3.5 16.6944 3.5 11.9996C3.5 7.30585 7.30621 3.49963 12 3.49963C16.6938 3.49963 20.5 7.30585 20.5 11.9996C20.5 16.6944 16.6938 20.4996 12 20.4996Z'
      fill='currentColor'
    />
  </svg>
);

export { CrossInCircle };

import { Validator } from '../Validator';

export enum StringRule {
  HasUppercase = 'HasUppercase',
  HasNumber = 'HasNumber',
  hasLowerCase = 'hasLowerCase',
  HasSpecialCharacter = 'HasSpecialCharacter',
  IsEmpty = 'IsEmpty',
}

export type StringError = {
  hasUppercase?: boolean;
  hasNumber?: boolean;
  hasLowercase?: boolean;
  hasSpecialCharacter?: boolean;
  isEmpty?: boolean;
};

class StringValidator extends Validator<string, StringError> {
  rules: StringRule[];

  constructor(...rules: StringRule[]) {
    super();
    this.rules = rules;
  }

  isValid = (input: string): StringError => {
    const checkUppercase = this.rules.indexOf(StringRule.HasUppercase) > -1;
    const checkNumber = this.rules.indexOf(StringRule.HasNumber) > -1;
    const checkLowercase = this.rules.indexOf(StringRule.hasLowerCase) > -1;
    const checkSpecialCharacters = this.rules.indexOf(StringRule.HasSpecialCharacter) > -1;
    const checkIsEmpty = this.rules.indexOf(StringRule.IsEmpty) > -1;

    return {
      hasUppercase: checkUppercase ? this.hasUpperCase(input) : undefined,
      hasNumber: checkNumber ? this.hasNumber(input) : undefined,
      hasLowercase: checkLowercase ? this.hasLowerCase(input) : undefined,
      hasSpecialCharacter: checkSpecialCharacters ? this.hasSpecialCharacters(input) : undefined,
      isEmpty: checkIsEmpty ? this.isEmpty(input) : undefined,
    };
  };

  hasUpperCase = (input: string): boolean => {
    const regex = new RegExp('[A-Z]');
    return regex.test(input);
  };

  hasNumber = (input: string): boolean => {
    const regex = new RegExp('[0-9]');
    return regex.test(input);
  };

  hasLowerCase = (input: string): boolean => {
    const regex = new RegExp('[a-z]');
    return regex.test(input);
  };

  hasSpecialCharacters = (input: string): boolean => {
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return regex.test(input);
  };

  isEmpty = (input: string): boolean => input.length === 0;
}

export default StringValidator;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { Star } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';
import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  variant?: 'default' | 'assetList' | 'assetPicker';
};

const AssetName: React.FC<Props> = observer(({ asset, variant = 'default' }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const isXs = useTailwindBreakpoint('xs');

  const favouriteAssetList = useFavouriteAssets();

  const isFavourited = useMemo(
    () => favouriteAssetList.findIndex((a) => asset.id === a.id) > -1,
    [asset.id, favouriteAssetList],
  );

  const favouriteAsset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, assetId: number, favourite: boolean) => {
    e.stopPropagation();
    e.preventDefault();

    UserService.UpdateUserSettings({
      data: { favouriteAsset: { assetId, favStatus: favourite } },
    });
  };

  const favouriteIcon = useMemo(
    () =>
      isFavourited ? <StarFilled className='text-color-icon-accent' /> : <Star className='text-color-icon-neutral' />,
    [isFavourited],
  );

  if (variant === 'assetList') {
    return (
      <FlexLayout spacing={8} alignItems='center' justifyContent='start' className='truncate'>
        <Button
          layout='icon'
          variant='ghost'
          tooltip={isFavourited ? t('labels.unFavourite') : t('labels.favourite')}
          leadingIcon={favouriteIcon}
          onClick={(e) => favouriteAsset(e, asset.id, !isFavourited)}
          size='sm'
        />
        <AssetIcon asset={asset} size={20} />
        <FlexLayout direction='column' className='truncate'>
          <Body size='small' title={asset.name} weight='emphasis' overflow='truncate'>
            {asset.name}
          </Body>
          {!isXs && (
            <Body size='small' title={asset.name} color='secondary' overflow='truncate'>
              {asset.code}
            </Body>
          )}
        </FlexLayout>
      </FlexLayout>
    );
  }

  if (variant === 'assetPicker') {
    return (
      <FlexLayout spacing={8} alignItems='center' justifyContent='start' className='truncate'>
        <Button
          layout='icon'
          variant='ghost'
          tooltip={isFavourited ? t('labels.unFavourite') : t('labels.favourite')}
          leadingIcon={favouriteIcon}
          onClick={(e) => favouriteAsset(e, asset.id, !isFavourited)}
          size='sm'
        />
        <AssetIcon asset={asset} size={20} />
        <FlexLayout direction='row' className='truncate' spacing={4}>
          <Body size='small' title={asset.name} weight='emphasis'>
            {asset.name}
          </Body>
          {!isXs && (
            <Body size='small' title={asset.name} color='secondary' overflow='truncate'>
              {asset.code}
            </Body>
          )}
        </FlexLayout>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout spacing={8} alignItems='center' justifyContent='start'>
      <Button
        layout='icon'
        variant='ghost'
        tooltip={isFavourited ? t('labels.unFavourite') : t('labels.favourite')}
        leadingIcon={favouriteIcon}
        onClick={(e) => favouriteAsset(e, asset.id, !isFavourited)}
      />
      <AssetIcon asset={asset} size={20} />
      <Body size='small' className='w-[160px]' overflow='truncate' title={asset.name}>
        {asset.name}
      </Body>
    </FlexLayout>
  );
});

export { AssetName };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

export interface SettingsInfoItemProps {
  infoItem?: React.ReactNode;
  title?: string | React.ReactNode;
  description?: string;
  infoAction?: React.ReactNode;
}

export const SettingsInfoItem: React.FC<SettingsInfoItemProps> = (props) => {
  const { title, description, infoItem, infoAction } = props;

  return (
    <FlexLayout direction='row' className='p-16 @container' spacing={16}>
      <FlexLayout direction='row' className='flex basis-full items-center'>
        <FlexLayout direction='column' className='h-full w-full justify-between' spacing={16}>
          <FlexLayout direction='column'>
            {typeof title === 'string' && <Body weight='emphasis'>{title}</Body>}
            {typeof title !== 'string' && <>{title}</>}

            <Body size='small' color='secondary' className='w-full'>
              {description}
            </Body>
          </FlexLayout>
          <FlexLayout>
            {infoAction && (
              <FlexLayout direction='column' className='pt-8 @xs:w-full @sm:w-1/2 @md:w-1/3'>
                {infoAction}
              </FlexLayout>
            )}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='row' className='items-center justify-end'>
        {infoItem}
      </FlexLayout>
    </FlexLayout>
  );
};

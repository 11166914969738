import React from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { ChipProps } from '@swyftx/aviary/atoms/Chip/Chip.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { PriceIncrease } from '@global-components/PriceIncrease';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { observer } from 'mobx-react-lite';

type Props = {
  change: string | null;
  changePercent: string | null;
  baseAsset: Asset;
};

export const AssetWalletChangeDetails: React.FC<Props> = observer(({ change, changePercent, baseAsset }) => {
  const chipColor = ((): ChipProps['color'] => {
    if (change === null) {
      return 'secondary';
    }

    if (Big(change).gt(0)) {
      return 'success';
    }

    return 'destructive';
  })();

  if (change === null && changePercent === null) {
    return null;
  }

  return (
    <FlexLayout direction='row' spacing={8} alignItems='center'>
      {change !== null && (
        <PriceIncrease amount={change} currencyAsset={baseAsset} typographyProps={{ weight: 'emphasis' }} />
      )}
      {changePercent !== null && <Chip color={chipColor}>{Number(changePercent).toFixed(2)}%</Chip>}
    </FlexLayout>
  );
});

import { useCallback, useContext, useEffect, useMemo } from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { TradingViewContext } from '../../../context/TradingView.context';
import { isAdvancedChartState } from '../../../utils/Tradingview.utils';

type OverlayItem = {
  text: string;
  trailing?: React.ReactElement;
  hidden?: boolean;
  onClick: () => void;
};

const useTradingViewOverlays = () => {
  const { isSwyftxPro } = useSwyftxPro();
  const {
    showMarks,
    setShowMarks,
    showSimpleVolume,
    setShowSimpleVolume,
    showPreviewOrders,
    setShowPreviewOrders,
    showOpenOrders,
    setShowOpenOrders,
    chartState,
    selectedChartStyle,
  } = useContext(TradingViewContext);
  const avo = useAvo();
  const location = useLocation();

  const trackEvent = useCallback(
    (buttonName: string, buttonState: string) => {
      avo.chartOverlaysAction({
        swyftxPro: isSwyftxPro ? 'true' : 'false',
        buttonName,
        buttonState,
        screen: location.pathname,
      });
    },
    [avo, isSwyftxPro, location.pathname],
  );

  const toggleMarks = useCallback(() => {
    const newValue = !showMarks;
    setShowMarks(newValue);

    trackEvent('Historical orders', newValue ? 'on' : 'off');

    if (!isAdvancedChartState(chartState)) return;

    chartState?.onChartReady(() => {
      if (!chartState?.chart()) return;

      chartState.chart().executeActionById('hideAllMarks');
    });
  }, [showMarks, setShowMarks, trackEvent, chartState]);

  const togglePreviewOrders = useCallback(() => {
    const newValue = !showPreviewOrders;
    setShowPreviewOrders(newValue);
    trackEvent('Preview orders', newValue ? 'on' : 'off');
  }, [setShowPreviewOrders, showPreviewOrders, trackEvent]);

  const toggleOpenOrders = useCallback(() => {
    const newValue = !showOpenOrders;
    setShowOpenOrders(newValue);
    trackEvent('Open orders', newValue ? 'on' : 'off');
  }, [setShowOpenOrders, showOpenOrders, trackEvent]);

  const toggleShowSimpleVolume = useCallback(() => {
    const newValue = !showSimpleVolume;
    setShowSimpleVolume(newValue);
    trackEvent('Simple volume', newValue ? 'on' : 'off');
  }, [setShowSimpleVolume, showSimpleVolume, trackEvent]);

  useEffect(() => {
    if (!isAdvancedChartState(chartState)) return;

    chartState?.onChartReady(() => {
      if (!chartState?.chart()) return;

      setShowMarks(!chartState.chart().getCheckableActionState('hideAllMarks'));
    });
  }, [setShowMarks, chartState]);

  const items: OverlayItem[] = useMemo(
    () => [
      {
        text: 'Historical orders',
        trailing: <Switch checked={showMarks} />,
        onClick: () => toggleMarks(),
      },
      {
        text: 'Preview orders',
        trailing: <Switch checked={showPreviewOrders} />,
        hidden: !isSwyftxPro || selectedChartStyle === 'simple',
        onClick: () => togglePreviewOrders(),
      },
      {
        text: 'Open orders',
        trailing: <Switch checked={showOpenOrders} />,
        hidden: !isSwyftxPro || selectedChartStyle === 'simple',
        onClick: () => toggleOpenOrders(),
      },
      {
        text: 'Show volume',
        trailing: <Switch checked={showSimpleVolume} />,
        hidden: selectedChartStyle === 'advanced',
        onClick: () => toggleShowSimpleVolume(),
      },
    ],
    [
      isSwyftxPro,
      selectedChartStyle,
      showMarks,
      showOpenOrders,
      showPreviewOrders,
      showSimpleVolume,
      toggleMarks,
      toggleOpenOrders,
      togglePreviewOrders,
      toggleShowSimpleVolume,
    ],
  );

  return {
    items,
  };
};

export { useTradingViewOverlays };

import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import { UIStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const HEIGHT = 50;
const PADDING = 12;
const BOTTOM_PADDING = 20;
const AUTO_HIDE_DURATION = 5000;
const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const GlobalToasts: React.FC = observer(() => {
  const [closedMap, setClosedMap] = useState<Map<string, boolean>>(new Map()); // Decouples the open state to the error message value to allow it time to show the closing transition
  const { toastMessages, removeToastMessage } = UIStore.useUIStore;

  const handleClose = (uuid: string) => {
    setClosedMap(new Map(closedMap.set(uuid, true)));

    setTimeout(() => {
      const closedMapBuff = new Map(closedMap);
      closedMapBuff.delete(uuid);
      setClosedMap(closedMapBuff);
      removeToastMessage(uuid);
    }, 200);
  };

  return (
    <>
      {Array.from(toastMessages).map(([uuid, data], index) => (
        <Snackbar
          sx={{ bottom: `${BOTTOM_PADDING + index * (HEIGHT + PADDING)}px !important`, transition: '0.1s' }}
          ClickAwayListenerProps={{
            onClickAway: () => {},
          }} // removes onClickAway
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => handleClose(uuid)}
          anchorOrigin={ANCHOR_ORIGIN}
          key={`${uuid}`}
          open={!closedMap.get(uuid)}
        >
          <Alert severity={data.severity} onClose={() => handleClose(uuid)}>
            {data.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
});

GlobalToasts.displayName = 'GlobalToasts';

export { GlobalToasts };

import { TFunction } from 'react-i18next';

import { Asset, OrderType, UpdateOrderParams } from '@shared/api';
import { Big } from '@shared/safe-big';
import { TransactionHistoryItem } from '@shared/services';

export const getEquivalentValueFromTrigger = (amount: Big, triggerValue: Big, isQuantityPrimary: boolean) => {
  if (isQuantityPrimary) {
    return amount.div(triggerValue).toString();
  }
  return amount.mul(triggerValue).toString();
};

export const getEditTriggerOrderTypeName = (
  orderType: OrderType | null,
  t: TFunction<'modals', 'editTriggerOrders'>,
) => {
  switch (orderType) {
    case OrderType.StopSell:
      return t('labels.stopSell');
    case OrderType.TriggerSell:
      return t('labels.triggerSell');
    case OrderType.TriggerBuy:
      return t('labels.triggerBuy');
    case OrderType.StopBuy:
      return t('labels.stopBuy');
    default:
      return null;
  }
};

export const formatNewTriggerValuesToHistoryItem = (
  order: TransactionHistoryItem,
  amount: string,
  primaryAmount: string,
  secondaryAmount: string,
): TransactionHistoryItem => ({
  ...order,
  amount,
  primaryAmount,
  secondaryAmount,
});

export const formatTriggerValuesToOrderParams = (
  order: TransactionHistoryItem,
  amount: string,
  triggerValue: string,
  quantityAsset: Asset | undefined,
  isBuy: boolean,
): UpdateOrderParams | null => {
  if (order.orderType == null || !quantityAsset) {
    return null;
  }

  return {
    assetQuantity: quantityAsset.code,
    orderType: order.orderType,
    orderUuid: order.uuid,
    primary: order.primaryAsset.toString(),
    secondary: order.secondaryAsset.toString(),
    quantity: amount,
    trigger: isBuy ? triggerValue : Big(1).div(triggerValue).toString(),
  };
};

export const validateTriggerValue = (orderType: OrderType | null, triggerValue: Big, currentConversionRate: Big) => {
  if (orderType === null || !triggerValue) {
    return false;
  }

  if (orderType === OrderType.StopBuy || orderType === OrderType.TriggerSell) {
    return triggerValue.gt(currentConversionRate);
  }

  return triggerValue.lt(currentConversionRate);
};

export const getEditTriggerOrderValidationError = (
  orderType: OrderType | null,
  valid: boolean,
  t: TFunction<'modals', 'editTriggerOrders'>,
) => {
  if (orderType === null || valid) {
    return '';
  }

  switch (orderType) {
    case OrderType.StopBuy:
      return t('validationError.stopBuy');
    case OrderType.TriggerSell:
      return t('validationError.triggerSell');
    case OrderType.TriggerBuy:
      return t('validationError.triggerBuy');
    case OrderType.StopSell:
      return t('validationError.stopSell');
    default:
      return '';
  }
};

import { Release, ReleaseVersion } from '../whats-new.type';

const releaseFive: Release = {
  version: ReleaseVersion.Five,
  features: [
    {
      imgSrc: '/assets/images/releases/4/feature.png',
      title: 'You said - we heard! We brought back our classic market ticker!',
      descriptions: [
        'Monitor the market at a glance every time you log in',
        'Don’t like it? Click on the ‘X’ icon to hide it from your dashboard',
        'Toggle your market ticker on and off anytime by going to the personalisation page via your profile avatar in the header.',
      ],
    },
  ],
};

export { releaseFive };

import React from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import { DefaultTheme } from '@global-components/Theme';

import { DarkModeEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { AssetListRoutes } from '@routes/AssetsList/AssetList.routes';
import { AuthRoutes } from '@routes/Auth';
import { AutoInvestRoutes } from '@routes/AutoInvest/AutoInvest.routes';
import { BundlesRoutes } from '@routes/Bundles';
import { DashboardRoutes } from '@routes/Dashboard/Dashboard.routes';
import { DynamicPriceAlertsRoutes } from '@routes/DynamicPriceAlerts';
import { EntityOnboardingRoutes } from '@routes/EntityOnboarding/EntityOnboarding.routes';
import { LoansRoutes } from '@routes/Loans';
import { LoginRoutes } from '@routes/Login';
import { LoginShell } from '@routes/Login/LoginShell';
import { MaintenanceRoutes } from '@routes/Maintenance';
import { MessagesRoutes } from '@routes/Messages/Messages.routes';
import { OnboardingRoutes } from '@routes/Onboarding';
import { ForcePasswordResetRoutes } from '@routes/PasswordReset';
import { PortfolioTransferRoutes } from '@routes/PortfolioTransfer';
import PrivateRoute from '@routes/PrivateRoute';
import { ProfileEntityRoutes, ProfileRoutes } from '@routes/Profile';
import { QuickBuyRoutes } from '@routes/QuickBuy';
import { RegisterRoutes } from '@routes/Register';
import { RewardsRoutes } from '@routes/Rewards';
import { ShopRoutes } from '@routes/Shop';
import { SourceOfWealthRoutes } from '@routes/SourceOfWealth';
import { SwyftxProRoutes } from '@routes/SwyftxPro';
import { TransactionsRoutes } from '@routes/Transactions';
import { TutorialRoutes } from '@routes/Tutorials/Tutorials.routes';
import { UniversalTradePageRoutes } from '@routes/UniversalTradePage/UniversalTradePage.routes';
import { WalletRoutes } from '@routes/Wallet';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { SwyftxProAppShell, SwyftxAppShell } from 'src/lib/layout';

import { NavigationURLs } from '../navigation/types';
import { useSwyftxPro } from '../trade-pro/hooks/useSwyftxPro';

const Router: React.FC = observer(() => {
  const { isEntity } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isSwyftxPro } = useSwyftxPro();

  const element = useRoutes([
    // Fullscreen pages
    {
      path: NavigationURLs.Root,
      element: (
        <div className='h-full w-full bg-color-background-pageBG'>
          <Outlet />
        </div>
      ),
      children: [
        ...MaintenanceRoutes,
        ...MessagesRoutes,
        ...AuthRoutes,
        ...ForcePasswordResetRoutes,
        ...OnboardingRoutes,
        ...SourceOfWealthRoutes,
        ...EntityOnboardingRoutes,
        //Redirect rules
        { path: NavigationURLs.Root, element: <Navigate to={NavigationURLs.Login} /> },
        { path: '*', element: <Navigate to={NavigationURLs.Login} /> },
      ],
    },

    // Login and Register pages
    {
      path: NavigationURLs.Root,
      element: (
        <div className='h-full w-full bg-color-background-pageBG'>
          <DefaultTheme mode={DarkModeEnum.LIGHT}>
            <LoginShell>
              <Outlet />
            </LoginShell>
          </DefaultTheme>
        </div>
      ),
      children: [
        ...LoginRoutes,
        ...RegisterRoutes,
        //Redirect rules
        { path: NavigationURLs.Root, element: <Navigate to={NavigationURLs.Login} /> },
        { path: '*', element: <Navigate to={NavigationURLs.Login} /> },
      ],
    },

    // Pages with header, sidebar and global actions
    !isSwyftxPro
      ? {
          path: NavigationURLs.Root,
          element: <SwyftxAppShell />,
          children: [
            ...DashboardRoutes,
            ...UniversalTradePageRoutes,
            ...(isFeatureEnabled(AppFeature.QuickBuy) ? QuickBuyRoutes : []),
            ...(isFeatureEnabled(AppFeature.DynamicPriceAlerts) ? DynamicPriceAlertsRoutes : []),
            ...PortfolioTransferRoutes,
            ...AssetListRoutes,
            ...WalletRoutes,
            ...BundlesRoutes,
            ...(isFeatureEnabled(AppFeature.AutoInvest) ? AutoInvestRoutes : []),
            ...TransactionsRoutes,
            ...ShopRoutes,
            ...RewardsRoutes,
            ...(isFeatureEnabled(AppFeature.ToursTutorialPage) ? TutorialRoutes : []),
            ...(isEntity() ? ProfileEntityRoutes : ProfileRoutes),
            ...(isFeatureEnabled(AppFeature.Loans) ? LoansRoutes : []),
          ],
        }
      : {
          path: NavigationURLs.Root,
          element: <PrivateRoute element={<SwyftxProAppShell />} />,
          children: [...SwyftxProRoutes],
        },
  ]);

  return element;
});

export { Router };

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { MarketMoversType, MarketTab } from './MarketMoversCard.types';

type Props = {
  title: string;
  value: MarketMoversType;
  actions: React.ReactNode;
  onChange: (value: MarketMoversType) => void;
  tabs: MarketTab[];
  tableElement?: React.ReactNode;
};

const MarketMoversCard: React.FC<Props> = ({ title, value, actions, onChange, tabs, tableElement }) => (
  <ElementContainer title={title} className='w-full' actions={actions}>
    <Card className='h-full min-h-[40.65rem] w-full overflow-hidden @md:max-h-[40.65rem]'>
      {tabs && (
        <FlexLayout
          alignItems='center'
          justifyContent='start'
          className='max-h-[5.31rem] min-h-[5.31rem] overflow-x-auto overflow-y-hidden border-b border-color-border-main p-16 @sm:p-24'
        >
          <EnhancedTabs<MarketMoversType> variant='child' tabs={tabs} onChange={onChange} value={value} />
        </FlexLayout>
      )}
      {tableElement}
    </Card>
  </ElementContainer>
);

export { MarketMoversCard };

import React, { useCallback, useMemo } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Information, Tick } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { getStepSubtitle, StepTitles } from '@routes/EntityOnboarding/types/StepTitles';

import { useEntityOnboardingSelector } from '@EntityOnboarding/EntityOnboarding.context';

import { Step } from './stepperUtils';

type Props = {
  step: Step;
  isActive: boolean;
  root?: boolean;
  stepNumber?: number;
};

const EntityOnboardingStepperItem: React.FC<Props> = (props) => {
  const { step, root, stepNumber, isActive } = props;
  const completedSteps = useEntityOnboardingSelector((state) => state.context.completedSteps);
  const value = useEntityOnboardingSelector((state) => state.value);

  const isStepComplete = useCallback(
    (currentStep: Step) =>
      completedSteps[currentStep.id] ||
      (currentStep.children.length && currentStep.children.every((s) => completedSteps[s.id])),
    [completedSteps],
  );

  const hasChildren = useMemo(() => step.children.filter((s) => !s.hidden).length > 0, [step.children]);
  const stepChildren = step.children.filter((s) => !s.hidden);

  const isSubStepActive = useCallback(
    (subStep: Step) => {
      const stepValues = Object.entries(value).map(([key, v]) => `${key}.${v}`);
      return stepValues[0] === subStep.id;
    },
    [value],
  );

  const stepSubtitle = getStepSubtitle(step.id as keyof typeof StepTitles);

  return (
    <>
      <FlexLayout key={step.id} direction='row' alignItems='center' spacing={16}>
        {root &&
          (isStepComplete(step) && !isActive ? (
            <Avatar color='primary' size='md' className='h-30 w-30 bg-color-background-surface-selected'>
              <Tick className='h-20 w-20 text-color-text-accent' />
            </Avatar>
          ) : (
            <Avatar color={isActive ? 'primary' : 'secondary'} size='md' className='h-30 w-30'>
              {step.id === 'introduction' && (
                <Information
                  className={cn('h-20 w-20', isActive ? 'text-color-text-inverse' : 'text-color-text-primary')}
                />
              )}
              {step.id !== 'introduction' && (
                <Body weight='emphasis' color={isActive ? 'inverse' : 'primary'}>
                  {stepNumber}
                </Body>
              )}
            </Avatar>
          ))}
        <FlexLayout direction='column' spacing={2}>
          <Body weight='emphasis' color='primary'>
            {StepTitles[step.id as keyof typeof StepTitles]}
          </Body>
          {stepSubtitle !== undefined && isActive && (
            <Body size='small' color='secondary'>
              {stepSubtitle}
            </Body>
          )}
        </FlexLayout>
      </FlexLayout>
      {isActive && hasChildren && (
        <FlexLayout direction='column' spacing={8}>
          <FlexLayout className={cn('border-color-divider ml-14 h-16 w-10 border-l-2 border-dashed')} />
          {stepChildren.map((s, index) => (
            <FlexLayout key={s.id} direction='column' spacing={8}>
              <FlexLayout direction='row' alignItems='center' spacing={16} className='ml-8'>
                {root && (
                  <Avatar
                    color={isSubStepActive(s) ? 'primary' : 'secondary'}
                    size='md'
                    className={cn(
                      'h-14 w-14',
                      isSubStepActive(s)
                        ? 'bg-color-background-primary'
                        : isStepComplete(s)
                        ? '!bg-color-background-primary-subtle'
                        : 'bg-color-background-surface-secondary',
                    )}
                  />
                )}
                <Body weight='emphasis' size='small' color='primary'>
                  {StepTitles[s.id as keyof typeof StepTitles]}
                </Body>
              </FlexLayout>
              {index !== stepChildren.length - 1 && (
                <FlexLayout
                  className={cn(
                    'ml-14 h-12 w-10 border-l-2',
                    isStepComplete(s) ? 'border-color-background-primary' : 'border-color-divider border-dashed',
                  )}
                />
              )}
            </FlexLayout>
          ))}
        </FlexLayout>
      )}
    </>
  );
};

export { EntityOnboardingStepperItem };

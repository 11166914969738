import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { AssetHistoryItem, AssetHistoryItemStatus } from '@shared/api';
import { assetService } from '@shared/services';

import { isTransfer } from '../../utils/Transactions.utils';

type Props = {
  className?: string;
  transaction: AssetHistoryItem;
};

const TransactionsActions: React.FC<Props> = ({ className, transaction }) => {
  const { openModal } = useModal();

  const openTransaction = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isTransfer(transaction)) {
        const transasctionAsset = assetService.getAsset(transaction.secondaryAsset);
        if (transasctionAsset) openModal(Modals.TransactionDetails, { transaction, asset: transasctionAsset });
      } else {
        openModal(Modals.OrderDetails, { transaction, orderId: transaction.uuid });
      }
    },
    [openModal, transaction],
  );

  const deleteOpenOrder = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      openModal(Modals.OrderDetails, { orderId: transaction.uuid, deleteModal: true });
    },
    [openModal, transaction.uuid],
  );

  const editOpenOrder = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      openModal(Modals.EditTriggerOrders, { order: transaction });
    },
    [openModal, transaction],
  );

  return (
    <FlexLayout justifyContent='end' spacing={8} className={className}>
      {transaction.status === AssetHistoryItemStatus.Pending && (
        <>
          <Button size='sm' variant='outlined' onClick={editOpenOrder}>
            Edit
          </Button>
          <Button size='sm' variant='outlined' onClick={deleteOpenOrder}>
            Delete
          </Button>
        </>
      )}
      {transaction.status !== AssetHistoryItemStatus.Pending && (
        <Button size='sm' variant='outlined' onClick={openTransaction}>
          View transaction
        </Button>
      )}
    </FlexLayout>
  );
};

export { TransactionsActions };

import { Validator, ValidatorResponse } from '.';

export enum StringRule {
  HasUppercase = 'HasUppercase',
  HasNumber = 'HasNumber',
  hasLowerCase = 'hasLowerCase',
  HasSpecialCharacter = 'HasSpecialCharacter',
  IsEmpty = 'IsEmpty',
  Match = 'Match',
}

export type StringError = {
  hasUppercase?: boolean;
  hasNumber?: boolean;
  hasLowercase?: boolean;
  hasSpecialCharacter?: boolean;
  isEmpty?: boolean;
  match?: boolean;
};

class StringValidator extends Validator<string> {
  rules: StringRule[];

  compareValue: string;

  constructor(...rules: StringRule[]) {
    super('');
    this.rules = rules;
    this.compareValue = '';
  }

  withCompareValue(value = ''): StringValidator {
    this.compareValue = value;
    return this;
  }

  hasUpperCase = (input: string): boolean => {
    const regex = new RegExp('[A-Z]');
    return regex.test(input);
  };

  hasNumber = (input: string): boolean => {
    const regex = new RegExp('[0-9]');
    return regex.test(input);
  };

  hasLowerCase = (input: string): boolean => {
    const regex = new RegExp('[a-z]');
    return regex.test(input);
  };

  hasSpecialCharacters = (input: string): boolean => {
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return regex.test(input);
  };

  match = (input: string): boolean =>
    // TODO: add country locales to locale compare
    input.localeCompare(this.compareValue) === 0;

  isEmpty = (input: string): boolean => input.length === 0;

  isValid = (input: string): StringError => {
    const checkUppercase = this.rules.indexOf(StringRule.HasUppercase) > -1;
    const checkNumber = this.rules.indexOf(StringRule.HasNumber) > -1;
    const checkLowercase = this.rules.indexOf(StringRule.hasLowerCase) > -1;
    const checkSpecialCharacters = this.rules.indexOf(StringRule.HasSpecialCharacter) > -1;
    const checkIsEmpty = this.rules.indexOf(StringRule.IsEmpty) > -1;
    const checkStringsMatch = this.rules.indexOf(StringRule.Match) > -1;

    return {
      hasUppercase: checkUppercase ? this.hasUpperCase(input) : undefined,
      hasNumber: checkNumber ? this.hasNumber(input) : undefined,
      hasLowercase: checkLowercase ? this.hasLowerCase(input) : undefined,
      hasSpecialCharacter: checkSpecialCharacters ? this.hasSpecialCharacters(input) : undefined,
      isEmpty: checkIsEmpty ? this.isEmpty(input) : undefined,
      match: checkStringsMatch && this.compareValue ? this.match(input) : undefined,
    };
  };

  validate = (input: string): ValidatorResponse<StringError> => {
    const metadata: any = this.isValid(input);
    let valid = true;
    const keys = Object.keys(metadata);

    keys.forEach((key: string) => {
      if (metadata[key] === undefined) {
        valid = false;
      }
    });

    return {
      message: undefined,
      valid,
      metadata,
    };
  };
}

export default StringValidator;

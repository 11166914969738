import { useEffect, useState } from 'react';

import { breakpoints } from './breakpoints';

type Breakpoint = keyof typeof breakpoints;

const getNextBreakpoint = (breakpoint: Breakpoint) => {
  const keys = Object.keys(breakpoints);
  const index = keys.indexOf(breakpoint);
  return keys[index + 1] as Breakpoint;
};

const isMatchingBreakpoint = (breakpoint: Breakpoint) => {
  const width = window.innerWidth;
  const min = breakpoints[breakpoint];
  const max = breakpoints[getNextBreakpoint(breakpoint)];
  return width >= min && (max ? width < max : true);
};

const useTailwindBreakpoint = (breakpoint: Breakpoint) => {
  const [isCurrentBreakpoint, setIsCurrentBreakpoint] = useState(() => isMatchingBreakpoint(breakpoint));

  const handleResize = () => {
    const isThisBreakpoint = isMatchingBreakpoint(breakpoint);
    if (isCurrentBreakpoint !== isThisBreakpoint) {
      setIsCurrentBreakpoint(isThisBreakpoint);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isCurrentBreakpoint]);

  return isCurrentBreakpoint;
};

export { useTailwindBreakpoint };

import React from 'react';

import { Box } from '@mui/material';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { pxToRem, Typography } from '@swyftx/react-web-design-system';

import { PieChart, PieChartProps } from '@global-components/charts/PieChart/PieChart';
import { PieChartData } from '@global-components/charts/PieChart/PieChart.types';

type Props = {
  pieData: PieChartData;
  percentOverlay?: string;
  chartProps?: Partial<PieChartProps>;
};

const DEFAULT_SIZE = 60;
const DEFAULT_INNER_RADIUS_RATIO = 0.8;

const MiniPieChart: React.FC<Props> = ({ pieData, percentOverlay, chartProps }) => (
  <Box position='relative' display='flex' alignItems='center' width={60} height={60}>
    <PieChart
      innerRadiusRatio={DEFAULT_INNER_RADIUS_RATIO}
      height={DEFAULT_SIZE}
      width={DEFAULT_SIZE}
      data={pieData}
      noSort
      {...chartProps}
    />
    {percentOverlay ? (
      <Box alignItems='center' display='flex' height='100%' justifyContent='center' position='absolute' width='100%'>
        <Typography color='text.primary' fontWeight={500} fontSize={pxToRem(12)} number>
          {percentOverlay}
        </Typography>
      </Box>
    ) : null}
  </Box>
);

export { MiniPieChart };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { AppHeaderBaseCurrencySelectorVerify } from '@global-components/AppHeader/AppHeaderBaseCurrencySelector/AppHeaderBaseCurrencySelectorVerify';

import { UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UserStore } from '@shared/store';

import { usePortfolio } from '@hooks/usePortfolio';

import { observer } from 'mobx-react-lite';
import { useHideSmallWalletBalances } from 'src/lib/wallets/hooks/useHideSmallWalletBalances';

import { PortfolioBreakdownBalanceItem } from './PortfolioBreakdownBalanceItem';

type Props = {
  isSmall?: boolean;
  maxHeight?: string;
};

const PortfolioBreakdownBalances: React.FC<Props> = observer(({ isSmall = false }) => {
  const { portfolio } = usePortfolio({ balanceKey: 'all' });
  const { hideZeroBalances, setHideZeroBalances } = useHideSmallWalletBalances();
  const { isDemo } = AppStore.useAppStore;
  const { t } = useTranslation('common');

  const { balances, isUserVerified } = UserStore.useUserStore;
  const { getRate, getMinimumOrderAmount } = RatesStore.useRatesStore;

  const balanceValues = useMemo(
    () =>
      Object.values(balances)
        .filter(({ availableBalance, assetId }) =>
          hideZeroBalances ? Big(availableBalance).gte(getMinimumOrderAmount(assetId)) : true,
        )
        .sort((a, b) => {
          const aAssetType = assetService.getAsset(a.assetId)?.assetType || 0;
          const bAssetType = assetService.getAsset(b.assetId)?.assetType || 0;

          if (aAssetType === bAssetType) {
            const aVal = Big(getRate(a.assetId).midPrice).times(Big(a.availableBalance).plus(a.stakingBalance));
            const bVal = Big(getRate(b.assetId).midPrice).times(Big(b.availableBalance).plus(b.stakingBalance));
            return bVal.toNumber() - aVal.toNumber();
          }

          return aAssetType > bAssetType ? 1 : -1;
        }),
    [balances, getMinimumOrderAmount, getRate, hideZeroBalances],
  );

  const hasCryptoBalance = useMemo(() => Object.values(balances).length > 0, [balances]);

  const getValue = (balance: UserBalance) => Big(balance.availableBalance).plus(balance.stakingBalance);

  return (
    <Stack sx={{ overflowY: 'auto' }}>
      {!isUserVerified() && !isDemo ? (
        <AppHeaderBaseCurrencySelectorVerify />
      ) : (
        <>
          {balanceValues.map((balance: UserBalance) => {
            const value = getValue(balance);
            const asset = assetService.getAsset(balance.assetId);

            if (!asset || value.lte(0)) return null;

            return (
              <PortfolioBreakdownBalanceItem
                key={asset.code}
                value={value}
                totalValue={portfolio.accountValue}
                asset={asset}
                isSmall={isSmall}
              />
            );
          })}
          {hasCryptoBalance && hideZeroBalances && (
            <Stack width='100%' alignItems='center' marginTop={1} spacing={0}>
              <Typography fontWeight={500}>{t('balances.cantFindAssets')}</Typography>
              <Button onClick={() => setHideZeroBalances(false)}>{t('balances.showBalances')}</Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
});

export { PortfolioBreakdownBalances };

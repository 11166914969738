import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Locked } from '@swyftx/aviary/icons/outlined';
import { Checkbox, Link } from '@swyftx/react-web-design-system';

import { Form } from '@global-components/Form';
import { EmailInput, PasswordInput } from '@global-components/Input';
import Recaptcha from '@global-components/Recaptcha';

import env from '@shared/config';

import { AuthContext } from '@routes/Auth/Auth.context';

import { observer } from 'mobx-react-lite';

const SignIn: React.FC = observer(() => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    login,
    submitting,
    setSubmitting,
    getRememberMe,
    getFederatedAuthType,
    error,
  } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const federatedAuthType = getFederatedAuthType(searchParams);

  const { t } = useTranslation('auth');
  useEffect(() => {
    getRememberMe();
  }, []);

  const onChangeEmail = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <Stack direction='column' sx={{ width: '100%', height: '100%', flex: 1 }} gap='24px'>
      <Typography fontSize='32px' fontWeight={700} alignSelf='center'>
        {t('signIn.title')}
      </Typography>
      <Box
        id='signIn.alert'
        padding='12px'
        borderRadius={1}
        textAlign='center'
        sx={{ backgroundColor: 'action.hover' }}
      >
        <Typography fontSize={12} marginBottom='2px'>
          {t('signIn.alert.title')}
        </Typography>
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <Locked className='mr-[5px] leading-6 text-color-text-info' />
          <Typography fontSize={12} fontWeight='600' color='primary'>
            {env.SWYFTX_APP_URL}
          </Typography>
        </Stack>
        <hr
          style={{
            border: '1px dashed rgba(0, 114, 237, 0.1)',
            margin: '12px 0',
          }}
        />
        <Typography fontSize={12}>
          {t('signIn.alert.subTitle')}{' '}
          <Link href={t('signIn.alert.link')} target='_blank' rel='noopener noreferrer'>
            Find out more
          </Link>
        </Typography>
      </Box>

      <Form
        id='signIn.form'
        onSubmit={() => login(federatedAuthType)}
        submitting={submitting}
        setSubmitting={setSubmitting}
        hideButton
        sx={{ height: '100%' }}
      >
        <Stack width='100%' height='100%' alignItems='center'>
          <EmailInput
            id='signIn.form.email'
            sx={{ width: '100%' }}
            label={
              <Typography fontSize={14} fontWeight={500}>
                {t('signIn.labels.email')}
              </Typography>
            }
            value={email}
            onChange={onChangeEmail}
          />

          <PasswordInput
            id='signIn.form.password'
            sx={{ width: '100%' }}
            value={password}
            label={
              <Typography fontSize={14} fontWeight={500}>
                {t('signIn.labels.password')}
              </Typography>
            }
            onChange={onChangePassword}
            type='general'
          />

          <Box
            id='signIn.form.rememberMe.container'
            display='flex'
            alignItems='center'
            width='100%'
            marginTop={1}
            marginBottom={{ xs: 4, lg: 3 }}
            sx={{ color: 'text.primary', '.MuiTypography-root': { fontSize: 14 } }}
          >
            <Checkbox
              id='signIn.form.rememberMe'
              label={t('signIn.labels.rememberMe')}
              checked={rememberMe}
              onClick={() => setRememberMe(!rememberMe)}
            />
          </Box>
        </Stack>

        <Stack width='100%' height='100%' alignItems='center' justifyContent='end'>
          <Recaptcha id='signIn.form.recaptcha' />

          {error && (
            <Typography id='sigIn.error' color='error' fontSize={12} marginBottom={2}>
              {error}
            </Typography>
          )}

          <Button id='signIn.submit.button' size='lg' type='submit' loading={submitting} className='w-full'>
            {t('signIn.buttonLabels.signIn')}
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
});

SignIn.displayName = 'SignIn';

export { SignIn };

import React, { useMemo } from 'react';

import { useAssetsSearchModule } from './useAssetsSearchModule';
import { useOwnedAssetsSearchModule } from './useOwnedAssetsSearchModule';
import { GlobalSearchCategory } from '../../components/GlobalSearchCategory';
import { useFilterSearchModuleItems } from '../../hooks';

type Props = {
  searchValue: string;
};

const AssetsModuleSearchCategory: React.FC<Props> = ({ searchValue }) => {
  const ownedItems = useOwnedAssetsSearchModule();
  const topItems = useAssetsSearchModule({ filterType: 'all', limit: 5, sortKey: 'rank' });

  const favExclusions = useMemo(() => ownedItems.map((o) => o.subTitle), [ownedItems]);

  const favItems = useAssetsSearchModule({ filterType: 'favourites', excludedAssetCodes: favExclusions });

  const otherExclusions = useMemo(
    () => [...ownedItems.map((o) => o.subTitle), ...favItems.map((f) => f.subTitle)],
    [ownedItems, favItems],
  );

  const otherItems = useAssetsSearchModule({ filterType: 'all', excludedAssetCodes: otherExclusions });

  const filteredOwnedItems = useFilterSearchModuleItems({ searchValue, items: ownedItems });
  const filteredFavItems = useFilterSearchModuleItems({ searchValue, items: favItems });
  const filteredOtherItems = useFilterSearchModuleItems({ searchValue, items: otherItems });

  if (!searchValue) return <GlobalSearchCategory title='Top assets' items={topItems} />;

  return (
    <>
      <GlobalSearchCategory title='Owned assets' items={filteredOwnedItems} />
      <GlobalSearchCategory title='Favourite assets' items={filteredFavItems} />
      <GlobalSearchCategory title='Other assets' items={filteredOtherItems} />
    </>
  );
};

export { AssetsModuleSearchCategory };

import en from './onboarding.en.json';
import fr from './onboarding.fr.json';

const OnboardingTranslations = {
  en,
  fr,
};

export const OnboardingTranslationKey = 'onboarding';

export { OnboardingTranslations };

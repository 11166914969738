import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import * as Sentry from '@sentry/react';
import DOMPurify from 'dompurify';

import { mockTitle, NewsCardLayout, NewsCardVariant } from './NewsCard.types';
import { NewsCardExcerpt } from './NewsCardExcerpt';
import { NewsCardFooter } from './NewsCardFooter';
import { NewsCardImage } from './NewsCardImage';

type Props = {
  id: number;
  variant?: NewsCardVariant;
  layout?: NewsCardLayout;
  isCard?: boolean;
  imgSrc?: string;
  title?: string;
  excerpt?: string;
  source?: string;
  published?: string;
  icons?: React.ReactNode[];
  className?: string;
  onClick?: () => void;
  loading?: boolean;
};

const NewsCard = React.forwardRef<HTMLDivElement, Props>(
  (
    { id, variant = 'card', layout = 'full', imgSrc, title, excerpt, source, published, icons, onClick, loading },
    ref,
  ) => {
    const getLoadingTitle = useCallback(() => {
      if (!loading) return mockTitle;
    }, [loading]);

    const classNames = useMemo(
      () => (variant === 'standard' ? 'cursor-pointer rounded-12 hover:bg-color-background-surface-hover' : ''),
      [variant],
    );

    const isCompactLayout = useMemo(() => layout === 'compact', [layout]);

    return (
      <FlexLayout
        ref={ref}
        data-name={id.toString()}
        direction={!isCompactLayout ? 'column' : 'row'}
        justifyContent='start'
        onClick={isCompactLayout ? onClick : undefined}
        className={`h-full  ${classNames}`}
        spacing={16}
      >
        <NewsCardImage isCompactLayout={isCompactLayout} imgSrc={imgSrc} loading={loading} />
        <FlexLayout direction='column' justifyContent='space-between' className='h-full overflow-hidden' spacing={16}>
          <FlexLayout
            direction='column'
            justifyContent='space-between'
            className=' h-full overflow-hidden'
            spacing={16}
          >
            <FlexLayout direction='column' spacing={8} className='h-full'>
              <Body
                weight='emphasis'
                size='medium'
                loading={loading}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title || getLoadingTitle() || '') }}
              />
              <NewsCardExcerpt excerpt={excerpt} loading={loading} />
            </FlexLayout>
          </FlexLayout>
          <NewsCardFooter source={source} published={published} icons={icons} loading={loading} />
        </FlexLayout>
      </FlexLayout>
    );
  },
);

NewsCard.displayName = 'NewsCard';

export const ProfiledNewsCard = Sentry.withProfiler(NewsCard);

export { ProfiledNewsCard as NewsCard };

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MedicareIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <rect
        x='3.667'
        y='5.333'
        width='24.667'
        height='21.333'
        rx='3'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        clipRule='evenodd'
        d='M8.176 15.822c-.508-1.49.086-3.191 1.752-3.695a2.999 2.999 0 0 1 2.573.406c.689-.5 1.692-.67 2.567-.406 1.667.504 2.264 2.206 1.757 3.695C16.033 18.182 12.5 20 12.5 20s-3.507-1.79-4.325-4.178Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='.4'
        d='M21 12h4M21 16h4M21 20h4'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

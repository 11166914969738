import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Layout: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.7495 11.9687V8.02625C21.7495 6.3989 21.2411 4.94847 20.263 3.89769C19.2792 2.84085 17.8768 2.25 16.2175 2.25H7.78301C6.1276 2.25 4.72542 2.84114 3.74073 3.89725C2.76146 4.94753 2.25 6.39786 2.25 8.02625V15.9733C2.25 17.6007 2.75871 19.0512 3.73711 20.1019C4.72112 21.1587 6.12371 21.7495 7.78301 21.7495H16.2165C17.8762 21.7495 19.2789 21.1587 20.2628 20.1019C21.2411 19.0511 21.7495 17.6006 21.7495 15.9733V12.0313C21.7499 12.0209 21.7501 12.0105 21.7501 12C21.7501 11.9895 21.7499 11.9791 21.7495 11.9687ZM4.83783 4.92016C4.16041 5.64672 3.75 6.70952 3.75 8.02625V15.9733C3.75 17.291 4.15878 18.3536 4.83488 19.0797C5.50536 19.7998 6.49428 20.2495 7.78301 20.2495H10.2773V3.75H7.78301C6.49915 3.75 5.50983 4.19943 4.83783 4.92016ZM11.7773 12.75V20.2495H16.2165C17.5058 20.2495 18.4946 19.7997 19.1649 19.0798C19.8409 18.3537 20.2495 17.2911 20.2495 15.9733V12.75H11.7773ZM20.2495 11.25H11.7773V3.75H16.2175C17.5062 3.75 18.4948 4.19972 19.165 4.91972C19.8409 5.64579 20.2495 6.70848 20.2495 8.02625V11.25Z'
      fill='currentColor'
    />
  </svg>
);

export { Layout };

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

const WalletListNoSearchResultsFound: React.FC = () => (
  <Card className='p-24'>
    <FlexLayout direction='column' spacing={8} alignItems='center'>
      <Image image='empty_search_results' usePalette className='mt-32 block h-[160px] w-[160px]' alt='' />
      <Body color='primary' weight='bold' size='large'>
        No results
      </Body>
      <Body color='secondary'>No matching results found</Body>
    </FlexLayout>
  </Card>
);

export { WalletListNoSearchResultsFound };

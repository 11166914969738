import React, { useCallback, useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Hide, More } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';
import { SwyftxProTradeContext } from 'src/lib/trade-pro/context';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { OrdersTileTabs } from './ordersTile.types';

interface Props {
  toggleFilterCurrentAsset: () => void;
  filterCurrentAsset: boolean;
  hideCancelled: boolean;
  toggleHideCancelled: () => void;
  selectedTab: OrdersTileTabs;
}

export const OrdersTileActions: React.FC<Props> = (props) => {
  const { toggleFilterCurrentAsset, filterCurrentAsset, hideCancelled, toggleHideCancelled, selectedTab } = props;
  const [open, setOpen] = useState<boolean>();
  const { isSwyftxPro } = useSwyftxPro();
  const { setShowOrdersAndBalances } = useContext(SwyftxProTradeContext);
  const avo = useAvo();
  const location = useLocation();

  const handleShowOrdersAndBalances = useCallback(() => {
    avo.hideWidgetAction({ buttonName: 'orders_balances', swyftxPro: 'true', screen: location.pathname });
    setShowOrdersAndBalances(false);
  }, [avo, location.pathname, setShowOrdersAndBalances]);

  return (
    <Menu
      triggerContent={
        <Button
          variant={isSwyftxPro ? 'ghost' : 'outlined'}
          layout='icon'
          className={open ? 'bg-color-background-surface-selected' : ''}
          leadingIcon={<More />}
        />
      }
      open={open}
      onOpenChange={setOpen}
      align='end'
      contentClassName='p-8'
    >
      <FlexLayout direction='column'>
        {isSwyftxPro && (
          <FlexLayout direction='column' className='hidden md:flex'>
            <Body className='mb-8 uppercase' size='xsmall' weight='emphasis' color='secondary'>
              Widget Settings
            </Body>
            <MenuItem action={handleShowOrdersAndBalances} text='Hide balances and orders widget' icon={<Hide />} />

            {selectedTab !== OrdersTileTabs.Wallets && (
              <Body className='my-8 uppercase' size='xsmall' weight='emphasis' color='secondary'>
                Order Settings
              </Body>
            )}
          </FlexLayout>
        )}

        {selectedTab !== OrdersTileTabs.Wallets && (
          <>
            <MenuItem
              action={toggleFilterCurrentAsset}
              text='Current asset only'
              preventCloseOnSelect
              endAdornment={<Switch checked={filterCurrentAsset} />}
            />
            {selectedTab !== OrdersTileTabs.OpenOrders && (
              <MenuItem
                action={toggleHideCancelled}
                text='Hide cancelled'
                preventCloseOnSelect
                endAdornment={<Switch checked={hideCancelled} />}
              />
            )}
          </>
        )}
      </FlexLayout>
    </Menu>
  );
};

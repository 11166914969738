import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Loading } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api, Asset } from '@shared/api';
import { PriceAlert } from '@shared/api/@types/alerts';
import { UIStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

import { DeletePriceAlertModal } from './DeletePriceAlert';
import { PriceAlertList } from './PriceAlertList/PriceAlertList';
import { PriceAlertStatus, priceAlertTabs } from '../PriceAlerts.types';
import { PriceAlertsModalHeader } from '../PriceAlertsModalHeader';

type Props = {
  id: string;
  asset: Asset;
  loading: boolean;
  alerts?: PriceAlert[];
  nextStep: () => void;
  onClose: () => void;
  open: boolean;
  hydrate: () => void;
};

export const CurrentPriceAlerts: React.FC<Props> = observer(
  ({ id, asset, alerts, open, onClose, nextStep, loading, hydrate }) => {
    const [deleteAlertModalOpen, setDeleteAlertModalOpen] = useState<boolean>(false);
    const [triggeredAlerts, setTriggeredAlerts] = useState<PriceAlert[]>([]);
    const [activeAlerts, setActiveAlerts] = useState<PriceAlert[]>([]);
    const [selectedTab, setSelectedTab] = useState<PriceAlertStatus>(PriceAlertStatus.ACTIVE);
    const [alertToDelete, setAlertToDelete] = useState<string | undefined>(undefined);
    const { addMessageBox } = UIStore.useUIStore;
    const isXs = useTailwindBreakpoint('xs');

    useEffect(() => {
      if (!alerts?.length) return;

      setTriggeredAlerts(alerts?.filter((alert) => alert.status === 'TRIGGERED') ?? []);
      setActiveAlerts(alerts?.filter((alert) => alert.status === 'WAITING') ?? []);
    }, [alerts]);

    const handleDeleteAlert = useCallback(async () => {
      try {
        if (!alertToDelete) throw Error('No alert to delete');
        await api.endpoints.deleteAlert({ data: { priceAlertUuid: alertToDelete } });

        addMessageBox({
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          content: <SuccessMessageBox title='Successfully deleted price alert' />,
        });
        hydrate();
      } catch (ex) {
        addMessageBox({
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          content: <ErrorMessageBox title='Failed to delete price alert, please try again' />,
        });
      }
    }, [addMessageBox, alertToDelete, hydrate]);

    return (
      <>
        <Modal
          id={id}
          open={open && !deleteAlertModalOpen}
          onClose={onClose}
          onOpenChange={(e) => (e?.valueOf() === false ? onClose() : undefined)}
          title={<PriceAlertsModalHeader asset={asset} />}
          position={isXs ? 'bottom' : 'center'}
        >
          <FlexLayout direction='column' spacing={16} className='w-full px-8 py-24 !pt-0 sm:p-24'>
            <FlexLayout direction='column' spacing={8}>
              <EnhancedTabs<PriceAlertStatus>
                variant='default'
                tabs={priceAlertTabs}
                onChange={setSelectedTab}
                value={selectedTab}
              />
              {loading && (
                <FlexLayout className='items-center justify-center p-32'>
                  <Loading className='animate-spin' />
                </FlexLayout>
              )}
              {!loading && (
                <PriceAlertList
                  alerts={selectedTab === PriceAlertStatus.ACTIVE ? activeAlerts : triggeredAlerts}
                  alertsStatus={selectedTab}
                  onDelete={(uuid) => {
                    setAlertToDelete(uuid);
                    setDeleteAlertModalOpen(true);
                  }}
                />
              )}
            </FlexLayout>
            <FlexLayout direction='row' className='w-full justify-end px-16 sm:px-0'>
              <Button onClick={nextStep} size='lg' className={cn(isXs ? 'w-full' : '')}>
                Create price alert
              </Button>
            </FlexLayout>
          </FlexLayout>
        </Modal>
        <DeletePriceAlertModal
          open={deleteAlertModalOpen}
          setOpen={setDeleteAlertModalOpen}
          confirmDelete={handleDeleteAlert}
          asset={asset}
        />
      </>
    );
  },
);

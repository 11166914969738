import React from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { cn } from '@shared/utils/lib/ui';

const SwyftxProLogo: React.FC = () => (
  <FlexLayout spacing={4} alignItems='end'>
    <Image image='swyftx_logo_pro_lg' className={cn('z-20 h-32')} />
  </FlexLayout>
);

export { SwyftxProLogo };

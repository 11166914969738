import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Tick: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.0303 5.46967C22.3232 5.76256 22.3232 6.23744 22.0303 6.53033L9.69873 18.8619C9.40588 19.1548 8.93109 19.1548 8.63818 18.862L2.46978 12.6962C2.17683 12.4034 2.17673 11.9285 2.46956 11.6356C2.76239 11.3426 3.23726 11.3425 3.53022 11.6354L9.16829 17.2711L20.9697 5.46967C21.2626 5.17678 21.7374 5.17678 22.0303 5.46967Z'
      fill='currentColor'
    />
  </svg>
);

export { Tick };

import { useCallback, useMemo, useState } from 'react';

import { CompanyForm, Corporate, CorporateTrustForm, Individual, Shareholder } from '@shared/api';

import { useToggle } from 'react-use';
import { v4 as uuid } from 'uuid';

import { useEntityOnboardingSelector, useEntityOnboardingService } from '../EntityOnboarding.context';
import { IndividualWithUuid } from '../types/EntityApplicationForm.types';

export type ShareholderWithUuid = { uuid: string; shareholder: Shareholder };
export type CorporateWithUuid = { uuid: string; corporate: Corporate };

/**
 * Logic for adding and removing shareholders in the company and corporate trust flows
 */
export const useShareholdersStep = () => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as
    | CompanyForm
    | CorporateTrustForm;

  const prevShareholders = applicationData.shareholders
    ? applicationData.shareholders.map((shareholder) => ({ shareholder, uuid: uuid() }))
    : undefined;

  const prevDirectors = applicationData.directors
    ? applicationData.directors.map((director) => ({
        individual: director,
        uuid:
          prevShareholders?.find(
            (shareholder) =>
              shareholder.shareholder.type === 'INDIVIDUAL' && shareholder.shareholder.email === director.email,
          )?.uuid ?? uuid(),
      }))
    : undefined;

  const prevBeneficiaries =
    applicationData.type === 'TRUST_CORPORATE' && applicationData.beneficiaries
      ? applicationData.beneficiaries.map((beneficiary) => ({
          individual: beneficiary,
          uuid:
            prevShareholders?.find(
              (shareholder) =>
                shareholder.shareholder.type === 'INDIVIDUAL' && shareholder.shareholder.email === beneficiary.email,
            )?.uuid ?? uuid(),
        }))
      : undefined;

  const [directors, setDirectors] = useState<IndividualWithUuid[]>(prevDirectors ?? []);
  const [shareholders, setShareholders] = useState<ShareholderWithUuid[]>(prevShareholders ?? []);
  const [beneficiaries, setBeneficiaries] = useState<IndividualWithUuid[]>(prevBeneficiaries ?? []);
  const completedSteps = useEntityOnboardingSelector((state) => state.context.completedSteps);
  const hasShareholdersAlready = applicationData.shareholders?.some(
    (shareholder: Shareholder) => shareholder.type === 'CORPORATE',
  );
  const [addIndividualMemberOpen, toggleAddIndividualMemberOpen] = useToggle(false);
  const [addCorporateMemberOpen, toggleAddCorporateMemberOpen] = useToggle(false);

  /**
   * In the company flow, if they have previously completed this step, prefill the shareholders
   */
  const [hasCompanyShareholders, setHasCompanyShareholders] = useState<boolean | undefined>(
    completedSteps['companyAddMembers.addShareholders'] ? hasShareholdersAlready : undefined,
  );

  /**
   * In the corporate trust, if they have previously completed this step, prefill the shareholders
   */
  const [hasCorporateTrustShareholders, setHasCorporateTrustShareholders] = useState<boolean | undefined>(
    completedSteps['corporateTrustAddMembers.addShareholders'] ? hasShareholdersAlready : undefined,
  );

  const onAddMember = useCallback(
    (individual: Individual) => {
      setShareholders((prev) => [...prev, { shareholder: individual, uuid: uuid() }]);
      toggleAddIndividualMemberOpen(false);
    },
    [toggleAddIndividualMemberOpen],
  );

  const onAddCorporateShareholder = useCallback(
    (corporateShareholder: Corporate) => {
      setShareholders((prev) => [...prev, { shareholder: corporateShareholder, uuid: uuid() }]);
      toggleAddCorporateMemberOpen(false);
    },
    [toggleAddCorporateMemberOpen],
  );

  const onCancel = useCallback(() => {
    toggleAddIndividualMemberOpen(false);
  }, [toggleAddIndividualMemberOpen]);

  const handleRemove = useCallback((shareholderUuid: string) => {
    setShareholders((prev) => prev.filter((shareholder) => shareholder.uuid !== shareholderUuid));
  }, []);

  const individualShareholders = useMemo(
    () =>
      shareholders
        .filter((shareholder) => shareholder.shareholder.type === 'INDIVIDUAL')
        .map((shareholder) => ({
          uuid: shareholder.uuid,
          individual: shareholder.shareholder,
        })) as IndividualWithUuid[],
    [shareholders],
  );

  const corporateShareholders = useMemo(
    () =>
      shareholders
        .filter((shareholder) => shareholder.shareholder.type === 'CORPORATE')
        .map((shareholder) => ({ uuid: shareholder.uuid, corporate: shareholder.shareholder })) as CorporateWithUuid[],
    [shareholders],
  );

  /**
   * In the company flow, if they have selected they have a corporate shareholder but not added one
   */
  const hasNotAddedCompanyShareholder = useMemo(
    () => hasCompanyShareholders === true && !corporateShareholders.length,
    [corporateShareholders.length, hasCompanyShareholders],
  );

  /**
   * In the corporate trust flow, they have selected they have a corporate shareholder but not added one
   */
  const hasNotAddedCorporateTrustShareholder = useMemo(
    () => hasCorporateTrustShareholders === true && !corporateShareholders.length,
    [corporateShareholders.length, hasCorporateTrustShareholders],
  );

  const continueDisabled = useMemo(
    () => !shareholders.length || hasNotAddedCompanyShareholder || addCorporateMemberOpen || addIndividualMemberOpen,
    [addCorporateMemberOpen, addIndividualMemberOpen, hasNotAddedCompanyShareholder, shareholders.length],
  );

  const onEditIndividual = useCallback(
    (editedShareholder: IndividualWithUuid) => {
      setShareholders((prev) => [
        ...prev.filter((shareholder) => shareholder.uuid !== editedShareholder.uuid),
        { uuid: editedShareholder.uuid, shareholder: editedShareholder.individual },
      ]);
      const isAlsoDirector = directors.some((director) => director.uuid === editedShareholder.uuid);
      if (isAlsoDirector) {
        setDirectors((prev) => [
          ...prev.filter((director) => director.uuid !== editedShareholder.uuid),
          { uuid: editedShareholder.uuid, individual: editedShareholder.individual },
        ]);
      }
      const isAlsoBeneficiary = beneficiaries.some((beneficiary) => beneficiary.uuid === editedShareholder.uuid);
      if (isAlsoBeneficiary) {
        setBeneficiaries((prev) => [
          ...prev.filter((beneficiary) => beneficiary.uuid !== editedShareholder.uuid),
          { uuid: editedShareholder.uuid, individual: editedShareholder.individual },
        ]);
      }
    },
    [beneficiaries, directors],
  );

  const onEditCorporate = useCallback((editedShareholder: CorporateWithUuid) => {
    setShareholders((prev) => [
      ...prev.filter((shareholder) => shareholder.uuid !== editedShareholder.uuid),
      { uuid: editedShareholder.uuid, shareholder: editedShareholder.corporate },
    ]);
  }, []);

  const removeAllCorporateShareholders = useCallback(() => {
    setShareholders((prev) => prev.filter((shareholder) => shareholder.shareholder.type !== 'CORPORATE'));
    setHasCompanyShareholders(false);
    setHasCorporateTrustShareholders(false);
  }, []);

  return {
    addIndividualMemberOpen,
    addCorporateMemberOpen,
    continueDisabled,
    corporateShareholders,
    handleRemove,
    hasCompanyShareholders,
    setHasCompanyShareholders,
    hasCorporateTrustShareholders,
    setHasCorporateTrustShareholders,
    individualShareholders,
    onCancel,
    onAddCorporateShareholder,
    onAddMember,
    toggleAddCorporateMemberOpen,
    toggleAddIndividualMemberOpen,
    shareholders,
    entityOnboardingService,
    applicationData,
    onEditIndividual,
    onEditCorporate,
    removeAllCorporateShareholders,
    hasNotAddedCompanyShareholder,
    hasNotAddedCorporateTrustShareholder,
    directors,
    beneficiaries,
  };
};

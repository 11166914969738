import React from 'react';

import { Link as LinkMUI, LinkProps } from '@mui/material';

const Link: React.FC<LinkProps> = ({ id, children, ...props }) => (
  <LinkMUI id={id} {...props} target='_blank' rel='noopener noreferrer'>
    {children}
  </LinkMUI>
);

export default Link;

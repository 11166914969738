import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsInfoItem } from '@swyftx/aviary/molecules/SettingsItem/SettingsInfoItem';

import { UserStore } from '@shared/store';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { TaxPartnerKoinlyIntegration } from './TaxPartnerKoinlyIntegration';
import { taxPartners } from '../TaxReports.data';

type Props = {
  onClickKoinlyCta: () => void;
};

export const TaxPartnerCard: React.FC<Props> = ({ onClickKoinlyCta }) => {
  const { t } = useTranslation('profile.taxreports', { keyPrefix: 'taxPartners' });
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { userCountryCurrencyCode, isEntity } = UserStore.useUserStore;

  const partners = useMemo(
    () => taxPartners.filter((tp) => tp.countryCode === userCountryCurrencyCode),
    [userCountryCurrencyCode],
  );

  const showKoinlyIntegration = useMemo(
    () => isFeatureEnabled(AppFeature.KoinlySso) && !isEntity(),
    [isFeatureEnabled, isEntity],
  );

  return (
    <SettingsCluster
      title={t('title')}
      items={partners.map((partner) => (
        <FlexLayout direction='column' key={partner.id}>
          <SettingsInfoItem
            title={
              <FlexLayout direction='row' spacing={4} className='mb-8 items-center'>
                <Body weight='emphasis'>{t(partner.name as any)}</Body>
                {partner.chipText && (
                  <Chip variant='subtle' size='sm' color='secondary'>
                    {t(partner.chipText as any)}
                  </Chip>
                )}
              </FlexLayout>
            }
            description={t(partner.description as any)}
            infoAction={
              <Button
                id={`${partner.id}-visit-button`}
                onClick={() => window.open(partner.link, '_blank', 'noopener,noreferrer')}
                variant='outlined'
                className='max-w-[15rem]'
              >
                {t(partner.linkText as any)}
              </Button>
            }
            infoItem={<FlexLayout className='w-4/5 items-center justify-center'>{partner.logo}</FlexLayout>}
          />
          {partner.id === 'tax-partner-koinly' && showKoinlyIntegration && (
            <TaxPartnerKoinlyIntegration onClickCta={onClickKoinlyCta} />
          )}
        </FlexLayout>
      ))}
    />
  );
};

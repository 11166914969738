import React, { useState, useCallback, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AddUser, Cross, Hide, Phone } from '@swyftx/aviary/icons/outlined';
import { Menu } from '@swyftx/aviary/molecules/Menu';

import { ENTITY_ASSISTANCE_PHONE_CONSULT_URL } from '@shared/constants/urls';
import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

export const AppHeaderEntityCta: React.FC = observer(() => {
  const { isUserVerified, isKyc1Complete, userProfile, entityAccounts } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();
  const [open, setOpen] = useState<boolean>(false);
  const [entityCtaDismissed, setEntityCtaDismissed] = useLocalStorage<boolean>(StorageKey.ENTITY_CTA_DISMISSED, false);

  const { startedApplication, entityCallToActionClicked, phoneConsultLinkCtaClicked, appHeaderEntityInfoButtonHidden } =
    useEntityOnboardingAnalytics();
  const { entityHistoryFetching, startNewEntityApplication, hasASubmittedSubmission } =
    useEntityOnboardingApplication();

  const hasEntityAccount = useMemo(() => entityAccounts.some((account) => account.isEntity), [entityAccounts]);

  /**
   * Should only show the call to action if:
   * - They haven't dismissed it
   * - They haven't submitted an application
   * - They have not showed intent of already applying for an entity account
   * - They do not already have an entity account
   */
  const showEntityCta = useMemo(
    () =>
      !entityHistoryFetching &&
      !entityCtaDismissed &&
      !hasASubmittedSubmission &&
      !userProfile?.userSettings.applyForEntityAccount &&
      !hasEntityAccount,
    [
      entityCtaDismissed,
      entityHistoryFetching,
      hasASubmittedSubmission,
      hasEntityAccount,
      userProfile?.userSettings.applyForEntityAccount,
    ],
  );

  const handlePhoneConsultCtaClicked = useCallback(() => {
    phoneConsultLinkCtaClicked({ callToActionLocation: 'app_header' });
    window.open(ENTITY_ASSISTANCE_PHONE_CONSULT_URL, '_blank', 'noopener,noreferrer');
  }, [phoneConsultLinkCtaClicked]);

  const handleNavigateToApplication = useCallback(() => {
    startedApplication();
    entityCallToActionClicked({ callToActionName: 'Create Entity HeaderNav call to action' });
    startNewEntityApplication();
  }, [startNewEntityApplication, startedApplication]);

  const handleDismiss = useCallback(() => {
    appHeaderEntityInfoButtonHidden();
    setOpen(false);
    setEntityCtaDismissed(true);
  }, [appHeaderEntityInfoButtonHidden, setEntityCtaDismissed]);

  const canCompleteEntityApplication = useMemo(
    () => isUserVerified() && isKyc1Complete(),
    [isKyc1Complete, isUserVerified],
  );

  const handleOpenChange = useCallback(
    (newState?: boolean) => {
      if (newState) {
        entityCallToActionClicked({ callToActionName: 'Create Entity App Header Button' });
      }
      setOpen(newState ?? false);
    },
    [entityCallToActionClicked],
  );

  if (!showEntityCta) {
    return null;
  }

  return (
    <Menu
      triggerContent={
        <Button variant='outlined' leadingIcon={<AddUser />}>
          Create entity account
        </Button>
      }
      open={open}
      onOpenChange={handleOpenChange}
      className='min-w-[25rem] p-4'
    >
      <FlexLayout direction='column' spacing={16}>
        <FlexLayout direction='row' className='w-full justify-between'>
          <Body size='large' weight='emphasis'>
            Investing on behalf of an entity
          </Body>
          <Button variant='outlined' leadingIcon={<Cross />} onClick={() => setOpen(false)} layout='icon' size='sm' />
        </FlexLayout>
        <Body color='secondary'>
          An entity account acts as a sub-account attached to your existing personal account, allowing you to invest on
          behalf of a Company, Self Managed Super Fund or Trust.
        </Body>
        <FlexLayout direction='column' spacing={4}>
          <Body size='small' weight='emphasis'>
            Requirements:
          </Body>
          <ul className='list-disc pl-16 text-12 text-color-text-secondary'>
            <li>
              <Body size='small' color='secondary'>
                Your personal account must be verified to Gold level.
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                You must have an existing Entity (Company, self managed super fund or trust) setup. If you do not have
                one, please book a phone consult.
              </Body>
            </li>
          </ul>
        </FlexLayout>
        <FlexLayout direction='column' spacing={4}>
          <Body size='small' weight='emphasis'>
            Accepted entity types:
          </Body>
          <ul className='list-disc pl-16 text-12 text-color-text-secondary'>
            <li>
              <Body size='small' color='secondary'>
                Companies (Pty Ltd)
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                Self Managed Super Funds (SMSF)
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                Trusts
              </Body>
            </li>
          </ul>
        </FlexLayout>
        <FlexLayout direction='column' spacing={8}>
          {!canCompleteEntityApplication && (
            <Button onClick={() => navigate(NavigationURLs.ProfileVerification)}>Update to Gold</Button>
          )}
          {canCompleteEntityApplication && (
            <Button onClick={handleNavigateToApplication}>Start entity application</Button>
          )}

          <Button variant='outlined' leadingIcon={<Phone />} className='w-full' onClick={handlePhoneConsultCtaClicked}>
            Book a phone consult
          </Button>
          <Button
            variant='ghost'
            leadingIcon={<Hide className='text-color-text-error' />}
            color='destructive'
            className='icon-color-text-error text-color-text-error'
            onClick={handleDismiss}
          >
            Hide this button
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Menu>
  );
});

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { useToggle } from 'react-use';

import { SingleWalletHoldingsDetails, SingleWalletPerformance } from './components';

type Props = {
  asset: Asset;
};

const SingleWalletHoldings: React.FC<Props> = ({ asset }) => {
  const baseAsset = useBaseAsset();
  const [costBasis, toggleCostBasis] = useToggle(false);

  return (
    <Card>
      <FlexLayout direction='column'>
        <SingleWalletHoldingsDetails asset={asset} costBasis={costBasis} toggleCostBasis={toggleCostBasis} />
        {baseAsset ? <SingleWalletPerformance asset={asset} baseAsset={baseAsset} costBasis={costBasis} /> : null}
      </FlexLayout>
    </Card>
  );
};

export { SingleWalletHoldings };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputProps } from '@mui/material/Input';

import { Input, Notification, pxToRem, Stack } from '@swyftx/react-web-design-system';

import { AutocompleteDropdown, AutocompleteOption } from '@global-components/AutocompleteDropdown/AutocompleteDropdown';
import { FormInputItem } from '@global-components/FormInputItem';
import { EmailInput, PhoneNumberInput } from '@global-components/Input';
import { SelectionBox } from '@global-components/SelectionBox';
import Wizard from '@global-components/Wizard';

import { api } from '@shared/api';
import { EntityMembershipType, EntityPermission } from '@shared/api/@types/entity';
import { CountriesEnum, EntityTypeEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import entityService from '@shared/services/entityService';
import { UserStore } from '@shared/store';
import entityHelpers from '@shared/utils/lib/entityHelpers';

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { observer } from 'mobx-react-lite';

import { useEntityMembershipTypeLabels, useEntityPermissionOptions } from '../EntityAddMemberWizard.hooks';

const INPUT_PROPS: InputProps = {
  sx: {
    height: '48px',
  },
};

type Props = {
  onClose: () => void;
  onSuccess: (name: string) => void;
};

export const EntityAddMemberDetailsStep: React.FC<Props> = observer(({ onClose, onSuccess }) => {
  const { t } = useTranslation('profile');
  const { userProfile, entityUuid } = UserStore.useUserStore;
  const entityType = userProfile?.entityDetails?.entityType;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  // Text inputs
  const [memberShipType, setMemberShipType] = useState<EntityMembershipType>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // Validation
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);

  // Dropdown inputs
  const [countryOfOrigin, setCountryOfOrigin] = useState<AutocompleteOption<CountriesEnum>>(
    entityHelpers.countryOptions.find((option) => option.value === userProfile?.countryCurrency?.code) ||
      entityHelpers.defaultCountryOption,
  );

  const [phoneCountry, setPhoneCountry] = useState(countryOfOrigin.value);

  const [permission, setPermission] = useState<AutocompleteOption<EntityPermission>>();

  // Hooks
  const permissionOptions = useEntityPermissionOptions();
  const membershipLabels = useEntityMembershipTypeLabels();

  const membershipTypes = (() => {
    if (
      entityType &&
      (entityType === EntityTypeEnum.COMPANY ||
        entityType === EntityTypeEnum.SMSF ||
        entityType === EntityTypeEnum.TRUST)
    ) {
      // Get the membershipTypes for a supported entity type (I.e. Company, SMSF or TRUST)
      return entityHelpers.entityMembershipTypes[entityType];
    }
    // safe fallback that should not be reached
    return entityHelpers.entityMembershipTypes[EntityTypeEnum.OTHER];
  })();

  const onMembershipTypeChange = (value: EntityMembershipType) => {
    setMemberShipType(value);
  };

  const isLocked =
    !countryOfOrigin ||
    !memberShipType ||
    !permission ||
    !emailValid ||
    !phoneValid ||
    !firstName ||
    !lastName ||
    !email ||
    !phone;

  const onAction = () => {
    setLoading(true);
    (async () => {
      try {
        if (!isLocked && entityUuid) {
          await api.endpoints.createNewEntityMember({
            params: { entityUuid },
            data: {
              stakeholder: memberShipType !== EntityMembershipType.None,
              type: entityType || EntityTypeEnum.PERSONAL,
              country: countryOfOrigin.value,
              scope: permission.value,
              nameFirst: firstName,
              nameLast: lastName,
              email,
              phone: parsePhoneNumber(phone, phoneCountry as CountryCode)?.formatNational(),
            },
          });

          // update the members list so the new member is shown right away
          await entityService.updateMembers();

          onSuccess(`${firstName} ${lastName}`);
        } else {
          throw new SwyftxError('Entity add member', 'Something went wrong');
        }
      } catch (e) {
        setError((e as SwyftxError)?.errorMessage || 'Something went wrong');
      }
    })();
    setLoading(false);
  };

  return (
    <Wizard.Step
      actionName={t('entities.addMemberModal.buttonLabels.action')}
      cancelName={t('entities.addMemberModal.buttonLabels.cancel')}
      title={t('entities.addMemberModal.title')}
      actionLoading={loading}
      onAction={onAction}
      onClose={onClose}
      locked={isLocked}
    >
      <Stack spacing={2}>
        <Stack
          spacing={2}
          direction='row'
          sx={{
            '& input': {
              fontSize: pxToRem(14),
            },
          }}
        >
          <FormInputItem label={t('entities.addMemberModal.labels.firstName')} required>
            <Input
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={t('entities.addMemberModal.placeholders.firstName')}
              InputProps={INPUT_PROPS}
            />
          </FormInputItem>
          <FormInputItem label={t('entities.addMemberModal.labels.lastName')} required>
            <Input
              onChange={(e) => setLastName(e.target.value)}
              placeholder={t('entities.addMemberModal.placeholders.lastName')}
              InputProps={INPUT_PROPS}
            />
          </FormInputItem>
        </Stack>
        <Stack spacing={2} direction='row'>
          <FormInputItem label={t('entities.addMemberModal.labels.phoneNumber')} required>
            <PhoneNumberInput
              onChange={(e) => setPhone(e.target.value)}
              country={countryOfOrigin.value}
              onCountryChange={setPhoneCountry}
              setValid={setPhoneValid}
              hideLabel
            />
          </FormInputItem>
          <FormInputItem label={t('entities.addMemberModal.labels.email')} required>
            <EmailInput
              placeholder={t('entities.addMemberModal.placeholders.email')}
              onChange={(e) => setEmail(e.target.value)}
              setValid={setEmailValid}
              hideLabel
            />
          </FormInputItem>
        </Stack>
        <Stack spacing={2} direction='row'>
          <FormInputItem label={t('entities.addMemberModal.labels.country')} required>
            <AutocompleteDropdown
              id='entity-member-country-dropdown'
              options={entityHelpers.countryOptions}
              placeholder={t('entities.addMemberModal.placeholders.country')}
              onChange={setCountryOfOrigin}
            />
          </FormInputItem>
          <FormInputItem label={t('entities.addMemberModal.labels.permission')} required>
            <AutocompleteDropdown
              id='entity-member-permissions-dropdown'
              options={permissionOptions}
              placeholder={t('entities.addMemberModal.placeholders.permission')}
              onChange={setPermission}
            />
          </FormInputItem>
        </Stack>
        <FormInputItem label={t('entities.addMemberModal.labels.membershipType')} width='100%' required>
          <Stack spacing={1} width='100%' direction='row'>
            {membershipTypes.map((type) => (
              <SelectionBox
                key={type}
                checked={type === memberShipType}
                onClick={onMembershipTypeChange}
                label={membershipLabels[type]}
                value={type}
              />
            ))}
          </Stack>
        </FormInputItem>
        {error?.length ? (
          <Notification title='Error' severity='error'>
            {error}
          </Notification>
        ) : null}
      </Stack>
    </Wizard.Step>
  );
});

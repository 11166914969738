import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { HIGH_SLIPPAGE_THRESHOLD } from 'src/lib/trade/trade.consts';

import { SwyftxProHighSlippageModal } from '../SwyftxProHighSlippageModal';

type Props = {
  selectedAsset: Asset;
  marketPrice: string;
  quotePrice: string;
  slippagePercentage?: number;
};

const SwyftxProTradeHighSlippageWarning: React.FC<Props> = observer(
  ({ selectedAsset, marketPrice, quotePrice, slippagePercentage }) => (
    <FlexLayout spacing={8} alignItems='start' justifyContent='start'>
      <AlertFilled className='!h-24 !w-24' />
      <div>
        <Body className='inline' size='small' weight='emphasis'>
          {selectedAsset.code}
        </Body>
        <Body className='inline' size='small'>
          {' '}
          order impacted by{' '}
        </Body>
        <SwyftxProHighSlippageModal
          marketPrice={marketPrice}
          quotePrice={quotePrice}
          slippagePercentage={slippagePercentage}
        >
          <Body
            size='small'
            className='ml-2 inline cursor-pointer underline decoration-dashed decoration-1 underline-offset-4'
          >
            price slippage.
          </Body>
        </SwyftxProHighSlippageModal>
        <Body className='ml-2 inline' size='small'>
          Slippage is greater than {HIGH_SLIPPAGE_THRESHOLD}%.
        </Body>
      </div>
    </FlexLayout>
  ),
);

export { SwyftxProTradeHighSlippageWarning };

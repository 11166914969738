import React, { useContext } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';
import { useFilterTourGuides } from '@swyftx/aviary/molecules/TourGuide/useFilterTourGuides';

import { useChangeAutoLogoutTour } from 'src/lib/feature-discovery/tours/profile/security/changeAutoLogout.tour';
import { useChangePasswordTour } from 'src/lib/feature-discovery/tours/profile/security/changePassword.tour';
import { useSetTwoFactorAuthenticationTour } from 'src/lib/feature-discovery/tours/profile/security/setTwoFactorAuthentication.tour';

type Props = {
  search?: string;
};

const AccountSecurityTutorials: React.FC<Props> = ({ search }) => {
  const { startTour } = useContext(TourGuideContext);
  const twoFactorTour = useSetTwoFactorAuthenticationTour();
  const changePasswordTour = useChangePasswordTour();
  const autoLogoutTour = useChangeAutoLogoutTour();

  const tours = useFilterTourGuides({ guides: [twoFactorTour, changePasswordTour, autoLogoutTour], search });

  if (!tours.length) return null;

  return (
    <ElementContainer title='Account security' className='w-full'>
      <Card className='w-full p-8'>
        <FlexLayout direction='column' spacing={8}>
          {tours.map((tour) => (
            <ListItem onClick={() => startTour(tour)} key={tour.name}>
              <FlexLayout alignItems='center' spacing={16}>
                {tour.icon}
                <FlexLayout direction='column' alignItems='start'>
                  <Body size='large' weight='bold'>
                    {tour.name}
                  </Body>
                  <Body color='secondary' className='text-start'>
                    {tour.description}
                  </Body>
                </FlexLayout>
              </FlexLayout>
            </ListItem>
          ))}
        </FlexLayout>
      </Card>
    </ElementContainer>
  );
};

export { AccountSecurityTutorials };

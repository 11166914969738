import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { observer } from 'mobx-react-lite';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

import { VerifyAccountWidget, FundAccountWidget, FirstTradeWidget } from '../../components';

const VerificationAccordionWidget: React.FC = observer(() => {
  const { isVerified, hasDeposited, hasTraded } = useProfileStatus();

  return (
    <>
      <Card className='p-0'>
        <FlexLayout direction='column' alignItems='center' justifyContent='space-between' spacing={0}>
          <div className='w-full p-24'>
            <VerifyAccountWidget
              completed={isVerified}
              isCurrentStep={!isVerified}
              variant='accordion'
              direction='row'
            />{' '}
          </div>
          <div className=' h-[1px] w-full bg-color-border-main' />
          <div className='w-full p-24'>
            <FundAccountWidget
              completed={hasDeposited}
              isCurrentStep={isVerified}
              variant='accordion'
              direction='row'
            />
          </div>
          <div className=' h-[1px] w-full bg-color-border-main' />
          <div className='w-full p-24'>
            <FirstTradeWidget completed={hasTraded} isCurrentStep={hasDeposited} variant='accordion' direction='row' />
          </div>
        </FlexLayout>
      </Card>
    </>
  );
});

export { VerificationAccordionWidget };

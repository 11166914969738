import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Cross, Locked, Unlocked } from '@swyftx/aviary/icons/outlined';

import { observer } from 'mobx-react-lite';
import { AssetAutoComplete } from 'src/lib/markets/components/AssetAutoComplete';

import { useAutoInvestAssetValueItem } from './useAutoInvestAssetValueItem';
import { AutoInvestAsset } from '../../autoInvest.types';

type Props = {
  assets: AutoInvestAsset[];
  excludedAssetCodes: string[];
  assetCode?: string;
  locked: boolean;
  percentage: number;
  onRemove: (assetCode: string) => void;
  onUpdatePercentage: (assetCode: string, percentage: number) => void;
  onUpdateAsset: (oldAssetCode: string, newAssetCode: string) => void;
  onLock: (assetCode: string) => void;
};

const AutoInvestAssetValueItem: React.FC<Props> = observer(
  ({
    assets,
    excludedAssetCodes,
    assetCode = '',
    locked,
    percentage,
    onRemove,
    onLock,
    onUpdatePercentage,
    onUpdateAsset,
  }) => {
    const { inputValue, minValue, maxValue, handleAssetChange, handleOnChange, handleOnBlur } =
      useAutoInvestAssetValueItem({
        assets,
        assetCode,
        percentage,
        onUpdatePercentage,
        onUpdateAsset,
      });

    return (
      <>
        <FlexLayout className='w-full' justifyContent='space-between' spacing={8}>
          <FlexLayout alignItems='center' spacing={12} className='w-1/2 sm:w-2/3' justifyContent='start'>
            <AssetAutoComplete value={assetCode} onSelect={handleAssetChange} excludedAssetCodes={excludedAssetCodes} />
          </FlexLayout>
          <FlexLayout className='w-1/2 flex-row sm:w-2/5' alignItems='center' spacing={8} justifyContent='end'>
            <Input
              color='secondary'
              className='text-right font-mono'
              placeholder='0'
              disabled={locked || !assetCode}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={inputValue}
              type='number'
              min={minValue}
              step={1}
              max={maxValue}
              trailing={
                <Numeric size='large' color='secondary'>
                  %
                </Numeric>
              }
            />
            <Button
              disabled={!assetCode}
              onClick={() => onLock(assetCode)}
              size='md'
              variant='outlined'
              layout='icon'
              className='text-color-text-inverse'
              leadingIcon={locked ? <Locked /> : <Unlocked />}
            />
            <Button
              onClick={() => onRemove(assetCode)}
              disabled={assets.length === 1}
              size='md'
              variant='outlined'
              layout='icon'
              leadingIcon={<Cross className='h-16 w-16' />}
            />
          </FlexLayout>
        </FlexLayout>
      </>
    );
  },
);

export { AutoInvestAssetValueItem };

import React, { createContext, PropsWithChildren } from 'react';

import { UserStore } from '@shared/store';

import {
  initialValues,
  KYCRequiredEnum,
  ProfileVerificationStoreSchema,
  VerificationStep,
} from '@Profile/subroutes/Verification/types';

import { observer, useLocalObservable } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';

export const ProfileVerificationContext = createContext<ProfileVerificationStoreSchema>(initialValues);

export const ProfileVerificationProvider: React.FC<PropsWithChildren<{}>> = observer(({ children }) => {
  const { showNewMessages } = useIntercom();
  const { isKyc0Required, isKyc1Required, isKyc2Required } = UserStore.useUserStore;

  const store = useLocalObservable(
    (): ProfileVerificationStoreSchema => ({
      ...initialValues,
      setVerificationStep: (verificationStep: VerificationStep) => {
        store.verificationStep = verificationStep;
      },
      resolveRestrictions: () => {
        if (isKyc0Required()) {
          showNewMessages('KYC0 Call');
          return KYCRequiredEnum.KYC0;
        }
        if (isKyc1Required()) {
          store.setVerificationStep(VerificationStep.IdentityVerificationSummary);
          return KYCRequiredEnum.KYC1;
        }
        if (isKyc2Required()) {
          store.setVerificationStep(VerificationStep.FundsSummary);
          return KYCRequiredEnum.KYC2;
        }
        showNewMessages('');
        return null;
      },
      setJumioTransaction: (transaction) => {
        store.jumioTransaction = transaction;
      },
      cleanup: () => {
        store.verificationStep = VerificationStep.None;
      },
    }),
  );

  return <ProfileVerificationContext.Provider value={store}>{children}</ProfileVerificationContext.Provider>;
});

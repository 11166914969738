import React from 'react';
import { useTranslation } from 'react-i18next';

import Close from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/material';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { BodyProps } from '@swyftx/aviary/atoms/Typography/Body/Body.styles';
import { Divider, IconButton, Modal, Stack } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';

import { observer } from 'mobx-react-lite';
import { useRates } from 'src/lib/rates/hooks';

import { TradeFeeBreakdownItem } from './components';

type TradeFeeBreakdownProps = {
  open: boolean;
  onClose: () => void;
};

const headerTypographyProps: BodyProps = {
  size: 'large',
  weight: 'bold',
};

const TradeFeeBreakdown: React.FC<TradeFeeBreakdownProps> = observer(({ open, onClose }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeFeeBreakdown' });
  const { getFeeDetail } = useUniversalTradeUtilityStore();
  const { tradeData } = UniversalTradeStore;
  const theme = useTheme();
  const countryAsset = useCountryAsset();
  const baseAsset = useBaseAsset();
  const { getRate } = useRates();

  const feeDetails = getFeeDetail(countryAsset);
  const feeAsset = assetService.getAsset(feeDetails?.totalAssetId || 0);

  if (!feeDetails || !feeAsset) {
    return null;
  }

  const getFeeForTrade = (from: number, to: number) =>
    feeDetails.breakdowns.find((e) => e.from === from && e.to === to)!;

  return (
    <Modal
      open={open}
      variant='Modal'
      onClose={onClose}
      sx={{
        width: `calc(100% - ${theme.spacing(4)})`,
        maxWidth: '40rem',
      }}
    >
      <Stack spacing={2} padding={2} paddingTop={1}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Body {...headerTypographyProps}>{t('labels.feeBreakdown')}</Body>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box>
          <Divider sx={{ position: 'absolute', width: '100%', left: 0 }} />
        </Box>
        <Stack
          spacing={2}
          paddingX={1}
          overflow='hidden'
          sx={{
            maxHeight: '60vh',
            overflow: 'auto',
          }}
          divider={
            <Box>
              <Divider />
            </Box>
          }
        >
          {Object.values(tradeData)
            .filter(({ from, to }) => from && to)
            .map(({ from, to }) => (
              <TradeFeeBreakdownItem
                key={`${from}_${to}`}
                tradeDataKey={`${from}_${to}`}
                feeBreakdown={getFeeForTrade(from, to)}
              />
            ))}
        </Stack>
        <Divider />
        <Stack width='100%' justifyContent='space-between' direction='row' alignItems='flex-start'>
          <Body {...headerTypographyProps}>{t('labels.feeTotal')}</Body>
          <FlexLayout direction='column' alignItems='end'>
            <FormattedText
              typographyProps={{ ...headerTypographyProps, color: 'primary' }}
              prefix={feeDetails.totalPrefix}
              value={feeDetails.totalFee}
              currency={feeAsset}
              formatOpts={{ appendCode: true }}
            />
            <Numeric>
              ~
              {`${formatCurrency(feeDetails.totalFee.times(getRate(feeAsset).askPrice), baseAsset, {
                appendCode: true,
                priceScale: baseAsset?.assetType === AssetType.Fiat ? 2 : baseAsset?.price_scale,
                hideSymbol: false,
              })}`}
            </Numeric>
          </FlexLayout>
        </Stack>
      </Stack>
    </Modal>
  );
});

export { TradeFeeBreakdown };

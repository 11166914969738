import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Coin, Star, TrendLineDown, TrendLineUp, Wallet, WhatsNew } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { AssetListTabTypes } from '../AssetList.types';

interface Props {
  onSelectTab: (tab: AssetListTabTypes) => void;
  selectedTab: AssetListTabTypes;
  activePath: boolean;
}

export const AssetListTabs: React.FC<Props> = (props) => {
  const { onSelectTab, selectedTab, activePath } = props;

  const getHumanReadableTextAndIcon = useCallback((tab: AssetListTabTypes) => {
    switch (tab) {
      case AssetListTabTypes.ALL:
        return { text: 'All', icon: <Coin /> };
      case AssetListTabTypes.FAVOURITES:
        return { text: 'Favourites', icon: <Star /> };
      case AssetListTabTypes.GAINERS:
        return { text: 'Gainers', icon: <TrendLineUp /> };
      case AssetListTabTypes.LOSERS:
        return { text: 'Losers', icon: <TrendLineDown /> };
      case AssetListTabTypes.NEW:
        return { text: 'New', icon: <WhatsNew /> };
      case AssetListTabTypes.OWNED:
        return { text: 'Owned', icon: <Wallet /> };
      default:
        tab satisfies never;
        return { text: '', icon: <></> };
    }
  }, []);

  return (
    <FlexLayout direction='row' spacing={8}>
      {Object.values(AssetListTabTypes).map((tab) => {
        const { text, icon } = getHumanReadableTextAndIcon(tab);
        return (
          <Button
            variant={activePath && selectedTab === tab ? 'filled' : 'outlined'}
            key={tab}
            onClick={() => onSelectTab(tab)}
            color={activePath && selectedTab === tab ? 'inverse' : undefined}
            leadingIcon={icon}
            selected={activePath && selectedTab === tab}
            size='sm'
            className={cn(
              activePath && selectedTab === tab
                ? 'hover:bg-color-text-primary'
                : 'hover:bg-color-background-surface-hover hover:outline-color-border-main',
            )}
          >
            {text}
          </Button>
        );
      })}
    </FlexLayout>
  );
};

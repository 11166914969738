import React, { useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';

import { Button, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

import { ApiKeyTypeItem } from '../useApiKeyTypes.hooks';

type Props = {
  item: ApiKeyTypeItem;
};

const ApiKeyTypeCard: React.FC<Props> = observer(({ item }) => {
  const { newApiKeyPayload, setNewApiKeyPayload } = useContext(ApiKeysContext);
  const { bx } = useContentBreakpoint();

  const selected = useMemo(() => newApiKeyPayload.keyType === item.type, [newApiKeyPayload]);

  return (
    <Button
      variant='outlined'
      onClick={() => setNewApiKeyPayload({ keyType: item.type })}
      sx={{
        width: '100%',
        height: bx({ xs: '94px', md: '74px' }),
        borderColor: 'divider',
        ':hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' height='100%'>
        <Stack direction='row' spacing={2}>
          <Stack direction='column' justifyContent='center'>
            <Box height='20px' width='20px' color='text.secondary'>
              {item.icon}
            </Box>
          </Stack>

          <Stack direction='column' spacing={1} textAlign='start'>
            <Typography fontSize={pxToRem(14)} fontWeight={500}>
              {item.title}
            </Typography>
            <Typography fontSize={pxToRem(12)} color='text.secondary'>
              {item.subTitle}
            </Typography>
          </Stack>
        </Stack>

        <Radio checked={selected} />
      </Stack>
    </Button>
  );
});

export { ApiKeyTypeCard };

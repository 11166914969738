import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Stablecoins: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M9.14844 10.2261C9.14844 9.0797 10.0776 8.14893 11.2256 8.14893H11.2793V7.72293C11.2793 7.32528 11.6017 7.00293 11.9993 7.00293C12.3969 7.00293 12.7193 7.32528 12.7193 7.72293V8.14893H13.7457C14.1433 8.14893 14.4657 8.47128 14.4657 8.86893C14.4657 9.26657 14.1433 9.58893 13.7457 9.58893H12.074C12.0494 9.59145 12.0245 9.59275 11.9993 9.59275C11.9741 9.59275 11.9492 9.59145 11.9246 9.58893H11.2256C10.8735 9.58893 10.5884 9.8743 10.5884 10.2261C10.5884 10.5779 10.8735 10.8632 11.2256 10.8632H12.7762C13.9242 10.8632 14.8533 11.794 14.8533 12.9405C14.8533 14.0882 13.9239 15.0176 12.7762 15.0176H12.7193V15.4364C12.7193 15.8341 12.3969 16.1564 11.9993 16.1564C11.6017 16.1564 11.2793 15.8341 11.2793 15.4364V15.0176H10.2561C9.85844 15.0176 9.53609 14.6953 9.53609 14.2976C9.53609 13.9 9.85844 13.5776 10.2561 13.5776H11.9661C11.9771 13.5771 11.9882 13.5769 11.9993 13.5769C12.0104 13.5769 12.0215 13.5771 12.0325 13.5776H12.7762C13.1286 13.5776 13.4133 13.2929 13.4133 12.9405C13.4133 12.5886 13.1282 12.3032 12.7762 12.3032H11.2256C10.0776 12.3032 9.14844 11.3725 9.14844 10.2261Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.64062 11.5802C2.64062 6.41049 6.8309 2.22021 12.0006 2.22021C17.1703 2.22021 21.3606 6.41049 21.3606 11.5802C21.3606 16.7499 17.1703 20.9402 12.0006 20.9402C6.8309 20.9402 2.64062 16.7499 2.64062 11.5802ZM12.0006 3.66021C7.62619 3.66021 4.08062 7.20578 4.08062 11.5802C4.08062 15.9546 7.62619 19.5002 12.0006 19.5002C16.375 19.5002 19.9206 15.9546 19.9206 11.5802C19.9206 7.20578 16.375 3.66021 12.0006 3.66021Z'
      fill='currentColor'
    />
  </svg>
);

export { Stablecoins };

import React, { useMemo } from 'react';

import { Tabs, TabsContent, TabsList } from '@swyftx/aviary/atoms/Tabs';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

import { tabsListVariants } from './EnhancedTabs.styles';
import { EnhancedTab } from './EnhancedTabs.types';
import { EnhancedTabsTrigger } from './EnhancedTabsTrigger';

type Props<T> = {
  value?: T;
  onChange?: (newValue: T) => void;
  variant?: 'parent' | 'child' | 'default' | 'header';
  size?: 'sm' | 'md';
  fullWidth?: boolean;
  tabs: EnhancedTab<T>[];
  spotlightElementId?: string;
  disabled?: boolean;
};

const EnhancedTabs = <T extends string>({
  value,
  variant = 'child',
  size = 'md',
  onChange,
  tabs,
  disabled = false,
  fullWidth = false,
  spotlightElementId,
}: Props<T>) => {
  const fontSize = useMemo(() => {
    switch (size) {
      case 'md':
        return variant === 'header' ? 'medium' : 'small';
      case 'sm':
        return 'xsmall';
    }
  }, [size, variant]);

  const renderWithTooltip = (tab: EnhancedTab<T>, element: React.ReactNode) => (
    <Tooltip
      title={tab.tooltip || tab.title}
      key={tab.title}
      className='z-button-tooltip'
      align='end'
      side='top'
      sideOffset={16}
    >
      {element}
    </Tooltip>
  );

  return (
    <Tabs value={value} className={fullWidth ? 'w-full' : ''}>
      <TabsList data-spotlightelementid={spotlightElementId} className={cn(tabsListVariants({ variant, fullWidth }))}>
        {tabs.map((tab) => (
          <EnhancedTabsTrigger
            key={tab.title}
            tab={tab}
            disabled={disabled || tab.disabled}
            onChange={onChange}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
            value={value}
            spotlightElementId={tab.spotlightElementId}
          >
            {!tab.icon ? (
              <>
                {variant === 'header'
                  ? renderWithTooltip(
                      tab,
                      <Heading
                        size='h6'
                        className={cn('uppercase', tab.value === value ? '' : 'hover:text-color-text-secondary')}
                        color={tab.value === value ? 'accent' : 'primary'}
                      >
                        {tab.title}
                      </Heading>,
                    )
                  : renderWithTooltip(
                      tab,
                      <Body weight='emphasis' size={fontSize}>
                        {tab.title}
                      </Body>,
                    )}
              </>
            ) : (
              renderWithTooltip(tab, <div>{tab.icon}</div>)
            )}
          </EnhancedTabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) =>
        tab.element ? (
          <TabsContent value={tab.value} key={tab.value}>
            {tab.element}
          </TabsContent>
        ) : null,
      )}
    </Tabs>
  );
};

export { EnhancedTabs };

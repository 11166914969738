import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CancelRounded from '@mui/icons-material/CancelRounded';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CloudCircle from '@mui/icons-material/CloudCircle';

import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Button, PIIClassName, Stack, Table, TableCellProperties, TableRow } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import { Asset, AssetHistoryItemStatus, TransactionHistoryResponse, WithdrawalHistoryResponse } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { formatDate, formatTime } from '@shared/utils';

import { TransactionHistoryItem, TransactionTypeFilter } from '@services/WalletService';
import { isDepositHistoryArray, isWithdrawalHistoryArray } from '@utils/guards/transactions';

import { headerTypographyProps, rowTypographyProps, tableStyles } from './AssetTransactionHistoryModal.data';
import { useAssetTransactionHistory, useAssetWalletHistory } from './AssetTransactionHistoryModals.hooks';

const TRANSACTIONS_PER_PAGE = 10;

export const useAssetTransactionHistoryTable = (
  asset: Asset,
  type: TransactionTypeFilter.DEPOSIT | TransactionTypeFilter.WITHDRAWAL,
) => {
  const { t } = useTranslation('modals');
  const {
    transactions,
    loading: loadingTransactions,
    page,
    incrementPage,
    hasAnotherPage,
  } = useAssetTransactionHistory(asset, [type], { transactionsPerPage: TRANSACTIONS_PER_PAGE });

  const { walletHistory, loading: loadingWalletHistory } = useAssetWalletHistory(
    asset,
    type,
    0,
    0,
    TRANSACTIONS_PER_PAGE,
    true,
    page,
  );
  const isFiat = assetService.isAssetFiat(asset.id);

  const getHistoryItem = (transactionId: string) => {
    if (walletHistory === null) {
      return null;
    }

    if (isDepositHistoryArray(walletHistory)) {
      return walletHistory.find((item) => item.depositId === transactionId);
    }

    if (isWithdrawalHistoryArray(walletHistory)) {
      return walletHistory.find((item) => item.withdrawalId === transactionId);
    }

    return null;
  };

  const getFiatHeaders = (isDeposit: boolean) => {
    let headers: Array<TableCellProperties> = [];

    // date
    headers.push({
      id: 'asset-transaction-history-date-header',
      children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.date')}</Numeric>,
    });

    if (isDeposit) {
      // deposit method
      headers.push({
        id: 'asset-transaction-history-label-header',
        children: (
          <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.depositMethod')}</Numeric>
        ),
      });
    } else {
      // account name
      headers.push({
        id: 'asset-transaction-history-label-header',
        children: (
          <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.accountName')}</Numeric>
        ),
      });
    }

    // address
    headers.push({
      id: 'asset-transaction-history-address',
      children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.address')}</Numeric>,
    });

    // status
    headers.push({
      id: 'asset-transaction-history-status-header',
      children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.status')}</Numeric>,
    });

    // fee
    headers.push({
      id: 'asset-transaction-history-status-header',
      children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.fee')}</Numeric>,
    });

    // amount
    headers.push({
      id: 'asset-transaction-history-amount-header',
      children: (
        <Numeric {...headerTypographyProps} className='text-end'>
          {t('assetTransactionHistory.table.headers.amount', {
            type: isDeposit
              ? t('assetTransactionHistory.table.headers.deposited')
              : t('assetTransactionHistory.table.headers.withdrawn'),
          })}
        </Numeric>
      ),
    });

    return headers;
  };

  const getHeaders = (): Array<TableCellProperties> => {
    let headers: Array<TableCellProperties> = [];
    const isDeposit = type === TransactionTypeFilter.DEPOSIT;

    if (isFiat) {
      return getFiatHeaders(isDeposit);
    }

    headers = [
      {
        id: 'asset-transaction-history-date-header',
        children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.date')}</Numeric>,
      },
      {
        id: 'asset-transaction-history-address',
        children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.address')}</Numeric>,
      },
      {
        id: 'asset-transaction-history-label-header',
        children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.label')}</Numeric>,
      },
      {
        id: 'asset-transaction-history-status-header',
        children: <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.status')}</Numeric>,
      },
    ];

    if (!isDeposit) {
      headers.push({
        id: 'asset-transaction-history-tid-header',
        children: (
          <Numeric {...headerTypographyProps}>{t('assetTransactionHistory.table.headers.transactionId')}</Numeric>
        ),
      });
    }

    headers.push({
      id: 'asset-transaction-history-amount-header',
      children: (
        <Numeric {...headerTypographyProps} className='text-end'>
          {t('assetTransactionHistory.table.headers.amount', {
            type: isDeposit
              ? t('assetTransactionHistory.table.headers.deposited')
              : t('assetTransactionHistory.table.headers.withdrawn'),
          })}
        </Numeric>
      ),
    });

    return headers;
  };

  const getFiatCells = (
    transaction: TransactionHistoryItem,
    address: string | undefined,
    completed: boolean,
    failed: boolean,
    pending: boolean,
    historyItem: TransactionHistoryResponse | null | undefined,
  ) => [
    {
      id: `asset-transaction-history-date-${transaction.uuid}`,
      children: (
        <Numeric PII {...rowTypographyProps}>
          {`${formatDate(transaction.date)} ${formatTime(transaction.date)}`}
        </Numeric>
      ),
    },
    {
      id: `asset-transaction-history-label-${transaction.uuid}`,
      children: (
        <Numeric PII {...rowTypographyProps}>
          {transaction.label || '-'}
        </Numeric>
      ),
    },
    {
      id: `asset-transaction-history-address-${transaction.uuid}`,
      children: (
        <Tooltip className={PIIClassName} title={address || ''}>
          <div>
            <Numeric PII {...rowTypographyProps} className='max-w-[100px] truncate'>
              {address || 'N/A'}
            </Numeric>
          </div>
        </Tooltip>
      ),
    },
    {
      id: `asset-transaction-history-status-${transaction.uuid}`,
      children: (
        <Stack direction='row' alignItems='center' spacing={0.5}>
          {completed && <CheckCircle color='success' sx={{ width: '16px !important' }} />}
          {failed && <CancelRounded color='error' sx={{ width: '16px !important' }} />}
          {pending && <CloudCircle color='disabled' sx={{ width: '16px !important' }} />}
          <Numeric {...rowTypographyProps}>{transaction.status}</Numeric>
        </Stack>
      ),
    },
    {
      id: `asset-transaction-history-fee-${transaction.uuid}`,
      children:
        historyItem?.feeAmount || historyItem?.txFeeAmount ? (
          <Stack direction='row' alignItems='center' spacing={1}>
            <FormattedText
              typographyProps={rowTypographyProps}
              value={Big(historyItem?.feeAmount || 0)
                .plus(historyItem?.txFeeAmount || 0)
                .toString()}
              currency={asset}
              formatOpts={{ appendCode: true }}
            />
            <AssetIcon asset={asset} size={20} />
          </Stack>
        ) : (
          <Numeric color='primary'>-</Numeric>
        ),
    },
    {
      id: `asset-transaction-history-amount-${transaction.uuid}`,
      children: (
        <Stack PII direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
          <FormattedText
            typographyProps={rowTypographyProps}
            value={transaction.amount}
            currency={asset}
            formatOpts={{ appendCode: true }}
          />
          <AssetIcon asset={asset} size={20} />
        </Stack>
      ),
    },
  ];

  const getCells = (transaction: TransactionHistoryItem): Array<TableCellProperties> => {
    const historyItem = getHistoryItem(transaction.uuid);
    const address = historyItem?.address_details.address;
    const completed = transaction.status === AssetHistoryItemStatus.Completed;
    const failed = transaction.status === AssetHistoryItemStatus.Failed;
    const pending = transaction.status === AssetHistoryItemStatus.Pending;

    if (isFiat) {
      return getFiatCells(transaction, address, completed, failed, pending, historyItem);
    }

    const isDeposit = type === TransactionTypeFilter.DEPOSIT;
    let cells: Array<TableCellProperties> = [];

    cells = [
      {
        id: `asset-transaction-history-date-${transaction.uuid}`,
        children: (
          <Numeric PII {...rowTypographyProps}>
            {`${formatDate(transaction.date)} ${formatTime(transaction.date)}`}
          </Numeric>
        ),
      },
      {
        id: `asset-transaction-history-address-${transaction.uuid}`,
        children: (
          <Tooltip className={PIIClassName} title={address || ''}>
            <div>
              <Numeric PII {...rowTypographyProps} className='max-w-[100px] truncate'>
                {address || 'N/A'}
              </Numeric>
            </div>
          </Tooltip>
        ),
      },
      {
        id: `asset-transaction-history-label-${transaction.uuid}`,
        children: (
          <Numeric PII {...rowTypographyProps}>
            {transaction.label || '-'}
          </Numeric>
        ),
      },
      {
        id: `asset-transaction-history-status-${transaction.uuid}`,
        children: (
          <Stack direction='row' alignItems='center' spacing={0.5}>
            {completed && <CheckCircle color='success' sx={{ width: '16px !important' }} />}
            {failed && <CancelRounded color='error' sx={{ width: '16px !important' }} />}
            {pending && <CloudCircle color='disabled' sx={{ width: '16px !important' }} />}
            <Numeric PII {...rowTypographyProps}>
              {transaction.status}
            </Numeric>
          </Stack>
        ),
      },
    ];

    if (!isDeposit) {
      const transactionId = (historyItem as WithdrawalHistoryResponse)?.transactionId || transaction.uuid;

      cells.push({
        id: `asset-transaction-history-tid-${transaction.uuid}`,
        children: (
          <Numeric PII {...rowTypographyProps}>
            {transactionId}
          </Numeric>
        ),
      });
    }

    cells.push({
      id: `asset-transaction-history-amount-${transaction.uuid}`,
      children: (
        <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
          <FormattedText
            typographyProps={{ ...rowTypographyProps, PII: true }}
            value={transaction.amount}
            currency={asset}
            formatOpts={{ appendCode: true }}
          />
          <AssetIcon asset={asset} size={20} />
        </Stack>
      ),
    });

    return cells;
  };

  const headers = getHeaders();

  const rows = transactions.map(
    (transaction): TableRow => ({
      id: `asset-transaction-history-row-${transaction.uuid}`,
      cells: getCells(transaction),
    }),
  );

  if (hasAnotherPage) {
    rows.push({
      id: `asset-transaction-history-show-more`,
      cells: [
        {
          id: 'show-more-button',
          colSpan: 8,
          children: (
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} width='100%'>
              <Button variant='contained' onClick={incrementPage}>
                Show More
              </Button>
            </Stack>
          ),
        },
      ],
    });
  }

  const table = useMemo(() => {
    if (!rows.length) {
      return <Numeric color='secondary'>{t('assetTransactionHistory.noHistory')}</Numeric>;
    }

    return <Table stickyHeader headers={headers} rows={rows} sx={tableStyles} />;
  }, [loadingTransactions, loadingWalletHistory, rows, headers, t]);

  return {
    loadingTransactions,
    loadingWalletHistory,
    headers,
    rows,
    table,
  };
};

import React from 'react';

import SvgIconMUI, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

export const StyledIcon = styled(SvgIconMUI)`
  &.MuiSvgIcon-root {
    fill: none !important;
  }
`;

const DangerIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <StyledIcon {...props}>
    <path
      opacity='0.4'
      d='M11.2503 16.2696C11.2503 15.8556 11.5863 15.5136 12.0003 15.5136C12.4143 15.5136 12.7503 15.8446 12.7503 16.2586V16.2696C12.7503 16.6836 12.4143 17.0196 12.0003 17.0196C11.5863 17.0196 11.2503 16.6836 11.2503 16.2696Z'
      fill='currentColor'
    />
    <path
      d='M4.79572 20.4179H19.2777C20.4247 20.3579 21.3067 19.3809 21.2477 18.2339C21.2347 18.0019 21.1847 17.7729 21.0967 17.5589L13.8197 4.82292C13.2637 3.81792 11.9987 3.45392 10.9937 4.01092C10.6517 4.19892 10.3697 4.48092 10.1807 4.82292L2.90372 17.5589C2.47172 18.6229 2.98372 19.8349 4.04672 20.2679C4.26272 20.3549 4.49072 20.4049 4.72272 20.4179'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity='0.4'
      d='M11.9899 13.3961V10.2961'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledIcon>
);

export { DangerIcon };

/* eslint-disable new-cap */
import React, { useContext, useMemo, useRef } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Loading } from '@swyftx/aviary/icons/outlined';

import { SortKey, SortDirection, AssetHistoryItemStatus, TransactionType } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { DateTime } from 'luxon';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useTransactions } from 'src/lib/transactions/hooks/useTransactions';

import { TradingViewWidgetHeaderSimpleResolution } from './components/TradingViewWidgetHeader/TradingViewWidgetHeaderResolution/TradingViewWidgetHeaderSimpleResolution';
import { TradingViewContext } from './context/TradingView.context';
import { useCreateSimpleChart, useSimpleChartData, useSimpleChartMarkers } from './hooks/simple';
import { useSimpleChartEvents } from './hooks/simple/useSimpleChartEvents';
import { useSimpleChartSeries } from './hooks/simple/useSimpleChartSeries';
import { useSimpleChartTheme } from './hooks/simple/useSimpleChartTheme';

type Props = {
  className?: string;
  headerHeight?: number;
  onDateClick?: (date: DateTime) => void;
};

const HEADER_PADDING = 36;

const SimpleTradingView: React.FC<Props> = ({ className, headerHeight = 49, onDateClick }) => {
  const { primary, secondary, simpleChartState: chartState } = useContext(TradingViewContext);
  const chartPriceTooltipRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const chartVolumeRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const { getAssetByCode } = useMarkets();
  const secondaryAsset = useMemo(() => getAssetByCode(secondary), [getAssetByCode, secondary]);

  const { transactions } = useTransactions({
    asset: secondaryAsset,
    sortKey: SortKey.Date,
    sortDirection: SortDirection.ASCENDING,
    statuses: [AssetHistoryItemStatus.Completed],
    types: [TransactionType.Buy, TransactionType.Sell],
    pageLimit: 10000,
  });

  const { chartContainerRef } = useCreateSimpleChart({ className, secondaryAsset });

  const { data, latestData, timeStart, timeEnd, isFetching } = useSimpleChartData();
  const { series, volumeSeries } = useSimpleChartSeries({ chartState, data, latestData, isFetching, secondary });

  useSimpleChartEvents({
    chartState,
    chartContainerRef,
    chartPriceTooltipRef,
    chartVolumeRef,
    series,
    volumeSeries,
    transactions,
    onClickDate: onDateClick,
  });

  useSimpleChartMarkers({ series, transactions, timeStart, timeEnd });
  useSimpleChartTheme({ chartState });

  return (
    <>
      <div
        ref={chartContainerRef}
        id={`${secondary}${primary}`}
        style={{ height: `calc(100% - ${headerHeight}px - ${HEADER_PADDING}px)` }}
        className={cn('relative w-full', className)}
      >
        <FlexLayout className='absolute top-0 z-10' ref={chartVolumeRef} spacing={4} alignItems='center' />
        <Card
          ref={chartPriceTooltipRef}
          className='pointer-events-none absolute z-10 hidden rounded-[8px] border border-color-border-info p-8'
        />
        {isFetching && (
          <div className='absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-color-background-surface-primary opacity-50'>
            <Loading className=' h-48 w-48 animate-spin' />
          </div>
        )}
      </div>
      <div className='px-16 py-8'>
        <TradingViewWidgetHeaderSimpleResolution />
      </div>
    </>
  );
};

export { SimpleTradingView };

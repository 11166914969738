import { TransactionType } from '@shared/api/@types/portfolio';

import { TransactionHistoryItem } from '@services/WalletService';

export const getOrderType = (transaction: TransactionHistoryItem, isFiat?: boolean) => {
  const { orderType, type, label } = transaction;
  switch (orderType) {
    case 1:
      return 'Market Buy';
    case 2:
      return 'Market Sell';
    case 3:
      return 'Trigger Buy';
    case 4:
      return 'Trigger Sell';
    case 5:
      return 'Stop Buy';
    case 6:
      return 'Stop Sell';
    case 8:
      return 'Dust';
    case 9:
      return 'OTC Buy';
    case 10:
      return 'OTC Sell';
    default:
      return type === TransactionType.Deposit
        ? `Deposit from ${label || `external ${isFiat ? 'account ' : 'wallet'}`}`
        : `Withdraw to ${label || `external ${isFiat ? 'account' : 'wallet'}`}`;
  }
};

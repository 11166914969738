import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TickInCircleFilled } from '@swyftx/aviary/icons/filled';
import type { types } from '@swyftx/marketing-utils';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { RewardsContext } from '@routes/Rewards/Rewards.context';
import { RewardType } from '@routes/Rewards/types';

interface Props {
  onClose: () => void;
  reward: types.UserAssetReward;
}

const RedeemSwyftxEntryModal: React.FC<Props> = ({ reward, onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'redeemReward' });
  const { fetchRewards } = useContext(RewardsContext);
  const { userProfile } = UserStore.useUserStore;

  const onClickClose = async () => {
    if (userProfile?.intercom?.uuid) {
      fetchRewards(userProfile.intercom.uuid, RewardType.Available);
      fetchRewards(userProfile.intercom.uuid, RewardType.History);
    }

    onClose();
  };

  return (
    <Modal
      id='redeem-swyftx-entry-modal'
      open
      sx={{ width: '400px' }}
      variant='Modal'
      onClose={onClickClose}
      FooterProps={{
        content: (
          <Stack direction='row' width='100%' spacing={1}>
            <Button color='primary' fullWidth size='large' variant='contained' onClick={onClickClose}>
              {t('buttonLabels.ok')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack justifyContent='center' alignItems='center' spacing={2} padding={2}>
        <TickInCircleFilled className='h-[4rem] w-[4rem] text-color-text-success' />
        <Typography color='text.secondary' textAlign='center' fontWeight={700} fontSize={20} zIndex={2}>
          {t('entryTitle')}
        </Typography>
        <Typography textAlign='center' zIndex={2} fontSize={14}>
          {t('entryContent', { campaignName: reward.campaignName })}
        </Typography>
      </Stack>
    </Modal>
  );
};

export { RedeemSwyftxEntryModal };

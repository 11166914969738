import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Cross } from '@swyftx/aviary/icons/outlined';
import { EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { Asset } from '@shared/api';

import { TableName } from 'src/context/Avo/generated-avo';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';

import { AssetPickerTableData, assetPickerTabs, AssetPickerTabTypes } from './AssetPicker.types';
import { AssetPickerList } from './AssetPickerList';

interface Props {
  selectedAsset?: Asset;
  onSelectAsset: (asset: Asset) => void;
  baseNavigationUrl?: NavigationURLs;
  onClose: () => void;
  sort: EnhancedTableSort<AssetPickerTableData>;
  onSort: (nextSort?: EnhancedTableSort<AssetPickerTableData> | undefined) => Asset[];
  selectedTab: AssetPickerTabTypes;
  setSelectedTab: (newTab: AssetPickerTabTypes) => void;
  search: string;
  setSearch: (search: string) => void;
  assetsToShow: Asset[];
}

export const AssetPickerContent: React.FC<Props> = (props) => {
  const {
    onSelectAsset,
    selectedAsset,
    sort,
    onSort,
    selectedTab,
    setSelectedTab,
    search,
    setSearch,
    assetsToShow,
    baseNavigationUrl = NavigationURLs.UniversalTradeAsset,
    onClose,
  } = props;

  const isXs = useTailwindBreakpoint('xs');
  const { navigate } = useNavigateRoute();

  const { changedFilter } = useTradePageAnalytics();

  const onTabChange = useCallback(
    (newTab: AssetPickerTabTypes) => {
      setSelectedTab(newTab);
      changedFilter({ tableName: TableName.ASSET_PICKER, filterName: newTab, filterEnabled: true });
    },
    [changedFilter, setSelectedTab],
  );

  const onSelectItem = useCallback(
    (asset: Asset) => {
      navigate(baseNavigationUrl, { pathParams: { asset: asset.code } });
      onSelectAsset(asset);
      setSearch('');
    },
    [baseNavigationUrl, navigate, onSelectAsset, setSearch],
  );

  const handleClose = useCallback(() => {
    setSearch('');
    onClose();
  }, [onClose, setSearch]);

  return (
    <FlexLayout direction='column' spacing={12} className='w-full'>
      <FlexLayout direction='row' spacing={16} className='items-center px-8 pb-0 md:p-0'>
        <Input
          placeholder='Search for...'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onClear={() => setSearch('')}
        />
        {!isXs && <Button variant='outlined' layout='icon' leadingIcon={<Cross />} size='lg' onClick={handleClose} />}
      </FlexLayout>
      <FlexLayout direction='column' spacing={8} className='w-[100vw] px-8 sm:w-full md:px-0 '>
        <FlexLayout
          direction={isXs ? 'row' : 'column'}
          className='sm:overflow-x-none w-full overflow-x-scroll px-8 pb-6 pt-2 sm:!p-2'
        >
          <EnhancedTabs<AssetPickerTabTypes>
            variant='child'
            tabs={assetPickerTabs}
            onChange={onTabChange}
            value={selectedTab}
          />
        </FlexLayout>

        <AssetPickerList
          assetsToShow={assetsToShow}
          onSelectItem={onSelectItem}
          selectedItem={selectedAsset}
          sort={sort}
          onSort={onSort}
          selectedTab={selectedTab}
        />
      </FlexLayout>
    </FlexLayout>
  );
};

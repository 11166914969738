import React from 'react';
import { useLocation } from 'react-router-dom';

import { CrossInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Card, Grid, Stack, Typography } from '@swyftx/react-web-design-system';

import { observer } from 'mobx-react-lite';

import { defaultMessages } from './Messages.data';

export const Messages = observer(() => {
  const params = new URLSearchParams(useLocation().search);
  const messageId = params.get('messageId') ?? '';
  const messageContent = defaultMessages[messageId];

  return (
    <Grid container alignItems='center' justifyContent='center' height='100%' zIndex={1000000}>
      <Card elevation={0} variant='outlined'>
        <Stack alignItems='center' justifyContent='center' spacing={1} padding={1}>
          {messageContent ? (
            <>
              {messageContent.icon}
              <Typography fontWeight={600} fontSize={19} color='text.primary' textAlign='center'>
                {messageContent.title}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={17}
                color='text.secondary'
                textAlign='center'
                marginBottom='.5rem !important'
              >
                {messageContent.content}
              </Typography>
            </>
          ) : (
            <>
              <CrossInCircleFilled className='h-[40px] w-[40px] text-color-text-error' />
              <Typography fontWeight={600} fontSize={19} color='text.primary' textAlign='center'>
                Unknown Error
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={17}
                color='text.secondary'
                textAlign='center'
                marginBottom='.5rem !important'
              >
                Uh oh. Looks like there was an unknown error. Please contact support via Live Chat for assistance.
              </Typography>
            </>
          )}
        </Stack>
      </Card>
    </Grid>
  );
});

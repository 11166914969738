import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

type StepModalContextType = {
  currentStep: number;
  stepCount: number;
  onNextStep: (step: number, pushToStack?: boolean) => void;
  onBack: (stepsBack?: number) => void;
  goBackToStep: (step?: number) => void;
  hasStep: (step: number) => boolean;
  onClear: () => void;
};

export const StepModalContext = React.createContext<StepModalContextType>({
  currentStep: 0,
  stepCount: 1,
  onNextStep: () => {},
  onBack: () => {},
  goBackToStep: () => {},
  hasStep: () => false,
  onClear: () => {},
});

type Props = {
  initialStep?: number;
};

const StepModalContextProvider: React.FC<PropsWithChildren<Props>> = ({ initialStep = 0, children }) => {
  const [stepIndexes, setStepIndexes] = useState<number[]>([initialStep]);
  const [forcedIndex, setForcedIndex] = useState<number>();

  const currentStep = useMemo(() => forcedIndex ?? stepIndexes[stepIndexes.length - 1], [forcedIndex, stepIndexes]);
  const stepCount = useMemo(() => stepIndexes.length, [stepIndexes]);

  const onNextStep = useCallback((step: number, pushToStack = true) => {
    if (!pushToStack) {
      setForcedIndex(step);
      return;
    } else {
      setForcedIndex(undefined);
      setStepIndexes((prev) => [...prev, step]);
    }
  }, []);

  const onBack = useCallback(
    (stepsBack = 1) => {
      if (forcedIndex !== undefined) {
        setForcedIndex(undefined);
        return;
      }

      if (stepIndexes.length === 1) return;

      setStepIndexes((prev) => {
        const end = Math.max(prev.length - stepsBack, 0);
        return [...prev.slice(0, end)];
      });
    },
    [forcedIndex, stepIndexes.length],
  );

  const goBackToStep = useCallback(
    (step?: number) => {
      if (step === undefined) return;

      const index = stepIndexes.indexOf(step);

      if (index < 0) return;

      setStepIndexes((prev) => [...prev.slice(0, index + 1)]);
    },
    [stepIndexes],
  );

  const onClear = useCallback(() => {
    if (stepIndexes.length === 1) return;

    setStepIndexes([initialStep]);
  }, [initialStep, stepIndexes.length]);

  const hasStep = useCallback((step: number) => stepIndexes.indexOf(step) > -1, [stepIndexes]);

  return (
    <StepModalContext.Provider value={{ stepCount, currentStep, onNextStep, onBack, goBackToStep, onClear, hasStep }}>
      {children}
    </StepModalContext.Provider>
  );
};

export { StepModalContextProvider };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import SMSRecoveryModalProps from '@global-components/Modals/SMSRecoveryModal/@types/SMSRecoveryModal.types';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { UpdateUserSettingsArgs } from '@shared/api';
import { UIStore } from '@shared/store';

import UserService from '@services/UserService';

const SMSRecoveryModal: React.FC<SMSRecoveryModalProps> = ({ toEnable, onClose }) => {
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('modals');

  const disableSMSRecovery = async () => {
    try {
      const userSettings: UpdateUserSettingsArgs = {
        data: {
          toggleSMSRecovery: true,
        },
      };

      await UserService.UpdateUserSettings(userSettings);
      addMessageBox({
        content: <SuccessMessageBox title={t('smsRecovery.disable.success')} />,
      });
    } catch (e) {
      // TODO handle errors
    }
  };

  const enableSMSRecovery = async () => {
    try {
      const userSettings: UpdateUserSettingsArgs = {
        data: {
          toggleSMSRecovery: false,
        },
      };

      await UserService.UpdateUserSettings(userSettings);
      addMessageBox({
        content: <SuccessMessageBox title={t('smsRecovery.enable.success')} />,
      });
    } catch (e) {
      // TODO handle errors
    }
  };

  const getBody = () => {
    if (toEnable) {
      return (
        <Stack spacing={2}>
          <Typography color='text.secondary'>
            {t('smsRecovery.enable.description1')}
            <br />
            <br />
            {t('smsRecovery.enable.description2')}
            <br />
            <br />
            {t('smsRecovery.enable.description3')}
          </Typography>
          <Divider />
          <Stack spacing={2} direction='row' alignSelf='flex-end'>
            <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
              {t('smsRecovery.enable.buttonLabels.cancel')}
            </Button>
            <Button variant='contained' color='primary' onClick={() => enableSMSRecovery()} disableElevation>
              {t('smsRecovery.enable.buttonLabels.agree')}
            </Button>
          </Stack>
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Typography color='text.secondary'>
          {t('smsRecovery.disable.description1')}
          <br />
          <br />
          {t('smsRecovery.disable.description2')}
          <br />
          <br />
          {t('smsRecovery.disable.description3')}
        </Typography>
        <Divider />
        <Stack spacing={2} direction='row' alignSelf='flex-end'>
          <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
            {t('smsRecovery.disable.buttonLabels.cancel')}
          </Button>
          <Button variant='contained' color='error' onClick={() => disableSMSRecovery()} disableElevation>
            {t('smsRecovery.disable.buttonLabels.agree')}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Modal
      id='sms-recovery-modal'
      open
      onClose={onClose}
      sx={{ maxWidth: '640px' }}
      HeaderProps={{ title: toEnable ? t('smsRecovery.enable.title') : t('smsRecovery.disable.title') }}
    >
      {getBody()}
    </Modal>
  );
};

export default SMSRecoveryModal;

import { Palette } from '@mui/material';

import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

const HighContrastLightThemePalette: Palette = {
  mode: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: Colors.Grey[10]!,
    secondary: Colors.Grey[7]!,
    disabled: Colors.Grey[5],
  },
  divider: Colors.Grey[3],
  background: {
    paper: Colors.White,
    default: Colors.White,
  },
  getContrastText: () => Colors.Black,
  augmentColor: () => ({
    main: Colors.Blue[3],
    light: Colors.Blue[3],
    dark: Colors.Blue[3],
    contrastText: Colors.White,
  }),
  action: {
    active: Colors.Blue[4],
    hover: Colors.Blue[1],
    hoverOpacity: 0.04,
    selected: Colors.Blue[4],
    selectedOpacity: 0.08,
    disabled: Colors.Grey[4],
    disabledBackground: Colors.Grey[4],
    disabledOpacity: 0.38,
    focus: Colors.Blue[4],
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  grey: {
    50: Colors.Grey[1],
    100: Colors.Grey[2],
    200: Colors.Grey[3],
    300: Colors.Grey[4],
    400: Colors.Grey[5],
    500: Colors.Grey[6]!,
    600: Colors.Grey[7]!,
    700: Colors.Grey[8]!,
    800: Colors.Grey[9]!,
    900: Colors.Grey[10]!,
    A100: Colors.Grey[2],
    A200: Colors.Grey[3],
    A400: Colors.Grey[5],
    A700: Colors.Grey[8]!,
  },
  primary: {
    main: Colors.Blue[3],
    light: Colors.Blue[3],
    dark: Colors.Blue[3],
    contrastText: Colors.White,
  },
  secondary: {
    main: Colors.Blue[3],
    light: Colors.Blue[3],
    dark: Colors.Blue[3],
    contrastText: Colors.White,
  },
  error: {
    main: Colors.Red[3],
    light: Colors.Red[3],
    dark: Colors.Red[3],
    contrastText: Colors.White,
  },
  warning: {
    main: Colors.Orange[3],
    light: Colors.Orange[3],
    dark: Colors.Orange[3],
    contrastText: Colors.White,
  },
  info: {
    main: Colors.Blue[3],
    light: Colors.Blue[3],
    dark: Colors.Blue[3],
    contrastText: Colors.White,
  },
  success: {
    main: Colors.Green[3],
    light: Colors.Green[3],
    dark: Colors.Green[3],
    contrastText: Colors.White,
  },
  common: {
    black: Colors.Black,
    white: Colors.White,
  },
};

const HighContrastLightTheme = createTheme(HighContrastLightThemePalette);

export { HighContrastLightTheme };

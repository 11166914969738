import { useCallback, useMemo, useState } from 'react';

import { Individual, SmsfCorporateTrustee, SmsfForm, Trustee } from '@shared/api';

import { useToggle } from 'react-use';
import { v4 as uuid } from 'uuid';

import { useEntityOnboardingSelector, useEntityOnboardingService } from '../EntityOnboarding.context';
import { IndividualWithUuid } from '../types/EntityApplicationForm.types';

export type TrusteeWithUuid = { uuid: string; trustee: Trustee };
export type SmsfCorporateTrusteeWithUuid = { uuid: string; corporate: SmsfCorporateTrustee };

export const useSmsfTrusteeStep = () => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as SmsfForm;
  const prevTrustees = applicationData.trustees
    ? applicationData.trustees.map((trustee) => ({ uuid: uuid(), trustee }))
    : undefined;
  const [trustees, setTrustees] = useState<TrusteeWithUuid[]>(prevTrustees ?? []);
  const completedSteps = useEntityOnboardingSelector((state) => state.context.completedSteps);
  const hasCorporateTrusteesAlready = applicationData.trustees?.some(
    (trustee: Trustee) => trustee.type === 'CORPORATE',
  );
  const [addMemberOpen, toggleAddMemberOpen] = useToggle(false);
  const [addCorporateTrusteeOpen, toggleAddCorporateTrusteeOpen] = useToggle(false);

  const [hasCorporateTrustees, setHasCorporateTrustees] = useState<boolean | undefined>(
    completedSteps['smsfAddMembers.addCorporateMembers'] ? hasCorporateTrusteesAlready : undefined,
  );

  const onAddMember = useCallback(
    (individual: Individual) => {
      setTrustees((prev) => [...prev, { trustee: individual, uuid: uuid() }]);
      toggleAddMemberOpen(false);
    },
    [toggleAddMemberOpen],
  );

  const onEditMember = useCallback((individual: IndividualWithUuid) => {
    setTrustees((prev) => [
      ...prev.filter((member) => member.uuid !== individual.uuid),
      { trustee: individual.individual, uuid: individual.uuid },
    ]);
  }, []);

  const onEditCorporateTrustee = useCallback((corporate: SmsfCorporateTrusteeWithUuid) => {
    setTrustees((prev) => [
      ...prev.filter((member) => member.uuid !== corporate.uuid),
      { trustee: corporate.corporate, uuid: corporate.uuid },
    ]);
  }, []);

  const onAddCorporateTrustee = useCallback(
    (trustee: SmsfCorporateTrustee) => {
      setTrustees((prev) => [...prev, { trustee, uuid: uuid() }]);
      toggleAddCorporateTrusteeOpen(false);
    },
    [toggleAddCorporateTrusteeOpen],
  );

  const onCancel = useCallback(() => {
    toggleAddMemberOpen(false);
  }, [toggleAddMemberOpen]);

  const corporateTrustees = useMemo(
    () =>
      trustees
        .filter((trustee) => trustee.trustee.type === 'CORPORATE')
        .map((trustee) => ({ uuid: trustee.uuid, corporate: trustee.trustee })) as SmsfCorporateTrusteeWithUuid[],
    [trustees],
  );

  const hasNotAddedCorporateTrustee = useMemo(
    () => hasCorporateTrustees === true && !corporateTrustees.length && !addCorporateTrusteeOpen,
    [addCorporateTrusteeOpen, corporateTrustees.length, hasCorporateTrustees],
  );

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: {
          trustees: trustees.map((trustee) => trustee.trustee),
          type: corporateTrustees.length ? 'SMSF_CORPORATE' : 'SMSF_INDIVIDUAL',
        } as SmsfForm,
      }),
    [corporateTrustees.length, entityOnboardingService, trustees],
  );

  const handleBack = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'BACK',
        data: {
          trustees: trustees.map((trustee) => trustee.trustee),
          type: corporateTrustees.length ? 'SMSF_CORPORATE' : 'SMSF_INDIVIDUAL',
        } as SmsfForm,
      }),
    [corporateTrustees.length, entityOnboardingService, trustees],
  );

  const handleRemove = useCallback((trusteeUuid: string) => {
    setTrustees((prev) => prev.filter((trustee) => trustee.uuid !== trusteeUuid));
  }, []);

  const members = useMemo(
    () =>
      trustees
        .filter((trustee) => trustee.trustee.type === 'INDIVIDUAL')
        .map((trustee) => ({ uuid: trustee.uuid, individual: trustee.trustee })) as IndividualWithUuid[],
    [trustees],
  );

  const removeAllCorporateTrustees = useCallback(() => {
    setTrustees((prev) => prev.filter((trustee) => trustee.trustee.type !== 'CORPORATE'));
    setHasCorporateTrustees(false);
  }, []);

  return {
    addMemberOpen,
    addCorporateTrusteeOpen,
    corporateTrustees,
    handleRemove,
    hasCorporateTrustees,
    setHasCorporateTrustees,
    members,
    onCancel,
    onAddCorporateTrustee,
    onAddMember,
    toggleAddCorporateTrusteeOpen,
    toggleAddMemberOpen,
    handleSubmit,
    handleBack,
    onEditMember,
    onEditCorporateTrustee,
    removeAllCorporateTrustees,
    hasNotAddedCorporateTrustee,
    trusteesWithUuid: trustees,
  };
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { AtLeastBreakpoints } from '@swyftx/aviary/hooks/breakpoints';
import { ArrowLineOnlyLeft, ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { NewsCard } from '@swyftx/aviary/molecules/NewsCard';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset, NewsItem } from '@shared/api';
import { Category } from '@shared/api/@types/categories';
import { relativeTimeFromDates } from '@shared/utils';

import { useSingleCategoryAnalytics } from '@hooks/Analytics/Categories/useSingleCategoryAnalytics';
import { useNews } from '@hooks/News/useNews';

type Props = {
  category: Category;
  assets: Asset[];
  isSmallScreen?: boolean;
  isXs?: boolean;
  atLeast: AtLeastBreakpoints;
};

const getNumArray = (num?: number) => {
  const arr: number[] = [];
  if (!num) return arr;

  for (let i = 0; i <= num; i += 1) arr.push(i);
  return arr;
};

const widthMapping = {
  4: '@md:w-[calc(25%-16px)]',
  3: '@md:w-[calc(33.33%-16px)]',
};

const SingleCategoryNews: React.FC<Props> = ({ category, assets, isSmallScreen = false, isXs = false, atLeast }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const { trackSingleCategoryNewsArticleOpened } = useSingleCategoryAnalytics();

  const pageSize = useMemo(() => {
    if (!atLeast) return 3;

    if (atLeast.lg) return 4;

    return 3;
  }, [atLeast]);

  const { newsItems, nextPage, prevPage, hasNextPage, hasPrevPage, loading } = useNews({
    assets,
    pageSize,
    returnAll: isSmallScreen,
  });

  const openNewsArticle = (newsItem: NewsItem) => {
    window.open(newsItem.newsUrl, '_blank', 'noopener,noreferrer');

    trackSingleCategoryNewsArticleOpened(category.name, newsItem.title);
  };

  return (
    <ElementContainer
      title={t('titles.news')}
      cardWrapper={isSmallScreen || isXs}
      className='w-full'
      actions={
        isSmallScreen ? null : (
          <FlexLayout spacing={4}>
            <Button variant='outlined' size='sm' layout='icon' disabled={!hasPrevPage} onClick={prevPage}>
              <ArrowLineOnlyLeft className='h-16 w-16' />
            </Button>
            <Button variant='outlined' size='sm' layout='icon' disabled={!hasNextPage} onClick={nextPage}>
              <ArrowLineOnlyRight className='h-16 w-16' />
            </Button>
          </FlexLayout>
        )
      }
    >
      <FlexLayout direction={isSmallScreen ? 'column' : 'row'} spacing={isSmallScreen ? 12 : 24}>
        {pageSize &&
          newsItems.map((newsItem) => (
            <Card
              key={newsItem.id}
              border={!isSmallScreen && !isXs}
              shadow={!isSmallScreen && !isXs}
              className={`cursor-pointer hover:bg-color-background-surface-hover @xs:h-[268px] @xs:w-full @sm:h-[156px] @md:h-[384px] ${widthMapping[pageSize]}`}
              onClick={() => openNewsArticle(newsItem)}
            >
              <CardContent className='h-full @xs:p-8 @md:p-24'>
                <NewsCard
                  id={newsItem.id}
                  layout={isSmallScreen && !isXs ? 'compact' : 'full'}
                  variant={isSmallScreen ? 'standard' : 'card'}
                  imgSrc={newsItem.imageHero}
                  title={newsItem.title}
                  published={relativeTimeFromDates(new Date(newsItem.publishedAt))}
                  excerpt={newsItem.excerpt}
                  source={newsItem.sourceName}
                  loading={!isSmallScreen && loading}
                  onClick={() => window.open(newsItem.newsUrl, '_blank', 'noopener,noreferrer')}
                  icons={newsItem.assets.map((a) => (
                    <AssetIcon key={a} asset={a} size={16} />
                  ))}
                />
              </CardContent>
            </Card>
          ))}
        {isSmallScreen &&
          loading &&
          getNumArray(pageSize).map((value) => (
            <Card
              key={value}
              border={!isSmallScreen && !isXs}
              shadow={!isSmallScreen && !isXs}
              className='cursor-pointer hover:border-color-border-hover @xs:h-[368px] @xs:w-full @sm:h-[156px] @md:h-[384px] @md:w-[calc(25%-24px)]'
            >
              <CardContent className={`h-full ${isSmallScreen || isXs ? 'p-8' : 'p-24'}`}>
                <NewsCard
                  id={value}
                  layout={isSmallScreen && !isXs ? 'compact' : 'full'}
                  variant={isSmallScreen ? 'standard' : 'card'}
                  loading={loading}
                />
              </CardContent>
            </Card>
          ))}
        {isSmallScreen && (
          <FlexLayout alignItems='center' justifyContent='center' className='mb-12 w-full'>
            <Button className='w-full items-center' disabled={!hasNextPage} color='subtle' onClick={nextPage}>
              Show More
            </Button>
          </FlexLayout>
        )}
      </FlexLayout>
    </ElementContainer>
  );
};

export { SingleCategoryNews };

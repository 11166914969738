import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Drag: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M8.40078 7.9998C9.28444 7.9998 10.0008 7.28346 10.0008 6.3998C10.0008 5.51615 9.28444 4.7998 8.40078 4.7998C7.51713 4.7998 6.80078 5.51615 6.80078 6.3998C6.80078 7.28346 7.51713 7.9998 8.40078 7.9998Z'
      fill='currentColor'
    />
    <path
      d='M8.40078 13.5998C9.28444 13.5998 10.0008 12.8835 10.0008 11.9998C10.0008 11.1161 9.28444 10.3998 8.40078 10.3998C7.51713 10.3998 6.80078 11.1161 6.80078 11.9998C6.80078 12.8835 7.51713 13.5998 8.40078 13.5998Z'
      fill='currentColor'
    />
    <path
      d='M10.0008 17.5998C10.0008 18.4835 9.28444 19.1998 8.40078 19.1998C7.51713 19.1998 6.80078 18.4835 6.80078 17.5998C6.80078 16.7161 7.51713 15.9998 8.40078 15.9998C9.28444 15.9998 10.0008 16.7161 10.0008 17.5998Z'
      fill='currentColor'
    />
    <path
      d='M15.6 7.9998C16.4837 7.9998 17.2 7.28346 17.2 6.3998C17.2 5.51615 16.4837 4.7998 15.6 4.7998C14.7163 4.7998 14 5.51615 14 6.3998C14 7.28346 14.7163 7.9998 15.6 7.9998Z'
      fill='currentColor'
    />
    <path
      d='M17.2 11.9998C17.2 12.8835 16.4837 13.5998 15.6 13.5998C14.7163 13.5998 14 12.8835 14 11.9998C14 11.1161 14.7163 10.3998 15.6 10.3998C16.4837 10.3998 17.2 11.1161 17.2 11.9998Z'
      fill='currentColor'
    />
    <path
      d='M15.6 19.1998C16.4837 19.1998 17.2 18.4835 17.2 17.5998C17.2 16.7161 16.4837 15.9998 15.6 15.9998C14.7163 15.9998 14 16.7161 14 17.5998C14 18.4835 14.7163 19.1998 15.6 19.1998Z'
      fill='currentColor'
    />
  </svg>
);

export { Drag };

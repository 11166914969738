export enum SwyftxEvent {
  ApiError,
  /** Emitted when an analytics log-able event occurs */
  Analytics,

  /** Emitted when demo mode is toggled */
  DemoMode,

  /** Emitted when navigating */
  Navigate,

  /** [Mobile] Emitted when navigation has completed including animations */
  NavigateComplete,

  /** [Mobile] Emitted when navigating back (android) or swipe back (iOS) */
  BackButton,

  /** [Mobile] Emitted when app changes active state */
  AppStateChange,

  /** [Internal] Emitted when showing the modal */
  ShowModal,

  /** [Internal] Emitted when hiding the modal */
  HideModal,

  /** [Internal] Emitted when updating the loading state of the the modal */
  UpdateModal,

  /** [Internal] Emitted when modal is showing */
  IsShowingModal,

  /** [Internal] Emitted when showing the loading panel */
  ShowLoadingPanel,

  /** [Internal] Emitted when hiding the loading panel */
  HideLoadingPanel,

  /** [Internal] Emitted when loading panel is showing */
  IsShowingLoadingPanel,

  /** [Internal] Emitted when changing the slider input to prevent parent redraw */
  UpdateSliderInputValue,

  /** [Internal] Emitted when a button is pressed on custom virtual keyboard */
  CustomKeyboard,

  /** [Internal] Emitted transactions should be updated */
  ChangeAccount,

  /** [Internal] Emitted when clear history filters button is pressed on history tab page */
  ClearHistoryFilters,

  /** [Internal] Emitted when clear history filters button is showing */
  ShowClearHistoryFiltersButton,

  /** [Internal] Emitted when code push on mobile receives update events */
  CodePushUpdate,
}

export type SwyftxAnalyticsData = {
  screen: string;
  props?: any;
  eventType?: string;
};

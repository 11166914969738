import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Shop: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M14.125 9.74487C13.7108 9.74487 13.375 10.0807 13.375 10.4949C13.375 10.9091 13.7108 11.2449 14.125 11.2449H16.898C17.3122 11.2449 17.648 10.9091 17.648 10.4949C17.648 10.0807 17.3122 9.74487 16.898 9.74487H14.125Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.01111 2.82217C2.08175 2.41402 2.46988 2.14042 2.87803 2.21106L4.95803 2.57106C5.29435 2.62927 5.54895 2.90722 5.5775 3.24734L5.81266 6.04907H19.9091C21.1831 6.04907 22.1608 7.17822 21.9784 8.43942L21.0294 14.9975C20.8469 16.2536 19.7713 17.1861 18.5021 17.1861H7.59012C6.26246 17.1861 5.15638 16.1696 5.04575 14.8458L4.13122 3.95026L2.62222 3.68909C2.21407 3.61845 1.94047 3.23031 2.01111 2.82217ZM5.93857 7.54907L6.54049 14.7203C6.58605 15.2664 7.04191 15.6861 7.59012 15.6861H18.5021C19.0249 15.6861 19.4693 15.3022 19.5449 14.7824L20.4938 8.22472C20.5454 7.86793 20.2692 7.54907 19.9091 7.54907H5.93857Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.4222 18.7703C6.58471 18.7703 5.9082 19.4503 5.9082 20.2853C5.9082 21.1215 6.58599 21.7993 7.4222 21.7993C8.25714 21.7993 8.9372 21.1228 8.9372 20.2853C8.9372 19.449 8.25842 18.7703 7.4222 18.7703ZM7.4082 20.2853C7.4082 20.2819 7.40874 20.2804 7.40911 20.2796C7.40969 20.2782 7.41084 20.2763 7.41276 20.2744C7.41469 20.2724 7.41647 20.2714 7.41753 20.271C7.41808 20.2707 7.41918 20.2703 7.4222 20.2703C7.42527 20.2703 7.4265 20.2707 7.42729 20.2711C7.42858 20.2716 7.43056 20.2728 7.43262 20.2748C7.43468 20.2769 7.43585 20.2789 7.4364 20.2802C7.43673 20.281 7.4372 20.2822 7.4372 20.2853C7.4372 20.2883 7.43674 20.2894 7.43651 20.2899C7.43606 20.291 7.43503 20.2928 7.4331 20.2947C7.43117 20.2966 7.42925 20.2978 7.42789 20.2984L7.42636 20.2989C7.42544 20.2991 7.42414 20.2993 7.4222 20.2993C7.42058 20.2993 7.41943 20.2991 7.41862 20.299L7.41693 20.2985L7.41508 20.2975C7.41423 20.2969 7.41327 20.2962 7.41228 20.2952C7.41049 20.2934 7.40948 20.2917 7.409 20.2905C7.40874 20.2899 7.4082 20.2886 7.4082 20.2853Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.6752 18.7703C17.8389 18.7703 17.1602 19.449 17.1602 20.2853C17.1602 21.1228 17.8402 21.7993 18.6752 21.7993C19.5101 21.7993 20.1902 21.1228 20.1902 20.2853C20.1902 19.449 19.5114 18.7703 18.6752 18.7703ZM18.6602 20.2853C18.6602 20.2822 18.6606 20.281 18.661 20.2802C18.6615 20.2789 18.6627 20.2769 18.6647 20.2748C18.6668 20.2728 18.6688 20.2716 18.6701 20.2711C18.6709 20.2707 18.6721 20.2703 18.6752 20.2703C18.6782 20.2703 18.6795 20.2707 18.6802 20.2711C18.6815 20.2716 18.6835 20.2728 18.6856 20.2748C18.6876 20.2769 18.6888 20.2789 18.6893 20.2802C18.6897 20.281 18.6902 20.2822 18.6902 20.2853C18.6902 20.2883 18.6897 20.2894 18.6895 20.2899C18.689 20.291 18.688 20.2928 18.6861 20.2947C18.6841 20.2966 18.6822 20.2978 18.6808 20.2984C18.68 20.2987 18.6785 20.2993 18.6752 20.2993C18.6718 20.2993 18.6703 20.2987 18.6695 20.2984C18.6681 20.2978 18.6662 20.2966 18.6643 20.2947C18.6623 20.2928 18.6613 20.291 18.6609 20.2899C18.6606 20.2894 18.6602 20.2883 18.6602 20.2853Z'
      fill='currentColor'
    />
  </svg>
);

export { Shop };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Pause: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.25 3.53846C7.57651 3.53846 3.78846 7.3274 3.78846 12C3.78846 16.6735 7.57645 20.4615 12.25 20.4615C16.9235 20.4615 20.7115 16.6735 20.7115 12C20.7115 7.3274 16.9235 3.53846 12.25 3.53846ZM2.25 12C2.25 6.47784 6.72673 2 12.25 2C17.7733 2 22.25 6.47784 22.25 12C22.25 17.5232 17.7732 22 12.25 22C6.72679 22 2.25 17.5232 2.25 12Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1025 8.44632C10.5274 8.44632 10.8717 8.79071 10.8717 9.21555V14.783C10.8717 15.2078 10.5274 15.5522 10.1025 15.5522C9.67773 15.5522 9.33333 15.2078 9.33333 14.783V9.21555C9.33333 8.79071 9.67773 8.44632 10.1025 8.44632ZM14.3988 8.44632C14.8236 8.44632 15.1681 8.79071 15.1681 9.21555V14.783C15.1681 15.2078 14.8236 15.5522 14.3988 15.5522C13.974 15.5522 13.6296 15.2078 13.6296 14.783V9.21555C13.6296 8.79071 13.974 8.44632 14.3988 8.44632Z'
      fill='currentColor'
    />
  </svg>
);

export { Pause };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { RecurringOrderTemplate } from '@shared/api/@types/recurringOrder';
import { Big } from '@shared/safe-big';

import { AutoInvestOrderItemMobileTemplateItem } from './AutoInvestOrderItemMobileTemplateItem';

type Props = {
  template: RecurringOrderTemplate[];
};

const AutoInvestOrderItemMobileTemplate: React.FC<Props> = ({ template }) => (
  <FlexLayout direction='column' spacing={8} className='pb-16'>
    {template
      .sort((a, b) => Big(b.active ? b.percentage : 0).cmp(a.active ? a.percentage : 0))
      .map((item, index) => (
        <FlexLayout key={item.secondaryAssetId} direction='column' className='px-16' spacing={8}>
          <AutoInvestOrderItemMobileTemplateItem templateItem={item} />
          {index < template.length - 1 && <div className='h-1 w-full bg-color-border-main' />}
        </FlexLayout>
      ))}
  </FlexLayout>
);

export { AutoInvestOrderItemMobileTemplate };

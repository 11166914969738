import { api, GenerateDepositAddressResponse } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { AxiosResponse } from 'axios';

const generateBankAddress = async (code: string, depositMethod: DepositMethodEnum, amount?: number) => {
  const results = await api.endpoints.generateDepositAddress({
    params: { code, variant: depositMethod, amount },
    data: {
      address: { name: `Default ${code}` },
    },
  });

  return results?.data?.address;
};

const getBankAddress = async (
  code: string,
  variant: DepositMethodEnum,
): Promise<AxiosResponse<GenerateDepositAddressResponse['address']>['data']> => {
  const results = await api.endpoints.getDepositAddresses({ params: { code, variant } });
  if (results?.data?.addresses?.length) {
    if (results.data.addresses?.[0].variant === DepositMethodEnum.MONOOVA_DIRECT_CREDIT) {
      return results.data.addresses?.[0];
    }
    if (results.data.addresses?.[0].variant === DepositMethodEnum.MONOOVA_PAYID) {
      return results.data.addresses?.[0];
    }
  }

  return generateBankAddress(code, variant);
};

const SUPPORTED_DEPOSIT_COUNTRY_CODES = ['AUD', 'NZD']; // should ideally be source of truth on back-end

export { getBankAddress, generateBankAddress, SUPPORTED_DEPOSIT_COUNTRY_CODES };

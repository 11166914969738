import { useCallback, useContext, useEffect, useMemo } from 'react';

import { ResolutionString } from 'public/assets/charting_library/charting_library';
import { useLocalStorage } from 'react-use';

import { TradingViewContext } from '../../../context/TradingView.context';
import { isAdvancedChartState } from '../../../utils/Tradingview.utils';

export type ResolutionItem = {
  text: string;
  value: ResolutionString;
  favourite?: boolean;
};

const useTradingViewResolution = () => {
  const { setSelectedResolution, selectedResolution, chartState } = useContext(TradingViewContext);
  const [quickIntervals, setQuickIntervals] = useLocalStorage<ResolutionString[]>(
    'tradingview.IntervalWidget.quicks',
    [],
  );

  const updateSelectedItemName = useCallback(
    (resolution: ResolutionString) => {
      setSelectedResolution(resolution);
    },
    [setSelectedResolution],
  );

  useEffect(() => {
    if (!isAdvancedChartState(chartState)) return;

    chartState?.onChartReady(() => {
      if (!chartState?.chart()) return;

      setSelectedResolution(chartState.chart().resolution());
    });
  }, [setSelectedResolution, chartState, updateSelectedItemName]);

  const setResolution = useCallback(
    (resolution: ResolutionString) => {
      if (!isAdvancedChartState(chartState)) {
        updateSelectedItemName(resolution);
        return;
      }

      chartState?.onChartReady(() => {
        if (!chartState?.chart()) return;

        chartState.chart().setResolution(resolution);
        updateSelectedItemName(resolution);
      });
    },
    [chartState, updateSelectedItemName],
  );

  const items: ResolutionItem[] = useMemo(
    () => [
      {
        text: '1m',
        value: '1' as ResolutionString,
        favourite: quickIntervals?.includes('1' as ResolutionString),
      },
      {
        text: '3m',
        value: '3' as ResolutionString,
        favourite: quickIntervals?.includes('3' as ResolutionString),
      },
      {
        text: '5m',
        value: '5' as ResolutionString,
        favourite: quickIntervals?.includes('5' as ResolutionString),
      },
      {
        text: '15m',
        value: '15' as ResolutionString,
        favourite: quickIntervals?.includes('15' as ResolutionString),
      },
      {
        text: '30m',
        value: '30' as ResolutionString,
        favourite: quickIntervals?.includes('30' as ResolutionString),
      },
      {
        text: '45m',
        value: '45' as ResolutionString,
        favourite: quickIntervals?.includes('45' as ResolutionString),
      },
      {
        text: '1h',
        value: '60' as ResolutionString,
        favourite: quickIntervals?.includes('60' as ResolutionString),
      },
      {
        text: '2h',
        value: '120' as ResolutionString,
        favourite: quickIntervals?.includes('120' as ResolutionString),
      },
      {
        text: '3h',
        value: '180' as ResolutionString,
        favourite: quickIntervals?.includes('180' as ResolutionString),
      },
      {
        text: '4h',
        value: '240' as ResolutionString,
        favourite: quickIntervals?.includes('240' as ResolutionString),
      },
      {
        text: '12h',
        value: '720' as ResolutionString,
        favourite: quickIntervals?.includes('720' as ResolutionString),
      },
      {
        text: '1D',
        value: '1D' as ResolutionString,
        favourite: quickIntervals?.includes('1D' as ResolutionString),
      },
      {
        text: '1W',
        value: '1W' as ResolutionString,
        favourite: quickIntervals?.includes('1W' as ResolutionString),
      },
      {
        text: '1M',
        value: '1M' as ResolutionString,
        favourite: quickIntervals?.includes('1M' as ResolutionString),
      },
    ],
    [quickIntervals],
  );

  const selectedItem = useMemo(() => items.find((i) => i.value === selectedResolution), [items, selectedResolution]);

  const toggleFavouriteItem = useCallback(
    (item: ResolutionItem) => {
      const favItems = Array.from(quickIntervals || []);
      const index = favItems.findIndex((i) => i === item.value);

      if (index > -1) {
        favItems.splice(index, 1);
      } else {
        favItems.push(item.value);
      }

      setQuickIntervals(favItems);
    },
    [quickIntervals, setQuickIntervals],
  );

  return {
    selectedItem,
    items,
    setResolution,
    toggleFavouriteItem,
  };
};

export { useTradingViewResolution };

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Stack } from '@swyftx/react-web-design-system';

import { AppHeaderDashboardActions } from '@global-components/AppHeader/AppHeaderDashboardActions';
import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';
import PageBanner from '@global-components/PageBanner';

import { UIStore, UserStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { DashboardContext } from '@Dashboard/Dashboard.context';
import { DashboardSection } from '@Dashboard/components';

import { observer } from 'mobx-react-lite';

type Props = {
  preview?: boolean;
  className?: string;
};

const Dashboard: React.FC<Props> = observer(({ preview, className }) => {
  const userStore = UserStore.useUserStore;
  const { initTiles, editMode, cancelEditMode, saveTiles, tiles } = useContext(DashboardContext);
  const { bx } = useContentBreakpoint();
  const { t } = useTranslation('dashboard');
  const { setCustomHeaderOptions } = UIStore.useUIStore;

  useEffect(() => {
    if (preview) return;
    setCustomHeaderOptions(<AppHeaderDashboardActions />);

    return () => {
      if (preview) return;
      setCustomHeaderOptions(undefined);
    };
  }, [preview, setCustomHeaderOptions]);

  // Load tiles
  useEffect(() => {
    if (tiles && !Object.values(tiles).length) {
      initTiles(userStore.userProfile?.userSettings.dashboardSettings?.tiles);
    }
  }, [tiles, initTiles, userStore.userProfile?.userSettings.dashboardSettings?.tiles]);

  return (
    <>
      <Grid
        className={className}
        container
        alignItems='flex-start'
        justifyContent='space-between'
        width='100%'
        paddingX={bx({ xs: 1 })}
        paddingY={bx({ xs: 1 })}
        marginTop={editMode ? 6 : 0}
      >
        <Grid item xs={12}>
          <Stack direction='row' margin={1}>
            <PageBanner />
          </Stack>
          <DashboardSection />
        </Grid>
      </Grid>

      {/* Buttons for Edit Dashboard Mode for Mobile View */}
      {editMode && (
        <Grid
          container
          spacing={1}
          justifyContent='space-between'
          width='100%'
          marginLeft={0}
          marginTop={2}
          marginBottom={2}
          sx={{ padding: '0 16px' }}
          display={bx({ xs: 'flex', sm: 'none' })}
        >
          <LayoutGridItem xs={6}>
            <Button id='editMode.cancel' variant='text' size='large' onClick={cancelEditMode} fullWidth>
              {t('editMode.cancel')}
            </Button>
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <Button
              variant='contained'
              size='large'
              color='primary'
              onClick={() => saveTiles(userStore.userProfile)}
              fullWidth
            >
              {t('editMode.save')}
            </Button>
          </LayoutGridItem>
        </Grid>
      )}
    </>
  );
});

Dashboard.displayName = 'Dashboard';

export default Dashboard;

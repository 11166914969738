import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset, RateSide } from '@shared/api/@types/markets';
import { RatesStore } from '@shared/store';

import { useAssetPriceMetrics } from '@hooks/Assets/useAssetPriceMetrics';

import { observer } from 'mobx-react-lite';

import { useAssetSummaryData } from './AssetSummary.hooks';
import { AssetSummaryItems } from './AssetSummaryItems/AssetSummaryItems';

type Props = {
  backgroundColor?: string;
  forceBaseAsset?: Asset;
  asset: Asset;
  priceSide?: RateSide;
};

export const AssetSummary: React.FC<Props> = observer(({ asset, forceBaseAsset, backgroundColor, priceSide }) => {
  const { getRate } = RatesStore.useRatesStore;
  const livePrice = asset && getRate(asset)[priceSide || 'midPrice'];
  const { currency } = useAssetSummaryData(livePrice, forceBaseAsset);
  const { data, forceFetch, loading } = useAssetPriceMetrics(asset);

  return (
    <FlexLayout className='relative w-full' alignItems='start' justifyContent='center' direction='column'>
      {data && (
        <AssetSummaryItems
          backgroundColor={backgroundColor}
          currency={currency}
          asset={asset}
          data={data}
          onUpdate={forceFetch}
          fetching={loading}
        />
      )}
    </FlexLayout>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { UserStore } from '@shared/store';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';

import { observer } from 'mobx-react-lite';

import { useGreenIdStatus } from './useGreenIdStatus';

type Props = {
  entityMember?: EntityMember;
  isEntityUser?: boolean;
};

const GreenIDStatusCard: React.FC<Props> = observer(({ entityMember, isEntityUser }) => {
  const { userProfile } = UserStore.useUserStore;
  const { t } = useTranslation('profile.verification');

  const status = entityMember?.verified || userProfile?.verification?.greenid?.status;

  const {
    chipColor,
    shouldShowNotification,
    notificationColor,
    notificationDescription,
    notificationTitle,
    shouldShowButton,
    buttonAction,
    buttonColor,
    buttonText,
  } = useGreenIdStatus(entityMember, isEntityUser);

  return (
    <Card>
      <FlexLayout direction='column' spacing={16}>
        <FlexLayout direction='row' className='w-full items-center justify-between p-16 pb-0'>
          <FlexLayout direction='row' spacing={8} className='items-center'>
            <TextSnippetIcon color='primary' sx={{ marginRight: 1 }} />
            <Body weight='emphasis'>{t('identityVerificationModal.labels.governmentId')}</Body>
          </FlexLayout>
          <Chip size='sm' color={chipColor} variant='subtle'>
            {status ? t(`identityVerificationModal.greenIdStatus.${status}`) : ''}
          </Chip>
        </FlexLayout>
        <Separator />
        <FlexLayout direction='column' spacing={16} className='p-16 pt-0'>
          <Body weight='emphasis'>{t('identityVerificationModal.labels.verifyIdentity')}</Body>
          <ul>
            <li>
              <Body color='secondary'>- {t('identityVerificationModal.labels.depositWithdrawfiat')}</Body>
            </li>
            <li>
              <Body color='secondary'>- {t('identityVerificationModal.labels.tradeFiat')}</Body>
            </li>
          </ul>
          {!!shouldShowNotification && (
            <Notification severity={notificationColor} title={notificationTitle}>
              {notificationDescription}
            </Notification>
          )}
          {!!shouldShowButton && (
            <FlexLayout direction='row'>
              <Button color={buttonColor} onClick={buttonAction}>
                {buttonText}
              </Button>
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
});

export { GreenIDStatusCard };

import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { MultiAssetPicker } from '@swyftx/aviary/complex/MultiAssetPickerModal/MultiAssetPicker';

import { AutoInvestCardHeader } from '../components';
import { useAutoInvestValues } from '../hooks';

export const AutoInvestSelectAssets: React.FC = () => {
  const { setCurrentStep, selectedAutoInvestAssets, onBulkUpdateAssets } = useAutoInvestValues();
  const preSelectedAssetCodes: string[] = useMemo(
    () => selectedAutoInvestAssets.map((asset) => asset.assetCode).filter((code) => code !== ''),
    [selectedAutoInvestAssets],
  );

  const onConfirm = useCallback(
    (assetCodes: string[]) => {
      onBulkUpdateAssets(assetCodes);
      setCurrentStep('values');
    },
    [onBulkUpdateAssets, setCurrentStep],
  );

  return (
    <FlexLayout direction='column' spacing={8} alignItems='center' className='h-full w-full'>
      <FlexLayout
        direction='column'
        className='h-full w-full overflow-auto py-16 sm:py-24'
        spacing={8}
        alignItems='center'
      >
        <FlexLayout direction='column' className='h-full w-full' spacing={24} alignItems='center'>
          <FlexLayout direction='column' className='w-full px-24'>
            <AutoInvestCardHeader title='Select assets' onBack={() => setCurrentStep('values')} />
          </FlexLayout>
          <MultiAssetPicker preSelectedAssetCodes={preSelectedAssetCodes} onConfirm={onConfirm} />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { observer } from 'mobx-react-lite';

import { getEmptyIcon, getEmptyContent } from './EmptyContent';
import { usePortfolioBreakdownTable } from './hooks/usePortfolioBreakdownTable';
import { OrdersTileTabs, PortfolioBreakdownTableData } from './ordersTile.types';

interface Props {
  setSelectedAsset: (asset: Asset) => void;
}

export const PortfolioBreakdown: React.FC<Props> = observer((props) => {
  const { setSelectedAsset } = props;
  const { headers, initialSort, onSort, data } = usePortfolioBreakdownTable();
  const isXs = useTailwindBreakpoint('xs');

  const onSelectItem = useCallback(
    (item: EnhancedTableData<PortfolioBreakdownTableData>) => {
      const assetCode = item.asset.value as string;
      const asset = assetService.getAssetByCode(assetCode);
      if (!asset) {
        throw Error('Asset not found');
      }
      setSelectedAsset(asset);
    },
    [setSelectedAsset],
  );

  const tableElement = useMemo(
    () => (
      <EnhancedTable<PortfolioBreakdownTableData>
        data-containter='trade-portfolio-table'
        headers={headers}
        data={data}
        sort={initialSort}
        onSort={onSort}
        onClickItem={onSelectItem}
        rowClassName='max-h-16'
        itemsPerPage={20}
        lazyLoad={!isXs}
      >
        <FlexLayout direction='column' className='h-full' alignItems='center' justifyContent='center' spacing={8}>
          {getEmptyIcon(OrdersTileTabs.Wallets)}
          <Body color='primary'>{getEmptyContent(OrdersTileTabs.Wallets)}</Body>
        </FlexLayout>
      </EnhancedTable>
    ),
    [data, headers, initialSort, isXs, onSelectItem, onSort],
  );

  return <>{tableElement}</>;
});

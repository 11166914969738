import React from 'react';

import { Modal, Typography } from '@swyftx/react-web-design-system';

import { DepositFundsLimitAndFees } from './DepositFundsLimitAndFees';

type Props = {
  id: string;
  title: string;
  summary?: string;
  limits: string;
  fees: string;
  onClose: () => void;
  children?: React.ReactNode;
};

const DepositFundsModal: React.FC<Props> = ({ id, title, summary, children, limits, fees, onClose }) => (
  <Modal
    id={id}
    open
    sx={{ width: '600px', '.MuiCardContent-root': { height: '100%' } }}
    HeaderProps={{ title, dismissible: true, divider: true }}
    onClose={onClose}
  >
    <>
      <Typography color='text.secondary' fontSize={14}>
        {summary}
      </Typography>
      {children}
      <DepositFundsLimitAndFees limits={limits} fees={fees} />
    </>
  </Modal>
);

export { DepositFundsModal };

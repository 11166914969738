import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

const useFetchSourceOfWealthHistory = () => {
  const { userId } = UserStore.useUserStore;

  const fetchSourceOfWealthHistory = useCallback(async () => {
    const response = await api.endpoints.getSourceOfWealthHistory();
    return response.data;
  }, []);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['source-of-wealth-history', userId],
    queryFn: fetchSourceOfWealthHistory,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { status, data, error, isFetching, isPreviousData, isStale };
};

export { useFetchSourceOfWealthHistory };

import React from 'react';
import { RouteObject } from 'react-router-dom';

import PublicRoute from '@routes/PublicRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import Login from './Login';

export const LoginRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Login,
    element: <PublicRoute element={<Login />} />,
  },
];

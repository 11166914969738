import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ListFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.56284 1.74635C3.92085 1.65662 4.35821 1.625 4.87615 1.625C5.39408 1.625 5.83145 1.65662 6.18945 1.74635C6.55738 1.83856 6.88582 2.00348 7.12465 2.29963C7.35648 2.58708 7.44368 2.92506 7.48305 3.23736C7.52181 3.54478 7.52182 3.90335 7.52182 4.27067C7.52182 4.28364 7.52182 4.2966 7.52182 4.30955C7.52187 4.66117 7.52192 5.005 7.48674 5.29984C7.4497 5.61039 7.36595 5.94981 7.13527 6.23906C6.8971 6.5377 6.56747 6.70299 6.19846 6.79513C5.83943 6.88478 5.39927 6.91634 4.87615 6.91634C4.35303 6.91634 3.91286 6.88478 3.55383 6.79513C3.18483 6.70299 2.8552 6.5377 2.61703 6.23906C2.38635 5.94981 2.3026 5.61039 2.26555 5.29984C2.23038 5.005 2.23042 4.66118 2.23047 4.30956C2.23047 4.29661 2.23048 4.28364 2.23048 4.27067C2.23048 3.90335 2.23048 3.54478 2.26924 3.23736C2.30861 2.92506 2.39582 2.58708 2.62764 2.29963C2.86647 2.00348 3.19491 1.83856 3.56284 1.74635Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5218 4.27067C10.5218 3.92549 10.8016 3.64567 11.1468 3.64567H21.1468C21.492 3.64567 21.7718 3.92549 21.7718 4.27067C21.7718 4.61585 21.492 4.89567 21.1468 4.89567H11.1468C10.8016 4.89567 10.5218 4.61585 10.5218 4.27067Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.56284 9.22568C3.92085 9.13595 4.35821 9.10433 4.87615 9.10433C5.39408 9.10433 5.83145 9.13595 6.18945 9.22568C6.55738 9.31789 6.88582 9.48281 7.12465 9.77896C7.35648 10.0664 7.44368 10.4044 7.48305 10.7167C7.52181 11.0241 7.52182 11.3827 7.52182 11.75C7.52182 11.763 7.52182 11.7759 7.52182 11.7889C7.52187 12.1405 7.52192 12.4843 7.48674 12.7792C7.4497 13.0897 7.36595 13.4291 7.13527 13.7184C6.8971 14.017 6.56747 14.1823 6.19846 14.2745C5.83943 14.3641 5.39927 14.3957 4.87615 14.3957C4.35303 14.3957 3.91286 14.3641 3.55383 14.2745C3.18483 14.1823 2.8552 14.017 2.61703 13.7184C2.38635 13.4291 2.3026 13.0897 2.26555 12.7792C2.23038 12.4843 2.23042 12.1405 2.23047 11.7889C2.23047 11.7759 2.23048 11.763 2.23048 11.75C2.23048 11.3827 2.23048 11.0241 2.26924 10.7167C2.30861 10.4044 2.39582 10.0664 2.62764 9.77896C2.86647 9.48281 3.19491 9.31789 3.56284 9.22568Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5218 11.75C10.5218 11.4048 10.8016 11.125 11.1468 11.125H21.1468C21.492 11.125 21.7718 11.4048 21.7718 11.75C21.7718 12.0952 21.492 12.375 21.1468 12.375H11.1468C10.8016 12.375 10.5218 12.0952 10.5218 11.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.56284 16.705C3.92085 16.6153 4.35821 16.5837 4.87615 16.5837C5.39408 16.5837 5.83145 16.6153 6.18945 16.705C6.55738 16.7972 6.88582 16.9621 7.12465 17.2583C7.35648 17.5457 7.44368 17.8837 7.48305 18.196C7.52181 18.5034 7.52182 18.862 7.52182 19.2293C7.52182 19.2423 7.52182 19.2553 7.52182 19.2682C7.52187 19.6198 7.52192 19.9637 7.48674 20.2585C7.4497 20.569 7.36595 20.9085 7.13527 21.1977C6.8971 21.4964 6.56747 21.6616 6.19846 21.7538C5.83943 21.8434 5.39927 21.875 4.87615 21.875C4.35303 21.875 3.91286 21.8434 3.55383 21.7538C3.18483 21.6616 2.8552 21.4964 2.61703 21.1977C2.38635 20.9085 2.3026 20.569 2.26555 20.2585C2.23038 19.9637 2.23042 19.6198 2.23047 19.2682C2.23047 19.2553 2.23048 19.2423 2.23048 19.2293C2.23048 18.862 2.23048 18.5034 2.26924 18.196C2.30861 17.8837 2.39582 17.5457 2.62764 17.2583C2.86647 16.9621 3.19491 16.7972 3.56284 16.705Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5218 19.2293C10.5218 18.8841 10.8016 18.6043 11.1468 18.6043H21.1468C21.492 18.6043 21.7718 18.8841 21.7718 19.2293C21.7718 19.5745 21.492 19.8543 21.1468 19.8543H11.1468C10.8016 19.8543 10.5218 19.5745 10.5218 19.2293Z'
      fill='currentColor'
    />
  </svg>
);

export { ListFilled };

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { useEntityOnboardingService } from '@routes/EntityOnboarding/EntityOnboarding.context';

import { NavigationURLs } from 'src/lib/navigation/types';

import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';

export const FormSubmittedStep: React.FC = () => {
  const entityOnboardingService = useEntityOnboardingService();
  const navigate = useNavigate();

  const onDashboardClick = () => {
    navigate(NavigationURLs.Dashboard);
  };

  return (
    <EntityOnboardingStepContainer
      onSubmit={(e) => {
        e.preventDefault();
        entityOnboardingService.send({ type: 'NEXT' });
      }}
      hideTitle
    >
      <FlexLayout direction='column' alignItems='center' spacing={16}>
        <FlexLayout direction='column' className='items-center text-center' spacing={32}>
          <Image image='entity_reviewing' className='h-[11.5rem] w-[11.5rem]' />
          <FlexLayout direction='column' spacing={16}>
            <Heading size='h3'>Entity application submitted</Heading>
            <Body color='secondary'>
              Once reviewed and approved, you will receive a confirmation email and be able to switch to your new entity
              account.
            </Body>
          </FlexLayout>
          <FlexLayout direction='column' spacing={16}>
            <Body weight='emphasis'>What happens next?</Body>
            <FlexLayout direction='column' spacing={4}>
              <Body color='secondary'>1. We will review your application.</Body>
              <Body color='secondary'>
                2. Some entity members (such as directors or trustees) will be invited to join the entity account and
                must verify their ID.
              </Body>
              <Body color='secondary'>3. If approved, trading will be enabled on your account.</Body>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='column' className='w-full' spacing={8}>
            <Button size='lg' onClick={onDashboardClick}>
              Go to dashboard
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </EntityOnboardingStepContainer>
  );
};

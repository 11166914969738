import React, { useEffect, useState } from 'react';

import { InputProps } from '@mui/material/Input';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { FormInputProps } from '@components/molecules/Inputs/FormInput';

import { CountryDropdown } from '@global-components/Dropdown';

import { CountriesEnum } from '@shared/enums';

import { CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

type PhoneNumberProps = {
  onCountrySelect: (countryCode: CountriesEnum) => void;
  defaultCountry: CountriesEnum;
  inputProps?: InputProps;
  onlySuggested?: boolean;
} & FormInputProps;

export const PhoneNumberInput: React.FC<PhoneNumberProps> = (props: PhoneNumberProps) => {
  const { id, label, error, defaultCountry, onCountrySelect, onlySuggested = false, onChange, value } = props;
  const [phoneCountry, setPhoneCountry] = useState<CountriesEnum>(defaultCountry);

  useEffect(() => setPhoneCountry(defaultCountry), [defaultCountry]);

  return (
    <FlexLayout direction='column' spacing={4} className='mb-24 w-full sm:mb-16'>
      <FlexLayout spacing={0} alignItems='start' justifyContent='start'>
        <Body size='small' weight='bold' color='primary'>
          {label || 'Phone number'}
        </Body>
        <Body size='small' weight='bold' color='error'>
          *
        </Body>
      </FlexLayout>

      <Input
        placeholder={getExampleNumber(phoneCountry as CountryCode, examples)?.formatNational()}
        type='text'
        onChange={onChange}
        value={value as string}
        id='createAccount.labels.emailAddress'
        className='pii-mask'
        error={!!error?.message?.length}
        leading={
          <CountryDropdown
            id={`${id}-country-prefix`}
            value={phoneCountry}
            onChange={onCountrySelect}
            compact
            onlySuggested={onlySuggested}
          />
        }
      />
      {error?.message && (
        <Body size='small' color='error'>
          {error.message}
        </Body>
      )}
    </FlexLayout>
  );
};

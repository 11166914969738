import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button as AviaryButton } from '@swyftx/aviary/atoms/Button';
import { TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';

interface SingleWalletFiatWithdrawalWithdrawManageAccountsProps {
  asset: Asset;
  isFiat: boolean;
  fullWidth?: boolean;

  onClose?: () => void;
  buttonSize?: 'sm' | 'md';
}

const SingleWalletManageAccounts: React.FC<SingleWalletFiatWithdrawalWithdrawManageAccountsProps> = observer(
  ({ buttonSize = 'sm', fullWidth = true }) => {
    const { onNextStep } = useContext(StepModalContext);

    const { t } = useTranslation('wallet');

    return (
      <AviaryButton
        onClick={() => onNextStep(TransferModalStep.ManageAccounts)}
        variant='ghost'
        color='accent'
        className={fullWidth ? 'w-full' : ''}
        size={buttonSize}
      >
        {t('manageAccounts.button')}
      </AviaryButton>
    );
  },
);

export { SingleWalletManageAccounts };

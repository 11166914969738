import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { StorageKey } from '@shared/storage';
import { UIStore } from '@shared/store';

import { LoginContext } from '@routes/Login/Login.context';
import AuthenticationService from '@services/AuthenticationService';

import { observer } from 'mobx-react-lite';

import { SignInForm } from './Form';

const SignIn: React.FC = observer(() => {
  const { postLoginActions, getRememberMe, setShowCreateAccount, recoveryUserProfile, twoFactorEnableInProgress } =
    useContext(LoginContext);

  const { t: securityT } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });
  const { addMessageBox } = UIStore.useUIStore;

  useEffect(() => {
    getRememberMe();
    setShowCreateAccount(true);
    if (twoFactorEnableInProgress) {
      addMessageBox({
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        content: <InformationMessageBox title={securityT('enable.finish.login')} />,
      });
    }
  }, []);

  const submitLogin = async (email: string, password: string, recaptcha: string, rememberMe: boolean) => {
    localStorage.setItem(StorageKey.REMEMBER_ME, rememberMe ? email : '');
    const res = await AuthenticationService.Login(email, password, recaptcha, recoveryUserProfile);
    if (res) postLoginActions(res, email, password);
  };

  return (
    <FlexLayout direction='column' className='w-full' alignItems='center' justifyContent='start'>
      <SignInForm
        onSignIn={async ({ email: formEmail, password, captchaToken, rememberMe: formRememberMe }) => {
          await submitLogin(formEmail, password, captchaToken, formRememberMe);
        }}
      />
    </FlexLayout>
  );
});

SignIn.displayName = 'SignIn';

export { SignIn };

import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { NameInput } from '@global-components/Input';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

import { AdvancedConfiguration } from './components/AdvancedConfiguration';
import { ApiKeyType } from './components/ApiKeyType';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
};

const ApiKeyConfigurationStep: React.FC<Props> = observer(({ onClose, onSubmit }) => {
  const { t } = useTranslation('profile.apiKey');
  const { newApiKeyPayload, setNewApiKeyPayload, fetchApiScopes } = useContext(ApiKeysContext);
  const { scopes, label } = newApiKeyPayload;
  const { bx } = useContentBreakpoint();

  useEffect(() => {
    const fetchData = async () => {
      fetchApiScopes();
    };

    fetchData();
  }, []);

  const validKeyData = useMemo(() => label && Object.keys(scopes).length > 0, [label, scopes]);

  return (
    <Modal
      id='api-key-modal-configuration-step'
      open
      sx={{ maxWidth: '968px', width: '100%' }}
      HeaderProps={{
        title: t('sections.createApiKey.config.title'),
        dismissible: true,
        divider: true,
      }}
      onClose={onClose}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={1}>
            <Button variant='text' onClick={onClose} color='inherit'>
              {t('sections.createApiKey.config.cancel')}
            </Button>
            <Button variant='contained' onClick={onSubmit} disabled={!validKeyData}>
              {t('sections.createApiKey.config.continue')}
            </Button>
          </Stack>
        ),
      }}
    >
      {/* Nested stack to render scroll bar on right side */}
      <Stack direction='column' height='100%' marginX='-16px !important'>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            width: '100%',
            maxHeight: bx({ xs: '450px', sm: '670px' }),
            overflow: 'auto',
            paddingX: '16px',
          }}
        >
          <Typography fontSize={16} fontWeight='400' color='text.secondary'>
            {t('sections.createApiKey.config.subtitle')}
          </Typography>

          <Stack direction='column' spacing={1}>
            <Typography fontSize={14} fontWeight='500'>
              {t('sections.createApiKey.config.inputLabel')}
              <Typography color='error.main' display='inline'>
                *
              </Typography>
            </Typography>

            <Box width={bx({ xs: '100%', md: '460px' })}>
              <NameInput
                id='api-key-name-input'
                onChange={(e) => setNewApiKeyPayload({ label: e.target.value })}
                type='ApiKey'
                showLabel={false}
                value={label}
              />
            </Box>
          </Stack>

          <ApiKeyType />

          <AdvancedConfiguration />
        </Stack>
      </Stack>
    </Modal>
  );
});

export { ApiKeyConfigurationStep };

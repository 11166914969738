import { useCallback } from 'react';

import { Candle } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { IChartApi, ISeriesApi, LineData, Time, UTCTimestamp } from 'lightweight-charts';
import { DateTime } from 'luxon';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

import { isSimpleChartState } from '../../../utils/Tradingview.utils';

type Props = {
  chartState?: IChartApi | IChartingLibraryWidget;
  data?: Candle[];
};

const useCreateLineSeries = ({ chartState, data }: Props) => {
  const createLineSeries = useCallback(() => {
    if (!isSimpleChartState(chartState) || !data) return undefined;

    const lineSeries = chartState.addLineSeries({
      color: getComputedStyle(document.body).getPropertyValue('--color-background-primary'),
    });

    const seriesData: LineData<Time>[] = data
      .map((d) => ({
        time: (Date.parse(DateTime.fromMillis(d.time).toISO()) / 1000) as UTCTimestamp,
        value: d.close || 0,
      }))
      .sort((a, b) => Big(a.time).cmp(b.time));

    try {
      lineSeries.setData(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }

    return lineSeries;
  }, [chartState, data]);

  const updateLineSeries = useCallback((series: ISeriesApi<'Line'>, bar: Candle) => {
    if (series.seriesType() !== 'Line') return;

    const seriesData: LineData<Time> = {
      time: (Date.parse(DateTime.fromMillis(bar.time).toISO()) / 1000) as UTCTimestamp,
      value: bar.close || 0,
    };

    try {
      series.update(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, []);

  return { createLineSeries, updateLineSeries };
};

export { useCreateLineSeries };

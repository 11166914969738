import { useEffect, useState } from 'react';

import { Asset } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

export const useAssetSummaryData = (price?: string, forceBaseAsset?: Asset) => {
  const assetsList = assetService.getActiveAssetList();
  const { userProfile } = UserStore.useUserStore;
  const currencyAsset: Asset | undefined =
    forceBaseAsset || assetsList.find((cAsset) => cAsset.id === userProfile?.currency.id);

  // Temporary
  const [currentCurrency, setCurrentCurrency] = useState<Asset>();
  const [currentPrice, setCurrentPrice] = useState<string>();
  const diffCurrentCurrency = currentCurrency?.id !== currencyAsset?.id;
  const changingAsset = diffCurrentCurrency || currentPrice !== price;

  useEffect(() => {
    if (diffCurrentCurrency) {
      setCurrentCurrency(currencyAsset);
    }

    setCurrentPrice(price);
  }, [price, currencyAsset, currentPrice, diffCurrentCurrency]);

  return { price: currentPrice, currency: currentCurrency, changingCurrency: changingAsset };
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Refresh: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M17.6542 5.68846C16.1849 4.47668 14.3026 3.74976 12.2498 3.74976C7.55497 3.74976 3.74976 7.55497 3.74976 12.2498C3.74976 16.9445 7.55497 20.7498 12.2498 20.7498C16.9445 20.7498 20.7498 16.9445 20.7498 12.2498C20.7498 11.8355 21.0855 11.4998 21.4998 11.4998C21.914 11.4998 22.2498 11.8355 22.2498 12.2498C22.2498 17.773 17.773 22.2498 12.2498 22.2498C6.72654 22.2498 2.24976 17.773 2.24976 12.2498C2.24976 6.72654 6.72654 2.24976 12.2498 2.24976C14.7023 2.24976 16.949 3.13308 18.6881 4.59763L18.6853 3.25142C18.6844 2.83721 19.0195 2.50073 19.4338 2.49987C19.848 2.49901 20.1844 2.8341 20.1853 3.24832L20.1919 6.44076C20.1923 6.64022 20.1133 6.83163 19.9722 6.97266C19.8312 7.1137 19.6398 7.19274 19.4403 7.19231L16.2481 7.18543C15.8339 7.18454 15.4988 6.84803 15.4997 6.43382C15.5006 6.01961 15.8371 5.68455 16.2514 5.68544L17.6542 5.68846Z'
      fill='currentColor'
    />
  </svg>
);

export { Refresh };

export const sortObject = (object: any): any => {
  if (!object) {
    return object;
  }

  const isArray = object instanceof Array;
  let sortedObj: { [key: string]: any } = {};
  if (isArray) {
    sortedObj = object.map((item: any) => sortObject(item));
  } else {
    const keys = Object.keys(object);

    keys.sort(function (key1: string, key2: string) {
      const lcKey1 = key1.toLowerCase();
      const lcKey2 = key2.toLowerCase();
      if (lcKey1 < lcKey2) return -1;
      if (lcKey1 > lcKey2) return 1;
      return 0;
    });

    for (const index in keys) {
      const key = keys[index];
      if (typeof object[key] === 'object') {
        sortedObj[key] = sortObject(object[key]);
      } else {
        sortedObj[key] = object[key];
      }
    }
  }

  return sortedObj;
};

export const isObject = (value: any): boolean => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

export const isEmptyObject = (value: any): boolean => {
  const type = typeof value;
  return !(value != null && (type === 'object' || type === 'function') && Object.keys(value).length === 0);
};

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export const mergeDeep = (target: any, source: any) => {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
};

import React from 'react';

import { SupportingDocumentsProvider } from 'src/context/DocumentUpload/SupportingDocument.context';
import { DocumentUploadFlow } from 'src/context/DocumentUpload/supportingDocumentsUtils';

import { SupportingDocumentsContent } from '../SupportingDocumentsContent';

const SupportingDocumentsStep: React.FC = () => (
  <SupportingDocumentsProvider flow={DocumentUploadFlow.SourceOfWealth}>
    <SupportingDocumentsContent />
  </SupportingDocumentsProvider>
);

export { SupportingDocumentsStep };

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';
import { Menu } from '@swyftx/aviary/molecules/Menu';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { SelectAssetList } from 'src/lib/markets/components/SelectAssetList/SelectAssetList';

import { PriceAlertType } from '../../dynamicPriceAlerts.types';
import { useDynamicPriceAlertAssetCounts } from '../../hooks/useDynamicPriceAlertAssetCounts';

type Props = {
  alertType?: PriceAlertType;
  filterAssets?: Asset[];
  setFilterAssets?: (filterAssets: Asset[]) => void;
};

const DynamicPriceAlertAssetFilter: React.FC<Props> = ({ alertType, filterAssets, setFilterAssets }) => {
  const [open, setOpen] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { alertActiveAssetCodes, alertTriggerAssetCodes } = useDynamicPriceAlertAssetCounts();
  const { t } = useTranslation('priceAlerts');

  const triggerTitle = useMemo(() => {
    if (filterAssets?.length === 0) return '';
    const totalAssetsCount = alertType === 'active' ? alertActiveAssetCodes.length : alertTriggerAssetCodes.length;
    if (totalAssetsCount === filterAssets?.length) return `: ${t('filters.all')}`;

    if (filterAssets && filterAssets.length > 1) {
      return `: ${filterAssets[0].code} + ${filterAssets.length - 1} other`;
    }

    return `: ${filterAssets?.map((a) => a.code).join(', ')}`;
  }, [filterAssets, alertType, alertActiveAssetCodes.length, alertTriggerAssetCodes.length, t]);

  const triggerTooltip = useMemo(() => {
    if ((filterAssets?.length || 0) <= 1) return '';

    return filterAssets?.map((a) => a.code).join(', ');
  }, [filterAssets]);

  const disabled = useMemo(
    () => (alertType === 'active' ? !alertActiveAssetCodes.length : !alertTriggerAssetCodes.length),
    [alertActiveAssetCodes.length, alertTriggerAssetCodes.length, alertType],
  );

  if (!setFilterAssets) return null;

  return (
    <Menu
      open={open}
      onOpenChange={setOpen}
      align='end'
      contentClassName='h-[85vh] sm:h-[280px]'
      variant={isXs ? 'mobile' : 'standard'}
      portalClassName='z-tour-popup'
      triggerContent={
        <Button
          tooltip={triggerTooltip}
          disabled={disabled}
          variant='outlined'
          textJustify='space-between'
          className={cn(
            'w-full cursor-pointer rounded-8 sm:w-auto',
            open ? 'focus-area-info outline-color-border-info hover:outline-color-border-info' : '',
          )}
          size={isXs ? 'md' : 'sm'}
          trailingIcon={<ArrowChevronDown className='h-24 w-24 text-color-text-primary' />}
        >
          <Body color={disabled ? 'disabled' : 'primary'}>{t('filters.asset', { assets: triggerTitle })}</Body>
        </Button>
      }
    >
      <SelectAssetList
        type='multi'
        showTabs={false}
        includedAssetCodes={alertType === 'active' ? alertActiveAssetCodes : alertTriggerAssetCodes}
        onSelectAsset={setFilterAssets}
        selectedAssets={filterAssets}
        className='mb-0 pt-8 sm:px-8'
        containerClassName='sm:pb-8'
        onClose={isXs ? () => setOpen(false) : undefined}
        title={isXs ? 'Select assets' : undefined}
      />
    </Menu>
  );
};

export { DynamicPriceAlertAssetFilter };

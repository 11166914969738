/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { Asset, PairExchangeRateResponse, PairExchangeRateResponseError } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useTradeQuote } from '../hooks/General';
import { QuickBuyOrderResponse, QuickBuyPaymentMethodType, QuickBuyStep, QuickBuyType } from '../types/Trade.types';

type QuickBuyContextType = {
  selectedAssetCode: string;
  countryAssetCode: string;
  limitAssetCode: string;
  selectedPaymentMethod: QuickBuyPaymentMethodType;
  currentStep: QuickBuyStep;
  amount: string;
  completedOrder?: QuickBuyOrderResponse;
  tradeError?: string;
  buyType: QuickBuyType;
  allowAssetChange: boolean;
  allowPaymentChange: boolean;
  quoteError?: PairExchangeRateResponseError;
  errorMessage?: string;
  tradeQuote?: PairExchangeRateResponse;
  tabValue: { current: { value: string; assetCode: string }; previous: { value: string; assetCode: string } };
  setSelectedAssetCode: React.Dispatch<React.SetStateAction<string>>;
  setLimitAssetCode: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<QuickBuyPaymentMethodType>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<QuickBuyStep>>;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setCompletedOrder: React.Dispatch<React.SetStateAction<QuickBuyOrderResponse | undefined>>;
  setBuyType: React.Dispatch<React.SetStateAction<QuickBuyType>>;
  setTradeError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTabValue: (newVal: { value: string; assetCode: string }, oldValue?: { value: string; assetCode: string }) => void;
};

type Props = {
  assetCode?: string;
  tradeSide?: QuickBuyType;
  countryAsset: Asset;
  allowAssetChange?: boolean;
  allowPaymentChange?: boolean;
};

const DEFAULT_ASSET_CODE = 'BTC';

const QuickBuyContext = createContext<QuickBuyContextType>({
  allowAssetChange: true,
  allowPaymentChange: true,
  selectedAssetCode: DEFAULT_ASSET_CODE,
  countryAssetCode: 'AUD',
  limitAssetCode: '',
  selectedPaymentMethod: 'wallet',
  currentStep: 'values',
  amount: '',
  buyType: 'buy',
  tradeError: '',
  tabValue: { current: { value: '', assetCode: '' }, previous: { value: '', assetCode: '' } },
  setSelectedAssetCode: () => {},
  setLimitAssetCode: () => {},
  setSelectedPaymentMethod: () => {},
  setCurrentStep: () => {},
  setAmount: () => {},
  setCompletedOrder: () => {},
  setBuyType: () => {},
  setTradeError: () => {},
  setTabValue: () => {},
});

const QuickBuyContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  assetCode,
  tradeSide = 'buy',
  countryAsset,
  allowAssetChange = true,
  allowPaymentChange = false,
}) => {
  const [selectedAssetCode, setSelectedAssetCode] = useState<string>(assetCode || DEFAULT_ASSET_CODE);
  const [countryAssetCode, setCountryAssetCode] = useState<string>(countryAsset.code);
  const [limitAssetCode, setLimitAssetCode] = useState<string>(countryAsset.code);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<QuickBuyPaymentMethodType>('wallet');
  const [currentStep, setCurrentStep] = useState<QuickBuyStep>('values');
  const [amount, setAmount] = useState<string>('');
  const [completedOrder, setCompletedOrder] = useState<QuickBuyOrderResponse>();
  const [buyType, setBuyType] = useState<QuickBuyType>(tradeSide);
  const [tradeError, setTradeError] = useState<string>();
  const [tabValue, pushTabValue] = useState({
    current: { value: '', assetCode: '' },
    previous: { value: '', assetCode: '' },
  });

  const setTabValue = useCallback(
    (newTab: { value: string; assetCode: string }, oldTab?: { value: string; assetCode: string }) => {
      pushTabValue({ current: newTab, previous: oldTab ? oldTab : tabValue.current });
    },
    [tabValue, pushTabValue],
  );

  useEffect(() => {
    if (countryAsset) setCountryAssetCode(countryAsset.code);
  }, [countryAsset]);

  useEffect(() => {
    setTabValue({ value: '', assetCode: '' });
  }, [buyType]);

  const {
    data: tradeQuote,
    quoteError,
    errorMessage,
  } = useTradeQuote({
    buy: buyType === 'buy' ? selectedAssetCode : countryAssetCode,
    sell: buyType === 'buy' ? countryAssetCode : selectedAssetCode,
    amount: Big(amount).toNumber(),
    limit: limitAssetCode,
  });

  return (
    <QuickBuyContext.Provider
      value={{
        tradeError,
        selectedAssetCode,
        countryAssetCode,
        limitAssetCode,
        selectedPaymentMethod,
        currentStep,
        amount,
        completedOrder,
        buyType,
        allowAssetChange,
        allowPaymentChange,
        quoteError,
        errorMessage,
        tradeQuote,
        tabValue,
        setSelectedAssetCode,
        setLimitAssetCode,
        setSelectedPaymentMethod,
        setCurrentStep,
        setAmount,
        setCompletedOrder,
        setBuyType,
        setTradeError,
        setTabValue,
      }}
    >
      {children}
    </QuickBuyContext.Provider>
  );
};

export { QuickBuyContextProvider, QuickBuyContext };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { QuickBuyStep, QuickBuyType } from 'src/lib/trade/types/Trade.types';

type Props = {
  buyType: QuickBuyType;
  className?: string;
  selectedAsset?: Asset;
  setStep: React.Dispatch<React.SetStateAction<QuickBuyStep>>;
  disabled?: boolean;
};

const QuickBuyAsset: React.FC<Props> = ({ buyType, className, selectedAsset, setStep, disabled }) => (
  <FlexLayout
    onClick={() => !disabled && setStep('assetPicker')}
    className={cn(className, !disabled ? 'cursor-pointer hover:bg-color-background-surface-hover' : '')}
    alignItems='center'
    justifyContent='space-between'
  >
    <Body size='small' weight='emphasis'>
      {buyType === 'buy' ? 'Buy' : 'Sell'}
    </Body>
    <FlexLayout spacing={8} alignItems='center' className={cn('rounded-8 p-8 ')}>
      <AssetIcon asset={selectedAsset} size={20} />
      <Body>{selectedAsset?.name || 'Select'}</Body>
      {!disabled && <ArrowChevronDown />}
    </FlexLayout>
  </FlexLayout>
);

export { QuickBuyAsset };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ExchangeBased: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5338 7.63179C12.2409 7.33891 11.7661 7.33888 11.4732 7.63171L7.6338 11.4701C7.49311 11.6108 7.41406 11.8016 7.41406 12.0005C7.41406 12.1994 7.49311 12.3902 7.6338 12.5309L11.4732 16.3693C11.7661 16.6621 12.2409 16.6621 12.5338 16.3692L16.3721 12.5308C16.665 12.2379 16.665 11.7631 16.3721 11.4702L12.5338 7.63179ZM12.0034 14.7783L9.22486 12.0005L12.0034 9.22271L14.7811 12.0005L12.0034 14.7783Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2.25C6.61487 2.25 2.25 6.61487 2.25 12C2.25 17.3851 6.61487 21.75 12 21.75C17.3851 21.75 21.75 17.3851 21.75 12C21.75 6.61487 17.3851 2.25 12 2.25ZM3.75 12C3.75 7.44329 7.44329 3.75 12 3.75C16.5567 3.75 20.25 7.44329 20.25 12C20.25 16.5567 16.5567 20.25 12 20.25C7.44329 20.25 3.75 16.5567 3.75 12Z'
      fill='currentColor'
    />
  </svg>
);

export { ExchangeBased };

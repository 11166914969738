import React, { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { WithdrawalAcknowledgementStep } from '@swyftx/aviary/complex/TransferModal';
import { TransferEnum, TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';
import { AddNewAddressStep } from '@swyftx/aviary/complex/TransferModal/components/AddNewAddressStep';
import { EmailVerificationStep } from '@swyftx/aviary/complex/TransferModal/components/EmailVerificationStep';
import { ManageAccountsStep } from '@swyftx/aviary/complex/TransferModal/components/ManageAccountsStep';
import { PhoneVerificationStep } from '@swyftx/aviary/complex/TransferModal/components/PhoneVerificationStep';
import { RemoveAddressStep } from '@swyftx/aviary/complex/TransferModal/components/RemoveAddressStep';
import { ViewAddressDetailsStep } from '@swyftx/aviary/complex/TransferModal/components/ViewAddressDetailsStep';
import { useTransferModal } from '@swyftx/aviary/complex/TransferModal/hooks/useTransferModal';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext, StepModalContextProvider } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { ModalName } from 'src/context/Avo/generated-avo';
import { useFetchAssetAddressesCache } from 'src/lib/assets/hooks/useFetchAssetAddressesCache';
import { SelectAssetList } from 'src/lib/markets/components/SelectAssetList/SelectAssetList';

import { NetworkFeesStep } from './components/NetworkFeesStep';
import { PhoneVerificationWithdrawStep } from './components/PhoneVerificationWithdrawStep';
import { TransferRootStep } from './components/TransferRootStep';

type Props = {
  initialStep?: TransferModalStep;
  initialTransferType: TransferEnum;
  selectedAsset?: Asset | string;
  initialDepositType?: DepositMethodEnum;
  onClose: () => void;
  open: boolean;
};

const TransferModalContent: React.FC<Props> = observer(
  ({ initialTransferType, initialDepositType, selectedAsset, onClose, open }) => {
    const { hasStep, goBackToStep, onClear } = useContext(StepModalContext);
    const { invalidateCache } = useFetchAssetAddressesCache();
    const { pathname } = useLocation();
    const avo = useAvo();

    const {
      preSelectedAsset,
      currentStep,
      selectedAddress,
      addAddressToken,
      transferType,
      depositType,
      withdrawAddress,
      withdrawAmount,
      withdrawReason,
      withdrawToken,
      setWithdrawToken,
      setWithdrawAddress,
      setWithdrawAmount,
      setWithdrawReason,
      setDepositType,
      setTransferType,
      setAddAddressToken,
      onSelectAsset,
      onNextStep,
      onBack,
      onOpenAddress,
      addAddressFormData,
      onClearFormData,
      onUpdateFormData,
    } = useTransferModal({ selectedAsset, initialTransferType, initialDepositType });

    const handleOnCloseVerificationModals = useCallback(() => {
      onClearFormData();
      invalidateCache();

      if (hasStep(TransferModalStep.ManageAccounts)) {
        goBackToStep(TransferModalStep.ManageAccounts);
      } else {
        onClear();
      }
    }, [goBackToStep, hasStep, invalidateCache, onClear, onClearFormData]);

    return (
      <StepModal.Container stepIndex={currentStep} onClose={onClose} open={open} className='sm:w-[600px]'>
        <TransferRootStep
          key={TransferModalStep.Root}
          onClose={onClose}
          transferType={transferType}
          setTransferType={setTransferType}
          depositType={depositType}
          setDepositType={setDepositType}
          selectedAsset={preSelectedAsset}
          withdrawAddress={withdrawAddress}
          withdrawReason={withdrawReason}
          withdrawAmount={withdrawAmount}
          setWithdrawToken={setWithdrawToken}
          setWithdrawAmount={setWithdrawAmount}
          setWithdrawReason={setWithdrawReason}
          setWithdrawAddress={setWithdrawAddress}
        />

        <StepModal.Step key={TransferModalStep.SelectAsset} onClose={onClose} hideActions title='Select asset'>
          <SelectAssetList
            containerClassName='min-h-[400px] max-h-[400px] h-[400px]'
            type={transferType === TransferEnum.DepositReceive ? 'deposit' : 'withdraw'}
            onSelectAsset={onSelectAsset}
            sortForTransferList
            selectedAssets={preSelectedAsset}
            defaultFilterType='all'
          />
        </StepModal.Step>

        <ManageAccountsStep
          asset={preSelectedAsset}
          onOpenAddress={onOpenAddress}
          onClose={onClose}
          key={TransferModalStep.ManageAccounts}
        />

        <WithdrawalAcknowledgementStep
          key={TransferModalStep.WithdrawalAcknowledgement}
          onBack={() => {
            avo.clickedButton({
              parentComponent: null,
              tableName: null,
              screen: pathname,
              buttonName: 'Back',
              modalName: ModalName.WITHDRAWAL_ADDRESS_ACKNOWLEDGEMENT,
            });
            onBack();
          }}
          onContinue={() => {
            avo.clickedButton({
              parentComponent: null,
              tableName: null,
              screen: pathname,
              buttonName: 'Continue',
              modalName: ModalName.WITHDRAWAL_ADDRESS_ACKNOWLEDGEMENT,
            });
            onNextStep(TransferModalStep.AddNewAccount);
          }}
        />

        <AddNewAddressStep
          asset={preSelectedAsset!}
          addAddressFormData={addAddressFormData}
          onClearFormData={onClearFormData}
          onUpdateFormData={onUpdateFormData}
          key={TransferModalStep.AddNewAccount}
          setAddAddressToken={setAddAddressToken}
          onClose={onClose}
        />

        <PhoneVerificationStep
          onClose={handleOnCloseVerificationModals}
          asset={preSelectedAsset!}
          addAddressToken={addAddressToken}
          setAddAddressToken={setAddAddressToken}
          addAddressFormData={addAddressFormData}
        />

        <EmailVerificationStep onClose={handleOnCloseVerificationModals} />

        <ViewAddressDetailsStep address={selectedAddress!} onClose={onClose} />
        <RemoveAddressStep address={selectedAddress!} />
        <NetworkFeesStep />
        <PhoneVerificationWithdrawStep
          selectedAsset={preSelectedAsset}
          withdrawToken={withdrawToken}
          setWithdrawToken={setWithdrawToken}
          withdrawAmount={withdrawAmount}
          withdrawAddress={withdrawAddress}
          withdrawReason={withdrawReason}
          onClose={onClose}
        />
      </StepModal.Container>
    );
  },
);

export const TransferModal: React.FC<Props> = (props) => (
  <StepModalContextProvider initialStep={props.initialStep}>
    <TransferModalContent {...props} />
  </StepModalContextProvider>
);

export enum BankNameEnum {
  CST = 'Commonwealth Bank',
  CBA = 'Commonwealth Bank',
  WBC = 'Westpac',
  NAB = 'National Australia Bank',
  ANZ = 'Australia and New Zealand Bank',
  BOM = 'Bank of Melbourne',
  BQL = 'Bank of Queensland',
  HBS = 'Heritage Bank',
  BBL = 'Bendigo Bank',
  YOU = '86400',
}

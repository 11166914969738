import React from 'react';

import { VariantProps, cva } from 'cva';
import { z } from 'zod';

export type InputProps = {
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  schema?: z.ZodType<any, any>;
  containerClassName?: string;
  onValidityChange?: (isValid: boolean) => void;
  onClear?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> &
  VariantProps<typeof inputContainerVariants>;

export const inputContainerVariants = cva('rounded-[8px] p-12 bg-color-background-surface-secondary leading-5 w-full', {
  variants: {
    disabled: {
      true: 'cursor-not-allowed opacity-50 hover:!bg-color-background-surface-secondary',
      false: '',
    },
    error: {
      true: 'focus-area-error outline outline-color-border-error text-color-text-error',
      false: '',
    },
    readOnly: {
      true: 'border-dashed',
      false: 'focus-within:!bg-color-background-surface-secondary hover:bg-color-background-neutral-hover',
    },
  },
  compoundVariants: [
    {
      error: false,
      readOnly: false,
      class: 'focus-within:focus-area-info focus-within:outline-color-border-accent focus-within:outline',
    },
  ],
  defaultVariants: {
    disabled: false,
    error: false,
  },
});

export const inputVariants = cva(
  `w-full file:text-10 file:bg-color-background-surface-primary text-color-text-primary file:cursor-pointer file:text-color-text-primary file:border-solid file:border-color-border-main file:rounded-[8px] bg-transparent focus-visible:outline-none text-color-text-primary border-none pointer-events-auto
  [&::-webkit-datetime-edit-day-field:focus]:bg-color-background-surface-selected [&::-webkit-datetime-edit-day-field:focus]:text-color-text-primary
  [&::-webkit-datetime-edit-month-field:focus]:bg-color-background-surface-selected [&::-webkit-datetime-edit-month-field:focus]:text-color-text-primary
  [&::-webkit-datetime-edit-year-field:focus]:bg-color-background-surface-selected [&::-webkit-datetime-edit-year-field:focus]:text-color-text-primary`,
  {
    variants: {
      variant: {
        default: 'font-sans',
        monospace: 'font-mono',
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: '',
      },
      error: {
        true: 'caret-[var(--color-text-error)]',
        false: 'caret-[var(--color-text-info)]',
      },
      fontSize: {
        medium: 'text-14',
        large: 'text-16',
      },
    },
    defaultVariants: {
      variant: 'default',
      fontSize: 'medium',
      error: false,
    },
  },
);

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Orderbook: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M4.25 2C3.00736 2 2 3.00736 2 4.25V9C2 10.2426 3.00736 11.25 4.25 11.25H7.25C8.49264 11.25 9.5 10.2426 9.5 9V4.25C9.5 3.00736 8.49264 2 7.25 2H4.25Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 15C2 13.7574 3.00736 12.75 4.25 12.75H7.25C8.49264 12.75 9.5 13.7574 9.5 15V19.75C9.5 20.9926 8.49264 22 7.25 22H4.25C3.00736 22 2 20.9926 2 19.75V15ZM4.25 14.25C3.83579 14.25 3.5 14.5858 3.5 15V19.75C3.5 20.1642 3.83579 20.5 4.25 20.5H7.25C7.66421 20.5 8 20.1642 8 19.75V15C8 14.5858 7.66421 14.25 7.25 14.25H4.25Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.25 2C12.0074 2 11 3.00736 11 4.25V5.41667C11 6.65931 12.0074 7.66667 13.25 7.66667H19.75C20.9926 7.66667 22 6.65931 22 5.41667V4.25C22 3.00736 20.9926 2 19.75 2H13.25ZM12.5 4.25C12.5 3.83579 12.8358 3.5 13.25 3.5H19.75C20.1642 3.5 20.5 3.83579 20.5 4.25V5.41667C20.5 5.83088 20.1642 6.16667 19.75 6.16667H13.25C12.8358 6.16667 12.5 5.83088 12.5 5.41667V4.25Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 11.417C11 10.1744 12.0074 9.16699 13.25 9.16699H19.75C20.9926 9.16699 22 10.1744 22 11.417V12.5837C22 13.8263 20.9926 14.8337 19.75 14.8337H13.25C12.0074 14.8337 11 13.8263 11 12.5837V11.417ZM13.25 10.667C12.8358 10.667 12.5 11.0028 12.5 11.417V12.5837C12.5 12.9979 12.8358 13.3337 13.25 13.3337H19.75C20.1642 13.3337 20.5 12.9979 20.5 12.5837V11.417C20.5 11.0028 20.1642 10.667 19.75 10.667H13.25Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.25 16.333C12.0074 16.333 11 17.3404 11 18.583V19.7497C11 20.9923 12.0074 21.9997 13.25 21.9997H19.75C20.9926 21.9997 22 20.9923 22 19.7497V18.583C22 17.3404 20.9926 16.333 19.75 16.333H13.25ZM12.5 18.583C12.5 18.1688 12.8358 17.833 13.25 17.833H19.75C20.1642 17.833 20.5 18.1688 20.5 18.583V19.7497C20.5 20.1639 20.1642 20.4997 19.75 20.4997H13.25C12.8358 20.4997 12.5 20.1639 12.5 19.7497V18.583Z'
      fill='currentColor'
    />
  </svg>
);

export { Orderbook };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@swyftx/react-web-design-system';

import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { TabButton } from '@routes/AssetsList/components/TabButton';

type Props = {
  triggerType: TriggerType;
  setTriggerType: React.Dispatch<React.SetStateAction<TriggerType>>;
  setProfitLossValue: (newValue: string) => void;
};

const ProfitStopLossTriggerType: React.FC<Props> = ({ triggerType, setTriggerType, setProfitLossValue }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });

  const onUpdateTriggerType = (newTriggerType: TriggerType) => {
    setTriggerType(newTriggerType);
    setProfitLossValue('');
  };

  return (
    <Stack direction='row' width='100%' alignItems='center' justifyContent='center' spacing={2} paddingX={2}>
      <TabButton
        id='btn-take-profit'
        data-spotlightelementid='btn-take-profit'
        active={triggerType === TriggerType.TakeProfit}
        size='lg'
        onClick={() => onUpdateTriggerType(TriggerType.TakeProfit)}
        className='w-full'
      >
        {t('labels.takeProfit')}
      </TabButton>
      <TabButton
        id='btn-stop-loss'
        data-spotlightelementid='btn-stop-loss'
        active={triggerType === TriggerType.StopLoss}
        size='lg'
        onClick={() => onUpdateTriggerType(TriggerType.StopLoss)}
        className='w-full'
      >
        {t('labels.stopLoss')}
      </TabButton>
    </Stack>
  );
};

export { ProfitStopLossTriggerType };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import LinearProgress from '@mui/material/LinearProgress';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { useOnboardingSelector } from '@Onboarding/Onboarding.context';
import { onboardingMachine } from '@Onboarding/Onboarding.machine';

import { StateNode } from 'xstate';

import { Step, transformOnboardingStates } from './stepperUtils';

const MobileOnboardingStepper: React.FC = () => {
  const { t } = useTranslation('onboarding');

  const transformedSteps = useMemo(
    () => transformOnboardingStates(onboardingMachine.states as Record<string, StateNode>),
    [],
  );

  const currentState = useOnboardingSelector((state) => state.toStrings());

  const [activeRootStep, activeRootStepIndex] = useMemo<[Step | undefined, number]>(() => {
    const [rootStepId] = currentState;
    const index = transformedSteps.findIndex((s) => s.id === rootStepId)!;
    return [transformedSteps[index], index];
  }, [transformedSteps, currentState]);

  const activeStep = useMemo<Step | undefined>(() => {
    const [, activeStepId] = currentState;
    if (!activeStepId || !onboardingMachine.getStateNodeByPath(activeStepId)?.meta?.step) {
      return activeRootStep;
    }

    const foundStep = activeRootStep?.children.find((s) => s.id === activeStepId);
    if (foundStep) return foundStep;
    return undefined;
  }, [activeRootStep, currentState]);

  const showTitle: boolean =
    activeStep == null ? true : onboardingMachine.getStateNodeByPath(activeStep.id)?.meta?.showMobileTitle ?? true;

  return (
    <Stack>
      <Stack direction='row' spacing={1}>
        {transformedSteps.map((rootStep, index) => {
          const activeIndexInRoot = rootStep.children.findIndex((s) => s.id === activeStep?.id);

          let progress = ((activeIndexInRoot + 1) / rootStep.children.length) * 100 || 0; // 0 fallback to cover NaN

          // Previous steps should show completion 100%
          if (activeRootStepIndex > index) {
            progress = 100;
          }

          // Edge case where root has no children and is a step in itself (fallback to 1 so we don't have 0/0)
          if (activeRootStepIndex === index && rootStep.children.length === 0) {
            progress = 100;
          }

          return (
            <LinearProgress
              key={rootStep.id}
              value={progress}
              variant='determinate'
              sx={{
                flexGrow: 1,
                height: 8,
                borderRadius: 4,
                marginBottom: 4,
                backgroundColor: 'divider',
              }}
            />
          );
        })}
      </Stack>
      {showTitle && (
        <Typography color='text.secondary' fontWeight={600} fontSize={14}>
          {activeRootStep ? t(`steps.${activeRootStep.id}.title` as any) : ''}
        </Typography>
      )}
    </Stack>
  );
};

export { MobileOnboardingStepper };

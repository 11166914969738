import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { getPriceScale } from '@swyftx/currency-util';

import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { DrawingEventType, EntityId, IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { usePriceScale } from 'src/lib/trade/hooks/General/usePriceScale';

import {
  LIMIT_PRICE_LINE_LENGTH,
  OCO_PRICE_LINE_LENGTH,
  TRADE_PRICE_LINE_TITLE,
  useBuildPriceLine,
} from './useBuildPriceLine';
import { TradingViewContext } from '../../context/TradingView.context';

type Props = {
  secondary: string;
  tvWidget?: IChartingLibraryWidget;
  priceLines?: string[];
  onUpdatePriceLines?: (prices: string[]) => void;
};

const useTradingViewPriceLines = ({ tvWidget, priceLines, secondary, onUpdatePriceLines }: Props) => {
  const { showPreviewOrders } = useContext(TradingViewContext);
  const [priceLineEntityId, setPriceLineEntityId] = useState<EntityId | null>(null);
  const { clearPriceLines, buildPriceLines } = useBuildPriceLine({ tvWidget, secondary });
  const { getAssetByCode } = useMarkets();

  const secondaryAsset = useMemo(() => getAssetByCode(secondary), [getAssetByCode, secondary]);
  const { calculatePriceScale } = usePriceScale();

  const isValidShape = useCallback(
    (properties: Record<string, any>) => {
      if (!priceLines) return false;

      if (properties.profitLevel && priceLines.length === OCO_PRICE_LINE_LENGTH) return true;
      if (!properties.profitLevel && priceLines.length === LIMIT_PRICE_LINE_LENGTH) return true;

      return false;
    },
    [priceLines],
  );

  useEffect(() => {
    if (!showPreviewOrders) {
      clearPriceLines(TRADE_PRICE_LINE_TITLE);
      setPriceLineEntityId(null);
    }
  }, [clearPriceLines, showPreviewOrders]);

  const buildTradePriceLines = useCallback(() => {
    try {
      if (!tvWidget) return;

      // If the TradingView priceLines array is empty we should remove any
      // pre-existing drawings that we have created
      // Note that pricelines comes from src/lib/trade-pro/components/SwyftxProChart/SwyftxProChart.tsx
      if (!priceLines || priceLines.length === 0 || !showPreviewOrders) {
        clearPriceLines(TRADE_PRICE_LINE_TITLE);
        setPriceLineEntityId(null);
      } else {
        // If we have priceLines defined but haven't created any lines, build those necessary lines
        if (!priceLineEntityId) {
          const entity = buildPriceLines(priceLines);
          setPriceLineEntityId(entity);
        } else {
          const shape = tvWidget.activeChart().getShapeById(priceLineEntityId);
          const properties = shape.getProperties();

          // Check if the shape is correct, if not clear it and rebuild
          if (!isValidShape(properties)) {
            clearPriceLines(TRADE_PRICE_LINE_TITLE);
            setPriceLineEntityId(null);

            const entity = buildPriceLines(priceLines);
            setPriceLineEntityId(entity);
            return;
          }

          // Update any price lines that already exist.
          // This will mainly happen when manually updating the trade input box
          if (priceLines.length === LIMIT_PRICE_LINE_LENGTH) {
            shape.setPoints([{ time: DateTime.now().toMillis(), price: Number(priceLines[0]) }]);
          } else if (priceLines.length === OCO_PRICE_LINE_LENGTH) {
            const price = (shape as any)?._source?._points?.[0]?.price;
            if (!price || !secondaryAsset || !shape) return;

            const priceScale = 10 ** getPriceScale(price);

            shape.setProperties({
              profitLevel: Big(priceLines[1]).minus(price).times(priceScale).toNumber(),
              stopLevel: Big(price).minus(priceLines[2]).abs().times(priceScale).toNumber(),
            });
          }
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [
    buildPriceLines,
    clearPriceLines,
    isValidShape,
    priceLineEntityId,
    priceLines,
    secondaryAsset,
    showPreviewOrders,
    tvWidget,
  ]);

  const onTick = useCallback(() => {
    if (!priceLines) {
      clearPriceLines(TRADE_PRICE_LINE_TITLE);
      setPriceLineEntityId(null);
    }
    tvWidget?.unsubscribe('onTick', onTick);
  }, [priceLines, clearPriceLines, tvWidget]);

  const onDrawingEvent = useCallback(
    (source: EntityId, event: DrawingEventType) => {
      if (source !== priceLineEntityId || !priceLines || !secondaryAsset || !tvWidget) return;

      const shape = tvWidget.activeChart().getShapeById(source);
      const properties = shape.getProperties();

      if (event === 'remove') {
        if (!isValidShape(properties)) return;

        clearPriceLines(TRADE_PRICE_LINE_TITLE);
        setPriceLineEntityId(null);
        const entity = buildPriceLines(priceLines);
        setPriceLineEntityId(entity);
        return;
      }

      // Update the price line data when the drawing is dragged and changed on the chart directly
      if (event === 'points_changed') {
        const priceScale = calculatePriceScale(secondaryAsset);

        if (priceLines?.length === LIMIT_PRICE_LINE_LENGTH) {
          const prices = (shape as any)?._source?._points.map((p: any) => {
            let price = Big(p.price).toString();
            if (price.includes('e')) price = Big(p.price).toFixed(priceScale);
            const pointScale = 10 ** getPriceScale(price);
            const actualScale = price.split('.')?.[1].length || 0;

            const scale = pointScale ? Math.min(actualScale, pointScale, priceScale) : priceScale;
            return Big(price).toFixed(scale);
          });
          if (onUpdatePriceLines) onUpdatePriceLines(prices);
        } else {
          let price = Big((shape as any)?._source?._points?.[0]?.price).toString();

          if (!price) return;

          if (price.includes('e')) price = Big(price).toFixed(priceScale);

          const scale = 10 ** getPriceScale(price);
          const fixedScale = Math.max(scale.toString().length - 1, 0);

          const profit = (properties.profitLevel / scale).toFixed(fixedScale);
          const stop = (properties.stopLevel / scale).toFixed(fixedScale);

          const takeProfit = Big(price).plus(profit).toFixed(fixedScale);
          const stopLoss = Big(price).minus(stop).toFixed(fixedScale);

          if (onUpdatePriceLines) onUpdatePriceLines([takeProfit, stopLoss]);
        }
      }
    },
    [
      buildPriceLines,
      calculatePriceScale,
      clearPriceLines,
      isValidShape,
      onUpdatePriceLines,
      priceLineEntityId,
      priceLines,
      secondaryAsset,
      tvWidget,
    ],
  );

  useEffect(() => {
    try {
      if (!tvWidget) {
        return;
      }

      tvWidget.onChartReady(() => {
        if (!priceLines || !showPreviewOrders) {
          tvWidget.subscribe('onTick', onTick);
        } else {
          buildTradePriceLines();
        }
        tvWidget.subscribe('drawing_event', onDrawingEvent);
      });
    } catch (e) {
      return;
    }
  }, [onTick, onDrawingEvent, tvWidget, priceLines, showPreviewOrders, buildTradePriceLines]);
};

export { useTradingViewPriceLines };

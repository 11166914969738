import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';

import { api } from '@shared/api';
import { UIStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';

type Props = {
  onContinue: () => void;
  onClose: () => void;
};

const IntegrateKoinlySsoTypeStep: React.FC<Props> = observer(({ onContinue, onClose }) => {
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('profile.taxreports');
  const { pathname } = useLocation();
  const avo = useAvo();

  const [hasAccount, setHasAccount] = useState<'yes' | 'no' | null>(null);
  const [hasWallet, setHasWallet] = useState<'yes' | 'no' | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const canContinue = useMemo(() => {
    if (hasAccount === 'no') return true;
    return hasAccount !== null && hasWallet !== null;
  }, [hasAccount, hasWallet]);

  const handleContinue = async () => {
    if (hasAccount === 'yes' && hasWallet === 'yes') {
      try {
        setSubmitting(true);
        avo.koinlySsoSessionStarted({
          screen: pathname,
          withApiKey: false,
        });
        const response = await api.endpoints.createKoinlySsoSession({
          data: {},
        });
        window.open(response.data.url, '_blank', 'noopener,noreferrer');
      } catch (e) {
        showError('ssoSessionError');
      } finally {
        setSubmitting(false);
        onClose();
      }
    } else {
      onContinue();
    }
  };

  const handleClose = () => {
    setHasAccount(null);
    setHasWallet(null);
    onClose();
  };

  const showError = (key: 'ssoSessionError') => {
    addMessageBox({
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      content: (
        <ErrorMessageBox
          title={t(`integrateKoinly.messageBoxes.sso.${key}.title`)}
          content={t(`integrateKoinly.messageBoxes.sso.${key}.subtitle`)}
        />
      ),
    });
  };

  return (
    <Modal
      id='integrate-koinly-sso-type-step'
      open
      onClose={handleClose}
      sx={{ width: '600px' }}
      HeaderProps={{
        title: t('integrateKoinly.sections.ssoType.title'),
        dismissible: true,
        divider: true,
      }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' width='100%' spacing={2} justifyContent='flex-end'>
            <Button variant='text' color='inherit' disableElevation onClick={handleClose} disabled={submitting}>
              {t('integrateKoinly.sections.ssoType.cancel')}
            </Button>
            <Button variant='contained' onClick={handleContinue} disabled={!canContinue} loading={submitting}>
              {t('integrateKoinly.sections.ssoType.continue')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={3} marginY='10px'>
        <Typography color='text.secondary' fontSize={16} fontWeight='400'>
          {t('integrateKoinly.sections.ssoType.subtitle')}
        </Typography>

        <FormControl>
          <FormLabel focused={false} sx={{ fontWeight: 500, fontSize: 14, color: 'text.primary', marginBottom: 1 }}>
            {t('integrateKoinly.sections.ssoType.haveAccount')}
          </FormLabel>
          <RadioGroup
            value={hasAccount}
            onChange={(e) => setHasAccount((e.target as HTMLInputElement).value as 'yes' | 'no')}
          >
            <FormControlLabel
              value='yes'
              sx={{ '.MuiTypography-root': { fontSize: 14 } }}
              control={<Radio />}
              label={t('integrateKoinly.sections.ssoType.yes')}
              labelPlacement='end'
            />
            <FormControlLabel
              value='no'
              sx={{ '.MuiTypography-root': { fontSize: 14 } }}
              control={<Radio />}
              label={t('integrateKoinly.sections.ssoType.no')}
              labelPlacement='end'
            />
          </RadioGroup>
        </FormControl>

        {hasAccount === 'yes' && (
          <FormControl>
            <FormLabel focused={false} sx={{ fontWeight: 500, fontSize: 14, color: 'text.primary', marginBottom: 1 }}>
              {t('integrateKoinly.sections.ssoType.haveWallet')}
            </FormLabel>
            <RadioGroup
              value={hasWallet}
              onChange={(e) => setHasWallet((e.target as HTMLInputElement).value as 'yes' | 'no')}
            >
              <FormControlLabel
                value='yes'
                sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                control={<Radio />}
                label={t('integrateKoinly.sections.ssoType.yes')}
                labelPlacement='end'
              />
              <FormControlLabel
                value='no'
                sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                control={<Radio />}
                label={t('integrateKoinly.sections.ssoType.no')}
                labelPlacement='end'
              />
            </RadioGroup>
          </FormControl>
        )}
      </Stack>
    </Modal>
  );
});

export { IntegrateKoinlySsoTypeStep };

import { Profile } from '@shared/api';
import { ReturnedTransaction, TransactionStatus } from '@shared/api/@types/verification';
import { CountriesEnum, GreenIdStatusEnum, KycStatusEnum } from '@shared/enums';

const isLocked = (userProfile?: Profile): boolean => {
  if (!userProfile) return true;

  const { verification } = userProfile;

  return !verification?.email || !verification?.phone;
};

const inProgress = (userProfile?: Profile, jumioTransaction?: ReturnedTransaction): boolean => {
  if (!userProfile || isLocked(userProfile)) return false;

  const { verification } = userProfile;

  const greenIdStatus = verification?.greenid.status;
  const jumioStatus = jumioTransaction?.status;

  if (greenIdStatus !== GreenIdStatusEnum.IN_PROGRESS && jumioStatus !== TransactionStatus.STARTED) {
    return false;
  }

  return true;
};

const isVerified = (userProfile?: Profile, jumioTransaction?: ReturnedTransaction): boolean => {
  if (!userProfile || isLocked(userProfile)) return false;

  const { verification } = userProfile;

  const greenIdStatus = verification?.greenid.status;
  const jumioStatus = jumioTransaction?.result?.status ?? jumioTransaction?.status;
  const kyc1Pass = userProfile?.kyc?.KYC1 === KycStatusEnum.PASSED;

  if (greenIdStatus === GreenIdStatusEnum.VERIFIED && (kyc1Pass || jumioStatus === TransactionStatus.DONE)) {
    return true;
  }

  return false;
};

const hasStarted = (userProfile?: Profile, jumioTransaction?: ReturnedTransaction): boolean => {
  if (!userProfile || isLocked(userProfile)) return false;

  const { verification } = userProfile;

  const greenIdStatus = verification?.greenid.status;
  const jumioStatus = jumioTransaction?.status;

  const greenIdStarted = greenIdStatus !== GreenIdStatusEnum.NOT_STARTED;
  const jumioStarted = jumioStatus !== undefined && jumioStatus !== TransactionStatus.NOT_STARTED;

  return greenIdStarted || jumioStarted;
};

const stepsPending = (
  userProfile: Profile | undefined,
  jumioTransaction: ReturnedTransaction | undefined,
  isKyc1Complete: () => boolean,
): boolean => {
  if (!userProfile || isLocked(userProfile) || isVerified(userProfile, jumioTransaction)) return false;

  const jumioStatus = jumioTransaction?.status;

  const jumioPending = !isKyc1Complete() && jumioStatus !== TransactionStatus.DONE;

  if (jumioPending || inProgress(userProfile, jumioTransaction)) return true;

  return false;
};

const actionRequired = (
  userProfile: Profile | undefined,
  jumioTransaction: ReturnedTransaction | undefined,
  isKyc1Complete: () => boolean,
): boolean => {
  if (!userProfile || isLocked(userProfile) || isVerified(userProfile, jumioTransaction)) return false;

  const pending = stepsPending(userProfile, jumioTransaction, isKyc1Complete);
  const verified = isVerified(userProfile, jumioTransaction);

  if (pending || verified) return false;

  return true;
};

const isValidCountry = (country: CountriesEnum | undefined) => {
  if (country === CountriesEnum.AU || country === CountriesEnum.NZ) return true;
  return false;
};

export { isLocked, hasStarted, inProgress, isVerified, stepsPending, actionRequired, isValidCountry };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps, StackProps } from '@mui/material';

import { Button, pxToRem, Stack, Typography, TypographyProps } from '@swyftx/react-web-design-system';

type Props = {
  containerProps?: StackProps;
  subTitleProps?: TypographyProps;
  titleProps?: TypographyProps;
  hideSubTitle?: boolean;
  hideTitle?: boolean;
  subTitle?: string;
  title?: string;
  tryAgainProps?: ButtonProps;
  onTryAgain?: () => void;
};

export const GenericError: React.FC<Props> = ({
  containerProps,
  subTitleProps,
  titleProps,
  hideSubTitle,
  hideTitle,
  subTitle,
  title,
  tryAgainProps,
  onTryAgain,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'genericError' });

  const errorTitle = (() => {
    if (title) {
      return title;
    }
    return t('error.title');
  })();

  const errorSubTitle = (() => {
    if (subTitle) {
      return subTitle;
    }

    return t('error.subTitle');
  })();

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      height='100%'
      width='100%'
      spacing={2}
      padding={2}
      {...containerProps}
    >
      {!hideTitle && (
        <Typography fontWeight={600} fontSize={pxToRem(18)} align='center' {...titleProps}>
          {errorTitle}
        </Typography>
      )}
      {!hideSubTitle && (
        <Typography color='text.secondary' fontSize={pxToRem(16)} align='center' {...subTitleProps}>
          {errorSubTitle}
        </Typography>
      )}
      {onTryAgain ? (
        <Button variant='text' color='primary' onClick={onTryAgain} {...tryAgainProps}>
          {t('tryAgain')}
        </Button>
      ) : null}
    </Stack>
  );
};

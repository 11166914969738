import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { UniversalTradeStore } from '@shared/store';
import { TradeSide, TradeState } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

type Props = {
  side: TradeSide;
  multiSelect: boolean;
};

const TradeAssetSelectorActionButtons: React.FC<Props> = observer(({ side, multiSelect }) => {
  const { convertCacheToTradeAssets } = useUniversalTradeUtilityStore();
  const { tradeCache, setTradeState } = UniversalTradeStore;
  const { t } = useTranslation('trade', { keyPrefix: 'tradeAssetSelector' });

  const assetsLength = tradeCache[side].length;

  const addAssets = () => {
    convertCacheToTradeAssets(side);
  };

  const onCancel = () => {
    setTradeState(TradeState.PlaceOrder);
  };

  return (
    <FlexLayout direction='row' className='full-width z-50 p-24' spacing={8}>
      <Button size='lg' variant='outlined' onClick={onCancel} className='w-full'>
        {t('buttonLabels.cancel')}
      </Button>
      {multiSelect && (
        <Button size='lg' disabled={assetsLength === 0} onClick={addAssets} className='w-full'>
          {t('buttonLabels.addAsset', {
            amount: assetsLength > 0 ? assetsLength : '',
            suffix: assetsLength > 1 ? 's' : '',
          })}
        </Button>
      )}
    </FlexLayout>
  );
});

TradeAssetSelectorActionButtons.displayName = 'TradeAssetSelectorActionButtons';

export { TradeAssetSelectorActionButtons };

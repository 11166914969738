import { useMemo } from 'react';

import { DarkMode } from '@swyftx/aviary/icons/outlined';
import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

const useThemeTour = (): TourGuideData =>
  useMemo(
    () => ({
      icon: <DarkMode className='h-28 w-28 text-color-text-primary' />,
      name: 'Choose a theme',
      description: 'Choose a dark, light, or midnight theme on your profile',
      steps: [
        {
          title: 'Open settings menu',
          description: 'Click the circled settings menu in the header',
          spotlightElementId: 'header-settings',
          alignment: 'left',
          borderRadius: 96,
          onNext: () => {
            document.getElementById('header-settings')?.click();
          },
        },
        {
          title: 'Open theme dropdown',
          description: 'Click on the theme dropdown to select a theme',
          spotlightElementId: 'settings-menu-theme',
          alignment: 'left',
          borderRadius: 8,
        },
      ],
    }),
    [],
  );

export { useThemeTour };

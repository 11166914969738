import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Document: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M7.74609 7.85986C7.74609 7.44565 8.08188 7.10986 8.49609 7.10986H11.2511C11.6653 7.10986 12.0011 7.44565 12.0011 7.85986C12.0011 8.27408 11.6653 8.60986 11.2511 8.60986H8.49609C8.08188 8.60986 7.74609 8.27408 7.74609 7.85986Z'
      fill='currentColor'
    />
    <path
      d='M8.49609 11.2871C8.08188 11.2871 7.74609 11.6229 7.74609 12.0371C7.74609 12.4513 8.08188 12.7871 8.49609 12.7871H15.7161C16.1303 12.7871 16.4661 12.4513 16.4661 12.0371C16.4661 11.6229 16.1303 11.2871 15.7161 11.2871H8.49609Z'
      fill='currentColor'
    />
    <path
      d='M8.49609 15.4736C8.08188 15.4736 7.74609 15.8094 7.74609 16.2236C7.74609 16.6378 8.08188 16.9736 8.49609 16.9736H15.7161C16.1303 16.9736 16.4661 16.6378 16.4661 16.2236C16.4661 15.8094 16.1303 15.4736 15.7161 15.4736H8.49609Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.7441 3.48761C18.8017 2.51495 17.4684 2 15.908 2L8.219 2.00399L8.21438 2.00401C6.66606 2.01355 5.34357 2.53213 4.40912 3.50304C3.47746 4.47105 3 5.81725 3 7.357V16.553C3 18.1001 3.48095 19.4525 4.42028 20.4223C5.36245 21.395 6.6962 21.91 8.25664 21.91L15.945 21.907L15.9496 21.907C17.498 21.8974 18.8207 21.3785 19.7554 20.4075C20.6872 19.4393 21.165 18.0929 21.165 16.553V7.357C21.165 5.80983 20.6837 4.45737 19.7441 3.48761ZM18.6669 4.53139C18.0518 3.89658 17.1315 3.50004 15.908 3.5L8.22362 3.50399L8.22158 3.504C7.01091 3.51189 6.09959 3.90972 5.48988 4.54321C4.87704 5.17995 4.5 6.12675 4.5 7.357V16.553C4.5 17.7899 4.88005 18.741 5.49772 19.3787C6.11255 20.0135 7.0325 20.41 8.25606 20.41L15.9404 20.407L15.9423 20.407C17.1529 20.3991 18.0646 20.0011 18.6746 19.3673C19.2878 18.7302 19.665 17.7831 19.665 16.553V7.357C19.665 6.12017 19.2848 5.16913 18.6669 4.53139Z'
      fill='currentColor'
    />
  </svg>
);

export { Document };

export const BASE_FONT_SIZE = 16;

/**
 * Converts px to rem amount used for styling purposes
 *
 * @param px pixel amount
 * @returns '{X}rem' depending on how many pixels are provided
 */
export const pxToRem = (pxValue: number | string | undefined): string => {
  if (pxValue === undefined) return '';

  if (typeof pxValue === 'string') return pxValue;
  // update this with rem ratio by default it is 16
  return `${pxValue / BASE_FONT_SIZE}rem`;
};

/**
 * Converts px number value to style readable format
 *
 * @param pxValue pixels
 * @returns pixel value convertted to style readable format
 */
export const toPx = (pxValue: number): string => `${pxValue}px`;

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmDelete: () => void;
  triggerElement: React.ReactNode;
}

export const ConfirmDeleteOrderModal: React.FC<Props> = (props) => {
  const { open, setOpen, confirmDelete, triggerElement } = props;
  const isXs = useTailwindBreakpoint('xs');
  const { invalidateCache } = useTransactionsCache();

  const onConfirm = useCallback(() => {
    confirmDelete();
    invalidateCache();
    setOpen(false);
  }, [confirmDelete, invalidateCache, setOpen]);

  return (
    <Modal
      id='delete-order-modal'
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      title='Cancel order'
      position={isXs ? 'bottom' : 'center'}
      triggerElement={triggerElement}
    >
      <FlexLayout direction='column' className='p-24 pt-0' spacing={24}>
        <Body color='secondary'>Are you sure you want to cancel / delete the order?</Body>
        <FlexLayout direction='row' className='w-full justify-end' spacing={8}>
          <Button size='lg' variant='outlined' onClick={() => setOpen(false)}>
            Back
          </Button>
          <Button size='lg' color='destructive' onClick={onConfirm}>
            Delete order
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';

import { FormattedText } from '@global-components/Text/FormattedText/FormattedText';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { WithdrawalAddress, withdrawalService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

type Props = {
  withdrawAmount: string;
  selectedAsset: Asset | null;
  withdrawAddress?: WithdrawalAddress;
  onNextStep: (step: number, pushToStack?: boolean | undefined) => void;
};

export const TransferWithdrawFees: React.FC<Props> = ({
  selectedAsset,
  withdrawAmount,
  withdrawAddress,
  onNextStep,
}) => {
  const { t } = useTranslation('modals', { keyPrefix: 'transferModal.withdraw' });
  const { convertRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const withdrawalFee =
    withdrawAddress && selectedAsset ? withdrawalService.getMiningFee(withdrawAddress, selectedAsset) : '0';
  const totalSendExclFee =
    withdrawalFee && Big(withdrawAmount).gt(0) ? Big(withdrawAmount || 0).minus(withdrawalFee) : Big(withdrawAmount);

  if (!baseAsset || !selectedAsset || Big(withdrawalFee).eq(0)) {
    return null;
  }

  return (
    <>
      <FlexLayout spacing={8} direction='column'>
        <FlexLayout direction='row' className='w-full' justifyContent='space-between' alignItems='start'>
          <FlexLayout direction='row' alignItems='center' justifyContent='start' spacing={4}>
            <Body weight='emphasis'>{t('fees')}</Body>
            <Button
              layout='icon'
              variant='ghost'
              leadingIcon={<InfoOutlined color='primary' fontSize='small' />}
              size='sm'
              onClick={() => onNextStep(TransferModalStep.NetworkFees)}
            />
          </FlexLayout>
          <FlexLayout alignItems='end' direction='column'>
            <FormattedText
              currency={selectedAsset}
              value={withdrawalFee}
              secondaryText={{
                typographyProps: {
                  color: 'secondary',
                  className: 'ml-8',
                },
                value: selectedAsset.code,
              }}
              formatOpts={{ appendCode: false, hideCode: true }}
              typographyProps={{
                color: 'primary',
                weight: 'emphasis',
              }}
            />
            <FormattedText
              prefix='~'
              currency={baseAsset}
              value={convertRate(selectedAsset, baseAsset, withdrawalFee, 'midPrice')}
              secondaryText={{
                typographyProps: {
                  color: 'secondary',
                  className: 'ml-8',
                },
                value: baseAsset.code,
              }}
              formatOpts={{ appendCode: false, hideCode: true }}
              typographyProps={{
                color: 'secondary',
                weight: 'emphasis',
              }}
            />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='row' className='w-full' justifyContent='space-between' alignItems='start'>
          <Body weight='emphasis'>{t('totalExcludingFees')}</Body>
          <Tooltip title={totalSendExclFee.eq(0) ? 'Enter an amount to calculate the amount after fees' : ''}>
            <FlexLayout alignItems='end' direction='column'>
              <FormattedText
                currency={selectedAsset}
                value={totalSendExclFee}
                secondaryText={{
                  typographyProps: {
                    color: totalSendExclFee.eq(0) ? 'disabled' : 'secondary',
                    className: 'ml-8',
                  },
                  value: selectedAsset.code,
                }}
                formatOpts={{ appendCode: false, hideCode: true }}
                typographyProps={{
                  color: totalSendExclFee.eq(0) ? 'disabled' : 'primary',
                  weight: 'emphasis',
                }}
              />
              <FormattedText
                prefix='~'
                currency={baseAsset}
                value={convertRate(selectedAsset, baseAsset, totalSendExclFee, 'midPrice')}
                secondaryText={{
                  typographyProps: {
                    color: totalSendExclFee.eq(0) ? 'disabled' : 'secondary',
                    className: 'ml-8',
                  },
                  value: baseAsset.code,
                }}
                formatOpts={{ appendCode: false, hideCode: true }}
                typographyProps={{
                  color: totalSendExclFee.eq(0) ? 'disabled' : 'secondary',
                  weight: 'emphasis',
                }}
              />
            </FlexLayout>
          </Tooltip>
        </FlexLayout>
      </FlexLayout>
    </>
  );
};

import { useCallback, useMemo, useState } from 'react';

import { Individual, IndividualTrustForm } from '@shared/api';

import { useToggle } from 'react-use';
import { v4 as uuid } from 'uuid';

import { useEntityOnboardingSelector } from '../EntityOnboarding.context';
import { IndividualWithUuid } from '../types/EntityApplicationForm.types';

export const useIndividualTrusteeMembersStep = (memberType: 'trustee' | 'beneficiary') => {
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as IndividualTrustForm;
  const prefilledData = (memberType === 'trustee' ? applicationData.trustees : applicationData.beneficiaries) as
    | Individual[]
    | undefined;
  const prevMembers = prefilledData ? prefilledData.map((member) => ({ individual: member, uuid: uuid() })) : undefined;
  const [members, setMembers] = useState<IndividualWithUuid[]>(prevMembers ?? []);
  const [addMemberOpen, toggleAddMemberOpen] = useToggle(false);

  const onAddMember = useCallback(
    (individual: Individual) => {
      setMembers((prev) => [...prev, { individual, uuid: uuid() }]);
      toggleAddMemberOpen(false);
    },
    [toggleAddMemberOpen],
  );

  const onCancel = useCallback(() => {
    toggleAddMemberOpen(false);
  }, [toggleAddMemberOpen]);

  const continueDisabled = useMemo(() => !members.length || addMemberOpen, [addMemberOpen, members.length]);

  const handleRemove = useCallback((memberUuid: string) => {
    setMembers((prev) => prev.filter((trustee) => trustee.uuid !== memberUuid));
  }, []);

  const onEditMember = useCallback((individual: IndividualWithUuid) => {
    setMembers((prev) => [...prev.filter((member) => member.uuid !== individual.uuid), individual]);
  }, []);

  return {
    members,
    addMemberOpen,
    onAddMember,
    onCancel,
    continueDisabled,
    handleRemove,
    toggleAddMemberOpen,
    applicationData,
    onEditMember,
    prevMembers,
  };
};

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { Utility } from '@swyftx/aviary/atoms/Typography';
import { Orderbook, Receipt, Settings, TrendLineUp } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useSwyftxProLayout } from 'src/lib/trade-pro/hooks/useSwyftxProLayout';
import { useMarketTicker } from 'src/lib/utils/hooks';

import { SwyftxProAppHeaderSettingsCurrency } from './SwyftxProAppHeaderSettingsCurrency';
import { SwyftxThemeSelector } from '../SwyftxThemeSelector';

const SwyftxProAppHeaderSettings: React.FC = observer(() => {
  const { showMarketTicker, setShowMarketTicker } = useMarketTicker();
  const { showOrderBook, showOrdersAndBalances, setShowOrderBook, setShowOrdersAndBalances } = useSwyftxProLayout();
  const avo = useAvo();
  const location = useLocation();

  const handleShowOrdersAndBalances = useCallback(() => {
    const newValue = !showOrdersAndBalances;

    avo.settingsCogAction({
      buttonName: 'orders_balances',
      buttonState: newValue ? 'on' : 'off',
      swyftxPro: 'true',
      screen: location.pathname,
    });

    setShowOrdersAndBalances(!showOrdersAndBalances);
  }, [avo, location.pathname, setShowOrdersAndBalances, showOrdersAndBalances]);

  const handleShowOrderBook = useCallback(() => {
    const newValue = !showOrderBook;

    avo.settingsCogAction({
      buttonName: 'orderbook',
      buttonState: newValue ? 'on' : 'off',
      swyftxPro: 'true',
      screen: location.pathname,
    });

    setShowOrderBook(newValue);
  }, [avo, location.pathname, setShowOrderBook, showOrderBook]);

  const handleShowMarketTicker = useCallback(() => {
    const newValue = !showMarketTicker;

    avo.settingsCogAction({
      buttonName: 'market_ticker',
      buttonState: newValue ? 'on' : 'off',
      swyftxPro: 'true',
      screen: location.pathname,
    });

    setShowMarketTicker(newValue);
  }, [avo, location.pathname, setShowMarketTicker, showMarketTicker]);

  return (
    <Menu
      triggerContent={
        <Button
          id='header-settings'
          data-spotlightelementid='pro-settings-trigger'
          variant='outlined'
          layout='icon'
          leadingIcon={<Settings />}
        />
      }
    >
      <FlexLayout direction='column' spacing={16}>
        <FlexLayout direction='column' spacing={8}>
          <Utility>System</Utility>
          <SwyftxProAppHeaderSettingsCurrency />
          <SwyftxThemeSelector />
        </FlexLayout>

        <Separator />

        <FlexLayout direction='column' spacing={8}>
          <Utility>Layout</Utility>
          <MenuItem
            icon={<TrendLineUp width={24} height={24} />}
            text='Market ticker'
            endAdornment={<Switch checked={showMarketTicker} />}
            action={handleShowMarketTicker}
            preventCloseOnSelect
          />
          <MenuItem
            icon={<Orderbook width={24} height={24} />}
            text='Order book'
            endAdornment={<Switch checked={showOrderBook} />}
            action={handleShowOrderBook}
            preventCloseOnSelect
          />
          <MenuItem
            icon={<Receipt className='h-18 w-18' />}
            text='Orders and balances'
            endAdornment={<Switch checked={showOrdersAndBalances} />}
            action={handleShowOrdersAndBalances}
            preventCloseOnSelect
          />
        </FlexLayout>
      </FlexLayout>
    </Menu>
  );
});

SwyftxProAppHeaderSettings.displayName = 'SwyftxProAppHeaderSettings';

export { SwyftxProAppHeaderSettings };

import { useCallback, useState } from 'react';

import { api, CreateWithdrawalPayload, WithdrawalResponse } from '@shared/api';
import { UIStore } from '@shared/store';

import WalletService from '@services/WalletService';

import { AxiosResponse } from 'axios';

export const useWithdraw = () => {
  const { setRefreshTransactions } = UIStore.useUIStore;
  const [loading, setLoading] = useState(false);

  const createWithdrawal = useCallback(
    async (assetCode: string, data: CreateWithdrawalPayload): Promise<AxiosResponse<WithdrawalResponse, any>> => {
      setLoading(true);

      const response = await WalletService.createWithdrawal(assetCode, data);

      api.endpoints.getAllTransactionHistory.resetCache();
      api.endpoints.getAssetTransactionHistory.resetCache();
      setLoading(false);

      setRefreshTransactions(true);

      return response;
    },
    [setRefreshTransactions],
  );

  return { loading, createWithdrawal };
};

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { api } from '@shared/api';
import { ExpectedAnnualSpendOption, SourceOfWealthStatus } from '@shared/api/@types/compliance';
import { Links } from '@shared/constants';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import {
  CashDepositsText,
  ExpectedInvestmentsOptionsText,
  SourcesOfIncomeOptionsText,
} from '@routes/SourceOfWealth/types/QuestionOptions';

import { useSourceOfWealthSelector, useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';
import { useAsync, useToggle } from 'react-use';

import { CURRENTLY_WORKING_VALUES } from './OccupationStep';
import { SourceOfWealthStepContainer } from './SourceOfWealthStepContainer';

interface ReviewAndSubmitItems {
  title: string;
  value: string;
  editDestination: string;
}

const ReviewAndSubmitStep: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();
  const verificationData = useSourceOfWealthSelector((state) => state.context.verificationData);
  const status = useSourceOfWealthSelector((state) => state.context.status);
  const { industry, occupation, sourcesOfWealth, cashDepositsPercentage, employmentType } = verificationData;
  const countryAsset = useCountryAsset();
  const [privacyPolicyChecked, togglePrivacyPolicyChecked] = useToggle(false);

  const fetchPreviousUploads = async () => {
    const response = await api.endpoints.getSowDocuments();
    return response.data;
  };

  const { loading, value: previousUploads } = useAsync(fetchPreviousUploads);

  const expectedAnnualSpend =
    'expectedAnnualSpend' in verificationData
      ? (verificationData.expectedAnnualSpend as ExpectedAnnualSpendOption)
      : undefined;

  const expectedAnnualSpendOther =
    'expectedAnnualSpendOther' in verificationData ? (verificationData.expectedAnnualSpendOther as string) : undefined;

  const isCurrentlyWorking = useMemo(
    () => employmentType && CURRENTLY_WORKING_VALUES.includes(employmentType),
    [employmentType],
  );

  const reviewAndSubmitItems: ReviewAndSubmitItems[] = useMemo(
    () => [
      {
        title: 'Employment Status',
        value: employmentType ?? 'Unknown',
        editDestination: 'GO_TO_OCCUPATION',
      },
      ...(isCurrentlyWorking
        ? [
            {
              title: 'Occupation',
              value: occupation ?? 'Unknown',
              editDestination: 'GO_TO_OCCUPATION',
            },
            {
              title: 'Industry',
              value: industry ?? 'Unknown',
              editDestination: 'GO_TO_OCCUPATION',
            },
          ]
        : []),
      {
        title: 'Sources of wealth',
        value: sourcesOfWealth?.map((source) => SourcesOfIncomeOptionsText[source]).join(', ') ?? 'Unknown',
        editDestination: 'GO_TO_PRIMARY_SOURCE_OF_INCOME',
      },
      {
        title: 'Cash Deposits',
        value: cashDepositsPercentage ? CashDepositsText[cashDepositsPercentage] : 'Unknown',
        editDestination: 'GO_TO_CASH_INCOME',
      },
      {
        title: 'Expected investments',
        value: expectedAnnualSpend
          ? ExpectedInvestmentsOptionsText[expectedAnnualSpend]
          : expectedAnnualSpendOther
          ? formatCurrency(expectedAnnualSpendOther, countryAsset)
          : 'Unknown',
        editDestination: 'GO_TO_EXPECTED_INVESTMENTS',
      },
      {
        title: 'Supporting documents',
        value: loading || !previousUploads ? 'Loading...' : previousUploads.map((file) => file.filename).join(', '),
        editDestination: 'GO_TO_SUPPORTING_DOCUMENTS',
      },
    ],
    [
      cashDepositsPercentage,
      countryAsset,
      employmentType,
      expectedAnnualSpend,
      expectedAnnualSpendOther,
      industry,
      isCurrentlyWorking,
      loading,
      occupation,
      previousUploads,
      sourcesOfWealth,
    ],
  );

  const itemsToShow = useMemo(() => {
    if (status === SourceOfWealthStatus.MORE_INFO_REQUESTED) {
      return reviewAndSubmitItems.filter((item) => item.title === 'Supporting documents');
    }
    return reviewAndSubmitItems;
  }, [reviewAndSubmitItems, status]);

  return (
    <SourceOfWealthStepContainer
      customTitle='Is all the information you provided correct?'
      onSubmit={(e) => {
        e.preventDefault();
        sourceOfWealthService.send({ type: 'NEXT' });
      }}
      submitDisabled={!privacyPolicyChecked}
      customContinueButtonText='Submit'
    >
      <FlexLayout direction='column' spacing={32}>
        <Body size='small' color='secondary'>
          Please review and confirm the information below is correct. Incorrect or insufficient documents will likely
          require further information to be collected, delaying the review process.
        </Body>
        <FlexLayout direction='column' spacing={16}>
          {itemsToShow.map((item) => (
            <FlexLayout direction='column' spacing={0} key={item.title}>
              <FlexLayout direction='row' className='justify-between'>
                <Body size='xsmall' weight='emphasis'>
                  {item.title}
                </Body>
                <Button
                  size='sm'
                  variant='ghost'
                  color='accent'
                  onClick={() => sourceOfWealthService.send(item.editDestination as unknown as any)}
                >
                  Edit
                </Button>
              </FlexLayout>
              <Input disabled placeholder={item.value} />
            </FlexLayout>
          ))}
          <FlexLayout direction='row' spacing={16} className='mt-8 items-center'>
            <Checkbox
              checked={privacyPolicyChecked}
              onCheckedChange={togglePrivacyPolicyChecked}
              className='h-20 w-20  border-2 border-color-border-main'
              onClick={togglePrivacyPolicyChecked}
            />
            <Body size='small'>
              I agree to Swyftx&apos;s{' '}
              <a
                className='cursor-pointer text-color-text-accent'
                href={Links.legal.privacyPolicy}
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
            </Body>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

export { ReviewAndSubmitStep };

import React, { FC, useEffect, useState } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { ChipProps } from '@swyftx/aviary/atoms/Chip/Chip.styles';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';

import { TradePriceHistory } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';
import { formatCurrency, FormatCurrencyOptions } from '@shared/utils';

import { observer } from 'mobx-react-lite';

export enum PortfolioTickerDisplay {
  Percentage = 'percent',
  Amount = 'value',
}

type Props = {
  assetId: number;
  display: PortfolioTickerDisplay;
  isChip?: boolean;
  chipProps?: ChipProps;
  typographyProps?: NumericProps;
  displayProps?: FormatCurrencyOptions;
};

interface PortfolioDifference {
  percent: Big;
  value: Big;
}

export const calculatePortfolioDifference = (
  assetId: number,
  tradePriceHistory: TradePriceHistory,
  currentPrice: Big,
  userBalance: Big,
): PortfolioDifference => {
  if (!tradePriceHistory[assetId?.toString()]) return { percent: Big(0), value: Big(0) };
  // If for some reason we are trying to get a portfolio ticker for an asset we've never traded (can happen)
  const averagePricePaid = Big(tradePriceHistory[assetId.toString()].avgPricePaid);

  if (averagePricePaid.eq(0)) {
    return { percent: Big(0), value: Big(0) };
  }

  // amount the asset has moved relative to purchase price
  // (i.e. paid $500 | current price = $1000 | movement = $500)
  const movement = currentPrice.minus(averagePricePaid);

  // percentage gain or loss relative to asset movement
  // (i.e. paid $500 | movement = $1000 | percentage = 200%)
  const percent = movement.div(averagePricePaid).times(100);

  // TODO: could be weird if we deposit funds because it will be included in the userBalance and will show some insane percentage increase
  // value gain or loss relative to asset movement
  // (i.e. movement = $1 | userBalance = 1000 | value = $1000)
  const value = movement.times(userBalance);

  return { percent, value };
};

// Todo fix up currency and percentage strings with updated Intl when react native 0.65
const PortfolioAssetTicker: FC<Props> = observer(
  ({ assetId, display, isChip = false, chipProps, typographyProps, displayProps }) => {
    const { rates, getRate } = RatesStore.useRatesStore;
    const { balances, userBaseCurrency, tradePriceHistory } = UserStore.useUserStore;
    const baseAsset = assetService.getAsset(userBaseCurrency);

    const [value, setValue] = useState<Big>(Big(0));

    useEffect(() => {
      let userBalance = Big(balances[assetId]?.availableBalance ?? 0);
      userBalance = userBalance.plus(balances[assetId]?.stakingBalance ?? 0);

      const currentPrice = Big(getRate(assetId).midPrice);

      const portfolioChange = calculatePortfolioDifference(assetId, tradePriceHistory, currentPrice, userBalance);

      setValue(display === PortfolioTickerDisplay.Percentage ? portfolioChange.percent : portfolioChange.value);
    }, [assetId, tradePriceHistory, balances, userBaseCurrency, display, rates, getRate]);

    // TODO: text color is not being respected
    const textColor: NumericProps['color'] = value >= Big(0) ? 'success' : 'error';
    const valueSymbol = value >= Big(0) ? '+' : '';
    const symbol = display === PortfolioTickerDisplay.Percentage ? '%' : '$';

    const displayValue =
      display === PortfolioTickerDisplay.Percentage
        ? valueSymbol + value.toFixed(2) + symbol
        : valueSymbol + formatCurrency(value, baseAsset, { appendCode: true, ...displayProps });

    return isChip ? (
      <Chip className='min-w-[70px]' color={value >= Big(0) ? 'success' : 'destructive'} {...chipProps}>
        <Numeric {...typographyProps}>{displayValue}</Numeric>
      </Chip>
    ) : (
      <Numeric {...typographyProps} color={textColor}>
        {displayValue}
      </Numeric>
    );
  },
);

export default PortfolioAssetTicker;

import React from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { useSwyftxProMarketStopLoss } from './useSwyftxProMarketStopLoss';
import { SwyftxProTradeStopLossInput } from '../../SwyftxProTradeStopLossInput';

const SwyftxProMarketStopLoss: React.FC = () => {
  const { showMarketStopLoss, handleChecked, tabs, selectedTab, setSelectedTab } = useSwyftxProMarketStopLoss();

  return (
    <FlexLayout direction='column' spacing={8}>
      <FlexLayout alignItems='center' spacing={8} justifyContent='space-between'>
        <FlexLayout spacing={8} alignItems='center'>
          <Checkbox
            checked={showMarketStopLoss}
            onCheckedChange={handleChecked}
            className='h-16 w-16 cursor-pointer border-color-border-neutral'
          />
          <Tooltip title='A stop loss order order will be placed when the trigger price is reached and liquidity conditions are met.'>
            <Body
              color='primary'
              size='small'
              className='cursor-pointer'
              onClick={() => handleChecked(!showMarketStopLoss)}
            >
              Set{' '}
              <span className='underline decoration-color-text-disabled decoration-dashed underline-offset-4'>
                stop loss
              </span>{' '}
              order
            </Body>
          </Tooltip>
        </FlexLayout>
        {showMarketStopLoss && (
          <EnhancedTabs variant='default' size='sm' tabs={tabs} onChange={setSelectedTab} value={selectedTab} />
        )}
      </FlexLayout>

      {showMarketStopLoss && <SwyftxProTradeStopLossInput type={selectedTab} showPrefillOptions />}
    </FlexLayout>
  );
};

export { SwyftxProMarketStopLoss };

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Image } from '@swyftx/aviary/atoms/Image';

import { SourceOfWealthStatus } from '@shared/api/@types/compliance';

import { useSourceOfWealthAnalytics } from '@routes/SourceOfWealth/useSourceOfWealthAnalytics';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useGetSourceOfWealthStatus } from 'src/lib/verification/hooks/useGetSourceOfWealth';

import { MoreInformationRequiredContent } from './MoreInformationRequiredContent';
import { SowAwaitingActionContent } from './SowAwaitingActionContent';
import { SowRequestedContent } from './SowRequestedContent';
import { SowVerificationExpiredContent } from './SowVerificationExpiredContent';

export const SourceOfWealthBanner: React.FC = observer(() => {
  const navigate = useNavigate();
  const { startedVerification } = useSourceOfWealthAnalytics();
  const { loading, status, verification } = useGetSourceOfWealthStatus();

  if (loading || !status || !verification) return null;

  const navigateToSourceOfWealth = () => {
    if (status === SourceOfWealthStatus.REQUIRED || status === SourceOfWealthStatus.REQUESTED) {
      startedVerification();
    }
    navigate(NavigationURLs.SourceOfWealth);
  };

  const bannerIcon = <Image image='source_of_wealth_check' className='h-64 w-64' usePalette />;

  //If the user has submitted the check and are awaiting action from compliance team
  if ([SourceOfWealthStatus.MORE_INFO_SUBMITTED, SourceOfWealthStatus.SUBMITTED].includes(status)) {
    return <SowAwaitingActionContent bannerIcon={bannerIcon} />;
  }

  // If the user has a pending source of wealth verification, and it has expired.
  if (verification.expiresAt <= DateTime.now().toMillis()) {
    return <SowVerificationExpiredContent bannerIcon={bannerIcon} onAction={navigateToSourceOfWealth} />;
  }

  // If the user has a pending source of wealth verification, and it is still valid.
  if ([SourceOfWealthStatus.REQUIRED, SourceOfWealthStatus.REQUESTED, SourceOfWealthStatus.STARTED].includes(status)) {
    return (
      <SowRequestedContent bannerIcon={bannerIcon} onAction={navigateToSourceOfWealth} verification={verification} />
    );
  }

  // If compliance have requested more information
  if (status === SourceOfWealthStatus.MORE_INFO_REQUESTED) {
    return <MoreInformationRequiredContent bannerIcon={bannerIcon} onAction={navigateToSourceOfWealth} />;
  }

  return null;
});

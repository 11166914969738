/* eslint-disable @typescript-eslint/no-explicit-any */
const GLOBAL_STATE_KEY = 'trading_view_settings';

type StateKeys = {
  theme: 'light' | 'dark';
  side: 'bid' | 'ask';
  showMarks: boolean;
  chartTimeouts: number[];
  onResetCacheNeededCallback: () => void;
  chartActive: boolean;
};

const DEFAULT_TRADINGVIEW_PRICE_SIDE = 'ask';

const DEFAULTS: { [K in keyof StateKeys]: StateKeys[K] } = {
  theme: 'light',
  side: DEFAULT_TRADINGVIEW_PRICE_SIDE,
  showMarks: false,
  chartTimeouts: [],
  chartActive: false,
  onResetCacheNeededCallback: () => {},
};

const updateGlobalState = <K extends keyof StateKeys>(key: K, value: StateKeys[K]): void => {
  if (!(window as any)[GLOBAL_STATE_KEY]) {
    (window as any)[GLOBAL_STATE_KEY] = {};
  }

  (window as any)[GLOBAL_STATE_KEY][key] = value;
};

const getFromGlobalState = <K extends keyof StateKeys>(key: K): StateKeys[K] =>
  (window as any)[GLOBAL_STATE_KEY]?.[key] ?? DEFAULTS[key];
const clearGlobalState = () => {
  delete (window as any)[GLOBAL_STATE_KEY];
};

export { updateGlobalState, getFromGlobalState, clearGlobalState, DEFAULT_TRADINGVIEW_PRICE_SIDE };

import React, { useRef, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ArrowChevronLeft, ArrowChevronRight } from '@swyftx/aviary/icons/outlined';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { SwipeDirection, useSwipe } from '@hooks/useSwipe';

import { observer } from 'mobx-react-lite';

import { AssetSummaryIconsContainer, AssetSummaryItemsSliderContainer } from './@styles/AssetSummaryItemsSlider.styled';
import { useCanShiftSummarySlider } from './AssetSummaryItemsSlider.hooks';
import { ASSET_SUMMARY_BREAKPOINT_SETTINGS } from '../../AssetSummary.const';

type Props = {
  lastElementRef: HTMLDivElement | null;
  children: Array<JSX.Element>;
  backgroundColor?: string;
};

const SWIPE_PX_THRESH = 75;

export const AssetSummaryItemsSlider: React.FC<Props> = observer(({ lastElementRef, children, backgroundColor }) => {
  const { itemWidth, itemSpacing, slideTransition, leftMenuWidth, rightMenuWidth } = ASSET_SUMMARY_BREAKPOINT_SETTINGS;
  const [activeIndex, setActiveIndex] = useState(0);
  const directionRef = useRef<SwipeDirection | null>(null); // for pre-calculating the last element position so we can hide the chevrons instantly

  const { canShiftLeft, canShiftRight } = useCanShiftSummarySlider(activeIndex, directionRef.current, lastElementRef);

  // Responsiveness
  const { contentBreakpoint } = useContentBreakpoint();
  const {
    up: { xs: showIcons },
  } = useContentBreakpoint();

  const shiftLeft = () => {
    if (canShiftLeft) {
      directionRef.current = SwipeDirection.LEFT;
      setActiveIndex((currActiveIndex) => currActiveIndex + 1);
    }
  };

  const shiftRight = () => {
    directionRef.current = SwipeDirection.RIGHT;
    if (canShiftRight) {
      setActiveIndex((currActiveIndex) => currActiveIndex - 1);
    }
  };

  const onSwipe = (direction: SwipeDirection) => {
    directionRef.current = direction;
    if (direction === SwipeDirection.LEFT) {
      shiftLeft();
    } else if (direction === SwipeDirection.RIGHT) {
      shiftRight();
    }
  };

  const { onEnd, onMove, onStart } = useSwipe({ swipePixelThreshold: SWIPE_PX_THRESH, onSwipe });

  const getRightMenu = () =>
    showIcons && (
      <AssetSummaryIconsContainer
        width={`${rightMenuWidth[contentBreakpoint]}px`}
        backgroundColor={backgroundColor}
        slideTransition={slideTransition}
        justifyContent='flex-end'
        direction='row'
        spacing='1rem'
        right={0}
      >
        {canShiftLeft && (
          <Button layout='icon' variant='ghost' leadingIcon={<ArrowChevronRight />} onClick={shiftLeft} />
        )}
      </AssetSummaryIconsContainer>
    );

  const getLeftMenu = () =>
    showIcons && (
      <AssetSummaryIconsContainer
        width={`${leftMenuWidth[contentBreakpoint]}px`}
        backgroundColor={backgroundColor}
        slideTransition={slideTransition}
        justifyContent='flex-start'
        direction='row'
        left={0}
      >
        {canShiftRight && (
          <Button layout='icon' variant='ghost' leadingIcon={<ArrowChevronLeft />} onClick={shiftRight} />
        )}
      </AssetSummaryIconsContainer>
    );

  return (
    <div className='relative h-full w-full overflow-hidden'>
      {canShiftRight && getLeftMenu()}
      {canShiftLeft && getRightMenu()}
      <AssetSummaryItemsSliderContainer
        slideTransition={slideTransition}
        itemWidth={itemWidth[contentBreakpoint]}
        activeIndex={activeIndex}
        onTouchStart={onStart}
        onMouseDown={onStart}
        onTouchCancel={onEnd}
        onMouseMove={onMove}
        onTouchMove={onMove}
        onTouchEnd={onEnd}
        onMouseUp={onEnd}
      >
        <FlexLayout
          style={{
            transform: `translateX(${
              (activeIndex > 0 ? leftMenuWidth[contentBreakpoint] : 0) + itemSpacing[contentBreakpoint] * 2
            }px)`,
          }}
          spacing={16}
          direction='row'
        >
          {children}
        </FlexLayout>
      </AssetSummaryItemsSliderContainer>
    </div>
  );
});

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Locked: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.6137 14.2026C12.6137 13.7884 12.2779 13.4526 11.8637 13.4526C11.4495 13.4526 11.1137 13.7884 11.1137 14.2026V16.4236C11.1137 16.8379 11.4495 17.1736 11.8637 17.1736C12.2779 17.1736 12.6137 16.8379 12.6137 16.4236V14.2026Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.55317 7.27727C6.56598 4.34968 8.94827 1.98764 11.8748 1.99956C14.8013 2.00044 17.1742 4.37389 17.1742 7.30056V8.87673C18.9502 9.49348 20.2254 11.1815 20.2254 13.1685V17.4575C20.2254 19.9668 18.1915 21.9995 15.6834 21.9995H8.04243C5.53434 21.9995 3.50043 19.9668 3.50043 17.4575V13.1685C3.50043 11.181 4.77633 9.49255 6.55316 8.87622L6.55317 7.27727ZM15.6742 7.30056V8.62646H8.05316V7.28227C8.06319 5.18314 9.77168 3.49037 11.8699 3.49956H11.8732C13.9719 3.49956 15.6742 5.20177 15.6742 7.30056ZM8.04243 10.1265C6.36251 10.1265 5.00043 11.4878 5.00043 13.1685V17.4575C5.00043 19.1381 6.36251 20.4995 8.04243 20.4995H15.6834C17.3633 20.4995 18.7254 19.1381 18.7254 17.4575V13.1685C18.7254 11.4878 17.3633 10.1265 15.6834 10.1265H8.04243Z'
      fill='currentColor'
    />
  </svg>
);

export { Locked };

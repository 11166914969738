import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

export type MarketMoversType = 'top' | 'owned' | 'favourites' | 'gainers' | 'losers' | 'new' | 'categories';

export type MarketTab = EnhancedTab<MarketMoversType>;

export const defaultMarketMoversTabs: MarketTab[] = [
  {
    title: 'The top 10',
    value: 'top',
  },
  {
    title: 'Owned',
    value: 'owned',
  },
  {
    title: 'Favourites',
    value: 'favourites',
  },
  {
    title: 'Gainers',
    value: 'gainers',
  },
  {
    title: 'Losers',
    value: 'losers',
  },
  {
    title: 'New',
    value: 'new',
  },
  {
    title: 'Categories',
    value: 'categories',
  },
];

export type AssetMoversTableData = {
  rank: string;
  asset: string;
  buyPrice: string;
  dailyChange: string;
  weeklyChange: string;
  monthlyChange: string;
  spread: string;
  dailyVolume: string;
  marketCap: string;
  trade: string;

  //mobile only data
  assetRanked: string;
  buyPriceChange: string;
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { UniversalTradePanel } from '@routes/Trade/components';

interface Props {
  onClose: () => void;
}

export const FullScreenTradePanel: React.FC<Props> = (props) => {
  const { onClose } = props;

  return (
    <FlexLayout direction='column' className='h-full w-full'>
      <FlexLayout className='absolute right-12 top-12 z-10'>
        <Button variant='outlined' layout='icon' onClick={onClose}>
          <Cross />
        </Button>
      </FlexLayout>
      <UniversalTradePanel />
    </FlexLayout>
  );
};

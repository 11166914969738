import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';

import LimitedSendNotification from '@global-components/LimitedSendNotification/LimitedSendNotification';
import LimitedTradeNotification from '@global-components/LimitedTradeNotification/LimitedTradeNotification';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { AppFeatureData, useIsFeatureEnabled } from 'src/config';
import { checkAssetRedenomination, LimitedFunctionalityType } from 'src/hooks/Assets/useAssetLimitedFunctionality';

type Props = {
  asset: Asset;
  renderContent?: (content: JSX.Element) => JSX.Element; // Allows us to use the conditional rendering from inside here with a custom container in higher context
};

const LimitedFunctionalityNotification: React.FC<Props> = ({ asset, renderContent }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleAsset' });
  const { getFeatureData } = useIsFeatureEnabled();
  const featureFlagaAssetRedenominations = getFeatureData(AppFeatureData.AssetRedenominationInformation);

  const isRestricted = useMemo(() => !assetService.canWithdrawAndDepositAsset(asset), [asset]);

  const isTradeRestricted = useMemo(() => asset.buyDisabled || !asset.sellEnabled, [asset]);

  const redenomAsset = checkAssetRedenomination(asset, featureFlagaAssetRedenominations);

  if (!asset || (!isRestricted && !isTradeRestricted)) return null;

  if (redenomAsset) {
    return (
      <Notification className='w-full' severity='warning'>
        <Body>
          <Trans
            i18nKey={
              redenomAsset.type === LimitedFunctionalityType.RedenominationInProgress
                ? 'tokenSwap.inProgress'
                : 'tokenSwap.completed'
            }
            t={t}
            values={{
              legacyAssetName: asset.name,
              legacyAssetCode: asset.code,
              rebrandedAsset: redenomAsset.assetRedenomination.rebrandedAsset,
            }}
            components={[<BodyLink color='accent' key={asset.code} href={redenomAsset.assetRedenomination.link} />]}
          />
        </Body>
      </Notification>
    );
  }
  const Content = (
    <Notification className='w-full' severity='warning' title={t('bittrex.title', { asset: asset.name })}>
      <ul className='list-inside list-disc'>
        {isRestricted && (
          <li className='font-sans text-12'>
            <LimitedSendNotification asset={asset} />
          </li>
        )}
        {isTradeRestricted && (
          <li className='font-sans text-12'>
            <LimitedTradeNotification asset={asset} />
          </li>
        )}
      </ul>
    </Notification>
  );

  if (renderContent) {
    return renderContent(Content);
  }

  return Content;
};

export default LimitedFunctionalityNotification;

import React, { useCallback, useContext } from 'react';

import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset, AssetType } from '@shared/api';

import { observer } from 'mobx-react-lite';

import { AddNewAddressCryptoFields } from './AddNewAddressCryptoFields';
import { AddNewAddressFiatFields } from './AddNewAddressFiatFields';
import { AddAddressFormData, AddCryptoAddressFormData, AddFiatAddressFormData } from '../../TransferModal.types';

type Props = {
  asset: Asset;
  addAddressFormData: AddAddressFormData;
  onClearFormData: () => void;
  onUpdateFormData: <T extends AddAddressFormData>(key: keyof T, value: T[keyof T]) => void;
  onClose: () => void;
  setAddAddressToken: React.Dispatch<React.SetStateAction<string>>;
};

const AddNewAddressStep: React.FC<Props> = observer(
  ({ asset, addAddressFormData, onClearFormData, onUpdateFormData, onClose, setAddAddressToken }) => {
    const { onBack } = useContext(StepModalContext);
    const onHandleBack = useCallback(() => {
      onBack();
      onClearFormData();
    }, [onBack, onClearFormData]);

    return (
      <StepModal.Step onClose={onClose} hideActions onStepBack={onClearFormData} title='Add new address'>
        {asset.assetType === AssetType.Fiat ? (
          <AddNewAddressFiatFields
            asset={asset}
            addAddressFormData={addAddressFormData as AddFiatAddressFormData}
            onUpdateFormData={onUpdateFormData}
            onClose={onHandleBack}
            setAddAddressToken={setAddAddressToken}
          />
        ) : (
          <AddNewAddressCryptoFields
            asset={asset}
            addAddressFormData={addAddressFormData as AddCryptoAddressFormData}
            onUpdateFormData={onUpdateFormData}
            onClose={onHandleBack}
            setAddAddressToken={setAddAddressToken}
          />
        )}
      </StepModal.Step>
    );
  },
);

export { AddNewAddressStep };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { FullPageCard } from '@global-components/FullPageCard';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { useEntityOnboardingSelector } from './EntityOnboarding.context';
import { EntityOnboardingStepper } from './components/EntityOnboardingStepper';
import { MobileEntityOnboardingStepper } from './components/EntityOnboardingStepper/MobileEntityOnboardingStepper';
import { mergeMeta } from './utils/mergeMeta';

type Props = {
  handleExit?: () => void;
};

const EntityOnboarding: React.FC<Props> = () => {
  const { isLargeScreen } = useScreenBreakpoints();
  const isXs = useTailwindBreakpoint('xs');

  const ActiveStepComponent = useEntityOnboardingSelector((state) => mergeMeta(state.meta)?.Component);

  return (
    <FullPageCard
      id='entityOnboarding'
      headerChildren={
        isLargeScreen && (
          <FlexLayout className='w-full md:w-4/12'>
            <EntityOnboardingStepper />
          </FlexLayout>
        )
      }
    >
      {isXs && <MobileEntityOnboardingStepper />}
      {ActiveStepComponent && <ActiveStepComponent />}
    </FullPageCard>
  );
};

EntityOnboarding.displayName = 'Account Setup';

export default EntityOnboarding;

import React from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Corporate, SmsfCorporateTrustee } from '@shared/api';

interface Props {
  corporate: Corporate | SmsfCorporateTrustee;
}

export const ViewCorporateDetails: React.FC<Props> = (props) => {
  const { corporate } = props;
  const isTrustee = 'businessActivity' in corporate;

  return (
    <FlexLayout direction='column' spacing={24}>
      <FlexLayout direction='column' spacing={4} className='w-full'>
        <Body size='small' weight='emphasis'>
          {isTrustee ? 'Name of business' : 'Company name'}:
        </Body>

        <Input value={corporate.name} disabled />
      </FlexLayout>
      {isTrustee && (
        <>
          <FlexLayout direction='column' spacing={4} className='w-full'>
            <Body size='small' weight='emphasis'>
              Nature of business:
            </Body>
            <Input value={corporate.businessActivity} disabled />
          </FlexLayout>
          <FlexLayout direction='column' spacing={4} className='w-full'>
            <Body size='small' weight='emphasis'>
              Trades in arms, oil or precious materials:
            </Body>
            <Input value={corporate.tradesArmsOilOrPreciousMats ? 'Yes' : 'No'} disabled />
          </FlexLayout>
        </>
      )}
    </FlexLayout>
  );
};

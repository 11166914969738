import { useCallback } from 'react';

import { api } from '@shared/api';
import { UIStore } from '@shared/store';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { PriceAlert, UpdateAlertData } from '../dynamicPriceAlerts.types';

const useToggleDynamicPriceAlertStatus = () => {
  const { getAssetById } = useMarkets();
  const { addToastMessage } = UIStore.useUIStore;

  const togglePriceAlertStatus = useCallback(
    async (alert: PriceAlert) => {
      const secondaryAsset = getAssetById(alert.secondary);
      const action: string = alert.status === 'PAUSED' ? 'resume' : 'pause';

      try {
        const data: UpdateAlertData = { priceAlertUuid: alert.priceAlertUuid };

        let response =
          alert.status === 'PAUSED'
            ? await api.endpoints.resumePriceAlert({ data })
            : await api.endpoints.pausePriceAlert({ data });

        if (secondaryAsset) {
          addToastMessage({ severity: 'success', message: `${secondaryAsset.code} price alert ${action}d` });
        }

        return response.data;
      } catch (e) {
        if (secondaryAsset)
          addToastMessage({ severity: 'error', message: `Failed to ${action} ${secondaryAsset.code}` });
      }
    },
    [addToastMessage, getAssetById],
  );

  return { togglePriceAlertStatus };
};

export { useToggleDynamicPriceAlertStatus };

import React, { useCallback, useContext, useEffect } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Contract, Expand } from '@swyftx/aviary/icons/outlined';

import { TradingViewContext } from '../../../context/TradingView.context';
import { useTradingViewAnalytics } from '../../../hooks';

type Props = {
  fullscreen: boolean;
  setFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TradingViewWidgetHeaderFullScreen: React.FC<Props> = ({ setFullscreen, fullscreen }) => {
  const { primary, secondary } = useContext(TradingViewContext);
  const { trackFullScreenOpened } = useTradingViewAnalytics({ primary, secondary });

  const handleFullscreen = useCallback(() => {
    setFullscreen((prev) => !prev);
  }, [setFullscreen]);

  useEffect(() => {
    if (fullscreen) trackFullScreenOpened();
  }, [fullscreen, trackFullScreenOpened]);

  return (
    <Button
      size='md'
      variant='ghost'
      color='subtle'
      layout='icon'
      leadingIcon={fullscreen ? <Contract /> : <Expand />}
      tooltip={fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
      onClick={handleFullscreen}
    />
  );
};

export { TradingViewWidgetHeaderFullScreen };

import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { CalendarPicker, CalendarPickerTriggerButton } from '@swyftx/aviary/atoms/CalendarPicker';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronRightFilled } from '@swyftx/aviary/icons/filled';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as Popover from '@radix-ui/react-popover';
import { DateTime } from 'luxon';
import { DateRange, DayPickerRangeProps } from 'react-day-picker';

type Props = {
  title?: string;
  placeholder?: string;
  value?: DateRange;
  className?: string;
  customTriggerIcon?: React.ReactElement;
  isSmallScreen?: boolean;
  onChange: (newDate?: DateRange) => void;
} & Omit<DayPickerRangeProps, 'selected' | 'setSelected' | 'mode'>;

const MultiDatePicker: React.FC<Props> = ({
  title,
  value,
  placeholder,
  customTriggerIcon,
  isSmallScreen,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [fromInput, setFromInput] = useState<string>(
    value?.from !== undefined ? DateTime.fromJSDate(value.from).toISODate() : '',
  );
  const [toInput, setToInput] = useState<string>(
    value?.to !== undefined ? DateTime.fromJSDate(value.to).toISODate() : '',
  );
  const [selectedStartDate, setSelectedStartDate] = useState<DateTime | undefined>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<DateTime | undefined>(undefined);

  const isXs = useTailwindBreakpoint('xs');

  useEffect(() => {
    if (value?.from) setSelectedStartDate(DateTime.fromJSDate(value.from));
    if (value?.to) setSelectedEndDate(DateTime.fromJSDate(value.to));
  }, [value?.from, value?.to]);

  const onSelectDate = useCallback(
    (range?: DateRange) => {
      if (range === undefined) {
        setOpen(false);
        return;
      }

      onChange(range);
    },
    [onChange],
  );

  useEffect(() => {
    setFromInput(value?.from !== undefined ? DateTime.fromJSDate(value.from).toISODate() : '');
    setToInput(value?.to !== undefined ? DateTime.fromJSDate(value.to).toISODate() : '');
  }, [value]);

  const onChangeFrom = useCallback(
    (val: string) => {
      setFromInput(val);
      const from = DateTime.fromISO(val);

      if (from.isValid) {
        if (isSmallScreen) {
          setSelectedStartDate(from);
        } else {
          onSelectDate({ from: from.toJSDate(), to: value?.to });
        }
      }
    },
    [isSmallScreen, onSelectDate, value?.to],
  );

  const onChangeTo = useCallback(
    (val: string) => {
      setToInput(val);
      const to = DateTime.fromISO(val);

      if (to.isValid) {
        if (isSmallScreen) {
          setSelectedEndDate(to);
        } else {
          onSelectDate({ to: to.toJSDate(), from: value?.from });
        }
      }
    },
    [isSmallScreen, onSelectDate, value?.from],
  );

  const onReset = useCallback(() => {
    onChange(undefined);
    setOpen(false);
  }, [onChange]);

  const onApply = useCallback(() => {
    if (!selectedEndDate && !selectedStartDate) return;

    onSelectDate({ to: selectedEndDate?.toJSDate(), from: selectedStartDate?.toJSDate() });
    setOpen(false);
  }, [onSelectDate, selectedEndDate, selectedStartDate]);

  return (
    <Popover.Root open={open} onOpenChange={(o) => !o && setOpen(o)}>
      <Popover.Trigger className='w-full'>
        <FlexLayout
          onClick={() => setOpen(true)}
          alignItems='center'
          justifyContent='space-between'
          className={cn(
            'rounded-[8px] border p-8',
            open
              ? 'focus-area-info border-color-border-info'
              : 'border-color-border-main hover:border-color-border-hover',
          )}
          spacing={12}
        >
          <FlexLayout alignItems='center' justifyContent='start' spacing={16}>
            <CalendarPickerTriggerButton leadingIcon={customTriggerIcon} onClick={() => setOpen(true)} />

            {value?.from || value?.to ? (
              <FlexLayout alignItems='center' spacing={4}>
                {value?.from && <Body>{DateTime.fromJSDate(value?.from).toFormat('dd/MM/yyyy')}</Body>}
                <ArrowChevronRightFilled className='h-14 w-14' />
                {value.to && <Body>{DateTime.fromJSDate(value?.to).toFormat('dd/MM/yyyy')}</Body>}
              </FlexLayout>
            ) : (
              <Body className='whitespace-nowrap'>{placeholder}</Body>
            )}
          </FlexLayout>
          {open ? <ArrowChevronUp className='h-20 w-20' /> : <ArrowChevronDown className='h-20 w-20' />}
        </FlexLayout>
      </Popover.Trigger>
      <Popover.Portal>
        <>
          {!isXs && (
            <Popover.Content sideOffset={8} alignOffset={0} align='start' className='z-modal-popover'>
              <Card className='px-8 pt-8'>
                <FlexLayout direction='column' spacing={0}>
                  <FlexLayout direction='row' spacing={8}>
                    <Input
                      placeholder='Start date'
                      className='w-[110px]'
                      type='date'
                      error={DateTime.fromISO(fromInput) > DateTime.fromISO(toInput)}
                      value={fromInput}
                      onChange={(e) => onChangeFrom(e.target.value)}
                    />
                    <Input
                      placeholder='End date'
                      className='w-[110px]'
                      type='date'
                      value={toInput}
                      error={DateTime.fromISO(toInput) < DateTime.fromISO(fromInput)}
                      onChange={(e) => onChangeTo(e.target.value)}
                    />
                  </FlexLayout>
                  <CalendarPicker {...props} selected={value} onSelect={onSelectDate} mode='range' />
                  <div className='pb-8'>
                    <Button size='sm' variant='outlined' disabled={!value?.from && !value?.to} onClick={onReset}>
                      Reset
                    </Button>
                  </div>
                </FlexLayout>
              </Card>
            </Popover.Content>
          )}
          {isXs && (
            <Popover.Content className='z-modal-popover'>
              <Modal
                title={title ? `Select ${title?.toLocaleLowerCase()}` : undefined}
                position='bottom'
                open
                triggerElement={null}
                onClose={() => setOpen(false)}
                className='z-modal-popover'
                onOpenChange={() => setOpen(false)}
                overlayClassName='z-modal-popover-backdrop'
              >
                <FlexLayout direction='column' spacing={8} className='p-16 pt-4'>
                  <FlexLayout direction='row' spacing={8}>
                    <Input
                      placeholder='Start date'
                      containerClassName='w-1/2'
                      type='date'
                      error={DateTime.fromISO(fromInput) > DateTime.fromISO(toInput)}
                      value={fromInput}
                      onChange={(e) => onChangeFrom(e.target.value)}
                    />
                    <Input
                      placeholder='End date'
                      containerClassName='w-1/2'
                      type='date'
                      value={toInput}
                      error={DateTime.fromISO(toInput) < DateTime.fromISO(fromInput)}
                      onChange={(e) => onChangeTo(e.target.value)}
                    />
                  </FlexLayout>

                  <CalendarPicker className='!mt-0' {...props} selected={value} onSelect={onSelectDate} mode='range' />
                  <FlexLayout className='mt-8' spacing={16}>
                    <Button
                      size={isSmallScreen ? 'lg' : 'sm'}
                      className={isSmallScreen ? 'w-full' : ''}
                      variant='outlined'
                      disabled={!value?.from && !value?.to}
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                    {isSmallScreen && (
                      <Button
                        size={isSmallScreen ? 'lg' : 'sm'}
                        className={isSmallScreen ? 'w-full' : ''}
                        variant='filled'
                        disabled={!selectedStartDate && !selectedEndDate}
                        onClick={onApply}
                      >
                        Apply
                      </Button>
                    )}
                  </FlexLayout>
                </FlexLayout>
              </Modal>
            </Popover.Content>
          )}
        </>
      </Popover.Portal>
    </Popover.Root>
  );
};

export { MultiDatePicker };

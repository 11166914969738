import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

// UI Types
export type PriceAlertType = 'active' | 'triggered';
export type CreatePriceAlertType = 'single' | 'multi';
export type PriceAlertGroupBy = 'type' | 'asset';

export type PriceAlertGroup = {
  icon: React.ReactNode;
  title: string;
  alerts: PriceAlert[];
};

// API Types
export type PriceAlertStatus = 'WAITING' | 'TRIGGERED' | 'EXPIRED' | 'PAUSED';
export type PriceAlertTriggerType = 'LIMIT' | 'VOLATILITY';
export type PriceAlertWindow = 'HOUR' | 'DAY';
export enum PriceAlertDirection {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
}

export type BaseAlert = {
  id: number;
  primary: number;
  secondary: number;
  type: 'LIMIT' | 'VOLATILITY';
};

export type BaseTriggeredPriceAlert = {
  dateTriggered: number;
} & BaseAlert;

export type LimitTriggeredPriceAlert = BaseTriggeredPriceAlert & {
  type: 'LIMIT';
  alertPrice: string;
  triggeredPrice: string;
  direction: PriceAlertDirection;
};

export type VolatilityTriggeredPriceAlert = BaseTriggeredPriceAlert & {
  type: 'VOLATILITY';
  window: PriceAlertWindow;
  alertChange: string;
  triggeredChange: string;
};

export type TriggeredPriceAlert = LimitTriggeredPriceAlert | VolatilityTriggeredPriceAlert;

export type BaseActivePriceAlert = {
  status: PriceAlertStatus;
  dateUpdated: number;
  dateCreated: number;
  dateLastTriggered: number | null;
  priceAlertUuid: string;
} & BaseAlert;

export type LimitPriceAlert = BaseActivePriceAlert & {
  type: 'LIMIT';
  price: string;
  direction: PriceAlertDirection;
};

export type VolatilityPriceAlert = BaseActivePriceAlert & {
  type: 'VOLATILITY';
  window: PriceAlertWindow;
  change: string;
};

export type PriceAlert = LimitPriceAlert | VolatilityPriceAlert;

export type GetAlertsResponse = PriceAlert[];

export type PriceAlertSecondaryCount = {
  secondary: number;
  count: number;
};

export type GetAlertCountQuery = {
  type?: PriceAlertType; // filter alerts by type
  primary?: number | number[]; // filter by primary asset/s
  secondary?: number | number[]; // filter by secondary asset/s
  direction?: PriceAlertDirection; // filter by limit alert direction
  window?: PriceAlertWindow; // filter by volatility alert window
  status?: PriceAlertStatus | PriceAlertStatus[]; // filter alerts by status (default: ['WAITING', 'TRIGGERED', 'PAUSED'])
};

export type GetPriceAlertsQuery = {
  type?: PriceAlertType; // filter by type
  primary?: number | number[]; // filter by primary asset/s
  secondary?: number | number[]; // filter by secondary asset/s
  direction?: PriceAlertDirection; // filter by limit alert direction
  window?: PriceAlertWindow; // filter by volatility alert window
  status?: PriceAlertStatus | PriceAlertStatus[]; // filter by status (default: ['WAITING', 'TRIGGERED', 'PAUSED'])
  from?: number; // filter by dateUpdated >= from
  to?: number; // filter by dateUpdated <= to
  orderBy?: 'type' | 'secondary'; // (default: 'secondary')
  limit?: number; // limit result count (max: 100, default: 100)
  offset?: number; // offset results (default: 0)
};

export type GetTriggerAlertsQuery = {
  type?: PriceAlertTriggerType; // filter by alert type
  primary?: number | number[]; // filter by primary asset/s
  secondary?: number | number[]; // filter by secondary asset/s
  direction?: PriceAlertDirection; // filter by limit alert direction
  window?: PriceAlertWindow; // filter by volatility alert window
  limit?: number; // limit result count (max: 100, default: 100)
  offset?: number; // offset results (default: 0)
};

export type LimitAlertData = {
  price: number | string; // the new price to trigger at
};

export type VolatilityAlertData = {
  window: PriceAlertWindow; // the new window to check for price changes
  change: number | string; // the new change in price to trigger at (1.00 = 100%)
};

export type CreateLimitAlertData = LimitAlertData;

export type CreateVolatilityAlertData = VolatilityAlertData;

export type UpdateAlertData = {
  priceAlertUuid: string; // the UUID of the price alert to update
};

export type UpdateLimitAlertData = UpdateAlertData & Partial<LimitAlertData>;

export type UpdateVolatilityAlertData = UpdateAlertData & Partial<VolatilityAlertData>;

export type DeleteAlertsData = {
  all?: boolean; // delete everything
  type?: PriceAlertTriggerType; // filter by type
  primary?: number | number[]; // filter by primary asset/s
  secondary?: number | number[]; // filter by secondary asset/s
  direction?: PriceAlertDirection; // filter by limit alert direction
  window?: PriceAlertWindow; // filter by volatility alert window
};

// UI Data
export const PriceAlertPriceTabs: EnhancedTab<string>[] = [
  {
    title: '-5%',
    value: '-5',
    animateColor: 'error',
  },
  {
    title: '-1%',
    value: '-1',
    animateColor: 'error',
  },
  {
    title: '+1%',
    value: '1',
    animateColor: 'success',
  },
  {
    title: '+5%',
    value: '5',
    animateColor: 'success',
  },
];

export const alertWindowToTitle: { [key in PriceAlertWindow]: string } = {
  HOUR: '1hr',
  DAY: '24hr',
};

export const PriceAlertWindowTabs: EnhancedTab<PriceAlertWindow>[] = [
  {
    title: alertWindowToTitle['DAY'],
    value: 'DAY',
  },
  {
    title: alertWindowToTitle['HOUR'],
    value: 'HOUR',
  },
];

export const PriceAlertTriggerTypeTabs: EnhancedTab<PriceAlertTriggerType>[] = [
  { value: 'LIMIT', title: 'By $ target' },
  { value: 'VOLATILITY', title: 'By % changes' },
];

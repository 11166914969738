import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useAvo } from '@hooks/Avo/useAvo';

type Props = {
  pageName?: string;
  assetCode?: string;
  assetId?: number;
};

const useTrackPageView = ({ pageName = '', assetCode, assetId }: Props = {}) => {
  const avo = useAvo();
  const isXs = useTailwindBreakpoint('xs');
  const { pathname } = useLocation();

  useEffect(() => {
    avo.pageViewed({
      screen: pathname,
      customDestinationPageName_: pageName,
      layout: isXs ? 'mobile' : 'desktop',
      assetCode,
      assetId,
    });
  }, [assetCode, assetId, avo, isXs, pageName, pathname]);
};

export { useTrackPageView };

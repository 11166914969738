import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { Loading } from '@swyftx/aviary/icons/outlined';

import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';

export const FormSubmittingStep: React.FC = () => (
  <EntityOnboardingStepContainer hideTitle>
    <FlexLayout direction='column' className='items-center justify-center text-center' spacing={32}>
      <Heading size='h3'>Submitting entity application</Heading>
      <Loading className='h-32 w-32 animate-spin' />
    </FlexLayout>
  </EntityOnboardingStepContainer>
);

import React, { useContext, useMemo } from 'react';

import { Card, Divider, List, ListItem, Stack } from '@swyftx/react-web-design-system';

import apiKeyHelpers, { ScopeGroup, SelectableAPIKeyScopeItem } from '@shared/api/lib/helpers/apiKeys.helper';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

import { getColumns } from './AdvancedConfiguration.data';
import { AdvancedConfigurationCheckbox } from './components/AdvancedConfigurationCheckbox';
import { AdvancedConfigurationHeader } from './components/AdvancedConfigurationHeader';

const TILE_PADDING = 18;

const AdvancedConfiguration: React.FC = observer(() => {
  const { newApiKeyPayload, setNewApiKeyPayload, showAdvancedConfig } = useContext(ApiKeysContext);
  const { scopes } = newApiKeyPayload;
  const { is, bx } = useContentBreakpoint();

  /** handles on select when a scope is checked as there are specific presets and logic associated with a scope */
  const onSelect = (item: SelectableAPIKeyScopeItem, group: ScopeGroup, isParent: boolean) => {
    if (item.locked) return;

    const localScopes = { ...scopes };

    // set items selection
    item.selected = !item.selected;

    if (isParent) {
      // if parent set all children
      group.children.forEach((child) => {
        child.selected = item.selected;
      });
    }

    // reset all scopes lock status
    apiKeyHelpers.resetAllScopes(localScopes);
    // set all required read scopes
    apiKeyHelpers.checkReadScopes(localScopes);
    // check dependants
    apiKeyHelpers.checkDependants(localScopes);

    setNewApiKeyPayload({
      keyType: apiKeyHelpers.getKeyType(localScopes),
      scopes: { ...localScopes },
    });
  };

  /** formats the scopes in specified columns */
  const formatScopes = (components: JSX.Element[]) => (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {getColumns(components, is.md, is.lg).map((col, index) => (
        <ListItem
          key={`api-key-scope-${index}`}
          disablePadding
          sx={{
            alignItems: 'flex-start',
            flexBasis: bx({
              xs: '100%',
              sm: `calc(50% - ${TILE_PADDING / 2}px)`,
              lg: `calc(33.33% - ${TILE_PADDING / 2}px)`,
              xl: `calc(25% - ${TILE_PADDING / 2}px)`,
            }),
            marginBottom: TILE_PADDING,
          }}
        >
          {col}
        </ListItem>
      ))}
    </List>
  );

  /** constructs scope tiles */
  const buildScopes = useMemo(() => {
    const components = [];
    const localScopes = { ...scopes };

    for (const [key, value] of Object.entries(localScopes)) {
      // get number of selected children
      const selected = value.children.reduce((prev, curr) => {
        let prevData = prev;
        if (curr.selected) prevData += 1;
        return prevData;
      }, 0);

      components.push(
        <Card
          elevation={0}
          sx={{ backgroundColor: 'background.paper', border: `1px solid`, borderColor: 'divider' }}
          contentSx={{ paddingBottom: '16px !important' }}
          key={key}
        >
          <Stack direction='column' spacing={1}>
            <AdvancedConfigurationCheckbox
              label={apiKeyHelpers.formatDisplayName(value.parent.display)}
              checked={selected === value.children.length}
              indeterminate={selected > 0 && selected !== value.children.length}
              onChange={() => {
                scopes[key].parent.selected = selected === value.children.length;
                onSelect(scopes[key].parent, scopes[key], true);
              }}
              labelProps={{ sx: { fontSize: 14, fontWeight: '600' } }}
              sx={{ color: 'divider', borderRadius: '4px' }}
            />

            <Divider sx={{ marginX: '-16px !important' /* subtract card margin */ }} />

            <Stack direction='column'>
              {value.children.map((child) => (
                <AdvancedConfigurationCheckbox
                  key={child.key}
                  label={apiKeyHelpers.formatDisplayName(child.display)}
                  disabled={child.locked}
                  checked={child.selected}
                  onChange={() => onSelect(child, scopes[key], false)}
                  labelProps={{
                    sx: {
                      fontSize: 14,
                      fontWeight: '400',
                      color: child.locked ? 'text.disabled' : 'text.primary',
                    },
                  }}
                  sx={{
                    color: child.locked ? 'text.disabled' : 'divider',
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Card>,
      );
    }

    return formatScopes(components);
  }, [newApiKeyPayload, is]);

  return (
    <Stack>
      <AdvancedConfigurationHeader />

      {showAdvancedConfig && buildScopes}
    </Stack>
  );
});

export { AdvancedConfiguration };

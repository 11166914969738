/* eslint-disable new-cap */
import React, { useContext, useEffect, useRef } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { cn } from '@shared/utils/lib/ui';

import { clearAllTimeouts } from '@utils/window.utils';

import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import {
  ChartingLibraryFeatureset,
  ChartingLibraryWidgetOptions,
  Timezone,
  widget,
} from 'public/assets/charting_library/charting_library';
import {
  getFromGlobalState,
  updateGlobalState,
} from 'src/lib/trade/components/TradingViewWidget/types/tradingViewGlobalState';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import {
  TradingViewSaveLoadAdapter,
  useTradingViewHeaderAdapter,
  useTradingViewThemeAdapter,
  useTradingViewPriceLines,
} from './adapters';
import { useTradingViewOpenOrdersAdapter } from './adapters/useTradingViewOpenOrders/useTradingViewOpenOrdersAdapter';
import { TradingViewContext } from './context/TradingView.context';
import { useTradingViewDatafeed, useTradingViewAnalytics } from './hooks';
import { TradingViewSide } from './types';
import { isAdvancedChartState } from './utils/Tradingview.utils';

type Props = {
  primary: string;
  secondary: string;
  useNewStyle?: boolean;
  onPriceSideChange?: (side: TradingViewSide) => void;
  className?: string;
  priceLines?: string[];
  onUpdatePriceLines?: (triggerPrices: string[]) => void;
};

const AdvancedTradingView: React.FC<Props> = observer(
  ({ primary, secondary, useNewStyle = true, onPriceSideChange, className, priceLines, onUpdatePriceLines }) => {
    const { isSwyftxPro } = useSwyftxPro();
    const isXs = useTailwindBreakpoint('xs');
    const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { isMidnightMode } = useTailwindTheme();
    const { getTradingViewDataFeed } = useTradingViewDatafeed({ primary, mock: false, isSwyftxPro });
    const {
      advancedChartState: chartState,
      setAdvancedChartState,
      selectedResolution,
      showMarks,
    } = useContext(TradingViewContext);

    const { trackChartAreaClicked, trackIndicatorSelected, trackIntervalSelected } = useTradingViewAnalytics({
      primary,
      secondary,
    });

    useTradingViewHeaderAdapter({ tvWidget: chartState, onPriceSideChange, primary, secondary, useNewStyle });

    useTradingViewPriceLines({
      tvWidget: chartState,
      priceLines,
      onUpdatePriceLines,
      secondary,
    });

    useTradingViewOpenOrdersAdapter({ tvWidget: chartState, primary, secondary });

    const { updateTheme } = useTradingViewThemeAdapter({
      tvWidget: isAdvancedChartState(chartState) ? chartState : undefined,
    });

    useEffect(() => {
      if (!chartState) return;
      chartState.onChartReady(() => {
        chartState?.addCustomCSSFile(
          isMidnightMode || document.documentElement.classList.contains('pro')
            ? '/charting_library_pro.css'
            : '/charting_library.css',
        );
      });
    }, [chartState, isMidnightMode]);

    useEffect(() => {
      try {
        if (chartState) {
          chartState?.onChartReady(() => {
            TradingViewSaveLoadAdapter({ tvWidget: chartState, primary, secondary });
            updateTheme();
            chartState?.setSymbol(`${secondary}${primary}`, selectedResolution, () => {});
            updateGlobalState('chartActive', true);
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartState, secondary, selectedResolution]);

    useEffect(() => {
      const cssUrl =
        isMidnightMode || document.documentElement.classList.contains('pro')
          ? '/charting_library_pro.css'
          : '/charting_library.css';
      updateGlobalState('chartActive', true);

      const enabledFeatures: ChartingLibraryFeatureset[] = [
        'side_toolbar_in_fullscreen_mode',
        'header_in_fullscreen_mode',
      ];
      const disabledFeatures: ChartingLibraryFeatureset[] = ['symbol_search_hot_key', 'header_symbol_search'];

      if (isXs) {
        disabledFeatures.push(
          'border_around_the_chart',
          'edit_buttons_in_legend',
          'context_menus',
          'control_bar',
          'timeframes_toolbar',
          'study_overlay_compare_legend_option',
          'study_symbol_ticker_description',
        );
      } else {
        enabledFeatures.push('timeframes_toolbar', 'context_menus', 'control_bar');
      }

      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: `${secondary}${primary}` as string,
        datafeed: getTradingViewDataFeed(),
        interval: '1D' as ChartingLibraryWidgetOptions['interval'],
        container: chartContainerRef.current,
        library_path: '/assets/charting_library/charting_library/',
        custom_css_url: useNewStyle ? cssUrl : '',
        locale: 'en',
        enabled_features: enabledFeatures,
        disabled_features: disabledFeatures,
        auto_save_delay: 1,
        fullscreen: false,
        autosize: true,
        theme: document.documentElement.classList.contains('light') ? 'Light' : 'Dark',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
        overrides: {
          'scalesProperties.fontSize': isXs ? 8 : 12,
        },
      };

      if (useNewStyle && widgetOptions.disabled_features) {
        widgetOptions.disabled_features.push('header_chart_type');
        widgetOptions.disabled_features.push('header_widget');
      }

      const tvWidget = new widget(widgetOptions);

      setAdvancedChartState(tvWidget);

      localStorage.setItem('tradingview.ChartDrawingToolbarWidget.visible', isXs ? 'false' : 'true');

      tvWidget.onChartReady(() => {
        if (!showMarks) tvWidget.chart().executeActionById('hideAllMarks');
        tvWidget.subscribe('mouse_down', trackChartAreaClicked);

        tvWidget.subscribe('study', (indicator) => trackIndicatorSelected(indicator.value));
        tvWidget.chart().onIntervalChanged().subscribe(null, trackIntervalSelected);
      });

      return () => {
        tvWidget.remove();
        setAdvancedChartState(undefined);
        clearAllTimeouts(getFromGlobalState('chartTimeouts'));
        updateGlobalState('chartActive', false);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTradingViewDataFeed, isXs, isSwyftxPro]);

    return <div ref={chartContainerRef} id={`${secondary}${primary}`} className={cn('h-full w-full', className)} />;
  },
);

export { AdvancedTradingView };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeBars: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.125 2.375C8.53921 2.375 8.875 2.71079 8.875 3.125V8.91447H11.125C11.5392 8.91447 11.875 9.25026 11.875 9.66447C11.875 10.0787 11.5392 10.4145 11.125 10.4145H8.875V20.875C8.875 21.2892 8.53921 21.625 8.125 21.625C7.71079 21.625 7.375 21.2892 7.375 20.875V17.8882H5.625C5.21079 17.8882 4.875 17.5524 4.875 17.1382C4.875 16.7239 5.21079 16.3882 5.625 16.3882H7.375V3.125C7.375 2.71079 7.71079 2.375 8.125 2.375Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.875 2.375C15.4608 2.375 15.125 2.71079 15.125 3.125V6.11184L12.875 6.11184C12.4608 6.11184 12.125 6.44763 12.125 6.86184C12.125 7.27606 12.4608 7.61184 12.875 7.61184H15.125L15.125 20.875C15.125 21.2892 15.4608 21.625 15.875 21.625C16.2892 21.625 16.625 21.2892 16.625 20.875L16.625 15.2607H18.375C18.7892 15.2607 19.125 14.9249 19.125 14.5107C19.125 14.0965 18.7892 13.7607 18.375 13.7607H16.625V3.125C16.625 2.71079 16.2892 2.375 15.875 2.375Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeBars };

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { TickInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';

import { api } from '@shared/api';
import { UIStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

type Props = {
  onClose: () => void;
};

const IntegrateKoinlySsoStep: React.FC<Props> = observer(({ onClose }) => {
  const { addMessageBox } = UIStore.useUIStore;
  const { createdApiKeyResponse } = useContext(ApiKeysContext);
  const { t } = useTranslation('profile.taxreports');
  const { pathname } = useLocation();
  const avo = useAvo();

  const [submitting, setSubmitting] = useState(false);

  const handleContinue = async () => {
    if (createdApiKeyResponse === undefined) {
      showError('apiKeyError');
      onClose();
      return;
    }

    try {
      setSubmitting(true);
      avo.koinlySsoSessionStarted({
        screen: pathname,
        withApiKey: true,
      });
      const response = await api.endpoints.createKoinlySsoSession({
        data: {
          apiKey: createdApiKeyResponse.apiKey,
        },
      });
      window.open(response.data.url, '_blank', 'noopener,noreferrer');
    } catch (e) {
      showError('ssoSessionError');
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  const showError = (key: 'apiKeyError' | 'ssoSessionError') => {
    addMessageBox({
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      content: (
        <ErrorMessageBox
          title={t(`integrateKoinly.messageBoxes.sso.${key}.title`)}
          content={t(`integrateKoinly.messageBoxes.sso.${key}.subtitle`)}
        />
      ),
    });
  };

  return (
    <Modal
      id='integrate-koinly-sso-step'
      open
      onClose={() => {}}
      sx={{ width: '400px' }}
      FooterProps={{
        divider: true,
        content: (
          <Button onClick={handleContinue} variant='contained' size='large' sx={{ width: '100%' }} loading={submitting}>
            {t('integrateKoinly.sections.sso.continue')}
          </Button>
        ),
      }}
    >
      <Stack direction='column' spacing={2} alignItems='center' textAlign='center'>
        <Box width='70px' height='70px'>
          <TickInCircleFilled id='integrate-koinly-sso-step-icon' className='h-full w-full text-color-text-success' />
        </Box>

        <Typography fontSize={20} fontWeight='700'>
          {t('integrateKoinly.sections.sso.title')}
        </Typography>

        <Typography color='text.secondary' fontSize={16} fontWeight='400'>
          {t('integrateKoinly.sections.sso.subtitle')}
        </Typography>

        <Typography color='text.secondary' fontSize={16} fontWeight='400'>
          {t('integrateKoinly.sections.sso.syncNotice')}
        </Typography>
      </Stack>
    </Modal>
  );
});

export { IntegrateKoinlySsoStep };

import { useCallback, useContext } from 'react';

import { formatValueToShorthand } from '@swyftx/aviary/utils';

import { ISeriesApi, MouseEventParams, Time } from 'lightweight-charts';

import { useSimpleChartTooltipUtils } from './useSimpleChartTooltipUtils';
import { TradingViewContext } from '../../context/TradingView.context';

type Props = {
  chartContainerRef: React.MutableRefObject<HTMLInputElement>;
  chartVolumeRef: React.MutableRefObject<HTMLDivElement>;
  series?: ISeriesApi<'Histogram'>;
};

const useSimpleChartVolumeTooltip = ({ chartContainerRef, chartVolumeRef, series }: Props) => {
  const { isValidParam } = useSimpleChartTooltipUtils({ chartContainerRef });
  const { showSimpleVolume, secondary } = useContext(TradingViewContext);

  const buildVolumeTooltip = useCallback(
    (param: MouseEventParams<Time>) => {
      if (!isValidParam(param) || !series || !chartVolumeRef || !showSimpleVolume) {
        chartVolumeRef.current.classList.add('hidden');
        return;
      }

      chartVolumeRef.current.classList.remove('hidden');

      const volumeData = param.seriesData.get(series) as any;

      if (!volumeData) return;

      chartVolumeRef.current.innerHTML = `
      <div class="font-sans text-12 line-height-16 letter-spacing-expanded text-color-tet-primary font-400">Volume</div>
        <div class="font-sans text-12 line-height-16 letter-spacing-expanded font-500" style="color:${volumeData.color.slice(
          0,
          7,
        )}">${formatValueToShorthand(volumeData.value)} ${secondary}</div>
`;
    },
    [chartVolumeRef, isValidParam, secondary, series, showSimpleVolume],
  );

  return {
    buildVolumeTooltip,
  };
};

export { useSimpleChartVolumeTooltip };

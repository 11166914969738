import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TrackMarket: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M19.2133 10.9036C19.2133 6.53875 15.6746 3 11.3098 3C6.94403 3 3.40625 6.53875 3.40625 10.9036C3.40625 13.6318 4.78789 16.037 6.89051 17.4576'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.8359 14.529L19.9726 13.9141L20.5875 16.0507'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.98438 20.9993L12.2378 17.3643L17.1679 18.8403L19.9828 13.9141'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9553 10.5312H10.0625V13.2404H12.0137C12.8053 13.2404 13.4383 12.561 13.3615 11.7526C13.2943 11.0483 12.6628 10.5312 11.9553 10.5312Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9553 7.82031H10.0625V10.5295H12.0137C12.8053 10.5295 13.4383 9.84998 13.3615 9.04163C13.2943 8.33733 12.6628 7.82031 11.9553 7.82031Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4688 14.0371V13.2383'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4766 7.81827V7.01953'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { TrackMarket };

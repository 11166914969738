import React, { useContext, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';
import { TransferModalSelectAsset } from '@swyftx/aviary/complex/TransferModal/components/TransferModalSelectAsset';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';
import { WithdrawalAddress, WithdrawalReasonEnum } from '@shared/services';

import { WithdrawalLimit } from '@routes/Wallet/components';

import { TransferDepositReceive } from './TransferDepositReceive';
import { TransferWithdrawSend } from './TransferWithdrawSend';
import { TransferModalDetailsTabs } from './components/TransferModalDetailsTabs/TransferModalDetailsTabs';
import { TransferEnum } from '../../TransferModal.types';
import { TransferModalErrorContentStandard, TransferModalErrorContentVerification } from '../TransferModalErrorContent';
import { useTransferModalErrors } from '../TransferModalErrorContent/TransferModalErrorContent.hooks';

type Props = {
  transferType: TransferEnum;
  onClose: () => void;
  selectedAsset?: Asset;
  depositType?: DepositMethodEnum;
  withdrawAddress?: WithdrawalAddress;
  withdrawReason?: WithdrawalReasonEnum;
  withdrawAmount: string;
  setWithdrawToken: React.Dispatch<React.SetStateAction<string>>;
  setWithdrawAddress: React.Dispatch<React.SetStateAction<WithdrawalAddress | undefined>>;
  setWithdrawReason: React.Dispatch<React.SetStateAction<WithdrawalReasonEnum | undefined>>;
  setWithdrawAmount: React.Dispatch<React.SetStateAction<string>>;
  setTransferType: React.Dispatch<React.SetStateAction<TransferEnum>>;
  setDepositType: React.Dispatch<React.SetStateAction<DepositMethodEnum>>;
};

export const TransferRootStep: React.FC<Props> = ({
  transferType,
  depositType,
  selectedAsset,
  withdrawAddress,
  withdrawReason,
  withdrawAmount,
  setWithdrawToken,
  setWithdrawAddress,
  setWithdrawReason,
  setWithdrawAmount,
  setDepositType,
  setTransferType,
  onClose,
}) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [onAction, setOnAction] = useState<() => void>(() => {});
  const error = useTransferModalErrors(transferType, selectedAsset?.assetType);
  const [hideContinueButton, setHideContinueButton] = useState(true);
  const { onNextStep } = useContext(StepModalContext);

  const content = useMemo(() => {
    if (error) {
      if (error.errorType !== 'standard') {
        return (
          <TransferModalErrorContentVerification
            title={error.title}
            subTitle={error.subTitle}
            errorType={error.errorType}
            showButton={error.errorType !== 'limit'}
            onClose={onClose}
          />
        );
      }

      return <TransferModalErrorContentStandard title={error.title} subTitle={error.subTitle} onClose={onClose} />;
    }

    if (transferType === TransferEnum.DepositReceive) {
      return (
        <TransferDepositReceive
          selectedAsset={selectedAsset}
          depositType={depositType}
          setDepositType={setDepositType}
          setHideContinue={setHideContinueButton}
          setOnAction={setOnAction}
          setDisabled={setDisabled}
          onClose={onClose}
        />
      );
    }

    return (
      <TransferWithdrawSend
        initialAsset={selectedAsset}
        withdrawAddress={withdrawAddress}
        setWithdrawAddress={setWithdrawAddress}
        withdrawReason={withdrawReason}
        withdrawAmount={withdrawAmount}
        setWithdrawToken={setWithdrawToken}
        setWithdrawReason={setWithdrawReason}
        setWithdrawAmount={setWithdrawAmount}
        setHideContinue={setHideContinueButton}
        setOnAction={setOnAction}
        setDisabled={setDisabled}
        onClose={onClose}
        onNextStep={onNextStep}
        onReceive={() => setTransferType(TransferEnum.DepositReceive)}
      />
    );
  }, [
    error,
    transferType,
    selectedAsset,
    withdrawAddress,
    setWithdrawAddress,
    withdrawReason,
    withdrawAmount,
    setWithdrawToken,
    setWithdrawReason,
    setWithdrawAmount,
    onClose,
    onNextStep,
    depositType,
    setDepositType,
    setTransferType,
  ]);

  return (
    <StepModal.Step
      hideContinue={hideContinueButton}
      footerProps={{ className: 'px-16 pb-16' }}
      hideActions={Boolean(error)}
      onAction={onAction}
      onClose={onClose}
      locked={disabled}
    >
      <FlexLayout direction='column'>
        <TransferModalDetailsTabs transferType={transferType} setTransferType={setTransferType} />
        <FlexLayout direction='column' spacing={16} className='p-24'>
          {transferType === TransferEnum.WithdrawSend && !error && <WithdrawalLimit variant='compact' />}
          {!error && (
            <TransferModalSelectAsset asset={selectedAsset} onClick={() => onNextStep(TransferModalStep.SelectAsset)} />
          )}
          {content}
        </FlexLayout>
      </FlexLayout>
    </StepModal.Step>
  );
};

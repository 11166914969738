import React, { Dispatch, SetStateAction, useState } from 'react';

import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { Form } from '@global-components/Form';
import { NameInput } from '@global-components/Input';
import { AccountNumberInput } from '@global-components/Input/AccountNumberInput/AccountNumberInput';
import { BsbInput } from '@global-components/Input/BsbInput/BsbInput';

import { Asset } from '@shared/api';
import { withdrawalService } from '@shared/services';
import { UIStore } from '@shared/store';

import { WithdrawalAccountFormInput, withdrawalFundsAddAccountForm } from '@services/WithdrawService/WithdrawService';

import { observer } from 'mobx-react-lite';

import { FiatWithdrawalFormData } from './FiatWithdrawalCreateNewAccountModal.data';
import { FiatWithdrawalEmailVerifyModal } from './FiatWithdrawalEmailVerifyModal';
import { FiatWithdrawalPhoneVerifyModal } from './FiatWithdrawalPhoneVerifyModal';

type Props = {
  asset: Asset;
  onClose: () => void;
};

interface SelectInputProps {
  setFormData: Dispatch<SetStateAction<FiatWithdrawalFormData>>;
  formData: FiatWithdrawalFormData;
  inputs: WithdrawalAccountFormInput;
}

const SelectInput: React.FC<SelectInputProps> = ({ setFormData, formData, inputs }) => {
  const { placeholder, type, label, validation } = inputs;
  switch (type) {
    case 'AccountName':
      return (
        <NameInput
          id={`create-new--withdraw-account-form-account-name-${label}`}
          label={label}
          onChange={(e) =>
            setFormData({
              ...formData,
              accountName: e.target.value,
            })
          }
          placeholder={placeholder}
          type='AccountName'
          value={formData.accountName}
          required
        />
      );
    case 'AccountHolderName':
      return (
        <NameInput
          id={`create-new--withdraw-account-form-account-name-${label}`}
          label={label}
          onChange={(e) =>
            setFormData({
              ...formData,
              accountHolderName: e.target.value,
            })
          }
          placeholder={placeholder}
          type='AccountHolderName'
          value={formData.accountHolderName}
          required
        />
      );
    case 'AccountNumber':
      if (!validation) {
        throw new Error('Account number validation not provided for country');
      }

      return (
        <AccountNumberInput
          characters={validation.characters}
          id='create-new--withdraw-account-form-account-number'
          value={formData.accountNumber}
          onChange={(e) =>
            setFormData({
              ...formData,
              accountNumber: e.target.value,
            })
          }
          required
        />
      );
    case 'BsbInput':
      return (
        <BsbInput
          id='create-new--withdraw-account-form-bsb'
          value={formData.bsb}
          onChange={(e) =>
            setFormData({
              ...formData,
              bsb: e.target.value,
            })
          }
          required
        />
      );
    default:
      throw new Error('Incorrect form data provided when add new withdrawal account');
  }
};

const FiatWithdrawalCreateNewAccountModal: React.FC<Props> = observer(({ asset, onClose }) => {
  const { addToastMessage } = UIStore.useUIStore;
  const [showPhoneVerifiedModal, setShowPhoneVerifiedModal] = useState(false);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [formData, setFormData] = useState<FiatWithdrawalFormData>({
    accountName: '',
    accountHolderName: '',
    accountNumber: '',
    bsb: '',
  });

  const createAccount = async () => {
    try {
      const { mfaType, token } = await withdrawalService.addWithdrawAddress(
        asset.code,
        formData.accountName,
        formData.accountNumber,
        formData.accountHolderName,
        formData.bsb,
      );

      switch (mfaType) {
        case 'email':
          setShowEmailVerifyModal(true);
          break;
        case 'sms':
          setAuthToken(token);
          setShowPhoneVerifiedModal(true);
          break;
        default:
          onClose();
          break;
      }
    } catch (error: any) {
      // TODO: handle api error
      setShowPhoneVerifiedModal(false);
      setAuthToken('');
      addToastMessage({ severity: 'error', message: error?.message || 'Something went wrong' });
    }
  };

  const text = withdrawalFundsAddAccountForm(asset.code);

  return (
    <>
      <Modal
        PII
        id='deposit-funds-info-modal'
        open
        sx={{ width: '600px' }}
        HeaderProps={{ title: 'Add new withdrawal account', dismissible: true, divider: true }}
        FooterProps={{
          divider: true,
          content: (
            <Stack direction='row' width='100%' justifyContent='flex-end' spacing={1}>
              <Button variant='text' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='contained' disableElevation type='submit' form='create-new--withdraw-account-form'>
                Add account
              </Button>
            </Stack>
          ),
        }}
        onClose={onClose}
      >
        <Stack direction='column' spacing={2} height='100%' minHeight='600px'>
          <Typography fontWeight='400' fontSize={16} color='text.secondary'>
            Please ensure all account information is correct as withdrawals made to an incorrect account will incur a
            $40 {asset.code.toUpperCase()} Dishonour Fee.
          </Typography>

          <Form
            id='create-new--withdraw-account-form'
            onSubmit={createAccount}
            setSubmitting={() => {}}
            submitting={false}
            hideButton
          >
            {text.forms.map((form) => (
              <SelectInput key={`forms-map-${form.type}`} setFormData={setFormData} formData={formData} inputs={form} />
            ))}
          </Form>
        </Stack>
      </Modal>

      {/* verify phone modal */}
      {showPhoneVerifiedModal && (
        <FiatWithdrawalPhoneVerifyModal asset={asset} onClose={onClose} formData={formData} authToken={authToken} />
      )}

      {/* verify email modal */}
      {showEmailVerifyModal && <FiatWithdrawalEmailVerifyModal onClose={onClose} />}
    </>
  );
});

export { FiatWithdrawalCreateNewAccountModal };

export default FiatWithdrawalCreateNewAccountModal;

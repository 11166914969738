import React, { useContext, useEffect } from 'react';

import { AffiliateSignUp, AffiliateDashboard } from '@routes/Profile/subroutes/AffiliateProgram/components';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

const ProfileAffiliateProgram: React.FC = observer(() => {
  const { fetchedAffiliateInfo, affiliateInfo, fetchAffiliateInfo } = useContext(ProfileContext);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAffiliateInfo();
    };

    fetchData();
  }, [fetchAffiliateInfo]);

  return (
    <>
      {fetchedAffiliateInfo && !affiliateInfo && <AffiliateSignUp />}
      {fetchedAffiliateInfo && affiliateInfo && <AffiliateDashboard />}
    </>
  );
});

export { ProfileAffiliateProgram };

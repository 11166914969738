import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { RecurringOrderTemplate } from '@shared/api/@types/recurringOrder';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AutoInvestDonutChart } from '../AutoInvestDonutChart';

type Props = {
  templateItem: RecurringOrderTemplate;
};

const AutoInvestOrderItemMobileTemplateItem: React.FC<Props> = observer(({ templateItem }) => {
  const { navigate } = useNavigateRoute();
  const countryAsset = useCountryAsset();
  const asset = useMemo(() => assetService.getAsset(templateItem.secondaryAssetId), [templateItem.secondaryAssetId]);

  const navigateToWallet = useCallback(() => {
    navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset?.code } });
  }, [asset?.code, navigate]);

  if (!asset || !countryAsset) return null;

  return (
    <FlexLayout
      onClick={navigateToWallet}
      direction='column'
      spacing={8}
      className='cursor-pointer rounded-8 p-8 hover:bg-color-background-surface-secondary'
    >
      <FlexLayout alignItems='center' justifyContent='space-between' className=' py-12 pt-0'>
        <FlexLayout alignItems='center' spacing={8}>
          <AssetIcon size={20} asset={asset} />
          <Body size='small' weight='emphasis'>
            {asset.name}
          </Body>
        </FlexLayout>
        <AutoInvestDonutChart asset={asset} percentage={Big(templateItem.active ? templateItem.percentage : 0)} />
      </FlexLayout>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <Body size='small'>Invested</Body>
        <FlexLayout alignItems='center' spacing={4}>
          <Numeric color='primary' size='small'>
            {formatCurrency(templateItem.total, countryAsset, { hideCode: true, appendCode: false })}
          </Numeric>
          <Numeric color='secondary' size='small'>
            {countryAsset.code}
          </Numeric>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <Body size='small'>Avg. buy price</Body>

        <FlexLayout alignItems='center' spacing={4}>
          <Numeric color='primary' size='small'>
            {formatCurrency(Big(templateItem.total).div(templateItem.qty), countryAsset, {
              hideCode: true,
              appendCode: false,
            })}
          </Numeric>
          <Numeric color='secondary' size='small'>
            {countryAsset.code}/{asset.code}
          </Numeric>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <Body size='small'>Balance</Body>
        <FlexLayout alignItems='center' spacing={4}>
          <Numeric color='primary' size='small'>
            {formatCurrency(templateItem.qty, asset, { hideCode: true, appendCode: false })}
          </Numeric>
          <Numeric color='secondary' size='small'>
            {asset.code}
          </Numeric>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AutoInvestOrderItemMobileTemplateItem };

import { SwyftxError } from './SwyftxError';

const logTag = '[REST_API]';

export class RateLimitError extends SwyftxError {
  data: { retryAfter: number };

  isRateLimitError = true;

  constructor(retryAfter: number) {
    super(logTag, 'You have tried that too many times. Please try again later.');
    this.data = { retryAfter };
  }
}

import { useCallback } from 'react';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useUniversalTradeUtilityStore } from './useUniversalTradeUtilityStore';

const useHighSlippage = () => {
  const { getMinimumOrderAmount, convertRate } = RatesStore.useRatesStore;
  const { highSlippageTrades } = useUniversalTradeUtilityStore();

  const getIsHighSlippage = useCallback(
    (asset: Asset) =>
      highSlippageTrades.findIndex((data) => {
        if (asset.assetType === AssetType.Fiat) return false;

        return [data.to, data.from].includes(asset.id);
      }) > -1,
    [highSlippageTrades],
  );

  const calculateSlippage = (fromAsset: Asset, toAsset: Asset, quotedPrice: Big) => {
    const minimumAmount = getMinimumOrderAmount(fromAsset);
    const rate = convertRate(fromAsset, toAsset, minimumAmount, 'midPrice');

    if (rate.eq(0) || minimumAmount.eq(0)) {
      return {
        currentPrice: 0,
        slippagePercentage: 0,
      };
    }

    const currentPrice = minimumAmount.div(rate).toString();

    const quoteToPriceDifference = quotedPrice.minus(currentPrice);

    return {
      currentPrice,
      slippage: Big(quoteToPriceDifference).div(currentPrice).times(100).toNumber(),
    };
  };

  return {
    getIsHighSlippage,
    calculateSlippage,
  };
};

export { useHighSlippage };

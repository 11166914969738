import React, { useCallback, useContext, useMemo } from 'react';

import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { TradingViewContext } from '../../../context/TradingView.context';
import { TradingViewSide } from '../../../types';

type Props = {
  changeSideEnabled?: boolean;
  onPriceSideChange?: (side: TradingViewSide) => void;
};

const TradingViewWidgetHeaderPriceSide: React.FC<Props> = ({ changeSideEnabled = true, onPriceSideChange }) => {
  const { side, setSide, onUpdateSide, loadingSide } = useContext(TradingViewContext);

  const onTogglePriceSide = useCallback(
    (newSide: TradingViewSide) => {
      setSide(newSide);
      onUpdateSide(newSide, onPriceSideChange);
    },
    [onPriceSideChange, onUpdateSide, setSide],
  );

  type SideTab = EnhancedTab<TradingViewSide>;

  const orderTypeTabs: SideTab[] = useMemo(
    () => [
      {
        title: 'Bid price',
        value: 'bid',
        tooltip: 'Show the current sell price',
        disabled: loadingSide || !changeSideEnabled,
      },
      {
        title: 'Ask price',
        value: 'ask',
        tooltip: 'Show the current buy price',
        disabled: loadingSide || !changeSideEnabled,
      },
    ],
    [changeSideEnabled, loadingSide],
  );

  return (
    <EnhancedTabs<TradingViewSide>
      variant='default'
      tabs={orderTypeTabs}
      onChange={(newSide) => onTogglePriceSide(newSide)}
      size='sm'
      value={side}
    />
  );
};

export { TradingViewWidgetHeaderPriceSide };

import { useMemo } from 'react';

import { AutoInvestAsset } from 'src/lib/auto-invest/autoInvest.types';

type Props = {
  autoInvestAssets: AutoInvestAsset[];
};

const useAutoInvestAllocationCheck = ({ autoInvestAssets }: Props) => {
  const validAssets = useMemo(() => {
    const everyAssetAssigned = autoInvestAssets.every((state) => state.assetCode !== '');
    const validPercentages = autoInvestAssets.reduce((prev, cur) => prev + cur.percentage, 0) === 100;
    let tooltip = '';
    if (!everyAssetAssigned) {
      tooltip = 'Please make sure you have selected an asset on each line';
    } else if (!validPercentages) {
      tooltip = 'Please make sure that the asset allocations add up to 100%';
    }

    return {
      valid: everyAssetAssigned && validPercentages,
      tooltip,
    };
  }, [autoInvestAssets]);

  return {
    validAssets,
  };
};

export { useAutoInvestAllocationCheck };

import React, { useState } from 'react';

import { Stack } from '@swyftx/react-web-design-system';

import { UIStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { MAXIMUM_COLUMN_SPACING } from '@Dashboard/Dashboard.data';
import { useDashboardRows } from '@Dashboard/hooks';

import { DragDropContext, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { DragAndDropRowType } from 'src/global-components/DragAndDrop/DragAndDrop.data';
import { DragAndDropRow } from 'src/global-components/DragAndDrop/DragAndDropRow';

type Props = {
  rows: DragAndDropRowType[];
  onUpdateRows: (rows: DragAndDropRowType[]) => void;
  editMode?: boolean;
};

const DRAGGABLE_QUERY = 'data-rbd-draggable-id';
const DROPPABLE_QUERY = 'data-rbd-droppable-id';

const DragAndDropContainer: React.FC<Props> = ({ rows, onUpdateRows, editMode = true }) => {
  const [placeholderProps, setPlaceholderProps] = useState<any>(undefined);
  const { isLargeContent } = useContentBreakpoint();
  const { getItemSpacing } = useDashboardRows();
  const { mainContentWidth } = UIStore.useUIStore;

  const OnDragEnd = (result: DropResult) => {
    setPlaceholderProps(undefined);

    const { source, destination } = result;
    // Multiple cases depends on the destination of Drag n Drop
    // 1. When the destination is not given
    if (!destination) return;

    const cachedRows = [...rows];
    const sourceRow = cachedRows[Number(source.droppableId)];
    const sourceItem = sourceRow.items[source.index];
    const { droppableId } = destination;

    // 2. When the destination is the first gap row (create row)
    if (droppableId === '-1') {
      sourceRow.items.splice(source.index, 1);
      cachedRows.splice(0, 0, { items: [sourceItem] });

      onUpdateRows(cachedRows.filter((cachedRow) => cachedRow.items.length > 0));
      return;
    }

    if (droppableId.includes('_gap')) {
      // 3. When the destination is the gap row (create row)
      const gapIndex = droppableId.split('_gap')[0];

      cachedRows.splice(Number(gapIndex) + 1, 0, { items: [sourceItem] });
      sourceRow.items.splice(source.index, 1);
    } else {
      // 4. When the destination is not the gap row (insert into the existing row)
      const destinationRow = cachedRows[Number(destination.droppableId)];

      sourceRow.items.splice(source.index, 1);

      if (destinationRow) {
        destinationRow.items.splice(destination.index, 0, sourceItem);
      } else {
        cachedRows.push({ items: [sourceItem] });
      }
    }

    onUpdateRows(cachedRows.filter((cachedRow) => cachedRow.items.length > 0));
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${DRAGGABLE_QUERY}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const getDroppableDom = (droppableId: string) => {
    const domQuery = `[${DROPPABLE_QUERY}='${droppableId}']`;
    const droppableDOM = document.querySelector(domQuery);

    return droppableDOM;
  };

  // To update the placeholder props (during the drag)
  const onDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);
    const droppableDom = getDroppableDom(event.destination.droppableId);

    if (!draggedDOM || !draggedDOM.parentNode || !draggedDOM.firstElementChild) {
      return;
    }

    const sourceIndex = event.source.index;

    const childrenArray = [...(draggedDOM.parentNode.children as any)];
    childrenArray.splice(sourceIndex, 1);

    const tileGap = parseFloat(window.getComputedStyle(draggedDOM.parentNode as any).paddingTop);

    const { clientWidth, clientHeight: draggedHeight } = draggedDOM.firstElementChild;
    const dropRow = rows[Number(event.destination.droppableId)];
    let clientLeft = event.destination.index > 0 ? 0 : tileGap;
    let clientHeight;

    if (dropRow.items.length && droppableDom?.firstElementChild) {
      clientHeight = droppableDom.firstElementChild.clientHeight;
    } else {
      clientHeight = draggedHeight;
    }

    if (event.destination.index > 0) {
      // If we are dropping after an item, lets get all items before it and add their size
      let itemsOnLeftCount = 0;
      dropRow.items.forEach((item) => {
        if (event?.draggableId === item.id || itemsOnLeftCount >= (event?.destination?.index || 0)) return;

        if (item?.component?.props?.tile?.metadata?.size) {
          const spacing = getItemSpacing(item?.component?.props?.tile?.metadata?.size);
          clientLeft += mainContentWidth * (spacing / MAXIMUM_COLUMN_SPACING);
          itemsOnLeftCount += 1;
        }
      });
    }

    if (!isLargeContent) {
      clientLeft *= 2;
    }

    setPlaceholderProps({
      destination: event.destination,
      source: event.source,
      sourceItem: rows[Number(event.source.droppableId)].items[event.source.index],
      clientHeight,
      clientWidth,
      clientTop: event.destination.droppableId.includes('_gap') ? tileGap / 2 : tileGap,
      clientLeft,
    });
  };

  return (
    <DragDropContext onDragEnd={OnDragEnd} onDragUpdate={onDragUpdate}>
      <Stack width='100%'>
        {/* Top gap row */}
        {editMode && <DragAndDropRow editMode={editMode} index='-1' placeholderProps={placeholderProps} />}

        {rows.map((row, index) => (
          <div key={`${index.toString()}_row_group`}>
            <DragAndDropRow
              editMode={editMode}
              row={row}
              index={index.toString()}
              placeholderProps={placeholderProps}
            />
          </div>
        ))}

        {/* Bottom gap row */}
        {editMode && (
          <DragAndDropRow editMode={editMode} index={rows.length.toString()} placeholderProps={placeholderProps} />
        )}
      </Stack>
    </DragDropContext>
  );
};

export { DragAndDropContainer };

import { useCallback } from 'react';

import { Candle } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { BarData, IChartApi, ISeriesApi, Time, UTCTimestamp } from 'lightweight-charts';
import { DateTime } from 'luxon';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

import { isSimpleChartState } from '../../../utils/Tradingview.utils';

type Props = {
  chartState?: IChartApi | IChartingLibraryWidget;
  data?: Candle[];
};

const useCreateBarSeries = ({ chartState, data }: Props) => {
  const createBarSeries = useCallback(() => {
    if (!isSimpleChartState(chartState) || !data) return undefined;

    const barSeries = chartState.addBarSeries({
      upColor: getComputedStyle(document.body).getPropertyValue('--color-background-success'),
      downColor: getComputedStyle(document.body).getPropertyValue('--color-background-error'),
    });

    const seriesData: BarData<Time>[] = data
      .map((d) => ({
        time: (Date.parse(DateTime.fromMillis(d.time).toISO()) / 1000) as UTCTimestamp,
        open: d.open || 0,
        high: d.high || 0,
        low: d.low || 0,
        close: d.close || 0,
      }))
      .sort((a, b) => Big(a.time).cmp(b.time));

    try {
      barSeries.setData(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }

    return barSeries;
  }, [chartState, data]);

  const updateBarSeries = useCallback((series: ISeriesApi<'Bar'>, bar: Candle) => {
    if (series.seriesType() !== 'Bar') return;

    const seriesData: BarData<Time> = {
      time: (Date.parse(DateTime.fromMillis(bar.time).toISO()) / 1000) as UTCTimestamp,
      open: bar.open || 0,
      high: bar.high || 0,
      low: bar.low || 0,
      close: bar.close || 0,
    };

    try {
      series.update(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, []);

  return { createBarSeries, updateBarSeries };
};

export { useCreateBarSeries };

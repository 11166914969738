import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { DashboardContext } from '@routes/Dashboard';
import { DashboardBaseTile } from '@routes/Dashboard/components/DashboardBaseTile';
import { DashboardProps } from '@routes/Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { BrazeAnnouncementCarousel } from './components/BrazeAnnouncementCarousel';

const AnnouncementsTile: React.FC<DashboardProps> = ({ tile, dragProps }) => {
  const { t } = useTranslation('dashboard');
  const { editMode } = useContext(DashboardContext);
  const { isMediumScreen } = useScreenBreakpoints();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  if (!isFeatureEnabled(AppFeature.Braze)) {
    return null;
  }

  return (
    <DashboardBaseTile
      tileName={tile.name}
      dragProps={dragProps}
      noHeader
      title={t('announcementsTile.title')}
      divider={false}
      noBorder={!editMode}
      cardSx={{
        maxHeight: isMediumScreen ? '220px' : 'auto',
        background: editMode ? 'auto' : 'none',
        '.MuiCardContent-root': {
          overflowY: 'hidden',
        },
      }}
      resizable={false}
      noPadding
    >
      <BrazeAnnouncementCarousel />
    </DashboardBaseTile>
  );
};

export { AnnouncementsTile };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Key: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.63559 18.9472C3.00894 17.3205 3.00689 14.68 4.63428 13.0526C6.08162 11.6053 8.32833 11.4456 9.95235 12.57L14.0021 8.5202C14.0094 8.51226 14.0169 8.50444 14.0246 8.49674C14.0323 8.48905 14.0401 8.48156 14.0481 8.47427L17.7175 4.80485C18.0104 4.51196 18.4853 4.51196 18.7781 4.80485L21.1958 7.22246C21.4887 7.51536 21.4887 7.99023 21.1958 8.28312C20.9029 8.57602 20.428 8.57602 20.1351 8.28312L18.2478 6.39584L15.6161 9.02756L17.5029 10.9144C17.7958 11.2072 17.7958 11.6821 17.5029 11.975C17.21 12.2679 16.7351 12.2679 16.4422 11.975L14.5554 10.0882L11.013 13.6307C12.1372 15.2547 11.9774 17.5012 10.5302 18.9485C8.90278 20.5759 6.26223 20.5738 4.63559 18.9472ZM5.69494 14.1133C4.65409 15.1541 4.65464 16.8449 5.69625 17.8865C6.73785 18.9281 8.42865 18.9287 9.46951 17.8878C10.5119 16.8455 10.5109 15.1547 9.46951 14.1133C8.42809 13.0718 6.73729 13.0709 5.69494 14.1133Z'
      fill='currentColor'
    />
  </svg>
);

export { Key };

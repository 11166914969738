import { useCallback, useEffect, useState } from 'react';

import { getPriceScale } from '@swyftx/currency-util';

import { ProfitLossValueMeasurement, ProfitLossValueMethod } from '../../types/profitStopLoss.types';

const DEFAULT_VALUE_METHOD = ProfitLossValueMethod.AmountTarget;
const DEFAULT_VALUE_MEASUREMENT = ProfitLossValueMeasurement.Percentage;
const MAX_VALUE_LENGTH = 8;

type Props = {
  currentPrice?: string;
};

const useProfitStopLossValueCalculation = ({ currentPrice }: Props) => {
  const [profitLossValueMethod, setProfitLossValueMethod] = useState<ProfitLossValueMethod>(DEFAULT_VALUE_METHOD);
  const [profitLossValueMeasurement, setProfitLossValueMeasurement] =
    useState<ProfitLossValueMeasurement>(DEFAULT_VALUE_MEASUREMENT);
  const [profitLossValue, setProfitLossValue] = useState<string>('');

  const onChangeProfitLossValue = useCallback(
    (newValue: string) => {
      // We need to sanitise this value a bit before we store it.
      // If its a percentage or dollar amount, limit to 2 decimals
      // Otherwise make sure we limit the values to only 10 digits
      const priceScale = currentPrice ? getPriceScale(currentPrice) : MAX_VALUE_LENGTH;

      if (profitLossValueMethod === ProfitLossValueMethod.AmountTarget) {
        const values = newValue.split('.');
        if (values?.[1]?.length > 2) return;
        setProfitLossValue(newValue);
      } else {
        const values = newValue.split('.');
        if (values?.[0]?.length > MAX_VALUE_LENGTH && !newValue.includes('.')) return;
        if (values?.[1]?.length > priceScale) return;
        setProfitLossValue(newValue);
      }
    },
    [currentPrice, profitLossValueMethod],
  );

  // Reset the profit loss value if the trigger type changes
  useEffect(() => {
    setProfitLossValue('');
  }, [profitLossValueMethod, profitLossValueMeasurement]);

  useEffect(() => {
    if (profitLossValueMethod === ProfitLossValueMethod.TriggerPrice) {
      setProfitLossValueMeasurement(ProfitLossValueMeasurement.Percentage);
    }
  }, [profitLossValueMethod]);

  return {
    profitLossValueMethod,
    profitLossValueMeasurement,
    profitLossValue,
    setProfitLossValueMethod,
    setProfitLossValueMeasurement,
    setProfitLossValue: onChangeProfitLossValue,
  };
};

export { useProfitStopLossValueCalculation };

import React, { useContext } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { AssetType } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { useTradeValue } from 'src/lib/trade/hooks/General';

import { EditTriggerOrderContext } from './EditTriggerOrder.context';

export const EditOrderTriggerPrice: React.FC = observer(() => {
  const { triggerPrice, setTriggerPrice, primaryAsset, secondaryAsset } = useContext(EditTriggerOrderContext);

  const { formattedAmount, onChangeValue } = useTradeValue({
    amount: triggerPrice,
    limitAsset: secondaryAsset,
    onChange: (e) => setTriggerPrice(e ?? ''),
  });

  return (
    <FlexLayout direction='column' spacing={12}>
      <Heading size='h6'>Trigger price</Heading>
      <Input
        leading={primaryAsset.assetType === AssetType.Fiat ? <Body>$</Body> : undefined}
        value={formattedAmount}
        variant='monospace'
        className='!text-16'
        onChange={(e) => onChangeValue(e.target.value)}
        trailing={
          <Body color='secondary'>
            {primaryAsset.code}/{secondaryAsset.code}
          </Body>
        }
      />
    </FlexLayout>
  );
});

import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import StepModal from '@swyftx/aviary/molecules/StepModal';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';

import { api, AssetType } from '@shared/api';
import { WithdrawalAddress } from '@shared/services';

import { useFetchAssetAddressesCache } from 'src/lib/assets/hooks/useFetchAssetAddressesCache';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

interface Props {
  address: WithdrawalAddress;
}

export const RemoveAddressStep: React.FC<Props> = ({ address }) => {
  const { t } = useTranslation('wallet');
  const { invalidateCache } = useFetchAssetAddressesCache();
  const { getAssetById } = useMarkets();
  const { onBack } = useContext(StepModalContext);

  const asset = useMemo(() => getAssetById(address.code), [address.code, getAssetById]);

  const onDeleteAddress = useCallback(() => {
    (async () => {
      await api.endpoints.removeWithdrawAddress({ params: { id: address.id, code: address.code.toString() } });
      invalidateCache();
      onBack(2);
    })();
  }, [address.code, address.id, invalidateCache, onBack]);

  return (
    <StepModal.Step hideActions title={`Remove ${address.label}?`}>
      <FlexLayout direction='column' spacing={24} className='p-24'>
        <Body>
          {asset?.assetType === AssetType.Fiat
            ? t('manageAccounts.removeConfirmation.fiatTitle')
            : t('manageAccounts.removeConfirmation.cryptoTitle')}
        </Body>
        <FlexLayout direction='row' justifyContent='end' spacing={8}>
          <Button variant='ghost' onClick={() => onBack()} size='lg'>
            {t('manageAccounts.removeConfirmation.cancel')}
          </Button>
          <Button variant='filled' color='destructive' onClick={onDeleteAddress} size='lg'>
            {t('manageAccounts.removeConfirmation.remove')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </StepModal.Step>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Learn: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M10.05 8.36C10.05 8.77 9.71 9.11 9.3 9.11H5.7C5.29 9.11 4.95 8.77 4.95 8.36C4.95 7.95 5.29 7.61 5.7 7.61H9.3C9.71 7.61 10.05 7.95 10.05 8.36ZM18.3 7.61H14.7C14.29 7.61 13.95 7.95 13.95 8.36C13.95 8.77 14.29 9.11 14.7 9.11H18.3C18.71 9.11 19.05 8.77 19.05 8.36C19.05 7.95 18.71 7.61 18.3 7.61ZM9.3 10.52H5.7C5.29 10.52 4.95 10.86 4.95 11.27C4.95 11.68 5.29 12.02 5.7 12.02H9.3C9.71 12.02 10.05 11.68 10.05 11.27C10.05 10.86 9.71 10.52 9.3 10.52ZM18.3 10.52H14.7C14.29 10.52 13.95 10.86 13.95 11.27C13.95 11.68 14.29 12.02 14.7 12.02H18.3C18.71 12.02 19.05 11.68 19.05 11.27C19.05 10.86 18.71 10.52 18.3 10.52ZM9.3 13.43H5.7C5.29 13.43 4.95 13.77 4.95 14.18C4.95 14.59 5.29 14.93 5.7 14.93H9.3C9.71 14.93 10.05 14.59 10.05 14.18C10.05 13.77 9.71 13.43 9.3 13.43ZM18.3 13.43H14.7C14.29 13.43 13.95 13.77 13.95 14.18C13.95 14.59 14.29 14.93 14.7 14.93H18.3C18.71 14.93 19.05 14.59 19.05 14.18C19.05 13.77 18.71 13.43 18.3 13.43ZM21.75 6V16.55C21.75 18.07 20.52 19.3 19 19.3H14.7C14.23 19.3 13.7 19.65 13.25 20L12.46 20.6C12.46 20.6 12.43 20.62 12.41 20.63C12.39 20.65 12.36 20.67 12.34 20.68C12.33 20.68 12.31 20.68 12.3 20.68C12.21 20.72 12.11 20.75 12 20.75C11.89 20.75 11.8 20.72 11.7 20.68C11.69 20.68 11.67 20.68 11.66 20.68C11.63 20.67 11.61 20.65 11.59 20.63C11.57 20.62 11.56 20.62 11.54 20.6L10.6 19.9C10.16 19.61 9.7 19.3 9.29 19.3H5C3.48 19.3 2.25 18.07 2.25 16.55V6C2.25 4.48 3.48 3.25 5 3.25H8.4C9.56 3.25 10.68 3.75 11.49 4.63C11.68 4.83 11.85 5.06 11.99 5.29C12.14 5.06 12.3 4.83 12.5 4.63C13.31 3.76 14.44 3.25 15.59 3.25H18.99C20.51 3.25 21.74 4.48 21.74 6H21.75ZM11.25 18.52V7.88C11.25 7.05 10.94 6.23 10.4 5.65C9.86 5.07 9.16 4.75 8.4 4.75H5C4.31 4.75 3.75 5.31 3.75 6V16.55C3.75 17.24 4.31 17.8 5 17.8H9.3C10.07 17.8 10.72 18.18 11.25 18.53V18.52ZM20.25 6C20.25 5.31 19.69 4.75 19 4.75H15.6C14.85 4.75 14.14 5.07 13.6 5.65C13.06 6.23 12.75 7.05 12.75 7.88V18.5C13.31 18.12 13.97 17.79 14.7 17.79H19C19.69 17.79 20.25 17.23 20.25 16.54V6Z'
      fill='currentColor'
    />
  </svg>
);

export { Learn };

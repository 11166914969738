import { useContext, useMemo } from 'react';

import { RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { DateTime } from 'luxon';
import { AutoInvestContext } from 'src/lib/auto-invest/context';
import { useAutoInvestMinimumOrder } from 'src/lib/auto-invest/hooks';

import { useAutoInvestBalanceCheck } from './useAutoInvestBalanceCheck';

const useAutoInvestPreferences = () => {
  const {
    startDate,
    setStartDate,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    amount,
    setAmount,
    frequency,
    setFrequency,
    orderName,
    setOrderName,
    selectedAutoInvestAssets,
    onCancel,
    setCurrentStep,
  } = useContext(AutoInvestContext);

  const { minimumOrderAmount } = useAutoInvestMinimumOrder(selectedAutoInvestAssets);
  const countryAsset = useCountryAsset();
  const { showBalanceWarning, maxOrderAmount, diffDays } = useAutoInvestBalanceCheck({
    startDate,
    amount,
    selectedPaymentMethod,
    frequency,
  });

  const validPreferences = useMemo(() => {
    if (selectedPaymentMethod === RecurringOrderSource.DEPOSIT) {
      if (!orderName) return false;
    } else if (selectedPaymentMethod === RecurringOrderSource.ACCOUNT) {
      if (!orderName || !startDate || !frequency || !countryAsset) return false;
      if (!amount || Big(amount).lt(minimumOrderAmount)) return false;
      if (startDate && startDate.isValid === false) return false;
      if (startDate && startDate < DateTime.now().startOf('day')) return false;
      if (startDate && startDate > DateTime.now().startOf('day').plus({ months: 2 })) return false;
    }

    return true;
  }, [amount, countryAsset, frequency, minimumOrderAmount, orderName, selectedPaymentMethod, startDate]);

  return {
    validPreferences,
    startDate,
    setStartDate,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    amount,
    setAmount,
    frequency,
    setFrequency,
    orderName,
    minimumOrderAmount,
    maxOrderAmount,
    showBalanceWarning,
    diffDays,
    setOrderName,
    onCancel,
    setCurrentStep,
  };
};

export { useAutoInvestPreferences };

import { useMemo } from 'react';

import { PlatformFeature } from '../types/FeatureDiscovery.types';

const useFeatureDiscoveryFeatures = () => {
  const features: PlatformFeature[] = useMemo(() => [], []);

  return features;
};

export { useFeatureDiscoveryFeatures };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { AtLeastBreakpoints } from '@swyftx/aviary/hooks/breakpoints';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { cn } from '@shared/utils/lib/ui';

import { HeaderIcon, HeaderMetrics } from './PageHeader.types';
import { PageHeaderIcon } from './PageHeaderIcon';

type CompactProps = {
  title?: string;
  icon?: HeaderIcon;
  className?: string;
  show: boolean;
  actions?: React.ReactNode[];
  metrics?: HeaderMetrics[];
  isDarkMode: boolean;
  atLeast: AtLeastBreakpoints;
};

const CompactPageHeader: React.FC<CompactProps> = ({
  title,
  icon,
  className,
  show,
  actions,
  metrics,
  isDarkMode,
  atLeast,
}) => {
  const titleSize = useMemo(() => {
    if (atLeast.xl) return 'h4';
    if (atLeast.lg) return 'h5';
    return 'h6';
  }, [atLeast]);

  const visibleMetrics = useMemo(() => {
    if (atLeast.xl) return metrics;
    if (atLeast.lg) return metrics?.filter((m) => m.priority <= 4).slice(0, 4);
    if (atLeast.md) return metrics?.filter((m) => m.priority <= 3).slice(0, 3);
    if (atLeast.sm) return metrics?.filter((m) => m.priority <= 2).slice(0, 2);
    return undefined;
  }, [atLeast.lg, atLeast.md, atLeast.sm, atLeast.xl, metrics]);

  const iconSize = useMemo(() => {
    if (atLeast.sm) return 48;
    return 32;
  }, [atLeast.sm]);

  return (
    <div
      className={cn(
        `opacity-1 transition-[transform,  opacity] outline-b sticky top-0 z-10 -mb-95 bg-color-background-pageBG outline outline-1 outline-color-border-main duration-200 
        ${show ? 'opacity-1 translate-y-[0px]' : 'absolute translate-y-[-250px] opacity-0'}`,
        className,
      )}
    >
      <FlexLayout alignItems='center' justifyContent='space-between' className='h-full py-24 pl-0' spacing={24}>
        <FlexLayout alignItems='center' justifyContent='start' spacing={atLeast.sm ? 24 : 16}>
          {icon && <PageHeaderIcon icon={icon} isDarkMode={isDarkMode} atLeast={atLeast} size={iconSize} />}
          <Heading size={titleSize} color='primary' className='whitespace-nowrap'>
            {title}
          </Heading>
        </FlexLayout>
        <FlexLayout alignItems='center' spacing={24}>
          {visibleMetrics && (
            <FlexLayout alignItems='center' justifyContent='start' spacing={24}>
              {visibleMetrics.map((metric) => (
                <NumericDataItem
                  className='w-[120px]'
                  key={metric.key}
                  title={metric.title}
                  size='medium'
                  data={metric.data}
                  percentage={metric.percentage}
                  color={metric.color}
                />
              ))}
            </FlexLayout>
          )}
          <FlexLayout direction='row' alignItems='center' justifyContent='space-between' spacing={12}>
            {actions}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </div>
  );
};

export { CompactPageHeader };

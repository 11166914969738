import { useCallback, useEffect, useState } from 'react';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, PairExchangeRateResponseError } from '@shared/api';
import { LowLiquidityMax } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { useDebounce } from 'react-use';

import { useProcessMinimumTradeQuoteError } from './useProcessMinimumTradeQuoteError';
import { useTradeQuoteErrorValue } from './useTradeQuoteErrorValue';
import { SwyftxTradeDirection, SwyftxTradeType } from '../../../types/Trade.types';
import { usePriceScale } from '../usePriceScale';

type Props = {
  quoteError?: PairExchangeRateResponseError;
  buyAsset?: Asset;
  sellAsset?: Asset;
  limitAsset?: Asset;
  tradeDirection: SwyftxTradeDirection;
  tradeType?: SwyftxTradeType;
  total: string;
  setTotal: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  errorMessage?: string;
  balance?: string;
  triggerPrice?: string;
};

const useTradeQuoteError = ({
  quoteError,
  buyAsset,
  sellAsset,
  limitAsset,
  tradeDirection,
  tradeType,
  total,
  amount,
  errorMessage,
  balance,
  triggerPrice,
  setAmount,
  setTotal,
}: Props) => {
  const [error, setError] = useState<JSX.Element | null>(null);
  const { getRate, convertRate } = RatesStore.useRatesStore;
  const { activeValue, updateActiveValue } = useTradeQuoteErrorValue({
    sellAsset,
    limitAsset,
    tradeDirection,
    total,
    amount,
    setTotal,
    setAmount,
  });

  const { processBelowMinimum } = useProcessMinimumTradeQuoteError({
    triggerPrice,
    activeValue,
    updateActiveValue,
    limitAsset,
    tradeDirection,
    tradeType,
  });

  const countryAsset = useCountryAsset();
  const { openModal } = useModal();
  const { calculatePriceScale } = usePriceScale();

  const openDepositModal = useCallback(() => {
    openModal(Modals.DepositReceive, { selectedAsset: sellAsset });
  }, [openModal, sellAsset]);

  const processGenericErrorMessage = useCallback(() => {
    if (!errorMessage) return undefined;

    return <>{errorMessage}</>;
  }, [errorMessage]);

  const processMaximumAmountError = useCallback(() => {
    if (!amount || !quoteError) return undefined;

    if (quoteError.maximumPercent && !Big(quoteError.maximumPercent).eq(0)) {
      const maxAmount = Big(activeValue).times(Big(1).minus(quoteError.maximumPercent));
      const formattedMax = formatCurrency(
        maxAmount,
        limitAsset,
        {
          appendCode: false,
          hideCode: true,
          hideSymbol: true,
          hideSeparator: true,
        },
        undefined,
        true,
      );

      return (
        <>
          Amount exceeds maximum{' '}
          <span className='cursor-pointer underline' onClick={() => updateActiveValue(formattedMax)}>
            {formatCurrency(maxAmount, limitAsset, undefined, undefined, true)}
          </span>{' '}
          order limit.
        </>
      );
    }

    return undefined;
  }, [activeValue, amount, limitAsset, quoteError, updateActiveValue]);

  const processLowLiquidity = useCallback(() => {
    if (!buyAsset || !sellAsset || !countryAsset) return '';

    const { buyLiquidityFlag } = getRate(buyAsset);
    const { sellLiquidityFlag } = getRate(sellAsset);

    if (tradeDirection === 'buy' && buyLiquidityFlag) {
      const maxLowLiqudityBuy = convertRate(countryAsset, sellAsset, LowLiquidityMax.AUD, 'askPrice');

      if (Big(total).gt(maxLowLiqudityBuy)) {
        const maxLiquidity = formatCurrency(
          maxLowLiqudityBuy,
          sellAsset,
          {
            hideCode: true,
            appendCode: false,
            hideSeparator: true,
            hideSymbol: true,
          },
          undefined,
          true,
        );
        return (
          <>
            Asset impacted by low liquidity. Enter amount less than{' '}
            <span className='cursor-pointer underline' onClick={() => updateActiveValue(maxLiquidity)}>
              {formatCurrency(maxLowLiqudityBuy, sellAsset, undefined, undefined, true)}
            </span>
          </>
        );
      }
    } else if (tradeDirection === 'sell' && sellLiquidityFlag) {
      const maxLowLiquditySell = convertRate(countryAsset, sellAsset, LowLiquidityMax.AUD, 'bidPrice');
      const maxLiquidity = formatCurrency(
        maxLowLiquditySell,
        sellAsset,
        {
          hideCode: true,
          priceScale: calculatePriceScale(sellAsset),
          appendCode: false,
          hideSeparator: true,
          hideSymbol: true,
        },
        undefined,
        true,
      );

      if (Big(amount).gt(maxLowLiquditySell)) {
        return (
          <>
            Asset impacted by low liquidity. Enter amount less than{' '}
            <span className='cursor-pointer underline' onClick={() => updateActiveValue(maxLiquidity)}>
              {formatCurrency(
                maxLowLiquditySell,
                sellAsset,
                {
                  priceScale: calculatePriceScale(sellAsset),
                },
                undefined,
                true,
              )}
            </span>
          </>
        );
      }
    }
  }, [
    buyAsset,
    sellAsset,
    countryAsset,
    getRate,
    tradeDirection,
    convertRate,
    total,
    updateActiveValue,
    calculatePriceScale,
    amount,
  ]);

  const processInsufficientFunds = useCallback(() => {
    if (!sellAsset || tradeType !== 'market') return undefined;

    if (!balance) {
      return (
        <>
          Insufficient funds. Reduce order amount or{' '}
          <span className='cursor-pointer underline' onClick={openDepositModal}>
            deposit {sellAsset.code}.
          </span>
        </>
      );
    }

    if (tradeDirection === 'buy' && Big(total).gt(balance)) {
      return (
        <>
          Insufficient funds. Reduce order amount or{' '}
          <span className='cursor-pointer underline' onClick={openDepositModal}>
            deposit {sellAsset.code}.
          </span>
        </>
      );
    } else if (tradeDirection === 'sell' && Big(amount).gt(balance)) {
      const maxBalance = formatCurrency(
        balance,
        sellAsset,
        {
          hideCode: true,
          appendCode: false,
          hideSeparator: true,
          hideSymbol: true,
        },
        undefined,
        true,
      );

      return (
        <>
          Insufficient funds. Reduce order amount to{' '}
          <span className='cursor-pointer underline' onClick={() => setAmount(maxBalance)}>
            {formatCurrency(balance, sellAsset, undefined, undefined, true)}
          </span>
        </>
      );
    }
  }, [balance, sellAsset, tradeType, tradeDirection, total, amount, openDepositModal, setAmount]);

  useEffect(() => {
    setError(null);
  }, [sellAsset, buyAsset]);

  useDebounce(
    () => {
      if (!quoteError && !activeValue) {
        setError(null);
        return;
      }

      setError(
        processBelowMinimum() ||
          processLowLiquidity() ||
          processMaximumAmountError() ||
          processGenericErrorMessage() ||
          processInsufficientFunds() ||
          null,
      );
    },
    200,
    [
      processBelowMinimum,
      processGenericErrorMessage,
      processInsufficientFunds,
      processLowLiquidity,
      processMaximumAmountError,
      quoteError,
      activeValue,
    ],
  );

  return {
    error,
    hasError: error !== null,
  };
};

export { useTradeQuoteError };

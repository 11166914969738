import { TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { AssetFilter } from '@hooks/Assets/useAssets';

export enum OrderTabEnum {
  OpenOrders = 0,
  History = 1,
}

export type OrderTab = {
  id: string;
  label: string;
  value: OrderTabEnum;
};

export type UniversalTradeHistoryState = {
  action: 'buying' | 'selling' | 'swap';
};

export enum TradeCurrencyFilterEnum {
  All,
  Owned,
  Favourites,
  Bundles,
}

export type TradeCurrencyFilter = {
  label: string;
  value: TradeCurrencyFilterEnum;
  leading?: React.ReactNode;
};

export enum PortfolioTabsEnum {
  Overview = 'Wallet overview',
  Trading = 'Trading',
  Earn = 'Earn',
}

export enum TradeActionEnum {
  Buy = 'labels.buy',
  Sell = 'labels.sell',
  Swap = 'labels.swap',
}

export enum SummaryType {
  GAINER = 'gainer',
  LOSER = 'loser',
  TRADED = 'traded',
}

export type AssetSelectorFilter = {
  label: string;
  value: AssetFilter;
};

export type TradeTypesType = {
  label: string;
  value: TradeType;
};

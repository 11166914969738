import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { observer } from 'mobx-react-lite';
import { useExecuteTrade } from 'src/lib/trade/hooks/General';

import { SwyftxProOrderConfirmationModalHeader } from './SwyftxProOrderConfirmationModalHeader';
import { SwyftxProOrderConfirmationModalOcoDetails } from './SwyftxProOrderConfirmationModalOcoDetails';
import { SwyftxProOrderConfirmationModalOrderDetails } from './SwyftxProOrderConfirmationModalOrderDetails';
import { SwyftxProTradeContext } from '../../context';
import { useBuildSwyftxProOcoOrder } from '../../hooks/useBuildSwyftxProOcoOrder/useBuildSwyftxProOcoOrder';
import { useBuildSwyftxProOrder } from '../../hooks/useBuildSwyftxProOrder';
import { useProcessMarketOcoOrder } from '../../hooks/useProcessMarketOcoOrder';
import { useSwyftxProTradeAssets } from '../../hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from '../../hooks/useSwyftxProTradeDirection';
import { useSwyftxProTradeType } from '../../hooks/useSwyftxProTradeType';

const SwyftxProOrderConfirmationModal: React.FC<PropsWithChildren<unknown>> = observer(({ children }) => {
  const [open, setOpen] = useState<boolean>();
  const { resetValues, stopLossPrice, takeProfitPrice, amount, tradeType } = useContext(SwyftxProTradeContext);
  const { selectedAsset, sellAsset, buyAsset } = useSwyftxProTradeAssets();
  const { isBuy } = useSwyftxProTradeDirection();
  const { isOco } = useSwyftxProTradeType();
  const { order } = useBuildSwyftxProOrder();
  const { buildOcoOrder, executeOcoOrder, isExecutingOco } = useBuildSwyftxProOcoOrder();
  const { processMarketOcoOrder } = useProcessMarketOcoOrder();
  const { addToast } = useContext(ToastManagerContext);

  const placeOcoOrder = useCallback(() => {
    if (!buyAsset || !sellAsset || !amount || !stopLossPrice || !takeProfitPrice) return;

    const ocoOrder = buildOcoOrder({
      primary: buyAsset.code,
      secondary: sellAsset.code,
      amount,
      stopLossPrice,
      takeProfitPrice,
    });

    executeOcoOrder(ocoOrder);
    setOpen(false);
    resetValues();
  }, [amount, buildOcoOrder, buyAsset, executeOcoOrder, resetValues, sellAsset, stopLossPrice, takeProfitPrice]);

  const { executeTrade, isExecuting } = useExecuteTrade({
    onExecuteSuccess: (resp) => {
      setOpen(false);
      resetValues();
      addToast({
        title: 'Your order has successfully been placed',
        type: 'success',
        isDismissable: true,
      });

      if (buyAsset && sellAsset && tradeType === 'market') {
        processMarketOcoOrder(resp.order, resp.orderUuid, sellAsset.code, buyAsset.code);
      }
    },
    onExecuteFailure: (error: string) => {
      setOpen(false);
      addToast({
        title: error,
        type: 'destructive',
        isDismissable: true,
      });
    },
  });

  const validTrade = useMemo(() => {
    if (!buyAsset || !sellAsset) return false;

    if (isOco) {
      return !!stopLossPrice && !!takeProfitPrice && !!amount;
    } else {
      return order !== undefined;
    }
  }, [amount, buyAsset, isOco, order, sellAsset, stopLossPrice, takeProfitPrice]);

  const onExecuteOrder = useCallback(() => {
    if (isOco) {
      placeOcoOrder();
    } else {
      if (!order) return;

      executeTrade(order);
    }
  }, [executeTrade, isOco, order, placeOcoOrder]);

  if (!selectedAsset || !buyAsset || !sellAsset) return null;

  return (
    <Modal
      open={open}
      triggerElement={children}
      title='Confirm order'
      showCloseButton
      className='!min-w-[29rem] !max-w-[29rem]'
      onOpenChange={setOpen}
    >
      <FlexLayout direction='column' spacing={24} className='px-24 pb-24'>
        {!isOco ? (
          <>
            <SwyftxProOrderConfirmationModalHeader />
            <SwyftxProOrderConfirmationModalOrderDetails />
            {(stopLossPrice || takeProfitPrice) && (
              <>
                <Separator className='bg-color-border-main' />
                <SwyftxProOrderConfirmationModalOcoDetails />
              </>
            )}
          </>
        ) : (
          <SwyftxProOrderConfirmationModalOcoDetails />
        )}

        <FlexLayout className='w-full' alignItems='center' justifyContent='space-between' spacing={12}>
          <Button variant='outlined' size='lg' className='w-full' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant='filled'
            size='lg'
            loading={isExecuting || isExecutingOco}
            className='w-full'
            color={isBuy ? 'success' : 'destructive'}
            disabled={!validTrade}
            onClick={onExecuteOrder}
          >
            {isBuy ? 'Buy' : 'Sell'} {selectedAsset?.code}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { SwyftxProOrderConfirmationModal };

import { api } from '@shared/api';
import { UserStore } from '@shared/store';

export const optInOtc = async (optIn: boolean, entityUuid?: string) => {
  const { setOtcOptIn } = UserStore.useUserStore;
  if (entityUuid) {
    await api.endpoints.otcOptInForEntity({ data: { optIn, entityUuid } });
  } else {
    await api.endpoints.otcOptIn({ data: { optIn } });
  }
  setOtcOptIn(optIn);
};

export default {
  optInOtc,
};

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { isAddressDeprecated } from '@global-components/Modals/ReceiveCrypto/ReceiveCrypto.data';

import { Asset, Network } from '@shared/api';
import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

type Props = {
  asset?: Asset;
  network?: Network;
  type?: 'send' | 'receive';
};

export const SendReceiveCryptoWarning: React.FC<Props> = ({ asset, network, type = 'receive' }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const [viewedNotification, setViewedNotification] = useLocalStorage(
    `${StorageKey.VIEWED_INLINE_NETWORK_CHANGE_NOTIFICATION}_${network?.networkName}_v1`,
    false,
  );

  const showWarning = useMemo(
    () =>
      isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarning) ||
      isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart2) ||
      isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart3) ||
      isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart4) ||
      isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart5),
    [isFeatureEnabled],
  );

  const memoTitle = useMemo(() => {
    if (network?.destinationTag) return ' and destination tag';
    if (network?.memo) return ' and memo';
    return '';
  }, [network]);

  const listItems = useMemo(() => {
    if (type === 'receive') {
      return [
        'Deposits sent to the wrong address are NOT recoverable.',
        'Deposits sent to an old address are NOT recoverable.',
        'Deposits sent on the wrong network are NOT recoverable.',
        'Deposits without a required memo or destination tag are NOT recoverable.',
        'Always send a small test deposit when making a deposit.',
        'Deposits for assets that Swyftx does not list or support are NOT recoverable.',
      ];
    } else {
      return [
        'Crypto withdrawals that are sent to the wrong address are NOT recoverable.',
        'ALWAYS send a small test transaction when making a first-time crypto withdrawal from Swyftx.',
      ];
    }
  }, [type]);

  if (asset && network && isAddressDeprecated(network) && showWarning && !viewedNotification) {
    return (
      <Notification severity='warning' title='Deposit address for this network has changed'>
        <FlexLayout direction='column' spacing={16}>
          <Body size='small'>
            The deposit address has changed on your account for all assets using this network. Please ensure that you
            are using the updated address{memoTitle} when making future deposits to your Swyftx account. Transfers to
            incorrect addresses may not be recoverable.
          </Body>
          <FlexLayout direction='row'>
            <Button variant='ghost' size='sm' onClick={() => setViewedNotification(true)}>
              I understand
            </Button>
          </FlexLayout>
        </FlexLayout>
      </Notification>
    );
  }

  return (
    <Notification
      severity='warning'
      title={`Warning: Always check your ${type === 'send' ? 'withdraw' : 'deposit'} details`}
    >
      <ul className='ml-16 list-disc'>
        {listItems.map((item) => (
          <li key={item} className='font-sans text-12'>
            {item}
          </li>
        ))}
      </ul>
    </Notification>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TickFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.5607 4.93934C23.1464 5.52513 23.1464 6.47487 22.5607 7.06066L10.2291 19.3923C9.64336 19.978 8.69379 19.9781 8.10796 19.3925L1.93956 13.2267C1.35365 12.641 1.35345 11.6913 1.93912 11.1054C2.52478 10.5195 3.47453 10.5193 4.06044 11.1049L9.16818 16.2105L20.4393 4.93934C21.0251 4.35355 21.9749 4.35355 22.5607 4.93934Z'
      fill='currentColor'
    />
  </svg>
);

export { TickFilled };

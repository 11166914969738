import React, { useMemo, useState } from 'react';

import { LineChartOverlay, TimeOverlayMap } from '@swyftx/aviary/atoms/Charts/LineChart/LineChart.types';
import { PortfolioPerformanceCard } from '@swyftx/aviary/organisms/Portfolio';
import {
  PortfolioPerformanceTab,
  PortfolioPeriodType,
  PortfolioViewDensity,
  periodToDays,
} from '@swyftx/aviary/organisms/Portfolio/PortfolioPerformanceCard/PortfolioPerformanceCard.types';

import {
  usePortfolioAssetLineOverlayData,
  usePortfolioLineChartData,
} from '@global-components/charts/LineChart/hooks/portfolioLineDataHooks';

import { assetService } from '@shared/services';
import { AppStore, RatesStore } from '@shared/store';
import { formatCurrency, formatDate, getValuePriceScale } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useOnEntityChanged } from '@hooks/Entity/useOnEntityChange';
import { usePortfolio } from '@hooks/usePortfolio';
import { generateKey } from '@utils/id';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';

import { PortfolioPerformanceWidgetMenu } from './PortfolioPerformanceWidgetMenu/PortfolioPerformanceWidgetMenu';
import { PortfolioChartDemoMode } from '../../components/PortfolioChartDemoMode/PortfolioChartDemoMode';

type Props = {
  className?: string;
  tabs?: PortfolioPerformanceTab[];
  showAxes?: boolean;
  variant?: PortfolioViewDensity;
  actions?: React.ReactNode;
};

const endDate = new Date();
const widgetId: DashboardWidgets = 'asset-performance';

const isOverlayDefined = (overlay: LineChartOverlay | undefined): overlay is LineChartOverlay =>
  (overlay as LineChartOverlay) !== undefined;

const PortfolioPerformanceWidget: React.FC<Props> = observer(
  ({ className, tabs, showAxes = true, variant = 'detailed' }) => {
    const baseAsset = useBaseAsset();
    const { convertRate, loading: loadingRates } = RatesStore.useRatesStore;
    const { isDemo } = AppStore.useAppStore;
    const [period, setPeriod] = useDashboardSyncState<PortfolioPeriodType>({
      widgetId,
      stateKey: 'period',
      defaultValue: '30d',
    });

    const [overlays, setOverlays] = useDashboardSyncState<string[]>({
      widgetId,
      stateKey: 'overlays',
      defaultValue: ['', '', ''],
    });

    const [reloadTrigger, setReloadTrigger] = useState(generateKey());

    useOnEntityChanged(() => setReloadTrigger(generateKey()));

    const {
      portfolio: { accountValue, profit, percentChange, balances },
      withdrawn,
      deposited,
      traded,
    } = usePortfolio({ balanceKey: 'all' });

    const startDate = useMemo(() => DateTime.now().minus({ days: periodToDays[period] }).toJSDate(), [period]);

    const { formattedData, loading } = usePortfolioLineChartData(startDate, endDate, undefined, reloadTrigger);

    const assetOverlays = useMemo(() => assetService.getAssetsByCode(overlays), [overlays]);

    const { filteredOverlayDataMap } = usePortfolioAssetLineOverlayData(
      assetOverlays,
      startDate,
      endDate,
      reloadTrigger,
    );

    const overlayData: Array<LineChartOverlay> = useMemo(
      () =>
        Object.entries<TimeOverlayMap>(filteredOverlayDataMap)
          .map(([assetId, data]) => {
            const asset = assetService.getAsset(parseInt(assetId, 10));
            if (asset && formattedData) {
              const arrayData = Object.values(data);
              const misMatchedData = formattedData.length > arrayData.length;

              const formatted: LineChartOverlay = {
                color: asset.color,
                data: misMatchedData
                  ? formattedData.map(({ time: overviewTime }) => {
                      const overviewTimeKey = formatDate(overviewTime);
                      const overlayDatum = data?.[overviewTimeKey];
                      return (
                        overlayDatum || {
                          time: overviewTime,
                          value: 0,
                        }
                      );
                    })
                  : Object.values(data),
                variant: 'dashed',
                id: asset.id,
              };

              if (baseAsset && balances[asset.id] !== undefined) {
                formatted.data.splice(0, 0, {
                  time: new Date(),
                  value: convertRate(asset, baseAsset, balances[asset.id].availableBalance, 'midPrice').toNumber(),
                });

                if (formatted.data.length === 1) {
                  formatted.data.splice(0, 0, {
                    time: DateTime.now().minus({ day: 1 }).toJSDate(),
                    value: accountValue.toNumber(),
                  });
                }
              }

              return formatted;
            }
            return undefined;
          })
          .filter(isOverlayDefined),
      [filteredOverlayDataMap, formattedData, baseAsset, convertRate, balances, accountValue],
    );

    const chartData = useMemo(() => {
      if (isDemo || loading) return undefined;

      const data = Array.from(formattedData || []);

      if (accountValue) {
        data.splice(0, 0, { time: new Date(), value: accountValue.toNumber() });

        if (data.length === 1) {
          data.splice(0, 0, {
            time: DateTime.now().minus({ day: 1 }).toJSDate(),
            value: accountValue.toNumber(),
          });
        }
      }

      return data;
    }, [isDemo, loading, formattedData, accountValue]);

    const emptyState = useMemo(() => {
      if (!isDemo) return null;
      return <PortfolioChartDemoMode />;
    }, [isDemo]);

    const title = useMemo(() => {
      if (!baseAsset) return 'Portfolio value';
      if (baseAsset.code === 'BTC') return 'Portfolio value (BTC)';
      return `Portfolio value ($${baseAsset.code})`;
    }, [baseAsset]);

    const actions = useMemo(() => {
      if (isDemo) return undefined;
      if (!formattedData?.length) return undefined;
      if (Object.values(balances).length <= 1) return undefined;
      return <PortfolioPerformanceWidgetMenu overlays={overlays} setOverlays={setOverlays} />;
    }, [isDemo, formattedData?.length, balances, overlays, setOverlays]);

    return (
      <PortfolioPerformanceCard
        id='portfolio-performance'
        className={className}
        title={title}
        portfolioGrowthValue={profit.toString()}
        depositedValue={
          isDemo ? '' : formatCurrency(deposited, baseAsset, { priceScale: getValuePriceScale(baseAsset) })
        }
        totalTradedValue={
          isDemo ? '' : formatCurrency(traded, baseAsset, { priceScale: getValuePriceScale(baseAsset) })
        }
        withdrawnValue={
          isDemo ? '' : formatCurrency(withdrawn, baseAsset, { priceScale: getValuePriceScale(baseAsset) })
        }
        portfolioGrowthPercent={`${percentChange.toFixed(2)}%`}
        chartData={chartData}
        emptyState={emptyState}
        overlays={overlayData}
        tabs={tabs}
        periodValue={period}
        loading={loading || loadingRates}
        showAxes={showAxes}
        onChangePeriod={setPeriod}
        variant={variant}
        currency={baseAsset}
        actions={actions}
      />
    );
  },
);

export { PortfolioPerformanceWidget };

import { useCallback } from 'react';

import { api, Asset, OrderbookResponse } from '@shared/api';

import { useQuery } from '@tanstack/react-query';

import mockData from './orderbooks.mock.json';

type Props = {
  mock?: boolean;
  baseAsset: Asset;
  secondaryAsset: Asset;
};

const useOrderbooks = ({ baseAsset, secondaryAsset, mock = false }: Props) => {
  const fetchOrderbook = useCallback(async (): Promise<OrderbookResponse | undefined> => {
    try {
      if (mock) {
        return mockData;
      }

      const resp = await api.endpoints.getLiveOrderbooks({
        params: { baseId: baseAsset.id, secondaryId: secondaryAsset.id },
        query: { pro: true },
      });

      return resp.data.orderBooks;
    } catch (e) {
      return undefined;
    }
  }, [baseAsset.id, mock, secondaryAsset.id]);

  const { status, data, error, isFetching, isStale } = useQuery<OrderbookResponse | undefined, unknown>({
    queryKey: ['orderbooks', baseAsset.code, secondaryAsset.code],
    queryFn: fetchOrderbook,
    refetchInterval: 2_000,
  });

  return { status, data, error, isFetching, isStale };
};

export { useOrderbooks };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';

type Props = { asset: Asset };

const AssetName: React.FC<Props> = observer((props) => {
  const { asset } = props;
  const { name, code } = asset || {};

  return (
    <FlexLayout spacing={8} alignItems='center' justifyContent='start'>
      <AssetIcon asset={asset} size={20} />
      <FlexLayout direction='row' className='whitespace-nowrap' spacing={4}>
        <Body size='small' title={name} color='primary' className='hidden @lg:block'>
          {name}
        </Body>
        <Body
          size='small'
          overflow='truncate'
          title={name}
          className='text-color-text-primary @sm:text-color-text-secondary'
        >
          {code}
        </Body>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AssetName };

import React, { useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ProfileInformationCard } from '@swyftx/aviary/molecules/ProfileInformationCard';
import { ProfileMenuButtons } from '@swyftx/aviary/molecules/ProfileMenuButtons/ProfileMenuButtons';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useProfileWrapper } from '../../useProfileWrapper.hooks';

type Props = {
  selectedTabId: string;
};

const ProfileTabView: React.FC<Props> = observer(({ selectedTabId }) => {
  const { userProfile, entityMembers, getName, getMaskedPhoneNumber, isUserVerified } = UserStore.useUserStore;

  const { tabs } = useProfileWrapper();
  const { navigate } = useNavigateRoute();

  const onTabChange = useCallback(
    (id: string) => {
      const selectedTab = tabs.find((tab) => tab.id === id);
      if (selectedTab && selectedTab.path) navigate(selectedTab.path);
    },
    [navigate, tabs],
  );

  return (
    <FlexLayout direction='column' spacing={16} className='mr-16'>
      <ProfileInformationCard
        avatarInitials={userProfile?.name?.first ? userProfile?.name?.first[0] : '?'}
        name={getName()}
        email={userProfile?.email}
        number={getMaskedPhoneNumber()}
        entityMembersLength={entityMembers.length}
        isUserVerified={isUserVerified()}
        navigateToVerificationPage={() => navigate(NavigationURLs.ProfileVerification)}
        navigateToFeesPage={() => navigate(NavigationURLs.ProfileTradeFees)}
      />

      <ProfileMenuButtons onTabChange={onTabChange} selectedTabId={selectedTabId} tabs={tabs} />
    </FlexLayout>
  );
});

export { ProfileTabView };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowInCircleDown: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M9.06077 10.0284C8.76851 9.73491 8.29364 9.73388 8.00011 10.0261C7.70659 10.3184 7.70556 10.7933 7.99782 11.0868L11.4688 14.5728C11.6096 14.7142 11.8008 14.7936 12.0003 14.7936C12.1998 14.7936 12.391 14.7142 12.5318 14.5728L16.0028 11.0868C16.295 10.7933 16.294 10.3184 16.0005 10.0261C15.707 9.73388 15.2321 9.73491 14.9398 10.0284L12.0003 12.9807L9.06077 10.0284Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.47673 2 2 6.47784 2 12C2 17.5222 6.47673 22 12 22C17.5222 22 22 17.5222 22 12C22 6.47779 17.5222 2 12 2ZM3.5 12C3.5 7.30616 7.30527 3.5 12 3.5C16.6938 3.5 20.5 7.30621 20.5 12C20.5 16.6938 16.6938 20.5 12 20.5C7.30527 20.5 3.5 16.6938 3.5 12Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowInCircleDown };

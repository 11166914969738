import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Undo: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.0343 3.96967C11.3272 4.26257 11.3271 4.73744 11.0343 5.03033L8.31457 7.75H11.4819C17.4202 7.75 20.4648 14.8653 16.3646 19.1608L16.3646 19.1608L15.5464 20.0179C15.2604 20.3175 14.7857 20.3285 14.486 20.0425C14.1864 19.7565 14.1754 19.2817 14.4614 18.9821L15.2796 18.125C15.2796 18.125 15.2796 18.125 15.2796 18.125C18.4686 14.7841 16.1006 9.25 11.4819 9.25H8.31457L11.0343 11.9697C11.3271 12.2626 11.3272 12.7374 11.0343 13.0303C10.7414 13.3232 10.2665 13.3232 9.9736 13.0303L5.97358 9.03033C5.83292 8.88968 5.75391 8.69891 5.75391 8.5C5.75391 8.30109 5.83292 8.11032 5.97358 7.96967L9.9736 3.96967C10.2665 3.67678 10.7414 3.67678 11.0343 3.96967Z'
      fill='currentColor'
    />
  </svg>
);

export { Undo };

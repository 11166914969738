import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const PaperUpload: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M20.3799 7.56L15.2699 2.24C15.1299 2.09 14.9299 2.01 14.7299 2.01H14.5199C14.5199 2.01 14.4799 2 14.4599 2C14.4399 2 14.4199 2.01 14.3999 2.01H8.02992C5.52992 2.01 3.41992 4.08 3.41992 6.52V17.33C3.41992 19.95 5.44992 22 8.02992 22H16.0799C18.5199 22 20.5899 19.86 20.5899 17.33V8.08C20.5899 7.89 20.5099 7.7 20.3799 7.56ZM18.2699 7.53C17.8899 7.53 17.4699 7.53 17.0599 7.53C16.0499 7.53 15.2199 6.7 15.2199 5.69V4.36L18.2699 7.54V7.53ZM16.0799 20.5H8.02992C6.25992 20.5 4.91992 19.14 4.91992 17.33V6.52C4.91992 4.89 6.33992 3.51 8.02992 3.51H13.7199V5.68C13.7199 7.52 15.2199 9.02 17.0599 9.02C17.8399 9.02 18.4099 9.02 18.8099 9.02C18.9199 9.02 19.0099 9.02 19.0899 9.02V17.33C19.0899 19.05 17.7099 20.5 16.0799 20.5ZM15.0099 11.83C15.2999 12.12 15.2999 12.6 15.0099 12.89C14.7199 13.18 14.2399 13.18 13.9499 12.89L12.3699 11.3V16.11C12.3699 16.52 12.0299 16.86 11.6199 16.86C11.2099 16.86 10.8699 16.52 10.8699 16.11V11.3L9.28992 12.88C9.13992 13.03 8.94992 13.1 8.75992 13.1C8.56992 13.1 8.37992 13.03 8.22992 12.88C7.93992 12.59 7.93992 12.11 8.22992 11.82L11.0899 8.95C11.0899 8.95 11.1099 8.94 11.1199 8.93C11.2499 8.81 11.4299 8.73 11.6199 8.73C11.8099 8.73 11.9899 8.81 12.1199 8.93C12.1199 8.93 12.1399 8.93 12.1499 8.95L15.0099 11.82V11.83Z'
      fill='currentColor'
    />
  </svg>
);

export { PaperUpload };

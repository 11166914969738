import React, { useMemo } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { ImageProps } from './Image.types';

const Image: React.FC<ImageProps> = ({
  className,
  category = '',
  image,
  usePalette = false,
  ignoreEntity = false,
  fileType = 'png',
  src,
  ...rest
}) => {
  const { theme, palette } = useTailwindTheme();

  const imagePalette = useMemo(() => {
    if (!usePalette) return 'general/';

    if (ignoreEntity) return 'personal/';

    return `${palette}/`;
  }, [ignoreEntity, palette, usePalette]);

  const imageSrc = useMemo(
    () => `/assets/images_new/${imagePalette}${theme}${category}/${image}.${fileType}`,
    [category, fileType, image, imagePalette, theme],
  );

  return <img {...rest} src={src ?? imageSrc} className={className} />;
};

export { Image };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DarkMode: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.2975 2.63178C12.4427 2.88932 12.423 3.20809 12.2473 3.44582C11.2999 4.72756 10.844 6.30677 10.9625 7.89624C11.081 9.4857 11.7661 10.9798 12.8931 12.1069C14.0202 13.2339 15.5143 13.919 17.1038 14.0375C18.6932 14.1561 20.2725 13.7002 21.5542 12.7527C21.7919 12.577 22.1107 12.5573 22.3682 12.7025C22.6258 12.8476 22.774 13.1305 22.7468 13.4249C22.5673 15.3673 21.8383 17.2185 20.6451 18.7617C19.452 20.305 17.8439 21.4765 16.0092 22.1392C14.1745 22.8019 12.189 22.9284 10.2851 22.5038C8.38112 22.0793 6.63744 21.1213 5.25807 19.7419C3.87871 18.3626 2.92071 16.6189 2.49617 14.7149C2.07163 12.811 2.19811 10.8255 2.86082 8.99078C3.52353 7.15608 4.69504 5.54806 6.23829 4.35488C7.78154 3.1617 9.63269 2.4327 11.5751 2.25319C11.8695 2.22599 12.1524 2.37424 12.2975 2.63178ZM10.1739 4.02929C9.08416 4.33187 8.05831 4.84376 7.15579 5.54156C5.83794 6.56047 4.83752 7.93363 4.27161 9.50037C3.70569 11.0671 3.59768 12.7626 3.96021 14.3885C4.32275 16.0144 5.14083 17.5034 6.31873 18.6813C7.49664 19.8592 8.98564 20.6773 10.6115 21.0398C12.2374 21.4023 13.9329 21.2943 15.4996 20.7284C17.0664 20.1625 18.4395 19.1621 19.4585 17.8442C20.1563 16.9417 20.6681 15.9159 20.9707 14.8261C19.7331 15.3882 18.3658 15.6358 16.9922 15.5334C15.0445 15.3881 13.2136 14.5486 11.8325 13.1675C10.4514 11.7864 9.61189 9.95553 9.46664 8.00778C9.36421 6.63423 9.61184 5.26692 10.1739 4.02929Z'
      fill='currentColor'
    />
  </svg>
);

export { DarkMode };

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset } from '@shared/api';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';
import { useIntersectionObserver } from '@hooks/IntersectionObserver/useIntersectionObserver';

import { useMarketActivity } from 'src/lib/markets/hooks/useMarketActivity';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { MarketAssetFilterType } from 'src/lib/markets/types/Markets.types';

export const useMultiAssetPickerList = (params: { selectedTab: MarketAssetFilterType; search: string }) => {
  const { selectedTab, search } = params;

  const itemsPerPage = 20;
  const [index, setIndex] = useState<number>(0);

  const { assets } = useMarkets();
  const favouriteAssets = useFavouriteAssets();
  const { newListings, topAssets, ownedAssets } = useMarketActivity({
    assets,
  });

  const { targetRef: intersectionObserverRef } = useIntersectionObserver(() => setIndex((prev) => prev + 1));

  useEffect(() => {
    setIndex(0);
  }, [search, selectedTab]);

  const tableAssets = useMemo(() => {
    switch (selectedTab) {
      case 'all':
        return topAssets;
      case 'favourites':
        return favouriteAssets;
      case 'new':
        return newListings;
      case 'owned':
        return ownedAssets;
      default:
        selectedTab satisfies never;
        return [];
    }
  }, [favouriteAssets, newListings, ownedAssets, selectedTab, topAssets]);

  const hasMoreToLoad = useMemo(() => index * itemsPerPage < tableAssets.length, [index, tableAssets.length]);

  const filterSearch = useCallback(
    (asset: Asset) => {
      const searchLower = search.toLowerCase();
      return asset.name.toLowerCase().includes(searchLower) || asset.code.toLowerCase().includes(searchLower);
    },
    [search],
  );

  const assetsToRender = useMemo(
    () => tableAssets.filter(filterSearch).slice(0, (index + 1) * itemsPerPage),
    [filterSearch, index, tableAssets],
  );

  return { assetsToRender, intersectionObserverRef, hasMoreToLoad };
};

import React from 'react';

import { NewsWidgetCard } from '@swyftx/aviary/complex/NewsWidgetCard';
import { NewsType, defaultNewsTabs } from '@swyftx/aviary/complex/NewsWidgetCard/NewsWidgetCard.types';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgetRowProps } from 'src/lib/dashboard/types';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';

type Props = {
  widgetId?: DashboardWidgets;
} & DashboardWidgetRowProps;

const NewsCardWidget: React.FC<Props> = observer(({ widgetId, atLeast }) => {
  const favouriteAssets = useFavouriteAssets();
  const [newsType, setNewsType] = useDashboardSyncState<NewsType>({
    widgetId,
    stateKey: 'newsType',
    defaultValue: 'all',
  });

  return (
    <NewsWidgetCard
      title='Latest news'
      tabs={defaultNewsTabs}
      newsType={newsType}
      setNewsType={setNewsType}
      defaultNewsType='all'
      className='w-full'
      atLeast={atLeast}
      assets={favouriteAssets}
    />
  );
});

export { NewsCardWidget };

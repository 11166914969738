import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { Star } from '@swyftx/aviary/icons/outlined';
import { formatValueToCurrencyShorthand } from '@swyftx/aviary/utils';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import UserService from '@services/UserService';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

interface Props {
  asset: Asset;
  dailyVolume: string;
}

export const RankAsset24Vol: React.FC<Props> = (props) => {
  const { asset, dailyVolume } = props;
  const { isFavourite } = useMarkets();
  const isFavourited = useMemo(() => isFavourite(asset.id), []);

  const favouriteAsset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, assetId: number, favourite: boolean) => {
    e.stopPropagation();
    e.preventDefault();

    UserService.UpdateUserSettings({
      data: { favouriteAsset: { assetId, favStatus: favourite } },
    });
  };

  const favouriteIcon = useMemo(
    () =>
      isFavourited ? <StarFilled className='text-color-icon-accent' /> : <Star className='text-color-icon-neutral' />,
    [isFavourited],
  );

  return (
    <FlexLayout spacing={8} alignItems='center' justifyContent='start' className='truncate'>
      <Button
        layout='icon'
        variant='ghost'
        tooltip={isFavourited ? 'Unfavourite' : 'Favourite'}
        leadingIcon={favouriteIcon}
        onClick={(e) => favouriteAsset(e, asset.id, !isFavourited)}
        size='sm'
      />
      <AssetIcon asset={asset} size={20} />
      <FlexLayout direction='column' className='items-center'>
        <FlexLayout direction='row' spacing={8}>
          <Body size='small' title={asset.name} weight='emphasis'>
            {asset.name}
          </Body>
          <Body size='small' title={asset.name} color='secondary' overflow='truncate'>
            {asset.code}
          </Body>
        </FlexLayout>
        <FlexLayout direction='row' className='w-full items-center' spacing={4}>
          <Chip size='sm' variant='subtle' color='secondary'>
            #{asset.rank}
          </Chip>
          <Numeric size='small'>{formatValueToCurrencyShorthand(dailyVolume)} </Numeric>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

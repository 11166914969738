import { useCallback, useEffect } from 'react';

import storage, { StorageKey } from '@shared/storage';
import { UIStore, UserStore } from '@shared/store';

import AuthenticationService from '@services/AuthenticationService';

import * as Sentry from '@sentry/react';
import { useIntercom } from 'react-use-intercom';
import { useGlobalSearch } from 'src/lib/search/hooks';
import { useCurrentPlatform } from 'src/lib/utils/hooks/useCurrentPlatform';

const logoutChannel = new BroadcastChannel('logout');

export const useLogout = () => {
  const { shutdown, boot } = useIntercom();
  const { setRecaptchaToken, setAuth, userAuth } = UserStore.useUserStore;
  const { setGlobalModalData } = UIStore.useUIStore;
  const { closeGlobalSearch } = useGlobalSearch();
  const { setCurrentPlatform } = useCurrentPlatform();

  const removeStripeResidual = () => {
    const iframe3DS2 = document.querySelector('iframe[src^="https://js.stripe.com/v3/three-ds-2-challenge"]');
    const iframe3DS = document.querySelector('iframe[src^="https://js.stripe.com/v3/authorize-with-url-inner"]');

    if (iframe3DS2 && iframe3DS2.parentElement) {
      iframe3DS2.parentElement.remove();
    }

    if (iframe3DS && iframe3DS.parentElement) {
      iframe3DS.parentElement.remove();
    }
  };

  const logout = useCallback(async () => {
    if (!userAuth) return;

    await AuthenticationService.Logout();
    await storage.removeItem(StorageKey.USER_DATA);
    removeStripeResidual();
    shutdown();
    Sentry.setUser(null);
    setRecaptchaToken('');
    setAuth(undefined);
    closeGlobalSearch();
    setGlobalModalData(undefined);
    setCurrentPlatform('swyftx');
    boot({
      alignment: 'left',
      horizontalPadding: 20,
    });

    logoutChannel.postMessage('logout');
  }, [boot, closeGlobalSearch, setAuth, setCurrentPlatform, setGlobalModalData, setRecaptchaToken, shutdown, userAuth]);

  useEffect(() => {
    logoutChannel.addEventListener('message', logout);

    return () => {
      logoutChannel.removeEventListener('message', logout);
    };
  }, [logout]);

  return logout;
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Hide: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.4176 4.78033C20.7105 4.48744 20.7105 4.01256 20.4176 3.71967C20.1247 3.42678 19.6498 3.42678 19.357 3.71967L17.2696 5.80702C15.6817 4.69866 13.884 4.08398 12 4.08398C9.91624 4.08398 7.92462 4.83395 6.21948 6.18315C4.50749 7.52987 3.08525 9.46849 2.06144 11.8397C1.97974 12.0289 1.97951 12.2434 2.06081 12.4328C2.94555 14.4939 4.13467 16.2256 5.55475 17.5219L3.58295 19.4937C3.29006 19.7866 3.29006 20.2614 3.58295 20.5543C3.87584 20.8472 4.35072 20.8472 4.64361 20.5543L20.4176 4.78033ZM6.61669 16.4599L8.75445 14.3222C8.33141 13.6994 8.08594 12.9506 8.08594 12.1377C8.08594 9.97093 9.83326 8.22168 11.9999 8.22168C12.8031 8.22168 13.5609 8.46567 14.1868 8.88981L16.1895 6.88708C14.886 6.03286 13.4614 5.58398 12 5.58398C10.2881 5.58398 8.61809 6.19775 7.14944 7.36009L7.14764 7.36151C5.73365 8.47357 4.50016 10.0919 3.56994 12.1378C4.37913 13.9265 5.42292 15.3849 6.61669 16.4599ZM13.0937 9.98293C12.7664 9.81604 12.3933 9.72168 11.9999 9.72168C10.6626 9.72168 9.58594 10.7984 9.58594 12.1377C9.58594 12.5338 9.67919 12.9031 9.84694 13.2297L13.0937 9.98293Z'
      fill='currentColor'
    />
    <path
      d='M15.2382 11.9612C15.6459 12.0345 15.917 12.4244 15.8436 12.8321C15.5563 14.4299 14.2996 15.6893 12.7025 15.9793C12.2949 16.0533 11.9045 15.7829 11.8306 15.3753C11.7566 14.9678 12.027 14.5774 12.4345 14.5034C13.4154 14.3253 14.1907 13.5488 14.3673 12.5666C14.4406 12.1589 14.8306 11.8879 15.2382 11.9612Z'
      fill='currentColor'
    />
    <path
      d='M18.996 8.39204C19.3269 8.14293 19.7971 8.20927 20.0462 8.54021C20.7737 9.50663 21.4075 10.6142 21.9374 11.8396C22.0195 12.0294 22.0195 12.2446 21.9376 12.4345C19.8957 17.1649 16.2021 20.1893 11.999 20.1893C11.0543 20.1893 10.1232 20.0359 9.22957 19.7377C8.83666 19.6065 8.62444 19.1817 8.75557 18.7888C8.8867 18.3959 9.31152 18.1837 9.70443 18.3148C10.4468 18.5626 11.2177 18.6893 11.999 18.6893C15.332 18.6893 18.5193 16.3349 20.4286 12.1375C19.9697 11.1295 19.4388 10.2274 18.8478 9.4423C18.5987 9.11137 18.665 8.64115 18.996 8.39204Z'
      fill='currentColor'
    />
  </svg>
);

export { Hide };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Storage: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M21.2295 6.79736L20.4146 4.77685C19.895 3.48486 18.6587 2.6499 17.2647 2.6499H7.46242C6.06545 2.6499 4.82815 3.48779 4.31008 4.78369L3.49758 6.81592C3.17678 7.61767 3.01369 8.46142 3.01223 9.32275L3.00002 16.7105C2.99367 19.5952 4.49856 21.3208 7.02785 21.3267L17.6919 21.3413H17.7061C20.2051 21.3413 21.7007 19.6255 21.7085 16.7466L21.7207 9.34424C21.7222 8.46826 21.5571 7.61084 21.2295 6.79736ZM19.0786 5.31494L19.8936 7.33545C19.9077 7.37042 19.912 7.40772 19.9253 7.44287H15.4271L14.8533 4.08935H17.2647C18.0674 4.08935 18.7793 4.56982 19.0786 5.31494ZM10.6201 8.88232H14.0996V12.1353L12.6084 11.5747C12.4453 11.5122 12.2647 11.5142 12.1006 11.5747L10.6201 12.1343V8.88232ZM10.7536 7.44287L11.3273 4.08935H13.3929L13.9662 7.44287H10.7536ZM5.64699 5.31885C5.94533 4.57178 6.65773 4.08936 7.46242 4.08936H9.86684L9.29274 7.44288H4.80745C4.81886 7.41285 4.82253 7.38099 4.83449 7.35108L5.64699 5.31885ZM20.2681 16.7437C20.2627 18.8394 19.4009 19.9019 17.7051 19.9019H17.6948L7.03078 19.8872C4.88332 19.8813 4.43703 18.1587 4.44045 16.7134L4.45266 9.3247C4.45278 9.17541 4.48263 9.03008 4.49514 8.88232H9.17971V13.1753C9.17971 13.4116 9.29592 13.6333 9.49026 13.7671C9.6846 13.9028 9.93313 13.9331 10.1543 13.8491L12.3555 13.0181L14.5664 13.8491C14.6485 13.8804 14.7344 13.895 14.8198 13.895C14.9644 13.895 15.1074 13.8521 15.2295 13.7671C15.4239 13.6323 15.5401 13.4116 15.5401 13.1753V8.88232H20.2372C20.2501 9.03595 20.2806 9.18676 20.2803 9.34228L20.2681 16.7437ZM10.7876 16.6655C10.7876 17.063 10.4649 17.3853 10.0674 17.3853H7.19094C6.79348 17.3853 6.47073 17.063 6.47073 16.6655C6.47073 16.2681 6.79348 15.9458 7.19094 15.9458H10.0674C10.4649 15.9458 10.7876 16.2681 10.7876 16.6655Z'
      fill='currentColor'
    />
  </svg>
);

export { Storage };

import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';
import { FiatIdEnum, LowLiquidityMax } from '@shared/enums';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

const useLowLiquidity = (fromAsset?: number, toAsset?: number) => {
  const { getRate, convertRate } = RatesStore.useRatesStore;

  const sellLiquidityFlag = useMemo(
    () => (fromAsset ? getRate(fromAsset).sellLiquidityFlag : false),
    [fromAsset, getRate],
  );
  const buyLiquidityFlag = useMemo(() => (toAsset ? getRate(toAsset).buyLiquidityFlag : false), [toAsset, getRate]);

  const maximumAmount = useMemo(() => {
    if (fromAsset) {
      return convertRate(FiatIdEnum.AUD, fromAsset, LowLiquidityMax.AUD, sellLiquidityFlag ? 'bidPrice' : 'askPrice');
    }
    return -1;
  }, [convertRate, fromAsset, sellLiquidityFlag]);

  const maximumAmountText = useMemo(() => {
    if (fromAsset === undefined) return '';

    return formatCurrency(maximumAmount, assetService.getAsset(fromAsset), { appendCode: true, hideCode: false });
  }, [fromAsset, maximumAmount]);

  const getIsLowLiquidity = useCallback(
    (asset: Asset) => {
      const assetSellLiquidityFlag = getRate(asset).sellLiquidityFlag;
      const assetBuyLiquidityFlag = getRate(asset).buyLiquidityFlag;

      return assetSellLiquidityFlag || assetBuyLiquidityFlag;
    },
    [getRate],
  );

  return {
    isLowLiquidity: sellLiquidityFlag || buyLiquidityFlag,
    maximumAmount,
    maximumAmountText,
    getIsLowLiquidity,
  };
};

export { useLowLiquidity };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Clock: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.4121 7.84619C12.4121 7.43198 12.0763 7.09619 11.6621 7.09619C11.2479 7.09619 10.9121 7.43198 10.9121 7.84619V12.6932C10.9121 12.9573 11.051 13.202 11.2779 13.3373L15.0479 15.5863C15.4036 15.7985 15.864 15.6822 16.0762 15.3264C16.2884 14.9707 16.1721 14.5103 15.8163 14.2981L12.4121 12.2673V7.84619Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.47679 2 2 6.47679 2 12C2 17.5232 6.47679 22 12 22C17.5232 22 22 17.5232 22 12C22 6.47679 17.5232 2 12 2ZM3.5 12C3.5 7.30521 7.30521 3.5 12 3.5C16.6948 3.5 20.5 7.30521 20.5 12C20.5 16.6948 16.6948 20.5 12 20.5C7.30521 20.5 3.5 16.6948 3.5 12Z'
      fill='currentColor'
    />
  </svg>
);

export { Clock };

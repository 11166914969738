import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { countryTextFactory } from '@services/DepositService';

export interface WithdrawOverview {
  processingTime?: string;
  limits: string;
  fees: string;
  verification?: string;
  incorrectWithdrawals?: string;
}

export interface WithdrawLimit {
  [key: string]: {
    min: number;
    max: number;
  };
}

export const useWithdrawDescription = (country: string) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'withdrawInformation' });

  const withdrawDescription = useMemo(
    (): WithdrawOverview =>
      countryTextFactory<WithdrawOverview>({
        nzd: {
          limits: t('nzd.limits'),
          fees: t('nzd.fees'),
          processingTime: t('nzd.processingTime'),
          verification: t('nzd.verification'),
        },
        aud: {
          limits: t('aud.limits'),
          fees: t('aud.fees'),
          verification: t('aud.verification'),
        },
        currentCountry: country,
      }),
    [t, country],
  );

  return {
    withdrawDescription,
  };
};

import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

export enum PriceAlertStep {
  CURRENT_ALERTS = 'CURRENT_ALERTS',
  CREATE_ALERT = 'CREATE_ALERT',
}

export enum PriceAlertStatus {
  ACTIVE = 'ACTIVE',
  TRIGGERED = 'TRIGGERED',
}

export type PriceAlertTab = EnhancedTab<PriceAlertStatus>;

export const priceAlertTabs: PriceAlertTab[] = [
  {
    title: 'Active',
    value: PriceAlertStatus.ACTIVE,
  },
  {
    title: 'Triggered',
    value: PriceAlertStatus.TRIGGERED,
  },
];

export enum FiatIdEnum {
  AUD = 1,
  GBP = 33,
  JPY = 34,
  EUR = 35,
  USD = 36,
  NZD = 190,
}

export enum FiatCodeEnum {
  AUD = 'AUD',
  GBP = 'GBP',
  JPY = 'JPY',
  EUR = 'EUR',
  USD = 'USD',
  NZD = 'NZD',
}

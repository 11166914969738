import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonBase from '@mui/material/ButtonBase';

import { Copy } from '@swyftx/aviary/icons/outlined';
import { Checkbox, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { UIStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

type Props = {
  onClose: () => void;
  onAction: () => void;
  recoveryCode: string;
};

export const EnableTwoFactorRecoveryStep: React.FC<Props> = ({ onClose, onAction, recoveryCode }) => {
  const [recordedCode, setRecordedCode] = useState(false);
  const { t } = useTranslation('profile');
  const { t: securityT } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });
  const { addMessageBox } = UIStore.useUIStore;
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  const recoveryCodeFormatted: string = recoveryCode.match(/.{1,4}/g)?.join('-') || '';

  const copyRecoveryCode = () => {
    navigator.clipboard.writeText(recoveryCodeFormatted);
    addMessageBox({ content: <InformationMessageBox title={t('smsVerification.copiedToClipboard')} /> });
  };

  return (
    <Wizard.Step
      actionName={securityT('enable.accountRecovery.buttonLabels.action')}
      locked={!recordedCode}
      title={securityT('enable.accountRecovery.title')}
      onAction={onAction}
      onClose={onClose}
      hideCancel
    >
      <Stack justifyContent='space-between' minHeight='300px'>
        <Stack spacing={2}>
          <Typography fontSize={pxToRem(14)} color='text.secondary'>
            {securityT('enable.accountRecovery.labels.description')}
          </Typography>
          <Stack bgcolor='action.hover' justifyContent='space-between' alignItems='center' direction='row' padding={1}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography fontWeight={600} fontSize={pxToRem(14)}>
                {securityT('enable.accountRecovery.labels.recoveryCode')}
              </Typography>
              <Typography id='copy-recovery-code' color='text.secondary' fontSize={pxToRem(14)}>
                {recoveryCodeFormatted}
              </Typography>
            </Stack>
            {hasClipboardPermission === 'granted' && (
              <ButtonBase sx={{ borderRadius: 1 }} onClick={copyRecoveryCode}>
                <Stack direction='row' spacing={1} alignItems='center' padding={1}>
                  <Copy className='h-20 w-20 text-color-text-primary' />
                  <Typography fontSize={pxToRem(12)} color='primary' fontWeight={500}>
                    {securityT('enable.labels.copy')}
                  </Typography>
                </Stack>
              </ButtonBase>
            )}
          </Stack>
        </Stack>
        <Checkbox
          checked={recordedCode}
          onChange={(e) => setRecordedCode(e.target.checked)}
          label={securityT('enable.accountRecovery.labels.checkbox')}
        />
      </Stack>
    </Wizard.Step>
  );
};

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { HumanReadableEntityTypes } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  prevType?: string;
  newType?: string;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChangeEntityTypeModal: React.FC<Props> = (props) => {
  const { open, setOpen, prevType, newType, onConfirm } = props;
  const prevEntityTypeHumanReadableText = prevType
    ? HumanReadableEntityTypes[prevType as keyof typeof HumanReadableEntityTypes]
    : '';
  const newEntityTypeHumanReadableText = newType
    ? HumanReadableEntityTypes[newType as keyof typeof HumanReadableEntityTypes]
    : '';

  const isXs = useTailwindBreakpoint('xs');
  return (
    <Modal
      open={open}
      onOpenChange={(e) => setOpen(e?.valueOf() ?? false)}
      title='Are you sure?'
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={24} className='p-24 pt-0'>
        <Body color='secondary'>
          Are you sure you want to switch from a {prevEntityTypeHumanReadableText} to {newEntityTypeHumanReadableText}?
        </Body>
        <Body color='secondary'>
          By selecting a new entity type, you will lose all saved information and be required to start the form again.
        </Body>
        <FlexLayout direction='row' className='w-full justify-end' spacing={8}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color='destructive' onClick={(e) => onConfirm(e)} type='submit'>
            Yes, switch to {newEntityTypeHumanReadableText}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

import React, { useMemo } from 'react';

import { Button, Modal, Skeleton, Stack, Typography } from '@swyftx/react-web-design-system';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

interface Props {
  onClose: () => void;
  nextDustTime?: number;
}

const NextDustTimeModal: React.FC<Props> = observer(({ nextDustTime, onClose }) => {
  const RemainingDustTime = useMemo(() => {
    if (!nextDustTime) return '';

    const nextDust = DateTime.fromMillis(nextDustTime);
    const diff = nextDust.diffNow(['hours', 'minutes', 'seconds']);
    const { hours, minutes } = diff;

    const hoursText = hours ? `${hours} hour${hours !== 1 ? 's' : ''}` : '';
    const minutesText = `${minutes} minute${minutes !== 1 ? 's' : ''}`;

    return `${hoursText} ${minutesText}`;
  }, [nextDustTime]);

  return (
    <>
      <Modal
        id='asset-dusting-modal'
        open
        variant='Modal'
        sx={{ width: '345px' }}
        onClose={onClose}
        HeaderProps={{ title: 'Daily limit exceeded', dismissible: true, divider: true }}
        FooterProps={{
          content: (
            <Stack width='100%' direction='row' alignItems='center' justifyContent='flex-end'>
              <Button variant='contained' color='primary' size='large' onClick={onClose} disableElevation>
                Dismiss
              </Button>
            </Stack>
          ),
          divider: true,
        }}
      >
        <Stack direction='column' alignItems='flex-start' justifyContent='center' width='100%'>
          <Typography fontSize={14} color='text.secondary' marginBottom={1}>
            Dusting can only be executed once every 24 hours. You will be able to dust assets again in:
          </Typography>
          {!nextDustTime && <Skeleton variant='text' width='150px' />}
          {nextDustTime && (
            <Typography color='primary' fontSize={16} fontWeight={500}>
              {RemainingDustTime}
            </Typography>
          )}
        </Stack>
      </Modal>
    </>
  );
});

NextDustTimeModal.displayName = 'NextDustTimeModal';

export { NextDustTimeModal };

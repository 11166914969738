import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserStore } from '@shared/store';

import { useIntercom } from 'react-use-intercom';
import { NavigationURLs } from 'src/lib/navigation/types';

export const useGlobalResolveRestrictions = () => {
  const navigate = useNavigate();
  const { show } = useIntercom();
  const { isEntity, isKyc0Required } = UserStore.useUserStore;

  const handleResolveRestrictions = useCallback(() => {
    // Entity accounts don't have access to ProfileVerification. Show intercom instead
    if (isEntity() || isKyc0Required()) {
      show();
      return;
    }
    navigate(NavigationURLs.ProfileVerification);
  }, [isEntity, isKyc0Required, navigate, show]);

  return () => {
    handleResolveRestrictions();
  };
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { NavigationErrorBoundary } from '@global-components/ErrorBoundaries';

import env from '@shared/config';
import { AppStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { useTrackPageView } from './useTrackPageView';

type Props = {
  element: React.ReactElement;
  pageName?: string;
  ignoreAuth?: boolean;
};

const PublicRoute: React.FC<Props> = observer(({ element, ignoreAuth, pageName = '' }) => {
  const { isMaintenanceMode } = AppStore.useAppStore;
  const { userProfile } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isSwyftxPro } = useSwyftxPro();
  const { parsePath } = useNavigateRoute();
  const { state } = useLocation();
  useTrackPageView({ pageName });

  if (isMaintenanceMode || env.MAINTENANCE_MODE === true || isFeatureEnabled(AppFeature.MaintenanceMode)) {
    return <Navigate to={NavigationURLs.Maintenance} />;
  }

  if (userProfile && !ignoreAuth) {
    if (userProfile.metadata?.passwordResetRequired) {
      return <Navigate to={NavigationURLs.PasswordReset} />;
    }

    return isSwyftxPro ? (
      <Navigate to={parsePath(NavigationURLs.SwyftxPro, { pathParams: { asset: 'BTC' } })} />
    ) : (
      <Navigate to={state?.redirectTo || NavigationURLs.Dashboard} />
    );
  }

  return <NavigationErrorBoundary>{element}</NavigationErrorBoundary>;
});

PublicRoute.displayName = 'PublicRoute';

export default PublicRoute;

import React, { SetStateAction, useCallback, useMemo, useState, useEffect } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ChartTypeLine } from '@swyftx/aviary/icons/charts';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { BuySellButtons } from '@global-components/BuySellButtons';

import { Asset } from '@shared/api';
import { UniversalTradeStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

import { UniversalTradeHeader } from './components/Header/UniversalTradeHeader';
import { OpenOrders } from './components/OrdersTile/OpenOrders';
import { OrderHistory } from './components/OrdersTile/OrderHistory';
import { PortfolioBreakdown } from './components/OrdersTile/PortfolioBreakdown';
import { FullScreenTradePanel } from './components/TradePanel/FullScreenTradePanel';
import { tradePageTabs, TradePageType, TradePageVariant } from './universalTradePage.consts';
import { TradingViewWidget } from '../trade/components';
import { TradingViewSide } from '../trade/components/TradingViewWidget/types';

interface Props {
  selectedAsset: Asset;
  setSelectedAsset: React.Dispatch<SetStateAction<Asset | undefined>>;
  variant: TradePageVariant;
  priceSide: TradingViewSide;
  setPriceSide: (side: TradingViewSide) => void;
}

export const XsUniversalTradePage: React.FC<Props> = observer((props) => {
  const { variant, selectedAsset, setSelectedAsset, setPriceSide, priceSide } = props;
  const [selectedTab, setSelectedTab] = useState<TradePageType>(TradePageType.CHART);
  const [tradePanelOpen, setTradePanelOpen] = useState(false);
  const { cleanup, tradeFrom, tradeTo } = UniversalTradeStore;
  const baseAsset = useBaseAsset();
  const onMobileBack = useCallback(() => {
    cleanup();
    setTradePanelOpen(false);
  }, [cleanup]);

  // If the user is navigating to this page with a trade already in progress, open the trade panel
  useEffect(() => {
    if (tradeFrom.length || tradeTo.length) {
      setTradePanelOpen(true);
    }
  }, [tradeFrom.length, tradeTo.length]);

  const pageContent = useMemo(() => {
    switch (selectedTab) {
      case TradePageType.CHART:
        return (
          <>
            {baseAsset && (
              <>
                {baseAsset.code !== selectedAsset.code && (
                  <div className='h-full p-0'>
                    <TradingViewWidget
                      primary={baseAsset.code}
                      secondary={selectedAsset.code}
                      border={false}
                      chartStyle='advanced'
                      className='h-full'
                      onPriceSideChange={setPriceSide}
                    />
                  </div>
                )}
                {baseAsset.code === selectedAsset.code && (
                  <FlexLayout direction='column' spacing={8} className='h-full items-center justify-center text-center'>
                    <ChartTypeLine className='h-96 w-96 text-color-text-accent' />
                    <Body color='primary'>Cannot show chart when selected asset is the same as base asset</Body>
                  </FlexLayout>
                )}
              </>
            )}
          </>
        );
      case TradePageType.WALLETS:
        return <PortfolioBreakdown setSelectedAsset={setSelectedAsset} />;
      case TradePageType.OPEN_ORDERS:
        return <OpenOrders selectedAsset={selectedAsset} />;
      case TradePageType.ORDER_HISTORY:
        return <OrderHistory selectedAsset={selectedAsset} />;
      default:
        selectedTab satisfies never;
    }
  }, [baseAsset, selectedAsset, selectedTab, setPriceSide, setSelectedAsset]);

  if (tradePanelOpen) {
    return <FullScreenTradePanel onClose={onMobileBack} />;
  }

  return (
    <>
      <FlexLayout direction='column' className='h-full w-full pb-72'>
        <FlexLayout direction='column' className='w-full'>
          <UniversalTradeHeader variant={variant} selectedAsset={selectedAsset} priceSide={priceSide} />

          <FlexLayout direction='column' className='w-full overflow-x-auto px-12 py-4 pr-4'>
            <EnhancedTabs<TradePageType>
              tabs={tradePageTabs}
              value={selectedTab}
              onChange={setSelectedTab}
              variant='child'
              size='sm'
            />
          </FlexLayout>
        </FlexLayout>
        <div className='min-h-[10rem] flex-grow p-8'>
          <FlexLayout
            direction='column'
            className={cn(
              'h-full w-full rounded-12 border border-color-border-main bg-color-background-surface-primary',
              selectedTab === TradePageType.CHART ? 'py-0' : 'pt-6 ',
            )}
          >
            {pageContent}
          </FlexLayout>
        </div>
      </FlexLayout>
      <FlexLayout className='fixed bottom-0 mt-45 h-64 w-full justify-end border-t border-color-border-main bg-color-background-pageBG p-8 py-16'>
        <BuySellButtons asset={selectedAsset} openTradePanel postClick={() => setTradePanelOpen(true)} />
      </FlexLayout>
    </>
  );
});

/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

const useWhatsNewAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const trackWhatsNewOpened = useCallback(
    (whats_new_feature: string, method: 'manual' | 'automatic') => {
      avo.whatsNewModalOpened({
        screen: pathname,
        whatsNewFeature: whats_new_feature,
        method,
      });
    },
    [avo, pathname],
  );

  const trackWhatsNewClosed = useCallback(
    (whats_new_feature: string, step: number) => {
      avo.whatsNewModalClosed({
        screen: pathname,
        whatsNewFeature: whats_new_feature,
        step,
      });
    },
    [avo, pathname],
  );

  const trackWhatsNewActionClicked = useCallback(
    (whats_new_feature: string, action: string) => {
      avo.whatsNewActionClicked({
        screen: pathname,
        whatsNewFeature: whats_new_feature,
        action,
      });
    },
    [avo, pathname],
  );

  return {
    trackWhatsNewOpened,
    trackWhatsNewClosed,
    trackWhatsNewActionClicked,
  };
};

export { useWhatsNewAnalytics };

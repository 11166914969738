import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const TwoFactorVerification: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;
  const { openModal } = useModal();

  const onSwitch = () => {
    if (!userProfile?.metadata?.mfa_enabled) {
      openModal(Modals.EnableTwoFactor);
    } else {
      openModal(Modals.DisableTwoFactor);
    }
  };

  return (
    <Switch
      id='profile-security-two-factor-verification'
      checked={userProfile?.metadata?.mfa_enabled}
      size='md'
      disabled={userProfile?.metadata?.mfa_enabled && userProfile?.userSettings?.disableSMSRecovery}
      onCheckedChange={onSwitch}
    />
  );
});

TwoFactorVerification.displayName = 'TwoFactorVerification';

export { TwoFactorVerification };

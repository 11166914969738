import { useCallback, useMemo } from 'react';

import { PortfolioBreakdownItem } from '@swyftx/aviary/organisms/Portfolio/PortfolioBreakdownCard/PortfolioBreakdownCard.types';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';
import { AssetColors } from '@utils/assets';

const usePortfolioBreakdown = () => {
  const { balances } = UserStore.useUserStore;
  const { convertRate } = RatesStore.useRatesStore;
  const {
    portfolio: { accountValue },
  } = usePortfolio({ balanceKey: 'all' });
  const baseAsset = useBaseAsset();

  const calculatePortfolioPercentage = useCallback((value: Big) => value.div(accountValue).times(100), [accountValue]);

  // How many other assets should we show before grouping
  const MAX_OTHER_DATA = 6;

  const breakdownData = useMemo((): PortfolioBreakdownItem[] => {
    if (!baseAsset) return [];

    let data: PortfolioBreakdownItem[] = [];

    const lowValueBalances = Object.values(balances).filter((b: UserBalance) => {
      const asset = assetService.getAsset(b.assetId)!;
      const value = convertRate(asset, baseAsset, b.availableBalance);
      const percentage = calculatePortfolioPercentage(value);
      return percentage.lte(1) && percentage.gt(0);
    });

    const highValueBalances = Object.values(balances).filter((b: UserBalance) => {
      const asset = assetService.getAsset(b.assetId)!;
      const value = convertRate(asset, baseAsset, b.availableBalance);
      return calculatePortfolioPercentage(value).gt(1);
    });

    highValueBalances.forEach((balance: UserBalance) => {
      const asset = assetService.getAsset(balance.assetId)!;

      if (!asset) return;

      const value = convertRate(asset, baseAsset, balance.availableBalance);

      data.push({
        assetCode: asset.code,
        color: asset.color || AssetColors[asset.code],
        icon: <AssetIcon asset={asset} size={20} />,
        countryValue: value.toNumber(),
        balance: formatCurrency(balance.availableBalance, asset),
        value: formatCurrency(value, baseAsset),
        portfolioPercentage: `${calculatePortfolioPercentage(value).toFixed(2)}%`,
      });
    });

    let lowValueCountryValue = Big(0);
    let lowValueTotalPercentage = Big(0);
    let otherData: { asset?: Asset; value: Big }[] = [];

    lowValueBalances.forEach((balance: UserBalance) => {
      const asset = assetService.getAsset(balance.assetId);

      if (!asset) return;

      const value = convertRate(asset, baseAsset, balance.availableBalance);

      lowValueCountryValue = lowValueCountryValue.plus(value);
      lowValueTotalPercentage = lowValueTotalPercentage.plus(calculatePortfolioPercentage(value));
      otherData.push({
        asset,
        value: value,
      });
    });

    otherData.sort((a, b) => b.value.cmp(a.value));

    const parsedOtherData = otherData
      .map((o) => ({ asset: o.asset, value: formatCurrency(o.value, baseAsset) }))
      .slice(0, MAX_OTHER_DATA);

    let smallBalances = Big(0);

    otherData.slice(MAX_OTHER_DATA + 1).forEach((d) => {
      smallBalances = smallBalances.plus(d.value);
    });

    parsedOtherData.push({ asset: undefined, value: formatCurrency(smallBalances, baseAsset) });

    data = data.sort((a, b) => Big(b.countryValue).cmp(a.countryValue));

    // Always ensure that Others are at the end of the Donut chart legend
    if (lowValueCountryValue.gt(0)) {
      data.push({
        assetCode: `Others (${lowValueBalances.length})`,
        color: 'var(--color-text-secondary)',
        countryValue: lowValueCountryValue.toNumber(),
        balance: 'All other assets',
        value: formatCurrency(lowValueCountryValue, baseAsset),
        portfolioPercentage: `${lowValueTotalPercentage.toFixed(2)}%`,
        otherData: parsedOtherData,
      });
    }

    return data;
  }, [balances, baseAsset, convertRate, calculatePortfolioPercentage]);

  return { breakdownData };
};

export { usePortfolioBreakdown };

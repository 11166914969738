import React, { useEffect, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyDown, ArrowLineOnlyUp } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

type Props = {
  midPrice?: string;
  loading?: boolean;
  baseAsset: Asset;
};

const SwyftxProOrderbookMidPrice: React.FC<Props> = ({ midPrice, loading, baseAsset }) => {
  const [lastMidPrice, setLastMidPrice] = useState<string>();
  const [priceMovement, setPriceMovement] = useState<'up' | 'down'>();

  useEffect(() => {
    if (lastMidPrice && !Big(midPrice).eq(lastMidPrice)) {
      setPriceMovement(Big(midPrice).gt(lastMidPrice) ? 'up' : 'down');
    }

    setLastMidPrice(midPrice);
  }, [lastMidPrice, midPrice]);

  return (
    <FlexLayout alignItems='center' spacing={8} className='relative w-full rounded-4 p-8'>
      <Numeric size='large' color={priceMovement === 'up' ? 'success' : 'error'} weight='emphasis' loading={loading}>
        {formatCurrency(midPrice, baseAsset)}
      </Numeric>
      {!loading && priceMovement === 'up' ? (
        <ArrowLineOnlyUp className='h-16 w-16 text-color-text-success' />
      ) : (
        <ArrowLineOnlyDown className='h-16 w-16 text-color-text-error' />
      )}
    </FlexLayout>
  );
};

export { SwyftxProOrderbookMidPrice };

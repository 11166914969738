import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InlineNotification } from '@swyftx/aviary/atoms/InlineNotification';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import Links from '@shared/constants/links';

import { useAssetLimitedFunctionality } from '@hooks/Assets/useAssetLimitedFunctionality';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

import { LimitedFunctionalityNotificationItem } from './LimitedFunctionalityNotificationItem';

type Props = {
  assets: Asset[];
  assetsToIgnore?: number[];
};

const LimitedFunctionalityNotificationAccordion: React.FC<Props> = observer(({ assets, assetsToIgnore }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { isLightTheme } = useTheme();
  const { limitedFunctionalityItems } = useAssetLimitedFunctionality(assets, assetsToIgnore);

  const { t } = useTranslation('assets', { keyPrefix: 'limitedFunctionality' });

  if (!limitedFunctionalityItems.length) return null;

  return (
    <Stack
      bgcolor={isLightTheme ? '#fff8ef' : 'grey.700'}
      borderTop='1px solid'
      borderBottom='1px solid'
      borderColor='divider'
      position='relative'
      padding={2}
      direction='row'
      width='100%'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <Stack spacing={1} alignItems='flex-start' width='100%'>
        {!expanded && (
          <InlineNotification severity='warning'>
            <Typography key='content' fontWeight={500} fontSize={12} color={isLightTheme ? 'warning' : 'text.primary'}>
              <Trans
                t={t}
                i18nKey='title'
                components={[
                  <Typography
                    key='hyperlink'
                    display='inline'
                    fontSize={12}
                    fontWeight={500}
                    color='primary'
                    onClick={() => window.open(Links.help.lowLiquidityAndLimited, '_blank', 'noopener,noreferrer')}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  />,
                ]}
              />
            </Typography>
          </InlineNotification>
        )}
        {expanded &&
          limitedFunctionalityItems.map((item) => (
            <LimitedFunctionalityNotificationItem
              key={item.limitedFunctionalityType}
              assets={item.assets}
              limitedFunctionalityType={item.limitedFunctionalityType}
            />
          ))}
      </Stack>
      <Button
        color='inherit'
        onClick={() => setExpanded((prevState) => !prevState)}
        sx={{ textAlign: 'center', position: 'absolute', top: '0.65rem', right: '0.5rem' }}
        icon
        startIcon={
          expanded ? (
            <ArrowChevronDown className='h-16 w-16 text-color-text-secondary' />
          ) : (
            <ArrowChevronUp className='h-16 w-16 text-color-text-secondary' />
          )
        }
      />
    </Stack>
  );
});

export { LimitedFunctionalityNotificationAccordion };

import { RouteObject } from 'react-router-dom';

import { NavigationURLs } from 'src/lib/navigation/types';

import { TutorialsPage } from './TutorialsPage';

export const TutorialRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Tutorials,
    element: <TutorialsPage />,
  },
];

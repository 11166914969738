import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { StarFilled } from '@swyftx/aviary/icons/filled';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { PercentageIncrease } from '@global-components/PercentageIncrease';
import { AssetPriceText } from '@global-components/Text';
import { LineChart } from '@global-components/charts/LineChart';
import { formatCandleData } from '@global-components/charts/LineChart/util';

import { Asset } from '@shared/api';
import { Candle } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';
import { RatesStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import UserService from '@services/UserService';

import { MoversTileFilter } from '@Dashboard/components/Tiles/MoversTile/MoversTile';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { HIDE_CHART_WIDTH, HIDE_MONTH_WIDTH, HIDE_WEEK_WIDTH } from './AssetRow.consts';

const labelTypographyProps: NumericProps = {
  weight: 'emphasis',
  className: 'text-right',
};

type Props = {
  selected?: boolean;
  asset: Asset;
  idKey: string;
  height?: number;
  width: number;
  isAppHeader?: boolean;
  isFavourite?: boolean;
  canUnFavourite?: boolean;
  baseAsset?: Asset;
  assetData?: Candle[];
  onSelectAsset: (asset: Asset) => void;
};

const CHART_WIDTH = 65;
const CHART_HEIGHT = 20;

const AssetRow: React.FC<Props> = observer(
  ({
    width,
    asset,
    baseAsset,
    selected,
    isAppHeader,
    isFavourite,
    idKey,
    canUnFavourite = true,
    assetData,
    onSelectAsset,
  }) => {
    const { getRate } = RatesStore.useRatesStore;
    const rate = getRate(asset.id);
    const priceChangeDay = rate?.dailyPriceChange;
    const { setFavourites } = UserStore.useUserStore;
    const { t } = useTranslation('dashboard');

    const hideRank = width < HIDE_MONTH_WIDTH;
    const hideMonth = width < HIDE_MONTH_WIDTH;
    const hideWeek = width < HIDE_WEEK_WIDTH;
    const hideChart = width < HIDE_CHART_WIDTH;

    const theme = useTheme();
    const { pathname } = useLocation();
    const avo = useAvo();
    const shouldShowChart = !hideChart && baseAsset?.id !== asset.id;

    const handleClick = () => {
      // id key for favourites is 'favourites', and movers is 'moversTile-title-{tab}'
      // check that we are only sending movers event for movers tile clicks
      if (idKey.split('-').length > 1) {
        const currentPrice = rate?.midPrice;
        const percentageGain = getRate(asset.id).dailyPriceChange;
        avo.clickedAssetOnMoversDashboardTile({
          screen: pathname,
          tileFilter: idKey.split('-')[3] as MoversTileFilter,
          assetId: asset.id,
          assetCode: asset.code,
          assetPrice: currentPrice,
          percentageGain24h: percentageGain,
        });
      }

      onSelectAsset(asset);
    };

    const lineChart = useMemo<JSX.Element | null>(() => {
      if (!asset || !baseAsset || !assetData?.length) {
        return null;
      }

      const { formattedData, openPrice, closePrice } = formatCandleData(asset, baseAsset, assetData);

      // Sometimes price change comes in as undefined when it really can be seen that it shouldn't be.
      const saferPriceChange = (() => {
        if (priceChangeDay !== undefined) {
          return priceChangeDay;
        }

        if (openPrice !== undefined && closePrice !== undefined) {
          return closePrice - openPrice;
        }

        return undefined;
      })();

      const color = (() => {
        if (Big(saferPriceChange).gt(0)) {
          return theme.palette.success.main;
        }

        if (Big(saferPriceChange).eq(0)) {
          return theme.palette.grey[500];
        }

        return theme.palette.error.main;
      })();

      const bgColor = theme.palette.background.paper;
      const strokeWidth = 1;

      const now = DateTime.now();

      const updatedTime = t('favouritesTile.chartLastUpdated', {
        lastUpdated: now.startOf('hour').toLocaleString({
          timeStyle: 'short',
          dateStyle: 'short',
        }),
      });

      const nextUpdate = t('favouritesTile.chartNextUpdate', {
        nextUpdate: now.startOf('hour').plus({ hours: 1 }).toLocaleString({
          timeStyle: 'short',
          dateStyle: 'short',
        }),
      });

      return (
        <Tooltip
          content={
            <FlexLayout direction='column'>
              <Body color='white' weight='bold' size='small'>
                {updatedTime}
              </Body>
              <Body color='white' size='small'>
                {nextUpdate}
              </Body>
            </FlexLayout>
          }
        >
          <span>
            <LineChart
              idKey={`${idKey}-asset-row-${asset.id}`}
              chartData={formattedData || []}
              disableLoadingAnimation
              backgroundColor={bgColor}
              strokeWidth={strokeWidth}
              areaColor={color}
              height={CHART_HEIGHT}
              width={CHART_WIDTH}
            />
          </span>
        </Tooltip>
      );
    }, [
      asset,
      baseAsset,
      assetData,
      theme.palette.background.paper,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.grey,
      t,
      idKey,
      priceChangeDay,
    ]);

    return (
      <FlexLayout
        alignItems='center'
        justifyContent='space-between'
        className={`w-full cursor-pointer  px-16 py-8 @container hover:bg-color-background-surface-hover ${
          selected ? '!bg-color-background-surface-active' : ''
        }`}
        onClick={handleClick}
      >
        <FlexLayout spacing={0} className='@xs:w-[70%] @sm:w-[calc(50%+10px)]' justifyContent='start'>
          {!hideRank && (
            <Body color='secondary' size='small' className='min-w-[60px] text-left'>
              {asset.rank === Infinity ? 'N/A' : asset.rank}
            </Body>
          )}
          <FlexLayout
            className={`min-w-[150px] ${isAppHeader ? 'w-full' : 'w-[150px]'} `}
            direction='row'
            spacing={8}
            alignItems='center'
          >
            {isFavourite && (
              <Tooltip title={canUnFavourite ? t('favouritesTile.labels.remove') : ''} align='start'>
                <span>
                  <Button
                    onClick={(e) => {
                      if (!canUnFavourite) return;

                      e.stopPropagation();
                      UserService.UpdateUserSettings({
                        data: { favouriteAsset: { assetId: asset.id, favStatus: false } },
                      });
                      setFavourites(asset.id, false);
                    }}
                    layout='icon'
                    size='sm'
                    variant='ghost'
                    leadingIcon={<StarFilled className='h-16 w-16 text-color-text-info' />}
                  />
                </span>
              </Tooltip>
            )}
            <AssetIcon asset={asset} size={20} />
            <Body size='small'>{isAppHeader ? asset.name : asset.code}</Body>
          </FlexLayout>
          <AssetPriceText asset={asset} liveChanges typographyProps={labelTypographyProps} />
        </FlexLayout>

        <div className='min-w-[80px] pr-8'>
          <PercentageIncrease amount={priceChangeDay} typographyProps={labelTypographyProps} />
        </div>

        {!hideWeek && (
          <div className='min-w-[80px] pr-8'>
            <PercentageIncrease
              typographyProps={labelTypographyProps}
              defaultColor='secondary'
              amount={Big(asset.priceChange.week).toString()}
            />
          </div>
        )}
        {!hideMonth && (
          <div className='min-w-[80px] pr-8'>
            <PercentageIncrease
              typographyProps={labelTypographyProps}
              defaultColor='secondary'
              amount={Big(asset.priceChange.month).toString()}
            />
          </div>
        )}
        {!hideChart && (
          <FlexLayout className='min-w-[80px]' justifyContent='end'>
            {shouldShowChart ? lineChart : null}
          </FlexLayout>
        )}
      </FlexLayout>
    );
  },
);

export { AssetRow };

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import { GridProps, GridSize } from '@mui/material';

import { Grid } from '@swyftx/react-web-design-system';

import { useGridContentBreakpoints } from '@hooks/Grid/useGridContentBreakpoints';

import { observer } from 'mobx-react-lite';

interface Props extends GridProps {
  // To force non boolean
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

type BreakpointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const LayoutGridItem: React.FC<Props> = observer((props) => {
  const { xs, sm, md, lg, xl } = props;

  const getFallbackBreakpoint = (name: BreakpointName) => {
    if (name === 'xl') {
      return xl || lg || md || sm || xs || 'auto';
    }

    if (name === 'lg') {
      return lg || md || sm || xs || 'auto';
    }

    if (name === 'md') {
      return md || sm || xs || 'auto';
    }

    if (name === 'sm') {
      return sm || xs || 'auto';
    }

    if (name === 'xs') {
      return xs || 'auto';
    }

    return 'auto';
  };

  const safeBreakpoints = {
    xl: getFallbackBreakpoint('xl'),
    lg: getFallbackBreakpoint('lg'),
    md: getFallbackBreakpoint('md'),
    sm: getFallbackBreakpoint('sm'),
    xs: getFallbackBreakpoint('xs'),
  };

  const gridBreakpoint = useGridContentBreakpoints(safeBreakpoints);

  return (
    <Grid
      item
      width='100% !important'
      {...props}
      xs={gridBreakpoint}
      sm={gridBreakpoint}
      md={gridBreakpoint}
      lg={gridBreakpoint}
      xl={gridBreakpoint}
    />
  );
});

// TODO refactor all of this and mvoe to aviary utils

import { UIStore } from '@shared/store';

import { DateTime } from 'luxon';

const shortTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const formatDate = (date: number | Date) => {
  const { dateFormat } = UIStore.useUIStore;

  const formatted = DateTime.fromJSDate(new Date(date)).toFormat(dateFormat);
  return formatted;
};

export const formatTime = (time: number | Date) => {
  const options: Intl.DateTimeFormatOptions = { ...shortTime };
  const formatted = new Date(time).toLocaleTimeString([], options);
  return formatted;
};

export const formatDateTime = (dateTime: number | Date) => {
  const { dateFormat } = UIStore.useUIStore;

  const formatted = DateTime.fromJSDate(new Date(dateTime)).toFormat(`${dateFormat} hh:mma`);
  return formatted;
};

export const getDateFormatString = () => {
  const { locale } = Intl.Collator().resolvedOptions();

  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return obj.value;
      }
    })
    .join('');
};

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
  { unit: 'year', ms: 31536000000 },
  { unit: 'month', ms: 2628000000 },
  { unit: 'day', ms: 86400000 },
  { unit: 'hour', ms: 3600000 },
  { unit: 'minute', ms: 60000 },
  { unit: 'second', ms: 1000 },
];

// when language toggle is live, check for set language and pass it in ('en' or 'fr')
const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

const relativeTimeFromElapsed = (elapsed: number): string => {
  for (const { unit, ms } of units) {
    if (Math.abs(elapsed) >= ms || unit === 'second') {
      return rtf.format(Math.round(elapsed / ms), unit);
    }
  }
  return '';
};

// return relative time from now
export const relativeTimeFromDates = (relative: Date | null, pivot: Date = new Date()): string => {
  if (!relative) return '';
  const elapsed = relative.getTime() - pivot.getTime();
  return relativeTimeFromElapsed(elapsed);
};

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { useTheme } from '@hooks/useTheme';

import { currentVersion, VIEWED_WHATS_NEW_VERSION, whatsNewChangeLog } from 'src/config/whats-new.data';
import { Release } from 'src/config/whats-new.type';

type Props = {
  onClose: () => void;
  customRelease?: Release;
};

const OVERVIEW_INDEX = -1;

const WhatsNewModal: React.FC<Props> = ({ customRelease, onClose }) => {
  const changelog = customRelease ?? whatsNewChangeLog[currentVersion];
  const [curFeature, setCurFeature] = useState<number>(changelog.overview ? OVERVIEW_INDEX : 0);
  const { t } = useTranslation('modals');
  const { theme } = useTheme();

  const image = useMemo(() => {
    if (curFeature === OVERVIEW_INDEX) return changelog.imgSrc;
    return changelog.features[curFeature].imgSrc;
  }, [curFeature, changelog]);

  const preTitle = useMemo(() => {
    if (curFeature === OVERVIEW_INDEX) return '';
    return changelog.features[curFeature].preTitle;
  }, [curFeature, changelog]);

  const title = useMemo(() => {
    if (curFeature === OVERVIEW_INDEX) return t('whatsNew.overviewTitle');
    return changelog.features[curFeature].title;
  }, [curFeature, changelog, t]);

  const features = useMemo(() => {
    if (curFeature === OVERVIEW_INDEX) return changelog.overview;
    return changelog.features[curFeature].descriptions;
  }, [curFeature, changelog]);

  const curPage = useMemo(() => {
    const indexAdjustment = changelog.overview ? 2 : 1;

    if (!changelog.overview && changelog.features.length === 1) return '';

    return curFeature + indexAdjustment;
  }, [curFeature, changelog]);

  const totalPages = useMemo(() => {
    const indexAdjustment = changelog.overview ? 1 : 0;

    if (!changelog.overview && changelog.features.length === 1) return '';

    return ` of ${changelog.features.length + indexAdjustment}`;
  }, [changelog]);

  const onCloseModal = useCallback(() => {
    localStorage.setItem(VIEWED_WHATS_NEW_VERSION, currentVersion.toString());
    if (onClose) onClose();
  }, [onClose]);

  const onNext = useCallback(() => {
    if (curFeature + 1 === changelog.features.length) {
      onCloseModal();
      return;
    }

    setCurFeature(curFeature + 1);
  }, [changelog.features.length, curFeature, onCloseModal]);

  const renderFeatures = useCallback(() => {
    const items = features?.map((feature) => {
      if (typeof feature === 'string') {
        return (
          <li key={feature} style={{ color: theme.palette.text.secondary }}>
            {feature}
          </li>
        );
      }
    });

    if (changelog.features[curFeature].orderedListDescription) {
      return <ol>{items}</ol>;
    }

    return <ul>{items}</ul>;
  }, [features, changelog.features, curFeature, theme.palette.text.secondary]);

  return (
    <Modal
      open
      id='whats-new-modal'
      HeaderProps={{
        title: changelog.title || t('whatsNew.title'),
        dismissible: true,
      }}
      sx={{
        width: '600px',
        '.MuiCardContent-root': {
          height: '100%',
          '> .MuiBox-root': { flexGrow: 1 },
          '> .MuiBox-root ~ .MuiBox-root': { flexGrow: 0 },
        },
      }}
      FooterProps={{
        content: (
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
            <Typography color='text.primary' fontWeight={500} fontSize={14}>
              {curPage}
              <Typography color='text.secondary' display='inline' fontSize={14}>
                {totalPages}
              </Typography>
            </Typography>
            <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
              {curFeature < changelog.features.length - 1 && (
                <Button variant='text' color='inherit' size='large' onClick={onCloseModal} disableElevation>
                  {t('whatsNew.buttonLabels.skip')}
                </Button>
              )}
              <Button variant='contained' color='primary' size='large' onClick={onNext} disableElevation>
                {curFeature === changelog.features.length - 1
                  ? t('whatsNew.buttonLabels.continue')
                  : t('whatsNew.buttonLabels.next')}
              </Button>
            </Stack>
          </Stack>
        ),
      }}
      onClose={onCloseModal}
    >
      <Stack direction='column' alignItems='flex-start' justifyContent='center' spacing={2}>
        {image && (
          <Box
            sx={{
              background: `url(${image})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            width='100%'
            height={{ xs: '135px', sm: '286px', md: '206px' }}
            borderRadius={2}
          />
        )}

        {title && (
          <Typography fontWeight={600} fontSize={16}>
            {preTitle && (
              <Typography color='primary' fontWeight={600} fontSize={16} display='inline'>
                {preTitle}{' '}
              </Typography>
            )}
            {title}
          </Typography>
        )}
        {features && features.length === 1 && <Typography color='text.secondary'>{features[0]}</Typography>}
        {features && features.length > 1 && renderFeatures()}
      </Stack>
    </Modal>
  );
};

export { WhatsNewModal };

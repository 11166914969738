/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { assetService, balanceService, portfolioService, ratesService, userStatisticsService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

const CurrencyDropdown: React.FC = observer(() => {
  const { setUserCurrency } = UserStore.useUserStore;
  const avo = useAvo();
  const baseAsset = useBaseAsset();
  const { pathname } = useLocation();
  const { setLoading } = RatesStore.useRatesStore;
  const isXs = useTailwindBreakpoint('xs');

  const items = useMemo(() => {
    const baseAssets = assetService.getBaseAssets();
    const selectItems: SelectItemData[] = [];

    baseAssets.forEach((assetId) => {
      const asset = assetService.getAsset(assetId);

      if (asset)
        selectItems.push({
          leadingIcon: <AssetIcon asset={asset.code} size={16} />,
          label: isXs ? asset.code : asset.name,
          value: asset.code,
        });
    });

    return selectItems;
  }, [isXs]);

  const onSelectCurrency = useCallback(
    async (assetCode: string) => {
      const currentCurrency = baseAsset;
      const asset = assetService.getAssetByCode(assetCode);

      if (!asset || !currentCurrency) return;

      // Fire event for chart analytics: base currency selected
      // `assetId` and `assetCode` correspond to the base currency being selected.
      avo.baseCurrencySelected({
        screen: pathname,
        newBaseCurrencyId: asset.id,
        newBaseCurrencyCode: asset.code,
        layout: isXs ? 'mobile' : 'desktop',
      });

      try {
        setLoading(true);
        setUserCurrency(asset.id, asset.code);
        await UserService.UpdateUserCurrency(asset.id);
      } catch (e) {
        setUserCurrency(currentCurrency.id, currentCurrency.code);
      }

      // get latest rates before updating other services
      await ratesService.forceUpdate();

      const updateValuesPromise = [
        balanceService.forceUpdate(),
        portfolioService.forceUpdate(),
        userStatisticsService.forceUpdate(),
      ];

      await Promise.all(updateValuesPromise);

      setLoading(false);
    },
    [avo, baseAsset, isXs, pathname, setLoading, setUserCurrency],
  );

  if (baseAsset === undefined || !items.length) return null;

  return (
    <FlexLayout className='ml-8 w-full'>
      <Select
        title='Currency'
        showInputTitle={false}
        className='w-[8rem] sm:w-[14rem]'
        value={baseAsset.code}
        items={items}
        onValueChange={onSelectCurrency}
      />
    </FlexLayout>
  );
});

CurrencyDropdown.displayName = 'CurrencyDropdown';

export default CurrencyDropdown;

export type OrderBookType = 'bid' | 'ask';
export type OrderBookValueType = 'price' | 'qty' | 'total';
export type ProPanels = 'chart' | 'orderbook' | 'wallets' | 'openOrders' | 'orderHistory' | 'trade';
export type MarketOcoTab = 'price' | 'percent' | 'total';

export const panelName: { [key in ProPanels]: string } = {
  chart: 'chart',
  orderbook: 'Order book',
  wallets: 'Wallets',
  openOrders: 'Open Orders',
  orderHistory: 'Order History',
  trade: 'Place order',
};

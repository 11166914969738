import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { NoChartError } from '@global-components/Errors/NoChartError';
import { LineChart } from '@global-components/charts/LineChart';
import { usePortfolioLineChartData } from '@global-components/charts/LineChart/hooks/portfolioLineDataHooks';

import { Asset } from '@shared/api';

import { AssetColors } from '@utils/assets';

import Color from 'color';
import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  baseAsset: Asset;
};

const WalletListRowLineChart: React.FC<Props> = observer(({ asset }) => {
  const startDate = useMemo(() => {
    const date = new Date(new Date().setDate(new Date().getDate() - 7));
    return date;
  }, []);

  const endDate = useMemo(() => new Date(), []);
  const { isLightMode } = useTailwindTheme();

  const { formattedData, loading, error } = usePortfolioLineChartData(startDate, endDate, asset.id);

  const lineChart = useMemo(() => {
    const idKey = asset.id;
    const bgColor = 'var(--color-background-surface-primary)';
    let assetColor = AssetColors?.[asset.code] ? Color(AssetColors?.[asset.code]) : undefined;

    if (!isLightMode && assetColor) {
      assetColor = assetColor.lightness(40);
    }

    const areaColor = assetColor ?? undefined;

    if (formattedData?.length || loading) {
      return (
        <LineChart
          areaColor={areaColor?.hex() || bgColor}
          chartData={formattedData || []}
          backgroundColor={bgColor}
          loading={loading}
          height={50}
          width={120}
          idKey={idKey}
        />
      );
    }

    if (error) {
      return <NoChartError hideTitle subTitleProps={{ fontSize: 14 }} />;
    }
    return null;
  }, [asset.id, asset.code, isLightMode, formattedData, loading, error]);

  return (
    <div className='h-[50px] pb-0 @md:w-[120px] @lg:w-[155px]'>
      <FlexLayout className='h-full w-full' direction='column' justifyContent='center'>
        {lineChart}
      </FlexLayout>
    </div>
  );
});

export { WalletListRowLineChart };

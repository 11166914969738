import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Add: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M13 2.75C13 2.33579 12.6642 2 12.25 2C11.8358 2 11.5 2.33579 11.5 2.75V11.75H2.75C2.33579 11.75 2 12.0858 2 12.5C2 12.9142 2.33579 13.25 2.75 13.25H11.5V21.25C11.5 21.6642 11.8358 22 12.25 22C12.6642 22 13 21.6642 13 21.25V13.25H21.25C21.6642 13.25 22 12.9142 22 12.5C22 12.0858 21.6642 11.75 21.25 11.75H13V2.75Z'
      fill='currentColor'
    />
  </svg>
);

export { Add };

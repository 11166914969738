import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/icons/outlined';
import { Stack } from '@swyftx/react-web-design-system';

import { UniversalTradeStore } from '@shared/store';
import { TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { TradeOrderDetailsAssetsContainer } from './components/TradeOrderDetailsAssetContainer';

const TradeOrderDetailsContainer: React.FC = observer(() => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });
  const { tradeFrom, tradeTo, swapTradeAssets } = UniversalTradeStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const swapsEnable = isFeatureEnabled(AppFeature.Swaps);
  const { getAssetById } = useMarkets();
  const avo = useAvo();
  const { pathname } = useLocation();

  const getAssetDetails = (assetIds: number[]) =>
    assetIds
      .map((assetId) => {
        const asset = getAssetById(assetId);
        if (!asset) {
          return undefined;
        }
        return { assetCode: asset.code, assetId: asset.id };
      })
      .filter((asset): asset is { assetCode: string; assetId: number } => asset !== undefined);

  return (
    <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={swapsEnable ? 1 : 3}>
      <TradeOrderDetailsAssetsContainer title={t('labels.tradeFrom')} side={TradeSide.From} />
      {swapsEnable && (
        <FlexLayout className='z-1 relative !-mb-20 items-center justify-center'>
          <Button
            layout='icon'
            leadingIcon={<Switch />}
            variant='outlined'
            tooltip={t('labels.swap')}
            onClick={() => {
              const getValidAssets = (assets: number[]) =>
                getAssetDetails(assets).filter(
                  (asset): asset is { assetCode: string; assetId: number } => asset !== undefined,
                );

              const fromAssetsBefore = getValidAssets(tradeFrom);
              const toAssetsBefore = getValidAssets(tradeTo);
              const fromAssetsAfter = getValidAssets(tradeTo);
              const toAssetsAfter = getValidAssets(tradeFrom);

              if (fromAssetsBefore.length && toAssetsBefore.length && fromAssetsAfter.length && toAssetsAfter.length) {
                avo.assetSwitcherClicked({
                  screen: pathname,
                  fromAssetsAfter,
                  toAssetsAfter,
                  fromAssetsBefore,
                  toAssetsBefore,
                });
              }
              swapTradeAssets();
            }}
            disabled={!tradeFrom.length && !tradeTo.length}
          />
        </FlexLayout>
      )}
      <TradeOrderDetailsAssetsContainer title={t('labels.tradeTo')} side={TradeSide.To} />
    </Stack>
  );
});

TradeOrderDetailsContainer.displayName = 'TradeOrderDetailsContainer';

export { TradeOrderDetailsContainer };

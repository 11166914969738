import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Marketing } from '@swyftx/aviary/icons/outlined';

import { NewsType } from './NewsWidgetCard.types';

type Props = {
  newsType?: NewsType;
  showBorder?: boolean;
};

const NewsWidgetCardEmpty: React.FC<Props> = ({ newsType, showBorder = false }) => {
  if (!newsType) return null;

  return (
    <Card className='h-full min-h-[280px] w-full p-24' border={showBorder}>
      <FlexLayout
        className='h-full min-h-[280px] w-full'
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={16}
      >
        <Marketing className='h-48 w-48 text-color-text-info' />
        {newsType === 'mine' && (
          <Body size='small' weight='emphasis' color='secondary' className='text-center'>
            Favourite some assets to see news content here
          </Body>
        )}
        {newsType === 'asset' && (
          <Body size='small' weight='emphasis' color='secondary' className='text-center'>
            No more news article found. Check back later
          </Body>
        )}
        {newsType === 'all' && (
          <Body size='small' weight='emphasis' color='secondary' className='text-center'>
            Error fetching news. Please try again later
          </Body>
        )}
      </FlexLayout>
    </Card>
  );
};

export { NewsWidgetCardEmpty };

import React, { useCallback, useMemo, useState } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { HelpTooltip } from '@swyftx/aviary/molecules/HelpTooltip/HelpTooltip';
import { RadioGroup } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

import { CorporateTrustForm, IndividualTrustForm, TrustForm } from '@shared/api';
import { SourceOfWealthOption } from '@shared/api/@types/compliance';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import {
  HumanReadablePurposeOfAccount,
  HumanReadableSourceOfWealthOption,
} from '@routes/EntityOnboarding/types/EntityApplicationForm.types';
import { yesNoRadioGroup } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';
import { getRadioSelectedValue } from '@routes/EntityOnboarding/utils/getRadioSelectedValue';

import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { SupplyInformationStepComplianceDisclaimers } from './SupplyInformationStepComplianceDisclaimers';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { ConfirmChangeTrustTypeModal } from '../../modals/ConfirmChangeTrustTypeModal';
import { trustInformationSchema } from '../AddMembersStep/schemas/TrustInformationSchema';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

type FormInputs = Omit<CorporateTrustForm, 'directors' | 'beneficiaries' | 'shareholders'>;

export const TrustSupplyInformationStep: React.FC = observer(() => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as TrustForm;
  const completedSteps = useEntityOnboardingSelector((state) => state.context.completedSteps);
  const prevHasCorporateTrustee = useMemo(() => {
    if (completedSteps['trustInformation']) {
      return applicationData.type === 'TRUST_CORPORATE';
    }
    return undefined;
  }, [applicationData.type, completedSteps]);
  const [hasCorporateTrustee, setHasCorporateTrustee] = useState<boolean | undefined>(prevHasCorporateTrustee);
  const [hasThirdPartyCustomers, setHasThirdPartyCustomers] = useState<boolean | undefined>(
    completedSteps['trustInformation'] ? false : undefined,
  );

  const [confirmChangeTrustTypeOpen, setConfirmChangeTrustTypeOpen] = useState<boolean>(false);
  const [tradesArmsOilOrPreciousMats, setTradesArmsOilOrPreciousMats] = useState<boolean | undefined>(
    'tradesArmsOilOrPreciousMats' in applicationData
      ? applicationData.tradesArmsOilOrPreciousMats ?? undefined
      : undefined,
  );

  const [otherSourceOfWealthSelected, setOtherSourceOfWealthSelected] = useState<boolean>(
    'sourceOfWealth' in applicationData && applicationData.sourceOfWealth
      ? Object.keys(SourceOfWealthOption).includes(applicationData.sourceOfWealth)
        ? true
        : false
      : false,
  );

  const {
    control,
    handleSubmit,
    watch,
    reset: resetForm,
    formState: { errors, isDirty: currentFormIsDirty },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      type: 'TRUST_CORPORATE',
      businessActivity: (applicationData as CorporateTrustForm).businessActivity,
      purposeOfAccount: (applicationData as CorporateTrustForm).purposeOfAccount,
      sourceOfWealth: (applicationData as CorporateTrustForm).sourceOfWealth,
      tradesArmsOilOrPreciousMats: (applicationData as CorporateTrustForm).tradesArmsOilOrPreciousMats,
    },
    resolver: zodResolver(trustInformationSchema, undefined, { rawValues: true }),
  });

  const nextStepsInFormAreDirty = useMemo(() => {
    if (currentFormIsDirty) return true;
    if (
      applicationData.type === 'TRUST_CORPORATE' &&
      (applicationData.businessActivity ||
        applicationData.purposeOfAccount ||
        applicationData.sourceOfWealth ||
        applicationData.tradesArmsOilOrPreciousMats ||
        applicationData.directors?.length ||
        applicationData.shareholders?.length ||
        applicationData.beneficiaries?.length)
    ) {
      return true;
    }
    if (
      applicationData.type === 'TRUST_INDIVIDUAL' &&
      (applicationData.trustees?.length || applicationData.beneficiaries?.length)
    ) {
      return true;
    }
    return false;
  }, [applicationData, currentFormIsDirty]);

  const businessActivity = watch('businessActivity');
  const purposeOfAccount = watch('purposeOfAccount');
  const sourceOfWealth = watch('sourceOfWealth');

  const disabled = useMemo(() => {
    if (hasCorporateTrustee === undefined) return true;
    if (hasCorporateTrustee === false) return false;
    if (hasThirdPartyCustomers !== false || tradesArmsOilOrPreciousMats === undefined) return true;
    return !businessActivity || !purposeOfAccount || !sourceOfWealth;
  }, [
    businessActivity,
    hasCorporateTrustee,
    hasThirdPartyCustomers,
    tradesArmsOilOrPreciousMats,
    purposeOfAccount,
    sourceOfWealth,
  ]);

  const onChangeTrustType = useCallback(() => {
    const prevValueHasCorporateTrustee = hasCorporateTrustee;
    setConfirmChangeTrustTypeOpen(false);
    resetForm();
    entityOnboardingService.send({
      type: 'CHANGE_TYPE',
      data: {
        type: prevValueHasCorporateTrustee ? 'TRUST_INDIVIDUAL' : 'TRUST_CORPORATE',
      },
    });
    setHasCorporateTrustee(!prevValueHasCorporateTrustee);
  }, [entityOnboardingService, hasCorporateTrustee, resetForm]);

  const handleRadioOnChange = useCallback(
    (newValue: boolean) => {
      if (nextStepsInFormAreDirty || (completedSteps['trustInformation'] && newValue !== hasCorporateTrustee)) {
        return setConfirmChangeTrustTypeOpen(true);
      }
      onChangeTrustType();
      setHasCorporateTrustee(newValue);
    },
    [completedSteps, hasCorporateTrustee, nextStepsInFormAreDirty, onChangeTrustType],
  );

  const handlePageSubmit = useCallback(
    (data?: FormInputs) => {
      if (hasCorporateTrustee === false) {
        return entityOnboardingService.send({
          type: 'NEXT',
          data: { type: 'TRUST_INDIVIDUAL' } as IndividualTrustForm,
        });
      }
      return entityOnboardingService.send({
        type: 'NEXT',
        data: { ...data, tradesArmsOilOrPreciousMats } as CorporateTrustForm,
      });
    },
    [entityOnboardingService, hasCorporateTrustee, tradesArmsOilOrPreciousMats],
  );

  return (
    <>
      <EntityOnboardingStepContainer
        customTitle='Trust information'
        submitDisabled={disabled}
        onSubmit={hasCorporateTrustee ? handleSubmit((data) => handlePageSubmit(data)) : () => handlePageSubmit()}
      >
        <EntityOnboardingPageLayout subtitle='Provide some basic information about your Trust. We are required to collect this for regulatory purposes.'>
          <FlexLayout direction='column' spacing={12}>
            <FlexLayout direction='row' spacing={8}>
              <Body size='small' weight='emphasis'>
                1. Do you have a company (Pty Ltd) that acts as a trustee for your trust?
              </Body>
              <HelpTooltip tooltipText='Trustees can be found in the schedule of your Trust Deed.' />
            </FlexLayout>
            <RadioGroup
              checkboxes={yesNoRadioGroup}
              selected={getRadioSelectedValue(hasCorporateTrustee)}
              onChange={(value) => handleRadioOnChange(value === 'yes')}
            />
          </FlexLayout>
          {hasCorporateTrustee && (
            <FlexLayout direction='column' spacing={32}>
              <FlexLayout direction='column' spacing={4}>
                <Body size='small' weight='emphasis'>
                  2. What is the nature of your corporate trustee?
                </Body>
                <Controller
                  name='businessActivity'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      placeholder='eg. Ecommerce store'
                      error={!!errors.businessActivity}
                    />
                  )}
                />
                {errors.businessActivity && (
                  <Body size='small' color='error'>
                    {errors.businessActivity.message}
                  </Body>
                )}
              </FlexLayout>
              <FlexLayout direction='column' spacing={4}>
                <Body size='small' weight='emphasis'>
                  3. What is the purpose of using Swyftx?
                </Body>
                <Controller
                  name='purposeOfAccount'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      items={Object.entries(HumanReadablePurposeOfAccount).map(([key, value]) => ({
                        label: value,
                        value: key,
                      }))}
                      placeholder='Please select an option'
                      value={field.value}
                      onValueChange={(value) => {
                        if (value === '') return;
                        field.onChange(value);
                      }}
                      error={!!errors.purposeOfAccount}
                    />
                  )}
                />
                {errors.purposeOfAccount && (
                  <Body size='small' color='error'>
                    {errors.purposeOfAccount.message}
                  </Body>
                )}
              </FlexLayout>
              <FlexLayout direction='column' spacing={4}>
                <Body size='small' weight='emphasis'>
                  4. What is the corporate trustees primary Source of Wealth?
                </Body>
                <Controller
                  name='sourceOfWealth'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      items={[
                        ...Object.entries(HumanReadableSourceOfWealthOption).map(([key, value]) => ({
                          label: value,
                          value: key,
                        })),
                        { label: 'Other', value: 'OTHER' },
                      ]}
                      placeholder='Please select an option'
                      value={otherSourceOfWealthSelected ? 'OTHER' : field.value}
                      onValueChange={(value) => {
                        if (value === '') return;
                        if (value === 'OTHER') {
                          setOtherSourceOfWealthSelected(true);
                          field.onChange(' ');
                        } else {
                          setOtherSourceOfWealthSelected(false);
                          field.onChange(value);
                        }
                      }}
                      error={!!errors.sourceOfWealth}
                    />
                  )}
                />
                {errors.sourceOfWealth && (
                  <Body size='small' color='error'>
                    {errors.sourceOfWealth.message}
                  </Body>
                )}
              </FlexLayout>
              {otherSourceOfWealthSelected && (
                <FlexLayout direction='column' spacing={4}>
                  <Body size='small' weight='emphasis'>
                    4.1. Please specify your other source of Wealth
                  </Body>
                  <Controller
                    name='sourceOfWealth'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        value={field.value}
                        onChange={field.onChange}
                        placeholder='e.g Inheritance'
                        error={!!errors.sourceOfWealth}
                      />
                    )}
                  />
                  {errors.sourceOfWealth && (
                    <Body size='small' color='error'>
                      {errors.sourceOfWealth.message}
                    </Body>
                  )}
                </FlexLayout>
              )}
              <SupplyInformationStepComplianceDisclaimers
                entityType='trust'
                hasThirdPartyCustomers={hasThirdPartyCustomers}
                setHasThirdPartyCustomers={setHasThirdPartyCustomers}
                tradesArmsOilOrPreciousMats={tradesArmsOilOrPreciousMats}
                setTradesArmsOilOrPreciousMats={setTradesArmsOilOrPreciousMats}
              />
            </FlexLayout>
          )}
        </EntityOnboardingPageLayout>
      </EntityOnboardingStepContainer>
      {confirmChangeTrustTypeOpen && (
        <ConfirmChangeTrustTypeModal
          setOpen={setConfirmChangeTrustTypeOpen}
          open={confirmChangeTrustTypeOpen}
          onConfirm={onChangeTrustType}
        />
      )}
    </>
  );
});

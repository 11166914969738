import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CountriesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { useQuery } from '@hooks/useQuery';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { RegisterContext } from '../Register.context';
import { Welcome, CreateAccountForm, VerifyPhoneNumber, VerifyEmailAddress, SetPasswordForm } from '../components';
import { AffiliatesEnum, SignUpFormValues } from '../types';

const useRegister = () => {
  const { userProfile } = UserStore.useUserStore;
  const query = useQuery();

  const {
    registerStep,
    setReferralCode,
    setPromoRef,
    setAffiliate,
    registerStepsConfig,
    isFirstStep,
    isComplete,
    captchaToken,
    setCaptchaToken,
    setCaptchaRefresh,
    captchaRefresh,
  } = useContext(RegisterContext);

  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const applyForEntityAccount = searchParams.get('accountType') === 'entity';

  const [formData, setFormData] = useState<SignUpFormValues>({
    country: undefined,
    countryCode: CountriesEnum.AU,
    email: '',
    firstName: '',
    lastName: '',
    mobileNumberCountry: CountriesEnum.AU,
    mobileNumber: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
    verifiedEmail: false,
    verifiedPhone: false,
    captchaToken: '',
    applyForEntityAccount,
  });

  const { navigate } = useNavigateRoute();
  const avo = useAvo();

  // Automatically redirect if the userProfile exists
  useEffect(() => {
    if (userProfile && !isComplete()) {
      navigate(NavigationURLs.Dashboard);
    }
  }, [isComplete, navigate, userProfile]);

  // Track if user has proceeded past the first step of registration flow
  const hasTrackedNext = useRef(false);
  useEffect(() => {
    if (!hasTrackedNext.current && !isFirstStep()) {
      hasTrackedNext.current = true;
      avo.registerStarted({
        registrationAccountTypeSelected: formData.applyForEntityAccount ? 'entity' : 'personal',
        country: formData.countryCode,
      });
    }
  }, [registerStep, isFirstStep, pathname, avo, formData.applyForEntityAccount, formData.countryCode]);

  // Detect promo reference and referral code from URL
  useEffect(() => {
    const urlReferralCode = query.get('ref');
    const urlPromoRef = query.get('promoRef');

    if (urlReferralCode) {
      setReferralCode(urlReferralCode);

      // Check if the code is related to one of the affiliates
      const codeIsAffiliate =
        Object.values(AffiliatesEnum).filter(
          (affiliateCode) => affiliateCode.toLowerCase() === urlReferralCode.toLowerCase(),
        ).length > 0;

      if (codeIsAffiliate) {
        setAffiliate(urlReferralCode.toLowerCase() as AffiliatesEnum);
      } else {
        setAffiliate(undefined);
      }
    } else {
      setReferralCode('');
    }

    if (urlPromoRef) {
      setPromoRef(urlPromoRef);
    } else {
      setPromoRef('');
    }
  }, [query, setReferralCode, setPromoRef, setAffiliate]);

  const registerStepElement = useMemo<JSX.Element | null>(() => {
    const stepConfig = registerStepsConfig[registerStep];

    // All steps completed show welcome page
    if (isComplete()) {
      return <Welcome />;
    }

    switch (stepConfig.type) {
      case 'CreateAccountForm':
        return (
          <CreateAccountForm
            captchaToken={captchaToken}
            setCaptchaToken={setCaptchaToken}
            setCaptchaRefresh={setCaptchaRefresh}
            captchaRefresh={captchaRefresh}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'VerifyPhoneNumber':
        return (
          <VerifyPhoneNumber
            captchaToken={captchaToken}
            setCaptchaToken={setCaptchaToken}
            setCaptchaRefresh={setCaptchaRefresh}
            captchaRefresh={captchaRefresh}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'VerifyEmailAddress':
        return (
          <VerifyEmailAddress
            captchaToken={captchaToken}
            setCaptchaToken={setCaptchaToken}
            setCaptchaRefresh={setCaptchaRefresh}
            captchaRefresh={captchaRefresh}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'SetPasswordForm':
        return (
          <SetPasswordForm
            captchaToken={captchaToken}
            setCaptchaToken={setCaptchaToken}
            setCaptchaRefresh={setCaptchaRefresh}
            captchaRefresh={captchaRefresh}
            formData={formData}
            setFormData={setFormData}
          />
        );

      default:
        return null;
    }
  }, [registerStep, isComplete, registerStepsConfig, formData, captchaToken, setFormData]);

  return {
    captchaToken,
    setCaptchaToken,
    formData,
    setFormData,
    registerStepElement,
  };
};

export { useRegister };

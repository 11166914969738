import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ShieldPass: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.0101 22.1899C11.8901 22.1899 11.7601 22.1599 11.6501 22.0999L8.09008 20.1799C7.08008 19.6399 6.29008 19.0199 5.67008 18.3099C4.32008 16.7599 3.58008 14.7699 3.58008 12.7199V5.94992C3.58008 5.16992 4.08008 4.46992 4.81008 4.20992L11.3901 1.89992C11.7801 1.75992 12.2201 1.75992 12.6101 1.89992L19.1901 4.20992C19.9301 4.46992 20.4201 5.15992 20.4201 5.94992V12.7199C20.4201 14.7699 19.6801 16.7599 18.3301 18.3099C17.7101 19.0199 16.9201 19.6299 15.9101 20.1799L12.3501 22.0999C12.2401 22.1599 12.1201 22.1899 11.9901 22.1899H12.0101ZM12.0101 3.28992C12.0101 3.28992 11.9301 3.28992 11.9001 3.30992L5.31008 5.62992C5.17008 5.67992 5.08008 5.80992 5.08008 5.94992V12.7199C5.08008 14.4199 5.69008 16.0499 6.80008 17.3299C7.30008 17.8999 7.95008 18.3999 8.80008 18.8599L12.0101 20.5899L15.2101 18.8599C16.0601 18.3999 16.7101 17.8999 17.2101 17.3299C18.3201 16.0499 18.9301 14.4099 18.9301 12.7199V5.94992C18.9301 5.80992 18.8401 5.67992 18.7001 5.62992L12.1101 3.31992C12.1101 3.31992 12.0401 3.29992 12.0001 3.29992L12.0101 3.28992ZM11.4101 14.1099C11.2201 14.1099 11.0201 14.0399 10.8701 13.8999L8.95008 12.0599C8.65008 11.7699 8.65008 11.3099 8.95008 11.0199C9.24008 10.7299 9.72008 10.7299 10.0201 11.0199L11.4001 12.3499L14.7701 9.02992C15.0601 8.73992 15.5401 8.73992 15.8401 9.02992C16.1401 9.31992 16.1401 9.77992 15.8401 10.0699L11.9401 13.9099C11.7901 14.0599 11.6001 14.1299 11.4101 14.1299'
      fill='currentColor'
    />
  </svg>
);

export { ShieldPass };

import { useCallback, useContext } from 'react';

import { Asset, AssetHistoryItem, OrderType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { ISeriesApi, MouseEventParams, Time } from 'lightweight-charts';
import { DateTime } from 'luxon';

import { useSimpleChartTooltipUtils } from './useSimpleChartTooltipUtils';
import { TradingViewContext } from '../../context/TradingView.context';

type Props = {
  chartContainerRef: React.MutableRefObject<HTMLInputElement>;
  chartPriceTooltipRef: React.MutableRefObject<HTMLDivElement>;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  series?: ISeriesApi<'Area' | 'Bar' | 'Candlestick' | 'Line'>;
  transactions?: AssetHistoryItem[];
};

const toolTipWidth = 80;
const toolTipMargin = 50;
const Y_POS_BUFFER = -45;
const DEFAULT_Y_POS_OFFSET = 200;

const useSimpleChartPriceTooltip = ({
  chartContainerRef,
  chartPriceTooltipRef,
  primaryAsset,
  secondaryAsset,
  series,
  transactions,
}: Props) => {
  const { simpleResolution, showMarks } = useContext(TradingViewContext);
  const { isValidParam } = useSimpleChartTooltipUtils({ chartContainerRef });

  const getTransactionsByDate = useCallback(
    (time: DateTime) => {
      const filteredTransactions = transactions?.filter((t) => {
        const startTime = time.toMillis();
        const endTime =
          simpleResolution === '1D' ? time.plus({ day: 1 }) : time.plus({ minute: Number(simpleResolution) });

        if (t.date < startTime || t.date > endTime.toMillis()) {
          return false;
        }

        return true;
      });

      return {
        buys: filteredTransactions?.filter((t) =>
          [OrderType.StopBuy, OrderType.MarketBuy, OrderType.TriggerBuy, OrderType.TriggerBuy].includes(
            t.orderType || 0,
          ),
        ),
        sells: filteredTransactions?.filter((t) =>
          [OrderType.DustSell, OrderType.StopSell, OrderType.MarketSell, OrderType.TriggerSell].includes(
            t.orderType || 0,
          ),
        ),
      };
    },
    [transactions, simpleResolution],
  );

  const buildPriceTooltip = useCallback(
    (param: MouseEventParams<Time>) => {
      if (!isValidParam(param) || !series) {
        chartPriceTooltipRef.current.classList.add('hidden');
        return;
      }

      const date = DateTime.fromSeconds(param.time as number).minus({ minutes: DateTime.now().offset });
      const dateStr = date.toFormat(`dd LLL yyyy ${simpleResolution !== '1D' ? 'hh:mm a' : ''}`);
      chartPriceTooltipRef.current.classList.remove('hidden');
      const data = param.seriesData.get(series) as any;

      if (!data) return;

      const price = data.value !== undefined ? data.value : data.close;
      const priceStr = formatCurrency(Big(100).times(price).div(100), primaryAsset);

      const transactionMarkers = getTransactionsByDate(date);

      chartPriceTooltipRef.current.innerHTML = `
    <div class="flex flex-col w-full items-start gap-8 whitespace-nowrap">
      <div class="flex flex-col w-full items-start gap-0 whitespace-nowrap">
        <div class="font-mono text-10 line-height-12 letter-spacing-expanded text-color-text-secondary">${dateStr}</div>
        <div class="flex flex-row w-full items-start gap-4 whitespace-nowrap">
          <div class="font-mono text-12 line-height-16 letter-spacing-expanded text-color-text-primary font-500">${priceStr}</div>
          <div class="font-mono text-12 line-height-16 letter-spacing-expanded text-color-text-secondary">
            ${primaryAsset?.code}/${secondaryAsset?.code}
          </div>
        </div>
      </div>

      ${
        showMarks && (transactionMarkers.buys?.length || transactionMarkers.sells?.length)
          ? `
      <div class="flex flex-row w-full items-start gap-12">
      ${
        transactionMarkers.buys?.length
          ? `
          <div class="flex flex-row gap-4 items-center">
            <div class="font-mono text-10 line-height-12 letter-spacing-expanded font-500">Buys</div>

            <div class="p-4 flex items-center rounded-8 justify-center h-16 min-w-[1rem] bg-color-background-success">
              <div class="font-mono text-10 letter-spacing-expanded text-color-text-inverse">${transactionMarkers.buys?.length}</div>
            </div>
          </div>
          `
          : ''
      }

      ${
        transactionMarkers.sells?.length
          ? `
          <div class="flex flex-row gap-4 items-center">
            <div class="font-mono text-10 line-height-12 letter-spacing-expanded font-500">Sells</div>
            <div class="p-4 flex items-center rounded-8 justify-center h-16 min-w-[1rem] bg-color-background-error">
              <div class="font-mono text-10 letter-spacing-expanded text-color-text-inverse">${transactionMarkers.sells?.length}</div>
            </div>
          </div>
          `
          : ''
      }
      </div>
       `
          : ''
      }
    </div>`;

      const coordinate = series.priceToCoordinate(price);
      let shiftedCoordinate = (param.point?.x || 0) - toolTipMargin;
      if (coordinate === null) {
        return;
      }
      shiftedCoordinate = Math.max(
        0,
        Math.min(chartContainerRef.current.clientWidth - toolTipWidth, shiftedCoordinate),
      );
      const coordinateY = coordinate + toolTipMargin;

      const doesTooltipOverflowTop = coordinateY - DEFAULT_Y_POS_OFFSET < Y_POS_BUFFER;
      let yOffset = doesTooltipOverflowTop ? 0 : DEFAULT_Y_POS_OFFSET;

      chartPriceTooltipRef.current.style.left = shiftedCoordinate + 'px';
      chartPriceTooltipRef.current.style.top = `${coordinateY - yOffset}px`;
    },
    [
      isValidParam,
      series,
      simpleResolution,
      chartPriceTooltipRef,
      primaryAsset,
      getTransactionsByDate,
      secondaryAsset?.code,
      showMarks,
      chartContainerRef,
    ],
  );

  return {
    buildPriceTooltip,
  };
};

export { useSimpleChartPriceTooltip };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const EthStaking: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M11.3368 2.21973C6.74786 2.21973 3.0293 5.93934 3.0293 10.5272C3.0293 13.3954 4.48282 15.9236 6.69111 17.4156C7.0206 17.6382 7.46817 17.5516 7.69078 17.2221C7.9134 16.8926 7.82676 16.445 7.49727 16.2224C5.66853 14.9869 4.4693 12.8971 4.4693 10.5272C4.4693 6.73451 7.54327 3.65973 11.3368 3.65973C15.1293 3.65973 18.2041 6.73456 18.2041 10.5272C18.2041 10.9248 18.5265 11.2472 18.9241 11.2472C19.3218 11.2472 19.6441 10.9248 19.6441 10.5272C19.6441 5.93929 15.9246 2.21973 11.3368 2.21973Z'
      fill='currentColor'
    />
    <path
      d='M19.4571 12.7253C19.596 12.6853 19.7372 12.689 19.8659 12.7283C19.9141 12.7431 19.9607 12.7628 20.0046 12.7871C20.0133 12.7919 20.0218 12.7969 20.0302 12.802C20.1963 12.9028 20.3076 13.0608 20.3533 13.2361L20.9384 15.2692C21.0484 15.6513 20.8278 16.0503 20.4456 16.1603C20.0635 16.2702 19.6645 16.0496 19.5546 15.6675L19.4358 15.2548L17.5793 18.5038C17.4132 18.7945 17.0685 18.9323 16.7477 18.8363L12.5428 17.5774L10.6699 20.5985C10.4604 20.9365 10.0166 21.0406 9.67863 20.8311C9.34065 20.6216 9.23652 20.1778 9.44604 19.8398L11.6093 16.3502C11.7807 16.0738 12.1162 15.9465 12.4278 16.0398L16.6128 17.2928L18.1518 14.5993L17.8041 14.6994C17.4219 14.8094 17.023 14.5888 16.913 14.2066C16.8031 13.8245 17.0237 13.4256 17.4058 13.3156L19.4571 12.7253Z'
      fill='currentColor'
    />
    <path
      d='M11.4434 5.80041C11.3805 5.71614 11.2815 5.6665 11.1763 5.6665C11.0711 5.6665 10.9721 5.71614 10.9092 5.80041L8.0057 9.68903C7.94469 9.77073 7.92444 9.87587 7.95072 9.97439C7.97701 10.0729 8.04694 10.154 8.14054 10.1944L11.0441 11.4494C11.1284 11.4859 11.2242 11.4859 11.3086 11.4494L14.212 10.1944C14.3056 10.154 14.3756 10.0729 14.4018 9.97439C14.4281 9.87587 14.4079 9.77074 14.3469 9.68903L11.4434 5.80041Z'
      fill='currentColor'
    />
    <path
      d='M8.40504 10.8995C8.25709 10.8355 8.08457 10.8866 7.99528 11.0208C7.90599 11.155 7.92551 11.3338 8.04164 11.4456L10.9452 14.2401C11.0742 14.3644 11.2784 14.3644 11.4075 14.2401L14.3109 11.4456C14.4271 11.3338 14.4466 11.155 14.3573 11.0208C14.268 10.8866 14.0955 10.8355 13.9475 10.8995L11.1763 12.0973L8.40504 10.8995Z'
      fill='currentColor'
    />
  </svg>
);

export { EthStaking };

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Paste } from '@swyftx/aviary/icons/outlined';

import {
  ResendButtonComponent,
  useCountdown,
  usePasteFromClipboard,
} from '@routes/Profile/subroutes/Information/components/ProfileInformationUpdatePhone/OtpCountdown';

import { Controller } from 'react-hook-form';
import OtpInput from 'react-otp-input';

import { TitleText } from './TitleText';
import { PasswordResetContext } from '../Context/PasswordResetContext';

export const ConfirmIdentity: React.FC = () => {
  const context = useContext(PasswordResetContext);
  const [localOtp, setLocalOtp] = useState<string>('');

  const { countdown, setCountdown, startCountdown, resendDisabled } = useCountdown(60, 1000, true);

  useEffect(() => {
    setCountdown(30);
    startCountdown();
  }, []);

  if (!context) {
    throw new Error('Missing Password Reset Context');
  }

  const {
    submitOTP,
    form: {
      control,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
    },
    mfaType,
    resendOTP,
  } = context;

  const confirmResendOTP = useCallback(async () => {
    resendOTP();
    setCountdown(30);
    startCountdown();
  }, [resendOTP, setCountdown, startCountdown]);

  const pasteFromClipboard = usePasteFromClipboard((otp: string) => {
    setValue('newOTP', otp);
    if (otp.length === 6) {
      handleSubmit(submitOTP)();
    }
  });

  return (
    <FlexLayout direction='column' className='flex justify-center' spacing={32}>
      <TitleText
        title='Confirm your identity'
        subtitle={`To reset your password we need to confirm it's you. Enter the 6-digit code sent to your ${
          mfaType === 'email' ? 'email address' : 'phone number'
        }.`}
      />
      <Controller
        control={control}
        name='newOTP'
        render={({ field: { onChange, value } }) => {
          const handleChangeOtp = (otp: string) => {
            onChange(otp);
            setLocalOtp(otp);

            if (otp.length === 6) {
              handleSubmit(submitOTP)();
            }
          };

          return (
            <FlexLayout className='justify-center'>
              <OtpInput
                value={value || ''}
                onChange={handleChangeOtp}
                numInputs={6}
                placeholder='000000'
                containerStyle='gap-8 [&>*:nth-child(3)]:mr-8'
                shouldAutoFocus
                inputType='tel'
                renderInput={(props) => <Input {...props} disabled={isSubmitting} className='h-32' />}
              />
            </FlexLayout>
          );
        }}
      />

      <FlexLayout spacing={8} className='mb-32 justify-center'>
        <Button
          size='md'
          variant='ghost'
          color='primary'
          leadingIcon={<Paste className='h-20 w-20' />}
          onClick={pasteFromClipboard}
        >
          Paste from clipboard
        </Button>

        <ResendButtonComponent
          countdown={countdown}
          sendOtp={confirmResendOTP}
          resendDisabled={resendDisabled}
          startCountdown={startCountdown}
        />
      </FlexLayout>
      <Button
        type='submit'
        size='lg'
        className='w-full'
        disabled={isSubmitting || localOtp.length !== 6}
        onClick={handleSubmit(submitOTP)}
      >
        Confirm
      </Button>
    </FlexLayout>
  );
};

import React, { useContext, useMemo, useState } from 'react';

import { UserStore } from '@shared/store';

import {
  ApiKeyConfigurationStep,
  ApiKeyConfirmPasswordStep,
  ApiKeyResultStep,
  CreateApiKeyWarningStep,
} from '@routes/Profile/subroutes/ApiKeys/CreateApiKey/components';

import { ApiKeysContext } from '@Profile/subroutes/ApiKeys/ApiKeys.context';

import { observer } from 'mobx-react-lite';

import { CreateApiKeyStep } from './types/CreateApiKey.types';

const CreateApiKey: React.FC = observer(() => {
  const { isEntity } = UserStore.useUserStore;
  const entityAccount = isEntity();
  const { setShowCreateNewApiKey, resetNewApiKeyPayload, createNewApiKey } = useContext(ApiKeysContext);
  const [step, setStep] = useState<CreateApiKeyStep>(CreateApiKeyStep.Warning);

  const steps: CreateApiKeyStep[] = useMemo(
    () =>
      entityAccount
        ? [CreateApiKeyStep.Warning, CreateApiKeyStep.Configuration, CreateApiKeyStep.Result]
        : [
            CreateApiKeyStep.Warning,
            CreateApiKeyStep.Configuration,
            CreateApiKeyStep.Authentication,
            CreateApiKeyStep.Result,
          ],
    [entityAccount],
  );

  const handleOnClose = () => {
    setStep(CreateApiKeyStep.Warning);
    resetNewApiKeyPayload();
    setShowCreateNewApiKey(false);
  };

  /** sets step which changes modal displayed */
  const nextStep = () => {
    if (!step) return;

    const nextStepIdx = steps.indexOf(step) + 1;

    if (nextStepIdx > steps.length) {
      setStep(CreateApiKeyStep.Warning);
    }

    setStep(steps[nextStepIdx]);
  };

  return (
    <>
      {step === CreateApiKeyStep.Warning && <CreateApiKeyWarningStep onClick={nextStep} onClose={handleOnClose} />}
      {step === CreateApiKeyStep.Configuration && (
        <ApiKeyConfigurationStep
          onClose={handleOnClose}
          onSubmit={async () => {
            nextStep();
            if (entityAccount) createNewApiKey();
          }}
        />
      )}
      {step === CreateApiKeyStep.Authentication && (
        <ApiKeyConfirmPasswordStep onClose={handleOnClose} onSubmit={nextStep} />
      )}
      {step === CreateApiKeyStep.Result && <ApiKeyResultStep onClose={handleOnClose} />}
    </>
  );
});

CreateApiKey.displayName = 'CreateApiKey';

export { CreateApiKey };

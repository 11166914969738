import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Email, Phone, Profile } from '@swyftx/aviary/icons/outlined';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { ProfileInformationUpdateEmail, ProfileInformationUpdatePhone } from './components';

const ProfileInformation: React.FC = observer(() => {
  const { userProfile, getMaskedPhoneNumber } = UserStore.useUserStore;
  const { t } = useTranslation('profile.information');
  const userPhone = getMaskedPhoneNumber();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  if (!userProfile) return null;

  const { name, verification, email } = userProfile;
  const { email: emailVerified, phone: phoneVerified } = verification || {};
  const { first, last } = name;

  return (
    <FlexLayout direction='column' className='PII'>
      <SettingsCluster
        title='User Information'
        items={[
          <SettingsItem
            icon={<Profile height={24} width={24} />}
            title={t('name.title')}
            key='name.title'
            description={`${first} ${last}`}
          />,
          <SettingsItem
            icon={<Email height={24} width={24} />}
            title={t('email.title')}
            key='email.title'
            description={`${email}`}
            titleChip={
              <Chip variant='subtle' size='sm' color={emailVerified ? 'success' : 'destructive'}>
                {emailVerified ? t('email.labels.verified') : t('email.labels.unverified')}
              </Chip>
            }
            action={
              <ProfileInformationUpdateEmail
                id='profile-information-email-update'
                userProfile={userProfile}
                isDoubleOTPEnabled={false}
              />
            }
          />,
          <SettingsItem
            icon={<Phone height={24} width={24} />}
            title={t('phone.title')}
            key='phone.title'
            description={`${userPhone}`}
            titleChip={
              <Chip variant='subtle' size='sm' color={phoneVerified ? 'success' : 'destructive'}>
                {phoneVerified ? t('phone.labels.verified') : t('phone.labels.unverified')}
              </Chip>
            }
            action={
              <ProfileInformationUpdatePhone
                id='profile-information-phone-update'
                userProfile={userProfile}
                isDoubleOTPEnabled={isFeatureEnabled(AppFeature.DoubleOTPForWebUpdate)}
              />
            }
          />,
        ]}
      />
    </FlexLayout>
  );
});

ProfileInformation.displayName = 'ProfileInformation';

export { ProfileInformation };

import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { useMarketTickerAnalytics } from '@global-components/MarketTicker/useMarketTickerAnalytics';

import logger from '@shared/logger';

import { observer } from 'mobx-react-lite';
import { useMarketTicker } from 'src/lib/utils/hooks';

const LOG_TAG = '[MARKET_TICKER_SWITCH]';

const MarketTickerSwitch: React.FC = observer(() => {
  const { showMarketTicker, setShowMarketTicker } = useMarketTicker();
  const { marketTickerToggled } = useMarketTickerAnalytics();

  const handleSwitch = async (value: boolean) => {
    try {
      setShowMarketTicker(value);
      marketTickerToggled({ toggleState: value });
    } catch (e) {
      logger.error(LOG_TAG, 'Error updating user settings');
    }
  };

  return (
    <Switch
      id='profile-settings-market-ticker-switch'
      size='md'
      checked={showMarketTicker}
      onCheckedChange={handleSwitch}
    />
  );
});

MarketTickerSwitch.displayName = 'MarketTickerSwitch';

export { MarketTickerSwitch };

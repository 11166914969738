import React from 'react';
import { useLocation } from 'react-router-dom';

import { Divider, Grid, Stack } from '@swyftx/react-web-design-system';

import AssetPlusMoreIcon from '@global-components/AssetPlusMoreIcon/AssetPlusMoreIcon';

import { NewsItem, NewsTileTab } from '@shared/api';
import { assetService } from '@shared/services';
import { relativeTimeFromDates } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';
import { AssetIconsGroup } from 'src/lib/markets/components';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { NewsFeedTileListView } from './NewsFeedTileListView';
import { NewsFeedTileThumbnailView } from './NewsFeedTileThumbnailView';

interface Props {
  newsItem: NewsItem;
  showDivider: boolean;
  selectedTab?: NewsTileTab;
  isThumbnailView: boolean;
  tileSize?: string;
}

const NewsFeedTileItem: React.FC<Props> = observer(({ newsItem, showDivider, isThumbnailView, tileSize }: Props) => {
  const { isMobile } = useContentBreakpoint();
  const assetList = newsItem.assets.filter((asset) => assetService.getAssetByCode(asset));
  const publishedAt = new Date(newsItem.publishedAt);
  const relativeTime = relativeTimeFromDates(publishedAt);
  const showMobile = isMobile || tileSize === 'compact';

  const { pathname } = useLocation();
  const { getAssetsByCode } = useMarkets();
  const avo = useAvo();

  const buildAssetIcon = () => {
    // if there are more than 3 assets, show plus more icon after second asset
    const indexToSplit = assetList.length > 3 ? 2 : 3;
    // for individual asset icons - show individual asset names in tooltip
    const firstThree = getAssetsByCode(assetList.slice(0, indexToSplit));
    // for plus more icon - tooltip should show the list of remaining assets
    const additionalAssets = assetList.slice(indexToSplit);

    return (
      <Stack direction='row' alignItems='center' justifyContent={isThumbnailView ? 'flex-start' : 'flex-end'}>
        {firstThree.length > 0 ? (
          <AssetIconsGroup assets={firstThree} size={20} alignment='right' maxAssets={3} />
        ) : (
          <div style={{ margin: '0 8px', height: 28 }}>-</div>
        )}
        {additionalAssets.length > 0 && <AssetPlusMoreIcon assetList={additionalAssets} />}
      </Stack>
    );
  };

  const onNewsItemClicked = () => {
    avo.clickedNewsArticle({
      screen: pathname,
      articleTitle: newsItem.title,
      articleLink: newsItem.newsUrl,
      articleAssets: newsItem.assets,
      articlePublishedDate: new Date(newsItem.publishedAt).toISOString(),
    });

    window.open(newsItem.newsUrl, '_blank', 'noopener,noreferrer');
  };

  if (showMobile) {
    return (
      <>
        <Stack
          paddingX={1}
          paddingY={0.5}
          onClick={onNewsItemClicked}
          sx={{
            '&:hover': {
              backgroundColor: 'action.hover',
              borderRadius: 0.5,
              cursor: 'pointer',
            },
          }}
        >
          <NewsFeedTileThumbnailView
            newsItem={newsItem}
            buildAsset={buildAssetIcon()}
            relativeTime={relativeTime}
            isMobile={showMobile}
            tileSize={tileSize}
          />
        </Stack>
        <Divider />
      </>
    );
  }

  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='center'
        paddingX={1}
        paddingY={0.5}
        onClick={onNewsItemClicked}
        sx={{
          '&:hover': {
            backgroundColor: 'action.hover',
            borderRadius: 0.5,
            cursor: 'pointer',
          },
        }}
      >
        {isThumbnailView ? (
          <NewsFeedTileThumbnailView
            newsItem={newsItem}
            buildAsset={buildAssetIcon()}
            relativeTime={relativeTime}
            tileSize={tileSize}
          />
        ) : (
          <NewsFeedTileListView
            buildAsset={buildAssetIcon()}
            newsItem={newsItem}
            relativeTime={relativeTime}
            onNewsItemClicked={onNewsItemClicked}
          />
        )}
      </Grid>
      {showDivider && !isThumbnailView && <Divider variant='middle' />}
    </>
  );
});

export { NewsFeedTileItem };

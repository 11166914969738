/* eslint-disable no-unused-vars */
import { api } from '@shared/api';
import { CronJobService } from '@shared/cron';
import { TimesEnum } from '@shared/enums';
import handleError, { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { UserStore } from '@shared/store';

const logTag = 'USER_STATISTICS_SERVICE';

class UserStatisticsService extends CronJobService {
  async update() {
    try {
      const { isLoggedIn, setUserStatistics } = UserStore.useUserStore;
      if (isLoggedIn) {
        const response = await api.endpoints.getStatistics();
        setUserStatistics(response.data);
      }
    } catch (ex) {
      // ignore if known
      if (!(ex as SwyftxError).isSwyftxError) {
        handleError(logTag, 'Failed to update', ex);
      }
    }
  }
}

export const userStatisticsService = new UserStatisticsService(TimesEnum.MINUTE);

// [dev] When hot reloading remove the cron
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const module: any;
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    logger.debug(logTag, 'Hot reload cleanup');
    userStatisticsService.cleanup();
  });
}

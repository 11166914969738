import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Telegram: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5337 16.5884L10.3218 18.6438C10.0511 18.8963 9.61043 18.6889 9.63539 18.3193C9.70451 17.2748 9.81203 15.688 9.88403 14.8057C9.89555 14.6665 10.0127 14.511 10.1241 14.4102C12.7074 12.0678 15.2965 9.73116 17.8847 7.39261C17.9807 7.30621 18.094 7.09309 18.0345 6.98077C17.9673 6.85405 17.6572 6.89245 17.5285 6.97309C14.2415 9.04285 10.9545 11.1116 7.67219 13.1881C7.47059 13.3148 7.22387 13.3456 6.99731 13.2726C5.64371 12.8377 4.28243 12.4259 2.92595 11.9977C2.61395 11.8988 2.24339 11.8009 2.20787 11.414C2.17043 11.0099 2.51699 10.8179 2.82995 10.6921C4.09139 10.1833 5.36147 9.6966 6.62963 9.20701C11.1916 7.44925 15.7535 5.69245 20.3154 3.93565C20.4681 3.87709 20.6716 3.84061 20.8309 3.80893C21.309 3.71293 21.7391 3.95293 21.7861 4.43677C21.8217 4.79101 21.7593 5.18557 21.6853 5.53597C20.7349 10.0566 19.7721 14.5753 18.8101 19.095C18.5509 20.3161 17.9231 20.5475 16.9122 19.8016C15.6095 18.8396 12.5337 16.5884 12.5337 16.5884Z'
      fill='currentColor'
    />
  </svg>
);

export { Telegram };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeColumns: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.5 11.5625V20.5H6.3125V11.5625H3.5ZM3 10.0625H6.8125C7.36478 10.0625 7.8125 10.5102 7.8125 11.0625V21C7.8125 21.5523 7.36478 22 6.8125 22H3C2.44772 22 2 21.5523 2 21V11.0625C2 10.5102 2.44772 10.0625 3 10.0625Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5938 5.5625V20.5H13.4062V5.5625H10.5938ZM10.0938 4.0625H13.9062C14.4585 4.0625 14.9062 4.51022 14.9062 5.0625V21C14.9062 21.5523 14.4585 22 13.9062 22H10.0938C9.54147 22 9.09375 21.5523 9.09375 21V5.0625C9.09375 4.51022 9.54147 4.0625 10.0938 4.0625Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.453 15.0404C21.317 14.9711 21.1631 14.9321 21 14.9321H17.1875C16.6352 14.9321 16.1875 15.3798 16.1875 15.9321V21C16.1875 21.5523 16.6352 22 17.1875 22H21C21.5523 22 22 21.5523 22 21V15.9321C22 15.5429 21.7776 15.2056 21.453 15.0404ZM20.5 16.4321V20.5H17.6875V16.4321H20.5Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeColumns };

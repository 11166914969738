import { CurrentUserFeesResponse, FeeTiersResponse, TradeVolumeResponse } from '@shared/api/@types/fees';

import { Methods, request } from '../rest';

type Query = {
  mode: 'pro' | undefined;
};

const feesEndpoints = {
  getFeeTiers: ({ query }: { query?: Query } = {}): FeeTiersResponse =>
    request({ path: '/fees/tiers/', method: Methods.GET, auth: true, query }),

  get30DayTradeVolume: ({ query }: { query?: Query } = {}): TradeVolumeResponse =>
    request({ path: '/fees/tiers/volume/', method: Methods.GET, auth: true, query }),

  getCurrentUserFees: ({ query }: { query?: Query } = {}): CurrentUserFeesResponse =>
    request({ path: '/fees/current/', method: Methods.GET, auth: true, query }),
};

export default feesEndpoints;

/* CAUTION! You should be aware the impact caused by making any changes below! */

/*
 * Most below magic numbers are currency based for time being. It maybe updated based when we support globalisation.
 * By the time, those amounts should be set and restricted by country/currency based OR converted from AUD values.
 */
export enum MiningFeeEnum {
  AUD = 0,
}

export enum LowLiquidityMax {
  AUD = 3000,
}

import React, { useCallback, useMemo } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

type Props = {
  assets: Asset[];
  size: number;
  overlap?: number;
  maxAssets?: number;
  alignment?: 'left' | 'right';
};

const AssetIconsGroup: React.FC<Props> = ({ assets, size, maxAssets, overlap = 4, alignment = 'right' }) => {
  const assetsToRender = useMemo(() => {
    if (!maxAssets) return assets;

    return assets.slice(0, maxAssets);
  }, [assets, maxAssets]);

  const getAlignmentStyle = useCallback(
    (index: number) => {
      switch (alignment) {
        case 'right':
          return { left: overlap * (assetsToRender.length - index), zIndex: index };
        case 'left':
          return { right: overlap * index, zIndex: index };
      }
    },
    [alignment, assetsToRender.length, overlap],
  );

  return (
    <FlexLayout alignItems='center' spacing={0}>
      {assetsToRender.map((asset: Asset, index: number) => (
        <AssetIcon
          key={asset.code}
          asset={asset}
          size={size}
          style={getAlignmentStyle(index)}
          className='rounded-[50%]'
        />
      ))}
      {assetsToRender.length < assets.length && (
        <Tooltip
          title={assets
            .slice(maxAssets)
            .map((a) => a.name)
            .join(', ')}
        >
          <Avatar
            style={{ ...getAlignmentStyle(assetsToRender.length), width: size, height: size }}
            className='rounded-[50%] bg-color-background-info-subtle'
            color='primary'
          >
            <Numeric color='accent' weight='bold'>
              {assets.length - assetsToRender.length}
            </Numeric>
          </Avatar>
        </Tooltip>
      )}
    </FlexLayout>
  );
};

export { AssetIconsGroup };

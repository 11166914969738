import * as React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as LabelPrimitive from '@radix-ui/react-label';
import { VariantProps, cva } from 'cva';

const labelVariants = cva(
  'font-sans text-12 line-height-16 letter-spacing-base font-500 peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };

import { TabItem } from '@swyftx/aviary/atoms/Tabs';

export type AssetListTabType = 'assets' | 'categories';

export type AssetListTab = TabItem<AssetListTabType>;

export const assetListTabs: AssetListTab[] = [
  { title: 'Assets', value: 'assets' },
  { title: 'Categories', value: 'categories', spotlightElementId: 'asset-list-category-tab' },
];

export type AssetListTableData = {
  rank: string;
  rankAssetVol: string;
  buyPrice24h: string;
  asset: string;
  categories: string;
  buyPrice: string;
  dailyChange: string;
  weeklyChange: string;
  monthlyChange: string;
  marketCap: string;
  dailyVolume: string;
  actions: string;
};

export enum AssetListTabTypes {
  ALL = 'all',
  OWNED = 'owned',
  FAVOURITES = 'favourites',
  GAINERS = 'gainers',
  LOSERS = 'losers',
  NEW = 'new',
}

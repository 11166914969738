import React, { useEffect, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { FullPageCard } from '@global-components/FullPageCard';

import { SourceOfWealthStatus } from '@shared/api/@types/compliance';
import { UserStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useGetSourceOfWealthStatus } from 'src/lib/verification/hooks/useGetSourceOfWealth';

import { useSourceOfWealthSelector } from './SourceOfWealth.context';
import { MobileSourceOfWealthStepper, SourceOfWealthStepper } from './components/SourceOfWealthStepper';
import { mergeMeta } from './utils/mergeMeta';

const SourceOfWealth: React.FC = () => {
  const { isLargeScreen } = useScreenBreakpoints();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isKyc2Required } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();
  const { status } = useGetSourceOfWealthStatus();

  const validStatuses = useMemo(
    () => [
      SourceOfWealthStatus.REQUESTED,
      SourceOfWealthStatus.REQUIRED,
      SourceOfWealthStatus.MORE_INFO_REQUESTED,
      SourceOfWealthStatus.STARTED,
    ],
    [],
  );

  const accessDisabled = useMemo(
    () =>
      !isFeatureEnabled(AppFeature.SourceOfWealth) || !isKyc2Required() || (status && !validStatuses.includes(status)),
    [isFeatureEnabled, isKyc2Required, status, validStatuses],
  );

  const ActiveStepComponent = useSourceOfWealthSelector((state) => mergeMeta(state.meta)?.Component);

  useEffect(() => {
    if (accessDisabled) {
      navigate(NavigationURLs.Dashboard);
    }
  }, [accessDisabled, isFeatureEnabled, isKyc2Required, navigate, status, validStatuses]);

  if (accessDisabled) return null;

  return (
    <FullPageCard
      id='sourceOfWealth'
      headerChildren={
        isLargeScreen && (
          <FlexLayout className='w-full pr-48 md:w-4/12'>
            <SourceOfWealthStepper />
          </FlexLayout>
        )
      }
    >
      {!isLargeScreen && <MobileSourceOfWealthStepper />}
      {ActiveStepComponent && <ActiveStepComponent />}
    </FullPageCard>
  );
};

SourceOfWealth.displayName = 'Account Setup';

export default SourceOfWealth;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box } from '@mui/material';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { useOnboardingSelector } from '@Onboarding/Onboarding.context';

import { Step } from './stepperUtils';

type Props = {
  step: Step;
  activeOrder: number;
  root?: boolean;
};

const LINEAR_PROGRESS_BG = '#B9C3D5';

const OnboardingStepperItem: React.FC<Props> = (props) => {
  const { step, root, activeOrder } = props;
  const { t } = useTranslation('onboarding');

  const currentState = useOnboardingSelector((state) => state.toStrings());
  const completedSteps = useOnboardingSelector((state) => state.context.completedSteps);

  const isActive = useMemo(() => currentState.includes(step.id), [currentState, step.id]);
  const isComplete = useMemo(
    () => completedSteps[step.id] || (step.children.length && step.children.every((s) => completedSteps[s.id])),
    [completedSteps, step.id, step.children],
  );

  const labelColor = useMemo(() => {
    if (isActive) return 'text.primary';
    if (isComplete) return 'text.disabled';
    return 'text.secondary';
  }, [isActive, isComplete]);

  return (
    <>
      <Stack key={step.id} direction='row' alignItems='center' height={root ? 52 : 36}>
        <Box
          bgcolor='primary'
          sx={{
            width: 3,
            height: '100%',
            backgroundColor: isActive || activeOrder > step.order ? 'primary.main' : LINEAR_PROGRESS_BG,
          }}
        />
        <Stack width={50} alignItems='center'>
          {root &&
            (isComplete ? (
              <CheckCircle color='primary' fontSize='small' />
            ) : (
              <RadioButtonUnchecked color={isActive ? 'primary' : 'action'} fontSize='small' />
            ))}
        </Stack>
        <Typography
          color={labelColor}
          fontWeight={isActive ? 600 : 500}
          fontSize={14}
          sx={{
            textDecoration: isComplete ? 'line-through' : 'none',
          }}
        >
          {t(`steps.${step.id}.title` as any)}
        </Typography>
      </Stack>
      {isActive &&
        step.children
          .filter((s) => !s.hidden)
          .map((s) => <OnboardingStepperItem key={s.id} step={s} activeOrder={activeOrder} />)}
    </>
  );
};

export { OnboardingStepperItem };

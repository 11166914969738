import { Coin, Deposit, Fire, Star } from '@swyftx/aviary/icons/outlined';
import { MarketMoversType } from '@swyftx/aviary/organisms/Assets/MarketMoversCard/MarketMoversCard.types';

const getEmptyContent = (type: MarketMoversType) => {
  switch (type) {
    case 'favourites':
      return 'You haven’t added any favourite assets yet';
    case 'gainers':
      return 'There doesn’t appear to be any gainers right now';
    case 'losers':
      return 'The market must be hot, we can’t find any losers';
    case 'owned':
      return "You don't own any assets right now";
    case 'new':
    default:
      return null;
  }
};

const getEmptyIcon = (type: MarketMoversType) => {
  switch (type) {
    case 'favourites':
      return <Star className='h-48 w-48 text-color-text-accent' />;
    case 'gainers':
      return <Deposit className='h-48 w-48 text-color-text-accent' />;
    case 'losers':
      return <Fire className='h-48 w-48 text-color-text-accent' />;
    case 'owned':
      return <Coin className='h-48 w-48 text-color-text-accent' />;
    case 'new':
    default:
      return null;
  }
};

export { getEmptyContent, getEmptyIcon };

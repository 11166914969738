import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading, Utility } from '@swyftx/aviary/atoms/Typography';
import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { Carousel, CarouselItem } from '@swyftx/react-web-design-system';

import { cn } from '@shared/utils/lib/ui';

import * as braze from '@braze/web-sdk';
import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useBuildAnnouncementCards, BrazeContentCardItem } from 'src/lib/announcements/hooks/useBuildAnnouncementCards';

const ANNOUNCEMENT_HEIGHT = '160px';

const BrazeAnnouncementCarousel: React.FC = observer(() => {
  const { theme } = useTailwindTheme();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { items } = useBuildAnnouncementCards();

  const handleClick = (card: BrazeContentCardItem) => {
    if (!card.url) return;

    braze.logContentCardClick(card.card);

    if (card.url.startsWith('http')) {
      window.open(card.url, '_blank', 'noopener,noreferrer');
      return;
    }

    navigate(card.url);
  };

  const getCardImage = (item: BrazeContentCardItem) => {
    switch (theme) {
      case TailWindTheme.Midnight:
        return item.card.extras?.['midnight-image'] || item.card.extras?.['dark-image'] || item.imgSrc;
      case TailWindTheme.Light:
        return item.imgSrc;
      case TailWindTheme.Dark:
        return item.card.extras?.['dark-image'] || item.imgSrc;
      default:
        theme satisfies never;
    }
  };

  // This function is to handle the new look content card
  const getCardBackground = (item: braze.CaptionedImage) => {
    if (theme === TailWindTheme.Light) {
      if (item.description.toLocaleLowerCase() === 'a new look for swyftx') {
        return 'var(--color-background-primary)';
      }
    }

    return undefined;
  };

  // This function is to handle the new look content card
  const getCardTextColor = (item: braze.CaptionedImage) => {
    if (theme === TailWindTheme.Light) {
      if (item.description.toLocaleLowerCase() === 'a new look for swyftx') {
        return 'white';
      }
    }

    return undefined;
  };

  if (!isFeatureEnabled(AppFeature.Braze)) {
    return null;
  }

  return (
    <Box
      width='100%'
      height='100%'
      minHeight={ANNOUNCEMENT_HEIGHT}
      sx={{
        '#announcement-carousel_slides_container': {
          '> div': {
            padding: '0',
          },
        },
        cursor: 'pointer',
        '#announcement-carousel_dot_container': {
          '> div': {
            display: 'none',
          },
        },
      }}
    >
      <Carousel id='announcement-carousel' interval={5000} loop>
        {items.map((item) => {
          if (item.card instanceof braze.Banner) {
            return (
              <CarouselItem
                id={item.id}
                key={item.id}
                onVisibilityChange={() => {
                  braze.logContentCardImpressions([item.card]);
                }}
              >
                <img
                  src={getCardImage(item)}
                  className={cn('h-full w-full overflow-hidden object-cover', {
                    'cursor-pointer': item.url,
                  })}
                  onClick={() => handleClick(item)}
                />
              </CarouselItem>
            );
          }
          return (
            <CarouselItem
              id={item.id}
              key={item.id}
              onVisibilityChange={() => {
                braze.logContentCardImpressions([item.card]);
              }}
              sx={{ backgroundColor: getCardBackground(item.card) }}
            >
              <FlexLayout spacing={16} className='h-full self-start p-8' onClick={() => handleClick(item)}>
                <Image src={getCardImage(item)} category='/announcements' className='h-full w-auto rounded-[.75rem]' />
                <FlexLayout direction='column' className='self-center' spacing={4}>
                  <Utility variant='overline' color={getCardTextColor(item.card) ?? 'accent'}>
                    {item.card.title}
                  </Utility>
                  <FlexLayout spacing={4} alignItems='center'>
                    <Heading size='h6' color={getCardTextColor(item.card) ?? 'primary'}>
                      {item.card.description}
                    </Heading>
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </CarouselItem>
          );
        })}
      </Carousel>
    </Box>
  );
});

export { BrazeAnnouncementCarousel };

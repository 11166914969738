import React from 'react';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { Tick } from '@swyftx/aviary/icons/outlined';
import { Menu, Stack } from '@swyftx/react-web-design-system';

import { CountriesEnum } from '@shared/enums';

import { observer } from 'mobx-react-lite';

type countries = {
  label: string;
  code: CountriesEnum;
  dialCode: string;
  group: string;
}[];

type Props = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onCountrySelected: (countryEnum: CountriesEnum) => void;
  selectedCountry?: CountriesEnum;
  countries: countries;
};

const CountryMenu: React.FC<Props> = observer(
  ({ countries, anchorEl, onClose, selectedCountry, onCountrySelected }) => {
    const countriesArray = Object.values(countries);

    return (
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <List style={{ maxHeight: '200px', overflow: 'auto' }}>
          {countriesArray.map((country, index) => {
            const isSelected = selectedCountry === country.code;
            return (
              <Stack
                key={index}
                sx={{ '&:hover': { backgroundColor: 'action.hover', cursor: 'pointer' } }}
                display='flex'
                alignItems='center'
              >
                <ListItem onClick={() => onCountrySelected(country.code)}>
                  <ListItemIcon>
                    <img src={`/assets/images/flags/${country.code}.svg`} alt='flag' />
                  </ListItemIcon>
                  <ListItemText primary={country.label} />
                  {isSelected && (
                    <ListItemIcon>
                      <Tick className='h-20 w-20' />
                    </ListItemIcon>
                  )}
                </ListItem>
              </Stack>
            );
          })}
        </List>
      </Menu>
    );
  },
);

CountryMenu.displayName = 'CountryMenu';

export { CountryMenu };

export interface PromiseFulfilledResult<T> {
  status: 'fulfilled';
  value: T;
}

export interface PromiseRejectedResult {
  status: 'rejected';
  reason: any;
}

export type PromiseSettledResult<T> = PromiseFulfilledResult<T> | PromiseRejectedResult;

export const allSettled = <T>(values: Promise<T>[]): Promise<PromiseSettledResult<T>[]> =>
  Promise.all(
    values.map((value) =>
      value
        .then((value) => {
          const result: PromiseSettledResult<typeof value> = { status: 'fulfilled', value };
          return result;
        })
        .catch((reason) => {
          const result: PromiseRejectedResult = { status: 'rejected', reason };
          return result;
        }),
    ),
  );

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import Recaptcha from '@components/molecules/Recaptcha';

import { CountdownDelay } from '@global-components/CountdownDelay';

import env from '@shared/config';

import { useRecaptcha } from '@hooks/useRecaptcha/useRecaptcha';
import AuthenticationService from '@services/AuthenticationService';

import { observer } from 'mobx-react-lite';

import { LoginTitle } from '../';

const INITIAL_CODE_TIMEOUT = 30;

type Props = {
  email: string;
};

const ResetLinkSent: React.FC<Props> = observer(({ email }) => {
  const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);
  const { captchaRefresh, captchaToken, setCaptchaRefresh, setCaptchaToken } = useRecaptcha();

  const { t } = useTranslation('login');

  const onSendResetLink = async () => {
    setResendTime(resendTime + INITIAL_CODE_TIMEOUT);
    await AuthenticationService.ResetPassword(email, captchaToken).finally(() => {
      setCaptchaRefresh(!captchaRefresh);
    });
  };

  return (
    <>
      <FlexLayout direction='column' className='w-full' alignItems='center' justifyContent='center'>
        <LoginTitle
          id='resetLinkSent.title'
          title=''
          subTitle={t('resetLinkSent.subTitle')}
          subTitleDescription={t('resetLinkSent.subTitleDescription', { email })}
        />
      </FlexLayout>
      <FlexLayout
        direction='column'
        id='resetLinkSent.labels.resendEmailIn.container'
        className='w-full text-center'
        alignItems='center'
        justifyContent='center'
      >
        <Recaptcha id='forgotPassword.form.recaptcha' submitCaptcha={setCaptchaToken} captchaRefresh={captchaRefresh} />
        <CountdownDelay
          disabled={!captchaToken && !env.DISABLE_CAPTCHA}
          id='resetLinkSent.labels.resendEmail.countdown'
          delay={30}
          delayText={t('resetLinkSent.buttonLabels.resendEmail')}
          onTimerRestart={onSendResetLink}
        />
      </FlexLayout>
    </>
  );
});

ResetLinkSent.displayName = 'ResetLinkSent';

export { ResetLinkSent };

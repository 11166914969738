import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { ChartTypeLine } from '@swyftx/aviary/icons/charts';
import { Chart, DarkMode, Dollar, Game, MenuExpand, SwyftxCompact } from '@swyftx/aviary/icons/outlined';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import { CurrencyDropdown } from '@global-components/Dropdown';

import env from '@shared/config';

import { observer } from 'mobx-react-lite';
import { SwyftxThemeSelector } from 'src/lib/layout';
import { UIContext } from 'src/lib/utils/context/UI.context';

import { AnalyticsSwitch, CheckForUpdate, DemoModeSwitch, MarketTickerSwitch } from './components';

const ProfileSettings: React.FC = observer(() => {
  const { t } = useTranslation('profile.settings');
  const version = useMemo(() => env.COMMIT_HASH?.substring(0, 6), []);
  const { openOnHover, setOpenOnHover } = useContext(UIContext);

  const personalisationItems = useMemo(
    () => [
      <SettingsItem
        key='interface.darkMode.title'
        title='Theme'
        description={t('interface.darkMode.subTitle')}
        icon={<DarkMode />}
        action={<SwyftxThemeSelector renderOnlyElement />}
      />,
      <SettingsItem
        key='interface.marketTicker.title'
        title={t('interface.marketTicker.title')}
        description={t('interface.marketTicker.subTitle')}
        icon={<ChartTypeLine />}
        action={<MarketTickerSwitch />}
      />,
      <SettingsItem
        key='interface.navigationHover.title'
        title='Auto open side menu'
        description='Enable this to expand the side menu when hovered even when collapsed'
        icon={<MenuExpand />}
        action={<Switch checked={openOnHover} onCheckedChange={setOpenOnHover} />}
      />,
    ],
    [openOnHover, setOpenOnHover, t],
  );

  const platformItems = useMemo(
    () => [
      <SettingsItem
        key='platformData.currency.title'
        title={t('platformData.currency.title')}
        description={t('platformData.currency.subTitle')}
        icon={<Dollar />}
        action={<CurrencyDropdown />}
      />,
      <SettingsItem
        key='platformData.demoMode.title'
        title={t('platformData.demoMode.title')}
        description={t('platformData.demoMode.subTitle')}
        action={<DemoModeSwitch />}
        icon={<Game />}
      />,
      <SettingsItem
        key='platformData.analytics.title'
        title={t('platformData.analytics.title')}
        description={t('platformData.analytics.subTitle')}
        icon={<Chart />}
        action={<AnalyticsSwitch />}
      />,
    ],
    [t],
  );

  return (
    <FlexLayout direction='column' spacing={24}>
      <SettingsCluster title='Interface Personalisation' items={personalisationItems} />
      <SettingsCluster title='Platform Data' items={platformItems} />
      <SettingsCluster
        title='Version'
        items={[
          <SettingsItem
            key='version'
            description={version}
            icon={<SwyftxCompact id='version-icon' className='h-24' />}
            action={<CheckForUpdate />}
          />,
        ]}
      />
    </FlexLayout>
  );
});

export { ProfileSettings };

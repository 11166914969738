import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const AssetsSearchRates: React.FC<Props> = observer(({ asset }) => {
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const rate = getRate(asset);
  const bigRateChange = useMemo(() => Big(rate.dailyPriceChange), [rate]);
  const chipColor = useMemo(() => (bigRateChange.lt(0) ? 'error' : 'success'), [bigRateChange]);

  return (
    <FlexLayout direction='column' alignItems='end' spacing={4}>
      <Numeric>{formatCurrency(rate.midPrice, baseAsset)}</Numeric>
      <Numeric color={chipColor} size='small'>
        {bigRateChange.abs().toFixed(2)}%
      </Numeric>
    </FlexLayout>
  );
});

export { AssetsSearchRates };

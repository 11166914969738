import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';

import { WalletIcon } from '@global-components/WalletIcon/WalletIcon';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { AssetColors } from '@utils/assets';

import { CurrencyIcons, CurrencyIconsRounded } from 'src/assets/currency';

type Props = {
  asset?: Asset | string;
  size: number;
  wallet?: boolean;
  border?: string;
  render3dVariant?: boolean;
  iconTiltDegrees?: number;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const AssetIcon: React.FC<Props> = ({
  asset,
  size,
  wallet,
  border,
  render3dVariant,
  iconTiltDegrees,
  disabled,
  className,
  style,
}) => {
  const assetCode = typeof asset === 'string' ? asset : asset?.code;

  if (!assetCode) return null;

  const iconAsset = assetService.getAssetByCode(assetCode);
  const currencyIcons = wallet ? CurrencyIcons : CurrencyIconsRounded;

  if (iconAsset?.assetType === AssetType.Fiat && currencyIcons[assetCode] !== undefined) {
    if (wallet) {
      return (
        <WalletIcon id={`single-wallet-details-${assetCode}-icon`} color={AssetColors[assetCode]} height={size}>
          <div
            className={cn('flex overflow-hidden', wallet ? 'rounded-[0%]' : 'rounded-[100%]', className)}
            style={{ width: wallet ? undefined : size, height: wallet ? undefined : size, ...style }}
          >
            {currencyIcons[assetCode]}
          </div>
        </WalletIcon>
      );
    }
    return (
      <FlexLayout
        style={{ width: wallet ? undefined : size, height: wallet ? undefined : size, border, ...style }}
        alignItems='center'
        justifyContent='center'
        className={cn('relative overflow-hidden', wallet ? 'rounded-[0%]' : 'rounded-[100%]', className)}
      >
        <div>{currencyIcons[assetCode]}</div>
      </FlexLayout>
    );
  }

  const render3dSvg = () => (
    <span
      className={cn(className, 'coin-icon-stack alt-icon md-avatar', className)}
      style={{ width: size, height: size, minWidth: size, minHeight: size, border }}
      title={iconAsset?.name}
    >
      <svg width={size} height={size} viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.1'>
          <rect x='7.66406' y='44.8599' width='44.8598' height='9.88547' rx='4.94274' fill='black' />
          <rect
            x='7.66406'
            y='44.8599'
            width='44.8598'
            height='9.88547'
            rx='4.94274'
            stroke='black'
            strokeWidth='0.528972'
          />
        </g>
        <ellipse cx='29.8015' cy='23.6434' rx='22.3913' ry='21.6214' fill={AssetColors[assetCode]} />
        <ellipse cx='29.8015' cy='23.6434' rx='22.3913' ry='21.6214' fill='black' fillOpacity='0.2' />
        <ellipse
          cx='29.8015'
          cy='23.6434'
          rx='22.3913'
          ry='21.6214'
          stroke={AssetColors[assetCode]}
          strokeWidth='0.52838'
        />
        <ellipse
          cx='29.8015'
          cy='23.6434'
          rx='22.3913'
          ry='21.6214'
          stroke='black'
          strokeOpacity='0.4'
          strokeWidth='0.52838'
        />
        <ellipse cx='29.8175' cy='30.0069' rx='22.5304' ry='22.5304' fill={AssetColors[assetCode]} />
        <ellipse
          cx='29.8175'
          cy='30.0069'
          rx='22.5304'
          ry='22.5304'
          stroke={AssetColors[assetCode]}
          strokeWidth='0.524162'
        />
        <ellipse
          cx='29.8175'
          cy='30.0069'
          rx='22.5304'
          ry='22.5304'
          stroke='black'
          strokeOpacity='0.4'
          strokeWidth='0.524162'
        />
        <path
          d='M7.28233 29.3159L7.41603 23.032'
          stroke={AssetColors[assetCode]}
          strokeWidth='0.52838'
          strokeLinecap='round'
        />
        <path
          d='M7.28233 29.3159L7.41603 23.032'
          stroke='black'
          strokeOpacity='0.4'
          strokeWidth='0.52838'
          strokeLinecap='round'
        />
        <path
          d='M52.327 29.3159L52.1933 23.032'
          stroke={AssetColors[assetCode]}
          strokeWidth='0.52838'
          strokeLinecap='round'
        />
        <path
          d='M52.327 29.3159L52.1933 23.032'
          stroke='black'
          strokeOpacity='0.4'
          strokeWidth='0.52838'
          strokeLinecap='round'
        />
      </svg>
      <i
        style={{
          fontSize: size * 0.45,
          transform: iconTiltDegrees ? `rotate(${iconTiltDegrees}deg)` : undefined,
          color: AssetColors[assetCode] || getComputedStyle(document.body).getPropertyValue('--color-text-primary'),
        }}
        color={AssetColors[assetCode] || 'text.primary'}
        className={`icon icon-${assetCode} coin-icon-logo`}
      />
    </span>
  );

  const renderIcon = () => {
    const disabledColor = getComputedStyle(document.body).getPropertyValue('--color-text-disabled');

    return (
      <Tooltip title={iconAsset?.name}>
        <span
          style={{ width: size, height: size, minWidth: size, minHeight: size, border, ...style }}
          className={cn(className, 'coin-icon-stack alt-icon md-avatar', className)}
        >
          <i
            style={{
              fontSize: size,
              color: disabled ? disabledColor : AssetColors[assetCode] || disabledColor,
            }}
            className='icon icon-circle coin-icon-circle'
          />
          <i
            style={{
              fontSize: size * 0.6,
              color: AssetColors[assetCode] || getComputedStyle(document.body).getPropertyValue('--color-text-primary'),
            }}
            className={`icon icon-${assetCode} coin-icon-logo`}
          />
        </span>
      </Tooltip>
    );
  };

  if (wallet) {
    return (
      <WalletIcon id={`single-wallet-details-${assetCode}-icon`} color={AssetColors[assetCode]} height={size}>
        {renderIcon()}
      </WalletIcon>
    );
  }

  return render3dVariant ? render3dSvg() : renderIcon();
};

export { AssetIcon };
export default AssetIcon;

import React from 'react';

import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { formatValueToCurrencyShorthand } from '@utils/currency';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const SingleCategoryMarketCap: React.FC<Props> = observer(({ asset }) => {
  const { getRate, loading } = RatesStore.useRatesStore;

  const baseAsset = useBaseAsset();

  if (!baseAsset) return null;

  return (
    <NumericDataItem
      color='primary'
      loading={loading}
      data={formatValueToCurrencyShorthand(
        Big(getRate(asset).midPrice).times(asset.circulatingSupply).toString(),
        baseAsset?.assetType,
        baseAsset?.code,
      )}
      size='small'
      alignItems='end'
    />
  );
});

export { SingleCategoryMarketCap };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  title: string;
  value: string | React.ReactNode;
};

const AutoInvestReviewItem: React.FC<Props> = ({ title, value }) => (
  <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
    <Body size='small' weight='emphasis'>
      {title}
    </Body>
    {typeof value === 'string' && (
      <Body size='small' weight='emphasis' color='secondary'>
        {value}
      </Body>
    )}
    {typeof value !== 'string' && value}
  </FlexLayout>
);

export { AutoInvestReviewItem };

import { useContext, useMemo } from 'react';

import { CreateOrderParams, OrderType } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useSwyftxPro } from './useSwyftxPro';
import { useSwyftxProTradeAmount } from './useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from './useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from './useSwyftxProTradeDirection';
import { useSwyftxProTradePrice } from './useSwyftxProTradePrice';
import { useSwyftxProTradeType } from './useSwyftxProTradeType';
import { SwyftxProTradeContext } from '../context';

const useBuildSwyftxProOrder = () => {
  const { triggerPrice } = useContext(SwyftxProTradeContext);
  const { baseAsset, selectedAsset, limitAsset } = useSwyftxProTradeAssets();
  const { isBuy } = useSwyftxProTradeDirection();
  const { isMarket, isLimit } = useSwyftxProTradeType();
  const { value } = useSwyftxProTradeAmount();
  const { currentPrice } = useSwyftxProTradePrice();
  const { isSwyftxPro } = useSwyftxPro();

  const orderType = useMemo(() => {
    if (!triggerPrice || !currentPrice) return undefined;

    if (isMarket) {
      return isBuy ? OrderType.MarketBuy : OrderType.MarketSell;
    } else if (isLimit) {
      if (isBuy) {
        return Big(currentPrice).lt(triggerPrice) ? OrderType.StopBuy : OrderType.TriggerBuy;
      } else {
        return Big(currentPrice).lt(triggerPrice) ? OrderType.TriggerSell : OrderType.StopSell;
      }
    }

    return undefined;
  }, [currentPrice, isBuy, isLimit, isMarket, triggerPrice]);

  const orderPrice = useMemo(() => {
    if (!triggerPrice || !currentPrice) return undefined;

    if (isBuy) return triggerPrice.toString();

    return Big(1).div(triggerPrice).toString();
  }, [currentPrice, isBuy, triggerPrice]);

  const order = useMemo((): CreateOrderParams | undefined => {
    if (!baseAsset || !selectedAsset || !limitAsset || !orderType || !value) return undefined;

    return {
      assetQuantity: limitAsset.code,
      orderType,
      primary: baseAsset.code,
      quantity: value,
      secondary: selectedAsset.code,
      trigger: isMarket ? undefined : orderPrice,
      pro: isSwyftxPro ? 1 : 0,
    };
  }, [baseAsset, isMarket, isSwyftxPro, limitAsset, orderPrice, orderType, selectedAsset, value]);

  return {
    order,
  };
};

export { useBuildSwyftxProOrder };

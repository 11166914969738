import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types';

type Props = {
  triggerType: TriggerType;
};

const useProfitStopLossValueCalculationMethodUI = ({ triggerType }: Props) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });

  const label = useMemo(() => {
    switch (triggerType) {
      case TriggerType.TakeProfit:
        return t('labels.howToCalculateProfit');
      case TriggerType.StopLoss:
        return t('labels.howToCalculateMaxLoss');
      default:
        return '';
    }
  }, [t, triggerType]);

  const targetLabel = useMemo(() => {
    switch (triggerType) {
      case TriggerType.TakeProfit:
        return t('labels.profitTarget');
      case TriggerType.StopLoss:
        return t('labels.lossTarget');
      default:
    }
  }, [t, triggerType]);

  return {
    label,
    targetLabel,
    triggerPriceLabel: t('labels.setTriggerPrice'),
  };
};

export { useProfitStopLossValueCalculationMethodUI };

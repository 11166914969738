import React, { PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToursAnalytics } from '@hooks/Analytics/Tours/useToursAnalytics';

import { TourGuideData } from './TourGuide.types';

type TourGuideContextType = {
  startTour: (tour: TourGuideData, onEndTour?: () => void) => void;
  stopTour: (end?: boolean) => void;
  tour?: TourGuideData;
  tourStarted: boolean;
  curStep: number;
  setCurStep: React.Dispatch<React.SetStateAction<number>>;
};

export const TourGuideContext = React.createContext<TourGuideContextType>({
  tourStarted: false,
  startTour: () => {},
  stopTour: () => {},
  curStep: 0,
  setCurStep: () => {},
});

const TourGuideContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [tour, setTour] = useState<TourGuideData>();
  const [curStep, setCurStep] = useState<number>(0);

  const [onTourEnd, setOnTourEnd] = useState<() => void>();
  const navigate = useNavigate();
  const { trackTourExited, trackTourCompleted, trackTourStarted } = useToursAnalytics();

  const [tourStarted, setTourStarted] = useState<boolean>(false);

  const tourViewed = useCallback(() => {
    if (tour?.tourKey) {
      localStorage.setItem(tour.tourKey, 'true');
    }
  }, [tour?.tourKey]);

  const startTour = useCallback(
    (newTour: TourGuideData, onEndTour?: () => void) => {
      if (newTour.startRoute) navigate(newTour.startRoute);
      setTour(newTour);
      setOnTourEnd(onEndTour);
      setTourStarted(true);
      trackTourStarted(newTour.name);
      tourViewed();
    },
    [navigate, tourViewed, trackTourStarted],
  );

  const stopTour = useCallback(
    (end?: boolean) => {
      if (end && onTourEnd) {
        onTourEnd();
      }
      if (curStep === tour?.steps.length) {
        trackTourCompleted(tour.name);
      } else if (tour) trackTourExited(tour.name, curStep + 1);

      tourViewed();
      setTour(undefined);
      setTourStarted(false);
      setCurStep(0);
    },
    [curStep, onTourEnd, tour, tourViewed, trackTourCompleted, trackTourExited],
  );

  return (
    <TourGuideContext.Provider value={{ startTour, stopTour, tour, tourStarted, curStep, setCurStep }}>
      {children}
    </TourGuideContext.Provider>
  );
};

export { TourGuideContextProvider };

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';

import { AssetInfoRow, useAssetInfo } from './useAssetInfo';

interface AssetInfoContentProps {
  asset: Asset;
  setOpen?: (open: boolean) => void;
  variant?: 'popover' | 'content';
  showLearnMore?: boolean;
}

type InfoRowProps = AssetInfoRow & { direction?: 'column' | 'row' };

const InfoRow: React.FC<InfoRowProps> = (infoRowProps) => {
  const { value, title, tooltip, hidden, direction = 'row' } = infoRowProps;

  if (hidden) return null;

  return (
    <FlexLayout direction={direction} className='flex-wrap justify-between px-6 py-8' spacing={8}>
      <Body>{title}</Body>
      {typeof value === 'string' && (
        <Tooltip title={tooltip}>
          <Body color='secondary'>{value}</Body>
        </Tooltip>
      )}
      {typeof value !== 'string' && <Tooltip title={tooltip}>{value}</Tooltip>}
    </FlexLayout>
  );
};

export const AssetInfoContent: React.FC<AssetInfoContentProps> = observer((props) => {
  const { asset, setOpen, showLearnMore = false, variant = 'popover' } = props;
  const { info } = useAssetInfo(asset, showLearnMore);
  const { navigate } = useNavigateRoute();
  const isXs = useTailwindBreakpoint('xs');
  const { clickedLearnMoreOnAssetInfoModal } = useTradePageAnalytics();

  const handleOnLearnMoreClick = useCallback(() => {
    clickedLearnMoreOnAssetInfoModal({ asset });
    navigate(NavigationURLs.SingleAsset, { pathParams: { asset: asset.code } });
  }, [asset, clickedLearnMoreOnAssetInfoModal, navigate]);

  return (
    <FlexLayout direction='column' className='h-full'>
      {!isXs && (
        <FlexLayout
          direction='row'
          className='sticky top-0 items-center justify-between bg-color-background-surface-primary p-24'
        >
          <Heading size='h4'>{asset.code} information</Heading>
          {variant === 'popover' && setOpen && (
            <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={() => setOpen(false)} />
          )}
        </FlexLayout>
      )}

      <FlexLayout direction='column' className={cn(!showLearnMore ? 'p-16' : 'p-24', 'pt-0')}>
        {info.map((row) => {
          if (row.hidden) return null;
          return (
            <InfoRow title={row.title} value={row.value} tooltip={row.tooltip} key={row.title} hidden={row.hidden} />
          );
        })}
      </FlexLayout>
      {showLearnMore && (
        <FlexLayout direction='row' className='justify-end p-24 pt-0'>
          <Button size='lg' className='w-full justify-start sm:w-auto sm:justify-end' onClick={handleOnLearnMoreClick}>
            Learn more
          </Button>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

import React from 'react';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';
import { formatCurrency } from '@shared/utils';

type Props = {
  asset: Asset;
  amount: string;
};

const CreditCardDepositModalRedirecting: React.FC<Props> = ({ asset, amount }) => (
  <Stack direction='column' alignItems='center' spacing={3} justifyContent='center' width='100%'>
    <Typography color='text.secondary' fontSize={14}>
      You will now be redirected to the secure Banxa gateway to complete your {formatCurrency(amount, asset)} deposit.
    </Typography>
    <Typography color='text.secondary' fontSize={14}>
      A new browser tab will be opened which should look similar to the sample below.
    </Typography>
    <img src='/assets/images/banxa_preview.png' alt='banxa preview' />
  </Stack>
);

export { CreditCardDepositModalRedirecting };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { TourGuideVariant } from '../TourGuide';

export type TourPosition = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

type Props = {
  stepNumber?: number;
  title: string;
  description: string;
  image?: string;
  buttonText?: string;
  stepText?: string;
  onNext: () => void;
  position?: TourPosition;
  imageClassName?: string;
  variant?: TourGuideVariant;
  stepLength: number;
  showSteps?: boolean;
  onClose: () => void;
} & React.HTMLAttributes<Omit<HTMLDivElement, 'style'>>;

const Tour = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      image,
      description,
      buttonText,
      imageClassName,
      stepText,
      position,
      onNext,
      stepLength,
      showSteps,
      stepNumber,
      onClose,
      variant = 'tour',
    },
    ref,
  ) => (
    <Card
      border
      shadow
      className={cn(
        variant === 'tour' ? 'w-[256px] bg-color-background-surface-primary' : 'w-[300px]',
        `absolute z-tour-popup p-12 text-white shadow-sm`,
      )}
      ref={ref}
      style={{
        top: position?.top,
        left: position?.left,
        right: position?.right,
        bottom: position?.bottom,
      }}
    >
      {image && <Image image={image} className={cn('mb-12', imageClassName)} />}
      <FlexLayout direction='row' className='w-full' justifyContent='space-between'>
        <Body size='small' weight='emphasis' color='primary' className='mb-4'>
          {title}
        </Body>
        <Button size='sm' layout='icon' leadingIcon={<Cross />} onClick={onClose} variant='outlined' />
      </FlexLayout>
      <Body size='xsmall' weight='none' color='primary' className='mb-12'>
        {description}
      </Body>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        {showSteps && stepNumber && stepLength && (
          <Body size='small' color='secondary'>
            {stepNumber} of {stepLength}
          </Body>
        )}
        {buttonText && (
          <Button variant='filled' color='primary' bodyProps={{ weight: 'emphasis' }} size='sm' onClick={onNext}>
            {buttonText}
          </Button>
        )}
        {stepText && (
          <Body size='small' weight='none' color='primary'>
            {stepText}
          </Body>
        )}
      </FlexLayout>
    </Card>
  ),
);

Tour.displayName = 'Tour';

export { Tour };

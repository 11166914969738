import { useCallback } from 'react';

import { TimesEnum } from '@shared/enums';
import { withdrawalService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useWithdrawalLimit = () => {
  const { userId } = UserStore.useUserStore;

  const fetchWithdrawalLimit = useCallback(async () => {
    const res = await withdrawalService.getWithdrawalLimit();

    return res;
  }, []);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['withdrawal-limit', userId],
    queryFn: fetchWithdrawalLimit,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 60,
  });

  return { status, data: data, error, isFetching, isPreviousData, isStale };
};

export const useWithdrawalLimitCache = () => {
  const queryCache = useQueryClient();

  const invalidateCache = useCallback(() => queryCache.invalidateQueries(['withdrawal-limit']), [queryCache]);

  return {
    invalidateCache,
  };
};

import { SourceOfWealthPayload } from '@shared/api/@types/compliance';

import { SourceOfWealthData } from '../types/SourceOfWealth.types';

// Fields that don't require any type transformations
const sharedFields = [
  'industry',
  'occupation',
  'employmentType',
  'cashDepositsPercentage',
  'cashDepositsOrigin',
  'sourcesOfWealth',
  'sourcesOfWealthOther',
  'expectedAnnualSpend',
  'expectedAnnualSpendOther',
];

export const transformSourceOfWealthPayloadToSourceOfWealthData = (
  data: SourceOfWealthPayload,
): Partial<SourceOfWealthData> => {
  const result: Partial<SourceOfWealthData> = {};

  sharedFields.forEach((key) => {
    // @ts-ignore
    if (data[key]) {
      // @ts-ignore
      result[key] = data[key];
    }
  });

  return result;
};

export const transformSourceOfWealthDataToSourceOfWealthPayload = (data: Partial<SourceOfWealthData>) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(data).reduce<SourceOfWealthPayload>((acc, [_key, value]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const key = _key as keyof SourceOfWealthData;

    return acc;
  }, {});

import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyLeft, ArrowLineOnlyRight, Tick } from '@swyftx/aviary/icons/outlined';

import { useWhatsNewAnalytics } from '@hooks/Analytics/WhatsNew/useWhatsNewAnalytics';

import { PlatformFeature } from '../../types/FeatureDiscovery.types';

type Props = {
  features: PlatformFeature[];
  activeFeature?: PlatformFeature;
  onNextFeature: (feature: PlatformFeature) => void;
  setMinimized: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const FeatureCardActions: React.FC<Props> = ({ features, activeFeature, onClose, onNextFeature, setMinimized }) => {
  const { trackWhatsNewActionClicked } = useWhatsNewAnalytics();
  const activeIndex = useMemo(
    () => features?.findIndex((f) => f.key === activeFeature?.key),
    [features, activeFeature],
  );

  const lastFeature = useMemo(() => activeIndex === (features?.length || 0) - 1, [activeIndex, features]);
  const isXs = useTailwindBreakpoint('xs');

  const onNext = useCallback(() => {
    if (lastFeature) {
      onClose();
    } else {
      onNextFeature(features[activeIndex + 1]);
    }
  }, [activeIndex, features, lastFeature, onClose, onNextFeature]);

  const onPrev = useCallback(() => {
    onNextFeature(features[activeIndex + -1]);
  }, [activeIndex, features, onNextFeature]);

  const onAction = () => {
    if (!activeFeature?.onAction) return;

    activeFeature.onAction();
    setMinimized(true);
    trackWhatsNewActionClicked(activeFeature.title, activeFeature.actionLabel || 'Action not defined');
  };

  return (
    <FlexLayout alignItems='center' justifyContent='space-between' className='min-h-60 w-full px-32 pb-32'>
      <Button
        size='md'
        onClick={onAction}
        className={`${
          activeFeature?.onAction
            ? 'animate-[fade-in_.3s_forwards,slide-down-up_.3s_forwards]'
            : 'animate-[fade-out_.3s_forwards,slide-up-down_.3s_forwards]'
        } translate-y-[100%] opacity-0`}
      >
        {activeFeature?.actionLabel || 'Try it now'}
      </Button>
      <FlexLayout spacing={12} alignItems='center'>
        {!isXs && features.length > 1 && (
          <Body size='small' color='secondary'>
            {activeIndex + 1} of {features.length}
          </Body>
        )}
        {features.length > 1 && (
          <Button
            disabled={activeIndex === 0}
            size='md'
            layout='icon'
            leadingIcon={<ArrowLineOnlyLeft />}
            variant='outlined'
            onClick={onPrev}
          />
        )}
        {activeIndex < features?.length - 1 && (
          <Button size='md' layout='icon' leadingIcon={<ArrowLineOnlyRight />} variant='outlined' onClick={onNext} />
        )}
        {activeIndex === features?.length - 1 && (
          <Button size='md' layout='icon' leadingIcon={<Tick />} variant='outlined' onClick={onNext} />
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export { FeatureCardActions };

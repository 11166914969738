import React from 'react';

import { observer } from 'mobx-react-lite';
import { SupportingDocumentsProvider } from 'src/context/DocumentUpload/SupportingDocument.context';
import { DocumentUploadFlow } from 'src/context/DocumentUpload/supportingDocumentsUtils';

import { UploadDocumentsContent } from './UploadDocumentsContent';

export const UploadDocumentsStep: React.FC = observer(() => (
  <SupportingDocumentsProvider flow={DocumentUploadFlow.EntityOnboarding}>
    <UploadDocumentsContent />
  </SupportingDocumentsProvider>
));

import React from 'react';

import { Box } from '@mui/material';

import { Card, Skeleton, Stack } from '@swyftx/react-web-design-system';

const RecaptchaSkeleton: React.FC = () => (
  <Card
    variant='outlined'
    sx={{ height: '100%', width: '100%' }}
    elevation={0}
    contentSx={{ padding: 0, height: '100%', paddingBottom: '0px !important' }}
  >
    <Stack direction='row' alignItems='center' justifyContent='space-around' width='100%' height='100%'>
      <Stack direction='row' height='100%' alignItems='center' width='100%'>
        <Skeleton variant='rectangular' width='28px' height='28px' sx={{ marginLeft: '12px', marginRight: '12px' }} />
        <Skeleton variant='text' width='90px' height='16px' />
      </Stack>
      <Box padding={1}>
        <Skeleton variant='rectangular' width='60px' height='60px' />
      </Box>
    </Stack>
  </Card>
);

export { RecaptchaSkeleton };

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Withdraw, Information } from '@swyftx/aviary/icons/outlined';

import { FiatWithdrawalInfoModal } from '@global-components/Modals/WithdrawFunds';
import { SourceOfWealthRequiredNotification } from '@global-components/SourceOfWealthNotification/SourceOfWealthRequiredNotification';

import { Asset } from '@shared/api';
import { AppStore, UserStore } from '@shared/store';

import { usePermissions, UserPermissions } from '@hooks/Permissions';
import { useWithdrawalPermission } from '@hooks/Withdraw/useWithdrawalPermission';
import { useGlobalResolveRestrictions } from '@hooks/useGlobalResolveRestrictions';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import {
  SingleWalletFiatWithdrawalLanding,
  SingleWalletFiatWithdrawalVerify,
  SingleWalletFiatWithdrawalWithdraw,
} from './components';

type Props = {
  asset: Asset;
};

const SingleWalletFiatWithdrawal: React.FC<Props> = observer(({ asset }) => {
  const { isUserVerified, userProfile, isRestricted, isKyc2Required } = UserStore.useUserStore;
  const { isDemo, withdrawalLandingRead } = AppStore.useAppStore;

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [continueToWithdrawals, setContinueToWithdrawals] = useState(withdrawalLandingRead);
  const { t } = useTranslation('wallet');
  const withdrawalBlockedStatus = useWithdrawalPermission(asset.code);
  const resolveRestrictions = useGlobalResolveRestrictions();

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const sourceOfWealthRequired = isFeatureEnabled(AppFeature.SourceOfWealth) && isKyc2Required();
  const userVerified = useMemo(() => isUserVerified(), [isUserVerified]);

  const { enabled, element: PermissionsChip } = usePermissions({
    permissions: [UserPermissions.WITHDRAW_FUNDS],
    disabledOptions: { chip: true },
  });

  const openInfo = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowInfoModal(true);
  };

  const withdrawDisabled = useMemo(
    () => Boolean(!userProfile?.accountStatus?.withdrawals || 0) || isRestricted(),
    [userProfile, isRestricted],
  );

  const getAccordionTrailing = () => {
    if (isDemo) {
      return (
        <Chip size='md' variant='subtle' color='secondary'>
          {t('demoMode.notAvailable')}
        </Chip>
      );
    }

    if (!enabled) return PermissionsChip;

    return undefined;
  };

  return (
    <>
      <Accordion type='single' collapsible defaultValue={!userVerified ? 'withdraw' : undefined}>
        <AccordionItem value={isDemo || !enabled ? '' : 'withdraw'}>
          <AccordionTrigger disabled={isDemo || !enabled}>
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
              <FlexLayout direction='row' alignItems='center'>
                <Withdraw />
                <Body weight='bold' className='ml-8' size='large'>
                  {t('singleWalletFiatWithdrawal.title')}
                </Body>
              </FlexLayout>
              {!isDemo && enabled && (
                <Button
                  layout='icon'
                  variant='ghost'
                  leadingIcon={<Information />}
                  onClick={openInfo}
                  className='mr-8'
                />
              )}
              {getAccordionTrailing()}
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent>
            <FlexLayout direction='column' spacing={16}>
              {sourceOfWealthRequired && <SourceOfWealthRequiredNotification />}
              {withdrawDisabled && (
                <Notification
                  severity='destructive'
                  title={t('singleWalletFiatWithdrawal.labels.disabledTitle')}
                  actions={
                    <Button variant='ghost' color='destructive' className='w-full' onClick={resolveRestrictions}>
                      {t('singleWalletFiatWithdrawal.buttonLabels.resolve')}
                    </Button>
                  }
                >
                  {t('singleWalletFiatWithdrawal.labels.disabledDescription')}
                </Notification>
              )}
              {withdrawalBlockedStatus.blocked && !withdrawDisabled && (
                <Notification severity='destructive' title={`${asset.code} withdrawals blocked`}>
                  {withdrawalBlockedStatus.message}
                </Notification>
              )}

              {!withdrawDisabled && !userVerified && <SingleWalletFiatWithdrawalVerify />}
              {!withdrawDisabled && userVerified && !continueToWithdrawals && (
                <SingleWalletFiatWithdrawalLanding asset={asset} setContinueToWithdrawals={setContinueToWithdrawals} />
              )}
              {userVerified && continueToWithdrawals && (
                <SingleWalletFiatWithdrawalWithdraw
                  asset={asset}
                  withdrawDisabled={withdrawDisabled}
                  withdrawalBlockedStatus={withdrawalBlockedStatus}
                />
              )}
            </FlexLayout>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {showInfoModal && <FiatWithdrawalInfoModal asset={asset} onClose={() => setShowInfoModal(false)} />}
    </>
  );
});

SingleWalletFiatWithdrawal.displayName = 'SingleWalletFiatWithdrawal';

export { SingleWalletFiatWithdrawal };

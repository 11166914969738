import { useCallback, useMemo, useRef, useState } from 'react';

import { api, DashboardSettings } from '@shared/api';
import { UserStore } from '@shared/store';

import * as Sentry from '@sentry/react';

import { Widgets } from '../types/Dashboard.widgets';

type Props<T> = {
  widgetId?: Widgets;
  stateKey: string;
  defaultValue: T;
};

const useDashboardSyncState = <T extends Object>({
  widgetId,
  stateKey,
  defaultValue,
}: Props<T>): [T, (newValue: T) => void] => {
  const { userProfile, setUserSettings } = UserStore.useUserStore;
  const [state, setState] = useState<T>(
    widgetId
      ? userProfile?.userSettings.dashboardSettings?.layout?.metadata?.[widgetId]?.[stateKey] ?? defaultValue
      : defaultValue,
  );
  const debounce = useRef<number>(0);

  const buildNewMetaData = useCallback(
    (newValue: T) => {
      if (!widgetId) return undefined;

      let existingMetadata = userProfile?.userSettings.dashboardSettings?.layout?.metadata[widgetId] || {};
      existingMetadata = { ...existingMetadata, [stateKey]: newValue };

      return {
        ...userProfile?.userSettings.dashboardSettings,
        layout: {
          layoutKey: userProfile?.userSettings.dashboardSettings?.layout?.layoutKey || undefined,
          metadata: {
            ...userProfile?.userSettings.dashboardSettings?.layout?.metadata,
            [widgetId]: existingMetadata,
          },
        },
      } as DashboardSettings;
    },
    [stateKey, userProfile?.userSettings.dashboardSettings, widgetId],
  );

  const updateUserSettings = useCallback(
    (dashboardSettings?: DashboardSettings) => {
      if (!userProfile || !dashboardSettings) return;

      if (userProfile) {
        setUserSettings({
          ...userProfile?.userSettings,
          dashboardSettings,
        });
      }
    },
    [setUserSettings, userProfile],
  );

  const syncToDB = useCallback(
    async (newValue: T) => {
      if (!widgetId) return;

      try {
        const newDashboardSettings = buildNewMetaData(newValue);

        if (!newDashboardSettings) return;

        await api.endpoints.updateUserSettings({
          data: {
            data: {
              dashboardSettings: newDashboardSettings,
            },
          },
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [buildNewMetaData, widgetId],
  );

  const syncWidgetData = useCallback(
    async (newValue: T) => {
      if (!widgetId) {
        setState(newValue);
        return;
      }

      if (debounce.current) {
        window.clearTimeout(debounce.current);
      }

      debounce.current = window.setTimeout(() => syncToDB(newValue), 1000);

      setState(newValue);
      updateUserSettings(buildNewMetaData(newValue));
    },
    [buildNewMetaData, syncToDB, updateUserSettings, widgetId],
  );

  return useMemo(() => [state, syncWidgetData], [state, syncWidgetData]);
};

export { useDashboardSyncState };

import { useMemo } from 'react';

import { Asset } from '@shared/api';

import {
  PriceAlert,
  PriceAlertGroupBy,
  PriceAlertTriggerType,
} from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  alerts?: PriceAlert[];
  groupBy: PriceAlertGroupBy;
  filterTypes: PriceAlertTriggerType[];
  filterAssets: Asset[];
};

export const alertTypeMap: { [key: string]: number } = {
  LIMIT: 0,
  VOLATILITY: 1,
};

// Based on the filter properties, we will sort, filter and group assets and return the result
const useDynamicPriceActiveAlertsTables = ({ alerts, groupBy, filterTypes, filterAssets }: Props) => {
  const { getAssetById } = useMarkets();
  const groupedAlerts = useMemo((): Map<number, PriceAlert[]> | undefined => {
    if (!alerts) return undefined;

    const groups: Map<number, PriceAlert[]> = new Map<number, PriceAlert[]>();

    alerts
      .filter((a) => {
        if (a.status === 'TRIGGERED') return false;
        if (filterTypes.length) {
          if (filterTypes.findIndex((t) => t === a.type) === -1) return false;
        }

        if (filterAssets.length) {
          if (filterAssets.findIndex((asset) => asset.id === a.secondary) === -1) return false;
        }

        return true;
      })
      .sort((a, b) => {
        if (groupBy === 'asset') {
          const aAssetName = getAssetById(a.secondary)?.name || '';
          const bAssetName = getAssetById(b.secondary)?.name || '';

          return aAssetName.localeCompare(bAssetName);
        } else {
          return b.dateUpdated - a.dateUpdated;
        }
      })
      .forEach((alert) => {
        if (groupBy === 'asset') {
          if (!groups.has(alert.secondary)) {
            groups.set(alert.secondary, [alert]);
          } else {
            groups.get(alert.secondary)?.push(alert);
          }
        } else {
          const alertTypeIndex = alertTypeMap[alert.type];

          if (!groups.has(alertTypeIndex)) {
            groups.set(alertTypeIndex, [alert]);
          } else {
            groups.get(alertTypeIndex)?.push(alert);
          }
        }
      });

    if (!Array.from(groups.keys()).length) return undefined;

    return groups;
  }, [alerts, filterAssets, filterTypes, getAssetById, groupBy]);

  return { groupedAlerts };
};

export { useDynamicPriceActiveAlertsTables };

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { PriceAlertType } from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import { useFetchDynamicPriceAlertsCache } from 'src/lib/dynamic-price-alerts/hooks';

type Props = {
  currentTab: PriceAlertType;
  fullWidth?: boolean;
  showTitle?: boolean;
  className?: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<PriceAlertType>>;
};

const DynamicPriceAlertsTabs: React.FC<Props> = ({
  currentTab,
  className,
  fullWidth = false,
  showTitle = true,
  setCurrentTab,
}) => {
  const { t } = useTranslation('priceAlerts');
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();

  const tabs: EnhancedTab<PriceAlertType>[] = useMemo(
    () => [
      {
        title: t('filters.active'),
        value: 'active',
      },
      {
        title: t('filters.triggered'),
        value: 'triggered',
      },
    ],
    [t],
  );

  const handleOnChange = useCallback(
    (newTab: PriceAlertType) => {
      setCurrentTab(newTab);
      invalidateCache();
    },
    [invalidateCache, setCurrentTab],
  );

  return (
    <FlexLayout alignItems='center' spacing={16} className={className}>
      {showTitle && <Heading size='h5'>{t('filters.title')}</Heading>}
      <EnhancedTabs<PriceAlertType> tabs={tabs} value={currentTab} onChange={handleOnChange} fullWidth={fullWidth} />
    </FlexLayout>
  );
};

export { DynamicPriceAlertsTabs };

import React, { useMemo } from 'react';

import { Tick } from '@swyftx/aviary/icons/outlined';

import { StepperStepType, StepperVariant } from './Stepper.types';
import { FlexLayout } from '../Layout/Flex';
import { Body } from '../Typography';

type Props = {
  index: number;
  step: StepperStepType;
  active?: boolean;
  completed?: boolean;
  variant?: StepperVariant;
};

const StepperStep: React.FC<Props> = ({ index, step, variant = 'standard', completed, active }) => {
  const completedOrActive = useMemo(() => completed || active, [completed, active]);

  const bodyColor = useMemo(() => {
    if (completed) return 'accent';
    if (active) return 'primary';
    return 'secondary';
  }, [completed, active]);

  return (
    <FlexLayout spacing={8}>
      {variant === 'standard' && (
        <FlexLayout
          className={`h-24 w-24 rounded-[100%]  ${
            completedOrActive ? 'bg-color-background-info text-white' : 'border border-color-icon-primary'
          }`}
          alignItems='center'
          justifyContent='center'
        >
          {!completed && <Body color={completedOrActive ? 'white' : 'secondary'}>{index + 1}</Body>}
          {completed && <Tick className='w-16' />}
        </FlexLayout>
      )}
      <Body color={bodyColor} weight={active ? 'emphasis' : 'none'}>
        {step.title}
      </Body>
    </FlexLayout>
  );
};

export { StepperStep };

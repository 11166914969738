import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  loading?: boolean;
  source?: string;
  published?: string;
  icons?: React.ReactNode[];
};

const NewsCardFooter: React.FC<Props> = ({ source, published, icons = [], loading = false }) => (
  <FlexLayout alignItems='center' justifyContent='space-between' className='@xs:h-[20px] @lg:h-[35px]'>
    <FlexLayout spacing={8} className='grow-1' alignItems='center'>
      <Body
        size='xsmall'
        color='secondary'
        weight='emphasis'
        loading={loading}
        className='truncate @xs:max-w-[100px] @sm:max-w-[130px]'
      >
        {source}
      </Body>
      <div className='h-[4px] w-[4px] rounded-[100%] bg-color-background-surface-inverse' />
      {published && (
        <Body size='xsmall' color='tertiary' loading={loading}>
          {published}
        </Body>
      )}
    </FlexLayout>
    {!loading && (
      <FlexLayout className='overflow-hidden' spacing={4}>
        {icons.slice(0, 4).map((icon) => icon)}
      </FlexLayout>
    )}
  </FlexLayout>
);

export { NewsCardFooter };

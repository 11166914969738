import { sortObject } from '@shared/utils';

import { AxiosResponse } from 'axios';
import { Md5 } from 'ts-md5';

import { Methods, Request } from './rest';

const responseCache: {
  [parentKey: string]: { [childKey: string]: { age: number; auth: boolean; response: AxiosResponse } };
} = {};

const generateCacheKey = <T>(request: Request<T>, isParentCacheKey: boolean): string => {
  const nuked = JSON.parse(JSON.stringify(request));
  delete nuked.headers;
  delete nuked.cache;
  if (isParentCacheKey) delete nuked.query; // Should not be included in the parent key
  delete nuked.resetCache;
  const sortedObj = sortObject(nuked); // Deep recurring sorting
  return Md5.hashStr(JSON.stringify(sortedObj)); // It's easier to use small text as key and for key comparison
};

/*
 * Only GET response is cached
 */
const write = <T>(request: Request<T>, response: AxiosResponse<any>) => {
  if (request.method === Methods.GET) {
    const parentKey = generateCacheKey(request, true);
    const childKey = generateCacheKey(request, false);

    if (!responseCache[parentKey]) responseCache[parentKey] = {};

    responseCache[parentKey][childKey] = {
      age: Date.now(),
      auth: !!request?.auth,
      response,
    };
  }
};

const read = <T>(request: Request<T>): AxiosResponse<any> | undefined => {
  const parentKey = generateCacheKey(request, true);
  const childKey = generateCacheKey(request, false);
  const cache = responseCache?.[parentKey]?.[childKey];
  if (Date.now() - cache?.age > (request?.cache || 0)) return undefined;
  return cache?.response;
};

/** This resets all cache for specific path regardless of the query data */
const resetCache = <T>(request: Request<T>) => {
  const parentKey = generateCacheKey(request, true);
  delete responseCache[parentKey];
};

const resetAuthedCaches = () => {
  for (const [key, value] of Object.entries(responseCache)) {
    if (value.auth) delete responseCache[key];
  }
};

const clear = () => {
  for (const key of Object.keys(responseCache)) {
    delete responseCache[key];
  }
};

const isCacheablePeriod = (fromTimestamp: number, toTimestamp: number) => {
  const startDate = new Date(fromTimestamp);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(toTimestamp);
  endDate.setHours(23, 59, 59, 999);

  const cacheableStartDate = new Date(new Date().setMonth(new Date().getMonth() - 12));
  cacheableStartDate.setHours(0, 0, 0, 0);
  const cacheableEndDate = new Date();
  cacheableEndDate.setHours(23, 59, 59, 999);
  return startDate.getTime() === cacheableStartDate.getTime() && endDate.getTime() === cacheableEndDate.getTime();
};

export default {
  read,
  write,
  resetCache,
  resetAuthedCaches,
  clear,
  isCacheablePeriod,
};

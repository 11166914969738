import React, { createContext, useContext, useEffect, useMemo, PropsWithChildren } from 'react';

import { api } from '@shared/api';
import { SourceOfWealthStatus } from '@shared/api/@types/compliance';
import env from '@shared/config';
import { UIStore } from '@shared/store';

import { useInterpret, useSelector } from '@xstate/react';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { assign, InterpreterFrom, Subscribable } from 'xstate';

import { sourceOfWealthMachine } from './SourceOfWealth.machine';
import { SourceOfWealthStepId } from './types/SourceOfWealth.types';
import { useSourceOfWealthAnalytics } from './useSourceOfWealthAnalytics';
import { mergeAndStripExclusiveFields } from './utils/mergeAndStripExclusiveFields';
import { transformSourceOfWealthPayloadToSourceOfWealthData } from './utils/transforms';

export const SourceOfWealthContext = createContext<{
  sourceOfWealthService: InterpreterFrom<typeof sourceOfWealthMachine>;
}>({
  sourceOfWealthService: {} as InterpreterFrom<typeof sourceOfWealthMachine>,
});

const SourceOfWealthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { navigate } = useNavigateRoute();
  const { stepCompleted, backClickedOnStep, exited } = useSourceOfWealthAnalytics();
  const { addToastMessage } = UIStore.useUIStore;

  const sourceOfWealthService = useInterpret(sourceOfWealthMachine, {
    devTools: env.ENABLE_XSTATE_INSPECT,
    // Initial context state based on existing user profile data
    context: {
      verificationData: {},
      completedSteps: {},
      status: undefined,
    },
    actions: {
      prefillProfileData: assign((context, event) => {
        const prefilledSourceOfWealthData = transformSourceOfWealthPayloadToSourceOfWealthData(event.data);
        return {
          verificationData: {
            ...context.verificationData,
            ...prefilledSourceOfWealthData,
          },
          completedSteps: {
            ...context.completedSteps,
            howItWorks: Boolean(
              prefilledSourceOfWealthData.industry ||
                prefilledSourceOfWealthData.occupation ||
                prefilledSourceOfWealthData.employmentType,
            ),
            occupation: Boolean(
              prefilledSourceOfWealthData.employmentType ||
                (prefilledSourceOfWealthData.occupation && prefilledSourceOfWealthData.industry),
            ),
            primarySourceOfIncome: Boolean(
              prefilledSourceOfWealthData.sourcesOfWealth?.length || prefilledSourceOfWealthData.sourcesOfWealthOther,
            ),
            cashIncome: Boolean(prefilledSourceOfWealthData.cashDepositsPercentage),
            expectedInvestments: Boolean(
              ('expectedAnnualSpend' in prefilledSourceOfWealthData &&
                prefilledSourceOfWealthData.expectedAnnualSpend) ||
                ('expectedAnnualSpendOther' in prefilledSourceOfWealthData &&
                  prefilledSourceOfWealthData.expectedAnnualSpendOther),
            ),
          },
        };
      }),
      assignStatus: assign((_, event) => ({
        status: event.data as SourceOfWealthStatus,
      })),
      completeStep: assign((context, event) => {
        stepCompleted(event.stepId as SourceOfWealthStepId);
        return {
          completedSteps: {
            ...context.completedSteps,
            [event.stepId]: true,
          },
        };
      }),
      redirectToDashboard: () => {
        navigate(NavigationURLs.Dashboard);
      },
      saveSourceOfWealthData: (context, event) => {
        const contextData = context.verificationData;
        const eventData = event.data;
        const data = mergeAndStripExclusiveFields(contextData, eventData);
        // We need to send the contextData back each time or it will wipe it
        api.endpoints.saveSourceOfWealthFormData({ data });
      },
      assignSourceOfWealthData: assign((context, event) => ({
        verificationData: {
          ...context.verificationData,
          ...event.data,
        },
      })),
    },
    services: {
      fetchSourceOfWealthData: async () => {
        const response = await api.endpoints.getSourceOfWealthFormData();
        return response.data;
      },
      fetchStatus: async () => {
        const response = await api.endpoints.getSourceOfWealthStatus();
        return response.data;
      },
      submitSourceOfWealthData: async (context) => {
        try {
          const data = context.verificationData;
          const response = await api.endpoints.submitSourceOfWealthForm({ data });
          return response.data;
        } catch (error) {
          addToastMessage({
            severity: 'error',
            message: 'There was an error submitting your source of wealth check. Please try again or contact support',
          });
          sourceOfWealthService.send({ type: 'GO_TO_REVIEW_AND_SUBMIT' });
        }
      },
    },
    guards: {
      hasNotCompletedOccupationStep: (context) => !context.completedSteps.occupation,
      hasNotCompletedPrimarySourceOfIncomeStep: (context) => !context.completedSteps.primarySourceOfIncome,
      hasNotCompletedCashIncomeStep: (context) => !context.completedSteps.cashIncome,
      hasNotCompletedExpectedInvestmentsStep: (context) => !context.completedSteps.expectedInvestments,
      hasNotCompletedSupportingDocumentsStep: (context) => !context.completedSteps.supportingDocuments,
      statusIsNotMoreInfoRequested: (context) => context.status !== SourceOfWealthStatus.MORE_INFO_REQUESTED,
    },
  });

  useEffect(() => {
    sourceOfWealthService.onTransition((state, event) => {
      const stepId = state.history?.toStrings()?.slice(0, 2).slice(-1)[0] as SourceOfWealthStepId;
      if (event.type === 'NEXT') {
        if (stepId && !state.context.completedSteps[stepId]) {
          sourceOfWealthService.send({ type: 'COMPLETE_STEP', stepId });
        }
      }
      if (event.type === 'BACK') {
        if (stepId && !state.context.completedSteps[stepId]) {
          backClickedOnStep(stepId as SourceOfWealthStepId);
        }
      }
    });
  }, [backClickedOnStep, exited, sourceOfWealthService, stepCompleted]);

  const value = useMemo(
    () => ({
      sourceOfWealthService,
    }),
    [sourceOfWealthService],
  );

  return <SourceOfWealthContext.Provider value={value}>{children}</SourceOfWealthContext.Provider>;
};

SourceOfWealthProvider.displayName = 'SourceOfWealthProvider';

export { SourceOfWealthProvider };

export const useSourceOfWealthService = () => {
  const { sourceOfWealthService } = useContext(SourceOfWealthContext);
  return sourceOfWealthService;
};

export const useSourceOfWealthSelector = <
  T,
  TEmitted = InterpreterFrom<typeof sourceOfWealthMachine> extends Subscribable<infer Emitted> ? Emitted : never,
>(
  selector: (emitted: TEmitted) => T,
) => {
  const sourceOfWealthService = useSourceOfWealthService();
  const value = useSelector(sourceOfWealthService, selector);

  return value;
};

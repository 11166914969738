import React, { useMemo, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { StorageKey } from '@shared/storage';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { FeatureDiscoveryContext } from 'src/lib/feature-discovery/context/FeatureDiscovery.context';

import {
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_FULL_NAMES,
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_THREE_FULL_NAMES,
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_TWO_FULL_NAMES,
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FOUR_FULL_NAMES,
  DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FIVE_FULL_NAMES,
} from '../ReceiveCrypto/ReceiveCrypto.data';

type Props = {
  onClose: () => void;
};

export const DepositAddressChangesModal: React.FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'depositAddressChangeFeature' });
  const [showNetworks, setShowNetworks] = useState<boolean>(false);
  const { isXs } = useScreenBreakpoints();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { showAll } = useContext(FeatureDiscoveryContext);
  const [hasReadInitialAddresses, setHasReadInitialAddresses] = useLocalStorage(StorageKey.DEPOSIT_CRYPTO_LANDING_READ);
  const [hasReadPartTwo, setHasReadPartTwo] = useLocalStorage(StorageKey.DEPOSIT_CRYPTO_LANDING_PART_TWO_READ);
  const [hasReadPartThree, setHasReadPartThree] = useLocalStorage(StorageKey.DEPOSIT_CRYPTO_LANDING_PART_THREE_READ);
  const [hasReadPartFour, setHasReadPartFour] = useLocalStorage(StorageKey.DEPOSIT_CRYPTO_LANDING_PART_FOUR_READ);
  const [hasReadPartFive, setHasReadPartFive] = useLocalStorage(StorageKey.DEPOSIT_CRYPTO_LANDING_PART_FIVE_READ);

  const affectedNetworks = useMemo(() => {
    const networks = [];

    if ((!hasReadInitialAddresses || showAll) && isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarning)) {
      networks.push(...DEPRECATED_BINANCE_ADDRESS_NETWORKS_FULL_NAMES);
    }

    if ((!hasReadPartTwo || showAll) && isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart2)) {
      networks.push(...DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_TWO_FULL_NAMES);
    }

    if ((!hasReadPartThree || showAll) && isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart3)) {
      networks.push(...DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_THREE_FULL_NAMES);
    }

    if ((!hasReadPartFour || showAll) && isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart4)) {
      networks.push(...DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FOUR_FULL_NAMES);
    }

    if ((!hasReadPartFive || showAll) && isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart5)) {
      networks.push(...DEPRECATED_BINANCE_ADDRESS_NETWORKS_PART_FIVE_FULL_NAMES);
    }

    return networks;
  }, [
    hasReadInitialAddresses,
    hasReadPartFive,
    hasReadPartFour,
    hasReadPartThree,
    hasReadPartTwo,
    isFeatureEnabled,
    showAll,
  ]);

  const onCloseModal = useCallback(() => {
    setHasReadInitialAddresses(true);
    setHasReadPartTwo(true);
    setHasReadPartThree(true);
    setHasReadPartFour(true);
    setHasReadPartFive(true);

    onClose();
  }, [
    onClose,
    setHasReadInitialAddresses,
    setHasReadPartFive,
    setHasReadPartFour,
    setHasReadPartThree,
    setHasReadPartTwo,
  ]);

  return (
    <Modal
      open
      id='affected-networks-modal'
      sx={{
        maxWidth: '752px',
        maxHeight: 'calc(100% - 14rem)',
        zIndex: 5000,
        '.MuiCardContent-root': {
          height: '100%',
          '> .MuiBox-root': { flexGrow: 1, padding: 0, height: '100%' },
          '> .MuiBox-root ~ .MuiBox-root': { flexGrow: 0 },
        },
      }}
      HeaderProps={{ title: 'Changes to crypto deposit addresses', dismissible: true, divider: true }}
      onClose={onCloseModal}
    >
      <Stack direction='column' spacing={3} padding={3}>
        <Typography color='text.secondary'>{t('description')}</Typography>
        <Typography fontSize={18} fontWeight={600}>
          {t('stepHeaderLabel')}
        </Typography>

        <Stack direction='row' spacing={2} alignItems='flex-start' justifyContent='space-between' width='100%'>
          <Stack direction='row' spacing={2} alignItems='flex-start' width='100%'>
            <Stack
              borderRadius={2}
              bgcolor='#E2F0FF'
              minWidth={36}
              height={36}
              alignItems='center'
              justifyContent='center'
            >
              <Typography number fontSize={20} fontWeight={500} color='primary'>
                1
              </Typography>
            </Stack>
            <Stack spacing={0.5} width='100%'>
              <Stack direction='row' width='100%' alignItems='center' justifyContent='space-between'>
                <Stack>
                  <Typography fontSize={12} fontWeight={600}>
                    {t('steps.step1.title')}
                  </Typography>
                  <Typography fontSize={12}>{t('steps.step1.description')}</Typography>
                </Stack>

                {!isXs && (
                  <Button
                    variant='outlined'
                    size='small'
                    color='inherit'
                    onClick={() => setShowNetworks((prev) => !prev)}
                  >
                    {showNetworks ? t('hideNetworks') : t('showNetworks')}
                  </Button>
                )}
              </Stack>

              {showNetworks && (
                <Stack
                  bgcolor='background.default'
                  marginTop='.5rem !important'
                  padding={1}
                  gap={0.5}
                  direction='row'
                  flexWrap='wrap'
                >
                  {affectedNetworks.map((network) => (
                    <FlexLayout spacing={8} className='w-full sm:w-[calc(50%-16px)]' alignItems='center' key={network}>
                      <Body size='xsmall' color='secondary'>
                        {network}
                      </Body>
                    </FlexLayout>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction='row' spacing={2}>
          <Stack
            borderRadius={2}
            bgcolor='#E2F0FF'
            minWidth={36}
            height={36}
            alignItems='center'
            justifyContent='center'
          >
            <Typography number fontSize={20} fontWeight={500} color='primary'>
              2
            </Typography>
          </Stack>
          <Stack>
            <Typography fontSize={12} fontWeight={600}>
              {t('steps.step2.title')}
            </Typography>
            <Typography fontSize={12}>{t('steps.step2.description')}</Typography>
          </Stack>
        </Stack>

        <Stack direction='row' spacing={2}>
          <Stack
            borderRadius={2}
            bgcolor='#E2F0FF'
            minWidth={36}
            maxHeight={36}
            alignItems='center'
            justifyContent='center'
          >
            <Typography number fontSize={20} fontWeight={500} color='primary'>
              3
            </Typography>
          </Stack>
          <Stack>
            <Typography fontSize={12} fontWeight={600}>
              {t('steps.step3.title')}
            </Typography>
            <Typography fontSize={12}>{t('steps.step3.description')}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
});

import React from 'react';

import { CardProps } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

export type ContentBannerProps = {
  actionLabel?: string;
  description: string | React.ReactElement;
  subtitle?: React.ReactElement;
  title: string;
  onAction?: () => void;
  cardSx?: CardProps['sx'];
  subActionLabel?: string;
  icon?: JSX.Element;
  onSubAction?: () => void;
};

export const ContentBanner: React.FC<ContentBannerProps> = ({
  subActionLabel,
  actionLabel,
  onSubAction,
  onAction,
  description,
  title,
  subtitle,
  icon,
}) => (
  <Card className='w-full p-24'>
    <FlexLayout spacing={8} justifyContent='space-between'>
      <FlexLayout alignItems='start' spacing={24} className='w-full items-center'>
        <div>{Boolean(icon) && icon}</div>

        <FlexLayout
          className='h-full w-full flex-col items-start @sm:flex-row @sm:items-center'
          spacing={8}
          justifyContent='space-between'
        >
          <FlexLayout direction='column' alignItems='start' spacing={subtitle ? 4 : 8}>
            <Heading size='h5'>{title}</Heading>

            {subtitle}
            {typeof description === 'string' && (
              <Body color='secondary' size='small'>
                {description}
              </Body>
            )}
            {typeof description !== 'string' && description}
          </FlexLayout>
          <FlexLayout spacing={8}>
            {Boolean(onSubAction && subActionLabel) && (
              <Button variant='outlined' color='primary' onClick={onSubAction}>
                {subActionLabel}
              </Button>
            )}
            {onAction && actionLabel && (
              <Button variant='filled' onClick={onAction} color='primary'>
                {actionLabel}
              </Button>
            )}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  </Card>
);
FlexLayout;

import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  searchValue: string;
};

type SearchMetadata = {
  assets: Asset[];
};

const useParseSearchMetadata = ({ searchValue }: Props) => {
  const { assets: marketAssets } = useMarkets();

  const findAssetFromValue = useCallback(
    (val: string) => marketAssets.find((a) => a.name.toLowerCase() === val || a.code.toLowerCase() === val),
    [marketAssets],
  );

  const metadata = useMemo((): SearchMetadata => {
    if (!searchValue) return { assets: [] };

    const searchStrings = searchValue.toLowerCase().replace(/,/g, ' ').split(' ');
    let assets: Asset[] = [];

    searchStrings.forEach((s) => {
      const asset = findAssetFromValue(s);
      if (asset) assets.push(asset);
    });

    return {
      assets,
    };
  }, [findAssetFromValue, searchValue]);

  return metadata;
};

export { useParseSearchMetadata };

import React from 'react';

import GridMUI, { GridProps } from '@mui/material/Grid';

const Grid: React.FC<GridProps> = ({ id, children, ...props }) => (
  <GridMUI id={id} {...props}>
    {children}
  </GridMUI>
);

export { Grid };

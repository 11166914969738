import { useCallback } from 'react';

import { api } from '@shared/api';

import * as Sentry from '@sentry/react';

import { useTransactionsCache } from './useTransactionsCache';

const useDeleteTransaction = () => {
  const { invalidateCache } = useTransactionsCache();

  const deleteTransaction = useCallback(
    async (orderUuid: string) => {
      try {
        await api.endpoints.cancelOrder({ params: { orderId: orderUuid } });
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        invalidateCache();
      }
    },
    [invalidateCache],
  );

  return {
    deleteTransaction,
  };
};

export { useDeleteTransaction };

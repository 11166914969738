import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTableData, EnhancedTable } from '@swyftx/aviary/molecules/EnhancedTable';
import { MarketMoversCard } from '@swyftx/aviary/organisms/Assets';
import {
  MarketMoversType,
  defaultMarketMoversTabs,
} from '@swyftx/aviary/organisms/Assets/MarketMoversCard/MarketMoversCard.types';

import { assetService } from '@shared/services';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { AssetsTableData } from '../../components/AssetsTable/AssetsTable.types';
import { useAssetsTable } from '../../components/AssetsTable/useAssetsTable';
import { CategoriesTable } from '../../components/CategoriesTable/CategoriesTable';
import { useMarketActivity } from '../../hooks/useMarketActivity';
import { useMarkets } from '../../hooks/useMarkets';
import { getEmptyContent, getEmptyIcon } from '../../utils';

const widgetId: DashboardWidgets = 'market-movements';

const MarketMoversWidget: React.FC = observer(() => {
  const [selectedMarketMover, setSelectedMarketMover] = useDashboardSyncState<MarketMoversType>({
    widgetId,
    stateKey: 'selectedMarketMover',
    defaultValue: 'top',
  });

  const { assets } = useMarkets();
  const favouriteAssets = useFavouriteAssets();
  const { gainers, losers, newListings, topAssets, ownedAssets } = useMarketActivity({
    assets,
  });

  const tableAssets = useMemo(() => {
    switch (selectedMarketMover) {
      case 'top':
        return topAssets;
      case 'favourites':
        return favouriteAssets;
      case 'gainers':
        return gainers;
      case 'losers':
        return losers;
      case 'new':
        return newListings;
      case 'owned':
        return ownedAssets;
      default:
        return [];
    }
  }, [favouriteAssets, gainers, losers, newListings, ownedAssets, selectedMarketMover, topAssets]);

  const numAssets = useMemo(() => {
    switch (selectedMarketMover) {
      case 'favourites':
      case 'owned':
        return undefined;
      default:
        return 10;
    }
  }, [selectedMarketMover]);

  const { headers, data, initialSort, onSort, setMarketTypeSort } = useAssetsTable({
    assets: tableAssets,
    numAssets,
    widgetId,
  });

  const { navigateToAsset } = useAssetNavigation();

  const onSelectItem = useCallback(
    (item: EnhancedTableData<AssetsTableData>) => {
      const asset = assetService.getAssetByName(item.asset.value as string);
      if (!asset) return;

      navigateToAsset(asset);
    },
    [navigateToAsset],
  );

  const onChangeTab = (value: MarketMoversType) => {
    setSelectedMarketMover(value);
    setMarketTypeSort(value);
  };

  const tableElement = useMemo(() => {
    switch (selectedMarketMover) {
      case 'categories':
        return <CategoriesTable />;
      default:
        return (
          <EnhancedTable<AssetsTableData>
            data-container='assets-table'
            headers={headers}
            data={data}
            sort={initialSort}
            cellClassName='py-8'
            itemsPerPage={10}
            inset
            onSort={onSort}
            onClickItem={onSelectItem}
          >
            <FlexLayout
              className='h-full w-full'
              direction='column'
              spacing={8}
              alignItems='center'
              justifyContent='center'
            >
              {getEmptyIcon(selectedMarketMover)}
              {getEmptyContent(selectedMarketMover)}
            </FlexLayout>
          </EnhancedTable>
        );
    }
  }, [data, headers, initialSort, onSelectItem, onSort, selectedMarketMover]);

  return (
    <MarketMoversCard
      title=''
      value={selectedMarketMover || 'top'}
      onChange={onChangeTab}
      tabs={defaultMarketMoversTabs}
      actions={null}
      tableElement={tableElement}
    />
  );
});

export { MarketMoversWidget };

import React, { useState, useContext } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

import { Image } from '@swyftx/aviary/atoms/Image';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { BalanceSuccess, BalanceFailure, BalanceFailureReasons } from '@shared/api/@types/migrator';
import { ExchangesEnum } from '@shared/enums';

import { PortfolioTransferContext } from '@routes/PortfolioTransfer/PortfolioTransfer.context';
import { AssetTable } from '@routes/PortfolioTransfer/components/AssetTable';
import { ConfirmModal } from '@routes/PortfolioTransfer/components/ConfirmModal';

interface Props {
  onNext: () => void;
}

type Data = BalanceSuccess | BalanceFailure;
const feeFreeExchanges: ExchangesEnum[] = [];

const AccordionContents: React.FC<{
  expanded: string | false;
  heading: string;
  id: string;
  subHeading: string;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  items?: Data[];
  tableDisabled?: boolean;
  onSelectedChange?: (selectedItems: Data[]) => void;
}> = ({ expanded, handleChange, heading, subHeading, id, items, tableDisabled, onSelectedChange }) => {
  const [selected, setSelected] = useState<Data[]>([]);

  const handleSelectedChange = React.useCallback(
    (selectedItems: Data[]) => {
      setSelected(selectedItems);

      if (onSelectedChange) {
        onSelectedChange(selectedItems);
      }
    },
    [onSelectedChange],
  );

  return (
    <Accordion
      expanded={expanded === id}
      onChange={handleChange(id)}
      disableGutters
      sx={{
        borderRadius: 2,
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        border: '1px solid #DCE1E7',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack flex='1'>
          <Typography sx={{ flexShrink: 0 }}>{heading}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{subHeading}</Typography>
        </Stack>
        <Typography sx={{ color: 'text.secondary', alignSelf: 'center', pr: 2 }}>
          {id === 'supported' ? `${selected.length} of ${items?.length ?? '0'}` : `${items?.length} assets`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <AssetTable items={items} onSelectedChange={handleSelectedChange} tableDisabled={tableDisabled} />
      </AccordionDetails>
    </Accordion>
  );
};

const SelectAssetsStep: React.FC<Props> = ({ onNext }) => {
  const { exchange, apiKey, apiSecret, balances } = useContext(PortfolioTransferContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const [, setErrored] = React.useState(false);
  const [expanded, setExpanded] = React.useState<string | false>('supported');
  const [selected, setSelected] = React.useState<Data[]>([]);

  const handleSelectedChange = React.useCallback((selectedItems: Data[]) => {
    setSelected(selectedItems);
  }, []);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTransfer = () => {
    setConfirmationDialogOpen(true);
  };

  const canMigrateArray: BalanceSuccess[] = balances.filter(
    (item): item is BalanceSuccess => 'canMigrate' in item && item.canMigrate === true,
  );

  const unsupportedArray: BalanceFailure[] = balances.filter(
    (item): item is BalanceFailure => 'reason' in item && item.reason === BalanceFailureReasons.UnsupportedAsset,
  );

  const belowMinArray: BalanceFailure[] = balances.filter(
    (item): item is BalanceFailure => 'reason' in item && item.reason === BalanceFailureReasons.BelowMin,
  );

  if (canMigrateArray.length === 0 && unsupportedArray.length === 0 && belowMinArray.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          p: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ my: 4, fontWeight: 700, fontSize: '24px' }}>No assets found</Typography>
        <Typography fontSize={{ sm: 14, md: 16 }} color='text.secondary'>
          Oh no! It looks like you don&apos;t have any assets on {exchange}.
        </Typography>
        <Image image='empty_wallet' className='h-[160px] w-[160px]' usePalette alt='empty wallet' />
      </Box>
    );
  }

  return (
    <>
      <Typography sx={{ mt: 1, fontWeight: 700, fontSize: '24px' }}>Selected Assets</Typography>
      <Typography fontSize={{ sm: 14, md: 16 }} color='text.secondary'>
        We&apos;ve used your API Key to view and read which assets are held on your {exchange} account. Select which
        assets you would like to transfer.
      </Typography>
      <Stack direction='column' width='100%'>
        <AccordionContents
          id='supported'
          heading='Supported assets'
          subHeading='Can be transferred to Swyftx.'
          expanded={expanded}
          handleChange={handleChange}
          items={canMigrateArray}
          onSelectedChange={handleSelectedChange}
        />
        <br />
        {belowMinArray.length > 0 && (
          <>
            <AccordionContents
              id='insufficient'
              heading='Insufficient balances'
              subHeading='Do not meet minimum withdrawal limits.'
              expanded={expanded}
              handleChange={handleChange}
              items={belowMinArray}
              tableDisabled
            />
            <br />
          </>
        )}

        {unsupportedArray.length > 0 && (
          <>
            <AccordionContents
              id='unsupported'
              heading='Unsupported'
              subHeading='Not currently supported on Swyftx.'
              expanded={expanded}
              handleChange={handleChange}
              items={unsupportedArray}
              tableDisabled
            />
            <br />
          </>
        )}
        <Button variant='contained' fullWidth type='submit' disabled={selected.length === 0} onClick={handleTransfer}>
          Transfer
        </Button>
      </Stack>
      {exchange && apiKey && apiSecret && selected && (
        <ConfirmModal
          selectedAssets={selected}
          exchange={exchange}
          onSuccess={() => {
            setConfirmationDialogOpen(false);
            onNext();
          }}
          handleCancel={(cancelError) => {
            setConfirmationDialogOpen(false);
            setErrored(cancelError);
          }}
          open={confirmationDialogOpen}
          apiKey={apiKey}
          apiSecret={apiSecret}
          feeFree={exchange && feeFreeExchanges.includes(exchange)}
        />
      )}
    </>
  );
};

export { SelectAssetsStep };

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { OTPInput } from '@swyftx/aviary/molecules/OTPInput/OTPInput';

import { LoginContext } from '@routes/Login/Login.context';
import { LoginStep } from '@routes/Login/types';

import { observer } from 'mobx-react-lite';

import { LoginStepFooter } from '../LoginStepFooter';
import { LoginTitle } from '../LoginTitle';

const TWO_FACTOR_LOGIN_STEP = 2;

const TwoFactorLogin: React.FC = observer(() => {
  const { mfaLogin, error, setLoginStep, setShowCreateAccount, submitting } = useContext(LoginContext);
  const [code, setCode] = useState('');
  const { t } = useTranslation('login');

  useEffect(() => {
    const tryMfaLogin = async (mfaCode: string) => {
      const response = await mfaLogin(mfaCode);
      if (!response) setCode('');
    };

    setShowCreateAccount(false);
    if (code.length === 6) tryMfaLogin(code);
  }, [code, mfaLogin, setShowCreateAccount]);

  const onLogin = useCallback(() => mfaLogin(code), [mfaLogin, code]);

  return (
    <FlexLayout
      direction='column'
      className='h-full w-full'
      alignItems='center'
      spacing={32}
      justifyContent='space-between'
    >
      <LoginTitle
        id='twoFactorLogin.title'
        title='Sign In'
        subTitle='Complete 2FA'
        subTitleDescription='Please enter the 6-digit code from your two factor authentication.'
      />
      <Box width='100%' alignItems='center' justifyContent='center' id='twoFactorLogin.form.codeInput.container'>
        <FlexLayout direction='column' spacing={8}>
          <OTPInput
            id='twoFactorLogin.form.codeInput'
            error={error.length > 0}
            onOtpChange={(newCode) => setCode(newCode)}
            value={code}
          />
          {error && <Body color='error'>{error}</Body>}
        </FlexLayout>

        <Button
          id='twoFactorLogin.buttonLabels.useRecoveryCode'
          variant='ghost'
          className='mt-8 w-full'
          color='accent'
          onClick={() => setLoginStep(LoginStep.TwoFactorRecovery)}
        >
          {t('twoFactorLogin.buttonLabels.useRecoveryCode')}
        </Button>

        <Button
          id='twoFactorLogin.buttonLabels.lostAuthenticator'
          variant='ghost'
          className='mt-8 w-full'
          color='accent'
          onClick={() => setLoginStep(LoginStep.TwoFactorLost)}
        >
          {t('twoFactorLogin.buttonLabels.lostAuthenticator')}
        </Button>
      </Box>

      <LoginStepFooter
        step={TWO_FACTOR_LOGIN_STEP}
        buttonText={t('twoFactorLogin.buttonLabels.verify')}
        onNext={onLogin}
        onBack={() => setLoginStep(LoginStep.SignIn)}
        buttonLoading={submitting}
        disableButton={!code}
      />
    </FlexLayout>
  );
});

TwoFactorLogin.displayName = 'TwoFactorLogin';

export { TwoFactorLogin };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Divider } from '@swyftx/react-web-design-system';

import { WalletListHideZeroBalancesFilter } from '@routes/Wallet/components/WalletList/components/WalletListFilterBar/components';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { observer } from 'mobx-react-lite';
import { useSize } from 'react-use';
import { DustSmallBalancesButton } from 'src/lib/markets/components';

import { PortfolioBreakdownBalances } from './components/PortfolioBreakdownBalances';
import { PortfolioBreakdownBaseCurrency } from './components/PortfolioBreakdownBaseCurrency';

const SMALL_WIDTH = 355;

const PortfolioBreakdownTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const [widthSize, { width }] = useSize(<Box width='100%' height={0} />);
  const { t } = useTranslation('dashboard');

  return (
    <DashboardBaseTile
      dragProps={dragProps}
      tileName={tile.name}
      title={t('portfolioBreakdownTile.title')}
      allowWide={false}
      noPadding
      headerBarActionItem={
        <FlexLayout direction='row' spacing={8}>
          <DustSmallBalancesButton layout='icon' />
          <WalletListHideZeroBalancesFilter />
        </FlexLayout>
      }
    >
      {widthSize}

      <Box width='100%' height='100%' position='relative' display='flex'>
        <FlexLayout direction='column' className='w-full'>
          <PortfolioBreakdownBaseCurrency isSmall={width <= SMALL_WIDTH} />
          <Divider variant='fullWidth' orientation='horizontal' key='portfolioBreakdownTile.divider' />
          <PortfolioBreakdownBalances isSmall={width <= SMALL_WIDTH} />
        </FlexLayout>
      </Box>
    </DashboardBaseTile>
  );
});

PortfolioBreakdownTile.displayName = 'PortfolioBreakdownTile';

export { PortfolioBreakdownTile };

import React from 'react';
import { Navigate } from 'react-router-dom';

import { FullPageCard } from '@global-components/FullPageCard';

import { UserStore } from '@shared/store';

import { NavigationURLs } from 'src/lib/navigation/types';

import { PasswordResetCardContent } from './StepperContent/PasswordResetCardContent';

export const PasswordResetContainer: React.FC = () => {
  const { userProfile } = UserStore.useUserStore;

  if (!userProfile?.metadata?.passwordResetRequired) {
    return <Navigate to={NavigationURLs.Dashboard} />;
  }

  return (
    <FullPageCard id='password-reset'>
      <PasswordResetCardContent />
    </FullPageCard>
  );
};

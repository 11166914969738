import { Validator } from '../Validator';

export enum LengthRule {
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  EqualTo = 'EqualTo',
}

class LengthValidator extends Validator<string> {
  length: number;

  rule: LengthRule;

  constructor(length: number, rule: LengthRule) {
    super();
    this.length = length;
    this.rule = rule;
  }

  isValid = (input: string): boolean => {
    switch (this.rule) {
      case LengthRule.LessThan:
        return input.length < this.length;
      case LengthRule.LessThanOrEqual:
        return input.length <= this.length;
      case LengthRule.GreaterThan:
        return input.length > this.length;
      case LengthRule.GreaterThanOrEqual:
        return input.length >= this.length;
      case LengthRule.EqualTo:
        return input.length === this.length;
      default:
        return false;
    }
  };
}

export default LengthValidator;

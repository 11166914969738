import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { AccountStatusEnum } from '@shared/enums';

import { useAvo } from '@hooks/Avo/useAvo';
import { usePermissions } from '@hooks/Permissions';

import { Link } from 'lucide-react';
import { observer } from 'mobx-react-lite';

type Props = {
  onClickCta: () => void;
};

export const TaxPartnerKoinlyIntegration: React.FC<Props> = observer(({ onClickCta }) => {
  const { t } = useTranslation('profile.taxreports', { keyPrefix: 'koinlyIntegration' });
  const { pathname } = useLocation();
  const avo = useAvo();

  const handleConnect = useCallback(() => {
    avo.koinlyConnectAccountButtonClicked({
      screen: pathname,
    });
    onClickCta();
  }, [avo, onClickCta, pathname]);

  const { element: ConnectAccountButton } = usePermissions({
    accountStatus: AccountStatusEnum.SILVER,
    enabledElement: <Button onClick={handleConnect}>{t('buttonText')}</Button>,
    disabledOptions: {
      disabled: true,
      tooltip: true,
      invalidKYCLabel: t('connectAccountDisabled'),
    },
  });

  return (
    <FlexLayout className='mx-16 mb-16 flex flex-col justify-between gap-16 rounded-16 bg-color-background-surface-secondary p-16 sm:flex-row sm:gap-0'>
      <FlexLayout direction='row' spacing={16}>
        <FlexLayout className='items-center p-8'>
          <Link />
        </FlexLayout>
        <FlexLayout direction='column' spacing={4}>
          <FlexLayout direction='row' spacing={8}>
            <Body size='small' weight='emphasis'>
              {t('title')}
            </Body>
            <Chip size='sm' color='primary' variant='subtle'>
              {t('promotion')}
            </Chip>
          </FlexLayout>
          <Body size='small' color='secondary'>
            {t('subtitle')}
          </Body>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout className='items-center'> {ConnectAccountButton}</FlexLayout>
    </FlexLayout>
  );
});

import { EntityApplicationForm } from '@shared/api';

export const getFinishedApplicationStats = (application: Partial<EntityApplicationForm>, numberOfDocuments: number) => {
  switch (application.type) {
    case 'COMPANY':
      return {
        entityType: 'COMPANY',
        numberOfDirectors: application.directors?.length ?? 0,
        numberOfShareholders: application.shareholders?.length ?? 0,
        numberOfDocumentsUploaded: numberOfDocuments,
      };
    case 'SMSF_INDIVIDUAL':
      return {
        entityType: 'SMSF_INDIVIDUAL',
        numberOfTrustees: application.trustees?.length ?? 0,
        numberOfDocumentsUploaded: numberOfDocuments,
      };
    case 'SMSF_CORPORATE':
      return {
        entityType: 'SMSF_CORPORATE',
        numberOfCorporateTrustees: application.trustees?.length ?? 0,
        numberOfDocumentsUploaded: numberOfDocuments,
      };
    case 'TRUST_INDIVIDUAL':
      return {
        entityType: 'TRUST_INDIVIDUAL',
        numberOfTrustees: application.trustees?.length ?? 0,
        numberOfBeneficiaries: application.beneficiaries?.length ?? 0,
        numberOfDocumentsUploaded: numberOfDocuments,
      };
    case 'TRUST_CORPORATE':
      return {
        entityType: 'TRUST_CORPORATE',
        numberOfDirectors: application.directors?.length ?? 0,
        numberOfShareholders: application.shareholders?.length ?? 0,
        numberOfBeneficiaries: application.beneficiaries?.length ?? 0,
        numberOfDocumentsUploaded: numberOfDocuments,
      };
  }
};

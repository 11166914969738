import { Theme } from '@mui/material';

import {
  DefaultDarkTheme,
  EntityFourDarkTheme,
  EntityOneDarkTheme,
  EntityThreeDarkTheme,
  EntityTwoDarkTheme,
  HighContrastDarkTheme,
} from './dark';
import {
  DefaultLightTheme,
  EntityFourLightTheme,
  EntityOneLightTheme,
  EntityThreeLightTheme,
  EntityTwoLightTheme,
  HighContrastLightTheme,
} from './light';
import {
  DefaultMidnightTheme,
  EntityFourMidnightTheme,
  EntityOneMidnightTheme,
  EntityThreeMidnightTheme,
  EntityTwoMidnightTheme,
  HighContrastMidnightTheme,
} from './midnight';

export enum DisplayModes {
  DARK = 'dark',
  LIGHT = 'light',
  MIDNIGHT = 'midnight',
  AUTO = 'auto',
}

export enum AvailableThemes {
  DEFAULT = 'default',
  HIGH_CONTRAST = 'high_contrast',
  ENTITY_ONE = '0',
  ENTITY_TWO = '1',
  ENTITY_THREE = '2',
  ENTITY_FOUR = '3',
}

/** List of supported themes (add themes using this convention) */
const Themes: { [key in DisplayModes]: Record<AvailableThemes, Theme> } = {
  [DisplayModes.LIGHT]: {
    [AvailableThemes.DEFAULT]: DefaultLightTheme,
    [AvailableThemes.HIGH_CONTRAST]: HighContrastLightTheme,
    [AvailableThemes.ENTITY_ONE]: EntityOneLightTheme,
    [AvailableThemes.ENTITY_TWO]: EntityTwoLightTheme,
    [AvailableThemes.ENTITY_THREE]: EntityThreeLightTheme,
    [AvailableThemes.ENTITY_FOUR]: EntityFourLightTheme,
  },
  [DisplayModes.DARK]: {
    [AvailableThemes.DEFAULT]: DefaultDarkTheme,
    [AvailableThemes.HIGH_CONTRAST]: HighContrastDarkTheme,
    [AvailableThemes.ENTITY_ONE]: EntityOneDarkTheme,
    [AvailableThemes.ENTITY_TWO]: EntityTwoDarkTheme,
    [AvailableThemes.ENTITY_THREE]: EntityThreeDarkTheme,
    [AvailableThemes.ENTITY_FOUR]: EntityFourDarkTheme,
  },
  [DisplayModes.MIDNIGHT]: {
    [AvailableThemes.DEFAULT]: DefaultMidnightTheme,
    [AvailableThemes.HIGH_CONTRAST]: HighContrastMidnightTheme,
    [AvailableThemes.ENTITY_ONE]: EntityOneMidnightTheme,
    [AvailableThemes.ENTITY_TWO]: EntityTwoMidnightTheme,
    [AvailableThemes.ENTITY_THREE]: EntityThreeMidnightTheme,
    [AvailableThemes.ENTITY_FOUR]: EntityFourMidnightTheme,
  },
  [DisplayModes.AUTO]: {
    [AvailableThemes.DEFAULT]: DefaultLightTheme,
    [AvailableThemes.HIGH_CONTRAST]: HighContrastLightTheme,
    [AvailableThemes.ENTITY_ONE]: EntityOneLightTheme,
    [AvailableThemes.ENTITY_TWO]: EntityTwoLightTheme,
    [AvailableThemes.ENTITY_THREE]: EntityThreeLightTheme,
    [AvailableThemes.ENTITY_FOUR]: EntityFourLightTheme,
  },
};

export const ThemeNames: { [key in DisplayModes]: string } = {
  [DisplayModes.LIGHT]: 'Light Theme',
  [DisplayModes.DARK]: 'Dark Theme',
  [DisplayModes.MIDNIGHT]: 'Midnight Theme',
  [DisplayModes.AUTO]: 'System Theme',
};

export default Themes;

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Profile: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9846 2.06451C9.0329 2.06451 6.63908 4.45655 6.63839 7.40848L6.63839 7.40975H7.38839L6.6384 7.40721L6.63839 7.40848C6.62911 10.3515 9.00712 12.746 11.9497 12.7559L11.9522 12.7559H11.9846C14.9371 12.7559 17.3298 10.3619 17.3298 7.40975C17.3298 4.45744 14.9369 2.06451 11.9846 2.06451ZM8.13839 7.40975C8.13839 5.28607 9.8605 3.56451 11.9846 3.56451C14.1085 3.56451 15.8298 5.28587 15.8298 7.40975C15.8298 9.53384 14.1083 11.2559 11.9846 11.2559H11.9534C9.83985 11.2481 8.13122 9.52734 8.13839 7.41229L8.13839 7.40975Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9846 14.5958C10.0349 14.5958 8.14057 14.7404 6.71062 15.2198C5.99184 15.4608 5.33469 15.8042 4.85052 16.3068C4.34855 16.8279 4.06415 17.489 4.06415 18.2724C4.06415 19.0543 4.34601 19.7157 4.8451 20.2388C5.3269 20.7437 5.9817 21.0904 6.69977 21.3345C8.12804 21.8201 10.0232 21.9701 11.9846 21.9701C13.9344 21.9701 15.8285 21.8252 17.2583 21.3456C17.9769 21.1046 18.6339 20.7611 19.118 20.2586C19.6198 19.7376 19.9041 19.0766 19.9041 18.2934C19.9041 17.5117 19.6223 16.8505 19.1234 16.3275C18.6417 15.8226 17.987 15.4758 17.2691 15.2317C15.8411 14.746 13.9461 14.5958 11.9846 14.5958ZM5.56415 18.2724C5.56415 17.8849 5.69261 17.5947 5.93079 17.3475C6.18676 17.0818 6.59705 16.8399 7.18744 16.642C8.37582 16.2435 10.0667 16.0958 11.9846 16.0958C13.9117 16.0958 15.6015 16.2489 16.7861 16.6518C17.3745 16.8519 17.7831 17.0957 18.0381 17.363C18.2758 17.6121 18.4041 17.9045 18.4041 18.2934C18.4041 18.6806 18.2758 18.9707 18.0376 19.218C17.7817 19.4836 17.3715 19.7255 16.7812 19.9235C15.5931 20.322 13.9025 20.4701 11.9846 20.4701C10.0575 20.4701 8.3674 20.3172 7.18257 19.9144C6.59409 19.7143 6.18539 19.4705 5.93031 19.2032C5.69253 18.954 5.56415 18.6615 5.56415 18.2724Z'
      fill='currentColor'
    />
  </svg>
);

export { Profile };

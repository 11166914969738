export enum AssetServiceAssetType {
  Fiat = 'fiat',
  Crypto = 'crypto',
}

export const EnabledStatuses = {
  Enabled: 'ENABLED',
  TemporarilyDisabled: 'TEMPORARILY_DISABLED',
  PermanentlyDisabled: 'PERMANENTLY_DISABLED',
} as const;

export type EnabledStatus = (typeof EnabledStatuses)[keyof typeof EnabledStatuses];

export type AssetServiceAssetsQuery = {
  includeNetworks?: boolean;
  includeMetadata?: boolean;
  basicMetadata?: boolean;
  includeCategories?: boolean;
  id?: number;
  assetType?: AssetServiceAssetType;
};

export interface AssetServiceAsset {
  id: number;
  baseAsset: boolean;
  code: string;
  description: string;
  // eslint-disable-next-line no-restricted-syntax
  colour: string;
  icon?: string;
  delisting: boolean;
  name: string;
  withdrawEnabled: boolean;
  depositEnabled: boolean;
  priceScale: number;
  tradable: boolean;
  buyDisabled: boolean;
  primary: number;
  secondary: number;
  minDeposit: string;
  minWithdrawal: string;
  minimumOrderIncrement: any;
  minimumOrder: string;
  miningFee: string;
  contract?: string;
  assetDepositScale?: number;
  type: AssetServiceAssetType;
  minWithdrawalIncrementE: number;
  metadata?: Metadata;
  categoryIds: number[];
  cryptoNetworks?: CryptoNetwork[];
}

export interface ExchangeAsset {
  id: number;
  exchangeId: number;
  assetId: number;
  enabled: boolean;
  exchange: AssetServiceExchange;
}

export interface AssetServiceExchange {
  id: number;
  enabled: boolean;
}

export interface Metadata {
  assetId: number;
  data: CoinGeckoData;
}

export interface CoinGeckoData {
  id: any;
  code: string;
  description?: string;
  timestamp: number;
  name: string;
  altName: string;
  category: string;
  categories?: string[];
  rank?: number;
  rankSuffix: string;
  marketCap: number;
  spread?: number;
  volume: {
    '24H': number;
  };
  priceChange?: CoinGeckoPriceChange;
  urls: CoinGeckoUrls;
  supply: CoinGeckoSupply;
  mineable?: boolean;
}

export interface CoinGeckoPriceChange {
  week: number;
  month: number;
}

export interface CoinGeckoUrls {
  explorer: string;
  reddit?: string;
  twitter: string;
  website: string;
}

export interface CoinGeckoSupply {
  circulating: number;
  total?: number;
  max?: number | string;
}

export interface CryptoNetwork {
  withdrawFee: string;
  withdrawMin: string;
  minWithdrawalIncrementE: number;
  id: number;
  network: string;
  networkName: string;
  memoEnabled: number;
  destinationTagEnabled: boolean;
  messageEnabled: number;
  paymentIdEnabled: number;
  addressFormats?: string;
  withdrawStatus: EnabledStatus;
  depositStatus: EnabledStatus;
}

export interface AssetServiceAssetsResponse {
  data: AssetServiceAsset[];
}

import React from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  title?: string;
  subTitle?: string;
  isSmall?: boolean;
};

const NoSearchResultsFound: React.FC<Props> = ({ title, subTitle, isSmall = false }) => {
  const titleToDisplay = title ?? 'No search results';
  const subTitleToDisplay = subTitle ?? 'No matching results found';

  return (
    <Stack direction='column' spacing={1} alignItems='center'>
      <Image
        image='empty_search_results'
        usePalette
        className={cn('block', isSmall ? 'mt-0 h-[120px] w-[120px]' : 'mt-32 h-[160px] w-[160px]')}
      />
      <Typography fontWeight='600' fontSize={18}>
        {titleToDisplay}
      </Typography>
      <Typography color='GrayText.secondary' fontWeight='400' fontSize={16}>
        {subTitleToDisplay}
      </Typography>
    </Stack>
  );
};

export { NoSearchResultsFound };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { useNavigateRoute } from 'src/lib/navigation/hooks';

import { ShopItemType } from './ShopItem.types';

const ShopItem: React.FC<ShopItemType> = ({
  id,
  title,
  colors,
  defaultColor,
  price,
  internalUrl,
  buttonText,
  chipText,
}) => {
  const { navigate } = useNavigateRoute();

  const navigateToItem = () => {
    navigate(internalUrl);
  };

  return (
    <Card className='cursor-pointer p-32 hover:border-color-border-main' id={id} onClick={navigateToItem}>
      <FlexLayout spacing={16} direction='column'>
        <FlexLayout className='rounded-16 bg-color-background-surface-secondary p-32'>
          <img src={colors[defaultColor]} alt={title} className='max-h-[300px]' />
        </FlexLayout>

        <FlexLayout direction='column' spacing={4}>
          <FlexLayout direction='row' spacing={8} className='flex items-center'>
            <Heading size='h6'>{title}</Heading>
            {chipText && <Chip size='sm'> {chipText} </Chip>}
          </FlexLayout>

          <Body size='small' color='secondary'>
            {price}
          </Body>
          <Button variant='ghost' color='primary'>
            {buttonText}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};
export { ShopItem };

import { useCallback } from 'react';

import { api } from '@shared/api';

import { useQuery } from '@tanstack/react-query';

type Props = {
  userId?: string;
};

const useFetchAutoInvest = ({ userId }: Props) => {
  const fetchAutoInvestments = useCallback(async () => {
    const { data } = await api.endpoints.getRecurringOrders({ query: { assetsFilter: 'all' } });
    return data.sort((a, b) => {
      if (a.createdTime > b.createdTime) return -1;
      if (a.createdTime < b.createdTime) return 1;
      return 0;
    });
  }, []);

  const { data, isFetching } = useQuery({
    queryKey: ['auto-invest', userId],
    queryFn: () => fetchAutoInvestments(),
    keepPreviousData: true,
    staleTime: 5000,
    refetchInterval: 60_000, // Refetch every 1 minute
  });

  return {
    autoInvestments: data,
    isFetching,
  };
};

export { useFetchAutoInvest };

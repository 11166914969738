import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Category } from '@shared/api/@types/categories';
import { assetService } from '@shared/services';

import { useSingleCategoryAnalytics } from '@hooks/Analytics/Categories/useSingleCategoryAnalytics';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SingleCategoryAssetTableData } from '../SingleCategory.types';
import { useSingleCategoryTable } from '../hooks';

type Props = {
  category: Category;
  loaded: boolean;
};

const SingleCategoryAssetsTable: React.FC<Props> = observer(({ category, loaded }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const isXs = useTailwindBreakpoint('xs');
  const { headers, data, initialSort, onSort } = useSingleCategoryTable({
    category,
  });
  const { trackSingleCategoryAssetFromTableOpened } = useSingleCategoryAnalytics();

  const { navigate } = useNavigateRoute();

  const onSelectItem = useCallback(
    (item: EnhancedTableData<SingleCategoryAssetTableData>) => {
      const code = assetService.getAssetByName(item.asset.value as string)?.code;
      if (!code) return;

      navigate(NavigationURLs.SingleAsset, {
        pathParams: { asset: code },
      });

      trackSingleCategoryAssetFromTableOpened(category.name, code);
    },
    [category.name, navigate, trackSingleCategoryAssetFromTableOpened],
  );

  return (
    <ElementContainer title={t('titles.assets')}>
      <Card
        shadow={false}
        style={{ width: '100%', maxHeight: isXs ? '' : '600px', minHeight: loaded ? '' : '600px' }}
        className={`overflow-hidden @container ${!loaded ? 'animate-pulse bg-color-background-surface-active' : ''}`}
      >
        <EnhancedTable<SingleCategoryAssetTableData>
          className={loaded ? '' : 'hidden'}
          data-container='single-category-table'
          headers={headers}
          data={data}
          sort={initialSort}
          onSort={onSort}
          onClickItem={onSelectItem}
        />
      </Card>
    </ElementContainer>
  );
});

SingleCategoryAssetsTable.displayName = 'SingleCategoryAssetsTable';

export { SingleCategoryAssetsTable };

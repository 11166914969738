import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

const useSourceOfWealthAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const startedVerification = useCallback(() => {
    avo.sowStarted({
      screen: pathname,
    });
  }, [avo, pathname]);

  const submittedVerification = useCallback(() => {
    avo.sowSubmitted({
      screen: pathname,
    });
  }, [avo, pathname]);

  const stepStarted = useCallback(
    (stepId: string) => {
      avo.sowStepStarted({
        screen: pathname,
        stepId,
      });
    },
    [avo, pathname],
  );

  const stepCompleted = useCallback(
    (stepId: string) => {
      avo.sowStepCompleted({
        screen: pathname,
        stepId,
      });
    },
    [avo, pathname],
  );

  const backClickedOnStep = useCallback(
    (stepId: string) => {
      avo.sowBackClicked({
        screen: pathname,
        stepId,
      });
    },
    [avo, pathname],
  );

  const moreInformationSubmitted = useCallback(() => {
    avo.sowAdditionalInformationSubmitted({
      screen: pathname,
    });
  }, [avo, pathname]);

  const exited = useCallback(() => {
    avo.sowExited({
      screen: pathname,
    });
  }, [avo, pathname]);

  return {
    startedVerification,
    submittedVerification,
    stepStarted,
    stepCompleted,
    backClickedOnStep,
    moreInformationSubmitted,
    exited,
  };
};

export { useSourceOfWealthAnalytics };

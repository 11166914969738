import { Big } from '@shared/safe-big';

const betweenAndIncludes = (num: number, start: number, end: number) => {
  const isBetween = num >= start && num <= end;
  return isBetween;
};

const between = (num: number, start: number, end: number) => {
  const isBetween = num > start && num < end;
  return isBetween;
};

const returnClosest = (value: number, ...numbers: number[]): number => {
  for (let i = 1; i < numbers.length; i += 1) {
    const min = numbers[i - 1];
    const max = numbers[i];

    if (between(value, min, max)) {
      const mid = max - (max - min) / 2;

      if (value < mid) return min;

      return max;
    }
  }

  return value;
};

const returnClosestWithBuffer = (value: number, buffer: number, numbers: number[]): number => {
  if (!numbers.length) return value;

  const closest = returnClosest(value, ...numbers);
  if (Big(value).minus(closest).abs().gt(buffer)) return value;

  return closest;
};

/**
 * Returns a number where the given value is limited to the given range.
 */
const clamp = (val: number, min: number, max: number) => Math.min(Math.max(val, min), max);

const getScale = (val: number) => {
  try {
    const valString = Big(val).toString();

    if (valString.includes('e')) {
      const decimalValue = valString.split('.')?.[1];
      const [value, decimal] = decimalValue.split('e-');

      return Big(value.length).plus(decimal).toNumber();
    } else {
      return valString.split('.')?.[1].length || 0;
    }
  } catch (e) {
    return 0;
  }
};

export { betweenAndIncludes, between, returnClosest, returnClosestWithBuffer, clamp, getScale };

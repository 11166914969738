import React, { useMemo } from 'react';

import { ProfileAffiliateProgram } from './AffiliateProgram';
import { ProfileApiKeys } from './ApiKeys';
import { ApiKeysProvider } from './ApiKeys/ApiKeys.context';
import { ProfileEntityMembers, ProfileEntitySecurity, ProfileEntityVerification } from './Entities';
import { ProfileInformation } from './Information';
import { ProfileReferAFriend } from './ReferAFriend';
import { ProfileSecurity } from './Security';
import { ProfileSettings } from './Settings';
import { ProfileTaxReports } from './TaxReports';
import { TradeFees } from './TradeFees';
import { ProfileVerification } from './Verification';
import { ProfileRoute } from './profile.types';
import { ProfileWrapper } from '../components';

interface Props {
  route: ProfileRoute;
}

export const Profile: React.FC<Props> = (props) => {
  const { route } = props;

  const content = useMemo(() => {
    switch (route) {
      case ProfileRoute.ProfileInformation:
        return <ProfileInformation />;
      case ProfileRoute.ProfileVerification:
        return <ProfileVerification />;
      case ProfileRoute.ProfileSettings:
        return <ProfileSettings />;
      case ProfileRoute.ProfileSecurity:
        return <ProfileSecurity />;
      case ProfileRoute.ProfileTradeFees:
        return <TradeFees />;
      case ProfileRoute.ProfileAffiliateProgram:
        return <ProfileAffiliateProgram />;
      case ProfileRoute.ProfileReferAFriend:
        return <ProfileReferAFriend />;
      case ProfileRoute.ProfileApiKeys:
        return (
          <ApiKeysProvider>
            <ProfileApiKeys />
          </ApiKeysProvider>
        );
      case ProfileRoute.ProfileTaxReports:
        return (
          <ApiKeysProvider>
            <ProfileTaxReports />
          </ApiKeysProvider>
        );
      case ProfileRoute.ProfileEntityVerification:
        return <ProfileEntityVerification />;
      case ProfileRoute.ProfileEntitySecurity:
        return <ProfileEntitySecurity />;
      case ProfileRoute.ProfileEntityLinkedMembers:
        return <ProfileEntityMembers />;
      default:
        route satisfies never;
        return <></>;
    }
  }, [route]);

  return (
    <ProfileWrapper id={route} path={route} selectedTabId={route}>
      {content}
    </ProfileWrapper>
  );
};

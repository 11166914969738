import { AssetFilter } from '@hooks/Assets/useAssets';

const searchPlaceholders: Partial<{ [key in AssetFilter]: string }> = {
  [AssetFilter.All]: 'tradePanel.search.placeholders.all',
  [AssetFilter.Owned]: 'tradePanel.search.placeholders.owned',
  [AssetFilter.Favourites]: 'tradePanel.search.placeholders.favourite',
  [AssetFilter.Bundles]: 'tradePanel.search.placeholders.bundles',
};

export { searchPlaceholders };

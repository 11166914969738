import React, { useEffect, useState } from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Clock } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api/@types/markets';
import { AppStore } from '@shared/store';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

import { SingleWalletEarnTransactionHistoryList } from './SingleWalletEarnTransactionHistoryList';
import { SingleWalletTransactionHistoryList } from './SingleWalletTransactionHistoryList';

type Props = {
  asset: Asset;
};

enum TransactionHistorySelection {
  Transactions = 'transactions',
  Earn = 'earn',
}

const SingleWalletTransactionHistory: React.FC<Props> = observer(({ asset }) => {
  const { staking } = useAssetBalance(asset);
  const [selectedOption, setSelectedOption] = useState<TransactionHistorySelection>(
    TransactionHistorySelection.Transactions,
  );
  const { isDemo } = AppStore.useAppStore;

  useEffect(() => {
    if (isDemo) setSelectedOption(TransactionHistorySelection.Transactions);
  }, [isDemo]);

  if (!asset) return null;

  return (
    <Accordion defaultValue='transactions' type='single' collapsible>
      <AccordionItem value='transactions'>
        <AccordionTrigger aria-controls='panel1a-content' id='panel1a-header'>
          <FlexLayout direction='row' spacing={8} alignItems='center'>
            <Clock className='text-color-text-primary' />
            <Body
              color={selectedOption === TransactionHistorySelection.Transactions ? 'selected' : 'secondary'}
              className='user-select-none cursor-pointer'
              weight={selectedOption === TransactionHistorySelection.Transactions ? 'bold' : undefined}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedOption(TransactionHistorySelection.Transactions);
              }}
            >
              Transaction history
            </Body>

            {staking.isStakeable && !isDemo && (
              <Body
                color={selectedOption === TransactionHistorySelection.Earn ? 'selected' : 'secondary'}
                className='user-select-none cursor-pointer'
                weight={selectedOption === TransactionHistorySelection.Earn ? 'bold' : undefined}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOption(TransactionHistorySelection.Earn);
                }}
              >
                Earn history
              </Body>
            )}
          </FlexLayout>
        </AccordionTrigger>
        <AccordionContent>
          <Separator className='w-full' />

          {selectedOption === TransactionHistorySelection.Transactions ? (
            <SingleWalletTransactionHistoryList asset={asset} />
          ) : (
            <SingleWalletEarnTransactionHistoryList asset={asset} />
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
});

export { SingleWalletTransactionHistory };

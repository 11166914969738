import { useMemo } from 'react';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { PriceAlert, TriggeredPriceAlert } from '../dynamicPriceAlerts.types';

type Props = {
  alert: PriceAlert | TriggeredPriceAlert;
};

const useDynamicPriceAlert = ({ alert }: Props) => {
  const { getAssetById } = useMarkets();

  const primaryAsset = useMemo(() => getAssetById(alert.primary), [alert.primary, getAssetById]);
  const secondaryAsset = useMemo(() => getAssetById(alert.secondary), [alert.secondary, getAssetById]);

  return {
    primaryAsset,
    secondaryAsset,
  };
};

export { useDynamicPriceAlert };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Wizard from '@global-components/Wizard';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { UIStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';

import { DisableTwoFactorConfirmStep } from './steps/DisableTwoFactorConfirmStep';
import { DisableTwoFactorVerifyStep } from './steps/DisableTwoFactorVerifyStep';

type Props = {
  onClose: () => void;
};

enum Steps {
  Confirm = 0,
  Verify = 1,
}

export const DisableTwoFactorWizard: React.FC<Props> = ({ onClose }) => {
  const { addMessageBox } = UIStore.useUIStore;
  const { t } = useTranslation('profile.security', { keyPrefix: 'twoFactorAuth' });

  const [stepIndex, setStepIndex] = useState(0);
  const logout = useLogout();

  const goToVerify = () => {
    setStepIndex(Steps.Verify);
  };

  const onSuccess = () => {
    addMessageBox({
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      content: <SuccessMessageBox title={t('disable.labels.success')} />,
    });
    onClose();
    setTimeout(() => {
      logout();
    }, 2000);
  };

  return (
    <Wizard.Container open onClose={onClose} stepIndex={stepIndex}>
      <DisableTwoFactorConfirmStep onClose={onClose} onAction={goToVerify} />
      <DisableTwoFactorVerifyStep onClose={onClose} onAction={onSuccess} />
    </Wizard.Container>
  );
};

import React, { useCallback } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Search } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgetRowProps } from 'src/lib/dashboard/types';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';
import { DustSmallBalancesButton } from 'src/lib/markets/components/DustSmallBalancesButton/DustSmallBalancesButton';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { usePortfolioTable } from 'src/lib/portfolio/hooks';
import { PortfolioTableData } from 'src/lib/portfolio/types';

import { PortfolioAssetAllocationWidgetMenu } from './PortfolioAssetAllocationWidgetMenu';

const widgetId: DashboardWidgets = 'portfolio-balance';

const PortfolioAssetAllocationWidget: React.FC<DashboardWidgetRowProps> = observer(({ atLeast }) => {
  const { navigate } = useNavigateRoute();
  const { balances } = UserStore.useUserStore;

  const [showSmallBalances, setShowSmallBalances] = useDashboardSyncState<boolean>({
    widgetId,
    stateKey: 'showSmallBalances',
    defaultValue: true,
  });

  const { headers, data, initialSort, search, onSort, setSearch } = usePortfolioTable({
    widgetId,
    showSmallBalances,
  });

  const viewPortfolio = useCallback(() => {
    navigate(NavigationURLs.WalletsRoot);
  }, [navigate]);

  const onSelectItem = useCallback(
    (item: EnhancedTableData<PortfolioTableData>) => {
      navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: item.asset.value } });
    },
    [navigate],
  );

  if (Object.values(balances).every((balance: UserBalance) => Big(balance.availableBalance).lte(0))) return null;

  return (
    <ElementContainer
      title='Your assets'
      className='w-full'
      actions={
        <Body color='accent' size='small' weight='emphasis' onClick={viewPortfolio} className='cursor-pointer'>
          View portfolio
        </Body>
      }
    >
      <Card className='mt-16 h-full max-h-[600px] overflow-hidden @sm:max-h-[600px] @md:mt-0'>
        <FlexLayout
          className='h-[80px] border-b border-color-border-main p-16 @md:h-[96px] @md:p-24'
          alignItems='center'
          justifyContent='space-between'
          spacing={24}
        >
          <div className='w-full max-w-[340px]'>
            <Input
              placeholder='Search for...'
              variant='default'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <FlexLayout alignItems='center' spacing={8}>
            <DustSmallBalancesButton layout={atLeast?.sm ? 'default' : 'icon'} />

            <PortfolioAssetAllocationWidgetMenu
              showSmallBalances={showSmallBalances}
              setShowSmallBalances={setShowSmallBalances}
            />
          </FlexLayout>
        </FlexLayout>

        <EnhancedTable<PortfolioTableData>
          data-container='portfolio-allocation-table'
          headers={headers}
          data={data}
          cellClassName='py-12 px-8'
          inset
          sort={initialSort}
          onSort={onSort}
          onClickItem={onSelectItem}
        >
          <FlexLayout
            direction='column'
            spacing={8}
            alignItems='center'
            justifyContent='center'
            className='h-full w-full'
          >
            <Search className='h-48 w-48 text-color-text-accent' />
            <Body size='small' weight='emphasis'>
              No matching results found
            </Body>
          </FlexLayout>
        </EnhancedTable>
      </Card>
    </ElementContainer>
  );
});

export { PortfolioAssetAllocationWidget };

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronLeft, ArrowChevronRight } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';

import { UserStore } from '@shared/store';

import { useItemPagination } from '@hooks/useItemPagination/useItemPagination';

import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgetRowProps } from 'src/lib/dashboard/types';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';

import { GettingStartedCard } from './GettingStartedCard';
import { useBuildGettingStartedCards } from '../../hooks/useBuildGettingStartedCards';

const widgetId: DashboardWidgets = 'getting-started';

const GettingStartedWidget: React.FC<DashboardWidgetRowProps> = observer(({ atLeast }) => {
  const [hidden, setHidden] = useDashboardSyncState<boolean>({ widgetId, stateKey: 'hidden', defaultValue: false });
  const { items } = useBuildGettingStartedCards();
  const isXs = useTailwindBreakpoint('xs');
  const { isEntity } = UserStore.useUserStore;

  const itemsPerPage = useMemo(() => {
    if (isXs) return 1;

    if (atLeast?.lg) return 4;
    if (atLeast?.md) return 3;

    return 2;
  }, [atLeast?.lg, atLeast?.md, isXs]);

  const { hasAnotherPage, nextPage, prevPage, page } = useItemPagination({
    widgetId,
    items,
    itemsPerPage,
  });

  if (isEntity() || hidden) return null;

  return (
    <ElementContainer
      className='w-full'
      title='Getting started'
      titleAlignment='space-between'
      actions={
        <FlexLayout alignItems='center' className='w-full' justifyContent='end' spacing={8}>
          <Button size='sm' variant='outlined' onClick={() => setHidden(true)}>
            Dismiss all
          </Button>
          {itemsPerPage < items.length && (
            <>
              <Button
                size='sm'
                variant='outlined'
                layout='icon'
                leadingIcon={<ArrowChevronLeft />}
                disabled={page === 0}
                onClick={prevPage}
              />
              <Button
                size='sm'
                variant='outlined'
                layout='icon'
                onClick={nextPage}
                leadingIcon={<ArrowChevronRight />}
                disabled={!hasAnotherPage}
              />
            </>
          )}
        </FlexLayout>
      }
    >
      <FlexLayout spacing={24} className='px-1 py-1 sm:overflow-hidden'>
        {items.map(({ icon, title, content, onClick }) => (
          <GettingStartedCard
            key={title}
            icon={icon}
            title={title}
            content={content}
            onClick={onClick}
            direction='row'
            style={{ transform: `translateX(calc(-${page * 100}% - ${page * 24}px)`, transition: 'transform 300ms' }}
          />
        ))}
      </FlexLayout>
    </ElementContainer>
  );
});

export { GettingStartedWidget };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Skeleton } from '@swyftx/react-web-design-system';

const OrderDetailsModalSkeleton: React.FC = () => (
  <FlexLayout direction='column' spacing={16} className='p-16'>
    {[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
      <Skeleton key={`order-details-skeleton-${key}`} className='py-8' />
    ))}
  </FlexLayout>
);

export { OrderDetailsModalSkeleton };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeHeikinAsh: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.7766 3.87109C16.7766 3.45688 16.4408 3.12109 16.0266 3.12109C15.6124 3.12109 15.2766 3.45688 15.2766 3.87109V6.87109H14.1203C13.568 6.87109 13.1203 7.31881 13.1203 7.87109V13.16C13.1203 13.7123 13.568 14.16 14.1203 14.16H17.9328C18.4851 14.16 18.9328 13.7123 18.9328 13.16V7.87109C18.9328 7.31881 18.4851 6.87109 17.9328 6.87109H16.7766V3.87109ZM14.6203 12.66V8.37109H17.4328V12.66H14.6203Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.72266 5.19128C8.72266 4.77707 8.38687 4.44128 7.97266 4.44128C7.55844 4.44128 7.22266 4.77707 7.22266 5.19128L7.22266 8.19128H6.06641C5.51412 8.19128 5.06641 8.639 5.06641 9.19128V19.1288C5.06641 19.6811 5.51412 20.1288 6.06641 20.1288H9.87891C10.4312 20.1288 10.8789 19.6811 10.8789 19.1288V9.19128C10.8789 8.639 10.4312 8.19128 9.87891 8.19128H8.72266L8.72266 5.19128ZM6.56641 18.6288V9.69128H9.37891V18.6288H6.56641Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeHeikinAsh };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';

import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import env from '@shared/config';
import { StorageKey } from '@shared/storage';
import { UIStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';

const CheckForUpdate: React.FC = observer(() => {
  const { t } = useTranslation('profile.settings', { keyPrefix: 'version' });
  const { addMessageBox } = UIStore.useUIStore;
  const { is } = useContentBreakpoint();

  const handleUpdateVersion = () => {
    localStorage.setItem(StorageKey.SWYFTX_UI_VERSION, env.COMMIT_HASH);
    window.location.reload();
  };

  const checkForUpdate = async () => {
    const currentVersion = await localStorage.getItem(StorageKey.SWYFTX_UI_VERSION);
    const newestVersion = env.COMMIT_HASH;
    const upToDate = currentVersion === newestVersion;

    addMessageBox({
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      content: upToDate ? (
        <SuccessMessageBox title={t('labels.noUpdate')} content={t('labels.noUpdateDescription')} />
      ) : (
        <InformationMessageBox
          title={t('labels.updateAvailable')}
          content={t('labels.updateAvailableDescription')}
          footer={
            <Button variant='filled' size='sm' onClick={() => handleUpdateVersion()}>
              {t('buttonLabels.updateNow')}
            </Button>
          }
        />
      ),
    });
  };

  return (
    <Button variant={is.sm ? 'filled' : 'outlined'} onClick={() => checkForUpdate()} color='primary'>
      {t('buttonLabels.checkForUpdate')}
    </Button>
  );
});

CheckForUpdate.displayName = 'CheckForUpdate';

export { CheckForUpdate };

/* eslint-disable react/display-name */
import { forwardRef, LegacyRef } from 'react';
import { Namespace, useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';

import { PriceTicker } from '@global-components/PriceTicker';

import { Asset } from '@shared/api/@types/markets';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { getChangeColor } from '@utils/color';

import { observer } from 'mobx-react-lite';

type Props = {
  price: string | null;
  change: string;
  title: string;
  translationKey?: Namespace;
  asset?: Asset;
  format?: boolean;
  compact?: boolean;
};

export const AssetSummaryItem = observer(
  forwardRef(
    ({ change, price, title, asset, translationKey, format, compact }: Props, ref: LegacyRef<HTMLDivElement>) => {
      const baseAsset = useBaseAsset();

      const color = getChangeColor(change);
      const { t } = useTranslation(translationKey);
      const fontSize: NumericProps['size'] = compact ? 'small' : 'medium';

      return (
        <div ref={ref}>
          <FlexLayout
            className={cn(
              compact
                ? 'w-auto flex-row items-center gap-8 whitespace-nowrap'
                : 'w-auto flex-row items-start gap-0 whitespace-normal @sm:flex-col @md:w-[175px]',
              'pb-0',
            )}
          >
            <Body color='secondary' size='small' weight='emphasis'>
              {t(title as any)}
            </Body>
            <FlexLayout direction='row' alignItems='center' spacing={8}>
              <Tooltip title={price ?? 'N/A'}>
                <div>
                  <PriceTicker
                    isApplicable={price !== null}
                    price={price ?? '0'}
                    format={format}
                    currency={asset || baseAsset}
                    typographyProps={{
                      className: cn('truncate leading-[20px]', compact ? 'text-12' : 'text-12 sm:text-14'),
                    }}
                  />
                </div>
              </Tooltip>
              <Numeric color={color} size={fontSize} className='font-mono'>
                {change}
              </Numeric>
            </FlexLayout>
          </FlexLayout>
        </div>
      );
    },
  ),
);

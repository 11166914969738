import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DepositFilled, ReceiveCryptoFilled } from '@swyftx/aviary/icons/filled';
import { Button, Card, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';

const LOG_TAG = 'APP_HEADER_BASE_CURRENCY_SELECTOR_EMPTY';

const AppHeaderBaseCurrencySelectorNoBalance: React.FC = observer(() => {
  const { t } = useTranslation('common', { keyPrefix: 'headerWallet' });
  const countryAsset = useCountryAsset();
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const avo = useAvo();

  const depositFunds = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
  };

  const depositCrypto = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive);
  };

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      spacing={2}
      paddingX={3}
      paddingY={2}
      color='text.secondary'
    >
      {countryAsset && (
        <Card elevation={0} variant='outlined'>
          <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
            <DepositFilled className='my-8s' />
            <Typography fontWeight={600} fontSize={16} color='text.primary' textAlign='center'>
              {t('noBalance.fiat.title', { code: countryAsset.code })}
            </Typography>
            <Typography
              fontWeight={400}
              fontSize={14}
              color='text.secondary'
              textAlign='center'
              marginBottom='.5rem !important'
            >
              {t('noBalance.fiat.subTitle')}
            </Typography>
            <Button variant='contained' color='primary' onClick={depositFunds} fullWidth size='large'>
              {t('noBalance.fiat.deposit')}
            </Button>
          </Stack>
        </Card>
      )}

      <Card elevation={0} variant='outlined'>
        <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
          <ReceiveCryptoFilled className='my-8s' />
          <Typography fontWeight={600} fontSize={16} color='text.primary' textAlign='center'>
            {t('noBalance.crypto.title')}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={14}
            color='text.secondary'
            textAlign='center'
            marginBottom='.5rem !important'
          >
            {t('noBalance.crypto.subTitle')}
          </Typography>
          <Button variant='contained' color='primary' onClick={depositCrypto} fullWidth size='large'>
            {t('noBalance.crypto.deposit')}
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
});

export { AppHeaderBaseCurrencySelectorNoBalance };

type Props = {
  rect: DOMRect;
  borderRadius?: number;
};

const CLIP_PADDING = 6;

const TourGuideClip: React.FC<Props> = ({ rect, borderRadius = 8 }) => (
  <svg width='0' height='0' viewBox='0 0 400 400'>
    <mask id='tourGuideMask'>
      <rect x='0' y='0' width='100vw' height='100vh' fill='white' />
      <rect
        fill='black'
        x={rect.x - CLIP_PADDING}
        y={rect.y - CLIP_PADDING}
        width={rect.width + CLIP_PADDING * 2}
        height={rect.height + CLIP_PADDING * 2}
        rx={borderRadius}
        ry={borderRadius}
        className='cursor-pointer'
      />
    </mask>
  </svg>
);

export { TourGuideClip };

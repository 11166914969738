import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Discount, Swap } from '@swyftx/aviary/icons/outlined';

import { ItemSlider } from '@global-components/ItemSlider/ItemSlider';
import { SliderItem } from '@global-components/ItemSlider/ItemSlider.data';
import { OverviewDataID } from '@global-components/charts/PieChart/PieChart.hooks';

import { assetService } from '@shared/services';

import { useOwnedAssets } from '@hooks/Assets/useOwnedAssets';
import { STAKING_COLOR, TRADING_COLOR } from '@utils/color';

import { getBalanceKeyFromWalletType } from '@Wallet/components/WalletPortfolioAllocation/WalletPortfolioAllocation.utils';
import {
  WalletPortfolioAllocationAssetDetails,
  WalletPortfolioAllocationOverviewDetails,
  WalletPortfolioAllocationPieChart,
} from '@Wallet/components/WalletPortfolioAllocation/components';
import { WalletPortfolioAllocationOption } from '@Wallet/components/WalletPortfolioAllocation/types';
import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { SizeMe } from 'react-sizeme';

type Props = {
  walletType: WalletType;
};

const WalletPortfolioAllocation: React.FC<Props> = observer(({ walletType }) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'allocation' });
  const [selectedPortfolioItem, setSelectedPortfolioItem] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<WalletPortfolioAllocationOption>(
    WalletPortfolioAllocationOption.IndividualAssets,
  );

  const ownedAssetOptions = useMemo(
    () => ({
      sortByValue: true,
      balanceKey: getBalanceKeyFromWalletType(walletType),
      getAllAssets: true,
    }),
    [walletType],
  );
  const ownedAssets = useOwnedAssets(ownedAssetOptions);
  const selectedAsset = selectedPortfolioItem !== null ? assetService.getAsset(selectedPortfolioItem) : null;
  const isPlaceholder = Boolean(!ownedAssets.length);

  // Reset option asset on wallet type change
  useEffect(() => {
    if (walletType !== WalletType.ALL_WALLETS && selectedOption !== WalletPortfolioAllocationOption.IndividualAssets) {
      setSelectedOption(WalletPortfolioAllocationOption.IndividualAssets);
    }
    setSelectedPortfolioItem(null);
  }, [walletType, selectedOption]);

  // Reset selected asset on option change
  useEffect(() => {
    setSelectedPortfolioItem(null);
  }, [selectedOption]);

  // Show overview details if we are displaying wallet types or we are displaying all asset information on mobile view
  const showOverviewDetails =
    selectedOption === WalletPortfolioAllocationOption.WalletTypes ||
    (selectedOption === WalletPortfolioAllocationOption.IndividualAssets && selectedPortfolioItem === null);

  const sliderItems = useMemo<Array<SliderItem>>(
    () =>
      selectedOption === WalletPortfolioAllocationOption.WalletTypes
        ? [
            {
              id: OverviewDataID.Trading,
              icon: <Swap />,
              color: TRADING_COLOR,
            },
            {
              id: OverviewDataID.Staking,
              icon: <Discount />,
              color: STAKING_COLOR,
            },
          ]
        : ownedAssets,
    [selectedOption, ownedAssets],
  );

  const title = useMemo(() => {
    switch (walletType) {
      case WalletType.TRADING_WALLETS:
        return t('tradingTitle');
      case WalletType.EARNING_WALLETS:
        return t('earnTitle');
      default:
        return t('title');
    }
  }, [walletType, t]);

  return (
    <FlexLayout direction='column' className='md:min-h-auto relative h-full min-h-[35rem] w-full'>
      <FlexLayout justifyContent='space-between' className='flex-col p-16 @sm:flex-row' alignItems='start'>
        <Body weight='bold' className='py-8'>
          {title}
        </Body>
      </FlexLayout>

      <Separator className='w-full' />

      <div className='h-full w-full'>
        <FlexLayout direction='column' className='relative h-full w-full'>
          <WalletPortfolioAllocationPieChart
            setSelectedPortfolioItem={setSelectedPortfolioItem}
            selectedPortfolioItem={selectedPortfolioItem}
            selectedOption={selectedOption}
            isPlaceholder={isPlaceholder}
            walletType={walletType}
          />

          {selectedAsset ? (
            <WalletPortfolioAllocationAssetDetails selectedAsset={selectedAsset} walletType={walletType} />
          ) : null}
          {showOverviewDetails && !isPlaceholder && (
            <WalletPortfolioAllocationOverviewDetails
              selectedPortfolioItem={selectedPortfolioItem}
              walletType={walletType}
            />
          )}

          {!isPlaceholder ? (
            <SizeMe monitorWidth>
              {({ size: { width } }) => (
                <ItemSlider
                  selectedItemOverride={selectedPortfolioItem}
                  onItemSelect={setSelectedPortfolioItem}
                  parentWidth={width}
                  items={sliderItems}
                  middle
                />
              )}
            </SizeMe>
          ) : null}
        </FlexLayout>
      </div>
    </FlexLayout>
  );
});

WalletPortfolioAllocation.displayName = 'WalletPortfolioAllocation';

export { WalletPortfolioAllocation };

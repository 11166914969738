import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import {
  Cross,
  Pause,
  Resume,
  Rewards,
  Settings,
  Star,
  TrendLineDown,
  TrendLineUp,
  Wallet,
  WhatsNew,
} from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { MarketTickerSortingOptions } from '../consts';

interface Props {
  setSort: (newSort: MarketTickerSortingOptions) => void;
  sort: MarketTickerSortingOptions;
  isStatic: boolean;
  toggleMode: () => void;
  onClose: () => void;
}

export const MarketTickerSettings: React.FC<Props> = (props) => {
  const { setSort, sort, onClose, isStatic, toggleMode } = props;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const scrollingMarketTickerEnabled = isFeatureEnabled(AppFeature.ScrollingMarketTicker);

  const selectedChip = useCallback(
    (row: MarketTickerSortingOptions) => {
      if (sort === row) {
        return (
          <Chip size='sm' variant='subtle'>
            Selected
          </Chip>
        );
      }
      return null;
    },
    [sort],
  );
  return (
    <Menu
      triggerContent={
        <Button
          variant='outlined'
          layout='icon'
          leadingIcon={<Settings />}
          size='sm'
          className='bg-color-background-surface-primary'
          tooltip='Settings'
        />
      }
    >
      <FlexLayout direction='column'>
        <Body weight='emphasis' className='mb-8'>
          Options
        </Body>
        {scrollingMarketTickerEnabled && (
          <MenuItem
            action={toggleMode}
            icon={isStatic ? <Resume /> : <Pause />}
            text={isStatic ? 'Change to scrolling' : 'Change to static'}
          />
        )}
        <MenuItem action={onClose} icon={<Cross />} text='Hide the Market Ticker' />
        <Body weight='emphasis' className='my-8'>
          Sort Assets by:
        </Body>
        <MenuItem
          action={() => setSort(MarketTickerSortingOptions.GAINERS)}
          icon={<TrendLineUp />}
          text='Gainers'
          endAdornment={selectedChip(MarketTickerSortingOptions.GAINERS)}
        />
        <MenuItem
          action={() => setSort(MarketTickerSortingOptions.LOSERS)}
          icon={<TrendLineDown />}
          text='Losers'
          endAdornment={selectedChip(MarketTickerSortingOptions.LOSERS)}
        />
        <MenuItem
          action={() => setSort(MarketTickerSortingOptions.MARKET_CAP)}
          icon={<Rewards />}
          text='Market cap'
          endAdornment={selectedChip(MarketTickerSortingOptions.MARKET_CAP)}
        />
        <MenuItem
          action={() => setSort(MarketTickerSortingOptions.NEW)}
          icon={<WhatsNew />}
          text='New listings'
          endAdornment={selectedChip(MarketTickerSortingOptions.NEW)}
        />
        <MenuItem
          action={() => setSort(MarketTickerSortingOptions.FAVOURITES)}
          icon={<Star />}
          text='Favourites'
          endAdornment={selectedChip(MarketTickerSortingOptions.FAVOURITES)}
        />
        <MenuItem
          action={() => setSort(MarketTickerSortingOptions.OWNED)}
          icon={<Wallet />}
          text='Owned'
          endAdornment={selectedChip(MarketTickerSortingOptions.OWNED)}
        />
      </FlexLayout>
    </Menu>
  );
};

import React, { useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { formatCurrency } from '@swyftx/currency-util';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';

import { observer } from 'mobx-react-lite';

const AppHeaderBaseCurrencySelectorPortfolio: React.FC = observer(() => {
  const { portfolio } = usePortfolio({ balanceKey: 'all' });
  const baseAsset = useBaseAsset();
  const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);

  const getColor = (val: Big) => {
    if (val.gt(0)) return 'success';
    if (val.lt(0)) return 'error';

    return 'secondary';
  };

  const getChipColor = (val: Big) => {
    if (val.gt(0)) return 'success';
    if (val.lt(0)) return 'destructive';

    return 'secondary';
  };

  return (
    <FlexLayout direction='column' className='w-full p-8'>
      <FlexLayout direction='row' className='w-full items-center justify-between p-2'>
        <AssetIcon asset={baseAsset} size={32} />
        <FlexLayout direction='row' spacing={4} className='w-full items-center justify-end'>
          <NumericDataItem
            data={formatCurrency(portfolio.accountValue.toString(), { isFiat: isBaseAssetFiat })}
            color='primary'
          />
          <NumericDataItem data={baseAsset?.code ?? '-'} color='secondary' size='xxlarge' />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='row' className='items-center justify-end' spacing={4}>
        <NumericDataItem
          data={formatCurrency(portfolio.profit.toString())}
          color={getColor(portfolio.profit)}
          size='medium'
        />
        <Chip color={getChipColor(portfolio.percentChange)} variant='subtle'>{`${
          portfolio.percentChange.gt(0) ? '+' : ''
        }${Big(portfolio.percentChange || '0')
          .toNumber()
          .toLocaleString([], { maximumFractionDigits: 2 })}%`}</Chip>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AppHeaderBaseCurrencySelectorPortfolio };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';

import { useGainersAndLosers } from '@hooks/Assets/useGainersAndLosers';

enum BorderRadiusType {
  Losers,
  Gainers,
}

type Props = {
  assets?: Asset[];
};

const CategoriesListGainersLosers: React.FC<Props> = ({ assets }) => {
  const { gainers, gainersPercentage, losers, losersPercentage } = useGainersAndLosers(assets);

  const getBorderRadius = (type: BorderRadiusType) => {
    if (type === BorderRadiusType.Gainers) {
      if (losers.length === 0) return '4px';
      return '4px 0px 0px 4px';
    }

    if (gainers.length === 0) return '4px';
    return '0px 4px 4px 0px';
  };

  return (
    <FlexLayout direction='column' className='w-full' spacing={4}>
      <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
        <FlexLayout direction='row' alignItems='center' spacing={4}>
          <Numeric size='small'>{gainers?.length || 0}</Numeric>
          <Numeric size='xsmall' color='secondary'>
            ({gainersPercentage.toString()})%
          </Numeric>
        </FlexLayout>
        <FlexLayout direction='row' alignItems='center' spacing={4}>
          <Numeric color='secondary' size='xsmall'>
            ({losersPercentage.toString()})%
          </Numeric>
          <Numeric size='small'>{losers?.length || 0}</Numeric>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout spacing={4} className='w-full' direction='row'>
        {gainers.length === 0 && losers.length === 0 && (
          <div className='h-4 w-full rounded-[4px] bg-color-background-neutral' />
        )}
        {gainers.length > 0 && (
          <div
            className='h-4 bg-color-background-success'
            style={{ width: `${gainersPercentage}%`, borderRadius: getBorderRadius(BorderRadiusType.Gainers) }}
          />
        )}
        {losers.length > 0 && (
          <div
            className='h-4 bg-color-background-error'
            style={{ width: `${losersPercentage}%`, borderRadius: getBorderRadius(BorderRadiusType.Losers) }}
          />
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export { CategoriesListGainersLosers };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TakeProfitAmount: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.27934 2.875C4.55547 2.875 4.77934 3.09886 4.77934 3.375V4.43099H6.27823C6.55437 4.43099 6.77823 4.65485 6.77823 4.93099C6.77823 5.20714 6.55437 5.43099 6.27823 5.43099H4.77934V7.54174H5.16703C6.30263 7.54174 7.22237 8.46254 7.22237 9.59767C7.22237 10.733 6.30237 11.653 5.16703 11.653H4.77934V12.7083C4.77934 12.9845 4.55547 13.2083 4.27934 13.2083C4.00314 13.2083 3.77934 12.9845 3.77934 12.7083V11.653H2.27808C2.00193 11.653 1.77808 11.4292 1.77808 11.153C1.77808 10.8769 2.00193 10.653 2.27808 10.653H3.77934V8.54174H3.3893C2.25417 8.54174 1.33337 7.62194 1.33337 6.48636C1.33337 5.35078 2.25417 4.43099 3.3893 4.43099H3.77934V3.375C3.77934 3.09886 4.00314 2.875 4.27934 2.875ZM3.3893 5.43099H3.77934V7.54174H3.3893C2.80597 7.54174 2.33337 7.0692 2.33337 6.48636C2.33337 5.90352 2.80597 5.43099 3.3893 5.43099ZM4.77934 10.653V8.54174H5.16703C5.74983 8.54174 6.22237 9.01434 6.22237 9.59767C6.22237 10.1807 5.7501 10.653 5.16703 10.653H4.77934Z'
      fill='currentColor'
    />
    <path
      d='M12.0166 11.8335C11.7405 11.8335 11.5166 11.6096 11.5166 11.3335L11.5166 6.54389L10.3792 7.68629C10.1843 7.88197 9.86773 7.88265 9.67205 7.68781C9.47637 7.49297 9.47569 7.17639 9.67053 6.9807L11.662 4.98061C11.7559 4.88638 11.8834 4.8334 12.0163 4.8334C12.0296 4.8334 12.0428 4.83392 12.0559 4.83496C12.1834 4.84486 12.2975 4.90259 12.3802 4.99023L14.3625 6.98068C14.5573 7.17634 14.5567 7.49292 14.361 7.68778C14.1653 7.88264 13.8488 7.88198 13.6539 7.68632L12.5166 6.54435V11.3335C12.5166 11.6096 12.2928 11.8335 12.0166 11.8335Z'
      fill='currentColor'
    />
  </svg>
);

export { TakeProfitAmount };

import React, { useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import * as Popover from '@radix-ui/react-popover';

import { AssetAutoCompletePanel } from './AssetAutoCompletePanel';

type Props = {
  value?: string;
  disabled?: boolean;
  excludedAssetCodes: string[];
  onSelect: (selectedAsset: Asset) => void;
};

const AssetAutoComplete: React.FC<Props> = ({ value, disabled, excludedAssetCodes, onSelect }) => {
  const isXs = useTailwindBreakpoint('xs');
  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  const selectedAsset = useMemo(() => (value ? assetService.getAssetByCode(value) : undefined), [value]);

  return (
    <Popover.Root open={panelOpen} onOpenChange={(o) => !disabled && setPanelOpen(o)} modal>
      <Popover.Trigger
        className={cn(
          'h-full w-full rounded-8 bg-color-background-surface-secondary p-12',
          panelOpen ? 'focus-area-info outline outline-color-border-accent' : '',
          disabled ? ' !cursor-not-allowed opacity-50' : 'hover:bg-color-background-neutral-hover',
        )}
      >
        <FlexLayout aria-disabled={disabled} spacing={8} alignItems='center' justifyContent='space-between'>
          <FlexLayout spacing={8} alignItems='center'>
            <AssetIcon asset={value} size={20} />
            <Body weight='emphasis' color={selectedAsset ? 'primary' : 'secondary'} className='hidden sm:block'>
              {selectedAsset ? selectedAsset.name : 'Select'}
            </Body>
            <Body weight='emphasis' color={selectedAsset ? 'primary' : 'secondary'} className='block sm:hidden'>
              {selectedAsset ? selectedAsset.code : 'Select'}
            </Body>
          </FlexLayout>
          {panelOpen ? (
            <ArrowChevronUp className='h-16 w-16 text-color-text-secondary' />
          ) : (
            <ArrowChevronDown className='h-16 w-16 text-color-text-secondary' />
          )}
        </FlexLayout>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align='start'
          side='bottom'
          sideOffset={12}
          alignOffset={0}
          asChild={isXs}
          className='!z-modal-popover w-[calc(var(--radix-popover-trigger-width)+5rem)]'
        >
          <AssetAutoCompletePanel
            value={value}
            open={panelOpen}
            excludedAssetCodes={excludedAssetCodes}
            onSelect={onSelect}
            onClose={() => {
              setPanelOpen(false);
            }}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export { AssetAutoComplete };

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';
import { MarketMoversType } from '@swyftx/aviary/organisms/Assets/MarketMoversCard/MarketMoversCard.types';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { BuySellButtons } from '@global-components/BuySellButtons';

import { Asset, AssetType } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { formatValueToCurrencyShorthand } from '@utils/currency';

import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';

import { AssetsTableData } from './AssetsTable.types';
import { AssetName, AssetBuyPrice, AssetDailyChange, AssetMarketCap } from './components';
import { AssetCategories } from './components/AssetCategories/AssetCategories';

type AssetsHeaderData = { [key in keyof AssetsTableData]: EnhancedTableHeaderData };

type Props = {
  assets?: Asset[];
  numAssets?: number;
  customInitialSort?: EnhancedTableSort<AssetsTableData>;
  widgetId?: DashboardWidgets;
};

const useAssetsTable = ({ assets = [], widgetId, numAssets }: Props) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const [sort, setSort] = useDashboardSyncState<EnhancedTableSort<AssetsTableData>>({
    widgetId,
    stateKey: 'sort',
    defaultValue: { sortKey: 'rank', sortDirection: 'DESC' },
  });

  const setMarketTypeSort = useCallback((newMarketType: MarketMoversType) => {
    switch (newMarketType) {
      case 'top':
      case 'favourites':
        setSort({ sortKey: 'rank', sortDirection: 'DESC' });
        break;
      case 'gainers':
        setSort({ sortKey: 'dailyChange', sortDirection: 'DESC' });
        break;
      case 'losers':
        setSort({ sortKey: 'dailyChange', sortDirection: 'ASC' });
        break;
      case 'new':
        setSort({ sortKey: 'trade', sortDirection: 'DESC' });
        break;
      default:
        setSort({ sortKey: 'rank', sortDirection: 'DESC' });
        break;
    }
  }, []);

  const headers: AssetsHeaderData = useMemo(
    () => ({
      rank: {
        title: t('table.labels.rank'),
        alignment: 'start',
        sortable: true,
        frozen: true,
        showFrozenDivider: false,
        insetLeft: true,
        className: 'hidden @sm:table-cell min-w-[85px] max-w-[85px]',
      },
      asset: {
        title: t('table.labels.asset'),
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
        frozen: true,
        offsetLeft: 85,
        showFrozenDivider: true,
      },
      categories: {
        title: t('table.labels.categories'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      buyPrice: {
        title: t('table.labels.buyPrice'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      dailyChange: {
        title: t('table.labels.dailyChange'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      weeklyChange: {
        title: t('table.labels.weeklyChange'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      monthlyChange: {
        title: t('table.labels.monthlyChange'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      dailyVolume: {
        title: t('table.labels.dailyVolume'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      marketCap: {
        title: t('table.labels.marketCap'),
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      trade: {
        title: t('table.labels.trade'),
        alignment: 'end',
        sortable: false,
        insetRight: true,
        className: 'hidden @md:table-cell',
      },

      // Mobile headers
      assetRanked: {
        title: t('table.labels.assetRanked'),
        alignment: 'start',
        sortable: true,
        insetLeft: true,
        className: 'table-cell @sm:hidden',
      },
      buyPriceChange: {
        title: t('table.labels.buyPrice'),
        alignment: 'end',
        sortable: true,
        insetRight: true,
        className: 'table-cell @sm:hidden',
      },
    }),
    [t],
  );

  const data: EnhancedTableData<AssetsTableData>[] = useMemo(
    () =>
      assets
        .map((asset) => {
          const rate = getRate(asset);

          return {
            rank: {
              value: asset.rank,
              element: (
                <Numeric size='small' className='whitespace-nowrap'>
                  {asset.rank === Infinity ? '-' : `# ${asset.rank}`}
                </Numeric>
              ),
            },
            asset: {
              value: asset.name,
              element: <AssetName asset={asset} />,
            },

            categories: {
              value: asset?.categoryIds?.[0] || 'uncategorised',
              blockClick: true,
              element: <AssetCategories asset={asset} />,
            },
            buyPrice: {
              value: rate.askPrice,
              element: <AssetBuyPrice asset={asset} />,
            },
            dailyChange: {
              value: rate.dailyPriceChange || '0.0',
              element: <AssetDailyChange asset={asset} />,
            },
            weeklyChange: {
              value: asset.priceChange.week,
              element: (
                <NumericDataItem
                  renderIcons={false}
                  data={`${asset.priceChange.week.toFixed(2)}%`}
                  percentage
                  size='small'
                  alignItems='end'
                />
              ),
            },
            monthlyChange: {
              value: asset.priceChange.month,
              element: (
                <NumericDataItem
                  className='truncate'
                  renderIcons={false}
                  data={`${asset.priceChange.month.toFixed(2)}%`}
                  percentage
                  size='small'
                  alignItems='end'
                />
              ),
            },
            dailyVolume: {
              value: asset.volume[baseAsset?.id || FiatIdEnum.AUD],
              element: (
                <NumericDataItem
                  className='truncate'
                  color='primary'
                  data={formatValueToCurrencyShorthand(
                    asset.volume[baseAsset?.id || FiatIdEnum.AUD].day,
                    baseAsset?.assetType,
                    baseAsset?.code,
                  )}
                  size='small'
                  alignItems='end'
                />
              ),
            },
            marketCap: {
              value: Big(rate.midPrice).times(asset.circulatingSupply).toNumber(),
              element:
                asset.assetType === AssetType.Crypto ? (
                  <AssetMarketCap asset={asset} />
                ) : (
                  <FlexLayout justifyContent='end'>
                    <Numeric>-</Numeric>
                  </FlexLayout>
                ),
            },
            trade: {
              value: '',
              element: (
                <FlexLayout justifyContent='end' spacing={4}>
                  <BuySellButtons asset={asset} variant='aviary-subtle' openTradePanel size='sm' />
                </FlexLayout>
              ),
            },

            // Mobile View Data
            assetRanked: {
              value: asset.rank,
              element: (
                <FlexLayout alignItems='center' spacing={8}>
                  <AssetIcon asset={asset} size={20} />
                  <FlexLayout direction='column'>
                    <Body size='small' className='w-[160px]' overflow='truncate' title={asset.name}>
                      {asset.name}
                    </Body>
                    <Body size='small' className='w-[160px]' overflow='truncate' color='secondary' title={asset.code}>
                      {asset.code}
                    </Body>
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            buyPriceChange: {
              value: rate.askPrice,
              element: (
                <FlexLayout direction='column'>
                  <Numeric size='small' className='text-right'>
                    {formatCurrency(rate.askPrice, baseAsset)}
                  </Numeric>
                  <NumericDataItem
                    data={`${rate.dailyPriceChange || '0.0'}%`}
                    percentage
                    size='small'
                    alignItems='end'
                  />
                </FlexLayout>
              ),
            },
          };
        })
        .slice(0, numAssets ? numAssets : undefined),
    [assets, baseAsset, getRate, numAssets],
  );

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<AssetsTableData>) => {
      const tableData: EnhancedTableData<AssetsTableData>[] = Object.assign([], data);

      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => {
        switch (newSort.sortKey) {
          case 'rank':
          case 'assetRanked':
            const aValNum = a[newSort.sortKey].value as number;
            const bValNum = b[newSort.sortKey].value as number;
            if (newSort.sortDirection === 'ASC') return bValNum - aValNum;
            return aValNum - bValNum;
          case 'asset':
          case 'categories':
            const aVal = a[newSort.sortKey].value as string;
            const bVal = b[newSort.sortKey].value as string;
            if (newSort.sortDirection === 'ASC') return bVal.localeCompare(aVal);
            return aVal.localeCompare(bVal);
          case 'buyPrice':
          case 'marketCap':
          case 'dailyVolume':
          case 'monthlyChange':
          case 'weeklyChange':
          case 'dailyChange':
          case 'buyPriceChange':
            const aValBig = Big(a[newSort.sortKey].value as string);
            const bValBig = Big(b[newSort.sortKey].value as string);
            if (newSort.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
            return bValBig.lt(aValBig) ? -1 : 1;
          default:
            return 1;
        }
      });
    },
    [data, setSort],
  );

  return {
    initialSort: sort,
    headers,
    data,
    assets,
    onSort,
    setMarketTypeSort,
  };
};

export { useAssetsTable };

import React from 'react';

import { Danger } from '@swyftx/aviary/icons/outlined';
import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  title: string;
  content?: string | JSX.Element;
  footer?: JSX.Element;
};

export const WarningMessageBox: React.FC<Props> = ({ title, content, footer }) => (
  <Stack spacing={2} direction='row'>
    <Danger className='h-24 w-24 text-color-text-warning' />
    <Stack spacing={1}>
      <Typography fontSize={pxToRem(16)} fontWeight={600}>
        {title}
      </Typography>
      {content ? (
        <>
          {typeof content === 'string' ? (
            <Typography fontSize={pxToRem(14)} color='text.secondary'>
              {content}
            </Typography>
          ) : (
            content
          )}
        </>
      ) : null}
      {footer || null}
    </Stack>
  </Stack>
);

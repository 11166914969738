import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { UserVerificationCard } from '@swyftx/aviary/organisms/Verification';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';

import { verificationSteps } from '../data/Verification.data';
import { VerificationCardProps } from '../types';

const FundAccountWidget: React.FC<VerificationCardProps> = observer(
  ({ completed, variant = 'full', direction = 'row', isCurrentStep = false }) => {
    const { openModal } = useModal();
    const countryAsset = useCountryAsset();

    const openDepositModal = useCallback(
      (selectedAsset?: Asset) => {
        openModal(Modals.DepositReceive, { selectedAsset });
      },
      [openModal],
    );

    return (
      <UserVerificationCard
        imageType='deposit'
        usePalette
        completed={completed}
        variant={variant}
        direction={direction}
        isCurrentStep={isCurrentStep}
        title='Fund your account'
        content='Swyftx offers a number of ways to deposit AUD. Alternatively, you can transfer crypto from an external wallet.'
        actions={
          <FlexLayout spacing={8} alignItems='center'>
            <Button variant='filled' onClick={() => openDepositModal(countryAsset)} disabled={!isCurrentStep}>
              Deposit funds
            </Button>
          </FlexLayout>
        }
        steps={verificationSteps}
        currentStep='deposit'
      />
    );
  },
);

export { FundAccountWidget };

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AssetGroupCard } from '@swyftx/aviary/complex/AssetGroupCard';

import { Asset } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';
import { NavigationURLs } from 'src/lib/navigation/types';

import { MarketActivityType } from '../../types/Markets.types';
import { getEmptyContent, getEmptyIcon } from '../../utils';

type Props = {
  title: string;
  assets: Asset[];
  variant: 'full' | 'compact';
  type: MarketActivityType;
  className?: string;
};

const MarketActivityCard: React.FC<Props> = observer(({ title, variant, assets, type, className }) => {
  const baseAsset = useBaseAsset();
  const { navigate } = useNavigateRoute();
  const { navigateToAsset } = useAssetNavigation();

  const openAssetListSection = useCallback(
    (route: NavigationURLs) => {
      navigate(route);
    },
    [navigate],
  );

  if (!baseAsset) return null;

  return (
    <AssetGroupCard
      key={type}
      title={title}
      variant={variant}
      actions={
        assets.length >= 10 ? (
          <Button
            variant='ghost'
            color='accent'
            size='sm'
            onClick={() => openAssetListSection(NavigationURLs.AssetsGainers)}
          >
            View all {type}
          </Button>
        ) : null
      }
      assets={assets}
      baseAsset={baseAsset}
      className={`@md:flex-4 w-[calc(50%-16px)] flex-[6] @sm:min-w-[calc(33.33%-36px)] ${className}`}
      numAssets={10}
      loaded
      emptyContent={
        <FlexLayout className='w-full' alignItems='center' justifyContent='center' direction='column' spacing={8}>
          {getEmptyIcon(type)}
          <Body className='text-center' color='secondary' size='small' weight='emphasis'>
            {getEmptyContent(type)}
          </Body>
        </FlexLayout>
      }
      onSelectAsset={navigateToAsset}
    />
  );
});

export { MarketActivityCard };

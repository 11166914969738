import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { PresetTaxOption } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

type Props = {
  taxPreset: PresetTaxOption;
  onClick: (taxPreset: PresetTaxOption) => void;
  selected: boolean;
};

export const TaxPresetCard: React.FC<Props> = ({ taxPreset, onClick, selected }) => (
  <Button
    onClick={() => onClick(taxPreset)}
    variant='outlined'
    className={cn(
      'm-8 w-full',
      selected ? 'bg-color-text-primary text-color-text-inverse' : 'hover:bg-color-background-surface-secondary',
    )}
    color='accent'
  >
    <FlexLayout direction='column' spacing={4} className='w-full py-16'>
      <Body weight='emphasis'>{taxPreset.title}</Body>
      <Body size='small'>{taxPreset.subTitle}</Body>
    </FlexLayout>
  </Button>
);

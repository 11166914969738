import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

type Props = {
  onDelete: () => void;
};

export const DeleteAlert: React.FC<Props> = ({ onDelete }) => (
  <FlexLayout direction='row' className='w-full justify-end'>
    <Button variant='outlined' size='sm' onClick={onDelete}>
      Delete
    </Button>
  </FlexLayout>
);

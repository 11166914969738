import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset } from '@shared/api';

import { useDepositDescription } from '@hooks/Deposit/useDepositDescription';

type Props = {
  onClose: () => void;
  asset: Asset;
};

const DepositFundsInfoModal: React.FC<Props> = ({ onClose, asset }) => {
  const { depositFundsInfo } = useDepositDescription(asset.code);
  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      id='deposit-funds-info-modal'
      open
      title='Important information'
      className='sm:min-w-[600px] sm:max-w-[600px]'
      onClose={onClose}
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
        {depositFundsInfo.content.map(({ title, description }) => (
          <>
            <Body color='primary' weight='bold'>
              {title}
            </Body>
            <Body color='primary'>{description}</Body>
          </>
        ))}
      </FlexLayout>
    </Modal>
  );
};

export { DepositFundsInfoModal };

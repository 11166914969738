import React, { useMemo } from 'react';

import { Body } from '@swyftx/aviary/atoms/Typography';
import { formatCurrency } from '@swyftx/currency-util';

import { AssetType, HistoricalOrder } from '@shared/api';
import { assetService } from '@shared/services';

interface Props {
  order: HistoricalOrder;
}

export const OrderPrice: React.FC<Props> = (props) => {
  const { order } = props;
  const primaryAsset = assetService.getAsset(order?.primary_asset);
  const secondaryAsset = assetService.getAsset(order?.secondary_asset);
  const isPrimaryAssetFiat = primaryAsset?.assetType === AssetType.Fiat;
  const suffix = useMemo(() => `${primaryAsset?.code}/${secondaryAsset?.code}`, [primaryAsset, secondaryAsset]);

  if (!order.rate) {
    return <Body color='secondary'>-</Body>;
  }

  return (
    <Body color='secondary'>{`${formatCurrency(order.rate.toString(), {
      isFiat: isPrimaryAssetFiat,
    })} ${suffix}`}</Body>
  );
};

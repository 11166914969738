import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

type Props = {
  secondaryAsset?: Asset;
  amount: Big;
};

const ProfitStopLossAmount: React.FC<Props> = ({ secondaryAsset, amount }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  return (
    <FlexLayout spacing={8} direction='column' className='px-16'>
      <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
        <Body>{t('labels.amountToSell', { code: secondaryAsset?.code })}</Body>
      </FlexLayout>
      <Input
        id='input-profit-amount'
        type='text'
        value={amount.toString()}
        inputMode='decimal'
        disabled
        name='profit-amount'
        placeholder='0.00'
        className='w-full'
        trailing={<Body>{secondaryAsset?.code}</Body>}
      />
    </FlexLayout>
  );
};

export { ProfitStopLossAmount };

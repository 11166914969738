import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import PortfolioAssetTicker, {
  PortfolioTickerDisplay,
} from '@global-components/PortfolioAssetTicker/PortfolioAssetTicker';

import { Asset, AssetType } from '@shared/api';
import { AppStore } from '@shared/store';

import { AssetBalanceData } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';
import { BalanceKey } from '@utils/balance';

import { WalletListPieChart } from '@Wallet/components/WalletList/components/WalletListPieChart';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  assetBalanceData: AssetBalanceData;
  isFiat?: boolean;
  balanceKey: BalanceKey;
  assetBalanceKey: 'trading' | 'staking' | 'all';
};

const WalletListTileAssetDetails: React.FC<Props> = observer(
  ({ asset, assetBalanceData, balanceKey, assetBalanceKey, isFiat = false }) => {
    const { isDemo } = AppStore.useAppStore;
    const { portfolio } = usePortfolio({ balanceKey });
    const { accountValue } = portfolio;
    const baseAsset = useBaseAsset();
    const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);

    return (
      <FlexLayout direction='row' justifyContent='space-between' alignItems='center' className='w-full'>
        <FlexLayout direction='column' spacing={8}>
          <FlexLayout direction='row' spacing={8} alignItems='center'>
            <AssetIcon asset={asset} size={24} wallet />
            <Body weight='emphasis' className='max-w-[150px] truncate'>
              {asset?.name}
            </Body>
            <Body className='whitespace-nowrap'>{asset?.code}</Body>
          </FlexLayout>

          {!isDemo && (
            <FlexLayout direction='row' spacing={8} alignItems='center' className={isFiat ? 'invisible' : 'visible'}>
              <PortfolioAssetTicker
                assetId={asset.id}
                chipProps={{ size: 'sm' }}
                display={PortfolioTickerDisplay.Percentage}
                isChip
                typographyProps={{ color: 'white' }}
              />
              <PortfolioAssetTicker
                assetId={asset.id}
                display={PortfolioTickerDisplay.Amount}
                displayProps={{ priceScale: isBaseAssetFiat ? 2 : baseAsset?.price_scale }}
              />
            </FlexLayout>
          )}
        </FlexLayout>

        <WalletListPieChart
          asset={asset}
          walletValue={accountValue.toString()}
          assetValue={assetBalanceData[assetBalanceKey].value}
        />
      </FlexLayout>
    );
  },
);

export { WalletListTileAssetDetails };

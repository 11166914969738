import React from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  title: string;
  subTitle: string;
  showImage?: boolean;
};

const NoResults: React.FC<Props> = ({ title, subTitle, showImage }) => (
  <FlexLayout direction='column' className='h-full items-center '>
    {showImage && (
      <Image image='empty_search_results' usePalette className='h-[120px] w-[120px]' alt='no assets found image' />
    )}
    <Body weight='emphasis' size='large' color='primary'>
      {title}
    </Body>
    <Body color='secondary'>{subTitle}</Body>
  </FlexLayout>
);

export { NoResults };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TrackPortfolio: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M17.6094 14.0083L19.6606 13.418L20.2509 15.4691'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0625 20.2198L12.2258 16.7302L16.9587 18.1471L19.661 13.418'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8591 7.95312H10.6659C10.0131 7.95312 9.48438 8.48181 9.48438 9.13379C9.48438 9.78672 10.0131 10.3154 10.6659 10.3154H12.0157C12.6676 10.3154 13.1963 10.8441 13.1963 11.496C13.1963 12.149 12.6676 12.6777 12.0157 12.6777H9.82251'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.3438 12.6743V13.6663M11.3438 6.95312V7.95351'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.9248 10.5289C18.9248 6.33861 15.5277 2.94141 11.3375 2.94141C7.14627 2.94141 3.75 6.33861 3.75 10.5289C3.75 13.1479 5.07637 15.4569 7.09489 16.8207'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { TrackPortfolio };

import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const OTCTrades: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;
  const isTradeOnBehalfEnabled = userProfile?.accountStatus?.tradeOnBehalf;
  const isDisabled = isTradeOnBehalfEnabled == null;
  const isChecked = Boolean(isTradeOnBehalfEnabled);

  const { openModal } = useModal();

  const handleSwitch = (newChecked: boolean) => {
    openModal(Modals.OTCTradesPermissionModal, { toEnable: newChecked });
  };

  return (
    <Switch
      id='profile-security-otc-enabled-switch'
      disabled={isDisabled}
      checked={isChecked}
      size='md'
      onCheckedChange={(checked: boolean) => handleSwitch(checked)}
    />
  );
});

OTCTrades.displayName = 'OTCTrades';

export { OTCTrades };

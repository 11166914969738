import React from 'react';

import { Alert, AlertProps, AlertTitle, AlertTitleProps } from '@mui/material';

import { getPIIClassName } from '../global-utils';
import { PIITypes } from '../types/PII.types';

type Props = {
  alertTitleProps?: AlertTitleProps;
} & AlertProps &
  PIITypes;

const Notification: React.FC<Props> = ({ id, children, title, alertTitleProps, PII, ...props }) => (
  <Alert className={getPIIClassName(PII)} id={id} {...props}>
    <AlertTitle
      id={`${id}-title`}
      sx={{ fontWeight: 500, fontSize: '14px', color: `${props.severity}.dark` }}
      {...alertTitleProps}
    >
      {title}
    </AlertTitle>
    {children}
  </Alert>
);

export default Notification;

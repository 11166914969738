import React, { useState } from 'react';

import { api } from '@shared/api';
import { PriceAlert } from '@shared/api/@types/alerts';
import { Asset } from '@shared/api/@types/markets';
import { FiatIdEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useAsync } from 'react-use';

import { CreatePriceAlert } from './CreatePriceAlert/CreatePriceAlert';
import { CurrentPriceAlerts } from './CurrentPriceAlerts/CurrentPriceAlerts';
import { PriceAlertStep } from './PriceAlerts.types';

type Props = {
  asset: Asset;
  onClose: () => void;
  open: boolean;
};

const STEPS = [PriceAlertStep.CURRENT_ALERTS, PriceAlertStep.CREATE_ALERT];

const PriceAlerts: React.FC<Props> = observer(({ asset, open, onClose }) => {
  const { userProfile } = UserStore.useUserStore;

  const [step, setStep] = useState<PriceAlertStep>(PriceAlertStep.CURRENT_ALERTS);
  const [alerts, setAerts] = useState<PriceAlert[]>([]);

  const getAlerts = async () => {
    const query = {
      primary: userProfile?.currency.id || FiatIdEnum.USD,
      secondary: asset?.id,
    };
    const alertsResponse = await api.endpoints.getAlerts({ query });
    setAerts(alertsResponse.data);
  };

  const { loading } = useAsync(getAlerts);

  const handleOnClose = () => {
    setStep(PriceAlertStep.CURRENT_ALERTS);
    onClose();
  };

  const nextStep = () => {
    const nextStepIdx = STEPS.indexOf(step) + 1;
    setStep(nextStepIdx > STEPS.length ? PriceAlertStep.CURRENT_ALERTS : STEPS[nextStepIdx]);
  };

  const prevStep = () => {
    const prevStepIdx = STEPS.indexOf(step) - 1;
    setStep(prevStepIdx < 0 ? PriceAlertStep.CURRENT_ALERTS : STEPS[prevStepIdx]);
  };

  return (
    <>
      {step === PriceAlertStep.CURRENT_ALERTS && (
        <CurrentPriceAlerts
          id='current-price-alert-modal'
          alerts={alerts}
          asset={asset}
          nextStep={nextStep}
          loading={loading}
          onClose={handleOnClose}
          hydrate={getAlerts}
          open={open}
        />
      )}
      {step === PriceAlertStep.CREATE_ALERT && (
        <CreatePriceAlert
          id='create-price-alert-modal'
          asset={asset}
          alerts={alerts}
          onClose={handleOnClose}
          open={open}
          prevStep={prevStep}
          refetchAlerts={getAlerts}
        />
      )}
    </>
  );
});

PriceAlerts.displayName = 'PriceAlerts';

export { PriceAlerts };

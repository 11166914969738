import { DashboardTile, Profile } from '@shared/api';

type DashboardStateType = {
  editMode: boolean;
  tiles: DashboardTile[];
  editTiles: DashboardTile[];
};

type DashboardActionType = {
  setEditMode: (editMode: boolean) => void;
  initTiles: (userTiles?: DashboardTile[]) => void;
  setEditTiles: (tiles: DashboardTile[]) => void;
  updateEditTile: (name: string, data: Partial<DashboardTile>) => void;
  cancelEditMode: () => void;
  saveTiles: (userProfile?: Profile) => void;
  cleanup: () => void;
};

interface DashboardStoreSchema extends DashboardStateType, DashboardActionType {}

const initialValues: DashboardStoreSchema = {
  editMode: false,
  tiles: [],
  editTiles: [],
  initTiles: () => {},
  setEditMode: () => {},
  setEditTiles: () => {},
  updateEditTile: () => {},
  cancelEditMode: () => {},
  saveTiles: () => {},
  cleanup: () => {},
};

export { initialValues };
export type { DashboardStoreSchema };

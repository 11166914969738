import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { FiatDepositDetailsCardItem } from './FiatDepositDetailsCardItem';

type Props = {
  accountName?: string;
  accountNumber?: string;
  bsb?: string;
  referenceDescription?: string;
  minimumDepositAmount?: string;
  loading?: boolean;
};

const FiatDepositDetailsCard: React.FC<Props> = ({
  accountName,
  accountNumber,
  bsb,
  referenceDescription,
  minimumDepositAmount,
  loading,
}) => (
  <FlexLayout direction='column' className='w-full' spacing={16}>
    <FiatDepositDetailsCardItem title='Account name' value={accountName} copyable loading={loading} />
    <FiatDepositDetailsCardItem title='Account number' value={accountNumber} copyable loading={loading} />
    <FiatDepositDetailsCardItem title='BSB' value={bsb} copyable loading={loading} />{' '}
    <FiatDepositDetailsCardItem
      title='Reference / Description'
      value={referenceDescription}
      copyable
      loading={loading}
      infoMessage='This is used to identify your deposit. If your bank does not include a reference field, please add this to the description.'
    />
    {(minimumDepositAmount || loading) && (
      <FiatDepositDetailsCardItem
        title='Minimum deposit amount'
        value={minimumDepositAmount}
        copyable
        loading={loading}
        infoMessage='Orders less than this will fail to execute.'
      />
    )}
  </FlexLayout>
);

export { FiatDepositDetailsCard };

import React, { useCallback, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Search } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { SkeletonRenderer } from '@swyftx/aviary/molecules/SkeletonRenderer';

import { Asset } from '@shared/api';

import { AssetAutoCompleteItem } from './AssetAutoCompleteItem';
import { AssetAutoCompleteItemSkeleton } from './AssetAutoCompleteItemSkeleton';
import { useAssetAutoCompletePanel } from './useAssetAutoCompletePanel';
import { useMarketAnalytics } from '../../hooks/useMarketsAnalytics';
import { MarketAssetFilterType } from '../../types/Markets.types';

type Props = {
  open: boolean;
  value?: string;
  excludedAssetCodes: string[];
  onSelect: (asset: Asset) => void;
  onClose: () => void;
};

const AssetAutoCompletePanel: React.FC<Props> = ({ value = '', excludedAssetCodes, open, onSelect, onClose }) => {
  const [search, setSearch] = useState<string>('');
  const { assetSelected } = useMarketAnalytics();

  const {
    emptyContent,
    emptyContentImage,
    tabs,
    selectedFilterType,
    setSelectedFilterType,
    loading,
    filteredAndSortedAssets,
  } = useAssetAutoCompletePanel({ excludedAssetCodes, search });
  const isXs = useTailwindBreakpoint('xs');

  const renderAutoCompleteContainer = useCallback(
    (children: React.ReactNode) => {
      if (!isXs) {
        return <Card className='h-[25rem] w-full !rounded-12'>{children}</Card>;
      } else {
        return (
          <Modal
            position='bottom'
            title='Select asset'
            open
            onClose={onClose}
            overlayClassName='z-modal-popover-backdrop'
            className='h-[94vh] sm:h-auto'
          >
            {children}
          </Modal>
        );
      }
    },
    [isXs, onClose],
  );

  const onSelectAsset = useCallback(
    (asset: Asset) => {
      onSelect(asset);
      assetSelected(asset, search, selectedFilterType);
    },
    [assetSelected, onSelect, search, selectedFilterType],
  );

  if (!open) return null;

  return renderAutoCompleteContainer(
    <FlexLayout
      direction='column'
      spacing={0}
      alignItems='center'
      justifyContent='start'
      className='h-full w-full overflow-auto'
    >
      <div className='block w-full p-16'>
        <Input
          leading={<Search />}
          placeholder='Search for an asset'
          value={search}
          autoFocus={false}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className='sticky top-0 w-full px-8 pb-16 pt-4 sm:px-16'>
        <EnhancedTabs<MarketAssetFilterType>
          tabs={tabs}
          value={selectedFilterType}
          variant='child'
          onChange={setSelectedFilterType}
        />
      </div>
      <List className='h-[75vh] w-full overflow-auto px-8 pt-4 sm:h-[21rem] sm:px-4 sm:pb-8' spacing={2}>
        {loading && <SkeletonRenderer num={16} skeletonElement={<AssetAutoCompleteItemSkeleton />} />}
        {!loading &&
          filteredAndSortedAssets?.map((asset: Asset) => (
            <AssetAutoCompleteItem
              key={asset.code}
              asset={asset}
              onClick={() => onSelectAsset(asset)}
              selected={value === asset.code}
            />
          ))}
        {!loading && filteredAndSortedAssets?.length === 0 && (
          <FlexLayout
            direction='column'
            className='h-full w-full'
            alignItems='center'
            justifyContent='center'
            spacing={8}
          >
            {emptyContentImage}
            {emptyContent && <Body>{emptyContent}</Body>}
          </FlexLayout>
        )}
      </List>
    </FlexLayout>,
  );
};

export { AssetAutoCompletePanel };

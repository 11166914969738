import { useCallback, useContext, useMemo } from 'react';

import { Image } from '@swyftx/aviary/atoms/Image/Image';
import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';

import { Links } from '@shared/constants';
import { UserStore } from '@shared/store';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useAutoInvestAnalytics } from 'src/lib/auto-invest/hooks';
import { useSetTwoFactorAuthenticationTour } from 'src/lib/feature-discovery/tours/profile/security/setTwoFactorAuthentication.tour';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { GettingStartedCardType } from '../types';

const useBuildGettingStartedCards = () => {
  const { navigate } = useNavigateRoute();
  const { startTour } = useContext(TourGuideContext);
  const twoFactorTour = useSetTwoFactorAuthenticationTour();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { gettingStartedTileOpened } = useAutoInvestAnalytics();
  const { startedApplication, gettingStartedTileOpened: entityTileSelected } = useEntityOnboardingAnalytics();
  const { entityAccounts, isKyc1Complete, userProfile, isUserVerified } = UserStore.useUserStore;

  const { inProgressSubmissions, startNewEntityApplication, goToExistingApplication } =
    useEntityOnboardingApplication();

  const openLink = (url: string) => {
    if (!url) return;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openAutoInvestTile = useCallback(() => {
    gettingStartedTileOpened();
    navigate(NavigationURLs.AutoInvest);
  }, [gettingStartedTileOpened, navigate]);

  const onNavigateToEntityOnboarding = useCallback(() => {
    startedApplication();
    entityTileSelected();
    if (!inProgressSubmissions.length) {
      startNewEntityApplication();
    } else {
      goToExistingApplication();
    }
  }, [
    entityTileSelected,
    goToExistingApplication,
    inProgressSubmissions.length,
    startNewEntityApplication,
    startedApplication,
  ]);

  const applyForEntityHidden = useMemo(() => {
    const hasEntityAccount = entityAccounts.some((account) => account.isEntity);
    const applyForEntityAccount = userProfile?.userSettings.applyForEntityAccount;

    return (
      !isFeatureEnabled(AppFeature.EntityOnboarding) ||
      hasEntityAccount ||
      !isKyc1Complete() ||
      !applyForEntityAccount ||
      !isUserVerified()
    );
  }, [
    entityAccounts,
    isFeatureEnabled,
    isKyc1Complete,
    isUserVerified,
    userProfile?.userSettings.applyForEntityAccount,
  ]);

  const items = useMemo(
    (): GettingStartedCardType[] => [
      {
        icon: <Image image='secure_account' usePalette className='h-64 w-64' />,
        title: 'Secure your account',
        content: 'Add another layer of security to your account by enabling 2-factor authentication.',
        onClick: () => startTour(twoFactorTour),
      },
      {
        icon: <Image image='auto_invest' className='h-64 w-64' />,
        title: 'Start your auto investing journey',
        hidden: !isFeatureEnabled(AppFeature.AutoInvest),
        content:
          'Create orders that execute on a routine schedule and automate the purchase process for multiple assets.',
        onClick: openAutoInvestTile,
      },
      {
        icon: <Image image='learn' usePalette className='h-64 w-64' />,
        title: 'Swyftx Learn',
        content:
          'Access quality educational resources and expert market analysis to sharpen your crypto and blockchain knowledge',
        onClick: () => openLink(Links.learn.root),
      },
      {
        icon: <Image image='entity_accounts' className='h-64 w-64' />,
        title: 'Create an entity account',
        content: 'Create a sub-account to trade on behalf of an entity (Company, SMSF or Trust).',
        onClick: onNavigateToEntityOnboarding,
        hidden: applyForEntityHidden,
      },
      {
        icon: <Image image='affiliates' usePalette className='h-64 w-64' />,
        title: 'Affiliate program',
        content:
          'Join the program and get 30% of trade fees for every new customer you refer, for the lifetime of their account!',
        onClick: () => navigate(NavigationURLs.ProfileAffiliateProgram),
      },
      {
        icon: <Image image='refer_a_friend' usePalette className='h-64 w-64' />,
        title: 'Refer a friend',
        hidden: isFeatureEnabled(AppFeature.AutoInvest),
        content:
          "Invite friends & family to trade with Swyftx within 30 days of signup and you'll both score $10 of Bitcoin",
        onClick: () => navigate(NavigationURLs.ProfileReferAFriend),
      },
    ],
    [
      applyForEntityHidden,
      isFeatureEnabled,
      navigate,
      onNavigateToEntityOnboarding,
      openAutoInvestTile,
      startTour,
      twoFactorTour,
    ],
  );

  return {
    items: items.filter((i) => !i.hidden),
  };
};

export { useBuildGettingStartedCards };

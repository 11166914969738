import { CompanyForm, CorporateTrustForm, IndividualTrustForm, SmsfForm, TrustForm } from '@shared/api';

import { EntityOnboardingData } from '../types/EntityOnboarding.types';

const getCompanyCompletedSteps = (data: Partial<CompanyForm>) => ({
  companyInformation: Boolean(data.name),
  'companyAddMembers.addDirectors': Boolean(data.directors?.length),
  'companyAddMembers.addShareholders': Boolean(data.shareholders?.length),
});

const getSmsfCompletedSteps = (data: Partial<SmsfForm>) => ({
  companyInformation: Boolean(data.trustees?.length),
  'smsfAddMembers.addInvidiuals': Boolean(data.trustees?.some((trustee) => trustee.type === 'INDIVIDUAL')),
  'smsfAddMembers.addCorporateMembers': Boolean(data.trustees?.some((trustee) => trustee.type === 'CORPORATE')),
});

const getCorporateTrustCompletedSteps = (data: Partial<CorporateTrustForm>) => ({
  trustInformation: data.sourceOfWealth !== undefined,
  'corporateTrustAddMembers.addDirectors': Boolean(data.directors?.length),
  'corporateTrustAddMembers.addShareholders': Boolean(data.shareholders?.length),
  'corporateTrustAddMembers.addBeneficiaries': Boolean(data.beneficiaries?.length),
});

const getIndividualTrustCompletedSteps = (data: Partial<IndividualTrustForm>) => ({
  trustInformation: Boolean(data.trustees?.length),
  'individualTrustAddMembers.addTrustees': Boolean(data.trustees?.length),
  'individualTrustAddMembers.addBeneficiaries': Boolean(data.beneficiaries?.length),
});

const getTrustCompletedSteps = (data: Partial<TrustForm>) => {
  if (data.type === 'TRUST_CORPORATE') {
    return getCorporateTrustCompletedSteps(data as CorporateTrustForm);
  }
  if (data.type === 'TRUST_INDIVIDUAL') {
    return getIndividualTrustCompletedSteps(data as IndividualTrustForm);
  }
};

const getOtherSteps = (data: Partial<EntityOnboardingData>) => {
  switch (data.type) {
    case 'COMPANY':
      return getCompanyCompletedSteps(data as CompanyForm);
    case 'SMSF_INDIVIDUAL':
    case 'SMSF_CORPORATE':
      return getSmsfCompletedSteps(data as SmsfForm);
    default:
      return getTrustCompletedSteps(data as TrustForm);
  }
};

export const getFinishedSteps = (data: Partial<EntityOnboardingData>) => {
  const otherSteps = data.type ? getOtherSteps(data) : undefined;
  return {
    introduction: Boolean(data.type),
    entityType: Boolean(data.type),
    ...otherSteps,
  };
};

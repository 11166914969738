import {
  releaseFive,
  releaseFour,
  releaseOne,
  releaseSix,
  releaseThree,
  releaseTwo,
  swyftxRewardsRelease,
} from './releases';
import { Release, ReleaseVersion } from './whats-new.type';

const currentVersion = ReleaseVersion.SwyftxRewards;
const VIEWED_WHATS_NEW_VERSION = 'whats_new_version_viewed_v1';

const whatsNewChangeLog: Release[] = [
  releaseOne,
  releaseTwo,
  releaseThree,
  releaseFour,
  releaseFive,
  releaseSix,
  swyftxRewardsRelease,
];

export { whatsNewChangeLog, currentVersion, VIEWED_WHATS_NEW_VERSION };

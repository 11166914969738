import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { HistoricalOrder } from '@shared/api';

import { observer } from 'mobx-react-lite';

import { useOrderInfo } from '../hooks/useOrderInfo';

interface Props {
  order: HistoricalOrder;
}

export const OrderDetailsContent: React.FC<Props> = observer((props) => {
  const { order } = props;
  const { items } = useOrderInfo(order);

  if (!order) return null;

  return (
    <FlexLayout direction='column' className='px-16' spacing={16}>
      {items.map((item, index) => (
        <>
          <FlexLayout direction='row' className='items-center justify-between'>
            <Body weight='emphasis'>{item.title}</Body>
            {typeof item.data === 'string' ? <Body color='secondary'>{item.data}</Body> : item.data}
          </FlexLayout>
          {index !== items.length - 1 && <Separator />}
        </>
      ))}
    </FlexLayout>
  );
});

import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { MobileInput } from '@swyftx/aviary/complex/MobileInput/MobileInput';

import { Individual } from '@shared/api';
import { CountriesEnum } from '@shared/enums';

import { useEntityOnboardingSelector } from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';
import { useStepId } from '@routes/EntityOnboarding/hooks/useStepId';
import { MemberType } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';
import { Countries } from '@utils/countries';

import { zodResolver } from '@hookform/resolvers/zod';
import getCountry from 'libphonenumber-js/max';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { individualSchema } from '../schemas/AddIndividualSchema';

interface Props {
  onCompanySubmit?: (data: Individual, isShareholder: boolean) => void;
  onCorporateTrustSubmit?: (data: Individual, isShareholder: boolean, isBeneficiary: boolean) => void;
  onSmsfSubmit?: (data: Individual) => void;
  onCancel?: () => void;
  member?: Individual;
  memberType: MemberType;
  isAlsoShareholder?: boolean;
  isAlsoBeneficiary?: boolean;
}

type FormInputs = Individual;

export const AddIndividual: React.FC<Props> = observer((props) => {
  const {
    onCompanySubmit,
    onSmsfSubmit,
    onCancel,
    memberType,
    onCorporateTrustSubmit,
    member,
    isAlsoBeneficiary,
    isAlsoShareholder,
  } = props;

  const { entityMemberAdded, entityMemberEdited } = useEntityOnboardingAnalytics();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);
  const { type } = applicationData;
  const { stepId } = useStepId();

  const [isShareholder, setIsShareholder] = useState(isAlsoShareholder ?? false);
  const [isBeneficiary, setIsBeneficiary] = useState(isAlsoBeneficiary ?? false);
  const prevCountryCode = member?.phone
    ? CountriesEnum[getCountry(member?.phone)?.country as keyof typeof CountriesEnum]
    : undefined;

  const prevNumber = useMemo(() => {
    if (member?.phone && prevCountryCode) {
      const prevDialCode = Countries[prevCountryCode].dialCode;
      return member?.phone?.replace(prevDialCode, '0');
    }
  }, [member?.phone, prevCountryCode]);

  const [mobileCountry, setMobileCountry] = useState<CountriesEnum>(prevCountryCode ?? CountriesEnum.AU);

  const isCorporateDirector = memberType === 'director' && onCorporateTrustSubmit;

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      type: 'INDIVIDUAL',
      firstName: member?.firstName,
      lastName: member?.lastName,
      email: member?.email,
      phone: prevNumber,
    },
    resolver: zodResolver(individualSchema(mobileCountry), undefined, { rawValues: true }),
  });

  const [firstName, lastName, email, phone] = watch(['firstName', 'lastName', 'email', 'phone']);

  const formatMobile = useCallback(
    (mobile: string) => {
      const dialCodeIndex = mobile.indexOf(Countries[mobileCountry].dialCode);
      return `${Countries[mobileCountry].dialCode}${mobile
        .slice(dialCodeIndex + 1)
        .replace(/^0/, '')
        .replace(/[^0-9]/g, '')}`;
    },
    [mobileCountry],
  );

  const handleAddIndividualSubmit = useCallback(() => {
    const individual: FormInputs = {
      type: 'INDIVIDUAL',
      firstName: watch('firstName'),
      lastName: watch('lastName'),
      phone: formatMobile(watch('phone')),
      email: watch('email'),
    };

    if (type) {
      if (member) {
        entityMemberEdited({ stepId, memberType, entityType: type });
      } else {
        entityMemberAdded({ stepId, memberType, entityType: type });
      }
    }

    if (isCorporateDirector) {
      return onCorporateTrustSubmit(individual, isShareholder, isBeneficiary);
    }
    if (['director', 'shareholder'].includes(memberType) && onCompanySubmit) {
      return onCompanySubmit(individual, isShareholder);
    }
    if (['member', 'trustee', 'beneficiary', 'shareholder'].includes(memberType) && onSmsfSubmit) {
      return onSmsfSubmit(individual);
    }
  }, [
    entityMemberAdded,
    entityMemberEdited,
    formatMobile,
    isBeneficiary,
    isCorporateDirector,
    isShareholder,
    member,
    memberType,
    onCompanySubmit,
    onCorporateTrustSubmit,
    onSmsfSubmit,
    stepId,
    type,
    watch,
  ]);

  const submitDisabled = !!Object.keys(errors).length || !firstName || !lastName || !email || !phone;

  return (
    <>
      <FlexLayout direction='column' spacing={24}>
        {!member && (
          <FlexLayout direction='row' className='w-full items-center '>
            <Heading size='h6'>New {memberType}</Heading>
          </FlexLayout>
        )}

        <FlexLayout direction='row' className='w-full justify-between' spacing={12}>
          <FlexLayout direction='column' spacing={4} className='w-full'>
            <Body size='small' weight='emphasis'>
              First name:
            </Body>
            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  placeholder='John'
                  onChange={(e) => field.onChange(e.target.value)}
                  error={!!errors.firstName}
                />
              )}
            />
            {errors.firstName && (
              <Body size='small' color='error'>
                {errors.firstName.message}
              </Body>
            )}
          </FlexLayout>
          <FlexLayout direction='column' spacing={4} className='w-full'>
            <Body size='small' weight='emphasis'>
              Last name:
            </Body>
            <Controller
              name='lastName'
              control={control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={!!errors.lastName}
                  placeholder='Doe'
                />
              )}
            />
            {errors.lastName && (
              <Body size='small' color='error'>
                {errors.lastName.message}
              </Body>
            )}
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='column' spacing={4} className='w-full'>
          <Body size='small' weight='emphasis'>
            Email address:
          </Body>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.email}
                placeholder='john.doe@email.com'
              />
            )}
          />
          {errors.email && (
            <Body size='small' color='error'>
              {errors.email.message}
            </Body>
          )}
        </FlexLayout>
        <FlexLayout direction='column' spacing={4} className='w-full'>
          <Body size='small' weight='emphasis'>
            Phone number:
          </Body>
          <Controller
            name='phone'
            control={control}
            render={({ field }) => (
              <MobileInput
                defaultCountry={mobileCountry}
                onChangeCountry={setMobileCountry}
                onChange={field.onChange}
                value={field.value}
                error={!!errors.phone}
              />
            )}
          />
          {errors.phone && (
            <Body size='small' color='error'>
              {errors.phone.message}
            </Body>
          )}
        </FlexLayout>
        {memberType === 'director' && (
          <FlexLayout direction='column' spacing={12} className='w-full'>
            <Body size='small' weight='emphasis'>
              This individual is also a:
            </Body>
            <FlexLayout
              direction='row'
              className='w-full cursor-pointer items-center'
              spacing={16}
              onMouseDown={() => setIsShareholder((prev) => !prev)}
            >
              <Checkbox className='rounded-[100%]' checked={isShareholder} />
              <Body>Shareholder (if they own 25% or more in shares)</Body>
            </FlexLayout>
            {isCorporateDirector && (
              <FlexLayout
                direction='row'
                className='w-full cursor-pointer items-center'
                spacing={16}
                onMouseDown={() => setIsBeneficiary((prev) => !prev)}
              >
                <Checkbox className='rounded-[100%]' checked={isBeneficiary} />
                <Body>Beneficiary</Body>
              </FlexLayout>
            )}
          </FlexLayout>
        )}
        <FlexLayout direction='row' className='w-full items-center justify-end' spacing={8}>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleAddIndividualSubmit} disabled={submitDisabled}>
            {member ? 'Save' : `Add ${memberType}`}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </>
  );
});

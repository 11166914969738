import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Add } from '@swyftx/aviary/icons/outlined';

import { useSmsfTrusteeStep } from '@routes/EntityOnboarding/hooks/useSmsfTrusteeStep';

import { observer } from 'mobx-react-lite';

import { AddIndividual } from './components/AddIndividual';
import { IndividualCard } from './components/IndividualCard';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const SmsfAddMembersStep: React.FC = observer(() => {
  const {
    addMemberOpen,
    addCorporateTrusteeOpen,
    handleRemove,
    members,
    onCancel,
    onAddMember,
    toggleAddMemberOpen,
    handleBack,
    onEditMember,
    handleSubmit,
  } = useSmsfTrusteeStep();

  const continueDisabled = useMemo(() => !members.length || addMemberOpen, [members.length, addMemberOpen]);

  return (
    <>
      <EntityOnboardingStepContainer
        customTitle='SMSF trustees & members'
        submitDisabled={continueDisabled}
        onSubmit={handleSubmit}
        onBack={handleBack}
      >
        <EntityOnboardingPageLayout
          subtitle='List all individuals who are members of your Self Managed Super Fund (SMSF). We are required to
          collect this for regulatory purposes.'
        >
          <FlexLayout direction='column' spacing={16}>
            <Body weight='emphasis'>Please add all individuals</Body>
            {members.map((shareholder, index) => (
              <IndividualCard
                key={`${shareholder.individual.firstName}-${index}`}
                member={shareholder}
                onRemove={() => handleRemove(shareholder.uuid)}
                memberType='member'
                onEditMember={onEditMember}
              />
            ))}
            {addMemberOpen && (
              <Card className='p-12'>
                <AddIndividual onSmsfSubmit={onAddMember} onCancel={onCancel} memberType='member' />
              </Card>
            )}
            <FlexLayout direction='row' className='w-full flex-wrap' spacing={8}>
              <Button
                leadingIcon={<Add />}
                onClick={() => toggleAddMemberOpen(true)}
                disabled={addMemberOpen || addCorporateTrusteeOpen}
              >
                Add member
              </Button>
            </FlexLayout>
          </FlexLayout>
        </EntityOnboardingPageLayout>
      </EntityOnboardingStepContainer>
    </>
  );
});

import React, { useState } from 'react';

import Wizard from '@global-components/Wizard';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { EntityAddMemberDetailsStep, EntityAddMemberResultStep } from './steps';

type Props = {
  open: boolean;
  onClose: () => void;
};

enum Steps {
  Details = 0,
  Result = 1,
}

export const EntityAddMemberWizard: React.FC<Props> = observer(({ open, onClose }) => {
  const { userProfile } = UserStore.useUserStore;
  const [stepIndex, setStepIndex] = useState(0);

  const [name, setName] = useState<string>();
  const entityName = userProfile?.entityDetails?.entityName;

  const goToResult = () => {
    setStepIndex(Steps.Result);
  };

  const onSuccess = (name: string) => {
    setName(name);
    goToResult();
  };

  return (
    <Wizard.Container open={open} onClose={onClose} stepIndex={stepIndex}>
      <EntityAddMemberDetailsStep onClose={onClose} onSuccess={onSuccess} />
      <EntityAddMemberResultStep onClose={onClose} name={name} accountName={entityName} />
    </Wizard.Container>
  );
});

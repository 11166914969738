import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useInfiniteQuery } from '@tanstack/react-query';

import { PriceAlertTriggerType } from '../../dynamicPriceAlerts.types';

type Props = {
  primary?: number;
  secondary?: number | number[];
  type?: PriceAlertTriggerType;
  limit?: number;
};

const useFetchDynamicTriggeredPriceAlerts = ({ primary, secondary, type, limit = 20 }: Props = {}) => {
  const { userId } = UserStore.useUserStore;

  const fetchDynamicPriceAlerts = useCallback(
    async (page = 0) => {
      const response = await api.endpoints.getTriggerAlerts({
        query: { primary, secondary, limit, type, offset: page * limit },
      });

      const sortedAlerts = response.data.sort((a, b) => b.dateTriggered - a.dateTriggered);

      return { alerts: sortedAlerts, page: response.data.length > 0 ? page : undefined };
    },
    [limit, primary, secondary, type],
  );

  const { status, data, error, isFetching, isPreviousData, isStale, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['dynamic-triggered-price-alerts', primary, secondary, type, userId],
      queryFn: ({ pageParam = 0 }) => fetchDynamicPriceAlerts(pageParam),
      staleTime: TimesEnum.MINUTE * 5,
      getNextPageParam: (lastPage) => (lastPage.page !== undefined ? lastPage.page + 1 : undefined),
      getPreviousPageParam: (lastPage) => (lastPage.page !== undefined ? lastPage.page - 1 : undefined),
    });

  return {
    status,
    data: data?.pages.flatMap((p) => p.alerts),
    error,
    isFetching,
    isPreviousData,
    isStale,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };
};

export { useFetchDynamicTriggeredPriceAlerts };

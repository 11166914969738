import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Abn: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.82 6.01167C11.2279 6.08457 11.5003 6.4788 11.4284 6.8922L11.0756 8.91977H13.9939L14.3926 6.62823C14.4645 6.21482 14.8535 5.93878 15.2614 6.01167C15.6693 6.08457 15.9417 6.4788 15.8698 6.8922L15.517 8.91977H17.25C17.6642 8.91977 18 9.26007 18 9.67985C18 10.0996 17.6642 10.4399 17.25 10.4399H15.2525L14.7235 13.4803H16.75C17.1642 13.4803 17.5 13.8206 17.5 14.2404C17.5 14.6602 17.1642 15.0005 16.75 15.0005H14.459L14.0465 17.3718C13.9745 17.7852 13.5855 18.0612 13.1776 17.9883C12.7697 17.9154 12.4973 17.5212 12.5692 17.1078L12.9359 15.0005H10.0176L9.60505 17.3718C9.53312 17.7852 9.14413 18.0612 8.73621 17.9883C8.32829 17.9154 8.05591 17.5212 8.12784 17.1078L8.49449 15.0005H6.75C6.33579 15.0005 6 14.6602 6 14.2404C6 13.8206 6.33579 13.4803 6.75 13.4803H8.75898L9.28796 10.4399H7.25C6.83579 10.4399 6.5 10.0996 6.5 9.67985C6.5 9.26007 6.83579 8.91977 7.25 8.91977H9.55245L9.95115 6.62823C10.0231 6.21482 10.4121 5.93878 10.82 6.01167ZM13.7294 10.4399L13.2004 13.4803H10.2821L10.8111 10.4399H13.7294Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 3.5H17C18.933 3.5 20.5 5.067 20.5 7V17C20.5 18.933 18.933 20.5 17 20.5H7C5.067 20.5 3.5 18.933 3.5 17V7C3.5 5.067 5.067 3.5 7 3.5Z'
      fill='currentColor'
    />
  </svg>
);

export { Abn };

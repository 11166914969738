import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { AssetCategories } from '@swyftx/aviary/organisms/AssetCategories/AssetCategories';
import { AssetList } from '@swyftx/aviary/organisms/AssetList/AssetList';

import { SingleCategoryPage } from '@routes/AssetsList/subroutes/SingleCategory';
import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import AssetPage from './subroutes/Asset';

const convertToAviaryRoute = (path: NavigationURLs) => {
  switch (path) {
    case NavigationURLs.AssetsAll:
      return NavigationURLs.AviaryAssetsAll;
    case NavigationURLs.AssetsFavourites:
      return NavigationURLs.AviaryAssetsFavourites;
    case NavigationURLs.AssetsOwned:
      return NavigationURLs.AviaryAssetsOwned;
    case NavigationURLs.AssetsGainers:
      return NavigationURLs.AviaryAssetsGainers;
    case NavigationURLs.AssetsLosers:
      return NavigationURLs.AviaryAssetsLosers;
    case NavigationURLs.AssetsNew:
      return NavigationURLs.AviaryAssetsNew;
    default:
      return path;
  }
};

export const AssetListRoutes: RouteObject[] = [
  {
    path: NavigationURLs.AssetsCategory,
    element: <PrivateRoute element={<AssetList path='Category' />} />,
  },
  {
    path: NavigationURLs.AssetFilter,
    element: <PrivateRoute element={<AssetList path='Assets' />} />,
  },
  {
    path: NavigationURLs.AssetsCategories,
    element: <PrivateRoute element={<AssetCategories />} />,
  },
  {
    path: NavigationURLs.SingleCategory,
    element: <PrivateRoute element={<SingleCategoryPage />} />,
  },
  {
    path: NavigationURLs.SingleAsset,
    element: <PrivateRoute element={<AssetPage />} />,
  },
  {
    path: NavigationURLs.Assets,
    element: <Navigate to={convertToAviaryRoute(NavigationURLs.AssetsAll)} />,
  },
  // TODO: delete these when removing the old asset list code and change all the references to the new routes
  {
    path: NavigationURLs.AssetsGainers,
    element: <Navigate to={convertToAviaryRoute(NavigationURLs.AssetsGainers)} />,
  },
  {
    path: NavigationURLs.AssetsLosers,
    element: <Navigate to={convertToAviaryRoute(NavigationURLs.AssetsLosers)} />,
  },
  {
    path: NavigationURLs.AssetsNew,
    element: <Navigate to={convertToAviaryRoute(NavigationURLs.AssetsNew)} />,
  },
  {
    path: NavigationURLs.AssetsFavourites,
    element: <Navigate to={convertToAviaryRoute(NavigationURLs.AssetsFavourites)} />,
  },
  {
    path: NavigationURLs.AssetsAll,
    element: <Navigate to={convertToAviaryRoute(NavigationURLs.AssetsAll)} />,
  },
];

import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { observer } from 'mobx-react-lite';
import { LimitedFunctionalityNotification } from 'src/lib/trade/components';

import { EditOrderAmount } from './EditOrderAmount';
import { EditOrderDetails } from './EditOrderDetails';
import { EditOrderFooter } from './EditOrderFooter';
import { EditOrderTriggerPrice } from './EditOrderTriggerPrice';
import { EditTriggerOrderContext } from './EditTriggerOrder.context';
import { TradeAssetName } from './TradeAssetName';

type Props = {
  onClose: () => void;
};

export const AviaryEditTriggerOrdersModal: React.FC<Props> = observer((props) => {
  const { onClose } = props;
  const isXs = useTailwindBreakpoint('xs');

  const { fromAsset, toAsset, handleSubmit, orderType, error } = useContext(EditTriggerOrderContext);

  return (
    <Modal
      title={`Edit ${orderType} order`}
      open
      showCloseButton
      onOpenChange={(e) => (e?.valueOf() === false ? onClose() : undefined)}
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={24} className='h-full p-24 pt-0'>
        <TradeAssetName title='Trade from' assetId={fromAsset.id} />
        <TradeAssetName title='Trade to' assetId={toAsset.id} />
        <EditOrderTriggerPrice />
        <EditOrderAmount />
        <LimitedFunctionalityNotification asset={toAsset} />
        <EditOrderDetails />
        <FlexLayout className='justify-end'>
          <EditOrderFooter onClose={onClose} onConfirm={handleSubmit} error={error} />
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

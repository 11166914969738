import { z } from 'zod';

export const CorporateShareholderSchema = z.object({
  name: z.string().min(1, 'Please enter a valid name'),
  businessActivity: z.string().min(1, 'Please enter a valid nature of business response'),
});

export const CorporateTrusteeSchema = z.object({
  name: z.string().min(1, 'Please enter a valid name'),
  businessActivity: z.string().min(1, 'Please enter a valid nature of business response'),
  purposeOfAccount: z.string().min(1, 'Please enter a valid purpose of account'),
});

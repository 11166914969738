import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { EmptyPieChart } from '@global-components/charts/PieChart/EmptyPIeChart';
import { PieChart } from '@global-components/charts/PieChart/PieChart';
import { usePieChartAssetData, usePieChartOverviewData } from '@global-components/charts/PieChart/PieChart.hooks';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { getBalanceKeyFromWalletType } from '@Wallet/components/WalletPortfolioAllocation/WalletPortfolioAllocation.utils';
import { WalletPortfolioAllocationOption } from '@Wallet/components/WalletPortfolioAllocation/types';
import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { useSize } from 'react-use';

type Props = {
  selectedOption: WalletPortfolioAllocationOption;
  setSelectedPortfolioItem: (asset: number | null) => void;
  selectedPortfolioItem: number | null;
  walletType: WalletType;
  isPlaceholder?: boolean;
};

const overviewDataOptions = { hideFiat: true };

export const WalletPortfolioAllocationPieChart: React.FC<Props> = observer(
  ({ setSelectedPortfolioItem, selectedPortfolioItem, selectedOption, isPlaceholder, walletType }) => {
    // Grab dynamic numeric values for calculations
    const [heightSized, { height }] = useSize(<div className='h-[70%] w-[0px] sm:h-[80%]' />);
    const allAssets: Asset[] = assetService.getActiveAssetList();

    const pieDataOptions = useMemo(
      () => ({
        balanceKey: getBalanceKeyFromWalletType(walletType),
      }),
      [walletType],
    );
    const { pieData } = usePieChartAssetData(allAssets, pieDataOptions);

    const overviewData = usePieChartOverviewData(allAssets, overviewDataOptions);

    // need to merge the two for transitions
    const pieChartData = useMemo(
      () =>
        selectedOption === WalletPortfolioAllocationOption.WalletTypes
          ? [...overviewData, ...pieData.map((datum) => ({ ...datum, value: 0 }))]
          : [...overviewData.map((datum) => ({ ...datum, value: 0 })), ...pieData],
      [selectedOption, overviewData, pieData],
    );

    const onSliceClick = (id: number | null) => {
      // Unselect if same asset
      if (selectedPortfolioItem === id || id === null) {
        setSelectedPortfolioItem(null);
      } else {
        setSelectedPortfolioItem(id);
      }
    };

    return (
      <FlexLayout justifyContent='center' alignItems='center' className='relative h-full w-full flex-1'>
        {heightSized}
        {height !== Infinity && (
          <div className='h-full min-h-[25rem] w-full'>
            {isPlaceholder ? (
              <EmptyPieChart chartSize={height} />
            ) : (
              <PieChart
                selectedId={selectedPortfolioItem || null}
                onSliceClick={onSliceClick}
                data={pieChartData}
                height={height}
                width={height}
              />
            )}
          </div>
        )}
      </FlexLayout>
    );
  },
);

import { useMemo } from 'react';

import { Game } from '@swyftx/aviary/icons/outlined';
import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

const useDemoModeTour = (): TourGuideData =>
  useMemo(
    () => ({
      icon: <Game className='h-28 w-28 text-color-text-primary' />,
      name: 'Toggle demo mode',
      description: 'Swyftx Demo mode allows you to practice and learn the crypto market.',
      steps: [
        {
          title: 'Open settings menu',
          description: 'Click the circled settings menu in the header',
          spotlightElementId: 'header-settings',
          alignment: 'left',
          borderRadius: 96,
          onNext: () => {
            document.getElementById('header-settings')?.click();
          },
        },
        {
          title: 'Toggle demo mode',
          description: 'Click on the demo mode toggle to turn on or off demo mode',
          spotlightElementId: 'settings-menu-demo',
          alignment: 'left',
          borderRadius: 8,
        },
      ],
    }),
    [],
  );

export { useDemoModeTour };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { TransactionHistoryTab } from '@routes/Transactions/types';

type Props = {
  value: TransactionHistoryTab;
  onChange: (newValue: TransactionHistoryTab) => void;
};

const TransactionsTabs: React.FC<Props> = ({ value, onChange }) => (
  <FlexLayout className='w-full border-b border-color-border-main p-12'>
    <EnhancedTabs
      tabs={[
        {
          title: 'Orders',
          value: 'orders',
        },
        {
          title: 'Deposits',
          value: 'deposits',
        },
        {
          title: 'Withdrawals',
          value: 'withdrawals',
        },
      ]}
      value={value}
      onChange={onChange}
      size='md'
    />
  </FlexLayout>
);

export { TransactionsTabs };

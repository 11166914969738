import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ShopInformationItem } from '@swyftx/aviary/molecules/ShopInformationItem';
import { ShopInfo } from '@swyftx/aviary/molecules/ShopItem';

import { shopInfoItems } from '@routes/Shop/Shop.data';

export const ShopInformationComponent: React.FC = () => (
  <FlexLayout direction='column' spacing={16} className='@xs:w-full @md:w-[calc(33%-8px)]'>
    {shopInfoItems.map(({ id, title, content }: ShopInfo) => (
      <ShopInformationItem key={title} title={title} content={content} id={id} />
    ))}
  </FlexLayout>
);

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TickInCircle: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M16.0897 10.1569C16.3826 9.86402 16.3826 9.38915 16.0897 9.09626C15.7968 8.80336 15.322 8.80336 15.0291 9.09626L10.8133 13.312L8.96961 11.4691C8.67666 11.1763 8.20178 11.1764 7.90895 11.4694C7.61612 11.7623 7.61622 12.2372 7.90917 12.53L10.2832 14.903C10.5761 15.1958 11.0509 15.1958 11.3437 14.9029L16.0897 10.1569Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.99994 11.9996C1.99994 17.5229 6.47778 21.9996 11.9999 21.9996C17.5221 21.9996 21.9999 17.5229 21.9999 11.9996C21.9999 6.47742 17.5222 1.99963 11.9999 1.99963C6.47773 1.99963 1.99994 6.47742 1.99994 11.9996ZM11.9999 20.4996C7.3061 20.4996 3.49994 16.6944 3.49994 11.9996C3.49994 7.30585 7.30615 3.49963 11.9999 3.49963C16.6937 3.49963 20.4999 7.30585 20.4999 11.9996C20.4999 16.6944 16.6938 20.4996 11.9999 20.4996Z'
      fill='currentColor'
    />
  </svg>
);

export { TickInCircle };

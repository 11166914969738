import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@swyftx/react-web-design-system';

import { GetStartedFeature, GetStartedFeatureStep } from '@global-components/GetStartedFeature/GetStartedFeature';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

type Props = {
  onClose: () => void;
};

export const EditTriggerOrdersAnnouncementModal: React.FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'editTriggerOrdersFeature' });
  const { isXs } = useScreenBreakpoints();
  const { isLightTheme } = useTheme();
  const isMobile = isXs;

  const imgSrc = (() => {
    if (isLightTheme) {
      if (isMobile) {
        return '/assets/images/announcements/edit-trigger-order-light-small.svg';
      }
      return '/assets/images/announcements/edit-trigger-order-light.svg';
    }
    if (isMobile) {
      return '/assets/images/announcements/edit-trigger-order-dark-small.svg';
    }
    return '/assets/images/announcements/edit-trigger-order-dark.svg';
  })();

  const steps: Array<GetStartedFeatureStep> = [
    {
      title: t('steps.step1.title'),
      description: t('steps.step1.description'),
    },

    {
      title: t('steps.step2.title'),
      description: t('steps.step2.description'),
    },

    {
      title: t('steps.step3.title'),
      description: t('steps.step3.description'),
    },
  ];

  return (
    <Modal
      open
      id='edit-trigger-orders-feature'
      sx={{
        minWidth: '600px',
        '.MuiCardContent-root': {
          height: '100%',
          '> .MuiBox-root': { flexGrow: 1, padding: 0 },
          '> .MuiBox-root ~ .MuiBox-root': { flexGrow: 0 },
        },
      }}
      onClose={onClose}
    >
      <GetStartedFeature
        description={t('description')}
        headerLabel={t('headerLabel')}
        title={t('title')}
        onClose={onClose}
        imgSrc={imgSrc}
        steps={steps}
      />
    </Modal>
  );
});

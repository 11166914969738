import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown, ArrowChevronUp, More } from '@swyftx/aviary/icons/outlined';

import { RecurringOrder, RecurringOrderStatus } from '@shared/api/@types/recurringOrder';

import { observer } from 'mobx-react-lite';

import { useEditAutoInvestment } from '../../hooks';
import { AutoInvestDepositDetailsModal } from '../AutoInvestDepositDetailsModal/AutoInvestDepositDetailsModal';
import { DeleteAutoInvestModal } from '../DeleteAutoInvestModal';
import { EditAutoInvestModal } from '../EditAutoInvestModal';
import { ResumeAutoInvestModal } from '../ResumeAutoInvestModal';

type Props = {
  autoInvestment: RecurringOrder;
  showBreakdown: boolean;
  isPaused: boolean;
  isProcessing: boolean;
  setShowBreakdown: (newValue: boolean) => void;
};

const AutoInvestOrderItemActions: React.FC<Props> = observer(
  ({ autoInvestment, showBreakdown, isPaused, isProcessing, setShowBreakdown }) => {
    const [showBottomSheet, setShowBottomSheet] = useState<boolean>();
    const { updateRecurringOrderStatus } = useEditAutoInvestment({ autoInvestment });
    const isXs = useTailwindBreakpoint('xs');

    const handleOnPause = useCallback(() => {
      updateRecurringOrderStatus(RecurringOrderStatus.Paused);
    }, [updateRecurringOrderStatus]);

    const sourceIsAccount = useMemo(() => autoInvestment.source === 'ACCOUNT', [autoInvestment]);

    const getDisabledTooltip = useCallback(
      (action: string) => {
        if (isProcessing) return `${action} orders is not allowed while orders are processing`;
        if (isPaused) return `${action} orders is not allowed for paused orders`;
        return '';
      },
      [isPaused, isProcessing],
    );

    useEffect(() => {
      if (!isXs && showBottomSheet) setShowBottomSheet(false);
    }, [isXs, showBottomSheet]);

    return (
      <>
        <FlexLayout spacing={8} className='flex @sm:hidden'>
          <Modal
            title='Order options'
            open={showBottomSheet}
            position='bottom'
            onOpenChange={setShowBottomSheet}
            showCloseButton
            triggerElement={<Button variant='outlined' size='md' layout='icon' leadingIcon={<More />} />}
          >
            <FlexLayout direction='column' alignItems='start' className='w-full p-4 pt-4' spacing={4}>
              <DeleteAutoInvestModal
                position='bottom'
                templateUuid={autoInvestment.templateUuid}
                name={autoInvestment.label}
                orderType={autoInvestment.source}
              >
                <ListItem className='text-color-text-error' disabled={isProcessing}>
                  Delete
                </ListItem>
              </DeleteAutoInvestModal>

              {!sourceIsAccount && (
                <AutoInvestDepositDetailsModal
                  reference={autoInvestment.templateKey}
                  position='bottom'
                  title={`Deposit details for ${autoInvestment.label}`}
                >
                  <ListItem>Deposit</ListItem>
                </AutoInvestDepositDetailsModal>
              )}

              {sourceIsAccount && !isPaused && (
                <ListItem onClick={handleOnPause} disabled={isProcessing}>
                  Pause
                </ListItem>
              )}
              {isPaused && (
                <ResumeAutoInvestModal autoInvestment={autoInvestment} position='bottom'>
                  <ListItem>Resume</ListItem>
                </ResumeAutoInvestModal>
              )}

              <EditAutoInvestModal autoInvestment={autoInvestment} position='bottom'>
                <ListItem disabled={isProcessing}>Edit</ListItem>
              </EditAutoInvestModal>
            </FlexLayout>
          </Modal>
        </FlexLayout>

        <FlexLayout spacing={8} className='hidden @sm:flex'>
          <DeleteAutoInvestModal
            templateUuid={autoInvestment.templateUuid}
            name={autoInvestment.label}
            orderType={autoInvestment.source}
          >
            <Button
              variant='outlined'
              size='md'
              disabled={isProcessing}
              tooltip={isProcessing ? getDisabledTooltip('Deleting') : ''}
            >
              Delete
            </Button>
          </DeleteAutoInvestModal>

          {!sourceIsAccount && (
            <AutoInvestDepositDetailsModal
              reference={autoInvestment.templateKey}
              title={`Deposit details for ${autoInvestment.label}`}
            >
              <Button variant='outlined' size='md'>
                Deposit
              </Button>
            </AutoInvestDepositDetailsModal>
          )}

          {sourceIsAccount && !isPaused && (
            <Button
              variant='outlined'
              size='md'
              onClick={handleOnPause}
              disabled={isProcessing}
              tooltip={isProcessing ? getDisabledTooltip('Pausing') : ''}
            >
              Pause
            </Button>
          )}
          {isPaused && (
            <ResumeAutoInvestModal autoInvestment={autoInvestment}>
              <Button variant='outlined' size='md'>
                Resume
              </Button>
            </ResumeAutoInvestModal>
          )}
          <EditAutoInvestModal autoInvestment={autoInvestment}>
            <Button variant='outlined' size='md' disabled={isProcessing} tooltip={getDisabledTooltip('Editing')}>
              Edit
            </Button>
          </EditAutoInvestModal>
          <Tooltip title={showBreakdown ? 'Hide asset breakdown' : 'Show asset breakdown'}>
            <Button
              onClick={() => setShowBreakdown(!showBreakdown)}
              layout='icon'
              variant='outlined'
              leadingIcon={showBreakdown ? <ArrowChevronUp /> : <ArrowChevronDown />}
            />
          </Tooltip>
        </FlexLayout>
      </>
    );
  },
);

export { AutoInvestOrderItemActions };

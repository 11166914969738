import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TwoFactorAuthentication: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M4.5 4.75C4.5 3.23122 5.73122 2 7.25 2H12.5C12.9142 2 13.25 2.33579 13.25 2.75C13.25 3.16421 12.9142 3.5 12.5 3.5H7.25C6.55964 3.5 6 4.05964 6 4.75V19.25C6 19.9404 6.55964 20.5 7.25 20.5H14.25C14.9404 20.5 15.5 19.9404 15.5 19.25V10C15.5 9.58579 15.8358 9.25 16.25 9.25C16.6642 9.25 17 9.58579 17 10V19.25C17 20.7688 15.7688 22 14.25 22H7.25C5.73122 22 4.5 20.7688 4.5 19.25V4.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.70327 7.53305C9.63239 6.60394 11.0434 6.45128 12.1311 7.07302L16.7345 2.46956C17.0274 2.17667 17.5023 2.17667 17.7952 2.46956L19.2795 3.95386C19.5724 4.24675 19.5724 4.72162 19.2795 5.01452C18.9866 5.30741 18.5117 5.30741 18.2188 5.01452L17.2649 4.06055L16.0586 5.26685L17.0128 6.22105C17.3057 6.51394 17.3057 6.98882 17.0128 7.28171C16.7199 7.5746 16.245 7.5746 15.9521 7.28171L14.9979 6.32751L13.192 8.13346C13.8144 9.22125 13.6619 10.6329 12.7325 11.5623C11.6202 12.6747 9.81568 12.6731 8.70407 11.5615C7.59247 10.4499 7.59092 8.64541 8.70327 7.53305ZM11.671 8.59381C11.6648 8.58764 11.6587 8.58139 11.6528 8.57506C11.1251 8.0674 10.2844 8.07321 9.76393 8.59371C9.23811 9.11953 9.23817 9.97427 9.76474 10.5008C10.2913 11.0274 11.146 11.0275 11.6719 10.5016C12.1925 9.98095 12.1982 9.13992 11.69 8.6122C11.6836 8.6062 11.6772 8.60007 11.671 8.59381Z'
      fill='currentColor'
    />
    <path
      d='M9.75 17.25C9.75 17.8009 10.1982 18.2492 10.75 18.2492C11.3018 18.2492 11.75 17.8009 11.75 17.25C11.75 16.6991 11.3018 16.25 10.75 16.25C10.1982 16.25 9.75 16.6991 9.75 17.25Z'
      fill='currentColor'
    />
  </svg>
);

export { TwoFactorAuthentication };

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';
import { GroupedScope } from '@shared/utils/lib/entityHelpers';

import { formatPermissionKeys, processPermissionScopes } from './EntityAddMemberWizard.const';

export const useToggleEntityMemberNotifications = (
  entityUuid: string,
  memberUuid: string,
  enable: boolean,
  newEnable: boolean,
  setEnable: (enable: boolean) => void,
) => {
  const { t } = useTranslation('profile');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (newEnable !== enable) {
      (async () => {
        setIsLoading(true);
        setIsSuccess(false);
        setError('');
        try {
          await api.endpoints.updateEntityMemberNotificationSettings({
            data: { enable: newEnable },
            params: { entityUuid, memberUuid },
          });
          setIsSuccess(true);
          setEnable(newEnable);
        } catch (error) {
          setError((error as SwyftxError)?.errorMessage || t('entities.configureMemberModal.overview.labels.error'));
        }
        setIsLoading(false);
      })();
    }
  }, [enable, entityUuid, memberUuid, newEnable]);

  return {
    isLoading,
    isSuccess,
    error,
  };
};

export const useEntityPermissionScopes = (
  entityMember: EntityMember,
  setGroupedScopes: (groupedScopes: GroupedScope) => void,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const req = await api.endpoints.getAPIPermissions();
        const localScopes = formatPermissionKeys(req.data);

        // get scopes for the member
        const memberScopes = entityMember.scope.length > 0 ? entityMember.scope.replace(/app./g, '').split(' ') : [];

        processPermissionScopes(localScopes, memberScopes, setGroupedScopes);
      } catch (e) {
        setError((e as SwyftxError)?.errorMessage || 'Something went wrong');
      }

      setLoading(false);
    })();
  }, []);

  return {
    loading,
    error,
  };
};

import { useContext } from 'react';

import { UIContext } from '../context/UI.context';

const useCurrentPlatform = () => {
  const { currentPlatform, setCurrentPlatform } = useContext(UIContext);

  return {
    currentPlatform,
    setCurrentPlatform,
  };
};

export { useCurrentPlatform };

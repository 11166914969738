import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, Stack } from '@swyftx/react-web-design-system';

import { EmailInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';

type Props = {
  onClose: () => void;
  email: string;
  setEmail: (email: string) => void;
  onAction: () => void;
};

export const UpdateEmailAddressInputStep: React.FC<Props> = ({ onClose, email, setEmail, onAction }) => {
  const { t } = useTranslation('common', { keyPrefix: 'input.notifications' });
  const [valid, setValid] = useState(false);

  return (
    <Wizard.Step onClose={onClose} title='Update email address' locked={!email || !valid} onAction={onAction}>
      <Stack spacing={2}>
        <Notification color='info' title='Important information'>
          {t('phoneEmailWithdrawLimit')}
        </Notification>
        <EmailInput
          setValid={setValid}
          id='update-email-address-modal-new-email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label='Email address'
        />
      </Stack>
    </Wizard.Step>
  );
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Show: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12 15.96C9.84 15.96 8.09 14.21 8.09 12.05C8.09 9.89 9.84 8.14 12 8.14C14.16 8.14 15.91 9.89 15.91 12.05C15.91 14.21 14.16 15.96 12 15.96ZM12 9.64C10.67 9.64 9.59 10.72 9.59 12.05C9.59 13.38 10.67 14.46 12 14.46C13.33 14.46 14.41 13.38 14.41 12.05C14.41 10.72 13.33 9.64 12 9.64ZM12 20.1C7.87 20.1 4.15 17.2 2.06 12.34C1.98 12.15 1.98 11.94 2.06 11.75C4.15 6.9 7.86 4 12 4C16.14 4 19.85 6.9 21.94 11.76C22.02 11.95 22.02 12.16 21.94 12.35C19.85 17.2 16.14 20.1 12 20.11V20.1ZM3.57 12.05C5.43 16.16 8.57 18.6 12 18.6C15.44 18.6 18.57 16.16 20.43 12.05C18.57 7.94 15.44 5.5 12 5.5C8.56 5.5 5.43 7.94 3.57 12.05Z'
      fill='currentColor'
    />
  </svg>
);

export { Show };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

interface MarketTickerItemProps {
  asset: Asset;
  movement: Big;
  isBaseBTC: boolean;
  onClick: (asset: Asset) => void;
}

export const MarketTickerItem: React.FC<MarketTickerItemProps> = (props) => {
  const { asset, movement, isBaseBTC, onClick } = props;
  const baseAsset = useBaseAsset();
  const { getRate } = RatesStore.useRatesStore;

  return (
    <Tooltip title={`View ${asset.name}`}>
      <FlexLayout
        direction='row'
        spacing={8}
        className={cn(
          'mx-8 w-full cursor-pointer items-center justify-center py-4 hover:rounded-8 hover:bg-color-background-surface-hover',
          isBaseBTC ? 'min-w-[13.5rem]' : 'min-w-[10.5rem]',
        )}
        key={`market-ticker-item-${asset.id}`}
        onClick={() => onClick(asset)}
      >
        <Numeric size='small' weight='emphasis'>
          {asset.code}
        </Numeric>
        <NumericDataItem
          data={`${movement.gt(0) ? '+' : ''}${movement.toString()}%`}
          size='small'
          percentage
          renderIcons={false}
        />
        <Numeric size='small' color='secondary' className='truncate'>
          {`${formatCurrency(getRate(asset.id).midPrice, baseAsset)} ${isBaseBTC ? ` ${baseAsset?.code}` : ''}`}
        </Numeric>
      </FlexLayout>
    </Tooltip>
  );
};

import { useEffect } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { ColorType, IChartApi } from 'lightweight-charts';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

import { isSimpleChartState } from '../../utils/Tradingview.utils';

type Props = {
  chartState?: IChartApi | IChartingLibraryWidget;
};

const useSimpleChartTheme = ({ chartState }: Props) => {
  const { theme } = useTailwindTheme();

  useEffect(() => {
    if (!isSimpleChartState(chartState)) return;

    chartState.applyOptions({
      layout: {
        background: {
          type: ColorType.Solid,
          color: getComputedStyle(document.body).getPropertyValue('--color-background-surface-primary'),
        },
        textColor: getComputedStyle(document.body).getPropertyValue('--color-text-primary'),
      },
      grid: {
        vertLines: {
          color: getComputedStyle(document.body).getPropertyValue('--color-background-surface-primary'),
        },
        horzLines: {
          color: getComputedStyle(document.body).getPropertyValue('--color-background-surface-primary'),
        },
      },
    });
  }, [chartState, theme]);
};

export { useSimpleChartTheme };

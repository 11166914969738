import React from 'react';

import { DayPicker, DayPickerProps } from 'react-day-picker';

import 'react-day-picker/dist/style.css';

const CalendarPicker: React.FC<DayPickerProps> = (props) => (
  <DayPicker
    {...props}
    classNames={{
      dropdown: 'rdp-dropdown hover:!bg-color-background-surface-hover',
      caption_label: 'rdp-caption_label',
      day: 'text-color-text-primary !font-mono !text-14 w-36 h-36 focus:rounded-[100%] rounded-0 focus:focus-area-info focus-visible:!border-none',
      day_disabled: 'text-color-disabled',
      day_range_start: '!bg-color-text-primary !text-color-text-inverse rounded-[100%]',
      day_range_middle:
        '!bg-color-background-neutral !text-color-text-primary !font-mono text-color-text-primary gap-0 !rounded-0 !border-none font-400 w-full',
      day_range_end: '!bg-color-text-primary !text-color-text-inverse rounded-[100%]',
      head: 'text-color-text-secondary text-10 font-400',
      caption: 'p-0 flex flex-row items-center justify-between',
      table: 'w-full',
      month: 'w-full',
      nav: 'flex flex-row gap-4 items-center',
      nav_button_previous:
        'focus:focus-area-info focus-visible:!border-none outline-1 outline outline-color-border-main text-color-text-secondary !w-20 !h-20 hover:!bg-color-background-surface-hover focus:!bg-color-background-surface-hover',
      nav_icon: 'text-color-text-secondary',
      nav_button_next:
        'focus:focus-area-info focus-visible:!border-none outline-1 outline outline-color-border-main text-color-text-secondary !w-20 !h-20 hover:!bg-color-background-surface-hover focus:!bg-color-background-surface-hover',
    }}
  />
);

export { CalendarPicker };

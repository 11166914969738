import React, { PropsWithChildren } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = PropsWithChildren<{
  id: string;
  code?: string;
}>;

const WalletListTileContainer: React.FC<Props> = ({ id, code, children }) => {
  const { navigate } = useNavigateRoute();

  const openWallet = () => {
    if (!code) return;

    const type = 'all';
    navigate(NavigationURLs.SingleWallet, { pathParams: { type, asset: code } });
  };

  return (
    <Card
      id={id}
      onClick={openWallet}
      className='min-h-[250px] w-full cursor-pointer p-24 hover:bg-color-background-surface-hover @sm:w-[calc(50%-4px)] @lg:w-[calc(33%-4px)]'
    >
      {children}
    </Card>
  );
};

export { WalletListTileContainer };

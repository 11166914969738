import React from 'react';

import ListItemIconMUI, { ListItemIconProps } from '@mui/material/ListItemIcon';

const MenuItemIcon: React.FC<ListItemIconProps> = ({ id, children, ...props }) => (
  <ListItemIconMUI id={id} {...props}>
    {children}
  </ListItemIconMUI>
);

export { MenuItemIcon };

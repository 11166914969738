import { useMemo } from 'react';

import { UserStore } from '@shared/store';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

export enum CardDepositProvider {
  Banxa = 'Banxa',
  Stripe = 'Stripe',
}

export const useCardDepositProvider = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isNZ } = UserStore.useUserStore;

  const cardDepositProvider = useMemo(() => {
    const isStripeEnabled = isFeatureEnabled(AppFeature.Stripe);
    const isStripeAUOnly = isFeatureEnabled(AppFeature.StripeAUOnly);

    if (isStripeEnabled && (!isStripeAUOnly || !isNZ())) {
      return CardDepositProvider.Stripe;
    }

    return CardDepositProvider.Banxa;
  }, [isFeatureEnabled, isNZ]);

  return cardDepositProvider;
};

import { AddressBrokenUp } from '@shared/api';
import { AccountPurpose, CountriesEnum, SourceOfWealth, ExchangesEnum } from '@shared/enums';

import { DateTime } from 'luxon';

import { Typegen0 as OnboardingMachineTypes } from '../Onboarding.machine.typegen';
import { keysOf } from '../utils/keysOf';

export type VerificationData = {
  givenNames: string;
  middleNames: string;
  surname: string;
  dob: DateTime | null;

  country: CountriesEnum;
  address: AddressBrokenUp;
  citizenshipCountry: CountriesEnum;
  dualCitizenshipCountry?: CountriesEnum;

  accountPurpose: AccountPurpose;

  sourceOfWealth: SourceOfWealth;
};

export type EmailCodeVerificationData = {
  userInputCode: string;
  error: string;
};

export type AssetMigratorData = {
  exchange?: ExchangesEnum;
};

export type MobileCodeVerificationData = {
  userInputCode: string;
  error: string;
};

export type RequiredVerificationData = Exclude<keyof VerificationData, 'middleNames' | 'dualCitizenshipCountry'>;

// creates a compile error if `VerificationData` is updated so we know to update this
const VerificationDataKeyLookup: Record<RequiredVerificationData, null> = {
  givenNames: null,
  surname: null,
  dob: null,
  country: null,
  address: null,
  citizenshipCountry: null,
  accountPurpose: null,
  sourceOfWealth: null,
};

export const RequiredVerificationDataKeys = keysOf(VerificationDataKeyLookup);

export type OnboardingStepId = Extract<OnboardingMachineTypes['matchesStates'], string>;

// Only include 2 levels deep
export type UpdateProfileSteps = Exclude<
  Extract<OnboardingStepId, `setupProfile.${string}`>,
  `setupProfile.${string}.${string}`
>;

// creates a compile error if a new `setupProfile.*` step is added so we know to update this
const UpdateProfileStepLookup: Record<UpdateProfileSteps, null> = {
  'setupProfile.nameAndDob': null,
  'setupProfile.address': null,
  'setupProfile.sourceOfWealth': null,
  'setupProfile.purpose': null,
};

export const UpdateProfileStepIds = keysOf(UpdateProfileStepLookup);

// Returns only the ids of the root steps
// - use as `OnboardingRootSteps` and ignore the T generic type
// - at time of writing the optional `T` generic is required to make the type work :sadge:
export type OnboardingRootSteps<T = OnboardingStepId> = T extends Extract<
  OnboardingStepId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  `${infer Root}.${infer Child}`
>
  ? Root
  : never;

// Given a root id, returns all the child steps without the root included
// - use as `ExtractOnboardingChildSteps<'root_id'>` and ignore the T generic type
// - at time of writing the optional `T` generic is required to make the type work :sadge:
type ExtractOnboardingChildSteps<
  Root extends OnboardingRootSteps | `${OnboardingRootSteps}.${string}`,
  T = OnboardingStepId,
> = T extends Extract<OnboardingStepId, `${Root}.${infer Child}`> ? Child : never;

export type MobileVerificationSteps = ExtractOnboardingChildSteps<'mobileVerification'>;
export type EmailVerificationSteps = ExtractOnboardingChildSteps<'emailVerification'>;

/* eslint-disable no-unused-vars */
import { TimesEnum } from '@shared/enums';
import handleError from '@shared/error-handler';
import { Listener, subscribeToEvent, SwyftxEvent } from '@shared/events';
import logger from '@shared/logger';

const logTag = 'CRON_MANAGER';

export interface CronJob {
  updateInterval: number;
  lastUpdate: number;
  enabled: boolean;
  running: boolean;
  timer: ReturnType<typeof setTimeout> | null;
  updateFn: () => Promise<void>;
  cleanupFn?: () => void;
}

const jobs: CronJob[] = [];

export const createCronJob = (
  updateInterval: number,
  updateFn: () => Promise<void>,
  cleanupFn?: () => void,
): CronJob => {
  const newJob: CronJob = {
    lastUpdate: 0,
    enabled: false,
    running: false,
    timer: null,
    updateInterval,
    updateFn,
    cleanupFn,
  };
  // add to jobs
  jobs.push(newJob);
  // start job
  startCronJob(newJob);
  // return job so creator has control
  return newJob;
};

export const startCronJob = (job: CronJob, forceStart = false) => {
  // exit if already enabled
  if (job.running || (!forceStart && job.enabled)) return;
  // otherwise start the job
  job.enabled = true;
  if (job.timer) {
    clearTimeout(job.timer);
  }
  let timeout = job.updateInterval - (Date.now() - job.lastUpdate);
  // minimum re-run should be 10 seconds from now or jobs update interval
  timeout = Math.max(timeout, TimesEnum.SECOND * 10);
  timeout = Math.min(timeout, job.updateInterval);
  job.timer = setTimeout(() => runCronJob(job), timeout);
};

export const stopCronJob = (job: CronJob) => {
  // exit if already stopped
  if (!job.enabled) return;
  // otherwise stop the job
  job.enabled = false;
  if (job.timer) {
    clearTimeout(job.timer);
  }
};

export const runCronJob = async (job: CronJob, force = false) => {
  // short circuit if stopped or is currently running
  if (!job.enabled || job.running) {
    return;
  }
  // only allow job to run once at a time
  job.running = true;
  try {
    if (force || Date.now() - job.lastUpdate >= job.updateInterval) {
      // run job
      await job.updateFn();
      // update age
      job.lastUpdate = Date.now();
    }
  } catch (ex) {
    // errors should be handled inside the jobs update fn.
    // Errors that hit here should be resolved by developers.
    handleError(logTag, 'Cron job error', ex);
  }
  job.running = false;

  // set timeout to refresh
  if (job.enabled) {
    startCronJob(job, true);
  }
};

/** Stop and start a cron job with optional update interval */
export const restartCronJob = (job: CronJob, newInterval?: number) => {
  job.updateInterval = newInterval ?? job.updateInterval;

  // don't allow job to be restarted if it's already running
  if (job.running) return;
  stopCronJob(job);
  startCronJob(job);
};

/** Remove and cleanup cron job from manager */
export const deleteCronJob = (job: CronJob) => {
  stopCronJob(job);
  if (job.cleanupFn) job.cleanupFn();
  const index = jobs.findIndex((e) => e === job);
  if (index >= -1) jobs.splice(index, 1);
};

let listener: Listener | null;

/** Initialise cron manager and start all job timers */
const init = () => {
  // listen to app state changes to stop/start update timer
  listener = subscribeToEvent(SwyftxEvent.AppStateChange, ({ active }: { active: boolean }) => {
    for (const job of jobs) {
      if (active) {
        startCronJob(job);
      } else {
        stopCronJob(job);
      }
    }
  });

  // start all jobs
  for (const job of jobs) {
    startCronJob(job);
  }
};

/** Cleanup cron manager and stop all job timers and run their cleanup */
const cleanup = () => {
  // remove listener
  if (listener) {
    listener.unsubscribe();
    listener = null;
  }
  // stop all jobs
  for (const job of jobs) {
    stopCronJob(job);
    if (job.cleanupFn) job.cleanupFn();
  }
};

export const cronManager = {
  init,
  cleanup,
};

// [dev] When hot reloading remove the cron
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const module: any;
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    logger.debug(logTag, 'Hot reload cleanup');
    jobs.forEach((job) => deleteCronJob(job));
  });
}

import React from 'react';

import { Box, Slide, Theme, useMediaQuery, useTheme } from '@mui/material';
import ModalMUI, { ModalProps as ModalPropsMUI } from '@mui/material/Modal';
import { SystemCssProperties } from '@mui/system';

import { ModalFooter, ModalFooterProps } from './ModalFooter';
import { ModalHeader, ModalHeaderProps } from './ModalHeader';
import Card from '../card';
import { getPIIClassName } from '../global-utils';
import { PIITypes } from '../types/PII.types';

export type ModalProps = {
  variant?: 'Modal' | 'BottomSheet';
  HeaderProps?: ModalHeaderProps;
  FooterProps?: ModalFooterProps;
} & ModalPropsMUI &
  PIITypes;

const Modal: React.FC<ModalProps> = ({
  id,
  sx,
  variant = 'BottomSheet',
  HeaderProps,
  FooterProps,
  children,
  PII,
  onClose,
  ...props
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  // TODO - weird typing errors so had to cast sx to any
  const { width, height, maxWidth, maxHeight, ...rest } = (sx as SystemCssProperties<Theme>) || {};
  const isSliding = variant === 'BottomSheet' && !isLargeScreen;
  const isModal = variant === 'Modal';

  const getContent = () => (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: isLargeScreen || isModal ? '50%' : '2rem',
        left: isLargeScreen || isModal ? '50%' : '0',
        width: isLargeScreen || isModal ? width : '100%',
        height: isLargeScreen || isModal ? height : 'calc(100% - 2rem)',
        maxWidth: isLargeScreen || isModal ? maxWidth : '100%',
        maxHeight: isLargeScreen || isModal ? maxHeight : '100%',
        transform: isLargeScreen || isModal ? 'translate(-50%, -50%)' : undefined,
        borderBottomLeftRadius: isLargeScreen || isModal ? undefined : 0,
        borderBottomRightRadius: isLargeScreen || isModal ? undefined : 0,
        zIndex: theme.zIndex.modal,
        ...rest,
      }}
    >
      <Card
        sx={{ width: '100%' }}
        contentSx={{
          width: '100%',
          padding: 0,
          paddingBottom: '0 !important',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {HeaderProps && <ModalHeader {...HeaderProps} onClose={onClose} />}
        <Box padding={2} sx={{ overflowY: 'auto' }}>
          {children}
        </Box>
        {FooterProps && <ModalFooter {...FooterProps} />}
      </Card>
    </Box>
  );

  return (
    <ModalMUI id={id} className={getPIIClassName(PII)} {...props} onClose={onClose} closeAfterTransition={isSliding}>
      {isSliding ? (
        <Slide in={props.open} direction='up' style={{ width: '100%' }}>
          {getContent()}
        </Slide>
      ) : (
        <Box width='100%'>{getContent()}</Box>
      )}
    </ModalMUI>
  );
};

export default Modal;

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { useWalletMetricContent } from '@Wallet/components/WalletMetrics/WalletMetrics.hooks';
import { WalletMetricCard } from '@Wallet/components/WalletMetrics/components/WalletMetricCard';
import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

type Props = {
  walletType: WalletType;
};

const WalletMetrics: React.FC<Props> = observer(({ walletType }) => {
  const metricPropData = useWalletMetricContent(walletType);

  return (
    <FlexLayout className='w-full p-0 @container' spacing={16}>
      {/* Desktop View */}
      <FlexLayout className='hidden w-full @md:w-5/12 @lg:flex'>
        <FlexLayout spacing={16} direction='row' className='w-full'>
          {metricPropData.slice(0, 2).map((metricProps) => (
            <FlexLayout className='flex-1' key={metricProps.title}>
              <WalletMetricCard {...metricProps} />
            </FlexLayout>
          ))}
        </FlexLayout>
      </FlexLayout>

      <FlexLayout className='hidden w-full @md:w-7/12 @lg:flex'>
        <FlexLayout spacing={16} direction='row' className='w-full'>
          {metricPropData.slice(2).map((metricProps) => (
            <FlexLayout className='flex-1' key={metricProps.title}>
              <WalletMetricCard {...metricProps} />
            </FlexLayout>
          ))}
        </FlexLayout>
      </FlexLayout>

      {/* Compact View */}
      <FlexLayout direction='row' className='flex w-full @md:w-5/12 @lg:hidden'>
        <WalletMetricCard {...metricPropData[0]} size='large' />
      </FlexLayout>

      <FlexLayout direction='row' className='flex w-full @md:w-7/12 @lg:hidden'>
        <FlexLayout direction='column' spacing={16} className='w-full'>
          <FlexLayout spacing={16} direction='row'>
            {metricPropData.slice(1, 3).map((metricProps) => (
              <FlexLayout className='flex-1' key={metricProps.title}>
                <WalletMetricCard {...metricProps} />
              </FlexLayout>
            ))}
          </FlexLayout>
          <FlexLayout spacing={16} direction='row'>
            {metricPropData.slice(3, 5).map((metricProps) => (
              <WalletMetricCard key={metricProps.title} {...metricProps} />
            ))}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

WalletMetrics.displayName = 'WalletMetrics';

export { WalletMetrics };

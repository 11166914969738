import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { ContentBanner } from '@global-components/ContentBanner';

interface Props {
  bannerIcon: JSX.Element;
  onAction: () => void;
}

export const MoreInformationRequiredContent: React.FC<Props> = (props) => {
  const { bannerIcon, onAction } = props;

  return (
    <FlexLayout direction='column'>
      <ContentBanner
        onAction={onAction}
        actionLabel='Submit additional information'
        description='After reviewing your source of wealth submission, we require additional information to complete the process.'
        title='We require more information.'
        icon={bannerIcon}
      />
    </FlexLayout>
  );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { AppHeaderAssetActions } from '@global-components/AppHeader/AppHeaderAssetActions';
import { AppHeaderWalletPicker } from '@global-components/AppHeader/AppHeaderWalletPicker';
import LimitedFunctionalityNotification from '@global-components/LimitedFunctionalityNotification/LimitedFunctionalityNotification';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { UIStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import {
  SingleWalletFiatDeposit,
  SingleWalletHoldings,
  SingleWalletReceive,
  SingleWalletSend,
  SingleWalletTradingInfo,
  SingleWalletTransactionHistory,
  SingleWalletUnsupported,
} from './components';
import { SingleWalletFiatWithdrawal } from './components/SingleWalletFiatWithdrawal';

type SingleWalletParams = {
  asset: string;
  type: 'all' | 'trade' | 'earn';
};

const SingleWallet: React.FC = observer(() => {
  const [asset, setAsset] = useState<Asset | undefined>(undefined);
  const { asset: assetParam, type: typeParam } = useParams<SingleWalletParams>();
  const { setCustomHeaderOptions, setCustomBreadcrumb } = UIStore.useUIStore;
  const countryAsset = useCountryAsset();
  const { navigate } = useNavigateRoute();

  useEffect(() => {
    if (assetParam) {
      setCustomBreadcrumb(
        assetParam.toLowerCase(),
        <AppHeaderWalletPicker assetCode={assetParam} type={typeParam} key={assetParam.toLowerCase()} />,
      );
      setCustomHeaderOptions(<AppHeaderAssetActions assetCode={assetParam} />);
    }

    return () => {
      setCustomHeaderOptions(undefined);
    };
  }, [assetParam, setCustomBreadcrumb, setCustomHeaderOptions, typeParam]);

  useEffect(() => {
    if (!assetParam) {
      navigate(NavigationURLs.WalletsRoot);
      return;
    }

    const assetByCode = assetService.getAssetByCode(assetParam.toUpperCase());

    if (!assetByCode) {
      navigate(NavigationURLs.WalletsRoot);
      return;
    }
    setAsset(assetByCode);
  }, [assetParam, navigate]);

  if (!asset) return null;

  return (
    <FlexLayout className='w-full p-16 @container' direction='column' spacing={16}>
      {asset && <LimitedFunctionalityNotification asset={asset} />}
      <FlexLayout className='w-full flex-col @sm:flex-row' spacing={16}>
        <FlexLayout direction='column' spacing={16} className='w-full @sm:w-7/12'>
          <SingleWalletHoldings asset={asset} />
          <SingleWalletTransactionHistory asset={asset} />
        </FlexLayout>
        <FlexLayout direction='column' spacing={16} className='w-full @sm:w-5/12'>
          {asset.id !== countryAsset?.id && (
            <>
              <SingleWalletTradingInfo asset={asset} />
            </>
          )}

          {asset.assetType === AssetType.Crypto && (
            <>
              <SingleWalletSend asset={asset} />
              <SingleWalletReceive asset={asset} />
            </>
          )}
          {asset.assetType === AssetType.Fiat && asset.id === countryAsset?.id && (
            <>
              <SingleWalletFiatDeposit asset={countryAsset} />

              <SingleWalletFiatWithdrawal asset={countryAsset} />
            </>
          )}
          {asset.assetType === AssetType.Fiat && asset && asset.id !== countryAsset?.id && (
            <SingleWalletUnsupported asset={asset} />
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

SingleWallet.displayName = 'SingleWallet';

export default SingleWallet;

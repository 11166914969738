import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { AssetFilter } from '@hooks/Assets/useAssets';

import { searchPlaceholders } from '@Trade/components/UniversalTradePanel/components/TradeAssetSelector/TradeAssetSelector.data';

import { useDebounce } from 'react-use';

type Props = {
  onChange: (val: string) => void;
  selectedFilter: AssetFilter;
  focused?: boolean;
  onFocus?: () => void;
};

const TradeAssetSelectorSearch: React.FC<Props> = ({ onChange, selectedFilter, focused, onFocus }) => {
  const { t } = useTranslation('trade');
  const [filterSearch, setFilterSearch] = useState<string>('');

  useDebounce(
    () => {
      onChange(filterSearch);
    },
    400,
    [filterSearch],
  );

  return (
    <Input
      id='trade-asset-selector-search'
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterSearch(e.target.value)}
      placeholder={t(searchPlaceholders[selectedFilter] as any)}
      autoFocus={focused}
      onFocus={onFocus}
      trailing={<Button variant='ghost' onClick={() => setFilterSearch('')} layout='icon' leadingIcon={<Cross />} />}
      value={filterSearch}
    />
  );
};

export { TradeAssetSelectorSearch };

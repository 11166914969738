import { useCallback, useMemo, useState } from 'react';

import { CorporateTrustForm, Individual, Shareholder } from '@shared/api';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import { IndividualWithUuid } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';

import { useToggle } from 'react-use';
import { v4 as uuid } from 'uuid';

export const useTrustDirectorsStep = () => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as CorporateTrustForm;
  const prevDirectors = applicationData.directors
    ? applicationData.directors.map((director) => ({ individual: director, uuid: uuid() }))
    : undefined;
  const [directors, setDirectors] = useState<IndividualWithUuid[]>(prevDirectors ?? []);
  const prevDirectorsAlsoShareholders = applicationData.shareholders
    ? (applicationData.shareholders.filter((shareholder) => shareholder.type === 'INDIVIDUAL') as Individual[]).map(
        (shareholder) => ({
          individual: shareholder,
          uuid:
            prevDirectors?.find(
              (director) =>
                director.individual.email === shareholder.email &&
                director.individual.firstName === shareholder.firstName,
            )?.uuid ?? uuid(),
        }),
      )
    : undefined;
  const prevDirectorsAlsoBeneficiaries = applicationData.beneficiaries
    ? (applicationData.beneficiaries.filter((beneficiary) => beneficiary.type === 'INDIVIDUAL') as Individual[]).map(
        (shareholder) => ({
          individual: shareholder,
          uuid:
            prevDirectors?.find(
              (director) =>
                director.individual.email === shareholder.email &&
                director.individual.firstName === shareholder.firstName,
            )?.uuid ?? uuid(),
        }),
      )
    : undefined;
  const [shareholders, setShareholders] = useState<IndividualWithUuid[]>(prevDirectorsAlsoShareholders ?? []);
  const [beneficiaries, setBeneficiaries] = useState<IndividualWithUuid[]>(prevDirectorsAlsoBeneficiaries ?? []);
  const [addMemberOpen, toggleAddMemberOpen] = useToggle(false);

  const continueDisabled = useMemo(() => !directors.length, [directors]);

  const onAddMember = useCallback(
    (individual: Individual, isShareholder: boolean, isBeneficiary?: boolean) => {
      const newUuid = uuid();
      if (isShareholder) {
        setShareholders((prev) => [...prev, { individual, uuid: newUuid }]);
      }
      if (isBeneficiary) {
        setBeneficiaries((prev) => [...prev, { individual, uuid: newUuid }]);
      }
      setDirectors((prev) => [...prev, { individual, uuid: newUuid }]);
      toggleAddMemberOpen(false);
    },
    [setDirectors, toggleAddMemberOpen],
  );

  const onEditMember = useCallback(
    (individual: IndividualWithUuid, isShareholder?: boolean, isBeneficiary?: boolean) => {
      if (isShareholder) {
        setShareholders((prev) => [...prev.filter((member) => member.uuid !== individual.uuid), individual]);
      } else {
        setShareholders((prev) => [...prev.filter((member) => member.uuid !== individual.uuid)]);
      }
      if (isBeneficiary) {
        setBeneficiaries((prev) => [...prev.filter((member) => member.uuid !== individual.uuid), individual]);
      } else {
        setBeneficiaries((prev) => [...prev.filter((member) => member.uuid !== individual.uuid)]);
      }
      setDirectors((prev) => [...prev.filter((member) => member.uuid !== individual.uuid), individual]);
    },
    [],
  );

  const handleRemove = useCallback((memberUuid: string) => {
    setDirectors((prev) => prev.filter((director) => director.uuid !== memberUuid));
    setShareholders((prev) => prev.filter((shareholder) => shareholder.uuid !== memberUuid));
    setBeneficiaries((prev) => prev.filter((beneficiary) => beneficiary.uuid !== memberUuid));
  }, []);

  const combineOldAndNewShareholders = useCallback(
    (prevShareholders: Shareholder[], newIndividualShareholders: Individual[]) => {
      const newIndividualShareholdersEmails = newIndividualShareholders.map((shareholder) => shareholder.email);
      const filteredOldShareholders = prevShareholders.filter(
        (shareholder) =>
          shareholder.type === 'CORPORATE' || !newIndividualShareholdersEmails.includes(shareholder.email),
      );

      return [...filteredOldShareholders, ...newIndividualShareholders];
    },
    [],
  );

  const combineOldAndNewBeneficiaries = useCallback(
    (prevBeneficiaries: Individual[], newIndividualBeneficiaries: Individual[]) => {
      const newIndividualBeneficiariesEmails = newIndividualBeneficiaries.map((beneficiary) => beneficiary.email);
      const filteredOldBeneficiaries = prevBeneficiaries.filter(
        (beneficiary) => !newIndividualBeneficiariesEmails.includes(beneficiary.email),
      );

      return [...filteredOldBeneficiaries, ...newIndividualBeneficiaries];
    },
    [],
  );

  const shareholdersToSubmit = useMemo(
    () =>
      combineOldAndNewShareholders(
        applicationData.shareholders ?? [],
        shareholders.map((shareholder) => shareholder.individual),
      ),
    [applicationData.shareholders, combineOldAndNewShareholders, shareholders],
  );

  const beneficiariesToSubmit = useMemo(
    () =>
      combineOldAndNewBeneficiaries(
        applicationData.beneficiaries ?? [],
        beneficiaries.map((beneficiary) => beneficiary.individual),
      ),
    [applicationData.beneficiaries, beneficiaries, combineOldAndNewBeneficiaries],
  );

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: {
          directors: directors.map((director) => director.individual),
          shareholders: shareholdersToSubmit,
          beneficiaries: beneficiariesToSubmit,
        } as CorporateTrustForm,
      }),
    [beneficiariesToSubmit, directors, entityOnboardingService, shareholdersToSubmit],
  );

  const handleBack = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'BACK',
        data: {
          directors: directors.map((director) => director.individual),
          shareholders: shareholdersToSubmit,
          beneficiaries: beneficiariesToSubmit,
        } as CorporateTrustForm,
      }),
    [beneficiariesToSubmit, directors, entityOnboardingService, shareholdersToSubmit],
  );

  const onCancel = useCallback(() => {
    toggleAddMemberOpen(false);
  }, [toggleAddMemberOpen]);

  return {
    continueDisabled,
    handleBack,
    handleSubmit,
    onCancel,
    addMemberOpen,
    toggleAddMemberOpen,
    onAddMember,
    onEditMember,
    handleRemove,
    directors,
    shareholders,
    beneficiaries,
  };
};

import React from 'react';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { ToastManagerContextProvider } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';
import { TourGuideContextProvider } from '@swyftx/aviary/molecules/TourGuide';

import env from '@shared/config';
import { StorageKey } from '@shared/storage';
import { Compose, withProps } from '@shared/utils/lib/Compose';

import { LoginProvider } from '@routes/Login/Login.context';
import { RewardsProvider } from '@routes/Rewards/Rewards.context';
import { init as initTranslations } from '@utils/i18n/i18n';

import { DashboardProvider } from '@Dashboard/Dashboard.context';
import { ProfileProvider } from '@Profile/Profile.context';
import { ProfileVerificationProvider } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { RegisterProvider } from '@Register/Register.context';

import * as braze from '@braze/web-sdk';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import { HttpClient } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { IntercomProvider } from 'react-use-intercom';
import { FeatureDiscoveryProvider } from 'src/lib/feature-discovery/context/FeatureDiscovery.context';
import { NavigationContextProvider } from 'src/lib/navigation/context';
import { UIContextProvider } from 'src/lib/utils/context/UI.context';

import { AvoProvider } from './Avo/Avo.context';
import { BrazeProvider } from './Braze/Braze.context';
import { UsersnapProvider } from './UserSnap/UserSnap.context';

export const getGlobalContext = async () => {
  FullStory.init({ orgId: env.FULLSTORY_ORG_ID, devMode: process.env.NODE_ENV !== 'production' });

  if (env.ENABLE_XSTATE_INSPECT && process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    const { inspect } = require('@xstate/inspect');
    inspect({ iframe: false });
  }

  await initTranslations();

  // Using a community driven config found here https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a

  Sentry.init({
    dsn: env.SENTRY_DNS,
    integrations: [
      new SentryFullStory('swyftx'),
      new HttpClient(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',

      // Errors that appear to be thrown from 3rd parties that we cant control
      'Non-Error promise rejection captured',
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    environment: env.SENTRY_ENVIRONMENT,
    release: `swyftx-react-web@${env.COMMIT_HASH}`,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      new RegExp(`^${env.SWYFTX_API_URL.replace(/https?:\/\/|\/$/g, '')}.*`),
      new RegExp(`^${env.SWYFTX_DEMO_API_URL.replace(/https?:\/\/|\/$/g, '')}.*`),
    ],
  });

  braze.initialize(env.BRAZE_ENVIRONMENT_KEY, {
    baseUrl: 'sdk.iad-05.braze.com',
    requireExplicitInAppMessageDismissal: true,
    inAppMessageZIndex: 500000,
    minimumIntervalBetweenTriggerActionsInSeconds: 5,
  });

  const queryClient = new QueryClient();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: env.LAUNCH_DARKLY_CLIENT_ID || '',
    context: {
      kind: 'user',
      key: 'swyftx',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const globalContext = ({ children }: { children: React.ReactNode }) => (
    <Compose
      components={[
        withProps(QueryClientProvider, {
          client: queryClient,
        }),
        withProps(IntercomProvider, {
          appId: env.INTERCOM_APP_ID,
          initializeDelay: 250,
          autoBootProps: { alignment: 'left', horizontalPadding: 20 },
          onHide: () => {
            if (JSON.parse(window.localStorage.getItem(StorageKey.CURRENT_PLATFORM) || '') === 'swyftx-pro') {
              // A bit of a hack to ensure we hide the intercom launcher in pro
              (window as any).intercomSettings.hide_default_launcher = true;
              (window as any).Intercom('update', { hideDefaultLauncher: true });
            }
          },
        }),
        LDProvider,
        withProps(LocalizationProvider, { dateAdapter: AdapterLuxon, adapterLocale: DateTime.local().locale }),
        UsersnapProvider,
        BrowserRouter,
        NavigationContextProvider,
        UIContextProvider,
        ToastManagerContextProvider,
        TourGuideContextProvider,
        FeatureDiscoveryProvider,
        BrazeProvider,
        AvoProvider,
        LoginProvider,
        RewardsProvider,
        RegisterProvider,
        DashboardProvider,
        ProfileProvider,
        ProfileVerificationProvider,
      ]}
    >
      {children}
    </Compose>
  );

  return globalContext;
};

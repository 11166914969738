import React from 'react';

import { useSuggestedSearchModule } from './useSuggestedSearchModule';
import { GlobalSearchCategory } from '../../components/GlobalSearchCategory';

type Props = {
  searchValue: string;
};

const SuggestionsModuleSearchCategory: React.FC<Props> = ({ searchValue }) => {
  const suggestionItems = useSuggestedSearchModule();

  if (!suggestionItems.length || searchValue.length > 0) return null;

  return <GlobalSearchCategory title='Suggestions' items={suggestionItems} />;
};

export { SuggestionsModuleSearchCategory };

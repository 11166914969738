import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, Stack } from '@swyftx/react-web-design-system';

import { PhoneNumberInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';

import { CountriesEnum } from '@shared/enums';

import ct from 'countries-and-timezones';

type Props = {
  onClose: () => void;
  newPhoneNumber: string;
  setNewPhoneNumber: (newPhoneNumber: string) => void;
  onAction: () => void;
};

export const UpdatePhoneInputStep: React.FC<Props> = ({ onClose, newPhoneNumber, setNewPhoneNumber, onAction }) => {
  const { t } = useTranslation('common', { keyPrefix: 'input.notifications' });
  const [valid, setValid] = useState(false);
  const country: CountriesEnum = ct.getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0]?.id as
    | CountriesEnum
    | CountriesEnum.AU;

  return (
    <Wizard.Step onClose={onClose} title='Change phone number' locked={!newPhoneNumber || !valid} onAction={onAction}>
      <Stack spacing={2}>
        <Notification color='info' title='Important information'>
          {t('phoneEmailWithdrawLimit')}
        </Notification>
        <PhoneNumberInput
          value={newPhoneNumber}
          country={country}
          onChange={(e) => setNewPhoneNumber(e.target.value)}
          setValid={setValid}
          label='Phone number'
        />
      </Stack>
    </Wizard.Step>
  );
};

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Grid } from '@swyftx/react-web-design-system';

interface Props {
  id: string;
  headerChildren?: React.ReactNode;
  children?: React.ReactNode;
}

export const FullPageCard: React.FC<Props> = (props) => {
  const { id, children, headerChildren } = props;

  return (
    <FlexLayout direction='column' className='h-full overflow-auto bg-color-background-pageBG @container'>
      {/* TODO: change this to be Aviary*/}
      <Grid
        id={`${id}.content`}
        container
        justifyContent='center'
        sx={{ maxWidth: { md: '928px', lg: '996px', margin: '0 auto' } }}
        paddingX={{ xs: 2, md: 3, lg: 'auto' }}
        paddingTop={{ xs: 2, md: 3, lg: 6 }}
      >
        {headerChildren}

        <Grid item xs={12} md={8} paddingLeft={{ xs: 0, md: 6 }} paddingRight={{ xs: 0, md: 0 }}>
          <Card className='mb-32 flex'>
            <FlexLayout direction='column' className='p-16 @md:p-24 @lg:p-48'>
              {children}
            </FlexLayout>
          </Card>
        </Grid>
      </Grid>
    </FlexLayout>
  );
};

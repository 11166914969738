export enum IncidentType {
  PLANNED = 'planned',
  UNPLANNED = 'unplanned',
}

export interface MaintenanceStatus {
  body: string;
  updatedAt: string;
  status: string;
  type: IncidentType;
  scheduledForUtc?: string;
  scheduledUntilUtc?: string;
  scheduledUntil?: string;
}

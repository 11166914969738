import React from 'react';

import { DashboardTile } from '@shared/api';
import { DashboardTileEnum } from '@shared/enums';

import { ActivityTile } from './ActivityTile';
import { AffiliateTile } from './AffiliateTile';
import { AnnouncementsTile } from './AnnouncementsTile';
import { FavouritesTile } from './FavouritesTile';
import { MoversTile } from './MoversTile';
import { NewsFeedTile } from './NewsFeedTile';
import { PortfolioBreakdownTile } from './PortfolioBreakdownTile';
import { PortfolioPerformanceTile } from './PortfolioPerformanceTile';
import { QuickActionsTile } from './QuickActionsTile';
import { ReferAFriendTile } from './ReferAFriendTile';
import { VerificationTile } from './VerificationTile';

const getTile = (tile: DashboardTile) => {
  switch (tile.name) {
    case DashboardTileEnum.ANNOUNCEMENT:
      return <AnnouncementsTile tile={tile} />;
    case DashboardTileEnum.FAVOURITES:
      return <FavouritesTile tile={tile} />;
    case DashboardTileEnum.MOVERS:
      return <MoversTile tile={tile} />;
    case DashboardTileEnum.PORTFOLIO_BREAKDOWN:
      return <PortfolioBreakdownTile tile={tile} />;
    case DashboardTileEnum.PORTFOLIO_PERFORMANCE:
      return <PortfolioPerformanceTile tile={tile} />;
    case DashboardTileEnum.QUICK_ACTIONS:
      return <QuickActionsTile tile={tile} />;
    case DashboardTileEnum.AFFILIATE:
      return <AffiliateTile tile={tile} />;
    case DashboardTileEnum.ACTIVITY:
      return <ActivityTile tile={tile} />;
    case DashboardTileEnum.VERIFICATION:
      return <VerificationTile tile={tile} />;
    case DashboardTileEnum.NEWS_FEED:
      return <NewsFeedTile tile={tile} />;
    case DashboardTileEnum.REFER_A_FRIEND:
      return <ReferAFriendTile tile={tile} />;
    default:
      return null;
  }
};

export { getTile };

import { useEffect, useState } from 'react';

import { Asset, Network } from '@shared/api';
import { CryptoAddressMetadata, walletAddressService } from '@shared/services';

export const useCryptoAddressDetails = (asset: Asset, address: string) => {
  const [validCryptoAddress, setValidCryptoAddress] = useState<boolean>();
  const [cryptoNetworks, setCryptoNetworks] = useState<Array<Network>>([]);
  const [cryptoAddressMetadata, setCryptoAddressMetadata] = useState<CryptoAddressMetadata | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  useEffect(() => {
    if (!address) {
      setValidCryptoAddress(undefined);
      setCryptoNetworks([]);
      setCryptoAddressMetadata(null);
      setLoading(false);
      setError(true);
      return;
    }

    if (address.length) {
      (async () => {
        try {
          const cryptoNetworksRes = await walletAddressService.getCryptoNetworks(address, asset.code);
          const cryptoAddressMetadataRes = cryptoNetworksRes?.[0]
            ? walletAddressService.getFriendlyMetadataKeyName(cryptoNetworksRes[0])
            : undefined;
          setCryptoNetworks(cryptoNetworksRes);
          if (cryptoAddressMetadataRes) {
            setCryptoAddressMetadata(cryptoAddressMetadataRes);
          } else {
            setCryptoAddressMetadata(null);
          }
          const addressWithNoSpaces = address.replace(/\s/g, '');
          const validAddress = walletAddressService.isValidCryptoAddress(addressWithNoSpaces, asset.code);

          setValidCryptoAddress(
            validAddress && (cryptoAddressMetadataRes?.required ? cryptoAddressMetadataRes.required : true),
          );
          setLoading(false);
        } catch (e) {
          setValidCryptoAddress(false);
          setLoading(false);
          setError(true);
        }
      })();
    }
  }, [asset, address]);

  return {
    cryptoAddressMetadata,
    validCryptoAddress,
    cryptoNetworks,
    loading,
    error,
  };
};

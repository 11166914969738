import { Validator, ValidatorResponse } from '.';
import LengthValidator, { LengthRule } from './length.validator';
import StringValidator, { StringError, StringRule } from './string.validator';

export type PasswordError = {
  minCharacters: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  isEmpty: boolean;
  isMatch: boolean;
};

export type PasswordType = 'general' | 'create' | 'reenter';

class PasswordValidator extends Validator<string> {
  type: PasswordType;

  lengthValidator: LengthValidator;

  stringValidator: StringValidator;

  constructor(type: PasswordType, message: string, compareValue?: string) {
    super(message);
    this.type = type;
    this.lengthValidator = new LengthValidator(8, LengthRule.GreaterThanOrEqual);

    // Password should have an Uppercase lowercase number and special character
    this.stringValidator = new StringValidator(
      StringRule.HasUppercase,
      StringRule.hasLowerCase,
      StringRule.HasSpecialCharacter,
      StringRule.HasNumber,
      StringRule.IsEmpty,
      StringRule.Match,
    ).withCompareValue(compareValue);
  }

  isValid = (lengthValid: boolean, stringMetadata?: StringError): boolean => {
    const { isEmpty, hasUppercase, hasLowercase, hasNumber, hasSpecialCharacter, match } = stringMetadata || {};
    if (this.type === 'general') {
      return !isEmpty;
    }
    if (this.type === 'create') {
      return (lengthValid && !isEmpty && hasUppercase && hasLowercase && hasNumber && hasSpecialCharacter) || false;
    }

    if (this.type === 'reenter') {
      return match || false;
    }
    return false;
  };

  validate = (input: string): ValidatorResponse<PasswordError> => {
    const lengthValid = this.lengthValidator.validate(input);
    const stringValid = this.stringValidator.validate(input);
    const valid = this.isValid(lengthValid.valid, stringValid.metadata);

    return {
      message: valid ? undefined : this.message,
      valid,
      metadata: {
        minCharacters: lengthValid.valid,
        hasUppercase: stringValid?.metadata?.hasUppercase || false,
        hasLowercase: stringValid?.metadata?.hasLowercase || false,
        hasNumber: stringValid?.metadata?.hasNumber || false,
        hasSpecialCharacter: stringValid?.metadata?.hasSpecialCharacter || false,
        isEmpty: stringValid?.metadata?.isEmpty || false,
        isMatch: stringValid?.metadata?.match || false,
      },
    };
  };
}

export default PasswordValidator;

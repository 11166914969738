import React from 'react';

import { Links } from '@shared/constants';

import { CryptoTaxCalculatorLogo, KoinlyLogo } from 'src/assets';

export type TaxPartner = {
  logo: React.ReactNode;
  description: string;
  link: string;
  linkText: string;
  name: string;
  id: string;
  chipText?: string;
  countryCode: 'AUD' | 'NZD';
};

export const taxPartners: TaxPartner[] = [
  {
    id: 'tax-partner-koinly',
    name: 'koinly.name',
    logo: <KoinlyLogo id='koinly-logo' height={30} alt='koinly' />,
    link: Links.partners.koinly,
    linkText: 'koinly.visit',
    chipText: 'koinly.chipText',
    description: 'koinly.description',
    countryCode: 'AUD',
  },
  {
    id: 'tax-partner-cryptoTaxCalculator',
    name: 'cryptoTaxCalculator.name',
    logo: <CryptoTaxCalculatorLogo id='cryptotaxcalculator-logo' height={30} alt='cryptotaxcalculator' />,
    link: Links.partners.cryptoTax,
    linkText: 'cryptoTaxCalculator.visit',
    chipText: 'cryptoTaxCalculator.chipTextNew',
    description: 'cryptoTaxCalculator.descriptionNew',
    countryCode: 'AUD',
  },
  {
    id: 'tax-partner-koinly',
    name: 'koinlynz.name',
    logo: <KoinlyLogo id='koinly-logo' height={30} alt='koinly' />,
    link: Links.partners.koinlyNZ,
    linkText: 'koinlynz.visit',
    chipText: 'koinlynz.chipText',
    description: 'koinlynz.description',
    countryCode: 'NZD',
  },
  {
    id: 'tax-partner-cryptoTaxCalculator',
    name: 'cryptoTaxCalculatornz.name',
    logo: <CryptoTaxCalculatorLogo id='cryptotaxcalculator-logo' height={30} alt='cryptotaxcalculator' />,
    link: Links.partners.cryptoTaxNZ,
    linkText: 'cryptoTaxCalculatornz.visit',
    chipText: 'cryptoTaxCalculatornz.chipText',
    description: 'cryptoTaxCalculatornz.description',
    countryCode: 'NZD',
  },
];

import React from 'react';

import LoadingButtonMUI, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

type Props = {
  icon?: boolean;
} & LoadingButtonProps;

const StyledLoadingButton = styled(LoadingButtonMUI, {
  shouldForwardProp: (prop) => prop !== 'icon',
})<Props>`
  text-transform: none;
  ${(icon) => (icon ? 'min-width: auto;' : '')}

  .MuiButton-startIcon {
    ${(icon) => icon && 'margin-left: 0; margin-right: 0; min-width: auto !important;'}
  }
`;

const Button: React.FC<Props> = ({ id, children, icon = false, disableElevation, ...props }) => (
  <StyledLoadingButton id={id} icon={icon} disableElevation={disableElevation || true} {...props}>
    {children}
  </StyledLoadingButton>
);

export default Button;

import { useCallback } from 'react';

import { MouseEventParams, Time } from 'lightweight-charts';

type Props = {
  chartContainerRef: React.MutableRefObject<HTMLInputElement>;
};

const useSimpleChartTooltipUtils = ({ chartContainerRef }: Props) => {
  const isValidParam = useCallback(
    (param: MouseEventParams<Time>) =>
      !(
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > chartContainerRef.current.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainerRef.current.clientHeight
      ),
    [chartContainerRef],
  );

  return { isValidParam };
};

export { useSimpleChartTooltipUtils };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useGetDailyMovement } from '@hooks/Trade/useGetDailyMovement';

import { observer } from 'mobx-react-lite';

import { MarketTickerItem } from './MarketTickerItem';
import { MarketTickerSortingOptions } from '../consts';

interface Props {
  assets: Asset[];
  isBaseBTC: boolean;
  onClick: (asset: Asset) => void;
  sort: MarketTickerSortingOptions;
  sortingText?: string;
}

export const MovingMarketTicker: React.FC<Props> = observer((props) => {
  const { assets, isBaseBTC, onClick, sort, sortingText } = props;
  const { getDailyMovement } = useGetDailyMovement();

  if (!assets.length) {
    return (
      <FlexLayout direction='row' className='w-[100vw] items-center justify-center text-center'>
        <Body color='secondary' size='small' className='w-full'>
          No {sortingText || 'assets'} found
        </Body>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout direction='row' className='moving-row' key={`moving-market-ticker-${sort}`}>
      <div className='content flex shrink-0'>
        {assets.map((asset, index) => {
          const movement = Big(getDailyMovement(asset));
          return (
            <FlexLayout key={`${asset.id}-${index}`} className='my-6 items-center'>
              <MarketTickerItem asset={asset} movement={movement} isBaseBTC={isBaseBTC} onClick={onClick} />
              <Separator orientation='vertical' className='bg-color-border-main' />
            </FlexLayout>
          );
        })}
      </div>
    </FlexLayout>
  );
});

import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { Cross, Expand } from '@swyftx/aviary/icons/outlined';
import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';

import { PlatformFeature } from '../../types/FeatureDiscovery.types';

type Props = {
  activeFeature?: PlatformFeature;
  minimize?: boolean;
  setMinimized: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const FeatureCardMinimized: React.FC<Props> = ({ activeFeature, minimize, onClose, setMinimized }) => {
  const { stopTour } = useContext(TourGuideContext);
  const { t } = useTranslation('featureDiscovery');
  const { theme } = useTailwindTheme();

  const closeFeatureCard = () => {
    onClose();
    stopTour(true);
  };

  const onMaximize = () => {
    if (activeFeature?.onMaximize) activeFeature.onMaximize();
    setMinimized(false);
  };

  return (
    <Card
      className={`fixed bottom-[20px] left-1/2 z-modal h-[72px] -translate-x-1/2 overflow-hidden rounded-[16px] px-8 py-4 shadow-lg transition-all duration-300 @xs:max-w-[370px] @sm:min-w-[400px] ${
        !minimize ? 'translate-y-[calc(100%+65px)]' : ''
      }`}
    >
      <FlexLayout alignItems='center' justifyContent='space-between' spacing={12} className='h-full w-full'>
        <FlexLayout alignItems='center' justifyContent='start' spacing={12}>
          <div className='h-48 w-48 overflow-hidden rounded-12'>
            <img
              src={`/assets/images/features/${activeFeature?.imageKey}/${theme}/${activeFeature?.imageKey}_thumbnail.png`}
              alt={activeFeature?.title}
              className='h-[100%]'
            />
          </div>
          <FlexLayout direction='column'>
            <Heading size='h6'>{t('common.labels.whatsNew')}</Heading>
            <Body size='small' color='secondary' className='w-[220px] truncate'>
              {activeFeature?.title}
            </Body>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout alignItems='center' justifyContent='end' spacing={8}>
          <Button leadingIcon={<Expand />} layout='icon' variant='outlined' onClick={onMaximize}></Button>
          <Button leadingIcon={<Cross />} layout='icon' variant='outlined' onClick={closeFeatureCard}></Button>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export { FeatureCardMinimized };

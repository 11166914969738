import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { OutlinedAvatarIcon } from '@global-components/OutlinedAvatarIcon';
import { FormattedText } from '@global-components/Text';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { usePortfolio } from '@hooks/usePortfolio';

import {
  getPortfolioItemColor,
  getPortfolioItemIcon,
} from '@Wallet/components/WalletPortfolioAllocation/WalletPortfolioAllocation.utils';
import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

type Props = {
  walletType: WalletType;
  selectedAsset: Asset;
};

export const hasChange = (data: any): data is { change: number } => (data as { change: number }).change !== undefined;
const getPortfolioType = (walletType: WalletType) => {
  switch (walletType) {
    case WalletType.EARNING_WALLETS:
      return 'stakingBalance';
    case WalletType.TRADING_WALLETS:
      return 'availableBalance';
    case WalletType.ALL_WALLETS:
    default:
      return 'all';
  }
};

const WalletPortfolioAllocationAssetDetails: React.FC<Props> = observer(({ selectedAsset, walletType }) => {
  const baseAsset = useBaseAsset();
  const baseAssetIsFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);

  const { t } = useTranslation('wallet', { keyPrefix: 'allocation' });
  const { trading, staking, all } = useAssetBalance(selectedAsset);
  const icon = getPortfolioItemIcon(selectedAsset.id, walletType, selectedAsset.code);
  const color = getPortfolioItemColor(selectedAsset.id, selectedAsset.code);
  const { portfolio } = usePortfolio({ balanceKey: getPortfolioType(walletType) });

  const usedData = (() => {
    if (walletType === WalletType.TRADING_WALLETS) {
      return trading;
    }

    if (walletType === WalletType.EARNING_WALLETS) {
      return staking;
    }

    return all;
  })();

  const percent = useMemo(() => {
    const percentage = Big(usedData.value).div(portfolio.accountValue).times(100);
    const percentParsed = percentage.toFixed(2);

    if (percentage.lte(0)) return percentage.toFixed(0);
    if (percentParsed === '0.00') return percentage.toFixed(4);

    return percentParsed;
  }, [usedData, portfolio.accountValue]);

  return (
    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
      <div>
        <FlexLayout spacing={8} direction='column' className='h-full pb-32' alignItems='center' justifyContent='center'>
          <FormattedText
            typographyProps={{ weight: 'emphasis', className: 'text-16 @sm:text-18' }}
            value={usedData.balance}
            formatBalance
            currency={selectedAsset}
            formatOpts={{
              appendCode: true,
            }}
          />
          <OutlinedAvatarIcon color={color}>{icon}</OutlinedAvatarIcon>
          <FormattedText
            typographyProps={{ weight: 'emphasis', size: 'large' }}
            value={usedData?.value}
            currency={baseAsset}
            formatOpts={{ priceScale: baseAssetIsFiat ? 2 : baseAsset?.price_scale }}
          />
          <Chip size='md' color='secondary'>
            <Numeric size='small'>{`${percent}% ${t('labels.portfolio')}`}</Numeric>
          </Chip>
        </FlexLayout>
      </div>
    </div>
  );
});

WalletPortfolioAllocationAssetDetails.displayName = 'WalletPortfolioAllocationAssetDetails';

export { WalletPortfolioAllocationAssetDetails };

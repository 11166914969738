import { useEffect, useRef } from 'react';

import { UserStore } from '@shared/store';

export const useOnEntityChanged = (onChange: () => void) => {
  const entityName = UserStore.useUserStore.userProfile?.entityDetails?.entityName;
  const entityNameRef = useRef(entityName);

  useEffect(() => {
    if (entityName !== entityNameRef.current) {
      onChange();
      entityNameRef.current = entityName;
    }
  }, [entityName, onChange]);
};

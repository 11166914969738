import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Asset, CreateWithdrawalPayload } from '@shared/api';
import { ASSET_DEFAULTS } from '@shared/constants';
import { Big } from '@shared/safe-big';
import { WithdrawalAddress, WithdrawalReasonEnum, withdrawalService } from '@shared/services';
import { RatesStore, UIStore, UserStore } from '@shared/store';

import { useWithdraw } from '@hooks/Withdraw/useWithdraw';
import {
  useWithdrawalLimit,
  useWithdrawalLimitCache,
} from '@routes/Wallet/components/WithdrawalLimit/WithdrawalLimit.hooks';

export const useWithdrawFunds = (
  asset: Asset | null,
  withdrawAmount?: string,
  withdrawReason?: WithdrawalReasonEnum,
  withdrawAddress?: WithdrawalAddress,
) => {
  const { getBalance, userCountryCurrency } = UserStore.useUserStore;
  const { convertRate } = RatesStore.useRatesStore;
  const { addToastMessage } = UIStore.useUIStore;

  const { t } = useTranslation('common', { keyPrefix: 'withdrawalDetails' });
  const { t: walletT } = useTranslation('wallet');
  const { createWithdrawal } = useWithdraw();
  const { invalidateCache } = useWithdrawalLimitCache();

  const { data: countryWithdrawalLimit } = useWithdrawalLimit();
  const availableWithdrawalBalance = asset && getBalance(asset);

  const selectedReason = useMemo(
    () => withdrawalService.getSortedWithdrawalReasons().filter((reason) => reason.key === withdrawReason)[0],
    [withdrawReason],
  );

  const withdrawFunds = useCallback(
    async (auth?: string) => {
      if (!withdrawAddress || !asset || !selectedReason) {
        throw Error('Not all fields for processing withdrawal were populated.');
      }

      try {
        const data: CreateWithdrawalPayload = {
          quantity: Big(withdrawAmount).toFixed(
            withdrawalService.getMinWithdrawalIncrementE(withdrawAddress, asset) * -1,
          ),
          address_id: withdrawAddress.id,
          reason: selectedReason.key,
          reasonText: walletT(selectedReason.label as any),
          auth,
        };

        const response = await createWithdrawal(asset.code, data);

        if (!response.data.smsVerificationRequired) {
          addToastMessage({
            severity: 'success',
            message: t('toasts.success', { address: withdrawAddress.label }),
          });
        }

        return response.data;
      } catch (ex: any) {
        addToastMessage({
          severity: 'error',
          message: ex?.errorMessage || t('toasts.error'),
        });

        throw ex;
      } finally {
        invalidateCache();
      }
    },
    [
      addToastMessage,
      asset,
      createWithdrawal,
      invalidateCache,
      selectedReason,
      t,
      walletT,
      withdrawAddress,
      withdrawAmount,
    ],
  );

  const maxWithdrawal = useMemo(() => {
    if (!withdrawAddress || !countryWithdrawalLimit || !availableWithdrawalBalance) return availableWithdrawalBalance;

    const maxWithdrawal = Big(countryWithdrawalLimit?.remaining || 0); // country based
    const availableBalance = Big(availableWithdrawalBalance); // withdrawal asset based
    const conversion = convertRate(asset, userCountryCurrency, availableBalance || 0, 'midPrice'); // withdrawal asset to base

    let maxAmount = '0';

    if (conversion.lte(maxWithdrawal)) {
      maxAmount = withdrawalService.getWithdrawAmount(withdrawAddress, asset, Big(availableBalance || 0));
    } else {
      const maxWithdrawalConversion = convertRate(userCountryCurrency, asset, maxWithdrawal || 0, 'midPrice');
      maxAmount = withdrawalService.getWithdrawAmount(withdrawAddress, asset, Big(maxWithdrawalConversion || 0));
    }

    return maxAmount;
  }, [withdrawAddress, countryWithdrawalLimit, availableWithdrawalBalance, convertRate, asset, userCountryCurrency]);

  const minWithdrawal = useMemo(() => {
    if (!withdrawAddress || !asset) return asset?.min_withdrawal || ASSET_DEFAULTS.minWithdrawal;

    return withdrawalService.getMinWithdrawal(withdrawAddress, asset);
  }, [asset, withdrawAddress]);

  return { withdrawFunds, minWithdrawal, maxWithdrawal, countryWithdrawalLimit };
};

import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import { observer } from 'mobx-react-lite';
import { useDemoMode } from 'src/lib/utils/hooks';

const DemoModeSwitch: React.FC = observer(() => {
  const { setDemoMode, isDemo } = useDemoMode();

  const switchDemo = (checked: boolean) => {
    setDemoMode(checked);
  };

  return <Switch id='profile-settings-demo-mode-switch' size='md' checked={isDemo} onCheckedChange={switchDemo} />;
});

DemoModeSwitch.displayName = 'DemoModeSwitch';

export { DemoModeSwitch };

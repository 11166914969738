import React from 'react';
import { useTranslation } from 'react-i18next';

import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { BodyProps } from '@swyftx/aviary/atoms/Typography/Body/Body.styles';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Grid, Stack } from '@swyftx/react-web-design-system';

import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';
import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';

import { Big } from 'src/shared/safe-big';

type Props = {
  deposited: Big;
  withdrawn: Big;
  traded: Big;
  baseAsset?: Asset;
  orders?: number;
};

const titleTypographyProps: BodyProps = {
  color: 'secondary',
  className: 'text-center',
};

const valueTypographyProps: NumericProps = {
  size: 'large',
  className: 'text-center',
  weight: 'bold',
};

const XS_COLS = 6;
const MD_COLS = 3;

const PortfolioPerformanceDetails: React.FC<Props> = ({ deposited, orders, traded, withdrawn, baseAsset }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'portfolioTile.details' });

  return (
    <Grid container rowSpacing={1}>
      <LayoutGridItem xs={XS_COLS} md={MD_COLS}>
        <Stack>
          <Numeric {...valueTypographyProps}>{orders || 0}</Numeric>
          <Body {...titleTypographyProps}>{t('orderTitle')}</Body>
        </Stack>
      </LayoutGridItem>
      <LayoutGridItem xs={XS_COLS} md={MD_COLS}>
        <Stack>
          <FormattedText
            typographyProps={valueTypographyProps}
            value={deposited}
            currency={baseAsset}
            formatOpts={{ displayPriceScale: true }}
          />
          <Body {...titleTypographyProps}>{t('depositedTitle')}</Body>
        </Stack>
      </LayoutGridItem>
      <LayoutGridItem xs={XS_COLS} md={MD_COLS}>
        <Stack>
          <FormattedText
            typographyProps={valueTypographyProps}
            value={traded}
            currency={baseAsset}
            formatOpts={{ displayPriceScale: true }}
          />
          <Body {...titleTypographyProps}>{t('tradedTitle')}</Body>
        </Stack>
      </LayoutGridItem>
      <LayoutGridItem xs={XS_COLS} md={MD_COLS}>
        <Stack>
          <FormattedText
            typographyProps={valueTypographyProps}
            value={withdrawn}
            currency={baseAsset}
            formatOpts={{ displayPriceScale: true }}
          />
          <Body {...titleTypographyProps}>{t('withdrawnTitle')}</Body>
        </Stack>
      </LayoutGridItem>
    </Grid>
  );
};

PortfolioPerformanceDetails.displayName = 'PortfolioPerformanceLineChart';

export { PortfolioPerformanceDetails };

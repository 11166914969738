import { useCallback, useEffect, useState } from 'react';

import { Asset, RateSide, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore, UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

type Props = {
  valueAsset?: Asset;
  side: RateSide;
};

const usePortfolioValue = ({ valueAsset, side }: Props) => {
  const [portfolioValue, setPortfolioValue] = useState<Big>(Big(0));
  const { balances, tradePriceHistory } = UserStore.useUserStore;
  const countryAsset = useCountryAsset();

  const { convertRate } = RatesStore.useRatesStore;

  useEffect(() => {
    let value = Big(0);

    if (valueAsset) {
      Object.values(balances).forEach((balance: UserBalance) => {
        value = value.plus(convertRate(balance.assetId, valueAsset, balance.availableBalance, side));
      });
    }

    setPortfolioValue(value);
  }, [valueAsset, balances, side, convertRate]);

  const getAveragePricePaid = useCallback(
    (asset?: Asset) => {
      if (!asset) return Big(0);

      const tradeHistory = tradePriceHistory?.[asset.id];

      if (!tradeHistory || !countryAsset || !valueAsset) return Big(0);

      return Big(tradeHistory.avgPricePaid);
    },
    [countryAsset, tradePriceHistory, valueAsset],
  );

  const getAllocationPercentage = useCallback(
    (assetValue: Big) => {
      if (portfolioValue.eq(0)) return Big(0);
      return Big(assetValue).div(portfolioValue).times(100);
    },
    [portfolioValue],
  );

  const getProfitLossValue = useCallback(
    (asset?: Asset) => {
      if (!asset) return Big(0);

      const tradeHistory = tradePriceHistory?.[asset.id];
      const balance = balances[asset.id];

      if (!tradeHistory || !valueAsset) return Big(0);

      const totalCost = Big(tradeHistory.avgPricePaid).times(balance.availableBalance);
      const totalValue = convertRate(asset.id, valueAsset.id, balance.availableBalance, side);

      return totalValue.minus(totalCost);
    },
    [balances, convertRate, side, tradePriceHistory, valueAsset],
  );

  const getProfitLossPercentage = useCallback(
    (asset?: Asset) => {
      if (!asset) return Big(0);

      const profitLossValue = getProfitLossValue(asset);
      const tradeHistory = tradePriceHistory?.[asset.id];

      if (profitLossValue.eq(0) || !tradeHistory) return Big(0);

      const balance = balances[asset.id];

      const totalCost = Big(tradeHistory.avgPricePaid).times(balance.availableBalance);

      return profitLossValue.div(totalCost).times(100);
    },
    [balances, getProfitLossValue, tradePriceHistory],
  );

  const getPortfolioValue = useCallback(
    (asset?: Asset) => {
      if (!valueAsset || !asset) return Big(0);

      const balance = balances[asset.id];

      return convertRate(asset, valueAsset, balance.availableBalance, side);
    },
    [balances, convertRate, side, valueAsset],
  );

  return {
    portfolioValue,
    getPortfolioValue,
    getAllocationPercentage,
    getProfitLossValue,
    getProfitLossPercentage,
    getAveragePricePaid,
  };
};

export { usePortfolioValue };

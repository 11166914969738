import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Unlocked: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.8633 13.9531C12.8633 13.5389 12.5275 13.2031 12.1133 13.2031C11.6991 13.2031 11.3633 13.5389 11.3633 13.9531V16.1751C11.3633 16.5893 11.6991 16.9251 12.1133 16.9251C12.5275 16.9251 12.8633 16.5893 12.8633 16.1751V13.9531Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.80274 7.02775C6.81555 4.10095 9.19802 1.7392 12.1242 1.75004C14.4451 1.75068 16.4153 3.2425 17.1335 5.31665C17.269 5.70807 17.0615 6.13523 16.6701 6.27076C16.2787 6.40628 15.8515 6.19884 15.716 5.80742C15.2003 4.31802 13.7852 3.25004 12.1227 3.25004H12.1198C10.0212 3.24169 8.31277 4.93478 8.30273 7.03275V8.37598H15.933C18.4413 8.37598 20.475 10.4099 20.475 12.919V17.207C20.475 19.7161 18.4413 21.75 15.933 21.75H8.292C5.78366 21.75 3.75 19.7161 3.75 17.207V12.919C3.75 10.9317 5.02579 9.24245 6.80274 8.62583L6.80274 7.02775ZM7.70308 9.93296L7.69552 9.93446C6.30132 10.2118 5.25 11.4424 5.25 12.919V17.207C5.25 18.8879 6.61234 20.25 8.292 20.25H15.933C17.6127 20.25 18.975 18.8879 18.975 17.207V12.919C18.975 11.2381 17.6127 9.87598 15.933 9.87598H8.292C8.09053 9.87598 7.89362 9.89557 7.70308 9.93296Z'
      fill='currentColor'
    />
  </svg>
);

export { Unlocked };

import { useCallback, useContext, useMemo } from 'react';

import { RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { fromLocalToUTCMidnightOrMinInFuture } from '@shared/utils/lib/date';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { DateTime } from 'luxon';
import { AutoInvestContext } from 'src/lib/auto-invest/context';
import { useCreateAutoInvest } from 'src/lib/auto-invest/hooks';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { useAutoInvestBalanceCheck } from './useAutoInvestBalanceCheck';

const useAutoInvestReview = () => {
  const {
    startDate,
    selectedPaymentMethod,
    frequency,
    orderName,
    amount,
    selectedAutoInvestAssets,
    setCurrentStep,
    setAutoInvestOrder,
    onCancel,
  } = useContext(AutoInvestContext);
  const { createAutoInvestFromDeposit, createAutoInvestFromBalances } = useCreateAutoInvest();
  const { showBalanceWarning, diffDays, diffDayText } = useAutoInvestBalanceCheck({
    startDate,
    amount,
    selectedPaymentMethod,
    frequency,
  });

  const countryAsset = useCountryAsset();

  const { lowestFee } = useCurrentUserFees();

  const feeAmount = useMemo(() => {
    if (!amount) return Big(0);
    return Big(amount).times(lowestFee || 0);
  }, [amount, lowestFee]);

  const createAutoInvestOrderFromDeposit = useCallback(() => {
    if (!countryAsset || !orderName) return;

    createAutoInvestFromDeposit(
      orderName,
      selectedAutoInvestAssets,
      countryAsset.id,
      (response) => {
        setAutoInvestOrder(response);
        setCurrentStep('complete');
      },
      () => {
        setCurrentStep('failure');
      },
    );
  }, [
    countryAsset,
    createAutoInvestFromDeposit,
    orderName,
    selectedAutoInvestAssets,
    setAutoInvestOrder,
    setCurrentStep,
  ]);

  const createAutoInvestOrderFromWallet = useCallback(() => {
    if (!countryAsset || !frequency || !orderName || !startDate) return;

    let date = fromLocalToUTCMidnightOrMinInFuture(startDate);

    createAutoInvestFromBalances(
      orderName,
      selectedAutoInvestAssets,
      countryAsset.id,
      frequency,
      date,
      DateTime.now().offset,
      Number(amount),
      (response) => {
        setAutoInvestOrder(response);
        setCurrentStep('complete');
      },
      () => {
        setCurrentStep('failure');
      },
    );
  }, [
    amount,
    countryAsset,
    createAutoInvestFromBalances,
    frequency,
    orderName,
    selectedAutoInvestAssets,
    setAutoInvestOrder,
    setCurrentStep,
    startDate,
  ]);

  const createAutoInvestOrder = useCallback(() => {
    switch (selectedPaymentMethod) {
      case RecurringOrderSource.DEPOSIT:
        createAutoInvestOrderFromDeposit();
        break;
      case RecurringOrderSource.ACCOUNT:
        createAutoInvestOrderFromWallet();
        break;
      default:
        return;
    }
  }, [createAutoInvestOrderFromDeposit, createAutoInvestOrderFromWallet, selectedPaymentMethod]);

  return {
    startDate,
    selectedPaymentMethod,
    frequency,
    orderName,
    amount,
    feeAmount,
    lowestFeeAmount: `${lowestFee.times(100)}%`,
    selectedAutoInvestAssets,
    showBalanceWarning,
    diffDays,
    diffDayText,
    setCurrentStep,
    onCancel,
    createAutoInvestOrder,
  };
};

export { useAutoInvestReview };

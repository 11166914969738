import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TrackEverything: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M20.3439 16.5931V6.67813C20.3579 4.70448 18.8046 3.07549 16.8337 2.99609C16.8337 2.99609 8.70935 2.99609 8.68038 2.99703C6.69086 3.03999 5.11324 4.68859 5.15714 6.67813V16.765C5.20384 18.7228 6.80855 20.2835 8.76725 20.2761L16.8207 20.2751C18.8102 20.2312 20.3869 18.5826 20.3439 16.5931Z'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.65625 16.1453H6.59368M3.65625 11.6347H6.59368M3.65625 7.125H6.59368'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1797 16.538V12.2891'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0469 16.5376V12.5312'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6094 16.5369V14.7695'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.40625 16.5369V14.7695'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.19531 10.2773L11.7558 7.525L14.677 9.42191L17.1822 6.74609'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { TrackEverything };

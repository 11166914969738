const sensitiveKeys = [
  'password',
  'otp',
  'binding_code',
  'token',
  'oob_code',
  'oldPassword',
  'newPassword',
  'newPasswordAgain',
  'client_secret',
  'refresh_token',
  'apiKey',
  'accessToken',
  'mfa_token',
  'privateKey',
  'secret',
  'X-MBX-APIKEY',
  'authorization',
  'X-CMC_PRO_API_KEY',
];

function searchObjForKeys(haystack: any, needle: string[], inArray = Array.isArray(haystack)) {
  const sanitised = haystack;
  const keys = Object.keys(sanitised);
  let i = keys.length;

  while (i--) {
    if (sanitised[keys[i]] !== null && typeof sanitised[keys[i]] === 'object') {
      sanitised[keys[i]] = searchObjForKeys(sanitised[keys[i]], needle, Array.isArray(sanitised[keys[i]]));
    } else if (!inArray && needle.includes(keys[i])) {
      sanitised[keys[i]] = '[REDACTED]';
    }
  }
  return sanitised;
}

export function hideSensitive(obj: any, extra?: string[]) {
  let needle: string[] = [];
  const haystack = JSON.parse(JSON.stringify(obj)); // clone object
  if (extra) {
    needle = sensitiveKeys.concat(extra);
  } else {
    needle = sensitiveKeys;
  }

  return searchObjForKeys(haystack, needle);
}

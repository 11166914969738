import React, { useMemo, useState } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { useTradeValue } from 'src/lib/trade/hooks/General';

import { useAutoInvestAnalytics } from '../../hooks';

type Props = {
  value?: string;
  onChange: (value?: string) => void;
  min: Big;
  max?: Big;
};

const AutoInvestAmount: React.FC<Props> = observer(({ value, onChange, min, max }) => {
  const [tabValue, setTabValue] = useState<string>();
  const countryAsset = useCountryAsset();
  const { autoInvestQuickAmountSelected } = useAutoInvestAnalytics();

  const { onChangeValue, formattedAmount } = useTradeValue({
    limitAsset: countryAsset,
    amount: value,
    onChange,
  });

  const tabs = useMemo(
    () => [
      { title: '$50', value: '50', disabled: min.gt(50) },
      { title: '$100', value: '100' },
      { title: '$200', value: '200' },
      { title: '$300', value: '300' },
      { title: '$500', value: '500' },
    ],
    [min],
  );

  const error = useMemo(() => {
    if (!value) return '';
    const valueStripped = Big(value.replace(/,/g, ''));
    if (valueStripped.lt(min))
      return `Total amount must be greater than ${formatCurrency(min.toString(), countryAsset)}`;
    if (max && valueStripped.gt(max))
      return `Total amount must not be greater than ${formatCurrency(max.toString(), countryAsset)}`;
    return '';
  }, [value, min, countryAsset, max]);

  return (
    <FlexLayout direction='column' spacing={12}>
      <FlexLayout direction='column' className='w-full' spacing={4}>
        <Label htmlFor='auto-invest-order-amount'>Total amount to invest (per order)</Label>
        <Input
          error={!!error.length}
          id='auto-invest-order-amount'
          leading={
            <Numeric size='large' color='secondary'>
              $
            </Numeric>
          }
          placeholder='0'
          className='font-mono'
          trailing={<Numeric color='secondary'>{countryAsset?.code}</Numeric>}
          value={formattedAmount}
          onChange={(e) => {
            onChangeValue(e.target.value);
            setTabValue('');
          }}
          onBlur={() => {
            autoInvestQuickAmountSelected(Number(value));
          }}
        />
        <Body size='small' color='error'>
          {error}
        </Body>
      </FlexLayout>

      <EnhancedTabs
        tabs={tabs}
        variant='child'
        onChange={(val: string) => {
          setTabValue(val);
          onChange(val);
          autoInvestQuickAmountSelected(Big(val).toNumber(), val);
        }}
        value={tabValue}
      />
    </FlexLayout>
  );
});

export { AutoInvestAmount };

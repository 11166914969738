import { useContext, useMemo } from 'react';

import { CreateOrderParams, OrderType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useQuickBuyAmounts } from './useQuickBuyAmounts';
import { useQuickBuyAssets } from './useQuickBuyAssets';
import { useQuickBuyPaymentMethods } from './useQuickBuyPaymentMethods';
import { QuickBuyContext } from '../../context';
import { useTradeQuote } from '../General';

const useQuickBuyReview = () => {
  const { buyType, selectedAssetCode, countryAssetCode, limitAssetCode } = useContext(QuickBuyContext);
  const { limitAsset, selectedAsset, countryAsset, nonLimitAsset } = useQuickBuyAssets();
  const { amount } = useQuickBuyAmounts();
  const { getPaymentOption, selectedPaymentMethod } = useQuickBuyPaymentMethods();

  const isBuy = useMemo(() => buyType === 'buy', [buyType]);

  const isApproximate = isBuy === (limitAsset?.id === countryAsset?.id);
  const [isTotalLimitApproximate, isAmountLimitApproximate] = isApproximate ? [false, true] : [true, false];

  const { data } = useTradeQuote({
    buy: isBuy ? selectedAssetCode : countryAssetCode,
    sell: isBuy ? countryAssetCode : selectedAssetCode,
    amount: Big(amount).toNumber(),
    limit: limitAssetCode,
  });

  const paymentMethod = useMemo(
    () => getPaymentOption(selectedPaymentMethod),
    [getPaymentOption, selectedPaymentMethod],
  );

  const order: CreateOrderParams = useMemo(
    () => ({
      assetQuantity: limitAssetCode,
      orderType: buyType === 'buy' ? OrderType.MarketBuy : OrderType.MarketSell,
      primary: countryAssetCode,
      quantity: amount || '',
      secondary: selectedAssetCode,
    }),
    [amount, buyType, countryAssetCode, limitAssetCode, selectedAssetCode],
  );

  const quoteAmount = useMemo(() => {
    const asset = isBuy ? selectedAsset : countryAsset;
    return `${formatCurrency(data?.amount, asset, {
      isApproximate: isAmountLimitApproximate,
    })} ${asset?.code}`;
  }, [isBuy, selectedAsset, countryAsset, data?.amount, isAmountLimitApproximate]);

  const quoteTotal = useMemo(() => {
    const asset = isBuy ? countryAsset : selectedAsset;
    return `${formatCurrency(data?.total, asset, {
      isApproximate: isTotalLimitApproximate,
    })} ${asset?.code}`;
  }, [isBuy, countryAsset, selectedAsset, data?.total, isTotalLimitApproximate]);

  const currentPrice = useMemo(
    () => `${formatCurrency(isBuy ? data?.price : Big(1).div(data?.price || 1), countryAsset)} ${countryAssetCode}`,
    [countryAsset, countryAssetCode, data?.price, isBuy],
  );

  const fee = useMemo(() => {
    if (!data) return '0';

    const { total, amount: dataAmount, feePerUnit } = data;
    const limitingOnCountry = limitAsset?.code === countryAsset?.code;
    const value = (isBuy && !limitingOnCountry) || (!isBuy && limitingOnCountry) ? total : dataAmount;

    return formatCurrency(Big(value).times(feePerUnit || 0), nonLimitAsset, {
      isApproximate: true,
      hideCode: false,
      appendCode: true,
    });
  }, [countryAsset?.code, data, isBuy, limitAsset?.code, nonLimitAsset]);

  return {
    type: isBuy ? 'Buy' : 'Sell',
    paymentMethod,
    currentPrice,
    fee,
    feePercentage: data?.feePerUnit,
    amount: quoteAmount,
    total: quoteTotal,
    order,
    isBuy,
  };
};

export { useQuickBuyReview };

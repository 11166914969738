import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';

import { CategoryIcons } from 'src/context/Categories/Categories.icons';
import { useCategory } from 'src/lib/categories/hooks';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  asset: Asset;
};

const AssetCategories: React.FC<Props> = ({ asset }) => {
  const { getAssetCategories } = useCategory();
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });

  const categories = useMemo(() => getAssetCategories(asset), [asset, getAssetCategories]);

  const openCategory = useCallback(
    (category?: Category, e?: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: category?.id } });
    },
    [navigate],
  );

  return (
    <FlexLayout direction='row' spacing={0} alignItems='center' justifyContent='end' className='w-full'>
      {!categories ||
        (!categories.length && (
          <Body size='xsmall' className='w-[160px] text-right' overflow='truncate' color='secondary' title={asset.name}>
            {t('table.labels.uncategorised')}
          </Body>
        ))}
      {categories && categories.length === 1 && (
        <Body
          size='xsmall'
          weight='emphasis'
          color='accent'
          className='w-[160px] text-right'
          overflow='truncate'
          title={asset.name}
          onClick={(e) => openCategory(categories?.[0], e)}
        >
          {categories?.[0].name}
        </Body>
      )}

      {categories && categories.length > 1 && (
        <Menu
          hideArrow
          triggerContent={
            <FlexLayout direction='row' spacing={8} alignItems='center' className='w-full' justifyContent='end'>
              <Tooltip title='Show categories' align='center' side='bottom' sideOffset={8}>
                <Body size='xsmall' weight='emphasis' color='accent' className='text-right' overflow='truncate'>
                  {categories?.length} categories
                </Body>
              </Tooltip>
            </FlexLayout>
          }
        >
          <FlexLayout direction='column' className='max-h-[200px] overflow-y-auto'>
            {categories.map((category: Category) => (
              <MenuItem
                key={category.id}
                icon={CategoryIcons()[category.name] || null}
                text={category.name}
                action={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  openCategory(category, e as unknown as React.MouseEvent<HTMLParagraphElement, MouseEvent>);
                }}
              />
            ))}
          </FlexLayout>
        </Menu>
      )}
    </FlexLayout>
  );
};

export { AssetCategories };

import React from 'react';

import BadgeMUI, { BadgeProps } from '@mui/material/Badge';

const Badge: React.FC<BadgeProps> = ({ id, children, ...props }) => (
  <BadgeMUI id={id} {...props}>
    {children}
  </BadgeMUI>
);

export default Badge;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useMemo } from 'react';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { QuickBuyContext } from '../../context';

const useQuickBuyAssets = () => {
  const { getAssetByCode } = useMarkets();
  const { selectedAssetCode, limitAssetCode, countryAssetCode, setSelectedAssetCode, setLimitAssetCode } =
    useContext(QuickBuyContext);

  const nonLimitAssetCode = useMemo(
    () => (limitAssetCode === selectedAssetCode ? countryAssetCode || 'AUD' : selectedAssetCode),
    [limitAssetCode, selectedAssetCode, countryAssetCode],
  );

  const selectedAsset = useMemo(() => getAssetByCode(selectedAssetCode), [selectedAssetCode, getAssetByCode]);
  const limitAsset = useMemo(() => getAssetByCode(limitAssetCode), [limitAssetCode, getAssetByCode]);
  const nonLimitAsset = useMemo(() => getAssetByCode(nonLimitAssetCode), [nonLimitAssetCode, getAssetByCode]);
  const countryAsset = useMemo(() => getAssetByCode(countryAssetCode), [countryAssetCode, getAssetByCode]);

  return {
    countryAsset: countryAsset,
    selectedAsset: selectedAsset,
    limitAsset: limitAsset,
    nonLimitAsset: nonLimitAsset,
    setSelectedAssetCode,
    setLimitAssetCode,
  };
};

export { useQuickBuyAssets };

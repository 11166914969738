import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Error: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.7051 8.99512C12.7051 8.5809 12.3693 8.24512 11.9551 8.24512C11.5409 8.24512 11.2051 8.5809 11.2051 8.99512V12.0951C11.2051 12.5093 11.5409 12.8451 11.9551 12.8451C12.3693 12.8451 12.7051 12.5093 12.7051 12.0951V8.99512Z'
      fill='currentColor'
    />
    <path
      d='M12.7148 14.9951C12.7148 15.4093 12.3791 15.7451 11.9648 15.7451C11.5506 15.7451 11.2148 15.4093 11.2148 14.9951C11.2148 14.5809 11.5506 14.2451 11.9648 14.2451C12.3791 14.2451 12.7148 14.5809 12.7148 14.9951Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.0918 2.49707C12.4694 1.87467 11.4603 1.87467 10.8379 2.49707L2.46679 10.8684C1.8444 11.4908 1.8444 12.4999 2.46679 13.1223L10.8379 21.4937C11.4603 22.1161 12.4694 22.1161 13.0918 21.4937L21.4629 13.1223C22.0853 12.4999 22.0853 11.4908 21.4629 10.8684L13.0918 2.49707ZM20.4023 11.9291L12.0311 3.55775C11.9945 3.52114 11.9352 3.52114 11.8986 3.55775L3.52744 11.9291C3.49083 11.9657 3.49083 12.0251 3.52744 12.0617L11.8986 20.433C11.9352 20.4696 11.9945 20.4696 12.0311 20.433L20.4023 12.0617C20.4389 12.0251 20.4389 11.9657 20.4023 11.9291Z'
      fill='currentColor'
    />
  </svg>
);

export { Error };

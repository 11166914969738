import React from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItem, Stack, Typography } from '@swyftx/react-web-design-system';

import { ApiKeyTypeCard } from './components/ApiKeyTypeCard';
import { useApiKeyTypes } from './useApiKeyTypes.hooks';

const TILE_PADDING = 15;

const ApiKeyType: React.FC = () => {
  const { ApiKeyTypeData } = useApiKeyTypes();
  const { t } = useTranslation('profile.apiKey');

  return (
    <Stack direction='column' spacing={1}>
      <Typography fontSize={14} fontWeight='500'>
        {t('sections.createApiKey.config.configType.title')}
      </Typography>

      <List
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          height: '100%',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {ApiKeyTypeData.map((type) => (
          <ListItem
            key={`api-key-item-${type.id}`}
            disablePadding
            sx={{
              flexBasis: {
                xs: '100%',
                md: `calc(50% - ${TILE_PADDING / 2}px)`,
                marginBottom: TILE_PADDING,
              },
            }}
          >
            <ApiKeyTypeCard item={type} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export { ApiKeyType };

import React, { PropsWithChildren } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { WhatIsSwyftxPortfolioTransfer } from '@routes/PortfolioTransfer/components/WhatIsSwyftxPortfolioTransfer';

import { observer } from 'mobx-react-lite';

const PortfolioTransferWrapper: React.FC<PropsWithChildren<unknown>> = observer(({ children }) => {
  const { atLeast } = useTailwindContainerBreakpoint({
    containerName: 'portfolio-transfer-page',
  });

  const { isRestricted, canTransferCrypto, isEntity } = UserStore.useUserStore;

  const canCompletePortfolioTransfer = isEntity() || (!isRestricted() && canTransferCrypto());

  return (
    <Page
      className={cn('@container')}
      customHeader={
        <FlexLayout
          justifyContent='space-between'
          className='max-h-[15rem] flex-col gap-16 @sm:flex-row'
          data-container='portfolio-transfer-page'
        >
          <FlexLayout direction='column' className='order-2 gap-8 @sm:order-1 @sm:w-[50%] @sm:gap-16'>
            <FlexLayout className='inline flex-wrap'>
              <Heading className='inline leading-normal' size={atLeast.lg ? 'h2' : 'h3'}>
                Portfolio Transfer
              </Heading>
            </FlexLayout>

            <Body color='secondary'>
              Portfolio Transfers allows you to easily move assets from one exchange to Swyftx through the use of API
              Keys.
            </Body>
            <div className='hidden sm:block'>
              <BodyLink
                href='https://support.swyftx.com/hc/en-au/articles/18756565962649'
                target='_blank'
                rel='noopener noreferrer'
                className='decoration text-color-text-accent underline'
              >
                Find out more
              </BodyLink>
            </div>
          </FlexLayout>
          <div className='order-1 @sm:order-2'>
            <Image image='portfolio_transfer' className='h-[128px]' alt='portfolio transfer' usePalette={false} />
          </div>
        </FlexLayout>
      }
    >
      <PageContent offset className='h-full'>
        <FlexLayout direction='row' className='h-full w-full flex-col gap-24 @sm:flex-row @sm:gap-8'>
          <FlexLayout direction='column' className='h-full w-full @sm:w-1/2 @md:w-1/3'>
            <WhatIsSwyftxPortfolioTransfer />
          </FlexLayout>
          <FlexLayout direction='column' className='h-full w-full @sm:w-1/2 @md:w-2/3'>
            <Card className='!h-full p-16'>
              <>
                {!canCompletePortfolioTransfer && <></>}
                {!!canCompletePortfolioTransfer && children}
              </>
            </Card>
          </FlexLayout>
        </FlexLayout>
      </PageContent>
    </Page>
  );
});

export { PortfolioTransferWrapper };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const CrossInCircleFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.0098 14.9991C15.3498 14.6601 15.3498 14.1101 15.0098 13.7701L13.2298 11.9901L15.0098 10.2091C15.3498 9.87015 15.3498 9.31015 15.0098 8.97015C14.6698 8.62915 14.1198 8.62915 13.7698 8.97015L11.9998 10.7491L10.2198 8.97015C9.86982 8.62915 9.31982 8.62915 8.97982 8.97015C8.63982 9.31015 8.63982 9.87015 8.97982 10.2091L10.7598 11.9901L8.97982 13.7601C8.63982 14.1101 8.63982 14.6601 8.97982 14.9991C9.14982 15.1691 9.37982 15.2601 9.59982 15.2601C9.82982 15.2601 10.0498 15.1691 10.2198 14.9991L11.9998 13.2301L13.7798 14.9991C13.9498 15.1801 14.1698 15.2601 14.3898 15.2601C14.6198 15.2601 14.8398 15.1691 15.0098 14.9991Z'
      fill='currentColor'
    />
  </svg>
);

export { CrossInCircleFilled };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const TrendLineDown: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M3.40198 2.37938C3.19735 2.01924 2.73952 1.89317 2.37938 2.09779C2.01924 2.30242 1.89317 2.76025 2.09779 3.12039L7.47248 12.5798C7.58036 12.7697 7.76565 12.9031 7.97993 12.9453C8.19422 12.9874 8.41622 12.934 8.58794 12.7991L13.8577 8.65858L18.8147 19.5259L17.5692 19.0703C17.1801 18.928 16.7494 19.128 16.6071 19.517C16.4648 19.906 16.6648 20.3367 17.0538 20.479L19.8686 21.5086C20.2576 21.6509 20.6883 21.4509 20.8306 21.0619L21.9539 17.9912C22.0962 17.6022 21.8962 17.1715 21.5072 17.0292C21.1182 16.8869 20.6875 17.0869 20.5452 17.4759L20.0926 18.7131L14.8266 7.16836C14.7311 6.95907 14.5449 6.80516 14.3214 6.75083C14.0978 6.6965 13.8617 6.74775 13.6809 6.88987L8.34627 11.0813L3.40198 2.37938Z'
      fill='currentColor'
    />
  </svg>
);

export { TrendLineDown };

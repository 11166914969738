import React, { useCallback, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown, ArrowChevronUp, Delete, Edit } from '@swyftx/aviary/icons/outlined';

import { Corporate, SmsfCorporateTrustee } from '@shared/api';

import { RemoveMemberModal } from '@routes/EntityOnboarding/components/modals/RemoveMemberModal';
import { SmsfCorporateTrusteeWithUuid } from '@routes/EntityOnboarding/hooks/useSmsfTrusteeStep';

import { AddCorporateShareholder } from './AddCorporateShareholder';
import { AddCorporateTrustee } from './AddCorporateTrustee';
import { ViewCorporateDetails } from './ViewCorporateDetails';

type Props =
  | {
      shareholder: CorporateWithUuid;
      onRemove: () => void;
      onEditCorporateMember: (member: CorporateWithUuid) => void;
    }
  | {
      shareholder: CorporateSmsfTrusteeWithUuid;
      onRemove: () => void;
      onEditCorporateTrustee: (member: CorporateSmsfTrusteeWithUuid) => void;
    };

export type CorporateWithUuid = { uuid: string; corporate: Corporate };
export type CorporateSmsfTrusteeWithUuid = { uuid: string; corporate: SmsfCorporateTrustee };

export const CorporateCard: React.FC<Props> = (props) => {
  const { shareholder, onRemove } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const onConfirmRemove = useCallback(() => {
    onRemove();
  }, [onRemove]);

  const handleOpenToggle = useCallback(() => {
    if (open) {
      return setOpen(false);
    }
    setOpen(true);
    setEdit(false);
  }, [open]);

  const handleOpenEdit = useCallback(() => {
    setEdit(true);
    setOpen(false);
  }, []);

  return (
    <>
      <Card className='p-16'>
        <FlexLayout direction='column' spacing={16}>
          <FlexLayout direction='row' className='w-full items-center justify-between'>
            <Body weight='emphasis'>{shareholder.corporate.name}</Body>
            <FlexLayout direction='row' spacing={8}>
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={<Edit />}
                onClick={handleOpenEdit}
                tooltip='Edit member details'
                disabled={edit}
              />
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={<Delete />}
                onClick={() => setConfirmRemoveOpen(true)}
              />
              <Button
                variant='outlined'
                layout='icon'
                leadingIcon={open ? <ArrowChevronUp /> : <ArrowChevronDown />}
                onClick={handleOpenToggle}
                tooltip='View member details'
                disabled={edit}
              />
            </FlexLayout>
          </FlexLayout>
          {open && <ViewCorporateDetails corporate={shareholder.corporate} />}
          {edit && (
            <>
              {'onEditCorporateTrustee' in props && (
                <AddCorporateTrustee
                  onCancel={() => setEdit(false)}
                  onSubmit={(trustee: SmsfCorporateTrustee) => {
                    setEdit(false);
                    return props.onEditCorporateTrustee({ uuid: shareholder.uuid, corporate: trustee });
                  }}
                  trustee={shareholder as SmsfCorporateTrusteeWithUuid}
                />
              )}
              {'onEditCorporateMember' in props && (
                <AddCorporateShareholder
                  onCancel={() => setEdit(false)}
                  onSubmit={(corporate: Corporate) => {
                    setEdit(false);
                    return props.onEditCorporateMember({ uuid: shareholder.uuid, corporate });
                  }}
                  corporate={shareholder as CorporateWithUuid}
                />
              )}
            </>
          )}
        </FlexLayout>
      </Card>
      <RemoveMemberModal
        name={shareholder.corporate.name}
        open={confirmRemoveOpen}
        setOpen={setConfirmRemoveOpen}
        onConfirm={onConfirmRemove}
      />
    </>
  );
};

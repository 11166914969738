import { Dispatch, MutableRefObject, RefObject, SetStateAction, useCallback, useEffect, useRef } from 'react';

import { isReactTouchEvent, isTouchEvent } from '@utils/guards';
import { isNativeEvent, NativeEvent, ReactEvent } from '@utils/guards/events';

import { ITEM_SLIDER_ALL_WIDTH } from './ItemSlider.data';

type Options = {
  onMouseUp?: () => void;
};

const getXFromEvent = (event: ReactEvent | NativeEvent) => {
  if (isNativeEvent(event)) {
    if (isTouchEvent(event)) {
      return event.changedTouches[0].clientX;
    }
    return event.clientX;
  }
  if (isReactTouchEvent(event)) {
    return event.changedTouches[0].clientX;
  }
  return event.clientX;
};

export const useDragScroll = (
  ref: RefObject<HTMLElement>,
  isDragging: MutableRefObject<boolean>,
  stationaryIndexCount: number,
  newIndexThreshold: number,
  setSelectedIndex: Dispatch<SetStateAction<number>>,
  assetCount: number,
  options?: Options,
) => {
  let initial = { x: 0 };
  const previousDx = useRef<number>(0);
  const draggedOffset = useRef<number>(0);
  const currentIndex = useRef<number>(-1);

  const moveHandler = useCallback(
    (event: { clientX: number }) => {
      if (ref.current) {
        const dx = event.clientX - initial.x;
        const isScrollingFromAll = currentIndex.current === -1;
        const dxChange = dx - previousDx.current;

        if (dxChange && previousDx.current !== 0) {
          isDragging.current = true;
        }

        previousDx.current = dx;
        draggedOffset.current += dxChange;

        // Need to increase the threshold if we're scrolling from ALL, which is wider
        const usedIndexThreshold = isScrollingFromAll
          ? newIndexThreshold + (newIndexThreshold - ITEM_SLIDER_ALL_WIDTH)
          : newIndexThreshold;

        // Update the index
        const indexesJumped = Math.floor(Math.abs(draggedOffset.current) / Math.abs(usedIndexThreshold));

        // Backward
        if (draggedOffset.current > usedIndexThreshold) {
          draggedOffset.current -= usedIndexThreshold * indexesJumped;
          setSelectedIndex((index) => {
            const newIndex = index - indexesJumped;
            if (newIndex >= -1) {
              currentIndex.current = newIndex;
              return newIndex;
            }
            draggedOffset.current = 0;
            currentIndex.current = -1;
            return -1;
          });
        }

        // Forward
        if (draggedOffset.current < -usedIndexThreshold) {
          draggedOffset.current += usedIndexThreshold * indexesJumped;
          setSelectedIndex((index) => {
            const newIndex = index + indexesJumped;
            if (newIndex < assetCount) {
              currentIndex.current = newIndex;
              return newIndex;
            }
            draggedOffset.current = 0;
            currentIndex.current = assetCount - 1;
            return assetCount - 1;
          });
        }

        const enableScroll =
          currentIndex.current >= stationaryIndexCount - 1 &&
          currentIndex.current <= assetCount - 1 - stationaryIndexCount;

        if (enableScroll) {
          ref.current.scrollLeft -= dxChange;
        }
      }
    },
    [ref, initial, stationaryIndexCount, currentIndex.current],
  );

  const moveEndHandler = useCallback(() => {
    if (ref.current) ref.current.style.cursor = 'grab';
    draggedOffset.current = 0;
    previousDx.current = 0;
    if (options?.onMouseUp) {
      options.onMouseUp();
    }

    document.removeEventListener('mousemove', moveHandler, true);
    document.removeEventListener('mouseup', moveEndHandler, true);
  }, [ref, options?.onMouseUp, moveHandler]);

  const onMouseDown = useCallback(
    (event: ReactEvent) => {
      if (ref.current) {
        initial = { x: getXFromEvent(event) };
        ref.current.style.cursor = 'grabbing';
        ref.current.style.userSelect = 'none';
        document.addEventListener('mousemove', moveHandler, true);
        document.addEventListener('mouseup', moveEndHandler, true);
      }
    },
    [ref, moveHandler, moveEndHandler],
  );

  // Map touch events to mouse events and simulate them cause having both events is kind of inconsistent and buggy
  useEffect(() => {
    const touchHandler = (event: TouchEvent) => {
      const touchStart = event.changedTouches[0];
      const type = (() => {
        if (event.type === 'touchstart') {
          return 'mousedown';
        }
        if (event.type === 'touchmove') {
          return 'mousemove';
        }
        if (event.type === 'touchend' || event.type === 'touchcancel') {
          return 'mouseup';
        }

        return null;
      })();

      if (type) {
        const simulatedEvent = new MouseEvent(type, {
          cancelable: true,
          view: window,
          screenX: touchStart.screenX,
          screenY: touchStart.screenY,
          clientX: touchStart.clientX,
          clientY: touchStart.clientY,
          relatedTarget: null,
        });
        document.dispatchEvent(simulatedEvent);
      }
    };

    document.addEventListener('touchstart', touchHandler, true);
    document.addEventListener('touchmove', touchHandler, true);
    document.addEventListener('touchend', touchHandler, true);
    document.addEventListener('touchcancel', touchHandler, true);

    return () => {
      document.removeEventListener('touchstart', touchHandler, true);
      document.removeEventListener('touchmove', touchHandler, true);
      document.removeEventListener('touchend', touchHandler, true);
      document.removeEventListener('touchcancel', touchHandler, true);
    };
  }, []);

  return { onMouseDown };
};

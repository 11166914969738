import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Document, ShieldPass, ThreeUser } from '@swyftx/aviary/icons/outlined';

import Wizard from '@global-components/Wizard';
import { StepProps } from '@global-components/Wizard/Wizard';

import { EntityModalTile } from '../EntityModalTile';

const CreateEntityCreateStepsStep: React.FC<StepProps> = (props) => {
  const { t } = useTranslation('entities');

  return (
    <Wizard.Step {...props}>
      <FlexLayout direction='column' alignItems='start' spacing={16} className='pb-24'>
        <Body weight='bold' size='large'>
          {t('createEntities.setUp.steps.title')}
        </Body>
        <EntityModalTile
          step={t('createEntities.setUp.steps.step1.title')}
          subTitle={t('createEntities.setUp.steps.step1.subTitle')}
        />
        <EntityModalTile
          step={t('createEntities.setUp.steps.step2.title')}
          subTitle={t('createEntities.setUp.steps.step2.subTitle')}
        />
        <EntityModalTile
          step={t('createEntities.setUp.steps.step3.title')}
          subTitle={t('createEntities.setUp.steps.step3.subTitle')}
        />

        <Body weight='bold' size='large'>
          {t('createEntities.setUp.title')}
        </Body>
        <EntityModalTile
          leading={<Document className='h-20 w-20 text-color-text-primary' />}
          title={t('createEntities.setUp.info1.title')}
          subTitle={t('createEntities.setUp.info1.subTitle')}
        />
        <EntityModalTile
          leading={<ThreeUser className='h-20 w-20' />}
          title={t('createEntities.setUp.info2.title')}
          subTitle={t('createEntities.setUp.info2.subTitle')}
        />
        <EntityModalTile
          leading={<ShieldPass className='h-20 w-20' />}
          title={t('createEntities.setUp.info3.title')}
          subTitle={t('createEntities.setUp.info3.subTitle')}
        />
      </FlexLayout>
    </Wizard.Step>
  );
};

export { CreateEntityCreateStepsStep };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Category, Password, Show, Trade } from '@swyftx/aviary/icons/outlined';

import { CreateAPIKeyType } from '@shared/api/lib/helpers/apiKeys.helper';

export type ApiKeyTypeItem = {
  id: string;
  icon: React.ReactNode;
  title: string;
  subTitle: string;
  actionElement?: React.ReactNode;
  type: CreateAPIKeyType;
};

export const useApiKeyTypes = () => {
  const { t } = useTranslation('profile.apiKey');

  const ApiKeyTypeData: ApiKeyTypeItem[] = useMemo(
    () => [
      {
        id: 'api-key-type-read-only',
        icon: <Show id='api-key-read-icon' className='h-full w-full' />,
        title: t('sections.createApiKey.config.configType.read.title'),
        subTitle: t('sections.createApiKey.config.configType.read.subTitle'),
        type: CreateAPIKeyType.ReadOnly,
      },
      {
        id: 'api-key-type-trading-only',
        icon: <Trade id='api-key-trade-icon' className='h-full w-full' />,
        title: t('sections.createApiKey.config.configType.trade.title'),
        subTitle: t('sections.createApiKey.config.configType.trade.subTitle'),
        type: CreateAPIKeyType.TradeOnly,
      },
      {
        id: 'api-key-type-admin',
        icon: <Password id='api-key-admin-icon' className='h-full w-full' />,
        title: t('sections.createApiKey.config.configType.admin.title'),
        subTitle: t('sections.createApiKey.config.configType.trade.subTitle'),
        type: CreateAPIKeyType.Admin,
      },
      {
        id: 'api-key-type-custom',
        icon: <Category id='api-key-custom-icon' className='h-full w-full' />,
        title: t('sections.createApiKey.config.configType.custom.title'),
        subTitle: t('sections.createApiKey.config.configType.trade.subTitle'),
        type: CreateAPIKeyType.Custom,
      },
    ],
    [t],
  );

  return {
    ApiKeyTypeData,
  };
};

import React, { useMemo } from 'react';

import { Loading } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import {
  ButtonProps,
  buttonIconVariants,
  buttonVariants,
  getFontSize,
  getCustomBodyClasses,
  buttonLoadingVariants,
} from './Button.styles';
import { FlexLayout } from '../Layout/Flex';
import { Tooltip } from '../Tooltip/Tooltip';
import { Body } from '../Typography/Body';

const Button = React.forwardRef<HTMLButtonElement, Omit<ButtonProps, 'style'>>(
  (
    {
      className,
      leadingIcon,
      trailingIcon,
      size = 'md',
      variant = 'filled',
      disabled,
      bodyProps,
      layout = 'default',
      color = 'primary',
      textAlign = 'center',
      textJustify = 'center',
      tooltip = '',
      focusStyle,
      children,
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const fontSize = useMemo(() => getFontSize(size), [size]);
    const customBodyClasses = useMemo(() => getCustomBodyClasses(size), [size]);

    const content = useMemo(() => {
      if (!children) return null;
      if (layout === 'icon' && typeof children === 'string') return null;

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Body
          size={fontSize}
          {...bodyProps}
          weight='emphasis'
          className={cn(customBodyClasses, loading ? 'invisible' : '')}
        >
          {children}
        </Body>
      );
    }, [children, layout, fontSize, bodyProps, customBodyClasses, loading]);

    return (
      <button
        ref={ref}
        type='button'
        className={cn(buttonVariants({ variant, size, disabled, layout, color, className, focusStyle }))}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        disabled={disabled || loading}
      >
        <Tooltip title={tooltip} sideOffset={16} className='z-tooltip'>
          <FlexLayout
            alignItems={textAlign}
            justifyContent={textJustify}
            spacing={8}
            className='relative h-full w-full'
          >
            <>
              {loading && <Loading className={cn(buttonLoadingVariants({ size, variant }), 'absolute')} />}
              {leadingIcon &&
                React.cloneElement(leadingIcon, {
                  className: cn(
                    buttonIconVariants({ variant, size, color, disabled }),
                    leadingIcon.props.className,
                    loading ? 'invisible' : '',
                  ),
                })}
              {content}
              {trailingIcon &&
                React.cloneElement(trailingIcon, {
                  className: cn(
                    buttonIconVariants({ variant, size, color, disabled }),
                    trailingIcon.props.className,
                    loading ? 'invisible' : '',
                  ),
                })}
            </>
          </FlexLayout>
        </Tooltip>
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };

import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';

import { UserStore } from '@shared/store';

import { useWhatsNewAnalytics } from '@hooks/Analytics/WhatsNew/useWhatsNewAnalytics';

import { useIsFeatureEnabled } from 'src/config';

import { FeatureDiscoveryContext } from '../context/FeatureDiscovery.context';
import { PlatformFeature } from '../types/FeatureDiscovery.types';

const MAX_FEATURES = 6;

const useFeatureDiscovery = () => {
  const { tourStarted } = useContext(TourGuideContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { features, setShow, show, showAll, setShowAll, minimized, setMinimized } = useContext(FeatureDiscoveryContext);
  const [activeFeatures, setActiveFeatures] = useState<PlatformFeature[]>([]);
  const [activeFeature, setActiveFeature] = useState<PlatformFeature>();
  const location = useLocation();
  const { isLoggedIn } = UserStore.useUserStore;
  const { trackWhatsNewOpened, trackWhatsNewClosed } = useWhatsNewAnalytics();

  useEffect(() => {
    // If a tour is running, don't filter out features as sometimes tours can change locations
    // which will then filter out existing features
    if (tourStarted || show) return;

    // when location changes, find any that match this route
    const relevantFeatures = Array.from(features).filter((f) => {
      if (f.shouldShowFeature && f.shouldShowFeature() === false) return false;
      if (localStorage.getItem(f.key) === 'true') return false;
      if (!f.routes) return true;
      return f.routes.includes(location.pathname);
    });

    setActiveFeatures(relevantFeatures.slice(Math.max(0, relevantFeatures.length - MAX_FEATURES)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features, location, isFeatureEnabled]);

  useEffect(() => {
    if (showAll) {
      setActiveFeatures(
        features.filter((f) => {
          if (f.shouldShowFeature !== undefined && f.shouldShowFeature() === false) return false;
          return true;
        }),
      );
    }
  }, [features, showAll]);

  useEffect(() => {
    if (!show && activeFeatures.length > 0) {
      trackWhatsNewOpened(activeFeatures[0].title, showAll ? 'manual' : 'automatic');
      setShow(true);
    }
  }, [activeFeatures, setShow, show, showAll, trackWhatsNewOpened]);

  useEffect(() => {
    if (!activeFeature) setActiveFeature(activeFeatures[0]);
  }, [activeFeature, activeFeatures]);

  // If we close the modal, we want to mark all the features as "seen"
  const onClose = useCallback(() => {
    const activeIndex = activeFeatures.findIndex((af) => af.key === activeFeature?.key);

    // Only mark items as seen and track the whats new closed if we have an active feature
    if (activeIndex > -1) {
      activeFeatures.forEach((f, index) => index <= activeIndex && localStorage.setItem(f.key, 'true'));
      if (activeFeatures[activeIndex]?.title) trackWhatsNewClosed(activeFeatures[activeIndex].title, activeIndex + 1);
    }

    setActiveFeatures([]);
    setActiveFeature(undefined);
    setShow(false);
    setShowAll(false);
    setMinimized(false);
  }, [activeFeature?.key, activeFeatures, setMinimized, setShow, setShowAll, trackWhatsNewClosed]);

  useEffect(() => {
    if (!isLoggedIn && show) onClose();
  }, [isLoggedIn, onClose, show]);

  return {
    showFeatureDiscovery: show,
    minimized,
    setMinimized,
    activeFeature,
    setActiveFeature,
    activeFeatures,
    onClose,
  };
};

export { useFeatureDiscovery };

import React, { useMemo, useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { getPriceScale } from '@swyftx/currency-util';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { RatesStore, UniversalTradeStore, UserStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

import { AssetFilter } from '@hooks/Assets/useAssets';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';

import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';

type Props = {
  style: React.CSSProperties;
  index: number;
  assetId: number;
  side: TradeSide;
  multiSelect: boolean;
  selectedFilter: AssetFilter;
};

type ClickType = 'none' | 'single' | 'multi';

const TradeAssetSelectorListItem: React.FC<Props> = observer(
  ({ style, index, assetId, multiSelect, side, selectedFilter }) => {
    const { convertCacheToTradeAssets, getMaxAssets } = useUniversalTradeUtilityStore();
    const { setTradeCache, tradeCache, setMaxTradeValue } = UniversalTradeStore;

    const [clicked, setClicked] = useState<ClickType>('none');
    const [multiMode, setMultiMode] = useState(tradeCache[side].length > 0);

    const { balances, userBaseCurrency } = UserStore.useUserStore;
    const { convertRate, getRate } = RatesStore.useRatesStore;

    const reverseSide = side === TradeSide.From ? TradeSide.To : TradeSide.From;
    const asset = assetService.getAsset(assetId)!;
    const baseAsset = assetService.getAsset(userBaseCurrency);
    const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);
    const hasReachedMax = tradeCache[side].length >= getMaxAssets(side);

    const checked = tradeCache[side].includes(assetId);
    const checkedReverse = UniversalTradeStore[reverseSide].includes(assetId);
    const balance = balances[assetId]?.availableBalance;
    const showBalance = useMemo(() => selectedFilter === AssetFilter.Owned, [selectedFilter]);

    const tradeDisabled = useMemo(() => {
      if (side === TradeSide.From && !asset.sellEnabled) return true;
      if (side === TradeSide.To && asset.buyDisabled) return true;

      return false;
    }, [asset.buyDisabled, asset.sellEnabled, side]);

    const disabled = useMemo(
      () =>
        tradeDisabled ||
        UniversalTradeStore[reverseSide].includes(assetId) ||
        tradeCache[reverseSide].includes(assetId) ||
        (hasReachedMax && !checked),
      [assetId, checked, hasReachedMax, reverseSide, tradeCache, tradeDisabled],
    );

    const onCheckSingle: React.MouseEventHandler<HTMLDivElement> = () => {
      // if we ever multi'd, stay in the multi behaviour
      setClicked(multiMode ? 'multi' : 'single');
    };

    // prevent race conditions with clicking the checkbox which overlaps with the list item button
    useDebounce(
      () => {
        if (clicked === 'single') {
          setTradeCache([assetId], side, TradeAssetAction.Add);
          convertCacheToTradeAssets(side);

          if (side === TradeSide.From) {
            setMaxTradeValue('');
          }
        }

        if (clicked === 'multi') {
          if (!checked) {
            setTradeCache([assetId], side, TradeAssetAction.Add);
          } else {
            setTradeCache([assetId], side, TradeAssetAction.Remove);
          }

          setMultiMode(true);
        }

        setClicked('none');
      },
      10,
      [clicked],
    );

    return (
      <ListItem style={style} key={index} sx={{ padding: 0 }}>
        <ListItemButton
          sx={{ padding: 0, paddingY: 0.5, borderRadius: 2 }}
          onClick={onCheckSingle}
          disabled={disabled && !checked}
        >
          <FlexLayout direction='row' className='w-full items-center pl-8' spacing={12}>
            <AssetIcon asset={asset} size={24} />
            <FlexLayout direction='column' spacing={0}>
              <Body weight='emphasis' color='primary' size='small'>
                {asset.name}
              </Body>
              <Body color='secondary' size='small'>
                {asset.code}
              </Body>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout direction='row' className='w-full justify-end'>
            {disabled && checkedReverse && (
              <FlexLayout direction='row' className='w-full justify-end'>
                <Chip size='sm' color='primary' variant='subtle'>
                  Asset selected
                </Chip>
              </FlexLayout>
            )}
            {showBalance && baseAsset && balance && !disabled && (
              <FlexLayout direction='column' className='items-end pr-8'>
                <Numeric size='small' weight='emphasis' className='text-end'>
                  {formatCurrency(
                    balance,
                    asset,
                    { appendCode: true, priceScale: getPriceScale(balance) },
                    asset.id,
                    true,
                  )}
                </Numeric>
                <Numeric size='small' color='secondary' className='text-end'>{`~${formatCurrency(
                  convertRate(asset, baseAsset.id, balance, 'askPrice'),
                  baseAsset,
                  {
                    appendCode: true,
                    priceScale: isBaseAssetFiat ? 2 : baseAsset.price_scale,
                  },
                )}`}</Numeric>
              </FlexLayout>
            )}
            {!showBalance && baseAsset && !disabled && !tradeDisabled && (
              <FlexLayout direction='row' spacing={4} className='items-center truncate pr-8'>
                <Numeric size='small' weight='emphasis'>
                  {`${formatCurrency(getRate(asset).askPrice, baseAsset, {
                    appendCode: false,
                    hideCode: true,
                    priceScale: baseAsset.price_scale,
                    hideSymbol: false,
                  })}`}
                </Numeric>
                <FlexLayout direction='row' spacing={0}>
                  <Numeric size='small'>{baseAsset.code}/</Numeric>
                  <Numeric color='secondary' size='small'>
                    {asset.code}
                  </Numeric>
                </FlexLayout>
              </FlexLayout>
            )}
            {(disabled || tradeDisabled) && !checked && !checkedReverse && (
              <FlexLayout direction='row' className='w-full justify-end'>
                <Chip size='sm' color='destructive' variant='subtle'>
                  {side === TradeSide.From ? 'Sell disabled' : 'Buy disabled'}
                </Chip>
              </FlexLayout>
            )}
            {multiSelect && (!tradeDisabled || !disabled || checked) && !checkedReverse && (
              <div className='flex items-center px-8'>
                <Checkbox className='h-20 w-20 border-color-border-main outline-1' checked={checked} />
              </div>
            )}
          </FlexLayout>
        </ListItemButton>
      </ListItem>
    );
  },
);

TradeAssetSelectorListItem.displayName = 'TradeAssetSelectorListItem';

export { TradeAssetSelectorListItem };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Expand: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M19.4423 5.28092C19.4427 5.08947 19.3667 4.90575 19.2313 4.77039C19.096 4.63503 18.9122 4.55916 18.7208 4.55957L14.3766 4.56888C13.9789 4.56973 13.6573 4.89277 13.6581 5.29042C13.659 5.68806 13.982 6.00972 14.3797 6.00887L16.9806 6.0033L13.4843 9.50043C13.2031 9.78164 13.2032 10.2375 13.4844 10.5187C13.7656 10.7998 14.2215 10.7998 14.5026 10.5186L17.999 7.02135L17.9941 9.62354C17.9934 10.0212 18.3151 10.3441 18.7128 10.3449C19.1104 10.3456 19.4334 10.0239 19.4341 9.62625L19.4423 5.28092Z'
      fill='currentColor'
    />
    <path
      d='M10.5196 14.4977C10.8008 14.2165 10.8008 13.7606 10.5196 13.4794C10.2384 13.1983 9.78254 13.1983 9.50136 13.4794L6.00488 16.9759L6.00983 14.3749C6.01059 13.9772 5.68885 13.6543 5.2912 13.6535C4.89356 13.6528 4.57059 13.9745 4.56983 14.3721L4.56163 18.6789C4.55083 18.8761 4.62077 19.0769 4.77143 19.2276C4.86505 19.3212 4.97804 19.3837 5.0975 19.4149C5.15755 19.4308 5.2199 19.439 5.2831 19.4389L9.62739 19.4295C10.025 19.4287 10.3467 19.1056 10.3458 18.708C10.345 18.3104 10.022 17.9887 9.62431 17.9896L7.02213 17.9951L10.5196 14.4977Z'
      fill='currentColor'
    />
  </svg>
);

export { Expand };

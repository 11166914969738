import { CountriesEnum } from '@shared/enums';

import { DateTime } from 'luxon';

export interface PresetTaxOption {
  title: string;
  subTitle: string;
  value: string;
  from: string;
  to: string;
  text: string;
  startDate: DateTime;
  endDate: DateTime;
}

// 1 July 2018 00:00:00
export const MINIMUM_TAX_REPORT_DATE = DateTime.fromMillis(1530367200000);

type FinancialMonthByCountry = {
  [key in CountriesEnum]?: number;
};

const financialMonthMapping: FinancialMonthByCountry = {
  AU: 7,
  NZ: 4,
};

export const getTaxPresetOptions = (country: CountriesEnum): PresetTaxOption[] => {
  const now = DateTime.now();
  // If the month is not current country FY, we want to go back an additional year as we are not in the new FY yet
  const yearOffset = now.month < (financialMonthMapping[country] || 7) ? 1 : 0;
  const fyArray = [];

  // generate last 3 financial years
  for (let i = 0; i < 3; i++) {
    const startFYDate = now
      .minus({ years: i + yearOffset })
      .set({ month: financialMonthMapping[country] || 7, day: 1 })
      .startOf('day');
    const endFYDate = startFYDate.plus({ year: 1 }).minus({ days: 1 }).endOf('day');

    fyArray.push({
      title: `FY ${startFYDate.year}/${endFYDate.year.toString().slice(-2)}`,
      subTitle: `${startFYDate.toFormat('yyyy MMMM')} - ${endFYDate.toFormat('yyyy MMMM')}`,
      value: `FY_${startFYDate.year.toString().slice(-2)}`,
      from: startFYDate.toISO(),
      to: endFYDate.toISO(),
      text: `${startFYDate.toFormat('dd MMM yyyy')} - ${endFYDate.toFormat('dd MMM yyyy')}`,
      startDate: startFYDate,
      endDate: endFYDate,
    });
  }

  const presetOptionArray = [
    {
      title: 'This Month',
      value: 'this_month',
      subTitle: `1 - ${now.toFormat('dd MMMM yyyy')}`,
      from: `${now.startOf('month').toFormat('yyyy-MM-dd')}`,
      to: `${now.toFormat('yyyy-MM-dd')}`,
      startDate: now.startOf('month'),
      endDate: now,
      text: `This Month (${now.toFormat('MMM yyyy')})`,
    },
    {
      title: 'Last Month',
      value: 'last_month',
      subTitle: `1 - ${now.minus({ month: 1 }).endOf('month').toFormat('dd MMMM yyyy')}`,
      from: `${now.minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd')}`,
      to: `${now.minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd')}`,
      startDate: now.minus({ month: 1 }).startOf('month'),
      endDate: now.minus({ month: 1 }).endOf('month'),
      text: `Last Month (${now.minus({ month: 1 }).toFormat('MMM yyyy')})`,
    },
    {
      title: 'Custom',
      value: 'custom',
      subTitle: 'Choose your own dates',
      from: '',
      to: '',
      startDate: DateTime.now().minus({ month: 1 }).startOf('day'),
      endDate: DateTime.now().endOf('day'),
      text: 'Custom',
    },
  ];

  return [...presetOptionArray.slice(0, -1), ...fyArray, presetOptionArray.slice(-1)[0]];
};

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  averagePricePaid: Big;
  variant?: 'dashboard' | 'trade';
};

const PortfolioTableAverageBuyPrice: React.FC<Props> = observer(({ averagePricePaid, variant = 'dashboard' }) => {
  const baseAsset = useBaseAsset();

  const averageBuyPrice = useMemo(() => {
    if (!averagePricePaid.eq(0)) {
      return `${formatCurrency(averagePricePaid, baseAsset, { hideCode: true, appendCode: false })}`;
    }

    return '-';
  }, [averagePricePaid, baseAsset]);

  if (variant === 'trade') {
    return (
      <FlexLayout direction='row' spacing={4}>
        <Numeric size='small' className='text-left'>
          {averageBuyPrice}
        </Numeric>
        {averagePricePaid.gt(0) && (
          <Numeric size='small' className='text-left' color='secondary'>
            {baseAsset?.code}
          </Numeric>
        )}
      </FlexLayout>
    );
  }

  return (
    <Numeric size='small' className='whitespace-nowrap text-right'>
      {averageBuyPrice} {averagePricePaid.gt(0) && baseAsset?.code}
    </Numeric>
  );
});

export { PortfolioTableAverageBuyPrice };

import { AssetServiceAssetsQuery, AssetServiceAssetsResponse } from '@shared/api/@types/assetService';
import { Category } from '@shared/api/@types/categories';

import { Methods, request } from '../rest';

const DEFAULT_QUERY: AssetServiceAssetsQuery = {
  includeCategories: true,
  includeNetworks: true,
};

const assetServiceEndpoints = {
  getAssetServiceAssets: (
    { query }: { query?: AssetServiceAssetsQuery } = { query: DEFAULT_QUERY },
  ): AssetServiceAssetsResponse =>
    request({
      path: '/assets/filter/',
      method: Methods.GET,
      query,
      hideErrorToast: true,
      auth: false,
    }),
  getAssetServiceCategories: (): Category[] =>
    request({
      path: '/assets/categories/',
      method: Methods.GET,
      hideErrorToast: true,
      auth: false,
    }),
};

export default assetServiceEndpoints;

import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Input, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { AddressBrokenUp } from '@shared/api';
import { CountriesEnum } from '@shared/enums';

type Props = {
  country: CountriesEnum;
  onClose: () => void;
  onAddManualAddress: (address: AddressBrokenUp) => void;
};

const ManualAddressModal: React.FC<Props> = ({ country, onClose, onAddManualAddress }) => {
  const [flatNumber, setFlatNumber] = useState<string>('');
  const [streetNumber, setStreetNumber] = useState<string>('');
  const [streetName, setStreetName] = useState<string>('');
  const [streetType, setStreetType] = useState<string>('');
  const [suburb, setSuburb] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const { t } = useTranslation('common');

  const addButtonDisabled = useMemo(
    () =>
      !streetNumber.length ||
      !streetName.length ||
      !streetType.length ||
      !suburb.length ||
      !state.length ||
      !postcode.length,
    [streetNumber, streetName, streetType, suburb, state, postcode],
  );

  const addManualAddress = () => {
    const address: AddressBrokenUp = {
      flatNumber,
      country,
      postcode,
      state,
      streetName,
      streetNumber,
      streetType,
      suburb,
    };

    onAddManualAddress(address);
  };

  const getLabel = (i18nKey: any) => (
    <Trans
      t={t}
      i18nKey={i18nKey}
      components={[
        <Typography
          key='credit-card-banxa-details-fee'
          display='inline-block'
          marginLeft={0.25}
          fontWeight={500}
          fontSize={14}
          color='error'
          sx={{ display: 'inline-block' }}
        />,
      ]}
    />
  );

  return (
    <Modal
      open
      HeaderProps={{ title: t('manualAddressInput.title'), dismissible: true }}
      FooterProps={{
        content: (
          <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
            <Button variant='text' color='inherit' size='large' onClick={onClose}>
              {t('manualAddressInput.buttonLabels.return')}
            </Button>
            <Button
              variant='contained'
              disableElevation
              color='primary'
              disabled={addButtonDisabled}
              size='large'
              onClick={addManualAddress}
            >
              {t('manualAddressInput.buttonLabels.add')}
            </Button>
          </Stack>
        ),
        divider: true,
      }}
      sx={{ width: '600px', '.MuiCardContent-root': { height: '100%' } }}
      onClose={onClose}
    >
      <Stack direction='column' spacing={2}>
        <Input
          PII
          label={t('manualAddressInput.labels.flatNumber')}
          placeholder={t('manualAddressInput.labels.flatNumberPlaceholder')}
          value={flatNumber}
          onChange={(e) => setFlatNumber(e.target.value)}
        />
        <Input
          PII
          label={getLabel('manualAddressInput.labels.streetNumber')}
          placeholder={t('manualAddressInput.labels.streetNumberPlaceholder')}
          value={streetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
        />
        <Input
          PII
          label={getLabel('manualAddressInput.labels.streetName')}
          placeholder={t('manualAddressInput.labels.streetNamePlaceholder')}
          value={streetName}
          onChange={(e) => setStreetName(e.target.value)}
        />
        <Input
          PII
          label={getLabel('manualAddressInput.labels.streetType')}
          placeholder={t('manualAddressInput.labels.streetTypePlaceholder')}
          value={streetType}
          onChange={(e) => setStreetType(e.target.value)}
        />
        <Input
          PII
          label={getLabel('manualAddressInput.labels.suburb')}
          placeholder={t('manualAddressInput.labels.suburbPlaceholder')}
          value={suburb}
          onChange={(e) => setSuburb(e.target.value)}
        />
        <Input
          PII
          label={getLabel('manualAddressInput.labels.state')}
          placeholder={t('manualAddressInput.labels.statePlaceholder')}
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <Input
          PII
          label={getLabel('manualAddressInput.labels.postcode')}
          placeholder={t('manualAddressInput.labels.postcodePlaceholder')}
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
        />
      </Stack>
    </Modal>
  );
};

export { ManualAddressModal };

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';

import { Category } from '@shared/api/@types/categories';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { RawCategoryIcons } from 'src/context/Categories/Categories.icons';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

interface Props {
  category: Category;
  selected: boolean;
}

export const AssetCategoriesTab: React.FC<Props> = observer((props) => {
  const { category, selected } = props;
  const { navigate } = useNavigateRoute();

  const icon = RawCategoryIcons('!h-12 !w-12')[category.name];

  const onSelectTab = useCallback(() => {
    navigate(NavigationURLs.AssetsCategory, { pathParams: { categoryId: category.id } });
  }, [category.id, navigate]);

  return (
    <Button
      variant={selected ? 'filled' : 'outlined'}
      key={category.id}
      onClick={() => onSelectTab()}
      color={selected ? 'inverse' : undefined}
      leadingIcon={icon}
      selected={selected}
      size='sm'
      className={cn(
        selected
          ? 'hover:bg-color-text-primary'
          : 'hover:bg-color-background-surface-hover hover:outline-color-border-main',
      )}
    >
      {category.name}
    </Button>
  );
});

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { EntityConfigureMemberWizard } from '@global-components/Modals/EntityConfigureMemberWizard';
import { EntityRemoveModal } from '@global-components/Modals/EntityRemoveModal/EntityRemoveModal';

import { EntityTypeEnum } from '@shared/enums';
import entityService from '@shared/services/entityService';
import { UserStore } from '@shared/store';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';
import entityHelpers, { EntityUserStatus, MappedEntityUserStatus } from '@shared/utils/lib/entityHelpers';

import { observer } from 'mobx-react-lite';

import { EntityMemberMoreOptions } from './EntityMemberMoreOptions';

type Props = {
  isAdmin?: boolean;
  member: EntityMember;
};

const EntityMemberListItem: React.FC<Props> = observer(({ isAdmin, member }) => {
  const { name, email, scope, verified, entityType } = member;
  const { previousUserProfile } = UserStore.useUserStore;
  const { t } = useTranslation('profile');

  const [showEntityWizard, setShowEntityWizard] = useState(false);
  const [removeMember, setRemoveMember] = useState(false);

  const userStatus: EntityUserStatus | undefined = useMemo(
    () => (verified ? MappedEntityUserStatus[verified] : undefined),
    [verified],
  );

  const scopePreset = useMemo(() => entityHelpers.getPermissionPreset(scope), [scope]);
  const isUser = member.uuid === previousUserProfile?.intercom?.uuid;
  const isEntityVerified = entityService.isEntityVerified();
  const canModify = isAdmin || isUser;

  const getChipColor = useCallback(() => {
    if (entityType === EntityTypeEnum.INVITE) {
      return 'secondary';
    }
    switch (userStatus) {
      case EntityUserStatus.VERIFIED:
        return 'success';
      case EntityUserStatus.INVITE_EXPIRED:
        return 'destructive';
      case EntityUserStatus.ACTIONS_PENDING:
      case EntityUserStatus.STEPS_PENDING:
      default:
        return 'secondary';
    }
  }, [entityType, userStatus]);

  const chipText = useMemo(() => {
    if (entityType === EntityTypeEnum.INVITE) {
      return t('entities.entityMembers.chip.stepsPending');
    }
    return userStatus;
  }, [entityType, t, userStatus]);

  const buttonText = useMemo(() => {
    if (entityType === EntityTypeEnum.INVITE) {
      return t('entities.entityMembers.button.configure');
    }
    switch (userStatus) {
      case EntityUserStatus.VERIFIED:
        return t('entities.entityMembers.button.configure');
      case EntityUserStatus.INVITE_EXPIRED:
        return t('entities.entityMembers.button.remove');
      case EntityUserStatus.ACTIONS_PENDING:
        return t('entities.entityMembers.button.resolve');
      case EntityUserStatus.STEPS_PENDING:
      default:
        return t('entities.entityMembers.button.verifyIdentity');
    }
  }, [entityType, t, userStatus]);

  const onButtonClick = useMemo(() => {
    if (entityType === EntityTypeEnum.INVITE) {
      return () => setShowEntityWizard(true);
    }

    switch (userStatus) {
      case EntityUserStatus.VERIFIED:
      case EntityUserStatus.ACTIONS_PENDING:
      case EntityUserStatus.STEPS_PENDING:
        return () => setShowEntityWizard(true);
      case EntityUserStatus.INVITE_EXPIRED:
      default:
        return () => setRemoveMember(true);
    }
  }, [entityType, userStatus]);

  return (
    <>
      <FlexLayout direction='row' className='PII w-full items-center justify-between p-16'>
        <FlexLayout direction='row' className='items-center' spacing={16}>
          <Avatar text={`${name[0]}${name.split(' ')[1][0]}`} />

          <FlexLayout direction='column' spacing={4}>
            <Body>{name}</Body>
            <Body color='secondary' size='small'>
              {email} &#183; {t(`entities.labels.${scopePreset}`) || scope}
            </Body>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='row' spacing={8} className='items-center'>
          <Chip size='sm' color={getChipColor()} variant='subtle'>
            {chipText}
          </Chip>
          {canModify && (
            <Button variant='outlined' onClick={onButtonClick}>
              {buttonText}
            </Button>
          )}
          {isAdmin && !isEntityVerified && <EntityMemberMoreOptions member={member} />}
        </FlexLayout>
      </FlexLayout>
      {showEntityWizard && (
        <EntityConfigureMemberWizard open onClose={() => setShowEntityWizard(false)} entityMember={member} />
      )}

      {removeMember && <EntityRemoveModal open onClose={() => setRemoveMember(false)} entityMember={member} />}
    </>
  );
});

export { EntityMemberListItem };

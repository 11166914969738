import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ArrowChevronRightFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M9.38388 4.11612C8.89573 3.62796 8.10427 3.62796 7.61612 4.11612C7.12796 4.60427 7.12796 5.39573 7.61612 5.88388L13.7322 12L7.61612 18.1161C7.12796 18.6043 7.12796 19.3957 7.61612 19.8839C8.10427 20.372 8.89573 20.372 9.38388 19.8839L16.3839 12.8839C16.872 12.3957 16.872 11.6043 16.3839 11.1161L9.38388 4.11612Z'
      fill='currentColor'
    />
  </svg>
);

export { ArrowChevronRightFilled };

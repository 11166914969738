import { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { RecurringOrderTemplate } from '@shared/api/@types/recurringOrder';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { AutoInvestDonutChart } from '../components/AutoInvestDonutChart';

type Props = {
  template: RecurringOrderTemplate[];
};

export type AutoInvestmentOrderTableData = {
  asset: string;
  activeAllocation: string;
  invested: string;
  avgBuyPrice: string;
  balance: string;
};

type AutoInvestmentHeaderData = { [key in keyof AutoInvestmentOrderTableData]: EnhancedTableHeaderData };

const useAutoInvestOrderTable = ({ template }: Props) => {
  const countryAsset = useCountryAsset();
  const [sort, setSort] = useState<EnhancedTableSort<AutoInvestmentOrderTableData>>({
    sortKey: 'activeAllocation',
    sortDirection: 'DESC',
  });

  const headers: AutoInvestmentHeaderData = useMemo(
    () => ({
      asset: {
        title: 'Asset',
        alignment: 'start',
        sortable: false,
        frozen: true,
        className: 'table-cell pl-24',
        insetLeft: true,
        showFrozenDivider: true,
      },
      activeAllocation: {
        title: 'Active allocation',
        alignment: 'start',
        sortable: true,
        className: 'table-cell',
      },

      invested: {
        title: 'Invested',
        alignment: 'start',
        sortable: true,
        insetLeft: true,
        className: 'table-cell',
      },

      avgBuyPrice: {
        title: 'Avg buy price',
        alignment: 'end',
        sortable: false,
        className: 'table-cell',
      },

      balance: {
        title: 'balance',
        alignment: 'end',
        sortable: false,
        className: 'table-cell pr-24',
      },
    }),
    [],
  );

  const data: EnhancedTableData<AutoInvestmentOrderTableData, RecurringOrderTemplate>[] = useMemo(() => {
    if (!template) return [];

    return template
      .map((templateItem: RecurringOrderTemplate) => {
        const asset = assetService.getAsset(templateItem.secondaryAssetId);
        const avgBuyPrice = Big(templateItem.total).div(templateItem.qty);
        const percentage = Big(templateItem.active ? templateItem.percentage : 0);

        return {
          value: templateItem,
          asset: {
            value: asset?.code,
            element: (
              <FlexLayout justifyContent='start' alignItems='center' spacing={8}>
                <AssetIcon asset={asset} size={20} />
                <Body size='small' overflow='truncate'>
                  {asset?.name}
                </Body>
                <Body size='small' color='secondary' overflow='truncate'>
                  {asset?.code}
                </Body>
              </FlexLayout>
            ),
          },
          activeAllocation: {
            value: percentage,
            element: asset ? <AutoInvestDonutChart asset={asset} percentage={percentage} /> : null,
          },

          invested: {
            value: templateItem.total,
            element: (
              <FlexLayout justifyContent='start' alignItems='center'>
                <Numeric size='small'>{formatCurrency(templateItem.total, countryAsset)}</Numeric>
              </FlexLayout>
            ),
          },

          avgBuyPrice: {
            value: avgBuyPrice,
            element: (
              <FlexLayout justifyContent='end' alignItems='center'>
                <Numeric size='small'>{formatCurrency(avgBuyPrice, countryAsset)}</Numeric>
              </FlexLayout>
            ),
          },

          balance: {
            value: templateItem.qty,
            element: (
              <FlexLayout justifyContent='end' alignItems='center' spacing={4}>
                <Numeric size='small'>
                  {formatCurrency(templateItem.qty, asset, { appendCode: false, hideCode: true })}
                </Numeric>{' '}
                <Numeric size='small' color='secondary'>
                  {asset?.code}
                </Numeric>
              </FlexLayout>
            ),
          },
        };
      })
      .sort((a, b) => b.activeAllocation.value.cmp(a.activeAllocation.value));
  }, [countryAsset, template]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<AutoInvestmentOrderTableData>) => {
      const tableData: EnhancedTableData<AutoInvestmentOrderTableData, RecurringOrderTemplate>[] = Object.assign(
        [],
        data,
      );
      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => {
        switch (newSort.sortKey) {
          case 'asset':
            const aVal = a[newSort.sortKey].value as string;
            const bVal = b[newSort.sortKey].value as string;
            if (newSort.sortDirection === 'ASC') return bVal.localeCompare(aVal);
            return aVal.localeCompare(bVal);
          case 'avgBuyPrice':
          case 'balance':
          case 'invested':
          case 'activeAllocation':
            const aValBig = Big(a[newSort.sortKey].value as string);
            const bValBig = Big(b[newSort.sortKey].value as string);
            if (newSort.sortDirection === 'ASC') return aValBig.cmp(bValBig);
            return bValBig.cmp(aValBig);
          default:
            return 1;
        }
      });
    },
    [data],
  );

  return { initialSort: sort, headers, data, onSort };
};

export { useAutoInvestOrderTable };

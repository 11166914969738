import React, { useContext, useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';

import { cn } from '@shared/utils/lib/ui';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';

import {
  AutoInvestOrderWidgetPreferences,
  AutoInvestWidgetComplete,
  AutoInvestWidgetFailure,
  AutoInvestWidgetReview,
  AutoInvestWidgetValues,
} from './steps';
import { AutoInvestSelectAssets } from './steps/AutoInvestSelectAssets';
import { AutoInvestContext } from '../../context';

const CreateAutoInvestWidgetContent: React.FC = () => {
  const { currentStep } = useContext(AutoInvestContext);

  const content = useMemo(() => {
    switch (currentStep) {
      case 'assets':
        return <AutoInvestSelectAssets />;
      case 'values':
        return <AutoInvestWidgetValues />;
      case 'orderPreferences':
        return <AutoInvestOrderWidgetPreferences />;
      case 'review':
        return <AutoInvestWidgetReview />;
      case 'complete':
        return <AutoInvestWidgetComplete />;
      case 'failure':
        return <AutoInvestWidgetFailure />;
    }
  }, [currentStep]);

  return (
    <Card
      className={cn(
        ['values', 'orderPreferences', 'review'].includes(currentStep) ? 'min-h-[35rem] sm:min-h-[48rem]' : '',
        'w-full overflow-hidden rounded-24 sm:w-[32rem]',
      )}
    >
      {content}
    </Card>
  );
};

const CreateAutoInvestWidget: React.FC = observer(() => {
  const countryAsset = useCountryAsset();

  if (!countryAsset) return null;

  return <CreateAutoInvestWidgetContent />;
});

export { CreateAutoInvestWidget };

import React from 'react';

import CheckboxMUI, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabelMUI from '@mui/material/FormControlLabel';

type Props = {
  id?: string;
  label?: string | React.ReactElement;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
} & CheckboxProps;

const Checkbox: React.FC<Props> = ({ id, label = '', labelPlacement = 'end', ...props }) => (
  <FormControlLabelMUI control={<CheckboxMUI id={id} {...props} />} label={label} labelPlacement={labelPlacement} />
);

export default Checkbox;

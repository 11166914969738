import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

type Props = {
  direction?: 'row' | 'column';
  num?: number;
  skeletonElement: React.ReactNode;
};

const SkeletonRenderer: React.FC<Props> = ({ direction = 'column', num = 1, skeletonElement }) => {
  const numArray = useMemo(() => new Array(num).fill(''), [num]);

  return <FlexLayout direction={direction}>{numArray.map(() => skeletonElement)}</FlexLayout>;
};

export { SkeletonRenderer };

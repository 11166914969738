export enum ShopProductEnum {
  LedgerNanoX = 'ledgerNanoX',
  LedgerNanoSPlus = 'ledgerNanoSPlus',
}

export enum ShopProductColorEnum {
  MatteBlack = 'matte-black',
  SaffronYellow = 'saffron-yellow',
  FlamingoPink = 'flamingo-pink',
  JadeGreen = 'jade-green',
  LagoonBlue = 'lagoon-blue',
  Transparent = 'transparent',
  MysticWhite = 'mystic-white',
  DeepseaBlue = 'deepsea-blue',
}

import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import {
  alertWindowToTitle,
  PriceAlertDirection,
  PriceAlertTriggerType,
  PriceAlertWindow,
} from '../../dynamicPriceAlerts.types';

type Props = {
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  type: PriceAlertTriggerType;
  direction?: PriceAlertDirection;
  triggered?: boolean;
  price?: string;
  change?: string;
  window?: PriceAlertWindow;
};

const DynamicPriceAlertTitle: React.FC<Props> = ({
  primaryAsset,
  secondaryAsset,
  type,
  direction,
  price,
  change,
  window = 'DAY',
  triggered,
}) => {
  const { t } = useTranslation('priceAlerts');

  const alertTitle = useMemo(() => {
    if (type === 'LIMIT') {
      if (triggered) {
        return (
          <Trans
            t={t}
            i18nKey='labels.triggerAlertTitle'
            values={{
              code: secondaryAsset?.code,
              direction: direction === 'ABOVE' ? 'increase' : 'decrease',
              price: formatCurrency(price, primaryAsset, {
                hideCode: false,
                appendCode: true,
              }),
            }}
            components={[
              <Body key='title' className='inline' color='primary' weight='bold' />,
              <Body key='price' className='inline' color='accent' weight='bold' />,
            ]}
          />
        );
      }

      return (
        <Trans
          t={t}
          i18nKey='labels.priceAlertTitle'
          values={{
            base: secondaryAsset?.code,
            direction: direction === 'ABOVE' ? 'increase' : 'decrease',
            price: formatCurrency(price, primaryAsset, {
              hideCode: false,
              appendCode: true,
            }),
          }}
          components={[
            <Body key='base' className='inline' color='primary' weight='bold' />,
            <Body key='title' className='inline' color='primary' weight='bold' />,
            <Body key='price' className='inline' color='accent' weight='bold' />,
          ]}
        />
      );
    } else {
      if (triggered) {
        return (
          <Trans
            t={t}
            i18nKey='labels.percentChangedTitle'
            values={{
              base: `${secondaryAsset?.code}/${primaryAsset?.code}`,
              percentage: `${Big(change).times(100)}%`,
              window: alertWindowToTitle[window],
            }}
            components={[
              <Body key='base' className='inline' color='primary' weight='bold' />,
              <Body key='title' className='inline' color='primary' weight='bold' />,
              <Body key='price' className='inline' color='accent' weight='bold' />,
            ]}
          />
        );
      }

      return (
        <Trans
          t={t}
          i18nKey='labels.percentChangeTitle'
          values={{
            base: `${secondaryAsset?.code}/${primaryAsset?.code}`,
            percentage: `${Big(change).times(100)}%`,
            window: alertWindowToTitle[window],
          }}
          components={[
            <Body key='base' className='inline' color='primary' weight='bold' />,
            <Body key='title' className='inline' color='primary' weight='bold' />,
            <Body key='price' className='inline' color='accent' weight='bold' />,
          ]}
        />
      );
    }
  }, [type, triggered, t, primaryAsset, direction, price, secondaryAsset?.code, change, window]);

  return <div className='inline w-full text-left'>{alertTitle}</div>;
};

export { DynamicPriceAlertTitle };

import React from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  children: JSX.Element;
  color?: string;
  className?: string;
  bgClassName?: string;
};

export const InverseAssetIcon: React.FC<Props> = ({ color, children, className, bgClassName }) => (
  <Avatar
    className={cn(className, bgClassName, 'p-2 text-white')}
    style={{
      backgroundColor: bgClassName === undefined ? color : undefined,
    }}
  >
    {children}
  </Avatar>
);

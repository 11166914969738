import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import { QuickBuyPage } from './QuickBuyPage';

export const QuickBuyRoutes: RouteObject[] = [
  {
    path: NavigationURLs.QuickBuy,
    element: <PrivateRoute element={<QuickBuyPage />} />,
  },
];

import { useLocation } from 'react-router-dom';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import {
  HIDE_MARKET_TICKER,
  HIDE_RESTRICTED_MODAL,
  NO_GLOBAL_ACTIONS_ROUTES,
  FULL_PAGE_ROUTES,
} from '@shared/constants/layout';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

export const useShouldSkipGlobalActions = () => {
  const location = useLocation();

  const { isFeatureEnabled } = useIsFeatureEnabled();

  // Using the same trade route so have to feature flag this. Remove when we deprecate old trade page
  if (
    isFeatureEnabled(AppFeature.UniversalTradePage) &&
    location.pathname.includes(NavigationURLs.UniversalTrade) &&
    !location.pathname.includes(NavigationURLs.ProfileTradeFees)
  )
    return true;
  return NO_GLOBAL_ACTIONS_ROUTES.filter((r: string) => location.pathname.includes(r)).length;
};

export const useShouldHideRestrictedModal = () => {
  const location = useLocation();
  return HIDE_RESTRICTED_MODAL.filter((r: string) => location.pathname.includes(r)).length;
};

export const useShouldHideMarketTicker = () => {
  const location = useLocation();
  return HIDE_MARKET_TICKER.filter((r: string) => location.pathname.includes(r)).length;
};

export const useShouldHideMobileAppHeaderMenu = () => {
  const isXs = useTailwindBreakpoint('xs');
  const location = useLocation();
  return isXs && FULL_PAGE_ROUTES.filter((r: string) => location.pathname.includes(r)).length;
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Swap: React.FC<Props> = ({ className, ...props }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M3.89514 4.89062L2.95181 5.83396L2.00781 4.89062'
      stroke='currentColor'
      strokeWidth='0.999999'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.95312 5.78267V4.35867C2.95312 3.056 4.00912 2 5.31179 2'
      stroke='currentColor'
      strokeWidth='0.999999'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.7624 4.63867C13.7624 3.18134 12.581 2.00001 11.1237 2C9.66637 2 8.48438 3.18133 8.48438 4.63867C8.48438 6.096 9.66637 7.27733 11.1237 7.27733C12.581 7.27733 13.7624 6.09601 13.7624 4.63867Z'
      stroke='currentColor'
      strokeWidth='0.999999'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.23438 11.3652C2.23438 12.8226 3.41571 14.0039 4.87304 14.0039C6.33037 14.0039 7.51238 12.8226 7.51238 11.3652C7.51238 9.9079 6.33037 8.72656 4.87304 8.72656C3.41571 8.72656 2.23438 9.9079 2.23438 11.3652Z'
      stroke='currentColor'
      strokeWidth='0.999999'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1094 11.1074L13.0527 10.1641L13.9967 11.1074'
      stroke='currentColor'
      strokeWidth='0.999999'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.0462 10.2109V11.6349C13.0462 12.9376 11.9902 13.9936 10.6875 13.9936'
      stroke='currentColor'
      strokeWidth='0.999999'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { Swap };

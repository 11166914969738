import React from 'react';

import ListMUI, { ListProps } from '@mui/material/List';

const List: React.FC<ListProps> = ({ id, children, ...props }) => (
  <ListMUI id={id} {...props}>
    {children}
  </ListMUI>
);

export { List };

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditInSquare, Paper } from '@swyftx/aviary/icons/outlined';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerifyTile } from '@Profile/subroutes/Verification/components/VerificationMethod/VerifyTile';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { GreenIDFrame } from './GreenIDFrame';
import { formsOfAcceptedId, GreenIDResponse } from './GreenIDVerificationModal.data';
import { CancelVerificationModal } from '../VerificationMethod/CancelVerificationModal';

const GreenIDVerificationModal: React.FC = observer(() => {
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const [showGreenId, setShowGreenId] = useState<boolean>(false);
  const [greenIdResponse, setGreenIdResponse] = useState<GreenIDResponse>();

  const [showConfirmClose, setShowConfirmClose] = useState<boolean>(false);

  const { t } = useTranslation('profile.verification');

  const continueToGreenId = () => {
    setShowGreenId(true);
  };

  const onCloseFromConfirmation = (closeParent: boolean) => {
    if (closeParent) {
      onClose(false);
    } else {
      setShowConfirmClose(false);
    }
  };

  const onClose = (showConfirmation: boolean) => {
    if (!showConfirmation || greenIdResponse !== undefined) {
      setVerificationStep(VerificationStep.IdentityVerificationSummary);
      return;
    }

    setShowConfirmClose(true);
  };

  return (
    <>
      <Modal
        HeaderProps={{
          title: t('greenIdVerificationModal.title'),
          dismissible: true,
          divider: true,
        }}
        FooterProps={
          showGreenId
            ? undefined
            : {
                divider: true,
                content: (
                  <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2}>
                    <Button variant='text' color='inherit' onClick={() => onClose(false)}>
                      {t(`greenIdVerificationModal.buttonsLabels.skip`)}
                    </Button>
                    <Button variant='contained' color='primary' onClick={continueToGreenId}>
                      {t('greenIdVerificationModal.buttonsLabels.understand')}
                    </Button>
                  </Stack>
                ),
              }
        }
        sx={{
          visibility: showConfirmClose ? 'hidden' : 'visible',
          width: '600px',
          maxHeight: 'calc(100% - 2rem)',
          '.MuiCardContent-root': { height: '100%' },
        }}
        onClose={() => onClose(true)}
        open
        variant='BottomSheet'
        PII
      >
        <>
          {showGreenId && (
            <GreenIDFrame greenIdResponse={greenIdResponse} onClose={onClose} setGreenIdResponse={setGreenIdResponse} />
          )}
          {!showGreenId && (
            <Stack direction='column' spacing={2}>
              <Typography>{t('greenIdVerificationModal.subTitle')}</Typography>
              <Typography fontSize={18} fontWeight={600}>
                {t('greenIdVerificationModal.labels.whatsInvolved')}
              </Typography>
              <VerifyTile
                leading={<EditInSquare className='h-24 w-24 text-color-text-primary' />}
                title={t('greenIdVerificationModal.labels.twoFormsOfId')}
                content={
                  <ul style={{ marginLeft: '8px', marginTop: '8px', paddingLeft: '16px' }}>
                    {formsOfAcceptedId.map((id: string) => (
                      <li key={id}>
                        <Typography color='text.secondary' fontSize={12}>
                          {t(id as any)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                }
              />
              <VerifyTile
                leading={<Paper className='h-24 w-24 text-color-text-primary' />}
                title={t('greenIdVerificationModal.labels.uploadIdDocuments')}
                subTitle={t('greenIdVerificationModal.labels.noAcceptedId')}
              />
            </Stack>
          )}
        </>
      </Modal>
      {showConfirmClose && <CancelVerificationModal onClose={onCloseFromConfirmation} />}
    </>
  );
});

export { GreenIDVerificationModal };

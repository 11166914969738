/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { PostRecurringOrderResponse, RecurringOrderFrequency } from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';

import { DateTime } from 'luxon';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AutoInvestAsset, AutoInvestStep } from '../autoInvest.types';

type AutoInvestContextType = {
  currentStep: AutoInvestStep;
  selectedAutoInvestAssets: AutoInvestAsset[];
  orderName?: string;
  selectedPaymentMethod: RecurringOrderSource;
  amount?: string;
  startDate?: DateTime;
  frequency?: RecurringOrderFrequency;
  autoInvestOrder?: PostRecurringOrderResponse;
  setCurrentStep: React.Dispatch<React.SetStateAction<AutoInvestStep>>;
  setSelectedAutoInvestAssets: React.Dispatch<React.SetStateAction<AutoInvestAsset[]>>;
  setOrderName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<RecurringOrderSource>>;
  setAmount: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
  setFrequency: React.Dispatch<React.SetStateAction<RecurringOrderFrequency | undefined>>;
  setAutoInvestOrder: React.Dispatch<React.SetStateAction<PostRecurringOrderResponse | undefined>>;
  onResetValues: () => void;
  onCancel: () => void;
  onLock: (assetCode: string) => void;
};

const AutoInvestContext = createContext<AutoInvestContextType>({
  currentStep: 'assets',
  selectedAutoInvestAssets: [],
  orderName: '',
  selectedPaymentMethod: RecurringOrderSource.ACCOUNT,
  amount: '',
  startDate: DateTime.now().startOf('day'),
  frequency: 'WEEKLY',
  setCurrentStep: () => {},
  setSelectedAutoInvestAssets: () => {},
  setOrderName: () => {},
  setSelectedPaymentMethod: () => {},
  setAmount: () => {},
  setStartDate: () => {},
  setFrequency: () => {},
  setAutoInvestOrder: () => {},
  onResetValues: () => {},
  onCancel: () => {},
  onLock: () => {},
});

const AutoInvestContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isXs = useTailwindBreakpoint('xs');
  const [currentStep, setCurrentStep] = useState<AutoInvestStep>(isXs ? 'values' : 'assets');
  const [selectedAutoInvestAssets, setSelectedAutoInvestAssets] = useState<AutoInvestAsset[]>([
    { assetCode: '', percentage: 100, locked: false },
  ]);
  const [orderName, setOrderName] = useState<string>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<RecurringOrderSource>(
    isFeatureEnabled(AppFeature.AutoInvestFromBalances) ? RecurringOrderSource.ACCOUNT : RecurringOrderSource.DEPOSIT,
  );
  const [amount, setAmount] = useState<string>();
  const [startDate, setStartDate] = useState<DateTime | undefined>(DateTime.now().startOf('day'));
  const [frequency, setFrequency] = useState<RecurringOrderFrequency | undefined>('WEEKLY');
  const [autoInvestOrder, setAutoInvestOrder] = useState<PostRecurringOrderResponse>();

  const { navigate } = useNavigateRoute();

  const onLock = useCallback(
    (assetCode: string) => {
      if (selectedAutoInvestAssets.length > 0) {
        const newSelectedAssetStates = selectedAutoInvestAssets.map((asset) => {
          if (asset.assetCode === assetCode) {
            return { ...asset, locked: !asset.locked };
          }
          return asset;
        });
        setSelectedAutoInvestAssets(newSelectedAssetStates);
      }
    },
    [selectedAutoInvestAssets],
  );

  const onCancel = useCallback(() => {
    navigate(NavigationURLs.AutoInvest);
  }, [navigate]);

  const onResetValues = useCallback(() => {
    setSelectedAutoInvestAssets([{ assetCode: '', percentage: 100, locked: false }]);
    setOrderName(undefined);
    setSelectedPaymentMethod(RecurringOrderSource.ACCOUNT);
    setAmount(undefined);
    setStartDate(DateTime.now().startOf('day'));
    setFrequency('WEEKLY');
    setAutoInvestOrder(undefined);
    setCurrentStep('values');
  }, []);

  return (
    <AutoInvestContext.Provider
      value={{
        currentStep,
        selectedAutoInvestAssets,
        orderName,
        amount,
        selectedPaymentMethod,
        startDate,
        frequency,
        autoInvestOrder,
        setCurrentStep,
        setSelectedAutoInvestAssets,
        setOrderName,
        setSelectedPaymentMethod,
        setAmount,
        setStartDate,
        setFrequency,
        setAutoInvestOrder,
        onResetValues,
        onCancel,
        onLock,
      }}
    >
      {children}
    </AutoInvestContext.Provider>
  );
};

export { AutoInvestContextProvider, AutoInvestContext };

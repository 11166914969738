import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Toast } from '@swyftx/aviary/atoms/Toast';
import { ToastType } from '@swyftx/aviary/atoms/Toast/Toast.types';

import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { ToastManagerContext } from './ToastManager.context';

const ToastManager: React.FC = () => {
  const { toasts, getNextToast } = useContext(ToastManagerContext);
  const [currentToast, setCurrentToast] = useState<ToastType | undefined>();
  const { isSwyftxPro } = useSwyftxPro();

  useEffect(() => {
    if (toasts.length > 0 && !currentToast) {
      setCurrentToast(getNextToast());
    }
  }, [currentToast, getNextToast, toasts]);

  const className = useMemo(
    () =>
      // Swyftx Pro toast messages should appear in the bottom right and standard will be in the centre
      isSwyftxPro ? 'fixed bottom-[24px] right-16 z-toast' : 'fixed bottom-[24px] left-1/2 z-toast -translate-x-1/2',
    [isSwyftxPro],
  );

  if (!currentToast) return null;

  return (
    <div className={className}>
      <Toast
        type={currentToast.type}
        title={currentToast.title}
        description={currentToast.description}
        durationMs={currentToast.durationMs}
        isDismissable={currentToast.isDismissable}
        onDismiss={() => setCurrentToast(getNextToast())}
      />
    </div>
  );
};

export { ToastManager };

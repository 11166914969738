import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import {
  DetailedPieData,
  DetailedPieDatum,
  PieChartData,
  PieChartDatum,
} from '@global-components/charts/PieChart/PieChart.types';

import { Asset, AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { AssetColors } from '@utils/assets';

import { BalanceKey, getBalanceValue } from 'src/utils/balance';
import { STAKING_COLOR, TRADING_COLOR } from 'src/utils/color';

type Output = {
  pieData: PieChartData;
  detailedData: DetailedPieData;
};

export enum OverviewDataID {
  Trading = -1,
  Staking = -2,
}

export const usePieChartAssetData = (
  selectedAssets: Array<Asset>,
  options?: {
    hideFiat?: boolean;
    balanceKey?: BalanceKey;
  },
): Output => {
  const theme = useTheme();
  const balanceKey = options?.balanceKey || 'all';
  const { balances } = UserStore.useUserStore;
  const { getRate } = RatesStore.useRatesStore;

  return useMemo(
    () =>
      Object.entries(balances).reduce<Output>(
        (acc, [, val]) => {
          const asset = selectedAssets.find((assetItem) => assetItem.id === val.assetId) || null;

          if (!asset || (options?.hideFiat && asset.assetType === AssetType.Fiat)) {
            return acc;
          }

          // Let me know if there's a better way to get the current value -- haven't explored all the services/stores yet
          const amount = parseFloat(getBalanceValue(val, balanceKey));
          const value = parseFloat(getRate(asset).midPrice) * amount;
          const color =
            AssetColors[asset.code] !== undefined && AssetColors[asset.code] !== 'undefined' // color is typed as string undefined for some reason in asset array
              ? AssetColors[asset.code]
              : theme.palette.secondary.main;

          const pieDatum: PieChartDatum = {
            name: asset.name,
            id: asset.id,
            color,
            value,
          };

          const detailedDatum: DetailedPieDatum = {
            type: asset.assetType,
            name: asset.name,
            code: asset.code,
            id: asset.id,
            amount,
            color,
            value,
          };

          acc.pieData.push(pieDatum);
          acc.detailedData.push(detailedDatum);

          return acc;
        },
        { pieData: [], detailedData: [] },
      ),
    [balanceKey, balances, getRate, options?.hideFiat, selectedAssets, theme.palette.secondary.main],
  );
};

export const usePieChartOverviewData = (
  selectedAssets?: Array<Asset>,
  options?: {
    hideFiat?: boolean;
  },
): Output['pieData'] => {
  const { balances } = UserStore.useUserStore;
  const { getRate } = RatesStore.useRatesStore;
  const assets = selectedAssets || assetService.getActiveAssetList();

  return Object.entries(balances).reduce<Output['pieData']>(
    (acc, [, val]) => {
      const asset = assets.find((assetItem) => assetItem.id === val.assetId) || null;

      if (!asset || (options?.hideFiat && asset.assetType === AssetType.Fiat)) {
        return acc;
      }

      const tradingBalance = parseFloat(getBalanceValue(val, 'availableBalance'));
      const stakingBalance = parseFloat(getBalanceValue(val, 'stakingBalance'));
      const tradingValue = parseFloat(getRate(asset).midPrice) * tradingBalance;
      const stakingValue = parseFloat(getRate(asset).midPrice) * stakingBalance;

      acc[0].value += tradingValue;
      acc[1].value += stakingValue;

      return acc;
    },
    [
      {
        name: 'Spot trading',
        id: OverviewDataID.Trading,
        color: TRADING_COLOR,
        value: 0,
      },
      {
        name: 'Earn',
        id: OverviewDataID.Staking,
        color: STAKING_COLOR,
        value: 0,
      },
    ],
  );
};

import React, { useContext, useEffect, useState } from 'react';

import { useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import { Contract, Drag, Expand, Hide, Show } from '@swyftx/aviary/icons/outlined';
import { Button, Stack } from '@swyftx/react-web-design-system';

import { DragItemProps } from '@global-components/DragAndDrop/DragAndDrop.data';

import { DashboardTile, DashboardTileSize } from '@shared/api';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { DashboardContext } from '@Dashboard/Dashboard.context';

import { observer } from 'mobx-react-lite';

type Props = {
  tile: DashboardTile;
  title?: string;
  customHeaderBar?: React.ReactNode;
  headerBarActionItem?: React.ReactNode;
  headerSx?: SxProps<{}>;
  noPadding?: boolean;
  allowCompact: boolean;
  allowWide: boolean;
  resizable: boolean;
} & DragItemProps;

type ActionButtonProps = {
  onToggle?: () => void;
  theme: Theme;
  display?: boolean;
};

const MinimiseButton: React.FC<ActionButtonProps> = ({ onToggle, theme }) => (
  <Button
    onClick={onToggle}
    icon
    sx={{
      display: 'flex',
      color: 'text.primary',
      ':hover': { backgroundColor: 'action.hover', color: theme.palette.primary.main },
    }}
  >
    <Contract className='h-18 w-18' />
  </Button>
);

const MaximiseButton: React.FC<ActionButtonProps> = ({ onToggle, theme }) => (
  <Button
    onClick={onToggle}
    icon
    sx={{
      display: 'flex',
      color: 'text.primary',
      ':hover': { backgroundColor: 'action.hover', color: theme.palette.primary.main },
    }}
  >
    <Expand className='h-18 w-18' />
  </Button>
);

const VisibilityButton: React.FC<ActionButtonProps> = ({ onToggle, theme, display }) => (
  <Button
    id='hide-tile-button'
    icon
    sx={{
      color: 'text.primary',
      ':hover': { backgroundColor: 'action.hover', color: theme.palette.primary.main },
    }}
    onClick={onToggle}
  >
    {display === true ? <Show className='h-18 w-18' /> : <Hide className='h-18 w-18' />}
  </Button>
);

const DragButton: React.FC<ActionButtonProps> = observer(({ theme }) => {
  const { bx } = useContentBreakpoint();

  return (
    <Button
      icon
      sx={{
        display: bx({ xs: 'none', md: 'flex' }),
        color: 'text.primary',
        ':hover': { backgroundColor: 'action.hover', color: theme.palette.primary.main },
      }}
    >
      <Drag className='h-18 w-18' />
    </Button>
  );
});

const DashboardBaseTileEditActions: React.FC<Props> = observer(({ tile, allowCompact, allowWide, resizable }) => {
  const theme = useTheme();
  const { editMode, updateEditTile } = useContext(DashboardContext);
  const [expandable, setExpandable] = useState(false);
  const [minimisable, setMinimisable] = useState(false);

  const { display, name, metadata } = tile;
  const { size } = metadata || {};
  const { isDesktopContent, isMediumContent, isSmallContent } = useContentBreakpoint();

  const onToggleDisplay = () => {
    if (!editMode) return;
    updateEditTile(name, { display: !display });
  };

  const onToggleMinimise = () => {
    if (!editMode || !size || size === 'compact' || isSmallContent) return;

    let newSize: DashboardTileSize = 'half';
    if (isDesktopContent) {
      switch (size) {
        case 'full':
          newSize = 'wide';
          break;
        case 'wide':
          newSize = 'half';
          break;
        case 'half':
        default:
          newSize = 'compact';
          break;
      }
    }

    updateEditTile(name, { metadata: { size: newSize } });
  };

  const onToggleExpand = () => {
    if (!editMode || !size || size === 'full' || isSmallContent) return;

    let newSize: DashboardTileSize = 'full';
    if (isDesktopContent) {
      switch (size) {
        case 'compact':
          newSize = 'half';
          break;
        case 'half':
          newSize = 'wide';
          break;
        case 'wide':
        default:
          newSize = 'full';
          break;
      }
    }

    updateEditTile(name, { metadata: { size: newSize } });
  };

  useEffect(() => {
    if (isDesktopContent) {
      if (size === 'compact' || !size) {
        setMinimisable(false);
        setExpandable(true);
      } else if (size === 'full') {
        setMinimisable(true);
        setExpandable(false);
      } else if (size === 'half') {
        setMinimisable(allowCompact);
        setExpandable(allowWide);
      } else {
        setMinimisable(true);
        setExpandable(true);
      }
      return;
    }

    if (isMediumContent) {
      if (size === 'half' || size === 'compact' || !size) {
        setMinimisable(false);
        setExpandable(allowWide);
      } else {
        setMinimisable(true);
        setExpandable(false);
      }

      return;
    }

    setMinimisable(false);
    setExpandable(false);
  }, [size, isDesktopContent, isMediumContent, allowCompact, allowWide]);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
      <Stack direction='row' alignItems='center' spacing={1}>
        {resizable && minimisable && <MinimiseButton onToggle={onToggleMinimise} theme={theme} />}
        {resizable && expandable && <MaximiseButton onToggle={onToggleExpand} theme={theme} />}
        <VisibilityButton theme={theme} onToggle={onToggleDisplay} display={display} />
        <DragButton theme={theme} />
      </Stack>
    </Stack>
  );
});

export { DashboardBaseTileEditActions };

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  show: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Backdrop = React.forwardRef<HTMLDivElement, Props>(({ className, show, ...rest }, ref) => {
  if (!show) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cn('absolute h-full w-full bg-black bg-opacity-40', className)} ref={ref} {...rest} />
  );
});

Backdrop.displayName = 'Backdrop';

export { Backdrop };

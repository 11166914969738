import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DollarNoteFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M5.5 4C5.5 3.58579 5.83579 3.25 6.25 3.25H17.25C17.6642 3.25 18 3.58579 18 4C18 4.41421 17.6642 4.75 17.25 4.75H6.25C5.83579 4.75 5.5 4.41421 5.5 4Z'
      fill='currentColor'
    />
    <path
      d='M4.75 5.75C4.33579 5.75 4 6.08579 4 6.5C4 6.91421 4.33579 7.25 4.75 7.25H18.75C19.1642 7.25 19.5 6.91421 19.5 6.5C19.5 6.08579 19.1642 5.75 18.75 5.75H4.75Z'
      fill='currentColor'
    />
    <path
      d='M11.476 13.0722H11.1544C10.8134 13.0722 10.537 13.3486 10.537 13.6895C10.537 14.0304 10.8134 14.3068 11.1544 14.3068H11.476V13.0722Z'
      fill='currentColor'
    />
    <path
      d='M12.513 15.3439V16.928H12.6599C13.0973 16.928 13.452 16.5734 13.452 16.136C13.452 15.6985 13.0973 15.3439 12.6599 15.3439H12.513Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.25 8.25C3.00736 8.25 2 9.25736 2 10.5V19.5C2 20.7426 3.00736 21.75 4.25 21.75H19.75C20.9926 21.75 22 20.7426 22 19.5V10.5C22 9.25736 20.9926 8.25 19.75 8.25H4.25ZM3.5 10.5C3.5 10.0858 3.83579 9.75 4.25 9.75H6.61519C6.22058 11.1797 5.00055 12.267 3.5 12.467V10.5ZM3.5 17.533V19.5C3.5 19.9142 3.83579 20.25 4.25 20.25H6.61519C6.22058 18.8203 5.00055 17.733 3.5 17.533ZM17.3848 20.25H19.75C20.1642 20.25 20.5 19.9142 20.5 19.5V17.533C18.9994 17.733 17.7794 18.8203 17.3848 20.25ZM20.5 12.467V10.5C20.5 10.0858 20.1642 9.75 19.75 9.75H17.3848C17.7794 11.1797 18.9994 12.267 20.5 12.467ZM12.513 11.5185C12.513 11.2321 12.2809 11 11.9945 11C11.7081 11 11.476 11.2321 11.476 11.5185V12.0351H11.1544C10.2407 12.0351 9.5 12.7758 9.5 13.6895C9.5 14.6032 10.2407 15.3439 11.1544 15.3439H11.476V16.928H10.3949C10.1085 16.928 9.87638 17.1602 9.87638 17.4466C9.87638 17.7329 10.1085 17.9651 10.3949 17.9651H11.476V18.4815C11.476 18.7679 11.7081 19 11.9945 19C12.2809 19 12.513 18.7679 12.513 18.4815V17.9651H12.6599C13.6701 17.9651 14.489 17.1461 14.489 16.136C14.489 15.1258 13.6701 14.3068 12.6599 14.3068H12.513V13.0722H13.5941C13.8805 13.0722 14.1126 12.84 14.1126 12.5537C14.1126 12.2673 13.8805 12.0351 13.5941 12.0351H12.513V11.5185Z'
      fill='currentColor'
    />
  </svg>
);

export { DollarNoteFilled };

import env from '@shared/config';
import { getCurrentDateTimeString, isDev, normaliseTag } from '@shared/utils';

declare const performance: { now: () => number };

enum LogType {
  PERFORMANCE = 'debug',
  DEBUG = 'debug',
  LOG = 'log',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum PerformanceType {
  RENDER = 'RENDER',
}

interface LogOptions {
  tag: string;
  message: string;
  extra: any;
  breadCrumb: boolean;
  logType: LogType;
  timestamp: boolean | string;
}

function flattenArgs(...params: any[]) {
  return params.filter((e: any) => e !== null && e !== undefined);
}

function innerLog(params: LogOptions) {
  // normalise log tag format
  const tag = normaliseTag(params.tag);

  // add to crashes breadcrumbs
  if (!isDev && params.breadCrumb) {
    // Web TODO
  }

  if (isDev) {
    // Format timestamp
    let timestamp;
    if (params.timestamp) {
      timestamp = `[${getCurrentDateTimeString()}]`;
    }
    // flatten log data
    const logData = flattenArgs(tag, timestamp, params.message, params.extra);
    // if dev log the detail
    console[params.logType](...logData);
  }
}

function perf(tag: string, message: string, extra?: any, timestamp = false) {
  if (env.PERFORMANCE_LOG) {
    const extendedExtra = Object.assign(extra ?? {}, { _ts: performance.now() });
    innerLog({
      tag: `PERFORMANCE::${tag}`,
      message,
      extra: extendedExtra,
      breadCrumb: false,
      logType: LogType.DEBUG,
      timestamp,
    });
  }
}

function debug(tag: string, message: string, extra?: any, timestamp = false) {
  innerLog({ tag, message, extra, breadCrumb: false, logType: LogType.DEBUG, timestamp });
}

function log(tag: string, message: string, extra?: any, timestamp = false) {
  innerLog({ tag, message, extra, breadCrumb: true, logType: LogType.LOG, timestamp });
}

function info(tag: string, message: string, extra?: any, timestamp = false) {
  innerLog({ tag, message, extra, breadCrumb: true, logType: LogType.INFO, timestamp });
}

function warn(tag: string, message: string, extra?: any, timestamp = false) {
  innerLog({ tag, message, extra, breadCrumb: true, logType: LogType.WARN, timestamp });
}

function error(tag: string, message: string, extra?: any, timestamp = false) {
  innerLog({ tag, message, extra, breadCrumb: true, logType: LogType.ERROR, timestamp });
}

const logger = {
  perf,
  debug,
  log,
  info,
  warn,
  error,
};

export default logger;

export enum ProfileRoute {
  ProfileInformation = 'profile-information',
  ProfileVerification = 'profile-verification',
  ProfileSettings = 'profile-settings',
  ProfileSecurity = 'profile-security',
  ProfileTradeFees = 'profile-trade-fees',
  ProfileAffiliateProgram = 'profile-affiliate-program',
  ProfileReferAFriend = 'profile-refer-a-friend',
  ProfileApiKeys = 'profile-api-keys',
  ProfileTaxReports = 'profile-tax-reports',
  ProfileEntityVerification = 'profile-entity-verification',
  ProfileEntitySecurity = 'profile-entity-security',
  ProfileEntityLinkedMembers = 'profile-entity-linked-members',
}

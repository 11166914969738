import { useCallback } from 'react';

import { DateTime } from 'luxon';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import TaxReportService from 'src/services/TaxReportService';

export const useTransactionReports = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const useNewEndpoint = isFeatureEnabled(AppFeature.TransactionReport);

  const generateTransactionReport = useCallback(
    (from: DateTime, to: DateTime, reportType: 'pdf' | 'csv') =>
      TaxReportService.DownloadTransactionReport(useNewEndpoint, from, to, reportType),
    [useNewEndpoint],
  );

  return { generateTransactionReport };
};

import React, { useCallback, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Camera } from '@swyftx/aviary/icons/outlined';

import { DateTime } from 'luxon';

import { TradingViewContext } from '../../../context/TradingView.context';
import { isAdvancedChartState, isSimpleChartState } from '../../../utils/Tradingview.utils';

const TradingViewWidgetHeaderScreenshot: React.FC = () => {
  const { chartState, primary, secondary } = useContext(TradingViewContext);

  const buildScreenshotFromBlob = useCallback(
    (source: 'tradingview' | 'simple', blob: Blob) => {
      const fileName = `${secondary}_${primary}_${source}_${DateTime.now().toISO({ includeOffset: false })}.png`;
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    },
    [primary, secondary],
  );

  const handleScreenshot = useCallback(() => {
    if (isAdvancedChartState(chartState)) {
      chartState.takeClientScreenshot().then((canvas) => {
        canvas.toBlob((blob) => {
          if (!blob) return;

          buildScreenshotFromBlob('tradingview', blob);
        });
      });
    } else if (isSimpleChartState(chartState)) {
      chartState.takeScreenshot().toBlob((blob: Blob | null) => {
        if (!blob) return;

        buildScreenshotFromBlob('simple', blob);
      });
    }
  }, [buildScreenshotFromBlob, chartState]);

  return (
    <Button
      size='md'
      variant='ghost'
      color='subtle'
      layout='icon'
      leadingIcon={<Camera />}
      tooltip='Take a screenshot'
      onClick={handleScreenshot}
    />
  );
};

export { TradingViewWidgetHeaderScreenshot };

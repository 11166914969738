import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { SmsfCorporateTrustee } from '@shared/api';

import { SmsfCorporateTrusteeWithUuid } from '@routes/EntityOnboarding/hooks/useSmsfTrusteeStep';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';

import { CorporateTrusteeSchema } from '../schemas/CorporateSchema';

interface Props {
  onCancel?: () => void;
  onSubmit: (data: SmsfCorporateTrustee) => void;
  trustee?: SmsfCorporateTrusteeWithUuid;
}

type FormInputs = SmsfCorporateTrustee;

export const AddCorporateTrustee: React.FC<Props> = (props) => {
  const { onCancel, onSubmit, trustee: trusteeWithUuid } = props;

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      type: 'CORPORATE',
      name: trusteeWithUuid?.corporate.name,
    },
    resolver: zodResolver(CorporateTrusteeSchema, undefined, { rawValues: true }),
  });

  const name = watch('name');

  const handleSubmit = useCallback(() => {
    const corporateTrustee: SmsfCorporateTrustee = {
      type: 'CORPORATE',
      name,
    };
    onSubmit(corporateTrustee);
  }, [name, onSubmit]);

  const submitDisabled = !!Object.keys(errors).length || !name;

  return (
    <FlexLayout direction='column' spacing={24}>
      {!trusteeWithUuid && (
        <FlexLayout direction='row' className='w-full items-center justify-between'>
          <Heading size='h6'>Add corporate trustee</Heading>
        </FlexLayout>
      )}

      <FlexLayout direction='column' spacing={4} className='w-full'>
        <Body size='small' weight='emphasis'>
          Company name:
        </Body>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              error={!!errors.name}
              placeholder='eg. Acme Pty Ltd'
            />
          )}
        />
        {errors.name && (
          <Body size='small' color='error'>
            {errors.name.message}
          </Body>
        )}
      </FlexLayout>

      <FlexLayout direction='row' className='w-full items-center justify-end' spacing={8}>
        <Button variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={submitDisabled}>
          {trusteeWithUuid ? 'Edit' : 'Add'} company
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useContext, useEffect } from 'react';

import { UIStore, UserStore } from '@shared/store';

import { LoginContext } from '@routes/Login/Login.context';

import { observer } from 'mobx-react-lite';
import { useGlobalSearch } from 'src/lib/search/hooks';

import { LoginSteps } from './Login.data';

const Login: React.FC = observer(() => {
  const { setRecaptchaToken, setRefreshRecaptcha } = UserStore.useUserStore;
  const { setGlobalModalData } = UIStore.useUIStore;
  const { loginStep } = useContext(LoginContext);
  const { closeGlobalSearch } = useGlobalSearch();

  useEffect(() => {
    setRecaptchaToken('');
    setRefreshRecaptcha(true);
    setGlobalModalData(undefined);
    closeGlobalSearch();
  }, [setGlobalModalData, setRecaptchaToken, setRefreshRecaptcha, closeGlobalSearch]);

  return LoginSteps[loginStep];
});

Login.displayName = 'Login';

export default Login;

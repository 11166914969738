import React, { useMemo } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Slider } from '@swyftx/aviary/atoms/Slider/Slider';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { Switch } from '@swyftx/aviary/icons/outlined';

import { AssetType } from '@shared/api';
import { formatNumberWithCommasAndAllowTrailingDot } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useSwyftxProTradeAssets } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeTotal } from 'src/lib/trade-pro/hooks/useSwyftxProTradeTotal';

type Props = {
  error: React.ReactElement | null;
};

const SwyftxProTradeTotalInput: React.FC<Props> = observer(({ error }) => {
  const { limitAsset, baseAsset, selectedAsset } = useSwyftxProTradeAssets();

  const {
    title,
    placeholder,
    sliderValue,
    showSlider,
    showSwapLimitAsset,
    value,
    updateSliderValue,
    onChangeValue,
    toggleLimitAsset,
  } = useSwyftxProTradeTotal();

  const tooltip = useMemo(() => {
    if (!showSwapLimitAsset || !limitAsset || !selectedAsset || !baseAsset) return undefined;

    if (limitAsset.code === baseAsset?.code) return `Set an amount of ${selectedAsset.code}`;
    if (limitAsset.code === selectedAsset.code) return `Set total spend in ${baseAsset.code}`;

    return undefined;
  }, [baseAsset, limitAsset, selectedAsset, showSwapLimitAsset]);

  const formattedValue = useMemo(() => {
    if (!value) return '';

    const prefix = limitAsset?.assetType === AssetType.Fiat ? '$' : '';

    return formatNumberWithCommasAndAllowTrailingDot(value, prefix);
  }, [limitAsset, value]);

  return (
    <FlexLayout direction='column' spacing={4}>
      <Input
        containerClassName='bg-color-background-surface-primary hover:bg-color-background-surface-primary focus-within:!bg-color-background-surface-primary hover:outline-color-border-hover focus-within:!outline-color-border-accent outline-color-border-main outline'
        className='text-right font-mono text-color-text-primary'
        error={!!error}
        leading={
          <Tooltip title={tooltip} align='center'>
            <FlexLayout
              alignItems='center'
              spacing={4}
              className={cn(
                'text-color-text-primary',
                showSwapLimitAsset ? 'pointer-events-auto relative cursor-pointer hover:text-color-text-accent' : '',
              )}
              onClick={toggleLimitAsset}
            >
              <Body weight='emphasis' className='uppercase' size='medium'>
                {title}
              </Body>
              {showSwapLimitAsset && <Switch className='h-14 w-14' />}
            </FlexLayout>
          </Tooltip>
        }
        placeholder={placeholder}
        value={formattedValue}
        onChange={(e) => onChangeValue(e.target.value)}
        trailing={
          <Numeric size='small' color='primary' className='whitespace-nowrap'>
            {limitAsset?.code}
          </Numeric>
        }
      />
      <Body color='error' size='small'>
        {error}
      </Body>
      {showSlider && (
        <FlexLayout direction='column' className='w-full' spacing={4}>
          <Slider value={sliderValue} onValueChange={updateSliderValue} defaultValue={0} marks={[0, 25, 50, 75, 100]} />
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Body
              color='primary'
              size='xsmall'
              className='cursor-pointer hover:underline'
              onClick={() => updateSliderValue([0])}
            >
              0%
            </Body>
            <Body
              color='primary'
              size='xsmall'
              className='cursor-pointer hover:underline'
              onClick={() => updateSliderValue([100])}
            >
              100%
            </Body>
          </FlexLayout>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export { SwyftxProTradeTotalInput };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Meme: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.08724 8.59342C5.8111 8.59342 5.58724 8.81728 5.58724 9.09342C5.58724 10.4698 6.6337 11.6496 7.99947 11.6496C9.36524 11.6496 10.4117 10.4698 10.4117 9.09342C10.4117 8.81728 10.1878 8.59342 9.91167 8.59342H6.08724ZM7.99947 10.6496C7.40086 10.6496 6.8539 10.2234 6.66091 9.59342H9.338C9.14502 10.2234 8.59806 10.6496 7.99947 10.6496Z'
      fill='currentColor'
    />
    <path
      d='M4.57424 5.13794C4.72012 4.90348 5.02845 4.83167 5.26291 4.97756L6.45448 5.71896C6.60098 5.81012 6.69013 5.97036 6.69033 6.14291C6.69053 6.31545 6.60176 6.47591 6.45547 6.5674L5.26391 7.31269C5.02979 7.45912 4.72129 7.38804 4.57486 7.15392C4.42842 6.9198 4.4995 6.6113 4.73362 6.46487L5.24567 6.1446L4.73462 5.82662C4.50016 5.68073 4.42835 5.3724 4.57424 5.13794Z'
      fill='currentColor'
    />
    <path
      d='M11.2669 5.82662C11.5014 5.68074 11.5732 5.37241 11.4273 5.13794C11.2815 4.90348 10.9731 4.83167 10.7387 4.97755L9.54706 5.71896C9.40055 5.81011 9.3114 5.97036 9.3112 6.14291C9.311 6.31545 9.39977 6.47591 9.54606 6.56741L10.7377 7.31269C10.9718 7.45912 11.2803 7.38804 11.4267 7.15392C11.5731 6.9198 11.5021 6.6113 11.2679 6.46487L10.7559 6.1446L11.2669 5.82662Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 1.5C4.40991 1.5 1.5 4.40991 1.5 8C1.5 11.5894 4.40987 14.5 8 14.5C11.5901 14.5 14.5 11.5894 14.5 8C14.5 4.40991 11.5901 1.5 8 1.5ZM2.5 8C2.5 4.9622 4.9622 2.5 8 2.5C11.0378 2.5 13.5 4.9622 13.5 8C13.5 11.0372 11.0378 13.5 8 13.5C4.96223 13.5 2.5 11.0372 2.5 8Z'
      fill='currentColor'
    />
  </svg>
);

export { Meme };

import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { cn } from '@shared/utils/lib/ui';

import { UniversalTradeHeader } from 'src/lib/UniversalTrade/components/Header/UniversalTradeHeader';
import { TradePageVariant } from 'src/lib/UniversalTrade/universalTradePage.consts';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SwyftxProTradeContext } from '../../context';

type Props = {
  className?: string;
  baseNavigationUrl?: NavigationURLs;
};

const SwyftxProAssetDetails: React.FC<Props> = ({ className, baseNavigationUrl }) => {
  const { selectedAsset, tradeDirection } = useContext(SwyftxProTradeContext);

  if (!selectedAsset) return null;

  return (
    <FlexLayout
      className={cn('w-full bg-color-background-surface-primary px-8 py-0 @container', className)}
      alignItems='center'
      justifyContent='center'
    >
      <UniversalTradeHeader
        variant={TradePageVariant.CLASSIC}
        selectedAsset={selectedAsset}
        baseNavigationUrl={baseNavigationUrl}
        priceSide={tradeDirection === 'buy' ? 'ask' : 'bid'}
      />
    </FlexLayout>
  );
};

export { SwyftxProAssetDetails };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode';

import { Asset } from '@shared/api';
import { AppStore } from '@shared/store';

import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { useToggle } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { WalletPerformanceDropdown, WalletPortfolioPerformanceChart } from './components';

type Props = {
  walletType: WalletType;
};

const WalletPortfolioPerformance: React.FC<Props> = observer(() => {
  const { t } = useTranslation('wallet', { keyPrefix: 'performance' });
  const { isDemo } = AppStore.useAppStore;
  const [selectedAssets, setSelectedAssets] = useState<Map<string, Asset>>(new Map());
  const [costBasis, toggleCostBasis] = useToggle(false);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const costBasisEnabled = isFeatureEnabled(AppFeature.CostBasis);

  return (
    <FlexLayout direction='column' className='h-full'>
      <FlexLayout justifyContent='space-between' direction='row' className='p-12' alignItems='center'>
        <Body weight='bold'>{t('title')}</Body>
        {!isDemo && (
          <FlexLayout direction='row' spacing={8}>
            {costBasisEnabled && (
              <Button variant='filled' size='md' color='subtle' onClick={toggleCostBasis}>
                {costBasis ? t('costBasis.disable') : t('costBasis.enable')}
              </Button>
            )}
            <WalletPerformanceDropdown selectedAssets={selectedAssets} setSelectedAssets={setSelectedAssets} />
          </FlexLayout>
        )}
      </FlexLayout>
      <div className='relative'>
        <Separator className='w-full' />
      </div>
      {!isDemo && (
        <WalletPortfolioPerformanceChart selectedAssets={selectedAssets} costBasis={costBasis && costBasisEnabled} />
      )}
      {isDemo && (
        <FlexLayout direction='column' className='h-full' alignItems='center' justifyContent='center'>
          <UnavailableDemoMode subTitle={t('demoMode') as string} showImage />
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export { WalletPortfolioPerformance };

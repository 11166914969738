import React from 'react';
import { useTranslation } from 'react-i18next';

import { TickInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { Modals } from '../../Modals.enum';
import { useModal } from '../../useModal.hooks';

type Props = {
  triggerType: TriggerType;
  originalOrderUuid: string;
  onClose: () => void;
};

const ProfitStopLossSuccessModal: React.FC<Props> = ({ triggerType, onClose, originalOrderUuid }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLossSuccess' });
  const { openModal } = useModal();
  const { navigate } = useNavigateRoute();

  const onSetOppositeOrder = () => {
    const inverseTriggerType = triggerType === TriggerType.StopLoss ? TriggerType.TakeProfit : TriggerType.StopLoss;
    openModal(Modals.ProfitStopLossModal, { orderUuid: originalOrderUuid, focusedTriggerType: inverseTriggerType });
  };

  const onViewOpenOrders = () => {
    navigate(NavigationURLs.TransactionsRoot);
    onClose();
  };

  return (
    <Modal
      id='profit-stop-loss-success-modal'
      open
      sx={{ width: '400px' }}
      FooterProps={{
        divider: true,
        content: (
          <Stack width='100%' spacing={1} color='text.secondary'>
            <Button variant='contained' onClick={onSetOppositeOrder} size='large'>
              {t('buttonLabels.anotherOrder')}
            </Button>
            <Button variant='outlined' color='inherit' onClick={onViewOpenOrders} size='large'>
              {t('buttonLabels.viewOpenOrders')}
            </Button>
            <Button variant='outlined' color='inherit' onClick={onClose} size='large'>
              {t('buttonLabels.close')}
            </Button>
          </Stack>
        ),
      }}
      onClose={onClose}
    >
      <Stack spacing={2} alignItems='center'>
        <TickInCircleFilled className='h-[80px] w-[80px] text-color-text-success' />
        <Typography fontSize={20} fontWeight={700} color='text.primary'>
          {t('contentTitle')}
        </Typography>
        <Typography fontSize={16} fontWeight={400} color='text.secondary' textAlign='center'>
          {t(triggerType === TriggerType.StopLoss ? 'contentStopLoss' : 'contentProfit')}
        </Typography>
      </Stack>
    </Modal>
  );
};

export { ProfitStopLossSuccessModal };

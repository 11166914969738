import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Heading, Utility } from '@swyftx/aviary/atoms/Typography';
import { Loading } from '@swyftx/aviary/icons/outlined';
import { formatCurrency } from '@swyftx/currency-util';

import { FeeTier } from '@shared/api/@types/fees';
import { Links } from '@shared/constants';

import * as Sentry from '@sentry/react';

interface TradeFeesTableProps {
  feeTiers?: FeeTier[];
  currentTier?: FeeTier;
  unlockedTier?: FeeTier;
  updatesIn?: string;
}

const TradeFeesTable: React.FC<TradeFeesTableProps> = (props) => {
  const { feeTiers, currentTier, unlockedTier, updatesIn } = props;
  const { t } = useTranslation('profile');

  const FeeTierRow = ({ item, index }: { item: FeeTier; index: number }) => (
    <Fragment key={item.tier}>
      <FlexLayout direction='row' className='py-12'>
        <FlexLayout direction='row' className='w-1/3'>
          <Body weight='emphasis'>{item.label}</Body>
        </FlexLayout>
        <FlexLayout direction='row' className='w-1/3'>
          <Body weight='none'>
            {index === 0 && item.upperBound !== null
              ? `< ${formatCurrency(item.upperBound.toString())}`
              : `≥ ${formatCurrency(item.lowerBound.toString())}`}
          </Body>
        </FlexLayout>
        <FlexLayout direction='row' className='flex w-1/3 items-center justify-between @container'>
          <Body color='secondary'>{item.fee} %</Body>
          {item.tier === currentTier?.tier && (
            <Chip size='sm' variant='default'>
              {t('feeTiers.current')}
            </Chip>
          )}
          {item.tier === unlockedTier?.tier && unlockedTier.tier > (currentTier?.tier ?? 1) && (
            <Chip size='sm' variant='subtle' color='success'>
              {t('feeTiers.unlocked', { updatesIn })}
            </Chip>
          )}
        </FlexLayout>
      </FlexLayout>
      {index + 1 < (feeTiers?.length ?? 0) && <Separator />}
    </Fragment>
  );

  return (
    <FlexLayout direction='column' spacing={8}>
      <FlexLayout direction='column' spacing={0}>
        <Heading size='h5'>{t('feeTiers.titles.table')}</Heading>
        <Body color='secondary'>
          <Trans
            t={t}
            i18nKey='feeTiers.description'
            components={[
              <a
                key={Links.help.feeTiers}
                href={Links.help.feeTiers}
                target='_blank'
                className='text-color-link-main'
                rel='noopener noreferrer'
              />,
            ]}
          />
        </Body>
      </FlexLayout>
      <Card id='trade-fees-table-card' className='w-full'>
        <CardContent className='grid w-full py-0'>
          <FlexLayout direction='row' className='w-full pt-24'>
            <Utility color='secondary' className='w-1/3'>
              {t('feeTiers.table.headers.tier')}
            </Utility>
            <Utility color='secondary' className='w-1/3'>
              {t('feeTiers.table.headers.volume')}
            </Utility>
            <Utility color='secondary' className='w-1/3'>
              {t('feeTiers.table.headers.fee')}
            </Utility>
          </FlexLayout>
          {!feeTiers?.length && (
            <FlexLayout className='w-full p-24' alignItems='center' justifyContent='center'>
              <Loading className='w-20 animate-spin' />
            </FlexLayout>
          )}
          {feeTiers && feeTiers.map((item, index) => <FeeTierRow item={item} index={index} key={item.tier} />)}
        </CardContent>
      </Card>
    </FlexLayout>
  );
};

TradeFeesTable.displayName = 'TradeFeesTable';

const ProfileTradeFeesTable = Sentry.withProfiler(TradeFeesTable);

export { ProfileTradeFeesTable as TradeFeesTable };

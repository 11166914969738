import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@swyftx/aviary/atoms/Select';
import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';

import { StorageKey } from '@shared/storage';

const AutoLogoutOptions: React.FC = () => {
  const [autoLogoutOption, setAutoLogoutOption] = useState<string>(
    window.localStorage.getItem(StorageKey.AUTO_LOGOUT) || '30',
  );
  const { t } = useTranslation('common');

  const autoLogoutOptions: SelectItemData[] = [
    { label: `30 ${t('labels.minute')}s`, value: '30' },
    { label: `1 ${t('labels.hour')}`, value: '60' },
    { label: `4 ${t('labels.hour')}s`, value: '240' },
    { label: `12 ${t('labels.hour')}s`, value: '720' },
    { label: `24 ${t('labels.hour')}s`, value: '1440' },
  ];

  const handleOnSelect = (option: string) => {
    setAutoLogoutOption(option);
    window.localStorage.setItem(StorageKey.AUTO_LOGOUT, option);
  };

  return (
    <Select
      items={autoLogoutOptions}
      value={autoLogoutOption}
      placeholder='Select an auto logout time'
      onValueChange={(e) => handleOnSelect(e)}
    />
  );
};

export { AutoLogoutOptions };

import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

export type AssetPickerTabTypes = 'all' | 'owned' | 'favourites' | 'gainers' | 'losers' | 'new';

export type MarketTab = EnhancedTab<AssetPickerTabTypes>;

export type AssetPickerTableData = {
  asset: string;
  buyPrice: string;
  dailyChange: string;
  weeklyChange: string;
  monthlyChange: string;
  dailyVolume: string;
};

export const assetPickerTabs: MarketTab[] = [
  {
    title: 'All assets',
    value: 'all',
  },
  {
    title: 'Owned',
    value: 'owned',
  },
  {
    title: 'Favourites',
    value: 'favourites',
  },
  {
    title: 'Gainers',
    value: 'gainers',
  },
  {
    title: 'Losers',
    value: 'losers',
  },
  {
    title: 'New',
    value: 'new',
  },
];

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { Stack } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';
import { useMultiAssetData } from '@hooks/Assets/useMultiAssetData';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { usePaginatedScroll } from '@hooks/usePaginatedScroll';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';
import { AssetRow, AssetRowHeader } from '@Dashboard/components/Tiles/components';

import { observer } from 'mobx-react-lite';
import { useMeasure, useSize } from 'react-use';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { FavouritesTileEmptyState } from './components';
import { AssetRowSort } from '../components/AssetRowHeader';
import { useSortAssetRow } from '../components/useSortAssetRow';

const ASSET_ROW_HEIGHT = 36;
const ASSET_ROW_SPACING = 8;
const ITEMS_PER_PAGE = 20;

const FavouritesTile: React.FC<DashboardProps> = observer(({ tile, dragProps }) => {
  const [sort, setSort] = useState<AssetRowSort>({ sort: 'rank', direction: 'asc' });
  const baseAsset = useBaseAsset();
  const { t } = useTranslation('dashboard');
  const { bx } = useContentBreakpoint();
  const { navigateToAsset } = useAssetNavigation();

  const favouriteAssetList = useFavouriteAssets();
  const { sortedData } = useSortAssetRow({ data: favouriteAssetList, sort });

  const { userCountryCurrencyCode } = UserStore.useUserStore;
  const { scrollRef, newItems } = usePaginatedScroll(sortedData, ITEMS_PER_PAGE);

  const [ref, { height, width }] = useMeasure();
  const [heightSize] = useSize(<Box width={0} height={bx({ xs: '220px', md: '440px' })} />);

  const assetData = useMultiAssetData({
    assets: newItems,
    baseAsset: baseAsset?.code ?? userCountryCurrencyCode,
    side: 'ask',
    resolution: '1h',
  });

  return (
    <DashboardBaseTile
      tileName={tile.name}
      dragProps={dragProps}
      title={t('favouritesTile.title')}
      contentSx={{ height: '100%' }}
      noPadding
    >
      <Box height='100%' width='100%' position='relative' display='flex' ref={ref}>
        {heightSize}
        <Stack maxHeight={height} direction='column' width='100%' spacing={0}>
          <AssetRowHeader
            width={width || 0}
            isFavourite
            sortingKeys={['rank', 'dailyChange', 'weeklyChange', 'monthlyChange']}
            sort={sort}
            onSort={setSort}
          />
          <Box sx={{ overflowY: 'auto' }} ref={scrollRef} className='favourites-container'>
            <Stack spacing={`${ASSET_ROW_SPACING}px`} flex={1} padding={0}>
              {sortedData.map((favouriteAsset: Asset) => (
                <AssetRow
                  height={ASSET_ROW_HEIGHT}
                  width={width || 0}
                  asset={favouriteAsset}
                  key={favouriteAsset.id}
                  isFavourite
                  baseAsset={baseAsset}
                  idKey='favourites'
                  assetData={assetData[favouriteAsset.id]}
                  onSelectAsset={(selectedAsset: Asset) => navigateToAsset(selectedAsset, false)}
                />
              ))}
              {favouriteAssetList.length === 0 && <FavouritesTileEmptyState width={width || 0} />}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </DashboardBaseTile>
  );
});

FavouritesTile.displayName = 'FavouritesTile';

export { FavouritesTile };

import { useCallback } from 'react';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { CategoryRowItem } from 'src/types/categories.types';

const useCreateCategoryRowItem = () => {
  const { getAssetsByCategoryId } = useMarkets();

  const createCategoryRowItem = useCallback(
    (category: Category): CategoryRowItem => {
      const { getRateOnce } = RatesStore.useRatesStore;
      const assets: Asset[] = getAssetsByCategoryId(category.id);

      let mcap = Big(0);
      let vol = Big(0);
      let changeDay = Big(0);
      let changeWeek = Big(0);
      let changeMonth = Big(0);

      assets.forEach((asset) => {
        const rate = getRateOnce(asset);
        vol = vol.plus(asset.volume[FiatIdEnum.AUD].day);
        mcap = mcap.plus(Big(rate.midPrice).times(asset.circulatingSupply));
        changeDay = changeDay.plus(rate.dailyPriceChange);
        changeWeek = changeWeek.plus(asset.priceChange.week);
        changeMonth = changeMonth.plus(asset.priceChange.month);
      });

      return {
        rank: '',
        categoryName: category.name,
        categoryDescription: category.description,
        assets,
        numAssets: assets.length,
        '24h': changeDay.div(assets.length).toString(),
        '7d': changeWeek.div(assets.length).toString(),
        '30d': changeMonth.div(assets.length).toString(),
        mcap: mcap.toString(),
        vol: vol.toString(),
        category,
      };
    },
    [getAssetsByCategoryId],
  );

  return { createCategoryRowItem };
};

export { useCreateCategoryRowItem };

import { useCallback, useContext, useEffect } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { AssetHistoryItem, OrderType } from '@shared/api';
import { Big } from '@shared/safe-big';

import { ISeriesApi, SeriesMarker, UTCTimestamp } from 'lightweight-charts';
import { DateTime } from 'luxon';

import { TradingViewContext } from '../../context/TradingView.context';
import { TradingViewMarkerTransactionStatus } from '../../types';

type Props = {
  series?: ISeriesApi<'Area' | 'Bar' | 'Candlestick' | 'Line'>;
  transactions?: AssetHistoryItem[];
  timeStart: DateTime;
  timeEnd: DateTime;
};

const useSimpleChartMarkers = ({ series, transactions, timeStart, timeEnd }: Props) => {
  const { showMarks, simpleResolutionMinutes } = useContext(TradingViewContext);
  const { isDarkMode } = useTailwindTheme();

  const getTransactionType = useCallback((transaction: AssetHistoryItem): TradingViewMarkerTransactionStatus => {
    if (!transaction.orderType) return undefined;

    const isBuy = [OrderType.StopBuy, OrderType.MarketBuy, OrderType.OTCBuy, OrderType.TriggerBuy].includes(
      transaction.orderType,
    );

    return isBuy ? 'buy' : 'sell';
  }, []);

  const getMarkerColor = useCallback(
    (transaction: AssetHistoryItem) => {
      const transactionType = getTransactionType(transaction);

      const buyColor = getComputedStyle(document.body).getPropertyValue('--color-background-success');

      const sellColor = getComputedStyle(document.body).getPropertyValue('--color-background-error');

      const pendingColor = getComputedStyle(document.body).getPropertyValue('--color-background-info');

      if (transactionType === 'buy') {
        return buyColor;
      } else if (transactionType === 'sell') {
        return sellColor;
      } else {
        return pendingColor;
      }
    },
    [getTransactionType],
  );

  const getMarkerText = useCallback(
    (transaction: AssetHistoryItem) => {
      const transactionType = getTransactionType(transaction);

      if (transactionType === 'buy') {
        return 'B';
      } else if (transactionType === 'sell') {
        return 'S';
      } else {
        return 'O';
      }
    },
    [getTransactionType],
  );

  useEffect(() => {
    if (!series) return;

    const mapTransactionsToMarkers = async () => {
      if (!transactions) return;

      const markers: Map<string, SeriesMarker<UTCTimestamp>> = new Map();

      transactions
        .sort((a, b) => Big(a.date).cmp(b.date))
        .forEach((transaction) => {
          if (!transaction.orderType) return;

          let date = DateTime.fromMillis(transaction.date);

          if (date.toMillis() < timeStart.toMillis()) return;

          date = date.plus({ minutes: DateTime.now().offset }).minus({ minute: simpleResolutionMinutes });

          const text = getMarkerText(transaction);
          const color = getMarkerColor(transaction);

          markers.set(`${date}_${text}`, {
            id: getTransactionType(transaction),
            time: (Date.parse(date.toISO()) / 1000) as UTCTimestamp,
            position: 'aboveBar',
            color,
            shape: 'circle',
            text: '',
          });
        });

      series.setMarkers(Array.from(markers.values()));
    };

    if (showMarks) {
      mapTransactionsToMarkers();
    } else {
      series.setMarkers([]);
    }
  }, [
    transactions,
    series,
    showMarks,
    isDarkMode,
    timeStart,
    timeEnd,
    getMarkerText,
    getMarkerColor,
    getTransactionType,
    simpleResolutionMinutes,
  ]);
};

export { useSimpleChartMarkers };

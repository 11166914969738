export type NewsItem = {
  id: number;
  title: string;
  newsUrl: string;
  sourceName: string;
  authorName: string;
  assets: string[];
  publishedAt: number;
  createdAt: number;
  imageHero: string;
  imageThumbnail: string;
  imageMobileThumbnail: string;
  excerpt: string;
};

export type NewsServiceResponse = {
  data: NewsItem[];
  links: {
    next: string;
  };
};

export enum NewsTileView {
  List = 'List',
  Thumbnail = 'Thumbnail',
}

export enum NewsTileTab {
  MyNews = 'My News',
  All = 'All',
}

export const NEWS_TILE_TAB_SELECTION = 'news_tile_tab_selection';
export const NEWS_TILE_PREFERRED_VIEW = 'news_tile_preferred_view';

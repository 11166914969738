import React, { useContext, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';

import { Big } from '@shared/safe-big';

import { SwyftxProTradeContext } from 'src/lib/trade-pro/context';
import { useSwyftxProTradeStopLossDetails } from 'src/lib/trade-pro/hooks/useSwyftxProTradeStopLoss';
import { useSwyftxProTradeTakeProfitDetails } from 'src/lib/trade-pro/hooks/useSwyftxProTradeTakeProfit';

import { SwyftxProOrderConfirmationModal } from '../../SwyftxProOrderConfirmationModal';

type Props = {
  hasError: boolean;
  size?: 'sm' | 'md' | 'lg';
};

const SwyftxProTradeReviewButton: React.FC<Props> = ({ hasError, size = 'md' }) => {
  const {
    tradeType,
    tradeDirection,
    triggerPrice,
    total,
    amount,
    stopLossPrice,
    takeProfitPrice,
    showMarketTakeProfit,
    showMarketStopLoss,
  } = useContext(SwyftxProTradeContext);

  const { takeProfitError, takeProfitTotal } = useSwyftxProTradeTakeProfitDetails();
  const { stopLossError, stopLossTotal } = useSwyftxProTradeStopLossDetails();

  const disabled = useMemo(() => {
    if (!triggerPrice || hasError) return true;

    switch (tradeType) {
      case 'limit':
      case 'market':
        return (
          !total ||
          Big(total).eq(0) ||
          !amount ||
          Big(amount).eq(0) ||
          !!takeProfitError ||
          !!stopLossError ||
          (showMarketTakeProfit && takeProfitTotal.lte(0)) ||
          (showMarketStopLoss && stopLossTotal.lte(0))
        );
      case 'oco':
        return !total || Big(total).eq(0) || !stopLossPrice || !takeProfitPrice || !!takeProfitError || !!stopLossError;
      default:
        return true;
    }
  }, [
    amount,
    hasError,
    showMarketStopLoss,
    showMarketTakeProfit,
    stopLossError,
    stopLossPrice,
    stopLossTotal,
    takeProfitError,
    takeProfitPrice,
    takeProfitTotal,
    total,
    tradeType,
    triggerPrice,
  ]);

  return (
    <SwyftxProOrderConfirmationModal>
      <Button color={tradeDirection === 'buy' ? 'success' : 'destructive'} disabled={disabled} size={size}>
        Review order
      </Button>
    </SwyftxProOrderConfirmationModal>
  );
};

export { SwyftxProTradeReviewButton };

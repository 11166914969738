import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { BuySellButtons } from '@global-components/BuySellButtons';
import { useAppSideBar } from '@global-components/Navigation/useAppSideBar';

import { Asset } from '@shared/api';
import env from '@shared/config';
import { UIStore, UniversalTradeStore, UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';

import { XsUniversalTradePage } from './XsUniversalTradePage';
import { UniversalTradeContent } from './components/Content/UniversalTradeContent';
import { FullScreenTradePanel } from './components/TradePanel/FullScreenTradePanel';
import { isAdvanced, TradePageVariant, variantClass } from './universalTradePage.consts';
import { useMarkets } from '../markets/hooks/useMarkets';
import { useNavigateRoute } from '../navigation/hooks';
import { TradingViewSide } from '../trade/components/TradingViewWidget/types';
import { DEFAULT_TRADINGVIEW_PRICE_SIDE } from '../trade/components/TradingViewWidget/types/tradingViewGlobalState';
import { useTradePageAnalytics } from '../trade/hooks/TradePage/useTradePageAnalytics';

export const UniversalTradePage: React.FC = observer(() => {
  const { state } = useAppSideBar();
  const { params } = useNavigateRoute();
  const { asset: assetCode } = params as { asset: string };
  const { getAssetByCode } = useMarkets();
  const asset = useMemo(() => getAssetByCode(assetCode.toUpperCase()), []);
  const { setCustomBreadcrumb } = UIStore.useUIStore;
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(asset);
  const [tradePanelOpen, setTradePanelOpen] = useState<boolean>(false);
  const [variant] = useState<TradePageVariant>(TradePageVariant.CLASSIC);
  const usersnapApi = useUsersnapApi();
  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');
  const isVariantAdvanced = isAdvanced(variant);
  const variantClassName = variantClass(isVariantAdvanced);
  const [priceSide, setPriceSide] = useState<TradingViewSide>(DEFAULT_TRADINGVIEW_PRICE_SIDE);
  const { viewedUniversalTradePage } = useTradePageAnalytics();
  const { userProfile } = UserStore.useUserStore;
  const { cleanup } = UniversalTradeStore;

  const openUserSnap = useCallback(() => {
    usersnapApi?.on('open', (event) => event.api.setValue('visitor', userProfile?.email));
    usersnapApi?.show(env.USERSNAP_PROJECT_ID_UTP).then((widgetApi) => widgetApi.open({}));
  }, [userProfile?.email, usersnapApi]);

  useEffect(() => {
    if (!isSm && !isXs) {
      setCustomBreadcrumb(assetCode.toLowerCase(), <Body color='primary'>{assetCode}</Body>);
    } else {
      setCustomBreadcrumb(assetCode.toLowerCase(), <></>);
      setCustomBreadcrumb('trade', <Body color='primary'>Trade</Body>);
    }
    return () => {
      setCustomBreadcrumb('trade', undefined);
      setCustomBreadcrumb(assetCode.toLowerCase(), undefined);
    };
  }, [assetCode, isSm, isXs, openUserSnap, setCustomBreadcrumb]);

  useEffect(() => {
    const assetFromParams = getAssetByCode(assetCode);
    if (!assetFromParams) {
      return;
    }

    setSelectedAsset(assetFromParams);
  }, [assetCode, getAssetByCode, params]);

  useEffect(() => {
    viewedUniversalTradePage();
  }, []);

  const onPriceSideChange = (side: TradingViewSide) => {
    setPriceSide(side);
  };

  if (!selectedAsset) return null;

  if (isXs)
    return (
      <XsUniversalTradePage
        variant={variant}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        priceSide={priceSide}
        setPriceSide={onPriceSideChange}
      />
    );

  return (
    <div
      className={cn(
        state === 'docked' ? 'w-[calc(100% - 240px)]' : 'w-[calc(100% + 56px)]',
        'flex h-full w-full bg-color-background-pageBG @container',
      )}
    >
      {tradePanelOpen && (
        <FullScreenTradePanel
          onClose={() => {
            cleanup();
            setTradePanelOpen(false);
          }}
        />
      )}
      {!tradePanelOpen && (
        <>
          <UniversalTradeContent
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            variant={variant}
            isAdvanced={isVariantAdvanced}
            variantClass={variantClassName}
            priceSide={priceSide}
            setPriceSide={onPriceSideChange}
          />
          <FlexLayout
            className='absolute bottom-0 z-10 flex h-60 w-full items-center bg-color-background-surface-primary px-8 @sm:hidden'
            direction='row'
          >
            <BuySellButtons asset={selectedAsset} size='lg' postClick={() => setTradePanelOpen(true)} />
          </FlexLayout>
        </>
      )}
    </div>
  );
});

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ChartTypeHighLow: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5938 9.23053V14.7695H17.4062V9.23053H14.5938ZM14.0938 7.73053H17.9062C18.4585 7.73053 18.9062 8.17825 18.9062 8.73053V15.2695C18.9062 15.8218 18.4585 16.2695 17.9062 16.2695H14.0938C13.5415 16.2695 13.0938 15.8218 13.0938 15.2695V8.73053C13.0938 8.17825 13.5415 7.73053 14.0938 7.73053Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.59375 7.53125V16.4688H9.40625V7.53125H6.59375ZM6.09375 6.03125H9.90625C10.4585 6.03125 10.9062 6.47897 10.9062 7.03125V16.9688C10.9062 17.521 10.4585 17.9688 9.90625 17.9688H6.09375C5.54147 17.9688 5.09375 17.521 5.09375 16.9688V7.03125C5.09375 6.47897 5.54147 6.03125 6.09375 6.03125Z'
      fill='currentColor'
    />
  </svg>
);

export { ChartTypeHighLow };

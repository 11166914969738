import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';

import { Asset } from '@shared/api';
import { Links } from '@shared/constants';
import { FiatIdEnum, LowLiquidityMax } from '@shared/enums';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';

type Props = {
  selectedAsset: Asset;
  baseAsset: Asset;
};

const SwyftxProTradeLowLiquidityWarning: React.FC<Props> = observer(({ selectedAsset, baseAsset }) => {
  const { convertRate } = RatesStore.useRatesStore;

  const maximumAmount = useMemo(
    () => convertRate(FiatIdEnum.AUD, baseAsset, LowLiquidityMax.AUD),
    [convertRate, baseAsset],
  );

  return (
    <FlexLayout spacing={8} alignItems='start' justifyContent='start'>
      <AlertFilled className='!h-24 !w-24' />
      <div>
        <Body className='inline' size='small' weight='emphasis'>
          {selectedAsset.code}
        </Body>
        <Body className='inline' size='small'>
          {' '}
          is impacted by{' '}
        </Body>
        <BodyLink
          size='small'
          href={Links.help.lowLiquidityAndLimited}
          className='ml-2 inline cursor-pointer underline decoration-dashed decoration-1 underline-offset-4'
        >
          low liquidity.
        </BodyLink>
        <Body className='ml-2 inline' size='small'>
          Order size is limited to a maximum of{' '}
          {formatCurrency(maximumAmount, baseAsset, { hideCode: true, appendCode: false })} {baseAsset.code}
        </Body>
      </div>
    </FlexLayout>
  );
});

export { SwyftxProTradeLowLiquidityWarning };

import { AutocompleteOption } from '@global-components/AutocompleteDropdown';

import { EntityMembershipType } from '@shared/api/@types/entity';
import { APIKeyScopeItem } from '@shared/api/@types/user';
import { CountriesEnum, EntityTypeEnum, GreenIdStatusEnum } from '@shared/enums';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';

import { Countries } from '@utils/countries';

import ct from 'countries-and-timezones';

export interface SelectableMemberScopeItem extends APIKeyScopeItem {
  expanded: boolean;
  locked: boolean;
  selected: boolean;
}

export enum EntityUserStatus {
  VERIFIED = 'Verified',
  STEPS_PENDING = 'Steps pending',
  ACTIONS_PENDING = 'Actions pending',
  INVITE_EXPIRED = 'Invite expired',
}

export enum MemberPermissionType {
  Admin = 'Admin',
  ReadOnly = 'Read Only',
  TradeOnly = 'Trade Only',
  None = 'None',
  Custom = 'Custom',
}

const permissions = [
  MemberPermissionType.Admin,
  MemberPermissionType.TradeOnly,
  MemberPermissionType.ReadOnly,
  MemberPermissionType.None,
];

export interface ScopeGroup {
  parent: SelectableMemberScopeItem;
  children: SelectableMemberScopeItem[];
}

export interface GroupedScope {
  [key: string]: ScopeGroup;
}

const scopeDependants = {
  'orders.create': {
    deps: [
      { group: 'account', scopes: ['balance', 'read'] },
      { group: 'orders', scopes: ['read'] },
    ],
    message: 'Required to create orders',
  },
  'funds.withdraw': {
    deps: [
      { group: 'account', scopes: ['balance', 'read'] },
      { group: 'address', scopes: ['read'] },
      { group: 'funds', scopes: ['read', 'withdrawal-limit'] },
    ],
    message: 'Required to withdraw funds',
  },
};

const presets = [
  {
    name: MemberPermissionType.ReadOnly,
    scopes: [
      'account.tax-report',
      'account.balance',
      'account.read',
      'address.read',
      'funds.read',
      'orders.read',
      'api.read',
      'recurring-orders.read',
    ],
  },
  {
    name: MemberPermissionType.TradeOnly,
    scopes: [
      'account.tax-report',
      'orders',
      'account.balance',
      'account.read',
      'address.read',
      'funds.read',
      'recurring-orders.read',
    ],
  },
  {
    name: MemberPermissionType.Admin,
    scopes: ['account', 'recurring-orders', 'address', 'funds', 'orders', 'api'],
  },
  {
    name: MemberPermissionType.None,
    scopes: [],
  },
];

const getPermissionPreset = (scopes: string): MemberPermissionType => {
  let allReadOnly = false;
  let allTradeOnly = false;
  let allAdmin = false;

  if (!scopes) return MemberPermissionType.None;

  presets.forEach((preset) => {
    if (preset.scopes.every((scope) => scopes.includes(scope))) {
      switch (preset.name) {
        case MemberPermissionType.Admin:
          allAdmin = true;
          break;
        case MemberPermissionType.TradeOnly:
          // Bad hack so we show correct scope but not to affect preselected preset in update permissions screen
          allTradeOnly = scopes.includes('orders.dust');
          break;
        case MemberPermissionType.ReadOnly:
          allReadOnly = true;
          break;
        default:
          // Custom or None
          break;
      }
    }

    if (preset.scopes.some((scope) => scopes.includes(scope))) {
      switch (preset.name) {
        case MemberPermissionType.Admin:
          break;
        case MemberPermissionType.TradeOnly:
          break;
        default:
          // ReadOnly, Custom or None
          break;
      }
    }
  });

  if (allReadOnly && allTradeOnly && allAdmin) {
    return MemberPermissionType.Admin;
  }

  if (allReadOnly && allTradeOnly) {
    return MemberPermissionType.TradeOnly;
  }

  if (allReadOnly) {
    return MemberPermissionType.ReadOnly;
  }

  return MemberPermissionType.Custom;
};

const formatDisplayName = (name: string) => {
  const parts = name.split(' ');
  parts.forEach((part, i) => {
    parts[i] = part[0].toUpperCase() + part.slice(1);
  });
  return parts.join(' ');
};

const getSelectedPermission = (localScopes: GroupedScope): string[] => {
  // return string array of selected permissions
  const selectedPermissions: string[] = [];
  for (const group of Object.values(localScopes)) {
    if (group.parent.selected) selectedPermissions.push(group.parent.key);
    for (const child of group.children) {
      if (child.selected) selectedPermissions.push(child.key);
    }
  }
  return selectedPermissions;
};

const toggleAll = (
  groupedScopes: GroupedScope,
  select: boolean,
  setGroupedScopes: (groupedScopes: GroupedScope) => void,
) => {
  const localScopes = { ...groupedScopes };

  // set all scopes
  for (const scope of Object.values(localScopes)) {
    scope.parent.selected = select;
    for (const child of scope.children) {
      child.selected = select;
    }
  }

  // reset all scopes lock status
  resetAllScopes(localScopes);

  if (select) {
    // set all required read scopes
    checkReadScopes(localScopes);
    // check dependants
    checkDependants(localScopes);
  }

  setGroupedScopes(localScopes);
};

const resetAllScopes = (localScopes: GroupedScope) => {
  for (const group of Object.values(localScopes)) {
    group.parent.locked = false;
    for (const child of group.children) {
      child.locked = false;
    }
  }
};

const checkReadScopes = (localScopes: GroupedScope) => {
  for (const group of Object.values(localScopes)) {
    const hasSelection = group.children.find((child) => child.selected);
    if (hasSelection && hasSelection.key !== `${group.parent.key}.read`) {
      const readScope = group.children.find((child) => child.key === `${group.parent.key}.read`);
      if (readScope) {
        readScope.selected = true;
        readScope.locked = true;
      }
    }
  }
};

const checkDependants = (localScopes: GroupedScope) => {
  for (const [scope, value] of Object.entries(scopeDependants)) {
    const group = scope.split('.')[0];
    const selected = localScopes[group].children.find((child) => child.key === `app.${scope}` && child.selected);

    if (selected) {
      for (const dep of value.deps) {
        for (const currentScope of dep.scopes) {
          const dependantScope = localScopes[dep.group].children.find(
            (child) => child.key === `app.${dep.group}.${currentScope}`,
          );
          if (dependantScope) {
            dependantScope.selected = true;
            dependantScope.locked = true;
          }
        }
      }
    }
  }
};

const getKeyType = (localScopes: GroupedScope) => {
  const selectedPermissions = getSelectedPermission(localScopes);

  for (const preset of presets) {
    let scopeCount = 0;
    let selectedCount = 0;
    for (const scope of preset.scopes) {
      // get number of permissions covered by the scope
      let count = 1;
      const isParentScope = scope.split('.').length === 1;
      if (isParentScope) {
        count += localScopes[scope].children.length;
      }
      scopeCount += count;
      const hasScope = !!selectedPermissions.find((perm) => perm === `app.${scope}`);
      if (hasScope) {
        selectedCount += count;
      } else {
        selectedCount = 0;
        break;
      }
    }
    // is using a preset when we have the same number of selected permissions as the scope requires
    // and they are the total number of permissions we have selected
    if (scopeCount === selectedCount && scopeCount === selectedPermissions.length) {
      return preset.name;
    }
  }
  return MemberPermissionType.Custom;
};

const getKeyTypeFromKey = (scope: string): MemberPermissionType => {
  for (const preset of presets) {
    const keyScopes = scope.split(' ');
    let hasMissing = false;

    for (const presetScope of preset.scopes) {
      const search = `app.${presetScope}`;

      let hasMatch = false;
      for (let i = 0; i < keyScopes.length; i++) {
        if (keyScopes[i].includes(search)) {
          hasMatch = true;
          keyScopes.splice(i--, 1);
        }
      }

      if (!hasMatch) {
        hasMissing = true;
        break;
      }
    }

    if (!hasMissing) {
      // remove app and offline_access parent scopes as they are automatically added on the server
      for (let i = 0; i < keyScopes.length; i++) {
        if (keyScopes[i] === 'app' || keyScopes[i] === 'offline_access') {
          keyScopes.splice(i--, 1);
        }
      }
      if (!keyScopes.length) return preset.name;
    }
  }

  return MemberPermissionType.Custom;
};

const isInviteExpired = (member?: EntityMember) =>
  member ? member.entityType === EntityTypeEnum.INVITE && !!member.isExpired : false;

const isUnderage = (member?: EntityMember) =>
  member
    ? member.entityType !== EntityTypeEnum.INVITE && member.verified === GreenIdStatusEnum.VERIFIED_UNDERAGE
    : false;

const isAllMembersVerified = (members?: EntityMember[]) => {
  if (!members) return false;

  let allMembersVerified = true;

  for (const member of members) {
    if (member.verified !== GreenIdStatusEnum.VERIFIED) {
      allMembersVerified = false;
    }
  }

  return allMembersVerified;
};

export const MappedEntityUserStatus = {
  [GreenIdStatusEnum.NOT_STARTED]: EntityUserStatus.STEPS_PENDING,
  [GreenIdStatusEnum.IN_PROGRESS]: EntityUserStatus.STEPS_PENDING,
  [GreenIdStatusEnum.PENDING_REVIEW]: EntityUserStatus.STEPS_PENDING,

  [GreenIdStatusEnum.VERIFIED]: EntityUserStatus.VERIFIED,
  [GreenIdStatusEnum.VERIFIED_UNDERAGE]: EntityUserStatus.VERIFIED,

  [GreenIdStatusEnum.FAILED]: EntityUserStatus.ACTIONS_PENDING,
  [GreenIdStatusEnum.FAILED_DUPLICATE]: EntityUserStatus.ACTIONS_PENDING,
};

const entityMembershipTypes = {
  [EntityTypeEnum.COMPANY]: [
    EntityMembershipType.None,
    EntityMembershipType.Director,
    EntityMembershipType.MajorShareholder,
  ],
  [EntityTypeEnum.SMSF]: [EntityMembershipType.None, EntityMembershipType.Beneficiary, EntityMembershipType.Trustee],
  [EntityTypeEnum.TRUST]: [EntityMembershipType.None, EntityMembershipType.Beneficiary, EntityMembershipType.Trustee],
  [EntityTypeEnum.OTHER]: [EntityMembershipType.None],
};

const defaultCountry = ct.getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0]?.id as
  | CountriesEnum
  | CountriesEnum.AU;

const defaultCountryOption = {
  value: defaultCountry,
  label: 'Australia',
};

const countryOptions = Object.values(Countries).map((country) => ({
  value: country.code,
  label: country.name,
}));

// Commented out options aren't in designs -- keeping them just in case
const entityTypeOptions: Array<AutocompleteOption<EntityTypeEnum>> = [
  // { value: EntityTypeEnum.PERSONAL, label: 'Personal' },
  { value: EntityTypeEnum.COMPANY, label: 'Company' },
  { value: EntityTypeEnum.SMSF, label: 'SMSF' },
  // { value: EntityTypeEnum.JOINT, label: 'Joint' },
  // { value: EntityTypeEnum.SOLETRADER, label: 'Sole Trader' },
  { value: EntityTypeEnum.TRUST, label: 'Trust' },
  // { value: EntityTypeEnum.OTHER, label: 'Other' },
  // { value: EntityTypeEnum.INVITE, label: 'Invite' },
];

const defaultEntityTypeOption = entityTypeOptions[0];
const defaultEntityType = entityTypeOptions[0].value;

const entityHelpers = {
  defaultEntityTypeOption,
  entityMembershipTypes,
  defaultCountryOption,
  entityTypeOptions,
  defaultEntityType,
  scopeDependants,
  defaultCountry,
  countryOptions,
  permissions,
  presets,
  getSelectedPermission,
  isAllMembersVerified,
  getPermissionPreset,
  formatDisplayName,
  getKeyTypeFromKey,
  checkDependants,
  checkReadScopes,
  isInviteExpired,
  resetAllScopes,
  getKeyType,
  isUnderage,
  toggleAll,
};

export default entityHelpers;

import { useState } from 'react';

import { TriggerType } from '../../types/profitStopLoss.types';

const DEFAULT_TRIGGER_TYPE = TriggerType.StopLoss;

const useProfitStopLossTriggerType = (triggerTypeOverride?: TriggerType) => {
  const [triggerType, setTriggerType] = useState<TriggerType>(triggerTypeOverride || DEFAULT_TRIGGER_TYPE);

  return {
    triggerType,
    setTriggerType,
  };
};

export { useProfitStopLossTriggerType };

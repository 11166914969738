import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Notification } from '@swyftx/react-web-design-system';

import { GreenIdStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useOnboardingService } from '@Onboarding/Onboarding.context';

import { useIntercom } from 'react-use-intercom';

const IdVerificationError: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const { show: showLiveChat } = useIntercom();
  const onboardingService = useOnboardingService();

  const { userProfile } = UserStore.useUserStore;

  const greenIdStatus = userProfile?.verification?.greenid?.status;

  const renderContent = (status?: GreenIdStatusEnum) => {
    switch (status) {
      case GreenIdStatusEnum.FAILED_DUPLICATE:
        return (
          <Notification severity='error' title={t('steps.idVerification.messages.failedDuplicateIdTitle')}>
            {t('steps.idVerification.messages.failedDuplicateId')}
          </Notification>
        );
      case GreenIdStatusEnum.FAILED:
      default:
        return (
          <Notification severity='error' title={t('steps.idVerification.messages.failedGenericTitle')}>
            {t('steps.idVerification.messages.failedGeneric')}
          </Notification>
        );
    }
  };

  const handleClick = () => {
    showLiveChat();
    onboardingService.send('SAVE_AND_EXIT');
  };

  return (
    <>
      {renderContent(greenIdStatus)}

      <Button variant='contained' onClick={handleClick} sx={{ marginTop: 4 }}>
        {t('labels.continue')}
      </Button>
    </>
  );
};

export { IdVerificationError };

import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Button, Input, Modal, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { UIStore } from '@shared/store';

import AbnValidator from '@hooks/validator/types/abn.validator';
import { useValidation } from '@hooks/validator/useValidation';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

type Props = {
  onClose: () => void;
  isUpdate?: boolean;
};

const ABN_LENGTH = 11;

const AffiliateSignUpModal: React.FC<Props> = observer(({ onClose, isUpdate = false }) => {
  const {
    error,
    hasAbn,
    setHasAbn,
    abn,
    setAbn,
    noAbnGstReason,
    setNoAbnGstReason,
    gstRegistered,
    setGstRegistered,
    becomeAffiliate,
    fetchAffiliateInfo,
  } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const { addMessageBox } = UIStore.useUIStore;

  const { t } = useTranslation('profile.affiliates', { keyPrefix: 'becomeAffiliateModal' });

  const onSuccess = () => {
    addMessageBox({
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      content: <SuccessMessageBox title={t('labels.onSuccess')} />,
    });
    fetchAffiliateInfo();
    onClose();
  };

  const onError = () => {
    setLoading(false);
  };

  const validatorProps = useValidation(
    [new AbnValidator(t('errors.validAbn'))],
    false,
    (e) => setAbn(e.target.value),
    abn,
  );

  const createAccountDisabled = useMemo(() => {
    const abnValid = !hasAbn || (hasAbn && abn !== undefined && abn.length >= ABN_LENGTH && !validatorProps.error);
    const abnReasonValid = hasAbn || (!hasAbn && noAbnGstReason !== undefined);
    const gstReasonValid = gstRegistered || (!gstRegistered && noAbnGstReason !== undefined);

    return !abnValid || !abnReasonValid || !gstReasonValid;
  }, [hasAbn, abn, gstRegistered, noAbnGstReason, validatorProps.error]);

  const handleCreate = () => {
    setLoading(true);
    becomeAffiliate(isUpdate, onSuccess, onError);
  };

  return (
    <Modal
      open
      HeaderProps={{ title: t(isUpdate ? 'setupTitle' : 'title'), dismissible: true, divider: true }}
      FooterProps={{
        content: (
          <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
            <Button variant='text' color='inherit' size='large' onClick={onClose}>
              {t('labels.cancel')}
            </Button>
            <Button
              disabled={createAccountDisabled}
              onClick={handleCreate}
              variant='contained'
              loading={loading}
              disableElevation
              color='primary'
              size='large'
            >
              {t(isUpdate ? 'buttonLabels.submit' : 'buttonLabels.createAccount')}
            </Button>
          </Stack>
        ),
        divider: true,
      }}
      sx={{ width: '600px', maxHeight: '90%', '.MuiCardContent-root': { height: '100%' } }}
      onClose={onClose}
    >
      <Stack direction='column' spacing={3}>
        <Typography color='text.secondary' fontSize={16}>
          {t(isUpdate ? 'updateSubTitle' : 'subTitle')}
        </Typography>
        <FormControl>
          <FormLabel
            id='demo-controlled-radio-buttons-group'
            sx={{ fontWeight: 500, fontSize: 14, color: 'text.primary' }}
          >
            {t('labels.haveAbn')}
          </FormLabel>
          <RadioGroup
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={hasAbn}
            row
            onChange={(e) => setHasAbn(Number((e.target as HTMLInputElement).value))}
          >
            <FormControlLabel value={1} control={<Radio />} label={t('labels.yes')} labelPlacement='end' />
            <FormControlLabel value={0} control={<Radio />} label={t('labels.no')} labelPlacement='end' />
          </RadioGroup>
        </FormControl>
        {hasAbn === 1 && (
          <>
            <Input label={t('labels.abn')} placeholder={t('labels.enterAbn')} {...validatorProps} />
            <FormControl>
              <FormLabel
                id='demo-controlled-radio-buttons-group'
                sx={{ fontWeight: 500, fontSize: 14, color: 'text.primary' }}
              >
                {t('labels.gstRegistered')}
              </FormLabel>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={gstRegistered}
                row
                onChange={(e) => setGstRegistered(Number((e.target as HTMLInputElement).value))}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                  label={t('labels.yes')}
                  labelPlacement='end'
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                  label={t('labels.no')}
                  labelPlacement='end'
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
        {(gstRegistered === 0 || hasAbn === 0) && (
          <FormControl>
            <FormLabel sx={{ fontWeight: 500, fontSize: 14, color: 'text.primary', marginBottom: 1 }}>
              {!hasAbn ? t(isUpdate ? 'labels.updateWhyNoAbn' : 'labels.whyNoAbn') : t('labels.whyNoGst')}
            </FormLabel>
            <RadioGroup
              value={noAbnGstReason}
              onChange={(e) => setNoAbnGstReason(Number((e.target as HTMLInputElement).value))}
            >
              <FormControlLabel
                value={2}
                sx={{ '.MuiTypography-root': { fontSize: 14, marginBottom: 1 } }}
                control={<Radio />}
                label={t('labels.noAbnGstReason1')}
                labelPlacement='end'
              />
              <FormControlLabel
                value={3}
                sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                control={<Radio />}
                label={t('labels.revenue')}
                labelPlacement='end'
              />
            </RadioGroup>
          </FormControl>
        )}
        {error && (
          <Notification
            severity='error'
            title={error}
            sx={{
              marginTop: 2,
              marginBottom: 2,
              width: '100%',
              '.MuiAlertTitle-root': {
                fontSize: 14,
                fontWeight: 600,
                color: 'error.dark',
              },
            }}
          />
        )}
      </Stack>
    </Modal>
  );
});

export { AffiliateSignUpModal };

import { useContext, useEffect } from 'react';

import { getPriceScale } from '@swyftx/currency-util';

import { Asset } from '@shared/api';
import { ratesService } from '@shared/services';

import { useMeasure } from '@react-hookz/web';
import { ColorType, createChart } from 'lightweight-charts';

import { TradingViewContext } from '../../context/TradingView.context';

type Props = {
  className?: string;
  secondaryAsset?: Asset;
};

const useCreateSimpleChart = ({ className, secondaryAsset }: Props) => {
  const { setSimpleChartState, simpleChartState } = useContext(TradingViewContext);
  const [size, chartContainerRef] = useMeasure<HTMLInputElement>();

  useEffect(() => {
    if (!simpleChartState || !size) return;

    simpleChartState.applyOptions({
      width: size.width,
      height: size.height,
    });
  }, [simpleChartState, size]);

  useEffect(() => {
    if (!chartContainerRef.current || !secondaryAsset) return;

    const amount = ratesService.getRate(secondaryAsset.id).midPrice;

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: {
          type: ColorType.Solid,
          color: getComputedStyle(document.body).getPropertyValue('--color-background-surface-primary'),
        },
        textColor: getComputedStyle(document.body).getPropertyValue('--color-text-primary'),
      },
      grid: {
        vertLines: {
          color: getComputedStyle(document.body).getPropertyValue('--color-background-surface-primary'),
        },
        horzLines: {
          color: getComputedStyle(document.body).getPropertyValue('--color-background-surface-primary'),
        },
      },
      timeScale: {
        secondsVisible: true,
        timeVisible: true,
      },
      localization: {
        priceFormatter: (p: number) => `${Number(p.toString()).toFixed(getPriceScale(amount))}`,
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
    });

    chart.timeScale().fitContent();

    setSimpleChartState(chart);

    return () => {
      chart.remove();
      setSimpleChartState(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainerRef, className, setSimpleChartState, secondaryAsset]);

  return {
    chartContainerRef: chartContainerRef as React.MutableRefObject<HTMLInputElement>,
  };
};

export { useCreateSimpleChart };

import React, { PropsWithChildren, useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Utility } from '@swyftx/aviary/atoms/Typography';
import { Hide } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { useAvo } from '@hooks/Avo/useAvo';

import { useLocation } from 'react-router';

import { useSwyftxProLayout } from '../../hooks/useSwyftxProLayout';

const SwyftxProOrderBookActionsMenu: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { setShowOrderBook } = useSwyftxProLayout();
  const avo = useAvo();
  const location = useLocation();

  const handleShowOrderBook = useCallback(() => {
    avo.hideWidgetAction({ buttonName: 'orderbook', swyftxPro: 'true', screen: location.pathname });
    setShowOrderBook(false);
  }, [avo, location.pathname, setShowOrderBook]);

  return (
    <Menu triggerContent={children} align='end' hideArrow>
      <FlexLayout direction='column' spacing={8}>
        <Utility>Widget Settings</Utility>
        <MenuItem icon={<Hide />} text='Hide order book widget' action={handleShowOrderBook} preventCloseOnSelect />
      </FlexLayout>
    </Menu>
  );
};

export { SwyftxProOrderBookActionsMenu };

import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { ModalName } from 'src/context/Avo/generated-avo';

type Props = {
  onClose?: () => void;
};

const LoanPostApplicationPopup: React.FC<PropsWithChildren<Props>> = observer(({ children, onClose }) => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      triggerElement={children}
      position={isXs ? 'bottom' : 'center'}
      className='max-w-[500px]'
      contentClassName=''
      open
      onClose={onClose}
    >
      <div className='space-y-32 p-24'>
        <h2 className='text-lg mb-1 text-[1.125rem]'>Thank you for your interest</h2>
        <Notification severity='warning' title='Loan product coming soon!'>
          We&apos;re thrilled you&apos;ve taken the first step toward applying for a loan. However, our loan product is
          still in development and we are only taking expressions of interest at this time.
        </Notification>

        <div>
          <h2 className='text-lg mb-1 font-semibold text-primary'>Want to be first in line if we launch?</h2>
          <p className='text-color-text-tertiary'>
            Join our early access program and stay updated on when our loan product goes live!
          </p>
        </div>

        <div className='space-y-12'>
          <Button
            className='w-full'
            size='lg'
            onClick={() => {
              avo.clickedButton({
                screen: pathname,
                buttonName: 'Opt-in to early access',
                modalName: ModalName.LOAN_POST_APPLICATION_POPUP,
                parentComponent: null,
                tableName: null,
              });
              onClose?.();
            }}
          >
            Opt-in to early access
          </Button>

          <Button
            className='w-full'
            size='lg'
            variant='outlined'
            onClick={() => {
              avo.clickedButton({
                screen: pathname,
                buttonName: 'No thanks',
                modalName: ModalName.LOAN_POST_APPLICATION_POPUP,
                parentComponent: null,
                tableName: null,
              });
              onClose?.();
            }}
          >
            No thanks
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export { LoanPostApplicationPopup };

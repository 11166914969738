import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ImmiCardIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <rect
        x='3.667'
        y='5.333'
        width='24.667'
        height='21.333'
        rx='3'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='17.333'
        y='9.667'
        width='6.667'
        height='8.333'
        rx='1'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='.4'
        d='M8 10h6M8 14h6M8 18h4M8 22h16'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

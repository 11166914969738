export enum TriggerType {
  TakeProfit = 'takeProfit',
  StopLoss = 'stopLoss',
}

export enum ProfitLossValueMethod {
  AmountTarget = 'target',
  TriggerPrice = 'triggerPrice',
}

export enum ProfitLossValueMeasurement {
  Dollar = 'dollar',
  Percentage = 'percentage',
}

export enum ProfitLossCalculationType {
  AmountTarget = 'target',
  TriggerPrice = 'triggerPrice',
}

export enum TriggerCalculationType {
  Dollar = 'dollar',
  Percentage = 'percentage',
}

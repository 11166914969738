import React, { PropsWithChildren, useState } from 'react';

import { Modal } from '@swyftx/aviary/atoms/Modal';

import { SwyftxProTradePanel } from './SwyftxProTradePanel';

const SwyftxProTradePanelModal: React.FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState<boolean>();

  return (
    <Modal
      position='bottom'
      triggerElement={children}
      className='h-[calc(100vh-3rem)]'
      open={open}
      onOpenChange={setOpen}
    >
      <SwyftxProTradePanel onClose={() => setOpen(false)} size='lg' />
    </Modal>
  );
};

export { SwyftxProTradePanelModal };

import { useContext, useMemo } from 'react';

import { SelectItemData } from '@swyftx/aviary/atoms/Select/Select.types';

import { SwyftxTradeType } from 'src/lib/trade/types/Trade.types';

import { SwyftxProTradeContext } from '../context';

const useSwyftxProTradeType = () => {
  const { tradeType, setTradeType } = useContext(SwyftxProTradeContext);

  const items = useMemo(
    (): SelectItemData<SwyftxTradeType>[] => [
      {
        value: 'market',
        label: 'Market',
      },
      {
        value: 'limit',
        label: 'Trigger',
      },
      {
        value: 'oco',
        label: 'OCO',
      },
    ],
    [],
  );

  const isMarket = useMemo(() => tradeType === 'market', [tradeType]);
  const isLimit = useMemo(() => tradeType === 'limit', [tradeType]);
  const isOco = useMemo(() => tradeType === 'oco', [tradeType]);

  return {
    items,
    tradeType,
    isMarket,
    isLimit,
    isOco,
    setTradeType,
  };
};

export { useSwyftxProTradeType };

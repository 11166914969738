import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Delete, Edit } from '@swyftx/aviary/icons/outlined';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { DynamicPriceAlertsVolatilitySwitch } from 'src/lib/dynamic-price-alerts/components';

import { PriceAlert } from '../../dynamicPriceAlerts.types';
import { useDynamicPriceAlert } from '../../hooks/useDynamicPriceAlert';
import { isLimitAlert, isVolatilityAlert } from '../../utils/dynamic-price-alerts.utils';
import { DynamicPriceAlertIcon } from '../DynamicPriceAlertIcon';
import { DynamicPriceAlertTitle } from '../DynamicPriceAlertTitle';

type Props = {
  alert: PriceAlert;
  onEditAlert: (alert: PriceAlert) => void;
  onDeleteAlert: (alert: PriceAlert) => void;
};

const PriceAlertsModalActiveItem: React.FC<Props> = observer(({ alert, onEditAlert, onDeleteAlert }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { primaryAsset, secondaryAsset } = useDynamicPriceAlert({ alert });

  return (
    <ListItem>
      <FlexLayout alignItems='center' justifyContent='space-between' className='w-full py-8'>
        <FlexLayout alignItems='center' spacing={12} onClick={() => onEditAlert(alert)}>
          <DynamicPriceAlertIcon
            type={alert.type}
            showIconOnly
            direction={isLimitAlert(alert) ? alert.direction : undefined}
            asset={secondaryAsset}
          />
          <FlexLayout alignItems='start' direction='column'>
            <DynamicPriceAlertTitle
              primaryAsset={primaryAsset}
              secondaryAsset={secondaryAsset}
              type={alert.type}
              direction={isLimitAlert(alert) ? alert.direction : undefined}
              price={isLimitAlert(alert) ? alert.price : undefined}
              change={isVolatilityAlert(alert) ? alert.change : undefined}
              window={isVolatilityAlert(alert) ? alert.window : undefined}
            />
            <Body size='small' color='secondary'>
              Updated {DateTime.fromMillis(alert.dateUpdated).toFormat('dd LLLL yyyy')}
            </Body>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout alignItems='center' spacing={12}>
          {isFeatureEnabled(AppFeature.DynamicPriceAlerts) && (
            <Button layout='icon' variant='outlined' leadingIcon={<Edit />} onClick={() => onEditAlert(alert)} />
          )}
          <Button layout='icon' variant='outlined' leadingIcon={<Delete />} onClick={() => onDeleteAlert(alert)} />
          <DynamicPriceAlertsVolatilitySwitch alert={alert} />
        </FlexLayout>
      </FlexLayout>
    </ListItem>
  );
});

export { PriceAlertsModalActiveItem };

import React, { useCallback, useState } from 'react';

import { useInterval } from 'react-use';

import { StyledCarouselContainer, StyledCarouselDotContainer, StyledCarouselSlidesContainer } from './Carousel.styled';
import CarouselDot from './dot/CarouselDot';
import { ComponentProps } from '../global-utils';

type Props = {
  interval?: number;
  loop?: boolean;
} & ComponentProps;

const Carousel: React.FC<Props> = ({ id, interval = 5000, loop = true, children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const incrementActiveIndex = useCallback(() => {
    if (activeIndex === React.Children.toArray(children).length - 1) {
      if (loop) {
        setActiveIndex(0);
      }
    } else {
      setActiveIndex(activeIndex + 1);
    }
  }, [activeIndex, children, loop]);

  useInterval(incrementActiveIndex, interval);

  const childrenWithOffsetIndexProp = () => {
    let effectiveIndex = 0;

    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // if for some reason we have an invalid item in the carousel, we want to ignore its array index
        // and build an effectiveIndex based on only valid elements
        const indexOffset = effectiveIndex - activeIndex;
        effectiveIndex += 1;

        return React.cloneElement(child, {
          ...child?.props,
          indexOffset,
          activeIndex,
        });
      }

      return child;
    });
  };

  return (
    <StyledCarouselContainer id={id} key={id}>
      <StyledCarouselSlidesContainer id={`${id}_slides_container`}>
        {childrenWithOffsetIndexProp()}
      </StyledCarouselSlidesContainer>
      <StyledCarouselDotContainer id={`${id}_dot_container`}>
        {React.Children.map(children, (_, index: number) => (
          <CarouselDot
            id={`${id}_carousel_dot_${index}`}
            active={activeIndex === index}
            onClick={() => {
              setActiveIndex(index);
            }}
          />
        ))}
      </StyledCarouselDotContainer>
    </StyledCarouselContainer>
  );
};

export default Carousel;

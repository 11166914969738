import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const PriceAlert: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 1.74988C8.77208 1.74988 5.75 4.77196 5.75 8.49988V9.76015C5.75 11.9347 4.84122 14.0104 3.24332 15.4854C2.76949 15.9227 2.5 16.5383 2.5 17.1831V17.6354C2.5 18.527 3.22283 19.2499 4.11449 19.2499H20.8855C21.7772 19.2499 22.5 18.527 22.5 17.6354V17.1831C22.5 16.5383 22.2305 15.9227 21.7567 15.4854C20.1588 14.0104 19.25 11.9347 19.25 9.76015V8.49988C19.25 4.77196 16.2279 1.74988 12.5 1.74988ZM7.25 8.49988C7.25 5.60038 9.60051 3.24988 12.5 3.24988C15.3995 3.24988 17.75 5.60038 17.75 8.49988V9.76015C17.75 12.3534 18.8337 14.8286 20.7393 16.5876C20.9055 16.741 21 16.9569 21 17.1831V17.6354C21 17.6986 20.9487 17.7499 20.8855 17.7499H4.11449C4.05126 17.7499 4 17.6986 4 17.6354V17.1831C4 16.9569 4.09453 16.741 4.26074 16.5876C6.16626 14.8286 7.25 12.3534 7.25 9.76015V8.49988Z'
      fill='currentColor'
    />
    <path
      d='M10.5774 19.856C10.3006 19.5479 9.82637 19.5226 9.51826 19.7994C9.21016 20.0763 9.18482 20.5505 9.46166 20.8586C11.1397 22.7261 13.787 22.7015 15.4462 20.8592C15.7234 20.5514 15.6986 20.0772 15.3908 19.8C15.083 19.5228 14.6088 19.5476 14.3316 19.8554C13.2624 21.0426 11.6537 21.0539 10.5774 19.856Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.91674 9.78528C9.91674 8.74682 10.7588 7.90542 11.7975 7.90542H14.0821C14.4411 7.90542 14.7321 8.19644 14.7321 8.55542C14.7321 8.91441 14.4411 9.20542 14.0821 9.20542H11.7975C11.4761 9.20542 11.2167 9.46544 11.2167 9.78528C11.2167 10.1064 11.4764 10.3661 11.7975 10.3661H13.2035C14.2416 10.3661 15.0833 11.2078 15.0833 12.2459C15.0833 13.2847 14.242 14.1268 13.2035 14.1268H10.919C10.56 14.1268 10.269 13.8358 10.269 13.4768C10.269 13.1178 10.56 12.8268 10.919 12.8268H13.2035C13.5233 12.8268 13.7833 12.5675 13.7833 12.2459C13.7833 11.9258 13.5236 11.6661 13.2035 11.6661H11.7975C10.7584 11.6661 9.91674 10.8244 9.91674 9.78528Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5003 6.86636C12.8593 6.86636 13.1503 7.15737 13.1503 7.51636V8.55843C13.1503 8.91741 12.8593 9.20843 12.5003 9.20843C12.1413 9.20843 11.8503 8.91741 11.8503 8.55843V7.51636C11.8503 7.15737 12.1413 6.86636 12.5003 6.86636ZM12.5003 12.8259C12.8593 12.8259 13.1503 13.1169 13.1503 13.4759V14.5092C13.1503 14.8682 12.8593 15.1592 12.5003 15.1592C12.1413 15.1592 11.8503 14.8682 11.8503 14.5092V13.4759C11.8503 13.1169 12.1413 12.8259 12.5003 12.8259Z'
      fill='currentColor'
    />
  </svg>
);

export { PriceAlert };

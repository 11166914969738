import React, { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { getPIIClassName, PIITypes } from '@swyftx/react-web-design-system';

import { StyledForm } from './Form.styled';
import { FormProps } from './Form.types';

const Form: React.FC<PropsWithChildren<FormProps & PIITypes>> = ({
  buttonLabel,
  buttonDisabled,
  children,
  error,
  hideButton,
  id,
  onSubmit,
  setSubmitting,
  submitting,
  PII,
  sx,
}) => {
  const elementValidators: { [key: string]: (_force: boolean) => Promise<boolean> } = {};

  const attachForm = async (elementId: string, validate: (_force: boolean) => Promise<boolean>) => {
    elementValidators[elementId] = validate;
  };

  const formValid = async (): Promise<boolean> => {
    let isFormValid = true;
    const keys = Object.keys(elementValidators);

    for (let i = 0; i < keys.length; i += 1) {
      const componentValid = await elementValidators[keys[i]](true);

      if (isFormValid === true && componentValid === false) {
        isFormValid = false;
      }
    }

    return isFormValid;
  };

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    // remove focus on any active elements
    const element = document.activeElement as HTMLElement | undefined;
    if (element) element?.blur();

    if (setSubmitting) setSubmitting(true);

    if (!(await formValid())) {
      if (setSubmitting) setSubmitting(false);
      return false;
    }

    onSubmit();
    return true;
  };

  const getChildrenWithProps = (givenChildren: React.ReactNode): React.ReactNode =>
    React.Children.map(givenChildren, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          attachForm,
        });
      }
      return child;
    });

  return (
    <StyledForm className={getPIIClassName(PII)} onSubmit={handleOnSubmit} id={id} noValidate sx={sx}>
      {getChildrenWithProps(children)}

      {error && <Notification title={error} severity='destructive' className='mb-16 w-full' />}

      {!hideButton && (
        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
          <Button
            id='form_submit'
            size='lg'
            type='submit'
            variant='filled'
            loading={submitting}
            className='w-full'
            disabled={buttonDisabled}
          >
            {buttonLabel}
          </Button>
        </Box>
      )}
    </StyledForm>
  );
};

export { Form };

import React, { PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import { sourceOfWealthMachine } from '@routes/SourceOfWealth/SourceOfWealth.machine';
import { SourceOfWealthStepId } from '@routes/SourceOfWealth/types/SourceOfWealth.types';
import { useSourceOfWealthAnalytics } from '@routes/SourceOfWealth/useSourceOfWealthAnalytics';

import { useSourceOfWealthSelector, useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';

type Props = {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  submitDisabled?: boolean;
  hideTitle?: boolean;
  customTitle?: string;
  customFooterLeftElement?: React.ReactElement;
  backDisabled?: boolean;
  customContinueButtonText?: string;
};

const SourceOfWealthStepContainer: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    onSubmit,
    hideTitle,
    customFooterLeftElement,
    submitDisabled,
    customTitle,
    backDisabled,
    customContinueButtonText,
  } = props;

  const sourceOfWealthService = useSourceOfWealthService();
  const { backClickedOnStep, stepStarted } = useSourceOfWealthAnalytics();

  const stepId = useSourceOfWealthSelector((state) => {
    const [rootStateId, activeStateId] = state.toStrings();

    if (activeStateId != null) {
      const activeStateNode = sourceOfWealthMachine.getStateNodeByPath(activeStateId);
      if (activeStateNode.meta?.step && !activeStateNode.meta?.hidden) {
        return activeStateId as SourceOfWealthStepId;
      }
    }

    return rootStateId as SourceOfWealthStepId;
  });

  const isCompleted = useSourceOfWealthSelector((state) => state.context.completedSteps[stepId]);

  useEffect(() => {
    if (!isCompleted) {
      stepStarted(stepId);
    }
  }, [isCompleted, stepId, stepStarted]);

  const canGoBack = useSourceOfWealthSelector((state) => state.can('BACK'));
  const canContinue = useSourceOfWealthSelector((state) => state.can('NEXT'));
  const hideContinueButton = useSourceOfWealthSelector((state) => state?.meta?.[stepId]?.hideContinueButton) ?? false;
  const titleBackButton = useSourceOfWealthSelector((state) => state?.meta?.[stepId]?.titleBackButton) ?? false;

  const [, setSearchParams] = useSearchParams();

  // Search params used purely for analytics & tracking purposes
  useEffect(() => {
    setSearchParams({ step: stepId });
  }, [stepId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = () => {
    sourceOfWealthService.send('BACK');
    backClickedOnStep(stepId);
  };

  const onSubmitDefault = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sourceOfWealthService.send('NEXT');
  };

  const shouldRender =
    customFooterLeftElement !== undefined || (canGoBack && !titleBackButton) || (canContinue && !hideContinueButton);

  return (
    <form onSubmit={onSubmit ?? onSubmitDefault}>
      <FlexLayout direction='column' className='h-full' spacing={24}>
        {!hideTitle && <Heading size='h3'>{customTitle ?? stepId}</Heading>}

        {children}
        {shouldRender && (
          <FlexLayout direction='row' spacing={16} className='mt-24 flex w-full justify-end'>
            {customFooterLeftElement}
            {canGoBack && !titleBackButton && (
              <Tooltip title={backDisabled ? 'Please wait until upload has finished to go back' : undefined}>
                <Button onClick={handleBack} disabled={backDisabled} size='lg' variant='outlined' className='w-full'>
                  Back
                </Button>
              </Tooltip>
            )}
            {canContinue && !hideContinueButton && (
              <Button type='submit' disabled={submitDisabled} size='lg' className='w-full'>
                {customContinueButtonText || 'Continue'}
              </Button>
            )}
          </FlexLayout>
        )}
      </FlexLayout>
    </form>
  );
};

export { SourceOfWealthStepContainer };

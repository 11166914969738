import React, { useCallback, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Search } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { observer } from 'mobx-react-lite';
import { useMeasure } from 'react-use';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AssetCategoriesTable } from './components/AssetCategoriesTable';
import { assetListTabs, AssetListTabType } from '../AssetList/AssetList.types';

const HEADER_HEIGHT = 2.3 * 16;

export const AssetCategories: React.FC = observer(() => {
  const [selectedTab, setSelectedTab] = useState<AssetListTabType>('categories');
  const { navigate } = useNavigateRoute();
  const [search, setSearch] = useState<string>('');
  const isXs = useTailwindBreakpoint('xs');

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const onSelectPageTab = useCallback(
    (tab: AssetListTabType) => {
      if (tab === 'assets') {
        return navigate(NavigationURLs.AviaryAssetsAll);
      }
      setSelectedTab(tab);
    },
    [navigate],
  );

  return (
    <FlexLayout direction='column' className='h-full p-16 sm:pr-0 sm:pt-24'>
      <Card className='h-full w-full overflow-hidden py-16 @container' ref={ref}>
        <FlexLayout direction='column' spacing={8}>
          <FlexLayout direction='row' className='w-full items-center justify-between px-16'>
            <EnhancedTabs<AssetListTabType> tabs={assetListTabs} onChange={onSelectPageTab} value={selectedTab} />
            <FlexLayout direction='row'>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={isXs ? 'Search...' : 'Search categories...'}
                leading={<Search />}
                containerClassName='w-[8rem] @sm:w-[13rem] @md:w-[16rem]'
                onClear={() => setSearch('')}
              />
            </FlexLayout>
          </FlexLayout>
          <div className='flex flex-col overflow-auto' style={{ height: height - HEADER_HEIGHT }}>
            <AssetCategoriesTable search={search} />
          </div>
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
});

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { SwyftxTradeDirection, SwyftxTradeType } from 'src/lib/trade/types/Trade.types';

type SwyftxProTradeContextType = {
  tradeDirection: SwyftxTradeDirection;
  tradeType: SwyftxTradeType;
  selectedAsset?: Asset;
  baseAsset?: Asset;
  limitAsset?: Asset;
  triggerPrice?: string;
  takeProfitPrice?: string;
  stopLossPrice?: string;
  amount: string;
  total: string;
  showOrderBook?: boolean;
  showOrdersAndBalances?: boolean;
  showMarketTakeProfit?: boolean;
  showMarketStopLoss?: boolean;
  chartLoading: boolean;
  setTradeDirection: React.Dispatch<React.SetStateAction<SwyftxTradeDirection>>;
  setTradeType: React.Dispatch<React.SetStateAction<SwyftxTradeType>>;
  setLimitAsset: React.Dispatch<React.SetStateAction<Asset>>;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setTotal: React.Dispatch<React.SetStateAction<string>>;
  setTriggerPrice: React.Dispatch<React.SetStateAction<string>>;
  setTakeProfitPrice: React.Dispatch<React.SetStateAction<string>>;
  setStopLossPrice: React.Dispatch<React.SetStateAction<string>>;
  setShowOrderBook: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowOrdersAndBalances: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowMarketTakeProfit: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowMarketStopLoss: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setChartLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetValues: () => void;
};

type Props = {
  selectedAsset: Asset;
};

const SwyftxProTradeContext = createContext<SwyftxProTradeContextType>({
  tradeDirection: 'buy',
  tradeType: 'market',
  amount: '',
  total: '',
  triggerPrice: '',
  takeProfitPrice: '',
  stopLossPrice: '',
  showOrderBook: true,
  showOrdersAndBalances: true,
  chartLoading: false,
  setTradeDirection: () => {},
  setTradeType: () => {},
  setLimitAsset: () => {},
  setAmount: () => {},
  setTotal: () => {},
  setTriggerPrice: () => {},
  setTakeProfitPrice: () => {},
  setStopLossPrice: () => {},
  setShowOrderBook: () => {},
  setShowOrdersAndBalances: () => {},
  setShowMarketTakeProfit: () => {},
  setShowMarketStopLoss: () => {},
  setChartLoading: () => {},
  resetValues: () => {},
});

const SwyftxProTradeContextProvider: React.FC<PropsWithChildren<Props>> = observer(({ children, selectedAsset }) => {
  const baseAsset = useBaseAsset();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [limitAsset, setLimitAsset] = useState<Asset>(baseAsset!);
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  const [tradeDirection, setTradeDirection] = useState<SwyftxTradeDirection>('buy');
  const [tradeType, setTradeType] = useState<SwyftxTradeType>('market');
  const [amount, setAmount] = useState<string>('');
  const [total, setTotal] = useState<string>('');
  const [triggerPrice, setTriggerPrice] = useState<string>('');
  const [takeProfitPrice, setTakeProfitPrice] = useState<string>('');
  const [stopLossPrice, setStopLossPrice] = useState<string>('');
  const [showOrderBook, setShowOrderBook] = useLocalStorage<boolean>(StorageKey.SHOW_ORDERBOOK, true);
  const [showOrdersAndBalances, setShowOrdersAndBalances] = useLocalStorage<boolean>(
    StorageKey.SHOW_ORDERS_AND_BALANCES,
    true,
  );
  const [showMarketTakeProfit, setShowMarketTakeProfit] = useState<boolean>();
  const [showMarketStopLoss, setShowMarketStopLoss] = useState<boolean>();

  useEffect(() => {
    if (!baseAsset || !selectedAsset) return;

    setAmount('');
    setTotal('');
    setTakeProfitPrice('');
    setStopLossPrice('');

    if (tradeDirection === 'buy') {
      setLimitAsset(baseAsset);
    } else {
      setLimitAsset(selectedAsset);
    }
  }, [baseAsset, selectedAsset, tradeDirection]);

  useEffect(() => {
    setTakeProfitPrice('');
    setStopLossPrice('');
    setShowMarketStopLoss(false);
    setShowMarketTakeProfit(false);
  }, [tradeDirection, tradeType]);

  useEffect(() => {
    // OCO currently only supports sell orders
    if (tradeType === 'oco') {
      if (tradeDirection !== 'sell') {
        setTradeDirection('sell');
      }
    }
  }, [stopLossPrice, takeProfitPrice, tradeDirection, tradeType, triggerPrice]);

  const resetValues = useCallback(() => {
    setTotal('');
    setAmount('');
    setTriggerPrice('');
    setTakeProfitPrice('');
    setStopLossPrice('');
    setShowMarketStopLoss(false);
    setShowMarketTakeProfit(false);
  }, []);

  useEffect(() => {
    resetValues();
  }, [selectedAsset, resetValues]);

  useEffect(() => {
    if (chartLoading) {
      window.setTimeout(() => setChartLoading(false), 750);
    }
  }, [chartLoading]);

  return (
    <SwyftxProTradeContext.Provider
      value={{
        tradeDirection,
        tradeType,
        selectedAsset,
        baseAsset,
        limitAsset,
        amount,
        total,
        triggerPrice,
        takeProfitPrice,
        stopLossPrice,
        showOrderBook,
        showOrdersAndBalances,
        showMarketTakeProfit,
        showMarketStopLoss,
        chartLoading,
        setLimitAsset,
        setTradeDirection,
        setTradeType,
        setAmount,
        setTotal,
        setTriggerPrice,
        setTakeProfitPrice,
        setStopLossPrice,
        setShowOrderBook,
        setShowOrdersAndBalances,
        setShowMarketTakeProfit,
        setShowMarketStopLoss,
        setChartLoading,
        resetValues,
      }}
    >
      {children}
    </SwyftxProTradeContext.Provider>
  );
});

export { SwyftxProTradeContextProvider, SwyftxProTradeContext };

import { useCallback, useContext } from 'react';

import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { Order, OrderType } from '@shared/api';
import { OrderSourceEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';

import { useExecuteTrade } from 'src/lib/trade/hooks/General';

import { useBuildSwyftxProOcoOrder } from './useBuildSwyftxProOcoOrder/useBuildSwyftxProOcoOrder';
import { SwyftxProTradeContext } from '../context';

const useProcessMarketOcoOrder = () => {
  const { showMarketStopLoss, showMarketTakeProfit, takeProfitPrice, stopLossPrice, tradeType } =
    useContext(SwyftxProTradeContext);
  const { buildOcoOrder, executeOcoOrder } = useBuildSwyftxProOcoOrder();
  const { addToast } = useContext(ToastManagerContext);
  const { executeTrade } = useExecuteTrade({
    onExecuteSuccess: () => {
      addToast({
        title: 'Linked order has successfully been placed',
        type: 'success',
        isDismissable: true,
      });
    },
    onExecuteFailure: (error: string) => {
      addToast({
        title: error,
        type: 'destructive',
        isDismissable: true,
      });
    },
  });

  const processMarketOcoOrder = useCallback(
    (marketOrder: Order, orderSourceUuid: string, primary: string, secondary: string) => {
      if ((!showMarketStopLoss && !showMarketTakeProfit) || tradeType !== 'market') return;

      if (showMarketTakeProfit && takeProfitPrice && showMarketStopLoss && stopLossPrice) {
        // Process a standard OCO order for the market order

        const ocoOrder = buildOcoOrder({
          primary,
          secondary,
          amount: marketOrder.amount.toString(),
          stopLossPrice,
          takeProfitPrice,
          orderSourceUuid,
        });

        executeOcoOrder(ocoOrder);
      } else if (showMarketTakeProfit && takeProfitPrice) {
        // Process a Take profit order (trigger sell)
        executeTrade(
          {
            assetQuantity: secondary,
            orderType: OrderType.TriggerSell,
            primary,
            quantity: marketOrder.amount.toString(),
            secondary,
            trigger: Big(1).div(takeProfitPrice).toString(),
            orderSource: OrderSourceEnum.PARENT_ORDER,
            orderSourceUuid,
            pro: 1,
          },
          OrderSourceEnum.PARENT_ORDER,
        );
      } else if (showMarketStopLoss && stopLossPrice) {
        // Process a Stop loss order (stop sell)
        executeTrade(
          {
            assetQuantity: secondary,
            orderType: OrderType.StopSell,
            primary,
            quantity: marketOrder.amount.toString(),
            secondary,
            trigger: Big(1).div(stopLossPrice).toString(),
            orderSource: OrderSourceEnum.PARENT_ORDER,
            orderSourceUuid,
            pro: 1,
          },
          OrderSourceEnum.PARENT_ORDER,
        );
      }
    },
    [
      buildOcoOrder,
      executeOcoOrder,
      executeTrade,
      showMarketStopLoss,
      showMarketTakeProfit,
      stopLossPrice,
      takeProfitPrice,
      tradeType,
    ],
  );

  return { processMarketOcoOrder };
};

export { useProcessMarketOcoOrder };

import React, { useMemo } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { uuid } from '@shared/utils';

export const UpdateProfileLightIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  const maskId = useMemo(() => uuid(), []);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none'>
        <rect width='64' height='64' fill='white' />
        <path
          d='M57.2192 47.23L34.843 34.3119C33.3916 33.4734 31.6059 33.4734 30.1545 34.3119L7.78079 47.23C6.73974 47.8303 6.73974 49.3349 7.78079 49.9352L30.1545 62.8533C31.6059 63.6918 33.3916 63.6918 34.843 62.8533L57.2167 49.9352C58.2577 49.3349 58.2577 47.8303 57.2167 47.23H57.2192Z'
          fill='black'
          fillOpacity='0.05'
        />
        <path
          d='M43.3261 29.2231L34.8457 24.3269C33.3943 23.4885 31.6086 23.4885 30.1572 24.3269L21.6768 29.2231H7.00079V42.5035C7.00079 43.0304 7.26171 43.5547 7.78097 43.8561L30.1572 56.7742C31.6086 57.6126 33.3943 57.6126 34.8457 56.7742L57.2194 43.8561C57.7412 43.5547 57.9996 43.0304 57.9996 42.5035V29.2231H43.3236H43.3261Z'
          fill='#E0E8F7'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.157 43.4939L7.78079 30.5758C6.73974 29.9755 6.73974 28.4709 7.78079 27.8706L30.1545 14.9525C31.6059 14.1141 33.3916 14.1141 34.843 14.9525L57.2192 27.8706C58.2603 28.4709 58.2603 29.9755 57.2192 30.5758L34.8455 43.4939C33.3941 44.3323 31.6084 44.3323 30.157 43.4939Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M52.0546 30.3783L49.4912 31.8601L47.0317 33.281L44.3797 34.811L41.5681 36.4346L34.3567 40.5988C34.2934 40.6342 34.2301 40.6697 34.1642 40.7026C33.493 41.037 32.7458 41.1586 32.0213 41.0648C31.5426 41.0015 31.0766 40.847 30.646 40.5988L29.4808 39.925L27.8622 38.9904L27.3379 38.6889L24.7999 37.2224L23.1408 36.2649L22.6544 35.9837L20.623 34.811L20.1164 34.5197L17.971 33.281L15.5115 31.8601L12.9481 30.3783C12.1249 29.9071 12.1249 28.7141 12.9481 28.2404L17.971 25.3402L20.1164 24.1016L22.6544 22.6375L24.7999 21.3989L27.7331 19.7043L30.646 18.0225C31.3628 17.607 32.186 17.4525 32.9814 17.5539C33.4348 17.6147 33.8805 17.759 34.2934 17.9895C34.3137 17.9997 34.3365 18.0098 34.3567 18.0225L36.877 19.4789L37.6648 19.9323L39.02 20.715L42.3483 22.6375L43.9972 23.5899L47.0317 25.3402L52.0546 28.2404C52.8778 28.7141 52.8778 29.9071 52.0546 30.3783Z'
          fill='#80C0FF'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M49.4911 31.86L44.3796 34.8109L34.3567 40.5987C33.2042 41.2598 31.7908 41.2598 30.6459 40.5987L20.6229 34.8109L15.5114 31.86L30.6459 23.1213C31.7908 22.4576 33.2042 22.4576 34.3567 23.1213L49.4911 31.86Z'
          fill='#3D93FA'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.9976 3.54036V25.5028C29.9976 26.3666 29.5344 27.3527 28.9206 27.8641C28.8382 27.9352 28.753 27.9949 28.6649 28.046L26.0705 29.5435L23.6665 30.933L20.8193 32.5754L18.9581 33.6524L18.4125 33.337L16.1336 32.0213L15.5652 31.6946V11.8747C15.5652 10.8943 16.1591 9.75485 16.8951 9.32862L27.0082 3.49204L28.6649 2.53443C28.9775 2.35257 29.2616 2.32985 29.4889 2.43783L29.5912 2.49749H29.5941C29.8441 2.67935 29.9976 3.04024 29.9976 3.54036Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.489 2.43785C29.2617 2.32987 28.9776 2.35259 28.665 2.53445L27.0083 3.49206L16.8952 9.32864C16.1592 9.75487 15.5654 10.8943 15.5654 11.8747V31.6946L13.1586 30.305V10.4851C13.1586 9.5048 13.7553 8.36535 14.4912 7.93911L26.261 1.14492C26.3236 1.10798 26.386 1.07955 26.4457 1.05682C26.5423 1.01704 26.6447 1 26.7498 1C26.9061 1 27.0652 1.04264 27.2044 1.1222L29.489 2.43785Z'
          fill='#E0E8F7'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.7551 6.9975V28.9571C35.7551 29.1588 35.7295 29.3691 35.6812 29.5766C35.5022 30.3779 35.0078 31.1678 34.4224 31.5031L31.8281 33.0007L29.4241 34.3873L26.574 36.0326L24.7583 37.0811L24.1701 36.743L21.3228 35.0977V15.3318C21.3228 14.3486 21.9167 13.2092 22.6526 12.7858L30.5011 8.25345L32.7658 6.94634L34.4224 5.98874C34.7322 5.80972 35.0191 5.787 35.2436 5.89498L35.3488 5.95464H35.3516C35.5989 6.1365 35.7551 6.49739 35.7551 6.9975Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.2434 5.8951C35.019 5.78712 34.732 5.80984 34.4223 5.98886L32.7656 6.94646L30.5009 8.25358L22.6525 12.7859C21.9165 13.2093 21.3226 14.3487 21.3226 15.3319V35.0979L19.4614 34.0237L18.9158 33.7083V13.9424C18.9158 12.9621 19.5125 11.8198 20.2485 11.3964L30.5009 5.47736L32.0183 4.60217C32.0808 4.56523 32.1433 4.53683 32.203 4.51126C32.2996 4.47432 32.4019 4.45441 32.5014 4.45441C32.6605 4.45441 32.8224 4.49989 32.9617 4.57945L35.2434 5.8951Z'
          fill='#E0E8F7'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M41.5124 10.452V32.4115C41.5124 32.6133 41.4868 32.8236 41.4385 33.031C41.2595 33.8323 40.7651 34.6223 40.1797 34.9576L37.5853 36.4551L35.1814 37.8418L32.3313 39.487L30.5156 40.5356L29.9273 40.1974L27.0801 38.5522V18.7863C27.0801 17.8031 27.6739 16.6636 28.4099 16.2402L36.2583 11.7079L38.5231 10.4008L40.1797 9.4432C40.4894 9.26418 40.7764 9.24146 41.0009 9.34944L41.1061 9.40911H41.1089C41.3561 9.59097 41.5124 9.95186 41.5124 10.452Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M41.0008 9.34951C40.7763 9.24153 40.4894 9.26425 40.1797 9.44326L38.523 10.4009L36.2583 11.708L28.4098 16.2403C27.6739 16.6637 27.08 17.8031 27.08 18.7863V38.5523L25.2188 37.4781L24.6732 37.1627V17.3968C24.6732 16.4165 25.2699 15.2742 26.0059 14.8508L36.2583 8.93177L37.7757 8.05658C37.8382 8.01964 37.9007 7.99124 37.9604 7.96567C38.057 7.92873 38.1593 7.90881 38.2588 7.90881C38.4179 7.90881 38.5798 7.9543 38.719 8.03386L41.0008 9.34951Z'
          fill='#E0E8F7'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.2024 25.9962L29.5813 26.9309'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.2719 21.9156L32.9957 24.9628'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.772 20.8493L29.5814 24.4233'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34.2496 19.2181L29.5813 21.9156'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M37.5373 17.3209L35.9188 18.2556'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.7214 18.7469L29.5815 19.4055'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34.7939 16.3963L32.3395 17.8122'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.2024 36.2604L29.5813 37.1951'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M38.2719 32.1798L32.9957 35.2269'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.772 31.1135L29.5814 34.6875'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34.2496 29.4822L29.5813 32.1798'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M37.5373 27.585L35.9188 28.5197'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.7214 29.0111L29.5815 29.6697'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34.7939 26.6605L32.3395 28.0764'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50.7132 15.0915V24.5116C50.7132 25.8335 49.9108 27.3666 48.9204 27.9388L40.4001 32.8577C39.9883 33.0942 39.6124 33.1301 39.3084 32.9928C39.2936 32.9907 39.2809 32.9802 39.2661 32.976L39.1943 32.9337L39.1521 32.9063C39.1521 32.9063 39.1436 32.8999 39.1373 32.8936C38.8079 32.6508 38.6052 32.1651 38.6052 31.4999V22.0798C38.6052 20.7579 39.4076 19.2248 40.398 18.6504L48.9183 13.7316C49.3512 13.4824 49.7461 13.4571 50.0586 13.6154L50.1051 13.645C50.4788 13.8667 50.7111 14.3735 50.7111 15.0894L50.7132 15.0915Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M41.7298 29.6371L47.8134 26.0863C48.0815 25.9094 48.3049 25.6719 48.4654 25.393C48.6259 25.1142 48.719 24.8018 48.7373 24.481L48.7518 17.4356C48.7523 16.8471 48.3413 16.6106 47.8327 16.9067L41.7505 20.4582C41.4819 20.6351 41.2583 20.8728 41.0976 21.1519C40.9369 21.4311 40.8438 21.7439 40.8258 22.0649L40.8107 29.1081C40.8095 29.698 41.2212 29.9331 41.7298 29.6371Z'
          fill='#3D93FA'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <mask id={maskId} style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='40' y='16' width='9' height='14'>
          <path
            d='M41.7298 29.6371L47.8134 26.0863C48.0815 25.9094 48.3049 25.6719 48.4654 25.393C48.6259 25.1142 48.719 24.8018 48.7373 24.481L48.7518 17.4356C48.7523 16.8471 48.3413 16.6106 47.8327 16.9067L41.7505 20.4582C41.4819 20.6351 41.2583 20.8728 41.0976 21.1519C40.9369 21.4311 40.8438 21.7439 40.8258 22.0649L40.8107 29.1081C40.8095 29.698 41.2212 29.9331 41.7298 29.6371Z'
            fill='#3D93FA'
            stroke='#22262E'
            strokeWidth='0.3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <path
            d='M47.3452 29.7455C45.9576 32.1567 43.6019 33.4031 42.0838 32.5293C40.5656 31.6556 40.4598 28.9927 41.8475 26.5815C43.2351 24.1703 45.5908 22.9239 47.1089 23.7976C48.6271 24.6713 48.7329 27.3343 47.3452 29.7455Z'
            fill='white'
            stroke='#22262E'
            strokeWidth='0.3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M41.7298 29.6371L47.8134 26.0863C48.0815 25.9094 48.3049 25.6719 48.4654 25.393C48.6259 25.1142 48.719 24.8018 48.7373 24.481L48.7518 17.4356C48.7523 16.8471 48.3413 16.6106 47.8327 16.9067L41.7505 20.4582C41.4819 20.6351 41.2583 20.8728 41.0976 21.1519C40.9369 21.4311 40.8438 21.7439 40.8258 22.0649L40.8107 29.1081C40.8095 29.698 41.2212 29.9331 41.7298 29.6371Z'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M45.7956 21.9656C45.2302 22.9479 44.2652 23.4526 43.64 23.0929C43.0149 22.7331 42.9664 21.6451 43.5318 20.6628C44.0971 19.6804 45.0622 19.1757 45.6873 19.5355C46.3124 19.8953 46.3609 20.9833 45.7956 21.9656Z'
          fill='white'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50.0603 13.6155C49.7499 13.455 49.3529 13.4825 48.92 13.7317L46.8252 14.9416L40.3979 18.6547C39.4075 19.2291 38.6051 20.7622 38.6051 22.0841V31.5042C38.6051 32.1694 38.8099 32.6551 39.1372 32.8979L35.4228 30.744L35.2433 30.6384L35.2307 30.6321C34.9118 30.3829 34.7133 29.9036 34.7133 29.251V19.8309C34.7133 18.509 35.5179 16.976 36.5103 16.4016L45.0324 11.4785C45.423 11.2526 45.7862 11.2124 46.084 11.3244L46.0988 11.3307L46.31 11.4511L50.0624 13.6176L50.0603 13.6155Z'
          fill='#80C0FF'
          stroke='#22262E'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation('profile.security');
  const { openModal } = useModal();

  return (
    <Button id='profile-security-change-password' onClick={() => openModal(Modals.ChangePassword)}>
      {t('overview.configureData.changePassword.title')}
    </Button>
  );
};

export { ChangePassword };

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { clsx } from 'clsx';

import { BodyLinkProps, bodyVariants } from './Body.styles';

const BodyLink = React.forwardRef<HTMLAnchorElement, BodyLinkProps>(
  (
    { className, size, weight, loading, color, overflow, rel = 'noopener noreferrer', target = '_blank', ...props },
    ref,
  ) => (
    <a
      ref={ref}
      className={clsx(bodyVariants({ size, weight, color, loading, overflow }), className)}
      {...props}
      rel={rel}
      target={target}
    />
  ),
);
BodyLink.displayName = 'BodyLink';

export { BodyLink };

import React from 'react';

import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { PortfolioPerformanceTab, PortfolioPeriodType } from './PortfolioPerformanceCard.types';

type Props = {
  tabs: PortfolioPerformanceTab[];
  value: PortfolioPeriodType;
  onChange: (value: PortfolioPeriodType) => void;
};

const PortfolioPerformanceTabs: React.FC<Props> = ({ tabs, value, onChange }) => (
  <EnhancedTabs tabs={tabs} onChange={onChange} value={value} variant='default' size='sm' />
);

export { PortfolioPerformanceTabs };

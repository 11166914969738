import React, { PropsWithChildren, useCallback } from 'react';

import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { observer } from 'mobx-react-lite';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { AssetsTableData } from './AssetsTable.types';
import { useAssetsTable } from './useAssetsTable';

type Props = {
  assets: Asset[];
  loaded: boolean;
  numAssets?: number;
  widgetId: DashboardWidgets;
};

const AssetsTable: React.FC<PropsWithChildren<Props>> = observer(({ assets, loaded, numAssets, children }) => {
  const { headers, data, initialSort, onSort } = useAssetsTable({
    assets,
    numAssets,
  });

  const { navigateToAsset } = useAssetNavigation();

  const onSelectItem = useCallback(
    (item: EnhancedTableData<AssetsTableData>) => {
      const asset = assetService.getAssetByName(item.asset.value as string);

      if (!asset) return;
      navigateToAsset(asset);
    },
    [navigateToAsset],
  );

  return (
    <EnhancedTable<AssetsTableData>
      className={loaded ? '' : 'hidden'}
      data-container='assets-table'
      headers={headers}
      data={data}
      sort={initialSort}
      onSort={onSort}
      onClickItem={onSelectItem}
    >
      {children}
    </EnhancedTable>
  );
});

AssetsTable.displayName = 'AssetsTable';

export { AssetsTable };

import React from 'react';

import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { formatValueToCurrencyShorthand } from '@utils/currency';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const AssetMarketCap: React.FC<Props> = observer(({ asset }) => {
  const baseAsset = useBaseAsset();
  const { getRate } = RatesStore.useRatesStore;

  const rate = getRate(asset);

  return (
    <NumericDataItem
      className='truncate'
      color='primary'
      data={formatValueToCurrencyShorthand(
        Big(rate.midPrice).times(asset.circulatingSupply).toString(),
        baseAsset?.assetType,
        baseAsset?.code,
      )}
      size='small'
      alignItems='end'
    />
  );
});

export { AssetMarketCap };

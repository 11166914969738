import { useMemo } from 'react';

import { Asset } from '@shared/api';

import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

type Props = {
  asset?: Asset;
};

const useSwyftxProTradeBalance = ({ asset }: Props) => {
  const { getBalance } = usePortfolioBalance();

  const balance = useMemo(() => {
    if (!asset) return undefined;

    return getBalance(asset.id)?.availableBalance;
  }, [getBalance, asset]);

  return {
    balance,
  };
};

export { useSwyftxProTradeBalance };

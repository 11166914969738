import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { AddInCircle } from '@swyftx/aviary/icons/outlined';

import { assetService } from '@shared/services';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { MAX_AUTO_INVEST_ASSETS } from 'src/lib/trade/trade.consts';

import { AutoInvestAsset } from '../../autoInvest.types';
import { useAutoInvestPercentages } from '../../hooks';
import { useAutoInvestValues } from '../../widgets/CreateAutoInvestWidget/hooks';
import { AutoInvestAssetValueItem } from '../AutoInvestAssetValueItem';
import { AutoInvestMobileAssetPickerModal } from '../AutoInvestMobileAssetPickerModal/AutoInvestMobileAssetPickerModal';

type Props = {
  assets: AutoInvestAsset[];
  onLock: (assetCode: string) => void;
  onRemove: (assetCode: string) => void;
  onUpdatePercentage: (assetCode: string, percentage: number) => void;
  onUpdateAsset: (oldAssetCode: string, newAssetCode: string) => void;
  onAddNewAsset: () => void;
  isEdit?: boolean;
  onBulkUpdateAssets?: (assetCodes: string[]) => void;
};

const AutoInvestAssetAllocation: React.FC<Props> = observer(
  ({ assets, onLock, onRemove, onUpdatePercentage, onUpdateAsset, isEdit, onAddNewAsset, onBulkUpdateAssets }) => {
    const countryAsset = useCountryAsset();
    const { getRemainingPercentage, minimumPercentage } = useAutoInvestPercentages();
    const { setCurrentStep } = useAutoInvestValues();
    const [multiAssetSelectOpen, setMultiAssetSelectOpen] = useState<boolean>(false);
    const isXs = useTailwindBreakpoint('xs');

    const getExcludedAssetCodes = useCallback(
      (assetCode: string) => {
        const excluded = [];
        const selectedAssetCodes = assets.map((a) => a.assetCode).filter((code) => code !== assetCode);
        const unTradeableCodes = assetService
          .getAssetList()
          .filter((asset) => !asset.tradable || asset.buyDisabled)
          .map((a) => a.code);

        if (countryAsset) excluded.push(countryAsset.code);
        excluded.push(...selectedAssetCodes, ...unTradeableCodes);

        return excluded;
      },
      [assets, countryAsset],
    );

    const hasReachedMaximumAssets = useMemo(
      () => getRemainingPercentage(assets).lt(minimumPercentage),
      [assets, getRemainingPercentage, minimumPercentage],
    );

    const addButtonTooltip = useMemo(() => {
      if (assets.length === MAX_AUTO_INVEST_ASSETS) return 'Maximum amount of assets added';
      if (hasReachedMaximumAssets)
        return 'The maximum allocation % has been reached. Please unlock an asset to allow additional assets to be added.';

      return '';
    }, [assets.length, hasReachedMaximumAssets]);

    return (
      <>
        <FlexLayout direction='column' spacing={8} className='relative mb-24 w-full'>
          <FlexLayout direction='row' className='w-full' alignItems='center' justifyContent='start'>
            <Body size='small' weight='emphasis' className='w-1/2 sm:w-2/3'>
              Assets to order
            </Body>
            <Body size='small' weight='emphasis' className='w-1/2 sm:w-1/3'>
              Allocation
            </Body>
          </FlexLayout>
          <FlexLayout direction='column' spacing={8}>
            {assets.length > 0 &&
              assets.map((asset) => (
                <AutoInvestAssetValueItem
                  key={asset.assetCode}
                  assetCode={asset.assetCode}
                  assets={assets}
                  excludedAssetCodes={getExcludedAssetCodes(asset.assetCode)}
                  onLock={onLock}
                  onRemove={onRemove}
                  onUpdateAsset={onUpdateAsset}
                  onUpdatePercentage={onUpdatePercentage}
                  locked={asset.locked || hasReachedMaximumAssets}
                  percentage={asset.percentage}
                />
              ))}
            <div>
              {!isEdit && (
                <Button
                  variant='outlined'
                  leadingIcon={<AddInCircle />}
                  className='w-1/2'
                  onClick={isXs ? () => setMultiAssetSelectOpen(true) : () => setCurrentStep('assets')}
                  disabled={hasReachedMaximumAssets}
                  tooltip={addButtonTooltip}
                >
                  Add assets
                </Button>
              )}
              {isEdit && (
                <Button
                  variant='outlined'
                  leadingIcon={<AddInCircle />}
                  className='w-1/2'
                  onClick={onAddNewAsset}
                  disabled={hasReachedMaximumAssets}
                  tooltip={addButtonTooltip}
                >
                  Add asset
                </Button>
              )}
            </div>
          </FlexLayout>
        </FlexLayout>
        {isXs && onBulkUpdateAssets && (
          <AutoInvestMobileAssetPickerModal
            preSelectedAssetCodes={assets.map((asset) => asset.assetCode).filter((code) => code !== '')}
            open={multiAssetSelectOpen}
            setOpen={setMultiAssetSelectOpen}
            onConfirm={onBulkUpdateAssets}
          />
        )}
      </>
    );
  },
);

export { AutoInvestAssetAllocation };

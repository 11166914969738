import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Body } from '@swyftx/aviary/atoms/Typography';
import { Grid } from '@swyftx/react-web-design-system';

import Links from '@shared/constants/links';

import { RegisterContext } from '@Register/Register.context';
import { Affiliates } from '@Register/Register.data';
import { RegisterTranslationKey } from '@Register/translations';

const FinderReferralCode: React.FC = () => {
  const { t } = useTranslation(RegisterTranslationKey);
  const { referralCode, affiliate, promoRef } = useContext(RegisterContext);

  if (!affiliate) return null;

  return (
    <div className='rounded-8 border border-dashed border-color-border-accent bg-color-background-surface-secondary p-16'>
      <Grid container justifyContent='space-between' alignItems='center' height='100%'>
        <Grid item xs={12} sm={3} sx={{ paddingBottom: { xs: 2, sm: 0 }, paddingRight: { sm: 2 } }}>
          <a href={Links.partners.finder} className='cursor-pointer' target='_blank' rel='noreferrer'>
            <img
              src='/assets/images/logos/register-logo-finder.svg'
              width='100%'
              height='19px'
              style={{ display: 'flex' }}
              alt='Referral code Finder logo'
            />
          </a>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Body color='secondary' size='small' className='w-full'>
            {t('referralCode.finder.subTitle', { referralCode: Affiliates[affiliate].name })}
            <strong>{promoRef || referralCode}</strong>
          </Body>
        </Grid>
      </Grid>
    </div>
  );
};

FinderReferralCode.displayName = 'FinderReferralCode';

export { FinderReferralCode };

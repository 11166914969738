import React, { createContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { MFAEnrolment, MFARequired, OTPRequired, UserProfile } from '@shared/api';
import env from '@shared/config';
import { SwyftxError } from '@shared/error-handler';
import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import AuthenticationService from '@services/AuthenticationService';

import { AuthPostMessage, AuthStep, AuthStoreSchema, FederatedAuthType, initialValues } from '@Auth/types';

import { useLocalObservable } from 'mobx-react-lite';

function isMfaRequired(response: UserProfile | OTPRequired | MFARequired | MFAEnrolment): response is MFARequired {
  return (response as any).mfa != null;
}

function isMfaEnrolment(response: UserProfile | OTPRequired | MFARequired | MFAEnrolment): response is MFAEnrolment {
  return (response as any).mfa_enrollment != null;
}

function isOtpRequired(response: UserProfile | OTPRequired | MFARequired | MFAEnrolment): response is OTPRequired {
  return (response as OTPRequired).otp != null;
}

function getAuthService(authType: FederatedAuthType) {
  switch (authType) {
    case 'Learn':
      return AuthenticationService;
    default:
      authType satisfies never;
      return AuthenticationService;
  }
}

export const AuthContext = createContext<AuthStoreSchema>(initialValues);

export const AuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation('auth');

  const store = useLocalObservable(
    (): AuthStoreSchema => ({
      ...initialValues,
      setEmail: (email: string) => {
        store.email = email;
      },
      setPassword: (password: string) => {
        store.password = password;
      },
      setToken: (token: string) => {
        store.token = token;
      },
      setRememberMe: (rememberMe: boolean) => {
        store.rememberMe = rememberMe;
      },
      setMfaData: (mfaData?: MFARequired | MFAEnrolment) => {
        store.mfaData = mfaData;
      },
      setOtpData: (otpData?: OTPRequired) => {
        store.otpData = otpData;
      },
      setAuthStep: (authStep: AuthStep) => {
        store.authStep = authStep;
        store.error = '';
      },
      setError: (error: string) => {
        store.error = error;
      },
      setSubmitting: (submitting: boolean) => {
        store.submitting = submitting;
      },
      login: async (type: FederatedAuthType) => {
        store.setSubmitting(true);
        store.setError('');

        try {
          const { recaptchaToken } = UserStore.useUserStore;
          const res = await getAuthService(type).Login(store.email, store.password, recaptchaToken);

          if (res === undefined) {
            store.setError('errors.login.none');
            store.setSubmitting(false);
            return;
          }

          if (store.rememberMe) {
            localStorage.setItem(StorageKey.REMEMBER_ME, store.email);
          }

          if (isMfaRequired(res)) {
            store.setMfaData(res);
            store.setAuthStep(AuthStep.TwoFactorLogin);
            return;
          }

          if (isMfaEnrolment(res)) {
            store.setError('This account requires 2FA enrolment.');
            return;
          }

          if (isOtpRequired(res)) {
            store.setOtpData(res);
            store.setAuthStep(AuthStep.NewLoginDetected);
            return;
          }

          store.onLoggedIn(type, res);
        } catch (e) {
          if (e instanceof SwyftxError) {
            store.setError(e.errorMessage);
          } else if (e instanceof Error) {
            store.setError(e.message);
          } else {
            store.setError('Encountered an unknown error.');
          }
        } finally {
          store.setSubmitting(false);
        }
      },
      mfaLogin: async (type: FederatedAuthType, code: string, challenge_type = '', oob_code = '') => {
        try {
          const mfaData = store.mfaData as MFARequired;
          store.setSubmitting(true);
          store.setError('');

          if (challenge_type !== 'rec' && Number.isNaN(Number(code))) {
            store.setError(t('errors.2FA.invalidCharacters'));
            store.setSubmitting(false);
            return;
          }

          const res = await getAuthService(type).MFALogin(
            code,
            mfaData.mfa.token,
            challenge_type || mfaData.mfa.challenge_type,
            oob_code,
          );

          if (res === undefined) {
            store.setSubmitting(false);
            return;
          }
          store.onLoggedIn(type, res);
        } catch (e) {
          if (e instanceof SwyftxError) {
            store.setError(e.errorMessage);
          } else if (e instanceof Error) {
            store.setError(e.message);
          } else {
            store.setError('Encountered an unknown error.');
          }
        } finally {
          store.setSubmitting(false);
        }
      },
      otpLogin: async (type: FederatedAuthType, code: string) => {
        if (!store.email || !store.otpData || !store.password) return;

        try {
          const otpData = store.otpData as OTPRequired;
          store.setSubmitting(true);
          store.setError('');

          const res = await AuthenticationService.OTPLogin(
            store.email,
            store.password,
            code,
            otpData.otp.challengeType,
            false,
          );

          if (res === undefined) {
            store.setSubmitting(false);
            return undefined;
          }

          store.onLoggedIn(type, res);
        } catch (e) {
          const { errorMessage } = e as SwyftxError;
          store.setError(errorMessage);
          return undefined;
        } finally {
          store.setSubmitting(false);
        }
      },
      onLoggedIn: (type: FederatedAuthType, res: UserProfile) => {
        if (type === 'Learn') {
          const authResponse = res as UserProfile;
          store.postMessage({
            type: 'success',
            token: authResponse.profile.intercom?.uuid,
            user: {
              firstName: authResponse.profile.name?.first,
            },
          });
        }
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getFederatedAuthType: (searchParams: URLSearchParams) => 'Learn',

      getRememberMe: async () => {
        const rememberMeEmail = await localStorage.getItem(StorageKey.REMEMBER_ME);

        if (rememberMeEmail) {
          store.setEmail(rememberMeEmail);
          store.setRememberMe(true);
        }
      },
      postMessage: (message: AuthPostMessage) => {
        const targets = env.FEDERATED_AUTH_TARGETS.split(',').filter(Boolean);
        targets.forEach((target) => {
          window.opener.postMessage(message, target);
        });
      },
      cleanup: () => {
        store.setEmail('');
        store.setPassword('');
        store.setToken('');
        store.setError('');
        store.setMfaData(undefined);
        store.setOtpData(undefined);
        store.setSubmitting(false);
        store.setAuthStep(AuthStep.SignIn);
      },
    }),
  );

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};

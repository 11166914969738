import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { UIStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { capitalize } from '@utils/formatting';

import { observer } from 'mobx-react-lite';

import { AppHeaderBreadCrumb } from './AppHeaderBreadCrumb';

const AppHeaderBreadCrumbs: React.FC = observer(() => {
  const { customHeaderOptions, customBreadcrumbs } = UIStore.useUIStore;
  const { isXs: isCompact } = useScreenBreakpoints();
  const location = useLocation();
  const crumbs = useMemo(() => location.pathname.split('/').filter((path) => path.length > 0), [location.pathname]);

  const renderCrumb = (crumb: string, index: number) => {
    const customCrumb = customBreadcrumbs[crumb.toLowerCase()];
    const historyOffset = 1 - crumbs.length + index;
    const fullPath = crumbs.slice(0, index + 1).join('/');

    return (
      customCrumb || (
        <AppHeaderBreadCrumb
          fullPath={fullPath}
          anotherPath={index < crumbs.length - 1}
          historyIndex={historyOffset}
          isCompact={isCompact}
          title={crumb}
          key={crumb}
        />
      )
    );
  };

  if (!crumbs) return customHeaderOptions || null;

  return (
    <>
      {crumbs.slice(0, 1).map((start, index) => renderCrumb(start, index))}
      {crumbs.length > 2 && (
        <AppHeaderBreadCrumb
          anotherPath
          historyIndex={-1}
          isCompact={isCompact}
          title='...'
          key='...'
          tooltip={capitalize(crumbs[crumbs.length - 2])}
        />
      )}
      {crumbs.length > 1 && crumbs.slice(crumbs.length - 1).map((end) => renderCrumb(end, crumbs.length - 1))}

      {customHeaderOptions}
    </>
  );
});

export { AppHeaderBreadCrumbs };

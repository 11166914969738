import React from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import PortfolioAssetTicker, {
  PortfolioTickerDisplay,
} from '@global-components/PortfolioAssetTicker/PortfolioAssetTicker';
import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { AppStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { AssetBalanceData } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

import { WalletListRowAssetDetails } from '../WalletListRowAssetDetails';

export const MOBILE_FONT_SIZE = 10;
export const TABLE_FONT_SIZE = 14;

type Props = {
  asset: Asset;
  assetBalanceData: AssetBalanceData;
  assetBalanceKey: 'trading' | 'staking' | 'all';
  userAsset?: Asset;
  className?: string;
};

const WalletListMobileTradingRow: React.FC<Props> = observer(
  ({ asset, assetBalanceData, userAsset, assetBalanceKey, className }) => {
    const { isDemo } = AppStore.useAppStore;
    const isFiat = assetService.isAssetFiat(asset.id);

    return (
      <FlexLayout direction='row' alignItems='center' className={cn('w-full', className)}>
        <FlexLayout direction='row' className='w-1/2'>
          <WalletListRowAssetDetails asset={asset} balance={assetBalanceData[assetBalanceKey].balance} isMobile />
        </FlexLayout>

        <FlexLayout direction='row' className='w-1/2'>
          <FlexLayout direction='column' className='h-full w-full' alignItems='end'>
            <FlexLayout direction='column' spacing={8} className='text-end'>
              <FormattedText
                typographyProps={{ weight: 'emphasis' }}
                value={assetBalanceData[assetBalanceKey].value}
                currency={userAsset}
                dynamicCurrency={asset}
              />

              {!isFiat && !isDemo && (
                <FlexLayout direction='row' spacing={8} justifyContent='end' alignItems='center'>
                  <PortfolioAssetTicker
                    assetId={asset.id}
                    display={PortfolioTickerDisplay.Amount}
                    displayProps={{ hideCode: true, appendCode: false }}
                  />

                  <Chip size='sm' variant='subtle'>
                    <PortfolioAssetTicker assetId={asset.id} display={PortfolioTickerDisplay.Percentage} />
                  </Chip>
                </FlexLayout>
              )}
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    );
  },
);

export { WalletListMobileTradingRow };

import React from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { InformationFilled } from '@swyftx/aviary/icons/filled';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

type Props = {
  isMobile?: boolean;
  isTablet?: boolean;
  estimatedApy?: string;
  asset: Asset;
  balance: string;
};

const WalletListRowAssetDetails: React.FC<Props> = ({
  isMobile = false,
  isTablet = false,
  estimatedApy,
  asset,
  balance,
}) => {
  const baseAsset = useBaseAsset();
  const isBaseAsset = baseAsset === asset;

  if (isMobile) {
    return (
      <FlexLayout direction='row' spacing={24} alignItems='center' justifyContent='start'>
        <AssetIcon asset={asset} size={18} wallet />

        <FlexLayout direction='column' spacing={8} className='w-full text-start'>
          <Body weight='bold' className='max-w-[150px] truncate' color='primary'>
            {asset?.name}
          </Body>

          {!isBaseAsset && (
            <FormattedText
              typographyProps={{ className: 'truncate' }}
              value={balance}
              currency={asset}
              formatOpts={{ appendCode: true }}
            />
          )}
        </FlexLayout>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout direction='row' alignItems='center' spacing={16} justifyContent='start'>
      <AssetIcon asset={asset} size={24} wallet />
      <FlexLayout direction='column' spacing={8} className='text-start'>
        <Body weight='bold' className='max-w-[150px] whitespace-nowrap' color='primary'>
          {asset?.name}
        </Body>

        {isTablet ? (
          <Chip size='sm' leadingIcon={<InformationFilled className='h-14 w-14 text-white' />} color='accent'>
            <Body size='small'>Current APY {estimatedApy}%</Body>
          </Chip>
        ) : (
          <Body className='whitespace-nowrap' color='secondary'>
            {asset?.code}
          </Body>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export { WalletListRowAssetDetails };

import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset } from '@shared/api';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { SelectAssetList } from 'src/lib/markets/components/SelectAssetList/SelectAssetList';

import { CreatePriceAlertModalContent } from './CreatePriceAlertModalContent';
import { useCreatePriceAlertModal } from './useCreatePriceAlertModal';
import { CreatePriceAlertType } from '../../dynamicPriceAlerts.types';
import { useDynamicPriceAlertAssetCounts } from '../../hooks/useDynamicPriceAlertAssetCounts';

type Props = {
  asset?: Asset;
  type?: CreatePriceAlertType;
  onClose?: () => void;
};

const MAX_ACTIVE_ALERTS = 100;

const CreatePriceAlertModal: React.FC<PropsWithChildren<Props>> = observer(({ asset, onClose, children }) => {
  const {
    open,
    type,
    selectedAssets,
    tempSelectedAssets,
    handleOpenChange,
    handleOnChangeAssets,
    handleApplySelection,
    setSelectedAssets,
    setTempSelectedAssets,
  } = useCreatePriceAlertModal({
    asset,
    children,
    onClose,
  });
  const { t } = useTranslation('priceAlerts');
  const baseAsset = useBaseAsset();
  const isXs = useTailwindBreakpoint('xs');
  const { totalActiveAlerts } = useDynamicPriceAlertAssetCounts();

  const max = useMemo(() => MAX_ACTIVE_ALERTS - totalActiveAlerts, [totalActiveAlerts]);

  const maxMessage = useMemo(() => {
    if (tempSelectedAssets.length < max) {
      return `You can select ${max - tempSelectedAssets.length} more assets to create alerts for`;
    }

    return "You've selected the maximum number of assets";
  }, [max, tempSelectedAssets.length]);

  const assetList = useMemo(
    () =>
      type === 'multi' ? (
        <SelectAssetList
          title='Select asset'
          onClose={() => handleOpenChange(false)}
          type={type}
          max={max}
          customMaxMessage={maxMessage}
          excludedAssetCodes={baseAsset ? [baseAsset.code] : undefined}
          selectedAssets={tempSelectedAssets}
          onSelectAsset={setTempSelectedAssets}
        />
      ) : (
        <SelectAssetList
          title={t('labels.selectAsset')}
          onClose={() => handleOpenChange(false)}
          type='priceAlert'
          selectedAssets={selectedAssets[0]}
          excludedAssetCodes={baseAsset ? [baseAsset.code] : undefined}
          onSelectAsset={(a) => {
            setSelectedAssets([a]);
          }}
        />
      ),
    [
      baseAsset,
      handleOpenChange,
      max,
      selectedAssets,
      setSelectedAssets,
      setTempSelectedAssets,
      t,
      tempSelectedAssets,
      type,
    ],
  );

  if (!baseAsset) return null;

  return (
    <Modal
      triggerElement={children}
      position={isXs ? 'bottom' : 'center'}
      className='min-w-[100vw] pb-24 sm:!min-w-[500px] sm:!max-w-[500px]'
      contentClassName='overflow-hidden'
      overlayClassName='z-modal-popover-backdrop'
      onOpenChange={handleOpenChange}
      open={open}
    >
      {selectedAssets.length === 0 ? (
        <FlexLayout direction='column'>
          <FlexLayout className='h-[75vh]'>{assetList}</FlexLayout>
          {type === 'multi' && (
            <div className='w-full px-24'>
              <Button className='w-full' size='lg' disabled={!tempSelectedAssets.length} onClick={handleApplySelection}>
                Select asset{tempSelectedAssets.length <= 1 ? '' : 's'}
              </Button>
            </div>
          )}
        </FlexLayout>
      ) : (
        <CreatePriceAlertModalContent
          assets={selectedAssets}
          baseAsset={baseAsset}
          onClose={() => handleOpenChange(false)}
          onChangeAssets={handleOnChangeAssets}
        />
      )}
    </Modal>
  );
});

export { CreatePriceAlertModal };

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as RadixSlider from '@radix-ui/react-slider';

import { SliderMark } from './SliderMark';

interface SliderProps {
  className?: string;
  trackClassName?: string;
  defaultValue?: number;
  max?: number;
  min?: number;
  step?: number;
  value?: number;
  marks?: number[];
  disabled?: boolean;
  sliderColor?: string;
  onValueChange?: (value: number[]) => void;
}

export const Slider: React.FC<SliderProps> = (props) => {
  const {
    className,
    trackClassName,
    onValueChange,
    defaultValue = 50,
    max = 100,
    min = 0,
    step = 1,
    value,
    disabled = false,
    sliderColor,
    marks,
  } = props;

  return (
    <form>
      <RadixSlider.Root
        className={cn('relative flex h-16 w-full cursor-pointer touch-none select-none items-center', className)}
        defaultValue={[defaultValue ?? 50]}
        max={max}
        min={min}
        disabled={disabled}
        minStepsBetweenThumbs={1}
        step={step}
        value={value !== undefined ? [value] : undefined}
        onValueChange={onValueChange}
      >
        <RadixSlider.Track className='relative h-2 grow rounded-r-[8px] bg-color-background-surface-secondary'>
          <RadixSlider.Range
            className={cn('absolute h-full rounded-l-[8px]  bg-color-background-primary', trackClassName)}
            style={{ backgroundColor: sliderColor }}
          />
          {marks?.map((mark) => (
            <SliderMark markColor={sliderColor} key={mark} value={value} mark={mark} onValueChange={onValueChange} />
          ))}
        </RadixSlider.Track>

        <RadixSlider.Thumb
          className='focus:focus-area-info block h-12 w-12 rounded-16 border border-color-background-primary bg-color-background-surface-secondary'
          style={{ borderColor: sliderColor }}
          aria-label='Volume'
        />
      </RadixSlider.Root>
    </form>
  );
};

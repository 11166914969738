import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';

type Props = {
  assetId: number;
  side: TradeSide;
  assetInErrorState?: boolean;
};

const TradeOrderDetailsAssetContainerItem: React.FC<Props> = observer(
  ({ assetId, side, assetInErrorState = false }) => {
    const { setTradeAssets } = UniversalTradeStore;
    const asset = useMemo(() => assetService.getAsset(assetId), [assetId]);

    if (!asset) return null;

    return (
      <Chip
        color={assetInErrorState ? 'destructive' : 'secondary'}
        variant='subtle'
        leadingIcon={<AssetIcon asset={asset} size={20} />}
        className={cn(
          'min-h-[40px] cursor-pointer rounded-[4px] border',
          assetInErrorState ? 'border-color-border-error' : '',
        )}
        onClick={() => setTradeAssets([assetId], side, TradeAssetAction.Remove)}
      >
        <FlexLayout direction='row' spacing={8} alignItems='center' className='pl-4'>
          <Body size='small' weight='emphasis' color='primary' className='select-none'>
            {asset.name}
          </Body>
          {/* TODO: hover/onclick state */}
          <Button
            size='sm'
            variant='ghost'
            layout='icon'
            leadingIcon={<Cross className='min-h-[16px] min-w-[16px]' />}
            onClick={() => setTradeAssets([assetId], side, TradeAssetAction.Remove)}
          />
        </FlexLayout>
      </Chip>
    );
  },
);

export { TradeOrderDetailsAssetContainerItem };

import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import { IconProps, iconContainerVariants, iconVariants } from './Icon.styles';

const Icon: React.FC<IconProps> = ({ color, icon, className }) => (
  <div className={cn(iconContainerVariants({ color }), className)}>
    {React.cloneElement(icon, { className: cn(icon.props?.className, iconVariants({ color })) })}
  </div>
);

export { Icon };

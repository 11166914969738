import React, { useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AssetGroupCard } from '@swyftx/aviary/complex/AssetGroupCard';
import { BarChartDownward } from '@swyftx/aviary/icons/charts';
import { Fire } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';

import { useSingleCategoryAnalytics } from '@hooks/Analytics/Categories/useSingleCategoryAnalytics';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  category: Category;
  title: string;
  assets: Asset[];
  numAssets: number;
  direction: 'gainers' | 'losers';
  emptyContent: string;
  className?: string;
  loaded: boolean;
};

const SingleCategoryMoversCard: React.FC<Props> = ({
  category,
  title,
  emptyContent,
  direction,
  assets,
  numAssets,
  loaded,
  className,
}) => {
  const { navigate } = useNavigateRoute();
  const baseAsset = useBaseAsset();
  const { trackSingleCategoryAssetFromMoversOpened } = useSingleCategoryAnalytics();

  const openAsset = useCallback(
    (asset: Asset) => {
      navigate(NavigationURLs.SingleAsset, { pathParams: { asset: asset.code } });
      trackSingleCategoryAssetFromMoversOpened(category.name, asset.code, direction);
    },
    [category.name, direction, navigate, trackSingleCategoryAssetFromMoversOpened],
  );

  if (!baseAsset) return null;

  return (
    <AssetGroupCard
      title={title}
      className={`@md:flex-4 w-[calc(50%-16px)] flex-[6] @sm:min-w-[calc(33.33%-36px)] ${className}`}
      assets={assets}
      baseAsset={baseAsset}
      numAssets={numAssets}
      loaded={loaded}
      emptyContent={
        <FlexLayout
          className='h-full w-full'
          alignItems='center'
          justifyContent='center'
          direction='column'
          spacing={8}
        >
          {direction === 'gainers' ? (
            <BarChartDownward className='text-color-text-accent' />
          ) : (
            <Fire className='text-color-text-accent' />
          )}
          <Body className='text-center @xs:p-16 @sm:p-24' color='secondary' size='small' weight='emphasis'>
            {emptyContent}
          </Body>
        </FlexLayout>
      }
      onSelectAsset={openAsset}
    />
  );
};

export { SingleCategoryMoversCard };

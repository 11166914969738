import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Coin } from '@swyftx/aviary/icons/outlined';
import { EnhancedTable } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { useAssetNavigation } from 'src/lib/navigation/hooks/useAssetNavigation';

import { AssetListTableData, AssetListTabTypes } from './AssetList.types';
import { useAssetListAssets } from './hooks/useAssetListAssets';
import { useAssetListTable } from './hooks/useAssetListTable';

interface Props {
  search: string;
  selectedTab: AssetListTabTypes;
}

export const AssetListTable: React.FC<Props> = observer((props) => {
  const { search, selectedTab } = props;
  const { params } = useNavigateRoute();
  const { categoryId } = params as { categoryId?: string };
  const isXs = useTailwindBreakpoint('xs');

  const { tableAssets: assets } = useAssetListAssets(selectedTab, categoryId);
  const { headers, sort, onSort, data } = useAssetListTable(assets, search, selectedTab);
  const { navigateToAsset } = useAssetNavigation();

  return (
    <EnhancedTable<AssetListTableData>
      data-container='asset-list-table'
      headers={headers}
      data={data}
      sort={sort}
      onSort={onSort}
      lazyLoad={!isXs}
      itemsPerPage={30}
      onClickItem={(item) => navigateToAsset(item.actions.value as Asset)}
      rowClassName='h-[4.5rem]'
    >
      <FlexLayout direction='column' className='h-full w-full items-center justify-center' spacing={8}>
        <Coin className='h-96 w-96 text-color-text-accent' />
        <Body color='primary'>No assets found</Body>
      </FlexLayout>
    </EnhancedTable>
  );
});

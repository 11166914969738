import React from 'react';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import {
  TriggerType,
  ProfitLossValueMethod,
} from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { TabButton } from '@routes/AssetsList/components/TabButton';

import { useProfitStopLossValueCalculationMethodUI } from './ProfitStopLossValueCalculationMethod.utils';

type Props = {
  triggerType: TriggerType;
  profitLossValueMethod: ProfitLossValueMethod;
  setProfitLossValueMethod: React.Dispatch<React.SetStateAction<ProfitLossValueMethod>>;
};

const ProfitStopLossValueCalculationMethod: React.FC<Props> = ({
  triggerType,
  profitLossValueMethod,
  setProfitLossValueMethod,
}) => {
  const { label, targetLabel, triggerPriceLabel } = useProfitStopLossValueCalculationMethodUI({ triggerType });

  return (
    <Stack paddingX={2} spacing={1}>
      <Typography fontWeight={500} fontSize={14}>
        {label}
      </Typography>
      <Stack direction='row' spacing={0.5}>
        <TabButton
          id='profit-target'
          active={profitLossValueMethod === ProfitLossValueMethod.AmountTarget}
          onClick={() => setProfitLossValueMethod(ProfitLossValueMethod.AmountTarget)}
          size='md'
        >
          {targetLabel}
        </TabButton>
        <TabButton
          id='target-price'
          active={profitLossValueMethod === ProfitLossValueMethod.TriggerPrice}
          onClick={() => setProfitLossValueMethod(ProfitLossValueMethod.TriggerPrice)}
          size='md'
        >
          {triggerPriceLabel}
        </TabButton>
      </Stack>
    </Stack>
  );
};

export { ProfitStopLossValueCalculationMethod };

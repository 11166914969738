import { AnchorHTMLAttributes, HTMLAttributes } from 'react';

import { VariantProps, cva } from 'cva';

export type BodyProps = Omit<HTMLAttributes<HTMLParagraphElement> & VariantProps<typeof bodyVariants>, 'style'>;
export type BodyLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement> & VariantProps<typeof bodyVariants>, 'style'>;

export const bodyVariants = cva('', {
  variants: {
    color: {
      accent: 'text-color-text-accent',
      primary: 'text-color-text-primary',
      tertiary: 'text-color-text-tertiary',
      secondary: 'text-color-text-secondary',
      selected: 'text-color-text-selected',
      white: 'text-white',
      success: 'text-color-text-success',
      error: 'text-color-text-error',
      inverse: 'text-color-text-inverse',
      warning: 'text-color-text-warning',
      disabled: 'text-color-text-disabled',
    },
    size: {
      large: 'font-sans text-16 line-height-20 letter-spacing-base',
      medium: 'font-sans text-14 line-height-20 letter-spacing-base',
      small: 'font-sans text-12 line-height-16 letter-spacing-base',
      xsmall: 'font-sans text-10 line-height-12 letter-spacing-base',
    },
    weight: {
      none: 'font-400',
      emphasis: 'font-500',
      bold: 'font-600',
    },
    loading: {
      true: 'animate-pulse !text-color-background-surface-active line-through min-w-[96px] bg-color-background-surface-active',
      false: '',
    },
    overflow: {
      truncate: 'truncate',
      none: '',
    },
  },
  compoundVariants: [
    {
      size: 'large',
      loading: true,
      class: 'decoration-[18px]',
    },
    {
      size: 'medium',
      loading: true,
      class: 'decoration-[16px]',
    },
    {
      size: 'small',
      loading: true,
      class: 'decoration-[14px]',
    },
    {
      size: 'xsmall',
      loading: true,
      class: 'decoration-[12px]',
    },
  ],
  defaultVariants: {
    size: 'medium',
    weight: 'none',
    overflow: 'none',
  },
});

import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import Radio from '@mui/material/Radio';
import { StackProps } from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  onClick: (value: any) => void;
  checked: boolean;
  label: string;
  value: any;
  stackProps?: StackProps['sx'];
  description?: string;
  icon?: JSX.Element;
  disabled?: boolean;
};

const BORDER_RADIUS = pxToRem(4);

export const SelectionBox: React.FC<Props> = ({
  description,
  stackProps,
  disabled,
  checked,
  onClick,
  label,
  value,
  icon,
}) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{ width: '100%', alignItems: 'flex-start', textAlign: 'left', borderRadius: BORDER_RADIUS }}
      onClick={() => onClick(value)}
      disabled={disabled}
    >
      <Stack
        border={checked ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[100]}`}
        bgcolor={checked ? theme.palette.action.hover : undefined}
        justifyContent='space-between'
        borderRadius={BORDER_RADIUS}
        alignItems='center'
        direction='row'
        paddingY={0.5}
        paddingX={2}
        width='100%'
        spacing={2}
        sx={{
          transition: '0.2s',
          cursor: 'pointer',
          ...stackProps,
        }}
      >
        <Stack spacing={2} direction='row' alignItems='center'>
          {icon}
          <Stack spacing={1}>
            <Typography fontSize={pxToRem(14)} fontWeight={500}>
              {label}
            </Typography>
            {description ? (
              <Typography fontSize={pxToRem(14)} color='text.secondary'>
                {description}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        <Radio checked={checked} sx={{ userSelect: 'none' }} />
      </Stack>
    </ButtonBase>
  );
};

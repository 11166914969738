import React from 'react';

import { CategoryFilled, TradeFilled } from '@swyftx/aviary/icons/filled';
import { Category, Trade } from '@swyftx/aviary/icons/outlined';

import { WalletType, WalletTypeTab } from '@Wallet/types';

import { AppFeature } from 'src/config';

export const WalletTypeTabs: WalletTypeTab[] = [
  {
    id: 'wallet-type-tab-all',
    type: 'all',
    feature: AppFeature.Wallets,
    value: WalletType.ALL_WALLETS,
    label: 'overview.tabs.standard.all',
    miniLabel: 'overview.tabs.mini.all',
    leading: <Category id='wallet-type-tab-all' className='h-20 w-20' />,
    activeLeading: <CategoryFilled id='wallet-type-tab-all-active' className='h-20 w-20' />,
  },
  {
    id: 'wallet-type-tab-trading',
    type: 'trade',
    feature: AppFeature.Wallets,
    value: WalletType.TRADING_WALLETS,
    label: 'overview.tabs.standard.trading',
    miniLabel: 'overview.tabs.mini.trading',
    leading: <Trade id='wallet-type-tab-trading' className='h-20 w-20' />,
    activeLeading: <TradeFilled id='wallet-type-tab-trading-active' className='h-20 w-20' />,
  },
];

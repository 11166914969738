import React, { useEffect, useMemo } from 'react';

import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart';
import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { AssetColors } from '@utils/assets';

import { AutoInvestAsset } from 'src/lib/auto-invest/autoInvest.types';
import { AutoInvestDonutChartTooltip } from 'src/lib/auto-invest/components/AutoInvestDonutChart/AutoInvestDonutChartTooltip';

type OtherAutoInvestBreakdownItem = {
  asset?: string;
  value: string;
  color: string;
  title: string;
  subTitle: string;
};

type AutoInvestBreakdownItem = {
  y: number;
  title: string;
  assetCode: string;
  icon?: React.ReactNode;
  color: string;
  value: string;
  portfolioPercentage: string;
  otherData: OtherAutoInvestBreakdownItem[];
};

const DEFAULT_COLOR = 'var(--color-background-surface-active)';
const SIZE = 220;

type Props = {
  autoInvestAssets: AutoInvestAsset[];
};

const AutoInvestDonutChart: React.FC<Props> = ({ autoInvestAssets }) => {
  const { externalMutations, setExternalMutations } = useDonutChartMutations();

  const breakDownItem: AutoInvestBreakdownItem[] = useMemo(
    () =>
      autoInvestAssets.map((asset) => ({
        title: asset.assetCode,
        y: asset.percentage,
        assetCode: asset.assetCode,
        color: AssetColors[asset.assetCode],
        icon: <AssetIcon asset={asset.assetCode} size={20} />,
        value: asset.percentage.toString(),
        portfolioPercentage: asset.percentage.toString(),
        percentage: `${asset.percentage}%`,
        otherData: [
          {
            asset: asset.assetCode,
            value: asset.percentage.toString(),
            color: AssetColors[asset.assetCode],
            title: asset.assetCode,
            subTitle: asset.percentage.toString(),
          },
        ],
      })),
    [autoInvestAssets],
  );

  useEffect(() => {
    setExternalMutations([
      {
        childName: ['auto-invest'],
        target: ['data'],
        eventKey: 'all',
        mutation: ({ style, index }) => ({
          style: { ...style, fill: breakDownItem[index].color || DEFAULT_COLOR },
        }),
      },
    ]);
  }, [breakDownItem, setExternalMutations]);

  return (
    <FlexLayout alignItems='start' direction='row' justifyContent='center' spacing={0} className='w-full'>
      <DonutChart
        id='auto-invest'
        loading={false}
        innerRadius={SIZE / 2 - 4}
        height={SIZE}
        data={breakDownItem}
        externalMutations={externalMutations}
        setExternalMutations={setExternalMutations}
        tooltipElement={<AutoInvestDonutChartTooltip chartId='auto-invest' />}
      />
    </FlexLayout>
  );
};

export { AutoInvestDonutChart };

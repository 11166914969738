import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import {
  Chat,
  CrossInCircle,
  Locked,
  Password,
  TickInCircle,
  Trade,
  TwoFactorAuthentication,
} from '@swyftx/aviary/icons/outlined';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';
import { SettingsInfoItem } from '@swyftx/aviary/molecules/SettingsItem/SettingsInfoItem';

import Links from '@shared/constants/links';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { AutoLogoutOptions, ChangePassword, SMSRecovery, SecurityImage, TwoFactorVerification } from './components';
import { OTCTrades } from './components/ActionElements/OTCTrades';
import { SecurityLink } from './components/SecurityLink';

const ProfileSecurity: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;
  const { t } = useTranslation('profile.security');

  const mfaEnabled = userProfile?.metadata?.mfa_enabled;
  const otcInvited = Boolean(userProfile?.accountStatus?.otcInvited);

  const healthCheckItems = useMemo(
    () => [
      <SettingsItem
        icon={<TickInCircle className='stroke-color-icon-success' />}
        title={t('overview.healthCheck.complexPassword.title')}
        key='overview.healthCheck.complexPassword.title'
        description={t('overview.healthCheck.complexPassword.subTitle')}
      />,
      <SettingsItem
        icon={<TickInCircle className='stroke-color-icon-success' />}
        title={t('overview.healthCheck.breaches.title')}
        key='overview.healthCheck.breaches.title'
        description={t('overview.healthCheck.breaches.subTitle')}
      />,
      <SettingsItem
        icon={
          mfaEnabled ? (
            <TickInCircle className='stroke-color-icon-success' />
          ) : (
            <CrossInCircle className='stroke-color-icon-error' />
          )
        }
        title={mfaEnabled ? t('overview.healthCheck.2fa.title2fa') : t('overview.healthCheck.2fa.title')}
        description={t('overview.healthCheck.2fa.subTitle')}
        key='overview.healthCheck.2fa.subTitle'
      />,
    ],
    [mfaEnabled, t],
  );

  const configureDataItems = useMemo(
    () => [
      <SettingsItem
        icon={<Password className='text-color-text-primary' />}
        title={t('overview.configureData.changePassword.title')}
        key='overview.configureData.changePassword.title'
        description={t('overview.configureData.changePassword.subTitle')}
        spotLightElementId='change-password-item'
        action={<ChangePassword />}
      />,
      <SettingsItem
        spotLightElementId='two-factor-authentication-item'
        icon={<TwoFactorAuthentication className='text-color-text-primary' />}
        title={t('overview.configureData.2faVerification.title')}
        key='overview.configureData.2faVerification.title'
        description={t('overview.configureData.2faVerification.subTitle')}
        action={
          <FlexLayout spacing={12} className='items-center justify-between'>
            <Chip color='primary' variant='subtle'>
              Recommended
            </Chip>
            <TwoFactorVerification />
          </FlexLayout>
        }
      />,
      ...(mfaEnabled
        ? [
            <SettingsItem
              icon={<Chat className='text-color-text-primary' />}
              title={t('overview.configureData.smsVerification.title')}
              key='overview.configureData.smsVerification.title'
              description={t('overview.configureData.smsVerification.subTitle')}
              action={<SMSRecovery />}
            />,
          ]
        : []),
      <SettingsItem
        icon={<Locked className='text-color-text-primary' />}
        title={t('overview.configureData.autoLogout.title')}
        key='overview.configureData.autoLogout.title'
        description={t('overview.configureData.autoLogout.subTitle')}
        spotLightElementId='auto-logout-item'
        action={
          <FlexLayout className='w-[150px] justify-end'>
            <AutoLogoutOptions />
          </FlexLayout>
        }
      />,
      ...(otcInvited
        ? [
            <SettingsItem
              icon={<Trade className='text-color-text-primary' />}
              title={t('overview.configureData.allowOtcTrades.title')}
              key='overview.configureData.allowOtcTrades.title'
              description={t('overview.configureData.allowOtcTrades.subTitle')}
              action={<OTCTrades />}
            />,
          ]
        : []),
    ],
    [mfaEnabled, otcInvited, t],
  );

  const learnItems = useMemo(
    () => [
      <SettingsInfoItem
        title={t('overview.learn.securityMeasures.title')}
        key='overview.learn.securityMeasures.title'
        description={t('overview.learn.securityMeasures.subTitle')}
        infoAction={
          <SecurityLink label={t('overview.learn.securityMeasures.linkLabel')} url={Links.learn.security} hideArrow />
        }
        infoItem={<SecurityImage url={Links.learn.security} imageUrl='/assets/images/learn/security-measures.png' />}
      />,
      <SettingsInfoItem
        title={t('overview.learn.complexPassword.title')}
        key='overview.learn.complexPassword.title'
        description={t('overview.learn.complexPassword.subTitle')}
        infoAction={
          <SecurityLink
            label={t('overview.learn.complexPassword.linkLabel')}
            url={Links.help.securityBestPracticesHelper}
            hideArrow
          />
        }
        infoItem={
          <SecurityImage
            url={Links.help.securityBestPracticesHelper}
            imageUrl='/assets/images/learn/secure-password.png'
          />
        }
      />,
    ],
    [t],
  );

  return (
    <FlexLayout direction='column' spacing={24}>
      <SettingsCluster
        title={t('overview.healthCheck.title')}
        key='overview.healthCheck.title'
        items={healthCheckItems}
      />
      <SettingsCluster
        title={t('overview.configureData.title')}
        key='overview.configureData.title'
        items={configureDataItems}
      />
      <SettingsCluster title={t('overview.learn.title')} key='overview.learn.title' items={learnItems} />
    </FlexLayout>
  );
});

export { ProfileSecurity };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { cn } from '@shared/utils/lib/ui';

import { DateTime } from 'luxon';

interface Props {
  orderDate: number;
  alignment?: 'start' | 'end';
  size?: 'medium' | 'small';
}

export const OrderDate: React.FC<Props> = (props) => {
  const { orderDate, alignment, size = 'medium' } = props;
  return (
    <FlexLayout direction='row' justifyContent={alignment} className='min-w-[130px]'>
      <NumericDataItem
        data={DateTime.fromMillis(orderDate).toFormat(size === 'medium' ? 'dd/MM/yyyy h:mma' : 'dd/MM/yyyy')}
        size='small'
        color='primary'
        className={cn('min-w-[130px]', alignment === 'end' ? '!items-end' : '')}
      />
    </FlexLayout>
  );
};

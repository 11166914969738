import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { TickInCircle } from '@swyftx/aviary/icons/outlined';
import { Button, Divider, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';

type Props = {
  onClose: () => void;
  name?: string;
  accountName?: string;
};

const ICON_SIZE = pxToRem(60);
const BUTTON_WIDTH = pxToRem(200);

export const EntityAddMemberResultStep: React.FC<Props> = ({ onClose, name, accountName }) => {
  const { t } = useTranslation('profile');

  return (
    <Wizard.Step onClose={onClose} hideActions>
      <Box paddingY={3} display='flex' alignItems='center' justifyContent='center'>
        <Stack
          alignItems='center'
          spacing={2}
          sx={{
            '& .MuiSvgIcon-root': {
              height: `${ICON_SIZE} !important`,
              width: `${ICON_SIZE} !important`,
            },
          }}
        >
          <TickInCircle className='text-color-text-success' />
          <Typography fontSize={pxToRem(20)} fontWeight={700} textAlign='center'>
            {t('entities.addMemberModal.result.title')}
          </Typography>
          <Typography fontSize={pxToRem(14)} color='text.secondary' textAlign='center' maxWidth='75%'>
            {t('entities.addMemberModal.result.description', {
              name: name || 'Member',
              accountName: accountName || 'entity',
            })}
          </Typography>
          <Divider variant='fullWidth' />
          {/* Isnt the cancel button redundant because they both do the same things? TODO ask design */}
          {/* <Stack direction='row' spacing={3} alignItems='center'>
            <Button color='inherit' onClick={onClose} sx={{ color: 'text.secondary', width: BUTTON_WIDTH }}>
              {t('entities.addMemberModal.buttonLabels.cancel')}
            </Button> */}
          <Button variant='contained' color='primary' onClick={onClose} disableElevation sx={{ width: BUTTON_WIDTH }}>
            {t('entities.addMemberModal.buttonLabels.action')}
          </Button>
          {/* </Stack> */}
        </Stack>
      </Box>
    </Wizard.Step>
  );
};

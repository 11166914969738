import { SafeBig } from './@types';
import { safeBig } from './SafeBig';

// export new constructor
export const Big = safeBig;

// export types
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Big = SafeBig;

export { tryParseBig } from './SafeBig';

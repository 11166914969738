import React from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  selected: boolean;
  asset?: Asset;
  balance: string;
  onSelect: (id: number) => void;
};

const DustableAssetItem: React.FC<Props> = observer(({ selected, asset, balance, onSelect }) => {
  const { convertRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  if (!baseAsset || !asset) return null;

  return (
    <FlexLayout
      alignItems='center'
      justifyContent='space-between'
      className='cursor-pointer rounded-[8px] p-8 hover:bg-color-background-surface-hover'
      onClick={() => onSelect(asset.id)}
    >
      <FlexLayout spacing={16} alignItems='center'>
        <Checkbox checked={selected} />
        <AssetIcon asset={asset} size={20} />
        <FlexLayout direction='column'>
          <Body size='small'>{asset.name}</Body>
          <Body size='small' color='secondary'>
            {asset.code}
          </Body>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout direction='column' alignItems='end'>
        <Numeric color='primary'>{formatCurrency(balance, asset)}</Numeric>
        <Numeric color='secondary'>
          {formatCurrency(convertRate(asset, baseAsset, balance, 'bidPrice'), baseAsset)}
        </Numeric>
      </FlexLayout>
    </FlexLayout>
  );
});

export { DustableAssetItem };

import { useState } from 'react';

const useRecaptcha = () => {
  const [captchaRefresh, setCaptchaRefresh] = useState<boolean>(false);
  const [captchaToken, setCaptchaToken] = useState<string>('');

  return {
    captchaRefresh,
    captchaToken,
    setCaptchaRefresh,
    setCaptchaToken,
  };
};

export { useRecaptcha };

import React from 'react';

import { Select } from '@swyftx/aviary/atoms/Select';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';

import { RecurringOrderSource } from '@shared/enums';

import { useFundOtherWithOptions } from '../../hooks';

type Props = {
  value: RecurringOrderSource;
  disabled?: boolean;
  onChange: (value: RecurringOrderSource) => void;
};

const AutoInvestFundWith: React.FC<Props> = ({ value, disabled = false, onChange }) => {
  const { fundOrderWithOptions } = useFundOtherWithOptions();

  return (
    <Tooltip title={disabled ? 'Unable to change funding source while order is paused' : ''}>
      <span className='w-full'>
        <Select
          title='Fund order with'
          className={disabled ? 'cursor-not-allowed' : ''}
          items={fundOrderWithOptions}
          value={value}
          disabled={disabled}
          onValueChange={(val) => onChange(val as RecurringOrderSource)}
        />
      </span>
    </Tooltip>
  );
};

export { AutoInvestFundWith };

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';
import { useDemoMode } from 'src/lib/utils/hooks';

type Props = {
  onClose: () => void;
};

const DemoModeLearnMoreModal: React.FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation('common');
  const { setDemoMode } = useDemoMode();

  const onCancel = useCallback(() => {
    setDemoMode(false);
    onClose();
  }, [onClose, setDemoMode]);

  return (
    <Modal id='demo-mode-learn-more-modal' open title={t('demoMode.learnMoreModal.title')} onClose={onClose}>
      <FlexLayout direction='column' className='p-24 pt-0' spacing={8}>
        <Body>{t('demoMode.learnMoreModal.subTitle')}</Body>
        <FlexLayout direction='column'>
          <Body>{t('demoMode.learnMoreModal.featuresTitle')}</Body>
          <ul className='pt-8'>
            {Array.from(t('demoMode.learnMoreModal.disabledFeatures', { returnObjects: true })).map((feature) => (
              <li key={feature}>
                <Body size='small' color='secondary'>
                  - {feature}
                </Body>
              </li>
            ))}
          </ul>
        </FlexLayout>
        <FlexLayout direction='row' alignItems='center' justifyContent='end' spacing={8}>
          <Button variant='outlined' onClick={onCancel}>
            {t('demoMode.learnMoreModal.buttonLabels.cancel')}
          </Button>
          <Button color='primary' onClick={onClose}>
            {t('demoMode.learnMoreModal.buttonLabels.understand')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { DemoModeLearnMoreModal };

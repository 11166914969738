import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Trade: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M20.7925 7.03442C20.7925 7.24909 20.7023 7.44269 20.5577 7.57941L16.4749 11.6434C16.1813 11.9356 15.7065 11.9345 15.4142 11.641C15.122 11.3474 15.1231 10.8725 15.4167 10.5803L18.2255 7.78442L6.42469 7.78442C6.01048 7.78442 5.67469 7.44864 5.67469 7.03442C5.67469 6.62021 6.01048 6.28442 6.42469 6.28442L18.2262 6.28442L15.4167 3.48785C15.1231 3.19564 15.122 2.72076 15.4142 2.4272C15.7065 2.13363 16.1813 2.13253 16.4749 2.42474L20.5716 6.50252C20.6281 6.55881 20.6748 6.62318 20.7104 6.69293C20.7569 6.78379 20.7855 6.8854 20.7913 6.99305C20.7921 7.00668 20.7925 7.02071 20.7925 7.03442Z'
      fill='currentColor'
    />
    <path
      d='M17.3276 17.7122C17.7418 17.7122 18.0776 17.3764 18.0776 16.9622C18.0776 16.5479 17.7418 16.2122 17.3276 16.2122H5.52572L8.33524 13.4156C8.62881 13.1234 8.62991 12.6485 8.33769 12.3549C8.04548 12.0614 7.57061 12.0603 7.27704 12.3525L3.18037 16.4303C3.03897 16.571 2.95947 16.7623 2.95947 16.9618C2.95947 17.1613 3.03897 17.3526 3.18037 17.4934L7.27704 21.5711C7.57061 21.8634 8.04548 21.8623 8.33769 21.5687C8.62991 21.2751 8.62881 20.8003 8.33524 20.508L5.52641 17.7122H17.3276Z'
      fill='currentColor'
    />
  </svg>
);

export { Trade };

import { useContext, useMemo } from 'react';

import { Body } from '@swyftx/aviary/atoms/Typography';

import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { SwyftxProTradeContext } from '../../context';

const useSwyftxProTradeStopLossDetails = () => {
  const { stopLossPrice, triggerPrice, total, baseAsset, selectedAsset, amount, tradeType, showMarketStopLoss } =
    useContext(SwyftxProTradeContext);
  const { lowestFee } = useCurrentUserFees();
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;

  const stopLossPercentage = useMemo(() => {
    if (!triggerPrice || !stopLossPrice) return Big('0');

    const priceDiff = Big(stopLossPrice).minus(triggerPrice);
    return priceDiff.div(triggerPrice).minus(lowestFee).times(100);
  }, [lowestFee, stopLossPrice, triggerPrice]);

  const stopLossTotal = useMemo(
    () => (stopLossPercentage.abs().gt(0) ? Big(total).times(Big(1).plus(stopLossPercentage.div(100))) : Big(0)),
    [stopLossPercentage, total],
  );

  const simpleStopLossTotal = useMemo(
    () => (stopLossPrice ? Big(amount).times(Big(stopLossPrice)) : Big(0)),
    [amount, stopLossPrice],
  );

  const stopLossTotalLoss = useMemo(
    () => (stopLossTotal ? Big(stopLossTotal).minus(total).abs() : Big(0)),
    [stopLossTotal, total],
  );

  const stopLossError = useMemo(() => {
    if (
      (tradeType === 'market' && !showMarketStopLoss) ||
      !triggerPrice ||
      !baseAsset ||
      !stopLossTotalLoss ||
      !stopLossPrice ||
      Big(total).lte(0) ||
      stopLossPercentage.eq(0)
    )
      return null;

    if (Big(triggerPrice).gt(0) && Big(stopLossPrice).gt(0) && Big(triggerPrice).lte(stopLossPrice || '0')) {
      return (
        <div>
          <Body color='error' size='small' className='inline'>
            Stop loss price must be lower than the current price:{' '}
          </Body>
          <Body color='error' size='small' className='inline'>
            {formatCurrency(triggerPrice, baseAsset, { hideCode: true, appendCode: false })} {baseAsset?.code}/
            {selectedAsset?.code}
          </Body>
        </div>
      );
    } else if (Big(total).lte(getMinimumOrderAmount(baseAsset))) {
      return (
        <Body color='error' size='small' className='inline'>
          The total trade must be more than {formatCurrency(getMinimumOrderAmount(baseAsset), baseAsset)} to create a
          stop loss order
        </Body>
      );
    } else if (stopLossTotalLoss && Big(total).minus(stopLossTotalLoss).lt(getMinimumOrderAmount(baseAsset))) {
      const maxLoss = Big(total).minus(getMinimumOrderAmount(baseAsset));
      return (
        <Body color='error' size='small' className='inline'>
          Total Loss amount must not be more than: {formatCurrency(maxLoss, baseAsset)} {baseAsset.code}
        </Body>
      );
    }

    return null;
  }, [
    baseAsset,
    getMinimumOrderAmount,
    selectedAsset?.code,
    showMarketStopLoss,
    stopLossPercentage,
    stopLossPrice,
    stopLossTotalLoss,
    total,
    tradeType,
    triggerPrice,
  ]);

  return {
    stopLossTotalLoss,
    stopLossPercentage,
    stopLossTotal,
    stopLossPrice,
    stopLossError,
    simpleStopLossTotal,
  };
};

export { useSwyftxProTradeStopLossDetails };

import { Asset } from '@shared/api';
import { MAX_TRADE_ASSETS } from '@shared/constants';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { useUniversalTradeUtilityStore } from './useUniversalTradeUtilityStore';

/** Utility Hook for interacting with the Universal Trade Panel */
const useUniversalTradePanel = () => {
  const { setTradeAssets, setShowGlobalTrade } = UniversalTradeStore;
  const { resetTradeAssets } = useUniversalTradeUtilityStore();
  const baseAsset = useBaseAsset();

  const showTradePanel = (show: boolean) => {
    setShowGlobalTrade(show);
  };

  const addAssetsToBuy = (assets: Asset[], openPanel = false) => {
    let assetsToBuy = assets.filter((a) => !a.buyDisabled).map((asset) => asset.id);

    if (assetsToBuy.length > MAX_TRADE_ASSETS) {
      assetsToBuy = assets
        .filter((a) => !a.buyDisabled)
        .sort((a, b) => a.rank - b.rank)
        .slice(0, MAX_TRADE_ASSETS)
        .map((asset) => asset.id);
    }

    resetTradeAssets();
    setTradeAssets(assetsToBuy, TradeSide.To, TradeAssetAction.Replace);
    if (baseAsset) setTradeAssets([baseAsset.id], TradeSide.From, TradeAssetAction.Replace);

    if (openPanel) showTradePanel(true);
  };

  const addAssetsToSell = (assets: Asset[], openPanel = false) => {
    let assetsToSell = assets.filter((a) => a.sellEnabled).map((asset) => asset.id);

    if (assetsToSell.length > MAX_TRADE_ASSETS) {
      assetsToSell = assets
        .filter((a) => a.sellEnabled)
        .sort((a, b) => a.rank - b.rank)
        .slice(0, MAX_TRADE_ASSETS)
        .map((asset) => asset.id);
    }

    resetTradeAssets();
    setTradeAssets(assetsToSell, TradeSide.From, TradeAssetAction.Replace);
    if (baseAsset) setTradeAssets([baseAsset.id], TradeSide.To, TradeAssetAction.Replace);

    if (openPanel) showTradePanel(true);
  };

  return {
    addAssetsToBuy,
    addAssetsToSell,
    showTradePanel,
    resetTradeAssets,
  };
};

export { useUniversalTradePanel };

import React from 'react';

import { useTrustDirectorsStep } from '@routes/EntityOnboarding/hooks/useTrustDirectorsStep';

import { observer } from 'mobx-react-lite';

import { IndividualPageLayout } from './components/IndividualPageLayout';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const TrustAddDirectorsStep: React.FC = observer(() => {
  const {
    continueDisabled,
    handleBack,
    handleSubmit,
    onCancel,
    addMemberOpen,
    toggleAddMemberOpen,
    onAddMember,
    onEditMember,
    handleRemove,
    directors,
    shareholders,
    beneficiaries,
  } = useTrustDirectorsStep();

  return (
    <EntityOnboardingStepContainer
      customTitle='Add directors of corporate trustee'
      submitDisabled={continueDisabled}
      onSubmit={handleSubmit}
      onBack={handleBack}
    >
      <EntityOnboardingPageLayout subtitle="List all individuals who are directors or members of the governing board of your trust's corporate trustee.">
        <IndividualPageLayout
          title='Please add all directors of your corporate trustee:'
          members={directors}
          onAddMember={onAddMember}
          handleRemove={handleRemove}
          onCancel={onCancel}
          addMemberOpen={addMemberOpen}
          toggleAddMemberOpen={toggleAddMemberOpen}
          memberType='director'
          shareholders={shareholders.map((shareholder) => shareholder.individual)}
          beneficiaries={beneficiaries.map((beneficiary) => beneficiary.individual)}
          onEditMember={onEditMember}
        />
      </EntityOnboardingPageLayout>
    </EntityOnboardingStepContainer>
  );
});

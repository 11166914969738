import React, { useCallback, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const IMG_SIZE = '64px';

export const EntityAccountVerifyMembersBanner: React.FC = () => {
  const { theme } = useTailwindTheme();
  const { entityColorIndex } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();

  const [open, setOpen] = useState<boolean>(true);

  const handleDismiss = useCallback(() => {
    localStorage.setItem(StorageKey.DISMISS_ENTITY_VERIFY_MEMBERS_BANNER, 'true');
    setOpen(false);
  }, []);

  const onNavigate = useCallback(() => {
    navigate(NavigationURLs.ProfileEntityVerification);
  }, [navigate]);

  if (!open) return null;

  return (
    <FlexLayout className='w-full'>
      <Card className='w-full p-24'>
        <FlexLayout direction='row' className='items-center justify-between'>
          <FlexLayout alignItems='center' justifyContent='space-between' spacing={16}>
            <img
              src={`/assets/images_new/entity${(entityColorIndex ?? 0) + 1}/${theme}/deposit.png`}
              alt='deposit'
              width={IMG_SIZE}
              height={IMG_SIZE}
            />

            <FlexLayout direction='column' className='w-full' spacing={4}>
              <Heading size='h6'>Member verification required</Heading>
              <Body color='secondary' size='small'>
                Check which members are pending verification and give them a nudge to complete it.
              </Body>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='row' spacing={8}>
            <Button variant='outlined' onClick={handleDismiss}>
              Dismiss
            </Button>
            <Button onClick={onNavigate}>View members</Button>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
};

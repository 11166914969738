import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Collapse } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Slider } from '@swyftx/aviary/atoms/Slider/Slider';
import { Body, Numeric, Utility } from '@swyftx/aviary/atoms/Typography';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { Skeleton, Stack } from '@swyftx/react-web-design-system';

import { AssetReceiveButton } from '@global-components/Assets';
import { LabelSwitch } from '@global-components/LabelSwitch';
import { LabelSwitchSide } from '@global-components/LabelSwitch/LabelSwitch';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { PriceInput } from '@global-components/PriceInput';
import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UniversalTradeStore, UserStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { cn } from '@shared/utils/lib/ui';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useAvo } from '@hooks/Avo/useAvo';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';
import { AssetColors } from '@utils/assets';

import Color from 'color';
import { observer } from 'mobx-react-lite';
import { usePriceScale } from 'src/lib/trade/hooks/General/usePriceScale';

import { Modals } from '../../Modals/Modals.enum';

export enum Balance {
  Trading,
  Staking,
}

type Props = {
  title?: string;
  asset: Asset;
  error?: string | null;
  baseAsset: Asset;
  flippable?: boolean;
  balanceToUse: Balance;
  value: string;
  onChange: (val: AssetInputOnChangeValue) => void;
  slider?: boolean;
  onBuyAsset?: () => void;
  onReceive?: () => void;
  initialFlip?: boolean;
  onAssetFlippedTo?: (asset: Asset) => void;
  estimatedValue?: string;
  isBalanceRequired?: boolean;
  loading?: boolean;
  maxDecimals?: number;
};

export type AssetInputOnChangeValue = {
  [key: string]: string;
  value: string;
};

const LOG_TAG = 'ASSET_INPUT';
// This component will eventually replace TradeInput
const AssetInput: React.FC<Props> = observer(
  ({
    title,
    asset,
    error,
    baseAsset,
    balanceToUse,
    slider,
    maxDecimals,
    value,
    onChange,
    onBuyAsset,
    onReceive,
    flippable = true,
    initialFlip = false,
    isBalanceRequired = true,
    onAssetFlippedTo,
    estimatedValue,
    loading = false,
  }) => {
    const [cacheValue, setCacheValue] = useState<string>();
    const [flipped, setFlipped] = useState<boolean>(initialFlip);
    const [percent, setPercent] = useState<number>();
    const { trading, staking } = useAssetBalance(asset);
    const { convertRate } = RatesStore.useRatesStore;
    const { canTransferCrypto } = UserStore.useUserStore;
    const { setTradeAssets, setShowGlobalTrade } = UniversalTradeStore;
    const { canTrade } = useUniversalTradeUtilityStore();
    const { t } = useTranslation('common', { keyPrefix: 'assetInput' });
    const { openModal } = useModal();
    const { pathname } = useLocation();
    const avo = useAvo();
    const { isLightMode } = useTailwindTheme();

    const assetBalance = useMemo(
      () => (balanceToUse === Balance.Staking ? staking : trading),
      [balanceToUse, staking, trading],
    );
    const primaryAsset = useMemo(() => (flipped ? baseAsset : asset), [flipped, asset, baseAsset]);
    const secondaryAsset = useMemo(() => (flipped ? asset : baseAsset), [flipped, asset, baseAsset]);
    const { priceScale } = usePriceScale(secondaryAsset);

    const isFiat = useMemo(() => assetService.isAssetFiat(primaryAsset.id), [primaryAsset]);

    const getRate = useCallback(
      (val?: string) => convertRate(primaryAsset, secondaryAsset, val || value, 'midPrice')?.toFixed(priceScale),
      [convertRate, priceScale, primaryAsset, secondaryAsset, value],
    );

    const onChangeValue = useCallback(
      (newValue: string, calcPercentage = true) => {
        const scale = maxDecimals ?? (isFiat ? 2 : 8);
        const decimals = newValue.split('.')[1] || '';
        const lastCharDecimal = newValue[newValue.length - 1] === '.';

        // Only format the value if we have formatted our decimal
        const formattedValue =
          !newValue.length || lastCharDecimal
            ? newValue
            : Big(newValue).toFixed(Math.min(decimals.length, scale), 0).toString();

        if (formattedValue !== '.' && Number.isNaN(Number(formattedValue))) return;

        if (calcPercentage) {
          const percentage = Big(newValue).div(assetBalance.balance).times(100).toNumber();

          setPercent(Math.min(100, Math.floor(percentage)));
        }

        setCacheValue(formattedValue);

        onChange({
          [secondaryAsset.id]: formattedValue ? getRate(formattedValue) : formattedValue,
          [primaryAsset.id]: formattedValue,
          value: formattedValue,
        });
      },
      [isFiat, primaryAsset.id, onChange, secondaryAsset.id, getRate, assetBalance.balance, maxDecimals],
    );

    const onChangePercentage = (newPercentage: number) => {
      const percentValue = Big(newPercentage).div(100);
      setPercent(newPercentage || undefined);

      onChangeValue(Big(assetBalance.balance).times(percentValue).toString(), false);
    };

    const onFlipAsset = () => {
      const newFlip = !flipped;
      setFlipped(newFlip);
      onChangeValue('');

      if (onAssetFlippedTo) {
        onAssetFlippedTo(newFlip ? baseAsset : asset);
      }
    };

    const receiveAsset = () => {
      if (onReceive) {
        onReceive();
      } else {
        avo.depositFundsTapped({
          screen: pathname,
          component: LOG_TAG,
        });
        openModal(Modals.DepositReceive, { selectedAsset: primaryAsset });
      }
    };

    const buyAsset = () => {
      if (onBuyAsset) onBuyAsset();
      setTradeAssets([asset.id], TradeSide.To, TradeAssetAction.Replace, [baseAsset.id]);
      setShowGlobalTrade(true);
    };

    useEffect(() => {
      if (value !== undefined && cacheValue !== value) onChangeValue(value);

      // Sometimes eslint doesn't know what it needs
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const isBuyDisabled = Boolean(!canTrade(true) || asset.delisting || asset.buyDisabled);
    const hasBalance = !isBalanceRequired || Big(assetBalance.balance).gt(0);
    const isAssetBase = asset.code === baseAsset.code;

    if (loading) {
      return (
        <FlexLayout direction='column' spacing={8}>
          {title && <Skeleton variant='text' height='21px' width='100px' />}
          <FlexLayout justifyContent='space-between' direction='row'>
            <FlexLayout direction='row' alignItems='center'>
              <Skeleton variant='text' height='18px' width='120px' />
            </FlexLayout>
            {flippable && !isAssetBase && hasBalance && (
              <FlexLayout className='w-full' alignItems='center' justifyContent='end'>
                <Skeleton variant='rounded' height='20px' width='42px' />
                <Skeleton variant='rounded' height='20px' width='42px' />
              </FlexLayout>
            )}
          </FlexLayout>
          <Skeleton variant='rounded' height='142px' width='100%' />
        </FlexLayout>
      );
    }

    return (
      <Stack spacing={1}>
        {title && (
          <Body weight='emphasis' color='primary'>
            {title}
          </Body>
        )}
        <FlexLayout justifyContent='space-between' direction='row' alignItems='end'>
          <FlexLayout direction='row' alignItems='end' spacing={4}>
            <Utility>{balanceToUse === Balance.Trading ? 'Available: ' : 'Available Earn funds:'}</Utility>
            <FormattedText
              typographyProps={{
                color: 'secondary',
                size: 'small',
              }}
              value={assetBalance.balance}
              currency={asset}
              formatOpts={{ hideCode: false, displayPriceScale: true, appendCode: true }}
            />
          </FlexLayout>
          {flippable && !isAssetBase && hasBalance && (
            <LabelSwitch
              initialSide={initialFlip ? LabelSwitchSide.Right : LabelSwitchSide.Left}
              rightLabel={baseAsset.code}
              leftLabel={asset.code}
              onChange={onFlipAsset}
            />
          )}
        </FlexLayout>
        <Card>
          <Stack direction='column'>
            {!hasBalance && (
              <Stack direction='column' alignItems='center' textAlign='center' spacing={1} padding={2}>
                <Body weight='bold' color='primary'>
                  {t('labels.noFunds')}
                </Body>
                <Body size='small' color='secondary'>
                  {balanceToUse === Balance.Staking ? t('labels.noFundsForEarn') : t('labels.noFundsForWithdraw')}
                </Body>
                <img src='/assets/images/no-earn-funds.png' width='120px' />
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='center'
                  width='100%'
                  spacing={2}
                  marginTop='1rem !important'
                >
                  {canTransferCrypto() && (
                    <AssetReceiveButton asset={primaryAsset} onReceive={receiveAsset} hideIfDepositDisabled />
                  )}
                  <Button
                    variant='filled'
                    size='md'
                    color='success'
                    onClick={buyAsset}
                    className='w-1/2'
                    disabled={isBuyDisabled}
                  >
                    {t('buttonLabels.buy', { code: primaryAsset?.code })}
                  </Button>
                </Stack>
              </Stack>
            )}
            {hasBalance && (
              <>
                <Stack direction='row' alignItems='center' margin={1.5}>
                  <PriceInput asset={primaryAsset} onChange={onChangeValue} value={value} error={Boolean(error)} />
                </Stack>
                {secondaryAsset && !isAssetBase && (
                  <div className='px-16'>
                    <FormattedText
                      typographyProps={{
                        className: cn(flipped ? 'mb-16' : ''),
                        color: error ? 'error' : 'secondary',
                        size: 'small',
                        weight: 'emphasis',
                      }}
                      prefix='~'
                      formatOpts={{ appendCode: true }}
                      hideRateLoadingState={Boolean(estimatedValue)}
                      value={estimatedValue || getRate()}
                      currency={secondaryAsset}
                    />
                  </div>
                )}
                {slider && !flipped && (
                  <FlexLayout
                    className='w-full px-16 py-8'
                    spacing={16}
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <div className='w-full'>
                      <Slider
                        className='w-full'
                        value={Number(percent)}
                        disabled={!assetBalance.balance}
                        marks={[0, 25, 50, 75, 100]}
                        step={1}
                        min={0}
                        sliderColor={
                          isLightMode ? AssetColors[asset.code] : Color(AssetColors[asset.code]).lightness(60).hex()
                        }
                        max={100}
                        onValueChange={([sliderValue]) => onChangePercentage(sliderValue as number)}
                      />
                    </div>

                    <FlexLayout direction='row' alignItems='center' justifyContent='end' className='w-[4.5rem]'>
                      <Input
                        value={percent}
                        onChange={(e) => onChangePercentage(Number(e.target.value))}
                        variant='monospace'
                        placeholder='0'
                        type='number'
                        max={100}
                        min={0}
                        containerClassName='h-28'
                        trailing={<Numeric size='small'>%</Numeric>}
                      />
                    </FlexLayout>
                  </FlexLayout>
                )}
                <Collapse in={Boolean(error)}>
                  <Body size='small' color='error' className='px-8 pb-8'>
                    {error}
                  </Body>
                </Collapse>
              </>
            )}
          </Stack>
        </Card>
      </Stack>
    );
  },
);

export { AssetInput };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  title: string;
  icon: React.ReactNode;
};

const SideBarMobileHeaderCurrency: React.FC<Props> = observer(({ title, icon }) => {
  const baseAsset = useBaseAsset();
  const { setUserCurrency } = UserStore.useUserStore;

  const items = useMemo(() => {
    const baseAssets = assetService.getBaseAssets();
    const assets: Asset[] = [];

    baseAssets.forEach((assetId) => {
      const asset = assetService.getAsset(assetId);

      if (asset) assets.push(asset);
    });

    return assets.map((asset) => ({
      label: asset.code,
      leadingIcon: <AssetIcon asset={asset} size={16} />,
      value: asset.code,
    }));
  }, []);

  return (
    <FlexLayout
      direction='row'
      spacing={24}
      alignItems='center'
      className='cursor-pointer select-none rounded-12 p-10 hover:bg-color-background-surface-hover'
    >
      <FlexLayout className='flex items-center justify-center text-color-text-primary'>{icon}</FlexLayout>
      <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
        <FlexLayout direction='column'>
          <Body weight='emphasis' color='primary'>
            {title}
          </Body>
        </FlexLayout>
        <div>
          <Select
            value={baseAsset?.code}
            size='small'
            title='currency'
            showInputTitle={false}
            items={items}
            className='text-12'
            onValueChange={(val) => {
              const selectedAsset = assetService.getAssetByCode(val);
              if (selectedAsset) {
                setUserCurrency(selectedAsset.id, selectedAsset.code);
              }
            }}
          />
        </div>
      </FlexLayout>
    </FlexLayout>
  );
});

export { SideBarMobileHeaderCurrency };

import { useCallback, useMemo } from 'react';

import { Body } from '@swyftx/aviary/atoms/Typography';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useMarketActivity } from './useMarketActivity';
import { useMarkets } from './useMarkets';
import { MarketActivityCard } from '../types/Markets.types';

const useBuildMarketActivityCards = () => {
  const { navigate } = useNavigateRoute();
  const { assets } = useMarkets();

  const { gainers, losers, newListings } = useMarketActivity({ assets });

  const favouriteAssets = useFavouriteAssets();

  const openAssetListSection = useCallback(
    (route: NavigationURLs) => {
      navigate(route);
    },
    [navigate],
  );

  const items: MarketActivityCard[] = useMemo(
    () => [
      {
        key: 'favourites',
        title: 'Favourites',
        actions:
          favouriteAssets.length >= 10 ? (
            <Body
              color='accent'
              className='cursor-pointer'
              size='xsmall'
              onClick={() => openAssetListSection(NavigationURLs.AssetsFavourites)}
            >
              View all favourites
            </Body>
          ) : (
            <Body
              color='accent'
              className='cursor-pointer'
              size='xsmall'
              onClick={() => openAssetListSection(NavigationURLs.AssetsAll)}
            >
              Explore assets
            </Body>
          ),
        assets: favouriteAssets,
      },
      {
        key: 'gainers',
        title: 'Gainers',
        actions:
          gainers.length >= 10 ? (
            <Body
              color='accent'
              className='cursor-pointer'
              size='xsmall'
              onClick={() => openAssetListSection(NavigationURLs.AssetsGainers)}
            >
              View all gainers
            </Body>
          ) : null,
        assets: gainers,
      },
      {
        key: 'losers',
        title: 'Losers',
        actions:
          losers.length >= 10 ? (
            <Body
              color='accent'
              className='cursor-pointer'
              size='xsmall'
              onClick={() => openAssetListSection(NavigationURLs.AssetsLosers)}
            >
              View all losers
            </Body>
          ) : null,
        assets: losers,
      },
      {
        key: 'new',
        title: 'New',
        actions:
          newListings.length >= 10 ? (
            <Body
              color='accent'
              className='cursor-pointer'
              size='xsmall'
              onClick={() => openAssetListSection(NavigationURLs.AssetsNew)}
            >
              View all new
            </Body>
          ) : null,
        assets: newListings,
      },
    ],
    [favouriteAssets, gainers, losers, newListings, openAssetListSection],
  );

  return items;
};

export { useBuildMarketActivityCards };

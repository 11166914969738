import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

const useDustingCache = () => {
  const queryCache = useQueryClient();

  const invalidateCache = useCallback(() => queryCache.invalidateQueries(['dustMessage']), [queryCache]);

  return {
    invalidateCache,
  };
};

export { useDustingCache };

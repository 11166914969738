import {
  api,
  CompleteMFAEnrolmentParams,
  LoginParams,
  MFAEnrolment,
  MfaLostAuthParams,
  MfaParams,
  MFARequired,
  PasswordlessOTPParams,
  ReCaptchaHeaders,
  ResetPasswordParams,
  SetupMFAEnrolmentResponse,
  CancelMFAEnrolmentResponse,
  SignUpMetaParams,
  SignUpParams,
  UserProfile,
  OTPRequired,
  OTPParams,
  OTPChallengeType,
  OTPSMSFallbackParams,
} from '@shared/api';
import env from '@shared/config';
import { CountriesEnum, ReCaptchaEnum } from '@shared/enums';
import { SwyftxError, isReCaptchaError } from '@shared/error-handler';
import logger from '@shared/logger';
import { assetService, cleanupServices, forceUpdateServices, initServices } from '@shared/services';
import entityService from '@shared/services/entityService';
import storage, { StorageKey } from '@shared/storage';
import { AppStore, UniversalTradeStore, UserStore } from '@shared/store';

import Cookies from 'universal-cookie';
const LOG_TAG = 'AuthenticationService';

const setUserProfile = (userProfile: UserProfile) => {
  const { setUserProfile: updateUserProfile } = UserStore.useUserStore;

  updateUserProfile(userProfile.profile);

  // first access to user country currency, update asset service
  assetService.setUserCountryAsset(userProfile?.profile?.countryCurrency?.id || 0);
};

/**
 * Saves user profile and access token to mobx store.
 * Saves access token to storage to handle page refreshes.
 * Inits all api services
 *
 * @param userProfile user profile
 * @param options
 */
const handleSuccessfulLogin = async (
  userProfile: UserProfile,
  options?: {
    isEntity?: boolean;
    initServices?: boolean;
    setUserProfile?: boolean;
    setDemoMode?: boolean;
  },
) => {
  const { setAuth, setBaseAsset } = UserStore.useUserStore;
  setAuth(userProfile.auth, options?.isEntity);
  setBaseAsset();

  if (options?.setUserProfile) setUserProfile(userProfile);

  // TODO add initThirdPartyServices to this function in a clean way, requires refactor of store function
  //if (options?.initThirdPartyServices) initThirdPartyServices(res.data as UserProfile);
  if (options?.initServices) {
    await initServices(LOG_TAG);
    await forceUpdateServices();
  }
};

const Login = async (
  email: string,
  password: string,
  recaptchaToken?: string,
  recoveryProfile?: UserProfile,
): Promise<UserProfile | MFARequired | MFAEnrolment | OTPRequired | undefined> => {
  let captcha = '';
  if (recaptchaToken) captcha = env.DISABLE_CAPTCHA ? '' : `${ReCaptchaEnum.CHECK}.${recaptchaToken}`;

  if (!recoveryProfile) {
    try {
      const browserFingerprintId =
        JSON.parse(window.localStorage.getItem('branch_session_first') || '{}')?.browser_fingerprint_id || '';

      const res = await api.endpoints.login({
        data: {
          login: {
            email,
            password,
            fingerprint: browserFingerprintId,
          },
        },
        headers: {
          captcha,
        },
      });
      if (res === undefined) return undefined;

      if (
        (res.data as MFARequired).mfa?.required ||
        (res.data as MFAEnrolment).mfa_enrollment ||
        (res.data as OTPRequired).otp?.required
      ) {
        return res.data;
      }

      const userProfile = res.data as UserProfile;

      await handleSuccessfulLogin(userProfile, {
        initServices: true,
        setUserProfile: true,
      });
      return userProfile;
    } catch (e: any) {
      const error = e as SwyftxError;
      logger.error(LOG_TAG, error.errorMessage);
      throw error;
    }
  } else {
    try {
      await handleSuccessfulLogin(recoveryProfile as UserProfile, {
        setUserProfile: true,
      });
    } catch (e: any) {
      const error = e as SwyftxError;
      logger.error(LOG_TAG, error.errorMessage);
      throw error;
    }
  }
};

/**
 * Ensure the UserStore contains the recaptchaToken upon confirming password.
 * @param email
 * @param password
 * @constructor
 */
const ConfirmPassword = async (
  email: string,
  password: string,
): Promise<UserProfile | MFARequired | MFAEnrolment | undefined> => {
  const { recaptchaToken, setRecaptchaToken, setRefreshRecaptcha } = UserStore.useUserStore;
  const data: LoginParams = {
    login: {
      email,
      password,
    },
  };

  const headers: ReCaptchaHeaders = {
    captcha: env.DISABLE_CAPTCHA ? '' : `${ReCaptchaEnum.CHECK}.${recaptchaToken}`,
  };
  try {
    const res = await api.endpoints.login({ data, headers });
    if (res === undefined) return undefined;
    return res.data as UserProfile;
  } catch (e: any) {
    // Reset recaptcha on login failure
    setRefreshRecaptcha(true);
    setRecaptchaToken('');
    const error = e as SwyftxError;
    logger.error(LOG_TAG, error.errorMessage);
    throw error;
  }
};

const MFALogin = async (
  code: string,
  token: string,
  challenge_type: string,
  oob_code?: string,
  handleLogin = true,
): Promise<UserProfile | undefined> => {
  const data: MfaParams = {
    mfa: {
      token,
      challenge_type,
      otp: challenge_type === 'otp' ? code : undefined,
      binding_code: undefined,
      recovery_code: challenge_type === 'rec' ? code : undefined,
      oob_code,
    },
  };

  try {
    const res = await api.endpoints.mfa({ data });
    if (res === undefined) {
      throw new Error();
    }

    if (!handleLogin) return res.data;

    await handleSuccessfulLogin(res.data, {
      initServices: true,
      setUserProfile: true,
    });

    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const MFALostAuthenticator = async (token: string, headers?: Record<string, string>): Promise<any> => {
  try {
    const data: MfaLostAuthParams = {
      mfaToken: token,
    };
    const res = await api.endpoints.mfaLostAuth({ data, headers });
    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const OTPSMSFallback = async (
  email: string,
  password: string,
  otpData: OTPRequired,
): Promise<OTPRequired | undefined> => {
  const data: OTPSMSFallbackParams = {
    otp: {
      email,
      password,
    },
  };

  const res = await api.endpoints.otpSmsFallback({ data });

  if (res === undefined || !res.data.success) {
    return undefined;
  }

  return {
    otp: {
      required: otpData.otp.required,
      reason: otpData.otp.reason,
      challengeType: 'sms',
    },
  };
};

const OTPLogin = async (
  email: string,
  password: string,
  code: string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  challengeType: OTPChallengeType,
  handleLogin = true,
): Promise<UserProfile | undefined> => {
  try {
    const data: OTPParams = {
      otp: {
        email,
        password,
        code,
        challengeType,
      },
    };

    const res = await api.endpoints.otp({ data });
    if (res === undefined) {
      throw new Error();
    }

    if (!handleLogin) return res.data;

    await handleSuccessfulLogin(res.data, {
      initServices: true,
      setUserProfile: true,
    });

    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const Logout = async (): Promise<boolean> => {
  const { setIsDemo } = AppStore.useAppStore;
  const { cleanup } = UniversalTradeStore;

  try {
    // Check to see if we are already logged out, this means we should have no token
    if (sessionStorage.getItem(StorageKey.ACCESS_TOKEN) === null) return true;

    await cleanupServices();

    // cleanup caches
    api.cache.clear();

    storage.removeItem(StorageKey.ACCESS_TOKEN);
    storage.removeItem(StorageKey.ANGULAR_ACCESS_TOKEN);
    storage.removeItem(StorageKey.PERSONAL_ACCESS_TOKEN);
    storage.removeItem(StorageKey.CURRENT_ACCOUNT);
    storage.removeItem(StorageKey.CURRENT_ACCOUNT_COLOR);

    // remove domain wide cookie
    const cookies = new Cookies();
    cookies.remove('swyftx_auth');

    // TODO auth grab token from profile and manually set here so we can clear the profile ASAP
    const res = await api.endpoints.logout().catch((error: any) => logger.error(LOG_TAG, 'Unable to logout', error));
    setIsDemo(false);

    const { resetUserProfile } = UserStore.useUserStore;
    resetUserProfile();

    // Remove entity aviary classes from the DOM if added in this session
    entityService.applyEntityColorToDOM();

    cleanup();

    return (res as any).data;
  } catch (e) {
    const { error } = e as any;
    throw error?.response?.data?.error?.error ?? error;
  }
};

const CreateAccount = async (
  email: string,
  phone: string,
  password: string,
  firstName: string,
  lastName: string,
  country: CountriesEnum,
  emailCode: string,
  phoneCode: string,
  referralCode?: string,
  promoRef?: string,
  captchaToken?: string,
  meta?: SignUpMetaParams,
  applyForEntityAccount?: boolean,
): Promise<any> => {
  const { setAuth } = UserStore.useUserStore;

  const data: SignUpParams = {
    signup: {
      email,
      phone,
      password,
      name: {
        first: firstName.trim(),
        last: lastName.trim(),
      },
      otp: {
        email: emailCode,
        phone: phoneCode,
      },
      country,
      applyForEntityAccount,
    },
    meta,
  };

  if (referralCode) {
    data.signup = { ...data.signup, referralCode };
  }

  if (promoRef) {
    data.signup = { ...data.signup, promoRef };
  }

  const headers: ReCaptchaHeaders = {
    captcha: env.DISABLE_CAPTCHA ? '' : `${ReCaptchaEnum.CHECK}.${captchaToken}`,
  };

  const res = await api.endpoints.register({
    data,
    headers,
  });

  setAuth(res.data.auth);

  return res.data;
};

const ResetPassword = async (email: string, recaptchaToken: string): Promise<any> => {
  const { setRecaptchaToken } = UserStore.useUserStore;

  const data: ResetPasswordParams = {
    resetPassword: {
      email,
    },
  };

  const headers: ReCaptchaHeaders = {
    captcha: env.DISABLE_CAPTCHA ? '' : `${ReCaptchaEnum.CHECK}.${recaptchaToken}`,
  };

  const res = await api.endpoints.resetPassword({ data, headers });
  setRecaptchaToken('');
  return res.data;
};

const SetupOTP = async (captchaToken: string, phone?: string, email?: string, code?: string) => {
  const { setRecaptchaToken, setRefreshRecaptcha } = UserStore.useUserStore;

  const data: PasswordlessOTPParams = {
    phone,
    email,
    code,
  };

  const headers: ReCaptchaHeaders = {
    captcha: env.DISABLE_CAPTCHA ? '' : `${ReCaptchaEnum.CHECK}.${captchaToken}`,
  };

  try {
    if (env.DISABLE_OTP) return { success: true };
    const res = await api.endpoints.signUpOTP({ data, headers });

    return res.data;
  } catch (e) {
    const error = e as SwyftxError;
    logger.error(LOG_TAG, error.errorMessage);

    // Check if there is a reCAPTCHA error
    if (isReCaptchaError(error)) {
      setRefreshRecaptcha(true);
      setRecaptchaToken('');
    }

    throw error;
  }
};

const SetupMFA = async (): Promise<SetupMFAEnrolmentResponse | null> => {
  try {
    const results = await api.endpoints.enrollMFA();
    const url = new URL(results?.data?.mfa_enrollment);
    const query = { mfaTicket: url.searchParams.get('ticket_id') || '' };
    const mfa = await api.endpoints.setupMFAEnrolment({ query });
    return mfa.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const CancelMFA = async (): Promise<CancelMFAEnrolmentResponse | undefined> => {
  try {
    const res = await api.endpoints.cancelMFA();
    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
  }
};

const CompleteMFASetup = async (otp: string, mfaToken: string): Promise<any> => {
  const data: CompleteMFAEnrolmentParams = { otp, mfaToken };

  try {
    const res = await api.endpoints.completeMFAEnrolment({ data });
    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const RemoveMFA = async (headers?: Record<string, string>): Promise<any> => {
  try {
    const results = await api.endpoints.mfaRemove({ headers });
    return results.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const AuthenticationService = {
  CompleteMFASetup,
  CreateAccount,
  Login,
  ConfirmPassword,
  Logout,
  OTPSMSFallback,
  OTPLogin,
  MFALogin,
  MFALostAuthenticator,
  RemoveMFA,
  ResetPassword,
  SetupMFA,
  CancelMFA,
  SetupOTP,
  handleSuccessfulLogin,
  setUserProfile,
};

export default AuthenticationService;

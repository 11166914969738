export enum MaintenanceState {
  LOADING,
  COMPLETE,
  ERROR,
}

export interface MaintenanceResponse {
  version: string;
  state: MaintenanceState;
  maintenanceMode: boolean;
}

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const SwyftxCompact: React.FC<Props> = ({ className }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M12.1657 14.6876C11.7847 15.9181 10.1238 18.619 9.41902 19.4952L9.15235 19.8571C9.15235 19.8571 8.89711 20.2381 9.77711 19.7543C10.4247 19.4076 13.9219 17.0533 14.9733 13.6286C14.9733 13.6286 16.0628 13.4305 16.5733 12.5276C16.5733 12.5276 16.2571 11.9943 15.2247 11.8457C15.2247 11.8457 15.3581 10.3829 14.8704 9.44952C14.68 9.08381 12.0514 5.34666 9.42664 4.00571C9.37711 3.98285 9.32759 4.03619 9.35425 4.0819C9.70092 4.68762 10.9657 6.91619 11.819 8.76C11.819 8.76 12.7981 10.6457 12.5657 11.6057C12.1466 13.3429 7.42664 13.1829 7.42664 13.1829L9.46473 13.7543C9.46473 13.7543 7.72759 14.8743 7.73902 14.8743L10.5047 14.0705C10.5047 14.0705 13.2438 13.96 14.8704 13.6514L12.1657 14.6876Z'
      fill='currentColor'
    />
  </svg>
);

export { SwyftxCompact };

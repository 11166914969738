import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Gift, Rewards, ThreeUser } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';
import { Badge } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { RewardsContext } from '@routes/Rewards/Rewards.context';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const AppHeaderReferrals: React.FC = observer(() => {
  const { t } = useTranslation('common');
  const { navigate } = useNavigateRoute();
  const { unseenRewards, hasAvailableReward, hasRewardToRedeem } = useContext(RewardsContext);
  const { isEntity } = UserStore.useUserStore;

  const { isFeatureEnabled } = useIsFeatureEnabled();

  return (
    <Menu
      triggerContent={
        <Button id='header-rewards' variant='outlined' layout='icon' leadingIcon={<Gift />} className='pr-0'>
          <Badge badgeContent={unseenRewards} color='error' overlap='circular' sx={{ position: 'absolute', top: 16 }} />
        </Button>
      }
    >
      <FlexLayout direction='column'>
        <MenuItem
          icon={<Rewards width={24} height={24} />}
          text={t('navigationMenu.rewards')}
          action={() => navigate(NavigationURLs.RewardsRoot)}
          description={
            hasAvailableReward ? (
              <Chip size='sm' color='info'>
                {hasRewardToRedeem ? t('navigationMenu.rewardChips.redeemable') : t('navigationMenu.rewardChips.new')}
              </Chip>
            ) : undefined
          }
        />
        <MenuItem
          icon={<ThreeUser width={24} height={24} />}
          text={t('navigationMenu.affiliateProgram')}
          action={() => navigate(NavigationURLs.ProfileAffiliateProgram)}
        />
        {!isEntity() && isFeatureEnabled(AppFeature.ReferAFriend) && (
          <MenuItem
            icon={<Gift width={24} height={24} />}
            text={t('navigationMenu.referAFriend')}
            action={() => navigate(NavigationURLs.ProfileReferAFriend)}
          />
        )}
      </FlexLayout>
    </Menu>
  );
});

export { AppHeaderReferrals };

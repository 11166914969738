import env from '@shared/config';

import { CountryCode } from 'libphonenumber-js';
import parseMobile from 'libphonenumber-js/max';
import { z } from 'zod';

export const CreateAccountSchema = z
  .object({
    country: z.string(),
    email: z.string().min(1).email('Please enter a valid email'),
    firstName: z.string().min(1, 'Please enter a valid first name'),
    lastName: z.string().min(1, 'Please enter a valid last name'),
    countryCode: z.string().min(1, 'Please enter a valid country code'),

    mobileNumberCountry: z.string(),
    mobileNumber: z.string(),
    applyForEntityAccount: z.boolean().optional(),
    captchaToken: env.DISABLE_CAPTCHA ? z.string().optional() : z.string().min(12),
  })
  .superRefine(({ mobileNumberCountry, mobileNumber }, ctx) => {
    if (mobileNumberCountry && mobileNumber) {
      const pn = parseMobile(mobileNumber, mobileNumberCountry as CountryCode);
      const validMobile = Boolean(pn && pn.isValid() && pn.country === mobileNumberCountry);

      if (!validMobile) {
        ctx.addIssue({
          code: 'custom',
          path: ['mobileNumber'],
          message: 'Mobile phone number is invalid',
        });
        return false;
      }

      return true;
    }

    return true;
  });

import { useContext, useMemo } from 'react';

import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';

import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

import { useQuickBuyAmounts } from './useQuickBuyAmounts';
import { useQuickBuyAssets } from './useQuickBuyAssets';
import { useQuickBuyPaymentMethods } from './useQuickBuyPaymentMethods';
import { QuickBuyContext } from '../../context';

const useQuickBuyValues = () => {
  const { buyType, setBuyType, setCurrentStep, allowAssetChange, allowPaymentChange, tabValue } =
    useContext(QuickBuyContext);
  const { nonLimitAsset, countryAsset, selectedAsset, limitAsset } = useQuickBuyAssets();

  const { countryBalance, nonLimitAmount, setAmount, amount, swapLimitAsset } = useQuickBuyAmounts();
  const { selectedPaymentMethod } = useQuickBuyPaymentMethods();
  const { getBalance } = usePortfolioBalance();

  const presetValueTabs: EnhancedTab<string>[] = useMemo(
    () => [
      {
        title: '$10',
        value: '10',
      },
      {
        title: '$25',
        value: '25',
      },
      {
        title: '$100',
        value: '100',
      },
      {
        title: '$250',
        value: '250',
      },
      {
        title: 'Max',
        value: Big(countryBalance?.availableBalance).round(2, 0).toFixed(2) ?? '0',
      },
    ],
    [countryBalance],
  );

  const selectedAssetBalance: UserBalance | undefined = useMemo(() => {
    if (!selectedAsset) return undefined;

    return getBalance(selectedAsset.id);
  }, [selectedAsset, getBalance]);

  const presetPercentageTabs: EnhancedTab<string>[] = useMemo(
    () => [
      {
        title: '15%',
        value: '0.15',
      },
      {
        title: '25%',
        value: '0.25',
      },
      {
        title: '50%',
        value: '0.5',
      },
      {
        title: '75%',
        value: '0.75',
      },
      {
        title: '100%',
        value: '1',
      },
    ],
    [],
  );

  const presetTabs = useMemo(() => {
    const showPresetValues = buyType === 'buy';
    return showPresetValues ? presetValueTabs : presetPercentageTabs;
  }, [buyType, presetPercentageTabs, presetValueTabs]);

  return {
    amount,
    presetTabs,
    countryAsset,
    selectedAsset,
    limitAsset,
    nonLimitAsset,
    nonLimitAmount,
    countryBalance,
    selectedAssetBalance,
    buyType,
    selectedPaymentMethod,
    allowAssetChange,
    allowPaymentChange,
    setBuyType,
    setCurrentStep,
    setAmount,
    swapLimitAsset,
    tabValue,
  };
};

export { useQuickBuyValues };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const BarChartDownward: React.FC<Props> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.7553 40.5462C38.5879 40.5462 40.0711 39.063 40.0711 37.2304V30.8752C40.0711 29.0427 38.5879 27.5576 36.7553 27.5576C34.9246 27.5576 33.4395 29.0427 33.4395 30.8752V37.2304C33.4395 39.063 34.9246 40.5462 36.7553 40.5462Z'
      stroke='currentColor'
      strokeWidth='2.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.0014 40.5464C25.8338 40.5464 27.3172 39.0632 27.3172 37.2305V26.0932C27.3172 24.2625 25.8338 22.7773 24.0014 22.7773C22.1707 22.7773 20.6855 24.2625 20.6855 26.0932V37.2305C20.6855 39.0632 22.1707 40.5464 24.0014 40.5464Z'
      stroke='currentColor'
      strokeWidth='2.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.2455 40.5467C13.0781 40.5467 14.5614 39.0635 14.5614 37.2309V18.0102C14.5614 16.1795 13.0781 14.6943 11.2455 14.6943C9.41481 14.6943 7.92969 16.1795 7.92969 18.0102V37.2309C7.92969 39.0635 9.41481 40.5467 11.2455 40.5467Z'
      stroke='currentColor'
      strokeWidth='2.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M40.0702 20.52L28.901 12.5377L24.711 16.952L11.2832 5.98633'
      stroke='currentColor'
      strokeWidth='2.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { BarChartDownward };

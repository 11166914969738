import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps, StackProps } from '@mui/material';

import { TypographyProps } from '@swyftx/react-web-design-system';

import { GenericError } from './GenericError';

export type ChartUnavailableReason =
  | 'isPortfolioAndDemoMode'
  | 'isDelisted'
  | 'isMatchingBaseAsset'
  | 'comingSoon'
  | 'error';

type Props = {
  reason?: ChartUnavailableReason;
  containerProps?: StackProps;
  subTitleProps?: TypographyProps;
  titleProps?: TypographyProps;
  hideSubTitle?: boolean;
  hideTitle?: boolean;
  subTitle?: string;
  title?: string;
  tryAgainProps?: ButtonProps;
  onTryAgain?: () => void;
};

export const NoChartError: React.FC<Props> = ({
  reason = 'error',
  containerProps,
  subTitleProps,
  titleProps,
  hideSubTitle,
  hideTitle,
  subTitle,
  title,
  tryAgainProps,
  onTryAgain,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'chartError' });

  const errorTitle = (() => {
    if (title) {
      return title;
    }

    if (reason === 'comingSoon') {
      return t('comingSoon.title');
    }

    if (reason === 'isDelisted') {
      return t('isDelisted.title');
    }

    if (reason === 'isPortfolioAndDemoMode') {
      return t('isPortfolioAndDemoMode.title');
    }

    if (reason === 'isMatchingBaseAsset') {
      return t('isMatchingBaseAsset.title');
    }

    return t('error.title');
  })();

  const errorSubTitle = (() => {
    if (subTitle) {
      return subTitle;
    }

    if (reason === 'comingSoon') {
      return t('comingSoon.subTitle');
    }

    if (reason === 'isDelisted') {
      return t('isDelisted.subTitle');
    }

    if (reason === 'isPortfolioAndDemoMode') {
      return t('isPortfolioAndDemoMode.subTitle');
    }

    if (reason === 'isMatchingBaseAsset') {
      return t('isMatchingBaseAsset.subTitle');
    }

    return t('error.subTitle');
  })();

  return (
    <GenericError
      containerProps={containerProps}
      subTitleProps={subTitleProps}
      tryAgainProps={tryAgainProps}
      hideSubTitle={hideSubTitle}
      subTitle={errorSubTitle}
      onTryAgain={onTryAgain}
      titleProps={titleProps}
      hideTitle={hideTitle}
      title={errorTitle}
    />
  );
};

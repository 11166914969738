import { useCallback } from 'react';

import { Asset, AssetType, HistoricalOrder } from '@shared/api';
import { OrderStatusEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';

import { getOrderSide } from 'src/lib/UniversalTrade/components/OrdersTile/utils';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { isTriggerOrder } from '../utils';

export const useFeeUtils = (order: HistoricalOrder) => {
  const { lowestFee: fee } = useCurrentUserFees();

  const orderSide = getOrderSide(order.order_type);
  const quantityAsset = assetService.getAsset(order.quantity_asset);
  const secondaryQuantityAsset = assetService.getAsset(
    order.quantity_asset === order.secondary_asset ? order.primary_asset : order.secondary_asset,
  );

  const rate = order.rate ? Big(order.rate) : Big(0);
  const orderQuantity = Big(order.quantity);

  const feeAmount = order.feeAmount ? Big(order.feeAmount) : Big(0);

  const limitAssetIsPrimaryAsset = order.primary_asset === order.quantity_asset;
  const trigger = order.trigger ? Big(order.trigger) : Big(1);

  const quantityTimesFee = orderQuantity.mul(fee).toString();

  const isAssetFiat = useCallback((asset?: Asset) => asset?.assetType === AssetType.Fiat, []);

  const isOpenOrder = isTriggerOrder(order.order_type) && order.status === OrderStatusEnum.OPEN;

  const calculateOpenOrderFee = useCallback(() => {
    if (limitAssetIsPrimaryAsset) {
      return {
        primaryAmount: orderQuantity.mul(fee).toString(),
        secondaryAmount:
          orderSide === 'Sell'
            ? orderQuantity.mul(fee).mul(trigger).toString()
            : orderQuantity.mul(fee).div(trigger).toString(),
        primaryFeeAsset: quantityAsset,
        secondaryFeeAsset: secondaryQuantityAsset,
      };
    }
    if (orderSide === 'Buy') {
      return {
        primaryAmount: quantityTimesFee,
        secondaryAmount: orderQuantity.mul(fee).mul(trigger).toString(),
        primaryFeeAsset: quantityAsset,
        secondaryFeeAsset: secondaryQuantityAsset,
      };
    }
    return {
      primaryAmount: orderQuantity.mul(fee).div(trigger).toString(),
      secondaryAmount: quantityTimesFee,
      primaryFeeAsset: secondaryQuantityAsset,
      secondaryFeeAsset: quantityAsset,
    };
  }, [
    fee,
    limitAssetIsPrimaryAsset,
    orderQuantity,
    orderSide,
    quantityAsset,
    quantityTimesFee,
    secondaryQuantityAsset,
    trigger,
  ]);

  const calculateExecutedOrderFee = useCallback(
    () => ({
      primaryAmount: feeAmount.toString(),
      secondaryAmount: limitAssetIsPrimaryAsset ? feeAmount.mul(rate).toString() : feeAmount.div(rate).toString(),
    }),
    [feeAmount, limitAssetIsPrimaryAsset, rate],
  );

  return {
    calculateOpenOrderFee,
    calculateExecutedOrderFee,
    quantityAsset,
    secondaryQuantityAsset,
    isAssetFiat,
    isOpenOrder,
  };
};

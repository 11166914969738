import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { SourceOfWealthStatus } from '@shared/api/@types/compliance';

import { useSourceOfWealthAnalytics } from '@routes/SourceOfWealth/useSourceOfWealthAnalytics';

import { NavigationURLs } from 'src/lib/navigation/types';
import { useGetSourceOfWealthStatus } from 'src/lib/verification/hooks/useGetSourceOfWealth';

const CongratulationsStep: React.FC = () => {
  const navigate = useNavigate();
  const { submittedVerification, moreInformationSubmitted } = useSourceOfWealthAnalytics();
  const { status } = useGetSourceOfWealthStatus();
  const submittedStatuses = useMemo(
    () => [
      SourceOfWealthStatus.REQUIRED,
      SourceOfWealthStatus.REQUESTED,
      SourceOfWealthStatus.STARTED,
      SourceOfWealthStatus.SUBMITTED,
    ],
    [],
  );
  const moreInfoRequestedStatuses = useMemo(
    () => [SourceOfWealthStatus.MORE_INFO_REQUESTED, SourceOfWealthStatus.MORE_INFO_SUBMITTED],
    [],
  );

  const onDashboardClick = () => {
    navigate(NavigationURLs.Dashboard);
  };

  const bannerIcon = (
    <Image image='source_of_wealth_check' className='h-64 w-64' usePalette alt='verification prompts' />
  );

  useEffect(() => {
    if (!status) return;
    if (submittedStatuses.includes(status)) submittedVerification();
    if (moreInfoRequestedStatuses.includes(status)) moreInformationSubmitted();
  }, [moreInfoRequestedStatuses, moreInformationSubmitted, status, submittedStatuses, submittedVerification]);

  return (
    <FlexLayout spacing={16} direction='column'>
      {bannerIcon}
      <Heading size='h2' className='flex justify-center'>
        Thank you
      </Heading>
      <Body color='secondary' className='mb-16 text-center'>
        Your responses have been submitted. We will contact you once the process has been completed.
      </Body>

      <Button onClick={onDashboardClick} size='lg' className='w-full'>
        Go to Dashboard
      </Button>
    </FlexLayout>
  );
};

export { CongratulationsStep };

import React, { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import { TradeVariant } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { SingleWalletTradingInfo } from '@routes/Wallet/subroutes/SingleWallet/components';

import { observer } from 'mobx-react-lite';

const TradeOrderDetailsPriceChart: React.FC = observer(() => {
  const { tradeFrom, tradeTo, setTradeVariant, tradeVariant } = UniversalTradeStore;

  const baseAssets = assetService.getBaseAssets();

  const fromAsset = assetService.getAsset(tradeFrom[0]);
  const toAsset = assetService.getAsset(tradeTo[0]);
  let primaryAsset: Asset | undefined;
  let secondaryAsset: Asset | undefined;

  const baseAsset = useMemo(() => {
    if (!fromAsset || !toAsset) return undefined;

    if (!baseAssets.includes(fromAsset.id) && !baseAssets.includes(toAsset.id)) return undefined;

    if (fromAsset.assetType === AssetType.Fiat) {
      return fromAsset;
    }

    return toAsset;
  }, [fromAsset, toAsset, baseAssets]);

  const asset = useMemo(() => {
    if (!baseAsset || !fromAsset || !toAsset) return undefined;

    return fromAsset.id === baseAsset.id ? toAsset : fromAsset;
  }, [baseAsset, fromAsset, toAsset]);

  // set primary and secondary assets to calculate trade variant (buy vs. sell)
  if (fromAsset && toAsset) {
    if (fromAsset === baseAsset) {
      primaryAsset = fromAsset;
      secondaryAsset = toAsset;
    } else {
      primaryAsset = toAsset;
      secondaryAsset = fromAsset;
    }
  }

  useEffect(() => {
    if (!fromAsset || !toAsset || !baseAsset) return;

    if (primaryAsset!.id === fromAsset.id && secondaryAsset!.id === toAsset.id) {
      setTradeVariant(TradeVariant.Buy);
    }
    if (secondaryAsset!.id === fromAsset.id && primaryAsset!.id === toAsset.id) {
      setTradeVariant(TradeVariant.Sell);
    }
  }, [fromAsset, toAsset, baseAsset]);

  if (!baseAsset || !asset || !secondaryAsset || !primaryAsset) return null;

  return (
    <Box marginY={2}>
      <SingleWalletTradingInfo
        asset={secondaryAsset}
        base={primaryAsset}
        showBuySellButtons={false}
        showTradingInfo={false}
        showAssetInfoLink={false}
        priceSide={tradeVariant === 'Sell' ? 'bidPrice' : 'askPrice'}
      />
    </Box>
  );
});

TradeOrderDetailsPriceChart.displayName = 'TradeOrderDetailsPriceChart';

export { TradeOrderDetailsPriceChart };

import React from 'react';

import { Box } from '@mui/material';

import { pxToRem } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { useTheme } from '@hooks/useTheme';

import { ITEM_SLIDER_ALL_WIDTH, ITEM_SLIDER_ICON_PADDING, ITEM_SLIDER_ICON_SIZE, SliderItem } from '../ItemSlider.data';

type Props = {
  selectedIndex: number;
  index: number;
  item?: SliderItem;
};

const OPACITY_DECREMENT = 0.1;
const SCALE_DECREMENT = 0.045;

export const isAsset = (e?: SliderItem): e is Asset => (e as Asset).assetType !== undefined;
export const isBaseAsset = (e?: SliderItem): e is Asset => Boolean((e as Asset).isBaseAsset);

export const ItemSliderItem: React.FC<Props> = ({ index, selectedIndex, item }) => {
  const { theme } = useTheme();
  const distanceFromSelected = Math.abs(index - selectedIndex);
  const normalizedOpacityDecrement = Math.round((1 - distanceFromSelected * OPACITY_DECREMENT) * 100) / 100;
  const normalizedScaleDecrement = Math.round((1 - distanceFromSelected * SCALE_DECREMENT) * 100) / 100;
  const opacity = normalizedOpacityDecrement < OPACITY_DECREMENT ? 0 : normalizedOpacityDecrement;
  const scale = normalizedScaleDecrement < SCALE_DECREMENT ? 0 : normalizedScaleDecrement;

  if (index === -1) {
    return (
      <Box
        borderRadius={pxToRem(ITEM_SLIDER_ICON_SIZE / 2)}
        marginRight={pxToRem(ITEM_SLIDER_ICON_PADDING)}
        minWidth={pxToRem(ITEM_SLIDER_ALL_WIDTH)}
        height={pxToRem(ITEM_SLIDER_ICON_SIZE)}
        bgcolor={theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.text.secondary}
        color={theme.palette.common.white}
        justifyContent='center'
        fontSize={pxToRem(14)}
        alignItems='center'
        display='flex'
        sx={{
          opacity,
          transform: `scale(${scale}, ${scale})`,
        }}
      >
        All
      </Box>
    );
  }

  if (isAsset(item)) {
    return (
      <AssetIcon
        asset={item}
        size={ITEM_SLIDER_ICON_SIZE}
        style={{
          opacity,
          transform: `scale(${scale}, ${scale})`,
          marginRight: pxToRem(ITEM_SLIDER_ICON_PADDING),
        }}
      />
    );
  }

  if (item) {
    return (
      <Box
        borderRadius={pxToRem(ITEM_SLIDER_ICON_SIZE / 2)}
        marginRight={pxToRem(ITEM_SLIDER_ICON_PADDING)}
        minWidth={pxToRem(ITEM_SLIDER_ICON_SIZE)}
        height={pxToRem(ITEM_SLIDER_ICON_SIZE)}
        color={theme.palette.common.white}
        justifyContent='center'
        bgcolor={item.color}
        alignItems='center'
        display='flex'
        sx={{
          opacity,
          transform: `scale(${scale}, ${scale})`,
          '& > svg': {
            width: `${pxToRem(ITEM_SLIDER_ICON_SIZE / 1.5)} !important`,
            height: `${pxToRem(ITEM_SLIDER_ICON_SIZE / 1.5)} !important`,
          },
        }}
      >
        {item.icon}
      </Box>
    );
  }

  return null;
};

import { styled } from '@mui/material/styles';

export const StyledCarouselContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledCarouselSlidesContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledCarouselDotContainer = styled('div')`
  display: flex;
  flex: 1;
`;

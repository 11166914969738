import { useMemo } from 'react';

import { Chat, Deposit, QuickBuy, Withdraw } from '@swyftx/aviary/icons/outlined';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { useIntercom } from 'react-use-intercom';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { GlobalSearchItemType } from '../../search.types';

type Props = {
  assets: Asset[];
};

const useActionsSearchModule = ({ assets }: Props): GlobalSearchItemType[] => {
  const { navigate } = useNavigateRoute();
  const { openModal } = useModal();
  const countryAsset = useCountryAsset();
  const { show } = useIntercom();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const asset = useMemo(() => {
    if (!assets?.length) return undefined;

    return assets[0];
  }, [assets]);

  const items = useMemo(
    (): GlobalSearchItemType[] => [
      {
        title: `Deposit ${asset ? asset?.name : 'Funds'}`,
        subTitle: `Deposit ${asset ? asset?.name : 'Funds'} into your account`,
        leading: <Deposit className='h-24 w-24' />,
        forceShow: asset !== undefined,
        hidden: asset && !asset?.deposit_enabled,
        onClick: () => openModal(Modals.DepositReceive, { selectedAsset: asset }),
      },
      {
        title: `Withdraw ${asset ? asset?.name : 'Funds'}`,
        subTitle: `Withdraw ${asset ? asset?.name : 'Funds'}`,
        leading: <Withdraw className='h-24 w-24' />,
        forceShow: asset !== undefined,
        hidden: asset && !asset?.withdraw_enabled,
        onClick: () => openModal(Modals.WithdrawSend, { selectedAsset: asset }),
      },
      {
        title: `Buy ${asset?.name}`,
        subTitle: `Quickly buy ${asset?.name} with ${countryAsset?.name}`,
        leading: <QuickBuy width={24} height={24} />,
        hidden: (!asset && !assets.length) || !isFeatureEnabled(AppFeature.QuickBuy) || !!asset?.buyDisabled,
        forceShow: asset !== undefined,
        onClick: () => navigate(NavigationURLs.QuickBuy, { state: { assetCode: asset?.code } }),
      },
      {
        title: 'Swyftx Live chat',
        subTitle: 'Our support team are here to answer your questions',
        leading: <Chat />,
        additionalKeywords: ['help'],
        onClick: () => show(),
      },
    ],
    [asset, assets.length, countryAsset?.name, isFeatureEnabled, navigate, openModal, show],
  );

  return items;
};

export { useActionsSearchModule };

export enum DepositStatusEnum {
  PENDING = 1,
  CREDITED = 2,
  FAILED_REJECTED = 3, // Failed
  FAILED_UNKNOWN = 4, // Failed
  FAILED_MIN_DEPOSIT = 5, // Failed
  PENDING_FIAT_CONVERTED = 6,
  PENDING_DAILY_LIMIT = 7,
  PENDING_NOT_CREDITED = 8,
  PENDING_EXTENDED_VERIFICATION = 9,
  REFUNDED = 10, // Failed
  PENDING_DISABLED = 11,
  PENDING_TERMINAL = 12,
  CANCELLED = 13, // Failed
  UNSEEN = 14,
  NOT_VERIFIED = 15,
  POLI_CLAWED_BACK = 16, // Failed
}

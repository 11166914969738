import React, { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { UserStore } from '@shared/store';
import { EntityMember } from '@shared/store/userStore/@types/userTypes';
import entityHelpers from '@shared/utils/lib/entityHelpers';

import { observer } from 'mobx-react-lite';

import { EntityConfigureMemberOverviewStep, EntityConfigureMemberPermissionStep } from './steps';
import { MemberPermissionType } from '../../../shared/utils/lib/entityHelpers';

type Props = {
  open: boolean;
  onClose: () => void;
  entityMember: EntityMember;
};

enum Steps {
  Overview = 0,
  Permission = 1,
}

export const EntityConfigureMemberWizard: React.FC<Props> = observer(({ open, onClose, entityMember }) => {
  const { userProfile, previousUserProfile, entityMembers } = UserStore.useUserStore;
  const [stepIndex, setStepIndex] = useState<Steps>(Steps.Overview);
  const isXs = useTailwindBreakpoint('xs');

  const isAdmin = useMemo(() => {
    const loggedUserMember = entityMembers.find((e) => e.uuid === previousUserProfile?.intercom?.uuid);
    const scopePreset = entityHelpers.getPermissionPreset(loggedUserMember?.scope || '');
    return scopePreset === MemberPermissionType.Admin;
  }, [previousUserProfile, entityMembers]);

  const goToPermissions = useCallback(() => {
    if (!isAdmin) return;
    setStepIndex(Steps.Permission);
  }, [isAdmin]);

  const goToOverview = useCallback(() => {
    setStepIndex(Steps.Overview);
  }, []);

  const title = useMemo(() => {
    switch (stepIndex) {
      case Steps.Overview:
        return 'Configure member details';
      case Steps.Permission:
        return `Update permissions for ${entityMember.name}`;
      default:
        return '';
    }
  }, [entityMember.name, stepIndex]);

  const handleBack = useCallback(() => {
    if (stepIndex > Steps.Overview) {
      setStepIndex(stepIndex - 1);
    } else {
      onClose();
    }
  }, [onClose, stepIndex]);

  const content = useMemo(() => {
    switch (stepIndex) {
      case Steps.Overview:
        return (
          <EntityConfigureMemberOverviewStep
            entityMemberUuid={entityMember.uuid || ''}
            goToPermissions={goToPermissions}
            entityUuid={userProfile?.intercom?.uuid || ''}
            entityMember={entityMember}
            isAdmin={isAdmin}
            onClose={onClose}
          />
        );
      case Steps.Permission:
        return (
          <EntityConfigureMemberPermissionStep
            onClose={handleBack}
            onSuccess={goToOverview}
            entityMember={entityMember}
          />
        );
      default:
        return <></>;
    }
  }, [
    entityMember,
    goToOverview,
    goToPermissions,
    handleBack,
    isAdmin,
    onClose,
    stepIndex,
    userProfile?.intercom?.uuid,
  ]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      className='sm:!min-w-[800px] sm:!max-w-[800px]'
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' className='p-24 pt-0' spacing={16}>
        {content}
      </FlexLayout>
    </Modal>
  );
});

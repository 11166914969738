import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const YieldFarming: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.93918 7.23331C5.97934 7.12948 6.03486 6.99522 6.10736 6.8397C6.27327 6.48377 6.53282 6.00621 6.90818 5.52478C7.66103 4.5592 8.92212 3.5332 10.8293 3.5332C12.7365 3.5332 13.9976 4.5592 14.7504 5.52479C15.1258 6.00622 15.3853 6.48378 15.5512 6.83971C15.6237 6.99523 15.6792 7.12948 15.7194 7.23331H17.1004L17.2613 6.51596C17.4254 5.7848 18.0745 5.26514 18.8238 5.26514H20.897C21.3113 5.26514 21.647 5.60092 21.647 6.01514C21.647 6.42935 21.3113 6.76514 20.897 6.76514H18.8238C18.7764 6.76514 18.7353 6.79802 18.725 6.84429L17.0127 14.4761C16.9944 14.558 16.963 14.6342 16.9211 14.7028L17.0279 18.8934C17.0483 19.6962 16.403 20.3582 15.5999 20.3582C14.9593 20.3582 14.3969 19.9317 14.2243 19.3147L13.0436 15.095C13.0405 15.084 13.0377 15.0729 13.0351 15.0619H7.52254L7.31092 15.4266C7.86539 15.9594 8.21047 16.7085 8.21047 17.5383C8.21047 19.1557 6.89935 20.4668 5.28199 20.4668C3.66463 20.4668 2.35352 19.1557 2.35352 17.5383C2.35352 15.9209 3.66465 14.6099 5.28199 14.6099C5.52944 14.6099 5.76973 14.6406 5.99925 14.6983L6.27751 14.2188L4.35519 9.43137C3.93271 8.37922 4.70741 7.23331 5.84123 7.23331H5.93918ZM14.0725 7.23331C13.9468 6.99451 13.78 6.71975 13.5675 6.44709C12.9927 5.70994 12.1254 5.0332 10.8293 5.0332C9.53319 5.0332 8.66586 5.70994 8.09112 6.4471C7.87853 6.71975 7.71179 6.99451 7.58605 7.23331H14.0725ZM14.5919 15.0619H15.4298L15.5137 18.3563L14.5919 15.0619ZM5.17796 16.1136C4.43758 16.1669 3.85352 16.7844 3.85352 17.5383C3.85352 18.3272 4.49306 18.9668 5.28199 18.9668C6.07092 18.9668 6.71047 18.3272 6.71047 17.5383C6.71047 17.2712 6.63717 17.0212 6.50959 16.8074L5.93006 17.8061C5.72216 18.1644 5.26319 18.2862 4.90493 18.0783C4.54668 17.8704 4.42479 17.4115 4.63269 17.0532L5.17796 16.1136ZM16.7639 8.73331H5.84123C5.76947 8.73331 5.72042 8.80584 5.74717 8.87244L7.63014 13.5619H15.6806L16.7639 8.73331Z'
      fill='currentColor'
    />
  </svg>
);

export { YieldFarming };

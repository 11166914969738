import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Notification: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2.125C8.27208 2.125 5.25 5.14708 5.25 8.875V10.1353C5.25 12.3099 4.34122 14.3855 2.74332 15.8605C2.26949 16.2979 2 16.9134 2 17.5582V18.0105C2 18.9022 2.72283 19.625 3.61449 19.625H20.3855C21.2772 19.625 22 18.9022 22 18.0105V17.5582C22 16.9134 21.7305 16.2979 21.2567 15.8605C19.6588 14.3855 18.75 12.3099 18.75 10.1353V8.875C18.75 5.14708 15.7279 2.125 12 2.125ZM6.75 8.875C6.75 5.97551 9.10051 3.625 12 3.625C14.8995 3.625 17.25 5.97551 17.25 8.875V10.1353C17.25 12.7285 18.3337 15.2037 20.2393 16.9627C20.4055 17.1161 20.5 17.332 20.5 17.5582V18.0105C20.5 18.0737 20.4487 18.125 20.3855 18.125H3.61449C3.55126 18.125 3.5 18.0737 3.5 18.0105V17.5582C3.5 17.332 3.59453 17.1161 3.76074 16.9627C5.66626 15.2037 6.75 12.7285 6.75 10.1353V8.875Z'
      fill='currentColor'
    />
    <path
      d='M10.0774 20.2312C9.80056 19.923 9.32637 19.8977 9.01826 20.1746C8.71016 20.4514 8.68482 20.9256 8.96166 21.2337C10.6397 23.1012 13.287 23.0766 14.9462 21.2343C15.2234 20.9265 15.1986 20.4523 14.8908 20.1751C14.583 19.8979 14.1088 19.9227 13.8316 20.2305C12.7624 21.4177 11.1537 21.429 10.0774 20.2312Z'
      fill='currentColor'
    />
  </svg>
);

export { Notification };

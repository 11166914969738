import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { LogOut } from '@swyftx/aviary/icons/outlined';
import { Button, Card, Stack, Typography } from '@swyftx/react-web-design-system';

import SessionTimeoutProps from '@global-components/Session/Timeout/SessionTimeout.types';

const SessionTimeout: React.FC<SessionTimeoutProps> = ({ timeoutRemaining, onDismiss, onLogout }) => {
  const [minutesRemaining, setMinutesRemaining] = useState<number>(-1);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(-1);

  useEffect(() => {
    setMinutesRemaining(Math.floor(timeoutRemaining / 60));
    setSecondsRemaining(timeoutRemaining % 60);
  }, [timeoutRemaining]);

  return (
    <Box position='fixed' bottom='1rem' right='1rem' zIndex={3001}>
      <Card id='session-timeout-card' className='rounded' contentSx={{ margin: 0 }}>
        <Stack
          direction='row'
          height='100%'
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
        >
          <Stack direction='row' spacing={2} height='100%' alignItems='center'>
            <LogOut id='session-timeout-icon' className='h-32 w-32' />
            <Stack direction='column' alignItems='center' justifyContent='flex-start' height='100%'>
              <Typography id='session-timeout-expiring-text' width='100%' fontWeight={600}>
                Session expiring in{' '}
                {minutesRemaining > 0 && `${minutesRemaining} min${minutesRemaining > 1 ? 's' : ''}`} {secondsRemaining}
                s.
              </Typography>
              <Typography id='session-timeout-stay-signed-in' width='100%' color='text.secondary'>
                Do you want to stay signed in?
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='column' alignItems='center' spacing={1}>
            <Button
              onClick={onDismiss}
              variant='text'
              id='session-timeout-expiring-text'
              sx={{ width: '100%', fontWeight: 500 }}
              color='primary'
            >
              Dismiss
            </Button>
            <Button
              id='session-timeout-expiring-text'
              variant='outlined'
              sx={{ width: '100%', fontWeight: 500 }}
              onClick={onLogout}
              color='inherit'
            >
              Logout
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default SessionTimeout;

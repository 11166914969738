import React from 'react';

import { Switch } from '@swyftx/aviary/atoms/Switch';

import logger from '@shared/logger';
import { UserStore } from '@shared/store';

import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

const LOG_TAG = '[ANALYTICS_SWITCH]';

const AnalyticsSwitch: React.FC = observer(() => {
  const { userProfile, setUserSettings } = UserStore.useUserStore;

  const handleOnSwitch = async (value: boolean) => {
    const userSettings = userProfile?.userSettings;

    if (!userSettings) return;

    try {
      await UserService.UpdateUserSettings({ data: { analyticsOptOut: !value } });
      setUserSettings({ ...userSettings, analyticsOptOut: !value });
    } catch (e) {
      logger.error(LOG_TAG, 'Error updating user settings');
    }
  };

  return (
    <Switch
      id='profile-settings-analytics-switch'
      size='md'
      checked={!userProfile?.userSettings.analyticsOptOut}
      onCheckedChange={handleOnSwitch}
    />
  );
});

AnalyticsSwitch.displayName = 'AnalyticsSwitch';

export { AnalyticsSwitch };

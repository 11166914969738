import React, { useContext, useMemo, useCallback } from 'react';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { PortfolioTransferContext, TransferSteps } from './PortfolioTransfer.context';
import { AddAPIKeyStep } from './components/AddAPIKeyStep';
import { SelectAssetsStep } from './components/SelectAssetsStep';
import { SuccessStep } from './components/SuccessStep';

export const PortfolioTransferContent: React.FC = () => {
  const { step, setStep } = useContext(PortfolioTransferContext);
  const steps = ['Add API Key', 'Select assets', 'Success'];

  const handleNextStep = useCallback(() => {
    if (step < 2) {
      setStep((step + 1) as 0 | 1 | 2);
    }
  }, [setStep, step]);

  const stepToRender = useMemo(() => {
    switch (step) {
      case TransferSteps.AddAPIKeys:
        return <AddAPIKeyStep onNext={handleNextStep} />;
      case TransferSteps.SelectAssets:
        return <SelectAssetsStep onNext={handleNextStep} />;
      case TransferSteps.Success:
        return <SuccessStep onNext={handleNextStep} />;
      default:
        step satisfies never;
    }
  }, [handleNextStep, step]);

  return (
    <FlexLayout direction='column' spacing={16} className='p-16'>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={step}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {stepToRender}
    </FlexLayout>
  );
};

import { useCallback } from 'react';

import { api } from '@shared/api';
import { UIStore } from '@shared/store';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { useFetchDynamicPriceAlertAssetCountsCache } from './useFetchDynamicPriceAlertAssetCounts/useFetchDynamicPriceAlertAssetCountsCache';
import { useFetchDynamicPriceAlertsCache } from './useFetchDynamicPriceAlerts';

const useDeleteDynamicPriceAlert = () => {
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();
  const { invalidateCache: invalidateCountsCache } = useFetchDynamicPriceAlertAssetCountsCache();

  const { getAssetById } = useMarkets();
  const { addToastMessage } = UIStore.useUIStore;

  const deletePriceAlert = useCallback(
    async (priceAlertUuid: string, secondary: number) => {
      const secondaryAsset = getAssetById(secondary);

      try {
        const response = await api.endpoints.deleteAlert({ data: { priceAlertUuid } });
        invalidateCache();
        invalidateCountsCache();

        if (secondaryAsset) {
          addToastMessage({ severity: 'success', message: `${secondaryAsset.code} price alert deleted` });
        }

        return response.data;
      } catch (e) {
        if (secondaryAsset) addToastMessage({ severity: 'error', message: `Failed to delete ${secondaryAsset.code}` });
      }
    },
    [addToastMessage, getAssetById, invalidateCache, invalidateCountsCache],
  );

  return { deletePriceAlert };
};

export { useDeleteDynamicPriceAlert };

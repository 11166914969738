import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { WalletTypeTabs } from '@Wallet/Wallet.data';
import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type WalletSelectionProps = {
  onSelect: (newWalletType: WalletType) => void;
  walletType: WalletType;
};

const WalletSelection: React.FC<WalletSelectionProps> = observer(({ onSelect, walletType }) => {
  const { t } = useTranslation('wallet');
  const { navigate } = useNavigateRoute();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const enabledTabs = WalletTypeTabs.filter((tab) => isFeatureEnabled(tab.feature)).length;
  if (enabledTabs <= 2) {
    return null;
  }

  return (
    <FlexLayout direction='row' justifyContent='space-between' spacing={8} className='h-full overflow-x-auto pt-16'>
      {WalletTypeTabs.map(({ id, value, label, miniLabel, leading, activeLeading, type }) => {
        const active = value === walletType;
        return (
          <Button
            id={id}
            key={value}
            variant='ghost'
            size='lg'
            leadingIcon={<>{active ? activeLeading : leading}</>}
            onClick={() => {
              navigate(NavigationURLs.Wallets, { pathParams: { type } });
              onSelect(value);
            }}
          >
            <span className='hidden @md:block'>{t(label)}</span>
            <span className='block @md:hidden'>{t(miniLabel)}</span>
          </Button>
        );
      })}
    </FlexLayout>
  );
});

export { WalletSelection };

import { useState } from 'react';

import { useDebounce } from 'react-use';

const useDebounceState = <T extends Object>(defaultValue: T, debounceMs = 200): [T, T, (newValue: T) => void] => {
  const [debouncedState, setDebouncedState] = useState<T>(defaultValue);
  const [state, setState] = useState<T>(defaultValue);

  useDebounce(() => setDebouncedState(state), debounceMs, [state]);

  return [debouncedState, state, setState];
};

export { useDebounceState };

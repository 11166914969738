import { JumioManualDocumentsModal } from '@routes/Profile/subroutes/Verification/components/JumioManualDocumentsModal';

import { FirstTimeVerificationModal } from '@Profile/subroutes/Verification/components/FirstTimeVerificationModal';
import { GreenIDVerificationModal } from '@Profile/subroutes/Verification/components/GreenID/GreenIDVerificationModal';
import { IdentityVerificationModal } from '@Profile/subroutes/Verification/components/IdentityVerificationModal/IdentityVerificationModal';
import { JumioVerificationModal } from '@Profile/subroutes/Verification/components/Jumio';
import { PersonalDetailsModal } from '@Profile/subroutes/Verification/components/PersonalDetailsModal/PersonalDetailsModal';
import { VerifyFundForm } from '@Profile/subroutes/Verification/components/VerificationMethod/VerifyFund/VerifyFundForm';
import { VerifyFundModal } from '@Profile/subroutes/Verification/components/VerificationMethod/VerifyFund/VerifyFundModal';

import { VerificationStep } from './ProfileVerification.types';

// Used for Segment events
export const VerificationStepEventName = {
  [VerificationStep.None]: null,
  [VerificationStep.FirstTimeVerification]: null,
  [VerificationStep.IdentityVerificationSummary]: null,
  [VerificationStep.GreenID]: 'GreenID Verification',
  [VerificationStep.Jumio]: 'Jumio Verification',
  [VerificationStep.JumioManualDocuments]: 'Jumio Manual Upload',
  [VerificationStep.FundsSummary]: null,
  [VerificationStep.Funds]: 'KYC2 Verification',
  [VerificationStep.PersonalDetails]: 'GreenID Personal Details',
};

const VerificationModals: { [key in VerificationStep]: React.ReactNode | null } = {
  [VerificationStep.None]: null,
  [VerificationStep.FirstTimeVerification]: <FirstTimeVerificationModal />,
  [VerificationStep.IdentityVerificationSummary]: <IdentityVerificationModal />,
  [VerificationStep.GreenID]: <GreenIDVerificationModal />,
  [VerificationStep.Jumio]: <JumioVerificationModal />,
  [VerificationStep.JumioManualDocuments]: <JumioManualDocumentsModal />,
  [VerificationStep.FundsSummary]: <VerifyFundModal />,
  [VerificationStep.Funds]: <VerifyFundForm />,
  [VerificationStep.PersonalDetails]: <PersonalDetailsModal />,
};

export { VerificationModals };

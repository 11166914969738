import React, { PropsWithChildren, useMemo } from 'react';

import { Danger, Error, Information } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { Card } from '../Card';
import { FlexLayout } from '../Layout/Flex';
import { Body } from '../Typography';

type Props = {
  title?: string;
  actions?: React.ReactNode;
  severity?: 'info' | 'warning' | 'destructive';
  className?: string;
  icon?: React.ReactElement;
};

const Notification: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  actions,
  severity = 'info',
  className,
  icon: iconOverride,
}) => {
  const cardColor = useMemo(() => {
    switch (severity) {
      case 'warning':
        return 'bg-color-background-warning-subtle border border-color-border-warning';
      case 'destructive':
        return 'bg-color-background-error-subtle border border-color-border-error';
      case 'info':
      default:
        return 'bg-color-background-info-subtle border border-color-border-info';
    }
  }, [severity]);

  const icon = useMemo(() => {
    if (iconOverride) {
      return iconOverride;
    }
    switch (severity) {
      case 'warning':
        return <Danger className='min-w-[24px] text-color-icon-warning' />;
      case 'destructive':
        return <Error className='min-w-[24px] text-color-icon-error' />;
      case 'info':
      default:
        return <Information className='min-w-[24px] text-color-icon-info' />;
    }
  }, [iconOverride, severity]);

  return (
    <Card shadow={false} border={false} className={cn('!rounded-12 p-16', cardColor, className)}>
      <FlexLayout alignItems='start' spacing={12}>
        {icon}
        <FlexLayout direction='column' alignItems='start' spacing={children ? 4 : 0}>
          {title && <Body weight='emphasis'>{title}</Body>}
          {children ? typeof children === 'string' ? <Body>{children}</Body> : <div>{children}</div> : null}
          {actions && (
            <FlexLayout className='mt-12' alignItems='start'>
              {actions}
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export { Notification };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Dust } from '@swyftx/aviary/icons/outlined';

import { observer } from 'mobx-react-lite';

import { useDusting } from '../../hooks/useDusting';
import { DustingModal } from '../DustingModal/DustingModal';

type Props = {
  layout: 'default' | 'icon';
  iconClassName?: string;
};

const DustSmallBalancesButton: React.FC<Props> = observer(({ layout, iconClassName }) => {
  const { dustMessage, isFetching } = useDusting();

  return (
    <DustingModal>
      <Button
        leadingIcon={<Dust className={iconClassName} />}
        tooltip={dustMessage}
        layout={layout}
        variant='outlined'
        size='md'
        disabled={!!dustMessage || isFetching}
      >
        Dust small balances
      </Button>
    </DustingModal>
  );
});

export { DustSmallBalancesButton };

import { useCallback, useMemo, useState } from 'react';

import { Big } from '@shared/safe-big';

import { OrderBookType } from '../types/TradePro.types';

type Props = {
  orderbook: string[][];
  side: OrderBookType;
};

const useSwyftxProOrderbookItems = ({ orderbook, side }: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  const bookValues = useMemo(() => orderbook.map((val) => val[1]), [orderbook]);

  const totalBookValue = useMemo(
    () => bookValues.reduce((prev, curr) => Big(prev).plus(curr).toString(), '0'),
    [bookValues],
  );

  const onMouseEnter = useCallback((index: number) => {
    setHoveredIndex(index);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHoveredIndex(-1);
  }, []);

  const backgroundColor = useMemo(() => {
    if (side === 'ask') return 'bg-color-background-error';

    return 'bg-color-background-success';
  }, [side]);

  const textColor = useMemo((): 'error' | 'success' => {
    if (side === 'ask') return 'error';

    return 'success';
  }, [side]);

  const getHoveredClass = useCallback(
    (index: number) => {
      if (hoveredIndex === -1) return '';
      if (side === 'ask' && index >= hoveredIndex) return 'cursor-pointer bg-color-background-neutral';
      if (side === 'bid' && index <= hoveredIndex)
        return 'cursor-pointer bg-color-background-neutral group-hover:bg-color-background-neutral';

      return '';
    },
    [hoveredIndex, side],
  );

  const getCurrHoveredClass = useCallback(
    (index: number) => {
      if (index !== hoveredIndex) return '';
      if (side === 'ask' && index >= hoveredIndex) return 'outline-t';
      if (side === 'bid' && index <= hoveredIndex) return 'outline-b';

      return '';
    },
    [hoveredIndex, side],
  );

  const getClassName = useCallback(
    (index: number) => `${getHoveredClass(index)} ${getCurrHoveredClass(index)}`,
    [getCurrHoveredClass, getHoveredClass],
  );

  const getOrderbookAmount = (orderbookItem: string[]) => {
    const [price, total] = orderbookItem;
    return Big(total).div(price);
  };

  const getCumulativeAmount = useCallback(
    (index: number) => {
      let amount = Big(0);

      if (side === 'ask') {
        for (let i = bookValues.length - 1; i >= 0; i--) {
          if (index <= i) {
            amount = amount.plus(getOrderbookAmount(orderbook[i]));
          }
        }
      } else {
        for (let i = 0; i < bookValues.length; i++) {
          if (i <= index) {
            amount = amount.plus(getOrderbookAmount(orderbook[i]));
          }
        }
      }

      return amount.toString();
    },
    [bookValues.length, side, orderbook],
  );

  return {
    totalBookValue,
    hoveredIndex,
    backgroundColor,
    textColor,
    onMouseEnter,
    onMouseLeave,
    getClassName,
    getCumulativeAmount,
  };
};

export { useSwyftxProOrderbookItems };

import { useRef } from 'react';

import { EventCallbackInterface } from 'victory-core';

type Props = {
  chartId: string;
  onUpdateExternalMutations: React.Dispatch<
    React.SetStateAction<EventCallbackInterface<string | string[], string | number | (string | number)[]>[] | undefined>
  >;
};

const useDonutChartLegend = ({ chartId, onUpdateExternalMutations }: Props) => {
  const timer = useRef<number>();

  const onMouseEnter = (key: string) => {
    if (timer) {
      window.clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(
      () =>
        onUpdateExternalMutations([
          {
            childName: [chartId],
            target: ['data'],
            eventKey: key,
            mutation: ({ style }) => ({
              style: { ...style, strokeWidth: 16, strokeOpacity: 0.2, stroke: style.fill },
            }),
          },
          {
            childName: [chartId],
            target: ['labels'],
            eventKey: key,
            mutation: () => ({ active: true }),
          },
        ]),
      100,
    );
  };

  const onMouseLeave = () => {
    if (timer) {
      window.clearTimeout(timer.current);
    }

    onUpdateExternalMutations([
      {
        childName: [chartId],
        target: ['data'],
        eventKey: 'all',
        mutation: ({ style }) => ({ style: { ...style, strokeWidth: 0, stroke: 'white' } }),
      },
      {
        childName: [chartId],
        target: ['labels'],
        eventKey: 'all',
        mutation: () => ({ active: false }),
      },
    ]);
  };

  return { onMouseEnter, onMouseLeave };
};

export { useDonutChartLegend };

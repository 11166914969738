import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Wallet: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M16.9202 11.7998C16.9202 12.2098 17.2602 12.5498 17.6702 12.5498H17.9802C18.3902 12.5498 18.7302 12.2098 18.7302 11.7998C18.7302 11.3898 18.3902 11.0498 17.9802 11.0498H17.6702C17.2602 11.0498 16.9202 11.3898 16.9202 11.7998Z'
      fill='currentColor'
    />
    <path
      d='M6.96906 6.9502H12.3691C12.7791 6.9502 13.1191 7.2902 13.1191 7.7002C13.1191 8.1102 12.7791 8.4502 12.3691 8.4502H6.96906C6.55906 8.4502 6.21906 8.1102 6.21906 7.7002C6.21906 7.2902 6.55906 6.9502 6.96906 6.9502Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.32 2.41016H7.67999C4.36999 2.41016 1.67999 5.10016 1.67999 8.41016V15.5902C1.67999 18.9002 4.36999 21.5902 7.67999 21.5902H16.32C19.63 21.5902 22.32 18.9002 22.32 15.5902V8.41016C22.32 5.10016 19.63 2.41016 16.32 2.41016ZM20.82 13.8102H17.52C16.45 13.8102 15.58 12.9402 15.58 11.8702C15.58 10.8002 16.45 9.93016 17.52 9.93016H20.82V13.8102ZM16.32 20.0902H7.67999C5.19999 20.0902 3.17999 18.0702 3.17999 15.5902V8.41016C3.17999 5.93016 5.19999 3.91016 7.67999 3.91016H16.32C18.8 3.91016 20.82 5.93016 20.82 8.41016V8.43016H17.52C15.62 8.43016 14.08 9.97016 14.08 11.8702C14.08 13.7702 15.62 15.3102 17.52 15.3102H20.82V15.5902C20.82 18.0702 18.8 20.0902 16.32 20.0902Z'
      fill='currentColor'
    />
  </svg>
);

export { Wallet };

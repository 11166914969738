import React, { useMemo } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  isCompactLayout: boolean;
  imgSrc?: string;
  loading?: boolean;
};

const NewsCardImage: React.FC<Props> = ({ imgSrc, isCompactLayout, loading = false }) => {
  const compactClasses = useMemo(
    () => (isCompactLayout ? 'flex min-w-[96px] max-w-[96px] items-center justify-center' : ''),
    [isCompactLayout],
  );

  const loadingClasses = useMemo(() => (loading ? 'animate-pulse' : ''), [loading]);

  return (
    <div
      style={{
        backgroundImage: loading ? '' : `url(${imgSrc})`,
      }}
      className={cn(
        'max-h-[96px] min-h-[96px] overflow-hidden rounded-12 bg-color-background-surface-active',
        compactClasses,
        loadingClasses,
        'bg-cover bg-center bg-no-repeat',
      )}
    />
  );
};

export { NewsCardImage };

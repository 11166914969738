import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject } from 'react-router-dom';

import { UserStore } from '@shared/store';

import PrivateRoute from '@routes/PrivateRoute';

import Onboarding from '@Onboarding/Onboarding';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { SwyftxAppShellCustomHeader } from 'src/lib/layout/SwyftxAppShellCustomHeader';
import { NavigationURLs } from 'src/lib/navigation/types';

import { OnboardingProvider } from './Onboarding.context';
import { HeaderExitButton } from './components';

const OnboardingRoute: React.FC = () => {
  const { isEntity } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { t } = useTranslation('onboarding');

  const [exitButtonText, setExitButtonText] = useState(t('actions.saveAndExit'));

  if (!isFeatureEnabled(AppFeature.OnboardingWizard) || isEntity()) {
    return <Navigate to={NavigationURLs.ProfileVerification} />;
  }

  const handleExit = () => {
    setExitButtonText(t('actions.exit'));
  };

  return (
    <SwyftxAppShellCustomHeader headerItemOverride={<HeaderExitButton text={exitButtonText} />}>
      <PrivateRoute
        element={
          <OnboardingProvider>
            <Onboarding handleExit={handleExit} />
          </OnboardingProvider>
        }
      />
    </SwyftxAppShellCustomHeader>
  );
};

export const OnboardingRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Onboarding,
    element: <OnboardingRoute />,
  },
];

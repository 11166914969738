import { Network, WithdrawalAddressDetailsOptionalKeyEnum } from '@shared/api';
import env from '@shared/config';
import { assetService, withdrawalService } from '@shared/services';

import * as WAValidator from 'src/wallet-address-validator';

const validator: any = (window as any).WAValidator || WAValidator;

export interface CryptoAddressMetadata {
  key: string;
  name: string;
  required?: boolean;
}

let isInitialised = false;

const init = () => {
  if (isInitialised) return;

  const assets = assetService.getAllAssets();

  validator.initialiseNetworks(Object.values(assets), Math.random().toString()); // Use Math.random to force update
  validator.setNetworkType(env.CRYPTO_VALIDATOR_NETWORK);
  isInitialised = true;
};

const getCryptoNetworks = (address: string, assetCode: string) => {
  try {
    init();
    const args = { address, asset: assetCode, includeDisabledNetworks: true };
    return validator.getNetworksForAddress(args).networks || [];
  } catch (error) {
    return [];
  }
};

const getFriendlyMetadataKeyName = (network: Network): CryptoAddressMetadata | null =>
  withdrawalService.withdrawalAddressMetadataKeyNamePair.find(
    (pair) =>
      (network.memo && pair.key === WithdrawalAddressDetailsOptionalKeyEnum.Memo) ||
      (network.destinationTag && pair.key === WithdrawalAddressDetailsOptionalKeyEnum.DestinationTag) ||
      (network.message && pair.key === WithdrawalAddressDetailsOptionalKeyEnum.Message) ||
      (network.paymentId && pair.key === WithdrawalAddressDetailsOptionalKeyEnum.PaymentId),
  ) || null;

const isValidCryptoAddress = (address: string, assetCode: string) => {
  try {
    init();
    const args = { address, asset: assetCode, includeDisabledNetworks: true };
    return validator.getNetworksForAddress(args)?.valid;
  } catch (error) {
    return false;
  }
};

const isValidCryptoMemo = (metadata?: string, networkName?: string) => {
  try {
    init();
    const args = { metadata, networkName };
    const valid = validator.validateMetadataOnNetwork(args);
    return valid || !metadata;
  } catch (error) {
    return false;
  }
};

export const walletAddressService = {
  getCryptoNetworks,
  getFriendlyMetadataKeyName,
  isValidCryptoAddress,
  isValidCryptoMemo,
};

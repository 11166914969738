import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';

import { UserStore } from '@shared/store';

import * as braze from '@braze/web-sdk';
import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

type BrazeContextType = {
  contentCards: braze.Card[];
};

const BrazeContext = createContext<BrazeContextType>({
  contentCards: [],
});

const BrazeProvider: React.FC<PropsWithChildren> = observer(({ children }) => {
  const { userProfile } = UserStore.useUserStore;
  const [contentCards, setContentCards] = useState<braze.Card[]>([]);
  const { isFeatureEnabled } = useIsFeatureEnabled();

  useEffect(() => {
    if (!userProfile?.intercom?.uuid) return;

    braze.removeAllSubscriptions();
    braze.changeUser(userProfile?.intercom?.uuid as string);

    if (!isFeatureEnabled(AppFeature.Braze)) return;

    braze.subscribeToContentCardsUpdates((cards: braze.ContentCards) => {
      setContentCards(cards.cards);
    });

    braze.subscribeToInAppMessage((message: braze.InAppMessage | braze.ControlMessage) => {
      braze.showInAppMessage(message);
    });

    braze.openSession();
    braze.requestContentCardsRefresh();
  }, [isFeatureEnabled, userProfile?.intercom?.uuid]);

  return <BrazeContext.Provider value={{ contentCards }}>{children}</BrazeContext.Provider>;
});

export { BrazeProvider, BrazeContext };

import { OrderType } from '@shared/api';

export const getOrderSide = (orderType: OrderType | null) => {
  switch (orderType) {
    case OrderType.MarketBuy:
    case OrderType.TriggerBuy:
    case OrderType.StopBuy:
    case OrderType.OTCBuy:
      return 'Buy';
    case OrderType.MarketSell:
    case OrderType.TriggerSell:
    case OrderType.StopSell:
    case OrderType.OTCSell:
    case OrderType.DustSell:
      return 'Sell';
    default:
      return 'Unknown';
  }
};

export const getOrderType = (orderType: OrderType | null) => {
  switch (orderType) {
    case OrderType.MarketBuy:
    case OrderType.MarketSell:
      return 'Instant';
    case OrderType.TriggerBuy:
    case OrderType.TriggerSell:
      return 'Trigger';
    case OrderType.StopBuy:
    case OrderType.StopSell:
      return 'Stop';
    case OrderType.OTCSell:
    case OrderType.OTCBuy:
      return 'OTC';
    case OrderType.DustSell:
      return 'Dusting';
    default:
      return 'Unknown';
  }
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const UnverifiedFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.733 2.72027L14.5113 3.49864C14.9686 3.95377 15.5881 4.2089 16.2335 4.2089H17.3448C18.6951 4.2089 19.7902 5.30402 19.7902 6.65427V7.76453C19.7902 8.411 20.0453 9.02937 20.5004 9.48775L21.2788 10.2661C22.2355 11.2185 22.241 12.7655 21.2885 13.7234L20.5004 14.5115C20.0453 14.9677 19.7902 15.5882 19.7902 16.2325V17.3449C19.7902 18.6952 18.6951 19.7892 17.3448 19.7892H16.2335C15.5881 19.7892 14.9686 20.0454 14.5113 20.5006L13.733 21.2779C12.7816 22.2357 11.2335 22.24 10.2757 21.2876L9.48869 20.5006C9.03032 20.0454 8.41087 19.7892 7.76655 19.7892H6.65414C5.30496 19.7892 4.20984 18.6952 4.20984 17.3449V16.2325C4.20984 15.5882 3.95363 14.9677 3.4985 14.5115L2.72122 13.7331C1.76339 12.7807 1.75907 11.2326 2.71149 10.2758L3.4985 9.48775C3.95363 9.02937 4.20984 8.411 4.20984 7.76453V6.65427C4.20984 5.30402 5.30496 4.2089 6.65414 4.2089H7.76655C8.41087 4.2089 9.03032 3.95377 9.48869 3.49864L10.266 2.72027C11.2184 1.76352 12.7665 1.7592 13.7232 2.71162L13.733 2.72027ZM10.6603 9.71652C10.3703 9.42652 9.89031 9.42652 9.60031 9.71652C9.31031 10.0065 9.31031 10.4865 9.60031 10.7765L10.9503 12.1265L9.60031 13.4765C9.31031 13.7665 9.31031 14.2465 9.60031 14.5365C9.89031 14.8265 10.3703 14.8265 10.6603 14.5365L12.0103 13.1865L13.3603 14.5365C13.6503 14.8265 14.1303 14.8265 14.4203 14.5365C14.7103 14.2465 14.7103 13.7665 14.4203 13.4765L13.0703 12.1265L14.4203 10.7765C14.7103 10.4865 14.7103 10.0065 14.4203 9.71652C14.1303 9.42652 13.6503 9.42652 13.3603 9.71652L12.0103 11.0665L10.6603 9.71652Z'
      fill='currentColor'
    />
  </svg>
);

export { UnverifiedFilled };

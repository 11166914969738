import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Information: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M11.9998 17.0463C11.5856 17.0463 11.2498 16.7105 11.2498 16.2963V11.8773C11.2498 11.4631 11.5856 11.1273 11.9998 11.1273C12.414 11.1273 12.7498 11.4631 12.7498 11.8773V16.2963C12.7498 16.7105 12.414 17.0463 11.9998 17.0463Z'
      fill='currentColor'
    />
    <path
      d='M11.9998 9.4545C12.414 9.4545 12.7498 9.11871 12.7498 8.7045C12.7498 8.29028 12.414 7.9545 11.9998 7.9545H11.9898C11.5756 7.9545 11.2398 8.29028 11.2398 8.7045C11.2398 9.11871 11.5756 9.4545 11.9898 9.4545H11.9998Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.99982 12.0004C1.99982 6.47818 6.4776 2.0004 11.9998 2.0004C17.5231 2.0004 21.9998 6.47824 21.9998 12.0004C21.9998 17.5226 17.5231 22.0004 11.9998 22.0004C6.4776 22.0004 1.99982 17.5226 1.99982 12.0004ZM11.9998 3.5004C7.30603 3.5004 3.49982 7.30661 3.49982 12.0004C3.49982 16.6942 7.30603 20.5004 11.9998 20.5004C16.6946 20.5004 20.4998 16.6942 20.4998 12.0004C20.4998 7.30656 16.6946 3.5004 11.9998 3.5004Z'
      fill='currentColor'
    />
  </svg>
);

export { Information };

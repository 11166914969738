import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { RadioGroup } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

import { yesNoRadioGroup } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';
import { getRadioSelectedValue } from '@routes/EntityOnboarding/utils/getRadioSelectedValue';

interface Props {
  entityType: 'company' | 'trust';
  isShareholder?: boolean;
  hideQuestionNumbers?: boolean;
  hasThirdPartyCustomers?: boolean;
  hideThirdPartyQuestion?: boolean;
  setHasThirdPartyCustomers: (value: boolean) => void;
  tradesArmsOilOrPreciousMats?: boolean;
  setTradesArmsOilOrPreciousMats: (value: boolean) => void;
}

export const SupplyInformationStepComplianceDisclaimers: React.FC<Props> = (props) => {
  const {
    entityType,
    hasThirdPartyCustomers,
    setHasThirdPartyCustomers,
    tradesArmsOilOrPreciousMats,
    setTradesArmsOilOrPreciousMats,
    hideThirdPartyQuestion,
    isShareholder,
    hideQuestionNumbers,
  } = props;

  const entityTypeText = entityType === 'company' ? 'company' : 'corporate trustee';
  const entityTypePluralText = entityType === 'company' ? 'companies' : 'trusts';
  const entityDeterminerText = isShareholder ? 'this' : 'your';

  return (
    <FlexLayout direction='column' spacing={32}>
      {!hideThirdPartyQuestion && (
        <FlexLayout direction='column' spacing={12}>
          <Body size='small' weight='emphasis'>
            {hideQuestionNumbers ? '' : '4.'} Does {entityDeterminerText} {entityTypeText} offer financial services to
            underlying third-party customers?
          </Body>
          <RadioGroup
            checkboxes={yesNoRadioGroup}
            selected={getRadioSelectedValue(hasThirdPartyCustomers)}
            onChange={(value) => setHasThirdPartyCustomers(value === 'yes')}
          />
          {hasThirdPartyCustomers && (
            <Body size='small' color='error'>
              Swyftx does not offer services to {entityTypePluralText} who deal with third-party customers
            </Body>
          )}
        </FlexLayout>
      )}
      <FlexLayout direction='column' spacing={12}>
        <Body size='small' weight='emphasis'>
          {hideQuestionNumbers ? '' : '5.'} Does {entityDeterminerText} {entityTypeText} directly trade in arms, oil or
          precious materials?
        </Body>
        <RadioGroup
          checkboxes={yesNoRadioGroup}
          selected={!hasThirdPartyCustomers ? getRadioSelectedValue(tradesArmsOilOrPreciousMats) : undefined}
          onChange={(value) => setTradesArmsOilOrPreciousMats(value === 'yes')}
          disabled={hasThirdPartyCustomers}
        />
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useCallback, useMemo } from 'react';

import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart';
import { useDonutChartLegend } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks';
import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { useGainersAndLosers } from '@hooks/Assets/useGainersAndLosers';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { MarketGainersLosersItem } from './MarketGainersLosersItem';
import { MarketActivityType } from '../../types/Markets.types';

type Props = {
  assets?: Asset[];
  className?: string;
  layoutClassName?: string;
  direction?: 'column' | 'row';
  alignment?: 'start' | 'center' | 'end';
  height?: number;
  onClick?: (type: MarketActivityType) => void;
};

const MarketsGainersLosersWidget: React.FC<Props> = ({
  assets,
  className,
  layoutClassName,
  direction,
  alignment,
  height = 200,
  onClick,
}) => {
  const { externalMutations, setExternalMutations } = useDonutChartMutations();
  const { onMouseEnter, onMouseLeave } = useDonutChartLegend({
    onUpdateExternalMutations: setExternalMutations,
    chartId: 'markets-gainers-losers',
  });
  const { navigate } = useNavigateRoute();
  const { loaded, gainers, losers, gainersPercentage, losersPercentage } = useGainersAndLosers(assets);

  const data = useMemo(
    () => [
      {
        y: losers.length,
        color: 'var(--color-background-error)',
        title: '',
      },
      {
        y: gainers.length,
        color: 'var(--color-background-success)',
        title: '',
      },
    ],
    [gainers.length, losers.length],
  );

  const openGainers = useCallback(() => {
    if (onClick) {
      onClick('gainers');
      return;
    }

    navigate(NavigationURLs.AssetsGainers);
  }, [navigate, onClick]);

  const openLosers = useCallback(() => {
    if (onClick) {
      onClick('losers');
      return;
    }

    navigate(NavigationURLs.AssetsLosers);
  }, [navigate, onClick]);

  return (
    <Card className={cn('overflow-hidden @container', className)}>
      <CardContent className='h-full pt-24 @xs:p-16  @sm:p-24'>
        {direction === 'row' && (
          <FlexLayout
            spacing={24}
            className={cn('h-full w-full', layoutClassName)}
            alignItems={alignment}
            direction={direction}
            justifyContent='space-around'
          >
            <div
              role='navigation'
              onMouseEnter={() => onMouseEnter('1')}
              onMouseLeave={onMouseLeave}
              onClick={openGainers}
              className='cursor-pointer rounded-[8px] p-8 hover:bg-color-background-surface-hover'
            >
              <MarketGainersLosersItem
                loaded={loaded}
                title='Gainers'
                color='success'
                assets={gainers.length}
                assetsPercentage={gainersPercentage}
                direction={direction}
              />
            </div>
            <div className=' overflow-hidden' style={{ height }}>
              <DonutChart
                id='markets-gainers-losers'
                innerRadius={height - 30}
                height={height * 2}
                showTooltip={false}
                startAngle={88}
                endAngle={-88}
                data={data}
                alignment='start'
                externalMutations={externalMutations}
                setExternalMutations={setExternalMutations}
              />
            </div>
            <div
              role='navigation'
              onMouseEnter={() => onMouseEnter('0')}
              onMouseLeave={onMouseLeave}
              onClick={openLosers}
              className='cursor-pointer rounded-[8px] p-8 hover:bg-color-background-surface-hover'
            >
              <MarketGainersLosersItem
                loaded={loaded}
                title='Losers'
                color='error'
                assets={losers.length}
                assetsPercentage={losersPercentage}
                direction={direction}
              />
            </div>
          </FlexLayout>
        )}

        {direction === 'column' && (
          <FlexLayout
            className='h-full w-full'
            alignItems='center'
            spacing={12}
            direction={direction}
            justifyContent='space-between'
          >
            <div className='max-h-[130px] min-h-[130px] overflow-hidden'>
              <DonutChart
                id='markets-gainers-losers'
                innerRadius={125}
                height={260}
                alignment='start'
                showTooltip={false}
                startAngle={88}
                endAngle={-88}
                data={data}
                externalMutations={externalMutations}
                setExternalMutations={setExternalMutations}
              />
            </div>

            <FlexLayout className=' w-full' alignItems='center' justifyContent='space-between' spacing={24}>
              <div
                role='navigation'
                onMouseEnter={() => onMouseEnter('1')}
                onMouseLeave={onMouseLeave}
                onClick={openGainers}
                className='w-1/2 cursor-pointer rounded-[8px] p-8 hover:bg-color-background-surface-hover'
              >
                <MarketGainersLosersItem
                  loaded={loaded}
                  title='Gainers'
                  color='success'
                  assets={gainers.length}
                  assetsPercentage={gainersPercentage}
                  direction={direction}
                />
              </div>

              <div
                role='navigation'
                onMouseEnter={() => onMouseEnter('0')}
                onMouseLeave={onMouseLeave}
                onClick={openLosers}
                className='w-1/2 cursor-pointer rounded-[8px] p-8 hover:bg-color-background-surface-hover'
              >
                <MarketGainersLosersItem
                  loaded={loaded}
                  title='Losers'
                  color='error'
                  assets={losers.length}
                  assetsPercentage={losersPercentage}
                  direction={direction}
                />
              </div>
            </FlexLayout>
          </FlexLayout>
        )}
      </CardContent>
    </Card>
  );
};

export { MarketsGainersLosersWidget };

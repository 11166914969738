import { useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyLeft, Information } from '@swyftx/aviary/icons/outlined';

import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';

import { DustableAssets } from './DustableAssets';
import { WhatIsDustingContent } from './WhatIsDustingContent';

type Props = {
  defaultOpen?: boolean;
};

const useDustingModal = ({ defaultOpen }: Props = {}) => {
  const [open, setOpen] = useState<boolean | undefined>(defaultOpen);
  const [seenDustingPrompt, setSeenDustingPrompt] = useLocalStorage<boolean>(StorageKey.DUSTING_MESSAGE_PROMPT, false);
  const [showWhatIsDusting, setShowWhatisDusting] = useState<'prompt' | 'manual' | undefined>();
  const isXs = useTailwindBreakpoint('xs');

  useEffect(() => {
    if (!seenDustingPrompt) {
      setShowWhatisDusting('prompt');
      setSeenDustingPrompt(true);
    }
  }, [seenDustingPrompt, setSeenDustingPrompt]);

  const content = useMemo(() => {
    if (showWhatIsDusting)
      return (
        <WhatIsDustingContent
          onContinue={() => setShowWhatisDusting(undefined)}
          showContinueButton={showWhatIsDusting === 'prompt'}
        />
      );
    return <DustableAssets onClose={() => setOpen(false)} />;
  }, [setOpen, showWhatIsDusting]);

  const title = useMemo(() => {
    if (showWhatIsDusting)
      return (
        <FlexLayout alignItems='center' spacing={16}>
          {showWhatIsDusting === 'manual' && (
            <Button
              layout='icon'
              size='md'
              variant='outlined'
              onClick={() => setShowWhatisDusting(undefined)}
              leadingIcon={<ArrowLineOnlyLeft />}
            />
          )}
          <Heading size='h5'>What is Dusting?</Heading>
        </FlexLayout>
      );
    return (
      <FlexLayout alignItems='center' spacing={8}>
        <Heading size='h5'>Dust small balances</Heading>
        <Button
          layout='icon'
          size='sm'
          variant='ghost'
          onClick={() => setShowWhatisDusting('manual')}
          leadingIcon={<Information />}
        />
      </FlexLayout>
    );
  }, [showWhatIsDusting]);

  const position: 'bottom' | 'center' = useMemo(() => (isXs ? 'bottom' : 'center'), [isXs]);

  return {
    title,
    content,
    position,
    open,
    setOpen,
  };
};

export { useDustingModal };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Modal, Notification } from '@swyftx/react-web-design-system';

import { PasswordInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';
import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { EntityTypeEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';
import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

import { ChangePasswordModalProps } from './ChangePasswordModal.types';

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = observer(({ id, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t } = useTranslation('modals');
  const { addMessageBox, addToastMessage } = UIStore.useUIStore;
  const logout = useLogout();
  const { userProfile } = UserStore.useUserStore;

  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

  const isLocked = !currentPassword || !newPassword || !newPasswordValid || !confirmPassword || !confirmPasswordValid;

  const changePassword = async () => {
    try {
      await UserService.ChangePassword(currentPassword, newPassword);
      addMessageBox({
        content: (
          <SuccessMessageBox title={t('changePassword.success.title')} content={t('changePassword.success.subTitle')} />
        ),
      });
      onClose();
      logout();
    } catch (e) {
      const err = e as SwyftxError;
      addMessageBox({
        content: (
          <ErrorMessageBox
            title={t('changePassword.error.title')}
            content={err?.errorMessage || t('changePassword.error.subTitle')}
          />
        ),
      });
    }
  };

  const getModalContent = () => (
    <>
      <Notification id='enable-two-factor-step-one' severity='info' title={t('changePassword.notification.title')}>
        {t('changePassword.notification.subTitle')}
      </Notification>
      <Box marginTop={2}>
        <PasswordInput
          id='change-password-old'
          label={t('changePassword.labels.currentPassword')}
          type='general'
          value={currentPassword}
          sx={{ marginBottom: 0 }}
          onChange={(e) => setCurrentPassword(e.target.value)}
          hideValidation
        />
        <PasswordInput
          id='change-password-new'
          label={t('changePassword.labels.newPassword')}
          type='create'
          showRequirements
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          setValid={setNewPasswordValid}
        />
        <PasswordInput
          id='change-password-confirm'
          label={t('changePassword.labels.confirmNewPassword')}
          type='reenter'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          compareValue={newPassword}
          setValid={setConfirmPasswordValid}
        />
      </Box>
    </>
  );

  useEffect(() => {
    if (userProfile?.entityDetails?.entityType !== EntityTypeEnum.PERSONAL) {
      onClose();
      addToastMessage({ message: 'Entity accounts cannot change their password', severity: 'error' });
    }
  }, [addToastMessage, onClose, userProfile]);

  return (
    <Modal id={id} open onClose={onClose} sx={{ maxWidth: '640px' }}>
      <Wizard.Step
        title={t('changePassword.title')}
        actionName='Change password'
        onAction={changePassword}
        onClose={onClose}
        locked={isLocked}
      >
        {getModalContent()}
      </Wizard.Step>
    </Modal>
  );
});

export default ChangePasswordModal;

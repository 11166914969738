import { IChartApi } from 'lightweight-charts';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

const isAdvancedChartState = (chartState?: IChartApi | IChartingLibraryWidget): chartState is IChartingLibraryWidget =>
  (chartState as IChartingLibraryWidget)?.activeChart !== undefined;

const isSimpleChartState = (chartState?: IChartApi | IChartingLibraryWidget): chartState is IChartApi =>
  (chartState as IChartApi)?.addAreaSeries !== undefined;

export { isAdvancedChartState, isSimpleChartState };

import { useEffect } from 'react';

import { AssetType } from '@shared/api';
import { PairExchangeRateParams, PairExchangeRateResponse } from '@shared/api/@types/trade';
import { FiatIdEnum } from '@shared/enums';
import { useGetRateInterval } from '@shared/hooks';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';
import {
  OrderStatus,
  TradeType,
  UniversalTradeStoreSchema,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency, isApiError } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';
import { useHighSlippage } from '@hooks/Trade';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';
import TradeService, { OrderRateResponseError } from '@services/TradeService';

import { useTradePanelTradeErrors } from './useTradePanelRateErrors';

export const useUpdateTradePanelRates = ({
  tradeType,
  tradeData,
  setTradeData,
  setTradeUIData,
  executingOrders,
  orderStatus,
  tradeFrom,
  tradeTo,
}: UniversalTradeStoreSchema) => {
  const { isSwap } = useUniversalTradeUtilityStore();
  const { calculateSlippage } = useHighSlippage();

  const { userCountryCurrency, balances } = UserStore.useUserStore;
  const { processMaximumError } = useTradePanelTradeErrors();
  const avo = useAvo();

  const { restartGetRate, getInstantRate } = useGetRateInterval(
    () => getUpdatedOrderRate(),
    () => !Object.keys(tradeData).length || executingOrders || orderStatus !== OrderStatus.Idle,
    [tradeData, executingOrders, orderStatus, tradeFrom, tradeTo, tradeType],
  );

  useEffect(() => {
    getInstantRate();
  }, [tradeType, tradeData, tradeFrom, tradeTo]);

  /** Update order rate from server */
  const getUpdatedOrderRate = async () => {
    const tradeDataKeys = Object.keys(tradeData);
    const tradeDataValues = Object.values(tradeData);

    const orderRateData: PairExchangeRateParams[] = tradeDataKeys.map((tdk: string) => {
      const { from, to, limit, balance } = tradeData[tdk];
      const fromAsset = assetService.getAsset(from);
      const toAsset = assetService.getAsset(to);
      const limitCode = limit === from ? fromAsset!.code : toAsset!.code;

      return {
        buy: toAsset!.code,
        sell: fromAsset!.code,
        amount: Big(balance).toNumber(),
        limit: Big(balance).gt(0) ? limitCode : '',
      };
    });

    try {
      const tempOrderRates = await TradeService.getOrderRate(orderRateData);

      if (tempOrderRates) {
        for (let i = 0; i < tempOrderRates.length; i += 1) {
          let fee = 0;
          let amount = '';
          let total = '';
          let price = '';
          // let swapAmount = '';
          const orderRate = tempOrderRates[i];
          const orderData = orderRateData[i];
          const data = tradeDataValues[i];
          const { from, to, balance, limit } = data;
          const fromAsset = assetService.getAsset(from);
          const toAsset = assetService.getAsset(to);

          if (isApiError(orderRate)) {
            const { error } = TradeService.processError(orderRate, { from, to, limit, amount: balance || '' });
            const errorObj = orderRate;
            const errorMessage = JSON.parse(errorObj.error.message);

            if (errorObj.error.error === 'MaximumAmountError') {
              const errorValue = errorMessage.message.split('Your order has a maximum order of ')[1];

              const errorValueTo = formatCurrency(error.detail, fromAsset);

              setTradeUIData(from, to, {
                error: errorMessage.message.replace(errorValue, errorValueTo),
                loading: false,
              });
            } else {
              setTradeUIData(from, to, { error: errorMessage.message, loading: false });
            }
          } else {
            if (isSwap(data) && fromAsset && toAsset) {
              const swapBaseCurrency = assetService.getAsset(userCountryCurrency || FiatIdEnum.USD)!;

              const fromRateData = {
                buy: swapBaseCurrency?.code,
                limit: Big(balance).gt(0) ? fromAsset.code : '',
                sell: fromAsset.code,
                amount: Big(balance).round(fromAsset.price_scale, 0).toNumber(),
              };

              const sellOrderRate = (await TradeService.getOrderRate([fromRateData])) as PairExchangeRateResponse[];

              if (sellOrderRate[0].amount) {
                const toRateData = {
                  buy: toAsset.code,
                  limit: Big(sellOrderRate[0].amount).gt(0) ? swapBaseCurrency?.code : '',
                  sell: swapBaseCurrency?.code,
                  amount: Number(sellOrderRate[0].amount),
                };

                const buyOrderRate = (await TradeService.getOrderRate([toRateData])) as PairExchangeRateResponse[];

                if (buyOrderRate[0].amount) {
                  fee += Number(buyOrderRate[0].feePerUnit || 0);
                  fee += Number(sellOrderRate[0].feePerUnit || 0);
                  amount = buyOrderRate[0].amount || '';
                  price = buyOrderRate[0].price || '';
                  total = sellOrderRate[0].total || '';
                }
              }
            } else {
              fee += Number(orderRate.feePerUnit || 0);
              amount = Big(orderRate.amount).toString() || '0';
              total = Big(orderRate.total).toString() || '0';
              price = Big(orderRate.price).toString() || '0';
            }

            const baseAssets = assetService.getBaseAssetCodes();
            const orderBuy = orderData.buy;
            const orderSell = orderData.sell;
            const orderBuyAsset = assetService.getAssetByCode(orderBuy);
            const buyingBase =
              !baseAssets.includes(orderSell) &&
              baseAssets.includes(orderBuy) &&
              orderBuyAsset!.assetType === AssetType.Fiat;

            // We are selling an asset
            const trigger = buyingBase ? Big(1).div(orderRate.price) : Big(orderRate.price);
            const ratePrice = Big(orderRate.price);
            let slippagePercentage = 0;
            const hasHighSlippageAlready = tradeData[`${from}_${to}`].isHighSlippage;

            if (fromAsset && toAsset) {
              const { slippage: calcSlippage } = calculateSlippage(fromAsset, toAsset, Big(ratePrice));
              slippagePercentage = calcSlippage || 0;
            }

            // If this is the first time we have hit high slippage, send an event
            if (!hasHighSlippageAlready && slippagePercentage >= HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD) {
              avo.highSlippageTrade({
                screen: 'trade',
                slippagePercentage,
                price: ratePrice,
                fromAsset: fromAsset?.code ?? undefined,
                toAsset: toAsset?.code ?? undefined,
              });
            }

            setTradeData(from, to, {
              trigger: trigger.toFixed(18).toString(),
              fee,
              amount,
              total,
              price,
              ratePrice: ratePrice.toFixed(18).toString(),
              slippagePercentage,
              isHighSlippage: hasHighSlippageAlready ? true : slippagePercentage >= HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD,
            });

            setTradeUIData(from, to, { error: '', loading: false });

            if (tradeType !== TradeType.OnTrigger) {
              if (Big(total).gt(balances[from].availableBalance)) {
                setTradeUIData(from, to, { error: 'Amount is larger than your balance', loading: false });
              }
            }
          }
        }
      }
    } catch (e: any) {
      // If the rate has failed to fetch from the backend we need to determine what the issue may be
      const { error, data } = e as OrderRateResponseError;

      if (data) {
        // If data exists we need to loop through them all, the data is mapped to each trade i.e AUD -> ADA, AUD -> ETH
        data.forEach((d) => {
          const { buy, sell } = d;
          const fromAsset = assetService.getAssetByCode(sell);
          const from = fromAsset?.id;
          const toAsset = assetService.getAssetByCode(buy);
          const to = toAsset?.id;

          // We can only do the eval if we have a valid to and from id
          if (from && to) {
            // the lookup in the store uses a key that combines from and to
            // in some cases this may be flipped so we check both
            const keyVariant = `${from}_${to}`;
            const keyAltVariant = `${to}_${from}`;

            // if the trade data contains the from_to key, lets work out the error
            if (tradeData[keyVariant]) {
              processMaximumError(tradeData[keyVariant], error);
            } else if (tradeData[keyAltVariant]) {
              processMaximumError(tradeData[keyAltVariant], error);
            }
          }
        });
      }
    }

    restartGetRate();
  };
};

import { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset, UserBalance } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';

import { usePortfolioValue } from './usePortfolioValue';
import {
  PortfolioTableAllocation,
  PortfolioTableAsset,
  PortfolioTableBalance,
  PortfolioTableValue,
  PortfolioTableProfitLossValue,
  PortfolioTableActions,
  PortfolioTableProfitLossPercentage,
  PortfolioTableAverageBuyPrice,
} from '../components/PortfolioTable';
import { PortfolioTableData, PortfolioTableVariant } from '../types';

type Props = {
  showSmallBalances?: boolean;
  variant?: PortfolioTableVariant;
  widgetId?: DashboardWidgets;
};

type SingleCategoryHeaderData = { [key in keyof PortfolioTableData]: EnhancedTableHeaderData };

const usePortfolioTable = ({ showSmallBalances = true, variant = 'full', widgetId }: Props) => {
  const [search, setSearch] = useState<string>('');

  const { balances } = UserStore.useUserStore;
  const { convertRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const { getAllocationPercentage, getProfitLossValue, getProfitLossPercentage, getAveragePricePaid } =
    usePortfolioValue({
      valueAsset: baseAsset,
      side: 'midPrice',
    });

  const [sort, setSort] = useDashboardSyncState<EnhancedTableSort<PortfolioTableData>>({
    widgetId,
    stateKey: 'sort',
    defaultValue: { sortKey: 'balanceValue', sortDirection: 'DESC' },
  });

  const headers: SingleCategoryHeaderData = useMemo(
    () => ({
      asset: {
        title: 'Asset',
        alignment: 'start',
        sortable: true,
        className: 'hidden @md:table-cell',
        frozen: true,
        insetLeft: true,
      },
      balance: {
        title: 'Balance',
        alignment: 'end',
        sortable: true,
        className: `hidden ${variant === 'full' ? '@md:table-cell' : ''}`,
      },
      avgBuyPrice: {
        title: 'Avg Buy Price',
        alignment: 'end',
        sortable: true,
        className: `hidden ${variant === 'full' ? '@md:table-cell' : ''}`,
      },

      profitLossPercentage: {
        title: 'Performance (%)',
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },

      profitLossValue: {
        title: 'Performance',
        alignment: 'end',
        sortable: true,
        className: `hidden ${variant === 'full' ? '@md:table-cell' : ''}`,
      },

      balanceValue: {
        title: 'value',
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },

      assetCode: {
        title: 'Asset',
        alignment: 'start',
        sortable: true,
        frozen: true,
        insetLeft: true,
        className: 'hidden @sm:table-cell @md:hidden',
      },
      performance: {
        title: 'Performance',
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell @md:hidden',
      },
      valueBalance: {
        title: 'Value',
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell @md:hidden',
      },

      allocation: {
        title: 'Allocation',
        alignment: 'end',
        sortable: true,
        className: `hidden ${variant === 'full' ? '@sm:table-cell' : ''}`,
      },

      actions: {
        title: 'actions',
        alignment: 'end',
        sortable: false,
        className: `hidden ${variant === 'full' ? '@md:table-cell' : ''}`,
        insetRight: true,
      },

      assetBalance: {
        title: 'Asset',
        alignment: 'start',
        sortable: false,
        insetLeft: true,
        className: 'table-cell @sm:hidden',
      },

      valueProfit: {
        title: 'Value',
        alignment: 'end',
        sortable: false,
        insetRight: true,
        className: 'table-cell @sm:hidden',
      },
    }),
    [variant],
  );

  const data: EnhancedTableData<PortfolioTableData, Asset>[] = useMemo(
    () =>
      !baseAsset
        ? []
        : Object.values(balances)
            .filter((b: UserBalance) => Big(b.availableBalance).gt(0) && assetService.getAsset(b.assetId))
            .map((balance: UserBalance) => {
              const asset = assetService.getAsset(balance.assetId)!;
              const value = convertRate(asset, baseAsset, balance.availableBalance, 'midPrice');
              const profitLossValue = getProfitLossValue(asset);
              const profitLossPercentage = getProfitLossPercentage(asset);
              const averagePricePaid = getAveragePricePaid(asset);
              const allocation = getAllocationPercentage(value);

              return {
                value: asset,
                asset: {
                  value: asset.code,
                  element: <PortfolioTableAsset asset={asset} variant={variant} />,
                },
                balance: {
                  value: balance.availableBalance,
                  element: <PortfolioTableBalance asset={asset} balance={balance} />,
                },
                avgBuyPrice: {
                  value: averagePricePaid,
                  element: <PortfolioTableAverageBuyPrice averagePricePaid={averagePricePaid} />,
                },
                profitLossPercentage: {
                  value: profitLossPercentage,
                  element: <PortfolioTableProfitLossPercentage profitLossPercentage={profitLossPercentage} />,
                },

                profitLossValue: {
                  value: profitLossValue,
                  element: <PortfolioTableProfitLossValue profitLossValue={profitLossValue} />,
                },
                balanceValue: {
                  value,
                  element: <PortfolioTableValue baseAsset={baseAsset} value={value} />,
                },

                assetCode: {
                  value: asset.code,
                  element: (
                    <FlexLayout justifyContent='start'>
                      <PortfolioTableAsset asset={asset} direction='column' variant={variant} />
                    </FlexLayout>
                  ),
                },
                performance: {
                  value: profitLossValue,
                  element: (
                    <FlexLayout direction='column' alignItems='end'>
                      <PortfolioTableProfitLossValue profitLossValue={profitLossValue} />
                      <PortfolioTableProfitLossPercentage profitLossPercentage={profitLossPercentage} />
                    </FlexLayout>
                  ),
                },
                valueBalance: {
                  value,
                  element: (
                    <FlexLayout direction='column' alignItems='end'>
                      <PortfolioTableValue baseAsset={baseAsset} value={value} />
                      <PortfolioTableBalance asset={asset} balance={balance} />
                    </FlexLayout>
                  ),
                },

                allocation: {
                  value: allocation,
                  element: (
                    <FlexLayout justifyContent='end'>
                      <PortfolioTableAllocation asset={asset} countryAsset={baseAsset} value={value} />{' '}
                    </FlexLayout>
                  ),
                },

                actions: {
                  element: <PortfolioTableActions asset={asset} countryAsset={baseAsset} />,
                },

                assetBalance: {
                  value: asset.code,
                  element: (
                    <FlexLayout direction='column' alignItems='start'>
                      <PortfolioTableAsset direction='column' asset={asset} variant={variant} showCode={false}>
                        <PortfolioTableBalance asset={asset} balance={balance} color='secondary' />
                      </PortfolioTableAsset>
                    </FlexLayout>
                  ),
                },

                valueProfit: {
                  value: profitLossValue,
                  element: (
                    <FlexLayout direction='column' alignItems='end'>
                      <PortfolioTableValue baseAsset={baseAsset} value={value} />
                      <PortfolioTableProfitLossValue profitLossValue={profitLossValue} />
                    </FlexLayout>
                  ),
                },
              };
            }),
    [
      balances,
      convertRate,
      baseAsset,
      getAllocationPercentage,
      getAveragePricePaid,
      getProfitLossPercentage,
      getProfitLossValue,
      variant,
    ],
  );

  const sortItems = (
    a: EnhancedTableData<PortfolioTableData>,
    b: EnhancedTableData<PortfolioTableData>,
    sortingBy: EnhancedTableSort<PortfolioTableData>,
  ) => {
    switch (sortingBy.sortKey) {
      case 'asset':
      case 'assetCode':
        const aVal = a[sortingBy.sortKey].value as string;
        const bVal = b[sortingBy.sortKey].value as string;
        if (sortingBy.sortDirection === 'ASC') return bVal.localeCompare(aVal);
        return aVal.localeCompare(bVal);
      case 'balance':
      case 'avgBuyPrice':
      case 'profitLossValue':
      case 'profitLossPercentage':
      case 'balanceValue':
      case 'allocation':
      case 'assetBalance':
      case 'valueBalance':
      case 'performance':
      case 'valueProfit':
        const aValBig = Big(a[sortingBy.sortKey].value as string);
        const bValBig = Big(b[sortingBy.sortKey].value as string);
        if (sortingBy.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
        return bValBig.lt(aValBig) ? -1 : 1;
      default:
        return 1;
    }
  };

  const filteredData = useMemo(() => {
    if (!search && showSmallBalances) return data.sort((a, b) => sortItems(a, b, sort));

    return data
      .filter((d) => {
        const loweredSearch = search.toLowerCase();
        const nameMatch = !search || d.value?.name?.toLowerCase().includes(loweredSearch);
        const codeMatch = !search || d.value?.code?.toLowerCase().includes(loweredSearch);
        const balanceMatch =
          baseAsset &&
          d.value &&
          (showSmallBalances ||
            (!showSmallBalances && convertRate(d.value, FiatIdEnum.AUD, d.balance.value as string).gte(1)));

        return (nameMatch || codeMatch) && balanceMatch;
      })
      .sort((a, b) => sortItems(a, b, sort));
  }, [search, showSmallBalances, data, baseAsset, convertRate, sort]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<PortfolioTableData>): EnhancedTableData<PortfolioTableData>[] => {
      const tableData: EnhancedTableData<PortfolioTableData>[] = Object.assign([], filteredData);
      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => sortItems(a, b, newSort));
    },
    [filteredData, setSort],
  );

  return {
    initialSort: sort,
    headers,
    data: filteredData,
    onSort,
    search,
    setSearch,
  };
};

export { usePortfolioTable };

import { useMemo } from 'react';

import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

const useProfileStatus = () => {
  const { isUserVerified, userStatistics, userProfile, isRestricted: isUserRestricted } = UserStore.useUserStore;

  const isVerified = useMemo(() => {
    if (!userProfile) return true;

    return isUserVerified();
  }, [isUserVerified, userProfile]);

  const isRestricted = useMemo(() => {
    if (!userProfile) return true;
    return isUserRestricted();
  }, [isUserRestricted, userProfile]);

  const hasDeposited = useMemo(() => {
    if (!userStatistics) return true;

    return Big(userStatistics?.deposited).gt(0) || Big(userStatistics?.depositedFiat).gt(0);
  }, [userStatistics]);

  const hasTraded = useMemo(() => {
    if (!userStatistics) return true;

    return Big(userStatistics?.traded).gt(0);
  }, [userStatistics]);

  return {
    isVerified,
    isRestricted,
    hasDeposited,
    hasTraded,
  };
};

export { useProfileStatus };

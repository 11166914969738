import { styled } from '@mui/material/styles';

import { ComponentProps, ComponentStateProps, EventProps } from '../../global-utils';

type Props = ComponentProps & EventProps & ComponentStateProps;

export const StyledDot = styled('div', { shouldForwardProp: (propName: string) => propName !== 'active' })<Props>`
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.2rem;
  background-color: ${({ theme }) => theme.palette.common.white};
  opacity: ${({ active }) => (active ? '1' : '.5')};
  transition: opacity 0.4s;
  cursor: pointer;
`;

import React from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';

import { SourceOfWealthStepContainer } from './SourceOfWealthStepContainer';

const HowItWorksStep: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();

  return (
    <SourceOfWealthStepContainer
      customTitle='Source of wealth verification'
      onSubmit={(e) => {
        e.preventDefault();
        sourceOfWealthService.send({ type: 'NEXT' });
      }}
    >
      <FlexLayout direction='column' spacing={32}>
        <Body color='secondary'>
          Source of wealth checks help us verify that the funds you use to deposit and trade with are of legitimate
          sources. We are required to do these checks on some accounts, and they may occur more than once.
        </Body>
        <FlexLayout direction='column' className='w-full' spacing={8}>
          <Heading size='h5'>Frequently asked questions</Heading>

          <Accordion type='multiple'>
            <AccordionItem value='item-1'>
              <AccordionTrigger>
                <Body size='small' weight='emphasis'>
                  How long do I have to complete this form?
                </Body>
              </AccordionTrigger>
              <AccordionContent>
                <Body color='secondary' size='small'>
                  You will have 10 business days to complete this form from the date you received the notification. If
                  you do not complete this form in 10 days, all withdrawals will be restricted on your account until
                  such time as you complete the verification.
                </Body>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='item-2'>
              <AccordionTrigger>
                <Body size='small' weight='emphasis'>
                  How long does it take to verify my funds?
                </Body>
              </AccordionTrigger>
              <AccordionContent>
                <Body color='secondary' size='small'>
                  Initial funds verification checks typically take 1-3 business days. However, it can take longer if we
                  require further information from you.
                </Body>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='item-3'>
              <AccordionTrigger>
                <Body size='small' weight='emphasis'>
                  Who can access this information?
                </Body>
              </AccordionTrigger>
              <AccordionContent>
                <Body color='secondary' size='small'>
                  Our fraud, AML and compliance teams will be reviewing this information to help verify funds.
                </Body>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='item-4'>
              <AccordionTrigger>
                <Body size='small' weight='emphasis'>
                  Why does Swyftx need to do this?
                </Body>
              </AccordionTrigger>
              <AccordionContent>
                <Body color='secondary' size='small'>
                  AUSTRAC, Australia&apos;s AML/CTF regulator, requires us to collect this information to ensure users
                  funds are legitimate and in compliance with Australian AML/CTF laws.
                </Body>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value='item-5'>
              <AccordionTrigger>
                <Body size='small' weight='emphasis'>
                  What happens if I fail this check?
                </Body>
              </AccordionTrigger>
              <AccordionContent>
                <Body color='secondary' size='small'>
                  If you complete the form and fail the check, all funds will be returned to your originating bank
                  account and you will no longer be able to use our services.
                </Body>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </FlexLayout>
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

export { HowItWorksStep };

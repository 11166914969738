import { DateTime } from 'luxon';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

export type TradingViewSide = 'bid' | 'ask';
export type TradingViewChartType = 'advanced' | 'simple';
export type TradingViewHeaderVariant = 'full' | 'compact';

export type TradingViewMarkerTransactionStatus = 'buy' | 'sell' | 'open' | undefined;

export type SimpleResolutionItem = {
  text: string;
  value: string;
  date: DateTime;
  hidden?: boolean;
};

export type TradingViewWidgetState = {
  side: TradingViewSide;
  showMarks: boolean;
  tvWidget?: IChartingLibraryWidget;
  onResetCacheNeededCallback: () => void;
};

export enum ChartStyle {
  Bar = 0,
  Candle = 1,
  Line = 2,
  Area = 3,
  LineBreak = 7,
  HeikinAshi = 8,
  HollowCandle = 9,
  Baseline = 10,
  HiLo = 12,
  Column = 13,
  LineWithMarkers = 14,
  Stepline = 15,
  HLCArea = 16,
}

export const chartStyleName: { [key in ChartStyle]: string } = {
  [ChartStyle.Bar]: 'Bars',
  [ChartStyle.Candle]: 'Candles',
  [ChartStyle.Line]: 'Line',
  [ChartStyle.Area]: 'Area',
  [ChartStyle.LineBreak]: 'Line break',
  [ChartStyle.HeikinAshi]: 'Heikin Ashi',
  [ChartStyle.HollowCandle]: 'Hollow candles',
  [ChartStyle.Baseline]: 'Baseline',
  [ChartStyle.HiLo]: 'High-low',
  [ChartStyle.Column]: 'Column',
  [ChartStyle.LineWithMarkers]: 'Line with markers',
  [ChartStyle.Stepline]: 'Step line',
  [ChartStyle.HLCArea]: 'HLC area',
};

export const chartIndicators: string[] = [
  '52 Week High/Low',
  'Accelerator Oscillator',
  'Accumulation/Distribution',
  'Accumulative Swing Index',
  'Advance/Decline',
  'Arnaud Legoux Moving Average',
  'Aroon',
  'Average Directional Index',
  'Average Price',
  'Average True Range',
  'Awesome Oscillator',
  'Balance of Power',
  'Bollinger Bands',
  'Bollinger Bands Width',
  'Chaikin Money Flow',
  'Chaikin Oscillator',
  'Chaikin Volatility',
  'Chande Kroll Stop',
  'Chande Momentum Oscillator',
  'Chop Zone',
  'Choppiness Index',
  'Commodity Channel Index',
  'Connors RSI',
  'Coppock Curve',
  'Correlation Coefficient',
  'Correlation - Log',
  'Detrended Price Oscillator',
  'Directional Movement',
  'Donchian Channels',
  'Double EMA',
  'Ease of Movement',
  "Elder's Force Index",
  'EMA Cross',
  'Envelopes',
  'Fisher Transform',
  'Guppy Multiple Moving Average',
  'Historical Volatility',
  'Hull Moving Average',
  'Ichimoku Cloud',
  'Keltner Channels',
  'Klinger Oscillator',
  'Know Sure Thing',
  'Least Squares Moving Average',
  'Linear Regression Curve',
  'Linear Regression Slope',
  'MA Cross',
  'MA with EMA Cross',
  'Mass Index',
  'McGinley Dynamic',
  'Median Price',
  'Momentum',
  'Money Flow Index',
  'Moving Average',
  'Moving Average Channel',
  'MACD',
  'Moving Average Exponential',
  'Moving Average Weighted',
  'Moving Average Double',
  'Moving Average Triple',
  'Moving Average Adaptive',
  'Moving Average Hamming',
  'Moving Average Multiple',
  'Majority Rule',
  'Net Volume',
  'On Balance Volume',
  'Parabolic SAR',
  'Pivot Points Standard',
  'Price Channel',
  'Price Oscillator',
  'Price Volume Trend',
  'Rate Of Change',
  'Ratio',
  'Relative Strength Index',
  'Relative Vigor Index',
  'Relative Volatility Index',
  'Standard Error',
  'Standard Error Bands',
  'SMI Ergodic Indicator/Oscillator',
  'Smoothed Moving Average',
  'Standard Deviation',
  'Stochastic',
  'Stochastic RSI',
  'SuperTrend',
  'Spread',
  'TRIX',
  'Triple EMA',
  'True Strength Index',
  'Trend Strength Index',
  'Typical Price',
  'Ultimate Oscillator',
  'Volatility Close-to-Close',
  'Volatility Zero Trend Close-to-Close',
  'Volatility O-H-L-C',
  'Volatility Index',
  'VWAP',
  'VWMA',
  'Volume Oscillator',
  'Volume Profile Fixed Range',
  'Volume Profile Visible Range',
  'Vortex Indicator',
  'Volume',
  'Williams %R',
  'Williams Alligator',
  'Williams Fractal',
  'ZigZag',
];

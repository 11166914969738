import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { OrderBookType, OrderBookValueType } from '../../types/TradePro.types';

type Props = {
  price: string;
  total: string;
  bookTotal: string;
  selectedAsset: Asset;
  midPrice?: string;
  baseAsset: Asset;
  amount: string;
  side: OrderBookType;
  className?: string;
  backgroundColor: string;
  textColor: 'accent' | 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'white';
  onClick?: (value: string, valueType: OrderBookValueType) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const SwyftxProOrderBookBookItem: React.FC<Props> = ({
  price,
  total,
  bookTotal,
  baseAsset,
  selectedAsset,
  midPrice,
  amount,
  className = '',
  backgroundColor,
  textColor,
  side,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const totalValuePercentage = useMemo(() => Big(total).div(bookTotal).times(100).toString(), [bookTotal, total]);
  const changePercentage = useMemo(() => {
    if (!midPrice) return '-';

    const priceDiff = Big(price).minus(midPrice);
    const prefix = priceDiff.gte(0) ? '+' : '-';
    const percent = Big(priceDiff).div(price).abs().times(100).toFixed(2);

    return `${prefix}${percent}%`;
  }, [midPrice, price]);

  const isXs = useTailwindBreakpoint('xs');

  return (
    <Tooltip
      title={formatCurrency(amount, selectedAsset, { appendCode: true, hideCode: false }, undefined, true)}
      align='start'
      side={isXs ? 'top' : 'left'}
    >
      <div
        className={cn(className, 'h-full w-full', side === 'bid' ? 'pb-2' : 'pt-2')}
        onClick={() =>
          onClick && onClick(formatCurrency(price, baseAsset, { hideSymbol: true, hideSeparator: true }), 'price')
        }
        onMouseOver={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <FlexLayout className={cn('group relative w-full p-4')} alignItems='center' justifyContent='space-between'>
          <Numeric
            color={textColor}
            weight='emphasis'
            size='small'
            className={cn(
              onClick ? 'decoration-dashed underline-offset-4 group-hover:underline' : '',
              'w-5/12 text-left',
            )}
          >
            {formatCurrency(price, baseAsset)}
          </Numeric>
          <Numeric color={textColor} weight='emphasis' size='small' className={cn('w-2/12 text-right ')}>
            {changePercentage}
          </Numeric>
          <Numeric
            color='primary'
            weight='emphasis'
            size='small'
            className={cn(
              onClick ? ' cursor-pointer group-hover:bg-color-background-neutral' : '',
              'w-5/12 text-right ',
            )}
          >
            {formatCurrency(total, baseAsset, {
              appendCode: baseAsset.assetType === AssetType.Crypto,
              hideCode: false,
            })}
          </Numeric>
          <div
            className={cn(backgroundColor, 'pointer-events-none absolute mx-[-.25rem] h-full rounded-4 p-4 opacity-10')}
            style={{ width: `${totalValuePercentage}%` }}
          />
        </FlexLayout>
      </div>
    </Tooltip>
  );
};

export { SwyftxProOrderBookBookItem };

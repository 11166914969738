import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderMetrics } from '@swyftx/aviary/layout/Page/PageHeader/PageHeader.types';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { formatValueToCurrencyShorthand } from '@utils/currency';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { useCategoryRank } from './useCategoryRank';

const useCategoryPerformance = (category?: Category) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const { rates } = RatesStore.useRatesStore;
  const { rank } = useCategoryRank(category);
  const baseAsset = useBaseAsset();
  const { getAssetsByCategoryId } = useMarkets();

  const getAssetMetrics = useCallback(
    (assets: Asset[], base: Asset) => {
      let mCap = Big(0);
      let vol = Big(0);
      let changeDay = Big(0);
      let changeWeek = Big(0);
      let changeMonth = Big(0);

      assets.forEach((asset) => {
        const rate = rates?.[asset.id];
        vol = vol.plus(asset.volume[base.id].day);
        mCap = mCap.plus(Big(rate?.midPrice).times(asset.circulatingSupply));
        changeDay = changeDay.plus(Big(rate?.dailyPriceChange));
        changeWeek = changeWeek.plus(asset.priceChange.week);
        changeMonth = changeMonth.plus(asset.priceChange.month);
      });

      return {
        vol,
        mCap,
        changeDay,
        changeWeek,
        changeMonth,
      };
    },
    [rates],
  );

  const getMetrics = useCallback(
    (categoryToCheck: Category) => {
      if (!categoryToCheck || !baseAsset) return {};

      const assets = assetService.getAssets(categoryToCheck.assetIds);

      const { vol, mCap, changeDay, changeMonth, changeWeek } = getAssetMetrics(assets, baseAsset);

      return {
        dailyVolume: formatValueToCurrencyShorthand(vol.toString(), baseAsset.assetType, baseAsset.code),
        marketCap: formatValueToCurrencyShorthand(mCap.toString(), baseAsset.assetType, baseAsset.code),
        dailyChange: `${changeDay.div(assets.length).toFixed(2)}`,
        weeklyChange: `${changeWeek.div(assets.length).toFixed(2)}`,
        monthlyChange: `${changeMonth.div(assets.length).toFixed(2)}`,
      };
    },
    [baseAsset, getAssetMetrics],
  );

  const performanceItems: HeaderMetrics[] = useMemo(() => {
    if (!category || !baseAsset) return [];

    const assets = getAssetsByCategoryId(category.id);

    const { vol, mCap, changeDay, changeMonth, changeWeek } = getAssetMetrics(assets, baseAsset);

    const metrics: HeaderMetrics[] = [
      {
        key: 'rank',
        title: t('labels.rank'),
        data: `#${rank}` || t('labels.na'),
        priority: 4,
        percentage: false,
        color: 'primary',
      },
      {
        key: 'volume',
        title: t('labels.volume'),
        data: formatValueToCurrencyShorthand(vol.toString(), baseAsset.assetType, baseAsset.code),
        percentage: false,
        color: 'primary',
        priority: 5,
      },
      {
        key: 'market-cap',
        title: t('labels.marketCap'),
        data: formatValueToCurrencyShorthand(mCap.toString(), baseAsset.assetType, baseAsset.code),
        percentage: false,
        color: 'primary',
        priority: 6,
      },
      {
        key: 'daily-change',
        title: t('labels.dailyChange'),
        data: `${changeDay.div(assets.length).toFixed(2)}%`,
        percentage: true,
        priority: 1,
        tooltip: t('tooltips.dailyChange'),
      },
      {
        key: 'weekly-change',
        title: t('labels.weeklyChange'),
        data: `${changeWeek.div(assets.length).toFixed(2)}%`,
        percentage: true,
        priority: 2,
        tooltip: t('tooltips.weeklyChange'),
      },
      {
        key: 'month-change',
        title: t('labels.monthlyChange'),
        data: `${changeMonth.div(assets.length).toFixed(2)}%`,
        percentage: true,
        priority: 3,
        tooltip: t('tooltips.monthlyChange'),
      },
    ];

    return metrics;
  }, [category, baseAsset, getAssetsByCategoryId, getAssetMetrics, t, rank]);

  return {
    performanceItems,
    getMetrics,
  };
};

export { useCategoryPerformance };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const FinanceBanking: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.73438 10.1353C8.73438 8.86951 9.76162 7.84277 11.0278 7.84277H11.2812V7.46976C11.2812 7.07211 11.6036 6.74976 12.0013 6.74976C12.3989 6.74976 12.7212 7.07211 12.7212 7.46976V7.84277H13.2431C14.3601 7.84277 15.2645 8.74939 15.2645 9.86509C15.2645 10.2627 14.9421 10.5851 14.5445 10.5851C14.1468 10.5851 13.8245 10.2627 13.8245 9.86509C13.8245 9.54337 13.5635 9.28277 13.2431 9.28277H12.7212V10.9878H13.0338C14.2665 10.9878 15.2654 11.9866 15.2654 13.2193C15.2654 14.4521 14.2666 15.4509 13.0338 15.4509H12.7212V15.6917C12.7212 16.0894 12.3989 16.4117 12.0013 16.4117C11.6036 16.4117 11.2812 16.0894 11.2812 15.6917V15.4509H10.7577C9.64 15.4509 8.73532 14.5447 8.73532 13.4286C8.73532 13.031 9.05767 12.7086 9.45532 12.7086C9.85296 12.7086 10.1753 13.031 10.1753 13.4286C10.1753 13.75 10.436 14.0109 10.7577 14.0109H11.2812V12.4278H11.0278C9.76122 12.4278 8.73575 11.401 8.73438 10.1353ZM11.2812 10.9878V9.28277H11.0278C10.5565 9.28277 10.1746 9.6651 10.1744 10.1349C10.1751 10.6067 10.5569 10.9878 11.0278 10.9878H11.2812ZM12.7212 12.4278V14.0109H13.0338C13.4713 14.0109 13.8254 13.6568 13.8254 13.2193C13.8254 12.782 13.4713 12.4278 13.0338 12.4278H12.7212Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.071 3.80147C5.01605 2.78741 6.36201 2.21973 7.95155 2.21973H16.0488C17.6416 2.21973 18.9881 2.78713 19.9328 3.8017C20.8721 4.81048 21.3606 6.20295 21.3606 7.76521V15.3951C21.3606 16.9574 20.8721 18.3497 19.9326 19.3583C18.9877 20.3727 17.641 20.9397 16.0478 20.9397H7.95155C6.35836 20.9397 5.01201 20.3726 4.06757 19.3582C3.12856 18.3495 2.64062 16.9572 2.64062 15.3951V7.76521C2.64062 6.20225 3.13121 4.8099 4.071 3.80147ZM5.12445 4.78323C4.47436 5.4808 4.08062 6.50119 4.08062 7.76521V15.3951C4.08062 16.6601 4.47278 17.6801 5.12154 18.377C5.76488 19.068 6.71399 19.4997 7.95155 19.4997H16.0478C17.2854 19.4997 18.2351 19.068 18.8789 18.3768C19.5281 17.6799 19.9206 16.6599 19.9206 15.3951V7.76521C19.9206 6.50049 19.5281 5.48021 18.8789 4.783C18.2351 4.09157 17.2857 3.65973 16.0488 3.65973H7.95155C6.71879 3.65973 5.76929 4.09129 5.12445 4.78323Z'
      fill='currentColor'
    />
  </svg>
);

export { FinanceBanking };

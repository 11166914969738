import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

import { GlobalSearchOpenMethodValueType } from 'src/context/Avo/generated-avo';

const useGlobalSearchAnalytics = () => {
  const avo = useAvo();
  const { pathname: screen } = useLocation();

  const trackGlobalSearchBarInputFieldCompleted = useCallback(
    (inputFieldContent: string) =>
      avo.globalSearchBarInputFieldCompleted({
        inputFieldContent,
        screen,
      }),
    [avo, screen],
  );

  const trackGlobalSearchBarOpened = useCallback(
    (globalSearchOpenMethod: GlobalSearchOpenMethodValueType[]) =>
      avo.globalSearchBarOpened({
        globalSearchOpenMethod,
        screen,
      }),
    [avo, screen],
  );

  const trackGlobalSearchBarResultClicked = useCallback(
    (searchBarResultCategory: string, searchBarResultName: string) =>
      avo.globalSearchBarResultClicked({ searchBarResultCategory, searchBarResultName, screen }),
    [avo, screen],
  );

  return {
    trackGlobalSearchBarInputFieldCompleted,
    trackGlobalSearchBarOpened,
    trackGlobalSearchBarResultClicked,
  };
};

export { useGlobalSearchAnalytics };

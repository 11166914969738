import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { useEntitySecurity } from './useEntitySecurity.hook';

export const ProfileEntitySecurity: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;
  const { t } = useTranslation('entity.security');

  const entitySecurity = useEntitySecurity(userProfile);

  return (
    <SettingsCluster
      key={entitySecurity.title}
      title='Configure your level of security'
      items={(entitySecurity.items ?? [])
        .filter((item) => !item.hidden)
        .map((item) => (
          <SettingsItem
            key={`entity-security-item-${item.id}`}
            title={t(item.title as any)}
            icon={item.leading}
            description={t(item.subTitle as any)}
            action={item.trailing}
          />
        ))}
    />
  );
});

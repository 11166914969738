import React, { useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Loading, Refresh } from '@swyftx/aviary/icons/outlined';
import { formatValueToCurrencyShorthand } from '@swyftx/aviary/utils';
import { CurrencyType } from '@swyftx/aviary/utils/currency/currency.types';

import { Asset } from '@shared/api/@types/markets';
import { NA } from '@shared/constants';
import { Big } from '@shared/safe-big';
import { AssetPriceHistoryData } from '@shared/services/chartService';
import { RatesStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';

import { AssetSummaryItem } from './AssetSummaryItem/AssetSummaryItem';
import { AssetSummaryItemsMarquee } from './AssetSummaryItemsMarquee/AssetSummaryItemsMarquee';
import { AssetSummaryItemsSlider } from './AssetSummaryItemsSlider';
import { assetInfo } from '../AssetSummary.const';

type Props = {
  data?: AssetPriceHistoryData;
  backgroundColor?: string;
  currency?: Asset;
  asset?: Asset;
  onUpdate?: () => void;
  fetching: boolean;
};

export const AssetSummaryItems: React.FC<Props> = observer(
  ({ currency, data, backgroundColor, asset, fetching, onUpdate }) => {
    const [lastElementRef, setLastElementRef] = useState<HTMLDivElement | null>(null); // need ref assignment to cause a rerender
    const { isMobile } = useContentBreakpoint();
    const Container = isMobile ? AssetSummaryItemsMarquee : AssetSummaryItemsSlider;
    const { getRate } = RatesStore.useRatesStore;
    const rate = asset ? getRate(asset.id) : undefined;

    const items = useMemo(
      () =>
        assetInfo.map((info, index) => {
          const datum = data?.[info.timeKey][info.detailKey];
          const isVolume = info.detailKey === 'volume';

          let change = 0;
          const isSettingRef = index === assetInfo.length - 1;
          let datumParsed = datum || null;

          switch (info.detailKey) {
            case 'volume':
              if (asset && currency) {
                const hasVolumeData = asset.volume[currency.id].day !== NA;
                datumParsed = hasVolumeData
                  ? formatValueToCurrencyShorthand(
                      asset.volume[currency.id].day,
                      currency.assetType as unknown as CurrencyType,
                      currency.code,
                    )
                  : NA;
              }
              break;
            case 'change':
              if (info.timeKey === 'day') change = Big(rate?.dailyPriceChange).toNumber();
              if (info.timeKey === 'week') change = asset?.priceChange.week || 0;
              if (info.timeKey === 'month') change = asset?.priceChange.month || 0;

              datumParsed = Big(rate?.midPrice || '1')
                .minus(
                  Big(100)
                    .div(Big(100).plus(change))
                    .times(rate?.midPrice || '1'),
                )
                .toString();

              break;
            default:
              break;
          }

          const changePrefix = change && change > 0 ? '+' : '';

          return (
            <AssetSummaryItem
              ref={(newRef: HTMLDivElement) => isSettingRef && setLastElementRef(newRef)}
              change={info.detailKey === 'change' && change !== 0 ? `${changePrefix}${change.toFixed(2)}%` : ''}
              key={`${info.name}`}
              title={info.name}
              translationKey='assets'
              price={datumParsed}
              format={!isVolume}
              asset={currency}
            />
          );
        }),
      [data, asset, currency, rate],
    );

    return (
      <>
        <FlexLayout justifyContent='end' alignItems='center' direction='row' spacing={8} className='w-full px-16'>
          {fetching && <Loading className='animate-spin' />}

          <Tooltip title='Refresh'>
            <span>
              <Refresh className='h-12 w-12 cursor-pointer text-color-text-primary' onClick={onUpdate} />
            </span>
          </Tooltip>
        </FlexLayout>
        <Container backgroundColor={backgroundColor} lastElementRef={lastElementRef}>
          {items}
        </Container>
      </>
    );
  },
);

import React from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { VerifiedFilled } from '@swyftx/aviary/icons/filled';
import { CrossInCircle, Vip } from '@swyftx/aviary/icons/outlined';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

interface ProfileInformationTabProps {
  avatarInitials: string;
  name?: string;
  email?: string;
  number: string;
  entityMembersLength: number;
  isUserVerified: boolean;
  navigateToVerificationPage: () => void;
  navigateToFeesPage: () => void;
}

export const ProfileInformationCard: React.FC<ProfileInformationTabProps> = observer((props) => {
  const {
    avatarInitials,
    name,
    email,
    number,
    entityMembersLength,
    isUserVerified,
    navigateToFeesPage,
    navigateToVerificationPage,
  } = props;
  const { userProfile } = UserStore.useUserStore;
  const feeTierLabel = userProfile?.currentFeeTier ?? 'Regular';

  return (
    <FlexLayout direction='row' className='mx-16' spacing={24}>
      <FlexLayout direction='column'>
        <Avatar id='profileInfoAvatar' text={avatarInitials} size='lg' />
      </FlexLayout>
      <FlexLayout direction='column' spacing={8}>
        <Heading size='h3'>{name}</Heading>

        {!!entityMembersLength && (
          <Body color='secondary' size='small'>
            Entity members: {entityMembersLength}
          </Body>
        )}

        {!entityMembersLength && (
          <FlexLayout direction='column' spacing={8}>
            <Body color='secondary' size='small'>
              {email}
            </Body>
            <Body color='secondary' size='small'>
              {number}
            </Body>
          </FlexLayout>
        )}

        <FlexLayout direction='row' spacing={8}>
          <Chip
            size='sm'
            color={isUserVerified ? 'success' : 'destructive'}
            leadingIcon={
              isUserVerified ? <VerifiedFilled className='h-16 w-16 text-color-text-success' /> : <CrossInCircle />
            }
            variant='subtle'
            className='cursor-pointer'
            onClick={navigateToVerificationPage}
          >
            {isUserVerified ? 'Verified' : 'Unverified'}
          </Chip>
          {feeTierLabel && (
            <Chip
              size='sm'
              leadingIcon={<Vip />}
              variant='subtle'
              onClick={navigateToFeesPage}
              className='cursor-pointer'
            >
              {feeTierLabel}
            </Chip>
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

ProfileInformationCard.displayName = 'ProfileTabInformationCard';

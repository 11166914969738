/* eslint-disable max-len */
import { Release, ReleaseVersion } from '../whats-new.type';

const releaseOne: Release = {
  imgSrc: '/assets/images/releases/0/overview.png',
  version: ReleaseVersion.One,
  overview: [
    'New trade dashboard',
    'Trade any asset on any page',
    'Quickly deposit and withdraw anywhere on the site',
    'New sitewide filters to see all your open orders',
    'A new wallet overview page to view your portfolio',
    'New charts to view a single asset performance over time',
  ],
  features: [
    {
      imgSrc: '/assets/images/releases/0/feature-1.png',
      preTitle: 'New!',
      title: 'Trade Dashboard',
      descriptions: [
        'Easily access and view trending assets & chart data',
        'Stay up to date with gainers & losers in real time',
        'View your portfolio balance',
        'Access saved favourites',
        'See your open orders & order history',
      ],
    },
    {
      imgSrc: '/assets/images/releases/0/feature-2.png',
      preTitle: 'New!',
      title: 'Trade interface',
      descriptions: [
        'Simple ‘from’ and ‘to’ inputs allow you to buy, sell or swap in any direction',
        'Trade assets on a one-to-one, many-to-one or one-to-many basis',
        'Trade any asset on any page',
      ],
    },
    {
      imgSrc: '/assets/images/releases/0/feature-3.png',
      preTitle: 'New!',
      title: 'Deposit & withdraw anywhere on the platform',
      descriptions: [
        'From the sidebar on any page, click Deposit or Withdraw',
        'Conveniently view your addresses & deposit or withdrawal history',
      ],
    },
    {
      imgSrc: '/assets/images/releases/0/feature-4.png',
      preTitle: 'New to web!',
      title: 'Wallet Overview page',
      descriptions: [
        'Brand new wallet overview page to track portfolio & asset performance',
        'View combined wallet balances',
        'View trade wallet and Earn wallet balances',
        'View individual asset performance, balances, transaction history & current market data.',
      ],
    },
    {
      imgSrc: '/assets/images/releases/0/feature-5.png',
      title: 'We’d love to get your feedback',
      descriptions: [
        'You can easily provide feedback directly on the web platform. Simply click the question mark (?) icon at the top right of the screen and select ‘Provide Feedback’ from the options.  ',
      ],
    },
  ],
};

export { releaseOne };

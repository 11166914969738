import { useMemo } from 'react';

import { HistoricalOrder } from '@shared/api';
import { Big } from '@shared/safe-big';

const useProfitStopLossOrderDetails = (order?: HistoricalOrder) => {
  const feeAmount = useMemo(() => {
    if (!order || !order.feeAmount || !order.amount) return '';

    // if the fee is the primary we need to do some calculations to work out the fee
    // amount in the secondary asset
    if (order.feeAsset === order.primary_asset && order.trigger) {
      return Big(order.feeAmount).div(order.trigger).toString();
    }
    return order.feeAmount.toString();
  }, [order]);

  return {
    amount: order?.amount ? order?.amount.toString() : '',
    purchasePrice: order?.trigger ? order?.trigger.toString() : '',
    feeAmount,
  };
};
export { useProfitStopLossOrderDetails };

import { useMemo } from 'react';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { useFetchDynamicPriceAlertAssetCounts } from './useFetchDynamicPriceAlertAssetCounts/useFetchDynamicPriceAlertAssetCounts';
import { useFetchDynamicPriceAlertTriggerAssetCounts } from './useFetchDynamicPriceAlertAssetCounts/useFetchDynamicPriceAlertTriggerAssetCounts';

type Props = {
  primary?: number;
  secondary?: number;
};

const useDynamicPriceAlertAssetCounts = ({ secondary, primary }: Props = {}) => {
  const { data } = useFetchDynamicPriceAlertAssetCounts({ primary, secondary, status: ['WAITING', 'PAUSED'] });
  const { data: triggerData } = useFetchDynamicPriceAlertTriggerAssetCounts({ primary, secondary });

  const { getAssetsById } = useMarkets();

  const alertActiveAssetCodes = useMemo(() => {
    const alertAssetIds: number[] = [];

    data?.forEach((d) => {
      if (!alertAssetIds.includes(d.secondary)) alertAssetIds.push(d.secondary);
    });

    return getAssetsById(alertAssetIds).map((a) => a.code);
  }, [data, getAssetsById]);

  const totalActiveAlerts = useMemo(() => {
    let total = 0;
    data?.forEach((d) => {
      total += d.count;
    });

    return total;
  }, [data]);

  const alertTriggerAssetCodes = useMemo(() => {
    const alertAssetIds: number[] = [];

    triggerData?.forEach((d) => {
      if (!alertAssetIds.includes(d.secondary)) alertAssetIds.push(d.secondary);
    });

    return getAssetsById(alertAssetIds).map((a) => a.code);
  }, [triggerData, getAssetsById]);

  const totalTriggerAlerts = useMemo(() => {
    let total = 0;
    triggerData?.forEach((d) => {
      total += d.count;
    });

    return total;
  }, [triggerData]);

  return { totalActiveAlerts, alertTriggerAssetCodes, alertActiveAssetCodes, totalTriggerAlerts };
};

export { useDynamicPriceAlertAssetCounts };

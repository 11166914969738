import React, { useRef, useMemo, useCallback } from 'react';

import InputBase from '@mui/material/InputBase';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatNumberWithCommasAndAllowTrailingDot } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

type Props = {
  value?: string;
  error?: boolean;
  showDollarSign: boolean;
  suffix?: string;
  disabled?: boolean;
  asset?: Asset;
  onChange: (value: string) => void;
};

const TradeInputBase: React.FC<Props> = ({
  value,
  error = false,
  showDollarSign,
  disabled = false,
  suffix,
  asset,
  onChange,
}) => {
  const inputRef = useRef<any>();

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // Prevent users from entering '00000' etc
      const input = e.target as HTMLInputElement;
      const parsedValue = e.target.value.replace(/,/g, '');

      // get a snapshot of the current cursor position
      const cursor = input.selectionStart || 0;
      // determine the offset based on if we have added or removed a character
      const offset = parsedValue.length > (value || '').length ? 1 : 0;

      onChange(parsedValue);

      // wait 50ms before forcing the location of the cursor
      window.setTimeout(() => input.setSelectionRange(cursor + offset, cursor + offset), 50);
    },
    [onChange, value],
  );

  const formattedValue = useMemo(() => {
    if (!asset) return value;

    // Don't format the number if its less than or equal to 1
    if (Big(value).lte(1)) return value;

    // format numbers with thousand separators
    return formatNumberWithCommasAndAllowTrailingDot(value);
  }, [asset, value]);

  return (
    <Stack
      spacing={0.5}
      direction='row'
      alignItems='center'
      className={cn(
        'border bg-color-background-surface-secondary',
        error ? 'border-color-border-error' : 'border-color-border-main',
      )}
      sx={{
        padding: 1,
        borderRadius: 1,
        width: '100%',
      }}
    >
      {showDollarSign && (
        <Typography
          fontSize={18}
          marginRight={0.2}
          number
          color='text.primary'
          onClick={() => inputRef?.current?.firstChild.select()}
        >
          $
        </Typography>
      )}
      <InputBase
        ref={inputRef}
        value={formattedValue}
        disabled={disabled}
        onChange={onChangeValue}
        className='font-mono'
        sx={{
          width: '100%',
          fontWeight: 500,
          color: error ? 'error.main' : 'text.primary',
          fontSize: 18,
          '.MuiInputBase-input::placeholder': {
            color: 'text.primary',
          },
          'input::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
          },
          'input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
        }}
        placeholder='0'
        inputProps={{
          className: '!font-mono',
          'aria-label': 'trigger price',
          sx: { padding: 0 },
        }}
      />
      {suffix && (
        <Typography
          fontSize={18}
          marginLeft={0.2}
          number
          className='!font-mono'
          color={disabled ? 'text.disabled' : 'text.secondary'}
          onClick={() => inputRef?.current?.firstChild.select()}
        >
          {suffix}
        </Typography>
      )}
    </Stack>
  );
};

export { TradeInputBase };

import { Bank, CreditCard, PayID } from '@swyftx/aviary/icons/outlined';

import { DepositMethodEnum } from '@shared/enums';

import { DepositMethodItem } from '@Wallet/subroutes/SingleWallet/components/SingleWalletFiatDeposit/types';

export const NZDepositMethods: DepositMethodItem[] = [
  {
    title: 'Card',
    method: DepositMethodEnum.BANXA,
    timeframe: 'Approx. 30-45 minutes',
    timeframeStripe: 'Near Instant',
    depositLimit: 'Deposit limits vary per transaction',
    depositSummary: 'Deposit into your account using your chosen card provider. Banxa and bank fees may apply.',
    depositSummaryStripe: 'Deposit into your account using your chosen card provider. Fees may apply.',
    icon: <CreditCard className='h-24 w-24 text-color-text-secondary' />,
  },
  {
    title: 'Bank transfer',
    disabled: true,
    method: DepositMethodEnum.NZD_BANK_TRANSFER,
    timeframe: '1 - 3 Days',
    depositLimit: 'Deposit up to $50,000 per transaction, per day',
    depositSummary: 'Deposit funds directly from your bank account',
    icon: <Bank className='h-24 w-24 text-color-text-secondary' />,
  },
];

export const AUDDepositMethods: DepositMethodItem[] = [
  {
    showIcon: false,
    icon: <Bank className='h-24 w-24 text-color-text-secondary' />,
    title: 'Bank transfer',
    method: DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
    timeframe: 'Instant with Osko',
    depositLimit: 'Deposit up to $50,000 per transaction, per day',
    depositSummary: 'Instantly deposit to your account when using an Osko approved bank.',
  },
  {
    showIcon: false,
    title: 'Card',
    method: DepositMethodEnum.BANXA,
    timeframe: '30-45 MINUTES',
    timeframeStripe: 'Near Instant',
    depositLimit: 'Limited to $15,000 per day, and $60,000 per month',
    depositSummary: 'Deposit into your account using your chosen card provider. Banxa and bank fees may apply.',
    depositSummaryStripe: 'Deposit into your account using your chosen card provider. Fees may apply.',
    icon: <CreditCard className='h-24 w-24 text-color-text-secondary' />,
  },
  {
    showIcon: true,
    title: 'PayID',
    method: DepositMethodEnum.MONOOVA_PAYID,
    timeframe: 'Near Instant',
    depositLimit: 'Deposit up to $50,000 per transaction, per day',
    depositSummary: 'Generate a unique PayID to use for depositing into your account.',
    icon: <PayID className='h-24 w-24 text-color-text-secondary' />,
  },
];

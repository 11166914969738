import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { ContentBanner } from '@global-components/ContentBanner';

interface Props {
  bannerIcon: JSX.Element;
  onAction: () => void;
}

export const SowVerificationExpiredContent: React.FC<Props> = (props) => {
  const { bannerIcon, onAction } = props;

  return (
    <FlexLayout direction='column'>
      <ContentBanner
        onAction={onAction}
        actionLabel='Complete source of wealth'
        description='We’ve put a temporary hold on all withdrawal requests, and will re-enable them once you complete this check.'
        title='Your withdrawals have been disabled.'
        subtitle={
          <Body size='small' weight='emphasis' color='error'>
            You failed to complete the source of wealth check within 10 days.
          </Body>
        }
        icon={bannerIcon}
      />
    </FlexLayout>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyDown, ArrowLineOnlyUp, Tick } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { cn } from '@shared/utils/lib/ui';

import { WalletListSort, WalletSort } from '@routes/Wallet/types';

import { observer } from 'mobx-react-lite';

type Props = {
  sort: WalletListSort;
  setSort: React.Dispatch<React.SetStateAction<WalletListSort>>;
};

const WalletListSortFilter: React.FC<Props> = observer(({ sort, setSort }) => {
  const [open, setOpen] = useState<boolean>(false);

  const filterOptions = useMemo(
    () => [
      {
        label: 'Amount',
        value: WalletSort.Amount,
      },
      {
        label: '% Change',
        value: WalletSort.ChangePercentage,
      },
      {
        label: '$ Change',
        value: WalletSort.ChangeAmount,
      },
      {
        label: 'Name',
        value: WalletSort.Name,
      },
    ],
    [],
  );

  const updateSort = useCallback(
    (sortOption: WalletListSort) => {
      setSort((prev) => ({
        ...prev,
        ...sortOption,
      }));
    },
    [setSort],
  );

  const [options, setOptions] = useState(filterOptions);

  useEffect(() => {
    setOptions(filterOptions);
  }, [filterOptions, options]);

  useEffect(() => {
    const isValidOption = options.filter((filter) => filter.value === sort.key).length > 0;

    if (!isValidOption) updateSort({ key: WalletSort.Amount });
  }, [options, updateSort, sort.key]);

  const getLabel = () => options.filter((filter) => filter.value === sort.key)[0]?.label || '';

  return (
    <Card className='w-full overflow-hidden'>
      <FlexLayout direction='row' alignItems='center' justifyContent='center' className='h-full w-full'>
        <FlexLayout
          alignItems='center'
          justifyContent='center'
          className={cn(
            'h-[36px]',
            open ? 'bg-color-background-surface-selected' : 'hover:bg-color-background-surface-hover',
          )}
        >
          <Menu
            open={open}
            onOpenChange={setOpen}
            triggerContent={
              <FlexLayout
                alignItems='center'
                justifyContent='center'
                className='h-full w-full cursor-pointer whitespace-nowrap px-16'
              >
                <Body color='primary' className='w-full'>
                  Sort: {getLabel()}
                </Body>
              </FlexLayout>
            }
          >
            {options.map((type) => (
              <MenuItem
                key={type.value}
                action={() => {
                  updateSort({
                    key: type.value as WalletSort,
                  });
                  setOpen(false);
                }}
                text={type.label}
                endAdornment={type.value === sort.key && <Tick className='h-14 w-14 text-color-text-accent' />}
              />
            ))}
          </Menu>
        </FlexLayout>
        <FlexLayout
          alignItems='center'
          justifyContent='center'
          className='h-[36px] w-full cursor-pointer border-l border-color-border-main hover:bg-color-background-surface-hover'
          onClick={() => updateSort({ ascending: !sort.ascending })}
        >
          {sort.ascending ? (
            <ArrowLineOnlyUp className='h-[20px] w-[20px] text-color-text-primary' />
          ) : (
            <ArrowLineOnlyDown className='h-[20px] w-[20px] text-color-text-primary' />
          )}
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
});

WalletListSortFilter.displayName = 'WalletListSortFilter';

export { WalletListSortFilter };

/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';

import { Asset } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

type Props = {
  asset?: Asset;
  hideCode?: boolean;
  hideIfDepositDisabled?: boolean;
  onReceive?: () => void;
};

const AssetReceiveButton: React.FC<Props> = observer(
  ({ asset, hideIfDepositDisabled, hideCode = false, onReceive }) => {
    const { t } = useTranslation('common', { keyPrefix: 'assetReceiveButton' });
    const { userProfile, canTransferCrypto } = UserStore.useUserStore;
    const isFiat = asset && assetService.isAssetFiat(asset.id);
    const canTransfer = isFiat || canTransferCrypto();

    const depositDisabled = useMemo(() => !(userProfile?.accountStatus?.deposits || 0), [userProfile]);
    const receiveDisabled = useMemo(
      () => asset?.networks.every((network) => network.depositDisabled) || !asset?.deposit_enabled || !canTransfer,
      [asset, canTransfer],
    );

    if (hideIfDepositDisabled && depositDisabled) return null;

    return (
      <Button variant='outlined' onClick={onReceive} disabled={receiveDisabled} className='w-full'>
        {t('buttonLabels.deposit', { code: hideCode ? '' : ` ${asset?.code}` })}
      </Button>
    );
  },
);

export { AssetReceiveButton };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useTheme } from '@hooks/useTheme';

type Props = {
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  amount: Big;
  purchasePrice: Big;
};

const ProfitStopLossLinkedOrder: React.FC<Props> = ({ primaryAsset, secondaryAsset, amount, purchasePrice }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  const { theme } = useTheme();

  return (
    <Stack>
      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5} padding={1} paddingX={2}>
        <Stack>
          <Typography fontSize={14} fontWeight={500} color='text.primary'>
            {t('labels.linkedOrder')}
          </Typography>
          <Typography fontSize={12} color='text.secondary'>
            {t('labels.instantBuy')}
          </Typography>
        </Stack>
        <Stack>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Stack direction='row' spacing={-0.5} alignItems='center'>
              <AssetIcon asset={primaryAsset?.code} size={16} />
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                bgcolor={theme.palette.background.default}
                borderRadius='100%'
                width={20}
                height={20}
                zIndex={1}
              >
                <AssetIcon asset={secondaryAsset?.code} size={16} />
              </Box>
            </Stack>

            <Typography fontSize={14} number color='text.secondary'>
              {formatCurrency(amount, secondaryAsset)}
            </Typography>
          </Stack>
          <Typography fontSize={14} number color='text.secondary'>
            {formatCurrency(purchasePrice, primaryAsset, { hideCode: true, appendCode: false })} {primaryAsset?.code}/
            {secondaryAsset?.code}
          </Typography>
        </Stack>
      </Stack>
      <Divider variant='fullWidth' orientation='horizontal' />
    </Stack>
  );
};

export { ProfitStopLossLinkedOrder };

import { AssetBreakdown } from '@shared/events';
import { assetService } from '@shared/services';
import { TradeData } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

interface AssetForSide extends AssetBreakdown {
  inverse: number;
}

interface AssetBreakdownWithPortion {
  assetId: number;
  assetCode: string;
  amount: number;
  portion: number;
}

/**
 * Called by getAssetBreakdowns().
 * Parse a TradeData object and accumulator map of assets for the specified side,
 * and return an updated AssetForSide for eventual processing into AssetBreakdown[].
 */
const deriveAssetForSide = (
  side: 'from' | 'to',
  accForSide: Record<number, AssetForSide>,
  curr: TradeData,
): AssetForSide => {
  const amountStr = side === 'from' ? curr.balance : curr.amount;
  const amount = amountStr ? parseFloat(amountStr) : 0;

  const inverseStr = side === 'to' ? curr.balance : curr.amount;
  const inverse = inverseStr ? parseFloat(inverseStr) : 0;

  if (accForSide[curr[side]]) {
    const currentAmount = accForSide[curr[side]].amount || 0;
    const currentInverse = accForSide[curr[side]].inverse || 0;

    return {
      ...accForSide[curr[side]],
      amount: currentAmount || amount ? currentAmount + amount : 0,
      inverse: currentInverse || inverse ? currentInverse + inverse : 0,
    };
  }

  return {
    assetId: curr[side],
    assetCode: assetService.getAssetCodeById(curr[side]) ?? '',
    amount,
    inverse,
    portion: 0,
  };
};

/**
 * Called by getAssetBreakdowns().
 * Only one side can be multi asset, if any. If both sides are multi, something is wrong and we throw an error.
 * If either side is empty, we throw an error (as the trade data is expected to be complete).
 * If both sides are single asset, this method will return `from`. getAssetBreakdowns() expects this behaviour.
 */
const checkMultiSide = (from: AssetForSide[], to: AssetForSide[]): 'from' | 'to' => {
  const lengths = [from.length, to.length];

  if (lengths.includes(0) || lengths.every((x) => x > 1)) {
    throw new Error('Invalid trade data, cannot calculate asset breakdowns');
  }

  if (lengths[1] > 1) {
    return 'to';
  }

  return 'from';
};

/**
 * O(n)
 * Returns the from and to assets, along with the amount of each asset, for a single trade based on its trade data.
 * If there is only one trade, the trigger is also returned.
 *
 * For a multi asset side (i.e. bundles), also returns the fractional portion of each asset as part of the whole.
 * The portion on a single asset side will be 1.
 *
 * This method is currently used to provide analytics data for reviewed / placed orders.
 * This method should only be called with the complete (finalised) TradeData map for an upcoming trade.
 * This method should **not** be called for analytics related to recurring orders - the TradeData map is empty for them.
 */
const getAssetBreakdowns = (
  tradeData: Record<string, TradeData>,
): {
  tradeFromAssets: AssetBreakdownWithPortion[];
  tradeToAssets: AssetBreakdownWithPortion[];
  trigger: number | undefined;
} => {
  const trades = Object.values(tradeData);

  const mappedBreakdowns = trades.reduce<{
    from: Record<number, AssetForSide>;
    to: Record<number, AssetForSide>;
    fromTotal: number;
    toTotal: number;
  }>(
    (acc, curr) => ({
      from: { ...acc.from, [curr.from]: deriveAssetForSide('from', acc.from, curr) },
      to: { ...acc.to, [curr.to]: deriveAssetForSide('to', acc.to, curr) },
      fromTotal: (curr.amount ? parseFloat(curr.amount) : 0) + acc.fromTotal,
      toTotal: (curr.balance ? parseFloat(curr.balance) : 0) + acc.toTotal,
    }),
    { from: {}, to: {}, fromTotal: 0, toTotal: 0 },
  );

  const from: AssetForSide[] = Object.values(mappedBreakdowns.from);
  const to: AssetForSide[] = Object.values(mappedBreakdowns.to);

  const multiSide = checkMultiSide(from, to);

  const tradeFromAssets = from.map(({ inverse, ...assetBreakdown }) => ({
    assetCode: '',
    ...assetBreakdown,
    portion: multiSide === 'from' ? (inverse || 0) / (mappedBreakdowns.fromTotal || 1) : 1,
  }));

  const tradeToAssets = to.map(({ inverse, ...assetBreakdown }) => ({
    assetCode: '',
    ...assetBreakdown,
    portion: multiSide === 'to' ? (inverse || 0) / (mappedBreakdowns.toTotal || 1) : 1,
  }));

  const trigger = trades.length === 1 && trades[0].trigger !== undefined ? parseFloat(trades[0].trigger) : undefined;

  return {
    tradeFromAssets,
    tradeToAssets,
    trigger,
  };
};

export { getAssetBreakdowns };

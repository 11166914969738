import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  text: string;
};

const HeaderExitButton: React.FC<Props> = ({ text }) => {
  const { isXs } = useScreenBreakpoints();
  const { navigate } = useNavigateRoute();

  const handleClick = () => {
    navigate(NavigationURLs.Dashboard);
  };

  if (isXs) {
    return <Button color='subtle' variant='ghost' layout='icon' leadingIcon={<Cross />} onClick={handleClick}></Button>;
  }

  return (
    <Button color='subtle' variant='filled' onClick={handleClick}>
      {text}
    </Button>
  );
};

export { HeaderExitButton };

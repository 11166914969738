import React, { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { api } from '@shared/api';
import { assetService, TransactionHistoryItem } from '@shared/services';

import { useAsync } from 'react-use';
import { getOrderSide } from 'src/lib/UniversalTrade/components/OrdersTile/utils';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { ConfirmDeleteOrderModal } from './components/ConfirmDeleteOrderModal';
import { OrderDetailsContent } from './components/OrderDetailsContent';
import { OrderDetailsFooter } from './components/OrderDetailsFooter';
import { OrderDetailsLinkedOrders } from './components/OrderDetailsLinkedOrders';
import { OrderDetailsModalSkeleton } from './components/OrderDetailsModalSkeleton';
import { OrderNotFound } from './components/OrderNotFound';
import { UseOrderInfoActions } from './hooks/useOrderInfoActions';

type Props = {
  orderId?: string;
  transaction?: TransactionHistoryItem;
  deleteModal?: boolean;
  onClose: () => void;
  onEditSuccess?: () => void;
};

const OrderDetailsModal: React.FC<Props> = ({ orderId, transaction, onClose, onEditSuccess, deleteModal = false }) => {
  const [showDeleteOrder, setShowDeleteOrder] = useState<boolean>(deleteModal);
  const isXs = useTailwindBreakpoint('xs');
  const { isSwyftxPro } = useSwyftxPro();

  const fetchOrderDetails = useCallback(async () => {
    if (orderId) {
      return await api.endpoints.getOrderById({ params: { orderUuid: orderId } });
    }
  }, [orderId]);

  const { loading, value } = useAsync(fetchOrderDetails, [fetchOrderDetails]);
  const order = value?.data;
  const asset = assetService.getAsset(order?.secondary_asset || 3);

  const modalTitle = order ? `${getOrderSide(order.order_type)} ${asset?.code}` : 'Order Details';

  const { downloadUserOrder, onDeleteOrder, onReOrder, viewAsset, onEditOrder } = UseOrderInfoActions({
    order,
    onClose,
    onEditSuccess,
    transaction,
  });

  const hasLinkedOrders = useMemo(
    () => !!transaction?.childOrders?.length || transaction?.linkedOrderUuid || transaction?.orderSourceUuid,
    [transaction],
  );

  return (
    <>
      <Modal
        id={orderId}
        open={!showDeleteOrder}
        onClose={onClose}
        onOpenChange={(e) => (e?.valueOf() === false ? onClose() : undefined)}
        title={modalTitle}
        position={isXs ? 'bottom' : 'center'}
        className='sm:!min-w-[35rem]'
      >
        <FlexLayout direction='column' spacing={16} className='px-8 pb-16 '>
          {loading && !order && <OrderDetailsModalSkeleton />}
          {!order && !loading && <OrderNotFound onClose={onClose} />}
          {order && (
            <>
              <OrderDetailsContent order={order} />
              {isSwyftxPro && transaction && hasLinkedOrders && <OrderDetailsLinkedOrders transaction={transaction} />}
              <OrderDetailsFooter
                order={order}
                transaction={transaction}
                setDeleteOpen={() => setShowDeleteOrder(true)}
                downloadUserOrder={downloadUserOrder}
                onReOrder={onReOrder}
                viewAsset={viewAsset}
                onEditOrder={onEditOrder}
              />
            </>
          )}
        </FlexLayout>
      </Modal>
      <ConfirmDeleteOrderModal
        open={showDeleteOrder}
        setOpen={setShowDeleteOrder}
        confirmDelete={onDeleteOrder}
        hasLinkedOrder={!!transaction?.orderSourceUuid && !transaction?.orderSourceUuid.includes('reo_')}
      />
    </>
  );
};

export { OrderDetailsModal };

import React, { useMemo } from 'react';

import Box from '@mui/material/Box';

import { Tick } from '@swyftx/aviary/icons/outlined';
import { types } from '@swyftx/marketing-utils';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { Big } from '@shared/safe-big';

import { useRewardQualificationProgress } from '@routes/Rewards/hooks';

type Props = {
  redemptionRequirement: types.UserAssetRedemptionRequirement | types.UserAssetRewardQualification;
  redemption?: Partial<types.UserAssetRedemption>;
};

const RewardCardProgress: React.FC<Props> = ({ redemptionRequirement, redemption }) => {
  const { label } = useRewardQualificationProgress(redemptionRequirement);

  const progress = useMemo(
    () =>
      Big((redemptionRequirement as types.UserAssetRedemptionRequirement).progress || 0)
        .div(redemptionRequirement.thresholdAmount || 1)
        .toNumber(),
    [redemptionRequirement],
  );

  const isExpired = useMemo(() => redemption?.status === types.AssetRedemptionStatus.Expired, [redemption]);

  return (
    <Stack width='100%' spacing={0.5} direction='row' alignItems='center'>
      <Box
        width='20px'
        height='20px'
        padding={0.2}
        sx={{
          borderRadius: '50%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          color: 'white',
          border: '1px solid',
          borderColor: 'text.secondary',
        }}
      >
        <Box
          width='100%'
          height='100%'
          sx={{
            borderRadius: '50%',
            position: 'relative',
            overflow: 'hidden',
            color: 'white',
          }}
        >
          {progress === 1 && (
            <Tick className='absolute left-1/2 top-1/2 z-10  h-12 w-12 -translate-x-1/2 -translate-y-1/2' />
          )}

          <Box
            bgcolor={isExpired ? 'text.disabled' : 'primary.main'}
            position='absolute'
            width='100%'
            color='white'
            bottom={0}
            height={`${Big(progress).times(100)}%`}
          />
        </Box>
      </Box>
      <Typography
        color='text.secondary'
        fontSize={14}
        whiteSpace='nowrap'
        paddingRight={4}
        sx={{ textDecoration: progress >= 1 ? 'line-through' : '' }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export { RewardCardProgress };

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TwoFactorAuthentication } from '@swyftx/aviary/icons/outlined';
import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const useSetTwoFactorAuthenticationTour = (): TourGuideData => {
  const { t } = useTranslation('featureDiscovery', { keyPrefix: 'tours' });
  const { navigate } = useNavigateRoute();

  return useMemo(
    () => ({
      icon: <TwoFactorAuthentication className='h-28 w-28 text-color-text-primary' />,
      name: t('setTwoFactorAuthentication.name'),
      description: t('setTwoFactorAuthentication.description'),
      steps: [
        {
          title: 'Open profile menu',
          description: 'Click your circled initial in the top right corner of the page',
          spotlightElementId: 'header-profile',
          alignment: 'left',
          customSpotlightStyles: 'border-radius: 50%',
          borderRadius: 96,
          onNext: () => {
            document.getElementById('header-profile')?.click();
          },
        },
        {
          title: 'Open profile page',
          description: 'Click on your name on the profile menu to open up your profile page',
          spotlightElementId: 'profile-menu-profile',
          alignment: 'left',
          customSpotlightStyles: 'border-radius: 12px',
          borderRadius: 8,
          onNext: () => {
            navigate(NavigationURLs.Profile);
          },
        },
        {
          title: 'Open profile security settings',
          description: 'Click on the security tab to open up all of the profile security settings',
          spotlightElementId: 'profile-security',
          alignment: 'right',
          borderRadius: 8,
          customSpotlightStyles: 'border-radius: 0px',
          onNext: () => {
            document.getElementById('profile-security')?.click();
          },
        },
        {
          title: t('setTwoFactorAuthentication.steps.1.title'),
          description: t('setTwoFactorAuthentication.steps.1.content'),
          spotlightElementId: 'two-factor-authentication-item',
          alignment: 'right',
        },
      ],
    }),
    [navigate, t],
  );
};

export { useSetTwoFactorAuthenticationTour };

import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Heading } from '@swyftx/aviary/atoms/Typography';

interface SettingsClusterProps {
  title: string;
  items: React.ReactNode[];
}

export const SettingsCluster: React.FC<SettingsClusterProps> = (props) => {
  const { title, items } = props;

  return (
    <FlexLayout direction='column' spacing={12}>
      <Heading size='h5'>{title}</Heading>
      <Card>
        <FlexLayout direction='column' spacing={0}>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {item}
              {index < items.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';

interface Props {
  orderTotal: string;
  asset?: Asset;
}

export const OrderTotal: React.FC<Props> = (props) => {
  const { orderTotal, asset } = props;

  if (!asset) return null;

  return (
    <NumericDataItem data={orderTotal} size='small' color='primary'>
      <FlexLayout direction='row' spacing={4}>
        <Numeric size='small'>{orderTotal}</Numeric>

        <Numeric size='small' color='secondary'>
          {asset.code}
        </Numeric>
      </FlexLayout>
    </NumericDataItem>
  );
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { RecurringOrderSource } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { capitalize } from '@utils/formatting';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { AutoInvestAsset } from 'src/lib/auto-invest/autoInvest.types';
import { useFundOtherWithOptions } from 'src/lib/auto-invest/hooks';
import { AssetIconsGroup } from 'src/lib/markets/components';

import { AutoInvestBalanceWarning } from './AutoInvestBalanceWarning';
import { AutoInvestDonutChart } from './AutoInvestDonutChart';
import { AutoInvestWidgetReviewItem } from './AutoInvestWidgetReviewItem';

type Props = {
  selectedAutoInvestAssets: AutoInvestAsset[];
  orderName?: React.ReactNode;
  frequency?: string;
  amount?: string;
  startDate?: DateTime;
  selectedPaymentMethod: RecurringOrderSource;
  lowestFeeAmount?: string;
  feeAmount?: Big;
  showBalanceWarning?: boolean;
  showDepositMessage?: boolean;
  diffDays: Big;
  diffDayText: string;
};

const AutoInvestReviewContent: React.FC<Props> = observer(
  ({
    selectedAutoInvestAssets,
    orderName,
    frequency,
    amount,
    startDate,
    selectedPaymentMethod,
    lowestFeeAmount,
    feeAmount,
    showBalanceWarning,
    showDepositMessage = true,
    diffDayText,
  }) => {
    const countryAsset = useCountryAsset();
    const { getFundOrderWithOption } = useFundOtherWithOptions();

    return (
      <FlexLayout direction='column' className='h-full w-full' spacing={16} alignItems='center'>
        <AutoInvestDonutChart autoInvestAssets={selectedAutoInvestAssets} />
        <AutoInvestWidgetReviewItem title='Order name' value={orderName} />
        <AutoInvestWidgetReviewItem
          title='Assets'
          value={
            <AssetIconsGroup
              assets={assetService.getAssetsByCode(selectedAutoInvestAssets.map((a) => a.assetCode))}
              size={16}
            />
          }
        />

        {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
          <>
            <AutoInvestWidgetReviewItem title='Frequency' value={capitalize(frequency || '')} />
            <AutoInvestWidgetReviewItem
              title={`${capitalize(frequency || '')} investment`}
              value={`${formatCurrency(amount, countryAsset)} ${countryAsset?.code}`}
            />
            <AutoInvestWidgetReviewItem
              title={`Fee per order (${lowestFeeAmount})`}
              value={`${formatCurrency(feeAmount, countryAsset)} ${countryAsset?.code}`}
            />
          </>
        )}

        {selectedPaymentMethod === RecurringOrderSource.DEPOSIT && (
          <AutoInvestWidgetReviewItem
            title='Minimum order amount'
            value={`${formatCurrency(30, countryAsset)} ${countryAsset?.code}`}
          />
        )}

        <AutoInvestWidgetReviewItem
          title='Funding method'
          value={getFundOrderWithOption(selectedPaymentMethod)?.label}
        />

        {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && startDate && (
          <AutoInvestWidgetReviewItem
            title='First order date'
            value={
              <FlexLayout direction='column' alignItems='end' spacing={4}>
                <Body color='secondary' weight='emphasis'>
                  {capitalize(diffDayText)}
                </Body>
                <Body size='small' color='secondary'>
                  ({startDate.toFormat('dd/MM/yyyy')})
                </Body>
              </FlexLayout>
            }
          />
        )}
        {selectedPaymentMethod === RecurringOrderSource.DEPOSIT && showDepositMessage && (
          <Notification title='Deposit instructions to follow' severity='info'>
            After creating your order, we will provide details to set up a recurring deposit from your banking provider
            of choice.
          </Notification>
        )}
        {showBalanceWarning && countryAsset && <AutoInvestBalanceWarning countryAsset={countryAsset} />}
      </FlexLayout>
    );
  },
);

export { AutoInvestReviewContent };

import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout';
import { Notification } from '@swyftx/aviary/atoms/Notification';

import { Profile } from '@shared/api';
import { CountriesEnum } from '@shared/enums';

import { getCountryCallingCode, isValidPhoneNumber, getExampleNumber, CountryCode } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import { Controller, UseFormReturn } from 'react-hook-form';

const PhoneNumberInputStep = ({
  userProfile,
  methods,
}: {
  userProfile: Profile;
  methods: UseFormReturn;
  handleClose: (boolean: false) => void;
}) => {
  const { formState, control } = methods;

  const userCountry = (userProfile.address?.country || CountriesEnum.AU) as CountryCode;
  const example = getExampleNumber(userCountry, examples);
  const examplePhoneNumber = example?.formatInternational() || '';

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        const countryCallingCode = getCountryCallingCode(userCountry);
        event.target.value = `+${countryCallingCode}`;
      }
    },
    [userCountry],
  );

  return (
    <FlexLayout direction='column' spacing={24}>
      <Notification title='Important information' className='text-left'>
        Crypto withdrawals will be disabled for 24 hours after changing your phone number or email address.
      </Notification>

      <Controller
        name='phone'
        control={control}
        defaultValue=''
        rules={{
          validate: (value) => isValidPhoneNumber(`${value}`),
        }}
        render={({ field, fieldState }) => (
          <div>
            <Input
              {...field}
              onFocus={handleFocus}
              placeholder={examplePhoneNumber}
              error={!!(fieldState.isDirty && fieldState.error)}
              className='PII'
              type='tel'
            />
            {!!(fieldState.isDirty && fieldState.error) && (
              <span className='text-color-text-error'>Please enter a valid phone number</span>
            )}
          </div>
        )}
      />

      <Button
        size='lg'
        type='submit'
        variant='filled'
        loading={formState.isSubmitting}
        disabled={!formState.isValid || formState.isSubmitting}
        className='w-full'
      >
        Continue
      </Button>
    </FlexLayout>
  );
};

export { PhoneNumberInputStep };

import React from 'react';

import { AffiliatesEnum, AffiliateType, RegisterStepsConfig, RegisterStepType } from '@Register/types';

import { SwyftxFinderLogo } from 'src/assets';
import { SwyftxFinderLogoMini } from 'src/assets/SwyftxFinderLogoMini';

import { FinderReferralCode } from './components/FinderReferralCode/FinderReferralCode';

export const Affiliates: { [key in AffiliatesEnum]: AffiliateType } = {
  [AffiliatesEnum.Finder]: {
    name: 'Finder',
    miniLogo: (
      <SwyftxFinderLogoMini id='login.logo.finder.mini' width={200} height={32} alt='swyftx-finder-logo-mini' />
    ),
    logo: <SwyftxFinderLogo id='login.logo.finder' width={265} height={35} alt='swyftx-finder-logo' />,
    bigLogo: <SwyftxFinderLogo id='login.logo.finder' width={350} height={35} alt='swyftx-finder-logo' />,
    registerLogo: <SwyftxFinderLogo id='login.logo.finder' height={32} alt='swyftx-finder-logo' />,
    component: <FinderReferralCode />,
  },
};

export const registerStepsConfig: RegisterStepsConfig = [
  {
    type: RegisterStepType.CreateAccountForm,
  },
  {
    type: RegisterStepType.VerifyPhoneNumber,
  },
  {
    type: RegisterStepType.VerifyEmailAddress,
  },
  {
    type: RegisterStepType.SetPasswordForm,
  },
];

import { useMemo } from 'react';

import { UserStore } from '@shared/store';

import { EntityPermissions } from '@hooks/Permissions';

import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

const useTradeStatus = (tradeType: 'order' | 'auto-invest' = 'order') => {
  const { scopeArray } = UserStore.useUserStore;

  const hasPermission = useMemo(() => {
    if (tradeType === 'auto-invest') {
      return scopeArray.includes(EntityPermissions.RECURRING_ORDERS_CREATE);
    }
    return scopeArray.includes(EntityPermissions.ORDERS_CREATE);
  }, [scopeArray, tradeType]);

  const { isVerified, isRestricted } = useProfileStatus();

  return {
    hasPermission,
    isVerified,
    isRestricted,
  };
};

export { useTradeStatus };

import React from 'react';

import { LineChart } from '@swyftx/aviary/atoms/Charts';
import { LineChartData } from '@swyftx/aviary/atoms/Charts/LineChart/LineChart.types';
import { CurrencyType } from '@swyftx/aviary/utils/currency/currency.types';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

type Props = {
  id: string;
  data?: LineChartData;
  color?: 'primary' | 'destructive' | 'success';
  asset: Asset;
  className?: string;
};

const AssetGroupCardLineChart: React.FC<Props> = ({ id, className, data = [], color = 'primary', asset }) => (
  <div className={cn('h-24 w-96', className)}>
    <LineChart
      className={className}
      idKey={id}
      chartData={data}
      strokeWidth={1}
      areaColor={color}
      currencyCode={asset.code}
      currencyType={asset.assetType as unknown as CurrencyType}
    />
  </div>
);

export { AssetGroupCardLineChart };

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { clsx } from 'clsx';

import { NumericProps, numericVariants } from './Numeric.styles';

const Numeric: React.FC<NumericProps> = ({ className, size, weight, loading, color, overflow, ...props }) => (
  <p className={clsx(numericVariants({ size, weight, color, loading, className, overflow }))} {...props} />
);

export { Numeric };

import TextFieldMUI from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

type Props = {
  success?: boolean;
};

export const StyledTextFieldMUI = styled(TextFieldMUI, {
  shouldForwardProp: (prop) => prop !== 'success',
})<Props>`
  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .MuiOutlinedInput-root {
    background-color: var(--color-background-surface-secondary);
  }

  fieldset {
    border-color: ${({ success }) => (success ? 'var(--color-border-success);' : 'var(--color-border-main);')}
`;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Refresh } from '@swyftx/aviary/icons/outlined';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

const AffiliateDashboardEarnToday: React.FC = observer(() => {
  const { affiliateInfo, payoutHistory, fetchAffiliateInfo, fetchingAffiliateInfo } = useContext(ProfileContext);
  const countryAsset = useCountryAsset();
  const { t } = useTranslation('profile.affiliates', { keyPrefix: 'affiliateDashboard.earn' });

  const total = payoutHistory ? payoutHistory.reduce((prev, curr) => prev.add(curr.userCountryValue), Big(0)) : Big(0);

  return (
    <FlexLayout direction='column' className='p-32'>
      <FlexLayout direction='column' spacing={8}>
        <FlexLayout direction='column' className='flex items-center justify-center' spacing={8}>
          <Image image='affiliates' usePalette className='h-[100px] w-[100px]' alt='affilliate sign up' />
          <Body weight='emphasis'>{t('title')}</Body>
          <Body size='small' color='secondary'>
            {t('subTitle')}
          </Body>
        </FlexLayout>

        {affiliateInfo && (
          <FlexLayout className='flex items-center justify-center' spacing={16}>
            <FlexLayout direction='row' className='flex-row' alignItems='center'>
              {affiliateInfo.referredUsers > 0 && total.gt(0) ? (
                <Body className='inline' size='medium'>
                  You&apos;ve made {affiliateInfo.referredUsers} referral{affiliateInfo.referredUsers !== 1 ? 's' : ''},
                  with a total payout of <strong>{formatCurrency(total, countryAsset, { appendCode: true })}</strong>
                </Body>
              ) : (
                <Body size='medium'>
                  You&apos;ve made {affiliateInfo.referredUsers} referral{affiliateInfo.referredUsers !== 1 ? 's' : ''},
                  however, you&apos;ve not yet earnt enough for your first payout.
                </Body>
              )}
            </FlexLayout>
            <Button
              loading={fetchingAffiliateInfo}
              onClick={fetchAffiliateInfo}
              variant='outlined'
              leadingIcon={<Refresh />}
              size='sm'
            >
              {t('buttonLabels.refresh')}
            </Button>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

export { AffiliateDashboardEarnToday };

/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
};

const Callout: React.FC<PropsWithChildren<Props>> = ({ className, children }) => (
  <span className={cn('font-callout', className)}>{children}</span>
);

export { Callout };

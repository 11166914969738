import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { useQuickBuyPaymentMethods } from 'src/lib/trade/hooks';
import { QuickBuyPaymentMethodType, QuickBuyStep } from 'src/lib/trade/types/Trade.types';

type Props = {
  className?: string;
  selectedPaymentMethod: QuickBuyPaymentMethodType;
  setStep: React.Dispatch<React.SetStateAction<QuickBuyStep>>;
  disabled?: boolean;
};

const QuickBuyPaymentMethod: React.FC<Props> = ({ className, selectedPaymentMethod, setStep, disabled }) => {
  const { getPaymentOption } = useQuickBuyPaymentMethods();

  const payWithOption = useMemo(
    () => getPaymentOption(selectedPaymentMethod),
    [getPaymentOption, selectedPaymentMethod],
  );

  if (!payWithOption) return null;

  return (
    <FlexLayout
      onClick={() => !disabled && setStep('paymentMethod')}
      className={cn(className, !disabled ? 'cursor-pointer hover:bg-color-background-surface-hover' : '')}
      alignItems='center'
      justifyContent='space-between'
    >
      <Body size='small' weight='emphasis'>
        Pay with
      </Body>
      <FlexLayout spacing={8} alignItems='center' className={cn('rounded-8 p-8 ')}>
        {payWithOption.icon}
        <Body>{payWithOption.title}</Body>
        {!disabled && <ArrowChevronDown />}
      </FlexLayout>
    </FlexLayout>
  );
};

export { QuickBuyPaymentMethod };

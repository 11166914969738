import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';

import { observer } from 'mobx-react-lite';
import { useMarketActivity } from 'src/lib/markets/hooks/useMarketActivity';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { MarketMoversWidget } from 'src/lib/markets/widgets';
import { MarketActivityCard } from 'src/lib/markets/widgets/MarketActivityWidget/MarketActivityCard';
import { MarketsGainersLosersWidget } from 'src/lib/markets/widgets/MarketsGainersLosersWidget/MarketsGainersLosersWidget';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DashboardWidgetRowProps } from '../../types';

const MarketsActivityRow: React.FC<DashboardWidgetRowProps> = observer(({ atLeast }) => {
  const { navigate } = useNavigateRoute();
  const { assets } = useMarkets();
  const { gainers, losers } = useMarketActivity({ assets });

  const viewAllAssets = useCallback(() => {
    navigate(NavigationURLs.AssetsAll);
  }, [navigate]);

  const filteredGainers = useMemo(() => gainers.slice(0, 5), [gainers]);
  const filteredLosers = useMemo(() => losers.slice(0, 5), [losers]);

  return (
    <ElementContainer
      title='Market activity'
      className='w-full'
      actions={
        <Body color='accent' onClick={viewAllAssets} size='small' weight='emphasis' className='cursor-pointer'>
          View all assets
        </Body>
      }
    >
      <FlexLayout direction='column' className='h-full w-full' spacing={atLeast?.md ? 24 : 16}>
        <FlexLayout className='w-full flex-wrap' spacing={atLeast?.md ? 24 : 16}>
          <MarketsGainersLosersWidget
            assets={assets}
            direction={atLeast?.md || !atLeast?.sm ? 'column' : 'row'}
            alignment='end'
            className='order-3 w-full @md:order-1 @md:w-1/3'
          />
          <MarketActivityCard
            title='Gainers'
            assets={filteredGainers}
            variant='full'
            type='gainers'
            className='w order-1 hidden @sm:block @md:order-2'
          />
          <MarketActivityCard
            title='Losers'
            assets={filteredLosers}
            variant='full'
            type='losers'
            className='order-2 hidden @sm:block @md:order-3'
          />
        </FlexLayout>
        <MarketMoversWidget />
      </FlexLayout>
    </ElementContainer>
  );
});

export { MarketsActivityRow };

import { useState } from 'react';

import { cn } from '@shared/utils/lib/ui';

import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

const ResizablePanelGroup = ({ className, ...props }: React.ComponentProps<typeof PanelGroup>) => (
  <PanelGroup
    className={cn('flex h-full w-full data-[panel-group-direction=vertical]:flex-col', className)}
    {...props}
  />
);

const ResizablePanel = Panel;

const ResizableHandle = ({
  className,
  border = false,
  dragClassName,
  ...props
}: React.ComponentProps<typeof PanelResizeHandle> & {
  withHandle?: boolean;
  border?: boolean;
  dragClassName?: string;
}) => {
  const [dragging, setDragging] = useState<boolean>();
  return (
    <PanelResizeHandle
      onDragging={setDragging}
      className={cn(
        `w-px data-[panel-group-direction=vertical]:h-px relative flex items-center justify-center py-4 after:absolute 
    after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:ring-1 
    focus-visible:ring-ring focus-visible:ring-offset-1 data-[panel-group-direction=vertical]:w-full 
    data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 
    data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 
    data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90`,
        border ? 'cursor-pointer rounded-8 hover:bg-color-background-neutral-hover' : '',
        dragging ? dragClassName : '',
        className,
      )}
      {...props}
    />
  );
};

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };

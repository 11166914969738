import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';

import { pxToRem } from '@swyftx/react-web-design-system';

import { generateKey } from '@utils/id';

import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';

import { ITEM_SLIDER_ALL_WIDTH, ITEM_SLIDER_ICON_PADDING, ITEM_SLIDER_ICON_SIZE, SliderItem } from './ItemSlider.data';
import { useDragScroll } from './ItemSlider.hooks';
import { ItemSliderContainer } from './ItemSlider.styled';
import { ItemSliderItem } from './ItemSliderItem/ItemSliderItem';

const ITEM_CHANGE_DEBOUNCE = 600;

type Props = {
  selectedItemOverride: null | number; // ID
  parentWidth: number | null;
  items: Array<SliderItem>;
  onItemSelect: (id: number | null) => void;
  middle?: boolean;
};

// good luck
export const ItemSlider: React.FC<Props> = observer(
  ({ onItemSelect, items, parentWidth, middle, selectedItemOverride }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const ref = useRef<HTMLDivElement | null>(null);
    const totalAllWidth = ITEM_SLIDER_ALL_WIDTH + ITEM_SLIDER_ICON_PADDING;
    const totalIconWidth = ITEM_SLIDER_ICON_SIZE + ITEM_SLIDER_ICON_PADDING;
    const [triggerSmoothSnap, setTriggerSmoothSnap] = useState<string>(generateKey()); // Used to avoid stale closures from event listeners
    const isSnapSmooth = useRef(false); // enables smooth snapScroll
    const isDragging = useRef(false); // used to disable onclick

    const stationaryIndexCount = useMemo(() => {
      if (parentWidth && !middle) {
        const center = Math.round(parentWidth / 2);
        const iconsToCenter = center / totalIconWidth;
        return iconsToCenter - 1;
      }
      return 0;
    }, [parentWidth, middle]);

    const reset = () => {
      if (ref.current) {
        ref.current.scrollLeft = 0;
        setSelectedIndex(-1);
      }
    };

    useEffect(() => {
      if (!isDragging.current) {
        isSnapSmooth.current = true;
        if (selectedItemOverride === null) {
          setSelectedIndex(-1);
        } else {
          setSelectedIndex(items.findIndex((item) => item.id === selectedItemOverride));
        }
      }
    }, [selectedItemOverride]);

    const indexScrollPositions = useMemo(() => {
      reset(); // Reset scroll positions
      // Calculate stationary indexes and return
      return [...items].map((_, index) => {
        const indexesFromStationary = index - stationaryIndexCount;
        if (indexesFromStationary >= 0) {
          return (
            totalAllWidth +
            ((indexesFromStationary - 1) * totalIconWidth + (middle ? totalIconWidth : totalIconWidth / 2))
          );
        }
        return 0;
      });
    }, [parentWidth, middle, items, stationaryIndexCount]);

    const onMouseUp = () => {
      setTriggerSmoothSnap(generateKey());

      // Give enough time for effect to trigger
      setTimeout(() => {
        isDragging.current = false;
      }, 500);
    };

    const snapScroll = () => {
      if (ref.current) {
        if (isSnapSmooth.current) {
          ref.current.style.scrollBehavior = 'smooth';
        }

        ref.current.scrollLeft = indexScrollPositions[selectedIndex];
        if (isSnapSmooth.current) {
          ref.current.style.scrollBehavior = 'auto';
          isSnapSmooth.current = false;
        }
      }
    };

    useEffect(() => {
      isSnapSmooth.current = true;
      snapScroll();
    }, [triggerSmoothSnap]);

    const { onMouseDown } = useDragScroll(
      ref,
      isDragging,
      stationaryIndexCount,
      totalIconWidth,
      setSelectedIndex,
      items.length,
      {
        onMouseUp,
      },
    );

    useEffect(() => {
      snapScroll();
    }, [selectedIndex]);

    useEffect(() => {
      if (ref.current) {
        ref.current.onwheel = () => false;
      }
    }, [ref]);

    const handleClick = (index: number) => {
      if (!isDragging.current) {
        isSnapSmooth.current = true;
        setSelectedIndex(index);
      }
    };

    // Update the selected item
    useDebounce(
      () => {
        if (selectedIndex === -1) {
          onItemSelect(null);
        } else {
          const selectedItem = items[selectedIndex];
          onItemSelect(selectedItem.id);
        }
      },
      ITEM_CHANGE_DEBOUNCE,
      [selectedIndex],
    );

    return (
      <ItemSliderContainer ref={ref} onMouseDown={onMouseDown} onTouchStart={onMouseDown}>
        {middle && (
          <Box
            minWidth={pxToRem((parentWidth || 0) / 2 - ITEM_SLIDER_ICON_SIZE / 2)}
            minHeight={ITEM_SLIDER_ICON_SIZE}
          />
        )}
        <Box onClick={() => handleClick(-1)}>
          <ItemSliderItem index={-1} selectedIndex={selectedIndex} />
        </Box>
        {items.map((item, i) => (
          <Box key={item.id} onClick={() => handleClick(i)} sx={{ cursor: 'pointer' }}>
            <ItemSliderItem item={item} index={i} selectedIndex={selectedIndex} />
          </Box>
        ))}
        {middle && <Box minWidth={pxToRem((parentWidth || 0) / 2)} minHeight={ITEM_SLIDER_ICON_SIZE} />}
      </ItemSliderContainer>
    );
  },
);

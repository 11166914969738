import React from 'react';

import { Divider, Grid, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';

type Props = {
  image: JSX.Element;
  link: JSX.Element;
  subTitle: string;
  title: string;
  id: string;
  divider?: boolean;
};

export const SecurityInformationSection = observer(({ id, image, subTitle, title, link, divider }: Props) => {
  const { is } = useContentBreakpoint();

  return (
    <>
      <Grid container direction='row' alignItems='center'>
        {is.sm && (
          <LayoutGridItem xs={12} display='flex' padding={2} paddingTop={0}>
            <Stack
              direction='row'
              spacing={1}
              sx={{
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {image}
            </Stack>
          </LayoutGridItem>
        )}
        <LayoutGridItem xs={is.sm ? 12 : 8}>
          <Stack direction='row' spacing={1}>
            <Stack direction='column' spacing={1}>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent='start'>
                <Typography id={`${id}-title`} fontSize={14} fontWeight='500'>
                  {title}
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography fontSize={pxToRem(14)} color='text.secondary' fontWeight='400'>
                  {subTitle}
                </Typography>
                {link}
              </Stack>
            </Stack>
          </Stack>
        </LayoutGridItem>
        {!is.sm && (
          <LayoutGridItem xs={4} display='flex' padding={2}>
            <Stack
              direction='row'
              spacing={1}
              sx={{
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              {image}
            </Stack>
          </LayoutGridItem>
        )}
      </Grid>
      {divider && <Divider sx={{ margin: '1.5rem' }} />}
    </>
  );
});

import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

export enum OrdersTileTabs {
  Wallets = 'wallets',
  OpenOrders = 'openOrders',
  OrderHistory = 'orderHistory',
}

export type OrdersTab = EnhancedTab<OrdersTileTabs>;

export const ordersTabs: OrdersTab[] = [
  {
    title: 'Wallets',
    value: OrdersTileTabs.Wallets,
  },
  {
    title: 'Open Orders',
    value: OrdersTileTabs.OpenOrders,
  },
  {
    title: 'Order History',
    value: OrdersTileTabs.OrderHistory,
  },
];

export type OrderHistoryTableData = {
  id: string;
  asset: string;
  side: string;
  type: string;
  orderPrice: string;
  amount: string;
  total: string;
  status: string;
  date: string;
};

export type OpenOrdersTableData = {
  id: string;
  asset: string;
  side: string;
  type: string;
  triggerPrice: string;
  currentPrice: string;
  amount: string;
  total: string;
  filled: string;
  date: string;
  actions: string;
};

export type PortfolioBreakdownTableData = {
  asset: string;
  balance: string;
  balanceValue: string;
  profitLossPercentage: string;
  profitLossValue: string;
  avgBuyPrice: string;
  allocation: string;
  currentPrice: string;
  actions: string;
};

import React, { useCallback, useMemo, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Category, Filter, Tick } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { cn } from '@shared/utils/lib/ui';

import { WalletLayoutType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

type Props = {
  layout: WalletLayoutType;
  setLayout: (newLayout: WalletLayoutType) => void;
  className?: string;
};

const WalletListLayoutFilter: React.FC<Props> = observer(({ layout, setLayout, className }) => {
  const [open, setOpen] = useState<boolean>(false);

  const layoutFilters = useMemo(
    () => [
      {
        label: 'List',
        value: WalletLayoutType.LIST,
        onClick: () => setLayout(WalletLayoutType.LIST),
        prefix: <Filter id='filter-layout-list-icon' className='h-20 w-20 text-color-text-primary' />,
      },
      {
        label: 'Tiles',
        value: WalletLayoutType.TILES,
        onClick: () => setLayout(WalletLayoutType.TILES),
        prefix: <Category id='filter-layout-tiles-icon' className='h-20 w-20 text-color-text-primary' />,
      },
    ],
    [setLayout],
  );

  const getPrefix = useCallback(
    () => layoutFilters.filter((filter) => filter.value === layout)[0].prefix,
    [layout, layoutFilters],
  );

  return (
    <Menu
      open={open}
      onOpenChange={setOpen}
      triggerContent={
        <Card
          className={cn(
            className,
            'h-[36px] cursor-pointer px-16',
            open ? 'bg-color-background-surface-selected' : 'hover:bg-color-background-surface-hover',
          )}
        >
          <FlexLayout alignItems='center' justifyContent='center' className='h-full w-full' spacing={8}>
            {getPrefix()}
            <Body color='primary'>{layout}</Body>
          </FlexLayout>
        </Card>
      }
    >
      {layoutFilters.map((type) => (
        <MenuItem
          key={type.value}
          action={() => {
            type.onClick();
            setOpen(false);
          }}
          text={type.label}
          endAdornment={type.value === layout && <Tick className='h-14 w-14 text-color-text-accent' />}
        />
      ))}
    </Menu>
  );
});

export { WalletListLayoutFilter };

import { BalanceSuccess, BalanceFailure } from '@shared/api/@types/migrator';
import { ExchangesEnum } from '@shared/enums';

export const APIKeyValidationStatus = {
  INVALID: 'INVALID',
  INVALID_PERMISSION: 'INVALID_PERMISSION',
  INVALID_IP: 'INVALID_IP',
} as const;

export const ErrorTitle = {
  UNAVAILABLE: 'UNAVAILABLE',
  VERIFICATION_REQUIRED: 'VERIFICATION_REQUIRED',
  INVALID_EXCHANGE: 'INVALID_EXCHANGE',
  INVALID_KEYS: 'INVALID_KEYS',
} as const;

export interface MigratorError {
  code: string;
  title: keyof typeof ErrorTitle;
  detail: string;
  extra?: {
    result: keyof typeof APIKeyValidationStatus;
  };
  exchange: ExchangesEnum;
}

export type Data = BalanceSuccess | BalanceFailure;

export type TranslationKey =
  | string
  | {
      [K in keyof typeof APIKeyValidationStatus]?: string;
    };

export type TranslationKeysType = {
  [K in ExchangesEnum]: {
    [P in keyof typeof ErrorTitle]?: TranslationKey;
  };
};

export const TranslationKeys: TranslationKeysType = {
  [ExchangesEnum.Coinspot]: {
    [ErrorTitle.INVALID_KEYS]: {
      [APIKeyValidationStatus.INVALID]: 'steps.assetMigrator.confirmationModal.error.coinspot.invalidKeys',
      [APIKeyValidationStatus.INVALID_PERMISSION]:
        'steps.assetMigrator.confirmationModal.error.coinspot.invalidPermission',
      [APIKeyValidationStatus.INVALID_IP]: 'steps.assetMigrator.confirmationModal.error.coinspot.invalidIp',
    },
  },
  [ExchangesEnum.Binance]: {
    [ErrorTitle.INVALID_KEYS]: {
      [APIKeyValidationStatus.INVALID]: 'steps.assetMigrator.confirmationModal.error.binance.invalidKeys',
      [APIKeyValidationStatus.INVALID_PERMISSION]:
        'steps.assetMigrator.confirmationModal.error.binance.invalidPermission',
      [APIKeyValidationStatus.INVALID_IP]: 'steps.assetMigrator.confirmationModal.error.binance.invalidIp',
    },
  },
} as const;

export const GlobalErrorTranslationKeys = {
  [ErrorTitle.UNAVAILABLE]: 'steps.assetMigrator.confirmationModal.error.unavailable',
  [ErrorTitle.VERIFICATION_REQUIRED]: 'steps.assetMigrator.confirmationModal.error.verificationRequired',
  [ErrorTitle.INVALID_EXCHANGE]: 'steps.assetMigrator.confirmationModal.error.invalidExchange',
} as const;

export function lookupTranslationKey(exchange: ExchangesEnum, error: MigratorError): string | undefined {
  const { title, extra } = error;

  if (title === ErrorTitle.INVALID_EXCHANGE) {
    return GlobalErrorTranslationKeys[title];
  }

  const translation = TranslationKeys[exchange][title];

  if (translation) {
    if (typeof translation === 'string') {
      return translation;
    }
    if (title === ErrorTitle.INVALID_KEYS && extra?.result) {
      return translation[extra.result];
    }
  }

  return undefined;
}

import { ReturnedTransaction } from '@shared/api/@types/verification';

type ProfileVerificationStateType = {
  verificationStep: VerificationStep;
};

export enum VerificationStep {
  None,
  FirstTimeVerification,
  IdentityVerificationSummary,
  GreenID,
  Jumio,
  JumioManualDocuments,
  PersonalDetails,
  FundsSummary,
  Funds,
}

export enum KYCRequiredEnum {
  KYC0 = 'kyc0',
  KYC1 = 'kyc1',
  KYC2 = 'kyc2',
}

type ProfileVerificationType = {
  setVerificationStep: (verificationStep: VerificationStep) => void;
  setJumioTransaction: (transaction: ReturnedTransaction) => void;
  resolveRestrictions: () => KYCRequiredEnum | null;
  cleanup: () => void;
  jumioTransaction: ReturnedTransaction | undefined;
};

interface ProfileVerificationStoreSchema extends ProfileVerificationStateType, ProfileVerificationType {}

const initialValues: ProfileVerificationStoreSchema = {
  verificationStep: VerificationStep.None,
  jumioTransaction: undefined,
  setJumioTransaction: () => {},
  setVerificationStep: () => {},
  resolveRestrictions: () => null,
  cleanup: () => {},
};

export { initialValues };
export type { ProfileVerificationStoreSchema };

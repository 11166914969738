import { OfferingTier, TierDetail, TierDetails } from '@shared/api/@types/staking';
import { Big } from '@shared/safe-big';

const DAYS_IN_YEAR = 365.25;

const getMinBalance = (tiers: OfferingTier[], tierIndex: number): number => {
  let minBalance = Big(0);

  if (tierIndex === 0) return minBalance.toNumber();

  tiers.forEach((tier, index) => {
    if (index < tierIndex) {
      minBalance = minBalance.plus(Big(tier.size || '0'));
    }
  });

  return minBalance.toNumber();
};

const getMaxBalance = (
  tiers: OfferingTier[],
  tierIndex: number,
  remainingCapacity: string,
  balance: string,
): number => {
  let maxBalance = Big(0);

  // If we are the last tier, just have the max as whatever is remaining for the pool
  if (tierIndex === tiers.length - 1) {
    const min = getMinBalance(tiers, tierIndex);
    const balanceInTier = Big(balance).minus(min);
    return Big(remainingCapacity).plus(balanceInTier).plus(min).toNumber();
  }

  tiers.forEach((tier, index) => {
    if (index <= tierIndex) {
      maxBalance = maxBalance.plus(Big(tier.size || '0'));
    }
  });

  return maxBalance.toNumber();
};

const getAprFromApy = (apy: number | string): string => {
  const apyValue = Big(Big(apy).div(100)).plus(1).toNumber();
  const value = apyValue ** (1 / DAYS_IN_YEAR) - 1;
  return Big(value).times(DAYS_IN_YEAR).times(100).toFixed(2);
};

const getTierDetails = (
  tiers: OfferingTier[],
  balance: string,
  remainingCapacity: string,
  existingBalance?: string,
  useApy = false,
) => {
  // loop through all of the tiers and calculate the rewards in each tier
  const currentBalance = existingBalance || balance;
  const { applicableBalance, totalRewards, tierDetails, totalDailyRewards } = tiers.reduce<{
    tierDetails: TierDetails;
    totalRewards: Big;
    totalDailyRewards: Big;
    applicableBalance: Big;
  }>(
    (acc, tier, index) => {
      const { APY, tier: tierNo, RPY } = tier;
      const min = getMinBalance(tiers, index);
      const max = getMaxBalance(tiers, index, remainingCapacity, balance);

      const normalizedBalance = Big(balance).minus(min).toNumber();
      const normalizedCurrentBalance = Big(currentBalance).minus(min).toNumber();

      const normalizedMax = Big(max).minus(min).toNumber();

      const balanceForTier = Big(Math.min(normalizedMax, normalizedBalance));
      const trueBalanceForTier = balanceForTier.lte(0) ? Big(0) : balanceForTier;

      const currentBalanceForTier = Big(Math.min(normalizedMax, normalizedCurrentBalance));
      const trueCurrentBalanceForTier = currentBalanceForTier.lte(0) ? Big(0) : currentBalanceForTier;

      const apyPercent = Big(APY).div(100);
      const aprPercent = Big(useApy ? getAprFromApy(Big(APY).toNumber()) : RPY).div(100);

      const rewards = trueBalanceForTier.times(apyPercent);
      const dailyRewards = trueBalanceForTier.times(aprPercent).div(DAYS_IN_YEAR);

      const details: TierDetail = {
        currentBalance: trueCurrentBalanceForTier.toString(),
        updatedBalance: trueBalanceForTier.toString(),
        updatedDailyPayout: dailyRewards.toString(),
        min,
        max,
      };

      acc.tierDetails[tierNo] = details;

      acc.totalRewards = acc.totalRewards.add(rewards);
      acc.totalDailyRewards = acc.totalDailyRewards.add(dailyRewards);
      acc.applicableBalance = acc.applicableBalance.add(trueBalanceForTier);
      return acc;
    },
    { totalRewards: Big('0'), applicableBalance: Big('0'), tierDetails: {}, totalDailyRewards: Big('0') },
  );

  const apy = totalRewards.div(applicableBalance).times(100).toFixed(2).toString();

  return {
    totalDailyRewards,
    totalRewards,
    apy,
    tierDetails,
  };
};

const getDailyPayout = (apy: string, balance: string) => Big(getAprFromApy(apy)).div(100).times(Big(balance)).div(365);

const nFormatter = (num: number) => {
  if (Big(num).lt(1)) return num.toString();
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((number) => num >= number.value);
  return item ? (num / item.value).toFixed(2).replace(rx, '$1') + item.symbol : '0';
};

export { getMinBalance, getMaxBalance, getTierDetails, getAprFromApy, nFormatter, getDailyPayout, DAYS_IN_YEAR };

import { useMemo } from 'react';

import { Big } from '@shared/safe-big';

import { useFetchOrder } from '@hooks/Orders';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useTransactionOrderType } from 'src/lib/transactions/hooks/useTransactionOrderType';

type Props = {
  orderUuid: string;
};

const useLinkedOrderDetails = ({ orderUuid }: Props) => {
  const { order } = useFetchOrder(orderUuid);
  const { getAssetById } = useMarkets();

  const { parseOrderTypeName, isSellOrder } = useTransactionOrderType();

  const typeName = useMemo(() => parseOrderTypeName(order?.order_type), [order?.order_type, parseOrderTypeName]);
  const triggerPrice = useMemo(() => {
    if (order?.rate) return order.rate.toString();
    if (!order?.trigger) return '';

    if (isSellOrder(order.order_type)) {
      return Big(1).div(order.trigger).toString();
    }

    return order.trigger.toString();
  }, [isSellOrder, order]);

  const amount = useMemo(() => {
    if (order?.amount) return order.amount.toString();
    return order?.quantity?.toString();
  }, [order?.amount, order?.quantity]);

  const total = useMemo(() => {
    if (order?.total) return Big(order.total).toString();
    return Big(amount).times(triggerPrice).toString();
  }, [amount, order?.total, triggerPrice]);

  const primaryAsset = useMemo(() => getAssetById(order?.primary_asset), [getAssetById, order?.primary_asset]);
  const secondaryAsset = useMemo(() => getAssetById(order?.secondary_asset), [getAssetById, order?.secondary_asset]);

  return {
    isValidLinkedOrder: !order || !order.orderUuid,
    primaryAsset,
    secondaryAsset,
    typeName,
    triggerPrice,
    amount,
    total,
  };
};

export { useLinkedOrderDetails };

export enum WalletPickerTabId {
  Owned = 'wallet-picker-tab-owned',
  Favourites = 'wallet-picker-tab-favourites',
  All = 'wallet-picker-tab-all',
}

export type WalletPickerTabs = {
  id: WalletPickerTabId;
  label: string;
};

export const TabToPathMap: Record<WalletPickerTabId, string> = {
  [WalletPickerTabId.Owned]: 'Owned',
  [WalletPickerTabId.Favourites]: 'Favourites',
  [WalletPickerTabId.All]: 'All',
};

export const Tabs: WalletPickerTabs[] = [
  {
    id: WalletPickerTabId.Owned,
    label: 'walletPickerMenu.buttonLabels.owned',
  },
  {
    id: WalletPickerTabId.Favourites,
    label: 'walletPickerMenu.buttonLabels.favourites',
  },
  {
    id: WalletPickerTabId.All,
    label: 'walletPickerMenu.buttonLabels.all',
  },
];

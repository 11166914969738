import React from 'react';

import { Fade } from '@mui/material';

import { Divider, Grid, Skeleton, Stack } from '@swyftx/react-web-design-system';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

interface Props {
  isThumbnailView: boolean;
}

const NewsFeedTileLoadingState: React.FC<Props> = ({ isThumbnailView }: Props) => {
  const { isMobile, bx } = useContentBreakpoint();
  const CONTENT_SKELETON_LENGTH = 3;
  const DESKTOP_SKELETON_LENGTH = 7;

  return (
    <Fade in timeout={500}>
      <div>
        {[...Array(DESKTOP_SKELETON_LENGTH)].map((item, i) => (
          <div key={`news-item-skeleton-${i}`}>
            {isThumbnailView ? (
              <Grid container sx={{ maxHeight: isMobile ? '140px' : '185px' }} padding={1}>
                <Grid item xs={8} md={9} xl={10} padding={1}>
                  <Skeleton width='100%' height={24} />
                  <Skeleton width='40%' height={24} />
                  {!isMobile && (
                    <>
                      <Skeleton width='100%' height={24} />
                      <Skeleton width='100%' height={24} />
                    </>
                  )}
                  <Stack direction='row' alignItems='center' justifyContent='flex-start' paddingY={1}>
                    {Array(CONTENT_SKELETON_LENGTH).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant='circular'
                        width={20}
                        height={20}
                        sx={{ display: 'flex', margin: 0.5 }}
                      />
                    ))}
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    {Array(CONTENT_SKELETON_LENGTH).map((_, index) => (
                      <Skeleton key={index} width='20%' height={24} />
                    ))}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  xl={2}
                  padding={1.5}
                  display='flex'
                  justifyContent='flex-end'
                  position='relative'
                >
                  <Skeleton
                    variant='rectangular'
                    sx={{ borderRadius: 1 }}
                    width={bx({ xs: 86, sm: 160, md: 210 })}
                    height={bx({ xs: 86, sm: 120, md: 158 })}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container direction='row' alignItems='center' paddingX={1} paddingY={1}>
                <Grid item xs={2} paddingX={0.5}>
                  <Skeleton width='90%' height={30} />
                </Grid>
                <Grid item xs={8}>
                  <Skeleton width='90%' height={30} />
                </Grid>
                <Grid item xs={2} paddingX={2.5} paddingY={1}>
                  <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                    {Array(CONTENT_SKELETON_LENGTH).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant='circular'
                        width={20}
                        height={20}
                        sx={{ display: 'flex', margin: 0.5 }}
                      />
                    ))}
                  </Stack>
                </Grid>
                {i < DESKTOP_SKELETON_LENGTH - 1 && <Divider variant='middle' />}
              </Grid>
            )}
          </div>
        ))}
      </div>
    </Fade>
  );
};

export { NewsFeedTileLoadingState };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Callout } from '@swyftx/aviary/atoms/Typography/Heading/Callout';
import { SwyftxFull } from '@swyftx/aviary/icons/outlined';

const SingleCategoryLearnCardLogo: React.FC = () => (
  <FlexLayout alignItems='center' justifyContent='space-between' spacing={8}>
    <SwyftxFull className='h-16 text-color-text-primary' />
    <div className='h-20 w-1 bg-color-border-main' />
    <Callout className='text-[14px] text-color-text-accent'>Learn</Callout>
  </FlexLayout>
);

export { SingleCategoryLearnCardLogo };

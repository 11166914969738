import { DepositMethodEnum, OrderSourceEnum } from '@shared/enums';

export enum AddressDetailsKeyEnum {
  BSB = 'bsb',
  Address = 'address',
}

export enum DepositAddressDetailsOptionalKeyEnum {
  Reference = 'reference',
  PayId = 'payid',
}

export enum MfaType {
  EMAIL = 'email',
  PHONE = 'sms',
}

interface DepositAddressEndpointParams {
  code: string;
  variant: DepositMethodEnum;
  amount?: number;
}

export type GetDepositAddressesParams = DepositAddressEndpointParams;

export type GenerateDepositAddressParams = DepositAddressEndpointParams;

export interface GenerateDepositAddressPayload {
  address: {
    name: string;
  };
}

export interface CryptoAddressesResponse {
  addresses: CryptoAddress[];
}

export interface CryptoAddressDetails {
  address: string;
  metadata?: string;
}

export interface CryptoAddress {
  networkId: string;
  address_details: CryptoAddressDetails;
  legacyAddressExists: boolean;
}

type DepositAddressDetailsType = {
  [key in AddressDetailsKeyEnum]: string;
};

type DepositAddressDetailsOptionalType = {
  [key in DepositAddressDetailsOptionalKeyEnum]: string;
};

export interface DepositAddressDetails extends DepositAddressDetailsType, DepositAddressDetailsOptionalType {}

export interface DepositAddressResponse {
  id: number;
  assetId: number;
  variant: DepositMethodEnum;
  created: number;
  type: string;
  redirectUrl?: string;
  address_details: DepositAddressDetails;
}

export interface GenerateDepositAddressResponse {
  address: DepositAddressResponse;
}

export interface GetDepositAddressesResponse {
  addresses: [DepositAddressResponse];
}

export interface GetDepositHistoryParams {
  code: string;
}

export interface GetDepositHistoryPayload {
  limit: number;
  from: number;
  to: number;
  ignoreDateFilter: boolean;
  page: number;
  sortBy: string;
}

export interface DepositHistoryResponse {
  address_details: DepositAddressDetails;
  amount: number;
  assetId: number;
  audValue: number;
  created: number;
  credited_amount?: number;
  credited_asset?: number;
  feeAmount?: number;
  feeAssetId?: number;
  feeAudValue?: number;
  label: string;
  status: number;
  txFeeAmount?: number;
  txFeeAssetId?: number;
  txFeeUserCurrencyValue?: number;
  updated: number;
  depositId: string;
}

export interface VerifyBSBParams {
  bsb: string;
}

export interface VerifyBSBResponse {
  durationMs: number;
  status: string;
  statusDescription: string;
  address: string;
  bankCode: string;
  bsb: string;
  city: string;
  closed: boolean;
  postCode: string;
  state: string;
}

export interface WithdrawalPermissionsParams {
  assetCode: string;
}

export interface WithdrawalPermissionsResponse {
  canWithdraw: boolean;
  message: string;
}

export interface GetWithdrawAddressesParams {
  code: string;
}

export interface WithdrawAddressDetails {
  address: string;
  network: string;
  nameOnAccount: string;
  bsb: string;
}

export interface WithdrawAddressResponse {
  id: number;
  code: number;
  address_details: WithdrawAddressDetails;
  label: string;
  verified: boolean;
  created: number;
}

export interface GetWithdrawAddressesResponse {
  address: WithdrawAddressResponse[];
}

export enum WithdrawalAddressDetailsOptionalKeyEnum {
  Memo = 'memo',
  DestinationTag = 'destination_tag',
  Message = 'message',
  PaymentId = 'payment_id',
}

type WithdrawalAddressDetailsOptionalType = {
  [key in WithdrawalAddressDetailsOptionalKeyEnum]?: string;
};

type WithdrawalAddressDetailsType = {
  [key in AddressDetailsKeyEnum]: string;
};

export interface WithdrawalAddressDetails extends WithdrawalAddressDetailsType, WithdrawalAddressDetailsOptionalType {}

export interface WithdrawalLimitResponse {
  limits: {
    used: string;
    remaining: string;
    limit: string;
    reducedForPromisedDeposits: boolean;
    rollingCycleHrs: number;
  };
}

export interface GetWithdrawalHistoryParams {
  code: string;
}

export interface GetWithdrawalHistoryPayload {
  limit: number;
  from: number;
  to: number;
  ignoreDateFilter: boolean;
  page: number;
  sortBy: string;
}

export interface WithdrawalHistoryResponse {
  address_details: WithdrawalAddressDetails;
  amount: number;
  assetId: number;
  audValue: number;
  created: number;
  credited_amount?: number;
  credited_asset?: number;
  feeAmount?: number;
  feeAssetId?: number;
  feeAudValue?: number;
  label: string;
  status: number;
  transactionId?: number;
  txFeeAmount?: number;
  txFeeAssetId?: number;
  txFeeUserCurrencyValue?: number;
  updated: number;
  withdrawalId: string;
}

export interface AddWithdrawAddressParams {
  code: string;
}

export interface AddWithdrawAddressPayload {
  address: {
    label: string;
    address_details: {
      address: string;
      nameOnAccount?: string;
      bsb?: string;
      networkId?: number;
      memo?: string;
      destinationTag?: string;
    };
  };
  auth?: string;
}

export interface AddWithdrawAddressResponse {
  success: boolean;
  mfaType: 'email' | 'sms';
  token: string;
}

export interface CreateWithdrawalParams {
  code: string;
}

export interface CreateWithdrawalPayload {
  quantity: string;
  address_id: number;
  reason: number;
  reasonText: string;
  auth?: string;
}

export interface WithdrawalResponse {
  smsVerificationRequired?: boolean;
  token?: string;
}

export interface RemoveWithdrawalAddressParams {
  code: string; // only used for intercom event
  id: number;
}

export interface PairExchangeRateParams {
  buy: string;
  sell: string;
  amount?: number;
  limit?: string;
  pro?: boolean;
}

export interface PairExchangeRateResponseError {
  message: string;
  maximumPercent: string;
}

export interface PairExchangeRateResponse {
  amount: string;
  feePerUnit: string;
  price: string;
  total: string;
}

export interface CreateOrderParams {
  assetQuantity: string;
  orderType: number;
  primary: string;
  quantity: string;
  secondary: string;
  trigger?: string;
  orderSource?: OrderSourceEnum;
  orderSourceUuid?: string;
  pro?: 1 | 0;
}

export interface UpdateOrderParams {
  primary: string;
  secondary: string;
  orderUuid: string;
  quantity: string;
  assetQuantity: string;
  trigger: string;
  orderType: number;
  pro?: boolean;
}

export interface CreateOrderResponse {
  order_id: number;
  orderUuid: string;
  order: Order; // TODO: this technically can be undefined...
  processed: boolean;
}

export interface CreateBundleResult {
  orderUuid: string;
  placed: boolean;
  processed: boolean;
}

export interface UUID {
  type: string;
  data: number[];
}

export interface Order {
  _id: number;
  user_id: number;
  orderUuid: UUID;
  order_type: number;
  primary_asset: number;
  secondary_asset: number;
  quantity_asset: number;
  quantity: number;
  trigger: number;
  invoice_id?: any;
  status: number;
  created_time: number;
  updated_time: number;
  amount: number;
  total: number;
  rate: number;
  commission_id?: any;
  aud_value: number;
  swyftxValue: number;
  userCountryValue: number;
}

/*
export interface Order {
  amount?: Big;
  aud_value?: Big;
  created_time: number;
  order_type: number;
  primary_asset: number;
  quantity: Big;
  quantity_asset: number;
  rate?: Big;
  secondary_asset: number;
  status: OrderStatus;
  swyftxValue?: Big;
  total?: Big;
  trigger?: Big; // not on market order
  updated_time: number;
  userCountryValue?: Big;
}
*/

export interface SwapPayload {
  buy: number; // buy asset id
  sell: number; // sell asset id
  limitAsset: number; // sell asset id
  limitQty: number;
  intermediateAssetId?: number; // profile currency id
}

type ProcessedSwapOrder = {
  orderUuid: Buffer;
  order_type: number;
  primary_asset: number;
  secondary_asset: number;
  quantity: number;
  quantity_asset: number;
  trigger: number;
  invoice_id: null | number;
  status: number;
  created_time: number;
  updated_time: number;
  amount: string;
  total: string;
  rate: number;
  commission_id: null | number;
  aud_value: number;
  swyftxValue: number;
  userCountryValue: number;
};

type SwapOrderResult = {
  orderUuid?: string;
  order?: ProcessedSwapOrder;
  processed: boolean;
  message?: string;
};

export interface SwapResponse {
  sellResult: SwapOrderResult;
  buyResult: SwapOrderResult;
}

export type TransactionHistoryResponse = WithdrawalHistoryResponse | DepositHistoryResponse;

export interface OrderbookResponseItem {
  mid: string;
  asks: string[][];
  bids: string[][];
}

export interface OrderbookResponse {
  builtAt: number;
  [assetId: string]: OrderbookResponseItem | number;
}

/* Linked Orders */
export interface LinkedOrder {
  orderType: number;
  trigger: string;
  primary: string;
  secondary: string;
  assetQuantity: string;
  quantity: string;
}

export interface CreateLinkedOrderPayload {
  orderA: LinkedOrder;
  orderB: LinkedOrder;
  linkType: 'OCO';
  orderSource?: OrderSourceEnum;
  orderSourceUuid?: string;
}

export interface CreateLinkedOrderResult {
  orderUuid: string;
  processed: boolean;
}

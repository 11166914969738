import React, { PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Heading } from '@swyftx/aviary/atoms/Typography';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';
import { useStepId } from '@routes/EntityOnboarding/hooks/useStepId';

type Props = {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onBack?: () => void;
  submitDisabled?: boolean;
  hideTitle?: boolean;
  customTitle?: string;
  customFooterLeftElement?: React.ReactElement;
  backDisabled?: boolean;
  customContinueButtonText?: string;
};

const EntityOnboardingStepContainer: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    onSubmit,
    onBack,
    hideTitle,
    customFooterLeftElement,
    submitDisabled,
    customTitle,
    backDisabled,
    customContinueButtonText,
  } = props;

  const entityOnboardingService = useEntityOnboardingService();
  const { stepStarted } = useEntityOnboardingAnalytics();
  const { stepId } = useStepId();

  const isCompleted = useEntityOnboardingSelector((state) => state.context.completedSteps[stepId]);

  useEffect(() => {
    if (!isCompleted) {
      stepStarted(stepId);
    }
  }, [isCompleted, stepId, stepStarted]);

  const canGoBack = useEntityOnboardingSelector((state) => state.can('BACK'));
  const canContinue = useEntityOnboardingSelector((state) => state.can('NEXT'));
  const hideContinueButton = useEntityOnboardingSelector((state) => state?.meta?.[stepId]?.hideContinueButton) ?? false;
  const titleBackButton = useEntityOnboardingSelector((state) => state?.meta?.[stepId]?.titleBackButton) ?? false;

  const [, setSearchParams] = useSearchParams();

  // Search params used purely for analytics & tracking purposes
  useEffect(() => {
    setSearchParams({ step: stepId });
  }, [stepId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = () => {
    entityOnboardingService.send('BACK');
  };

  const onSubmitDefault = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    entityOnboardingService.send('NEXT');
  };

  const shouldRender =
    customFooterLeftElement !== undefined || (canGoBack && !titleBackButton) || (canContinue && !hideContinueButton);

  return (
    <form onSubmit={onSubmit ?? onSubmitDefault}>
      <FlexLayout direction='column' className='h-full' spacing={16}>
        {!hideTitle && <Heading size='h3'>{customTitle ?? stepId}</Heading>}

        {children}
        {shouldRender && (
          <FlexLayout direction='row' spacing={16} className='mt-24 flex w-full justify-end'>
            {customFooterLeftElement}
            {canGoBack && !titleBackButton && (
              <Tooltip title={backDisabled ? 'Please wait until upload has finished to go back' : undefined}>
                <Button
                  onClick={onBack || handleBack}
                  disabled={backDisabled}
                  size='lg'
                  variant='outlined'
                  className='w-full'
                >
                  Back
                </Button>
              </Tooltip>
            )}
            {canContinue && !hideContinueButton && (
              <Button type='submit' disabled={submitDisabled} size='lg' className='w-full'>
                {customContinueButtonText || 'Continue'}
              </Button>
            )}
          </FlexLayout>
        )}
      </FlexLayout>
    </form>
  );
};

export { EntityOnboardingStepContainer };

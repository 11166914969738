import { useContext } from 'react';

import { SwyftxProTradeContext } from '../context';

const useSwyftxProLayout = () => {
  const { showOrderBook, showOrdersAndBalances, setShowOrderBook, setShowOrdersAndBalances } =
    useContext(SwyftxProTradeContext);

  return {
    showOrderBook,
    showOrdersAndBalances,
    setShowOrderBook,
    setShowOrdersAndBalances,
  };
};

export { useSwyftxProLayout };

import { useCallback, useMemo } from 'react';

import { Asset } from '@shared/api';

import { SwyftxTradeDirection } from '../../../types/Trade.types';

type Props = {
  sellAsset?: Asset;
  limitAsset?: Asset;
  tradeDirection: SwyftxTradeDirection;
  total: string;
  amount: string;
  setTotal: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
};

const useTradeQuoteErrorValue = ({
  sellAsset,
  limitAsset,
  tradeDirection,
  total,
  amount,
  setAmount,
  setTotal,
}: Props) => {
  const activeValue = useMemo(() => {
    if (tradeDirection === 'buy') {
      if (limitAsset?.code === sellAsset?.code) {
        return total;
      } else {
        return amount;
      }
    } else {
      if (limitAsset?.code === sellAsset?.code) {
        return amount;
      } else {
        return total;
      }
    }
  }, [amount, limitAsset?.code, sellAsset?.code, total, tradeDirection]);

  const updateActiveValue = useCallback(
    (value: string) => {
      if (tradeDirection === 'buy') {
        if (limitAsset?.code === sellAsset?.code) {
          setTotal(value);
        } else {
          setAmount(value);
        }
      } else {
        if (limitAsset?.code === sellAsset?.code) {
          setAmount(value);
        } else {
          setTotal(value);
        }
      }
    },
    [limitAsset?.code, sellAsset?.code, setAmount, setTotal, tradeDirection],
  );

  return {
    activeValue,
    updateActiveValue,
  };
};

export { useTradeQuoteErrorValue };

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { EntityType } from '@shared/api';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { useQueryMetadata } from '@hooks/UseQueryMetadata/useQueryMetadata';

import { CallToActionLocationValueType, CallToActionNameValueType, TileName } from 'src/context/Avo/generated-avo';

import { MemberType } from '../types/EntityApplicationForm.types';
import { EntityOnboardingStepId } from '../types/EntityOnboarding.types';

export const useEntityOnboardingAnalytics = () => {
  const { pathname } = useLocation();
  const { userProfile } = UserStore.useUserStore;
  const avo = useAvo();
  const { platform } = useQueryMetadata();

  const convertTypesToAvoEntityTypes = useCallback((entityType: EntityType) => {
    switch (entityType) {
      case 'COMPANY':
        return 'COMPANY';
      case 'TRUST_INDIVIDUAL':
      case 'TRUST_CORPORATE':
        return 'TRUST';
      case 'SMSF_INDIVIDUAL':
      case 'SMSF_CORPORATE':
        return 'SMSF';
      default:
        entityType satisfies never;
    }
  }, []);

  const convertMemberTypeToAvoMemberType = useCallback((memberType: MemberType) => {
    switch (memberType) {
      case 'director':
        return 'Director';
      case 'shareholder':
        return 'Shareholder';
      case 'trustee':
        return 'Individual Trustee';
      case 'beneficiary':
      default:
        return 'Beneficiary';
    }
  }, []);

  const startedApplication = useCallback(() => {
    avo.entityApplicationStarted({
      screen: pathname,
      platform,
    });
  }, [avo, pathname, platform]);

  const stepStarted = useCallback(
    (stepId: EntityOnboardingStepId, otherParams?: { entityType?: EntityType }) => {
      const entityType = otherParams?.entityType;
      avo.entityApplicationStepStarted({
        screen: pathname,
        stepId,
        entityType: entityType ? convertTypesToAvoEntityTypes(entityType) : undefined,
        platform,
      });
    },
    [avo, pathname, convertTypesToAvoEntityTypes, platform],
  );

  const stepCompleted = useCallback(
    (stepId: EntityOnboardingStepId, otherParams?: { entityType?: EntityType }) => {
      const entityType = otherParams?.entityType;
      avo.entityApplicationStepCompleted({
        screen: pathname,
        stepId,
        entityType: entityType ? convertTypesToAvoEntityTypes(entityType) : undefined,
        platform,
      });
    },
    [avo, pathname, convertTypesToAvoEntityTypes, platform],
  );

  const entityTypeChanged = useCallback(
    (entityType: EntityType) => {
      avo.entityTypeChanged({
        screen: pathname,
        entityType: convertTypesToAvoEntityTypes(entityType)!,
        platform,
      });
    },
    [avo, convertTypesToAvoEntityTypes, pathname, platform],
  );

  const applicationSubmitted = useCallback(
    (params: {
      entityType: EntityType;
      numberOfDirectors?: number;
      numberOfShareholders?: number;
      numberOfTrustees?: number;
      numberOfCorporateTrustees?: number;
      numberOfBeneficiaries?: number;
      numberOfDocumentsUploaded: number;
    }) => {
      avo.entityApplicationSubmitted({
        screen: pathname,
        entityType: convertTypesToAvoEntityTypes(params.entityType),
        numberOfDirectors: params.numberOfDirectors,
        numberOfShareholders: params.numberOfShareholders,
        numberOfTrustees: params.numberOfTrustees,
        numberOfCorporateTrustees: params.numberOfCorporateTrustees,
        numberOfBeneficiaries: params.numberOfBeneficiaries,
        numberOfDocumentsUploaded: params.numberOfDocumentsUploaded,
        platform,
      });
    },
    [avo, pathname, convertTypesToAvoEntityTypes, platform],
  );

  const gettingStartedTileOpened = useCallback(() => {
    avo.gettingStartedTileOpened({
      screen: pathname,
      tileName: TileName.CREATE_ENTITY_ACCOUNT,
    });
  }, [avo, pathname]);

  const applicationExited = useCallback(
    (stepId: EntityOnboardingStepId) => {
      avo.entityApplicationExited({
        screen: pathname,
        stepId,
        platform,
      });
    },
    [avo, pathname, platform],
  );

  const applicationCancelled = useCallback(
    (stepId: string) => {
      avo.entityApplicationCancelled({
        stepId,
        platform,
      });
    },
    [avo, platform],
  );

  const entityCallToActionClicked = useCallback(
    (params: { callToActionName: CallToActionNameValueType }) => {
      avo.entityCtaClicked({
        screen: pathname,
        callToActionName: params.callToActionName,
        platform,
      });
    },
    [avo, pathname, platform],
  );

  const entityMemberAdded = useCallback(
    (params: { stepId: EntityOnboardingStepId; memberType: MemberType; entityType: EntityType }) => {
      const { stepId, memberType, entityType } = params;
      const avoMemberType = convertMemberTypeToAvoMemberType(memberType);
      avo.entityMemberAdded({
        screen: pathname,
        stepId,
        entityType: convertTypesToAvoEntityTypes(entityType)!,
        memberType: avoMemberType,
        platform,
      });
    },
    [avo, convertMemberTypeToAvoMemberType, convertTypesToAvoEntityTypes, pathname, platform],
  );

  const entityMemberEdited = useCallback(
    (params: { stepId: EntityOnboardingStepId; memberType: MemberType; entityType: EntityType }) => {
      const { stepId, memberType, entityType } = params;
      const avoMemberType = convertMemberTypeToAvoMemberType(memberType);
      avo.entityMemberEdited({
        screen: pathname,
        stepId,
        entityType: convertTypesToAvoEntityTypes(entityType)!,
        memberType: avoMemberType,
        platform,
      });
    },
    [avo, convertMemberTypeToAvoMemberType, convertTypesToAvoEntityTypes, pathname, platform],
  );

  const entityMemberRemoved = useCallback(
    (params: { stepId: EntityOnboardingStepId; memberType: MemberType; entityType: EntityType }) => {
      const { stepId, memberType, entityType } = params;
      const avoMemberType = convertMemberTypeToAvoMemberType(memberType);
      avo.entityMemberEdited({
        screen: pathname,
        stepId,
        entityType: convertTypesToAvoEntityTypes(entityType)!,
        memberType: avoMemberType,
        platform,
      });
    },
    [avo, convertMemberTypeToAvoMemberType, convertTypesToAvoEntityTypes, pathname, platform],
  );

  const supportLinkClicked = useCallback(
    (params: { label: string; url: string }) => {
      const { label, url } = params;
      avo.supportLinkClicked({
        supportLinkLabel: label,
        supportLinkUrl: url,
      });
    },
    [avo],
  );

  const phoneConsultLinkCtaClicked = useCallback(
    (params: {
      entityType?: EntityType;
      stepId?: EntityOnboardingStepId;
      callToActionLocation: CallToActionLocationValueType;
    }) => {
      const { entityType, stepId, callToActionLocation } = params;
      const applyForEntityAccount = !!userProfile?.userSettings.applyForEntityAccount;

      avo.entityPhoneConsultationCtaClicked({
        screen: pathname,
        entityType: entityType ? convertTypesToAvoEntityTypes(entityType)! : null,
        applyForEntityAccount,
        stepId,
        callToActionLocation,
      });
    },
    [avo, convertTypesToAvoEntityTypes, pathname, userProfile?.userSettings.applyForEntityAccount],
  );

  const appHeaderEntityInfoButtonHidden = useCallback(() => {
    const applyForEntityAccount = !!userProfile?.userSettings.applyForEntityAccount;

    avo.hideCreateEntityAccountButton({
      screen: pathname,
      applyForEntityAccount,
    });
  }, [avo, pathname, userProfile?.userSettings.applyForEntityAccount]);

  return {
    startedApplication,
    stepStarted,
    stepCompleted,
    applicationSubmitted,
    gettingStartedTileOpened,
    applicationExited,
    applicationCancelled,
    entityTypeChanged,
    entityMemberAdded,
    entityMemberEdited,
    entityMemberRemoved,
    supportLinkClicked,
    entityCallToActionClicked,
    phoneConsultLinkCtaClicked,
    appHeaderEntityInfoButtonHidden,
  };
};

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Paper: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M20.4 7.56L15.3 2.24C15.16 2.09 14.96 2.01 14.76 2.01H14.55C14.55 2.01 14.51 2 14.49 2C14.47 2 14.45 2.01 14.43 2.01H8.05C5.55 2.01 3.44 4.08 3.44 6.52V17.31C3.44 19.94 5.47 22 8.05 22H16.1C18.54 22 20.61 19.85 20.61 17.31V8.08C20.61 7.89 20.53 7.7 20.4 7.56ZM18.29 7.53C17.91 7.53 17.5 7.53 17.08 7.53C16.07 7.53 15.24 6.7 15.24 5.69V4.36L18.29 7.54V7.53ZM16.1 20.5H8.05C6.3 20.5 4.94 19.1 4.94 17.31V6.52C4.94 4.89 6.36 3.51 8.05 3.51H13.74V5.68C13.74 7.52 15.24 9.02 17.07 9.02C17.85 9.02 18.42 9.02 18.82 9.02C18.93 9.02 19.02 9.02 19.1 9.02V17.31C19.1 19.01 17.69 20.5 16.09 20.5H16.1ZM15.05 15.65C15.05 16.06 14.71 16.4 14.3 16.4H8.86C8.45 16.4 8.11 16.06 8.11 15.65C8.11 15.24 8.45 14.9 8.86 14.9H14.3C14.71 14.9 15.05 15.24 15.05 15.65ZM8.11 10.66C8.11 10.25 8.45 9.91 8.86 9.91H12.24C12.65 9.91 12.99 10.25 12.99 10.66C12.99 11.07 12.65 11.41 12.24 11.41H8.86C8.45 11.41 8.11 11.07 8.11 10.66Z'
      fill='currentColor'
    />
  </svg>
);

export { Paper };

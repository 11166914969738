import React from 'react';

import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
  variant?: 'default' | 'assetList';
};

const AssetBuyPrice: React.FC<Props> = observer(({ asset, variant = 'default' }) => {
  const baseAsset = useBaseAsset();
  const { getRate } = RatesStore.useRatesStore;

  const rate = getRate(asset);

  // We want to show the mid price for the asset picker
  if (variant === 'assetList') {
    return (
      <Numeric size='small' className='truncate text-right'>
        {formatCurrency(rate.midPrice, baseAsset, { appendCode: true })}
      </Numeric>
    );
  }

  return (
    <Numeric size='small' className='truncate text-right'>
      {formatCurrency(rate.askPrice, baseAsset)}
    </Numeric>
  );
});

export { AssetBuyPrice };

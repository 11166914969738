import React, { useCallback } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { VerifiedFilled } from '@swyftx/aviary/icons/filled';
import { CrossInCircle, Vip } from '@swyftx/aviary/icons/outlined';

import { Profile } from '@shared/api';
import { UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { SwyftxProFeeTiersModal } from 'src/lib/trade-pro/components/SwyftxProFeeTiersModal/SwyftxProFeeTiersModal';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

type Props = {
  user?: Profile;
  isEntity?: boolean;
  isUserVerified?: boolean;
  onClick?: () => void;
};

const AppHeaderMenuProfile: React.FC<Props> = observer(({ user, isEntity, isUserVerified, onClick }) => {
  const { navigate } = useNavigateRoute();
  const { userProfile } = UserStore.useUserStore;
  const { isSwyftxPro } = useSwyftxPro();

  const feeTierLabel = userProfile?.currentFeeTier ?? 'Regular';

  const accountType = isEntity ? 'Entity account' : 'Personal account';

  const handleOnClick = useCallback(() => {
    if (isSwyftxPro) return;
    if (onClick) onClick();
    navigate(NavigationURLs.ProfileInformation);
  }, [isSwyftxPro, navigate, onClick]);

  return (
    <FlexLayout direction='column' spacing={8} className='w-full'>
      <FlexLayout
        direction='row'
        spacing={12}
        className={cn(
          isSwyftxPro ? 'cursor-default' : 'cursor-pointer hover:bg-color-background-surface-hover ',
          'items-center rounded-8 p-8 ',
          'PII',
        )}
        id='profile-menu-profile'
        data-spotlightelementid='profile-menu-profile'
        onClick={handleOnClick}
      >
        <Avatar text={user?.name?.first ? user.name.first[0] : '?'} size='lg' />
        <FlexLayout direction='column'>
          <Body color='primary' weight='emphasis' size='small'>{`${user?.name.first} ${user?.name.last}`}</Body>
          <Body color='secondary' size='xsmall'>
            {user?.email ?? accountType}
          </Body>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='row' spacing={8} className='ml-8 pb-8'>
        <Chip
          size='sm'
          color={isUserVerified ? 'success' : 'destructive'}
          leadingIcon={
            isUserVerified ? <VerifiedFilled className='h-16 w-16 text-color-text-success' /> : <CrossInCircle />
          }
          variant='subtle'
          className={isSwyftxPro ? 'cursor-default' : 'cursor-pointer'}
          onClick={() => !isSwyftxPro && navigate(NavigationURLs.ProfileVerification)}
        >
          {isUserVerified ? 'Verified' : 'Unverified'}
        </Chip>
        {feeTierLabel && (
          <SwyftxProFeeTiersModal>
            <Chip
              size='sm'
              leadingIcon={<Vip />}
              variant='subtle'
              onClick={() => !isSwyftxPro && navigate(NavigationURLs.ProfileTradeFees)}
              className='cursor-pointer'
            >
              {feeTierLabel}
            </Chip>
          </SwyftxProFeeTiersModal>
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

export { AppHeaderMenuProfile };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { HeroInput } from '@swyftx/aviary/molecules/HeroInput';

import { Asset, AssetType } from '@shared/api';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { PriceAlertInputFooterContent } from './PriceAlertInputFooterContent';
import { usePriceAlertInput } from './usePriceAlertInput';
import {
  CreatePriceAlertType,
  PriceAlert,
  PriceAlertPriceTabs,
  PriceAlertTriggerType,
  PriceAlertTriggerTypeTabs,
  PriceAlertWindow,
  PriceAlertWindowTabs,
} from '../../dynamicPriceAlerts.types';

type Props = {
  value: string;
  alertWindow?: PriceAlertWindow;
  setAlertWindow?: React.Dispatch<React.SetStateAction<PriceAlertWindow | undefined>>;
  primaryAsset: Asset;
  secondaryAsset: Asset;
  assets?: Asset[];
  alertType: PriceAlertTriggerType;
  type?: CreatePriceAlertType;
  loading?: boolean;
  disabled?: boolean;
  alert?: PriceAlert;
  setAlertType?: React.Dispatch<React.SetStateAction<PriceAlertTriggerType>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onActionClick: () => void;
};

const PriceAlertInput: React.FC<Props> = observer(
  ({
    value,
    alertWindow,
    setAlertWindow,
    setAlertType,
    primaryAsset,
    alertType,
    type = 'single',
    secondaryAsset,
    assets,
    loading,
    alert,
    disabled,
    setValue,
    onActionClick,
  }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const { t } = useTranslation('priceAlerts');

    const effectiveAlertType = useMemo((): PriceAlertTriggerType => {
      if (type === 'multi') return 'VOLATILITY';

      return alertType;
    }, [alertType, type]);

    const {
      currentPrice,
      placeholder,
      formattedAmount,
      onSelectPercentageTab,
      priceDiffPercentage,
      priceDirection,
      priceTitle,
      error,
      onChangeValue,
    } = usePriceAlertInput({
      value,
      secondaryAsset,
      primaryAsset,
      alert,
      alertWindow,
      alertType: effectiveAlertType,
      setValue,
    });

    return (
      <FlexLayout direction='column' spacing={32} className='w-full' alignItems='center'>
        {type === 'single' && isFeatureEnabled(AppFeature.DynamicPriceAlertsRelease2) && (
          <EnhancedTabs<PriceAlertTriggerType>
            value={alertType}
            tabs={PriceAlertTriggerTypeTabs}
            variant='default'
            fullWidth
            onChange={setAlertType}
          />
        )}

        {effectiveAlertType === 'LIMIT' && (
          <Numeric color='accent' weight='bold' className='w-full text-center'>
            {t('labels.currentPrice', {
              price: formatCurrency(currentPrice, primaryAsset, { appendCode: true, hideCode: false }),
            })}
          </Numeric>
        )}

        <FlexLayout direction='column' spacing={16} className='w-full' alignItems='center'>
          <Body color='secondary' size='small' className='w-full text-center'>
            {priceTitle}
          </Body>

          <HeroInput
            leading={primaryAsset.assetType === AssetType.Fiat && effectiveAlertType === 'LIMIT' ? '$' : undefined}
            trailing={effectiveAlertType === 'VOLATILITY' ? '%' : undefined}
            asset={secondaryAsset}
            value={formattedAmount}
            onChange={onChangeValue}
            placeholder={placeholder}
            error={!!error}
          />
        </FlexLayout>

        <FlexLayout className='w-full' alignItems='center' justifyContent='center'>
          {effectiveAlertType === 'LIMIT' ? (
            <EnhancedTabs value='' tabs={PriceAlertPriceTabs} onChange={onSelectPercentageTab} variant='child' />
          ) : (
            alertWindow && (
              <EnhancedTabs<PriceAlertWindow>
                value={alertWindow}
                tabs={PriceAlertWindowTabs}
                disabled={setAlertWindow === undefined}
                onChange={setAlertWindow}
                size='sm'
                variant='default'
              />
            )
          )}
        </FlexLayout>
        <FlexLayout
          alignItems='center'
          spacing={4}
          className={(priceDiffPercentage && priceDirection) || error ? 'visible' : 'invisible'}
        >
          <PriceAlertInputFooterContent
            type={effectiveAlertType}
            secondaryCode={secondaryAsset.code}
            priceDirection={priceDirection}
            priceDiffPercentage={priceDiffPercentage}
            alertWindow={alertWindow}
            value={value}
            assets={assets}
            error={error}
          />
        </FlexLayout>
        <Button className='w-full' size='lg' disabled={disabled || !!error} onClick={onActionClick} loading={loading}>
          {alert ? t('buttons.updateAlert') : t('buttons.createAlert')}
        </Button>
      </FlexLayout>
    );
  },
);

export { PriceAlertInput };

import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { SwyftxFull } from '@swyftx/aviary/icons/outlined';

import { RegisterContext } from '@routes/Register/Register.context';

import { Affiliates } from '@Register/Register.data';

import { observer } from 'mobx-react-lite';

import { RegisterAffiliateChip } from './RegisterAffiliateChip';

const RegisterAffiliateBar: React.FC = observer(() => {
  const { referralCode, promoRef, affiliate } = useContext(RegisterContext);

  return (
    <FlexLayout
      id='register.referralCode.container.mobile'
      direction='column'
      justifyContent='center'
      alignItems='center'
      className='flex w-full sm:hidden'
    >
      <div className='mb-16 mt-8'>
        {affiliate ? (
          Affiliates[affiliate].miniLogo
        ) : (
          <SwyftxFull id='loginCarousel.logo.dark.image' className='h-32' />
        )}
      </div>

      {promoRef && <RegisterAffiliateChip type='promoCode' />}
      {referralCode && <RegisterAffiliateChip type='referralCode' />}
    </FlexLayout>
  );
});

export { RegisterAffiliateBar };

import React, { useMemo } from 'react';

import { Body } from '@swyftx/aviary/atoms/Typography';
import { formatCurrency } from '@swyftx/currency-util';

import { AssetType, HistoricalOrder } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';

import { getOrderSide } from 'src/lib/UniversalTrade/components/OrdersTile/utils';

interface Props {
  order: HistoricalOrder;
}

export const OrderTriggerPrice: React.FC<Props> = (props) => {
  const { order } = props;
  const side = getOrderSide(order.order_type);
  const primaryAsset = assetService.getAsset(order?.primary_asset);
  const secondaryAsset = assetService.getAsset(order?.secondary_asset);
  const isFiat = primaryAsset?.assetType === AssetType.Fiat;
  const suffix = useMemo(() => `${primaryAsset?.code}/${secondaryAsset?.code}`, [primaryAsset, secondaryAsset]);

  if (!order?.trigger) return null;

  if (side === 'Buy') {
    return (
      <Body color='secondary'>{`${formatCurrency(order.trigger.toString(), {
        isFiat,
      })} ${suffix}`}</Body>
    );
  }

  return (
    <Body color='secondary'>{`${formatCurrency(Big(1).div(Big(order.trigger)).toString(), {
      isFiat,
    })} ${suffix}`}</Body>
  );
};

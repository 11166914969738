export enum CryptoEnum {
  USDT = 2,
  BTC = 3,
  ETH = 5,
  ADA = 12,
  USDC = 53,
  TUSD = 55,
  BUSD = 95,
  APPC = 260,
  RDN = 236,
  EVX = 234,
  USDP = 319,
  UST = 386,
  TAUD = 400,
}

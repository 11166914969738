import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Chip } from '@swyftx/react-web-design-system';

import { GreenIdStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { PermissionConfig } from './permissions.types';

export const usePermissions = ({
  accountStatus,
  enabledOptions = {},
  disabledOptions = {},
  needsUnrestricted,
  enabledElement,
  permissions,
}: PermissionConfig) => {
  const { scopeArray, isRestricted, getAccountStatus, isEntity, getEntityMember } = UserStore.useUserStore;
  const { t } = useTranslation('common');

  const validEntity = isEntity() ? getEntityMember()?.verified === GreenIdStatusEnum.VERIFIED : false;
  const scopesToCheck = isEntity() ? getEntityMember()?.scope : scopeArray;
  const validScope = permissions ? permissions.every((permission) => scopesToCheck?.includes(permission)) : true;
  const validKYC = validEntity || (accountStatus ? accountStatus <= getAccountStatus() : true);
  const enabled = validScope && validKYC && !(needsUnrestricted && isRestricted());

  const renderElement = (props: any) => {
    if (!enabledElement) return;

    return React.cloneElement(enabledElement, {
      ...props,
      sx: {
        ...enabledElement.props.sx,
        ...props.sx,
      },
    });
  };

  const element = useMemo(() => {
    if (enabled) {
      const { tooltip } = enabledOptions;

      if (tooltip)
        return (
          <Tooltip title={tooltip}>
            <span>{renderElement({})}</span>
          </Tooltip>
        );

      return enabledElement;
    }

    const {
      chip,
      customDisabledElement,
      disabled,
      hidden,
      invalidScopeLabel,
      invalidKYCLabel,
      tooltip,
      isRestrictedLabel,
    } = disabledOptions;

    const label =
      (needsUnrestricted && isRestricted() && isRestrictedLabel) ||
      (!validScope && invalidScopeLabel) ||
      (!validKYC && invalidKYCLabel) ||
      t('permissions.noAccess');

    if (hidden) return null;

    if (customDisabledElement) return customDisabledElement;

    if (chip) return <Chip sx={{ color: 'text.secondary' }} label={label} />;

    if (tooltip) {
      return (
        <Tooltip title={label}>
          <span>{renderElement({ disabled })}</span>
        </Tooltip>
      );
    }

    return renderElement({ disabled });
  }, [enabled, t, enabledElement]);

  return {
    element,
    enabled,
    validKYC,
    validScope,
    isRestricted: isRestricted(),
  };
};

import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ReceiveCrypto, SendCrypto, Wallet } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { AppStore, UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const LOG_TAG = 'APP_HEADER';

const AppHeaderTransferButton: React.FC = observer(() => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const countryAsset = useCountryAsset();
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const { navigate } = useNavigateRoute();
  const { isKyc1Complete, userProfile } = UserStore.useUserStore;
  const { isDemo } = AppStore.useAppStore;
  const isKyc1RequiredForCryptoTransactions = userProfile?.metadata?.cryptoRequiresKyc1 === true;
  const portfolioTransferEnabled = isFeatureEnabled(AppFeature.PortfolioTransfer);
  const avo = useAvo();

  const handleDepositClicked = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
  };

  const handleWithdrawalClicked = () => {
    avo.withdrawFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.WithdrawSend, { selectedAsset: countryAsset });
  };

  const handleOpenAssetMigrator = () => {
    navigate(NavigationURLs.PortfolioTransfer);
  };

  if (isDemo) {
    return (
      <Tooltip title='Transfers are unavailable in Demo Mode'>
        <FlexLayout className='cursor-pointer rounded-8 p-6'>
          <Button disabled={isDemo} size='md'>
            Transfer Funds
          </Button>
        </FlexLayout>
      </Tooltip>
    );
  }

  return (
    <Menu
      triggerContent={
        <FlexLayout className='cursor-pointer rounded-8 px-6'>
          <Button size='md'>Transfer Funds</Button>
        </FlexLayout>
      }
    >
      <MenuItem
        action={handleDepositClicked}
        text='Deposit'
        description='Deposit fiat or crypto'
        icon={<ReceiveCrypto />}
      />
      <MenuItem
        action={handleWithdrawalClicked}
        text='Withdraw'
        description='Withdraw fiat or crypto'
        icon={<SendCrypto />}
      />
      {portfolioTransferEnabled && (
        <MenuItem
          action={handleOpenAssetMigrator}
          text='Transfer Portfolio to Swyftx'
          description={
            <Body
              size='xsmall'
              color={isKyc1RequiredForCryptoTransactions && !isKyc1Complete() ? 'error' : 'secondary'}
            >
              {isKyc1RequiredForCryptoTransactions && !isKyc1Complete()
                ? 'Requires account verification'
                : 'Use API Keys to transfer portfolio from one exchange directly into Swyftx.'}
            </Body>
          }
          icon={<Wallet />}
        />
      )}
    </Menu>
  );
});

export { AppHeaderTransferButton };

import React, { PropsWithChildren, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

const SwyftxProOrderTypesModal: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [open, setOpen] = useState<boolean>();
  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      open={open}
      position={isXs ? 'bottom' : 'center'}
      triggerElement={children}
      title='Order types explained'
      showCloseButton
      className='w-full md:!min-w-[31.25rem] md:!max-w-[31.25rem]'
      onOpenChange={setOpen}
    >
      <FlexLayout spacing={24} direction='column' className='px-24 pb-24'>
        <FlexLayout spacing={4} direction='column'>
          <Body weight='emphasis'>Market orders</Body>
          <Body color='secondary'>This order will buy or sell immediately at the markets best available price.</Body>
        </FlexLayout>
        <FlexLayout spacing={4} direction='column'>
          <Body weight='emphasis'>Trigger orders</Body>
          <Body color='secondary'>
            This order will be placed when the trigger price is reached and liquidity conditions are met.
          </Body>
        </FlexLayout>
        <FlexLayout spacing={4} direction='column'>
          <Body weight='emphasis'>One Cancels the Other orders (OCO)</Body>
          <Body color='secondary'>
            This order places two linked trigger orders at the same time. When one orders conditions are met, the other
            will be cancelled automatically.
          </Body>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { SwyftxProOrderTypesModal };

import { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset, UserBalance } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import {
  PortfolioTableActions,
  PortfolioTableAllocation,
  PortfolioTableAverageBuyPrice,
  PortfolioTableBalance,
  PortfolioTableProfitLossPercentage,
  PortfolioTableProfitLossValue,
  PortfolioTableValue,
} from 'src/lib/portfolio/components/PortfolioTable';
import { usePortfolioValue } from 'src/lib/portfolio/hooks';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { PortfolioBreakdownTableData } from '../ordersTile.types';
import { AssetName } from '../tableItems/AssetName';
import { CurrentPrice } from '../tableItems/CurrentPrice';

type PortfolioBreakdownHeaderData = { [key in keyof PortfolioBreakdownTableData]: EnhancedTableHeaderData };

export const usePortfolioBreakdownTable = () => {
  const { balances } = UserStore.useUserStore;
  const { convertRate } = RatesStore.useRatesStore;
  const isXs = useTailwindBreakpoint('xs');
  const { isSwyftxPro } = useSwyftxPro();

  const baseAsset = useBaseAsset()!;
  const { getAllocationPercentage, getProfitLossValue, getProfitLossPercentage, getAveragePricePaid } =
    usePortfolioValue({
      valueAsset: baseAsset,
      side: 'midPrice',
    });

  const [sort, setSort] = useState<EnhancedTableSort<PortfolioBreakdownTableData>>({
    sortKey: 'balanceValue',
    sortDirection: 'DESC',
  });

  const headers: PortfolioBreakdownHeaderData = useMemo(
    () => ({
      asset: {
        title: 'Asset',
        alignment: 'start',
        sortable: true,
        frozen: true,
        className: ' sm:max-w-[100%] sm:max-w-[12rem]',
      },
      balance: {
        title: 'Balance',
        alignment: isXs ? 'end' : 'start',
        sortable: true,
        className: 'max-w-[8rem] sm:max-w-[100%]',
      },
      balanceValue: {
        title: 'value',
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      profitLossPercentage: {
        title: 'Change (%)',
        alignment: 'end',
        sortable: true,
      },
      profitLossValue: {
        title: 'Change ($)',
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      avgBuyPrice: {
        title: 'Avg Buy Price',
        alignment: 'start',
        sortable: true,
        className: 'hidden @lg:table-cell',
      },
      currentPrice: {
        title: 'Current Price',
        alignment: 'start',
        sortable: false,
        className: 'hidden @md:table-cell',
        tooltip: 'The current MID price of the asset',
      },
      allocation: {
        title: 'Allocation',
        alignment: 'end',
        sortable: true,
        className: 'hidden @lg:table-cell max-w-[4rem]',
      },
      actions: {
        title: 'Action',
        alignment: 'end',
        sortable: false,
        enabled: !isSwyftxPro,
        className: 'hidden @sm:table-cell max-w-[8rem]',
      },
    }),
    [isSwyftxPro, isXs],
  );

  const data: EnhancedTableData<PortfolioBreakdownTableData, Asset>[] = useMemo(
    () =>
      Object.values(balances)
        .filter((b: UserBalance) => Big(b.availableBalance).gt(0) && assetService.getAsset(b.assetId))
        .map((balance: UserBalance) => {
          const asset = assetService.getAsset(balance.assetId)!;
          const value = convertRate(asset, baseAsset, balance.availableBalance, 'midPrice');
          const profitLossValue = getProfitLossValue(asset);
          const profitLossPercentage = getProfitLossPercentage(asset);
          const averagePricePaid = getAveragePricePaid(asset);
          const allocation = getAllocationPercentage(value);

          return {
            asset: {
              value: asset.code,
              element: <AssetName asset={asset} />,
            },
            balance: {
              value: balance.availableBalance,
              element: (
                <PortfolioTableBalance
                  asset={asset}
                  balance={balance}
                  value={value}
                  baseAsset={baseAsset}
                  variant='trade'
                />
              ),
            },
            balanceValue: {
              value,
              element: <PortfolioTableValue baseAsset={baseAsset} value={value} variant='trade' />,
            },
            profitLossPercentage: {
              value: profitLossPercentage,
              element: (
                <PortfolioTableProfitLossPercentage profitLossPercentage={profitLossPercentage} variant='trade' />
              ),
            },
            profitLossValue: {
              value: profitLossValue,
              element: <PortfolioTableProfitLossValue profitLossValue={profitLossValue} variant='trade' />,
            },
            avgBuyPrice: {
              value: averagePricePaid,
              element: <PortfolioTableAverageBuyPrice averagePricePaid={averagePricePaid} variant='trade' />,
            },
            currentPrice: {
              value: asset.id,
              element: <CurrentPrice assetId={asset.id} baseAsset={baseAsset} />,
            },
            allocation: {
              value: allocation,
              element: (
                <FlexLayout justifyContent='end' className='max-h-16 items-center'>
                  <PortfolioTableAllocation asset={asset} countryAsset={baseAsset} value={value} />
                </FlexLayout>
              ),
            },
            actions: {
              element: (
                <FlexLayout className='max-h-16 items-center justify-end'>
                  <PortfolioTableActions asset={asset} countryAsset={baseAsset} />
                </FlexLayout>
              ),
            },
          };
        }),
    [
      balances,
      convertRate,
      baseAsset,
      getAllocationPercentage,
      getAveragePricePaid,
      getProfitLossPercentage,
      getProfitLossValue,
    ],
  );

  const sortItems = (
    a: EnhancedTableData<PortfolioBreakdownTableData>,
    b: EnhancedTableData<PortfolioBreakdownTableData>,
    sortingBy: EnhancedTableSort<PortfolioBreakdownTableData>,
  ) => {
    switch (sortingBy.sortKey) {
      case 'asset':
        const aVal = a[sortingBy.sortKey].value as string;
        const bVal = b[sortingBy.sortKey].value as string;
        if (sortingBy.sortDirection === 'ASC') return bVal.localeCompare(aVal);
        return aVal.localeCompare(bVal);
      case 'balance':
      case 'avgBuyPrice':
      case 'profitLossValue':
      case 'profitLossPercentage':
      case 'balanceValue':
      case 'allocation':
        const aValBig = Big(a[sortingBy.sortKey].value as string);
        const bValBig = Big(b[sortingBy.sortKey].value as string);
        if (sortingBy.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
        return bValBig.lt(aValBig) ? -1 : 1;
      case 'currentPrice':
      case 'actions':
        return 1;
      default:
        sortingBy.sortKey satisfies never;
    }
    return 1;
  };

  const filteredData = useMemo(
    () =>
      data
        .filter((d) => {
          const convertedRate = convertRate(d.currentPrice.value as number, FiatIdEnum.AUD, d.balance.value as string);
          // FE filter to remove assets with less than a dollar, noting that the BE still has the actual limit
          const balanceMatch = d.balance && convertedRate.gte(1);
          return balanceMatch;
        })
        .sort((a, b) => sortItems(a, b, sort)),
    [convertRate, data, sort],
  );

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<PortfolioBreakdownTableData>): EnhancedTableData<PortfolioBreakdownTableData>[] => {
      const tableData: EnhancedTableData<PortfolioBreakdownTableData>[] = Object.assign([], filteredData);
      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => sortItems(a, b, newSort));
    },
    [filteredData, setSort],
  );

  return {
    initialSort: sort,
    headers,
    data: filteredData,
    onSort,
  };
};

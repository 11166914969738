import { useEffect, useRef, useState } from 'react';

import { useDebounce } from 'react-use';

const useImageLoader = (delay = 0) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => setImageLoaded(imgRef.current?.complete || false), [imgRef.current?.src]);

  useDebounce(() => setLoaded(imageLoaded), delay, [imageLoaded]);

  const onLoadImage = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (imgRef.current?.complete) {
      onLoadImage();
    }
  }, []);

  return {
    imgRef,
    onLoadImage,
    loaded,
  };
};

export { useImageLoader };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';

import { Asset } from '@shared/api';
import { withdrawalService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import { observer } from 'mobx-react-lite';

import { FiatWithdrawalFormData } from './FiatWithdrawalCreateNewAccountModal.data';
import { FiatWithdrawalEmailVerifyModal } from './FiatWithdrawalEmailVerifyModal';

const CODE_LENGTH = 6;
const INITIAL_CODE_TIMEOUT = 30;
const SKIP_CODE_TIMEOUT = 60;

type Props = {
  asset: Asset;
  onClose: () => void;
  formData: FiatWithdrawalFormData;
  authToken?: string;
  onSuccess?: () => void;
};

const FiatWithdrawalPhoneVerifyModal: React.FC<Props> = observer(
  ({ asset, onClose, formData, authToken, onSuccess }) => {
    const { getMaskedPhoneNumber } = UserStore.useUserStore;
    const { t } = useTranslation('login');
    const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

    const [codeResent, setCodeResent] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [pin, setPin] = useState<string>('');
    const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);

    const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);

    useEffect(() => {
      if (pin && pin.length === CODE_LENGTH && !error) {
        handleSubmitCode();
      }
    }, [pin, error]);

    useEffect(() => {
      if (resendTime === SKIP_CODE_TIMEOUT) setCodeResent(true);
    }, [resendTime]);

    const resendCode = async () => {
      if (codeResent) {
        // maybe can skip and verify later
        return;
      }

      setResendTime(SKIP_CODE_TIMEOUT);

      if (!formData) return;

      try {
        await withdrawalService.addWithdrawAddress(
          asset.code,
          formData.accountName,
          formData.accountNumber,
          formData.accountHolderName,
          formData.bsb,
        );
      } catch (err) {
        // TODO: catch
      }
    };

    const handleSubmitCode = async () => {
      if (error || !formData) return;

      try {
        await withdrawalService.addWithdrawAddress(
          asset.code,
          formData.accountName,
          formData.accountNumber,
          formData.accountHolderName,
          formData.bsb,
          authToken,
          pin,
        );

        setPin(''); // clear input field

        if (onSuccess) {
          onSuccess();
        } else {
          setShowEmailVerifyModal(true);
        }
      } catch (err) {
        setError('Invalid code');
        // TODO should we handle this error?
        // handleAPIError(LOG_TAG, error);
      }
    };

    const handlePasteFromClipboard = async () => {
      const text = await navigator.clipboard.readText();
      setPin(text);
    };

    return (
      <>
        {!showEmailVerifyModal && (
          <Modal
            id='deposit-funds-info-modal'
            open
            sx={{ width: '600px', top: { xs: '2rem', md: 'calc(50% - 219px)' } }}
            HeaderProps={{ title: 'Verification required', dismissible: true, divider: true }}
            onClose={onClose}
          >
            <Stack direction='column' spacing={2} height='100%'>
              <Stack direction='column' spacing={3}>
                <Typography PII fontSize={16} fontWeight='400' color='text.secondary'>
                  {/* eslint-disable-next-line max-len */}
                  {`The action you are trying to complete requires SMS verification. A code will be sent to ${getMaskedPhoneNumber()}.`}
                </Typography>
                <Typography fontSize={16} fontWeight='400' color='text.secondary'>
                  Do not share this code, Swyftx will never ask you for verification details.
                </Typography>
              </Stack>

              {/* code input */}
              <Stack spacing={1}>
                <Box width='100%' alignItems='center' justifyContent='center' margin={0}>
                  <CodeInput
                    id='verify-phone-code'
                    error={error}
                    hideErrorMessage
                    hidePasteButton
                    length={CODE_LENGTH}
                    onChange={(newCode) => setPin(newCode.join(''))}
                    value={pin}
                  />
                </Box>

                <Box height='1rem'>
                  {error && (
                    <Typography color='error' fontSize={12} fontWeight='400'>
                      Invalid code
                    </Typography>
                  )}
                </Box>

                {/* buttons */}
                <Stack direction='row' alignItems='center'>
                  {hasClipboardPermission === 'granted' && (
                    <Button variant='text' onClick={() => handlePasteFromClipboard()} sx={{ marginTop: '0rem' }}>
                      <Stack direction='row' alignItems='center' height='100%'>
                        <Paste className='text-color-text-primary' /> Paste from clipboard
                      </Stack>
                    </Button>
                  )}

                  <CountdownDelay
                    id='verify-phone-countdown'
                    delay={resendTime}
                    delayText={t('codeInput.buttonLabels.resendCodeIn')}
                    restartText={t('codeInput.buttonLabels.resendCode')}
                    onTimerRestart={resendCode}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Modal>
        )}

        {/* show email verify */}
        {showEmailVerifyModal && <FiatWithdrawalEmailVerifyModal onClose={onClose} />}
      </>
    );
  },
);

export { FiatWithdrawalPhoneVerifyModal };

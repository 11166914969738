import { useContext, useMemo } from 'react';

import { useSwyftxProTradeDirection } from './useSwyftxProTradeDirection';
import { SwyftxProTradeContext } from '../context';

const useSwyftxProTradeAssets = () => {
  const { selectedAsset, baseAsset, limitAsset, setLimitAsset } = useContext(SwyftxProTradeContext);
  const { tradeDirection } = useSwyftxProTradeDirection();

  const buyAsset = useMemo(() => {
    if (tradeDirection === 'buy') {
      return selectedAsset;
    } else {
      return baseAsset;
    }
  }, [baseAsset, selectedAsset, tradeDirection]);

  const sellAsset = useMemo(() => {
    if (tradeDirection === 'buy') {
      return baseAsset;
    } else {
      return selectedAsset;
    }
  }, [baseAsset, selectedAsset, tradeDirection]);

  return {
    buyAsset,
    sellAsset,
    limitAsset,
    baseAsset,
    selectedAsset,
    setLimitAsset,
  };
};

export { useSwyftxProTradeAssets };

import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading, Utility } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  image: string;
  type: string;
  title: string;
  content: string;
  footer?: React.ReactNode;
  onClick?: () => void;
};

const AutoInvestLearnCard: React.FC<Props> = ({ className, style, image, type, title, content, footer, onClick }) => (
  <Card
    border
    shadow={false}
    className={cn(
      `min-w-[96%] cursor-pointer hover:bg-color-background-surface-hover @sm:min-w-[calc(50%-18px)] @md:min-w-[calc(33.33%-18px)]`,
      className,
    )}
    style={style}
    onClick={onClick}
  >
    <CardContent className='h-full @xs:p-16 @md:p-[24px]'>
      <FlexLayout
        direction='column'
        justifyContent='space-between'
        className='h-full w-full  cursor-pointer rounded-12'
        spacing={8}
      >
        {/* img */}
        <div className='relative flex h-[128px] items-center overflow-hidden rounded-12'>
          <Image image={image} className='absolute ' />
        </div>

        <FlexLayout direction='column' spacing={8}>
          {/* category */}

          <Utility variant='overline' color='accent'>
            {type}
          </Utility>

          {/* title */}
          <Heading size='h6'>{title}</Heading>

          {/* description */}
          <Body weight='none' size='small' className='line-clamp-[3]'>
            {content}
          </Body>
        </FlexLayout>

        {/* buttons */}
        {footer}
      </FlexLayout>
    </CardContent>
  </Card>
);

export { AutoInvestLearnCard };

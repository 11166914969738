export type Column = {
  label: string;
  hidden: boolean;
  flex: number;
  align: 'flex-start' | 'flex-end' | 'center';
  accessor?: 'updated' | 'accrued' | 'gst' | 'total';
};

const getColumns = (gstRegistered: boolean): Column[] => [
  {
    label: 'headers.month',
    hidden: false,
    flex: 3,
    align: 'flex-start',
    accessor: 'updated',
  },
  {
    label: 'headers.accrued',
    hidden: false,
    flex: 2,
    align: 'flex-end',
    accessor: 'accrued',
  },
  {
    label: 'headers.gst',
    hidden: false,
    flex: 2,
    align: 'flex-end',
    accessor: 'gst',
  },
  {
    label: 'headers.total',
    hidden: false,
    flex: 2,
    align: 'flex-end',
    accessor: 'total',
  },
  {
    label: '',
    hidden: !gstRegistered,
    flex: 1,
    align: 'flex-end',
    accessor: undefined,
  },
];

export { getColumns };

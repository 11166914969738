import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Wallets: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M16.5332 11.8286C16.5332 11.4144 16.869 11.0786 17.2832 11.0786H17.2859C17.7001 11.0786 18.0359 11.4144 18.0359 11.8286C18.0359 12.2428 17.7001 12.5786 17.2859 12.5786H17.2832C16.869 12.5786 16.5332 12.2428 16.5332 11.8286Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.98047 8.8561C4.98047 6.73109 6.70374 5.00781 8.82876 5.00781H16.4017C18.4992 5.00781 20.2051 6.68658 20.2491 8.77344H17.4524C15.7363 8.77344 14.3438 10.1649 14.3438 11.8822C14.3438 13.5988 15.7365 14.99 17.4524 14.99H20.25V15.1446C20.25 17.2696 18.5268 18.9929 16.4017 18.9929H14.0859C13.6717 18.9929 13.3359 19.3287 13.3359 19.7429C13.3359 20.1571 13.6717 20.4929 14.0859 20.4929H16.4017C19.3552 20.4929 21.75 18.098 21.75 15.1446V8.8561C21.75 5.90267 19.3552 3.50781 16.4017 3.50781H8.82876C5.87531 3.50781 3.48047 5.90267 3.48047 8.8561V9.88739C3.48047 10.3016 3.81626 10.6374 4.23047 10.6374C4.64468 10.6374 4.98047 10.3016 4.98047 9.88739V8.8561ZM20.25 13.49V10.2734H17.4524C16.5644 10.2734 15.8438 10.9937 15.8438 11.8822C15.8438 12.7696 16.5642 13.49 17.4524 13.49H20.25Z'
      fill='currentColor'
    />
    <path
      d='M9.36328 13.332C9.77749 13.332 10.1133 13.6678 10.1133 14.082V17.8718L10.5764 17.4251C10.8745 17.1375 11.3493 17.146 11.6369 17.4441C11.9245 17.7423 11.9159 18.2171 11.6178 18.5046L9.88468 20.1764C9.66367 20.3896 9.34447 20.4407 9.07645 20.3295C8.98273 20.2907 8.89859 20.2334 8.82856 20.1622L7.11017 18.5046C6.81205 18.2171 6.80349 17.7423 7.09107 17.4441C7.37864 17.146 7.85344 17.1375 8.15156 17.4251L8.61328 17.8704V14.082C8.61328 13.6678 8.94907 13.332 9.36328 13.332Z'
      fill='currentColor'
    />
    <path
      d='M3.98438 15.8464V19.6363C3.98438 20.0505 4.32016 20.3863 4.73438 20.3863C5.14859 20.3863 5.48438 20.0505 5.48438 19.6363V15.8488L5.94555 16.2936C6.24367 16.5812 6.71846 16.5726 7.00603 16.2745C7.29361 15.9764 7.28506 15.5016 6.98694 15.214L5.2805 13.568C5.14372 13.4227 4.94964 13.332 4.73438 13.332C4.73421 13.332 4.73454 13.332 4.73438 13.332C4.54641 13.3318 4.3579 13.4019 4.21243 13.5422L2.47931 15.214C2.18119 15.5016 2.17264 15.9764 2.46021 16.2745C2.74778 16.5726 3.22258 16.5812 3.5207 16.2936L3.98438 15.8464Z'
      fill='currentColor'
    />
  </svg>
);

export { Wallets };

import React from 'react';
import { RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import Bundles from '@Bundles/Bundles';

import { NavigationURLs } from 'src/lib/navigation/types';

export const BundlesRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Bundles,
    element: <PrivateRoute element={<Bundles />} />,
  },
];

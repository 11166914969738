import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset, NewsItem, NewsServiceResponse } from '@shared/api';
import { ParamKeysEnum } from '@shared/api/lib/endpoints/newsServiceEndpoint';

import * as Sentry from '@sentry/react';

import { useFetchNews } from './useFetchNews';

type Props = {
  assets: Asset[];
  pageSize: number;
  returnAll?: boolean;
};

const useNews = ({ assets, pageSize, returnAll = false }: Props) => {
  const [nextPageCursor, setNextPageCursor] = useState<string>('');
  const { fetchNews, loading } = useFetchNews({ assets, pageSize });

  const [changingNewsType, setChangingNewsType] = useState<boolean>(false);
  const [items, setItems] = useState<NewsItem[]>([]);
  const [prevPageSize, setPrevPageSize] = useState<number>();
  const [page, setPage] = useState<number>(0);

  const hasNextPage = useMemo(() => nextPageCursor.length > 0, [nextPageCursor]);
  const hasPrevPage = useMemo(() => page > 0, [page]);

  const processNewsItems = (newsData: NewsServiceResponse) => {
    setItems((prev) => {
      const newItems: Map<number, NewsItem> = new Map();
      prev.forEach((v) => newItems.set(v.id, v));
      newsData.data.forEach((d) => newItems.set(d.id, d));

      return Array.from(newItems.values());
    });

    const cursorParam = new URLSearchParams(newsData.links.next).get(ParamKeysEnum.CURSOR);
    setNextPageCursor(cursorParam ?? '');
  };

  const fetchNewsItems = useCallback(
    async (nextCursor: string, next?: boolean) => {
      if (!assets || loading || !pageSize) return;

      try {
        const data = await fetchNews(nextCursor);

        if (!data) return;

        processNewsItems(data);

        if (next) setPage((prev) => prev + 1);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setChangingNewsType(false);
      }
    },
    [assets, fetchNews, loading, pageSize],
  );

  const getPageItems = useCallback(
    (offset = 0) => (pageSize ? items.slice((page + offset) * pageSize, (page + offset) * pageSize + pageSize) : []),
    [pageSize, page, items],
  );

  const nextPage = useCallback(() => {
    if (loading || !hasNextPage || !pageSize) return;

    // Check if we already have enough items in the array to show the next page
    const enoughItems = getPageItems(1).length === pageSize;

    if (returnAll || !enoughItems) {
      fetchNewsItems(nextPageCursor, true);
      return;
    }

    setPage((prev) => prev + 1);
  }, [loading, hasNextPage, pageSize, getPageItems, returnAll, fetchNewsItems, nextPageCursor]);

  const prevPage = useCallback(() => {
    if (!hasPrevPage || !pageSize) return;
    setPage((prev) => prev - 1);
  }, [hasPrevPage, pageSize]);

  const newsItems = useMemo(() => {
    if (!items || !pageSize) return [];
    if (returnAll) return items;
    return getPageItems();
  }, [getPageItems, items, pageSize, returnAll]);

  // Calculate the relative page when changing page size
  useEffect(() => {
    if (!pageSize || pageSize === prevPageSize) return;

    if (!prevPageSize) {
      setPrevPageSize(pageSize);
    } else {
      // Work out the new page based on the new page size and the old page size
      const pageItemIndex = page * prevPageSize; // The index at the current page size
      if (pageItemIndex === 0) {
        setPage(0);
      } else {
        setPage(Math.round(pageItemIndex / pageSize));
      }

      setPrevPageSize(pageSize);
    }
  }, [page, pageSize, prevPageSize]);

  useEffect(() => {
    fetchNewsItems(nextPageCursor);
  }, [pageSize]);

  const refreshNewsItems = useCallback(() => {
    setChangingNewsType(true);
    setPage(0);
    setItems([]);
    fetchNewsItems('');
  }, [fetchNewsItems]);

  useEffect(() => {
    refreshNewsItems();
  }, [assets]);

  return {
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    newsItems,
    loading: loading || changingNewsType,
    changingNewsType,
    setChangingNewsType,
    refreshNewsItems,
  };
};

export { useNews };

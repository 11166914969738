import { styled } from '@mui/material';

export const ItemSliderContainer = styled('div')`
  ::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  position: relative;
  touch-action: none;
  overflow-x: scroll;
  display: flex;
  margin: 1rem;
`;

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';

import { AssetBalanceData } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

import { AssetWalletChangeDetails } from './AssetWalletChangeDetails';

type Props = {
  all: AssetBalanceData['all'];
  asset: Asset;
};

export const AssetWalletInformation: React.FC<Props> = observer(({ asset, all }) => {
  const isXs = useTailwindBreakpoint('xs');
  const baseAsset = useBaseAsset();

  if (isXs) {
    return (
      <FlexLayout spacing={16} direction='column'>
        <FlexLayout spacing={4} direction='column'>
          {baseAsset && baseAsset.id !== asset.id ? (
            <FlexLayout spacing={8} direction='row' alignItems='center'>
              <FormattedText
                prefix='~'
                currency={baseAsset}
                value={all.value}
                formatOpts={{ hideCode: true, appendCode: false }}
                typographyProps={{
                  size: 'xlarge',
                  weight: 'emphasis',
                }}
              />
              <Numeric size='xlarge' color='secondary'>
                {baseAsset.code}
              </Numeric>
            </FlexLayout>
          ) : null}
          <FlexLayout spacing={8} direction='row'>
            <FormattedText
              currency={asset}
              value={all.balance}
              formatBalance
              formatOpts={{ hideCode: true, appendCode: false }}
              typographyProps={{
                size: 'xlarge',
                weight: 'emphasis',
              }}
            />
            <Numeric size='xlarge' color='secondary'>
              {asset.code}
            </Numeric>
          </FlexLayout>
        </FlexLayout>

        {baseAsset ? (
          <>
            <AssetWalletChangeDetails change={all.change} changePercent={all.changePercent} baseAsset={baseAsset} />

            <div>
              {all.averageBuyPrice ? (
                <FlexLayout justifyContent='center' direction='column'>
                  <Body size='small' color='secondary' weight='emphasis'>
                    Avg. buy price:
                  </Body>
                  <FlexLayout direction='row' spacing={8}>
                    <FormattedText
                      currency={baseAsset}
                      value={all.averageBuyPrice}
                      formatOpts={{ hideCode: true, appendCode: false }}
                      typographyProps={{
                        color: 'secondary',
                        size: 'large',
                      }}
                    />
                    <Numeric color='secondary'>
                      {baseAsset.code}/{asset.code}
                    </Numeric>
                  </FlexLayout>
                </FlexLayout>
              ) : null}
            </div>
          </>
        ) : null}
      </FlexLayout>
    );
  }

  return (
    <FlexLayout direction='row' justifyContent='space-between'>
      <FlexLayout spacing={8} direction='column'>
        <FlexLayout spacing={8} direction='row'>
          <FormattedText
            currency={asset}
            value={all.balance}
            formatBalance
            formatOpts={{ hideCode: true, appendCode: false }}
            typographyProps={{
              size: 'xlarge',
              weight: 'emphasis',
            }}
          />
          <Numeric size='xlarge' color='secondary'>
            {asset.code}
          </Numeric>
        </FlexLayout>
        {all.averageBuyPrice && baseAsset ? (
          <FlexLayout direction='row' spacing={8} alignItems='center'>
            <Body size='small' color='secondary' weight='emphasis'>
              Avg. buy price:
            </Body>
            <FormattedText
              currency={baseAsset}
              value={all.averageBuyPrice}
              formatOpts={{ hideCode: true, appendCode: false }}
              typographyProps={{
                color: 'secondary',
                size: 'large',
                weight: 'emphasis',
              }}
            />
            <Numeric color='secondary' weight='emphasis'>
              {baseAsset.code}/{asset.code}
            </Numeric>
          </FlexLayout>
        ) : null}
      </FlexLayout>
      {baseAsset && baseAsset.id !== asset.id ? (
        <FlexLayout spacing={8} alignItems='end' direction='column'>
          <FlexLayout spacing={8} direction='row'>
            <FormattedText
              prefix='~'
              currency={baseAsset}
              value={all.value}
              formatOpts={{ hideCode: true, appendCode: false }}
              typographyProps={{
                size: 'xlarge',
                weight: 'emphasis',
              }}
            />
            <Numeric size='xlarge' color='secondary'>
              {baseAsset.code}
            </Numeric>
          </FlexLayout>
          <AssetWalletChangeDetails change={all.change} changePercent={all.changePercent} baseAsset={baseAsset} />
        </FlexLayout>
      ) : null}
    </FlexLayout>
  );
});

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { Information } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { DashboardContext } from '@routes/Dashboard/Dashboard.context';

import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';

const CustomizeDashboardBanner: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;
  const { editMode, cancelEditMode, saveTiles } = useContext(DashboardContext);
  const { bx } = useContentBreakpoint();
  const { t } = useTranslation('dashboard');

  const location = useLocation();
  const DASHBOARD_PATH = NavigationURLs.Dashboard;

  if (!location.pathname.includes(DASHBOARD_PATH) && editMode) {
    cancelEditMode();
    return null;
  }

  if (!editMode) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 10,
        color: 'white',
        backgroundColor: 'action.hover',
        height: '52px',
        transform: editMode ? 'translateY(0)' : 'translateY(-100%)',
        transition: 'transform 300ms',
      }}
      width='100%'
      overflow='hidden'
      paddingX={bx({ xs: 1, sm: 3 })}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' height='100%'>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Information className='text-color-text-primary' />
          <Box display={bx({ xs: 'block', sm: 'flex' })}>
            <Typography color='primary.main' fontWeight={600} fontSize={14}>
              {t('customizeDashboardBanner.title')}
            </Typography>
            <Typography
              marginLeft={bx({ xs: 0, md: 0.4 })}
              color='text.primary'
              fontWeight={400}
              fontSize={bx({ xs: 12, md: 14 })}
            >
              {t('customizeDashboardBanner.subTitle')}
            </Typography>
          </Box>
        </Stack>
        <Box display={bx({ xs: 'none', sm: 'flex' })}>
          <Button sx={{ color: 'text.secondary' }} color='inherit' variant='text' onClick={cancelEditMode}>
            {t('customizeDashboardBanner.buttonLabels.cancel')}
          </Button>
          <Button
            id='app-header-save-changes-button'
            color='primary'
            variant='text'
            onClick={() => saveTiles(userProfile)}
          >
            {t('customizeDashboardBanner.buttonLabels.saveChanges')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
});

CustomizeDashboardBanner.displayName = 'CustomizeDashboardBanner';

export { CustomizeDashboardBanner };

import { useMemo } from 'react';

import { Deposit, QuickBuy, Coin, ExternalLink, Chat } from '@swyftx/aviary/icons/outlined';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Links } from '@shared/constants';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { useIntercom } from 'react-use-intercom';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

import { GlobalSearchItemType } from '../../search.types';

const useSuggestedSearchModule = (): GlobalSearchItemType[] => {
  const { openModal } = useModal();
  const { navigate } = useNavigateRoute();
  const countryAsset = useCountryAsset();
  const { show } = useIntercom();
  const { isVerified, hasDeposited } = useProfileStatus();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const items = useMemo(
    (): GlobalSearchItemType[] => [
      {
        title: 'Quick buy',
        subTitle: `Simplified buying and selling crypto with ${countryAsset?.name}`,
        leading: <QuickBuy width={24} height={24} />,
        hidden: !hasDeposited || !isFeatureEnabled(AppFeature.QuickBuy),
        onClick: () => navigate(NavigationURLs.QuickBuy),
      },
      {
        title: `Deposit ${countryAsset?.code}`,
        subTitle: `Explore our ${countryAsset?.code} deposit methods`,
        leading: <Deposit className='h-24 w-24' />,
        hidden: !isVerified,
        onClick: () => openModal(Modals.DepositReceive, { selectedAsset: countryAsset }),
      },
      {
        title: 'View assets',
        subTitle: 'Discover all the assets we list on Swyftx',
        leading: <Coin className='h-24 w-24' />,
        onClick: () => navigate(NavigationURLs.AssetsAll),
      },
      {
        title: 'Swyftx Learn',
        subTitle: 'Educational resources to build your crypto and blockchain knowledge',
        leading: <ExternalLink />,
        onClick: () => window.open(Links.learn.root, '_blank', 'noopener,noreferrer'),
      },
      {
        title: 'Swyftx Support centre',
        subTitle: 'Step-by-step guides, helpful resources, and more',
        leading: <ExternalLink />,
        onClick: () => window.open(Links.help.home, '_blank', 'noopener,noreferrer'),
      },
      {
        title: 'Swyftx Live chat',
        subTitle: 'Our support team are here to answer your questions',
        leading: <Chat />,
        onClick: () => show(),
      },
    ],
    [countryAsset, hasDeposited, isFeatureEnabled, isVerified, navigate, openModal, show],
  );

  return items;
};

export { useSuggestedSearchModule };

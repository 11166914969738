import { useMemo } from 'react';

import { uuid } from '@shared/utils';

import { useContentCards } from '@hooks/useContentCards/useContentCards';
import { useTheme } from '@hooks/useTheme';

import * as braze from '@braze/web-sdk';

export interface BrazeContentCardItem {
  id: string;
  imgSrc: string;
  url?: string;
  card: braze.Banner | braze.CaptionedImage;
}

const useBuildAnnouncementCards = () => {
  const { isLightTheme } = useTheme();
  const contentCards = useContentCards();

  const announcementItems: BrazeContentCardItem[] = useMemo(
    () =>
      contentCards
        .filter(
          (x): x is braze.Banner | braze.CaptionedImage =>
            x instanceof braze.Banner || x instanceof braze.CaptionedImage,
        )
        .map((card: braze.Banner | braze.CaptionedImage) => ({
          id: card.id ?? uuid(),
          card,
          imgSrc: isLightTheme ? card.imageUrl : card.extras?.['dark-image'] ?? card.imageUrl,
          url: card.url,
        })),
    [isLightTheme, contentCards],
  );

  return {
    items: announcementItems,
  };
};

export { useBuildAnnouncementCards };

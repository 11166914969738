import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Delete, Edit } from '@swyftx/aviary/icons/outlined';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { DateTime } from 'luxon';
import {
  DynamicPriceAlertTitle,
  DynamicPriceAlertIcon,
  DeletePriceAlertModal,
  DynamicPriceAlertsVolatilitySwitch,
} from 'src/lib/dynamic-price-alerts/components';
import { EditPriceAlertModal } from 'src/lib/dynamic-price-alerts/components/EditPriceAlertModal';
import { PriceAlert, PriceAlertGroupBy } from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import { isLimitAlert, isVolatilityAlert } from 'src/lib/dynamic-price-alerts/utils/dynamic-price-alerts.utils';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  alerts?: PriceAlert[];
  groupBy: PriceAlertGroupBy;
};

export type DynamicPriceAlertTableData = {
  asset: string;
  price: string;
  lastUpdated: string;
  actions: string;
};

type DynamicPriceAlertsHeaderData = { [key in keyof DynamicPriceAlertTableData]: EnhancedTableHeaderData };

const useDynamicPriceActiveAlertsTable = ({ alerts = [], groupBy }: Props) => {
  const { getAssetById } = useMarkets();
  const { t } = useTranslation('priceAlerts');
  const isXs = useTailwindBreakpoint('xs');
  const [sort, setSort] = useState<EnhancedTableSort<DynamicPriceAlertTableData>>({
    sortKey: 'lastUpdated',
    sortDirection: 'DESC',
  });

  const headers: DynamicPriceAlertsHeaderData = useMemo(
    () => ({
      asset: {
        title: t('table.headers.asset'),
        alignment: 'start',
        sortable: false,
        enabled: !isXs,
        className: `${groupBy === 'asset' ? 'hidden' : 'table-cell w-[250px]'}`,
      },
      price: {
        title: groupBy === 'asset' ? t('table.headers.price') : t('table.headers.priceAlert'),
        alignment: 'start',
        sortable: false,
        className: 'table-cell',
      },
      lastUpdated: {
        title: t('table.headers.lastUpdated'),
        alignment: 'end',
        sortable: true,
        className: 'table-cell hidden sm:table-cell w-[200px]',
      },
      actions: {
        title: t('table.headers.actions'),
        alignment: 'end',
        sortable: false,
        className: 'table-cell w-[200px]',
      },
    }),
    [groupBy, isXs, t],
  );

  const data: EnhancedTableData<DynamicPriceAlertTableData>[] = useMemo(
    () =>
      alerts.map((alert) => {
        const asset = getAssetById(alert.secondary);
        const primaryAsset = getAssetById(alert.primary);

        return {
          value: alert,
          asset: {
            value: alert.secondary,
            element: (
              <FlexLayout alignItems='center' spacing={8}>
                <AssetIcon asset={asset} size={24} />
                <Body>{asset?.name}</Body>
              </FlexLayout>
            ),
          },
          price: {
            value: isLimitAlert(alert) ? alert?.price?.toString() || '' : '',
            element: (
              <FlexLayout alignItems='center' className='gap-16 sm:gap-8'>
                <DynamicPriceAlertIcon
                  direction={isLimitAlert(alert) ? alert.direction : undefined}
                  type={alert.type}
                  asset={groupBy === 'asset' ? undefined : asset}
                  size={24}
                />
                <DynamicPriceAlertTitle
                  type={alert.type}
                  primaryAsset={primaryAsset}
                  secondaryAsset={asset}
                  direction={isLimitAlert(alert) ? alert.direction : undefined}
                  change={isVolatilityAlert(alert) ? alert.change : undefined}
                  price={isLimitAlert(alert) ? alert.price : undefined}
                  window={isVolatilityAlert(alert) ? alert.window : undefined}
                />
              </FlexLayout>
            ),
          },
          lastUpdated: {
            value: alert.dateUpdated,
            element: (
              <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
                <Body size='small' className='whitespace-nowrap'>
                  {DateTime.fromMillis(alert.dateUpdated).toFormat('dd LLL yyyy')}
                </Body>
              </FlexLayout>
            ),
          },
          actions: {
            value: '',
            blockClick: true,
            element: (
              <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
                {primaryAsset && (
                  <EditPriceAlertModal alert={alert}>
                    <Button
                      variant='outlined'
                      layout={isXs ? 'icon' : 'default'}
                      leadingIcon={isXs ? <Edit /> : undefined}
                    >
                      Edit
                    </Button>
                  </EditPriceAlertModal>
                )}
                <DeletePriceAlertModal alert={alert}>
                  <Button
                    variant='outlined'
                    layout={isXs ? 'icon' : 'default'}
                    leadingIcon={isXs ? <Delete /> : undefined}
                  >
                    Delete
                  </Button>
                </DeletePriceAlertModal>
                <DynamicPriceAlertsVolatilitySwitch alert={alert} />
              </FlexLayout>
            ),
          },
        };
      }),
    [alerts, getAssetById, groupBy, isXs],
  );

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<DynamicPriceAlertTableData>) => {
      const tableData: EnhancedTableData<DynamicPriceAlertTableData>[] = Object.assign([], data);

      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => {
        switch (newSort.sortKey) {
          case 'lastUpdated':
            const aValNum = a[newSort.sortKey].value as number;
            const bValNum = b[newSort.sortKey].value as number;
            if (newSort.sortDirection === 'ASC') return aValNum - bValNum;
            return bValNum - aValNum;
          default:
            return 1;
        }
      });
    },
    [data, setSort],
  );

  return { headers, data, initialSort: sort, onSort };
};

export { useDynamicPriceActiveAlertsTable };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AddUserFilled } from '@swyftx/aviary/icons/filled';

type Props = {
  expiration: number;
};

const EntityConfigureMemberInvitation: React.FC<Props> = ({ expiration }) => {
  const { t } = useTranslation('profile');
  const expirationString = useMemo(
    () => new Date(expiration).toLocaleString('default', { hour12: true }).toUpperCase(),
    [expiration],
  );

  return (
    <Card>
      <FlexLayout direction='column' spacing={16}>
        <FlexLayout direction='row' className='w-full items-center justify-between p-16 pb-0'>
          <FlexLayout direction='row' spacing={8} className='items-center'>
            <AddUserFilled className='mr-8' />
            <Body weight='emphasis'>{t('entities.configureMemberModal.overview.headers.invitation')}</Body>
          </FlexLayout>
          <Chip size='sm' variant='subtle' color='warning'>
            {t('entities.configureMemberModal.overview.labels.pendingInvite')}
          </Chip>
        </FlexLayout>
        <Separator />
        <FlexLayout direction='column' spacing={16} className='p-16 pt-0'>
          <Body weight='emphasis'>
            {t('entities.configureMemberModal.overview.labels.inviteExpiration', { expiration: expirationString })}
          </Body>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export { EntityConfigureMemberInvitation };

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { MarketTickerVisibleAssetsValueType } from 'src/context/Avo/generated-avo';

import { MarketTickerMode } from './aviary/marketTicker.consts';
import { MarketTickerSortingOptions } from './consts';

const useMarketTickerAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();
  const { balances } = UserStore.useUserStore;

  const fetchAssetParams = useCallback(
    (asset: Asset) => {
      const balance = balances[asset.id];
      const availableBalance = balance ? balance.availableBalance : '0';
      const assetType = asset.assetType === AssetType.Crypto ? 'CRYPTO' : 'FIAT';

      return {
        assetType: assetType as 'FIAT' | 'CRYPTO',
        ownsAsset: Big(availableBalance).gt(0),
        assetBalance: availableBalance,
      };
    },
    [balances],
  );

  const convertSortToAvo = useCallback((sort: MarketTickerSortingOptions) => {
    switch (sort) {
      case MarketTickerSortingOptions.FAVOURITES:
        return 'Favourites';
      case MarketTickerSortingOptions.GAINERS:
        return 'Gainers';
      case MarketTickerSortingOptions.LOSERS:
        return 'Losers';
      case MarketTickerSortingOptions.MARKET_CAP:
        return 'Market cap';
      case MarketTickerSortingOptions.NEW:
        return 'New listings';
      case MarketTickerSortingOptions.OWNED:
        return 'Owned';
      default:
        return 'Gainers';
    }
  }, []);

  const convertVariantToAvo = useCallback((variant: MarketTickerMode) => {
    switch (variant) {
      case MarketTickerMode.STATIC:
        return 'Static';
      case MarketTickerMode.MOVING:
        return 'Scrolling';
      default:
        return 'Static';
    }
  }, []);

  const changeAssetViewed = useCallback(
    (attributes: { asset: Asset }) => {
      const { asset } = attributes;
      const { ownsAsset, assetBalance, assetType } = fetchAssetParams(asset);
      avo.changedAssetViewed({
        screen: pathname,
        assetCode: asset.code,
        assetId: asset.id,
        assetType,
        ownsAsset,
        assetBalance,
      });
    },
    [avo, fetchAssetParams, pathname],
  );

  const marketTickerAssetClicked = useCallback(
    (attributes: {
      asset: Asset;
      sort: MarketTickerSortingOptions;
      variant: MarketTickerMode;
      percentageChange: string;
      price: string;
      visibleAssets?: MarketTickerVisibleAssetsValueType;
    }) => {
      const { asset, sort, variant, percentageChange, price, visibleAssets } = attributes;
      avo.marketTickerAssetClicked({
        screen: pathname,
        assetCode: asset.code,
        assetId: asset.id,
        assetName: asset.name,
        percentageChange,
        assetPriceBaseCurrency: price,
        marketTickerSort: convertSortToAvo(sort),
        marketTickerVariant: convertVariantToAvo(variant),
        marketTickerVisibleAssets: visibleAssets,
      });
    },
    [avo, convertSortToAvo, convertVariantToAvo, pathname],
  );

  const marketTickerSortChanged = useCallback(
    (attributes: {
      sort: MarketTickerSortingOptions;
      variant: MarketTickerMode;
      visibleAssets?: MarketTickerVisibleAssetsValueType;
    }) => {
      const { sort, variant, visibleAssets } = attributes;
      avo.marketTickerSortChanged({
        screen: pathname,
        marketTickerSort: convertSortToAvo(sort),
        marketTickerVariant: convertVariantToAvo(variant),
        marketTickerVisibleAssets: visibleAssets,
      });
    },
    [avo, convertSortToAvo, convertVariantToAvo, pathname],
  );

  const marketTickerToggled = useCallback(
    (attributes: { toggleState: boolean }) => {
      const { toggleState } = attributes;
      avo.marketTickerToggled({
        screen: pathname,
        marketTickerToggleState: toggleState,
      });
    },
    [avo, pathname],
  );

  const marketTickerVariantChanged = useCallback(
    (attributes: {
      sort: MarketTickerSortingOptions;
      variant: MarketTickerMode;
      visibleAssets?: MarketTickerVisibleAssetsValueType;
    }) => {
      const { sort, variant, visibleAssets } = attributes;
      avo.marketTickerVariantChanged({
        screen: pathname,
        marketTickerSort: convertSortToAvo(sort),
        marketTickerVariant: convertVariantToAvo(variant),
        marketTickerVisibleAssets: visibleAssets,
      });
    },
    [avo, convertSortToAvo, convertVariantToAvo, pathname],
  );

  const marketTickerVisibleAssetsChanged = useCallback(
    (attributes: {
      sort: MarketTickerSortingOptions;
      variant: MarketTickerMode;
      visibleAssets?: MarketTickerVisibleAssetsValueType;
    }) => {
      const { sort, variant, visibleAssets } = attributes;
      avo.marketTickerVisibleAssetsChanged({
        screen: pathname,
        marketTickerSort: convertSortToAvo(sort),
        marketTickerVariant: convertVariantToAvo(variant),
        marketTickerVisibleAssets: visibleAssets,
      });
    },
    [avo, convertSortToAvo, convertVariantToAvo, pathname],
  );

  return {
    changeAssetViewed,
    marketTickerAssetClicked,
    marketTickerSortChanged,
    marketTickerToggled,
    marketTickerVariantChanged,
    marketTickerVisibleAssetsChanged,
  };
};

export { useMarketTickerAnalytics };

import { VariantProps, cva } from 'cva';

import { BodyProps } from '../Typography/Body/Body.styles';

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof chipVariants> {
  color?: 'primary' | 'info' | 'warning' | 'success' | 'destructive' | 'secondary' | 'accent';
  bodyProps?: BodyProps;
  leadingIcon?: React.ReactElement;
}

export const chipIconVariants = cva('', {
  variants: {
    color: {
      primary: 'text-color-text-primary',
      info: 'text-color-text-info',
      warning: 'text-color-text-warning',
      success: 'text-color-text-success',
      destructive: 'text-color-text-error',
      secondary: 'text-color-text-secondary',
      accent: 'text-color-text-accent',
    },
    variant: {
      default: undefined,
      subtle: undefined,
    },
    size: {
      sm: 'w-16 h-16',
      md: 'w-20 h-20',
    },
  },
});

export const chipVariants = cva('flex text-center w-fit font-medium whitespace-nowrap focus:focus-area-info', {
  variants: {
    color: {
      primary: 'bg-color-background-primary text-color-text-inverse',
      info: 'bg-color-background-info text-color-text-inverse',
      warning: 'bg-color-background-warning text-color-text-inverse',
      success: 'bg-color-background-success text-color-text-inverse ',
      destructive: 'bg-color-background-error text-color-text-inverse',
      secondary: 'bg-color-background-surface-secondary text-color-text-primary',
      accent: 'bg-color-background-primary text-color-text-inverse',
    },
    variant: {
      default: '',
      subtle: '',
    },
    size: {
      sm: 'rounded-12 py-2 px-8',
      md: 'rounded-16 py-4 px-12',
    },
  },
  compoundVariants: [
    {
      variant: 'subtle',
      color: 'primary',
      class: 'bg-color-background-primary-subtle text-color-text-accent',
    },
    {
      variant: 'subtle',
      color: 'info',
      class: 'bg-color-background-info-subtle text-color-text-info outline outline-color-border-info-subtle',
    },
    {
      variant: 'subtle',
      color: 'warning',
      class: 'bg-color-background-warning-subtle text-color-text-warning outline outline-color-border-warning-subtle',
    },
    {
      variant: 'subtle',
      color: 'success',
      class: 'bg-color-background-success-subtle text-color-text-success outline outline-color-border-success-subtle',
    },
    {
      variant: 'subtle',
      color: 'destructive',
      class: 'bg-color-background-error-subtle text-color-text-error outline outline-color-border-error-subtle',
    },
  ],
  defaultVariants: {
    variant: 'default',
    color: 'info',
    size: 'md',
  },
});

import React from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { Command } from 'cmdk';

const GlobalSearchEmpty: React.FC = () => (
  <Command.Empty className='p-'>
    <FlexLayout direction='column' alignItems='center' justifyContent='center'>
      <Image className='h-64 w-64' image='no_results' />
      <Heading size='h5'>No results</Heading>
      <Body color='secondary' size='small'>
        No matching results found.
      </Body>
    </FlexLayout>
  </Command.Empty>
);

export { GlobalSearchEmpty };

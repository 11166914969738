import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { SwyftxProOrderBookBookItem } from './SwyftxProOrderBookBookItem';
import { useSwyftxProOrderbookItems } from '../../hooks/useSwyftxProOrderbookItems';
import { OrderBookType, OrderBookValueType } from '../../types/TradePro.types';

type Props = {
  orderbook: string[][];
  midPrice?: string;
  loading?: boolean;
  selectedAsset: Asset;
  baseAsset: Asset;
  side: OrderBookType;
  onClick?: (value: string, valueType: OrderBookValueType) => void;
};

const SwyftxProOrderBookBook: React.FC<Props> = ({
  orderbook,
  loading,
  midPrice,
  selectedAsset,
  baseAsset,
  side,
  onClick,
}) => {
  const { getClassName, totalBookValue, backgroundColor, textColor, onMouseEnter, onMouseLeave, getCumulativeAmount } =
    useSwyftxProOrderbookItems({ orderbook, side });

  if (loading) {
    return (
      <FlexLayout
        className={cn('w-full', side === 'ask' ? 'flex-col' : 'flex-col-reverse	')}
        alignItems='start'
        justifyContent='space-between'
        spacing={4}
      >
        <div
          className='mx-[-.5rem] h-[2rem] animate-pulse rounded-4 bg-color-background-neutral p-16	'
          style={{ width: `75%` }}
        />
        <div
          className='mx-[-.5rem] h-[2rem] animate-pulse rounded-4 bg-color-background-neutral p-16	'
          style={{ width: `20%` }}
        />
        <div
          className='mx-[-.5rem] h-[2rem] animate-pulse rounded-4 bg-color-background-neutral p-16	'
          style={{ width: `35%` }}
        />
        <div
          className='mx-[-.5rem] h-[2rem] animate-pulse rounded-4 bg-color-background-neutral p-16	'
          style={{ width: `15%` }}
        />
        <div
          className='mx-[-.5rem] h-[2rem] animate-pulse rounded-4 bg-color-background-neutral p-16	'
          style={{ width: `5%` }}
        />
      </FlexLayout>
    );
  }

  return (
    <FlexLayout className='w-full' alignItems='center' justifyContent='space-between' direction='column'>
      {orderbook.map(([price, total], index) => (
        <SwyftxProOrderBookBookItem
          key={`${selectedAsset.code}_${index}`}
          price={price}
          total={total}
          midPrice={midPrice}
          bookTotal={totalBookValue}
          backgroundColor={backgroundColor}
          textColor={textColor}
          selectedAsset={selectedAsset}
          baseAsset={baseAsset}
          side={side}
          onClick={onClick}
          className={getClassName(index)}
          amount={getCumulativeAmount(index)}
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={onMouseLeave}
        />
      ))}
    </FlexLayout>
  );
};

export { SwyftxProOrderBookBook };

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { BuyButton } from '@global-components/BuySellButtons';

import { useSingleCategoryAnalytics } from '@hooks/Analytics/Categories/useSingleCategoryAnalytics';
import { useGainersAndLosers } from '@hooks/Assets/useGainersAndLosers';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { CategoryIcons, fallbackIcon } from 'src/context/Categories/Categories.icons';
import { useCategory, useCategoryPerformance } from 'src/lib/categories/hooks';
import { MarketsGainersLosersWidget } from 'src/lib/markets/widgets/MarketsGainersLosersWidget/MarketsGainersLosersWidget';

import { PAGE_CONTAINER_DATA } from './SingleCategoryPage.data';
import { SingleCategoryMoversCard, SingleCategoryAssetsTable } from './components';
import { SingleCategoryLearnCard } from './components/SingleCategoryLearnCard';
import { SingleCategoryNews } from './components/SingleCategoryNews';
import { useSingleCategoryLearnCard } from './hooks/useSingleCategoryLearnCard';

const SingleCategoryPage: React.FC = observer(() => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const { categoryId } = useParams();
  const { trackSingleCategoryPageOpened, trackSingleCategoryPageBuyBundleClicked } = useSingleCategoryAnalytics();

  const { category, assets } = useCategory({ id: Number(categoryId) });
  const isScreenXs = useTailwindBreakpoint('xs');
  const {
    isSmallScreen,
    isXs,
    isLg,
    isMd,
    atLeast,
    loaded: loadedContainer,
  } = useTailwindContainerBreakpoint({
    containerName: PAGE_CONTAINER_DATA,
    deps: [category],
  });

  const { performanceItems } = useCategoryPerformance(category);
  const { gainers, losers, loaded } = useGainersAndLosers(assets);
  const { learnCard } = useSingleCategoryLearnCard({ category });

  useEffect(() => {
    if (category) trackSingleCategoryPageOpened(category.name);
  }, [category, trackSingleCategoryPageOpened]);

  if (!category) return null;

  return (
    <>
      <Page
        data-spotlightelementid='single-category-page'
        title={category.name}
        actions={
          !isScreenXs
            ? [
                <BuyButton
                  key='buy-bundle'
                  size='lg'
                  assets={assets}
                  color='primary'
                  onClickCallback={() => trackSingleCategoryPageBuyBundleClicked(category.name)}
                  customLabel={t('labels.buyBundle')}
                  variant='aviary'
                />,
              ]
            : []
        }
        compactActions={
          !isScreenXs
            ? [
                <BuyButton
                  key='buy-bundle'
                  size='lg'
                  assets={assets}
                  color='primary'
                  onClickCallback={() => trackSingleCategoryPageBuyBundleClicked(category.name)}
                  customLabel={t('labels.buyBundle')}
                  variant='aviary'
                />,
              ]
            : []
        }
        icon={{
          // eslint-disable-next-line no-restricted-syntax
          color: category.colour || '',
          element: <>{CategoryIcons(96, true, true)[category.name] || fallbackIcon(96, true, true)}</>,
        }}
        metrics={performanceItems}
        content={category.description}
        className={`@container ${isScreenXs ? 'pb-96' : ''}`}
        data-container={PAGE_CONTAINER_DATA}
      >
        <PageContent offset className='@xs:p-16 @sm:p-24 @md:p-48'>
          <FlexLayout direction='column' spacing={24}>
            {learnCard ? (
              <FlexLayout direction='row' className='w-full flex-wrap @lg:flex-nowrap' spacing={isXs ? 16 : 24}>
                <FlexLayout
                  direction='column'
                  spacing={isXs ? 16 : 24}
                  className='order-2 w-full @lg:order-1 @lg:w-full'
                >
                  <FlexLayout spacing={isXs ? 16 : 24} className='w-full flex-col @sm:flex-row'>
                    <SingleCategoryMoversCard
                      className='h-[272px] min-h-[272px] @xs:w-full @sm:w-[calc(50%-16px)]  @md:w-1/2'
                      category={category}
                      title={t('titles.gainers')}
                      direction='gainers'
                      emptyContent={t('emptyLabels.gainers')}
                      assets={gainers}
                      numAssets={5}
                      loaded={loaded}
                    />
                    <SingleCategoryMoversCard
                      className='h-[272px] min-h-[272px] @xs:w-full @sm:w-[calc(50%-16px)]  @md:w-1/2'
                      category={category}
                      title={t('titles.losers')}
                      direction='losers'
                      emptyContent={t('emptyLabels.losers')}
                      assets={losers}
                      numAssets={5}
                      loaded={loaded}
                    />
                  </FlexLayout>
                  <MarketsGainersLosersWidget
                    className='w-full @xs:h-auto @md:h-[180px]'
                    assets={assets}
                    alignment='end'
                    height={150}
                    direction={loaded && !learnCard && (isXs || isLg || isMd) ? 'column' : 'row'}
                  />
                </FlexLayout>
                <div className='order-1 w-full @lg:order-2 @lg:max-w-[600px] '>
                  <SingleCategoryLearnCard
                    title={learnCard.title}
                    imageSrc={learnCard.imageSrc}
                    articleUrl={learnCard.articleUrl}
                    content={learnCard.content}
                  />
                </div>
              </FlexLayout>
            ) : (
              <FlexLayout
                direction='row'
                className='flex-wrap'
                alignItems='center'
                justifyContent='space-between'
                spacing={isXs ? 16 : 24}
              >
                <SingleCategoryMoversCard
                  className='h-[272px] @xs:w-full @sm:w-[calc(50%-16px)]  @md:w-[calc(33.33%-16px)]'
                  category={category}
                  title={t('titles.gainers')}
                  direction='gainers'
                  emptyContent={t('emptyLabels.gainers')}
                  assets={gainers}
                  numAssets={5}
                  loaded={loaded}
                />
                <SingleCategoryMoversCard
                  className='h-[272px] @xs:w-full @sm:w-[calc(50%-16px)]  @md:w-[calc(33.33%-16px)]'
                  category={category}
                  title={t('titles.losers')}
                  direction='losers'
                  emptyContent={t('emptyLabels.losers')}
                  assets={losers}
                  numAssets={5}
                  loaded={loaded}
                />
                <MarketsGainersLosersWidget
                  className='@xs:h-auto @xs:w-full @md:h-[272px] @md:w-[calc(100%-16px)]  @md:w-[calc(33.33%-16px)]'
                  assets={assets}
                  alignment='end'
                  direction={loaded && (isXs || isLg || isMd) ? 'column' : 'row'}
                />
              </FlexLayout>
            )}

            <SingleCategoryAssetsTable category={category} loaded={loadedContainer} />

            <FlexLayout
              direction='row'
              className='w-full'
              alignItems='start'
              justifyContent='space-between'
              spacing={16}
            >
              <SingleCategoryNews
                category={category}
                assets={assets}
                isXs={isXs}
                isSmallScreen={isSmallScreen}
                atLeast={atLeast}
              />
            </FlexLayout>
          </FlexLayout>
        </PageContent>
      </Page>
      {isScreenXs && (
        <div className='fixed bottom-0 left-0 z-10 z-20  h-[80px] w-[calc(100%-8px)]  bg-color-background-pageBG'>
          <FlexLayout alignItems='center' className='h-full w-full px-16' justifyContent='center'>
            <BuyButton
              key='buy-bundle'
              assets={assets}
              color='primary'
              customLabel={t('labels.buyBundle')}
              size='md'
              variant='aviary'
            />
          </FlexLayout>
        </div>
      )}
    </>
  );
});

export { SingleCategoryPage };

import React, { useEffect, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Recurring } from '@swyftx/aviary/icons/outlined';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { AppStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AutoInvestAsset } from 'src/lib/auto-invest/autoInvest.types';
import { AutoInvestContext } from 'src/lib/auto-invest/context';
import { useAutoInvestPercentages } from 'src/lib/auto-invest/hooks';
import { CreateAutoInvestWidget } from 'src/lib/auto-invest/widgets';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTradeStatus } from 'src/lib/trade/hooks/General';

type PageState = {
  selectedAssets: AutoInvestAsset[];
};

type Props = {
  className?: string;
};
const AutoInvestCreatePage: React.FC<Props> = observer(({ className }) => {
  const { setSelectedAutoInvestAssets } = useContext(AutoInvestContext);

  const { isDemo } = AppStore.useAppStore;
  const { isVerified, isRestricted, hasPermission } = useTradeStatus('auto-invest');
  const countryAsset = useCountryAsset();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const location = useLocation();
  const { calculatePercentages } = useAutoInvestPercentages();
  const pageState: PageState = location.state;
  const isXs = useTailwindBreakpoint('xs');

  useEffect(() => {
    if (!pageState?.selectedAssets) return;

    const assetsWithCalculatedPercentages = calculatePercentages(pageState.selectedAssets);
    setSelectedAutoInvestAssets(assetsWithCalculatedPercentages);
  }, [calculatePercentages, setSelectedAutoInvestAssets, pageState]);

  if (
    isDemo ||
    !isVerified ||
    isRestricted ||
    !hasPermission ||
    (countryAsset?.code === 'NZD' && !isFeatureEnabled(AppFeature.AutoInvestFromBalances))
  ) {
    return <Navigate to={NavigationURLs.AutoInvest} />;
  }

  return (
    <Page
      showCompactHeader={false}
      alignment='center'
      className={cn('pr-0 @container', className, isXs ? 'mt-[-36px]' : '!mt-0 !pt-0')}
      customHeader={
        <FlexLayout
          className='mt-48 w-full py-32 @container sm:mt-0 sm:pb-32'
          direction='column'
          alignItems='center'
          justifyContent='center'
          spacing={12}
        >
          <Icon color='accent' className='h-48 w-48 text-color-icon-accent' icon={<Recurring />} />
          <FlexLayout direction='column' alignItems='center'>
            <Heading size={isXs ? 'h4' : 'h3'} className='text-center'>
              Create an auto investing order
            </Heading>
            <Body size='small' color='secondary'>
              Recurring investments made easy.
            </Body>
          </FlexLayout>
        </FlexLayout>
      }
    >
      <PageContent offset className='-mt-[12.25rem] !p-16 @container @sm:p-32 @md:p-24'>
        <FlexLayout justifyContent='center'>
          <CreateAutoInvestWidget />
        </FlexLayout>
      </PageContent>
    </Page>
  );
});

export { AutoInvestCreatePage };

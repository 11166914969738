import { useCallback, useMemo } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

type Props = {
  orientation: 'left' | 'right' | 'bottom';
  x: number;
  y: number;
  tooltipWidth: number;
  tooltipHeight: number;
  chartId: string;
};

const padding = 16;
const mainContentId = 'mainContentLayout.container';

const useTooltipPosition = ({ orientation, x, y, tooltipHeight, tooltipWidth, chartId }: Props) => {
  const isXs = useTailwindBreakpoint('xs');
  const getChartRect = useCallback(
    () => (document.getElementById(chartId)?.firstChild as Element)?.getBoundingClientRect(),
    [chartId],
  );

  const getContentRect = () => document.getElementById(mainContentId)?.getBoundingClientRect();

  const checkXOverlap = useCallback(() => {
    const chartRect = getChartRect();
    const contentRect = getContentRect();

    if (!chartRect || !contentRect) {
      return {
        overlapLeft: false,
        overlapRight: false,
      };
    }

    const contentRight = contentRect.x + contentRect.width;
    const contentLeft = contentRect.x;
    const overlapLeft = chartRect.x - x - tooltipWidth < contentLeft;
    const overlapRight = chartRect.x + x + tooltipWidth > contentRight;

    return {
      overlapLeft,
      overlapRight,
    };
  }, [getChartRect, tooltipWidth, x]);

  const newX = useMemo(() => {
    let xValue = x;
    const { overlapLeft, overlapRight } = checkXOverlap();

    if (overlapLeft && overlapRight) {
      // if we are going to overlap on both, just render the tooltip smack bang in the middle
      return 0;
    } else if (overlapLeft) {
      // if we are overlapping on the left, render to the middle on a small device
      // or just use our initial x pos on larger devices as its already left anchored so the content will
      // render to the right of the point
      return isXs ? 0 : x;
    } else if (overlapRight) {
      // if we are overlapping to the left, once again render small screens in the middle
      // and render to the right of the point by subtracting the tooltips width
      return isXs ? 0 : x - tooltipWidth;
    }

    if (orientation === 'left') {
      // if we are left aligned, we need to minus the tooltip width and padding
      // from the initial x position
      xValue = xValue - tooltipWidth - padding;
    } else if (orientation === 'right') {
      // if we are right aligned, the x pos already is anchored to the left
      // so just apply the padding
      xValue = xValue + padding;
    }

    return xValue;
  }, [checkXOverlap, isXs, orientation, tooltipWidth, x]);

  const newY = useMemo(() => {
    const yValue = y + tooltipHeight / 2 - padding;

    return yValue;
  }, [tooltipHeight, y]);

  return {
    newX,
    newY,
  };
};

export { useTooltipPosition };

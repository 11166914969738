import { Big } from '@shared/safe-big';

export const HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD = 4;

export type AssetTradePercentage = {
  assetId: number;
  percent: number;
  isLocked: boolean;
};

export type AssetTradePercentageTemplate = { [assetId: number]: number };

export interface FeeBreakdown {
  from: number;
  to: number;
  feeInLimit: Big;
  feeInNonLimit: Big;
  feeInUserCountry: string;
  limitPrefix?: string;
  nonLimitPrefix: string;
  assetInLimit: number;
  assetInNonLimit: number;
}

export interface FeeDetail {
  totalPrefix: string;
  totalAssetId: number;
  totalFee: Big;
  totalInUserCountry: string;
  breakdowns: FeeBreakdown[];
}

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronDown, Coin } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

type Props = {
  asset?: Asset;
  onClick: () => void;
};

const TransferModalSelectAsset: React.FC<Props> = ({ asset, onClick }) => (
  <FlexLayout direction='column' spacing={8}>
    <Body weight='bold' size='small'>
      Select asset: <span className='text-color-text-error'>*</span>
    </Body>
    <FlexLayout
      alignItems='center'
      justifyContent='space-between'
      spacing={8}
      onClick={onClick}
      className='w-full cursor-pointer rounded-8 bg-color-background-surface-secondary p-16 hover:bg-color-background-surface-active'
    >
      <FlexLayout alignItems='center' spacing={8}>
        {asset ? (
          <AssetIcon asset={asset} size={24} />
        ) : (
          <FlexLayout
            className='h-24 w-24 rounded-20 bg-color-background-surface-inverse p-2'
            alignItems='center'
            justifyContent='center'
          >
            <Coin />
          </FlexLayout>
        )}
        <Body color={asset ? 'primary' : 'secondary'} weight='emphasis'>
          {asset?.name || 'Select an asset'}
        </Body>
      </FlexLayout>
      <ArrowChevronDown className='h-16 w-16' />
    </FlexLayout>
  </FlexLayout>
);

export { TransferModalSelectAsset };

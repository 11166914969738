import React, { useCallback, useContext, useMemo } from 'react';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { SelectAssetList } from 'src/lib/markets/components/SelectAssetList/SelectAssetList';
import { QuickBuyContext } from 'src/lib/trade/context';
import { useQuickBuyAnalytics } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAnalytics';
import { useQuickBuyAssets } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAssets';

const QuickBuyWidgetAssetPicker: React.FC = observer(() => {
  const { setCurrentStep, setAmount, countryAssetCode, limitAssetCode, buyType } = useContext(QuickBuyContext);
  const { trackSelectedTradeAsset } = useQuickBuyAnalytics();
  const { selectedAsset, setSelectedAssetCode, setLimitAssetCode } = useQuickBuyAssets();

  const excludedAssetCodes = useMemo(() => [countryAssetCode], [countryAssetCode]);
  const defaultFilterType = useMemo(() => (buyType === 'buy' ? 'all' : 'owned'), [buyType]);

  const selectAsset = useCallback(
    (asset: Asset) => {
      setSelectedAssetCode(asset.code);
      trackSelectedTradeAsset({ asset, direction: buyType });

      if (limitAssetCode !== countryAssetCode) {
        setAmount('');
        setLimitAssetCode(asset.code);
      }
      setCurrentStep('values');
    },
    [
      buyType,
      countryAssetCode,
      limitAssetCode,
      setAmount,
      setCurrentStep,
      setLimitAssetCode,
      setSelectedAssetCode,
      trackSelectedTradeAsset,
    ],
  );

  return (
    <SelectAssetList
      title='Change asset'
      onBack={() => setCurrentStep('values')}
      type={buyType}
      onSelectAsset={selectAsset}
      selectedAssets={selectedAsset}
      defaultFilterType={defaultFilterType}
      excludedAssetCodes={excludedAssetCodes}
    />
  );
});

export { QuickBuyWidgetAssetPicker };

import { StorageKey } from '@shared/storage';

import { action, observable } from 'mobx';

import { GlobalModalData, MessageBoxData, ToastMessageData, UIStateType, UIStoreSchema } from './@types/uiTypes';

const initialValues: UIStateType = {
  autoLogoutTimeStamp: -1,
  hideDemoModeBar: false,
  hideHeader: false,
  hideSideMenu: false,
  navDrawerExpanded: false,
  navDrawerPinned: Boolean(JSON.parse(localStorage.getItem(StorageKey.PIN_NAVIGATION_PANEL) || 'false')),
  navDrawerOpen: false,
  pageRef: null,
  showAssetSearch: false,
  toastMessages: new Map(),
  messageBoxes: new Map(),
  editDashboard: false,
  customBreadcrumbs: {},
  mainContentWidth: 0,
  mainContentHeight: 0,
  globalModalData: undefined,
  refreshTransactions: false,
  dateFormat: 'dd/MM/yyyy',
  showBaseCurrencySelectorMenu: false,
  showMarketTicker: localStorage.getItem('showMarketTicker_v1')
    ? localStorage.getItem('showMarketTicker_v1') === 'true'
    : true,
};

const store: UIStoreSchema = observable({
  /* observables */
  ...initialValues,

  setDateFormat: action((dateFormat: string) => {
    store.dateFormat = dateFormat;
  }),

  setMainContentWidth: action((width: number) => {
    store.mainContentWidth = width;
  }),

  setMainContentHeight: action((height: number) => {
    store.mainContentHeight = height;
  }),

  setPageRef: action((ref: HTMLElement | null) => {
    store.pageRef = ref;
  }),

  setHideDemoModeBar: action((hideDemoModeBar: boolean) => {
    store.hideDemoModeBar = hideDemoModeBar;
  }),

  setHideHeader: action((hideHeader: boolean) => {
    store.hideHeader = hideHeader;
  }),

  setHideSideMenu: action((hideSideMenu: boolean) => {
    store.hideSideMenu = hideSideMenu;
  }),

  setGlobalModalData: action((globalModalData?: GlobalModalData) => {
    store.globalModalData = globalModalData;
  }),

  setNavDrawerOpen: action((navDrawerOpen: boolean) => {
    store.navDrawerOpen = navDrawerOpen;
  }),

  setNavDrawerExpanded: action((navDrawerExpanded: boolean) => {
    store.navDrawerExpanded = navDrawerExpanded;
  }),

  setNavDrawerPinned: action((navDrawerPinned: boolean) => {
    store.navDrawerPinned = navDrawerPinned;
    localStorage.setItem(StorageKey.PIN_NAVIGATION_PANEL, navDrawerPinned ? 'true' : 'false');
  }),

  setAutoLogoutTimeStamp: action((autoLogoutTimeStamp: number) => {
    store.autoLogoutTimeStamp = autoLogoutTimeStamp;
  }),
  setShowAssetSearch: action((showAssetSearch: boolean) => {
    store.showAssetSearch = showAssetSearch;
  }),

  addToastMessage: action((toastMessage: ToastMessageData) => {
    const key = Date.now().toString(36) + Math.random().toString(36).substring(2);
    store.toastMessages.set(key, toastMessage);
  }),

  removeToastMessage: action((key: string) => {
    store.toastMessages.delete(key);
  }),

  clearToastMessages: action(() => {
    store.toastMessages.clear();
  }),

  addMessageBox: action((messageBox: MessageBoxData) => {
    const key = messageBox?.forceKey || Date.now().toString(36) + Math.random().toString(36).substring(2);
    store.messageBoxes.set(key, messageBox);
  }),

  removeMessageBox: action((key: string) => {
    store.messageBoxes.delete(key);
  }),

  setCustomHeaderOptions: action((customHeaderOptions?: React.ReactElement) => {
    store.customHeaderOptions = customHeaderOptions;
  }),

  setCustomBreadcrumb: action((crumbUrl: string, customBreadcrumb?: React.ReactElement) => {
    store.customBreadcrumbs[crumbUrl] = customBreadcrumb;
  }),

  setEditDashboard: action((editDashboard: boolean) => {
    store.editDashboard = editDashboard;
  }),

  setRefreshTransactions: action((refresh: boolean) => {
    store.refreshTransactions = refresh;
  }),

  setShowBaseCurrencySelectorMenu: action((showBaseCurrencySelectorMenu: boolean) => {
    store.showBaseCurrencySelectorMenu = showBaseCurrencySelectorMenu;
  }),

  setShowMarketTicker: action((showMarketTicker: boolean) => {
    localStorage.setItem(StorageKey.SHOW_MARKET_TICKER, JSON.stringify(showMarketTicker));
    store.showMarketTicker = showMarketTicker;
  }),
});

export { store as useUIStore };

import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Add } from '@swyftx/aviary/icons/outlined';

import { Individual } from '@shared/api';

import { IndividualWithUuid, MemberType } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';

import { AddIndividual } from './AddIndividual';
import { IndividualCard } from './IndividualCard';

interface PartialProps {
  title: string;
  members: IndividualWithUuid[];
  handleRemove: (memberUuid: string) => void;
  onCancel: () => void;
  addMemberOpen: boolean;
  toggleAddMemberOpen: (open: boolean) => void;
  memberType: MemberType;
  onEditMember?: (member: IndividualWithUuid) => void;
}

type Props =
  | (PartialProps & {
      onAddMember: (member: Individual, isShareholder: boolean) => void;
      onEditMember: (member: IndividualWithUuid, isShareholder: boolean) => void;
      shareholders: Individual[];
    })
  | (PartialProps & { onAddMember: (member: Individual) => void; onEditMember: (member: IndividualWithUuid) => void })
  | (PartialProps & {
      onAddMember: (member: Individual, isShareholder: boolean, isBeneficiary: boolean) => void;
      onEditMember: (member: IndividualWithUuid, isShareholder: boolean, isBeneficiary: boolean) => void;
      shareholders: Individual[];
      beneficiaries: Individual[];
    });

export const IndividualPageLayout: React.FC<Props> = (props) => {
  const {
    title,
    members,
    onAddMember,
    handleRemove,
    onCancel,
    addMemberOpen,
    toggleAddMemberOpen,
    memberType,
    onEditMember,
  } = props;

  const isShareholderVariant = () => onAddMember.length === 2;
  const isCorporateVariant = () => onAddMember.length === 3;

  const shareholders = 'shareholders' in props ? props.shareholders : undefined;
  const beneficiaries = 'beneficiaries' in props ? props.beneficiaries : undefined;

  return (
    <FlexLayout direction='column' spacing={16}>
      <Body weight='emphasis'>{title}</Body>
      {members.map((member, index) => (
        <IndividualCard
          key={`${member.individual.firstName}-${index}`}
          member={member}
          onRemove={() => handleRemove(member.uuid)}
          memberType={memberType}
          isCorporateTrustVariant={memberType === 'director' && isCorporateVariant()}
          shareholders={shareholders}
          beneficiaries={beneficiaries}
          onEditMember={onEditMember}
        />
      ))}
      {addMemberOpen && (
        <Card className='p-12'>
          {['director', 'shareholder'].includes(memberType) && isShareholderVariant() && (
            <AddIndividual
              onCompanySubmit={onAddMember as (member: Individual, isShareholder: boolean) => void}
              onCancel={onCancel}
              memberType={memberType}
            />
          )}
          {['trustee', 'member', 'beneficiary'].includes(memberType) && !isShareholderVariant() && (
            <AddIndividual
              onSmsfSubmit={onAddMember as (member: Individual) => void}
              onCancel={onCancel}
              memberType={memberType}
            />
          )}
          {memberType === 'director' && isCorporateVariant() && (
            <AddIndividual onCorporateTrustSubmit={onAddMember} onCancel={onCancel} memberType='director' />
          )}
        </Card>
      )}
      <div>
        <Button
          leadingIcon={<Add />}
          onClick={() => toggleAddMemberOpen(true)}
          disabled={addMemberOpen}
          tooltip={addMemberOpen ? `Please complete the unsaved ${memberType} first` : ''}
        >
          Add {memberType}
        </Button>
      </div>
    </FlexLayout>
  );
};

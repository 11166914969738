import { Candle, Resolution } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';

export interface ChartType {
  label: string;
  value: number;
}

export interface ChartInterval {
  label: string;
  value: string;
  group: string;
}

export interface ChartIndicator {
  label: string;
  value: string;
}

export interface ChartPriceSide {
  label: string;
  value: 'bid' | 'ask';
}

export interface AssetPriceHistoryTimeData {
  low: string | null;
  high: string | null;
  volume: string;
  change: string | null;
  open: string | null;
}

export interface AssetPriceHistoryData {
  day: AssetPriceHistoryTimeData;
  week: AssetPriceHistoryTimeData;
  month: AssetPriceHistoryTimeData;
  allTime: AssetPriceHistoryTimeData;
}

const tradingToSwyftx: Record<string, Resolution> = { 1: '1m', 5: '5m', 60: '1h', 240: '4h', '1D': '1d' };

// labels of below should correspond to keys in i18n files
const chartTypes: ChartType[] = [
  { label: 'bars', value: 0 },
  { label: 'candles', value: 1 },
  { label: 'line', value: 2 },
  { label: 'hollowCandles', value: 9 },
  { label: 'area', value: 3 },
  { label: 'heikinAshi', value: 8 },
  { label: 'baseline', value: 10 },
];

export const getChartTypeByValue = (value: number): ChartType | undefined => chartTypes.find((t) => t.value === value);

const chartIntervals: ChartInterval[] = [
  { label: '1m', value: '1', group: 'min' },
  { label: '5m', value: '5', group: 'min' },
  { label: '15m', value: '15', group: 'min' },
  { label: '30m', value: '30', group: 'min' },
  { label: '1h', value: '60', group: 'hrs' },
  { label: '2h', value: '120', group: 'hrs' },
  { label: '4h', value: '240', group: 'hrs' },
  { label: '12h', value: '720', group: 'hrs' },
  { label: '1Day', value: '1D', group: 'other' },
  { label: '1Week', value: '1W', group: 'other' },
  { label: '1Month', value: '1M', group: 'other' },
];

const chartIndicators: ChartIndicator[] = [
  { label: 'accumulationDistribution', value: 'Accumulation/Distribution' },
  { label: 'accumulativeSwingIndex', value: 'Accumulative Swing Index' },
  { label: 'advanceDecline', value: 'Advance/Decline' },
  { label: 'arnaudLegouxMovingAverage', value: 'Arnaud Legoux Moving Average' },
  { label: 'aroon', value: 'Aroon' },
  { label: 'averageDirectionalIndex', value: 'Average Directional Index' },
  { label: 'averageTrueRange', value: 'Average True Range' },
  { label: 'awesomeOscillator', value: 'Awesome Oscillator' },
  { label: 'balanceofPower', value: 'Balance of Power' },
  { label: 'bollingerBands', value: 'Bollinger Bands' },
  { label: 'bollingerBandsB', value: 'Bollinger Bands %B' },
  { label: 'bollingerBandsWidth', value: 'Bollinger Bands Width' },
  { label: 'chaikinMoneyFlow', value: 'Chaikin Money Flow' },
  { label: 'chaikinOscillator', value: 'Chaikin Oscillator' },
  { label: 'chandeKrollStop', value: 'Chande Kroll Stop' },
  { label: 'chandeMomentumOscillator', value: 'Chande Momentum Oscillator' },
  { label: 'chopZone', value: 'Chop Zone' },
  { label: 'choppinessIndex', value: 'Choppiness Index' },
  { label: 'commodityChannelIndex', value: 'Commodity Channel Index' },
  { label: 'connorsRsi', value: 'Connors RSI' },
  { label: 'coppockCurve', value: 'Coppock Curve' },
  { label: 'correlationCoefficient', value: 'Correlation Coefficient' },
  { label: 'detrendedPriceOscillator', value: 'Detrended Price Oscillator' },
  { label: 'directionalMovement', value: 'Directional Movement' },
  { label: 'donchianChannels', value: 'Donchian Channels' },
  { label: 'doubleEma', value: 'Double EMA' },
  { label: 'emaCross', value: 'EMA Cross' },
  { label: 'easeOfMovement', value: 'Ease Of Movement' },
  { label: 'eldersForceIndex', value: "Elder's Force Index" },
  { label: 'envelope', value: 'Envelope' },
  { label: 'fisherTransform', value: 'Fisher Transform' },
  { label: 'historicalVolatility', value: 'Historical Volatility' },
  { label: 'hullMovingAverage', value: 'Hull Moving Average' },
  { label: 'ichimokuCloud', value: 'Ichimoku Cloud' },
  { label: 'keltnerChannels', value: 'Keltner Channels' },
  { label: 'klingerOscillator', value: 'Klinger Oscillator' },
  { label: 'knowSureThing', value: 'Know Sure Thing' },
  { label: 'leastSquaresMovingAverage', value: 'Least Squares Moving Average' },
  { label: 'linearRegressionCurve', value: 'Linear Regression Curve' },
  { label: 'maCross', value: 'MA Cross' },
  { label: 'mAwithEmaCross', value: 'MA with EMA Cross' },
  { label: 'macd', value: 'MACD' },
  { label: 'massIndex', value: 'Mass Index' },
  { label: 'mcGinleyDynamic', value: 'McGinley Dynamic' },
  { label: 'momentum', value: 'Momentum' },
  { label: 'moneyFlow', value: 'Money Flow' },
  { label: 'movingAverage', value: 'Moving Average' },
  { label: 'movingAverageChannel', value: 'Moving Average Channel' },
  { label: 'movingAverageExponential', value: 'Moving Average Exponential' },
  { label: 'movingAverageWeighted', value: 'Moving Average Weighted' },
  { label: 'netVolume', value: 'Net Volume' },
  { label: 'onBalanceVolume', value: 'On Balance Volume' },
  { label: 'parabolicSar', value: 'Parabolic SAR' },
  { label: 'pivotPointsStandard', value: 'Pivot Points Standard' },
  { label: 'priceChannel', value: 'Price Channel' },
  { label: 'priceOscillator', value: 'Price Oscillator' },
  { label: 'priceVolumeTrend', value: 'Price Volume Trend' },
  { label: 'rateOfChange', value: 'Rate Of Change' },
  { label: 'ratio', value: 'Ratio' },
  { label: 'relativeStrengthIndex', value: 'Relative Strength Index' },
  { label: 'relativeVigorIndex', value: 'Relative Vigor Index' },
  { label: 'relativeVolatilityIndex', value: 'Relative Volatility Index' },
  { label: 'smiErgodicIndicatorOscillator', value: 'SMI Ergodic Indicator/Oscillator' },
  { label: 'smoothedMovingAverage', value: 'Smoothed Moving Average' },
  { label: 'spread', value: 'Spread' },
  { label: 'stochastic', value: 'Stochastic' },
  { label: 'stochasticRsi', value: 'Stochastic RSI' },
  { label: 'superTrend', value: 'SuperTrend' },
  { label: 'trix', value: 'TRIX' },
  { label: 'tripleEma', value: 'Triple EMA' },
  { label: 'trueStrengthIndicator', value: 'True Strength Index' },
  { label: 'ultimateOscillator', value: 'Ultimate Oscillator' },
  { label: 'vwap', value: 'VWAP' },
  { label: 'vwma', value: 'VWMA' },
  { label: 'volume', value: 'Volume' },
  { label: 'volumeOscillator', value: 'Volume Oscillator' },
  { label: 'vortexIndicator', value: 'Vortex Indicator' },
  { label: 'williamsR', value: 'Williams %R' },
  { label: 'williamsAlligator', value: 'Williams Alligator' },
  { label: 'williamsFractal', value: 'Williams Fractal' },
  { label: 'zigZag', value: 'Zig Zag' },
];

const chartPriceSide: ChartPriceSide[] = [
  { label: 'ask', value: 'ask' },
  { label: 'bid', value: 'bid' },
];

const parseAssetPricePeriod = (candles: Candle[], currentPrice: string): AssetPriceHistoryTimeData => {
  const hasCandles = candles?.length;
  const close = hasCandles ? Big(candles[0].close).toString() : null;
  const open = hasCandles ? Big(candles[0].open).toString() : null;
  const price = Big(currentPrice);
  const diff = price.minus(close ?? 0);

  const high = hasCandles
    ? Big(candles.reduce((prev, curr) => (prev.high > curr.high ? prev : curr)).high).toString()
    : null;
  const low = hasCandles
    ? Big(candles.reduce((prev, curr) => (prev.low < curr.low ? prev : curr)).low).toString()
    : null;

  return {
    high,
    low,
    change: price && hasCandles ? diff.toString() : null,
    volume:
      candles
        .map((c) => c.volume)
        .reduce((prev, curr) => Big(prev).plus(Big(curr)).toNumber(), 0)
        ?.toString() || '',
    open,
  };
};

const getAssetPriceHistory = (candles: Candle[], currentPrice: string): AssetPriceHistoryData => {
  const candlesInDay = 1;

  const dayCandles: Candle[] = candles.slice(-candlesInDay);
  const weekCandles: Candle[] = candles.slice(-(candlesInDay * 7));
  const monthCandles: Candle[] = candles.slice(Math.max(0, -(candlesInDay * 30)));

  return {
    day: parseAssetPricePeriod(dayCandles, currentPrice),
    week: parseAssetPricePeriod(weekCandles, currentPrice),
    month: parseAssetPricePeriod(monthCandles, currentPrice),
    allTime: {
      high: '',
      low: '',
      change: '',
      volume: '',
      open: '',
    },
  };
};

export const chartService = {
  chartTypes,
  chartIntervals,
  tradingToSwyftx,
  chartIndicators,
  chartPriceSide,
  getAssetPriceHistory,
};

/* eslint-disable no-bitwise */
import { useCallback } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { AssetColors } from '@utils/assets';

const useColor = () => {
  const { isDarkMode } = useTailwindTheme();

  const isValidRGBColorValue = (rgb?: string): boolean => {
    const hexColorRegex = new RegExp(/^#?([A-Fa-f0-9]{6})$/);
    return hexColorRegex.test(rgb ?? '');
  };

  const getBrightnessForHexColor = useCallback((color: string) => {
    if (isValidRGBColorValue(color)) {
      try {
        const c = color?.length === 7 ? color.substring(1) : color; // strip #
        const rgb = parseInt(c, 16); // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff; // extract red
        const g = (rgb >> 8) & 0xff; // extract green
        const b = (rgb >> 0) & 0xff; // extract blue

        const luminosity = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        return luminosity;
      } catch (error) {
        console.error('Unable to get colorluminosity ');
      }
    } else {
      console.error('Invalid RGB color');
    }

    return 0;
  }, []);

  const isContrastSufficientColor = useCallback(
    (darkMode: boolean, rgb: string): boolean => {
      if (isValidRGBColorValue(rgb)) {
        const colorBrightness = getBrightnessForHexColor(rgb);
        return darkMode ? colorBrightness > 100 : colorBrightness < 210;
      }
      return false;
    },
    [getBrightnessForHexColor],
  );

  const getAssetColor = useCallback(
    (assetCode?: string) => {
      if (!assetCode) return AssetColors['UNKNOWN'];

      const color = AssetColors[assetCode];

      if (isContrastSufficientColor(isDarkMode, color)) {
        return color;
      } else {
        return getComputedStyle(document.documentElement).getPropertyValue('--color-background-surface-inverse');
      }
    },
    [isContrastSufficientColor, isDarkMode],
  );

  return { getAssetColor };
};

export { useColor };

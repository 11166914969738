import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';

import { TradeData, TradeUIData, UniversalTradeStoreSchema } from './@types/universalTradeTypes';

const MAX_TRADE_PERCENTAGE = 100;
const MIN_TRADE_PERCENTAGE = 1;

const createTradeData = (schema: UniversalTradeStoreSchema) => {
  const { tradeFrom, tradeTo, tradeData } = schema;
  const tradeDataCache = { ...tradeData };
  const newTradeData: { [key: string]: TradeData } = {};

  tradeFrom.forEach((from) => {
    tradeTo.forEach((to) => {
      const key = `${from}_${to}`;
      const data = tradeDataCache[key] || {};

      // If we are doing a 1 to 1 trade reset the balances
      if (tradeFrom.length === 1 && tradeTo.length === 1) {
        data.balance = '';
      }

      newTradeData[key] = {
        ...data,
        from,
        to,
        limit: from,
        slippagePercentage: 0,
        isHighSlippage: false,
        balance: '',
      };
    });
  });

  return newTradeData;
};

const createTradeUIData = (schema: UniversalTradeStoreSchema) => {
  const { tradeFrom, tradeTo, tradeUIData } = schema;
  const tradeDataUICache = { ...tradeUIData };
  const newTradeUIData: { [key: string]: TradeUIData } = {};

  tradeFrom.forEach((from) => {
    tradeTo.forEach((to) => {
      const key = `${from}_${to}`;
      const data = tradeDataUICache[key] || {};

      // If we are doing a 1 to 1 trade reset the percentages and error
      if (tradeFrom.length === 1 && tradeTo.length === 1) {
        data.percentage = 0;
        data.clientSideError = '';
      }

      newTradeUIData[key] = { ...data, from, to };
    });
  });

  return newTradeUIData;
};

const updateTradeDataPercentages = (
  schema: UniversalTradeStoreSchema,
  updatingKey?: string,
  data?: Partial<TradeUIData>,
) => {
  const { tradeUIData } = schema;

  const tradeUIDataCache = { ...tradeUIData };
  const dataValues = Object.values(tradeUIDataCache);
  const dataKeys = Object.keys(tradeUIDataCache);

  const indexAdjustment = updatingKey && data?.percentage !== undefined ? 1 : 0;
  const lockedValues = dataValues.filter((dv) => dv.locked);
  const assetToAssignCount = dataValues.length - lockedValues.length - indexAdjustment;

  const lockedPercentage = lockedValues.reduce((prev, curr) => prev + (curr.percentage || 0), 0);
  const minimumPercentageAssigned = assetToAssignCount * MIN_TRADE_PERCENTAGE;
  const maxNewPercentage = MAX_TRADE_PERCENTAGE - lockedPercentage - minimumPercentageAssigned;

  const clampedNewPercentage = data?.percentage
    ? Math.min(Math.max(data?.percentage || 0, MIN_TRADE_PERCENTAGE), maxNewPercentage)
    : 0;

  const remainingPercentToAssign = MAX_TRADE_PERCENTAGE - lockedPercentage - clampedNewPercentage;

  const nonRoundedPercentageSplit = remainingPercentToAssign / assetToAssignCount;
  const roundedPercentageSplit = Math.floor(nonRoundedPercentageSplit);

  const splitDiff = remainingPercentToAssign - roundedPercentageSplit * assetToAssignCount;
  const splitDiffDistribution = Math.ceil(splitDiff / assetToAssignCount);

  let diffRemaining = splitDiff;

  dataKeys.forEach((key: string) => {
    if (key === updatingKey) {
      tradeUIDataCache[key].percentage = clampedNewPercentage;
      tradeUIDataCache[key].loading = true;
      return;
    }

    const excluded = lockedValues.find((la) => key === `${la.from}_${la.to}`);

    if (excluded) {
      return;
    }

    tradeUIDataCache[key].percentage =
      diffRemaining > 0 ? roundedPercentageSplit + splitDiffDistribution : roundedPercentageSplit;
    tradeUIDataCache[key].loading = true;

    diffRemaining -= splitDiffDistribution;
  });

  return tradeUIDataCache;
};

const getPercentage = (totalVal?: string, val?: string) => {
  if (!totalVal || !val) return 0;

  return Big(val).div(totalVal).times(100).round(0, 0).toNumber();
};

const getUpdatingBalance = (balancePercentage?: number, calculatedPercentage?: number, balance?: string) => {
  if (!balance || balancePercentage === undefined || calculatedPercentage === undefined) return '0';

  if (balancePercentage !== calculatedPercentage) {
    return Big(balance).times(Big(calculatedPercentage).div(100)).toString();
  }
  return Big(balance).times(Big(balancePercentage).div(100)).toString();
};

const updateTradeDataBalances = (schema: UniversalTradeStoreSchema, updatingKey?: string, updateLocked = false) => {
  const { tradeUIData, tradeData, maxTradeValue } = schema;
  const tradeUIDataCache = { ...tradeUIData };
  const tradeDataCache = { ...tradeData };

  const indexAdjustment = updatingKey && tradeUIDataCache[updatingKey]?.percentage !== undefined ? 1 : 0;

  const dataKeys = Object.keys(tradeDataCache);
  const dataValues = Object.values(tradeDataCache);

  const updatingPercentage = getPercentage(maxTradeValue, tradeDataCache[updatingKey || '']?.balance);

  const updatingBalance = getUpdatingBalance(
    updatingPercentage,
    tradeUIDataCache[updatingKey || '']?.percentage,
    maxTradeValue,
  );

  const lockedValues = dataValues.filter((dv) => {
    const key = `${dv.from}_${dv.to}`;
    return !updateLocked && tradeUIDataCache[key].locked;
  });
  const assetToAssignCount = dataValues.length - lockedValues.length - indexAdjustment;

  const lockedBalances = lockedValues.reduce((prev, curr) => prev + (Big(curr.balance).toNumber() || 0), 0);

  const remainingBalanceToAssign = Big(maxTradeValue).minus(lockedBalances).minus(updatingBalance).toNumber();

  let runningBalanceDistributed = 0;
  let assetsDistributed = 0;

  dataKeys.forEach((key: string) => {
    const uiData = tradeUIDataCache[key];
    const fromAsset = assetService.getAsset(tradeDataCache[key].from);

    // Dont update the balance of the key that is updating
    if (key === updatingKey || (!updateLocked && uiData.locked)) return;

    const distributedAmount = Big(maxTradeValue).times(Big(uiData?.percentage?.toString()).div(100)).toString();
    const distributedDiff = Big(remainingBalanceToAssign).minus(runningBalanceDistributed);

    if (assetsDistributed === assetToAssignCount - 1) {
      tradeDataCache[key].balance = Big(distributedDiff).round(fromAsset?.price_scale, 0).toString();
    } else {
      tradeDataCache[key].balance = Big(distributedAmount).round(fromAsset?.price_scale, 0).toString();
    }

    runningBalanceDistributed += Big(distributedAmount).toNumber();
    assetsDistributed += 1;
  });

  return tradeDataCache;
};

export { createTradeData, updateTradeDataPercentages, createTradeUIData, updateTradeDataBalances };

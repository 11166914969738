import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { useSwyftxProTradeAmount } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from 'src/lib/trade-pro/hooks/useSwyftxProTradeAssets';
import { useSwyftxProTradeStopLossDetails } from 'src/lib/trade-pro/hooks/useSwyftxProTradeStopLoss';
import { useSwyftxProTradeTakeProfitDetails } from 'src/lib/trade-pro/hooks/useSwyftxProTradeTakeProfit';
import { useSwyftxProTradeType } from 'src/lib/trade-pro/hooks/useSwyftxProTradeType';

import { SwyftxProTradeBreakdownItem } from './SwyftxProTradeBreakdownItem';

const SwyftxProTradeBreakdown: React.FC = () => {
  const { total, amount } = useSwyftxProTradeAmount();
  const { baseAsset, selectedAsset, limitAsset } = useSwyftxProTradeAssets();
  const { isOco } = useSwyftxProTradeType();
  const { simpleStopLossTotal, stopLossPercentage, stopLossPrice, stopLossTotalLoss } =
    useSwyftxProTradeStopLossDetails();
  const { simpleTakeProfitTotal, takeProfitTotal, takeProfitTotalProfit, takeProfitPercentage, takeProfitPrice } =
    useSwyftxProTradeTakeProfitDetails();

  return (
    <FlexLayout className='w-full' alignItems='center' justifyContent='space-between' direction='column' spacing={12}>
      <div className='h-[1px] w-full border-t border-dashed border-color-border-main' />
      <SwyftxProTradeBreakdownItem
        title='Amount'
        value={formatCurrency(amount || '0', selectedAsset, {
          appendCode: true,
          hideCode: false,
          isApproximate: limitAsset?.code === baseAsset?.code,
        })}
      />

      {!isOco && (
        <>
          {total && takeProfitPrice && takeProfitTotal.gt(0) && (
            <SwyftxProTradeBreakdownItem
              title='Est. Profit'
              value={`≈ (${takeProfitPercentage.toFixed(2)}%) ${formatCurrency(
                takeProfitTotalProfit || '0',
                baseAsset,
                {
                  isApproximate: limitAsset?.code === selectedAsset?.code,
                },
              )}`}
              color={Big(takeProfitTotalProfit).gt(0) ? 'success' : 'error'}
              valueWeight='emphasis'
            />
          )}
          {total && stopLossPrice && stopLossTotalLoss.gt(0) && (
            <SwyftxProTradeBreakdownItem
              title='Est. Loss'
              value={`≈ (${stopLossPercentage.abs().toFixed(2)}%) ${formatCurrency(stopLossTotalLoss.abs(), baseAsset, {
                isApproximate: limitAsset?.code === selectedAsset?.code,
              })}`}
              color='error'
              valueWeight='emphasis'
            />
          )}
          <SwyftxProTradeBreakdownItem
            title='Total order'
            value={formatCurrency(total || '0', baseAsset, {
              isApproximate: limitAsset?.code === selectedAsset?.code,
              appendCode: true,
              hideCode: false,
            })}
            valueWeight='emphasis'
          />
        </>
      )}

      {isOco && (
        <>
          <SwyftxProTradeBreakdownItem
            title='Total order (TP)'
            value={formatCurrency(simpleTakeProfitTotal || '0', baseAsset, {
              isApproximate: limitAsset?.code === selectedAsset?.code,
            })}
            valueWeight='emphasis'
          />
          <SwyftxProTradeBreakdownItem
            title='Total order (SL)'
            value={formatCurrency(simpleStopLossTotal || '0', baseAsset, {
              isApproximate: limitAsset?.code === selectedAsset?.code,
            })}
            valueWeight='emphasis'
          />
        </>
      )}
    </FlexLayout>
  );
};

export { SwyftxProTradeBreakdown };

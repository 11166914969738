import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { ContentBanner } from '@global-components/ContentBanner';

interface Props {
  bannerIcon: JSX.Element;
}

export const SowAwaitingActionContent: React.FC<Props> = (props) => {
  const { bannerIcon } = props;

  const Description: React.FC = () => (
    <FlexLayout direction='row' spacing={4}>
      <Body size='small' color='secondary'>
        We are currently reviewing your submission. Our team will be in touch if any further documentation is needed.
      </Body>
    </FlexLayout>
  );

  return (
    <FlexLayout direction='column'>
      <ContentBanner
        actionLabel='Complete source of wealth'
        description={<Description />}
        title="Hold tight. We're reviewing your source of wealth check"
        icon={bannerIcon}
      />
    </FlexLayout>
  );
};

import React from 'react';

import { Backdrop } from '@swyftx/aviary/atoms/Backdrop';

import { TourGuideClip } from './TourGuideClip';
import { useTourGuide } from './useTourGuide';

const TourGuide: React.FC = () => {
  const { element, showBackdrop, stopTour, onNextStep, targetRect, borderRadius } = useTourGuide();

  return (
    <>
      <Backdrop
        className='z-tour-backdrop cursor-pointer bg-opacity-50'
        show={showBackdrop}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (
            targetRect &&
            e.pageX > targetRect.x &&
            e.pageX < targetRect.x + targetRect.width &&
            e.pageY > targetRect.y &&
            e.pageY < targetRect.y + targetRect.height
          ) {
            onNextStep();
          } else {
            stopTour(true);
          }
        }}
        style={{
          maskImage: 'url(#tourGuideMask)',
        }}
      />
      {showBackdrop && targetRect && <TourGuideClip rect={targetRect} borderRadius={borderRadius} />}
      {element}
    </>
  );
};

export { TourGuide };

import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetType, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency, FormatCurrencyOptions } from '@shared/utils';

import { capitalize } from '@utils/formatting';

import { observer } from 'mobx-react-lite';
import { DustingModal } from 'src/lib/markets/components/DustingModal';
import { useDusting } from 'src/lib/markets/hooks/useDusting';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';
import { QuickBuyStep, QuickBuyType } from 'src/lib/trade/types/Trade.types';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

type Props = {
  limitAsset?: Asset;
  countryAsset?: Asset;
  countryBalance?: UserBalance;
  selectedAsset?: Asset;
  selectedAssetBalance?: UserBalance;
  buyType: QuickBuyType;
  error: React.ReactNode;
  loading?: boolean;
  isVerified?: boolean;
  setTabValue: (newVal: { value: string; assetCode: string }, oldVal?: { value: string; assetCode: string }) => void;
  setAmount: (newAmount: string, forceValue: boolean) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<QuickBuyStep>>;
};

const QuickBuyBalance: React.FC<Props> = observer(
  ({
    limitAsset,
    countryAsset,
    countryBalance,
    selectedAsset,
    selectedAssetBalance,
    buyType,
    loading,
    isVerified,
    error,
    setTabValue,
    setAmount,
    setCurrentStep,
  }) => {
    const { convertRate } = RatesStore.useRatesStore;
    const { openModal } = useModal();
    const { getBalance } = usePortfolioBalance();
    const { navigate } = useNavigateRoute();
    const { hasTraded } = useProfileStatus();
    const { dustMessage } = useDusting();
    const { getMinimumOrderAmount } = RatesStore.useRatesStore;
    const assetToUse = useMemo(
      () => (buyType === 'buy' ? countryAsset : selectedAsset),
      [buyType, countryAsset, selectedAsset],
    );

    const activeBalance = useMemo(() => {
      if (buyType === 'buy') {
        return countryBalance;
      } else if (buyType === 'sell') {
        return selectedAssetBalance;
      }
    }, [buyType, countryBalance, selectedAssetBalance]);

    const balanceToUse = useMemo((): string => {
      if (!activeBalance || !countryAsset || !limitAsset || !selectedAsset) return '';

      // We are limiting on the asset we want to buy
      const limitingBuyCheck = buyType === 'buy' && limitAsset?.id === selectedAsset?.id;
      const limitingSellCheck = buyType === 'sell' && limitAsset?.id !== selectedAsset?.id;
      const formatOpts: FormatCurrencyOptions = {
        hideCode: true,
        appendCode: false,
        hideSeparator: true,
        hideSymbol: true,
        priceScale: limitAsset.assetType === AssetType.Fiat ? 2 : undefined,
      };

      if (limitingBuyCheck || limitingSellCheck) {
        const from = buyType === 'buy' ? countryAsset : selectedAsset;
        const to = buyType === 'buy' ? limitAsset : countryAsset;

        const convertedAmount = convertRate(
          from,
          to,
          activeBalance?.availableBalance || 0,
          buyType === 'buy' ? 'askPrice' : 'bidPrice',
        );

        return formatCurrency(convertedAmount, limitAsset, formatOpts, undefined, true);
      }

      return formatCurrency(activeBalance?.availableBalance || 0, assetToUse, formatOpts, undefined, true);
    }, [activeBalance, assetToUse, buyType, convertRate, countryAsset, limitAsset, selectedAsset]);

    const balanceElement = useMemo(() => {
      if (!isVerified) {
        return (
          <Body color='accent' size='small' weight='emphasis' loading={loading} className='text-center'>
            Account verification required to trade.{' '}
            <span className='cursor-pointer underline' onClick={() => navigate(NavigationURLs.ProfileVerification)}>
              Verify account now.
            </span>
          </Body>
        );
      }

      if (
        buyType === 'buy' &&
        countryAsset &&
        getMinimumOrderAmount(countryAsset).gt(getBalance(countryAsset.id)?.availableBalance || '')
      ) {
        if (!hasTraded) {
          return (
            <Body color='accent' size='small' weight='emphasis' loading={loading} className='text-center'>
              <span
                className='cursor-pointer underline'
                onClick={() => openModal(Modals.DepositReceive, { selectedAsset: countryAsset })}
              >
                Deposit {countryAsset.code}
              </span>{' '}
              to start your trading journey
            </Body>
          );
        } else {
          return (
            <Body color='accent' size='small' weight='emphasis' loading={loading} className='text-center'>
              Insufficent funds available to buy.{' '}
              <span
                className='cursor-pointer underline'
                onClick={() => openModal(Modals.DepositReceive, { selectedAsset: countryAsset })}
              >
                Deposit {countryAsset.code}
              </span>
            </Body>
          );
        }
      }

      if (
        buyType === 'sell' &&
        selectedAsset &&
        getMinimumOrderAmount(selectedAsset).gt(getBalance(selectedAsset.id)?.availableBalance || '')
      ) {
        if (!dustMessage && Big(getBalance(selectedAsset.id)?.availableBalance).gt(0)) {
          return (
            <Body color='accent' size='small' weight='emphasis' loading={loading} className='text-center'>
              Insufficent funds available to sell.{' '}
              <DustingModal>
                <span className='cursor-pointer underline'>Dust small balances.</span>
              </DustingModal>
            </Body>
          );
        } else {
          return (
            <Body color='error' size='small' weight='emphasis' loading={loading} className='text-center'>
              Insufficent funds available to sell.{' '}
              <span className='cursor-pointer underline' onClick={() => setCurrentStep('assetPicker')}>
                Change asset.
              </span>
            </Body>
          );
        }
      }

      if (error) {
        return (
          <Body color='error' size='small' weight='emphasis' className='text-center' loading={loading}>
            {error}
          </Body>
        );
      }

      if ((buyType == 'buy' && selectedAsset?.buyDisabled) || (buyType === 'sell' && !selectedAsset?.sellEnabled)) {
        return (
          <Body color='error' size='small' weight='emphasis' loading={loading} className='text-center'>
            {capitalize(buyType)}ing {selectedAsset?.name} is currently disabled.{' '}
            <span className='cursor-pointer underline' onClick={() => setCurrentStep('assetPicker')}>
              Change asset.
            </span>
          </Body>
        );
      }

      return (
        <Body color='accent' size='small' weight='emphasis' loading={loading} className='text-center'>
          You can {buyType} up to{' '}
          <span
            className='cursor-pointer underline'
            onClick={() => {
              setAmount(balanceToUse, true);
              setTabValue({ value: '', assetCode: '' });
            }}
          >
            {formatCurrency(balanceToUse, limitAsset, undefined, undefined, true)}
          </span>
        </Body>
      );
    }, [
      balanceToUse,
      buyType,
      countryAsset,
      dustMessage,
      error,
      getBalance,
      getMinimumOrderAmount,
      hasTraded,
      isVerified,
      limitAsset,
      loading,
      navigate,
      openModal,
      selectedAsset,
      setAmount,
      setCurrentStep,
      setTabValue,
    ]);

    if (!countryAsset || !selectedAsset) return null;

    return (
      <FlexLayout alignItems='end' className='h-42'>
        {balanceElement}
      </FlexLayout>
    );
  },
);

export { QuickBuyBalance };

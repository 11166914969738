import React from 'react';
import { RouteObject } from 'react-router-dom';

import PublicRoute from '@routes/PublicRoute';

import Auth from '@Auth/Auth';

import { NavigationURLs } from 'src/lib/navigation/types';

export const AuthRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Auth,
    element: <PublicRoute element={<Auth />} />,
  },
];

import { useCallback, useEffect, useState } from 'react';

import { withdrawalService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

export type WithdrawalBlocked = {
  blocked: boolean;
  message: string;
  loading: boolean;
};

const useWithdrawalPermission = (assetCode?: string) => {
  const [blockedStatus, setBlockedStatus] = useState<WithdrawalBlocked>({ blocked: false, message: '', loading: true });
  const { userProfile } = UserStore.useUserStore;
  const countryAsset = useCountryAsset();

  const checkPermission = useCallback(async () => {
    // When we check the permission, set to loading first
    // If we haven't assigned an asset code, default to not blocked until they select an asset
    if (!assetCode) {
      setBlockedStatus({
        blocked: false,
        message: '',
        loading: false,
      });
      return;
    }

    const response = await withdrawalService.getWithdrawalPermissions(assetCode ?? (countryAsset?.code || 'AUD'));
    setBlockedStatus({
      blocked: !response.canWithdraw,
      message: !response.canWithdraw ? response.message : '',
      loading: false,
    });
  }, [assetCode, countryAsset]);

  useEffect(() => {
    if (userProfile) checkPermission();
  }, [userProfile, checkPermission]);

  return blockedStatus;
};

export { useWithdrawalPermission };

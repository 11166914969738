/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { AssetFilter } from '@hooks/Assets/useAssets';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  filter: AssetFilter;
  filterSearch: string;
};

const TradeAssetSelectorEmpty: React.FC<Props> = observer(({ filter, filterSearch }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradePanel' });
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { openModal } = useModal();
  const countryAsset = useCountryAsset();
  const portfolioTransferEnabled = isFeatureEnabled(AppFeature.PortfolioTransfer);
  const { navigate } = useNavigateRoute();

  const getMessageText = () => {
    if (filter === AssetFilter.Owned) {
      return t('labels.depositFunds');
    }

    if (filter === AssetFilter.Favourites && !filterSearch) {
      return t('labels.noFavourites');
    }

    if (filterSearch) {
      if (filter === AssetFilter.Bundles) {
        return t('labels.noBundles');
      }
      return t('labels.noAssets');
    }

    return t('labels.somethingWrong');
  };

  const onDepositFunds = () => {
    openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
  };

  return (
    <FlexLayout direction='column' spacing={32}>
      {filter === AssetFilter.Owned && (
        <FlexLayout direction='column' className='items-center justify-center p-32'>
          <FlexLayout direction='column' className='items-center'>
            <Body weight='emphasis' color='primary'>
              {t('labels.empty')}
            </Body>
            <Body color='secondary' size='small'>
              {getMessageText()}
            </Body>
          </FlexLayout>
          <FlexLayout direction='column' className='mt-24 flex w-full items-center' spacing={32}>
            <Image image='deposit' usePalette className='h-96 w-96' />
            <FlexLayout direction='column' className='w-full' spacing={8}>
              <Button
                disabled={!isFeatureEnabled(AppFeature.Wallets)}
                onClick={onDepositFunds}
                size='lg'
                className='w-full'
              >
                {t('buttonLabels.depositFunds')}
              </Button>
              {portfolioTransferEnabled && (
                <Button
                  onClick={() => navigate(NavigationURLs.PortfolioTransfer)}
                  size='lg'
                  variant='outlined'
                  className='w-full'
                >
                  Portfolio transfer
                </Button>
              )}
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

TradeAssetSelectorEmpty.displayName = 'TradeAssetSelectorEmpty';

export { TradeAssetSelectorEmpty };

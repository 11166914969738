import { useCallback } from 'react';

import { UIStore } from '@shared/store';

const useMarketTicker = () => {
  const { showMarketTicker, setShowMarketTicker } = UIStore.useUIStore;

  const toggleMarketTicker = useCallback(() => {
    setShowMarketTicker(!showMarketTicker);
  }, [setShowMarketTicker, showMarketTicker]);

  return {
    showMarketTicker,
    toggleMarketTicker,
    setShowMarketTicker,
  };
};

export { useMarketTicker };

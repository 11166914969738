import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';

import { observer } from 'mobx-react-lite';

const SwyftxProTradeMultipleWarnings: React.FC = observer(() => (
  <FlexLayout spacing={8} alignItems='center' justifyContent='start'>
    <AlertFilled className='h-12 w-12' />
    <Body size='small' weight='emphasis'>
      Multiple asset warnings
    </Body>
  </FlexLayout>
));

export { SwyftxProTradeMultipleWarnings };

import React, { useMemo, useCallback } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { InputDropdown } from '@swyftx/aviary/molecules/InputDropdown/InputDropdown';

import { useSourceOfWealthSelector, useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';
import { Controller, useForm } from 'react-hook-form';

import { SourceOfWealthStepContainer } from './SourceOfWealthStepContainer';
import { useOccupationAndIndustry } from '../hooks/useOccupationAndIndustry';

type FormInputs = {
  occupation?: string;
  industry?: string;
  employmentType?: string;
};

export const CURRENTLY_WORKING_VALUES = ['Full-Time', 'Part-Time', 'Casual', 'Self-Employed'];

const OccupationStep: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();
  const verificationData = useSourceOfWealthSelector((state) => state.context.verificationData);
  const { occupations, industries, employmentTypes } = useOccupationAndIndustry();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      occupation: verificationData.occupation,
      employmentType: verificationData.employmentType,
      industry: verificationData.industry,
    },
  });

  const employmentStatus = watch('employmentType');

  const isCurrentlyWorking = useMemo(
    () => employmentStatus && CURRENTLY_WORKING_VALUES.includes(employmentStatus),
    [employmentStatus],
  );

  const onSubmit = useCallback(
    (data: FormInputs) => {
      if (isCurrentlyWorking) {
        return sourceOfWealthService.send({ type: 'NEXT', data });
      }
      return sourceOfWealthService.send({
        type: 'NEXT',
        data: { employmentType: employmentStatus, industry: undefined, occupation: undefined },
      });
    },
    [employmentStatus, isCurrentlyWorking, sourceOfWealthService],
  );

  return (
    <SourceOfWealthStepContainer
      customTitle='What is your occupation?'
      onSubmit={handleSubmit(onSubmit)}
      submitDisabled={!isValid}
    >
      <FlexLayout direction='column' spacing={24}>
        <FlexLayout direction='column' spacing={8}>
          <Body size='small' weight='emphasis'>
            What is your current employment status?
          </Body>
          <Controller
            name='employmentType'
            control={control}
            rules={{ required: 'Please select your employment status' }}
            render={({ field }) => (
              <Select
                placeholder='Please select an option'
                value={watch('employmentType')}
                onValueChange={(e: string) => {
                  if (e === '') return;
                  return field.onChange(e);
                }}
                items={employmentTypes.map(({ name }) => ({ label: name, value: name }))}
                triggerClassName='!min-h-[3.3rem] items-center'
              />
            )}
          />
        </FlexLayout>

        {isCurrentlyWorking && (
          <>
            <FlexLayout direction='column' spacing={8}>
              <Body size='small' weight='emphasis'>
                What is your job title?
              </Body>
              <Controller
                name='occupation'
                control={control}
                rules={{ required: 'Please select your job title' }}
                render={({ field }) => (
                  <InputDropdown
                    placeholder='Occupation'
                    value={watch('occupation')}
                    onChange={(e) => field.onChange(e)}
                    items={occupations.map(({ occupation }) => occupation)}
                  />
                )}
              />
            </FlexLayout>

            <FlexLayout direction='column' spacing={8}>
              <Body size='small' weight='emphasis'>
                What industry do you work in?
              </Body>
              <Controller
                name='industry'
                control={control}
                rules={{ required: 'Please select your industry' }}
                render={({ field }) => (
                  <InputDropdown
                    placeholder='Industry'
                    value={watch('industry')}
                    onChange={(e) => field.onChange(e)}
                    items={industries.map(({ industry }) => industry)}
                  />
                )}
              />
            </FlexLayout>
          </>
        )}
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

export { OccupationStep };

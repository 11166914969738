import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import entityService from '@shared/services/entityService';
import { UserStore } from '@shared/store';
import entityHelpers from '@shared/utils/lib/entityHelpers';

export type EntityVerificationStep = {
  id: string;
  completed: boolean;
  title: string;
  content: string;
};

export const useEntityVerification = () => {
  const { t } = useTranslation('profile');
  const { entityMembers } = UserStore.useUserStore;

  useEffect(() => {
    entityService.updateMembers();
  }, []);

  const allMembersVerified = entityHelpers.isAllMembersVerified(entityMembers);
  const isEntityVerified = entityService.isEntityVerified();

  const steps: EntityVerificationStep[] = useMemo(
    () => [
      {
        id: 'create-entity-step',
        completed: true,
        title: t('entities.entityVerification.section.steps.createEntity.title'),
        content: t('entities.entityVerification.section.steps.createEntity.content'),
      },
      {
        id: 'all-members-verified-entity-step',
        completed: allMembersVerified,
        title: t('entities.entityVerification.section.steps.membersVerified.title'),
        content: t('entities.entityVerification.section.steps.membersVerified.content'),
      },
      {
        id: 'verify-entity-step',
        completed: isEntityVerified,
        title: t('entities.entityVerification.section.steps.verify.title'),
        content: t('entities.entityVerification.section.steps.verify.content'),
      },
    ],
    [allMembersVerified, isEntityVerified, t],
  );

  return { steps };
};

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

interface SingleWalletTradingInfoPairProps {
  baseAsset: Asset;
  asset: Asset;
  priceSide?: 'midPrice' | 'bidPrice' | 'askPrice';
}

const SingleWalletTradingInfoPair: React.FC<SingleWalletTradingInfoPairProps> = observer(
  ({ asset, baseAsset, priceSide = 'midPrice' }) => {
    const { convertRate } = RatesStore.useRatesStore;

    const assetRate = convertRate(asset, baseAsset, 1, priceSide);

    return (
      <FlexLayout spacing={8} direction='column'>
        <FlexLayout direction='row' alignItems='center' spacing={8}>
          <AssetIcon asset={asset} size={28} />
          <FormattedText
            typographyProps={{ size: 'large', weight: 'emphasis' }}
            formatOpts={{ appendCode: false, hideCode: true }}
            value={assetRate}
            currency={baseAsset}
            dynamicCurrency={asset}
          />

          <Numeric size='xlarge' color='secondary'>
            {baseAsset.code}/{asset.code}
          </Numeric>
        </FlexLayout>
      </FlexLayout>
    );
  },
);

export { SingleWalletTradingInfoPair };

import React, { useMemo, useCallback } from 'react';

import { AssetType } from '@shared/api';
import { DEFAULT_PRICE_SCALE } from '@shared/constants';
import { Big } from '@shared/safe-big';
import { RatesStore, UniversalTradeStore } from '@shared/store';
import { TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { isValidDecimalString } from '@shared/utils';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useUniversalTradeAsset } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

import { TradeInputBase } from './TradeInputBase';
import { Balance } from '../TradeInput.data';

type Props = {
  tradeDataKey: string;
  balanceToUse: Balance;
};

const TradeInputValue: React.FC<Props> = observer(({ tradeDataKey, balanceToUse }) => {
  const { tradeType, maxTradeValue } = UniversalTradeStore;
  const {
    limitAsset,
    fromAsset,
    toAsset,
    balance,
    error,
    locked,
    clientSideError,
    othersLocked,
    setTradeData,
    setTradeUIData,
    isOverLowLiquidityThreshold,
  } = useUniversalTradeAsset(tradeDataKey);
  const { trading, staking } = useAssetBalance(fromAsset!);
  const { convertRate } = RatesStore.useRatesStore;

  const assetBalance = useMemo(() => (balanceToUse === Balance.Staking ? staking : trading), [balanceToUse]);

  const onChangeValue = useCallback(
    (newValue: string) => {
      const scale = limitAsset?.price_scale || DEFAULT_PRICE_SCALE;
      const isValid: boolean = isValidDecimalString(newValue, scale);

      if (!limitAsset || !isValid) {
        setTradeUIData({ loading: false });
        return;
      }

      const newBalance = Big(newValue).round(limitAsset.price_scale, 0).toString();

      setTradeData({
        balance: newValue,
        slippagePercentage: 0,
        isHighSlippage: false,
      });

      if (!limitAsset || !fromAsset || !toAsset) return;

      if (limitAsset.id === fromAsset.id) {
        const divideBy = Big(maxTradeValue || assetBalance.balance).round(limitAsset.price_scale, 0);
        const percent = Big(newBalance).div(divideBy).times(100).round(1, 0).toNumber();
        const clampedPercentage = Math.min(Math.max(percent, 0), 100);

        setTradeUIData({
          percentage: clampedPercentage,
          loading: tradeType !== TradeType.OnTrigger,
          interactedWith: 'input',
        });
      } else if (limitAsset.id === toAsset.id) {
        const limitVal = convertRate(toAsset.id, fromAsset.id, newBalance, 'askPrice');
        const percent = Big(limitVal)
          .div(maxTradeValue || assetBalance.balance)
          .times(100)
          .round(1, 0)
          .toNumber();
        const clampedPercentage = Math.min(Math.max(percent, 0), 100);

        setTradeUIData({
          percentage: clampedPercentage,
          loading: tradeType !== TradeType.OnTrigger,
          interactedWith: 'input',
        });
      }
    },
    [
      assetBalance.balance,
      convertRate,
      fromAsset,
      limitAsset,
      maxTradeValue,
      setTradeData,
      setTradeUIData,
      toAsset,
      tradeType,
    ],
  );

  if (!limitAsset) return null;

  return useMemo(
    () => (
      <TradeInputBase
        value={balance}
        error={(error || '').length > 0 || (clientSideError || '').length > 0 || isOverLowLiquidityThreshold}
        onChange={onChangeValue}
        showDollarSign={limitAsset.assetType === AssetType.Fiat}
        suffix={limitAsset.code}
        asset={limitAsset}
        disabled={locked || othersLocked()}
      />
    ),
    [balance, error, clientSideError, isOverLowLiquidityThreshold, onChangeValue, limitAsset, locked, othersLocked],
  );
});

TradeInputValue.displayName = 'TradeInputValue';

export { TradeInputValue };

import { CreateAPIKeyType, GroupedScope } from '@shared/api/lib/helpers/apiKeys.helper';

export enum CreateApiKeyStep {
  Warning = 'Warning',
  Configuration = 'Configuration',
  Authentication = 'Authentication',
  Result = 'Result',
}

export type CreateApiKeyPayload = {
  keyType: CreateAPIKeyType;
  label: string;
  password?: string;
  scopes: GroupedScope;
};

export const defaultApiKeyPayload: CreateApiKeyPayload = {
  keyType: CreateAPIKeyType.ReadOnly,
  label: '',
  password: undefined,
  scopes: {},
};

export enum TransferModalStep {
  Root = 0,
  SelectAsset = 1,
  ManageAccounts = 2,
  WithdrawalAcknowledgement = 3,
  AddNewAccount = 4,
  PhoneVerification = 5,
  EmailVerification = 6,
  ViewAddressDetails = 7,
  RemoveAddress = 8,
  NetworkFees = 9,
  PhoneVerificationWithdraw = 10,
}

export enum TransferEnum {
  DepositReceive,
  WithdrawSend,
}

export type AddCryptoAddressFormData = {
  label: string;
  address: string;
  selectedCryptoNetworkId: number;
  metaDataPayload?: Record<string, string>;
};

export type AddFiatAddressFormData = {
  accountName: string;
  accountHolderName: string;
  accountNumber: string;
  bsb: string;
};

export type AddAddressFormData = AddCryptoAddressFormData | AddFiatAddressFormData;

export const isCryptoAddressFormData = (
  data: AddFiatAddressFormData | AddCryptoAddressFormData,
): data is AddCryptoAddressFormData =>
  (data as AddCryptoAddressFormData).address !== undefined && (data as AddCryptoAddressFormData).address.length > 0;

export const isFiatAddressFormData = (
  data: AddFiatAddressFormData | AddCryptoAddressFormData,
): data is AddFiatAddressFormData =>
  (data as AddFiatAddressFormData).accountNumber !== undefined &&
  (data as AddFiatAddressFormData).accountNumber.length > 0;

import { GeneratePresignedUrlPayload, GetDocumentUploadUrlResponse } from '@shared/api/@types/compliance';
import {
  EntityApplicationForm,
  EntityHistoryResponse,
  GetEntityApplicationDocumentsResponse,
} from '@shared/api/@types/entity';
import env from '@shared/config';

import { Methods, request } from '../rest';

type Headers = Record<string, string>;

const host = env.GEN2_HOST;

const entityApplicationEndpoints = {
  getEntityApplicationHistory: ({ headers }: { headers?: Headers } = {}): EntityHistoryResponse =>
    request({
      path: '/compliance/entities/v1/history',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  createEntityApplication: ({ headers }: { headers?: Headers } = {}) =>
    request({
      path: '/compliance/entities/v1/new',
      method: Methods.POST,
      headers,
      host,
      auth: true,
    }),

  getEntityApplicationForm: ({ headers }: { headers?: Headers } = {}) =>
    request({
      path: '/compliance/entities/v1/form',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  cancelEntityApplicationForm: ({ headers, query }: { headers?: Headers; query?: { applicationId: string } } = {}) =>
    request({
      path: '/compliance/entities/v1/form',
      method: Methods.DELETE,
      headers,
      query,
      host,
      auth: true,
    }),

  saveEntityApplicationData: ({
    headers,
    data,
  }: {
    headers?: Headers;
    data: Partial<EntityApplicationForm>;
  }): Partial<EntityApplicationForm> =>
    request({
      path: '/compliance/entities/v1/form',
      method: Methods.PUT,
      headers,
      data,
      host,
      auth: true,
    }),

  submitEntityApplicationForm: ({ headers, data }: { headers?: Headers; data: Partial<EntityApplicationForm> }) =>
    request({
      path: '/compliance/entities/v1/form',
      method: Methods.POST,
      headers,
      host,
      data,
      auth: true,
    }),

  getEntityDocuments: ({ headers }: { headers?: Headers } = {}): GetEntityApplicationDocumentsResponse =>
    request({
      path: '/compliance/entities/v1/documents',
      method: Methods.GET,
      headers,
      host,
      auth: true,
    }),

  generateEntityDocumentUploadUrl: ({
    headers,
    data,
  }: {
    headers?: Headers;
    data: GeneratePresignedUrlPayload;
  }): GetDocumentUploadUrlResponse =>
    request({
      path: '/compliance/entities/v1/documents/upload',
      method: Methods.POST,
      headers,
      host,
      data,
      auth: true,
    }),

  processEntityUploadedDocument: ({ headers, params }: { headers?: Headers; params: { id: string } }) =>
    request({
      path: '/compliance/entities/v1/documents/:id',
      method: Methods.POST,
      headers,
      params,
      host,
      auth: true,
    }),

  deleteUploadedEntityDocument: ({ headers, params }: { headers?: Headers; params: { id: string } }) =>
    request({
      path: '/compliance/entities/v1/documents/:id',
      method: Methods.DELETE,
      headers,
      params,
      host,
      auth: true,
    }),
};

export default entityApplicationEndpoints;

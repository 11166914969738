import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Layout } from '@swyftx/aviary/icons/outlined';
import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { DashboardContext } from '@routes/Dashboard';

import { observer } from 'mobx-react-lite';
import { useDashboardAnalytics } from 'src/lib/dashboard/hooks/useDashboardAnalytics';
import { useDashboardLayout } from 'src/lib/dashboard/hooks/useDashboardLayout';
import { useUpdateDashboard } from 'src/lib/dashboard/hooks/useUpdateDashboard';
import { DashboardLayoutItem } from 'src/lib/dashboard/types/Dashboard.types';

type Props = {
  backdropClose?: boolean;
  show?: boolean;
  onApplyLayout?: () => void;
  onOpen?: () => void;
};

const DashboardLayoutSelectorModal: React.FC<PropsWithChildren<Props>> = observer(
  ({ children, show, backdropClose = true, onApplyLayout, onOpen }) => {
    const [open, setOpen] = useState<boolean>();
    const { updateDashboardLayout } = useUpdateDashboard();
    const { layouts, layout: currentLayout } = useDashboardLayout();
    const [selectedLayout, setSelectedLayout] = useState<DashboardLayoutItem | undefined>(currentLayout);
    const isXs = useTailwindBreakpoint('xs');
    const isSm = useTailwindBreakpoint('sm');
    const { addToast } = useContext(ToastManagerContext);
    const { setEditMode } = useContext(DashboardContext);
    const { trackDashboardLayoutChanged, trackDashboardLayoutModalOpened } = useDashboardAnalytics();

    useEffect(() => {
      setOpen(show);
    }, [show]);

    const applyLayout = useCallback(() => {
      if (!selectedLayout) return;

      trackDashboardLayoutChanged(currentLayout?.key || 'classic', selectedLayout.key);
      updateDashboardLayout(selectedLayout);

      if (onApplyLayout) onApplyLayout();
      setEditMode(false);
      addToast({ title: 'Layout successfully updated', type: 'success', durationMs: 5000 });
      setOpen(false);
    }, [
      addToast,
      currentLayout?.key,
      onApplyLayout,
      selectedLayout,
      setEditMode,
      trackDashboardLayoutChanged,
      updateDashboardLayout,
    ]);

    const onOpenChange = useCallback(
      (newOpen?: boolean) => {
        if (!backdropClose && !newOpen) return;
        setOpen(newOpen);
        if (newOpen) {
          trackDashboardLayoutModalOpened();
        }
        if (newOpen && onOpen) onOpen();
      },
      [backdropClose, onOpen, trackDashboardLayoutModalOpened],
    );

    const isSmallScreen = useMemo(() => isXs || isSm, [isXs, isSm]);

    return (
      <Modal
        triggerElement={children}
        open={open}
        onOpenChange={onOpenChange}
        position={isXs ? 'bottom' : 'center'}
        contentClassName='w-full h-[90%] sm:max-w-[640px] lg:max-w-[960px] lg:max-h-[616px]'
      >
        <FlexLayout alignItems='center' justifyContent='space-between' className='h-full w-full'>
          <FlexLayout
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            className='h-full w-full sm:p-32 lg:max-w-[412px]'
          >
            <FlexLayout spacing={8} direction='column' alignItems='center'>
              <Layout className='h-48 w-48 text-color-text-info' />
              <Heading size={isSmallScreen ? 'h4' : 'h3'} className='text-center'>
                Dashboard layouts
              </Heading>
              <Body color='secondary' size='small' className='text-center'>
                To get started, select a layout that best shows what you’d like to see on log in.
              </Body>
            </FlexLayout>
            <FlexLayout spacing={12} direction='column' className='h-full w-full overflow-y-auto p-8'>
              {layouts.map((layout) =>
                layout.hidden ? null : (
                  <Card
                    key={layout.key}
                    shadow={false}
                    className={`w-full cursor-pointer p-16 ${
                      selectedLayout?.key === layout.key ? 'focus-area-info !outline-color-border-focus' : ''
                    }`}
                    onClick={() => setSelectedLayout(layout)}
                  >
                    <FlexLayout direction='column' spacing={16}>
                      <FlexLayout className='w-full' justifyContent='space-between' alignItems='center' spacing={8}>
                        <FlexLayout alignItems='center' spacing={16}>
                          {layout.icon}
                          <Body size='small' weight='emphasis'>
                            {layout.title}
                          </Body>
                        </FlexLayout>
                        {selectedLayout?.key === layout.key ? (
                          <FlexLayout
                            alignItems='center'
                            justifyContent='center'
                            className='relative h-24 w-24 rounded-[100%] border-[2px] border-color-background-info'
                          >
                            <div className='h-12 w-12 rounded-[100%] bg-color-background-info' />
                          </FlexLayout>
                        ) : (
                          <div className='h-24 w-24 rounded-[100%] border-[2px] border-color-border-main' />
                        )}
                      </FlexLayout>

                      {selectedLayout?.key === layout.key && isSmallScreen && (
                        <>
                          <Body size='small'>{layout.description}</Body>
                          {isSm && (
                            <>
                              {selectedLayout.imageSm && (
                                <FlexLayout
                                  justifyContent='center'
                                  className='h-[280px] overflow-hidden rounded-[12px] bg-color-background-surface-selected pt-24 dark:bg-color-background-surface-secondary'
                                >
                                  <Image image={selectedLayout.imageSm} className='relative h-[380px]' />
                                </FlexLayout>
                              )}
                              {!selectedLayout?.imageSm && (
                                <div className='relative h-[280px] overflow-hidden rounded-[12px] border border-color-border-active'>
                                  <div className='relative h-full w-full overflow-hidden bg-color-background-pageBG px-48 pt-48'>
                                    <div className='absolute left-[calc(50%-50vw)] top-[0px] w-[100vw] origin-top scale-[0.25]'></div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </FlexLayout>
                  </Card>
                ),
              )}
            </FlexLayout>
            <div className='w-full'>
              <Button disabled={!selectedLayout} onClick={applyLayout} size='lg' className='w-full '>
                Apply layout
              </Button>
            </div>
          </FlexLayout>
          {!isSmallScreen && (
            <FlexLayout
              spacing={32}
              direction='column'
              alignItems='center'
              justifyContent='end'
              className='h-[616px] w-[616px] bg-color-background-surface-selected p-48 pt-[104px] dark:bg-color-background-surface-secondary'
            >
              <Body weight='emphasis' className='text-center text-color-text-selected'>
                {selectedLayout?.description}
              </Body>
              {selectedLayout && (
                <>
                  {selectedLayout.image && (
                    <Image image={selectedLayout.image} className='relative h-[357px] w-[452px]' />
                  )}
                  {!selectedLayout?.image && (
                    <div className='relative h-[357px] w-[452px] overflow-hidden rounded-[12px] border border-color-border-active'>
                      <div className='relative h-full w-full overflow-hidden bg-color-background-pageBG px-48 pt-48'>
                        <div className='absolute left-[calc(50%-50vw)] top-[0px] w-[100vw] origin-top scale-[0.25]'></div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </FlexLayout>
          )}
        </FlexLayout>
      </Modal>
    );
  },
);

export { DashboardLayoutSelectorModal };

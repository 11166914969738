import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Information, Trade } from '@swyftx/aviary/icons/outlined';

import TileProps from '@global-components/Tile/Tile.types';

import { Profile } from '@shared/api';
import { UserStore } from '@shared/store';
import entityHelpers, { MemberPermissionType } from '@shared/utils/lib/entityHelpers';

import { ProfileSection } from '@Profile/types';

import { OTCTrades } from './components/ActionElements/OTCTrades';

export const useEntitySecurity = (userProfile?: Profile): ProfileSection => {
  const { t } = useTranslation('entity.security', { keyPrefix: 'overview.configureData' });

  const { entityMembers, previousUserProfile } = UserStore.useUserStore;

  const otcInvited = Boolean(userProfile?.accountStatus?.otcInvited);

  const isAdmin = useMemo(() => {
    const loggedUserMember = entityMembers.find((e) => e.uuid === previousUserProfile?.intercom?.uuid);
    const scopePreset = entityHelpers.getPermissionPreset(loggedUserMember?.scope || '');
    return scopePreset === MemberPermissionType.Admin;
  }, [previousUserProfile, entityMembers]);

  const items: TileProps[] = [];

  if (isAdmin) {
    // TODO remove this conditional check when multiple admin-only options, it will be redundant
    // since we hide it
    if (otcInvited) {
      items.push({
        id: 'allow_otc_trades',
        leading: <Trade id='allow-otc-trades-icon' />,
        translationKey: 'profile.security',
        title: 'overview.configureData.allowOtcTrades.title',
        subTitle: 'overview.configureData.allowOtcTrades.subTitle',
        hidden: !otcInvited,
        trailing: <OTCTrades isAdmin={isAdmin} />,
      });
    }
  }

  if (!items.length) {
    items.push({
      id: 'empty_security',
      leading: <Information id='empty-security-icon' />,
      translationKey: 'entity.security',
      title: 'overview.configureData.empty.title',
      subTitle: 'overview.configureData.empty.subTitle',
    });
  }

  return {
    title: t('title'),
    items,
  };
};

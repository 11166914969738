import { useCallback } from 'react';

import * as d3 from 'd3';

import { LineChartData, LineChartDatum } from '../LineChart.types';

type Props = {
  container: d3.Selection<SVGSVGElement | null, unknown, null, undefined>;
  chartData: LineChartData;
  idKey: string;
  valueArea: d3.Area<LineChartDatum>;
};

const useLineDefinitions = ({ container, chartData, idKey, valueArea }: Props) => {
  const buildLineDefinitions = useCallback(() => {
    // define svg defs and our asset graph group
    const defs = container.select('.line-definitions');
    // We need to re-create defs every render to fix the clip path
    defs.selectChildren().remove();
    // Set the clip path over our area data
    const clipPath = defs.append('clipPath').attr('id', `clip-line-path-${idKey}`);
    clipPath.append('path').attr('d', valueArea(chartData)).attr('class', 'value-line');
  }, [chartData, container, idKey, valueArea]);

  return {
    buildLineDefinitions,
  };
};

export { useLineDefinitions };

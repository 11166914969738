export enum GreenIDResponse {
  Success,
  EmailPhoneVerificationRequired,
  Error,
}

const formsOfAcceptedId: string[] = [
  'greenIdVerificationModal.labels.driversLicence',
  'greenIdVerificationModal.labels.passport',
  'greenIdVerificationModal.labels.citizenship',
  'greenIdVerificationModal.labels.visa',
  'greenIdVerificationModal.labels.immicard',
  'greenIdVerificationModal.labels.medicare',
  'greenIdVerificationModal.labels.nzBirthCertificate',
];

export { formsOfAcceptedId };

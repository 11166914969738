import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Game: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M9.59766 12.314C9.59766 11.8998 9.26187 11.564 8.84766 11.564C8.43344 11.564 8.09766 11.8998 8.09766 12.314V13.4365H6.9375C6.52329 13.4365 6.1875 13.7723 6.1875 14.1865C6.1875 14.6007 6.52329 14.9365 6.9375 14.9365H8.09766V16.059C8.09766 16.4732 8.43344 16.809 8.84766 16.809C9.26187 16.809 9.59766 16.4732 9.59766 16.059V14.9365H10.7586C11.1728 14.9365 11.5086 14.6007 11.5086 14.1865C11.5086 13.7723 11.1728 13.4365 10.7586 13.4365H9.59766V12.314Z'
      fill='currentColor'
    />
    <path
      d='M14.5098 12.4282C14.5098 12.014 14.8456 11.6782 15.2598 11.6782H15.3668C15.781 11.6782 16.1168 12.014 16.1168 12.4282C16.1168 12.8424 15.781 13.1782 15.3668 13.1782H15.2598C14.8456 13.1782 14.5098 12.8424 14.5098 12.4282Z'
      fill='currentColor'
    />
    <path
      d='M17.0723 15.2524C16.6581 15.2524 16.3223 15.5882 16.3223 16.0024C16.3223 16.4167 16.6581 16.7524 17.0723 16.7524H17.1793C17.5935 16.7524 17.9293 16.4167 17.9293 16.0024C17.9293 15.5882 17.5935 15.2524 17.1793 15.2524H17.0723Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.07227 1.25C8.48648 1.25 8.82227 1.58579 8.82227 2C8.82227 2.31211 9.08466 2.59076 9.44028 2.59076H10.4994C12.0631 2.59634 13.3546 3.84138 13.3618 5.40918L13.3619 5.41266L13.3618 5.62726C14.382 5.63262 15.4063 5.64376 16.4346 5.66054C20.049 5.66357 22.75 8.27991 22.75 11.8619V16.5114C22.75 20.0945 20.049 22.7097 16.4348 22.7128C13.4252 22.7636 10.4708 22.7616 7.56631 22.7128C3.9523 22.7097 1.25 20.0946 1.25 16.5114V11.8619C1.25 8.27869 3.95223 5.6637 7.56617 5.66054C8.98968 5.63624 10.4216 5.62403 11.8618 5.62355V5.41459C11.8577 4.69864 11.2617 4.09412 10.4953 4.09076H9.44028C8.28484 4.09076 7.32227 3.16885 7.32227 2C7.32227 1.58579 7.65805 1.25 8.07227 1.25ZM7.58556 7.16042C10.4929 7.11073 13.4368 7.11175 16.4161 7.16043L16.4283 7.16053C19.2493 7.16053 21.25 9.13776 21.25 11.8619V16.5114C21.25 19.2365 19.2494 21.2128 16.4283 21.2128L16.4156 21.2129C13.4191 21.2636 10.4774 21.2615 7.58537 21.2129L7.57275 21.2128C4.7515 21.2128 2.75 19.2363 2.75 16.5114V11.8619C2.75 9.137 4.7515 7.16053 7.57275 7.16053L7.58556 7.16042Z'
      fill='currentColor'
    />
  </svg>
);

export { Game };

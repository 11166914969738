import React, { useEffect, useRef, useMemo } from 'react';

import { formatDate, formatTime } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { formatValueToCurrencyShorthand } from '@utils/currency';

import * as d3 from 'd3';
import { DateTime } from 'luxon';

import { LINE_CHART_SETTINGS } from './LineChart.const';
import { LineChartDatum, LineChartProps, LineChartTooltipData } from './LineChart.types';
import { removeNodeOverlaps } from './LineChart.utils';
import { mockLineChartData } from './mocks/priceData';

const { defaults, tooltipWidth, yAxisPaddingFactor, tooltipThrottle, axisFontSize, overlapPadding } =
  LINE_CHART_SETTINGS;

const axisMargins = {
  marginTop: 20,
  marginLeft: 45,
  marginRight: 0,
  marginBottom: 20,
};

const emptyMargins = {
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
};

// TODO abstract some functionality to utilities -- its getting a bit full
export const LineChart: React.FC<LineChartProps> = ({
  strokeWidth = defaults.strokeWidth,
  height = defaults.height,
  xTicks = defaults.xTicks,
  yTicks = defaults.yTicks,
  disableLoadingAnimation,
  width = defaults.width,
  removeYAxisPadding,
  idKey = 'default',
  disableAreaFill,
  backgroundColor = 'var(--color-background-surface-primary)',
  forceBaseAsset,
  costBasis: costBasisOrUndefined,
  areaColor,
  chartData,
  smoothing,
  overlays,
  showAxes,
  loading,
  tooltipCallback,
}) => {
  const startDate = chartData.length ? DateTime.fromJSDate(chartData[0].time) : null;
  const endDate = chartData.length ? DateTime.fromJSDate(chartData[chartData.length - 1].time) : null;
  const dayDifference = startDate && endDate ? startDate.diff(endDate, ['days']).toObject().days : null;
  const showAsTime = dayDifference !== null && dayDifference !== undefined && Math.abs(dayDifference) < 2;
  const margins = showAxes ? axisMargins : emptyMargins;
  const baseAsset = useBaseAsset();
  const usedAsset = forceBaseAsset || baseAsset;
  const ref = useRef<SVGSVGElement | null>(null);
  const throttlingMouse = useRef(false);
  const color = areaColor || 'var(--color-text-accent)';

  const chartRef = useRef<SVGSVGElement | null>(null);
  const usedChartData = loading ? mockLineChartData : chartData;
  const hasAnimated = useRef(false);
  const costBasis = useMemo(() => costBasisOrUndefined ?? [], [costBasisOrUndefined]);

  useEffect(() => {
    hasAnimated.current = false;
  }, [chartData]);

  // Could use a decent refactor soon
  useEffect(() => {
    if (width !== Infinity && height !== Infinity) {
      const container = d3.select(ref.current).attr('width', width).attr('height', height);
      const overlayData = (!loading && overlays?.map((overlay) => overlay.data)) || [];

      // quick data mapper
      const getX = (d: LineChartDatum) => d.time;
      const getY = (d: LineChartDatum) => d.value;

      // Find the min of all datasets if we have overlays -- data points should be around 7-30 so in final version so not much optimization required
      const { minY, maxY }: { minY: number; maxY: number } =
        overlays?.length || costBasis?.length
          ? [...overlayData, usedChartData, costBasis].reduce<{ minY: number; maxY: number }>(
              (acc, data) => {
                const dataMinY = d3.min(data, getY);
                const dataMaxY = d3.max(data, getY);
                if (dataMinY !== undefined && (dataMinY < acc.minY || acc.minY === undefined)) {
                  acc.minY = dataMinY;
                }
                if (dataMaxY !== undefined && (dataMaxY > acc.maxY || acc.maxY === undefined)) {
                  acc.maxY = dataMaxY;
                }
                return acc;
              },
              {
                minY: d3.min([...costBasis, ...usedChartData], getY) as number,
                maxY: d3.max([...costBasis, ...usedChartData], getY) as number,
              },
            )
          : {
              minY: d3.min([...usedChartData], getY) as number,
              maxY: d3.max([...usedChartData], getY) as number,
            };

      // Adds a bit of room below the minimum Y value on the chart
      const add10PercentToBottomOfMinY = (calculatedMinY: number) => {
        const minYPlus10Percent = calculatedMinY - calculatedMinY / 20;
        if (minYPlus10Percent > 0) {
          return minYPlus10Percent;
        }
        return 0;
      };

      // set the x range to 0 - width and the domain from the min to the max
      const x = d3
        .scaleTime()
        .range([margins.marginLeft, width - margins.marginRight])
        .domain([d3.min(usedChartData, getX) as Date, d3.max(usedChartData, getX) as Date]);

      // set the y range to height - 0 and the domain from the min to the max
      const y = d3
        .scaleLinear()
        .range([height - margins.marginBottom, margins.marginTop])
        // * small increase to add a little padding and remove a cut-off bug
        .domain([
          removeYAxisPadding
            ? add10PercentToBottomOfMinY(minY)
            : add10PercentToBottomOfMinY(minY) * (1 - (yAxisPaddingFactor - 1)),
          removeYAxisPadding ? maxY : maxY * yAxisPaddingFactor,
        ])
        .nice();

      // Draw the axes
      if (showAxes) {
        const yAxisTicks = d3
          .axisRight(y)
          .ticks(yTicks)
          .tickSizeInner(0)
          .tickSizeOuter(0)
          .tickFormat((value) =>
            formatValueToCurrencyShorthand(value.toString(), usedAsset?.assetType, usedAsset?.code, {
              includeThousands: true,
              fixedTo: 1,
            }),
          );
        const yAxis = container.select<SVGSVGElement>(`#y-axis-${idKey}`);
        yAxis
          .call(yAxisTicks)
          .call((g) => g.select('.domain').remove())
          .attr('color', 'var(--color-text-secondary)')
          .attr('stroke-opacity', 0)
          .selectAll('text')
          .attr('className', 'font-mono')
          .attr('transform', `translate(0, -${axisFontSize / 2})`)
          .attr('font-size', axisFontSize);

        const xAxisTicks = d3
          .axisBottom(x)
          .ticks(xTicks)
          .tickSizeInner(0)
          .tickSizeOuter(0)
          .tickFormat((date) => (showAsTime ? formatTime(date as Date) : formatDate(date as Date)));
        const xAxis = container.select<SVGSVGElement>(`#x-axis-${idKey}`);
        xAxis
          .attr('color', 'var(--color-text-secondary)')
          .call(xAxisTicks)
          .attr('transform', `translate(0, ${height - margins.marginBottom + axisFontSize / 2})`)
          .attr('className', 'font-mono')
          .attr('font-size', axisFontSize)
          .attr('stroke-opacity', 0);

        // Grid-lines
        const gridLines = d3.axisLeft(y).ticks(yTicks).tickSize(-width);
        const gridLinesContainer = container.select<SVGSVGElement>(`#y-axis-grid-lines-${idKey}`);
        gridLinesContainer
          .call(gridLines)
          .attr('opacity', 0.05)
          .call((g) => g.select('.domain').remove());

        // Remove label duplicates
        const xAxisLabels = xAxis.selectAll('text').nodes() as Element[];
        if (xAxisLabels.length) {
          removeNodeOverlaps(xAxisLabels, {
            overlapPadding,
            containerBBox: container.node()?.getBoundingClientRect(),
          });
        }
      }

      // d3 doing the thing to make the area calculations from our data
      const valueArea = d3
        .area<LineChartDatum>()
        .x((d) => x(getX(d)))
        .y0(height)
        .y1((d) => y(getY(d)));

      // separate line and area calculations because we want to add a stroke to the line data but not the whole area
      const valueLine = d3
        .line<LineChartDatum>()
        .x((d) => x(getX(d)))
        .y((d) => y(getY(d)));
      if (smoothing) {
        valueLine.curve(d3.curveBasis);
        valueArea.curve(d3.curveBasis);
      }

      // Tooltip
      const onMouseMove = (e: MouseEvent) => {
        if (!chartData.length) return;

        if (tooltipCallback && !loading && !throttlingMouse.current) {
          const mousePosition = d3.pointer(e);
          const hoveredDate = x.invert(mousePosition[0]);
          const getTimeDifferenceFromHoveredDate = (d: LineChartDatum) =>
            Math.abs(getX(d).getTime() - hoveredDate.getTime());
          const closestIndex = d3.leastIndex(
            usedChartData,
            (a, b) => getTimeDifferenceFromHoveredDate(a) - getTimeDifferenceFromHoveredDate(b),
          );
          if (closestIndex !== undefined) {
            // Find the closest data points of our main data and our overlays
            const closestDataPoints = [chartData, ...overlayData, costBasis].reduce<LineChartTooltipData>(
              (acc, data, index) => {
                if (index === 0) {
                  const closestDataPoint = data[closestIndex];
                  acc.main = closestDataPoint;
                  acc.costBasis = closestDataPoint;
                } else {
                  const overlay = overlays?.[index - 1];
                  const closestDataPoint = data[closestIndex];
                  const oneDataPointAway = data[closestIndex + 1];
                  if (overlay) {
                    acc.overlays.push({ ...closestDataPoint, id: overlay.id });
                  }
                  acc.costBasis = oneDataPointAway;
                }

                return acc;
              },
              { main: chartData[0], overlays: [] },
            );

            const xPos = x(getX(closestDataPoints.main));
            tooltipLine.attr('x1', xPos).attr('x2', xPos).attr('opacity', '1');

            tooltipCallback({ x: xPos, y: mousePosition[1] }, closestDataPoints);
          }
          throttlingMouse.current = true;
        } else {
          setTimeout(() => {
            throttlingMouse.current = false;
          }, tooltipThrottle);
        }
      };

      const onMouseLeave = () => {
        if (!chartData.length) return;

        tooltipLine.attr('opacity', '0');

        if (tooltipCallback) {
          tooltipCallback(null, null);
        }
      };

      // define svg defs and our asset graph group
      const defs = container.select('.line-definitions');
      // We need to re-create defs every render to fix the clip path
      defs.selectChildren().remove();
      // Set the clip path over our area data
      const clipPath = defs.append('clipPath').attr('id', `clip-line-path-${idKey}`);
      clipPath.append('path').attr('d', valueArea(usedChartData)).attr('class', 'value-line');

      const graphArea = container.select('.line-graph-area');

      // Gradient stops
      const bgGradient = d3.select(`#bg-gradient-${idKey}`).attr('gradientTransform', 'rotate(90)');
      bgGradient.select('.top-stop').attr('stop-color', color).attr('stop-opacity', 0.1).attr('offset', '0%');
      bgGradient.select('.bottom-stop').attr('stop-color', backgroundColor).attr('offset', '90%');

      // Add the line from our line data
      container
        .select('.line-path')
        .datum(usedChartData)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', strokeWidth)
        .attr('d', valueLine(usedChartData));

      container
        .select('#cost-basis')
        .datum(costBasis)
        .attr('fill', 'none')
        .attr('stroke', 'var(--color-text-primary)')
        .attr('stroke-width', strokeWidth)
        .attr('stroke-dasharray', 4)
        .attr('d', valueLine(costBasis));

      // Add overlay lines for each overlay we have passed
      overlays?.forEach((overlay) => {
        container
          .select(`#overlays-${idKey}`)
          .select(`#overlay-${overlay.id}`)
          .datum(overlay.data)
          .attr('fill', 'none')
          .attr('stroke', overlay.color)
          .attr('stroke-width', strokeWidth)
          .attr('stroke-dasharray', overlay?.variant === 'dashed' ? 7 : 0)
          .attr('d', valueLine(overlay.data));
      });

      // Clip the area to our data so the gradient shows only under
      const gradientClipPath = graphArea.select('.gradient-clip').attr('clip-path', `url(#clip-line-path-${idKey})`);

      gradientClipPath
        .select('.area-rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', height)
        .style('fill', `url(#bg-gradient-${idKey})`);

      if (tooltipCallback) {
        d3.select(`.listening-rect-${idKey}`)
          .attr('width', width)
          .attr('height', height)
          .attr('fill', 'transparent')
          .on('mousemove', onMouseMove)
          .on('mouseleave', onMouseLeave);
      }

      const tooltipLine = d3
        .select(`#tooltip-line-${idKey}`)
        .attr('stroke-width', tooltipWidth)
        .attr('stroke', 'var(--color-text-primary)')
        .attr('fill', 'none')
        .attr('opacity', 0)
        .attr('stroke-dasharray', 3)
        .attr('y1', 0)
        .attr('y2', height);

      if (chartRef.current && !disableLoadingAnimation && !hasAnimated.current) {
        chartRef.current.style.animation = '';
        window.setTimeout(() => {
          if (chartRef.current) {
            if (loading) {
              chartRef.current.style.animation =
                'clip-linear-loading 3s infinite, clip-linear-loading-fade 1.25s infinite';
            } else {
              chartRef.current.style.animation = 'clip-linear 1.5s forwards';
              hasAnimated.current = true;
            }
          }
        }, 10);
      }
    }
  }, [
    disableLoadingAnimation,
    removeYAxisPadding,
    backgroundColor,
    tooltipCallback,
    usedChartData,
    strokeWidth,
    showAsTime,
    chartData,
    smoothing,
    usedAsset,
    showAxes,
    overlays,
    loading,
    margins,
    height,
    xTicks,
    yTicks,
    width,
    idKey,
    costBasis,
    color,
  ]);

  if (width === Infinity || height === Infinity) {
    return null;
  }

  // We pre-render the elements and use d3 selections rather than appends so we can leave the DOM renders to react
  // -- classnames are mostly for top-down readability
  return (
    <svg
      ref={chartRef}
      style={{
        width,
        height,
        ...(!disableLoadingAnimation && {
          transition: '1s !important',
          willChange: 'clip-path',
          clipPath: 'inset(0px 100% 0px 0px)',
        }),
      }}
    >
      <g ref={ref}>
        {/* Definitions/helpers */}
        <defs className='line-definitions' />

        {!disableAreaFill ? (
          <linearGradient id={`bg-gradient-${idKey}`}>
            <stop className='top-stop' />
            <stop className='bottom-stop' />
          </linearGradient>
        ) : null}
        {/* Charts */}
        <g className='line-graph-area'>
          <g className='gradient-clip'>
            <g>
              <rect className='area-rect' />
            </g>
          </g>
        </g>
        {/* Overlays */}
        <g id={`overlays-${idKey}`}>
          {overlays?.map((overlay) => (
            <path id={`overlay-${overlay.id}`} key={overlay.id} />
          ))}
        </g>
        <path className='line-path' />
        <path id='cost-basis' />

        {/* Axis */}
        {!loading ? (
          <>
            <g id={`x-axis-${idKey}`} />
            <g id={`y-axis-${idKey}`} />
            <g id={`y-axis-grid-lines-${idKey}`} />
          </>
        ) : null}
      </g>
      {/* Tooltip */}
      {Boolean(tooltipCallback) && (
        <g>
          <line id={`tooltip-line-${idKey}`} />
          <rect className={`listening-rect-${idKey}`} />
        </g>
      )}
    </svg>
  );
};

import React, { useCallback, useContext, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { api } from '@shared/api';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { CancelVerificationModal } from '@Profile/subroutes/Verification/components/VerificationMethod/CancelVerificationModal';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import { observer } from 'mobx-react-lite';

import { JumioWizardLandingStep } from './steps/JumioWizardLandingStep';
import { JumioWizardVerifyStep } from './steps/JumioWizardVerifyStep';

enum Steps {
  Landing = 0,
  Verify = 1,
}

export const JumioVerificationModal: React.FC = observer(() => {
  const { setVerificationStep, setJumioTransaction } = useContext(ProfileVerificationContext);
  const [verifyClose, setVerifyClose] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<Steps>(Steps.Landing);
  const isXs = useTailwindBreakpoint('xs');

  const goToVerify = useCallback(() => {
    const fetch = async () => {
      const transaction = await api.endpoints.getJumioTransaction({});
      setJumioTransaction(transaction.data);
      setStepIndex(Steps.Verify);
    };

    fetch();
  }, [setJumioTransaction]);

  const onClose = useCallback(
    (closeParent?: boolean) => {
      setVerifyClose(false);
      if (closeParent) setVerificationStep(VerificationStep.None);
    },
    [setVerificationStep],
  );

  const onVerifyClose = useCallback(
    (skipVerify = false) => {
      if (skipVerify) {
        onClose(true);
        return;
      }
      setVerifyClose(true);
    },
    [onClose],
  );

  const modalTitle = useMemo(
    () => (stepIndex === Steps.Landing ? 'Authenticate your ID' : 'Verify your identity'),
    [stepIndex],
  );

  return (
    <>
      <Modal open onClose={onVerifyClose} title={modalTitle} position={isXs ? 'bottom' : 'center'}>
        <FlexLayout direction='column' className='max-w-[40rem] p-24 pt-0'>
          {stepIndex === Steps.Landing && <JumioWizardLandingStep onClose={onVerifyClose} onAction={goToVerify} />}
          {stepIndex === Steps.Verify && <JumioWizardVerifyStep onClose={onVerifyClose} />}
        </FlexLayout>
      </Modal>
      {verifyClose && <CancelVerificationModal onClose={onClose} />}
    </>
  );
});

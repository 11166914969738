import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { UserStore } from '@shared/store';

import { ProfileVerificationProvider } from '@routes/Profile/subroutes/Verification/ProfileVerification.context';

import { CompleteProfileStep } from './CompleteProfileStep';
import { SubmitApplicationStep } from './SubmitApplicationStep';
import { VerifyIdStep } from './VerifyIdStep';

export const EntityApplicationOnboardingBanner: React.FC = () => {
  const { userHasCompletedOnboarding, isKyc1Complete } = UserStore.useUserStore;

  if (!open) return null;

  return (
    <ProfileVerificationProvider>
      <Card className='w-full p-24 @container'>
        <FlexLayout className='w-full flex-col gap-24 @md:flex-row @md:gap-0'>
          <CompleteProfileStep completed={userHasCompletedOnboarding()} />
          <VerifyIdStep completed={isKyc1Complete()} actionUnlocked={userHasCompletedOnboarding()} />
          <SubmitApplicationStep actionUnlocked={isKyc1Complete()} />
        </FlexLayout>
      </Card>
    </ProfileVerificationProvider>
  );
};

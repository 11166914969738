import { useEffect } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { RecurringOrderSource } from '@shared/enums';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import {
  AutoInvestAmount,
  AutoInvestDateFrequency,
  AutoInvestFundWith,
  AutoInvestOrderName,
} from 'src/lib/auto-invest/components/AutoInvestPreferences';
import { useNavigateExitIntent } from 'src/lib/navigation/hooks/useNavigateExitIntent';

import { AutoInvestBalanceWarning, AutoInvestCardHeader } from '../components';
import { useAutoInvestPreferences } from '../hooks/useAutoInvestPreferences';

export const AutoInvestOrderWidgetPreferences = () => {
  const {
    orderName,
    selectedPaymentMethod,
    amount,
    startDate,
    frequency,
    minimumOrderAmount,
    validPreferences,
    showBalanceWarning,
    setOrderName,
    setSelectedPaymentMethod,
    setAmount,
    setStartDate,
    setFrequency,
    setCurrentStep,
    onCancel,
  } = useAutoInvestPreferences();
  const countryAsset = useCountryAsset();
  const { initExitIntent } = useNavigateExitIntent();

  const title = 'Step 2: Set your preferences';
  const description = 'Configure your order detail to best suit your investment strategy.';

  useEffect(() => {
    initExitIntent(
      'Discard changes?',
      'Are you sure you want to discard the changes you’ve made to this order?',
      'Order details',
      'Auto Invest',
    );
  }, [initExitIntent]);

  const handleOnBack = () => {
    setCurrentStep('values');
  };

  return (
    <FlexLayout
      direction='column'
      spacing={8}
      alignItems='center'
      justifyContent='space-between'
      className='h-full w-full pt-16 sm:pt-0'
    >
      <FlexLayout
        direction='column'
        className='h-full w-full p-16 pt-0 sm:p-24'
        spacing={8}
        alignItems='center'
        justifyContent='space-between'
      >
        <FlexLayout direction='column' className='w-full' spacing={24} alignItems='center'>
          <AutoInvestCardHeader title={title} onBack={handleOnBack} description={description} />
          {/** Order Name input */}
          <AutoInvestOrderName value={orderName} onChange={setOrderName} />
          {/** Fund order with */}
          <AutoInvestFundWith value={selectedPaymentMethod} onChange={setSelectedPaymentMethod} />

          {/** Start Date and Frequency */}
          {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
            <FlexLayout direction='column' className='w-full' spacing={24}>
              <AutoInvestDateFrequency
                startDate={startDate}
                onStartDateChange={setStartDate}
                frequency={frequency}
                onFrequencyChange={setFrequency}
              />
              <AutoInvestAmount value={amount} onChange={setAmount} min={minimumOrderAmount} />
              {showBalanceWarning && countryAsset && <AutoInvestBalanceWarning countryAsset={countryAsset} />}
            </FlexLayout>
          )}
        </FlexLayout>
        <FlexLayout direction='column' className='mt-24 w-full sm:mt-0' spacing={12}>
          <Button size='lg' className='w-full' onClick={() => setCurrentStep('review')} disabled={!validPreferences}>
            Review order
          </Button>
          <Button variant='outlined' size='lg' className='w-full' onClick={onCancel}>
            Cancel
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

import React from 'react';

import { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TypographyProps } from '@mui/material/Typography';

import { Checkbox, Typography } from '@swyftx/react-web-design-system';

type Props = {
  label: string;
  labelProps: TypographyProps;
} & CheckboxProps;

const AdvancedConfigurationCheckbox: React.FC<Props> = ({ label, labelProps, ...props }) => (
  <FormControlLabel
    label={<Typography {...labelProps}>{label}</Typography>}
    control={<Checkbox {...props} size='small' sx={{ color: 'divider', borderRadius: '4px' }} />}
    sx={{
      '.MuiFormControlLabel-root': {
        margin: 0,
      },
    }}
  />
);

export { AdvancedConfigurationCheckbox };

import React from 'react';

import { Box, Fade } from '@mui/material';

import { Button, List, ListItem, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { useDeposit } from '@hooks/Deposit/useDeposit';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AppFeatureData } from 'src/config/featureFlags.data';

import { DepositInformationItem } from '../components/DepositInformationItem';
import { TransferTypeLoading } from '../components/TransferTypeLoading';

type Props = {
  asset: Asset;
};

const DepositPayIdTransfer: React.FC<Props> = ({ asset }) => {
  const { refetchAddressDetails, loading, bankAddressDetails } = useDeposit({
    asset,
    transferType: DepositMethodEnum.MONOOVA_PAYID,
  });
  const { isFeatureEnabled, getFeatureData } = useIsFeatureEnabled();
  const payIdDisabled = isFeatureEnabled(AppFeature.PayIDDisabled);
  const disabledCopy = getFeatureData(AppFeatureData.PayIDDisabledInformation);

  if (loading) return <TransferTypeLoading />;

  return (
    <Fade in timeout={500}>
      <div>
        <Stack>
          {payIdDisabled && asset.code === 'AUD' && (
            <Box margin={2}>
              <Notification severity='error' title={disabledCopy.title || 'Planned maintenance'}>
                <Typography fontSize={14}>{disabledCopy.detail || 'Please try another payment method.'}</Typography>
              </Notification>
            </Box>
          )}
          {!bankAddressDetails && (
            <Notification
              severity='warning'
              title='There was an issue generating deposit details'
              sx={{
                margin: 2,
                '.MuiAlertTitle-root': {
                  marginLeft: 1,
                  color: 'warning.dark',
                },
              }}
            >
              <Typography marginLeft={1} fontSize={14} fontWeight={400}>
                Please try again, and if you continue to experience issues, contact support.
              </Typography>

              <Button color='warning' sx={{ color: 'warning.dark' }} onClick={refetchAddressDetails}>
                Retry generating deposit details
              </Button>
            </Notification>
          )}

          {bankAddressDetails && (
            <>
              <Typography padding={2} color='text.secondary' fontSize={14} fontWeight='400'>
                Deposit funds directly from your bank account using a unique PayID.
              </Typography>

              <Stack direction='column'>
                <List>
                  <ListItem divider>
                    <DepositInformationItem key='deposit-information-account-name' title='Biller name' value='Swyftx' />
                  </ListItem>

                  <ListItem>
                    <DepositInformationItem
                      key='deposit-information-account-name'
                      title='PayID'
                      value={bankAddressDetails.payid}
                    />
                  </ListItem>
                </List>
              </Stack>
            </>
          )}
        </Stack>
      </div>
    </Fade>
  );
};

export { DepositPayIdTransfer };

import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

const useFetchAssetDepositAddressesCache = () => {
  const queryCache = useQueryClient();

  const invalidateCache = useCallback(() => queryCache.invalidateQueries(['asset-deposit-addresses']), [queryCache]);

  return {
    invalidateCache,
  };
};

export { useFetchAssetDepositAddressesCache };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset } from '@shared/api';

import { useWithdrawalFundsImportantInformation } from '@services/WithdrawService';

type Props = {
  onClose: () => void;
  asset: Asset;
};

const FiatWithdrawalInfoModal: React.FC<Props> = ({ onClose, asset }) => {
  const { informationList } = useWithdrawalFundsImportantInformation(asset.code);

  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      id='deposit-funds-info-modal'
      open
      title='Important information'
      className='sm:min-w-[600px] sm:max-w-[600px]'
      onClose={onClose}
      position={isXs ? 'bottom' : 'center'}
    >
      <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
        {asset.code === 'NZD' && (
          <>
            <Body color='primary' weight='bold'>
              Withdrawal Processing Times
            </Body>
            <Body color='primary'>
              Withdrawals are processed within 1-3 business days and will arrive in your bank account after 8-10
              business days. Please contact support if your withdrawal has not arrived after this time.
            </Body>
          </>
        )}
        <Body color='primary' weight='bold'>
          Withdrawal sizes
        </Body>
        <Body color='primary'>{informationList[0]}</Body>
        <Body color='primary' weight='bold'>
          Fees
        </Body>
        <Body color='primary'>{informationList[1]}</Body>
        <Body color='primary' weight='bold'>
          Verification
        </Body>
        <Body color='primary'>{informationList[2]}</Body>
      </FlexLayout>
    </Modal>
  );
};

export { FiatWithdrawalInfoModal };

import React, { useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';
import { Add, Wallet } from '@swyftx/aviary/icons/outlined';
import { StepModalContext } from '@swyftx/aviary/molecules/StepModal/StepModal.context';
import { Skeleton } from '@swyftx/react-web-design-system';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { assetService, WithdrawalAddress, withdrawalService } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { SingleWalletManageAccounts } from '@routes/Wallet/subroutes/SingleWallet/components/SingleWalletManageAccounts';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useFetchAssetAddresses } from 'src/lib/assets/hooks/useFetchAssetAddresses';

type Props = {
  asset: Asset;
  title: string;
  value?: string;
  onChange: (val: WithdrawalAddress) => void;
};

const WithdrawAddressDropdown: React.FC<Props> = ({ asset, value, onChange, title }) => {
  const { data: addresses, isFetching } = useFetchAssetAddresses({ asset });
  const isFiat = asset && assetService.isAssetFiat(asset.id);
  const { onNextStep } = useContext(StepModalContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const onSelect = (id: string) => {
    const address = addresses?.find((a) => a.id === Number(id));

    if (address) onChange(address);
  };

  const getLeadingIcon = () => (
    <FlexLayout
      className='h-24 w-24 rounded-8 bg-color-background-info-subtle'
      alignItems='center'
      justifyContent='center'
    >
      <Wallet className={cn('h-18 w-18 text-color-text-primary')} />
    </FlexLayout>
  );

  const getSecondaryLabel = (address: WithdrawalAddress) => {
    const details: string[] = [address.address_details.address];

    if (address.metadata?.name && address.metadata?.data) {
      details.push(`${address.metadata.name}: ${address.metadata.data}`);
    }

    if (address.address_details.network) {
      details.push(`${address.address_details.network} Network`);
    }

    return details.join(' • ');
  };

  const getTrailingIcon = (address: WithdrawalAddress, networkActive?: boolean) => {
    if (!address.verified) {
      return (
        <Chip color='destructive' size='sm'>
          Confirm email
        </Chip>
      );
    }

    if (address.verified === true && networkActive === false) {
      return (
        <Chip size='sm' color='destructive'>
          Network disabled
        </Chip>
      );
    }

    return undefined;
  };

  if (isFetching) {
    return (
      <FlexLayout spacing={8} direction='column'>
        <FlexLayout direction='row' justifyContent='space-between' alignItems='center' className='mb-4'>
          <Skeleton variant='rounded' width='218px' height='21px' />
          <Skeleton variant='rounded' width='145px' height='36px' />
        </FlexLayout>
        <Skeleton variant='rounded' width='100%' height='57px' />
      </FlexLayout>
    );
  }

  return (
    <FlexLayout spacing={4} direction='column'>
      <FlexLayout direction='row' justifyContent='space-between' alignItems='center' className='mb-4'>
        <Body size='small' weight='bold' color='primary'>
          Select {title}: <span className='text-color-text-error'>*</span>
        </Body>

        <SingleWalletManageAccounts asset={asset} isFiat={isFiat} fullWidth={false} buttonSize='md' />
      </FlexLayout>
      <Select
        className='mb-16 h-56 w-full'
        title={title}
        showInputTitle={false}
        showSecondaryLabel
        value={value}
        onValueChange={(value) => onSelect(value)}
        placeholder='Select an address'
        items={addresses.map((address) => {
          const networkActive = withdrawalService.canNetworkWithdraw(address, asset);
          const leadingIcon = getLeadingIcon();
          const trailingIcon = getTrailingIcon(address, networkActive);
          const secondaryLabel = getSecondaryLabel(address);

          return {
            value: address.id.toString(),
            label: address.label,
            secondaryLabel: secondaryLabel,
            leadingIcon: leadingIcon,
            disabled: trailingIcon !== undefined,
            trailingIcon: trailingIcon,
          };
        })}
      >
        <div className='p-8'>
          <Button
            leadingIcon={<Add />}
            variant='outlined'
            className='cursor-pointer'
            onClick={() => {
              if (asset.assetType === AssetType.Fiat || !isFeatureEnabled(AppFeature.CryptoWithdrawalAcknowledgement)) {
                onNextStep(TransferModalStep.AddNewAccount);
              } else {
                onNextStep(TransferModalStep.WithdrawalAcknowledgement, false);
              }
            }}
          >
            Add new address
          </Button>
        </div>
      </Select>
    </FlexLayout>
  );
};

export { WithdrawAddressDropdown };

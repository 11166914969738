import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { types } from '@swyftx/marketing-utils';
import { PublicAssetRewards } from '@swyftx/promotions-sdk';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';

import env from '@shared/config';
import config from '@shared/config';
import { EnvType } from '@shared/config/@types/EnvTypeEnum';
import { StorageKey } from '@shared/storage';
import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';

import { RewardsStoreSchema, RewardsInitialValues, mockRewards, mockActionable, RewardType } from './types';

export const RewardsContext = createContext<RewardsStoreSchema>(RewardsInitialValues);

type StoredRewards = { available: types.UserAssetReward[]; history: types.UserAssetReward[] };

type Props = {
  mock?: boolean;
};

export const RewardsProvider: React.FC<PropsWithChildren<Props>> = observer(({ mock, children }) => {
  const [viewedRewards, setViewedRewards] = useLocalStorage<number[]>(StorageKey.VIEWED_REWARDS, []);
  const [fetchingRewards, setFetchingRewards] = useState<boolean>(false);
  const [fetchingActionable, setFetchingActionable] = useState<boolean>(false);
  const [rewards, setRewards] = useState<StoredRewards>({ available: [], history: [] });
  const [actionable, setActionable] = useState<types.UserAssetRewardActionableIds>({
    canOptIn: [],
    canRedeem: [],
    inProgress: [],
  });
  const { userAuth } = UserStore.useUserStore;
  const { addMessageBox } = UIStore.useUIStore;
  const { openModal } = useModal();
  const { t } = useTranslation('rewards');

  const hasAvailableReward = useMemo(
    () => actionable.canOptIn.length + actionable.canRedeem.length + actionable.inProgress.length > 0,
    [actionable],
  );

  const hasRewardToRedeem = useMemo(() => actionable.canRedeem.length > 0, [actionable]);

  const unseenRewards = useMemo(
    () =>
      actionable.canOptIn.concat(actionable.inProgress).filter((id) => !viewedRewards?.includes(id)).length +
      actionable.canRedeem.length,
    [viewedRewards, actionable],
  );

  const viewRewards = useCallback(() => {
    const seenRewards = rewards.available.map((a) => a.id);
    setViewedRewards(seenRewards);
  }, [rewards.available, setViewedRewards]);

  const getClient = useCallback(
    () =>
      new PublicAssetRewards.Client({
        serviceUrl: env.MARKETING_SERVICE_URL,
        servicePrefix: '/api/v1',
        withCredentials: true,
        headers: {
          'swyftx-platform': 'web',
          Authorization: `Bearer ${userAuth?.access_token}`,
        },
      }),
    [userAuth],
  );

  const fetchRewards = async (userId: string, rewardType: RewardType) => {
    if (fetchingRewards) return;
    setFetchingRewards(true);

    if (mock) {
      setRewards((prev) => ({ ...prev, [rewardType]: mockRewards[rewardType] }));
    } else {
      const history = rewardType === RewardType.History;
      const client = getClient();
      const assetRewards = await client.getAllAssetRewards({ userId, history });
      setRewards((prev) => ({ ...prev, [rewardType]: assetRewards }));
    }

    setFetchingRewards(false);
  };

  const fetchActionable = async (userId: string) => {
    if (fetchingActionable) return;
    setFetchingActionable(true);

    if (mock) {
      setActionable(() => mockActionable);
    } else {
      const client = getClient();
      const actionable = await client.getActionableAssetRewardIds({ userId });
      setActionable(() => actionable);
    }

    setFetchingActionable(false);
  };

  const optIn = async (assetRewardId: number, userId: string) => {
    try {
      const client = getClient();
      const response: types.AssetRedemption = await client.createAssetRedemption({
        userId,
        assetRewardId,
      });

      const availableRewards = rewards.available;

      const index = availableRewards.findIndex((r) => r.id === assetRewardId);

      if (index > -1) {
        setRewards((prev) => {
          const newRewards = { ...prev };
          newRewards.available[index] = { ...rewards.available[index], redemptions: [response] };

          return newRewards;
        });
      } else {
        fetchRewards(userId, RewardType.Available);
      }
    } catch (e) {
      addMessageBox({
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        content: <ErrorMessageBox title={t('rewardCard.optInError')} />,
      });
    }
  };

  const redeemReward = async (assetReward: types.UserAssetReward, userId: string) => {
    if (mock) {
      await fetchRewards(userId, RewardType.Available);
      await fetchRewards(userId, RewardType.History);
      if (assetReward.hasPayout) {
        openModal(Modals.RedeemSwyftxReward, { reward: assetReward });
      } else {
        openModal(Modals.RedeemSwyftxEntry, { reward: assetReward });
      }
      return;
    }
    try {
      const client = getClient();
      (await client.redeemAssetReward(
        assetReward.redemptions[0].id,
        config.ENV === EnvType.LOCAL ? userId : undefined, // Only send the userId if we are debugging locally
      )) as types.UserAssetRedemption;

      await fetchRewards(userId, RewardType.Available);
      await fetchRewards(userId, RewardType.History);
      const modalToOpen = assetReward.hasPayout ? Modals.RedeemSwyftxReward : Modals.RedeemSwyftxEntry;
      openModal(modalToOpen, { reward: assetReward });
    } catch (e) {
      addMessageBox({
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        content: <ErrorMessageBox title={t('rewardCard.redeemError')} />,
      });
    }
  };

  return (
    <RewardsContext.Provider
      value={{
        rewards,
        actionable,
        fetchingRewards,
        fetchingActionable,
        hasAvailableReward,
        hasRewardToRedeem,
        unseenRewards,
        viewRewards,
        optIn,
        redeemReward,
        fetchRewards,
        fetchActionable,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
});

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Layer: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.996 2.92328C12.5673 2.12063 10.827 2.12063 9.3984 2.92327L4.18274 5.85358C2.87398 6.58888 2.87399 8.48024 4.18274 9.21555L4.98868 9.66834L4.56456 9.91123L4.18271 10.1258C2.87398 10.8611 2.87399 12.7524 4.18274 13.4877L4.98793 13.9401L4.48529 14.182C3.06877 14.846 2.99654 16.8418 4.3605 17.6081L9.39744 20.438C10.8261 21.2407 12.5664 21.2407 13.995 20.438L19.1065 17.5663C20.4485 16.8122 20.4059 14.8575 19.0309 14.164L19.0295 14.1633L18.4939 13.891L19.2116 13.4877C20.5204 12.7524 20.5204 10.8611 19.2116 10.1258L18.8272 9.90969L18.3936 9.67513L19.2116 9.21555C20.5204 8.48024 20.5204 6.58889 19.2116 5.85358L13.996 2.92328ZM16.909 10.5092L13.996 12.1458C12.5674 12.9485 10.827 12.9485 9.39841 12.1458L6.45136 10.4901L5.27254 11.1652L4.88811 11.3812C4.5589 11.5661 4.55887 12.0473 4.88808 12.2323L10.1037 15.1626C11.0943 15.7191 12.3 15.7191 13.2906 15.1626L18.5063 12.2323C18.8355 12.0473 18.8355 11.5662 18.5063 11.3812L18.1269 11.168L16.909 10.5092ZM10.1037 4.1787C11.0943 3.62215 12.3 3.62215 13.2906 4.1787L18.5063 7.10901C18.8355 7.29396 18.8355 7.77515 18.5063 7.96012L13.2906 10.8904C12.3 11.4469 11.0943 11.4469 10.1037 10.8904L4.88808 7.96012C4.55887 7.77515 4.55888 7.29396 4.88808 7.10901L10.1037 4.1787ZM13.996 16.418L16.9846 14.7389L18.3794 15.4482L18.382 15.4495C18.7273 15.6234 18.7393 16.1208 18.401 16.3109L13.2897 19.1826C12.2991 19.7391 11.0934 19.7391 10.1028 19.1826L5.06584 16.3527C4.72156 16.1593 4.74196 15.6511 5.09791 15.4852L6.52004 14.8009L9.3984 16.418C10.827 17.2207 12.5674 17.2207 13.996 16.418Z'
      fill='currentColor'
    />
  </svg>
);

export { Layer };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useDemoMode } from 'src/lib/utils/hooks';

const AppHeaderBaseCurrencySelectorVerify: React.FC = observer(() => {
  const { t } = useTranslation('common', { keyPrefix: 'headerWallet' });
  const { isDemo, setDemoMode } = useDemoMode();
  const navigate = useNavigate();

  const verifyNow = () => {
    navigate(NavigationURLs.Onboarding);
  };

  const tryDemo = () => {
    setDemoMode(true);
  };

  return (
    <FlexLayout direction='column' spacing={16} className='m-16'>
      <Body weight='bold' className='text-center' size='large'>
        {t('verify.title')}
      </Body>

      <Body color='secondary' className='text-center'>
        {t('verify.subTitle')}
      </Body>
      <FlexLayout direction='row' className='w-full' spacing={16}>
        <Button variant='filled' color='primary' onClick={verifyNow} size='lg' className='w-full'>
          {t('verify.verifyNow')}
        </Button>
        {!isDemo && (
          <Button variant='outlined' onClick={tryDemo} size='lg' className='w-full'>
            {t('verify.tryDemo')}
          </Button>
        )}
      </FlexLayout>
    </FlexLayout>
  );
});

export { AppHeaderBaseCurrencySelectorVerify };

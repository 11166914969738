import { useMemo } from 'react';

import { GlobalSearchItemType, Keywords } from '../search.types';

type Props = {
  searchValue: string;
  items: GlobalSearchItemType[];
};

const useFilterSearchModuleItems = ({ searchValue, items }: Props) => {
  const filteredItems = useMemo(() => {
    if (!searchValue?.length) return items;

    const loweredSearch = searchValue.trim().toLowerCase();

    return items.filter(
      (i) =>
        !i.hidden &&
        (i.additionalKeywords?.includes(loweredSearch as Keywords) ||
          i.title.toLowerCase().includes(loweredSearch) ||
          i.subTitle?.toLowerCase().includes(loweredSearch) ||
          i.forceShow),
    );
  }, [items, searchValue]);

  return filteredItems;
};

export { useFilterSearchModuleItems };

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DepositDarkIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon
    {...props}
    sx={{
      ...props.sx,
      width: props.width || 64,
      height: props.height || 64,
      color: props.color || props.htmlColor || 'currentcolor',
    }}
  >
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
      <path
        d='M55.9904 31.5095C55.9569 31.7991 55.7942 32.0862 55.5046 32.3375C55.4017 32.4261 55.2796 32.5122 55.1432 32.5912L26.394 49.1903C25.25 49.8508 23.3953 49.8508 22.249 49.1903L8.85914 41.4603C8.72033 41.3789 8.60067 41.2952 8.49537 41.2043C8.20579 40.953 8.04306 40.6682 8.00956 40.3786C7.99999 40.302 7.99999 40.2254 8.00956 40.1489C8.05503 39.754 8.33742 39.3687 8.85914 39.0671L37.6084 22.468C38.7523 21.8075 40.6094 21.8075 41.7534 22.468L55.1432 30.198C55.6649 30.502 55.9473 30.8873 55.9928 31.2797C56.0024 31.3563 56.0024 31.4329 55.9928 31.5095H55.9904Z'
        fill='black'
      />
      <path
        d='M8.20718 34.375C8.20718 34.2694 8.12503 34.182 8.01961 34.1754C7.91418 34.1688 7.82183 34.2454 7.80872 34.3502C7.79709 34.4432 7.79709 34.5365 7.80872 34.6296C7.82183 34.7344 7.91418 34.8109 8.01961 34.8044C8.12503 34.7978 8.20718 34.7104 8.20718 34.6048V34.375Z'
        fill='#28313C'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M55.6327 24.8322C55.6764 24.7946 55.7018 24.7399 55.7022 24.6821C55.7026 24.6244 55.6781 24.5693 55.6349 24.531C55.5186 24.4279 55.39 24.3363 55.2403 24.2511C55.2401 24.251 55.2399 24.2509 55.2397 24.2508L41.851 16.5214C41.2414 16.1694 40.4545 15.9992 39.6785 15.9992C38.9025 15.9992 38.1156 16.1694 37.506 16.5214L8.75674 33.1181L8.75559 33.1188C8.60838 33.2051 8.47841 33.2957 8.36226 33.396C8.31827 33.434 8.29298 33.4893 8.29298 33.5474C8.29298 33.6055 8.31827 33.6608 8.36226 33.6988C8.47841 33.7991 8.60838 33.8897 8.75559 33.976L8.75674 33.9767L22.1466 41.7066L22.1467 41.7067C22.7574 42.0586 23.5442 42.2288 24.32 42.2288C25.0957 42.2288 25.882 42.0586 26.4916 41.7066L55.2408 25.1075L55.241 25.1074C55.387 25.0229 55.5194 24.9297 55.6327 24.8322Z'
        fill='#28313C'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M55.6357 24.8323L55.635 24.8329C55.5218 24.9303 55.3894 25.0235 55.2434 25.108L55.2432 25.1082L26.494 41.7072C25.8844 42.0592 25.0981 42.2294 24.3224 42.2294C23.5466 42.2294 22.7598 42.0592 22.1491 41.7073L22.149 41.7073L8.75914 33.9773L8.75799 33.9766C8.61079 33.8903 8.4808 33.7997 8.36464 33.6994L8.36429 33.6991C8.04705 33.4238 7.85181 33.0967 7.81099 32.7463C7.79951 32.6538 7.79951 32.5612 7.81098 32.4688C7.86645 31.9905 8.20418 31.5584 8.75905 31.2377L8.75913 31.2377L37.5084 14.6386C38.118 14.2866 38.9049 14.1164 39.6809 14.1164C40.4569 14.1164 41.2438 14.2866 41.8534 14.6386L55.2432 22.3686L55.2439 22.369C55.7981 22.6918 56.1359 23.1236 56.1914 23.5996C56.1914 23.5999 56.1914 23.6002 56.1915 23.6005L55.9928 23.6235L55.6357 24.8323ZM55.6357 24.8323C55.9436 24.5651 56.1361 24.2473 56.1848 23.9094L55.6357 24.8323Z'
        fill='#28313C'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M53.7021 21.829C53.764 21.7932 53.8021 21.7272 53.8021 21.6557C53.8021 21.5843 53.7639 21.5183 53.702 21.4825L50.007 19.3502C49.9451 19.3145 49.8689 19.3145 49.807 19.3502L16.9151 38.3425C16.8533 38.3782 16.8151 38.4443 16.8151 38.5157C16.8151 38.5872 16.8533 38.6532 16.9151 38.6889L20.6078 40.8212C20.6697 40.857 20.7459 40.857 20.8078 40.8212L53.7021 21.829Z'
        fill='#4C9CF2'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.326 22.7126C38.1795 22.7126 38.9619 22.5134 39.5379 22.1811C40.1086 21.8519 40.5127 21.3671 40.5127 20.7895C40.5127 20.2119 40.1086 19.7271 39.5379 19.3978C38.9619 19.0655 38.1795 18.8664 37.326 18.8664C36.4726 18.8664 35.6902 19.0655 35.1142 19.3978C34.5435 19.7271 34.1394 20.2119 34.1394 20.7895C34.1394 21.3671 34.5435 21.8519 35.1142 22.1811C35.6902 22.5134 36.4726 22.7126 37.326 22.7126Z'
        fill='#4C9CF2'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.0662 21.1306C40.9197 21.1306 41.7021 20.9314 42.2781 20.5991C42.8488 20.2699 43.2529 19.785 43.2529 19.2075C43.2529 18.6299 42.8488 18.145 42.2781 17.8158C41.7021 17.4835 40.9197 17.2844 40.0662 17.2844C39.2128 17.2844 38.4304 17.4835 37.8544 17.8158C37.2837 18.145 36.8795 18.6299 36.8795 19.2075C36.8795 19.785 37.2837 20.2699 37.8544 20.5991C38.4304 20.9314 39.2128 21.1306 40.0662 21.1306Z'
        fill='#B2D4F9'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8531 29.0956L19.9898 28.4375'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5973 32.7075L17.4243 29.9219'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.4534 31.8575L27.0114 26.918'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7942 33.9717L16.8093 32.8086'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.7212 30.7748L27.1263 29.3867'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9888 35.2385L23.0985 31.7109'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M56.1904 23.8516C56.1904 23.7411 56.1009 23.6516 55.9904 23.6516H55.9881C55.8865 23.6516 55.8011 23.7277 55.7894 23.8286C55.7629 24.0573 55.6325 24.3016 55.3716 24.5281C55.2791 24.6077 55.1674 24.6868 55.0407 24.7602L26.2916 41.3592C25.7572 41.6677 25.0442 41.8278 24.32 41.8278C23.5959 41.8278 22.8822 41.6677 22.3465 41.3591L8.95792 33.6299C8.95776 33.6298 8.9576 33.6297 8.95744 33.6296C8.82731 33.5533 8.71817 33.4765 8.62387 33.3951C8.36239 33.1681 8.2323 32.926 8.20588 32.6977C8.1937 32.5924 8.1015 32.5149 7.99569 32.521C7.88988 32.5271 7.8072 32.6147 7.8072 32.7207V34.6017C7.8072 34.6094 7.80764 34.617 7.80852 34.6246C7.86364 35.1033 8.20144 35.5357 8.7567 35.8566L8.75676 35.8567L22.1466 43.589L22.1468 43.5891C22.7574 43.9409 23.5442 44.1112 24.32 44.1112C25.0958 44.1112 25.8821 43.941 26.4916 43.589L55.2409 26.9899L55.241 26.9899C55.7962 26.6689 56.134 26.2366 56.1891 25.7579C56.19 25.7503 56.1904 25.7426 56.1904 25.735V23.8516Z'
        fill='#1B2129'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.1151 38.3424C17.0533 38.3067 16.977 38.3067 16.9151 38.3424C16.8533 38.3781 16.8151 38.4442 16.8151 38.5156V40.3991C16.8151 40.4705 16.8533 40.5365 16.9151 40.5723L20.6078 42.7046C20.6697 42.7403 20.7459 42.7403 20.8078 42.7046C20.8697 42.6689 20.9078 42.6028 20.9078 42.5314V40.648C20.9078 40.5765 20.8697 40.5105 20.8078 40.4748L17.1151 38.3424Z'
        fill='#B2D4F9'
        stroke='#11151A'
        strokeWidth='0.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

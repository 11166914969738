import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Redo, Undo } from '@swyftx/aviary/icons/outlined';

import { UndoRedoState } from 'public/assets/charting_library/charting_library';

import { TradingViewContext } from '../../../context/TradingView.context';

const TradingViewWidgetHeaderUndoRedo: React.FC = () => {
  const { advancedChartState } = useContext(TradingViewContext);
  const [undoText, setUndoText] = useState<string | undefined>();
  const [redoText, setRedoText] = useState<string | undefined>();

  const onUndoRedoStateChange = useCallback((e: UndoRedoState) => {
    setUndoText(e.originalUndoText);
    setRedoText(e.originalRedoText);
  }, []);

  useEffect(() => {
    if (!advancedChartState) return;

    advancedChartState.onChartReady(() => {
      advancedChartState.subscribe('undo_redo_state_changed', onUndoRedoStateChange);
    });
  }, [advancedChartState, onUndoRedoStateChange]);

  const handleUndo = useCallback(() => {
    if (!advancedChartState || !undoText) return;

    advancedChartState.chart().executeActionById('undo');
  }, [advancedChartState, undoText]);

  const handleRedo = useCallback(() => {
    if (!advancedChartState || !redoText) return;

    advancedChartState.chart().executeActionById('redo');
  }, [advancedChartState, redoText]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (!e.ctrlKey) return;

      if (e.key === 'z') {
        handleUndo();
      } else if (e.key === 'y') {
        handleRedo();
      }
    },
    [handleRedo, handleUndo],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (!advancedChartState) return null;

  return (
    <FlexLayout alignItems='center' spacing={4}>
      <Button
        size='sm'
        variant='ghost'
        color='subtle'
        layout='icon'
        leadingIcon={<Undo className='h-16 w-16' />}
        onClick={handleUndo}
        disabled={!undoText}
        tooltip={undoText ? `Undo ${undoText}` : undefined}
      />
      <Button
        size='sm'
        variant='ghost'
        color='subtle'
        layout='icon'
        leadingIcon={<Redo className='h-16 w-16' />}
        onClick={handleRedo}
        disabled={!redoText}
        tooltip={redoText ? `Redo ${redoText}` : undefined}
      />
    </FlexLayout>
  );
};

export { TradingViewWidgetHeaderUndoRedo };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';
import { Divider, Skeleton, Stack, Typography } from '@swyftx/react-web-design-system';

import { WithdrawOverview } from '@hooks/Withdraw/useWithdrawDescription';

type Props = {
  withdrawDescription: WithdrawOverview;
  loading?: boolean;
};

const WithdrawFaqAccordion: React.FC<Props> = ({ withdrawDescription, loading = false }) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'withdrawInformation.FAQTitles' });

  const getAccordionDetail = (
    title: string,
    body?: string,
    link?: string,
    linkDescription?: string,
    customOnClick?: () => void,
  ) => {
    if (!body) return null;

    return (
      <AccordionDetails>
        <Stack direction='column' spacing={1}>
          <Typography fontSize={14} fontWeight={500}>
            {title}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color='text.secondary'>
            {body}
            {linkDescription && (
              <Typography
                sx={{ cursor: 'pointer' }}
                fontSize={14}
                display='inline'
                color='primary'
                onClick={customOnClick || (() => window.open(link ?? '')?.focus())}
              >
                {' '}
                {linkDescription ?? ''}
              </Typography>
            )}
          </Typography>
        </Stack>
      </AccordionDetails>
    );
  };

  if (loading) {
    return <Skeleton variant='rectangular' width='100%' height='40px' />;
  }

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        backgroundColor: 'inherit',
        '.MuiAccordionSummary-root': {
          paddingX: 0,
        },
        '.MuiAccordionDetails-root': {
          paddingX: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowChevronDown className='h-20 w-20' />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography fontWeight={600} fontSize={14}>
          {t('main')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Divider />
      </AccordionDetails>

      {getAccordionDetail(t('processingTime'), withdrawDescription.processingTime)}

      {getAccordionDetail(t('limits'), withdrawDescription.limits)}

      {getAccordionDetail(t('fees'), withdrawDescription.fees)}

      {getAccordionDetail(t('verification'), withdrawDescription.verification)}

      {getAccordionDetail(t('incorrectWithdrawals'), withdrawDescription.incorrectWithdrawals)}
    </Accordion>
  );
};

export { WithdrawFaqAccordion };

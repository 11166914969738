import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PrivateRoute from '@routes/PrivateRoute';

import Wallet from '@Wallet/Wallet';
import SingleWallet from '@Wallet/subroutes';

import { NavigationURLs } from 'src/lib/navigation/types';

export const WalletRoutes: RouteObject[] = [
  {
    path: NavigationURLs.SingleWallet,
    element: <PrivateRoute element={<SingleWallet />} />,
  },
  {
    path: NavigationURLs.Wallets,
    element: <PrivateRoute element={<Wallet />} />,
  },
  {
    path: NavigationURLs.WalletsRoot,
    element: <PrivateRoute element={<Navigate to='/wallet/all' />} />,
  },
];

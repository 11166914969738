import React from 'react';

import { Cross } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { inputContainerVariants, InputProps, inputVariants } from './Input.styles';
import { Button } from '../Button/Button';
import { FlexLayout } from '../Layout/Flex';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      containerClassName,
      schema,
      onValidityChange,
      onChange,
      leading,
      trailing,
      variant,
      error,
      disabled,
      readOnly = false,
      id,
      fontSize = 'medium',
      value,
      onClear,
      ...otherProps
    },
    ref,
  ) => {
    const validateValue = (value: string) => {
      if (!schema) {
        return true;
      }

      try {
        schema.parse(value);
        return true;
      } catch (err) {
        return false;
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const isValid = validateValue(newValue);

      if (onChange) {
        onChange(event);
      }

      if (onValidityChange) {
        onValidityChange(isValid);
      }
    };

    return (
      <FlexLayout
        className={cn(inputContainerVariants({ error, disabled, readOnly }), containerClassName)}
        alignItems='center'
        spacing={4}
        justifyContent='space-between'
      >
        <FlexLayout alignItems='center' justifyContent='start' spacing={8} className='w-full'>
          {leading}
          <input
            id={id}
            ref={ref}
            readOnly={readOnly}
            className={cn(inputVariants({ variant, error, disabled, fontSize }), className)}
            onChange={handleChange}
            disabled={disabled}
            value={value}
            {...otherProps}
          />
        </FlexLayout>
        <div className='pointer-events-auto'>
          <>
            {onClear && value !== '' && (
              <Button variant='ghost' layout='icon' size='sm' onClick={onClear} className='pointer-events-auto p-2'>
                <Cross className='h-16 w-16' />
              </Button>
            )}
            {trailing}
          </>
        </div>
      </FlexLayout>
    );
  },
);
Input.displayName = 'Input';

export { Input };

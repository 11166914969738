import { useMemo } from 'react';

import { UserStore } from '@shared/store';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useCurrentPlatform } from 'src/lib/utils/hooks/useCurrentPlatform';

const useSwyftxPro = () => {
  const { userProfile } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { currentPlatform } = useCurrentPlatform();

  // Pro is enabled and the user is opted in
  const swyftxProEnabled = useMemo(
    () => isFeatureEnabled(AppFeature.SwyftxPro) && userProfile?.proEnabled,
    [isFeatureEnabled, userProfile?.proEnabled],
  );

  const isSwyftxPro = useMemo(
    () => swyftxProEnabled && currentPlatform === 'swyftx-pro',
    [swyftxProEnabled, currentPlatform],
  );

  return {
    swyftxProEnabled,
    isSwyftxPro,
  };
};

export { useSwyftxPro };

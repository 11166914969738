import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Progress } from '@swyftx/aviary/atoms/Progress';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Danger, Delete, Loading, UploadFile } from '@swyftx/aviary/icons/outlined';

import {
  DocumentUuid,
  FileInProgress,
  UploadedFile,
  UserDocumentWithProcessing,
} from 'src/context/DocumentUpload/SupportingDocument.context';
interface GenericUploadCardProps {
  icon: React.ReactElement;
  key: string;
  fileName: string;
  fileUploadError?: boolean;
  processing?: boolean;
  onDelete?: () => void;
  progress?: number;
}

const GenericUploadCard: React.FC<GenericUploadCardProps> = (props) => {
  const { icon, key, fileName, onDelete, progress, processing } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDelete = () => {
    setIsLoading(true);
    if (onDelete) onDelete();
  };

  return (
    <Card
      key={key}
      className='flex rounded-8 border-color-background-surface-secondary bg-color-background-surface-secondary px-16 py-8'
    >
      <FlexLayout direction='column' spacing={4}>
        <FlexLayout direction='row' className=' w-full items-center'>
          <FlexLayout className='flex w-[90%] items-center' spacing={12}>
            <div>{icon}</div>
            <FlexLayout direction='column' className='truncate'>
              <Body weight='emphasis' size='small' className='w-full'>
                {fileName}
              </Body>
              {processing && (
                <Body size='xsmall' color='secondary' weight='emphasis'>
                  Document is processing. Please wait...
                </Body>
              )}
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='row' className='w-[10%] justify-end'>
            {!processing && onDelete && !isLoading && (
              <Button variant='ghost' size='sm' className='rounded-full w-24' onClick={handleDelete}>
                <Delete className='w-16' />
              </Button>
            )}
            {isLoading && <Loading className='mr-4 w-20 animate-spin' />}
            {processing && <Loading className='mr-4 w-20 animate-spin' />}
          </FlexLayout>
        </FlexLayout>
        {progress !== undefined && !processing && <Progress value={progress} className='mt-2 w-full' />}
      </FlexLayout>
    </Card>
  );
};

interface PreviouslyUploadedFilesProps {
  documents: UserDocumentWithProcessing[];
  deleteFile: (uuid: DocumentUuid) => void;
}

export const PreviouslyUploadedFiles: React.FC<PreviouslyUploadedFilesProps> = (props) => {
  const { documents, deleteFile } = props;

  return (
    <>
      {documents.map((document) => {
        const icon =
          document.status === 'pending' ? (
            <Danger className='w-20 text-color-text-error' />
          ) : (
            <UploadFile className='w-20 text-color-background-info' />
          );
        return (
          <GenericUploadCard
            icon={icon}
            fileName={document.filename}
            key={document.id}
            onDelete={() => deleteFile(document.id)}
            fileUploadError={document.status === 'pending'}
            processing={document.processing}
          />
        );
      })}
    </>
  );
};

interface UploadedFilesProps {
  uploadedFiles: UploadedFile[];
  deleteFile: (uuid: DocumentUuid) => void;
}

export const UploadedFiles: React.FC<UploadedFilesProps> = (props) => {
  const { uploadedFiles, deleteFile } = props;
  return (
    <>
      {uploadedFiles.map(({ file, uuid }) => (
        <GenericUploadCard
          icon={<UploadFile className='w-20 text-color-background-info' />}
          fileName={file.name}
          key={uuid}
          onDelete={() => deleteFile(uuid)}
        />
      ))}
    </>
  );
};

interface UploadInProgressProps {
  filesInProgress: FileInProgress[];
}

export const UploadInProgress: React.FC<UploadInProgressProps> = (props) => {
  const { filesInProgress } = props;
  return (
    <>
      {filesInProgress.map(({ file, progress, processing }) => (
        <GenericUploadCard
          icon={<UploadFile className='w-20' />}
          fileName={file.name}
          key={file.name}
          progress={progress}
          processing={processing}
        />
      ))}
    </>
  );
};

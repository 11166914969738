import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Paste: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M11.25 12.4697C11.25 12.0555 11.5858 11.7197 12 11.7197H13.8706C14.2848 11.7197 14.6206 12.0555 14.6206 12.4697C14.6206 12.8839 14.2848 13.2197 13.8706 13.2197H12C11.5858 13.2197 11.25 12.8839 11.25 12.4697Z'
      fill='currentColor'
    />
    <path
      d='M12 14.5557C11.5858 14.5557 11.25 14.8915 11.25 15.3057C11.25 15.7199 11.5858 16.0557 12 16.0557H16.9022C17.3164 16.0557 17.6522 15.7199 17.6522 15.3057C17.6522 14.8915 17.3164 14.5557 16.9022 14.5557H12Z'
      fill='currentColor'
    />
    <path
      d='M12 17.3984C11.5858 17.3984 11.25 17.7342 11.25 18.1484C11.25 18.5627 11.5858 18.8984 12 18.8984H16.9022C17.3164 18.8984 17.6522 18.5627 17.6522 18.1484C17.6522 17.7342 17.3164 17.3984 16.9022 17.3984H12Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.62889 3.25C7.03252 2.64701 7.7199 2.25 8.5 2.25H10.5C11.2801 2.25 11.9675 2.64701 12.3711 3.25H13C15.0711 3.25 16.75 4.92893 16.75 7V8.25015L17.0324 8.25C18.1459 8.25 19.1175 8.61865 19.81 9.33329C20.4996 10.045 20.8425 11.0277 20.8425 12.1281V18.372C20.8425 19.4672 20.502 20.4455 19.8181 21.156C19.1315 21.8694 18.1675 22.2412 17.0621 22.248L17.0575 22.248L11.8368 22.25C10.7232 22.25 9.75177 21.8814 9.05951 21.1666C8.4501 20.5375 8.11156 19.6966 8.0412 18.75H6C3.92893 18.75 2.25 17.0711 2.25 15V7C2.25 4.92893 3.92893 3.25 6 3.25H6.62889ZM6.26373 4.75H6C4.75736 4.75 3.75 5.75736 3.75 7V15C3.75 16.2426 4.75736 17.25 6 17.25H8.02734V12.1281C8.02734 11.0329 8.36764 10.0548 9.0514 9.34434C9.73795 8.63101 10.7018 8.25954 11.8071 8.25273L11.8117 8.2527L15.25 8.25092V7C15.25 5.75736 14.2426 4.75 13 4.75H12.7363C12.6119 5.87499 11.6581 6.75 10.5 6.75H8.5C7.34186 6.75 6.38809 5.87499 6.26373 4.75ZM8.5 3.75C8.08579 3.75 7.75 4.08579 7.75 4.5C7.75 4.91421 8.08579 5.25 8.5 5.25H10.5C10.9142 5.25 11.25 4.91421 11.25 4.5C11.25 4.08579 10.9142 3.75 10.5 3.75H8.5ZM11.8143 9.75271L17.0325 9.75C17.8091 9.75007 18.3676 10.0003 18.7327 10.3771C19.1006 10.7568 19.3425 11.3381 19.3425 12.1281V18.372C19.3425 19.1575 19.1025 19.7364 18.7374 20.1158C18.3753 20.492 17.8224 20.7428 17.0549 20.748L17.0529 20.748L11.8368 20.75C11.0601 20.75 10.5019 20.4998 10.137 20.123C9.7692 19.7434 9.52734 19.162 9.52734 18.372V12.1281C9.52734 11.3424 9.76723 10.7637 10.1322 10.3845C10.494 10.0086 11.0467 9.75787 11.8143 9.75271Z'
      fill='currentColor'
    />
  </svg>
);

export { Paste };

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckBox from '@mui/icons-material/CheckBox';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Grid, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { PasswordValidator } from '@shared/validator';
import { PasswordError } from '@shared/validator/Validators/Errors';

import { PasswordErrorMappings } from './PasswordRequirements.data';
import PasswordRequirementsProps from './PasswordRequirements.types';

// mui icon adds a strange padding so we need to balance it out
const getIconSize = (isBox?: boolean) =>
  isBox ? `${pxToRem(12)} !important` : `calc(${pxToRem(12)} + ${pxToRem(3)}) !important`;

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({ error, password, type, confirmPassword }) => {
  const { t } = useTranslation('common');
  const [requirements, setRequirements] = useState<PasswordError>();
  const passwordValidator = new PasswordValidator();

  const theme = useTheme();

  const validate = useCallback(() => {
    setRequirements(passwordValidator.isValid(password));
  }, [password]);

  useEffect(() => {
    validate();
  }, [password]);

  const renderErrors = () => {
    if (!requirements) return null;

    const errors = Object.keys(requirements);

    return errors.map((err) => {
      const checked = (requirements as any)[err];
      const label = PasswordErrorMappings[err];

      if (!label) return null;

      return (
        <Grid item key={err}>
          <Stack direction='row' alignItems='center' spacing={0.75}>
            {checked ? (
              <CheckBox
                color='success'
                sx={{
                  width: getIconSize(false),
                  height: getIconSize(false),
                  padding: 0,
                }}
              />
            ) : (
              <Box
                width={getIconSize(true)}
                height={getIconSize(true)}
                bgcolor={theme.palette.grey[200]}
                borderRadius={pxToRem(2)}
              />
            )}
            <Typography fontSize='10px' id='password-match-error-label'>
              {t(label)}
            </Typography>
          </Stack>
        </Grid>
      );
    });
  };

  const renderMatchError = () => {
    const checked = password.length > 0 && password === confirmPassword;

    return (
      <Grid item>
        <Stack direction='row' alignItems='center' spacing={0.75}>
          {checked ? (
            <CheckBox
              color='success'
              sx={{
                width: getIconSize(false),
                height: getIconSize(false),
                padding: 0,
              }}
            />
          ) : (
            <Box
              width={getIconSize(true)}
              height={getIconSize(true)}
              bgcolor={theme.palette.grey[200]}
              borderRadius={pxToRem(2)}
            />
          )}
          <Typography fontSize='10px' id='password-match-error-label'>
            {t('errors.password.passwordMatch')}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  return (
    <Box marginTop={error ? 4 : 2} marginBottom={2} display='flex'>
      <Grid container spacing={0.75}>
        {type === 'create' && renderErrors()}
      </Grid>

      {type === 'reenter' && renderMatchError()}
    </Box>
  );
};

export default PasswordRequirements;

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

export interface SettingsItemProps {
  icon?: React.ReactNode;
  action?: React.ReactNode;
  titleChip?: React.ReactNode;
  title?: string;
  description?: string;
  spotLightElementId?: string;
}

export const SettingsItem: React.FC<SettingsItemProps> = (props) => {
  const { title, description, icon, action, titleChip, spotLightElementId } = props;

  return (
    <FlexLayout direction='column' className='p-16 px-24' spacing={16} data-spotlightelementid={spotLightElementId}>
      <FlexLayout direction='row' className='@container'>
        <FlexLayout direction='row' spacing={16} className='flex basis-full items-center'>
          {icon}
          <FlexLayout direction='column' spacing={4}>
            <FlexLayout direction='row' spacing={8}>
              <Body weight='emphasis' color='primary'>
                {title}
              </Body>
              {titleChip}
            </FlexLayout>
            <Body size='small' color='secondary'>
              {description}
            </Body>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='row' className='items-center justify-end'>
          {action}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

/* eslint-disable no-unused-vars */
import { api } from '@shared/api';
import { Asset, LiveRate, LiveRates } from '@shared/api/@types/markets';
import { CronJobService } from '@shared/cron';
import { FiatIdEnum, TimesEnum } from '@shared/enums';
import handleError, { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { StorageKey } from '@shared/storage';
import { RatesStore, UserStore } from '@shared/store';

import { assetService } from './assetService';

const logTag = 'RATES_SERVICE';

class RatesService extends CronJobService {
  // This service should only run once but needs to account for being
  // unloaded/reloaded on concurrent pages
  // use a trivial first in last out method to control the service
  queue = 0;

  private rates: LiveRates = {};

  async update() {
    const { setLiveRates } = RatesStore.useRatesStore;
    const isPro = Boolean(JSON.parse(localStorage.getItem(StorageKey.CURRENT_PLATFORM) || 'swyftx') === 'swyftx-pro');

    try {
      const { userBaseCurrency } = UserStore.useUserStore;

      const response = isPro
        ? await api.endpoints.getProRates({ params: { assetId: userBaseCurrency } })
        : await api.endpoints.getRates({ params: { assetId: userBaseCurrency } });

      const newRates = response.data;

      setLiveRates(newRates);
      this.rates = newRates;
    } catch (ex) {
      // ignore if known
      if (!(ex as SwyftxError).isSwyftxError) {
        handleError(logTag, 'Failed to update rates', ex);
      }
    }
  }

  getRate(asset: Asset | Asset['id']): LiveRate {
    const rateAsset = assetService.getAsset(asset);

    // temp earn testing overriding rates.
    if (rateAsset?.code === 'TAUD') {
      let taudRate = this.rates[FiatIdEnum.AUD];
      if (!taudRate) {
        taudRate = {
          midPrice: '1',
          askPrice: '1',
          bidPrice: '1',
          buyLiquidityFlag: false,
          sellLiquidityFlag: false,
          dailyPriceChange: '0',
        };
      }
      return taudRate;
    }

    let rate = rateAsset && this.rates[rateAsset.id];
    if (!rate) {
      rate = {
        midPrice: '0',
        askPrice: '0',
        bidPrice: '0',
        buyLiquidityFlag: false,
        sellLiquidityFlag: false,
        dailyPriceChange: '0',
      };
    }
    return rate;
  }

  public async init() {
    // add to queue and start job if it isn't already started
    this.queue += 1;
    if (this.queue === 1 && !this.job) {
      return this._init();
    }
    return Promise.resolve();
  }

  public cleanup(force = false) {
    // remove from queue and stop job if no-one left listening
    this.queue -= 1;
    if (force || this.queue <= 0) {
      this._cleanup();
      this.queue = 0;
    }
  }
}

export const ratesService = new RatesService(10 * TimesEnum.SECOND);

// [dev] When hot reloading remove the cron
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const module: any;
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    logger.debug(logTag, 'Hot reload cleanup');
    ratesService.queue = 0;
    ratesService.cleanup();
  });
}

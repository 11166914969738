import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Danger: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M11.2505 16.12C11.2505 15.706 11.5865 15.364 12.0005 15.364C12.4145 15.364 12.7505 15.695 12.7505 16.109V16.12C12.7505 16.534 12.4145 16.87 12.0005 16.87C11.5865 16.87 11.2505 16.534 11.2505 16.12Z'
      fill='currentColor'
    />
    <path
      d='M12.7407 10.1467C12.7407 9.73251 12.4049 9.39673 11.9907 9.39673C11.5765 9.39673 11.2407 9.73251 11.2407 10.1467V13.2467C11.2407 13.6609 11.5765 13.9967 11.9907 13.9967C12.4049 13.9967 12.7407 13.6609 12.7407 13.2467V10.1467Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4736 4.30549L21.7482 17.0374C21.7642 17.0654 21.7784 17.0944 21.7907 17.1242C21.9122 17.4196 21.9794 17.7313 21.9969 18.0425L21.9971 18.0459C22.0774 19.607 20.8769 20.9359 19.3172 21.0174C19.3042 21.0181 19.2911 21.0185 19.278 21.0185H4.79605C4.78397 21.0185 4.77195 21.0182 4.76001 21.0176C4.734 21.0189 4.70767 21.0188 4.68109 21.0173C4.36904 20.9998 4.06042 20.9324 3.76684 20.8142L3.76412 20.8131C2.31814 20.2241 1.62131 18.5751 2.20914 17.1273C2.2217 17.0964 2.2363 17.0664 2.25285 17.0374L9.5274 4.30568C9.78462 3.84246 10.1676 3.45992 10.6328 3.20422C11.9985 2.44888 13.7161 2.9426 14.4736 4.30549ZM4.75972 19.5193C4.77176 19.5188 4.78387 19.5185 4.79605 19.5185H19.2563C19.9815 19.4712 20.5356 18.851 20.4991 18.1246C20.4913 17.9876 20.464 17.8558 20.4189 17.7345L13.1637 5.03657C12.8082 4.39394 11.9999 4.1615 11.3576 4.51745L11.3553 4.51871C11.1382 4.63808 10.9582 4.8177 10.8375 5.03623L10.8323 5.04558L3.58285 17.7332C3.33642 18.4027 3.66333 19.1515 4.32856 19.4233C4.465 19.4781 4.61011 19.5104 4.75972 19.5193Z'
      fill='currentColor'
    />
  </svg>
);

export { Danger };

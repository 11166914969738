import React, { useCallback, useEffect, useState } from 'react';

import { ArrowChevronRight } from '@swyftx/aviary/icons/outlined';

import { StepperStepType, StepperVariant } from './Stepper.types';
import { StepperDivider } from './StepperDivider';
import { StepperStep } from './StepperStep';
import { FlexLayout } from '../Layout/Flex';

type Props = {
  steps: StepperStepType[];
  currentStep: string;
  variant?: StepperVariant;
};

const Stepper: React.FC<Props> = ({ steps, variant = 'standard', currentStep }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const getCurrentStepIndex = useCallback(
    () => steps.findIndex((step) => step.key === currentStep) || 0,
    [steps, currentStep],
  );

  useEffect(() => {
    setCurrentStepIndex(getCurrentStepIndex());
  }, [getCurrentStepIndex]);

  return (
    <FlexLayout spacing={12} className='w-full'>
      {steps.map((step, index) => {
        const active = index === currentStepIndex;
        const completed = index < currentStepIndex;
        const lastItem = index === steps.length - 1;
        return (
          <FlexLayout spacing={12} alignItems='center' className={`${lastItem ? '' : 'w-full'}`} key={step.key}>
            <StepperStep step={step} index={index} active={active} variant={variant} completed={completed} />
            {!lastItem && (
              <>
                {variant === 'compact' && <ArrowChevronRight className='text-color-text-primary' />}
                {variant === 'standard' && <StepperDivider completed={completed} />}
              </>
            )}
          </FlexLayout>
        );
      })}
    </FlexLayout>
  );
};

export { Stepper };

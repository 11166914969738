import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Cross: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L13.3107 12.25L19.5303 6.03033C19.8232 5.73744 19.8232 5.26256 19.5303 4.96967C19.2374 4.67678 18.7626 4.67678 18.4697 4.96967L12.25 11.1893L6.03033 4.96967C5.73744 4.67678 5.26256 4.67678 4.96967 4.96967C4.67678 5.26256 4.67678 5.73744 4.96967 6.03033L11.1893 12.25L4.96967 18.4697C4.67678 18.7626 4.67678 19.2374 4.96967 19.5303C5.26256 19.8232 5.73744 19.8232 6.03033 19.5303L12.25 13.3107L18.4697 19.5303Z'
      fill='currentColor'
    />
  </svg>
);

export { Cross };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps } from '@mui/material';

import { Image } from '@swyftx/aviary/atoms/Image';

import { ContentBanner } from '@global-components/ContentBanner';
import { ContentBannerProps } from '@global-components/ContentBanner/ContentBanner';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';

type Props = {
  containerSx?: BoxProps['sx'];
  bannerCardSx?: ContentBannerProps['cardSx'];
  message: string;
};

export const WithdrawalsBlockedBanner: React.FC<Props> = observer(({ containerSx, bannerCardSx, message }) => {
  const { t } = useTranslation('common', { keyPrefix: 'withdrawalsBlockedBanner' });
  const { show } = useIntercom();

  const bannerIcon = (
    <Image image='account_restricted' usePalette={false} className='h-64 w-64' alt='account restricted icon' />
  );

  return (
    <Box
      width='100%'
      sx={{
        ...containerSx,
      }}
    >
      <ContentBanner
        onAction={() => show()}
        actionLabel={message.toLowerCase().includes('please contact support') ? t('actionLabel') : ''}
        description={message}
        cardSx={bannerCardSx}
        title={t('title')}
        icon={bannerIcon}
      />
    </Box>
  );
});

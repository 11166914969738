import { useCallback, useMemo, useRef } from 'react';

import { Big } from '@shared/safe-big';
import { StorageKey } from '@shared/storage';

import { ImperativePanelHandle } from 'react-resizable-panels';
import { useLocalStorage, useMeasure } from 'react-use';

type Props = {
  key: StorageKey;
  minimizedHeight?: number;
};

const DEFAULT_TILE_HEIGHT = 30;

const useMinimizedResize = ({ key, minimizedHeight = 60 }: Props) => {
  const panelRef = useRef<ImperativePanelHandle>(null);
  const [containerRef, { height }] = useMeasure<HTMLDivElement>();
  const [minimizedTile, setMinimizedTile] = useLocalStorage<boolean>(key, false);

  // gets the orders tile height as a percentage of the total height
  const minimizedTileHeight = useMemo(() => {
    if (height === 0) {
      return DEFAULT_TILE_HEIGHT;
    }

    const percentage = Number(Big(minimizedHeight).times(100).div(height).toPrecision(4));

    return !isNaN(percentage) ? percentage : DEFAULT_TILE_HEIGHT;
  }, [height, minimizedHeight]);

  const toggleMinimize = useCallback(() => {
    const panel = panelRef.current;
    if (panel) {
      if (minimizedTile) {
        // Resizes to 50% of the total height on smaller screen heights
        if (height < 700) {
          panel.resize(50);
        } else {
          panel.expand();
        }
        setMinimizedTile(false);
      } else {
        panel.collapse();
        setMinimizedTile(true);
      }
    }
  }, [height, minimizedTile, setMinimizedTile]);

  return {
    panelRef,
    containerRef,
    minimizedTile,
    setMinimizedTile,
    toggleMinimize,
    minimizedTileHeight,
  };
};

export { useMinimizedResize };

import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { Copy } from '@swyftx/aviary/icons/outlined';
import { Typography, Skeleton } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';
import { DepositAddressDetails } from '@shared/api/@types/trade';
import { DepositMethodEnum } from '@shared/enums';

import { useDeposit } from '@hooks/Deposit/useDeposit';
import { useCopy } from '@hooks/useCopy';

const BankTransfers: React.FC<{ asset: Asset }> = ({ asset }) => {
  const { t } = useTranslation('onboarding');
  const { onCopy } = useCopy();

  const { depositDescription, bankAddressDetails, loading } = useDeposit({
    asset,
    transferType: DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
  });

  return (
    <div className='py-12'>
      <Typography color='text.secondary' marginBottom={3}>
        {t('steps.depositFunds.option.bankTransfers.description')}
      </Typography>
      <Card>
        <CardContent className='grid gap-4 py-4'>
          <ul className='divide-y divide-gray-200'>
            <li className='flex items-center justify-between py-16'>
              <div className='flex-grow'>
                <span className='font-normal'>Account Name</span>
              </div>
              <div className='mr-24 text-right text-blue-600'>
                <span>{depositDescription.extraBankAddressDetails?.accountName || ''}</span>
              </div>
              <div>
                <Button
                  variant='filled'
                  color='subtle'
                  size='sm'
                  leadingIcon={<Copy />}
                  onClick={() => onCopy(depositDescription.extraBankAddressDetails?.accountName ?? '', 'Account Name')}
                >
                  {t('labels.copy')}
                </Button>
              </div>
            </li>
            {depositDescription.addressDetails?.map(({ title, key }) => (
              <li key={`deposit-information-${title}-${key}`} className='flex items-center justify-between py-16'>
                <div className='flex-grow'>
                  <span className='font-normal'>{title}</span>
                </div>
                {!loading && bankAddressDetails ? (
                  <div className='mr-24 text-right text-blue-600'>
                    <span>{bankAddressDetails[key as keyof DepositAddressDetails]}</span>
                  </div>
                ) : (
                  <div className='mr-24 w-96 text-right'>
                    <Skeleton />
                  </div>
                )}
                <div>
                  <Button
                    variant='filled'
                    color='subtle'
                    size='sm'
                    leadingIcon={<Copy />}
                    disabled={loading || bankAddressDetails === undefined}
                    onCopy={() =>
                      onCopy(bankAddressDetails ? bankAddressDetails[key as keyof DepositAddressDetails] : '', title)
                    }
                  >
                    {t('labels.copy')}
                  </Button>
                </div>
              </li>
            ))}
            <li className='flex items-center justify-between py-16'>
              <div className='flex-grow'>
                <span className='font-normal'>Reference</span>
              </div>
              <div className='mr-24 text-right text-blue-600'>
                <span>{depositDescription.extraBankAddressDetails?.reference ?? ''}</span>
              </div>
              <div>
                <Button
                  variant='filled'
                  color='subtle'
                  size='sm'
                  leadingIcon={<Copy />}
                  onClick={() => onCopy(depositDescription.extraBankAddressDetails?.reference ?? '', 'Reference')}
                >
                  {t('labels.copy')}
                </Button>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>
      <br />
      <hr style={{ borderTop: '2px dashed lightGray' }} />
      <ul className='py-16 text-gray-500'>
        <li className='flex items-center justify-between py-6'>
          <div className='flex-grow'>
            <span className='font-normal'>{t('steps.depositFunds.labels.processingTime')}</span>
          </div>
          <div className='text-right'>
            <span>{t('steps.depositFunds.labels.instantWithOsko')}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-6'>
          <div className='flex-grow'>
            <span className='font-normal'>{t('steps.depositFunds.labels.depositFee')}</span>
          </div>
          <div className='text-right'>
            <span>{t('steps.depositFunds.labels.free')}</span>
          </div>
        </li>
      </ul>
      <Alert severity='warning' sx={{ mb: 3 }}>
        <AlertTitle>You may experience delays from your bank</AlertTitle>
        Deposits may be subject to a temporary, 24hr hold for the purpose of conducting additional compliance checks by
        your banking institution.
      </Alert>
    </div>
  );
};

export { BankTransfers };

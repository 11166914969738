import React, { useMemo } from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { MobileInput } from '@swyftx/aviary/complex/MobileInput/MobileInput';

import { Individual } from '@shared/api';
import { CountriesEnum } from '@shared/enums';

import { MemberType } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';
import { Countries } from '@utils/countries';

import getCountry from 'libphonenumber-js/max';

interface Props {
  member: Individual;
  memberType?: MemberType;
  isAlsoShareholder?: boolean;
  isAlsoBeneficiary?: boolean;
  isCorporateTrustVariant?: boolean;
}

export const ViewIndividualDetails: React.FC<Props> = (props) => {
  const { member, memberType, isAlsoShareholder, isAlsoBeneficiary, isCorporateTrustVariant } = props;

  const phoneCountryCode = member?.phone
    ? CountriesEnum[getCountry(member?.phone)?.country as keyof typeof CountriesEnum]
    : undefined;

  const phone = useMemo(() => {
    if (member?.phone && phoneCountryCode) {
      const prevDialCode = Countries[phoneCountryCode].dialCode;
      return member?.phone?.replace(prevDialCode, '0');
    }
  }, [member?.phone, phoneCountryCode]);

  return (
    <FlexLayout direction='column' spacing={24}>
      <FlexLayout direction='row' className='w-full justify-between' spacing={12}>
        <FlexLayout direction='column' spacing={4} className='w-full'>
          <Body size='small' weight='emphasis'>
            First name:
          </Body>
          <Input value={member.firstName} disabled />
        </FlexLayout>
        <FlexLayout direction='column' spacing={4} className='w-full'>
          <Body size='small' weight='emphasis'>
            Last name:
          </Body>
          <Input value={member.lastName} disabled />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout direction='column' spacing={4} className='w-full'>
        <Body size='small' weight='emphasis'>
          Email address:
        </Body>
        <Input value={member.email} disabled />
      </FlexLayout>
      <FlexLayout direction='column' spacing={4} className='w-full'>
        <Body size='small' weight='emphasis'>
          Phone number:
        </Body>
        <MobileInput defaultCountry={phoneCountryCode ?? CountriesEnum.AU} value={phone} disabled />
      </FlexLayout>
      {memberType === 'director' && (
        <FlexLayout direction='column' spacing={12} className='w-full'>
          <Body size='small' weight='emphasis'>
            This individual is also a:
          </Body>
          <FlexLayout direction='row' className='w-full items-center' spacing={16}>
            <Checkbox className='rounded-[100%]' checked={isAlsoShareholder} disabled />
            <Body className='text-color-text-disabled'>Shareholder (if they own 25% or more in shares)</Body>
          </FlexLayout>
          {isCorporateTrustVariant && (
            <FlexLayout direction='row' className='w-full items-center' spacing={16}>
              <Checkbox className='rounded-[100%]' checked={isAlsoBeneficiary} disabled />
              <Body className='text-color-text-disabled'>Beneficiary</Body>
            </FlexLayout>
          )}
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

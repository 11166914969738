import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { TradeInputBase } from '@global-components/Input/TradeInput/components/TradeInputBase';

import { AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UniversalTradeStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';

const TradeMultiDetailsLimitInput: React.FC = observer(() => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeMultiDetails' });
  const { balances } = UserStore.useUserStore;
  const { tradeFrom, setMaxTradeValue, maxTradeValue, multiTradeError, setMultiTradeError } = UniversalTradeStore;
  const [limit, setLimit] = useState(maxTradeValue);

  useEffect(() => {
    setLimit(maxTradeValue);
  }, [maxTradeValue]);

  const fromAsset = assetService.getAsset(tradeFrom[0]);

  const availableBalance = useMemo(() => {
    if (!fromAsset) return '0';

    if (balances[fromAsset.id] === undefined) return '0';

    return balances[fromAsset.id]?.availableBalance;
  }, [balances, fromAsset]);

  const onChangeLimit = (parsedValue: string) => {
    const isValid: boolean = parsedValue === '.' || !Number.isNaN(Number(parsedValue));

    if (!isValid) return;

    setLimit(parsedValue);
  };

  const onChangeValue = (parsedValue?: string) => {
    const isValid: boolean = parsedValue === '.' || !Number.isNaN(Number(parsedValue));

    if (!isValid) return;

    setMaxTradeValue(parsedValue || '');

    if (fromAsset && Big(parsedValue).gt(availableBalance)) {
      setMultiTradeError(t('labels.amountToLarge'));
    } else {
      setMultiTradeError('');
    }
  };

  useDebounce(() => onChangeValue(limit), 200, [limit]);

  if (!fromAsset) return null;

  return (
    <FlexLayout direction='column' spacing={8}>
      <Body color='primary'>{t('labels.howMuch', { asset: fromAsset.code })}</Body>
      <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
        <Body size='small' color='primary'>
          {t('labels.available', { amount: formatCurrency(availableBalance, fromAsset) })}
        </Body>
        <Button
          variant='outlined'
          size='sm'
          onClick={() => onChangeValue(Big(availableBalance).round(fromAsset.price_scale, 0).toString())}
        >
          {t('labels.setMax')}
        </Button>
      </FlexLayout>

      <TradeInputBase
        value={limit}
        error={(multiTradeError || '').length > 0}
        onChange={onChangeLimit}
        showDollarSign={fromAsset.assetType === AssetType.Fiat}
        suffix={fromAsset.code}
        asset={fromAsset}
      />

      {multiTradeError && (
        <Body size='small' color='error'>
          {multiTradeError}
        </Body>
      )}
    </FlexLayout>
  );
});

TradeMultiDetailsLimitInput.displayName = 'TradeMultiDetailsLimitInput';

export { TradeMultiDetailsLimitInput };

import { CameraFilled, DocumentFilled, EditInSquareFilled, ScanFilled } from '@swyftx/aviary/icons/filled';

type WhatsInvolvedItem = {
  icon: React.ReactNode;
  text: any;
};

const whatsInvolvedItems: WhatsInvolvedItem[] = [
  {
    icon: <DocumentFilled className='h-[1.5rem] w-[1.5rem]' />,
    text: 'firstTimeVerificationModal.labels.provideBasic',
  },
  {
    icon: <ScanFilled className='h-[1.5rem] w-[1.5rem]' />,
    text: 'firstTimeVerificationModal.labels.scanId',
  },
  {
    icon: <CameraFilled className='h-[1.5rem] w-[1.5rem]' />,
    text: 'firstTimeVerificationModal.labels.selfie',
  },
  {
    icon: <EditInSquareFilled className='h-[1.5rem] w-[1.5rem]' />,
    text: 'firstTimeVerificationModal.labels.governmentId',
  },
];

export { whatsInvolvedItems };

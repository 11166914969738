import { withdrawalService } from '@shared/services';

import v from 'validator';

import { Validator, ValidatorResponse } from '.';

class BsbValidator extends Validator<string> {
  validate = async (input: string): Promise<ValidatorResponse> => {
    let name = '';
    let valid = v.matches(input, /^[0-9]{3}-{0,1}[0-9]{3}$/);

    if (valid) {
      const res = await withdrawalService.verifyBSB(input);
      if ('bankName' in res) {
        name = res.bankName;
      } else {
        valid = false;
        this.message = res.errorMessage;
      }
    }

    return {
      message: valid ? name : this.message,
      valid,
    };
  };
}

export default BsbValidator;

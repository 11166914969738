import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { Card } from '@swyftx/aviary/atoms/Card';

import { UniversalTradeStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { UniversalTradePanel } from '@Trade/components';

import { observer } from 'mobx-react-lite';
import { useMeasure } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const TRANSITION_DURATION_MS = 100;
const DESKTOP_TRADE_CONTAINER_WIDTH = 400;
const NON_DESKTOP_TRADE_CONTAINER_WIDTH = 304;
const TRADE_PAGE_PANEL_WIDTH = 600;

interface Props {
  universalTradePage?: boolean;
}

const GlobalTradeButton: React.FC<Props> = observer((props) => {
  const { universalTradePage } = props;
  const { showGlobalTrade, setShowGlobalTrade } = UniversalTradeStore;
  const [boxRef] = useMeasure();

  const { isDesktop, isMediumScreen } = useScreenBreakpoints();
  const { navigate } = useNavigateRoute();

  const location = useLocation();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const containerWidth = useMemo(() => {
    if (universalTradePage) return TRADE_PAGE_PANEL_WIDTH;
    if (isDesktop) return DESKTOP_TRADE_CONTAINER_WIDTH;

    return NON_DESKTOP_TRADE_CONTAINER_WIDTH;
  }, [isDesktop]);

  useEffect(() => {
    if (!isMediumScreen && showGlobalTrade) {
      navigate(NavigationURLs.UniversalTrade);
      setShowGlobalTrade(false);
    }
  }, [isMediumScreen, showGlobalTrade]);

  const isOnUniversalTradePage = useMemo(
    () =>
      isFeatureEnabled(AppFeature.UniversalTradePage) &&
      location.pathname.includes(NavigationURLs.UniversalTrade) &&
      !location.pathname.includes(NavigationURLs.ProfileTradeFees),
    [],
  );

  return (
    <Box
      ref={boxRef}
      right={0}
      position='relative'
      height='100%'
      width={!showGlobalTrade ? 0 : containerWidth}
      sx={{
        transition: `
          transform ${TRANSITION_DURATION_MS}ms, 
          width ${showGlobalTrade ? 0 : TRANSITION_DURATION_MS}ms,
          opacity 500ms`,
        transform: `translateX(${showGlobalTrade ? 0 : '100%'})`,
        opacity: showGlobalTrade ? 1 : 0.3,
      }}
    >
      <Card className='h-full'>
        <UniversalTradePanel dismissible tradePanel={isOnUniversalTradePage} />
      </Card>
    </Box>
  );
});

export { GlobalTradeButton };

export type TailWindBreakPoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
  xxxl: number;
};

// This is copied from the tailwind.config.js file as importing it directly is not working nicely
const breakpoints: TailWindBreakPoints = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
  xxxl: 2560,
};

export type AtLeastBreakpoints = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
  xxxl: boolean;
};

export { breakpoints };

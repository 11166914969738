import { Breakpoint, useTheme } from '@mui/material';

import { UIStore } from '@shared/store';

type BpInput<T> = {
  [key in Breakpoint]?: T;
};

type BpInputXsRequired<T> = BpInput<T> & {
  xs: T;
};

export interface Bx {
  // must provide `xs`, guarantees no implicitly undefined return type
  <R>(input: BpInputXsRequired<R>): R;

  // all optional, but it means if there's an xs breakpoint and there's no xs provided, undefined is returned
  <R>(input: BpInput<R>): R | undefined;
}

export const useContentBreakpoint = () => {
  const { mainContentWidth } = UIStore.useUIStore;

  const {
    breakpoints: {
      values: { sm, md, lg, xl },
    },
  } = useTheme();

  const isContentAtBreakpoint = (breakpoint: number) => breakpoint < mainContentWidth;

  const contentBreakpoint: Breakpoint = (() => {
    if (isContentAtBreakpoint(xl)) {
      return 'xl';
    }

    if (isContentAtBreakpoint(lg)) {
      return 'lg';
    }

    if (isContentAtBreakpoint(md)) {
      return 'md';
    }

    if (isContentAtBreakpoint(sm)) {
      return 'sm';
    }

    return 'xs';
  })();

  const isXs = contentBreakpoint === 'xs';
  const isSm = contentBreakpoint === 'sm';
  const isMd = contentBreakpoint === 'md';
  const isLg = contentBreakpoint === 'lg';
  const isXl = contentBreakpoint === 'xl';

  // e.g. md = 900 -> down.md = 0 - 899
  const down = {
    xs: false,
    sm: isXs,
    md: isXs || isSm,
    lg: isXs || isSm || isMd,
    xl: isXs || isSm || isMd || isLg,
  };

  // e.g. md = 900, lg = 1200 -> only.md = 600 - 899
  const only = {
    xs: isXs,
    sm: isSm,
    md: isMd,
    lg: isLg,
    xl: isXl,
  };

  // e.g. md = 900 -> up.md = 900 - ∞
  const up = {
    xs: isXs || isSm || isMd || isLg || isXl,
    sm: isSm || isMd || isLg || isXl,
    md: isMd || isLg || isXl,
    lg: isLg || isXl,
    xl: isXl,
  };

  // equivalent to isXS/etc in `useScreenBreakpoints`
  const is = {
    xs: down.sm,
    sm: down.md,
    md: down.lg,
    lg: down.xl,
    xl: up.lg,
  };

  // accepts an object like { xs: 0, md: 1 } and processes it using the dynamic breakpoints to return a single value
  // - if possible use bx with an explicit prop on a component instead of inside sx
  //     - ※\(^o^)/※     <Box padding={bx({ xs: 5, sm: 10 })}>
  //     - (╯°□°)╯︵ ┻━┻   <Box sx={{ padding: bx({ xs: 5, sm: 10 }) }}>
  // - bx tries to infer typing, but there are some limitations:
  //     - you can't mix types, like bx({ xs: 10, sm: '20px' })
  //        - you can fix this like bx<string | number>({ xs: 10, sm: '20px' })
  //     - string unions will widen to a regular string type, so type suggestions will break in vscode
  //        - you can fix this like bx({ xs: 'row', sm: 'column' } as const), now suggestions will work
  // - the breakpoint logic has been inferred from the mui example of how sx breakpoints work:
  //     https://mui.com/system/basics/#the-sx-prop
  const bx: Bx = (input: BpInput<any>) => {
    const bV = (bp: Breakpoint) => {
      if (up[bp]) {
        return input[bp];
      }

      return undefined;
    };

    return bV('xl') ?? bV('lg') ?? bV('md') ?? bV('sm') ?? input.xs;
  };

  return {
    contentBreakpoint,
    down,
    only,
    up,
    is,
    bx,
    isMobile: down.sm,
    isSmallContent: down.md,
    isMediumContent: up.sm,
    isLargeContent: up.md,
    isDesktopContent: up.lg,
  };
};

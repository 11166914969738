import React from 'react';

import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

type Props = {
  asset: Asset;
};

const SingleCategoryBuyPrice: React.FC<Props> = observer(({ asset }) => {
  const { getRate, loading } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  return (
    <Numeric size='small' className='text-right' loading={loading}>
      {formatCurrency(getRate(asset).askPrice, baseAsset)}
    </Numeric>
  );
});

export { SingleCategoryBuyPrice };

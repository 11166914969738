import React from 'react';

import { Button, ButtonProps } from '@swyftx/aviary/atoms/Button';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  active: boolean;
} & ButtonProps;

// TODO this is used in the Assets List table but this component will be replaced by the new design system once it has been built
const TabButton: React.FC<Props> = ({ active, children, className, ...rest }) => (
  <Button
    className={cn('whitespace-nowrap', active ? 'font-medium' : 'font-normal', className)}
    color={active ? 'primary' : 'subtle'}
    {...rest}
  >
    {children}
  </Button>
);

export { TabButton };

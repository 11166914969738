import { useEffect, useState } from 'react';

import { types } from '@swyftx/marketing-utils';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

export type BreakdownItem = {
  value: Big;
  amount: Big;
  asset: Asset;
};

const useRewardsBreakdownData = (assetRewards: types.UserAssetReward[], baseAsset?: Asset) => {
  const [breakdownItems, setBreakdownItems] = useState<BreakdownItem[]>([]);
  const [totalValue, setTotalValue] = useState(Big(0));
  const { getRate, loading } = RatesStore.useRatesStore;

  useEffect(() => {
    if (!baseAsset || loading) return;

    let newTotalValue = Big(0);

    const newBreakdownItems = assetRewards
      .reduce((acc: BreakdownItem[], reward: types.UserAssetReward) => {
        const { redemptions, rewardAssetId } = reward;

        if (redemptions?.length > 0) {
          const { rewardAmount } = redemptions[0];
          const asset = rewardAssetId ? assetService.getAsset(rewardAssetId) : undefined;

          if (rewardAmount && asset) {
            const rate = getRate(asset).midPrice;
            const value = Big(rate).times(rewardAmount);

            newTotalValue = newTotalValue.plus(value);
            const index = acc.findIndex((b) => b.asset.id === rewardAssetId);

            if (index > -1) {
              acc[index].amount = acc[index].amount.plus(rewardAmount);
              acc[index].value = acc[index].value.plus(value);
            } else {
              acc.push({ asset, value, amount: Big(rewardAmount) });
            }
          }
        }

        return acc;
      }, [])
      .sort((a, b) => b.value.toNumber() - a.value.toNumber());

    setBreakdownItems(newBreakdownItems);
    setTotalValue(newTotalValue);
  }, [assetRewards, baseAsset, getRate, loading]);

  return {
    breakdownItems,
    totalValue,
  };
};

export { useRewardsBreakdownData };

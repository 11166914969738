import React, { useCallback, useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List, ListItem } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown, ArrowChevronUp } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as Popover from '@radix-ui/react-popover';

import { useTradingViewSimpleResolution } from './useTradingViewSimpleResolution';
import { TradingViewContext } from '../../../context/TradingView.context';
import { useTradingViewAnalytics } from '../../../hooks';
import { TradingViewHeaderVariant } from '../../../types';

type Props = {
  variant?: TradingViewHeaderVariant;
};

const TradingViewWidgetHeaderSimpleResolution: React.FC<Props> = ({ variant = 'full' }) => {
  const { primary, secondary } = useContext(TradingViewContext);
  const { items, selectedItem, setSelectedSimpleResolution } = useTradingViewSimpleResolution();
  const [open, setOpen] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { changedChartTimeframe } = useTradingViewAnalytics({ primary, secondary });

  const handleResolutionChange = useCallback(
    (value: string) => {
      changedChartTimeframe('Simple Charts', value);
      setSelectedSimpleResolution(value);
    },
    [changedChartTimeframe, setSelectedSimpleResolution],
  );

  return (
    <>
      {open && isXs && (
        <Modal position='bottom' open={open} onOpenChange={(o) => !o && setOpen(o || false)} title='Chart timeframe'>
          <List className='px-12'>
            {items.map((item) => {
              const selected = item.value === selectedItem?.value;

              return (
                <ListItem
                  key={item.value}
                  className={cn(
                    'px-8 py-4',
                    selected ? 'bg-color-background-surface-selected text-color-text-selected' : '',
                  )}
                  onClick={() => {
                    handleResolutionChange(item.value);
                    setOpen(false);
                  }}
                >
                  <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                    <Body size='small' color='primary'>
                      {item.text}
                    </Body>
                  </FlexLayout>
                </ListItem>
              );
            })}
          </List>
        </Modal>
      )}

      <FlexLayout spacing={8} alignItems='center'>
        <Body size='small' weight='emphasis'>
          Time
        </Body>
        {items.map((item) => {
          if (item.hidden) return null;

          const selected = item.value === selectedItem?.value;

          if (variant === 'compact' && !selected) return null;

          return (
            <Button
              key={item.value}
              className={cn(
                'rounded-full h-24 w-24',
                selected ? 'bg-color-background-surface-selected text-color-text-selected' : '',
              )}
              size='sm'
              variant='ghost'
              onClick={() => handleResolutionChange(item.value)}
            >
              {item.text}
            </Button>
          );
        })}

        {variant === 'compact' && (
          <Popover.Root open={open} onOpenChange={(o) => !o && setOpen(o)} modal>
            <Popover.Trigger asChild>
              <Button
                variant={open ? 'filled' : 'ghost'}
                size='sm'
                layout='icon'
                trailingIcon={open ? <ArrowChevronUp /> : <ArrowChevronDown />}
                onClick={() => setOpen(true)}
              />
            </Popover.Trigger>
            {!isXs && (
              <Popover.Portal>
                <Popover.Content asChild sideOffset={16} align='start'>
                  <Card className='h-[19rem] w-[15rem] p-8 pr-8'>
                    <List>
                      {items.map((item) => {
                        const selected = item.value === selectedItem?.value;

                        return (
                          <ListItem
                            key={item.value}
                            className={cn(
                              'px-8 py-4',
                              selected ? 'bg-color-background-surface-selected text-color-text-selected' : '',
                            )}
                            onClick={() => {
                              handleResolutionChange(item.value);
                              setOpen(false);
                            }}
                          >
                            <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
                              <Body size='small' color='primary'>
                                {item.text}
                              </Body>
                            </FlexLayout>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Card>
                </Popover.Content>
              </Popover.Portal>
            )}
          </Popover.Root>
        )}
      </FlexLayout>
    </>
  );
};

export { TradingViewWidgetHeaderSimpleResolution };

import { Release, ReleaseVersion } from '../whats-new.type';

const releaseFour: Release = {
  version: ReleaseVersion.Four,
  features: [
    {
      imgSrc: '/assets/images/releases/3/feature.png',
      title: 'Love Swyftx? Tell your friends & get rewarded',
      descriptions: [
        'Introducing a brand new program for you and your mates',
        'Receive $10 AUD of Bitcoin each when they sign up & trade',
        `Get your unique code anytime via the new 'reward' icon in the header`,
      ],
    },
  ],
};

export { releaseFour };

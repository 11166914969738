import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ExternalLink: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.32422 5.11035C7.91001 5.11035 7.57422 5.44614 7.57422 5.86035C7.57422 6.27457 7.91001 6.61035 8.32422 6.61035H16.3289L5.37592 17.5633C5.08303 17.8562 5.08303 18.3311 5.37592 18.624C5.66881 18.9169 6.14369 18.9169 6.43658 18.624L17.3411 7.71942V15.6273C17.3411 16.0415 17.6769 16.3773 18.0911 16.3773C18.5053 16.3773 18.8411 16.0415 18.8411 15.6273V5.96894C18.8447 5.92612 18.8446 5.88306 18.8409 5.84025C18.8302 5.43533 18.4986 5.11035 18.0911 5.11035H8.32422Z'
      fill='currentColor'
    />
  </svg>
);

export { ExternalLink };

import React, { useEffect } from 'react';

import { GreenIdStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';

import { IdVerificationError } from './IdVerificationError';
import { IdVerificationInProgress } from './IdVerificationInProgress';
import { IdVerificationInReview } from './IdVerificationInReview';
import { IdVerificationListOfForms } from './IdVerificationListOfForms';
import { OnboardingStepContainer } from '../OnboardingStepContainer';

const IdVerificationStep: React.FC = () => {
  const onboardingService = useOnboardingService();

  const isLoadingGreenID = useOnboardingSelector((state) => state.matches('idVerification.setupGreenID'));
  const isReady = useOnboardingSelector((state) => state.matches('idVerification.verificationStart'));
  const isInProgress = useOnboardingSelector((state) => state.matches('idVerification.verificationInProgress'));
  const isInReview = useOnboardingSelector((state) => state.matches('idVerification.verificationInReview'));
  const hasErrored = useOnboardingSelector((state) => state.matches('idVerification.verificationError'));

  const { userProfile } = UserStore.useUserStore;

  const greenIdStatus = userProfile?.verification?.greenid?.status;

  useEffect(() => {
    if (greenIdStatus === GreenIdStatusEnum.FAILED_DUPLICATE || greenIdStatus === GreenIdStatusEnum.FAILED) {
      onboardingService.send('VERIFICATION_ERROR');
    }
  }, [greenIdStatus, onboardingService]);

  const renderContent = () => {
    if (isLoadingGreenID || isReady) {
      return <IdVerificationListOfForms />;
    }

    if (isInProgress) {
      return <IdVerificationInProgress />;
    }

    if (isInReview) {
      return <IdVerificationInReview />;
    }

    if (hasErrored) {
      return <IdVerificationError />;
    }

    return <IdVerificationError />;
  };

  return <OnboardingStepContainer submitDisabled={isLoadingGreenID}>{renderContent()}</OnboardingStepContainer>;
};

export { IdVerificationStep };

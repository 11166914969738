import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { types } from '@swyftx/marketing-utils';
import { Badge, Button, Divider, Stack } from '@swyftx/react-web-design-system';

import { RewardType } from '@routes/Rewards/types';

import { rewardTabs } from './RewardTabs.data';

type Props = {
  rewardType: RewardType;
  setRewardType: React.Dispatch<React.SetStateAction<RewardType>>;
  rewards: { [key in RewardType]: types.UserAssetReward[] };
};

const RewardTabs: React.FC<Props> = ({ rewardType, setRewardType, rewards }) => {
  const { t } = useTranslation('rewards', { keyPrefix: 'tabs' });

  const tabs = useMemo(
    () => rewardTabs(rewards.available, rewards.history, rewardType),
    [rewardType, rewards.available, rewards.history],
  );

  return (
    <Stack spacing={0} width='100%' overflow='auto'>
      <Stack direction='row' alignItems='center' spacing={2} padding={2}>
        {tabs.map((tab) => (
          <Button
            key={tab.label}
            variant='text'
            color='inherit'
            icon
            onClick={() => setRewardType(tab.rewardType)}
            startIcon={tab.icon}
            endIcon={
              tab.totalRewards ? (
                <Badge badgeContent={tab.totalRewards} color='primary' variant='standard' sx={{ marginLeft: 1 }} />
              ) : undefined
            }
            sx={{
              bgcolor: rewardType === tab.rewardType ? 'action.hover' : '',
              paddingRight: tab.totalRewards ? 3 : 1,
              whiteSpace: 'nowrap',
            }}
          >
            {t(tab.label as any)}
          </Button>
        ))}
      </Stack>
      <Divider />
    </Stack>
  );
};

export { RewardTabs };

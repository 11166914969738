import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Deposit, Withdraw } from '@swyftx/aviary/icons/outlined';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { formatCurrency } from '@swyftx/currency-util';

import { PriceAlert } from '@shared/api/@types/alerts';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';

interface Props {
  isTriggeredTab: boolean;
  alert: PriceAlert;
  rate: Big;
}

export const PriceAlertPrice: React.FC<Props> = (props) => {
  const { isTriggeredTab, alert, rate } = props;
  const baseAsset = assetService.getAsset(alert.primary)!;
  const priceAlertDifference = Big(alert.price).minus(rate);
  return (
    <FlexLayout direction='row' className='flex items-center gap-16 sm:gap-32'>
      {priceAlertDifference.gte(0) && (
        <div className='text-color-text-success'>
          <Withdraw className='mt-4 h-16 w-16' />
        </div>
      )}
      {priceAlertDifference.lt(0) && (
        <div className='text-color-text-error'>
          <Deposit className='mb-4 h-16 w-16' />
        </div>
      )}
      <FlexLayout direction='row' spacing={4} className='flex items-center'>
        <NumericDataItem
          size='small'
          alignItems='start'
          renderIcons
          color={isTriggeredTab ? 'primary' : Big(alert.price).minus(rate).gt(0) ? 'success' : 'error'}
          data={priceAlertDifference.toString()}
        >
          {formatCurrency(alert.price.toString())}
        </NumericDataItem>
        <Numeric color='secondary' size='small'>
          {baseAsset.code}
        </Numeric>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { NavigationTab } from '@global-components/TabNavigationBar/TabNavigationBar.types';

import { cn } from '@shared/utils/lib/ui';

import { EnhancedTabs } from '../EnhancedTabs';

interface Props {
  selectedTabId: string;
  onTabChange: (id: string) => void;
  tabs: NavigationTab[];
}

export const ProfileMenuButtons: React.FC<Props> = (props) => {
  const { selectedTabId, onTabChange, tabs } = props;

  const aviaryTabsConverted = useMemo(
    () =>
      tabs.map((tab) => ({
        value: tab.id,
        title: tab.label,
      })),
    [tabs],
  );

  return (
    <FlexLayout direction='column'>
      <FlexLayout direction='row' className='mb-16 flex w-full overflow-x-auto py-4 @sm:hidden'>
        <EnhancedTabs tabs={aviaryTabsConverted} onChange={onTabChange} value={selectedTabId} />
      </FlexLayout>
      <FlexLayout direction='column' spacing={8} className='hidden @sm:flex'>
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            variant='ghost'
            className={cn(
              'py-12',
              tab.id === selectedTabId
                ? 'bg-color-text-primary text-color-text-inverse hover:bg-color-text-primary'
                : '',
            )}
            id={tab.id}
            data-spotlightelementid={tab.id}
            textJustify='start'
            onClick={() => onTabChange(tab.id)}
            selected={tab.id === selectedTabId}
          >
            <Body
              size='small'
              color={tab.id === selectedTabId ? 'inverse' : 'secondary'}
              weight={tab.id === selectedTabId ? 'emphasis' : 'none'}
            >
              {tab.label}
            </Body>
          </Button>
        ))}
      </FlexLayout>
    </FlexLayout>
  );
};

import { OtcOptInData, OtcOptInForEntityData } from '@shared/api/@types/otc';

import { Methods, request } from '../rest';

const otcEndpoints = {
  otcOptIn: ({ data }: { data: OtcOptInData }) =>
    request({
      path: '/otc/optIn/',
      method: Methods.POST,
      auth: true,
      data,
    }),
  otcOptInForEntity: ({ data }: { data: OtcOptInForEntityData }) =>
    request({
      path: '/otc/optInForEntity/',
      method: Methods.POST,
      auth: true,
      data,
    }),
};

export default otcEndpoints;

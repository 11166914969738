/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Skeleton } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency, FormatCurrencyOptions } from '@shared/utils';

import { observer } from 'mobx-react-lite';

export type SecondaryProps = {
  typographyProps?: NumericProps;
  value?: string;
};

type Props = {
  prefix?: string;
  value?: string | number | Big | undefined;
  currency?: Asset;
  dynamicCurrency?: Asset;
  typographyProps?: NumericProps;
  formatOpts?: FormatCurrencyOptions;
  secondaryText?: SecondaryProps;
  hideRateLoadingState?: boolean;
  formatBalance?: boolean;
  loading?: boolean;
} & ({ currency: Asset | undefined; formatBalance?: boolean } | { formatBalance: true; currency?: Asset });

const FormattedText: React.FC<Props> = observer(
  ({
    prefix,
    value = 0,
    currency,
    dynamicCurrency,
    typographyProps,
    formatOpts,
    secondaryText,
    hideRateLoadingState,
    formatBalance = false,
    loading = false,
  }) => {
    const { loading: ratesLoading, rates } = RatesStore.useRatesStore;

    const formattedValue = useMemo(
      () => formatCurrency(value || '0', currency, formatOpts, dynamicCurrency?.id, formatBalance),
      [value, currency, formatOpts, dynamicCurrency, formatBalance],
    );

    if (
      ((loading || ratesLoading) && !hideRateLoadingState) ||
      (currency && currency.code !== 'TAUD' && !hideRateLoadingState && !rates[currency.id])
    ) {
      return <Skeleton variant='text' width={90} height={20} />;
    }

    return (
      <FlexLayout alignItems='center' spacing={4} className={typographyProps?.className}>
        <FlexLayout alignItems='center' spacing={0}>
          {prefix && <Numeric {...typographyProps}>{prefix}</Numeric>}
          <Numeric {...typographyProps}>{formattedValue}</Numeric>
        </FlexLayout>
        {secondaryText && <Numeric {...secondaryText.typographyProps}>{secondaryText.value}</Numeric>}
      </FlexLayout>
    );
  },
);

export { FormattedText };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Deposit: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M7.18311 17.8774H15.0898C15.5041 17.8774 15.8398 18.2132 15.8398 18.6274C15.8398 19.0416 15.5041 19.3774 15.0898 19.3774H5.32294C4.90872 19.3774 4.57294 19.0416 4.57294 18.6274V8.86052C4.57294 8.44631 4.90872 8.11052 5.32294 8.11052C5.73715 8.11052 6.07294 8.44631 6.07294 8.86052V16.8663L17.5634 5.3758C17.8563 5.0829 18.3312 5.0829 18.6241 5.3758C18.917 5.66869 18.917 6.14356 18.6241 6.43646L7.18311 17.8774Z'
      fill='currentColor'
    />
  </svg>
);

export { Deposit };

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyLeft, Cross } from '@swyftx/aviary/icons/outlined';

type ModalState = 'edit' | 'review';

type Props = {
  open?: boolean;
  updating?: boolean;
  position: 'center' | 'bottom';
  validEditOrder: {
    valid: boolean;
    tooltip: string;
  };
  dirty?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  resetValues: () => void;
  parseTemplate: () => void;
  updateRecurringOrder: () => Promise<void>;
};

const useEditAutoInvestModal = ({
  open,
  updating,
  validEditOrder,
  setOpen,
  resetValues,
  position,
  dirty,
  parseTemplate,
  updateRecurringOrder,
}: Props) => {
  const [state, setState] = useState<ModalState>('edit');

  useEffect(() => {
    if (open) {
      resetValues();
      parseTemplate();
    } else {
      setState('edit');
    }
  }, [open, parseTemplate, resetValues]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    setState('edit');
  }, []);

  const isBottomSheet = useMemo(() => position === 'bottom', [position]);

  const footer = useMemo(() => {
    if (state === 'edit') {
      return (
        <FlexLayout
          alignItems='center'
          justifyContent='end'
          className='p-24'
          spacing={12}
          direction={isBottomSheet ? 'column' : 'row'}
        >
          <Button
            variant='outlined'
            onClick={handleOnClose}
            className={isBottomSheet ? 'order-2 w-full' : 'order-1'}
            size={isBottomSheet ? 'lg' : 'md'}
          >
            Discard
          </Button>
          <Button
            variant='filled'
            color='primary'
            loading={updating}
            onClick={() => setState('review')}
            disabled={!validEditOrder.valid || !dirty}
            tooltip={validEditOrder.tooltip}
            className={isBottomSheet ? 'order-1 w-full' : 'order-2'}
            size={isBottomSheet ? 'lg' : 'md'}
          >
            Review changes
          </Button>
        </FlexLayout>
      );
    } else if (state === 'review') {
      return (
        <FlexLayout
          alignItems='center'
          justifyContent='end'
          className='p-24'
          spacing={12}
          direction={isBottomSheet ? 'column' : 'row'}
        >
          <Button
            variant='outlined'
            onClick={handleOnClose}
            className={isBottomSheet ? 'order-2 w-full' : 'order-1'}
            size={isBottomSheet ? 'lg' : 'md'}
          >
            Discard
          </Button>
          <Button
            variant='filled'
            color='primary'
            loading={updating}
            onClick={updateRecurringOrder}
            className={isBottomSheet ? 'order-1 w-full' : 'order-2'}
            size={isBottomSheet ? 'lg' : 'md'}
          >
            Update order
          </Button>
        </FlexLayout>
      );
    }

    return null;
  }, [setOpen, state, updateRecurringOrder, updating, validEditOrder.tooltip, validEditOrder.valid, isBottomSheet]);

  const header = useMemo(() => {
    if (state === 'edit') {
      return (
        <FlexLayout alignItems='center' justifyContent='space-between' className='p-24'>
          <Heading size='h4'>Edit order</Heading>
          <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={handleOnClose} />
        </FlexLayout>
      );
    } else if (state === 'review') {
      return (
        <FlexLayout alignItems='center' justifyContent='space-between' className='p-24'>
          <FlexLayout alignItems='center' justifyContent='start' spacing={16}>
            <Button
              layout='icon'
              variant='outlined'
              leadingIcon={<ArrowLineOnlyLeft />}
              onClick={() => setState('edit')}
            />
            <Heading size='h4'>Review changes</Heading>
          </FlexLayout>

          <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={handleOnClose} />
        </FlexLayout>
      );
    }

    return null;
  }, [setOpen, state]);

  return {
    state,
    open,
    setOpen,
    footer,
    header,
  };
};

export { useEditAutoInvestModal };

import {
  DCASimulation,
  DeleteRecurringOrderTemplateParams,
  PostRecurringOrderData,
  PostRecurringOrderResponse,
  RecurringOrder,
  RecurringOrderQuery,
  RecurringOrderStats,
  RecurringOrderStatsQuery,
  UpdateRecurringOrderData,
} from '../../@types/recurringOrder';
import { Methods, request } from '../rest';

const recurringOrderEndpoints = {
  postRecurringOrderTemplate: ({ data }: { data: PostRecurringOrderData }): PostRecurringOrderResponse =>
    request({ path: '/templates/createUserTemplate/', method: Methods.POST, data, auth: true, hideErrorToast: true }),

  deleteRecurringOrderTemplate: ({ params }: { params: DeleteRecurringOrderTemplateParams }) =>
    request({ path: '/templates/:templateUuid/', method: Methods.DELETE, params, auth: true }),

  updateRecurringOrderTemplate: ({ data }: { data: UpdateRecurringOrderData }): PostRecurringOrderResponse =>
    request({ path: '/templates/updateUserTemplate/', method: Methods.POST, data, auth: true }),

  getRecurringOrders: ({ query }: { query: RecurringOrderQuery }): RecurringOrder[] =>
    request({
      path: '/templates/getUserTemplates/',
      method: Methods.GET,
      auth: true,
      cache: undefined,
      query,
    }),

  getRecurringOrderStats: ({ query }: { query: RecurringOrderStatsQuery }): RecurringOrderStats[] =>
    request({ path: '/templates/getUserTemplateStats/', method: Methods.GET, query, auth: true }),

  // TODO: Add path and update call once endpoint exists
  getDCASimulations: (): DCASimulation[] => request({ path: '', method: Methods.GET, auth: true }),
};

export default recurringOrderEndpoints;

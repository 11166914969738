import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { CloseUniversalTrade } from '@global-components/CloseUniversalTrade/CloseUniversalTrade';
import { NoResults } from '@global-components/NoResults/NoResults';

import { Asset, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UIStore, UserStore } from '@shared/store';
import { TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { AssetFilter, TradeSideFilter, useAssets } from '@hooks/Assets/useAssets';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

import {
  TradeAssetSelectorActionButtons,
  TradeAssetSelectorBundles,
  TradeAssetSelectorEmpty,
  TradeAssetSelectorFilter,
  TradeAssetSelectorList,
  TradeAssetSelectorSearch,
} from './components';

type Props = {
  title: string;
  side: TradeSide;
  dismissible?: boolean;
};

const TradeAssetSelector: React.FC<Props> = observer(({ title, side, dismissible }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradePanel' });
  const { mainContentHeight } = UIStore.useUIStore;
  const { balances } = UserStore.useUserStore;
  const { isMobile } = useContentBreakpoint();
  const countryAsset = useCountryAsset();
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<AssetFilter>(
    side === TradeSide.From ? AssetFilter.Owned : AssetFilter.All,
  );

  const hasBalances = useMemo(
    () => Object.values(balances as UserBalance[]).some((balance) => Big(balance.availableBalance).gt(0)),
    [balances],
  );

  const { getMaxAssets, getTradeAssetIds } = useUniversalTradeUtilityStore();

  const sortAssets = useCallback((a: Asset, b: Asset) => a.rank - b.rank, []);

  const assets: any[] = useAssets({
    filter: selectedFilter,
    filterOutSmallBalances: true,
    searchString: filterSearch,
    tradeSide: side === TradeSide.From ? TradeSideFilter.Sell : TradeSideFilter.Buy,
    assetIds: getTradeAssetIds(side, countryAsset),
    sort: sortAssets,
  });

  const multiSelect = getMaxAssets(side) > 1;

  // On the new universal trade page we need to make the content height a bit smaller as the trade panel is
  // no longer full screen. Ideally this all gets removed and converted into Aviary
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const universalTradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const isXs = useTailwindBreakpoint('xs');
  const isOnTradePage = location.pathname.includes(NavigationURLs.UniversalTrade);
  const heightToSubtractForTradePage = useMemo(() => {
    if (isXs) return 280;
    if (universalTradePageEnabled && isOnTradePage) return 362;
    return 323;
  }, [isOnTradePage, isXs, universalTradePageEnabled]);
  const contentHeight = mainContentHeight - heightToSubtractForTradePage;

  return (
    <FlexLayout direction='column' className='h-full'>
      <FlexLayout direction='column' spacing={16} className='p-16 pb-0'>
        <FlexLayout direction='row' className='w-full items-center justify-between py-4'>
          <Heading size='h5'>{title}</Heading>
          {dismissible && <CloseUniversalTrade />}
        </FlexLayout>
        <TradeAssetSelectorSearch onChange={setFilterSearch} selectedFilter={selectedFilter} focused={!isMobile} />
        <TradeAssetSelectorFilter side={side} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />

        <div style={{ height: contentHeight }}>
          {hasBalances && assets.length === 0 && (
            <NoResults title={t('empty.title')} subTitle={t('empty.subTitle')} showImage />
          )}
          {!hasBalances && <TradeAssetSelectorEmpty filter={selectedFilter} filterSearch={filterSearch} />}
          {hasBalances && assets.length > 0 && selectedFilter === AssetFilter.Bundles ? (
            <TradeAssetSelectorBundles />
          ) : (
            <TradeAssetSelectorList
              assets={assets}
              selectedFilter={selectedFilter}
              side={side}
              multiSelect={multiSelect}
              height={contentHeight}
            />
          )}
        </div>
      </FlexLayout>
      <FlexLayout direction='column'>
        <Separator />
        <TradeAssetSelectorActionButtons multiSelect={multiSelect} side={side} />
      </FlexLayout>
    </FlexLayout>
  );
});

TradeAssetSelector.displayName = 'TradeAssetSelector';

export { TradeAssetSelector };

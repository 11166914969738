import { useCategories } from 'src/lib/categories/hooks';

export const singleCategoryPageRegex = /^assets\/categories\/(-?\d+)$/;
export const assetCategoryFilterRegex = /^assets\/category\/(-?\d+)$/;

const useCustomCategoryRouteNames = () => {
  const { categories } = useCategories();

  const getCategoryName = (route: string, regex: RegExp) => {
    const match = route.match(regex);

    if (match) {
      const categoryId = parseInt(match[1]);
      if (categoryId === -1) return 'Favourite Assets';
      return categories.find((c) => c.id === categoryId)?.name;
    }

    return '';
  };

  return {
    getCategoryName,
  };
};

export { useCustomCategoryRouteNames };

import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardContent } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { AtLeastBreakpoints } from '@swyftx/aviary/hooks/breakpoints';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronLeft, ArrowChevronRight, Refresh } from '@swyftx/aviary/icons/outlined';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { NewsCard } from '@swyftx/aviary/molecules/NewsCard';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset, AssetType, NewsItem } from '@shared/api';
import { relativeTimeFromDates } from '@shared/utils';

import { useNews } from '@hooks/News/useNews';

import * as Sentry from '@sentry/react';

import { NewsTab, NewsType } from './NewsWidgetCard.types';
import { NewsWidgetCardEmpty } from './NewsWidgetCardEmpty';

type Props = {
  title: string;
  assets: Asset[];
  defaultNewsType?: NewsType;
  newsType?: NewsType;
  setNewsType?: (newsType: NewsType) => void;
  tabs?: NewsTab[];
  onNewsClick?: (newsItem: NewsItem) => void;
  className?: string;
  atLeast?: AtLeastBreakpoints;
};

const getNumArray = (num?: number) => {
  const arr: number[] = [];
  if (!num) return arr;

  for (let i = 0; i <= num - 1; i += 1) arr.push(i);
  return arr;
};

const widthMapping = {
  4: '@md:w-[calc(25%-16px)]',
  3: '@md:w-[calc(33.33%-16px)]',
};

const NewsWidgetCard: React.FC<Props> = ({
  onNewsClick,
  title,
  assets,
  tabs,
  newsType,
  setNewsType,
  atLeast,
  className,
}) => {
  const pageSize = useMemo(() => {
    if (!atLeast) return 3;

    if (atLeast.lg) return 4;

    return 3;
  }, [atLeast]);

  const assetsToFetch = useMemo(() => {
    switch (newsType) {
      case 'all':
        return [];
      case 'mine':
      case 'asset':
        return assets.filter((a) => a.assetType === AssetType.Crypto);
      default:
        return [];
    }
  }, [assets, newsType]);

  const isXs = useTailwindBreakpoint('xs');

  const {
    newsItems,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    loading,
    changingNewsType,
    setChangingNewsType,
    refreshNewsItems,
  } = useNews({
    assets: assetsToFetch,
    pageSize,
    returnAll: !atLeast?.md,
  });

  const openNewsArticle = (newsItem: NewsItem) => {
    window.open(newsItem.newsUrl, '_blank', 'noopener,noreferrer');
    if (onNewsClick) onNewsClick(newsItem);
  };

  const onChangeNewsType = (newValue: NewsType) => {
    setChangingNewsType(true);
    if (setNewsType) setNewsType(newValue);
  };

  return (
    <ElementContainer
      title={title}
      cardWrapper={!atLeast?.md}
      className={className}
      actions={
        <FlexLayout alignItems='center' justifyContent={tabs ? 'space-between' : 'end'} className='w-full'>
          {tabs && (
            <EnhancedTabs<NewsType>
              variant='child'
              size={atLeast?.md ? 'sm' : 'md'}
              tabs={tabs}
              onChange={onChangeNewsType}
              value={newsType}
            />
          )}
          {!atLeast?.md ? (
            <FlexLayout spacing={4} alignItems='center' justifyContent='end'>
              <Button
                leadingIcon={<Refresh />}
                size='md'
                variant='outlined'
                textAlign='center'
                layout={isXs ? 'icon' : 'default'}
                onClick={refreshNewsItems}
              >
                Refresh
              </Button>
            </FlexLayout>
          ) : (
            <FlexLayout spacing={4}>
              <Button leadingIcon={<Refresh />} size='sm' variant='outlined' onClick={refreshNewsItems}>
                Refresh
              </Button>
              <Button variant='outlined' size='sm' layout='icon' disabled={!hasPrevPage} onClick={prevPage}>
                <ArrowChevronLeft className='h-16 w-16' />
              </Button>
              <Button variant='outlined' size='sm' layout='icon' disabled={!hasNextPage} onClick={nextPage}>
                <ArrowChevronRight className='h-16 w-16' />
              </Button>
            </FlexLayout>
          )}
        </FlexLayout>
      }
    >
      <FlexLayout direction={!atLeast?.md ? 'column' : 'row'} spacing={!atLeast?.md ? 12 : 24}>
        {!newsItems.length && !loading && <NewsWidgetCardEmpty newsType={newsType} showBorder={atLeast?.md} />}
        {pageSize &&
          !changingNewsType &&
          newsItems.map((newsItem) => (
            <Card
              key={newsItem.id}
              border={atLeast?.md}
              shadow={atLeast?.md}
              className={`cursor-pointer hover:bg-color-background-surface-hover @xs:h-[268px] @xs:w-full @sm:h-[156px] @md:h-[384px] ${widthMapping[pageSize]}`}
              onClick={() => openNewsArticle(newsItem)}
            >
              <CardContent className='h-full @xs:p-8 @md:p-24'>
                <NewsCard
                  id={newsItem.id}
                  layout={!atLeast?.md && atLeast?.sm ? 'compact' : 'full'}
                  variant={!atLeast?.md ? 'standard' : 'card'}
                  imgSrc={newsItem.imageHero}
                  title={newsItem.title}
                  published={relativeTimeFromDates(new Date(newsItem.publishedAt))}
                  excerpt={newsItem.excerpt}
                  source={newsItem.sourceName}
                  loading={atLeast?.md && loading}
                  onClick={() => window.open(newsItem.newsUrl, '_blank', 'noopener,noreferrer')}
                  icons={newsItem.assets.map((a) => (
                    <AssetIcon key={a} asset={a} size={16} />
                  ))}
                />
              </CardContent>
            </Card>
          ))}
        {loading &&
          (!atLeast?.md || (!!atLeast?.md && changingNewsType)) &&
          getNumArray(pageSize).map((value) => (
            <Card
              key={value}
              border={!atLeast?.md && atLeast?.sm}
              shadow={!atLeast?.md && atLeast?.sm}
              className={`cursor-pointer hover:bg-color-background-surface-hover @xs:h-[268px] @xs:w-full @sm:h-[156px] @md:h-[384px] ${widthMapping[pageSize]}`}
            >
              <CardContent className='h-full @xs:p-8 @md:p-24'>
                <NewsCard
                  id={value}
                  layout={!atLeast?.md && atLeast?.sm ? 'compact' : 'full'}
                  variant={!atLeast?.md ? 'standard' : 'card'}
                  loading={loading}
                />
              </CardContent>
            </Card>
          ))}
        {!atLeast?.md && newsItems.length > 0 && hasNextPage && (
          <FlexLayout alignItems='center' justifyContent='center' className='mb-12 w-full'>
            <Button className='w-full items-center' disabled={!hasNextPage} color='subtle' onClick={nextPage}>
              Show More
            </Button>
          </FlexLayout>
        )}
      </FlexLayout>
    </ElementContainer>
  );
};

export const ProfiledNewsWidgetCard = Sentry.withProfiler(NewsWidgetCard);

export { NewsWidgetCard };

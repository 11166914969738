import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';

import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api } from '@shared/api';
import { TransactionStatus, VerificationStatus } from '@shared/api/@types/verification';
import { UIStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import * as Sentry from '@sentry/react';
import { useIntercom } from 'react-use-intercom';

interface ErrorHandlerProps {
  setVerificationStep: (step: VerificationStep) => void;
  showNewMessages: (message: string) => void;
  t: (key: string) => string;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ setVerificationStep, showNewMessages, t }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'colum',
      alignItems: 'center',
      gap: '1rem',
    }}
  >
    <Notification title={t('jumio.labels.error')} severity='destructive' />
    <Button
      size='lg'
      onClick={() => {
        setVerificationStep(VerificationStep.None);
        showNewMessages('KYC0 Call');
      }}
    >
      {t('jumio.buttonLabels.contactSupport')}
    </Button>
  </Box>
);

type Props = {
  onClose: (skipVerify?: boolean) => void;
};

export const JumioWizardVerifyStep: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('profile.verification');
  const { isXs } = useScreenBreakpoints();
  const { jumioTransaction, setJumioTransaction, setVerificationStep } = useContext(ProfileVerificationContext);
  const { showNewMessages } = useIntercom();
  const { addMessageBox } = UIStore.useUIStore;
  const [iFrameError, setIFrameError] = useState<boolean>(false);

  React.useEffect(() => {
    if (!jumioTransaction) return;

    const updateStatus = async (status: TransactionStatus) => {
      try {
        await api.endpoints.updateJumioTransactionStatus({
          params: { transactionReference: jumioTransaction.transactionReference },
          data: { status },
        });

        setJumioTransaction({
          ...jumioTransaction,
          status,
        });
      } catch (e) {
        // swallow error
        Sentry.captureException(e);
      }
    };

    const receiveMessage = (event: MessageEvent) => {
      if (typeof event.origin !== 'string' || !event.origin.endsWith('jumio.ai')) return;

      // https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#example-iframe-logging-code
      const data = JSON.parse(event.data);

      if (data.payload.value === 'success') {
        updateStatus(TransactionStatus.PENDING);
        addMessageBox({ content: <SuccessMessageBox title={t('jumio.labels.submitted')} /> });
      } else if (data.payload.value === 'error') {
        setIFrameError(true);
        switch (data.payload.metainfo.code) {
          // https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#after-the-user-journey
          case 9200:
            // auth token missing
            updateStatus(TransactionStatus.FAILED);
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('message', receiveMessage, false);
    return () => window.removeEventListener('message', receiveMessage);
  }, [jumioTransaction]);

  const content = useMemo(() => {
    if (!jumioTransaction) {
      return (
        <Box height='600px' width='100%' display='flex' alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Box>
      );
    }

    if (iFrameError) {
      return <ErrorHandler setVerificationStep={setVerificationStep} showNewMessages={showNewMessages} t={t} />;
    }

    switch (jumioTransaction.result?.status ?? jumioTransaction.status) {
      case TransactionStatus.PENDING:
      case TransactionStatus.DONE:
        return <Notification title={t('jumio.labels.pending')} severity='info' />;

      case TransactionStatus.FAILED:
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
      case VerificationStatus.DENIED_SUSPICIOUS:
        return <ErrorHandler setVerificationStep={setVerificationStep} showNewMessages={showNewMessages} t={t} />;

      default:
        return (
          <Box
            allow='camera;fullscreen;accelerometer;gyroscope;magnetometer'
            src={jumioTransaction.redirectUrl}
            component='iframe'
            minHeight={isXs ? '600px' : '60vh'}
            allowFullScreen
            height='100%'
            width='100%'
          />
        );
    }
  }, [iFrameError, isXs, jumioTransaction, setVerificationStep, showNewMessages, t]);

  return (
    <FlexLayout direction='column' className='PII min-h-[60vh]' spacing={8}>
      {content}
      <FlexLayout direction='row' className='w-full justify-end'>
        <Button variant='outlined' onClick={() => onClose(true)}>
          Back
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import { Image } from '@swyftx/aviary/atoms/Image';
import { Button, Divider, IconButton, Stack, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { ProfileContext } from '@routes/Profile/Profile.context';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const COPIED_TIMEOUT = 5000;

const AffiliateTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const { affiliateInfo, fetchAffiliateInfo } = useContext(ProfileContext);

  const countryAsset = useCountryAsset();
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('dashboard');

  const navigateToAffiliates = () => {
    navigate(NavigationURLs.ProfileAffiliateProgram);
  };

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  useEffect(() => {
    fetchAffiliateInfo();
  }, [fetchAffiliateInfo]);

  return (
    <DashboardBaseTile
      tileName={tile.name}
      title={t('affiliateTile.title')}
      cardSx={{ maxHeight: expanded ? 'auto' : '100%' }}
      headerSx={{ paddingX: 2, paddingTop: 2 }}
      contentSx={{ padding: 0, paddingBottom: '0px !important' }}
      dragProps={dragProps}
      headerBarActionItem={
        <IconButton onClick={() => setExpanded((current) => !current)} sx={{ padding: 0 }}>
          <ExpandMore sx={{ transition: '0.2s', transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
        </IconButton>
      }
    >
      <Stack direction='column' alignItems='flex-start' spacing={2} padding={2}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
          <Image image='affiliates' className='h-[80px] w-[80px]' alt='affiliate' usePalette />
          <Stack alignItems='flex-start'>
            <Typography fontWeight='400' fontSize={14} color='text.secondary' marginLeft={1}>
              {affiliateInfo ? t('affiliateTile.subTitleAffiliate') : t('affiliateTile.subTitleNonAffiliate')}
            </Typography>
            {/* buttons */}
            {!affiliateInfo && <Button onClick={navigateToAffiliates}>Learn more</Button>}
            {affiliateInfo && affiliateInfo.referralLink && (
              <Button onClick={navigateToAffiliates}>My affiliate overview</Button>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Collapse in={expanded}>
        <Stack direction='column' spacing={2} paddingX={2} paddingBottom={2}>
          {/* divider */}
          <Divider sx={{ marginX: '-16px !important' /* subtract card margin */ }} />

          <Stack direction='row' spacing={4} marginTop={2}>
            {/* referrals */}
            <Stack direction='column' spacing={1}>
              <Typography fontWeight='400' fontSize={14} color='text.secondary'>
                {t('affiliateTile.labels.referredUsers')}
              </Typography>

              {/* referred users */}
              <Typography number> {affiliateInfo ? affiliateInfo.referredUsers : 0}</Typography>
            </Stack>

            {/* payouts */}
            <Stack direction='column' spacing={1}>
              <Typography fontWeight='400' fontSize={14} color='text.secondary'>
                {t('affiliateTile.labels.nextPayout')}
              </Typography>

              {/* affiliate next payout */}
              <FormattedText value={affiliateInfo ? affiliateInfo.outstandingBalance : 0} currency={countryAsset} />
            </Stack>
          </Stack>

          {/* manage affiliates */}
          <Button
            variant='text'
            sx={{ width: 'fit-content', padding: '0', ':hover': { backgroundColor: 'transparent' } }}
            onClick={navigateToAffiliates}
          >
            {t('affiliateTile.buttonLabels.manageAffiliate')}
          </Button>
        </Stack>
      </Collapse>
    </DashboardBaseTile>
  );
});

AffiliateTile.displayName = 'affiliateTile';

export { AffiliateTile };

import { useMemo } from 'react';

import { TourGuideData } from './TourGuide.types';

type Props = {
  guides?: TourGuideData[];
  search?: string;
};

const useFilterTourGuides = ({ guides, search }: Props) => {
  const filteredTourGuides = useMemo(() => {
    if (!guides) return [];
    if (!search) return guides;

    return guides.filter(
      (g) =>
        g.name.toLowerCase().includes(search?.toLowerCase()) ||
        g.description.toLowerCase().includes(search?.toLowerCase()),
    );
  }, [guides, search]);

  return filteredTourGuides;
};

export { useFilterTourGuides };

import { useMemo } from 'react';

import { useEntityOnboardingSelector } from '@routes/EntityOnboarding/EntityOnboarding.context';

const companyExtract = {
  title: 'ASIC Company extract. You can purchase one',
  content: (
    <img
      src='assets/images_new/entity_application/company_extract.jpg'
      alt='Company Extract Example'
      className='h-[60vh]'
    />
  ),
};

const asicExtract = {
  title: 'ASIC Company extracts (one per company). These can be purchased',
  content: (
    <img
      src='assets/images_new/entity_application/company_extract.jpg'
      alt='Company Extract Example'
      className='h-[60vh]'
    />
  ),
};

const smsfTrustDeed = {
  title: 'SMSF Trust Deed',
  content: (
    <img src='assets/images_new/entity_application/trust_deed.jpg' alt='Trust Deed Example' className='h-[60vh]' />
  ),
};
const trustDeed = {
  title: 'Trust Deed',
  content: (
    <img src='assets/images_new/entity_application/trust_deed.jpg' alt='Trust Deed Example' className='h-[60vh]' />
  ),
};
const deedOfVariation = {
  title: 'Executed Deed of Variation (if there are changes to structure)',
  content: (
    <img
      src='assets/images_new/entity_application/deed_of_variation.jpg'
      alt='Deed of Variation Example'
      className='h-[60vh]'
    />
  ),
};
const rolloverStatement = {
  title: 'Rollover statement (Optional)',
  content: (
    <img
      src='assets/images_new/entity_application/rollover_statement.jpg'
      alt='Rollover Statement Example'
      className='h-[60vh]'
    />
  ),
};

export const useDocumentSuggestions = () => {
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);
  const entityType = applicationData.type;

  const documentSuggestions = useMemo(() => {
    if (entityType === 'COMPANY') {
      return [companyExtract];
    }
    if (entityType === 'SMSF_INDIVIDUAL') {
      return [smsfTrustDeed];
    }
    if (entityType === 'SMSF_CORPORATE') {
      return [smsfTrustDeed, asicExtract, rolloverStatement];
    }
    if (entityType === 'TRUST_CORPORATE') {
      return [trustDeed, asicExtract, deedOfVariation];
    }
    if (entityType === 'TRUST_INDIVIDUAL') {
      return [trustDeed, deedOfVariation];
    }
  }, [entityType]);

  return { documentSuggestions };
};

/* eslint-disable max-len */
import { Release, ReleaseVersion } from '../whats-new.type';

const releaseThree: Release = {
  version: ReleaseVersion.Three,
  features: [
    {
      imgSrc: '/assets/images/releases/2/feature-1.png',
      title: 'Customise how you view crypto news',
      descriptions: [
        'Curated crypto news based on your trade wallet & favourite list',
        'Thumbnail view to give news more context',
        'Access news from the dashboard & single asset pages',
      ],
    },
  ],
};

export { releaseThree };

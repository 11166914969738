import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';

import * as Popover from '@radix-ui/react-popover';
import { observer } from 'mobx-react-lite';
import { CategoryIcons } from 'src/context/Categories/Categories.icons';
import { useCategory } from 'src/lib/categories/hooks';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AssetCategoriesChip } from './AssetCategoriesChip';

interface Props {
  asset: Asset;
}

export const AssetCategories: React.FC<Props> = observer((props) => {
  const { asset } = props;
  const { getAssetCategories } = useCategory();
  const categories = useMemo(() => getAssetCategories(asset), [asset, getAssetCategories]);
  const [open, setOpen] = useState<boolean>(false);
  const { navigate } = useNavigateRoute();
  const { getAssetsByCategoryId } = useMarkets();

  const onSelectCategory = useCallback(
    (category: Category) => {
      navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: category.id } });
    },
    [navigate],
  );

  if (!categories.length)
    return (
      <FlexLayout direction='row' className='flex-wrap' spacing={8}>
        <Body size='small' color='secondary'>
          Uncategorised
        </Body>
      </FlexLayout>
    );

  if (categories.length === 1) {
    return (
      <FlexLayout direction='row' className='flex-wrap' spacing={8}>
        <AssetCategoriesChip category={categories[0]} />
      </FlexLayout>
    );
  }

  return (
    <Popover.Root open={open} onOpenChange={(o) => !o && setOpen(o)} modal>
      <Popover.Trigger asChild>
        <FlexLayout direction='row' onClick={() => setOpen(true)}>
          <Tooltip title='View categories'>
            <FlexLayout direction='row' className='flex-wrap items-center' spacing={8}>
              <AssetCategoriesChip category={categories[0]} disableNavigation />
              <Body color='accent' size='small'>{`+ ${categories.length - 1} more`}</Body>
            </FlexLayout>
          </Tooltip>
        </FlexLayout>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild sideOffset={12} align='start'>
          <Card className='p-12'>
            <FlexLayout direction='column' spacing={8}>
              <FlexLayout direction='row' className='w-full justify-between p-8'>
                <Body weight='emphasis'>{asset.code} categories</Body>
                <Button
                  variant='outlined'
                  layout='icon'
                  leadingIcon={<Cross />}
                  size='sm'
                  onClick={() => setOpen(false)}
                  tooltip='Close'
                />
              </FlexLayout>
              {categories.map((category) => (
                <FlexLayout
                  direction='row'
                  className='w-full cursor-pointer items-center justify-between rounded-12 p-8 hover:bg-color-background-surface-hover'
                  key={category.id}
                  onClick={() => onSelectCategory(category)}
                  spacing={12}
                >
                  <FlexLayout direction='row' spacing={4} className='items-center'>
                    <Icon icon={<>{CategoryIcons()[category.name]}</>} />
                    <Body>{category.name}</Body>
                  </FlexLayout>
                  <Body color='secondary' size='small'>
                    {getAssetsByCategoryId(category.id).length} assets
                  </Body>
                </FlexLayout>
              ))}
            </FlexLayout>
          </Card>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
});

/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

import { FlexLayoutProps, flexLayoutVariants } from './FlexLayout.styles';

const FlexLayout = React.forwardRef<HTMLDivElement, PropsWithChildren<FlexLayoutProps>>(
  ({ alignItems, direction = 'row', justifyContent, spacing, children, className, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn(flexLayoutVariants({ alignItems, justifyContent, spacing, direction }), className)}
    >
      {children}
    </div>
  ),
);

FlexLayout.displayName = 'FlexLayout';

export { FlexLayout };

import { isDev } from '@shared/utils';

import { Methods, request } from '../rest';

// TODO delete remove or protect!

const reactEndpoints = {
  deleteMyAccount: () => {
    if (isDev || !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return request({ path: '/react/deleteMyAccount/', method: Methods.DELETE, auth: true });
    }
  },
};

export default reactEndpoints;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, PropsWithChildren } from 'react';

import { BalanceResult } from '@shared/api/@types/migrator';
import { ExchangesEnum } from '@shared/enums';

import { observer } from 'mobx-react-lite';

export enum TransferSteps {
  AddAPIKeys,
  SelectAssets,
  Success,
}

type PortfolioContextType = {
  exchange: ExchangesEnum | undefined;
  apiKey: string;
  apiSecret: string;
  endpointResponse: any;
  balances: BalanceResult[];
  setExchange: (exchange: ExchangesEnum) => void;
  setApiKey: (apiKey: string) => void;
  setApiSecret: (apiSecret: string) => void;
  setEndpointResponse: (response: any) => void;
  setBalances: (balances: BalanceResult[]) => void;
  step: TransferSteps;
  setStep: (step: TransferSteps) => void;
};

export const PortfolioTransferContext = React.createContext<PortfolioContextType>({
  exchange: undefined,
  apiKey: '',
  apiSecret: '',
  endpointResponse: null,
  balances: [],
  setExchange: () => {},
  setApiKey: () => {},
  setApiSecret: () => {},
  setEndpointResponse: () => {},
  setBalances: () => {},
  step: 0,
  setStep: () => {},
});

export const PortfolioTransferContextProvider: React.FC<PropsWithChildren<unknown>> = observer(({ children }) => {
  const [step, setStep] = useState<TransferSteps>(TransferSteps.AddAPIKeys);
  const [exchange, setExchange] = useState<ExchangesEnum | undefined>(undefined);
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [endpointResponse, setEndpointResponse] = useState<any>(null);
  const [balances, setBalances] = useState<BalanceResult[]>([]);

  return (
    <PortfolioTransferContext.Provider
      value={{
        exchange,
        apiKey,
        apiSecret,
        endpointResponse,
        balances,
        setExchange,
        setApiKey,
        setApiSecret,
        setEndpointResponse,
        setBalances,
        step,
        setStep,
      }}
    >
      {children}
    </PortfolioTransferContext.Provider>
  );
});

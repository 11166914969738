import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Settings } from '@swyftx/aviary/icons/outlined';

import * as Popover from '@radix-ui/react-popover';
import { observer } from 'mobx-react-lite';

import { PortfolioPerformanceOverlaysMenu } from '../PortfolioPerformanceOverlaysMenu';

type Props = {
  overlays: string[];
  setOverlays: (newValue: string[]) => void;
};

const PortfolioPerformanceWidgetMenu: React.FC<Props> = observer(({ overlays = [], setOverlays }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <Button layout='icon' leadingIcon={<Settings />} variant='outlined' focusStyle={open} size='md' />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={16} alignOffset={0} align='end'>
          <Card className='min-w-[22rem]'>
            <FlexLayout className='z-modal-popover w-full p-16' direction='column'>
              <PortfolioPerformanceOverlaysMenu overlays={overlays} setOverlays={setOverlays} />
            </FlexLayout>
          </Card>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
});

export { PortfolioPerformanceWidgetMenu };

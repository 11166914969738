import * as React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';

import { Image } from '@swyftx/aviary/atoms/Image';

import { BalanceSuccess, BalanceFailure } from '@shared/api/@types/migrator';

import { PortfolioTransferContext } from '@routes/PortfolioTransfer/PortfolioTransfer.context';

type Data = BalanceSuccess | BalanceFailure;

type Order = 'asc' | 'desc';

interface HeadCell {
  id: keyof Data;
  label: string;
  rightAlign: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'asset',
    rightAlign: false,
    label: 'ASSET',
  },
  {
    id: 'balance',
    rightAlign: true,
    label: 'BALANCE',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tableDisabled?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!props.tableDisabled && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.rightAlign ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface AssetTableProps {
  onSelectedChange: (selectedItems: Data[]) => void;
  tableDisabled?: boolean;
  items?: Data[];
}

const getKey = (item: Data) => `${item.asset.exchangeCode}-${item.asset.swyftxId ?? 'null'}`;

const AssetTable: React.FC<AssetTableProps> = ({ onSelectedChange, tableDisabled, items = [] }) => {
  const { exchange } = React.useContext(PortfolioTransferContext);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('asset');
  const [selected, setSelected] = React.useState(tableDisabled ? [] : items.map(getKey));

  React.useEffect(() => {
    // Notify about the initial state rather than jank hoisting
    onSelectedChange(selected.map((key) => items.find((item) => getKey(item) === key)!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = items.map(getKey);
      setSelected(newSelected);
      onSelectedChange(items);
      return;
    }
    setSelected([]);
    onSelectedChange([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, key: string) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    onSelectedChange(newSelected.map((key) => items.find((item) => getKey(item) === key)!));
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  if (items.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          p: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>No assets match criteria</Typography>
        <Typography>
          Oh no! It looks like none of the assets on {exchange} can be transferred as they&lsquo;re not listed on
          Swyftx.
        </Typography>
        <Image image='empty_wallet' className='h-[160px] w-[160px]' usePalette alt='empty wallet' />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table aria-labelledby='tableTitle'>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={items.length}
            tableDisabled={tableDisabled}
          />
          <TableBody>
            {items.map((row, index) => {
              const key = getKey(row);
              const isItemSelected = isSelected(key);
              const labelId = `migratortable-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => {
                    if (!tableDisabled) {
                      handleClick(event, key);
                    }
                  }}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={key}
                  selected={isItemSelected}
                  sx={{ cursor: tableDisabled ? 'not-allowed' : 'pointer' }}
                >
                  {!tableDisabled && (
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell component='th' id={labelId} scope='row'>
                    {row.asset.exchangeCode}
                  </TableCell>
                  <TableCell align='right'>{row.balance}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export { AssetTable };

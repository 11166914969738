import React from 'react';

import ListItemMUI, { ListItemProps } from '@mui/material/ListItem';

const ListItem: React.FC<ListItemProps> = ({ id, children, ...props }) => (
  <ListItemMUI id={id} {...props}>
    {children}
  </ListItemMUI>
);

export { ListItem };

import { ReturnedTransaction, ReturnedTransactionStatus, TransactionStatus } from '../../@types/verification';
import { Methods, request } from '../rest';

const verificationEndpoints = {
  setupNewGreenID: ({ data, headers }: { data: object; headers?: Record<string, string> }): { greenIdRef: string } =>
    request({ path: '/verification/setupNewGreenID/', method: Methods.POST, data, headers, auth: true }),

  setupNewGreenIDForMember: ({
    params,
    data,
    headers,
  }: {
    params: { memberId: string };
    data: object;
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/verification/setupNewGreenIDForMember/:memberId/',
      method: Methods.POST,
      headers,
      data,
      params,
      auth: true,
    }),

  createJumioTransaction: ({
    data,
    headers,
  }: {
    data: { transactionReference: string };
    headers?: Record<string, string>;
  }) => request({ path: '/verification/jumioExisting/', method: Methods.POST, headers, data, auth: true }),

  getJumioTransaction: ({
    query,
    headers,
  }: {
    query?: { forceCreate?: boolean; checkOnly: boolean };
    headers?: Record<string, string>;
  }): ReturnedTransaction =>
    request({
      path: '/verification/jumioLink',
      method: Methods.GET,
      headers,
      query,
      auth: true,
    }),

  getJumioTransactionStatus: ({
    params,
    headers,
  }: {
    params: { transactionReference: string };
    headers?: Record<string, string>;
  }): ReturnedTransactionStatus =>
    request({
      path: '/verification/jumioStatus/:transactionReference',
      method: Methods.GET,
      headers,
      params,
      auth: true,
    }),

  updateJumioTransactionStatus: ({
    params,
    headers,
  }: {
    data: { status: TransactionStatus };
    params: { transactionReference: string };
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/verification/jumioUpdate/:transactionReference',
      method: Methods.POST,
      headers,
      params,
      auth: true,
    }),
};

export default verificationEndpoints;

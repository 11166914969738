/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';
import { CrossInCircle, Search } from '@swyftx/aviary/icons/outlined';

type Props = InputProps & {
  onDelete: () => void;
  value: string;
  hideClear?: boolean;
};

const SearchInputAviary: React.FC<Props> = (props) => {
  const { hideClear, onDelete, ...iProps } = props;
  const ref = useRef<HTMLInputElement>(null);

  const onClear = () => {
    onDelete();
    ref.current?.focus();
  };

  return (
    <Input
      ref={ref}
      leading={<Search id='search-icon' className='h-20 w-20 text-color-text-primary' />}
      trailing={
        !hideClear &&
        iProps.value.length > 0 && (
          <CrossInCircle
            className='h-20 w-20 cursor-pointer text-color-text-primary '
            onClick={onClear}
            id='cross-icon'
          />
        )
      }
      {...iProps}
    />
  );
};

export { SearchInputAviary };

import { useEntityOnboardingSelector } from '../EntityOnboarding.context';
import { entityOnboardingMachine } from '../EntityOnboarding.machine';
import { EntityOnboardingStepId } from '../types/EntityOnboarding.types';

export const useStepId = () => {
  const stepId = useEntityOnboardingSelector((state) => {
    const [rootStateId, activeStateId] = state.toStrings();

    if (activeStateId != null) {
      const activeStateNode = entityOnboardingMachine.getStateNodeByPath(activeStateId);
      if (activeStateNode.meta?.step && !activeStateNode.meta?.hidden) {
        return activeStateId as EntityOnboardingStepId;
      }
    }

    return rootStateId as EntityOnboardingStepId;
  });

  return { stepId };
};

import React, { useContext, useMemo, useState } from 'react';

import { Stack } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { ProfileContext } from '@Profile/Profile.context';
import { AffiliateHistoryEntry } from '@Profile/types';

import { observer } from 'mobx-react-lite';

import { getColumns } from './PastPayouts.data';
import { AffiliateDashboardPastPayoutsTableHeader } from './PastPayoutsTableHeader';
import { AffiliateDashboardPastPayoutsTableItem } from './PastPayoutsTableItem';

const AffiliateDashboardPastPayoutsTable: React.FC = observer(() => {
  const [sortKey, setSortKey] = useState<Extract<keyof AffiliateHistoryEntry, string>>('updated');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'default'>('desc');
  const { payoutHistory } = useContext(ProfileContext);
  const { userProfile } = UserStore.useUserStore;
  const gstRegistered = userProfile?.userSettings.gstRegistered === 1;

  const { bx, is } = useContentBreakpoint();

  const columns = useMemo(() => getColumns(gstRegistered), [is, gstRegistered]);

  const sortedPayoutItems = useMemo(() => {
    if (!payoutHistory) return payoutHistory;

    const history = Array.from(payoutHistory);

    switch (sortDirection) {
      case 'asc': {
        return history.sort((a: AffiliateHistoryEntry, b: AffiliateHistoryEntry) => +a[sortKey]! - +b[sortKey]!);
      }
      case 'desc': {
        return history.sort((a: AffiliateHistoryEntry, b: AffiliateHistoryEntry) => +b[sortKey]! - +a[sortKey]!);
      }
      default: {
        return history.sort((a: AffiliateHistoryEntry, b: AffiliateHistoryEntry) => +b[sortKey]! - +a[sortKey]!);
      }
    }
  }, [sortKey, sortDirection, payoutHistory]);

  return (
    <>
      <Stack
        display={bx({ xs: 'none', md: 'flex' })}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        width='100%'
        height='60px'
        borderBottom='1px solid'
        borderColor='divider'
        paddingX={2}
      >
        {columns.map(
          (column) =>
            !column.hidden && (
              <AffiliateDashboardPastPayoutsTableHeader
                key={column.label}
                column={column}
                sortKey={sortKey}
                setSortKey={setSortKey}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              />
            ),
        )}
      </Stack>
      {sortedPayoutItems?.map((history) => (
        <AffiliateDashboardPastPayoutsTableItem item={history} key={history.reference} />
      ))}
    </>
  );
});

export { AffiliateDashboardPastPayoutsTable };

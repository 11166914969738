import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Heart: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M17.6413 2.57829C15.7713 1.97829 13.6113 2.25829 11.9813 3.28829C10.3113 2.22829 8.23134 1.95829 6.33134 2.57829C2.27134 3.87829 1.02134 8.30829 2.14134 11.8283C3.94134 17.4183 11.3113 21.4883 11.6213 21.6583C11.7313 21.7183 11.8613 21.7483 11.9813 21.7483C12.1013 21.7483 12.2313 21.7183 12.3413 21.6583C12.6613 21.4883 20.0813 17.3583 21.8213 11.8283C22.9513 8.30829 21.6913 3.87829 17.6313 2.57829H17.6413ZM20.4013 11.3783C19.0413 15.6883 13.4613 19.2583 11.9913 20.1383C10.5213 19.2683 4.98134 15.7383 3.58134 11.3783C2.69134 8.59829 3.51134 5.06829 6.79134 4.00829C8.40134 3.48829 10.1813 3.78829 11.5413 4.80829C11.8113 5.00829 12.1813 5.00829 12.4513 4.80829C13.7313 3.81829 15.5913 3.50829 17.1813 4.01829C20.4713 5.07829 21.2913 8.60829 20.4013 11.3883V11.3783ZM18.6513 9.06829C18.6813 9.47829 18.3813 9.84829 17.9613 9.87829C17.9413 9.87829 17.9213 9.87829 17.9013 9.87829C17.5113 9.87829 17.1913 9.57829 17.1513 9.18829C17.0813 8.36829 16.5313 7.66829 15.7513 7.41829C15.3613 7.28829 15.1413 6.86829 15.2713 6.46829C15.4013 6.07829 15.8213 5.85829 16.2213 5.98829C17.5813 6.42829 18.5413 7.63829 18.6513 9.05829V9.06829Z'
      fill='currentColor'
    />
  </svg>
);

export { Heart };

import { useCallback, useContext, useEffect } from 'react';

import { AssetHistoryItemStatus, SortDirection, SortKey, TransactionType } from '@shared/api';
import { UserStore } from '@shared/store';

import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { useTransactionDetails } from 'src/lib/transactions/hooks/useTransactionDetails';
import { useTransactions } from 'src/lib/transactions/hooks/useTransactions';

import { TradingViewContext } from '../../context/TradingView.context';
import { OPEN_ORDER_LINE_TITLE, useBuildPriceLine } from '../useTradingViewPriceLines/useBuildPriceLine';

type Props = {
  primary: string;
  secondary: string;
  tvWidget?: IChartingLibraryWidget;
};

const useTradingViewOpenOrdersAdapter = ({ primary, secondary, tvWidget }: Props) => {
  const { showOpenOrders } = useContext(TradingViewContext);
  const { getAssetByCode } = useMarkets();
  const { buildPriceLine, clearPriceLines } = useBuildPriceLine({ tvWidget, secondary });
  const { userProfile } = UserStore.useUserStore;
  const { isSwyftxPro } = useSwyftxPro();

  const primaryAsset = getAssetByCode(primary);
  const secondaryAsset = getAssetByCode(secondary);

  const { transactions } = useTransactions({
    sortKey: SortKey.Date,
    sortDirection: SortDirection.DESCENDING,
    pageLimit: 500,
    statuses: [AssetHistoryItemStatus.Pending],
    types: [TransactionType.Buy, TransactionType.Sell],
    userId: userProfile?.intercom?.uuid,
    asset: secondaryAsset,
  });
  const { getTransactionDetails } = useTransactionDetails();

  const onTick = useCallback(() => {
    if (!showOpenOrders || !isSwyftxPro || !transactions?.length) {
      // Remove any open order lines that have been drawn
      clearPriceLines(OPEN_ORDER_LINE_TITLE);
    }
    tvWidget?.unsubscribe('onTick', onTick);
  }, [showOpenOrders, isSwyftxPro, transactions, tvWidget, clearPriceLines]);

  const onBuildLines = useCallback(() => {
    if (showOpenOrders && transactions && tvWidget) {
      // Clear price lines before building the new ones
      clearPriceLines(OPEN_ORDER_LINE_TITLE);

      // Draw open order lines that aren't already on the chart
      transactions.forEach((transaction) => {
        if (
          (transaction.primaryAsset === primaryAsset?.id && transaction.secondaryAsset === secondaryAsset?.id) ||
          (transaction.primaryAsset === secondaryAsset?.id && transaction.secondaryAsset === primaryAsset?.id)
        ) {
          const { rawTriggerPrice, orderType } = getTransactionDetails(transaction, { noSuffix: true });

          const line = buildPriceLine('horizontal_line', Number(rawTriggerPrice));

          if (line) {
            const shape = tvWidget.activeChart().getShapeById(line);
            const color = '#0072ED';

            shape.setProperties({
              text: orderType.title,
              title: OPEN_ORDER_LINE_TITLE,
              horzLabelsAlign: 'left',
              showLabel: true,
              linecolor: color,
              textcolor: color,
              fontsize: 12,
              bold: false,
              lineStyle: 1,
            });

            shape.setUserEditEnabled(false);
          }
        }
      });
    }
    tvWidget?.unsubscribe('onTick', onBuildLines);
  }, [
    showOpenOrders,
    transactions,
    tvWidget,
    primaryAsset?.id,
    secondaryAsset?.id,
    getTransactionDetails,
    buildPriceLine,
  ]);

  useEffect(() => {
    try {
      if (!transactions || !tvWidget) return;

      tvWidget.onChartReady(() => {
        if (!showOpenOrders || !isSwyftxPro || !transactions.length) {
          tvWidget.subscribe('onTick', onTick);
        } else {
          tvWidget.subscribe('onTick', onBuildLines);
        }
      });
    } catch (e) {
      return;
    }
  }, [
    transactions,
    showOpenOrders,
    clearPriceLines,
    getTransactionDetails,
    buildPriceLine,
    tvWidget,
    onTick,
    onBuildLines,
    isSwyftxPro,
  ]);
};

export { useTradingViewOpenOrdersAdapter };

import * as React from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Radio = React.forwardRef<HTMLInputElement, Props>(({ className, ...props }, ref) => (
  <label className='rounded-full relative flex cursor-pointer items-center'>
    <input
      type='radio'
      ref={ref}
      className={cn(
        'before:absolute before:left-2/4 before:top-2/4 before:block before:h-1/2 before:w-1/2 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-[100%]',
        'ring-offset-background  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        'cursor-pointer border-color-border-main checked:border-color-border-accent',
        'peer h-22 w-22 appearance-none rounded-[100%] border-2 bg-color-background-surface-primary p-4 checked:before:bg-color-background-primary',
        className,
      )}
      {...props}
    />
  </label>
));
Radio.displayName = 'Radio';

export { Radio };

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { getPriceScale } from '@swyftx/currency-util';

import { api, AssetHistoryItem, AssetHistoryItemStatus, orderType, TransactionType } from '@shared/api';
import { Candle, Candles } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';
import { assetService, chartService, ratesService } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import { clearAllTimeouts } from '@utils/window.utils';

import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import {
  ChartingLibraryWidgetOptions,
  LibrarySymbolInfo,
  Mark,
  MarkCustomColor,
} from 'public/assets/charting_library/charting_library';
import { mockFetchChartBarsData, mockFetchLatestChartBarData } from 'src/lib/trade/hooks/Charts';

import { getFromGlobalState, updateGlobalState } from '../types/tradingViewGlobalState';

type Props = {
  primary: string;
  mock?: boolean;
  isSwyftxPro?: boolean;
};

const TransactionTypeToColor: { [txType: string]: { light: MarkCustomColor; dark: MarkCustomColor } } = {
  [TransactionType.Buy]: {
    light: { background: '#038214', border: '#038214' },
    dark: { background: '#4ea75a', border: '#4ea75a' },
  },
  [TransactionType.Sell]: {
    light: { background: '#d41a0b', border: '#d41a0b' },
    dark: { background: '#e05e54', border: '#e05e54' },
  },
};

const useTradingViewDatafeed = ({ primary, mock = false, isSwyftxPro }: Props) => {
  const { t } = useTranslation('orders');
  const { theme } = useTailwindTheme();

  useEffect(() => {
    const tvTheme = theme === TailWindTheme.Light ? 'light' : 'dark';
    updateGlobalState('theme', tvTheme);
  }, [theme]);

  const getSecondarySymbol = (symbols: string) => {
    // Remove any SWYFTX: References first
    const val = symbols.replace('SWYFTX:', '');

    return val.slice(0, val.length - primary.length);
  };

  const transformTransactionForMark = useCallback(
    (transaction: AssetHistoryItem, secondary: string): Mark | undefined => {
      const tradingViewTheme = getFromGlobalState('theme') || theme;

      if (transaction.orderType === null) return undefined;

      const asset = assetService.getAssetByCode(secondary);
      const transactionType = t(orderType[transaction.orderType] as any);

      const header = `${transactionType}`;
      const date = DateTime.fromJSDate(new Date(transaction.date)).toFormat('dd/MM/yyyy h:mm a').toUpperCase();

      const primaryAsset = assetService.getAsset(transaction.primaryAsset);
      const secondaryAsset = assetService.getAsset(transaction.secondaryAsset);

      if (!primaryAsset) return undefined;

      const feeIsPrimary = transaction.limitAsset === transaction.secondaryAsset;
      let primaryAmount = Big(transaction.primaryAmount);
      if (feeIsPrimary) primaryAmount = primaryAmount.plus(transaction.feeAmount);

      let secondaryAmount = Big(transaction.secondaryAmount);
      if (!feeIsPrimary) secondaryAmount = secondaryAmount.plus(transaction.feeAmount);

      const trigger = Big(primaryAmount).div(secondaryAmount).abs().toString();

      const rate = `${formatCurrency(trigger, primaryAsset, { hideCode: true, appendCode: false })} ${
        primaryAsset.code
      }/${asset?.code}`;

      const toText = asset ? formatCurrency(Big(transaction.secondaryAmount).abs().toString(), secondaryAsset) : '';
      const fromText = formatCurrency(Math.abs(Number(transaction.primaryAmount)), primaryAsset, {
        hideCode: false,
        appendCode: true,
      });

      return {
        id: transaction.uuid,
        time: transaction.date / 1000,
        text: `${header} ${toText} for ${fromText} on the
      ${date} at a rate of ${rate}`,
        color: TransactionTypeToColor[transaction.type][tradingViewTheme],
        label: transaction.type.slice(0, 1),
        labelFontColor: tradingViewTheme === 'dark' ? 'black' : 'white',
        minSize: 16,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const getTradingViewDataFeed = useCallback(
    (): ChartingLibraryWidgetOptions['datafeed'] => ({
      onReady: (callback) => {
        window.setTimeout(() => callback({ supports_marks: true }), 0);
      },
      searchSymbols: () => {},
      resolveSymbol: async (symbols, onSymbolResolvedCallback, onResolveErrorCallback) => {
        const secondary = getSecondarySymbol(symbols);
        try {
          const res = await api.endpoints.resolveChartSymbol({
            params: { primary, secondary },
          });
          // const res = resolveSymbolMock; // for mock testing (bipolar dev environment things)
          const symbolInfo = res?.data;

          const asset = assetService.getAssetByCode(secondary);
          const primaryAsset = assetService.getAssetByCode(primary);
          if (!asset || !primaryAsset) {
            throw Error();
          }

          const rate = ratesService.getRate(asset.id);

          const newPriceScale = 10 ** getPriceScale(rate.midPrice);
          if (symbolInfo) {
            onSymbolResolvedCallback({ ...symbolInfo, pricescale: newPriceScale } as LibrarySymbolInfo);
          } else {
            throw Error();
          }
        } catch (er) {
          onResolveErrorCallback('Symbol info not found');
        }
      },
      getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const secondary = getSecondarySymbol(symbolInfo.name);

        const { from, to } = periodParams;
        const MINIMUM_START_TIME = new Date('2017-01-01T00:00:00+00:00').getTime();

        // A bad hack for when switching between bid and ask, we are getting
        // the same from and to dates. If thats the case. Just return 7 days of data and let
        // TradingView handle getting the rest
        const timeStart =
          from === to
            ? DateTime.fromMillis(Number(`${from}000`))
                .minus({ days: 7 })
                .toMillis()
            : Number(`${from}000`) < MINIMUM_START_TIME
            ? MINIMUM_START_TIME
            : Number(`${from}000`);

        const timeEnd = Number(`${to}000`);

        const params = {
          side: getFromGlobalState('side'),
          secondary,
          primary,
        };

        const query = {
          resolution: chartService.tradingToSwyftx?.[resolution],
          timeStart,
          timeEnd,
          isPro: isSwyftxPro,
        };

        try {
          const barsOutput = await (async () => {
            if (timeEnd < MINIMUM_START_TIME) {
              return { data: { candles: [] } };
            }

            if (mock) return { data: mockFetchChartBarsData };

            const res = await api.endpoints.getChartBars({ params, query });
            res.data.candles = res.data.candles.map((candle) => ({
              ...candle,
              open: Number(candle.open.toString()),
              close: Number(candle.close.toString()),
              high: Number(candle.high.toString()),
              low: Number(candle.low.toString()),
            }));
            return res;
          })();

          const bars = barsOutput?.data as Candles;

          onHistoryCallback(bars.candles, { noData: !bars.candles.length, nextTime: bars.nextTime });
        } catch (e) {
          onErrorCallback('getChartBars data not found');
        }
      },
      subscribeBars: async (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        const secondary = getSecondarySymbol(symbolInfo.name);

        clearAllTimeouts(getFromGlobalState('chartTimeouts'));

        updateGlobalState('onResetCacheNeededCallback', onResetCacheNeededCallback);

        const fetchLatest = async () => {
          if (getFromGlobalState('chartActive') === false) return;

          const params = {
            primary,
            secondary,
            side: getFromGlobalState('side'),
          };

          const query = {
            resolution: chartService.tradingToSwyftx?.[resolution],
            isPro: isSwyftxPro,
          };

          try {
            if (mock) {
              onRealtimeCallback(mockFetchLatestChartBarData);
            } else {
              const res = await api.endpoints.getChartLatestBar({ params, query });
              const bars: Candle = {
                ...res?.data,
                open: Number(res?.data?.open.toString()),
                close: Number(res?.data?.close.toString()),
                high: Number(res?.data?.high.toString()),
                low: Number(res?.data?.low.toString()),
              };
              onRealtimeCallback(bars);
            }
          } catch (e) {
            // ignore errors
          } finally {
            clearAllTimeouts(getFromGlobalState('chartTimeouts'));

            const timeout = window.setTimeout(fetchLatest, 2000);
            updateGlobalState('chartTimeouts', [...getFromGlobalState('chartTimeouts'), timeout]);
          }
        };

        fetchLatest();
      },
      unsubscribeBars: (listenerGuid: string) => {
        window.clearTimeout(listenerGuid);
      },
      getMarks: async (symbolInfo, from, to, onDataCallback) => {
        const secondary = getSecondarySymbol(symbolInfo.name);

        const assetId = assetService.getAssetByCode(secondary)?.id;
        if (!assetId) return;

        try {
          const res = await api.endpoints.getAssetTransactionHistory({
            params: { assetId },
            query: {
              type: `${TransactionType.Buy},${TransactionType.Sell}`,
              status: `${AssetHistoryItemStatus.Completed}`,
              startDate: from * 1000,
              endDate: to * 1000,
              // order histories larger than this are unlikely to perform well in the tradingview chart
              limit: 10000,
            },
          });

          const marks: Mark[] = res.data.items
            .filter(
              (i) =>
                i.orderType !== null &&
                [AssetHistoryItemStatus.Completed, AssetHistoryItemStatus.Pending].includes(i.status) &&
                [TransactionType.Buy, TransactionType.Sell].includes(i.type),
            )
            .map((transaction) => transformTransactionForMark(transaction, secondary)) as Mark[];
          onDataCallback(marks);
        } catch (e) {
          Sentry.captureException(e);
        }
      },
    }),
    [mock, primary, transformTransactionForMark],
  );

  return { getTradingViewDataFeed };
};

export { useTradingViewDatafeed };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Progress } from '@swyftx/aviary/atoms/Progress';

import { useStepperSteps } from '@routes/EntityOnboarding/hooks/useStepperSteps';

const MobileEntityOnboardingStepper: React.FC = () => {
  const { isStepComplete, steps } = useStepperSteps();

  return (
    <FlexLayout direction='column'>
      <FlexLayout direction='row' spacing={8}>
        {steps.map((step) => {
          const progress = isStepComplete(step) ? 100 : 0;

          return (
            <Progress
              key={step.id}
              value={progress}
              className='mb-32 h-8 flex-grow rounded-4 bg-color-background-neutral'
            />
          );
        })}
      </FlexLayout>
    </FlexLayout>
  );
};

export { MobileEntityOnboardingStepper };

import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { RadioButtonUnchecked } from '@mui/icons-material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Grid, Stack } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { AccountStatusEnum } from '@shared/enums';
import { StorageKey } from '@shared/storage';
import { UniversalTradeStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useUserTradeDetails } from '@hooks/useUserTradeDetails';
import TickCircleFilledIcon from '@routes/Auth/assets/TickCircleFilledIcon';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  setRefereeBannerDismissed: Dispatch<SetStateAction<boolean>>;
};

type ReferralStep = {
  description: string;
  completed: boolean;
  actionText: string;
  onClickAction: () => void;
};

type ReferralStepProps = {
  step: ReferralStep;
  index: number;
};

const LOG_TAG = 'REFER_A_FRIEND_BANNER';

const RefereeBanner: React.FC<Props> = observer(({ setRefereeBannerDismissed }) => {
  const { t } = useTranslation('common', { keyPrefix: 'refereeBanner' });
  const { isLargeContent, bx, up } = useContentBreakpoint();
  const { navigate } = useNavigateRoute();
  const { openModal } = useModal();
  const { userStatistics, getAccountStatus, userProfile } = UserStore.useUserStore;
  const { depositedFiat } = useUserTradeDetails();
  const madeTrade = useMemo(() => (userStatistics ? userStatistics?.traded > 0 : false), [userStatistics]);
  const userStatus = useMemo(() => getAccountStatus(), [getAccountStatus]);
  const referralBonusStatus = useMemo(() => userProfile?.userSettings?.signupBonus?.state, [userProfile]);
  const { setShowGlobalTrade } = UniversalTradeStore;
  const expiryTime = userProfile?.userSettings?.signupBonus?.expires
    ? DateTime.fromMillis(userProfile?.userSettings?.signupBonus?.expires)
    : undefined;
  const daysUntilExpiry = expiryTime?.diffNow('days').days;
  const { pathname } = useLocation();
  const avo = useAvo();

  const dismissBanner = () => {
    localStorage.setItem(StorageKey.DISMISS_REFER_A_FRIEND_BANNER, JSON.stringify(true));
    setRefereeBannerDismissed(true);
  };

  const greenIdref = userProfile?.verification?.greenid?.id;

  const referralSteps: ReferralStep[] = [
    {
      description: t('referralSteps.verify'),
      completed: userStatus === AccountStatusEnum.GOLD,
      actionText: t('actionLabels.verify'),
      onClickAction: () => {
        if (greenIdref) {
          navigate(NavigationURLs.ProfileVerification);
        } else {
          navigate(NavigationURLs.Onboarding);
        }

        avo.clickedReferAFriendDashboardBanner({
          screen: pathname,
          buttonName: t('actionLabels.verify'),
        });
      },
    },
    {
      description: t('referralSteps.deposit'),
      completed: depositedFiat,
      actionText: t('actionLabels.deposit'),
      onClickAction: () => {
        openModal(Modals.DepositReceive);
        avo.clickedReferAFriendDashboardBanner({
          screen: pathname,
          buttonName: t('actionLabels.deposit'),
        });
        avo.depositFundsTapped({
          screen: pathname,
          component: LOG_TAG,
        });
      },
    },
    {
      description: t('referralSteps.trade'),
      completed: madeTrade,
      actionText: t('actionLabels.trade'),
      onClickAction: () => {
        avo.clickedReferAFriendDashboardBanner({
          screen: pathname,
          buttonName: t('actionLabels.trade'),
        });
        navigate(NavigationURLs.UniversalTrade);
        setShowGlobalTrade(true);
      },
    },
  ];

  const currentStep = referralSteps.find((step) => !step.completed);

  const ReferralStepItem = ({ step, index }: ReferralStepProps) => (
    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
      <Stack
        direction='row'
        gap='8px'
        paddingY={1}
        color='text.secondary'
        paddingLeft={index > 0 && isLargeContent ? 2 : 0}
      >
        {step.completed ? (
          <TickCircleFilledIcon className='text-color-text-accent' width='20px' height='20px' />
        ) : (
          <RadioButtonUnchecked />
        )}
        <Body size='small' color='primary' className='mt-2' weight={step.completed ? 'emphasis' : 'none'}>
          {step.description}
        </Body>
      </Stack>
      {index < 2 && up.lg && <div className='h-[1px] w-[50px] border border-dashed border-color-border-main' />}
    </Stack>
  );

  const getBannerContent = () => {
    if (referralBonusStatus === 'redeemed') {
      return (
        <Stack flexDirection='column' width='100%'>
          <Body weight='emphasis'>{t('claimed.title')}</Body>
          <Body size='small' color='secondary'>
            {t('claimed.content')}
          </Body>
        </Stack>
      );
    }
    if (referralBonusStatus === 'expired') {
      return (
        <Stack flexDirection='column' width='100%'>
          <Body weight='emphasis' className='inline'>
            {t('expired.title')}{' '}
            <Body weight='emphasis' className='inline' color='error'>
              {t('expired.highlight')}
            </Body>
          </Body>
          <Body size='small' color='secondary'>
            {t('expired.content')}
          </Body>
        </Stack>
      );
    }
    return (
      <Stack flexDirection='column' width='100%'>
        {daysUntilExpiry && daysUntilExpiry <= 10 && daysUntilExpiry > -1 ? (
          <Body weight='emphasis' className='inline'>
            {t('titleWithCountDown1')}{' '}
            <Body weight='emphasis' color='error' className='inline'>
              {daysUntilExpiry > 1
                ? t('countDown', { days: daysUntilExpiry })
                : t('oneDayLeft', { days: daysUntilExpiry })}
            </Body>{' '}
            {t('titleWithCountDown2')}
          </Body>
        ) : (
          <Body weight='emphasis'>{t('title')}</Body>
        )}
        <Stack flexDirection={bx({ xs: 'column', md: 'row' })}>
          {referralSteps.map((step, i) => (
            <ReferralStepItem key={`referral-step-${i}`} step={step} index={i} />
          ))}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack width='100%'>
      <Card className='w-full p-16'>
        <FlexLayout alignItems='center' justifyContent='space-between'>
          <Grid item sm={10}>
            <Stack
              flexDirection={bx({ xs: 'column', sm: 'row' })}
              width='100%'
              alignItems={bx({ xs: 'baseline', sm: 'center' })}
              paddingBottom={bx({ xs: 2, sm: 0 })}
            >
              <Stack flexDirection='column' paddingRight={bx({ sm: 2, md: 3 })}>
                {referralBonusStatus === 'redeemed' ? (
                  <Image image='refer_a_friend' className='h-64 w-64' usePalette alt='referral claimed' />
                ) : (
                  <Image image='maintenance' className='h-64 w-64' usePalette alt='verification prompts' />
                )}
              </Stack>
              {getBannerContent()}
            </Stack>
          </Grid>
          {referralBonusStatus === 'pending' ? (
            <Button variant='filled' onClick={currentStep?.onClickAction}>
              {currentStep?.actionText}
            </Button>
          ) : (
            <Button onClick={dismissBanner}>{t('actionLabels.dismiss')}</Button>
          )}
        </FlexLayout>
      </Card>
    </Stack>
  );
});

RefereeBanner.displayName = 'RefereeBanner';

export { RefereeBanner };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const MEVProtection: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      d='M20.3999 19.3291C20.3999 19.7266 20.0772 20.0488 19.6797 20.0488H6.23975C4.78418 20.0488 3.59961 18.8652 3.59961 17.4092V3.96973C3.59961 3.57227 3.92236 3.25 4.31982 3.25C4.71728 3.25 5.04003 3.57227 5.04003 3.96973V17.4092C5.04003 18.0713 5.57812 18.6094 6.23974 18.6094H19.6797C20.0771 18.6094 20.3999 18.9316 20.3999 19.3291ZM7.38818 15.3135C8.27734 15.3135 10.4014 14.8535 10.9141 10.5898C11.0312 9.61719 11.665 7.97169 13.2983 7.97169C14.9321 7.97169 15.5659 9.6172 15.6831 10.5898C16.1958 14.8535 18.3198 15.3135 19.209 15.3135C19.6064 15.3135 19.9292 14.9912 19.9292 14.5938C19.9292 14.1963 19.6064 13.874 19.209 13.874C17.6919 13.874 17.2378 11.457 17.1128 10.418C16.8799 8.4834 15.6099 6.53223 13.2983 6.53223C10.9868 6.53223 9.7173 8.4834 9.48439 10.418C9.35939 11.457 8.90529 13.874 7.3882 13.874C6.99074 13.874 6.66799 14.1963 6.66799 14.5938C6.66799 14.9912 6.99072 15.3135 7.38818 15.3135Z'
      fill='currentColor'
    />
  </svg>
);

export { MEVProtection };

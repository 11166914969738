import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Switch: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M10.4567 8.4578C10.7489 8.75137 11.2238 8.75247 11.5174 8.46025C11.811 8.16804 11.8121 7.69316 11.5198 7.3996L7.44206 3.30293C7.30131 3.16153 7.11002 3.08203 6.9105 3.08203C6.71099 3.08203 6.5197 3.16153 6.37895 3.30293L2.30117 7.39959C2.00896 7.69316 2.01005 8.16804 2.30362 8.46025C2.59719 8.75247 3.07206 8.75137 3.36428 8.4578L6.16016 5.64897L6.16016 17.4512C6.16016 17.8654 6.49594 18.2012 6.91016 18.2012C7.32437 18.2012 7.66016 17.8654 7.66016 17.4512L7.66016 5.64828L10.4567 8.4578Z'
      fill='currentColor'
    />
    <path
      d='M17.5898 18.3488L17.5898 6.54627C17.5898 6.13206 17.2541 5.79627 16.8398 5.79627C16.4256 5.79627 16.0898 6.13206 16.0898 6.54627L16.0898 18.3481L13.294 15.5393C13.0018 15.2457 12.5269 15.2446 12.2333 15.5368C11.9397 15.829 11.9386 16.3039 12.2309 16.5975L16.3086 20.6941C16.4494 20.8355 16.6407 20.915 16.8402 20.915C17.0397 20.915 17.231 20.8355 17.3717 20.6941L21.4495 16.5975C21.7417 16.3039 21.7406 15.829 21.4471 15.5368C21.1535 15.2446 20.6786 15.2457 20.3864 15.5393L17.5898 18.3488Z'
      fill='currentColor'
    />
  </svg>
);

export { Switch };

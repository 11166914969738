import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NzBirthCertIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <rect
        x='5.333'
        y='3.333'
        width='21.333'
        height='25.333'
        rx='3.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='.4'
        d='M10.668 16.668h10.667M10.668 22.001h10.667'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 3A3.5 3.5 0 0 0 5 6.5V12h22V6.5A3.5 3.5 0 0 0 23.5 3h-15Zm6.156 7.116h1.368V4.5h-1.368v3.48L12.368 4.5H11v5.616h1.368V6.652l2.288 3.464Zm6.232-1.12h-2.416l2.416-3.456V4.5H16.92v1.12h2.4l-2.4 3.456v1.04h3.968v-1.12Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);

import { useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

import { useTooltipPosition } from './useTooltipPosition';

type Props = {
  chartId: string;
  [key: string]: any;
};

const PortfolioBreakdownChartTooltip: React.FC<Props> = observer((props: Props) => {
  const {
    chartId,
    center: { x, y },
    orientation,
    datum: { title, icon, subTitle, percentage, color, otherData },
  } = props;

  const { newX, newY } = useTooltipPosition({ orientation, x, y, tooltipHeight: 76, tooltipWidth: 250, chartId });

  const tooltipHeader: React.ReactElement = useMemo(() => {
    if (!otherData) {
      return (
        <FlexLayout spacing={8} className='w-full' alignItems='center' justifyContent='space-between'>
          <FlexLayout alignItems='center' spacing={8}>
            <div className='h-full min-h-[24px] min-w-[4px] rounded-[2px]' style={{ backgroundColor: color }} />
            <Numeric size='medium' weight='emphasis' className='truncate'>
              {title}
            </Numeric>
          </FlexLayout>
          {icon}
        </FlexLayout>
      );
    }

    return (
      <FlexLayout spacing={8} className='w-full' alignItems='center' justifyContent='space-between'>
        <FlexLayout direction='column' alignItems='center' spacing={8}>
          <Numeric size='xsmall' weight='emphasis' className='truncate' color='secondary'>
            {title}
          </Numeric>
          <Numeric size='medium' weight='emphasis' color='primary' className='truncate'>
            {subTitle}
          </Numeric>
        </FlexLayout>
        {icon}
      </FlexLayout>
    );
  }, [color, icon, otherData, subTitle, title]);

  const tooltipContent: React.ReactElement = useMemo(() => {
    if (!otherData) {
      return (
        <FlexLayout direction='column' className='w-full' spacing={0}>
          {subTitle && (
            <FlexLayout alignItems='center' className='w-full' justifyContent='space-between'>
              <Numeric size='xsmall' color='secondary'>
                Value
              </Numeric>
              <Numeric size='xsmall' color='primary' className='truncate'>
                {subTitle}
              </Numeric>
            </FlexLayout>
          )}
          {percentage && (
            <FlexLayout alignItems='center' className='w-full' justifyContent='space-between'>
              <Numeric size='xsmall' color='secondary'>
                Allocation
              </Numeric>
              <Numeric size='xsmall' color='primary' className='truncate'>
                {percentage}
              </Numeric>
            </FlexLayout>
          )}
        </FlexLayout>
      );
    }

    return (
      <>
        {otherData.map((d: any) => (
          <FlexLayout alignItems='center' spacing={8} justifyContent='space-between' className='w-full' key={d.color}>
            <FlexLayout spacing={8}>
              <div className='h-full min-h-[16px] min-w-[4px] rounded-[2px]' style={{ backgroundColor: d.color }} />
              <Numeric size='xsmall' color='primary' className='truncate'>
                {d.title}
              </Numeric>
            </FlexLayout>
            <Numeric size='xsmall' color='primary' className='truncate'>
              {d.subTitle}
            </Numeric>
          </FlexLayout>
        ))}
      </>
    );
  }, [otherData, percentage, subTitle]);

  return (
    <foreignObject x={newX} y={newY} width={300} height={300} className='p-8'>
      <div className='max-h-[76px] min-h-[76px] min-w-[200px] max-w-[250px]'>
        <Card className='h-full w-full rounded-[8px] p-8'>
          <FlexLayout className='h-full w-full' spacing={8} alignItems='start' direction='column'>
            {tooltipHeader}
            <div className='h-1 w-full bg-color-border-main' />
            {tooltipContent}
          </FlexLayout>
        </Card>
      </div>
    </foreignObject>
  );
});

export { PortfolioBreakdownChartTooltip };

/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export type Props = {
    id: string;
    width: string | number;
    height: string | number;
    alt: string;
};

const SwyftxFinderLogoMini: React.FC<Props> = ({id, width, height, alt}) => (
    <img id={id} src='/assets/images/logos/login-logo-swyftx-finder-mini.svg' width={width} height={height} alt={alt}/>
);

export {SwyftxFinderLogoMini};

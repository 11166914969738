import React, { useCallback } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { UserVerificationCard } from '@swyftx/aviary/organisms/Verification';

import { UniversalTradeStore } from '@shared/store';

import { verificationSteps } from '../data/Verification.data';
import { VerificationCardProps } from '../types';

const FirstTradeWidget: React.FC<VerificationCardProps> = ({
  completed,
  variant = 'full',
  direction = 'row',
  isCurrentStep = false,
}) => {
  const { setShowGlobalTrade } = UniversalTradeStore;

  const tradeNow = useCallback(() => {
    setShowGlobalTrade(true);
  }, [setShowGlobalTrade]);

  return (
    <UserVerificationCard
      imageType='trade'
      completed={completed}
      variant={variant}
      direction={direction}
      isCurrentStep={isCurrentStep}
      title='You’re ready to make your first trade'
      content='Your account is setup and ready to trade. If you’re new to crypto, take a tour and explore the core features of Swyftx.'
      actions={
        <FlexLayout spacing={8} alignItems='center'>
          <Button variant='filled' onClick={tradeNow} disabled={!isCurrentStep}>
            Buy now
          </Button>
        </FlexLayout>
      }
      steps={verificationSteps}
      currentStep='trade'
    />
  );
};

export { FirstTradeWidget };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const PriceAlertFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 1.87488C8.91015 1.87488 6 4.78503 6 8.37488V9.63515C6 11.8795 5.06206 14.0218 3.41289 15.5441C2.99033 15.9341 2.75 16.483 2.75 17.0581V17.5104C2.75 18.264 3.3609 18.8749 4.11449 18.8749H20.8855C21.6391 18.8749 22.25 18.264 22.25 17.5104V17.0581C22.25 16.483 22.0097 15.9341 21.5871 15.5441C19.9379 14.0218 19 11.8795 19 9.63515V8.37488C19 4.78503 16.0899 1.87488 12.5 1.87488ZM13.1503 6.91086C13.1503 6.55188 12.8593 6.26086 12.5003 6.26086C12.1414 6.26086 11.8503 6.55188 11.8503 6.91086V7.29993H11.7975C10.7588 7.29993 9.91675 8.14133 9.91675 9.17979C9.91675 10.2189 10.7585 11.0606 11.7975 11.0606H13.2035C13.5237 11.0606 13.7833 11.3203 13.7833 11.6404C13.7833 11.962 13.5233 12.2213 13.2035 12.2213H12.5349C12.5234 12.2207 12.5119 12.2204 12.5003 12.2204C12.4888 12.2204 12.4772 12.2207 12.4658 12.2213H10.919C10.56 12.2213 10.269 12.5123 10.269 12.8713C10.269 13.2303 10.56 13.5213 10.919 13.5213H11.8503V13.9037C11.8503 14.2627 12.1414 14.5537 12.5003 14.5537C12.8593 14.5537 13.1503 14.2627 13.1503 13.9037V13.5213H13.2035C14.242 13.5213 15.0833 12.6792 15.0833 11.6404C15.0833 10.6023 14.2416 9.76063 13.2035 9.76063H11.7975C11.4765 9.76063 11.2167 9.50096 11.2167 9.17979C11.2167 8.85994 11.4761 8.59993 11.7975 8.59993H12.4374C12.4581 8.60192 12.4791 8.60293 12.5003 8.60293C12.5216 8.60293 12.5426 8.60192 12.5633 8.59993H14.0821C14.4411 8.59993 14.7321 8.30891 14.7321 7.94993C14.7321 7.59094 14.4411 7.29993 14.0821 7.29993H13.1503V6.91086Z'
      fill='currentColor'
    />
    <path
      d='M9.51826 19.6744C9.82637 19.3976 10.3006 19.4229 10.5774 19.731C11.6537 20.9289 13.2624 20.9176 14.3316 19.7304C14.6088 19.4226 15.083 19.3978 15.3908 19.675C15.6986 19.9522 15.7234 20.4264 15.4462 20.7342C13.787 22.5765 11.1397 22.6011 9.46166 20.7336C9.18482 20.4255 9.21016 19.9513 9.51826 19.6744Z'
      fill='currentColor'
    />
  </svg>
);

export { PriceAlertFilled };

import { Asset, LiveRates, RateSide } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { OnReady } from '@shared/utils/lib/OnReady';

import { action, observable, untracked } from 'mobx';

import { RatesStateType, RatesStoreSchema } from './@types/ratesTypes';
import { useUserStore } from '../userStore';

const logTag = 'RATES_STORE';

const onReady = new OnReady();

const initialValues: RatesStateType = {
  rates: {},
  loading: false,
  initialised: true,
};

const store: RatesStoreSchema = observable({
  /* observables */
  ...initialValues,

  setLiveRates: action((rates: LiveRates) => {
    store.rates = rates || initialValues.rates;
    onReady.setReady(true);
  }),

  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),

  setInitialised: action((initialised: boolean) => {
    store.initialised = initialised;
  }),

  ratesOnReady: () => onReady.isReady(),

  convertRateOnce: (
    from: Asset | Asset['id'],
    to: Asset | Asset['id'],
    amount: string | number | Big,
    side?: RateSide,
  ) => untracked(() => store.convertRate(from, to, amount, side)),

  convertRate: (
    from: Asset | Asset['id'],
    to: Asset | Asset['id'],
    amount: string | number | Big,
    side?: RateSide,
  ): Big => {
    const fromAsset = assetService.getAsset(from);
    const toAsset = assetService.getAsset(to);
    let convertedAmount = Big(amount);

    if (!fromAsset || !toAsset) {
      // log if couldn't find an asset
      new SwyftxError(logTag, 'Unable to find asset to convert', { from, to, amount }).log();
    } else if (fromAsset !== toAsset) {
      const { userProfile } = useUserStore;

      if (userProfile) {
        const userCurrency = userProfile.currency.id;
        const toRate = store.getRate(toAsset.id);
        const fromRate = store.getRate(fromAsset.id);

        if (fromAsset.id === userCurrency) {
          // going from base to another means we would need buy the other asset which is the higher price (ask price)
          const rate = Big(1).div(toRate[side || 'askPrice']);
          convertedAmount = convertedAmount.times(rate);
        } else if (toAsset.id === userCurrency) {
          // going from another to base means we need to sell the other asset which is the lower price (bid price)
          convertedAmount = convertedAmount.times(fromRate[side || 'bidPrice']);
        } else {
          // going from asset to asset means we need to sell the from (bid) and buy the to (ask)
          convertedAmount = convertedAmount.times(fromRate[side || 'askPrice']);
          const rate = Big(1).div(toRate[side || 'bidPrice']);
          convertedAmount = convertedAmount.times(rate);
        }
      }
    }

    return convertedAmount;
  },

  getMinimumOrderAmount: (asset: Asset | Asset['id'], side: RateSide = 'midPrice') => {
    const { userProfile, userCountryCurrency } = useUserStore;
    // default to 1 USD
    const countryAsset = userCountryCurrency;
    const countryMin = userProfile?.minimumOrderAmount ?? 1;

    return store.convertRate(countryAsset, asset, countryMin, side);
  },

  getRateOnce: (asset: Asset | Asset['id']) => untracked(() => store.getRate(asset)),

  getRate: (asset: Asset | Asset['id']) => {
    const rateAsset = assetService.getAsset(asset);

    // temp.. hacky.. horrible.. TAUD fix as there are no TAUD rates??
    const audRate = store.rates[FiatIdEnum.AUD];
    if (audRate && rateAsset?.code === 'TAUD') return { ...audRate, dailyPriceChange: '0' };

    let rate = rateAsset && store.rates[rateAsset.id];
    if (!rate) {
      rate = {
        midPrice: '0',
        askPrice: '0',
        bidPrice: '0',
        buyLiquidityFlag: false,
        sellLiquidityFlag: false,
        dailyPriceChange: '0',
      };
    }
    return rate;
  },
});

export { store as useRatesStore };

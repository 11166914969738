import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { formatCurrency as currencyFormat } from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';

type Props = {
  baseAsset: Asset;
  value: Big;
  variant?: 'dashboard' | 'trade';
};

const PortfolioTableValue: React.FC<Props> = observer(({ baseAsset, value, variant = 'dashboard' }) => {
  const priceScale = useMemo(() => {
    if (baseAsset.assetType === AssetType.Fiat) return 2;
    return baseAsset.price_scale;
  }, [baseAsset]);

  if (variant === 'trade') {
    return (
      <FlexLayout direction='row' spacing={4} className='truncate'>
        <Numeric size='small' className='text-left'>
          {currencyFormat(value.toString(), { isFiat: baseAsset.assetType === AssetType.Fiat })}
        </Numeric>
        <Numeric size='small' className='text-left ' color='secondary'>
          {baseAsset.code}
        </Numeric>
      </FlexLayout>
    );
  }

  return (
    <Numeric size='small' className='whitespace-nowrap text-right'>
      {formatCurrency(value, baseAsset, { hideCode: true, appendCode: false, priceScale })} {baseAsset.code}
    </Numeric>
  );
});

export { PortfolioTableValue };

import React from 'react';

import { List, Rewards } from '@swyftx/aviary/icons/outlined';
import { types } from '@swyftx/marketing-utils';

import { cn } from '@shared/utils/lib/ui';

import { RewardType } from '@routes/Rewards/types';

type RewardTab = {
  label: string;
  icon: React.ReactNode;
  rewardType: RewardType;
  totalRewards: number;
};

export const rewardTabs = (
  rewards: types.UserAssetReward[],
  redeemedRewards: types.UserAssetReward[],
  selectedRewardType: RewardType,
): RewardTab[] => {
  const isSelectedRewardTypeAvailable = selectedRewardType === RewardType.Available;

  return [
    {
      label: 'available',
      icon: <Rewards className={cn('mr-8', isSelectedRewardTypeAvailable ? 'text-color-text-accent' : '')} />,
      rewardType: RewardType.Available,
      totalRewards: rewards.length,
    },
    {
      label: 'history',
      icon: <List className={cn('mr-8', isSelectedRewardTypeAvailable ? '' : 'text-color-text-accent')} />,
      rewardType: RewardType.History,
      totalRewards: redeemedRewards.length,
    },
  ];
};

import React from 'react';

type ComposableComponent<T = any> = React.FC<T> | React.ComponentType<T> | ReturnType<typeof withProps>;

interface Props {
  components: ComposableComponent[];
  children: React.ReactNode;
}

/**
 * Compose components together, passing the children of each component as the children of the next.
 */
export const Compose = (props: Props) => {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((child, Parent) => {
        if (Array.isArray(Parent)) {
          const [Component, childProps] = Parent;
          return <Component {...childProps}>{child}</Component>;
        }

        return <Parent>{child}</Parent>;
      }, children)}
    </>
  );
};

/**
 * Allows passing of a component definition & its props as an array.
 * Used to generate type-safe arguments to the Compose component.
 */
export const withProps = <P,>(
  Component: React.ComponentType<P>,
  props: Omit<P, 'children'>,
): React.FC<React.PropsWithChildren<P>> => {
  const WrappedComponent: React.FC<React.PropsWithChildren<P>> = ({ children }) => (
    <Component {...(props as P)}>{children}</Component>
  );
  WrappedComponent.displayName = `WithProps(${Component.displayName || Component.name || 'Component'})`;
  return WrappedComponent;
};

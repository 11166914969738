import React, { useCallback, useContext, useMemo } from 'react';

import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { ChartTypeArea, ChartTypeCandles } from '@swyftx/aviary/icons/charts';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { cn } from '@shared/utils/lib/ui';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { TradingViewWidgetHeaderChartType } from './TradingViewWidgetHeaderChartType';
import { TradingViewWidgetHeaderFullScreen } from './TradingViewWidgetHeaderFullScreen';
import { TradingViewWidgetHeaderIndicators } from './TradingViewWidgetHeaderIndicators';
import { TradingViewWidgetHeaderOverlays } from './TradingViewWidgetHeaderOverlays';
import { TradingViewWidgetHeaderPriceSide } from './TradingViewWidgetHeaderPriceSide';
import { TradingViewWidgetHeaderResolution } from './TradingViewWidgetHeaderResolution';
import { TradingViewWidgetHeaderScreenshot } from './TradingViewWidgetHeaderScreenshot';
import { TradingViewWidgetHeaderSettings } from './TradingViewWidgetHeaderSettings';
import { TradingViewWidgetHeaderUndoRedo } from './TradingViewWidgetHeaderUndoRedo';
import { FlexLayout } from '../../../../../../aviary/atoms/Layout/Flex';
import { TradingViewContext } from '../../context/TradingView.context';
import { useTradingViewAnalytics } from '../../hooks';
import { TradingViewChartType, TradingViewHeaderVariant, TradingViewSide } from '../../types';

type Props = {
  fullscreen: boolean;
  setFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  changeSideEnabled?: boolean;
  onPriceSideChange?: (side: TradingViewSide) => void;
};

const TradingViewHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ setFullscreen, fullscreen, changeSideEnabled = true, onPriceSideChange }, ref) => {
    const { selectedChartStyle, setSelectedChartStyle, primary, secondary } = useContext(TradingViewContext);
    const { trackChartTypeSelected } = useTradingViewAnalytics({ primary, secondary });

    const onChangeChartStyle = useCallback(
      (chartStyle: TradingViewChartType) => {
        setSelectedChartStyle(chartStyle);
        trackChartTypeSelected(chartStyle === 'advanced' ? 'TradingView' : 'Simple Charts');
      },
      [setSelectedChartStyle, trackChartTypeSelected],
    );

    const isAdvancedChart = useMemo(() => selectedChartStyle === 'advanced', [selectedChartStyle]);

    const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'tradingview-widget' });

    const headerVariant = useMemo((): TradingViewHeaderVariant => (atLeast.sm ? 'full' : 'compact'), [atLeast]);

    const renderChartOptions = useCallback(
      () => (
        <FlexLayout
          alignItems='center'
          spacing={8}
          className='py-8'
          data-spotlightelementId='tradingview-chart-options'
        >
          {isAdvancedChart && <TradingViewWidgetHeaderResolution />}
          <TradingViewWidgetHeaderChartType chartType={selectedChartStyle} variant={headerVariant} />
          {isAdvancedChart && <TradingViewWidgetHeaderIndicators variant={headerVariant} />}
          <TradingViewWidgetHeaderOverlays variant={headerVariant} />

          {isAdvancedChart && <TradingViewWidgetHeaderUndoRedo />}

          <TradingViewWidgetHeaderPriceSide
            onPriceSideChange={onPriceSideChange}
            changeSideEnabled={changeSideEnabled}
          />
        </FlexLayout>
      ),
      [changeSideEnabled, headerVariant, isAdvancedChart, onPriceSideChange, selectedChartStyle],
    );

    const renderActions = useCallback(
      () => (
        <FlexLayout
          spacing={8}
          alignItems='center'
          className='w-full py-8 @sm:w-auto'
          justifyContent='end'
          data-spotlightelementId='tradingview-chart-actions'
        >
          {isAdvancedChart && <TradingViewWidgetHeaderSettings />}
          <TradingViewWidgetHeaderScreenshot />
          <TradingViewWidgetHeaderFullScreen setFullscreen={setFullscreen} fullscreen={fullscreen} />

          <EnhancedTabs<TradingViewChartType>
            variant='default'
            size='sm'
            value={selectedChartStyle}
            onChange={onChangeChartStyle}
            tabs={[
              {
                title: 'TradingView',
                value: 'advanced',
                icon: headerVariant === 'full' ? undefined : <ChartTypeCandles />,
              },
              { title: 'Simple', value: 'simple', icon: headerVariant === 'full' ? undefined : <ChartTypeArea /> },
            ]}
          />
        </FlexLayout>
      ),
      [fullscreen, headerVariant, isAdvancedChart, onChangeChartStyle, selectedChartStyle, setFullscreen],
    );

    return (
      <FlexLayout
        ref={ref}
        className='w-full border-b border-color-border-main px-16'
        spacing={8}
        alignItems='center'
        justifyContent='space-between'
      >
        <OverlayScrollbarsComponent
          className={cn(
            'flex h-full w-full items-center justify-between gap-[1.5rem] [&>[data-overlayscrollbars-contents=true]]:flex',
          )}
          options={{
            scrollbars: {
              visibility: 'auto',
              autoHide: 'leave',
              autoHideDelay: 400,
              theme: 'tradingview-header-os-theme',
            },
          }}
        >
          {renderChartOptions()}
          {headerVariant === 'compact' && renderActions()}
        </OverlayScrollbarsComponent>
        {headerVariant === 'full' && renderActions()}
      </FlexLayout>
    );
  },
);

TradingViewHeader.displayName = 'TradingViewHeader';

export { TradingViewHeader };

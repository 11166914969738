/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';

const useToursAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();

  const trackTourStarted = useCallback(
    (tour_name: string) => {
      avo.tourStarted({
        screen: pathname,
        tourName: tour_name,
      });
    },
    [avo, pathname],
  );

  const trackTourCompleted = useCallback(
    (tour_name: string) => {
      avo.tourCompleted({
        screen: pathname,
        tourName: tour_name,
      });
    },
    [avo, pathname],
  );

  const trackTourExited = useCallback(
    (tour_name: string, step: number) => {
      avo.tourExited({
        screen: pathname,
        tourName: tour_name,
        tourStep: step,
      });
    },
    [avo, pathname],
  );

  return {
    trackTourStarted,
    trackTourCompleted,
    trackTourExited,
  };
};

export { useToursAnalytics };

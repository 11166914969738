import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Paper } from '@swyftx/aviary/icons/outlined';

import { TransactionsType } from '../../types/Transactions.types';

type Props = {
  transactionsType: TransactionsType;
};

const TransactionsTableEmpty: React.FC<Props> = ({ transactionsType }) => {
  const text = useMemo(() => {
    switch (transactionsType) {
      case 'open':
        return 'You don’t have any open orders';
      case 'recent':
        return 'You don’t have any recent orders';
      case 'all':
      default:
        return 'You don’t have any orders';
    }
  }, [transactionsType]);

  return (
    <FlexLayout
      direction='column'
      spacing={16}
      className='h-[15rem] w-full'
      alignItems='center'
      justifyContent='center'
    >
      <Paper className='h-48 w-48 text-color-text-accent' />
      <Body size='small' weight='emphasis'>
        {text}
      </Body>
    </FlexLayout>
  );
};

export { TransactionsTableEmpty };

import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import { Modal } from '@swyftx/react-web-design-system';

import Links from '@shared/constants/links';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const JumioManualDocumentsModal: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;

  const iframe = useMemo(
    () => (
      <Box
        allow='camera;fullscreen;accelerometer;gyroscope;magnetometer'
        src={`${Links.forms.manualKYC1}?ID=True&UUID=${userProfile?.intercom?.uuid}`}
        component='iframe'
        minHeight='600px'
        allowFullScreen
        height='100%'
        width='100%'
      />
    ),
    [],
  );

  return (
    <Modal open HeaderProps={{ title: 'Manual Document Verification', dismissible: true }}>
      {iframe}
    </Modal>
  );
});

export { JumioManualDocumentsModal };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Vip: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.66043 3C6.76468 3 5.92643 3.44248 5.4217 4.18276L2.47065 8.50573C1.76425 9.54177 1.8616 10.9288 2.70496 11.8568L10.0109 19.8837C10.9428 20.9085 12.555 20.9085 13.4869 19.8837L20.7934 11.8563C21.639 10.9286 21.7351 9.54266 21.0294 8.50611L18.0868 4.1837C17.5823 3.44379 16.7433 3 15.8471 3H7.66043ZM6.66105 5.02776C6.88606 4.69774 7.26015 4.5 7.66043 4.5H8.92105L7.66617 8.7141C7.66408 8.7211 7.6621 8.72813 7.66022 8.73519C7.6145 8.90692 7.57865 9.08914 7.55272 9.27734H3.76008L6.66105 5.02776ZM3.75556 10.7773C3.77418 10.8012 3.7938 10.8245 3.81443 10.8473L9.80476 17.4288L7.74312 11.6019C7.65469 11.3518 7.59069 11.0714 7.55093 10.7773H3.75556ZM13.6962 17.4254L19.6845 10.8462C19.7049 10.8238 19.7243 10.8009 19.7427 10.7773H15.9489C15.9091 11.071 15.8452 11.3511 15.7567 11.6009L13.6962 17.4254ZM19.7398 9.27734L16.8475 5.02876C16.6222 4.69847 16.247 4.5 15.8471 4.5H14.5834L15.8348 8.71465C15.837 8.72186 15.839 8.7291 15.8409 8.73637C15.8863 8.90778 15.9218 9.08959 15.9476 9.27734H19.7398ZM13.0186 4.5H10.4861L9.10712 9.13099C9.09467 9.17851 9.08337 9.22738 9.07318 9.27734H14.4274C14.4172 9.2271 14.4059 9.17794 14.3935 9.13012L13.0186 4.5ZM9.15721 11.1016C9.12274 11.0041 9.09373 10.8947 9.07047 10.7773H14.4292C14.406 10.8944 14.377 11.0034 14.3426 11.1006L11.7499 18.4294L9.15721 11.1016Z'
      fill='currentColor'
    />
  </svg>
);

export { Vip };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { UIStore, UserStore } from '@shared/store';

import UserService from '@services/UserService';

import { observer } from 'mobx-react-lite';

export const VerifyEmail: React.FC = observer(() => {
  const { t } = useTranslation('profile.verification');
  const { userProfile } = UserStore.useUserStore;
  const { addMessageBox } = UIStore.useUIStore;
  const emailVerified = Boolean(userProfile?.verification?.email);
  const { openModal } = useModal();

  const onVerifyEmail = () => {
    (async () => {
      try {
        await UserService.RequestEmailVerification();
      } catch (e) {
        // TODO error
      }
    })();
    addMessageBox({
      content: (
        <InformationMessageBox
          title={t('verificationMethod.verifyEmail.subTitle')}
          content={
            <Stack spacing={1}>
              <Typography fontSize={pxToRem(16)} color='text.secondary'>
                {t('verificationMethod.verifyEmail.labels.verifyEmail')}
              </Typography>
              <Typography fontSize={pxToRem(16)} color='text.secondary'>
                {t('verificationMethod.verifyEmail.labels.tapLink')}
              </Typography>
              <Typography PII fontSize={pxToRem(16)} color='text.secondary' fontWeight={500}>
                {userProfile?.email}
              </Typography>
            </Stack>
          }
          footer={
            <Box>
              <Button
                id='verificationMethod.verifyEmail.labels.notYourEmail'
                variant='ghost'
                color='primary'
                onClick={() => openModal(Modals.UpdateEmailAddress)}
              >
                {t('verificationMethod.verifyEmail.labels.notYourEmail')}
              </Button>
            </Box>
          }
        />
      ),
    });
  };

  return (
    <FlexLayout>
      {!emailVerified && (
        <Button
          id='verificationMethod.verifyEmail.buttonLabels.startVerification'
          color='primary'
          onClick={onVerifyEmail}
        >
          {t('verificationMethod.verifyEmail.buttonLabels.startVerification')}
        </Button>
      )}

      {emailVerified && (
        <Chip id='verificationMethod.verifyEmail.buttonLabels.verified' color='success' variant='subtle' size='sm'>
          {t('verificationMethod.verifyEmail.buttonLabels.verified')}
        </Chip>
      )}
    </FlexLayout>
  );
});

import { useCallback, useEffect } from 'react';

import { api } from '@shared/api';
import { ChartBarsParams, ChartBarsQuery } from '@shared/api/@types/charts';
import { chartService } from '@shared/services';

import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { ResolutionString } from 'public/assets/charting_library/charting_library';

import mockData from './latestBar.mock.json';

type Props = {
  side: 'bid' | 'ask';
  resolution: ResolutionString;
  secondary: string;
  primary: string;
  timeStart: DateTime;
  timeEnd: DateTime;
  mock?: boolean;
  refetchInterval?: number;
  pro?: boolean;
};

const useFetchLatestChartBar = ({
  side,
  secondary,
  primary,
  timeStart,
  timeEnd,
  resolution,
  mock = false,
  pro = false,
  refetchInterval = 5000,
}: Props) => {
  const fetchLatestChartBar = useCallback(async () => {
    const params: ChartBarsParams = {
      side,
      secondary,
      primary,
    };

    const query: ChartBarsQuery = {
      resolution: chartService.tradingToSwyftx?.[resolution],
      timeStart: timeStart.toMillis(),
      timeEnd: timeEnd.toMillis(),
      isPro: pro,
    };

    if (mock) return mockData;

    const { data } = await api.endpoints.getChartLatestBar({
      params,
      query,
    });

    return data;
  }, [mock, primary, pro, resolution, secondary, side, timeEnd, timeStart]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['latest_chart_bars', primary, secondary, resolution, side, timeStart.toMillis()],
    queryFn: () => fetchLatestChartBar(),
    keepPreviousData: true,
    staleTime: 5000,
    refetchInterval,
  });

  useEffect(() => {
    if (isStale) {
      fetchLatestChartBar();
    }
  }, [fetchLatestChartBar, isStale]);

  return {
    status,
    latestChartBar: data,
    error,
    isFetching,
    isPreviousData,
  };
};

export { useFetchLatestChartBar };

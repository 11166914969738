import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { NewsWidgetCard } from '@swyftx/aviary/complex/NewsWidgetCard';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';

import { Asset } from '@shared/api';

type Props = {
  asset: Asset;
};

const AssetNews: React.FC<Props> = ({ asset }) => {
  const { atLeast } = useTailwindContainerBreakpoint({ containerName: 'asset-news' });

  return (
    <FlexLayout
      direction='column'
      spacing={16}
      className='w-full pb-64 pt-8 @container @sm:pb-8'
      data-container='asset-news'
    >
      <NewsWidgetCard
        title={`${asset.name} news`}
        defaultNewsType='all'
        className='w-full'
        assets={[asset]}
        newsType='asset'
        atLeast={atLeast}
      />
    </FlexLayout>
  );
};

export { AssetNews };

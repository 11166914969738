import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

type Props = {
  primary?: number;
  secondary?: number;
};

const useFetchDynamicPriceAlertTriggerAssetCounts = ({ primary, secondary }: Props = {}) => {
  const { userId } = UserStore.useUserStore;

  const fetchDynamicPriceAlertsAssetCounts = useCallback(async () => {
    const response = await api.endpoints.getTriggerAlertCounts({ query: { primary, secondary } });

    return response.data;
  }, [primary, secondary]);

  const { data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['dynamic-price-alerts-trigger-counts', primary, secondary, status, userId],
    queryFn: fetchDynamicPriceAlertsAssetCounts,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { data, error, isFetching, isPreviousData, isStale };
};

export { useFetchDynamicPriceAlertTriggerAssetCounts };

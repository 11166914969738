import React from 'react';
import { useTranslation } from 'react-i18next';

import { SvgIconProps, useTheme } from '@mui/material';

import { Chip, Stack, Typography } from '@swyftx/react-web-design-system';

import {
  EmailVerificationDarkIcon,
  EmailVerificationLightIcon,
  IdentityDarkIcon,
  IdentityLightIcon,
  MobileVerificationDarkIcon,
  MobileVerificationLightIcon,
  UpdateProfileDarkIcon,
  UpdateProfileLightIcon,
  DepositDarkIcon,
  DepositLightIcon,
} from '@routes/Onboarding/assets/icons';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { OnboardingStepContainer } from './OnboardingStepContainer';

type StepPreviewProps = {
  icon: React.FC<SvgIconProps>;
  title: string;
  isComplete: boolean;
  showVerified?: boolean;
  description?: string;
};

const StepPreview = ({ icon: Icon, title, description, isComplete, showVerified }: StepPreviewProps) => {
  const showDescription = description != null && !isComplete;

  return (
    <Stack direction='row' gap='2rem' alignItems={showDescription ? 'flex-start' : 'center'} marginTop='2rem'>
      <Icon color='primary' sx={{ fontSize: '64px' }} />
      <Stack direction='row' gap='4px'>
        <Stack direction='column' gap='4px'>
          <Typography
            fontWeight={600}
            fontSize={14}
            sx={{ textDecoration: isComplete ? 'line-through' : undefined }}
            color={isComplete ? 'text.primary' : undefined}
          >
            {title}
          </Typography>
          {showDescription && (
            <Typography fontWeight={400} fontSize={14} color='text.secondary'>
              {description}
            </Typography>
          )}
        </Stack>
        {isComplete && showVerified && (
          <Chip color='success' label='Verified' size='small' sx={{ fontSize: '10px', height: '20px' }} />
        )}
      </Stack>
    </Stack>
  );
};

const QuickIntroStep: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const {
    emailVerification = false,
    mobileVerification = false,
    setupProfile = false,
    idVerification = false,
  } = useOnboardingSelector((state) => state.context.completedSteps);
  const onboardingService = useOnboardingService();

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <OnboardingStepContainer
      customTitle={t('steps.quickIntro.welcome')}
      onSubmit={(e) => {
        e.preventDefault();
        onboardingService.send({ type: 'NEXT' });
      }}
    >
      <Stack direction='column'>
        <Typography fontSize={{ sm: 14, md: 16 }} color='text.secondary'>
          {t('steps.quickIntro.subtitle')}
        </Typography>
        <StepPreview
          icon={isDarkMode ? EmailVerificationDarkIcon : EmailVerificationLightIcon}
          title={t('steps.quickIntro.emailVerification.title')}
          description={t('steps.quickIntro.emailVerification.description')}
          isComplete={emailVerification}
          showVerified
        />
        <StepPreview
          icon={isDarkMode ? MobileVerificationDarkIcon : MobileVerificationLightIcon}
          title={t('steps.quickIntro.mobileVerification.title')}
          description={t('steps.quickIntro.mobileVerification.description')}
          isComplete={mobileVerification}
          showVerified
        />
        <StepPreview
          icon={isDarkMode ? UpdateProfileDarkIcon : UpdateProfileLightIcon}
          title={t('steps.quickIntro.updateProfile.title')}
          description={t('steps.quickIntro.updateProfile.description')}
          isComplete={setupProfile}
        />
        <StepPreview
          icon={isDarkMode ? IdentityDarkIcon : IdentityLightIcon}
          title={t('steps.quickIntro.identity.title')}
          description={t('steps.quickIntro.identity.description')}
          isComplete={idVerification}
        />
        {isFeatureEnabled(AppFeature.OnboardingDepositPage) && (
          <StepPreview
            icon={isDarkMode ? DepositDarkIcon : DepositLightIcon}
            title={t('steps.quickIntro.depositFunds.title')}
            description={t('steps.quickIntro.depositFunds.description')}
            isComplete={idVerification}
          />
        )}
      </Stack>
    </OnboardingStepContainer>
  );
};

export { QuickIntroStep };

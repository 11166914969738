import { useWalletStore } from './useWalletStore';

const useHideSmallWalletBalances = () => {
  const [hideZeroBalances, setHideZeroBalances] = useWalletStore((state) => [
    state.hideZeroBalances,
    state.setHideZeroBalances,
  ]);

  return {
    hideZeroBalances,
    setHideZeroBalances,
  };
};

export { useHideSmallWalletBalances };

import { Namespace } from 'react-i18next';

import TileProps from '@global-components/Tile/Tile.types';

import { AffiliateInfoResponse, ReferralCodeResponse } from '@shared/api';
import { AffiliateHistoryResponse } from '@shared/api/@types/history';

export type ProfileSection = {
  title: string;
  translationKey?: Namespace;
  headerActionItem?: React.ReactNode;
  customSection?: React.ReactNode /** custom section overrides items */;
  items?: TileProps[];
};

export interface AffiliateHistoryEntry extends AffiliateHistoryResponse {
  gst?: string;
  accrued?: string;
  total?: string;
  loading?: boolean;
}

type ProfileStateType = {
  fetchedAffiliateInfo?: boolean;
  fetchingAffiliateInfo?: boolean;
  affiliateInfo?: AffiliateInfoResponse;
  fetchingReferralCode?: boolean;
  referralCode?: ReferralCodeResponse;
  payoutHistory?: AffiliateHistoryEntry[];
  abn?: string;
  password: string;
  confirmPassword: string;
  gstRegistered?: number;
  hasAbn?: number;
  noAbnGstReason?: number;
  error?: string;
};

type ProfileActionType = {
  setFetchedAffiliateInfo: (fetchedAffiliateInfo: boolean) => void;
  setFetchingAffiliateInfo: (fetchingAffiliateInfo: boolean) => void;
  setAffiliateInfo: (affiliateInfo?: AffiliateInfoResponse) => void;
  setFetchingReferralcode: (fetchedReferralCode: boolean) => void;
  setReferralCode: (referralCode?: ReferralCodeResponse) => void;
  setAbn: (abn: string) => void;
  setPayoutHistory: (payoutHistory: AffiliateHistoryEntry[]) => void;
  setPassword: (password: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;
  setGstRegistered: (gstRegistered: number) => void;
  setHasAbn: (hasAbn: number) => void;
  setNoAbnGstReason: (noAbnGstReason: number) => void;
  fetchAffiliateInfo: () => void;
  fetchReferralCode: () => void;
  fetchPayoutHistory: () => void;
  becomeAffiliate: (isUpdate: boolean, onSuccess?: () => void, onError?: () => void) => void;
  setError: (error: string) => void;
  cleanup: () => void;
};

interface ProfileStoreSchema extends ProfileStateType, ProfileActionType {}

const initialValues: ProfileStoreSchema = {
  fetchedAffiliateInfo: false,
  fetchingAffiliateInfo: false,
  affiliateInfo: undefined,
  fetchingReferralCode: false,
  referralCode: undefined,
  password: '',
  confirmPassword: '',
  error: '',
  setFetchedAffiliateInfo: () => {},
  setFetchingAffiliateInfo: () => {},
  setAffiliateInfo: () => {},
  setFetchingReferralcode: () => {},
  setReferralCode: () => {},
  setPayoutHistory: () => {},
  setAbn: () => {},
  setPassword: () => {},
  setConfirmPassword: () => {},
  setGstRegistered: () => {},
  setHasAbn: () => {},
  setNoAbnGstReason: () => {},
  fetchAffiliateInfo: () => {},
  fetchReferralCode: () => {},
  fetchPayoutHistory: () => {},
  becomeAffiliate: () => {},
  setError: () => {},
  cleanup: () => {},
};

export { initialValues };
export type { ProfileStoreSchema };

import React, { useMemo } from 'react';

import { Icon } from '@swyftx/aviary/atoms/Icon/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Percent, TrendLineDown, TrendLineUp } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { PriceAlertDirection, PriceAlertTriggerType } from '../../dynamicPriceAlerts.types';

type Props = {
  type: PriceAlertTriggerType;
  direction?: PriceAlertDirection;
  asset?: Asset;
  size?: number;
  showIconOnly?: boolean;
};

const DynamicPriceAlertIcon: React.FC<Props> = ({ type, direction, asset, size = 24, showIconOnly = false }) => {
  const isXs = useTailwindBreakpoint('xs');

  const alertIcon = useMemo(() => {
    if (type === 'LIMIT') {
      const isAboveDirection = direction === 'ABOVE';

      return (
        <Icon
          color={isAboveDirection ? 'success' : 'destructive'}
          icon={isAboveDirection ? <TrendLineUp /> : <TrendLineDown />}
          className={
            isXs && !showIconOnly && asset ? 'absolute right-[-7px] top-[-7px] h-18 w-18 ' : 'relative h-24 w-24'
          }
        />
      );
    } else {
      return (
        <Icon
          color='info'
          icon={<Percent />}
          className={
            isXs && !showIconOnly && asset ? 'absolute right-[-7px] top-[-7px] h-18 w-18 ' : 'relative h-24 w-24'
          }
        />
      );
    }
  }, [asset, direction, showIconOnly, isXs, type]);

  if (!isXs || !asset || showIconOnly) return alertIcon;

  return (
    <FlexLayout className='relative'>
      <AssetIcon asset={asset} size={size} />
      {alertIcon}
    </FlexLayout>
  );
};

export { DynamicPriceAlertIcon };

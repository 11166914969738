import { useCallback, useState } from 'react';

import { Asset, SortDirection } from '@shared/api';

import { PriceAlertGroupBy, PriceAlertTriggerType } from '../../dynamicPriceAlerts.types';

const useDynamicPriceAlertFilters = () => {
  const [groupBy, setGroupBy] = useState<PriceAlertGroupBy>('asset');
  const [filterTypes, setFilterTypes] = useState<PriceAlertTriggerType[]>([]);
  const [filterAssets, setFilterAssets] = useState<Asset[]>([]);
  const [dateSort, setDateSort] = useState<SortDirection>(SortDirection.DESCENDING);

  const onRemoveAllFilters = useCallback(() => {
    setFilterAssets([]);
    setFilterTypes([]);
  }, [setFilterAssets, setFilterTypes]);

  return {
    groupBy,
    setGroupBy,
    filterTypes,
    setFilterTypes,
    filterAssets,
    setFilterAssets,
    dateSort,
    setDateSort,
    onRemoveAllFilters,
  };
};

export { useDynamicPriceAlertFilters };

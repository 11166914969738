import React, { useCallback, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { Corporate } from '@shared/api';

import { CorporateWithUuid } from '@routes/EntityOnboarding/hooks/useShareholdersStep';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';

import { SupplyInformationStepComplianceDisclaimers } from '../../SupplyInformationStep/SupplyInformationStepComplianceDisclaimers';
import { CorporateShareholderSchema } from '../schemas/CorporateSchema';

interface Props {
  onCancel?: () => void;
  onSubmit: (data: Corporate) => void;
  corporate?: CorporateWithUuid;
}

type FormInputs = Omit<Corporate, 'tradesArmsOilOrPreciousMats'>;

export const AddCorporateShareholder: React.FC<Props> = (props) => {
  const { onCancel, onSubmit: onAddCorporateShareholder, corporate: corporateWithUuid } = props;
  const [hasThirdPartyCustomers, setHasThirdPartyCustomers] = useState<boolean | undefined>(
    corporateWithUuid ? false : undefined,
  );
  const [tradesArmsOilOrPreciousMats, setTradesArmsOilOrPreciousMats] = useState<boolean | undefined>(
    corporateWithUuid?.corporate.tradesArmsOilOrPreciousMats ?? undefined,
  );

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      type: 'CORPORATE',
      name: corporateWithUuid?.corporate.name,
      businessActivity: corporateWithUuid?.corporate.businessActivity,
    },
    resolver: zodResolver(CorporateShareholderSchema, undefined, { rawValues: true }),
  });

  const [name, businessActivity] = watch(['name', 'businessActivity']);

  const handleSubmit = useCallback(() => {
    const corporateShareholder: Corporate = {
      type: 'CORPORATE',
      name: watch('name'),
      businessActivity: watch('businessActivity'),
      tradesArmsOilOrPreciousMats: tradesArmsOilOrPreciousMats ?? false,
    };
    onAddCorporateShareholder(corporateShareholder);
  }, [onAddCorporateShareholder, tradesArmsOilOrPreciousMats, watch]);

  const submitDisabled =
    !!Object.keys(errors).length ||
    !name ||
    !businessActivity ||
    tradesArmsOilOrPreciousMats === undefined ||
    hasThirdPartyCustomers;

  return (
    <FlexLayout direction='column' spacing={24}>
      {!corporateWithUuid && (
        <FlexLayout direction='row' className='w-full items-center justify-between'>
          <Heading size='h6'>Add corporate shareholder</Heading>
        </FlexLayout>
      )}

      <FlexLayout direction='column' spacing={4} className='w-full'>
        <Body size='small' weight='emphasis'>
          Name of business:
        </Body>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <Input value={field.value} onChange={(e) => field.onChange(e.target.value)} error={!!errors.name} />
          )}
        />
        {errors.name && (
          <Body size='small' color='error'>
            {errors.name.message}
          </Body>
        )}
      </FlexLayout>
      <FlexLayout direction='column' spacing={4} className='w-full'>
        <Body size='small' weight='emphasis'>
          Nature of business:
        </Body>
        <Controller
          name='businessActivity'
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              error={!!errors.businessActivity}
            />
          )}
        />
        {errors.businessActivity && (
          <Body size='small' color='error'>
            {errors.businessActivity.message}
          </Body>
        )}
      </FlexLayout>
      <SupplyInformationStepComplianceDisclaimers
        entityType='company'
        isShareholder
        hideQuestionNumbers
        hasThirdPartyCustomers={hasThirdPartyCustomers}
        setHasThirdPartyCustomers={setHasThirdPartyCustomers}
        tradesArmsOilOrPreciousMats={tradesArmsOilOrPreciousMats}
        setTradesArmsOilOrPreciousMats={setTradesArmsOilOrPreciousMats}
      />
      <FlexLayout direction='row' className='w-full items-center justify-end' spacing={8}>
        <Button variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={submitDisabled}>
          {corporateWithUuid ? 'Save' : 'Add business'}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import { useEffect, useState } from 'react';

import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';

import { useIdleTimer } from 'react-idle-timer';

import SessionTimeout from './SessionTimeout';

export const IdleSessionTimeout = () => {
  const timeout = Number(window.localStorage.getItem(StorageKey.AUTO_LOGOUT) || '30');

  const [remaining, setRemaining] = useState<number>(timeout);
  const { isLoggedIn } = UserStore.useUserStore;
  const logout = useLogout();

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    onIdle: logout,
    timeout: 1000 * 60 * timeout,
    promptBeforeIdle: 60_000,
    throttle: 1000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  if (!isLoggedIn || !isPrompted()) return null;

  return <SessionTimeout id='session-timeout' timeoutRemaining={remaining} onDismiss={activate} onLogout={logout} />;
};

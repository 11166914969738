import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { assetService } from '@shared/services';

type Props = {
  assetList: string[];
};

// icon used to display additional number of assets when we have a fixed length of asset icons we want to show
const AssetPlusMoreIcon: React.FC<Props> = ({ assetList }) => (
  <Tooltip
    align='end'
    side='bottom'
    content={assetList.map((asset) => {
      const assetDetails = assetService?.getAssetByCode(asset);
      return (
        <Body color='white' key={asset}>
          {assetDetails?.name ?? asset}
        </Body>
      );
    })}
  >
    <FlexLayout className='m-4 h-20 w-20 cursor-pointer overflow-hidden' alignItems='center'>
      <Body color='accent' weight='emphasis' size={assetList.length > 9 ? 'small' : 'medium'}>
        +{assetList.length}
      </Body>
    </FlexLayout>
  </Tooltip>
);

export { AssetPlusMoreIcon };
export default AssetPlusMoreIcon;

import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { cn } from '@shared/utils/lib/ui';

import { EventCallbackInterface, VictoryPie, VictorySharedEvents, VictoryTooltip } from 'victory';

import { DonutChartItem } from './DonutChart.types';
import { FlexLayout } from '../../Layout/Flex';

type Props = {
  id: string;
  data: DonutChartItem[];
  innerRadius?: number;
  showTooltip?: boolean;
  legend?: React.ReactElement;
  startAngle?: number;
  endAngle?: number;
  alignment?: 'start' | 'center' | 'end';
  height?: number;
  tooltipElement?: React.ReactElement;
  className?: string;
  noDataElement?: React.ReactElement;
  loading?: boolean;
  cornerRadius?: number;
  gap?: number;
  externalMutations?: EventCallbackInterface<string | string[], string | number | (string | number)[]>[];
  setExternalMutations: React.Dispatch<
    React.SetStateAction<EventCallbackInterface<string | string[], string | number | (string | number)[]>[] | undefined>
  >;
};

const DEFAULT_COLOR = 'var(--color-background-surface-active)';

const DonutChart: React.FC<PropsWithChildren<Props>> = ({
  id,
  legend,
  showTooltip = true,
  innerRadius = 136,
  data,
  cornerRadius = 60,
  gap = 2,
  loading = false,
  children,
  startAngle = 0,
  endAngle = 360,
  noDataElement,
  height = 400,
  tooltipElement,
  externalMutations,
  className,
  alignment = 'center',
  setExternalMutations,
}) => {
  const { theme } = useTailwindTheme();

  const colorScale = useMemo(
    () => (!data.length || loading ? ['var(--color-background-neutral)'] : data.map((d) => d.color || DEFAULT_COLOR)),
    [data, loading],
  );

  useEffect(() => {
    // Only run these mutations if we have a data object with no color set
    if (data.find((d) => !d.color)) {
      setExternalMutations([
        {
          childName: [id],
          target: ['data'],
          eventKey: 'all',
          mutation: ({ style, index }) => ({
            style: { ...style, fill: data[index].color || DEFAULT_COLOR },
          }),
        },
      ]);
    }
  }, [data, id, setExternalMutations, theme]);

  return (
    <FlexLayout
      className={cn(' w-full flex-col @md:flex-row', className)}
      alignItems='center'
      justifyContent='center'
      spacing={24}
    >
      <FlexLayout
        id={id}
        className={cn('w-full items-center', {
          '@md:w-1/2': legend,
          'animate-pulse': loading,
        })}
        alignItems={alignment}
        justifyContent='center'
      >
        <VictorySharedEvents
          externalEventMutations={externalMutations}
          events={[
            {
              childName: id,
              target: 'data',
              eventHandlers: {
                onMouseEnter: () => [
                  {
                    mutation: ({ style }) => ({
                      style: { ...style, strokeWidth: 12, strokeOpacity: 0.2, stroke: style.fill },
                    }),
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: true }),
                  },
                ],
                onMouseLeave: () => [
                  {
                    mutation: ({ style }) => ({ style: { ...style, strokeWidth: 0, stroke: 'white' } }),
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: false }),
                  },
                ],
              },
            },
          ]}
        >
          <VictoryPie
            name={id}
            padAngle={() => gap}
            startAngle={startAngle}
            endAngle={endAngle}
            colorScale={colorScale}
            cornerRadius={cornerRadius}
            labels={data.map(() => '')}
            labelComponent={
              showTooltip && tooltipElement && !loading && data.length > 0 ? (
                <VictoryTooltip flyoutComponent={tooltipElement} />
              ) : undefined
            }
            style={{
              data: {
                fillOpacity: 1,
                cursor: 'pointer',
              },
            }}
            height={height}
            width={height}
            innerRadius={innerRadius - 8}
            radius={(height - 42) / 2}
            data={data.length > 0 ? data : [{ y: 100 }]}
          />
        </VictorySharedEvents>
      </FlexLayout>
      {!loading && data.length > 0 && legend && <div className='mt-16 w-full @md:mt-0 @md:w-1/2'>{legend}</div>}
      {!loading && !data.length && legend && <div className='mt-16 w-full @md:w-1/2'>{noDataElement}</div>}

      <FlexLayout className='full-width absolute flex items-center justify-center'>{children}</FlexLayout>
    </FlexLayout>
  );
};

export { DonutChart };

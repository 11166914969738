import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  preventCloseOnSelect?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const ListItem = React.forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ className, children, disabled, onClick, preventCloseOnSelect, ...props }, ref) => (
    <button
      ref={ref}
      className={cn(
        !disabled
          ? 'focus:focus-area-info cursor-pointer  hover:bg-color-background-surface-hover'
          : 'cursor-auto !text-color-text-disabled',
        'flex min-h-[40px] w-full flex-row items-center rounded-12 px-0 py-8 sm:px-12',
        className,
      )}
      {...props}
      disabled={disabled}
      onClick={(e) => {
        if (onClick) onClick();
        if (preventCloseOnSelect) {
          e.preventDefault;
        }
      }}
    >
      {children}
    </button>
  ),
);

ListItem.displayName = 'ListItem';

export { ListItem };

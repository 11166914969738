import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Funnel: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.56517 2.25C3.27545 2.25 2.25 3.31015 2.25 4.5904V5.52644C2.25 6.36625 2.56924 7.17545 3.14301 7.78558L7.98875 12.9381C8.00801 12.9586 8.02829 12.9779 8.04947 12.9959C8.8168 13.8073 9.24905 14.8918 9.24905 16.0233V20.5952C9.24905 21.4481 10.1583 22.0363 10.9419 21.6107L13.703 20.1061C14.364 19.7451 14.7701 19.0485 14.7701 18.2984V16.0114C14.7701 14.8618 15.2147 13.7606 16.0043 12.9466L20.8587 7.78491C21.4309 7.17502 21.75 6.36617 21.75 5.52644V4.5904C21.75 3.31082 20.7263 2.25 19.4359 2.25H4.56517ZM9.07375 11.897C9.05944 11.8824 9.04461 11.8684 9.02929 11.855L4.2357 6.75795C3.92515 6.42771 3.75 5.98668 3.75 5.52644V4.5904C3.75 4.11557 4.1267 3.75 4.56517 3.75H19.4359C19.8737 3.75 20.25 4.1149 20.25 4.5904V5.52644C20.25 5.7782 20.1974 6.02447 20.0991 6.25H10C9.58579 6.25 9.25 6.58579 9.25 7C9.25 7.41421 9.58579 7.75 10 7.75H18.8324L14.9235 11.9064C13.8618 13.0031 13.2701 14.4784 13.2701 16.0114V18.2984C13.2701 18.5081 13.1561 18.6955 12.9841 18.7896L10.7491 20.0075V16.0233C10.7491 14.4788 10.147 12.9957 9.07375 11.897Z'
      fill='currentColor'
    />
  </svg>
);

export { Funnel };

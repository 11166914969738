import { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { BuyButton } from '@global-components/BuySellButtons';

import { Category } from '@shared/api/@types/categories';
import { Big } from '@shared/safe-big';

import { CategoriesListGainersLosers } from '@routes/AssetsList/subroutes/CategoriesList/CategoriesListGainersLosers';

import { CategoryIcons } from 'src/context/Categories/Categories.icons';
import { useCategories, useCategoryPerformance, useCategoryRank } from 'src/lib/categories/hooks';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types';

import { useMarkets } from './useMarkets';
import { CategoriesTableData } from '../types/Markets.types';

type Props = {
  widgetId?: DashboardWidgets;
};

type CaetgoriesHeaderData = { [key in keyof CategoriesTableData]: EnhancedTableHeaderData };

const useCategoriesTable = ({ widgetId }: Props = {}) => {
  const { categories } = useCategories();
  const { getCategoryRank } = useCategoryRank();
  const { getMetrics } = useCategoryPerformance();
  const { getAssetsByCategoryId } = useMarkets();

  const [sortCategories, setSortCategories] = useDashboardSyncState<EnhancedTableSort<CategoriesTableData>>({
    widgetId,
    stateKey: 'sortCategories',
    defaultValue: { sortKey: 'marketCap', sortDirection: 'DESC' },
  });

  const headers: CaetgoriesHeaderData = useMemo(
    () => ({
      rank: {
        title: 'Rank',
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
        frozen: true,
        insetLeft: true,
        showFrozenDivider: false,
      },
      category: {
        title: 'Category',
        alignment: 'start',
        sortable: true,
        className: 'table-cell',
        frozen: true,
        showFrozenDivider: true,
      },
      numAssets: {
        title: '# Assets',
        alignment: 'start',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      gainersLosers: {
        title: 'Gainers vs Losers',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell',
      },
      dailyChange: {
        title: '24hr Change',
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      weeklyChange: {
        title: '7d Change',
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      monthlyChange: {
        title: '30d Change',
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      dailyVolume: {
        title: '24hr Volume',
        alignment: 'end',
        sortable: true,
        className: 'hidden @md:table-cell',
      },
      marketCap: {
        title: 'Market Cap',
        alignment: 'end',
        sortable: true,
        className: 'hidden @sm:table-cell',
      },
      trade: {
        title: 'Trade',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell',
      },

      marketCapChange: {
        title: 'Market cap',
        alignment: 'end',
        sortable: false,
        className: 'table-cell @sm:hidden',
      },
    }),
    [],
  );

  const data: EnhancedTableData<CategoriesTableData, Category>[] = useMemo(() => {
    if (!categories) return [];

    return categories
      ?.filter((c) => getAssetsByCategoryId(c.id).length > 0)
      .map((category) => {
        const rank = getCategoryRank(category);
        const { dailyChange, dailyVolume, weeklyChange, monthlyChange, marketCap } = getMetrics(category);
        const assets = getAssetsByCategoryId(category.id);

        return {
          value: category,
          rank: {
            value: rank,
            element: (
              <FlexLayout alignItems='center' justifyContent='start'>
                <Numeric size='small'>{rank}</Numeric>
              </FlexLayout>
            ),
          },
          category: {
            value: category.name,
            element: (
              <FlexLayout alignItems='center' justifyContent='start'>
                {CategoryIcons(undefined, true)[category.name]}
                <Body size='small' weight='emphasis' className='max-w-[180px] truncate'>
                  {category.name}
                </Body>
              </FlexLayout>
            ),
          },
          numAssets: {
            value: assets.length,
            element: (
              <FlexLayout alignItems='center' justifyContent='start'>
                <Numeric className='text-end' size='small'>
                  {assets.length}
                </Numeric>
              </FlexLayout>
            ),
          },
          gainersLosers: {
            value: category.name,
            element: (
              <FlexLayout alignItems='center' justifyContent='end'>
                <CategoriesListGainersLosers assets={assets} />
              </FlexLayout>
            ),
          },
          dailyChange: {
            value: dailyChange,
            element: (
              <FlexLayout alignItems='center' justifyContent='end'>
                <NumericDataItem data={`${dailyChange}%` || ''} renderIcons={false} size='small' percentage />
              </FlexLayout>
            ),
          },
          weeklyChange: {
            value: weeklyChange,
            element: (
              <FlexLayout alignItems='center' justifyContent='end'>
                <NumericDataItem data={`${weeklyChange}%` || ''} renderIcons={false} size='small' percentage />
              </FlexLayout>
            ),
          },
          monthlyChange: {
            value: monthlyChange,
            element: (
              <FlexLayout alignItems='center' justifyContent='end'>
                <NumericDataItem data={`${monthlyChange}%` || ''} renderIcons={false} size='small' percentage />
              </FlexLayout>
            ),
          },
          dailyVolume: {
            value: dailyVolume,
            element: (
              <FlexLayout alignItems='center' justifyContent='end'>
                <Numeric className='text-end' size='small'>
                  {dailyVolume}
                </Numeric>
              </FlexLayout>
            ),
          },
          marketCap: {
            value: marketCap,
            element: (
              <FlexLayout alignItems='center' justifyContent='end'>
                <Numeric className='text-end' size='small'>
                  {marketCap}
                </Numeric>
              </FlexLayout>
            ),
          },
          trade: {
            value: category.name,
            element: <BuyButton assets={assets} variant='aviary' size='sm' buttonVariant='outlined' />,
          },

          marketCapChange: {
            value: marketCap,
            element: (
              <FlexLayout alignItems='end' direction='column' justifyContent='end'>
                <Numeric className='text-end' size='small'>
                  {marketCap}
                </Numeric>
                <NumericDataItem data={`${dailyChange}%` || ''} size='small' percentage />
              </FlexLayout>
            ),
          },
        };
      });
  }, [categories, getAssetsByCategoryId, getCategoryRank, getMetrics]);

  const onSort = useCallback(
    (sort?: EnhancedTableSort<CategoriesTableData>) => {
      const tableData: EnhancedTableData<CategoriesTableData, Category>[] = Object.assign([], data);
      if (!sort) return tableData;

      setSortCategories(sort);

      return tableData.sort((a, b) => {
        switch (sort.sortKey) {
          case 'category':
            const aVal = a[sort.sortKey].value as string;
            const bVal = b[sort.sortKey].value as string;
            if (sort.sortDirection === 'ASC') return bVal.localeCompare(aVal);
            return aVal.localeCompare(bVal);
          case 'rank':
          case 'dailyChange':
          case 'weeklyChange':
          case 'monthlyChange':
          case 'dailyVolume':
          case 'numAssets':
          case 'marketCap':
            const aValBig = Big(a[sort.sortKey].value as string);
            const bValBig = Big(b[sort.sortKey].value as string);

            if (sort.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
            return bValBig.lt(aValBig) ? -1 : 1;
          default:
            return 1;
        }
      });
    },
    [data, setSortCategories],
  );

  return {
    initialSort: sortCategories,
    headers,
    data,
    onSort,
  };
};

export { useCategoriesTable };

export enum AccountStatusEnum {
  BRONZE = 1,
  BRONZE_RESTRICTED = 2,
  SILVER = 3,
  SILVER_RESTRICTED = 4,
  GOLD = 5,
  GOLD_RESTRICTED = 6,
  DIAMOND = 7,
  DIAMOND_RESTRICTED = 8,
  GOLD_NZ = 9,
  GOLD_NZ_RESTRICTED = 10,
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { VerifyPhoneModal } from '@global-components/Modals/VerifyPhoneModal';

import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

export const VerifyPhone: React.FC = observer(() => {
  const { userProfile } = UserStore.useUserStore;
  const { t } = useTranslation('profile.verification');
  const [showVerifyPhoneNumber, setShowVerifyPhoneNumber] = useState<boolean>(false);
  const phoneVerified = Boolean(userProfile?.verification?.phone);

  return (
    <>
      <FlexLayout>
        {!phoneVerified && (
          <Button
            id='verificationMethod.verifyPhone.buttonLabels.startVerification'
            color='primary'
            onClick={() => setShowVerifyPhoneNumber(true)}
          >
            {t('verificationMethod.verifyPhone.buttonLabels.startVerification')}
          </Button>
        )}

        {phoneVerified && (
          <Chip id='verificationMethod.verifyPhone.buttonLabels.verified' color='success' size='sm' variant='subtle'>
            {t('verificationMethod.verifyPhone.buttonLabels.verified')}
          </Chip>
        )}
      </FlexLayout>

      {showVerifyPhoneNumber && <VerifyPhoneModal onClose={() => setShowVerifyPhoneNumber(false)} />}
    </>
  );
});

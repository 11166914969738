import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Utility } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';

type Props = {
  baseAsset: Asset;
};

const SwyftxProOrderBookBookHeader: React.FC<Props> = ({ baseAsset }) => (
  <FlexLayout className='relative w-full p-8' alignItems='center' justifyContent='space-between' spacing={8}>
    <Utility color='secondary' className='w-5/12 whitespace-nowrap text-left'>
      Price ({baseAsset.code})
    </Utility>
    <Utility color='secondary' className='w-2/12 whitespace-nowrap text-right'>
      Change (%)
    </Utility>
    <Utility color='secondary' className='w-5/12 whitespace-nowrap text-right'>
      Total ({baseAsset.code})
    </Utility>
  </FlexLayout>
);

export { SwyftxProOrderBookBookHeader };

import { useCallback } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  AppFeature,
  AppFeatureData,
  AppFeatureDataType,
  getDefaultAppFeature,
  getDefaultAppFeatureData,
} from './featureFlags.data';

const useIsFeatureEnabled = () => {
  const flags = useFlags();

  const isFeatureEnabled = useCallback(
    (feature: AppFeature, value: boolean | string = true): boolean => {
      if (!flags || !Object.keys(flags).includes(feature)) return getDefaultAppFeature(feature);

      return flags[feature] === value;
    },
    [flags],
  );

  const getFeatureData = useCallback(
    <T extends AppFeatureData>(feature: T): AppFeatureDataType<T> => {
      if (!flags) return getDefaultAppFeatureData(feature);
      return flags[feature] ?? getDefaultAppFeatureData(feature);
    },
    [flags],
  );

  return { isFeatureEnabled, getFeatureData };
};

export { useIsFeatureEnabled };

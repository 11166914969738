import { useCallback, useMemo } from 'react';

import { UserStore } from '@shared/store';

import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

import { useBuildDashboardLayouts } from './useBuildDashboardLayouts';
import { DashboardLayoutKeys } from '../types/Dashboard.types';

const useDashboardLayout = () => {
  const { userProfile } = UserStore.useUserStore;
  const { isVerified, hasDeposited } = useProfileStatus();
  const { layouts } = useBuildDashboardLayouts();

  const getLayout = useCallback((key: DashboardLayoutKeys) => layouts.find((dl) => dl.key === key), [layouts]);

  const layoutKey = useMemo(
    () => userProfile?.userSettings.dashboardSettings?.layout?.layoutKey,
    [userProfile?.userSettings.dashboardSettings?.layout?.layoutKey],
  );

  // Determine which layout we should be showing to the user
  const layout = useMemo(() => {
    // if we aren't verified and we haven't deposited, default to the User Verification layout
    if (!isVerified && !hasDeposited) return getLayout('everything');

    // if we already have a layout key set, just use it
    if (layoutKey) return getLayout(layoutKey);

    // If we have never seen the old layout, default to the everything layout
    if (userProfile?.userSettings.dashboardSettings?.tiles === undefined) {
      return getLayout('everything');
    }

    return getLayout('classic');
  }, [getLayout, hasDeposited, isVerified, layoutKey, userProfile?.userSettings.dashboardSettings?.tiles]);

  return { layout, layouts };
};

export { useDashboardLayout };

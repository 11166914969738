import React, { useMemo } from 'react';

import { Cross, Danger, Error, Information, TickInCircle } from '@swyftx/aviary/icons/outlined';

import { useInterval } from 'react-use';

import { Button } from '../Button';
import { Card } from '../Card';
import { FlexLayout } from '../Layout/Flex';
import { Body } from '../Typography';

type Props = {
  title: string;
  description?: string;
  type?: 'info' | 'success' | 'destructive' | 'warning';
  durationMs?: number;
  isDismissable?: boolean;
  actions?: React.ReactElement;
  onDismiss?: () => void;
};

const Toast: React.FC<Props> = ({ title, type, description, durationMs = 5000, isDismissable, actions, onDismiss }) => {
  useInterval(() => {
    if (onDismiss) onDismiss();
  }, durationMs);

  const icon = useMemo(() => {
    switch (type) {
      case 'info':
        return <Information className='min-h-20 min-w-20 mt-2 text-color-text-info' />;
      case 'success':
        return <TickInCircle className='min-h-20 min-w-20 mt-2 text-color-text-success' />;
      case 'destructive':
        return <Error className='min-h-20 min-w-20 mt-2 text-color-text-error' />;
      case 'warning':
        return <Danger className='min-h-20 min-w-20 mt-2 text-color-text-warning' />;
    }
  }, [type]);

  return (
    <Card className='relative max-w-[520px] p-16'>
      <FlexLayout alignItems='start' justifyContent='space-between' spacing={12} className='w-full'>
        <FlexLayout alignItems='center' spacing={12} className='w-full'>
          {icon}
          <FlexLayout direction='column' spacing={4}>
            <Body weight='emphasis' size='small' color='primary' className='truncate'>
              {title}
            </Body>
            {description && (
              <Body size='small' color='secondary'>
                {description}
              </Body>
            )}
            {actions}
          </FlexLayout>
        </FlexLayout>
        {isDismissable && <Button size='md' variant='ghost' onClick={onDismiss} leadingIcon={<Cross />} />}
      </FlexLayout>
    </Card>
  );
};

export { Toast };

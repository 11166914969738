import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { observer } from 'mobx-react-lite';

type Props = {
  chartId: string;
  [key: string]: any;
};

const AutoInvestDonutChartTooltip: React.FC<Props> = observer((props: Props) => {
  const {
    center: { x, y },
    datum: { title, icon, percentage, color },
  } = props;

  if (!title) return null;

  return (
    <foreignObject x={x} y={y} width={300} height={300} className='p-8'>
      <div className='max-h-[48px] min-h-[48px] min-w-[136px] max-w-[136px]'>
        <Card className='h-full w-full rounded-[8px] p-8'>
          <FlexLayout className='h-full w-full' spacing={8} alignItems='center' justifyContent='space-between'>
            <FlexLayout spacing={8} alignItems='center' justifyContent='start'>
              <div className='h-full min-h-[36px] min-w-[4px] rounded-[2px]' style={{ backgroundColor: color }} />
              <FlexLayout direction='column' spacing={0}>
                <Numeric size='medium' weight='emphasis'>
                  {title}
                </Numeric>
                <Numeric size='xsmall' color='secondary'>
                  {percentage}
                </Numeric>
              </FlexLayout>
            </FlexLayout>

            {icon}
          </FlexLayout>
        </Card>
      </div>
    </foreignObject>
  );
});

export { AutoInvestDonutChartTooltip };

import React from 'react';

import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  limits: string;
  fees: string;
  successfulDeposits?: string;
};
const DepositFundsLimitAndFees: React.FC<Props> = ({ limits, fees, successfulDeposits }) => (
  <>
    <Separator orientation='horizontal' className='w-full' />
    <Body weight='bold' size='medium' color='primary' className='mb-8 mt-16'>
      Limits & fees
    </Body>
    {successfulDeposits && (
      <>
        <Body weight='bold' size='small' color='primary' className='mb-8'>
          Successful deposits
        </Body>
        <Body size='small' color='secondary' className='my-8'>
          {successfulDeposits}
        </Body>
      </>
    )}
    <Body weight='bold' size='small' color='primary' className='mb-8'>
      Limits
    </Body>
    <Body className='mb-8' size='small' color='secondary'>
      {limits}
    </Body>
    <Body weight='bold' size='small' color='primary' className='mb-8'>
      Fees
    </Body>
    <Body size='small' color='secondary' className='my-8'>
      {fees}
    </Body>
  </>
);

export { DepositFundsLimitAndFees };

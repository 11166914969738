import React, { useCallback, useEffect, useRef, useState } from 'react';

import { cn } from '@shared/utils/lib/ui';

import { useTimeoutEffect } from '@react-hookz/web';

import { CarouselItemType } from './Carousel.types';
import { CarouselItem } from './CarouselItem';
import { CarouselProgress } from './CarouselProgress';
import { FlexLayout } from '../Layout/Flex';

type Props = {
  items: CarouselItemType[];
  durationMs?: number; // defaults to 4s
  className?: string;
  itemClassName?: string;
};

const Carousel: React.FC<Props> = ({ items, durationMs = 4000, className, itemClassName }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const transitionNone = useRef<boolean>(false);

  const calculateItemClass = useCallback(
    (index: number) => {
      if (index === currentIndex - 1 || (index === items.length - 1 && currentIndex === 0)) return '-translate-x-full';
      if (index === currentIndex) return 'translate-x-0';
      return 'translate-x-full transition-none';
    },
    [currentIndex, items.length],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, reset] = useTimeoutEffect(() => {
    if (currentIndex === items.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }, durationMs);

  useEffect(() => {
    reset();
  }, [currentIndex, reset]);

  return (
    <FlexLayout
      direction='column'
      className={cn('h-full w-full p-16', className)}
      alignItems='center'
      justifyContent='center'
    >
      <div className='relative h-full w-full flex-nowrap overflow-hidden'>
        {items.map((item, index) => (
          <div
            key={item.key}
            className={cn(
              'absolute h-full w-full transition-transform duration-1000',
              transitionNone.current && currentIndex !== index ? 'translate-x-full transition-none ' : '',
              calculateItemClass(index),
            )}
          >
            <CarouselItem item={item} className={cn(itemClassName)} />
          </div>
        ))}
      </div>
      <CarouselProgress
        items={items}
        durationMs={durationMs}
        currentIndex={currentIndex}
        setCurrentIndex={(e) => {
          transitionNone.current = true;
          setCurrentIndex(e);
          window.setTimeout(() => {
            transitionNone.current = false;
          }, 500);
        }}
      />
    </FlexLayout>
  );
};

export { Carousel };

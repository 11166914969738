import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { TransferModalStep } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';

import { AssetTransactionHistoryModal } from '@global-components/Modals/AssetTransactionHistoryModal';
import { Modals } from '@global-components/Modals/Modals.enum';
import { FiatWithdrawalCreateNewAccountModal } from '@global-components/Modals/WithdrawFunds/FiatWithdrawal/FiatWithdrawalCreateNewAccountModal/FiatWithdrawalCreateNewAccountModal';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetType } from '@shared/api/@types/markets';

import { WithdrawalBlocked } from '@hooks/Withdraw/useWithdrawalPermission';
import { TransactionTypeFilter } from '@services/WalletService';

import { WithdrawalLimit } from '@Wallet/components/WithdrawalLimit';

import { useFetchAssetAddresses } from 'src/lib/assets/hooks/useFetchAssetAddresses';

import { SingleWalletSendInfo } from '../../../SingleWalletSend/components/SingleWalletSendInfo';

type Props = {
  asset: Asset;
  withdrawDisabled: boolean;
  withdrawalBlockedStatus: WithdrawalBlocked;
};

const SingleWalletFiatWithdrawalWithdraw: React.FC<Props> = ({ asset, withdrawDisabled, withdrawalBlockedStatus }) => {
  const { t } = useTranslation('wallet');
  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState<boolean>(false);
  const [showWithdrawHistory, setShowWithdrawHistory] = useState(false);

  const { data: addresses, isFetching: loading } = useFetchAssetAddresses({ asset });
  const { openModal } = useModal();

  const hasWithdrawAddresses = useMemo(() => addresses.length > 0, [addresses.length]);

  const withdrawalsBlocked = withdrawalBlockedStatus.blocked;

  const handleCloseModal = () => {
    setShowCreateNewAccountModal(false);
  };

  const createAccountButton = () => (
    <Button
      variant='ghost'
      className='w-full'
      onClick={() =>
        openModal(Modals.WithdrawSend, {
          initialStep: TransferModalStep.ManageAccounts,
          selectedAsset: asset,
        })
      }
    >
      Add new account
    </Button>
  );

  const manageAccountsButton = () => (
    <Button
      className='w-full'
      color='accent'
      variant='ghost'
      onClick={() =>
        openModal(Modals.WithdrawSend, {
          initialStep: TransferModalStep.ManageAccounts,
          selectedAsset: asset,
        })
      }
    >
      Manage addresses
    </Button>
  );

  return (
    <>
      <FlexLayout direction='column' spacing={16}>
        {!withdrawDisabled && !withdrawalsBlocked && (
          <SingleWalletSendInfo assetType={AssetType.Fiat} assetCode={asset.code} />
        )}

        {!withdrawDisabled && !withdrawalsBlocked && (
          <>
            <WithdrawalLimit />
            <Button
              size='lg'
              className='w-full'
              onClick={() => openModal(Modals.WithdrawSend, { selectedAsset: asset })}
            >
              Withdraw funds
            </Button>
          </>
        )}

        <FlexLayout direction='row' alignItems='center' spacing={16} className='p-4'>
          <div className='h-full w-full'>
            {loading && <div className='h-full w-full animate-pulse bg-color-background-surface-secondary' />}
            {!loading && (hasWithdrawAddresses ? manageAccountsButton() : createAccountButton())}
          </div>
          <Button variant='ghost' onClick={() => setShowWithdrawHistory(true)} className='w-full'>
            {t('singleWalletFiatDeposit.selector.buttonLabels.withdrawHistory')}
          </Button>
        </FlexLayout>
      </FlexLayout>

      {/* create new account */}
      {showCreateNewAccountModal && <FiatWithdrawalCreateNewAccountModal asset={asset} onClose={handleCloseModal} />}

      {showWithdrawHistory && (
        <AssetTransactionHistoryModal
          asset={asset}
          type={TransactionTypeFilter.WITHDRAWAL}
          onClose={() => setShowWithdrawHistory(false)}
        />
      )}
    </>
  );
};

export { SingleWalletFiatWithdrawalWithdraw };

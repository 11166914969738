import React from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  icon: React.ReactElement;
  title: string;
  content: string;
  direction: 'row' | 'column';
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
};

const GettingStartedCard: React.FC<Props> = ({ icon, title, content, onClick, direction, style, className }) => (
  <Card
    style={style}
    className={cn(
      `min-w-[96%] cursor-pointer p-16 hover:bg-color-background-surface-hover @sm:min-w-[calc(33.33%-18px)] @sm:p-24 @lg:min-w-[calc(25%-18px)] `,
      className,
    )}
    onClick={onClick}
  >
    <FlexLayout spacing={16} direction={direction}>
      {icon}
      <FlexLayout direction='column' spacing={8}>
        <Body weight='emphasis' className='whitespace-wrap'>
          {title}
        </Body>
        <Body size='xsmall' color='secondary' className='min-h-[50px]'>
          {content}
        </Body>
      </FlexLayout>
    </FlexLayout>
  </Card>
);

export { GettingStartedCard };

import { useMemo } from 'react';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { useMarketsFilterSort } from 'src/lib/markets/hooks/useMarketsFilterSort';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AssetsSearchBalances } from './AssetsSearchBalances';
import { GlobalSearchItemType } from '../../search.types';

const useOwnedAssetsSearchModule = (): GlobalSearchItemType[] => {
  const { navigate } = useNavigateRoute();
  const { filteredAndSortedAssets } = useMarketsFilterSort({
    filterType: 'owned',
    sort: { sortKey: 'rank', sortDirection: 'DESC' },
  });

  const items = useMemo(
    (): GlobalSearchItemType[] =>
      filteredAndSortedAssets.map((asset) => ({
        leading: <AssetIcon asset={asset} size={24} />,
        title: asset.name,
        subTitle: asset.code,
        trailing: <AssetsSearchBalances asset={asset} />,
        onClick: () => navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset.code } }),
      })),
    [filteredAndSortedAssets, navigate],
  );

  return items;
};

export { useOwnedAssetsSearchModule };

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useAvo } from '@hooks/Avo/useAvo';
import { LearnCardProps } from '@routes/AssetsList/subroutes/SingleCategory/components/SingleCategoryLearnCard';

import { ModalNameValueType } from 'src/context/Avo/generated-avo';

const useCategoriesAnalytics = () => {
  const avo = useAvo();
  const location = useLocation();
  const isXs = useTailwindBreakpoint('xs');

  const trackLearnArticleClicked = useCallback(
    ({ title, articleUrl }: Pick<LearnCardProps, 'title' | 'articleUrl'>) => {
      if (!title || !articleUrl) return;

      avo.learnArticleOpened({
        articleTitle: title,
        articleLink: articleUrl,
        screen: location.pathname,
        layout: isXs ? 'mobile' : 'desktop',
      });
    },
    [avo, isXs, location.pathname],
  );

  const trackAssetDiscoveryImpression = (modalName: ModalNameValueType) => {
    avo.assetDiscoveryAbTestImpression({
      modalName,
      screen: location.pathname,
    });
  };

  const trackAssetDiscoveryInteraction = (modalName: ModalNameValueType, buttonName: string) => {
    avo.assetDiscoveryAbTestInteraction({
      modalName,
      buttonName,
      screen: location.pathname,
    });
  };

  return { trackLearnArticleClicked, trackAssetDiscoveryImpression, trackAssetDiscoveryInteraction };
};

export { useCategoriesAnalytics };

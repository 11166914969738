import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@swyftx/aviary/atoms/Resizable/Resizable';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ChartTypeLine } from '@swyftx/aviary/icons/charts';

import { GlobalActions } from '@global-components/GlobalActions/GlobalActions';
import { GlobalTradeButton } from '@global-components/GlobalTradeButton';

import { Asset } from '@shared/api';
import { StorageKey } from '@shared/storage';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { TradingViewWidget } from 'src/lib/trade/components';
import { TradingViewSide } from 'src/lib/trade/components/TradingViewWidget/types';
import { useMinimizedResize } from 'src/lib/utils/hooks/useMinimizedResize';

import { TradePageVariant } from '../../universalTradePage.consts';
import { UniversalTradeHeader } from '../Header/UniversalTradeHeader';
import { OrdersTile } from '../OrdersTile/OrdersTile';

interface Props {
  variant: TradePageVariant;
  isAdvanced: boolean;
  selectedAsset: Asset;
  setSelectedAsset: React.Dispatch<React.SetStateAction<Asset | undefined>>;
  variantClass: string;
  priceSide: TradingViewSide;
  setPriceSide: (side: TradingViewSide) => void;
}

export const UniversalTradeContent: React.FC<Props> = observer((props) => {
  const { variant, isAdvanced, selectedAsset, variantClass, priceSide, setPriceSide } = props;
  const baseAsset = useBaseAsset();
  const { navigate } = useNavigateRoute();
  const selectedAssetEqualsBaseAsset = useMemo(
    () => selectedAsset.code === baseAsset?.code,
    [baseAsset?.code, selectedAsset.code],
  );
  const { containerRef, panelRef, minimizedTileHeight, minimizedTile, setMinimizedTile, toggleMinimize } =
    useMinimizedResize({
      key: StorageKey.ORDERS_TILE_MINIMIZED,
    });

  const onChangeSelectedAsset = useCallback(
    (asset?: Asset) => {
      if (!asset) return;
      navigate(NavigationURLs.UniversalTradeAsset, { pathParams: { asset: asset.code } });
    },
    [navigate],
  );

  if (!baseAsset) return null;

  return (
    <>
      <div className='absolute z-behind-content h-[20vh] w-full bg-color-background-surface-secondary @md:h-[15vh]' />
      <FlexLayout className={cn(isAdvanced ? 'p-4' : 'p-8', 'w-full', 'mb-60 @sm:mb-0')} direction='column' spacing={8}>
        <UniversalTradeHeader variant={variant} selectedAsset={selectedAsset} priceSide={priceSide} />
        <FlexLayout direction='row' spacing={8} className='h-full w-full overflow-hidden'>
          <FlexLayout direction='column' className='w-full' ref={containerRef}>
            <ResizablePanelGroup direction='vertical' autoSaveId='trade-page-orders-and-chart'>
              <ResizablePanel minSize={20} order={1} id='trading-view-tile'>
                {!selectedAssetEqualsBaseAsset && (
                  <TradingViewWidget
                    primary={baseAsset.code}
                    secondary={selectedAsset.code}
                    border={false}
                    chartStyle='advanced'
                    className={cn('h-full', variantClass)}
                    onPriceSideChange={setPriceSide}
                  />
                )}
                {selectedAssetEqualsBaseAsset && (
                  <FlexLayout
                    direction='column'
                    spacing={8}
                    className={cn('h-full items-center justify-center', variantClass)}
                  >
                    <ChartTypeLine className='h-96 w-96 text-color-text-accent' />
                    <Body color='primary'>Cannot show chart when selected asset is the same as base asset</Body>
                  </FlexLayout>
                )}
              </ResizablePanel>
              <ResizableHandle withHandle border className='left-8 right-8 my-6 !w-[calc(100%-16px)]' />
              <ResizablePanel
                id='orders-tile'
                order={2}
                defaultSize={30}
                collapsible
                ref={panelRef}
                collapsedSize={minimizedTileHeight}
                minSize={minimizedTileHeight}
                onCollapse={() => setMinimizedTile(true)}
                onResize={() => setMinimizedTile(false)}
              >
                <OrdersTile
                  className={cn('h-full', variantClass)}
                  selectedAsset={selectedAsset}
                  setSelectedAsset={onChangeSelectedAsset}
                  minimized={minimizedTile}
                  setMinimized={toggleMinimize}
                />
              </ResizablePanel>
            </ResizablePanelGroup>
          </FlexLayout>
          <FlexLayout
            className={cn('hidden h-full min-w-[4rem] max-w-[24rem] p-2 @sm:flex')}
            data-spotlightelementId='trade-panel'
            spacing={16}
          >
            <GlobalActions noPadding />
            <GlobalTradeButton universalTradePage />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </>
  );
});

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FlexLayoutProps } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';
import { Add } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  id?: string;
  containerProps?: FlexLayoutProps;
  delay: number;
  delayText: string;
  restartText?: string;
  disabled?: boolean;
  onTimerFinished?: () => void;
  onTimerRestart?: () => void;
};

const CountdownDelay: React.FC<Props> = ({
  id,
  containerProps,
  disabled = false,
  delay,
  delayText,
  restartText,
  onTimerFinished,
  onTimerRestart,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState<number>(delay);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  // maintain a ref to the most up-to-date `onTimerFinished`
  const onTimerFinishedRef = useRef(onTimerFinished);
  onTimerFinishedRef.current = onTimerFinished;

  useEffect(() => {
    setSecondsRemaining(delay);
  }, [delay]);

  const onRestart = useCallback(() => {
    setSecondsRemaining(delay);

    if (onTimerRestart) onTimerRestart();
  }, [delay, onTimerRestart]);

  useEffect(() => {
    if (secondsRemaining <= 0) {
      if (onTimerFinishedRef.current) onTimerFinishedRef.current();
      return undefined;
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;
      setSecondsRemaining((t) => t - 1);
    }, 1000);

    return () => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [secondsRemaining]);

  const isDelayed = secondsRemaining > 0;

  return (
    <FlexLayout id={id} direction='row' className='w-auto' {...containerProps}>
      <Button
        leadingIcon={
          <Add className={cn('h-16 w-16', isDelayed ? 'text-color-text-disabled' : 'text-color-text-primary')} />
        }
        variant='outlined'
        onClick={onRestart}
        disabled={disabled || isDelayed}
      >
        {isDelayed ? `${delayText} ${secondsRemaining}s` : restartText ?? delayText}
      </Button>
    </FlexLayout>
  );
};

export { CountdownDelay };

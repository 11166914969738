import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Delete: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.88211 2C8.96475 2 8.1586 2.615 7.92056 3.50527C7.91643 3.52074 7.91279 3.53633 7.90965 3.55203L7.66665 4.76803C7.58264 5.18683 7.21413 5.489 6.78711 5.489L6.76725 5.48926H3.51953C3.10532 5.48926 2.76953 5.82504 2.76953 6.23926C2.76953 6.65347 3.10532 6.98926 3.51953 6.98926H20.4775C20.8917 6.98926 21.2275 6.65347 21.2275 6.23926C21.2275 5.82504 20.8917 5.48926 20.4775 5.48926H17.23L17.2101 5.489C16.7831 5.489 16.4145 5.18652 16.3305 4.76773L16.0876 3.55203C16.0844 3.53633 16.0808 3.52074 16.0767 3.50527C15.8386 2.615 15.0325 2 14.1151 2H9.88211ZM14.9873 5.48926C14.9324 5.35316 14.8894 5.21044 14.8597 5.06227L14.6224 3.87461C14.5546 3.65265 14.3494 3.5 14.1151 3.5H9.88211C9.6478 3.5 9.44266 3.65265 9.37484 3.87461L9.13757 5.06197C9.10787 5.21025 9.06485 5.35306 9.00989 5.48926H14.9873Z'
      fill='currentColor'
    />
    <path
      d='M10.25 9.99951C10.25 9.5853 9.91421 9.24951 9.5 9.24951C9.08579 9.24951 8.75 9.5853 8.75 9.99951V17.9995C8.75 18.4137 9.08579 18.7495 9.5 18.7495C9.91421 18.7495 10.25 18.4137 10.25 17.9995V9.99951Z'
      fill='currentColor'
    />
    <path
      d='M14.5 9.24951C14.9142 9.24951 15.25 9.5853 15.25 9.99951V17.9995C15.25 18.4137 14.9142 18.7495 14.5 18.7495C14.0858 18.7495 13.75 18.4137 13.75 17.9995V9.99951C13.75 9.5853 14.0858 9.24951 14.5 9.24951Z'
      fill='currentColor'
    />
    <path
      d='M19.8429 9.52808C19.8762 9.11521 19.5685 8.75353 19.1556 8.72024C18.7427 8.68695 18.381 8.99467 18.3478 9.40754L17.4919 18.957C17.3892 19.8849 16.8055 20.4467 15.8657 20.4639C13.2651 20.5108 10.6626 20.5137 8.06568 20.459C7.55997 20.4486 7.21466 20.294 6.98377 20.0739C6.74938 19.8505 6.56536 19.4947 6.50677 18.9642C6.19409 16.1412 5.966 13.3076 5.73809 10.4761C5.70942 10.12 5.68075 9.76384 5.65192 9.40778C5.61873 8.9949 5.25711 8.68703 4.84423 8.72022C4.43135 8.75342 4.12356 9.11512 4.15675 9.528C4.18524 9.87985 4.21357 10.2318 4.2419 10.5837C4.47142 13.4344 4.7011 16.2872 5.01589 19.1294C5.10433 19.9298 5.40533 20.6417 5.94889 21.1597C6.4959 21.6811 7.22141 21.9419 8.03452 21.9586C10.6536 22.0139 13.2756 22.0108 15.893 21.9637C16.723 21.9486 17.4652 21.6966 18.0267 21.1751C18.5863 20.6552 18.8927 19.9359 18.9827 19.1226L19.8429 9.52808Z'
      fill='currentColor'
    />
  </svg>
);

export { Delete };

import { useCallback, useMemo, useState } from 'react';

import { api, PairExchangeRateParams, PairExchangeRateResponse, PairExchangeRateResponseError } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';

import { useQuery } from '@tanstack/react-query';

type Props = {
  key?: string;
  pro?: boolean;
} & Partial<PairExchangeRateParams>;

const useTradeQuote = ({ buy, sell, amount, limit, key, pro }: Props) => {
  const [quoteError, setQuoteError] = useState<PairExchangeRateResponseError>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const enabled = useMemo(
    () => (buy?.length || 0) > 0 && (sell?.length || 0) > 0 && Big(amount).gt(0),
    [buy, sell, amount],
  );

  const fetchQuote = useCallback(async (): Promise<PairExchangeRateResponse | undefined> => {
    if (!buy || !sell || Big(amount).eq(0)) return undefined;

    try {
      const response = await api.endpoints.getPairExchangeRate({
        data: { buy, sell, amount, limit, pro },
      });
      setQuoteError(undefined);
      setErrorMessage(undefined);

      return response.data;
    } catch (e) {
      const err = JSON.parse((e as SwyftxError)?.errorMessage) as PairExchangeRateResponseError;
      if (err.maximumPercent) {
        setQuoteError(err);
        setErrorMessage(undefined);
      } else {
        setQuoteError(undefined);
        setErrorMessage(err.message);
      }

      throw err;
    }
  }, [amount, buy, limit, pro, sell]);

  const { status, data, error, isFetching, isStale } = useQuery<
    PairExchangeRateResponse | undefined,
    PairExchangeRateResponseError
  >({
    queryKey: ['tradeQuote', buy, sell, limit, amount, key],
    queryFn: fetchQuote,
    refetchInterval: 3000,
    enabled,
  });

  return { status, data, error, isFetching, isStale, quoteError, errorMessage };
};

export { useTradeQuote };

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';

import { useQuery } from '@tanstack/react-query';

const useCategories = () => {
  const { status, data, error, isFetching, isPreviousData } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let response = (await api.endpoints.getAssetServiceCategories()).data;
      return response || [];
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: TimesEnum.HOUR,
  });

  return {
    status,
    categories: data || [],
    error,
    isFetching,
    isPreviousData,
  };
};

export { useCategories };

import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { OrdersTile } from 'src/lib/UniversalTrade/components/OrdersTile/OrdersTile';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SwyftxProTradeContext } from '../../context';

type Props = {
  className?: string;
  minimized?: boolean;
  setMinimized?: (minimized?: boolean) => void;
};

const SwyftxProBalanceOrders: React.FC<Props> = ({ className, minimized, setMinimized }) => {
  const { selectedAsset } = useContext(SwyftxProTradeContext);
  const { navigate } = useNavigateRoute();

  return (
    <FlexLayout
      className={cn('bg-color-background-surface-primary', className)}
      alignItems='center'
      justifyContent='center'
    >
      {selectedAsset && (
        <OrdersTile
          className='h-full w-full'
          selectedAsset={selectedAsset}
          setSelectedAsset={(asset: Asset) => {
            navigate(NavigationURLs.SwyftxPro, { pathParams: { asset: asset.code } });
          }}
          tabVariant='header'
          minimized={minimized}
          setMinimized={setMinimized}
        />
      )}
    </FlexLayout>
  );
};

export { SwyftxProBalanceOrders };

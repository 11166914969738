import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Category } from '@shared/api/@types/categories';
import { Links } from '@shared/constants';

import { LearnCardProps } from '../components/SingleCategoryLearnCard';

type Props = {
  category?: Category;
};

const AI_CATEGORY_ID = 28;

const useSingleCategoryLearnCard = ({ category }: Props) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });

  const learnCard = useMemo((): LearnCardProps | undefined => {
    if (category?.id !== AI_CATEGORY_ID) return undefined;

    return {
      title: t('aiLearnCard.title'),
      imageSrc: '/assets/images/ai_learn_card.webp',
      articleUrl: Links.learn.cryptoXAi,
      content: t('aiLearnCard.content'),
    };
  }, [category?.id, t]);

  return {
    learnCard,
  };
};

export { useSingleCategoryLearnCard };

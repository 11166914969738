import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type WalletState = {
  hideZeroBalances: boolean;
  setHideZeroBalances: (hideZeroBalances: boolean) => void;
};

const useWalletStore = create<WalletState>()(
  persist(
    (set) => ({
      hideZeroBalances: false,
      setHideZeroBalances: (hideZeroBalances: boolean) => set({ hideZeroBalances }),
    }),
    {
      name: 'wallet-store',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export { useWalletStore };

import { UserStore } from '@shared/store';

import { CountryTextFactoryProps } from './DepositService.types';

export const countryTextFactory = <T>(params: CountryTextFactoryProps<T>): T => {
  const { nzd, aud, currentCountry } = params;

  const { userProfile } = UserStore.useUserStore;

  switch (currentCountry) {
    case 'NZD':
      return nzd;
    case 'AUD':
      return aud;
    default:
      if (!userProfile || !currentCountry) {
        return aud;
      }

      throw new Error(`Failed to select text for country ${currentCountry}`);
  }
};

export const isValidDepositInput = (value: string) => {
  const POSITIVE_DEPOSIT_INPUT = /^(?:\d+(?:\.\d{0,2})?)?$/;
  return value.match(POSITIVE_DEPOSIT_INPUT);
};

import React, { PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Carousel } from '@swyftx/aviary/atoms/Carousel/Carousel';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { WhatsNewFilled } from '@swyftx/aviary/icons/filled';
import { SwyftxFull } from '@swyftx/aviary/icons/outlined';

import { Links } from '@shared/constants';
import { StorageKey } from '@shared/storage';
import { cn } from '@shared/utils/lib/ui';

import { useLocalStorage } from 'react-use';
import Balancer from 'react-wrap-balancer';

const LoginShell: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [showBrandHeader, setShowBrandHeader] = useLocalStorage<boolean>(StorageKey.SHOW_REBRAND_BANNER, true);

  const carouselItems = useMemo(
    () => [
      {
        key: 'assets',
        title: (
          <Heading size='h2' color='inverse' className='font-normal'>
            Buy, sell and trade over{' '}
            <span className='font-callout text-color-text-highlight-blockchainbreeze'>350+</span> assets.
          </Heading>
        ),
        subTitle: (
          <Body color='inverse' className='w-full'>
            <Balancer>Never miss out on assets, with new listings added every month.</Balancer>
          </Body>
        ),
        image: <Image image='trading_illustration' className='max-w-[200px]' />,
      },
      {
        key: 'portfolio',
        title: (
          <Heading size='h2' color='inverse' className='font-normal'>
            Keep <span className='font-callout text-color-text-highlight-blockchainbreeze'>track</span> of your
            portfolio and profits.
          </Heading>
        ),
        subTitle: (
          <Body color='inverse' className='w-full'>
            <Balancer>Live market data that lets you track your crypto investments.</Balancer>
          </Body>
        ),
        image: <Image image='portfolio_illustration' className='max-w-[200px]' />,
      },
      {
        key: 'demo',
        title: (
          <Heading size='h2' color='inverse' className='font-normal'>
            Test your <span className='font-callout text-color-text-highlight-blockchainbreeze'>strategy</span> using
            demo mode.
          </Heading>
        ),
        subTitle: (
          <Body color='inverse' className='w-full'>
            <Balancer>The only exchange that lets you place paper trades 100% free.</Balancer>
          </Body>
        ),
        image: <Image image='demo_mode_illustration' className='max-w-[200px]' />,
      },
    ],
    [],
  );

  return (
    <FlexLayout direction='column' className='h-full w-full overflow-hidden'>
      {showBrandHeader && (
        <FlexLayout
          className='min-h-[130px] w-full flex-col items-start gap-8 border-b border-color-border-main p-8 sm:min-h-[60px] sm:flex-row sm:items-center sm:gap-64'
          justifyContent='center'
        >
          <FlexLayout alignItems='center' spacing={16}>
            <WhatsNewFilled className='min-h-[24px] min-w-[24px] text-color-text-accent' />
            <FlexLayout spacing={0} direction='column'>
              <Heading size='h5'>Introducing a new look</Heading>
              <Body size='small' color='secondary'>
                We&apos;ve been hard at work on a new look and feel for Swyftx, and we&apos;re excited to finally share
                it with you. The platform you know and love has not changed.
              </Body>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout spacing={8} className='ml-36 sm:ml-0'>
            <Link to={Links.blog.aBoldNewEra} target='_blank'>
              <Button variant='filled' size='md'>
                Read the announcement
              </Button>
            </Link>
            <Button variant='outlined' size='md' onClick={() => setShowBrandHeader(false)}>
              Dismiss
            </Button>
          </FlexLayout>
        </FlexLayout>
      )}
      <FlexLayout className='h-full w-full bg-color-background-primary' justifyContent='space-between'>
        <FlexLayout
          className='hidden w-8/12 p-24 sm:flex'
          alignItems='center'
          justifyContent='center'
          direction='column'
          spacing={0}
        >
          <SwyftxFull className='h-42 text-color-text-inverse' />
          <div className='h-[550px] w-full'>
            <Carousel itemClassName='w-[280px] md:w-[600px]' items={carouselItems} />
          </div>
        </FlexLayout>
        <FlexLayout
          direction='column'
          className={cn(
            'h-full w-full justify-start bg-color-background-surface-primary sm:w-4/12 sm:min-w-[400px] sm:max-w-[600px] sm:justify-center',
            showBrandHeader ? 'pb-[130px] sm:pb-[60px]' : '',
          )}
          alignItems='center'
        >
          <FlexLayout direction='column' className='w-full overflow-y-auto' alignItems='center' justifyContent='start'>
            <div className='h-full w-full p-24 xl:px-64'>{children}</div>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export { LoginShell };

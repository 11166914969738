import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Bank: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.9149 6.25229C10.9149 5.89823 10.6279 5.61121 10.2738 5.61121C9.91972 5.61121 9.63269 5.89823 9.63269 6.25229V6.2612C9.63269 6.61526 9.91972 6.90228 10.2738 6.90228C10.6279 6.90228 10.9149 6.61526 10.9149 6.2612V6.25229Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.84153 9.50931H3.26494C2.56668 9.50931 1.99984 8.94355 1.99984 8.24505V7.75364C1.99984 7.32315 2.21858 6.92358 2.57877 6.69072L9.58667 2.16668C10.0038 1.89757 10.5409 1.89757 10.958 2.16668L17.9678 6.69143C18.3264 6.92435 18.5448 7.3235 18.5448 7.75364V8.24505C18.5448 8.94355 17.978 9.50931 17.2797 9.50931H16.7011V14.9769H17.1719C17.6771 14.9769 18.1151 15.3283 18.2239 15.8222L18.5545 17.323C18.7016 17.9929 18.1931 18.6315 17.5034 18.6315H3.04439C2.35639 18.6315 1.84483 17.9951 1.99225 17.3234L2.32301 15.8222C2.43175 15.3283 2.86979 14.9769 3.37503 14.9769H3.84153V9.50931ZM3.28203 7.76286L10.2723 3.25019L17.2626 7.76233V8.22714C12.6025 8.22714 7.94144 8.22714 3.28203 8.22714V7.76286ZM5.12373 9.50931V14.9769H7.70011V9.50931H5.12373ZM8.9823 9.50931V14.9769H11.5603V9.50931H8.9823ZM12.8425 9.50931V14.9769H15.4189V9.50931H12.8425ZM3.5397 16.2591L3.29949 17.3493H17.2474L17.0072 16.2591C12.5189 16.2591 8.02918 16.2591 3.5397 16.2591Z'
      fill='currentColor'
    />
  </svg>
);

export { Bank };

import { useCallback, useContext, useMemo } from 'react';

import { CreditCard, Wallet } from '@swyftx/aviary/icons/outlined';

import { QuickBuyPaymentMethodData, QuickBuyPaymentMethodType } from 'src/lib/trade/types/Trade.types';

import { useQuickBuyAssets } from './useQuickBuyAssets';
import { QuickBuyContext } from '../../context';

const useQuickBuyPaymentMethods = () => {
  const { selectedPaymentMethod, setSelectedPaymentMethod } = useContext(QuickBuyContext);
  const { countryAsset } = useQuickBuyAssets();

  const paymentOptions: QuickBuyPaymentMethodData[] = useMemo(
    () => [
      {
        icon: <Wallet />,
        type: 'wallet',
        title: `${countryAsset?.code || 'AUD'} Wallet`,
        description: 'Buy instantly with account funds',
        disabled: false,
      },
      {
        icon: <CreditCard />,
        type: 'card',
        title: 'Card',
        description: 'Buy instantly with Visa or Mastercard',
        disabled: true,
      },
    ],
    [countryAsset],
  );

  const getPaymentOption = useCallback(
    (type: QuickBuyPaymentMethodType) => paymentOptions.find((option) => option.type === type),
    [paymentOptions],
  );

  return { paymentOptions, getPaymentOption, selectedPaymentMethod, setSelectedPaymentMethod };
};

export { useQuickBuyPaymentMethods };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { QuickBuyPaymentMethodType, QuickBuyStep, QuickBuyType } from 'src/lib/trade/types/Trade.types';

import { QuickBuyAsset, QuickBuyPaymentMethod } from './components';

type Props = {
  buyType: QuickBuyType;
  selectedAsset?: Asset;
  selectedPaymentMethod: QuickBuyPaymentMethodType;
  setStep: React.Dispatch<React.SetStateAction<QuickBuyStep>>;
  allowAssetChange: boolean;
  allowPaymentChange: boolean;
};

const QuickBuyPayPanel: React.FC<Props> = ({
  buyType,
  selectedAsset,
  selectedPaymentMethod,
  setStep,
  allowAssetChange,
  allowPaymentChange,
}) => (
  <FlexLayout direction='column' className={cn('!h-[8rem]', 'w-full')}>
    <FlexLayout
      direction='column'
      className='rounded-8 outline outline-1 outline-color-border-main'
      justifyContent='center'
    >
      <QuickBuyAsset
        buyType={buyType}
        className={cn(buyType === 'buy' ? 'border-b' : '', 'px-12 py-4 sm:py-12')}
        selectedAsset={selectedAsset}
        setStep={setStep}
        disabled={!allowAssetChange}
      />
      {buyType === 'buy' && (
        <QuickBuyPaymentMethod
          disabled={!allowPaymentChange}
          className=' border-color-border-main px-12 py-4 sm:py-12'
          selectedPaymentMethod={selectedPaymentMethod}
          setStep={setStep}
        />
      )}
    </FlexLayout>
  </FlexLayout>
);

export { QuickBuyPayPanel };

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption } from '@global-components/AutocompleteDropdown';

import { EntityMembershipType, EntityPermission } from '@shared/api/@types/entity';

export const useEntityPermissionOptions = (): Array<AutocompleteOption<EntityPermission>> => {
  const { t } = useTranslation('profile');

  const options = useMemo<Array<AutocompleteOption<EntityPermission>>>(
    () => [
      {
        value: EntityPermission.Admin,
        label: t('entities.permissionOptions.admin'),
      },
      {
        value: EntityPermission.Trade,
        label: t('entities.permissionOptions.trade'),
      },
      {
        value: EntityPermission.Read,
        label: t('entities.permissionOptions.read'),
      },
      {
        value: EntityPermission.None,
        label: t('entities.permissionOptions.none'),
      },
    ],
    [t],
  );

  return options;
};

export const useEntityMembershipTypeLabels = (): Record<EntityMembershipType, string> => {
  const { t } = useTranslation('profile');

  const options = useMemo<Record<EntityMembershipType, string>>(
    () => ({
      [EntityMembershipType.MajorShareholder]: t('entities.addMemberModal.membershipTypes.majorShareholder'),
      [EntityMembershipType.Beneficiary]: t('entities.addMemberModal.membershipTypes.beneficiary'),
      [EntityMembershipType.Director]: t('entities.addMemberModal.membershipTypes.director'),
      [EntityMembershipType.Trustee]: t('entities.addMemberModal.membershipTypes.trustee'),
      [EntityMembershipType.None]: t('entities.addMemberModal.membershipTypes.none'),
    }),
    [],
  );

  return options;
};

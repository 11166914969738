import { useCallback } from 'react';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useFetchDynamicPriceAlerts } from './useFetchDynamicPriceAlerts';
import { PriceAlert, PriceAlertWindow } from '../dynamicPriceAlerts.types';

type Props = {
  primary?: number;
  secondary?: number;
};

const useCheckDuplicatePriceAlert = ({ primary, secondary }: Props) => {
  const { data } = useFetchDynamicPriceAlerts({ primary, secondary });

  const checkForDuplicate = useCallback(
    (value: string, baseAsset: Asset, asset: Asset, alert?: PriceAlert, window?: PriceAlertWindow) => {
      if (!data) return false;

      for (let i = 0; i < data.length; i++) {
        const alertToTest = data[i];
        const isLimit = alertToTest.type === 'LIMIT';
        const matchingBase = alertToTest.primary === baseAsset.id;
        const matchingAsset = alertToTest.secondary === asset.id;

        if (!alert || alertToTest.priceAlertUuid !== alert.priceAlertUuid) {
          if (isLimit && Big(alertToTest.price).eq(value) && matchingBase && matchingAsset) {
            return true;
          } else if (!isLimit && Big(alertToTest.change).times(100).eq(value) && matchingBase && matchingAsset) {
            if (!window || window === alertToTest.window) return true;
          }
        }
      }

      return false;
    },
    [data],
  );

  return {
    checkForDuplicate,
  };
};

export { useCheckDuplicatePriceAlert };

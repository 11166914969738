import React, { useContext } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';

import { ProfileContext } from '@Profile/Profile.context';

import { observer } from 'mobx-react-lite';

import { AffiliateDashboardEarnToday } from './AffiliateDashboardEarnToday';
import { AffiliateDashboardReferralLink } from './AffiliateDashboardReferralLink';
import { AffiliateDashboardPastPayouts } from './PastPayouts';
import { AffiliateUpdateAbn } from '../AffiliateUpdateAbn';

const AffiliateDashboard: React.FC = observer(() => {
  const { payoutHistory } = useContext(ProfileContext);

  const affiliateStatusItems = [<AffiliateUpdateAbn key='affiliateUpdateAbn' />];

  if (payoutHistory?.length) {
    affiliateStatusItems.push(<AffiliateDashboardPastPayouts key='affiliateDashboardPastPayouts' />);
  }

  return (
    <FlexLayout direction='column' spacing={24}>
      <SettingsCluster
        title='Affiliate Program'
        items={[
          <AffiliateDashboardEarnToday key='affiliateDashboardEarnToday' />,
          <AffiliateDashboardReferralLink key='affiliateDashboardReferralLink' />,
        ]}
      />
      <SettingsCluster title='Affiliate Status' items={affiliateStatusItems} />
    </FlexLayout>
  );
});

export { AffiliateDashboard };

import { useCallback, useState } from 'react';

import { api } from '@shared/api';
import { UIStore } from '@shared/store';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { useFetchDynamicPriceAlertsCache } from './useFetchDynamicPriceAlerts';
import { UpdateLimitAlertData, UpdateVolatilityAlertData } from '../dynamicPriceAlerts.types';

const useUpdateDynamicPriceAlert = () => {
  const { invalidateCache } = useFetchDynamicPriceAlertsCache();
  const [updating, setUpdating] = useState<boolean>(false);
  const { addToastMessage } = UIStore.useUIStore;
  const { getAssetById } = useMarkets();

  const updateDynamicPriceAlert = useCallback(
    async (data: UpdateLimitAlertData | UpdateVolatilityAlertData) => {
      try {
        setUpdating(true);
        const response = await api.endpoints.updatePriceAlert({ data });
        const asset = getAssetById(response.data.secondary);
        invalidateCache();
        addToastMessage({ severity: 'success', message: `${asset?.code} price alert updated` });

        return response.data;
      } catch (e) {
        addToastMessage({ severity: 'error', message: 'Failed to update price alert' });
      } finally {
        setUpdating(false);
      }
    },
    [addToastMessage, getAssetById, invalidateCache],
  );

  return { updateDynamicPriceAlert, updating };
};

export { useUpdateDynamicPriceAlert };

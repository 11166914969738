import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { formatCurrency } from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';

interface Props {
  orderAmount: string;
  orderTotal: string;
  assetId: number;
  primaryAsset?: Asset;
  approximate?: boolean;
}

export const OrderQuantity: React.FC<Props> = (props) => {
  const { orderAmount, assetId, orderTotal, primaryAsset, approximate } = props;
  const amount = Big(orderAmount).abs().toString();
  const asset = assetService.getAsset(assetId);

  if (!asset || !primaryAsset) return null;

  return (
    <FlexLayout direction='column' className=' items-end @sm:items-start '>
      <NumericDataItem data={formatCurrency(amount, { isFiat: false })} size='small' color='primary' className=''>
        <FlexLayout direction='row' spacing={4} className='flex-wrap justify-end @sm:justify-start'>
          <Numeric size='small'>{`${approximate ? '≈' : ''}${formatCurrency(amount, {
            isFiat: asset.assetType === AssetType.Fiat,
          })} `}</Numeric>
          <Numeric size='small' className='text-color-text-primary @sm:text-color-text-secondary'>
            {asset.code}
          </Numeric>
        </FlexLayout>
      </NumericDataItem>
      <div className='flex flex-wrap @sm:hidden'>
        <NumericDataItem data={orderTotal} size='small' color='secondary' className='text-end'>
          {orderTotal} {primaryAsset.code}
        </NumericDataItem>
      </div>
    </FlexLayout>
  );
};

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';
import { Card, DateRange, Input, Stack } from '@swyftx/react-web-design-system';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

const StyledInput = styled(Input)`
  height: auto;
  width: 100%;

  .MuiOutlinedInput-root,
  input {
    height: 36px;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
  }

  fieldset {
    border: none;
  }
`;

type Props = {
  id?: string;
  dateRange: DateRange<DateTime>;
  setDateRange: (date: DateRange<DateTime>) => void;
};

export const CalendarPicker: React.FC<Props> = observer(({ dateRange, setDateRange, id }) => {
  const [startDate, setStartDate] = useState<DateTime | null>(dateRange[0]);
  const [endDate, setEndDate] = useState<DateTime | null>(dateRange[1]);

  useEffect(() => {
    setDateRange([startDate, endDate]);
  }, [startDate, endDate]);

  const updateStartDate = (date: DateTime | null) => {
    if (date === null) {
      setStartDate(date);
      return;
    }
    setStartDate(date.startOf('day'));
  };

  const updateEndDate = (date: DateTime | null) => {
    if (date === null) {
      setEndDate(date);
      return;
    }

    setEndDate(date.endOf('day'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-AU'>
      <Card
        variant='outlined'
        elevation={0}
        sx={{ height: '40px', width: '100%' }}
        contentSx={{ padding: 0, height: '100%', paddingBottom: '0 !important' }}
        id={id || 'calendar-picker'}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ height: '100%', width: '100%' }}
        >
          <DatePicker
            views={['year', 'month', 'day']}
            onChange={updateStartDate}
            value={startDate}
            disableFuture
            shouldDisableDate={(date) => {
              if (endDate === null) return false;
              return date.endOf('day').toMillis() > endDate?.toMillis();
            }}
            renderInput={(props) => (
              <Stack
                direction='row'
                sx={{
                  fieldset: { border: 'none !important' },
                  '.MuiInputBase-root': { background: 'none !important', border: 'none !important', height: '100%' },
                  '.MuiOutlinedInput-input': { textAlign: 'left' },
                }}
              >
                <StyledInput
                  {...props}
                  label=''
                  inputProps={{ ...props.inputProps, endAdornment: props.InputProps?.endAdornment }}
                  placeholder='DD/MM/YYYY'
                />
              </Stack>
            )}
          />

          <ArrowLineOnlyRight className='h-24 w-24' />

          <DatePicker
            views={['year', 'month', 'day']}
            onChange={updateEndDate}
            value={endDate}
            disableFuture
            shouldDisableDate={(date) => {
              if (startDate === null) return false;
              return date.startOf('day').toMillis() < startDate?.toMillis();
            }}
            renderInput={(props) => (
              <Stack
                direction='row'
                sx={{
                  fieldset: { border: 'none !important' },
                  '.MuiInputBase-root': { background: 'none !important', border: 'none !important', height: '100%' },
                  '.MuiOutlinedInput-input': { textAlign: 'left' },
                }}
              >
                <StyledInput
                  {...props}
                  label=''
                  inputProps={{ ...props.inputProps, endAdornment: props.InputProps?.endAdornment }}
                  placeholder='DD/MM/YYYY'
                />
              </Stack>
            )}
          />
        </Stack>
      </Card>
    </LocalizationProvider>
  );
});

import { useCallback, useEffect, useState } from 'react';

import {
  api,
  Asset,
  GetDepositHistoryParams,
  GetDepositHistoryPayload,
  GetWithdrawalHistoryParams,
  GetWithdrawalHistoryPayload,
  TransactionHistoryResponse,
} from '@shared/api';
import { AppStore, UIStore } from '@shared/store';

import WalletService, {
  EarnHistoryItem,
  EarnTypeFilter,
  TransactionHistoryItem,
  TransactionOrder,
  TransactionTypeFilter,
} from '@services/WalletService';

const DEFAULTS = {
  transactionsPerPage: 10,
};

type Options = {
  transactionsPerPage: number;
};

export const useAssetTransactionHistory = (
  asset: Asset,
  transactionTypes: Array<TransactionTypeFilter>,
  options?: Options,
) => {
  const { isDemo } = AppStore.useAppStore;
  const { refreshTransactions, setRefreshTransactions } = UIStore.useUIStore;
  const [transactions, setTransactions] = useState<TransactionHistoryItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [fetching, setFetching] = useState<boolean>(true);
  const transactionsPerPage = options?.transactionsPerPage || DEFAULTS.transactionsPerPage;

  const fetch = useCallback(
    async (force?: boolean) => {
      setFetching(true);

      if (force) {
        setTransactions([]);
        setCurrentPage(1);
        setTotalPages(1);
      }

      try {
        const assetTransactions = await WalletService.getTransactions(
          asset.id,
          currentPage,
          transactionsPerPage,
          transactionTypes,
        );

        setTransactions((prevState) => {
          const deduped = assetTransactions.tableItems.filter(
            (r) => prevState.findIndex((a) => a.uuid === r.uuid) === -1,
          );

          return [...prevState, ...deduped];
        });

        const totalPages = Math.ceil(assetTransactions.recordCount / transactionsPerPage);
        setTotalPages(totalPages);
      } finally {
        setFetching(false);
      }
    },
    [currentPage, isDemo, asset],
  );

  useEffect(() => {
    fetch();
  }, [currentPage]);

  useEffect(() => {
    fetch(true);
  }, [isDemo, asset]);

  useEffect(() => {
    if (refreshTransactions) {
      fetch(true);
      setRefreshTransactions(false);
    }
  }, [refreshTransactions]);

  const incrementPage = () => {
    if (fetching) return;

    if (currentPage < totalPages) setCurrentPage((prevState) => prevState + 1);
  };

  return {
    loading: fetching,
    transactions,
    hasAnotherPage: currentPage < totalPages,
    page: currentPage,
    incrementPage,
  };
};

export const useAssetEarnTransactionHistory = (
  asset: Asset,
  transactionTypes: Array<EarnTypeFilter>,
  options?: Options,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<EarnHistoryItem[]>([]);
  const [hasAnotherPage, setHasAnotherPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const transactionsPerPage = options?.transactionsPerPage || DEFAULTS.transactionsPerPage;

  const getTransactions = async (oldTransactions: EarnHistoryItem[]) => {
    setLoading(true);
    const assetTransactions = await WalletService.getEarnHistory(
      asset.id,
      page,
      transactionsPerPage,
      transactionTypes ?? [],
      TransactionOrder.DESC,
    );
    setHasAnotherPage(assetTransactions.earnRecordCount > page * transactionsPerPage);
    setTransactions([...oldTransactions, ...assetTransactions.tableItems]);
    setLoading(false);
  };

  useEffect(() => {
    getTransactions([]);
  }, [asset]);

  useEffect(() => {
    getTransactions([...transactions]);
  }, [page]);

  return { loading, transactions, hasAnotherPage, page, setPage };
};

export const useAssetWalletHistory = (
  asset: Asset,
  transactionType: TransactionTypeFilter.DEPOSIT | TransactionTypeFilter.WITHDRAWAL,
  from: number,
  to: number,
  limit: number,
  ignoreDateFilter: boolean,
  page = 1,
) => {
  const [walletHistory, setWalletHistory] = useState<Array<TransactionHistoryResponse>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const query: GetWithdrawalHistoryPayload | GetDepositHistoryPayload = {
    from,
    to,
    limit,
    ignoreDateFilter,
    page,
    sortBy: 'updated',
  };

  const params: GetWithdrawalHistoryParams | GetDepositHistoryParams = {
    code: asset.code,
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const endpoint =
          transactionType === TransactionTypeFilter.DEPOSIT
            ? api.endpoints.getDepositHistory
            : api.endpoints.getWithdrawalHistory;
        const res = await endpoint({ params, query });
        setWalletHistory((prevWalletHistory) => [...prevWalletHistory, ...res.data]);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    })();
  }, [asset, page]);

  return { walletHistory, loading, error };
};

import React, { useMemo } from 'react';

import Box from '@mui/material/Box';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Cross } from '@swyftx/aviary/icons/outlined';
import { Stack, Button as MUIButton } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { TradeData } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useUniversalTradeAsset } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

type Props = {
  tradeDataKey: string;
  flippable?: boolean;
  tradeData?: TradeData;
  onRemove?: (data?: TradeData) => void;
};

const TradeInputFlip: React.FC<Props> = observer(({ tradeDataKey, flippable, onRemove, tradeData }) => {
  const { getRate } = RatesStore.useRatesStore;
  const { setTradeData, nonLimitAsset, getNonLimitValue, limitAsset, fromAsset, toAsset } =
    useUniversalTradeAsset(tradeDataKey);

  const { sellLiquidityFlag } = getRate(fromAsset?.id || -1);
  const { buyLiquidityFlag } = getRate(toAsset?.id || -1);

  const disabled = useMemo(() => buyLiquidityFlag || sellLiquidityFlag, [buyLiquidityFlag, sellLiquidityFlag]);

  // Flip the limit asset
  const setLimitAsset = (asset: Asset) => {
    if (!nonLimitAsset || !limitAsset || !fromAsset || !toAsset) return;

    setTradeData({
      limit: asset.id,
      balance: Big(getNonLimitValue()).gt(0) ? Big(getNonLimitValue()).round(nonLimitAsset.price_scale).toString() : '',
    });
  };

  if (!nonLimitAsset || !toAsset || !fromAsset) return null;

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-end'
      spacing={1}
      width={flippable ? '120px' : '20px'}
    >
      {flippable && (
        <Tooltip title={disabled ? 'Unable to change the limit asset when trading against a low liquidity asset' : ''}>
          <span>
            <Stack
              direction='row'
              alignItems='center'
              className='bg-color-background-surface-secondary'
              spacing={0.5}
              borderRadius={6}
              sx={{ overflowX: 'auto', padding: 0.25 }}
            >
              <Button
                focusStyle={false}
                className='w-full px-8'
                variant={limitAsset?.code === fromAsset.code ? 'filled' : 'ghost'}
                color={limitAsset?.code === fromAsset.code ? 'primary' : 'subtle'}
                size='sm'
                disabled={disabled}
                onClick={() => setLimitAsset(fromAsset)}
              >
                {fromAsset.code}
              </Button>
              <Button
                focusStyle={false}
                className='w-full px-8'
                variant={limitAsset?.code === toAsset.code ? 'filled' : 'ghost'}
                color={limitAsset?.code === toAsset.code ? 'primary' : 'subtle'}
                size='sm'
                disabled={disabled}
                onClick={() => setLimitAsset(toAsset)}
              >
                {toAsset.code}
              </Button>
            </Stack>
          </span>
        </Tooltip>
      )}
      {onRemove !== undefined && (
        <Box height='100%' color='text.primary'>
          <MUIButton
            variant='text'
            size='small'
            color='inherit'
            onClick={() => onRemove(tradeData)}
            startIcon={<Cross className='h-14 w-14' />}
          />
        </Box>
      )}
    </Stack>
  );
});

TradeInputFlip.displayName = 'TradeInputFlip';

export { TradeInputFlip };

import React from 'react';

import SvgIconMUI, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

export const StyledIcon = styled(SvgIconMUI)`
  &.MuiSvgIcon-root {
    fill: none !important;
  }
`;

const ArrowDownIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <StyledIcon {...props}>
    <path
      d='M19 8.5L12 15.5L5 8.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledIcon>
);

export { ArrowDownIcon };

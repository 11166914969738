import React, { useCallback, useEffect, useState } from 'react';

import Wizard from '@global-components/Wizard';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { EntityTypeEnum } from '@shared/enums';
import { UIStore, UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { UpdateEmailAddressInputStep } from './steps/UpdateEmailAddressInputStep';
import { UpdateEmailAddressVerifyStep } from './steps/UpdateEmailAddressVerifyStep';

type Props = {
  onClose: () => void;
};

export const UpdateEmailAddressWizard: React.FC<Props> = observer(({ onClose }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [email, setEmail] = useState('');
  const { addMessageBox, addToastMessage } = UIStore.useUIStore;
  const { userProfile, setUserProfile } = UserStore.useUserStore;

  // if user's email is not verified AND green id is not verified, update email without additional verification
  const userNotVerified = !userProfile?.verification?.email && !userProfile?.verification?.greenid.status;

  const goToVerify = () => {
    setStepIndex(1);
  };

  const setEmailUnVerified = useCallback(() => {
    if (userProfile) {
      const userProfileUpdated = { ...userProfile };
      userProfileUpdated.email = email;
      if (userProfileUpdated.verification) userProfileUpdated.verification.email = 0;
      setUserProfile(userProfileUpdated);
    }
  }, [email, setUserProfile, userProfile]);

  const onSuccess = useCallback(() => {
    addMessageBox({
      content: <SuccessMessageBox title='Email address successfully updated' />,
    });

    setEmailUnVerified();

    onClose();
  }, [addMessageBox, onClose, setEmailUnVerified]);

  const skipVerification = useCallback(() => {
    setEmailUnVerified();
    onClose();
    onSuccess();
  }, [onClose, onSuccess, setEmailUnVerified]);

  useEffect(() => {
    if (userProfile?.entityDetails?.entityType !== EntityTypeEnum.PERSONAL) {
      onClose();
      addToastMessage({ message: 'Entity accounts cannot change their email address', severity: 'error' });
    }
  }, [addToastMessage, onClose, userProfile]);

  return (
    <Wizard.Container open onClose={onClose} stepIndex={stepIndex}>
      <UpdateEmailAddressInputStep
        email={email}
        setEmail={setEmail}
        onClose={onClose}
        onAction={userNotVerified ? skipVerification : goToVerify}
      />
      <UpdateEmailAddressVerifyStep onClose={onClose} email={email} onSuccess={onSuccess} />
    </Wizard.Container>
  );
});

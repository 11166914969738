import React, { useMemo } from 'react';

import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

import { AssetHistoryItem } from '@shared/api';

interface Props {
  order: AssetHistoryItem;
}

export const FilledAmount: React.FC<Props> = (props) => {
  const { order } = props;

  const donutChart = useMemo(
    () => (
      <DonutChart
        id={order.uuid}
        innerRadius={164}
        showTooltip={false}
        data={[
          {
            y: 1,
            color: '',
            title: '',
          },
        ]}
        cornerRadius={0}
        gap={0}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setExternalMutations={() => {}}
      >
        <Numeric size='xxsmall' weight='emphasis' className='select-none text-center'>
          0%
        </Numeric>
      </DonutChart>
    ),
    [order.uuid],
  );

  return <div className='h-34 w-34'>{donutChart}</div>;
};

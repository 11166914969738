import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyLeft } from '@swyftx/aviary/icons/outlined';

type Props = {
  title: string;
  onBack?: () => void;
  description?: string;
};

const AutoInvestCardHeader: React.FC<Props> = ({ title, onBack, description }) => (
  <FlexLayout direction='column' alignItems='center' className='w-full' spacing={16}>
    <FlexLayout alignItems='center' spacing={16} direction='row' className='w-full'>
      {onBack && <Button layout='icon' leadingIcon={<ArrowLineOnlyLeft />} onClick={onBack} variant='outlined' />}
      <FlexLayout direction='column' spacing={4}>
        <Heading size='h5' color='primary'>
          {title}
        </Heading>
      </FlexLayout>
    </FlexLayout>
    {description && (
      <Body size='medium' color='secondary'>
        {description}
      </Body>
    )}
  </FlexLayout>
);

export { AutoInvestCardHeader };

import { DateTime } from 'luxon';

import {
  LimitPriceAlert,
  LimitTriggeredPriceAlert,
  PriceAlert,
  TriggeredPriceAlert,
  VolatilityPriceAlert,
  VolatilityTriggeredPriceAlert,
} from '../dynamicPriceAlerts.types';

const isVolatilityAlert = (alert?: PriceAlert): alert is VolatilityPriceAlert => alert?.type === 'VOLATILITY';
const isLimitAlert = (alert?: PriceAlert): alert is LimitPriceAlert => alert?.type === 'LIMIT';

const isVolatilityTriggerAlert = (alert?: TriggeredPriceAlert): alert is VolatilityTriggeredPriceAlert =>
  alert?.type === 'VOLATILITY';

const isLimitTriggerAlert = (alert?: TriggeredPriceAlert): alert is LimitTriggeredPriceAlert => alert?.type === 'LIMIT';

const getGroupByTitle = (dateTriggered: DateTime) => {
  const realDaysDiff = dateTriggered.diff(DateTime.now(), 'days').days;
  const daysDiff = Math.round(Math.abs(realDaysDiff));

  if (dateTriggered.hasSame(DateTime.now(), 'day')) {
    return 'Today';
  }

  if (daysDiff <= 1) {
    return 'Yesterday';
  }

  return dateTriggered.toFormat(`dd LLL, yyyy`);
};

export { getGroupByTitle, isVolatilityAlert, isVolatilityTriggerAlert, isLimitAlert, isLimitTriggerAlert };

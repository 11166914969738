import React, { useCallback } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { UIStore } from '@shared/store';

import { useShouldHideMarketTicker } from '@hooks/Layout/useShouldSkip';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { AviaryMarketTicker } from './aviary/AviaryMarketTicker';
import { useMarketTickerAnalytics } from './useMarketTickerAnalytics';

export const SLIDER_OPTIONS_WIDTH = 80;
export const TICKER_ITEM_WIDTH = 150;

const MarketTicker: React.FC = observer(() => {
  const { showMarketTicker: marketTickerEnabled, setShowMarketTicker: setMarketTickerEnabled } = UIStore.useUIStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const aviaryMarketTickerEnabled = isFeatureEnabled(AppFeature.AviaryMarketTicker);
  const isXs = useTailwindBreakpoint('xs');

  const shouldHideMarketTicker = useShouldHideMarketTicker();
  const { marketTickerToggled } = useMarketTickerAnalytics();

  const handleClose = useCallback(async () => {
    setMarketTickerEnabled(false);

    marketTickerToggled({ toggleState: false });
  }, [marketTickerToggled, setMarketTickerEnabled]);

  if (!marketTickerEnabled || isXs || shouldHideMarketTicker) return null;

  if (!aviaryMarketTickerEnabled) return null;

  return <AviaryMarketTicker onClose={handleClose} />;
});

MarketTicker.displayName = 'Page Banner';

export default MarketTicker;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { CloseUniversalTrade } from '@global-components/CloseUniversalTrade/CloseUniversalTrade';

import { UniversalTradeStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useDemoMode } from 'src/lib/utils/hooks';

type Props = {
  dismissible?: boolean;
};

const TradeUnverifiedAccount: React.FC<Props> = observer(({ dismissible }) => {
  const { t } = useTranslation('trade');
  const { setDemoMode } = useDemoMode();
  const { setShowGlobalTrade } = UniversalTradeStore;
  const { navigate } = useNavigateRoute();

  const handleTryDemoMode = () => {
    setDemoMode(true);
    setShowGlobalTrade(false);
  };

  const handleVerification = () => {
    navigate(NavigationURLs.Onboarding);
    setShowGlobalTrade(false);
  };

  return (
    <FlexLayout
      direction='column'
      className='h-full p-24 text-center'
      alignItems='center'
      justifyContent='center'
      spacing={16}
    >
      {dismissible && (
        <FlexLayout className='absolute right-16 top-16'>
          <CloseUniversalTrade />
        </FlexLayout>
      )}
      <Image image='trade' alt='universal trading unverified' usePalette className='h-[120px] w-[120px]' />
      <Body size='large' weight='emphasis' color='primary'>
        {t('unverifiedTradeOverview.title')}
      </Body>
      <Body size='small' color='secondary'>
        {t('unverifiedTradeOverview.subTitle')}
      </Body>
      <FlexLayout direction='column' spacing={8} className='w-full'>
        <Button onClick={handleVerification} className='w-full'>
          {t('unverifiedTradeOverview.buttonLabels.verify')}
        </Button>
        <Button variant='outlined' onClick={handleTryDemoMode} className='w-full'>
          {t('unverifiedTradeOverview.buttonLabels.demo')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
});

TradeUnverifiedAccount.displayName = 'TradeUnverifiedAccount';

export { TradeUnverifiedAccount };

import React, { useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { formatCurrency } from '@swyftx/currency-util';
import { Stack } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  value: Big;
  totalValue: Big;
  asset: Asset;
};

const AppHeaderBaseCurrencySelectorMenuItem: React.FC<Props> = observer(({ value, totalValue, asset }) => {
  const { getContrastAssetColor } = useTheme();
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const { navigate } = useNavigateRoute();
  const { all } = useAssetBalance(asset);

  const percentage = useMemo(() => {
    if (!baseAsset) return 0;

    const valueInBase = Big(getRate(asset).midPrice).times(value);
    const totalValueInBase = Big(getRate(baseAsset).midPrice).times(totalValue);

    return valueInBase.div(totalValueInBase).times(100).toNumber();
  }, [totalValue, value, getRate, asset, baseAsset]);

  const openWallet = () => {
    navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: asset.code } });
  };

  const getColor = (val: string) => {
    const bigValue = Big(val);

    if (bigValue.gt(0)) return 'success';
    if (bigValue.lt(0)) return 'destructive';

    return 'secondary';
  };

  const getTextColor = (val: string) => {
    const bigValue = Big(val);

    if (bigValue.gt(0)) return 'success';
    if (bigValue.lt(0)) return 'error';

    return 'secondary';
  };

  return (
    <FlexLayout
      direction='column'
      className='cursor-pointer rounded-8 px-6 py-12 hover:bg-color-background-surface-hover'
      onClick={openWallet}
    >
      <FlexLayout direction='row' className='items-center' spacing={12}>
        <AssetIcon asset={asset} size={20} />
        <FlexLayout direction='column' className='w-full' spacing={4}>
          <FlexLayout direction='row' className='w-full' justifyContent='space-between'>
            <Body color='primary'>{asset?.code}</Body>
            <NumericDataItem
              data={formatCurrency(
                Big(getRate(asset).midPrice)
                  .times(value || Big(1))
                  .toString(),
                { isFiat: baseAsset?.assetType === AssetType.Fiat },
              )}
              size='medium'
              color='primary'
              weight='emphasis'
            />
          </FlexLayout>
          <Stack direction='row' width='100%' justifyContent='space-between'>
            <FormattedText value={value} currency={asset} formatBalance />
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
              {all.change && all.change !== '0' && (
                <NumericDataItem
                  data={formatCurrency(all.change, { isFiat: baseAsset?.assetType === AssetType.Fiat })}
                  size='medium'
                  color={getTextColor(all.change)}
                />
              )}

              <Chip variant='subtle' color={getColor(all.changePercent ?? '0')}>{`${
                Big(all.changePercent || '0').gt(0) ? '+' : ''
              }${Big(all.changePercent || '0')
                .toNumber()
                .toLocaleString([], { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`}</Chip>
            </Stack>
          </Stack>
        </FlexLayout>
      </FlexLayout>
      <div className='relative z-10 mt-8 h-[6px] w-full overflow-hidden rounded-[8px] bg-color-background-surface-active'>
        <div
          className='absolute left-0 top-0 z-20 h-[6px]'
          style={{
            width: `${percentage}%`,
            backgroundColor: asset
              ? getContrastAssetColor(asset.code)
              : getComputedStyle(document.body).getPropertyValue('--color-background-info'),
          }}
        />
      </div>
    </FlexLayout>
  );
});

export { AppHeaderBaseCurrencySelectorMenuItem };

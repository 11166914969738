import React, { useEffect } from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Chat, Cross, DarkMode, DollarInCircle, Hamburger, Heart, Help, LogOut } from '@swyftx/aviary/icons/outlined';
import { AppHeaderMenuProfile } from '@swyftx/aviary/molecules/AppHeaderMenu/AppHeaderMenuProfile';
import { AppSideBarItem } from '@swyftx/aviary/molecules/AppSideBar/AppSideBarItem';

import { SideBarMobileHeaderSwitchItem, SideBarMobileHeaderCurrency } from '@global-components/Navigation/components';

import env from '@shared/config';
import { Links } from '@shared/constants';
import { UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { useLogout } from '@hooks/Logout';

import { useToggle } from '@react-hookz/web';
import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { SwitchPlatformMenuItem } from 'src/lib/user-profile/components/SwitchPlatformMenuItem/SwitchPlatformMenuItem';
import { useToggleTheme } from 'src/lib/utils/hooks';

const SwyftxProAppNavigation: React.FC = observer(() => {
  const [showNavigation, toggleShowNavigation] = useToggle(undefined);
  const { isUserVerified, isEntity, userProfile } = UserStore.useUserStore;
  const logout = useLogout();
  const { isDarkMode, toggleDarkMode } = useToggleTheme();
  const { show: openLiveChat } = useIntercom();
  const usersnapApi = useUsersnapApi();
  const feedbackID = env.USERSNAP_FEEDBACK_PROJECT_ID;
  const isMd = useTailwindBreakpoint('md');

  useEffect(() => {
    if (isMd) toggleShowNavigation(false);
  }, [isMd, toggleShowNavigation]);

  return (
    <>
      <Button
        className='block md:hidden'
        layout='icon'
        variant='outlined'
        leadingIcon={<Hamburger />}
        onClick={() => toggleShowNavigation(true)}
      />

      <>
        {showNavigation && (
          <div
            className='absolute left-0 top-0 z-sidebar-backdrop h-[100vh] w-[100vw] cursor-pointer overflow-auto bg-black opacity-30 md:hidden'
            onClick={() => toggleShowNavigation(false)}
          />
        )}
        <FlexLayout
          direction='column'
          className={cn(
            showNavigation ? '!translate-x-[0%]' : '!translate-x-[-100%]',
            'absolute left-0 top-0 z-sidebar h-[100vh] max-h-[100vh] w-[90vw] max-w-[25rem] -translate-x-[100%] overflow-y-auto bg-color-background-surface-primary p-16 transition-transform duration-200 md:hidden',
          )}
          justifyContent='space-between'
          spacing={4}
        >
          <FlexLayout direction='column'>
            <FlexLayout alignItems='start' justifyContent='space-between'>
              <AppHeaderMenuProfile isEntity={isEntity()} isUserVerified={isUserVerified()} user={userProfile} />
              <Button
                layout='icon'
                variant='outlined'
                leadingIcon={<Cross />}
                onClick={() => toggleShowNavigation(false)}
              />
            </FlexLayout>
            <Separator className='my-12 bg-color-border-main' />
            <SideBarMobileHeaderCurrency title='Currency' icon={<DollarInCircle width={24} height={24} />} />
            <SideBarMobileHeaderSwitchItem
              title='Theme'
              icon={<DarkMode width={24} height={24} />}
              checked={isDarkMode}
              onClick={() => toggleDarkMode(!isDarkMode)}
              color='primary'
            />
            <Separator className='my-12 bg-color-border-main' />
            <SwitchPlatformMenuItem />
            <Separator className='my-12 bg-color-border-main' />
            <Accordion type='single' className='rounded-8' collapsible>
              <AccordionItem
                value='support'
                className='h rounded-8 bg-color-background-surface-primary p-0 text-color-text-primary'
              >
                <AccordionTrigger className='p-8 hover:bg-color-background-surface-hover'>
                  <FlexLayout spacing={24}>
                    <Help />
                    <Body weight='emphasis'>Support</Body>
                  </FlexLayout>
                </AccordionTrigger>
                <AccordionContent className='mx-0 w-full pt-8'>
                  <FlexLayout direction='column' spacing={8}>
                    <AppSideBarItem
                      item={{
                        title: 'Swyftx Support centre',
                        onClick: () => {
                          window.open(Links.help.home, '_blank', 'noopener,noreferrer');
                          toggleShowNavigation(false);
                        },
                        icon: <Help />,
                      }}
                      className='px-0'
                    />
                    <AppSideBarItem
                      item={{
                        title: 'Live chat',
                        onClick: () => {
                          openLiveChat();
                          toggleShowNavigation(false);
                        },
                        icon: <Chat />,
                      }}
                      className='px-0'
                    />
                    <AppSideBarItem
                      item={{
                        title: 'Provide feedback',
                        onClick: () => {
                          usersnapApi?.show(feedbackID).then((widgetApi) => widgetApi.open({}));
                          toggleShowNavigation(false);
                        },
                        icon: <Heart />,
                      }}
                      className='px-0'
                    />
                  </FlexLayout>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </FlexLayout>
          <Button variant='outlined' className='w-full' size='lg' leadingIcon={<LogOut />} onClick={logout}>
            Log out
          </Button>
        </FlexLayout>
      </>
    </>
  );
});

export { SwyftxProAppNavigation };

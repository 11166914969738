/** process tag to get in format [TAG_NAME] */
export function normaliseTag(tag: string) {
  if (tag) {
    let fTag = tag.toUpperCase().replace(/ /g, '_');
    if (fTag[0] !== '[') fTag = `[${fTag}`;
    if (fTag[fTag.length - 1] !== ']') fTag += ']';
    return fTag;
  }
  return '';
}

import { useCallback, useMemo } from 'react';

import { getPriceScale } from '@swyftx/currency-util';

import { Asset, AssetType } from '@shared/api';
import { DEFAULT_PRICE_SCALE } from '@shared/constants';
import { RatesStore } from '@shared/store';

export const usePriceScale = (asset?: Asset, ignoreAssetScale = false) => {
  const { getRate } = RatesStore.useRatesStore;

  const calculatePriceScale = useCallback(
    (assetToCalculate: Asset, ignoreAssetPriceScale = false) => {
      if (assetToCalculate.assetType === AssetType.Fiat) return 2;
      const assetRate = getRate(assetToCalculate.id).midPrice;
      const scale = getPriceScale(assetRate);

      return Math.max(scale, ignoreAssetPriceScale ? 0 : assetToCalculate.price_scale);
    },
    [getRate],
  );

  const priceScale = useMemo(() => {
    if (asset) {
      return calculatePriceScale(asset, ignoreAssetScale);
    }
    return DEFAULT_PRICE_SCALE;
  }, [asset, calculatePriceScale, ignoreAssetScale]);

  return { priceScale, calculatePriceScale };
};

import { useContext } from 'react';

import { DragAndDropRowType } from '@global-components/DragAndDrop/DragAndDrop.data';

import { DashboardTile, DashboardTileSize } from '@shared/api';
import { mergeDeep } from '@shared/utils';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { DashboardContext } from '@Dashboard/Dashboard.context';
import { MAXIMUM_COLUMN_SPACING } from '@Dashboard/Dashboard.data';
import { getTile } from '@Dashboard/components';

import { toJS } from 'mobx';

const useDashboardRows = () => {
  const { editMode, editTiles, setEditTiles } = useContext(DashboardContext);
  const { isDesktopContent, isMediumContent } = useContentBreakpoint();

  const getItemSpacing = (size: DashboardTileSize): 4 | 6 | 8 | 12 => {
    let spacing: 4 | 6 | 8 | 12;

    switch (size) {
      case 'compact':
        if (isDesktopContent) {
          spacing = 4;
        } else if (isMediumContent) {
          spacing = 6;
        } else {
          spacing = 12;
        }
        break;
      case 'half':
        if (isDesktopContent) {
          spacing = 6;
        } else if (isMediumContent) {
          spacing = 6;
        } else {
          spacing = 12;
        }
        break;
      case 'wide':
        if (isDesktopContent) {
          spacing = 8;
        } else if (isMediumContent) {
          spacing = 6;
        } else {
          spacing = 12;
        }
        break;
      case 'full':
      default:
        spacing = 12;
        break;
    }

    return spacing;
  };

  const getRows = () => {
    const rows: DragAndDropRowType[] = new Array(20);

    let rowIndex = 0;
    let columnIndex = 0;
    let currentColumnSpacing = 0;

    const sortedTiles: DashboardTile[] = toJS(editTiles);

    sortedTiles.sort((a, b) => {
      if (!a?.metadata?.webIndex) return -1;
      if (!b?.metadata?.webIndex) return 1;
      return a.metadata.webIndex < b.metadata.webIndex ? -1 : 1;
    });

    sortedTiles
      .filter((tile) => tile.display || editMode)
      .forEach((tile: DashboardTile, tileIndex) => {
        const itemSpacing = getItemSpacing(tile.metadata?.size || 'compact');

        currentColumnSpacing += itemSpacing;
        if (currentColumnSpacing > MAXIMUM_COLUMN_SPACING) {
          currentColumnSpacing = itemSpacing;
          rowIndex += 1;
          columnIndex = 0;
        }

        const row = rows[rowIndex] || {};
        const component = { ...getTile(tile) };

        row.items = row.items || [];

        row.items.splice(columnIndex, 0, {
          id: tile.name,
          component,
          spacing: itemSpacing,
          visible: tile.display,
        });

        rows[rowIndex] = row;

        if (tileIndex !== editTiles.length - 1) {
          if (currentColumnSpacing === MAXIMUM_COLUMN_SPACING || tile?.metadata?.offset) {
            currentColumnSpacing = 0;
            rowIndex += 1;
            columnIndex = 0;
          } else {
            columnIndex += 1;
          }
        }
      });

    return rows.filter((r) => r.items.length);
  };

  const updateRows = (rows: DragAndDropRowType[]) => {
    const newTiles: DashboardTile[] = [];
    let newTileIndex = 0;

    rows.forEach((row) => {
      let totalSpacing = 0;

      row.items.forEach((item, itemIndex) => {
        const arrayIndex = editTiles.findIndex((editTile) => editTile.name === item.id);

        if (arrayIndex !== -1) {
          const tile = editTiles[arrayIndex];
          let newTile = toJS(tile);
          const isLastItem = itemIndex === row.items.length - 1;
          const itemSpacing = getItemSpacing(tile?.metadata?.size || 'compact');
          totalSpacing += itemSpacing;

          let offset;
          if (isLastItem && totalSpacing < MAXIMUM_COLUMN_SPACING) {
            offset = true;
          } else if (tile?.metadata?.offset) {
            offset = false;
          }

          newTile = mergeDeep({ ...newTile }, { metadata: { offset, webIndex: newTileIndex } });
          newTileIndex += 1;

          newTiles.splice(arrayIndex, 0, newTile);
        }
      });
    });

    setEditTiles(newTiles);
  };

  return {
    getItemSpacing,
    getRows,
    updateRows,
  };
};

export { useDashboardRows };

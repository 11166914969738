import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

const PRIMARY_COLOR = Colors.Purple[3];
const HOVER_COLOR = Colors.Purple[1];

const EntityOneLightThemePalette: any = {
  mode: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: Colors.Grey[10]!,
    secondary: Colors.Grey[7]!,
    disabled: Colors.Grey[5],
  },
  divider: Colors.Grey[3],
  background: {
    paper: Colors.White,
    default: Colors.Grey[1],
  },
  getContrastText: () => Colors.Black,
  augmentColor: () => ({
    main: PRIMARY_COLOR,
    light: PRIMARY_COLOR,
    dark: PRIMARY_COLOR,
    contrastText: Colors.White,
  }),
  action: {
    hover: HOVER_COLOR,
  },
  grey: {
    50: Colors.Grey[1],
    100: Colors.Grey[2],
    200: Colors.Grey[3],
    300: Colors.Grey[4],
    400: Colors.Grey[5],
    500: Colors.Grey[6]!,
    600: Colors.Grey[7]!,
    700: Colors.Grey[8]!,
    800: Colors.Grey[9]!,
    900: Colors.Grey[10]!,
    A100: Colors.Grey[2],
    A200: Colors.Grey[3],
    A400: Colors.Grey[5],
    A700: Colors.Grey[8]!,
  },
  primary: {
    main: PRIMARY_COLOR,
  },
  secondary: {
    main: PRIMARY_COLOR,
  },
  error: {
    main: Colors.Red[3],
  },
  warning: {
    main: Colors.Orange[3],
  },
  info: {
    main: Colors.Blue[3],
  },
  success: {
    main: Colors.Green[3],
  },
  common: {
    black: Colors.Black,
    white: Colors.White,
  },
};

const EntityOneLightTheme = createTheme(EntityOneLightThemePalette);

export { EntityOneLightTheme };

import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { TransactionHistoryItem } from '@shared/services';

import { OrderDetailsLinkedOrdersItem } from './OrderDetailsLinkedOrdersItem';

interface Props {
  transaction: TransactionHistoryItem;
}

const OrderDetailsLinkedOrders: React.FC<Props> = ({ transaction }) => (
  <FlexLayout direction='column' spacing={8} className='px-16'>
    <Separator className='mb-8' />
    <Body weight='emphasis'>Linked orders</Body>
    {transaction.orderSourceUuid && <OrderDetailsLinkedOrdersItem orderUuid={transaction.orderSourceUuid} />}
    {transaction.linkedOrderUuid && <OrderDetailsLinkedOrdersItem orderUuid={transaction.linkedOrderUuid} />}
    {transaction.childOrders?.map((orderUuid: string) => (
      <OrderDetailsLinkedOrdersItem key={orderUuid} orderUuid={orderUuid} />
    ))}
  </FlexLayout>
);

export { OrderDetailsLinkedOrders };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const OTCTrade: React.FC<Props> = ({ className, ...props }) => (
  <svg
    {...props}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M4.10938 6.14743C4.10938 3.85707 5.96644 2 8.25683 2C10.5472 2 12.4042 3.85707 12.4042 6.14743'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.94617 11.4995H8.77777C9.37123 11.4995 9.85123 11.0727 9.85123 10.4792C9.85123 9.98556 9.51723 9.59116 9.03917 9.47049C8.35417 9.29862 7.6705 9.16369 6.9557 9.18122C5.82318 9.20909 5.03053 9.84022 4.13281 10.4409'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.94683 11.522C8.36717 11.4604 8.79523 11.5227 9.2201 11.5129C10.2054 11.4889 10.9773 10.6567 11.7272 10.0917C12.1105 9.80312 12.6469 9.84139 12.9855 10.1819C13.3617 10.5588 13.3617 11.1711 12.9855 11.5486C12.176 12.3613 11.4599 13.1157 10.363 13.5425C8.83937 14.1354 7.42597 13.8474 5.8835 13.5425C5.28675 13.4245 4.7367 13.4173 4.13281 13.4173'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.35938 13.9948C3.83613 13.9948 4.13256 13.6588 4.13256 13.1827V10.3981C4.13256 9.92194 3.83613 9.58594 3.36002 9.58594'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.48899 5.77344H7.49219V7.20023H8.51979C8.93665 7.20023 9.27005 6.84236 9.22959 6.41664C9.19419 6.04572 8.86159 5.77344 8.48899 5.77344Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.48899 4.35156H7.49219V5.77834H8.51979C8.93665 5.77834 9.27005 5.42048 9.22959 4.99477C9.19419 4.62386 8.86159 4.35156 8.48899 4.35156Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M8.23438 7.62379V7.20312' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.23438 4.34254V3.92188' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export { OTCTrade };

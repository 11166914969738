/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';

import InputAdornment from '@mui/material/InputAdornment';

import { CrossInCircle, Search } from '@swyftx/aviary/icons/outlined';
import { Input, InputProps } from '@swyftx/react-web-design-system';

type Props = InputProps & {
  onDelete: () => void;
  value: string;
  hideClear?: boolean;
};

const SearchInput: React.FC<Props> = (props) => {
  const { hideClear, onDelete, inputProps, ...iProps } = props;
  const ref = useRef<HTMLInputElement>(null);

  const onClear = () => {
    onDelete();
    ref.current?.focus();
  };

  return (
    <Input
      {...iProps}
      inputRef={ref}
      inputProps={{
        ...inputProps,
        startAdornment: (
          <InputAdornment position='start'>
            <Search id='search-icon' className='h-20 w-20' />
          </InputAdornment>
        ),
        endAdornment:
          !hideClear && iProps.value?.length > 0 ? (
            <InputAdornment position='end'>
              <CrossInCircle className='h-20 w-20 cursor-pointer' onClick={onClear} id='cross-icon' />
            </InputAdornment>
          ) : undefined,
      }}
    />
  );
};

export { SearchInput };

import { useCallback, useMemo } from 'react';

import { api } from '@shared/api';
import { UserStore } from '@shared/store';

import UserService from '@services/UserService';

import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

export const useEntityOnboardingApplication = () => {
  const { userId } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const entityOnboardingEnabled = isFeatureEnabled(AppFeature.EntityOnboarding);

  const fetchApplicationHistory = useCallback(async () => {
    if (!entityOnboardingEnabled) return;
    const { data } = await api.endpoints.getEntityApplicationHistory();
    return data;
  }, [entityOnboardingEnabled]);

  const { data, isFetching } = useQuery({
    queryKey: ['entityApplicationHistory', userId],
    queryFn: () => fetchApplicationHistory(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const startNewEntityApplication = useCallback(async () => {
    await api.endpoints.createEntityApplication();
    navigate(NavigationURLs.EntityOnboarding);
  }, [navigate]);

  const goToExistingApplication = useCallback(() => navigate(NavigationURLs.EntityOnboarding), [navigate]);

  const inProgressSubmissions = useMemo(
    () => [...(data ?? [])].filter((entity) => entity.status === 'STARTED'),
    [data],
  );

  const submittedSubmissions = useMemo(
    () => [...(data ?? [])].filter((entity) => entity.status === 'SUBMITTED'),
    [data],
  );

  const updateApplyForEntityAccountFlag = useCallback((enabled = false) => {
    UserService.UpdateUserSettings({ data: { applyForEntityAccount: enabled } });
  }, []);

  /**
   * Ensures the user has a submission with status: 'SUBMITTED' within the last month.
   */
  const hasASubmittedSubmission = useMemo(
    () =>
      submittedSubmissions.length &&
      submittedSubmissions[0].submittedAt > DateTime.now().minus({ months: 1 }).toMillis(),
    [submittedSubmissions],
  );

  return {
    entityHistory: data ?? [],
    entityHistoryFetching: isFetching,
    inProgressSubmissions,
    submittedSubmissions,
    startNewEntityApplication,
    goToExistingApplication,
    updateApplyForEntityAccountFlag,
    hasASubmittedSubmission,
  };
};

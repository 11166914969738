import React, { useMemo } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

type Props = {
  value?: string;
  onChange: (newValue?: string) => void;
};

const AccountHolderNameInput: React.FC<Props> = ({ value, onChange }) => {
  const hasError = useMemo(() => {
    if (!value) return false;
    return value.length < 2;
  }, [value]);

  return (
    <FlexLayout direction='column' spacing={8}>
      <FlexLayout spacing={4}>
        <Body weight='emphasis' size='medium'>
          Account holder name
        </Body>
        <Body color='error'>*</Body>
      </FlexLayout>
      <Input
        placeholder='Account holder name'
        value={value}
        error={hasError}
        onChange={(e) => onChange(e.target.value)}
      />
      {hasError && (
        <Body size='small' color='error'>
          Account holder name is invalid
        </Body>
      )}
    </FlexLayout>
  );
};

export { AccountHolderNameInput };

import {
  CashDepositPercentageOption,
  ExpectedAnnualSpendOption,
  SourceOfWealthOption,
} from '@shared/api/@types/compliance';

export const ExpectedInvestmentsOptionsText = {
  [ExpectedAnnualSpendOption.LESS_THAN_50K]: '< $50,000',
  [ExpectedAnnualSpendOption.BETWEEN_50K_AND_100K]: '$50,000 - $100,000',
  [ExpectedAnnualSpendOption.BETWEEN_100K_AND_250K]: '$100,000 - $250,000',
  [ExpectedAnnualSpendOption.BETWEEN_250K_AND_500K]: '$250,000 - $500,000',
  [ExpectedAnnualSpendOption.BETWEEN_500K_AND_1M]: '$500,000 - $1,000,000',
} as const;

export const SourcesOfIncomeOptionsText = {
  [SourceOfWealthOption.OCCUPATIONAL_INCOME]: 'Occupational income',
  [SourceOfWealthOption.INHERITANCE]: 'Inheritance',
  [SourceOfWealthOption.BUSINESS_OWNERSHIP]: 'Business ownership',
  [SourceOfWealthOption.RENTAL_INCOME]: 'Rental income / Real estate',
  [SourceOfWealthOption.INVESTMENT_INCOME]: 'Investment income',
  [SourceOfWealthOption.INTELLECTUAL_PROPERTY]: 'Intellectual property',
  [SourceOfWealthOption.GAMBLING_OR_LOTTERY]: 'Lottery/gambling winnings',
  [SourceOfWealthOption.SELF_MANAGED_SUPER_FUND]: 'Self-managed Superannuation Fund',
  [SourceOfWealthOption.SAVINGS]: 'Savings',
} as const;

export const CashDepositsText = {
  [CashDepositPercentageOption.LESS_THAN_20]: 'Less than 20%',
  [CashDepositPercentageOption.GREATER_THAN_20]: 'Greater than 20%',
};

import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDataPersistence } from '@shared/hooks';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useIntercomEvent } from '@hooks/useIntercomEvent/useIntercomEvent';
import { useMarketingTags } from '@hooks/useMarketingTags/useMarketingTags';
import { LoginContext } from '@routes/Login/Login.context';
import { RewardsContext } from '@routes/Rewards/Rewards.context';
import { GTMService } from '@services/Analytics/GTMService';
import UserService from '@services/UserService';

import * as Sentry from '@sentry/react';
import { useIntercom } from 'react-use-intercom';
import { NavigationURLs } from 'src/lib/navigation/types';

const useInitApp = () => {
  const { dataStorageEffect } = useDataPersistence();
  const { isLargeScreen } = useScreenBreakpoints();

  const { userProfile } = UserStore.useUserStore;
  const { extractFromParams } = useMarketingTags();
  const [searchParams] = useSearchParams();

  const [loadedFromStorage, setLoadedFromStorage] = useState(false);
  const [loadedUserData, setLoadedUserData] = useState(false);
  const { boot, update } = useIntercom();
  const { init } = useIntercomEvent();
  const avo = useAvo();
  const { initThirdParty } = useContext(LoginContext);
  const { fetchActionable } = useContext(RewardsContext);

  useEffect(() => {
    extractFromParams(searchParams);
  }, [extractFromParams, searchParams]);

  useEffect(() => {
    async function handleDataStorageEffect() {
      await dataStorageEffect();
      setLoadedFromStorage(true);
    }

    handleDataStorageEffect();

    GTMService.init();

    return () => {
      GTMService.cleanup();
    };
  }, [dataStorageEffect]);

  useEffect(() => {
    boot({
      alignment: 'left',
      horizontalPadding: 20,
    });
    init();
  }, [boot, init]);

  useEffect(() => {
    if (userProfile?.intercom?.uuid && window.location.pathname !== NavigationURLs.RewardsRoot) {
      fetchActionable(userProfile.intercom.uuid);
    }
  }, [userProfile?.intercom?.uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    update({
      hideDefaultLauncher: !isLargeScreen,
    });
  }, [isLargeScreen, update]);

  useEffect(() => {
    if (userProfile && userProfile.intercom) {
      if (avo) {
        avo.webIdentify({ userId_: userProfile.intercom.uuid });
      }
      Sentry.setUser({ id: userProfile.intercom?.uuid });
    }
  }, [userProfile, avo, boot, update, isLargeScreen]);

  useEffect(() => {
    const initUserProfile = async () => {
      const response = await UserService.InitUserProfile(initThirdParty);

      const { user } = response || {};
      if (user && !UserService.IsEntity(user)) {
        initThirdParty(user);
      }
      setLoadedUserData(true);
    };

    initUserProfile();
  }, [(window as any).Intercom, initThirdParty]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      (window as any).Intercom('shutdown');
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        (window as any).Intercom('shutdown');
      });
    };
  }, []);

  return {
    loadedUserData,
    loadedFromStorage,
  };
};

export { useInitApp };

import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import { darken } from '@mui/system';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { performanceMenuControls } from '@global-components/PerformanceMenu/PerformanceMenu.const';
import { PerformanceMenuControl } from '@global-components/PerformanceMenu/PerformanceMenu.types';

import { useTheme } from '@hooks/useTheme';

type Props = {
  selectedControl: PerformanceMenuControl;
  setSelectedControl: (control: PerformanceMenuControl) => void;
  disabled?: boolean;
  noPadding?: boolean;
};

// TODO cancel existing network request upon new selection
const PortfolioPerformanceChartMenu: React.FC<Props> = ({
  selectedControl,
  setSelectedControl,
  noPadding,
  disabled,
}) => {
  const { theme } = useTheme();
  const bgColor = theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[600];

  const handleClick = (item: PerformanceMenuControl) => {
    setSelectedControl(item);
  };

  return (
    <Stack spacing={1} direction='row' padding={noPadding ? 0 : 2}>
      {performanceMenuControls.map((item) => {
        const selected = selectedControl.label === item.label;
        const buttonColor = (() => {
          if (disabled) {
            return bgColor;
          }
          return selected ? theme.palette.action.hover : bgColor;
        })();

        return (
          <ButtonBase
            key={item.label}
            onClick={() => handleClick(item)}
            disabled={disabled}
            sx={{
              backgroundColor: buttonColor,
              width: '100%',
              paddingX: 1.5,
              paddingY: 1,
              borderRadius: 1,
              transition: '0.1s',
              '&:hover': {
                backgroundColor: darken(theme.palette.action.hover, 0.1),
              },
            }}
          >
            <Typography fontWeight={500} color='text.primary' fontSize={pxToRem(14)}>
              {item.label}
            </Typography>
          </ButtonBase>
        );
      })}
    </Stack>
  );
};

PortfolioPerformanceChartMenu.displayName = 'PortfolioPerformanceChartMenu';

export { PortfolioPerformanceChartMenu };

import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { Gift } from '@swyftx/aviary/icons/outlined';
import { types } from '@swyftx/marketing-utils';
import { Button, Card, Chip, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { ErrorMessageBox } from '@global-components/message-boxes/ErrorMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { assetService } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { RewardsContext } from '@routes/Rewards/Rewards.context';

import { observer } from 'mobx-react-lite';

import { RewardCardCountdown } from './RewardCardCountdown';
import { RewardCardProgress } from './RewardCardProgress';
import { RewardCardRedeemStatus } from './RewardCardRedeemStatus';
import { useReward } from '../../hooks/useReward.hooks';

type Props = {
  reward: types.UserAssetReward;
};

const RewardCard: React.FC<Props> = observer(({ reward }) => {
  const { buttonColor, buttonLabel, buttonVariant, buttonDisabled } = useReward(reward);
  const { addMessageBox } = UIStore.useUIStore;
  const { userProfile } = UserStore.useUserStore;
  const { t } = useTranslation('rewards', { keyPrefix: 'rewardCard' });
  const { bx } = useContentBreakpoint();
  const { optIn, redeemReward } = useContext(RewardsContext);

  const rewardLabel = useMemo(() => {
    if (!reward) return null;

    const { rewardAssetId, valueAssetId, hasPayout, valueAmount } = reward;
    const isEntryDraw = !hasPayout || !valueAssetId || !rewardAssetId || !valueAmount;

    if (isEntryDraw) {
      return (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box
            bgcolor='primary.main'
            borderRadius='50%'
            width={18}
            height={18}
            display='flex'
            alignItems='center'
            justifyContent='center'
            color='white'
          >
            <Gift className='h-12 w-12 text-inherit' />
          </Box>
          <Typography fontSize={12} fontWeight={500}>
            {t('entryIntoDraw')}
          </Typography>
        </Stack>
      );
    }

    const valueAsset = assetService.getAsset(valueAssetId);
    const rewardAsset = assetService.getAsset(rewardAssetId);
    const suffix = rewardAssetId === valueAssetId ? '' : `of ${rewardAsset?.code}`;

    return (
      <Stack direction='row' alignItems='center' spacing={1}>
        <AssetIcon asset={rewardAsset} size={18} />
        <Typography fontSize={12} fontWeight={500}>
          {formatCurrency(valueAmount, valueAsset, { appendCode: true })} {suffix}
        </Typography>
      </Stack>
    );
  }, [reward]);

  const onAction = () => {
    if (!reward.redemptions.length) {
      if (userProfile?.intercom?.uuid) {
        optIn(reward.id, userProfile.intercom.uuid);
        addMessageBox({
          content: (
            <SuccessMessageBox title={t('subscribeMessage', { campaignName: (reward as any).campaignName || '' })} />
          ),
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
      } else {
        addMessageBox({
          content: (
            <ErrorMessageBox title={t('subscribeFailMessage', { campaignName: (reward as any).campaignName || '' })} />
          ),
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
      }
    } else if (userProfile?.intercom?.uuid) {
      redeemReward(reward, userProfile.intercom.uuid);
    }
  };

  const redemption = useMemo(() => {
    if (!reward.redemptions.length) return undefined;
    return reward.redemptions[0];
  }, [reward]);

  const showButton = useMemo(() => {
    const showButtonTypes: types.AssetRedemptionStatus[] = [
      types.AssetRedemptionStatus.InProgress,
      types.AssetRedemptionStatus.Qualified,
    ];
    if (!redemption && reward.status === types.AssetRewardStatus.Live) return true;
    if (redemption && redemption.status && showButtonTypes.includes(redemption?.status)) return true;

    return false;
  }, [redemption, reward]);

  const showCountdown = useMemo(() => {
    const showCountdownTypes: types.AssetRedemptionStatus[] = [
      types.AssetRedemptionStatus.InProgress,
      types.AssetRedemptionStatus.Qualified,
    ];
    if (!redemption && reward.status === types.AssetRewardStatus.Live) return true;
    if (redemption && showCountdownTypes.includes(redemption.status)) return true;

    return false;
  }, [reward, redemption]);

  return (
    <Card sx={{ width: '100%' }} variant='outlined'>
      <Stack
        direction={bx({ xs: 'column', sm: 'row' })}
        height='100%'
        width='100%'
        spacing={4}
        marginBottom={2}
        alignItems={bx({ xs: 'flex-start', sm: 'center' })}
        justifyContent='space-between'
      >
        <Stack
          direction='column'
          spacing={2}
          alignItems='flex-start'
          width={bx({ xs: '100%', sm: '60%' })}
          height='100%'
        >
          <Stack
            direction={bx({ xs: 'column', sm: 'row' })}
            alignItems={bx({ xs: 'flex-start', sm: 'center' })}
            spacing={1}
          >
            <Typography fontWeight={700} fontSize={20}>
              {(reward as any)?.campaignName || ''}
            </Typography>
            {rewardLabel && (
              <Chip
                size='small'
                sx={{ '.MuiChip-label ': { paddingLeft: 0.5 }, fontWeight: 500 }}
                color='default'
                label={rewardLabel}
              />
            )}
          </Stack>

          <Typography color='text.secondary' fontSize={14} width='100%'>
            {reward?.description}
          </Typography>

          {showButton && (
            <Box width='150px' display='flex' justifyContent='flex-end'>
              <Button
                onClick={onAction}
                color={buttonColor}
                sx={{ fontWeight: 500, minHeight: '36px' }}
                variant={buttonVariant}
                fullWidth
                disabled={buttonDisabled}
                size='large'
              >
                {buttonLabel}
              </Button>
            </Box>
          )}
        </Stack>
        <Stack direction='column' width='100%' spacing={1} height='100%'>
          <Typography color='text.secondary' fontSize={14} width='100%' fontWeight={600}>
            {reward.hasPayout ? t('requirementsToUnlock') : t('entryRequirements')}
          </Typography>
          <Stack direction='column' width='100%' spacing={1}>
            {redemption &&
              redemption.requirements.map((requirement) => (
                <RewardCardProgress
                  key={requirement.trigger}
                  redemptionRequirement={requirement}
                  redemption={redemption}
                />
              ))}
            {!redemption &&
              reward.qualifications &&
              reward.qualifications.map((qualification) => (
                <RewardCardProgress key={qualification.trigger} redemptionRequirement={qualification} />
              ))}
          </Stack>
        </Stack>
      </Stack>
      {showCountdown && <RewardCardCountdown reward={reward} />}
      {(redemption || (reward.endDate && reward.endDate.getTime() < new Date().getTime())) && (
        <RewardCardRedeemStatus redemption={redemption} />
      )}
    </Card>
  );
});

export { RewardCard };

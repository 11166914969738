import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

const AffiliateSignUp: React.FC = () => {
  const { openModal } = useModal();

  const { t } = useTranslation('profile.affiliates', { keyPrefix: 'signUp' });

  return (
    <FlexLayout direction='column' spacing={24}>
      <Card className='py-32'>
        <FlexLayout direction='column' className='flex items-center justify-center' spacing={8}>
          <Image image='affiliates' className='h-[100px] w-[100px]' usePalette alt='affilliate sign up' />
          <Body weight='emphasis'>{t('title')}</Body>
          <Body size='small' color='secondary'>
            {t('subTitle')}
          </Body>
          <Button onClick={() => openModal(Modals.AffiliateSignUp, { isUpdate: false })} className='mt-8'>
            {t('buttonLabels.becomeAffiliate')}
          </Button>
        </FlexLayout>
      </Card>

      <SettingsCluster
        title={t('labels.whyBecomeAffiliate')}
        items={[
          <FlexLayout className='m-16' key='whyBecomeAffiliate'>
            <Body size='small' color='secondary'>
              {t('labels.whyBecomeAffiliateDescription')}
            </Body>
          </FlexLayout>,
        ]}
      />
    </FlexLayout>
  );
};

export { AffiliateSignUp };

import React, { useCallback, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { RecurringOrderStatus } from '@shared/api/@types/recurringOrder';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  tabValue: RecurringOrderStatus;
  hasAutomatedOrders: boolean;
  disabledTooltip?: string;
};

const AutoInvestOrdersEmpty: React.FC<Props> = observer(({ tabValue, hasAutomatedOrders, disabledTooltip }) => {
  const { navigate } = useNavigateRoute();

  const onCreateOrderClick = useCallback(() => {
    navigate(NavigationURLs.AutoInvestCreate);
  }, [navigate]);

  const headingText = useMemo(() => {
    if (!hasAutomatedOrders) return 'No orders';
    switch (tabValue) {
      case RecurringOrderStatus.Active:
        return 'No active orders';
      case RecurringOrderStatus.Paused:
        return 'No paused orders';
      case RecurringOrderStatus.Expired:
        return 'No expired orders';
      default:
        return 'No orders';
    }
  }, [hasAutomatedOrders, tabValue]);

  const content = useMemo(() => {
    if (!hasAutomatedOrders) return "Once you've created orders, they will appear here.";
    switch (tabValue) {
      case RecurringOrderStatus.Active:
        return 'You have no orders scheduled.';
      case RecurringOrderStatus.Paused:
        return "You don't have any orders to resume.";
      case RecurringOrderStatus.Expired:
        return 'You have no orders that have expired.';
      default:
        return "Once you've created orders, they will appear here.";
    }
  }, [hasAutomatedOrders, tabValue]);

  return (
    <FlexLayout direction='column' spacing={16} alignItems='center' justifyContent='center'>
      <FlexLayout direction='column' spacing={8} alignItems='center' justifyContent='center'>
        <Image image='empty_order' className='mb-12 h-64 w-64' />
        <Heading size='h5'>{headingText}</Heading>
        <Body color='secondary'>{content}</Body>
      </FlexLayout>
      {!hasAutomatedOrders && (
        <div>
          <Button
            variant='outlined'
            onClick={onCreateOrderClick}
            disabled={!!disabledTooltip?.length}
            tooltip={disabledTooltip}
          >
            Create an order
          </Button>
        </div>
      )}
    </FlexLayout>
  );
});

export { AutoInvestOrdersEmpty };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { types } from '@swyftx/marketing-utils';
import { Typography } from '@swyftx/react-web-design-system';

type Props = {
  redemption?: types.UserAssetRedemption;
};

const RewardCardRedeemStatus: React.FC<Props> = ({ redemption }) => {
  const { t } = useTranslation('rewards', { keyPrefix: 'redeemStatus' });

  const redeemed = useMemo(() => {
    if (!redemption) return false;
    return (
      redemption.status === types.AssetRedemptionStatus.Redeemed ||
      redemption.status === types.AssetRedemptionStatus.Paid
    );
  }, [redemption]);

  const label = useMemo(() => {
    if (!redemption) return 'Time has expired to opt-in this reward.';

    switch (redemption.status) {
      case types.AssetRedemptionStatus.Expired:
        if (redemption.qualifiedAt === null) return t('completeExpired');
        return t('redeemExpired');
      case types.AssetRedemptionStatus.Redeemed:
      case types.AssetRedemptionStatus.Paid:
        return t('redeemedOn', {
          redeemedAtDate: redemption.redeemedAt?.toLocaleDateString(),
          redeemedAtTime: redemption.redeemedAt?.toLocaleTimeString('en-AU', { timeStyle: 'medium' }).toUpperCase(),
        });
      default:
        return '';
    }
  }, [redemption, t]);

  if (!label.length) return null;

  return (
    <FlexLayout direction='row' alignItems='center' className='bg-color-background-surface-secondary p-8'>
      <Typography color={redeemed ? 'text.secondary' : 'error'} number>
        {label}
      </Typography>
    </FlexLayout>
  );
};

export { RewardCardRedeemStatus };

import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Search: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.75 2C5.91751 2 2 5.91751 2 10.75C2 15.5825 5.91751 19.5 10.75 19.5C12.7761 19.5 14.6413 18.8114 16.1244 17.6554L21.0126 22.531C21.3059 22.8235 21.7808 22.8229 22.0733 22.5296C22.3658 22.2363 22.3652 21.7614 22.0719 21.4689L17.2254 16.635C18.6386 15.0809 19.5 13.016 19.5 10.75C19.5 5.91751 15.5825 2 10.75 2ZM3.5 10.75C3.5 6.74594 6.74594 3.5 10.75 3.5C14.7541 3.5 18 6.74594 18 10.75C18 14.7541 14.7541 18 10.75 18C6.74594 18 3.5 14.7541 3.5 10.75Z'
      fill='currentColor'
    />
  </svg>
);

export { Search };

import React from 'react';

import Box from '@mui/material/Box/Box';

import { Button } from '@swyftx/react-web-design-system';

import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

type Props = {
  children: React.ReactNode;
  onReset?: (...args: Array<unknown>) => void;
  onError?: (
    error: Error,
    info: {
      componentStack: string;
    },
  ) => void;
};

const DefaultErrorStateComponent: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <Box role='alert' paddingX={2}>
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </Box>
);

export const CompositeErrorBoundary: React.FC<Props> = ({ children, onReset, onError }) => (
  <ErrorBoundary FallbackComponent={DefaultErrorStateComponent} onReset={onReset} onError={onError}>
    {children}
  </ErrorBoundary>
);

import { useMemo } from 'react';

import { Asset, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useGainersAndLosers } from '@hooks/Assets/useGainersAndLosers';

type Props = {
  assets: Asset[];
};

const useMarketActivity = ({ assets }: Props) => {
  const { balances } = UserStore.useUserStore;
  const { gainers, losers } = useGainersAndLosers(assets);
  const newListings = useMemo(() => [...assets].sort((a, b) => Big(b.id).cmp(a.id)), [assets]);
  const topAssets = useMemo(
    () =>
      [...assets].sort((a, b) =>
        Number.isFinite(a.rank) && Number.isFinite(b.rank)
          ? Big(a.rank).cmp(b.rank)
          : Number.isFinite(a.rank)
          ? -1
          : Number.isFinite(b.rank)
          ? 1
          : 0,
      ),
    [assets],
  );

  const ownedAssets = useMemo(
    () =>
      assetService.getAssets(
        Object.values(balances)
          .filter((b: UserBalance) => Big(b.availableBalance).gt(0))
          .map((balance: UserBalance) => balance.assetId),
      ),
    [balances],
  );

  return {
    gainers,
    losers,
    newListings,
    /**
     * Currently owned assets i.e. assets with a positive balance
     */
    ownedAssets,
    topAssets,
  };
};

export { useMarketActivity };

import React, { useMemo } from 'react';

import BoxMUI, { BoxProps } from '@mui/material/Box';
import InputAdornmentMUI from '@mui/material/InputAdornment';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';

import { getPIIClassName, Input, PIITypes, Typography } from '@swyftx/react-web-design-system';
import { DangerIcon, TickIcon } from '@swyftx/react-web-design-system/assets';

import { FieldError } from 'react-hook-form';

export type FormInputProps = {
  success?: boolean;
  ContainerProps?: BoxProps;
  error?: FieldError;
} & Omit<MuiTextFieldProps, 'error'> &
  PIITypes;

export const FormInput: React.FC<FormInputProps> = ({
  PII,
  ContainerProps,
  label,
  required,
  InputProps,
  error,
  success,
  ...rest
}: FormInputProps) => {
  const endAdornment = error ? (
    <InputAdornmentMUI position='end'>
      <DangerIcon color='error' />
    </InputAdornmentMUI>
  ) : success ? (
    <InputAdornmentMUI position='end'>
      <TickIcon color='success' />
    </InputAdornmentMUI>
  ) : (
    InputProps?.endAdornment
  );

  const hasError = useMemo(() => error && (error.message?.length || 0) > 0, [error]);

  return (
    <BoxMUI className={getPIIClassName(PII)} {...ContainerProps} sx={{ ...ContainerProps?.sx, width: '100%' }}>
      {label && (
        <Typography fontWeight={600} color='text.primary' fontSize={14} marginBottom='.5rem' fontFamily='Public Sans'>
          {label}
          {required && <span style={{ color: '#EF2D4E' }}>*</span>}
        </Typography>
      )}
      <Input
        PII
        error={error !== undefined}
        InputProps={{
          ...InputProps,
          sx: { height: '48px', fontSize: '14px' },
          startAdornment: InputProps?.startAdornment,
          endAdornment,
        }}
        sx={{
          width: '100%',
          '.MuiFormHelperText-root': {
            marginTop: '4px',
            lineHeight: '1',
          },
        }}
        {...rest}
      />
      <Typography color='error.main' fontSize={12} height={18} visibility={hasError ? 'visible' : 'hidden'}>
        {error?.message}
      </Typography>
    </BoxMUI>
  );
};

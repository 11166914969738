import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';
import Wizard from '@global-components/Wizard';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { SwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';
import UserService from '@services/UserService';

const INITIAL_CODE_TIMEOUT = 60;

type Props = {
  onClose: () => void;
};

export const VerifyPhoneModal: React.FC<Props> = ({ onClose }) => {
  const { userProfile, setUserProfile } = UserStore.useUserStore;
  const { addMessageBox } = UIStore.useUIStore;
  const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const { t } = useTranslation('profile');

  const phone = userProfile?.phone;

  const handleError = useCallback((e: any) => {
    const { errorMessage } = e as SwyftxError;
    setError(errorMessage);
  }, []);

  const sendSMSMessageNotification = () => {
    addMessageBox({
      content: (
        <InformationMessageBox
          PII
          title="We've sent you a message"
          content={`The action you are trying to complete requires SMS verification. A code will be sent to ${phone}.`}
        />
      ),
    });
  };

  const handleNewPhone = useCallback(
    async (phone: string) => {
      if (userProfile) {
        const userProfileUpdated = { ...userProfile };
        userProfileUpdated.phone = phone;
        if (userProfileUpdated.verification) userProfileUpdated.verification.phone = 0;
        setUserProfile(userProfileUpdated);
        sendSMSMessageNotification();
      }
    },
    [onClose],
  );

  const requestVerification = useCallback(async () => {
    try {
      await UserService.RequestPhoneVerification();
      sendSMSMessageNotification();
    } catch (e) {
      handleError(e);
    }
  }, [handleError, handleNewPhone]);

  const checkVerification = useCallback(async () => {
    try {
      await UserService.CheckPhoneVerification(code);
      await UserService.GetUserProfile();
      addMessageBox({
        content: <SuccessMessageBox title='Phone number successfully verified.' />,
      });
      onClose();
    } catch (e) {
      handleError(e);
    }
  }, [code, handleError, onClose]);

  const handleResendCode = () => {
    setResendTime(resendTime + INITIAL_CODE_TIMEOUT);
    requestVerification();
  };

  useEffect(() => {
    // Initial verification request
    requestVerification();
  }, []);

  useEffect(() => {
    if (code.length === 6) {
      checkVerification();
    }
  }, [code]);

  const handlePasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setCode(text);
  };

  if (!phone) {
    // TODO error
    return null;
  }

  return (
    <Modal id='verify-phone-modal' open onClose={onClose} sx={{ maxWidth: '450px' }}>
      <Wizard.Step onClose={onClose} title='Verification via phone required' hideActions>
        <Stack spacing={2}>
          <Typography PII color='text.secondary' fontSize={16}>
            The action you are trying to complete requires SMS verification. A code will be sent to{' '}
            <strong>{phone}</strong>.
          </Typography>
          <Typography color='text.secondary' fontSize={16}>
            Do not share this code, Swyftx will never ask you for verification details.
          </Typography>
          <Stack direction='column' spacing={2} alignItems='center' justifyContent='center'>
            <CodeInput
              id='verify-phone-modal-code'
              length={6}
              onChange={(newCode: string[]) => setCode(newCode.join(''))}
              error={error}
              hidePasteButton
            />
          </Stack>
          <Stack direction='row' spacing={2} alignItems='center'>
            {hasClipboardPermission === 'granted' && (
              <Button variant='text' onClick={() => handlePasteFromClipboard()} sx={{ marginTop: '0rem' }}>
                <Stack direction='row' alignItems='center' height='100%' spacing={1}>
                  <Paste className='h-20 w-20 text-color-text-primary' />
                  <Typography color='primary'>Paste from clipboard</Typography>
                </Stack>
              </Button>
            )}
            <CountdownDelay
              id='verify-phone-modal-resend-countdown'
              delay={resendTime}
              delayText={t('smsVerification.resendCodeIn')}
              restartText={t('smsVerification.resendCode')}
              onTimerRestart={handleResendCode}
            />
          </Stack>
        </Stack>
      </Wizard.Step>
    </Modal>
  );
};

import { useCallback } from 'react';

import { Candle } from '@shared/api/@types/charts';
import { Big } from '@shared/safe-big';

import * as Sentry from '@sentry/react';
import { CandlestickData, IChartApi, ISeriesApi, Time, UTCTimestamp } from 'lightweight-charts';
import { DateTime } from 'luxon';
import { IChartingLibraryWidget } from 'public/assets/charting_library/charting_library';

import { isSimpleChartState } from '../../../utils/Tradingview.utils';

type Props = {
  chartState?: IChartApi | IChartingLibraryWidget;
  data?: Candle[];
};

const useCreateCandleSeries = ({ chartState, data }: Props) => {
  const createCandleSeries = useCallback(() => {
    if (!isSimpleChartState(chartState) || !data) return undefined;

    const candleSeries = chartState.addCandlestickSeries({
      upColor: getComputedStyle(document.body).getPropertyValue('--color-background-success'),
      downColor: getComputedStyle(document.body).getPropertyValue('--color-background-error'),
      borderVisible: false,
      wickUpColor: getComputedStyle(document.body).getPropertyValue('--color-background-success'),
      wickDownColor: getComputedStyle(document.body).getPropertyValue('--color-background-error'),
    });

    const seriesData: CandlestickData<Time>[] = data
      .map((d) => ({
        time: (Date.parse(DateTime.fromMillis(d.time).toISO()) / 1000) as UTCTimestamp,
        open: d.open || 0,
        high: d.high || 0,
        low: d.low || 0,
        close: d.close || 0,
      }))
      .sort((a, b) => Big(a.time).cmp(b.time));

    try {
      candleSeries.setData(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }

    return candleSeries;
  }, [chartState, data]);

  const updateCandleSeries = useCallback((series: ISeriesApi<'Candlestick'>, bar: Candle) => {
    if (series.seriesType() !== 'Candlestick') return;

    const seriesData: CandlestickData<Time> = {
      time: (Date.parse(DateTime.fromMillis(bar.time).toISO()) / 1000) as UTCTimestamp,
      open: bar.open || 0,
      high: bar.high || 0,
      low: bar.low || 0,
      close: bar.close || 0,
    };

    try {
      series.update(seriesData);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, []);

  return { createCandleSeries, updateCandleSeries };
};

export { useCreateCandleSeries };

import { useCallback, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Links } from '@shared/constants';

import { AutoInvestLearnItem } from './AutoInvestLearnCards.types';
import { useAutoInvestAnalytics } from '../../hooks';

const useBuildAutoInvestLearnCards = () => {
  const { learnArticleOpened } = useAutoInvestAnalytics();

  const openLearnCard = useCallback(
    (articleTitle: string, articleLink: string) => {
      learnArticleOpened(articleTitle, articleLink);
      window.open(articleLink, '_blank', 'noopener,noreferrer');
    },
    [learnArticleOpened],
  );

  const items: AutoInvestLearnItem[] = useMemo(
    (): AutoInvestLearnItem[] => [
      {
        image: 'auto_invest_dca',
        type: 'Swyftx Learn & Earn',
        title: 'Dollar Cost Averaging in Crypto',
        content:
          'Dollar cost averaging (DCA) is an investment strategy that can be utilised by inexperienced and advanced crypto traders alike. It is an incredibly simple technique used to manage price risk and has existed in traditional finance long before crypto existed. It is designed to remove emotion from making trades, a common pitfall for newcomers to buying and selling crypto.',
        onClick: () => openLearnCard('Dollar Cost Averaging in Crypto', Links.learn.dollarCostAverageLearnEarn),
        footer: (
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Button size='md' variant='outlined'>
              View Course
            </Button>
          </FlexLayout>
        ),
      },
      {
        image: 'auto_invest_timing',
        type: 'Swyftx Learn',
        title: 'Timing the Market vs Time in the Market in Crypto',
        content:
          'Choosing an investment strategy can be overwhelming as there are so many to pick from. However, almost every strategy boils down to two main approaches: timing the market and time in the market. These strategies are regularly practised across the crypto and stock market. This article will unpack both investment styles and how they contrast with each other.',
        onClick: () =>
          openLearnCard('Timing the Market vs Time in the Market in Crypto', Links.learn.timingTheMarketLearn),
        footer: (
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Button size='md' variant='outlined'>
              Read more
            </Button>
          </FlexLayout>
        ),
      },
      {
        image: 'auto_invest_dca_guide',
        type: 'Swyftx Learn',
        title: 'Guide to Cryptocurrency Dollar-Cost Averaging (DCA)',
        content:
          'Dollar-cost averaging (DCA) is an investment strategy that is designed to remove the “emotional” factor in investing and help mitigate the risk of badly timed trades. It is used by both amateur and professional investors, and works on a simple principle: invest a fixed amount of money on a regular basis over set intervals (i.e. every week) regardless of market conditions and then take advantage of long-term price.',
        onClick: () =>
          openLearnCard('Guide to Cryptocurrency Dollar-Cost Averaging (DCA)', Links.learn.dollarCostAveragingGuide),
        footer: (
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <Button size='md' variant='outlined'>
              Read more
            </Button>
          </FlexLayout>
        ),
      },
    ],
    [openLearnCard],
  );

  return {
    items,
  };
};

export { useBuildAutoInvestLearnCards };

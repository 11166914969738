import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import { Asset } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { cn } from '@shared/utils/lib/ui';

import { formatValueToCurrencyShorthand } from '@utils/currency';

import { AssetBuyPrice, AssetDailyChange, AssetName } from 'src/lib/markets/components/AssetsTable/components';
import { AssetMonthlyChange } from 'src/lib/markets/components/AssetsTable/components/AssetMonthlyChange/AssetMonthlyChange';
import { AssetWeeklyChange } from 'src/lib/markets/components/AssetsTable/components/AssetWeeklyChange/AssetWeeklyChange';

interface Props {
  item: Asset;
  onSelectItem: () => void;
  selectedItem?: Asset;
  baseAsset?: Asset;
}

export const AssetPickerRow: React.FC<Props> = (props) => {
  const { item, onSelectItem, selectedItem, baseAsset } = props;
  return (
    <ListItem
      key={item.id}
      className={cn('justify-between', item.id === selectedItem?.id ? 'bg-color-background-surface-selected' : '')}
      onClick={onSelectItem}
    >
      <FlexLayout direction='row' spacing={16} className='w-full items-center'>
        <FlexLayout className='w-1/2 sm:w-1/4'>
          <AssetName asset={item} variant='assetPicker' />
        </FlexLayout>
        <FlexLayout className='w-5/12 justify-end truncate sm:w-1/6'>
          <AssetBuyPrice asset={item} variant='assetList' />
        </FlexLayout>
        <FlexLayout className='w-1/6 justify-end sm:w-[12.5%]'>
          <AssetDailyChange asset={item} />
        </FlexLayout>
        <FlexLayout className='hidden w-[12.5%] justify-end md:flex'>
          <AssetWeeklyChange asset={item} />
        </FlexLayout>
        <FlexLayout className='hidden w-[12.5%] justify-end lg:flex'>
          <AssetMonthlyChange asset={item} />
        </FlexLayout>
        <FlexLayout className='hidden w-[12.5%] justify-end sm:flex'>
          <NumericDataItem
            className='truncate'
            color='primary'
            data={formatValueToCurrencyShorthand(
              item.volume[baseAsset?.id || FiatIdEnum.AUD].day,
              baseAsset?.assetType,
              baseAsset?.code,
            )}
            size='small'
            alignItems='end'
          />
        </FlexLayout>
      </FlexLayout>
    </ListItem>
  );
};

import React, { useMemo } from 'react';

import { styled } from '@mui/system';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { useOnboardingAccountProgress, useOnboardingSelector } from '@Onboarding/Onboarding.context';
import { onboardingMachine } from '@Onboarding/Onboarding.machine';

import { StateNode } from 'xstate';

import { OnboardingStepperItem } from './OnboardingStepperItem';
import { transformOnboardingStates } from './stepperUtils';

const ProgressTypography = styled(Typography)({
  fontWeight: 600,
});

const OnboardingStepper: React.FC = () => {
  const { userProfile } = UserStore.useUserStore;

  // Hides the deposit funds step if the user is applying for an entity account
  const transformedSteps = useMemo(
    () =>
      transformOnboardingStates(onboardingMachine.states as Record<string, StateNode>).filter(
        (step) => step.id !== 'depositFunds' || !userProfile?.userSettings?.applyForEntityAccount,
      ),
    [userProfile?.userSettings?.applyForEntityAccount],
  );

  const [activeRootStepId, activeStepId] = useOnboardingSelector((state) => state.toStrings());

  const force100progress = useOnboardingSelector((state) => state?.meta?.[activeRootStepId]?.force100progress) ?? false;

  const activeStepOrder = useMemo<number>(() => {
    const activeRootStep = transformedSteps.find((s) => s.id === activeRootStepId);

    if (!activeRootStep) return 0;

    if (!activeStepId) return activeRootStep.order;

    const activeStep = activeRootStep.children.find((s) => s.id === activeStepId);

    if (!activeStep) return activeRootStep.order;

    return activeStep.order;
  }, [transformedSteps, activeRootStepId, activeStepId]);

  const calculatedAccountProgress = useOnboardingAccountProgress();
  const accountProgress = force100progress ? '100' : calculatedAccountProgress;

  return (
    <Stack>
      <Stack direction='row' marginBottom='16px'>
        <ProgressTypography fontSize={{ xs: 14, lg: 16 }}>Your account is</ProgressTypography>
        <ProgressTypography fontSize={{ xs: 14, lg: 16 }} color='primary' width='50px' textAlign='center'>
          {accountProgress}%
        </ProgressTypography>
        <ProgressTypography fontSize={{ xs: 14, lg: 16 }}>complete</ProgressTypography>
      </Stack>

      {transformedSteps.map((step) => (
        <OnboardingStepperItem key={step.id} step={step} activeOrder={activeStepOrder} root />
      ))}
    </Stack>
  );
};

export { OnboardingStepper };

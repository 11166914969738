import React, { useEffect } from 'react';

import { Grid } from '@swyftx/react-web-design-system';

import { FullPageCard } from '@global-components/FullPageCard';

import { useAvo } from '@hooks/Avo/useAvo';
import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';

import { NavigationURLs } from 'src/lib/navigation/types';

import { useOnboardingSelector } from './Onboarding.context';
import { MobileOnboardingStepper, OnboardingStepper } from './components/OnboardingStepper';
import { mergeMeta } from './utils/mergeMeta';

type Props = {
  handleExit: () => void;
};

const Onboarding: React.FC<Props> = () => {
  const { isLargeScreen } = useScreenBreakpoints();
  const avo = useAvo();

  const ActiveStepComponent = useOnboardingSelector((state) => mergeMeta(state.meta)?.Component);

  useEffect(() => {
    avo.onboardingWizardStarted({
      screen: NavigationURLs.Onboarding,
    });
  }, [avo]);

  return (
    <FullPageCard
      id='onboarding'
      headerChildren={
        isLargeScreen && (
          <Grid item xs={12} md={4} paddingRight={6}>
            <OnboardingStepper />
          </Grid>
        )
      }
    >
      {!isLargeScreen && <MobileOnboardingStepper />}
      {ActiveStepComponent && <ActiveStepComponent />}
    </FullPageCard>
  );
};

Onboarding.displayName = 'Account Setup';

export default Onboarding;

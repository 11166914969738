import React from 'react';

import { Box } from '@mui/material';

import { Skeleton } from '@swyftx/react-web-design-system';

const TransferTypeLoading: React.FC = () => (
  <Box margin={2}>
    <Skeleton height='135px' variant='rectangular' sx={{ borderRadius: '8px' }} />
  </Box>
);

export { TransferTypeLoading };

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem, MenuProps } from '@swyftx/aviary/molecules/Menu';

import { cn } from '@shared/utils/lib/ui';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { PriceAlertTriggerType } from '../../dynamicPriceAlerts.types';

type Props = {
  align?: MenuProps['align'];
  filterTypes: PriceAlertTriggerType[];
  size?: ButtonProps['size'];
  setFilterTypes: (filterTypes: PriceAlertTriggerType[]) => void;
};

const DynamicPriceAlertTypeFilter: React.FC<Props> = ({
  align = 'start',
  size = 'sm',
  filterTypes,
  setFilterTypes,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation('priceAlerts');
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isXs = useTailwindBreakpoint('xs');

  const toggleFilter = useCallback(
    (filterType: PriceAlertTriggerType) => {
      const newFilters = [...filterTypes];
      const filterIndex = newFilters.indexOf(filterType);

      if (filterIndex > -1) {
        newFilters.splice(filterIndex, 1);
      } else {
        newFilters.push(filterType);
      }

      setFilterTypes(newFilters);
    },
    [filterTypes, setFilterTypes],
  );

  const triggerTitle = useMemo(() => {
    if (!filterTypes.length) return '';

    if (filterTypes.length === 2) {
      return `: ${t('filters.all')}`;
    }

    if (filterTypes.includes('LIMIT')) {
      return `: ${t('filters.type.byPriceChange')}`;
    } else {
      return `: ${t('filters.type.byPercentChange')}`;
    }
  }, [filterTypes, t]);

  if (!isFeatureEnabled(AppFeature.DynamicPriceAlertsRelease2)) return null;

  return (
    <Menu
      onOpenChange={setOpen}
      open={open}
      align={align}
      title='Filter by Type'
      variant={isXs ? 'mobile' : 'standard'}
      portalClassName='z-modal-popover'
      triggerContent={
        <Button
          textJustify='space-between'
          variant='outlined'
          className={cn(
            'w-full cursor-pointer rounded-8 sm:w-auto',
            open ? 'focus-area-info outline-color-border-info hover:outline-color-border-info' : '',
          )}
          size={size}
          trailingIcon={<ArrowChevronDown className='h-24 w-24 text-color-text-primary' />}
        >
          <Body color='primary'>{t('filters.type.title', { type: triggerTitle })}</Body>
        </Button>
      }
    >
      <MenuItem
        icon={<Checkbox checked={filterTypes.includes('LIMIT')} />}
        text='By $ target'
        action={() => toggleFilter('LIMIT')}
      />

      <MenuItem
        icon={<Checkbox checked={filterTypes.includes('VOLATILITY')} />}
        text='By % change'
        action={() => toggleFilter('VOLATILITY')}
      />
    </Menu>
  );
};

export { DynamicPriceAlertTypeFilter };

import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

const useFetchTriggerNotificationsCache = () => {
  const queryCache = useQueryClient();

  const invalidateCache = useCallback(() => queryCache.invalidateQueries(['trigger-notifications']), [queryCache]);

  return {
    invalidateCache,
  };
};

export { useFetchTriggerNotificationsCache };

import React, { useEffect, useMemo } from 'react';

import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart';
import { DonutChartItem } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart.types';
import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { RecurringOrderTemplate } from '@shared/api/@types/recurringOrder';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { AssetColors } from '@utils/assets';

import { observer } from 'mobx-react-lite';

type Props = {
  template: RecurringOrderTemplate[];
  size?: number;
};

const AutoInvestOrderItemDonutChart: React.FC<Props> = observer(({ template, size = 200 }) => {
  const { externalMutations, setExternalMutations } = useDonutChartMutations();
  const countryAsset = useCountryAsset();

  const breakDownItem: DonutChartItem[] = useMemo(
    (): DonutChartItem[] =>
      template
        .filter((t) => t.active)
        .map((templateItem) => {
          const asset = assetService.getAsset(templateItem.secondaryAssetId);

          return {
            y: templateItem.percentage,
            color: asset ? AssetColors[asset.code] : 'var(--color-background-surface-active)',
            icon: <AssetIcon asset={asset} size={20} />,
            subTitle: `${templateItem.percentage.toString()}%`,
            title: asset?.name || '',
            otherData: [
              {
                color: 'var(--color-background-surface-active)',
                title: 'Total invested',
                subTitle: formatCurrency(templateItem.total.toString(), countryAsset),
              },
            ],
          };
        }),
    [countryAsset, template],
  );

  useEffect(() => {
    setExternalMutations([
      {
        childName: ['auto-invest'],
        target: ['data'],
        eventKey: 'all',
        mutation: ({ style, index }) => ({
          style: { ...style, fill: breakDownItem[index].color || 'var(--color-background-surface-active)' },
        }),
      },
    ]);
  }, [breakDownItem, template, setExternalMutations]);

  return (
    <FlexLayout alignItems='start' direction='row' justifyContent='center' spacing={0} className='w-full'>
      <DonutChart
        id='auto-invest'
        loading={false}
        innerRadius={size / 2 - 5}
        height={size}
        data={breakDownItem}
        showTooltip
        externalMutations={externalMutations}
        setExternalMutations={setExternalMutations}
      />
    </FlexLayout>
  );
});

export { AutoInvestOrderItemDonutChart };

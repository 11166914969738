import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import MarketTicker from '@global-components/MarketTicker';

import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { SwyftxProTradeContextProvider } from 'src/lib/trade-pro/context';

import { SwyftxProAppHeader } from './SwyftxProAppHeader';

const SwyftxProAppShell = observer(() => {
  const { params } = useNavigateRoute();
  const { getAssetByCode } = useMarkets();
  const { asset: assetCode } = params as { asset: string };
  const selectedAsset = getAssetByCode(assetCode);

  if (!selectedAsset) return null;

  return (
    <SwyftxProTradeContextProvider selectedAsset={selectedAsset}>
      <FlexLayout
        direction='column'
        className='relative h-full w-full bg-color-background-pageBG'
        alignItems='start'
        justifyContent='start'
      >
        <SwyftxProAppHeader />
        <Outlet />
        <MarketTicker />
      </FlexLayout>
    </SwyftxProTradeContextProvider>
  );
});

export { SwyftxProAppShell };

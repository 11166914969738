import { useContext, useMemo } from 'react';

import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';

import { useTradeValue } from 'src/lib/trade/hooks/General';

import { useSwyftxProTradeAssets } from './useSwyftxProTradeAssets';
import { useSwyftxProTradeDirection } from './useSwyftxProTradeDirection';
import { useSwyftxProTradeType } from './useSwyftxProTradeType';
import { SwyftxProTradeContext } from '../context';

const useSwyftxProTradePrice = () => {
  const { tradeDirection, triggerPrice, setTriggerPrice } = useContext(SwyftxProTradeContext);
  const { selectedAsset } = useSwyftxProTradeAssets();
  const { isBuy } = useSwyftxProTradeDirection();
  const { isMarket, isOco } = useSwyftxProTradeType();
  const { getRate } = RatesStore.useRatesStore;

  const { formattedAmount, onChangeValue } = useTradeValue({
    amount: triggerPrice,
    limitAsset: selectedAsset,
    ignoreAssetScale: false,
    onChange: (val?: string) => setTriggerPrice(val || ''),
  });

  const currentPrice = useMemo(() => {
    if (isMarket || isOco || !selectedAsset) return triggerPrice;

    const rate = getRate(selectedAsset);

    return isBuy ? rate.askPrice : rate.bidPrice;
  }, [getRate, isBuy, isMarket, isOco, selectedAsset, triggerPrice]);

  const rawTriggerPrice = useMemo(() => {
    if (!triggerPrice) return undefined;

    if (tradeDirection === 'buy') return triggerPrice;

    return Big(1).div(triggerPrice);
  }, [triggerPrice, tradeDirection]);

  return {
    price: formattedAmount,
    currentPrice,
    triggerPrice,
    rawTriggerPrice,
    onChangeValue,
  };
};

export { useSwyftxProTradePrice };

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import entityHelpers, { GroupedScope, MemberPermissionType } from '@shared/utils/lib/entityHelpers';

type Props = {
  setSelectedPermissionPreset: (preset: MemberPermissionType | null) => void;
  setShowAdvancedConfiguration: (showAdvancedConfiguration: boolean) => void;
  setGroupedScopes: (groupedScope: GroupedScope) => void;
  showAdvancedConfiguration: boolean;
  groupedScopes: GroupedScope;
  disableButtons?: boolean;
};

export const EntityAdvancedConfigurationHeader: React.FC<Props> = ({
  setShowAdvancedConfiguration,
  setSelectedPermissionPreset,
  showAdvancedConfiguration,
  setGroupedScopes,
  disableButtons,
  groupedScopes,
}) => {
  const { t } = useTranslation('profile');

  const handleSelectAll = () => {
    entityHelpers.toggleAll(groupedScopes, true, setGroupedScopes);
    setSelectedPermissionPreset(entityHelpers.getKeyType(groupedScopes));
  };

  const handleRemoveAll = () => {
    entityHelpers.toggleAll(groupedScopes, false, setGroupedScopes);
    setSelectedPermissionPreset(entityHelpers.getKeyType(groupedScopes));
  };

  const handleShowConfiguration = () => {
    setShowAdvancedConfiguration(true);
  };

  const handleHideConfiguration = () => {
    setShowAdvancedConfiguration(false);
  };

  return (
    <FlexLayout direction='row' justifyContent='space-between' className='w-full items-center justify-between'>
      <Body weight='emphasis'>{t('entities.configureMemberModal.permissions.advancedConfiguration.title')}</Body>
      <FlexLayout direction='row' spacing={8}>
        <Button variant='outlined' size='sm' onClick={handleSelectAll} disabled={disableButtons}>
          {t('entities.configureMemberModal.permissions.advancedConfiguration.labels.selectAll')}
        </Button>
        <Button variant='outlined' size='sm' onClick={handleRemoveAll} disabled={disableButtons}>
          {t('entities.configureMemberModal.permissions.advancedConfiguration.labels.removeAll')}
        </Button>
        {showAdvancedConfiguration ? (
          <Button variant='outlined' size='sm' onClick={handleHideConfiguration} disabled={disableButtons}>
            {t('entities.configureMemberModal.permissions.advancedConfiguration.labels.hideAdvancedConfiguration')}
          </Button>
        ) : (
          <Button variant='outlined' size='sm' onClick={handleShowConfiguration} disabled={disableButtons}>
            {t('entities.configureMemberModal.permissions.advancedConfiguration.labels.showConfiguration')}
          </Button>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

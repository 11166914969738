import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyLeft, Cross } from '@swyftx/aviary/icons/outlined';

type Props = {
  title: string;
  onBack?: () => void;
  onClose?: () => void;
};

const SelectAssetListHeader: React.FC<Props> = ({ title, onBack, onClose }) => (
  <FlexLayout
    className='h-[5.25rem] w-full p-8 sm:p-24'
    alignItems='center'
    justifyContent='space-between'
    spacing={16}
  >
    <FlexLayout alignItems='center' spacing={16}>
      {onBack !== undefined && (
        <Button layout='icon' leadingIcon={<ArrowLineOnlyLeft />} onClick={onBack} variant='outlined' />
      )}
      <Heading size='h4' color='primary'>
        {title}
      </Heading>
    </FlexLayout>
    {onClose !== undefined && <Button layout='icon' leadingIcon={<Cross />} onClick={onClose} variant='outlined' />}
  </FlexLayout>
);

export { SelectAssetListHeader };

import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { observer } from 'mobx-react-lite';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { PriceAlertType } from '../../dynamicPriceAlerts.types';
import { useDeleteAllDynamicPriceAlerts } from '../../hooks/useDeleteAllDynamicPriceAlerts';
import { useDynamicPriceAlertAssetCounts } from '../../hooks/useDynamicPriceAlertAssetCounts';

type Props = {
  alertType: PriceAlertType;
  secondary?: number;
  onClose?: () => void;
  onDelete?: () => void;
};

const DELETE_ALL_KEY = 'DELETE ALL';

const DeleteAllPriceAlertModal: React.FC<PropsWithChildren<Props>> = observer(
  ({ alertType, secondary, onClose, onDelete, children = null }) => {
    const [open, setOpen] = useState<boolean>(!children);
    const [value, setValue] = useState<string>('');
    const isXs = useTailwindBreakpoint('xs');
    const { totalActiveAlerts, totalTriggerAlerts } = useDynamicPriceAlertAssetCounts({
      secondary,
    });
    const { deleteAllPriceAlerts, deleteAllTriggerPriceAlerts } = useDeleteAllDynamicPriceAlerts();
    const { t } = useTranslation('priceAlerts');
    const { getAssetById } = useMarkets();

    const secondaryAssetCode = useMemo(() => getAssetById(secondary)?.code || '', [getAssetById, secondary]);
    const alertsToDelete = useMemo(
      () => (alertType === 'active' ? totalActiveAlerts : totalTriggerAlerts),
      [alertType, totalActiveAlerts, totalTriggerAlerts],
    );

    const handleOpenChange = useCallback(
      (o?: boolean) => {
        setOpen(!!o);
        if (!o) {
          setValue('');
          if (onClose) onClose();
        }
      },
      [onClose],
    );

    const handleDeleteAlert = useCallback(async () => {
      if (alertType === 'active') {
        await deleteAllPriceAlerts(secondary);
      } else {
        await deleteAllTriggerPriceAlerts(secondary);
      }
      setOpen(false);
      if (onDelete) onDelete();
    }, [alertType, deleteAllPriceAlerts, deleteAllTriggerPriceAlerts, onDelete, secondary]);

    return (
      <Modal
        triggerElement={children}
        position={isXs ? 'bottom' : 'center'}
        className='min-w-[100vw] sm:!min-w-[500px] sm:!max-w-[500px]'
        overlayClassName='z-modal-popover-backdrop'
        onOpenChange={handleOpenChange}
        open={open}
      >
        <FlexLayout
          direction='column'
          alignItems='start'
          justifyContent='space-between'
          className='w-full overflow-hidden p-24'
          spacing={12}
        >
          <Heading size='h4' className='pb-12'>
            {t('deleteAll.title', { type: alertType, asset: secondaryAssetCode ? ` ${secondaryAssetCode}` : '' })}
          </Heading>
          <Body color='secondary' size='small'>
            {t('deleteAll.content', {
              amount: alertsToDelete,
              type: alertType,
              asset: secondaryAssetCode ? ` ${secondaryAssetCode}` : '',
            })}
          </Body>
          <FlexLayout direction='column' spacing={8} className='w-full'>
            <Body size='small' weight='bold'>
              {t('deleteAll.confirmTitle', {
                term: DELETE_ALL_KEY,
                type: alertType,
                asset: secondaryAssetCode ? ` ${secondaryAssetCode}` : '',
              })}
            </Body>
            <Input value={value} onChange={(e) => setValue(e.target.value)} />
          </FlexLayout>
          <FlexLayout className='w-full pt-12' alignItems='center' justifyContent='end' spacing={16}>
            <Button variant='outlined' onClick={() => handleOpenChange(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button color='destructive' onClick={handleDeleteAlert} disabled={value.toUpperCase() !== DELETE_ALL_KEY}>
              {t('buttons.deleteAll')}
            </Button>
          </FlexLayout>
        </FlexLayout>
      </Modal>
    );
  },
);

export { DeleteAllPriceAlertModal };

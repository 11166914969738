import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { FormattedText } from '@global-components/Text';

import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const TradeMultiDetailsSpend: React.FC = observer(() => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeMultiDetails' });
  const { tradeFrom, maxTradeValue } = UniversalTradeStore;

  const fromAsset = assetService.getAsset(tradeFrom[0]);

  if (!fromAsset || tradeFrom.length > 1) return null;

  return (
    <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='px-8'>
      <Body color='primary'>{t('labels.spend')}</Body>
      <FormattedText
        typographyProps={{ color: 'primary', size: 'large', weight: 'emphasis', className: 'mb-8' }}
        value={maxTradeValue}
        currency={fromAsset}
        formatOpts={{ appendCode: true }}
      />
    </FlexLayout>
  );
});

TradeMultiDetailsSpend.displayName = 'TradeMultiDetailsSpend';

export { TradeMultiDetailsSpend };

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { DateTime } from 'luxon';
import { DynamicPriceAlertTitle, DynamicPriceAlertIcon } from 'src/lib/dynamic-price-alerts/components';
import { TriggeredPriceAlert } from 'src/lib/dynamic-price-alerts/dynamicPriceAlerts.types';
import {
  getGroupByTitle,
  isLimitTriggerAlert,
  isVolatilityTriggerAlert,
} from 'src/lib/dynamic-price-alerts/utils/dynamic-price-alerts.utils';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  alerts?: TriggeredPriceAlert[];
};

export type DynamicPriceAlertTableData = {
  asset: string;
  price: string;
  lastTriggered: string;
};

type DynamicPriceAlertsHeaderData = { [key in keyof DynamicPriceAlertTableData]: EnhancedTableHeaderData };

const useDynamicTriggeredPriceAlertsTable = ({ alerts }: Props) => {
  const { getAssetById } = useMarkets();
  const { t } = useTranslation('priceAlerts');
  const isXs = useTailwindBreakpoint('xs');
  const [sort, setSort] = useState<EnhancedTableSort<DynamicPriceAlertTableData>>({
    sortKey: 'lastTriggered',
    sortDirection: 'DESC',
  });

  const headers: DynamicPriceAlertsHeaderData = useMemo(
    () => ({
      asset: {
        title: t('table.headers.asset'),
        alignment: 'start',
        sortable: false,
        enabled: !isXs,
        className: 'table-cell w-[18rem]',
      },
      price: {
        title: t('table.headers.priceAlert'),
        alignment: 'start',
        sortable: false,
        className: 'table-cell',
      },
      lastTriggered: {
        title: t('table.headers.dateTriggered'),
        alignment: 'end',
        sortable: false,
        enabled: true,
        className: 'hidden sm:table-cell w-[200px]',
      },
    }),
    [isXs, t],
  );

  const data: EnhancedTableData<DynamicPriceAlertTableData>[] = useMemo(
    () =>
      !alerts?.length
        ? []
        : alerts.map((alert) => {
            const asset = getAssetById(alert.secondary);
            const primaryAsset = getAssetById(alert.primary);

            return {
              value: asset?.code,
              asset: {
                value: alert.secondary,
                element: (
                  <FlexLayout alignItems='center' spacing={8}>
                    <AssetIcon asset={asset} size={24} />
                    <Body>{asset?.name}</Body>
                  </FlexLayout>
                ),
              },
              price: {
                value: isLimitTriggerAlert(alert) ? alert?.alertPrice?.toString() || '' : '',
                element: (
                  <FlexLayout alignItems='center' className='gap-16 sm:gap-8'>
                    <DynamicPriceAlertIcon
                      direction={isLimitTriggerAlert(alert) ? alert.direction : undefined}
                      type={alert.type}
                      asset={asset}
                      size={24}
                    />
                    <DynamicPriceAlertTitle
                      triggered
                      type={alert.type}
                      primaryAsset={primaryAsset}
                      secondaryAsset={asset}
                      direction={isLimitTriggerAlert(alert) ? alert.direction : undefined}
                      change={isVolatilityTriggerAlert(alert) ? alert.alertChange : undefined}
                      price={isLimitTriggerAlert(alert) ? alert.alertPrice : undefined}
                      window={isVolatilityTriggerAlert(alert) ? alert.window : undefined}
                    />
                  </FlexLayout>
                ),
              },
              lastTriggered: {
                value: alert.dateTriggered || 0,
                groupByTitle: getGroupByTitle(DateTime.fromMillis(alert.dateTriggered || 0)),
                element: (
                  <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
                    <Body size='small' className='whitespace-nowrap'>
                      {DateTime.fromMillis(alert.dateTriggered || 0).toFormat(
                        `'${getGroupByTitle(DateTime.fromMillis(alert.dateTriggered))}' hh:mm a`,
                      )}
                    </Body>
                  </FlexLayout>
                ),
              },
            };
          }),
    [alerts, getAssetById],
  );

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<DynamicPriceAlertTableData>) => {
      const tableData: EnhancedTableData<DynamicPriceAlertTableData>[] = Object.assign([], data);

      if (!newSort) return tableData;

      setSort(newSort);

      return tableData.sort((a, b) => {
        switch (newSort.sortKey) {
          case 'lastTriggered':
            const aValNum = a[newSort.sortKey].value as number;
            const bValNum = b[newSort.sortKey].value as number;
            if (newSort.sortDirection === 'ASC') return aValNum - bValNum;
            return bValNum - aValNum;
          default:
            return 1;
        }
      });
    },
    [data, setSort],
  );

  return { headers, data, initialSort: sort, onSort };
};

export { useDynamicTriggeredPriceAlertsTable };

import React, { useState, useCallback, useEffect } from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { ExpectedAnnualSpendOption, ExpectedInvestments } from '@shared/api/@types/compliance';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { ExpectedInvestmentsOptionsText } from '@routes/SourceOfWealth/types/QuestionOptions';

import { useSourceOfWealthSelector, useSourceOfWealthService } from '@SourceOfWealth/SourceOfWealth.context';
import { SourceOfWealthData } from '@SourceOfWealth/types/SourceOfWealth.types';
import { Controller, useForm } from 'react-hook-form';

import { SourceOfWealthStepContainer } from './SourceOfWealthStepContainer';

const ExpectedInvestmentsStep: React.FC = () => {
  const sourceOfWealthService = useSourceOfWealthService();
  const verificationData = useSourceOfWealthSelector((state) => state.context.verificationData);
  const countryAsset = useCountryAsset();
  const [otherChecked, setOtherChecked] = useState<boolean>(false);

  useEffect(() => {
    if ('expectedAnnualSpend' in verificationData && verificationData.expectedAnnualSpend !== undefined) {
      setOtherChecked(false);
      return;
    }
    if ('expectedAnnualSpendOther' in verificationData && verificationData.expectedAnnualSpendOther !== undefined) {
      setOtherChecked(true);
    }
  }, [verificationData]);

  const { control, handleSubmit, watch, setValue } = useForm<ExpectedInvestments>({
    mode: 'onChange',
    defaultValues: {
      expectedAnnualSpend:
        'expectedAnnualSpend' in verificationData
          ? (verificationData.expectedAnnualSpend as ExpectedAnnualSpendOption)
          : undefined,
      expectedAnnualSpendOther:
        'expectedAnnualSpendOther' in verificationData
          ? (verificationData.expectedAnnualSpendOther as string)
          : undefined,
    },
  });

  const expectedAnnualSpend = watch('expectedAnnualSpend');
  const expectedAnnualSpendOther = watch('expectedAnnualSpendOther');

  const canContinue = useCallback(() => {
    if (expectedAnnualSpend === undefined) {
      return expectedAnnualSpendOther && expectedAnnualSpendOther.length > 0;
    }
    return expectedAnnualSpend !== undefined;
  }, [expectedAnnualSpend, expectedAnnualSpendOther]);

  /*
   * Handles an edge case where one of the fields exists in the back end, and the user has selected the other field now
   */
  const stripOtherFieldIfExits = useCallback(
    (data: Partial<SourceOfWealthData>) => {
      if ('expectedAnnualSpend' in data && expectedAnnualSpendOther) {
        delete data.expectedAnnualSpend;
      }
      if ('expectedAnnualSpendOther' in data && expectedAnnualSpend) {
        delete data.expectedAnnualSpendOther;
      }
      return data;
    },
    [expectedAnnualSpend, expectedAnnualSpendOther],
  );

  return (
    <SourceOfWealthStepContainer
      customTitle='How much do you plan to invest into Swyftx each year?'
      onSubmit={handleSubmit((data) => {
        // Typescript complaining here, but data is of type ExpectedInvestments which is in SourceOfWealthFormPartial
        sourceOfWealthService.send({ type: 'NEXT', data: stripOtherFieldIfExits(data as Partial<SourceOfWealthData>) });
      })}
      submitDisabled={!canContinue()}
    >
      <FlexLayout direction='column' spacing={8}>
        <Body size='small' weight='emphasis'>
          Please select one:
        </Body>
        <FlexLayout direction='column' spacing={16}>
          <Controller
            name='expectedAnnualSpend'
            control={control}
            rules={{ required: false }}
            defaultValue={expectedAnnualSpend}
            render={({ field }) => (
              <>
                {Object.entries(ExpectedAnnualSpendOption).map(([key, value]) => (
                  <FlexLayout key={key} direction='row' className='flex items-center' spacing={16}>
                    <Checkbox
                      className='h-20 w-20 rounded-[100%] border-2 border-color-border-main'
                      checked={expectedAnnualSpend === value}
                      onCheckedChange={() => {
                        field.onChange(value);
                        setValue('expectedAnnualSpendOther', null);
                        setOtherChecked(false);
                      }}
                    />
                    <Body size='small'>{ExpectedInvestmentsOptionsText[value]}</Body>
                  </FlexLayout>
                ))}
                <FlexLayout direction='row' className='flex items-center' spacing={16}>
                  <Checkbox
                    className='h-20 w-20 rounded-[100%] border-2 border-color-border-main'
                    checked={otherChecked}
                    onCheckedChange={() => {
                      setOtherChecked(true);
                      setValue('expectedAnnualSpend', null);
                      field.onChange(undefined);
                    }}
                  />
                  <Body size='small'>Other</Body>
                </FlexLayout>
              </>
            )}
          />

          {otherChecked && (
            <FlexLayout direction='column' spacing={8} className='mt-16'>
              <Body size='small' weight='emphasis'>
                Please describe these cash deposits:
              </Body>
              <Controller
                name='expectedAnnualSpendOther'
                control={control}
                rules={{ required: false }}
                defaultValue={expectedAnnualSpendOther}
                render={({ field }) => (
                  <Input
                    placeholder='Please enter amount'
                    value={field.value ?? undefined}
                    type='number'
                    onChange={field.onChange}
                    leading='$'
                    trailing={
                      <Body size='small' color='secondary'>
                        {countryAsset?.code ?? 'AUD'}
                      </Body>
                    }
                  />
                )}
              />
            </FlexLayout>
          )}
        </FlexLayout>
      </FlexLayout>
    </SourceOfWealthStepContainer>
  );
};

export { ExpectedInvestmentsStep };

import { z } from 'zod';

/* password format is as follows
at least 1 lower case
at least 1 upper case
at least 1 number
at least 1 symbol
and at least 8 characters long
*/

export const SetPasswordSchema = z
  .object({
    password: z
      .string()
      .regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/, 'Please enter a valid password'),
    confirmPassword: z.string(),
    referralCode: z.string().optional(),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'Passwords do not match',
      });
      return false;
    }

    return true;
  });

import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TransactionStatus, VerificationStatus } from '@shared/api/@types/verification';
import { UserStore } from '@shared/store';

import { ProfileVerificationContext } from '../../ProfileVerification.context';
import { VerificationStep } from '../../types';

export type JumioStatus = VerificationStatus | TransactionStatus;

export const useJumioStatus = () => {
  const { setVerificationStep, jumioTransaction } = useContext(ProfileVerificationContext);
  const { isKyc1Complete } = UserStore.useUserStore;
  const { t } = useTranslation('profile.verification');

  const initialStatus = useMemo(() => {
    if (isKyc1Complete()) {
      return VerificationStatus.PASSED;
    }
    if (jumioTransaction) {
      return jumioTransaction.result?.status ?? jumioTransaction.status;
    }
    return TransactionStatus.NOT_STARTED;
  }, [isKyc1Complete, jumioTransaction]);

  const [status, setStatus] = useState<JumioStatus>(initialStatus as JumioStatus);

  const getColor = useCallback(() => {
    switch (status) {
      case VerificationStatus.PASSED:
        return 'success';
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
      case VerificationStatus.DENIED_RETRY_ALLOWED:
      case VerificationStatus.DENIED_SUSPICIOUS:
      case VerificationStatus.DETAILS_DID_NOT_MATCH:
      case VerificationStatus.NO_SIMILARITY:
      case TransactionStatus.FAILED:
        return 'destructive';
      case TransactionStatus.PENDING:
      case TransactionStatus.STARTED:
      case TransactionStatus.DONE:
        return 'primary';
      case TransactionStatus.NOT_STARTED:
      default:
        return 'secondary';
    }
  }, [status]);

  const buttonText: string = useMemo(() => {
    switch (status) {
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
      case VerificationStatus.DENIED_SUSPICIOUS:
        return t('identityVerificationModal.buttonLabels.manualSubmission');
      case VerificationStatus.DENIED_RETRY_ALLOWED:
        return t('identityVerificationModal.buttonLabels.retryAuthentication');
      case TransactionStatus.STARTED:
        return t('identityVerificationModal.buttonLabels.resumeVerification');
      case TransactionStatus.NOT_STARTED:
        return t('identityVerificationModal.buttonLabels.startVerification');
      default:
        return '';
    }
  }, [status, t]);

  const shouldShowButton = useMemo(
    () =>
      [
        VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED,
        VerificationStatus.DENIED_SUSPICIOUS,
        VerificationStatus.DENIED_RETRY_ALLOWED,
        TransactionStatus.STARTED,
        TransactionStatus.NOT_STARTED,
      ].includes(status),
    [status],
  );

  const shouldShowNotification = useMemo(() => {
    const validValues: JumioStatus[] = [
      VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED,
      VerificationStatus.DENIED_SUSPICIOUS,
      VerificationStatus.DENIED_RETRY_ALLOWED,
    ];
    return validValues.includes(status);
  }, [status]);

  const buttonColor: 'success' | 'destructive' | 'primary' = useMemo(() => {
    switch (status) {
      case VerificationStatus.PASSED:
        return 'success';
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
      case VerificationStatus.DENIED_RETRY_ALLOWED:
      case VerificationStatus.DENIED_SUSPICIOUS:
      case VerificationStatus.DETAILS_DID_NOT_MATCH:
      case VerificationStatus.NO_SIMILARITY:
      case TransactionStatus.FAILED:
        return 'destructive';
      case TransactionStatus.PENDING:
      case TransactionStatus.STARTED:
      case TransactionStatus.NOT_STARTED:
      default:
        return 'primary';
    }
  }, [status]);

  const buttonOnClick = useMemo(() => {
    switch (status) {
      case VerificationStatus.PASSED:
        return undefined;
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
        return () => setVerificationStep(VerificationStep.JumioManualDocuments);
      case VerificationStatus.DENIED_RETRY_ALLOWED:
        return () => setVerificationStep(VerificationStep.Jumio);
      case VerificationStatus.DENIED_SUSPICIOUS:
        return () => setVerificationStep(VerificationStep.Jumio);
      case TransactionStatus.STARTED:
        return () => setVerificationStep(VerificationStep.Jumio);
      case TransactionStatus.NOT_STARTED:
        return () => setVerificationStep(VerificationStep.Jumio);
      default:
        return undefined;
    }
  }, [setVerificationStep, status]);

  const notificationColor: 'info' | 'destructive' = useMemo(() => {
    switch (status) {
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
      case VerificationStatus.DENIED_SUSPICIOUS:
      case VerificationStatus.DENIED_RETRY_ALLOWED:
        return 'destructive';
      default:
        return 'info';
    }
  }, [status]);

  const notificationTitle = useMemo(() => {
    switch (status) {
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
        return t('identityVerificationModal.labels.maximumAttemptsTitle');
      case VerificationStatus.DENIED_SUSPICIOUS:
        return t('identityVerificationModal.labels.suspiciousTitle');
      case VerificationStatus.DENIED_RETRY_ALLOWED:
        return t('identityVerificationModal.labels.failedRetryTitle');
      default:
        return '';
    }
  }, [status, t]);

  const notificationDescription = useMemo(() => {
    switch (status) {
      case VerificationStatus.DENIED_RETRY_MAXIMUM_REACHED:
        return t('identityVerificationModal.labels.maximumAttempts');
      case VerificationStatus.DENIED_SUSPICIOUS:
        return t('identityVerificationModal.labels.suspicious');
      case VerificationStatus.DENIED_RETRY_ALLOWED:
        return t('identityVerificationModal.labels.failedRetry');
      default:
        return '';
    }
  }, [status, t]);

  return {
    status,
    setStatus,
    getColor,
    buttonText,
    buttonColor,
    buttonOnClick,
    shouldShowButton,
    shouldShowNotification,
    notificationColor,
    notificationTitle,
    notificationDescription,
  };
};

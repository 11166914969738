import { useCallback, useContext } from 'react';

import { AutoInvestContext } from 'src/lib/auto-invest/context';

const useAutoInvestFailure = () => {
  const { setCurrentStep, onCancel } = useContext(AutoInvestContext);

  const handleRetrySubmission = useCallback(() => {
    setCurrentStep('review');
  }, [setCurrentStep]);

  return {
    onCancel,
    handleRetrySubmission,
  };
};

export { useAutoInvestFailure };

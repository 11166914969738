import { useCallback, useEffect, useMemo, useState } from 'react';

import { AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useDebounce } from 'react-use';
import { useTradeValue } from 'src/lib/trade/hooks/General';

import { useSwyftxProTradeAmount } from './useSwyftxProTradeAmount';
import { useSwyftxProTradeAssets } from './useSwyftxProTradeAssets';
import { useSwyftxProTradeBalance } from './useSwyftxProTradeBalance';
import { useSwyftxProTradeDirection } from './useSwyftxProTradeDirection';

const useSwyftxProTradeTotal = () => {
  const [sliderValue, setSliderValue] = useState<number>(0);

  const { value, onChange, total, amount } = useSwyftxProTradeAmount();
  const { limitAsset, buyAsset, sellAsset, setLimitAsset } = useSwyftxProTradeAssets();
  const { balance } = useSwyftxProTradeBalance({ asset: sellAsset });
  const { tradeDirection } = useSwyftxProTradeDirection();
  const { getRate } = RatesStore.useRatesStore;

  const [showSlider, setShowSlider] = useState<boolean>(limitAsset?.code === sellAsset?.code);
  const { formattedAmount, onChangeValue } = useTradeValue({
    amount: value,
    limitAsset,
    ignoreAssetScale: false,
    onChange,
  });

  const title = useMemo(() => {
    if (tradeDirection === 'buy') {
      if (limitAsset?.code === sellAsset?.code) return 'Total';
      return 'Amount';
    } else {
      if (limitAsset?.code === buyAsset?.code) return 'Total';
      return 'Amount';
    }
  }, [buyAsset, limitAsset?.code, sellAsset, tradeDirection]);

  const placeholder = useMemo(() => {
    if (limitAsset?.price_scale === 0) return '0';

    return limitAsset?.assetType === AssetType.Fiat ? '$0.00' : '0.'.padEnd((limitAsset?.price_scale || 0) + 2, '0');
  }, [limitAsset?.assetType, limitAsset?.price_scale]);

  const showSwapLimitAsset = useMemo(() => {
    if (tradeDirection === 'buy' && buyAsset) {
      const { buyLiquidityFlag } = getRate(buyAsset);

      if (buyLiquidityFlag) return false;
    }

    if (tradeDirection === 'sell' && sellAsset) {
      const { sellLiquidityFlag } = getRate(sellAsset);

      if (sellLiquidityFlag) return false;
    }

    return true;
  }, [buyAsset, getRate, sellAsset, tradeDirection]);

  const toggleLimitAsset = useCallback(() => {
    if (!sellAsset || !buyAsset || !showSwapLimitAsset) return;
    setLimitAsset(limitAsset?.code === buyAsset?.code ? sellAsset : buyAsset);
  }, [sellAsset, buyAsset, showSwapLimitAsset, setLimitAsset, limitAsset?.code]);

  useDebounce(
    () => {
      setShowSlider(limitAsset?.code === sellAsset?.code);
    },
    50,
    [limitAsset, sellAsset],
  );

  const updateSliderValue = useCallback(
    (newValue: number[]) => {
      const [val] = newValue;

      if (val !== undefined) {
        onChange(
          formatCurrency(Big(balance).times(Big(val).div(100)).toString(), sellAsset, {
            hideCode: true,
            hideSeparator: true,
            hideSymbol: true,
          }),
        );
      }

      setSliderValue(newValue[0]);
    },
    [balance, onChange, sellAsset],
  );

  useEffect(() => {
    if (!balance) {
      setSliderValue(0);
      return;
    }

    if (tradeDirection === 'buy') {
      setSliderValue(Big(total).div(balance).times(100).toNumber());
    } else {
      setSliderValue(Big(amount).div(balance).times(100).toNumber());
    }
  }, [total, amount, balance, tradeDirection]);

  return {
    title,
    placeholder,
    formattedAmount,
    limitAsset,
    value,
    sliderValue,
    showSlider,
    showSwapLimitAsset,
    onChangeValue,
    updateSliderValue,
    toggleLimitAsset,
  };
};

export { useSwyftxProTradeTotal };

import { useMemo } from 'react';

import { useEntityOnboardingSelector } from '@routes/EntityOnboarding/EntityOnboarding.context';

export const useDocumentHelpLinks = () => {
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);
  const entityType = applicationData.type;

  const helpLinkDestination = useMemo(() => {
    if (!entityType) return '';
    switch (entityType) {
      case 'COMPANY':
        return 'https://support.swyftx.com/hc/en-au/articles/15064367668889-Create-a-company-account#h_01HMWD3KBWW6MFY0CD5MCMB30Q';
      case 'SMSF_INDIVIDUAL':
      case 'SMSF_CORPORATE':
        return 'https://support.swyftx.com/hc/en-au/articles/28005048424857-Create-a-self-managed-super-fund-SMSF-account#h_01HMWD3KBWW6MFY0CD5MCMB30Q';
      case 'TRUST_CORPORATE':
      case 'TRUST_INDIVIDUAL':
        return 'https://support.swyftx.com/hc/en-au/articles/15061101520153-Create-a-trust-account#h_01HMWD3KBWW6MFY0CD5MCMB30Q';
      default:
        entityType satisfies never;
    }
  }, [entityType]);

  const asicExtractLink =
    'https://support.swyftx.com/hc/en-au/articles/15063913419289-Download-a-current-company-extract';

  return { helpLinkDestination, asicExtractLink };
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@swyftx/aviary/atoms/Icon';
import { TrendLineDown, Dust, OTCTrade, Swap, TrendLineUp, Trade } from '@swyftx/aviary/icons/outlined';

import { AssetHistoryItem, OrderType } from '@shared/api';
import { OrderStatusEnum } from '@shared/enums';

const useTransactionOrderType = () => {
  const { t } = useTranslation('orders');

  const parseOrderTypeName = useCallback((orderType?: number) => {
    switch (orderType) {
      case 1:
        return 'Market Buy';
      case 2:
        return 'Market Sell';
      case 3:
        return 'Trigger Buy';
      case 4:
        return 'Trigger Sell';
      case 5:
        return 'Stop Buy';
      case 6:
        return 'Stop Sell';
      case 8:
        return 'Dust';
      case 9:
        return 'OTC Buy';
      case 10:
        return 'OTC Sell';
      default:
        return 'Unknown';
    }
  }, []);

  const isSellOrder = useCallback((orderType?: number) => {
    if (!orderType) return false;

    switch (orderType) {
      case 2:
      case 4:
      case 6:
      case 8:
      case 10:
        return true;
      default:
        return false;
    }
  }, []);

  const parseOrderType = useCallback(
    (transaction: AssetHistoryItem) => {
      switch (transaction.orderType) {
        case OrderType.MarketBuy:
          return {
            title: t('types.marketBuy'),
            icon: <Icon className='h-24 w-24' icon={<Swap className='h-18 w-18' />} color='success' />,
          };
        case OrderType.MarketSell:
          return {
            title: t('types.marketSell'),
            icon: <Icon className='h-24 w-24' icon={<Swap className='h-18 w-18' />} color='destructive' />,
          };
        case OrderType.TriggerBuy:
          return {
            title: t('types.triggerBuy'),
            icon: <Icon className='h-24 w-24' icon={<TrendLineDown className='h-18 w-18' />} color='success' />,
          };
        case OrderType.TriggerSell:
          return {
            title: t('types.triggerSell'),
            icon: <Icon className='h-24 w-24' icon={<TrendLineUp className='h-18 w-18' />} color='destructive' />,
          };
        case OrderType.StopBuy:
          return {
            title: t('types.stopBuy'),
            icon: <Icon className='h-24 w-24' icon={<TrendLineUp className='h-18 w-18' />} color='success' />,
          };
        case OrderType.StopSell:
          return {
            title: t('types.stopSell'),
            icon: <Icon className='h-24 w-24' icon={<TrendLineDown className='h-18 w-18' />} color='destructive' />,
          };
        case OrderType.DustSell:
          return {
            title: t('types.dustSell'),
            icon: <Icon className='h-24 w-24' icon={<Dust className='h-18 w-18' />} color='destructive' />,
          };
        case OrderType.OTCBuy:
          return {
            title: t('types.otcBuy'),
            icon: <Icon className='h-24 w-24' icon={<OTCTrade className='h-18 w-18' />} color='success' />,
          };
        case OrderType.OTCSell:
          return {
            title: t('types.otcSell'),
            icon: <Icon className='h-24 w-24' icon={<OTCTrade className='h-18 w-18' />} color='destructive' />,
          };
        default:
          return {
            title: t('types.na'),
            icon: <Icon className='h-24 w-24' icon={<Trade className='h-18 w-18' />} color='success' />,
          };
      }
    },
    [t],
  );

  const parseOrderStatus = useCallback(
    (transaction: AssetHistoryItem): { status: string; color: 'info' | 'success' | 'destructive' | undefined } => {
      switch (transaction.statusRaw) {
        case OrderStatusEnum.OPEN:
          return { status: t('status.open'), color: 'info' };
        case OrderStatusEnum.PARTIAL_FILL:
          return { status: t('status.partialFill'), color: 'info' };
        case OrderStatusEnum.PENDING:
          return { status: t('status.processing'), color: 'info' };
        case OrderStatusEnum.FILLED:
          return { status: t('status.completed'), color: 'success' };
        case OrderStatusEnum.REFUNDED:
          return { status: t('status.refunded'), color: 'success' };
        case OrderStatusEnum.INSUFFICIENT_BALANCE:
          return { status: t('status.cancelled'), color: 'destructive' };
        case OrderStatusEnum.USER_CANCELLED:
        case OrderStatusEnum.FAILED:
        case OrderStatusEnum.FAILED_MAX_AMOUNT:
          return { status: t('status.userCancelled'), color: 'destructive' };
        case OrderStatusEnum.SYSTEM_CANCELLED:
          return { status: t('status.systemCancelled'), color: 'destructive' };
        case OrderStatusEnum.FAILED_MIN_AMOUNT:
          return { status: t('status.failedMinAmount'), color: 'destructive' };
        default:
          return { status: 'N/A', color: 'info' };
      }
    },
    [t],
  );

  return {
    parseOrderType,
    parseOrderStatus,
    parseOrderTypeName,
    isSellOrder,
  };
};

export { useTransactionOrderType };

import React, { useContext } from 'react';

import { DateTime } from 'luxon';

import { AdvancedTradingView } from './AdvancedTradingView';
import { SimpleTradingView } from './SimpleTradingView';
import { TradingViewContext } from './context/TradingView.context';
import { TradingViewSide } from './types';

type Props = {
  useNewStyle?: boolean;
  onDateClick?: (date: DateTime) => void;
  onPriceSideChange?: (side: TradingViewSide) => void;
  headerHeight?: number;
  priceLines?: string[];
  onUpdatePriceLines?: (triggerPrices: string[]) => void;
};

const TradingViewWidgetContent: React.FC<Props> = ({
  useNewStyle = true,
  headerHeight,
  priceLines,
  onPriceSideChange,
  onDateClick,
  onUpdatePriceLines,
}) => {
  const { selectedChartStyle, primary, secondary } = useContext(TradingViewContext);

  return selectedChartStyle === 'advanced' || !useNewStyle ? (
    <AdvancedTradingView
      primary={primary}
      secondary={secondary}
      useNewStyle={useNewStyle}
      onPriceSideChange={onPriceSideChange}
      priceLines={priceLines}
      onUpdatePriceLines={onUpdatePriceLines}
    />
  ) : (
    <SimpleTradingView onDateClick={onDateClick} headerHeight={headerHeight} />
  );
};

export { TradingViewWidgetContent };

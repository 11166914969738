import React from 'react';
import { RouteObject } from 'react-router-dom';

import PublicRoute from '@routes/PublicRoute';

import { NavigationURLs } from 'src/lib/navigation/types';

import Register from './Register';

export const RegisterRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Register,
    element: <PublicRoute ignoreAuth element={<Register />} />,
  },
];

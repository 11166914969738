import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';

import { OTPInputGroup } from './OTPInput';

const OTPStep = ({
  methods,
  fieldName,
  nextButtonText,
  bodyText,
  numberOfDigits = 6,
  showIntercom = true,
}: {
  methods: UseFormReturn;
  fieldName: 'oldOTP' | 'newOTP';
  nextButtonText: string;
  bodyText?: React.ReactNode;
  numberOfDigits?: number;
  showIntercom?: boolean;
}) => {
  const { formState, control, watch } = methods;
  const { show } = useIntercom();

  const watchedOtpValue = watch('oldOTP');

  return (
    <FlexLayout alignItems='center' className='text-center' justifyContent='center' direction='column' spacing={32}>
      <FlexLayout direction='column' spacing={24}>
        {bodyText}
      </FlexLayout>

      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value = '' }, fieldState }) => (
          <OTPInputGroup
            value={value}
            onChangeValue={(newValue) => onChange(newValue.join(''))}
            numberOfDigits={numberOfDigits}
            disabled={formState.isSubmitting}
            aria-invalid={fieldState.invalid ? true : false}
          />
        )}
      />

      <FlexLayout direction='column' spacing={12} className='flex w-full'>
        <Button
          type='submit'
          size='lg'
          className='w-full'
          loading={formState.isSubmitting}
          disabled={formState.isSubmitting || (watchedOtpValue?.length || 0) < numberOfDigits}
        >
          {nextButtonText}
        </Button>

        {showIntercom && (
          <FlexLayout className='flex justify-center'>
            <Button size='md' variant='ghost' color='subtle' onClick={show} className='w-2/3'>
              Lost access to your phone? Chat to support
            </Button>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export { OTPStep };

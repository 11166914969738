import React from 'react';

import { formatCurrency } from '@swyftx/currency-util';

import { Big } from '@shared/safe-big';

import { useWithdrawalLimit } from '@routes/Wallet/components/WithdrawalLimit/WithdrawalLimit.hooks';
import { countryTextFactory } from '@services/DepositService';

interface WithdrawalFundsImportantInformation {
  informationList: string[];
}

export const useWithdrawalFundsImportantInformation = (country?: string): WithdrawalFundsImportantInformation => {
  const { data: limits } = useWithdrawalLimit();
  const formattedTotal = formatCurrency((limits?.limit ?? Big(0)).toFixed(0));

  const countryText = React.useMemo(
    () =>
      countryTextFactory<WithdrawalFundsImportantInformation>({
        aud: {
          informationList: [
            `The maximum withdrawal amount is ${formattedTotal} per day. To request increased limits, please contact us.`,
            'AUD withdrawals do not incur any fee.',
            'Transaction monitoring programs are used by Swyftx and in some circumstances may we require enhanced verification on your account.',
          ],
        },
        nzd: {
          informationList: [
            `The minimum withdrawal is $30 NZD per transaction, and The maximum withdrawal amount is ${formattedTotal} per day. To request increased limits, please contact us.`,
            'NZD withdrawals incur a $10 NZD fee from our banking provider. Withdrawals made to an incorrect account will incur a $40 NZD fee.',
            'Transaction monitoring programs are used by Swyftx and in some circumstances may require enhanced verification on your account.',
            'Withdrawals are processed within 1-3 business days and will arrive in your bank account after 8-10 business days. Please contact support if your withdrawal has not arrived after this time.',
          ],
        },
        currentCountry: country,
      }),
    [country, formattedTotal],
  );

  return countryText;
};

export interface WithdrawalAccountFormInput {
  label: string;
  type: 'AccountName' | 'AccountHolderName' | 'AccountNumber' | 'BsbInput';
  placeholder: string;
  validation?: {
    characters: {
      min: number;
      max: number;
    };
  };
}

interface WithdrawalFundsAddAccount {
  forms: WithdrawalAccountFormInput[];
}

export const withdrawalFundsAddAccountForm = (country: string) =>
  countryTextFactory<WithdrawalFundsAddAccount>({
    nzd: {
      forms: [
        {
          label: 'Account name',
          placeholder: 'Enter name for this account',
          type: 'AccountName',
        },
        {
          label: 'Account holder name',
          placeholder: 'Enter name of account holder',
          type: 'AccountHolderName',
        },
        {
          label: 'Account number',
          placeholder: 'Enter account number',
          type: 'AccountNumber',
          validation: {
            characters: {
              min: 16,
              max: 16,
            },
          },
        },
      ],
    },
    aud: {
      forms: [
        {
          label: 'Account name',
          placeholder: 'Enter name for this account',
          type: 'AccountName',
        },
        {
          label: 'Account holder name',
          placeholder: 'Enter name of account holder',
          type: 'AccountHolderName',
        },
        {
          label: 'BSB',
          placeholder: 'Enter Enter BSB',
          type: 'BsbInput',
        },
        {
          label: 'Account number',
          placeholder: 'Enter account number',
          type: 'AccountNumber',
          validation: {
            characters: {
              min: 6,
              max: 9,
            },
          },
        },
      ],
    },
    currentCountry: country,
  });

import { useMemo } from 'react';

import { UserStore } from '@shared/store';
import entityHelpers, { MemberPermissionType } from '@shared/utils/lib/entityHelpers';

export const useEntityMembers = () => {
  const { entityMembers, previousUserProfile } = UserStore.useUserStore;

  const isAdmin = useMemo(() => {
    const loggedUserMember = entityMembers.find((e) => e.uuid === previousUserProfile?.intercom?.uuid);
    const scopePreset = entityHelpers.getPermissionPreset(loggedUserMember?.scope || '');
    return scopePreset === MemberPermissionType.Admin;
  }, [previousUserProfile, entityMembers]);

  return { isAdmin };
};

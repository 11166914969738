import { DateTime } from 'luxon';

export const useGetTimeRemaining = (expiresAt: number): { timeRemaining: string } => {
  const DAYS_REMAINING = expiresAt;

  const currentTime = DateTime.now();
  const expirationTime = DateTime.fromMillis(DAYS_REMAINING);

  const duration = expirationTime.diff(currentTime);
  const daysRemaining = duration.as('days');

  if (daysRemaining >= 1) {
    const flooredDaysRemaining = Math.floor(daysRemaining);
    return { timeRemaining: `${flooredDaysRemaining} day${flooredDaysRemaining > 1 ? 's' : ''}` };
  }
  const hoursRemaining = duration.as('hours');
  if (hoursRemaining >= 1) {
    const flooredHoursRemaining = Math.floor(hoursRemaining);
    return { timeRemaining: `${flooredHoursRemaining} hour${flooredHoursRemaining > 1 ? 's' : ''}` };
  }

  return { timeRemaining: 'Less than an hour' };
};

import React from 'react';

import { Box } from '@mui/material';

import { Checkbox, Divider, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { ScopeGroup, SelectableMemberScopeItem } from '@shared/utils/lib/entityHelpers';

type Props = {
  groupedScope: ScopeGroup;
  onSelect: (item: SelectableMemberScopeItem, group: ScopeGroup, isParent: boolean) => void;
};

const PADDING = 1;

export const GroupedScopeContainer: React.FC<Props> = ({ groupedScope, onSelect }) => {
  const title = `${groupedScope.parent.display.charAt(0).toUpperCase()}${groupedScope.parent.display.slice(1)}`;

  return (
    <Box width='100%'>
      <Stack
        position='relative'
        spacing={0.5}
        border='1px solid'
        borderColor='divider'
        padding={PADDING}
        borderRadius={pxToRem(6)}
      >
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{
            color: 'divider',
            borderRadius: '4px',
            '& .MuiCheckbox-root': { padding: 0.75 },
            '& .MuiFormControlLabel-root': { margin: 0 },
          }}
        >
          <Checkbox
            onChange={() => onSelect(groupedScope.parent, groupedScope, true)}
            checked={groupedScope.parent.selected}
            disabled={groupedScope.parent.locked}
            size='small'
          />
          <Typography fontSize={pxToRem(14)} fontWeight={600}>
            {title}
          </Typography>
        </Stack>
        <Box>
          <Divider sx={{ position: 'absolute', width: '100%', marginLeft: -PADDING }} />
        </Box>
        {groupedScope.children.map((child) => {
          const childTitle = `${child.display.charAt(0).toUpperCase()}${child.display.slice(1)}`;
          return (
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              key={child.key}
              sx={{
                color: 'divider',
                borderRadius: '4px',
                '& .MuiCheckbox-root': { padding: 0.75 },
                '& .MuiFormControlLabel-root': { margin: 0 },
              }}
            >
              <Checkbox
                onChange={() => onSelect(child, groupedScope, false)}
                checked={child.selected}
                disabled={child.locked}
                size='small'
              />
              <Typography fontSize={pxToRem(14)} color={child.locked ? 'text.disabled' : 'text.primary'}>
                {childTitle}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

import React from 'react';

import { getPriceScale } from '@swyftx/currency-util';
import { Button, Input, Stack, Typography } from '@swyftx/react-web-design-system';

import {
  TriggerType,
  ProfitLossValueMeasurement,
  ProfitLossValueMethod,
} from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { TabButton } from '@routes/AssetsList/components/TabButton';

import { useProfitStopLossValueCalculationValueUI } from './ProfitStopLossValueCalculationValue.utils';
import { getCalculatedTriggerPrice } from '../../hooks/ProfitStopLossUtils';

type Props = {
  amount: Big;
  feeAmount: Big;
  feePercentage: string;
  profitLossValueMeasurement: ProfitLossValueMeasurement;
  setProfitLossValueMeasurement: React.Dispatch<React.SetStateAction<ProfitLossValueMeasurement>>;
  profitLossValueMethod: ProfitLossValueMethod;
  profitLossValue: string;
  purchasePrice: Big;
  currentPrice?: string;
  triggerPrice?: string;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  triggerType: TriggerType;
  estimatedProfitPercentage: string;
  setProfitLossValue: (newValue: string) => void;
};

const PROFIT_AMOUNTS = ['25', '50', '100', '250', '500'];
const PERCENTAGE_AMOUNTS = ['3', '5', '10', '15', '25'];

const ProfitStopLossValueCalculationValue: React.FC<Props> = ({
  amount,
  feeAmount,
  feePercentage,
  profitLossValueMeasurement,
  profitLossValueMethod,
  profitLossValue,
  purchasePrice,
  currentPrice,
  triggerPrice,
  primaryAsset,
  secondaryAsset,
  triggerType,
  setProfitLossValueMeasurement,
  estimatedProfitPercentage,
  setProfitLossValue,
}) => {
  const { error, label, endAdornment, startAdornment, placeholder, isDollarCalculation } =
    useProfitStopLossValueCalculationValueUI({
      triggerType,
      profitLossValue,
      profitLossValueMethod,
      profitLossValueMeasurement,
      currentPrice,
      triggerPrice,
      primaryAsset,
      secondaryAsset,
      estimatedProfitPercentage,
    });

  const sanitiseValue = (newValue: string) => {
    const isValid = newValue === '.' || !Number.isNaN(Number(newValue));
    if (!isValid) return;

    setProfitLossValue(newValue);
  };

  const onUpdateFromPercentage = (percentageValue: string) => {
    if (profitLossValueMethod === ProfitLossValueMethod.AmountTarget) {
      setProfitLossValue(percentageValue);
      return;
    }

    const calcTriggerPrice = getCalculatedTriggerPrice(
      {
        purchasePrice,
        feePercentage,
        feeAmount,
        triggerType,
        profitLossValue,
        profitLossValueMeasurement,
        amount,
      },
      percentageValue,
    );

    const priceScale = getPriceScale(calcTriggerPrice.toString());

    setProfitLossValue(calcTriggerPrice.toFixed(priceScale));
  };

  return (
    <Stack spacing={1}>
      <Stack direction='row' spacing={0.5} paddingX={2}>
        <Stack width='100%' spacing={1}>
          <Stack direction='row' alignItems='center' justifyContent='space-between' height={32.5}>
            <Typography fontSize={14} fontWeight={500}>
              {label}
            </Typography>

            {profitLossValueMethod === ProfitLossValueMethod.AmountTarget && (
              <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                <TabButton
                  id='btn-dollars'
                  size='sm'
                  active={isDollarCalculation}
                  onClick={() => setProfitLossValueMeasurement(ProfitLossValueMeasurement.Dollar)}
                >
                  $
                </TabButton>
                <TabButton
                  id='btn-percent'
                  size='sm'
                  active={!isDollarCalculation}
                  onClick={() => setProfitLossValueMeasurement(ProfitLossValueMeasurement.Percentage)}
                >
                  %
                </TabButton>
              </Stack>
            )}
          </Stack>
          <Input
            id='input-profit-amount'
            size='medium'
            variant='outlined'
            value={profitLossValue}
            error={error.length > 0}
            onChange={(e) => sanitiseValue(e.target.value)}
            color='primary'
            placeholder={placeholder}
            InputProps={{
              endAdornment,
              startAdornment,
            }}
            sx={{ width: '100%' }}
          />
          {error && (
            <Typography fontSize={12} color='error.main'>
              {error}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
        paddingX={2}
        width='100%'
        color='text.secondary'
      >
        {/* map over profit amounts */}
        {isDollarCalculation &&
          PROFIT_AMOUNTS.map((amount) => (
            <Button
              fullWidth
              id={`btn-profit-amount-${amount}`}
              key={amount}
              size='medium'
              variant='outlined'
              className='font-mono'
              color='inherit'
              onClick={() => setProfitLossValue(amount)}
            >
              ${amount}
            </Button>
          ))}
        {!isDollarCalculation &&
          PERCENTAGE_AMOUNTS.map((amount) => (
            <Button
              fullWidth
              id={`btn-profit-amount-${amount}`}
              key={amount}
              size='medium'
              variant='outlined'
              className='font-mono'
              color='inherit'
              onClick={() => onUpdateFromPercentage(amount)}
            >
              {amount}%
            </Button>
          ))}
      </Stack>
    </Stack>
  );
};

export { ProfitStopLossValueCalculationValue };

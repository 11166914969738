import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
} & Omit<HTMLAttributes<HTMLTableElement>, 'style'>;

const Table = React.forwardRef<HTMLTableElement, PropsWithChildren<Props>>(({ className, children, ...props }, ref) => (
  <table ref={ref} className={cn('relative w-full overflow-auto', className)} {...props}>
    {children}
  </table>
));

Table.displayName = 'Table';

export { Table };

import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Show } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import { cn } from '@shared/utils/lib/ui';

import { useFullScreenElement } from 'src/lib/utils/hooks/useFullScreenElement';

import { useTradingViewOverlays } from './useTradingViewOverlays';
import { TradingViewHeaderVariant } from '../../../types';

type Props = {
  variant?: TradingViewHeaderVariant;
};

const TradingViewWidgetHeaderOverlays: React.FC<Props> = ({ variant = 'full' }) => {
  const { items } = useTradingViewOverlays();
  const [open, setOpen] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { element } = useFullScreenElement();

  return (
    <Menu
      variant={isXs ? 'mobile' : 'standard'}
      container={element}
      title='Chart overlays'
      open={open}
      onOpenChange={setOpen}
      className='max-h-[25rem] min-w-[20rem] overflow-auto'
      spotlightElementId='tv-overlays'
      triggerContent={
        <Button
          id='tv-overlays-trigger'
          leadingIcon={<Show className='h-16 w-16' />}
          variant='ghost'
          size='sm'
          className={cn(open ? 'bg-color-background-surface-selected' : '')}
        >
          {variant === 'full' ? 'Overlays' : undefined}
        </Button>
      }
    >
      {items.map((item) =>
        item.hidden ? null : (
          <MenuItem
            key={item.text}
            action={item.onClick}
            preventCloseOnSelect
            text={item.text}
            endAdornment={item.trailing}
          />
        ),
      )}
    </Menu>
  );
};

export { TradingViewWidgetHeaderOverlays };

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';

import { CreatePriceAlertType, PriceAlertType } from '../../dynamicPriceAlerts.types';
import { CreatePriceAlertModal } from '../CreatePriceAlertModal';

type Props = {
  alertType: PriceAlertType;
  type?: CreatePriceAlertType;
  showCreateButton?: boolean;
  showRemoveFiltersButton?: boolean;
  filtersActive?: boolean;
  onRemoveAllFilters?: () => void;
};

const usePriceAlertEmpty = ({
  type,
  alertType,
  showCreateButton = true,
  showRemoveFiltersButton = true,
  filtersActive = false,
  onRemoveAllFilters,
}: Props) => {
  const { t } = useTranslation('priceAlerts');

  const title = useMemo(() => {
    if (filtersActive) return t('emptyAlerts.titles.noAlertsFound');
    if (alertType === 'active') return t('emptyAlerts.titles.noAlertsCreated');
    return t('emptyAlerts.titles.noTriggeredAlerts');
  }, [filtersActive, t, alertType]);

  const subTitle = useMemo(() => {
    if (filtersActive) return t('emptyAlerts.content.diffFilter');
    if (alertType === 'active') return t('emptyAlerts.content.createAlert');
    return t('emptyAlerts.content.triggeredAlerts');
  }, [filtersActive, t, alertType]);

  const actionButton = useMemo(() => {
    if (filtersActive) {
      return showRemoveFiltersButton ? (
        <Button variant='outlined' onClick={onRemoveAllFilters}>
          {t('buttons.removeFilters')}
        </Button>
      ) : null;
    } else if (showCreateButton) {
      return (
        <CreatePriceAlertModal type={type}>
          <Button variant='filled'> {t('buttons.createFirst')}</Button>
        </CreatePriceAlertModal>
      );
    }

    return null;
  }, [filtersActive, onRemoveAllFilters, showCreateButton, showRemoveFiltersButton, t, type]);

  return {
    title,
    subTitle,
    actionButton,
  };
};

export { usePriceAlertEmpty };

import React, { useContext } from 'react';

import { Box } from '@mui/material';

import { DragAndDropContainer } from '@global-components/DragAndDrop';

import { DashboardContext } from '@Dashboard/Dashboard.context';
import { useDashboardRows } from '@Dashboard/hooks';

import { observer } from 'mobx-react-lite';

const DashboardSection: React.FC = observer(() => {
  const { editMode } = useContext(DashboardContext);
  const { getRows, updateRows } = useDashboardRows();

  return (
    <Box padding={0}>
      <DragAndDropContainer rows={getRows()} onUpdateRows={updateRows} editMode={editMode} />
    </Box>
  );
});

DashboardSection.displayName = 'DashboardSection';

export { DashboardSection };

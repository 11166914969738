import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { ChartUnavailableReason, NoChartError } from '@global-components/Errors/NoChartError';
import { PerformanceMenu } from '@global-components/PerformanceMenu';
import {
  PerformanceMenuControlEnum,
  performanceMenuControls,
} from '@global-components/PerformanceMenu/PerformanceMenu.const';
import { PerformanceMenuControl } from '@global-components/PerformanceMenu/PerformanceMenu.types';
import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode/UnavailableDemoMode';
import { LineChart } from '@global-components/charts/LineChart';
import { LineChartTooltipData, LineChartTooltipPosition } from '@global-components/charts/LineChart/LineChart.types';
import { usePortfolioLineChartData } from '@global-components/charts/LineChart/hooks/portfolioLineDataHooks';

import { Asset } from '@shared/api';
import { AppStore } from '@shared/store';

import { useAssetCostBasis } from '@hooks/Portfolio';
import { AssetColors } from '@utils/assets';

import Color from 'color';
import { observer } from 'mobx-react-lite';
import { useSize } from 'react-use';

import { SingleWalletPerformanceTooltip } from './SingleWalletPerformanceTooltip';

type Props = { asset: Asset; baseAsset: Asset; costBasis: boolean };

const HEIGHT = 300;
const endDate = new Date();

const SingleWalletPerformance: React.FC<Props> = observer(({ asset, baseAsset, costBasis }) => {
  const { t } = useTranslation('wallet');
  const [selectedControl, setSelectedControl] = useState<PerformanceMenuControl>(
    performanceMenuControls[PerformanceMenuControlEnum.OneYear],
  );
  const [widthSize, { width }] = useSize(<div className='h-0 w-full' />);
  const { isDemo } = AppStore.useAppStore;
  const [tooltipInfo, setTooltipInfo] = useState<{
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  }>({ position: null, data: null });

  const startDate = useMemo(
    () => (selectedControl.startTime ? new Date(selectedControl.startTime) : null),
    [selectedControl],
  );

  const { formattedData, loading } = usePortfolioLineChartData(startDate, endDate, asset.id);
  const { data: useCostBasisData } = useAssetCostBasis(asset.id.toString(), formattedData);

  const costBasisData = costBasis ? useCostBasisData : undefined;
  const { isLightMode } = useTailwindTheme();

  const tooltipCallback = useCallback(
    (tooltipPosition: LineChartTooltipPosition | null, tooltipData: LineChartTooltipData | null) => {
      setTooltipInfo({ data: tooltipData, position: tooltipPosition });
    },
    [],
  );

  const isDelisted = Boolean(asset.delisting);
  const isMatchingBaseAsset = asset.id === baseAsset?.id;

  const lineChart = useMemo(() => {
    const isLoading = loading || width === Infinity;
    const errorReason = ((): ChartUnavailableReason => {
      if (isDemo) {
        return 'isPortfolioAndDemoMode';
      }
      if (isMatchingBaseAsset) {
        return 'isMatchingBaseAsset';
      }
      if (isDelisted) {
        return 'isDelisted';
      }

      return 'error';
    })();

    let assetColor = AssetColors?.[asset.code] ? Color(AssetColors?.[asset.code]) : undefined;

    if (!isLightMode && assetColor) {
      assetColor = assetColor.lightness(40);
    }

    const areaColor = assetColor ?? undefined;

    if (formattedData?.length || isLoading) {
      return (
        <LineChart
          areaColor={areaColor?.hex() || 'var(--color-text-accent)'}
          idKey='single-wallet-performance-chart'
          tooltipCallback={tooltipCallback}
          chartData={formattedData || []}
          backgroundColor='var(--color-background-surface-primary)'
          costBasis={costBasisData}
          loading={isLoading}
          height={HEIGHT}
          width={width}
          showAxes
        />
      );
    }

    return <NoChartError reason={errorReason} containerProps={{ height: HEIGHT }} />;
  }, [
    loading,
    width,
    asset.code,
    isLightMode,
    formattedData,
    isDemo,
    isMatchingBaseAsset,
    isDelisted,
    tooltipCallback,
    costBasisData,
  ]);

  if (isDemo) {
    return <UnavailableDemoMode subTitle={t('singleWalletPerformance.demoMode') as string} showImage />;
  }

  return (
    <FlexLayout direction='column' className='relative flex-1'>
      <FlexLayout direction='column' className='p-16'>
        {widthSize}
        {lineChart}
      </FlexLayout>
      <SingleWalletPerformanceTooltip
        selectedControl={selectedControl}
        tooltipInfo={tooltipInfo}
        baseAsset={baseAsset}
        asset={asset}
        width={width}
      />
      <PerformanceMenu selectedControl={selectedControl} setSelectedControl={setSelectedControl} />
    </FlexLayout>
  );
});

SingleWalletPerformance.displayName = 'SingleWalletPerformance';

export { SingleWalletPerformance };

import React from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { AlertFilled } from '@swyftx/aviary/icons/filled';
import { Tick } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { MenuItemProps, menuItemVariants } from './MenuItem.styles';

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    icon,
    action: onClick,
    text,
    description,
    avatarColor,
    selected,
    error,
    endAdornment,
    preventCloseOnSelect,
    color = 'primary',
    disabled,
    tooltip,
    className,
    ...rest
  } = props;

  const getIcon = () => {
    if (typeof icon === 'string') {
      return <Avatar text={icon} color={avatarColor} size='sm' />;
    }
    return icon;
  };

  return (
    <Tooltip title={tooltip} className='z-button-tooltip'>
      <button
        {...rest}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          if (preventCloseOnSelect) {
            e.preventDefault();
          }
        }}
        disabled={disabled}
        className='w-full'
      >
        <FlexLayout
          direction='row'
          spacing={12}
          alignItems='center'
          className={cn(
            'w-full cursor-pointer select-none rounded-12 p-10',
            menuItemVariants({ color, disabled }),
            className,
          )}
        >
          {icon && <FlexLayout className='flex items-center justify-center'>{getIcon()}</FlexLayout>}
          <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
            <FlexLayout direction='column' alignItems='start' className='text-start'>
              <Body size='small' weight='emphasis' color={disabled ? 'secondary' : color}>
                {text}
              </Body>
              {typeof description === 'string' && (
                <Body size='xsmall' color='secondary'>
                  {description}
                </Body>
              )}
              {typeof description !== 'string' && description}
            </FlexLayout>
            {!error && selected && <Tick className='h-20 w-20 stroke-color-background-primary' />}
            {error && <AlertFilled className='h-18 w-18 text-color-text-error' />}
            {endAdornment}
          </FlexLayout>
        </FlexLayout>
      </button>
    </Tooltip>
  );
};

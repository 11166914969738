import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const LogOut: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M2.02252 6.45651C2.02252 4.00743 4.00818 2.02151 6.45652 2.02151H11.3315C13.7807 2.02151 15.7665 4.0073 15.7665 6.45651V7.38951C15.7665 7.80373 15.4307 8.13951 15.0165 8.13951C14.6023 8.13951 14.2665 7.80373 14.2665 7.38951V6.45651C14.2665 4.83573 12.9523 3.52151 11.3315 3.52151H6.45652C4.83687 3.52151 3.52252 4.8356 3.52252 6.45651V17.5865C3.52252 19.2074 4.83687 20.5215 6.45652 20.5215H11.3415C12.9566 20.5215 14.2665 19.212 14.2665 17.5975V16.6545C14.2665 16.2403 14.6023 15.9045 15.0165 15.9045C15.4307 15.9045 15.7665 16.2403 15.7665 16.6545V17.5975C15.7665 20.041 13.7845 22.0215 11.3415 22.0215H6.45652C4.00818 22.0215 2.02252 20.0356 2.02252 17.5865V6.45651Z'
      fill='currentColor'
    />
    <path
      d='M9.01862 12.0215C9.01862 11.6073 9.3544 11.2715 9.76862 11.2715H19.9936L18.3527 9.63798C18.0592 9.34574 18.0581 8.87087 18.3504 8.57733C18.6426 8.28378 19.1175 8.28273 19.411 8.57497L22.339 11.49C22.4804 11.6307 22.5599 11.8219 22.5599 12.0214C22.5599 12.2209 22.4805 12.4121 22.3391 12.5529L19.4111 15.4689C19.1176 15.7612 18.6428 15.7602 18.3505 15.4667C18.0582 15.1732 18.0592 14.6983 18.3527 14.4061L19.9939 12.7715H9.76862C9.3544 12.7715 9.01862 12.4357 9.01862 12.0215Z'
      fill='currentColor'
    />
  </svg>
);

export { LogOut };

import React from 'react';

import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Skeleton } from '@swyftx/react-web-design-system';

import { PriceTicker } from '@global-components/PriceTicker';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { FormatCurrencyOptions } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';

import { FormattedText, SecondaryProps } from '../FormattedText';

type Props = {
  asset: Asset;
  dynamicCurrency?: Asset;
  balance?: Big | string | number;
  prefix?: string;
  liveChanges?: boolean;
  typographyProps?: NumericProps;
  formatOpts?: FormatCurrencyOptions;
  side?: 'bidPrice' | 'midPrice' | 'askPrice';
  secondaryText?: SecondaryProps;
  isPortfolioTile?: boolean;
  formatBalance?: boolean;
};

const AssetPriceText: React.FC<Props> = observer(
  ({
    asset,
    prefix,
    dynamicCurrency,
    balance,
    liveChanges,
    typographyProps,
    formatOpts,
    side = 'midPrice',
    secondaryText,
    isPortfolioTile,
    formatBalance = false,
  }) => {
    const { getRate, loading } = RatesStore.useRatesStore;
    const baseAsset = useBaseAsset();

    if (loading) {
      return <Skeleton variant='text' width={90} height={20} />;
    }

    if (liveChanges) {
      return (
        <PriceTicker
          typographyProps={{ ...typographyProps, className: 'text-left' }}
          price={Big(getRate(asset)[side])
            .times(balance || Big(1))
            .toString()}
          currency={baseAsset}
          duration={200}
          formatBalance={formatBalance}
          formatOptions={{
            ...formatOpts,
            displayPriceScale: isPortfolioTile,
            showTrailingZero: baseAsset?.assetType === AssetType.Fiat,
          }}
          ticks={3}
        />
      );
    }

    return (
      <FormattedText
        prefix={prefix}
        value={Big(getRate(asset)[side])
          .times(balance || Big(1))
          .toString()}
        currency={baseAsset}
        dynamicCurrency={dynamicCurrency}
        typographyProps={typographyProps}
        formatOpts={formatOpts}
        secondaryText={secondaryText}
        formatBalance={formatBalance}
      />
    );
  },
);

export { AssetPriceText };

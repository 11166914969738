import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { MultiDatePicker } from '@swyftx/aviary/molecules/MultiDatePicker/MultiDatePicker';

import { cn } from '@shared/utils/lib/ui';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { DateRange } from 'react-day-picker';

type Props = {
  className?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
  setEndDate: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
  isSmallScreen?: boolean;
};

const TransactionsFilterDate: React.FC<Props> = observer(
  ({ className, startDate, endDate, setStartDate, setEndDate, isSmallScreen }) => {
    const dateRange = useMemo((): DateRange | undefined => {
      if (!startDate && !endDate) return undefined;

      return { from: startDate?.toJSDate(), to: endDate?.toJSDate() };
    }, [startDate, endDate]);

    const onChange = useCallback(
      (range?: DateRange) => {
        if (!range) {
          setStartDate(undefined);
          setEndDate(undefined);
          return;
        }
        if (range?.from) setStartDate(DateTime.fromJSDate(range.from).startOf('day'));
        if (range?.to) setEndDate(DateTime.fromJSDate(range.to).endOf('day'));
      },
      [setEndDate, setStartDate],
    );

    return (
      <FlexLayout
        alignItems='start'
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent='space-between'
        className={cn(className)}
        spacing={8}
      >
        <MultiDatePicker
          placeholder='Between dates'
          title='Date'
          isSmallScreen={isSmallScreen}
          disabled={{ after: DateTime.now().endOf('day').toJSDate() }}
          value={dateRange}
          onChange={onChange}
        />
      </FlexLayout>
    );
  },
);

export { TransactionsFilterDate };

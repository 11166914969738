import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';

import { useAvo } from '@hooks/Avo/useAvo';
import { ProfileContext } from '@routes/Profile/Profile.context';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

const AffiliateLink: React.FC = () => {
  const { navigate } = useNavigateRoute();
  const { fetchedAffiliateInfo, affiliateInfo, fetchAffiliateInfo } = useContext(ProfileContext);
  const { pathname } = useLocation();
  const avo = useAvo();
  const { t } = useTranslation('profile.referrals', { keyPrefix: 'affiliateLink' });

  useEffect(() => {
    const fetchData = async () => {
      await fetchAffiliateInfo();
    };

    fetchData();
  }, [fetchAffiliateInfo]);

  const openAffiliates = () => {
    avo.redirectToAffiliatePage({
      screen: pathname,
    });
    navigate(NavigationURLs.ProfileAffiliateProgram);
  };

  if (!fetchedAffiliateInfo) return null;

  return (
    <SettingsCluster
      title={fetchedAffiliateInfo && affiliateInfo ? t('lookingForAffiliates.title') : t('exploreAffiliates.title')}
      items={[
        <FlexLayout className='m-16 flex justify-start' direction='column' spacing={8} key='affiliateLinkCluster'>
          <Body color='secondary' size='small'>
            {fetchedAffiliateInfo && affiliateInfo
              ? t('lookingForAffiliates.subtitle')
              : t('exploreAffiliates.subtitle')}
          </Body>

          <Button onClick={openAffiliates} className='w-full @sm:w-[50%] @md:w-[33%] @lg:w-[25%]'>
            {fetchedAffiliateInfo && affiliateInfo
              ? t('lookingForAffiliates.buttonLabel')
              : t('exploreAffiliates.buttonLabel')}
          </Button>
        </FlexLayout>,
      ]}
    />
  );
};

export { AffiliateLink };

import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import Recaptcha from '@components/molecules/Recaptcha';

import { Form } from '@global-components/Form';
import { PhoneNumberInput, CodeInput } from '@global-components/Input';

import { MobileVerificationLightIcon } from '@routes/Onboarding/assets/icons';
import { RegisterContext } from '@routes/Register/Register.context';

import { RegisterTranslationKey } from '@Register/translations';
import { CODE_LENGTH, FormStepProps, INITIAL_CODE_TIMEOUT } from '@Register/types';

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { RegisterStepFooter } from '../../RegisterStepFooter';

const VerifyPhoneNumber: React.FC<FormStepProps> = observer(
  ({ captchaToken, setCaptchaToken, captchaRefresh, formData, setFormData }) => {
    const {
      phoneCode,
      phoneNumberVerified,
      phoneCodeSent,
      setPhoneCode,
      country,
      handleSendCode,
      handleVerifyCode,
      submitting,
      error,
      setError,
      setSubmitting,
      nextRegisterStep,
    } = useContext(RegisterContext);
    const [changePhone, setChangePhone] = useState<boolean>(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
    const [phoneCodeResent, setPhoneCodeResent] = useState(false);

    const { t } = useTranslation(RegisterTranslationKey);
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const mobileVerificaitonRequired = isFeatureEnabled(AppFeature.SignUpForceMobileVerification);

    const changePhoneNumber = () => setChangePhone(true);
    const mobileNumber = useMemo(() => formData.mobileNumber, [formData.mobileNumber]);

    const onChangePhone = () => {
      // Only change the email, if we actually change the email
      if (newPhoneNumber.length && newPhoneNumber !== formData.email) {
        setFormData((prevState) => ({ ...prevState, mobileNumber: newPhoneNumber }));
        handleSendCode({ ...formData, mobileNumber: newPhoneNumber }, true);
      }
      setPhoneCodeResent(false);
      setChangePhone(false);
      setSubmitting(false);
      setError('');
    };

    const resendCode = useCallback(() => {
      if (!phoneCodeResent) {
        handleSendCode(formData, true);
        setPhoneCodeResent(true);
      }
    }, [formData, handleSendCode, phoneCodeResent]);

    useEffect(() => {
      setError('');
      if (!phoneNumberVerified && !phoneCodeSent) {
        handleSendCode(formData, true);
        setPhoneCodeResent(false);
      }
    }, [formData, handleSendCode, phoneCodeSent, phoneNumberVerified, setError]);

    useEffect(() => {
      if (!phoneNumberVerified && phoneCode.length === CODE_LENGTH) {
        handleVerifyCode(formData, true);
      }
    }, [formData, handleVerifyCode, phoneCode, phoneNumberVerified]);

    const formattedPhoneNumber = parsePhoneNumber(
      mobileNumber || '',
      formData.mobileNumberCountry as CountryCode,
    )?.formatInternational();

    const renderChangePhone = () => (
      <>
        <FlexLayout direction='column' className='mb-24 w-full'>
          <Heading id='verifyPhoneNumber.title' size='h4' className='text-center'>
            {t('verifyPhoneNumber.update.title')}
          </Heading>
          <Body id='verifyPhoneNumber.subTitle' className='my-8 text-center' color='secondary'>
            {t('verifyPhoneNumber.update.subTitle')}
          </Body>
        </FlexLayout>

        <Form
          PII
          id='verifyPhoneNumber.form'
          buttonLabel={t('verifyPhoneNumber.buttonLabels.updateAndResendCode')}
          buttonDisabled={!captchaToken}
          onSubmit={onChangePhone}
          setSubmitting={setSubmitting}
          submitting={submitting}
          sx={{
            '.MuiButton-root': {
              marginTop: 1,
              fontSize: 14,
            },
          }}
        >
          <PhoneNumberInput
            id='verifyPhoneNumber.form.phone'
            country={country}
            value={newPhoneNumber}
            onChange={(e) => setNewPhoneNumber(e.target.value)}
          />
        </Form>

        <Button
          id='verifyPhoneNumber.buttons.cancelUpdate'
          variant='ghost'
          type='button'
          size='lg'
          onClick={() => setChangePhone(false)}
          className='my-8 w-full'
        >
          {t('verifyPhoneNumber.buttonLabels.cancelUpdate')}
        </Button>
      </>
    );

    const renderVerifyPhone = () => (
      <>
        <Heading id='verifyPhoneNumber.title' className='text-center' size='h4'>
          {t('verifyPhoneNumber.title')}
        </Heading>

        <Body
          id='verifyPhoneNumber.labels.enterCode'
          className='mt-8 w-full text-center'
          size='large'
          color='secondary'
        >
          {t('verifyPhoneNumber.labels.enterCode', { num: CODE_LENGTH })}
        </Body>

        <Body
          id='verifyEmailAddress.labels.phoneNumber'
          className='pii-mask mb-8 w-full text-center'
          size='large'
          weight='bold'
          color='primary'
        >
          {formattedPhoneNumber}
        </Body>
        <FlexLayout className='mb-16 w-full' alignItems='center' justifyContent='center'>
          <Button
            id='verifyPhoneNumber.buttons.changePhoneNumber'
            variant='ghost'
            onClick={changePhoneNumber}
            size='lg'
            color='accent'
          >
            {t('verifyPhoneNumber.buttonLabels.changePhoneNumber')}
          </Button>
        </FlexLayout>

        <CodeInput
          id='verifyPhoneNumber.code'
          length={CODE_LENGTH}
          error={error}
          onChange={setPhoneCode}
          loading={submitting}
          resendCode={resendCode}
          codeResent={phoneCodeResent}
          resendTime={INITIAL_CODE_TIMEOUT}
        />
      </>
    );

    const renderVerified = () => (
      <FlexLayout direction='column' alignItems='center' spacing={4}>
        <MobileVerificationLightIcon sx={{ fontSize: '64px', marginBottom: '7px' }} />
        <Heading size='h4'>{t('verifyPhoneNumber.labels.alreadyVerified')}</Heading>
        <Body className='pii-mask' color='secondary' weight='bold'>
          {formattedPhoneNumber}
        </Body>
      </FlexLayout>
    );

    return (
      <div className='h-full w-full'>
        <FlexLayout direction='column' className='h-full w-full' justifyContent='space-between'>
          <div className='mt-16 w-full sm:mt-[2.5rem]'>
            {phoneNumberVerified ? renderVerified() : changePhone ? renderChangePhone() : renderVerifyPhone()}
          </div>

          <div className='w-full pt-8'>
            {!captchaToken && (
              <Recaptcha
                id='verifyPhoneNumber.recaptcha'
                captchaRefresh={captchaRefresh}
                submitCaptcha={setCaptchaToken}
              />
            )}

            <RegisterStepFooter
              onNext={nextRegisterStep}
              disableButton={mobileVerificaitonRequired ? !phoneNumberVerified : false}
              nextButtonText={phoneNumberVerified ? t('registerStep.buttonLabels.continue') : undefined}
            />
          </div>
        </FlexLayout>
      </div>
    );
  },
);

VerifyPhoneNumber.displayName = 'VerifyPhoneNumber';

export { VerifyPhoneNumber };

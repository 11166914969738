import { useState } from 'react';

import { EventCallbackInterface } from 'victory-core';

const useDonutChartMutations = () => {
  const [externalMutations, setExternalMutations] = useState<
    EventCallbackInterface<string | string[], string | number | (string | number)[]>[] | undefined
  >();

  return { externalMutations, setExternalMutations };
};

export { useDonutChartMutations };

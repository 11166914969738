import React, { useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ShowFilled } from '@swyftx/aviary/icons/filled';

import { useDocumentHelpLinks } from './useDocumentHelpLinks';

interface Props {
  suggestedDocument: {
    title: string;
    content: JSX.Element;
  };
  key: string;
}

export const RequiredDocumentItem: React.FC<Props> = (props) => {
  const { suggestedDocument, key } = props;
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false);
  const { asicExtractLink } = useDocumentHelpLinks();

  const isXs = useTailwindBreakpoint('xs');

  return (
    <li key={key}>
      <FlexLayout direction='row' spacing={8} className='items-center'>
        <FlexLayout direction='row' spacing={4} className='items-center'>
          <Body size='small' color='secondary'>
            - {suggestedDocument.title}
          </Body>
          {suggestedDocument.title.includes('extract') && (
            <BodyLink color='accent' size='small' className='decoration underline' href={asicExtractLink}>
              here
            </BodyLink>
          )}
        </FlexLayout>

        {!isXs && (
          <Tooltip title='Example document:' align='start' content={suggestedDocument.content}>
            <FlexLayout>
              <ShowFilled className='h-18 w-18 text-color-text-accent' />
            </FlexLayout>
          </Tooltip>
        )}

        {isXs && (
          <FlexLayout>
            <Button
              variant='ghost'
              leadingIcon={<ShowFilled className='h-18 w-18 text-color-text-accent' />}
              layout='icon'
              size='sm'
              onClick={() => setDocumentModalOpen(true)}
            />
            <Modal
              title={`Example ${suggestedDocument.title}`}
              open={documentModalOpen}
              onOpenChange={(e) => (!e?.valueOf() ? setDocumentModalOpen(false) : undefined)}
              position='bottom'
            >
              {suggestedDocument.content}
            </Modal>
          </FlexLayout>
        )}
      </FlexLayout>
    </li>
  );
};

import { useMemo } from 'react';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

export enum AssetFilter {
  All = 'All assets',
  Owned = 'Owned assets',
  Favourites = 'Favourites',
  Bundles = 'Bundles',
  DepositReceive = 'DepositReceive',
  Fiat = 'Fiat',
  Crypto = 'Crypto',
}

export enum TradeSideFilter {
  Buy = 'buy',
  Sell = 'sell',
}

export type FilterOptions = {
  filter?: AssetFilter;
  filterOutSmallBalances?: boolean;
  searchString?: string;
  assetIds?: number[];
  tradeSide?: TradeSideFilter;
  sort?: (a: Asset, b: Asset) => number;
};

const useAssets = (options: FilterOptions) => {
  const { userProfile, balances } = UserStore.useUserStore;
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;
  const { filter, searchString, assetIds, tradeSide, sort, filterOutSmallBalances } = options;

  const assets = useMemo(() => {
    const filteredAssetList = assetService.getAssetList().filter((asset) => {
      let returnAsset = true;
      switch (filter) {
        case AssetFilter.Favourites:
          returnAsset = userProfile?.userSettings.favouriteAssets[asset.id] || false;
          break;
        case AssetFilter.Owned:
          const minBalanceAmount = filterOutSmallBalances ? getMinimumOrderAmount(asset) : 0;
          returnAsset = Big(balances[asset.id]?.availableBalance).gt(minBalanceAmount) || false;
          break;
        case AssetFilter.DepositReceive:
          returnAsset = Boolean(asset.deposit_enabled);
          break;
        case AssetFilter.Fiat:
          returnAsset = asset.assetType === AssetType.Fiat;
          break;
        case AssetFilter.Crypto:
          returnAsset = asset.assetType !== AssetType.Fiat;
          break;
        case AssetFilter.All:
        default:
          break;
      }

      if (returnAsset && searchString) {
        returnAsset =
          asset.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
          asset.code.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      }

      if (returnAsset && assetIds) {
        returnAsset = assetIds.includes(asset.id);
      }

      if (returnAsset && tradeSide) {
        if (!asset.tradable) {
          returnAsset = false;
        }
      }

      return returnAsset;
    });
    if (sort) {
      return filteredAssetList.sort(sort);
    }

    return filteredAssetList;
  }, [sort, filter, searchString, assetIds, tradeSide, userProfile?.userSettings.favouriteAssets, balances]);

  return assets;
};

export { useAssets };

import { DefaultLightThemePalette } from '../light/default.palette';
import { Colors } from '../types/Colors';
import createTheme from '../utils/createTheme';

// TODO: replace these with colors from refresh
const RefreshLightThemePalette: any = {
  ...DefaultLightThemePalette,
  augmentColor: () => ({
    main: '#1113d4',
    light: '#1113d4',
    dark: '#1113d4',
    contrastText: '#ffffff',
  }),
  text: {
    primary: '#090d12',
    secondary: '#434340',
    disabled: '#b6b9bd',
  },
  background: {
    paper: Colors.White,
    default: '#fdfdfe',
  },
  divider: '#dcdde0',
  action: {
    hover: '#ededef',
  },
  grey: {
    50: '#fdfdfe',
    100: '#ededef',
    200: '#dcdde0',
    300: '#b6b9bd',
    400: '#8a8f97',
    500: '#585f6a',
    600: '#353941',
    700: '#24272d',
    800: '#0c0d0f',
    900: '#0c0d0f',
    A100: '#0c0d0f',
    A200: '#24272d',
    A400: '#585f6a',
    A700: '#dcdde0',
  },
  primary: {
    main: '#1113d4',
  },
  secondary: {
    main: '#1113d4',
  },
  error: {
    main: '#d41a0b',
  },
  warning: {
    main: '#ffc900',
  },
  info: {
    main: '#1113d4',
  },
  success: {
    main: '#16a34a',
  },
};

const RefreshLightTheme = createTheme(RefreshLightThemePalette, '"Public Sans"');

export { RefreshLightTheme };

import React, { useMemo } from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Stepper, StepperStepType } from '@swyftx/aviary/atoms/Stepper';
import { Body, Heading, Numeric } from '@swyftx/aviary/atoms/Typography';
import { TickFilled } from '@swyftx/aviary/icons/filled';

import * as Sentry from '@sentry/react';
import { VerificationCardDirection, VerificationCardVariant } from 'src/lib/verification/types';

type Props = {
  imageType: string;
  usePalette?: boolean;
  title: string;
  content: string;
  actions: React.ReactNode;
  steps: StepperStepType[];
  currentStep: string;
  completed?: boolean;
  variant: VerificationCardVariant;
  isCurrentStep?: boolean;
  direction: VerificationCardDirection;
};

const UserVerificationCard: React.FC<Props> = ({
  imageType,
  usePalette = false,
  title,
  content,
  actions,
  steps,
  currentStep,
  completed = false,
  variant,
  direction,
  isCurrentStep = false,
}) => {
  const iconSize = useMemo(() => {
    switch (variant) {
      case 'accordion':
        return 'w-48 h-48';
      case 'compact':
      case 'full':
      default:
        return 'w-96 h-96';
    }
  }, [variant]);

  const icon = useMemo(
    () => <Image image={imageType} usePalette={usePalette} className={iconSize} />,
    [iconSize, imageType, usePalette],
  );

  const stepIndex = useMemo(() => steps.findIndex((step) => step.key === currentStep) + 1, [steps, currentStep]);

  const containerAlignItems = useMemo(() => {
    if (variant === 'accordion' && !isCurrentStep) return 'center';
    if (variant === 'compact') return 'center';

    return 'start';
  }, [variant, isCurrentStep]);

  const containerJustifyContent = useMemo(() => {
    if (variant === 'accordion' && !isCurrentStep) return 'center';
    if (variant === 'compact') return 'center';

    return 'space-between';
  }, [variant, isCurrentStep]);

  return (
    <FlexLayout spacing={48} alignItems='start' justifyContent='space-between'>
      <FlexLayout
        spacing={direction === 'column' ? 8 : 24}
        direction={direction}
        className='h-full w-full'
        justifyContent='center'
        alignItems={containerAlignItems}
      >
        {variant === 'compact' && (
          <FlexLayout className='mb-16' justifyContent='center'>
            <Stepper steps={steps} variant='compact' currentStep={currentStep} />
          </FlexLayout>
        )}

        {icon}
        <FlexLayout
          direction='column'
          spacing={8}
          className='h-full w-full text-center @sm:text-left'
          alignItems={variant === 'compact' ? 'center' : 'start'}
          justifyContent={containerJustifyContent}
        >
          <FlexLayout
            alignItems={variant === 'accordion' ? 'center' : 'start'}
            spacing={8}
            direction={variant === 'accordion' ? 'row' : 'column'}
          >
            {variant !== 'compact' && (
              <FlexLayout
                alignItems='center'
                justifyContent='center'
                className={`h-24 w-24 rounded-[50%] border border-color-border-main  ${
                  completed || isCurrentStep ? 'bg-color-background-primary !text-white' : ''
                }`}
              >
                {completed ? (
                  <TickFilled className='h-14 w-14 text-color-text-inverse' />
                ) : (
                  <Numeric color='inverse'>{stepIndex}</Numeric>
                )}
              </FlexLayout>
            )}
            <Heading size='h5' className={variant === 'compact' ? 'text-center' : 'text-left'}>
              {title}
            </Heading>
          </FlexLayout>
          {(variant !== 'accordion' || (variant === 'accordion' && isCurrentStep)) && (
            <Body size='small' className={variant === 'compact' ? 'text-center' : 'text-left'}>
              {content}
            </Body>
          )}
          <div
            className={`mt-16 ${completed ? 'invisible' : ''} ${
              variant === 'accordion' && !isCurrentStep ? 'hidden' : ''
            }`}
          >
            {actions}
          </div>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export const ProfiledUserVerificationCard = Sentry.withProfiler(UserVerificationCard);

export { ProfiledUserVerificationCard as UserVerificationCard };

import { clamp } from '@utils/number';

const THUMB_SIZE = 8;

// Utility functions have been copied from here
// https://github.com/radix-ui/primitives/issues/1188#issuecomment-1594974370

const linearScale = (input: readonly [number, number], output: readonly [number, number]) => (value: number) => {
  if (input[0] === input[1] || output[0] === output[1]) return output[0];
  const ratio = (output[1] - output[0]) / (input[1] - input[0]);
  return output[0] + ratio * (value - input[0]);
};

const convertValueToPercentage = (value: number, min: number, max: number) => {
  const maxSteps = max - min;
  const percentPerStep = 100 / maxSteps;
  const percentage = percentPerStep * (value - min);
  return clamp(percentage, 0, 100);
};

const getThumbInBoundsOffset = (width: number, left: number, direction: number) => {
  const halfWidth = width / 2;
  const halfPercent = 50;
  const offset = linearScale([0, halfPercent], [0, halfWidth]);
  return (halfWidth - offset(left) * direction) * direction;
};

const calcStepMarkOffset = (percentage: number, maxPercentage: number) => {
  const percent = convertValueToPercentage(percentage, 0, maxPercentage);
  const thumbInBoundsOffset = getThumbInBoundsOffset(THUMB_SIZE, percent, 1);
  return `calc(${percent}% + ${thumbInBoundsOffset}px)`;
};

export { linearScale, convertValueToPercentage, getThumbInBoundsOffset, calcStepMarkOffset };

export type Feature = {
  imgSrc?: string;
  imgHeight?: string;
  preTitle?: string;
  title?: string;
  orderedListDescription?: boolean;
  descriptions?: (string | JSX.Element)[];
};

export type Release = {
  imgSrc?: string;
  title?: string;
  version: ReleaseVersion;
  overview?: string[];
  features: Feature[];
};

export enum ReleaseVersion {
  One = 0,
  Two = 1,
  Three = 2,
  Four = 3,
  Five = 4,
  Six = 5,
  SwyftxRewards = 6,
}

import { useMemo, useState } from 'react';

import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';

import { MarketOcoTab } from 'src/lib/trade-pro/types/TradePro.types';

type Props = {
  type: MarketOcoTab;
};

const useSecondaryOcoTabs = ({ type }: Props) => {
  const [selectedSecondaryTabs, setSelectedSecondaryTabs] = useState<string>();

  const secondaryTabs = useMemo((): EnhancedTab<string>[] | undefined => {
    switch (type) {
      case 'price':
        return undefined;
      case 'percent':
        return [
          { title: '2.5%', value: '2.5' },
          { title: '5%', value: '5' },
          { title: '10%', value: '10' },
          { title: '15%', value: '15' },
          { title: '25%', value: '25' },
        ];
      case 'total':
        return [
          { title: '$250', value: '250' },
          { title: '$500', value: '500' },
          { title: '$1,000', value: '1000' },
          { title: '$2,500', value: '2500' },
          { title: '$5,000', value: '5000' },
        ];
    }
  }, [type]);

  return {
    secondaryTabs,
    selectedSecondaryTabs,
    setSelectedSecondaryTabs,
  };
};

export { useSecondaryOcoTabs };

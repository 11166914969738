import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const Music: React.FC<Props> = ({ ...props }) => (
  <svg {...props} viewBox='0 0 24 24 '>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.9429 3.00001C20.9429 2.74393 20.8122 2.50554 20.5964 2.36778C20.3805 2.23001 20.1093 2.21191 19.877 2.31978C16.4521 3.91034 12.918 4.5115 9.43957 4.23914C9.23113 4.22282 9.02533 4.29425 8.87182 4.43621C8.71831 4.57816 8.63103 4.77776 8.63103 4.98684L8.6309 15.323C8.05512 14.9165 7.35248 14.6777 6.5939 14.6777C4.64108 14.6777 3.05859 16.2619 3.05859 18.214C3.05859 20.1674 4.64125 21.7503 6.5939 21.7503C8.53552 21.7503 10.111 20.1867 10.13 18.2495C10.1306 18.2376 10.1309 18.2257 10.1309 18.2137L10.131 5.78311C13.242 5.91311 16.3822 5.388 19.4429 4.14414L19.4427 13.3359C18.8671 12.9299 18.1647 12.6914 17.4064 12.6914C15.4537 12.6914 13.8711 14.2744 13.8711 16.2277C13.8711 18.1799 15.4535 19.764 17.4064 19.764C19.3594 19.764 20.9415 18.1812 20.9427 16.2298L20.9427 16.2271L20.9429 3.00001ZM17.4064 14.1914C16.2825 14.1914 15.3711 15.1024 15.3711 16.2277C15.3711 17.3521 16.2827 18.264 17.4064 18.264C18.5313 18.264 19.4427 17.352 19.4427 16.2277C19.4427 15.1026 18.5315 14.1914 17.4064 14.1914ZM6.5939 16.1777C5.47019 16.1777 4.55859 17.0896 4.55859 18.214C4.55859 19.3393 5.47001 20.2503 6.5939 20.2503C7.71895 20.2503 8.63019 19.3391 8.63019 18.214C8.63019 17.0898 7.71877 16.1777 6.5939 16.1777Z'
      fill='currentColor'
    />
  </svg>
);

export { Music };

import { Validator, ValidatorResponse } from '.';

class NameValidator extends Validator<string> {
  validate = (input: string): ValidatorResponse => {
    const nameRegex = /^([a-zA-Z]+[a-zA-Z' -]*[a-zA-Z]+)$/;
    const valid = input.length > 0 && nameRegex.test(input);

    return {
      message: valid ? undefined : this.message,
      valid,
    };
  };
}

export default NameValidator;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Danger } from '@swyftx/aviary/icons/outlined';
import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  open?: boolean;
  onClose: (closeParent: boolean) => void;
  title?: any;
};

export const CancelVerificationModal: React.FC<Props> = ({ onClose, title }) => {
  const { t } = useTranslation('profile.verification');

  return (
    <Modal
      open
      id='cancel-verification-modal'
      sx={{ width: '400px' }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
            <Button
              id='verificationMethod.cancelVerificationModal.buttonLabels.resume'
              variant='text'
              color='inherit'
              sx={{ width: '50%' }}
              onClick={() => onClose(false)}
            >
              {t('verificationMethod.cancelVerificationModal.buttonLabels.resume')}
            </Button>
            <Button
              id='verificationMethod.cancelVerificationModal.buttonLabels.cancel'
              variant='contained'
              color='error'
              sx={{ width: '50%' }}
              onClick={() => onClose(true)}
            >
              {t('verificationMethod.cancelVerificationModal.buttonLabels.cancel')}
            </Button>
          </Stack>
        ),
      }}
    >
      <Stack direction='column' textAlign='center' alignItems='center' justifyContent='center'>
        <Danger className='w-70px] mb-8 h-[70px] text-color-text-warning' />
        <Typography fontWeight={600} fontSize={20} marginBottom={1}>
          {title || t('verificationMethod.cancelVerificationModal.title')}
        </Typography>
        <Typography fontSize={14} color='text.secondary' marginBottom={2}>
          {t('verificationMethod.cancelVerificationModal.subTitle')}
        </Typography>
      </Stack>
    </Modal>
  );
};

import env from '@shared/config';

import {
  StartSessionArgs,
  StartSessionResponse,
  SyncContentCardsArgs,
  SyncContentCardsResponse,
} from '../../@types/braze';
import { Methods, request } from '../rest';

const brazeBaseUrl = env.BRAZE_API_URL;

const maintenanceEndpoints = {
  startBrazeSession: ({ data }: { data: StartSessionArgs }): StartSessionResponse =>
    request({ path: '/data/', method: Methods.POST, host: brazeBaseUrl, data }),

  syncBrazeContentCards: ({ data }: { data: SyncContentCardsArgs }): SyncContentCardsResponse =>
    request({
      path: '/content_cards/sync',
      method: Methods.POST,
      host: brazeBaseUrl,
      data,
    }),
};

export default maintenanceEndpoints;

import React, { PropsWithChildren } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';

import { PortfolioTableVariant } from 'src/lib/portfolio/types';

type Props = {
  asset: Asset;
  showCode?: boolean;
  variant: PortfolioTableVariant;
  direction?: 'row' | 'column';
};

const PortfolioTableAsset: React.FC<PropsWithChildren<Props>> = ({
  asset,
  direction = 'row',
  showCode = true,
  variant,
  children,
}) => (
  <FlexLayout spacing={8} alignItems='center' justifyContent='start'>
    <AssetIcon asset={asset} size={24} />

    <FlexLayout direction={direction} spacing={direction === 'row' ? 8 : 0} alignItems='start' justifyContent='start'>
      {variant === 'full' && (
        <Body size='small' overflow='truncate' title={asset.name}>
          {asset.name}
        </Body>
      )}

      {showCode && (
        <Body size='small' overflow='truncate' color='secondary'>
          {asset.code}
        </Body>
      )}
      {direction === 'column' && children}
    </FlexLayout>
  </FlexLayout>
);

export { PortfolioTableAsset };
